import React from "react";
import { Container, Card, Form } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSettings } from "../../context/settings";
import { TIMEZONE_OPTIONS } from "../../context/appdata";

function Settings() {
    const { settings, set } = useSettings();

    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header><b>Settings</b></Card.Header>
                <Card.Body>
                    <Card.Title>Timezone</Card.Title>
                    <Form.Select defaultValue={settings.timezone} onChange={(e) => set('timezone', e.target.value)}>
                        {TIMEZONE_OPTIONS.map((opt,i) => (
                            <option value={opt.name}>{opt.code} - {opt.name}</option>
                        ))}
                    </Form.Select>
                </Card.Body>
                {/* <Card.Body>
                    <Card.Title>Theme</Card.Title>
                    <Form.Select size="lg" defaultValue={settings.theme} onChange={(e) => set('theme', e.target.value)}>
                        <option value="light">Light</option>
                        <option value="dark">Dark</option>
                    </Form.Select>
                </Card.Body> */}
                {/* <Card.Body>
                    <Card.Title>Language</Card.Title>
                    <Form.Select size="lg" defaultValue={settings.language} onChange={(e) => set('language', e.target.value)}>
                        <option value="english">English</option>
                        <option value="spanish">Spanish</option>
                    </Form.Select>
                </Card.Body> */}
            </Card>
        </Container>
    );
}

export default Settings;