import React from "react";
import NavBarComponent from "../../components/NavBar.component";
import { useRole } from "../../context/role";
import { AiFillSchedule } from "react-icons/ai";

const ManifestNav = () => {
    const { userRole, checkNavPermissions } = useRole();
    const navOptions = [
        { href: "/manifest", icon: <AiFillSchedule/>, text: "Manifest" },
      ];
      const allowed = checkNavPermissions(userRole, navOptions)
    return (
        <NavBarComponent name={'Manifest Menu'} navOptions={allowed}/>
    );
};

export default ManifestNav