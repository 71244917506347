import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import { Container, Accordion, Card, Row, Col, Form, Button, Table, Spinner, Alert, Badge, Modal } from "react-bootstrap";
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp, FaCaretDown, FaCaretRight, FaEye, FaTrash } from "react-icons/fa";
import { TIMEZONE_OPTIONS, ROUTE_ACTION, CONTACT_SERVICE_TYPES, VEHICLE_CATEGORIES, TRAILER_CATEGORIES, ROUTPLAN_PERIOD_TYPE, ROUTPLAN_CREW_TYPE, ROUTEPLAN_COVERAGE_DEFAULTS, ROUTEPLAN_WK1_KEYS, ROUTEPLAN_WK2_KEYS, LICENSE_TYPES } from "../../context/appdata";
import { useParams, useNavigate } from "react-router-dom"
import CalendarChart from "../../components/CalendarChart";
import DataTable from '../../components/DataTable'
import { Week1Digest, DayOfWeekDigest, getDayOfWeek, formatDate } from "../../utils/DayOfWeekDigest";
import { formatNameBadge } from "../../components/formatNameBadge";
import Api from "../../utils/Api";
import { useSettings } from "../../context/settings";
import { useAuth } from '../../context/auth';
import RoutePlanDetailVersion from './RoutePlan.detail.version';
import { parseUtcToStr } from '../../utils/DateTimeFormat.helper';
import AlertDisplay from '../../components/AlertDisplay';
// import GoogleMapPlotRoute from '../../components/GoogleMapPlotRoute'

function RoutePlanUpdate() {
    const navigate = useNavigate();
    const { id } = useParams();
    const { user, idToken } = useAuth();
    const { settings } = useSettings();

    // State variables
    const [set, didSet] = useState(false);
    const [loadData, shouldLoadData] = useState(true);
    const [loadRoutePlan, shouldLoadRoutePlan] = useState(true);
    const [loadForecast, shouldLoadForecast] = useState(false);
    const [loadBuild, shouldLoadBuild] = useState(false);
    const [routeplan, setRoutePlan] = useState({});
    const [coverage, setCoverage] = useState({});
    const [stops, setStops] = useState([]);
    const [showStopContent, setShowStopContent] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [assignments, setAssignments] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [frequencies, setFrequencies] = useState([]);
    const [locations, setLocations] = useState([]);
    const [domiciles, setDomiciles] = useState([]);
    const [operators, setOperators] = useState([]);
    const [vehicles, setVehicles] = useState([]); // Corrected typo: vehciles -> vehicles
    const [trailers, setTrailers] = useState([]);
    const [ops, setOps] = useState([]);
    const [runct, setRunCt] = useState();

    const [showModal, shouldShowModal] = useState();

    // Date-related variables
    const date = new Date();
    const yyyy = date.getUTCFullYear();
    const today = formatDate(date);
    const tomorrow = new Date(date);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowplus14 = new Date(date);
    tomorrowplus14.setDate(tomorrowplus14.getDate() + 14);
    const todayplus30 = new Date(date);
    todayplus30.setDate(todayplus30.getDate() + 30);
    const todayplus30Formatted = formatDate(todayplus30);
    const tomorrowFormatted = formatDate(tomorrow);
    const [buildStart, setBuildStart] = useState(tomorrowFormatted);
    const tomorrowplus14Formatted = formatDate(tomorrowplus14);
    const [buildEnd, setBuildEnd] = useState(tomorrowplus14Formatted);
    const [buildForecast, setBuildForecast] = useState([]);



    const [forecast, setForecast] = useState([]);
    //
    const [showRoute, shouldShowRoute] = useState(false)
    const [showService, shouldShowService] = useState(false)
    const [showEquipment, shouldShowEquipment] = useState(false)
    const [showCoverage, shouldShowCoverage] = useState(false)
    const [showStandardOp, shouldShowStandardOp] = useState(false)
    const [showForecast, shouldShowForecast] = useState(false)
    //
    const [routeplanVersionIndex, setRoutePlanVersionIndex] = useState(0)
    const [showRoutePlanVersion, shouldShowRoutePlanVersion] = useState(false)
    const [routeplanVersion, setRoutePlanVersion] = useState({})

    async function handleBuild() {
        try {
            if (buildStart > today && buildStart <= buildEnd) {
                setAlerts([...alerts, { variant: 'success', message: "..." }])
                await Api.get(`/dispatch/build/route/${id}/${buildStart}/${buildEnd}`, idToken)
                setAlerts([...alerts, { variant: 'success', message: "Dispatch built!" }])
            } else {
                setAlerts([...alerts, { variant: 'warning', message: "Build start date must be greater than today and build end must be greater than or equal to build start." }])
            }
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Unable to build' }])
        }
    }

    async function handleEmail() {
        try {
            await Api.post(`/jobs/mailcarrier/routeplan/hire/${id}`, { to: [user.email] }, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Email sent' }])
        } catch (err) {
            setAlerts([...alerts, { variant: 'warning', message: 'Unable to send email' }])
        }
    }

    async function handleReport() {
        try {
            await Api.post(`/jobs/mailcarrier/routeplan/hire/report/${id}`, { to: [user.email] }, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Email sent' }])
        } catch (err) {
            setAlerts([...alerts, { variant: 'warning', message: 'Unable to send email' }])
        }
    }

    async function handleCopy() {
        try {
            const newRoutePlanName = window.prompt('You must enter a unique name for the route plan?');
            if (newRoutePlanName && newRoutePlanName.trim() !== '') {
                const newRoutePlan = { ...routeplan, name: newRoutePlanName, stops: stops, coverage: coverage }
                const res = await Api.post(`/routeplans`, newRoutePlan, idToken)
                if (res) {
                    const _id = res.message.insertedId;
                    navigate(`/plan/routeplan/${_id}`);
                } else {
                    setAlerts([...alerts, { variant: 'warning', message: 'Unable to post copied route plan' }])
                }
            } else {
                setAlerts([...alerts, { variant: 'warning', message: 'Enter a name for the route plan.' }])
            }

        } catch (err) {
            setAlerts([...alerts, { variant: 'warning', message: 'Unable to copy route plan' }])
        }
    }

    async function handleUpdate() {
        try {
            const sophours = calculateRouteHours(stops);
            const payrateperhour = getRatePerHour()
            const payest = moneyformatter.format(routeplan.blockpay ?
                (routeplan.blockpayhours * getRatePerHour())
                :
                (calculateRouteHours(stops) * getRatePerHour())
            )
            const updateRoutePlan = {
                ...routeplan,
                stops: stops,
                coverage: coverage,
                payrateperhour: payrateperhour,
                sophours: sophours,
                payest: payest,
            }
            await Api.patch(`/routeplan/${id}`, updateRoutePlan, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success' }])
            shouldLoadRoutePlan(true)
            didSet(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error updating' + JSON.stringify(error) }])
        }
    }

    async function handleActivateVersion() {
        try {
            await Api.patch(`/routeplan/${id}/activate/${routeplanVersionIndex}`, {}, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success' }])
            shouldLoadRoutePlan(true)
            didSet(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error updating' + JSON.stringify(error) }])
        }
    }

    function removeCoverage(wkday, j) {
        const coverageArr = coverage[wkday].filter((i, index) => index !== j) ?? [];
        const newCoverage = { ...coverage, [wkday]: coverageArr }
        setCoverage({ ...newCoverage })
        setRoutePlan({ ...routeplan, coverage: newCoverage })
    }

    function cover(wkday, val) {
        const coverageArr = coverage[wkday] ?? []
        const newCoverage = { ...coverage, [wkday]: [...coverageArr, val] }
        setCoverage({ ...newCoverage })
        setRoutePlan({ ...routeplan, coverage: newCoverage })
    }

    function setStop(i, k, v) {
        stops[i][k] = v
        setRoutePlan({ ...routeplan, [stops]: [...stops] })
    }

    function moveStopUp(index) {
        if (index > 0) {
            const stop = stops[index];
            const newStops = [...stops];
            newStops.splice(index, 1);
            newStops.splice(index - 1, 0, stop);
            setStops([...newStops]);
            setRoutePlan({ ...routeplan, [stops]: [...newStops] })
        }
    }

    function moveStopDown(index) {
        if (index < stops.length - 1) {
            const stop = stops[index];
            const newStops = [...stops];
            newStops.splice(index, 1);
            newStops.splice(index + 1, 0, stop);
            setRoutePlan({ ...routeplan, [stops]: [...newStops] })
            setStops([...newStops]);
        }
    }

    function removeStop(index) {
        const filtered = stops.filter((_, i) => {
            return i !== index
        })
        setStops([...filtered])
        setRoutePlan({ ...routeplan, [stops]: [...filtered] })
    }

    function sumTimeDifferences(times) {
        if (times && times.length > 0) {
            const diffs = [];
            // let accum = 0
            for (let i = 1; i < times.length; i++) {
                const timeString1 = times[i - 1];
                const timeString2 = times[i];
                const [time1, tz1] = timeString1.split(' ');
                const [hour1, minute1] = time1.split(':');
                const [time2, tz2] = timeString2.split(' ');
                const [hour2, minute2] = time2.split(':');
                const date1 = new Date(0, 0, 0, hour1, minute1)
                const date2 = new Date(0, 0, 0, hour2, minute2)
                let ds1 = date1.toLocaleString('en-US', { timeZone: tz1 });
                let ds2 = date2.toLocaleString('en-US', { timeZone: tz2 });
                if (Date.parse(ds2) <= Date.parse(ds1)) {
                    // add 24 hours to date2 if it is less than date1
                    ds2 = new Date(Date.parse(ds2) + 24 * 60 * 60 * 1000);
                }
                const diff = Date.parse(ds2) - Date.parse(ds1);
                diffs.push(diff);
            }
            const totalDiff = diffs.reduce((acc, curr) => acc + curr, 0);
            const totalHours = totalDiff / 3600000;
            return totalHours;
        }
        return 'NA'
    }

    function areTimesFormattedCorrectly(times) {
        const regex = /^\d{2}:\d{2} (CT|MT|PT|ET|America\/New_York|America\/Chicago|America\/Denver|America\/Los_Angeles)$/;
        return times.every(time => regex.test(time));
    }

    function calculateRouteHours(stops) {
        const times = [];
        for (let i = 0; i < stops.length; i++) {
            times.push(stops[i].arrive + " " + stops[i].timezone)
            times.push(stops[i].depart + " " + stops[i].timezone)
        }
        if (areTimesFormattedCorrectly(times)) {
            const timelapse = sumTimeDifferences(times)
            return timelapse
        } else {
            return 0;
        }

    }

    function getRatePerHour() {
        if (routeplan.contract.payrates !== undefined) {
            let rates = [];
            for (let i = 0; i < routeplan.contract.payrates.length; i++) {
                if (Date(routeplan.contract.payrates[i].effective) <= Date(routeplan.startop) && routeplan.serviceclass === routeplan.contract.payrates[i].class) {
                    switch (routeplan.contract.payrates[i].rule) {
                        case 'All Hours':
                            rates.push(routeplan.contract.payrates[i]);
                            break;
                        case 'First 40 Hours':
                            rates.push(routeplan.contract.payrates[i]);
                            break;
                        default:
                            break;
                    }
                }
            }
            const uniqueData = Object.values(rates.reduce((acc, curr) => {
                const key = `${curr.type}-${curr.rule}`;
                if (!(key in acc) || new Date(acc[key].effectiveDate) < new Date(curr.effectiveDate)) {
                    acc[key] = curr;
                }
                return acc;
            }, {}));
            return uniqueData.reduce((acc, curr) => {
                return acc += parseInt(curr.amount)
            }, 0)
        }
        return 0
    }

    const CoverageCards = (wkkey, h) => {
        const encounteredDomiciles = new Set();
        return (
            <Col key={h} sm={12} md={6} lg={3}>
                <Card>
                    <Card.Header>
                        <span>
                            <b>{wkkey.toUpperCase()}</b>
                        </span>
                        <span style={{ float: 'right' }}>
                            <b>{routeplan.coverage[wkkey] ? (routeplan.coverage[wkkey]).length : 0} / {routeplan.crew === 'Team' ? 2 : 1}</b>
                        </span>
                    </Card.Header>
                    <Card.Body>
                        <Col>
                            <Container fluid style={{ margin: '0px', padding: '2px' }}>
                                <Row>
                                    <Col xs={10}>
                                        <Form.Select
                                            onChange={(e) =>
                                                e.target.value !== "" && cover(wkkey, JSON.parse(e.target.value))
                                            }
                                        >
                                            <option value="">Select</option>
                                            {ROUTEPLAN_COVERAGE_DEFAULTS.map((option, i) => (
                                                <option key={i} value={JSON.stringify(option)}>
                                                    {option.name}
                                                </option>
                                            ))}
                                            {operators &&
                                                operators.map((operator) => {
                                                    // Check if the domicile has already been encountered
                                                    const domicileName = operator.domicile.name;
                                                    if (!domicileName) return null; // Skip operators without a domicile

                                                    // Render the disabled option only for the first occurrence of the domicile
                                                    if (!encounteredDomiciles.has(domicileName)) {
                                                        encounteredDomiciles.add(domicileName);
                                                        return (
                                                            <>
                                                                <option key={domicileName} disabled>
                                                                    {domicileName}
                                                                </option>
                                                                <option key={operator._id} value={JSON.stringify(operator)}>
                                                                    {operator.firstname} {operator.lastname}{" "}
                                                                    {operator.provider && `- ${operator.provider} 📶 `}
                                                                </option>
                                                            </>

                                                        );
                                                    }

                                                    return (
                                                        <option key={operator._id} value={JSON.stringify(operator)}>
                                                            {operator.firstname} {operator.lastname}{" "}
                                                            {operator.provider && `- ${operator.provider} 📶 `}
                                                        </option>
                                                    );
                                                })}
                                        </Form.Select>
                                    </Col>
                                    {routeplan.crew === 'Team' &&
                                        <Col xs={2}>
                                            <Button variant="primary" onClick={(coverage[wkkey].length - 1 >= 0)
                                                ? () => cover(wkkey, coverage[wkkey][coverage[wkkey].length - 1])
                                                : () => setAlerts([...alerts, { variant: 'warning', message: 'You must select a driver.' }])
                                            }>+</Button>
                                        </Col>
                                    }
                                </Row>
                            </Container>
                        </Col>
                        <hr />
                        <Col>
                            {routeplan.coverage[wkkey] && routeplan.coverage[wkkey].map((operator, j) => (
                                <Container fluid style={{ margin: '0px', padding: '2px' }}>
                                    <Row>
                                        <Col xs={10}>
                                            {(ROUTEPLAN_COVERAGE_DEFAULTS.map(item => item.name)).includes(operator.name)
                                                ? <Form.Control key={h + j} disabled value={`${operator.name}`} />
                                                : <Form.Control key={h + j} disabled value={`${operator.firstname} ${operator.lastname} ${operator.provider !== '' && `- ${operator.provider} 📶 `}`} />
                                            }
                                        </Col>
                                        <Col xs={2}>
                                            <Button variant="warning" onClick={() => removeCoverage(wkkey, j)}>X</Button>
                                        </Col>
                                    </Row>
                                </Container>
                            ))}
                        </Col>

                    </Card.Body>
                    {(routeplan.coverage[wkkey] && (routeplan.coverage[wkkey]).length > (routeplan.crew === 'Team' ? 2 : 1))
                        &&
                        <Alert variant="warning">🚨 too many drivers</Alert>}
                </Card>
                <br />
            </Col>
        )
    }

    const updateShowStopContent = (index) => {
        const updatedShowStopContent = [...showStopContent];
        updatedShowStopContent[index] = !updatedShowStopContent[index];
        setShowStopContent(updatedShowStopContent);
    };

    const moneyformatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });


    function viewRoutePlanVersions() {
        shouldShowRoutePlanVersion(true)
        setRoutePlanVersion(routeplan.versions[routeplanVersionIndex])
    }

    function nextVersion() {
        let min = 0
        let version = routeplanVersionIndex + 1
        if (version < routeplan.versions.length) {
            setRoutePlanVersionIndex(version)
            setRoutePlanVersion(routeplan.versions[version])
        } else {
            setRoutePlanVersionIndex(min)
            setRoutePlanVersion(routeplan.versions[min])
        }
    }

    function previousVersion() {
        let max = routeplan?.versions.length - 1
        let version = routeplanVersionIndex - 1
        if (version >= 0) {
            setRoutePlanVersionIndex(version)
            setRoutePlanVersion(routeplan.versions[version])
        } else {
            setRoutePlanVersionIndex(max)
            setRoutePlanVersion(routeplan.versions[max])
        }
    }



    useEffect(() => {
        //
        const fetchData = async () => {
            try {
                const endpoints = [
                    '/assignments/live',
                    '/contracts/live',
                    '/frequencies/live',
                    '/locations/live',
                    '/locations/live/Domicile',
                    '/operators/live',
                    '/trailers/live',
                    '/vehicles/live'
                ];
                const [
                    assignments,
                    contracts,
                    frequencies,
                    locations,
                    domiciles,
                    operators,
                    trailers,
                    vehicles
                ] = await Promise.all(endpoints.map(endpoint => Api.get(endpoint, idToken)));

                setAssignments(assignments);
                setContracts(contracts);
                setFrequencies(frequencies);
                setLocations(locations);
                setDomiciles(domiciles);
                setOperators(operators);
                setTrailers(trailers);
                setVehicles(vehicles);

            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error. ' + JSON.stringify(error) }]);
            }
            shouldLoadData(false);
        };
        if (loadData) {
            fetchData();
        }
        //
        const fetchRoutePlan = async () => {
            try {
                const rp = await Api.get(`/routeplan/${id}`, idToken);
                setRoutePlan(rp);
                setCoverage({ ...rp.coverage })
                setStops([...rp.stops])
                const initialShowStopContent = Array(rp.stops ?? []).fill(false);
                setShowStopContent(initialShowStopContent)
                const frequencyId = rp.frequency._id;
                if (!frequencyId) {
                    throw new Error('Unable to access frequency information. ')
                }
                const operations = await Api.get(`/frequency/${frequencyId}/digest/${yyyy}-01-01/${yyyy}-12-31`, idToken)
                const operationsClean = operations.filter(operation => {
                    const o = new Date(operation[0])
                    const s = new Date(routeplan.startop)
                    const e = new Date(routeplan.endop)
                    return o >= s && o <= e
                })

                const runs = operationsClean.reduce((acc, curr) => acc + curr[1], 0);
                setRunCt(runs)
                setOps(operationsClean)
                shouldLoadForecast(true)
                didSet(true)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Route Plan request error' + JSON.stringify(error) }]);
            }
            shouldLoadRoutePlan(false);
        }
        if (loadRoutePlan) {
            fetchRoutePlan();
        }
        //
        const fetchForecast = async () => {
            try {
                const data = await Api.get(`/forecast/routeplan/${id}/${today}/${todayplus30Formatted}`, idToken)
                const cleanForecast = data.map(route => {
                    let wkday;
                    if (route.routeplan.period === 'Weekly') {
                        wkday = Week1Digest(route.servicedate)
                    } else {
                        wkday = DayOfWeekDigest(route.servicedate)
                    }
                    return {
                        'Name': route.routeplan.name,
                        'Service Date': `${route.servicedate}`,
                        'Day': `${getDayOfWeek(route.servicedate)}`,
                        'Service Type': route.servicetype,
                        'Period': route.routeplan.period,
                        'Contract': route.routeplan.contract.name,
                        'Frequency ': `${route.routeplan.frequency.name} - ${route.routeplan.frequency.detail}`,
                        'Crew': route.routeplan.crew,
                        'Coverage': <>{route.routeplan.coverage[wkday].map(driver => formatNameBadge(driver))}</>
                    }
                })
                setForecast(cleanForecast);
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Forecasting error' }])
            }
            shouldLoadForecast(false);
        }
        if (loadForecast) {
            fetchForecast()
        }
        // 
        const fetchBuildForecast = async () => {
            try {
                const data = await Api.get(`/forecast/routeplan/${id}/${buildStart}/${buildEnd}`, idToken)
                const cleanBuildForecast = data.map(route => {
                    let wkday;
                    if (route.routeplan.period === 'Weekly') {
                        wkday = Week1Digest(route.servicedate)
                    } else {
                        wkday = DayOfWeekDigest(route.servicedate)
                    }
                    return {
                        'Name': route.routeplan.name,
                        'Service Date': `${route.servicedate}`,
                        'Day': `${getDayOfWeek(route.servicedate)}`,
                        'Service Type': route.servicetype,
                        'Period': route.period,
                        'Contract': route.routeplan.contract.name,
                        'Frequency ': `${route.routeplan.frequency.name} - ${route.routeplan.frequency.detail}`,
                        'Crew': route.routeplan.crew,
                        'Coverage': <>{route.routeplan.coverage[wkday].map(driver => formatNameBadge(driver))}</>
                    }
                })
                setBuildForecast(cleanBuildForecast);
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Error forecasting build. ' + JSON.stringify(error) }])
            }
            shouldLoadBuild(false);
        }
        if (loadBuild) {
            fetchBuildForecast()
        }

    }, [set, loadData, loadForecast, loadRoutePlan, loadBuild, alerts,
        id, idToken, routeplan.endop, routeplan.startop,
        yyyy, today, todayplus30Formatted, buildStart, buildEnd,
    ]);

    if (showModal) {
        return (
            <Container fluid>
                <Card>
                    <Card.Header>
                        <Modal.Title>Build Route : {routeplan && routeplan.name}</Modal.Title>
                    </Card.Header>
                    <AlertDisplay alertState={alertState} />
                    <Card.Body>
                        <Row>
                            <Col>
                                Start
                                <Form.Control type="date"
                                    value={buildStart}
                                    onChange={e => {
                                        if (new Date(e.target.value) > new Date(today)) {
                                            setBuildStart(e.target.value);
                                            shouldLoadBuild(true);
                                        }
                                    }} />
                            </Col>
                            <Col>
                                End
                                <Form.Control
                                    type="date"
                                    value={buildEnd}
                                    onChange={e => {
                                        if (new Date(e.target.value) > new Date(today)) {
                                            setBuildEnd(e.target.value);
                                            shouldLoadBuild(true);
                                        }
                                    }} />
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Body>
                        {loadBuild ? 
                            <Spinner/>
                        :
                            buildForecast.length > 0 ?
                            <>
                                <DataTable dataSource={buildForecast} lsKey={'@mctms-buildForecast-route-data'} />
                            </>
                            :
                            <>
                                No data.
                            </>
                        }
                    </Card.Body>
                    <Card.Footer>
                        <Row>
                            <Col>
                                <Button variant="primary" onClick={() => handleBuild()}>Build Dispatch</Button>
                            </Col>
                            <Col style={{ textAlign: 'right' }}><Button variant="secondary" onClick={() => shouldShowModal(false)}>Close</Button></Col>
                        </Row>
                    </Card.Footer>
                </Card>
            </Container>
        )
    }

    if (showRoutePlanVersion) {
        return (
            <Container fluid>
                <AlertDisplay alertState={alertState} />
                <Card>
                    <Card.Header>
                        <Row>
                            <Col>
                                Version : <Card.Title>{routeplanVersionIndex + 1}/{routeplan && routeplan?.versions.length}</Card.Title>
                            </Col>
                            <Col>
                                Date :
                                <Card.Title>
                                    {
                                        routeplanVersion?.versionUtcTimestamp
                                            ? parseUtcToStr(routeplanVersion.versionUtcTimestamp, settings.timezone)
                                            : 'No date'
                                    }
                                </Card.Title>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Button variant="secondary" onClick={() => previousVersion()}><FaArrowLeft /></Button>
                                <Button variant="secondary" onClick={() => nextVersion()}><FaArrowRight /></Button>
                            </Col>
                        </Row>
                    </Card.Header>

                    {routeplanVersion &&
                        <RoutePlanDetailVersion
                            key={routeplanVersionIndex}
                            routeplan={routeplan.versions[routeplanVersionIndex]}
                            version={routeplanVersionIndex}
                        />
                    }
                    <Card.Footer>
                        <Row>
                            <Col style={{ textAlign: 'left' }}><Button variant="primary" onClick={() => handleActivateVersion()}>Activate</Button></Col>
                            <Col style={{ textAlign: 'right' }}><Button variant="secondary" onClick={() => shouldShowRoutePlanVersion(false)}>Close</Button></Col>
                        </Row>
                    </Card.Footer>
                </Card>
            </Container>
        )
    }


    const RouteDetail = () => {
        return (
            <>
                <Card.Header>
                    <Card.Title onClick={() => shouldShowRoute(!showRoute)}> {showRoute ? <FaCaretDown /> : <FaCaretRight />} Route </Card.Title>
                </Card.Header>
                {showRoute &&
                    <Card.Body>
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                Name
                                <Form.Control type="text" value={routeplan.name} onChange={e => setRoutePlan({ ...routeplan, name: e.target.value })} />
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Contract
                                <Form.Select value={JSON.stringify(routeplan.contract)} onChange={e => setRoutePlan({ ...routeplan, contract: JSON.parse(e.target.value) })}>
                                    <option value="">Select</option>
                                    {contracts && contracts.map(contract => (
                                        <option key={contract._id} value={JSON.stringify(contract)}>{contract.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Assignment
                                <Form.Select value={JSON.stringify(routeplan.assignment)} onChange={e => e.target.value && setRoutePlan({ ...routeplan, assignment: JSON.parse(e.target.value) })}>
                                    <option value="">Select</option>
                                    {assignments && assignments.map(assignment => (
                                        <option key={assignment._id} value={JSON.stringify(assignment)}>{assignment.name}</option>
                                    ))}
                                </Form.Select>
                                {!assignments.includes(routeplan.assignment) ? 
                                    <Alert>
                                        ok
                                    </Alert>
                                    :
                                    <Alert>
                                    Mismatch
                                </Alert>
                                }
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Domicile
                                <Form.Select value={routeplan.domicile ? JSON.stringify(routeplan.domicile) : ''} onChange={e => setRoutePlan({ ...routeplan, domicile: JSON.parse(e.target.value) })}>
                                    <option value="">Select</option>
                                    {domiciles && domiciles.map(domicile => (
                                        <option key={domicile._id} value={JSON.stringify(domicile)}>{domicile.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <br />
                        <Card.Header>
                            <Row>
                                <Col>
                                    <Card.Title> Stops </Card.Title>
                                </Col>
                                <Col>
                                    <Button
                                        variant="primary"
                                        className="float-end"
                                        onClick={() => setStops([...stops, {
                                            timezone: settings.timezone,
                                            trip: '',
                                            location: '',
                                            arrive: '',
                                            arrival: '',
                                            action: '',
                                            depart: '',
                                            departure: '',
                                        }])}
                                    >Add Stop</Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Accordion>
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>{'#'}</th>
                                        <th>{'Trip'}</th>
                                        <th>{'Location'}</th>
                                        <th>{'Timezone'}</th>
                                        <th>{'Arrive'}</th>
                                        <th>{'Action '}</th>
                                        <th>{'Depart'}</th>
                                        <th>{'Up'}</th>
                                        <th>{'Down'}</th>
                                        <th>{'Delete'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(stops && stops.length > 0) ? stops.map((stop, i) => (
                                        <>
                                            <tr style={{ borderTop: '0px transparent', borderBottom: '0px transparent' }} key={i + 'a'}>
                                                <td onClick={() => updateShowStopContent(i)}><h4><Badge>{i + 1}{showStopContent[i] ? <FaCaretDown /> : <FaCaretRight />}</Badge></h4></td>
                                                <td onClick={() => updateShowStopContent(i)}>{stop.trip}</td>
                                                <td onClick={() => updateShowStopContent(i)}>
                                                    {stop.location &&
                                                        <>
                                                            {stop.location.name} <br />
                                                            {stop.location.address1} {stop.location.address2} <br />
                                                            {stop.location.city} {stop.location.state} {stop.location.zipcode}
                                                        </>
                                                    }
                                                </td>
                                                <td onClick={() => updateShowStopContent(i)}>{stop.timezone}</td>
                                                <td onClick={() => updateShowStopContent(i)}>{stop.arrive}</td>
                                                <td onClick={() => updateShowStopContent(i)}>{stop.action}</td>
                                                <td onClick={() => updateShowStopContent(i)}>{stop.depart}</td>
                                                <td>
                                                    <Button variant="secondary" onClick={() => moveStopUp(i)}>
                                                        <FaArrowUp />
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button variant="secondary" onClick={() => moveStopDown(i)}>
                                                        <FaArrowDown />
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button variant="warning" onClick={() => removeStop(i)} style={{ float: 'right' }}>
                                                        <FaTrash />
                                                    </Button>
                                                </td>
                                            </tr>
                                            {showStopContent[i] &&
                                                <tr style={{ borderTop: '0px transparent', borderBottom: '2px solid #333' }} key={i + 'b'} >
                                                    <td colspan="100%">

                                                        <Card>
                                                            <Card.Header>
                                                                <Card.Subtitle>
                                                                    Stop {i + 1} Update
                                                                </Card.Subtitle>
                                                            </Card.Header>
                                                            <Card.Body>
                                                                <Row>
                                                                    <Col xs={12} md={4}>
                                                                        Trip
                                                                        <Form.Control type="text" value={stop.trip} onChange={e => setStop(i, 'trip', e.target.value)} />
                                                                    </Col>
                                                                    <Col xs={12} md={4}>
                                                                        Location
                                                                        <Form.Select value={JSON.stringify(stop.location)} onChange={e => setStop(i, 'location', JSON.parse(e.target.value))}>
                                                                            <option value="">Select</option>
                                                                            {locations && locations.map(location => (
                                                                                <option key={location._id} value={JSON.stringify(location)}>{location.name}</option>
                                                                            ))}
                                                                        </Form.Select>
                                                                    </Col>
                                                                    <Col xs={12} md={4}>
                                                                        Timezone
                                                                        <Form.Select value={stop.timezone} defaultValue={settings.timezone} onChange={e => setStop(i, 'timezone', e.target.value)} >
                                                                            <option value="">Select</option>
                                                                            {TIMEZONE_OPTIONS.map((tz, j) => (
                                                                                <option key={j} value={tz.name}>{tz.code}</option>
                                                                            ))}
                                                                        </Form.Select>
                                                                    </Col>
                                                                    <Col xs={12} md={4}>
                                                                        Arrive
                                                                        <Form.Control value={stop.arrive} defaultValue={'00:00'} type="time" onChange={e => setStop(i, 'arrive', e.target.value)} />
                                                                    </Col>
                                                                    <Col xs={12} md={4}>
                                                                        Action
                                                                        <Form.Select value={stop.action} onChange={e => setStop(i, 'action', e.target.value)} >
                                                                            <option value="">Select</option>
                                                                            {ROUTE_ACTION.map((ra, j) => (
                                                                                <option key={j} value={ra.name}>{ra.name} - {ra.detail}</option>
                                                                            ))}
                                                                        </Form.Select>
                                                                    </Col>
                                                                    <Col xs={12} md={4}>
                                                                        Depart
                                                                        <Form.Control value={stop.depart} defaultValue={'00:00'} type="time" onChange={e => setStop(i, 'depart', e.target.value)} />
                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Card>
                                                    </td>
                                                </tr>
                                            }
                                        </>
                                    )) :
                                        <tr>
                                            <td colspan="100%">
                                                Add stops.
                                            </td>
                                        </tr>
                                    }
                                </tbody >
                                {(stops && stops.length > 0) &&
                                    <tfoot>
                                        <tr>
                                            <td colSpan={'100%'}>
                                                <Row>
                                                    <Col sm={12} md={6} lg={4}>
                                                        <b> SOP Hours </b>
                                                        <Form.Control
                                                            type="number"
                                                            value={stops ? calculateRouteHours(stops) : 0}
                                                            onChange={e => setRoutePlan({ ...routeplan, sophours: e.target.value })}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Col>
                                                </Row>
                                            </td>
                                        </tr>
                                    </tfoot>}
                            </Table>
                            {/* <GoogleMapPlotRoute waypoints={[]}/> */}
                        </Accordion>
                    </Card.Body>
                }
            </>
        )
    }

    const SOPDetail = () => {
        return (
            <>
                <Card.Header>
                    <Card.Title onClick={() => shouldShowStandardOp(!showStandardOp)}>
                        {showStandardOp ? <FaCaretDown /> : <FaCaretRight />} Standard Operating Procedure
                    </Card.Title>
                </Card.Header>
                {showStandardOp &&
                    <Card.Body>
                        <Row>
                            <Col xs={12} sm={6}>
                                <Form.Check
                                    type="radio"
                                    label="Pay by Hour"
                                    name="option"
                                    value="hour"
                                    checked={routeplan.payby === 'hour'}
                                    onChange={e => setRoutePlan({ ...routeplan, payby: e.target.value })}
                                />
                                {(routeplan.payby === 'hour') &&
                                    <Form.Check
                                        label={'Block Pay'}
                                        checked={routeplan.blockpay}
                                        onChange={() => {
                                            setRoutePlan({ ...routeplan, blockpay: (routeplan.blockpay ? false : true) })
                                        }}
                                    />
                                }
                            </Col>
                            <Col sm={12} md={6} lg={4}>
                                <Form.Check
                                    type="radio"
                                    label="Pay by Mile"
                                    name="option"
                                    value="mile"
                                    checked={routeplan.payby === 'mile'}
                                    onChange={e => setRoutePlan({ ...routeplan, payby: e.target.value })}
                                />
                            </Col>
                            <Col sm={12} md={6} lg={4}>
                                <b> {(routeplan.blockpay !== true && routeplan.payby === 'hour') && '*'} SOP Hours</b>
                                <Form.Control
                                    type="number"
                                    value={stops ? calculateRouteHours(stops) : 0}
                                    onChange={e => setRoutePlan({ ...routeplan, sophours: e.target.value })}
                                    readOnly
                                />
                            </Col>
                            <Col sm={12} md={6} lg={4}>
                                {(routeplan.blockpay === true && routeplan.payby === 'hour') &&
                                    <>
                                        <b>{(routeplan.blockpay === true && routeplan.payby === 'hour') && '*'} Block Hours </b>
                                        <Form.Control
                                            type="number"
                                            step="0.00"
                                            value={routeplan.blockpayhours}
                                            onChange={e => setRoutePlan({ ...routeplan, blockpayhours: e.target.value })}
                                        />
                                    </>
                                }
                            </Col>
                            <Col xs={12}>
                                {routeplan.payby === 'hour' &&
                                    <Row>
                                        <Col sm={12} md={6} lg={4}>
                                            <b> {routeplan.payby === 'hour' && '*'} Rate Per Hour</b>
                                            <Form.Control type="number"
                                                step="0.00"
                                                value={getRatePerHour()}
                                                onChange={e => setRoutePlan({ ...routeplan, payrateperhour: e.target.value })}
                                                readOnly
                                                disabled
                                            />
                                        </Col>
                                        <Col sm={12} md={6} lg={4}>
                                            <b>Pay Est.</b>
                                            <Form.Control
                                                type="text"
                                                value={moneyformatter.format(routeplan.blockpay ?
                                                    (routeplan.blockpayhours * getRatePerHour())
                                                    :
                                                    (calculateRouteHours(stops) * getRatePerHour())
                                                )}
                                                onChange={e => setRoutePlan({ ...routeplan, payrph: e.target.value })}
                                                readOnly
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                }
                            </Col>
                            {routeplan.payby === 'hour' &&
                                <>
                                    <Col xs={12}>
                                        <br />
                                        <Table striped bordered hover responsive>
                                            <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Class</th>
                                                    <th>Rule</th>
                                                    <th>Amount</th>
                                                    <th>Effective</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(routeplan.contract && routeplan.contract.payrates) && 
                                                (routeplan.contract.payrates.length > 0) &&
                                                routeplan.contract.payrates.map((rate, i) => (
                                                    rate.class === routeplan.serviceclass && 
                                                    <tr key={i}>
                                                        <td>{rate.type}</td>
                                                        <td>{rate.class}</td>
                                                        <td>{rate.rule}</td>
                                                        <td>{rate.amount}</td>
                                                        <td>{rate.effective}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </>
                            }
                        </Row>
                        <Row>
                            <Col sm={12} md={6} lg={4}>
                                <b  > {(routeplan.payby === 'mile') && '*'} Miles</b>
                                <Form.Control
                                    type="number"
                                    step="0.00"
                                    placeholder="Measured miles"
                                    value={routeplan.routemiles}
                                    onChange={e => setRoutePlan({ ...routeplan, routemiles: e.target.value })}
                                />
                            </Col>
                            <Col sm={12} md={6}>
                                {routeplan.payby === 'mile' &&
                                    <Row>
                                        <Col sm={12} md={6} lg={4}>
                                            <b> {(routeplan.payby === 'mile') && '*'}Rate Per Mile</b>
                                            <Form.Control type="number"
                                                step="0.00"
                                                value={routeplan.payratepermile}
                                                onChange={e => setRoutePlan({ ...routeplan, payratepermile: e.target.value })}
                                            />
                                        </Col>
                                        <Col sm={12} md={6} lg={4}>
                                            <b>Pay Est.</b>
                                            <Form.Control type="text"
                                                step="0.00"
                                                value={moneyformatter.format(routeplan.payratepermile * routeplan.routemiles)}
                                                onChange={e => setRoutePlan({ ...routeplan, payrpm: e.target.value })}
                                                readOnly
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                }
                            </Col>
                        </Row>
                    </Card.Body>
                }
            </>
        )
    }

    const ServiceDetail = () => {
        return (
            <>
                <Card.Header>
                    <Row>
                        <Col>
                            <Card.Title onClick={() => shouldShowService(!showService)}> {showService ? <FaCaretDown /> : <FaCaretRight />}  Service </Card.Title>
                        </Col>
                    </Row>
                </Card.Header>
                {showService &&
                    <Card.Body>
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                Type
                                <Form.Select value={routeplan.servicetype} onChange={e => setRoutePlan({ ...routeplan, servicetype: e.target.value })}>
                                    <option value="">Select</option>
                                    {CONTACT_SERVICE_TYPES.map((servicetype, i) => (
                                        <option key={i} value={servicetype.name}>{servicetype.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Frequency
                                <Form.Select value={JSON.stringify(routeplan.frequency)} onChange={e => setRoutePlan({ ...routeplan, frequency: JSON.parse(e.target.value) })}>
                                    <option value="">Select</option>
                                    {frequencies && frequencies.map(frequency => (
                                        <option key={frequency._id} value={JSON.stringify(frequency)}>{frequency.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Start Op
                                <Form.Control type="date" value={routeplan.startop} onChange={e => setRoutePlan({ ...routeplan, startop: e.target.value })} />
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                End Op
                                <Form.Control type="date" value={routeplan.endop} onChange={e => setRoutePlan({ ...routeplan, endop: e.target.value })} />
                            </Col>
                        </Row>
                        <br />
                        <Container fluid style={{ overflowX: 'scroll' }}>
                            <CalendarChart title={`${routeplan.frequency.name}`} opdata={ops} adjust={Math.ceil(ops.length / 365)} />
                            <Card.Subtitle>Operations : {runct} / {ops.length}</Card.Subtitle>
                        </Container>
                    </Card.Body>
                }
            </>
        )
    }

    const EquipmentDetail = () => {
        return (
            <>
                <Card.Header>
                    <Row>
                        <Col>
                            <Card.Title onClick={() => shouldShowEquipment(!showEquipment)}>{showEquipment ? <FaCaretDown /> : <FaCaretRight />} Equipment </Card.Title>
                        </Col>
                        <Col>
                            <Form.Check
                                label={'Trailer Required'}
                                className="float-end"
                                checked={routeplan.trailerrequired}
                                onChange={() => { setRoutePlan({ ...routeplan, trailerrequired: (routeplan.trailerrequired ? false : true) }) }}
                            />
                        </Col>
                    </Row>
                </Card.Header>
                {showEquipment &&
                    <Card.Body>
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                Class
                                <Form.Select value={routeplan.serviceclass} onChange={e => setRoutePlan({ ...routeplan, serviceclass: e.target.value })}>
                                    <option value="">Select</option>
                                    {LICENSE_TYPES.map((serviceclass, i) => (
                                        <option key={i} value={serviceclass.name}>{serviceclass.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            {routeplan.serviceclass &&
                                <>
                                    <Col sm={12} md={6} lg={3}>
                                        Vehicle
                                        <Form.Select value={routeplan.vehiclecat} onChange={e => setRoutePlan({ ...routeplan, vehiclecat: e.target.value })}>
                                            <option value="">Select</option>
                                            {VEHICLE_CATEGORIES.filter(vehicle => vehicle.licenseRequirement === routeplan.serviceclass).map((v, i) => (
                                                <option key={i} value={v.name}>{v.name}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Check
                                            label={'Schedule Vehicle'}
                                            checked={routeplan.schedulevehicle}
                                            onChange={() => { setRoutePlan({ ...routeplan, schedulevehicle: (routeplan.schedulevehicle ? false : true) }) }}
                                        />
                                        {routeplan.schedulevehicle === true &&
                                            <Form.Select value={JSON.stringify(routeplan.scheduledvehicle)} onChange={e => setRoutePlan({ ...routeplan, scheduledvehicle: JSON.parse(e.target.value) })}>
                                                <option value="">Select</option>
                                                {vehicles.map(vehicle => (
                                                    <option value={JSON.stringify(vehicle)}>{vehicle.unitnumber}</option>
                                                ))}
                                            </Form.Select>
                                        }
                                    </Col>
                                    <Col sm={12} md={6} lg={3}>
                                        {routeplan.trailerrequired &&
                                            <>
                                                Trailer
                                                <Form.Select value={routeplan.trailercat} onChange={e => setRoutePlan({ ...routeplan, trailercat: e.target.value })}>
                                                    <option value="">Select</option>
                                                    {TRAILER_CATEGORIES.map((t, i) => (
                                                        <option key={i} value={t.name}>{t.name}</option>
                                                    ))}
                                                </Form.Select>
                                                <Form.Check
                                                    label={'Schedule Trailer'}
                                                    checked={routeplan.scheduletrailer}
                                                    onChange={() => { setRoutePlan({ ...routeplan, scheduletrailer: (routeplan.scheduletrailer ? false : true) }) }}
                                                />
                                                {routeplan.scheduletrailer === true &&
                                                    <Form.Select value={JSON.stringify(routeplan.scheduledtrailer)} onChange={e => setRoutePlan({ ...routeplan, scheduledtrailer: JSON.parse(e.target.value) })}>
                                                        <option value="">Select</option>
                                                        {trailers.map((trailer) => (
                                                            <option key={trailer._id} value={JSON.stringify(trailer)}>{trailer.unitnumber}</option>
                                                        ))}
                                                    </Form.Select>
                                                }
                                            </>
                                        }
                                    </Col>
                                </>
                            }
                        </Row>

                    </Card.Body>
                }
            </>
        )
    }

    const CoverageDetail = () => {
        return (
            <>
                <Card.Header>
                    <Row>
                        <Col>
                            <Card.Title onClick={() => shouldShowCoverage(!showCoverage)}>{showCoverage ? <FaCaretDown /> : <FaCaretRight />} Coverage </Card.Title>
                        </Col>
                    </Row>
                </Card.Header>
                {showCoverage &&
                    <Card.Body>
                        <Row>
                            <Col sm={12} md={6} lg={4}>
                                Crew
                                <Form.Select value={routeplan.crew} onChange={e => setRoutePlan({ ...routeplan, crew: e.target.value })}>
                                    <option value="">Select</option>
                                    {ROUTPLAN_CREW_TYPE.map((name, i) => (
                                        <option key={i} value={name}>{name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col sm={12} md={6} lg={4}>
                                Period
                                <Form.Select value={routeplan.period} onChange={e => setRoutePlan({ ...routeplan, period: e.target.value })}>
                                    <option value="">Select</option>
                                    {ROUTPLAN_PERIOD_TYPE.map((name, i) => (
                                        <option key={i} value={name}>{name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <Container fluid>
                                    <Card.Title>
                                        Week 1
                                    </Card.Title>
                                    <Row>
                                        {ROUTEPLAN_WK1_KEYS.map((wk1key, h) => (
                                            CoverageCards(wk1key, h)
                                        ))}
                                    </Row>
                                    <br />
                                    {routeplan.period === 'Biweekly' &&
                                        <>
                                            <Card.Title>
                                                Week 2
                                            </Card.Title>
                                            <Row>
                                                {ROUTEPLAN_WK2_KEYS.map((wk2key, h) => (
                                                    CoverageCards(wk2key, h)
                                                ))}
                                            </Row>
                                        </>}
                                </Container>
                            </Col>
                        </Row>

                    </Card.Body>
                }
            </>
        )
    }

    const ForecastDetail = () => {
        return (
            <>
                <Card.Header>
                    <Row>
                        <Col>
                            <Card.Title onClick={() => shouldShowForecast(!showForecast)}>{showForecast ? <FaCaretDown /> : <FaCaretRight />} Forecast </Card.Title>
                        </Col>
                    </Row>
                </Card.Header>
                {showForecast &&
                    <Card.Body>
                        {forecast.length > 0 ?
                            <>
                                <DataTable dataSource={forecast} lsKey={'@mctms-forecast-route-data'} />
                            </>
                            :
                            <>
                                No data.
                            </>
                        }

                    </Card.Body>
                }
            </>
        )
    }

    return (
        <Container fluid>
            {/* <Row>
                Token: {JSON.stringify(idToken)}
            </Row> */}
            <Card >
                <Card.Header>
                    <Row>
                        <Col><Card.Title>Route Plan Update : {routeplan && routeplan.name}</Card.Title> </Col>
                        <Col>
                            <Button variant="primary" onClick={() => handleCopy()} style={{ float: 'right', marginLeft: '0.5em' }}> Copy Route </Button>
                            <Button variant="secondary" onClick={() => handleReport()} style={{ float: 'right', marginLeft: '0.5em' }}> Report </Button>
                            <Button variant="secondary" onClick={() => handleEmail()} style={{ float: 'right', marginLeft: '0.5em' }}> Email Link </Button>
                            <Button
                                variant="secondary"
                                onClick={() => routeplan?.versions && viewRoutePlanVersions()}
                                style={{ float: 'right', marginLeft: '0.5em' }}
                            >
                                <FaEye /> Versions : {routeplan?.versions ? routeplan?.versions.length : 0}
                            </Button>
                            {
                                routeplan.isActive &&
                                <Button
                                    variant="warning"
                                    onClick={() => {
                                        shouldShowModal(true);
                                        shouldLoadForecast(true)
                                    }}
                                    style={{ float: 'right', marginLeft: '0.5em' }}
                                > Build Dispatch </Button>
                            }
                        </Col>
                    </Row>
                </Card.Header>
                <AlertDisplay alertState={alertState} />
                {set ?
                    <>
                        <RouteDetail />
                        <EquipmentDetail />
                        <SOPDetail />
                        <ServiceDetail />
                        <CoverageDetail />
                        <ForecastDetail />
                        <Card.Footer>
                            <Row>
                                <Col>
                                    <Button variant="primary" onClick={() => handleUpdate()}>Update</Button>
                                </Col>
                                <Col style={{ textAlign: 'right' }}>
                                    Active : <Button variant={routeplan.isActive ? 'success' : 'danger'} onClick={() => {
                                        setRoutePlan({ ...routeplan, isActive: routeplan.isActive ? false : true })
                                    }
                                    }>{routeplan.isActive ? 'True' : 'False'}</Button>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </>
                    :
                    <Card.Footer>
                        <Spinner />
                    </Card.Footer>
                }
            </Card>
        </Container>
    );
}

export default RoutePlanUpdate;