import { Container, Card, Spinner, Row, Col, Badge, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import React, { useState, useEffect } from 'react'
import { FaPlusCircle, } from 'react-icons/fa'
// import { IoCalendarOutline, IoSyncOutline, IoCopyOutline } from 'react-icons/io5'
import { useAuth } from '../../context/auth';
import DataDisplay from "../../components/DataDisplay";
import { Link } from "react-router-dom";
import { TIMEZONE_OPTIONS } from "../../context/appdata";
import AlertDisplay from "../../components/AlertDisplay";
import { getTimezoneCode } from "../../utils/Clock";


function RoutePlan() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [routeplans, setRoutePlans] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    const [query, setQuery] = useState({
        isActive: true
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const queryParams = new URLSearchParams(query);
                const data = await Api.query('/routeplans', queryParams, idToken);
                if (!data || !Array.isArray(data)) {
                    throw new Error("Received invalid data from server.");
                }
                const cleanRoutePlans = data.map((routeplan) => {
                    return {
                        _id: routeplan._id,
                        'Name': `${routeplan.name}`,
                        'Service Type': routeplan.servicetype,
                        'Contract': routeplan.contract.name,
                        'Assignment': routeplan.assignment.name,
                        'Domicile': routeplan.domicile.name,
                        'Stops': routeplan.stops.map(stp => `A: ${stp.arrive} | D: ${stp.depart} | ${getTimezoneCode(stp.timezone)} | ${stp.location.name}`).join('\n') ,
                        'Miles': routeplan.routemiles,
                        'Hours': routeplan.sophours,
                        'RPH': routeplan.payrateperhour,
                        'Pay By': routeplan.payby,
                        'Est Pay': routeplan.payest,
                        'Block Pay': routeplan.blockpay ? "True" : "False",
                        'BP Hrs.': routeplan.blockpayhours,
                        'Vehicle': routeplan.vehiclecat,
                        'Trailer': routeplan.trailercat,
                        'Frequency': `${routeplan.frequency.name} - ${routeplan.frequency.detail}`,
                        'Active': routeplan.isActive ? '✅' : '❌',
                    }
                })

                setRoutePlans(cleanRoutePlans)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }
            didSet(true)
        }
        fetchData()
    }, [set, idToken, alerts,query])


    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <Card.Title>
                        <Row>
                            <Col>Route </Col>
                            <Col style={{ textAlign: 'center' }}>
                                <Badge
                                    bg={query.isActive ? 'success' : 'danger'}
                                    onClick={() => { setQuery({ ...query, isActive: !query.isActive }); didSet(false) }}
                                >
                                    {query.isActive ? 'Active' : 'Inactive'}
                                </Badge>
                            </Col>
                            <Col>
                                <Link className="float-end" to="/plan/routeplan-form"><FaPlusCircle /> Form </Link>
                            </Col>
                        </Row>
                    </Card.Title>
                </Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {(set && routeplans) ?
                    <Card.Body>
                        {routeplans.length > 0 ?
                            <>
                                <DataDisplay
                                    dataSource={routeplans}
                                    lsKey={'@mctms-routeplans-display'}
                                    urlPath={'/plan/routeplan/'}
                                    urlKey={'_id'}
                                    popKeys={['_id']}
                                />
                            </>
                            :
                            <>
                                No data.
                            </>
                        }
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }
            </Card>
        </Container>
    );
}

export default RoutePlan;