import React, { useState, useEffect } from 'react'
import { Container, Card, Row, Col, Alert, Spinner, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import approutes from '../../context/approutes'
import DataDisplay from '../../components/DataDisplay';

function mapArraysByKey(array1, array2, key) {
    const result = [];

    array1.forEach((obj1) => {
        const matchedObj2 = array2.find((obj2) => obj2[key] === obj1[key]);

        if (matchedObj2) {
            result.push([obj1, matchedObj2]);
        } else {
            result.push([obj1, undefined]);
        }
    });

    array2.forEach((obj2) => {
        const matchedObj1 = array1.find((obj1) => obj1[key] === obj2[key]);

        if (!matchedObj1) {
            result.push([undefined, obj2]);
        }
    });

    return result;
}

function AccessNewUsers() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [newusers, setNewUsers] = useState([])
    const [error, setError] = useState();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const r = await Api.get('/access/roles', idToken);
                const p = await Api.get('/access/profiles', idToken);
                const u = await Api.get('/firebase/users', idToken);

                const cleanRoles = [];

                for (let i = 0; i < r.length; i++) {
                    const permissionCt = Object.values(r[i].permissions).filter(value => value === true).length
                    const cleaned = {
                        _id: r[i]._id,
                        'Name ': r[i].name,
                        'Permissions ': permissionCt + ' of ' + approutes.length,
                        'Active ': r[i].isActive ? '✅' : '❌'
                    }
                    cleanRoles.push(cleaned)
                }

                const usersprofiles = mapArraysByKey(u, p, 'uid')

                function getArraysUndefined(usersprofiles) {
                    const undefinedArrays = usersprofiles.filter(([_, secondElement]) => secondElement === undefined);
                    return undefinedArrays;
                }

                const newUserArray = getArraysUndefined(usersprofiles);

                const cleanUsers = newUserArray.map(([user, _]) => {
                    return {
                        uid: user.uid,
                        'Email': user.email,
                        'Last Signin': user.metadata.lastSignInTime,
                        'Last Refreseh': user.metadata.lastRefreshTime,
                        'Created': user.metadata.creationTime,
                        'Disabled': user.disabled
                    }
                })
                setNewUsers(cleanUsers)
            } catch (error) {
                setError('Request error');
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [idToken, set])

    return (
        <Container fluid>
            {error &&
                <Card.Body>
                    <Alert variant='warning' dismissible onClick={() => setError()}>{error}</Alert>
                </Card.Body>
            }
            <Card>
                <Card.Header>
                    <Card.Title>
                        New Users x {newusers.length}
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    {set ?
                        <Row>
                            {(newusers && newusers.length > 0) ?
                                <>
                                    <DataDisplay 
                                        dataSource={newusers} 
                                        urlPath={'/access/new-user-form/'} 
                                        urlKey={'uid'} 
                                        lsKey={'@mctms-access-newusers-table'} 
                                        popKeys={'uid'}
                                    />
                                </>
                                :
                                <Row>
                                    <Col>
                                        No new users.
                                    </Col>
                                </Row>
                            }
                        </Row>
                        : 
                        <Spinner />
                        }

                </Card.Body>
            </Card>
        </Container>
    )
}

export default AccessNewUsers;