import React, { useEffect, useState } from 'react';
import { Form, Button, Card, Row, Col, Badge, Alert } from 'react-bootstrap';
import logo from '../../../assets/logo.png'
import { useAuth } from '../../../context/auth';
import Api from '../../../utils/Api';

const initialFormState = {
    status: 'draft',
    firstname: '',
    lastname: '',
    dateofrequest: '',
    email: '',
    type: '',
    position: '',
    // region: '',
    // location: '',
    firstdayoff: '',
    lastdayoff: '',
    returndate: '',
    daysrequested: 0,
    hoursrequested: '',
    notes: '',
}

const PTORequestForm = () => {
    const { user, idToken } = useAuth();
    const [alerts, setAlerts] = useState([]);
    const [load, didLoad] = useState();
    const [form, setForm] = useState(initialFormState);
    const [locations, setLocations] = useState([]);
    const [regions, setRegions] = useState([]);
    const [profile, setProfile] = useState([]);

    const renderLocationColumns = () => {
        const columnSize = Math.ceil(locations.length / 4);

        const columns = [];
        for (let col = 0; col < 4; col++) {
            const start = col * columnSize;
            const end = start + columnSize;
            const columnLocations = locations.slice(start, end);

            columns.push(
                <Col sm={12} md={4} lg={3}>
                    {columnLocations.map((location) => (
                        <Form.Check
                            key={location.id}
                            label={location.name}
                            type='radio'
                            id={location.id}
                            name='location'
                            value={location.name}
                            checked={form.location === location.name && form.position === 'driver'}
                            onChange={(e) => handleFormSet('location', e.target.value)}
                            style={{ fontSize: '14px' }}
                        />
                    ))}
                </Col>
            );
        }
        return columns;
    };

    const renderRegionColumns = () => {
        const columnSize = Math.ceil(regions.length / 2);

        const columns = [];
        for (let col = 0; col < 2; col++) {
            const start = col * columnSize;
            const end = start + columnSize;
            const columnRegions = regions.slice(start, end);

            columns.push(
                <Col sm={12} md={4} lg={3}>
                    {columnRegions.map((region) => (
                        <Form.Check
                            key={region.id}
                            label={region.name}
                            type='radio'
                            id={region.id}
                            name='region'
                            value={region.name}
                            checked={form.region === region.name && form.position === 'management'}
                            onChange={(e) => handleFormSet('region', e.target.value)}
                            style={{ fontSize: '14px' }}
                        />
                    ))}
                </Col>
            );
        }
        return columns;
    };

    const getStatusText = () => {
        return 'Status: ' + form.status;
    };

    const getStatusVariant = () => {
        if (form.status === 'draft' ? true : false) {
            return 'secondary';
        } else if (form.status === 'pending') {
            return 'warning';
        } else if (form.status === 'approved') {
            return 'success';
        } else if (form.status === 'denied') {
            return 'danger';
        } else if (form.status === 'cancelled') {
            return 'dark';
        }
        return 'light';
    };

    const getDateDiff = (date1, date2) => {
        const diffInTime = date2.getTime() - date1.getTime();
        const diffInDays = diffInTime / (1000 * 3600 * 24);
        return Math.round(diffInDays);
    };

    const handleSubmit = async () => {
        try {
            const missingFields = Object.keys(initialFormState).filter((field) => form[field] === null || form[field] === undefined || form[field] === '');

            if (missingFields.length > 0) {
                const missingFieldNames = missingFields.map((field) => field.toUpperCase());
                const missingFieldsMessage = missingFieldNames.join(', ');
                setAlerts([...alerts, { variant: 'warning', message: `Please fill in the required fields: ${missingFieldsMessage}` }]);
            } else {
                const res = await Api.post('/form/pto', { ...form, email: user.email, status: 'pending' }, idToken)
                setAlerts([...alerts, { variant: 'success', message: `${JSON.stringify(res)}` }]);
                handleReset();
            }
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error submitting PTO Request' }]);
        }

    };

    const handleReset = async () => {
        setForm(initialFormState)
        localStorage.setItem('@mctms-pto-form-data', JSON.stringify(initialFormState))
        didLoad(false);
    }

    const calculateDaysRequested = (form, key, val) => {
        let daysRequested;
        if (key === 'firstdayoff') {
            const firstDayOffDate = new Date(val);
            const lastDayOffDate = new Date(form.lastdayoff);
            const returnDate = new Date(form.returndate);

            if (lastDayOffDate && firstDayOffDate > lastDayOffDate) {
                // alert('First Day Off must come before or be the same as Last Day Off');
                // add ux
                setAlerts([...alerts, { variant: 'warning', message: 'First Day Off must come before or be the same as Last Day Off' }]);
                return;
            }

            if (returnDate && firstDayOffDate >= returnDate) {
                // alert('First Day Off must come before the Return to Work Date');
                // add ux
                setAlerts([...alerts, { variant: 'warning', message: 'First Day Off must come before the Return to Work Date' }]);
                return;
            }          

            daysRequested = getDateDiff(firstDayOffDate, lastDayOffDate) + 1;
        } 
        
        if (key === 'lastdayoff') {
            const lastDayOffDate = new Date(val);
            const firstDayOffDate = new Date(form.firstdayoff);
            const returnDate = new Date(form.returndate);

            if (firstDayOffDate && lastDayOffDate < firstDayOffDate) {
                // add ux
                setAlerts([...alerts, { variant: 'warning', message: 'Last Day Off must come after or be the same as First Day Off' }]);
                // alert('Last Day Off must come after or be the same as First Day Off')
                return;
            }

            if (returnDate && lastDayOffDate >= returnDate) {
                // alert('Last Day Off must come before the Return to Work Date');
                setAlerts([...alerts, { variant: 'warning', message: 'Last Day Off must come before the Return to Work Date' }]);
                return;
            }

            daysRequested = getDateDiff(firstDayOffDate, lastDayOffDate) + 1;

        } 
        
        if (key === 'returndate') {
            const returnDate = new Date(val);
            const firstDayOffDate = new Date(form.firstdayoff);
            const lastDayOffDate = new Date(form.lastdayoff);

            if (lastDayOffDate && returnDate <= lastDayOffDate) {
                // alert('Return to Work Date must come after Last Day Off');
                setAlerts([...alerts, { variant: 'warning', message: 'Return to Work Date must come after Last Day Off' }]);
                return;
            }

            if (firstDayOffDate && returnDate <= firstDayOffDate) {
                // alert('Return to Work Date must come after First Day Off');
                setAlerts([...alerts, { variant: 'warning', message: 'Return to Work Date must come after First Day Off' }]);
                return;
            }

        }

        return daysRequested
    }

    const handleFormSet = (key, val) => {
        if (form.position !== 'driver') {
            form.location = '';
        }

        if (form.position !== 'management') {
            form.region = '';
        }
        
        const daysRequested = calculateDaysRequested(form, key, val)

        if(daysRequested){
            form['daysrequested'] = daysRequested 
        }

        const formData = { ...form, [key]: val }
        const formJSON = JSON.stringify(formData);
        setForm(formData)
        localStorage.setItem('@mctms-pto-form-data', formJSON)
    }

    const handleLoad = async () => {
        const userprofile = await Api.get(`/access/profile/uid/${user.uid}`, idToken);
        setProfile(userprofile)
        const formjson = localStorage.getItem('@mctms-pto-form-data');
        const formobj = formjson ? JSON.parse(formjson) : initialFormState;
        setForm({ ...formobj, firstname: profile.firstname, lastname: profile.lastname, email: user.email });
    }

    const fetchData = async () => {
        const assignments = await Api.get('/assignments/live', idToken);
        const locations = await Api.get('/locations/live/Domicile', idToken);
        const userprofile = await Api.get(`/access/profile/uid/${user.uid}`, idToken);
        setRegions(assignments)
        setLocations(locations)
        setProfile(userprofile)
    }

    useEffect(() => {
        if (!load) {
            handleLoad()
            fetchData()
        }
        return () => didLoad(true)
    });


    return (
        <Card>
            <Card.Header>
                <div className="img-container d-flex justify-content-center align-items-center">
                    <Card.Img
                        src={logo}
                        alt="logo"
                        style={{ width: "150px", height: "auto", }}
                    />
                </div>
                <Card.Title className="text-center">
                    PTO Request
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Card.Text style={{ fontWeight: 'bold', fontSize: '12px' }}>
                    This form should not be used to request leave under the Family and Medical Leave Act (FMLA) or to request leave as an accommodation under the Americans with Disabilities Act (ADA). Employees should consult with HR to request leave under the FMLA or ADA.
                </Card.Text>
                <Card.Text style={{ fontStyle: 'italic', fontSize: '12px' }}>
                    Please submit this form for approval at least 4 weeks in advance of your preferred vaction dates. If you have questions in regards to your PTO please contact: Tonda @ 402-890-2244
                </Card.Text>
            </Card.Body>
            {load &&
                        <Card.Body>
                        <Card.Header>
                            <Card.Title>Request
                                <span style={{ float: 'right' }}><Badge pill bg={getStatusVariant()}>{getStatusText()}</Badge></span>
                            </Card.Title>
                        </Card.Header>
                        <Row>
                            <Col sm={12} md={3}>
                                <Form.Group>
                                    <Form.Label>First Name:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="firstname"
                                        value={form.firstname}
                                        disabled
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={3}>
                                <Form.Group>
                                    <Form.Label className='form-label'>Last Name:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="lastname"
                                        value={form.lastname}
                                        disabled
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={3}>
                                <Form.Group>
                                    <Form.Label className='form-label'>Email:</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={user.email}
                                        disabled
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={3}>
                                <Form.Group>
                                    <Form.Label className='form-label'>Date of Request:</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="dateofrequest"
                                        value={form.dateofrequest}
                                        onChange={(e) => handleFormSet('dateofrequest', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* type radios (paid/unpaid) */}
                        <Row style={{ fontSize: '14px' }}>
                            <Form.Label style={{ marginTop: '10px' }}>Type:</Form.Label>
                            <Col xs={6} md={4} lg={3}>
                                <Form.Check
                                    inline
                                    label='Paid'
                                    type='radio'
                                    id='option1'
                                    name='type'
                                    value='paid'
                                    checked={form.type === 'paid'}
                                    onChange={(e) => handleFormSet('type', e.target.value)}
                                />
                            </Col>
                            <Col xs={6} md={4} lg={3}>
                                <Form.Check
                                    inline
                                    label='Unpaid'
                                    type='radio'
                                    id='option2'
                                    name='type'
                                    value='unpaid'
                                    checked={form.type === 'unpaid'}
                                    onChange={(e) => handleFormSet('type', e.target.value)}
                                />
                            </Col>
                        </Row>
                        {/* <Row>
                            <Card.Text style={{ fontStyle: 'italic', fontSize: '12px' }}>
                                Cash Out will occur in the next available payroll. Contract HR for additional information.
                            </Card.Text>
                        </Row> */}
                        <Row>
                            <Form.Label style={{ marginTop: '10px' }}>Position:</Form.Label>
                            <Col sm={12} md={4} lg={3}>
                                <Form.Check
                                    label='Driver'
                                    type='radio'
                                    name='position'
                                    value='driver'
                                    checked={form.position === 'driver'}
                                    onChange={(e) => handleFormSet('position', e.target.value)}
                                />
                            </Col>
                            <Col sm={12} md={4} lg={3}>
                                <Form.Check
                                    label='Management'
                                    type='radio'
                                    name='position'
                                    value='management'
                                    checked={form.position === 'management'}
                                    onChange={(e) => handleFormSet('position', e.target.value)}
                                />
                            </Col>
                            <Col sm={12} md={4} lg={3}>
                                <Form.Check
                                    label='Corporate'
                                    type='radio'
                                    name='position'
                                    value='corporate'
                                    checked={form.position === 'corporate'}
                                    onChange={(e) => handleFormSet('position', e.target.value)}
                                />
                            </Col>
                        </Row>
                        {form.position === 'management' && (
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className='form-label'>Region:</Form.Label>
                                        <Row>{renderRegionColumns()}</Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                        )}
                        {form.position === 'driver' && (
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className='form-label'>Location:</Form.Label>
                                        <Row>{renderLocationColumns()}</Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col sm={12} md={4} >
                                <Form.Group>
                                    <Form.Label>First Day Off:</Form.Label>
                                    <Form.Control
                                        type='date'
                                        value={form.firstdayoff}
                                        onChange={(e) => handleFormSet('firstdayoff', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={4} >
                                <Form.Group>
                                    <Form.Label>Last Day Off:</Form.Label>
                                    <Form.Control
                                        type='date'
                                        value={form.lastdayoff}
                                        onChange={(e) => handleFormSet('lastdayoff', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={4} >
                                <Form.Group>
                                    <Form.Label>Return to Work:</Form.Label>
                                    <Form.Control
                                        type='date'
                                        value={form.returndate}
                                        onChange={(e) => handleFormSet('returndate', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6} >
                                <Form.Group>
                                    <Form.Label>Days Requested:</Form.Label>
                                    <Form.Control
                                        type='number'
                                        value={form.daysrequested}
                                        disabled
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Group>
                                    <Form.Label>Hours Requested:</Form.Label>
                                    <Form.Control
                                        type='number'
                                        value={form.hoursrequested}
                                        onChange={(e) => handleFormSet('hoursrequested', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Form.Group>
                                    <Form.Label>Notes:</Form.Label>
                                    <Form.Control
                                        rows={2}
                                        as="textarea"
                                        style={{ resize: "vertical" }}
                                        value={form.notes}
                                        onChange={(e) => handleFormSet('notes', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Button variant='primary' type='button' onClick={handleSubmit} className='m-3'>
                                    Submit
                                </Button>
                                <Button variant='danger' type='button' onClick={handleReset} className='m-3' >
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body >
            }

            {alerts &&
                <Card.Body>
                    <Row>
                        <Col>
                            {alerts.map((alrt, i) => (
                                <Alert key={i} variant={alrt.variant} dismissible >{JSON.stringify(alrt.message)}</Alert>
                            ))}
                        </Col>
                    </Row>
                </Card.Body>
            }
        </Card >
    );
};

export default PTORequestForm;
