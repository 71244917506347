import { Container, Card, Row, Col, Form, Spinner, Alert, Button, Table } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import Api from "../../utils/Api";
import { useAuth } from '../../context/auth';
import { TIMEZONE_OPTIONS } from "../../context/appdata";
import { FORM_REPORTS } from "../../context/appreports";
import AlertDisplay from "../../components/AlertDisplay";

const defaultForm = {
    name: '',
    groupId: '',
    groupMembers: '',
    reportforward: '',
    time: '',
    timezone: '',
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
}

function ReportForm() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [form, setForm] = useState(defaultForm);
    const [groups, setGroups] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const alertState = {alerts, setAlerts}


    const handleTest = async () => {
        try {
            const filterGroups = (groups.filter(group => group._id === form.groupId))
            const group = filterGroups[0]
            if (form.reportforward !== '' && group) {
                await Api.post(form.reportforward, { to: group.members }, idToken)
                setAlerts([...alerts, { variant: 'success', message: 'Email sent' }])
            } else {
                throw new Error('Unable to test')
            }
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: JSON.stringify(error.message) }])
        }
    }

    async function handlePost() {
        try {
            const res = await Api.post('/reports', form, idToken)
            console.log(form)
            setAlerts([...alerts, { variant: 'success', message: JSON.stringify(res) }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: JSON.stringify(error) }])
        }
    }

    useEffect(() => {
        const handleFetch = async () => {
            try {
                const groupData = await Api.query('/access/groups', {}, idToken)
                setGroups(groupData)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured requesting data.' }])
            }
            didSet(true)
        }

        if (!set) {
            handleFetch();
        }
    },[set,idToken,alerts])

    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header>
                    <b>Report Form</b>
                </Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                <Card.Body>
                    <Row>
                        <Col sm={12}>
                            Name :
                            <Form.Control
                                type="text"
                                onChange={(e) => setForm({ ...form, name: e.target.value })}
                            />
                        </Col>
                        <Col sm={12}>
                            Report:
                            <Form.Select 
                                onChange={e => {
                                    if (e.target.value.trim() !== "") {
                                        const rpt = JSON.parse(e.target.value);
                                        setForm(prevForm => ({
                                            ...prevForm,
                                            reportforward: rpt.forwardurl,
                                            reportdetail: rpt.detail
                                        }));
                                    }
                                    }}>
                                    <option value=""></option>
                                    {FORM_REPORTS.map((formreport, i) => (
                                        <option key={i} value={JSON.stringify(formreport)}>
                                            {formreport.name} - {formreport.detail}
                                        </option>
                                    ))}
                            </Form.Select>
                        </Col>
                        <Col sm={12} md={4}>
                            Group:
                            <Form.Select 
                                value={JSON.stringify(groups.find(group => group._id === form.groupId))}
                                onChange={e => {
                                if (e.target.value.trim() !== "") {
                                    const grp = JSON.parse(e.target.value);
                                    setForm(prevForm => ({
                                        ...prevForm,
                                        groupId: grp._id,
                                        groupMembers: grp.members.sort()
                                    }));
                                }
                            }}>
                                <option value=""></option>
                                {groups && groups.map((grp, i) => (
                                    <option key={i} value={JSON.stringify(grp)}>
                                        {grp.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col sm={12} md={4}>
                            Time:
                            <Form.Control type="time" onChange={e => setForm({ ...form, time: e.target.value })} />
                        </Col>
                        <Col sm={12} md={4}>
                            Timezone:
                            <Form.Select onChange={e => setForm({ ...form, timezone: e.target.value })} >
                                <option value=""></option>
                                {TIMEZONE_OPTIONS.map((tz, i) => (
                                    <option key={i} value={tz.name}>{tz.name}</option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col sm={12}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>S</th>
                                        <th>M</th>
                                        <th>T</th>
                                        <th>W</th>
                                        <th>T</th>
                                        <th>F</th>
                                        <th>S</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><Form.Check checked={form.sunday} onChange={() => setForm({ ...form, sunday: !form.sunday })} /></td>
                                        <td><Form.Check checked={form.monday} onChange={() => setForm({ ...form, monday: !form.monday })} /></td>
                                        <td><Form.Check checked={form.tuesday} onChange={() => setForm({ ...form, tuesday: !form.tuesday })} /></td>
                                        <td><Form.Check checked={form.wednesday} onChange={() => setForm({ ...form, wednesday: !form.wednesday })} /></td>
                                        <td><Form.Check checked={form.thursday} onChange={() => setForm({ ...form, thursday: !form.thursday })} /></td>
                                        <td><Form.Check checked={form.friday} onChange={() => setForm({ ...form, friday: !form.friday })} /></td>
                                        <td><Form.Check checked={form.saturday} onChange={() => setForm({ ...form, saturday: !form.saturday })} /></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <br />
                    <b>Receipients :</b>
                    <Row>
                        {form.groupMembers && form.groupMembers.map((member, i) => (
                            <Col  key={i}>
                                <Form.Control value={member} disabled/>
                            </Col>
                        ))}
                    </Row>
                    <br />
                    <Button variant="primary" onClick={handlePost} className="m-1">Create</Button>
                    <Button variant="secondary" onClick={handleTest} className="m-1" style={{ float: 'right' }}>Test</Button>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default ReportForm;