import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import { Container, Card, Row, Col, Spinner, } from "react-bootstrap";
import Api from '../../utils/Api';
import { FaPlusCircle } from 'react-icons/fa'
import { useAuth } from '../../context/auth';
import DataDisplay from '../../components/DataDisplay';
import { Link } from 'react-router-dom';
import AlertDisplay from '../../components/AlertDisplay'

function IncidentManagementSystem() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [accidents, setAccidents] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const queryParams = new URLSearchParams();
    //             const data = await Api.query('/accident', queryParams, idToken);
    //             if (!data || !Array.isArray(data)) {
    //                 throw new Error("Received invalid data from server.");
    //             }
    //             const cleanAccidents = data.map(assignment => {
    //                 return {
    //                     _id: assignment._id,
    //                     'Name': assignment.name,
    //                     'Active': assignment.isActive ? '✅' : '❌',
    //                 }
    //             })
    //             setAccidents(cleanAccidents)
    //         } catch (error) {
    //             setAlerts([...alerts, { variant: 'warning', message: JSON.stringify(error) }]);
    //         }
    //         didSet(true)
    //     }
    //     if (!set) {
    //         fetchData()
    //     }
    // }, [idToken, set, alerts])

    return (
        <Container fluid>
            <Card>
                <Card.Header className="align-items-center">
                    <Row>
                        <Col>
                            <Card.Title>
                                Incident Managemenet
                                {/* <Link style={{ float: 'right' }} to="/plan/assignment-form"><FaPlusCircle /> Form</Link> */}
                            </Card.Title>
                        </Col>
                    </Row>
                </Card.Header>
                {/* {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {set ?
                    <>
                        <Card.Body>
                            {accidents.length > 0 ?
                                <DataDisplay
                                    dataSource={accidents}
                                    lsKey={'@mctms-accidents-display'}
                                    urlPath={'/plan/assignment/'}
                                    urlKey={'_id'}
                                    popKeys={['_id']}
                                />
                                :
                                <>
                                    No data.
                                </>
                            }
                        </Card.Body>
                    </>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                } */}
            </Card>
        </Container>
    )
}

export default IncidentManagementSystem;