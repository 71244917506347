import React, { useState, useEffect } from "react";
import { Container, Card, Form, Row, Col, Button, ListGroup, ListGroupItem, Badge, Table, Accordion, useAccordionButton } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from "../../../utils/Api";
import { useAuth } from "../../../context/auth";
import { FaEdit, FaPlusCircle, FaFilter } from "react-icons/fa";
import { PTO_STATUSES } from "../../../context/appdata";

function ThirdPartyVehicle() {

    const { idToken } = useAuth();
    const [thirdpartyvehcile, setThirdPartyVehicles] = useState([])
    const [filters, setFilters] = useState({});

    const fetchData = async () => {
        const records = await Api.query('/form/thirdpartyvehicle', filters, idToken);
        setThirdPartyVehicles(records)
    };

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey);
    
        return (
          <Button
            variant='link'
            className="float-end"
            onClick={decoratedOnClick}
          >
            {children}
          </Button>
        );
      };

    useEffect(() => {
        fetchData();
    }, [filters]);

    return (
        <Container fluid>
          <br />
          <Card>
            <Accordion>
              <Card>
                <Card.Header>
                  <b>3rd Party Vehicles</b>
                  <Button
                    variant="link"
                    className="float-end"
                    href="/form/thirdpartyvehicle-form"
                  >
                    <FaPlusCircle /> Form{" "}
                  </Button>
                  <CustomToggle eventKey="0">
                    <FaFilter /> Filters
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Form>
                      <Row>
                        <Col>
                          <Form.Label>Status</Form.Label>
                          {PTO_STATUSES.map((status) => (
                            <Form.Check
                              key={status}
                              type="checkbox"
                              id={status}
                            />
                          ))}
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Card.Body>
              <ListGroup>
                {thirdpartyvehcile && thirdpartyvehcile.length > 0 ? (
                  thirdpartyvehcile.map((record, i) => (
                    <ListGroupItem key={i}>
                      <Row>
                        <Col xs="auto">
                          <Button
                            variant="link"
                            className=""
                            href={`/form/thirdpartyvehicle/${record._id}`}
                          >
                            {" "}
                            <FaEdit />{" "}
                          </Button>
                        </Col>
                        <Col>{record.firstname}</Col>
                        <Col>{record.lastname}</Col>
                        <Col>{record.date}</Col>
                      </Row>
                    </ListGroupItem>
                  ))
                ) : (
                  <ListGroupItem>No data</ListGroupItem>
                )}
              </ListGroup>
            </Card.Body>
          </Card>
        </Container>
      );
  }
  
  export default ThirdPartyVehicle;