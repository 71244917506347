
import { FaChartPie,  FaDollarSign, FaEye, FaInfo, FaMapPin, FaStopwatch, FaTruckLoading } from "react-icons/fa";
import NavBarComponent from "../../components/NavBar.component";
import { useRole } from "../../context/role";
// import { BsActivity, BsCheckLg, BsInbox, BsXLg } from "react-icons/bs";
import { MdOutlineSensors } from "react-icons/md";
import { BsFillSignStopFill } from "react-icons/bs";
import { BsBoxes } from "react-icons/bs";
import { FaMagnifyingGlass } from "react-icons/fa6";
const LoadTenderNav = () => {
    const { userRole, checkNavPermissions } = useRole();
    const navOptions = [
        { href: "/loadtender", icon: <FaInfo />, text: "Info" },
        { href: "/loadtender/iot", icon: <MdOutlineSensors />, text: "IoT" },
        { href: "/loadtender/pay", icon: <FaDollarSign />, text: "Pay" },
        { href: "/loadtender/watch", icon: <FaEye />, text: "Watch" },
        { href: "/loadtender/search", icon: <FaMagnifyingGlass />, text: "Search" },
        { href: "/loadtender/dashboard", icon: <FaChartPie />, text: "Dashboard" },
        { href: "/loadtender/dashboard/v2", icon: <FaChartPie />, text: "Dashboard v2" },
        { href: "/loadtender/usps-service-points", icon: <FaMapPin />, text: "USPS Pts." },
        { href: "/loadtender/dispatch", icon: <BsBoxes />, text: "Dispatch" },
        { href: "/loadtender/live", icon: <FaStopwatch />, text: "Live" },
        // { href: "/loadtender/issues", icon: <BsFillSignStopFill />, text: "Issues" },
        // { href: "/loadtender/calendar", icon: <FaCalendar />, text: "Calendar" },
        // { href: "/loadtender/active", icon: <BsActivity />, text: "Active" },
        // { href: "/loadtender/completed", icon: <BsCheckLg />, text: "Completed" },
        // { href: "/loadtender/rejected", icon: <BsXLg />, text: "Rejected" },
        // { href: "/loadtender/requests", icon: <BsInbox />, text: "Requests" },
      ];

      const allowed = checkNavPermissions(userRole, navOptions)
    return (
        <NavBarComponent name={'Load Tender Menu'} navOptions={allowed}/>
    );
};

export default LoadTenderNav