import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import { Card, Container } from 'react-bootstrap';


function LoadTenderDispatch() {
    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    Load Tender Dispatch
                </Card.Header>
                <Card.Body>
                    test
                </Card.Body>
            </Card>
        </Container>
    ) 
}

export default LoadTenderDispatch;

