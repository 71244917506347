import React from "react";
import { Container, Card, Accordion } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
function Help() {
    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header>
                    <Card.Title>Help? I want to...</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                Build a Route Plan
                            </Accordion.Header>
                            <Accordion.Body>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                Dispatch a Driver
                            </Accordion.Header>
                            <Accordion.Body>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                On Board a Driver
                            </Accordion.Header>
                            <Accordion.Body>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                Submit PTO Request and/or other workplace forms.
                            </Accordion.Header>
                            <Accordion.Body>

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    
                </Card.Body>
            </Card>
        </Container>
    );
}

export default Help