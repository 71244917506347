import Access from '../features/access/Access';
import AccessNav from '../features/access/Access.nav';
import AccessProfileForm from '../features/access/Access.profile.form';
import AccessProfileUpdate from '../features/access/Access.profile.update';
import AccessRoleForm from '../features/access/Access.role.form';
import AccessRoleUpdate from '../features/access/Access.role.update';
import Accident from '../features/form/accident/Accident'
import AccidentForm from '../features/form/accident/Accident.form'
import AccidentUpdate from '../features/form/accident/Accident.update'
import Assignment from '../features/assignment/Assignment';
import AssignmentForm from '../features/assignment/Assignment.form';
import AssignmentUpdate from '../features/assignment/Assignment.update';
import Contract from '../features/contract/Contract';
import ContractForm from '../features/contract/Contract.form';
import ContractUpdate from '../features/contract/Contract.update';
import Dispatch from '../features/dispatch/Dispatch';
import DailyReportForm from '../features/form/dailyreport/DailyReport.form';
import DailyReportUpdate from '../features/form/dailyreport/DailyReport.update';
import DispatchDetail from '../features/dispatch/Dispatch.detail';
import DispatchForm from '../features/dispatch/Dispatch.form';
import DispatchNav from '../features/dispatch/Dispatch.nav';
import DispatchReports from '../features/dispatch/Dispatch.reports';
import DispatchSettings from '../features/dispatch/Dispatch.settings';
import DispatchUpdate from '../features/dispatch/Dispatch.update';
import IOT from '../features/telematics/IOT';
import FiftyFiveHundredNav from '../features/ffh/FiftyFivehundred.nav';
import FiftyFivehundredUpload from '../features/ffh/FiftyFiveHundred.upload';
import FiftyFiveHundredInfo from '../features/ffh/FiftyFiveHundred.info';
import FiftyFiveHundredTable from '../features/ffh/FiftyFiveHundred.table';
import Frequency from '../features/frequency/Frequency';
import FrequencyForm from '../features/frequency/Frequency.form';
import FrequencyUpdate from '../features/frequency/Frequency.update';
import Fuel from '../features/fuel/Fuel';
import HCR from '../features/hcr/HCR';
import Home from '../views/Home'
import IncidentForm from '../features/form/incident/Incident.form';
import IncidentUpdate from '../features/form/incident/Incident.update';
import LoadEye from '../features/loadeye/LoadEye';
import LoadTender from '../features/loadtender/LoadTender';
import LoadTenderNav from '../features/loadtender/LoadTender.nav';
import LoadTenderActive from '../features/loadtender/LoadTender.active';
import LoadTenderCompleted from '../features/loadtender/LoadTender.completed';
import LoadTenderDashboard from '../features/loadtender/LoadTender.dashboard';
import LoadTenderRejected from '../features/loadtender/LoadTender.rejected';
import LoadTenderRequests from '../features/loadtender/LoadTender.requests';
import LoadTenderResponse from '../features/loadtender/LoadTender.response';
import LoadTenderUpdate from '../features/loadtender/LoadTender.update';
import LoadTenderView from '../features/loadtender/LoadTender.view';
import Location from '../features/location/Location';
import LocationForm from '../features/location/Location.form';
import LocationUpdate from '../features/location/Location.update';
import Manifest from '../features/manifest/Manifest';
import Operator from '../features/operator/Operator';
import OperatorForm from '../features/operator/Operator.form';
import OperatorUpdate from '../features/operator/Operator.update';
import Plan from '../features/plan/Plan'
import RoutePlanIssues from '../features/routeplan/RoutePlan.issues';
import PlanNav from '../features/plan/Plan.nav';
import Profile from '../views/Profile';
import RoutePlan from '../features/routeplan/RoutePlan';
import RoutePlanForecast from '../features/routeplan/RoutePlan.forecast';
import RoutePlanForm from '../features/routeplan/RoutePlan.form';
import RoutePlanUpdate from '../features/routeplan/RoutePlan.update';
import RoadTest from '../features/form/roadtest/RoadTest'
import RoadTestForm from '../features/form/roadtest/RoadTest.form'
import RoadTestView from '../features/form/roadtest/RoadTest.view'
import Settings from '../features/settings/Settings'
import SpecialOp from '../features/specialop/SpecialOp';
import SpecialOpForm from '../features/specialop/SpecialOp.form';
import SpecialOpUpdate from '../features/specialop/SpecialOp.update';
import Timecard from '../features/timecard/Timecard';
import TimecardDashboard from '../features/timecard/Timecard.dashboard';
import TimecardExports from '../features/timecard/Timecard.exports';
import TimecardForm from '../features/timecard/Timecard.form';
import TimecardNav from '../features/timecard/Timecard.nav';
import TimecardReports from '../features/timecard/Timecard.reports';
import TimecardSettings from '../features/timecard/Timecard.settings';
import TimecardUpdate from '../features/timecard/Timecard.update';
import Trailer from '../features/trailer/Trailer';
import TrailerForm from '../features/trailer/Trailer.form';
import TrailerUpdate from '../features/trailer/Trailer.update';
import Vehicle from '../features/vehicle/Vehicle';
import VehicleForm from '../features/vehicle/Vehicle.form';
import VehicleUpdate from '../features/vehicle/Vehicle.update';
import FiftyFivehundredReports from '../features/ffh/FiftyFiveHundred.reports';
import FormNav from '../features/form/Form.nav';
import Forms from '../features/form/Forms';
import DailyReport from '../features/form/dailyreport/DailyReport';
import EmployeeReimbursement from '../features/form/employeereimbursement/EmployeeReimbursement';
import EmployeeReimbursementForm from '../features/form/employeereimbursement/EmployeeReimbursement.form';
import EmployeeReimbursementUpdate from '../features/form/employeereimbursement/EmployeeReimbursement.update';
import Incident from '../features/form/incident/Incident';
import PurchaseOrder from '../features/form/purchaseorder/PurchaseOrder';
import TravelRequest from '../features/form/travelrequest/TravelRequest';
import PTORequest from '../features/form/pto/PTORequest';
import PTORequestForm from '../features/form/pto/PTORequest.form';
import PTORequestUpdate from '../features/form/pto/PTORequest.update';
import PurchaseOrderForm from '../features/form/purchaseorder/PurchaseOrder.form';
import PurchaseOrderUpdate from '../features/form/purchaseorder/PurchaseOrder.update';
import TravelRequestForm from '../features/form/travelrequest/TravelRequest.form';
import TravelRequestUpdate from '../features/form/travelrequest/TravelRequest.update';
import ThirdPartyVehicle from '../features/form/thirdpartyvehicle/ThirdPartyVehicle';
import ThirdPartyVehicleForm from '../features/form/thirdpartyvehicle/ThirdPartyVehicle.form';
import ThirdPartyVehicleUpdate from '../features/form/thirdpartyvehicle/ThirdPartyVehicle.update';
import AccessNewUsers from '../features/access/Access.newusers';
import AccessNewUserForm from '../features/access/Access.newuser.form';
import AccessGroupForm from '../features/access/Access.group.form';
import AccessGroupUpdate from '../features/access/Access.group.update';
import Weather from '../features/weather/Weather';
import DispatchDashboard from '../features/dispatch/Dispatch.dashboard';
import Samsara from '../features/eld/Samsara';
import Notifcation from '../features/notification/Notification';
import NotifcationForm from '../features/notification/Notification.form';
import NotifcationDetail from '../features/notification/Notification.detail';
import Report from '../features/report/Report';
import ReportForm from '../features/report/Report.form';
import ReportUpdate from '../features/report/Report.update';
import ReportNav from '../features/report/Report.nav';
import DispatchBuilder from '../features/dispatch/Dispatch.builder';
import Help from '../views/Help';
import AccessRole from '../features/access/Access.role';
import AccessGroup from '../features/access/Access.group';
import AccessProfile from '../features/access/Access.profile';
import GeoTab from '../features/eld/GeoTab';
import TimecardProfile from '../features/timecard/Timecard.profile';
import TimecardInfo from '../features/timecard/Timecard.info';
import TimecardPayPeriods from '../features/timecard/Timecard.payperiods';
import IncidentManagementSystem from '../features/safety/IncidentManagementSystem';
import SafetyNav from '../features/safety/Safety.nav';
import Safety from '../features/safety/Safety';
import ManifestNav from '../features/manifest/Manifest.nav';
import ManifestForm from '../features/manifest/Manifest.form';
import DispatchOpen from '../features/dispatch/Dispatch.open';
import DispatchCalendar from '../features/dispatch/Dispatch.calendar';
import AccessStale from '../features/access/Access.stale';
import LoadTenderCalendar from '../features/loadtender/LoadTender.calendar';
import LoadTenderPay from '../features/loadtender/LoadTender.pay';
import LoadTenderIOT from '../features/loadtender/LoadTender.iot';
import LoadTenderLive from '../features/loadtender/LoadTender.live';
import LoadTenderDispatch from '../features/loadtender/LoadTender.dispatch';
import LoadTenderDashboardV2 from '../features/loadtender/LoadTender.dashboard.v2';
import USPSServicePointsDashboard from '../features/loadtender/USPSServicePointsDashboard';
import Billing from '../features/billing/Billing';
import LoadTenderWatch from '../features/loadtender/LoadTender.Watch';
import LoadTenderSearch from '../features/loadtender/LoadTender.Search';

const appRoutes = [

    { feature:'0) Billing', path: '/billing', name: 'Billing', detail: 'Billing Dashboard', element: <><Billing/></> },

    { feature:'1) Home', path: '/', name: 'Home', detail: 'Home page', element: <><Home /></> },
    { feature:'1) Home', path: '/profile', name: 'Profile', detail: 'User profile', element: <><Profile /></> },
    { feature:'1) Home', path: '/help', name: 'Help', detail: 'View useful "How To" information.', element: <><Help /></> },
    { feature:'1) Home', path: '/settings', name: 'Settings', detail: 'change app settings', element: <><Settings /></> },

    { feature:'2) Access', path: '/access', name: 'Access', detail: 'Control user access', element: <><AccessNav/><Access /></> },
    { feature:'2) Access', path: '/access/roles', name: 'Roles', detail: 'Roles grant access to features within the application.', element: <><AccessNav/><AccessRole /></> },
    { feature:'2) Access', path: '/access/groups', name: 'Groups', detail: 'Groups are used to categories sets of users.', element: <><AccessNav/><AccessGroup /></> },
    { feature:'2) Access', path: '/access/profiles', name: 'Profiles', detail: 'People with access to the application.', element: <><AccessNav/><AccessProfile /></> },
    { feature:'2) Access', path: '/access/new-users', name: 'New User Onboarding', detail: 'See a list of new users.', element: <><AccessNav/><AccessNewUsers /></> },
    { feature:'2) Access', path: '/access/stale-users', name: 'Stale Users', detail: 'See a list of stale users accounts.', element: <><AccessNav/><AccessStale /></> },
    { feature:'2) Access', path: '/access/group-form', name: 'Group Form', detail: 'New Group', element: <><AccessNav /><AccessGroupForm /></> },
    { feature:'2) Access', path: '/access/group/:id', name: 'Group', detail: 'Update Group', element: <><AccessNav /><AccessGroupUpdate /></> },
    { feature:'2) Access', path: '/access/new-user-form/:uid', name: 'Grant user access.', detail: 'Give new users a role.', element: <><AccessNewUserForm /></> },
    { feature:'2) Access', path: '/access/profile-form/:uid', name: 'Profile Form', detail: 'New User Profile', element: <><AccessNav /><AccessProfileForm /></> },
    { feature:'2) Access', path: '/access/profile/:id', name: 'Profile', detail: 'Update Profile', element: <><AccessNav /><AccessProfileUpdate /></> },
    { feature:'2) Access', path: '/access/role-form', name: 'Role Form', detail: 'New Role', element: <><AccessNav /><AccessRoleForm /></> },
    { feature:'2) Access', path: '/access/role/:id', name: 'Role', detail: 'Update Role', element: <><AccessNav /><AccessRoleUpdate /></> },

    { feature:'3) Plan', path: '/plan', name: 'Plan', detail: 'plan details', element: <><PlanNav /><Plan /></> },
    { feature:'3) Plan', path: '/plan/assignment', name: 'Assignments', detail: 'a table of assignments', element: <><PlanNav /><Assignment /></> },
    { feature:'3) Plan', path: '/plan/assignment-form', name: 'Assignment Form', detail: 'create a new assignment', element: <><PlanNav /><AssignmentForm /></> },
    { feature:'3) Plan', path: '/plan/assignment/:id', name: 'Assignment', detail: 'view/update assignments', element: <><PlanNav /><AssignmentUpdate /></> },
    { feature:'3) Plan', path: '/plan/contract', name: 'Contracts', detail: 'a table of contracts', element: <><PlanNav /><Contract /></> },
    { feature:'3) Plan', path: '/plan/contract-form', name: 'Contract Form', detail: 'create a contract', element: <><PlanNav /><ContractForm /></> },
    { feature:'3) Plan', path: '/plan/contract/:id', name: 'Contract', detail: 'view/update a contract', element: <><PlanNav /><ContractUpdate /></> },
    { feature:'3) Plan', path: '/plan/forecast', name: 'Forecast', detail: 'forecast all active routes ', element: <><PlanNav /><RoutePlanForecast /></> },
    { feature:'3) Plan', path: '/plan/frequency', name: 'Frequencies', detail: 'a table of frequencies ', element: <><PlanNav /><Frequency /></> },
    { feature:'3) Plan', path: '/plan/frequency-form', name: 'Frequency Form', detail: 'create a new freduency', element: <><PlanNav /><FrequencyForm /></> },
    { feature:'3) Plan', path: '/plan/frequency/:id', name: 'Frequency', detail: 'view/update a single frequency', element: <><PlanNav /><FrequencyUpdate /></> },
    { feature:'3) Plan', path: '/plan/location', name: 'Locations', detail: 'a table of locations', element: <><PlanNav /><Location /></> },
    { feature:'3) Plan', path: '/plan/location-form', name: 'Location Form', detail: 'create a new location', element: <><PlanNav /><LocationForm /></> },
    { feature:'3) Plan', path: '/plan/location/:id', name: 'Location', detail: 'view/update a single location', element: <><PlanNav /><LocationUpdate /></> },
    { feature:'3) Plan', path: '/plan/operator', name: 'Operators', detail: 'a table of operators', element: <><PlanNav /><Operator /></> },
    { feature:'3) Plan', path: '/plan/operator-form', name: 'Operator Form', detail: 'create a new operator', element: <><PlanNav /><OperatorForm /></> },
    { feature:'3) Plan', path: '/plan/operator/:id', name: 'Operator', detail: 'view/update a single operator', element: <><PlanNav /><OperatorUpdate /></> },
    { feature:'3) Plan', path: '/plan/routeplan', name: 'Routes', detail: 'a table of all routes', element: <><PlanNav /><RoutePlan /></> },
    { feature:'3) Plan', path: '/plan/routeplan-form', name: 'Route Form', detail: 'create a route', element: <><PlanNav /><RoutePlanForm /></> },
    { feature:'3) Plan', path: '/plan/routeplan/:id', name: 'Update Route', detail: 'view/update a route', element: <><PlanNav /><RoutePlanUpdate /></> },
    { feature:'3) Plan', path: '/plan/routeplan/:id/forecast', name: 'Forecast One', detail: 'forecast a single route', element: <><PlanNav /><RoutePlanForecast /></> },
    { feature:'3) Plan', path: '/plan/specialop', name: 'Special Ops', detail: 'a table of special operations', element: <><PlanNav /><SpecialOp /></> },
    { feature:'3) Plan', path: '/plan/specialop-form', name: 'Special Op Form', detail: 'create a new special operation', element: <><PlanNav /><SpecialOpForm /></> },
    { feature:'3) Plan', path: '/plan/specialop/:id', name: 'Special Op', detail: 'view/update a special operation', element: <><PlanNav /><SpecialOpUpdate /></> },
    { feature:'3) Plan', path: '/plan/trailer', name: 'Trailers', detail: 'a table of trailers', element: <><PlanNav /><Trailer /></> },
    { feature:'3) Plan', path: '/plan/trailer-form', name: 'Trailer Form', detail: 'create a new trailer', element: <><PlanNav /><TrailerForm /></> },
    { feature:'3) Plan', path: '/plan/trailer/:id', name: 'Trailer', detail: 'view/update a trailer', element: <><PlanNav /><TrailerUpdate /></> },
    { feature:'3) Plan', path: '/plan/vehicle', name: 'Vehicles', detail: 'a table of vehicles', element: <><PlanNav /><Vehicle /></> },
    { feature:'3) Plan', path: '/plan/vehicle-form', name: 'Vehicle Form', detail: 'create a new vehicle', element: <><PlanNav /><VehicleForm /></> },
    { feature:'3) Plan', path: '/plan/vehicle/:id', name: 'Vehicle', detail: 'view/update a vehicle', element: <><PlanNav /><VehicleUpdate /></> },
    { feature:'3) Plan', path: '/plan/issues', name: 'Issues', detail: 'See issues detected with Plan', element: <><PlanNav /><RoutePlanIssues/></> },

    { feature:'4) Dispatch', path: '/dispatch', name: 'Dispatch', detail: 'Active Transportation Managemenet', element: <><Dispatch /></> },
    { feature:'4) Dispatch', path: '/dispatch/calendar', name: 'Dispatch Calendar', detail: 'Dispatch Calendar', element: <><DispatchCalendar /></> },
    { feature:'4) Dispatch', path: '/dispatch/open', name: 'Open Dispatch', detail: 'Open Dispatch', element: <><DispatchOpen /></> },
    { feature:'4) Dispatch', path: '/dispatch/builder', name: 'Build Dispatch', detail: 'Build Dispatch Operations', element: <><DispatchNav /><DispatchBuilder/></> },
    { feature:'4) Dispatch', path: '/dispatch/dashboard', name: 'Dispatch Dashboard', detail: 'Dispatch overview', element: <><DispatchNav /><DispatchDashboard /></> },
    { feature:'4) Dispatch', path: '/dispatch/:id', name: 'Dispatch Record', detail: 'Update a dispatch', element: <><DispatchNav /><DispatchUpdate /></> },
    { feature:'4) Dispatch', path: '/dispatch/detail/:id', name: 'Dispatch Detail', detail: 'View a dispatch', element: <><DispatchNav /><DispatchDetail /></> },
    { feature:'4) Dispatch', path: '/dispatch-form', name: 'Dispatch Form', detail: 'New dispatch', element: <><DispatchNav /><DispatchForm /></> },
    { feature:'4) Dispatch', path: '/dispatch/reports', name: 'Dispatch Reports', detail: 'See dispatch reports', element: <><DispatchNav /><DispatchReports /></> },
    { feature:'4) Dispatch', path: '/dispatch/settings', name: 'Dispatch Settings', detail: 'Dispatch settings', element: <><DispatchNav /><DispatchSettings /></> },

    { feature:'5) Telematics', path: '/iot', name: 'IoT', detail: 'Internet of Things connections', element: <><IOT /></> },
    { feature:'5) Telematics', path: '/eld/samsara', name: 'Samsara ELD Provider', detail: 'Samsara ELD', element: <><Samsara/></> },
    { feature:'5) Telematics', path: '/eld/geotab', name: 'Geotab ELD Provider', detail: 'Geotab ELD', element: <><GeoTab/></> },
    { feature:'5) Telematics', path: '/edi/kleinschmidt', name: 'Kleinschmidt EDI', detail: 'Kleinschmidt EDI', element: <><Weather/></> },
    { feature:'5) Telematics', path: '/fourkites', name: 'Four Kites', detail: 'Four Kites', element: <><LoadEye /></> },
    { feature:'5) Telematics', path: '/loadeye', name: 'Loadeye', detail: 'Loadeye trailer tracking prototype.', element: <><LoadEye /></> },
    { feature:'5) Telematics', path: '/fuel', name: 'Fuel', detail: 'See EIA fuel data.', element: <><Fuel /></> },
    { feature:'5) Telematics', path: '/weather', name: 'Weather', detail: 'View Weather', element: <><Weather/></> },
    
    { feature:'6) Load Tender', path: '/loadtender', name: 'Load Tender Information', detail: 'load tender details', element: <><LoadTenderNav /><LoadTender /></> },
    { feature:'6) Load Tender', path: '/loadtender/:id', name: 'Load Tender', detail: 'view a load tender', element: <><LoadTenderNav /><LoadTenderUpdate /></> },
    { feature:'6) Load Tender', path: '/loadtender/calendar', name: 'Load Tender Calendar', detail: 'Easily navigate, sort, and filter Load Tenders on a calendar.', element: <><LoadTenderNav /><LoadTenderCalendar /></> },
    { feature:'6) Load Tender', path: '/loadtender/iot', name: 'Load Tender Connections', detail: 'Load Tender IoT Connections', element: <><LoadTenderNav /><LoadTenderIOT /></> },
    { feature:'6) Load Tender', path: '/loadtender/pay', name: 'Load Tender Pay', detail: 'load tender payment info', element: <><LoadTenderNav /><LoadTenderPay /></> },
    { feature:'6) Load Tender', path: '/loadtender/dashboard', name: 'Load Tender Dashboard', detail: 'load tender analytics', element: <><LoadTenderNav /><LoadTenderDashboard /></> },
    { feature:'6) Load Tender', path: '/loadtender/watch', name: 'Load Tender Watch', detail: 'Watch Live Shipments', element: <><LoadTenderNav /><LoadTenderWatch /></> },
    { feature:'6) Load Tender', path: '/loadtender/search', name: 'Load Tender Search', detail: 'Search for a load tender based on Shipment ID', element: <><LoadTenderNav /><LoadTenderSearch /></> },
    { feature:'6) Load Tender', path: '/loadtender/dispatch', name: 'Load Tender Dispatch', detail: 'Combine load tenders into dispatch', element: <><LoadTenderNav /><LoadTenderDispatch /></> },
    { feature:'6) Load Tender', path: '/loadtender/dashboard/v2', name: 'Load Tender Dispatch V2', detail: 'Combine load tenders into dispatch', element: <><LoadTenderDashboardV2 /></> },
    { feature:'6) Load Tender', path: '/loadtender/live', name: 'Load Tenders Live', detail: 'live look at load tenders', element: <><LoadTenderNav /><LoadTenderLive /></> },
    { feature:'6) Load Tender', path: '/loadtender/active', name: 'Active Load Tenders', detail: 'a table of active load tenders', element: <><LoadTenderNav /><LoadTenderActive /></> },
    { feature:'6) Load Tender', path: '/loadtender/requests', name: 'Load Tender Requests', detail: 'a table of load tender requests that need responses', element: <><LoadTenderNav /><LoadTenderRequests /></> },
    { feature:'6) Load Tender', path: '/loadtender/rejected', name: 'Rejected Load Tenders', detail: 'a table of rejected load tenders', element: <><LoadTenderNav /><LoadTenderRejected /></> },
    { feature:'6) Load Tender', path: '/loadtender/completed', name: 'Completed Load Tenders', detail: 'a table of completed load tenders', element: <><LoadTenderNav /><LoadTenderCompleted /></> },
    { feature:'6) Load Tender', path: '/loadtender/response/:id', name: 'Load Tender Repsponses', detail: 'respond to a load tender request', element: <><LoadTenderNav /><LoadTenderResponse /></> },
    { feature:'6) Load Tender', path: '/loadtender/update/:id', name: 'Update Load Tender', detail: 'update a load tender', element: <><LoadTenderNav /><LoadTenderUpdate /></> },
    { feature:'6) Load Tender', path: '/loadtender/view/:id', name: 'View Load Tender', detail: 'view load tender', element: <><LoadTenderNav /><LoadTenderView /></> },
    { feature:'6) Load Tender', path: '/loadtender/usps-service-points', name: 'USPS Service Points Dashboard', detail: 'view USPS services points dashboard', element: <><USPSServicePointsDashboard /></> },



    { feature:'7) Manifest', path: '/manifest', name: 'Manifests', detail: 'usps manifest data', element: <><ManifestNav/><Manifest /></> },
    { feature:'7) Manifest', path: '/manifest-form', name: 'Upload Manifests', detail: 'Upload USPS Manifest', element: <><ManifestNav/><ManifestForm /></> },
    
    { feature:'8) 5500', path: '/ffh', name: '5500', detail: 'USPS 5500 Info', element: <><FiftyFiveHundredNav /><FiftyFiveHundredInfo /></> },
    { feature:'8) 5500', path: '/ffh/table', name: '5500 Table', detail: 'USPS 5500 record table', element: <><FiftyFiveHundredNav /><FiftyFiveHundredTable /></> },
    { feature:'8) 5500', path: '/ffh/reports', name: '5500 Reports', detail: 'USPS 5500 Report', element: <><FiftyFiveHundredNav /><FiftyFivehundredReports /></> },
    { feature:'8) 5500', path: '/ffh/upload', name: '5500 Upload', detail: 'Upload USPS 5500 records', element: <><FiftyFiveHundredNav /><FiftyFivehundredUpload /></> },
    
    { feature:'9) HCR', path: '/hcr', name: 'HCRs', detail: 'Highway Contract Routes', element: <><HCR /></> },

    { feature:'10) Safety', path: '/safety', name: 'Safety', detail: 'Safety', element: <><SafetyNav /><Safety /></> },
    { feature:'10) Safety', path: '/safety/incidentmanagement', name: 'Incident Management', detail: 'Incident Management System', element: <><SafetyNav /><IncidentManagementSystem /></> },

    { feature:'11) Timecard', path: '/timecard/info', name: 'Timecard Info', detail: 'a detail page explaining the timecard feature', element: <><TimecardNav /><TimecardInfo /></> },
    { feature:'11) Timecard', path: '/timecard', name: 'Timecards', detail: 'a list of timecards', element: <><TimecardNav /><Timecard /></> },
    { feature:'11) Timecard', path: '/timecard/profile', name: 'Profile Timecards ', detail: 'Individual user timecard list', element: <><TimecardNav /><TimecardProfile /></> },
    { feature:'11) Timecard', path: '/timecards/:id', name: 'Timecard', detail: 'view/update a timecard', element: <><TimecardNav /><TimecardUpdate /></> },
    { feature:'11) Timecard', path: '/timecard-form', name: 'Timecard Form', detail: 'create a new timecard.', element: <><TimecardNav /><TimecardForm /></> },
    { feature:'11) Timecard', path: '/timecard/dashboard', name: 'Timecard Dashboard', detail: 'dashboard of analytics and costs', element: <><TimecardNav /><TimecardDashboard /></> },
    { feature:'11) Timecard', path: '/timecard/exports', name: 'Timecard Exports', detail: 'export data to various file formates', element: <><TimecardNav /><TimecardExports /></> },
    { feature:'11) Timecard', path: '/timecard/payperiods', name: 'Timecard Pay Periods', detail: 'set pay periods', element: <><TimecardNav /><TimecardPayPeriods /></> },
    { feature:'11) Timecard', path: '/timecard/reports', name: 'Timecard Reports', detail: 'create reports', element: <><TimecardNav /><TimecardReports /></> },
    { feature:'11) Timecard', path: '/timecard/settings', name: 'Timecard Settings', detail: 'view/update timecard feature settings', element: <><TimecardNav /><TimecardSettings /></> },
    { feature:'11) Timecard', path: '/timecard/:uid/:periodid', name: 'Users Pay Period Timecards', detail: 'view/update a pay periods timecards', element: <><TimecardNav /><TimecardForm /></> },

    { feature:'12) Notification', path: '/notification', name: 'Notifications', detail: 'change notification settings', element: <><Notifcation/></> },
    { feature:'12) Notification', path: '/notification-form', name: 'Notification Form', detail: 'Send app notification', element: <><NotifcationForm/></> },
    { feature:'12) Notification', path: '/notification/:id', name: 'Notification View', detail: 'View an App Notifcation', element: <><NotifcationDetail/></> },
    
    { feature:'13) Reports', path: '/report', name: 'Reports', detail: 'View a list of reports', element: <><ReportNav/><Report/></> },
    { feature:'13) Reports', path: '/report-form', name: 'Report Form', detail: 'Send app report', element: <><ReportNav/><ReportForm/></> },
    { feature:'13) Reports', path: '/report/:id', name: 'Report Update', detail: 'Update a report', element: <><ReportNav/><ReportUpdate/></> },

    { feature:'14) Forms', path: '/forms', name: 'Forms', detail: 'Form Details', element: <><FormNav /><Forms /></> },
    { feature:'14) Forms', path: '/form/accident', name: 'Accidents', detail: 'Accident Form Table', element: <><FormNav /><Accident /></> },
    { feature:'14) Forms', path: '/form/accident-form', name: 'Accident Form', detail: 'New Accident Form', element: <><FormNav /><AccidentForm /></> },
    { feature:'14) Forms', path: '/form/accident/:id', name: 'Accident Report', detail: 'View Accident Form', element: <><FormNav /><AccidentUpdate /></> },
    { feature:'14) Forms', path: '/form/dailyreport', name: 'Daily Reports', detail: 'Daily Report Form Table', element: <><FormNav /><DailyReport /></> },
    { feature:'14) Forms', path: '/form/dailyreport-form', name: 'Daily Report Form', detail: 'New Daily Report Form', element: <><FormNav /><DailyReportForm /></> },
    { feature:'14) Forms', path: '/form/dailyreport/:id', name: 'Daily Report', detail: 'View Daily Report Form', element: <><FormNav /><DailyReportUpdate /></> },
    { feature:'14) Forms', path: '/form/employeereimbursement', name: 'Emp Reimbursements', detail: 'Employee Reimbursements', element: <><FormNav /><EmployeeReimbursement /></> },
    { feature:'14) Forms', path: '/form/employeereimbursement-form', name: 'Emp Reimbursement Form', detail: 'Employee Reimbursement Form', element: <><FormNav /><EmployeeReimbursementForm /></> },
    { feature:'14) Forms', path: '/form/employeereimbursement/:id', name: 'Emp Reimbursement', detail: 'Employee Reimbursement', element: <><FormNav /><EmployeeReimbursementUpdate /></> },
    { feature:'14) Forms', path: '/form/incident', name: 'Incidents', detail: 'Incident Table', element: <><FormNav /><Incident /></> },
    { feature:'14) Forms', path: '/form/incident-form', name: 'Incident Form', detail: 'New Incident Form', element: <><FormNav /><IncidentForm /></> },
    { feature:'14) Forms', path: '/form/incident/:id', name: 'Incident', detail: 'View Incident', element: <><FormNav /><IncidentUpdate /></> },
    { feature:'14) Forms', path: '/form/pto', name: 'PTO', detail: 'View PTO Table', element: <><FormNav /><PTORequest /></> },
    { feature:'14) Forms', path: '/form/pto-form', name: 'PTO Form', detail: 'Create PTO Form', element: <><FormNav /><PTORequestForm /></> },
    { feature:'14) Forms', path: '/form/pto/:id', name: 'PTO Record', detail: 'View PTO Form', element: <><FormNav /><PTORequestUpdate /></> },
    { feature:'14) Forms', path: '/form/purchaseorder', name: 'Purchase Orders', detail: 'Purchase Order Table', element: <><FormNav /><PurchaseOrder /></> },
    { feature:'14) Forms', path: '/form/purchaseorder-form', name: 'Purchase Order Form', detail: 'Create Purchase Order', element: <><FormNav /><PurchaseOrderForm /></> },
    { feature:'14) Forms', path: '/form/purchaseorder/:id', name: 'Purchase Order', detail: 'View Purchase Order', element: <><FormNav /><PurchaseOrderUpdate /></> },
    { feature:'14) Forms', path: '/form/roadtest', name: 'Road Tests', detail: 'Road Test Table', element: <><FormNav /><RoadTest/></> },
    { feature:'14) Forms', path: '/form/roadtest-form', name: 'Road Test Form', detail: 'Create Road Test', element: <><FormNav /><RoadTestForm/></> },
    { feature:'14) Forms', path: '/form/roadtest/:id', name: 'Road Test', detail: 'View Road Test', element: <><FormNav /><RoadTestView/></> },
    { feature:'14) Forms', path: '/form/travelrequest', name: 'Travel Requests', detail: 'Travel Request Table', element: <><FormNav /><TravelRequest /></> },
    { feature:'14) Forms', path: '/form/travelrequest-form', name: 'Travel Request Form', detail: 'New Travel Request', element: <><FormNav /><TravelRequestForm /></> },
    { feature:'14) Forms', path: '/form/travelrequest/:id', name: 'Travel Request', detail: 'View Travel Request', element: <><FormNav /><TravelRequestUpdate /></> },
    { feature:'14) Forms', path: '/form/thirdpartyvehicle', name: 'Third Party Vehicles', detail: 'Third Party Vehicles', element: <><FormNav /><ThirdPartyVehicle /></> },
    { feature:'14) Forms', path: '/form/thirdpartyvehicle-form', name: 'Check out a vehicle', detail: 'Check out a vehicle from a 3rd party', element: <><FormNav /><ThirdPartyVehicleForm /></> },
    { feature:'14) Forms', path: '/form/thirdpartyvehicle/:id', name: 'Check in a vehicle', detail: 'Check a vehicle back into a 3rd party', element: <><FormNav /><ThirdPartyVehicleUpdate /></> },

];


export default appRoutes;