import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col, Button, Form, Alert, Spinner, Accordion, Figure, Image, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from "../context/auth";
import Api from "../utils/Api";
// import AVATAR_IMG from '../assets/default/avatar.png';
import DataDisplay from "../components/DataDisplay";

function Profile() {
    const { user, idToken } = useAuth();
    const [load, didLoad] = useState();
    const [profile, setProfile] = useState();
    const [alerts, setAlerts] = useState([]);
    //
    // const [accidents, setAccidents] = useState();
    const [dailyreports, setDailyReports] = useState();
    const [employeereimbursement, setEmployeeReimbursement] = useState();
    const [ptorequests, setPTORequests] = useState();
    // const [profileImg, setProfileImg] = useState();

    // async function updateAvatarImg(event) {
    //     const file = event.target.files[0]; // Get the selected file from the input element

    //     if (file) {
    //         // Check if the file size is within the limit (2.5MB)
    //         const maxSizeInBytes = 1 * 1024 * 1024; // 1MB in bytes
    //         if (file.size > maxSizeInBytes) {
    //             setAlerts([...alerts, { variant: 'warning', message: 'The image file is to big, file should be less than 1 MB in size.' }])
    //             return;
    //         }

    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             const blob = new Blob([reader.result], { type: file.type });
    //             setProfileImg(blob);
    //         };
    //         reader.readAsArrayBuffer(file);
    //     } else {
    //         setAlerts([...alerts, { variant: 'warning', message: 'Select a file.' }])
    //     }
    // }

    async function handleUpdate() {
        try {
            const result = await Api.patch(`/access/profile/${profile._id}`, 
                { ...profile, 
                    email: user.email, 
                    // profileImg: profileImg 
            }, idToken)
            setAlerts([...alerts, { variant: 'success', message: JSON.stringify(result) }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: JSON.stringify(error) }])
        }
    }



    useEffect(() => {
        try {
            const fetchData = async () => {
                const data = await Api.get(`/access/profile/uid/${user.uid}`, idToken);
                setProfile(data)
                didLoad(true)
            }

            const fetchProfileFormData = async () => {
                // const formAccidentReport = await Api.query(`/form/accident`, { email: user.email }, idToken);
                // // const cleanAccidentReport = formAccidentReport.map(accidentreport => {
                // //     return {
                // //         'Date': `${accidentreport.date} `,
                // //     }
                // // }) ?? [];
                // // setAccidents(cleanAccidentReport)
        
        
                const formDailyReports = await Api.query(`/form/dailyreport`, { 'email': user.email }, idToken);
                const cleanDailyReports = formDailyReports.map(dailyreport => {
                    return {
                        'Date': `${dailyreport.date} `,
                        'Name': `${dailyreport.firstname} ${dailyreport.lastname}, ${dailyreport.email}`,
                        'HCRs': `${dailyreport.hcrs} `,
                        'Schedule': `${dailyreport.schedule}`,
                        'Personnel': `${dailyreport.personnel} `,
                        'Equipment': `${dailyreport.equipment} `,
                        'Extra Service': `${dailyreport.extraservice} `,
                        'USPS Communication': `${dailyreport.extraservice} `,
                    }
                })
                setDailyReports(cleanDailyReports)
        
        
        
                const formEmployeReimbursement = await Api.query(`/form/employeereimbursement`, { email: user.email }, idToken);
                const cleanEmployeReimbursement = formEmployeReimbursement.map(reimbursement => {
                    return {
                        'Status': reimbursement.status,
                        'Purchased': reimbursement.purchasedate,
                        'Amount': reimbursement.amount,
                        'Reason': reimbursement.reason,
                        'Name': `${reimbursement.firstname} ${reimbursement.lastnamename}`,
                        'Address': `${reimbursement.address1} ${reimbursement.address2} ${reimbursement.city} ${reimbursement.state} ${reimbursement.zipcode} `,
                        'Email': reimbursement.email,
                        'Phone': reimbursement.phone,
                    }
                })
                setEmployeeReimbursement(cleanEmployeReimbursement)
        
        
                const formPTORequests = await Api.query(`/form/pto`, { email: user.email }, idToken);
                const cleanPTO = formPTORequests.map(record => {
                    return {
                        'Status': record.status,
                        'Response': record.response,
                        'Manager': record.managerfirstname ? `${record.managerfirstname} ${record.managerlastname}, ${record.manageremail}` : '',
                        'Requestee': `${record.firstname} ${record.lastname}, ${record.email}`,
                        'Date': `${record.requestdate}`,
                        'Type': record.type,
                        'First Day': record.firstdayoff,
                        'Last Day': record.lastdayoff,
                        'Return to Work': record.returndate,
                        'Days Off': record.daysrequested,
                        'Hours': record.hoursrequested,
                        'Notes': record.notes,
                    }
                })
                setPTORequests(cleanPTO)
            }
            if(!load && user){
                fetchData();
                fetchProfileFormData();
            }
        } catch (error) {
            setAlerts([...alerts, {variant:'warning',message:'Request error'}])
        }
    },[user, load, alerts, idToken])

    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header><b>Profile</b></Card.Header>
                {alerts.length > 0 &&
                    <Card.Body>
                        {alerts.map((alrt, i) => (
                            <Row key={i}>
                                <Col>
                                    <Alert variant={alrt.variant} dismissible onClick={() => setAlerts([])}>{JSON.stringify(alrt.message)}</Alert>
                                </Col>
                            </Row>
                        ))}
                    </Card.Body>
                }
                <Card.Body>
                    {load ?
                        <>
                            <Row>
                                {/* <Col sm={12} md={2} style={{ textAlign: 'center' }}>
                                    Profile Image
                                    <Form.Control type="file" accept="image/*" onChange={updateAvatarImg} />
                                    <Figure>
                                            <Image
                                                src={profileImg ? URL.createObjectURL(profileImg) : AVATAR_IMG}
                                                alt={'Profile Image'}
                                                style={{ height: '200px', margin: '0.5em' }}
                                                fit="contain"
                                                thumbnail
                                            />
                                    </Figure>
                                </Col> */}
                                <Col sm={12} md={10}>
                                    <Row>

                                        <Col sm={12} md={6} lg={3}>
                                            Email
                                            <Form.Control
                                                type="text"
                                                value={user.email}
                                                readOnly={true}
                                                disabled
                                            />
                                        </Col>
                                        <Col sm={12} md={6} lg={3}>
                                            First Name
                                            <Form.Control
                                                type="text"
                                                value={profile.firstname}
                                                onChange={e => setProfile({ ...profile, firstname: e.target.value })}
                                            />
                                        </Col>
                                        <Col sm={12} md={6} lg={3}>
                                            Last Name
                                            <Form.Control
                                                type="text"
                                                value={profile.lastname}
                                                onChange={e => setProfile({ ...profile, lastname: e.target.value })}
                                            />
                                        </Col>
                                        <Col sm={2} md={2} lg={2}>
                                            <br />
                                            <Button variant="primary" onClick={() => handleUpdate()}>Update</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                        :
                        <Spinner />
                    }

                </Card.Body>
            </Card>
            {/* <br/> */}
            {/* <Accordion>
                <Accordion.Item eventKey="0" >
                    <Accordion.Header>Notifications x </Accordion.Header>
                    <Accordion.Body>
                       {accidents 
                            ? <DataCard dataSource={accidents} lsKey={'@mctms-profile-notification-data'} />
                            : 'No data.'
                        } 
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" >
                    <Accordion.Header>Reports x </Accordion.Header>
                    <Accordion.Body>
                         {accidents 
                            ? <DataCard dataSource={accidents} lsKey={'@mctms-profile-notification-data'} />
                            : 'No data.'
                        } 
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion> */}
            <br />
            <Accordion>
                {/* <Accordion.Item eventKey="0" >
                    <Accordion.Header>Accident Reports x {accidents ? accidents.length : '#'}</Accordion.Header>
                    <Accordion.Body>
                        {accidents ? <DataCard dataSource={accidents} lsKey={'@mctms-accidents-profile-data'} />
                        : 'No data.'
                        }
                    </Accordion.Body>
                </Accordion.Item> */}
                <Accordion.Item eventKey="1" >
                    <Accordion.Header>Daily Reports x {dailyreports ? dailyreports.length : '#'}</Accordion.Header>
                    <Accordion.Body>
                        {dailyreports ?
                            <DataDisplay
                                dataSource={dailyreports}
                                lsKey={'@mctms-dailyreports-profile-display'}
                            /> : 'No data.'}
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Employee Reimbursement x {employeereimbursement ? employeereimbursement.length : '#'}</Accordion.Header>
                    <Accordion.Body>
                        {employeereimbursement ?
                            <DataDisplay
                                dataSource={employeereimbursement}
                                lsKey={'@mctms-employeereimbursement-profile-display'}
                            /> : 'No data.'}
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3a">
                    <Accordion.Header>PTO Requests x {ptorequests ? ptorequests.length : '#'}</Accordion.Header>
                    <Accordion.Body>
                        {ptorequests ?
                            <DataDisplay
                                dataSource={ptorequests}
                                lsKey={'@mctms-ptorequests-profile-display'}
                            /> : 'No data.'}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <br />
        </Container>
    );
}

export default Profile;