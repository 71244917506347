import React, { useEffect, useState } from "react";
import { Container, Card, Button, Spinner, Row, Col, Image } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from '../../context/auth';
import Api from '../../utils/Api'
import AlertDisplay from '../../components/AlertDisplay'
import DataDisplay from "../../components/DataDisplay";
import { multidimensionalGroupBy } from "../../utils/Array.helper";
import { FaPrint, FaSync } from "react-icons/fa";
import { CONTACT_SERVICE_TYPES } from '../../context/appdata'
import LOGO from '../../assets/logo.png'
import { getTimezoneCode } from '../../utils/Clock'
import dispatchReports from './Dispatch.reports.json'
import { Chart } from 'react-google-charts';
import CalendarChart from "../../components/CalendarChart";

const today = new Date();  // today based on the clock
const tomorrow = new Date(today); // based on today value
const yesterday = new Date(today); // based on today value
const minusSeven = new Date(today); // minus 7 days
const minusFourteen = new Date(today); // minus 14 days
const minusThirty = new Date(today); // minus 30 days
const plusSeven = new Date(today); // based on today value, plus 7 days
const plusFourteen = new Date(today); // based on today value, plus 14 days
const plusThirty = new Date(today); // based on today value, plus 30 days
const lastSunday = new Date(today); // based on today value, the last week's Sunday
const lastSaturday = new Date(today);// based on today value, the last week's Saturday
const thisSunday = new Date(today); // based on today value, the current week's Sunday
const thisSaturday = new Date(today);// based on today value, the current week's Saturday
const nextSunday = new Date(today);// based on today value, the next week's Sunday
const nextSaturday = new Date(today);// based on today value, the next week's Saturday
const newyears = new Date(today); // current calendar's first day of the year
const newyearseve = new Date(today); // current calendar's last day of the year
const currentYear = today.getFullYear();
const currentMonth = today.getMonth();
const nextMonth = new Date(today);
nextMonth.setMonth(currentMonth + 1);
const firstdayofthemonth = new Date(currentYear, currentMonth, 1);
const lastdayofthemonth = new Date(currentYear, currentMonth + 1, 0);
const firstdayoflastmonth = new Date(currentYear, currentMonth - 1, 1);
const lastdayoflastmonth = new Date(currentYear, currentMonth, 0);
const firstdayofnextmonth = new Date(currentYear, currentMonth + 1, 1);
const lastDayOfNextMonth = new Date(nextMonth);
lastDayOfNextMonth.setMonth(nextMonth.getMonth() + 1, 0);

tomorrow.setDate(today.getDate() + 1);
yesterday.setDate(today.getDate() - 1);
minusSeven.setDate(today.getDate() - 7);
minusFourteen.setDate(today.getDate() - 14);
minusThirty.setDate(today.getDate() - 30);
plusSeven.setDate(today.getDate() + 7);
plusFourteen.setDate(today.getDate() + 14);
plusThirty.setDate(today.getDate() + 30);


// Calculate the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
const currentDayOfWeek = today.getDay();

// Calculate the date for the last week's Sunday and Saturday
lastSunday.setDate(today.getDate() - (currentDayOfWeek + 7));
lastSaturday.setDate(today.getDate() - (currentDayOfWeek + 1));

// Calculate the date for the current week's Sunday and Saturday
thisSunday.setDate(today.getDate() - currentDayOfWeek);
thisSaturday.setDate(today.getDate() + (6 - currentDayOfWeek));

// Calculate the date for the next week's Sunday and Saturday
nextSunday.setDate(today.getDate() + (7 - currentDayOfWeek));
nextSaturday.setDate(today.getDate() + (13 - currentDayOfWeek));

// Calculate the first day and last day of the current year
newyears.setMonth(0); // January (0 index)
newyears.setDate(1);

newyearseve.setMonth(11); // December (0 index)
newyearseve.setDate(31);

function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    return `${year}-${month}-${day}`;
}

const initialQuery = {
    start: formatDate(today),
    end: formatDate(today),
    servicetype: 'All',
    locationId: 'All',
    assignmentId: 'All',
    contractId: 'All',
}

const initialReport = dispatchReports.find(rpt => rpt.name === 'today');

function DispatchDashboard() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [load, shouldLoad] = useState();
    const [reset, shouldReset] = useState();
    const [period, setPeriod] = useState('today');
    const [report, setReport] = useState(initialReport);
    const [query, setQuery] = useState(initialQuery);
    const [domiciles, setDomiciles] = useState();
    const [assignments, setAssignments] = useState();
    const [contracts, setContracts] = useState();
    const [reportdata, setReportData] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts };

    function objectToQueryString(obj) {
        const params = new URLSearchParams();
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                params.append(key, obj[key]);
            }
        }
        return params.toString();
    }
    function setQueryStart(value) {
        if (value <= query.end) {
            setPeriod()
            setQuery({ ...query, start: value })
            shouldLoad(true)
        } else {
            setAlerts([...alerts, { variant: 'warning', message: 'Start should come before end.' }]);
        }
    }
    function setQueryEnd(value) {
        if (value >= query.start) {
            setPeriod()
            setQuery({ ...query, end: value })
            shouldLoad(true)
        } else {
            setAlerts([...alerts, { variant: 'warning', message: 'End should come after start.' }]);
        }
    }
    function setQueryAssignment(value) {
        setQuery({ ...query, assignmentId: value })
        shouldLoad(true)
    }
    function setQueryDomicle(value) {
        setQuery({ ...query, locationId: value })
        shouldLoad(true)
    }
    function setQueryContract(value) {
        setQuery({ ...query, contractId: value })
        shouldLoad(true)
    }
    function setQueryService(value) {
        setQuery({ ...query, servicetype: value })
        shouldLoad(true)
    }
    function setQueryToday() {
        setQuery({ ...query, start: formatDate(today), end: formatDate(today) })
        setPeriod('today')
        shouldLoad(true)
    }
    function setQueryTomorrow() {
        setQuery({ ...query, start: formatDate(tomorrow), end: formatDate(tomorrow) })
        setPeriod('tomorrow')
        shouldLoad(true)
    }
    function setQueryYeserday() {
        setQuery({ ...query, start: formatDate(yesterday), end: formatDate(yesterday) })
        setPeriod('yesterday')
        shouldLoad(true)
    }
    function setThisWeek() {
        setQuery({ ...query, start: formatDate(thisSunday), end: formatDate(thisSaturday) })
        setPeriod('thisweek')
        shouldLoad(true)
    }
    function setLastWeek() {
        setQuery({ ...query, start: formatDate(lastSunday), end: formatDate(lastSaturday) })
        setPeriod('lastweek')
        shouldLoad(true)
    }
    function setNextWeek() {
        setQuery({ ...query, start: formatDate(nextSunday), end: formatDate(nextSaturday) })
        setPeriod('nextweek')
        shouldLoad(true)
    }
    function setThisMonth() {
        setQuery({ ...query, start: formatDate(firstdayofthemonth), end: formatDate(lastdayofthemonth) })
        setPeriod('thismonth')
        shouldLoad(true)
    }
    function setLastMonth() {
        setQuery({ ...query, start: formatDate(firstdayoflastmonth), end: formatDate(lastdayoflastmonth) })
        setPeriod('lastmonth')
        shouldLoad(true)
    }
    function setNextMonth() {
        setQuery({ ...query, start: formatDate(firstdayofnextmonth), end: formatDate(lastDayOfNextMonth) })
        setPeriod('nextmonth')
        shouldLoad(true)
    }
    function setQueryMinus7() {
        setQuery({ ...query, start: formatDate(minusSeven), end: formatDate(today) })
        setPeriod('minus7')
        shouldLoad(true)
    }
    function setQueryMinus14() {
        setQuery({ ...query, start: formatDate(minusFourteen), end: formatDate(today) })
        setPeriod('minus14')
        shouldLoad(true)
    }
    function setQueryMinus30() {
        setQuery({ ...query, start: formatDate(minusThirty), end: formatDate(today) })
        setPeriod('minus30')
        shouldLoad(true)
    }
    function setQueryPlus7() {
        setQuery({ ...query, start: formatDate(today), end: formatDate(plusSeven) })
        setPeriod('plus7')
        shouldLoad(true)
    }
    function setQueryPlus14() {
        setQuery({ ...query, start: formatDate(today), end: formatDate(plusFourteen) })
        setPeriod('plus14')
        shouldLoad(true)
    }
    function setQueryPlus30() {
        setQuery({ ...query, start: formatDate(today), end: formatDate(plusThirty) })
        setPeriod('plus30')
        shouldLoad(true)
    }
    function setQueryYTD() {
        setQuery({ ...query, start: formatDate(newyears), end: formatDate(today) })
        setPeriod('ytd')
        shouldLoad(true)
    }
    function updateReport(rpt) {
        setReport(rpt)
        shouldLoad(true)
    }

    const labelStyle = {
        fontSize: '.9em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        whiteSpace: 'nowrap',
    }

    const resetStyle = {
        fontSize: '.75em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        backgroundColor: '#fff5bd',
        border: '1px solid',
        borderColor: '#222',
        borderRadius: '0.25em',
        whiteSpace: 'nowrap',
        float: 'right'
    }

    const reloadStyle = {
        fontSize: '.75em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        backgroundColor: '#bcdbbc',
        border: '1px solid',
        borderColor: '#222',
        borderRadius: '0.25em',
        whiteSpace: 'nowrap',
        float: 'left'
    }

    const printStyle = {
        fontSize: '.75em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        backgroundColor: '#95add5',
        border: '1px solid',
        borderColor: '#222',
        borderRadius: '0.25em',
        whiteSpace: 'nowrap',
        float: 'left'
    }

    const buttonStyle = {
        fontSize: '.8em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        backgroundColor: '#fff',
        border: '1px solid',
        borderColor: '#222',
        borderRadius: '0.25em',
        whiteSpace: 'nowrap'
    }

    const buttonSelectedStyle = {
        fontSize: '.8em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        backgroundColor: '#6eb6ff',
        border: '1px solid',
        borderColor: '#222',
        borderRadius: '0.25em',
        whiteSpace: 'nowrap'
    }

    function cleanDispatchData(data) {
        return data.map(dispatch => {
            return {
                _id: dispatch?._id,
                'Status': dispatch.status,
                'Service Date': dispatch?.servicedate,
                'Route': dispatch?.routeplan?.name,
                'Contract': dispatch?.routeplan?.contract?.name,
                'Start': `${dispatch?.routeplan?.stops[0].arrive} ${getTimezoneCode(dispatch?.routeplan?.stops[0].timezone)}`,
                'End': `${dispatch?.routeplan?.stops[dispatch?.routeplan?.stops.length - 1].depart} ${getTimezoneCode(dispatch?.routeplan?.stops[dispatch?.routeplan?.stops.length - 1].timezone)}`,
                'Operations': `${dispatch?.operations.map(stp => (`${stp?.trip} | A: ${stp?.arrive} | D: ${stp?.depart} | ${getTimezoneCode(stp?.timezone)} | ${stp?.location?.name} `)).join('\n')}`,
                'Service Type': dispatch?.servicetype,
                'Service Class': dispatch?.routeplan?.serviceclass,
                'Assignment': dispatch?.routeplan?.assignment?.name,
                'Domicile': dispatch?.routeplan?.domicile?.name,
                'Crew': dispatch?.routeplan?.crew,
                'Coverage': `${dispatch?.coverage.map((driver) => driver.firstname + ' ' + driver.lastname).join(',\n')}`,
            };
        });
    }

    function cleanTelematicsData(data) {
        return data.map(telematics => {
            return {
                _id: telematics?.dispatch?._id,
                'Status': telematics?.dispatch?.status,
                'Service Date': telematics?.dispatch?.servicedate,
                'Route': telematics?.dispatch?.routeplan?.name,
                'Stops': <>{
                    telematics?.dispatch?.operations.map(stp => (<div style={{ whiteSpace: 'nowrap', fontFamily: 'monospace' }}> A: {stp?.arrive} | D: {stp?.depart} | {getTimezoneCode(stp?.timezone)} | {stp?.location?.name} </div>))
                }</>,
                'Service Type': telematics?.dispatch?.servicetype,
                'Assignment': telematics?.dispatch?.routeplan?.assignment?.name,
                'Domicile': telematics?.dispatch?.routeplan?.domicile?.name,
                'Crew': telematics?.dispatch?.routeplan?.crew,
                'Coverage': `${telematics?.dispatch?.coverage.map((driver) => driver.firstname + ' ' + driver.lastname).join(',\n')}`,
                'SearchTol': `${telematics.searchTolerance}`,
                'AppointmentTol': `${telematics.appointmentTolerance}`,
                'Audit': `${telematics?.goodaudit === 'PASS' ? telematics?.goodaudit : telematics?.goodaudit + ' ' + telematics?.pointoffailure}`
            }
        })
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const doms = await Api.get(`/locations/live/Domicile`, idToken);
                setDomiciles(doms);
                const assign = await Api.get(`/assignments/live`, idToken);
                setAssignments(assign);
                const cons = await Api.get(`/contracts/live`, idToken);
                setContracts(cons);
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Unable to get domiciles.' }]);
            }
            didSet(true);
        }

        const loadReport = async () => {
            try {
                if (report) {
                    const filters = objectToQueryString(query);
                    const filtereddata = await Api.get(`/dispatch/report/${report.name}?${filters}`, idToken)
                    let cleanData = [];
                    if (report.data === 'dispatch') {
                        cleanData = cleanDispatchData(filtereddata)
                    }
                    if (report.data === 'telematics') {
                        cleanData = cleanTelematicsData(filtereddata)
                    }
                    setReportData(cleanData);
                } else {
                    setAlerts([...alerts, { variant: 'warning', message: 'Select a report.' }]);
                }
            } catch {
                setReportData()
                setAlerts([...alerts, { variant: 'warning', message: 'Error getting report' }]);
            }
            shouldLoad(false)
        }

        if (!set) {
            fetchData();
        }

        if (load) {
            loadReport();
        }

        if (reset) {
            setQuery(initialQuery)
            setPeriod('today')
            shouldLoad(true)
            shouldReset(false)
        }

    }, [set, load, query, report, idToken, alerts, reset])

    const reportGroups = multidimensionalGroupBy(dispatchReports, ['group']);

    // dedup
    function formatDateToWeekday(dateString) {
        const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const date = new Date(dateString);
        const dayOfWeek = daysOfWeek[date.getDay()];
        return dayOfWeek;
    }

    // GOOGLE CHART DATA - KEYS CHANGE
    function dispatchServiceDatePieChart(dispatch) {
        const groupeddata = multidimensionalGroupBy(dispatch, ['Service Date']);
        let graphdata;
        if (Object.keys(groupeddata).length >= 7) {
            const weekdayCounts = {};
            Object.keys(groupeddata).forEach((key) => {
                const weekday = formatDateToWeekday(key);
                const count = groupeddata[key].length;
                weekdayCounts[weekday] = (weekdayCounts[weekday] || 0) + count;
            });
            graphdata = Object.keys(weekdayCounts).map((weekday) => [`${weekday} (${weekdayCounts[weekday]})`, weekdayCounts[weekday]]);
            const customSortOrder = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            graphdata.sort((a, b) => {
                const dayA = a[0].substring(0, 3); // Extract the day part from the label
                const dayB = b[0].substring(0, 3);

                return customSortOrder.indexOf(dayA) - customSortOrder.indexOf(dayB);
            });
        } else {
            graphdata = Object.keys(groupeddata).map(key => ([`${key} ${formatDateToWeekday(key)} (${groupeddata[key].length})`, groupeddata[key].length]));

        }
        const graphheader = ['Service Date', 'Dispatch Ct.']
        const pieChartData = [graphheader, ...graphdata];
        return pieChartData
    }

    function dispatchStatusPieChart(dispatch) {
        const groupeddata = multidimensionalGroupBy(dispatch, ['Status']);
        const graphdata = Object.keys(groupeddata).map(key => ([`${key} (${groupeddata[key].length})`, groupeddata[key].length]));
        const graphheader = ['Status', 'Dispatch Ct.']
        const pieChartData = [graphheader, ...graphdata];
        return pieChartData
    }

    function dispatchServiceTypePieChart(dispatch) {
        const groupeddata = multidimensionalGroupBy(dispatch, ['Service Type']);
        const graphdata = Object.keys(groupeddata).map(key => ([`${key} (${groupeddata[key].length})`, groupeddata[key].length]));
        const graphheader = ['Service Type', 'Dispatch Ct.']
        const pieChartData = [graphheader, ...graphdata];
        return pieChartData
    }

    function dispatchAssignmentPieChart(dispatch) {
        const groupeddata = multidimensionalGroupBy(dispatch, ['Assignment']);
        const graphdata = Object.keys(groupeddata).map(key => ([`${key} (${groupeddata[key].length})`, groupeddata[key].length]));
        const graphheader = ['Assignment', 'Dispatch Ct.']
        const pieChartData = [graphheader, ...graphdata];
        return pieChartData
    }

    function dispatchDomicilePieChart(dispatch) {
        const groupeddata = multidimensionalGroupBy(dispatch, ['Domicile']);
        const graphdata = Object.keys(groupeddata).map(key => ([`${key} (${groupeddata[key].length})`, groupeddata[key].length]));
        const graphheader = ['Domicile', 'Dispatch Ct.']
        const pieChartData = [graphheader, ...graphdata];
        return pieChartData
    }

    function dispatchCrewPieChart(dispatch) {
        const groupeddata = multidimensionalGroupBy(dispatch, ['Crew']);
        const graphdata = Object.keys(groupeddata).map(key => ([`${key} (${groupeddata[key].length})`, groupeddata[key].length]));
        const graphheader = ['Crew', 'Dispatch Ct.']
        const pieChartData = [graphheader, ...graphdata];
        return pieChartData
    }

    function dispatchContractPieChart(dispatch) {
        const groupeddata = multidimensionalGroupBy(dispatch, ['Contract']);
        const graphdata = Object.keys(groupeddata).map(key => ([`${key} (${groupeddata[key].length})`, groupeddata[key].length]));
        const graphheader = ['Contract', 'Dispatch Ct.']
        const pieChartData = [graphheader, ...graphdata];
        return pieChartData
    }

    function dispatchServiceClassPieChart(dispatch) {
        const groupeddata = multidimensionalGroupBy(dispatch, ['Service Class']);
        const graphdata = Object.keys(groupeddata).map(key => ([`${key} (${groupeddata[key].length})`, groupeddata[key].length]));
        const graphheader = ['Service Class', 'Dispatch Ct.']
        const pieChartData = [graphheader, ...graphdata];
        return pieChartData
    }

    function dispatchOperationsChartData(start, end) {
        const ops = [];
        const currentDate = new Date(start);
        const endDate = new Date(end);
        while (currentDate <= endDate) {
            ops.push([new Date(currentDate), 1]);
            currentDate.setDate(currentDate.getDate() + 1);
        }
        console.log(ops);
        return ops;
    }

    return (
        <Container fluid>
            <AlertDisplay alertState={alertState} />
            <Card>
                <Card.Header>
                    <Row>
                        <Col>
                            <Button type="button" style={reloadStyle} onClick={() => shouldLoad(true)}><FaSync /> Fetch</Button>
                            <Button type="button" style={printStyle}><FaPrint /> Print</Button>
                        </Col>
                        <Col>
                            <Button type="button" style={resetStyle} onClick={() => shouldReset(true)}>Reset <FaSync /></Button>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Header>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                        <div>
                            <label style={labelStyle}>Assignment</label>
                            <select style={buttonStyle} value={query.assignmentId} onChange={e => setQueryAssignment(e.target.value)}>
                                <option value="All">All</option>
                                {assignments && assignments.map(assignment => (
                                    <option key={assignment._id} value={assignment._id}>{assignment.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label style={labelStyle}>Domicile</label>
                            <select style={buttonStyle} value={query.locationId} onChange={e => setQueryDomicle(e.target.value)}>
                                <option value="All">All</option>
                                {domiciles && domiciles.map(domicile => (
                                    <option key={domicile._id} value={domicile._id}>{domicile.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label style={labelStyle}>Contract</label>
                            <select style={buttonStyle} value={query.locationId} onChange={e => setQueryContract(e.target.value)}>
                                <option value="All">All</option>
                                {contracts && contracts.map(contract => (
                                    <option key={contract._id} value={contract._id}>{contract.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label style={labelStyle}>Service</label>
                            <select style={buttonStyle} value={query.servicetype} onChange={e => setQueryService(e.target.value)}>
                                <option value="All">All</option>
                                {CONTACT_SERVICE_TYPES.map((service, i) => (
                                    <option key={i} value={service.name}>{service.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label style={labelStyle}>Start</label>
                            <input type="date" style={buttonStyle} value={query.start} onChange={e => setQueryStart(e.target.value)} />
                        </div>
                        <div>
                            <label style={labelStyle}>End</label>
                            <input type="date" style={buttonStyle} value={query.end} onChange={e => setQueryEnd(e.target.value)} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                        <button type="button" style={period === 'lastmonth' ? buttonSelectedStyle : buttonStyle} onClick={() => setLastMonth()}>Last Month</button>
                        <button type="button" style={period === 'minus30' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryMinus30()}>-30</button>
                        <button type="button" style={period === 'minus14' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryMinus14()}>-14</button>
                        <button type="button" style={period === 'lastweek' ? buttonSelectedStyle : buttonStyle} onClick={() => setLastWeek()}>Last Week</button>
                        <button type="button" style={period === 'minus7' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryMinus7()}>-7</button>
                        <button type="button" style={period === 'yesterday' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryYeserday()}>Yesterday</button>
                        <button type="button" style={period === 'today' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryToday()}>Today</button>
                        <button type="button" style={period === 'tomorrow' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryTomorrow()}>Tomorrow</button>
                        <button type="button" style={period === 'plus7' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryPlus7()}>+7</button>
                        <button type="button" style={period === 'thisweek' ? buttonSelectedStyle : buttonStyle} onClick={() => setThisWeek()}>This Week</button>
                        <button type="button" style={period === 'nextweek' ? buttonSelectedStyle : buttonStyle} onClick={() => setNextWeek()}>Next Week</button>
                        <button type="button" style={period === 'plus14' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryPlus14()}>+14</button>
                        <button type="button" style={period === 'thismonth' ? buttonSelectedStyle : buttonStyle} onClick={() => setThisMonth()}>This Month</button>
                        <button type="button" style={period === 'plus30' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryPlus30()}>+30</button>
                        <button type="button" style={period === 'nextmonth' ? buttonSelectedStyle : buttonStyle} onClick={() => setNextMonth()}>Next Month</button>
                        <button type="button" style={period === 'ytd' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryYTD()}>YTD</button>
                    </div>
                </Card.Header>
                <Card.Header>
                    <div >
                        {Object.keys(reportGroups).map((group, i) => (
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }} key={i}>
                                <label style={labelStyle}>{group.toUpperCase()}</label> {reportGroups[group].map((rpt, j) => (
                                    <button
                                        key={j}
                                        style={report?.name === rpt.name ? buttonSelectedStyle : buttonStyle}
                                        onClick={() => updateReport(rpt)}
                                    >
                                        {rpt.title}
                                    </button>
                                ))}
                            </div>
                        ))}
                    </div>
                </Card.Header>
                {report &&
                    <Card.Header id="mctms-printable">
                        <b>{report?.title}</b> - {report?.detail}
                        {period === 'minus30' && ' Records from past 30 days.'}
                        {period === 'minus14' && ' Records from past 14 days.'}
                        {period === 'minus7' && ' Records from past 7 days.'}
                        {period === 'yesterday' && ' Records from yesterday.'}
                        {period === 'today' && ' Records from today.'}
                        {period === 'tomorrow' && ' Records for tomorrow.'}
                        {period === 'thisweek' && ' Records for this week Sunday-Saturday.'}
                        {period === 'nextweek' && ' Records for this week Sunday-Saturday.'}
                        {period === 'lastweek' && ' Records from last week Sunday-Saturday.'}
                        {period === 'thismonth' && ' Records for this month.'}
                        {period === 'nextmonth' && ' Records for next month.'}
                        {period === 'lastmonth' && ' Records for last month.'}
                        {period === 'plus7' && ' Records for next 7 days.'}
                        {period === 'plus14' && ' Records for next 14 days.'}
                        {period === 'plus30' && ' Records for next 30 days.'}
                        {period === 'ytd' && ' Records from first of the year through today.'}
                    </Card.Header>
                }
                <Card.Body>
                    <Row>
                        <Col>
                            {(load)
                                ? <Spinner />
                                : (report && reportdata)
                                    ?
                                    <pre>
                                        {report.type === 'table' &&
                                            <DataDisplay
                                                dataSource={reportdata}
                                                lsKey={'@mctms-dispatch-report-display'}
                                                urlPath={'/dispatch/'}
                                                urlKey={'_id'}
                                                popKeys={['_id']}
                                                sourceName={report.sourceName}
                                            />
                                        }
                                        {(report.data === 'dispatch' && report.type === 'graph') &&
                                            <>
                                                <Row>
                                                    <Col>
                                                        <Card.Title>
                                                            {reportdata.length} Dispatch
                                                        </Card.Title>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <CalendarChart
                                                            title={`Operations`}
                                                            opdata={dispatchOperationsChartData(query.start, query.end)}
                                                            adjust={Math.ceil(dispatchOperationsChartData(query.start, query.end).length / 365)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12} md={6} lg={4}>
                                                        <Chart
                                                            chartType={"PieChart"}
                                                            data={dispatchServiceDatePieChart(reportdata)}
                                                            options={{ title: `Service Date Pie Chart` }}
                                                            width="100%"
                                                            height="400px"
                                                        />
                                                    </Col>
                                                    <Col sm={12} md={6} lg={4}>
                                                        <Chart
                                                            chartType={"PieChart"}
                                                            data={dispatchStatusPieChart(reportdata)}
                                                            options={{ title: `Status Pie Chart` }}
                                                            width="100%"
                                                            height="400px"
                                                        />
                                                    </Col>
                                                    <Col sm={12} md={6} lg={4}>
                                                        <Chart
                                                            chartType={"PieChart"}
                                                            data={dispatchServiceTypePieChart(reportdata)}
                                                            options={{ title: `Service Type Pie Chart` }}
                                                            width="100%"
                                                            height="400px"
                                                        />
                                                    </Col>
                                                    <Col sm={12} md={6} lg={4}>
                                                        <Chart
                                                            chartType={"PieChart"}
                                                            data={dispatchAssignmentPieChart(reportdata)}
                                                            options={{ title: `Assignment Pie Chart` }}
                                                            width="100%"
                                                            height="400px"
                                                        />
                                                    </Col>
                                                    <Col sm={12} md={6} lg={4}>
                                                        <Chart
                                                            chartType={"PieChart"}
                                                            data={dispatchDomicilePieChart(reportdata)}
                                                            options={{ title: `Domicile Location Pie Chart` }}
                                                            width="100%"
                                                            height="400px"
                                                        />
                                                    </Col>
                                                    <Col sm={12} md={6} lg={4}>
                                                        <Chart
                                                            chartType={"PieChart"}
                                                            data={dispatchCrewPieChart(reportdata)}
                                                            options={{ title: `Crew Type Pie Chart` }}
                                                            width="100%"
                                                            height="400px"
                                                        />
                                                    </Col>
                                                    <Col sm={12} md={6} lg={4}>
                                                        <Chart
                                                            chartType={"PieChart"}
                                                            data={dispatchContractPieChart(reportdata)}
                                                            options={{ title: `Contract Pie Chart` }}
                                                            width="100%"
                                                            height="400px"
                                                        />
                                                    </Col>
                                                    <Col sm={12} md={6} lg={4}>
                                                        <Chart
                                                            chartType={"PieChart"}
                                                            data={dispatchServiceClassPieChart(reportdata)}
                                                            options={{ title: `Service Class Pie Chart` }}
                                                            width="100%"
                                                            height="400px"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>

                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        {(report.data === 'telematics' && report.type === 'graph') &&
                                            <>
                                                <Row>
                                                    <Col>
                                                        Telematics Count : {reportdata.length}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <CalendarChart
                                                            title={`Operations`}
                                                            opdata={dispatchOperationsChartData(query.start, query.end)}
                                                            adjust={Math.ceil(dispatchOperationsChartData(query.start, query.end).length / 365)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12} md={6}>

                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                    </pre>
                                    :
                                    <div>
                                        <h3>{process.env.REACT_APP_COMPANY_NAME}</h3>
                                        <Image src={LOGO} alt={'logo'} style={{ maxHeight: '500px' }} />
                                    </div>
                            }
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default DispatchDashboard;
