

function convertCentToDollar(cents){
    const dollars = Number(cents) / 100;
    return dollars
}


export {
    convertCentToDollar
}