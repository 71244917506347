import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Container, Row, Col, Badge } from 'react-bootstrap';
import appRoutes from '../context/approutes'
import ReactGA from 'react-ga'

const LocationHistoryContext = createContext();

function segmentsMatch(a, b) {
    if (a === b) return true;
    if (a === ':id' && b) return true;
    return false;
}

function pathsMatch(routePath, page) {
    const routeSegments = routePath.split('/');
    const pageSegments = page.split('/');

    if (routeSegments.length !== pageSegments.length) return false;

    for (let i = 0; i < routeSegments.length; i++) {
        if (!segmentsMatch(routeSegments[i], pageSegments[i])) {
            return false;
        }
    }
    return true;
}

function findRouteName(page) {
    const route = appRoutes.find(appRoute => pathsMatch(appRoute.path, page));
    return route ? route.name : '';
}

export function LocationHistoryProvider({ children }) {
    const [visitedPages, setVisitedPages] = useState([]);

    return (
        <LocationHistoryContext.Provider value={{ visitedPages, setVisitedPages }}>
            {children}
        </LocationHistoryContext.Provider>
    );
}

export function useLocationHistory() {
    return useContext(LocationHistoryContext);
}

export function NavigateHistory() {
    const location = useLocation();
    const { visitedPages, setVisitedPages } = useLocationHistory();

    useEffect(() => {
        setVisitedPages(prevPages => {
            const index = prevPages.indexOf(location.pathname);
            if (index !== -1) {
                const updatedPages = [location.pathname, ...prevPages.slice(0, index), ...prevPages.slice(index + 1, 10)];
                return updatedPages;
            }

            const newPages = [location.pathname, ...prevPages.slice(0, 9)];
            return newPages;
        });
    }, [location, setVisitedPages]);

    useEffect(() => {
        // Save visitedPages to localStorage whenever it changes
        localStorage.setItem('visitedPages', JSON.stringify(visitedPages));
    }, [visitedPages]);

    useEffect(() => {
        // Load visitedPages from localStorage on component mount
        const storedVisitedPages = JSON.parse(localStorage.getItem('visitedPages'));
        if (storedVisitedPages) {
            setVisitedPages(storedVisitedPages);
        }
    }, [setVisitedPages]);


    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);
        ReactGA.pageview(location.pathname + location.search);
      }, [location]);
    
    return (
        <Container fluid style={{ backgroundColor: '#333', color: '#eee', padding: '3px', margin: '0px' }}>
            <div className="visited-pages">
                {visitedPages.map((page, i) => (
                    <Col key={i}>
                        <Badge>
                            <Link to={page} style={{ color: '#eee' }}>
                                {i + 1}. {findRouteName(page)}
                            </Link>
                        </Badge>
                    </Col>
                ))}
            </div>
        </Container>
    );
}