import React, { useState, useEffect } from "react";
import { Container, Card, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from "../../../utils/Api";
import { useAuth } from "../../../context/auth";
import { FaPlusCircle } from "react-icons/fa";
import DataDisplay from "../../../components/DataDisplay";

function EmployeeReimbursement() {

  const { idToken } = useAuth();
  const [set, didSet] = useState();
  const [empre, setEmpRe] = useState([])

  const fetchData = async () => {
    const records = await Api.get('/form/employeereimbursement', idToken);
    const cleanEmpRe = records.map(reimbursement => {
      return {
        _id: reimbursement._id,
        'Status':reimbursement.status,
        'Purchased': reimbursement.purchasedate,
        'Amount': reimbursement.amount,
        'Reason': reimbursement.reason,
        'First Name':reimbursement.firstname,
        'Last Name': reimbursement.lastname,
        'Phone': reimbursement.phone,
        'Email': reimbursement.email,
      }
    })
    setEmpRe(cleanEmpRe)
  };

  useEffect(() => {
    if (!set) {
      fetchData()
    }
    return () => didSet(true)
  })

  return (
    <Container fluid>
      <Card>
        <Card.Header>
          <b>Employee Reimbursement</b>
          <Button
            variant="link"
            className="float-end"
            href="/form/employeereimbursement-form"
          >
            <FaPlusCircle /> Form{" "}
          </Button>
        </Card.Header>
        <Card.Body>
          {empre.length > 0 ?
            <>
                <DataDisplay
                  dataSource={empre}
                  lsKey={'@mctms-employeereimbursement-columns'}
                  urlPath={'/form/employeereimbursement/'}
                  urlKey={'_id'}
                  popKeys={['_id']}
                  sourceName={"Emp-Reimbursement"}
                />
            </>
            :
            <>
              No data.
            </>
          }
        </Card.Body>
      </Card>
    </Container>
  );
}

export default EmployeeReimbursement;