import React, { useState, useEffect } from "react";
import { Row, Col, Container, Button, Image } from "react-bootstrap";
import { useSettings } from "../context/settings";
// import { AiOutlineLogout } from "react-icons/ai";
import { useAuth } from '../context/auth';
import { useRole } from "../context/role";
import MCTMS_LOGO from '../assets/mctms-logo.png'

function Clock() {

    const { settings } = useSettings()

    const [time, setTime] = useState(new Date());

    const options = {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZoneName: "short",
        timeZone: settings.timezone,
        hourCycle: "h23",
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, [settings]);

    return (
        <div style={{ fontFamily: 'Courier New' }}>
            <b>{time.toLocaleString(undefined, options)}</b>
        </div>
    );
}

function BannerBar({ navhistory }) {
    const { user } = useAuth();
    const { userRole } = useRole();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // The empty dependency array ensures this effect runs only once


    return (
        <Container fluid style={{ backgroundColor: '#333', color: '#eee', textAlign: 'center', padding: '0px', margin: '0px' }}>
            <Row className="justify-content-center align-items-center p-1 m-0">
                {windowWidth > 600 && 
                    <Col xs={12} sm={6} md={3} >
                        <Image src={MCTMS_LOGO} alt={'Mail Carrier TMS Logo'} style={{ minHeight: '3vh', maxHeight: '5vh' }} />
                    </Col>
                }
                <Col xs={12} sm={6} md={9}>
                    <Row>
                        {windowWidth < 600 && 
                            <Col xs={12} sm={6} md={3} >
                                Mail Carrier TMS
                            </Col>
                        }
                        <Col sm={12} md={6} lg={4}>
                            {process.env.REACT_APP_CLIENT_NAME}
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            {userRole.name} : {user.email}
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <Clock />
                        </Col>
                    </Row>
                    {/* <Row>
                        {navhistory}
                    </Row> */}
                </Col>
            </Row>
        </Container>
    )
}

export default BannerBar