
import React from "react";
import { Container, Card, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
// import Api from '../../utils/Api';
// import { useParams } from 'react-router-dom';

function TimecardReports() {

    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header>Timecard Reports</Card.Header>
                <Card.Body>
                    <Card.Text>
                        List of default and user generated reports 
                    </Card.Text>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default TimecardReports;