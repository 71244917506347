import { Container, Card, Row, Col, Form, Button, Alert } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import Api from '../../utils/Api';
import {BiSolidArrowFromBottom, BiSolidArrowFromTop, BiSolidTrashAlt, BiSolidXCircle} from 'react-icons/bi';
import {RiDeleteBinLine} from 'react-icons/ri';
import {AiOutlineArrowUp, AiOutlineArrowDown} from 'react-icons/ai';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'
import logo from '../../../src/assets/logo.png';
import { useAuth } from '../../context/auth';
import { useParams } from 'react-router-dom';

function TimecardUpdate() {
  const headers = [
    { title: "Date", type: "date" },
    // { title: "Day", type: "text" },
    { title: "Begin Time", type: "time" },
    { title: "End Time", type: "time" },
    { title: "Other Time", type: "number" },
    { title: "Total Time", type: "number" },
    { title: "Block", type: "number" },
    { title: "HCR", type: "text" },
    { title: "Trip", type: "text" },
    { title: "Start Origin", type: "text" },
    { title: "Ending Destination", type: "text" },
    { title: "Truck Number", type: "text" },
    { title: "Gallons Fueled", type: "text" },
    { title: "Total Amount Fueled", type: "text" },
  ];

  const headerStyles = {
    "Begin Time": "bg-green",
    "End Time": "bg-light-red",
    "Other Time": "bg-black",
    "Total Time": "bg-grey",
    "Block": "bg-orange",
  };
  
  const { user, idToken } = useAuth();    
  const { id } = useParams();
  const [form, setForm] = useState({});
  const [alerts, setAlerts] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isPending, setIsPending] = useState(null);
  const [isReviewed, setIsReviewed] = useState(null);
  const [isValidated, setIsValidated] = useState(null);
  const [managerOption, setManagerOption] = useState('');
  const [validaterOption, setValidaterOption] = useState('');
  const [set, didSet] = useState();

  const [rows, setRows] = useState([]);

  const keys = headers.map(header => header.title);

  const calculateSum = (columnTitle) => {
    if (!rows || rows.length === 0) {
        return 0;
    }
    return rows.reduce((acc, row) => {
      const columnIndex = headers.findIndex(header => header.title === columnTitle);
      const value = parseFloat(row.data[columnIndex]) || 0;
      return acc + value;
    }, 0);
  };
  
//   const otherHoursSum = 0;
//   const totalWorkHoursSum = 0;
//   const blockSum = 0;
  const otherHoursSum = calculateSum("Other Time");
  const totalWorkHoursSum = calculateSum("Total Time");
  const blockSum = calculateSum("Block");
  

  const addRow = (position, index) => {
    const newRow = { data: new Array(13).fill("") };
    // const newRow = { id: Date.now(), data: new Array(13).fill("") };
    let updatedRows = [...rows];
    if (position === "before") {
      updatedRows.splice(index, 0, newRow);
    } else if (position === "after") {
      updatedRows.splice(index + 1, 0, newRow);
    } else {
      updatedRows.push(newRow);
    }
    setRows(updatedRows);
  };

  const removeRow = (index) => {
    const updatedRows = rows.filter((_, idx) => idx !== index);
    setRows(updatedRows);
  };

  const updateForm = (updatedFormData) => {
    setForm(updatedFormData);
  };

  const handleFormChange = (name, value) => {
    const updatedFormData = {...form, [name]: value};
    updateForm(updatedFormData);
  };

  const handleCellChange = (newValue, rowIndex, cellIndex) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex].data[cellIndex] = newValue;

    const headersTitles = headers.map(h => h.title);

    if (["Begin Time", "End Time", "Other Time"].includes(headersTitles[cellIndex])) {
      const beginTime = updatedRows[rowIndex].data[headersTitles.indexOf("Begin Time")];
      const endTime = updatedRows[rowIndex].data[headersTitles.indexOf("End Time")];
      const otherTime = parseFloat(updatedRows[rowIndex].data[headersTitles.indexOf("Other Time")]) || 0;

      let totalTimeDecimal = 0;

      if (beginTime && endTime) {
        totalTimeDecimal += convertTime(beginTime, endTime);
      }

      totalTimeDecimal += otherTime;
      updatedRows[rowIndex].data[headersTitles.indexOf("Total Time")] = totalTimeDecimal.toFixed(2);
    }

    const result = updatedRows.length > 0 ? updatedRows.map(row => {
        return keys.reduce((obj, key, index) => {
            obj[key] = row.data[index];
            return obj;
        }, {});
    }) : [];

    setRows(updatedRows);
    
    const updatedFormData = {
        ...form,
        rowData: result,
        otherHoursSum: calculateSum("Other Time"),
        totalWorkHoursSum: calculateSum("Total Time"),
        blockSum: calculateSum("Block")
    };

    updateForm(updatedFormData);
};

  function handleCheckboxChange(e) {
    setIsChecked(e.target.checked);
  }

  async function handleUpdate() {
    try {
        for (const row of rows) {
        for (const cellValue of row.data) {
            if (!cellValue || cellValue === '') {
            setAlerts([...alerts, {variant:'warning', message: 'All timecard fields must be filled out.'}]);
            return;
            }
        }
        }

        if (!isChecked) {
        setAlerts([...alerts, {variant:'warning', message: 'Please certify the timecard information by checking the checkbox.'}]);
        return;
        }

        // setIsPending(true);
        const res = await Api.patch(`/timecard/${id}`, {...form, user: user, status: 'pending', event: 'submitted to manager'}, idToken)
        console.log(res);
        setAlerts([...alerts, {variant:'success', message: 'timecard updated, pending approval'}]);
        didSet(false);
    } catch(error) {
        setAlerts([...alerts, {variant:'warning', message: 'update error'}]);
    }
  }

  async function handleManagerResponse() {
    try {
        if (managerOption === "approved") {
            // setIsReviewed(true);
            setForm(prevForm => ({...prevForm, managernotes: ''}));
            // localStorage.removeItem('timecardForm');
            const res = await Api.patch(`/timecard/${id}`, {...form, status: 'reviewed', user: user, event: 'reviewed by manager'}, idToken)
            setAlerts([...alerts, {variant:'success', message: 'reviewed, pending validation'}]);
        }

        if (managerOption === "returned") {
            if (!form.managernotes) {
            setAlerts([...alerts, {variant:'warning', message: 'Manager notes are required to return for edits'}]);
            return;
            }
            // setIsReviewed(false);
            // setIsPending(false);
            const res = await Api.patch(`/timecard/${id}`, {...form, status: 'draft', user: user, event: 'returned to driver'}, idToken)
            setAlerts([...alerts, {variant:'info', message: 'returned to driver'}]);
        }
        didSet(false);
    } catch(error) {
        setAlerts([...alerts, {variant:'warning', message: 'approve error'}]);
    }
  }
  
  async function handlePayrollResponse() {
    try{
        if (validaterOption === "approved") {
        //   setIsValidated(true);
          const res = await Api.patch(`/timecard/${id}`, {...form, status: 'validated', user: user, event: 'validated'}, idToken)
          setAlerts([...alerts, {variant:'success', message: 'validated'}]);
        }
    
        if (validaterOption === "returned") {
          if (!form.validaternotes) {
            setAlerts([...alerts, {variant:'info', message: 'Notes are required to return to manager'}]);
            return;
          }
        //   setIsValidated(false);
        //   setIsReviewed(false);
          const res = await Api.patch(`/timecard/${id}`, {...form, status: 'pending', user: user, event: 'returned to manager'}, idToken);
          setAlerts([...alerts, {variant:'info', message: 'returned to manager'}]);
        }
        didSet(false);
    } catch(error) {
        setAlerts([...alerts, {variant:'warning', message: 'validate error'}]);
    }
  }

  async function handleReopen() {

    if (form.status === 'validated') {
        // setIsValidated(false);
        // setIsReviewed(true);
        const res = await Api.patch(`/timecard/${id}`, {...form, status: 'reviewed', user: user, event: 're-opened by Payroll'}, idToken);
        setAlerts([...alerts, {variant:'info', message: 're-opened by Payroll'}]);
    }

    if (form.status === 'reviewed') {
        // setIsReviewed(false);
        const res = await Api.patch(`/timecard/${id}`, {...form, status: 'pending', user: user, event: 're-opened by manager'}, idToken);
        setAlerts([...alerts, {variant:'info', message: 're-opened by manager'}]);
    }

    if (form.status === 'pending'){
        // console.log('form.status vefore re-open call:', form.status);
        // setIsPending(false);
        const res = await Api.patch(`/timecard/${id}`, {...form, status: 'draft', user: user, event: 're-opened by driver'}, idToken);
        setAlerts([...alerts, {variant:'info', message: 're-opened by driver'}]);
        // console.log('form re-opened by driver')
        // console.log('form.status after re-open call:', form.status);
    }
    didSet(false);
  }

  function convertTime(startTime, endTime) {
    const [startHour, startMinute] = startTime.split(':').map(Number);
    const [endHour, endMinute] = endTime.split(':').map(Number);

    let hourDifference = endHour - startHour;
    let minuteDifference = endMinute - startMinute;

    if (hourDifference < 0 || (hourDifference === 0 && minuteDifference < 0)) {
      hourDifference += 24;
    }

    if (minuteDifference < 0) {
      hourDifference -= 1;
      minuteDifference += 60;
    }

    const minuteAsFraction = minuteDifference / 60;

    return hourDifference + minuteAsFraction;
  }

  // function getDayOfWeek(dateString) {
  //   const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    
  //   const date = new Date(dateString);
  //   return days[date.getUTCDay()];
  // }
  
  useEffect(() => {

    const fetchData = async () => {
        try {
            const data = await Api.get(`/timecard/${id}`, idToken);
            setForm(data);

            // switch (data.status) {
            //     case 'pending':
            //         setIsPending(true);
            //         setIsReviewed(false);
            //         setIsValidated(false);
            //         break;
            //     case 'reviewed':
            //         setIsPending(false);
            //         setIsReviewed(true);
            //         setIsValidated(false);
            //         break;
            //     case 'validated':
            //         setIsPending(false);
            //         setIsReviewed(false);
            //         setIsValidated(true);
            //         break;
            //     default:
            //         setIsPending(false);
            //         setIsReviewed(false);
            //         setIsValidated(false);
            //         break;
            // }

            const output = data.rowData.map(row => {
                const values = [];
                Object.keys(row).forEach(k => {
                    const matchingHeader = headers.findIndex(header => header.title === k);
                    if (matchingHeader !== -1) {
                        values.push(row[k]);
                    }
                });
                const newRow = { data: values };                
                // const newRow = { id: Date.now(), data: values };                
                return newRow;
            });
            setRows(output);
            console.log('row output: ', output)
            console.log('data.status: ', data.status);
        } catch (error) {
            setAlerts([...alerts, {variant:'warning', message: 'Request error'}]);
        }
        didSet(true)
    }
    if(!set){
        fetchData()
    }
  }, [set]);

  useEffect(() => {
    console.log('Updated form.status: ', form.status);
  }, [form.status]);
  

  return (
    <Container fluid>
      <br />
      <Card>
        <Card.Header>Timecard Form</Card.Header>
        {alerts.length > 0 && (
          <Card.Body>
            {alerts.map((alrt, i) => (
              <Row key={i}>
                <Col>
                  <Alert
                    variant={alrt.variant}
                    dismissible
                    onClick={() => setAlerts([])}
                  >
                    {alrt.message}
                  </Alert>
                </Col>
              </Row>
            ))}
          </Card.Body>
        )}
      </Card>
      {form && Object.keys(form).length > 0 &&
        <div className="timecard">
            <div className="timecard-banner">
            <div className="banner-logo">
                <img src={logo} alt="logo"></img>
            </div>
            <div className="timecard-title">
                <h5>Mail Management Services Timesheet 2023</h5>
            </div>
            <div className="timecard-address">
                <p>Mail Management Services</p>
                <p>1919 S. 40th Street, Ste 222</p>
                <p>Lincoln, NE</p>
                <p>68506-5248</p>
                <p>402.890.2244</p>
            </div>
            <div className="pay-period-table">
                <table>
                    <tbody>
                        <tr>
                            <th>Period Start</th>
                        </tr>
                        <tr>
                            <td>
                            <input
                                type="date"
                                name="periodStart"
                                value={form.periodStart}
                                onChange={(e) =>
                                handleFormChange("periodStart", e.target.value)
                                }
                                disabled={form.status !== 'draft'}
                                required
                            />
                            </td>
                        </tr>
                        <tr>
                            <th>Period End</th>
                        </tr>
                        <tr>
                            <td>
                            <input
                                type="date"
                                name="periodEnd"
                                value={form.periodEnd}
                                onChange={(e) =>
                                handleFormChange("periodEnd", e.target.value)
                                }
                                disabled={form.status !== 'draft'}
                                required
                            />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="banner-disclaimer">
                DRIVERS TIME RECORD for operation within a 150 air-mile radius of
                normal reporting location For all vehicles or combinations with a
                GVWR/GCWR over 10,000 lbs. or that requires placarding
            </div>

            <div className="driver-name-table">
                <table>
                <thead>
                    <tr>
                    <th>DRIVER NAME</th>
                    <td>
                        <input
                        type="text"
                        name="driverName"
                        value={form.driverName}
                        onChange={(e) =>
                            handleFormChange("driverName", e.target.value)
                        }
                        disabled={form.status !== 'draft'}
                        required
                        />
                    </td>
                    </tr>
                    <tr>
                    <th>LOCATION</th>
                    <td>
                        <input
                        type="text"
                        name="location"
                        value={form.location}
                        onChange={(e) =>
                            handleFormChange("location", e.target.value)
                        }
                        disabled={form.status !== 'draft'}
                        required
                        />
                    </td>
                    </tr>
                </thead>
                </table>
            </div>
            </div>

            <div className="table-container">
            <table className="table-full-width">
                <thead>
                <tr>
                    <th colSpan="9">Time Capture</th>
                    <th colSpan="2">USPS Contract</th>
                    <th colSpan="2">Service Points</th>
                    <th colSpan="1">Vehicle</th>
                    <th colSpan="2">Fuel</th>
                </tr>
                <tr>
                    <th>Actions</th>
                    {headers.map((header) => (
                    <th
                        key={header.title}
                        className={headerStyles[header.title] || ""}
                    >
                        {header.title}
                    </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {/* {rows.map((row, idx) => (
                    <tr>
                        <td colSpan='100%'>{JSON.stringify(row)}</td>
                    </tr>
                ))} */}
                {rows && rows.map((row, idx) => (
                    
                    <tr key={idx}>
                    <td className="action-buttons">
                        <button
                        onClick={() => addRow("before", idx)}
                        disabled={form.status !== 'draft'}
                        >
                        <AiOutlineArrowUp />
                        <span className="tooltip-text">Add row above</span>
                        </button>
                        <button
                        onClick={() => addRow("after", idx)}
                        disabled={form.status !== 'draft'}
                        >
                        <AiOutlineArrowDown />
                        <span className="tooltip-text">Add row below</span>
                        </button>
                        <button
                        onClick={() => removeRow(idx)}
                        disabled={rows.length === 1 || form.status !== 'draft'}
                        >
                        <RiDeleteBinLine />
                        <span className="tooltip-text">Delete row</span>
                        </button>
                    </td>
                    {row.data && row.data.map((cellData, cellIndex) => {
                        const headerTitle = headers[cellIndex].title;

                        return (
                        <td key={`${idx}.${cellIndex}`}>
                            <input
                            type={headers[cellIndex].type}
                            value={cellData}
                            step={
                                headerTitle === "Other Time" ||
                                headerTitle === "Total Time" ||
                                headerTitle === "Block"
                                ? "0.01"
                                : undefined
                            }
                            readOnly={
                                headerTitle === "Total Time" ||
                                headerTitle === "Day"
                            }
                            onChange={(e) =>
                                handleCellChange(e.target.value, idx, cellIndex)
                            }
                            disabled={form.status !== 'draft'}
                            required={headerTitle !== "Other Time"}
                            />
                        </td>
                        );
                    })}
                    </tr>
                ))}
                </tbody>
                <tfoot>
                <tr>
                    <th colSpan="4" rowSpan="2">
                    *Pay periods start Saturday and end on Friday*
                    </th>
                    <td className="tfoot-padding">{otherHoursSum}</td>
                    <td className="tfoot-padding">{totalWorkHoursSum}</td>
                    <td className="tfoot-padding">{blockSum}</td>
                    <td className="certify" colSpan="7" rowSpan="2">
                    <div className="flex-container">
                        <div className="certify-box">
                        <input
                            type="checkbox"
                            disabled={form.status !== 'draft'}
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                        </div>
                        <div className="yellow-highlight">
                        <strong>
                            I certify that the above information has been completed
                            by me and is true and accurate
                        </strong>
                        </div>
                    </div>
                    </td>
                </tr>
                <tr>
                    <th>Other Hours Total</th>
                    <th>Total Work Hours</th>
                    <th>Block Total</th>
                </tr>
                </tfoot>
            </table>
            </div>

            <div className="form-buttons">
            {/* <button onClick={handleReset} disabled={isPending}>
                RESET FORM
            </button> */}
            <button onClick={() => handleUpdate()} disabled={form.status !== 'draft'}>
            {/* <button onClick={handleUpdate} disabled={isPending || isReviewed || isValidated}> */}
                SUBMIT FORM
            </button>
            <button onClick={() => handleReopen()} disabled={form.status !== 'pending'}>
            {/* <button onClick={handleReopen} disabled={!isPending || isReviewed || isValidated}> */}
                REOPEN
            </button>
            </div>

            <div className="bottom-section">
            <div className="bottom-text">
                <p>
                This document must be retained by the driver through the first
                week of the following month so that a previous seven day record of
                duty status can be presented to any enforcement officer upon
                request.
                </p>
                <p>
                A driver is exempt from maintaining the Drivers Daily Log
                Requirements of section 395.8 if ALL of the following are true
                395.1( e ) ( 1).{" "}
                </p>
                <p>
                The driver returns to their normal work reporting location and is
                released from work within 14 consecutive hours, A property
                carrying commercial motor vehicle driver must have at least 10
                consecutive hours off duty separating each 14 hours on duty{" "}
                </p>
                <strong>
                If a driver who uses the 150 air-mile radius exemption exceeds the
                150 air-mile radius, the 14 hour work day, or fails to take a
                minimum of 10 hours off duty between any work day period, the
                driver must comply with all regulations associated with the driver
                logbook requirements including daily hours of service worked.
                </strong>
            </div>
            </div>

            {(form.status !== 'draft') &&
            // {(isPending || isReviewed) &&
            <div className="manager-section">
            <div>
                <h6>For Manager:</h6>
            </div>
            <div className="manager-options">
                <label>
                <input
                    type="radio"
                    name="managerOption"
                    value="approved"
                    checked={managerOption === "approved"}
                    onChange={() => {
                    setManagerOption("approved");

                    setForm((prevState) => ({
                        ...prevState,
                        managernotes: "",
                    }));
                    }}
                    disabled={form.status !== 'pending'}
                    // disabled={!isPending || isReviewed}
                />
                Approved
                </label>

                <label>
                <input
                    type="radio"
                    name="managerOption"
                    value="returned"
                    checked={managerOption === "returned"}
                    onChange={() => setManagerOption("returned")}
                    disabled={form.status !== 'pending'}
                    // disabled={!isPending || isReviewed}
                />
                Returned
                </label>
            </div>
            <div className="manager-notes">
                <label htmlFor="managerComments">Manager Comments:</label>
                <textarea
                type="text"
                name="managernotes"
                rows="4"
                cols="50"
                value={form.managernotes}
                onChange={(e) => handleFormChange("managernotes", e.target.value)}
                disabled={managerOption !== "returned" || form.status !== 'pending'}
                required={managerOption === "returned"}
                placeholder="Comments required if returning for edits"
                />
            </div>
            <div className="mgmt-table">
                <table>
                    <tbody>

                    </tbody>
                <tr>
                    <th>Truck:</th>
                    <td>
                    <input
                        type="text"
                        name="truck"
                        value={form.truck}
                        onChange={(e) => handleFormChange("truck", e.target.value)}
                        disabled={form.status !== 'pending'}
                        // disabled={!isPending || isReviewed}
                    />
                    </td>
                </tr>
                <tr>
                    <th>Car:</th>
                    <td>
                    <input
                        type="text"
                        name="car"
                        value={form.car}
                        onChange={(e) => handleFormChange("car", e.target.value)}
                        disabled={form.status !== 'pending'}
                        // disabled={!isPending || isReviewed}
                    />
                    </td>
                </tr>
                <tr>
                    <th>Holiday:</th>
                    <td>
                    <input
                        type="text"
                        name="holiday"
                        value={form.holiday}
                        onChange={(e) =>
                        handleFormChange("holiday", e.target.value)
                        }
                        disabled={form.status !== 'pending'}
                        // disabled={!isPending || isReviewed}
                    />
                    </td>
                </tr>
                <tr>
                    <th>PTO:</th>
                    <td>
                    <input
                        type="text"
                        name="pto"
                        value={form.pto}
                        onChange={(e) => handleFormChange("pto", e.target.value)}
                        disabled={form.status !== 'pending'}
                        // disabled={!isPending || isReviewed}
                    />
                    </td>
                </tr>
                </table>
            </div>
            <div className="manager-name">
                <input type="text" value="Manager Name" disabled />
            </div>
            <div className="form-buttons">
                <button onClick={() => handleManagerResponse()} disabled={form.status !== 'pending'}>
                {/* <button onClick={handleManagerResponse} disabled={!isPending || isReviewed}> */}
                SUBMIT
                </button>
                <button
                onClick={() => handleReopen()}
                disabled={form.status !== 'reviewed'}
                // disabled={!isReviewed || isValidated}
                >
                REOPEN
                </button>
            </div>
            </div>            
            }

            {(form.status === 'reviewed' || form.status === 'validated') &&
            // {(isReviewed || isValidated) &&
            <div className="validate-section">
            <div>
                <h6>For Validater:</h6>
            </div>
            <div className="validate-options">
                <label>
                <input
                    type="radio"
                    name="validaterOption"
                    value="approved"
                    checked={validaterOption === "approved"}
                    onChange={() => {
                    setValidaterOption("approved");

                    setForm((prevState) => ({
                        ...prevState,
                        validaternotes: "",
                    }));
                    }}
                    disabled={form.status !== 'reviewed'}
                    // disabled={!isReviewed || isValidated}
                />
                Approved
                </label>

                <label>
                <input
                    type="radio"
                    name="validaterOption"
                    value="returned"
                    checked={validaterOption === "returned"}
                    onChange={() => setValidaterOption("returned")}
                    disabled={form.status !== 'reviewed'}
                    // disabled={!isReviewed || isValidated}
                />
                Returned
                </label>
            </div>
            <div className="validate-notes">
                <label htmlFor="validateComments">Validater Comments:</label>
                <textarea
                type="text"
                name="validaternotes"
                rows="4"
                cols="50"
                value={form.validaternotes}
                onChange={(e) =>
                    handleFormChange("validaternotes", e.target.value)
                }
                disabled={
                    validaterOption !== "returned" || form.status !== 'reviewed'
                }
                required={validaterOption === "returned"}
                placeholder="Comments required if returning for edits"
                />
            </div>
            <div className="validate-name">
                <input type="text" value="Validater Name" disabled />
            </div>
            <div className="form-buttons">
                <button
                onClick={() => handlePayrollResponse()}
                disabled={form.status !== 'reviewed'}
                >
                SUBMIT
                </button>
                <button onClick={() => handleReopen()} disabled={form.status !== 'validated'}>
                REOPEN
                </button>
            </div>
            </div>
            }
        </div>
      }
    </Container>
  );
}

export default TimecardUpdate;