
import { Container, Card, Row, Col, Form, Button, Alert, Spinner, Table } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import Api from "../../utils/Api";
import { useAuth } from '../../context/auth';
import AlertDisplay from "../../components/AlertDisplay";

const initialFormState = {
    name: '',
    detail: '',
    members: [],
}


function mapArraysByKey(array1, array2, key) {
    const result = [];

    array1.forEach((obj1) => {
        const matchedObj2 = array2.find((obj2) => obj2[key] === obj1[key]);

        if (matchedObj2) {
            result.push([obj1, matchedObj2]);
        } else {
            result.push([obj1, undefined]);
        }
    });

    array2.forEach((obj2) => {
        const matchedObj1 = array1.find((obj1) => obj1[key] === obj2[key]);

        if (!matchedObj1) {
            result.push([undefined, obj2]);
        }
    });

    return result;
}

function AccessGroupForm() {
    const [set, didSet] = useState();
    const { idToken } = useAuth();
    const [formdata, setFormdata] = useState(initialFormState)
    const [alerts, setAlerts] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [members, setMembers] = useState([]);

    async function handlePost() {
        try {
            const data = { ...formdata, members: members };
            await Api.post('/access/groups', data, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success', }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error updating', }])
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const p = await Api.get('/access/profiles', idToken);
                const u = await Api.get('/firebase/users', idToken);
                const usersprofiles = mapArraysByKey(u, p, 'uid')
                function getArraysDefined(usersprofiles) {
                    const undefinedArrays = usersprofiles.filter(([f, s]) => f !== undefined && s !== undefined);
                    return undefinedArrays;
                }
                const profilesArray = getArraysDefined(usersprofiles);
                const cleanProfiles = profilesArray.map(([user, profile]) => {
                    return {
                        _id: profile._id,
                        'Email': user.email,
                        'Name': `${profile.firstname} ${profile.lastname}`,
                        'Role': profile.role.name,
                        'Last Signin': user.metadata.lastSignInTime,
                        'Last Refreseh': user.metadata.lastRefreshTime,
                        'Created': user.metadata.creationTime,
                        'Disabled': user.disabled
                    }
                })
                setProfiles(cleanProfiles)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error', }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    })

    return (
        <Container fluid>
            <Card>
                <Card.Header><b>New Group Form</b></Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={{alerts, setAlerts}}/>
                }
                <Card.Body>
                    <Row>
                        <Col sm={12} md={4} lg={3}>
                            Name
                            <Form.Control
                                type="text"
                                value={formdata.name}
                                onChange={(e) => setFormdata({ ...formdata, name: e.target.value })}
                            />
                        </Col>
                        <Col sm={12} md={8} lg={9}>
                            Detail
                            <Form.Control
                                type="text"
                                value={formdata.detail}
                                onChange={(e) => setFormdata({ ...formdata, detail: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Include</th>
                                <th>Email</th>
                                <th>Name</th>
                                <th>Role</th>
                            </tr>
                        </thead>
                        <tbody>
                            {profiles.map((profile, i) => (
                                <tr key={i}>
                                    <td>
                                        <Form.Check
                                            onClick={() =>
                                                members.includes(profile.Email)
                                                    ? setMembers(members.filter((member) => member !== profile.Email))
                                                    : setMembers([...members, profile.Email])
                                            }
                                            checked={members.includes(profile.Email)}
                                        />
                                    </td>
                                    <td>{profile.Email}</td>
                                    <td>{profile.Name}</td>
                                    <td>{profile.Role}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <br />
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={() => handlePost()}>Create</Button>
                        </Col>

                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default AccessGroupForm;