import { Container, Card, Row, Col, Button, Spinner, Table } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import Api from "../../utils/Api";
import { useParams } from 'react-router-dom';

function DispatchDetail() {
    const { id } = useParams();
    const [set, didSet] = useState();
    const [dispatch, setDispatch] = useState();
    const [error, setError] = useState();



    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Api.get(`/dispatch/${id}`)
                setDispatch(data)
            } catch (err) {
                setError(err)
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    })

    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header>
                    Dispatch Detail
                    {dispatch &&
                        <>
                            <Button variant="link" className="float-end" href={`/dispatch/${dispatch._id}`}> Update</Button>
                            <Button variant="link" className="float-end" href={`/dispatch/${dispatch._id}/upload`}> Attach Documents</Button>
                        </>
                    }
                </Card.Header>
                {error &&
                    <Card.Body>
                        {JSON.stringify(error)}
                    </Card.Body>
                }
                {dispatch ?
                    <Card.Body>
                        <Row>
                            <Col>
                                ID : {dispatch._id}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <b>Route Plan</b> <br />
                                <>{(dispatch.routeplan.name)}</>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <b>Service Date</b> <br />
                                <>{(dispatch.servicedate)}</>
                            </Col>
                            <Col>
                                <b>Service Type</b> <br />
                                {(dispatch.servicetype)}
                            </Col>
                            <Col>
                                <b>Vehicle</b> <br />
                                {(dispatch.routeplan.vehiclecat)}
                            </Col>
                            <Col>
                                <b>Trailer</b> <br />
                                {(dispatch.routeplan.trailercat)}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <b>Contract</b> <br />
                                {(dispatch.routeplan.contract)}
                            </Col>
                            <Col>
                                <b>Assignment</b> <br />
                                {(dispatch.routeplan.assignment)}
                            </Col>
                            <Col>
                                <b>Frequency</b> <br />
                                <a href={`/plan/frequency/${dispatch.routeplan.frequency._id}`} target="_blank" rel="noreferrer">{dispatch.routeplan.frequency.name}</a>
                            </Col>
                            <Col>
                                <b>Detail</b> <br />
                                {(dispatch.routeplan.frequency.detail)}
                            </Col>
                        </Row>
                        <br />
                        <Card.Header>
                            <Card.Title>
                                Events
                            </Card.Title>
                        </Card.Header>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Order</th>
                                    <th>Date</th>
                                    <th>Event</th>
                                    <th>Detail</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dispatch.routeplan.stops.map(stop => (
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <br />
                        <Card.Header>
                            <Card.Title>
                                Operations
                            </Card.Title>
                        </Card.Header>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Order</th>
                                    <th>Trip</th>
                                    <th>Location</th>
                                    <th>Address</th>
                                    <th>Arrive</th>
                                    <th>GPS Arrive</th>
                                    <th>Action</th>
                                    <th>Depart</th>
                                    <th>GPS Depart</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dispatch.routeplan.stops.map(stop => (
                                    <tr>
                                        <td>{stop.order}</td>
                                        <td>{stop.trip}</td>
                                        <td>{stop.location}</td>
                                        <td>{stop.address}</td>
                                        <td>{stop.arrive}</td>
                                        <td></td>
                                        <td>{stop.action}</td>
                                        <td>{stop.depart}</td>
                                        <td></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Row style={{ margin: '1em' }}>
                            <Col>
                                {/* <GMap waypoints={waypoints}/> */}
                            </Col>
                        </Row>
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }
            </Card>
        </Container>
    );
}

export default DispatchDetail;