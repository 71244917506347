class HtmlEmail {
    constructor(data) {
        this.header = data.header || '';
        this.greeting = data.greeting || '';
        this.title = data.title || '';
        this.message = data.message || '';
        this.followup = data.followup || '';
        this.url = data.url || '';
        this.urlText = data.urlText || '';
        this.complimentary = data.complimentary || '';
        this.signature = data.signature || '';
    }

    isValid() {
        return (
            this.to.trim() !== '' &&
            this.header.trim() !== '' &&
            this.greeting.trim() !== '' &&
            this.title.trim() !== '' &&
            this.message.trim() !== '' &&
            this.followup.trim() !== '' &&
            this.url.trim() !== '' &&
            this.urlText.trim() !== '' &&
            this.complimentary.trim() !== '' &&
            this.signature.trim() !== ''
        );
    }

    getHtmlAsStr() {
        return (`
            <html>
            <head>
            <meta charset="UTF-8" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Mail Carrier TMS Message</title>
            </head>
            <body style="font-family: Arial, sans-serif; line-height: 1.6; margin: 0; padding: 20px; background-color: #f1f1f1;">
            <div style="max-width: 600px; margin: 0 auto; background-color: #fff; padding: 20px; border-radius: 5px;">
                <h2 style="color: #333;">${this.header}</h2>
                <p style="color: #333;">
                ${this.greeting}
                <br />
                <br />
                <strong>${this.title}</strong>
                <br />
                ${this.message}
                <br />
                <br />
                ${this.followup}
                <br />
                <a href="${this.url}" target="_blank" rel="noreferrer" style="color: #007bff"; text-decoration: none;">${this.urlText}</a>
                <br />
                <br />
                <br />
                ${this.complimentary}
                <br />
                ${this.signature}
                </p>
            </div>
            </body>
            </html>
        `)
    }
}


export default HtmlEmail;
