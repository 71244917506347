import { Container, Card, Button, Row, Col, Spinner, Alert } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/auth';
import { useParams } from 'react-router-dom';
import EDI204Component from "./EDI204.component";

function LoadTenderResponse() {
    const { id } = useParams();
    const { idToken } = useAuth();

    const [set, didSet] = useState();
    const [error, setError] = useState();
    const [message, setMessage] = useState();


    const [edi204, setEDI204] = useState();
    const [togglejson, setToggleJSON] = useState();
    const [toggleresponse, setToggleResponse] = useState(true)




    const handleLoadTenderAccept = async (edi204) => {
        setToggleResponse(false)
        try {
            const date = new Date();
            const utcTimestamp = date.toISOString();

            const edi204Update = {
                _id: edi204._id,
                loadtenderLifeCycleStatus: 'Accepted',
            }

            const edi990 = {
                "TransactionId": 990,
                "InternalTrackingNum": edi204.InternalTrackingNum,
                "Customer": edi204.Customer,
                "CustomerISA": edi204.CustomerISA,
                "SCAC": edi204.SCAC,
                "Response": "Accepted",
                "ShipmentId": edi204.ShipmentId,
                "Timestamp": utcTimestamp,
                "TimeZone": "UT",
                "Note": "",
                "RejectionReason": "",
                "References": ""
            }

            Promise.all([
                await Api.post('/edi990', edi990, idToken),
                await Api.patch(`/edi204/${edi204._id}`, edi204Update, idToken)
            ]).then(() => {
                setMessage(`Successfully responded to Load Tender`)
            }).catch(err => {
                setError(err)
            })
            didSet(false)
        } catch (error) {
            setError(error)
        }
    }

    const handleLoadTenderReject = async (edi204) => {
        setToggleResponse(false)
        try {
            const date = new Date();
            const utcTimestamp = date.toISOString();

            const edi204Update = {
                '_id': edi204._id,
                'loadtenderLifeCycleStatus': 'Rejected',
            }

            const edi990 = {
                "TransactionId": 990,
                "InternalTrackingNum": edi204.InternalTrackingNum,
                "Customer": edi204.Customer,
                "CustomerISA": edi204.CustomerISA,
                "SCAC": edi204.SCAC,
                "Response": "Rejected",
                "ShipmentId": edi204.ShipmentId,
                "ProNumber": edi204.ProNumber,
                "TxnNumber": edi204.TxnNumber,
                "Timestamp": utcTimestamp,
                "TimeZone": "UT",
                "Note": "",
                "RejectionReason": "",
                "References": ""
            }

            Promise.all([
                await Api.post('/edi990', edi990, idToken),
                await Api.patch(`/edi204/${edi204._id}`, edi204Update, idToken)
            ]).then(() => {
                setMessage(`Successfully responded to Load Tender`)
            }).catch(err => {
                setError(err)
            })
            didSet(false)
        } catch (error) {
            setError(error)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            Promise.all([
                await Api.get(`/edi204/${id}`, idToken),
            ]).then((data) => {
                const e204 = data[0]
                setEDI204(e204)
            }).catch(err => {
                setError(err)
            })
            didSet(true)
        }
        if(!set){
            fetchData()
        }
    })

    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <Row>
                        <Col>
                            <b>Load Tender Response</b>
                            <Button variant="secondary" style={{ float: 'right' }} onClick={() => setToggleJSON(togglejson ? false : true)}>{togglejson ? "See UI" : "See Raw"}</Button>
                        </Col>
                    </Row>
                </Card.Header>
                {message &&
                    <Card.Body>
                        <Row>
                            <Col>
                                <Alert variant="success" dismissible onClick={() => setMessage()}>
                                    {JSON.stringify(message)}
                                </Alert>
                            </Col>
                        </Row>
                    </Card.Body>
                }
                {error &&
                    <Card.Body>
                        <Row>
                            <Col>
                                <Alert variant='warning' dismissible onClick={() => setError()}>{JSON.stringify(error)}</Alert>
                            </Col>
                        </Row>
                    </Card.Body>
                }
                {set ?
                    <>
                        <Card.Body>
                            {togglejson ?
                                <pre>
                                    {JSON.stringify(edi204, "", 2)}
                                </pre>
                                :
                                <EDI204Component edi204={edi204} />
                            }

                        </Card.Body>
                        {(edi204 && toggleresponse && edi204.loadtenderLifeCycleStatus === 'pending') &&
                            <Card.Body>

                                <Row>
                                    <Col>
                                        <Button variant="primary" onClick={() => handleLoadTenderAccept(edi204)}>Accept</Button>
                                    </Col>
                                    <Col>
                                        <Button variant="danger" onClick={() => handleLoadTenderReject(edi204)} style={{ float: 'right' }}>Reject</Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        }
                    </>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }
            </Card>
        </Container >
    );
}

export default LoadTenderResponse;