import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaUpload, FaChartPie } from 'react-icons/fa';
import { TbTable } from 'react-icons/tb';
import NavBarComponent from "../../components/NavBar.component";

const FiftyFiveHundredNav = () => {
    const navOptions = [
        { href: "/ffh/reports", icon: <FaChartPie />, text: "Reports" },
        { href: "/ffh/table", icon: <TbTable /> , text: "Table" },
        { href: "/ffh/upload", icon:<FaUpload />, text: "Upload" },
      ];
    return (
        <NavBarComponent name={'USPS 5500 Nav'} navOptions={navOptions}/>
    );
};

export default FiftyFiveHundredNav