import { Container, Card, Row, Col, Spinner, Alert, Form } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useRef } from 'react'
import Api from "../../utils/Api";
import { useParams } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import AlertDisplay from "../../components/AlertDisplay";

function NotificationDetail() {
    const { idToken } = useAuth();
    const { id } = useParams();
    const [set, didSet] = useState();
    const [notification, setNotification] = useState({});
    const [alerts, setAlerts] = useState([]);
    const alertState = {alerts, setAlerts}
    const [html, setHtml] = useState();
    const targetRef = useRef(null);
    
    useEffect(() => {
        if (targetRef.current && html) {
            targetRef.current.appendChild(html);
          }

        const fetchData = async () => {
            try {
                const data = await Api.get(`/notification/${id}`, idToken);
                setNotification(data)
                const HTML = parseHTMLStringToHTML(data.html)
                setHtml(HTML)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    },[set, html, id, idToken, alerts])

    function parseHTMLStringToHTML(htmlString) {
        const parser = new DOMParser();
        const parsedDocument = parser.parseFromString(htmlString, 'text/html');
        return parsedDocument.body;
      }

    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header>
                    <b>Notification Detail</b>
                </Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {set ?
                    <>
                        <Card.Body>
                            <Row>
                                <Col>
                                    ID : {notification._id}
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    To:
                                    <Form.Control
                                        type="text"
                                        value={JSON.stringify(notification.to)}
                                        readOnly
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Subject:
                                    <Form.Control
                                        type="text"
                                        value={notification.subject}
                                        readOnly
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Body:
                                    <div ref={targetRef}> </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    );
}

export default NotificationDetail;