import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import { Container, Card, } from "react-bootstrap";
import { useRole } from "../context/role";
import { FaGasPump, FaInfo, FaFileExcel, FaWpforms, FaTruck, FaMoneyCheck, FaUserLock, FaFilePdf, FaUserPlus, FaCloud } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { AiOutlineSchedule, } from 'react-icons/ai';
import { TbScanEye } from 'react-icons/tb';
import { MdOutlineSensors } from 'react-icons/md';
import { TbNetwork } from 'react-icons/tb'
import { CgProfile } from "react-icons/cg";
import logo from '../assets/logo.png'
import Api from "../utils/Api";
import { useAuth } from "../context/auth";

function Home() {
    const { user } = useAuth();

    const [apihealth, setApiHealth] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const res = await Api.get('/healthcheck');
            setApiHealth(res)
        }

        fetchData();
    }, [])

    const { userRole, checkNavPermissions } = useRole();

    const features = [
        { href: "/info", icon: <FaInfo />, text: "Info", detail: 'This page displays application information and resources.' },
        { href: "/iot", icon: <MdOutlineSensors />, text: "IoT ", detail: 'Internet of Things is the page to see the currently supported business to business connections.' },
        { href: "/profile", icon: <CgProfile />, text: "Profile", detail: 'Check your profile' },
        { href: "/settings", icon: <FiSettings />, text: "Settings", detail: 'Change your application default timezone.' },
        { href: "/plan", icon: <AiOutlineSchedule />, text: "Plan", detail: 'Plan logistic operations.' },
        { href: "/dispatch", icon: <FaTruck />, text: "Dispatch ", detail: 'Live and historic operations.' },
        { href: "/forms", icon: <FaWpforms />, text: "Forms", detail: 'Daily Report, PTO Requests, Emp. Reimbursement, etc.' },
        { href: "/fuel", icon: <FaGasPump />, text: "Fuel", detail: 'Check current fuel prices accross USA.' },
        { href: "/loadtender", icon: <TbNetwork />, text: "Load Tender", detail: 'Manage load tenders from trading partners.' },
        { href: "/timecard", icon: <FaMoneyCheck />, text: "Timecard", detail: 'Create, check, and analize operation timecards.' },
        { href: "/weather", icon: <FaCloud />, text: "Weather", detail: 'Check weather for operations.' },
        { href: "/ffh", icon: <FaFileExcel />, text: "5500", detail: 'Upload USPS 5500 and analyize data.' },
        { href: "/loadeye", icon: <TbScanEye />, text: "Loadeye", detail: 'Prototype trailer tracking device.' },
        { href: "/manifest", icon: <FaFileExcel />, text: "Manifest", detail: 'Upload USPS Manifests into dispatch and report operation cost.' },
        { href: "/hcr", icon: <FaFilePdf />, text: "HCR", detail: 'Upload plate contacts (may become obsolete soon...)' },
        { href: "/access", icon: <FaUserLock />, text: "Access", detail: 'Manage users company wide from coperate to drivers.' },
        { href: "/access/new-users", icon: <FaUserPlus />, text: "New Users", detail: 'Designed for onboarding new users.' },
    ]

    const allowedFeatures = checkNavPermissions(userRole, features)

    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header>
                    <Card.Img
                        src={logo}
                        alt="logo"
                        style={{ width: "150px", height: "auto", }}
                    />
                </Card.Header>
                <Card.Body>
                    <Card.Subtitle>
                        Company : {(process.env.REACT_APP_CLIENT_NAME)}
                    </Card.Subtitle>
                    <br />
                    <Card.Subtitle>
                        Environment : {(process.env.NODE_ENV)}
                    </Card.Subtitle>
                    <br />
                    <Card.Subtitle>
                        User : {user.email}
                    </Card.Subtitle>
                    <br />
                    <Card.Subtitle>
                        APP : {process.env.REACT_APP_VERSION}
                    </Card.Subtitle>
                    <br />
                    <Card.Subtitle>
                        API : {apihealth && (apihealth.version || '')}
                    </Card.Subtitle>
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Header><b>Mail Carrier TMS</b></Card.Header>
                {allowedFeatures.map((feature, i) => (
                    <Card.Body key={i}>
                        <Card.Text>
                            <b>{feature.icon} {feature.text}</b>  :  {feature.detail}
                        </Card.Text>
                    </Card.Body>
                ))}
            </Card>
        </Container>
    );
}

export default Home;