import React, { useEffect, useState, useMemo } from 'react';
import { Container, Card } from 'react-bootstrap';
import { RxCrossCircled, RxCheckCircled } from 'react-icons/rx';
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';

import KLEINSCHMIDT_IMG from '../../assets/b2b/kleinschmidt.jpg';
import SAMSARA_IMG from '../../assets/b2b/samsara.png';
import FOURKITES_IMG from '../../assets/b2b/fourkites.png';
import AlertDisplay from '../../components/AlertDisplay';

function LoadTenderIOT() {
    const { idToken } = useAuth();
    const [didSet, setDidSet] = useState(false);
    const [alerts, setAlerts] = useState([]);

    const [serviceStates, setServiceStates] = useState({
        Kleinschmidt: { component: null, color: '#fbb514', message: 'checking' },
        FourKites: { component: null, color: '#fbb514', message: 'checking' },
        Samsara: { component: null, color: '#fbb514', message: 'checking' },
    });

    const services = useMemo(() => [
        {
            name: 'Kleinschmidt',
            detail:'Load Tendering with USPS',
            image: KLEINSCHMIDT_IMG,
            checkFunction: async () => await Api.get('/edi/healthcheck', idToken),
        },
        {
            name: 'FourKites',
            detail:'3rd Party Load Tracking',
            image: FOURKITES_IMG,
            checkFunction: async () => await Api.get('/fourkites/healthcheck', idToken),
        },
        {
            name: 'Samsara',
            detail:'Electronic Logging Devices',
            image: SAMSARA_IMG,
            checkFunction: async () => await Api.get('/samsara/healthcheck', idToken),
        },
    ], [idToken]);

    useEffect(() => {
        const checkHealth = async () => {
            for (const service of services) {
                try {
                    const result = await service.checkFunction();
                    setServiceStates(prevState => ({
                        ...prevState,
                        [service.name]: {
                            component: result ? <RxCheckCircled /> : <RxCrossCircled />,
                            color: result ? '#488e5c' : '#dc3912',
                            message: result ? 'Established' : 'Error',
                        }
                    }));
                } catch (error) {
                    setAlerts(prevAlerts => [...prevAlerts, { variant: 'warning', message: `${service.name} error` }]);
                }
            }
            setDidSet(true);
        };

        if (!didSet) {
            checkHealth();
        }
    }, [didSet, services]);

    const renderServiceCards = () => {
        return Object.entries(serviceStates).map(([serviceName, serviceState], index) => (
            <Card key={index} style={{ width: '18rem', margin: '1em' }}>
                <Card.Img src={services.find(s => s?.name === serviceName).image} variant="top" style={{ padding: '1em', height: '11rem', objectFit: 'contain' }} />

                <Card.Header>
                    <h5>
                        Connection:
                        <span className='float-end' style={{ color: serviceState.color }}>
                            {serviceState.message} {serviceState.component}
                        </span>
                    </h5>
                </Card.Header>
                <Card.Footer>
                    {services.find(s => s.name === serviceName).detail}
                </Card.Footer>
            </Card>
        ));
    };

    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header>
                    <b>Load Tender IoT - Internet of Things</b>
                </Card.Header>
                <AlertDisplay alertState={{ alerts, setAlerts }} />
                <Card.Body className="d-flex justify-content-space-evenly align-items-center flex-wrap">
                    {renderServiceCards()}
                </Card.Body>
            </Card>
        </Container>
    );
}

export default LoadTenderIOT;
