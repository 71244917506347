const USPS_NASS_CODES = [
    {
      "Location ID": "006",
      "Location Name": "LOG SAN JUAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 585 AVE FD ROOSEVELT STE 370 SAN JUAN PR 00936 USA "
    },
    {
      "Location ID": "00601",
      "Location Name": "ADJUNTAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37 CALLE MUNOZ RIVERA ADJUNTAS PR 00601 USA "
    },
    {
      "Location ID": "00602",
      "Location Name": "AGUADA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 AVE NATIVO ALERS AGUADA PR 00602 USA "
    },
    {
      "Location ID": "00603",
      "Location Name": "AGUADILLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 CARR 459 STE 1 AGUADILLA PR 00603 USA "
    },
    {
      "Location ID": "00606",
      "Location Name": "MARICAO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 CALLE ZUZUARREGUI FRNT MARICAO PR 00606 USA "
    },
    {
      "Location ID": "00610",
      "Location Name": "ANASCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 93 CALLE 65 INFANTERIA STE 1 ANASCO PR 00610 USA "
    },
    {
      "Location ID": "00611",
      "Location Name": "ANGELES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 CARR 111 ANGELES PR 00611 USA "
    },
    {
      "Location ID": "00612",
      "Location Name": "ARECIBO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 AVE SAN PATRICIO ARECIBO PR 00612 USA "
    },
    {
      "Location ID": "00616",
      "Location Name": "BAJADERO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 CARR 656 BAJADERO PR 00616 USA "
    },
    {
      "Location ID": "00617",
      "Location Name": "BARCELONETA",
      "Time Zone Offset (Hours)": 0,
      "Address": " H1 CALLE 5 BARCELONETA PR 00617 USA "
    },
    {
      "Location ID": "00622",
      "Location Name": "BOQUERON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 CARR 101 BOQUERON PR 00622 USA "
    },
    {
      "Location ID": "00623",
      "Location Name": "CABO ROJO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 64 CALLE CARBONELL CABO ROJO PR 00623 USA "
    },
    {
      "Location ID": "00624",
      "Location Name": "PENUELAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 CARR 132 PENUELAS PR 00624 USA "
    },
    {
      "Location ID": "00627",
      "Location Name": "CAMUY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 CALLE CORREO CAMUY PR 00627 USA "
    },
    {
      "Location ID": "00638",
      "Location Name": "CIALES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 CARR 149 CIALES PR 00638 USA "
    },
    {
      "Location ID": "00646",
      "Location Name": "DORADO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 CARR 698 DORADO PR 00646 USA "
    },
    {
      "Location ID": "00650",
      "Location Name": "FLORIDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 66 CALLE MUNOZ RIVERA FLORIDA PR 00650 USA "
    },
    {
      "Location ID": "00652",
      "Location Name": "GARROCHALES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 CARR 682 GARROCHALES PR 00652 USA "
    },
    {
      "Location ID": "00656",
      "Location Name": "GUAYANILLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 285 CALLE LUIS MUNOZ RIVERA GUAYANILLA PR 00656 USA "
    },
    {
      "Location ID": "00659",
      "Location Name": "HATILLO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 192 AVE DR SUSONI HATILLO PR 00659 USA "
    },
    {
      "Location ID": "00660",
      "Location Name": "HORMIGUEROS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 CARR 344 HORMIGUEROS PR 00660 USA "
    },
    {
      "Location ID": "00662",
      "Location Name": "ISABELA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 CALLE PINEIRO ISABELA PR 00662 USA "
    },
    {
      "Location ID": "00664",
      "Location Name": "JAYUYA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 CALLE A BARCELO STE 2 JAYUYA PR 00664 USA "
    },
    {
      "Location ID": "00667",
      "Location Name": "LAJAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 72 CALLE 65 DE INFANTERIA S LAJAS PR 00667 USA "
    },
    {
      "Location ID": "00669",
      "Location Name": "LARES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 AVE LOS PATRIOTAS STE 1 LARES PR 00669 USA "
    },
    {
      "Location ID": "00670",
      "Location Name": "LAS MARIAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 78 AVE MATIAS BRUGMAN LAS MARIAS PR 00670 USA "
    },
    {
      "Location ID": "00674",
      "Location Name": "MANATI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 CALLE ELLIOT VELEZ MANATI PR 00674 USA "
    },
    {
      "Location ID": "00676",
      "Location Name": "MOCA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 212 AVE LA MOCA MOCA PR 00676 USA "
    },
    {
      "Location ID": "00677",
      "Location Name": "RINCON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 CARR 115 RINCON PR 00677 USA "
    },
    {
      "Location ID": "00678",
      "Location Name": "QUEBRADILLAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 64 CALLE JOSE L LINARES QUEBRADILLAS PR 00678 USA "
    },
    {
      "Location ID": "00680",
      "Location Name": "MAYAGUEZ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 CALLE MCKINLEY W MAYAGUEZ PR 00680 USA "
    },
    {
      "Location ID": "00682",
      "Location Name": "MARINA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 270 CALLE DE LA CANDELARIA MAYAGUEZ PR 00682 USA "
    },
    {
      "Location ID": "00683",
      "Location Name": "SAN GERMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 181 AVE UNIV INTERAMERICANA SAN GERMAN PR 00683 USA "
    },
    {
      "Location ID": "00685",
      "Location Name": "SAN SEBASTIAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 211 CALLE RUIZ BELVIS SAN SEBASTIAN PR 00685 USA "
    },
    {
      "Location ID": "00687",
      "Location Name": "MOROVIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 CARR 155 MOROVIS PR 00687 USA "
    },
    {
      "Location ID": "00688",
      "Location Name": "SABANA HOYOS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 CARR 639 SABANA HOYOS PR 00688 USA "
    },
    {
      "Location ID": "00690",
      "Location Name": "SAN ANTONIO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1068 AVE GENERAL RAMEY SAN ANTONIO PR 00690 USA "
    },
    {
      "Location ID": "00692",
      "Location Name": "VEGA ALTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 CARR 2 VEGA ALTA PR 00692 USA "
    },
    {
      "Location ID": "00693",
      "Location Name": "VEGA BAJA",
      "Time Zone Offset (Hours)": 0,
      "Address": " A10 CALLE 2 VEGA BAJA PR 00693 USA "
    },
    {
      "Location ID": "00698",
      "Location Name": "YAUCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 329 YAUCO PLAZA I YAUCO PR 00698 USA "
    },
    {
      "Location ID": "00703",
      "Location Name": "AGUAS BUENAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 CALLE PIO RECHANI AGUAS BUENAS PR 00703 USA "
    },
    {
      "Location ID": "00704",
      "Location Name": "AGUIRRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 CALLE PRINCIPAL AGUIRRE PR 00704 USA "
    },
    {
      "Location ID": "00705",
      "Location Name": "AIBONITO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 CALLE IGNACIO LOPEZ AIBONITO PR 00705 USA "
    },
    {
      "Location ID": "00714",
      "Location Name": "ARROYO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 CALLE MORSE ARROYO PR 00714 USA "
    },
    {
      "Location ID": "00715",
      "Location Name": "MERCEDITA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 TURPEAUX IND PARK MERCEDITA PR 00715 USA "
    },
    {
      "Location ID": "00718",
      "Location Name": "NAGUABO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 CARR 31 NAGUABO PR 00718 USA "
    },
    {
      "Location ID": "00719",
      "Location Name": "NARANJITO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 CALLE GEORGETTI NARANJITO PR 00719 USA "
    },
    {
      "Location ID": "00720",
      "Location Name": "OROCOVIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 AVE ERNESTO RAMOS ANTONINI OROCOVIS PR 00720 USA "
    },
    {
      "Location ID": "00721",
      "Location Name": "PALMER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23 CALLE PRINCIPAL PALMER PR 00721 USA "
    },
    {
      "Location ID": "00723",
      "Location Name": "PATILLAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 CARR 3 PATILLAS PR 00723 USA "
    },
    {
      "Location ID": "00725",
      "Location Name": "CAGUAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 225 CALLE GAUTIER BENITEZ CAGUAS PR 00725 USA "
    },
    {
      "Location ID": "00729",
      "Location Name": "CANOVANAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18400 CARR 3 STE 136 CANOVANAS PR 00729 USA "
    },
    {
      "Location ID": "00731",
      "Location Name": "PONCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2340 AVE EDUARDO RUBERTE PONCE PR 00717 USA "
    },
    {
      "Location ID": "00735",
      "Location Name": "CEIBA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 305 AVE LAURO PINERO CEIBA PR 00735 USA "
    },
    {
      "Location ID": "00736",
      "Location Name": "CAYEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 AVE BALDORIOTY E CAYEY PR 00736 USA "
    },
    {
      "Location ID": "00738",
      "Location Name": "FAJARDO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 CALLE GARRIDO MORALES W FAJARDO PR 00738 USA "
    },
    {
      "Location ID": "00739",
      "Location Name": "CIDRA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 AVE INDUSTRIAL CIDRA PR 00739 USA "
    },
    {
      "Location ID": "00740",
      "Location Name": "PUERTO REAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 477 CALLE UN PUERTO REAL PR 00740 USA "
    },
    {
      "Location ID": "00741",
      "Location Name": "PUNTA SANTIAGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 CALLE ADUANA PUNTA SANTIAGO PR 00741 USA "
    },
    {
      "Location ID": "00744",
      "Location Name": "RIO BLANCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 CARR 31 RIO BLANCO PR 00744 USA "
    },
    {
      "Location ID": "00745",
      "Location Name": "RIO GRANDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 99 CALLE PIMENTEL STE 1 RIO GRANDE PR 00745 USA "
    },
    {
      "Location ID": "00751",
      "Location Name": "SALINAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 CARR PLAYA SALINAS PR 00751 USA "
    },
    {
      "Location ID": "00757",
      "Location Name": "SANTA ISABEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 CALLE LUIS MUNOZ RIVERA STE 1 SANTA ISABEL PR 00757 USA "
    },
    {
      "Location ID": "00769",
      "Location Name": "COAMO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 CALLE A STE 1 COAMO PR 00769 USA "
    },
    {
      "Location ID": "00771",
      "Location Name": "LAS PIEDRAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 77 CALLE JT PINERO STE 1 LAS PIEDRAS PR 00771 USA "
    },
    {
      "Location ID": "00772",
      "Location Name": "LOIZA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 64 CALLE SAN PATRICIO LOIZA PR 00772 USA "
    },
    {
      "Location ID": "00773",
      "Location Name": "LUQUILLO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 160 CALLE QUINTO CENTENARIO LUQUILLO PR 00773 USA "
    },
    {
      "Location ID": "00775",
      "Location Name": "CULEBRA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 CALLE PEDRO MARQUEZ CULEBRA PR 00775 USA "
    },
    {
      "Location ID": "00777",
      "Location Name": "JUNCOS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 CALLE MUNOZ RIVERA JUNCOS PR 00777 USA "
    },
    {
      "Location ID": "00778",
      "Location Name": "GURABO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 CALLE ANGEL C MORALES E GURABO PR 00778 USA "
    },
    {
      "Location ID": "00780",
      "Location Name": "COTO LAUREL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3007 AVE SAN CRISTOBAL COTO LAUREL PR 00780 USA "
    },
    {
      "Location ID": "00782",
      "Location Name": "COMERIO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37 CARR 778 STE 3 COMERIO PR 00782 USA "
    },
    {
      "Location ID": "00783",
      "Location Name": "COROZAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 COROZAL SHOP CTR STE 104 COROZAL PR 00783 USA "
    },
    {
      "Location ID": "00784",
      "Location Name": "GUAYAMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 151 CALLE ASHFORD S GUAYAMA PR 00784 USA "
    },
    {
      "Location ID": "00786",
      "Location Name": "LA PLATA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 CARR 173 LA PLATA PR 00786 USA "
    },
    {
      "Location ID": "00791",
      "Location Name": "HUMACAO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 122 CALLE FONT MARTELO HUMACAO PR 00791 USA "
    },
    {
      "Location ID": "00794",
      "Location Name": "BARRANQUITAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41 CALLE MUNOZ RIVERA FRNT BARRANQUITAS PR 00794 USA "
    },
    {
      "Location ID": "00795",
      "Location Name": "JUANA DIAZ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 72 CALLE EXT MUNOZ RIVERA JUANA DIAZ PR 00795 USA "
    },
    {
      "Location ID": "00820",
      "Location Name": "CHRISTIANSTED",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1104 ESTATE RICHMOND CHRISTIANSTED VI 00820 USA "
    },
    {
      "Location ID": "00840",
      "Location Name": "FREDERIKSTED",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 MARS HL FREDERIKSTED VI 00840 USA "
    },
    {
      "Location ID": "00850",
      "Location Name": "KINGSHILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 EST LA REINE KINGSHILL VI 00850 USA "
    },
    {
      "Location ID": "00936",
      "Location Name": "SAN JUAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 585 AVE FD ROOSEVELT STE 202 SAN JUAN PR 00936 USA "
    },
    {
      "Location ID": "00949",
      "Location Name": "TOA BAJA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 CALLE DR HERNAN CORTES STE TOA BAJA PR 00949 USA "
    },
    {
      "Location ID": "00950",
      "Location Name": "TOA BAJA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 CALLE DR HERNAN CORTES STE 1 TOA BAJA PR 00949 USA "
    },
    {
      "Location ID": "00952",
      "Location Name": "SABANA SECA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 CARR 866 SABANA SECA PR 00952 USA "
    },
    {
      "Location ID": "00953",
      "Location Name": "TOA ALTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 CARR 165 STE 1 TOA ALTA PR 00953 USA "
    },
    {
      "Location ID": "00958",
      "Location Name": "BAYAMON GDNS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 VICTORY SHOPP CTR STE 1 BAYAMON PR 00957 USA "
    },
    {
      "Location ID": "00960",
      "Location Name": "BAYAMON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 AVE RAMON L RODRIGUEZ BAYAMON PR 00959 USA "
    },
    {
      "Location ID": "00962",
      "Location Name": "CATANO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 131 AVE BARBOSA CATANO PR 00962 USA "
    },
    {
      "Location ID": "00964",
      "Location Name": "LOG DMDU ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 AVE CONQUISTADORES CATANO PR 00962 USA "
    },
    {
      "Location ID": "00970",
      "Location Name": "GUAYNABO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 CALLE CECILIO URBINA GUAYNABO PR 00969 USA "
    },
    {
      "Location ID": "00984",
      "Location Name": "CAROLINA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 AVE SANCHEZ OSORIO CAROLINA PR 00983 USA "
    },
    {
      "Location ID": "009MA",
      "Location Name": "LOG SAN JUAN MPA3",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 CALLE GILDITA STE 635 CAROLINA PR 00983 USA "
    },
    {
      "Location ID": "009MP",
      "Location Name": "LOG SAN JUAN MPA2",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 CALLE GILDITA STE 501 CAROLINA PR 00983 USA "
    },
    {
      "Location ID": "01001",
      "Location Name": "AGAWAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 SUFFIELD ST AGAWAM MA 01001 USA "
    },
    {
      "Location ID": "01002",
      "Location Name": "AMHERST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 145 UNIVERSITY DR AMHERST MA 01002 USA "
    },
    {
      "Location ID": "01005",
      "Location Name": "BARRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 SOUTH ST BARRE MA 01005 USA "
    },
    {
      "Location ID": "01007",
      "Location Name": "BELCHERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 MAIN ST BELCHERTOWN MA 01007 USA "
    },
    {
      "Location ID": "01011",
      "Location Name": "CHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 309 ROUTE 20 CHESTER MA 01011 USA "
    },
    {
      "Location ID": "01020",
      "Location Name": "CHICOPEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 MAIN ST CHICOPEE MA 01020 USA "
    },
    {
      "Location ID": "01026",
      "Location Name": "CUMMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 MAIN ST STE 103 CUMMINGTON MA 01026 USA "
    },
    {
      "Location ID": "01027",
      "Location Name": "EASTHAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 191 NORTHAMPTON ST EASTHAMPTON MA 01027 USA "
    },
    {
      "Location ID": "01028",
      "Location Name": "EAST LONGMEADOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 INDUSTRIAL DR EAST LONGMEADOW MA 01028 USA "
    },
    {
      "Location ID": "01029",
      "Location Name": "EAST OTIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1722 E OTIS RD EAST OTIS MA 01029 USA "
    },
    {
      "Location ID": "01030",
      "Location Name": "FEEDING HILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1339 SPRINGFIELD ST FEEDING HILLS MA 01030 USA "
    },
    {
      "Location ID": "01031",
      "Location Name": "GILBERTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 NORTH ST GILBERTVILLE MA 01031 USA "
    },
    {
      "Location ID": "01032",
      "Location Name": "GOSHEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33C MAIN ST GOSHEN MA 01032 USA "
    },
    {
      "Location ID": "01034",
      "Location Name": "GRANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 467 MAIN RD GRANVILLE MA 01034 USA "
    },
    {
      "Location ID": "01036",
      "Location Name": "HAMPDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 534 MAIN ST HAMPDEN MA 01036 USA "
    },
    {
      "Location ID": "01037",
      "Location Name": "HARDWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 BARRE RD HARDWICK MA 01037 USA "
    },
    {
      "Location ID": "01038",
      "Location Name": "HATFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 SCHOOL ST HATFIELD MA 01038 USA "
    },
    {
      "Location ID": "01040",
      "Location Name": "HOLYOKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 650 DWIGHT ST HOLYOKE MA 01040 USA "
    },
    {
      "Location ID": "01050",
      "Location Name": "HUNTINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 RUSSELL RD HUNTINGTON MA 01050 USA "
    },
    {
      "Location ID": "01054",
      "Location Name": "LEVERETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 MONTAGUE RD LEVERETT MA 01054 USA "
    },
    {
      "Location ID": "01057",
      "Location Name": "MONSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 MAIN ST MONSON MA 01057 USA "
    },
    {
      "Location ID": "01060",
      "Location Name": "NORTHAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37 BRIDGE ST NORTHAMPTON MA 01060 USA "
    },
    {
      "Location ID": "01066",
      "Location Name": "NORTH HATFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 162 DEPOT RD NORTH HATFIELD MA 01066 USA "
    },
    {
      "Location ID": "01068",
      "Location Name": "OAKHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 541 N BROOKFIELD RD OAKHAM MA 01068 USA "
    },
    {
      "Location ID": "01069",
      "Location Name": "PALMER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1057 PARK ST PALMER MA 01069 USA "
    },
    {
      "Location ID": "01070",
      "Location Name": "PLAINFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 S CENTRAL ST PLAINFIELD MA 01070 USA "
    },
    {
      "Location ID": "01071",
      "Location Name": "RUSSELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 58 MAIN ST RUSSELL MA 01071 USA "
    },
    {
      "Location ID": "01072",
      "Location Name": "SHUTESBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 WENDELL RD SHUTESBURY MA 01072 USA "
    },
    {
      "Location ID": "01073",
      "Location Name": "SOUTHAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 POMEROY MEADOW RD SOUTHAMPTON MA 01073 USA "
    },
    {
      "Location ID": "01074",
      "Location Name": "SOUTH BARRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 46 MAIN ST SOUTH BARRE MA 01074 USA "
    },
    {
      "Location ID": "01075",
      "Location Name": "SOUTH HADLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 HADLEY ST SOUTH HADLEY MA 01075 USA "
    },
    {
      "Location ID": "01077",
      "Location Name": "SOUTHWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 653 COLLEGE HWY SOUTHWICK MA 01077 USA "
    },
    {
      "Location ID": "01079",
      "Location Name": "THORNDIKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 CHURCH ST THORNDIKE MA 01079 USA "
    },
    {
      "Location ID": "01080",
      "Location Name": "THREE RIVERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2020 BRIDGE ST THREE RIVERS MA 01080 USA "
    },
    {
      "Location ID": "01082",
      "Location Name": "WARE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 165 WEST ST WARE MA 01082 USA "
    },
    {
      "Location ID": "01083",
      "Location Name": "WARREN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 MILTON O FOUNTAIN WAY WARREN MA 01083 USA "
    },
    {
      "Location ID": "01085",
      "Location Name": "WESTFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 W SILVER ST WESTFIELD MA 01085 USA "
    },
    {
      "Location ID": "01092",
      "Location Name": "WEST WARREN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2282 MAIN ST WEST WARREN MA 01092 USA "
    },
    {
      "Location ID": "01093",
      "Location Name": "WHATELY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 196 CHESTNUT PLAIN RD WHATELY MA 01093 USA "
    },
    {
      "Location ID": "01094",
      "Location Name": "WHEELWRIGHT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 CHURCH LN WHEELWRIGHT MA 01094 USA "
    },
    {
      "Location ID": "01095",
      "Location Name": "WILBRAHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 POST OFFICE PARK WILBRAHAM MA 01095 USA "
    },
    {
      "Location ID": "01096",
      "Location Name": "WILLIAMSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 WILLIAMS ST WILLIAMSBURG MA 01096 USA "
    },
    {
      "Location ID": "010CC",
      "Location Name": "CT NMO ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24A RESEARCH PKWY WALLINGFORD CT 06492 USA "
    },
    {
      "Location ID": "010FZ",
      "Location Name": "LOG SPRINGFIELD MA PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 BROOKDALE DR STE DK13 SPRINGFIELD MA 01104 USA "
    },
    {
      "Location ID": "010PM",
      "Location Name": "LOG SPRINGFIELD LDC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1800L PAGE BLVD SPRINGFIELD MA 01152 USA "
    },
    {
      "Location ID": "012",
      "Location Name": "PITTSFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 212 FENN ST PITTSFIELD MA 01201 USA "
    },
    {
      "Location ID": "01220",
      "Location Name": "ADAMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 CENTER ST ADAMS MA 01220 USA "
    },
    {
      "Location ID": "01222",
      "Location Name": "ASHLEY FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1180 ASHLEY FALLS RD ASHLEY FALLS MA 01222 USA "
    },
    {
      "Location ID": "01223",
      "Location Name": "BECKET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 623 MAIN ST BECKET MA 01223 USA "
    },
    {
      "Location ID": "01225",
      "Location Name": "CHESHIRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 214 CHURCH ST CHESHIRE MA 01225 USA "
    },
    {
      "Location ID": "01226",
      "Location Name": "DALTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 609 MAIN ST DALTON MA 01226 USA "
    },
    {
      "Location ID": "01230",
      "Location Name": "GREAT BARRINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 222 MAIN ST GREAT BARRINGTON MA 01230 USA "
    },
    {
      "Location ID": "01235",
      "Location Name": "HINSDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 MAPLE ST HINSDALE MA 01235 USA "
    },
    {
      "Location ID": "01236",
      "Location Name": "HOUSATONIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 DEPOT ST HOUSATONIC MA 01236 USA "
    },
    {
      "Location ID": "01237",
      "Location Name": "LANESBOROUGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 545 S MAIN ST LANESBOROUGH MA 01237 USA "
    },
    {
      "Location ID": "01238",
      "Location Name": "LEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 FRANK P CONSOLATI WAY LEE MA 01238 USA "
    },
    {
      "Location ID": "0123H",
      "Location Name": "HANCOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37 COREY RD HANCOCK MA 01237 USA "
    },
    {
      "Location ID": "01240",
      "Location Name": "LENOX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 68 MAIN ST STE A LENOX MA 01240 USA "
    },
    {
      "Location ID": "01242",
      "Location Name": "LENOX DALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 42 CRYSTAL ST LENOX DALE MA 01242 USA "
    },
    {
      "Location ID": "01243",
      "Location Name": "MIDDLEFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 BELL RD MIDDLEFIELD MA 01243 USA "
    },
    {
      "Location ID": "01245",
      "Location Name": "MONTEREY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 444 MAIN RD MONTEREY MA 01245 USA "
    },
    {
      "Location ID": "01247",
      "Location Name": "NORTH ADAMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 67 SUMMER ST NORTH ADAMS MA 01247 USA "
    },
    {
      "Location ID": "01253",
      "Location Name": "OTIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 45 N MAIN RD OTIS MA 01253 USA "
    },
    {
      "Location ID": "01254",
      "Location Name": "RICHMOND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2089 STATE RD RICHMOND MA 01254 USA "
    },
    {
      "Location ID": "01257",
      "Location Name": "SHEFFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 99 MAIN ST SHEFFIELD MA 01257 USA "
    },
    {
      "Location ID": "01260",
      "Location Name": "SOUTH LEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 CHURCH ST SOUTH LEE MA 01260 USA "
    },
    {
      "Location ID": "01262",
      "Location Name": "STOCKBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 ELM ST STOCKBRIDGE MA 01262 USA "
    },
    {
      "Location ID": "01264",
      "Location Name": "TYRINGHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 MAIN RD TYRINGHAM MA 01264 USA "
    },
    {
      "Location ID": "01266",
      "Location Name": "WEST STOCKBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 CENTER ST WEST STOCKBRIDGE MA 01266 USA "
    },
    {
      "Location ID": "01267",
      "Location Name": "WILLIAMSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 56 SPRING ST WILLIAMSTOWN MA 01267 USA "
    },
    {
      "Location ID": "012AG",
      "Location Name": "AZURE GREEN (BISS CO), MA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 BELL RD MIDDLEFIELD MA 01243 USA "
    },
    {
      "Location ID": "012EX",
      "Location Name": "EXCELSIOR INTEGRATED",
      "Time Zone Offset (Hours)": 0,
      "Address": " 705 PLEASANT ST LEE MA 01238 USA "
    },
    {
      "Location ID": "012HC",
      "Location Name": "HANCOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37 COREY RD HANCOCK MA 01237 USA "
    },
    {
      "Location ID": "013",
      "Location Name": "GREENFIELD POST OFFICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 442 MAIN ST GREENFIELD MA 01301 USA "
    },
    {
      "Location ID": "01330",
      "Location Name": "ASHFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 258 MAIN ST ASHFIELD MA 01330 USA "
    },
    {
      "Location ID": "01331",
      "Location Name": "ATHOL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 242 MAIN ST ATHOL MA 01331 USA "
    },
    {
      "Location ID": "01338",
      "Location Name": "BUCKLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 67 UPPER ST BUCKLAND MA 01338 USA "
    },
    {
      "Location ID": "01339",
      "Location Name": "CHARLEMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90-3 MAIN ST CHARLEMONT MA 01339 USA "
    },
    {
      "Location ID": "01340",
      "Location Name": "COLRAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 MAIN RD COLRAIN MA 01340 USA "
    },
    {
      "Location ID": "01341",
      "Location Name": "CONWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 38 RIVER ST CONWAY MA 01341 USA "
    },
    {
      "Location ID": "01342",
      "Location Name": "DEERFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 75 OLD MAIN ST DEERFIELD MA 01342 USA "
    },
    {
      "Location ID": "01364",
      "Location Name": "ORANGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 72 S MAIN ST ORANGE MA 01364 USA "
    },
    {
      "Location ID": "01370",
      "Location Name": "SHELBURNE FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 CHURCH ST SHELBURNE FALLS MA 01370 USA "
    },
    {
      "Location ID": "01373",
      "Location Name": "SOUTH DEERFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 SUGARLOAF ST STE 1 SOUTH DEERFIELD MA 01373 USA "
    },
    {
      "Location ID": "01375",
      "Location Name": "SUNDERLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 GARAGE RD SUNDERLAND MA 01375 USA "
    },
    {
      "Location ID": "01376",
      "Location Name": "TURNERS FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 178 AVENUE A TURNERS FALLS MA 01376 USA "
    },
    {
      "Location ID": "01379",
      "Location Name": "WENDELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 59 LOCKES VILLAGE RD WENDELL MA 01379 USA "
    },
    {
      "Location ID": "01380",
      "Location Name": "WENDELL DEPOT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 466 WENDELL DEPOT RD WENDELL DEPOT MA 01380 USA "
    },
    {
      "Location ID": "013GA",
      "Location Name": "GREENFIELD ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 ADAMS RD GREENFIELD MA 01301 USA "
    },
    {
      "Location ID": "01420",
      "Location Name": "FITCHBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 881 MAIN ST STE E FITCHBURG MA 01420 USA "
    },
    {
      "Location ID": "01430",
      "Location Name": "ASHBURNHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 CENTRAL ST ASHBURNHAM MA 01430 USA "
    },
    {
      "Location ID": "01431",
      "Location Name": "ASHBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 COMMON RD STE 2 ASHBY MA 01431 USA "
    },
    {
      "Location ID": "01432",
      "Location Name": "AYER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 COLUMBIA ST AYER MA 01432 USA "
    },
    {
      "Location ID": "01436",
      "Location Name": "BALDWINVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 38 ELM ST BALDWINVILLE MA 01436 USA "
    },
    {
      "Location ID": "01438",
      "Location Name": "EAST TEMPLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 132B PATRIOTS RD EAST TEMPLETON MA 01438 USA "
    },
    {
      "Location ID": "01440",
      "Location Name": "GARDNER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 69 PLEASANT ST GARDNER MA 01440 USA "
    },
    {
      "Location ID": "01450",
      "Location Name": "GROTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 BOSTON RD GROTON MA 01450 USA "
    },
    {
      "Location ID": "01451",
      "Location Name": "HARVARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 AYER RD HARVARD MA 01451 USA "
    },
    {
      "Location ID": "01452",
      "Location Name": "HUBBARDSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 MAIN ST HUBBARDSTON MA 01452 USA "
    },
    {
      "Location ID": "01453",
      "Location Name": "LEOMINSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 68 MAIN ST LEOMINSTER MA 01453 USA "
    },
    {
      "Location ID": "01460",
      "Location Name": "LITTLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 451 KING ST LITTLETON MA 01460 USA "
    },
    {
      "Location ID": "01462",
      "Location Name": "LUNENBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 945 MASSACHUSETTS AVE LUNENBURG MA 01462 USA "
    },
    {
      "Location ID": "01463",
      "Location Name": "PEPPERELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41 LOWELL RD PEPPERELL MA 01463 USA "
    },
    {
      "Location ID": "01464",
      "Location Name": "SHIRLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23 MAIN ST SHIRLEY MA 01464 USA "
    },
    {
      "Location ID": "01468",
      "Location Name": "TEMPLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 449 PATRIOTS RD TEMPLETON MA 01468 USA "
    },
    {
      "Location ID": "01469",
      "Location Name": "TOWNSEND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 227 MAIN ST TOWNSEND MA 01469 USA "
    },
    {
      "Location ID": "01472",
      "Location Name": "WEST GROTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 PEPPERELL RD WEST GROTON MA 01472 USA "
    },
    {
      "Location ID": "01473",
      "Location Name": "WESTMINSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 NICHOLS ST WESTMINSTER MA 01473 USA "
    },
    {
      "Location ID": "01475",
      "Location Name": "WINCHENDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 160 CENTRAL ST WINCHENDON MA 01475 USA "
    },
    {
      "Location ID": "014HS",
      "Location Name": "HARWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 TAYLOR ST LITTLETON MA 01460 USA "
    },
    {
      "Location ID": "014PC",
      "Location Name": "PRICHARD PLAZA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 477 MAIN ST FITCHBURG MA 01420 USA "
    },
    {
      "Location ID": "014RH",
      "Location Name": "REGIONAL HOME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 SCALES LN TOWNSEND MA 01469 USA "
    },
    {
      "Location ID": "014UA",
      "Location Name": "UNION ABRASIVES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 LOMAR PARK DRIVE PEPPERELL MA 01463 USA "
    },
    {
      "Location ID": "015",
      "Location Name": "CENTRAL MASS P&DC, MA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 192 MAIN ST SHREWSBURY MA 01546 USA "
    },
    {
      "Location ID": "01503",
      "Location Name": "BERLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 260 PLEASANT ST BERLIN MA 01503 USA "
    },
    {
      "Location ID": "01504",
      "Location Name": "BLACKSTONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 149 MAIN ST BLACKSTONE MA 01504 USA "
    },
    {
      "Location ID": "01505",
      "Location Name": "BOYLSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 67 SHREWSBURY ST BOYLSTON MA 01505 USA "
    },
    {
      "Location ID": "01506",
      "Location Name": "BROOKFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 POST RD BROOKFIELD MA 01506 USA "
    },
    {
      "Location ID": "01510",
      "Location Name": "CLINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 320 HIGH ST CLINTON MA 01510 USA "
    },
    {
      "Location ID": "01515",
      "Location Name": "EAST BROOKFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 MECHANIC ST EAST BROOKFIELD MA 01515 USA "
    },
    {
      "Location ID": "01516",
      "Location Name": "DOUGLAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 331 MAIN ST DOUGLAS MA 01516 USA "
    },
    {
      "Location ID": "01519",
      "Location Name": "GRAFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23 UPTON ST GRAFTON MA 01519 USA "
    },
    {
      "Location ID": "01520",
      "Location Name": "HOLDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1037 MAIN ST HOLDEN MA 01520 USA "
    },
    {
      "Location ID": "01522",
      "Location Name": "JEFFERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 140 PRINCETON ST JEFFERSON MA 01522 USA "
    },
    {
      "Location ID": "01523",
      "Location Name": "LANCASTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 771 MAIN ST LANCASTER MA 01523 USA "
    },
    {
      "Location ID": "01524",
      "Location Name": "LEICESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1199 MAIN ST STE 1 LEICESTER MA 01524 USA "
    },
    {
      "Location ID": "01525",
      "Location Name": "LINWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 166 LINWOOD ST LINWOOD MA 01525 USA "
    },
    {
      "Location ID": "01526",
      "Location Name": "MANCHAUG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 359 MANCHAUG RD MANCHAUG MA 01526 USA "
    },
    {
      "Location ID": "01527",
      "Location Name": "MILLBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 ELM ST MILLBURY MA 01527 USA "
    },
    {
      "Location ID": "01529",
      "Location Name": "MILLVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31 CENTRAL ST MILLVILLE MA 01529 USA "
    },
    {
      "Location ID": "01531",
      "Location Name": "NEW BRAINTREE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 UTLEY RD NEW BRAINTREE MA 01531 USA "
    },
    {
      "Location ID": "01532",
      "Location Name": "NORTHBOROUGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 235 W MAIN ST NORTHBOROUGH MA 01532 USA "
    },
    {
      "Location ID": "01534",
      "Location Name": "NORTHBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 76 SUTTON ST STE 2 NORTHBRIDGE MA 01534 USA "
    },
    {
      "Location ID": "01535",
      "Location Name": "NORTH BROOKFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 357 N MAIN ST NORTH BROOKFIELD MA 01535 USA "
    },
    {
      "Location ID": "01536",
      "Location Name": "NORTH GRAFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 191 WORCESTER ST NORTH GRAFTON MA 01536 USA "
    },
    {
      "Location ID": "01538",
      "Location Name": "NORTH UXBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 298 N MAIN ST NORTH UXBRIDGE MA 01538 USA "
    },
    {
      "Location ID": "01541",
      "Location Name": "PRINCETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 WORCESTER RD PRINCETON MA 01541 USA "
    },
    {
      "Location ID": "01542",
      "Location Name": "ROCHDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1138 STAFFORD ST ROCHDALE MA 01542 USA "
    },
    {
      "Location ID": "01543",
      "Location Name": "RUTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 MAPLE AVE RUTLAND MA 01543 USA "
    },
    {
      "Location ID": "01560",
      "Location Name": "SOUTH GRAFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 FERRY ST SOUTH GRAFTON MA 01560 USA "
    },
    {
      "Location ID": "01561",
      "Location Name": "SOUTH LANCASTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 93 SAWYER ST SOUTH LANCASTER MA 01561 USA "
    },
    {
      "Location ID": "01562",
      "Location Name": "SPENCER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 MECHANIC ST SPENCER MA 01562 USA "
    },
    {
      "Location ID": "01564",
      "Location Name": "STERLING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 CLINTON RD STERLING MA 01564 USA "
    },
    {
      "Location ID": "01568",
      "Location Name": "UPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 MAIN ST UPTON MA 01568 USA "
    },
    {
      "Location ID": "01569",
      "Location Name": "UXBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 287 MILLVILLE RD UXBRIDGE MA 01569 USA "
    },
    {
      "Location ID": "01581",
      "Location Name": "WESTBOROUGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 E MAIN ST WESTBOROUGH MA 01581 USA "
    },
    {
      "Location ID": "01583",
      "Location Name": "WEST BOYLSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 67 W BOYLSTON ST UNIT 5 WEST BOYLSTON MA 01583 USA "
    },
    {
      "Location ID": "01585",
      "Location Name": "WEST BROOKFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 CENTRAL ST WEST BROOKFIELD MA 01585 USA "
    },
    {
      "Location ID": "01588",
      "Location Name": "WHITINSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44 CHURCH ST WHITINSVILLE MA 01588 USA "
    },
    {
      "Location ID": "01590",
      "Location Name": "SUTTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 156 WORCESTER PROVIDENCE TPKE STE 12 SUTTON MA 01590 USA "
    },
    {
      "Location ID": "015AA",
      "Location Name": "SHREWSBURY ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 194 MAIN ST SHREWSBURY MA 01545 USA "
    },
    {
      "Location ID": "015FZ",
      "Location Name": "LOG CENTRAL MA PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 CONSTITUTION BLVD FRANKLIN MA 02038 USA "
    },
    {
      "Location ID": "015PD",
      "Location Name": "015PD MA FEDEX SMARTPOST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 BEEMAN RD NORTHBOROUGH MA 01532 USA "
    },
    {
      "Location ID": "01701",
      "Location Name": "FRAMINGHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 330 COCHITUATE RD FRAMINGHAM MA 01701 USA "
    },
    {
      "Location ID": "01721",
      "Location Name": "ASHLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 MAIN ST ASHLAND MA 01721 USA "
    },
    {
      "Location ID": "01740",
      "Location Name": "BOLTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1092 MAIN ST BOLTON MA 01740 USA "
    },
    {
      "Location ID": "01746",
      "Location Name": "HOLLISTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1661 WASHINGTON ST HOLLISTON MA 01746 USA "
    },
    {
      "Location ID": "01747",
      "Location Name": "HOPEDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 HOPEDALE ST HOPEDALE MA 01747 USA "
    },
    {
      "Location ID": "01748",
      "Location Name": "HOPKINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 CEDAR ST HOPKINTON MA 01748 USA "
    },
    {
      "Location ID": "01749",
      "Location Name": "HUDSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 CHURCH ST HUDSON MA 01749 USA "
    },
    {
      "Location ID": "01752",
      "Location Name": "MARLBOROUGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 FLORENCE ST MARLBOROUGH MA 01752 USA "
    },
    {
      "Location ID": "01754",
      "Location Name": "MAYNARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 143 MAIN ST MAYNARD MA 01754 USA "
    },
    {
      "Location ID": "01756",
      "Location Name": "MENDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 MAPLE ST MENDON MA 01756 USA "
    },
    {
      "Location ID": "01757",
      "Location Name": "MILFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 CONGRESS ST STE 1 MILFORD MA 01757 USA "
    },
    {
      "Location ID": "01760",
      "Location Name": "NATICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 HURON DR NATICK MA 01760 USA "
    },
    {
      "Location ID": "01770",
      "Location Name": "SHERBORN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 N MAIN ST STE 3 SHERBORN MA 01770 USA "
    },
    {
      "Location ID": "01772",
      "Location Name": "SOUTHBOROUGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 164 CORDAVILLE RD SOUTHBOROUGH MA 01772 USA "
    },
    {
      "Location ID": "01775",
      "Location Name": "STOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 179 GREAT RD STOW MA 01775 USA "
    },
    {
      "Location ID": "01784",
      "Location Name": "WOODVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 234 WOOD ST WOODVILLE MA 01784 USA "
    },
    {
      "Location ID": "017AA",
      "Location Name": "A-ALERT LOGISTICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 153 NORTHBORO RD SUITE 14 SOUTHBOROUGH MA 01772 USA "
    },
    {
      "Location ID": "017AX",
      "Location Name": "NEW ENGLAND CROSSDOCK NEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 RESEARCH PARKWAY WALLINGFORD CT 06492 USA "
    },
    {
      "Location ID": "017AZ",
      "Location Name": "AMAZON SC/BOS4",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 NATIONAL ST MILFORD MA 01757 USA "
    },
    {
      "Location ID": "017EF",
      "Location Name": "NATICK STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 COMMON ST NATICK MA 01760 USA "
    },
    {
      "Location ID": "017LC",
      "Location Name": "LCI PAPER CO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 399 RIVER RDSUITE 3 HUDSON MA 01749 USA "
    },
    {
      "Location ID": "017MM",
      "Location Name": "Energy Federation Incorporated",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 WILLOW ST SOUTHBOROUGH MA 01745 USA "
    },
    {
      "Location ID": "017UT",
      "Location Name": "UPTON TEA IMPORTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 JEFFREY AVE STE 1 HOLLISTON MA 01746 USA "
    },
    {
      "Location ID": "017WW",
      "Location Name": "UNIVERSAL WILDE INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 135 WILL DR CANTON MA 02021 USA "
    },
    {
      "Location ID": "018",
      "Location Name": "LOG MIDDLESEXESSEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 76 MAIN ST NORTH READING MA 01889 USA "
    },
    {
      "Location ID": "01801",
      "Location Name": "WOBURN CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 ABBOTT ST WOBURN MA 01801 USA "
    },
    {
      "Location ID": "01803",
      "Location Name": "BURLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 BEDFORD ST BURLINGTON MA 01803 USA "
    },
    {
      "Location ID": "01810",
      "Location Name": "ANDOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 STEVENS ST ANDOVER MA 01810 USA "
    },
    {
      "Location ID": "01821",
      "Location Name": "BILLERICA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 460 BOSTON RD BILLERICA MA 01821 USA "
    },
    {
      "Location ID": "01824",
      "Location Name": "CHELMSFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 45 ALPINE LN CHELMSFORD MA 01824 USA "
    },
    {
      "Location ID": "01826",
      "Location Name": "DRACUT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 54 BROADWAY RD DRACUT MA 01826 USA "
    },
    {
      "Location ID": "01827",
      "Location Name": "DUNSTABLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 170 PLEASANT ST DUNSTABLE MA 01827 USA "
    },
    {
      "Location ID": "01830",
      "Location Name": "HAVERHILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 WASHINGTON SQ HAVERHILL MA 01830 USA "
    },
    {
      "Location ID": "01832",
      "Location Name": "BRADFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 135 S MAIN ST HAVERHILL MA 01835 USA "
    },
    {
      "Location ID": "01833",
      "Location Name": "GEORGETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 64 CENTRAL ST STE 9 GEORGETOWN MA 01833 USA "
    },
    {
      "Location ID": "01834",
      "Location Name": "GROVELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 299 MAIN ST GROVELAND MA 01834 USA "
    },
    {
      "Location ID": "01835",
      "Location Name": "WARD HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 CROSS RD HAVERHILL MA 01835 USA "
    },
    {
      "Location ID": "01840",
      "Location Name": "LAWRENCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 431 COMMON ST LAWRENCE MA 01840 USA "
    },
    {
      "Location ID": "01854",
      "Location Name": "LOWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 155 FATHER MORISSETTE BLVD LOWELL MA 01854 USA "
    },
    {
      "Location ID": "01860",
      "Location Name": "MERRIMAC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 79 E MAIN ST MERRIMAC MA 01860 USA "
    },
    {
      "Location ID": "01862",
      "Location Name": "NORTH BILLERICA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 95 BOSTON RD STE 5 NORTH BILLERICA MA 01862 USA "
    },
    {
      "Location ID": "01863",
      "Location Name": "NORTH CHELMSFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 KENNEDY DR NORTH CHELMSFORD MA 01863 USA "
    },
    {
      "Location ID": "01865",
      "Location Name": "NUTTING LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 612 MIDDLESEX TPKE NUTTING LAKE MA 01865 USA "
    },
    {
      "Location ID": "01866",
      "Location Name": "PINEHURST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 880 BOSTON RD STE I PINEHURST MA 01866 USA "
    },
    {
      "Location ID": "01867",
      "Location Name": "READING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 HAVEN ST STE 2 READING MA 01867 USA "
    },
    {
      "Location ID": "01876",
      "Location Name": "TEWKSBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1751 MAIN ST TEWKSBURY MA 01876 USA "
    },
    {
      "Location ID": "01879",
      "Location Name": "TYNGSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 353 MIDDLESEX RD TYNGSBORO MA 01879 USA "
    },
    {
      "Location ID": "01880",
      "Location Name": "WAKEFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 321 MAIN ST WAKEFIELD MA 01880 USA "
    },
    {
      "Location ID": "01885",
      "Location Name": "WEST BOXFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 561 MAIN ST WEST BOXFORD MA 01885 USA "
    },
    {
      "Location ID": "01886",
      "Location Name": "WESTFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 LITTLETON RD WESTFORD MA 01886 USA "
    },
    {
      "Location ID": "01887",
      "Location Name": "WILMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 CHURCH ST WILMINGTON MA 01887 USA "
    },
    {
      "Location ID": "018BI",
      "Location Name": "BILLERICA DDC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 BRICK KILN RD BILLERICA MA 01821 USA "
    },
    {
      "Location ID": "018GC",
      "Location Name": "GENCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37 MANNING RD BILLERICA MA 01821 USA "
    },
    {
      "Location ID": "018WO",
      "Location Name": "WOBURN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 462 WASHINGTON ST WOBURN MA 01801 USA "
    },
    {
      "Location ID": "01901",
      "Location Name": "LYNN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 51 WILLOW ST LYNN MA 01901 USA "
    },
    {
      "Location ID": "01904",
      "Location Name": "EAST LYNN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 245 MAPLE ST LYNN MA 01904 USA "
    },
    {
      "Location ID": "01907",
      "Location Name": "SWAMPSCOTT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 49 ELMWOOD RD SWAMPSCOTT MA 01907 USA "
    },
    {
      "Location ID": "01908",
      "Location Name": "NAHANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 332 NAHANT RD STE 2 NAHANT MA 01908 USA "
    },
    {
      "Location ID": "01913",
      "Location Name": "AMESBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 MAIN ST AMESBURY MA 01913 USA "
    },
    {
      "Location ID": "01915",
      "Location Name": "BEVERLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 151 RANTOUL ST BEVERLY MA 01915 USA "
    },
    {
      "Location ID": "01921",
      "Location Name": "BOXFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 ELM ST STE 1 BOXFORD MA 01921 USA "
    },
    {
      "Location ID": "01922",
      "Location Name": "BYFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 CENTRAL ST BYFIELD MA 01922 USA "
    },
    {
      "Location ID": "01929",
      "Location Name": "ESSEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 MARTIN ST ESSEX MA 01929 USA "
    },
    {
      "Location ID": "01930",
      "Location Name": "GLOUCESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 DALE AVE GLOUCESTER MA 01930 USA "
    },
    {
      "Location ID": "01936",
      "Location Name": "HAMILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 589 BAY RD HAMILTON MA 01936 USA "
    },
    {
      "Location ID": "01938",
      "Location Name": "IPSWICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 MARKET ST IPSWICH MA 01938 USA "
    },
    {
      "Location ID": "01940",
      "Location Name": "LYNNFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 38 SUMMER ST LYNNFIELD MA 01940 USA "
    },
    {
      "Location ID": "01944",
      "Location Name": "MANCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 BEACH ST MANCHESTER MA 01944 USA "
    },
    {
      "Location ID": "01950",
      "Location Name": "NEWBURYPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 61 PLEASANT ST NEWBURYPORT MA 01950 USA "
    },
    {
      "Location ID": "01960",
      "Location Name": "PEABODY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 ESSEX CENTER DR PEABODY MA 01960 USA "
    },
    {
      "Location ID": "01961",
      "Location Name": "CHRISTIAN BOOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 137 SUMMIT STREET PEABODY MA 01961 USA "
    },
    {
      "Location ID": "01965",
      "Location Name": "PRIDES CROSSING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 64 THISSELL ST PRIDES CROSSING MA 01965 USA "
    },
    {
      "Location ID": "01966",
      "Location Name": "ROCKPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 BROADWAY ROCKPORT MA 01966 USA "
    },
    {
      "Location ID": "01969",
      "Location Name": "ROWLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 161 MAIN ST ROWLEY MA 01969 USA "
    },
    {
      "Location ID": "01982",
      "Location Name": "SOUTH HAMILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 49 RAILROAD AVE SOUTH HAMILTON MA 01982 USA "
    },
    {
      "Location ID": "01983",
      "Location Name": "TOPSFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 MAIN ST TOPSFIELD MA 01983 USA "
    },
    {
      "Location ID": "01984",
      "Location Name": "WENHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 146 MAIN ST WENHAM MA 01984 USA "
    },
    {
      "Location ID": "01985",
      "Location Name": "WEST NEWBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 284 MAIN ST WEST NEWBURY MA 01985 USA "
    },
    {
      "Location ID": "019AA",
      "Location Name": "NEWBURYPORT CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 MALCOLM HOYT DR NEWBURYPORT MA 01950 USA "
    },
    {
      "Location ID": "019AX",
      "Location Name": "IPSWICH CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 HAYWARD ST STE 5 IPSWICH MA 01938 USA "
    },
    {
      "Location ID": "019BE",
      "Location Name": "BEVERLY DDC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 TOZER RD BEVERLY MA 01915 USA "
    },
    {
      "Location ID": "019CB",
      "Location Name": "CHRISTIAN BOOK DISTRIBUTORS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 140 SUMMIT ST PEABODY MA 01960 USA "
    },
    {
      "Location ID": "019DM",
      "Location Name": "SPIRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 1ST AVE PEABODY MA 01960 USA "
    },
    {
      "Location ID": "019LS",
      "Location Name": "SOUTH LYNNFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 598 SALEM ST LYNNFIELD MA 01940 USA "
    },
    {
      "Location ID": "01H",
      "Location Name": "CHICOPEE MA STC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2189 WESTOVER RD CHICOPEE MA 01022 USA "
    },
    {
      "Location ID": "01Z",
      "Location Name": "LOG SPRINGFIELD NDC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1800 PAGE BLVD SPRINGFIELD MA 01152 USA "
    },
    {
      "Location ID": "01ZAX",
      "Location Name": "SPRINGFIELD NDC TRAY ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1800A PAGE BLVD SPRINGFIELD MA 01152 USA "
    },
    {
      "Location ID": "02019",
      "Location Name": "BELLINGHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 MECHANIC ST BELLINGHAM MA 02019 USA "
    },
    {
      "Location ID": "02025",
      "Location Name": "COHASSET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 RIPLEY RD COHASSET MA 02025 USA "
    },
    {
      "Location ID": "02026",
      "Location Name": "DEDHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 611 HIGH ST DEDHAM MA 02026 USA "
    },
    {
      "Location ID": "02030",
      "Location Name": "DOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 WALPOLE ST DOVER MA 02030 USA "
    },
    {
      "Location ID": "02032",
      "Location Name": "EAST WALPOLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31 UNION ST EAST WALPOLE MA 02032 USA "
    },
    {
      "Location ID": "02035",
      "Location Name": "FOXBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 WALL ST FOXBORO MA 02035 USA "
    },
    {
      "Location ID": "02038",
      "Location Name": "FRANKLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 43 MAIN ST FRANKLIN MA 02038 USA "
    },
    {
      "Location ID": "02043",
      "Location Name": "HINGHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 NORTH ST HINGHAM MA 02043 USA "
    },
    {
      "Location ID": "02045",
      "Location Name": "HULL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 515 NANTASKET AVE HULL MA 02045 USA "
    },
    {
      "Location ID": "0204S",
      "Location Name": "ALLERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 797 NANTASKET AVE HULL MA 02045 USA "
    },
    {
      "Location ID": "02050",
      "Location Name": "MARSHFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 SNOW RD MARSHFIELD MA 02050 USA "
    },
    {
      "Location ID": "02052",
      "Location Name": "MEDFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 NORTH ST MEDFIELD MA 02052 USA "
    },
    {
      "Location ID": "02053",
      "Location Name": "MEDWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 MAIN ST MEDWAY MA 02053 USA "
    },
    {
      "Location ID": "02054",
      "Location Name": "MILLIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1116 MAIN ST MILLIS MA 02054 USA "
    },
    {
      "Location ID": "02056",
      "Location Name": "NORFOLK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 208 MAIN ST NORFOLK MA 02056 USA "
    },
    {
      "Location ID": "02061",
      "Location Name": "NORWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 DOVER ST NORWELL MA 02061 USA "
    },
    {
      "Location ID": "02062",
      "Location Name": "NORWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 CENTRAL ST NORWOOD MA 02062 USA "
    },
    {
      "Location ID": "02066",
      "Location Name": "SCITUATE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 FIRST PARISH RD SCITUATE MA 02066 USA "
    },
    {
      "Location ID": "02070",
      "Location Name": "SHELDONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1063 WEST ST SHELDONVILLE MA 02070 USA "
    },
    {
      "Location ID": "02071",
      "Location Name": "SOUTH WALPOLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44 SUMMER ST SOUTH WALPOLE MA 02071 USA "
    },
    {
      "Location ID": "02072",
      "Location Name": "STOUGHTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31 PORTER ST STOUGHTON MA 02072 USA "
    },
    {
      "Location ID": "02081",
      "Location Name": "WALPOLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 COMMON ST WALPOLE MA 02081 USA "
    },
    {
      "Location ID": "02090",
      "Location Name": "WESTWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 697 HIGH ST WESTWOOD MA 02090 USA "
    },
    {
      "Location ID": "02093",
      "Location Name": "WRENTHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 RANDALL RD WRENTHAM MA 02093 USA "
    },
    {
      "Location ID": "020BO",
      "Location Name": "BOS5",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 TECHNOLOGY CENTER DR STOUGHTON MA 02072 USA "
    },
    {
      "Location ID": "020DH",
      "Location Name": "DHL GLOBAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 SASSACUS DRIVE WESTBOROUGH MA 01581 USA "
    },
    {
      "Location ID": "020IS",
      "Location Name": "ISLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 307 WASHINGTON ST WESTWOOD MA 02090 USA "
    },
    {
      "Location ID": "020WE",
      "Location Name": "WE GOT SOCCER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 605 MYLES STANDISH BLVD TAUNTON MA 02780 USA "
    },
    {
      "Location ID": "021",
      "Location Name": "BOSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 DORCHESTER AVE RM 4025B BOSTON MA 02205 USA "
    },
    {
      "Location ID": "021FZ",
      "Location Name": "LOG BOSTON MA PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 275 BODWELL ST STE B AVON MA 02322 USA "
    },
    {
      "Location ID": "021QA",
      "Location Name": "WORLDWIDE FLIGHT SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 132 HARBORSIDE DR BOSTON MA 02128 USA "
    },
    {
      "Location ID": "021UP",
      "Location Name": "UNITED PARCEL SERVICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 280 EASTERN AVE CHELSEA MA 02150 USA "
    },
    {
      "Location ID": "023",
      "Location Name": "BROCKTON P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 225 LIBERTY ST RM 234 BROCKTON MA 02301 USA "
    },
    {
      "Location ID": "02322",
      "Location Name": "AVON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 E MAIN ST AVON MA 02322 USA "
    },
    {
      "Location ID": "02324",
      "Location Name": "BRIDGEWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 169 BROAD ST BRIDGEWATER MA 02324 USA "
    },
    {
      "Location ID": "02327",
      "Location Name": "BRYANTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 SCHOOL ST BRYANTVILLE MA 02327 USA "
    },
    {
      "Location ID": "02331",
      "Location Name": "SNUG HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 455 WASHINGTON ST DUXBURY MA 02331 USA "
    },
    {
      "Location ID": "02332",
      "Location Name": "DUXBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 56 DEPOT ST DUXBURY MA 02332 USA "
    },
    {
      "Location ID": "02333",
      "Location Name": "EAST BRIDGEWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 63 W UNION ST EAST BRIDGEWATER MA 02333 USA "
    },
    {
      "Location ID": "02334",
      "Location Name": "EASTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 670 DEPOT ST EASTON MA 02334 USA "
    },
    {
      "Location ID": "02337",
      "Location Name": "ELMWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 734 BEDFORD ST ELMWOOD MA 02337 USA "
    },
    {
      "Location ID": "02339",
      "Location Name": "HANOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 196 ROCKLAND ST HANOVER MA 02339 USA "
    },
    {
      "Location ID": "02341",
      "Location Name": "HANSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 270 MAIN ST HANSON MA 02341 USA "
    },
    {
      "Location ID": "02343",
      "Location Name": "HOLBROOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 333 PLYMOUTH ST HOLBROOK MA 02343 USA "
    },
    {
      "Location ID": "02346",
      "Location Name": "MIDDLEBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90 CENTRE ST MIDDLEBORO MA 02346 USA "
    },
    {
      "Location ID": "02351",
      "Location Name": "ABINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 HARRISON AVE ABINGTON MA 02351 USA "
    },
    {
      "Location ID": "02356",
      "Location Name": "NORTH EASTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 MAIN ST NORTH EASTON MA 02356 USA "
    },
    {
      "Location ID": "02358",
      "Location Name": "NORTH PEMBROKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 288 WASHINGTON ST NORTH PEMBROKE MA 02358 USA "
    },
    {
      "Location ID": "02359",
      "Location Name": "PEMBROKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 ELLIOT AVE PEMBROKE MA 02359 USA "
    },
    {
      "Location ID": "02360",
      "Location Name": "PLYMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 LONG POND RD PLYMOUTH MA 02360 USA "
    },
    {
      "Location ID": "02362",
      "Location Name": "NORTH PLYMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 283 COURT ST PLYMOUTH MA 02362 USA "
    },
    {
      "Location ID": "02364",
      "Location Name": "KINGSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 74 SUMMER ST KINGSTON MA 02364 USA "
    },
    {
      "Location ID": "02368",
      "Location Name": "RANDOLPH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 THOMAS PATTEN DR RANDOLPH MA 02368 USA "
    },
    {
      "Location ID": "02370",
      "Location Name": "ROCKLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 WEBSTER ST ROCKLAND MA 02370 USA "
    },
    {
      "Location ID": "02375",
      "Location Name": "SOUTH EASTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 704 WASHINGTON ST SOUTH EASTON MA 02375 USA "
    },
    {
      "Location ID": "02379",
      "Location Name": "WEST BRIDGEWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 78 N MAIN ST WEST BRIDGEWATER MA 02379 USA "
    },
    {
      "Location ID": "02382",
      "Location Name": "WHITMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 64 SOUTH AVE WHITMAN MA 02382 USA "
    },
    {
      "Location ID": "023AD",
      "Location Name": "WEST HANOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1405 HANOVER ST STE 2 HANOVER MA 02339 USA "
    },
    {
      "Location ID": "023JL",
      "Location Name": "JLS MAILING SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 672 CRESCENT ST BROCKTON MA 02302 USA "
    },
    {
      "Location ID": "023LC",
      "Location Name": "LAKEVILLE CARRIERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 RIVERSIDE DR LAKEVILLE MA 02347 USA "
    },
    {
      "Location ID": "023PA",
      "Location Name": "PLYMOUTH ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 74 CAMELOT DR STE 21 PLYMOUTH MA 02360 USA "
    },
    {
      "Location ID": "024",
      "Location Name": "NORTHWEST BOSTON P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 SMITH ST WALTHAM MA 02451 USA "
    },
    {
      "Location ID": "024AB",
      "Location Name": "WATERTOWN ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 170 HIGH ST WALTHAM MA 02453 USA "
    },
    {
      "Location ID": "025",
      "Location Name": "WAREHAM ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 TOBEY RD WAREHAM MA 02571 USA "
    },
    {
      "Location ID": "02532",
      "Location Name": "BUZZARDS BAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 MAIN ST BUZZARDS BAY MA 02532 USA "
    },
    {
      "Location ID": "02534",
      "Location Name": "CATAUMET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 POST OFFICE SQ CATAUMET MA 02534 USA "
    },
    {
      "Location ID": "02535",
      "Location Name": "CHILMARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 STATE RD CHILMARK MA 02535 USA "
    },
    {
      "Location ID": "02536",
      "Location Name": "EAST FALMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 451 E FALMOUTH HWY EAST FALMOUTH MA 02536 USA "
    },
    {
      "Location ID": "02537",
      "Location Name": "EAST SANDWICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 443 ROUTE 6A EAST SANDWICH MA 02537 USA "
    },
    {
      "Location ID": "02538",
      "Location Name": "EAST WAREHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2991C CRANBERRY HWY EAST WAREHAM MA 02538 USA "
    },
    {
      "Location ID": "02539",
      "Location Name": "EDGARTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 236B EDGARTOWN RD EDGARTOWN MA 02539 USA "
    },
    {
      "Location ID": "02540",
      "Location Name": "FALMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 MAIN ST FALMOUTH MA 02540 USA "
    },
    {
      "Location ID": "02543",
      "Location Name": "WOODS HOLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 WATER ST WOODS HOLE MA 02543 USA "
    },
    {
      "Location ID": "0254W",
      "Location Name": "WOODS HOLE WHARF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 RAILROAD AVE WOODS HOLE MA 02543 USA "
    },
    {
      "Location ID": "02553",
      "Location Name": "MONUMENT BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 436 SHORE RD MONUMENT BEACH MA 02553 USA "
    },
    {
      "Location ID": "02554",
      "Location Name": "NANTUCKET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 FEDERAL ST NANTUCKET MA 02554 USA "
    },
    {
      "Location ID": "02556",
      "Location Name": "NRTH FALMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 634 N FALMOUTH HWY NORTH FALMOUTH MA 02556 USA "
    },
    {
      "Location ID": "02557",
      "Location Name": "OAK BLUFFS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24E KENNEBEC AVE OAK BLUFFS MA 02557 USA "
    },
    {
      "Location ID": "02558",
      "Location Name": "ONSET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 214 ONSET AVE ONSET MA 02558 USA "
    },
    {
      "Location ID": "02559",
      "Location Name": "POCASSET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 BARLOWS LANDING RD POCASSET MA 02559 USA "
    },
    {
      "Location ID": "02561",
      "Location Name": "SAGAMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 883 SANDWICH RD SAGAMORE MA 02561 USA "
    },
    {
      "Location ID": "02562",
      "Location Name": "SAGAMORE BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 45 MEETINGHOUSE LN SAGAMORE BEACH MA 02562 USA "
    },
    {
      "Location ID": "02563",
      "Location Name": "SANDWICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 69 ROUTE 6A SANDWICH MA 02563 USA "
    },
    {
      "Location ID": "02564",
      "Location Name": "SIASCONSET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 MAIN ST SIASCONSET MA 02564 USA "
    },
    {
      "Location ID": "02568",
      "Location Name": "VINEYARD HAVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 LAGOON POND RD VINEYARD HAVEN MA 02568 USA "
    },
    {
      "Location ID": "02571",
      "Location Name": "WAREHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 248 MAIN ST WAREHAM MA 02571 USA "
    },
    {
      "Location ID": "02574",
      "Location Name": "WEST FALMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 410 W FALMOUTH HWY WEST FALMOUTH MA 02574 USA "
    },
    {
      "Location ID": "02575",
      "Location Name": "WEST TISBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 479 STATE RD WEST TISBURY MA 02575 USA "
    },
    {
      "Location ID": "02576",
      "Location Name": "WEST WAREHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2360 CRANBERRY HWY WEST WAREHAM MA 02576 USA "
    },
    {
      "Location ID": "02584",
      "Location Name": "NANTUCKET STATION 1",
      "Time Zone Offset (Hours)": 0,
      "Address": " 144 PLEASANT ST NANTUCKET MA 02584 USA "
    },
    {
      "Location ID": "025AC",
      "Location Name": "NANTUCKET CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 155 OLD SOUTH RD NANTUCKET MA 02554 USA "
    },
    {
      "Location ID": "025BS",
      "Location Name": "BOURNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 49 SANDWICH RD BUZZARDS BAY MA 02532 USA "
    },
    {
      "Location ID": "025CC",
      "Location Name": "CAPE COD MEDICAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 JAN SEBASTIAN DR STE 5 SANDWICH MA 02563 USA "
    },
    {
      "Location ID": "025CP",
      "Location Name": "CPU ALPHA SYSTEMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 CHURCH ST EDGARTOWN MA 02539 USA "
    },
    {
      "Location ID": "025NW",
      "Location Name": "NANTUCKET WHARF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 SWAINS WHARF ROAD NANTUCKET MA 02584 USA "
    },
    {
      "Location ID": "025PT",
      "Location Name": "PURPLE TURTLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 PATTERSONS BROOK RD STE 9 WEST WAREHAM MA 02576 USA "
    },
    {
      "Location ID": "025SC",
      "Location Name": "STOVE CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1220 ROUTE 28A CATAUMET MA 02534 USA "
    },
    {
      "Location ID": "025TS",
      "Location Name": "TEATICKET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 160 TEATICKET HWY TEATICKET MA 02536 USA "
    },
    {
      "Location ID": "025VH",
      "Location Name": "VINEYARD HAVEN WHARF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 WATER STREET VINEYYARD HAVEN MA 02568 USA "
    },
    {
      "Location ID": "025VP",
      "Location Name": "V POWER EQUIPMENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 PATTERSONS BROOK RD STE 4 WEST WAREHAM MA 02576 USA "
    },
    {
      "Location ID": "02601",
      "Location Name": "HYANNIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 385 MAIN ST HYANNIS MA 02601 USA "
    },
    {
      "Location ID": "02630",
      "Location Name": "BARNSTABLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3230 MAIN ST BARNSTABLE MA 02630 USA "
    },
    {
      "Location ID": "02631",
      "Location Name": "BREWSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 260 UNDERPASS RD BREWSTER MA 02631 USA "
    },
    {
      "Location ID": "02632",
      "Location Name": "CENTERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1672 FALMOUTH RD CENTERVILLE MA 02632 USA "
    },
    {
      "Location ID": "02633",
      "Location Name": "CHATHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 802 MAIN ST CHATHAM MA 02633 USA "
    },
    {
      "Location ID": "02635",
      "Location Name": "COTUIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 45 SCHOOL ST COTUIT MA 02635 USA "
    },
    {
      "Location ID": "02637",
      "Location Name": "CUMMAQUID",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4029 MAIN ST CUMMAQUID MA 02637 USA "
    },
    {
      "Location ID": "02638",
      "Location Name": "DENNIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 788 MAIN ST DENNIS MA 02638 USA "
    },
    {
      "Location ID": "02639",
      "Location Name": "DENNIS PORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 MILL ST DENNIS PORT MA 02639 USA "
    },
    {
      "Location ID": "02641",
      "Location Name": "EAST DENNIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1600 MAIN ST EAST DENNIS MA 02641 USA "
    },
    {
      "Location ID": "02642",
      "Location Name": "EASTHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2365 STATE HWY EASTHAM MA 02642 USA "
    },
    {
      "Location ID": "02643",
      "Location Name": "EAST ORLEANS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 MAIN ST EAST ORLEANS MA 02643 USA "
    },
    {
      "Location ID": "02644",
      "Location Name": "FORESTDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 290 ROUTE 130 FORESTDALE MA 02644 USA "
    },
    {
      "Location ID": "02645",
      "Location Name": "HARWICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1484 ORLEANS RD HARWICH MA 02645 USA "
    },
    {
      "Location ID": "02646",
      "Location Name": "HARWICH PORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 POST OFFICE SQ HARWICH PORT MA 02646 USA "
    },
    {
      "Location ID": "02647",
      "Location Name": "HYANNIS PORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44 LONGWOOD AVE HYANNIS PORT MA 02647 USA "
    },
    {
      "Location ID": "02648",
      "Location Name": "MARSTONS MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 RIVER RD MARSTONS MILLS MA 02648 USA "
    },
    {
      "Location ID": "02649",
      "Location Name": "MASHPEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 MARKET ST MASHPEE MA 02649 USA "
    },
    {
      "Location ID": "02650",
      "Location Name": "NRTH CHATHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 ORLEANS RD NORTH CHATHAM MA 02650 USA "
    },
    {
      "Location ID": "02651",
      "Location Name": "NRTH EASTHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 BRACKETT RD NORTH EASTHAM MA 02651 USA "
    },
    {
      "Location ID": "02652",
      "Location Name": "NRTH TRURO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 HIGHLAND RD UNIT B NORTH TRURO MA 02652 USA "
    },
    {
      "Location ID": "02653",
      "Location Name": "ORLEANS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 56 MAIN ST ORLEANS MA 02653 USA "
    },
    {
      "Location ID": "02655",
      "Location Name": "OSTERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 42 WIANNO AVE OSTERVILLE MA 02655 USA "
    },
    {
      "Location ID": "02657",
      "Location Name": "PROVINCETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 219 COMMERCIAL ST PROVINCETOWN MA 02657 USA "
    },
    {
      "Location ID": "02659",
      "Location Name": "SOUTH CHATHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2455 MAIN ST SOUTH CHATHAM MA 02659 USA "
    },
    {
      "Location ID": "02660",
      "Location Name": "SOUTH DENNIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 MARKET PL SOUTH DENNIS MA 02660 USA "
    },
    {
      "Location ID": "02661",
      "Location Name": "SOUTH HARWICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 944 ROUTE 28 SOUTH HARWICH MA 02661 USA "
    },
    {
      "Location ID": "02662",
      "Location Name": "SOUTH ORLEANS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 403 S ORLEANS RD (ROUTE 28) SOUTH ORLEANS MA 02662 USA "
    },
    {
      "Location ID": "02663",
      "Location Name": "SOUTH WELLFLEET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1426 ROUTE 6 SOUTH WELLFLEET MA 02663 USA "
    },
    {
      "Location ID": "02664",
      "Location Name": "SOUTH YARMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1057 ROUTE 28 SOUTH YARMOUTH MA 02664 USA "
    },
    {
      "Location ID": "02666",
      "Location Name": "TRURO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 TRURO CENTER RD TRURO MA 02666 USA "
    },
    {
      "Location ID": "02667",
      "Location Name": "WELLFLEET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2357 STATE HWY RTE 6 WELLFLEET MA 02667 USA "
    },
    {
      "Location ID": "02668",
      "Location Name": "WEST BARNSTABLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1165 MAIN ST WEST BARNSTABLE MA 02668 USA "
    },
    {
      "Location ID": "02669",
      "Location Name": "WEST CHATHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1671 MAIN ST WEST CHATHAM MA 02669 USA "
    },
    {
      "Location ID": "02670",
      "Location Name": "WEST DENNIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 97 SCHOOL ST WEST DENNIS MA 02670 USA "
    },
    {
      "Location ID": "02671",
      "Location Name": "WEST HARWICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 216 ROUTE 28 WEST HARWICH MA 02671 USA "
    },
    {
      "Location ID": "02672",
      "Location Name": "WEST HYANNISPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 CRAIGVILLE BEACH RD WEST HYANNISPORT MA 02672 USA "
    },
    {
      "Location ID": "02673",
      "Location Name": "WEST YARMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 602 ROUTE 28 WEST YARMOUTH MA 02673 USA "
    },
    {
      "Location ID": "02675",
      "Location Name": "YARMOUTH PORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 231 ROUTE 6A YARMOUTH PORT MA 02675 USA "
    },
    {
      "Location ID": "026HW",
      "Location Name": "HYANNIS WHARF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 RAILROAD AVENUE HYANNIS MA 02543 USA "
    },
    {
      "Location ID": "02702",
      "Location Name": "ASSONET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 57 S MAIN ST ASSONET MA 02702 USA "
    },
    {
      "Location ID": "02703",
      "Location Name": "ATTLEBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 901 PLEASANT ST ATTLEBORO MA 02703 USA "
    },
    {
      "Location ID": "02715",
      "Location Name": "DIGHTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 232 MAIN ST DIGHTON MA 02715 USA "
    },
    {
      "Location ID": "02718",
      "Location Name": "EAST TAUNTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 493 MIDDLEBORO AVE EAST TAUNTON MA 02718 USA "
    },
    {
      "Location ID": "02720",
      "Location Name": "HIGHLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1701 PRESIDENT AVE FALL RIVER MA 02720 USA "
    },
    {
      "Location ID": "02722",
      "Location Name": "FALL RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 GOVERNMENT CTR FALL RIVER MA 02722 USA "
    },
    {
      "Location ID": "02726",
      "Location Name": "SOMERSET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1089 COUNTY ST SOMERSET MA 02726 USA "
    },
    {
      "Location ID": "02740",
      "Location Name": "NEW BEDFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 695 PLEASANT ST NEW BEDFORD MA 02740 USA "
    },
    {
      "Location ID": "02745",
      "Location Name": "MOUNT PLEASANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 748 MOUNT PLEASANT ST NEW BEDFORD MA 02745 USA "
    },
    {
      "Location ID": "02760",
      "Location Name": "NORTH ATTLEBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 S WASHINGTON ST NORTH ATTLEBORO MA 02760 USA "
    },
    {
      "Location ID": "02762",
      "Location Name": "PLAINVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 127 SOUTH ST PLAINVILLE MA 02762 USA "
    },
    {
      "Location ID": "02763",
      "Location Name": "ATTLEBORO FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 COMMONWEALTH AVE ATTLEBORO FALLS MA 02763 USA "
    },
    {
      "Location ID": "02764",
      "Location Name": "NORTH DIGHTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1120 SOMERSET AVE NORTH DIGHTON MA 02764 USA "
    },
    {
      "Location ID": "02767",
      "Location Name": "RAYNHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 681 BROADWAY RAYNHAM MA 02767 USA "
    },
    {
      "Location ID": "02768",
      "Location Name": "RAYNHAM CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 622 N MAIN ST RAYNHAM CENTER MA 02768 USA "
    },
    {
      "Location ID": "02769",
      "Location Name": "REHOBOTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 275 WINTHROP ST REHOBOTH MA 02769 USA "
    },
    {
      "Location ID": "02777",
      "Location Name": "SWANSEA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1278 GAR HWY SWANSEA MA 02777 USA "
    },
    {
      "Location ID": "02780",
      "Location Name": "TAUNTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 245 MYLES STANDISH BLVD TAUNTON MA 02780 USA "
    },
    {
      "Location ID": "027MG",
      "Location Name": "MASTRIA AUTO GROUP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1305 NEW STATE HWY RAYNHAM MA 02767 USA "
    },
    {
      "Location ID": "027SA",
      "Location Name": "SOUTH ATTLEBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 775 NEWPORT AVE ATTLEBORO MA 02703 USA "
    },
    {
      "Location ID": "027SS",
      "Location Name": "SIMPLISAFE - TAUNTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 275 JOHN HANCOCK RD STE 300 TAUNTON MA 02780 USA "
    },
    {
      "Location ID": "027TS",
      "Location Name": "TAUNTON STA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37 TAUNTON GRN TAUNTON MA 02780 USA "
    },
    {
      "Location ID": "028",
      "Location Name": "LOG PROVIDENCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 CORLISS ST REAR DOCK PROVIDENCE RI 02904 USA "
    },
    {
      "Location ID": "02804",
      "Location Name": "ASHAWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 129 MAIN ST ASHAWAY RI 02804 USA "
    },
    {
      "Location ID": "02806",
      "Location Name": "BARRINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 MIDDLE HWY BARRINGTON RI 02806 USA "
    },
    {
      "Location ID": "02808",
      "Location Name": "BRADFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 416 BRADFORD RD BRADFORD RI 02808 USA "
    },
    {
      "Location ID": "02809",
      "Location Name": "BRISTOL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 515 HOPE ST BRISTOL RI 02809 USA "
    },
    {
      "Location ID": "02812",
      "Location Name": "CAROLINA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 790 ALTON CAROLINA RD CAROLINA RI 02812 USA "
    },
    {
      "Location ID": "02813",
      "Location Name": "CHARLESTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3970 OLD POST RD CHARLESTOWN RI 02813 USA "
    },
    {
      "Location ID": "02816",
      "Location Name": "COVENTRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1550 NOOSENECK HILL RD COVENTRY RI 02816 USA "
    },
    {
      "Location ID": "02818",
      "Location Name": "EAST GREENWICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5775 POST RD EAST GREENWICH RI 02818 USA "
    },
    {
      "Location ID": "02822",
      "Location Name": "EXETER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 260 S COUNTY TRL EXETER RI 02822 USA "
    },
    {
      "Location ID": "02823",
      "Location Name": "FISKEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 MAIN ST FISKEVILLE RI 02823 USA "
    },
    {
      "Location ID": "02831",
      "Location Name": "HOPE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 134 JACKSON FLAT RD HOPE RI 02831 USA "
    },
    {
      "Location ID": "02832",
      "Location Name": "HOPE VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1027 MAIN ST HOPE VALLEY RI 02832 USA "
    },
    {
      "Location ID": "02833",
      "Location Name": "HOPKINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 482 MAIN ST HOPKINTON RI 02833 USA "
    },
    {
      "Location ID": "02835",
      "Location Name": "JAMESTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 NARRAGANSETT AVE JAMESTOWN RI 02835 USA "
    },
    {
      "Location ID": "02837",
      "Location Name": "LITTLE COMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 S OF COMMONS RD LITTLE COMPTON RI 02837 USA "
    },
    {
      "Location ID": "02840",
      "Location Name": "NEWPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 320 THAMES ST STE 1 NEWPORT RI 02840 USA "
    },
    {
      "Location ID": "02841",
      "Location Name": "NETC STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1900 PEARY ST STE 2 NEWPORT RI 02841 USA "
    },
    {
      "Location ID": "02842",
      "Location Name": "MIDDLETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 COMMERCIAL BLVD MIDDLETOWN RI 02842 USA "
    },
    {
      "Location ID": "02852",
      "Location Name": "NORTH KINGSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7715 POST RD NORTH KINGSTOWN RI 02852 USA "
    },
    {
      "Location ID": "02871",
      "Location Name": "PORTSMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 95 CHASE RD PORTSMOUTH RI 02871 USA "
    },
    {
      "Location ID": "02873",
      "Location Name": "ROCKVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 332 CANONCHET RD ROCKVILLE RI 02873 USA "
    },
    {
      "Location ID": "02874",
      "Location Name": "SAUNDERSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 FERRY RD SAUNDERSTOWN RI 02874 USA "
    },
    {
      "Location ID": "02875",
      "Location Name": "SHANNOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 196A SHANNOCK VILLAGE RD SHANNOCK RI 02875 USA "
    },
    {
      "Location ID": "02877",
      "Location Name": "SLOCUM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 743 INDIAN CORNER RD SLOCUM RI 02877 USA "
    },
    {
      "Location ID": "02878",
      "Location Name": "TIVERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 MAIN RD TIVERTON RI 02878 USA "
    },
    {
      "Location ID": "02879",
      "Location Name": "WAKEFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 551 KINGSTOWN RD WAKEFIELD RI 02879 USA "
    },
    {
      "Location ID": "02881",
      "Location Name": "KINGSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 BRIAR LN KINGSTON RI 02881 USA "
    },
    {
      "Location ID": "02882",
      "Location Name": "NARRAGANSETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 MEMORIAL SQ NARRAGANSETT RI 02882 USA "
    },
    {
      "Location ID": "02885",
      "Location Name": "WARREN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 53 CHILD ST WARREN RI 02885 USA "
    },
    {
      "Location ID": "02891",
      "Location Name": "HIGH ST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 HIGH ST WESTERLY RI 02891 USA "
    },
    {
      "Location ID": "02892",
      "Location Name": "WEST KINGSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3484 KINGSTOWN RD WEST KINGSTON RI 02892 USA "
    },
    {
      "Location ID": "02893",
      "Location Name": "WEST WARWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 WASHINGTON ST WEST WARWICK RI 02893 USA "
    },
    {
      "Location ID": "02894",
      "Location Name": "WOOD RIVER JUNCTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 446 CHURCH ST WOOD RIVER JUNCTION RI 02894 USA "
    },
    {
      "Location ID": "02898",
      "Location Name": "WYOMING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1190 MAIN ST WYOMING RI 02898 USA "
    },
    {
      "Location ID": "028CC",
      "Location Name": "COAST TO COAST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 773 VICTORY HWY WEST GREENWICH RI 02817 USA "
    },
    {
      "Location ID": "028PJ",
      "Location Name": "POINT JUDITH WHARF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 304 GREAT ISLAND RD NARRAGANSETT RI 02882 USA "
    },
    {
      "Location ID": "028TT",
      "Location Name": "TWISTED THROTTLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 570 NOOSENECK HILL RD EXETER RI 02822 USA "
    },
    {
      "Location ID": "02MTE",
      "Location Name": "SPRINGFIELD MTESC - NEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1111 SOUTHAMPTON RD WESTFIELD MA 01085 USA "
    },
    {
      "Location ID": "030",
      "Location Name": "MANCHESTER P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 955 GOFFS FALLS RD MANCHESTER NH 03103 USA "
    },
    {
      "Location ID": "03031",
      "Location Name": "AMHERST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 199 STATE ROUTE 101 UNIT 4D AMHERST NH 03031 USA "
    },
    {
      "Location ID": "03032",
      "Location Name": "AUBURN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 61 RAYMOND RD AUBURN NH 03032 USA "
    },
    {
      "Location ID": "03033",
      "Location Name": "BROOKLINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 POST OFFICE DR BROOKLINE NH 03033 USA "
    },
    {
      "Location ID": "03034",
      "Location Name": "CANDIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 RAYMOND RD CANDIA NH 03034 USA "
    },
    {
      "Location ID": "03036",
      "Location Name": "CHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 CHESTER ST CHESTER NH 03036 USA "
    },
    {
      "Location ID": "03037",
      "Location Name": "DEERFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17A RAYMOND RD DEERFIELD NH 03037 USA "
    },
    {
      "Location ID": "03038",
      "Location Name": "DERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 TSIENNETO RD DERRY NH 03038 USA "
    },
    {
      "Location ID": "03041",
      "Location Name": "EAST DERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 E DERRY RD EAST DERRY NH 03041 USA "
    },
    {
      "Location ID": "03042",
      "Location Name": "EPPING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 71 MAIN ST EPPING NH 03042 USA "
    },
    {
      "Location ID": "03043",
      "Location Name": "FRANCESTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 158 2ND NH TPKE S FRANCESTOWN NH 03043 USA "
    },
    {
      "Location ID": "03044",
      "Location Name": "FREMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 51 BEEDE HILL RD FREMONT NH 03044 USA "
    },
    {
      "Location ID": "03045",
      "Location Name": "GOFFSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 CHURCH ST STE 1 GOFFSTOWN NH 03045 USA "
    },
    {
      "Location ID": "03047",
      "Location Name": "GREENFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 75 SLIP RD GREENFIELD NH 03047 USA "
    },
    {
      "Location ID": "03048",
      "Location Name": "GREENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 MAIN ST GREENVILLE NH 03048 USA "
    },
    {
      "Location ID": "03049",
      "Location Name": "HOLLIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 HUTCHINGS DR STE 100 HOLLIS NH 03049 USA "
    },
    {
      "Location ID": "03051",
      "Location Name": "HUDSON DMU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 EXECUTIVE DR HUDSON NH 03051 USA "
    },
    {
      "Location ID": "03053",
      "Location Name": "LONDONDERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 86 NASHUA RD LONDONDERRY NH 03053 USA "
    },
    {
      "Location ID": "03054",
      "Location Name": "MERRIMACK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 510 DANIEL WEBSTER HWY MERRIMACK NH 03054 USA "
    },
    {
      "Location ID": "03055",
      "Location Name": "MILFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 MONT VERNON ST MILFORD NH 03055 USA "
    },
    {
      "Location ID": "03057",
      "Location Name": "MONT VERNON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 GRAND HILL RD MONT VERNON NH 03057 USA "
    },
    {
      "Location ID": "03060",
      "Location Name": "NASHUA MPO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 38 SPRING ST NASHUA NH 03060 USA "
    },
    {
      "Location ID": "03070",
      "Location Name": "NEW BOSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 71 MONT VERNON RD NEW BOSTON NH 03070 USA "
    },
    {
      "Location ID": "03071",
      "Location Name": "NEW IPSWICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 802 TURNPIKE RD NEW IPSWICH NH 03071 USA "
    },
    {
      "Location ID": "03073",
      "Location Name": "NORTH SALEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 112 E BROADWAY NORTH SALEM NH 03073 USA "
    },
    {
      "Location ID": "03076",
      "Location Name": "PELHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 BRIDGE ST PELHAM NH 03076 USA "
    },
    {
      "Location ID": "03077",
      "Location Name": "RAYMOND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 FREETOWN RD STE 1 RAYMOND NH 03077 USA "
    },
    {
      "Location ID": "03079",
      "Location Name": "SALEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 51 S BROADWAY SALEM NH 03079 USA "
    },
    {
      "Location ID": "03082",
      "Location Name": "LYNDEBOROUGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 FOREST RD LYNDEBOROUGH NH 03082 USA "
    },
    {
      "Location ID": "03084",
      "Location Name": "TEMPLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 MAIN ST UNIT 3 TEMPLE NH 03084 USA "
    },
    {
      "Location ID": "03086",
      "Location Name": "WILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 79 MAIN ST WILTON NH 03086 USA "
    },
    {
      "Location ID": "03087",
      "Location Name": "WINDHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44 INDIAN ROCK RD WINDHAM NH 03087 USA "
    },
    {
      "Location ID": "030AB",
      "Location Name": "MANCHESTER WEST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 LANCE LN GOFFSTOWN NH 03045 USA "
    },
    {
      "Location ID": "030AD",
      "Location Name": "Alldayzip",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 INDUSTRIAL WAY STE E5 SALEM NH 03079 USA "
    },
    {
      "Location ID": "030AI",
      "Location Name": "ATLANTIC INNOVATIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 NORTHERN BLVD AMHERST NH 03031 USA "
    },
    {
      "Location ID": "030AM",
      "Location Name": "AMAZON.COM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 STATE STREET NASHUA NH 03063-1012 USA "
    },
    {
      "Location ID": "030BR",
      "Location Name": "11 BRAVOS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 WILLIAM LOEB DR MANCHESTER NH 03109 USA "
    },
    {
      "Location ID": "030FW",
      "Location Name": "F W WEBB COMPANY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 BON TERRAIN DRIVE AMHERST NH 03031 USA "
    },
    {
      "Location ID": "030HE",
      "Location Name": "HOBBY ETC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 140 HAYWARD ST MANCHESTER NH 03103 USA "
    },
    {
      "Location ID": "030HP",
      "Location Name": "AMES PERFORMANCE ENGINEERING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 PONTIAC DRIVE SPOFFORD NH 03462 USA "
    },
    {
      "Location ID": "030HS",
      "Location Name": "HUDSON MALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 77 DERRY ST STE 13 HUDSON NH 03051 USA "
    },
    {
      "Location ID": "030LA",
      "Location Name": "LABEL ART",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 GOURMET PL WILTON NH 03086 USA "
    },
    {
      "Location ID": "030LG",
      "Location Name": "Life Is Good",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 HUDSON PARK DRIVE HUDSON NH 03051 USA "
    },
    {
      "Location ID": "030MD",
      "Location Name": "MILFORD ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 CAPRON RD MILFORD NH 03055 USA "
    },
    {
      "Location ID": "030NA",
      "Location Name": "REGIONAL HOME CARE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 HARVEY RD BEDFORD NH 03110 USA "
    },
    {
      "Location ID": "030NE",
      "Location Name": "NORTHEAST 3PL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 NORTH WENTWORTH AVE LONDONDERRY NH 03053 USA "
    },
    {
      "Location ID": "030PM",
      "Location Name": "NASHUA L&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 CELINA AVE NASHUA NH 03063 USA "
    },
    {
      "Location ID": "030PP",
      "Location Name": "HANDICAPPED PETS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 STATE ROUTE 101A STE 18 AMHERST NH 03031 USA "
    },
    {
      "Location ID": "030SN",
      "Location Name": "SHIP NAKED",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 LONDONDERRY ROAD UNIT A2 LONDONDERRY NH 03053-3351 USA "
    },
    {
      "Location ID": "030SS",
      "Location Name": "SPORT STOP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 86 ROUTE 13 BROOKLINE NH 03033 USA "
    },
    {
      "Location ID": "030UP",
      "Location Name": "MANCHESTER UPS (QUANTUM)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 COMMERCE AVE STE W LONDONDERRY NH 03053 USA "
    },
    {
      "Location ID": "030YT",
      "Location Name": "Yankee Toy Box",
      "Time Zone Offset (Hours)": 0,
      "Address": " 48 POWERS ST MILFORD NH 03055 USA "
    },
    {
      "Location ID": "03101",
      "Location Name": "DOWNTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 ELM ST STE 15104 MANCHESTER NH 03101 USA "
    },
    {
      "Location ID": "03103",
      "Location Name": "MANCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 955 GOFFS FALLS RD STE 998 MANCHESTER NH 03103-9998 USA "
    },
    {
      "Location ID": "03106",
      "Location Name": "HOOKSETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1328 HOOKSETT RD STE 45 HOOKSETT NH 03106 USA "
    },
    {
      "Location ID": "031PD",
      "Location Name": "POLARIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 TECHNOLOGY DR HOOKSETT NH 03106 USA "
    },
    {
      "Location ID": "031PS",
      "Location Name": "MANCHESTER SOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 PERIMETER RD STE 2 MANCHESTER NH 03103 USA "
    },
    {
      "Location ID": "031YO",
      "Location Name": "Your Oil Tools",
      "Time Zone Offset (Hours)": 0,
      "Address": " 78 LONDONDERRY TPKE HOOKSETT NH 03106 USA "
    },
    {
      "Location ID": "03216",
      "Location Name": "ANDOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 164 MAIN ST REAR ANDOVER NH 03216 USA "
    },
    {
      "Location ID": "03217",
      "Location Name": "ASHLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 142 MAIN ST ASHLAND NH 03217 USA "
    },
    {
      "Location ID": "03218",
      "Location Name": "BARNSTEAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 335 PARADE RD BARNSTEAD NH 03218 USA "
    },
    {
      "Location ID": "03220",
      "Location Name": "BELMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 POST OFFICE RD BELMONT NH 03220 USA "
    },
    {
      "Location ID": "03221",
      "Location Name": "BRADFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2219 STATE ROUTE 114 BRADFORD NH 03221 USA "
    },
    {
      "Location ID": "03223",
      "Location Name": "CAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 SOUTHMAYD ST CAMPTON NH 03223 USA "
    },
    {
      "Location ID": "03224",
      "Location Name": "CANTERBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 CENTER RD CANTERBURY NH 03224 USA "
    },
    {
      "Location ID": "03225",
      "Location Name": "CENTER BARNSTEAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 S BARNSTEAD RD CENTER BARNSTEAD NH 03225 USA "
    },
    {
      "Location ID": "03226",
      "Location Name": "CENTER HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44 MAIN ST CENTER HARBOR NH 03226 USA "
    },
    {
      "Location ID": "03227",
      "Location Name": "CENTER SANDWICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31 MAIN ST CENTER SANDWICH NH 03227 USA "
    },
    {
      "Location ID": "03229",
      "Location Name": "CONTOOCOOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 129 PARK AVE CONTOOCOOK NH 03229 USA "
    },
    {
      "Location ID": "03230",
      "Location Name": "DANBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41 NH ROUTE 104 DANBURY NH 03230 USA "
    },
    {
      "Location ID": "03231",
      "Location Name": "EAST ANDOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 770 FRANKLIN HWY EAST ANDOVER NH 03231 USA "
    },
    {
      "Location ID": "03233",
      "Location Name": "ELKINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 349 ELKINS RD ELKINS NH 03233 USA "
    },
    {
      "Location ID": "03234",
      "Location Name": "EPSOM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 948 SUNCOOK VALLEY HWY EPSOM NH 03234 USA "
    },
    {
      "Location ID": "03235",
      "Location Name": "FRANKLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 65 FRANKLIN ST FRANKLIN NH 03235 USA "
    },
    {
      "Location ID": "03237",
      "Location Name": "GILMANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 365 NH ROUTE 140 GILMANTON NH 03237 USA "
    },
    {
      "Location ID": "03238",
      "Location Name": "GLENCLIFF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1385 NH ROUTE 25 UNIT 1 GLENCLIFF NH 03238 USA "
    },
    {
      "Location ID": "03240",
      "Location Name": "GRAFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 564 MAIN ST GRAFTON NH 03240 USA "
    },
    {
      "Location ID": "03242",
      "Location Name": "HENNIKER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 POST OFFICE PL HENNIKER NH 03242 USA "
    },
    {
      "Location ID": "03243",
      "Location Name": "HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 COMMERCE ST HILL NH 03243 USA "
    },
    {
      "Location ID": "03244",
      "Location Name": "HILLSBOROUGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 CENTRAL ST HILLSBOROUGH NH 03244 USA "
    },
    {
      "Location ID": "03245",
      "Location Name": "HOLDERNESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 846 US ROUTE 3 HOLDERNESS NH 03245 USA "
    },
    {
      "Location ID": "03246",
      "Location Name": "LACONIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 CHURCH ST LACONIA NH 03246 USA "
    },
    {
      "Location ID": "03251",
      "Location Name": "LINCOLN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 LUMBER YARD DR UNIT 9 LINCOLN NH 03251 USA "
    },
    {
      "Location ID": "03252",
      "Location Name": "LOCHMERE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 SILVER LAKE RD LOCHMERE NH 03252 USA "
    },
    {
      "Location ID": "03253",
      "Location Name": "MEREDITH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 76 MAIN ST MEREDITH NH 03253 USA "
    },
    {
      "Location ID": "03254",
      "Location Name": "MOULTONBOROUGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 995 WHITTIER HWY MOULTONBOROUGH NH 03254 USA "
    },
    {
      "Location ID": "03255",
      "Location Name": "NEWBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 976 ROUTE 103 NEWBURY NH 03255 USA "
    },
    {
      "Location ID": "03257",
      "Location Name": "NEW LONDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 319 NEWPORT RD NEW LONDON NH 03257 USA "
    },
    {
      "Location ID": "03259",
      "Location Name": "NORTH SANDWICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 MAPLE RIDGE RD NORTH SANDWICH NH 03259 USA "
    },
    {
      "Location ID": "03261",
      "Location Name": "NORTHWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 TASKER SHORE DR NORTHWOOD NH 03261 USA "
    },
    {
      "Location ID": "03262",
      "Location Name": "NORTH WOODSTOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 159 MAIN ST UNIT A NORTH WOODSTOCK NH 03262 USA "
    },
    {
      "Location ID": "03263",
      "Location Name": "PITTSFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 ELM ST PITTSFIELD NH 03263 USA "
    },
    {
      "Location ID": "03264",
      "Location Name": "PLYMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 POST OFFICE SQ PLYMOUTH NH 03264 USA "
    },
    {
      "Location ID": "03266",
      "Location Name": "RUMNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 POST OFFICE LN RUMNEY NH 03266 USA "
    },
    {
      "Location ID": "03268",
      "Location Name": "SALISBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 269 OLD TURNPIKE RD SALISBURY NH 03268 USA "
    },
    {
      "Location ID": "03275",
      "Location Name": "SUNCOOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 GLASS ST PEMBROKE NH 03275 USA "
    },
    {
      "Location ID": "03276",
      "Location Name": "TILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 66 E MAIN ST TILTON NH 03276 USA "
    },
    {
      "Location ID": "03278",
      "Location Name": "WARNER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 E MAIN ST WARNER NH 03278 USA "
    },
    {
      "Location ID": "03279",
      "Location Name": "WARREN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 LAKE TARLETON RD UNIT 1 WARREN NH 03279 USA "
    },
    {
      "Location ID": "03280",
      "Location Name": "WASHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 173 N MAIN ST WASHINGTON NH 03280 USA "
    },
    {
      "Location ID": "03281",
      "Location Name": "WEARE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 DUCK POND RD WEARE NH 03281 USA "
    },
    {
      "Location ID": "03282",
      "Location Name": "WENTWORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 WENTWORTH VILLAGE RD UNIT B WENTWORTH NH 03282 USA "
    },
    {
      "Location ID": "03284",
      "Location Name": "SPRINGFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2776 MAIN ST SPRINGFIELD NH 03284 USA "
    },
    {
      "Location ID": "03287",
      "Location Name": "WILMOT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 VILLAGE RD WILMOT NH 03287 USA "
    },
    {
      "Location ID": "03289",
      "Location Name": "WINNISQUAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 969 LACONIA RD WINNISQUAM NH 03289 USA "
    },
    {
      "Location ID": "03290",
      "Location Name": "NOTTINGHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 STAGE RD NOTTINGHAM NH 03290 USA "
    },
    {
      "Location ID": "03291",
      "Location Name": "WEST NOTTINGHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 368 STAGE RD UNIT 2 WEST NOTTINGHAM NH 03291 USA "
    },
    {
      "Location ID": "032AN",
      "Location Name": "ANNALEE DOLLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 339 DANIEL WEBSTER HWY MEREDITH NH 03253 USA "
    },
    {
      "Location ID": "032DM",
      "Location Name": "J JILL DM MGMT (TILTON)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 BIRCH POND ROAD TILTON NH 03299 USA "
    },
    {
      "Location ID": "032LP",
      "Location Name": "LAKEPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 CLINTON ST LACONIA NH 03246 USA "
    },
    {
      "Location ID": "032MA",
      "Location Name": "MADERIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 BAYSIDE COURT LACONIA NH 03246 USA "
    },
    {
      "Location ID": "032MP",
      "Location Name": "MEGAPRINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1177 NH 175 HOLDERNESS NH 03245 USA "
    },
    {
      "Location ID": "032PA",
      "Location Name": "3PALL LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1064 1ST NH TURNPIKE #2 NORTHWOOD NH 03261 USA "
    },
    {
      "Location ID": "03301",
      "Location Name": "CONCORD RETAIL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 LOUDON RD CONCORD NH 03301 USA "
    },
    {
      "Location ID": "03303",
      "Location Name": "PENACOOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 VILLAGE ST STE 4 CONCORD NH 03303-9997 USA "
    },
    {
      "Location ID": "033DM",
      "Location Name": "CONCORD ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22 CHENELL DR CONCORD NH 03301 USA "
    },
    {
      "Location ID": "033PP",
      "Location Name": "PENNEY PRESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 570 ROUTE 106 NORTH LOUDON NH 03307-1127 USA "
    },
    {
      "Location ID": "033TS",
      "Location Name": "CONCORD LITHO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 92 OLD TURNPIKE RD CONCORD NH 03301 USA "
    },
    {
      "Location ID": "03431",
      "Location Name": "KEENE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 196 MAIN ST KEENE NH 03431 USA "
    },
    {
      "Location ID": "03440",
      "Location Name": "ANTRIM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 POST RD ANTRIM NH 03440 USA "
    },
    {
      "Location ID": "03441",
      "Location Name": "ASHUELOT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 ASHUELOT MAIN ST ASHUELOT NH 03441 USA "
    },
    {
      "Location ID": "03443",
      "Location Name": "CHESTERFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 523 ROUTE 63 CHESTERFIELD NH 03443 USA "
    },
    {
      "Location ID": "03444",
      "Location Name": "DUBLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1251 MAIN ST DUBLIN NH 03444 USA "
    },
    {
      "Location ID": "03445",
      "Location Name": "SULLIVAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 311 CENTRE ST SULLIVAN NH 03445 USA "
    },
    {
      "Location ID": "03446",
      "Location Name": "SWANZEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 486 OLD HOMESTEAD HWY SWANZEY NH 03446 USA "
    },
    {
      "Location ID": "03447",
      "Location Name": "FITZWILLIAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 NH ROUTE 12 S FITZWILLIAM NH 03447 USA "
    },
    {
      "Location ID": "03448",
      "Location Name": "GILSUM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 MAIN ST GILSUM NH 03448 USA "
    },
    {
      "Location ID": "03449",
      "Location Name": "HANCOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 59 MAIN ST HANCOCK NH 03449 USA "
    },
    {
      "Location ID": "03450",
      "Location Name": "HARRISVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 CHURCH ST SIDE HARRISVILLE NH 03450 USA "
    },
    {
      "Location ID": "03451",
      "Location Name": "HINSDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 MAIN ST HINSDALE NH 03451 USA "
    },
    {
      "Location ID": "03452",
      "Location Name": "JAFFREY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 TURNPIKE RD JAFFREY NH 03452 USA "
    },
    {
      "Location ID": "03455",
      "Location Name": "MARLBOROUGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 136 MAIN ST MARLBOROUGH NH 03455 USA "
    },
    {
      "Location ID": "03456",
      "Location Name": "MARLOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 842 NH ROUTE 10 MARLOW NH 03456 USA "
    },
    {
      "Location ID": "03458",
      "Location Name": "PETERBOROUGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23 GROVE ST PETERBOROUGH NH 03458 USA "
    },
    {
      "Location ID": "03461",
      "Location Name": "RINDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1056 NH ROUTE 119 RINDGE NH 03461 USA "
    },
    {
      "Location ID": "03462",
      "Location Name": "SPOFFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 562 N SHORE RD SPOFFORD NH 03462 USA "
    },
    {
      "Location ID": "03465",
      "Location Name": "TROY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 MARLBORO RD STE F TROY NH 03465 USA "
    },
    {
      "Location ID": "03466",
      "Location Name": "WEST CHESTERFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 913 GULF RD UNIT C WEST CHESTERFIELD NH 03466 USA "
    },
    {
      "Location ID": "03467",
      "Location Name": "WESTMORELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 776 ROUTE 63 WESTMORELAND NH 03467 USA "
    },
    {
      "Location ID": "03468",
      "Location Name": "WEST PETERBOROUGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 UNION ST UNIT C WEST PETERBOROUGH NH 03468 USA "
    },
    {
      "Location ID": "03469",
      "Location Name": "WEST SWANZEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 769 W SWANZEY RD WEST SWANZEY NH 03469 USA "
    },
    {
      "Location ID": "03470",
      "Location Name": "WINCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 RICHMOND RD WINCHESTER NH 03470 USA "
    },
    {
      "Location ID": "034BS",
      "Location Name": "THE BIKE SHOP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 466 WEST STREET KEENE NH 03431 USA "
    },
    {
      "Location ID": "034DC",
      "Location Name": "DOUGLAS CUDDLE TOYS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 69 KRIF RD KEENE NH 03431 USA "
    },
    {
      "Location ID": "034HD",
      "Location Name": "HARRISVILLE DESIGNS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 KADAKIT ST HARRISVILLE NH 03450 USA "
    },
    {
      "Location ID": "034MC",
      "Location Name": "MOUNTAIN CORPORATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 59 OPTICAL AVE KEENE NH 03431 USA "
    },
    {
      "Location ID": "034MN",
      "Location Name": "THE MOUNTAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 WILCOX COURT MARLBOROUGH NH 03455 USA "
    },
    {
      "Location ID": "034OK",
      "Location Name": "OLIVE KIDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 45 THATCHER HILL ROAD MARLBOROUGH NH 03455 USA "
    },
    {
      "Location ID": "034SC",
      "Location Name": "SO CLEAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 VOSE FARM RD PETERBOROUGH NH 03458 USA "
    },
    {
      "Location ID": "034TT",
      "Location Name": "TEE TREE CARDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 KRIF CT KEENE NH 03431 USA "
    },
    {
      "Location ID": "03561",
      "Location Name": "LITTLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 134 MAIN ST STE 100 LITTLETON NH 03561 USA "
    },
    {
      "Location ID": "03570",
      "Location Name": "BERLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 MOUNT FORIST ST BERLIN NH 03570 USA "
    },
    {
      "Location ID": "03574",
      "Location Name": "BETHLEHEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2159 MAIN ST BETHLEHEM NH 03574 USA "
    },
    {
      "Location ID": "03575",
      "Location Name": "BRETTON WOODS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 310 MOUNT WASHINGTON HOTEL RD UNIT 1 BRETTON WOODS NH 03575 USA "
    },
    {
      "Location ID": "03576",
      "Location Name": "COLEBROOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 PARSONS ST COLEBROOK NH 03576 USA "
    },
    {
      "Location ID": "03580",
      "Location Name": "FRANCONIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 308 MAIN ST FRANCONIA NH 03580 USA "
    },
    {
      "Location ID": "03581",
      "Location Name": "GORHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 165 MAIN ST GORHAM NH 03581 USA "
    },
    {
      "Location ID": "03582",
      "Location Name": "GROVETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 59 CHURCH ST GROVETON NH 03582 USA "
    },
    {
      "Location ID": "03583",
      "Location Name": "JEFFERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 736 PRESIDENTIAL HWY JEFFERSON NH 03583 USA "
    },
    {
      "Location ID": "03584",
      "Location Name": "LANCASTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 MAIN ST LANCASTER NH 03584 USA "
    },
    {
      "Location ID": "03585",
      "Location Name": "LISBON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 N MAIN ST LISBON NH 03585 USA "
    },
    {
      "Location ID": "03589",
      "Location Name": "MOUNT WASHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3168 BASE STATION RD UNIT 1 MOUNT WASHINGTON NH 03589 USA "
    },
    {
      "Location ID": "03590",
      "Location Name": "NORTH STRATFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 79 MAIN ST UNIT 1 NORTH STRATFORD NH 03590 USA "
    },
    {
      "Location ID": "03592",
      "Location Name": "PITTSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1551 MAIN ST PITTSBURG NH 03592 USA "
    },
    {
      "Location ID": "03595",
      "Location Name": "TWIN MOUNTAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 US HIGHWAY 3 N TWIN MOUNTAIN NH 03595 USA "
    },
    {
      "Location ID": "03597",
      "Location Name": "WEST STEWARTSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 MAIN ST WEST STEWARTSTOWN NH 03597 USA "
    },
    {
      "Location ID": "03598",
      "Location Name": "WHITEFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 JEFFERSON RD WHITEFIELD NH 03598 USA "
    },
    {
      "Location ID": "035LC",
      "Location Name": "LITTLETON COIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1309 MT. EUSTIS ROAD LITTLETON NH 03561 USA "
    },
    {
      "Location ID": "035SH",
      "Location Name": "SUGAR HILL NPU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1410 ROUTE 117 SUGAR HILL NH 03586 USA "
    },
    {
      "Location ID": "035WM",
      "Location Name": "WHITE MOUNTAIN FOOTWEAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1014 PROFILE RD BETHLEHEM NH 03574 USA "
    },
    {
      "Location ID": "03601",
      "Location Name": "ACWORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 HILL RD ACWORTH NH 03601 USA "
    },
    {
      "Location ID": "03602",
      "Location Name": "ALSTEAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 42 HIGH ST ALSTEAD NH 03602 USA "
    },
    {
      "Location ID": "03603",
      "Location Name": "CHARLESTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 CHURCH ST CHARLESTOWN NH 03603 USA "
    },
    {
      "Location ID": "03605",
      "Location Name": "LEMPSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 767 US ROUTE 10 LEMPSTER NH 03605 USA "
    },
    {
      "Location ID": "03607",
      "Location Name": "SOUTH ACWORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1068 ROUTE 123A UNIT 1 SOUTH ACWORTH NH 03607 USA "
    },
    {
      "Location ID": "03608",
      "Location Name": "WALPOLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 47 MAIN ST UNIT 1 WALPOLE NH 03608 USA "
    },
    {
      "Location ID": "03740",
      "Location Name": "BATH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 W BATH RD BATH NH 03740 USA "
    },
    {
      "Location ID": "03741",
      "Location Name": "CANAAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1205 US ROUTE 4 CANAAN NH 03741 USA "
    },
    {
      "Location ID": "03743",
      "Location Name": "CLAREMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 140 BROAD ST CLAREMONT NH 03743 USA "
    },
    {
      "Location ID": "03746",
      "Location Name": "CORNISH FLAT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 241 NH ROUTE 120 CORNISH FLAT NH 03746 USA "
    },
    {
      "Location ID": "03748",
      "Location Name": "ENFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 MAPLE ST ENFIELD NH 03748 USA "
    },
    {
      "Location ID": "03749",
      "Location Name": "ENFIELD CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1089 NH ROUTE 4A ENFIELD CENTER NH 03749 USA "
    },
    {
      "Location ID": "03750",
      "Location Name": "ETNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 SCHOOL HOUSE LN ETNA NH 03750 USA "
    },
    {
      "Location ID": "03751",
      "Location Name": "GEORGES MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1294 ROUTE 11 GEORGES MILLS NH 03751 USA "
    },
    {
      "Location ID": "03753",
      "Location Name": "GRANTHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 WILLIS AVE GRANTHAM NH 03753 USA "
    },
    {
      "Location ID": "03754",
      "Location Name": "GUILD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 HALE ST GUILD NH 03754 USA "
    },
    {
      "Location ID": "03755",
      "Location Name": "HANOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 S MAIN ST HANOVER NH 03755 USA "
    },
    {
      "Location ID": "03765",
      "Location Name": "HAVERHILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 630 DARTMOUTH COLLEGE HWY HAVERHILL NH 03765 USA "
    },
    {
      "Location ID": "03766",
      "Location Name": "LEBANON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 E PARK ST LEBANON NH 03766 USA "
    },
    {
      "Location ID": "03768",
      "Location Name": "LYME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 MAIN ST UNIT 5 LYME NH 03768 USA "
    },
    {
      "Location ID": "03770",
      "Location Name": "MERIDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 54 MAIN ST UNIT 1 MERIDEN NH 03770 USA "
    },
    {
      "Location ID": "03773",
      "Location Name": "NEWPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 PARK ST NEWPORT NH 03773 USA "
    },
    {
      "Location ID": "03774",
      "Location Name": "NORTH HAVERHILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2730 DARTMOUTH COLLEGE HWY NORTH HAVERHILL NH 03774 USA "
    },
    {
      "Location ID": "03777",
      "Location Name": "ORFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 621 NH ROUTE 10 ORFORD NH 03777 USA "
    },
    {
      "Location ID": "03779",
      "Location Name": "PIERMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 CHURCH ST UNIT 2 PIERMONT NH 03779 USA "
    },
    {
      "Location ID": "03780",
      "Location Name": "PIKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1572 MOUNT MOOSILAUKE HWY PIKE NH 03780 USA "
    },
    {
      "Location ID": "03781",
      "Location Name": "PLAINFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1132 ROUTE 12A PLAINFIELD NH 03781 USA "
    },
    {
      "Location ID": "03782",
      "Location Name": "SUNAPEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 ELM ST SUNAPEE NH 03782 USA "
    },
    {
      "Location ID": "03785",
      "Location Name": "WOODSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31 S COURT ST STE 5 WOODSVILLE NH 03785 USA "
    },
    {
      "Location ID": "037CA",
      "Location Name": "WEST LEBANON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 66 BENNING ST STE 10 WEST LEBANON NH 03784 USA "
    },
    {
      "Location ID": "037UV",
      "Location Name": "UPPER VALLEY PRESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 446 BENTON ROAD NORTH HAVERHILL NH 03774 USA "
    },
    {
      "Location ID": "037VP",
      "Location Name": "UPPER VALLEY PRESS (BRADFORD)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 446 BENTON ROAD N. HAVERHILL NH 03774 USA "
    },
    {
      "Location ID": "03801",
      "Location Name": "PORTSMOUTH RETAIL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 345 HERITAGE AVE PORTSMOUTH NH 03801 USA "
    },
    {
      "Location ID": "03802",
      "Location Name": "CPU PEASE INTL TRADE PORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 MANCHESTER SQ STE 170 PORTSMOUTH NH 03801 USA "
    },
    {
      "Location ID": "03804",
      "Location Name": "PORTSMOUTH NAVAL SHIPYARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 MCDONOUGH ST PORTSMOUTH NH 03804-4000 USA "
    },
    {
      "Location ID": "03809",
      "Location Name": "ALTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 SCHOOL ST ALTON NH 03809 USA "
    },
    {
      "Location ID": "03810",
      "Location Name": "ALTON BAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 MOUNT MAJOR HWY UNIT 1 ALTON BAY NH 03810 USA "
    },
    {
      "Location ID": "03811",
      "Location Name": "ATKINSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 MAIN ST ATKINSON NH 03811 USA "
    },
    {
      "Location ID": "03812",
      "Location Name": "BARTLETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1373 US ROUTE 302 BARTLETT NH 03812-9998 USA "
    },
    {
      "Location ID": "03814",
      "Location Name": "CENTER OSSIPEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 71 MAIN ST CENTER OSSIPEE NH 03814 USA "
    },
    {
      "Location ID": "03816",
      "Location Name": "CENTER TUFTONBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 MIDDLE ROAD CENTER TUFTONBORO NH 03816 USA "
    },
    {
      "Location ID": "03817",
      "Location Name": "CHOCORUA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 CHOCORUA MOUNTAIN HWY CHOCORUA NH 03817 USA "
    },
    {
      "Location ID": "03818",
      "Location Name": "CONWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 175 MAIN ST CONWAY NH 03818 USA "
    },
    {
      "Location ID": "03819",
      "Location Name": "DANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 PINE ST DANVILLE NH 03819 USA "
    },
    {
      "Location ID": "03820",
      "Location Name": "DOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 133 WASHINGTON ST DOVER NH 03820 USA "
    },
    {
      "Location ID": "03822",
      "Location Name": "LIBERTY MUTUAL (DOVER)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 LIBERTY WAY DOVER NH 03820-0999 USA "
    },
    {
      "Location ID": "03824",
      "Location Name": "DURHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 MADBURY RD DURHAM NH 03824 USA "
    },
    {
      "Location ID": "03825",
      "Location Name": "BARRINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 MALLEGO RD BARRINGTON NH 03825 USA "
    },
    {
      "Location ID": "03826",
      "Location Name": "EAST HAMPSTEAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 59 COLBY COR EAST HAMPSTEAD NH 03826 USA "
    },
    {
      "Location ID": "03827",
      "Location Name": "EAST KINGSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 176 HAVERHILL RD EAST KINGSTON NH 03827 USA "
    },
    {
      "Location ID": "03833",
      "Location Name": "EXETER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 FRONT ST EXETER NH 03833 USA "
    },
    {
      "Location ID": "03835",
      "Location Name": "FARMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 PLEASANT ST FARMINGTON NH 03835 USA "
    },
    {
      "Location ID": "03838",
      "Location Name": "GLEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 69 US ROUTE 302 UNIT B GLEN NH 03838-9998 USA "
    },
    {
      "Location ID": "03840",
      "Location Name": "GREENLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 609 PORTSMOUTH AVE GREENLAND NH 03840 USA "
    },
    {
      "Location ID": "03841",
      "Location Name": "HAMPSTEAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 212 MAIN ST HAMPSTEAD NH 03841 USA "
    },
    {
      "Location ID": "03842",
      "Location Name": "HAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 STICKNEY TER HAMPTON NH 03842 USA "
    },
    {
      "Location ID": "03844",
      "Location Name": "HAMPTON FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 EXETER RD HAMPTON FALLS NH 03844 USA "
    },
    {
      "Location ID": "03845",
      "Location Name": "INTERVALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 99 INTERVALE CROSS RD INTERVALE NH 03845 USA "
    },
    {
      "Location ID": "03846",
      "Location Name": "JACKSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 BLACK MOUNTAIN RD JACKSON NH 03846-9998 USA "
    },
    {
      "Location ID": "03848",
      "Location Name": "KINGSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 53 CHURCH ST KINGSTON NH 03848 USA "
    },
    {
      "Location ID": "03849",
      "Location Name": "MADISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1904 VILLAGE RD MADISON NH 03849 USA "
    },
    {
      "Location ID": "03850",
      "Location Name": "MELVIN VILLAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 448 GOVERNOR WENTWORTH HWY UNIT C MELVIN VILLAGE NH 03850 USA "
    },
    {
      "Location ID": "03851",
      "Location Name": "MILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 851 WHITE MOUNTAIN HWY MILTON NH 03851 USA "
    },
    {
      "Location ID": "03853",
      "Location Name": "MIRROR LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 TUFTONBORO NECK RD MIRROR LAKE NH 03853 USA "
    },
    {
      "Location ID": "03854",
      "Location Name": "NEW CASTLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 73 MAIN ST NEW CASTLE NH 03854 USA "
    },
    {
      "Location ID": "03855",
      "Location Name": "NEW DURHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 DEPOT RD STE 1 NEW DURHAM NH 03855 USA "
    },
    {
      "Location ID": "03856",
      "Location Name": "NEWFIELDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 MAIN ST NEWFIELDS NH 03856 USA "
    },
    {
      "Location ID": "03857",
      "Location Name": "NEWMARKET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 126 MAIN ST NEWMARKET NH 03857 USA "
    },
    {
      "Location ID": "03858",
      "Location Name": "NEWTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 77 S MAIN ST NEWTON NH 03858 USA "
    },
    {
      "Location ID": "03859",
      "Location Name": "NEWTON JUNCTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 W MAIN ST NEWTON JUNCTION NH 03859 USA "
    },
    {
      "Location ID": "03860",
      "Location Name": "NORTH CONWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 78 GROVE ST NORTH CONWAY NH 03860 USA "
    },
    {
      "Location ID": "03862",
      "Location Name": "NORTH HAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44 LAFAYETTE RD NORTH HAMPTON NH 03862 USA "
    },
    {
      "Location ID": "03864",
      "Location Name": "OSSIPEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 OLD ROUTE 28 OSSIPEE NH 03864 USA "
    },
    {
      "Location ID": "03865",
      "Location Name": "PLAISTOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 38 MAIN ST PLAISTOW NH 03865 USA "
    },
    {
      "Location ID": "03867",
      "Location Name": "ROCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 ALLEN ST ROCHESTER NH 03867 USA "
    },
    {
      "Location ID": "03869",
      "Location Name": "ROLLINSFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 664 MAIN ST ROLLINSFORD NH 03869 USA "
    },
    {
      "Location ID": "03870",
      "Location Name": "RYE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 CENTRAL RD RYE NH 03870 USA "
    },
    {
      "Location ID": "03871",
      "Location Name": "RYE BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 830 CENTRAL RD RYE BEACH NH 03871 USA "
    },
    {
      "Location ID": "03872",
      "Location Name": "SANBORNVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 378 MEADOW ST SANBORNVILLE NH 03872 USA "
    },
    {
      "Location ID": "03873",
      "Location Name": "SANDOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 345 MAIN ST SANDOWN NH 03873 USA "
    },
    {
      "Location ID": "03874",
      "Location Name": "SEABROOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 MAIN ST SEABROOK NH 03874 USA "
    },
    {
      "Location ID": "03875",
      "Location Name": "SILVER LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1381 VILLAGE RD SILVER LAKE NH 03875 USA "
    },
    {
      "Location ID": "03878",
      "Location Name": "SOMERSWORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 GOVERNMENT WAY SOMERSWORTH NH 03878 USA "
    },
    {
      "Location ID": "03884",
      "Location Name": "STRAFFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1079 PARKER MOUNTAIN RD STRAFFORD NH 03884 USA "
    },
    {
      "Location ID": "03885",
      "Location Name": "STRATHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 COLLEGE RD STRATHAM NH 03885 USA "
    },
    {
      "Location ID": "03887",
      "Location Name": "UNION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 402 MAIN ST UNION NH 03887 USA "
    },
    {
      "Location ID": "03890",
      "Location Name": "WEST OSSIPEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2326 ROUTE 16 WEST OSSIPEE NH 03890 USA "
    },
    {
      "Location ID": "03894",
      "Location Name": "WOLFEBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 S MAIN ST WOLFEBORO NH 03894 USA "
    },
    {
      "Location ID": "03896",
      "Location Name": "WOLFEBORO FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 CENTER ST WOLFEBORO FALLS NH 03896 USA "
    },
    {
      "Location ID": "038AX",
      "Location Name": "PORTSMOUTH ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 345 HERITAGE AVE UNIT 100 PORTSMOUTH NH 03801 USA "
    },
    {
      "Location ID": "038BM",
      "Location Name": "BAM LAB LCC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 MAIN ST ROCHESTER NH 03839 USA "
    },
    {
      "Location ID": "038BP",
      "Location Name": "PENNINGTON AND BAILES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22 MARIN WAY STRATHAM NH 03885 USA "
    },
    {
      "Location ID": "038CB",
      "Location Name": "CB GITTY CRAFTER SUPPLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 73 PICKERING RD STE 201 ROCHESTER NH 03839 USA "
    },
    {
      "Location ID": "038CR",
      "Location Name": "NEW HAMPSHIRE COFFEE ROASTERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 SUMNER DR DOVER NH 03820 USA "
    },
    {
      "Location ID": "038CS",
      "Location Name": "COED SPORTSWEAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 PLEASANT ST STE 2 NEWFIELDS NH 03856 USA "
    },
    {
      "Location ID": "038GG",
      "Location Name": "GOURMET GIFT BASKETS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 GOURMET PLACE EXETER NH 03833 USA "
    },
    {
      "Location ID": "038GL",
      "Location Name": "GLERUPS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 PLEASANT ST STE 1 NEWFIELDS NH 03856 USA "
    },
    {
      "Location ID": "038HF",
      "Location Name": "HOME FASHIONS DESIGNS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44 INDUSTRIAL PARK DR DOVER NH 03820 USA "
    },
    {
      "Location ID": "038KK",
      "Location Name": "KIMS KORNER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 860 WHITE MOUNTAIN HIGHWAY TAMWORTH NH 03886 USA "
    },
    {
      "Location ID": "038KT",
      "Location Name": "KITTERY TRADING POST WAREHOUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 INDUSTRIAL PARK DOVER NH 03820 USA "
    },
    {
      "Location ID": "038LM",
      "Location Name": "LIBERTY MUTUAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 LIBERTY WAY DOVER NH 03820 USA "
    },
    {
      "Location ID": "038MY",
      "Location Name": "MILL YARDAGE LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 FRONT ST SUITE 113 ROLLINSFORD NH 03869 USA "
    },
    {
      "Location ID": "038OS",
      "Location Name": "OHMIBOD/SUKI - LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22 MARIN WAY STRATHAM NH 03885 USA "
    },
    {
      "Location ID": "038PB",
      "Location Name": "Penalty Box",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 HILLDALE DR PLAISTOW NH 03865 USA "
    },
    {
      "Location ID": "038PP",
      "Location Name": "NATIONAL PASSPORT CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 207 INTERNATIONAL DRIVE PORTSMOUTH NH 03801 USA "
    },
    {
      "Location ID": "038PW",
      "Location Name": "ROCKY COAST PRINTWORKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 SUMNER DR DOVER NH 03820 USA "
    },
    {
      "Location ID": "038SN",
      "Location Name": "Sleepnet",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 MERRILL DR HAMPTON NH 03842 USA "
    },
    {
      "Location ID": "038TK",
      "Location Name": "TOP KAYAKER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 860 WHITE MOUNTAIN HWY STE 6 TAMWORTH NH 03886 USA "
    },
    {
      "Location ID": "038TP",
      "Location Name": "TRADEPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 PROGRESS DR UNIT 7 DOVER NH 03820 USA "
    },
    {
      "Location ID": "038WC",
      "Location Name": "WORLDWIDE CHOCOLATE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 643 FRANKLIN PIERCE HWY BARRINGTON NH 03825 USA "
    },
    {
      "Location ID": "038WR",
      "Location Name": "WRISTIES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 FRONT ST SUITE 116 ROLLINSFORD NH 03869 USA "
    },
    {
      "Location ID": "038WS",
      "Location Name": "WOODEN SOLDIER",
      "Time Zone Offset (Hours)": 0,
      "Address": " ROUTE 16/302 INTERVALE NH 03845-9500 USA "
    },
    {
      "Location ID": "03901",
      "Location Name": "BERWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 43 ROUTE 236 BERWICK ME 03901 USA "
    },
    {
      "Location ID": "03902",
      "Location Name": "CAPE NEDDICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1268 US ROUTE 1 CAPE NEDDICK ME 03902 USA "
    },
    {
      "Location ID": "03903",
      "Location Name": "ELIOT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 LEVESQUE DR STE C ELIOT ME 03903 USA "
    },
    {
      "Location ID": "03904",
      "Location Name": "KITTERY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 SHAPLEIGH RD STE 9998 KITTERY ME 03904 USA "
    },
    {
      "Location ID": "03905",
      "Location Name": "KITTERY POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 91 PEPPERRELL RD KITTERY POINT ME 03905 USA "
    },
    {
      "Location ID": "03906",
      "Location Name": "NORTH BERWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 WELLS ST NORTH BERWICK ME 03906 USA "
    },
    {
      "Location ID": "03907",
      "Location Name": "OGUNQUIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 172 MAIN ST OGUNQUIT ME 03907 USA "
    },
    {
      "Location ID": "03908",
      "Location Name": "SOUTH BERWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 MAIN ST SOUTH BERWICK ME 03908 USA "
    },
    {
      "Location ID": "03909",
      "Location Name": "YORK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 WOODBRIDGE RD YORK ME 03909 USA "
    },
    {
      "Location ID": "03910",
      "Location Name": "YORK BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 420 RIDGE RD YORK BEACH ME 03910 USA "
    },
    {
      "Location ID": "03911",
      "Location Name": "YORK HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 445 YORK ST YORK HARBOR ME 03911 USA "
    },
    {
      "Location ID": "040",
      "Location Name": "SOUTHERN MAINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 79 POSTAL SERVICE WAY SCARBOROUGH ME 04074 USA "
    },
    {
      "Location ID": "04001",
      "Location Name": "ACTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 179 SAM PAGE RD ACTON ME 04001 USA "
    },
    {
      "Location ID": "04002",
      "Location Name": "ALFRED",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 KENNEBUNK RD ALFRED ME 04002 USA "
    },
    {
      "Location ID": "04003",
      "Location Name": "BAILEY ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2120 HARPSWELL ISLANDS RD BAILEY ISLAND ME 04003 USA "
    },
    {
      "Location ID": "04005",
      "Location Name": "BIDDEFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 ALFRED ST BIDDEFORD ME 04005 USA "
    },
    {
      "Location ID": "04006",
      "Location Name": "BIDDEFORD POOL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 LESTER B ORCUTT BLVD BIDDEFORD POOL ME 04006 USA "
    },
    {
      "Location ID": "04008",
      "Location Name": "BOWDOINHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 49 POND RD BOWDOINHAM ME 04008 USA "
    },
    {
      "Location ID": "04009",
      "Location Name": "BRIDGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 ELM ST BRIDGTON ME 04009 USA "
    },
    {
      "Location ID": "04010",
      "Location Name": "BROWNFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 MAIN ST BROWNFIELD ME 04010 USA "
    },
    {
      "Location ID": "04011",
      "Location Name": "BRUNSWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 PLEASANT ST BRUNSWICK ME 04011 USA "
    },
    {
      "Location ID": "04014",
      "Location Name": "CAPE PORPOISE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 167 MAIN ST CAPE PORPOISE ME 04014 USA "
    },
    {
      "Location ID": "04015",
      "Location Name": "CASCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 942 MEADOW RD CASCO ME 04015 USA "
    },
    {
      "Location ID": "04016",
      "Location Name": "CENTER LOVELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 OLD STAGE RD CENTER LOVELL ME 04016 USA "
    },
    {
      "Location ID": "04021",
      "Location Name": "CUMBERLAND CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 FARWELL AVE CUMBERLAND CENTER ME 04021 USA "
    },
    {
      "Location ID": "04022",
      "Location Name": "DENMARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 E MAIN ST DENMARK ME 04022 USA "
    },
    {
      "Location ID": "04024",
      "Location Name": "EAST BALDWIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 213 PEQUAWKET TRL EAST BALDWIN ME 04024 USA "
    },
    {
      "Location ID": "04027",
      "Location Name": "LEBANON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 LITTLE RIVER RD LEBANON ME 04027 USA "
    },
    {
      "Location ID": "04029",
      "Location Name": "SEBAGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 222 SEBAGO RD SEBAGO ME 04029 USA "
    },
    {
      "Location ID": "04037",
      "Location Name": "FRYEBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 488 MAIN ST FRYEBURG ME 04037 USA "
    },
    {
      "Location ID": "04038",
      "Location Name": "GORHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 POST OFFICE SQ GORHAM ME 04038 USA "
    },
    {
      "Location ID": "04039",
      "Location Name": "GRAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 TURNPIKE ACRES RD GRAY ME 04039 USA "
    },
    {
      "Location ID": "04040",
      "Location Name": "HARRISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 FRONT ST HARRISON ME 04040 USA "
    },
    {
      "Location ID": "04041",
      "Location Name": "HIRAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 SCHOOLHOUSE RD HIRAM ME 04041 USA "
    },
    {
      "Location ID": "04043",
      "Location Name": "KENNEBUNK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 POST OFFICE SQ KENNEBUNK ME 04043 USA "
    },
    {
      "Location ID": "04046",
      "Location Name": "KENNEBUNKPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 TEMPLE ST KENNEBUNKPORT ME 04046 USA "
    },
    {
      "Location ID": "04051",
      "Location Name": "LOVELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 216 MAIN ST LOVELL ME 04051 USA "
    },
    {
      "Location ID": "04054",
      "Location Name": "MOODY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 243 POST RD MOODY ME 04054 USA "
    },
    {
      "Location ID": "04055",
      "Location Name": "NAPLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 VILLAGE GREEN LN NAPLES ME 04055 USA "
    },
    {
      "Location ID": "04057",
      "Location Name": "NORTH BRIDGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 N BRIDGTON RD NORTH BRIDGTON ME 04057 USA "
    },
    {
      "Location ID": "04062",
      "Location Name": "WINDHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 765 ROOSEVELT TRL STE 7 WINDHAM ME 04062 USA "
    },
    {
      "Location ID": "04063",
      "Location Name": "OCEAN PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 COLBY AVE OCEAN PARK ME 04063 USA "
    },
    {
      "Location ID": "04064",
      "Location Name": "OLD ORCHARD BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 CASCADE RD STE 8 OLD ORCHARD BEACH ME 04064 USA "
    },
    {
      "Location ID": "04066",
      "Location Name": "ORRS ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1729 HARPSWELL ISLANDS RD ORRS ISLAND ME 04066 USA "
    },
    {
      "Location ID": "04069",
      "Location Name": "POWNAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 195 ALLEN RD POWNAL ME 04069 USA "
    },
    {
      "Location ID": "04071",
      "Location Name": "RAYMOND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1235 ROOSEVELT TRL RAYMOND ME 04071 USA "
    },
    {
      "Location ID": "04072",
      "Location Name": "SACO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 225 MAIN ST SACO ME 04072 USA "
    },
    {
      "Location ID": "04073",
      "Location Name": "SANFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 SCHOOL ST SANFORD ME 04073 USA "
    },
    {
      "Location ID": "04074",
      "Location Name": "SCARBOROUGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31 GORHAM RD SCARBOROUGH ME 04074 USA "
    },
    {
      "Location ID": "04076",
      "Location Name": "SHAPLEIGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 994 SHAPLEIGH CORNER RD SHAPLEIGH ME 04076 USA "
    },
    {
      "Location ID": "04077",
      "Location Name": "SOUTH CASCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 326 ROOSEVELT TRL SOUTH CASCO ME 04077 USA "
    },
    {
      "Location ID": "04078",
      "Location Name": "SOUTH FREEPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 95 S FREEPORT RD SOUTH FREEPORT ME 04078 USA "
    },
    {
      "Location ID": "04079",
      "Location Name": "HARPSWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1212 HARPSWELL NECK RD HARPSWELL ME 04079 USA "
    },
    {
      "Location ID": "04082",
      "Location Name": "SOUTH WINDHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 57 MAIN ST SOUTH WINDHAM ME 04082 USA "
    },
    {
      "Location ID": "04083",
      "Location Name": "SPRINGVALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 492 MAIN ST SPRINGVALE ME 04083 USA "
    },
    {
      "Location ID": "04085",
      "Location Name": "STEEP FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1090 PEQUAWKET TRL STEEP FALLS ME 04085 USA "
    },
    {
      "Location ID": "04086",
      "Location Name": "TOPSHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 MAIN ST TOPSHAM ME 04086 USA "
    },
    {
      "Location ID": "04088",
      "Location Name": "WATERFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 965 WATERFORD RD WATERFORD ME 04088 USA "
    },
    {
      "Location ID": "04090",
      "Location Name": "WELLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1886 POST RD WELLS ME 04090 USA "
    },
    {
      "Location ID": "04091",
      "Location Name": "WEST BALDWIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 932 PEQUAWKET TRL WEST BALDWIN ME 04091 USA "
    },
    {
      "Location ID": "040BA",
      "Location Name": "INDUSTRIAL PARK ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 81 INDUSTRIAL PARK RD SACO ME 04072 USA "
    },
    {
      "Location ID": "041",
      "Location Name": "PORTLAND LOWER DOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 FOREST AVE PORTLAND ME 04101 USA "
    },
    {
      "Location ID": "04210",
      "Location Name": "AUBURN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 258 RODMAN RD AUBURN ME 04210 USA "
    },
    {
      "Location ID": "04217",
      "Location Name": "BETHEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 82 MAIN ST BETHEL ME 04217 USA "
    },
    {
      "Location ID": "04219",
      "Location Name": "BRYANT POND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 N MAIN ST BRYANT POND ME 04219 USA "
    },
    {
      "Location ID": "04220",
      "Location Name": "BUCKFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 TURNER ST BUCKFIELD ME 04220 USA "
    },
    {
      "Location ID": "04221",
      "Location Name": "CANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 MAIN ST CANTON ME 04221 USA "
    },
    {
      "Location ID": "04223",
      "Location Name": "DANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 DUNLAP ST DANVILLE ME 04223 USA "
    },
    {
      "Location ID": "04224",
      "Location Name": "DIXFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 MAIN ST DIXFIELD ME 04224 USA "
    },
    {
      "Location ID": "04225",
      "Location Name": "DRYDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 MUNSON RD DRYDEN ME 04225 USA "
    },
    {
      "Location ID": "04227",
      "Location Name": "EAST DIXFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 963 US ROUTE 2 W EAST DIXFIELD ME 04227 USA "
    },
    {
      "Location ID": "04228",
      "Location Name": "EAST LIVERMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1047 PARK ST EAST LIVERMORE ME 04228 USA "
    },
    {
      "Location ID": "04231",
      "Location Name": "STONEHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 341 MAINE ST STONEHAM ME 04231 USA "
    },
    {
      "Location ID": "04234",
      "Location Name": "EAST WILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1328 MAIN ST EAST WILTON ME 04234 USA "
    },
    {
      "Location ID": "04236",
      "Location Name": "GREENE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 677 ROUTE 202 GREENE ME 04236 USA "
    },
    {
      "Location ID": "04238",
      "Location Name": "HEBRON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 337 PARIS RD HEBRON ME 04238 USA "
    },
    {
      "Location ID": "04239",
      "Location Name": "JAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 158 MAIN ST JAY ME 04239 USA "
    },
    {
      "Location ID": "04240",
      "Location Name": "LEWISTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 49 ASH ST LEWISTON ME 04240 USA "
    },
    {
      "Location ID": "04250",
      "Location Name": "LISBON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 331 LISBON ST LISBON ME 04250 USA "
    },
    {
      "Location ID": "04252",
      "Location Name": "LISBON FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 MAIN ST STE 9998 LISBON FALLS ME 04252 USA "
    },
    {
      "Location ID": "04253",
      "Location Name": "LIVERMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 BEAR MOUNTAIN RD LIVERMORE ME 04253 USA "
    },
    {
      "Location ID": "04254",
      "Location Name": "LIVERMORE FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 MAIN ST LIVERMORE FALLS ME 04254 USA "
    },
    {
      "Location ID": "04255",
      "Location Name": "GREENWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 268 MAIN ST GREENWOOD ME 04255 USA "
    },
    {
      "Location ID": "04256",
      "Location Name": "MECHANIC FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 DEPOT SQ MECHANIC FALLS ME 04256 USA "
    },
    {
      "Location ID": "04257",
      "Location Name": "MEXICO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 RIVERSIDE AVE MEXICO ME 04257 USA "
    },
    {
      "Location ID": "04258",
      "Location Name": "MINOT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 348 MINOT AVE MINOT ME 04258 USA "
    },
    {
      "Location ID": "04259",
      "Location Name": "MONMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 744 MAIN ST MONMOUTH ME 04259 USA "
    },
    {
      "Location ID": "04260",
      "Location Name": "NEW GLOUCESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 GILMORE RD NEW GLOUCESTER ME 04260 USA "
    },
    {
      "Location ID": "04263",
      "Location Name": "LEEDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1279 ROUTE 106 LEEDS ME 04263 USA "
    },
    {
      "Location ID": "04265",
      "Location Name": "NORTH MONMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 140 N MAIN ST NORTH MONMOUTH ME 04265 USA "
    },
    {
      "Location ID": "04266",
      "Location Name": "NORTH TURNER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 HOWES CORNER RD NORTH TURNER ME 04266 USA "
    },
    {
      "Location ID": "04267",
      "Location Name": "NORTH WATERFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 905 VALLEY RD NORTH WATERFORD ME 04267 USA "
    },
    {
      "Location ID": "04268",
      "Location Name": "NORWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 299 MAIN ST NORWAY ME 04268 USA "
    },
    {
      "Location ID": "04270",
      "Location Name": "OXFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 98 PLEASANT ST OXFORD ME 04270 USA "
    },
    {
      "Location ID": "04271",
      "Location Name": "PARIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 TREMONT ST PARIS ME 04271 USA "
    },
    {
      "Location ID": "04273",
      "Location Name": "POLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 965 MAINE ST POLAND ME 04274 USA "
    },
    {
      "Location ID": "04276",
      "Location Name": "RUMFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 137 CONGRESS ST RUMFORD ME 04276 USA "
    },
    {
      "Location ID": "04280",
      "Location Name": "SABATTUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 MIDDLE RD SABATTUS ME 04280 USA "
    },
    {
      "Location ID": "04281",
      "Location Name": "SOUTH PARIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 PARK ST SOUTH PARIS ME 04281 USA "
    },
    {
      "Location ID": "04282",
      "Location Name": "TURNER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 BUCKFIELD RD TURNER ME 04282 USA "
    },
    {
      "Location ID": "04284",
      "Location Name": "WAYNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 516 MAIN ST WAYNE ME 04284 USA "
    },
    {
      "Location ID": "04285",
      "Location Name": "WELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 MILL ST WELD ME 04285 USA "
    },
    {
      "Location ID": "04286",
      "Location Name": "WEST BETHEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 737 W BETHEL RD WEST BETHEL ME 04286 USA "
    },
    {
      "Location ID": "04290",
      "Location Name": "PERU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 93 MAIN ST PERU ME 04290 USA "
    },
    {
      "Location ID": "04291",
      "Location Name": "WEST POLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 91 MEGQUIER HILL RD WEST POLAND ME 04291 USA "
    },
    {
      "Location ID": "04294",
      "Location Name": "WILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 454 MAIN ST WILTON ME 04294 USA "
    },
    {
      "Location ID": "042DP",
      "Location Name": "DINGLEY PRESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 LISBON ST LISBON ME 04250 USA "
    },
    {
      "Location ID": "042GL",
      "Location Name": "GREAT LAKE FULFILLMENT SERVICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41 CANAL ST LEWISTON ME 04240 USA "
    },
    {
      "Location ID": "042OL",
      "Location Name": "ORIGIN LABS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 946 US ROUTE 2 E WILTON ME 04294 USA "
    },
    {
      "Location ID": "042PT",
      "Location Name": "PINETREE GARDEN SEEDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 616 LEWISTON RD NEW GLOUCESTR ME 04260 USA "
    },
    {
      "Location ID": "04330",
      "Location Name": "AUGUSTA POST OFFICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 WESTERN AVE AUGUSTA ME 04330 USA "
    },
    {
      "Location ID": "04341",
      "Location Name": "COOPERS MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 128 MAIN ST COOPERS MILLS ME 04341 USA "
    },
    {
      "Location ID": "04342",
      "Location Name": "DRESDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 MIDDLE RD DRESDEN ME 04342 USA "
    },
    {
      "Location ID": "04343",
      "Location Name": "EAST WINTHROP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2402 US ROUTE 202 EAST WINTHROP ME 04343 USA "
    },
    {
      "Location ID": "04345",
      "Location Name": "GARDINER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 348 WATER ST GARDINER ME 04345 USA "
    },
    {
      "Location ID": "04347",
      "Location Name": "HALLOWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 95 SECOND ST HALLOWELL ME 04347 USA "
    },
    {
      "Location ID": "04348",
      "Location Name": "JEFFERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 133 VILLAGE ST JEFFERSON ME 04348 USA "
    },
    {
      "Location ID": "04349",
      "Location Name": "KENTS HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1613 MAIN ST KENTS HILL ME 04349 USA "
    },
    {
      "Location ID": "04350",
      "Location Name": "LITCHFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1882 HALLOWELL RD LITCHFIELD ME 04350 USA "
    },
    {
      "Location ID": "04351",
      "Location Name": "MANCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31 READFIELD RD MANCHESTER ME 04351 USA "
    },
    {
      "Location ID": "04352",
      "Location Name": "MOUNT VERNON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 MAIN ST MOUNT VERNON ME 04352 USA "
    },
    {
      "Location ID": "04354",
      "Location Name": "PALERMO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 BRANCH MLS PALERMO ME 04354 USA "
    },
    {
      "Location ID": "04355",
      "Location Name": "READFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1138 MAIN ST READFIELD ME 04355 USA "
    },
    {
      "Location ID": "04357",
      "Location Name": "RICHMOND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 92 MAIN ST RICHMOND ME 04357 USA "
    },
    {
      "Location ID": "04358",
      "Location Name": "SOUTH CHINA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 382 ROUTE 3 SOUTH CHINA ME 04358 USA "
    },
    {
      "Location ID": "04359",
      "Location Name": "SOUTH GARDINER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 681 RIVER RD SOUTH GARDINER ME 04359 USA "
    },
    {
      "Location ID": "04360",
      "Location Name": "VIENNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 KIMBALL POND RD VIENNA ME 04360 USA "
    },
    {
      "Location ID": "04363",
      "Location Name": "WINDSOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 519 RIDGE RD WINDSOR ME 04363 USA "
    },
    {
      "Location ID": "04364",
      "Location Name": "WINTHROP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 112 MAIN ST WINTHROP ME 04364 USA "
    },
    {
      "Location ID": "044",
      "Location Name": "EASTERN MAINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 PENOBSCOT MEADOW DR HAMPDEN ME 04444 USA "
    },
    {
      "Location ID": "04401",
      "Location Name": "BANGOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 73 HAMMOND ST STE 9998 BANGOR ME 04401 USA "
    },
    {
      "Location ID": "04402",
      "Location Name": "BANGOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 73 HAMMOND ST STE 9998 BANGOR ME 04401-9998 USA "
    },
    {
      "Location ID": "04406",
      "Location Name": "ABBOT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 WEST RD ABBOT ME 04406 USA "
    },
    {
      "Location ID": "04408",
      "Location Name": "AURORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 68 OLD AIRLINE RD AURORA ME 04408 USA "
    },
    {
      "Location ID": "04410",
      "Location Name": "BRADFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 STORER RD BRADFORD ME 04410 USA "
    },
    {
      "Location ID": "04411",
      "Location Name": "BRADLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 165A MAIN ST BRADLEY ME 04411 USA "
    },
    {
      "Location ID": "04412",
      "Location Name": "BREWER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22 PARKWAY S BREWER ME 04412 USA "
    },
    {
      "Location ID": "04413",
      "Location Name": "BROOKTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 FOREST CITY RD BROOKTON ME 04413 USA "
    },
    {
      "Location ID": "04414",
      "Location Name": "BROWNVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 CHURCH ST BROWNVILLE ME 04414 USA "
    },
    {
      "Location ID": "04415",
      "Location Name": "BROWNVILLE JUNCTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 77 RAILROAD AVE BROWNVILLE JUNCTION ME 04415 USA "
    },
    {
      "Location ID": "04416",
      "Location Name": "BUCKSPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 MECHANIC ST BUCKSPORT ME 04416 USA "
    },
    {
      "Location ID": "04418",
      "Location Name": "GREENBUSH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1076 MAIN RD GREENBUSH ME 04418 USA "
    },
    {
      "Location ID": "04419",
      "Location Name": "CARMEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 950 MAIN RD CARMEL ME 04419 USA "
    },
    {
      "Location ID": "04421",
      "Location Name": "CASTINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 43 MAIN ST CASTINE ME 04421 USA "
    },
    {
      "Location ID": "04422",
      "Location Name": "CHARLESTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 CHARLESTON RD CHARLESTON ME 04422 USA "
    },
    {
      "Location ID": "04424",
      "Location Name": "DANFORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 SPRINGFIELD RD DANFORTH ME 04424 USA "
    },
    {
      "Location ID": "04426",
      "Location Name": "DOVER FOXCROFT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41 NORTH ST DOVER FOXCROFT ME 04426 USA "
    },
    {
      "Location ID": "04427",
      "Location Name": "CORINTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 274 MAIN ST CORINTH ME 04427 USA "
    },
    {
      "Location ID": "04428",
      "Location Name": "EDDINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1042 MAIN RD EDDINGTON ME 04428 USA "
    },
    {
      "Location ID": "04429",
      "Location Name": "HOLDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 966 MAIN RD HOLDEN ME 04429 USA "
    },
    {
      "Location ID": "04430",
      "Location Name": "EAST MILLINOCKET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 95 MAIN ST EAST MILLINOCKET ME 04430 USA "
    },
    {
      "Location ID": "04431",
      "Location Name": "EAST ORLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 986 ACADIA HWY EAST ORLAND ME 04431 USA "
    },
    {
      "Location ID": "04434",
      "Location Name": "ETNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 STAGE RD ETNA ME 04434 USA "
    },
    {
      "Location ID": "04435",
      "Location Name": "EXETER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1220 STETSON RD EXETER ME 04435 USA "
    },
    {
      "Location ID": "04438",
      "Location Name": "FRANKFORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 48B MAIN RD S FRANKFORT ME 04438 USA "
    },
    {
      "Location ID": "04441",
      "Location Name": "GREENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 LILY BAY RD GREENVILLE ME 04441 USA "
    },
    {
      "Location ID": "04442",
      "Location Name": "GREENVILLE JUNCTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 383 PRITHAM AVE GREENVILLE JUNCTION ME 04442 USA "
    },
    {
      "Location ID": "04443",
      "Location Name": "GUILFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 HUDSON AVE GUILFORD ME 04443 USA "
    },
    {
      "Location ID": "04444",
      "Location Name": "HAMPDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 WESTERN AVE HAMPDEN ME 04444 USA "
    },
    {
      "Location ID": "04448",
      "Location Name": "HOWLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 MAIN ST HOWLAND ME 04448 USA "
    },
    {
      "Location ID": "04449",
      "Location Name": "HUDSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 OLD TOWN RD HUDSON ME 04449 USA "
    },
    {
      "Location ID": "04450",
      "Location Name": "KENDUSKEAG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4008 BROADWAY KENDUSKEAG ME 04450 USA "
    },
    {
      "Location ID": "04453",
      "Location Name": "LAGRANGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5763 BENNCH RD LAGRANGE ME 04453 USA "
    },
    {
      "Location ID": "04455",
      "Location Name": "LEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2790 LEE RD LEE ME 04455 USA "
    },
    {
      "Location ID": "04456",
      "Location Name": "LEVANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3454 UNION ST STE 1 LEVANT ME 04456 USA "
    },
    {
      "Location ID": "04457",
      "Location Name": "LINCOLN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 FLEMING ST LINCOLN ME 04457 USA "
    },
    {
      "Location ID": "04459",
      "Location Name": "MATTAWAMKEAG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 328 MAIN ST MATTAWAMKEAG ME 04459 USA "
    },
    {
      "Location ID": "04460",
      "Location Name": "MEDWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2070 MEDWAY RD MEDWAY ME 04460 USA "
    },
    {
      "Location ID": "04461",
      "Location Name": "MILFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 MAIN RD MILFORD ME 04461 USA "
    },
    {
      "Location ID": "04462",
      "Location Name": "MILLINOCKET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 PENOBSCOT AVE MILLINCKET ME 04462 USA "
    },
    {
      "Location ID": "04463",
      "Location Name": "MILO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 PLEASANT ST MILO ME 04463 USA "
    },
    {
      "Location ID": "04464",
      "Location Name": "MONSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 GREENVILLE RD MONSON ME 04464 USA "
    },
    {
      "Location ID": "04468",
      "Location Name": "OLD TOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 141 CENTER ST OLD TOWN ME 04468 USA "
    },
    {
      "Location ID": "04469",
      "Location Name": "UNIVERSITY OF MAINE (ORONO)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5761 PUBLIC AFFAIRS ORONO ME 04469-5761 USA "
    },
    {
      "Location ID": "04472",
      "Location Name": "ORLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 59 CASTINE RD ORLAND ME 04472 USA "
    },
    {
      "Location ID": "04473",
      "Location Name": "ORONO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 BENNOCH RD ORONO ME 04473 USA "
    },
    {
      "Location ID": "04474",
      "Location Name": "ORRINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 726 RIVER RD ORRINGTON ME 04474 USA "
    },
    {
      "Location ID": "04475",
      "Location Name": "PASSADUMKEAG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 70 PLEASANT ST PASSADUMKEAG ME 04475 USA "
    },
    {
      "Location ID": "04476",
      "Location Name": "PENOBSCOT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 N PENOBSCOT RD PENBSCOT ME 04476 USA "
    },
    {
      "Location ID": "04478",
      "Location Name": "ROCKWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 62 VILLAGE RD ROCKWOOD ME 04478 USA "
    },
    {
      "Location ID": "04479",
      "Location Name": "SANGERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 54 MAIN ST SANGERVILLE ME 04479 USA "
    },
    {
      "Location ID": "0447A",
      "Location Name": "ORONO CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 GODFREY DR ORONO ME 04473 USA "
    },
    {
      "Location ID": "04487",
      "Location Name": "SPRINGFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1007 MAIN STREET SPRINGFIELD ME 04487 USA "
    },
    {
      "Location ID": "04488",
      "Location Name": "STETSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 STETSON RD STETSON ME 04488 USA "
    },
    {
      "Location ID": "04489",
      "Location Name": "STILLWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 441 BENNOCH RD STILLWATER ME 04489 USA "
    },
    {
      "Location ID": "04490",
      "Location Name": "TOPSFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 61 SOUTH RD TOPSFIELD ME 04490 USA "
    },
    {
      "Location ID": "04491",
      "Location Name": "VANCEBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23 WATER ST VANCEBORO ME 04491 USA "
    },
    {
      "Location ID": "04493",
      "Location Name": "WEST ENFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41 BRIDGE ST WEST ENFIELD ME 04493 USA "
    },
    {
      "Location ID": "04495",
      "Location Name": "WINN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 986 ROUTE 2 WINN ME 04495 USA "
    },
    {
      "Location ID": "04496",
      "Location Name": "WINTERPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 S MAIN ST WINTERPORT ME 04496 USA "
    },
    {
      "Location ID": "04497",
      "Location Name": "WYTOPITLOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 751 MAIN ST WYTOPITLOCK ME 04497 USA "
    },
    {
      "Location ID": "044CK",
      "Location Name": "CPU C&K VARIETY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 BILLINGS RD HERMON ME 04401 USA "
    },
    {
      "Location ID": "044GW",
      "Location Name": "GLACIER WEAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 76 SPRUCE STREET GREENVILLE ME 04441 USA "
    },
    {
      "Location ID": "044HO",
      "Location Name": "HOME, INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90 SCHOOLHOUSE ROAD ORLAND ME 04472 USA "
    },
    {
      "Location ID": "044SB",
      "Location Name": "SBD APPAREL USA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1185 HAMMOND ST BANGOR ME 04401 USA "
    },
    {
      "Location ID": "044SW",
      "Location Name": "SPRAGUE'S WREATH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1664 UNION ST BANGOR ME 04401 USA "
    },
    {
      "Location ID": "044UM",
      "Location Name": "UNIVERSITY OF MAINE PRINTING AND MAILING SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 129 RANGELEY RD ORONO ME 04469 USA "
    },
    {
      "Location ID": "044WF",
      "Location Name": "WINTERBERRY FARMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 232 SOUTHERN BAY RD HANCOCK ME 04476 USA "
    },
    {
      "Location ID": "04530",
      "Location Name": "BATH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 750 WASHINGTON ST BATH ME 04530 USA "
    },
    {
      "Location ID": "04535",
      "Location Name": "ALNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 148 DOCK RD ALNA ME 04535 USA "
    },
    {
      "Location ID": "04537",
      "Location Name": "BOOTHBAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 COREY LN BOOTHBAY ME 04537 USA "
    },
    {
      "Location ID": "04538",
      "Location Name": "BOOTHBAY HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 OAK ST BOOTHBAY HARBOR ME 04538 USA "
    },
    {
      "Location ID": "04543",
      "Location Name": "DAMARISCOTTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 173 MAIN ST DAMARISCOTTA ME 04543 USA "
    },
    {
      "Location ID": "04548",
      "Location Name": "GEORGETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 765 FIVE ISLANDS RD GEORGETOWN ME 04548 USA "
    },
    {
      "Location ID": "04553",
      "Location Name": "NEWCASTLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 MILLS RD NEWCASTLE ME 04553 USA "
    },
    {
      "Location ID": "04555",
      "Location Name": "NOBLEBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 CENTER ST NOBLEBORO ME 04555 USA "
    },
    {
      "Location ID": "04556",
      "Location Name": "EDGECOMB",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 CROSS RD EDGECOMB ME 04556 USA "
    },
    {
      "Location ID": "04562",
      "Location Name": "PHIPPSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 520 MAIN RD PHIPPSBURG ME 04562 USA "
    },
    {
      "Location ID": "04565",
      "Location Name": "SEBASCO ESTATES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 415 SEBASCO RD SEBASCO ESTATES ME 04565 USA "
    },
    {
      "Location ID": "04571",
      "Location Name": "TREVETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 381A BARTERS ISLAND RD TREVETT ME 04571 USA "
    },
    {
      "Location ID": "04572",
      "Location Name": "WALDOBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 959 MAIN ST WALDOBORO ME 04572 USA "
    },
    {
      "Location ID": "04574",
      "Location Name": "WASHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 LIBERTY RD WASHINGTON ME 04574 USA "
    },
    {
      "Location ID": "04575",
      "Location Name": "WEST BOOTHBAY HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 143 WESTERN AVE WEST BOOTHBAY HARBOR ME 04575 USA "
    },
    {
      "Location ID": "04576",
      "Location Name": "SOUTHPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 432 HENDRICKS HILL RD SOUTHPORT ME 04576 USA "
    },
    {
      "Location ID": "04578",
      "Location Name": "WISCASSET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 140 MAIN ST WISCASSET ME 04578 USA "
    },
    {
      "Location ID": "04579",
      "Location Name": "WOOLWICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 53 MAIN ST WOOLWICH ME 04579 USA "
    },
    {
      "Location ID": "04605",
      "Location Name": "ELLSWORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 199 MAIN ST ELLSWORTH ME 04605 USA "
    },
    {
      "Location ID": "04606",
      "Location Name": "ADDISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 297 WATER ST ADDISON ME 04606 USA "
    },
    {
      "Location ID": "04607",
      "Location Name": "GOULDSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 411 ROUTE 1 GOULDSBORO ME 04607 USA "
    },
    {
      "Location ID": "04609",
      "Location Name": "BAR HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 COTTAGE ST BAR HARBOR ME 04609 USA "
    },
    {
      "Location ID": "04611",
      "Location Name": "BEALS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 43 BAYVIEW DR BEALS ME 04611 USA "
    },
    {
      "Location ID": "04612",
      "Location Name": "BERNARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 71 BERNARD RD BERNARD ME 04612 USA "
    },
    {
      "Location ID": "04613",
      "Location Name": "BIRCH HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 E SCHOODIC DR BIRCH HARBOR ME 04613 USA "
    },
    {
      "Location ID": "04614",
      "Location Name": "BLUE HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 MAIN ST BLUE HILL ME 04614 USA "
    },
    {
      "Location ID": "04616",
      "Location Name": "BROOKLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 REACH RD BROOKLIN ME 04616 USA "
    },
    {
      "Location ID": "04617",
      "Location Name": "BROOKSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 945 COASTAL RD BROOKSVILLE ME 04617 USA "
    },
    {
      "Location ID": "04619",
      "Location Name": "CALAIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 76 NORTH ST CALAIS ME 04619 USA "
    },
    {
      "Location ID": "04622",
      "Location Name": "CHERRYFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 MUNICIPAL WAY CHERRYFIELD ME 04622 USA "
    },
    {
      "Location ID": "04623",
      "Location Name": "COLUMBIA FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 169 MAIN ST COLUMBIA FALLS ME 04623 USA "
    },
    {
      "Location ID": "04624",
      "Location Name": "COREA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 646 COREA RD COREA ME 04624 USA "
    },
    {
      "Location ID": "04627",
      "Location Name": "DEER ISLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 MAIN ST DEER ISLE ME 04627 USA "
    },
    {
      "Location ID": "04628",
      "Location Name": "DENNYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 128 MAIN ST DENNYSVILLE ME 04628 USA "
    },
    {
      "Location ID": "04630",
      "Location Name": "EAST MACHIAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 580 MAIN ST EAST MACHIAS ME 04630 USA "
    },
    {
      "Location ID": "04631",
      "Location Name": "EASTPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 WASHINGTON ST EASTPORT ME 04631 USA "
    },
    {
      "Location ID": "04634",
      "Location Name": "FRANKLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 48 BLACKSWOODS RD FRANKLIN ME 04634 USA "
    },
    {
      "Location ID": "04640",
      "Location Name": "HANCOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1405 US HWY 1 HANCOCK ME 04640 USA "
    },
    {
      "Location ID": "04643",
      "Location Name": "HARRINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1285 MAIN ST HARRINGTON ME 04643 USA "
    },
    {
      "Location ID": "04644",
      "Location Name": "HULLS COVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 BREAK NECK RD HULLS COVE ME 04644 USA "
    },
    {
      "Location ID": "04645",
      "Location Name": "CPU ISLE AU HAUT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 MAIN ST ISLE AU HAUT ME 04645 USA "
    },
    {
      "Location ID": "04648",
      "Location Name": "JONESBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 810 US RTE 1 JONESBORO ME 04648 USA "
    },
    {
      "Location ID": "04649",
      "Location Name": "JONESPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 193 MAIN ST JONESPORT ME 04649 USA "
    },
    {
      "Location ID": "04650",
      "Location Name": "LITTLE DEER ISLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 EGGEMOGGIN ROAD LITTLE DEER ISLE ME 04650 USA "
    },
    {
      "Location ID": "04652",
      "Location Name": "LUBEC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 WASHINGTON STREET LUBEC ME 04652 USA "
    },
    {
      "Location ID": "04653",
      "Location Name": "BASS HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 HARBOR DR BASS HARBOR ME 04653 USA "
    },
    {
      "Location ID": "04654",
      "Location Name": "MACHIAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 89 COURT STREET, STE 1 MACHIAS ME 04654 USA "
    },
    {
      "Location ID": "04658",
      "Location Name": "MILBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 SCHOOL ST MILBRIDGE ME 04658 USA "
    },
    {
      "Location ID": "04660",
      "Location Name": "MOUNT DESERT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1057 MAIN ST MOUNT DESERT ME 04660 USA "
    },
    {
      "Location ID": "04662",
      "Location Name": "NORTHEAST HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 112 MAIN ST NRTHEAST HARBOR ME 04662 USA "
    },
    {
      "Location ID": "04664",
      "Location Name": "SULLIVAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 96 TAUNTON DR SULLIVAN ME 04664 USA "
    },
    {
      "Location ID": "04666",
      "Location Name": "PEMBROKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 475 US ROUTE 1 PEMBROKE ME 04666 USA "
    },
    {
      "Location ID": "04667",
      "Location Name": "PERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 972 US ROUTE 1 PERRY ME 04667 USA "
    },
    {
      "Location ID": "04669",
      "Location Name": "PROSPECT HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 W BAY RD PROSPECT HARBOR ME 04669 USA "
    },
    {
      "Location ID": "04671",
      "Location Name": "ROBBINSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 398 US RTE 1 ROBBINSTON ME 04671 USA "
    },
    {
      "Location ID": "04672",
      "Location Name": "SALSBURY COVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 855 NORWAY DR SALSBURY COVE ME 04672 USA "
    },
    {
      "Location ID": "04674",
      "Location Name": "SEAL COVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 896 TREMONT RD SEAL COVE ME 04674 USA "
    },
    {
      "Location ID": "04675",
      "Location Name": "SEAL HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 MAIN ST SEAL HARBOR ME 04675 USA "
    },
    {
      "Location ID": "04676",
      "Location Name": "SEDGWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 47 REACH RD SEDGWICK ME 04676 USA "
    },
    {
      "Location ID": "04679",
      "Location Name": "SOUTHWEST HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 CLARK POINT RD SOUTHWEST HARBOR ME 04679 USA "
    },
    {
      "Location ID": "04680",
      "Location Name": "STEUBEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 MORGAN PARRITT RD STEUBEN ME 04680 USA "
    },
    {
      "Location ID": "04681",
      "Location Name": "STONINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 W MAIN ST STONINGTON ME 04681 USA "
    },
    {
      "Location ID": "04683",
      "Location Name": "SUNSET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 SUNSET RD SUNSET ME 04683 USA "
    },
    {
      "Location ID": "04684",
      "Location Name": "SURRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1197 SURRY RD SURRY ME 04684 USA "
    },
    {
      "Location ID": "04691",
      "Location Name": "WHITING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 240 US RTE 1 WHITING ME 04691 USA "
    },
    {
      "Location ID": "04693",
      "Location Name": "WINTER HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 267 MAIN ST WINTER HARBOR ME 04693 USA "
    },
    {
      "Location ID": "046AW",
      "Location Name": "ANGIE'S",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 GATCOMB ROAD EAST MICHIAS ME 04630 USA "
    },
    {
      "Location ID": "046BF",
      "Location Name": "BLACKBERRY FARM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44 BLACKBERRY LANE HARRINGTON ME 04643 USA "
    },
    {
      "Location ID": "046BW",
      "Location Name": "WOODEN BOAT CO",
      "Time Zone Offset (Hours)": 0,
      "Address": " GREAT COVE RD BROOKLIN ME 04616 USA "
    },
    {
      "Location ID": "046GL",
      "Location Name": "GALLERY LEATHER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 INDUSTRIAL WAY TRENTON ME 04605 USA "
    },
    {
      "Location ID": "046GW",
      "Location Name": "GAY'S WREATH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 78 RIDGE ROAD MARSHFIELD ME 04654 USA "
    },
    {
      "Location ID": "046HF",
      "Location Name": "HARBOR FARMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 350 SHORE ROAD ELLSWORTH ME 04605 USA "
    },
    {
      "Location ID": "046HW",
      "Location Name": "MAINE HOLIDAY WREATHS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 244 MAIN STREET ELLSWORTH ME 04605 USA "
    },
    {
      "Location ID": "046KW",
      "Location Name": "KELCO WREATHS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 58 MAIN STREET MILBRIDGE ME 04658 USA "
    },
    {
      "Location ID": "046MW",
      "Location Name": "MINGO'S WREATHS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 405 SHATTUCK ROAD CALAIS ME 04619 USA "
    },
    {
      "Location ID": "046NP",
      "Location Name": "NOAH PUBLICATIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 751 REACH ROAD BROOKLIN ME 04616 USA "
    },
    {
      "Location ID": "046SS",
      "Location Name": "SCYTHE SUPPLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 496 SHORE ROAD PERRY ME 04667 USA "
    },
    {
      "Location ID": "046WB",
      "Location Name": "WOODEN BOAT COMPANY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 82 GREAT COVE RD BROOLIN ME 04616 USA "
    },
    {
      "Location ID": "046WR",
      "Location Name": "WORCESTER WREATHS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 399 US HIGHWAY 1 COLUMBIA ME 04623 USA "
    },
    {
      "Location ID": "04730",
      "Location Name": "HOULTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 COURT ST STE 9998 HOULTON ME 04730 USA "
    },
    {
      "Location ID": "04732",
      "Location Name": "ASHLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 SHERIDAN RD ASHLAND ME 04732 USA "
    },
    {
      "Location ID": "04733",
      "Location Name": "BENEDICTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 SCHOOL ST BENEDICTA ME 04733 USA "
    },
    {
      "Location ID": "04734",
      "Location Name": "BLAINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 42 MILITARY RD BLAINE ME 04734 USA "
    },
    {
      "Location ID": "04735",
      "Location Name": "BRIDGEWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 486 MAIN RD BRIDGEWATER ME 04735 USA "
    },
    {
      "Location ID": "04736",
      "Location Name": "CARIBOU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41 SWEDEN ST CARIBOU ME 04736 USA "
    },
    {
      "Location ID": "04738",
      "Location Name": "CROUSEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 437 WASHBURN RD CROUSEVILLE ME 04738 USA "
    },
    {
      "Location ID": "04739",
      "Location Name": "EAGLE LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3315 AROOSTOOK RD EAGLE LAKE ME 04739 USA "
    },
    {
      "Location ID": "04740",
      "Location Name": "EASTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 87 CENTER RD EASTON ME 04740 USA "
    },
    {
      "Location ID": "04742",
      "Location Name": "FORT FAIRFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 289 MAIN ST FORT FAIRFIELD ME 04742 USA "
    },
    {
      "Location ID": "04743",
      "Location Name": "FORT KENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 152 W MAIN ST FORT KENT ME 04743 USA "
    },
    {
      "Location ID": "04744",
      "Location Name": "FORT KENT MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 345 MARKET ST UNIT 102 FORT KENT MILLS ME 04744 USA "
    },
    {
      "Location ID": "04745",
      "Location Name": "FRENCHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 386 US ROUTE 1 FRENCHVILLE ME 04745 USA "
    },
    {
      "Location ID": "04746",
      "Location Name": "GRAND ISLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 TRAVERSE ST GRAND ISLE ME 04746 USA "
    },
    {
      "Location ID": "04747",
      "Location Name": "ISLAND FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1009 CRYSTAL RD ISLAND FALLS ME 04747 USA "
    },
    {
      "Location ID": "04750",
      "Location Name": "LIMESTONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 MAIN ST LIMESTONE ME 04750 USA "
    },
    {
      "Location ID": "04756",
      "Location Name": "MADAWASKA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 370 E MAIN ST MADAWASKA ME 04756 USA "
    },
    {
      "Location ID": "04757",
      "Location Name": "MAPLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1754 MAIN ST MAPLETON ME 04757 USA "
    },
    {
      "Location ID": "04758",
      "Location Name": "MARS HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 LINCOLN ST MARS HILL ME 04758 USA "
    },
    {
      "Location ID": "04760",
      "Location Name": "MONTICELLO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 428 US HWY 1 MONTICELLO ME 04760 USA "
    },
    {
      "Location ID": "04761",
      "Location Name": "NEW LIMERICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1132 COUNTY RD NEW LIMERICK ME 04761 USA "
    },
    {
      "Location ID": "04763",
      "Location Name": "OAKFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 198 OAKFIELD SMYRNA RD OAKFIELD ME 04763 USA "
    },
    {
      "Location ID": "04765",
      "Location Name": "PATTEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 MAIN ST PATTEN ME 04765 USA "
    },
    {
      "Location ID": "04766",
      "Location Name": "PERHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2A MOUSE ISLAND RD PERHAM ME 04766 USA "
    },
    {
      "Location ID": "04768",
      "Location Name": "PORTAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 SCHOOL ST PORTAGE ME 04768 USA "
    },
    {
      "Location ID": "04769",
      "Location Name": "PRESQUE ISLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23 2ND ST PRESQUE ISLE ME 04769 USA "
    },
    {
      "Location ID": "04772",
      "Location Name": "SAINT AGATHA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 353 MAIN ST SAINT AGATHA ME 04772 USA "
    },
    {
      "Location ID": "04773",
      "Location Name": "SAINT DAVID",
      "Time Zone Offset (Hours)": 0,
      "Address": " 994 MAIN ST SAINT DAVID ME 04773 USA "
    },
    {
      "Location ID": "04774",
      "Location Name": "SAINT FRANCIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 890 MAIN ST SAINT FRANCIS ME 04774 USA "
    },
    {
      "Location ID": "04776",
      "Location Name": "SHERMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 WOODBRIDGE CORNER RD SHERMAN ME 04776 USA "
    },
    {
      "Location ID": "04777",
      "Location Name": "STACYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 418 STATION ST STACYVILLE ME 04777 USA "
    },
    {
      "Location ID": "04779",
      "Location Name": "SINCLAIR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 251 SINCLAIR RD SINCLAIR ME 04779 USA "
    },
    {
      "Location ID": "04780",
      "Location Name": "SMYRNA MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3391 US ROUTE 2 SMYRNA MILLS ME 04780 USA "
    },
    {
      "Location ID": "04781",
      "Location Name": "WALLAGRASS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 174 SOLDIER POND RD WALLAGRASS ME 04781 USA "
    },
    {
      "Location ID": "04785",
      "Location Name": "VAN BUREN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 42 MAIN ST VAN BUREN ME 04785 USA "
    },
    {
      "Location ID": "04786",
      "Location Name": "WASHBURN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 WOODMAN ST WASHBURN ME 04786 USA "
    },
    {
      "Location ID": "04787",
      "Location Name": "WESTFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 286 BURLEIGH RD WESTFIELD ME 04787 USA "
    },
    {
      "Location ID": "047BP",
      "Location Name": "BURRELL'S INFORMATION SERVICES LLC, ME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 MAIN ST PRESQUE ISLE ME 04769 USA "
    },
    {
      "Location ID": "047PS",
      "Location Name": "PRO SUPPLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1086 VAN BUREN RD CARIBOU ME 04736 USA "
    },
    {
      "Location ID": "047WP",
      "Location Name": "WOOD PRAIRIE FARMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 49 KINNEY RD BRIDGEWATER ME 04735 USA "
    },
    {
      "Location ID": "04841",
      "Location Name": "ROCKLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 LIMEROCK ST STE 9998 ROCKLAND ME 04841 USA "
    },
    {
      "Location ID": "04843",
      "Location Name": "CAMDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 CHESTNUT ST CAMDEN ME 04843 USA "
    },
    {
      "Location ID": "04849",
      "Location Name": "LINCOLNVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2512 ATLANTIC HWY LINCOLNVILLE ME 04849 USA "
    },
    {
      "Location ID": "04850",
      "Location Name": "LINCOLNVILLE CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 499 HOPE RD LINCOLNVILLE CENTER ME 04850 USA "
    },
    {
      "Location ID": "04852",
      "Location Name": "MONHEGAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 258 MONHEGAN AVE MONHEGAN ME 04852 USA "
    },
    {
      "Location ID": "04854",
      "Location Name": "OWLS HEAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 MAIN ST OWLS HEAD ME 04854 USA "
    },
    {
      "Location ID": "04855",
      "Location Name": "PORT CLYDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 860 PORT CLYDE RD PORT CLYDE ME 04855 USA "
    },
    {
      "Location ID": "04856",
      "Location Name": "ROCKPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 223 UNION ST ROCKPORT ME 04856 USA "
    },
    {
      "Location ID": "04858",
      "Location Name": "SOUTH THOMASTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 ELM ST SOUTH THOMASTON ME 04858 USA "
    },
    {
      "Location ID": "04859",
      "Location Name": "SPRUCE HEAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 POST OFFICE RD SPRUCE HEAD ME 04859 USA "
    },
    {
      "Location ID": "04860",
      "Location Name": "TENANTS HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 MAIN ST TENANTS HARBOR ME 04860 USA "
    },
    {
      "Location ID": "048CW",
      "Location Name": "CEDAR WORKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 799 COMMERCIAL ST ROCKPORT ME 04856 USA "
    },
    {
      "Location ID": "04901",
      "Location Name": "WATERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 COLLEGE AVE WATERVILLE ME 04901 USA "
    },
    {
      "Location ID": "04910",
      "Location Name": "ALBION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 MAIN ST ALBION ME 04910 USA "
    },
    {
      "Location ID": "04911",
      "Location Name": "ANSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 72 MAIN ST ANSON ME 04911 USA "
    },
    {
      "Location ID": "04912",
      "Location Name": "ATHENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 BRIGHTON RD ATHENS ME 04912 USA "
    },
    {
      "Location ID": "04915",
      "Location Name": "BELFAST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 FRANKLIN ST BELFAST ME 04915 USA "
    },
    {
      "Location ID": "04917",
      "Location Name": "BELGRADE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1010 AUGUSTA RD BELGRADE ME 04917 USA "
    },
    {
      "Location ID": "04918",
      "Location Name": "BELGRADE LAKES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 171 MAIN ST BELGRADE LAKES ME 04918 USA "
    },
    {
      "Location ID": "04920",
      "Location Name": "BINGHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 MAIN ST BINGHAM ME 04920 USA "
    },
    {
      "Location ID": "04921",
      "Location Name": "BROOKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 PURPLE HEART HWY BROOKS ME 04921 USA "
    },
    {
      "Location ID": "04922",
      "Location Name": "BURNHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 TROY RD BURNHAM ME 04922 USA "
    },
    {
      "Location ID": "04923",
      "Location Name": "CAMBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 MAIN ST CAMBRIDGE ME 04923 USA "
    },
    {
      "Location ID": "04924",
      "Location Name": "CANAAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 273 MAIN ST CANAAN ME 04924 USA "
    },
    {
      "Location ID": "04925",
      "Location Name": "CARATUNK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 172 MAIN ST CARATUNK ME 04925 USA "
    },
    {
      "Location ID": "04926",
      "Location Name": "CHINA VILLAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 MAIN ST CHINA VILLAGE ME 04926 USA "
    },
    {
      "Location ID": "04927",
      "Location Name": "CLINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 RAILROAD ST CLINTON ME 04927 USA "
    },
    {
      "Location ID": "04928",
      "Location Name": "CORINNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 EXETER RD CORINNA ME 04928 USA "
    },
    {
      "Location ID": "04929",
      "Location Name": "DETROIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 MAIN ST DETROIT ME 04929 USA "
    },
    {
      "Location ID": "04930",
      "Location Name": "DEXTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 POST OFFICE RD DEXTER ME 04930 USA "
    },
    {
      "Location ID": "04932",
      "Location Name": "DIXMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 975 WESTERN AVE DIXMONT ME 04932 USA "
    },
    {
      "Location ID": "04933",
      "Location Name": "EAST NEWPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 679 ELM ST EAST NEWPORT ME 04933 USA "
    },
    {
      "Location ID": "04935",
      "Location Name": "EAST VASSALBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 351 MAIN ST EAST VASSALBORO ME 04935 USA "
    },
    {
      "Location ID": "04936",
      "Location Name": "EUSTIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1050 THE ARNOLD TRL EUSTIS ME 04936 USA "
    },
    {
      "Location ID": "04937",
      "Location Name": "FAIRFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 MAIN ST FAIRFIELD ME 04937 USA "
    },
    {
      "Location ID": "04938",
      "Location Name": "FARMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 196 MAIN ST FARMINGTON ME 04938 USA "
    },
    {
      "Location ID": "04939",
      "Location Name": "GARLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 OLIVER HILL RD GARLAND ME 04939 USA "
    },
    {
      "Location ID": "04940",
      "Location Name": "FARMINGTON FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 174 CROSWELL RD FARMINGTON FALLS ME 04940 USA "
    },
    {
      "Location ID": "04941",
      "Location Name": "FREEDOM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 93 BELFAST RD FREEDOM ME 04941 USA "
    },
    {
      "Location ID": "04942",
      "Location Name": "HARMONY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 MAIN ST HARMONY ME 04942 USA "
    },
    {
      "Location ID": "04943",
      "Location Name": "HARTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90 MAIN ST HARTLAND ME 04943 USA "
    },
    {
      "Location ID": "04944",
      "Location Name": "HINCKLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 753 SKOWHEGAN RD HINCKLEY ME 04944 USA "
    },
    {
      "Location ID": "04945",
      "Location Name": "JACKMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 552 MAIN ST JACKMAN ME 04945 USA "
    },
    {
      "Location ID": "04946",
      "Location Name": "JACKMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 552 MAIN ST JACKMAN ME 04945-9998 USA "
    },
    {
      "Location ID": "04947",
      "Location Name": "KINGFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 DEPOT ST KINGFIELD ME 04947 USA "
    },
    {
      "Location ID": "04949",
      "Location Name": "LIBERTY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 76 MAIN ST LIBERTY ME 04949 USA "
    },
    {
      "Location ID": "04950",
      "Location Name": "MADISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 WESTON AVE MADISON ME 04950 USA "
    },
    {
      "Location ID": "04951",
      "Location Name": "MONROE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 59 W MAIN ST MONROE ME 04951 USA "
    },
    {
      "Location ID": "04952",
      "Location Name": "MORRILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 63 N MAIN ST MORRILL ME 04952 USA "
    },
    {
      "Location ID": "04953",
      "Location Name": "NEWPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 85 MAIN ST NEWPORT ME 04953 USA "
    },
    {
      "Location ID": "04955",
      "Location Name": "NEW SHARON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 POST OFFICE RD NEW SHARON ME 04955 USA "
    },
    {
      "Location ID": "04956",
      "Location Name": "NEW VINEYARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 LAKE ST NEW VINEYARD ME 04956 USA "
    },
    {
      "Location ID": "04957",
      "Location Name": "NORRIDGEWOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 CLARK ST NORRIDGEWOCK ME 04957 USA "
    },
    {
      "Location ID": "04958",
      "Location Name": "NORTH ANSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 MADISON ST NORTH ANSON ME 04958 USA "
    },
    {
      "Location ID": "04961",
      "Location Name": "NEW PORTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 186 MEADOW BROOK RD NEW PORTLAND ME 04961 USA "
    },
    {
      "Location ID": "04962",
      "Location Name": "NORTH VASSALBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 847 MAIN ST NORTH VASSALBORO ME 04962 USA "
    },
    {
      "Location ID": "04963",
      "Location Name": "OAKLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 62 WATER ST OAKLAND ME 04993 USA "
    },
    {
      "Location ID": "04964",
      "Location Name": "OQUOSSOC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 92 CARRY RD OQUOSSOC ME 04964 USA "
    },
    {
      "Location ID": "04965",
      "Location Name": "PALMYRA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 WARREN HILL RD PALMYRA ME 04965 USA "
    },
    {
      "Location ID": "04966",
      "Location Name": "PHILLIPS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 91 DODGE RD PHILLIPS ME 04966 USA "
    },
    {
      "Location ID": "04967",
      "Location Name": "PITTSFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 131 CENTRAL ST PITTSFIELD ME 04967 USA "
    },
    {
      "Location ID": "04969",
      "Location Name": "PLYMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1947 MOOSEHEAD TRL PLYMOUTH ME 04969 USA "
    },
    {
      "Location ID": "04970",
      "Location Name": "RANGELEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2517 MAIN ST RANGELEY ME 04970 USA "
    },
    {
      "Location ID": "04971",
      "Location Name": "SAINT ALBANS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 MAIN ST SAINT ALBANS ME 04971 USA "
    },
    {
      "Location ID": "04973",
      "Location Name": "SEARSMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 49 MAIN ST N SEARSMONT ME 04973 USA "
    },
    {
      "Location ID": "04974",
      "Location Name": "SEARSPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 WATER ST SEARSPORT ME 04974 USA "
    },
    {
      "Location ID": "04975",
      "Location Name": "SHAWMUT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 BRAY AVE SHAWMUT ME 04975 USA "
    },
    {
      "Location ID": "04976",
      "Location Name": "SKOWHEGAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 242 WATER ST SKOWHEGAN ME 04976 USA "
    },
    {
      "Location ID": "04978",
      "Location Name": "SMITHFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 860 VILLAGE RD SMITHFIELD ME 04978 USA "
    },
    {
      "Location ID": "04979",
      "Location Name": "SOLON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 FERRY ST SOLON ME 04979 USA "
    },
    {
      "Location ID": "04981",
      "Location Name": "STOCKTON SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 221 MAIN ST STOCKTON SPRINGS ME 04981 USA "
    },
    {
      "Location ID": "04982",
      "Location Name": "STRATTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 95 MAIN ST STRATTON ME 04982 USA "
    },
    {
      "Location ID": "04983",
      "Location Name": "STRONG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 136 N MAIN ST STRONG ME 04983 USA "
    },
    {
      "Location ID": "04984",
      "Location Name": "TEMPLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 TEMPLE RD TEMPLE ME 04984 USA "
    },
    {
      "Location ID": "04985",
      "Location Name": "WEST FORKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2933 US RTE 201 WEST FORKS ME 04985 USA "
    },
    {
      "Location ID": "04986",
      "Location Name": "THORNDIKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 58 MOUNT VIEW RD THORNDIKE ME 04986 USA "
    },
    {
      "Location ID": "04987",
      "Location Name": "TROY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 846 BANGOR RD TROY ME 04987 USA "
    },
    {
      "Location ID": "04988",
      "Location Name": "UNITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 CLIFFORD COMMONS 1 UNITY ME 04988 USA "
    },
    {
      "Location ID": "04989",
      "Location Name": "VASSALBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 ALPINE ST VASSALBORO ME 04989 USA "
    },
    {
      "Location ID": "04992",
      "Location Name": "WEST FARMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 126 BRIDGE ST WEST FARMINGTON ME 04992 USA "
    },
    {
      "Location ID": "049CM",
      "Location Name": "CONTINENTAL MARKETING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 LITHGOW STREET WINSLOW ME 04901 USA "
    },
    {
      "Location ID": "049FS",
      "Location Name": "FEDCO SEEDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 688 BELLSQUEEZE RD CLINTON ME 04927 USA "
    },
    {
      "Location ID": "049HM",
      "Location Name": "HAMILTON MARINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 155 E MAIN ST SEARSPORT ME 04974 USA "
    },
    {
      "Location ID": "049JS",
      "Location Name": "JOHNNYS SEL SEEDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 955 BENTON AVE WINSLOW ME 04901 USA "
    },
    {
      "Location ID": "049MW",
      "Location Name": "CENTRAL ME WREATH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 228 NORTH AVE SKOWHEGAN ME 04976 USA "
    },
    {
      "Location ID": "049PF",
      "Location Name": "PERFECT FIT SHIELD WALLETS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 STETSON ROAD CORINNA ME 04928 USA "
    },
    {
      "Location ID": "049WH",
      "Location Name": "WHEEL HOUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 835 LOWER DETROIT RD PLYMOUTH ME 04969 USA "
    },
    {
      "Location ID": "050",
      "Location Name": "WHITE RIVER JUNCTION P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 195 SYKES MOUNTAIN AVE WHITE RIVER JUNCTION VT 05001 USA "
    },
    {
      "Location ID": "05030",
      "Location Name": "ASCUTNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5159 ROUTE 5 ASCUTNEY VT 05030 USA "
    },
    {
      "Location ID": "05031",
      "Location Name": "BARNARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6430 STAGE RD UNIT 1 BARNARD VT 05031 USA "
    },
    {
      "Location ID": "05032",
      "Location Name": "BETHEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 N MAIN ST BETHEL VT 05032 USA "
    },
    {
      "Location ID": "05033",
      "Location Name": "BRADFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41 BARTON ST BRADFORD VT 05033 USA "
    },
    {
      "Location ID": "05034",
      "Location Name": "BRIDGEWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 MILL RD BRIDGEWATER VT 05034 USA "
    },
    {
      "Location ID": "05035",
      "Location Name": "BRIDGEWATER CORNERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 VT ROUTE 100A BRIDGEWATER CORNERS VT 05035 USA "
    },
    {
      "Location ID": "05037",
      "Location Name": "BROWNSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1269 ROUTE 44 BROWNSVILLE VT 05037 USA "
    },
    {
      "Location ID": "05042",
      "Location Name": "EAST RYEGATE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 57 PAPERMILL RD EAST RYEGATE VT 05042 USA "
    },
    {
      "Location ID": "05045",
      "Location Name": "FAIRLEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 SCHOOL ST FAIRLEE VT 05045 USA "
    },
    {
      "Location ID": "05046",
      "Location Name": "GROTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1236 SCOTT HWY GROTON VT 05046 USA "
    },
    {
      "Location ID": "05047",
      "Location Name": "HARTFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1238 MAPLE ST HARTFORD VT 05047 USA "
    },
    {
      "Location ID": "05048",
      "Location Name": "HARTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 159 US ROUTE 5 HARTLAND VT 05048 USA "
    },
    {
      "Location ID": "05050",
      "Location Name": "MC INDOE FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37 ACADEMY LN MC INDOE FALLS VT 05050 USA "
    },
    {
      "Location ID": "05051",
      "Location Name": "NEWBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4982 MAIN ST N NEWBURY VT 05051 USA "
    },
    {
      "Location ID": "05052",
      "Location Name": "NORTH HARTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 619 US ROUTE 5 NORTH HARTLAND VT 05052 USA "
    },
    {
      "Location ID": "05054",
      "Location Name": "NORTH THETFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5378 RTE 5 NORTH THETFORD VT 05054 USA "
    },
    {
      "Location ID": "05055",
      "Location Name": "NORWICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 293 MAIN ST NORWICH VT 05055 USA "
    },
    {
      "Location ID": "05059",
      "Location Name": "QUECHEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 91 VILLAGE GREEN CIR QUECHEE VT 05059 USA "
    },
    {
      "Location ID": "05060",
      "Location Name": "RANDOLPH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 SALISBURY ST RANDOLPH VT 05060 USA "
    },
    {
      "Location ID": "05062",
      "Location Name": "READING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 799 ROUTE 106 READING VT 05062 USA "
    },
    {
      "Location ID": "05065",
      "Location Name": "SHARON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5094 ROUTE 14 SHARON VT 05065 USA "
    },
    {
      "Location ID": "05068",
      "Location Name": "SOUTH ROYALTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 239 CHELSEA ST SOUTH ROYALTON VT 05068 USA "
    },
    {
      "Location ID": "05069",
      "Location Name": "SOUTH RYEGATE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2544 SCOTT HWY SOUTH RYEGATE VT 05069 USA "
    },
    {
      "Location ID": "05071",
      "Location Name": "SOUTH WOODSTOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4800 SOUTH RD SOUTH WOODSTOCK VT 05071 USA "
    },
    {
      "Location ID": "05073",
      "Location Name": "TAFTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 HAPPY VALLEY RD TAFTSVILLE VT 05073 USA "
    },
    {
      "Location ID": "05074",
      "Location Name": "THETFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2734 RTE 113 THETFORD VT 05074 USA "
    },
    {
      "Location ID": "05081",
      "Location Name": "WELLS RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31 MAIN ST WELLS RIVER VT 05081 USA "
    },
    {
      "Location ID": "05088",
      "Location Name": "WILDER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 DEPOT ST WILDER VT 05088 USA "
    },
    {
      "Location ID": "05089",
      "Location Name": "WINDSOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 57 MAIN ST WINDSOR VT 05089 USA "
    },
    {
      "Location ID": "05091",
      "Location Name": "WOODSTOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22 CENTRAL ST WOODSTOCK VT 05091 USA "
    },
    {
      "Location ID": "050KA",
      "Location Name": "KING ARTHUR FLOUR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 58 BILLINGS FARMS ROAD WILDER VT 05001 USA "
    },
    {
      "Location ID": "05101",
      "Location Name": "BELLOWS FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 BRIDGE ST BELLOWS FALLS VT 05101 USA "
    },
    {
      "Location ID": "05142",
      "Location Name": "CAVENDISH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2025 MAIN ST CAVENDISH VT 05142 USA "
    },
    {
      "Location ID": "05143",
      "Location Name": "CHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 203 S MAIN ST CHESTER VT 05143 USA "
    },
    {
      "Location ID": "05146",
      "Location Name": "GRAFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 MAIN ST GRAFTON VT 05146 USA "
    },
    {
      "Location ID": "05148",
      "Location Name": "LONDONDERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5660 VT ROUTE 100 LONDONDERRY VT 05148 USA "
    },
    {
      "Location ID": "05149",
      "Location Name": "LUDLOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 57 POND ST STE 8 LUDLOW VT 05149 USA "
    },
    {
      "Location ID": "05150",
      "Location Name": "NORTH SPRINGFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 MAIN ST NORTH SPRINGFIELD VT 05150 USA "
    },
    {
      "Location ID": "05151",
      "Location Name": "PERKINSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7290 VT ROUTE 131 PERKINSVILLE VT 05151 USA "
    },
    {
      "Location ID": "05153",
      "Location Name": "PROCTORSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 DEPOT ST PROCTORSVILLE VT 05153 USA "
    },
    {
      "Location ID": "05154",
      "Location Name": "SAXTONS RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 MAIN ST SAXTONS RIVER VT 05154 USA "
    },
    {
      "Location ID": "05155",
      "Location Name": "SOUTH LONDONDERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 67 MAIN ST SOUTH LONDONDERRY VT 05155 USA "
    },
    {
      "Location ID": "05156",
      "Location Name": "SPRINGFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 132 MAIN ST SPRINGFIELD VT 05156 USA "
    },
    {
      "Location ID": "05158",
      "Location Name": "WESTMINSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3658 US ROUTE 5 WESTMINSTER VT 05158 USA "
    },
    {
      "Location ID": "05159",
      "Location Name": "WESTMINSTER STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5187 US ROUTE 5 WESTMINSTER STATION VT 05159 USA "
    },
    {
      "Location ID": "05201",
      "Location Name": "BENNINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 ELM ST BENNINGTON VT 05201 USA "
    },
    {
      "Location ID": "05250",
      "Location Name": "ARLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3575 VT ROUTE 7A ARLINGTON VT 05250 USA "
    },
    {
      "Location ID": "05251",
      "Location Name": "DORSET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3309 ROUTE 30 DORSET VT 05251 USA "
    },
    {
      "Location ID": "05252",
      "Location Name": "EAST ARLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 243 OLD MILL RD STE 1 EAST ARLINGTON VT 05252 USA "
    },
    {
      "Location ID": "05253",
      "Location Name": "EAST DORSET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 MAD TOM RD EAST DORSET VT 05253 USA "
    },
    {
      "Location ID": "05254",
      "Location Name": "MANCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 SEMINARY AVE MANCHESTER VT 05254 USA "
    },
    {
      "Location ID": "05255",
      "Location Name": "MANCHESTER CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3452 RICHVILLE RD MANCHESTER CENTER VT 05255 USA "
    },
    {
      "Location ID": "05257",
      "Location Name": "NORTH BENNINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 52 MAIN ST NORTH BENNINGTON VT 05257 USA "
    },
    {
      "Location ID": "05262",
      "Location Name": "SHAFTSBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 56 CHURCH ST SHAFTSBURY VT 05262 USA "
    },
    {
      "Location ID": "05301",
      "Location Name": "BRATTLEBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 204 MAIN ST STE JJ BRATTLEBORO VT 05301 USA "
    },
    {
      "Location ID": "05340",
      "Location Name": "BONDVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 43 VT ROUTE 30 BONDVILLE VT 05340 USA "
    },
    {
      "Location ID": "05341",
      "Location Name": "EAST DOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 NORTH ST UNIT A EAST DOVER VT 05341 USA "
    },
    {
      "Location ID": "05342",
      "Location Name": "JACKSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2939 VT ROUTE 100 JACKSONVILLE VT 05342 USA "
    },
    {
      "Location ID": "05343",
      "Location Name": "JAMAICA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3912 VT ROUTE 30 JAMAICA VT 05343 USA "
    },
    {
      "Location ID": "05344",
      "Location Name": "MARLBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 510 SOUTH RD MARLBORO VT 05344 USA "
    },
    {
      "Location ID": "05345",
      "Location Name": "NEWFANE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 562 VT ROUTE 30 NEWFANE VT 05345 USA "
    },
    {
      "Location ID": "05346",
      "Location Name": "PUTNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 MAIN ST PUTNEY VT 05346 USA "
    },
    {
      "Location ID": "05350",
      "Location Name": "READSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6802 MAIN ST READSBORO VT 05350 USA "
    },
    {
      "Location ID": "05353",
      "Location Name": "TOWNSHEND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1955 VT ROUTE 30 TOWNSHEND VT 05353 USA "
    },
    {
      "Location ID": "05354",
      "Location Name": "VERNON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2531 FORT BRIDGMAN RD VERNON VT 05354 USA "
    },
    {
      "Location ID": "05356",
      "Location Name": "WEST DOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 MOUNTAIN PARK PLZ UNIT A WEST DOVER VT 05356 USA "
    },
    {
      "Location ID": "05357",
      "Location Name": "WEST DUMMERSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 LYONS ST UNIT A WEST DUMMERSTON VT 05357 USA "
    },
    {
      "Location ID": "05358",
      "Location Name": "WEST HALIFAX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3438 REED HILL RD WEST HALIFAX VT 05358 USA "
    },
    {
      "Location ID": "05359",
      "Location Name": "WEST TOWNSHEND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6573 VT ROUTE 30 WEST TOWNSHEND VT 05359 USA "
    },
    {
      "Location ID": "05361",
      "Location Name": "WHITINGHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6677 VT ROUTE 100 WHITINGHAM VT 05361 USA "
    },
    {
      "Location ID": "05362",
      "Location Name": "WILLIAMSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 67 DOVER RD WILLIAMSVILLE VT 05362 USA "
    },
    {
      "Location ID": "05363",
      "Location Name": "WILMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 E MAIN ST WILMINGTON VT 05363 USA "
    },
    {
      "Location ID": "053CI",
      "Location Name": "Cultural Intrigue",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 FROST STREET BRATTLEBORO VT 05301 USA "
    },
    {
      "Location ID": "053EB",
      "Location Name": "ECHO BOOKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22 BROWNE COURT BRATTLEBORO VT 05301 USA "
    },
    {
      "Location ID": "053FM",
      "Location Name": "FIVE MAPLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 78  RIVER ROAD SOUTH PUTNEY VT 05346 USA "
    },
    {
      "Location ID": "053GM",
      "Location Name": "GREEN MOUNTAIN DIAPERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 74 COTTON MILL HILL BRATTLEBORO VT 05301 USA "
    },
    {
      "Location ID": "053HS",
      "Location Name": "HIDDEN SPRINGS MAPLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 162 WESTMINSTER RD PUTNEY VT 05346 USA "
    },
    {
      "Location ID": "053MR",
      "Location Name": "PUTNEY (MAIL RITE)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 78  RIVER ROAD SOUTH PUTNEY VT 05346 USA "
    },
    {
      "Location ID": "053NB",
      "Location Name": "NORTH BRATTLEBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1222 PUTNEY RD BRATTLEBORO VT 05301 USA "
    },
    {
      "Location ID": "053WB",
      "Location Name": "WEST BRATTLEBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1037 WESTERN AVE STE 2 BRATTLEBORO VT 05301 USA "
    },
    {
      "Location ID": "054",
      "Location Name": "BURLINGTON P&DF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 NEW ENGLAND DR STE 997 ESSEX JUNCTION VT 05452 USA "
    },
    {
      "Location ID": "05401",
      "Location Name": "BURLINGTON MPO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 ELMWOOD AVE LBBY BURLINGTON VT 05401 USA "
    },
    {
      "Location ID": "05403",
      "Location Name": "SOUTH BURLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 WHITE ST SOUTH BURLINGTON VT 05403 USA "
    },
    {
      "Location ID": "05404",
      "Location Name": "WINOOSKI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 MAIN ST WINOOSKI VT 05404 USA "
    },
    {
      "Location ID": "05406",
      "Location Name": "CHAMPLAIN STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 370 SHELBURNE RD STE 4 BURLINGTON VT 05401 USA "
    },
    {
      "Location ID": "05408",
      "Location Name": "NORTH BURLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1127 NORTH AVE STE 40 BURLINGTON VT 05408 USA "
    },
    {
      "Location ID": "05440",
      "Location Name": "ALBURGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 S MAIN ST ALBURGH VT 05440 USA "
    },
    {
      "Location ID": "05441",
      "Location Name": "BAKERSFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 65 MAIN ST N BAKERSFIELD VT 05441 USA "
    },
    {
      "Location ID": "05443",
      "Location Name": "BRISTOL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 WEST ST BRISTOL VT 05443 USA "
    },
    {
      "Location ID": "05444",
      "Location Name": "CAMBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 MANSFIELD AVE CAMBRIDGE VT 05444 USA "
    },
    {
      "Location ID": "05445",
      "Location Name": "CHARLOTTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 161 FERRY RD CHARLOTTE VT 05445 USA "
    },
    {
      "Location ID": "05446",
      "Location Name": "COLCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 218 MALLETTS BAY AVE COLCHESTER VT 05446 USA "
    },
    {
      "Location ID": "05448",
      "Location Name": "EAST FAIRFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9516 ROUTE 36 EAST FAIRFIELD VT 05448 USA "
    },
    {
      "Location ID": "05452",
      "Location Name": "ESSEX JUNCTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22 ESSEX WAY ESSEX JUNCTION VT 05452 USA "
    },
    {
      "Location ID": "05453",
      "Location Name": "PEARL STREET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 78 PEARL ST ESSEX JUNCTION VT 05453 USA "
    },
    {
      "Location ID": "05454",
      "Location Name": "FAIRFAX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 997 MAIN ST FAIRFAX VT 05454 USA "
    },
    {
      "Location ID": "05455",
      "Location Name": "FAIRFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4696 VT ROUTE 36 FAIRFIELD VT 05455 USA "
    },
    {
      "Location ID": "05456",
      "Location Name": "FERRISBURGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3153 ROUTE 7 FERRISBURGH VT 05456 USA "
    },
    {
      "Location ID": "05457",
      "Location Name": "FRANKLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5142 HANNA RD FRANKLIN VT 05457 USA "
    },
    {
      "Location ID": "05458",
      "Location Name": "GRAND ISLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 203 US ROUTE 2 GRAND ISLE VT 05458 USA "
    },
    {
      "Location ID": "05459",
      "Location Name": "HIGHGATE CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 38 ST ARMAND RD HIGHGATE CENTER VT 05459 USA "
    },
    {
      "Location ID": "05460",
      "Location Name": "HIGHGATE SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2934 VT ROUTE 7 HIGHGATE SPRINGS VT 05460 USA "
    },
    {
      "Location ID": "05461",
      "Location Name": "HINESBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 234 COMMERCE ST HINESBURG VT 05461 USA "
    },
    {
      "Location ID": "05462",
      "Location Name": "HUNTINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2209 MAIN RD HUNTINGTON VT 05462 USA "
    },
    {
      "Location ID": "05463",
      "Location Name": "ISLE LA MOTTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 46 SCHOOL ST ISLE LA MOTTE VT 05463 USA "
    },
    {
      "Location ID": "05464",
      "Location Name": "JEFFERSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 85 CHURCH ST JEFFERSONVILLE VT 05464 USA "
    },
    {
      "Location ID": "05465",
      "Location Name": "JERICHO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 87 VT ROUTE 15 JERICHO VT 05465 USA "
    },
    {
      "Location ID": "05466",
      "Location Name": "JONESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3459 E MAIN ST JONESVILLE VT 05466 USA "
    },
    {
      "Location ID": "05468",
      "Location Name": "MILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 ROUTE 7 S MILTON VT 05468 USA "
    },
    {
      "Location ID": "05469",
      "Location Name": "MONKTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3747 STATES PRISON HOLLOW RD SIDE MONKTON VT 05469 USA "
    },
    {
      "Location ID": "05472",
      "Location Name": "NEW HAVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 73 MAIN ST NEW HAVEN VT 05472 USA "
    },
    {
      "Location ID": "05473",
      "Location Name": "NORTH FERRISBURGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6935 ROUTE 7 NORTH FERRISBURGH VT 05473 USA "
    },
    {
      "Location ID": "05474",
      "Location Name": "NORTH HERO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3537 US ROUTE 2 STE A NORTH HERO VT 05474 USA "
    },
    {
      "Location ID": "05477",
      "Location Name": "RICHMOND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 BRIDGE ST RICHMOND VT 05477 USA "
    },
    {
      "Location ID": "05478",
      "Location Name": "SAINT ALBANS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 153 N MAIN ST SAINT ALBANS VT 05478 USA "
    },
    {
      "Location ID": "05481",
      "Location Name": "SAINT ALBANS BAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 571 LAKE RD SAINT ALBANS BAY VT 05481 USA "
    },
    {
      "Location ID": "05482",
      "Location Name": "SHELBURNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 495 FALLS RD SHELBURNE VT 05482 USA "
    },
    {
      "Location ID": "05485",
      "Location Name": "SHELDON SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 614 MILL ST SHELDON SPRINGS VT 05485 USA "
    },
    {
      "Location ID": "05486",
      "Location Name": "SOUTH HERO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 291 ROUTE 2 SOUTH HERO VT 05486 USA "
    },
    {
      "Location ID": "05487",
      "Location Name": "STARKSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3054 VT ROUTE 116 STARKSBORO VT 05487 USA "
    },
    {
      "Location ID": "05488",
      "Location Name": "SWANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 GRAND AVE SWANTON VT 05488 USA "
    },
    {
      "Location ID": "05489",
      "Location Name": "UNDERHILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 HARVEST RUN UNDERHILL VT 05489 USA "
    },
    {
      "Location ID": "05490",
      "Location Name": "UNDERHILL CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 286 RIVER RD UNDERHILL CENTER VT 05490 USA "
    },
    {
      "Location ID": "05491",
      "Location Name": "VERGENNES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 N GREEN ST VERGENNES VT 05491 USA "
    },
    {
      "Location ID": "05492",
      "Location Name": "WATERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1211 VT ROUTE 109 FRNT WATERVILLE VT 05492 USA "
    },
    {
      "Location ID": "05494",
      "Location Name": "WESTFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 POST RD WESTFORD VT 05494 USA "
    },
    {
      "Location ID": "05495",
      "Location Name": "WILLISTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 82 BLAIR PARK RD WILLISTON VT 05495 USA "
    },
    {
      "Location ID": "054AA",
      "Location Name": "PINE STREET DMU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 320 PINE ST BURLINGTON VT 05401 USA "
    },
    {
      "Location ID": "054AI",
      "Location Name": "AIDC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 82 WINTER SPORT LANE WILLISTON VT 05495 USA "
    },
    {
      "Location ID": "054AM",
      "Location Name": "AMERICAN MEADOWS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 331B BRYCE BOULEVARD STE 1 FAIRFAX VT 05454 USA "
    },
    {
      "Location ID": "054CH",
      "Location Name": "COUNTRY HOME PRODUCTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 75 MEIGS ROAD VERGENNES VT 05491 USA "
    },
    {
      "Location ID": "054DW",
      "Location Name": "DANISH WOOLEN DELIGHTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1325 VT ROUTE 128 WESTFORD VT 05494 USA "
    },
    {
      "Location ID": "054GA",
      "Location Name": "CPU GEORGIA MARKET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 962 ETHAN ALLEN HWY FAIRFAX VT 05454 USA "
    },
    {
      "Location ID": "054GM",
      "Location Name": "GREEN MOUNTAIN CAMERA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 450 WEAVER STREET STE 12 WINOOSKI VT 05404 USA "
    },
    {
      "Location ID": "054IF",
      "Location Name": "VERMONT CHRISTMAS COMPANY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 CLAPPER ROAD MILTON VT 05468 USA "
    },
    {
      "Location ID": "054IN",
      "Location Name": "INS (WILLISTON)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 124 LEROY ROAD WILLISTON VT 05495 USA "
    },
    {
      "Location ID": "054LP",
      "Location Name": "BURLINGTON (LANE PRESS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 87 MEADOWLAND DR SOUTH BURLINGTON VT 05403 USA "
    },
    {
      "Location ID": "054MM",
      "Location Name": "MOUNT MANSFIELD MAPLE PRODUCTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 450 WEAVER STREET STE 18 WINOOSKI VT 05404 USA "
    },
    {
      "Location ID": "054NR",
      "Location Name": "VT NUT FREE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 146 BRENTWOOD DRIVE COLCHESTER VT 05446 USA "
    },
    {
      "Location ID": "054OH",
      "Location Name": "OFFSET HOUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 89 SAND HILL ROAD ESSEX VT 05451 USA "
    },
    {
      "Location ID": "054RE",
      "Location Name": "RESOLUTIONS (WILLISTON)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 687 MARSHALL AVENUE WILLISTON VT 05495 USA "
    },
    {
      "Location ID": "054RI",
      "Location Name": "REI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 244 RETAIL WAY WILLISTON VT 05495 USA "
    },
    {
      "Location ID": "054RN",
      "Location Name": "ROVERS NORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1319 VERMONT ROUTE 128 WESTFORD VT 05494 USA "
    },
    {
      "Location ID": "054SD",
      "Location Name": "SUBATOMIC DIGITAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 151 BLAIR PARK ROAD WILLISTON VT 05495 USA "
    },
    {
      "Location ID": "054SE",
      "Location Name": "SWEET ENERGY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 195 ACORN LANE COLCHESTER VT 05446 USA "
    },
    {
      "Location ID": "054TB",
      "Location Name": "VT TEDDY BEAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6655 SHELBURNE ROAD SHELBURNE VT 05482 USA "
    },
    {
      "Location ID": "054US",
      "Location Name": "VILLANTI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 CATAMOUNT DR MILTON VT 05468 USA "
    },
    {
      "Location ID": "054UV",
      "Location Name": "UVM MAIL ROOM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 223 AVENUE D WILLISTON VT 05495 USA "
    },
    {
      "Location ID": "054VI",
      "Location Name": "VILLANTI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 CATAMOUNT DR MILTON VT 05468 USA "
    },
    {
      "Location ID": "05602",
      "Location Name": "MONTPELIER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 87 STATE ST MONTPELIER VT 05602 USA "
    },
    {
      "Location ID": "05641",
      "Location Name": "BARRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 S MAIN ST BARRE VT 05641 USA "
    },
    {
      "Location ID": "05647",
      "Location Name": "CABOT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3123 MAIN ST CABOT VT 05647 USA "
    },
    {
      "Location ID": "05649",
      "Location Name": "EAST BARRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 491 WEBSTERVILLE RD EAST BARRE VT 05649 USA "
    },
    {
      "Location ID": "05651",
      "Location Name": "EAST MONTPELIER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2893 US ROUTE 2 EAST MONTPELIER VT 05651 USA "
    },
    {
      "Location ID": "05652",
      "Location Name": "EDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 NANCY LN EDEN VT 05652 USA "
    },
    {
      "Location ID": "05654",
      "Location Name": "GRANITEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 623 GRANITEVILLE RD GRANITEVILLE VT 05654 USA "
    },
    {
      "Location ID": "05655",
      "Location Name": "HYDE PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 214 CHURCH ST HYDE PARK VT 05655 USA "
    },
    {
      "Location ID": "05656",
      "Location Name": "JOHNSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 LOWER MAIN ST W JOHNSON VT 05656 USA "
    },
    {
      "Location ID": "05657",
      "Location Name": "LAKE ELMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1208 VT ROUTE 12 LAKE ELMORE VT 05657 USA "
    },
    {
      "Location ID": "05658",
      "Location Name": "MARSHFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1401 US ROUTE 2 MARSHFIELD VT 05658 USA "
    },
    {
      "Location ID": "05660",
      "Location Name": "MORETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1115 ROUTE 100 B MORETOWN VT 05660 USA "
    },
    {
      "Location ID": "05661",
      "Location Name": "MORRISVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 PORTLAND ST MORRISVILLE VT 05661 USA "
    },
    {
      "Location ID": "05663",
      "Location Name": "NORTHFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 S MAIN ST STE 1 NORTHFIELD VT 05663 USA "
    },
    {
      "Location ID": "05664",
      "Location Name": "NORTHFIELD FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 51 GOULD RD NORTHFIELD FALLS VT 05664 USA "
    },
    {
      "Location ID": "05665",
      "Location Name": "NORTH HYDE PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5541 VT RTE 100 NORTH HYDE PARK VT 05665 USA "
    },
    {
      "Location ID": "05669",
      "Location Name": "ROXBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1593 ROXBURY RD ROXBURY VT 05669 USA "
    },
    {
      "Location ID": "05670",
      "Location Name": "SOUTH BARRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 379 S BARRE RD SOUTH BARRE VT 05670 USA "
    },
    {
      "Location ID": "05672",
      "Location Name": "STOWE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 DEPOT ST STOWE VT 05672 USA "
    },
    {
      "Location ID": "05673",
      "Location Name": "WAITSFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 MAD RIVER GRN WAITSFIELD VT 05673 USA "
    },
    {
      "Location ID": "05674",
      "Location Name": "WARREN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 354 MAIN ST WARREN VT 05674 USA "
    },
    {
      "Location ID": "05675",
      "Location Name": "WASHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3029 VT ROUTE 110 WASHINGTON VT 05675 USA "
    },
    {
      "Location ID": "05676",
      "Location Name": "WATERBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 47 N MAIN ST STE 2 WATERBURY VT 05676 USA "
    },
    {
      "Location ID": "05677",
      "Location Name": "WATERBURY CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1787 GUPTIL RD WATERBURY CENTER VT 05677 USA "
    },
    {
      "Location ID": "05678",
      "Location Name": "WEBSTERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37 CHURCH HILL RD WEBSTERVILLE VT 05678 USA "
    },
    {
      "Location ID": "05679",
      "Location Name": "WILLIAMSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2632 VT ROUTE 14 WILLIAMSTOWN VT 05679 USA "
    },
    {
      "Location ID": "05680",
      "Location Name": "WOLCOTT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4154 VT RTE 15 WOLCOTT VT 05680 USA "
    },
    {
      "Location ID": "056CT",
      "Location Name": "CONCEPT 2",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 INDUSTRIAL PARK DRIVE MORRISVILLE VT 05661 USA "
    },
    {
      "Location ID": "056DT",
      "Location Name": "DARN TOUGH VERMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 PILGRIM PARK ROAD WATERBURY VT 05676 USA "
    },
    {
      "Location ID": "056HM",
      "Location Name": "HIGH MOWING ORGANIC SEEDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 76 QUARRY ROAD WOLCOTT VT 05680 USA "
    },
    {
      "Location ID": "056TF",
      "Location Name": "TURTLE FUR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 146 INDUSTRIAL PARK DRIVE MORRISVILLE VT 05661 USA "
    },
    {
      "Location ID": "056VS",
      "Location Name": "VERMONT STATE OFFICE COMPLEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1078 US ROUTE 2 MONTPELIER VT 05602 USA "
    },
    {
      "Location ID": "05701",
      "Location Name": "RUTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 173 WEST ST RUTLAND VT 05701 USA "
    },
    {
      "Location ID": "05730",
      "Location Name": "BELMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2400 BELMONT RD BELMONT VT 05730 USA "
    },
    {
      "Location ID": "05732",
      "Location Name": "BOMOSEEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 63 ROUTE 4A W BOMOSEEN VT 05732 USA "
    },
    {
      "Location ID": "05733",
      "Location Name": "BRANDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 32 CONANT SQ BRANDON VT 05733 USA "
    },
    {
      "Location ID": "05734",
      "Location Name": "BRIDPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3039 VT ROUTE 22A BRIDPORT VT 05734 USA "
    },
    {
      "Location ID": "05735",
      "Location Name": "CASTLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 576 MAIN ST CASTLETON VT 05735 USA "
    },
    {
      "Location ID": "05737",
      "Location Name": "CHITTENDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 198 CHITTENDEN RD CHITTENDEN VT 05737 USA "
    },
    {
      "Location ID": "05738",
      "Location Name": "CUTTINGSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5446 VT ROUTE 103 CUTTINGSVILLE VT 05738 USA "
    },
    {
      "Location ID": "05739",
      "Location Name": "DANBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 214 N MAIN ST DANBY VT 05739 USA "
    },
    {
      "Location ID": "05740",
      "Location Name": "EAST MIDDLEBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 CASE ST EAST MIDDLEBURY VT 05740 USA "
    },
    {
      "Location ID": "05742",
      "Location Name": "EAST WALLINGFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 VILLAGE ST EAST WALLINGFORD VT 05742 USA "
    },
    {
      "Location ID": "05743",
      "Location Name": "FAIR HAVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 MARBLE ST FAIR HAVEN VT 05743 USA "
    },
    {
      "Location ID": "05744",
      "Location Name": "FLORENCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1543 W CREEK RD FLORENCE VT 05744 USA "
    },
    {
      "Location ID": "05745",
      "Location Name": "FOREST DALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 233 NORTH ST FOREST DALE VT 05745 USA "
    },
    {
      "Location ID": "05746",
      "Location Name": "GAYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 BRIDGE ST GAYSVILLE VT 05746 USA "
    },
    {
      "Location ID": "05747",
      "Location Name": "GRANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4801 VT ROUTE 100 GRANVILLE VT 05747 USA "
    },
    {
      "Location ID": "05748",
      "Location Name": "HANCOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 882 VT ROUTE 100 HANCOCK VT 05748 USA "
    },
    {
      "Location ID": "05750",
      "Location Name": "HYDEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 912 ROUTE 4A W HYDEVILLE VT 05750 USA "
    },
    {
      "Location ID": "05751",
      "Location Name": "KILLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2046 ROUTE 4 KILLINGTON VT 05751 USA "
    },
    {
      "Location ID": "05753",
      "Location Name": "MIDDLEBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 MAIN ST MIDDLEBURY VT 05753 USA "
    },
    {
      "Location ID": "05757",
      "Location Name": "MIDDLETOWN SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 SOUTH ST MIDDLETOWN SPRINGS VT 05757 USA "
    },
    {
      "Location ID": "05758",
      "Location Name": "MOUNT HOLLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 53 BELMONT RD MOUNT HOLLY VT 05758 USA "
    },
    {
      "Location ID": "05759",
      "Location Name": "NORTH CLARENDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 47 MOULTON AVE NORTH CLARENDON VT 05759 USA "
    },
    {
      "Location ID": "05760",
      "Location Name": "ORWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 538 MAIN ST ORWELL VT 05760 USA "
    },
    {
      "Location ID": "05761",
      "Location Name": "PAWLET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5707 VT ROUTE 30 PAWLET VT 05761 USA "
    },
    {
      "Location ID": "05762",
      "Location Name": "PITTSFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3934 ROUTE 100 PITTSFIELD VT 05762 USA "
    },
    {
      "Location ID": "05763",
      "Location Name": "PITTSFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 853 ARCH ST PITTSFORD VT 05763 USA "
    },
    {
      "Location ID": "05764",
      "Location Name": "POULTNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 MAIN ST POULTNEY VT 05764 USA "
    },
    {
      "Location ID": "05765",
      "Location Name": "PROCTOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 MAIN ST STE 2 PROCTOR VT 05765 USA "
    },
    {
      "Location ID": "05767",
      "Location Name": "ROCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 46 PARK ST ROCHESTER VT 05767 USA "
    },
    {
      "Location ID": "05768",
      "Location Name": "RUPERT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 84 MAIN ST RUPERT VT 05768 USA "
    },
    {
      "Location ID": "05769",
      "Location Name": "SALISBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 935 MAPLE ST SALISBURY VT 05769 USA "
    },
    {
      "Location ID": "05770",
      "Location Name": "SHOREHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 75 MAIN ST SHOREHAM VT 05770 USA "
    },
    {
      "Location ID": "05772",
      "Location Name": "STOCKBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1720 VT ROUTE 100 STOCKBRIDGE VT 05772 USA "
    },
    {
      "Location ID": "05773",
      "Location Name": "WALLINGFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 137 N MAIN ST UNIT A WALLINGFORD VT 05773 USA "
    },
    {
      "Location ID": "05774",
      "Location Name": "WELLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 NORTH ST UNIT B WELLS VT 05774 USA "
    },
    {
      "Location ID": "05775",
      "Location Name": "WEST PAWLET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2841 VT ROUTE 153 WEST PAWLET VT 05775 USA "
    },
    {
      "Location ID": "05776",
      "Location Name": "WEST RUPERT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1151 STATE HWY 153 WEST RUPERT VT 05776 USA "
    },
    {
      "Location ID": "05777",
      "Location Name": "WEST RUTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 66 WESTWAY MALL DR WEST RUTLAND VT 05777 USA "
    },
    {
      "Location ID": "05778",
      "Location Name": "WHITING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 S MAIN ST WHITING VT 05778 USA "
    },
    {
      "Location ID": "057AB",
      "Location Name": "RUTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 173 WEST ST RUTLAND VT 05701 USA "
    },
    {
      "Location ID": "057CA",
      "Location Name": "MIDDLEBURY CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 658 EXCHANGE ST STE 2 MIDDLEBURY VT 05753 USA "
    },
    {
      "Location ID": "05819",
      "Location Name": "SAINT JOHNSBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1153 MAIN ST RM 100 SAINT JOHNSBURY VT 05819 USA "
    },
    {
      "Location ID": "05822",
      "Location Name": "BARTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 405 MAIN ST BARTON VT 05822 USA "
    },
    {
      "Location ID": "05825",
      "Location Name": "COVENTRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 177 MAIN ST COVENTRY VT 05825 USA "
    },
    {
      "Location ID": "05826",
      "Location Name": "CRAFTSBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 S CRAFTSBURY RD STE 1 CRAFTSBURY VT 05826 USA "
    },
    {
      "Location ID": "05827",
      "Location Name": "CRAFTSBURY COMMON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1354 N CRAFTSBURY RD CRAFTSBURY COMMON VT 05827 USA "
    },
    {
      "Location ID": "05828",
      "Location Name": "DANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 PARK ST DANVILLE VT 05828 USA "
    },
    {
      "Location ID": "05829",
      "Location Name": "DERBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3275 US ROUTE 5 DERBY VT 05829 USA "
    },
    {
      "Location ID": "05830",
      "Location Name": "DERBY LINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 84 MAIN ST DERBY LINE VT 05830 USA "
    },
    {
      "Location ID": "05832",
      "Location Name": "EAST BURKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 552 VT ROUTE 114 EAST BURKE VT 05832 USA "
    },
    {
      "Location ID": "05836",
      "Location Name": "EAST HARDWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 45 MAIN ST EAST HARDWICK VT 05836 USA "
    },
    {
      "Location ID": "05837",
      "Location Name": "EAST HAVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 472 SCHOOL ST EAST HAVEN VT 05837 USA "
    },
    {
      "Location ID": "05841",
      "Location Name": "GREENSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 CEMETERY RDG GREENSBORO VT 05841 USA "
    },
    {
      "Location ID": "05842",
      "Location Name": "GREENSBORO BEND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 975 MAIN ST GREENSBORO BEND VT 05842 USA "
    },
    {
      "Location ID": "05843",
      "Location Name": "HARDWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 52 MILL ST HARDWICK VT 05843 USA "
    },
    {
      "Location ID": "05846",
      "Location Name": "ISLAND POND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 32 MAIN ST ISLAND POND VT 05846 USA "
    },
    {
      "Location ID": "05847",
      "Location Name": "LOWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 296 HAZEN NOTCH ROAD LOWELL VT 05847 USA "
    },
    {
      "Location ID": "05851",
      "Location Name": "LYNDONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1025 BROAD ST LYNDONVILLE VT 05851 USA "
    },
    {
      "Location ID": "05855",
      "Location Name": "NEWPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 59 COVENTRY ST NEWPORT VT 05855 USA "
    },
    {
      "Location ID": "05857",
      "Location Name": "NEWPORT CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4168 VT ROUTE 105 NEWPORT CENTER VT 05857 USA "
    },
    {
      "Location ID": "05859",
      "Location Name": "NORTH TROY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 66 SOUTH STREET NORTH TROY VT 05859 USA "
    },
    {
      "Location ID": "05860",
      "Location Name": "ORLEANS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 WATER ST ORLEANS VT 05860 USA "
    },
    {
      "Location ID": "05868",
      "Location Name": "TROY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6694 VT RTE 100 TROY VT 05868 USA "
    },
    {
      "Location ID": "05871",
      "Location Name": "WEST BURKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 POST OFFICE RD WEST BURKE VT 05871 USA "
    },
    {
      "Location ID": "05873",
      "Location Name": "WEST DANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2748 US ROUTE 2 W WEST DANVILLE VT 05873 USA "
    },
    {
      "Location ID": "05874",
      "Location Name": "WESTFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 32 NORTH HILL ROAD UNIT 2 WESTFIELD VT 05874 USA "
    },
    {
      "Location ID": "05902",
      "Location Name": "BEECHER FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1110 VERMONT ROUTE 253 BEECHER FALLS VT 05902 USA "
    },
    {
      "Location ID": "05903",
      "Location Name": "CANAAN VT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 350 CHRISTIAN HL CANAAN VT 05903 USA "
    },
    {
      "Location ID": "05904",
      "Location Name": "GILMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 PARRISH ST GILMAN VT 05904 USA "
    },
    {
      "Location ID": "05905",
      "Location Name": "GUILDHALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7031 ROUTE 102 GUILDHALL VT 05905 USA "
    },
    {
      "Location ID": "05906",
      "Location Name": "LUNENBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 S LUNENBURG RD LUNENBURG VT 05906 USA "
    },
    {
      "Location ID": "060",
      "Location Name": "HARTFORD P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 141 WESTON ST HARTFORD CT 06101 USA "
    },
    {
      "Location ID": "06001",
      "Location Name": "AVON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 ENFORD ST AVON CT 06001 USA "
    },
    {
      "Location ID": "06002",
      "Location Name": "BLOOMFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 JEROME AVE BLOOMFIELD CT 06002 USA "
    },
    {
      "Location ID": "06010",
      "Location Name": "BRISTOL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 151 N MAIN ST BRISTOL CT 06010 USA "
    },
    {
      "Location ID": "06018",
      "Location Name": "CANAAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 CHURCH ST CANAAN CT 06018 USA "
    },
    {
      "Location ID": "06019",
      "Location Name": "CANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 ALBANY TPKE CANTON CT 06019 USA "
    },
    {
      "Location ID": "06020",
      "Location Name": "CANTON CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 178 CHERRY BROOK RD CANTON CENTER CT 06020 USA "
    },
    {
      "Location ID": "06022",
      "Location Name": "COLLINSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 BRIDGE ST COLLINSVILLE CT 06022 USA "
    },
    {
      "Location ID": "06023",
      "Location Name": "EAST BERLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 MAIN ST EAST BERLIN CT 06023 USA "
    },
    {
      "Location ID": "06024",
      "Location Name": "EAST CANAAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 270 E CANAAN RD EAST CANAAN CT 06024 USA "
    },
    {
      "Location ID": "06026",
      "Location Name": "EAST GRANBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 CENTER ST EAST GRANBY CT 06026 USA "
    },
    {
      "Location ID": "06027",
      "Location Name": "EAST HARTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23 SOUTH RD EAST HARTLAND CT 06027 USA "
    },
    {
      "Location ID": "06031",
      "Location Name": "FALLS VILLAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 MINER ST FALLS VILLAGE CT 06031 USA "
    },
    {
      "Location ID": "06032",
      "Location Name": "FARMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 MAIN ST FARMINGTON CT 06032 USA "
    },
    {
      "Location ID": "06035",
      "Location Name": "GRANBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 BANK ST GRANBY CT 06035 USA "
    },
    {
      "Location ID": "06037",
      "Location Name": "KENSINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 62 KENSINGTON RD BERLIN CT 06037 USA "
    },
    {
      "Location ID": "06050",
      "Location Name": "NEW BRITAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 135 CHESTNUT ST NEW BRITAIN CT 06050 USA "
    },
    {
      "Location ID": "06059",
      "Location Name": "NORTH CANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 531 CHERRY BROOK RD NORTH CANTON CT 06059 USA "
    },
    {
      "Location ID": "06060",
      "Location Name": "NORTH GRANBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 389 N GRANBY RD NORTH GRANBY CT 06060 USA "
    },
    {
      "Location ID": "06062",
      "Location Name": "PLAINVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 56 WHITING ST PLAINVILLE CT 06062 USA "
    },
    {
      "Location ID": "06067",
      "Location Name": "ROCKY HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 32 CHURCH ST ROCKY HILL CT 06067 USA "
    },
    {
      "Location ID": "06070",
      "Location Name": "SIMSBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 932 HOPMEADOW ST SIMSBURY CT 06070 USA "
    },
    {
      "Location ID": "06078",
      "Location Name": "SUFFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 235 MOUNTAIN RD SUFFIELD CT 06078 USA "
    },
    {
      "Location ID": "06079",
      "Location Name": "TACONIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 557 TWIN LAKES RD TACONIC CT 06079 USA "
    },
    {
      "Location ID": "06082",
      "Location Name": "ENFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31 PALOMBA DR ENFIELD CT 06082 USA "
    },
    {
      "Location ID": "06084",
      "Location Name": "TOLLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 658 TOLLAND STAGE RD TOLLAND CT 06084 USA "
    },
    {
      "Location ID": "06085",
      "Location Name": "UNIONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 MILL ST UNIONVILLE CT 06085 USA "
    },
    {
      "Location ID": "06088",
      "Location Name": "EAST WINDSOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 78 BRIDGE ST EAST WINDSOR CT 06088 USA "
    },
    {
      "Location ID": "06090",
      "Location Name": "WEST GRANBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 W GRANBY RD WEST GRANBY CT 06090 USA "
    },
    {
      "Location ID": "06092",
      "Location Name": "WEST SIMSBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 239 FARMS VILLAGE RD WEST SIMSBURY CT 06092 USA "
    },
    {
      "Location ID": "06093",
      "Location Name": "WEST SUFFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1361 MOUNTAIN RD WEST SUFFIELD CT 06093 USA "
    },
    {
      "Location ID": "06095",
      "Location Name": "WINDSOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 245 BROAD ST WINDSOR CT 06095 USA "
    },
    {
      "Location ID": "06096",
      "Location Name": "WINDSOR LOCKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 MAIN ST WINDSOR LOCKS CT 06096 USA "
    },
    {
      "Location ID": "06098",
      "Location Name": "WINSTED",
      "Time Zone Offset (Hours)": 0,
      "Address": " 328 MAIN ST WINSTED CT 06098 USA "
    },
    {
      "Location ID": "060BA",
      "Location Name": "LOG BRADLEY ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 SCHOEPHOESTER RD WINDSOR LOCKS CT 06096 USA "
    },
    {
      "Location ID": "060DH",
      "Location Name": "DESIGNS FOR HEALTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 980 SOUTH ST STE 100 SUFFIELD CT 06078 USA "
    },
    {
      "Location ID": "060FS",
      "Location Name": "060FS CT FEDEX SMARTPOST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 759 RAINBOW RD WINDSOR CT 06095 USA "
    },
    {
      "Location ID": "060WC",
      "Location Name": "WILDLIFE CONTROL SUPPLIES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1160 SOUTH ST SUFFIELD CT 06078 USA "
    },
    {
      "Location ID": "061AX",
      "Location Name": "HARTFORD PEAK ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 DAY HILL RD WINDSOR CT 06095 USA "
    },
    {
      "Location ID": "062",
      "Location Name": "WILLIMANTIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 919 MAIN ST WILLIMANTIC CT 06226 USA "
    },
    {
      "Location ID": "06231",
      "Location Name": "AMSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 540 CHURCH ST AMSTON CT 06231 USA "
    },
    {
      "Location ID": "06232",
      "Location Name": "ANDOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 335 ROUTE 6 ANDOVER CT 06232 USA "
    },
    {
      "Location ID": "06233",
      "Location Name": "BALLOUVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 252 BALLOUVILLE RD BALLOUVILLE CT 06233 USA "
    },
    {
      "Location ID": "06234",
      "Location Name": "BROOKLYN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 CANTERBURY RD BROOKLYN CT 06234 USA "
    },
    {
      "Location ID": "06235",
      "Location Name": "CHAPLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 181 WILLIMANTIC RD CHAPLIN CT 06235 USA "
    },
    {
      "Location ID": "06237",
      "Location Name": "COLUMBIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 172 ROUTE 66 COLUMBIA CT 06237 USA "
    },
    {
      "Location ID": "06238",
      "Location Name": "COVENTRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1376 MAIN ST COVENTRY CT 06238 USA "
    },
    {
      "Location ID": "06239",
      "Location Name": "DANIELSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 70 WATER ST DANIELSON CT 06239 USA "
    },
    {
      "Location ID": "06241",
      "Location Name": "DAYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 988 N MAIN ST DAYVILLE CT 06241 USA "
    },
    {
      "Location ID": "06242",
      "Location Name": "EASTFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 COUNTY RD EASTFORD CT 06242 USA "
    },
    {
      "Location ID": "06243",
      "Location Name": "EAST KILLINGLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1289 HARTFORD PIKE EAST KILLINGLY CT 06243 USA "
    },
    {
      "Location ID": "06244",
      "Location Name": "EAST WOODSTOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 217 WOODSTOCK RD EAST WOODSTOCK CT 06244 USA "
    },
    {
      "Location ID": "06247",
      "Location Name": "HAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 222 MAIN ST HAMPTON CT 06247 USA "
    },
    {
      "Location ID": "06248",
      "Location Name": "HEBRON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 93 MAIN ST HEBRON CT 06248 USA "
    },
    {
      "Location ID": "06249",
      "Location Name": "LEBANON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 562 EXETER RD LEBANON CT 06249 USA "
    },
    {
      "Location ID": "06250",
      "Location Name": "MANSFIELD CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 522 STORRS RD MANSFIELD CENTER CT 06250 USA "
    },
    {
      "Location ID": "06254",
      "Location Name": "NORTH FRANKLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 683 ROUTE 32 NORTH FRANKLIN CT 06254 USA "
    },
    {
      "Location ID": "06255",
      "Location Name": "NORTH GROSVENORDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 831 RIVERSIDE DR NORTH GROSVENORDALE CT 06255 USA "
    },
    {
      "Location ID": "06256",
      "Location Name": "NORTH WINDHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 690 BOSTON POST RD NORTH WINDHAM CT 06256 USA "
    },
    {
      "Location ID": "06258",
      "Location Name": "POMFRET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 32 PUTNAM RD POMFRET CT 06258 USA "
    },
    {
      "Location ID": "06259",
      "Location Name": "POMFRET CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 144 MURDOCK RD POMFRET CENTER CT 06259 USA "
    },
    {
      "Location ID": "06260",
      "Location Name": "PUTNAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 189 MAIN ST PUTNAM CT 06260 USA "
    },
    {
      "Location ID": "06262",
      "Location Name": "QUINEBAUG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 734 QUINEBAUG RD QUINEBAUG CT 06262 USA "
    },
    {
      "Location ID": "06263",
      "Location Name": "ROGERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29A WILLIAMSVILLE RD ROGERS CT 06263 USA "
    },
    {
      "Location ID": "06264",
      "Location Name": "SCOTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 PALMER RD SCOTLAND CT 06264 USA "
    },
    {
      "Location ID": "06266",
      "Location Name": "SOUTH WINDHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 MAIN ST SOUTH WINDHAM CT 06266 USA "
    },
    {
      "Location ID": "06268",
      "Location Name": "STORRS MANSFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 CHARLES SMITH WAY STORRS MANSFIELD CT 06268 USA "
    },
    {
      "Location ID": "06277",
      "Location Name": "THOMPSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 366 THOMPSON HILL RD THOMPSON CT 06277 USA "
    },
    {
      "Location ID": "06278",
      "Location Name": "ASHFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 141 NOTT HWY STE 5 ASHFORD CT 06278 USA "
    },
    {
      "Location ID": "06279",
      "Location Name": "WILLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 PHELPS WAY WILLINGTON CT 06279 USA "
    },
    {
      "Location ID": "06280",
      "Location Name": "WINDHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 SCOTLAND RD WINDHAM CT 06280 USA "
    },
    {
      "Location ID": "06281",
      "Location Name": "WOODSTOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 679 ROUTE 169 WOODSTOCK CT 06281 USA "
    },
    {
      "Location ID": "063",
      "Location Name": "NEW LONDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 MASONIC ST STE 1 NEW LONDON CT 06320 USA "
    },
    {
      "Location ID": "06330",
      "Location Name": "BALTIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 MAIN ST BALTIC CT 06330 USA "
    },
    {
      "Location ID": "06331",
      "Location Name": "CANTERBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 168 WESTMINSTER RD CANTERBURY CT 06331 USA "
    },
    {
      "Location ID": "06332",
      "Location Name": "CENTRAL VILLAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 SCHOOL ST CENTRAL VILLAGE CT 06332 USA "
    },
    {
      "Location ID": "06333",
      "Location Name": "EAST LYME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 225 BOSTON POST RD EAST LYME CT 06333 USA "
    },
    {
      "Location ID": "06335",
      "Location Name": "GALES FERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 MILITARY HWY GALES FERRY CT 06335 USA "
    },
    {
      "Location ID": "06339",
      "Location Name": "LEDYARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 764 COLONEL LEDYARD HWY LEDYARD CT 06339 USA "
    },
    {
      "Location ID": "06340",
      "Location Name": "GROTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 PLAZA CT GROTON CT 06340 USA "
    },
    {
      "Location ID": "06349",
      "Location Name": "GROTON NAVAL BASE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 NAVAL SUBMARINE BASE GROTON CT 06349 USA "
    },
    {
      "Location ID": "06350",
      "Location Name": "HANOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 290 MAIN ST HANOVER CT 06350 USA "
    },
    {
      "Location ID": "06351",
      "Location Name": "JEWETT CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 54 MAIN ST JEWETT CITY CT 06351 USA "
    },
    {
      "Location ID": "06353",
      "Location Name": "MONTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 293 ROUTE 163 MONTVILLE CT 06353 USA "
    },
    {
      "Location ID": "06354",
      "Location Name": "MOOSUP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 S MAIN ST MOOSUP CT 06354 USA "
    },
    {
      "Location ID": "06355",
      "Location Name": "MYSTIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23 E MAIN ST MYSTIC CT 06355 USA "
    },
    {
      "Location ID": "06357",
      "Location Name": "NIANTIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 58 PENNSYLVANIA AVE STE 2 NIANTIC CT 06357 USA "
    },
    {
      "Location ID": "06359",
      "Location Name": "NORTH STONINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 391 NORWICH WESTERLY RD NORTH STONINGTON CT 06359 USA "
    },
    {
      "Location ID": "06360",
      "Location Name": "NORWICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 340 MAIN ST NORWICH CT 06360 USA "
    },
    {
      "Location ID": "06370",
      "Location Name": "OAKDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 711 ROUTE 163 OAKDALE CT 06370 USA "
    },
    {
      "Location ID": "06371",
      "Location Name": "OLD LYME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 HALLS RD OLD LYME CT 06371 USA "
    },
    {
      "Location ID": "06373",
      "Location Name": "ONECO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1104 PLAINFIELD PIKE ONECO CT 06373 USA "
    },
    {
      "Location ID": "06374",
      "Location Name": "PLAINFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 42 RAILROAD AVE PLAINFIELD CT 06374 USA "
    },
    {
      "Location ID": "06375",
      "Location Name": "QUAKER HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 132 OLD NORWICH RD QUAKER HILL CT 06375 USA "
    },
    {
      "Location ID": "06376",
      "Location Name": "SOUTH LYME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 389 SHORE RD SOUTH LYME CT 06376 USA "
    },
    {
      "Location ID": "06377",
      "Location Name": "STERLING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 347 STERLING RD STERLING CT 06377 USA "
    },
    {
      "Location ID": "06378",
      "Location Name": "STONINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 BROAD ST STONINGTON CT 06378 USA "
    },
    {
      "Location ID": "06379",
      "Location Name": "PAWCATUCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 66 S BROAD ST PAWCATUCK CT 06379 USA "
    },
    {
      "Location ID": "06380",
      "Location Name": "TAFTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 72 S B ST TAFTVILLE CT 06380 USA "
    },
    {
      "Location ID": "06382",
      "Location Name": "UNCASVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1587 NORWICH NEW LONDON TPKE UNCASVILLE CT 06382 USA "
    },
    {
      "Location ID": "06383",
      "Location Name": "VERSAILLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 MAIN ST STE 2 VERSAILLES CT 06383 USA "
    },
    {
      "Location ID": "06384",
      "Location Name": "VOLUNTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 42 CHURCH ST VOLUNTOWN CT 06384 USA "
    },
    {
      "Location ID": "06385",
      "Location Name": "WATERFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 222 BOSTON POST RD WATERFORD CT 06385 USA "
    },
    {
      "Location ID": "06387",
      "Location Name": "WAUREGAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 WAUREGAN RD WAUREGAN CT 06387 USA "
    },
    {
      "Location ID": "06388",
      "Location Name": "WEST MYSTIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 W MYSTIC AVE WEST MYSTIC CT 06388 USA "
    },
    {
      "Location ID": "063AX",
      "Location Name": "ROSE CITY CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 292 SALEM TPKE NORWICH CT 06360 USA "
    },
    {
      "Location ID": "063MT",
      "Location Name": "CPU MASHANTUCKET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 MATTS PATH MASHANTUCKET CT 06338 USA "
    },
    {
      "Location ID": "064",
      "Location Name": "LOG SOUTHERN CT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 RESEARCH PKWY WALLINGFORD CT 06492 USA "
    },
    {
      "Location ID": "06401",
      "Location Name": "ANSONIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 237 MAIN ST ANSONIA CT 06401 USA "
    },
    {
      "Location ID": "06403",
      "Location Name": "BEACON FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 N MAIN ST BEACON FALLS CT 06403 USA "
    },
    {
      "Location ID": "06404",
      "Location Name": "BOTSFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 BOTSFORD HILL RD BOTSFORD CT 06404 USA "
    },
    {
      "Location ID": "06405",
      "Location Name": "BRANFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 PARK PL BRANFORD CT 06405 USA "
    },
    {
      "Location ID": "06409",
      "Location Name": "CENTERBROOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 WESTBROOK RD CENTERBROOK CT 06409 USA "
    },
    {
      "Location ID": "06410",
      "Location Name": "CHESHIRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 MAPLE AVE CHESHIRE CT 06410 USA "
    },
    {
      "Location ID": "06411",
      "Location Name": "BLOOMINGDALES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 475 KNOTTER DR CHESHIRE CT 06411 USA "
    },
    {
      "Location ID": "06412",
      "Location Name": "CHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 MAIN ST CHESTER CT 06412 USA "
    },
    {
      "Location ID": "06413",
      "Location Name": "CLINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 W MAIN ST CLINTON CT 06413 USA "
    },
    {
      "Location ID": "06414",
      "Location Name": "COBALT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 371 W HIGH ST COBALT CT 06414 USA "
    },
    {
      "Location ID": "06415",
      "Location Name": "COLCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 S MAIN ST STE 3 COLCHESTER CT 06415 USA "
    },
    {
      "Location ID": "06416",
      "Location Name": "CROMWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 528 MAIN ST CROMWELL CT 06416 USA "
    },
    {
      "Location ID": "06417",
      "Location Name": "DEEP RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 225 MAIN ST DEEP RIVER CT 06417 USA "
    },
    {
      "Location ID": "06418",
      "Location Name": "DERBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 74 OLIVIA ST DERBY CT 06418 USA "
    },
    {
      "Location ID": "06422",
      "Location Name": "DURHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 MAIN ST DURHAM CT 06422 USA "
    },
    {
      "Location ID": "06423",
      "Location Name": "EAST HADDAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 MAIN ST EAST HADDAM CT 06423 USA "
    },
    {
      "Location ID": "06424",
      "Location Name": "EAST HAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 57 MAIN ST EAST HAMPTON CT 06424 USA "
    },
    {
      "Location ID": "06426",
      "Location Name": "ESSEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 MAIN ST STE 5 ESSEX CT 06426 USA "
    },
    {
      "Location ID": "06438",
      "Location Name": "HADDAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1050 SAYBROOK RD HADDAM CT 06438 USA "
    },
    {
      "Location ID": "06439",
      "Location Name": "HADLYME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 FERRY RD HADLYME CT 06439 USA "
    },
    {
      "Location ID": "06440",
      "Location Name": "HAWLEYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23 BARNABAS RD STE 5 HAWLEYVILLE CT 06440 USA "
    },
    {
      "Location ID": "06441",
      "Location Name": "HIGGANUM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 KILLINGWORTH RD HIGGANUM CT 06441 USA "
    },
    {
      "Location ID": "06442",
      "Location Name": "IVORYTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 98 MAIN ST IVORYTON CT 06442 USA "
    },
    {
      "Location ID": "06447",
      "Location Name": "MARLBOROUGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 E HAMPTON RD MARLBOROUGH CT 06447 USA "
    },
    {
      "Location ID": "06450",
      "Location Name": "MERIDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 190 CENTER ST MERIDEN CT 06450 USA "
    },
    {
      "Location ID": "06455",
      "Location Name": "MIDDLEFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 456 MAIN ST MIDDLEFIELD CT 06455 USA "
    },
    {
      "Location ID": "06456",
      "Location Name": "MIDDLE HADDAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 77 MIDDLE HADDAM RD MIDDLE HADDAM CT 06456 USA "
    },
    {
      "Location ID": "06457",
      "Location Name": "MIDDLETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 SILVER ST MIDDLETOWN CT 06457 USA "
    },
    {
      "Location ID": "06460",
      "Location Name": "MILFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 PEPES FARM RD MILFORD CT 06460 USA "
    },
    {
      "Location ID": "06468",
      "Location Name": "MONROE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 270 MONROE TPKE MONROE CT 06468 USA "
    },
    {
      "Location ID": "06469",
      "Location Name": "MOODUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 FALLS RD MOODUS CT 06469 USA "
    },
    {
      "Location ID": "06470",
      "Location Name": "NEWTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 COMMERCE RD NEWTOWN CT 06470 USA "
    },
    {
      "Location ID": "06471",
      "Location Name": "NORTH BRANFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 280 BRANFORD RD NORTH BRANFORD CT 06471 USA "
    },
    {
      "Location ID": "06472",
      "Location Name": "NORTHFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1447 MIDDLETOWN AVE NORTHFORD CT 06472 USA "
    },
    {
      "Location ID": "06475",
      "Location Name": "OLD SAYBROOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 MAIN ST OLD SAYBROOK CT 06475 USA "
    },
    {
      "Location ID": "06479",
      "Location Name": "PLANTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 847 S MAIN ST PLANTSVILLE CT 06479 USA "
    },
    {
      "Location ID": "06480",
      "Location Name": "PORTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 320 MAIN ST PORTLAND CT 06480 USA "
    },
    {
      "Location ID": "06481",
      "Location Name": "ROCKFALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 MAIN ST ROCKFALL CT 06481 USA "
    },
    {
      "Location ID": "06483",
      "Location Name": "SEYMOUR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 91 MAIN ST SEYMOUR CT 06483 USA "
    },
    {
      "Location ID": "06484",
      "Location Name": "SHELTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 83 BRIDGE ST SHELTON CT 06484 USA "
    },
    {
      "Location ID": "06488",
      "Location Name": "SOUTHBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 710 MAIN ST S SOUTHBURY CT 06488 USA "
    },
    {
      "Location ID": "06489",
      "Location Name": "SOUTHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 MAIN ST SOUTHINGTON CT 06489 USA "
    },
    {
      "Location ID": "06491",
      "Location Name": "STEVENSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 230 ROOSEVELT DR STE 2 MONROE CT 06468 USA "
    },
    {
      "Location ID": "06492",
      "Location Name": "WALLINGFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 S MAIN ST WALLINGFORD CT 06492 USA "
    },
    {
      "Location ID": "06498",
      "Location Name": "WESTBROOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1411 BOSTON POST RD WESTBROOK CT 06498 USA "
    },
    {
      "Location ID": "064CX",
      "Location Name": "GUILFORD/MADISON CAR ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 SHORELINE DR GUILFORD CT 06437 USA "
    },
    {
      "Location ID": "064M1",
      "Location Name": "MBI - 1",
      "Time Zone Offset (Hours)": 0,
      "Address": " 88 LONG HILL CROSS RD SHELTON CT 06484 USA "
    },
    {
      "Location ID": "064M2",
      "Location Name": "POSTAL COMMEMORATIVE SOCIETY (MBI)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 FOREST PKWY SHELTON CT 06484 USA "
    },
    {
      "Location ID": "064RR",
      "Location Name": "RED ROCKET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90 KNOTHE RD WESTBROOK CT 06498 USA "
    },
    {
      "Location ID": "064SA",
      "Location Name": "SHELTON CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 FOREST PKWY SHELTON CT 06484 USA "
    },
    {
      "Location ID": "064SP",
      "Location Name": "SHORE POWER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 SCHOOL HOUSE RD OLD SAYBROOK CT 06475 USA "
    },
    {
      "Location ID": "065",
      "Location Name": "NEW HAVEN           CT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 BREWERY STREET NEW HAVEN CT 06511 USA "
    },
    {
      "Location ID": "06610",
      "Location Name": "BAYVIEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 BOSTON AVE BRIDGEPORT CT 06610 USA "
    },
    {
      "Location ID": "067",
      "Location Name": "WATERBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 135 GRAND ST WATERBURY CT 06701 USA "
    },
    {
      "Location ID": "06750",
      "Location Name": "BANTAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 DOYLE RD BANTAM CT 06750 USA "
    },
    {
      "Location ID": "06751",
      "Location Name": "BETHLEHEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 EAST ST BETHLEHEM CT 06751 USA "
    },
    {
      "Location ID": "06752",
      "Location Name": "BRIDGEWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 MAIN ST S BRIDGEWATER CT 06752 USA "
    },
    {
      "Location ID": "06753",
      "Location Name": "CORNWALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 BOLTON HILL RD CORNWALL CT 06753 USA "
    },
    {
      "Location ID": "06754",
      "Location Name": "CORNWALL BRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18A KENT RD S CORNWALL BRIDGE CT 06754 USA "
    },
    {
      "Location ID": "06755",
      "Location Name": "GAYLORDSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 GEORGE WASHINGTON PLZ UNIT 8 GAYLORDSVILLE CT 06755 USA "
    },
    {
      "Location ID": "06756",
      "Location Name": "GOSHEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 SUNSET DR GOSHEN CT 06756 USA "
    },
    {
      "Location ID": "06757",
      "Location Name": "KENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31 KENT GREEN BLVD KENT CT 06757 USA "
    },
    {
      "Location ID": "06759",
      "Location Name": "LITCHFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 SOUTH ST LITCHFIELD CT 06759 USA "
    },
    {
      "Location ID": "06762",
      "Location Name": "MIDDLEBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 590 MIDDLEBURY RD STE F MIDDLEBURY CT 06762 USA "
    },
    {
      "Location ID": "06763",
      "Location Name": "MORRIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 WEST ST MORRIS CT 06763 USA "
    },
    {
      "Location ID": "06770",
      "Location Name": "NAUGATUCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 170 WATER ST NAUGATUCK CT 06770 USA "
    },
    {
      "Location ID": "06776",
      "Location Name": "NEW MILFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37 MAIN ST NEW MILFORD CT 06776 USA "
    },
    {
      "Location ID": "06777",
      "Location Name": "NEW PRESTON MARBLE DALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 253 NEW MILFORD TPKE NEW PRESTON MARBLE DALE CT 06777 USA "
    },
    {
      "Location ID": "06783",
      "Location Name": "ROXBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 NORTH ST ROXBURY CT 06783 USA "
    },
    {
      "Location ID": "06784",
      "Location Name": "SHERMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 ROUTE 37 CTR STE 1 SHERMAN CT 06784 USA "
    },
    {
      "Location ID": "06785",
      "Location Name": "SOUTH KENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 314 S KENT RD SOUTH KENT CT 06785 USA "
    },
    {
      "Location ID": "06790",
      "Location Name": "TORRINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 185 E ELM ST TORRINGTON CT 06790 USA "
    },
    {
      "Location ID": "06793",
      "Location Name": "WASHINGTON GREEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 KIRBY RD WASHINGTON CT 06793 USA "
    },
    {
      "Location ID": "06794",
      "Location Name": "WASHINGTON DEPOT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 BRYAN HALL PLZ WASHINGTON DEPOT CT 06794 USA "
    },
    {
      "Location ID": "06795",
      "Location Name": "WATERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 WOODRUFF AVE WATERTOWN CT 06795 USA "
    },
    {
      "Location ID": "06796",
      "Location Name": "WEST CORNWALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 408 SHARON GOSHEN TPKE WEST CORNWALL CT 06796 USA "
    },
    {
      "Location ID": "06798",
      "Location Name": "WOODBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 WASHINGTON AVE WOODBURY CT 06798 USA "
    },
    {
      "Location ID": "068",
      "Location Name": "LOG STAMFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 427 WEST AVE STAMFORD CT 06910 USA "
    },
    {
      "Location ID": "06804",
      "Location Name": "BROOKFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 POCONO RD BROOKFIELD CT 06804 USA "
    },
    {
      "Location ID": "06810",
      "Location Name": "MAIN STREET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 265 MAIN ST DANBURY CT 06810 USA "
    },
    {
      "Location ID": "06820",
      "Location Name": "DARIEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 CORBIN DR DARIEN CT 06820 USA "
    },
    {
      "Location ID": "06851",
      "Location Name": "WESTPORT CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 234 WESTPORT AVE NORWALK CT 06851 USA "
    },
    {
      "Location ID": "06852",
      "Location Name": "BELDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 BELDEN AVE NORWALK CT 06850 USA "
    },
    {
      "Location ID": "06856",
      "Location Name": "NORWALK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 WASHINGTON ST NORWALK CT 06854 USA "
    },
    {
      "Location ID": "06875",
      "Location Name": "REDDING CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 LONETOWN RD REDDING CENTER CT 06875 USA "
    },
    {
      "Location ID": "06876",
      "Location Name": "REDDING RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 BLACK ROCK TPKE REDDING RIDGE CT 06876 USA "
    },
    {
      "Location ID": "06880",
      "Location Name": "WESTPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 275 POST RD E STE 10 WESTPORT CT 06880 USA "
    },
    {
      "Location ID": "06896",
      "Location Name": "WEST REDDING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 268 SIMPAUG TPKE REDDING CT 06896 USA "
    },
    {
      "Location ID": "068CT",
      "Location Name": "CHALK TALK SPORT INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 151 WOODWARD AVE NORWALK CT 06854 USA "
    },
    {
      "Location ID": "068LX",
      "Location Name": "LAX.COM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 345 WILSON AVE NORWALK CT 06854 USA "
    },
    {
      "Location ID": "068NO",
      "Location Name": "NORDSTROM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 N WATER ST STE A NORWALK CT 06854 USA "
    },
    {
      "Location ID": "06NHA",
      "Location Name": "NORTH HAVEN CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 MIDDLETOWN AVE NORTH HAVEN CT 06473 USA "
    },
    {
      "Location ID": "06PSI",
      "Location Name": "PITNEY BOWES PRESORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 LOCUST ST HARTFORD CT 06114 USA "
    },
    {
      "Location ID": "070",
      "Location Name": "NEWARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 FEDERAL SQ NEWARK NJ 07102 USA "
    },
    {
      "Location ID": "07001",
      "Location Name": "AVENEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1065 RAHWAY AVE AVENEL NJ 07001 USA "
    },
    {
      "Location ID": "07008",
      "Location Name": "CARTERET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 212 PERSHING AVE CARTERET NJ 07008 USA "
    },
    {
      "Location ID": "07016",
      "Location Name": "CRANFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 MILN ST CRANFORD NJ 07016 USA "
    },
    {
      "Location ID": "07023",
      "Location Name": "FANWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 275 SOUTH AVE FANWOOD NJ 07023 USA "
    },
    {
      "Location ID": "07027",
      "Location Name": "GARWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 CENTER ST GARWOOD NJ 07027 USA "
    },
    {
      "Location ID": "07033",
      "Location Name": "KENILWORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 641 BOULEVARD KENILWORTH NJ 07033 USA "
    },
    {
      "Location ID": "07039",
      "Location Name": "LIVINGSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 49 W MOUNT PLEASANT AVE LIVINGSTON NJ 07039 USA "
    },
    {
      "Location ID": "07041",
      "Location Name": "MILLBURN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 MILLBURN AVE MILLBURN NJ 07041 USA "
    },
    {
      "Location ID": "07050",
      "Location Name": "ORANGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 384 MAIN ST ORANGE NJ 07050 USA "
    },
    {
      "Location ID": "07059",
      "Location Name": "WARREN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 TECHNOLOGY DR WARREN NJ 07059 USA "
    },
    {
      "Location ID": "07060",
      "Location Name": "PLAINFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 WATCHUNG AVE PLAINFIELD NJ 07060 USA "
    },
    {
      "Location ID": "07061",
      "Location Name": "INVALID FACILITY - DO NOT USE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 WATCHUNG AVE PLAINFIELD NJ 07060 USA "
    },
    {
      "Location ID": "07064",
      "Location Name": "PORT READING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 605 PORT READING AVE PORT READING NJ 07064 USA "
    },
    {
      "Location ID": "07065",
      "Location Name": "RAHWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 E MILTON AVE RAHWAY NJ 07065 USA "
    },
    {
      "Location ID": "07076",
      "Location Name": "SCOTCH PLAINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 536 PARK AVE SCOTCH PLAINS NJ 07076 USA "
    },
    {
      "Location ID": "07077",
      "Location Name": "SEWAREN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 85 WOODBRIDGE AVE SEWAREN NJ 07077 USA "
    },
    {
      "Location ID": "07078",
      "Location Name": "SHORT HILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 CHATHAM RD SHORT HILLS NJ 07078 USA "
    },
    {
      "Location ID": "07079",
      "Location Name": "SOUTH ORANGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31 VOSE AVE SOUTH ORANGE NJ 07079 USA "
    },
    {
      "Location ID": "07080",
      "Location Name": "SOUTH PLAINFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 OAK TREE AVE SOUTH PLAINFIELD NJ 07080 USA "
    },
    {
      "Location ID": "07081",
      "Location Name": "SPRINGFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 MOUNTAIN AVE SPRINGFIELD NJ 07081 USA "
    },
    {
      "Location ID": "07090",
      "Location Name": "WESTFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 153 CENTRAL AVE WESTFIELD NJ 07090 USA "
    },
    {
      "Location ID": "07095",
      "Location Name": "WOODBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 MAIN ST WOODBRIDGE NJ 07095 USA "
    },
    {
      "Location ID": "07099",
      "Location Name": "LOG DOMINCK V DANIELS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 850 NEWARK TPKE KEARNY NJ 07099 USA "
    },
    {
      "Location ID": "070AM",
      "Location Name": "AMAZON EWR5",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 BLAIR RD STE 200 AVENEL NJ 07001 USA "
    },
    {
      "Location ID": "070AX",
      "Location Name": "EAST RUTHERFORD DDC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 263 HACKENSACK ST EAST RUTHERFORD NJ 07073 USA "
    },
    {
      "Location ID": "070AZ",
      "Location Name": "AMAZON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 380 MIDDLESEX AVE CARTERET NJ 07008 USA "
    },
    {
      "Location ID": "070BB",
      "Location Name": "BED BATH & BEYOND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1001 W MIDDLESEX AVE PORT READING NJ 07064 USA "
    },
    {
      "Location ID": "070DH",
      "Location Name": "DHL GLOBAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 191 BLAIR RD AVENEL NJ 07001 USA "
    },
    {
      "Location ID": "070FZ",
      "Location Name": "LOG NEW JERSEY NJ PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1201 VALLEY BROOK AVE LYNDHURST NJ 07071 USA "
    },
    {
      "Location ID": "070IC",
      "Location Name": "INDOO COM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22 CRAIGWOOD RD AVENEL NJ 07001 USA "
    },
    {
      "Location ID": "070PM",
      "Location Name": "LOG GREATER NEWARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1200 HARRISON AVE KEARNY NJ 07032 USA "
    },
    {
      "Location ID": "070PS",
      "Location Name": "PSI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19A CHAPIN RD STE 101A PINE BROOK NJ 07058 USA "
    },
    {
      "Location ID": "07109",
      "Location Name": "BELLEVILLE ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 525 MAIN ST BELLEVILLE NJ 07109 USA "
    },
    {
      "Location ID": "072NG",
      "Location Name": "PITNEY BOWES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 INTERSTATE BLVD MONROE TOWNSHIP NJ 08831 USA "
    },
    {
      "Location ID": "072NJ",
      "Location Name": "NEW JERSEY DISPATCH ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 COUNTY RD JERSEY CITY NJ 07097 USA "
    },
    {
      "Location ID": "07401",
      "Location Name": "ALLENDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 MYRTLE AVE ALLENDALE NJ 07401 USA "
    },
    {
      "Location ID": "07403",
      "Location Name": "BLOOMINGDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 126 MAIN ST BLOOMINGDALE NJ 07403 USA "
    },
    {
      "Location ID": "07405",
      "Location Name": "BUTLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 180 MAIN ST STE A BUTLER NJ 07405 USA "
    },
    {
      "Location ID": "07407",
      "Location Name": "ELMWOOD PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 335 MOLA BLVD ELMWOOD PARK NJ 07407 USA "
    },
    {
      "Location ID": "07410",
      "Location Name": "FAIR LAWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14-24 ABBOTT RD FAIR LAWN NJ 07410 USA "
    },
    {
      "Location ID": "07416",
      "Location Name": "FRANKLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 270K STATE RT 23 FRANKLIN NJ 07416 USA "
    },
    {
      "Location ID": "07417",
      "Location Name": "FRANKLIN LAKES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 785 FRANKLIN AVE FRANKLIN LAKES NJ 07417 USA "
    },
    {
      "Location ID": "07418",
      "Location Name": "GLENWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 958 COUNTY RT 517 GLENWOOD NJ 07418 USA "
    },
    {
      "Location ID": "07419",
      "Location Name": "HAMBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 MAIN ST HAMBURG NJ 07419 USA "
    },
    {
      "Location ID": "07422",
      "Location Name": "HIGHLAND LAKES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 177 BREAKNECK RD HIGHLAND LAKES NJ 07422 USA "
    },
    {
      "Location ID": "07423",
      "Location Name": "HO HO KUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 N FRANKLIN TPKE HO HO KUS NJ 07423 USA "
    },
    {
      "Location ID": "07428",
      "Location Name": "MC AFEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 STATE RT 94 MC AFEE NJ 07428 USA "
    },
    {
      "Location ID": "07432",
      "Location Name": "MIDLAND PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 GODWIN AVE MIDLAND PARK NJ 07432 USA "
    },
    {
      "Location ID": "07435",
      "Location Name": "NEWFOUNDLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2727 STATE RT 23 STE 1 NEWFOUNDLAND NJ 07435 USA "
    },
    {
      "Location ID": "07436",
      "Location Name": "OAKLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 332 RAMAPO VALLEY RD OAKLAND NJ 07436 USA "
    },
    {
      "Location ID": "07438",
      "Location Name": "OAK RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 247 OAK RIDGE RD OAK RIDGE NJ 07438 USA "
    },
    {
      "Location ID": "07439",
      "Location Name": "OGDENSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 328 MAIN ST OGDENSBURG NJ 07439 USA "
    },
    {
      "Location ID": "07442",
      "Location Name": "POMPTON LAKES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 47 LAKESIDE AVE POMPTON LAKES NJ 07442 USA "
    },
    {
      "Location ID": "07450",
      "Location Name": "RIDGEWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 143 E RIDGEWOOD AVE RIDGEWOOD NJ 07450 USA "
    },
    {
      "Location ID": "07457",
      "Location Name": "RIVERDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 56 HAMBURG TPKE RIVERDALE NJ 07457 USA "
    },
    {
      "Location ID": "07460",
      "Location Name": "STOCKHOLM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2741 STATE RT 23 STE 4 STOCKHOLM NJ 07460 USA "
    },
    {
      "Location ID": "07461",
      "Location Name": "SUSSEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 455 STATE RT 23 STE 5 SUSSEX NJ 07461 USA "
    },
    {
      "Location ID": "07462",
      "Location Name": "VERNON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 530 COUNTY ROUTE 515 UNIT 13 VERNON NJ 07462 USA "
    },
    {
      "Location ID": "07463",
      "Location Name": "WALDWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 WALTER HAMMOND PL WALDWICK NJ 07463 USA "
    },
    {
      "Location ID": "07481",
      "Location Name": "WYCKOFF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 411 GREENWOOD AVE WYCKOFF NJ 07481 USA "
    },
    {
      "Location ID": "076",
      "Location Name": "LOG NORTHERN NJ METRO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 INDUSTRIAL AVE TETERBORO NJ 07699 USA "
    },
    {
      "Location ID": "07621",
      "Location Name": "BERGENFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 VETERANS PLZ BERGENFIELD NJ 07621 USA "
    },
    {
      "Location ID": "07624",
      "Location Name": "CLOSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 185 HOMANS AVE CLOSTER NJ 07624 USA "
    },
    {
      "Location ID": "07626",
      "Location Name": "CRESSKILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 WASHINGTON ST STE 2 CRESSKILL NJ 07626 USA "
    },
    {
      "Location ID": "07627",
      "Location Name": "DEMAREST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 126 HARDENBURGH AVE DEMAREST NJ 07627 USA "
    },
    {
      "Location ID": "07628",
      "Location Name": "DUMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 138 VETERANS PLZ DUMONT NJ 07628 USA "
    },
    {
      "Location ID": "07631",
      "Location Name": "ENGLEWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 77 ENGLE ST ENGLEWOOD NJ 07631 USA "
    },
    {
      "Location ID": "07640",
      "Location Name": "HARRINGTON PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 LYNN ST HARRINGTON PARK NJ 07640 USA "
    },
    {
      "Location ID": "07641",
      "Location Name": "HAWORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 SAINT NICHOLAS AVE HAWORTH NJ 07641 USA "
    },
    {
      "Location ID": "07642",
      "Location Name": "HILLSDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 445 HILLSDALE AVE HILLSDALE NJ 07642 USA "
    },
    {
      "Location ID": "07643",
      "Location Name": "LITTLE FERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 MAIN ST LITTLE FERRY NJ 07643 USA "
    },
    {
      "Location ID": "07644",
      "Location Name": "LODI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 WASHINGTON ST STE 1 LODI NJ 07644 USA "
    },
    {
      "Location ID": "07646",
      "Location Name": "NEW MILFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1055 RIVER RD NEW MILFORD NJ 07646 USA "
    },
    {
      "Location ID": "07647",
      "Location Name": "NORTHVALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 124 PARIS AVE NORTHVALE NJ 07647 USA "
    },
    {
      "Location ID": "07648",
      "Location Name": "NORWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 239 RAILROAD AVE NORWOOD NJ 07648 USA "
    },
    {
      "Location ID": "07649",
      "Location Name": "ORADELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 618 VELDRAN AVE ORADELL NJ 07649 USA "
    },
    {
      "Location ID": "07652",
      "Location Name": "PARAMUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 E MIDLAND AVE PARAMUS NJ 07652 USA "
    },
    {
      "Location ID": "07657",
      "Location Name": "RIDGEFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 757 BROAD AVE RIDGEFIELD NJ 07657 USA "
    },
    {
      "Location ID": "07660",
      "Location Name": "RIDGEFIELD PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 155 MAIN ST RIDGEFIELD PARK NJ 07660 USA "
    },
    {
      "Location ID": "07661",
      "Location Name": "RIVER EDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 650 KINDERKAMACK RD RIVER EDGE NJ 07661 USA "
    },
    {
      "Location ID": "07663",
      "Location Name": "SADDLE BROOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 360 MARKET ST SADDLE BROOK NJ 07663 USA "
    },
    {
      "Location ID": "07666",
      "Location Name": "TEANECK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 751 PALISADE AVE TEANECK NJ 07666 USA "
    },
    {
      "Location ID": "07670",
      "Location Name": "TENAFLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 TENAFLY RD TENAFLY NJ 07670 USA "
    },
    {
      "Location ID": "07675",
      "Location Name": "WESTWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 216 WESTWOOD AVE WESTWOOD NJ 07675 USA "
    },
    {
      "Location ID": "076AB",
      "Location Name": "ENGLEWOOD ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 SMITH ST ENGLEWOOD NJ 07631 USA "
    },
    {
      "Location ID": "076KR",
      "Location Name": "KRANE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 NORDHOFF PL ENGLEWOOD NJ 07631 USA "
    },
    {
      "Location ID": "07701",
      "Location Name": "RED BANK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 171 BROAD ST UNIT 1 RED BANK NJ 07701 USA "
    },
    {
      "Location ID": "07710",
      "Location Name": "ADELPHIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 WYCKOFF MILLS RD ADELPHIA NJ 07710 USA "
    },
    {
      "Location ID": "07711",
      "Location Name": "ALLENHURST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 MAIN ST ALLENHURST NJ 07711 USA "
    },
    {
      "Location ID": "07712",
      "Location Name": "ASBURY PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 801 BANGS AVE ASBURY PARK NJ 07712 USA "
    },
    {
      "Location ID": "07716",
      "Location Name": "ATLANTIC HIGHLANDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 RAILROAD AVE ATLANTIC HIGHLANDS NJ 07716 USA "
    },
    {
      "Location ID": "07717",
      "Location Name": "AVON BY THE SEA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 321 MAIN ST AVON BY THE SEA NJ 07717 USA "
    },
    {
      "Location ID": "07718",
      "Location Name": "BELFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 669 STATE ROUTE 36 BELFORD NJ 07718 USA "
    },
    {
      "Location ID": "07719",
      "Location Name": "BELMAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1300 MAIN ST BELMAR NJ 07719 USA "
    },
    {
      "Location ID": "07720",
      "Location Name": "BRADLEY BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 515 MAIN ST BRADLEY BEACH NJ 07720 USA "
    },
    {
      "Location ID": "07721",
      "Location Name": "CLIFFWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 349 STATE ROUTE 35 CLIFFWOOD NJ 07721 USA "
    },
    {
      "Location ID": "07722",
      "Location Name": "COLTS NECK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 420 STATE ROUTE 34 STE 307 COLTS NECK NJ 07722 USA "
    },
    {
      "Location ID": "07723",
      "Location Name": "DEAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 92 NORWOOD AVE DEAL NJ 07723 USA "
    },
    {
      "Location ID": "07724",
      "Location Name": "EATONTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 409 SOUTH ST EATONTOWN NJ 07724 USA "
    },
    {
      "Location ID": "07726",
      "Location Name": "ENGLISHTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 TAYLORS MILLS RD MANALAPAN NJ 07726 USA "
    },
    {
      "Location ID": "07727",
      "Location Name": "FARMINGDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 66 MAIN ST FARMINGDALE NJ 07727 USA "
    },
    {
      "Location ID": "07728",
      "Location Name": "FREEHOLD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 VILLAGE CENTER DR FREEHOLD NJ 07728 USA "
    },
    {
      "Location ID": "07730",
      "Location Name": "HAZLET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 65 HAZLET AVE HAZLET NJ 07730 USA "
    },
    {
      "Location ID": "07731",
      "Location Name": "HOWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4170 US HIGHWAY 9 HOWELL NJ 07731 USA "
    },
    {
      "Location ID": "07732",
      "Location Name": "HIGHLANDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 170 BAY AVE HIGHLANDS NJ 07732 USA "
    },
    {
      "Location ID": "07733",
      "Location Name": "HOLMDEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 CRAWFORDS CORNER RD HOLMDEL NJ 07733 USA "
    },
    {
      "Location ID": "07734",
      "Location Name": "KEANSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 230 MAIN ST KEANSBURG NJ 07734 USA "
    },
    {
      "Location ID": "07735",
      "Location Name": "KEYPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 95 W FRONT ST KEYPORT NJ 07735 USA "
    },
    {
      "Location ID": "07737",
      "Location Name": "LEONARDO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 THOMPSON AVE LEONARDO NJ 07737 USA "
    },
    {
      "Location ID": "07738",
      "Location Name": "LINCROFT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 774 NEWMAN SPRINGS RD LINCROFT NJ 07738 USA "
    },
    {
      "Location ID": "07739",
      "Location Name": "LITTLE SILVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 CHURCH ST LITTLE SILVER NJ 07739 USA "
    },
    {
      "Location ID": "07740",
      "Location Name": "LONG BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 3RD AVE LONG BRANCH NJ 07740 USA "
    },
    {
      "Location ID": "07746",
      "Location Name": "MARLBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 S MAIN ST STE 1 MARLBORO NJ 07746 USA "
    },
    {
      "Location ID": "07747",
      "Location Name": "MATAWAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 155 MAIN ST MATAWAN NJ 07747 USA "
    },
    {
      "Location ID": "07748",
      "Location Name": "MIDDLETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1601 STATE ROUTE 35 MIDDLETOWN NJ 07748 USA "
    },
    {
      "Location ID": "07750",
      "Location Name": "MONMOUTH BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 BEACH RD STE 8 MONMOUTH BEACH NJ 07750 USA "
    },
    {
      "Location ID": "07751",
      "Location Name": "MORGANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 TENNENT RD MORGANVILLE NJ 07751 USA "
    },
    {
      "Location ID": "07752",
      "Location Name": "NAVESINK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2375 STATE ROUTE 36 NAVESINK NJ 07752 USA "
    },
    {
      "Location ID": "07753",
      "Location Name": "NEPTUNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 NEPTUNE BLVD NEPTUNE NJ 07753 USA "
    },
    {
      "Location ID": "07755",
      "Location Name": "OAKHURST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 715 W PARK AVE OAKHURST NJ 07755 USA "
    },
    {
      "Location ID": "07756",
      "Location Name": "OCEAN GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 59 MAIN AVE OCEAN GROVE NJ 07756 USA "
    },
    {
      "Location ID": "07757",
      "Location Name": "OCEANPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 285 E MAIN ST OCEANPORT NJ 07757 USA "
    },
    {
      "Location ID": "07758",
      "Location Name": "PORT MONMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 PULSCH ST PORT MONMOUTH NJ 07758 USA "
    },
    {
      "Location ID": "07760",
      "Location Name": "RUMSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 HUNT ST RUMSON NJ 07760 USA "
    },
    {
      "Location ID": "07762",
      "Location Name": "SPRING LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2407 STATE ROUTE 71 STE 1 SPRING LAKE NJ 07762 USA "
    },
    {
      "Location ID": "07763",
      "Location Name": "TENNENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 420 MAIN ST TENNENT NJ 07763 USA "
    },
    {
      "Location ID": "07764",
      "Location Name": "WEST LONG BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 183 LOCUST AVE WEST LONG BRANCH NJ 07764 USA "
    },
    {
      "Location ID": "07765",
      "Location Name": "WICKATUNK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 248 STATE ROUTE 79 WICKATUNK NJ 07765 USA "
    },
    {
      "Location ID": "077DA",
      "Location Name": "DISCOUNT AUTO PARTS.COM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 FRANCIS ST STE 10 KEYPORT NJ 07735 USA "
    },
    {
      "Location ID": "077DH",
      "Location Name": "DR HARVEYS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 INDUSTRIAL WAY W EATONTOWN NJ 07724 USA "
    },
    {
      "Location ID": "077GS",
      "Location Name": "THE GLOBAL SPORTSMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 246 INDUSTRIAL WAY W EATONTOWN NJ 07724 USA "
    },
    {
      "Location ID": "077KB",
      "Location Name": "KUMAR BROTHERS MARLBORO NJ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 TIMBER LN STE 304 MARLBORO NJ 07746 USA "
    },
    {
      "Location ID": "077NO",
      "Location Name": "NORDSTROM 522",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3710 HIGHWAY 9 STE 1400 FREEHOLD NJ 07728 USA "
    },
    {
      "Location ID": "077RR",
      "Location Name": "REALLY RIGHT ON LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5030 SHAFTO RD TINTON FALLS NJ 07712 USA "
    },
    {
      "Location ID": "077WB",
      "Location Name": "WILD BOBBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 INDUSTRIAL WAY W BLDG D-E EATONTOWN NJ 07724 USA "
    },
    {
      "Location ID": "07801",
      "Location Name": "DOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22 N SUSSEX ST DOVER NJ 07801 USA "
    },
    {
      "Location ID": "07820",
      "Location Name": "ALLAMUCHY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 RUTHERFORD RD ALLAMUCHY NJ 07820 USA "
    },
    {
      "Location ID": "07821",
      "Location Name": "ANDOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 LENAPE RD ANDOVER NJ 07821 USA "
    },
    {
      "Location ID": "07822",
      "Location Name": "AUGUSTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 US HIGHWAY 206 STE 101 AUGUSTA NJ 07822 USA "
    },
    {
      "Location ID": "07826",
      "Location Name": "BRANCHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 BROAD ST BRANCHVILLE NJ 07826 USA "
    },
    {
      "Location ID": "07828",
      "Location Name": "BUDD LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 MOUNT OLIVE RD STE 102 BUDD LAKE NJ 07828 USA "
    },
    {
      "Location ID": "07830",
      "Location Name": "CALIFON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 53 MAIN ST CALIFON NJ 07830 USA "
    },
    {
      "Location ID": "07834",
      "Location Name": "DENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 CHURCH ST DENVILLE NJ 07834 USA "
    },
    {
      "Location ID": "07836",
      "Location Name": "FLANDERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 240 US HIGHWAY 206 UNIT 20 FLANDERS NJ 07836 USA "
    },
    {
      "Location ID": "07838",
      "Location Name": "GREAT MEADOWS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 REGINA LN GREAT MEADOWS NJ 07838 USA "
    },
    {
      "Location ID": "07839",
      "Location Name": "GREENDELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 243 KENNEDY RD GREENDELL NJ 07839 USA "
    },
    {
      "Location ID": "07840",
      "Location Name": "HACKETTSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 GRAND AVE HACKETTSTOWN NJ 07840 USA "
    },
    {
      "Location ID": "07842",
      "Location Name": "HIBERNIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 328 GREEN POND RD HIBERNIA NJ 07842 USA "
    },
    {
      "Location ID": "07845",
      "Location Name": "IRONIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 389 DOVER CHESTER RD IRONIA NJ 07845 USA "
    },
    {
      "Location ID": "07847",
      "Location Name": "KENVIL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 708 US HIGHWAY 46 KENVIL NJ 07847 USA "
    },
    {
      "Location ID": "07851",
      "Location Name": "LAYTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 122 COUNTY ROAD 560 LAYTON NJ 07851 USA "
    },
    {
      "Location ID": "07852",
      "Location Name": "LEDGEWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1070 US HIGHWAY 46 STE 11 LEDGEWOOD NJ 07852 USA "
    },
    {
      "Location ID": "07853",
      "Location Name": "LONG VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 OLD FARMERS RD LONG VALLEY NJ 07853 USA "
    },
    {
      "Location ID": "07857",
      "Location Name": "NETCONG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 MAPLE AVE NETCONG NJ 07857 USA "
    },
    {
      "Location ID": "07860",
      "Location Name": "NEWTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 MAIN ST NEWTON NJ 07860 USA "
    },
    {
      "Location ID": "07863",
      "Location Name": "OXFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 170 STATE ROUTE 31 N OXFORD NJ 07863 USA "
    },
    {
      "Location ID": "07865",
      "Location Name": "PORT MURRAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 218 MAIN ST PORT MURRAY NJ 07865 USA "
    },
    {
      "Location ID": "07871",
      "Location Name": "SPARTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 MAIN ST STE 1 SPARTA NJ 07871 USA "
    },
    {
      "Location ID": "07874",
      "Location Name": "STANHOPE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 KELLY PL STANHOPE NJ 07874 USA "
    },
    {
      "Location ID": "07876",
      "Location Name": "SUCCASUNNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 ROXBURY MALL SUCCASUNNA NJ 07876 USA "
    },
    {
      "Location ID": "07878",
      "Location Name": "MOUNT TABOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 SIMPSON AVE MOUNT TABOR NJ 07878 USA "
    },
    {
      "Location ID": "07879",
      "Location Name": "TRANQUILITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 KENNEDY RD TRANQUILITY NJ 07879 USA "
    },
    {
      "Location ID": "07880",
      "Location Name": "VIENNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 WATER ST VIENNA NJ 07880 USA "
    },
    {
      "Location ID": "07882",
      "Location Name": "WASHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 BELVIDERE AVE WASHINGTON NJ 07882 USA "
    },
    {
      "Location ID": "07885",
      "Location Name": "WHARTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 350 N MAIN ST STE 7 WHARTON NJ 07885 USA "
    },
    {
      "Location ID": "07901",
      "Location Name": "SUMMIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 61 MAPLE ST SUMMIT NJ 07901 USA "
    },
    {
      "Location ID": "07920",
      "Location Name": "BASKING RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 BROWNLEE PL BASKING RIDGE NJ 07920 USA "
    },
    {
      "Location ID": "07921",
      "Location Name": "BEDMINSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 251 SOMERVILLE RD BEDMINSTER NJ 07921 USA "
    },
    {
      "Location ID": "07922",
      "Location Name": "BERKELEY HEIGHTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 364 SPRINGFIELD AVE BERKELEY HEIGHTS NJ 07922 USA "
    },
    {
      "Location ID": "07924",
      "Location Name": "BERNARDSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23 QUIMBY LN BERNARDSVILLE NJ 07924 USA "
    },
    {
      "Location ID": "07926",
      "Location Name": "BROOKSIDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 E MAIN ST BROOKSIDE NJ 07926 USA "
    },
    {
      "Location ID": "07927",
      "Location Name": "CEDAR KNOLLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 MOUNTAIN AVE CEDAR KNOLLS NJ 07927 USA "
    },
    {
      "Location ID": "07928",
      "Location Name": "CHATHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 S PASSAIC AVE CHATHAM NJ 07928 USA "
    },
    {
      "Location ID": "07930",
      "Location Name": "CHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 SENTRY LN CHESTER NJ 07930 USA "
    },
    {
      "Location ID": "07931",
      "Location Name": "FAR HILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 US HIGHWAY 202 FAR HILLS NJ 07931 USA "
    },
    {
      "Location ID": "07932",
      "Location Name": "FLORHAM PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 187 COLUMBIA TPKE STE 15 FLORHAM PARK NJ 07932 USA "
    },
    {
      "Location ID": "07933",
      "Location Name": "GILLETTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 594 VALLEY RD GILLETTE NJ 07933 USA "
    },
    {
      "Location ID": "07934",
      "Location Name": "GLADSTONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 266 MAIN ST STE A GLADSTONE NJ 07934 USA "
    },
    {
      "Location ID": "07935",
      "Location Name": "GREEN VILLAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 372 GREEN VILLAGE RD GREEN VILLAGE NJ 07935 USA "
    },
    {
      "Location ID": "07936",
      "Location Name": "EAST HANOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 315 RIDGEDALE AVE EAST HANOVER NJ 07936 USA "
    },
    {
      "Location ID": "07938",
      "Location Name": "LIBERTY CORNER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 CHURCH ST LIBERTY CORNER NJ 07938 USA "
    },
    {
      "Location ID": "07939",
      "Location Name": "LYONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 151 KNOLLCROFT RD LYONS NJ 07939 USA "
    },
    {
      "Location ID": "07940",
      "Location Name": "MADISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 LINCOLN PL MADISON NJ 07940 USA "
    },
    {
      "Location ID": "07945",
      "Location Name": "MENDHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 TEMPE WICK RD MENDHAM NJ 07945 USA "
    },
    {
      "Location ID": "07946",
      "Location Name": "MILLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1936 LONG HILL RD MILLINGTON NJ 07946 USA "
    },
    {
      "Location ID": "07950",
      "Location Name": "MORRIS PLAINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 485 SPEEDWELL AVE MORRIS PLAINS NJ 07950 USA "
    },
    {
      "Location ID": "07960",
      "Location Name": "MORRISTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 166 RIDGEDALE AVE STE 9 MORRISTOWN NJ 07960 USA "
    },
    {
      "Location ID": "07961",
      "Location Name": "CONVENT STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 CONVENT RD CONVENT STATION NJ 07961 USA "
    },
    {
      "Location ID": "07970",
      "Location Name": "MOUNT FREEDOM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1250 SUSSEX TPKE MOUNT FREEDOM NJ 07970 USA "
    },
    {
      "Location ID": "07974",
      "Location Name": "NEW PROVIDENCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 598 CENTRAL AVE STE 7 NEW PROVIDENCE NJ 07974 USA "
    },
    {
      "Location ID": "07976",
      "Location Name": "NEW VERNON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 VILLAGE RD NEW VERNON NJ 07976 USA "
    },
    {
      "Location ID": "07977",
      "Location Name": "PEAPACK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 155 MAIN ST PEAPACK NJ 07977 USA "
    },
    {
      "Location ID": "07978",
      "Location Name": "PLUCKEMIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 318 US HIGHWAY 206 PLUCKEMIN NJ 07978 USA "
    },
    {
      "Location ID": "07979",
      "Location Name": "POTTERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 FAIRMOUNT RD E POTTERSVILLE NJ 07979 USA "
    },
    {
      "Location ID": "07980",
      "Location Name": "STIRLING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1153 VALLEY RD STE 8 STIRLING NJ 07980 USA "
    },
    {
      "Location ID": "079MA",
      "Location Name": "MADISON CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 CENTRAL AVE MADISON NJ 07940 USA "
    },
    {
      "Location ID": "079SM",
      "Location Name": "SCIENTIFIC MODELS INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 340 SNYDER AVE BERKELEY HEIGHTS NJ 07922 USA "
    },
    {
      "Location ID": "07H",
      "Location Name": "NEW JERSEY STC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 STRYKERS RD PHILLIPSBURG NJ 08865 USA "
    },
    {
      "Location ID": "07I",
      "Location Name": "NJ INTERNATIONAL OPERATIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 COUNTY RD JERSEY CITY NJ 07097 USA "
    },
    {
      "Location ID": "07Z",
      "Location Name": "LOG NEW JERSEY NDC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 COUNTY RD JERSEY CITY NJ 07097 USA "
    },
    {
      "Location ID": "080",
      "Location Name": "LOG SOUTH JERSEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 421 BENIGNO BLVD BELLMAWR NJ 08031 USA "
    },
    {
      "Location ID": "08001",
      "Location Name": "ALLOWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 SCHOOL LN ALLOWAY NJ 08001 USA "
    },
    {
      "Location ID": "08002",
      "Location Name": "ELLISBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 KINGS HWY N STE 1 CHERRY HILL NJ 08002 USA "
    },
    {
      "Location ID": "08004",
      "Location Name": "ATCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 408 FRONT ST ATCO NJ 08004 USA "
    },
    {
      "Location ID": "08009",
      "Location Name": "BERLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 HARKER AVE BERLIN NJ 08009 USA "
    },
    {
      "Location ID": "08010",
      "Location Name": "BEVERLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 417 WARREN ST BEVERLY NJ 08010 USA "
    },
    {
      "Location ID": "08011",
      "Location Name": "BIRMINGHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 221 BIRMINGHAM RD BIRMINGHAM NJ 08011 USA "
    },
    {
      "Location ID": "08012",
      "Location Name": "BLACKWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 DAVISTOWN RD BLACKWOOD NJ 08012 USA "
    },
    {
      "Location ID": "08014",
      "Location Name": "BRIDGEPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 134 MAIN ST BRIDGEPORT NJ 08014 USA "
    },
    {
      "Location ID": "08015",
      "Location Name": "BROWNS MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 DEARBORN AVE BROWNS MILLS NJ 08015 USA "
    },
    {
      "Location ID": "08016",
      "Location Name": "BURLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 427 HIGH ST BURLINGTON NJ 08016 USA "
    },
    {
      "Location ID": "08018",
      "Location Name": "CEDAR BROOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 N ROUTE 73 CEDAR BROOK NJ 08018 USA "
    },
    {
      "Location ID": "08019",
      "Location Name": "CHATSWORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 2ND AVE CHATSWORTH NJ 08019 USA "
    },
    {
      "Location ID": "08020",
      "Location Name": "CLARKSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 175 W COHAWKIN RD STE B CLARKSBORO NJ 08020 USA "
    },
    {
      "Location ID": "08021",
      "Location Name": "CLEMENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22 BERLIN RD CLEMENTON NJ 08021 USA "
    },
    {
      "Location ID": "08022",
      "Location Name": "COLUMBUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 231 ATLANTIC AVE COLUMBUS NJ 08022 USA "
    },
    {
      "Location ID": "08023",
      "Location Name": "DEEPWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 582 N BROADWAY DEEPWATER NJ 08023 USA "
    },
    {
      "Location ID": "08025",
      "Location Name": "EWAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 310 EWAN RD EWAN NJ 08025 USA "
    },
    {
      "Location ID": "08026",
      "Location Name": "GIBBSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 65 KIRKWOOD RD GIBBSBORO NJ 08026 USA "
    },
    {
      "Location ID": "08027",
      "Location Name": "GIBBSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 414 W BROAD ST GIBBSTOWN NJ 08027 USA "
    },
    {
      "Location ID": "08028",
      "Location Name": "GLASSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 124 E HIGH ST GLASSBORO NJ 08028 USA "
    },
    {
      "Location ID": "08029",
      "Location Name": "GLENDORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 38 6TH AVE GLENDORA NJ 08029 USA "
    },
    {
      "Location ID": "08032",
      "Location Name": "GRENLOCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 CENTRAL AVE GRENLOCH NJ 08032 USA "
    },
    {
      "Location ID": "08036",
      "Location Name": "HAINESPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 BROAD ST HAINESPORT NJ 08036 USA "
    },
    {
      "Location ID": "08037",
      "Location Name": "HAMMONTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 S 3RD ST HAMMONTON NJ 08037 USA "
    },
    {
      "Location ID": "08038",
      "Location Name": "HANCOCKS BRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 LOCUST ISLAND RD HANCOCKS BRIDGE NJ 08038 USA "
    },
    {
      "Location ID": "08039",
      "Location Name": "HARRISONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 830 MULLICA HILL RD HARRISONVILLE NJ 08039 USA "
    },
    {
      "Location ID": "08041",
      "Location Name": "JOBSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2206 SAYLORS POND RD JOBSTOWN NJ 08041 USA "
    },
    {
      "Location ID": "08042",
      "Location Name": "JULIUSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 393 MAIN ST JULIUSTOWN NJ 08042 USA "
    },
    {
      "Location ID": "08043",
      "Location Name": "VOORHEES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 504 CENTENNIAL BLVD VOORHEES NJ 08043 USA "
    },
    {
      "Location ID": "08045",
      "Location Name": "LAWNSIDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 175 WARWICK RD N LAWNSIDE NJ 08045 USA "
    },
    {
      "Location ID": "08046",
      "Location Name": "WILLINGBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 REV DR MARTIN LUTHER KING JR DR WILLINGBORO NJ 08046 USA "
    },
    {
      "Location ID": "08048",
      "Location Name": "LUMBERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 702 MAIN ST LUMBERTON NJ 08048 USA "
    },
    {
      "Location ID": "08049",
      "Location Name": "MAGNOLIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 307 E MONROE AVE MAGNOLIA NJ 08049 USA "
    },
    {
      "Location ID": "08050",
      "Location Name": "MANAHAWKIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 525 E BAY AVE MANAHAWKIN NJ 08050 USA "
    },
    {
      "Location ID": "08051",
      "Location Name": "MANTUA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 MANTUA BLVD MANTUA NJ 08051 USA "
    },
    {
      "Location ID": "08052",
      "Location Name": "MAPLE SHADE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 E MAIN ST MAPLE SHADE NJ 08052 USA "
    },
    {
      "Location ID": "08053",
      "Location Name": "MARLTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 E MAIN ST MARLTON NJ 08053 USA "
    },
    {
      "Location ID": "08055",
      "Location Name": "MEDFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 326 STOKES RD MEDFORD NJ 08055 USA "
    },
    {
      "Location ID": "08056",
      "Location Name": "MICKLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 159 DEMOCRAT RD STE A MICKLETON NJ 08056 USA "
    },
    {
      "Location ID": "08057",
      "Location Name": "MOORESTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 CHESTER AVE STE 1 MOORESTOWN NJ 08057 USA "
    },
    {
      "Location ID": "08059",
      "Location Name": "MOUNT EPHRAIM       NJ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 313 W. KINGS HIGHWAY WEST COLLINGSWO NJ 08059 USA "
    },
    {
      "Location ID": "08060",
      "Location Name": "MOUNT HOLLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 WASHINGTON ST MOUNT HOLLY NJ 08060 USA "
    },
    {
      "Location ID": "08061",
      "Location Name": "MOUNT ROYAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 138 KINGS HWY MOUNT ROYAL NJ 08061 USA "
    },
    {
      "Location ID": "08062",
      "Location Name": "MULLICA HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 BRIDGETON PIKE MULLICA HILL NJ 08062 USA "
    },
    {
      "Location ID": "08063",
      "Location Name": "NATIONAL PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 512 HESSIAN AVE NATIONAL PARK NJ 08063 USA "
    },
    {
      "Location ID": "08064",
      "Location Name": "NEW LISBON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 79 FOUR MILE RD NEW LISBON NJ 08064 USA "
    },
    {
      "Location ID": "08065",
      "Location Name": "PALMYRA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 W BROAD ST PALMYRA NJ 08065 USA "
    },
    {
      "Location ID": "08066",
      "Location Name": "PAULSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1015 N DELAWARE ST PAULSBORO NJ 08066 USA "
    },
    {
      "Location ID": "08067",
      "Location Name": "PEDRICKTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 W MILL ST PEDRICKTOWN NJ 08067 USA "
    },
    {
      "Location ID": "08068",
      "Location Name": "PEMBERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 73 ELIZABETH ST PEMBERTON NJ 08068 USA "
    },
    {
      "Location ID": "08069",
      "Location Name": "PENNS GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 56 W MAIN ST PENNS GROVE NJ 08069 USA "
    },
    {
      "Location ID": "08070",
      "Location Name": "PENNSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 S BROADWAY PENNSVILLE NJ 08070 USA "
    },
    {
      "Location ID": "08071",
      "Location Name": "PITMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 N BROADWAY PITMAN NJ 08071 USA "
    },
    {
      "Location ID": "08072",
      "Location Name": "QUINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 MAIN ST QUINTON NJ 08072 USA "
    },
    {
      "Location ID": "08073",
      "Location Name": "RANCOCAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 MAIN ST RANCOCAS NJ 08073 USA "
    },
    {
      "Location ID": "08074",
      "Location Name": "RICHWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 647 MULLICA HILL RD RICHWOOD NJ 08074 USA "
    },
    {
      "Location ID": "08075",
      "Location Name": "RIVERSIDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 W SCOTT ST RIVERSIDE NJ 08075 USA "
    },
    {
      "Location ID": "08078",
      "Location Name": "RUNNEMEDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 N BLACK HORSE PIKE RUNNEMEDE NJ 08078 USA "
    },
    {
      "Location ID": "08079",
      "Location Name": "SALEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 W BROADWAY SALEM NJ 08079 USA "
    },
    {
      "Location ID": "08080",
      "Location Name": "SEWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 141 CENTER ST SEWELL NJ 08080 USA "
    },
    {
      "Location ID": "08081",
      "Location Name": "SICKLERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 415 SICKLERVILLE RD SICKLERVILLE NJ 08081 USA "
    },
    {
      "Location ID": "08083",
      "Location Name": "SOMERDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 S WHITE HORSE PIKE SOMERDALE NJ 08083 USA "
    },
    {
      "Location ID": "08084",
      "Location Name": "STRATFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 190 WARWICK RD STRATFORD NJ 08084 USA "
    },
    {
      "Location ID": "08085",
      "Location Name": "SWEDESBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1950 KINGS HWY SWEDESBORO NJ 08085 USA "
    },
    {
      "Location ID": "08086",
      "Location Name": "THOROFARE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 CROWN POINT RD UNIT A WEST DEPTFORD NJ 08086 USA "
    },
    {
      "Location ID": "08087",
      "Location Name": "TUCKERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 139 E MAIN ST TUCKERTON NJ 08087 USA "
    },
    {
      "Location ID": "08088",
      "Location Name": "VINCENTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1813 ROUTE 206 SOUTHAMPTON NJ 08088 USA "
    },
    {
      "Location ID": "08089",
      "Location Name": "WATERFORD WORKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 N WHITE HORSE PIKE WATERFORD WORKS NJ 08089 USA "
    },
    {
      "Location ID": "08090",
      "Location Name": "WENONAH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 S WEST AVE WENONAH NJ 08090 USA "
    },
    {
      "Location ID": "08091",
      "Location Name": "WEST BERLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 WALKER AVE WEST BERLIN NJ 08091 USA "
    },
    {
      "Location ID": "08092",
      "Location Name": "WEST CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 194 ROUTE 9 WEST CREEK NJ 08092 USA "
    },
    {
      "Location ID": "08093",
      "Location Name": "WESTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 329 BROADWAY WESTVILLE NJ 08093 USA "
    },
    {
      "Location ID": "08094",
      "Location Name": "WILLIAMSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 135 S BLACK HORSE PIKE WILLIAMSTOWN NJ 08094 USA "
    },
    {
      "Location ID": "08095",
      "Location Name": "WINSLOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 48 HALL ST WINSLOW NJ 08095 USA "
    },
    {
      "Location ID": "08096",
      "Location Name": "WOODBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 N BROAD ST WOODBURY NJ 08096 USA "
    },
    {
      "Location ID": "08098",
      "Location Name": "WOODSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 EAST AVE OFC 1 WOODSTOWN NJ 08098 USA "
    },
    {
      "Location ID": "080AA",
      "Location Name": "AMAZON - ACY5",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2277 CENTER SQUARE RD LOGAN TWP NJ 08085 USA "
    },
    {
      "Location ID": "080AD",
      "Location Name": "DEPTFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1265 HURFFVILLE RD DEPTFORD NJ 08096 USA "
    },
    {
      "Location ID": "080AE",
      "Location Name": "LITTLE EGG HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 MATHISTOWN RD UNIT 20 LITTLE EGG HARBOR TWP NJ 08087 USA "
    },
    {
      "Location ID": "080AF",
      "Location Name": "LONG BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7401 LONG BEACH BLVD LONG BEACH TOWNSHIP NJ 08008 USA "
    },
    {
      "Location ID": "080AI",
      "Location Name": "MOORESTOWN CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 WALT WHITMAN AVE MOUNT LAUREL NJ 08054 USA "
    },
    {
      "Location ID": "080AL",
      "Location Name": "CINNAMINSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2496 ROUTE 130 N CINNAMINSON NJ 08077 USA "
    },
    {
      "Location ID": "080AM",
      "Location Name": "AGILE MEDICAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 413 BLOOMFIELD DR STE 1 WEST BERLIN NJ 08091 USA "
    },
    {
      "Location ID": "080AN",
      "Location Name": "BARTON & COONEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 RICHARDS RUN BURLINGTON NJ 08016 USA "
    },
    {
      "Location ID": "080AR",
      "Location Name": "SQUARE ONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 GAITHER DR STE 104 MOUNT LAUREL NJ 08054 USA "
    },
    {
      "Location ID": "080AW",
      "Location Name": "B&B TOYS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1301 METROPOLITAN AVE STE 5 WEST DEPTFORD NJ 08066 USA "
    },
    {
      "Location ID": "080AX",
      "Location Name": "EXTREME INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 702 KING AVE CHERRY HILL NJ 08002 USA "
    },
    {
      "Location ID": "080BF",
      "Location Name": "SEAMLESS DEVELOPMENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 824 E GATE DR STE D MOUNT LAUREL NJ 08054 USA "
    },
    {
      "Location ID": "080BG",
      "Location Name": "THE FLAG STORE, NJ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 W. COLLING AVE BERLIN NJ 08091 USA "
    },
    {
      "Location ID": "080CO",
      "Location Name": "COSTUMES OF AMERICA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 999 S GRAND ST HAMMONTON NJ 08037 USA "
    },
    {
      "Location ID": "080CS",
      "Location Name": "ICS CORPORATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 FRIARS BLVD WEST DEPTFORD NJ 08086 USA "
    },
    {
      "Location ID": "080CV",
      "Location Name": "CEVA LOGISTICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 ARLINGTON BLVD SWEDESBORO NJ 08085 USA "
    },
    {
      "Location ID": "080CX",
      "Location Name": "BARNEGAT CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 685 E BAY AVE BARNEGAT NJ 08005 USA "
    },
    {
      "Location ID": "080DC",
      "Location Name": "DEPTFORD CARRIERSANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1265 HURFFVILLE RD WOODBURY NJ 08096 USA "
    },
    {
      "Location ID": "080FB",
      "Location Name": "FIREBIRD PRESORT II BURLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 RICHARDS RUN BURLINGTON NJ 08016 USA "
    },
    {
      "Location ID": "080IC",
      "Location Name": "ICS GRANDVIEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1571 GRANDVIEW AVE WEST DEPTFORD NJ 08066 USA "
    },
    {
      "Location ID": "080ML",
      "Location Name": "MOUNT LAUREL CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4000 IRWIN RD MOUNT LAUREL NJ 08054 USA "
    },
    {
      "Location ID": "080MM",
      "Location Name": "EXPRESS SCRIPTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2040 ROUTE 130 BURLINGTON NJ 08016 USA "
    },
    {
      "Location ID": "080MO",
      "Location Name": "BOSCOV'S (MOORESTOWN)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 W ROUTE 38 MOORESTOWN NJ 08057 USA "
    },
    {
      "Location ID": "080MS",
      "Location Name": "MOUNT HOLLY MOTOR SPORTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2044 ROUTE 206 SOUTHAMPTON NJ 08088 USA "
    },
    {
      "Location ID": "080NE",
      "Location Name": "NEON EDGE, INC.",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 COOPER RD STE B4 WEST BERLIN NJ 08091 USA "
    },
    {
      "Location ID": "080QG",
      "Location Name": "QUAD GRAPHICS EAST PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 E PARK DR WESTAMPTON NJ 08060 USA "
    },
    {
      "Location ID": "080RA",
      "Location Name": "RADWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 MILLENIUM DR WILLINGBORO NJ 08046 USA "
    },
    {
      "Location ID": "080RG",
      "Location Name": "RITE GENERAL MERCHANDISE LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 424 COMMERCE LN STE 4 WEST BERLIN NJ 08091 USA "
    },
    {
      "Location ID": "080RV",
      "Location Name": "REVZILLA - LAWNSIDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 141 WALTER A GAINES WAY LAWNSIDE NJ 08045 USA "
    },
    {
      "Location ID": "080SR",
      "Location Name": "STERIGENICS RADIATION TECHNOLOGIES LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 303 HERON DR SWEDESBORO NJ 08085 USA "
    },
    {
      "Location ID": "080TP",
      "Location Name": "TOWNSEND PRESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 439 KELLY DR WEST BERLIN NJ 08091 USA "
    },
    {
      "Location ID": "080TV",
      "Location Name": "TURNERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 425 HURFFVILLE CROSS KEYS RD BLACKWOOD NJ 08012 USA "
    },
    {
      "Location ID": "080WC",
      "Location Name": "WALMART SORT CENTER  - NJ9",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 WESTERN DR MOUNT HOLLY NJ 08060 USA "
    },
    {
      "Location ID": "080WT",
      "Location Name": "WALMART - SORBELLO RD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 SORBELLO RD BLDG 1 PEDRICKTOWN NJ 08067 USA "
    },
    {
      "Location ID": "081AA",
      "Location Name": "CAMDEN CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2700 MOUNT EPHRAIM AVE CAMDEN NJ 08104 USA "
    },
    {
      "Location ID": "08201",
      "Location Name": "ABSECON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1001 NEW JERSEY AVE ABSECON NJ 08201 USA "
    },
    {
      "Location ID": "08202",
      "Location Name": "AVALON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3219 DUNE DR AVALON NJ 08202 USA "
    },
    {
      "Location ID": "08203",
      "Location Name": "BRIGANTINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4326 HARBOR BEACH BLVD BRIGANTINE NJ 08203 USA "
    },
    {
      "Location ID": "08204",
      "Location Name": "CAPE MAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 700 WASHINGTON ST CAPE MAY NJ 08204 USA "
    },
    {
      "Location ID": "08210",
      "Location Name": "CAPE MAY COURT HOUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 615 ROUTE 9 S CAPE MAY COURT HOUSE NJ 08210 USA "
    },
    {
      "Location ID": "08213",
      "Location Name": "COLOGNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 807 W WHITE HORSE PIKE COLOGNE NJ 08213 USA "
    },
    {
      "Location ID": "08214",
      "Location Name": "DENNISVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 HALL AVE DENNISVILLE NJ 08214 USA "
    },
    {
      "Location ID": "08215",
      "Location Name": "EGG HARBOR CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 237 PHILADELPHIA AVE EGG HARBOR CITY NJ 08215 USA "
    },
    {
      "Location ID": "08217",
      "Location Name": "ELWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4401 WHITE HORSE PIKE ELWOOD NJ 08217 USA "
    },
    {
      "Location ID": "08219",
      "Location Name": "GREEN CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 501 ROUTE 47 S GREEN CREEK NJ 08219 USA "
    },
    {
      "Location ID": "08220",
      "Location Name": "LEEDS POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 S LEEDS POINT RD LEEDS POINT NJ 08220 USA "
    },
    {
      "Location ID": "08221",
      "Location Name": "LINWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1103 SHORE RD LINWOOD NJ 08221 USA "
    },
    {
      "Location ID": "08223",
      "Location Name": "MARMORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 TUCKAHOE RD MARMORA NJ 08223 USA "
    },
    {
      "Location ID": "08224",
      "Location Name": "NEW GRETNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5624 ROUTE 9 NEW GRETNA NJ 08224 USA "
    },
    {
      "Location ID": "08225",
      "Location Name": "NORTHFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 800 NEW RD NORTHFIELD NJ 08225 USA "
    },
    {
      "Location ID": "08226",
      "Location Name": "OCEAN CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 859 OCEAN AVE OCEAN CITY NJ 08226 USA "
    },
    {
      "Location ID": "08230",
      "Location Name": "OCEAN VIEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 556 SEA ISLE BLVD UNIT H OCEAN VIEW NJ 08230 USA "
    },
    {
      "Location ID": "08231",
      "Location Name": "OCEANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 174 S NEW YORK AVE OCEANVILLE NJ 08231 USA "
    },
    {
      "Location ID": "08234",
      "Location Name": "SOUTH JERSEY SHORE DDC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 COLUMBUS AVE EGG HARBOR TOWN NJ 08234 USA "
    },
    {
      "Location ID": "08240",
      "Location Name": "POMONA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 269 W WHITE HORSE PIKE POMONA NJ 08240 USA "
    },
    {
      "Location ID": "08242",
      "Location Name": "RIO GRANDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1087 ROUTE 47 S RIO GRANDE NJ 08242 USA "
    },
    {
      "Location ID": "08243",
      "Location Name": "SEA ISLE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 145 JOHN F KENNEDY BLVD SEA ISLE CITY NJ 08243 USA "
    },
    {
      "Location ID": "08244",
      "Location Name": "SOMERS POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 325 W GROVELAND AVE SOMERS POINT NJ 08244 USA "
    },
    {
      "Location ID": "08245",
      "Location Name": "SOUTH DENNIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 231 COUNTY RD SOUTH DENNIS NJ 08245 USA "
    },
    {
      "Location ID": "08246",
      "Location Name": "SOUTH SEAVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 493 KINGS HWY SOUTH SEAVILLE NJ 08246 USA "
    },
    {
      "Location ID": "08247",
      "Location Name": "STONE HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9601 2ND AVE STONE HARBOR NJ 08247 USA "
    },
    {
      "Location ID": "08248",
      "Location Name": "STRATHMERE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 COMMONWEALTH AVE STRATHMERE NJ 08248 USA "
    },
    {
      "Location ID": "08250",
      "Location Name": "TUCKAHOE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2250 ROUTE 50 TUCKAHOE NJ 08250 USA "
    },
    {
      "Location ID": "08251",
      "Location Name": "VILLAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1814 BAYSHORE RD STE 1 VILLAS NJ 08251 USA "
    },
    {
      "Location ID": "08252",
      "Location Name": "WHITESBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 E MAIN ST WHITESBORO NJ 08252 USA "
    },
    {
      "Location ID": "08260",
      "Location Name": "WILDWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3311 ATLANTIC AVE WILDWOOD NJ 08260 USA "
    },
    {
      "Location ID": "08270",
      "Location Name": "WOODBINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 642 WASHINGTON AVE WOODBINE NJ 08270 USA "
    },
    {
      "Location ID": "082AB",
      "Location Name": "PLEASANTVILLE CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 COLUMBUS AVE EGG HARBOR TOWNSHIP NJ 08234 USA "
    },
    {
      "Location ID": "082BC",
      "Location Name": "BLUE CHIP FULFILLMENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6595 DELILAH RD EGG HARBOR TOWNSHIP NJ 08234 USA "
    },
    {
      "Location ID": "082BS",
      "Location Name": "BOSCOV'S SHORE MALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6725 BLACK HORSE PIKE EGG HBR TWP NJ 08234 USA "
    },
    {
      "Location ID": "082FD",
      "Location Name": "FDI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6805 DELILAH RD EGG HARBOR TOWN NJ 08234 USA "
    },
    {
      "Location ID": "082TD",
      "Location Name": "TACKLE DIRECT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6825 TILTON RD STE C EGG HARBOR TOWN NJ 08234 USA "
    },
    {
      "Location ID": "082TW",
      "Location Name": "TOP WIRELESS (EGG HARBOR TWP)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6579 DELILAH RD EGG HBR TWP NJ 08234 USA "
    },
    {
      "Location ID": "08302",
      "Location Name": "BRIDGETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 W BROAD ST BRIDGETON NJ 08302 USA "
    },
    {
      "Location ID": "08311",
      "Location Name": "CEDARVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 343 MAIN ST CEDARVILLE NJ 08311 USA "
    },
    {
      "Location ID": "08312",
      "Location Name": "CLAYTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 732 N DELSEA DR CLAYTON NJ 08312 USA "
    },
    {
      "Location ID": "08313",
      "Location Name": "DEERFIELD STREET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1542 ROUTE 77 DEERFIELD STREET NJ 08313 USA "
    },
    {
      "Location ID": "08314",
      "Location Name": "DELMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4507 ROUTE 47 DELMONT NJ 08314 USA "
    },
    {
      "Location ID": "08315",
      "Location Name": "DIVIDING CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 918 UNION ST DIVIDING CREEK NJ 08315 USA "
    },
    {
      "Location ID": "08316",
      "Location Name": "DORCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 239 CARLISLE PLACE RD DORCHESTER NJ 08316 USA "
    },
    {
      "Location ID": "08318",
      "Location Name": "ELMER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 625 N MAIN ST ELMER NJ 08318 USA "
    },
    {
      "Location ID": "08320",
      "Location Name": "FAIRTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 56 BRIDGETON FAIRTON RD FAIRTON NJ 08320 USA "
    },
    {
      "Location ID": "08321",
      "Location Name": "FORTESCUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 880 DOWNE AVE FORTESCUE NJ 08321 USA "
    },
    {
      "Location ID": "08322",
      "Location Name": "FRANKLINVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2387 DELSEA DR FRANKLINVILLE NJ 08322 USA "
    },
    {
      "Location ID": "08323",
      "Location Name": "GREENWICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1016 YE GREATE ST GREENWICH NJ 08323 USA "
    },
    {
      "Location ID": "08324",
      "Location Name": "HEISLERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 222 MAIN ST HEISLERVILLE NJ 08324 USA "
    },
    {
      "Location ID": "08327",
      "Location Name": "LEESBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 174 HIGH ST LEESBURG NJ 08327 USA "
    },
    {
      "Location ID": "08328",
      "Location Name": "MALAGA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 603 DELSEA DR MALAGA NJ 08328 USA "
    },
    {
      "Location ID": "08329",
      "Location Name": "MAURICETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9572 HIGHLAND ST MAURICETOWN NJ 08329 USA "
    },
    {
      "Location ID": "08330",
      "Location Name": "MAYS LANDING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5306 ATLANTIC AVE MAYS LANDING NJ 08330 USA "
    },
    {
      "Location ID": "08332",
      "Location Name": "MILLVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 302 HIGH ST N MILLVILLE NJ 08332 USA "
    },
    {
      "Location ID": "08343",
      "Location Name": "MONROEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 403 MONROEVILLE RD MONROEVILLE NJ 08343 USA "
    },
    {
      "Location ID": "08344",
      "Location Name": "NEWFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 N WEST BLVD NEWFIELD NJ 08344 USA "
    },
    {
      "Location ID": "08345",
      "Location Name": "NEWPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 304 BAPTIST RD NEWPORT NJ 08345 USA "
    },
    {
      "Location ID": "08347",
      "Location Name": "NORMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 809 GERSHAL AVE NORMA NJ 08347 USA "
    },
    {
      "Location ID": "08348",
      "Location Name": "PORT ELIZABETH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3575 ROUTE 47 PORT ELIZABETH NJ 08348 USA "
    },
    {
      "Location ID": "08349",
      "Location Name": "PORT NORRIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1770 MAIN ST PORT NORRIS NJ 08349 USA "
    },
    {
      "Location ID": "08352",
      "Location Name": "ROSENHAYN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 522 MORTON AVE ROSENHAYN NJ 08352 USA "
    },
    {
      "Location ID": "08353",
      "Location Name": "SHILOH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 966 MAIN ST SHILOH NJ 08353 USA "
    },
    {
      "Location ID": "083RS",
      "Location Name": "VINELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 722 E LANDIS AVE VINELAND NJ 08360 USA "
    },
    {
      "Location ID": "083VA",
      "Location Name": "VINELAND CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1539 ALMOND RD VINELAND NJ 08360 USA "
    },
    {
      "Location ID": "08401",
      "Location Name": "ATLANTIC CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1801 ATLANTIC AVE STE 101 ATLANTIC CITY NJ 08401 USA "
    },
    {
      "Location ID": "08402",
      "Location Name": "MARGATE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 S FRANKLIN AVE MARGATE CITY NJ 08402 USA "
    },
    {
      "Location ID": "08403",
      "Location Name": "LONGPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 S 27TH AVE STE 2 LONGPORT NJ 08403 USA "
    },
    {
      "Location ID": "08405",
      "Location Name": "N A F E C",
      "Time Zone Offset (Hours)": 0,
      "Address": " ATLANTIC CITY INTERNATIONAL AIRPORT ATLANTIC CITY NJ 08405 USA "
    },
    {
      "Location ID": "08406",
      "Location Name": "VENTNOR CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5006 WELLINGTON AVE VENTNOR CITY NJ 08406 USA "
    },
    {
      "Location ID": "084AA",
      "Location Name": "ATLANTIC CITY CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 800 ABSECON BLVD ATLANTIC CITY NJ 08401 USA "
    },
    {
      "Location ID": "084ES",
      "Location Name": "ELEVATED STYLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1420 ATLANTIC AVE ATLANTIC CITY NJ 08401 USA "
    },
    {
      "Location ID": "085",
      "Location Name": "LOG TRENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 680 US HIGHWAY 130 TRENTON NJ 08650 USA "
    },
    {
      "Location ID": "08502",
      "Location Name": "BELLE MEAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2468 US HIGHWAY 206 BELLE MEAD NJ 08502 USA "
    },
    {
      "Location ID": "08504",
      "Location Name": "BLAWENBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1693 GREAT RD BLAWENBURG NJ 08504 USA "
    },
    {
      "Location ID": "08511",
      "Location Name": "COOKSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 WRIGHTSTOWN COOKSTOWN RD COOKSTOWN NJ 08511 USA "
    },
    {
      "Location ID": "08514",
      "Location Name": "CREAM RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 163 BURLINGTON PATH RD STE D CREAM RIDGE NJ 08514 USA "
    },
    {
      "Location ID": "08525",
      "Location Name": "HOPEWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 S GREENWOOD AVE HOPEWELL NJ 08525 USA "
    },
    {
      "Location ID": "08527",
      "Location Name": "JACKSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 915 BENNETTS MILLS RD JACKSON NJ 08527 USA "
    },
    {
      "Location ID": "08528",
      "Location Name": "KINGSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4599 ROUTE 27 KINGSTON NJ 08528 USA "
    },
    {
      "Location ID": "08530",
      "Location Name": "LAMBERTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 YORK ST LAMBERTVILLE NJ 08530 USA "
    },
    {
      "Location ID": "08533",
      "Location Name": "NEW EGYPT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22 N MAIN ST NEW EGYPT NJ 08533 USA "
    },
    {
      "Location ID": "08534",
      "Location Name": "PENNINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 BROEMEL PL PENNINGTON NJ 08534 USA "
    },
    {
      "Location ID": "08536",
      "Location Name": "PLAINSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 SCHALKS CROSSING RD PLAINSBORO NJ 08536 USA "
    },
    {
      "Location ID": "08540",
      "Location Name": "PRINCETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 213 CARNEGIE CTR PRINCETON NJ 08540 USA "
    },
    {
      "Location ID": "08550",
      "Location Name": "PRINCETON JUNCTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 331 N POST RD PRINCETON JUNCTION NJ 08550 USA "
    },
    {
      "Location ID": "08551",
      "Location Name": "RINGOES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1108 OLD YORK RD RINGOES NJ 08551 USA "
    },
    {
      "Location ID": "08553",
      "Location Name": "ROCKY HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 WASHINGTON ST ROCKY HILL NJ 08553 USA "
    },
    {
      "Location ID": "08556",
      "Location Name": "ROSEMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 99 KINGWOOD STOCKTON RD ROSEMONT NJ 08556 USA "
    },
    {
      "Location ID": "08558",
      "Location Name": "SKILLMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 950 ROUTE 601 SKILLMAN NJ 08558 USA "
    },
    {
      "Location ID": "08559",
      "Location Name": "STOCKTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 BRIDGE ST STOCKTON NJ 08559 USA "
    },
    {
      "Location ID": "08560",
      "Location Name": "TITUSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1380 RIVER RD TITUSVILLE NJ 08560 USA "
    },
    {
      "Location ID": "08561",
      "Location Name": "WINDSOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 S MAIN ST WINDSOR NJ 08561 USA "
    },
    {
      "Location ID": "08562",
      "Location Name": "WRIGHTSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 E MAIN ST WRIGHTSTOWN NJ 08562 USA "
    },
    {
      "Location ID": "085AN",
      "Location Name": "TRENTON NJ (ANNEX)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 435 US HIGHWAY 130 TRENTON NJ 08620 USA "
    },
    {
      "Location ID": "085GL",
      "Location Name": "GLOBAL M2C LTD (CRANBURY)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 MELRICH RD CRANBURY NJ 08512 USA "
    },
    {
      "Location ID": "085JB",
      "Location Name": "GREAT JONES BOOKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 ROUTE 31 S PENNINGTON NJ 08534 USA "
    },
    {
      "Location ID": "085LD",
      "Location Name": "LINDA'S DIET DELIGHTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 176 US HIGHWAY 202 UNIT 2 RINGOES NJ 08551 USA "
    },
    {
      "Location ID": "085PF",
      "Location Name": "PRO FLOWER CRANBURY NJ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 MELRICH RD STE 1 CRANBURY NJ 08512 USA "
    },
    {
      "Location ID": "08641",
      "Location Name": "MC GUIRE AFB",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2907 TUSKEGEE AIRMEN AVE JOINT BASE MDL NJ 08641 USA "
    },
    {
      "Location ID": "08701",
      "Location Name": "LAKEWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1820 SWARTHMORE AVE LAKEWOOD NJ 08701 USA "
    },
    {
      "Location ID": "08720",
      "Location Name": "ALLENWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3212 ALLENWOOD LAKEWOOD RD ALLENWOOD NJ 08720 USA "
    },
    {
      "Location ID": "08721",
      "Location Name": "BAYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 801 ATLANTIC CITY BLVD BAYVILLE NJ 08721 USA "
    },
    {
      "Location ID": "08722",
      "Location Name": "BEACHWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 74 LOCKER ST BEACHWOOD NJ 08722 USA "
    },
    {
      "Location ID": "08723",
      "Location Name": "BRICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 160 CHAMBERS BRIDGE RD BRICK NJ 08723 USA "
    },
    {
      "Location ID": "08730",
      "Location Name": "BRIELLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 412 HIGGINS AVE BRIELLE NJ 08730 USA "
    },
    {
      "Location ID": "08731",
      "Location Name": "FORKED RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 610 LACEY RD FORKED RIVER NJ 08731 USA "
    },
    {
      "Location ID": "08732",
      "Location Name": "ISLAND HEIGHTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 WANAMAKER MUNICIPAL COMPLEX ISLAND HEIGHTS NJ 08732 USA "
    },
    {
      "Location ID": "08733",
      "Location Name": "LAKEHURST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 BROWN AVE LAKEHURST NJ 08733 USA "
    },
    {
      "Location ID": "08734",
      "Location Name": "LANOKA HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 402 US HIGHWAY 9 LANOKA HARBOR NJ 08734 USA "
    },
    {
      "Location ID": "08735",
      "Location Name": "LAVALLETTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1302 GRAND CENTRAL AVE LAVALLETTE NJ 08735 USA "
    },
    {
      "Location ID": "08736",
      "Location Name": "MANASQUAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 43 ABE VOORHEES DR MANASQUAN NJ 08736 USA "
    },
    {
      "Location ID": "08738",
      "Location Name": "MANTOLOKING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 207 DOWNER AVE MANTOLOKING NJ 08738 USA "
    },
    {
      "Location ID": "08739",
      "Location Name": "NORMANDY BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 551 STATE ROUTE 35 N NORMANDY BEACH NJ 08739 USA "
    },
    {
      "Location ID": "08740",
      "Location Name": "OCEAN GATE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 450 OCEAN GATE AVE OCEAN GATE NJ 08740 USA "
    },
    {
      "Location ID": "08741",
      "Location Name": "PINE BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 601 PENNSYLVANIA AVE PINE BEACH NJ 08741 USA "
    },
    {
      "Location ID": "08742",
      "Location Name": "POINT PLEASANT BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 410 ARNOLD AVE POINT PLEASANT BEACH NJ 08742 USA "
    },
    {
      "Location ID": "08750",
      "Location Name": "SEA GIRT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 800 THE PLZ STE 10 SEA GIRT NJ 08750 USA "
    },
    {
      "Location ID": "08751",
      "Location Name": "SEASIDE HEIGHTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 SUMNER AVE SEASIDE HEIGHTS NJ 08751 USA "
    },
    {
      "Location ID": "08752",
      "Location Name": "SEASIDE PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 900 SE CENTRAL AVE SEASIDE PARK NJ 08752 USA "
    },
    {
      "Location ID": "08753",
      "Location Name": "TOMS RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 W WATER ST TOMS RIVER NJ 08753 USA "
    },
    {
      "Location ID": "08758",
      "Location Name": "WARETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 529 ROUTE 9 STE 17 WARETOWN NJ 08758 USA "
    },
    {
      "Location ID": "087AC",
      "Location Name": "AVACARE MEDICAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1655 CORPORATE RD W LAKEWOOD NJ 08701 USA "
    },
    {
      "Location ID": "087BB",
      "Location Name": "POINT PLEASANT BORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2229 BRIDGE AVE POINT PLEASANT BORO NJ 08742 USA "
    },
    {
      "Location ID": "087BR",
      "Location Name": "BUYRITE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 OBERLIN AVE N STE 12 LAKEWOOD NJ 08701 USA "
    },
    {
      "Location ID": "087CA",
      "Location Name": "COLLECTORS ALLIANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1942 SWARTHMORE AVE LAKEWOOD NJ 08701 USA "
    },
    {
      "Location ID": "087CW",
      "Location Name": "CWR WHOLESALE DISTRIBUTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 BUTLER AVE BAYVILLE NJ 08721 USA "
    },
    {
      "Location ID": "087FU",
      "Location Name": "FULFILLRITE E-COMMERCE ORDER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 687 PROSPECT ST LAKEWOOD NJ 08701 USA "
    },
    {
      "Location ID": "087LD",
      "Location Name": "LIJO DECOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1985 SWARTHMORE AVE LAKEWOOD NJ 08701 USA "
    },
    {
      "Location ID": "087PC",
      "Location Name": "PREMIUM CHOCOLATIER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 170 OBERLIN AVE N LAKEWOOD NJ 08701 USA "
    },
    {
      "Location ID": "088",
      "Location Name": "KILMER CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 KILMER RD EDISON NJ 08899 USA "
    },
    {
      "Location ID": "08801",
      "Location Name": "ANNANDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 LOWER WEST ST ANNANDALE NJ 08801 USA "
    },
    {
      "Location ID": "08802",
      "Location Name": "ASBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 285 ASBURY ANDERSON RD ASBURY NJ 08802 USA "
    },
    {
      "Location ID": "08804",
      "Location Name": "BLOOMSBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 53 CENTER ST BLOOMSBURY NJ 08804 USA "
    },
    {
      "Location ID": "08805",
      "Location Name": "BOUND BROOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 MOUNTAIN AVE BOUND BROOK NJ 08805 USA "
    },
    {
      "Location ID": "08807",
      "Location Name": "BRIDGEWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 495 N BRIDGE ST BRIDGEWATER NJ 08807 USA "
    },
    {
      "Location ID": "08808",
      "Location Name": "BROADWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 ASBURY RD BROADWAY NJ 08808 USA "
    },
    {
      "Location ID": "08809",
      "Location Name": "CLINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 E MAIN ST CLINTON NJ 08809 USA "
    },
    {
      "Location ID": "08810",
      "Location Name": "DAYTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 347 GEORGES RD DAYTON NJ 08810 USA "
    },
    {
      "Location ID": "08812",
      "Location Name": "DUNELLEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 311 NORTH AVE DUNELLEN NJ 08812 USA "
    },
    {
      "Location ID": "08816",
      "Location Name": "EAST BRUNSWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 614 CRANBURY RD EAST BRUNSWICK NJ 08816 USA "
    },
    {
      "Location ID": "08821",
      "Location Name": "FLAGTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 223 S BRANCH RD FLAGTOWN NJ 08821 USA "
    },
    {
      "Location ID": "08824",
      "Location Name": "KENDALL PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3124 STATE ROUTE 27 KENDALL PARK NJ 08824 USA "
    },
    {
      "Location ID": "08826",
      "Location Name": "GLEN GARDNER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 64 MAIN ST GLEN GARDNER NJ 08826 USA "
    },
    {
      "Location ID": "08827",
      "Location Name": "HAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 77 FOSS AVE HAMPTON NJ 08827 USA "
    },
    {
      "Location ID": "08828",
      "Location Name": "HELMETTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 68 MAIN ST HELMETTA NJ 08828 USA "
    },
    {
      "Location ID": "08829",
      "Location Name": "HIGH BRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 MCDONALD ST HIGH BRIDGE NJ 08829 USA "
    },
    {
      "Location ID": "08831",
      "Location Name": "MONROE TOWNSHIP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1601 PERRINEVILLE RD MONROE TOWNSHIP NJ 08831 USA "
    },
    {
      "Location ID": "08832",
      "Location Name": "KEASBEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 140 SMITH ST STE 1 KEASBEY NJ 08832 USA "
    },
    {
      "Location ID": "08833",
      "Location Name": "LEBANON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1271 US HIGHWAY 22 STE A LEBANON NJ 08833 USA "
    },
    {
      "Location ID": "08835",
      "Location Name": "MANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 BROOKS BLVD MANVILLE NJ 08835 USA "
    },
    {
      "Location ID": "08836",
      "Location Name": "MARTINSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1990 WASHINGTON VALLEY RD MARTINSVILLE NJ 08836 USA "
    },
    {
      "Location ID": "08844",
      "Location Name": "HILLSBOROUGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 437 AMWELL RD HILLSBOROUGH NJ 08844 USA "
    },
    {
      "Location ID": "08846",
      "Location Name": "MIDDLESEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 95 MARLBOROUGH AVE MIDDLESEX NJ 08846 USA "
    },
    {
      "Location ID": "08850",
      "Location Name": "MILLTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 WASHINGTON AVE MILLTOWN NJ 08850 USA "
    },
    {
      "Location ID": "08852",
      "Location Name": "MONMOUTH JUNCTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 651 RIDGE RD MONMOUTH JUNCTION NJ 08852 USA "
    },
    {
      "Location ID": "08853",
      "Location Name": "NESHANIC STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 MAIN ST NESHANIC STATION NJ 08853 USA "
    },
    {
      "Location ID": "08854",
      "Location Name": "PISCATAWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 SKILES AVE PISCATAWAY NJ 08854 USA "
    },
    {
      "Location ID": "08857",
      "Location Name": "OLD BRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 151 HIGHWAY 516 OLD BRIDGE NJ 08857 USA "
    },
    {
      "Location ID": "08858",
      "Location Name": "OLDWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 174 LAMINGTON RD OLDWICK NJ 08858 USA "
    },
    {
      "Location ID": "08859",
      "Location Name": "PARLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 778 WASHINGTON RD PARLIN NJ 08859 USA "
    },
    {
      "Location ID": "08861",
      "Location Name": "PERTH AMBOY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 JEFFERSON ST PERTH AMBOY NJ 08861 USA "
    },
    {
      "Location ID": "08865",
      "Location Name": "PHILLIPSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 361 MEMORIAL PKWY PHILLIPSBURG NJ 08865 USA "
    },
    {
      "Location ID": "08867",
      "Location Name": "PITTSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 313 PITTSTOWN RD PITTSTOWN NJ 08867 USA "
    },
    {
      "Location ID": "08868",
      "Location Name": "QUAKERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 289 WHITEBRIDGE RD QUAKERTOWN NJ 08868 USA "
    },
    {
      "Location ID": "08869",
      "Location Name": "RARITAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 E SOMERSET ST RARITAN NJ 08869 USA "
    },
    {
      "Location ID": "08870",
      "Location Name": "READINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 READINGTON RD BLDG 2 READINGTON NJ 08870 USA "
    },
    {
      "Location ID": "08872",
      "Location Name": "SAYREVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90 MAIN ST SAYREVILLE NJ 08872 USA "
    },
    {
      "Location ID": "08873",
      "Location Name": "SOMERSET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 DEMOTT LN SOMERSET NJ 08873 USA "
    },
    {
      "Location ID": "08876",
      "Location Name": "SOMERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 DIVISION ST SOMERVILLE NJ 08876 USA "
    },
    {
      "Location ID": "08879",
      "Location Name": "SOUTH AMBOY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 S BROADWAY SOUTH AMBOY NJ 08879 USA "
    },
    {
      "Location ID": "08880",
      "Location Name": "SOUTH BOUND BROOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 MADISON ST SOUTH BOUND BROOK NJ 08880 USA "
    },
    {
      "Location ID": "08882",
      "Location Name": "SOUTH RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44 OBERT ST SOUTH RIVER NJ 08882 USA "
    },
    {
      "Location ID": "08884",
      "Location Name": "SPOTSWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 436 MAIN ST SPOTSWOOD NJ 08884 USA "
    },
    {
      "Location ID": "08885",
      "Location Name": "STANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 STANTON MOUNTAIN RD STANTON NJ 08885 USA "
    },
    {
      "Location ID": "08886",
      "Location Name": "STEWARTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 731 STATE ROUTE 57 STEWARTSVILLE NJ 08886 USA "
    },
    {
      "Location ID": "08888",
      "Location Name": "WHITEHOUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 366 US HIGHWAY 22 WHITEHOUSE NJ 08888 USA "
    },
    {
      "Location ID": "08889",
      "Location Name": "WHITEHOUSE STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 273 MAIN ST WHITEHOUSE STATION NJ 08889 USA "
    },
    {
      "Location ID": "088AM",
      "Location Name": "A.M. BEST CO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 AMBEST RD OLDWICK NJ 08858 USA "
    },
    {
      "Location ID": "088AN",
      "Location Name": "BRAINY BORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 195 CENTRAL AVE METUCHEN NJ 08840 USA "
    },
    {
      "Location ID": "088AO",
      "Location Name": "ALLEN GROUP / ARNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 READINGTON RD BRANCHBURG NJ 08876 USA "
    },
    {
      "Location ID": "088AZ",
      "Location Name": "AMAZON - LGA5 - EDISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2205 STATE ROUTE 27 EDISON NJ 08817 USA "
    },
    {
      "Location ID": "088BO",
      "Location Name": "BASIC OUTFITTERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10-A SIGLE LN DAYTON NJ 08810 USA "
    },
    {
      "Location ID": "088EG",
      "Location Name": "ECOM GROUP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3775 PARK AVE UNIT 3 EDISON NJ 08820 USA "
    },
    {
      "Location ID": "088KG",
      "Location Name": "KENCO GROUP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2B S MIDDLESEX AVE MONROE TOWNSHIP NJ 08831 USA "
    },
    {
      "Location ID": "088KI",
      "Location Name": "KENNEDY INTERNATIONAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11-A CORN RD DAYTON NJ 08810 USA "
    },
    {
      "Location ID": "088KU",
      "Location Name": "KUBRA DATA TRANSFER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 KNOX DR PISCATAWAY NJ 08854 USA "
    },
    {
      "Location ID": "088LL",
      "Location Name": "Y'ALL-2",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10-B SIGLE LN DAYTON NJ 08810 USA "
    },
    {
      "Location ID": "088LO",
      "Location Name": "L'OCCITAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 HERROD BLVD DAYTON NJ 08810 USA "
    },
    {
      "Location ID": "088MA",
      "Location Name": "MOMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5D BRICK PLANT RD SOUTH RIVER NJ 08882 USA "
    },
    {
      "Location ID": "088MC",
      "Location Name": "MY CASE DAZZLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 46 COLONIAL RD PISCATAWAY NJ 08854 USA "
    },
    {
      "Location ID": "088MO",
      "Location Name": "MOULTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 DOCKS CORNER RD SUITE 211 DAYTON NJ 08810 USA "
    },
    {
      "Location ID": "088MT",
      "Location Name": "MORET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 285 RIDGE RD DAYTON NJ 08810 USA "
    },
    {
      "Location ID": "088NY",
      "Location Name": "NEW YORK LIFE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 COKESBURY RD LEBANON NJ 08833 USA "
    },
    {
      "Location ID": "088PX",
      "Location Name": "4-PX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 HIGH ST PERTH AMBOY NJ 08861 USA "
    },
    {
      "Location ID": "088RP",
      "Location Name": "RAW PRODICTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 HARTLE ST SAYREVILLE NJ 08872 USA "
    },
    {
      "Location ID": "088SS",
      "Location Name": "SIF SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2A S MIDDLESEX AVE MONROE TOWNSHIP NJ 08831 USA "
    },
    {
      "Location ID": "088WS",
      "Location Name": "WILLIAMS SONOMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 340 MIDDLESEX CENTER BLVD MONROE TOWNSHIP NJ 08831 USA "
    },
    {
      "Location ID": "088YA",
      "Location Name": "Y'ALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 DOCKS CORNER RD SUITE 221 DAYTON NJ 08810 USA "
    },
    {
      "Location ID": "089",
      "Location Name": "KILMER P&D CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 KILMER ROAD EDISON NJ 08899 USA "
    },
    {
      "Location ID": "08901",
      "Location Name": "NEW BRUNSWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 86 BAYARD ST NEW BRUNSWICK NJ 08901 USA "
    },
    {
      "Location ID": "089YA",
      "Location Name": "YALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1980 US HIGHWAY 1 N BRUNSWICK NJ 08902 USA "
    },
    {
      "Location ID": "08MTE",
      "Location Name": "PHILADELPHIA MTESC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 COMMERCE DR BLDG 1 BARRINGTON NJ 08007 USA "
    },
    {
      "Location ID": "100",
      "Location Name": "LOG NEW YORK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 341 9TH AVE RM 918 NEW YORK NY 10199 USA "
    },
    {
      "Location ID": "10016",
      "Location Name": "MURRAY HILL ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 E 36TH ST NEW YORK NY 10016 USA "
    },
    {
      "Location ID": "100AJ",
      "Location Name": "MORNINGSIDE ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 365 W 125TH ST STE 2B NEW YORK NY 10027 USA "
    },
    {
      "Location ID": "100AK",
      "Location Name": "PRINCE ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 350 CANAL ST STE 2B NEW YORK NY 10013 USA "
    },
    {
      "Location ID": "103",
      "Location Name": "STATEN ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 550 MANOR RD STE 1 STATEN ISLAND NY 10314 USA "
    },
    {
      "Location ID": "103SS",
      "Location Name": "SOUTH SHORE ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3031 VETERANS RD W STATEN ISLAND NY 10309 USA "
    },
    {
      "Location ID": "105",
      "Location Name": "LOG WESTCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 WESTCHESTER AVE WHITE PLAINS NY 10610 USA "
    },
    {
      "Location ID": "10501",
      "Location Name": "AMAWALK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 MAHOPAC AVE AMAWALK NY 10501 USA "
    },
    {
      "Location ID": "10502",
      "Location Name": "ARDSLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 875 SAW MILL RIVER RD STE 4 ARDSLEY NY 10502 USA "
    },
    {
      "Location ID": "10503",
      "Location Name": "ARDSLEY ON HUDSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 ARDSLEY AVE W ARDSLEY ON HUDSON NY 10503 USA "
    },
    {
      "Location ID": "10504",
      "Location Name": "ARMONK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 403 MAIN ST ARMONK NY 10504 USA "
    },
    {
      "Location ID": "10505",
      "Location Name": "BALDWIN PLACE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 167 ROUTE 6 MAHOPAC NY 10541 USA "
    },
    {
      "Location ID": "10506",
      "Location Name": "BEDFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 VILLAGE GRN BEDFORD NY 10506 USA "
    },
    {
      "Location ID": "10507",
      "Location Name": "BEDFORD HILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 ADAMS ST BEDFORD HILLS NY 10507 USA "
    },
    {
      "Location ID": "10509",
      "Location Name": "BREWSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 MOUNT EBO RD N BREWSTER NY 10509 USA "
    },
    {
      "Location ID": "10510",
      "Location Name": "BRIARCLIFF MANOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1077 PLEASANTVILLE RD BRIARCLIFF MANOR NY 10510 USA "
    },
    {
      "Location ID": "10511",
      "Location Name": "BUCHANAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 252 TATE AVE BUCHANAN NY 10511 USA "
    },
    {
      "Location ID": "10512",
      "Location Name": "CARMEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 FAIR ST CARMEL NY 10512 USA "
    },
    {
      "Location ID": "10514",
      "Location Name": "CHAPPAQUA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 N GREELEY AVE CHAPPAQUA NY 10514 USA "
    },
    {
      "Location ID": "10516",
      "Location Name": "COLD SPRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 JULIA LN COLD SPRING NY 10516 USA "
    },
    {
      "Location ID": "10517",
      "Location Name": "CROMPOND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 BARON DE HIRSCH RD CROMPOND NY 10517 USA "
    },
    {
      "Location ID": "10518",
      "Location Name": "CROSS RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 CROSS RIVER SHOPPING CTR STE H CROSS RIVER NY 10518 USA "
    },
    {
      "Location ID": "10519",
      "Location Name": "CROTON FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 FRONT ST CROTON FALLS NY 10519 USA "
    },
    {
      "Location ID": "10520",
      "Location Name": "CROTON ON HUDSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 MAPLE ST CROTON ON HUDSON NY 10520 USA "
    },
    {
      "Location ID": "10522",
      "Location Name": "DOBBS FERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 MAIN ST DOBBS FERRY NY 10522 USA "
    },
    {
      "Location ID": "10523",
      "Location Name": "ELMSFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 E MAIN ST ELMSFORD NY 10523 USA "
    },
    {
      "Location ID": "10524",
      "Location Name": "GARRISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1145 ROUTE 9D GARRISON NY 10524 USA "
    },
    {
      "Location ID": "10526",
      "Location Name": "GOLDENS BRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 N COUNTY SHOPPING CTR GOLDENS BRIDGE NY 10526 USA "
    },
    {
      "Location ID": "10527",
      "Location Name": "GRANITE SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 137 MAHOPAC AVE GRANITE SPRINGS NY 10527 USA "
    },
    {
      "Location ID": "10528",
      "Location Name": "HARRISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 258 HALSTEAD AVE HARRISON NY 10528 USA "
    },
    {
      "Location ID": "1052A",
      "Location Name": "ELMSFORD ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 CLEARBROOK RD STE 124 ELMSFORD NY 10523 USA "
    },
    {
      "Location ID": "10530",
      "Location Name": "HARTSDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 441 CENTRAL PARK AVE SCARSDALE NY 10583 USA "
    },
    {
      "Location ID": "10532",
      "Location Name": "HAWTHORNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 CROSS ST W HAWTHORNE NY 10532 USA "
    },
    {
      "Location ID": "10533",
      "Location Name": "IRVINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 N BUCKHOUT ST IRVINGTON NY 10533 USA "
    },
    {
      "Location ID": "10535",
      "Location Name": "JEFFERSON VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3652 LEE RD JEFFERSON VALLEY NY 10535 USA "
    },
    {
      "Location ID": "10536",
      "Location Name": "KATONAH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 292 KATONAH AVE KATONAH NY 10536 USA "
    },
    {
      "Location ID": "10537",
      "Location Name": "LAKE PEEKSKILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 132 LAKE DR LAKE PEEKSKILL NY 10537 USA "
    },
    {
      "Location ID": "10538",
      "Location Name": "LARCHMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 CHATSWORTH AVE LARCHMONT NY 10538 USA "
    },
    {
      "Location ID": "10540",
      "Location Name": "LINCOLNDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 152 ROUTE 202 LINCOLNDALE NY 10540 USA "
    },
    {
      "Location ID": "10541",
      "Location Name": "MAHOPAC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 346 ROUTE 6 MAHOPAC NY 10541 USA "
    },
    {
      "Location ID": "10542",
      "Location Name": "MAHOPAC FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 173 MYRTLE AVE MAHOPAC FALLS NY 10542 USA "
    },
    {
      "Location ID": "10543",
      "Location Name": "MAMARONECK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 309 MOUNT PLEASANT AVE MAMARONECK NY 10543 USA "
    },
    {
      "Location ID": "10545",
      "Location Name": "MARYKNOLL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 85 RYDER RD MARYKNOLL NY 10545 USA "
    },
    {
      "Location ID": "10546",
      "Location Name": "MILLWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 230 SAW MILL RIVER RD STE 10 MILLWOOD NY 10546 USA "
    },
    {
      "Location ID": "10547",
      "Location Name": "MOHEGAN LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3147 E MAIN ST MOHEGAN LAKE NY 10547 USA "
    },
    {
      "Location ID": "10548",
      "Location Name": "MONTROSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 KINGS FERRY RD MONTROSE NY 10548 USA "
    },
    {
      "Location ID": "10549",
      "Location Name": "MOUNT KISCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 MORGAN DR MOUNT KISCO NY 10549 USA "
    },
    {
      "Location ID": "10550",
      "Location Name": "MOUNT VERNON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 S 1ST AVE MOUNT VERNON NY 10550 USA "
    },
    {
      "Location ID": "10560",
      "Location Name": "NORTH SALEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 JUNE RD NORTH SALEM NY 10560 USA "
    },
    {
      "Location ID": "10562",
      "Location Name": "OSSINING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 MAIN ST OSSINING NY 10562 USA "
    },
    {
      "Location ID": "10566",
      "Location Name": "PEEKSKILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 738 SOUTH ST PEEKSKILL NY 10566 USA "
    },
    {
      "Location ID": "10570",
      "Location Name": "PLEASANTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 MEMORIAL PLZ PLEASANTVILLE NY 10570 USA "
    },
    {
      "Location ID": "10573",
      "Location Name": "PORT CHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 245 WESTCHESTER AVE PORT CHESTER NY 10573 USA "
    },
    {
      "Location ID": "10576",
      "Location Name": "POUND RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 57 WESTCHESTER AVE POUND RIDGE NY 10576 USA "
    },
    {
      "Location ID": "10577",
      "Location Name": "PURCHASE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3003 PURCHASE ST PURCHASE NY 10577 USA "
    },
    {
      "Location ID": "10578",
      "Location Name": "PURDYS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 MAIN ST PURDYS NY 10578 USA "
    },
    {
      "Location ID": "10579",
      "Location Name": "PUTNAM VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 PEEKSKILL HOLLOW RD PUTNAM VALLEY NY 10579 USA "
    },
    {
      "Location ID": "10580",
      "Location Name": "RYE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41 PURDY AVE RYE NY 10580 USA "
    },
    {
      "Location ID": "10583",
      "Location Name": "SCARSDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 CHASE RD SCARSDALE NY 10583 USA "
    },
    {
      "Location ID": "10587",
      "Location Name": "SHENOROCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 TIGHE RD SHENOROCK NY 10587 USA "
    },
    {
      "Location ID": "10588",
      "Location Name": "SHRUB OAK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1350 E MAIN ST SHRUB OAK NY 10588 USA "
    },
    {
      "Location ID": "10589",
      "Location Name": "SOMERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 MARKET PLZ SOMERS NY 10589 USA "
    },
    {
      "Location ID": "10590",
      "Location Name": "SOUTH SALEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 MAIN ST SOUTH SALEM NY 10590 USA "
    },
    {
      "Location ID": "10591",
      "Location Name": "TARRYTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 N BROADWAY TARRYTOWN NY 10591 USA "
    },
    {
      "Location ID": "10594",
      "Location Name": "THORNWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 822 COMMERCE ST THORNWOOD NY 10594 USA "
    },
    {
      "Location ID": "10595",
      "Location Name": "VALHALLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 CLEVELAND ST VALHALLA NY 10595 USA "
    },
    {
      "Location ID": "10596",
      "Location Name": "VERPLANCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 174 6TH ST VERPLANCK NY 10596 USA "
    },
    {
      "Location ID": "10597",
      "Location Name": "WACCABUC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 POST OFFICE RD WACCABUC NY 10597 USA "
    },
    {
      "Location ID": "10598",
      "Location Name": "YORKTOWN HEIGHTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2020 MAPLE HILL ST YORKTOWN HEIGHTS NY 10598 USA "
    },
    {
      "Location ID": "105BA",
      "Location Name": "BIG APPLE COLLECTIBLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 175 CLEARBROOK RD STE 176 ELMSFORD NY 10523 USA "
    },
    {
      "Location ID": "105BC",
      "Location Name": "BALL CHAIN MFG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 741 S FULTON AVE MOUNT VERNON NY 10550 USA "
    },
    {
      "Location ID": "105CA",
      "Location Name": "CROWNE AWARDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 SKYLINE DR HAWTHORNE NY 10532 USA "
    },
    {
      "Location ID": "105DY",
      "Location Name": "DEAL YARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 534 FURNACE DOCK RD CORTLANDT MANOR NY 10567 USA "
    },
    {
      "Location ID": "105HB",
      "Location Name": "HEATHCOTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1112 WILMOT RD SCARSDALE NY 10583 USA "
    },
    {
      "Location ID": "105OD",
      "Location Name": "OPTICAL DISTRIBUTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 SKYLINE DR STE 8 HAWTHORNE NY 10532 USA "
    },
    {
      "Location ID": "10603",
      "Location Name": "NORTH WHITE PLAINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 585 N BROADWAY WHITE PLAINS NY 10603 USA "
    },
    {
      "Location ID": "10605",
      "Location Name": "GEDNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 620 MAMARONECK AVE WHITE PLAINS NY 10605 USA "
    },
    {
      "Location ID": "10606",
      "Location Name": "WHITE PLAINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 FISHER AVE WHITE PLAINS NY 10606 USA "
    },
    {
      "Location ID": "10701",
      "Location Name": "YONKERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 79-81 MAIN ST YONKERS NY 10701 USA "
    },
    {
      "Location ID": "10704",
      "Location Name": "YONKERS EAST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 915 YONKERS AVE YONKERS NY 10704 USA "
    },
    {
      "Location ID": "10705",
      "Location Name": "YONKERS SOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 335 S BROADWAY YONKERS NY 10705 USA "
    },
    {
      "Location ID": "10706",
      "Location Name": "HASTINGS ON HUDSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 591 WARBURTON AVE HASTINGS ON HUDSON NY 10706 USA "
    },
    {
      "Location ID": "10707",
      "Location Name": "TUCKAHOE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 COLUMBUS AVE TUCKAHOE NY 10707 USA "
    },
    {
      "Location ID": "10708",
      "Location Name": "BRONXVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 PONDFIELD RD BRONXVILLE NY 10708 USA "
    },
    {
      "Location ID": "10710",
      "Location Name": "CENTUCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1585 CENTRAL PARK AVE YONKERS NY 10710 USA "
    },
    {
      "Location ID": "107AA",
      "Location Name": "GREYSTONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 ODELL PLZ STE 130 YONKERS NY 10701 USA "
    },
    {
      "Location ID": "107MS",
      "Location Name": "MY SPACES SAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 SAW MILL RIVER RD YONKERS NY 10710 USA "
    },
    {
      "Location ID": "10803",
      "Location Name": "PELHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 1ST ST PELHAM NY 10803 USA "
    },
    {
      "Location ID": "10804",
      "Location Name": "WYKAGYL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 QUAKER RIDGE RD NEW ROCHELLE NY 10804 USA "
    },
    {
      "Location ID": "109",
      "Location Name": "ROCKLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17-27 MELNICK RD. MONSEY NY 10952 USA "
    },
    {
      "Location ID": "10901",
      "Location Name": "SUFFERN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 CHESTNUT ST SUFFERN NY 10901 USA "
    },
    {
      "Location ID": "10911",
      "Location Name": "BEAR MOUNTAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 SERVICE RD BEAR MOUNTAIN NY 10911 USA "
    },
    {
      "Location ID": "10913",
      "Location Name": "BLAUVELT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 545 WESTERN HWY BLAUVELT NY 10913 USA "
    },
    {
      "Location ID": "10914",
      "Location Name": "BLOOMING GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2876 ROUTE 94 BLOOMING GROVE NY 10914 USA "
    },
    {
      "Location ID": "10915",
      "Location Name": "BULLVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 652 LYBOLT RD BULLVILLE NY 10915 USA "
    },
    {
      "Location ID": "10916",
      "Location Name": "CAMPBELL HALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 MAYBROOK RD CAMPBELL HALL NY 10916 USA "
    },
    {
      "Location ID": "10917",
      "Location Name": "CENTRAL VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 SMITH CLOVE RD CENTRAL VALLEY NY 10917 USA "
    },
    {
      "Location ID": "10918",
      "Location Name": "CHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 76 BROOKSIDE AVE CHESTER NY 10918 USA "
    },
    {
      "Location ID": "10919",
      "Location Name": "CIRCLEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2079 ROUTE 302 CIRCLEVILLE NY 10919 USA "
    },
    {
      "Location ID": "10920",
      "Location Name": "CONGERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 OLD HAVERSTRAW RD CONGERS NY 10920 USA "
    },
    {
      "Location ID": "10921",
      "Location Name": "FLORIDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 EDWARD J LEMPKA DR FLORIDA NY 10921 USA "
    },
    {
      "Location ID": "10922",
      "Location Name": "FORT MONTGOMERY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 FIREFIGHTERS MEMORIAL DR FORT MONTGOMERY NY 10922 USA "
    },
    {
      "Location ID": "10923",
      "Location Name": "GARNERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 RAMAPO RD GARNERVILLE NY 10923 USA "
    },
    {
      "Location ID": "10924",
      "Location Name": "GOSHEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 GRAND ST GOSHEN NY 10924 USA "
    },
    {
      "Location ID": "10925",
      "Location Name": "GREENWOOD LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 WINDERMERE AVE GREENWOOD LAKE NY 10925 USA "
    },
    {
      "Location ID": "10926",
      "Location Name": "HARRIMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 88 STATE ROUTE 17M HARRIMAN NY 10926 USA "
    },
    {
      "Location ID": "10927",
      "Location Name": "HAVERSTRAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 86 MAIN ST HAVERSTRAW NY 10927 USA "
    },
    {
      "Location ID": "10928",
      "Location Name": "HIGHLAND FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 259 MAIN ST HIGHLAND FALLS NY 10928 USA "
    },
    {
      "Location ID": "10930",
      "Location Name": "HIGHLAND MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 547 STATE ROUTE 32 HIGHLAND MILLS NY 10930 USA "
    },
    {
      "Location ID": "10931",
      "Location Name": "HILLBURN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 ROCKLAND AVE HILLBURN NY 10931 USA "
    },
    {
      "Location ID": "10932",
      "Location Name": "HOWELLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 126 NEW VERNON RD STE 1 HOWELLS NY 10932 USA "
    },
    {
      "Location ID": "10933",
      "Location Name": "JOHNSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 83 COUNTY HIGHWAY 22 JOHNSON NY 10933 USA "
    },
    {
      "Location ID": "10940",
      "Location Name": "MIDDLETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 FULTON ST MIDDLETOWN NY 10940 USA "
    },
    {
      "Location ID": "10950",
      "Location Name": "MONROE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 787 STATE ROUTE 17M MONROE NY 10950 USA "
    },
    {
      "Location ID": "10952",
      "Location Name": "MONSEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 MELNICK DR MONSEY NY 10952 USA "
    },
    {
      "Location ID": "10953",
      "Location Name": "MOUNTAINVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 KETCHAM AVE MOUNTAINVILLE NY 10953 USA "
    },
    {
      "Location ID": "10954",
      "Location Name": "NANUET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 PROSPECT ST NANUET NY 10954 USA "
    },
    {
      "Location ID": "10956",
      "Location Name": "NEW CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 245 N MAIN ST NEW CITY NY 10956 USA "
    },
    {
      "Location ID": "10958",
      "Location Name": "NEW HAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5070 ROUTE 17M NEW HAMPTON NY 10958 USA "
    },
    {
      "Location ID": "10959",
      "Location Name": "NEW MILFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 JOCKEY HOLLOW RD STE 2 NEW MILFORD NY 10959 USA "
    },
    {
      "Location ID": "10960",
      "Location Name": "NYACK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 48 S BROADWAY NYACK NY 10960 USA "
    },
    {
      "Location ID": "10962",
      "Location Name": "ORANGEBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 ORANGETOWN SHOPPING CTR ORANGEBURG NY 10962 USA "
    },
    {
      "Location ID": "10963",
      "Location Name": "OTISVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 MAIN ST OTISVILLE NY 10963 USA "
    },
    {
      "Location ID": "10964",
      "Location Name": "PALISADES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 705 OAK TREE RD PALISADES NY 10964 USA "
    },
    {
      "Location ID": "10965",
      "Location Name": "PEARL RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 S MAIN ST PEARL RIVER NY 10965 USA "
    },
    {
      "Location ID": "10968",
      "Location Name": "PIERMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 393 PIERMONT AVE PIERMONT NY 10968 USA "
    },
    {
      "Location ID": "10969",
      "Location Name": "PINE ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 665 COUNTY ROUTE 1 PINE ISLAND NY 10969 USA "
    },
    {
      "Location ID": "10970",
      "Location Name": "POMONA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1515 ROUTE 202 POMONA NY 10970 USA "
    },
    {
      "Location ID": "10973",
      "Location Name": "SLATE HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1990 ROUTE 284 SLATE HILL NY 10973 USA "
    },
    {
      "Location ID": "10974",
      "Location Name": "SLOATSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 ORANGE TPKE SLOATSBURG NY 10974 USA "
    },
    {
      "Location ID": "10975",
      "Location Name": "SOUTHFIELDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 996 ROUTE 17 SOUTHFIELDS NY 10975 USA "
    },
    {
      "Location ID": "10976",
      "Location Name": "SPARKILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 DEPOT SQ SPARKILL NY 10976 USA "
    },
    {
      "Location ID": "10977",
      "Location Name": "SPRING VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 N MADISON AVE SPRING VALLEY NY 10977 USA "
    },
    {
      "Location ID": "10980",
      "Location Name": "STONY POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 81 CENTRAL HWY STONY POINT NY 10980 USA "
    },
    {
      "Location ID": "10981",
      "Location Name": "SUGAR LOAF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1407 KINGS HWY SUGAR LOAF NY 10981 USA "
    },
    {
      "Location ID": "10982",
      "Location Name": "TALLMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 321 ROUTE 59 TALLMAN NY 10982 USA "
    },
    {
      "Location ID": "10983",
      "Location Name": "TAPPAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 57 OLD TAPPAN RD TAPPAN NY 10983 USA "
    },
    {
      "Location ID": "10984",
      "Location Name": "THIELLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 177 THIELLS MT IVY RD THIELLS NY 10984 USA "
    },
    {
      "Location ID": "10985",
      "Location Name": "THOMPSON RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 49 COUNTY HIGHWAY 17 THOMPSON RIDGE NY 10985 USA "
    },
    {
      "Location ID": "10986",
      "Location Name": "TOMKINS COVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 160 N LIBERTY DR STE 5 TOMKINS COVE NY 10986 USA "
    },
    {
      "Location ID": "10987",
      "Location Name": "TUXEDO PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 228 ROUTE 17 TUXEDO PARK NY 10987 USA "
    },
    {
      "Location ID": "10988",
      "Location Name": "UNIONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 MAIN ST UNIONVILLE NY 10988 USA "
    },
    {
      "Location ID": "10989",
      "Location Name": "VALLEY COTTAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 137 ROUTE 303 VALLEY COTTAGE NY 10989 USA "
    },
    {
      "Location ID": "10990",
      "Location Name": "WARWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 MAIN ST WARWICK NY 10990 USA "
    },
    {
      "Location ID": "10992",
      "Location Name": "WASHINGTONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22 BROTHERHOOD PLAZA DR WASHINGTONVILLE NY 10992 USA "
    },
    {
      "Location ID": "10993",
      "Location Name": "WEST HAVERSTRAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 71 S ROUTE 9W STE 7 WEST HAVERSTRAW NY 10993 USA "
    },
    {
      "Location ID": "10994",
      "Location Name": "WEST NYACK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 720 W NYACK RD WEST NYACK NY 10994 USA "
    },
    {
      "Location ID": "10996",
      "Location Name": "WEST POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 634 SWIFT RD WEST POINT NY 10996 USA "
    },
    {
      "Location ID": "10997",
      "Location Name": "U S C C",
      "Time Zone Offset (Hours)": 0,
      "Address": " 745 BREWERTON RD WEST POINT NY 10996 USA "
    },
    {
      "Location ID": "10998",
      "Location Name": "WESTTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2097 COUNTY ROUTE 1 WESTTOWN NY 10998 USA "
    },
    {
      "Location ID": "109AD",
      "Location Name": "ADS #2",
      "Time Zone Offset (Hours)": 0,
      "Address": " 614 ROUTE 303 BLAUVELT NY 10913 USA "
    },
    {
      "Location ID": "109BT",
      "Location Name": "BIGELOW TRADING LTD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 707 EXECUTIVE BLVD STE G VLY COTTAGE NY 10989 USA "
    },
    {
      "Location ID": "109FF",
      "Location Name": "ROYAL FIREWORKS, NY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41 1ST ST UNIONVILLE NY 10988 USA "
    },
    {
      "Location ID": "109FM",
      "Location Name": "FORD MEDICAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 CORPORATE DR ORANGEBURG NY 10962 USA "
    },
    {
      "Location ID": "109HP",
      "Location Name": "HEY PHARMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 ALPINE CT STE W150 SPRING VALLEY NY 10977 USA "
    },
    {
      "Location ID": "109SA",
      "Location Name": "SOUND AROUND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 32 LEONE LN CHESTER NY 10918 USA "
    },
    {
      "Location ID": "109SB",
      "Location Name": "STAR BEST BUY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 COTTAGE ST MIDDLETOWN NY 10940 USA "
    },
    {
      "Location ID": "109SC",
      "Location Name": "SANDERS COLLECTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 GLENSHAW ST ORANGEBURG NY 10962 USA "
    },
    {
      "Location ID": "109TV",
      "Location Name": "TVP INDUSTRIES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 612 CORPORATE WAY STE 2 VALLEY COTTAGE NY 10989 USA "
    },
    {
      "Location ID": "109WB",
      "Location Name": "WARBY PARKER OPTICAL LABS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 ROUTE 17 SLOATSBURG NY 10974 USA "
    },
    {
      "Location ID": "110",
      "Location Name": "LOG QUEENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14202 20TH AVE RM 3166 FLUSHING NY 11351 USA "
    },
    {
      "Location ID": "111",
      "Location Name": "LONG ISLAND CITY MPO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4602 21ST ST LONG ISLAND CITY NY 11101 USA "
    },
    {
      "Location ID": "112",
      "Location Name": "LOG BROOKLYN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1050 FORBELL ST RM 2005 BROOKLYN NY 11256 USA "
    },
    {
      "Location ID": "11203",
      "Location Name": "RUGBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 726 UTICA AVE BROOKLYN NY 11203 USA "
    },
    {
      "Location ID": "11206",
      "Location Name": "METROPOLITAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 47 DEBEVOISE ST BROOKLYN NY 11206 USA "
    },
    {
      "Location ID": "11211",
      "Location Name": "WILLIAMSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 263 S 4TH ST BROOKLYN NY 11211 USA "
    },
    {
      "Location ID": "11218",
      "Location Name": "KENSINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 419 MCDONALD AVE BROOKLYN NY 11218 USA "
    },
    {
      "Location ID": "11222",
      "Location Name": "GREENPOINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 66 MESEROLE AVE BROOKLYN NY 11222 USA "
    },
    {
      "Location ID": "11229",
      "Location Name": "HOMECREST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2370 E 19TH ST BROOKLYN NY 11229 USA "
    },
    {
      "Location ID": "11234",
      "Location Name": "RYDER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1739 E 45TH ST BROOKLYN NY 11234 USA "
    },
    {
      "Location ID": "11235",
      "Location Name": "BAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2628 E 18TH ST BROOKLYN NY 11235 USA "
    },
    {
      "Location ID": "112AN",
      "Location Name": "TIMES PLAZA ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 594 DEAN ST BROOKLYN NY 11238 USA "
    },
    {
      "Location ID": "112BE",
      "Location Name": "BROOKLYN ALABAMA S&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 966 ALABAMA AVE BROOKLYN NY 11207 USA "
    },
    {
      "Location ID": "11360",
      "Location Name": "BAYSIDE ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4129 216TH ST BAYSIDE NY 11361 USA "
    },
    {
      "Location ID": "114AB",
      "Location Name": "INTERNATIONAL SERVICE CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 N BOUNDARY RD RM 2F1 JAMAICA NY 11430 USA "
    },
    {
      "Location ID": "114AG",
      "Location Name": "ACCELERATED GLOBAL SOLUTIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14709 182ND ST SPRINGFIELD GARDENS NY 11413 USA "
    },
    {
      "Location ID": "114BC",
      "Location Name": "BIG AND CHEAP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10108 97TH AVE OZONE PARK NY 11416 USA "
    },
    {
      "Location ID": "114BM",
      "Location Name": "BAYLINK / MICOM CHB INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14545 156TH ST JAMAICA NY 11434 USA "
    },
    {
      "Location ID": "114BP",
      "Location Name": "BEST PET SUPPLIES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10420 DUNKIRK ST SAINT ALBANS NY 11412 USA "
    },
    {
      "Location ID": "114FT",
      "Location Name": "FAST TRACK EXPRESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18208 149TH AVE SPRINGFIELD GAR NY 11413 USA "
    },
    {
      "Location ID": "114IB",
      "Location Name": "INTERNATIONAL BONDED COURIERS (IBC)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14729 182ND ST SPRINGFIELD GARDENS NY 11413 USA "
    },
    {
      "Location ID": "114JS",
      "Location Name": "JS EXPRESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17920 149TH AVE JAMAICA NY 11434 USA "
    },
    {
      "Location ID": "114SC",
      "Location Name": "SEKO - AIR CITY - SPRINGFIELD GARDENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15015 183RD ST SPRINGFIELD GARDENS NY 11413 USA "
    },
    {
      "Location ID": "114SG",
      "Location Name": "GLOBAL SYNERGY LOGISTICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14538 157TH ST JAMAICA NY 11434 USA "
    },
    {
      "Location ID": "114YD",
      "Location Name": "YDH EXPRESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16721 PORTER RD STE 1 JAMAICA NY 11434 USA "
    },
    {
      "Location ID": "114YE",
      "Location Name": "YUN EXPRESS INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18230 150TH RD JAMAICA NY 11413 USA "
    },
    {
      "Location ID": "115",
      "Location Name": "LOG WESTERN NASSAU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 830 STEWART AVE GARDEN CITY NY 11599 USA "
    },
    {
      "Location ID": "11566",
      "Location Name": "MERRICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2040 MERRICK RD MERRICK NY 11566 USA "
    },
    {
      "Location ID": "11570",
      "Location Name": "ROCKVILLE CENTRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 MERRICK RD ROCKVILLE CENTRE NY 11570 USA "
    },
    {
      "Location ID": "11572",
      "Location Name": "OCEANSIDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 ATLANTIC AVE OCEANSIDE NY 11572 USA "
    },
    {
      "Location ID": "117",
      "Location Name": "MID-ISLAND NY P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 160 DURYEA RD MELVILLE NY 11747 USA "
    },
    {
      "Location ID": "11746",
      "Location Name": "HUNTINGTON STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 888 E JERICHO TPKE HUNTINGTON STATION NY 11746 USA "
    },
    {
      "Location ID": "117AX",
      "Location Name": "NY L&DC CHRISTMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 160 DURYEA RD MELVILLE NY 11747 USA "
    },
    {
      "Location ID": "117PM",
      "Location Name": "LOG BETHPAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 288 GRUMMAN RD W BETHPAGE NY 11714 USA "
    },
    {
      "Location ID": "119",
      "Location Name": "RIVERHEAD MAIN PO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1210 WEST MAIN STREET RIVERHEAD NY 11901 USA "
    },
    {
      "Location ID": "11901",
      "Location Name": "RIVERHEAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1210 W MAIN ST RIVERHEAD NY 11901 USA "
    },
    {
      "Location ID": "11931",
      "Location Name": "AQUEBOGUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 212 LINDA AVE AQUEBOGUE NY 11931 USA "
    },
    {
      "Location ID": "11933",
      "Location Name": "CALVERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3690 MIDDLE COUNTRY RD CALVERTON NY 11933 USA "
    },
    {
      "Location ID": "11934",
      "Location Name": "CENTER MORICHES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 RAILROAD AVE CENTER MORICHES NY 11934 USA "
    },
    {
      "Location ID": "11935",
      "Location Name": "CUTCHOGUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 240 GRIFFING ST CUTCHOGUE NY 11935 USA "
    },
    {
      "Location ID": "11937",
      "Location Name": "EAST HAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 GAY RD EAST HAMPTON NY 11937 USA "
    },
    {
      "Location ID": "11939",
      "Location Name": "EAST MARION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9165 MAIN RD EAST MARION NY 11939 USA "
    },
    {
      "Location ID": "11940",
      "Location Name": "EAST MORICHES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 ATLANTIC AVE EAST MORICHES NY 11940 USA "
    },
    {
      "Location ID": "11941",
      "Location Name": "EASTPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 EASTPORT MANOR RD EASTPORT NY 11941 USA "
    },
    {
      "Location ID": "11942",
      "Location Name": "EAST QUOGUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 BAY AVE EAST QUOGUE NY 11942 USA "
    },
    {
      "Location ID": "11944",
      "Location Name": "GREENPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 131 FRONT ST GREENPORT NY 11944 USA "
    },
    {
      "Location ID": "11946",
      "Location Name": "HAMPTON BAYS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 PONQUOGUE AVE HAMPTON BAYS NY 11946 USA "
    },
    {
      "Location ID": "11947",
      "Location Name": "JAMESPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1451 ROUTE 25 JAMESPORT NY 11947 USA "
    },
    {
      "Location ID": "11948",
      "Location Name": "LAUREL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2168 MAIN RD LAUREL NY 11948 USA "
    },
    {
      "Location ID": "11949",
      "Location Name": "MANORVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 525 EASTPORT MANOR RD MANORVILLE NY 11949 USA "
    },
    {
      "Location ID": "11950",
      "Location Name": "MASTIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1450 MONTAUK HWY MASTIC NY 11950 USA "
    },
    {
      "Location ID": "11951",
      "Location Name": "MASTIC BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 MASTIC RD MASTIC BEACH NY 11951 USA "
    },
    {
      "Location ID": "11952",
      "Location Name": "MATTITUCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 140 LOVE LN MATTITUCK NY 11952 USA "
    },
    {
      "Location ID": "11953",
      "Location Name": "MIDDLE ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 780 MIDDLE COUNTRY RD MIDDLE ISLAND NY 11953 USA "
    },
    {
      "Location ID": "11954",
      "Location Name": "MONTAUK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 73 S EUCLID AVE MONTAUK NY 11954 USA "
    },
    {
      "Location ID": "11955",
      "Location Name": "MORICHES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 290 MONTAUK HWY MORICHES NY 11955 USA "
    },
    {
      "Location ID": "11956",
      "Location Name": "NEW SUFFOLK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 375 1ST ST NEW SUFFOLK NY 11956 USA "
    },
    {
      "Location ID": "11957",
      "Location Name": "ORIENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 980 VILLAGE LN ORIENT NY 11957 USA "
    },
    {
      "Location ID": "11958",
      "Location Name": "PECONIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2575 PECONIC LN PECONIC NY 11958 USA "
    },
    {
      "Location ID": "11959",
      "Location Name": "QUOGUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 MIDLAND ST QUOGUE NY 11959 USA "
    },
    {
      "Location ID": "11960",
      "Location Name": "REMSENBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 137 S COUNTRY RD REMSENBURG NY 11960 USA "
    },
    {
      "Location ID": "11961",
      "Location Name": "RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1700 MIDDLE COUNTRY RD RIDGE NY 11961 USA "
    },
    {
      "Location ID": "11967",
      "Location Name": "SHIRLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 800 MONTAUK HWY STE 1 SHIRLEY NY 11967 USA "
    },
    {
      "Location ID": "11970",
      "Location Name": "SOUTH JAMESPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 70 2ND ST SOUTH JAMESPORT NY 11970 USA "
    },
    {
      "Location ID": "11971",
      "Location Name": "SOUTHOLD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 710 TRAVELER ST SOUTHOLD NY 11971 USA "
    },
    {
      "Location ID": "11972",
      "Location Name": "SPEONK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 323 MONTAUK HWY SPEONK NY 11972 USA "
    },
    {
      "Location ID": "11973",
      "Location Name": "UPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 CENTER ST UPTON NY 11973 USA "
    },
    {
      "Location ID": "11977",
      "Location Name": "WESTHAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 408 MILL RD WESTHAMPTON NY 11977 USA "
    },
    {
      "Location ID": "11978",
      "Location Name": "WESTHAMPTON BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 170 MAIN ST WESTHAMPTON BEACH NY 11978 USA "
    },
    {
      "Location ID": "11980",
      "Location Name": "YAPHANK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 E MAIN ST YAPHANK NY 11980 USA "
    },
    {
      "Location ID": "120",
      "Location Name": "LOG ALBANY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 KARNER RD ALBANY NY 12288 USA "
    },
    {
      "Location ID": "12009",
      "Location Name": "ALTAMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 PARK ST ALTAMONT NY 12009 USA "
    },
    {
      "Location ID": "12010",
      "Location Name": "AMSTERDAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 CHURCH ST AMSTERDAM NY 12010 USA "
    },
    {
      "Location ID": "12015",
      "Location Name": "ATHENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 64 N WASHINGTON ST ATHENS NY 12015 USA "
    },
    {
      "Location ID": "12018",
      "Location Name": "AVERILL PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2951 NY HIGHWAY 43 AVERILL PARK NY 12018 USA "
    },
    {
      "Location ID": "12022",
      "Location Name": "BERLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18246 STATE ROUTE 22 BERLIN NY 12022 USA "
    },
    {
      "Location ID": "12024",
      "Location Name": "BRAINARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2045 US ROUTE 20 BRAINARD NY 12024 USA "
    },
    {
      "Location ID": "12025",
      "Location Name": "BROADALBIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 CENTER ST BROADALBIN NY 12025 USA "
    },
    {
      "Location ID": "12029",
      "Location Name": "CANAAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1961 STATE ROUTE 295 CANAAN NY 12029 USA "
    },
    {
      "Location ID": "12033",
      "Location Name": "CASTLETON ON HUDSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 S MAIN ST CASTLETON ON HUDSON NY 12033 USA "
    },
    {
      "Location ID": "12035",
      "Location Name": "CENTRAL BRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 126 S MAIN ST STE 1 CENTRAL BRIDGE NY 12035 USA "
    },
    {
      "Location ID": "12037",
      "Location Name": "CHATHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 RAILROAD AVE CHATHAM NY 12037 USA "
    },
    {
      "Location ID": "12040",
      "Location Name": "CHERRY PLAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 65 CHERRY PLAIN SQ CHERRY PLAIN NY 12040 USA "
    },
    {
      "Location ID": "12041",
      "Location Name": "CLARKSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1967 DELAWARE TPKE CLARKSVILLE NY 12041 USA "
    },
    {
      "Location ID": "12043",
      "Location Name": "COBLESKILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 UNION ST COBLESKILL NY 12043 USA "
    },
    {
      "Location ID": "12045",
      "Location Name": "COEYMANS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 96 MAIN ST COEYMANS NY 12045 USA "
    },
    {
      "Location ID": "12047",
      "Location Name": "COHOES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 MOHAWK ST COHOES NY 12047 USA "
    },
    {
      "Location ID": "12050",
      "Location Name": "COLUMBIAVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1227 ROUTE 9 COLUMBIAVILLE NY 12050 USA "
    },
    {
      "Location ID": "12051",
      "Location Name": "COXSACKIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 REED ST COXSACKIE NY 12051 USA "
    },
    {
      "Location ID": "12052",
      "Location Name": "CROPSEYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3991 STATE RTE 2 CROPSEYVILLE NY 12052 USA "
    },
    {
      "Location ID": "12053",
      "Location Name": "DELANSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 126 RAILROAD AVE DELANSON NY 12053 USA "
    },
    {
      "Location ID": "12054",
      "Location Name": "DELMAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 357 DELAWARE AVE DELMAR NY 12054 USA "
    },
    {
      "Location ID": "12056",
      "Location Name": "DUANESBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7000 DUANESBURG RD DUANESBURG NY 12056 USA "
    },
    {
      "Location ID": "12060",
      "Location Name": "EAST CHATHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1111 STATE ROUTE 295 EAST CHATHAM NY 12060 USA "
    },
    {
      "Location ID": "12061",
      "Location Name": "EAST GREENBUSH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 GREENWOOD DR EAST GREENBUSH NY 12061 USA "
    },
    {
      "Location ID": "12062",
      "Location Name": "EAST NASSAU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6466 STATE ROUTE 66 EAST NASSAU NY 12062 USA "
    },
    {
      "Location ID": "12063",
      "Location Name": "EAST SCHODACK",
      "Time Zone Offset (Hours)": 0,
      "Address": " ROUTE 150 EAST SCHODACK NY 12063 USA "
    },
    {
      "Location ID": "12064",
      "Location Name": "EAST WORCESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 70 MAIN ST STE 1 EAST WORCESTER NY 12064 USA "
    },
    {
      "Location ID": "12066",
      "Location Name": "ESPERANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 170 MAIN ST ESPERANCE NY 12066 USA "
    },
    {
      "Location ID": "12067",
      "Location Name": "FEURA BUSH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1380 INDIAN FIELDS RD FEURA BUSH NY 12067 USA "
    },
    {
      "Location ID": "12068",
      "Location Name": "FONDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 W MAIN ST FONDA NY 12068 USA "
    },
    {
      "Location ID": "12070",
      "Location Name": "FORT JOHNSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 E MAIN ST FORT JOHNSON NY 12070 USA "
    },
    {
      "Location ID": "12071",
      "Location Name": "FULTONHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3431 STATE ROUTE 30 FULTONHAM NY 12071 USA "
    },
    {
      "Location ID": "12072",
      "Location Name": "FULTONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 MAIN ST STE 5 FULTONVILLE NY 12072 USA "
    },
    {
      "Location ID": "12073",
      "Location Name": "GALLUPVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 859 STATE ROUTE 443 STE 2 GALLUPVILLE NY 12073 USA "
    },
    {
      "Location ID": "12074",
      "Location Name": "GALWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5101 SACANDAGA RD GALWAY NY 12074 USA "
    },
    {
      "Location ID": "12075",
      "Location Name": "GHENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2354 STATE ROUTE 66 GHENT NY 12075 USA "
    },
    {
      "Location ID": "12076",
      "Location Name": "GILBOA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 373 STATE ROUTE 990V STE 2 GILBOA NY 12076 USA "
    },
    {
      "Location ID": "12077",
      "Location Name": "GLENMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 402 FEURA BUSH RD GLENMONT NY 12077 USA "
    },
    {
      "Location ID": "12078",
      "Location Name": "GLOVERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 N MAIN ST GLOVERSVILLE NY 12078 USA "
    },
    {
      "Location ID": "12082",
      "Location Name": "GRAFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 ROXBOROUGH RD GRAFTON NY 12082 USA "
    },
    {
      "Location ID": "12083",
      "Location Name": "GREENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4912 STATE ROUTE 81 GREENVILLE NY 12083 USA "
    },
    {
      "Location ID": "12086",
      "Location Name": "HAGAMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 127 S PAWLING ST HAGAMAN NY 12086 USA "
    },
    {
      "Location ID": "12087",
      "Location Name": "HANNACROIX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13591 US ROUTE 9W HANNACROIX NY 12087 USA "
    },
    {
      "Location ID": "12092",
      "Location Name": "HOWES CAVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2929 STATE ROUTE 7 HOWES CAVE NY 12092 USA "
    },
    {
      "Location ID": "12093",
      "Location Name": "JEFFERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 PARK AVE JEFFERSON NY 12093 USA "
    },
    {
      "Location ID": "12095",
      "Location Name": "JOHNSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 N WILLIAM ST JOHNSTOWN NY 12095 USA "
    },
    {
      "Location ID": "120AR",
      "Location Name": "AMAZON RETURNS - CASTLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1835 US ROUTE 9 CASTLETON NY 12033 USA "
    },
    {
      "Location ID": "120NT",
      "Location Name": "NATURTINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 645 WEMPLE RD GLENMONT NY 12077 USA "
    },
    {
      "Location ID": "12106",
      "Location Name": "KINDERHOOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 HUDSON ST KINDERHOOK NY 12106 USA "
    },
    {
      "Location ID": "12108",
      "Location Name": "LAKE PLEASANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2557 STATE ROUTE 8 LAKE PLEASANT NY 12108 USA "
    },
    {
      "Location ID": "12110",
      "Location Name": "LATHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 685 WATERVLIET SHAKER RD LATHAM NY 12110 USA "
    },
    {
      "Location ID": "12115",
      "Location Name": "MALDEN BRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4691 STATE ROUTE 66 MALDEN BRIDGE NY 12115 USA "
    },
    {
      "Location ID": "12116",
      "Location Name": "MARYLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7501 STATE HIGHWAY 7 MARYLAND NY 12116 USA "
    },
    {
      "Location ID": "12117",
      "Location Name": "MAYFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 N SCHOOL ST MAYFIELD NY 12117 USA "
    },
    {
      "Location ID": "12118",
      "Location Name": "MECHANICVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 S MAIN ST MECHANICVILLE NY 12118 USA "
    },
    {
      "Location ID": "12120",
      "Location Name": "MEDUSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 COUNTY ROUTE 351 MEDUSA NY 12120 USA "
    },
    {
      "Location ID": "12122",
      "Location Name": "MIDDLEBURGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 MAIN ST MIDDLEBURGH NY 12122 USA "
    },
    {
      "Location ID": "12123",
      "Location Name": "NASSAU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 FAIRGROUNDS ST NASSAU NY 12123 USA "
    },
    {
      "Location ID": "12124",
      "Location Name": "NEW BALTIMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 849 STATE ROUTE 144 NEW BALTIMORE NY 12124 USA "
    },
    {
      "Location ID": "12125",
      "Location Name": "NEW LEBANON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 502 ROUTE 20 NEW LEBANON NY 12125 USA "
    },
    {
      "Location ID": "12130",
      "Location Name": "NIVERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 845 MAIN ST NIVERVILLE NY 12130 USA "
    },
    {
      "Location ID": "12131",
      "Location Name": "NORTH BLENHEIM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1748 STATE ROUTE 30 NORTH BLENHEIM NY 12131 USA "
    },
    {
      "Location ID": "12132",
      "Location Name": "NORTH CHATHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4241 ROUTE 203 NORTH CHATHAM NY 12132 USA "
    },
    {
      "Location ID": "12134",
      "Location Name": "NORTHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 151 N MAIN ST NORTHVILLE NY 12134 USA "
    },
    {
      "Location ID": "12136",
      "Location Name": "OLD CHATHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 635 ALBANY TPKE OLD CHATHAM NY 12136 USA "
    },
    {
      "Location ID": "12137",
      "Location Name": "PATTERSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1626 MAIN ST PATTERSONVILLE NY 12137 USA "
    },
    {
      "Location ID": "12138",
      "Location Name": "PETERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90 MAIN ST PETERSBURG NY 12138 USA "
    },
    {
      "Location ID": "12139",
      "Location Name": "PISECO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 402 OLD PISECO RD PISECO NY 12139 USA "
    },
    {
      "Location ID": "12140",
      "Location Name": "POESTENKILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 PLANK RD POESTENKILL NY 12140 USA "
    },
    {
      "Location ID": "12143",
      "Location Name": "RAVENA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 167 MAIN ST RAVENA NY 12143 USA "
    },
    {
      "Location ID": "12144",
      "Location Name": "RENSSELAER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 531 EAST ST RENSSELAER NY 12144 USA "
    },
    {
      "Location ID": "12147",
      "Location Name": "RENSSELAERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 COUNTY ROUTE 353 RENSSELAERVILLE NY 12147 USA "
    },
    {
      "Location ID": "12149",
      "Location Name": "RICHMONDVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 278 MAIN ST RICHMONDVILLE NY 12149 USA "
    },
    {
      "Location ID": "12150",
      "Location Name": "ROTTERDAM JUNCTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1206 MAIN ST ROTTERDAM JUNCTION NY 12150 USA "
    },
    {
      "Location ID": "12153",
      "Location Name": "SAND LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8427 MILLER HILL RD SAND LAKE NY 12153 USA "
    },
    {
      "Location ID": "12155",
      "Location Name": "SCHENEVUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 97 MAIN ST SCHENEVUS NY 12155 USA "
    },
    {
      "Location ID": "12156",
      "Location Name": "SCHODACK LANDING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 630 RIVER RD SCHODACK LANDING NY 12156 USA "
    },
    {
      "Location ID": "12157",
      "Location Name": "SCHOHARIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 420 MAIN ST SCHOHARIE NY 12157 USA "
    },
    {
      "Location ID": "12158",
      "Location Name": "SELKIRK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1155 US ROUTE 9W SELKIRK NY 12158 USA "
    },
    {
      "Location ID": "12159",
      "Location Name": "SLINGERLANDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1399 NEW SCOTLAND RD SLINGERLANDS NY 12159 USA "
    },
    {
      "Location ID": "12160",
      "Location Name": "SLOANSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 766 SADDLEMIRE HILL RD SLOANSVILLE NY 12160 USA "
    },
    {
      "Location ID": "12161",
      "Location Name": "SOUTH BETHLEHEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 541 BRIDGE ST SOUTH BETHLEHEM NY 12161 USA "
    },
    {
      "Location ID": "12164",
      "Location Name": "SPECULATOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 FOUR CORS SPECULATOR NY 12164 USA "
    },
    {
      "Location ID": "12166",
      "Location Name": "SPRAKERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 582 SPRAKERS RD SPRAKERS NY 12166 USA "
    },
    {
      "Location ID": "12167",
      "Location Name": "STAMFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 CHURCHILL AVE STAMFORD NY 12167 USA "
    },
    {
      "Location ID": "12168",
      "Location Name": "STEPHENTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 389 STATE ROUTE 43 STEPHENTOWN NY 12168 USA "
    },
    {
      "Location ID": "12170",
      "Location Name": "STILLWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 740 HUDSON AVE STILLWATER NY 12170 USA "
    },
    {
      "Location ID": "12172",
      "Location Name": "STOTTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2857 ATLANTIC AVE STOTTVILLE NY 12172 USA "
    },
    {
      "Location ID": "12173",
      "Location Name": "STUYVESANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 48 RIVERVIEW ST STUYVESANT NY 12173 USA "
    },
    {
      "Location ID": "12174",
      "Location Name": "STUYVESANT FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 ROUTE 25A STUYVESANT FALLS NY 12174 USA "
    },
    {
      "Location ID": "12175",
      "Location Name": "SUMMIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2906 STATE ROUTE 10 SUMMIT NY 12175 USA "
    },
    {
      "Location ID": "12177",
      "Location Name": "TRIBES HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 216 MOHAWK DR TRIBES HILL NY 12177 USA "
    },
    {
      "Location ID": "12180",
      "Location Name": "TROY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 BROADWAY TROY NY 12180 USA "
    },
    {
      "Location ID": "12184",
      "Location Name": "VALATIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1050 KINDERHOOK ST VALATIE NY 12184 USA "
    },
    {
      "Location ID": "12187",
      "Location Name": "WARNERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1585 STATE ROUTE 7 STE 2 WARNERVILLE NY 12187 USA "
    },
    {
      "Location ID": "12188",
      "Location Name": "WATERFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 57 4TH ST WATERFORD NY 12188 USA "
    },
    {
      "Location ID": "12189",
      "Location Name": "WATERVLIET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 145 16TH ST WATERVLIET NY 12189 USA "
    },
    {
      "Location ID": "12190",
      "Location Name": "WELLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1431 STATE ROUTE 30 WELLS NY 12190 USA "
    },
    {
      "Location ID": "12193",
      "Location Name": "WESTERLO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 591 STATE ROUTE 143 WESTERLO NY 12193 USA "
    },
    {
      "Location ID": "12194",
      "Location Name": "WEST FULTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 832 W FULTON RD STE 1 WEST FULTON NY 12194 USA "
    },
    {
      "Location ID": "12195",
      "Location Name": "WEST LEBANON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1533 STATE ROUTE 20 WEST LEBANON NY 12195 USA "
    },
    {
      "Location ID": "12196",
      "Location Name": "WEST SAND LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 38 MALL WAY WEST SAND LAKE NY 12196 USA "
    },
    {
      "Location ID": "12197",
      "Location Name": "WORCESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 MAIN ST WORCESTER NY 12197 USA "
    },
    {
      "Location ID": "12198",
      "Location Name": "WYNANTSKILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 85 MAIN AVE WYNANTSKILL NY 12198 USA "
    },
    {
      "Location ID": "121CS",
      "Location Name": "COMPUTER SCIENCE CORP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 CSC WAY RENSSELAER NY 12144 USA "
    },
    {
      "Location ID": "121FG",
      "Location Name": "FLIPSIDE GAMING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 VIALL AVE MECHANICVILLE NY 12118 USA "
    },
    {
      "Location ID": "122CX",
      "Location Name": "ALBANY ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 747 BROADWAY ALBANY NY 12207 USA "
    },
    {
      "Location ID": "122TX",
      "Location Name": "TERMINAL ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 71 TERMINAL ST ALBANY NY 12206 USA "
    },
    {
      "Location ID": "12305",
      "Location Name": "SCHENECTADY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 JAY ST SCHENECTADY NY 12305 USA "
    },
    {
      "Location ID": "12401",
      "Location Name": "KINGSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90 CORNELL ST KINGSTON NY 12401 USA "
    },
    {
      "Location ID": "12404",
      "Location Name": "ACCORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 MAIN ST ACCORD NY 12404 USA "
    },
    {
      "Location ID": "12405",
      "Location Name": "ACRA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 522 OLD ROUTE 23 ACRA NY 12405 USA "
    },
    {
      "Location ID": "12406",
      "Location Name": "ARKVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 43400 STATE ROUTE 28 ARKVILLE NY 12406 USA "
    },
    {
      "Location ID": "12407",
      "Location Name": "ASHLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12101 STATE HIGHWAY 23 ASHLAND NY 12407 USA "
    },
    {
      "Location ID": "12409",
      "Location Name": "BEARSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3203 ROUTE 212 STE 1 BEARSVILLE NY 12409 USA "
    },
    {
      "Location ID": "12410",
      "Location Name": "BIG INDIAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8279 STATE ROUTE 28 BIG INDIAN NY 12410 USA "
    },
    {
      "Location ID": "12411",
      "Location Name": "BLOOMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 58 MAIN ST BLOOMINGTON NY 12411 USA "
    },
    {
      "Location ID": "12412",
      "Location Name": "BOICEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4117 STATE ROUTE 28 BOICEVILLE NY 12412 USA "
    },
    {
      "Location ID": "12413",
      "Location Name": "CAIRO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 540 MAIN ST CAIRO NY 12413 USA "
    },
    {
      "Location ID": "12414",
      "Location Name": "CATSKILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 270 MAIN ST CATSKILL NY 12414 USA "
    },
    {
      "Location ID": "12417",
      "Location Name": "CONNELLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 3RD ST CONNELLY NY 12417 USA "
    },
    {
      "Location ID": "12419",
      "Location Name": "COTTEKILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1820 LUCAS AVENUE EXT COTTEKILL NY 12419 USA "
    },
    {
      "Location ID": "12423",
      "Location Name": "EAST DURHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2355 ROUTE 145 EAST DURHAM NY 12423 USA "
    },
    {
      "Location ID": "12428",
      "Location Name": "ELLENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 LIBERTY SQ ELLENVILLE NY 12428 USA "
    },
    {
      "Location ID": "12429",
      "Location Name": "ESOPUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 MAIN ST ESOPUS NY 12429 USA "
    },
    {
      "Location ID": "12430",
      "Location Name": "FLEISCHMANNS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1050 MAIN ST FLEISCHMANNS NY 12430 USA "
    },
    {
      "Location ID": "12431",
      "Location Name": "FREEHOLD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9467 ROUTE 32 FREEHOLD NY 12431 USA "
    },
    {
      "Location ID": "12432",
      "Location Name": "GLASCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90 GLASCO TPKE GLASCO NY 12432 USA "
    },
    {
      "Location ID": "12433",
      "Location Name": "GLENFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 216 OLD STATE ROUTE 28 GLENFORD NY 12433 USA "
    },
    {
      "Location ID": "12434",
      "Location Name": "GRAND GORGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37163 STATE HIGHWAY 23 GRAND GORGE NY 12434 USA "
    },
    {
      "Location ID": "12436",
      "Location Name": "HAINES FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5197 ROUTE 23A HAINES FALLS NY 12436 USA "
    },
    {
      "Location ID": "12438",
      "Location Name": "HALCOTTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 387 HALCOTTSVILLE RD HALCOTTSVILLE NY 12438 USA "
    },
    {
      "Location ID": "12440",
      "Location Name": "HIGH FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 MAIN ST HIGH FALLS NY 12440 USA "
    },
    {
      "Location ID": "12441",
      "Location Name": "HIGHMOUNT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 GALLI CURCI RD HIGHMOUNT NY 12441 USA "
    },
    {
      "Location ID": "12442",
      "Location Name": "HUNTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7975 MAIN ST HUNTER NY 12442 USA "
    },
    {
      "Location ID": "12443",
      "Location Name": "HURLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 MAIN ST HURLEY NY 12443 USA "
    },
    {
      "Location ID": "12446",
      "Location Name": "KERHONKSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 WEST ST KERHONKSON NY 12446 USA "
    },
    {
      "Location ID": "12448",
      "Location Name": "LAKE HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3929 STATE ROUTE 212 LAKE HILL NY 12448 USA "
    },
    {
      "Location ID": "12449",
      "Location Name": "LAKE KATRINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1840 ULSTER AVE LAKE KATRINE NY 12449 USA "
    },
    {
      "Location ID": "12451",
      "Location Name": "LEEDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1155 MAIN ST LEEDS NY 12451 USA "
    },
    {
      "Location ID": "12452",
      "Location Name": "LEXINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4021 ROUTE 42 STE 1 LEXINGTON NY 12452 USA "
    },
    {
      "Location ID": "12453",
      "Location Name": "MALDEN ON HUDSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1116 MAIN ST MALDEN ON HUDSON NY 12453 USA "
    },
    {
      "Location ID": "12455",
      "Location Name": "MARGARETVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 315 MAIN ST MARGARETVILLE NY 12455 USA "
    },
    {
      "Location ID": "12456",
      "Location Name": "MOUNT MARION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 542 GLASCO TPKE MOUNT MARION NY 12456 USA "
    },
    {
      "Location ID": "12457",
      "Location Name": "MOUNT TREMPER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5340 ROUTE 28 MOUNT TREMPER NY 12457 USA "
    },
    {
      "Location ID": "12458",
      "Location Name": "NAPANOCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7600 STATE ROUTE 209 STE 1 NAPANOCH NY 12458 USA "
    },
    {
      "Location ID": "12460",
      "Location Name": "OAK HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 OAK HILL RD OAK HILL NY 12460 USA "
    },
    {
      "Location ID": "12461",
      "Location Name": "OLIVEBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5147 STATE ROUTE 213 STE 1 OLIVEBRIDGE NY 12461 USA "
    },
    {
      "Location ID": "12463",
      "Location Name": "PALENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3378 ROUTE 23A PALENVILLE NY 12463 USA "
    },
    {
      "Location ID": "12464",
      "Location Name": "PHOENICIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 AVA MARIA DR PHOENICIA NY 12464 USA "
    },
    {
      "Location ID": "12465",
      "Location Name": "PINE HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 MAIN ST PINE HILL NY 12465 USA "
    },
    {
      "Location ID": "12466",
      "Location Name": "PORT EWEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 194 BROADWAY PORT EWEN NY 12466 USA "
    },
    {
      "Location ID": "12468",
      "Location Name": "PRATTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14546 MAIN ST PRATTSVILLE NY 12468 USA "
    },
    {
      "Location ID": "12469",
      "Location Name": "PRESTON HOLLOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2930 ROUTE 145 PRESTON HOLLOW NY 12469 USA "
    },
    {
      "Location ID": "12471",
      "Location Name": "RIFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1822 MAIN ST RIFTON NY 12471 USA "
    },
    {
      "Location ID": "12472",
      "Location Name": "ROSENDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 354 MAIN ST ROSENDALE NY 12472 USA "
    },
    {
      "Location ID": "12474",
      "Location Name": "ROXBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2375 COUNTY HIGHWAY 41 ROXBURY NY 12474 USA "
    },
    {
      "Location ID": "12475",
      "Location Name": "RUBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1165 MAIN ST RUBY NY 12475 USA "
    },
    {
      "Location ID": "12477",
      "Location Name": "SAUGERTIES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 MARKET ST SAUGERTIES NY 12477 USA "
    },
    {
      "Location ID": "12480",
      "Location Name": "SHANDAKEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22 STATE ROUTE 42 SHANDAKEN NY 12480 USA "
    },
    {
      "Location ID": "12481",
      "Location Name": "SHOKAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3110 STATE ROUTE 28 SHOKAN NY 12481 USA "
    },
    {
      "Location ID": "12482",
      "Location Name": "SOUTH CAIRO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2395 ROUTE 23B SOUTH CAIRO NY 12482 USA "
    },
    {
      "Location ID": "12483",
      "Location Name": "SPRING GLEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1395 OLD ROUTE 209 SPRING GLEN NY 12483 USA "
    },
    {
      "Location ID": "12484",
      "Location Name": "STONE RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3765 MAIN ST STONE RIDGE NY 12484 USA "
    },
    {
      "Location ID": "12485",
      "Location Name": "TANNERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6070 MAIN ST TANNERSVILLE NY 12485 USA "
    },
    {
      "Location ID": "12486",
      "Location Name": "TILLSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 134 HARDENBERGH AVE TILLSON NY 12486 USA "
    },
    {
      "Location ID": "12487",
      "Location Name": "ULSTER PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 364 UNION CENTER RD ULSTER PARK NY 12487 USA "
    },
    {
      "Location ID": "12489",
      "Location Name": "WAWARSING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7199 ROUTE 209 WAWARSING NY 12489 USA "
    },
    {
      "Location ID": "12490",
      "Location Name": "WEST CAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4294 ROUTE 9W WEST CAMP NY 12490 USA "
    },
    {
      "Location ID": "12491",
      "Location Name": "WEST HURLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1300 STATE ROUTE 28 WEST HURLEY NY 12491 USA "
    },
    {
      "Location ID": "12493",
      "Location Name": "WEST PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1740 ROUTE 9W WEST PARK NY 12493 USA "
    },
    {
      "Location ID": "12494",
      "Location Name": "WEST SHOKAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4079 STATE ROUTE 28A WEST SHOKAN NY 12494 USA "
    },
    {
      "Location ID": "12495",
      "Location Name": "WILLOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41 VAN WAGNER RD WILLOW NY 12495 USA "
    },
    {
      "Location ID": "12496",
      "Location Name": "WINDHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5365 STATE ROUTE 23 WINDHAM NY 12496 USA "
    },
    {
      "Location ID": "12498",
      "Location Name": "WOODSTOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 WOODSTOCK MEADOWS LN WOODSTOCK NY 12498 USA "
    },
    {
      "Location ID": "124PS",
      "Location Name": "PMC SUPPLIES, NY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 KATRINE LN LAKE KATRINE NY 12449 USA "
    },
    {
      "Location ID": "124WC",
      "Location Name": "WOODSTOCK CHIMES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 167 DUBOIS RD SHOKAN NY 12481 USA "
    },
    {
      "Location ID": "125",
      "Location Name": "LOG MID HUDSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 99 ENTERPRISE DR NEWBURGH NY 12555 USA "
    },
    {
      "Location ID": "12501",
      "Location Name": "AMENIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3296 ROUTE 343 AMENIA NY 12501 USA "
    },
    {
      "Location ID": "12502",
      "Location Name": "ANCRAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1295 COUNTY ROUTE 7 ANCRAM NY 12502 USA "
    },
    {
      "Location ID": "12503",
      "Location Name": "ANCRAMDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 COUNTY ROUTE 8 ANCRAMDALE NY 12503 USA "
    },
    {
      "Location ID": "12504",
      "Location Name": "ANNANDALE ON HUDSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 CAMPUS RD ANNANDALE ON HUDSON NY 12504 USA "
    },
    {
      "Location ID": "12506",
      "Location Name": "BANGALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 HUNNS LAKE RD BANGALL NY 12506 USA "
    },
    {
      "Location ID": "12508",
      "Location Name": "BEACON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 369 MAIN ST BEACON NY 12508 USA "
    },
    {
      "Location ID": "12510",
      "Location Name": "BILLINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2411 ROUTE 82 BILLINGS NY 12510 USA "
    },
    {
      "Location ID": "12512",
      "Location Name": "CHELSEA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 LIBERTY ST CHELSEA NY 12512 USA "
    },
    {
      "Location ID": "12513",
      "Location Name": "CLAVERACK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 PARK PLACE ST CLAVERACK NY 12513 USA "
    },
    {
      "Location ID": "12514",
      "Location Name": "CLINTON CORNERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2486 SALT POINT TPKE CLINTON CORNERS NY 12514 USA "
    },
    {
      "Location ID": "12515",
      "Location Name": "CLINTONDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1059 ROUTE 44 55 CLINTONDALE NY 12515 USA "
    },
    {
      "Location ID": "12516",
      "Location Name": "COPAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1656 COUNTY ROUTE 7A COPAKE NY 12516 USA "
    },
    {
      "Location ID": "12517",
      "Location Name": "COPAKE FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 STATE ROUTE 344 COPAKE FALLS NY 12517 USA "
    },
    {
      "Location ID": "12518",
      "Location Name": "CORNWALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 QUAKER AVE CORNWALL NY 12518 USA "
    },
    {
      "Location ID": "12520",
      "Location Name": "CORNWALL ON HUDSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 237 HUDSON ST STE 1 CORNWALL ON HUDSON NY 12520 USA "
    },
    {
      "Location ID": "12521",
      "Location Name": "CRARYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1751 STATE ROUTE 23 CRARYVILLE NY 12521 USA "
    },
    {
      "Location ID": "12522",
      "Location Name": "DOVER PLAINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3042 ROUTE 22 DOVER PLAINS NY 12522 USA "
    },
    {
      "Location ID": "12523",
      "Location Name": "ELIZAVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 673 COUNTY ROUTE 2 ELIZAVILLE NY 12523 USA "
    },
    {
      "Location ID": "12524",
      "Location Name": "FISHKILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1018 MAIN ST FISHKILL NY 12524 USA "
    },
    {
      "Location ID": "12525",
      "Location Name": "GARDINER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 124 MAIN ST GARDINER NY 12525 USA "
    },
    {
      "Location ID": "12526",
      "Location Name": "GERMANTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 MAIN ST GERMANTOWN NY 12526 USA "
    },
    {
      "Location ID": "12527",
      "Location Name": "GLENHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 77 OLD GLENHAM RD GLENHAM NY 12527 USA "
    },
    {
      "Location ID": "12528",
      "Location Name": "HIGHLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 197 NORTH RD HIGHLAND NY 12528 USA "
    },
    {
      "Location ID": "12529",
      "Location Name": "HILLSDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9245 STATE ROUTE 22 HILLSDALE NY 12529 USA "
    },
    {
      "Location ID": "12531",
      "Location Name": "HOLMES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 298 ROUTE 292 HOLMES NY 12531 USA "
    },
    {
      "Location ID": "12533",
      "Location Name": "HOPEWELL JUNCTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 815 ROUTE 82 HOPEWELL JUNCTION NY 12533 USA "
    },
    {
      "Location ID": "12534",
      "Location Name": "HUDSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 402 UNION ST HUDSON NY 12534 USA "
    },
    {
      "Location ID": "12537",
      "Location Name": "HUGHSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2337 ROUTE 9D HUGHSONVILLE NY 12537 USA "
    },
    {
      "Location ID": "12538",
      "Location Name": "HYDE PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 E MARKET ST HYDE PARK NY 12538 USA "
    },
    {
      "Location ID": "12540",
      "Location Name": "LAGRANGEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1085 ROUTE 55 LAGRANGEVILLE NY 12540 USA "
    },
    {
      "Location ID": "12542",
      "Location Name": "MARLBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 43 WESTERN AVE MARLBORO NY 12542 USA "
    },
    {
      "Location ID": "12543",
      "Location Name": "MAYBROOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 207 HOMESTEAD AVE MAYBROOK NY 12543 USA "
    },
    {
      "Location ID": "12544",
      "Location Name": "MELLENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 671 STATE ROUTE 217 MELLENVILLE NY 12544 USA "
    },
    {
      "Location ID": "12545",
      "Location Name": "MILLBROOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 47 FRONT ST MILLBROOK NY 12545 USA "
    },
    {
      "Location ID": "12546",
      "Location Name": "MILLERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 CENTURY BLVD MILLERTON NY 12546 USA "
    },
    {
      "Location ID": "12547",
      "Location Name": "MILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 63 MAIN ST MILTON NY 12547 USA "
    },
    {
      "Location ID": "12548",
      "Location Name": "MODENA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2255 STATE ROUTE 32 MODENA NY 12548 USA "
    },
    {
      "Location ID": "12549",
      "Location Name": "MONTGOMERY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 218 BOYD ST MONTGOMERY NY 12549 USA "
    },
    {
      "Location ID": "12550",
      "Location Name": "NEWBURGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 217 LIBERTY ST NEWBURGH NY 12550 USA "
    },
    {
      "Location ID": "12553",
      "Location Name": "NEW WINDSOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 436 BLOOMING GROVE TPKE STE 1100 NEW WINDSOR NY 12553 USA "
    },
    {
      "Location ID": "12561",
      "Location Name": "NEW PALTZ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41 MAIN ST NEW PALTZ NY 12561 USA "
    },
    {
      "Location ID": "12563",
      "Location Name": "PATTERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 FRONT ST PATTERSON NY 12563 USA "
    },
    {
      "Location ID": "12564",
      "Location Name": "PAWLING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 BROAD ST PAWLING NY 12564 USA "
    },
    {
      "Location ID": "12565",
      "Location Name": "PHILMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 MAPLE AVE PHILMONT NY 12565 USA "
    },
    {
      "Location ID": "12566",
      "Location Name": "PINE BUSH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 MAIN ST PINE BUSH NY 12566 USA "
    },
    {
      "Location ID": "12567",
      "Location Name": "PINE PLAINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7722 S MAIN ST PINE PLAINS NY 12567 USA "
    },
    {
      "Location ID": "12568",
      "Location Name": "PLATTEKILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 OLD FIREHOUSE RD PLATTEKILL NY 12568 USA "
    },
    {
      "Location ID": "12569",
      "Location Name": "PLEASANT VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1612 MAIN ST PLEASANT VALLEY NY 12569 USA "
    },
    {
      "Location ID": "12570",
      "Location Name": "POUGHQUAG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2546 ROUTE 55 POUGHQUAG NY 12570 USA "
    },
    {
      "Location ID": "12571",
      "Location Name": "RED HOOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7525 N BROADWAY RED HOOK NY 12571 USA "
    },
    {
      "Location ID": "12572",
      "Location Name": "RHINEBECK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6383 MILL ST RHINEBECK NY 12572 USA "
    },
    {
      "Location ID": "12574",
      "Location Name": "RHINECLIFF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 SHATZELL AVE RHINECLIFF NY 12574 USA "
    },
    {
      "Location ID": "12575",
      "Location Name": "ROCK TAVERN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1964 LITTLE BRITAIN RD ROCK TAVERN NY 12575 USA "
    },
    {
      "Location ID": "12577",
      "Location Name": "SALISBURY MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2130 STATE ROUTE 94 STE 5 SALISBURY MILLS NY 12577 USA "
    },
    {
      "Location ID": "12578",
      "Location Name": "SALT POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 59 COTTAGE ST SALT POINT NY 12578 USA "
    },
    {
      "Location ID": "12580",
      "Location Name": "STAATSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 MARKET ST STAATSBURG NY 12580 USA "
    },
    {
      "Location ID": "12581",
      "Location Name": "STANFORDVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6069 ROUTE 82 STANFORDVILLE NY 12581 USA "
    },
    {
      "Location ID": "12582",
      "Location Name": "STORMVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 OLD ROUTE 52 STORMVILLE NY 12582 USA "
    },
    {
      "Location ID": "12583",
      "Location Name": "TIVOLI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 78 BROADWAY TIVOLI NY 12583 USA "
    },
    {
      "Location ID": "12584",
      "Location Name": "VAILS GATE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 OLD TEMPLE HILL RD VAILS GATE NY 12584 USA "
    },
    {
      "Location ID": "12585",
      "Location Name": "VERBANK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3393 ROUTE 82 VERBANK NY 12585 USA "
    },
    {
      "Location ID": "12586",
      "Location Name": "WALDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 ORANGE AVE WALDEN NY 12586 USA "
    },
    {
      "Location ID": "12588",
      "Location Name": "WALKER VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3619 ROUTE 52 WALKER VALLEY NY 12588 USA "
    },
    {
      "Location ID": "12589",
      "Location Name": "WALLKILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 45 WALLKILL AVE WALLKILL NY 12589 USA "
    },
    {
      "Location ID": "12590",
      "Location Name": "WAPPINGERS FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 71 E MAIN ST WAPPINGERS FALLS NY 12590 USA "
    },
    {
      "Location ID": "12592",
      "Location Name": "WASSAIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 MAIN ST WASSAIC NY 12592 USA "
    },
    {
      "Location ID": "12594",
      "Location Name": "WINGDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1809 ROUTE 22 WINGDALE NY 12594 USA "
    },
    {
      "Location ID": "125CA",
      "Location Name": "CLOWN ANTICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 DALFONSO RD NEWBURGH NY 12550 USA "
    },
    {
      "Location ID": "125DB",
      "Location Name": "DO IT BEST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 650 NEELYTOWN RD MONTGOMERY NY 12549 USA "
    },
    {
      "Location ID": "125GP",
      "Location Name": "THE GAP - FISHKILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 MERRITT BLVD FISHKILL NY 12524 USA "
    },
    {
      "Location ID": "125HA",
      "Location Name": "HOPEWELL CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1616 ROUTE 82 LAGRANGEVILLE NY 12540 USA "
    },
    {
      "Location ID": "125HT",
      "Location Name": "HARNEY TEA COMPANY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5723 ROUTE 22 MILLERTON NY 12546 USA "
    },
    {
      "Location ID": "125TN",
      "Location Name": "THRIFTY NICKEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 WISNER AVE NEWBURGH NY 12550 USA "
    },
    {
      "Location ID": "12601",
      "Location Name": "POUGHKEEPSIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 MANSION ST POUGHKEEPSIE NY 12601 USA "
    },
    {
      "Location ID": "12603",
      "Location Name": "ARLINGTON BRANCH    NY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 RAYMOND AVENUE ARLINGTON NY 12603 USA "
    },
    {
      "Location ID": "126AB",
      "Location Name": "ARLINGTON CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 PAGE PARK DR POUGHKEEPSIE NY 12603 USA "
    },
    {
      "Location ID": "126DM",
      "Location Name": "POUGHKEEPSIE DMU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 229A MANCHESTER RD POUGHKEEPSIE NY 12603 USA "
    },
    {
      "Location ID": "126MC",
      "Location Name": "MARIST COLLEGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3399 NORTH RD POUGHKEEPSIE NY 12601 USA "
    },
    {
      "Location ID": "126VC",
      "Location Name": "VASSAR COLLEGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 124 RAYMOND AVE POUGHKEEPSIE NY 12604 USA "
    },
    {
      "Location ID": "12701",
      "Location Name": "MONTICELLO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 486 BROADWAY MONTICELLO NY 12701 USA "
    },
    {
      "Location ID": "12720",
      "Location Name": "BETHEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2166 STATE ROUTE 17B BETHEL NY 12720 USA "
    },
    {
      "Location ID": "12721",
      "Location Name": "BLOOMINGBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 WINTERTON RD BLOOMINGBURG NY 12721 USA "
    },
    {
      "Location ID": "12722",
      "Location Name": "BURLINGHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 709 BURLINGHAM RD BURLINGHAM NY 12722 USA "
    },
    {
      "Location ID": "12723",
      "Location Name": "CALLICOON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 RIVER RD CALLICOON NY 12723 USA "
    },
    {
      "Location ID": "12729",
      "Location Name": "CUDDEBACKVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 STATE ROUTE 211 CUDDEBACKVILLE NY 12729 USA "
    },
    {
      "Location ID": "12733",
      "Location Name": "FALLSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5672 STATE ROUTE 42 FALLSBURG NY 12733 USA "
    },
    {
      "Location ID": "12734",
      "Location Name": "FERNDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 SULLIVAN AVE FERNDALE NY 12734 USA "
    },
    {
      "Location ID": "12738",
      "Location Name": "GLEN WILD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 424 GLEN WILD RD GLEN WILD NY 12738 USA "
    },
    {
      "Location ID": "12742",
      "Location Name": "HARRIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 798 OLD ROUTE 17 HARRIS NY 12742 USA "
    },
    {
      "Location ID": "12746",
      "Location Name": "HUGUENOT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 435 ROUTE 209 HUGUENOT NY 12746 USA "
    },
    {
      "Location ID": "12749",
      "Location Name": "KAUNEONGA LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3584 STATE ROUTE 55 KAUNEONGA LAKE NY 12749 USA "
    },
    {
      "Location ID": "12751",
      "Location Name": "KIAMESHA LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4668 STATE ROUTE 42 KIAMESHA LAKE NY 12751 USA "
    },
    {
      "Location ID": "12754",
      "Location Name": "LIBERTY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 CHESTNUT ST LIBERTY NY 12754 USA "
    },
    {
      "Location ID": "12758",
      "Location Name": "LIVINGSTON MANOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23 MAIN ST LIVINGSTON MANOR NY 12758 USA "
    },
    {
      "Location ID": "12762",
      "Location Name": "MONGAUP VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 933 STATE ROUTE 17B MONGAUP VALLEY NY 12762 USA "
    },
    {
      "Location ID": "12763",
      "Location Name": "MOUNTAIN DALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 POST HILL RD MOUNTAIN DALE NY 12763 USA "
    },
    {
      "Location ID": "12768",
      "Location Name": "PARKSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 COOLEY RD PARKSVILLE NY 12768 USA "
    },
    {
      "Location ID": "12769",
      "Location Name": "PHILLIPSPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 PHILLIPSPORT RD PHILLIPSPORT NY 12769 USA "
    },
    {
      "Location ID": "12771",
      "Location Name": "PORT JERVIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 SUSSEX ST PORT JERVIS NY 12771 USA "
    },
    {
      "Location ID": "12775",
      "Location Name": "ROCK HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 240 ROCK HILL DR ROCK HILL NY 12775 USA "
    },
    {
      "Location ID": "12776",
      "Location Name": "ROSCOE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 MAPLE ST ROSCOE NY 12776 USA "
    },
    {
      "Location ID": "12778",
      "Location Name": "SMALLWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 PINE GROVE RD SMALLWOOD NY 12778 USA "
    },
    {
      "Location ID": "12779",
      "Location Name": "SOUTH FALLSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 RAILROAD PLZ SOUTH FALLSBURG NY 12779 USA "
    },
    {
      "Location ID": "12780",
      "Location Name": "SPARROW BUSH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 505 STATE ROUTE 42 SPARROW BUSH NY 12780 USA "
    },
    {
      "Location ID": "12781",
      "Location Name": "SUMMITVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3868 ROUTE 209 SUMMITVILLE NY 12781 USA "
    },
    {
      "Location ID": "12783",
      "Location Name": "SWAN LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 STANTON CORNER RD SWAN LAKE NY 12783 USA "
    },
    {
      "Location ID": "12784",
      "Location Name": "THOMPSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 558 THOMPSON RD THOMPSONVILLE NY 12784 USA "
    },
    {
      "Location ID": "12785",
      "Location Name": "WESTBROOKVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1755 STATE ROUTE 209 WESTBROOKVILLE NY 12785 USA "
    },
    {
      "Location ID": "12786",
      "Location Name": "WHITE LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1447 STATE ROUTE 17B WHITE LAKE NY 12786 USA "
    },
    {
      "Location ID": "12788",
      "Location Name": "WOODBOURNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6169 STATE ROUTE 42 WOODBOURNE NY 12788 USA "
    },
    {
      "Location ID": "12789",
      "Location Name": "WOODRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 47 BROADWAY WOODRIDGE NY 12789 USA "
    },
    {
      "Location ID": "12790",
      "Location Name": "WURTSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 SULLIVAN ST WURTSBORO NY 12790 USA "
    },
    {
      "Location ID": "128",
      "Location Name": "GLENS FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 HUDSON AVE GLENS FALLS NY 12801 USA "
    },
    {
      "Location ID": "12809",
      "Location Name": "ARGYLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 38 MAIN ST ARGYLE NY 12809 USA "
    },
    {
      "Location ID": "12812",
      "Location Name": "BLUE MOUNTAIN LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3444 STATE ROUTE 28 BLUE MOUNTAIN LAKE NY 12812 USA "
    },
    {
      "Location ID": "12815",
      "Location Name": "BRANT LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6580 STATE ROUTE 8 BRANT LAKE NY 12815 USA "
    },
    {
      "Location ID": "12816",
      "Location Name": "CAMBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 51 E MAIN ST CAMBRIDGE NY 12816 USA "
    },
    {
      "Location ID": "12817",
      "Location Name": "CHESTERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6323 STATE ROUTE 9 CHESTERTOWN NY 12817 USA "
    },
    {
      "Location ID": "12819",
      "Location Name": "CLEMONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14007 STATE ROUTE 22 CLEMONS NY 12819 USA "
    },
    {
      "Location ID": "12820",
      "Location Name": "CLEVERDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2663 STATE ROUTE 9L CLEVERDALE NY 12820 USA "
    },
    {
      "Location ID": "12821",
      "Location Name": "COMSTOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11762 STATE ROUTE 22 COMSTOCK NY 12821 USA "
    },
    {
      "Location ID": "12822",
      "Location Name": "CORINTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 97 MAIN ST CORINTH NY 12822 USA "
    },
    {
      "Location ID": "12827",
      "Location Name": "FORT ANN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 86 GEORGE ST FORT ANN NY 12827 USA "
    },
    {
      "Location ID": "12828",
      "Location Name": "FORT EDWARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 126 BROADWAY FORT EDWARD NY 12828 USA "
    },
    {
      "Location ID": "12831",
      "Location Name": "GANSEVOORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 LEONARD ST GANSEVOORT NY 12831 USA "
    },
    {
      "Location ID": "12832",
      "Location Name": "GRANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41 MAIN ST GRANVILLE NY 12832 USA "
    },
    {
      "Location ID": "12833",
      "Location Name": "GREENFIELD CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 WILTON RD GREENFIELD CENTER NY 12833 USA "
    },
    {
      "Location ID": "12834",
      "Location Name": "GREENWICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 MAIN ST GREENWICH NY 12834 USA "
    },
    {
      "Location ID": "12835",
      "Location Name": "HADLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 311 OLD CORINTH RD HADLEY NY 12835 USA "
    },
    {
      "Location ID": "12837",
      "Location Name": "HAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2522 COUNTY RTE. 18 HAMPTON NY 12837 USA "
    },
    {
      "Location ID": "12838",
      "Location Name": "HARTFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 46 HARTFORD MAIN ST STE 1 HARTFORD NY 12838 USA "
    },
    {
      "Location ID": "12839",
      "Location Name": "HUDSON FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 MAIN ST HUDSON FALLS NY 12839 USA "
    },
    {
      "Location ID": "12841",
      "Location Name": "HULETTS LANDING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 962 COUNTY ROUTE 6 HULETTS LANDING NY 12841 USA "
    },
    {
      "Location ID": "12842",
      "Location Name": "INDIAN LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6316 NYS ROUTE 30 INDIAN LAKE NY 12842 USA "
    },
    {
      "Location ID": "12844",
      "Location Name": "KATTSKILL BAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1488 PILOT KNOB RD KATTSKILL BAY NY 12844 USA "
    },
    {
      "Location ID": "12845",
      "Location Name": "LAKE GEORGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 180 CANADA ST LAKE GEORGE NY 12845 USA "
    },
    {
      "Location ID": "12846",
      "Location Name": "LAKE LUZERNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 SCHOOL ST LAKE LUZERNE NY 12846 USA "
    },
    {
      "Location ID": "12847",
      "Location Name": "LONG LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1095 DEERLAND RD LONG LAKE NY 12847 USA "
    },
    {
      "Location ID": "12848",
      "Location Name": "MIDDLE FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1079 STATE ROUTE 29 MIDDLE FALLS NY 12848 USA "
    },
    {
      "Location ID": "12850",
      "Location Name": "MIDDLE GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3B LAKE DESOLATION RD MIDDLE GROVE NY 12850 USA "
    },
    {
      "Location ID": "12853",
      "Location Name": "NORTH CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 CIRCLE AVE NORTH CREEK NY 12853 USA "
    },
    {
      "Location ID": "12855",
      "Location Name": "NORTH HUDSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2940 US ROUTE 9 NORTH HUDSON NY 12855 USA "
    },
    {
      "Location ID": "12856",
      "Location Name": "NORTH RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4631 STATE ROUTE 28 NORTH RIVER NY 12856 USA "
    },
    {
      "Location ID": "12859",
      "Location Name": "PORTER CORNERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 515 ALLEN RD PORTER CORNERS NY 12859 USA "
    },
    {
      "Location ID": "12860",
      "Location Name": "POTTERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37 VALLEY FARM RD POTTERSVILLE NY 12860 USA "
    },
    {
      "Location ID": "12861",
      "Location Name": "PUTNAM STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16783 STATE ROUTE 22 PUTNAM STATION NY 12861 USA "
    },
    {
      "Location ID": "12863",
      "Location Name": "ROCK CITY FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 744 ROUTE 29 STE 1 ROCK CITY FALLS NY 12863 USA "
    },
    {
      "Location ID": "12865",
      "Location Name": "SALEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 W BROADWAY SALEM NY 12865 USA "
    },
    {
      "Location ID": "12870",
      "Location Name": "SCHROON LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1097 US ROUTE 9 STE 1 SCHROON LAKE NY 12870 USA "
    },
    {
      "Location ID": "12871",
      "Location Name": "SCHUYLERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 FERRY ST SCHUYLERVILLE NY 12871 USA "
    },
    {
      "Location ID": "12873",
      "Location Name": "SHUSHAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 738 COUNTY ROUTE 64 SHUSHAN NY 12873 USA "
    },
    {
      "Location ID": "12878",
      "Location Name": "STONY CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 HADLEY RD STONY CREEK NY 12878 USA "
    },
    {
      "Location ID": "12883",
      "Location Name": "TICONDEROGA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 169 CHAMPLAIN AVE TICONDEROGA NY 12883 USA "
    },
    {
      "Location ID": "12884",
      "Location Name": "VICTORY MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 51 GATES AVE VICTORY MILLS NY 12884 USA "
    },
    {
      "Location ID": "12885",
      "Location Name": "WARRENSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3930 MAIN ST WARRENSBURG NY 12885 USA "
    },
    {
      "Location ID": "12886",
      "Location Name": "WEVERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3854 STATE ROUTE 8 WEVERTOWN NY 12886 USA "
    },
    {
      "Location ID": "12887",
      "Location Name": "WHITEHALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 88 BROADWAY WHITEHALL NY 12887 USA "
    },
    {
      "Location ID": "128AH",
      "Location Name": "ACE HARDWARE RETAIL SUPPORT CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 295 BALLARD RD GANSEVOORT NY 12831 USA "
    },
    {
      "Location ID": "128QG",
      "Location Name": "QUAD GRAPHICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 56 DUPLAINVILLE RD SARATOGA SPRINGS NY 12866 USA "
    },
    {
      "Location ID": "128WA",
      "Location Name": "SARATOGA SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 245 WASHINGTON ST SARATOGA SPRINGS NY 12866 USA "
    },
    {
      "Location ID": "129",
      "Location Name": "PLATTSBURGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 MILLER ST PLATTSBURGH NY 12901 USA "
    },
    {
      "Location ID": "12901",
      "Location Name": "PLATTSBURGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 46 VETERANS LN PLATTSBURGH NY 12901 USA "
    },
    {
      "Location ID": "12910",
      "Location Name": "ALTONA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 499 DEVILS DEN RD ALTONA NY 12910 USA "
    },
    {
      "Location ID": "12913",
      "Location Name": "BLOOMINGDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1711 STATE ROUTE 3 STE 1 BLOOMINGDALE NY 12913 USA "
    },
    {
      "Location ID": "12914",
      "Location Name": "BOMBAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1794 STATE ROUTE 95 BOMBAY NY 12914 USA "
    },
    {
      "Location ID": "12915",
      "Location Name": "BRAINARDSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2399 COUNTY ROUTE 24 BRAINARDSVILLE NY 12915 USA "
    },
    {
      "Location ID": "12916",
      "Location Name": "BRUSHTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1249 WASHINGTON ST BRUSHTON NY 12916 USA "
    },
    {
      "Location ID": "12917",
      "Location Name": "BURKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 822 DEPOT ST BURKE NY 12917 USA "
    },
    {
      "Location ID": "12918",
      "Location Name": "CADYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2106 STATE ROUTE 3 CADYVILLE NY 12918 USA "
    },
    {
      "Location ID": "12919",
      "Location Name": "CHAMPLAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 880 STATE ROUTE 11 CHAMPLAIN NY 12919 USA "
    },
    {
      "Location ID": "12920",
      "Location Name": "CHATEAUGAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 RIVER ST CHATEAUGAY NY 12920 USA "
    },
    {
      "Location ID": "12921",
      "Location Name": "CHAZY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9578 STATE ROUTE 9 CHAZY NY 12921 USA "
    },
    {
      "Location ID": "12923",
      "Location Name": "CHURUBUSCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1306 CLINTON MILLS RD CHURUBUSCO NY 12923 USA "
    },
    {
      "Location ID": "12926",
      "Location Name": "CONSTABLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1138 STATE ROUTE 122 CONSTABLE NY 12926 USA "
    },
    {
      "Location ID": "12928",
      "Location Name": "CROWN POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2752 MAIN ST CROWN POINT NY 12928 USA "
    },
    {
      "Location ID": "12929",
      "Location Name": "DANNEMORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1079 COOK ST DANNEMORA NY 12929 USA "
    },
    {
      "Location ID": "12932",
      "Location Name": "ELIZABETHTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 190 WATER ST ELIZABETHTOWN NY 12932 USA "
    },
    {
      "Location ID": "12933",
      "Location Name": "ELLENBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 ST EDMUNDS WAY ELLENBURG NY 12933 USA "
    },
    {
      "Location ID": "12934",
      "Location Name": "ELLENBURG CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 BRANDY BROOK RD ELLENBURG CENTER NY 12934 USA "
    },
    {
      "Location ID": "12935",
      "Location Name": "ELLENBURG DEPOT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4959 STATE ROUTE 11 ELLENBURG DEPOT NY 12935 USA "
    },
    {
      "Location ID": "12936",
      "Location Name": "ESSEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2303 MAIN ST ESSEX NY 12936 USA "
    },
    {
      "Location ID": "12937",
      "Location Name": "FORT COVINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 S WATER ST FORT COVINGTON NY 12937 USA "
    },
    {
      "Location ID": "12939",
      "Location Name": "GABRIELS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 846 STATE ROUTE 86 GABRIELS NY 12939 USA "
    },
    {
      "Location ID": "12944",
      "Location Name": "KEESEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 KENT ST KEESEVILLE NY 12944 USA "
    },
    {
      "Location ID": "12946",
      "Location Name": "LAKE PLACID",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2591 MAIN ST LAKE PLACID NY 12946 USA "
    },
    {
      "Location ID": "12950",
      "Location Name": "LEWIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8550 US ROUTE 9 LEWIS NY 12950 USA "
    },
    {
      "Location ID": "12952",
      "Location Name": "LYON MOUNTAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 LANGEY ST LYON MOUNTAIN NY 12952 USA "
    },
    {
      "Location ID": "12953",
      "Location Name": "MALONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 482 E MAIN ST MALONE NY 12953 USA "
    },
    {
      "Location ID": "12957",
      "Location Name": "MOIRA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 816 STATE ROUTE 11 MOIRA NY 12957 USA "
    },
    {
      "Location ID": "12958",
      "Location Name": "MOOERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2578 STATE ROUTE 11 MOOERS NY 12958 USA "
    },
    {
      "Location ID": "12962",
      "Location Name": "MORRISONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1934 STATE ROUTE 22B MORRISONVILLE NY 12962 USA "
    },
    {
      "Location ID": "12965",
      "Location Name": "NICHOLVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 PORT KENT RD NICHOLVILLE NY 12965 USA "
    },
    {
      "Location ID": "12966",
      "Location Name": "NORTH BANGOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2449 STATE ROUTE 11 NORTH BANGOR NY 12966 USA "
    },
    {
      "Location ID": "12967",
      "Location Name": "NORTH LAWRENCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1971 STATE HIGHWAY 11C NORTH LAWRENCE NY 12967 USA "
    },
    {
      "Location ID": "12972",
      "Location Name": "PERU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 742 BEAR SWAMP RD PERU NY 12972 USA "
    },
    {
      "Location ID": "12974",
      "Location Name": "PORT HENRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3260 BROAD ST PORT HENRY NY 12974 USA "
    },
    {
      "Location ID": "12975",
      "Location Name": "PORT KENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31 FIRST ST PORT KENT NY 12975 USA "
    },
    {
      "Location ID": "12976",
      "Location Name": "RAINBOW LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 555 COUNTY ROUTE 60 RAINBOW LAKE NY 12976 USA "
    },
    {
      "Location ID": "12979",
      "Location Name": "ROUSES POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 CHAPMAN ST ROUSES POINT NY 12979 USA "
    },
    {
      "Location ID": "12983",
      "Location Name": "SARANAC LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 51 BROADWAY SARANAC LAKE NY 12983 USA "
    },
    {
      "Location ID": "12986",
      "Location Name": "TUPPER LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 CLIFF AVE TUPPER LAKE NY 12986 USA "
    },
    {
      "Location ID": "12989",
      "Location Name": "VERMONTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 COLD BROOK RD VERMONTVILLE NY 12989 USA "
    },
    {
      "Location ID": "12992",
      "Location Name": "WEST CHAZY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7634 STATE ROUTE 22 WEST CHAZY NY 12992 USA "
    },
    {
      "Location ID": "12993",
      "Location Name": "WESTPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 HARRIS LN WESTPORT NY 12993 USA "
    },
    {
      "Location ID": "12996",
      "Location Name": "WILLSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3665 ESSEX RD STE 2 WILLSBORO NY 12996 USA "
    },
    {
      "Location ID": "129DT",
      "Location Name": "DAVID'S TEA C/O BEE LINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 W SERVICE RD CHAMPLAIN NY 12919 USA "
    },
    {
      "Location ID": "129IC",
      "Location Name": "IFC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1083 STATE ROUTE 9 CHAMPLAIN NY 12919 USA "
    },
    {
      "Location ID": "129IM",
      "Location Name": "IMPERIAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 LAWRENCE PAQUETTE DR CHAMPLAIN NY 12919 USA "
    },
    {
      "Location ID": "130",
      "Location Name": "SYRACUSE P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5640 E TAFT RD SYRACUSE NY 13220 USA "
    },
    {
      "Location ID": "13020",
      "Location Name": "APULIA STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 979 APULIA RD APULIA STATION NY 13020 USA "
    },
    {
      "Location ID": "13021",
      "Location Name": "AUBURN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 JAMES ST AUBURN NY 13021 USA "
    },
    {
      "Location ID": "13026",
      "Location Name": "AURORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 373 MAIN ST AURORA NY 13026 USA "
    },
    {
      "Location ID": "13027",
      "Location Name": "BALDWINSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 E GENESEE ST BALDWINSVILLE NY 13027 USA "
    },
    {
      "Location ID": "13028",
      "Location Name": "BERNHARDS BAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 694 STATE ROUTE 49 BERNHARDS BAY NY 13028 USA "
    },
    {
      "Location ID": "13029",
      "Location Name": "BREWERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5560 BARTEL RD BREWERTON NY 13029 USA "
    },
    {
      "Location ID": "13030",
      "Location Name": "BRIDGEPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7901 BRIDGEPORT MINOA RD BRIDGEPORT NY 13030 USA "
    },
    {
      "Location ID": "13031",
      "Location Name": "CAMILLUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 KASSON RD CAMILLUS NY 13031 USA "
    },
    {
      "Location ID": "13032",
      "Location Name": "CANASTOTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 S PETERBORO ST CANASTOTA NY 13032 USA "
    },
    {
      "Location ID": "13033",
      "Location Name": "CATO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2544 E MAIN ST CATO NY 13033 USA "
    },
    {
      "Location ID": "13034",
      "Location Name": "CAYUGA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6198 CENTER ST CAYUGA NY 13034 USA "
    },
    {
      "Location ID": "13035",
      "Location Name": "CAZENOVIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 LINCKLAEN ST CAZENOVIA NY 13035 USA "
    },
    {
      "Location ID": "13036",
      "Location Name": "CENTRAL SQUARE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 555 S MAIN ST CENTRAL SQUARE NY 13036 USA "
    },
    {
      "Location ID": "13037",
      "Location Name": "CHITTENANGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1001 E GENESEE ST STE 1 CHITTENANGO NY 13037 USA "
    },
    {
      "Location ID": "13040",
      "Location Name": "CINCINNATUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2763 LOWER CINCINNATUS RD CINCINNATUS NY 13040 USA "
    },
    {
      "Location ID": "13041",
      "Location Name": "CLAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5601 STATE ROUTE 31 CLAY NY 13041 USA "
    },
    {
      "Location ID": "13042",
      "Location Name": "CLEVELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 STATE ROUTE 49 CLEVELAND NY 13042 USA "
    },
    {
      "Location ID": "13043",
      "Location Name": "CLOCKVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7247 OXBOW RD CLOCKVILLE NY 13043 USA "
    },
    {
      "Location ID": "13044",
      "Location Name": "CONSTANTIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 45 REDFIELD ST CONSTANTIA NY 13044 USA "
    },
    {
      "Location ID": "13045",
      "Location Name": "CORTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 88 MAIN ST CORTLAND NY 13045 USA "
    },
    {
      "Location ID": "13051",
      "Location Name": "DELPHI FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2185 ORAN DELPHI RD DELPHI FALLS NY 13051 USA "
    },
    {
      "Location ID": "13052",
      "Location Name": "DE RUYTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1639 CORTLAND ST DE RUYTER NY 13052 USA "
    },
    {
      "Location ID": "13053",
      "Location Name": "DRYDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 W MAIN ST DRYDEN NY 13053 USA "
    },
    {
      "Location ID": "13054",
      "Location Name": "DURHAMVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5346 CANAL ST DURHAMVILLE NY 13054 USA "
    },
    {
      "Location ID": "13057",
      "Location Name": "EAST SYRACUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 404 W MANLIUS ST EAST SYRACUSE NY 13057 USA "
    },
    {
      "Location ID": "13060",
      "Location Name": "ELBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 SOUTH ST ELBRIDGE NY 13060 USA "
    },
    {
      "Location ID": "13061",
      "Location Name": "ERIEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3643 EATON BROOK RD ERIEVILLE NY 13061 USA "
    },
    {
      "Location ID": "13062",
      "Location Name": "ETNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 LOWER CREEK RD ETNA NY 13062 USA "
    },
    {
      "Location ID": "13063",
      "Location Name": "FABIUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1306 KEENEY RD FABIUS NY 13063 USA "
    },
    {
      "Location ID": "13064",
      "Location Name": "FAIR HAVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14452 FANCHER AVE FAIR HAVEN NY 13064 USA "
    },
    {
      "Location ID": "13065",
      "Location Name": "FAYETTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4346 STATE ROUTE 414 FAYETTE NY 13065 USA "
    },
    {
      "Location ID": "13066",
      "Location Name": "FAYETTEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 599 E GENESEE ST FAYETTEVILLE NY 13066 USA "
    },
    {
      "Location ID": "13068",
      "Location Name": "FREEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 46 MAIN ST FREEVILLE NY 13068 USA "
    },
    {
      "Location ID": "13069",
      "Location Name": "FULTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 214 S 1ST ST FULTON NY 13069 USA "
    },
    {
      "Location ID": "13071",
      "Location Name": "GENOA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1034 NORTH ST GENOA NY 13071 USA "
    },
    {
      "Location ID": "13072",
      "Location Name": "GEORGETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1008 STATE ROUTE 26 GEORGETOWN NY 13072 USA "
    },
    {
      "Location ID": "13073",
      "Location Name": "GROTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 E CORTLAND ST GROTON NY 13073 USA "
    },
    {
      "Location ID": "13074",
      "Location Name": "HANNIBAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 VILLAGE SQ HANNIBAL NY 13074 USA "
    },
    {
      "Location ID": "13076",
      "Location Name": "HASTINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 COUNTY ROUTE 45 HASTINGS NY 13076 USA "
    },
    {
      "Location ID": "13077",
      "Location Name": "HOMER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 S MAIN ST HOMER NY 13077 USA "
    },
    {
      "Location ID": "13078",
      "Location Name": "JAMESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6499 E SENECA TPKE JAMESVILLE NY 13078 USA "
    },
    {
      "Location ID": "13080",
      "Location Name": "JORDAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 MECHANIC ST JORDAN NY 13080 USA "
    },
    {
      "Location ID": "13081",
      "Location Name": "KING FERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8761 STATE ROUTE 90 N KING FERRY NY 13081 USA "
    },
    {
      "Location ID": "13082",
      "Location Name": "KIRKVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6365 N KIRKVILLE RD KIRKVILLE NY 13082 USA "
    },
    {
      "Location ID": "13083",
      "Location Name": "LACONA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 PARK AVE LACONA NY 13083 USA "
    },
    {
      "Location ID": "13084",
      "Location Name": "LA FAYETTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2507 US ROUTE 11 LA FAYETTE NY 13084 USA "
    },
    {
      "Location ID": "13087",
      "Location Name": "LITTLE YORK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6002 STATE ROUTE 281 LITTLE YORK NY 13087 USA "
    },
    {
      "Location ID": "13092",
      "Location Name": "LOCKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12199 WATER ST LOCKE NY 13092 USA "
    },
    {
      "Location ID": "13093",
      "Location Name": "LYCOMING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1059 COUNTY ROUTE 29N LYCOMING NY 13093 USA "
    },
    {
      "Location ID": "130PA",
      "Location Name": "SYRACUSE PEAK ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6600 NEW VENTURE GEAR DR EAST SYRACUSE NY 13057 USA "
    },
    {
      "Location ID": "13101",
      "Location Name": "MC GRAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 MAIN ST MC GRAW NY 13101 USA "
    },
    {
      "Location ID": "13102",
      "Location Name": "MC LEAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 SCHOOL ST MC LEAN NY 13102 USA "
    },
    {
      "Location ID": "13103",
      "Location Name": "MALLORY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 77 COUNTY ROUTE 32 MALLORY NY 13103 USA "
    },
    {
      "Location ID": "13104",
      "Location Name": "MANLIUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 WESLEY ST MANLIUS NY 13104 USA "
    },
    {
      "Location ID": "13108",
      "Location Name": "MARCELLUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 E MAIN ST MARCELLUS NY 13108 USA "
    },
    {
      "Location ID": "13110",
      "Location Name": "MARIETTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2796 STATE ROUTE 174 MARIETTA NY 13110 USA "
    },
    {
      "Location ID": "13111",
      "Location Name": "MARTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15422 STATE ROUTE 104 MARTVILLE NY 13111 USA "
    },
    {
      "Location ID": "13112",
      "Location Name": "MEMPHIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1749 STATE ROUTE 173 MEMPHIS NY 13112 USA "
    },
    {
      "Location ID": "13113",
      "Location Name": "MERIDIAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3078 STATE ROUTE 370 MERIDIAN NY 13113 USA "
    },
    {
      "Location ID": "13114",
      "Location Name": "MEXICO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5640 NORMAN AVE MEXICO NY 13114 USA "
    },
    {
      "Location ID": "13115",
      "Location Name": "MINETTO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 COUNTY ROUTE 24 MINETTO NY 13115 USA "
    },
    {
      "Location ID": "13116",
      "Location Name": "MINOA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 N MAIN ST MINOA NY 13116 USA "
    },
    {
      "Location ID": "13117",
      "Location Name": "MONTEZUMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8109 STATE ROUTE 90 MONTEZUMA NY 13117 USA "
    },
    {
      "Location ID": "13118",
      "Location Name": "MORAVIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 S MAIN ST MORAVIA NY 13118 USA "
    },
    {
      "Location ID": "13120",
      "Location Name": "NEDROW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6709 S SALINA ST NEDROW NY 13120 USA "
    },
    {
      "Location ID": "13121",
      "Location Name": "NEW HAVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4250 STATE ROUTE 104 NEW HAVEN NY 13121 USA "
    },
    {
      "Location ID": "13122",
      "Location Name": "NEW WOODSTOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2607 SCHOOL ST NEW WOODSTOCK NY 13122 USA "
    },
    {
      "Location ID": "13123",
      "Location Name": "NORTH BAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2079 STATE ROUTE 49 NORTH BAY NY 13123 USA "
    },
    {
      "Location ID": "13126",
      "Location Name": "OSWEGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 391 W 1ST ST OSWEGO NY 13126 USA "
    },
    {
      "Location ID": "13131",
      "Location Name": "PARISH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2963 E MAIN ST PARISH NY 13131 USA "
    },
    {
      "Location ID": "13132",
      "Location Name": "PENNELLVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 374 COUNTY ROUTE 54 PENNELLVILLE NY 13132 USA "
    },
    {
      "Location ID": "13134",
      "Location Name": "PETERBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4667 PETERBORO RD PETERBORO NY 13134 USA "
    },
    {
      "Location ID": "13135",
      "Location Name": "PHOENIX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 99 JEFFERSON ST PHOENIX NY 13135 USA "
    },
    {
      "Location ID": "13137",
      "Location Name": "PLAINVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8000 PLAINVILLE RD PLAINVILLE NY 13137 USA "
    },
    {
      "Location ID": "13138",
      "Location Name": "POMPEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7360 ACADEMY ST POMPEY NY 13138 USA "
    },
    {
      "Location ID": "13139",
      "Location Name": "POPLAR RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2485 STATE ROUTE 34B POPLAR RIDGE NY 13139 USA "
    },
    {
      "Location ID": "13140",
      "Location Name": "PORT BYRON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 MAIN ST PORT BYRON NY 13140 USA "
    },
    {
      "Location ID": "13141",
      "Location Name": "PREBLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1968 PREBLE RD PREBLE NY 13141 USA "
    },
    {
      "Location ID": "13142",
      "Location Name": "PULASKI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4909 N JEFFERSON ST PULASKI NY 13142 USA "
    },
    {
      "Location ID": "13143",
      "Location Name": "RED CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6755 WOLCOTT ST RED CREEK NY 13143 USA "
    },
    {
      "Location ID": "13144",
      "Location Name": "RICHLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1110 COUNTY ROUTE 48 RICHLAND NY 13144 USA "
    },
    {
      "Location ID": "13145",
      "Location Name": "SANDY CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6067 S MAIN ST SANDY CREEK NY 13145 USA "
    },
    {
      "Location ID": "13146",
      "Location Name": "SAVANNAH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1542 N MAIN ST SAVANNAH NY 13146 USA "
    },
    {
      "Location ID": "13147",
      "Location Name": "SCIPIO CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3354 STATE ROUTE 34 SCIPIO CENTER NY 13147 USA "
    },
    {
      "Location ID": "13148",
      "Location Name": "SENECA FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 38 STATE ST SENECA FALLS NY 13148 USA "
    },
    {
      "Location ID": "13152",
      "Location Name": "SKANEATELES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 FENNELL ST SKANEATELES NY 13152 USA "
    },
    {
      "Location ID": "13153",
      "Location Name": "SKANEATELES FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4564 JORDAN RD SKANEATELES FALLS NY 13153 USA "
    },
    {
      "Location ID": "13154",
      "Location Name": "SOUTH BUTLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3453 STATE ROUTE 89 SOUTH BUTLER NY 13154 USA "
    },
    {
      "Location ID": "13156",
      "Location Name": "STERLING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1325 STATE ROUTE 104A STERLING NY 13156 USA "
    },
    {
      "Location ID": "13157",
      "Location Name": "SYLVAN BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1404 MAIN ST SYLVAN BEACH NY 13157 USA "
    },
    {
      "Location ID": "13158",
      "Location Name": "TRUXTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3706 STATE ROUTE 13 TRUXTON NY 13158 USA "
    },
    {
      "Location ID": "13159",
      "Location Name": "TULLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 ELM ST TULLY NY 13159 USA "
    },
    {
      "Location ID": "13160",
      "Location Name": "UNION SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 CAYUGA ST UNION SPRINGS NY 13160 USA "
    },
    {
      "Location ID": "13162",
      "Location Name": "VERONA BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6783 LAKE SHORE RD N VERONA BEACH NY 13162 USA "
    },
    {
      "Location ID": "13163",
      "Location Name": "WAMPSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 137 N COURT ST WAMPSVILLE NY 13163 USA "
    },
    {
      "Location ID": "13164",
      "Location Name": "WARNERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6454 NEWPORT RD WARNERS NY 13164 USA "
    },
    {
      "Location ID": "13165",
      "Location Name": "WATERLOO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 E MAIN ST WATERLOO NY 13165 USA "
    },
    {
      "Location ID": "13166",
      "Location Name": "WEEDSPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8942 N SENECA ST WEEDSPORT NY 13166 USA "
    },
    {
      "Location ID": "13167",
      "Location Name": "WEST MONROE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2301 STATE ROUTE 49 WEST MONROE NY 13167 USA "
    },
    {
      "Location ID": "131HA",
      "Location Name": "HABERMAASS CORP DBA HABA USA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4407 JORDAN RD SKANEATELES NY 13152 USA "
    },
    {
      "Location ID": "13220",
      "Location Name": "SYRACUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5640 E TAFT RD SYRACUSE NY 13220 USA "
    },
    {
      "Location ID": "133",
      "Location Name": "UTICA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 PITCHER ST UTICA NY 13504 USA "
    },
    {
      "Location ID": "13305",
      "Location Name": "BEAVER FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9572 MAIN ST BEAVER FALLS NY 13305 USA "
    },
    {
      "Location ID": "13309",
      "Location Name": "BOONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 MAIN ST BOONVILLE NY 13309 USA "
    },
    {
      "Location ID": "13313",
      "Location Name": "BRIDGEWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 320 STATE ROUTE 8 BRIDGEWATER NY 13313 USA "
    },
    {
      "Location ID": "13315",
      "Location Name": "BURLINGTON FLATS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6047 STATE HIGHWAY 51 BURLINGTON FLATS NY 13315 USA "
    },
    {
      "Location ID": "13318",
      "Location Name": "CASSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1685 MAIN ST CASSVILLE NY 13318 USA "
    },
    {
      "Location ID": "13319",
      "Location Name": "CHADWICKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3367 ONEIDA ST CHADWICKS NY 13319 USA "
    },
    {
      "Location ID": "13322",
      "Location Name": "CLAYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2370 MAIN ST CLAYVILLE NY 13322 USA "
    },
    {
      "Location ID": "13323",
      "Location Name": "CLINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 COLLEGE ST CLINTON NY 13323 USA "
    },
    {
      "Location ID": "13325",
      "Location Name": "CONSTABLEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3109 MAIN ST CONSTABLEVILLE NY 13325 USA "
    },
    {
      "Location ID": "13327",
      "Location Name": "CROGHAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6915 BANK ST CROGHAN NY 13327 USA "
    },
    {
      "Location ID": "13343",
      "Location Name": "GLENFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6036 MAIN ST GLENFIELD NY 13343 USA "
    },
    {
      "Location ID": "13346",
      "Location Name": "HAMILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 32 BROAD ST HAMILTON NY 13346 USA "
    },
    {
      "Location ID": "13367",
      "Location Name": "LOWVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7651 N STATE ST LOWVILLE NY 13367 USA "
    },
    {
      "Location ID": "13368",
      "Location Name": "LYONS FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3864 FULTON ST LYONS FALLS NY 13368 USA "
    },
    {
      "Location ID": "133CS",
      "Location Name": "CPU COLGATE COLLEGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 OAK DR HAMILTON NY 13346 USA "
    },
    {
      "Location ID": "13402",
      "Location Name": "MADISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7326 STATE ROUTE 20 MADISON NY 13402 USA "
    },
    {
      "Location ID": "13413",
      "Location Name": "NEW HARTFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 CAMPION RD NEW HARTFORD NY 13413 USA "
    },
    {
      "Location ID": "13425",
      "Location Name": "ORISKANY FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 216 MAIN ST ORISKANY FALLS NY 13425 USA "
    },
    {
      "Location ID": "13433",
      "Location Name": "PORT LEYDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7085 W MAIN ST PORT LEYDEN NY 13433 USA "
    },
    {
      "Location ID": "13456",
      "Location Name": "SAUQUOIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2952 ONEIDA ST SAUQUOIT NY 13456 USA "
    },
    {
      "Location ID": "13459",
      "Location Name": "SHARON SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 MAIN ST STE 2 SHARON SPRINGS NY 13459 USA "
    },
    {
      "Location ID": "13473",
      "Location Name": "TURIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4205 STATE ROUTE 26 TURIN NY 13473 USA "
    },
    {
      "Location ID": "13479",
      "Location Name": "WASHINGTON MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3910 ONEIDA ST WASHINGTON MILLS NY 13479 USA "
    },
    {
      "Location ID": "13491",
      "Location Name": "WEST WINFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 376 W MAIN ST WEST WINFIELD NY 13491 USA "
    },
    {
      "Location ID": "136",
      "Location Name": "WATERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 232 COMMERCE PARK DR WATERTOWN NY 13601 USA "
    },
    {
      "Location ID": "13602",
      "Location Name": "FORT DRUM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10720 MOUNT BELVEDERE BLVD FORT DRUM NY 13602 USA "
    },
    {
      "Location ID": "13605",
      "Location Name": "ADAMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 E CHURCH ST ADAMS NY 13605 USA "
    },
    {
      "Location ID": "13606",
      "Location Name": "ADAMS CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13346 NORTH ST ADAMS CENTER NY 13606 USA "
    },
    {
      "Location ID": "13607",
      "Location Name": "ALEXANDRIA BAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 BETHUNE ST ALEXANDRIA BAY NY 13607 USA "
    },
    {
      "Location ID": "13608",
      "Location Name": "ANTWERP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 MAIN ST ANTWERP NY 13608 USA "
    },
    {
      "Location ID": "13612",
      "Location Name": "BLACK RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28553 STATE ROUTE 3 BLACK RIVER NY 13612 USA "
    },
    {
      "Location ID": "13614",
      "Location Name": "BRIER HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 COUNTY ROUTE 2 BRIER HILL NY 13614 USA "
    },
    {
      "Location ID": "13615",
      "Location Name": "BROWNVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 BROWN BLVD BROWNVILLE NY 13615 USA "
    },
    {
      "Location ID": "13616",
      "Location Name": "CALCIUM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24841 SANFORD CORNERS RD CALCIUM NY 13616 USA "
    },
    {
      "Location ID": "13617",
      "Location Name": "CANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 MAIN ST CANTON NY 13617 USA "
    },
    {
      "Location ID": "13618",
      "Location Name": "CAPE VINCENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 362 E BROADWAY ST CAPE VINCENT NY 13618 USA "
    },
    {
      "Location ID": "13619",
      "Location Name": "CARTHAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 521 STATE ST CARTHAGE NY 13619 USA "
    },
    {
      "Location ID": "13620",
      "Location Name": "CASTORLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5158 STATE ROUTE 410 CASTORLAND NY 13620 USA "
    },
    {
      "Location ID": "13621",
      "Location Name": "CHASE MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5275 COUNTY ROUTE 14 CHASE MILLS NY 13621 USA "
    },
    {
      "Location ID": "13622",
      "Location Name": "CHAUMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12061 STATE ROUTE 12E CHAUMONT NY 13622 USA "
    },
    {
      "Location ID": "13624",
      "Location Name": "CLAYTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 236 JOHN ST CLAYTON NY 13624 USA "
    },
    {
      "Location ID": "13626",
      "Location Name": "COPENHAGEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9886 STATE ROUTE 12 COPENHAGEN NY 13626 USA "
    },
    {
      "Location ID": "13627",
      "Location Name": "DEER RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " US POSTAL SERVICE DEER RIVER NY 13627 USA "
    },
    {
      "Location ID": "13628",
      "Location Name": "DEFERIET DPOBU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 68 RIVERSIDE DR DEFERIET NY 13628 USA "
    },
    {
      "Location ID": "13630",
      "Location Name": "DE KALB JUNCTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4329 US HIGHWAY 11 DE KALB JUNCTION NY 13630 USA "
    },
    {
      "Location ID": "13632",
      "Location Name": "DEPAUVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 32523 STATE ROUTE 12 DEPAUVILLE NY 13632 USA "
    },
    {
      "Location ID": "13634",
      "Location Name": "DEXTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 612 BROWN ST DEXTER NY 13634 USA "
    },
    {
      "Location ID": "13635",
      "Location Name": "EDWARDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 196 MAIN ST EDWARDS NY 13635 USA "
    },
    {
      "Location ID": "13637",
      "Location Name": "EVANS MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8496 LERAY ST EVANS MILLS NY 13637 USA "
    },
    {
      "Location ID": "13638",
      "Location Name": "FELTS MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24456 MAIN ST FELTS MILLS NY 13638 USA "
    },
    {
      "Location ID": "13639",
      "Location Name": "FINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 SPRING ST FINE NY 13639 USA "
    },
    {
      "Location ID": "13640",
      "Location Name": "FINEVIEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44392 COUNTY ROUTE 191 WELLESLEY ISLAND NY 13640 USA "
    },
    {
      "Location ID": "13641",
      "Location Name": "FISHERS LANDING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18064 REEDS POINT RD FISHERS LANDING NY 13641 USA "
    },
    {
      "Location ID": "13642",
      "Location Name": "GOUVERNEUR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 GROVE ST GOUVERNEUR NY 13642 USA "
    },
    {
      "Location ID": "13643",
      "Location Name": "GREAT BEND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 32675 STATE ROUTE 26 GREAT BEND NY 13643 USA "
    },
    {
      "Location ID": "13645",
      "Location Name": "HAILESBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 97 MAIN ST HAILESBORO NY 13645 USA "
    },
    {
      "Location ID": "13646",
      "Location Name": "HAMMOND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 S MAIN ST HAMMOND NY 13646 USA "
    },
    {
      "Location ID": "13648",
      "Location Name": "HARRISVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14220 STATE ST HARRISVILLE NY 13648 USA "
    },
    {
      "Location ID": "13649",
      "Location Name": "HELENA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 589 1/2 QUINELL RD HELENA NY 13649 USA "
    },
    {
      "Location ID": "13655",
      "Location Name": "HOGANSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 SAINT REGIS ST HOGANSBURG NY 13655 USA "
    },
    {
      "Location ID": "13656",
      "Location Name": "LA FARGEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36327 STATE ROUTE 180 LA FARGEVILLE NY 13656 USA "
    },
    {
      "Location ID": "13658",
      "Location Name": "LISBON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7007 COUNTY ROUTE 10 LISBON NY 13658 USA "
    },
    {
      "Location ID": "13659",
      "Location Name": "LORRAINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20693 COUNTY ROUTE 93 LORRAINE NY 13659 USA "
    },
    {
      "Location ID": "13660",
      "Location Name": "MADRID",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 CHURCH ST MADRID NY 13660 USA "
    },
    {
      "Location ID": "13661",
      "Location Name": "MANNSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 306 S MAIN ST MANNSVILLE NY 13661 USA "
    },
    {
      "Location ID": "13662",
      "Location Name": "MASSENA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 MAIN ST MASSENA NY 13662 USA "
    },
    {
      "Location ID": "13664",
      "Location Name": "MORRISTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 306 MAIN ST MORRISTOWN NY 13664 USA "
    },
    {
      "Location ID": "13665",
      "Location Name": "NATURAL BRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 43889 STATE ROUTE 3 NATURAL BRIDGE NY 13665 USA "
    },
    {
      "Location ID": "13666",
      "Location Name": "NEWTON FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 926 COUNTY ROUTE 60 NEWTON FALLS NY 13666 USA "
    },
    {
      "Location ID": "13667",
      "Location Name": "NORFOLK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 E HIGH ST NORFOLK NY 13667 USA "
    },
    {
      "Location ID": "13668",
      "Location Name": "NORWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 S MAIN ST NORWOOD NY 13668 USA "
    },
    {
      "Location ID": "13669",
      "Location Name": "OGDENSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 431 STATE ST OGDENSBURG NY 13669 USA "
    },
    {
      "Location ID": "13673",
      "Location Name": "PHILADELPHIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 S MAIN ST PHILADELPHIA NY 13673 USA "
    },
    {
      "Location ID": "13674",
      "Location Name": "PIERREPONT MANOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4685 US ROUTE 11 PIERREPONT MANOR NY 13674 USA "
    },
    {
      "Location ID": "13676",
      "Location Name": "POTSDAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 ELM ST POTSDAM NY 13676 USA "
    },
    {
      "Location ID": "13678",
      "Location Name": "RAYMONDVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8766 STATE HIGHWAY 56 RAYMONDVILLE NY 13678 USA "
    },
    {
      "Location ID": "13679",
      "Location Name": "REDWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 43711 STATE ROUTE 37 REDWOOD NY 13679 USA "
    },
    {
      "Location ID": "13681",
      "Location Name": "RICHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 160 MAIN ST RICHVILLE NY 13681 USA "
    },
    {
      "Location ID": "13682",
      "Location Name": "RODMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12509 SCHOOL ST RODMAN NY 13682 USA "
    },
    {
      "Location ID": "13683",
      "Location Name": "ROOSEVELTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 COUNTY ROUTE 45 ROOSEVELTOWN NY 13683 USA "
    },
    {
      "Location ID": "13685",
      "Location Name": "SACKETS HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 W MAIN ST SACKETS HARBOR NY 13685 USA "
    },
    {
      "Location ID": "13690",
      "Location Name": "STAR LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4052 STATE HIGHWAY 3 STAR LAKE NY 13690 USA "
    },
    {
      "Location ID": "13691",
      "Location Name": "THERESA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 112 COMMERCIAL ST THERESA NY 13691 USA "
    },
    {
      "Location ID": "13693",
      "Location Name": "THREE MILE BAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8685 STATE ROUTE 12E THREE MILE BAY NY 13693 USA "
    },
    {
      "Location ID": "13694",
      "Location Name": "WADDINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23 MAIN ST WADDINGTON NY 13694 USA "
    },
    {
      "Location ID": "13695",
      "Location Name": "WANAKENA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 HAMELE ST WANAKENA NY 13695 USA "
    },
    {
      "Location ID": "136AA",
      "Location Name": "A & AF EXCHANGE SERVICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10730C ENDURING FREEDOM DR FORT DRUM NY 13602 USA "
    },
    {
      "Location ID": "136CC",
      "Location Name": "CPU CLARKSON UNIVERSITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 CLARKSON AVE POTSDAM NY 13676 USA "
    },
    {
      "Location ID": "136DR",
      "Location Name": "DEER RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10734 STATE ROUTE 26 CARTHAGE NY 13619 USA "
    },
    {
      "Location ID": "136EX",
      "Location Name": "A&AF EXCHANGE SERVICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10730C ENDURING FREEDOM DR FORT DRUM NY 13602 USA "
    },
    {
      "Location ID": "136PP",
      "Location Name": "PALEOPAX LLC DBA MONTHLY BOXER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22476 FISHER RD STE 1 WATERTOWN NY 13601 USA "
    },
    {
      "Location ID": "137",
      "Location Name": "BINGHAMTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 HENRY ST BINGHAMTON NY 13902 USA "
    },
    {
      "Location ID": "13730",
      "Location Name": "AFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 89 MAIN ST AFTON NY 13730 USA "
    },
    {
      "Location ID": "13732",
      "Location Name": "APALACHIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6100 STATE ROUTE 434 APALACHIN NY 13732 USA "
    },
    {
      "Location ID": "13733",
      "Location Name": "BAINBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 E MAIN ST BAINBRIDGE NY 13733 USA "
    },
    {
      "Location ID": "13736",
      "Location Name": "BERKSHIRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12324 STATE ROUTE 38 BERKSHIRE NY 13736 USA "
    },
    {
      "Location ID": "13738",
      "Location Name": "BLODGETT MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2320 BLODGETT MILLS RD BLODGETT MILLS NY 13738 USA "
    },
    {
      "Location ID": "13739",
      "Location Name": "BLOOMVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 51948 STATE HIGHWAY 10 BLOOMVILLE NY 13739 USA "
    },
    {
      "Location ID": "13743",
      "Location Name": "CANDOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 62 MAIN ST CANDOR NY 13743 USA "
    },
    {
      "Location ID": "13744",
      "Location Name": "CASTLE CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1094 CASTLE CREEK RD CASTLE CREEK NY 13744 USA "
    },
    {
      "Location ID": "13745",
      "Location Name": "CHENANGO BRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 511 MAIN ST CHENANGO BRIDGE NY 13745 USA "
    },
    {
      "Location ID": "13746",
      "Location Name": "CHENANGO FORKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6554 STATE ROUTE 79 CHENANGO FORKS NY 13746 USA "
    },
    {
      "Location ID": "13747",
      "Location Name": "COLLIERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6173 STATE HIGHWAY 7 COLLIERSVILLE NY 13747 USA "
    },
    {
      "Location ID": "13748",
      "Location Name": "CONKLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1101 CONKLIN RD CONKLIN NY 13748 USA "
    },
    {
      "Location ID": "13749",
      "Location Name": "CORBETTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 CORBETTSVILLE RD CORBETTSVILLE NY 13749 USA "
    },
    {
      "Location ID": "13750",
      "Location Name": "DAVENPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15682 STATE HIGHWAY 23 DAVENPORT NY 13750 USA "
    },
    {
      "Location ID": "13751",
      "Location Name": "DAVENPORT CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11790 STATE HIGHWAY 23 DAVENPORT CENTE NY 13751 USA "
    },
    {
      "Location ID": "13753",
      "Location Name": "DELHI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 COURT ST DELHI NY 13753 USA "
    },
    {
      "Location ID": "13754",
      "Location Name": "DEPOSIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 DEAN ST DEPOSIT NY 13754 USA "
    },
    {
      "Location ID": "13757",
      "Location Name": "EAST MEREDITH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 51 COUNTY HIGHWAY 12 EAST MEREDITH NY 13757 USA "
    },
    {
      "Location ID": "13760",
      "Location Name": "ENDICOTT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 WASHINGTON AVE ENDICOTT NY 13760 USA "
    },
    {
      "Location ID": "13776",
      "Location Name": "GILBERTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 COMMERCIAL ST GILBERTSVILLE NY 13776 USA "
    },
    {
      "Location ID": "13778",
      "Location Name": "GREENE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 S CANAL ST GREENE NY 13778 USA "
    },
    {
      "Location ID": "13780",
      "Location Name": "GUILFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1261 COUNTY ROAD 35 GUILFORD NY 13780 USA "
    },
    {
      "Location ID": "13782",
      "Location Name": "HAMDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35866 STATE HIGHWAY 10 HAMDEN NY 13782 USA "
    },
    {
      "Location ID": "13783",
      "Location Name": "HANCOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 204 WHEELER ST HANCOCK NY 13783 USA "
    },
    {
      "Location ID": "13784",
      "Location Name": "HARFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 405 STATE ROUTE 38 HARFORD NY 13784 USA "
    },
    {
      "Location ID": "13786",
      "Location Name": "HARPERSFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 798 COLONEL HARPER DR HARPERSFIELD NY 13786 USA "
    },
    {
      "Location ID": "13787",
      "Location Name": "HARPURSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3619 STATE ROUTE 79 HARPURSVILLE NY 13787 USA "
    },
    {
      "Location ID": "13788",
      "Location Name": "HOBART",
      "Time Zone Offset (Hours)": 0,
      "Address": " 698 MAIN ST STE 1 HOBART NY 13788 USA "
    },
    {
      "Location ID": "13790",
      "Location Name": "JOHNSON CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 307 MAIN ST JOHNSON CITY NY 13790 USA "
    },
    {
      "Location ID": "13794",
      "Location Name": "KILLAWOG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 614 JENNINGS CREEK RD KILLAWOG NY 13794 USA "
    },
    {
      "Location ID": "13795",
      "Location Name": "KIRKWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 78 CRESCENT DR KIRKWOOD NY 13795 USA "
    },
    {
      "Location ID": "13796",
      "Location Name": "LAURENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 BROOK ST LAURENS NY 13796 USA "
    },
    {
      "Location ID": "13797",
      "Location Name": "LISLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8988 MAIN ST LISLE NY 13797 USA "
    },
    {
      "Location ID": "137HC",
      "Location Name": "HANK’S CLOTHING FOR MEN AND WOMEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3119 PEARL ST ENDICOTT NY 13760 USA "
    },
    {
      "Location ID": "13802",
      "Location Name": "MAINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2597 MAIN ST MAINE NY 13802 USA "
    },
    {
      "Location ID": "13803",
      "Location Name": "MARATHON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 MAIN ST MARATHON NY 13803 USA "
    },
    {
      "Location ID": "13804",
      "Location Name": "MASONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15505 STATE HIGHWAY 8 MASONVILLE NY 13804 USA "
    },
    {
      "Location ID": "13807",
      "Location Name": "MILFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 43 S MAIN ST MILFORD NY 13807 USA "
    },
    {
      "Location ID": "13808",
      "Location Name": "MORRIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 127 MAIN ST MORRIS NY 13808 USA "
    },
    {
      "Location ID": "13809",
      "Location Name": "MOUNT UPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1753 STATE HIGHWAY 8 MOUNT UPTON NY 13809 USA "
    },
    {
      "Location ID": "13810",
      "Location Name": "MOUNT VISION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2363 STATE HIGHWAY 205 MOUNT VISION NY 13810 USA "
    },
    {
      "Location ID": "13811",
      "Location Name": "NEWARK VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 PARK ST NEWARK VALLEY NY 13811 USA "
    },
    {
      "Location ID": "13812",
      "Location Name": "NICHOLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 491 W RIVER RD NICHOLS NY 13812 USA "
    },
    {
      "Location ID": "13813",
      "Location Name": "NINEVEH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3303 STATE ROUTE 7 NINEVAH NY 13813 USA "
    },
    {
      "Location ID": "13814",
      "Location Name": "NORTH NORWICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 168 COUNTY ROAD 23 NORTH NORWICH NY 13814 USA "
    },
    {
      "Location ID": "13815",
      "Location Name": "NORWICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20-22 E MAIN ST NORWICH NY 13815 USA "
    },
    {
      "Location ID": "13820",
      "Location Name": "ONEONTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 352 MAIN ST ONEONTA NY 13820 USA "
    },
    {
      "Location ID": "13825",
      "Location Name": "OTEGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 RIVER ST OTEGO NY 13825 USA "
    },
    {
      "Location ID": "13827",
      "Location Name": "OWEGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 LAKE ST OWEGO NY 13827 USA "
    },
    {
      "Location ID": "13830",
      "Location Name": "OXFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 S WASHINGTON RD OXFORD NY 13830 USA "
    },
    {
      "Location ID": "13833",
      "Location Name": "PORT CRANE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 CANAL ST PORT CRANE NY 13833 USA "
    },
    {
      "Location ID": "13834",
      "Location Name": "PORTLANDVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2846 STATE HIGHWAY 28 PORTLANDVILLE NY 13834 USA "
    },
    {
      "Location ID": "13835",
      "Location Name": "RICHFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1007 STATE RTE 79 RICHFORD NY 13835 USA "
    },
    {
      "Location ID": "13838",
      "Location Name": "SIDNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 MANG DR SIDNEY NY 13838 USA "
    },
    {
      "Location ID": "13839",
      "Location Name": "SIDNEY CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10445 COUNTY HIGHWAY 23 SIDNEY CENTER NY 13839 USA "
    },
    {
      "Location ID": "13843",
      "Location Name": "SOUTH NEW BERLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6338 STATE HIGHWAY 23 SOUTH NEW BERLIN NY 13843 USA "
    },
    {
      "Location ID": "13847",
      "Location Name": "TROUT CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8650 COUNTY HIGHWAY 27 TROUT CREEK NY 13847 USA "
    },
    {
      "Location ID": "13848",
      "Location Name": "TUNNEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 688 TUNNEL RD TUNNEL NY 13848 USA "
    },
    {
      "Location ID": "13849",
      "Location Name": "UNADILLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 BRIDGE ST UNADILLA NY 13849 USA "
    },
    {
      "Location ID": "13850",
      "Location Name": "VESTAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 RANO BLVD VESTAL NY 13850 USA "
    },
    {
      "Location ID": "13856",
      "Location Name": "WALTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 GARDINER PL WALTON NY 13856 USA "
    },
    {
      "Location ID": "13859",
      "Location Name": "WELLS BRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 COUNTY HIGHWAY  4 WELLS BRIDGE NY 13859 USA "
    },
    {
      "Location ID": "13860",
      "Location Name": "WEST DAVENPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37 CHARLOTTE CREEK RD WEST DAVENPORT NY 13860 USA "
    },
    {
      "Location ID": "13861",
      "Location Name": "WEST ONEONTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3912 STATE HIGHWAY 23 WEST ONEONTA NY 13861 USA "
    },
    {
      "Location ID": "13862",
      "Location Name": "WHITNEY POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2641 MAIN ST WHITNEY POINT NY 13862 USA "
    },
    {
      "Location ID": "13864",
      "Location Name": "WILLSEYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 WILLSEYVILLE RD WILLSEYVILLE NY 13864 USA "
    },
    {
      "Location ID": "13865",
      "Location Name": "WINDSOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 652 OLD ROUTE 17 WINDSOR NY 13865 USA "
    },
    {
      "Location ID": "138BB",
      "Location Name": "BEST BUY REGIONAL DISTRIBUTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 BERRY RD NICHOLS NY 13812 USA "
    },
    {
      "Location ID": "138DD",
      "Location Name": "DIRIG DESIGN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2251 STATE HIGHWAY 7 UNADILLA NY 13849 USA "
    },
    {
      "Location ID": "138SC",
      "Location Name": "STERICYCLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31 LOWER RIVER ST ONEONTA NY 13820 USA "
    },
    {
      "Location ID": "13H",
      "Location Name": "BINGHAMTON (NY) STC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 PINE CAMP DR BINGHAMTON NY 13904 USA "
    },
    {
      "Location ID": "140",
      "Location Name": "LOG BUFFALO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1200 WILLIAM ST RM 201 BUFFALO NY 14240 USA "
    },
    {
      "Location ID": "14001",
      "Location Name": "AKRON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 MAIN ST AKRON NY 14001 USA "
    },
    {
      "Location ID": "14004",
      "Location Name": "ALDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13127 BROADWAY ST ALDEN NY 14004 USA "
    },
    {
      "Location ID": "14005",
      "Location Name": "ALEXANDER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10506 MAIN ST ALEXANDER NY 14005 USA "
    },
    {
      "Location ID": "14006",
      "Location Name": "ANGOLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 N MAIN ST ANGOLA NY 14006 USA "
    },
    {
      "Location ID": "14008",
      "Location Name": "APPLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1924 HESS RD APPLETON NY 14008 USA "
    },
    {
      "Location ID": "14009",
      "Location Name": "ARCADE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23 EDWARD ST ARCADE NY 14009 USA "
    },
    {
      "Location ID": "14010",
      "Location Name": "ATHOL SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4480 OLD CAMP RD ATHOL SPRINGS NY 14010 USA "
    },
    {
      "Location ID": "14011",
      "Location Name": "ATTICA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 76 MAIN ST ATTICA NY 14011 USA "
    },
    {
      "Location ID": "14012",
      "Location Name": "BARKER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8720 MAIN ST BARKER NY 14012 USA "
    },
    {
      "Location ID": "14013",
      "Location Name": "BASOM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7156 ALLEGHANY RD BASOM NY 14013 USA "
    },
    {
      "Location ID": "14020",
      "Location Name": "BATAVIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 W MAIN ST BATAVIA NY 14020 USA "
    },
    {
      "Location ID": "14024",
      "Location Name": "BLISS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6664 ROUTE 362 BLISS NY 14024 USA "
    },
    {
      "Location ID": "14025",
      "Location Name": "BOSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9025 BOSTON STATE RD BOSTON NY 14025 USA "
    },
    {
      "Location ID": "14026",
      "Location Name": "BOWMANSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 515 HARRIS HILL RD BOWMANSVILLE NY 14026 USA "
    },
    {
      "Location ID": "14027",
      "Location Name": "BRANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10778 BRANT ANGOLA RD BRANT NY 14027 USA "
    },
    {
      "Location ID": "14029",
      "Location Name": "CENTERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10985B RUSHFORD RD CENTERVILLE NY 14029 USA "
    },
    {
      "Location ID": "14031",
      "Location Name": "CLARENCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9845 MAIN ST CLARENCE NY 14031 USA "
    },
    {
      "Location ID": "14032",
      "Location Name": "CLARENCE CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6045 RAILROAD ST CLARENCE CENTER NY 14032 USA "
    },
    {
      "Location ID": "14034",
      "Location Name": "COLLINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2275 MAIN ST COLLINS NY 14034 USA "
    },
    {
      "Location ID": "14035",
      "Location Name": "COLLINS CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3592 MAIN ST COLLINS CENTER NY 14035 USA "
    },
    {
      "Location ID": "14036",
      "Location Name": "CORFU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 W MAIN ST CORFU NY 14036 USA "
    },
    {
      "Location ID": "14039",
      "Location Name": "DALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1302 DALE RD DALE NY 14039 USA "
    },
    {
      "Location ID": "14040",
      "Location Name": "DARIEN CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1402 BROADWAY DARIEN CENTER NY 14040 USA "
    },
    {
      "Location ID": "14041",
      "Location Name": "DAYTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9593 ROUTE 62 DAYTON NY 14041 USA "
    },
    {
      "Location ID": "14043",
      "Location Name": "DEPEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 165 WARSAW ST DEPEW NY 14043 USA "
    },
    {
      "Location ID": "14047",
      "Location Name": "DERBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7350 ERIE RD DERBY NY 14047 USA "
    },
    {
      "Location ID": "14048",
      "Location Name": "DUNKIRK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 410 CENTRAL AVE DUNKIRK NY 14048 USA "
    },
    {
      "Location ID": "14051",
      "Location Name": "EAST AMHERST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9830 TRANSIT RD EAST AMHERST NY 14051 USA "
    },
    {
      "Location ID": "14052",
      "Location Name": "EAST AURORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 408 QUAKER RD EAST AURORA NY 14052 USA "
    },
    {
      "Location ID": "14056",
      "Location Name": "EAST PEMBROKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8643 READ RD EAST PEMBROKE NY 14056 USA "
    },
    {
      "Location ID": "14057",
      "Location Name": "EDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8230 N MAIN ST EDEN NY 14057 USA "
    },
    {
      "Location ID": "14058",
      "Location Name": "ELBA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 N MAIN ST ELBA NY 14058 USA "
    },
    {
      "Location ID": "14059",
      "Location Name": "ELMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2300 BOWEN RD ELMA NY 14059 USA "
    },
    {
      "Location ID": "14060",
      "Location Name": "FARMERSVILLE STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 994 ELTON RD FARMERSVILLE STATION NY 14060 USA "
    },
    {
      "Location ID": "14061",
      "Location Name": "FARNHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 461 COMMERCIAL ST FARNHAM NY 14061 USA "
    },
    {
      "Location ID": "14062",
      "Location Name": "FORESTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 MAIN ST FORESTVILLE NY 14062 USA "
    },
    {
      "Location ID": "14063",
      "Location Name": "FREDONIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 DAY ST FREDONIA NY 14063 USA "
    },
    {
      "Location ID": "14065",
      "Location Name": "FREEDOM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11481 ROUTE 98 FREEDOM NY 14065 USA "
    },
    {
      "Location ID": "14066",
      "Location Name": "GAINESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 S MAIN ST GAINESVILLE NY 14066 USA "
    },
    {
      "Location ID": "14067",
      "Location Name": "GASPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8391 ROCHESTER RD GASPORT NY 14067 USA "
    },
    {
      "Location ID": "14068",
      "Location Name": "GETZVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2655 MILLERSPORT HWY GETZVILLE NY 14068 USA "
    },
    {
      "Location ID": "14070",
      "Location Name": "GOWANDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 63 W MAIN ST GOWANDA NY 14070 USA "
    },
    {
      "Location ID": "14072",
      "Location Name": "GRAND ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2391 BASELINE RD GRAND ISLAND NY 14072 USA "
    },
    {
      "Location ID": "14075",
      "Location Name": "HAMBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5501 CAMP RD HAMBURG NY 14075 USA "
    },
    {
      "Location ID": "14081",
      "Location Name": "IRVING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12746 ERIE ST IRVING NY 14081 USA "
    },
    {
      "Location ID": "14086",
      "Location Name": "LANCASTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5406 BROADWAY ST LANCASTER NY 14086 USA "
    },
    {
      "Location ID": "14091",
      "Location Name": "LAWTONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12525 GOWANDA STATE RD LAWTONS NY 14091 USA "
    },
    {
      "Location ID": "14092",
      "Location Name": "LEWISTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 S 8TH ST LEWISTON NY 14092 USA "
    },
    {
      "Location ID": "14094",
      "Location Name": "LOCKPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 138 EAST AVE LOCKPORT NY 14094 USA "
    },
    {
      "Location ID": "14098",
      "Location Name": "LYNDONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 S MAIN ST LYNDONVILLE NY 14098 USA "
    },
    {
      "Location ID": "140DL",
      "Location Name": "LANCASTER DEPEW CARRIER UNIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1763 COMO PARK BLVD DEPEW NY 14043 USA "
    },
    {
      "Location ID": "14103",
      "Location Name": "MEDINA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 128 W CENTER ST MEDINA NY 14103 USA "
    },
    {
      "Location ID": "14105",
      "Location Name": "MIDDLEPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 42 MAIN ST MIDDLEPORT NY 14105 USA "
    },
    {
      "Location ID": "14107",
      "Location Name": "MODEL CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4746 MODEL CITY RD MODEL CITY NY 14107 USA "
    },
    {
      "Location ID": "14108",
      "Location Name": "NEWFANE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6000 EDWARD AVE NEWFANE NY 14108 USA "
    },
    {
      "Location ID": "14109",
      "Location Name": "NIAGARA UNIVERSITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 VINCENTIAN DR NIAGARA UNIVERSITY NY 14109 USA "
    },
    {
      "Location ID": "14110",
      "Location Name": "NORTH BOSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7170 BOSTON STATE RD NORTH BOSTON NY 14110 USA "
    },
    {
      "Location ID": "14111",
      "Location Name": "NORTH COLLINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10607 MAIN ST NORTH COLLINS NY 14111 USA "
    },
    {
      "Location ID": "14112",
      "Location Name": "NORTH EVANS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6971 VERSAILLES RD NORTH EVANS NY 14112 USA "
    },
    {
      "Location ID": "14120",
      "Location Name": "NORTH TONAWANDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 141 GOUNDRY ST NORTH TONAWANDA NY 14120 USA "
    },
    {
      "Location ID": "14125",
      "Location Name": "OAKFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 MAIN ST OAKFIELD NY 14125 USA "
    },
    {
      "Location ID": "14126",
      "Location Name": "OLCOTT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1617 LOCKPORT OLCOTT RD OLCOTT NY 14126 USA "
    },
    {
      "Location ID": "14127",
      "Location Name": "ORCHARD PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 SCHOOL ST ORCHARD PARK NY 14127 USA "
    },
    {
      "Location ID": "14129",
      "Location Name": "PERRYSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11980 MAIN ST PERRYSBURG NY 14129 USA "
    },
    {
      "Location ID": "14130",
      "Location Name": "PIKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 59 E MAIN ST PIKE NY 14130 USA "
    },
    {
      "Location ID": "14131",
      "Location Name": "RANSOMVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2567 YOUNGSTOWN LOCKPORT RD RANSOMVILLE NY 14131 USA "
    },
    {
      "Location ID": "14132",
      "Location Name": "SANBORN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2901 NIAGARA ST SANBORN NY 14132 USA "
    },
    {
      "Location ID": "14135",
      "Location Name": "SHERIDAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2707 MAIN RD SHERIDAN NY 14135 USA "
    },
    {
      "Location ID": "14136",
      "Location Name": "SILVER CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37 MAIN ST SILVER CREEK NY 14136 USA "
    },
    {
      "Location ID": "14138",
      "Location Name": "SOUTH DAYTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 PARK ST SOUTH DAYTON NY 14138 USA "
    },
    {
      "Location ID": "14140",
      "Location Name": "SPRING BROOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6150 SENECA ST SPRING BROOK NY 14140 USA "
    },
    {
      "Location ID": "14141",
      "Location Name": "SPRINGVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 75 FRANKLIN ST SPRINGVILLE NY 14141 USA "
    },
    {
      "Location ID": "14143",
      "Location Name": "STAFFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8899 ROUTE 237 STAFFORD NY 14143 USA "
    },
    {
      "Location ID": "14144",
      "Location Name": "STELLA NIAGARA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4421 LOWER RIVER RD STELLA NIAGARA NY 14144 USA "
    },
    {
      "Location ID": "14150",
      "Location Name": "TONAWANDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 96 SEYMOUR ST TONAWANDA NY 14150 USA "
    },
    {
      "Location ID": "14168",
      "Location Name": "VERSAILLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11980 DIVISION ST VERSAILLES NY 14168 USA "
    },
    {
      "Location ID": "14172",
      "Location Name": "WILSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 367 MCCHESNEY ST WILSON NY 14172 USA "
    },
    {
      "Location ID": "14174",
      "Location Name": "YOUNGSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 135 LOCKPORT ST YOUNGSTOWN NY 14174 USA "
    },
    {
      "Location ID": "141BI",
      "Location Name": "BRENT INDUSTRIES (MIDDLEPORT)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9952 MOUNTAIN RD MIDDLEPORT NY 14105 USA "
    },
    {
      "Location ID": "141FT",
      "Location Name": "141FT FEDEX TRADE NETWORKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 555 RIVERWALK PKWY TONAWANDA NY 14150 USA "
    },
    {
      "Location ID": "141ZD",
      "Location Name": "ZENGER DMU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 777 E PARK DR TONAWANDA NY 14150 USA "
    },
    {
      "Location ID": "142",
      "Location Name": "BUFFALO ASF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1200 WILLIAM ST BUFFALO NY 14240 USA "
    },
    {
      "Location ID": "14225",
      "Location Name": "CHEEKTOWAGA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 GALLERIA DR BUFFALO NY 14225 USA "
    },
    {
      "Location ID": "142CC",
      "Location Name": "CHRISTMAS CENTRAL / GORDONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 85 INNSBRUCK DR CHEEKTOWAGA NY 14227 USA "
    },
    {
      "Location ID": "142FG",
      "Location Name": "142FG FEDEX GROUND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3779 LAKE SHORE R BLASDELL NY 14219 USA "
    },
    {
      "Location ID": "14301",
      "Location Name": "NIAGARA FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 615 MAIN ST STE 1 NIAGARA FALLS NY 14301 USA "
    },
    {
      "Location ID": "14304",
      "Location Name": "LA SALLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9860 NIAGARA FALLS BLVD NIAGARA FALLS NY 14304 USA "
    },
    {
      "Location ID": "144",
      "Location Name": "LOG ROCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1335 JEFFERSON RD ROCHESTER NY 14692 USA "
    },
    {
      "Location ID": "14410",
      "Location Name": "ADAMS BASIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4303 CANAL RD ADAMS BASIN NY 14410 USA "
    },
    {
      "Location ID": "14411",
      "Location Name": "ALBION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 S MAIN ST ALBION NY 14411 USA "
    },
    {
      "Location ID": "14413",
      "Location Name": "ALTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8483 RIDGE RD ALTON NY 14413 USA "
    },
    {
      "Location ID": "14414",
      "Location Name": "AVON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 PROSPECT ST AVON NY 14414 USA "
    },
    {
      "Location ID": "14420",
      "Location Name": "BROCKPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 MAIN ST BROCKPORT NY 14420 USA "
    },
    {
      "Location ID": "14424",
      "Location Name": "CANANDAIGUA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 405 EASTERN BLVD CANANDAIGUA NY 14424 USA "
    },
    {
      "Location ID": "14425",
      "Location Name": "FARMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1560 STATE ROUTE 332 FARMINGTON NY 14425 USA "
    },
    {
      "Location ID": "14429",
      "Location Name": "CLARENDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4540 HOLLEY BYRON RD CLARENDON NY 14429 USA "
    },
    {
      "Location ID": "14430",
      "Location Name": "CLARKSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3720 LAKE RD CLARKSON NY 14430 USA "
    },
    {
      "Location ID": "14432",
      "Location Name": "CLIFTON SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 SPRING ST CLIFTON SPRINGS NY 14432 USA "
    },
    {
      "Location ID": "14433",
      "Location Name": "CLYDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 S PARK ST CLYDE NY 14433 USA "
    },
    {
      "Location ID": "14435",
      "Location Name": "CONESUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6154 S LIVONIA RD CONESUS NY 14435 USA "
    },
    {
      "Location ID": "14437",
      "Location Name": "DANSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 MAIN ST DANSVILLE NY 14437 USA "
    },
    {
      "Location ID": "14445",
      "Location Name": "EAST ROCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 W COMMERCIAL ST EAST ROCHESTER NY 14445 USA "
    },
    {
      "Location ID": "14449",
      "Location Name": "EAST WILLIAMSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4995 RIDGE RD EAST WILLIAMSON NY 14449 USA "
    },
    {
      "Location ID": "14450",
      "Location Name": "FAIRPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 770 AYRAULT RD FAIRPORT NY 14450 USA "
    },
    {
      "Location ID": "14452",
      "Location Name": "FANCHER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15865 MILLION DOLLAR HWY FANCHER NY 14452 USA "
    },
    {
      "Location ID": "14453",
      "Location Name": "FISHERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7839 MAIN ST FISHERS NY 14453 USA "
    },
    {
      "Location ID": "14454",
      "Location Name": "GENESEO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 SOUTH ST GENESEO NY 14454 USA "
    },
    {
      "Location ID": "14456",
      "Location Name": "GENEVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 67 CASTLE ST GENEVA NY 14456 USA "
    },
    {
      "Location ID": "14461",
      "Location Name": "GORHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2621 MAIN ST GORHAM NY 14461 USA "
    },
    {
      "Location ID": "14462",
      "Location Name": "GROVELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7426 GROVELAND STATION RD GROVELAND NY 14462 USA "
    },
    {
      "Location ID": "14464",
      "Location Name": "HAMLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 133 RAILROAD AVE HAMLIN NY 14464 USA "
    },
    {
      "Location ID": "14466",
      "Location Name": "HEMLOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4625 MAIN ST HEMLOCK NY 14466 USA "
    },
    {
      "Location ID": "14468",
      "Location Name": "HILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 SOUTH AVE HILTON NY 14468 USA "
    },
    {
      "Location ID": "14469",
      "Location Name": "BLOOMFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 ELM ST BLOOMFIELD NY 14469 USA "
    },
    {
      "Location ID": "14470",
      "Location Name": "HOLLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 WRIGHT ST HOLLEY NY 14470 USA "
    },
    {
      "Location ID": "14471",
      "Location Name": "HONEOYE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8627 MAIN ST HONEOYE NY 14471 USA "
    },
    {
      "Location ID": "14472",
      "Location Name": "HONEOYE FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 W MAIN ST HONEOYE FALLS NY 14472 USA "
    },
    {
      "Location ID": "14475",
      "Location Name": "IONIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8289 COUNTY ROAD 14 IONIA NY 14475 USA "
    },
    {
      "Location ID": "14476",
      "Location Name": "KENDALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1870 KENDALL RD KENDALL NY 14476 USA "
    },
    {
      "Location ID": "14477",
      "Location Name": "KENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1788 KENT RD KENT NY 14477 USA "
    },
    {
      "Location ID": "14478",
      "Location Name": "KEUKA PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 508 ASSEMBLY AVE KEUKA PARK NY 14478 USA "
    },
    {
      "Location ID": "14480",
      "Location Name": "LAKEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5832 BIG TREE RD LAKEVILLE NY 14480 USA "
    },
    {
      "Location ID": "14481",
      "Location Name": "LEICESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 MAIN ST LEICESTER NY 14481 USA "
    },
    {
      "Location ID": "14485",
      "Location Name": "LIMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7275 W MAIN ST LIMA NY 14485 USA "
    },
    {
      "Location ID": "14487",
      "Location Name": "LIVONIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 MAIN ST LIVONIA NY 14487 USA "
    },
    {
      "Location ID": "14489",
      "Location Name": "LYONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 PEARL ST LYONS NY 14489 USA "
    },
    {
      "Location ID": "144AX",
      "Location Name": "NW ROCHESTER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 800 PHILLIPS RD BUILDING 200 WEBSTER NY 14653 USA "
    },
    {
      "Location ID": "144PM",
      "Location Name": "LOG NORTHWEST ROCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1700 LYELL AVE ROCHESTER NY 14606 USA "
    },
    {
      "Location ID": "14502",
      "Location Name": "MACEDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1475 CANANDAIGUA RD MACEDON NY 14502 USA "
    },
    {
      "Location ID": "14504",
      "Location Name": "MANCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 S MAIN ST MANCHESTER NY 14504 USA "
    },
    {
      "Location ID": "14505",
      "Location Name": "MARION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3785 S MAIN ST MARION NY 14505 USA "
    },
    {
      "Location ID": "14506",
      "Location Name": "MENDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 53 ASSEMBLY DR MENDON NY 14506 USA "
    },
    {
      "Location ID": "14507",
      "Location Name": "MIDDLESEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1213 MAIN ST MIDDLESEX NY 14507 USA "
    },
    {
      "Location ID": "14508",
      "Location Name": "MORTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1205 COUNTY LINE RD STE 1 MORTON NY 14508 USA "
    },
    {
      "Location ID": "14510",
      "Location Name": "MOUNT MORRIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31 CHAPEL ST MOUNT MORRIS NY 14510 USA "
    },
    {
      "Location ID": "14512",
      "Location Name": "NAPLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 137 N MAIN ST NAPLES NY 14512 USA "
    },
    {
      "Location ID": "14513",
      "Location Name": "NEWARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 S MAIN ST NEWARK NY 14513 USA "
    },
    {
      "Location ID": "14515",
      "Location Name": "NORTH GREECE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 640 N GREECE RD NORTH GREECE NY 14515 USA "
    },
    {
      "Location ID": "14516",
      "Location Name": "NORTH ROSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10374 E RAILROAD AVE NORTH ROSE NY 14516 USA "
    },
    {
      "Location ID": "14517",
      "Location Name": "NUNDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 S STATE ST NUNDA NY 14517 USA "
    },
    {
      "Location ID": "14518",
      "Location Name": "OAKS CORNERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2091 PRE EMPTION RD OAKS CORNERS NY 14518 USA "
    },
    {
      "Location ID": "14519",
      "Location Name": "ONTARIO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2001 FURGUSON ST ONTARIO NY 14519 USA "
    },
    {
      "Location ID": "14520",
      "Location Name": "ONTARIO CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1544 RIDGE RD ONTARIO CENTER NY 14520 USA "
    },
    {
      "Location ID": "14521",
      "Location Name": "OVID",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7161 MAIN ST OVID NY 14521 USA "
    },
    {
      "Location ID": "14522",
      "Location Name": "PALMYRA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 522 E MAIN ST PALMYRA NY 14522 USA "
    },
    {
      "Location ID": "14526",
      "Location Name": "PENFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2080 FAIRPORT NINE MILE POINT RD PENFIELD NY 14526 USA "
    },
    {
      "Location ID": "14527",
      "Location Name": "PENN YAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 159 MAIN ST PENN YAN NY 14527 USA "
    },
    {
      "Location ID": "14529",
      "Location Name": "PERKINSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1904 MAIN ST PERKINSVILLE NY 14529 USA "
    },
    {
      "Location ID": "14530",
      "Location Name": "PERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 MAIN ST N PERRY NY 14530 USA "
    },
    {
      "Location ID": "14532",
      "Location Name": "PHELPS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 CHURCH ST PHELPS NY 14532 USA "
    },
    {
      "Location ID": "14533",
      "Location Name": "PIFFARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3215 GENESEE ST PIFFARD NY 14533 USA "
    },
    {
      "Location ID": "14534",
      "Location Name": "PITTSFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5607 PALMYRA RD PITTSFORD NY 14534 USA "
    },
    {
      "Location ID": "14536",
      "Location Name": "PORTAGEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6676 HAMILTON ST PORTAGEVILLE NY 14536 USA "
    },
    {
      "Location ID": "14537",
      "Location Name": "PORT GIBSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41 ATWATER ST PORT GIBSON NY 14537 USA "
    },
    {
      "Location ID": "14538",
      "Location Name": "PULTNEYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4133 MILL ST PULTNEYVILLE NY 14538 USA "
    },
    {
      "Location ID": "14539",
      "Location Name": "RETSOF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3681 RETSOF RD RETSOF NY 14539 USA "
    },
    {
      "Location ID": "14541",
      "Location Name": "ROMULUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1969 SENECA ST ROMULUS NY 14541 USA "
    },
    {
      "Location ID": "14542",
      "Location Name": "ROSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10660 WOLCOTT ST ROSE NY 14542 USA "
    },
    {
      "Location ID": "14543",
      "Location Name": "RUSH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6144 RUSH LIMA RD RUSH NY 14543 USA "
    },
    {
      "Location ID": "14544",
      "Location Name": "RUSHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 S MAIN ST RUSHVILLE NY 14544 USA "
    },
    {
      "Location ID": "14547",
      "Location Name": "SENECA CASTLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2081 BUFFALO ST SENECA CASTLE NY 14547 USA "
    },
    {
      "Location ID": "14548",
      "Location Name": "SHORTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 SHELDON ST SHORTSVILLE NY 14548 USA "
    },
    {
      "Location ID": "14551",
      "Location Name": "SODUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44 W MAIN ST SODUS NY 14551 USA "
    },
    {
      "Location ID": "14555",
      "Location Name": "SODUS POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8432 BAY ST SODUS POINT NY 14555 USA "
    },
    {
      "Location ID": "14556",
      "Location Name": "SONYEA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 56 PORTER DR SONYEA NY 14556 USA "
    },
    {
      "Location ID": "14559",
      "Location Name": "SPENCERPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 AMITY ST SPENCERPORT NY 14559 USA "
    },
    {
      "Location ID": "14560",
      "Location Name": "SPRINGWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7886 N MAIN ST SPRINGWATER NY 14560 USA "
    },
    {
      "Location ID": "14561",
      "Location Name": "STANLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2102 STATE ROUTE 245 STANLEY NY 14561 USA "
    },
    {
      "Location ID": "14563",
      "Location Name": "UNION HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 82 RIDGE RD UNION HILL NY 14563 USA "
    },
    {
      "Location ID": "14564",
      "Location Name": "VICTOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 FRAMARK DR VICTOR NY 14564 USA "
    },
    {
      "Location ID": "14568",
      "Location Name": "WALWORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2263 WALWORTH MARION RD WALWORTH NY 14568 USA "
    },
    {
      "Location ID": "14569",
      "Location Name": "WARSAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 S MAIN ST WARSAW NY 14569 USA "
    },
    {
      "Location ID": "14571",
      "Location Name": "WATERPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1975 MAIN ST WATERPORT NY 14571 USA "
    },
    {
      "Location ID": "14572",
      "Location Name": "WAYLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23 E NAPLES ST WAYLAND NY 14572 USA "
    },
    {
      "Location ID": "14580",
      "Location Name": "WEBSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 75 BARRETT DR WEBSTER NY 14580 USA "
    },
    {
      "Location ID": "14585",
      "Location Name": "WEST BLOOMFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9222 STATE ROUTE 5 AND 20 WEST BLOOMFIELD NY 14585 USA "
    },
    {
      "Location ID": "14588",
      "Location Name": "WILLARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1435 PROSPECT ST WILLARD NY 14588 USA "
    },
    {
      "Location ID": "14589",
      "Location Name": "WILLIAMSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6144 STATE ROUTE 21 WILLIAMSON NY 14589 USA "
    },
    {
      "Location ID": "14590",
      "Location Name": "WOLCOTT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6048 LAKE AVE WOLCOTT NY 14590 USA "
    },
    {
      "Location ID": "14592",
      "Location Name": "YORK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2706 MAIN ST YORK NY 14592 USA "
    },
    {
      "Location ID": "145CM",
      "Location Name": "COUNTRYMAX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6290 STATE ROUTE 96 VICTOR NY 14564 USA "
    },
    {
      "Location ID": "145GT",
      "Location Name": "GUERRILLA TEES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1290 BLOSSOM DR VICTOR NY 14564 USA "
    },
    {
      "Location ID": "145HB",
      "Location Name": "HBAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 788 OLD DUTCH RD VICTOR NY 14564 USA "
    },
    {
      "Location ID": "145LP",
      "Location Name": "LAST PIECE OF THE PUZZLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 VOAK RD STANLEY NY 14561 USA "
    },
    {
      "Location ID": "145VO",
      "Location Name": "VELOCITY OUTDOORS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1360 COUNTY ROAD 8 SHORTSVILLE NY 14548 USA "
    },
    {
      "Location ID": "145WS",
      "Location Name": "WOLFF-SMG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1641 COMMONS PKWY MACEDON NY 14502 USA "
    },
    {
      "Location ID": "145WW",
      "Location Name": "CPU 420 BAY RIDGE MOBIL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 420 RIDGE RD WEBSTER NY 14580 USA "
    },
    {
      "Location ID": "147",
      "Location Name": "JAMESTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 E 3RD ST JAMESTOWN NY 14701 USA "
    },
    {
      "Location ID": "14701",
      "Location Name": "JAMESTOWN, NY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 E 3RD ST JAMESTOWN NY 14701 USA "
    },
    {
      "Location ID": "14709",
      "Location Name": "ANGELICA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 W MAIN ST ANGELICA NY 14709 USA "
    },
    {
      "Location ID": "14710",
      "Location Name": "ASHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2181 N MAPLE AVE ASHVILLE NY 14710 USA "
    },
    {
      "Location ID": "14711",
      "Location Name": "BELFAST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 53 S MAIN ST BELFAST NY 14711 USA "
    },
    {
      "Location ID": "14712",
      "Location Name": "BEMUS POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 MAIN ST BEMUS POINT NY 14712 USA "
    },
    {
      "Location ID": "14714",
      "Location Name": "BLACK CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6391 ROUTE 305 BLACK CREEK NY 14714 USA "
    },
    {
      "Location ID": "14715",
      "Location Name": "BOLIVAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 279 MAIN ST BOLIVAR NY 14715 USA "
    },
    {
      "Location ID": "14716",
      "Location Name": "BROCTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 42 E MAIN ST BROCTON NY 14716 USA "
    },
    {
      "Location ID": "14717",
      "Location Name": "CANEADEA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8915 STATE ROUTE 19 CANEADEA NY 14717 USA "
    },
    {
      "Location ID": "14718",
      "Location Name": "CASSADAGA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 MAPLE AVE CASSADAGA NY 14718 USA "
    },
    {
      "Location ID": "14719",
      "Location Name": "CATTARAUGUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 MEMORIAL DR CATTARAUGUS NY 14719 USA "
    },
    {
      "Location ID": "14720",
      "Location Name": "CELORON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 BOULEVARD AVE CELORON NY 14720 USA "
    },
    {
      "Location ID": "14721",
      "Location Name": "CERES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 227 MAIN ST CERES NY 14721 USA "
    },
    {
      "Location ID": "14723",
      "Location Name": "CHERRY CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6746 MAIN ST CHERRY CREEK NY 14723 USA "
    },
    {
      "Location ID": "14724",
      "Location Name": "CLYMER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8727 E MAIN ST CLYMER NY 14724 USA "
    },
    {
      "Location ID": "14726",
      "Location Name": "CONEWANGO VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5496 ROUTE 62 CONEWANGO VALLEY NY 14726 USA "
    },
    {
      "Location ID": "14727",
      "Location Name": "CUBA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 WATER ST CUBA NY 14727 USA "
    },
    {
      "Location ID": "14728",
      "Location Name": "DEWITTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5452 E LAKE RD DEWITTVILLE NY 14728 USA "
    },
    {
      "Location ID": "14729",
      "Location Name": "EAST OTTO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8991 REED HILL RD EAST OTTO NY 14729 USA "
    },
    {
      "Location ID": "14731",
      "Location Name": "ELLICOTTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 WASHINGTON ST ELLICOTTVILLE NY 14731 USA "
    },
    {
      "Location ID": "14732",
      "Location Name": "ELLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5006 MILL ST ELLINGTON NY 14732 USA "
    },
    {
      "Location ID": "14733",
      "Location Name": "FALCONER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 W EVERETT ST FALCONER NY 14733 USA "
    },
    {
      "Location ID": "14735",
      "Location Name": "FILLMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 54 W MAIN ST FILLMORE NY 14735 USA "
    },
    {
      "Location ID": "14737",
      "Location Name": "FRANKLINVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 S MAIN ST FRANKLINVILLE NY 14737 USA "
    },
    {
      "Location ID": "14738",
      "Location Name": "FREWSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 E MAIN ST FREWSBURG NY 14738 USA "
    },
    {
      "Location ID": "14739",
      "Location Name": "FRIENDSHIP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 W WATER ST FRIENDSHIP NY 14739 USA "
    },
    {
      "Location ID": "14740",
      "Location Name": "GERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4465 ROUTE 60 GERRY NY 14740 USA "
    },
    {
      "Location ID": "14741",
      "Location Name": "GREAT VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6027 DEPOT ST GREAT VALLEY NY 14741 USA "
    },
    {
      "Location ID": "14742",
      "Location Name": "GREENHURST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2871 GREENHURST AVE GREENHURST NY 14742 USA "
    },
    {
      "Location ID": "14743",
      "Location Name": "HINSDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3722 MAIN ST HINSDALE NY 14743 USA "
    },
    {
      "Location ID": "14744",
      "Location Name": "HOUGHTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9672 ROUTE 19 HOUGHTON NY 14744 USA "
    },
    {
      "Location ID": "14745",
      "Location Name": "HUME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10912 COUNTY ROAD 23 HUME NY 14745 USA "
    },
    {
      "Location ID": "14747",
      "Location Name": "KENNEDY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3600 CHURCH ST KENNEDY NY 14747 USA "
    },
    {
      "Location ID": "14750",
      "Location Name": "LAKEWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 W FAIRMOUNT AVE LAKEWOOD NY 14750 USA "
    },
    {
      "Location ID": "14752",
      "Location Name": "LILY DALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 SOUTH ST LILY DALE NY 14752 USA "
    },
    {
      "Location ID": "14754",
      "Location Name": "LITTLE GENESEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8258 STATE ROUTE 417 LITTLE GENESEE NY 14754 USA "
    },
    {
      "Location ID": "14755",
      "Location Name": "LITTLE VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 MAIN ST LITTLE VALLEY NY 14755 USA "
    },
    {
      "Location ID": "14757",
      "Location Name": "MAYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 E CHAUTAUQUA ST MAYVILLE NY 14757 USA "
    },
    {
      "Location ID": "14758",
      "Location Name": "NIOBE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5967 PANAMA NIOBE RD NIOBE NY 14758 USA "
    },
    {
      "Location ID": "14760",
      "Location Name": "OLEAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 S UNION ST OLEAN NY 14760 USA "
    },
    {
      "Location ID": "14766",
      "Location Name": "OTTO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9120 OTTO EAST OTTO RD OTTO NY 14766 USA "
    },
    {
      "Location ID": "14767",
      "Location Name": "PANAMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 E MAIN ST PANAMA NY 14767 USA "
    },
    {
      "Location ID": "14769",
      "Location Name": "PORTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6372 W MAIN RD PORTLAND NY 14769 USA "
    },
    {
      "Location ID": "14770",
      "Location Name": "PORTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 S MAIN ST PORTVILLE NY 14770 USA "
    },
    {
      "Location ID": "14772",
      "Location Name": "RANDOLPH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90 MAIN ST RANDOLPH NY 14772 USA "
    },
    {
      "Location ID": "14774",
      "Location Name": "RICHBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 MAIN ST RICHBURG NY 14774 USA "
    },
    {
      "Location ID": "14775",
      "Location Name": "RIPLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 W MAIN ST RIPLEY NY 14775 USA "
    },
    {
      "Location ID": "14777",
      "Location Name": "RUSHFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9019 MAIN ST RUSHFORD NY 14777 USA "
    },
    {
      "Location ID": "14779",
      "Location Name": "SALAMANCA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 CHESTNUT ST SALAMANCA NY 14779 USA "
    },
    {
      "Location ID": "14782",
      "Location Name": "SINCLAIRVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 MAIN ST SINCLAIRVILLE NY 14782 USA "
    },
    {
      "Location ID": "14783",
      "Location Name": "STEAMBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2560 ROUTE 394 STEAMBURG NY 14783 USA "
    },
    {
      "Location ID": "14784",
      "Location Name": "STOCKTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7254 ROUTE 380 STOCKTON NY 14784 USA "
    },
    {
      "Location ID": "14785",
      "Location Name": "STOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3479 OLD BRIDGE RD STOW NY 14785 USA "
    },
    {
      "Location ID": "14786",
      "Location Name": "WEST CLARKSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2941 ROUTE 305 WEST CLARKSVILLE NY 14786 USA "
    },
    {
      "Location ID": "14787",
      "Location Name": "WESTFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 ACADEMY ST WESTFIELD NY 14787 USA "
    },
    {
      "Location ID": "14788",
      "Location Name": "WESTONS MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1319 OLEAN PORTVILLE RD WESTONS MILLS NY 14788 USA "
    },
    {
      "Location ID": "147CU",
      "Location Name": "CUTCO RETURNS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 322 HOUGHTON AVE OLEAN NY 14760 USA "
    },
    {
      "Location ID": "148",
      "Location Name": "ELMIRA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1580 SULLIVAN ST ELMIRA NY 14901 USA "
    },
    {
      "Location ID": "14801",
      "Location Name": "ADDISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 FRONT ST ADDISON NY 14801 USA "
    },
    {
      "Location ID": "14802",
      "Location Name": "ALFRED",
      "Time Zone Offset (Hours)": 0,
      "Address": " 63 N MAIN ST ALFRED NY 14802 USA "
    },
    {
      "Location ID": "14803",
      "Location Name": "ALFRED STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 623 STATE ROUTE 244 ALFRED STATION NY 14803 USA "
    },
    {
      "Location ID": "14804",
      "Location Name": "ALMOND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 49 MAIN ST ALMOND NY 14804 USA "
    },
    {
      "Location ID": "14805",
      "Location Name": "ALPINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5908 COUNTY ROAD 11 ALPINE NY 14805 USA "
    },
    {
      "Location ID": "14806",
      "Location Name": "ANDOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 MAIN ST ANDOVER NY 14806 USA "
    },
    {
      "Location ID": "14807",
      "Location Name": "ARKPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 MAIN ST ARKPORT NY 14807 USA "
    },
    {
      "Location ID": "14810",
      "Location Name": "BATH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 LIBERTY ST BATH NY 14810 USA "
    },
    {
      "Location ID": "14812",
      "Location Name": "BEAVER DAMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2347 COUNTY ROAD 20 BEAVER DAMS NY 14812 USA "
    },
    {
      "Location ID": "14813",
      "Location Name": "BELMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 SCHUYLER ST BELMONT NY 14813 USA "
    },
    {
      "Location ID": "14814",
      "Location Name": "BIG FLATS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 471 MAPLE ST BIG FLATS NY 14814 USA "
    },
    {
      "Location ID": "14816",
      "Location Name": "BREESPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 657 MAIN ST BREESPORT NY 14816 USA "
    },
    {
      "Location ID": "14818",
      "Location Name": "BURDETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3986 LAKE AVE BURDETT NY 14818 USA "
    },
    {
      "Location ID": "14820",
      "Location Name": "CAMERON MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6785 COUNTY ROUTE 119 CAMERON MILLS NY 14820 USA "
    },
    {
      "Location ID": "14821",
      "Location Name": "CAMPBELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8753 MAIN ST CAMPBELL NY 14821 USA "
    },
    {
      "Location ID": "14822",
      "Location Name": "CANASERAGA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 59 MAIN ST CANASERAGA NY 14822 USA "
    },
    {
      "Location ID": "14823",
      "Location Name": "CANISTEO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 DEPOT ST CANISTEO NY 14823 USA "
    },
    {
      "Location ID": "14824",
      "Location Name": "CAYUTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6338 STATE ROUTE 224 CAYUTA NY 14824 USA "
    },
    {
      "Location ID": "14825",
      "Location Name": "CHEMUNG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 733 MAIN ST CHEMUNG NY 14825 USA "
    },
    {
      "Location ID": "14827",
      "Location Name": "COOPERS PLAINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 158 MAIN ST COOPERS PLAINS NY 14827 USA "
    },
    {
      "Location ID": "14830",
      "Location Name": "CORNING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 198 BAKER ST CORNING NY 14830 USA "
    },
    {
      "Location ID": "14836",
      "Location Name": "DALTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9959 STATE ST DALTON NY 14836 USA "
    },
    {
      "Location ID": "14837",
      "Location Name": "DUNDEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 MAIN ST DUNDEE NY 14837 USA "
    },
    {
      "Location ID": "14838",
      "Location Name": "ERIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 336 WHEATON RD ERIN NY 14838 USA "
    },
    {
      "Location ID": "14839",
      "Location Name": "GREENWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2698 MAIN ST GREENWOOD NY 14839 USA "
    },
    {
      "Location ID": "14841",
      "Location Name": "HECTOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5640 STATE ROUTE 414 HECTOR NY 14841 USA "
    },
    {
      "Location ID": "14842",
      "Location Name": "HIMROD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3619 HIMROD RD HIMROD NY 14842 USA "
    },
    {
      "Location ID": "14843",
      "Location Name": "HORNELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23 GENESEE ST HORNELL NY 14843 USA "
    },
    {
      "Location ID": "14845",
      "Location Name": "HORSEHEADS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 624 TUTTLE AVE HORSEHEADS NY 14845 USA "
    },
    {
      "Location ID": "14846",
      "Location Name": "HUNT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9926 SMITH HILL RD HUNT NY 14846 USA "
    },
    {
      "Location ID": "14847",
      "Location Name": "INTERLAKEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8409 MAIN ST INTERLAKEN NY 14847 USA "
    },
    {
      "Location ID": "14850",
      "Location Name": "ITHACA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 757 WARREN RD ITHACA NY 14850 USA "
    },
    {
      "Location ID": "14851",
      "Location Name": "ITHACA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 213 N TIOGA ST ITHACA NY 14851 USA "
    },
    {
      "Location ID": "14854",
      "Location Name": "JACKSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1852 TRUMANSBURG RD JACKSONVILLE NY 14854 USA "
    },
    {
      "Location ID": "14855",
      "Location Name": "JASPER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3807 LIBRARY ST JASPER NY 14855 USA "
    },
    {
      "Location ID": "14857",
      "Location Name": "LAKEMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5160 LAKEMONT HIMROD RD LAKEMONT NY 14857 USA "
    },
    {
      "Location ID": "14858",
      "Location Name": "LINDLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 635 COUNTY ROUTE 115 LINDLEY NY 14858 USA "
    },
    {
      "Location ID": "14859",
      "Location Name": "LOCKWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1557 STATE ROUTE 34 LOCKWOOD NY 14859 USA "
    },
    {
      "Location ID": "14860",
      "Location Name": "LODI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8455 N MAIN ST LODI NY 14860 USA "
    },
    {
      "Location ID": "14861",
      "Location Name": "LOWMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1931 COUNTY ROUTE 60 LOWMAN NY 14861 USA "
    },
    {
      "Location ID": "14863",
      "Location Name": "MECKLENBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6123 MECKLENBURG RD MECKLENBURG NY 14863 USA "
    },
    {
      "Location ID": "14864",
      "Location Name": "MILLPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 BURCH HILL RD MILLPORT NY 14864 USA "
    },
    {
      "Location ID": "14865",
      "Location Name": "MONTOUR FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 309 W MAIN ST MONTOUR FALLS NY 14865 USA "
    },
    {
      "Location ID": "14867",
      "Location Name": "NEWFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 298 MAIN ST NEWFIELD NY 14867 USA "
    },
    {
      "Location ID": "14869",
      "Location Name": "ODESSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 223 W MAIN ST ODESSA NY 14869 USA "
    },
    {
      "Location ID": "14870",
      "Location Name": "PAINTED POST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 135 N HAMILTON ST PAINTED POST NY 14870 USA "
    },
    {
      "Location ID": "14871",
      "Location Name": "PINE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1565 PENNSYLVANIA AVE PINE CITY NY 14871 USA "
    },
    {
      "Location ID": "14872",
      "Location Name": "PINE VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3703 WATKINS RD PINE VALLEY NY 14872 USA "
    },
    {
      "Location ID": "14876",
      "Location Name": "READING CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4051 STATE ROUTE 14A READING CENTER NY 14876 USA "
    },
    {
      "Location ID": "14878",
      "Location Name": "ROCK STREAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 278 ROCK STREAM RD ROCK STREAM NY 14878 USA "
    },
    {
      "Location ID": "14879",
      "Location Name": "SAVONA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 47 MAIN ST SAVONA NY 14879 USA "
    },
    {
      "Location ID": "14880",
      "Location Name": "SCIO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4422 W SCIOTA ST SCIO NY 14880 USA "
    },
    {
      "Location ID": "14883",
      "Location Name": "SPENCER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 196 N MAIN ST SPENCER NY 14883 USA "
    },
    {
      "Location ID": "14884",
      "Location Name": "SWAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2215 COUNTY ROAD 24 SWAIN NY 14884 USA "
    },
    {
      "Location ID": "14885",
      "Location Name": "TROUPSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 878 STATE ROUTE 36 TROUPSBURG NY 14885 USA "
    },
    {
      "Location ID": "14886",
      "Location Name": "TRUMANSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 67 W MAIN ST TRUMANSBURG NY 14886 USA "
    },
    {
      "Location ID": "14887",
      "Location Name": "TYRONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 815 COUNTY ROAD 23 TYRONE NY 14887 USA "
    },
    {
      "Location ID": "14889",
      "Location Name": "VAN ETTEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 MAIN ST VAN ETTEN NY 14889 USA "
    },
    {
      "Location ID": "14891",
      "Location Name": "WATKINS GLEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 N FRANKLIN ST WATKINS GLEN NY 14891 USA "
    },
    {
      "Location ID": "14892",
      "Location Name": "WAVERLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 434 WAVERLY ST WAVERLY NY 14892 USA "
    },
    {
      "Location ID": "14893",
      "Location Name": "WAYNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4894 COUNTY ROUTE 26 WAYNE NY 14893 USA "
    },
    {
      "Location ID": "14894",
      "Location Name": "WELLSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3685 FRONT ST WELLSBURG NY 14894 USA "
    },
    {
      "Location ID": "14895",
      "Location Name": "WELLSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 E PEARL ST WELLSVILLE NY 14895 USA "
    },
    {
      "Location ID": "14898",
      "Location Name": "WOODHULL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1617 MAIN ST WOODHULL NY 14898 USA "
    },
    {
      "Location ID": "150",
      "Location Name": "PITTSBURGH PA P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1001 CALIFORNIA AVENUE RM 2057A PITTSBURGH PA 15290 USA "
    },
    {
      "Location ID": "15001",
      "Location Name": "ALIQUIPPA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 517 FRANKLIN AVE ALIQUIPPA PA 15001 USA "
    },
    {
      "Location ID": "15003",
      "Location Name": "AMBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1020 MERCHANT ST AMBRIDGE PA 15003 USA "
    },
    {
      "Location ID": "15004",
      "Location Name": "ATLASBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1620 SMITH TWP STATE RD ATLASBURG PA 15004 USA "
    },
    {
      "Location ID": "15005",
      "Location Name": "BADEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 398 STATE ST BADEN PA 15005 USA "
    },
    {
      "Location ID": "15006",
      "Location Name": "BAIRDFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 453 BAIRDFORD RD BAIRDFORD PA 15006 USA "
    },
    {
      "Location ID": "15007",
      "Location Name": "BAKERSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37 WINWOOD PLZ BAKERSTOWN PA 15007 USA "
    },
    {
      "Location ID": "15009",
      "Location Name": "BEAVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 777 CORPORATION ST BEAVER PA 15009 USA "
    },
    {
      "Location ID": "15010",
      "Location Name": "BEAVER FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 720 11TH ST BEAVER FALLS PA 15010 USA "
    },
    {
      "Location ID": "15012",
      "Location Name": "BELLE VERNON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 611 CIRCLE DR ROSTRAVER TOWNSHIP PA 15012 USA "
    },
    {
      "Location ID": "15014",
      "Location Name": "BRACKENRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1101 BRACKENRIDGE AVE BRACKENRIDGE PA 15014 USA "
    },
    {
      "Location ID": "15015",
      "Location Name": "BRADFORDWOODS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 SELDOM SEEN RD BRADFORDWOODS PA 15015 USA "
    },
    {
      "Location ID": "15017",
      "Location Name": "BRIDGEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 700 WASHINGTON AVE BRIDGEVILLE PA 15017 USA "
    },
    {
      "Location ID": "15018",
      "Location Name": "BUENA VISTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 CONROY ST BUENA VISTA PA 15018 USA "
    },
    {
      "Location ID": "15019",
      "Location Name": "BULGER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1021 GRANT ST BULGER PA 15019 USA "
    },
    {
      "Location ID": "15020",
      "Location Name": "BUNOLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 358 BUNOLA RIVER RD BUNOLA PA 15020 USA "
    },
    {
      "Location ID": "15021",
      "Location Name": "BURGETTSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1602 MAIN ST BURGETTSTOWN PA 15021 USA "
    },
    {
      "Location ID": "15022",
      "Location Name": "CHARLEROI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 MCKEAN AVE CHARLEROI PA 15022 USA "
    },
    {
      "Location ID": "15024",
      "Location Name": "CHESWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1601 PITTSBURGH ST CHESWICK PA 15024 USA "
    },
    {
      "Location ID": "15025",
      "Location Name": "CLAIRTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 445 SAINT CLAIR AVE CLAIRTON PA 15025 USA "
    },
    {
      "Location ID": "15026",
      "Location Name": "CLINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3738 WASHINGTON RD CLINTON PA 15026 USA "
    },
    {
      "Location ID": "15027",
      "Location Name": "CONWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1216 3RD AVE CONWAY PA 15027 USA "
    },
    {
      "Location ID": "15028",
      "Location Name": "COULTERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 211 TOURMAN ST COULTERS PA 15028 USA "
    },
    {
      "Location ID": "15030",
      "Location Name": "CREIGHTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1029 FREEPORT RD CREIGHTON PA 15030 USA "
    },
    {
      "Location ID": "15031",
      "Location Name": "CUDDY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 615 MILLERS RUN RD CUDDY PA 15031 USA "
    },
    {
      "Location ID": "15032",
      "Location Name": "CURTISVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1778 SAXONBURG BLVD CURTISVILLE PA 15032 USA "
    },
    {
      "Location ID": "15033",
      "Location Name": "DONORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 599 MELDON AVE DONORA PA 15033 USA "
    },
    {
      "Location ID": "15034",
      "Location Name": "DRAVOSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 RICHLAND AVE STE 5 DRAVOSBURG PA 15034 USA "
    },
    {
      "Location ID": "15037",
      "Location Name": "ELIZABETH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 320 S 2ND AVE ELIZABETH PA 15037 USA "
    },
    {
      "Location ID": "15038",
      "Location Name": "ELRAMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 DAVIDSON AVE ELRAMA PA 15038 USA "
    },
    {
      "Location ID": "15042",
      "Location Name": "FREEDOM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 601 8TH ST FREEDOM PA 15042 USA "
    },
    {
      "Location ID": "15043",
      "Location Name": "GEORGETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 MARKET ST GEORGETOWN PA 15043 USA "
    },
    {
      "Location ID": "15044",
      "Location Name": "GIBSONIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4098 GIBSONIA RD GIBSONIA PA 15044 USA "
    },
    {
      "Location ID": "15045",
      "Location Name": "GLASSPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 730 MONONGAHELA AVE GLASSPORT PA 15045 USA "
    },
    {
      "Location ID": "15047",
      "Location Name": "GREENOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 710 ZIMMER LN GREENOCK PA 15047 USA "
    },
    {
      "Location ID": "15049",
      "Location Name": "HARWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1400 PILLOW AVE HARWICK PA 15049 USA "
    },
    {
      "Location ID": "15050",
      "Location Name": "HOOKSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 MILL ST HOOKSTOWN PA 15050 USA "
    },
    {
      "Location ID": "15051",
      "Location Name": "INDIANOLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 898 ROUTE 910 STE 2 INDIANOLA PA 15051 USA "
    },
    {
      "Location ID": "15052",
      "Location Name": "INDUSTRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1620A MIDLAND BEAVER RD INDUSTRY PA 15052 USA "
    },
    {
      "Location ID": "15053",
      "Location Name": "JOFFRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 587 JOFFRE BULGER RD JOFFRE PA 15053 USA "
    },
    {
      "Location ID": "15054",
      "Location Name": "LANGELOTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1450 LANGELOTH RD LANGELOTH PA 15054 USA "
    },
    {
      "Location ID": "15055",
      "Location Name": "LAWRENCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 SCHOOL ST LAWRENCE PA 15055 USA "
    },
    {
      "Location ID": "15056",
      "Location Name": "LEETSDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 QUAKER VILLAGE SHOPPING CTR LEETSDALE PA 15056 USA "
    },
    {
      "Location ID": "15057",
      "Location Name": "MC DONALD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 W BARR ST MC DONALD PA 15057 USA "
    },
    {
      "Location ID": "15059",
      "Location Name": "MIDLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 831 MIDLAND AVE MIDLAND PA 15059 USA "
    },
    {
      "Location ID": "15060",
      "Location Name": "MIDWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 JEFFERSON ST MIDWAY PA 15060 USA "
    },
    {
      "Location ID": "15061",
      "Location Name": "MONACA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1015 PENNSYLVANIA AVE MONACA PA 15061 USA "
    },
    {
      "Location ID": "15062",
      "Location Name": "MONESSEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 821 DONNER AVE MONESSEN PA 15062 USA "
    },
    {
      "Location ID": "15063",
      "Location Name": "MONONGAHELA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 312 CHESS ST MONONGAHELA PA 15063 USA "
    },
    {
      "Location ID": "15064",
      "Location Name": "MORGAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 514 MILLERS RUN RD MORGAN PA 15064 USA "
    },
    {
      "Location ID": "15065",
      "Location Name": "NATRONA HEIGHTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1715 UNION AVE NATRONA HEIGHTS PA 15065 USA "
    },
    {
      "Location ID": "15066",
      "Location Name": "NEW BRIGHTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 510 11TH ST NEW BRIGHTON PA 15066 USA "
    },
    {
      "Location ID": "15067",
      "Location Name": "NEW EAGLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 203 MAIN ST NEW EAGLE PA 15067 USA "
    },
    {
      "Location ID": "15068",
      "Location Name": "NEW KENSINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 501 11TH ST NEW KENSINGTON PA 15068 USA "
    },
    {
      "Location ID": "15071",
      "Location Name": "OAKDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 UNION AVE OAKDALE PA 15071 USA "
    },
    {
      "Location ID": "15072",
      "Location Name": "PRICEDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 133 MAIN ST PRICEDALE PA 15072 USA "
    },
    {
      "Location ID": "15074",
      "Location Name": "ROCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 257 CONNECTICUT AVE ROCHESTER PA 15074 USA "
    },
    {
      "Location ID": "15075",
      "Location Name": "RURAL RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 165 LITTLE DEER CREEK RD RURAL RIDGE PA 15075 USA "
    },
    {
      "Location ID": "15076",
      "Location Name": "RUSSELLTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 733 LITTLE DEER CREEK VALLEY RD RUSSELLTON PA 15076 USA "
    },
    {
      "Location ID": "15077",
      "Location Name": "SHIPPINGPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 162 STATE ROUTE 3016 SHIPPINGPORT PA 15077 USA "
    },
    {
      "Location ID": "15078",
      "Location Name": "SLOVAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 SMITH PARK DR SLOVAN PA 15078 USA "
    },
    {
      "Location ID": "15081",
      "Location Name": "SOUTH HEIGHTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3963 JORDAN ST SOUTH HEIGHTS PA 15081 USA "
    },
    {
      "Location ID": "15082",
      "Location Name": "STURGEON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 507 MAIN ST STURGEON PA 15082 USA "
    },
    {
      "Location ID": "15083",
      "Location Name": "SUTERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 141 3RD AVE SUTERSVILLE PA 15083 USA "
    },
    {
      "Location ID": "15084",
      "Location Name": "TARENTUM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 708 CORBET ST TARENTUM PA 15084 USA "
    },
    {
      "Location ID": "15085",
      "Location Name": "TRAFFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 411 CAVITT AVE TRAFFORD PA 15085 USA "
    },
    {
      "Location ID": "15087",
      "Location Name": "WEBSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 703 WALL ST WEBSTER PA 15087 USA "
    },
    {
      "Location ID": "15088",
      "Location Name": "WEST ELIZABETH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 604 GRANT ST WEST ELIZABETH PA 15088 USA "
    },
    {
      "Location ID": "15089",
      "Location Name": "WEST NEWTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 VINE ST WEST NEWTON PA 15089 USA "
    },
    {
      "Location ID": "15090",
      "Location Name": "WEXFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10675 PERRY HWY WEXFORD PA 15090 USA "
    },
    {
      "Location ID": "15091",
      "Location Name": "WILDWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2507 WILDWOOD RD WILDWOOD PA 15091 USA "
    },
    {
      "Location ID": "150CA",
      "Location Name": "PITTSBURGH CMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1967 EASTERN AVE VERONA PA 15147 USA "
    },
    {
      "Location ID": "150DC",
      "Location Name": "DELTA CARE RX (BURGETTSTOWN)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 264 SMITH TOWNSHIP STATE RD STE 5 BURGETTSTOWN PA 15021 USA "
    },
    {
      "Location ID": "150FZ",
      "Location Name": "LOG PITTSBURGH PA PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 553 AVENUE A BLDG 15 LEETSDALE PA 15056 USA "
    },
    {
      "Location ID": "150HF",
      "Location Name": "HARD TO FIND BRANDS(MCDONALD)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2525 QUICKSILVER RD MC DONALD PA 15057 USA "
    },
    {
      "Location ID": "150PM",
      "Location Name": "LOG PENNWOOD PLACE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 51 PENNWOOD PL STE 100 WARRENDALE PA 15086 USA "
    },
    {
      "Location ID": "150SP",
      "Location Name": "FEDEX SMARTPOST PITTSBURGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2291 SWEENEY DR BLDG 2 CLINTON PA 15026 USA "
    },
    {
      "Location ID": "15101",
      "Location Name": "ALLISON PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4746 WILLIAM FLYNN HWY ALLISON PARK PA 15101 USA "
    },
    {
      "Location ID": "15104",
      "Location Name": "BRADDOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1110 BRADDOCK AVE BRADDOCK PA 15104 USA "
    },
    {
      "Location ID": "15106",
      "Location Name": "CARNEGIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 139 E MAIN ST CARNEGIE PA 15106 USA "
    },
    {
      "Location ID": "15108",
      "Location Name": "CORAOPOLIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1140 THORN RUN RD CORAOPOLIS PA 15108 USA "
    },
    {
      "Location ID": "15110",
      "Location Name": "DUQUESNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 S 1ST ST DUQUESNE PA 15110 USA "
    },
    {
      "Location ID": "15112",
      "Location Name": "EAST PITTSBURGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 701 LINDEN AVE EAST PITTSBURGH PA 15112 USA "
    },
    {
      "Location ID": "15116",
      "Location Name": "GLENSHAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1541 BUTLER PLANK RD GLENSHAW PA 15116 USA "
    },
    {
      "Location ID": "15126",
      "Location Name": "IMPERIAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 PENN LINCOLN DR IMPERIAL PA 15126 USA "
    },
    {
      "Location ID": "15127",
      "Location Name": "INGOMAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 736 W INGOMAR RD INGOMAR PA 15127 USA "
    },
    {
      "Location ID": "15134",
      "Location Name": "MCKEESPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 850 WALNUT ST MCKEESPORT PA 15134 USA "
    },
    {
      "Location ID": "15137",
      "Location Name": "NORTH VERSAILLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 353 LINCOLN HWY NORTH VERSAILLES PA 15137 USA "
    },
    {
      "Location ID": "15139",
      "Location Name": "OAKMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 530 ALLEGHENY RIVER BLVD OAKMONT PA 15139 USA "
    },
    {
      "Location ID": "15142",
      "Location Name": "PRESTO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5234 THOMS RUN RD PRESTO PA 15142 USA "
    },
    {
      "Location ID": "15143",
      "Location Name": "SEWICKLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 521 THORN ST SEWICKLEY PA 15143 USA "
    },
    {
      "Location ID": "15144",
      "Location Name": "SPRINGDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 896 PITTSBURGH ST SPRINGDALE PA 15144 USA "
    },
    {
      "Location ID": "15145",
      "Location Name": "TURTLE CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 GRANT ST TURTLE CREEK PA 15145 USA "
    },
    {
      "Location ID": "15146",
      "Location Name": "MONROEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2630 MONROEVILLE BLVD MONROEVILLE PA 15146 USA "
    },
    {
      "Location ID": "15147",
      "Location Name": "VERONA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 620 ALLEGHENY RIVER BLVD VERONA PA 15147 USA "
    },
    {
      "Location ID": "15148",
      "Location Name": "WILMERDING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 410 STATION ST WILMERDING PA 15148 USA "
    },
    {
      "Location ID": "151PA",
      "Location Name": "PITTSBURGH AUTO ELECTRIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4085 ALPHA DR ALLISON PARK PA 15101 USA "
    },
    {
      "Location ID": "152",
      "Location Name": "PITTSBURGH (CITY DOCK) PA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1001 CALIFORNIA AVE PITTSBURGH PA 15233 USA "
    },
    {
      "Location ID": "15206",
      "Location Name": "EAST LIBERTY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6360 BROAD ST PITTSBURGH PA 15206 USA "
    },
    {
      "Location ID": "15213",
      "Location Name": "OAKLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 347 S BOUQUET ST PITTSBURGH PA 15213 USA "
    },
    {
      "Location ID": "15215",
      "Location Name": "SHARPSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1200 MAIN ST PITTSBURGH PA 15215 USA "
    },
    {
      "Location ID": "15216",
      "Location Name": "SOUTH HILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3038 W LIBERTY AVE PITTSBURGH PA 15216 USA "
    },
    {
      "Location ID": "15217",
      "Location Name": "SQUIRREL HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1800 MURRAY AVE PITTSBURGH PA 15217 USA "
    },
    {
      "Location ID": "15224",
      "Location Name": "BLOOMFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5182 LIBERTY AVE PITTSBURGH PA 15224 USA "
    },
    {
      "Location ID": "15234",
      "Location Name": "CASTLE SHANNON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 370 CASTLE SHANNON BLVD PITTSBURGH PA 15234 USA "
    },
    {
      "Location ID": "15236",
      "Location Name": "PLEASANT HILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 REGIS AVE PITTSBURGH PA 15236 USA "
    },
    {
      "Location ID": "15237",
      "Location Name": "MC KNIGHT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4981 MCKNIGHT RD PITTSBURGH PA 15237 USA "
    },
    {
      "Location ID": "15238",
      "Location Name": "BLAWNOX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1310 OLD FREEPORT RD PITTSBURGH PA 15238 USA "
    },
    {
      "Location ID": "15241",
      "Location Name": "UPPER SAINT CLAIR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2211 LESNETT RD PITTSBURGH PA 15241 USA "
    },
    {
      "Location ID": "15243",
      "Location Name": "CEDARHURST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1099 BOWER HILL RD PITTSBURGH PA 15243 USA "
    },
    {
      "Location ID": "15244",
      "Location Name": "MONTOUR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1936 PARK MANOR BLVD MONTOUR PA 15244 USA "
    },
    {
      "Location ID": "152AD",
      "Location Name": "VALASSIS DIRECT MAIL INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 SUMMIT PARK DR PITTSBURGH PA 15275 USA "
    },
    {
      "Location ID": "152AS",
      "Location Name": "AXE & SLEDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 365 PLUM INDUSTRIAL CT PLUM PA 15239 USA "
    },
    {
      "Location ID": "152AZ",
      "Location Name": "AMAZON PIT5",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2250 ROSWELL DR STE 100 PITTSBURGH PA 15205 USA "
    },
    {
      "Location ID": "152CF",
      "Location Name": "CARGO FORCE INC - PIT THS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 CARGO BLDG PITTSBURGH PA 15231 USA "
    },
    {
      "Location ID": "152MS",
      "Location Name": "MAILING SVCS OF PITTSBURGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 155 COMMERCE DR FREEDOM PA 15042 USA "
    },
    {
      "Location ID": "153",
      "Location Name": "WASHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 153 JEFFERSON AVE WASHINGTON PA 15301 USA "
    },
    {
      "Location ID": "15301",
      "Location Name": "WASHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 153 JEFFERSON AVE WASHINGTON PA 15301 USA "
    },
    {
      "Location ID": "15311",
      "Location Name": "AMITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 630 AMITY RIDGE RD AMITY PA 15311 USA "
    },
    {
      "Location ID": "15312",
      "Location Name": "AVELLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 CAMPBELL ST AVELLA PA 15312 USA "
    },
    {
      "Location ID": "15314",
      "Location Name": "BENTLEYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 901 MAIN ST BENTLEYVILLE PA 15314 USA "
    },
    {
      "Location ID": "15317",
      "Location Name": "CANONSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 W PIKE ST CANONSBURG PA 15317 USA "
    },
    {
      "Location ID": "15320",
      "Location Name": "CARMICHAELS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 N MARKET ST CARMICHAELS PA 15320 USA "
    },
    {
      "Location ID": "15321",
      "Location Name": "CECIL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3333 MILLERS RUN RD CECIL PA 15321 USA "
    },
    {
      "Location ID": "15322",
      "Location Name": "CLARKSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 541 CENTER ST CLARKSVILLE PA 15322 USA "
    },
    {
      "Location ID": "15323",
      "Location Name": "CLAYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 146 MAIN ST CLAYSVILLE PA 15323 USA "
    },
    {
      "Location ID": "15325",
      "Location Name": "CRUCIBLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103B CRUCIBLE CARMICHAELS RD CRUCIBLE PA 15325 USA "
    },
    {
      "Location ID": "15329",
      "Location Name": "PROSPERITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4559 PROSPERITY PIKE PROSPERITY PA 15329 USA "
    },
    {
      "Location ID": "15330",
      "Location Name": "EIGHTY FOUR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 812 ROUTE 519 EIGHTY FOUR PA 15330 USA "
    },
    {
      "Location ID": "15332",
      "Location Name": "FINLEYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3510 EXTENSION AVE FINLEYVILLE PA 15332 USA "
    },
    {
      "Location ID": "15333",
      "Location Name": "FREDERICKTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 531 FRONT ST FREDERICKTOWN PA 15333 USA "
    },
    {
      "Location ID": "15336",
      "Location Name": "GASTONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3708 FINLEYVILLE ELRAMA RD GASTONVILLE PA 15336 USA "
    },
    {
      "Location ID": "15340",
      "Location Name": "HICKORY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 188 MAIN ST HICKORY PA 15340 USA "
    },
    {
      "Location ID": "15342",
      "Location Name": "HOUSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 S MAIN ST HOUSTON PA 15342 USA "
    },
    {
      "Location ID": "15344",
      "Location Name": "JEFFERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1422 JEFFERSON RD JEFFERSON PA 15344 USA "
    },
    {
      "Location ID": "15345",
      "Location Name": "MARIANNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1742 MAIN ST MARIANNA PA 15345 USA "
    },
    {
      "Location ID": "15346",
      "Location Name": "MATHER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 319 3RD ST MATHER PA 15346 USA "
    },
    {
      "Location ID": "15347",
      "Location Name": "MEADOW LANDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 COUNTRY CLUB RD MEADOW LANDS PA 15347 USA "
    },
    {
      "Location ID": "15348",
      "Location Name": "MILLSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 MILL ST MILLSBORO PA 15348 USA "
    },
    {
      "Location ID": "15351",
      "Location Name": "NEMACOLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 PERSHING BLVD NEMACOLIN PA 15351 USA "
    },
    {
      "Location ID": "15353",
      "Location Name": "NINEVEH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1750 BROWNS CREEK RD NINEVEH PA 15353 USA "
    },
    {
      "Location ID": "15357",
      "Location Name": "RICES LANDING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 RICES LANDING RD RICES LANDING PA 15357 USA "
    },
    {
      "Location ID": "15363",
      "Location Name": "STRABANE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 218 LATIMER AVE STRABANE PA 15363 USA "
    },
    {
      "Location ID": "15364",
      "Location Name": "SYCAMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 WW RAILROAD RD SYCAMORE PA 15364 USA "
    },
    {
      "Location ID": "15365",
      "Location Name": "TAYLORSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 56 MAIN ST TAYLORSTOWN PA 15365 USA "
    },
    {
      "Location ID": "15367",
      "Location Name": "VENETIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 VENETIA RD VENETIA PA 15367 USA "
    },
    {
      "Location ID": "15368",
      "Location Name": "VESTABURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 COMMUNITY ST VESTABURG PA 15368 USA "
    },
    {
      "Location ID": "15370",
      "Location Name": "WAYNESBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 S MORRIS ST WAYNESBURG PA 15370 USA "
    },
    {
      "Location ID": "15376",
      "Location Name": "WEST ALEXANDER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 N LIBERTY RD WEST ALEXANDER PA 15376 USA "
    },
    {
      "Location ID": "15378",
      "Location Name": "WESTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 428 WESTLAND RD WESTLAND PA 15378 USA "
    },
    {
      "Location ID": "15379",
      "Location Name": "WEST MIDDLETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 E MAIN ST WEST MIDDLETOWN PA 15379 USA "
    },
    {
      "Location ID": "153AB",
      "Location Name": "MC MURRAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3935 WASHINGTON RD CANONSBURG PA 15317 USA "
    },
    {
      "Location ID": "153AX",
      "Location Name": "CANONSBURG CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 CURRY AVE CANONSBURG PA 15317 USA "
    },
    {
      "Location ID": "154",
      "Location Name": "UNIONTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 47-49 E FAYETTE ST UNIONTOWN PA 15401 USA "
    },
    {
      "Location ID": "15411",
      "Location Name": "ADDISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7337 NATIONAL PIKE ADDISON PA 15411 USA "
    },
    {
      "Location ID": "15412",
      "Location Name": "ALLENPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1908 MAIN ST ALLENPORT PA 15412 USA "
    },
    {
      "Location ID": "15413",
      "Location Name": "ALLISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 575 ALLISON AVE ALLISON PA 15413 USA "
    },
    {
      "Location ID": "15416",
      "Location Name": "BROWNFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 212 2ND ST BROWNFIELD PA 15416 USA "
    },
    {
      "Location ID": "15417",
      "Location Name": "BROWNSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 CHARLES ST BROWNSVILLE PA 15417 USA "
    },
    {
      "Location ID": "15419",
      "Location Name": "CALIFORNIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 430 2ND ST CALIFORNIA PA 15419 USA "
    },
    {
      "Location ID": "15420",
      "Location Name": "CARDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 258 FILBERT ORIENT RD CARDALE PA 15420 USA "
    },
    {
      "Location ID": "15421",
      "Location Name": "CHALK HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2963 NATIONAL PIKE CHALK HILL PA 15421 USA "
    },
    {
      "Location ID": "15422",
      "Location Name": "CHESTNUT RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 402 3RD ST CHESTNUT RIDGE PA 15422 USA "
    },
    {
      "Location ID": "15423",
      "Location Name": "COAL CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 SPRING ST COAL CENTER PA 15423 USA "
    },
    {
      "Location ID": "15424",
      "Location Name": "CONFLUENCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 512 HUGART ST CONFLUENCE PA 15424 USA "
    },
    {
      "Location ID": "15425",
      "Location Name": "CONNELLSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 N ARCH ST CONNELLSVILLE PA 15425 USA "
    },
    {
      "Location ID": "15427",
      "Location Name": "DAISYTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 545 PIKE RUN DR DAISYTOWN PA 15427 USA "
    },
    {
      "Location ID": "15428",
      "Location Name": "DAWSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 RAILROAD ST DAWSON PA 15428 USA "
    },
    {
      "Location ID": "15430",
      "Location Name": "DICKERSON RUN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 232 MAIN ST DICKERSON RUN PA 15430 USA "
    },
    {
      "Location ID": "15431",
      "Location Name": "DUNBAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 CHURCH ST DUNBAR PA 15431 USA "
    },
    {
      "Location ID": "15432",
      "Location Name": "DUNLEVY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2216 ROUTE 88 DUNLEVY PA 15432 USA "
    },
    {
      "Location ID": "15433",
      "Location Name": "EAST MILLSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 326 MILLSBORO RD EAST MILLSBORO PA 15433 USA "
    },
    {
      "Location ID": "15435",
      "Location Name": "FAIRBANK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 650 FAIRBANK HERBERT RD FAIRBANK PA 15435 USA "
    },
    {
      "Location ID": "15436",
      "Location Name": "FAIRCHANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 W CHURCH ST FAIRCHANCE PA 15436 USA "
    },
    {
      "Location ID": "15437",
      "Location Name": "FARMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 FARMINGTON OHIOPYLE RD FARMINGTON PA 15437 USA "
    },
    {
      "Location ID": "15438",
      "Location Name": "FAYETTE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 JOHNSON HOLLOW RD FAYETTE CITY PA 15438 USA "
    },
    {
      "Location ID": "15440",
      "Location Name": "GIBBON GLADE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 872 GIBBON GLADE RD GIBBON GLADE PA 15440 USA "
    },
    {
      "Location ID": "15442",
      "Location Name": "GRINDSTONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 565 GRINDSTONE RD GRINDSTONE PA 15442 USA "
    },
    {
      "Location ID": "15443",
      "Location Name": "HIBBS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1006 RALPH NEW SALEM RD HIBBS PA 15443 USA "
    },
    {
      "Location ID": "15444",
      "Location Name": "HILLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 906 1ST ST HILLER PA 15444 USA "
    },
    {
      "Location ID": "15445",
      "Location Name": "HOPWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 PAULL ST HOPWOOD PA 15445 USA "
    },
    {
      "Location ID": "15447",
      "Location Name": "ISABELLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 506 1ST ST REAR ISABELLA PA 15447 USA "
    },
    {
      "Location ID": "15448",
      "Location Name": "JACOBS CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 178 MAIN ST JACOBS CREEK PA 15448 USA "
    },
    {
      "Location ID": "15449",
      "Location Name": "KEISTERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 831 MAIN ST KEISTERVILLE PA 15449 USA "
    },
    {
      "Location ID": "15450",
      "Location Name": "LA BELLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1139 LABELLE RD LA BELLE PA 15450 USA "
    },
    {
      "Location ID": "15454",
      "Location Name": "LECKRONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 348 LECKRONE HIGH HOUSE RD LECKRONE PA 15454 USA "
    },
    {
      "Location ID": "15455",
      "Location Name": "LEISENRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 232 MAIN ST LEISENRING PA 15455 USA "
    },
    {
      "Location ID": "15456",
      "Location Name": "LEMONT FURNACE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 265 STARLITE RD LEMONT FURNACE PA 15456 USA "
    },
    {
      "Location ID": "15458",
      "Location Name": "MC CLELLANDTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 MCKINLEY ST MC CLELLANDTOWN PA 15458 USA "
    },
    {
      "Location ID": "15459",
      "Location Name": "MARKLEYSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 173 MAIN ST MARKLEYSBURG PA 15459 USA "
    },
    {
      "Location ID": "15460",
      "Location Name": "MARTIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 NICHOLSON ST MARTIN PA 15460 USA "
    },
    {
      "Location ID": "15461",
      "Location Name": "MASONTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 E CHURCH AVE MASONTOWN PA 15461 USA "
    },
    {
      "Location ID": "15463",
      "Location Name": "MERRITTSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 ACADEMY RD MERRITTSTOWN PA 15463 USA "
    },
    {
      "Location ID": "15465",
      "Location Name": "MOUNT BRADDOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 FERGUSON RD MOUNT BRADDOCK PA 15465 USA "
    },
    {
      "Location ID": "15466",
      "Location Name": "NEWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 406 4TH ST NEWELL PA 15466 USA "
    },
    {
      "Location ID": "15468",
      "Location Name": "NEW SALEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 W MAIN ST NEW SALEM PA 15468 USA "
    },
    {
      "Location ID": "15472",
      "Location Name": "OLIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 169 MAIN ST OLIVER PA 15472 USA "
    },
    {
      "Location ID": "15473",
      "Location Name": "PERRYOPOLIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 221 REPUBLICAN ST PERRYOPOLIS PA 15473 USA "
    },
    {
      "Location ID": "15474",
      "Location Name": "POINT MARION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 PENN ST POINT MARION PA 15474 USA "
    },
    {
      "Location ID": "15475",
      "Location Name": "REPUBLIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 VAN KIRK ST REPUBLIC PA 15475 USA "
    },
    {
      "Location ID": "15476",
      "Location Name": "RONCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 639 STEVENSON ST RONCO PA 15476 USA "
    },
    {
      "Location ID": "15477",
      "Location Name": "ROSCOE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 FURLONG AVE ROSCOE PA 15477 USA "
    },
    {
      "Location ID": "15478",
      "Location Name": "SMITHFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 91 MAIN ST SMITHFIELD PA 15478 USA "
    },
    {
      "Location ID": "15479",
      "Location Name": "SMITHTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 222 2ND ST SMITHTON PA 15479 USA "
    },
    {
      "Location ID": "15480",
      "Location Name": "SMOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 MAIN ST SMOCK PA 15480 USA "
    },
    {
      "Location ID": "15482",
      "Location Name": "STAR JUNCTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 TONY ROW RD STAR JUNCTION PA 15482 USA "
    },
    {
      "Location ID": "15483",
      "Location Name": "STOCKDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 398 RAILROAD ST STOCKDALE PA 15483 USA "
    },
    {
      "Location ID": "15484",
      "Location Name": "ULEDI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 RAINEY RD ULEDI PA 15484 USA "
    },
    {
      "Location ID": "15486",
      "Location Name": "VANDERBILT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 262 LIBERTY ST VANDERBILT PA 15486 USA "
    },
    {
      "Location ID": "15488",
      "Location Name": "WALTERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1829 PITTSBURGH RD WALTERSBURG PA 15488 USA "
    },
    {
      "Location ID": "15489",
      "Location Name": "WEST LEISENRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 POSTAL WAY WEST LEISENRING PA 15489 USA "
    },
    {
      "Location ID": "154CU",
      "Location Name": "CALIFORNIA (UNIVERSITY)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 UNIVERSITY AVE CALIFORNIA PA 15419 USA "
    },
    {
      "Location ID": "155",
      "Location Name": "SOMERSET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 N. CENTER AVE SOMERSET PA 15501 USA "
    },
    {
      "Location ID": "15501",
      "Location Name": "SOMERSET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 N CENTER AVE SOMERSET PA 15501 USA "
    },
    {
      "Location ID": "15520",
      "Location Name": "ACOSTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 5TH ST ACOSTA PA 15520 USA "
    },
    {
      "Location ID": "15522",
      "Location Name": "BEDFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 S JULIANA ST BEDFORD PA 15522 USA "
    },
    {
      "Location ID": "15530",
      "Location Name": "BERLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 531 MAIN ST STE 100 BERLIN PA 15530 USA "
    },
    {
      "Location ID": "15531",
      "Location Name": "BOSWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 908 ATKINSON WAY BOSWELL PA 15531 USA "
    },
    {
      "Location ID": "15534",
      "Location Name": "BUFFALO MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8862 HYNDMAN RD BUFFALO MILLS PA 15534 USA "
    },
    {
      "Location ID": "15537",
      "Location Name": "EVERETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 E MAIN ST EVERETT PA 15537 USA "
    },
    {
      "Location ID": "15538",
      "Location Name": "FAIRHOPE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1172 FAIRHOPE RD FAIRHOPE PA 15538 USA "
    },
    {
      "Location ID": "15540",
      "Location Name": "FORT HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 414 FORT HILL RD FORT HILL PA 15540 USA "
    },
    {
      "Location ID": "15542",
      "Location Name": "GARRETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 419 JACKSON ST GARRETT PA 15542 USA "
    },
    {
      "Location ID": "15544",
      "Location Name": "GRAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 129 E 1ST ST GRAY PA 15544 USA "
    },
    {
      "Location ID": "15545",
      "Location Name": "HYNDMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 129 WASHINGTON ST HYNDMAN PA 15545 USA "
    },
    {
      "Location ID": "15547",
      "Location Name": "JENNERSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1596 PITT ST JENNERSTOWN PA 15547 USA "
    },
    {
      "Location ID": "15550",
      "Location Name": "MANNS CHOICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 DOGWOOD LN MANNS CHOICE PA 15550 USA "
    },
    {
      "Location ID": "15551",
      "Location Name": "MARKLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1099 MARKLETON SCHOOL RD MARKLETON PA 15551 USA "
    },
    {
      "Location ID": "15552",
      "Location Name": "MEYERSDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 239 CENTER ST MEYERSDALE PA 15552 USA "
    },
    {
      "Location ID": "15557",
      "Location Name": "ROCKWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 690 MAIN ST ROCKWOOD PA 15557 USA "
    },
    {
      "Location ID": "15561",
      "Location Name": "SIPESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 601 SIPESVILLE RD SIPESVILLE PA 15561 USA "
    },
    {
      "Location ID": "155RE",
      "Location Name": "REI - BEDFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1400 COUNTRY RIDGE RD BEDFORD PA 15522 USA "
    },
    {
      "Location ID": "155WB",
      "Location Name": "WHEELER BROTHERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 384 DRUM AVE SOMERSET PA 15501 USA "
    },
    {
      "Location ID": "15601",
      "Location Name": "GREENSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 238 S PENNSYLVANIA AVE GREENSBURG PA 15601 USA "
    },
    {
      "Location ID": "15610",
      "Location Name": "ACME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2930 STATE ROUTE 31 ACME PA 15610 USA "
    },
    {
      "Location ID": "15611",
      "Location Name": "ADAMSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 184 MAIN ST ADAMSBURG PA 15611 USA "
    },
    {
      "Location ID": "15612",
      "Location Name": "ALVERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 459 RUFFSDALE ALVERTON RD ALVERTON PA 15612 USA "
    },
    {
      "Location ID": "15613",
      "Location Name": "APOLLO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 NORTH PLZ APOLLO PA 15613 USA "
    },
    {
      "Location ID": "15615",
      "Location Name": "ARDARA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12889 STATE ROUTE 993 ARDARA PA 15615 USA "
    },
    {
      "Location ID": "15616",
      "Location Name": "ARMBRUST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7855 STATE ROUTE 819 ARMBRUST PA 15616 USA "
    },
    {
      "Location ID": "15617",
      "Location Name": "ARONA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2128 MAIN ST ARONA PA 15617 USA "
    },
    {
      "Location ID": "15618",
      "Location Name": "AVONMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 516 WESTMORELAND AVE AVONMORE PA 15618 USA "
    },
    {
      "Location ID": "15619",
      "Location Name": "BOVARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 JEFFERSON ST BOVARD PA 15619 USA "
    },
    {
      "Location ID": "15620",
      "Location Name": "BRADENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1303 HIGH ST BRADENVILLE PA 15620 USA "
    },
    {
      "Location ID": "15622",
      "Location Name": "CHAMPION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1410 COUNTY LINE RD CHAMPION PA 15622 USA "
    },
    {
      "Location ID": "15623",
      "Location Name": "CLARIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3162 MAIN ST CLARIDGE PA 15623 USA "
    },
    {
      "Location ID": "15624",
      "Location Name": "CRABTREE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2573 STATE ROUTE 119 CRABTREE PA 15624 USA "
    },
    {
      "Location ID": "15625",
      "Location Name": "DARRAGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3206 ROUTE 136 DARRAGH PA 15625 USA "
    },
    {
      "Location ID": "15626",
      "Location Name": "DELMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 48 GREENSBURG ST DELMONT PA 15626 USA "
    },
    {
      "Location ID": "15627",
      "Location Name": "DERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 E 1ST AVE DERRY PA 15627 USA "
    },
    {
      "Location ID": "15628",
      "Location Name": "DONEGAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 154 MAIN ST DONEGAL PA 15628 USA "
    },
    {
      "Location ID": "15629",
      "Location Name": "EAST VANDERGRIFT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 252 MCKINLEY AVE EAST VANDERGRIFT PA 15629 USA "
    },
    {
      "Location ID": "15631",
      "Location Name": "EVERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 328 BROWN ST EVERSON PA 15631 USA "
    },
    {
      "Location ID": "15633",
      "Location Name": "FORBES ROAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 344 FIRE STATION RD FORBES ROAD PA 15633 USA "
    },
    {
      "Location ID": "15634",
      "Location Name": "GRAPEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2526 NEWARK ST GRAPEVILLE PA 15634 USA "
    },
    {
      "Location ID": "15635",
      "Location Name": "HANNASTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 189 FRONT ST HANNASTOWN PA 15635 USA "
    },
    {
      "Location ID": "15636",
      "Location Name": "HARRISON CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 567 MANOR HARRISON CITY RD HARRISON CITY PA 15636 USA "
    },
    {
      "Location ID": "15637",
      "Location Name": "HERMINIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 HIGHLAND AVE HERMINIE PA 15637 USA "
    },
    {
      "Location ID": "15638",
      "Location Name": "HOSTETTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 126 SLOVAK CLUB RD HOSTETTER PA 15638 USA "
    },
    {
      "Location ID": "15639",
      "Location Name": "HUNKER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 331 DIVISION ST HUNKER PA 15639 USA "
    },
    {
      "Location ID": "15640",
      "Location Name": "HUTCHINSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 HOOD ST HUTCHINSON PA 15640 USA "
    },
    {
      "Location ID": "15641",
      "Location Name": "HYDE PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 385 CHESTNUT ST HYDE PARK PA 15641 USA "
    },
    {
      "Location ID": "15642",
      "Location Name": "IRWIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 140 MAIN ST IRWIN PA 15642 USA "
    },
    {
      "Location ID": "15644",
      "Location Name": "JEANNETTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 223 S 5TH ST JEANNETTE PA 15644 USA "
    },
    {
      "Location ID": "15646",
      "Location Name": "JONES MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4124 STATE ROUTE 31 JONES MILLS PA 15646 USA "
    },
    {
      "Location ID": "15647",
      "Location Name": "LARIMER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1370 BROWNSTOWN RD LARIMER PA 15647 USA "
    },
    {
      "Location ID": "15650",
      "Location Name": "LATROBE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 811 JEFFERSON ST LATROBE PA 15650 USA "
    },
    {
      "Location ID": "15656",
      "Location Name": "LEECHBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23 MAIN ST LEECHBURG PA 15656 USA "
    },
    {
      "Location ID": "15658",
      "Location Name": "LIGONIER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 W MAIN ST LIGONIER PA 15658 USA "
    },
    {
      "Location ID": "15661",
      "Location Name": "LOYALHANNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1302 LATROBE DERRY RD LOYALHANNA PA 15661 USA "
    },
    {
      "Location ID": "15662",
      "Location Name": "LUXOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 470 BOVARD LUXOR RD LUXOR PA 15662 USA "
    },
    {
      "Location ID": "15663",
      "Location Name": "MADISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 232 MAIN ST MADISON PA 15663 USA "
    },
    {
      "Location ID": "15664",
      "Location Name": "MAMMOTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2688 ROUTE 982 MAMMOTH PA 15664 USA "
    },
    {
      "Location ID": "15665",
      "Location Name": "MANOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 ATLANTIC AVE MANOR PA 15665 USA "
    },
    {
      "Location ID": "15666",
      "Location Name": "MOUNT PLEASANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 RUMBAUGH AVE MOUNT PLEASANT PA 15666 USA "
    },
    {
      "Location ID": "15668",
      "Location Name": "MURRYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 PRIORITY LN MURRYSVILLE PA 15668 USA "
    },
    {
      "Location ID": "15670",
      "Location Name": "NEW ALEXANDRIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 198 W MAIN ST NEW ALEXANDRIA PA 15670 USA "
    },
    {
      "Location ID": "15671",
      "Location Name": "NEW DERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5754 ROUTE 982 NEW DERRY PA 15671 USA "
    },
    {
      "Location ID": "15672",
      "Location Name": "NEW STANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 135 W PENNSYLVANIA AVE NEW STANTON PA 15672 USA "
    },
    {
      "Location ID": "15673",
      "Location Name": "NORTH APOLLO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 312 16TH ST NORTH APOLLO PA 15673 USA "
    },
    {
      "Location ID": "15674",
      "Location Name": "NORVELT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2333 MOUNT PLEASANT RD NORVELT PA 15674 USA "
    },
    {
      "Location ID": "15675",
      "Location Name": "PENN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 COAL ST PENN PA 15675 USA "
    },
    {
      "Location ID": "15676",
      "Location Name": "PLEASANT UNITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2061 ROUTE 130 PLEASANT UNITY PA 15676 USA "
    },
    {
      "Location ID": "15678",
      "Location Name": "RILLTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 MARS HILL RD RILLTON PA 15678 USA "
    },
    {
      "Location ID": "15679",
      "Location Name": "RUFFS DALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 875 STATE ROUTE 31 RUFFS DALE PA 15679 USA "
    },
    {
      "Location ID": "15680",
      "Location Name": "SALINA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 194 MAIN ST SALINA PA 15680 USA "
    },
    {
      "Location ID": "15681",
      "Location Name": "SALTSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 207 POINT ST SALTSBURG PA 15681 USA "
    },
    {
      "Location ID": "15682",
      "Location Name": "SCHENLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 RAILROAD ST SCHENLEY PA 15682 USA "
    },
    {
      "Location ID": "15683",
      "Location Name": "SCOTTDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 N CHESTNUT ST SCOTTDALE PA 15683 USA "
    },
    {
      "Location ID": "15684",
      "Location Name": "SLICKVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 124 MAIN ST SLICKVILLE PA 15684 USA "
    },
    {
      "Location ID": "15685",
      "Location Name": "SOUTHWEST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 536 HECLA RD SOUTHWEST PA 15685 USA "
    },
    {
      "Location ID": "15686",
      "Location Name": "SPRING CHURCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1759 STATE ROUTE 56 SPRING CHURCH PA 15686 USA "
    },
    {
      "Location ID": "15687",
      "Location Name": "STAHLSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4145 MAIN ST STAHLSTOWN PA 15687 USA "
    },
    {
      "Location ID": "15688",
      "Location Name": "TARRS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 980 ROUTE 31 TARRS PA 15688 USA "
    },
    {
      "Location ID": "15689",
      "Location Name": "UNITED",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1103 UNITED WAY UNITED PA 15689 USA "
    },
    {
      "Location ID": "15690",
      "Location Name": "VANDERGRIFT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 142 FARRAGUT AVE VANDERGRIFT PA 15690 USA "
    },
    {
      "Location ID": "15692",
      "Location Name": "WESTMORELAND CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8880 WALNUT ST WESTMORELAND CITY PA 15692 USA "
    },
    {
      "Location ID": "15693",
      "Location Name": "WHITNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 AUGUSTA LN WHITNEY PA 15693 USA "
    },
    {
      "Location ID": "15695",
      "Location Name": "WYANO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2202 1ST ST WYANO PA 15695 USA "
    },
    {
      "Location ID": "15696",
      "Location Name": "YOUNGSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 312 MAIN ST YOUNGSTOWN PA 15696 USA "
    },
    {
      "Location ID": "15697",
      "Location Name": "YOUNGWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 S 3RD ST YOUNGWOOD PA 15697 USA "
    },
    {
      "Location ID": "15698",
      "Location Name": "YUKON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 137 HIGHWAY ST YUKON PA 15698 USA "
    },
    {
      "Location ID": "156IA",
      "Location Name": "NORTH HUNTINGDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1101 MAIN ST IRWIN PA 15642 USA "
    },
    {
      "Location ID": "156OS",
      "Location Name": "ONLINE SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 WESTINGHOUSE DR STE 1 NEW STANTON PA 15672 USA "
    },
    {
      "Location ID": "157",
      "Location Name": "INDIANA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 47 S 7TH STREET INDIANA PA 15701 USA "
    },
    {
      "Location ID": "15710",
      "Location Name": "ALVERDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11089 ROUTE 553 HWY ALVERDA PA 15710 USA "
    },
    {
      "Location ID": "15712",
      "Location Name": "ARCADIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21832 ROUTE 286 HWY E ARCADIA PA 15712 USA "
    },
    {
      "Location ID": "15714",
      "Location Name": "NORTHERN CAMBRIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4310 CRAWFORD AVE NORTHERN CAMBRIA PA 15714 USA "
    },
    {
      "Location ID": "15715",
      "Location Name": "BIG RUN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 E MAIN ST BIG RUN PA 15715 USA "
    },
    {
      "Location ID": "15717",
      "Location Name": "BLAIRSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 S WALNUT ST BLAIRSVILLE PA 15717 USA "
    },
    {
      "Location ID": "15722",
      "Location Name": "CARROLLTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 N MAIN ST CARROLLTOWN PA 15722 USA "
    },
    {
      "Location ID": "15724",
      "Location Name": "CHERRY TREE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 550 S MAIN ST CHERRY TREE PA 15724 USA "
    },
    {
      "Location ID": "15725",
      "Location Name": "CLARKSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15555 ROUTE 286 HWY W CLARKSBURG PA 15725 USA "
    },
    {
      "Location ID": "15727",
      "Location Name": "CLUNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2021 COAL RUN RD CLUNE PA 15727 USA "
    },
    {
      "Location ID": "15728",
      "Location Name": "CLYMER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 735 FRANKLIN ST CLYMER PA 15728 USA "
    },
    {
      "Location ID": "15729",
      "Location Name": "COMMODORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 VANDERBILT ST COMMODORE PA 15729 USA "
    },
    {
      "Location ID": "15732",
      "Location Name": "CREEKSIDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 435 INDIANA RD CREEKSIDE PA 15732 USA "
    },
    {
      "Location ID": "15734",
      "Location Name": "DIXONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18050 ROUTE 403 HWY N DIXONVILLE PA 15734 USA "
    },
    {
      "Location ID": "15736",
      "Location Name": "ELDERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 E SALTWORK ST ELDERTON PA 15736 USA "
    },
    {
      "Location ID": "15737",
      "Location Name": "ELMORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 990 DEVEAUX ST ELMORA PA 15737 USA "
    },
    {
      "Location ID": "15738",
      "Location Name": "EMEIGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 871 MAIN ST EMEIGH PA 15738 USA "
    },
    {
      "Location ID": "15741",
      "Location Name": "GIPSY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3885 GIPSY RD GIPSY PA 15741 USA "
    },
    {
      "Location ID": "15742",
      "Location Name": "GLEN CAMPBELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 455 GLENWOOD AVE GLEN CAMPBELL PA 15742 USA "
    },
    {
      "Location ID": "15745",
      "Location Name": "HEILWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6521 ROUTE 403 HWY N HEILWOOD PA 15745 USA "
    },
    {
      "Location ID": "15746",
      "Location Name": "HILLSDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 WILGUS RD HILLSDALE PA 15746 USA "
    },
    {
      "Location ID": "15747",
      "Location Name": "HOME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 51 ROUTE 85 HWY HOME PA 15747 USA "
    },
    {
      "Location ID": "15752",
      "Location Name": "KENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90 E JACKSON ST KENT PA 15752 USA "
    },
    {
      "Location ID": "15753",
      "Location Name": "LA JOSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 CECIL HURD HWY STE 1 LA JOSE PA 15753 USA "
    },
    {
      "Location ID": "15756",
      "Location Name": "MC INTYRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2576 MCINTYRE RD MC INTYRE PA 15756 USA "
    },
    {
      "Location ID": "15757",
      "Location Name": "MAHAFFEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 E MAIN ST MAHAFFEY PA 15757 USA "
    },
    {
      "Location ID": "15759",
      "Location Name": "MARION CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 204 MANOR ST MARION CENTER PA 15759 USA "
    },
    {
      "Location ID": "15762",
      "Location Name": "NICKTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3281 BLUE GOOSE RD NICKTOWN PA 15762 USA "
    },
    {
      "Location ID": "15765",
      "Location Name": "PENN RUN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1417 N HARMONY RD PENN RUN PA 15765 USA "
    },
    {
      "Location ID": "15767",
      "Location Name": "PUNXSUTAWNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 553 E MAHONING ST PUNXSUTAWNEY PA 15767 USA "
    },
    {
      "Location ID": "15771",
      "Location Name": "ROCHESTER MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4941 RICHMOND RD ROCHESTER MILLS PA 15771 USA "
    },
    {
      "Location ID": "15772",
      "Location Name": "ROSSITER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 384 CENTRAL ST ROSSITER PA 15772 USA "
    },
    {
      "Location ID": "15773",
      "Location Name": "SAINT BENEDICT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 POST OFFICE RD SAINT BENEDICT PA 15773 USA "
    },
    {
      "Location ID": "15774",
      "Location Name": "SHELOCTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 MAIN ST SHELOCTA PA 15774 USA "
    },
    {
      "Location ID": "15775",
      "Location Name": "SPANGLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1904 BIGLER AVE STE 2 SPANGLER PA 15775 USA "
    },
    {
      "Location ID": "15777",
      "Location Name": "STARFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4218 STARFORD RD STARFORD PA 15777 USA "
    },
    {
      "Location ID": "15783",
      "Location Name": "WEST LEBANON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 POUNDS RD WEST LEBANON PA 15783 USA "
    },
    {
      "Location ID": "158",
      "Location Name": "DU BOIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 229 BEAVER DR DU BOIS PA 15801 USA "
    },
    {
      "Location ID": "15823",
      "Location Name": "BROCKPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5139 ROUTE 219 BROCKPORT PA 15823 USA "
    },
    {
      "Location ID": "15824",
      "Location Name": "BROCKWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 MAIN ST BROCKWAY PA 15824 USA "
    },
    {
      "Location ID": "15825",
      "Location Name": "BROOKVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 67 WHITE ST BROOKVILLE PA 15825 USA "
    },
    {
      "Location ID": "15827",
      "Location Name": "BYRNEDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19884 BENNETTS VALLEY HWY BYRNEDALE PA 15827 USA "
    },
    {
      "Location ID": "15829",
      "Location Name": "CORSICA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 446 OLEAN RD CORSICA PA 15829 USA "
    },
    {
      "Location ID": "15831",
      "Location Name": "DAGUS MINES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 176 DAGUS MINES RD DAGUS MINES PA 15831 USA "
    },
    {
      "Location ID": "15834",
      "Location Name": "EMPORIUM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 W 4TH ST EMPORIUM PA 15834 USA "
    },
    {
      "Location ID": "15840",
      "Location Name": "FALLS CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 65 MAIN ST FALLS CREEK PA 15840 USA "
    },
    {
      "Location ID": "15841",
      "Location Name": "FORCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17686 BENNETTS VALLEY HWY FORCE PA 15841 USA "
    },
    {
      "Location ID": "15845",
      "Location Name": "JOHNSONBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 420 MARKET ST JOHNSONBURG PA 15845 USA "
    },
    {
      "Location ID": "15846",
      "Location Name": "KERSEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 377 MAIN ST KERSEY PA 15846 USA "
    },
    {
      "Location ID": "15847",
      "Location Name": "KNOX DALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 MAIN ST KNOX DALE PA 15847 USA "
    },
    {
      "Location ID": "15848",
      "Location Name": "LUTHERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 79 CHURCH ST LUTHERSBURG PA 15848 USA "
    },
    {
      "Location ID": "15849",
      "Location Name": "PENFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12336 BENNETTS VALLEY HWY PENFIELD PA 15849 USA "
    },
    {
      "Location ID": "15851",
      "Location Name": "REYNOLDSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 350 E MAIN ST REYNOLDSVILLE PA 15851 USA "
    },
    {
      "Location ID": "15853",
      "Location Name": "RIDGWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 S MILL AVE RIDGWAY PA 15853 USA "
    },
    {
      "Location ID": "15856",
      "Location Name": "ROCKTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 OLD DUBOIS RD ROCKTON PA 15856 USA "
    },
    {
      "Location ID": "15857",
      "Location Name": "SAINT MARYS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 CHESTNUT ST SAINT MARYS PA 15857 USA "
    },
    {
      "Location ID": "15860",
      "Location Name": "SIGEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8517 ROUTE 949 SIGEL PA 15860 USA "
    },
    {
      "Location ID": "15863",
      "Location Name": "STUMP CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6871 ROUTE 119 STUMP CREEK PA 15863 USA "
    },
    {
      "Location ID": "15864",
      "Location Name": "SUMMERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 170 STATE ST SUMMERVILLE PA 15864 USA "
    },
    {
      "Location ID": "15865",
      "Location Name": "SYKESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 W MAIN ST SYKESVILLE PA 15865 USA "
    },
    {
      "Location ID": "15868",
      "Location Name": "WEEDVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 472 RIVER RD WEEDVILLE PA 15868 USA "
    },
    {
      "Location ID": "15870",
      "Location Name": "WILCOX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 553 MARVIN ST WILCOX PA 15870 USA "
    },
    {
      "Location ID": "159",
      "Location Name": "LOG JOHNSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 235 JARI DR RM 109 JOHNSTOWN PA 15904 USA "
    },
    {
      "Location ID": "15901",
      "Location Name": "JOHNSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 FRANKLIN ST RM 105 JOHNSTOWN PA 15901 USA "
    },
    {
      "Location ID": "15920",
      "Location Name": "ARMAGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 E PHILADELPHIA ST ARMAGH PA 15920 USA "
    },
    {
      "Location ID": "15921",
      "Location Name": "BEAVERDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 316 CEDAR ST STE 100 BEAVERDALE PA 15921 USA "
    },
    {
      "Location ID": "15923",
      "Location Name": "BOLIVAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 308 4TH ST BOLIVAR PA 15923 USA "
    },
    {
      "Location ID": "15924",
      "Location Name": "CAIRNBROOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 2ND ST CAIRNBROOK PA 15924 USA "
    },
    {
      "Location ID": "15926",
      "Location Name": "CENTRAL CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 SUNSHINE AVE CENTRAL CITY PA 15926 USA "
    },
    {
      "Location ID": "15929",
      "Location Name": "DILLTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7491 ROUTE 403 HWY S DILLTOWN PA 15929 USA "
    },
    {
      "Location ID": "15930",
      "Location Name": "DUNLO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 207 HUFF ST DUNLO PA 15930 USA "
    },
    {
      "Location ID": "15931",
      "Location Name": "EBENSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 N CENTER ST EBENSBURG PA 15931 USA "
    },
    {
      "Location ID": "15934",
      "Location Name": "ELTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4690 ELTON RD ELTON PA 15934 USA "
    },
    {
      "Location ID": "15940",
      "Location Name": "LORETTO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 245 SAINT MARY ST LORETTO PA 15940 USA "
    },
    {
      "Location ID": "15943",
      "Location Name": "NANTY GLO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 950 CHESTNUT ST NANTY GLO PA 15943 USA "
    },
    {
      "Location ID": "15944",
      "Location Name": "NEW FLORENCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 185 LIGONIER ST NEW FLORENCE PA 15944 USA "
    },
    {
      "Location ID": "15949",
      "Location Name": "ROBINSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 45 FRONT ST ROBINSON PA 15949 USA "
    },
    {
      "Location ID": "15951",
      "Location Name": "SAINT MICHAEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 983 LOCUST ST SAINT MICHAEL PA 15951 USA "
    },
    {
      "Location ID": "15952",
      "Location Name": "SALIX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1108 FOREST HILLS DR SALIX PA 15952 USA "
    },
    {
      "Location ID": "15954",
      "Location Name": "SEWARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 224 INDIANA ST SEWARD PA 15954 USA "
    },
    {
      "Location ID": "15955",
      "Location Name": "SIDMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 367 LOCUST ST STE 101 SIDMAN PA 15955 USA "
    },
    {
      "Location ID": "15963",
      "Location Name": "WINDBER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1600 GRAHAM AVE WINDBER PA 15963 USA "
    },
    {
      "Location ID": "159RF",
      "Location Name": "ROADSTER FACTORY (ARMAGH PA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 328 KILLEN RD ARMAGH PA 15920 USA "
    },
    {
      "Location ID": "159UP",
      "Location Name": "UNIVERSITY OF PGH(JOHNSTOWN)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 450 SCHOOLHOUSE RD JOHNSTOWN PA 15904 USA "
    },
    {
      "Location ID": "15Z",
      "Location Name": "LOG PITTSBURGH NDC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 BRUSH CREEK RD WARRENDALE PA 15095 USA "
    },
    {
      "Location ID": "15ZBS",
      "Location Name": "NETWORK DISTRIBUTION CT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 BRUSH CREEK RD WARRENDALE PA 15095 USA "
    },
    {
      "Location ID": "160",
      "Location Name": "BUTLER PA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 345 S MAIN ST BUTLER PA 16001 USA "
    },
    {
      "Location ID": "16020",
      "Location Name": "BOYERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1188 BOYERS RD BOYERS PA 16020 USA "
    },
    {
      "Location ID": "16023",
      "Location Name": "CABOT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 452 WINFIELD RD CABOT PA 16023 USA "
    },
    {
      "Location ID": "16024",
      "Location Name": "CALLERY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 172 RAILROAD ST CALLERY PA 16024 USA "
    },
    {
      "Location ID": "16027",
      "Location Name": "CONNOQUENESSING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 175 CONNOQUENESSING MAIN ST CONNOQUENESSING PA 16027 USA "
    },
    {
      "Location ID": "16030",
      "Location Name": "EAU CLAIRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 S WASHINGTON ST EAU CLAIRE PA 16030 USA "
    },
    {
      "Location ID": "16033",
      "Location Name": "EVANS CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 126 S JACKSON ST EVANS CITY PA 16033 USA "
    },
    {
      "Location ID": "16035",
      "Location Name": "FORESTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4114 WILLIAM FLYNN HWY FORESTVILLE PA 16035 USA "
    },
    {
      "Location ID": "16036",
      "Location Name": "FOXBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 RAILROAD ST FOXBURG PA 16036 USA "
    },
    {
      "Location ID": "16038",
      "Location Name": "HARRISVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 S MAIN ST HARRISVILLE PA 16038 USA "
    },
    {
      "Location ID": "16039",
      "Location Name": "HERMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 GEIBEL RD HERMAN PA 16039 USA "
    },
    {
      "Location ID": "16045",
      "Location Name": "LYNDORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 WHITESTOWN RD LYNDORA PA 16045 USA "
    },
    {
      "Location ID": "16046",
      "Location Name": "MARS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 GRAND AVE MARS PA 16046 USA "
    },
    {
      "Location ID": "16048",
      "Location Name": "NORTH WASHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 739 FAIRVIEW RD NORTH WASHINGTON PA 16048 USA "
    },
    {
      "Location ID": "16049",
      "Location Name": "PARKER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 208 WASHINGTON ST PARKER PA 16049 USA "
    },
    {
      "Location ID": "16051",
      "Location Name": "PORTERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1272 PERRY HWY PORTERSVILLE PA 16051 USA "
    },
    {
      "Location ID": "16052",
      "Location Name": "PROSPECT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 S FRANKLIN ST PROSPECT PA 16052 USA "
    },
    {
      "Location ID": "16053",
      "Location Name": "RENFREW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 E MAIN ST RENFREW PA 16053 USA "
    },
    {
      "Location ID": "16054",
      "Location Name": "SAINT PETERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 711 MAIN ST SAINT PETERSBURG PA 16054 USA "
    },
    {
      "Location ID": "16055",
      "Location Name": "SARVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 705 EKASTOWN RD SARVER PA 16055 USA "
    },
    {
      "Location ID": "16056",
      "Location Name": "SAXONBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 PITTSBURGH ST SAXONBURG PA 16056 USA "
    },
    {
      "Location ID": "16057",
      "Location Name": "SLIPPERY ROCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 S MAIN ST SLIPPERY ROCK PA 16057 USA "
    },
    {
      "Location ID": "16058",
      "Location Name": "TURKEY CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1245 ROUTE 338 STE 2 TURKEY CITY PA 16058 USA "
    },
    {
      "Location ID": "16059",
      "Location Name": "VALENCIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1324 PITTSBURGH RD VALENCIA PA 16059 USA "
    },
    {
      "Location ID": "16061",
      "Location Name": "WEST SUNBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 W STATE ST WEST SUNBURY PA 16061 USA "
    },
    {
      "Location ID": "16063",
      "Location Name": "ZELIENOPLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 249 S MAIN ST ZELIENOPLE PA 16063 USA "
    },
    {
      "Location ID": "16066",
      "Location Name": "CRANBERRY TOWNSHIP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 240 EXECUTIVE DR CRANBERRY TOWNSHIP PA 16066 USA "
    },
    {
      "Location ID": "161",
      "Location Name": "NEW CASTLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 435 S CASCADE ST NEW CASTLE PA 16108 USA "
    },
    {
      "Location ID": "16108",
      "Location Name": "DOWNTOWN NEW CASTLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 151 CASCADE GALLERIA NEW CASTLE PA 16101 USA "
    },
    {
      "Location ID": "16111",
      "Location Name": "ATLANTIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3498 ATLANTIC LAKE RD ATLANTIC PA 16111 USA "
    },
    {
      "Location ID": "16112",
      "Location Name": "BESSEMER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 E POLAND AVE BESSEMER PA 16112 USA "
    },
    {
      "Location ID": "16115",
      "Location Name": "DARLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 708 MARKET ST DARLINGTON PA 16115 USA "
    },
    {
      "Location ID": "16116",
      "Location Name": "EDINBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5412 ERIE ST EDINBURG PA 16116 USA "
    },
    {
      "Location ID": "16117",
      "Location Name": "ELLWOOD CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 320 7TH ST ELLWOOD CITY PA 16117 USA "
    },
    {
      "Location ID": "16120",
      "Location Name": "ENON VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7150 VINE ST ENON VALLEY PA 16120 USA "
    },
    {
      "Location ID": "16121",
      "Location Name": "FARRELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 700 SPEARMAN AVE FARRELL PA 16121 USA "
    },
    {
      "Location ID": "16125",
      "Location Name": "GREENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 CLINTON ST GREENVILLE PA 16125 USA "
    },
    {
      "Location ID": "16127",
      "Location Name": "GROVE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 E PINE ST GROVE CITY PA 16127 USA "
    },
    {
      "Location ID": "16131",
      "Location Name": "HARTSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6768 CENTER ST HARTSTOWN PA 16131 USA "
    },
    {
      "Location ID": "16132",
      "Location Name": "HILLSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2562 CHURCHILL RD HILLSVILLE PA 16132 USA "
    },
    {
      "Location ID": "16134",
      "Location Name": "JAMESTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 LIBERTY ST JAMESTOWN PA 16134 USA "
    },
    {
      "Location ID": "16136",
      "Location Name": "KOPPEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5502 5TH AVE KOPPEL PA 16136 USA "
    },
    {
      "Location ID": "16137",
      "Location Name": "MERCER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 E VENANGO ST MERCER PA 16137 USA "
    },
    {
      "Location ID": "16140",
      "Location Name": "NEW BEDFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 425 STATE ROUTE 208 NEW BEDFORD PA 16140 USA "
    },
    {
      "Location ID": "16141",
      "Location Name": "NEW GALILEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 801 WASHINGTON AVE NEW GALILEE PA 16141 USA "
    },
    {
      "Location ID": "16142",
      "Location Name": "NEW WILMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 143 W NESHANNOCK AVE NEW WILMINGTON PA 16142 USA "
    },
    {
      "Location ID": "16143",
      "Location Name": "PULASKI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1930 STATE ROUTE 208 PULASKI PA 16143 USA "
    },
    {
      "Location ID": "16146",
      "Location Name": "SHARON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 SHENANGO AVE SHARON PA 16146 USA "
    },
    {
      "Location ID": "16148",
      "Location Name": "HERMITAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3386 E STATE ST SHARON PA 16148 USA "
    },
    {
      "Location ID": "16155",
      "Location Name": "VILLA MARIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 288 VILLA DR RM MH216 VILLA MARIA PA 16155 USA "
    },
    {
      "Location ID": "16156",
      "Location Name": "VOLANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 HIGH ST VOLANT PA 16156 USA "
    },
    {
      "Location ID": "16157",
      "Location Name": "WAMPUM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 414 BEAVER ST WAMPUM PA 16157 USA "
    },
    {
      "Location ID": "16159",
      "Location Name": "WEST MIDDLESEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 FAIR ST WEST MIDDLESEX PA 16159 USA "
    },
    {
      "Location ID": "16160",
      "Location Name": "WEST PITTSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 341 CENTER AVE WEST PITTSBURG PA 16160 USA "
    },
    {
      "Location ID": "16161",
      "Location Name": "WHEATLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 COUNCIL AVE WHEATLAND PA 16161 USA "
    },
    {
      "Location ID": "161CC",
      "Location Name": "GROVE CITY COLLEGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 CAMPUS DR GROVE CITY PA 16127 USA "
    },
    {
      "Location ID": "161ES",
      "Location Name": "EARTH, SUN, & MOON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 N CENTER ST GROVE CITY PA 16127 USA "
    },
    {
      "Location ID": "161IG",
      "Location Name": "ITHEN GLOBAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 N BROAD ST GROVE CITY PA 16127 USA "
    },
    {
      "Location ID": "161IM",
      "Location Name": "INMETCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 245 PORTERSVILLE RD ELLWOOD CITY PA 16117 USA "
    },
    {
      "Location ID": "161WA",
      "Location Name": "WENDELL AUGUST FORGE (MERCER)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 AMERCIAN WAY MERCER PA 16127 USA "
    },
    {
      "Location ID": "162",
      "Location Name": "KITTANNING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 162 N MCKEAN ST KITTANNING PA 16201 USA "
    },
    {
      "Location ID": "16210",
      "Location Name": "ADRIAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 593 REESEDALE RD ADRIAN PA 16210 USA "
    },
    {
      "Location ID": "16212",
      "Location Name": "CADOGAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 MAIN ST CADOGAN PA 16212 USA "
    },
    {
      "Location ID": "16213",
      "Location Name": "CALLENSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 450A MAIN ST CALLENSBURG PA 16213 USA "
    },
    {
      "Location ID": "16214",
      "Location Name": "CLARION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 626 MAIN ST CLARION PA 16214 USA "
    },
    {
      "Location ID": "16217",
      "Location Name": "COOKSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 RIVERSIDE DR STE 1 COOKSBURG PA 16217 USA "
    },
    {
      "Location ID": "16218",
      "Location Name": "COWANSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 827 E BRADY RD COWANSVILLE PA 16218 USA "
    },
    {
      "Location ID": "16220",
      "Location Name": "CROWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36044 ROUTE 66 CROWN PA 16220 USA "
    },
    {
      "Location ID": "16223",
      "Location Name": "DISTANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3273 STATE ROUTE 28 AND 66 DISTANT PA 16223 USA "
    },
    {
      "Location ID": "16224",
      "Location Name": "FAIRMOUNT CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 54 MAPLE AVE FAIRMOUNT CITY PA 16224 USA "
    },
    {
      "Location ID": "16226",
      "Location Name": "FORD CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 824 4TH AVE FORD CITY PA 16226 USA "
    },
    {
      "Location ID": "16229",
      "Location Name": "FREEPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 415 HIGH ST FREEPORT PA 16229 USA "
    },
    {
      "Location ID": "16230",
      "Location Name": "HAWTHORN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3977 BROOKVILLE ST HAWTHORN PA 16230 USA "
    },
    {
      "Location ID": "16232",
      "Location Name": "KNOX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 523 E RAILROAD ST KNOX PA 16232 USA "
    },
    {
      "Location ID": "16233",
      "Location Name": "LEEPER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6815 ROUTE 36 LEEPER PA 16233 USA "
    },
    {
      "Location ID": "16235",
      "Location Name": "LUCINDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 454 MAPLE DR LUCINDA PA 16235 USA "
    },
    {
      "Location ID": "16236",
      "Location Name": "MC GRANN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 207 BYRON ST MC GRANN PA 16236 USA "
    },
    {
      "Location ID": "16238",
      "Location Name": "MANORVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 WATER ST MANORVILLE PA 16238 USA "
    },
    {
      "Location ID": "16239",
      "Location Name": "MARIENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 S FOREST ST MARIENVILLE PA 16239 USA "
    },
    {
      "Location ID": "16240",
      "Location Name": "MAYPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 395 MAYPORT RD MAYPORT PA 16240 USA "
    },
    {
      "Location ID": "16242",
      "Location Name": "NEW BETHLEHEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 451 BROAD ST NEW BETHLEHEM PA 16242 USA "
    },
    {
      "Location ID": "16245",
      "Location Name": "OAK RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 MAIN ST OAK RIDGE PA 16245 USA "
    },
    {
      "Location ID": "16248",
      "Location Name": "RIMERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 689 MAIN ST RIMERSBURG PA 16248 USA "
    },
    {
      "Location ID": "16254",
      "Location Name": "SHIPPENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 305 MAIN ST SHIPPENVILLE PA 16254 USA "
    },
    {
      "Location ID": "16255",
      "Location Name": "SLIGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 312 MADISON ST SLIGO PA 16255 USA "
    },
    {
      "Location ID": "16258",
      "Location Name": "STRATTANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 361 WASHINGTON ST STRATTANVILLE PA 16258 USA "
    },
    {
      "Location ID": "16259",
      "Location Name": "TEMPLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 232 3RD ST STE 1 TEMPLETON PA 16259 USA "
    },
    {
      "Location ID": "16261",
      "Location Name": "WIDNOON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 858 MADISON RD WIDNOON PA 16261 USA "
    },
    {
      "Location ID": "16262",
      "Location Name": "WORTHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 199 E MAIN ST WORTHINGTON PA 16262 USA "
    },
    {
      "Location ID": "163",
      "Location Name": "OIL CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 302 E 2ND ST OIL CITY PA 16301 USA "
    },
    {
      "Location ID": "16301",
      "Location Name": "OIL CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 302 E 2ND ST OIL CITY PA 16301 USA "
    },
    {
      "Location ID": "16311",
      "Location Name": "CARLTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1333 COCHRANTON RD CARLTON PA 16311 USA "
    },
    {
      "Location ID": "16312",
      "Location Name": "CHANDLERS VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9243 JACKSON RUN RD CHANDLERS VALLEY PA 16312 USA "
    },
    {
      "Location ID": "16314",
      "Location Name": "COCHRANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 N FRANKLIN ST COCHRANTON PA 16314 USA "
    },
    {
      "Location ID": "16316",
      "Location Name": "CONNEAUT LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 170 N 4TH ST CONNEAUT LAKE PA 16316 USA "
    },
    {
      "Location ID": "16317",
      "Location Name": "COOPERSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 137 BRADLEYTOWN RD COOPERSTOWN PA 16317 USA "
    },
    {
      "Location ID": "16323",
      "Location Name": "FRANKLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1202 ELK ST FRANKLIN PA 16323 USA "
    },
    {
      "Location ID": "16329",
      "Location Name": "IRVINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 853 NATIONAL FORGE RD IRVINE PA 16329 USA "
    },
    {
      "Location ID": "16331",
      "Location Name": "KOSSUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1787 ROUTE 322 STE 1 KOSSUTH PA 16331 USA "
    },
    {
      "Location ID": "16335",
      "Location Name": "MEADVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 212 ARCH ST MEADVILLE PA 16335 USA "
    },
    {
      "Location ID": "16340",
      "Location Name": "PITTSFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 128 DALRYMPLE ST PITTSFIELD PA 16340 USA "
    },
    {
      "Location ID": "16341",
      "Location Name": "PLEASANTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 S MAIN ST PLEASANTVILLE PA 16341 USA "
    },
    {
      "Location ID": "16342",
      "Location Name": "POLK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 710 MAIN ST POLK PA 16342 USA "
    },
    {
      "Location ID": "16343",
      "Location Name": "RENO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1891 ALLEGHENY BLVD RENO PA 16343 USA "
    },
    {
      "Location ID": "16345",
      "Location Name": "RUSSELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 LIBERTY ST RUSSELL PA 16345 USA "
    },
    {
      "Location ID": "16346",
      "Location Name": "SENECA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 MEADOW RD SENECA PA 16346 USA "
    },
    {
      "Location ID": "16347",
      "Location Name": "SHEFFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90 S MAIN ST SHEFFIELD PA 16347 USA "
    },
    {
      "Location ID": "16350",
      "Location Name": "SUGAR GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 ABBOTT ST SUGAR GROVE PA 16350 USA "
    },
    {
      "Location ID": "16353",
      "Location Name": "TIONESTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 FAULKNER DR TIONESTA PA 16353 USA "
    },
    {
      "Location ID": "16354",
      "Location Name": "TITUSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 135 W SPRING ST TITUSVILLE PA 16354 USA "
    },
    {
      "Location ID": "16362",
      "Location Name": "UTICA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3838 ACADEMY ST UTICA PA 16362 USA "
    },
    {
      "Location ID": "16365",
      "Location Name": "WARREN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 W 3RD AVE STE 100 WARREN PA 16365 USA "
    },
    {
      "Location ID": "16371",
      "Location Name": "YOUNGSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 RAILROAD ST YOUNGSVILLE PA 16371 USA "
    },
    {
      "Location ID": "16372",
      "Location Name": "CLINTONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 IRWIN ST CLINTONVILLE PA 16372 USA "
    },
    {
      "Location ID": "16373",
      "Location Name": "EMLENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 714 MAIN ST EMLENTON PA 16373 USA "
    },
    {
      "Location ID": "16374",
      "Location Name": "KENNERDELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 240 MAIN ST KENNERDELL PA 16374 USA "
    },
    {
      "Location ID": "163AG",
      "Location Name": "AST GLOBAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10921 MURRAY RD STE 550 MEADVILLE PA 16335 USA "
    },
    {
      "Location ID": "163PA",
      "Location Name": "PATTERSON'S AUTO WRECKING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1973 OLD HIGHWAY 322 COCHRANTON PA 16314 USA "
    },
    {
      "Location ID": "163WI",
      "Location Name": "WARREN INDUSTRIES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18317 INDUSTRIAL DR MEADVILLE PA 16388 USA "
    },
    {
      "Location ID": "164",
      "Location Name": "LOG ERIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2108 E 38TH ST ERIE PA 16515 USA "
    },
    {
      "Location ID": "16401",
      "Location Name": "ALBION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 E STATE ST ALBION PA 16401 USA "
    },
    {
      "Location ID": "16403",
      "Location Name": "CAMBRIDGE SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 332 VENANGO AVE CAMBRIDGE SPRINGS PA 16403 USA "
    },
    {
      "Location ID": "16404",
      "Location Name": "CENTERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18599 ERIE ST CENTERVILLE PA 16404 USA "
    },
    {
      "Location ID": "16405",
      "Location Name": "COLUMBUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 E MAIN ST COLUMBUS PA 16405 USA "
    },
    {
      "Location ID": "16406",
      "Location Name": "CONNEAUTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 809 MAIN ST CONNEAUTVILLE PA 16406 USA "
    },
    {
      "Location ID": "16407",
      "Location Name": "CORRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 S CENTER ST CORRY PA 16407 USA "
    },
    {
      "Location ID": "16410",
      "Location Name": "CRANESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10283 E CRANE ST CRANESVILLE PA 16410 USA "
    },
    {
      "Location ID": "16411",
      "Location Name": "EAST SPRINGFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11932 MAIN ST STE A EAST SPRINGFIELD PA 16411 USA "
    },
    {
      "Location ID": "16412",
      "Location Name": "EDINBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 WATERFORD ST EDINBORO PA 16412 USA "
    },
    {
      "Location ID": "16413",
      "Location Name": "ELGIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18250 MAIN ST ELGIN PA 16413 USA "
    },
    {
      "Location ID": "16415",
      "Location Name": "FAIRVIEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3856 AVONIA RD FAIRVIEW PA 16415 USA "
    },
    {
      "Location ID": "16416",
      "Location Name": "GARLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 EXTENSION RD GARLAND PA 16416 USA "
    },
    {
      "Location ID": "16417",
      "Location Name": "GIRARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 MAIN ST E GIRARD PA 16417 USA "
    },
    {
      "Location ID": "16420",
      "Location Name": "GRAND VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 76 ELDRED CENTER RD GRAND VALLEY PA 16420 USA "
    },
    {
      "Location ID": "16421",
      "Location Name": "HARBORCREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7175 BUFFALO RD HARBORCREEK PA 16421 USA "
    },
    {
      "Location ID": "16422",
      "Location Name": "HARMONSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14238 LEXINGTON ST HARMONSBURG PA 16422 USA "
    },
    {
      "Location ID": "16424",
      "Location Name": "LINESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 141 W ERIE ST LINESVILLE PA 16424 USA "
    },
    {
      "Location ID": "16426",
      "Location Name": "MC KEAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8988 MAIN ST MC KEAN PA 16426 USA "
    },
    {
      "Location ID": "16427",
      "Location Name": "MILL VILLAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14525 S MAIN ST MILL VILLAGE PA 16427 USA "
    },
    {
      "Location ID": "16428",
      "Location Name": "NORTH EAST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 38 S LAKE ST NORTH EAST PA 16428 USA "
    },
    {
      "Location ID": "16433",
      "Location Name": "SAEGERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 521 MAIN ST SAEGERTOWN PA 16433 USA "
    },
    {
      "Location ID": "16434",
      "Location Name": "SPARTANSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 258 MAIN ST SPARTANSBURG PA 16434 USA "
    },
    {
      "Location ID": "16435",
      "Location Name": "SPRINGBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 BEAVER ST SPRINGBORO PA 16435 USA "
    },
    {
      "Location ID": "16436",
      "Location Name": "SPRING CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 185 OLD ROUTE 77 SPRING CREEK PA 16436 USA "
    },
    {
      "Location ID": "16438",
      "Location Name": "UNION CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90 N MAIN ST UNION CITY PA 16438 USA "
    },
    {
      "Location ID": "16440",
      "Location Name": "VENANGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21663 CUSSEWAGO ST VENANGO PA 16440 USA "
    },
    {
      "Location ID": "16441",
      "Location Name": "WATERFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 HIGH ST WATERFORD PA 16441 USA "
    },
    {
      "Location ID": "16442",
      "Location Name": "WATTSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14410 MAIN ST WATTSBURG PA 16442 USA "
    },
    {
      "Location ID": "16443",
      "Location Name": "WEST SPRINGFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13788 RIDGE RD WEST SPRINGFIELD PA 16443 USA "
    },
    {
      "Location ID": "164YO",
      "Location Name": "YO YO SAM/MSE ENTERPRISES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7870 W RIDGE RD FAIRVIEW PA 16415 USA "
    },
    {
      "Location ID": "165",
      "Location Name": "ERIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2108 E. 38TH ST ERIE  A PA 16515 USA "
    },
    {
      "Location ID": "16501",
      "Location Name": "DOWNTOWN ERIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1401 STATE ST STE 100 ERIE PA 16501 USA "
    },
    {
      "Location ID": "16508",
      "Location Name": "SOUTH ERIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3607 POPLAR ST ERIE PA 16508 USA "
    },
    {
      "Location ID": "16530",
      "Location Name": "ERIE INSURANCE GROUP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 ERIE INSURANCE PLAZA ERIE PA 16530 USA "
    },
    {
      "Location ID": "165FS",
      "Location Name": "FISH USA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6960 W RIDGE RD FAIRVIEW PA 16415 USA "
    },
    {
      "Location ID": "165PI",
      "Location Name": "PRESQUE ISLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2711 LEGION RD ERIE PA 16506 USA "
    },
    {
      "Location ID": "165VA",
      "Location Name": "VETERANS MEDICAL CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 135 E 38TH ST ERIE PA 16504 USA "
    },
    {
      "Location ID": "166",
      "Location Name": "LOG ALTOONA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 141 PATCHWAY RD DUNCANSVILLE PA 16635 USA "
    },
    {
      "Location ID": "16601",
      "Location Name": "ALTOONA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1201 11TH AVE ALTOONA PA 16601 USA "
    },
    {
      "Location ID": "16621",
      "Location Name": "BROAD TOP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3956 LOCUST ST BROAD TOP PA 16621 USA "
    },
    {
      "Location ID": "16630",
      "Location Name": "CRESSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 832 KEYSTONE AVE CRESSON PA 16630 USA "
    },
    {
      "Location ID": "16631",
      "Location Name": "CURRYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1590 CURRYVILLE RD CURRYVILLE PA 16631 USA "
    },
    {
      "Location ID": "16633",
      "Location Name": "DEFIANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 807 SIX MILE RUN RD DEFIANCE PA 16633 USA "
    },
    {
      "Location ID": "16634",
      "Location Name": "DUDLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3878 BROADTOP MOUNTAIN RD DUDLEY PA 16634 USA "
    },
    {
      "Location ID": "16635",
      "Location Name": "DUNCANSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 146 DEVILLE DR DUNCANSVILLE PA 16635 USA "
    },
    {
      "Location ID": "16641",
      "Location Name": "GALLITZIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 312 CHURCH ST GALLITZIN PA 16641 USA "
    },
    {
      "Location ID": "16647",
      "Location Name": "HESSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12393 MAPLE LN HESSTON PA 16647 USA "
    },
    {
      "Location ID": "16648",
      "Location Name": "HOLLIDAYSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 525 ALLEGHENY ST HOLLIDAYSBURG PA 16648 USA "
    },
    {
      "Location ID": "16650",
      "Location Name": "HOPEWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 211 FRONT ST HOPEWELL PA 16650 USA "
    },
    {
      "Location ID": "16652",
      "Location Name": "HUNTINGDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 WASHINGTON ST HUNTINGDON PA 16652 USA "
    },
    {
      "Location ID": "16657",
      "Location Name": "JAMES CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3322 OLD MOUNTAIN RD STE A JAMES CREEK PA 16657 USA "
    },
    {
      "Location ID": "16660",
      "Location Name": "MC CONNELLSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11701 RAYSTOWN RD FL 1 MC CONNELLSTOWN PA 16660 USA "
    },
    {
      "Location ID": "16662",
      "Location Name": "MARTINSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 E ALLEGHENY ST MARTINSBURG PA 16662 USA "
    },
    {
      "Location ID": "16664",
      "Location Name": "NEW ENTERPRISE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 CHURCH ST NEW ENTERPRISE PA 16664 USA "
    },
    {
      "Location ID": "16665",
      "Location Name": "NEWRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 825 PATRICK LN NEWRY PA 16665 USA "
    },
    {
      "Location ID": "16672",
      "Location Name": "RIDDLESBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 124 NEWTOWN RD RIDDLESBURG PA 16672 USA "
    },
    {
      "Location ID": "16673",
      "Location Name": "ROARING SPRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 427 E MAIN ST ROARING SPRING PA 16673 USA "
    },
    {
      "Location ID": "16674",
      "Location Name": "ROBERTSDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 563 MAIN ST ROBERTSDALE PA 16674 USA "
    },
    {
      "Location ID": "16678",
      "Location Name": "SAXTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 508 MAIN ST SAXTON PA 16678 USA "
    },
    {
      "Location ID": "16679",
      "Location Name": "SIX MILE RUN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1384 MAIN ST SIX MILE RUN PA 16679 USA "
    },
    {
      "Location ID": "16689",
      "Location Name": "WATERFALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 965 WATERFALL RD WATERFALL PA 16689 USA "
    },
    {
      "Location ID": "16694",
      "Location Name": "WOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 242 MAIN ST WOOD PA 16694 USA "
    },
    {
      "Location ID": "16695",
      "Location Name": "WOODBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 143 MAIN ST WOODBURY PA 16695 USA "
    },
    {
      "Location ID": "166BP",
      "Location Name": "JM BOSWELL PIPES AND CIGARS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6481 WILLIAM PENN HWY ALEXANDRIA PA 16611 USA "
    },
    {
      "Location ID": "166HC",
      "Location Name": "HUNTINGDON CORRECTION INSTITUT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1120 PIKE ST HUNTINGDON PA 16652 USA "
    },
    {
      "Location ID": "166LC",
      "Location Name": "LION COUNTRY SUPPLY (TYRONE)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12133 S EAGLE VALLEY RD TYRONE PA 16686 USA "
    },
    {
      "Location ID": "166NA",
      "Location Name": "N AMERICAN COMMUNICATIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 141 NAC DR DUNCANSVILLE PA 16635 USA "
    },
    {
      "Location ID": "166NP",
      "Location Name": "NPC DMU",
      "Time Zone Offset (Hours)": 0,
      "Address": " RR 2 BOX 1321 CLAYSBURG PA 16625 USA "
    },
    {
      "Location ID": "167",
      "Location Name": "BRADFORD   PA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 BOYLSTON ST BRADFORD PA 16701 USA "
    },
    {
      "Location ID": "16701",
      "Location Name": "BRADFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 BOYLSTON ST BRADFORD PA 16701 USA "
    },
    {
      "Location ID": "16720",
      "Location Name": "AUSTIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 MAIN ST AUSTIN PA 16720 USA "
    },
    {
      "Location ID": "16730",
      "Location Name": "EAST SMETHPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18253 ROUTE 6 EAST SMETHPORT PA 16730 USA "
    },
    {
      "Location ID": "16734",
      "Location Name": "JAMES CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 147 DELAWARE AVE JAMES CITY PA 16734 USA "
    },
    {
      "Location ID": "16735",
      "Location Name": "KANE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 131 N FRALEY ST KANE PA 16735 USA "
    },
    {
      "Location ID": "16740",
      "Location Name": "MOUNT JEWETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 W MAIN ST MOUNT JEWETT PA 16740 USA "
    },
    {
      "Location ID": "16743",
      "Location Name": "PORT ALLEGANY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 64 W MILL ST PORT ALLEGANY PA 16743 USA "
    },
    {
      "Location ID": "16746",
      "Location Name": "ROULETTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 MAIN ST ROULETTE PA 16746 USA "
    },
    {
      "Location ID": "16749",
      "Location Name": "SMETHPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 N MECHANIC ST SMETHPORT PA 16749 USA "
    },
    {
      "Location ID": "16750",
      "Location Name": "TURTLEPOINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1787 CHAMPLIN HILL RD UNIT 2 TURTLEPOINT PA 16750 USA "
    },
    {
      "Location ID": "168",
      "Location Name": "STATE COLLEGE  PA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 237 S FRAZER STATE COLLEGE PA 16801 USA "
    },
    {
      "Location ID": "16801",
      "Location Name": "STATE COLLEGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 237 S FRASER ST STATE COLLEGE PA 16801 USA "
    },
    {
      "Location ID": "16821",
      "Location Name": "ALLPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5695 MORRISDALE ALLPORT HWY ALLPORT PA 16821 USA "
    },
    {
      "Location ID": "16822",
      "Location Name": "BEECH CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 GRANT ST BEECH CREEK PA 16822 USA "
    },
    {
      "Location ID": "16823",
      "Location Name": "BELLEFONTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 132 N ALLEGHENY ST BELLEFONTE PA 16823 USA "
    },
    {
      "Location ID": "16825",
      "Location Name": "BIGLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 336 BIGLER RD BIGLER PA 16825 USA "
    },
    {
      "Location ID": "16826",
      "Location Name": "BLANCHARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 BEACH ST BLANCHARD PA 16826 USA "
    },
    {
      "Location ID": "16827",
      "Location Name": "BOALSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 W PINE ST BOALSBURG PA 16827 USA "
    },
    {
      "Location ID": "16828",
      "Location Name": "CENTRE HALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 E CHURCH ST CENTRE HALL PA 16828 USA "
    },
    {
      "Location ID": "16829",
      "Location Name": "CLARENCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 788 CLARENCE RD CLARENCE PA 16829 USA "
    },
    {
      "Location ID": "16830",
      "Location Name": "CLEARFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 N 2ND ST CLEARFIELD PA 16830 USA "
    },
    {
      "Location ID": "16833",
      "Location Name": "CURWENSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 525 STATE ST CURWENSVILLE PA 16833 USA "
    },
    {
      "Location ID": "16834",
      "Location Name": "DRIFTING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5433 KYLERTOWN DRIFTING HWY DRIFTING PA 16834 USA "
    },
    {
      "Location ID": "16835",
      "Location Name": "FLEMING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 241 MAIN ST FLEMING PA 16835 USA "
    },
    {
      "Location ID": "16838",
      "Location Name": "GRAMPIAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 273 MAIN ST GRAMPIAN PA 16838 USA "
    },
    {
      "Location ID": "16840",
      "Location Name": "HAWK RUN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 398 POWELL ST HAWK RUN PA 16840 USA "
    },
    {
      "Location ID": "16841",
      "Location Name": "HOWARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 324 WALNUT ST HOWARD PA 16841 USA "
    },
    {
      "Location ID": "16843",
      "Location Name": "HYDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1501 WASHINGTON AVE HYDE PA 16843 USA "
    },
    {
      "Location ID": "16844",
      "Location Name": "JULIAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4848 S EAGLE VALLEY RD JULIAN PA 16844 USA "
    },
    {
      "Location ID": "16847",
      "Location Name": "KYLERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 93 ROLLING STONE RD KYLERTOWN PA 16847 USA "
    },
    {
      "Location ID": "16848",
      "Location Name": "LAMAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5015 NITTANY VALLEY DR LAMAR PA 16848 USA "
    },
    {
      "Location ID": "16849",
      "Location Name": "LANSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 977 MAPLE ST LANSE PA 16849 USA "
    },
    {
      "Location ID": "16851",
      "Location Name": "LEMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 141 MARY ST LEMONT PA 16851 USA "
    },
    {
      "Location ID": "16853",
      "Location Name": "MILESBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 MILL ST MILESBURG PA 16853 USA "
    },
    {
      "Location ID": "16854",
      "Location Name": "MILLHEIM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 W MAIN ST MILLHEIM PA 16854 USA "
    },
    {
      "Location ID": "16855",
      "Location Name": "MINERAL SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 244 POST OFFICE RD MINERAL SPRINGS PA 16855 USA "
    },
    {
      "Location ID": "16856",
      "Location Name": "MINGOVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 HECLA RD MINGOVILLE PA 16856 USA "
    },
    {
      "Location ID": "16858",
      "Location Name": "MORRISDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 52 CHURCH ST MORRISDALE PA 16858 USA "
    },
    {
      "Location ID": "16859",
      "Location Name": "MOSHANNON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1511 W SYCAMORE RD MOSHANNON PA 16859 USA "
    },
    {
      "Location ID": "16865",
      "Location Name": "PENNSYLVANIA FURNACE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6890 W WHITEHALL RD PENNSYLVANIA FURNACE PA 16865 USA "
    },
    {
      "Location ID": "16866",
      "Location Name": "PHILIPSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 222 E LAUREL ST PHILIPSBURG PA 16866 USA "
    },
    {
      "Location ID": "16868",
      "Location Name": "PINE GROVE MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 112 E PINE GROVE RD PINE GROVE MILLS PA 16868 USA "
    },
    {
      "Location ID": "16870",
      "Location Name": "PORT MATILDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 N HIGH ST PORT MATILDA PA 16870 USA "
    },
    {
      "Location ID": "16874",
      "Location Name": "SNOW SHOE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 S MOSHANNON AVE SNOW SHOE PA 16874 USA "
    },
    {
      "Location ID": "16875",
      "Location Name": "SPRING MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 136 SCHOOL ST SPRING MILLS PA 16875 USA "
    },
    {
      "Location ID": "16876",
      "Location Name": "WALLACETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 445 CLEARFIELD ST WALLACETON PA 16876 USA "
    },
    {
      "Location ID": "16877",
      "Location Name": "WARRIORS MARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1883 CENTRE LINE RD WARRIORS MARK PA 16877 USA "
    },
    {
      "Location ID": "16878",
      "Location Name": "WEST DECATUR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 BLUE BALL RD WEST DECATUR PA 16878 USA "
    },
    {
      "Location ID": "16879",
      "Location Name": "WINBURNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1208 MAIN ST WINBURNE PA 16879 USA "
    },
    {
      "Location ID": "16881",
      "Location Name": "WOODLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1374 SHAWVILLE HWY WOODLAND PA 16881 USA "
    },
    {
      "Location ID": "169",
      "Location Name": "WELLSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 MAIN ST WELLSBORO PA 16901 USA "
    },
    {
      "Location ID": "16911",
      "Location Name": "ARNOT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 834 ARNOT RD ARNOT PA 16911 USA "
    },
    {
      "Location ID": "16912",
      "Location Name": "BLOSSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 RIVERSIDE PLZ BLOSSBURG PA 16912 USA "
    },
    {
      "Location ID": "16914",
      "Location Name": "COLUMBIA CROSS ROADS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6921 AUSTINVILLE RD COLUMBIA CROSS ROADS PA 16914 USA "
    },
    {
      "Location ID": "16915",
      "Location Name": "COUDERSPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 N MAIN ST COUDERSPORT PA 16915 USA "
    },
    {
      "Location ID": "16917",
      "Location Name": "COVINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2220 N WILLIAMSON RD COVINGTON PA 16917 USA "
    },
    {
      "Location ID": "16920",
      "Location Name": "ELKLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 S BUFFALO ST ELKLAND PA 16920 USA "
    },
    {
      "Location ID": "16921",
      "Location Name": "GAINES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1977 ROUTE 6 GAINES PA 16921 USA "
    },
    {
      "Location ID": "16922",
      "Location Name": "GALETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 BRIDGE ST GALETON PA 16922 USA "
    },
    {
      "Location ID": "16923",
      "Location Name": "GENESEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 276 MAIN ST GENESEE PA 16923 USA "
    },
    {
      "Location ID": "16925",
      "Location Name": "GILLETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33165 ROUTE 14 GILLETT PA 16925 USA "
    },
    {
      "Location ID": "16928",
      "Location Name": "KNOXVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 E MAIN ST KNOXVILLE PA 16928 USA "
    },
    {
      "Location ID": "16929",
      "Location Name": "LAWRENCEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 MAIN ST LAWRENCEVILLE PA 16929 USA "
    },
    {
      "Location ID": "16930",
      "Location Name": "LIBERTY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4514 WILLIAMSON TRL LIBERTY PA 16930 USA "
    },
    {
      "Location ID": "16932",
      "Location Name": "MAINESBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 MAINESBURG RD MAINESBURG PA 16932 USA "
    },
    {
      "Location ID": "16933",
      "Location Name": "MANSFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 320 S MAIN ST MANSFIELD PA 16933 USA "
    },
    {
      "Location ID": "16935",
      "Location Name": "MIDDLEBURY CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11660 ROUTE 287 MIDDLEBURY CENTER PA 16935 USA "
    },
    {
      "Location ID": "16936",
      "Location Name": "MILLERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 45 BACK ST MILLERTON PA 16936 USA "
    },
    {
      "Location ID": "16937",
      "Location Name": "MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5021 STATE ROUTE 49 W MILLS PA 16937 USA "
    },
    {
      "Location ID": "16938",
      "Location Name": "MORRIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 917 ROUTE 287 MORRIS PA 16938 USA "
    },
    {
      "Location ID": "16939",
      "Location Name": "MORRIS RUN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1043 MORRIS RUN RD MORRIS RUN PA 16939 USA "
    },
    {
      "Location ID": "16940",
      "Location Name": "NELSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 VILLAGE DR NELSON PA 16940 USA "
    },
    {
      "Location ID": "16942",
      "Location Name": "OSCEOLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7570 ROUTE 49 OSCEOLA PA 16942 USA "
    },
    {
      "Location ID": "16943",
      "Location Name": "SABINSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1056 SPRUCE ST SABINSVILLE PA 16943 USA "
    },
    {
      "Location ID": "16945",
      "Location Name": "SYLVANIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2345 SYLVANIA RD SYLVANIA PA 16945 USA "
    },
    {
      "Location ID": "16946",
      "Location Name": "TIOGA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 N MAIN ST TIOGA PA 16946 USA "
    },
    {
      "Location ID": "16947",
      "Location Name": "TROY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 416 CANTON ST TROY PA 16947 USA "
    },
    {
      "Location ID": "16948",
      "Location Name": "ULYSSES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 408 N MAIN ST ULYSSES PA 16948 USA "
    },
    {
      "Location ID": "16950",
      "Location Name": "WESTFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 W MAIN ST WESTFIELD PA 16950 USA "
    },
    {
      "Location ID": "170",
      "Location Name": "LOG HARRISBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1425 CROOKED HILL RD HARRISBURG PA 17107 USA "
    },
    {
      "Location ID": "17003",
      "Location Name": "ANNVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 51 N LANCASTER ST ANNVILLE PA 17003 USA "
    },
    {
      "Location ID": "17004",
      "Location Name": "BELLEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4242 E MAIN ST BELLEVILLE PA 17004 USA "
    },
    {
      "Location ID": "17005",
      "Location Name": "BERRYSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 137 N CHESTNUT ST BERRYSBURG PA 17005 USA "
    },
    {
      "Location ID": "17007",
      "Location Name": "BOILING SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 E 1ST ST BOILING SPRINGS PA 17007 USA "
    },
    {
      "Location ID": "17009",
      "Location Name": "BURNHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 229 N LOGAN BLVD BURNHAM PA 17009 USA "
    },
    {
      "Location ID": "17010",
      "Location Name": "CAMPBELLTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 N PALMYRA RD CAMPBELLTOWN PA 17010 USA "
    },
    {
      "Location ID": "17013",
      "Location Name": "CARLISLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 66 W LOUTHER ST CARLISLE PA 17013 USA "
    },
    {
      "Location ID": "17016",
      "Location Name": "CORNWALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 BOYD ST CORNWALL PA 17016 USA "
    },
    {
      "Location ID": "17018",
      "Location Name": "DAUPHIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 204 ALLEGHENY ST DAUPHIN PA 17018 USA "
    },
    {
      "Location ID": "17019",
      "Location Name": "DILLSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 N BALTIMORE ST DILLSBURG PA 17019 USA "
    },
    {
      "Location ID": "17020",
      "Location Name": "DUNCANNON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 203 N MARKET ST DUNCANNON PA 17020 USA "
    },
    {
      "Location ID": "17021",
      "Location Name": "EAST WATERFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9393 ROUTE 75 S EAST WATERFORD PA 17021 USA "
    },
    {
      "Location ID": "17023",
      "Location Name": "ELIZABETHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 N COLLIER ST ELIZABETHVILLE PA 17023 USA "
    },
    {
      "Location ID": "17026",
      "Location Name": "FREDERICKSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3024 S PINE GROVE ST FREDERICKSBURG PA 17026 USA "
    },
    {
      "Location ID": "17027",
      "Location Name": "GRANTHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 S COLLEGE AVE GRANTHAM PA 17027 USA "
    },
    {
      "Location ID": "17028",
      "Location Name": "GRANTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 251 FIREHOUSE RD GRANTVILLE PA 17028 USA "
    },
    {
      "Location ID": "17029",
      "Location Name": "GRANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 324 GRANVILLE RD GRANVILLE PA 17029 USA "
    },
    {
      "Location ID": "17030",
      "Location Name": "GRATZ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 S CENTER ST GRATZ PA 17030 USA "
    },
    {
      "Location ID": "17032",
      "Location Name": "HALIFAX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 ARMSTRONG ST HALIFAX PA 17032 USA "
    },
    {
      "Location ID": "17033",
      "Location Name": "HERSHEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 151 W CHOCOLATE AVE STE 145 HERSHEY PA 17033 USA "
    },
    {
      "Location ID": "17035",
      "Location Name": "HONEY GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13134 ROUTE 75 S HONEY GROVE PA 17035 USA "
    },
    {
      "Location ID": "17036",
      "Location Name": "HUMMELSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 448 WALTON AVE HUMMELSTOWN PA 17036 USA "
    },
    {
      "Location ID": "17037",
      "Location Name": "ICKESBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6978 VETERANS WAY ICKESBURG PA 17037 USA "
    },
    {
      "Location ID": "17038",
      "Location Name": "JONESTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 217 S LANCASTER ST JONESTOWN PA 17038 USA "
    },
    {
      "Location ID": "17041",
      "Location Name": "LAWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5575 ELIZABETHTOWN RD LAWN PA 17041 USA "
    },
    {
      "Location ID": "17042",
      "Location Name": "LEBANON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 S 8TH ST LEBANON PA 17042 USA "
    },
    {
      "Location ID": "17044",
      "Location Name": "LEWISTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 W MARKET ST LEWISTOWN PA 17044 USA "
    },
    {
      "Location ID": "17045",
      "Location Name": "LIVERPOOL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 RACE ST LIVERPOOL PA 17045 USA "
    },
    {
      "Location ID": "17048",
      "Location Name": "LYKENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 668 MAIN ST LYKENS PA 17048 USA "
    },
    {
      "Location ID": "17049",
      "Location Name": "MC ALISTERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 132 WESTFALL ST MC ALISTERVILLE PA 17049 USA "
    },
    {
      "Location ID": "17051",
      "Location Name": "MC VEYTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 E JOHN ST MC VEYTOWN PA 17051 USA "
    },
    {
      "Location ID": "17052",
      "Location Name": "MAPLETON DEPOT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 617 MAIN ST MAPLETON DEPOT PA 17052 USA "
    },
    {
      "Location ID": "17053",
      "Location Name": "MARYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 309 CAMERON ST MARYSVILLE PA 17053 USA "
    },
    {
      "Location ID": "17054",
      "Location Name": "MATTAWANA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 86 STATE ROUTE 103 N MATTAWANA PA 17054 USA "
    },
    {
      "Location ID": "17056",
      "Location Name": "MEXICO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6798 WILLIAM PENN HWY MEXICO PA 17056 USA "
    },
    {
      "Location ID": "17058",
      "Location Name": "MIFFLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 112 MAIN ST MIFFLIN PA 17058 USA "
    },
    {
      "Location ID": "17059",
      "Location Name": "MIFFLINTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 BRIDGE ST MIFFLINTOWN PA 17059 USA "
    },
    {
      "Location ID": "17060",
      "Location Name": "MILL CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9787 MAIN ST MILL CREEK PA 17060 USA "
    },
    {
      "Location ID": "17061",
      "Location Name": "MILLERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 225 UNION ST MILLERSBURG PA 17061 USA "
    },
    {
      "Location ID": "17062",
      "Location Name": "MILLERSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 N MARKET ST MILLERSTOWN PA 17062 USA "
    },
    {
      "Location ID": "17063",
      "Location Name": "MILROY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90 S MAIN ST MILROY PA 17063 USA "
    },
    {
      "Location ID": "17064",
      "Location Name": "MOUNT GRETNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 PRINCETON AVE MOUNT GRETNA PA 17064 USA "
    },
    {
      "Location ID": "17065",
      "Location Name": "MOUNT HOLLY SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 W PINE ST MOUNT HOLLY SPRINGS PA 17065 USA "
    },
    {
      "Location ID": "17066",
      "Location Name": "MOUNT UNION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 W MARKET ST MOUNT UNION PA 17066 USA "
    },
    {
      "Location ID": "17067",
      "Location Name": "MYERSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 W JEFFERSON AVE MYERSTOWN PA 17067 USA "
    },
    {
      "Location ID": "17068",
      "Location Name": "NEW BLOOMFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 E MCCLURE ST NEW BLOOMFIELD PA 17068 USA "
    },
    {
      "Location ID": "17069",
      "Location Name": "NEW BUFFALO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 BUFFALO CREEK RD NEW BUFFALO PA 17069 USA "
    },
    {
      "Location ID": "17074",
      "Location Name": "NEWPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 226 WALNUT ST NEWPORT PA 17074 USA "
    },
    {
      "Location ID": "17075",
      "Location Name": "NEWTON HAMILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 WAYNE ST NEWTON HAMILTON PA 17075 USA "
    },
    {
      "Location ID": "17076",
      "Location Name": "OAKLAND MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28442 ROUTE 35 N STE 1 OAKLAND MILLS PA 17076 USA "
    },
    {
      "Location ID": "17078",
      "Location Name": "PALMYRA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 S RAILROAD ST PALMYRA PA 17078 USA "
    },
    {
      "Location ID": "17080",
      "Location Name": "PILLOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 182 MARKET ST PILLOW PA 17080 USA "
    },
    {
      "Location ID": "17082",
      "Location Name": "PORT ROYAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 W 3RD ST PORT ROYAL PA 17082 USA "
    },
    {
      "Location ID": "17083",
      "Location Name": "QUENTIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2625 QUENTIN RD QUENTIN PA 17083 USA "
    },
    {
      "Location ID": "17084",
      "Location Name": "REEDSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 S MAIN ST REEDSVILLE PA 17084 USA "
    },
    {
      "Location ID": "17085",
      "Location Name": "REXMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 DOHNER RD REXMONT PA 17085 USA "
    },
    {
      "Location ID": "17086",
      "Location Name": "RICHFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 683 MAIN ST RICHFIELD PA 17086 USA "
    },
    {
      "Location ID": "17094",
      "Location Name": "THOMPSONTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 N MILL ST THOMPSONTOWN PA 17094 USA "
    },
    {
      "Location ID": "17097",
      "Location Name": "WICONISCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 POTTSVILLE ST WICONISCO PA 17097 USA "
    },
    {
      "Location ID": "17098",
      "Location Name": "WILLIAMSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 N WEST ST WILLIAMSTOWN PA 17098 USA "
    },
    {
      "Location ID": "17099",
      "Location Name": "YEAGERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 S MAIN ST YEAGERTOWN PA 17099 USA "
    },
    {
      "Location ID": "170BB",
      "Location Name": "BODYBUILDING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 431 RAILROAD AVE SHIRESTOWN PA 17011 USA "
    },
    {
      "Location ID": "170CO",
      "Location Name": "FRY COMMUNICATIONS BLDG 3",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 PLEASANT VIEW DR MECHANICSBURG PA 17050 USA "
    },
    {
      "Location ID": "170EE",
      "Location Name": "ETERNITY MART EAST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1349 CUMBERLAND ST LEBANON PA 17042 USA "
    },
    {
      "Location ID": "170EM",
      "Location Name": "ETERNITY MART",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1621 WILLOW ST LEBANON PA 17042 USA "
    },
    {
      "Location ID": "170FB",
      "Location Name": "FRY COMMUNICATIONS BLDG 2",
      "Time Zone Offset (Hours)": 0,
      "Address": " 800 W CHURCH RD MECHANICSBURG PA 17055 USA "
    },
    {
      "Location ID": "170FC",
      "Location Name": "FRY COMMUNICATIONS BLDG 1",
      "Time Zone Offset (Hours)": 0,
      "Address": " 800 W CHURCH RD MECHANICSBURG PA 17055 USA "
    },
    {
      "Location ID": "170FD",
      "Location Name": "FRY COMMUNICATIONS BLDG 4",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 FRY DR MECHANICSBURG PA 17050 USA "
    },
    {
      "Location ID": "170FR",
      "Location Name": "FRY COMMUNICATIONS BLDG 5",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 E MAIN ST NEW KINGSTOWN PA 17072 USA "
    },
    {
      "Location ID": "170FZ",
      "Location Name": "LOG HARRISBURG PA PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6455 ALLENTOWN BLVD HARRISBURG PA 17112 USA "
    },
    {
      "Location ID": "170GR",
      "Location Name": "GROVE COLLABORATIVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 CONEWAGO RD ELIZABETHTOWN PA 17022 USA "
    },
    {
      "Location ID": "170HA",
      "Location Name": "HERSHEY CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 312 MILL ST HERSHEY PA 17033 USA "
    },
    {
      "Location ID": "170HS",
      "Location Name": "HOME SUPPLY NETWORK",
      "Time Zone Offset (Hours)": 0,
      "Address": " AIRPORT RD ANNVILLE PA 17003 USA "
    },
    {
      "Location ID": "170MA",
      "Location Name": "MIFFLINTOWN ANNEX   PA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 612 WASHINGTON AVE MIFFLINTOWN PA 17059 USA "
    },
    {
      "Location ID": "170NI",
      "Location Name": "NEW ICKESBRG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 290 SAWMILL RD NEWPORT PA 17074 USA "
    },
    {
      "Location ID": "170NO",
      "Location Name": "NORDSTROM EAST COAST FULFILLMENT CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 DISTRIBUTION DR ELIZABETHTOWN PA 17022 USA "
    },
    {
      "Location ID": "17101",
      "Location Name": "KEYSTONE STATION 01",
      "Time Zone Offset (Hours)": 0,
      "Address": " 813 MARKET STREET HARRISBURG PA 17105 USA "
    },
    {
      "Location ID": "171CP",
      "Location Name": "CAPITOL PRESORT SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1912 CROOKED HILL RD STE 116 HARRISBURG PA 17110 USA "
    },
    {
      "Location ID": "17201",
      "Location Name": "CHAMBERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 308 LINCOLN WAY E CHAMBERSBURG PA 17201 USA "
    },
    {
      "Location ID": "17214",
      "Location Name": "BLUE RIDGE SUMMIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14959 BUCHANAN TRL E BLUE RIDGE SUMMIT PA 17214 USA "
    },
    {
      "Location ID": "17222",
      "Location Name": "FAYETTEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4025 LINCOLN WAY E FAYETTEVILLE PA 17222 USA "
    },
    {
      "Location ID": "17224",
      "Location Name": "FORT LOUDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 131 HAWBAKER DR N FORT LOUDON PA 17224 USA "
    },
    {
      "Location ID": "17225",
      "Location Name": "GREENCASTLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 N WASHINGTON ST GREENCASTLE PA 17225 USA "
    },
    {
      "Location ID": "17228",
      "Location Name": "HARRISONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8764 LINCOLN HWY HARRISONVILLE PA 17228 USA "
    },
    {
      "Location ID": "17229",
      "Location Name": "HUSTONTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 313 PITT ST STE A HUSTONTOWN PA 17229 USA "
    },
    {
      "Location ID": "17231",
      "Location Name": "LEMASTERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4729 STEELE AVE LEMASTERS PA 17231 USA "
    },
    {
      "Location ID": "17232",
      "Location Name": "LURGAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8406 ROXBURY RD LURGAN PA 17232 USA "
    },
    {
      "Location ID": "17233",
      "Location Name": "MC CONNELLSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 E MAPLE ST MC CONNELLSBURG PA 17233 USA "
    },
    {
      "Location ID": "17235",
      "Location Name": "MARION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 144 COLORADO ST MARION PA 17235 USA "
    },
    {
      "Location ID": "17236",
      "Location Name": "MERCERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 128 S MAIN ST MERCERSBURG PA 17236 USA "
    },
    {
      "Location ID": "17237",
      "Location Name": "MONT ALTO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 ASH ST MONT ALTO PA 17237 USA "
    },
    {
      "Location ID": "17238",
      "Location Name": "NEEDMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7992 GREAT COVE RD NEEDMORE PA 17238 USA "
    },
    {
      "Location ID": "17240",
      "Location Name": "NEWBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 S WATER ST NEWBURG PA 17240 USA "
    },
    {
      "Location ID": "17241",
      "Location Name": "NEWVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 57 WEST ST NEWVILLE PA 17241 USA "
    },
    {
      "Location ID": "17244",
      "Location Name": "ORRSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3630 ORRSTOWN RD ORRSTOWN PA 17244 USA "
    },
    {
      "Location ID": "17246",
      "Location Name": "PLEASANT HALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6535 UPPER STRASBURG RD PLEASANT HALL PA 17246 USA "
    },
    {
      "Location ID": "17247",
      "Location Name": "QUINCY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6617 ORPHANAGE RD QUINCY PA 17247 USA "
    },
    {
      "Location ID": "17250",
      "Location Name": "ROUZERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11738 PEN MAR RD ROUZERVILLE PA 17250 USA "
    },
    {
      "Location ID": "17251",
      "Location Name": "ROXBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10970 ROXBURY RD ROXBURY PA 17251 USA "
    },
    {
      "Location ID": "17252",
      "Location Name": "SAINT THOMAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 164 SAINT THOMAS WILLIAMSON RD SAINT THOMAS PA 17252 USA "
    },
    {
      "Location ID": "17254",
      "Location Name": "SCOTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3735 SCOTLAND RD SCOTLAND PA 17254 USA "
    },
    {
      "Location ID": "17256",
      "Location Name": "SHADY GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1814 BUCHANAN TRL E SHADY GROVE PA 17256 USA "
    },
    {
      "Location ID": "17257",
      "Location Name": "SHIPPENSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 46 W KING ST SHIPPENSBURG PA 17257 USA "
    },
    {
      "Location ID": "17261",
      "Location Name": "SOUTH MOUNTAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10444 S MOUNTAIN RD SOUTH MOUNTAIN PA 17261 USA "
    },
    {
      "Location ID": "17263",
      "Location Name": "STATE LINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15753 PENNSYLVANIA AVE S STATE LINE PA 17263 USA "
    },
    {
      "Location ID": "17265",
      "Location Name": "UPPERSTRASBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9808 UPPER STRASBURG RD UPPERSTRASBURG PA 17265 USA "
    },
    {
      "Location ID": "17266",
      "Location Name": "WALNUT BOTTOM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 MAPLE AVE WALNUT BOTTOM PA 17266 USA "
    },
    {
      "Location ID": "17267",
      "Location Name": "WARFORDSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13363 BUCK VALLEY RD WARFORDSBURG PA 17267 USA "
    },
    {
      "Location ID": "17268",
      "Location Name": "WAYNESBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 E MAIN ST WAYNESBORO PA 17268 USA "
    },
    {
      "Location ID": "17272",
      "Location Name": "ZULLINGER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5012 BUCHANAN TRL E ZULLINGER PA 17272 USA "
    },
    {
      "Location ID": "172CL",
      "Location Name": "CEVA LOGISTICS (SHIPPENSBURG)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9300 OLDE SCOTLAND RD SHIPPENSBURG PA 17257 USA "
    },
    {
      "Location ID": "172IB",
      "Location Name": "INGRAM BOOK CO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1240 INGRAM DR CHAMBERSBURG PA 17201 USA "
    },
    {
      "Location ID": "172OD",
      "Location Name": "OFFICE DEPOT (PA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 950 CENTERVILLE RD NEWVILLE PA 17241 USA "
    },
    {
      "Location ID": "172OH",
      "Location Name": "OZBURN-HESSY LOG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1490 NITTERHOUSE RD CHAMBERSBURG PA 17201 USA "
    },
    {
      "Location ID": "172PT",
      "Location Name": "PHOTO THIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2000 LINCOLN WAY E CHAMBERSBURG PA 17202 USA "
    },
    {
      "Location ID": "172TB",
      "Location Name": "TAB BOOKS INC/MCGRAW HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " BLUE RIDGE SUMMIT B. RIDGE SUMMIT PA 17214 USA "
    },
    {
      "Location ID": "172WM",
      "Location Name": "WALMART - PHL4 - 7380",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1410 UNITED DR SHIPPENSBURG PA 17257 USA "
    },
    {
      "Location ID": "17301",
      "Location Name": "ABBOTTSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 680 W KING ST ABBOTTSTOWN PA 17301 USA "
    },
    {
      "Location ID": "17303",
      "Location Name": "ARENDTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 S HIGH ST ARENDTSVILLE PA 17303 USA "
    },
    {
      "Location ID": "17304",
      "Location Name": "ASPERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 420 ASPERS BENDERSVILLE RD ASPERS PA 17304 USA "
    },
    {
      "Location ID": "17306",
      "Location Name": "BENDERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 RAMPIKE HILL RD BENDERSVILLE PA 17306 USA "
    },
    {
      "Location ID": "17307",
      "Location Name": "BIGLERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 HIGH ST BIGLERVILLE PA 17307 USA "
    },
    {
      "Location ID": "17309",
      "Location Name": "BROGUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2224 DELTA RD BROGUE PA 17309 USA "
    },
    {
      "Location ID": "17310",
      "Location Name": "CASHTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 372 HIGH ST CASHTOWN PA 17310 USA "
    },
    {
      "Location ID": "17311",
      "Location Name": "CODORUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 BALTIMORE ST CODORUS PA 17311 USA "
    },
    {
      "Location ID": "17313",
      "Location Name": "DALLASTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 61 N WALNUT ST DALLASTOWN PA 17313 USA "
    },
    {
      "Location ID": "17314",
      "Location Name": "DELTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 COLLEGE AVE DELTA PA 17314 USA "
    },
    {
      "Location ID": "17315",
      "Location Name": "DOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 N MAIN ST DOVER PA 17315 USA "
    },
    {
      "Location ID": "17316",
      "Location Name": "EAST BERLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 214 3RD ST EAST BERLIN PA 17316 USA "
    },
    {
      "Location ID": "17320",
      "Location Name": "FAIRFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 CARROLLS TRACT RD FAIRFIELD PA 17320 USA "
    },
    {
      "Location ID": "17321",
      "Location Name": "FAWN GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 S MARKET ST FAWN GROVE PA 17321 USA "
    },
    {
      "Location ID": "17322",
      "Location Name": "FELTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 RIPPLING RUN RD FELTON PA 17322 USA "
    },
    {
      "Location ID": "17323",
      "Location Name": "FRANKLINTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 S BALTIMORE ST FRANKLINTOWN PA 17323 USA "
    },
    {
      "Location ID": "17325",
      "Location Name": "GETTYSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 BUFORD AVE GETTYSBURG PA 17325 USA "
    },
    {
      "Location ID": "17327",
      "Location Name": "GLEN ROCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 CHURCH ST GLEN ROCK PA 17327 USA "
    },
    {
      "Location ID": "17329",
      "Location Name": "GLENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8142 BLOOMING GROVE RD GLENVILLE PA 17329 USA "
    },
    {
      "Location ID": "17331",
      "Location Name": "HANOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 HIGH ST HANOVER PA 17331 USA "
    },
    {
      "Location ID": "17339",
      "Location Name": "LEWISBERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 408 MARKET ST LEWISBERRY PA 17339 USA "
    },
    {
      "Location ID": "17340",
      "Location Name": "LITTLESTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 W KING ST LITTLESTOWN PA 17340 USA "
    },
    {
      "Location ID": "17342",
      "Location Name": "LOGANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 W ORE ST LOGANVILLE PA 17342 USA "
    },
    {
      "Location ID": "17343",
      "Location Name": "MC KNIGHTSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 245 OLD ROUTE 30 MC KNIGHTSTOWN PA 17343 USA "
    },
    {
      "Location ID": "17344",
      "Location Name": "MC SHERRYSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 328 MAIN ST MC SHERRYSTOWN PA 17344 USA "
    },
    {
      "Location ID": "17349",
      "Location Name": "NEW FREEDOM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 S BROAD ST NEW FREEDOM PA 17349 USA "
    },
    {
      "Location ID": "17350",
      "Location Name": "NEW OXFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 CENTER SQ NEW OXFORD PA 17350 USA "
    },
    {
      "Location ID": "17352",
      "Location Name": "NEW PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44 CAREA RD NEW PARK PA 17352 USA "
    },
    {
      "Location ID": "17355",
      "Location Name": "RAILROAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 E MAIN ST OFC RAILROAD PA 17355 USA "
    },
    {
      "Location ID": "17356",
      "Location Name": "RED LION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 N MAIN ST RED LION PA 17356 USA "
    },
    {
      "Location ID": "17358",
      "Location Name": "ROSSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7489A CARLISLE RD ROSSVILLE PA 17358 USA "
    },
    {
      "Location ID": "17360",
      "Location Name": "SEVEN VALLEYS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 63 CHURCH ST SEVEN VALLEYS PA 17360 USA "
    },
    {
      "Location ID": "17361",
      "Location Name": "SHREWSBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 CONSTITUTION AVE SHREWSBURY PA 17361 USA "
    },
    {
      "Location ID": "17362",
      "Location Name": "SPRING GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 W JACKSON ST SPRING GROVE PA 17362 USA "
    },
    {
      "Location ID": "17363",
      "Location Name": "STEWARTSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 N MAIN ST STEWARTSTOWN PA 17363 USA "
    },
    {
      "Location ID": "17364",
      "Location Name": "THOMASVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5285 LINCOLN HWY W THOMASVILLE PA 17364 USA "
    },
    {
      "Location ID": "17365",
      "Location Name": "WELLSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 224 MAIN ST WELLSVILLE PA 17365 USA "
    },
    {
      "Location ID": "17366",
      "Location Name": "WINDSOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 S CAMP ST WINDSOR PA 17366 USA "
    },
    {
      "Location ID": "17371",
      "Location Name": "YORK NEW SALEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 S MAIN ST YORK NEW SALEM PA 17371 USA "
    },
    {
      "Location ID": "17372",
      "Location Name": "YORK SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 203 MAIN ST YORK SPRINGS PA 17372 USA "
    },
    {
      "Location ID": "173BR",
      "Location Name": "ALPHABRODER - LEWISBERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 INDUSTRIAL DR LEWISBERRY PA 17339 USA "
    },
    {
      "Location ID": "173BS",
      "Location Name": "DIRECT BRANDS (HANOVER PA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 348 POPLAR ST HANOVER PA 17331 USA "
    },
    {
      "Location ID": "173SP",
      "Location Name": "SHERIDAN PRESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 450 FAME AVE HANOVER PA 17331 USA "
    },
    {
      "Location ID": "17402",
      "Location Name": "YORK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3435 CONCORD RD OFC YORK PA 17402 USA "
    },
    {
      "Location ID": "17404",
      "Location Name": "WEST YORK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2150 WHITE ST STE 8 YORK PA 17404 USA "
    },
    {
      "Location ID": "174OM",
      "Location Name": "OSM WORLDWIDE - YORK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 223 MORGAN LN STE A YORK PA 17406 USA "
    },
    {
      "Location ID": "174OS",
      "Location Name": "ONE STOP MAILING (YORK)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 BUTTERFLY COMMONS DR YORK PA 17402 USA "
    },
    {
      "Location ID": "174RR",
      "Location Name": "R.R. DONNELLY LOGISTICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 BUTTERFLY COMMONS DR YORK PA 17402 USA "
    },
    {
      "Location ID": "174YG",
      "Location Name": "THE YGS GROUP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3650 W MARKET ST YORK PA 17404 USA "
    },
    {
      "Location ID": "175",
      "Location Name": "LOG LANCASTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1400 HARRISBURG PIKE LANCASTER PA 17604 USA "
    },
    {
      "Location ID": "17501",
      "Location Name": "AKRON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 N 7TH ST AKRON PA 17501 USA "
    },
    {
      "Location ID": "17502",
      "Location Name": "BAINBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 N 2ND ST BAINBRIDGE PA 17502 USA "
    },
    {
      "Location ID": "17503",
      "Location Name": "BART",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1093 GEORGETOWN RD BART PA 17503 USA "
    },
    {
      "Location ID": "17504",
      "Location Name": "BAUSMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 ROSEDALE AVE BAUSMAN PA 17504 USA "
    },
    {
      "Location ID": "17505",
      "Location Name": "BIRD IN HAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2694 OLD PHILADELPHIA PIKE BIRD IN HAND PA 17505 USA "
    },
    {
      "Location ID": "17506",
      "Location Name": "BLUE BALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1058 MAIN ST BLUE BALL PA 17506 USA "
    },
    {
      "Location ID": "17508",
      "Location Name": "BROWNSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 CHARLES PL BROWNSTOWN PA 17508 USA "
    },
    {
      "Location ID": "17509",
      "Location Name": "CHRISTIANA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 N BRIDGE ST CHRISTIANA PA 17509 USA "
    },
    {
      "Location ID": "17512",
      "Location Name": "COLUMBIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 53 N 4TH ST COLUMBIA PA 17512 USA "
    },
    {
      "Location ID": "17516",
      "Location Name": "CONESTOGA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 RIVER CORNER RD CONESTOGA PA 17516 USA "
    },
    {
      "Location ID": "17517",
      "Location Name": "DENVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 SNYDER ST DENVER PA 17517 USA "
    },
    {
      "Location ID": "17519",
      "Location Name": "EAST EARL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1238 E EARL RD EAST EARL PA 17519 USA "
    },
    {
      "Location ID": "17522",
      "Location Name": "EPHRATA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 129 E MAIN ST EPHRATA PA 17522 USA "
    },
    {
      "Location ID": "17527",
      "Location Name": "GAP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 937 ROUTE 41 GAP PA 17527 USA "
    },
    {
      "Location ID": "17528",
      "Location Name": "GOODVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1599 MAIN ST GOODVILLE PA 17528 USA "
    },
    {
      "Location ID": "17529",
      "Location Name": "GORDONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3197 IRISHTOWN RD GORDONVILLE PA 17529 USA "
    },
    {
      "Location ID": "17532",
      "Location Name": "HOLTWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 DRYTOWN RD HOLTWOOD PA 17532 USA "
    },
    {
      "Location ID": "17533",
      "Location Name": "HOPELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 482 N CLAY ST HOPELAND PA 17533 USA "
    },
    {
      "Location ID": "17534",
      "Location Name": "INTERCOURSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14J CENTER ST INTERCOURSE PA 17534 USA "
    },
    {
      "Location ID": "17535",
      "Location Name": "KINZERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3583 LINCOLN HWY E KINZERS PA 17535 USA "
    },
    {
      "Location ID": "17536",
      "Location Name": "KIRKWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1713 KIRKWOOD PIKE KIRKWOOD PA 17536 USA "
    },
    {
      "Location ID": "17537",
      "Location Name": "LAMPETER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1638 LAMPETER RD LAMPETER PA 17537 USA "
    },
    {
      "Location ID": "17538",
      "Location Name": "LANDISVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 241 ELIZABETH ST LANDISVILLE PA 17538 USA "
    },
    {
      "Location ID": "17540",
      "Location Name": "LEOLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 E MAIN ST LEOLA PA 17540 USA "
    },
    {
      "Location ID": "17547",
      "Location Name": "MARIETTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 N NEW HAVEN ST MARIETTA PA 17547 USA "
    },
    {
      "Location ID": "17550",
      "Location Name": "MAYTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 W HIGH ST MAYTOWN PA 17550 USA "
    },
    {
      "Location ID": "17551",
      "Location Name": "MILLERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 431 N GEORGE ST MILLERSVILLE PA 17551 USA "
    },
    {
      "Location ID": "17552",
      "Location Name": "MOUNT JOY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 E MAIN ST MOUNT JOY PA 17552 USA "
    },
    {
      "Location ID": "17554",
      "Location Name": "MOUNTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 S BRIDGE ST MOUNTVILLE PA 17554 USA "
    },
    {
      "Location ID": "17555",
      "Location Name": "NARVON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1125 NARVON RD NARVON PA 17555 USA "
    },
    {
      "Location ID": "17557",
      "Location Name": "NEW HOLLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 DILLER AVE NEW HOLLAND PA 17557 USA "
    },
    {
      "Location ID": "17560",
      "Location Name": "NEW PROVIDENCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 69 MAIN ST NEW PROVIDENCE PA 17560 USA "
    },
    {
      "Location ID": "17562",
      "Location Name": "PARADISE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 LEACOCK RD PARADISE PA 17562 USA "
    },
    {
      "Location ID": "17563",
      "Location Name": "PEACH BOTTOM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1842A LANCASTER PIKE PEACH BOTTOM PA 17563 USA "
    },
    {
      "Location ID": "17565",
      "Location Name": "PEQUEA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 735 MARTICVILLE RD PEQUEA PA 17565 USA "
    },
    {
      "Location ID": "17566",
      "Location Name": "QUARRYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 W STATE ST QUARRYVILLE PA 17566 USA "
    },
    {
      "Location ID": "17567",
      "Location Name": "REAMSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 N REAMSTOWN RD REAMSTOWN PA 17567 USA "
    },
    {
      "Location ID": "17568",
      "Location Name": "REFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 45 REFTON RD REFTON PA 17568 USA "
    },
    {
      "Location ID": "17569",
      "Location Name": "REINHOLDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110B MECHANIC ST REINHOLDS PA 17569 USA "
    },
    {
      "Location ID": "17570",
      "Location Name": "RHEEMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 LIME ST RHEEMS PA 17570 USA "
    },
    {
      "Location ID": "17572",
      "Location Name": "RONKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 N RONKS RD RONKS PA 17572 USA "
    },
    {
      "Location ID": "17575",
      "Location Name": "SILVER SPRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3672 MARIETTA AVE SILVER SPRING PA 17575 USA "
    },
    {
      "Location ID": "17576",
      "Location Name": "SMOKETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2433 OLD PHILADELPHIA PIKE STE E SMOKETOWN PA 17576 USA "
    },
    {
      "Location ID": "17578",
      "Location Name": "STEVENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 S LINE RD STEVENS PA 17578 USA "
    },
    {
      "Location ID": "17579",
      "Location Name": "STRASBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 HISTORIC DR STRASBURG PA 17579 USA "
    },
    {
      "Location ID": "17580",
      "Location Name": "TALMAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 247 S STATE ST TALMAGE PA 17580 USA "
    },
    {
      "Location ID": "17582",
      "Location Name": "WASHINGTON BORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 ELIZABETH ST WASHINGTON BORO PA 17582 USA "
    },
    {
      "Location ID": "17584",
      "Location Name": "WILLOW STREET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 VIOLET AVE WILLOW STREET PA 17584 USA "
    },
    {
      "Location ID": "17585",
      "Location Name": "WITMER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 407 MOUNT SIDNEY RD WITMER PA 17585 USA "
    },
    {
      "Location ID": "175BC",
      "Location Name": "BUELAH'S CANDYLAND (EPHRATA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 330 N READING RD EPHRATA PA 17522 USA "
    },
    {
      "Location ID": "175BH",
      "Location Name": "BOLLMAN HAT (DENVER PA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 DENVER RD DENVER PA 17517 USA "
    },
    {
      "Location ID": "175BK",
      "Location Name": "BOOKSAVERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1054 S STATE ST EPHRATA PA 17522 USA "
    },
    {
      "Location ID": "175CA",
      "Location Name": "HARRISBURG PEAK ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3500 INDUSTRIAL RD STE 100 HARRISBURG PA 17110 USA "
    },
    {
      "Location ID": "175CG",
      "Location Name": "THE CELL GURU (EPHRATA PA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 NEW ST STE 1 EPHRATA PA 17522 USA "
    },
    {
      "Location ID": "175DW",
      "Location Name": "DOGWOOD FARM MARKET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1124 DOGWOOD DR REINHOLDS PA 17569 USA "
    },
    {
      "Location ID": "175EG",
      "Location Name": "ESPENSHADE'S GARDEN CENTERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 546 E 28TH DIVISION HWY LITITZ PA 17543 USA "
    },
    {
      "Location ID": "175ES",
      "Location Name": "EARTH'S SUPPLY SPECIALTIES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1268 READING RD DENVER PA 17517 USA "
    },
    {
      "Location ID": "175KK",
      "Location Name": "KITCHEN KETTLE VILLAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3529 OLD PHILADELPHIA PIKE INTERCOURSE PA 17534 USA "
    },
    {
      "Location ID": "175KO",
      "Location Name": "KINSEYS OUTDOOR (MOUNT JOY PA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1658 STEEL WAY MOUNT JOY PA 17552 USA "
    },
    {
      "Location ID": "175LC",
      "Location Name": "LITITZ CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 WYNFIELD DR LITITZ PA 17543 USA "
    },
    {
      "Location ID": "175LF",
      "Location Name": "LANCASTER FARMING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 E MAIN ST EPHRATA PA 17522 USA "
    },
    {
      "Location ID": "175LT",
      "Location Name": "LIQUID THREADS (EPHRATA PA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 330 N READING RD STE 4 EPHRATA PA 17522 USA "
    },
    {
      "Location ID": "175MA",
      "Location Name": "MORPHY AUCTIONS (DENVER PA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2000 N READING RD DENVER PA 17517 USA "
    },
    {
      "Location ID": "175MP",
      "Location Name": "MAPLE PRESS (MOUNT JOY PA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 STRICKLER RD MOUNT JOY PA 17552 USA "
    },
    {
      "Location ID": "175OC",
      "Location Name": "OLD CANDLE BARN (INTERCOURSE)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3551 OLD PHILADELPHIA PIKE INTERCOURSE PA 17534 USA "
    },
    {
      "Location ID": "175SB",
      "Location Name": "SHELLY B (EPHRATA PA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1438 W MAIN ST EPHRATA PA 17522 USA "
    },
    {
      "Location ID": "175ST",
      "Location Name": "SUSQUEHANNA FISHING TACKLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3992 LOCUST GROVE RD COLUMBIA PA 17512 USA "
    },
    {
      "Location ID": "175TO",
      "Location Name": "THE OLD COUNTRY STORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3510 OLD PHILADELPHIA PIKE INTERCOURSE PA 17534 USA "
    },
    {
      "Location ID": "175TS",
      "Location Name": "THE TRAILER SHOPPE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 171 NAPIERVILLE RD EPHRATA PA 17522 USA "
    },
    {
      "Location ID": "175TT",
      "Location Name": "TEN THOUSAND VILLAGES AKRON PA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 704 MAIN ST AKRON PA 17501 USA "
    },
    {
      "Location ID": "175UO",
      "Location Name": "URBAN OUTFITTERS (GAP PA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 766 BRACKBILL RD GAP PA 17527 USA "
    },
    {
      "Location ID": "175ZF",
      "Location Name": "ZOOKS FABRIC STORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3535 OLD PHILADELPHIA PIKE INTERCOURSE PA 17534 USA "
    },
    {
      "Location ID": "176CM",
      "Location Name": "LSC WEST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1375 HARRISBURG PIKE LANCASTER PA 17601 USA "
    },
    {
      "Location ID": "176GF",
      "Location Name": "LSC EAST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 216 GREENFIELD RD LANCASTER PA 17601 USA "
    },
    {
      "Location ID": "176LA",
      "Location Name": "LANCASTER ARCHERY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2195A OLD PHILADELPHIA PIKE LANCASTER PA 17602 USA "
    },
    {
      "Location ID": "177",
      "Location Name": "WILLIAMSPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2901 REACH RD WILLIAMSPORT PA 17701 USA "
    },
    {
      "Location ID": "17705",
      "Location Name": "WILLIAMSPORT (BRO-DART)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 ARCH ST WILLIAMSPORT PA 17705 USA "
    },
    {
      "Location ID": "17720",
      "Location Name": "ANTES FORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 THIRD ST ANTES FORT PA 17720 USA "
    },
    {
      "Location ID": "17721",
      "Location Name": "AVIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 W CENTRAL AVE AVIS PA 17721 USA "
    },
    {
      "Location ID": "17724",
      "Location Name": "CANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 TROY ST CANTON PA 17724 USA "
    },
    {
      "Location ID": "17726",
      "Location Name": "CASTANEA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 LOGAN AVE CASTANEA PA 17726 USA "
    },
    {
      "Location ID": "17728",
      "Location Name": "COGAN STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4772 LYCOMING CREEK RD COGAN STATION PA 17728 USA "
    },
    {
      "Location ID": "17730",
      "Location Name": "DEWART",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2037 MAIN ST DEWART PA 17730 USA "
    },
    {
      "Location ID": "17731",
      "Location Name": "EAGLES MERE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 SULLIVAN AVE EAGLES MERE PA 17731 USA "
    },
    {
      "Location ID": "17735",
      "Location Name": "GROVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44 N WATER ST GROVER PA 17735 USA "
    },
    {
      "Location ID": "17737",
      "Location Name": "HUGHESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44 N MAIN ST HUGHESVILLE PA 17737 USA "
    },
    {
      "Location ID": "17740",
      "Location Name": "JERSEY SHORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 234 ALLEGHENY ST JERSEY SHORE PA 17740 USA "
    },
    {
      "Location ID": "17744",
      "Location Name": "LINDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 224 FRONT ST LINDEN PA 17744 USA "
    },
    {
      "Location ID": "17745",
      "Location Name": "LOCK HAVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 W MAIN ST LOCK HAVEN PA 17745 USA "
    },
    {
      "Location ID": "17747",
      "Location Name": "LOGANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 83 W MAIN ST LOGANTON PA 17747 USA "
    },
    {
      "Location ID": "17748",
      "Location Name": "MC ELHATTAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 529 MC ELHATTAN DR MC ELHATTAN PA 17748 USA "
    },
    {
      "Location ID": "17749",
      "Location Name": "MC EWENSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 POTASH ST MC EWENSVILLE PA 17749 USA "
    },
    {
      "Location ID": "17750",
      "Location Name": "MACKEYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 191 MACKEYVILLE RD MACKEYVILLE PA 17750 USA "
    },
    {
      "Location ID": "17751",
      "Location Name": "MILL HALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 PENNSYLVANIA AVE MILL HALL PA 17751 USA "
    },
    {
      "Location ID": "17752",
      "Location Name": "MONTGOMERY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 HENRY HAND DR MONTGOMERY PA 17752 USA "
    },
    {
      "Location ID": "17754",
      "Location Name": "MONTOURSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 335 BROAD ST MONTOURSVILLE PA 17754 USA "
    },
    {
      "Location ID": "17756",
      "Location Name": "MUNCY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 S MAIN ST MUNCY PA 17756 USA "
    },
    {
      "Location ID": "17758",
      "Location Name": "MUNCY VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 BENDERS RD MUNCY VALLEY PA 17758 USA "
    },
    {
      "Location ID": "17762",
      "Location Name": "PICTURE ROCKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 LAUREL RUN RD PICTURE ROCKS PA 17762 USA "
    },
    {
      "Location ID": "17763",
      "Location Name": "RALSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10975 ROUTE 14 RALSTON PA 17763 USA "
    },
    {
      "Location ID": "17764",
      "Location Name": "RENOVO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 365 HURON AVE RENOVO PA 17764 USA "
    },
    {
      "Location ID": "17765",
      "Location Name": "ROARING BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 986 ROUTE 14 ROARING BRANCH PA 17765 USA "
    },
    {
      "Location ID": "17771",
      "Location Name": "TROUT RUN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 67 MAIN ST TROUT RUN PA 17771 USA "
    },
    {
      "Location ID": "17772",
      "Location Name": "TURBOTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4745 STATE ROUTE 54 TURBOTVILLE PA 17772 USA "
    },
    {
      "Location ID": "17777",
      "Location Name": "WATSONTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 316 MAIN ST WATSONTOWN PA 17777 USA "
    },
    {
      "Location ID": "17779",
      "Location Name": "WOOLRICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1039 PARK AVE WOOLRICH PA 17779 USA "
    },
    {
      "Location ID": "177FQ",
      "Location Name": "FIRST QUALITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 NORTH RD LOCK HAVEN PA 17745 USA "
    },
    {
      "Location ID": "177GP",
      "Location Name": "WILLIAMSPORT (WEBB PLANT)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 MAYNARD ST WILLIAMSPORT PA 17701 USA "
    },
    {
      "Location ID": "177LC",
      "Location Name": "LOOTCRATE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 820 E BALD EAGLE ST LOCK HAVEN PA 17745 USA "
    },
    {
      "Location ID": "177WM",
      "Location Name": "WOOLRICH MFG CO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 SHAFFER LN JERSEY SHORE PA 17740 USA "
    },
    {
      "Location ID": "178",
      "Location Name": "SUNBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 135 N 3RD ST SUNBURY PA 17801 USA "
    },
    {
      "Location ID": "17810",
      "Location Name": "ALLENWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16945 US ROUTE 15 ALLENWOOD PA 17810 USA "
    },
    {
      "Location ID": "17812",
      "Location Name": "BEAVER SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 85 PINE ST BEAVER SPRINGS PA 17812 USA "
    },
    {
      "Location ID": "17813",
      "Location Name": "BEAVERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 212 S SASSAFRAS ST BEAVERTOWN PA 17813 USA "
    },
    {
      "Location ID": "17814",
      "Location Name": "BENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 THIRD ST BENTON PA 17814 USA "
    },
    {
      "Location ID": "17815",
      "Location Name": "BLOOMSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 230 MARKET ST BLOOMSBURG PA 17815 USA "
    },
    {
      "Location ID": "17820",
      "Location Name": "CATAWISSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 N 3RD ST CATAWISSA PA 17820 USA "
    },
    {
      "Location ID": "17821",
      "Location Name": "DANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 410 MILL ST DANVILLE PA 17821 USA "
    },
    {
      "Location ID": "17824",
      "Location Name": "ELYSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 E ROOSEVELT AVE ELYSBURG PA 17824 USA "
    },
    {
      "Location ID": "17827",
      "Location Name": "FREEBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 E MARKET ST FREEBURG PA 17827 USA "
    },
    {
      "Location ID": "17829",
      "Location Name": "HARTLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 COLD RUN RD HARTLETON PA 17829 USA "
    },
    {
      "Location ID": "17830",
      "Location Name": "HERNDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 127 POTTSVILLE ST HERNDON PA 17830 USA "
    },
    {
      "Location ID": "17831",
      "Location Name": "HUMMELS WHARF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1245 N SUSQUEHANNA TRL HUMMELS WHARF PA 17831 USA "
    },
    {
      "Location ID": "17833",
      "Location Name": "KREAMER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 515 KREAMER AVE KREAMER PA 17833 USA "
    },
    {
      "Location ID": "17834",
      "Location Name": "KULPMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 901 CHESTNUT ST FRNT KULPMONT PA 17834 USA "
    },
    {
      "Location ID": "17835",
      "Location Name": "LAURELTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2495 LAUREL RD LAURELTON PA 17835 USA "
    },
    {
      "Location ID": "17837",
      "Location Name": "LEWISBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 MARKET ST LEWISBURG PA 17837 USA "
    },
    {
      "Location ID": "17841",
      "Location Name": "MC CLURE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 BROWN ST MC CLURE PA 17841 USA "
    },
    {
      "Location ID": "17842",
      "Location Name": "MIDDLEBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 N MAIN ST MIDDLEBURG PA 17842 USA "
    },
    {
      "Location ID": "17844",
      "Location Name": "MIFFLINBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 N 4TH ST MIFFLINBURG PA 17844 USA "
    },
    {
      "Location ID": "17845",
      "Location Name": "MILLMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1295 MILLMONT RD MILLMONT PA 17845 USA "
    },
    {
      "Location ID": "17846",
      "Location Name": "MILLVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 E MAIN ST MILLVILLE PA 17846 USA "
    },
    {
      "Location ID": "17847",
      "Location Name": "MILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 N FRONT ST MILTON PA 17847 USA "
    },
    {
      "Location ID": "17850",
      "Location Name": "MONTANDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 97 MAIN ST MONTANDON PA 17850 USA "
    },
    {
      "Location ID": "17851",
      "Location Name": "MOUNT CARMEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 S HICKORY ST MOUNT CARMEL PA 17851 USA "
    },
    {
      "Location ID": "17853",
      "Location Name": "MOUNT PLEASANT MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9116 ROUTE 35 MOUNT PLEASANT MILLS PA 17853 USA "
    },
    {
      "Location ID": "17855",
      "Location Name": "NEW BERLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 VINE ST NEW BERLIN PA 17855 USA "
    },
    {
      "Location ID": "17857",
      "Location Name": "NORTHUMBERLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 75 QUEEN ST NORTHUMBERLAND PA 17857 USA "
    },
    {
      "Location ID": "17858",
      "Location Name": "NUMIDIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1114 NUMIDIA DR NUMIDIA PA 17858 USA "
    },
    {
      "Location ID": "17859",
      "Location Name": "ORANGEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 410 MILL ST ORANGEVILLE PA 17859 USA "
    },
    {
      "Location ID": "17860",
      "Location Name": "PAXINOS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5317 STATE ROUTE 61 PAXINOS PA 17860 USA "
    },
    {
      "Location ID": "17861",
      "Location Name": "PAXTONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1072 PAXTON ST STE 10 PAXTONVILLE PA 17861 USA "
    },
    {
      "Location ID": "17862",
      "Location Name": "PENNS CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 RASPBERRY ST PENNS CREEK PA 17862 USA "
    },
    {
      "Location ID": "17864",
      "Location Name": "PORT TREVORTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3934 S SUSQUEHANNA TRL STE 10 PORT TREVORTON PA 17864 USA "
    },
    {
      "Location ID": "17865",
      "Location Name": "POTTS GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4365 STATE ROUTE 642 POTTS GROVE PA 17865 USA "
    },
    {
      "Location ID": "17868",
      "Location Name": "RIVERSIDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 452 DEWART ST RIVERSIDE PA 17868 USA "
    },
    {
      "Location ID": "17870",
      "Location Name": "SELINSGROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 S MARKET ST SELINSGROVE PA 17870 USA "
    },
    {
      "Location ID": "17872",
      "Location Name": "SHAMOKIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 E INDEPENDENCE ST SHAMOKIN PA 17872 USA "
    },
    {
      "Location ID": "17876",
      "Location Name": "SHAMOKIN DAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3054 N SUSQUEHANNA TRL SHAMOKIN DAM PA 17876 USA "
    },
    {
      "Location ID": "17881",
      "Location Name": "TREVORTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 801 W SHAMOKIN ST LOWR TREVORTON PA 17881 USA "
    },
    {
      "Location ID": "17882",
      "Location Name": "TROXELVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8891 ROUTE 235 TROXELVILLE PA 17882 USA "
    },
    {
      "Location ID": "17884",
      "Location Name": "WASHINGTONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1229 WATER ST WASHINGTONVILLE PA 17884 USA "
    },
    {
      "Location ID": "17885",
      "Location Name": "WEIKERT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5540 WEIKERT RD WEIKERT PA 17885 USA "
    },
    {
      "Location ID": "17886",
      "Location Name": "WEST MILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 505 BROAD ST WEST MILTON PA 17886 USA "
    },
    {
      "Location ID": "17887",
      "Location Name": "WHITE DEER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 601 WHITE DEER PIKE WHITE DEER PA 17887 USA "
    },
    {
      "Location ID": "17889",
      "Location Name": "WINFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 477 PARK RD WINFIELD PA 17889 USA "
    },
    {
      "Location ID": "178AX",
      "Location Name": "LEWISBURG CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2013 W MARKET ST LEWISBURG PA 17837 USA "
    },
    {
      "Location ID": "178DJ",
      "Location Name": "THOMPSON MAILING CORP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 NAUS WAY BLOOMSBURG PA 17815 USA "
    },
    {
      "Location ID": "178GH",
      "Location Name": "GHS - CLAM (ELYSBURG)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 INDUSTRIAL PARK RD ELYSBURG PA 17824 USA "
    },
    {
      "Location ID": "178SP",
      "Location Name": "SPICES INC (ELYSBURG)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 465 INDUSTRIAL PARK RD ELYSBURG PA 17824 USA "
    },
    {
      "Location ID": "179",
      "Location Name": "POTTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 450 N CENTER ST POTTSVILLE PA 17901 USA "
    },
    {
      "Location ID": "17920",
      "Location Name": "ARISTES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90 S LEIBY ST ARISTES PA 17920 USA "
    },
    {
      "Location ID": "17921",
      "Location Name": "ASHLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 901 RACE ST ASHLAND PA 17921 USA "
    },
    {
      "Location ID": "17922",
      "Location Name": "AUBURN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 425 MARKET ST AUBURN PA 17922 USA "
    },
    {
      "Location ID": "17923",
      "Location Name": "BRANCHDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 S STATE RD BRANCHDALE PA 17923 USA "
    },
    {
      "Location ID": "17929",
      "Location Name": "CRESSONA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 N SILLYMAN ST CRESSONA PA 17929 USA "
    },
    {
      "Location ID": "17930",
      "Location Name": "CUMBOLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 COAL ST CUMBOLA PA 17930 USA "
    },
    {
      "Location ID": "17931",
      "Location Name": "FRACKVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 S LEHIGH AVE FRACKVILLE PA 17931 USA "
    },
    {
      "Location ID": "17933",
      "Location Name": "FRIEDENSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1305 LONG RUN RD FRIEDENSBURG PA 17933 USA "
    },
    {
      "Location ID": "17935",
      "Location Name": "GIRARDVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 W MAIN ST GIRARDVILLE PA 17935 USA "
    },
    {
      "Location ID": "17936",
      "Location Name": "GORDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 E BIDDLE ST GORDON PA 17936 USA "
    },
    {
      "Location ID": "17938",
      "Location Name": "HEGINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 POST OFFICE RD HEGINS PA 17938 USA "
    },
    {
      "Location ID": "17943",
      "Location Name": "LAVELLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 337 MAIN ST LAVELLE PA 17943 USA "
    },
    {
      "Location ID": "17944",
      "Location Name": "LLEWELLYN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 SILVERTON RD LLEWELLYN PA 17944 USA "
    },
    {
      "Location ID": "17945",
      "Location Name": "LOCUSTDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3115 MAIN ST LOCUSTDALE PA 17945 USA "
    },
    {
      "Location ID": "17946",
      "Location Name": "LOST CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 43 CONVENT RD LOST CREEK PA 17946 USA "
    },
    {
      "Location ID": "17948",
      "Location Name": "MAHANOY CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 E PINE ST MAHANOY CITY PA 17948 USA "
    },
    {
      "Location ID": "17951",
      "Location Name": "MAR LIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 BEECHWOOD AVE MAR LIN PA 17951 USA "
    },
    {
      "Location ID": "17952",
      "Location Name": "MARY D",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 MAIN ST MARY D PA 17952 USA "
    },
    {
      "Location ID": "17953",
      "Location Name": "MIDDLEPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 32 MAIN ST MIDDLEPORT PA 17953 USA "
    },
    {
      "Location ID": "17954",
      "Location Name": "MINERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 407 SUNBURY ST MINERSVILLE PA 17954 USA "
    },
    {
      "Location ID": "17957",
      "Location Name": "MUIR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 212 W WICONISCO ST OFC MUIR PA 17957 USA "
    },
    {
      "Location ID": "17959",
      "Location Name": "NEW PHILADELPHIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 VALLEY ST NEW PHILADELPHIA PA 17959 USA "
    },
    {
      "Location ID": "17960",
      "Location Name": "NEW RINGGOLD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 N RAILROAD ST NEW RINGGOLD PA 17960 USA "
    },
    {
      "Location ID": "17961",
      "Location Name": "ORWIGSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 700 W MARKET ST ORWIGSBURG PA 17961 USA "
    },
    {
      "Location ID": "17963",
      "Location Name": "PINE GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 SNYDER AVE PINE GROVE PA 17963 USA "
    },
    {
      "Location ID": "17964",
      "Location Name": "PITMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 175 MAIN RD PITMAN PA 17964 USA "
    },
    {
      "Location ID": "17965",
      "Location Name": "PORT CARBON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 73 WASHINGTON ST PORT CARBON PA 17965 USA "
    },
    {
      "Location ID": "17967",
      "Location Name": "RINGTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 S MARKET ST RINGTOWN PA 17967 USA "
    },
    {
      "Location ID": "17968",
      "Location Name": "SACRAMENTO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2263 E MAIN ST SACRAMENTO PA 17968 USA "
    },
    {
      "Location ID": "17970",
      "Location Name": "SAINT CLAIR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 W CARROLL ST SAINT CLAIR PA 17970 USA "
    },
    {
      "Location ID": "17972",
      "Location Name": "SCHUYLKILL HAVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 W MAIN ST SCHUYLKILL HAVEN PA 17972 USA "
    },
    {
      "Location ID": "17974",
      "Location Name": "SELTZER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 541 SELTZER RD POTTSVILLE PA 17901 USA "
    },
    {
      "Location ID": "17976",
      "Location Name": "SHENANDOAH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 N WHITE ST SHENANDOAH PA 17976 USA "
    },
    {
      "Location ID": "17978",
      "Location Name": "SPRING GLEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2636 W MAIN ST SPRING GLEN PA 17978 USA "
    },
    {
      "Location ID": "17979",
      "Location Name": "SUMMIT STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 SPRING RD SUMMIT STATION PA 17979 USA "
    },
    {
      "Location ID": "17980",
      "Location Name": "TOWER CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 544 E GRAND AVE TOWER CITY PA 17980 USA "
    },
    {
      "Location ID": "17981",
      "Location Name": "TREMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 W MAIN ST TREMONT PA 17981 USA "
    },
    {
      "Location ID": "17982",
      "Location Name": "TUSCARORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 505 STRAWBERRY ST TUSCARORA PA 17982 USA "
    },
    {
      "Location ID": "17983",
      "Location Name": "VALLEY VIEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1124 W MAIN ST VALLEY VIEW PA 17983 USA "
    },
    {
      "Location ID": "17985",
      "Location Name": "ZION GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 731 RINGTOWN RD ZION GROVE PA 17985 USA "
    },
    {
      "Location ID": "17H",
      "Location Name": "HARRISBURG PA RDC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1425 CROOKED HILL RD HARRISBURG PA 17107 USA "
    },
    {
      "Location ID": "180",
      "Location Name": "LOG LEHIGH VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 S COMMERCE WAY BETHLEHEM PA 18017 USA "
    },
    {
      "Location ID": "18011",
      "Location Name": "ALBURTIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 302 S MAIN ST ALBURTIS PA 18011 USA "
    },
    {
      "Location ID": "18012",
      "Location Name": "AQUASHICOLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 LITTLE GAP RD AQUASHICOLA PA 18012 USA "
    },
    {
      "Location ID": "18013",
      "Location Name": "BANGOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 212 S 1ST ST BANGOR PA 18013 USA "
    },
    {
      "Location ID": "18014",
      "Location Name": "BATH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 175 N CHESTNUT ST BATH PA 18014 USA "
    },
    {
      "Location ID": "18030",
      "Location Name": "BOWMANSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 RAILROAD ST BOWMANSTOWN PA 18030 USA "
    },
    {
      "Location ID": "18031",
      "Location Name": "BREINIGSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8922 BREINIGSVILLE RD BREINIGSVILLE PA 18031 USA "
    },
    {
      "Location ID": "18032",
      "Location Name": "CATASAUQUA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 BRIDGE ST CATASAUQUA PA 18032 USA "
    },
    {
      "Location ID": "18034",
      "Location Name": "CENTER VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5800 ROUTE 378 CENTER VALLEY PA 18034 USA "
    },
    {
      "Location ID": "18035",
      "Location Name": "CHERRYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 680 BLUE MOUNTAIN DR CHERRYVILLE PA 18035 USA "
    },
    {
      "Location ID": "18036",
      "Location Name": "COOPERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 E STATION AVE COOPERSBURG PA 18036 USA "
    },
    {
      "Location ID": "18037",
      "Location Name": "COPLAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 918 CHESTNUT ST COPLAY PA 18037 USA "
    },
    {
      "Location ID": "18038",
      "Location Name": "DANIELSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3937 MOUNTAIN VIEW DR DANIELSVILLE PA 18038 USA "
    },
    {
      "Location ID": "18039",
      "Location Name": "DURHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 955 DURHAM RD DURHAM PA 18039 USA "
    },
    {
      "Location ID": "18041",
      "Location Name": "EAST GREENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 357 MAIN ST OFC EAST GREENVILLE PA 18041 USA "
    },
    {
      "Location ID": "18046",
      "Location Name": "EAST TEXAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5637 E TEXAS RD EAST TEXAS PA 18046 USA "
    },
    {
      "Location ID": "18049",
      "Location Name": "EMMAUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 N 5TH ST EMMAUS PA 18049 USA "
    },
    {
      "Location ID": "18051",
      "Location Name": "FOGELSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7918 MAIN ST OFC FOGELSVILLE PA 18051 USA "
    },
    {
      "Location ID": "18052",
      "Location Name": "WHITEHALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1105 SCHADT AVE WHITEHALL PA 18052 USA "
    },
    {
      "Location ID": "18053",
      "Location Name": "GERMANSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6066 MEMORIAL RD GERMANSVILLE PA 18053 USA "
    },
    {
      "Location ID": "18054",
      "Location Name": "GREEN LANE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 GRAVEL PIKE GREEN LANE PA 18054 USA "
    },
    {
      "Location ID": "18055",
      "Location Name": "HELLERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 660 DELAWARE AVE HELLERTOWN PA 18055 USA "
    },
    {
      "Location ID": "18056",
      "Location Name": "HEREFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1153 GRAVEL PIKE STE 1 HEREFORD PA 18056 USA "
    },
    {
      "Location ID": "18058",
      "Location Name": "KUNKLETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 490 KUNKLETOWN RD KUNKLETOWN PA 18058 USA "
    },
    {
      "Location ID": "18059",
      "Location Name": "LAURYS STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1072 RISING SUN RD LAURYS STATION PA 18059 USA "
    },
    {
      "Location ID": "18060",
      "Location Name": "LIMEPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1540 BLUE CHURCH RD LIMEPORT PA 18060 USA "
    },
    {
      "Location ID": "18062",
      "Location Name": "MACUNGIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 51 W END TRL MACUNGIE PA 18062 USA "
    },
    {
      "Location ID": "18063",
      "Location Name": "MARTINS CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6564 S DELAWARE DR MARTINS CREEK PA 18063 USA "
    },
    {
      "Location ID": "18064",
      "Location Name": "NAZARETH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 N MAIN ST NAZARETH PA 18064 USA "
    },
    {
      "Location ID": "18065",
      "Location Name": "NEFFS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5613 ROUTE 873 NEFFS PA 18065 USA "
    },
    {
      "Location ID": "18066",
      "Location Name": "NEW TRIPOLI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6736 MADISON ST NEW TRIPOLI PA 18066 USA "
    },
    {
      "Location ID": "18067",
      "Location Name": "NORTHAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1701 WASHINGTON AVE NORTHAMPTON PA 18067 USA "
    },
    {
      "Location ID": "18068",
      "Location Name": "OLD ZIONSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5831 KINGS HWY S OLD ZIONSVILLE PA 18068 USA "
    },
    {
      "Location ID": "18069",
      "Location Name": "OREFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4711 KERNSVILLE RD OREFIELD PA 18069 USA "
    },
    {
      "Location ID": "18070",
      "Location Name": "PALM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 801 GRAVEL PIKE PALM PA 18070 USA "
    },
    {
      "Location ID": "18071",
      "Location Name": "PALMERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 128 DELAWARE AVE FRNT PALMERTON PA 18071 USA "
    },
    {
      "Location ID": "18072",
      "Location Name": "PEN ARGYL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 S ROBINSON AVE PEN ARGYL PA 18072 USA "
    },
    {
      "Location ID": "18074",
      "Location Name": "PERKIOMENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1463 GRAVEL PIKE PERKIOMENVILLE PA 18074 USA "
    },
    {
      "Location ID": "18076",
      "Location Name": "RED HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 427 MAIN ST OFC RED HILL PA 18076 USA "
    },
    {
      "Location ID": "18077",
      "Location Name": "RIEGELSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 DELAWARE RD RIEGELSVILLE PA 18077 USA "
    },
    {
      "Location ID": "18078",
      "Location Name": "SCHNECKSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4709 ROUTE 309 SCHNECKSVILLE PA 18078 USA "
    },
    {
      "Location ID": "18079",
      "Location Name": "SLATEDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4016 MAIN ST SLATEDALE PA 18079 USA "
    },
    {
      "Location ID": "18080",
      "Location Name": "SLATINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 605 MAIN ST SLATINGTON PA 18080 USA "
    },
    {
      "Location ID": "18081",
      "Location Name": "SPRINGTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3147 ROUTE 212 SPRINGTOWN PA 18081 USA "
    },
    {
      "Location ID": "18083",
      "Location Name": "STOCKERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 MAIN ST STOCKERTOWN PA 18083 USA "
    },
    {
      "Location ID": "18084",
      "Location Name": "SUMNEYTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3196 MAIN ST SUMNEYTOWN PA 18084 USA "
    },
    {
      "Location ID": "18085",
      "Location Name": "TATAMY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 423 BROAD ST TATAMY PA 18085 USA "
    },
    {
      "Location ID": "18086",
      "Location Name": "TREICHLERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 331 E BREADFRUIT DR TREICHLERS PA 18086 USA "
    },
    {
      "Location ID": "18087",
      "Location Name": "TREXLERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7150 HAMILTON BLVD OFC TREXLERTOWN PA 18087 USA "
    },
    {
      "Location ID": "18088",
      "Location Name": "WALNUTPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 249 LEHIGH GAP ST WALNUTPORT PA 18088 USA "
    },
    {
      "Location ID": "18091",
      "Location Name": "WIND GAP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 138 N BROADWAY WIND GAP PA 18091 USA "
    },
    {
      "Location ID": "180CA",
      "Location Name": "LEHIGH VALLEY CMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2685 OPUS WAY BETHLEHEM PA 18020 USA "
    },
    {
      "Location ID": "180FZ",
      "Location Name": "LOG LEHIGH VALLEY PA PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 RADAR DR NORTHAMPTON PA 18067 USA "
    },
    {
      "Location ID": "180NA",
      "Location Name": "NAZARETH CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 859 NAZARETH PIKE UNIT A1 NAZARETH PA 18064 USA "
    },
    {
      "Location ID": "180SP",
      "Location Name": "180SP FEDEX SMARTPOST (BREINIGSVLLE)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8451 WILLARD DR SUITE 200 BREINIGSVILLE PA 18031 USA "
    },
    {
      "Location ID": "180ST",
      "Location Name": "STITCH FIX (BETHLEHEM)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4770 HANOVERVILLE RD STE 100 BETHLEHEM PA 18020 USA "
    },
    {
      "Location ID": "182",
      "Location Name": "HAZLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 S MAIN ST WILKES BARRE PA 18201 USA "
    },
    {
      "Location ID": "18201",
      "Location Name": "HAZLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 231 N WYOMING ST HAZLETON PA 18201 USA "
    },
    {
      "Location ID": "18210",
      "Location Name": "ALBRIGHTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 PINE POINT DR STE 4 ALBRIGHTSVILLE PA 18210 USA "
    },
    {
      "Location ID": "18211",
      "Location Name": "ANDREAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 820 LIZARD CREEK RD ANDREAS PA 18211 USA "
    },
    {
      "Location ID": "18212",
      "Location Name": "ASHFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 403 BLUE MOUNTAIN RD ASHFIELD PA 18212 USA "
    },
    {
      "Location ID": "18214",
      "Location Name": "BARNESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 GRIER AVE OFC BARNESVILLE PA 18214 USA "
    },
    {
      "Location ID": "18216",
      "Location Name": "BEAVER MEADOWS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 BROAD ST BEAVER MEADOWS PA 18216 USA "
    },
    {
      "Location ID": "18218",
      "Location Name": "COALDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 145 3RD ST COALDALE PA 18218 USA "
    },
    {
      "Location ID": "18219",
      "Location Name": "CONYNGHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 366 MAIN ST CONYNGHAM PA 18219 USA "
    },
    {
      "Location ID": "18220",
      "Location Name": "DELANO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 HAZLE ST DELANO PA 18220 USA "
    },
    {
      "Location ID": "18221",
      "Location Name": "DRIFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2304 STATE ROUTE 940 DRIFTON PA 18221 USA "
    },
    {
      "Location ID": "18222",
      "Location Name": "DRUMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 RITTENHOUSE PL DRUMS PA 18222 USA "
    },
    {
      "Location ID": "18223",
      "Location Name": "EBERVALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 OAKMONT DR EBERVALE PA 18223 USA "
    },
    {
      "Location ID": "18224",
      "Location Name": "FREELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 701 MAIN ST FREELAND PA 18224 USA "
    },
    {
      "Location ID": "18225",
      "Location Name": "HARLEIGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1129 STATE ROUTE 940 STE 1 HARLEIGH PA 18225 USA "
    },
    {
      "Location ID": "18229",
      "Location Name": "JIM THORPE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 LEHIGH AVE JIM THORPE PA 18229 USA "
    },
    {
      "Location ID": "18230",
      "Location Name": "JUNEDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 E MAIN ST JUNEDALE PA 18230 USA "
    },
    {
      "Location ID": "18231",
      "Location Name": "KELAYRES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 4TH ST KELAYRES PA 18231 USA "
    },
    {
      "Location ID": "18232",
      "Location Name": "LANSFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 TUNNEL ST LANSFORD PA 18232 USA "
    },
    {
      "Location ID": "18234",
      "Location Name": "LATTIMER MINES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 850 LATTIMER RD LATTIMER MINES PA 18234 USA "
    },
    {
      "Location ID": "18235",
      "Location Name": "LEHIGHTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 NORTH ST LEHIGHTON PA 18235 USA "
    },
    {
      "Location ID": "18237",
      "Location Name": "MCADOO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 126 W BLAINE ST MCADOO PA 18237 USA "
    },
    {
      "Location ID": "18239",
      "Location Name": "MILNESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1620 N CHURCH ST MILNESVILLE PA 18239 USA "
    },
    {
      "Location ID": "18240",
      "Location Name": "NESQUEHONING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 W CATAWISSA ST NESQUEHONING PA 18240 USA "
    },
    {
      "Location ID": "18241",
      "Location Name": "NUREMBERG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 MAHANOY ST NUREMBERG PA 18241 USA "
    },
    {
      "Location ID": "18242",
      "Location Name": "ONEIDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 172 FIRST ST ONEIDA PA 18242 USA "
    },
    {
      "Location ID": "18244",
      "Location Name": "PARRYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 177 MAIN ST PARRYVILLE PA 18244 USA "
    },
    {
      "Location ID": "18245",
      "Location Name": "QUAKAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 32 HECKMAN ST STE 1 QUAKAKE PA 18245 USA "
    },
    {
      "Location ID": "18246",
      "Location Name": "ROCK GLEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1289 MOUNTAIN ST ROCK GLEN PA 18246 USA "
    },
    {
      "Location ID": "18247",
      "Location Name": "SAINT JOHNS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 784 SAINT JOHNS RD SAINT JOHNS PA 18247 USA "
    },
    {
      "Location ID": "18248",
      "Location Name": "SHEPPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 928 CENTER ST SHEPPTON PA 18248 USA "
    },
    {
      "Location ID": "18249",
      "Location Name": "SUGARLOAF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 627 TOMHICKEN RD SUGARLOAF PA 18249 USA "
    },
    {
      "Location ID": "18250",
      "Location Name": "SUMMIT HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 N MARKET ST SUMMIT HILL PA 18250 USA "
    },
    {
      "Location ID": "18251",
      "Location Name": "SYBERTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41 N MAIN ST SYBERTSVILLE PA 18251 USA "
    },
    {
      "Location ID": "18252",
      "Location Name": "TAMAQUA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 399 E BROAD ST TAMAQUA PA 18252 USA "
    },
    {
      "Location ID": "18255",
      "Location Name": "WEATHERLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 CARBON ST WEATHERLY PA 18255 USA "
    },
    {
      "Location ID": "18256",
      "Location Name": "WESTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 592 HAZLE ST STE 1 WESTON PA 18256 USA "
    },
    {
      "Location ID": "182AE",
      "Location Name": "AMERICAN EAGLE OUTFITTERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 OAK RIDGE RD HAZLE TOWNSHP PA 18202 USA "
    },
    {
      "Location ID": "18301",
      "Location Name": "EAST STROUDSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 S COURTLAND ST EAST STROUDSBURG PA 18301 USA "
    },
    {
      "Location ID": "18320",
      "Location Name": "ANALOMINK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1635 CHERRY LANE RD ANALOMINK PA 18320 USA "
    },
    {
      "Location ID": "18321",
      "Location Name": "BARTONSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3400 ROUTE 611 STE 102 BARTONSVILLE PA 18321 USA "
    },
    {
      "Location ID": "18322",
      "Location Name": "BRODHEADSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 239 RODENBACH LN BRODHEADSVILLE PA 18322 USA "
    },
    {
      "Location ID": "18323",
      "Location Name": "BUCK HILL FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 270 GOLF DR BUCK HILL FALLS PA 18323 USA "
    },
    {
      "Location ID": "18324",
      "Location Name": "BUSHKILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5527 BUSHKILL FALLS RD BUSHKILL PA 18324 USA "
    },
    {
      "Location ID": "18325",
      "Location Name": "CANADENSIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4792 ROUTE 447 CANADENSIS PA 18325 USA "
    },
    {
      "Location ID": "18326",
      "Location Name": "CRESCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6535 ROUTE 191 CRESCO PA 18326 USA "
    },
    {
      "Location ID": "18327",
      "Location Name": "DELAWARE WATER GAP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 SHEPARD AVE DELAWARE WATER GAP PA 18327 USA "
    },
    {
      "Location ID": "18328",
      "Location Name": "DINGMANS FERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 NICHECRONK RD DINGMANS FERRY PA 18328 USA "
    },
    {
      "Location ID": "18330",
      "Location Name": "EFFORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 985 GILBERT RD EFFORT PA 18330 USA "
    },
    {
      "Location ID": "18331",
      "Location Name": "GILBERT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 622 GILBERT RD GILBERT PA 18331 USA "
    },
    {
      "Location ID": "18332",
      "Location Name": "HENRYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5285 PARADISE VALLEY RD HENRYVILLE PA 18332 USA "
    },
    {
      "Location ID": "18333",
      "Location Name": "KRESGEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 543 INTERCHANGE RD STE 103 KRESGEVILLE PA 18333 USA "
    },
    {
      "Location ID": "18334",
      "Location Name": "LONG POND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1557 LONG POND RD LONG POND PA 18334 USA "
    },
    {
      "Location ID": "18335",
      "Location Name": "MARSHALLS CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 315 DARTMOUTH DR MARSHALLS CREEK PA 18335 USA "
    },
    {
      "Location ID": "18336",
      "Location Name": "MATAMORAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 PENNSYLVANIA AVE MATAMORAS PA 18336 USA "
    },
    {
      "Location ID": "18337",
      "Location Name": "MILFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 W HARFORD ST MILFORD PA 18337 USA "
    },
    {
      "Location ID": "18340",
      "Location Name": "MILLRIFT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 BLUESTONE BLVD MILLRIFT PA 18340 USA "
    },
    {
      "Location ID": "18342",
      "Location Name": "MOUNTAINHOME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1099 ROUTE 390 STE 102 MOUNTAINHOME PA 18342 USA "
    },
    {
      "Location ID": "18343",
      "Location Name": "MOUNT BETHEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 MOUNT BETHEL PLZ MOUNT BETHEL PA 18343 USA "
    },
    {
      "Location ID": "18344",
      "Location Name": "MOUNT POCONO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 PINE HILL RD MOUNT POCONO PA 18344 USA "
    },
    {
      "Location ID": "18346",
      "Location Name": "POCONO SUMMIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2657 ROUTE 940 POCONO SUMMIT PA 18346 USA "
    },
    {
      "Location ID": "18347",
      "Location Name": "POCONO LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 ROUTE 940 POCONO LAKE PA 18347 USA "
    },
    {
      "Location ID": "18350",
      "Location Name": "POCONO PINES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1712 ROUTE 940 STE 102 POCONO PINES PA 18350 USA "
    },
    {
      "Location ID": "18351",
      "Location Name": "PORTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 403 DELAWARE AVE PORTLAND PA 18351 USA "
    },
    {
      "Location ID": "18352",
      "Location Name": "REEDERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2162 ROUTE 715 REEDERS PA 18352 USA "
    },
    {
      "Location ID": "18353",
      "Location Name": "SAYLORSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 284 ANCHORAGE RD SAYLORSBURG PA 18353 USA "
    },
    {
      "Location ID": "18354",
      "Location Name": "SCIOTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 134 BOSSARDSVILLE RD SCIOTA PA 18354 USA "
    },
    {
      "Location ID": "18355",
      "Location Name": "SCOTRUN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 338 SCOTRUN AVE OFC SCOTRUN PA 18355 USA "
    },
    {
      "Location ID": "18356",
      "Location Name": "SHAWNEE ON DELAWARE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 SHAWNEE SQUARE DR SE SHAWNEE ON DELAWARE PA 18356 USA "
    },
    {
      "Location ID": "18357",
      "Location Name": "SKYTOP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 0 SKYTOP LDG SKYTOP PA 18357 USA "
    },
    {
      "Location ID": "18360",
      "Location Name": "STROUDSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 701 ANN ST STROUDSBURG PA 18360 USA "
    },
    {
      "Location ID": "18370",
      "Location Name": "SWIFTWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 296 UPPER SWIFTWATER RD SWIFTWATER PA 18370 USA "
    },
    {
      "Location ID": "18372",
      "Location Name": "TANNERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2937 ROUTE 611 TANNERSVILLE PA 18372 USA "
    },
    {
      "Location ID": "183CI",
      "Location Name": "CHILDREN'S ISLAND (E STROUDS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 434 KING ST E STROUDSBURG PA 18301 USA "
    },
    {
      "Location ID": "183CV",
      "Location Name": "CVS PHARMACY (BRODHEADSVILLE)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 ERIN LN BRODHEADSVLLE PA 18322 USA "
    },
    {
      "Location ID": "183JS",
      "Location Name": "J&S GAMES LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 901 SUNRISE BLVD MOUNT BETHEL PA 18343 USA "
    },
    {
      "Location ID": "184",
      "Location Name": "LOG SCRANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2800 STAFFORD AVE SCRANTON PA 18505 USA "
    },
    {
      "Location ID": "18403",
      "Location Name": "ARCHBALD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 KENNEDY DR ARCHBALD PA 18403 USA "
    },
    {
      "Location ID": "18405",
      "Location Name": "BEACH LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1015 BEACH LAKE HWY BEACH LAKE PA 18405 USA "
    },
    {
      "Location ID": "18407",
      "Location Name": "CARBONDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 DUNDAFF ST CARBONDALE PA 18407 USA "
    },
    {
      "Location ID": "18410",
      "Location Name": "CHINCHILLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 555 NORTHERN BLVD CHINCHILLA PA 18410 USA "
    },
    {
      "Location ID": "18411",
      "Location Name": "CLARKS SUMMIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 S STATE ST CLARKS SUMMIT PA 18411 USA "
    },
    {
      "Location ID": "18414",
      "Location Name": "DALTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 W MAIN ST DALTON PA 18414 USA "
    },
    {
      "Location ID": "18417",
      "Location Name": "EQUINUNK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4338 HANCOCK HWY BLDG B EQUINUNK PA 18417 USA "
    },
    {
      "Location ID": "18419",
      "Location Name": "FACTORYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 COLLEGE AVE FACTORYVILLE PA 18419 USA "
    },
    {
      "Location ID": "18420",
      "Location Name": "FLEETVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16043 STATE ROUTE 407 FLEETVILLE PA 18420 USA "
    },
    {
      "Location ID": "18421",
      "Location Name": "FOREST CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 334 MAIN ST FOREST CITY PA 18421 USA "
    },
    {
      "Location ID": "18424",
      "Location Name": "GOULDSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 546 MAIN ST GOULDSBORO PA 18424 USA "
    },
    {
      "Location ID": "18425",
      "Location Name": "GREELEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 ROUTE 590 GREELEY PA 18425 USA "
    },
    {
      "Location ID": "18426",
      "Location Name": "GREENTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1340 ROUTE 507 GREENTOWN PA 18426 USA "
    },
    {
      "Location ID": "18427",
      "Location Name": "HAMLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 501 HAMLIN HWY STE A HAMLIN PA 18427 USA "
    },
    {
      "Location ID": "18428",
      "Location Name": "HAWLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 MAIN AVE HAWLEY PA 18428 USA "
    },
    {
      "Location ID": "18431",
      "Location Name": "HONESDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 830 MAIN ST HONESDALE PA 18431 USA "
    },
    {
      "Location ID": "18433",
      "Location Name": "JERMYN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 551 WASHINGTON AVE JERMYN PA 18433 USA "
    },
    {
      "Location ID": "18434",
      "Location Name": "JESSUP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 95 CHURCH ST JESSUP PA 18434 USA "
    },
    {
      "Location ID": "18435",
      "Location Name": "LACKAWAXEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 178 SCENIC DR LACKAWAXEN PA 18435 USA "
    },
    {
      "Location ID": "18436",
      "Location Name": "LAKE ARIEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1446 LAKE ARIEL HWY LAKE ARIEL PA 18436 USA "
    },
    {
      "Location ID": "18439",
      "Location Name": "LAKEWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1436 CROSSTOWN HWY LAKEWOOD PA 18439 USA "
    },
    {
      "Location ID": "18440",
      "Location Name": "LA PLUME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2092 E LA PLUME RD LA PLUME PA 18440 USA "
    },
    {
      "Location ID": "18444",
      "Location Name": "MOSCOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 331 N MAIN ST MOSCOW PA 18444 USA "
    },
    {
      "Location ID": "18445",
      "Location Name": "NEWFOUNDLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 PANTHER RD NEWFOUNDLAND PA 18445 USA "
    },
    {
      "Location ID": "18446",
      "Location Name": "NICHOLSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 290 MAIN ST NICHOLSON PA 18446 USA "
    },
    {
      "Location ID": "18447",
      "Location Name": "OLYPHANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 517 BURKE BYP OLYPHANT PA 18447 USA "
    },
    {
      "Location ID": "18452",
      "Location Name": "PECKVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 323 KEYSTONE AVE PECKVILLE PA 18452 USA "
    },
    {
      "Location ID": "18456",
      "Location Name": "PROMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 88 RIVER RD PROMPTON PA 18456 USA "
    },
    {
      "Location ID": "18457",
      "Location Name": "ROWLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 SOLVERSON RD ROWLAND PA 18457 USA "
    },
    {
      "Location ID": "18458",
      "Location Name": "SHOHOLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 RICHARDSON AVE SHOHOLA PA 18458 USA "
    },
    {
      "Location ID": "18459",
      "Location Name": "SOUTH CANAAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2159 EASTON TPKE UNIT 1 SOUTH CANAAN PA 18459 USA "
    },
    {
      "Location ID": "18462",
      "Location Name": "STARRUCCA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 327 STARRUCCA CREEK RD STE 101 STARRUCCA PA 18462 USA "
    },
    {
      "Location ID": "18463",
      "Location Name": "STERLING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 617 STERLING RD STERLING PA 18463 USA "
    },
    {
      "Location ID": "18465",
      "Location Name": "THOMPSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 412 JACKSON ST THOMPSON PA 18465 USA "
    },
    {
      "Location ID": "18466",
      "Location Name": "TOBYHANNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 659 MAIN ST TOBYHANNA PA 18466 USA "
    },
    {
      "Location ID": "18470",
      "Location Name": "UNION DALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 924 S MAIN ST UNION DALE PA 18470 USA "
    },
    {
      "Location ID": "18471",
      "Location Name": "WAVERLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1115 N ABINGTON RD WAVERLY PA 18471 USA "
    },
    {
      "Location ID": "18472",
      "Location Name": "WAYMART",
      "Time Zone Offset (Hours)": 0,
      "Address": " 310 HONESDALE RD WAYMART PA 18472 USA "
    },
    {
      "Location ID": "18473",
      "Location Name": "WHITE MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 744 TEXAS PALMYRA HWY WHITE MILLS PA 18473 USA "
    },
    {
      "Location ID": "184GE",
      "Location Name": "GENCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2086 CORPORATE CENTER DR W TOBYHANNA PA 18466 USA "
    },
    {
      "Location ID": "186",
      "Location Name": "WILKES-BARRE MPO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 S MAIN ST WILKES BARRE PA 18701 USA "
    },
    {
      "Location ID": "18603",
      "Location Name": "BERWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 330 PINE ST BERWICK PA 18603 USA "
    },
    {
      "Location ID": "18612",
      "Location Name": "DALLAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2759 MEMORIAL HWY DALLAS PA 18612 USA "
    },
    {
      "Location ID": "18614",
      "Location Name": "DUSHORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 CENTER ST DUSHORE PA 18614 USA "
    },
    {
      "Location ID": "18615",
      "Location Name": "FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 WOODSIDE DR FALLS PA 18615 USA "
    },
    {
      "Location ID": "18617",
      "Location Name": "GLEN LYON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 45 N MARKET ST GLEN LYON PA 18617 USA "
    },
    {
      "Location ID": "18618",
      "Location Name": "HARVEYS LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2115 LAKESIDE DR HARVEYS LAKE PA 18618 USA "
    },
    {
      "Location ID": "18621",
      "Location Name": "HUNLOCK CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1623 MAIN RD HUNLOCK CREEK PA 18621 USA "
    },
    {
      "Location ID": "18623",
      "Location Name": "LACEYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 445 MAIN ST OFC LACEYVILLE PA 18623 USA "
    },
    {
      "Location ID": "18625",
      "Location Name": "LAKE WINOLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 POST OFFICE LN LAKE WINOLA PA 18625 USA "
    },
    {
      "Location ID": "18626",
      "Location Name": "LAPORTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 MAIN ST LAPORTE PA 18626 USA "
    },
    {
      "Location ID": "18627",
      "Location Name": "LEHMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1010 MOUNTAINVIEW DR LEHMAN PA 18627 USA "
    },
    {
      "Location ID": "18628",
      "Location Name": "LOPEZ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 61 MAIN ST LOPEZ PA 18628 USA "
    },
    {
      "Location ID": "18629",
      "Location Name": "MEHOOPANY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 PEARL RD MEHOOPANY PA 18629 USA "
    },
    {
      "Location ID": "18630",
      "Location Name": "MESHOPPEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 149 CANAL ST MESHOPPEN PA 18630 USA "
    },
    {
      "Location ID": "18631",
      "Location Name": "MIFFLINVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 MARKET ST MIFFLINVILLE PA 18631 USA "
    },
    {
      "Location ID": "18632",
      "Location Name": "MILDRED",
      "Time Zone Offset (Hours)": 0,
      "Address": " 54 JACKSON ST MILDRED PA 18632 USA "
    },
    {
      "Location ID": "18634",
      "Location Name": "NANTICOKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 70 N MARKET ST NANTICOKE PA 18634 USA "
    },
    {
      "Location ID": "18635",
      "Location Name": "NESCOPECK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 311 BROAD ST NESCOPECK PA 18635 USA "
    },
    {
      "Location ID": "18640",
      "Location Name": "PITTSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 DOCK ST PITTSTON PA 18640 USA "
    },
    {
      "Location ID": "18651",
      "Location Name": "PLYMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 W MAIN ST PLYMOUTH PA 18651 USA "
    },
    {
      "Location ID": "18653",
      "Location Name": "RANSOM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3063 MAIN ST RANSOM PA 18653 USA "
    },
    {
      "Location ID": "18655",
      "Location Name": "SHICKSHINNY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 N CANAL ST SHICKSHINNY PA 18655 USA "
    },
    {
      "Location ID": "18656",
      "Location Name": "SWEET VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5319 MAIN RD SWEET VALLEY PA 18656 USA "
    },
    {
      "Location ID": "18657",
      "Location Name": "TUNKHANNOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 BRIDGE ST TUNKHANNOCK PA 18657 USA "
    },
    {
      "Location ID": "18660",
      "Location Name": "WAPWALLOPEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 435 S MAIN ST WAPWALLOPEN PA 18660 USA "
    },
    {
      "Location ID": "186AM",
      "Location Name": "AMAZON (TUNKHANNOCK)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 279 P AND G WAREHOUSE WAY TUNKHANNOCK PA 18657 USA "
    },
    {
      "Location ID": "186AZ",
      "Location Name": "AMAZON AVP6 (PITTSTON PA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 COMMERCE RD PITTSTON PA 18640 USA "
    },
    {
      "Location ID": "186KA",
      "Location Name": "KEYSTONE AUTOMOTIVE (EXETER)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44 TUNKHANNOCK AVE EXETER PA 18643 USA "
    },
    {
      "Location ID": "186OL",
      "Location Name": "ONLINE LABELS (PA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 260 RESEARCH DR JENKINS TWP PA 18640 USA "
    },
    {
      "Location ID": "186SH",
      "Location Name": "SHIPMUNK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 285 CENTERPOINT BLVD PITTSTON PA 18640 USA "
    },
    {
      "Location ID": "186VN",
      "Location Name": "VMI NNOVATIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 TRADEPORT RD NANTICOKE PA 18634 USA "
    },
    {
      "Location ID": "18701",
      "Location Name": "WILKES BARRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 S MAIN ST WILKES BARRE PA 18701 USA "
    },
    {
      "Location ID": "18707",
      "Location Name": "MOUNTAIN TOP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 42 S MOUNTAIN BLVD MOUNTAIN TOP PA 18707 USA "
    },
    {
      "Location ID": "187AD",
      "Location Name": "ALL ABOUT DANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 180 WELLES ST FORTY FORT PA 18704 USA "
    },
    {
      "Location ID": "187AI",
      "Location Name": "ADIDAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 550 NEW COMMERCE BLVD HANOVER TOWNSHI PA 18706 USA "
    },
    {
      "Location ID": "187CV",
      "Location Name": "CVS CAREMARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 GREAT VALLEY BLVD WILKES BARRE PA 18706 USA "
    },
    {
      "Location ID": "187DH",
      "Location Name": "DHL/PATAGONIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 630 NEW COMMERCE BLVD HANOVER TWP PA 18706 USA "
    },
    {
      "Location ID": "187KB",
      "Location Name": "SHAVERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1915 MEMORIAL HWY SHAVERTOWN PA 18708 USA "
    },
    {
      "Location ID": "187RN",
      "Location Name": "RAKUTEN - WILKES BARRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 325 LASLEY AVE HANOVER TOWNSHIP PA 18706 USA "
    },
    {
      "Location ID": "18801",
      "Location Name": "MONTROSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 38 MONUMENT SQ MONTROSE PA 18801 USA "
    },
    {
      "Location ID": "18810",
      "Location Name": "ATHENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 S MAIN ST ATHENS PA 18810 USA "
    },
    {
      "Location ID": "18814",
      "Location Name": "BURLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16658 ROUTE 6 BURLINGTON PA 18814 USA "
    },
    {
      "Location ID": "18821",
      "Location Name": "GREAT BEND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 260 MAIN ST GREAT BEND PA 18821 USA "
    },
    {
      "Location ID": "18822",
      "Location Name": "HALLSTEAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 587 MAIN ST HALLSTEAD PA 18822 USA "
    },
    {
      "Location ID": "18825",
      "Location Name": "JACKSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 362 OLD MILL RD JACKSON PA 18825 USA "
    },
    {
      "Location ID": "18828",
      "Location Name": "LAWTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10727 STATE ROUTE 267 OFC LAWTON PA 18828 USA "
    },
    {
      "Location ID": "18829",
      "Location Name": "LE RAYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 981 MAIN ST LE RAYSVILLE PA 18829 USA "
    },
    {
      "Location ID": "18831",
      "Location Name": "MILAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28364 ROUTE 220 MILAN PA 18831 USA "
    },
    {
      "Location ID": "18832",
      "Location Name": "MONROETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 264 SHAW BLVD MONROETON PA 18832 USA "
    },
    {
      "Location ID": "18833",
      "Location Name": "NEW ALBANY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 360 FRONT ST NEW ALBANY PA 18833 USA "
    },
    {
      "Location ID": "18834",
      "Location Name": "NEW MILFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 667 MAIN ST NEW MILFORD PA 18834 USA "
    },
    {
      "Location ID": "18837",
      "Location Name": "ROME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 811 MAIN ST ROME PA 18837 USA "
    },
    {
      "Location ID": "18840",
      "Location Name": "SAYRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 240 DESMOND ST SAYRE PA 18840 USA "
    },
    {
      "Location ID": "18842",
      "Location Name": "SOUTH GIBSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8917 ROUTE 92 SOUTH GIBSON PA 18842 USA "
    },
    {
      "Location ID": "18845",
      "Location Name": "STEVENSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8979 ROUTE 706 STEVENSVILLE PA 18845 USA "
    },
    {
      "Location ID": "18846",
      "Location Name": "SUGAR RUN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4873 SR 187 SUGAR RUN PA 18846 USA "
    },
    {
      "Location ID": "18847",
      "Location Name": "SUSQUEHANNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1186 MAIN ST SUSQUEHANNA PA 18847 USA "
    },
    {
      "Location ID": "18848",
      "Location Name": "TOWANDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 700 MAIN ST TOWANDA PA 18848 USA "
    },
    {
      "Location ID": "18850",
      "Location Name": "ULSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23848 ROUTE 220 ULSTER PA 18850 USA "
    },
    {
      "Location ID": "18853",
      "Location Name": "WYALUSING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 58 CHURCH ST WYALUSING PA 18853 USA "
    },
    {
      "Location ID": "18854",
      "Location Name": "WYSOX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1800 GOLDEN MILE RD WYSOX PA 18854 USA "
    },
    {
      "Location ID": "189",
      "Location Name": "SOUTHEASTERN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 W VALLEY RD SOUTHEASTERN PA 19399 USA "
    },
    {
      "Location ID": "18901",
      "Location Name": "DOYLESTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 ATKINSON DR DOYLESTOWN PA 18901 USA "
    },
    {
      "Location ID": "18912",
      "Location Name": "BUCKINGHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4657 YORK RD BUCKINGHAM PA 18912 USA "
    },
    {
      "Location ID": "18913",
      "Location Name": "CARVERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6208 FLEECY DALE RD CARVERSVILLE PA 18913 USA "
    },
    {
      "Location ID": "18914",
      "Location Name": "CHALFONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 N MAIN ST CHALFONT PA 18914 USA "
    },
    {
      "Location ID": "18915",
      "Location Name": "COLMAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 BETHLEHEM PIKE COLMAR PA 18915 USA "
    },
    {
      "Location ID": "18916",
      "Location Name": "DANBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4317 POINT PLEASANT PIKE DANBORO PA 18916 USA "
    },
    {
      "Location ID": "18922",
      "Location Name": "FOREST GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1809 FOREST GROVE RD FOREST GROVE PA 18922 USA "
    },
    {
      "Location ID": "18925",
      "Location Name": "FURLONG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 720 EDISON FURLONG RD FURLONG PA 18925 USA "
    },
    {
      "Location ID": "18926",
      "Location Name": "GARDENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " W/SIDE ROUTE 413 GARDENVILLE PA 18926 USA "
    },
    {
      "Location ID": "18928",
      "Location Name": "HOLICONG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4950 YORK RD HOLICONG PA 18928 USA "
    },
    {
      "Location ID": "18929",
      "Location Name": "JAMISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2370 YORK RD STE G1 JAMISON PA 18929 USA "
    },
    {
      "Location ID": "18931",
      "Location Name": "LAHASKA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 168 PEDDLERS VLG LAHASKA PA 18931 USA "
    },
    {
      "Location ID": "18932",
      "Location Name": "LINE LEXINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4683 COUNTY LINE RD LINE LEXINGTON PA 18932 USA "
    },
    {
      "Location ID": "18933",
      "Location Name": "LUMBERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " US POSTAL SERVICE DOYLESTOWN PA 18933 USA "
    },
    {
      "Location ID": "18934",
      "Location Name": "MECHANICSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3295 DURHAM RD MECHANICSVILLE PA 18934 USA "
    },
    {
      "Location ID": "18935",
      "Location Name": "MILFORD SQUARE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2112 ALLENTOWN RD MILFORD SQUARE PA 18935 USA "
    },
    {
      "Location ID": "18936",
      "Location Name": "MONTGOMERYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 411 DOYLESTOWN RD MONTGOMERYVILLE PA 18936 USA "
    },
    {
      "Location ID": "18938",
      "Location Name": "NEW HOPE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 325 W BRIDGE ST NEW HOPE PA 18938 USA "
    },
    {
      "Location ID": "18940",
      "Location Name": "NEWTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 TERRY DR NEWTOWN PA 18940 USA "
    },
    {
      "Location ID": "18943",
      "Location Name": "PENNS PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 842 DURHAM RD PENNS PARK PA 18943 USA "
    },
    {
      "Location ID": "18944",
      "Location Name": "PERKASIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 N 7TH ST PERKASIE PA 18944 USA "
    },
    {
      "Location ID": "18946",
      "Location Name": "PINEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1089 DURHAM RD PINEVILLE PA 18946 USA "
    },
    {
      "Location ID": "18950",
      "Location Name": "POINT PLEASANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4977 RIVER RD POINT PLEASANT PA 18950 USA "
    },
    {
      "Location ID": "18951",
      "Location Name": "QUAKERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 165 CALIFORNIA RD QUAKERTOWN PA 18951 USA "
    },
    {
      "Location ID": "18954",
      "Location Name": "RICHBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 851 BUSTLETON PIKE RICHBORO PA 18954 USA "
    },
    {
      "Location ID": "18955",
      "Location Name": "RICHLANDTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 492 RICHLANDTOWN PIKE RICHLANDTOWN PA 18955 USA "
    },
    {
      "Location ID": "18956",
      "Location Name": "RUSHLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1070 SWAMP RD RUSHLAND PA 18956 USA "
    },
    {
      "Location ID": "18960",
      "Location Name": "SELLERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 N MAIN ST SELLERSVILLE PA 18960 USA "
    },
    {
      "Location ID": "18966",
      "Location Name": "SOUTHAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1050 STREET RD SOUTHAMPTON PA 18966 USA "
    },
    {
      "Location ID": "18968",
      "Location Name": "SPINNERSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2191 SPINNERSTOWN RD SPINNERSTOWN PA 18968 USA "
    },
    {
      "Location ID": "18969",
      "Location Name": "TELFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 99 S 4TH ST TELFORD PA 18969 USA "
    },
    {
      "Location ID": "18970",
      "Location Name": "TRUMBAUERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 N MAIN ST TRUMBAUERSVILLE PA 18970 USA "
    },
    {
      "Location ID": "18974",
      "Location Name": "WARMINSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1135 MEARNS RD WARMINSTER PA 18974 USA "
    },
    {
      "Location ID": "18976",
      "Location Name": "WARRINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1380 EASTON RD STE 1 WARRINGTON PA 18976 USA "
    },
    {
      "Location ID": "18977",
      "Location Name": "WASHINGTON CROSSING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1077 RIVER RD STE 1 WASHINGTON CROSSING PA 18977 USA "
    },
    {
      "Location ID": "18980",
      "Location Name": "WYCOMBE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4122 TOWNSHIP LINE RD WYCOMBE PA 18980 USA "
    },
    {
      "Location ID": "18981",
      "Location Name": "ZIONHILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2985B OLD BETHLEHEM PIKE ZIONHILL PA 18981 USA "
    },
    {
      "Location ID": "189IO",
      "Location Name": "IO HOLDINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 COMMERCE DR QUAKERTOWN PA 18951 USA "
    },
    {
      "Location ID": "189MD",
      "Location Name": "ENCOMPASS ELEMENTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2750 MORRIS RD C LANSDALE PA 19446 USA "
    },
    {
      "Location ID": "189SG",
      "Location Name": "SWEET GOURMET (WARMINSTER)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1730 STOUT DR STE 4 WARMINSTER PA 18974 USA "
    },
    {
      "Location ID": "189VT",
      "Location Name": "QUAD GRAPHICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4371 COUNTY LINE RD CHALFONT PA 18914 USA "
    },
    {
      "Location ID": "190",
      "Location Name": "LOG PHILADELPHIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7500 LINDBERGH BLVD PHILADELPHIA PA 19176 USA "
    },
    {
      "Location ID": "19003",
      "Location Name": "ARDMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 ARDMORE AVE ARDMORE PA 19003 USA "
    },
    {
      "Location ID": "19010",
      "Location Name": "BRYN MAWR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 N BRYN MAWR AVE BRYN MAWR PA 19010 USA "
    },
    {
      "Location ID": "19041",
      "Location Name": "HAVERFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 387 W LANCASTER AVE HAVERFORD PA 19041 USA "
    },
    {
      "Location ID": "19066",
      "Location Name": "MERION STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 CIVIC CIR MERION STATION PA 19066 USA "
    },
    {
      "Location ID": "19072",
      "Location Name": "NARBERTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 144 N NARBERTH AVE NARBERTH PA 19072 USA "
    },
    {
      "Location ID": "19085",
      "Location Name": "VILLANOVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 789 E LANCASTER AVE STE 40 VILLANOVA PA 19085 USA "
    },
    {
      "Location ID": "19087",
      "Location Name": "WAYNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 S WAYNE AVE WAYNE PA 19087 USA "
    },
    {
      "Location ID": "19096",
      "Location Name": "WYNNEWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 E WYNNEWOOD RD STE 22 WYNNEWOOD PA 19096 USA "
    },
    {
      "Location ID": "190AM",
      "Location Name": "ACTION MAILERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90 COMMERCE DR ASTON PA 19014 USA "
    },
    {
      "Location ID": "190BW",
      "Location Name": "ASENDIA USA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 701 ASHLAND AVE FOLCROFT PA 19032 USA "
    },
    {
      "Location ID": "190CA",
      "Location Name": "PHILADELPHIA NDC ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9801 BLUE GRASS RD PHILADELPHIA PA 19114 USA "
    },
    {
      "Location ID": "190DL",
      "Location Name": "DELTA AIRLINES",
      "Time Zone Offset (Hours)": 0,
      "Address": " C8 CARGO CITY PHILADELPHIA PA 19153 USA "
    },
    {
      "Location ID": "190FZ",
      "Location Name": "LOG PHILADELPHIA PA PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2021 WOODHAVEN RD PHILADELPHIA PA 19116 USA "
    },
    {
      "Location ID": "190PM",
      "Location Name": "PHILADELPHIA L&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2279 CENTER SQUARE RD LOGAN TOWNSHIP NJ 08085 USA "
    },
    {
      "Location ID": "190YD",
      "Location Name": "PHILADELPHIA YARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7625 SUFFOLK AVE PHILADELPHIA PA 19153 USA "
    },
    {
      "Location ID": "191",
      "Location Name": "PHILADELPHIA        PA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7500 LINDBERGH BOULEVARD PHILADELPHIA PA 19176 USA "
    },
    {
      "Location ID": "19114",
      "Location Name": "TORRESDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10380 DRUMMOND RD PHILADELPHIA PA 19154 USA "
    },
    {
      "Location ID": "19115",
      "Location Name": "BUSTLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9925 BUSTLETON AVE PHILADELPHIA PA 19115 USA "
    },
    {
      "Location ID": "191FI",
      "Location Name": "FMI DIRECT MAIL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2100 KUBACH RD PHILADELPHIA PA 19116 USA "
    },
    {
      "Location ID": "191FZ",
      "Location Name": "LOG PHILADELPHIA PA PSA 2",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 CREEK PKWY UPPER CHICHESTER PA 19061-9994 USA "
    },
    {
      "Location ID": "191UT",
      "Location Name": "U S TREASURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13000 TOWNSEND RD PHILADELPHIA PA 19154 USA "
    },
    {
      "Location ID": "192",
      "Location Name": "PHILADELPHA FIRMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7500 LINDBERG BLVD PHILADELPHIA PA 19176 USA "
    },
    {
      "Location ID": "19319",
      "Location Name": "CHEYNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 STATION RD CHEYNEY PA 19319 USA "
    },
    {
      "Location ID": "19339",
      "Location Name": "STATE FARM INSURANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 STATE FARM DRIVE CONCORDVILLE PA 19339 USA "
    },
    {
      "Location ID": "19342",
      "Location Name": "GLEN MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 142 GLEN MILLS RD GLEN MILLS PA 19342 USA "
    },
    {
      "Location ID": "19373",
      "Location Name": "THORNTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 378 GLEN MILLS RD THORNTON PA 19373 USA "
    },
    {
      "Location ID": "19395",
      "Location Name": "WESTTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 844 E STREET RD WESTTOWN PA 19395 USA "
    },
    {
      "Location ID": "193AN",
      "Location Name": "ANRO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 931 S MATLACK ST WEST CHESTER PA 19382 USA "
    },
    {
      "Location ID": "193AX",
      "Location Name": "GLEN MILLS CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 99 ALDAN AVE FRNT GLEN MILLS PA 19342 USA "
    },
    {
      "Location ID": "193CC",
      "Location Name": "CLEMENT COMMUNICATIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " CONCORD INDUSTRIAL PARK CONCORDVILLE PA 19331 USA "
    },
    {
      "Location ID": "19401",
      "Location Name": "NORRISTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 E AIRY ST NORRISTOWN PA 19401 USA "
    },
    {
      "Location ID": "19421",
      "Location Name": "BIRCHRUNVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1405 HOLLOW RD BIRCHRUNVILLE PA 19421 USA "
    },
    {
      "Location ID": "19422",
      "Location Name": "BLUE BELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 651 E TOWNSHIP LINE RD BLUE BELL PA 19422 USA "
    },
    {
      "Location ID": "19425",
      "Location Name": "CHESTER SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1214 KIMBERTON RD CHESTER SPRINGS PA 19425 USA "
    },
    {
      "Location ID": "19432",
      "Location Name": "DEVAULT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4030 S WHITEHORSE RD DEVAULT PA 19432 USA "
    },
    {
      "Location ID": "19437",
      "Location Name": "GWYNEDD VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 527 PLYMOUTH RD GWYNEDD VALLEY PA 19437 USA "
    },
    {
      "Location ID": "19442",
      "Location Name": "KIMBERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2102 KIMBERTON RD KIMBERTON PA 19442 USA "
    },
    {
      "Location ID": "19444",
      "Location Name": "LAFAYETTE HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 446 GERMANTOWN PIKE LAFAYETTE HILL PA 19444 USA "
    },
    {
      "Location ID": "19462",
      "Location Name": "PLYMOUTH MEETING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 E GERMANTOWN PIKE PLYMOUTH MEETING PA 19462 USA "
    },
    {
      "Location ID": "19464",
      "Location Name": "POTTSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 770 E HIGH ST POTTSTOWN PA 19464 USA "
    },
    {
      "Location ID": "19470",
      "Location Name": "SAINT PETERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3300 SAINT PETERS RD SAINT PETERS PA 19470 USA "
    },
    {
      "Location ID": "19477",
      "Location Name": "SPRING HOUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 905 BETHLEHEM PIKE SPRING HOUSE PA 19477 USA "
    },
    {
      "Location ID": "19480",
      "Location Name": "UWCHLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 FELLOWSHIP RD UWCHLAND PA 19480 USA "
    },
    {
      "Location ID": "194AX",
      "Location Name": "EAGLEVILLE CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2300 POTSHOP LN NORRISTOWN PA 19403 USA "
    },
    {
      "Location ID": "194CX",
      "Location Name": "CONSHOHOCKEN CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 INDUSTRIAL WAY CONSHOHOCKEN PA 19428 USA "
    },
    {
      "Location ID": "195",
      "Location Name": "READING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2100 N 13TH ST READING PA 19612 USA "
    },
    {
      "Location ID": "19501",
      "Location Name": "ADAMSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 128 W MAIN ST ADAMSTOWN PA 19501 USA "
    },
    {
      "Location ID": "19503",
      "Location Name": "BALLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 S 7TH ST BALLY PA 19503 USA "
    },
    {
      "Location ID": "19504",
      "Location Name": "BARTO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2245 OLD ROUTE 100 BARTO PA 19504 USA "
    },
    {
      "Location ID": "19505",
      "Location Name": "BECHTELSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1811 N MAIN ST BECHTELSVILLE PA 19505 USA "
    },
    {
      "Location ID": "19506",
      "Location Name": "BERNVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 E 3RD ST BERNVILLE PA 19506 USA "
    },
    {
      "Location ID": "19507",
      "Location Name": "BETHEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 LEGION DR BETHEL PA 19507 USA "
    },
    {
      "Location ID": "19508",
      "Location Name": "BIRDSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 340 W MAIN ST BIRDSBORO PA 19508 USA "
    },
    {
      "Location ID": "19510",
      "Location Name": "BLANDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 E WESNER RD BLANDON PA 19510 USA "
    },
    {
      "Location ID": "19511",
      "Location Name": "BOWERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 97 OLD BOWERS RD BOWERS PA 19511 USA "
    },
    {
      "Location ID": "19512",
      "Location Name": "BOYERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 128 S WASHINGTON ST BOYERTOWN PA 19512 USA "
    },
    {
      "Location ID": "19516",
      "Location Name": "CENTERPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 E CHESTNUT ST CENTERPORT PA 19516 USA "
    },
    {
      "Location ID": "19518",
      "Location Name": "DOUGLASSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 197 OLD SWEDE RD DOUGLASSVILLE PA 19518 USA "
    },
    {
      "Location ID": "19520",
      "Location Name": "ELVERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 S PINE ST ELVERSON PA 19520 USA "
    },
    {
      "Location ID": "19522",
      "Location Name": "FLEETWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 LENHART RD FLEETWOOD PA 19522 USA "
    },
    {
      "Location ID": "19523",
      "Location Name": "GEIGERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3291 HAYCREEK RD GEIGERTOWN PA 19523 USA "
    },
    {
      "Location ID": "19525",
      "Location Name": "GILBERTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1516 SWAMP PIKE GILBERTSVILLE PA 19525 USA "
    },
    {
      "Location ID": "19526",
      "Location Name": "HAMBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 N 3RD ST HAMBURG PA 19526 USA "
    },
    {
      "Location ID": "19529",
      "Location Name": "KEMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3759 ROUTE 737 KEMPTON PA 19529 USA "
    },
    {
      "Location ID": "19530",
      "Location Name": "KUTZTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 235 GRIMLEY ST KUTZTOWN PA 19530 USA "
    },
    {
      "Location ID": "19533",
      "Location Name": "LEESPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 E WALL ST LEESPORT PA 19533 USA "
    },
    {
      "Location ID": "19534",
      "Location Name": "LENHARTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 83 PENN ST LENHARTSVILLE PA 19534 USA "
    },
    {
      "Location ID": "19535",
      "Location Name": "LIMEKILN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 820 LIMEKILN RD LIMEKILN PA 19535 USA "
    },
    {
      "Location ID": "19536",
      "Location Name": "LYON STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 S KEMP ST LYON STATION PA 19536 USA "
    },
    {
      "Location ID": "19538",
      "Location Name": "MAXATAWNY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15881 KUTZTOWN RD MAXATAWNY PA 19538 USA "
    },
    {
      "Location ID": "19539",
      "Location Name": "MERTZTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1001 STATE ST MERTZTOWN PA 19539 USA "
    },
    {
      "Location ID": "19540",
      "Location Name": "MOHNTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 328 E WYOMISSING AVE MOHNTON PA 19540 USA "
    },
    {
      "Location ID": "19541",
      "Location Name": "MOHRSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 518 MAIN ST MOHRSVILLE PA 19541 USA "
    },
    {
      "Location ID": "19543",
      "Location Name": "MORGANTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 N MULBERRY ST MORGANTOWN PA 19543 USA "
    },
    {
      "Location ID": "19544",
      "Location Name": "MOUNT AETNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 E MILL ST MOUNT AETNA PA 19544 USA "
    },
    {
      "Location ID": "19545",
      "Location Name": "NEW BERLINVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 721 N READING AVE NEW BERLINVILLE PA 19545 USA "
    },
    {
      "Location ID": "19547",
      "Location Name": "OLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 644 MAIN ST OLEY PA 19547 USA "
    },
    {
      "Location ID": "19548",
      "Location Name": "PINE FORGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 213 PINE FORGE RD PINE FORGE PA 19548 USA "
    },
    {
      "Location ID": "19549",
      "Location Name": "PORT CLINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 BROAD ST PORT CLINTON PA 19549 USA "
    },
    {
      "Location ID": "19550",
      "Location Name": "REHRERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 349 GODFREY ST REHRERSBURG PA 19550 USA "
    },
    {
      "Location ID": "19551",
      "Location Name": "ROBESONIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37 E PENN AVE ROBESONIA PA 19551 USA "
    },
    {
      "Location ID": "19554",
      "Location Name": "SHARTLESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 N 1ST ST SHARTLESVILLE PA 19554 USA "
    },
    {
      "Location ID": "19555",
      "Location Name": "SHOEMAKERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 511 FRANKLIN ST SHOEMAKERSVILLE PA 19555 USA "
    },
    {
      "Location ID": "19559",
      "Location Name": "STRAUSSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 72 MAIN ST STRAUSSTOWN PA 19559 USA "
    },
    {
      "Location ID": "19560",
      "Location Name": "TEMPLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4917 KUTZTOWN RD TEMPLE PA 19560 USA "
    },
    {
      "Location ID": "19562",
      "Location Name": "TOPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 S HOME AVE TOPTON PA 19562 USA "
    },
    {
      "Location ID": "19564",
      "Location Name": "VIRGINVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 475 MAIN ST VIRGINVILLE PA 19564 USA "
    },
    {
      "Location ID": "19565",
      "Location Name": "WERNERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 S CHURCH RD WERNERSVILLE PA 19565 USA "
    },
    {
      "Location ID": "19567",
      "Location Name": "WOMELSDORF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37 N 3RD ST WOMELSDORF PA 19567 USA "
    },
    {
      "Location ID": "195BP",
      "Location Name": "BEHR PAINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9670 WEST HILLS CT KUTZTOWN PA 18051 USA "
    },
    {
      "Location ID": "195JM",
      "Location Name": "IWCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 INDUSTRIAL DR HAMBURG PA 19526 USA "
    },
    {
      "Location ID": "195KP",
      "Location Name": "KUTZTOWN PUBLISHING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15076 KUTZTOWN RD KUTZTOWN PA 19530 USA "
    },
    {
      "Location ID": "195MH",
      "Location Name": "MHI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 511 W PENN AVE STE 1 ROBESONIA PA 19551 USA "
    },
    {
      "Location ID": "195PF",
      "Location Name": "PAUL FREDERICKS (FLEETWOOD PA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 223 W POPLAR ST FLEETWOOD PA 19522 USA "
    },
    {
      "Location ID": "195RT",
      "Location Name": "RTC DIRECT MAILING INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 56 SEIP LN SHOEMAKERSVILLE PA 19555 USA "
    },
    {
      "Location ID": "195SM",
      "Location Name": "JETSON SPECIALITY MARKETING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9775 COMMERCE CIR KUTZTOWN PA 19530 USA "
    },
    {
      "Location ID": "195VG",
      "Location Name": "VITAMIN GUY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1623 KRAMER RD KUTZTOWN PA 19530 USA "
    },
    {
      "Location ID": "196PS",
      "Location Name": "PITNEY BOWES PSI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4201 POTTSVILLE PIKE BLDG 1D READING PA 19605 USA "
    },
    {
      "Location ID": "197",
      "Location Name": "LOG WILMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 147 QUIGLEY BLVD HISTORIC NEW CASTLE DE 19720 USA "
    },
    {
      "Location ID": "19706",
      "Location Name": "DELAWARE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 54 CLINTON ST DELAWARE CITY DE 19706 USA "
    },
    {
      "Location ID": "19707",
      "Location Name": "HOCKESSIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 651 VALLEY RD HOCKESSIN DE 19707 USA "
    },
    {
      "Location ID": "19708",
      "Location Name": "KIRKWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2501 RED LION RD KIRKWOOD DE 19708 USA "
    },
    {
      "Location ID": "19709",
      "Location Name": "MIDDLETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 298 E MAIN ST MIDDLETOWN DE 19709 USA "
    },
    {
      "Location ID": "19710",
      "Location Name": "MONTCHANIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 STONE BLOCK ROW MONTCHANIN DE 19710 USA "
    },
    {
      "Location ID": "19730",
      "Location Name": "ODESSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 312 MAIN ST ODESSA DE 19730 USA "
    },
    {
      "Location ID": "19731",
      "Location Name": "PORT PENN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 S CONGRESS ST PORT PENN DE 19731 USA "
    },
    {
      "Location ID": "19732",
      "Location Name": "ROCKLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 700 ROCKLAND RD ROCKLAND DE 19732 USA "
    },
    {
      "Location ID": "19733",
      "Location Name": "SAINT GEORGES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 BROAD ST SAINT GEORGES DE 19733 USA "
    },
    {
      "Location ID": "19734",
      "Location Name": "TOWNSEND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 MAIN ST TOWNSEND DE 19734 USA "
    },
    {
      "Location ID": "19735",
      "Location Name": "WINTERTHUR          DE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5105 KENNETT PIKE WINTERTHUR DE 19735 USA "
    },
    {
      "Location ID": "19736",
      "Location Name": "YORKLYN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1149 YORKLYN RD YORKLYN DE 19736 USA "
    },
    {
      "Location ID": "197AD",
      "Location Name": "VALASSIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 MCINTIRE DR NEWARK DE 19711 USA "
    },
    {
      "Location ID": "197AZ",
      "Location Name": "AMAZON.COM PHL7",
      "Time Zone Offset (Hours)": 0,
      "Address": " 560 MERRIMAC AVE MIDDLETOWN DE 19709 USA "
    },
    {
      "Location ID": "197BA",
      "Location Name": "BANK OF AMERICA (NEWARK)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 SAMOSET DR BLDG 3 NEWARK DE 19713 USA "
    },
    {
      "Location ID": "199",
      "Location Name": "DOVER PO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 THE PLAZA DOVER DE 19901 USA "
    },
    {
      "Location ID": "19901",
      "Location Name": "DOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 445 BANK LN DOVER DE 19904 USA "
    },
    {
      "Location ID": "19902",
      "Location Name": "DOVER AFB",
      "Time Zone Offset (Hours)": 0,
      "Address": " 442 13TH ST DOVER AFB DE 19902 USA "
    },
    {
      "Location ID": "19931",
      "Location Name": "BETHEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7748 MAIN ST BETHEL DE 19931 USA "
    },
    {
      "Location ID": "19933",
      "Location Name": "BRIDGEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 WALNUT ST BRIDGEVILLE DE 19933 USA "
    },
    {
      "Location ID": "19934",
      "Location Name": "CAMDEN WYOMING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 OLD NORTH RD CAMDEN WYOMING DE 19934 USA "
    },
    {
      "Location ID": "19936",
      "Location Name": "CHESWOLD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 262 E MAIN ST CHESWOLD DE 19936 USA "
    },
    {
      "Location ID": "19938",
      "Location Name": "CLAYTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 RAILROAD AVE CLAYTON DE 19938 USA "
    },
    {
      "Location ID": "19940",
      "Location Name": "DELMAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 E GROVE ST DELMAR DE 19940 USA "
    },
    {
      "Location ID": "19943",
      "Location Name": "FELTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 W SEWELL ST FELTON DE 19943 USA "
    },
    {
      "Location ID": "19946",
      "Location Name": "FREDERICA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 128 W FRONT ST FREDERICA DE 19946 USA "
    },
    {
      "Location ID": "19950",
      "Location Name": "GREENWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 E MARKET ST GREENWOOD DE 19950 USA "
    },
    {
      "Location ID": "19951",
      "Location Name": "HARBESON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18865 HARBESON RD HARBESON DE 19951 USA "
    },
    {
      "Location ID": "19952",
      "Location Name": "HARRINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 COMMERCE ST HARRINGTON DE 19952 USA "
    },
    {
      "Location ID": "19953",
      "Location Name": "HARTLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 223 MAIN ST HARTLY DE 19953 USA "
    },
    {
      "Location ID": "19954",
      "Location Name": "HOUSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 156 BROAD ST HOUSTON DE 19954 USA "
    },
    {
      "Location ID": "19955",
      "Location Name": "KENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 FRAZIER ST KENTON DE 19955 USA "
    },
    {
      "Location ID": "19956",
      "Location Name": "LAUREL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1121 S CENTRAL AVE LAUREL DE 19956 USA "
    },
    {
      "Location ID": "19958",
      "Location Name": "LEWES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 FRONT ST STE 1 LEWES DE 19958 USA "
    },
    {
      "Location ID": "19961",
      "Location Name": "LITTLE CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 458 MAIN ST LITTLE CREEK DE 19961 USA "
    },
    {
      "Location ID": "19962",
      "Location Name": "MAGNOLIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 N MAIN ST MAGNOLIA DE 19962 USA "
    },
    {
      "Location ID": "19966",
      "Location Name": "MILLSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 MAIN ST MILLSBORO DE 19966 USA "
    },
    {
      "Location ID": "19968",
      "Location Name": "MILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 219 MILTON ELLENDALE HWY MILTON DE 19968 USA "
    },
    {
      "Location ID": "19969",
      "Location Name": "NASSAU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17601 COASTAL HWY UNIT 11 NASSAU DE 19969 USA "
    },
    {
      "Location ID": "19971",
      "Location Name": "REHOBOTH BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 179 REHOBOTH AVE REHOBOTH BEACH DE 19971 USA "
    },
    {
      "Location ID": "19973",
      "Location Name": "SEAFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2001 BRIDGEVILLE HWY SEAFORD DE 19973 USA "
    },
    {
      "Location ID": "19977",
      "Location Name": "SMYRNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 S MARKET ST SMYRNA DE 19977 USA "
    },
    {
      "Location ID": "19979",
      "Location Name": "VIOLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 S MAIN ST VIOLA DE 19979 USA "
    },
    {
      "Location ID": "19980",
      "Location Name": "WOODSIDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4568 TURKEY POINT RD WOODSIDE DE 19980 USA "
    },
    {
      "Location ID": "199CS",
      "Location Name": "CHICKS SADDLERY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18011 S DUPONT HWY HARRINGTON DE 19952 USA "
    },
    {
      "Location ID": "199LA",
      "Location Name": "LEWES ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 402 E SAVANNAH RD LEWES DE 19958 USA "
    },
    {
      "Location ID": "199RE",
      "Location Name": "REHOBOTH BEACH CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20673 COASTAL HWY UNIT 8 REHOBOTH BEACH DE 19971 USA "
    },
    {
      "Location ID": "19H",
      "Location Name": "PTN.MCI COLO SITE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 51 ROBINSON ST POTTSTOWN PA 19464 USA "
    },
    {
      "Location ID": "19Z",
      "Location Name": "LOG PHILADELPHIA NDC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1900 BYBERRY RD STE 1 PHILADELPHIA PA 19116 USA "
    },
    {
      "Location ID": "200",
      "Location Name": "LOG WASHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 900 BRENTWOOD RD NE WASHINGTON DC 20018 USA "
    },
    {
      "Location ID": "200AX",
      "Location Name": "WASHINGTON DC OFFICIAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3300 V STREET NE WASHINGTON DC 20018 USA "
    },
    {
      "Location ID": "200FZ",
      "Location Name": "LOG WASHINGTON DC PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3300 MARLO LN DISTRICT HEIGHTS MD 20747 USA "
    },
    {
      "Location ID": "201",
      "Location Name": "DULLES P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44715 PRENTICE DR DULLES VA 20101 USA "
    },
    {
      "Location ID": "20105",
      "Location Name": "ALDIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39344 JOHN MOSBY HWY ALDIE VA 20105 USA "
    },
    {
      "Location ID": "20106",
      "Location Name": "AMISSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 768 VIEWTOWN RD AMISSVILLE VA 20106 USA "
    },
    {
      "Location ID": "20110",
      "Location Name": "MANASSAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8801 SUDLEY RD MANASSAS VA 20110 USA "
    },
    {
      "Location ID": "20111",
      "Location Name": "MANASSAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9020 EUCLID AVE STE L MANASSAS VA 20110 USA "
    },
    {
      "Location ID": "20115",
      "Location Name": "MARSHALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8361 W MAIN ST MARSHALL VA 20115 USA "
    },
    {
      "Location ID": "20117",
      "Location Name": "MIDDLEBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 W WASHINGTON ST MIDDLEBURG VA 20117 USA "
    },
    {
      "Location ID": "20119",
      "Location Name": "CATLETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3584 CATLETT RD CATLETT VA 20119 USA "
    },
    {
      "Location ID": "20120",
      "Location Name": "SULLY STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5003 WESTFIELDS BLVD CENTREVILLE VA 20120 USA "
    },
    {
      "Location ID": "20121",
      "Location Name": "CENTREVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14120 LEE HWY CENTREVILLE VA 20121 USA "
    },
    {
      "Location ID": "20128",
      "Location Name": "ORLEAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6864 LEEDS MANOR RD ORLEAN VA 20128 USA "
    },
    {
      "Location ID": "20131",
      "Location Name": "PHILOMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36550 JEB STUART RD PHILOMONT VA 20131 USA "
    },
    {
      "Location ID": "20132",
      "Location Name": "PURCELLVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 N HATCHER AVE PURCELLVILLE VA 20132 USA "
    },
    {
      "Location ID": "20135",
      "Location Name": "BLUEMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33775 SNICKERSVILLE TPKE BLUEMONT VA 20135 USA "
    },
    {
      "Location ID": "20136",
      "Location Name": "BRISTOW BOX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11151 VALLEY VIEW DR BRISTOW VA 20136 USA "
    },
    {
      "Location ID": "20137",
      "Location Name": "BROAD RUN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5073 JOHN MARSHALL HWY BROAD RUN VA 20137 USA "
    },
    {
      "Location ID": "20138",
      "Location Name": "CALVERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4115 CATLETT RD CALVERTON VA 20138 USA "
    },
    {
      "Location ID": "20139",
      "Location Name": "CASANOVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5137 WESTON RD CASANOVA VA 20139 USA "
    },
    {
      "Location ID": "20140",
      "Location Name": "RECTORTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2975 RECTORTOWN RD RECTORTOWN VA 20140 USA "
    },
    {
      "Location ID": "20141",
      "Location Name": "ROUND HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 MAIN ST ROUND HILL VA 20141 USA "
    },
    {
      "Location ID": "20143",
      "Location Name": "CATHARPIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4625 SUDLEY RD CATHARPIN VA 20143 USA "
    },
    {
      "Location ID": "20144",
      "Location Name": "DELAPLANE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3046 DELAPLANE GRADE RD DELAPLANE VA 20144 USA "
    },
    {
      "Location ID": "20151",
      "Location Name": "CHANTILLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4410 BROOKFIELD CORPORATE DR CHANTILLY VA 20151 USA "
    },
    {
      "Location ID": "20155",
      "Location Name": "GAINESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14689 LEE HWY GAINESVILLE VA 20155 USA "
    },
    {
      "Location ID": "20158",
      "Location Name": "HAMILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 53 W COLONIAL HWY HAMILTON VA 20158 USA "
    },
    {
      "Location ID": "20164",
      "Location Name": "STERLING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 S STERLING BLVD STERLING VA 20164 USA "
    },
    {
      "Location ID": "20165",
      "Location Name": "POTOMAC FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 46164 WESTLAKE DR STERLING VA 20165 USA "
    },
    {
      "Location ID": "20168",
      "Location Name": "HAYMARKET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6601 JEFFERSON STREET HAYMARKET VA 20169 USA "
    },
    {
      "Location ID": "20181",
      "Location Name": "NOKESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12913 MARSTELLER DR NOKESVILLE VA 20181 USA "
    },
    {
      "Location ID": "20184",
      "Location Name": "UPPERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9090 JOHN S MOSBY HWY UPPERVILLE VA 20184 USA "
    },
    {
      "Location ID": "20186",
      "Location Name": "WARRENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 53 MAIN ST WARRENTON VA 20186 USA "
    },
    {
      "Location ID": "20187",
      "Location Name": "VINT HILL FARMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4257 AIKEN DR WARRENTON VA 20187 USA "
    },
    {
      "Location ID": "20188",
      "Location Name": "WARRENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 53 MAIN ST WARRENTON VA 20186 USA "
    },
    {
      "Location ID": "20198",
      "Location Name": "THE PLAINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4314 FAUQUIER AVE THE PLAINS VA 20198 USA "
    },
    {
      "Location ID": "201AN",
      "Location Name": "NOVA PRIORITY ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 831 FOLLIN LN SE VIENNA VA 22180 USA "
    },
    {
      "Location ID": "201AX",
      "Location Name": "TRI CITY GAINESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7645 LIMESTONE DR GAINESVILLE VA 20155 USA "
    },
    {
      "Location ID": "201CA",
      "Location Name": "WARRENTON CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7349 COMFORT INN DR WARRENTON VA 20187 USA "
    },
    {
      "Location ID": "201DE",
      "Location Name": "DEL-A-RUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4250 PLEASANT VALLEY RD CHANTILLY VA 20151 USA "
    },
    {
      "Location ID": "201LA",
      "Location Name": "LEESBURG ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 75 LAWSON RD SE STE 106 LEESBURG VA 20175 USA "
    },
    {
      "Location ID": "201RP",
      "Location Name": "RPG ENTERPRISE LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7403 GATEWAY CT MANASSAS VA 20109 USA "
    },
    {
      "Location ID": "201SD",
      "Location Name": "SMS MAILING SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8461 VIRGINIA MEADOWS DR MANASSAS VA 20109 USA "
    },
    {
      "Location ID": "201ST",
      "Location Name": "STATE DEPARTMENT ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44132 MERCURE CIR STERLING VA 20166 USA "
    },
    {
      "Location ID": "201TE",
      "Location Name": "TEPPIGEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7951 GAINESFORD CT BRISTOW VA 20136 USA "
    },
    {
      "Location ID": "206",
      "Location Name": "SOUTHERN MARYLAND AMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 PAUL MELLON CT WALDORF MD 20602-9997 USA "
    },
    {
      "Location ID": "20601",
      "Location Name": "WALDORF CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 PAUL MELLON CT WALDORF MD 20602 USA "
    },
    {
      "Location ID": "20602",
      "Location Name": "WALDORF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 POST OFFICE RD WALDORF MD 20602-9998 USA "
    },
    {
      "Location ID": "20606",
      "Location Name": "ABELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21388 ABELL RD ABELL MD 20606 USA "
    },
    {
      "Location ID": "20609",
      "Location Name": "AVENUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21535 COLTON POINT RD AVENUE MD 20609 USA "
    },
    {
      "Location ID": "20610",
      "Location Name": "BARSTOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2485 HALLOWING POINT RD BARSTOW MD 20610 USA "
    },
    {
      "Location ID": "20611",
      "Location Name": "BEL ALTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9695 BEL ALTON NEWTOWN RD BEL ALTON MD 20611 USA "
    },
    {
      "Location ID": "20612",
      "Location Name": "BENEDICT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7240 BENEDICT AVE BENEDICT MD 20612 USA "
    },
    {
      "Location ID": "20613",
      "Location Name": "BRANDYWINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14109 BRANDYWINE RD BRANDYWINE MD 20613 USA "
    },
    {
      "Location ID": "20617",
      "Location Name": "BRYANTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6425 LEONARDTOWN RD BRYANTOWN MD 20617 USA "
    },
    {
      "Location ID": "20618",
      "Location Name": "BUSHWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22840 BUSHWOOD RD BUSHWOOD MD 20618 USA "
    },
    {
      "Location ID": "20619",
      "Location Name": "CALIFORNIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22576 MACARTHUR BLVD STE 330 CALIFORNIA MD 20619 USA "
    },
    {
      "Location ID": "20620",
      "Location Name": "CALLAWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20293 PINEY POINT RD CALLAWAY MD 20620 USA "
    },
    {
      "Location ID": "20621",
      "Location Name": "CHAPTICO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25749 BUDDS CREEK RD CHAPTICO MD 20621 USA "
    },
    {
      "Location ID": "20622",
      "Location Name": "CHARLOTTE HALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29948 THREE NOTCH RD CHARLOTTE HALL MD 20622 USA "
    },
    {
      "Location ID": "20623",
      "Location Name": "CHELTENHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11505 CHERRY TREE CROSSING RD CHELTENHAM MD 20623 USA "
    },
    {
      "Location ID": "20624",
      "Location Name": "CLEMENTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24015 COLTON POINT RD CLEMENTS MD 20624 USA "
    },
    {
      "Location ID": "20625",
      "Location Name": "COBB ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17009 COBB ISLAND RD COBB ISLAND MD 20625 USA "
    },
    {
      "Location ID": "20626",
      "Location Name": "COLTONS POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20254 COLTON POINT RD COLTONS POINT MD 20626 USA "
    },
    {
      "Location ID": "20627",
      "Location Name": "COMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22173 BAYSIDE RD COMPTON MD 20627 USA "
    },
    {
      "Location ID": "20628",
      "Location Name": "DAMERON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17679 THREE NOTCH RD DAMERON MD 20628 USA "
    },
    {
      "Location ID": "20629",
      "Location Name": "DOWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14515 DOWELL RD DOWELL MD 20629 USA "
    },
    {
      "Location ID": "20630",
      "Location Name": "DRAYDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18552 CHERRYFIELD RD DRAYDEN MD 20630 USA "
    },
    {
      "Location ID": "20632",
      "Location Name": "FAULKNER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9977 FAULKNER RD FAULKNER MD 20632 USA "
    },
    {
      "Location ID": "20634",
      "Location Name": "GREAT MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20210 POINT LOOKOUT RD GREAT MILLS MD 20634 USA "
    },
    {
      "Location ID": "20636",
      "Location Name": "HOLLYWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24351 MERVELL DEAN RD HOLLYWOOD MD 20636 USA "
    },
    {
      "Location ID": "20637",
      "Location Name": "HUGHESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15485 PRINCE FREDERICK RD HUGHESVILLE MD 20637 USA "
    },
    {
      "Location ID": "20639",
      "Location Name": "HUNTINGTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4020 OLD TOWN RD HUNTINGTOWN MD 20639 USA "
    },
    {
      "Location ID": "20645",
      "Location Name": "ISSUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15800 COBB ISLAND RD ISSUE MD 20645 USA "
    },
    {
      "Location ID": "20646",
      "Location Name": "LA PLATA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 CENTENNIAL ST LA PLATA MD 20646 USA "
    },
    {
      "Location ID": "20650",
      "Location Name": "LEONARDTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22735 WASHINGTON ST LEONARDTOWN MD 20650 USA "
    },
    {
      "Location ID": "20653",
      "Location Name": "LEXINGTON PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21745 S CORAL DR LEXINGTON PARK MD 20653 USA "
    },
    {
      "Location ID": "20656",
      "Location Name": "LOVEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27770 POINT LOOKOUT RD LOVEVILLE MD 20656 USA "
    },
    {
      "Location ID": "20657",
      "Location Name": "LUSBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11575 LUSBY LN LUSBY MD 20657 USA "
    },
    {
      "Location ID": "20659",
      "Location Name": "MECHANICSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28948 THREE NOTCH RD MECHANICSVILLE MD 20659 USA "
    },
    {
      "Location ID": "20660",
      "Location Name": "MORGANZA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39600 DOCTOR JOHNSON RD MORGANZA MD 20660 USA "
    },
    {
      "Location ID": "20661",
      "Location Name": "MOUNT VICTORIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12085 MOUNT VICTORIA RD MOUNT VICTORIA MD 20661 USA "
    },
    {
      "Location ID": "20662",
      "Location Name": "NANJEMOY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9365 BEAVERDAM RD NANJEMOY MD 20662 USA "
    },
    {
      "Location ID": "20664",
      "Location Name": "NEWBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12179 ROCK POINT RD NEWBURG MD 20664 USA "
    },
    {
      "Location ID": "20667",
      "Location Name": "PARK HALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18400 POINT LOOKOUT RD PARK HALL MD 20667 USA "
    },
    {
      "Location ID": "20670",
      "Location Name": "PATUXENT RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 459 CEDAR POINT RD PATUXENT RIVER MD 20670 USA "
    },
    {
      "Location ID": "20674",
      "Location Name": "PINEY POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17160 PINEY POINT RD PINEY POINT MD 20674 USA "
    },
    {
      "Location ID": "20675",
      "Location Name": "POMFRET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8205 MARSHALL CORNER RD POMFRET MD 20675 USA "
    },
    {
      "Location ID": "20676",
      "Location Name": "PORT REPUBLIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3000 SAINT LEONARD RD PORT REPUBLIC MD 20676 USA "
    },
    {
      "Location ID": "20677",
      "Location Name": "PORT TOBACCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8200 PORT TOBACCO RD PORT TOBACCO MD 20677 USA "
    },
    {
      "Location ID": "20678",
      "Location Name": "PRINCE FREDERICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 DUKE ST PRINCE FREDERICK MD 20678 USA "
    },
    {
      "Location ID": "20680",
      "Location Name": "RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13274 POINT LOOKOUT RD RIDGE MD 20680 USA "
    },
    {
      "Location ID": "20684",
      "Location Name": "SAINT INIGOES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15066 POINT LOOKOUT RD SAINT INIGOES MD 20684 USA "
    },
    {
      "Location ID": "20685",
      "Location Name": "SAINT LEONARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4244 SAINT LEONARD RD STE 100 SAINT LEONARD MD 20685 USA "
    },
    {
      "Location ID": "20686",
      "Location Name": "SAINT MARYS CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16855 POINT LOOKOUT RD SAINT MARYS CITY MD 20686 USA "
    },
    {
      "Location ID": "20688",
      "Location Name": "SOLOMONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13946 HG TRUEMAN RD SOLOMONS MD 20688 USA "
    },
    {
      "Location ID": "20692",
      "Location Name": "VALLEY LEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 45150 BLAKE CREEK RD VALLEY LEE MD 20692 USA "
    },
    {
      "Location ID": "20693",
      "Location Name": "WELCOME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6204 WELCOME RD WELCOME MD 20693 USA "
    },
    {
      "Location ID": "20695",
      "Location Name": "WHITE PLAINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4660 CRAIN HWY WHITE PLAINS MD 20695 USA "
    },
    {
      "Location ID": "206M1",
      "Location Name": "DIRECT MAIL MANAGEMENT INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5511 KETCH RD PRINCE FREDERICK MD 20678 USA "
    },
    {
      "Location ID": "207",
      "Location Name": "LOG SOUTHERN MARYLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9201 EDGEWORTH DR CAPITOL HEIGHTS MD 20790 USA "
    },
    {
      "Location ID": "20701",
      "Location Name": "ANNAPOLIS JUNCTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10820 GUILFORD RD STE 212 ANNAPOLIS JUNCTION MD 20701 USA "
    },
    {
      "Location ID": "20707",
      "Location Name": "LAUREL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 324 MAIN ST LAUREL MD 20707 USA "
    },
    {
      "Location ID": "20711",
      "Location Name": "LOTHIAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5471 SOUTHERN MARYLAND BLVD LOTHIAN MD 20711 USA "
    },
    {
      "Location ID": "20714",
      "Location Name": "NORTH BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9132A BAY AVE NORTH BEACH MD 20714 USA "
    },
    {
      "Location ID": "20732",
      "Location Name": "CHESAPEAKE BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7905 BAYSIDE RD CHESAPEAKE BEACH MD 20732 USA "
    },
    {
      "Location ID": "20733",
      "Location Name": "CHURCHTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5537 DEALE CHURCHTON RD CHURCHTON MD 20733 USA "
    },
    {
      "Location ID": "20735",
      "Location Name": "CLINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9134 PISCATAWAY RD CLINTON MD 20735 USA "
    },
    {
      "Location ID": "20736",
      "Location Name": "OWINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9440 OLD SOLOMONS ISLAND RD OWINGS MD 20736 USA "
    },
    {
      "Location ID": "20751",
      "Location Name": "DEALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 650 DEALE RD DEALE MD 20751 USA "
    },
    {
      "Location ID": "20754",
      "Location Name": "DUNKIRK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10383 SOUTHERN MARYLAND BLVD DUNKIRK MD 20754 USA "
    },
    {
      "Location ID": "20755",
      "Location Name": "FORT GEORGE G MEADE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2789 MACARTHUR RD FORT GEORGE G MEADE MD 20755 USA "
    },
    {
      "Location ID": "20758",
      "Location Name": "FRIENDSHIP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 FRIENDSHIP RD W FRIENDSHIP MD 20758 USA "
    },
    {
      "Location ID": "20759",
      "Location Name": "FULTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11831 SCAGGSVILLE RD FULTON MD 20759 USA "
    },
    {
      "Location ID": "20763",
      "Location Name": "SAVAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8926 BALTIMORE ST SAVAGE MD 20763 USA "
    },
    {
      "Location ID": "20764",
      "Location Name": "SHADY SIDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6160 SHADY SIDE RD SHADY SIDE MD 20764 USA "
    },
    {
      "Location ID": "20765",
      "Location Name": "GALESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 948 GALESVILLE RD GALESVILLE MD 20765 USA "
    },
    {
      "Location ID": "20776",
      "Location Name": "HARWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4450 SOLOMONS ISLAND RD HARWOOD MD 20776 USA "
    },
    {
      "Location ID": "20777",
      "Location Name": "HIGHLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12975 HIGHLAND RD HIGHLAND MD 20777 USA "
    },
    {
      "Location ID": "20779",
      "Location Name": "TRACYS LANDING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5889 SOLOMONS ISLAND RD TRACYS LANDING MD 20779 USA "
    },
    {
      "Location ID": "20790",
      "Location Name": "HAMPTON PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9201 EDGEWORTH DR CAPITOL HEIGHTS MD 20790 USA "
    },
    {
      "Location ID": "20794",
      "Location Name": "JESSUP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2851 JESSUP RD JESSUP MD 20794 USA "
    },
    {
      "Location ID": "207AN",
      "Location Name": "LOG CAPITAL BELTWAY FACILITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9151 EDGEWORTH DR CAPITOL HEIGHTS MD 20790 USA "
    },
    {
      "Location ID": "207CA",
      "Location Name": "CAPITAL METRO STC CMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3300 MARLO LN FORESTVILLE MD 20747 USA "
    },
    {
      "Location ID": "207MB",
      "Location Name": "BARTON COTTON GRAPHICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3030 WATERVIEW AVE STE 100 BALTIMORE MD 21230 USA "
    },
    {
      "Location ID": "207OP",
      "Location Name": "CORPORATE PRESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9700 PHILADELPHIA CT LANHAM MD 20706 USA "
    },
    {
      "Location ID": "207PB",
      "Location Name": "CPU PEAK BUSINESS SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14440 CHERRY LANE CT STE 117 LAUREL MD 20707 USA "
    },
    {
      "Location ID": "207ZM",
      "Location Name": "ZIP MAILING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6304 SHERIFF RD STE Z HYATTSVILLE MD 20785 USA "
    },
    {
      "Location ID": "208",
      "Location Name": "LOG SUBURBAN MARYLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16501 SHADY GROVE RD GAITHERSBURG MD 20898 USA "
    },
    {
      "Location ID": "20832",
      "Location Name": "OLNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3570 OLNEY LAYTONSVILLE RD OLNEY MD 20832 USA "
    },
    {
      "Location ID": "20833",
      "Location Name": "BROOKEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22311 GEORGIA AVE BROOKEVILLE MD 20833 USA "
    },
    {
      "Location ID": "20860",
      "Location Name": "SANDY SPRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 901 OLNEY SANDY SPRING RD SANDY SPRING MD 20860 USA "
    },
    {
      "Location ID": "20861",
      "Location Name": "ASHTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 OLNEY SANDY SPRING RD ASHTON MD 20861 USA "
    },
    {
      "Location ID": "20866",
      "Location Name": "BURTONSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15210 DINO DR BURTONSVILLE MD 20866 USA "
    },
    {
      "Location ID": "20868",
      "Location Name": "SPENCERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2323 SPENCERVILLE RD SPENCERVILLE MD 20868 USA "
    },
    {
      "Location ID": "208AN",
      "Location Name": "SUBURBAN MARYLAND ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15813 GAITHER DR GAITHERSBURG MD 20877 USA "
    },
    {
      "Location ID": "20H",
      "Location Name": "CAPITAL METRO STC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14301 MATTAWOMAN DR BRANDYWINE MD 20613 USA "
    },
    {
      "Location ID": "20Z",
      "Location Name": "LOG WASHINGTON NDC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9201 EDGEWORTH DR CAPITOL HEIGHTS MD 20799 USA "
    },
    {
      "Location ID": "210",
      "Location Name": "LINTHICUM P&DC MD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 961 CORPORATE BLVD LINTHICUM MD 21090 USA "
    },
    {
      "Location ID": "21001",
      "Location Name": "ABERDEEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 W BEL AIR AVE ABERDEEN MD 21001 USA "
    },
    {
      "Location ID": "21005",
      "Location Name": "ABERDEEN PROVING GROUND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4510 BOOTHBY HILL AVE ABERDEEN PROVIN MD 21005 USA "
    },
    {
      "Location ID": "21009",
      "Location Name": "ABINGDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2945 EMMORTON RD ABINGDON MD 21009 USA "
    },
    {
      "Location ID": "21012",
      "Location Name": "ARNOLD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1521 RITCHIE HWY STE 100 ARNOLD MD 21012 USA "
    },
    {
      "Location ID": "21013",
      "Location Name": "BALDWIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13516 LONG GREEN PIKE BALDWIN MD 21013 USA "
    },
    {
      "Location ID": "21014",
      "Location Name": "BEL AIR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 BLUM CT BEL AIR MD 21014 USA "
    },
    {
      "Location ID": "21017",
      "Location Name": "BELCAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4405 PULASKI HWY STE A BELCAMP MD 21017 USA "
    },
    {
      "Location ID": "21018",
      "Location Name": "BENSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 CONNOLLY RD BENSON MD 21018 USA "
    },
    {
      "Location ID": "21020",
      "Location Name": "BORING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14707 OLD HANOVER RD BORING MD 21020 USA "
    },
    {
      "Location ID": "21022",
      "Location Name": "BROOKLANDVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10807 FALLS RD BROOKLANDVILLE MD 21022 USA "
    },
    {
      "Location ID": "21023",
      "Location Name": "BUTLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14921 FALLS RD BUTLER MD 21023 USA "
    },
    {
      "Location ID": "21028",
      "Location Name": "CHURCHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2931 CHURCHVILLE RD CHURCHVILLE MD 21028 USA "
    },
    {
      "Location ID": "21029",
      "Location Name": "CLARKSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12171 CLARKSVILLE PIKE CLARKSVILLE MD 21029 USA "
    },
    {
      "Location ID": "21030",
      "Location Name": "COCKEYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 WIGHT AVE COCKEYSVILLE MD 21030 USA "
    },
    {
      "Location ID": "21032",
      "Location Name": "CROWNSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1333 GENERALS HWY CROWNSVILLE MD 21032 USA "
    },
    {
      "Location ID": "21034",
      "Location Name": "DARLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1113 MAIN ST DARLINGTON MD 21034 USA "
    },
    {
      "Location ID": "21035",
      "Location Name": "DAVIDSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 820 W CENTRAL AVE DAVIDSONVILLE MD 21035 USA "
    },
    {
      "Location ID": "21036",
      "Location Name": "DAYTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4924A 10 OAKS RD DAYTON MD 21036 USA "
    },
    {
      "Location ID": "21037",
      "Location Name": "EDGEWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 STEPNEY LN EDGEWATER MD 21037 USA "
    },
    {
      "Location ID": "21040",
      "Location Name": "EDGEWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2290 HANSON RD EDGEWOOD MD 21040 USA "
    },
    {
      "Location ID": "21042",
      "Location Name": "ENCHANTED FOREST STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10020 BALTIMORE NATIONAL PIKE ELLICOTT CITY MD 21042 USA "
    },
    {
      "Location ID": "21043",
      "Location Name": "ELLICOTT CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3375 ELLICOTT CENTER DR ELLICOTT CITY MD 21043 USA "
    },
    {
      "Location ID": "21045",
      "Location Name": "COLUMBIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6801 OAK HALL LN COLUMBIA MD 21045 USA "
    },
    {
      "Location ID": "21047",
      "Location Name": "FALLSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2416 WATERVALE RD FALLSTON MD 21047 USA "
    },
    {
      "Location ID": "21048",
      "Location Name": "FINKSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3000 GAMBER RD STE 13 FINKSBURG MD 21048 USA "
    },
    {
      "Location ID": "21050",
      "Location Name": "FOREST HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2329 ROCK SPRING RD FOREST HILL MD 21050 USA "
    },
    {
      "Location ID": "21051",
      "Location Name": "FORK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12544 HARFORD RD FORK MD 21051 USA "
    },
    {
      "Location ID": "21053",
      "Location Name": "FREELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21600 NEW FREEDOM RD FREELAND MD 21053 USA "
    },
    {
      "Location ID": "21054",
      "Location Name": "GAMBRILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 388 GAMBRILLS RD GAMBRILLS MD 21054 USA "
    },
    {
      "Location ID": "21057",
      "Location Name": "GLEN ARM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12112 LONG GREEN PIKE GLEN ARM MD 21057 USA "
    },
    {
      "Location ID": "21060",
      "Location Name": "GLEN BURNIE FINANCE STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7728 RITCHIE HWY GLEN BURNIE MD 21060 USA "
    },
    {
      "Location ID": "21061",
      "Location Name": "GLEN BURNIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7596 RITCHIE HWY GLEN BURNIE MD 21061 USA "
    },
    {
      "Location ID": "21071",
      "Location Name": "GLYNDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 RAILROAD AVE GLYNDON MD 21071 USA "
    },
    {
      "Location ID": "21074",
      "Location Name": "HAMPSTEAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4005 HOUCK AVE HAMPSTEAD MD 21074 USA "
    },
    {
      "Location ID": "21075",
      "Location Name": "ELKRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6655 SANTA BARBARA RD ELKRIDGE MD 21075 USA "
    },
    {
      "Location ID": "21076",
      "Location Name": "HANOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7476 NEW RIDGE RD STE G HANOVER MD 21076 USA "
    },
    {
      "Location ID": "21078",
      "Location Name": "HAVRE DE GRACE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 N JUNIATA ST HAVRE DE GRACE MD 21078 USA "
    },
    {
      "Location ID": "21082",
      "Location Name": "HYDES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5237 HYDES RD HYDES MD 21082 USA "
    },
    {
      "Location ID": "21084",
      "Location Name": "JARRETTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3713 FEDERAL HILL RD JARRETTSVILLE MD 21084 USA "
    },
    {
      "Location ID": "21085",
      "Location Name": "JOPPA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 906 JOPPA FARM RD JOPPA MD 21085 USA "
    },
    {
      "Location ID": "21087",
      "Location Name": "KINGSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7363 SUNSHINE AVE KINGSVILLE MD 21087 USA "
    },
    {
      "Location ID": "21090",
      "Location Name": "LINTHICUM HEIGHTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 515 S CAMP MEADE RD LINTHICUM HEIGHTS MD 21090 USA "
    },
    {
      "Location ID": "21093",
      "Location Name": "LUTHERVILLE-TIMONIUM DDU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9603 DEERECO RD LUTHERVILLE TIM MD 21093 USA "
    },
    {
      "Location ID": "210AB",
      "Location Name": "ARBBLANKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 INDUSTRY LN FOREST HILL MD 21050 USA "
    },
    {
      "Location ID": "210AZ",
      "Location Name": "AUTOZONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7462 NEW RIDGE RD HANOVER MD 21076 USA "
    },
    {
      "Location ID": "210BB",
      "Location Name": "BLUE CROSS BLUE SHIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9100 GUILFORD RD COLUMBIA MD 21046 USA "
    },
    {
      "Location ID": "210BS",
      "Location Name": "BRITE STAR BUSINESS SOLUTIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1305 GOVERNORS CT ABINGDON MD 21009 USA "
    },
    {
      "Location ID": "210CH",
      "Location Name": "CARDINAL HEALTH SPLTY PHARMACY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7172 COLUMBIA GATEWAY DR COLUMBIA MD 21046 USA "
    },
    {
      "Location ID": "210DB",
      "Location Name": "DAEDALUS BOOKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9645 GERWIG LN COLUMBIA MD 21046 USA "
    },
    {
      "Location ID": "210DH",
      "Location Name": "DHL GLOBAL MAIL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6670 BUSINESS PKWY STE 103 ELKRIDGE MD 21075 USA "
    },
    {
      "Location ID": "210JE",
      "Location Name": "JACKS SMALL ENGINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 INDUSTRY LN FOREST HILL MD 21050 USA "
    },
    {
      "Location ID": "210JH",
      "Location Name": "JOHN H HARLAND CO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1904 PARK 100 DR GLEN BURNIE MD 21061 USA "
    },
    {
      "Location ID": "210NP",
      "Location Name": "NATIONS PHOTO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11103 PEPPER RD HUNT VALLEY MD 21031 USA "
    },
    {
      "Location ID": "210SE",
      "Location Name": "SEPHORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 531 CHELSEA RD ABERDEEN MD 21001 USA "
    },
    {
      "Location ID": "210TE",
      "Location Name": "TESSCO TECHNOLOGIES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11126 MCCORMICK RD HUNT VALLEY MD 21031 USA "
    },
    {
      "Location ID": "210TS",
      "Location Name": "TESSCO TECHNOLOGIES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10999 MCCORMICK RD HUNT VALLEY MD 21031 USA "
    },
    {
      "Location ID": "210UP",
      "Location Name": "ULLA POPKIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12201 LONG GREEN PIKE GLEN ARM MD 21057 USA "
    },
    {
      "Location ID": "211",
      "Location Name": "LINTHICUM IMF P&DF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 961 CORPORATE BLVD LINTHICUM HEIGHTS MD 21090 USA "
    },
    {
      "Location ID": "21102",
      "Location Name": "MANCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3100 WESTMINSTER ST MANCHESTER MD 21102 USA "
    },
    {
      "Location ID": "21105",
      "Location Name": "MARYLAND LINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21400 YORK RD MARYLAND LINE MD 21105 USA "
    },
    {
      "Location ID": "21106",
      "Location Name": "MAYO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1157 MAYO RD MAYO MD 21106 USA "
    },
    {
      "Location ID": "21108",
      "Location Name": "MILLERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 221 NAJOLES RD MILLERSVILLE MD 21108 USA "
    },
    {
      "Location ID": "21111",
      "Location Name": "MONKTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16944 YORK RD MONKTON MD 21111 USA "
    },
    {
      "Location ID": "21113",
      "Location Name": "ODENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1177 ANNAPOLIS RD ODENTON MD 21113 USA "
    },
    {
      "Location ID": "21114",
      "Location Name": "CROFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1296 CRONSON BLVD CROFTON MD 21114 USA "
    },
    {
      "Location ID": "21117",
      "Location Name": "OWINGS MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10821 RED RUN BLVD OWINGS MILLS MD 21117 USA "
    },
    {
      "Location ID": "21120",
      "Location Name": "PARKTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 645 HAPPY LN PARKTON MD 21120 USA "
    },
    {
      "Location ID": "21122",
      "Location Name": "PASADENA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4301 MOUNTAIN RD PASADENA MD 21122 USA "
    },
    {
      "Location ID": "21128",
      "Location Name": "PERRY HALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8744 BELAIR RD PERRY HALL MD 21128 USA "
    },
    {
      "Location ID": "21130",
      "Location Name": "PERRYMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 428 MICHAELSVILLE RD PERRYMAN MD 21130 USA "
    },
    {
      "Location ID": "21131",
      "Location Name": "PHOENIX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14320 JARRETTSVILLE PIKE PHOENIX MD 21131 USA "
    },
    {
      "Location ID": "21132",
      "Location Name": "PYLESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3965 ADY RD PYLESVILLE MD 21132 USA "
    },
    {
      "Location ID": "21133",
      "Location Name": "RANDALLSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3608 OFFUTT RD RANDALLSTOWN MD 21133 USA "
    },
    {
      "Location ID": "21136",
      "Location Name": "REISTERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 GLYNDON DR REISTERSTOWN MD 21136 USA "
    },
    {
      "Location ID": "21139",
      "Location Name": "RIDERWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8016 BELLONA AVE RIDERWOOD MD 21139 USA "
    },
    {
      "Location ID": "21140",
      "Location Name": "RIVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3123 RIVA RD RIVA MD 21140 USA "
    },
    {
      "Location ID": "21144",
      "Location Name": "SEVERN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 819 REECE RD SEVERN MD 21144 USA "
    },
    {
      "Location ID": "21146",
      "Location Name": "SEVERNA PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 MAGOTHY BRIDGE RD SEVERNA PARK MD 21146 USA "
    },
    {
      "Location ID": "21150",
      "Location Name": "SIMPSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10400 SHAKER DR SIMPSONVILLE MD 21150 USA "
    },
    {
      "Location ID": "21152",
      "Location Name": "SPARKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14800 YORK RD SPARKS GLENCOE MD 21152 USA "
    },
    {
      "Location ID": "21153",
      "Location Name": "STEVENSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10421 STEVENSON RD STEVENSON MD 21153 USA "
    },
    {
      "Location ID": "21154",
      "Location Name": "STREET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 708 HIGHLAND RD REAR STREET MD 21154 USA "
    },
    {
      "Location ID": "21155",
      "Location Name": "UPPERCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5420 ARCADIA AVE UPPERCO MD 21155 USA "
    },
    {
      "Location ID": "21156",
      "Location Name": "UPPER FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7755 BRADSHAW RD UPPER FALLS MD 21156 USA "
    },
    {
      "Location ID": "21157",
      "Location Name": "WESTMINSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 345 WOODWARD RD WESTMINSTER MD 21157 USA "
    },
    {
      "Location ID": "21160",
      "Location Name": "WHITEFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1508 MAIN ST STE 4 WHITEFORD MD 21160 USA "
    },
    {
      "Location ID": "21161",
      "Location Name": "WHITE HALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1415 WISEBURG RD WHITE HALL MD 21161 USA "
    },
    {
      "Location ID": "21162",
      "Location Name": "WHITE MARSH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5422 EBENEZER RD WHITE MARSH MD 21162 USA "
    },
    {
      "Location ID": "21163",
      "Location Name": "WOODSTOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10805 BIRMINGHAM WAY STE 25 WOODSTOCK MD 21163 USA "
    },
    {
      "Location ID": "211AG",
      "Location Name": "AGORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 LOVETON CIR STE 100 SPARKS MD 21152 USA "
    },
    {
      "Location ID": "211CF",
      "Location Name": "CAREFIRST BC BS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10455 MILL RUN CIR OWINGS MILLS MD 21117 USA "
    },
    {
      "Location ID": "211DF",
      "Location Name": "DFTEES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 47 LOVETON CIR SPARKS MD 21163 USA "
    },
    {
      "Location ID": "211HB",
      "Location Name": "HATS IN THE BELFRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 407 HEADQUARTERS DR MILLERSVILLE MD 21108 USA "
    },
    {
      "Location ID": "211MB",
      "Location Name": "MAGOTHY BRIDGE DDU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 MAGOTHY BRIDGE RD SEVERNA PARK MD 21146 USA "
    },
    {
      "Location ID": "211NG",
      "Location Name": "NAVY GEAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8211 CLOVERLEAF DRIVE FRNT B MILLERSVILLE MD 21108 USA "
    },
    {
      "Location ID": "211PB",
      "Location Name": "PITNEY BOWES - ODENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1781 CROSSROADS DR STE 100 ODENTON MD 21113 USA "
    },
    {
      "Location ID": "211RA",
      "Location Name": "RANDOM HOUSE INC (2)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 BENNETT CERF DR WESTMINSTER MD 21157 USA "
    },
    {
      "Location ID": "211RH",
      "Location Name": "RANDOM HOUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 HAHN RD WESTMINSTER MD 21157 USA "
    },
    {
      "Location ID": "211SS",
      "Location Name": "THE SUSPENDER STORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 405 HEADQUARTERS DRIVE MILLERSVILLE MD 21108 USA "
    },
    {
      "Location ID": "212",
      "Location Name": "BALTIMORE P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 900 E FAYETTE ST RM 505 BALTIMORE MD 21233 USA "
    },
    {
      "Location ID": "212AN",
      "Location Name": "Baltimore Christmas",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7481 COCA COLA DR. HANOVER MD 21076 USA "
    },
    {
      "Location ID": "212AO",
      "Location Name": "AMAZON - MTN9",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5300 NOTTINGHAM DR NOTTINGHAM MD 21236 USA "
    },
    {
      "Location ID": "212FX",
      "Location Name": "212FX FEDEX SHIP CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1005 AIR CARGO DR BLDG 4 #34 BALTIMORE MD 21240 USA "
    },
    {
      "Location ID": "212FZ",
      "Location Name": "BALTIMORE MD PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4545 ANNAPOLIS RD STE C HALETHORPE MD 21227 USA "
    },
    {
      "Location ID": "212MS",
      "Location Name": "CORPORATE MAILING SERVICES INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1625 KNECHT AVENUE BALTIMORE MD 21227 USA "
    },
    {
      "Location ID": "21401",
      "Location Name": "ANNAPOLIS LEGION AVE STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 LEGION AVE ANNAPOLIS MD 21401 USA "
    },
    {
      "Location ID": "21403",
      "Location Name": "ANNAPOLIS CHESAPEAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 821 CHESAPEAKE AVE ANNAPOLIS MD 21403 USA "
    },
    {
      "Location ID": "214AA",
      "Location Name": "ANNAPOLIS DDU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 ADMIRAL COCHRANE DR ANNAPOLIS MD 21401 USA "
    },
    {
      "Location ID": "214AC",
      "Location Name": "ANNAPOLIS LEGION AVE STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 ADMIRAL COCHRANE DR ANNAPOLIS MD 21401 USA "
    },
    {
      "Location ID": "214DU",
      "Location Name": "ANNE ARUNDEL DDU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 195 ADMIRAL COCHRAN DR ANNAPOLIS MD 21401 USA "
    },
    {
      "Location ID": "214ZY",
      "Location Name": "ANNAPOLIS EASTPORT BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 821 CHESAPEAKE AVENUE ANNAPOLIS MD 21403 USA "
    },
    {
      "Location ID": "215",
      "Location Name": "CUMBERLAND  POST OFFICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 PARK ST CUMBERLAND MD 21502 USA "
    },
    {
      "Location ID": "21502",
      "Location Name": "CUMBERLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 PARK ST CUMBERLAND MD 21502 USA "
    },
    {
      "Location ID": "21520",
      "Location Name": "ACCIDENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 SOUTH ST ACCIDENT MD 21520 USA "
    },
    {
      "Location ID": "21521",
      "Location Name": "BARTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19112 S RAILROAD ST SW BARTON MD 21521 USA "
    },
    {
      "Location ID": "21522",
      "Location Name": "BITTINGER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 MAYNARDIER RIDGE RD BITTINGER MD 21522 USA "
    },
    {
      "Location ID": "21523",
      "Location Name": "BLOOMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 N HAMILL AVE BLOOMINGTON MD 21523 USA "
    },
    {
      "Location ID": "21524",
      "Location Name": "CORRIGANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10810 KREIGHBAUM RD CORRIGANVILLE MD 21524 USA "
    },
    {
      "Location ID": "21528",
      "Location Name": "ECKHART MINES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10501 ECKHART MINES ST ECKHART MINES MD 21528 USA "
    },
    {
      "Location ID": "21529",
      "Location Name": "ELLERSLIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14302 ELLERSLIE RD NW ELLERSLIE MD 21529 USA "
    },
    {
      "Location ID": "21531",
      "Location Name": "FRIENDSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 836 1ST AVE FRIENDSVILLE MD 21531 USA "
    },
    {
      "Location ID": "21532",
      "Location Name": "FROSTBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37 W MAIN ST FROSTBURG MD 21532 USA "
    },
    {
      "Location ID": "21536",
      "Location Name": "GRANTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 159 MAIN ST GRANTSVILLE MD 21536 USA "
    },
    {
      "Location ID": "21538",
      "Location Name": "KITZMILLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 W CENTER ST KITZMILLER MD 21538 USA "
    },
    {
      "Location ID": "21539",
      "Location Name": "LONACONING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 E MAIN ST LONACONING MD 21539 USA "
    },
    {
      "Location ID": "21541",
      "Location Name": "MC HENRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1914 DEEP CREEK DR MC HENRY MD 21541 USA "
    },
    {
      "Location ID": "21543",
      "Location Name": "MIDLOTHIAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19911 OLD MIDLOTHIAN RD SW MIDLOTHIAN MD 21543 USA "
    },
    {
      "Location ID": "21545",
      "Location Name": "MOUNT SAVAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12700 NEW SCHOOL RD NW MOUNT SAVAGE MD 21545 USA "
    },
    {
      "Location ID": "21550",
      "Location Name": "OAKLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22 S 2ND ST OAKLAND MD 21550 USA "
    },
    {
      "Location ID": "21555",
      "Location Name": "OLDTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19104 OPESSA ST SE OLDTOWN MD 21555 USA "
    },
    {
      "Location ID": "21556",
      "Location Name": "PINTO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16111 MCMULLEN HWY PINTO MD 21556 USA "
    },
    {
      "Location ID": "21557",
      "Location Name": "RAWLINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19000 MCMULLEN HWY SW RAWLINGS MD 21557 USA "
    },
    {
      "Location ID": "21560",
      "Location Name": "SPRING GAP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13400 OLDTOWN RD SPRING GAP MD 21560 USA "
    },
    {
      "Location ID": "21561",
      "Location Name": "SWANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3320 SWANTON RD SWANTON MD 21561 USA "
    },
    {
      "Location ID": "21562",
      "Location Name": "WESTERNPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 72 MAIN ST WESTERNPORT MD 21562 USA "
    },
    {
      "Location ID": "215ML",
      "Location Name": "MOUNTAIN LAKE PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1325 MARYLAND HWY OAKLAND MD 21550 USA "
    },
    {
      "Location ID": "215ZY",
      "Location Name": "STATION A",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 VIRGINIA AVE CUMBERLAND MD 21503 USA "
    },
    {
      "Location ID": "216",
      "Location Name": "EASTERN SHORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29060 AIRPARK DRIVE EASTON MD 21601 USA "
    },
    {
      "Location ID": "21601",
      "Location Name": "EASTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 E DOVER ST EASTON MD 21601-9998 USA "
    },
    {
      "Location ID": "21607",
      "Location Name": "BARCLAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1325 GOLDSBORO RD BARCLAY MD 21609-9998 USA "
    },
    {
      "Location ID": "21609",
      "Location Name": "BETHLEHEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22015 DOVER BRIDGE RD BETHLEHEM MD 21609-9998 USA "
    },
    {
      "Location ID": "21610",
      "Location Name": "BETTERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 3RD AVE BETTERTON MD 21610-9998 USA "
    },
    {
      "Location ID": "21612",
      "Location Name": "BOZMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7983 QUAKER NECK RD BOZMAN MD 21612-9998 USA "
    },
    {
      "Location ID": "21613",
      "Location Name": "CAMBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 HIGH ST CAMBRIDGE MD 21613 USA "
    },
    {
      "Location ID": "21617",
      "Location Name": "CENTREVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 E WATER ST CENTREVILLE MD 21617-9998 USA "
    },
    {
      "Location ID": "21619",
      "Location Name": "CHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1575 POSTAL RD CHESTER MD 21619-9998 USA "
    },
    {
      "Location ID": "21620",
      "Location Name": "CHESTERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 SPRING AVE CHESTERTOWN MD 21620-9998 USA "
    },
    {
      "Location ID": "21623",
      "Location Name": "CHURCH HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 558 MAIN ST CHURCH HILL MD 21623-9998 USA "
    },
    {
      "Location ID": "21625",
      "Location Name": "CORDOVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11836 KITTYS CORNER RD CORDOVA MD 21625-9998 USA "
    },
    {
      "Location ID": "21628",
      "Location Name": "CRUMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 213 BROAD ST CRUMPTON MD 21628-9998 USA "
    },
    {
      "Location ID": "21629",
      "Location Name": "DENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 503 MARKET ST DENTON MD 21629-9998 USA "
    },
    {
      "Location ID": "21631",
      "Location Name": "EAST NEW MARKET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 RAILROAD AVE EAST NEW MARKET MD 21631-9998 USA "
    },
    {
      "Location ID": "21632",
      "Location Name": "FEDERALSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 122 MORRIS AVE FEDERALSBURG MD 21632-9998 USA "
    },
    {
      "Location ID": "21635",
      "Location Name": "GALENA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 124 W CROSS ST GALENA MD 21635-9998 USA "
    },
    {
      "Location ID": "21636",
      "Location Name": "GOLDSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 507 OLDTOWN RD GOLDSBORO MD 21636-9998 USA "
    },
    {
      "Location ID": "21638",
      "Location Name": "GRASONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4307 MAIN ST GRASONVILLE MD 21638-9998 USA "
    },
    {
      "Location ID": "21639",
      "Location Name": "GREENSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 W SUNSET AVE GREENSBORO MD 21639-9998 USA "
    },
    {
      "Location ID": "21640",
      "Location Name": "HENDERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 321 BEE TREE RD HENDERSON MD 21640-9998 USA "
    },
    {
      "Location ID": "21641",
      "Location Name": "HILLSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22043 MAIN ST HILLSBORO MD 21641-9998 USA "
    },
    {
      "Location ID": "21643",
      "Location Name": "HURLOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 POPLAR ST HURLOCK MD 21643-9998 USA "
    },
    {
      "Location ID": "21644",
      "Location Name": "INGLESIDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2626 ROBERTS STATION RD INGLESIDE MD 21644-9998 USA "
    },
    {
      "Location ID": "21645",
      "Location Name": "KENNEDYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11959 AUGUSTINE HERMAN HWY KENNEDYVILLE MD 21645-9998 USA "
    },
    {
      "Location ID": "21649",
      "Location Name": "MARYDEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 HALLTOWN RD MARYDEL MD 21649 USA "
    },
    {
      "Location ID": "21650",
      "Location Name": "MASSEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12139 GALENA RD MASSEY MD 21650-9998 USA "
    },
    {
      "Location ID": "21651",
      "Location Name": "MILLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 412 CYPRESS ST MILLINGTON MD 21651-9998 USA "
    },
    {
      "Location ID": "21653",
      "Location Name": "NEWCOMB",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7414 BACK ST NEWCOMB MD 21653-9998 USA "
    },
    {
      "Location ID": "21654",
      "Location Name": "OXFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 WILSON ST OXFORD MD 21654-9998 USA "
    },
    {
      "Location ID": "21655",
      "Location Name": "PRESTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 MAIN ST PRESTON MD 21655-9998 USA "
    },
    {
      "Location ID": "21657",
      "Location Name": "QUEEN ANNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 32209 PARK AVE QUEEN ANNE MD 21657-9998 USA "
    },
    {
      "Location ID": "21658",
      "Location Name": "QUEENSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7100 MAIN ST QUEENSTOWN MD 21658-9998 USA "
    },
    {
      "Location ID": "21659",
      "Location Name": "RHODESDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5664 RHODESDALE VIENNA RD RHODESDALE MD 21659-9998 USA "
    },
    {
      "Location ID": "21660",
      "Location Name": "RIDGELY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 502 MARYLAND AVE RIDGELY MD 21660-9998 USA "
    },
    {
      "Location ID": "21661",
      "Location Name": "ROCK HALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5691 LIBERTY ST ROCK HALL MD 21661-9998 USA "
    },
    {
      "Location ID": "21662",
      "Location Name": "ROYAL OAK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25897 ROYAL OAK RD ROYAL OAK MD 21662-9998 USA "
    },
    {
      "Location ID": "21663",
      "Location Name": "SAINT MICHAELS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 303 S TALBOT ST SAINT MICHAELS MD 21663-9998 USA "
    },
    {
      "Location ID": "21664",
      "Location Name": "SECRETARY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 145 MAIN ST SECRETARY MD 21664-9998 USA "
    },
    {
      "Location ID": "21666",
      "Location Name": "STEVENSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 366 THOMPSON CREEK MALL STEVENSVILLE MD 21666 USA "
    },
    {
      "Location ID": "21668",
      "Location Name": "SUDLERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 S CHURCH ST SUDLERSVILLE MD 21668-9998 USA "
    },
    {
      "Location ID": "21670",
      "Location Name": "TEMPLEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3711 BARCLAY RD TEMPLEVILLE MD 21670-9998 USA "
    },
    {
      "Location ID": "21671",
      "Location Name": "TILGHMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5806 TILGHMAN ISLAND RD TILGHMAN MD 21671-9998 USA "
    },
    {
      "Location ID": "21673",
      "Location Name": "TRAPPE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29381 GREENFIELD AVE TRAPPE MD 21673-9998 USA "
    },
    {
      "Location ID": "21676",
      "Location Name": "WITTMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22611 POT PIE RD WITTMAN MD 21676-9998 USA "
    },
    {
      "Location ID": "21678",
      "Location Name": "WORTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10802 WORTON RD WORTON MD 21678-9998 USA "
    },
    {
      "Location ID": "21679",
      "Location Name": "WYE MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14278 OLD WYE MILLS RD WYE MILLS MD 21679-9998 USA "
    },
    {
      "Location ID": "216AG",
      "Location Name": "AGP MEDIA OF CHESAPEAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29088 AIRPARK DR EASTON MD 21601-7000 USA "
    },
    {
      "Location ID": "216LE",
      "Location Name": "LORRY INDUSTRIES - ENGERMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 45 ENGERMAN AVE STE G DENTON MD 21629 USA "
    },
    {
      "Location ID": "216LW",
      "Location Name": "LORRY INDUSTRIES - WALSH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 95 WALSH WAY DENTON MD 21629 USA "
    },
    {
      "Location ID": "216MD",
      "Location Name": "MEDIFAST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 601 SUNRISE AVE RIDGELY MD 21660-1939 USA "
    },
    {
      "Location ID": "216SF",
      "Location Name": "SISK FULFILLMENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1900 INDUSTRIAL PARK RD FEDERALSBURG MD 21632-2667 USA "
    },
    {
      "Location ID": "216UF",
      "Location Name": "USA FULFILLMENT (CHESTERTOWN)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 313 TALBOT BOULEVARD CHESTERTOWN MD 21620-1016 USA "
    },
    {
      "Location ID": "216WF",
      "Location Name": "WARWICK FULFILLMENT SOLUTIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4400 EAST NEW MARKET HURLOCK RD HURLOCK MD 21643-3020 USA "
    },
    {
      "Location ID": "217",
      "Location Name": "FREDERICK PDF MD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1550 TILCO DR FREDERICK MD 21704 USA "
    },
    {
      "Location ID": "21701",
      "Location Name": "FREDERICK PO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201E PATRICK ST FREDERICK MD 21701 USA "
    },
    {
      "Location ID": "21702",
      "Location Name": "COLLEGE ESTATES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1301 W 7TH ST FREDERICK MD 21702 USA "
    },
    {
      "Location ID": "21710",
      "Location Name": "ADAMSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5537 MOUNTVILLE RD ADAMSTOWN MD 21710 USA "
    },
    {
      "Location ID": "21711",
      "Location Name": "BIG POOL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11304 HOMESTEAD DR BIG POOL MD 21711 USA "
    },
    {
      "Location ID": "21713",
      "Location Name": "BOONSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 POTOMAC ST BOONSBORO MD 21713 USA "
    },
    {
      "Location ID": "21714",
      "Location Name": "BRADDOCK HEIGHTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4707 SCHLEY AVE STE A BRADDOCK HEIGHTS MD 21714 USA "
    },
    {
      "Location ID": "21716",
      "Location Name": "BRUNSWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 315 BRUNSWICK ST BRUNSWICK MD 21716 USA "
    },
    {
      "Location ID": "21717",
      "Location Name": "BUCKEYSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4001 BUCKEYSTOWN PIKE BUCKEYSTOWN MD 21717 USA "
    },
    {
      "Location ID": "21718",
      "Location Name": "BURKITTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 E MAIN ST BURKITTSVILLE MD 21718 USA "
    },
    {
      "Location ID": "21719",
      "Location Name": "CASCADE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25208 MILITARY RD CASCADE MD 21719 USA "
    },
    {
      "Location ID": "21720",
      "Location Name": "CAVETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22509 CAVETOWN CHURCH RD CAVETOWN MD 21720 USA "
    },
    {
      "Location ID": "21721",
      "Location Name": "CHEWSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21106 TWIN SPRINGS DR CHEWSVILLE MD 21721 USA "
    },
    {
      "Location ID": "21722",
      "Location Name": "CLEAR SPRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 MULBERRY ST CLEAR SPRING MD 21722 USA "
    },
    {
      "Location ID": "21727",
      "Location Name": "EMMITSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 305 S SETON AVE EMMITSBURG MD 21727 USA "
    },
    {
      "Location ID": "21737",
      "Location Name": "GLENELG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3900 10 OAKS RD UNIT 7 GLENELG MD 21737 USA "
    },
    {
      "Location ID": "21738",
      "Location Name": "GLENWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3000 ROUTE 97 GLENWOOD MD 21738 USA "
    },
    {
      "Location ID": "21740",
      "Location Name": "HAGERSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44 W FRANKLIN ST HAGERSTOWN MD 21740 USA "
    },
    {
      "Location ID": "21742",
      "Location Name": "NORTHERN STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12912 CONAMAR DR HAGERSTOWN MD 21742 USA "
    },
    {
      "Location ID": "21750",
      "Location Name": "HANCOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 N PENNSYLVANIA AVE HANCOCK MD 21750 USA "
    },
    {
      "Location ID": "21755",
      "Location Name": "JEFFERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3702 JEFFERSON PIKE JEFFERSON MD 21755 USA "
    },
    {
      "Location ID": "21756",
      "Location Name": "KEEDYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 S MAIN ST KEEDYSVILLE MD 21756 USA "
    },
    {
      "Location ID": "21757",
      "Location Name": "KEYMAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6747 MIDDLEBURG RD KEYMAR MD 21757 USA "
    },
    {
      "Location ID": "21759",
      "Location Name": "LADIESBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12509 WOODSBORO PIKE LADIESBURG MD 21759 USA "
    },
    {
      "Location ID": "21762",
      "Location Name": "LIBERTYTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11941 MAIN ST LIBERTYTOWN MD 21762 USA "
    },
    {
      "Location ID": "21765",
      "Location Name": "LISBON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1280 HOPKINS ALY LISBON MD 21765 USA "
    },
    {
      "Location ID": "21767",
      "Location Name": "MAUGANSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13809 MAUGANSVILLE RD MAUGANSVILLE MD 21767 USA "
    },
    {
      "Location ID": "21769",
      "Location Name": "MIDDLETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7227 HOLLOW RD MIDDLETOWN MD 21769 USA "
    },
    {
      "Location ID": "21770",
      "Location Name": "MONROVIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4411 GREEN VALLEY RD MONROVIA MD 21770 USA "
    },
    {
      "Location ID": "21771",
      "Location Name": "MT AIRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 505 WINDY KNOLL DR MOUNT AIRY MD 21771 USA "
    },
    {
      "Location ID": "21773",
      "Location Name": "MYERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 WOLFSVILLE RD MYERSVILLE MD 21773 USA "
    },
    {
      "Location ID": "21774",
      "Location Name": "NEW MARKET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 168 W MAIN ST NEW MARKET MD 21774 USA "
    },
    {
      "Location ID": "21775",
      "Location Name": "NEW MIDWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12048A WOODSBORO PIKE NEW MIDWAY MD 21775 USA "
    },
    {
      "Location ID": "21776",
      "Location Name": "NEW WINDSOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 204 MAPLE AVE NEW WINDSOR MD 21776 USA "
    },
    {
      "Location ID": "21777",
      "Location Name": "POINT OF ROCKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1597 BOWIS DR POINT OF ROCKS MD 21777 USA "
    },
    {
      "Location ID": "21778",
      "Location Name": "ROCKY RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13516 MOTTERS STATION RD ROCKY RIDGE MD 21778 USA "
    },
    {
      "Location ID": "21779",
      "Location Name": "ROHRERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4314 MAIN ST ROHRERSVILLE MD 21779 USA "
    },
    {
      "Location ID": "21780",
      "Location Name": "SABILLASVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17235 SABILLASVILLE RD SABILLASVILLE MD 21780 USA "
    },
    {
      "Location ID": "21782",
      "Location Name": "SHARPSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 E CHAPLINE ST SHARPSBURG MD 21782 USA "
    },
    {
      "Location ID": "21783",
      "Location Name": "SMITHSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 43 GROVE LN SMITHSBURG MD 21783 USA "
    },
    {
      "Location ID": "21784",
      "Location Name": "SKYESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1363 PROGRESS WAY SKYESVILLE MD 21784 USA "
    },
    {
      "Location ID": "21787",
      "Location Name": "TANEYTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 MIDDLE ST TANEYTOWN MD 21787 USA "
    },
    {
      "Location ID": "21788",
      "Location Name": "THURMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 WATER ST THURMONT MD 21788 USA "
    },
    {
      "Location ID": "21790",
      "Location Name": "TUSCARORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5709A TUSCARORA RD TUSCARORA MD 21790 USA "
    },
    {
      "Location ID": "21791",
      "Location Name": "UNION BRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 PENROSE ST UNION BRIDGE MD 21791 USA "
    },
    {
      "Location ID": "21793",
      "Location Name": "WALKERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 E FREDERICK ST WALKERSVILLE MD 21793 USA "
    },
    {
      "Location ID": "21794",
      "Location Name": "WEST FRIENDSHIP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12800 STATE ROUTE 144 WEST FRIENDSHIP MD 21794 USA "
    },
    {
      "Location ID": "21795",
      "Location Name": "WILLIAMSPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 W SALISBURY ST WILLIAMSPORT MD 21795 USA "
    },
    {
      "Location ID": "21797",
      "Location Name": "WOODBINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7623 WOODBINE RD WOODBINE MD 21797 USA "
    },
    {
      "Location ID": "21798",
      "Location Name": "WOODSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 602 S MAIN ST WOODSBORO MD 21798 USA "
    },
    {
      "Location ID": "217HB",
      "Location Name": "HBP INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 952 FREDERICK ST HAGERSTOWN MD 21740 USA "
    },
    {
      "Location ID": "217ID",
      "Location Name": "IMAGE DIRECT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 605 EAST CHURCH STREET FREDERICK MD 21701 USA "
    },
    {
      "Location ID": "217MF",
      "Location Name": "MOORES BUSINESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 POPLAR ST THURMONT MD 21788 USA "
    },
    {
      "Location ID": "217SE",
      "Location Name": "SERRV",
      "Time Zone Offset (Hours)": 0,
      "Address": " 601 MAIN ST NEW WINDSOR MD 21776 USA "
    },
    {
      "Location ID": "217UP",
      "Location Name": "UNITED PRESORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4612 NAVISTAR DR FREDERICK MD 21703 USA "
    },
    {
      "Location ID": "218",
      "Location Name": "SALISBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 816 E SALISBURY PKWY SALISBURY MD 21801 USA "
    },
    {
      "Location ID": "21810",
      "Location Name": "ALLEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3519 ALLEN RD ALLEN MD 21810-9998 USA "
    },
    {
      "Location ID": "21811",
      "Location Name": "BERLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 BROAD ST BERLIN MD 21811 USA "
    },
    {
      "Location ID": "21813",
      "Location Name": "BISHOPVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10656 BISHOPVILLE RD BISHOPVILLE MD 21813-9998 USA "
    },
    {
      "Location ID": "21817",
      "Location Name": "CRISFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 W MAIN ST CRISFIELD MD 21817-9998 USA "
    },
    {
      "Location ID": "21821",
      "Location Name": "DEAL ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10051 DEAL ISLAND RD DEAL ISLAND MD 21821-9998 USA "
    },
    {
      "Location ID": "21822",
      "Location Name": "EDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31676 EDEN ALLEN RD EDEN MD 21822-9998 USA "
    },
    {
      "Location ID": "21826",
      "Location Name": "FRUITLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 E MAIN ST FRUITLAND MD 21826-9998 USA "
    },
    {
      "Location ID": "21829",
      "Location Name": "GIRDLETREE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2744 SNOW HILL RD GIRDLETREE MD 21829-9998 USA "
    },
    {
      "Location ID": "21830",
      "Location Name": "HEBRON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 N MAIN ST HEBRON MD 21830-9998 USA "
    },
    {
      "Location ID": "21835",
      "Location Name": "LINKWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3843 OCEAN GTWY LINKWOOD MD 21835-9998 USA "
    },
    {
      "Location ID": "21837",
      "Location Name": "MARDELA SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 RAILROAD AVE MARDELA SPRINGS MD 21837 USA "
    },
    {
      "Location ID": "21838",
      "Location Name": "MARION STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28530 HUDSON CORNER RD MARION STATION MD 21838-9998 USA "
    },
    {
      "Location ID": "21841",
      "Location Name": "NEWARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8420 NEWARK RD NEWARK MD 21841-9998 USA "
    },
    {
      "Location ID": "21842",
      "Location Name": "OCEAN CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7101 COASTAL HGWY OCEAN CITY MD 21842 USA "
    },
    {
      "Location ID": "21849",
      "Location Name": "PARSONSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7237 PARSONSBURG RD PARSONSBURG MD 21849-9998 USA "
    },
    {
      "Location ID": "21850",
      "Location Name": "PITTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7390 PINE ST PITTSVILLE MD 21850-9998 USA "
    },
    {
      "Location ID": "21851",
      "Location Name": "POCOMOKE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 207 MARKET ST POCOMOKE CITY MD 21851-9998 USA "
    },
    {
      "Location ID": "21853",
      "Location Name": "PRINCESS ANNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11690 SOMERSET AVE PRINCESS ANNE MD 21853-9998 USA "
    },
    {
      "Location ID": "21861",
      "Location Name": "SHARPTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 403 4TH ST SHARPTOWN MD 21861-1041 USA "
    },
    {
      "Location ID": "21863",
      "Location Name": "SNOW HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 306 N WASHINGTON ST SNOW HILL MD 21863-9998 USA "
    },
    {
      "Location ID": "21869",
      "Location Name": "VIENNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 MARKET ST VIENNA MD 21869-9998 USA "
    },
    {
      "Location ID": "21871",
      "Location Name": "WESTOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8930 CRISFIELD HGWY WESTOVER MD 21871-9998 USA "
    },
    {
      "Location ID": "21872",
      "Location Name": "WHALEYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11721 SHEPPARDS CROSSING RD WHALEYSVILLE MD 21872-9998 USA "
    },
    {
      "Location ID": "21874",
      "Location Name": "WILLARDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36311 RICHLAND RD WILLARDS MD 21874-9998 USA "
    },
    {
      "Location ID": "218AA",
      "Location Name": "OCEAN CITY FINANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 408 PHILADELPHIA AVE OCEAN CITY MD 21842-9996 USA "
    },
    {
      "Location ID": "218HL",
      "Location Name": "HATLAND.COM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11429 COASTAL HGWY OCEAN CITY MD 21842-2516 USA "
    },
    {
      "Location ID": "218ZY",
      "Location Name": "OCEAN PINES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11115 LAKEVIEW LN OCEAN PINES MD 21811 USA "
    },
    {
      "Location ID": "21912",
      "Location Name": "WARWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 77 MAIN ST WARWICK MD 21912 USA "
    },
    {
      "Location ID": "21913",
      "Location Name": "CECILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 127 W MAIN ST CECILTON MD 21913 USA "
    },
    {
      "Location ID": "21915",
      "Location Name": "CHESAPEAKE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2551 AUGUSTINE HERMAN HWY CHESAPEAKE CITY MD 21915 USA "
    },
    {
      "Location ID": "21919",
      "Location Name": "EARLEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1244 CRYSTAL BEACH RD EARLEVILLE MD 21919 USA "
    },
    {
      "Location ID": "21930",
      "Location Name": "GEORGETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7480 AUGUSTINE HERMAN HWY GEORGETOWN MD 21930 USA "
    },
    {
      "Location ID": "220",
      "Location Name": "NORTHERN VA P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8409 LEE HWY MERRIFIELD VA 22081 USA "
    },
    {
      "Location ID": "22026",
      "Location Name": "DUMFRIES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17949 MAIN ST DUMFRIES VA 22026 USA "
    },
    {
      "Location ID": "22060",
      "Location Name": "FORT BELVOIR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5875 21ST ST FORT BELVOIR VA 22060 USA "
    },
    {
      "Location ID": "22079",
      "Location Name": "LORTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7726 GUNSTON PLZ LORTON VA 22079 USA "
    },
    {
      "Location ID": "220CA",
      "Location Name": "FALLS CHURCH 43 ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2920 ESKRIDGE RD FAIRFAX VA 22031 USA "
    },
    {
      "Location ID": "22121",
      "Location Name": "MOUNT VERNON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3500T MOUNT VERNON MEMORIAL HWY MOUNT VERNON VA 22121 USA "
    },
    {
      "Location ID": "22122",
      "Location Name": "NEWINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8253A BACKLICK RD NEWINGTON VA 22122 USA "
    },
    {
      "Location ID": "22125",
      "Location Name": "OCCOQUAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 MILL ST OCCOQUAN VA 22125 USA "
    },
    {
      "Location ID": "22134",
      "Location Name": "QUANTICO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 5TH AVE QUANTICO VA 22134 USA "
    },
    {
      "Location ID": "22172",
      "Location Name": "TRIANGLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18627 OLD TRIANGLE RD TRIANGLE VA 22172 USA "
    },
    {
      "Location ID": "22186",
      "Location Name": "VINT HILL FARMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4257 AIKEN DR WARRENTON VA 20187 USA "
    },
    {
      "Location ID": "22191",
      "Location Name": "WOODBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1810 REDDY DR WOODBRIDGE VA 22191 USA "
    },
    {
      "Location ID": "22193",
      "Location Name": "PRINCE WILLIAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3360 POST OFFICE RD WOODBRIDGE VA 22193 USA "
    },
    {
      "Location ID": "221LE",
      "Location Name": "L & E MERIDIAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8000 CORPORATE CT SPRINGFIELD VA 22153 USA "
    },
    {
      "Location ID": "221TJ",
      "Location Name": "SPRINGFIELD OFFSET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6885 COMMERCIAL DRIVE SPRINGFIELD VA 22159 USA "
    },
    {
      "Location ID": "22201",
      "Location Name": "ARLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3118 WASHINGTON BLVD ARLINGTON VA 22201 USA "
    },
    {
      "Location ID": "223AA",
      "Location Name": "MEMORIAL ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2226 DUKE ST ALEXANDRIA VA 22314 USA "
    },
    {
      "Location ID": "223AM",
      "Location Name": "AUTOMATED MAILING CORP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4407 WHEELER AVE ALEXANDRIA VA 22304 USA "
    },
    {
      "Location ID": "224",
      "Location Name": "FREDERICKSBURG/RICHMOND VA AMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 PRINCESS ANNE STREET FREDERICKSBURG VA 22404 USA "
    },
    {
      "Location ID": "22401",
      "Location Name": "FREDERICKSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 PRINCESS ANNE ST FREDERICKSBURG VA 22401 USA "
    },
    {
      "Location ID": "22406",
      "Location Name": "FALMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 LICHFIELD BLVD FREDERICKSBURG VA 22406 USA "
    },
    {
      "Location ID": "22427",
      "Location Name": "BOWLING GREEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 MILFORD ST BOWLING GREEN VA 22427 USA "
    },
    {
      "Location ID": "22432",
      "Location Name": "BURGESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15096 NORTHUMBERLAND HWY BURGESS VA 22432 USA "
    },
    {
      "Location ID": "22433",
      "Location Name": "BURR HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29483 RACCOON FORD RD BURR HILL VA 22433 USA "
    },
    {
      "Location ID": "22435",
      "Location Name": "CALLAO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 72 NORTHUMBERLAND HWY CALLAO VA 22435 USA "
    },
    {
      "Location ID": "22442",
      "Location Name": "COLES POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 256 LONG POINT RD HAGUE VA 22469 USA "
    },
    {
      "Location ID": "22443",
      "Location Name": "COLONIAL BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 DWIGHT AVE COLONIAL BEACH VA 22443 USA "
    },
    {
      "Location ID": "22446",
      "Location Name": "CORBIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4519 FREDERICKSBURG TPKE CORBIN VA 22446 USA "
    },
    {
      "Location ID": "22448",
      "Location Name": "DAHLGREN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17285 DAHLGREN RD DAHLGREN VA 22448 USA "
    },
    {
      "Location ID": "22451",
      "Location Name": "DOGUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13218 CLEVE DR DOGUE VA 22451 USA "
    },
    {
      "Location ID": "22460",
      "Location Name": "FARNHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 193 CEDAR GROVE RD FARNHAM VA 22460 USA "
    },
    {
      "Location ID": "22463",
      "Location Name": "GARRISONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 524 GARRISONVILLE RD GARRISONVILLE VA 22463 USA "
    },
    {
      "Location ID": "22469",
      "Location Name": "HAGUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8433 COPLE HWY HAGUE VA 22469 USA "
    },
    {
      "Location ID": "22471",
      "Location Name": "HARTWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 HARTWOOD CHURCH RD HARTWOOD VA 22471 USA "
    },
    {
      "Location ID": "22472",
      "Location Name": "HAYNESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11320 RICHMOND RD HAYNESVILLE VA 22472 USA "
    },
    {
      "Location ID": "22473",
      "Location Name": "HEATHSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 MONUMENT PL HEATHSVILLE VA 22473 USA "
    },
    {
      "Location ID": "22480",
      "Location Name": "IRVINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4473 IRVINGTON RD IRVINGTON VA 22480 USA "
    },
    {
      "Location ID": "22481",
      "Location Name": "JERSEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12079 JERSEY RD JERSEY VA 22481 USA "
    },
    {
      "Location ID": "22482",
      "Location Name": "KILMARNOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 239 N MAIN ST KILMARNOCK VA 22482 USA "
    },
    {
      "Location ID": "22485",
      "Location Name": "KING GEORGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7993 KINGS HWY KING GEORGE VA 22485 USA "
    },
    {
      "Location ID": "22488",
      "Location Name": "KINSALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 490 KINSALE RD KINSALE VA 22488 USA "
    },
    {
      "Location ID": "224AK",
      "Location Name": "ANTHONY KANE, VA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1155 INTERNATIONAL PKWY FREDERICKSBURG VA 22406 USA "
    },
    {
      "Location ID": "224GD",
      "Location Name": "GEICO DIRECT, VA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 GEICO BLVD FREDERICKSBURG VA 22412 USA "
    },
    {
      "Location ID": "224KC",
      "Location Name": "KNIFE CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5201 LAD LAND DR FREDERICKSBRG VA 22407 USA "
    },
    {
      "Location ID": "224SW",
      "Location Name": "SKYLINE WEARS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11900 MAIN ST FREDERICKSBRG VA 22408 USA "
    },
    {
      "Location ID": "22501",
      "Location Name": "LADYSMITH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18014 JEFFERSON DAVIS HWY LADYSMITH VA 22501 USA "
    },
    {
      "Location ID": "22503",
      "Location Name": "LANCASTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9054 MARY BALL RD LANCASTER VA 22503 USA "
    },
    {
      "Location ID": "22507",
      "Location Name": "LIVELY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5316 MARY BALL RD LIVELY VA 22507 USA "
    },
    {
      "Location ID": "22508",
      "Location Name": "LOCUST GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4447 GERMANNA HWY LOCUST GROVE VA 22508 USA "
    },
    {
      "Location ID": "22511",
      "Location Name": "LOTTSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2217 NORTHUMBERLAND HWY LOTTSBURG VA 22511 USA "
    },
    {
      "Location ID": "22514",
      "Location Name": "MILFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15429 ANTIOCH RD MILFORD VA 22514 USA "
    },
    {
      "Location ID": "22520",
      "Location Name": "MONTROSS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15917 KINGS HWY MONTROSS VA 22520 USA "
    },
    {
      "Location ID": "22524",
      "Location Name": "MOUNT HOLLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4375 COPLE HWY MOUNT HOLLY VA 22524 USA "
    },
    {
      "Location ID": "22526",
      "Location Name": "NINDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15488 RIDGE RD NINDE VA 22526 USA "
    },
    {
      "Location ID": "22529",
      "Location Name": "OLDHAMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3498 OLDHAMS RD OLDHAMS VA 22529 USA "
    },
    {
      "Location ID": "22534",
      "Location Name": "PARTLOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2920 PARTLOW RD PARTLOW VA 22534 USA "
    },
    {
      "Location ID": "22535",
      "Location Name": "PORT ROYAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 602 MAIN ST PORT ROYAL VA 22535 USA "
    },
    {
      "Location ID": "22539",
      "Location Name": "REEDVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 284 MORRIS AVE REEDVILLE VA 22539 USA "
    },
    {
      "Location ID": "22542",
      "Location Name": "RHOADESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25433 LAFAYETTE DR RHOADESVILLE VA 22542 USA "
    },
    {
      "Location ID": "22545",
      "Location Name": "RUBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 32 ARROWHEAD DR STE 101 RUBY VA 22545 USA "
    },
    {
      "Location ID": "22546",
      "Location Name": "RUTHER GLEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24134 WELCOME WAY DR RUTHER GLEN VA 22546 USA "
    },
    {
      "Location ID": "22547",
      "Location Name": "SEALSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1130 KINGS HWY SEALSTON VA 22547 USA "
    },
    {
      "Location ID": "22553",
      "Location Name": "SPOTSYLVANIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7420 BROCK RD SPOTSYLVANIA VA 22553 USA "
    },
    {
      "Location ID": "22554",
      "Location Name": "STAFFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2650 RICHMOND HWY STAFFORD VA 22554 USA "
    },
    {
      "Location ID": "22560",
      "Location Name": "TAPPAHANNOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 WRIGHT ST TAPPAHANNOCK VA 22560 USA "
    },
    {
      "Location ID": "22565",
      "Location Name": "THORNBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5314 MUDD TAVERN RD THORNBURG VA 22565 USA "
    },
    {
      "Location ID": "22567",
      "Location Name": "UNIONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23209 VILLAGE RD UNIONVILLE VA 22567 USA "
    },
    {
      "Location ID": "22572",
      "Location Name": "WARSAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 360 MAIN ST WARSAW VA 22572 USA "
    },
    {
      "Location ID": "22576",
      "Location Name": "WEEMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3960 WEEMS RD WEEMS VA 22576 USA "
    },
    {
      "Location ID": "22578",
      "Location Name": "WHITE STONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 564 RAPPAHANNOCK DR WHITE STONE VA 22578 USA "
    },
    {
      "Location ID": "22579",
      "Location Name": "WICOMICO CHURCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5148 JESSE DUPONT MEMORIAL HWY WICOMICO CHURCH VA 22579 USA "
    },
    {
      "Location ID": "22580",
      "Location Name": "WOODFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11087 WOODFORD RD WOODFORD VA 22580 USA "
    },
    {
      "Location ID": "225SM",
      "Location Name": "PATTON KIEHL GROUP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17026 BULL CHURCH RD. WOODFORD VA 22580 USA "
    },
    {
      "Location ID": "226",
      "Location Name": "WINCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 340 N PLEASANT VALLEY RD WINCHESTER VA 22601 USA "
    },
    {
      "Location ID": "22610",
      "Location Name": "BENTONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2590 STONEWALL JACKSON HWY BENTONVILLE VA 22610 USA "
    },
    {
      "Location ID": "22611",
      "Location Name": "BERRYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 N CHURCH ST BERRYVILLE VA 22611 USA "
    },
    {
      "Location ID": "22620",
      "Location Name": "BOYCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 112 W MAIN ST BOYCE VA 22620 USA "
    },
    {
      "Location ID": "22624",
      "Location Name": "CLEAR BROOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1164 BRUCETOWN RD CLEAR BROOK VA 22624 USA "
    },
    {
      "Location ID": "22625",
      "Location Name": "CROSS JUNCTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6654 N FREDERICK PIKE CROSS JUNCTION VA 22625 USA "
    },
    {
      "Location ID": "22630",
      "Location Name": "FRONT ROYAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 E 3RD ST FRONT ROYAL VA 22630 USA "
    },
    {
      "Location ID": "22637",
      "Location Name": "GORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6819 NORTHWESTERN PIKE GORE VA 22637 USA "
    },
    {
      "Location ID": "22639",
      "Location Name": "HUME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5046 LEEDS MANOR RD HUME VA 22639 USA "
    },
    {
      "Location ID": "22642",
      "Location Name": "LINDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13474 JOHN MARSHALL HWY LINDEN VA 22642 USA "
    },
    {
      "Location ID": "22643",
      "Location Name": "MARKHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11524 OLD MARKHAM RD MARKHAM VA 22643 USA "
    },
    {
      "Location ID": "22644",
      "Location Name": "MAURERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24784 OLD VALLEY PIKE MAURERTOWN VA 22644 USA "
    },
    {
      "Location ID": "22645",
      "Location Name": "MIDDLETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7922 MAIN ST MIDDLETOWN VA 22645 USA "
    },
    {
      "Location ID": "22650",
      "Location Name": "RILEYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 560 RILEYVILLE RD RILEYVILLE VA 22650 USA "
    },
    {
      "Location ID": "22655",
      "Location Name": "STEPHENS CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5071 MAIN ST STEPHENS CITY VA 22655 USA "
    },
    {
      "Location ID": "22656",
      "Location Name": "STEPHENSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2546 MARTINSBURG PIKE STE 2 STEPHENSON VA 22656 USA "
    },
    {
      "Location ID": "22657",
      "Location Name": "STRASBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 152 W KING ST STRASBURG VA 22657 USA "
    },
    {
      "Location ID": "22660",
      "Location Name": "TOMS BROOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3374 S MAIN ST TOMS BROOK VA 22660 USA "
    },
    {
      "Location ID": "22664",
      "Location Name": "WOODSTOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 S MUHLENBERG ST WOODSTOCK VA 22664 USA "
    },
    {
      "Location ID": "226OF",
      "Location Name": "ORTHOFEET - WINCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 660 BROOKE RD WINCHESTER VA 22603-5737 USA "
    },
    {
      "Location ID": "226SV",
      "Location Name": "SHENNANDOAH VLY PRS VA",
      "Time Zone Offset (Hours)": 0,
      "Address": " RT-55 EAST STRASBURG VA 22657 USA "
    },
    {
      "Location ID": "227",
      "Location Name": "CULPEPER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 S MAIN ST CULPEPER VA 22701 USA "
    },
    {
      "Location ID": "22712",
      "Location Name": "BEALETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6189 STATION DR BEALETON VA 22712 USA "
    },
    {
      "Location ID": "22713",
      "Location Name": "BOSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6276 SPERRYVILLE PIKE BOSTON VA 22713 USA "
    },
    {
      "Location ID": "22714",
      "Location Name": "BRANDY STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19329 BRANDY RD BRANDY STATION VA 22714 USA "
    },
    {
      "Location ID": "22715",
      "Location Name": "BRIGHTWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4362 N SEMINOLE TRL BRIGHTWOOD VA 22715 USA "
    },
    {
      "Location ID": "22718",
      "Location Name": "ELKWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22098 JAMES MADISON HWY ELKWOOD VA 22718 USA "
    },
    {
      "Location ID": "22724",
      "Location Name": "JEFFERSONTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5073 JEFFERSONTON RD JEFFERSONTON VA 22724 USA "
    },
    {
      "Location ID": "22726",
      "Location Name": "LIGNUM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23395 LIGNUM RD LIGNUM VA 22726 USA "
    },
    {
      "Location ID": "22727",
      "Location Name": "MADISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1438 N MAIN ST MADISON VA 22727 USA "
    },
    {
      "Location ID": "22728",
      "Location Name": "MIDLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5473 MIDLAND RD MIDLAND VA 22728 USA "
    },
    {
      "Location ID": "22729",
      "Location Name": "MITCHELLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11320 MITCHELL RD MITCHELLS VA 22729 USA "
    },
    {
      "Location ID": "22733",
      "Location Name": "RAPIDAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9767 LOCUST DALE RD RAPIDAN VA 22733 USA "
    },
    {
      "Location ID": "22734",
      "Location Name": "REMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 501 N JAMES MADISON ST REMINGTON VA 22734 USA "
    },
    {
      "Location ID": "22735",
      "Location Name": "REVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7005 JAMES MONROE HWY REVA VA 22735 USA "
    },
    {
      "Location ID": "22737",
      "Location Name": "RIXEYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8623 RIXEYVILLE RD RIXEYVILLE VA 22737 USA "
    },
    {
      "Location ID": "22740",
      "Location Name": "SPERRYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 43 MAIN ST SPERRYVILLE VA 22740 USA "
    },
    {
      "Location ID": "22741",
      "Location Name": "STEVENSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19312 GERMANNA HWY STEVENSBURG VA 22741 USA "
    },
    {
      "Location ID": "22742",
      "Location Name": "SUMERDUCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5338 SUMERDUCK RD SUMERDUCK VA 22742 USA "
    },
    {
      "Location ID": "22747",
      "Location Name": "WASHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 LEGGETT LN WASHINGTON VA 22747 USA "
    },
    {
      "Location ID": "227CC",
      "Location Name": "CCA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13195 FREEDOM WAY BOSTON VA 22718 USA "
    },
    {
      "Location ID": "228",
      "Location Name": "HARRISONBURG VA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3811 S MAIN ST HARRISONBURG VA 22801 USA "
    },
    {
      "Location ID": "22801",
      "Location Name": "HARRISONBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 N MAIN ST HARRISONBURG VA 22801 USA "
    },
    {
      "Location ID": "22802",
      "Location Name": "HARRISONBURG(DOWNTOWN STATION)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 281 N MASON ST HARRISONBURG VA 22802 USA "
    },
    {
      "Location ID": "22812",
      "Location Name": "BRIDGEWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 W COLLEGE ST BRIDGEWATER VA 22812 USA "
    },
    {
      "Location ID": "22815",
      "Location Name": "BROADWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 151 N CENTRAL ST BROADWAY VA 22815 USA "
    },
    {
      "Location ID": "22821",
      "Location Name": "DAYTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 242 MAIN ST DAYTON VA 22821 USA "
    },
    {
      "Location ID": "22824",
      "Location Name": "EDINBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 N MAIN ST EDINBURG VA 22824 USA "
    },
    {
      "Location ID": "22827",
      "Location Name": "ELKTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 W ROCKINGHAM ST ELKTON VA 22827 USA "
    },
    {
      "Location ID": "22831",
      "Location Name": "HINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6121 RAWLEY PIKE HINTON VA 22831 USA "
    },
    {
      "Location ID": "22833",
      "Location Name": "LACEY SPRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8975 N VALLEY PIKE LACEY SPRING VA 22833 USA "
    },
    {
      "Location ID": "22834",
      "Location Name": "LINVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3754 LINVILLE EDOM RD LINVILLE VA 22834 USA "
    },
    {
      "Location ID": "22835",
      "Location Name": "LURAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 S BROAD ST LURAY VA 22835 USA "
    },
    {
      "Location ID": "22840",
      "Location Name": "MC GAHEYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 419 JACOB BURNER DR MC GAHEYSVILLE VA 22840 USA "
    },
    {
      "Location ID": "22841",
      "Location Name": "MOUNT CRAWFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 411 N MAIN ST MOUNT CRAWFORD VA 22841 USA "
    },
    {
      "Location ID": "22842",
      "Location Name": "MOUNT JACKSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5240 MAIN ST MOUNT JACKSON VA 22842 USA "
    },
    {
      "Location ID": "22843",
      "Location Name": "MOUNT SOLON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 885 NATURAL CHIMNEYS RD MOUNT SOLON VA 22843 USA "
    },
    {
      "Location ID": "22844",
      "Location Name": "NEW MARKET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9444 JOHN SEVIER RD NEW MARKET VA 22844 USA "
    },
    {
      "Location ID": "22846",
      "Location Name": "PENN LAIRD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5375 SPOTSWOOD TRL PENN LAIRD VA 22846 USA "
    },
    {
      "Location ID": "22847",
      "Location Name": "QUICKSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4199 RIDGE RD QUICKSBURG VA 22847 USA "
    },
    {
      "Location ID": "22849",
      "Location Name": "SHENANDOAH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 VIRGINIA AVE SHENANDOAH VA 22849 USA "
    },
    {
      "Location ID": "22850",
      "Location Name": "SINGERS GLEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9858 SINGERS GLEN RD SINGERS GLEN VA 22850 USA "
    },
    {
      "Location ID": "22851",
      "Location Name": "STANLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 151 E MAIN ST STANLEY VA 22851 USA "
    },
    {
      "Location ID": "22853",
      "Location Name": "TIMBERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 172 S MAIN ST TIMBERVILLE VA 22853 USA "
    },
    {
      "Location ID": "228BO",
      "Location Name": "BLUE OVAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 207 N FIFTH ST ELKTON VA 22827 USA "
    },
    {
      "Location ID": "228BR",
      "Location Name": "BRILLMAN COMPANY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2328 PEPPER RD MOUNT JACKSON VA 22842 USA "
    },
    {
      "Location ID": "228MO",
      "Location Name": "HARRISONBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3811 S MAIN ST HARRISONBURG VA 22801 USA "
    },
    {
      "Location ID": "229",
      "Location Name": "CHARLOTTESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3590 GRAND FORKS BLVD CHARLOTTESVILLE VA 22911 USA "
    },
    {
      "Location ID": "22901",
      "Location Name": "SANATORIUM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 11TH STREET S W CHARLOTTESVILLE VA 22901 USA "
    },
    {
      "Location ID": "22902",
      "Location Name": "DOWNTOWN CHARLOTTESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 513 E MAIN ST CHARLOTTESVILLE VA 22902 USA "
    },
    {
      "Location ID": "22903",
      "Location Name": "UNIVERSITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2150 WISE ST CHARLOTTESVILLE VA 22903 USA "
    },
    {
      "Location ID": "22905",
      "Location Name": "BARRACKS ROAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2150 WISE ST CHARLOTTESVILLE VA 22905 USA "
    },
    {
      "Location ID": "22906",
      "Location Name": "CHARLOTTESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1155 SEMINOLE TRL CHARLOTTESVILLE VA 22906 USA "
    },
    {
      "Location ID": "22920",
      "Location Name": "AFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3154 AFTON MOUNTAIN RD AFTON VA 22920 USA "
    },
    {
      "Location ID": "22922",
      "Location Name": "ARRINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 PHOENIX RD ARRINGTON VA 22922 USA "
    },
    {
      "Location ID": "22923",
      "Location Name": "BARBOURSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5285 BARBOURSVILLE COMM CTR DR BARBOURSVILLE VA 22923 USA "
    },
    {
      "Location ID": "22924",
      "Location Name": "BATESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6620 PLANK RD BATESVILLE VA 22924 USA "
    },
    {
      "Location ID": "22931",
      "Location Name": "COVESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5912 COVESVILLE STORE RD COVESVILLE VA 22931 USA "
    },
    {
      "Location ID": "22932",
      "Location Name": "CROZET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1210 CROZET AVE CROZET VA 22932 USA "
    },
    {
      "Location ID": "22935",
      "Location Name": "DYKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8607 DYKE RD DYKE VA 22935 USA "
    },
    {
      "Location ID": "22936",
      "Location Name": "EARLYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 675 BENT OAKS DR EARLYSVILLE VA 22936 USA "
    },
    {
      "Location ID": "22937",
      "Location Name": "ESMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7514 ESMONT RD ESMONT VA 22937 USA "
    },
    {
      "Location ID": "22938",
      "Location Name": "FABER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 68 POST OFFICE LN FABER VA 22938 USA "
    },
    {
      "Location ID": "22939",
      "Location Name": "FISHERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1595 JEFFERSON HWY FISHERSVILLE VA 22939 USA "
    },
    {
      "Location ID": "22940",
      "Location Name": "FREE UNION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4270 FREE UNION RD FREE UNION VA 22940 USA "
    },
    {
      "Location ID": "22942",
      "Location Name": "GORDONSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 S MAIN ST GORDONSVILLE VA 22942 USA "
    },
    {
      "Location ID": "22943",
      "Location Name": "GREENWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 788 GREENWOOD RD GREENWOOD VA 22943 USA "
    },
    {
      "Location ID": "22945",
      "Location Name": "IVY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4301 IVY RD IVY VA 22945 USA "
    },
    {
      "Location ID": "22946",
      "Location Name": "KEENE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6246 ESMONT RD KEENE VA 22946 USA "
    },
    {
      "Location ID": "22947",
      "Location Name": "KESWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3991 LOUISA RD KESWICK VA 22947 USA "
    },
    {
      "Location ID": "22948",
      "Location Name": "LOCUST DALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1296 N JAMES MADISON HWY LOCUST DALE VA 22948 USA "
    },
    {
      "Location ID": "22949",
      "Location Name": "LOVINGSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 131 MAIN ST LOVINGSTON VA 22949 USA "
    },
    {
      "Location ID": "22952",
      "Location Name": "LYNDHURST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 555 MT TORREY RD LYNDHURST VA 22952 USA "
    },
    {
      "Location ID": "22957",
      "Location Name": "MONTPELIER STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11350 CONSTITUTION HWY MONTPELIER STATION VA 22957 USA "
    },
    {
      "Location ID": "22958",
      "Location Name": "NELLYSFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2663 ROCKFISH VALLEY HWY NELLYSFORD VA 22958 USA "
    },
    {
      "Location ID": "22959",
      "Location Name": "NORTH GARDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4917 PLANK RD NORTH GARDEN VA 22959 USA "
    },
    {
      "Location ID": "22960",
      "Location Name": "ORANGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 129 W MAIN ST ORANGE VA 22960 USA "
    },
    {
      "Location ID": "22963",
      "Location Name": "PALMYRA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13683 JAMES MADISON HWY PALMYRA VA 22963 USA "
    },
    {
      "Location ID": "22964",
      "Location Name": "PINEY RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3577 PATRICK HENRY HWY PINEY RIVER VA 22964 USA "
    },
    {
      "Location ID": "22965",
      "Location Name": "QUINQUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12817 SPOTSWOOD TRL QUINQUE VA 22965 USA "
    },
    {
      "Location ID": "22967",
      "Location Name": "ROSELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7877 PATRICK HENRY HWY ROSELAND VA 22967 USA "
    },
    {
      "Location ID": "22968",
      "Location Name": "RUCKERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8845 SEMINOLE TRL RUCKERSVILLE VA 22968 USA "
    },
    {
      "Location ID": "22969",
      "Location Name": "SCHUYLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6648 ROCKFISH RIVER RD SCHUYLER VA 22969 USA "
    },
    {
      "Location ID": "22971",
      "Location Name": "SHIPMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10697 JAMES RIVER RD SHIPMAN VA 22971 USA "
    },
    {
      "Location ID": "22972",
      "Location Name": "SOMERSET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14257 BLUE RIDGE TPKE SOMERSET VA 22972 USA "
    },
    {
      "Location ID": "22973",
      "Location Name": "STANARDSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 47 CELT RD STANARDSVILLE VA 22973 USA "
    },
    {
      "Location ID": "22974",
      "Location Name": "TROY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 TROY RD TROY VA 22974 USA "
    },
    {
      "Location ID": "22980",
      "Location Name": "WAYNESBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 S WAYNE AVE WAYNESBORO VA 22980 USA "
    },
    {
      "Location ID": "22989",
      "Location Name": "WOODBERRY FOREST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 WALKER DR WOODBERRY FORES VA 22989 USA "
    },
    {
      "Location ID": "229AM",
      "Location Name": "AUGUSTA MEDICAL CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 78 MEDICAL CENTER DR FISHERSVILLE VA 22939 USA "
    },
    {
      "Location ID": "229CC",
      "Location Name": "CRUTCHFIELD CORPORATION (EARLYSVILLE)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 395 REAS FORD RD EARLYSVILLE VA 22936 USA "
    },
    {
      "Location ID": "229CF",
      "Location Name": "CRUTCHFIELD CORPORATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 CRUTCHFIELD PARK CHARLOTTESVLE VA 22911 USA "
    },
    {
      "Location ID": "229MP",
      "Location Name": "MPS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14299/14301 LITCHFIELD DR ORANGE VA 22960 USA "
    },
    {
      "Location ID": "229MT",
      "Location Name": "MUSIC TODAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5391 THREE NOTCH D RD CROZET VA 22932-3181 USA "
    },
    {
      "Location ID": "230",
      "Location Name": "LOG RICHMOND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5801 TECHNOLOGY BLVD SANDSTON VA 23150 USA "
    },
    {
      "Location ID": "23001",
      "Location Name": "ACHILLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9363 GUINEA RD ACHILLES VA 23001 USA "
    },
    {
      "Location ID": "23002",
      "Location Name": "AMELIA COURT HOUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16400 CHURCH ST AMELIA COURT HOUSE VA 23002 USA "
    },
    {
      "Location ID": "23003",
      "Location Name": "ARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9458 GEORGE WASHINGTON MEMORIAL HWY ARK VA 23003 USA "
    },
    {
      "Location ID": "23004",
      "Location Name": "ARVONIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 ARVON RD ARVONIA VA 23004 USA "
    },
    {
      "Location ID": "23009",
      "Location Name": "AYLETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 56 MILL RD AYLETT VA 23009 USA "
    },
    {
      "Location ID": "23011",
      "Location Name": "BARHAMSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18428 NEW KENT HWY BARHAMSVILLE VA 23011 USA "
    },
    {
      "Location ID": "23015",
      "Location Name": "BEAVERDAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16176 BEAVERDAM SCHOOL RD BEAVERDAM VA 23015 USA "
    },
    {
      "Location ID": "23018",
      "Location Name": "BENA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8833 GUINEA RD BENA VA 23018 USA "
    },
    {
      "Location ID": "23022",
      "Location Name": "BREMO BLUFF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 538 BREMO RD BREMO BLUFF VA 23022 USA "
    },
    {
      "Location ID": "23024",
      "Location Name": "BUMPASS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 68 FREDERICKS HALL RD BUMPASS VA 23024 USA "
    },
    {
      "Location ID": "23027",
      "Location Name": "CARTERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 HIGH ST CARTERSVILLE VA 23027 USA "
    },
    {
      "Location ID": "23030",
      "Location Name": "CHARLES CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10800 COURTHOUSE RD CHARLES CITY VA 23030 USA "
    },
    {
      "Location ID": "23031",
      "Location Name": "CHRISTCHURCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " US POSTAL SERVICE P O BOX 9998 CHRISTCHURCH VA 23149 USA "
    },
    {
      "Location ID": "23035",
      "Location Name": "COBBS CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2963 BUCKLEY HALL RD COBBS CREEK VA 23035 USA "
    },
    {
      "Location ID": "23038",
      "Location Name": "COLUMBIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 CAMERON ST COLUMBIA VA 23038 USA "
    },
    {
      "Location ID": "23039",
      "Location Name": "CROZIER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1531 RIVER RD W CROZIER VA 23039 USA "
    },
    {
      "Location ID": "23040",
      "Location Name": "CUMBERLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1489 ANDERSON HWY CUMBERLAND VA 23040 USA "
    },
    {
      "Location ID": "23043",
      "Location Name": "DELTAVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17283 GENERAL PULLER HWY DELTAVILLE VA 23043 USA "
    },
    {
      "Location ID": "23047",
      "Location Name": "DOSWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16410 WASHINGTON HWY DOSWELL VA 23047 USA "
    },
    {
      "Location ID": "23050",
      "Location Name": "DUTTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9226 DUTTON RD DUTTON VA 23050 USA "
    },
    {
      "Location ID": "23055",
      "Location Name": "FORK UNION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4885 JAMES MADISON HWY FORK UNION VA 23055 USA "
    },
    {
      "Location ID": "23056",
      "Location Name": "FOSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15315 JOHN CLAYTON MEMORIAL HWY FOSTER VA 23056 USA "
    },
    {
      "Location ID": "23061",
      "Location Name": "GLOUCESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6892 MAIN ST GLOUCESTER VA 23061 USA "
    },
    {
      "Location ID": "23062",
      "Location Name": "GLOUCESTER POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1451 GEORGE WASHINGTON MEMORIAL HWY GLOUCESTER POINT VA 23062 USA "
    },
    {
      "Location ID": "23063",
      "Location Name": "GOOCHLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2972 RIVER RD W GOOCHLAND VA 23063 USA "
    },
    {
      "Location ID": "23069",
      "Location Name": "HANOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13228 HANOVER COURTHOUSE RD HANOVER VA 23069 USA "
    },
    {
      "Location ID": "23070",
      "Location Name": "HARDYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15224 GENERAL PULLER HWY HARDYVILLE VA 23070 USA "
    },
    {
      "Location ID": "23071",
      "Location Name": "HARTFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 GRAFTON CHURCH RD HARTFIELD VA 23071 USA "
    },
    {
      "Location ID": "23072",
      "Location Name": "HAYES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2344 GEORGE WASHINGTON MEMORIAL HWY HAYES VA 23072 USA "
    },
    {
      "Location ID": "23076",
      "Location Name": "HUDGINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8251 BUCKLEY HALL RD HUDGINS VA 23076 USA "
    },
    {
      "Location ID": "23083",
      "Location Name": "JETERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21595 JETERSVILLE RD JETERSVILLE VA 23083 USA "
    },
    {
      "Location ID": "23084",
      "Location Name": "KENTS STORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 KENTS STORE WAY KENTS STORE VA 23084 USA "
    },
    {
      "Location ID": "23086",
      "Location Name": "KING WILLIAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 HORSE LANDING RD KING WILLIAM VA 23086 USA "
    },
    {
      "Location ID": "23089",
      "Location Name": "LANEXA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15545 POCAHONTAS TRL LANEXA VA 23089 USA "
    },
    {
      "Location ID": "23090",
      "Location Name": "LIGHTFOOT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6376 RICHMOND RD LIGHTFOOT VA 23090 USA "
    },
    {
      "Location ID": "23092",
      "Location Name": "LOCUST HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5924 GENERAL PULLER HWY LOCUST HILL VA 23092 USA "
    },
    {
      "Location ID": "23093",
      "Location Name": "LOUISA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 204 W MAIN ST LOUISA VA 23093 USA "
    },
    {
      "Location ID": "230AN",
      "Location Name": "RICHMOND ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13721 JEFFERSON DAVIS HWY CHESTER VA 23831 USA "
    },
    {
      "Location ID": "230MR",
      "Location Name": "GLEN ALLEN ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3017 MOUNTAIN RD GLEN ALLEN VA 23060 USA "
    },
    {
      "Location ID": "230PS",
      "Location Name": "PSI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11720 N. LAKERIDGE PKWY ASHLAND VA 23005 USA "
    },
    {
      "Location ID": "23102",
      "Location Name": "MAIDENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2746 MAIDENS LOOP STE E MAIDENS VA 23102 USA "
    },
    {
      "Location ID": "23103",
      "Location Name": "MANAKIN SABOT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 334 RIVER RD W MANAKIN SABOT VA 23103 USA "
    },
    {
      "Location ID": "23106",
      "Location Name": "MANQUIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2171 RICHMOND TAPPAHANNOCK HWY MANQUIN VA 23106 USA "
    },
    {
      "Location ID": "23109",
      "Location Name": "MATHEWS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 161 MAIN ST MATHEWS VA 23109 USA "
    },
    {
      "Location ID": "23110",
      "Location Name": "MATTAPONI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6877 LEWIS B PULLER MEMORIAL HWY MATTAPONI VA 23110 USA "
    },
    {
      "Location ID": "23112",
      "Location Name": "GENITO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3530 POST OFFICE RD MIDLOTHIAN VA 23112 USA "
    },
    {
      "Location ID": "23113",
      "Location Name": "MIDLOTHIAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1201 SYCAMORE SQUARE DR MIDLOTHIAN VA 23113 USA "
    },
    {
      "Location ID": "23115",
      "Location Name": "MILLERS TAVERN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6908 RICHMOND HWY MILLERS TAVERN VA 23115 USA "
    },
    {
      "Location ID": "23117",
      "Location Name": "MINERAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 512 MINERAL AVE MINERAL VA 23117 USA "
    },
    {
      "Location ID": "23120",
      "Location Name": "MOSELEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21431 HULL STREET RD MOSELEY VA 23120 USA "
    },
    {
      "Location ID": "23123",
      "Location Name": "NEW CANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3708 C G WOODSON RD NEW CANTON VA 23123 USA "
    },
    {
      "Location ID": "23124",
      "Location Name": "NEW KENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12109 NEW KENT HWY NEW KENT VA 23124 USA "
    },
    {
      "Location ID": "23127",
      "Location Name": "NORGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7489 RICHMOND RD NORGE VA 23127 USA "
    },
    {
      "Location ID": "23128",
      "Location Name": "NORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13846 JOHN CLAYTON MEMORIAL HWY NORTH VA 23128 USA "
    },
    {
      "Location ID": "23129",
      "Location Name": "OILVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1411 BROAD STREET RD OILVILLE VA 23129 USA "
    },
    {
      "Location ID": "23131",
      "Location Name": "ORDINARY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3992 GEORGE WASHINGTON MEMORIAL HWY ORDINARY VA 23131 USA "
    },
    {
      "Location ID": "23139",
      "Location Name": "POWHATAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4195 ANDERSON HWY POWHATAN VA 23139 USA "
    },
    {
      "Location ID": "23140",
      "Location Name": "PROVIDENCE FORGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3511 N COURTHOUSE RD PROVIDENCE FORGE VA 23140 USA "
    },
    {
      "Location ID": "23141",
      "Location Name": "QUINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2510 NEW KENT HWY QUINTON VA 23141 USA "
    },
    {
      "Location ID": "23146",
      "Location Name": "ROCKVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16590 POUNCEY TRACT RD ROCKVILLE VA 23146 USA "
    },
    {
      "Location ID": "23147",
      "Location Name": "RUTHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13121 THE GLEBE LN RUTHVILLE VA 23147 USA "
    },
    {
      "Location ID": "23148",
      "Location Name": "SAINT STEPHENS CHURCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13280 RICHMOND TAPPAHANNOCK HWY SAINT STEPHENS CHURCH VA 23148 USA "
    },
    {
      "Location ID": "23149",
      "Location Name": "SALUDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 880 GLOUCESTER RD SALUDA VA 23149 USA "
    },
    {
      "Location ID": "23150",
      "Location Name": "SANDSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 W WILLIAMSBURG RD SANDSTON VA 23150 USA "
    },
    {
      "Location ID": "23156",
      "Location Name": "SHACKLEFORDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30165 THE TRL SHACKLEFORDS VA 23156 USA "
    },
    {
      "Location ID": "23162",
      "Location Name": "STUDLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5411 STUDLEY RD STUDLEY VA 23162 USA "
    },
    {
      "Location ID": "23168",
      "Location Name": "TOANO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7890 RICHMOND RD TOANO VA 23168 USA "
    },
    {
      "Location ID": "23169",
      "Location Name": "TOPPING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 882 GREYS POINT RD TOPPING VA 23169 USA "
    },
    {
      "Location ID": "23175",
      "Location Name": "URBANNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 251 VIRGINIA ST URBANNA VA 23175 USA "
    },
    {
      "Location ID": "23176",
      "Location Name": "WAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1360 WAKE RD WAKE VA 23176 USA "
    },
    {
      "Location ID": "23177",
      "Location Name": "WALKERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2954 WALKERTON LANDING RD WALKERTON VA 23177 USA "
    },
    {
      "Location ID": "23178",
      "Location Name": "WARE NECK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6495 WARE NECK RD WARE NECK VA 23178 USA "
    },
    {
      "Location ID": "23181",
      "Location Name": "WEST POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 925 MAIN ST WEST POINT VA 23181 USA "
    },
    {
      "Location ID": "23183",
      "Location Name": "WHITE MARSH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4898 GEORGE WASHINGTON MEMORIAL HWY WHITE MARSH VA 23183 USA "
    },
    {
      "Location ID": "23184",
      "Location Name": "WICOMICO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6926 POWHATAN DR WICOMICO VA 23184 USA "
    },
    {
      "Location ID": "23185",
      "Location Name": "WILLIAMSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 425 N BOUNDARY ST WILLIAMSBURG VA 23185 USA "
    },
    {
      "Location ID": "23187",
      "Location Name": "WILLIAMSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 425 N BOUNDARY ST WILLIAMSBURG VA 23185 USA "
    },
    {
      "Location ID": "23188",
      "Location Name": "MONTICELLO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5219 MONTICELLO AVE WILLIAMSBURG VA 23188 USA "
    },
    {
      "Location ID": "23190",
      "Location Name": "WOODS CROSS ROADS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9900 DAVENPORT RD WOODS CROSS ROAD VA 23190 USA "
    },
    {
      "Location ID": "23192",
      "Location Name": "MONTPELIER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17132 MOUNTAIN RD MONTPELIER VA 23192 USA "
    },
    {
      "Location ID": "231LM",
      "Location Name": "LATCHED MAMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2935 OAK LAKE BLVD MIDLOTHIAN VA 23112 USA "
    },
    {
      "Location ID": "231UP",
      "Location Name": "UPS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1400 LISLE RD RICHMOND VA 23250 USA "
    },
    {
      "Location ID": "23232",
      "Location Name": "RICHMOND MAIN OFFICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1801 BROOK RD RICHMOND VA 23232 USA "
    },
    {
      "Location ID": "232CJ",
      "Location Name": "CADMUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2901 BYRDHILL RD HENRICO VA 23228 USA "
    },
    {
      "Location ID": "232DM",
      "Location Name": "DIRECT MAIL SOLUTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4500 SARELLEN RD HENRICO VA 23231 USA "
    },
    {
      "Location ID": "232PR",
      "Location Name": "PRODUCTIV INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1801 WILLIS RD NORTH CHESTERFI VA 23237 USA "
    },
    {
      "Location ID": "233",
      "Location Name": "LOG NORFOLK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 CHURCH ST NORFOLK VA 23501 USA "
    },
    {
      "Location ID": "23301",
      "Location Name": "ACCOMAC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23380 FRONT ST ACCOMAC VA 23301 USA "
    },
    {
      "Location ID": "23304",
      "Location Name": "BATTERY PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20512 TODD AVE BATTERY PARK VA 23304 USA "
    },
    {
      "Location ID": "23310",
      "Location Name": "CAPE CHARLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 299 RANDOLPH AVE CAPE CHARLES VA 23310 USA "
    },
    {
      "Location ID": "23313",
      "Location Name": "CAPEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4511 CAPEVILLE RD CAPEVILLE VA 23313 USA "
    },
    {
      "Location ID": "23314",
      "Location Name": "CARROLLTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15108 CARROLLTON BLVD CARROLLTON VA 23314 USA "
    },
    {
      "Location ID": "23315",
      "Location Name": "CARRSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5354 CARRSVILLE HWY CARRSVILLE VA 23315 USA "
    },
    {
      "Location ID": "23316",
      "Location Name": "CHERITON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21197 N BAYSIDE RD CHERITON VA 23316 USA "
    },
    {
      "Location ID": "23320",
      "Location Name": "CHESAPEAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1425 BATTLEFIELD BLVD N CHESAPEAKE VA 23320 USA "
    },
    {
      "Location ID": "23321",
      "Location Name": "JOLLIFF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4417 PORTSMOUTH BLVD CHESAPEAKE VA 23321 USA "
    },
    {
      "Location ID": "23322",
      "Location Name": "GREAT BRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1100 BATTLEFIELD BLVD S CHESAPEAKE VA 23322 USA "
    },
    {
      "Location ID": "23323",
      "Location Name": "DEEP CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 857 GEORGE WASHINGTON HWY N CHESAPEAKE VA 23323 USA "
    },
    {
      "Location ID": "23325",
      "Location Name": "INDIAN RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3810 INDIAN RIVER RD CHESAPEAKE VA 23325 USA "
    },
    {
      "Location ID": "23336",
      "Location Name": "CHINCOTEAGUE ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4144 MAIN ST CHINCOTEAGUE ISLAND VA 23336 USA "
    },
    {
      "Location ID": "23347",
      "Location Name": "EASTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5253 WILLOW OAK RD EASTVILLE VA 23347 USA "
    },
    {
      "Location ID": "23350",
      "Location Name": "EXMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12144 BANK AVE EXMORE VA 23350 USA "
    },
    {
      "Location ID": "23389",
      "Location Name": "HARBORTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28059 DOCKVIEW ST HARBORTON VA 23389 USA "
    },
    {
      "Location ID": "23397",
      "Location Name": "ISLE OF WIGHT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17161 COURTHOUSE HWY ISLE OF WIGHT VA 23397 USA "
    },
    {
      "Location ID": "233AA",
      "Location Name": "JOLLIFF RETAIL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4300 PORTSMOUTH BLVD STE 174 CHESAPEAKE VA 23321 USA "
    },
    {
      "Location ID": "233CX",
      "Location Name": "NORFOLK CHRISTMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3512 BUSINESS CENTER DR CHESAPEAKE VA 23323 USA "
    },
    {
      "Location ID": "233FZ",
      "Location Name": "LOG NORFOLK VA PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3512 BUSINESS CENTER DR CHESAPEAKE VA 23323 USA "
    },
    {
      "Location ID": "233SN",
      "Location Name": "SOUTH NORFOLK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1210 POINDEXTER ST CHESAPEAKE VA 23324 USA "
    },
    {
      "Location ID": "23401",
      "Location Name": "KELLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18243 1ST ST KELLER VA 23401 USA "
    },
    {
      "Location ID": "23405",
      "Location Name": "MACHIPONGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7414 MACHIPONGO DR MACHIPONGO VA 23405 USA "
    },
    {
      "Location ID": "23407",
      "Location Name": "MAPPSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14148 LANKFORD HWY MAPPSVILLE VA 23407 USA "
    },
    {
      "Location ID": "23408",
      "Location Name": "MARIONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9095 SEASIDE RD MARIONVILLE VA 23408 USA "
    },
    {
      "Location ID": "23410",
      "Location Name": "MELFA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19268 AIRPORT DR MELFA VA 23410 USA "
    },
    {
      "Location ID": "23413",
      "Location Name": "NASSAWADOX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10238 ROGERS RD NASSAWADOX VA 23413 USA "
    },
    {
      "Location ID": "23414",
      "Location Name": "NELSONIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16150 LANKFORD HWY NELSONIA VA 23414 USA "
    },
    {
      "Location ID": "23415",
      "Location Name": "NEW CHURCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4136 LANKFORD HWY NEW CHURCH VA 23415 USA "
    },
    {
      "Location ID": "23416",
      "Location Name": "OAK HALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8133 LANKFORD HWY OAK HALL VA 23416 USA "
    },
    {
      "Location ID": "23417",
      "Location Name": "ONANCOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 MARKET ST ONANCOCK VA 23417 USA "
    },
    {
      "Location ID": "23418",
      "Location Name": "ONLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25468 COASTAL BLVD ONLEY VA 23418 USA "
    },
    {
      "Location ID": "23420",
      "Location Name": "PAINTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33280 HICKMAN ST PAINTER VA 23420 USA "
    },
    {
      "Location ID": "23421",
      "Location Name": "PARKSLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24290 COOKE ST PARKSLEY VA 23421 USA "
    },
    {
      "Location ID": "23422",
      "Location Name": "PUNGOTEAGUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30296 BOBTOWN RD PUNGOTEAGUE VA 23422 USA "
    },
    {
      "Location ID": "23423",
      "Location Name": "QUINBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35369 UPSHURS NECK RD QUINBY VA 23423 USA "
    },
    {
      "Location ID": "23424",
      "Location Name": "RESCUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21148 RESCUE RD RESCUE VA 23424 USA "
    },
    {
      "Location ID": "23430",
      "Location Name": "SMITHFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 234 MAIN ST SMITHFIELD VA 23430 USA "
    },
    {
      "Location ID": "23432",
      "Location Name": "CHUCKATUCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 KINGS HWY SUFFOLK VA 23432 USA "
    },
    {
      "Location ID": "23433",
      "Location Name": "CRITTENDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1605 BRIDGE RD SUFFOLK VA 23433 USA "
    },
    {
      "Location ID": "23434",
      "Location Name": "SUFFOLK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 445 N MAIN ST SUFFOLK VA 23434 USA "
    },
    {
      "Location ID": "23435",
      "Location Name": "DRIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4897 BENNETTS PASTURE RD SUFFOLK VA 23435 USA "
    },
    {
      "Location ID": "23437",
      "Location Name": "HOLLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6725 SOUTH QUAY ROAD SUFFOLK VA 23437 USA "
    },
    {
      "Location ID": "23438",
      "Location Name": "WHALEYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6425 WHALEYVILLE BLVD SUFFOLK VA 23438 USA "
    },
    {
      "Location ID": "23443",
      "Location Name": "TOWNSEND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28463 SEASIDE RD TOWNSEND VA 23443 USA "
    },
    {
      "Location ID": "23480",
      "Location Name": "WACHAPREAGUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 MAIN ST WACHAPREAGUE VA 23480 USA "
    },
    {
      "Location ID": "23487",
      "Location Name": "WINDSOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 E WINDSOR BLVD WINDSOR VA 23487 USA "
    },
    {
      "Location ID": "234AX",
      "Location Name": "ACREDALE ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1700 CENTERVILLE TPKE VIRGINIA BEACH VA 23464 USA "
    },
    {
      "Location ID": "23501",
      "Location Name": "NORFOLK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 CHURCH ST NORFOLK VA 23501 USA "
    },
    {
      "Location ID": "23511",
      "Location Name": "JOINT FORCES STAFF COLLEGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7800 HAMPTON BLVD NORFOLK VA 23511 USA "
    },
    {
      "Location ID": "23518",
      "Location Name": "L C PAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2461 E LITTLE CREEK RD NORFOLK VA 23518 USA "
    },
    {
      "Location ID": "23521",
      "Location Name": "WEST ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1224 D ST VIRGINIA BEACH VA 23459 USA "
    },
    {
      "Location ID": "235BC",
      "Location Name": "BIG CHIMNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4506 PENNSYLVANIA AVE CHARLESTON WV 25302-9997 USA "
    },
    {
      "Location ID": "23601",
      "Location Name": "EASTPOINTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 CAVA DRIVE CLARKSBURG WV 26301-0002 USA "
    },
    {
      "Location ID": "23670",
      "Location Name": "HAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 809 ABERDEEN RD HAMPTON VA 23670 USA "
    },
    {
      "Location ID": "236AX",
      "Location Name": "HIDENWOOD ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 759 J CLYDE MORRIS BLVD NEWPORT NEWS VA 23601 USA "
    },
    {
      "Location ID": "236CF",
      "Location Name": "CANON FACTORY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6000 INDUSTRIAL DR GLOUCESTER VA 23061 USA "
    },
    {
      "Location ID": "238",
      "Location Name": "PETERSBURG MPO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 FRANKLIN ST PETERSBURG VA 23803 USA "
    },
    {
      "Location ID": "23801",
      "Location Name": "FORT LEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1400 MAHONE AVE FORT LEE VA 23801 USA "
    },
    {
      "Location ID": "23803",
      "Location Name": "PETERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 FRANKLIN ST PETERSBURG VA 23803 USA "
    },
    {
      "Location ID": "23805",
      "Location Name": "WALNUT HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3131 S CRATER RD PETERSBURG VA 23805 USA "
    },
    {
      "Location ID": "23821",
      "Location Name": "ALBERTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 642 MAIN ST ALBERTA VA 23821 USA "
    },
    {
      "Location ID": "23824",
      "Location Name": "BLACKSTONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 S MAIN ST BLACKSTONE VA 23824 USA "
    },
    {
      "Location ID": "23827",
      "Location Name": "BOYKINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 32129 N MAIN ST BOYKINS VA 23827 USA "
    },
    {
      "Location ID": "23828",
      "Location Name": "BRANCHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33143 WOODARD ST BRANCHVILLE VA 23828 USA "
    },
    {
      "Location ID": "23829",
      "Location Name": "CAPRON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23021 MAIN ST CAPRON VA 23829 USA "
    },
    {
      "Location ID": "23830",
      "Location Name": "CARSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17206 HALLIGAN PARK RD CARSON VA 23830 USA "
    },
    {
      "Location ID": "23831",
      "Location Name": "CHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12300 ROCK HILL RD CHESTER VA 23831 USA "
    },
    {
      "Location ID": "23832",
      "Location Name": "CHESTERFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10221 KRAUSE RD CHESTERFIELD VA 23832 USA "
    },
    {
      "Location ID": "23833",
      "Location Name": "CHURCH ROAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15300 COX RD CHURCH ROAD VA 23833 USA "
    },
    {
      "Location ID": "23834",
      "Location Name": "COLONIAL HEIGHTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1205 BOULEVARD COLONIAL HEIGHTS VA 23834 USA "
    },
    {
      "Location ID": "23837",
      "Location Name": "COURTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22183 MAIN ST COURTLAND VA 23837 USA "
    },
    {
      "Location ID": "23840",
      "Location Name": "DEWITT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16907 BOYDTON PLANK RD DEWITT VA 23840 USA "
    },
    {
      "Location ID": "23841",
      "Location Name": "DINWIDDIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14416 COURTHOUSE RD DINWIDDIE VA 23841 USA "
    },
    {
      "Location ID": "23842",
      "Location Name": "DISPUTANTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10001 COUNTY DR DISPUTANTA VA 23842 USA "
    },
    {
      "Location ID": "23843",
      "Location Name": "DOLPHIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7593 LIBERTY RD DOLPHIN VA 23843 USA "
    },
    {
      "Location ID": "23844",
      "Location Name": "DREWRYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22365 DREWRY RD DREWRYVILLE VA 23844 USA "
    },
    {
      "Location ID": "23847",
      "Location Name": "EMPORIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 S MAIN ST EMPORIA VA 23847 USA "
    },
    {
      "Location ID": "23850",
      "Location Name": "FORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9109 BALTIMORE RD FORD VA 23850 USA "
    },
    {
      "Location ID": "23851",
      "Location Name": "FRANKLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 519 N MAIN ST FRANKLIN VA 23851 USA "
    },
    {
      "Location ID": "23856",
      "Location Name": "FREEMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18031 GOVERNOR HARRISON PKWY FREEMAN VA 23856 USA "
    },
    {
      "Location ID": "23860",
      "Location Name": "HOPEWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 W POYTHRESS ST HOPEWELL VA 23860 USA "
    },
    {
      "Location ID": "23866",
      "Location Name": "IVOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8280 MAIN ST IVOR VA 23866 USA "
    },
    {
      "Location ID": "23867",
      "Location Name": "JARRATT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 JARRATT AVE JARRATT VA 23867 USA "
    },
    {
      "Location ID": "23868",
      "Location Name": "LAWRENCEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 409 S HICKS ST LAWRENCEVILLE VA 23868 USA "
    },
    {
      "Location ID": "23872",
      "Location Name": "MC KENNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10504 RIVES AVE MC KENNEY VA 23872 USA "
    },
    {
      "Location ID": "23873",
      "Location Name": "MEREDITHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4771 BOYDTON PLANK RD MEREDITHVILLE VA 23873 USA "
    },
    {
      "Location ID": "23874",
      "Location Name": "NEWSOMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29174 S MAIN ST NEWSOMS VA 23874 USA "
    },
    {
      "Location ID": "23875",
      "Location Name": "PRINCE GEORGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6605 COURTHOUSE RD PRINCE GEORGE VA 23875 USA "
    },
    {
      "Location ID": "23876",
      "Location Name": "RAWLINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4818 RAWLINGS RD RAWLINGS VA 23876 USA "
    },
    {
      "Location ID": "23878",
      "Location Name": "SEDLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18050 JOHNSONS MILL RD SEDLEY VA 23878 USA "
    },
    {
      "Location ID": "23881",
      "Location Name": "SPRING GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 SWANNS POINT RD SPRING GROVE VA 23881 USA "
    },
    {
      "Location ID": "23882",
      "Location Name": "STONY CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12445 MAIN ST STONY CREEK VA 23882 USA "
    },
    {
      "Location ID": "23883",
      "Location Name": "SURRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 91 COLONIAL TRL E SURRY VA 23883 USA "
    },
    {
      "Location ID": "23884",
      "Location Name": "SUSSEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15083 COURTHOUSE RD SUSSEX VA 23884 USA "
    },
    {
      "Location ID": "23885",
      "Location Name": "SUTHERLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5503 HART RD SUTHERLAND VA 23885 USA "
    },
    {
      "Location ID": "23888",
      "Location Name": "WAKEFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 312 W CHURCH ST WAKEFIELD VA 23888 USA "
    },
    {
      "Location ID": "23889",
      "Location Name": "WARFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15243 BOYDTON PLANK RD WARFIELD VA 23889 USA "
    },
    {
      "Location ID": "23890",
      "Location Name": "WAVERLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 325 W MAIN ST WAVERLY VA 23890 USA "
    },
    {
      "Location ID": "23894",
      "Location Name": "WILSONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2120 SPRINGTON RD WILSONS VA 23894 USA "
    },
    {
      "Location ID": "23897",
      "Location Name": "YALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20123 COURTHOUSE RD STE A YALE VA 23897 USA "
    },
    {
      "Location ID": "23898",
      "Location Name": "ZUNI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5375 WINDSOR BLVD ZUNI VA 23898 USA "
    },
    {
      "Location ID": "23899",
      "Location Name": "CLAREMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3962 SPRING GROVE AVE CLAREMONT VA 23899 USA "
    },
    {
      "Location ID": "238BP",
      "Location Name": "BELMONT PEANUTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22420 SOUTHAMPTON PKWY COURTLAND VA 23837 USA "
    },
    {
      "Location ID": "238FL",
      "Location Name": "FORT LEE PX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 A AVE BLDG 1605 FORT LEE VA 23801 USA "
    },
    {
      "Location ID": "238FP",
      "Location Name": "FERIDIES PEANUTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28285 MILL CREEK DR COURTLAND VA 23837 USA "
    },
    {
      "Location ID": "238GL",
      "Location Name": "GRIGGS LAWN & TRACTOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28028 SOUTHAMPTON PKWY COURTLAND VA 23837 USA "
    },
    {
      "Location ID": "238MT",
      "Location Name": "METAGENICS - SOUTH CHESTERFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1928 RUFFIN MILL RD SOUTH CHESTERFIELD VA 23834 USA "
    },
    {
      "Location ID": "238WM",
      "Location Name": "WAL-MART SUTHERLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21510 COX RD SUTHERLAND VA 23885 USA "
    },
    {
      "Location ID": "239",
      "Location Name": "FARMVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 E 3RD ST FARMVILLE VA 23901 USA "
    },
    {
      "Location ID": "23901",
      "Location Name": "FARMVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 E 3RD ST FARMVILLE VA 23901 USA "
    },
    {
      "Location ID": "23915",
      "Location Name": "BASKERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4444 BASKERVILLE RD BASKERVILLE VA 23915 USA "
    },
    {
      "Location ID": "23917",
      "Location Name": "BOYDTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 419 MADISON ST BOYDTON VA 23917 USA "
    },
    {
      "Location ID": "23919",
      "Location Name": "BRACEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3913 HIGHWAY NINE O THREE BRACEY VA 23919 USA "
    },
    {
      "Location ID": "23920",
      "Location Name": "BRODNAX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 460 PINEY POND RD BRODNAX VA 23920 USA "
    },
    {
      "Location ID": "23921",
      "Location Name": "BUCKINGHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4241 OAK HILL RD BUCKINGHAM VA 23921 USA "
    },
    {
      "Location ID": "23922",
      "Location Name": "BURKEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 204 S AGNEW ST BURKEVILLE VA 23922 USA "
    },
    {
      "Location ID": "23923",
      "Location Name": "CHARLOTTE COURT HOUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 140 DAVID BRUCE AVE CHARLOTTE COURT HOUSE VA 23923 USA "
    },
    {
      "Location ID": "23924",
      "Location Name": "CHASE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 960 N MAIN ST CHASE CITY VA 23924 USA "
    },
    {
      "Location ID": "23927",
      "Location Name": "CLARKSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 4TH ST CLARKSVILLE VA 23927 USA "
    },
    {
      "Location ID": "23930",
      "Location Name": "CREWE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 W TENNESSEE AVE CREWE VA 23930 USA "
    },
    {
      "Location ID": "23934",
      "Location Name": "CULLEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4304 THOMAS JEFFERSON HWY CULLEN VA 23934 USA "
    },
    {
      "Location ID": "23936",
      "Location Name": "DILLWYN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16625 OAK ST DILLWYN VA 23936 USA "
    },
    {
      "Location ID": "23937",
      "Location Name": "DRAKES BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4871 DRAKES MAIN ST DRAKES BRANCH VA 23937 USA "
    },
    {
      "Location ID": "23938",
      "Location Name": "DUNDAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4091 DUNDAS RD DUNDAS VA 23938 USA "
    },
    {
      "Location ID": "23939",
      "Location Name": "EVERGREEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7077 OLD EVERGREEN RD EVERGREEN VA 23939 USA "
    },
    {
      "Location ID": "23942",
      "Location Name": "GREEN BAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8278 W PATRICK HENRY HWY GREEN BAY VA 23942 USA "
    },
    {
      "Location ID": "23943",
      "Location Name": "HAMPDEN SYDNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 GRAHAM CIRCLE DR HAMPDEN SYDNEY VA 23943 USA "
    },
    {
      "Location ID": "23944",
      "Location Name": "KENBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 E 5TH AVE KENBRIDGE VA 23944 USA "
    },
    {
      "Location ID": "23947",
      "Location Name": "KEYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 225 KING ST KEYSVILLE VA 23947 USA "
    },
    {
      "Location ID": "23950",
      "Location Name": "LA CROSSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 SAINT TAMMANY RD LA CROSSE VA 23950 USA "
    },
    {
      "Location ID": "23952",
      "Location Name": "LUNENBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11452 COURTHOUSE RD LUNENBURG VA 23952 USA "
    },
    {
      "Location ID": "23954",
      "Location Name": "MEHERRIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 495 MOORES ORDINARY RD MEHERRIN VA 23954 USA "
    },
    {
      "Location ID": "23955",
      "Location Name": "NOTTOWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 329 W COURTHOUSE RD NOTTOWAY VA 23955 USA "
    },
    {
      "Location ID": "23958",
      "Location Name": "PAMPLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 CHURCH ST PAMPLIN VA 23958 USA "
    },
    {
      "Location ID": "23959",
      "Location Name": "PHENIX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6836 PHENIX MAIN ST PHENIX VA 23959 USA "
    },
    {
      "Location ID": "23960",
      "Location Name": "PROSPECT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 152 PROSPECT RD PROSPECT VA 23960 USA "
    },
    {
      "Location ID": "23962",
      "Location Name": "RANDOLPH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6000 ROANOKE STATION RD RANDOLPH VA 23962 USA "
    },
    {
      "Location ID": "23963",
      "Location Name": "RED HOUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9699 RED HOUSE RD RED HOUSE VA 23963 USA "
    },
    {
      "Location ID": "23964",
      "Location Name": "RED OAK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6025 BARNESVILLE HWY RED OAK VA 23964 USA "
    },
    {
      "Location ID": "23966",
      "Location Name": "RICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 658 RICES DEPOT RD RICE VA 23966 USA "
    },
    {
      "Location ID": "23967",
      "Location Name": "SAXE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 180 HAILEY RD SAXE VA 23967 USA "
    },
    {
      "Location ID": "23968",
      "Location Name": "SKIPWITH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6000 SKIPWITH RD SKIPWITH VA 23968 USA "
    },
    {
      "Location ID": "23970",
      "Location Name": "SOUTH HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 216 E FERRELL ST SOUTH HILL VA 23970 USA "
    },
    {
      "Location ID": "23974",
      "Location Name": "VICTORIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1715 MAIN ST VICTORIA VA 23974 USA "
    },
    {
      "Location ID": "23976",
      "Location Name": "WYLLIESBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 790 BARNESVILLE HWY WYLLIESBURG VA 23976 USA "
    },
    {
      "Location ID": "240",
      "Location Name": "ROANOKE P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 419 RUTHERFORD AVE NE ROANOKE VA 24022 USA "
    },
    {
      "Location ID": "24011",
      "Location Name": "DOWNTOWN ROANOKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 CHURCH AVE SW ROANOKE VA 24011 USA "
    },
    {
      "Location ID": "24014",
      "Location Name": "SOUTH ROANOKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2229 CRYSTAL SPRING AVE SW ROANOKE VA 24014 USA "
    },
    {
      "Location ID": "24015",
      "Location Name": "GRANDIN ROAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1733 GRANDIN RD SW ROANOKE VA 24015 USA "
    },
    {
      "Location ID": "24017",
      "Location Name": "MELROSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3018 MELROSE AVE NW ROANOKE VA 24017 USA "
    },
    {
      "Location ID": "24018",
      "Location Name": "CAVE SPRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4069 POSTAL DR ROANOKE VA 24018 USA "
    },
    {
      "Location ID": "24019",
      "Location Name": "HOLLINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6621 WILLIAMSON RD ROANOKE VA 24019 USA "
    },
    {
      "Location ID": "24054",
      "Location Name": "AXTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1725 DANIEL RD AXTON VA 24054 USA "
    },
    {
      "Location ID": "24055",
      "Location Name": "BASSETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3465 FAIRYSTONE PARK HWY BASSETT VA 24055 USA "
    },
    {
      "Location ID": "24058",
      "Location Name": "BELSPRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7893 NECK CREEK RD BELSPRING VA 24058 USA "
    },
    {
      "Location ID": "24059",
      "Location Name": "BENT MOUNTAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9961 BENT MOUNTAIN RD BENT MOUNTAIN VA 24059 USA "
    },
    {
      "Location ID": "24060",
      "Location Name": "BLACKSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 909 UNIVERSITY CITY BLVD BLACKSBURG VA 24060 USA "
    },
    {
      "Location ID": "24064",
      "Location Name": "BLUE RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3521 BLUE RIDGE BLVD BLUE RIDGE VA 24064 USA "
    },
    {
      "Location ID": "24065",
      "Location Name": "BOONES MILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 MAIN ST BOONES MILL VA 24065 USA "
    },
    {
      "Location ID": "24066",
      "Location Name": "BUCHANAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19698 MAIN ST BUCHANAN VA 24066 USA "
    },
    {
      "Location ID": "24067",
      "Location Name": "CALLAWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7855 CALLAWAY RD CALLAWAY VA 24067 USA "
    },
    {
      "Location ID": "24069",
      "Location Name": "CASCADE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5860 CASCADE RD CASCADE VA 24069 USA "
    },
    {
      "Location ID": "24070",
      "Location Name": "CATAWBA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4917 CATAWBA CREEK RD CATAWBA VA 24070 USA "
    },
    {
      "Location ID": "24072",
      "Location Name": "CHECK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 168 MEADOW RUN RD SE CHECK VA 24072 USA "
    },
    {
      "Location ID": "24073",
      "Location Name": "CHRISTIANSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 350 ARBOR DR CHRISTIANSBURG VA 24073 USA "
    },
    {
      "Location ID": "24077",
      "Location Name": "CLOVERDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4518 READ MOUNTAIN RD CLOVERDALE VA 24077 USA "
    },
    {
      "Location ID": "24078",
      "Location Name": "COLLINSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3365 VIRGINIA AVE COLLINSVILLE VA 24078 USA "
    },
    {
      "Location ID": "24079",
      "Location Name": "COPPER HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8480 FLOYD HWY N COPPER HILL VA 24079 USA "
    },
    {
      "Location ID": "24082",
      "Location Name": "CRITZ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2658 ABRAM PENN HWY CRITZ VA 24082 USA "
    },
    {
      "Location ID": "24083",
      "Location Name": "DALEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1492 ROANOKE RD DALEVILLE VA 24083 USA "
    },
    {
      "Location ID": "24084",
      "Location Name": "DUBLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 TOWN CENTER DR DUBLIN VA 24084 USA "
    },
    {
      "Location ID": "24085",
      "Location Name": "EAGLE ROCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14630 CHURCH ST EAGLE ROCK VA 24085 USA "
    },
    {
      "Location ID": "24086",
      "Location Name": "EGGLESTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3618 CHURCH HILL RD EGGLESTON VA 24086 USA "
    },
    {
      "Location ID": "24087",
      "Location Name": "ELLISTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9420 ROANOKE RD ELLISTON VA 24087 USA "
    },
    {
      "Location ID": "24088",
      "Location Name": "FERRUM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 57 FIELDCREST RD FERRUM VA 24088 USA "
    },
    {
      "Location ID": "24089",
      "Location Name": "FIELDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 MARSHALL WAY FIELDALE VA 24089 USA "
    },
    {
      "Location ID": "24090",
      "Location Name": "FINCASTLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 223 S ROANOKE ST FINCASTLE VA 24090 USA "
    },
    {
      "Location ID": "24091",
      "Location Name": "FLOYD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 819 E MAIN ST FLOYD VA 24091 USA "
    },
    {
      "Location ID": "24092",
      "Location Name": "GLADE HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7300 OLD FRANKLIN TPKE GLADE HILL VA 24092 USA "
    },
    {
      "Location ID": "24093",
      "Location Name": "GLEN LYN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 DAVIS AVE GLEN LYN VA 24093 USA "
    },
    {
      "Location ID": "24095",
      "Location Name": "GOODVIEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7315 GOODVIEW RD GOODVIEW VA 24095 USA "
    },
    {
      "Location ID": "240AA",
      "Location Name": "DOWNTOWN CHRISTIANSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 E MAIN ST CHRISTIANSBURG VA 24073 USA "
    },
    {
      "Location ID": "240BC",
      "Location Name": "BACK COUNTRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 295 TECHNOLOGY DR CHRISTIANSBURG VA 24073 USA "
    },
    {
      "Location ID": "240CA",
      "Location Name": "ROANOKE CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1515 COURTLAND RD NE ROANOKE VA 24012 USA "
    },
    {
      "Location ID": "240DN",
      "Location Name": "DOWNTOWN BLACKSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 N MAIN ST BLACKSBURG VA 24060 USA "
    },
    {
      "Location ID": "240EC",
      "Location Name": "Ecommerce",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7729 MYERS ROAD ROANOKE VA 24019 USA "
    },
    {
      "Location ID": "240FL",
      "Location Name": "FOOT LEVELERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 518 POCAHONTAS AVE ROANOKE VA 24012 USA "
    },
    {
      "Location ID": "240HH",
      "Location Name": "HENRYS HEALTHY PETS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 158 PIONEER WAY SUITE 1 FLOYD VA 24091 USA "
    },
    {
      "Location ID": "240HS",
      "Location Name": "HOME SHOPPING NETWORK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 AVERY ROW ROANOKE VA 24012 USA "
    },
    {
      "Location ID": "240JC",
      "Location Name": "JTC ENTERPRISE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 158 PIONEER WAY SUITE 5B FLOYD VA 24091 USA "
    },
    {
      "Location ID": "240OV",
      "Location Name": "ORVISS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1711 BLUE HILLS DR NE ROANOKE VA 24012 USA "
    },
    {
      "Location ID": "240RR",
      "Location Name": "RED ROOSTER COFFEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 823 E MAIN ST FLOYD VA 24091 USA "
    },
    {
      "Location ID": "240VT",
      "Location Name": "VT Mailroom",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1425 S MAIN ST BLACKSBURG VA 24060 USA "
    },
    {
      "Location ID": "24101",
      "Location Name": "HARDY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7210 HARDY RD HARDY VA 24101 USA "
    },
    {
      "Location ID": "24102",
      "Location Name": "HENRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 TOWNE CREEK RD HENRY VA 24102 USA "
    },
    {
      "Location ID": "24104",
      "Location Name": "HUDDLESTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1080 TOLERS FERRY RD HUDDLESTON VA 24104 USA "
    },
    {
      "Location ID": "24111",
      "Location Name": "MC COY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6100 CENTENNIAL RD MC COY VA 24111 USA "
    },
    {
      "Location ID": "24112",
      "Location Name": "MARTINSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1123 SPRUCE ST MARTINSVILLE VA 24112 USA "
    },
    {
      "Location ID": "24120",
      "Location Name": "MEADOWS OF DAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3561 JEB STUART HWY MEADOWS OF DAN VA 24120 USA "
    },
    {
      "Location ID": "24121",
      "Location Name": "MONETA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14211 MONETA RD MONETA VA 24121 USA "
    },
    {
      "Location ID": "24122",
      "Location Name": "MONTVALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11345 W LYNCHBURG SALEM TPKE MONTVALE VA 24122 USA "
    },
    {
      "Location ID": "24124",
      "Location Name": "NARROWS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 305 MAIN ST NARROWS VA 24124 USA "
    },
    {
      "Location ID": "24126",
      "Location Name": "NEWBERN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5339 WILDERNESS RD NEWBERN VA 24126 USA "
    },
    {
      "Location ID": "24127",
      "Location Name": "NEW CASTLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 283 MAIN ST NEW CASTLE VA 24127 USA "
    },
    {
      "Location ID": "24128",
      "Location Name": "NEWPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 BLUE GRASS TRL NEWPORT VA 24128 USA "
    },
    {
      "Location ID": "24129",
      "Location Name": "NEW RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6461 NEW RIVER RD NEW RIVER VA 24129 USA "
    },
    {
      "Location ID": "24132",
      "Location Name": "PARROTT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6059 PARROTT MOUNTAIN RD PARROTT VA 24132 USA "
    },
    {
      "Location ID": "24133",
      "Location Name": "PATRICK SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22015 JEB STUART HWY PATRICK SPRINGS VA 24133 USA "
    },
    {
      "Location ID": "24134",
      "Location Name": "PEARISBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 N MAIN ST PEARISBURG VA 24134 USA "
    },
    {
      "Location ID": "24136",
      "Location Name": "PEMBROKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 PROGRESS ST PEMBROKE VA 24136 USA "
    },
    {
      "Location ID": "24137",
      "Location Name": "PENHOOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 137 MORGANS FORK RD PENHOOK VA 24137 USA "
    },
    {
      "Location ID": "24139",
      "Location Name": "PITTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5424 PITTSVILLE RD PITTSVILLE VA 24139 USA "
    },
    {
      "Location ID": "24141",
      "Location Name": "RADFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 901 W MAIN ST RADFORD VA 24141 USA "
    },
    {
      "Location ID": "24146",
      "Location Name": "REDWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3450 OLD FRANKLIN TPKE REDWOOD VA 24146 USA "
    },
    {
      "Location ID": "24147",
      "Location Name": "RICH CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 299 OLD VIRGINIA AVE RICH CREEK VA 24147 USA "
    },
    {
      "Location ID": "24148",
      "Location Name": "RIDGEWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 82 MAGNOLIA ST RIDGEWAY VA 24148 USA "
    },
    {
      "Location ID": "24149",
      "Location Name": "RINER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3955 RINER RD RINER VA 24149 USA "
    },
    {
      "Location ID": "24150",
      "Location Name": "RIPPLEMEAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 GREYSTONE DR RIPPLEMEAD VA 24150 USA "
    },
    {
      "Location ID": "24151",
      "Location Name": "ROCKY MOUNT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 E COLLEGE ST ROCKY MOUNT VA 24151 USA "
    },
    {
      "Location ID": "24153",
      "Location Name": "SALEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 320 W MAIN ST SALEM VA 24153 USA "
    },
    {
      "Location ID": "24162",
      "Location Name": "SHAWSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6914 ROANOKE RD SHAWSVILLE VA 24162 USA "
    },
    {
      "Location ID": "24165",
      "Location Name": "SPENCER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 SPENCER PENN RD SPENCER VA 24165 USA "
    },
    {
      "Location ID": "24168",
      "Location Name": "STANLEYTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 180 FAIR OAKS DR STANLEYTOWN VA 24168 USA "
    },
    {
      "Location ID": "24171",
      "Location Name": "STUART",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 N MAIN ST STUART VA 24171 USA "
    },
    {
      "Location ID": "24174",
      "Location Name": "THAXTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1162 PENICKS MILL RD THAXTON VA 24174 USA "
    },
    {
      "Location ID": "24175",
      "Location Name": "TROUTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4952 LEE HWY TROUTVILLE VA 24175 USA "
    },
    {
      "Location ID": "24176",
      "Location Name": "UNION HALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 66 BERGER LOOP UNION HALL VA 24176 USA "
    },
    {
      "Location ID": "24177",
      "Location Name": "VESTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 COMMUNITY LN VESTA VA 24177 USA "
    },
    {
      "Location ID": "24179",
      "Location Name": "VINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 S POLLARD ST VINTON VA 24179 USA "
    },
    {
      "Location ID": "24184",
      "Location Name": "WIRTZ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7198 BOOKER T WASHINGTON HWY WIRTZ VA 24184 USA "
    },
    {
      "Location ID": "24185",
      "Location Name": "WOOLWINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9724 WOOLWINE HWY WOOLWINE VA 24185 USA "
    },
    {
      "Location ID": "241BS",
      "Location Name": "BANK SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 320 COLLEGE DR MARTINSVILLE VA 24112-6746 USA "
    },
    {
      "Location ID": "241DS",
      "Location Name": "DIRECT SPORTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1720 CURVE ROAD PEARISBURG VA 24134 USA "
    },
    {
      "Location ID": "241FC",
      "Location Name": "FAIRLAWN BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7436 PEPPERS FERRY BLVD FRNT FAIRLAWN VA 24141 USA "
    },
    {
      "Location ID": "241KB",
      "Location Name": "COOL KNOBS AND PULLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 850 HOLLIE DR STE 100 MARTINSVILLE VA 24112-2168 USA "
    },
    {
      "Location ID": "241KP",
      "Location Name": "COOL KNOBS AND PULLS, VA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 850 HOLLIE DR STE 100 MARTINSVILLE VA 24112 USA "
    },
    {
      "Location ID": "241LB",
      "Location Name": "LIBERTY MEDICAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2157 APPERSON DR SALEM VA 24153-7235 USA "
    },
    {
      "Location ID": "241NC",
      "Location Name": "GSI (from Martinsville)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 229 HOLLIE DR MARTINSVILLE VA 24112 USA "
    },
    {
      "Location ID": "241SM",
      "Location Name": "CPU SMITH MOUNTAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 VILLAGE SPRINGS DR STE 14 HARDY VA 24101 USA "
    },
    {
      "Location ID": "241SP",
      "Location Name": "SOUTH PRINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 545 HOLLIE DR MARTINSVILLE VA 24112-1386 USA "
    },
    {
      "Location ID": "24201",
      "Location Name": "DOMINION STATION, VA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2002 LEE HWY BRISTOL VA 24201 USA "
    },
    {
      "Location ID": "24203",
      "Location Name": "BRISTOL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 6TH ST BRISTOL VA 24203 USA "
    },
    {
      "Location ID": "24210",
      "Location Name": "ABINGDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 W MAIN ST ABINGDON VA 24210 USA "
    },
    {
      "Location ID": "24216",
      "Location Name": "APPALACHIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 534 W MAIN ST APPALACHIA VA 24216 USA "
    },
    {
      "Location ID": "24218",
      "Location Name": "BEN HUR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37997 VETERANS MEMORIAL HWY BEN HUR VA 24218 USA "
    },
    {
      "Location ID": "24219",
      "Location Name": "BIG STONE GAP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 322 WOOD AVE E STE 2 BIG STONE GAP VA 24219 USA "
    },
    {
      "Location ID": "24220",
      "Location Name": "BIRCHLEAF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 166 RAKES RIDGE RD BIRCHLEAF VA 24220 USA "
    },
    {
      "Location ID": "24224",
      "Location Name": "CASTLEWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19366 US HIGHWAY 58 CASTLEWOOD VA 24224 USA "
    },
    {
      "Location ID": "24225",
      "Location Name": "CLEVELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6550 CLEVELAND RD CLEVELAND VA 24225 USA "
    },
    {
      "Location ID": "24226",
      "Location Name": "CLINCHCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16556 DICKENSON HWY CLINCHCO VA 24226 USA "
    },
    {
      "Location ID": "24228",
      "Location Name": "CLINTWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 454 CHASE ST STE 100 CLINTWOOD VA 24228 USA "
    },
    {
      "Location ID": "24230",
      "Location Name": "COEBURN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 HIGH AVE NE COEBURN VA 24230 USA "
    },
    {
      "Location ID": "24236",
      "Location Name": "DAMASCUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 211 N REYNOLDS ST DAMASCUS VA 24236 USA "
    },
    {
      "Location ID": "24237",
      "Location Name": "DANTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 506 BUNCHTOWN RD DANTE VA 24237 USA "
    },
    {
      "Location ID": "24239",
      "Location Name": "DAVENPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4038 HELEN HENDERSON HWY DAVENPORT VA 24239 USA "
    },
    {
      "Location ID": "24243",
      "Location Name": "DRYDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 48354 VETERANS MEMORIAL HWY DRYDEN VA 24243 USA "
    },
    {
      "Location ID": "24244",
      "Location Name": "DUFFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 466 DUFF PATT HWY DUFFIELD VA 24244 USA "
    },
    {
      "Location ID": "24245",
      "Location Name": "DUNGANNON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18948 VETERANS MEMORIAL HWY STE 102 DUNGANNON VA 24245 USA "
    },
    {
      "Location ID": "24246",
      "Location Name": "EAST STONE GAP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3911 E STONE GAP RD EAST STONE GAP VA 24246 USA "
    },
    {
      "Location ID": "24248",
      "Location Name": "EWING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1579 DR THOMAS WALKER RD EWING VA 24248 USA "
    },
    {
      "Location ID": "24251",
      "Location Name": "GATE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 380 JONES ST GATE CITY VA 24251 USA "
    },
    {
      "Location ID": "24256",
      "Location Name": "HAYSI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 288 HAYSI MAIN ST HAYSI VA 24256 USA "
    },
    {
      "Location ID": "24260",
      "Location Name": "HONAKER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5528 REDBUD HWY HONAKER VA 24260 USA "
    },
    {
      "Location ID": "24263",
      "Location Name": "JONESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 169 CHURCH ST JONESVILLE VA 24263 USA "
    },
    {
      "Location ID": "24265",
      "Location Name": "KEOKEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 POST OFFICE RD KEOKEE VA 24265 USA "
    },
    {
      "Location ID": "24266",
      "Location Name": "LEBANON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 304 W MAIN ST LEBANON VA 24266 USA "
    },
    {
      "Location ID": "24271",
      "Location Name": "NICKELSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 158 BETHEL RD NICKELSVILLE VA 24271 USA "
    },
    {
      "Location ID": "24272",
      "Location Name": "NORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5623 DANTE MOUNTAIN RD NORA VA 24272 USA "
    },
    {
      "Location ID": "24273",
      "Location Name": "NORTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 7TH ST NW NORTON VA 24273 USA "
    },
    {
      "Location ID": "24277",
      "Location Name": "PENNINGTON GAP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41610 W MORGAN AVE PENNINGTON GAP VA 24277 USA "
    },
    {
      "Location ID": "24279",
      "Location Name": "POUND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11317 HIGHLAND AVE POUND VA 24279 USA "
    },
    {
      "Location ID": "24280",
      "Location Name": "ROSEDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18542 U S HIGHWAY 19 STE C ROSEDALE VA 24280 USA "
    },
    {
      "Location ID": "24281",
      "Location Name": "ROSE HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6501 DR THOMAS WALKER RD ROSE HILL VA 24281 USA "
    },
    {
      "Location ID": "24282",
      "Location Name": "SAINT CHARLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 155 MONARCH RD SAINT CHARLES VA 24282 USA "
    },
    {
      "Location ID": "24283",
      "Location Name": "SAINT PAUL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16552 RUSSELL ST SAINT PAUL VA 24283 USA "
    },
    {
      "Location ID": "24292",
      "Location Name": "WHITETOP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15145 HIGHLANDS PKWY WHITETOP VA 24292 USA "
    },
    {
      "Location ID": "24293",
      "Location Name": "WISE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 208 MAIN ST WISE VA 24293 USA "
    },
    {
      "Location ID": "242SU",
      "Location Name": "SURFANDDIRT, VA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 196 BEACON RD BRISTOL VA 24201 USA "
    },
    {
      "Location ID": "242VC",
      "Location Name": "VA CANDLE COMPANY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1055 ISLAND RD BRISTOL VA 24201 USA "
    },
    {
      "Location ID": "24301",
      "Location Name": "PULASKI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 N WASHINGTON AVE PULASKI VA 24301 USA "
    },
    {
      "Location ID": "24311",
      "Location Name": "ATKINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5864 LEE HWY ATKINS VA 24311 USA "
    },
    {
      "Location ID": "24312",
      "Location Name": "AUSTINVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2286 AUSTINVILLE RD AUSTINVILLE VA 24312 USA "
    },
    {
      "Location ID": "24313",
      "Location Name": "BARREN SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1895 WYSOR HWY BARREN SPRINGS VA 24313 USA "
    },
    {
      "Location ID": "24317",
      "Location Name": "CANA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14207 FANCY GAP HWY CANA VA 24317 USA "
    },
    {
      "Location ID": "24319",
      "Location Name": "CHILHOWIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 195 OLD STAGE RD CHILHOWIE VA 24319 USA "
    },
    {
      "Location ID": "24324",
      "Location Name": "DRAPER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4390 ACADEMY ST DRAPER VA 24324 USA "
    },
    {
      "Location ID": "24325",
      "Location Name": "DUGSPUR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 DOUBLE CABIN RD DUGSPUR VA 24325 USA "
    },
    {
      "Location ID": "24327",
      "Location Name": "EMORY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31045 OXFORD AVE EMORY VA 24327 USA "
    },
    {
      "Location ID": "24328",
      "Location Name": "FANCY GAP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7608 FANCY GAP HWY FANCY GAP VA 24328 USA "
    },
    {
      "Location ID": "24330",
      "Location Name": "FRIES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 491 W MAIN ST FRIES VA 24330 USA "
    },
    {
      "Location ID": "24333",
      "Location Name": "GALAX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 E GRAYSON ST GALAX VA 24333 USA "
    },
    {
      "Location ID": "24340",
      "Location Name": "GLADE SPRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 E GLADE ST GLADE SPRING VA 24340 USA "
    },
    {
      "Location ID": "24343",
      "Location Name": "HILLSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 404 S MAIN ST HILLSVILLE VA 24343 USA "
    },
    {
      "Location ID": "24347",
      "Location Name": "HIWASSEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2298 JULIA SIMPKINS RD HIWASSEE VA 24347 USA "
    },
    {
      "Location ID": "24348",
      "Location Name": "INDEPENDENCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 DAVIS ST INDEPENDENCE VA 24348 USA "
    },
    {
      "Location ID": "24351",
      "Location Name": "LAMBSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3291 LAMBSBURG RD LAMBSBURG VA 24351 USA "
    },
    {
      "Location ID": "24352",
      "Location Name": "LAUREL FORK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14208 DANVILLE PIKE LAUREL FORK VA 24352 USA "
    },
    {
      "Location ID": "24354",
      "Location Name": "MARION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 PEARL AVE MARION VA 24354 USA "
    },
    {
      "Location ID": "24360",
      "Location Name": "MAX MEADOWS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1427 RAMSEY MOUNTAIN RD MAX MEADOWS VA 24360 USA "
    },
    {
      "Location ID": "24361",
      "Location Name": "MEADOWVIEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29063 WALKER LN MEADOWVIEW VA 24361 USA "
    },
    {
      "Location ID": "24363",
      "Location Name": "MOUTH OF WILSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 700 TROUTDALE HWY MOUTH OF WILSON VA 24363 USA "
    },
    {
      "Location ID": "24368",
      "Location Name": "RURAL RETREAT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 405 S MAIN ST RURAL RETREAT VA 24368 USA "
    },
    {
      "Location ID": "24370",
      "Location Name": "SALTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 146 PALMER AVE SALTVILLE VA 24370-9998 USA "
    },
    {
      "Location ID": "24375",
      "Location Name": "SUGAR GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5444 SUGAR GROVE HWY SUGAR GROVE VA 24375 USA "
    },
    {
      "Location ID": "24378",
      "Location Name": "TROUTDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 93 RIPSHIN RD TROUTDALE VA 24378 USA "
    },
    {
      "Location ID": "24380",
      "Location Name": "WILLIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5600 FLOYD HWY S WILLIS VA 24380 USA "
    },
    {
      "Location ID": "24381",
      "Location Name": "WOODLAWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 32 COULSON CHURCH RD WOODLAWN VA 24381 USA "
    },
    {
      "Location ID": "24382",
      "Location Name": "WYTHEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 380 W MAIN ST WYTHEVILLE VA 24382 USA "
    },
    {
      "Location ID": "244",
      "Location Name": "STAUNTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1430 N AUGUSTA ST STAUNTON VA 24401 USA "
    },
    {
      "Location ID": "24401",
      "Location Name": "STAUNTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1430 N AUGUSTA ST STAUNTON VA 24401 USA "
    },
    {
      "Location ID": "24411",
      "Location Name": "AUGUSTA SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 OLD WHITETAIL LN AUGUSTA SPRINGS VA 24411 USA "
    },
    {
      "Location ID": "24412",
      "Location Name": "BACOVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23 POST OFFICE DR BACOVA VA 24412 USA "
    },
    {
      "Location ID": "24413",
      "Location Name": "BLUE GRASS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2741 BLUE GRASS VALLEY RD BLUE GRASS VA 24413 USA "
    },
    {
      "Location ID": "24415",
      "Location Name": "BROWNSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2741 BROWNSBURG TPKE BROWNSBURG VA 24415 USA "
    },
    {
      "Location ID": "24416",
      "Location Name": "BUENA VISTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2071 FOREST AVE BUENA VISTA VA 24416 USA "
    },
    {
      "Location ID": "24421",
      "Location Name": "CHURCHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3696 CHURCHVILLE AVE CHURCHVILLE VA 24421 USA "
    },
    {
      "Location ID": "24422",
      "Location Name": "CLIFTON FORGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 324 COMMERCIAL AVE CLIFTON FORGE VA 24422 USA "
    },
    {
      "Location ID": "24426",
      "Location Name": "COVINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 211 W MAIN ST COVINGTON VA 24426 USA "
    },
    {
      "Location ID": "24430",
      "Location Name": "CRAIGSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 W CRAIG ST CRAIGSVILLE VA 24430 USA "
    },
    {
      "Location ID": "24431",
      "Location Name": "CRIMORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1805 E SIDE HWY STE 101 CRIMORA VA 24431 USA "
    },
    {
      "Location ID": "24435",
      "Location Name": "FAIRFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5586 N LEE HWY FAIRFIELD VA 24435 USA "
    },
    {
      "Location ID": "24438",
      "Location Name": "GLEN WILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 MAIN ST GLEN WILTON VA 24438 USA "
    },
    {
      "Location ID": "24439",
      "Location Name": "GOSHEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9693 MAURY RIVER RD GOSHEN VA 24439 USA "
    },
    {
      "Location ID": "24440",
      "Location Name": "GREENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4171 LEE JACKSON HWY STE 101 GREENVILLE VA 24440 USA "
    },
    {
      "Location ID": "24441",
      "Location Name": "GROTTOES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 6TH ST GROTTOES VA 24441 USA "
    },
    {
      "Location ID": "24442",
      "Location Name": "HEAD WATERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14915 HIGHLAND TPKE HEAD WATERS VA 24442 USA "
    },
    {
      "Location ID": "24445",
      "Location Name": "HOT SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2793 MAIN ST HOT SPRINGS VA 24445 USA "
    },
    {
      "Location ID": "24448",
      "Location Name": "IRON GATE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 511 MARKET ST IRON GATE VA 24448 USA "
    },
    {
      "Location ID": "24450",
      "Location Name": "LEXINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 LEE AVE LEXINGTON VA 24450 USA "
    },
    {
      "Location ID": "24457",
      "Location Name": "LOW MOOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 OLD CHURCH RD LOW MOOR VA 24457 USA "
    },
    {
      "Location ID": "24458",
      "Location Name": "MC DOWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8988 HIGHLAND TPKE MC DOWELL VA 24458 USA "
    },
    {
      "Location ID": "24460",
      "Location Name": "MILLBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 142 MAIN ST MILLBORO VA 24460 USA "
    },
    {
      "Location ID": "24463",
      "Location Name": "MINT SPRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2069 LEE JACKSON HWY MINT SPRING VA 24463 USA "
    },
    {
      "Location ID": "24465",
      "Location Name": "MONTEREY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 66 E MAIN ST MONTEREY VA 24465 USA "
    },
    {
      "Location ID": "24467",
      "Location Name": "MOUNT SIDNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2349 LEE HWY MOUNT SIDNEY VA 24467 USA "
    },
    {
      "Location ID": "24469",
      "Location Name": "NEW HOPE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 610 BATTLEFIELD RD NEW HOPE VA 24469 USA "
    },
    {
      "Location ID": "24471",
      "Location Name": "PORT REPUBLIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8595 MAIN ST PORT REPUBLIC VA 24471 USA "
    },
    {
      "Location ID": "24472",
      "Location Name": "RAPHINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2139 RAPHINE RD RAPHINE VA 24472 USA "
    },
    {
      "Location ID": "24473",
      "Location Name": "ROCKBRIDGE BATHS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4781 MAURY RIVER RD ROCKBRIDGE BATHS VA 24473 USA "
    },
    {
      "Location ID": "24474",
      "Location Name": "SELMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1119 RICHMOND ST SELMA VA 24474 USA "
    },
    {
      "Location ID": "24476",
      "Location Name": "STEELES TAVERN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6554 LEE JACKSON HWY STEELES TAVERN VA 24476 USA "
    },
    {
      "Location ID": "24477",
      "Location Name": "STUARTS DRAFT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2857 STUARTS DRAFT HWY STE 133 STUARTS DRAFT VA 24477 USA "
    },
    {
      "Location ID": "24479",
      "Location Name": "SWOOPE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1351 HEWITT RD SWOOPE VA 24479 USA "
    },
    {
      "Location ID": "24482",
      "Location Name": "VERONA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 485 LEE HWY VERONA VA 24482 USA "
    },
    {
      "Location ID": "24484",
      "Location Name": "WARM SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 97 OLD MILL RD WARM SPRINGS VA 24484 USA "
    },
    {
      "Location ID": "24485",
      "Location Name": "WEST AUGUSTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 SHENANDOAH MOUNTAIN DR WEST AUGUSTA VA 24485 USA "
    },
    {
      "Location ID": "24486",
      "Location Name": "WEYERS CAVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 DEPOT RD WEYERS CAVE VA 24486 USA "
    },
    {
      "Location ID": "244AA",
      "Location Name": "WOODRUM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 W FREDERICK ST STAUNTON VA 24401 USA "
    },
    {
      "Location ID": "244BW",
      "Location Name": "BLUEGRASS WOODS/I-FULFILLMENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 223 MILLBORO INDUSTRIAL RD MILLBORO VA 24460 USA "
    },
    {
      "Location ID": "244SB",
      "Location Name": "SCOUT BAGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 COMMERCE CENTER DR COVINGTON VA 24426 USA "
    },
    {
      "Location ID": "245",
      "Location Name": "LYNCHBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3300 ODD FELLOWS RD LYNCHBURG VA 24506 USA "
    },
    {
      "Location ID": "24501",
      "Location Name": "LYNCHBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3300 ODD FELLOWS RD LYNCHBURG VA 24506-9998 USA "
    },
    {
      "Location ID": "24502",
      "Location Name": "FORT HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6020 FORT AVE LYNCHBURG VA 24502 USA "
    },
    {
      "Location ID": "24503",
      "Location Name": "RIVERMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2485 RIVERMONT AVE LYNCHBURG VA 24503 USA "
    },
    {
      "Location ID": "24505",
      "Location Name": "COURTHOUSE LYNCHBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1100 CLAY ST LYNCHBURG VA 24504 USA "
    },
    {
      "Location ID": "24517",
      "Location Name": "ALTAVISTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 700 BROAD ST ALTAVISTA VA 24517 USA "
    },
    {
      "Location ID": "24520",
      "Location Name": "ALTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2041 BLANE RD ALTON VA 24520 USA "
    },
    {
      "Location ID": "24521",
      "Location Name": "AMHERST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 S MAIN ST AMHERST VA 24521 USA "
    },
    {
      "Location ID": "24522",
      "Location Name": "APPOMATTOX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 791 COURT ST APPOMATTOX VA 24522 USA "
    },
    {
      "Location ID": "24523",
      "Location Name": "BEDFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 E MAIN ST BEDFORD VA 24523 USA "
    },
    {
      "Location ID": "24526",
      "Location Name": "BIG ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10830 LEE JACKSON HWY BIG ISLAND VA 24526 USA "
    },
    {
      "Location ID": "24527",
      "Location Name": "BLAIRS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5601 U S HIGHWAY 29 BLAIRS VA 24527 USA "
    },
    {
      "Location ID": "24528",
      "Location Name": "BROOKNEAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 TODD ST BROOKNEAL VA 24528 USA "
    },
    {
      "Location ID": "24529",
      "Location Name": "BUFFALO JUNCTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7199 HIGHWAY 49 BUFFALO JUNCTION VA 24529 USA "
    },
    {
      "Location ID": "24531",
      "Location Name": "CHATHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31 S MAIN ST CHATHAM VA 24531 USA "
    },
    {
      "Location ID": "24533",
      "Location Name": "CLIFFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 TURKEY MOUNTAIN RD CLIFFORD VA 24533 USA "
    },
    {
      "Location ID": "24534",
      "Location Name": "CLOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 S MAIN ST CLOVER VA 24534 USA "
    },
    {
      "Location ID": "24535",
      "Location Name": "CLUSTER SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6225 HUELL MATTHEWS HWY CLUSTER SPRINGS VA 24535 USA "
    },
    {
      "Location ID": "24536",
      "Location Name": "COLEMAN FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6285 LEE JACKSON HWY COLEMAN FALLS VA 24536 USA "
    },
    {
      "Location ID": "24538",
      "Location Name": "CONCORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10275 VILLAGE HWY CONCORD VA 24538 USA "
    },
    {
      "Location ID": "24539",
      "Location Name": "CRYSTAL HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6200 HOWARD P ANDERSON RD CRYSTAL HILL VA 24539 USA "
    },
    {
      "Location ID": "24540",
      "Location Name": "DANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 TEAL CT DANVILLE VA 24541 USA "
    },
    {
      "Location ID": "24541",
      "Location Name": "DANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 TEAL CT DANVILLE VA 24541 USA "
    },
    {
      "Location ID": "24549",
      "Location Name": "DRY FORK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 656 DRY FORK RD DRY FORK VA 24549 USA "
    },
    {
      "Location ID": "24550",
      "Location Name": "EVINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11496 LEESVILLE RD EVINGTON VA 24550 USA "
    },
    {
      "Location ID": "24551",
      "Location Name": "FOREST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14803 FOREST RD FOREST VA 24551 USA "
    },
    {
      "Location ID": "24553",
      "Location Name": "GLADSTONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2418 PIEDMONT RD GLADSTONE VA 24553 USA "
    },
    {
      "Location ID": "24554",
      "Location Name": "GLADYS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 62 PIGEON RUN RD GLADYS VA 24554 USA "
    },
    {
      "Location ID": "24555",
      "Location Name": "GLASGOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 805 BLUE RIDGE RD GLASGOW VA 24555 USA "
    },
    {
      "Location ID": "24556",
      "Location Name": "GOODE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9161 FOREST RD GOODE VA 24556 USA "
    },
    {
      "Location ID": "24557",
      "Location Name": "GRETNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 WATTS ST GRETNA VA 24557 USA "
    },
    {
      "Location ID": "24558",
      "Location Name": "HALIFAX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 231 S MAIN ST HALIFAX VA 24558 USA "
    },
    {
      "Location ID": "24563",
      "Location Name": "HURT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 MAIN ST HURT VA 24563 USA "
    },
    {
      "Location ID": "24569",
      "Location Name": "LONG ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 PEAK PL LONG ISLAND VA 24569 USA "
    },
    {
      "Location ID": "24570",
      "Location Name": "LOWRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3516 LOWRY RD LOWRY VA 24570 USA "
    },
    {
      "Location ID": "24571",
      "Location Name": "LYNCH STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 969 POWELL RD LYNCH STATION VA 24571 USA "
    },
    {
      "Location ID": "24572",
      "Location Name": "MADISON HEIGHTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 158 LAKEVIEW DR MADISON HEIGHTS VA 24572 USA "
    },
    {
      "Location ID": "24574",
      "Location Name": "MONROE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3378 S AMHERST HWY MONROE VA 24574 USA "
    },
    {
      "Location ID": "24576",
      "Location Name": "NARUNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 438 WHIPPING CREEK RD NARUNA VA 24576 USA "
    },
    {
      "Location ID": "24577",
      "Location Name": "NATHALIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1031 VOLENS RD NATHALIE VA 24577 USA "
    },
    {
      "Location ID": "24578",
      "Location Name": "NATURAL BRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6477 S LEE HWY NATURAL BRIDGE VA 24578 USA "
    },
    {
      "Location ID": "24579",
      "Location Name": "NATURAL BRIDGE STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1482 WERT FULKNER HWY NATURAL BRIDGE STATION VA 24579 USA "
    },
    {
      "Location ID": "24580",
      "Location Name": "NELSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1868 HIGHWAY 49 NELSON VA 24580 USA "
    },
    {
      "Location ID": "24586",
      "Location Name": "RINGGOLD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4745 RINGGOLD CHURCH RD RINGGOLD VA 24586 USA "
    },
    {
      "Location ID": "24588",
      "Location Name": "RUSTBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1103 VILLAGE HWY RUSTBURG VA 24588 USA "
    },
    {
      "Location ID": "24589",
      "Location Name": "SCOTTSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2221 SCOTTSBURG RD SCOTTSBURG VA 24589 USA "
    },
    {
      "Location ID": "24590",
      "Location Name": "SCOTTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 SCOTTSVILLE CTR SCOTTSVILLE VA 24590 USA "
    },
    {
      "Location ID": "24592",
      "Location Name": "SOUTH BOSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 601 N MAIN ST SOUTH BOSTON VA 24592 USA "
    },
    {
      "Location ID": "24593",
      "Location Name": "SPOUT SPRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 837 SPOUT SPRING RD SPOUT SPRING VA 24593 USA "
    },
    {
      "Location ID": "24594",
      "Location Name": "SUTHERLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2061 LAUREL GROVE RD SUTHERLIN VA 24594 USA "
    },
    {
      "Location ID": "24595",
      "Location Name": "SWEET BRIAR COLLEGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 134 CHAPEL RD SWEET BRIAR VA 24595 USA "
    },
    {
      "Location ID": "24597",
      "Location Name": "VERNON HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10164 MOUNTAIN RD VERNON HILL VA 24597 USA "
    },
    {
      "Location ID": "24598",
      "Location Name": "VIRGILINA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1142 7TH ST VIRGILINA VA 24598 USA "
    },
    {
      "Location ID": "24599",
      "Location Name": "WINGINA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13711 NORWOOD RD WINGINA VA 24599 USA "
    },
    {
      "Location ID": "245BL",
      "Location Name": "BAUSCH & LAUMB",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1501 GRAVES MILLS RD LYNCHBURG VA 24502 USA "
    },
    {
      "Location ID": "245BS",
      "Location Name": "BROCK SUPPLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 189 RINGGOLD IND PKWY DANVILLE VA 24540 USA "
    },
    {
      "Location ID": "245CH",
      "Location Name": "COURTHOUSE DANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 700 MAIN ST DANVILLE VA 24541 USA "
    },
    {
      "Location ID": "245EL",
      "Location Name": "CPU EXPRESS LANE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17867 FOREST RD FOREST VA 24551 USA "
    },
    {
      "Location ID": "245JC",
      "Location Name": "J CREW",
      "Time Zone Offset (Hours)": 0,
      "Address": " ONE IVY CRESENT LYNCHBURG VA 24513 USA "
    },
    {
      "Location ID": "245LC",
      "Location Name": "LINKS CHOICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4545 KIDDS DAIRY RD SCOTTSVILLE VA 24590 USA "
    },
    {
      "Location ID": "245LT",
      "Location Name": "LOGIC TECHNOLOGY DEV LTD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 260 RINGGOLD INDUSTRIAL PKWY DANVILLE VA 24540 USA "
    },
    {
      "Location ID": "245MA",
      "Location Name": "MAIL AMERICA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14845 FOREST RD FOREST VA 24551 USA "
    },
    {
      "Location ID": "245MS",
      "Location Name": "MARKETING SUPPORT SOLUTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 VISTA CENTRE DR FOREST VA 24551 USA "
    },
    {
      "Location ID": "245RD",
      "Location Name": "RR DONNELLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4201 MURRAY PL LYNCHBURG VA 24501 USA "
    },
    {
      "Location ID": "245RR",
      "Location Name": "RR DONNELLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4201 MURRAY PL LYNCHBURG VA 24501 USA "
    },
    {
      "Location ID": "245SC",
      "Location Name": "STAR CITY GAMES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5728 WILLIAMSON RD ROANOKE VA 24012 USA "
    },
    {
      "Location ID": "245SD",
      "Location Name": "SUGAR DIVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 680 LYNN STREET DANVILLE VA 24541-1540 USA "
    },
    {
      "Location ID": "245SO",
      "Location Name": "SOURCE4",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4201 MURRAY PL LYNCHBURG VA 24501 USA "
    },
    {
      "Location ID": "245TV",
      "Location Name": "DIRECT TV",
      "Time Zone Offset (Hours)": 0,
      "Address": " 417 BRIDGE ST DANVILLE VA 24541 USA "
    },
    {
      "Location ID": "245V1",
      "Location Name": "VITAL PLAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 680 LYNN STREET DANVILLE VA 24541 USA "
    },
    {
      "Location ID": "245VI",
      "Location Name": "VITAL PLAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 680 LYNN STREET DANVILLE VA 24541 USA "
    },
    {
      "Location ID": "24604",
      "Location Name": "BISHOP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3978 STONEY RIDGE RD BISHOP VA 24604 USA "
    },
    {
      "Location ID": "24605",
      "Location Name": "BLUEFIELD VA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 507 VIRGINIA AVENUE BLUEFIELD VA 24605 USA "
    },
    {
      "Location ID": "24606",
      "Location Name": "BOISSEVAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 CREEKVIEW RD BOISSEVAIN VA 24606 USA "
    },
    {
      "Location ID": "24609",
      "Location Name": "CEDAR BLUFF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1126 CEDAR VALLEY DR CEDAR BLUFF VA 24609 USA "
    },
    {
      "Location ID": "24612",
      "Location Name": "DORAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5947 GOVERNOR G C PEERY HWY STE 1 DORAN VA 24612 USA "
    },
    {
      "Location ID": "24613",
      "Location Name": "FALLS MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 BRUSHFORK RD FALLS MILLS VA 24613 USA "
    },
    {
      "Location ID": "24614",
      "Location Name": "GRUNDY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 WALNUT ST GRUNDY VA 24614 USA "
    },
    {
      "Location ID": "24620",
      "Location Name": "HURLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7505 LESTERS FORK RD HURLEY VA 24620 USA "
    },
    {
      "Location ID": "24624",
      "Location Name": "KEEN MOUNTAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1063 KEEN MOUNTAIN CAMP RD KEEN MOUNTAIN VA 24624 USA "
    },
    {
      "Location ID": "24630",
      "Location Name": "NORTH TAZEWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 E RIVERSIDE DR NORTH TAZEWELL VA 24630 USA "
    },
    {
      "Location ID": "24631",
      "Location Name": "OAKWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10735 RIVERSIDE DR OAKWOOD VA 24631 USA "
    },
    {
      "Location ID": "24635",
      "Location Name": "POCAHONTAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 CENTRE ST POCAHONTAS VA 24635 USA "
    },
    {
      "Location ID": "24637",
      "Location Name": "POUNDING MILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4355 INDIAN PAINT RD POUNDING MILL VA 24637 USA "
    },
    {
      "Location ID": "24639",
      "Location Name": "RAVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 561 RAVEN RD RAVEN VA 24639 USA "
    },
    {
      "Location ID": "24641",
      "Location Name": "RICHLANDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1430 FRONT ST RICHLANDS VA 24641 USA "
    },
    {
      "Location ID": "24651",
      "Location Name": "TAZEWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 W MAIN ST TAZEWELL VA 24651 USA "
    },
    {
      "Location ID": "24656",
      "Location Name": "VANSANT BOX SECTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1903 LOVERS GAP RD VANSANT VA 24656 USA "
    },
    {
      "Location ID": "24658",
      "Location Name": "WOLFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5145 HURLEY RD WOLFORD VA 24658 USA "
    },
    {
      "Location ID": "246CB",
      "Location Name": "FIRST COMMUNITY BANK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 COLLEGE DR BLUEFIELD WV 24605 USA "
    },
    {
      "Location ID": "247",
      "Location Name": "BLUEFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3010 E CUMBERLAND RD BLUEFIELD WV 24701 USA "
    },
    {
      "Location ID": "24701",
      "Location Name": "BLUEFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3010 E CUMBERLAND RD BLUEFIELD WV 24701 USA "
    },
    {
      "Location ID": "24712",
      "Location Name": "ATHENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 203 VERMILLION ST ATHENS WV 24712 USA "
    },
    {
      "Location ID": "24715",
      "Location Name": "BRAMWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 137 MAIN ST BRAMWELL WV 24715 USA "
    },
    {
      "Location ID": "24716",
      "Location Name": "BUD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3714 W VA RT 10 HERNDON RD BUD WV 24716 USA "
    },
    {
      "Location ID": "24719",
      "Location Name": "COVEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 252 GOONEY OTTER HOLLOW RD COVEL WV 24719 USA "
    },
    {
      "Location ID": "24731",
      "Location Name": "KEGLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3376 BECKLEY RD KEGLEY WV 24731 USA "
    },
    {
      "Location ID": "24733",
      "Location Name": "LASHMEET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4226 MATOAKA RD LASHMEET WV 24733 USA "
    },
    {
      "Location ID": "24736",
      "Location Name": "MATOAKA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 S MATOAKA WV 24736 USA "
    },
    {
      "Location ID": "24737",
      "Location Name": "MONTCALM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5427 SIMMONS RIVER RD MONTCALM WV 24737 USA "
    },
    {
      "Location ID": "24738",
      "Location Name": "NEMOURS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5609 FALLS MILLS RD NEMOURS WV 24738 USA "
    },
    {
      "Location ID": "24740",
      "Location Name": "PRINCETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 MERCER ST PRINCETON WV 24740 USA "
    },
    {
      "Location ID": "24747",
      "Location Name": "ROCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2757 ROCK RD ROCK WV 24747 USA "
    },
    {
      "Location ID": "24801",
      "Location Name": "WELCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 MCDOWELL ST WELCH WV 24801 USA "
    },
    {
      "Location ID": "24811",
      "Location Name": "AVONDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7060 MOUNTAINEER HWY AVONDALE WV 24811 USA "
    },
    {
      "Location ID": "24813",
      "Location Name": "BARTLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31 STORE BOTTOM RD BARTLEY WV 24813 USA "
    },
    {
      "Location ID": "24815",
      "Location Name": "BERWIND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2115 RIFT BERWIND RD BERWIND WV 24815 USA "
    },
    {
      "Location ID": "24816",
      "Location Name": "BIG SANDY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 62 ORA AVE BIG SANDY WV 24816 USA "
    },
    {
      "Location ID": "24817",
      "Location Name": "BRADSHAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 58 MOUNTAINEER HWY BRADSHAW WV 24817 USA "
    },
    {
      "Location ID": "24826",
      "Location Name": "CUCUMBER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7836 ROCKET BOYS DR CUCUMBER WV 24826 USA "
    },
    {
      "Location ID": "24828",
      "Location Name": "DAVY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 83 DAVY SCHOOL HOUSE RD DAVY WV 24828 USA "
    },
    {
      "Location ID": "24843",
      "Location Name": "HENSLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 EVERGREEN DR HENSLEY WV 24843 USA "
    },
    {
      "Location ID": "24844",
      "Location Name": "IAEGER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5265 COAL HERITAGE RD IAEGER WV 24844 USA "
    },
    {
      "Location ID": "24849",
      "Location Name": "JESSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 71 POPLAR GAP RD JESSE WV 24849 USA "
    },
    {
      "Location ID": "24850",
      "Location Name": "JOLO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12499 MARSHALL HWY JOLO WV 24850 USA "
    },
    {
      "Location ID": "24853",
      "Location Name": "KIMBALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28499 COAL HERITAGE RD KIMBALL WV 24853 USA "
    },
    {
      "Location ID": "24854",
      "Location Name": "KOPPERSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5657 KOPPERSTON ST KOPPERSTON WV 24854 USA "
    },
    {
      "Location ID": "24861",
      "Location Name": "MAYBEURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40128 COAL HERITAGE RD MAYBEURY WV 24861 USA "
    },
    {
      "Location ID": "24866",
      "Location Name": "NEWHALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19233 HIGHWAY 16 N NEWHALL WV 24866 USA "
    },
    {
      "Location ID": "24868",
      "Location Name": "NORTHFORK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33955 COAL HERITAGE RD NORTHFORK WV 24868 USA "
    },
    {
      "Location ID": "24870",
      "Location Name": "OCEANA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1524 COOK PKWY OCEANA WV 24870 USA "
    },
    {
      "Location ID": "24873",
      "Location Name": "PAYNESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17117 MARSHALL HWY PAYNESVILLE WV 24873 USA "
    },
    {
      "Location ID": "24874",
      "Location Name": "PINEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 145 MAIN AVE PINEVILLE WV 24874 USA "
    },
    {
      "Location ID": "24878",
      "Location Name": "PREMIER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 CASPIAN LN PREMIER WV 24878 USA "
    },
    {
      "Location ID": "24879",
      "Location Name": "RAYSAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8672 MARSHALL HWY RAYSAL WV 24879 USA "
    },
    {
      "Location ID": "24881",
      "Location Name": "RODERFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12790 COAL HERITAGE RD RODERFIELD WV 24881 USA "
    },
    {
      "Location ID": "24884",
      "Location Name": "SQUIRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20714 HIGHWAY 16 S SQUIRE WV 24884 USA "
    },
    {
      "Location ID": "24892",
      "Location Name": "WAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14175 ROCKET BOYS DR WAR WV 24892 USA "
    },
    {
      "Location ID": "24894",
      "Location Name": "WARRIORMINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 245 WAR CREEK DR WARRIORMINE WV 24894 USA "
    },
    {
      "Location ID": "24901",
      "Location Name": "LEWISBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 204 E WASHINGTON LEWISBURG WV 24901 USA "
    },
    {
      "Location ID": "24910",
      "Location Name": "ALDERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 RAILROAD AVE ALDERSON WV 24910 USA "
    },
    {
      "Location ID": "24920",
      "Location Name": "BARTOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6784 STAUNTON PARKERSBURG TPKE BARTOW WV 24920 USA "
    },
    {
      "Location ID": "24924",
      "Location Name": "BUCKEYE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14333 SENECA TRL BUCKEYE WV 24924 USA "
    },
    {
      "Location ID": "24931",
      "Location Name": "CRAWLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13795 MIDLAND TRAIL W CRAWLEY WV 24931 USA "
    },
    {
      "Location ID": "24934",
      "Location Name": "DUNMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 POTOMAC HIGHLANDS TRL DUNMORE WV 24934 USA "
    },
    {
      "Location ID": "24938",
      "Location Name": "FRANKFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21981 SENECA TRL N FRANKFORD WV 24938 USA "
    },
    {
      "Location ID": "24944",
      "Location Name": "GREEN BANK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5314 POTOMAC HIGHLANDS TRL GREEN BANK WV 24944 USA "
    },
    {
      "Location ID": "24946",
      "Location Name": "HILLSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7559 SENECA TRL HILLSBORO WV 24946 USA "
    },
    {
      "Location ID": "24951",
      "Location Name": "LINDSIDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8529 SENECA TRL S LINDSIDE WV 24951 USA "
    },
    {
      "Location ID": "24954",
      "Location Name": "MARLINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 819 4TH AVE MARLINTON WV 24954 USA "
    },
    {
      "Location ID": "24957",
      "Location Name": "MAXWELTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16387 SENECA TRL N MAXWELTON WV 24957 USA "
    },
    {
      "Location ID": "24963",
      "Location Name": "PETERSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 MARKET ST PETERSTOWN WV 24963 USA "
    },
    {
      "Location ID": "24966",
      "Location Name": "RENICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27288 SENECA TRL N RENICK WV 24966 USA "
    },
    {
      "Location ID": "24970",
      "Location Name": "RONCEVERTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 314 W EDGAR AVE RONCEVERTE WV 24970 USA "
    },
    {
      "Location ID": "24981",
      "Location Name": "TALCOTT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3685 SR AND 12 TALCOTT WV 24981 USA "
    },
    {
      "Location ID": "24983",
      "Location Name": "UNION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 MIDDLE ST UNION WV 24983 USA "
    },
    {
      "Location ID": "24986",
      "Location Name": "WHITE SULPHUR SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 857 MAIN ST E WHITE SULPHUR SPRINGS WV 24986 USA "
    },
    {
      "Location ID": "250",
      "Location Name": "CHARLESTON P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 CENTRE WAY SOUTH CHARLESTON WV 25309 USA "
    },
    {
      "Location ID": "25002",
      "Location Name": "ALLOY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4038 MIDLAND TRL ALLOY WV 25002 USA "
    },
    {
      "Location ID": "25003",
      "Location Name": "ALUM CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 LITTLE COAL RIVER RD ALUM CREEK WV 25003 USA "
    },
    {
      "Location ID": "25007",
      "Location Name": "ARNETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4461 COAL RIVER RD ARNETT WV 25007 USA "
    },
    {
      "Location ID": "25011",
      "Location Name": "BANCROFT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 MAIN ST BANCROFT WV 25011 USA "
    },
    {
      "Location ID": "25015",
      "Location Name": "BELLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 814 E DUPONT AVE BELLE WV 25015 USA "
    },
    {
      "Location ID": "25022",
      "Location Name": "BLAIR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10516 BLAIR MOUNTAIN HWY BLAIR WV 25022 USA "
    },
    {
      "Location ID": "25024",
      "Location Name": "BLOOMINGROSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22201 COAL RIVER RD BLOOMINGROSE WV 25024 USA "
    },
    {
      "Location ID": "25026",
      "Location Name": "BLUE CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5151 ELK RIVER RD N BLUE CREEK WV 25026 USA "
    },
    {
      "Location ID": "25031",
      "Location Name": "BOOMER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2995 MIDLAND TRL BOOMER WV 25031 USA "
    },
    {
      "Location ID": "25033",
      "Location Name": "BUFFALO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2062 BUFFALO RD BUFFALO WV 25033 USA "
    },
    {
      "Location ID": "25035",
      "Location Name": "CABIN CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15043 MACCORKLE AVE CABIN CREEK WV 25035 USA "
    },
    {
      "Location ID": "25036",
      "Location Name": "CANNELTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 216 STUMP RD CANNELTON WV 25036 USA "
    },
    {
      "Location ID": "25039",
      "Location Name": "CEDAR GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 E GEORGE ST CEDAR GROVE WV 25039 USA "
    },
    {
      "Location ID": "25040",
      "Location Name": "CHARLTON HEIGHTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6371 MIDLAND TRL CHARLTON HEIGHTS WV 25040 USA "
    },
    {
      "Location ID": "25043",
      "Location Name": "CLAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 54 MAIN ST CLAY WV 25043 USA "
    },
    {
      "Location ID": "25045",
      "Location Name": "CLENDENIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 MAYWOOD AVE W CLENDENIN WV 25045 USA "
    },
    {
      "Location ID": "25047",
      "Location Name": "CLOTHIER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18841 BLAIR MOUNTAIN HWY CLOTHIER WV 25047 USA "
    },
    {
      "Location ID": "25049",
      "Location Name": "COMFORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7008 COAL RIVER RD COMFORT WV 25049 USA "
    },
    {
      "Location ID": "25053",
      "Location Name": "DANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 236 MALL RD DANVILLE WV 25053 USA "
    },
    {
      "Location ID": "25054",
      "Location Name": "DAWES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5558 CABIN CREEK DR DAWES WV 25054 USA "
    },
    {
      "Location ID": "25061",
      "Location Name": "DRYBRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2137 CABIN CREEK RD DRYBRANCH WV 25061 USA "
    },
    {
      "Location ID": "25062",
      "Location Name": "DRY CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6486 COAL RIVER RD DRY CREEK WV 25062 USA "
    },
    {
      "Location ID": "25063",
      "Location Name": "DUCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 390 SERVIA ELMIRA RD DUCK WV 25063 USA "
    },
    {
      "Location ID": "25064",
      "Location Name": "DUNBAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1201 GROSSCUP AVE DUNBAR WV 25064 USA "
    },
    {
      "Location ID": "25070",
      "Location Name": "ELEANOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 ROOSEVELT BLVD ELEANOR WV 25070 USA "
    },
    {
      "Location ID": "25071",
      "Location Name": "ELKVIEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5062 ELK RIVER RD S ELKVIEW WV 25071 USA "
    },
    {
      "Location ID": "25075",
      "Location Name": "ESKDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 KEENEY DR ESKDALE WV 25075 USA "
    },
    {
      "Location ID": "25076",
      "Location Name": "ETHEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2978 BLAIR MOUNTAIN HWY ETHEL WV 25076 USA "
    },
    {
      "Location ID": "25079",
      "Location Name": "FALLING ROCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 AD FALLING ROCK WV 25079 USA "
    },
    {
      "Location ID": "25081",
      "Location Name": "FOSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7976 DANIEL BOONE PKWY FOSTER WV 25081 USA "
    },
    {
      "Location ID": "25082",
      "Location Name": "FRAZIERS BOTTOM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18350 WINFIELD RD FRAZIERS BOTTOM WV 25082 USA "
    },
    {
      "Location ID": "25085",
      "Location Name": "GAULEY BRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 MAIN ST GAULEY BRIDGE WV 25085 USA "
    },
    {
      "Location ID": "25086",
      "Location Name": "GLASGOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 406 3RD ST GLASGOW WV 25086 USA "
    },
    {
      "Location ID": "25090",
      "Location Name": "GLEN FERRIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8999 MIDLAND TRL GLEN FERRIS WV 25090 USA "
    },
    {
      "Location ID": "25106",
      "Location Name": "HENDERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 CHESTNUT ST HENDERSON WV 25106 USA "
    },
    {
      "Location ID": "25107",
      "Location Name": "HERNSHAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2996 LENS CREEK RD HERNSHAW WV 25107 USA "
    },
    {
      "Location ID": "25108",
      "Location Name": "HEWETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3864 HEWETT CREEK RD HEWETT WV 25108 USA "
    },
    {
      "Location ID": "25109",
      "Location Name": "HOMETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41 5TH AVE S HOMETOWN WV 25109 USA "
    },
    {
      "Location ID": "25110",
      "Location Name": "HUGHESTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5082 HUGHES CREEK RD HUGHESTON WV 25110 USA "
    },
    {
      "Location ID": "25112",
      "Location Name": "INSTITUTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6200 FAIRLAWN AVE INSTITUTE WV 25112 USA "
    },
    {
      "Location ID": "25113",
      "Location Name": "IVYDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10514 IVYDALE RD IVYDALE WV 25113 USA "
    },
    {
      "Location ID": "25114",
      "Location Name": "JEFFREY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 162 HEWETT CREEK RD JEFFREY WV 25114 USA "
    },
    {
      "Location ID": "25121",
      "Location Name": "LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2413 MAIN HEWETT CREEK RD LAKE WV 25121 USA "
    },
    {
      "Location ID": "25123",
      "Location Name": "LEON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20597 CHARLESTON RD LEON WV 25123 USA "
    },
    {
      "Location ID": "25126",
      "Location Name": "LONDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10049 E DUPONT AVE LONDON WV 25126 USA "
    },
    {
      "Location ID": "25130",
      "Location Name": "MADISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 650 S MAIN ST MADISON WV 25130 USA "
    },
    {
      "Location ID": "25133",
      "Location Name": "MAYSEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1848 PROCIOUS MAYSEL RD MAYSEL WV 25133 USA "
    },
    {
      "Location ID": "25134",
      "Location Name": "MIAMI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4933 CABIN CREEK RD MIAMI WV 25134 USA "
    },
    {
      "Location ID": "25136",
      "Location Name": "MONTGOMERY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 315 4TH AVE MONTGOMERY WV 25136 USA "
    },
    {
      "Location ID": "25140",
      "Location Name": "NAOMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7502 COAL RIVER RD NAOMA WV 25140 USA "
    },
    {
      "Location ID": "25143",
      "Location Name": "NITRO BOX SECTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 311 21ST ST NITRO WV 25143 USA "
    },
    {
      "Location ID": "25148",
      "Location Name": "ORGAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24220 COAL RIVER RD ORGAS WV 25148 USA "
    },
    {
      "Location ID": "25149",
      "Location Name": "OTTAWA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22321 SPRUCE RIVER RD STE A OTTAWA WV 25149 USA "
    },
    {
      "Location ID": "25154",
      "Location Name": "PEYTONA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 54 ROUNDBOTTOM RD STE A PEYTONA WV 25154 USA "
    },
    {
      "Location ID": "25156",
      "Location Name": "PINCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 231 N PINCH RD PINCH WV 25156 USA "
    },
    {
      "Location ID": "25159",
      "Location Name": "POCA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 MAIN ST POCA WV 25159 USA "
    },
    {
      "Location ID": "25164",
      "Location Name": "PROCIOUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8531 PROCIOUS MAYSEL RD PROCIOUS WV 25164 USA "
    },
    {
      "Location ID": "25165",
      "Location Name": "RACINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3010 SHORT CREEK RD RACINE WV 25165 USA "
    },
    {
      "Location ID": "25168",
      "Location Name": "RED HOUSE BOX SECTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11442 CHARLESTON RD RED HOUSE WV 25168 USA "
    },
    {
      "Location ID": "25174",
      "Location Name": "ROCK CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5762 COAL RIVER RD ROCK CREEK WV 25174 USA "
    },
    {
      "Location ID": "25177",
      "Location Name": "SAINT ALBANS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 431 B ST SAINT ALBANS WV 25177 USA "
    },
    {
      "Location ID": "25181",
      "Location Name": "SETH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10117 COAL RIVER RD SETH WV 25181 USA "
    },
    {
      "Location ID": "25183",
      "Location Name": "SHARPLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16547 BLAIR MOUNTAIN HWY SHARPLES WV 25183 USA "
    },
    {
      "Location ID": "25185",
      "Location Name": "MOUNT OLIVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 MOUNTAIN WAY MOUNT OLIVE WV 25185 USA "
    },
    {
      "Location ID": "25186",
      "Location Name": "SMITHERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 314 MICHIGAN AVE SMITHERS WV 25186 USA "
    },
    {
      "Location ID": "25187",
      "Location Name": "SOUTHSIDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26460 KANAWHA VALLEY RD SOUTHSIDE WV 25187 USA "
    },
    {
      "Location ID": "25193",
      "Location Name": "SYLVESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 32832 COAL RIVER RD SYLVESTER WV 25193 USA "
    },
    {
      "Location ID": "25201",
      "Location Name": "TAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 75 PATTERSON DR TAD WV 25201 USA "
    },
    {
      "Location ID": "25209",
      "Location Name": "WHITESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 157 LEWIS ST WHITESVILLE WV 25209 USA "
    },
    {
      "Location ID": "25213",
      "Location Name": "WINFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3278 WINFIELD RD WINFIELD WV 25213 USA "
    },
    {
      "Location ID": "25214",
      "Location Name": "WINIFREDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 303 FIELDS CREEK RD WINIFREDE WV 25214 USA "
    },
    {
      "Location ID": "25234",
      "Location Name": "ARNOLDSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 HAYS ST ARNOLDSBURG WV 25234 USA "
    },
    {
      "Location ID": "25239",
      "Location Name": "COTTAGEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 53 OLE MILL RD COTTAGEVILLE WV 25239 USA "
    },
    {
      "Location ID": "25241",
      "Location Name": "EVANS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 EVANS RD EVANS WV 25241 USA "
    },
    {
      "Location ID": "25247",
      "Location Name": "HARTFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 HEALEY ST HARTFORD WV 25247 USA "
    },
    {
      "Location ID": "25248",
      "Location Name": "KENNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 KENTUCK RD KENNA WV 25248 USA "
    },
    {
      "Location ID": "25252",
      "Location Name": "LE ROY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4327 LIVERPOOL RD LE ROY WV 25252 USA "
    },
    {
      "Location ID": "25253",
      "Location Name": "LETART",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23758 SANDHILL RD LETART WV 25253 USA "
    },
    {
      "Location ID": "25260",
      "Location Name": "MASON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1931 2ND ST MASON WV 25260 USA "
    },
    {
      "Location ID": "25261",
      "Location Name": "MILLSTONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11376 S CALHOUN HWY MILLSTONE WV 25261 USA "
    },
    {
      "Location ID": "25262",
      "Location Name": "MILLWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23 DANDELION DR MILLWOOD WV 25262 USA "
    },
    {
      "Location ID": "25265",
      "Location Name": "NEW HAVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 606 5TH ST NEW HAVEN WV 25265 USA "
    },
    {
      "Location ID": "25267",
      "Location Name": "NORMANTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 GASSAWAY RD NORMANTOWN WV 25267 USA "
    },
    {
      "Location ID": "25271",
      "Location Name": "RIPLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 MAIN ST W RIPLEY WV 25271 USA "
    },
    {
      "Location ID": "25275",
      "Location Name": "SANDYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 INDEPENDENCE RD SANDYVILLE WV 25275 USA "
    },
    {
      "Location ID": "25276",
      "Location Name": "SPENCER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 MAIN ST SPENCER WV 25276 USA "
    },
    {
      "Location ID": "25287",
      "Location Name": "WEST COLUMBIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17404 OHIO RIVER RD WEST COLUMBIA WV 25287 USA "
    },
    {
      "Location ID": "252CT",
      "Location Name": "CLEMENTS TREE NURSERY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 624 FORESTRY RD WEST COLUMBIA WV 25287-8686 USA "
    },
    {
      "Location ID": "252LS",
      "Location Name": "LAKIN STATE HOSPITAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11522 OHIO RIVER RD, WEST COLUMBIA WV 25287 USA "
    },
    {
      "Location ID": "252OV",
      "Location Name": "OVIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 JACK BURLINGAME DR MILLWOOD WV 25262 USA "
    },
    {
      "Location ID": "25301",
      "Location Name": "CHARLESTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1002 LEE ST E CHARLESTON WV 25301 USA "
    },
    {
      "Location ID": "25302",
      "Location Name": "STONEWALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 303 OHIO AVE CHARLESTON WV 25302 USA "
    },
    {
      "Location ID": "25303",
      "Location Name": "SOUTH CHARLESTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 D ST SOUTH CHARLESTON WV 25303 USA "
    },
    {
      "Location ID": "25305",
      "Location Name": "CHARLESTON - STATE HOUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1900 KANAWHA BLVD E CHARLESTON WV 25350 USA "
    },
    {
      "Location ID": "25306",
      "Location Name": "MALDEN STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 402 MALDEN DR CHARLESTON WV 25306-6442 USA "
    },
    {
      "Location ID": "25313",
      "Location Name": "CROSS LANES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5306 BIG TYLER RD CHARLESTON WV 25313 USA "
    },
    {
      "Location ID": "25315",
      "Location Name": "MARMET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9303 MACCORKLE AVE CHARLESTON WV 25315 USA "
    },
    {
      "Location ID": "25364",
      "Location Name": "KANAWHA CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4600 MACCORKLE AVE SE CHARLESTON WV 25304 USA "
    },
    {
      "Location ID": "253AB",
      "Location Name": "STATION C",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2328 7TH AVE CHARLESTON WV 25387 USA "
    },
    {
      "Location ID": "253AC",
      "Location Name": "SISSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6088 SISSONVILLE DR CHARLESTON WV 25312 USA "
    },
    {
      "Location ID": "253AD",
      "Location Name": "SOUTHRIDGE CENTRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2470 MOUNTAINEER BLVD SOUTH CHARLESTON WV 25309 USA "
    },
    {
      "Location ID": "253CA",
      "Location Name": "Charleston Christmas Peak Annex",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2846 MOUNTAINEER BLVD CHARLESTON WV 25309 USA "
    },
    {
      "Location ID": "253HS",
      "Location Name": "DEPT HUMAN SERVICES WV",
      "Time Zone Offset (Hours)": 0,
      "Address": " 350 CAPITOL ST CHARLESTON WV 25301 USA "
    },
    {
      "Location ID": "254",
      "Location Name": "MARTINSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1355 OLD COURTHOUSE SQ MARTINSBURG WV 25404 USA "
    },
    {
      "Location ID": "25410",
      "Location Name": "BAKERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 834 CARTER AVE HARPERS FERRY WV 25425 USA "
    },
    {
      "Location ID": "25411",
      "Location Name": "BERKELEY SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 417 N WASHINGTON ST BERKELEY SPRINGS WV 25411 USA "
    },
    {
      "Location ID": "25413",
      "Location Name": "BUNKER HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9604 WINCHESTER AVE BUNKER HILL WV 25413 USA "
    },
    {
      "Location ID": "25414",
      "Location Name": "CHARLES TOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 W WASHINGTON ST CHARLES TOWN WV 25414 USA "
    },
    {
      "Location ID": "25419",
      "Location Name": "FALLING WATERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9853 WILLIAMSPORT PIKE FALLING WATERS WV 25419 USA "
    },
    {
      "Location ID": "25420",
      "Location Name": "GERRARDSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1498 GERRARDSTOWN RD GERRARDSTOWN WV 25420 USA "
    },
    {
      "Location ID": "25422",
      "Location Name": "GREAT CACAPON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5010 CENTRAL AVE GREAT CACAPON WV 25422 USA "
    },
    {
      "Location ID": "25423",
      "Location Name": "HALLTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 402 SHEPHERDSTOWN PIKE HALLTOWN WV 25423 USA "
    },
    {
      "Location ID": "25425",
      "Location Name": "HARPERS FERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1010 WASHINGTON ST HARPERS FERRY WV 25425 USA "
    },
    {
      "Location ID": "25427",
      "Location Name": "HEDGESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5471 HEDGESVILLE RD HEDGESVILLE WV 25427 USA "
    },
    {
      "Location ID": "25428",
      "Location Name": "INWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7796 WINCHESTER AVE INWOOD WV 25428 USA "
    },
    {
      "Location ID": "25430",
      "Location Name": "KEARNEYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5512 CHARLES TOWN RD KEARNEYSVILLE WV 25430 USA "
    },
    {
      "Location ID": "25434",
      "Location Name": "PAW PAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 93 WINCHESTER ST PAW PAW WV 25434 USA "
    },
    {
      "Location ID": "25438",
      "Location Name": "RANSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 AMBROSE DR RANSON WV 25438 USA "
    },
    {
      "Location ID": "25441",
      "Location Name": "RIPPON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2468 BERRYVILLE PIKE STE 100 RIPPON WV 25441 USA "
    },
    {
      "Location ID": "25442",
      "Location Name": "SHENANDOAH JUNCTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 360 1ST ST SHENANDOAH JUNCTION WV 25442 USA "
    },
    {
      "Location ID": "25443",
      "Location Name": "SHEPHERDSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 S KING ST SHEPHERDSTOWN WV 25443 USA "
    },
    {
      "Location ID": "25444",
      "Location Name": "SLANESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41 SLANESVILLE PIKE SLANESVILLE WV 25444 USA "
    },
    {
      "Location ID": "25446",
      "Location Name": "SUMMIT POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 HAWTHORNE AVE SUMMIT POINT WV 25446 USA "
    },
    {
      "Location ID": "254MC",
      "Location Name": "MACY'S (MARTINSBURG WV)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 333 CAPERTON BLVD MARTINSBURG WV 25403 USA "
    },
    {
      "Location ID": "254QG",
      "Location Name": "QUAD GRAPHICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 855 CAPERTON BLVD FRNT MARTINSBURG WV 25403 USA "
    },
    {
      "Location ID": "254WH",
      "Location Name": "WASHINGTON HOMEPAHPIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 260 J R HAWVERMALE WAY BERKELEY SPGS WV 25411 USA "
    },
    {
      "Location ID": "255",
      "Location Name": "HUNTINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 VIRGINIA AVE W HUNTINGTON WV 25704 USA "
    },
    {
      "Location ID": "25501",
      "Location Name": "ALKOL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 BULGER RD ALKOL WV 25501 USA "
    },
    {
      "Location ID": "25502",
      "Location Name": "APPLE GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27799 HUNTINGTON RD APPLE GROVE WV 25502 USA "
    },
    {
      "Location ID": "25503",
      "Location Name": "ASHTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33014 HUNTINGTON RD ASHTON WV 25503 USA "
    },
    {
      "Location ID": "25504",
      "Location Name": "BARBOURSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 680 CENTRAL AVE BARBOURSVILLE WV 25504 USA "
    },
    {
      "Location ID": "25505",
      "Location Name": "BIG CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 BIG CREEK RD BIG CREEK WV 25505 USA "
    },
    {
      "Location ID": "25506",
      "Location Name": "BRANCHLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4934 MCCLELLAN HWY BRANCHLAND WV 25506 USA "
    },
    {
      "Location ID": "25507",
      "Location Name": "CEREDO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 371 C ST CEREDO WV 25507 USA "
    },
    {
      "Location ID": "25508",
      "Location Name": "CHAPMANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 93 MAIN STREET CHAPMANVILLE WV 25508 USA "
    },
    {
      "Location ID": "25510",
      "Location Name": "CULLODEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2000 US HIGHWAY 60 CULLODEN WV 25510 USA "
    },
    {
      "Location ID": "25511",
      "Location Name": "DUNLOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 MAIN ST DUNLOW WV 25511 USA "
    },
    {
      "Location ID": "25514",
      "Location Name": "FORT GAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3204 COURT ST FORT GAY WV 25514 USA "
    },
    {
      "Location ID": "25515",
      "Location Name": "GALLIPOLIS FERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11945 HUNTINGTON RD GALLIPOLIS FERRY WV 25515 USA "
    },
    {
      "Location ID": "25517",
      "Location Name": "GENOA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21696 ROUTE 152 GENOA WV 25517 USA "
    },
    {
      "Location ID": "25520",
      "Location Name": "GLENWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39272 HUNTINGTON RD GLENWOOD WV 25520 USA "
    },
    {
      "Location ID": "25521",
      "Location Name": "GRIFFITHSVILLE BOX SECTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5290 STRAIGHT FRK GRIFFITHSVILLE WV 25521 USA "
    },
    {
      "Location ID": "25523",
      "Location Name": "HAMLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8111 COURT AVE HAMLIN WV 25523 USA "
    },
    {
      "Location ID": "25524",
      "Location Name": "HARTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1074 MCCLELLAN HWY HARTS WV 25524 USA "
    },
    {
      "Location ID": "25526",
      "Location Name": "HURRICANE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3225 TEAYS VALLEY RD HURRICANE WV 25526 USA "
    },
    {
      "Location ID": "25529",
      "Location Name": "JULIAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 309 HILL VALLEY DR STE A JULIAN WV 25529 USA "
    },
    {
      "Location ID": "25530",
      "Location Name": "KENOVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1300 CHESTNUT ST KENOVA WV 25530 USA "
    },
    {
      "Location ID": "25535",
      "Location Name": "LAVALETTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4642 LYNN CREEK RD LAVALETTE WV 25535 USA "
    },
    {
      "Location ID": "25537",
      "Location Name": "LESAGE BOX SECTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6596 OHIO RIVER RD LESAGE WV 25537 USA "
    },
    {
      "Location ID": "25540",
      "Location Name": "MIDKIFF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 43 9 MILE CRK MIDKIFF WV 25540 USA "
    },
    {
      "Location ID": "25541",
      "Location Name": "MILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1177 MAIN ST MILTON WV 25541 USA "
    },
    {
      "Location ID": "25545",
      "Location Name": "ONA BOX SECTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2632 US ROUTE 60 ONA WV 25545 USA "
    },
    {
      "Location ID": "25547",
      "Location Name": "PECKS MILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 THUNDER RD PECKS MILL WV 25547 USA "
    },
    {
      "Location ID": "25550",
      "Location Name": "POINT PLEASANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 329 MAIN ST POINT PLEASANT WV 25550 USA "
    },
    {
      "Location ID": "25555",
      "Location Name": "PRICHARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 295 PRICHARD RD PRICHARD WV 25555 USA "
    },
    {
      "Location ID": "25557",
      "Location Name": "RANGER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 VANATTERS CRK RANGER WV 25557 USA "
    },
    {
      "Location ID": "25559",
      "Location Name": "SALT ROCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5577 MADISON CREEK RD SALT ROCK WV 25559 USA "
    },
    {
      "Location ID": "25560",
      "Location Name": "SCOTT DEPOT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4034 TEAYS VALLEY RD SCOTT DEPOT WV 25560 USA "
    },
    {
      "Location ID": "25562",
      "Location Name": "SHOALS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3712 ROUTE 75 SHOALS WV 25562 USA "
    },
    {
      "Location ID": "25564",
      "Location Name": "SOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2584 GARRETTS BND SOD WV 25564 USA "
    },
    {
      "Location ID": "25567",
      "Location Name": "SUMERCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2003 MIDWAY RD SUMERCO WV 25567 USA "
    },
    {
      "Location ID": "25569",
      "Location Name": "TEAYS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31 PUTNAM VILLAGE SHOPPING CTR TEAYS WV 25569 USA "
    },
    {
      "Location ID": "25570",
      "Location Name": "WAYNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 218 KEYSER ST WAYNE WV 25570 USA "
    },
    {
      "Location ID": "25571",
      "Location Name": "WEST HAMLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6017 MCCLELLAN HWY WEST HAMLIN WV 25571 USA "
    },
    {
      "Location ID": "25601",
      "Location Name": "LOGAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 HUDGINS ST LOGAN WV 25601 USA "
    },
    {
      "Location ID": "25611",
      "Location Name": "BRUNO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 242 SANDLICK BRANCH RD BRUNO WV 25611 USA "
    },
    {
      "Location ID": "25617",
      "Location Name": "DAVIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2687 HUFF CREEK HWY DAVIN WV 25617 USA "
    },
    {
      "Location ID": "25621",
      "Location Name": "GILBERT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 STAFFORD BR GILBERT WV 25621 USA "
    },
    {
      "Location ID": "25624",
      "Location Name": "HENLAWSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 VETERANS AVE HENLAWSON WV 25624 USA "
    },
    {
      "Location ID": "25625",
      "Location Name": "HOLDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 603 BEE BEE ST HOLDEN WV 25625 USA "
    },
    {
      "Location ID": "25634",
      "Location Name": "MALLORY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1453 HUFF CREEK HWY MALLORY WV 25634 USA "
    },
    {
      "Location ID": "25635",
      "Location Name": "MAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 MARKET ST MAN WV 25635 USA "
    },
    {
      "Location ID": "25637",
      "Location Name": "MOUNT GAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 352 MOUNT GAY RD MOUNT GAY WV 25637 USA "
    },
    {
      "Location ID": "25638",
      "Location Name": "OMAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8296 JERRY WEST HWY OMAR WV 25638 USA "
    },
    {
      "Location ID": "25639",
      "Location Name": "PEACH CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 631 ALLEGHENY AVE PEACH CREEK WV 25639 USA "
    },
    {
      "Location ID": "25644",
      "Location Name": "SARAH ANN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11765 JERRY WEST HWY SARAH ANN WV 25644 USA "
    },
    {
      "Location ID": "25647",
      "Location Name": "SWITZER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5468 JERRY WEST HWY SWITZER WV 25647 USA "
    },
    {
      "Location ID": "25649",
      "Location Name": "VERDUNVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2571 MUD FORK RD VERDUNVILLE WV 25649 USA "
    },
    {
      "Location ID": "25650",
      "Location Name": "VERNER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6151 RT 80 VERNER WV 25650 USA "
    },
    {
      "Location ID": "25652",
      "Location Name": "WHITMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1125 WHITMAN CREEK RD WHITMAN WV 25652 USA "
    },
    {
      "Location ID": "25653",
      "Location Name": "WILKINSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1604 JERRY WEST HWY WILKINSON WV 25653 USA "
    },
    {
      "Location ID": "25661",
      "Location Name": "WILLIAMSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 W 2ND AVE FL 1 WILLIAMSON WV 25661 USA "
    },
    {
      "Location ID": "25667",
      "Location Name": "CHATTAROY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 POST OFFICE DR CHATTAROY WV 25667 USA "
    },
    {
      "Location ID": "25669",
      "Location Name": "CRUM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44573 US 52 CRUM WV 25669 USA "
    },
    {
      "Location ID": "25670",
      "Location Name": "DELBARTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 128 GRACE ST DELBARTON WV 25670 USA "
    },
    {
      "Location ID": "25672",
      "Location Name": "EDGARTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5501 RT 49 EDGARTON WV 25672 USA "
    },
    {
      "Location ID": "25674",
      "Location Name": "KERMIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 OHIO AVE KERMIT WV 25674 USA "
    },
    {
      "Location ID": "25678",
      "Location Name": "MATEWAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 181 MATE ST MATEWAN WV 25678 USA "
    },
    {
      "Location ID": "25685",
      "Location Name": "NAUGATUCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 RT 65 NAUGATUCK WV 25685 USA "
    },
    {
      "Location ID": "25690",
      "Location Name": "RAGLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 JACKSON ST RAGLAND WV 25690 USA "
    },
    {
      "Location ID": "25691",
      "Location Name": "RAWL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 MURPHY BR RAWL WV 25691 USA "
    },
    {
      "Location ID": "25696",
      "Location Name": "VARNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1135 RA WEST HWY VARNEY WV 25696 USA "
    },
    {
      "Location ID": "25701",
      "Location Name": "DOWNTOWN HUNTINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1200 VETERANS MEMORIAL BLVD HUNTINGTON WV 25701 USA "
    },
    {
      "Location ID": "25702",
      "Location Name": "GUYANDOTTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 BRIDGE ST HUNTINGTON WV 25702 USA "
    },
    {
      "Location ID": "25704",
      "Location Name": "HUNTINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 VIRGINIA AVE W HUNTINGTON WV 25704 USA "
    },
    {
      "Location ID": "257AA",
      "Location Name": "BEVERLY HILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 323 OLIVE ST HUNTINGTON WV 25705 USA "
    },
    {
      "Location ID": "258",
      "Location Name": "BECKLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 134 INDUSTRIAL DR BECKLEY WV 25801 USA "
    },
    {
      "Location ID": "25801",
      "Location Name": "BECKLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 134 INDUSTRIAL DR BECKLEY WV 25801 USA "
    },
    {
      "Location ID": "25810",
      "Location Name": "ALLEN JUNCTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3891 GULF RD ALLEN JUNCTION WV 25810 USA "
    },
    {
      "Location ID": "25812",
      "Location Name": "ANSTED",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 JAMES RIVER AND KANAWHA TPKE ANSTED WV 25812 USA "
    },
    {
      "Location ID": "25813",
      "Location Name": "BEAVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 BEAVER PLZ BEAVER WV 25813 USA "
    },
    {
      "Location ID": "25818",
      "Location Name": "BRADLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6147 ROBERT C BRYD DR BRADLEY WV 25818 USA "
    },
    {
      "Location ID": "25823",
      "Location Name": "COAL CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 TINGLER AVE COAL CITY WV 25823 USA "
    },
    {
      "Location ID": "25825",
      "Location Name": "COOL RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1902 FLAT TOP RD COOL RIDGE WV 25825 USA "
    },
    {
      "Location ID": "25826",
      "Location Name": "CORINNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2064 GULF RD CORINNE WV 25826 USA "
    },
    {
      "Location ID": "25827",
      "Location Name": "CRAB ORCHARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1325 ROBERT C BYRD DR CRAB ORCHARD WV 25827 USA "
    },
    {
      "Location ID": "25832",
      "Location Name": "DANIELS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 124 BURGESS DR DANIELS WV 25832 USA "
    },
    {
      "Location ID": "25837",
      "Location Name": "EDMOND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3524 LANSING EDMOND RD EDMOND WV 25837 USA "
    },
    {
      "Location ID": "25840",
      "Location Name": "FAYETTEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 W MAPLE AVE FAYETTEVILLE WV 25840 USA "
    },
    {
      "Location ID": "25841",
      "Location Name": "FLAT TOP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21629 BECKLEY RD FLAT TOP WV 25841-9998 USA "
    },
    {
      "Location ID": "25843",
      "Location Name": "GHENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 ODD RD GHENT WV 25843 USA "
    },
    {
      "Location ID": "25846",
      "Location Name": "GLEN JEAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 MAIN ST GLEN JEAN WV 25846 USA "
    },
    {
      "Location ID": "25853",
      "Location Name": "HELEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5011 TAMS HWY HELEN WV 25853 USA "
    },
    {
      "Location ID": "25854",
      "Location Name": "HICO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 WILLIAM BEAN RD HICO WV 25854 USA "
    },
    {
      "Location ID": "25857",
      "Location Name": "JOSEPHINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4282 ODD RD JOSEPHINE WV 25857 USA "
    },
    {
      "Location ID": "25862",
      "Location Name": "LANSING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 59 FAYETTE MINE RD LANSING WV 25862 USA "
    },
    {
      "Location ID": "25865",
      "Location Name": "LESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1711 LESTER HWY LESTER WV 25865 USA "
    },
    {
      "Location ID": "25866",
      "Location Name": "LOCHGELLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3746 SUMMERLEE RD LOCHGELLY WV 25866 USA "
    },
    {
      "Location ID": "25868",
      "Location Name": "LOOKOUT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31085 MIDLAND TRL LOOKOUT WV 25868 USA "
    },
    {
      "Location ID": "25871",
      "Location Name": "MABSCOTT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 CHESTNUT ST MABSCOTT WV 25871 USA "
    },
    {
      "Location ID": "25873",
      "Location Name": "MAC ARTHUR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1902 ROBERT C BYRD DR MAC ARTHUR WV 25873 USA "
    },
    {
      "Location ID": "25878",
      "Location Name": "MIDWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1158 MIDWAY RD MIDWAY WV 25878 USA "
    },
    {
      "Location ID": "25880",
      "Location Name": "MOUNT HOPE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 415 MAIN ST MOUNT HOPE WV 25880 USA "
    },
    {
      "Location ID": "25882",
      "Location Name": "MULLENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 4TH ST MULLENS WV 25882 USA "
    },
    {
      "Location ID": "25901",
      "Location Name": "OAK HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 208 MAIN ST E OAK HILL WV 25901 USA "
    },
    {
      "Location ID": "25904",
      "Location Name": "PAX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 251 MAIN ST PAX WV 25904 USA "
    },
    {
      "Location ID": "25908",
      "Location Name": "PRINCEWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 127 EPPERLY RD PRINCEWICK WV 25908-9998 USA "
    },
    {
      "Location ID": "25911",
      "Location Name": "RALEIGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 BURMEISTER AVE RALEIGH WV 25911 USA "
    },
    {
      "Location ID": "25915",
      "Location Name": "RHODELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7230 COAL CITY RD RHODELL WV 25915 USA "
    },
    {
      "Location ID": "25917",
      "Location Name": "SCARBRO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1379 SCARBRO RD SCARBRO WV 25917 USA "
    },
    {
      "Location ID": "25918",
      "Location Name": "SHADY SPRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 218 HINTON RD SHADY SPRING WV 25918 USA "
    },
    {
      "Location ID": "25921",
      "Location Name": "SOPHIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 221 RIFFE ST SOPHIA WV 25921 USA "
    },
    {
      "Location ID": "25922",
      "Location Name": "SPANISHBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8781 BECKLEY RD SPANISHBURG WV 25922 USA "
    },
    {
      "Location ID": "25928",
      "Location Name": "STEPHENSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5869 GULF RD STEPHENSON WV 25928 USA "
    },
    {
      "Location ID": "25938",
      "Location Name": "VICTOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21313 MIDLAND TRL VICTOR WV 25938 USA "
    },
    {
      "Location ID": "25943",
      "Location Name": "WYCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1006 WYCO HOLLOW RD WYCO WV 25943 USA "
    },
    {
      "Location ID": "25951",
      "Location Name": "HINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 128 BALLENGEE ST HINTON WV 25951 USA "
    },
    {
      "Location ID": "25958",
      "Location Name": "CHARMCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5170 MIDLAND TRL W CHARMCO WV 25958 USA "
    },
    {
      "Location ID": "25962",
      "Location Name": "RAINELLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 506 JOHN RAINE DR RAINELLE WV 25962 USA "
    },
    {
      "Location ID": "25966",
      "Location Name": "GREEN SULPHUR SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12957 N SR 20 GREEN SULPHUR SPRINGS WV 25966 USA "
    },
    {
      "Location ID": "25969",
      "Location Name": "JUMPING BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5766 BEECH RUN RD JUMPING BRANCH WV 25969 USA "
    },
    {
      "Location ID": "25972",
      "Location Name": "LESLIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3543 ORIENT HILL RD LESLIE WV 25972 USA "
    },
    {
      "Location ID": "25976",
      "Location Name": "MEADOW BRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9245 SEWELL CREEK RD MEADOW BRIDGE WV 25976 USA "
    },
    {
      "Location ID": "25977",
      "Location Name": "MEADOW CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2638 MEADOW CREEK RD MEADOW CREEK WV 25977 USA "
    },
    {
      "Location ID": "25978",
      "Location Name": "NIMITZ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7394 BEECH RUN RD NIMITZ WV 25978 USA "
    },
    {
      "Location ID": "25981",
      "Location Name": "QUINWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 AMICK ST W QUINWOOD WV 25981 USA "
    },
    {
      "Location ID": "25984",
      "Location Name": "RUPERT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 703 NICHOLAS ST RUPERT WV 25984 USA "
    },
    {
      "Location ID": "25985",
      "Location Name": "SANDSTONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 38 RIVER RD SANDSTONE WV 25985 USA "
    },
    {
      "Location ID": "25986",
      "Location Name": "SPRING DALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5130 SEWELL CREEK RD SPRING DALE WV 25986 USA "
    },
    {
      "Location ID": "25989",
      "Location Name": "WHITE OAK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 809 HINTON RD WHITE OAK WV 25989 USA "
    },
    {
      "Location ID": "25MTE",
      "Location Name": "MARTINSBURG MTESC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 891 AUTO PARTS PL STE 3 MARTINSBURG WV 25403 USA "
    },
    {
      "Location ID": "260",
      "Location Name": "WHEELING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2501 CHAPLINE ST WHEELING WV 26003 USA "
    },
    {
      "Location ID": "26003",
      "Location Name": "WHEELING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2501 CHAPLINE ST WHEELING WV 26003 USA "
    },
    {
      "Location ID": "26030",
      "Location Name": "BEECH BOTTOM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 3RD ST BEECH BOTTOM WV 26030 USA "
    },
    {
      "Location ID": "26031",
      "Location Name": "BENWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 MCMECHEN ST BENWOOD WV 26031 USA "
    },
    {
      "Location ID": "26032",
      "Location Name": "BETHANY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 ROSS ST BETHANY WV 26032 USA "
    },
    {
      "Location ID": "26033",
      "Location Name": "CAMERON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 73 HIGH ST CAMERON WV 26033 USA "
    },
    {
      "Location ID": "26034",
      "Location Name": "CHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 323 CAROLINA AVE CHESTER WV 26034 USA "
    },
    {
      "Location ID": "26038",
      "Location Name": "GLEN DALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 4TH ST GLEN DALE WV 26038 USA "
    },
    {
      "Location ID": "26040",
      "Location Name": "MCMECHEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 717 MARSHALL ST MCMECHEN WV 26040 USA "
    },
    {
      "Location ID": "26041",
      "Location Name": "MOUNDSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1214 3RD ST MOUNDSVILLE WV 26041 USA "
    },
    {
      "Location ID": "26056",
      "Location Name": "NEW MANCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 290 HIGH ST NEW MANCHESTER WV 26056 USA "
    },
    {
      "Location ID": "26058",
      "Location Name": "SHORT CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 46 WINDY HILL RD SHORT CREEK WV 26058 USA "
    },
    {
      "Location ID": "26059",
      "Location Name": "TRIADELPHIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4317 NATIONAL RD TRIADELPHIA WV 26059 USA "
    },
    {
      "Location ID": "26060",
      "Location Name": "VALLEY GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8566 NATIONAL RD VALLEY GROVE WV 26060 USA "
    },
    {
      "Location ID": "26070",
      "Location Name": "WELLSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 944 CHARLES ST WELLSBURG WV 26070 USA "
    },
    {
      "Location ID": "26075",
      "Location Name": "WINDSOR HEIGHTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 290 WINDY HILL RD WINDSOR HEIGHTS WV 26075 USA "
    },
    {
      "Location ID": "260CB",
      "Location Name": "CABELA'S INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 DISTRIBUTION RD TRIADELPHIA WV 26059 USA "
    },
    {
      "Location ID": "260MA",
      "Location Name": "MAIL AMERICA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 89 BRIDGE ST PLAZA WHEELING WV 26003 USA "
    },
    {
      "Location ID": "260WW",
      "Location Name": "WARWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 605 WARWOOD AVE WHEELING WV 26003 USA "
    },
    {
      "Location ID": "261",
      "Location Name": "PARKERSBURG CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 RAYON DR PARKERSBURG WV 26101 USA "
    },
    {
      "Location ID": "26101",
      "Location Name": "PARKERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 RAYON DR PARKERSBURG WV 26101 USA "
    },
    {
      "Location ID": "26103",
      "Location Name": "FORT NEAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 806 NEAL ST PARKERSBURG WV 26101 USA "
    },
    {
      "Location ID": "26104",
      "Location Name": "NORTH PARKERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3505 CENTRAL AVE PARKERSBURG WV 26104 USA "
    },
    {
      "Location ID": "26105",
      "Location Name": "VIENNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 27TH ST VIENNA WV 26105 USA "
    },
    {
      "Location ID": "26133",
      "Location Name": "BELLEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11293 HARRIS HWY BELLEVILLE WV 26133 USA "
    },
    {
      "Location ID": "26134",
      "Location Name": "BELMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 310 CLARK ST BELMONT WV 26134 USA "
    },
    {
      "Location ID": "26136",
      "Location Name": "BIG BEND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6915 W LITTLE KANAWHA HWY BIG BEND WV 26136 USA "
    },
    {
      "Location ID": "26137",
      "Location Name": "BIG SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6657 NORTH CALHOUN HWY BIG SPRINGS WV 26137 USA "
    },
    {
      "Location ID": "26143",
      "Location Name": "ELIZABETH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 502 E SCHOOLVIEW ST ELIZABETH WV 26143 USA "
    },
    {
      "Location ID": "26146",
      "Location Name": "FRIENDLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2096 FRIENDLY RD FRIENDLY WV 26146 USA "
    },
    {
      "Location ID": "26147",
      "Location Name": "GRANTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 372 MAIN ST GRANTSVILLE WV 26147 USA "
    },
    {
      "Location ID": "26148",
      "Location Name": "MACFARLAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8190 STAUNTON TPKE MACFARLAN WV 26148 USA "
    },
    {
      "Location ID": "26149",
      "Location Name": "MIDDLEBOURNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 DODD ST MIDDLEBOURNE WV 26149 USA "
    },
    {
      "Location ID": "26150",
      "Location Name": "MINERAL WELLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1259 ELIZABETH PIKE MINERAL WELLS WV 26150 USA "
    },
    {
      "Location ID": "26155",
      "Location Name": "NEW MARTINSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 230 MAIN ST NEW MARTINSVILLE WV 26155 USA "
    },
    {
      "Location ID": "26159",
      "Location Name": "PADEN CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 213 W MAIN ST PADEN CITY WV 26159 USA "
    },
    {
      "Location ID": "26164",
      "Location Name": "RAVENSWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 716 WASHINGTON ST RAVENSWOOD WV 26164 USA "
    },
    {
      "Location ID": "26167",
      "Location Name": "READER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9599 SHORTLINE RD READER WV 26167 USA "
    },
    {
      "Location ID": "26170",
      "Location Name": "SAINT MARYS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 402 2ND ST SAINT MARYS WV 26170 USA "
    },
    {
      "Location ID": "26175",
      "Location Name": "SISTERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 521 WELLS ST SISTERSVILLE WV 26175 USA "
    },
    {
      "Location ID": "26178",
      "Location Name": "SMITHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11630 SMITHVILLE RD SMITHVILLE WV 26178 USA "
    },
    {
      "Location ID": "26181",
      "Location Name": "WASHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8791 DUPONT RD WASHINGTON WV 26181 USA "
    },
    {
      "Location ID": "26184",
      "Location Name": "WAVERLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 64 WAVERLY ST WAVERLY WV 26184 USA "
    },
    {
      "Location ID": "26187",
      "Location Name": "WILLIAMSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 420 OAKWOOD AVE WILLIAMSTOWN WV 26187 USA "
    },
    {
      "Location ID": "261AB",
      "Location Name": "SIMMONS STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1050 MARKET STREET PARKERSBURG WV 26101 USA "
    },
    {
      "Location ID": "261AX",
      "Location Name": "PARKERSBURG CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 RAYON DR PARKERSBURG WV 26101-6668 USA "
    },
    {
      "Location ID": "261GC",
      "Location Name": "GRAND CENTRAL MALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 230 GRAND CENTRAL MALL PARKERSBURG WV 26101 USA "
    },
    {
      "Location ID": "26201",
      "Location Name": "BUCKHANNON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 S SPRING ST BUCKHANNON WV 26201 USA "
    },
    {
      "Location ID": "26205",
      "Location Name": "CRAIGSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 RED OAK DR CRAIGSVILLE WV 26205 USA "
    },
    {
      "Location ID": "26206",
      "Location Name": "COWEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 ERBACON RD COWEN WV 26206 USA "
    },
    {
      "Location ID": "26208",
      "Location Name": "CAMDEN ON GAULEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9652 WEBSTER RD CAMDEN ON GAULEY WV 26208-9998 USA "
    },
    {
      "Location ID": "26209",
      "Location Name": "SNOWSHOE",
      "Time Zone Offset (Hours)": 0,
      "Address": " GENERAL DELIVERY SNOWSHOE WV 26209 USA "
    },
    {
      "Location ID": "26229",
      "Location Name": "LORENTZ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3621 OLD WESTON RD LORENTZ WV 26229 USA "
    },
    {
      "Location ID": "26241",
      "Location Name": "ELKINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 3RD ST FL 1 ELKINS WV 26241 USA "
    },
    {
      "Location ID": "26250",
      "Location Name": "BELINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 801 CRIM AVE BELINGTON WV 26250 USA "
    },
    {
      "Location ID": "26254",
      "Location Name": "BOWDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8835 ALLEGHENY HWY BOWDEN WV 26254 USA "
    },
    {
      "Location ID": "26257",
      "Location Name": "COALTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 CHURCH ST COALTON WV 26257 USA "
    },
    {
      "Location ID": "26260",
      "Location Name": "DAVIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 421 WILLIAM AVE DAVIS WV 26260 USA "
    },
    {
      "Location ID": "26261",
      "Location Name": "RICHWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22 E MAIN ST RICHWOOD WV 26261 USA "
    },
    {
      "Location ID": "26264",
      "Location Name": "DURBIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4345 STAUNTON PARKERSBURG TPKE DURBIN WV 26264 USA "
    },
    {
      "Location ID": "26266",
      "Location Name": "UPPERGLADE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 85 PLEASANT RIDGE RD UPPERGLADE WV 26266 USA "
    },
    {
      "Location ID": "26270",
      "Location Name": "HARMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 62 MOTT ST HARMAN WV 26270 USA "
    },
    {
      "Location ID": "26275",
      "Location Name": "JUNIOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 204 BEECH ST JUNIOR WV 26275 USA "
    },
    {
      "Location ID": "26276",
      "Location Name": "KERENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5173 PARSONS RD KERENS WV 26276 USA "
    },
    {
      "Location ID": "26278",
      "Location Name": "MABIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1010 MABIE CASSITY RD MABIE WV 26278 USA "
    },
    {
      "Location ID": "26283",
      "Location Name": "MONTROSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9152 PARSONS RD MONTROSE WV 26283 USA "
    },
    {
      "Location ID": "26287",
      "Location Name": "PARSONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 235 WALNUT ST RM 1 PARSONS WV 26287 USA "
    },
    {
      "Location ID": "26288",
      "Location Name": "WEBSTER SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 BACK FORK ST WEBSTER SPRINGS WV 26288 USA "
    },
    {
      "Location ID": "26291",
      "Location Name": "SLATYFORK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35161 SENECA TRL SLATYFORK WV 26291 USA "
    },
    {
      "Location ID": "26292",
      "Location Name": "THOMAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 51 EAST AVE THOMAS WV 26292 USA "
    },
    {
      "Location ID": "263",
      "Location Name": "CLARKSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 CAVA DR CLARKSBURG WV 26301 USA "
    },
    {
      "Location ID": "26301",
      "Location Name": "CLARKSBURG DOWNTOWN STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 158 W MAIN ST CLARKSBURG WV 26301 USA "
    },
    {
      "Location ID": "26302",
      "Location Name": "CPU NUTTER FORT STONEWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 OHIO AVE LOWR CLARKSBURG WV 26301 USA "
    },
    {
      "Location ID": "26306",
      "Location Name": "FBI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 CUSTER HOLLOW RD CLARKSBURG WV 26306 USA "
    },
    {
      "Location ID": "26321",
      "Location Name": "ALUM BRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 MOODY LN ALUM BRIDGE WV 26321 USA "
    },
    {
      "Location ID": "26323",
      "Location Name": "ANMOORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2919 PHILIPPI PIKE ANMOORE WV 26323 USA "
    },
    {
      "Location ID": "26330",
      "Location Name": "BRIDGEPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 S VIRGINIA AVE BRIDGEPORT WV 26330 USA "
    },
    {
      "Location ID": "26335",
      "Location Name": "BURNSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 126 S MAIN ST BURNSVILLE WV 26335 USA "
    },
    {
      "Location ID": "26337",
      "Location Name": "CAIRO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 RAILROAD ST CAIRO WV 26337 USA "
    },
    {
      "Location ID": "26338",
      "Location Name": "CAMDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4354 U S HIGHWAY 33 W CAMDEN WV 26338 USA "
    },
    {
      "Location ID": "26346",
      "Location Name": "ELLENBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 E WASHINGTON AVE ELLENBORO WV 26346 USA "
    },
    {
      "Location ID": "26347",
      "Location Name": "FLEMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 42 E VETERANS MEMORIAL HWY FLEMINGTON WV 26347 USA "
    },
    {
      "Location ID": "26348",
      "Location Name": "FOLSOM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28456 SHORTLINE HWY FOLSOM WV 26348 USA "
    },
    {
      "Location ID": "26349",
      "Location Name": "GALLOWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5130 GALLOWAY RD GALLOWAY WV 26349 USA "
    },
    {
      "Location ID": "26351",
      "Location Name": "GLENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 E MAIN ST RM 100 GLENVILLE WV 26351 USA "
    },
    {
      "Location ID": "26354",
      "Location Name": "GRAFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 260 W MAIN ST GRAFTON WV 26354 USA "
    },
    {
      "Location ID": "26361",
      "Location Name": "GYPSY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6426 SHINNSTON PIKE GYPSY WV 26361 USA "
    },
    {
      "Location ID": "26362",
      "Location Name": "HARRISVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 602 E SOUTH ST HARRISVILLE WV 26362 USA "
    },
    {
      "Location ID": "26366",
      "Location Name": "HAYWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1573 HAYWOOD RD HAYWOOD WV 26366 USA "
    },
    {
      "Location ID": "26369",
      "Location Name": "HEPZIBAH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3523 SHINNSTON PIKE HEPZIBAH WV 26369 USA "
    },
    {
      "Location ID": "26372",
      "Location Name": "HORNER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 63 GEORGETOWN RD HORNER WV 26372 USA "
    },
    {
      "Location ID": "26376",
      "Location Name": "IRELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 234 WILDCAT RD IRELAND WV 26376 USA "
    },
    {
      "Location ID": "26377",
      "Location Name": "JACKSONBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18160 SHORTLINE HWY JACKSONBURG WV 26377 USA "
    },
    {
      "Location ID": "26378",
      "Location Name": "JANE LEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 141 INDUSTRIAL PARK RD JANE LEW WV 26378 USA "
    },
    {
      "Location ID": "26385",
      "Location Name": "LOST CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 E MAIN ST LOST CREEK WV 26385 USA "
    },
    {
      "Location ID": "26386",
      "Location Name": "LUMBERPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 489 MAIN ST LUMBERPORT WV 26386 USA "
    },
    {
      "Location ID": "263AA",
      "Location Name": "CLARKSBURG MAIN OFFICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 CAVA DRIVE CLARKSBURG WV 26301-9991 USA "
    },
    {
      "Location ID": "263ST",
      "Location Name": "STAPLES(CLARKSBURG)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 504 EMILY DR CLARKSBURG WV 26301-5507 USA "
    },
    {
      "Location ID": "26404",
      "Location Name": "MEADOWBROOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3754 MEADOWBROOK RD MEADOWBROOK WV 26404 USA "
    },
    {
      "Location ID": "26405",
      "Location Name": "MOATSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13205 MORGANTOWN PIKE MOATSVILLE WV 26405 USA "
    },
    {
      "Location ID": "26408",
      "Location Name": "MOUNT CLARE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3801 MOUNT CLARE RD MOUNT CLARE WV 26408 USA "
    },
    {
      "Location ID": "26410",
      "Location Name": "NEWBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 54 MORGANTOWN AVE NEWBURG WV 26410 USA "
    },
    {
      "Location ID": "26412",
      "Location Name": "ORLANDO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 CEMETARY RD ORLANDO WV 26412 USA "
    },
    {
      "Location ID": "26415",
      "Location Name": "PENNSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 CLAYTON ST PENNSBORO WV 26415 USA "
    },
    {
      "Location ID": "26416",
      "Location Name": "PHILIPPI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 S RAILROAD ST PHILIPPI WV 26416 USA "
    },
    {
      "Location ID": "26419",
      "Location Name": "PINE GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 TRIPLEX ST PINE GROVE WV 26419 USA "
    },
    {
      "Location ID": "26422",
      "Location Name": "REYNOLDSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 GLADYS HOLLOW RD REYNOLDSVILLE WV 26422 USA "
    },
    {
      "Location ID": "26424",
      "Location Name": "ROSEMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1791 W VETERANS MEMORIAL HWY ROSEMONT WV 26424 USA "
    },
    {
      "Location ID": "26425",
      "Location Name": "ROWLESBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 CHESSIE LN ROWLESBURG WV 26425 USA "
    },
    {
      "Location ID": "26426",
      "Location Name": "SALEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 147 W MAIN ST SALEM WV 26426 USA "
    },
    {
      "Location ID": "26431",
      "Location Name": "SHINNSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1001 HOOD AVE SHINNSTON WV 26431 USA "
    },
    {
      "Location ID": "26435",
      "Location Name": "SIMPSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2230 SIMPSON RD SIMPSON WV 26435 USA "
    },
    {
      "Location ID": "26436",
      "Location Name": "SMITHBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2809 SMITHTON RD SMITHBURG WV 26436 USA "
    },
    {
      "Location ID": "26437",
      "Location Name": "SMITHFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 86 MANNINGTON RD SMITHFIELD WV 26437 USA "
    },
    {
      "Location ID": "26438",
      "Location Name": "SPELTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 LEO MENENDEZ DR SPELTER WV 26438 USA "
    },
    {
      "Location ID": "26440",
      "Location Name": "THORNTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3990 THORTON PIKE THORNTON WV 26440 USA "
    },
    {
      "Location ID": "26444",
      "Location Name": "TUNNELTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 BANK ST TUNNELTON WV 26444 USA "
    },
    {
      "Location ID": "26447",
      "Location Name": "WALKERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16957 US HIGHWAY 19 S WALKERSVILLE WV 26447 USA "
    },
    {
      "Location ID": "26448",
      "Location Name": "WALLACE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7737 WALLACE PIKE WALLACE WV 26448 USA "
    },
    {
      "Location ID": "26452",
      "Location Name": "WESTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 CENTER AVE WESTON WV 26452 USA "
    },
    {
      "Location ID": "26456",
      "Location Name": "WEST UNION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 COTTAGE AVE WEST UNION WV 26456 USA "
    },
    {
      "Location ID": "265",
      "Location Name": "MORGANTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 POSTAL PLAZA MORGANTOWN WV 26505 USA "
    },
    {
      "Location ID": "26508",
      "Location Name": "MORGANTOWN CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 POSTAL PLAZA MORGANTOWN WV 26508 USA "
    },
    {
      "Location ID": "26520",
      "Location Name": "ARTHURDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13258 N MOUNTAINEER HWY ARTHURDALE WV 26520 USA "
    },
    {
      "Location ID": "26521",
      "Location Name": "BLACKSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 46 DAYBROOK RD BLACKSVILLE WV 26521 USA "
    },
    {
      "Location ID": "26525",
      "Location Name": "BRUCETON MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 MORGANTOWN ST BRUCETON MILLS WV 26525 USA "
    },
    {
      "Location ID": "26527",
      "Location Name": "CASSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 778 MASON DIXON HWY CASSVILLE WV 26527 USA "
    },
    {
      "Location ID": "26531",
      "Location Name": "DELLSLOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1902 ELJAZID ST DELLSLOW WV 26531 USA "
    },
    {
      "Location ID": "26534",
      "Location Name": "GRANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 524 MAIN ST GRANVILLE WV 26534 USA "
    },
    {
      "Location ID": "26537",
      "Location Name": "KINGWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 225 E MAIN ST KINGWOOD WV 26537 USA "
    },
    {
      "Location ID": "26542",
      "Location Name": "MASONTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 N MAIN ST MASONTOWN WV 26542 USA "
    },
    {
      "Location ID": "26544",
      "Location Name": "PENTRESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 PENTRESS RD PENTRESS WV 26544 USA "
    },
    {
      "Location ID": "26546",
      "Location Name": "PURSGLOVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 576 BLUE HORIZON DR PURSGLOVE WV 26546 USA "
    },
    {
      "Location ID": "26547",
      "Location Name": "REEDSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13275 VETERANS MEMORIAL HWY REEDSVILLE WV 26547 USA "
    },
    {
      "Location ID": "26554",
      "Location Name": "FAIRMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 331 MADISON ST FAIRMONT WV 26554 USA "
    },
    {
      "Location ID": "26559",
      "Location Name": "BARRACKVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 PIKE ST BARRACKVILLE WV 26559 USA "
    },
    {
      "Location ID": "26563",
      "Location Name": "CAROLINA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 MAIN ST CAROLINA WV 26563 USA "
    },
    {
      "Location ID": "26568",
      "Location Name": "ENTERPRISE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3215 HOOD AVE ENTERPRISE WV 26568 USA "
    },
    {
      "Location ID": "26570",
      "Location Name": "FAIRVIEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 310 MAIN ST FAIRVIEW WV 26570 USA "
    },
    {
      "Location ID": "26571",
      "Location Name": "FARMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3003 MAIN ST FARMINGTON WV 26571 USA "
    },
    {
      "Location ID": "26572",
      "Location Name": "FOUR STATES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 84 4 STATES RD FOUR STATES WV 26572 USA "
    },
    {
      "Location ID": "26574",
      "Location Name": "GRANT TOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 1/2 BALLAH AVE GRANT TOWN WV 26574 USA "
    },
    {
      "Location ID": "26575",
      "Location Name": "HUNDRED",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3992 HORNET HWY HUNDRED WV 26575 USA "
    },
    {
      "Location ID": "26576",
      "Location Name": "IDAMAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1194 MAIN ST IDAMAY WV 26576 USA "
    },
    {
      "Location ID": "26578",
      "Location Name": "KINGMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2379 KINGMONT RD KINGMONT WV 26578 USA "
    },
    {
      "Location ID": "26581",
      "Location Name": "LITTLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9585 HORNET HWY LITTLETON WV 26581 USA "
    },
    {
      "Location ID": "26582",
      "Location Name": "MANNINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 WATER ST MANNINGTON WV 26582 USA "
    },
    {
      "Location ID": "26587",
      "Location Name": "RACHEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 134 RACHEL RD RACHEL WV 26587 USA "
    },
    {
      "Location ID": "26588",
      "Location Name": "RIVESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 214 MAIN ST RIVESVILLE WV 26588 USA "
    },
    {
      "Location ID": "26590",
      "Location Name": "WANA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8468 MASON DIXON HWY WANA WV 26590 USA "
    },
    {
      "Location ID": "26591",
      "Location Name": "WORTHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 287 MAIN ST WORTHINGTON WV 26591 USA "
    },
    {
      "Location ID": "26601",
      "Location Name": "SUTTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 306 RIVERVIEW DR SUTTON WV 26601 USA "
    },
    {
      "Location ID": "26610",
      "Location Name": "BIRCH RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 POWELLS CREEK RD BIRCH RIVER WV 26610 USA "
    },
    {
      "Location ID": "26623",
      "Location Name": "FRAMETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7441 ELK RIVER RD FRAMETOWN WV 26623 USA "
    },
    {
      "Location ID": "26624",
      "Location Name": "GASSAWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 ELK ST GASSAWAY WV 26624 USA "
    },
    {
      "Location ID": "26651",
      "Location Name": "SUMMERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 846 BROAD ST SUMMERSVILLE WV 26651 USA "
    },
    {
      "Location ID": "26656",
      "Location Name": "BELVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24652 TURNPIKE RD BELVA WV 26656-9998 USA "
    },
    {
      "Location ID": "26662",
      "Location Name": "CANVAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 OAKWOOD DR CANVAS WV 26662 USA "
    },
    {
      "Location ID": "26667",
      "Location Name": "DRENNEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 MISTY RD DRENNEN WV 26667-9998 USA "
    },
    {
      "Location ID": "26671",
      "Location Name": "GILBOA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5495 TURNPIKE RD GILBOA WV 26671-9998 USA "
    },
    {
      "Location ID": "26676",
      "Location Name": "LEIVASY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5325 LEIVASY RD LEIVASY WV 26676 USA "
    },
    {
      "Location ID": "26681",
      "Location Name": "NETTIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11427 CANVAS NETTIE RD NETTIE WV 26681 USA "
    },
    {
      "Location ID": "26690",
      "Location Name": "SWISS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20492 TURNPIKE RD SWISS WV 26690-9998 USA "
    },
    {
      "Location ID": "26704",
      "Location Name": "AUGUSTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16962 NORTHWESTERN PIKE AUGUSTA WV 26704 USA "
    },
    {
      "Location ID": "26707",
      "Location Name": "BAYARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2257 FRONT ST BAYARD WV 26707 USA "
    },
    {
      "Location ID": "26710",
      "Location Name": "BURLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10393 UPPER PATTERSON CREEK RD BURLINGTON WV 26710 USA "
    },
    {
      "Location ID": "26717",
      "Location Name": "ELK GARDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90 OAK ST ELK GARDEN WV 26717 USA "
    },
    {
      "Location ID": "26719",
      "Location Name": "FORT ASHBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10456 FRANKFORT HWY FORT ASHBY WV 26719 USA "
    },
    {
      "Location ID": "26720",
      "Location Name": "GORMANIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 TRAIN LN GORMANIA WV 26720 USA "
    },
    {
      "Location ID": "26722",
      "Location Name": "GREEN SPRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 167 BELL ST GREEN SPRING WV 26722 USA "
    },
    {
      "Location ID": "26726",
      "Location Name": "KEYSER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 N DAVIS ST KEYSER WV 26726 USA "
    },
    {
      "Location ID": "26739",
      "Location Name": "MOUNT STORM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16615 GEORGE WASHINGTON HWY MOUNT STORM WV 26739 USA "
    },
    {
      "Location ID": "26743",
      "Location Name": "NEW CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 NEW CREEK SCHOOL RD NEW CREEK WV 26743 USA "
    },
    {
      "Location ID": "26750",
      "Location Name": "PIEDMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 ASHFIELD ST PIEDMONT WV 26750 USA "
    },
    {
      "Location ID": "26753",
      "Location Name": "RIDGELEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 VETERANS MEMORIAL HWY RIDGELEY WV 26753 USA "
    },
    {
      "Location ID": "26757",
      "Location Name": "ROMNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 71 N MARSHAM ST ROMNEY WV 26757 USA "
    },
    {
      "Location ID": "26763",
      "Location Name": "SPRINGFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8485 CUMBERLAND RD SPRINGFIELD WV 26763 USA "
    },
    {
      "Location ID": "26764",
      "Location Name": "TERRA ALTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 N 4TH ST TERRA ALTA WV 26764 USA "
    },
    {
      "Location ID": "26767",
      "Location Name": "WILEY FORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 SPRINGFIELD ST WILEY FORD WV 26767 USA "
    },
    {
      "Location ID": "26804",
      "Location Name": "CIRCLEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2878 MOUNT FREEDOM DR CIRCLEVILLE WV 26804 USA "
    },
    {
      "Location ID": "26807",
      "Location Name": "FRANKLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 63 PINE ST FRANKLIN WV 26807 USA "
    },
    {
      "Location ID": "26814",
      "Location Name": "RIVERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 61 TUSCARORA LN RIVERTON WV 26814 USA "
    },
    {
      "Location ID": "26823",
      "Location Name": "CAPON SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8960 BACK CREEK RD CAPON SPRINGS WV 26823 USA "
    },
    {
      "Location ID": "26836",
      "Location Name": "MOOREFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 410 SPRING AVE MOOREFIELD WV 26836 USA "
    },
    {
      "Location ID": "26845",
      "Location Name": "OLD FIELDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 165 OLD FIELDS RD OLD FIELDS WV 26845 USA "
    },
    {
      "Location ID": "26852",
      "Location Name": "PURGITSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6406 PURGITSVILLE PIKE PURGITSVILLE WV 26852 USA "
    },
    {
      "Location ID": "26865",
      "Location Name": "YELLOW SPRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7966 CARPERS PIKE YELLOW SPRING WV 26865 USA "
    },
    {
      "Location ID": "26866",
      "Location Name": "UPPER TRACT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11477 PETERSBURG PIKE UPPER TRACT WV 26866 USA "
    },
    {
      "Location ID": "26884",
      "Location Name": "SENECA ROCKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 648 ALLEGHENY DR SENECA ROCKS WV 26884 USA "
    },
    {
      "Location ID": "270",
      "Location Name": "GREENSBORO P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1120 PLEASANT RIDGE RD GREENSBORO NC 27498 USA "
    },
    {
      "Location ID": "27006",
      "Location Name": "ADVANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1860 NC HIGHWAY 801 S ADVANCE NC 27006 USA "
    },
    {
      "Location ID": "27007",
      "Location Name": "ARARAT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1354 ARARAT RD ARARAT NC 27007 USA "
    },
    {
      "Location ID": "27009",
      "Location Name": "BELEWS CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8540 BELEWS CREEK RD BELEWS CREEK NC 27009 USA "
    },
    {
      "Location ID": "27010",
      "Location Name": "BETHANIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5670 MAIN ST BETHANIA NC 27010 USA "
    },
    {
      "Location ID": "27011",
      "Location Name": "BOONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 S CAROLINA AVE BOONVILLE NC 27011 USA "
    },
    {
      "Location ID": "27012",
      "Location Name": "CLEMMONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3630 CLEMMONS RD CLEMMONS NC 27012 USA "
    },
    {
      "Location ID": "27013",
      "Location Name": "CLEVELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 W MAIN ST CLEVELAND NC 27013 USA "
    },
    {
      "Location ID": "27014",
      "Location Name": "COOLEEMEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7814 NC HWY 801 S COOLEEMEE NC 27014 USA "
    },
    {
      "Location ID": "27016",
      "Location Name": "DANBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 303 N MAIN ST DANBURY NC 27016 USA "
    },
    {
      "Location ID": "27017",
      "Location Name": "DOBSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 214 W KAPP ST DOBSON NC 27017 USA "
    },
    {
      "Location ID": "27018",
      "Location Name": "EAST BEND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 448 E MAIN ST EAST BEND NC 27018 USA "
    },
    {
      "Location ID": "27019",
      "Location Name": "GERMANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3188 NC 65 HWY W GERMANTON NC 27019 USA "
    },
    {
      "Location ID": "27020",
      "Location Name": "HAMPTONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2943 ROCKY BRANCH RD HAMPTONVILLE NC 27020 USA "
    },
    {
      "Location ID": "27021",
      "Location Name": "KING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 603 S MAIN ST KING NC 27021 USA "
    },
    {
      "Location ID": "27022",
      "Location Name": "LAWSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3471 PINEY GROVE CHURCH RD LAWSONVILLE NC 27022 USA "
    },
    {
      "Location ID": "27023",
      "Location Name": "LEWISVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6524 SHALLOWFORD RD LEWISVILLE NC 27023 USA "
    },
    {
      "Location ID": "27024",
      "Location Name": "LOWGAP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8855 W PINE ST LOWGAP NC 27024 USA "
    },
    {
      "Location ID": "27025",
      "Location Name": "MADISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 302 HIGHWAY ST STE 195 MADISON NC 27025 USA "
    },
    {
      "Location ID": "27027",
      "Location Name": "MAYODAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 W MAIN ST MAYODAN NC 27027 USA "
    },
    {
      "Location ID": "27028",
      "Location Name": "MOCKSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1119 YADKINVILLE RD MOCKSVILLE NC 27028 USA "
    },
    {
      "Location ID": "27029",
      "Location Name": "MOUNT AIRY ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 510 N RENFRO ST MOUNT AIRY NC 27030 USA "
    },
    {
      "Location ID": "27030",
      "Location Name": "MOUNT AIRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 S MAIN ST MOUNT AIRY NC 27030 USA "
    },
    {
      "Location ID": "27040",
      "Location Name": "PFAFFTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4715 YADKINVILLE RD PFAFFTOWN NC 27040 USA "
    },
    {
      "Location ID": "27041",
      "Location Name": "PILOT MOUNTAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 647K S KEY ST PILOT MOUNTAIN NC 27041 USA "
    },
    {
      "Location ID": "27042",
      "Location Name": "PINE HALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1716 PINE HALL RD PINE HALL NC 27042 USA "
    },
    {
      "Location ID": "27043",
      "Location Name": "PINNACLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2870 N OLD 52 RD PINNACLE NC 27043 USA "
    },
    {
      "Location ID": "27045",
      "Location Name": "RURAL HALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8201 BROAD ST RURAL HALL NC 27045 USA "
    },
    {
      "Location ID": "27046",
      "Location Name": "SANDY RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4918 NC 704 HWY E SANDY RIDGE NC 27046 USA "
    },
    {
      "Location ID": "27047",
      "Location Name": "SILOAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7051 SILOAM RD SILOAM NC 27047 USA "
    },
    {
      "Location ID": "27048",
      "Location Name": "STONEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 E MAIN ST STONEVILLE NC 27048 USA "
    },
    {
      "Location ID": "27049",
      "Location Name": "TOAST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1110 W PINE ST TOAST NC 27049 USA "
    },
    {
      "Location ID": "27050",
      "Location Name": "TOBACCOVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6900 DORAL DR TOBACCOVILLE NC 27050 USA "
    },
    {
      "Location ID": "27051",
      "Location Name": "WALKERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4970 US HIGHWAY 311 N WALKERTOWN NC 27051 USA "
    },
    {
      "Location ID": "27052",
      "Location Name": "WALNUT COVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 217 N MAIN ST WALNUT COVE NC 27052 USA "
    },
    {
      "Location ID": "27053",
      "Location Name": "WESTFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6648 WESTFIELD RD WESTFIELD NC 27053 USA "
    },
    {
      "Location ID": "27054",
      "Location Name": "WOODLEAF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4220 POTNECK RD WOODLEAF NC 27054 USA "
    },
    {
      "Location ID": "27055",
      "Location Name": "YADKINVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 315 W MAIN ST YADKINVILLE NC 27055 USA "
    },
    {
      "Location ID": "270AA",
      "Location Name": "SOUTHDATA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 TECHNOLOGY LN MT AIRY NC 27030 USA "
    },
    {
      "Location ID": "270BQ",
      "Location Name": "BERMUDA QUAY CONTRACT STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5431 US HWY 158 ADVANCE NC 27006 USA "
    },
    {
      "Location ID": "270CA",
      "Location Name": "GREENSBORO CMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 315 CASSELL ST WINSTON SALEM NC 27107 USA "
    },
    {
      "Location ID": "270CL",
      "Location Name": "2520 LEWISVILLE RD INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2520 LEWISVILLE-CLEMMONS RD CLEMMONS NC 27012-8712 USA "
    },
    {
      "Location ID": "270FZ",
      "Location Name": "LOG GREENSBORO NC PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1671 GREENBOURNE DR STE 101 GREENSBORO NC 27409 USA "
    },
    {
      "Location ID": "270HU",
      "Location Name": "HOP-ME-UP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 S MARKET ST MADISON NC 27025 USA "
    },
    {
      "Location ID": "270KL",
      "Location Name": "KAPLAN EARLY LEARNING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1310 LEWISVILLE CLEMMONS RD LEWISVILLE NC 27023 USA "
    },
    {
      "Location ID": "270LC",
      "Location Name": "2501 LEWISVILLE RD INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2501 LEWISVILLE-CLEMMONS RD CLEMMONS NC 27012-8712 USA "
    },
    {
      "Location ID": "270MM",
      "Location Name": "MEADOWBROOK MALL COURT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6000 MEADOWBROOK MALL CT CLEMMONS NC 27012-8712 USA "
    },
    {
      "Location ID": "270PH",
      "Location Name": "RURAL HALL (PRINCESS HOUSE)",
      "Time Zone Offset (Hours)": 0,
      "Address": " NORTHRIDGE PARK DRIVE RURAL HALL NC 27045 USA "
    },
    {
      "Location ID": "270PS",
      "Location Name": "US 52 PLAZA SHOPPING CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1050 BETHANIA RURAL RD RURAL HALL NC 27045 USA "
    },
    {
      "Location ID": "270SL",
      "Location Name": "RURAL HALL (SARAH LEE/HANES),",
      "Time Zone Offset (Hours)": 0,
      "Address": " 531 NORTHRIDGE PARK DR RURAL HALL NC 27045 USA "
    },
    {
      "Location ID": "270TH",
      "Location Name": "GREENSBORO THS SURFACE HUB",
      "Time Zone Offset (Hours)": 0,
      "Address": " 490 GALLIMORE DAIRY RD GREENSBORO NC 27409 USA "
    },
    {
      "Location ID": "271",
      "Location Name": "WINSTON SALEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1500 N PATTERSON AVE WINSTON SALEM NC 27102 USA "
    },
    {
      "Location ID": "27102",
      "Location Name": "WINSTON SALEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1500 N PATTERSON AVE WINSTON SALEM NC 27102 USA "
    },
    {
      "Location ID": "27103",
      "Location Name": "ARDMORE STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 229 MILLER ST WINSTON SALEM NC 27103 USA "
    },
    {
      "Location ID": "27105",
      "Location Name": "NORTH POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7840 N POINT BLVD STE 110 WINSTON SALEM NC 27106 USA "
    },
    {
      "Location ID": "27106",
      "Location Name": "NORTH POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7840 N POINT BLVD STE 110 WINSTON SALEM NC 27106-3290 USA "
    },
    {
      "Location ID": "27107",
      "Location Name": "WAUGHTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1995 PLEASANT ST WINSTON SALEM NC 27107 USA "
    },
    {
      "Location ID": "27110",
      "Location Name": "WSSU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 601 S MARTIN LUTHER KING DR WINSTON SALEM NC 27110 USA "
    },
    {
      "Location ID": "27114",
      "Location Name": "MANOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3331 HEALY DR WINSTON SALEM NC 27103 USA "
    },
    {
      "Location ID": "27120",
      "Location Name": "CENTER CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 TOWN RUN LN WINSTON SALEM NC 27101 USA "
    },
    {
      "Location ID": "27130",
      "Location Name": "HANES MALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3320 SILAS CREEK PKWY STE 500 WINSTON SALEM NC 27103 USA "
    },
    {
      "Location ID": "271AR",
      "Location Name": "GREENSBORO ARISTA INFO SYSTEMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8075 NORTH POINT BLVD WINSTON SALEM NC 27106 USA "
    },
    {
      "Location ID": "27201",
      "Location Name": "ALAMANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4205 S NC HIGHWAY 62 ALAMANCE NC 27201 USA "
    },
    {
      "Location ID": "27202",
      "Location Name": "ALTAMAHAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3788 ALTMAHAW CHURCH ST ALTAMAHAW NC 27202 USA "
    },
    {
      "Location ID": "27203",
      "Location Name": "ASHEBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1030 S CHURCH ST ASHEBORO NC 27203 USA "
    },
    {
      "Location ID": "27204",
      "Location Name": "ASHEBORO FINANCE CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 340 N FAYETTEVILLE ST ASHEBORO NC 27203 USA "
    },
    {
      "Location ID": "27207",
      "Location Name": "BEAR CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14525 NC 902 HWY BEAR CREEK NC 27207 USA "
    },
    {
      "Location ID": "27208",
      "Location Name": "BENNETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3859 CHATHAM ST BENNETT NC 27208 USA "
    },
    {
      "Location ID": "27209",
      "Location Name": "BISCOE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 303 S MAIN ST BISCOE NC 27209 USA "
    },
    {
      "Location ID": "27213",
      "Location Name": "BONLEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1010 ELMER MOORE RD BONLEE NC 27213 USA "
    },
    {
      "Location ID": "27214",
      "Location Name": "BROWNS SUMMIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7222 BROWNS SUMMIT RD BROWNS SUMMIT NC 27214 USA "
    },
    {
      "Location ID": "27215",
      "Location Name": "BURLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 405 MAPLE AVE BURLINGTON NC 27215 USA "
    },
    {
      "Location ID": "27229",
      "Location Name": "CANDOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 405 N MAIN ST CANDOR NC 27229 USA "
    },
    {
      "Location ID": "27230",
      "Location Name": "CEDAR FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 370 LOFLIN POND RD CEDAR FALLS NC 27230 USA "
    },
    {
      "Location ID": "27231",
      "Location Name": "CEDAR GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5206 EFLAND CEDAR GROVE RD CEDAR GROVE NC 27231 USA "
    },
    {
      "Location ID": "27233",
      "Location Name": "CLIMAX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1319 NC HIGHWAY 62 E CLIMAX NC 27233 USA "
    },
    {
      "Location ID": "27235",
      "Location Name": "COLFAX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9052 W MARKET ST COLFAX NC 27235 USA "
    },
    {
      "Location ID": "27239",
      "Location Name": "DENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 W SALISBURY ST DENTON NC 27239 USA "
    },
    {
      "Location ID": "27242",
      "Location Name": "EAGLE SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1984 NC HWY 211 EAGLE SPRINGS NC 27242 USA "
    },
    {
      "Location ID": "27243",
      "Location Name": "EFLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3522 US HIGHWAY 70 W EFLAND NC 27243 USA "
    },
    {
      "Location ID": "27244",
      "Location Name": "ELON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 S WILLIAMSON AVE ELON NC 27244 USA "
    },
    {
      "Location ID": "27247",
      "Location Name": "ETHER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 HOGAN FARM RD ETHER NC 27247 USA "
    },
    {
      "Location ID": "27248",
      "Location Name": "FRANKLINVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 221 PINE ST FRANKLINVILLE NC 27248 USA "
    },
    {
      "Location ID": "27249",
      "Location Name": "GIBSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 221 E MAIN ST GIBSONVILLE NC 27249 USA "
    },
    {
      "Location ID": "27252",
      "Location Name": "GOLDSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 E GOLDBAR AVE GOLDSTON NC 27252 USA "
    },
    {
      "Location ID": "27253",
      "Location Name": "GRAHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 112 S MARSHALL ST GRAHAM NC 27253 USA "
    },
    {
      "Location ID": "27256",
      "Location Name": "GULF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 833 THRIFT RD GULF NC 27256 USA "
    },
    {
      "Location ID": "27258",
      "Location Name": "HAW RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 ROXBORO ST HAW RIVER NC 27258 USA "
    },
    {
      "Location ID": "27259",
      "Location Name": "HIGHFALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1368 NC HIGHWAY 22 HIGHFALLS NC 27259 USA "
    },
    {
      "Location ID": "27260",
      "Location Name": "HIGH POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 315 E GREEN DR HIGH POINT NC 27260 USA "
    },
    {
      "Location ID": "27262",
      "Location Name": "EMERYWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 ROCKSPRING RD HIGH POINT NC 27262 USA "
    },
    {
      "Location ID": "27263",
      "Location Name": "ARCHDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10193 N MAIN ST ARCHDALE NC 27263 USA "
    },
    {
      "Location ID": "27278",
      "Location Name": "HILLSBOROUGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 144 E KING ST HILLSBOROUGH NC 27278 USA "
    },
    {
      "Location ID": "27281",
      "Location Name": "JACKSON SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2411 JACKSON SPRINGS RD JACKSON SPRINGS NC 27281 USA "
    },
    {
      "Location ID": "27282",
      "Location Name": "JAMESTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 203 E MAIN ST JAMESTOWN NC 27282 USA "
    },
    {
      "Location ID": "27283",
      "Location Name": "JULIAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6600 LIBERTY RD JULIAN NC 27283 USA "
    },
    {
      "Location ID": "27284",
      "Location Name": "KERNERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 325 W MOUNTAIN ST KERNERSVILLE NC 27284 USA "
    },
    {
      "Location ID": "27288",
      "Location Name": "EDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 332 N PIERCE ST EDEN NC 27288 USA "
    },
    {
      "Location ID": "27291",
      "Location Name": "LEASBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7330 US HIGHWAY 158 E LEASBURG NC 27291 USA "
    },
    {
      "Location ID": "27292",
      "Location Name": "LEXINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 N STATE ST LEXINGTON NC 27292 USA "
    },
    {
      "Location ID": "27298",
      "Location Name": "LIBERTY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 416 W SWANNANOA AVE LIBERTY NC 27298 USA "
    },
    {
      "Location ID": "27299",
      "Location Name": "LINWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4276 OLD LINWOOD RD LINWOOD NC 27299 USA "
    },
    {
      "Location ID": "272AH",
      "Location Name": "ADVANCE HOME CARE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4001 PIEDMONT PKWY HIGH POINT NC 27265 USA "
    },
    {
      "Location ID": "272AN",
      "Location Name": "ADVANTIS NUTRITION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 237 W CENTRAL AVE ASHEBORO NC 27203 USA "
    },
    {
      "Location ID": "272CT",
      "Location Name": "CARROT TOP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 328 ELIZABETH BRADY RD HILLSBOROUGH NC 27278 USA "
    },
    {
      "Location ID": "272EA",
      "Location Name": "EAGLES CPU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 620 HAMPTON PT HILLSBOROUGH NC 27278 USA "
    },
    {
      "Location ID": "272ES",
      "Location Name": "EUROSPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1014 CORPORATE PARK DR MEBANE NC 27302 USA "
    },
    {
      "Location ID": "272FJ",
      "Location Name": "FLYING J TRUCK STOP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1043 JIMMIE KERR RD HAW RIVER NC 27258 USA "
    },
    {
      "Location ID": "272FW",
      "Location Name": "FORMAL WEAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 415 MILLSTONE DR HILLSBOROUGH NC 27278 USA "
    },
    {
      "Location ID": "272GD",
      "Location Name": "GREENSBORO DIXIE SALES INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5920 SUMMIT AVE BROWNS SUMMIT NC 27214 USA "
    },
    {
      "Location ID": "272HC",
      "Location Name": "HILLSBOROUGH COLBOX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 129 MAYO ST HILLSBOROUGH NC 27278 USA "
    },
    {
      "Location ID": "272HL",
      "Location Name": "HARLAND CLARKE, NC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4475 PREMIER DR HIGH POINT NC 27265 USA "
    },
    {
      "Location ID": "272JJ",
      "Location Name": "JUICY JAVA (GATEWAY CPU)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 PINEVIEW DRIVE STE 1 KERNERSVILLE NC 27284 USA "
    },
    {
      "Location ID": "272JR",
      "Location Name": "JR TOBACCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2589 ERIC LN BURLINGTON NC 27215 USA "
    },
    {
      "Location ID": "272JT",
      "Location Name": "JOHNNY T-SHIRT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 416 MILLSTONE DR HILLSBOROUGH NC 27278 USA "
    },
    {
      "Location ID": "272LB",
      "Location Name": "23 & ME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 231A BURKE ST GIBSONVILLE NC 27249 USA "
    },
    {
      "Location ID": "272LC",
      "Location Name": "LABCORP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1225 JAY LANE GRAHAM NC 27253 USA "
    },
    {
      "Location ID": "272LS",
      "Location Name": "LIBERTY SHOPPING CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 422 N GREENSBORO ST LIBERTY NC 27298 USA "
    },
    {
      "Location ID": "272LW",
      "Location Name": "LOLLY WOLLY DOODLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 474 OLD HARGRAVE RD LEXINGTON NC 27295 USA "
    },
    {
      "Location ID": "272NW",
      "Location Name": "NATIONAL WHOLESALE (27048)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 NATIONAL BLVD LEXINGTON NC 27292 USA "
    },
    {
      "Location ID": "272PH",
      "Location Name": "PHE INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 302 MEADOWLAND DR HILLSBOROUGH NC 27278 USA "
    },
    {
      "Location ID": "272PP",
      "Location Name": "PERFECT PARTY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1381 SOUTH PARK DRIVE KERNERSVILLE NC 27284 USA "
    },
    {
      "Location ID": "272RD",
      "Location Name": "REDEYE DISTRIBUTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 449-A TROLLINGWOOD RD HAW RIVER NC 27258 USA "
    },
    {
      "Location ID": "272SL",
      "Location Name": "SEVEN LAKES SHOPPING CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1984 NC-211 EAGLE SPRING NC 27242 USA "
    },
    {
      "Location ID": "272SP",
      "Location Name": "SMART POWDERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1130 CHERRY LN GRAHAM NC 27253 USA "
    },
    {
      "Location ID": "272TA",
      "Location Name": "T & A TRUCKING STOP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7229 PACE DRIVE WHITSETT NC 27377 USA "
    },
    {
      "Location ID": "272TY",
      "Location Name": "TYRO CPU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4344 S NORTH CAROLINA HWY 150 LEXINGTON NC 27295 USA "
    },
    {
      "Location ID": "272VC",
      "Location Name": "VITACOST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 LEXINGTON PKWY LEXINGTON NC 27295 USA "
    },
    {
      "Location ID": "272VT",
      "Location Name": "VIETRI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 343 ELIZABETH BRADY RD HILLSBOROUGH NC 27278 USA "
    },
    {
      "Location ID": "27301",
      "Location Name": "MC LEANSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5711 MCLEANSVILLE RD MC LEANSVILLE NC 27301 USA "
    },
    {
      "Location ID": "27302",
      "Location Name": "MEBANE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 VILLAGE DR MEBANE NC 27302 USA "
    },
    {
      "Location ID": "27305",
      "Location Name": "MILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 238 BROAD ST MILTON NC 27305 USA "
    },
    {
      "Location ID": "27306",
      "Location Name": "MOUNT GILEAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 N SCHOOL ST MOUNT GILEAD NC 27306 USA "
    },
    {
      "Location ID": "27310",
      "Location Name": "OAK RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8323 LINVILLE RD OAK RIDGE NC 27310 USA "
    },
    {
      "Location ID": "27311",
      "Location Name": "PELHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6331 OLD US HIGHWAY 29 PELHAM NC 27311 USA "
    },
    {
      "Location ID": "27312",
      "Location Name": "PITTSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 916 EAST ST PITTSBORO NC 27312 USA "
    },
    {
      "Location ID": "27313",
      "Location Name": "PLEASANT GARDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4621 PLEASANT GARDEN RD PLEASANT GARDEN NC 27313 USA "
    },
    {
      "Location ID": "27314",
      "Location Name": "PROSPECT HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 MAIN ST PROSPECT HILL NC 27314 USA "
    },
    {
      "Location ID": "27315",
      "Location Name": "PROVIDENCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 48 PARK SPRINGS RD PROVIDENCE NC 27315 USA "
    },
    {
      "Location ID": "27316",
      "Location Name": "RAMSEUR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1514 MAIN ST RAMSEUR NC 27316 USA "
    },
    {
      "Location ID": "27317",
      "Location Name": "RANDLEMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 611 W ACADEMY ST RANDLEMAN NC 27317 USA "
    },
    {
      "Location ID": "27320",
      "Location Name": "REIDSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 PIEDMONT ST REIDSVILLE NC 27320 USA "
    },
    {
      "Location ID": "27325",
      "Location Name": "ROBBINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 181 N MIDDLETON ST ROBBINS NC 27325 USA "
    },
    {
      "Location ID": "27326",
      "Location Name": "RUFFIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9682 US HIGHWAY 29 BUSINESS RUFFIN NC 27326 USA "
    },
    {
      "Location ID": "27330",
      "Location Name": "SANFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1200 S HORNER BLVD SANFORD NC 27330 USA "
    },
    {
      "Location ID": "27340",
      "Location Name": "SAXAPAHAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1612 JORDAN DR SAXAPAHAW NC 27340 USA "
    },
    {
      "Location ID": "27341",
      "Location Name": "SEAGROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 231 N BROAD ST SEAGROVE NC 27341 USA "
    },
    {
      "Location ID": "27342",
      "Location Name": "SEDALIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6151 BURLINGTON RD SEDALIA NC 27342 USA "
    },
    {
      "Location ID": "27343",
      "Location Name": "SEMORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14757 NC HIGHWAY 119 N SEMORA NC 27343 USA "
    },
    {
      "Location ID": "27344",
      "Location Name": "SILER CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 E RALEIGH ST SILER CITY NC 27344 USA "
    },
    {
      "Location ID": "27349",
      "Location Name": "SNOW CAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 W GREENSBORO CHAPEL HILL RD SNOW CAMP NC 27349 USA "
    },
    {
      "Location ID": "27350",
      "Location Name": "SOPHIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5814 US HIGHWAY 311 SOPHIA NC 27350 USA "
    },
    {
      "Location ID": "27351",
      "Location Name": "SOUTHMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10584 NC HIGHWAY 8 S SOUTHMONT NC 27351 USA "
    },
    {
      "Location ID": "27355",
      "Location Name": "STALEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 229 W FRANKLINVILLE ST STALEY NC 27355 USA "
    },
    {
      "Location ID": "27356",
      "Location Name": "STAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 S MAIN ST STAR NC 27356 USA "
    },
    {
      "Location ID": "27357",
      "Location Name": "STOKESDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8210 US HIGHWAY 158 STOKESDALE NC 27357 USA "
    },
    {
      "Location ID": "27358",
      "Location Name": "SUMMERFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7412 SUMMERFIELD RD SUMMERFIELD NC 27358 USA "
    },
    {
      "Location ID": "27359",
      "Location Name": "SWEPSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1072 W MAIN ST SWEPSONVILLE NC 27359 USA "
    },
    {
      "Location ID": "27360",
      "Location Name": "THOMASVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 W MAIN ST THOMASVILLE NC 27360 USA "
    },
    {
      "Location ID": "27370",
      "Location Name": "TRINITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7259 NC HIGHWAY 62 TRINITY NC 27370 USA "
    },
    {
      "Location ID": "27371",
      "Location Name": "TROY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 601 N MAIN ST TROY NC 27371 USA "
    },
    {
      "Location ID": "27373",
      "Location Name": "WALLBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8590 N NC HWY 109 WALLBURG NC 27373 USA "
    },
    {
      "Location ID": "27374",
      "Location Name": "WELCOME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6325 OLD US HIGHWAY 52 WELCOME NC 27374 USA "
    },
    {
      "Location ID": "27375",
      "Location Name": "WENTWORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 COUNTY HOME RD WENTWORTH NC 27375 USA "
    },
    {
      "Location ID": "27376",
      "Location Name": "WEST END",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5161 NC HIGHWAY 211 WEST END NC 27376 USA "
    },
    {
      "Location ID": "27377",
      "Location Name": "WHITSETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1001 NC HIGHWAY 61 WHITSETT NC 27377 USA "
    },
    {
      "Location ID": "27379",
      "Location Name": "YANCEYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 146 MAIN ST YANCEYVILLE NC 27379 USA "
    },
    {
      "Location ID": "273AA",
      "Location Name": "LASER PRINT PLUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 FOREST PARK LN THOMASVILLE NC 27360 USA "
    },
    {
      "Location ID": "273AM",
      "Location Name": "AMAZON RDU9 - MEBANE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3875 SEN RALPH SCOTT PKWY MEBANE NC 27302 USA "
    },
    {
      "Location ID": "273AN",
      "Location Name": "ADVANCE NUTRITION (WHITSETT)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6491  FRANZ WARNER PKWY WHITSETT NC 27377 USA "
    },
    {
      "Location ID": "273CB",
      "Location Name": "PITTSBORO COLBOX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 W SALISBURY ST PITTSBORO NC 27312 USA "
    },
    {
      "Location ID": "273CM",
      "Location Name": "COMFORTLAND MEDICAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 709 AO SMITH RD MEBANE NC 27302 USA "
    },
    {
      "Location ID": "273MM",
      "Location Name": "MEDI MANUFACTURING INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6481  FRANZ WARNER PKWY WHITSETT NC 27377 USA "
    },
    {
      "Location ID": "273WE",
      "Location Name": "WEST END TOYS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 N SCALES ST REIDSVILLE NC 48745 USA "
    },
    {
      "Location ID": "27401",
      "Location Name": "GREENSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 N MURROW BLVD GREENSBORO NC 27401 USA "
    },
    {
      "Location ID": "27404",
      "Location Name": "FRIENDLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 610 PEMBROKE RD GREENSBORO NC 27404 USA "
    },
    {
      "Location ID": "27405",
      "Location Name": "SUMMIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1585 YANCEYVILLE ST GREENSBORO NC 27405 USA "
    },
    {
      "Location ID": "27406",
      "Location Name": "SPRING VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2300 SOABAR ST GREENSBORO NC 27406 USA "
    },
    {
      "Location ID": "27408",
      "Location Name": "WESTSIDE CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4528 W MARKET ST GREENSBORO NC 27407 USA "
    },
    {
      "Location ID": "27413",
      "Location Name": "UNC GREENSBORO CONTRACT STA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 SPRING GARDEN ST GREENSBORO NC 27413 USA "
    },
    {
      "Location ID": "27417",
      "Location Name": "HILLTOP FINANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4615 W GATE CITY BLVD GREENSBORO NC 27407 USA "
    },
    {
      "Location ID": "27419",
      "Location Name": "GUILFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5704 W MARKET ST GREENSBORO NC 27409 USA "
    },
    {
      "Location ID": "27427",
      "Location Name": "FOUR SEASONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 FOUR SEASONS TOWN CTR GREENSBORO NC 27407 USA "
    },
    {
      "Location ID": "27438",
      "Location Name": "BATTLEGROUND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2941 BATTLEGROUND AVE GREENSBORO NC 27408 USA "
    },
    {
      "Location ID": "274AH",
      "Location Name": "ACE HARDWARE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3002 RANDLEMAN RD GREENSBORO NC 27406 USA "
    },
    {
      "Location ID": "274B9",
      "Location Name": "BOARD PARADISE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1130 TARRANT RD GREENSBORO NC 27409-9255 USA "
    },
    {
      "Location ID": "274BP",
      "Location Name": "BOARD PARADISE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1130 TARRANT RD GREENSBORO NC 27409 USA "
    },
    {
      "Location ID": "274GC",
      "Location Name": "GREENSBORO CARSON DELLOSA PUB",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4200 TUDOR LANE GREENSBORO NC 27410 USA "
    },
    {
      "Location ID": "274MA",
      "Location Name": "MARKET AMERICA INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1302 PLEASANT RIDGE RD GREENSBORO NC 27409 USA "
    },
    {
      "Location ID": "274NG",
      "Location Name": "NEW GROWTH PRESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1451 S ELM EUGENE ST GREENSBORO NC 27406 USA "
    },
    {
      "Location ID": "274RS",
      "Location Name": "RSVP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8795 W MARKET ST GREENSBORO NC 27409 USA "
    },
    {
      "Location ID": "274TT",
      "Location Name": "TWININGS TEA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2812 TWINNING RD GREENSBORO NC 27406 USA "
    },
    {
      "Location ID": "274WF",
      "Location Name": "WILLIS FABER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1603 WILLOS RD GREENSBORO NC 27401 USA "
    },
    {
      "Location ID": "275",
      "Location Name": "RALEIGH P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 FLORETTA PL RALEIGH NC 27676 USA "
    },
    {
      "Location ID": "27502",
      "Location Name": "APEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 501 W WILLIAMS ST APEX NC 27502 USA "
    },
    {
      "Location ID": "27503",
      "Location Name": "BAHAMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1420 BAHAMA RD BAHAMA NC 27503 USA "
    },
    {
      "Location ID": "27504",
      "Location Name": "BENSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 402 S MARKET ST BENSON NC 27504 USA "
    },
    {
      "Location ID": "27505",
      "Location Name": "BROADWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 S MAIN ST BROADWAY NC 27505 USA "
    },
    {
      "Location ID": "27506",
      "Location Name": "BUIES CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 254 MAIN ST BUIES CREEK NC 27506 USA "
    },
    {
      "Location ID": "27507",
      "Location Name": "BULLOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8682 US HIGHWAY 15 BULLOCK NC 27507 USA "
    },
    {
      "Location ID": "27508",
      "Location Name": "BUNN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 313 MAIN ST BUNN NC 27508 USA "
    },
    {
      "Location ID": "27509",
      "Location Name": "BUTNER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 331 CENTRAL AVE BUTNER NC 27509 USA "
    },
    {
      "Location ID": "27510",
      "Location Name": "CARRBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1500 W MAIN ST CARRBORO NC 27510 USA "
    },
    {
      "Location ID": "27511",
      "Location Name": "CARY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 WRENN DR CARY NC 27511 USA "
    },
    {
      "Location ID": "27514",
      "Location Name": "CHAPEL HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 S ESTES DR CHAPEL HILL NC 27514 USA "
    },
    {
      "Location ID": "27519",
      "Location Name": "ACADEMY ST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 S ACADEMY ST CARY NC 27519 USA "
    },
    {
      "Location ID": "27520",
      "Location Name": "CLAYTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 604 E FRONT ST CLAYTON NC 27520 USA "
    },
    {
      "Location ID": "27521",
      "Location Name": "COATS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 43 W MAIN ST COATS NC 27521 USA "
    },
    {
      "Location ID": "27522",
      "Location Name": "CREEDMOOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2157 WILTON AVE CREEDMOOR NC 27522 USA "
    },
    {
      "Location ID": "27524",
      "Location Name": "FOUR OAKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 N RAILROAD ST FOUR OAKS NC 27524 USA "
    },
    {
      "Location ID": "27525",
      "Location Name": "FRANKLINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 320 S MAIN ST FRANKLINTON NC 27525 USA "
    },
    {
      "Location ID": "27529",
      "Location Name": "GARNER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 267 TIMBER DR GARNER NC 27529 USA "
    },
    {
      "Location ID": "27530",
      "Location Name": "GOLDSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 N WILLIAM ST GOLDSBORO NC 27530 USA "
    },
    {
      "Location ID": "27534",
      "Location Name": "BERKELEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3100 CASHWELL DR GOLDSBORO NC 27534 USA "
    },
    {
      "Location ID": "27536",
      "Location Name": "HENDERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 905 S GARNETT ST HENDERSON NC 27536 USA "
    },
    {
      "Location ID": "27541",
      "Location Name": "HURDLE MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9581 HURDLE MILLS RD HURDLE MILLS NC 27541 USA "
    },
    {
      "Location ID": "27542",
      "Location Name": "KENLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 N GARDNER AVE KENLY NC 27542 USA "
    },
    {
      "Location ID": "27543",
      "Location Name": "KIPLING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 NEILL SMITH RD KIPLING NC 27543 USA "
    },
    {
      "Location ID": "27544",
      "Location Name": "KITTRELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1400 US HIGHWAY 1 S KITTRELL NC 27544 USA "
    },
    {
      "Location ID": "27545",
      "Location Name": "KNIGHTDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1003 MULFORD CT KNIGHTDALE NC 27545 USA "
    },
    {
      "Location ID": "27546",
      "Location Name": "LILLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 901 S 8TH ST LILLINGTON NC 27546 USA "
    },
    {
      "Location ID": "27549",
      "Location Name": "LOUISBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 N MAIN ST LOUISBURG NC 27549 USA "
    },
    {
      "Location ID": "27551",
      "Location Name": "MACON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1608 US HIGHWAY 158 E MACON NC 27551 USA "
    },
    {
      "Location ID": "27552",
      "Location Name": "MAMERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 61 MAMERS RD MAMERS NC 27552 USA "
    },
    {
      "Location ID": "27553",
      "Location Name": "MANSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1402 US HIGHWAY 1 S MANSON NC 27553 USA "
    },
    {
      "Location ID": "27555",
      "Location Name": "MICRO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 S RAILROAD ST MICRO NC 27555 USA "
    },
    {
      "Location ID": "27556",
      "Location Name": "MIDDLEBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 69 N HAWKINS AVE MIDDLEBURG NC 27556 USA "
    },
    {
      "Location ID": "27557",
      "Location Name": "MIDDLESEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10286 S NASH ST MIDDLESEX NC 27557 USA "
    },
    {
      "Location ID": "27559",
      "Location Name": "MONCURE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 213 POST OFFICE RD MONCURE NC 27559 USA "
    },
    {
      "Location ID": "27562",
      "Location Name": "NEW HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3525 OLD US 1 HWY NEW HILL NC 27562 USA "
    },
    {
      "Location ID": "27563",
      "Location Name": "NORLINA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 306 LIBERTY ST NORLINA NC 27563 USA "
    },
    {
      "Location ID": "27565",
      "Location Name": "OXFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 144 MAIN ST OXFORD NC 27565 USA "
    },
    {
      "Location ID": "27568",
      "Location Name": "PINE LEVEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2180 US HIGHWAY 70A E SELMA NC 27576 USA "
    },
    {
      "Location ID": "27569",
      "Location Name": "PRINCETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 W EDWARDS ST PRINCETON NC 27569 USA "
    },
    {
      "Location ID": "27570",
      "Location Name": "RIDGEWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 686 US HIGHWAY 1 S RIDGEWAY NC 27570 USA "
    },
    {
      "Location ID": "27571",
      "Location Name": "ROLESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6470 ROGERS RD ROLESVILLE NC 27571 USA "
    },
    {
      "Location ID": "27572",
      "Location Name": "ROUGEMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12311 ROXBORO RD ROUGEMONT NC 27572 USA "
    },
    {
      "Location ID": "27573",
      "Location Name": "ROXBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 223 S MAIN ST ROXBORO NC 27573 USA "
    },
    {
      "Location ID": "27576",
      "Location Name": "SELMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 308 N RAIFORD ST SELMA NC 27576 USA "
    },
    {
      "Location ID": "27577",
      "Location Name": "SMITHFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 N 3RD ST SMITHFIELD NC 27577 USA "
    },
    {
      "Location ID": "27581",
      "Location Name": "STEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 E TALLY HO RD STEM NC 27581 USA "
    },
    {
      "Location ID": "27582",
      "Location Name": "STOVALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 MAIN ST STOVALL NC 27582 USA "
    },
    {
      "Location ID": "27583",
      "Location Name": "TIMBERLAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 545 HELENA MORIAH RD TIMBERLAKE NC 27583 USA "
    },
    {
      "Location ID": "27584",
      "Location Name": "TOWNSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12525 NC HIGHWAY 39 N TOWNSVILLE NC 27584 USA "
    },
    {
      "Location ID": "27586",
      "Location Name": "VAUGHAN             NC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 EATONS FERRY RD VAUGHAN NC 27586 USA "
    },
    {
      "Location ID": "27587",
      "Location Name": "WAKE FOREST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 224 E HOLDING AVE WAKE FOREST NC 27587 USA "
    },
    {
      "Location ID": "27589",
      "Location Name": "WARRENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 143 N MAIN ST WARRENTON NC 27589 USA "
    },
    {
      "Location ID": "27591",
      "Location Name": "WENDELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 HANOR LN WENDELL NC 27591 USA "
    },
    {
      "Location ID": "27593",
      "Location Name": "WILSONS MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 71 FIRE DEPARTMENT RD WILSONS MILLS NC 27593 USA "
    },
    {
      "Location ID": "27594",
      "Location Name": "WISE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1728 US 1 HWY N WISE NC 27594 USA "
    },
    {
      "Location ID": "27596",
      "Location Name": "YOUNGSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 112 E PERSIMMON ST YOUNGSVILLE NC 27596 USA "
    },
    {
      "Location ID": "27597",
      "Location Name": "ZEBULON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 139 E VANCE ST ZEBULON NC 27597 USA "
    },
    {
      "Location ID": "275AC",
      "Location Name": "ARTFUL COLOR LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2501 SCHIEFFELIN RD APEX NC 27502 USA "
    },
    {
      "Location ID": "275AF",
      "Location Name": "ALLPACK FULFILLMENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 20TH ST BUTNER NC 27509 USA "
    },
    {
      "Location ID": "275AS",
      "Location Name": "AGRI SUPPLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 582 LOWELL MILL RD SELMA NC 27576 USA "
    },
    {
      "Location ID": "275BP",
      "Location Name": "BLUE PACK MARKETING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7868 US HIGHWAY 70 W STE B CLAYTON NC 27520 USA "
    },
    {
      "Location ID": "275CH",
      "Location Name": "CULTURES FOR HEALTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 INNOVATION AVE STE 150 MORRISVILLE NC 27560 USA "
    },
    {
      "Location ID": "275JA",
      "Location Name": "JERRY\"S ARTARAMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 PETER GILL ROAD HENDERSON NC 27537 USA "
    },
    {
      "Location ID": "275RA",
      "Location Name": "ROCK AUTO DBA UNITED CONSOLIDATED INDUSTRIES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1225 W GRANTHAM ST GOLDSBORO NC 27530 USA "
    },
    {
      "Location ID": "275SG",
      "Location Name": "TACTICAL SCORPION GEAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 W HOLLY ST GOLDSBORO NC 27530 USA "
    },
    {
      "Location ID": "275SR",
      "Location Name": "SHIELD REPUBLIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 67 JR RD STE 900 SELMA NC 27576 USA "
    },
    {
      "Location ID": "275ST",
      "Location Name": "SANTA FE TOBACCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3220 KNOTTS GROVE RD OXFORD NC 27565 USA "
    },
    {
      "Location ID": "275TW",
      "Location Name": "THREE WISHES LINGERIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2144 E LYON STATION RD CREEDMOOR NC 27522 USA "
    },
    {
      "Location ID": "275WB",
      "Location Name": "THE WOOBLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1600 OLIVE CHAPEL RD STE 228 APEX NC 27502 USA "
    },
    {
      "Location ID": "276",
      "Location Name": "RALEIGH P&DC SOUTH DOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 FLORETTA RALEIGH NC 27676-9998 USA "
    },
    {
      "Location ID": "27613",
      "Location Name": "HILBURN ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6508 HILBURN DR RALEIGH NC 27613 USA "
    },
    {
      "Location ID": "27676",
      "Location Name": "RALEIGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 FLORETTA PL RM 88 RALEIGH NC 27676 USA "
    },
    {
      "Location ID": "276CA",
      "Location Name": "RALEIGH CHRISTMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5511 W TEN RD MEBANE NC 27302 USA "
    },
    {
      "Location ID": "276GR",
      "Location Name": "GREEN RESOURCES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1218 MANAGEMENT WAY GARNER NC 27529 USA "
    },
    {
      "Location ID": "277AM",
      "Location Name": "AMAZON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1805 TW ALEXANDER DR DURHAM NC 27703 USA "
    },
    {
      "Location ID": "277PS",
      "Location Name": "UPS - PEAK SUPPLY CHAIN SOLUTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1001 HILL DR STE 175 DURHAM NC 27703 USA "
    },
    {
      "Location ID": "278",
      "Location Name": "ROCKY MOUNT P&DF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 327 E RALEIGH BLVD ROCKY MOUNT NC 27801 USA "
    },
    {
      "Location ID": "27804",
      "Location Name": "WESTRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3695 SUNSET AVE ROCKY MOUNT NC 27804 USA "
    },
    {
      "Location ID": "27805",
      "Location Name": "AULANDER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 W MAIN ST AULANDER NC 27805 USA "
    },
    {
      "Location ID": "27806",
      "Location Name": "AURORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 186 N 5TH ST AURORA NC 27806 USA "
    },
    {
      "Location ID": "27807",
      "Location Name": "BAILEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6171 DEANS ST BAILEY NC 27807 USA "
    },
    {
      "Location ID": "27808",
      "Location Name": "BATH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 415 CARTERET ST BATH NC 27808 USA "
    },
    {
      "Location ID": "27809",
      "Location Name": "BATTLEBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 241 W BATTLEBORO AVE BATTLEBORO NC 27809 USA "
    },
    {
      "Location ID": "27810",
      "Location Name": "BELHAVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 614 W OLD COUNTY RD BELHAVEN NC 27810 USA "
    },
    {
      "Location ID": "27811",
      "Location Name": "BELLARTHUR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2643B FIREHOUSE RD BELLARTHUR NC 27811 USA "
    },
    {
      "Location ID": "27812",
      "Location Name": "BETHEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 EAST ST BETHEL NC 27812 USA "
    },
    {
      "Location ID": "27813",
      "Location Name": "BLACK CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 CENTRAL AVE BLACK CREEK NC 27813 USA "
    },
    {
      "Location ID": "27814",
      "Location Name": "BLOUNTS CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13746 NC HIGHWAY 33 E BLOUNTS CREEK NC 27814 USA "
    },
    {
      "Location ID": "27816",
      "Location Name": "CASTALIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9512 MAIN ST CASTALIA NC 27816 USA "
    },
    {
      "Location ID": "27817",
      "Location Name": "CHOCOWINITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 PATRICK LN CHOCOWINITY NC 27817 USA "
    },
    {
      "Location ID": "27818",
      "Location Name": "COMO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1226 US HIGHWAY 258 N COMO NC 27818 USA "
    },
    {
      "Location ID": "27819",
      "Location Name": "CONETOE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 W CHURCH ST CONETOE NC 27819 USA "
    },
    {
      "Location ID": "27820",
      "Location Name": "CONWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 S CHURCH ST CONWAY NC 27820 USA "
    },
    {
      "Location ID": "27821",
      "Location Name": "EDWARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19160 NC HIGHWAY 33 E EDWARD NC 27821 USA "
    },
    {
      "Location ID": "27822",
      "Location Name": "ELM CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 409 W MAIN ST ELM CITY NC 27822 USA "
    },
    {
      "Location ID": "27823",
      "Location Name": "ENFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 S DENNIS ST ENFIELD NC 27823 USA "
    },
    {
      "Location ID": "27824",
      "Location Name": "ENGELHARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34668 US HIGHWAY 264 ENGELHARD NC 27824 USA "
    },
    {
      "Location ID": "27825",
      "Location Name": "EVERETTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 N BROAD ST EVERETTS NC 27825 USA "
    },
    {
      "Location ID": "27826",
      "Location Name": "FAIRFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7089 NC HIGHWAY 94 FAIRFIELD NC 27826 USA "
    },
    {
      "Location ID": "27827",
      "Location Name": "FALKLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6063 NC HIGHWAY 43 N FALKLAND NC 27827 USA "
    },
    {
      "Location ID": "27828",
      "Location Name": "FARMVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3703 N MAIN ST FARMVILLE NC 27828 USA "
    },
    {
      "Location ID": "27829",
      "Location Name": "FOUNTAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6738 E WILSON ST FOUNTAIN NC 27829 USA "
    },
    {
      "Location ID": "27830",
      "Location Name": "FREMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 E MAIN ST FREMONT NC 27830 USA "
    },
    {
      "Location ID": "27831",
      "Location Name": "GARYSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 213 OLD HIGHWAY RD GARYSBURG NC 27831 USA "
    },
    {
      "Location ID": "27834",
      "Location Name": "GREENVILLE MPO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 W 2ND ST GREENVILLE NC 27834 USA "
    },
    {
      "Location ID": "27837",
      "Location Name": "GRIMESLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7607 PITT ST GRIMESLAND NC 27837 USA "
    },
    {
      "Location ID": "27839",
      "Location Name": "HALIFAX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 S KING ST HALIFAX NC 27839 USA "
    },
    {
      "Location ID": "27843",
      "Location Name": "HOBGOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 218 COMMERCE ST HOBGOOD NC 27843 USA "
    },
    {
      "Location ID": "27844",
      "Location Name": "HOLLISTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 231 GIBBS AVE HOLLISTER NC 27844 USA "
    },
    {
      "Location ID": "27845",
      "Location Name": "JACKSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 ATHERTON ST JACKSON NC 27845 USA "
    },
    {
      "Location ID": "27846",
      "Location Name": "JAMESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1840 MAIN ST JAMESVILLE NC 27846 USA "
    },
    {
      "Location ID": "27850",
      "Location Name": "LITTLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 MOSBY AVE LITTLETON NC 27850 USA "
    },
    {
      "Location ID": "27851",
      "Location Name": "LUCAMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 S MAIN ST LUCAMA NC 27851 USA "
    },
    {
      "Location ID": "27852",
      "Location Name": "MACCLESFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 W EDGECOMBE ST MACCLESFIELD NC 27852 USA "
    },
    {
      "Location ID": "27853",
      "Location Name": "MARGARETTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2717 TOWER RD MARGARETTSVILLE NC 27853 USA "
    },
    {
      "Location ID": "27855",
      "Location Name": "MURFREESBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 N WYNN ST MURFREESBORO NC 27855 USA "
    },
    {
      "Location ID": "27856",
      "Location Name": "NASHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 504 S BARNES ST NASHVILLE NC 27856 USA "
    },
    {
      "Location ID": "27858",
      "Location Name": "GREENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 806 E 10TH ST GREENVILLE NC 27858 USA "
    },
    {
      "Location ID": "27860",
      "Location Name": "PANTEGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26022 US HIGHWAY 264 E PANTEGO NC 27860 USA "
    },
    {
      "Location ID": "27861",
      "Location Name": "PARMELE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1063 JAMES RD ROBERSONVILLE NC 27871 USA "
    },
    {
      "Location ID": "27862",
      "Location Name": "PENDLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7488 NC HIGHWAY 35 PENDLETON NC 27862 USA "
    },
    {
      "Location ID": "27863",
      "Location Name": "PIKEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 214 BIG DADDYS RD PIKEVILLE NC 27863 USA "
    },
    {
      "Location ID": "27864",
      "Location Name": "PINETOPS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 SW 1ST ST PINETOPS NC 27864 USA "
    },
    {
      "Location ID": "27865",
      "Location Name": "PINETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 378 MAIN ST PINETOWN NC 27865 USA "
    },
    {
      "Location ID": "27866",
      "Location Name": "PLEASANT HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4366 US HIGHWAY 301 PLEASANT HILL NC 27866 USA "
    },
    {
      "Location ID": "27867",
      "Location Name": "POTECASI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3185 NC HIGHWAY 35 POTECASI NC 27867 USA "
    },
    {
      "Location ID": "27868",
      "Location Name": "RED OAK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8382 RED OAK BLVD RED OAK NC 27868 USA "
    },
    {
      "Location ID": "27869",
      "Location Name": "RICH SQUARE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 W JACKSON ST RICH SQUARE NC 27869 USA "
    },
    {
      "Location ID": "27870",
      "Location Name": "ROANOKE RAPIDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 725 ROANOKE AVE ROANOKE RAPIDS NC 27870 USA "
    },
    {
      "Location ID": "27871",
      "Location Name": "ROBERSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 SW RAILROAD ST ROBERSONVILLE NC 27871 USA "
    },
    {
      "Location ID": "27873",
      "Location Name": "SARATOGA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6744 MAIN ST SARATOGA NC 27873 USA "
    },
    {
      "Location ID": "27874",
      "Location Name": "SCOTLAND NECK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1015 MAIN ST SCOTLAND NECK NC 27874 USA "
    },
    {
      "Location ID": "27875",
      "Location Name": "SCRANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 OLD US HIGHWAY 264 SCRANTON NC 27875 USA "
    },
    {
      "Location ID": "27876",
      "Location Name": "SEABOARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 204 S MAIN ST SEABOARD NC 27876 USA "
    },
    {
      "Location ID": "27877",
      "Location Name": "SEVERN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 410 MAIN ST SEVERN NC 27877 USA "
    },
    {
      "Location ID": "27878",
      "Location Name": "SHARPSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4241 S HATHAWAY BLVD SHARPSBURG NC 27878 USA "
    },
    {
      "Location ID": "27879",
      "Location Name": "SIMPSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2806 EDWARDS DR SIMPSON NC 27879 USA "
    },
    {
      "Location ID": "27880",
      "Location Name": "SIMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6404 MAIN ST SIMS NC 27880 USA "
    },
    {
      "Location ID": "27881",
      "Location Name": "SPEED",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 MAIN ST SPEED NC 27881 USA "
    },
    {
      "Location ID": "27882",
      "Location Name": "SPRING HOPE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 E BRANCH ST SPRING HOPE NC 27882 USA "
    },
    {
      "Location ID": "27883",
      "Location Name": "STANTONSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 S MAIN ST STANTONSBURG NC 27883 USA "
    },
    {
      "Location ID": "27884",
      "Location Name": "STOKES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2817 NC HIGHWAY 903 N STOKES NC 27884 USA "
    },
    {
      "Location ID": "27885",
      "Location Name": "SWANQUARTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1271 MAIN ST SWANQUARTER NC 27885 USA "
    },
    {
      "Location ID": "27886",
      "Location Name": "TARBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 525 N MAIN ST TARBORO NC 27886 USA "
    },
    {
      "Location ID": "27887",
      "Location Name": "TILLERY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 45 NC HIGHWAY 481 TILLERY NC 27887 USA "
    },
    {
      "Location ID": "27888",
      "Location Name": "WALSTONBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 N WILSON ST WALSTONBURG NC 27888 USA "
    },
    {
      "Location ID": "27889",
      "Location Name": "WASHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 222 W 2ND ST WASHINGTON NC 27889 USA "
    },
    {
      "Location ID": "27890",
      "Location Name": "WELDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 WASHINGTON AVE WELDON NC 27890 USA "
    },
    {
      "Location ID": "27891",
      "Location Name": "WHITAKERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 NW RAILROAD ST WHITAKERS NC 27891 USA "
    },
    {
      "Location ID": "27892",
      "Location Name": "WILLIAMSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 E MAIN ST WILLIAMSTON NC 27892 USA "
    },
    {
      "Location ID": "27893",
      "Location Name": "WILSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 501 MERCER ST SW WILSON NC 27893 USA "
    },
    {
      "Location ID": "27895",
      "Location Name": "WILSON PARKWOOD STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1470 TARBORO ST W WILSON NC 27893 USA "
    },
    {
      "Location ID": "27897",
      "Location Name": "WOODLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 W MAIN ST WOODLAND NC 27897 USA "
    },
    {
      "Location ID": "278AA",
      "Location Name": "ROCKY MOUNT ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2451 POSTAL DR ROCKY MOUNT NC 27804 USA "
    },
    {
      "Location ID": "278AL",
      "Location Name": "PFIZER LAB",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4285 N WESLEYAN BLVD ROCKY MOUNT NC 27804 USA "
    },
    {
      "Location ID": "278AN",
      "Location Name": "GREENVILLE ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2728 S MEMORIAL DR GREENVILLE NC 27834 USA "
    },
    {
      "Location ID": "278AX",
      "Location Name": "ROCKY MOUNT CHRISTMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6551 N US HWY 301 BRATTLEBORO NC 27809 USA "
    },
    {
      "Location ID": "278AZ",
      "Location Name": "AMOCO ZIP MART STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1830 WESLEYAN BLVD, ROCKY MOUNT NC 27804 USA "
    },
    {
      "Location ID": "278BT",
      "Location Name": "BTW GLOBAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7483 US HIGHWAY 264 E WASHINGTON NC 27889 USA "
    },
    {
      "Location ID": "278CD",
      "Location Name": "CUMMINS DIESEL (N.O.)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9377 US HWY 301 N WHITAKERS NC 27891 USA "
    },
    {
      "Location ID": "278CE",
      "Location Name": "CUT EM DOWN WATERFOWL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3316 US HIGHWAY 301 N WILSON NC 27893 USA "
    },
    {
      "Location ID": "278CM",
      "Location Name": "CYCLEMAX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6723 WARD BLVD WILSON NC 27893 USA "
    },
    {
      "Location ID": "278FP",
      "Location Name": "FIRESTONE PLANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3001 FIRESTONE PKWY WILSON NC 27893 USA "
    },
    {
      "Location ID": "278GD",
      "Location Name": "GREENVILLE OVERTONS DIST CTR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 RED BANKS RD GREENVILLE NC 27858 USA "
    },
    {
      "Location ID": "278LG",
      "Location Name": "LOGISTECH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1800 QUALITY DR NE WILSON NC 27893 USA "
    },
    {
      "Location ID": "278QV",
      "Location Name": "QVC RETURNS CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 QVC BLVD ROCKY MOUNT NC 27815 USA "
    },
    {
      "Location ID": "278SC",
      "Location Name": "SOULE CEMETERY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20790 US HWY 264 SWANQUARTER NC 27885 USA "
    },
    {
      "Location ID": "278SN",
      "Location Name": "SECOND NATURE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1920 FARRIOR AVE SE WILSON NC 27893 USA "
    },
    {
      "Location ID": "278UM",
      "Location Name": "UMS STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 E 5TH ST GREENVILLE NC 27858 USA "
    },
    {
      "Location ID": "278WC",
      "Location Name": "WESLEYAN COLLEGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3400 N WESLEYAN BLVD ROCKY MOUNT NC 27804 USA "
    },
    {
      "Location ID": "27909",
      "Location Name": "ELIZABETH CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1001 W EHRINGHAUS ST ELIZABETH CITY NC 27909 USA "
    },
    {
      "Location ID": "27910",
      "Location Name": "AHOSKIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 CHURCH ST W AHOSKIE NC 27910 USA "
    },
    {
      "Location ID": "27915",
      "Location Name": "AVON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41196 NC HIGHWAY 12 AVON NC 27915 USA "
    },
    {
      "Location ID": "27916",
      "Location Name": "AYDLETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 523 AYDLETT AYDLETT NC 27916 USA "
    },
    {
      "Location ID": "27917",
      "Location Name": "BARCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3929 CARATOKE HWY BARCO NC 27917 USA "
    },
    {
      "Location ID": "27919",
      "Location Name": "BELVIDERE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1517 BELVIDERE RD BELVIDERE NC 27919 USA "
    },
    {
      "Location ID": "27920",
      "Location Name": "BUXTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 48042 NC 12 HWY BUXTON NC 27920 USA "
    },
    {
      "Location ID": "27921",
      "Location Name": "CAMDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 US HIGHWAY 158 E CAMDEN NC 27921 USA "
    },
    {
      "Location ID": "27922",
      "Location Name": "COFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1240 AHOSKIE COFIELD RD COFIELD NC 27922 USA "
    },
    {
      "Location ID": "27923",
      "Location Name": "COINJOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 183 WORTH GUARD RD COINJOCK NC 27923 USA "
    },
    {
      "Location ID": "27924",
      "Location Name": "COLERAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 W RIVER ST COLERAIN NC 27924 USA "
    },
    {
      "Location ID": "27925",
      "Location Name": "COLUMBIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 S BROAD ST COLUMBIA NC 27925 USA "
    },
    {
      "Location ID": "27926",
      "Location Name": "CORAPEAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1398 NC HIGHWAY 32 N CORAPEAKE NC 27926 USA "
    },
    {
      "Location ID": "27928",
      "Location Name": "CRESWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 W MAIN ST CRESWELL NC 27928 USA "
    },
    {
      "Location ID": "27932",
      "Location Name": "EDENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 N BROAD ST EDENTON NC 27932 USA "
    },
    {
      "Location ID": "27935",
      "Location Name": "EURE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1203 NC 137 EURE NC 27935 USA "
    },
    {
      "Location ID": "27936",
      "Location Name": "FRISCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 53590 NC HIGHWAY 12 FRSCO NC 27936 USA "
    },
    {
      "Location ID": "27937",
      "Location Name": "GATES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 958 NC HIGHWAY 37 N GATES NC 27937 USA "
    },
    {
      "Location ID": "27938",
      "Location Name": "GATESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 COURT ST GATESVILLE NC 27938 USA "
    },
    {
      "Location ID": "27939",
      "Location Name": "GRANDY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 FORBES LOOP UNIT C GRANDY NC 27939 USA "
    },
    {
      "Location ID": "27941",
      "Location Name": "HARBINGER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8732 CARATOKE HWY HARBINGER NC 27941 USA "
    },
    {
      "Location ID": "27942",
      "Location Name": "HARRELLSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 E MAIN ST HARRELLSVILLE NC 27942 USA "
    },
    {
      "Location ID": "27943",
      "Location Name": "HATTERAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 57689 NC HIGHWAY 12 HATTERAS NC 27943 USA "
    },
    {
      "Location ID": "27944",
      "Location Name": "HERTFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 W GRUBB ST HERTFORD NC 27944 USA "
    },
    {
      "Location ID": "27946",
      "Location Name": "HOBBSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 385 HOBBSVILLE RD HOBBSVILLE NC 27946 USA "
    },
    {
      "Location ID": "27947",
      "Location Name": "JARVISBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7066 CARATOKE HWY JARVISBURG NC 27947 USA "
    },
    {
      "Location ID": "27948",
      "Location Name": "KILL DEVIL HILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 302 S CROATAN HWY KILL DEVIL HILLS NC 27948 USA "
    },
    {
      "Location ID": "27949",
      "Location Name": "KITTY HAWK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3841 N CROATAN HWY KITTY HAWK NC 27949 USA "
    },
    {
      "Location ID": "27953",
      "Location Name": "MANNS HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6941 US HIGHWAY 64 MANNS HARBOR NC 27953 USA "
    },
    {
      "Location ID": "27954",
      "Location Name": "MANTEO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 212B US HIGHWAY 64 AND 264 MANTEO NC 27954 USA "
    },
    {
      "Location ID": "27956",
      "Location Name": "MAPLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3452 CARATOKE HWY MAPLE NC 27956 USA "
    },
    {
      "Location ID": "27957",
      "Location Name": "MERRY HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 335 OLD MERRY HILL RD MERRY HILL NC 27957 USA "
    },
    {
      "Location ID": "27958",
      "Location Name": "MOYOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 574 CARATOKE HWY MOYOCK NC 27958 USA "
    },
    {
      "Location ID": "27959",
      "Location Name": "NAGS HEAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 DEERING ST NAGS HEAD NC 27959 USA "
    },
    {
      "Location ID": "27960",
      "Location Name": "OCRACOKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1122 IRVIN GARRISH HWY OCRACOKE NC 27960 USA "
    },
    {
      "Location ID": "27962",
      "Location Name": "PLYMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 W MAIN ST PLYMOUTH NC 27962 USA "
    },
    {
      "Location ID": "27964",
      "Location Name": "POINT HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9142 CARATOKE HWY POINT HARBOR NC 27964 USA "
    },
    {
      "Location ID": "27965",
      "Location Name": "POPLAR BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 227 POPLAR BRANCH RD POPULAR BRANCH NC 27965 USA "
    },
    {
      "Location ID": "27966",
      "Location Name": "POWELLS POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7664 CARATOKE HWY POWELLS POINT NC 27966 USA "
    },
    {
      "Location ID": "27967",
      "Location Name": "POWELLSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 BETHLEHEM CHURCH RD POWELLSVILLE NC 27967 USA "
    },
    {
      "Location ID": "27968",
      "Location Name": "RODANTHE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25969 NC HIGHWAY 12 RODANTHE NC 27968 USA "
    },
    {
      "Location ID": "27969",
      "Location Name": "RODUCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1453 US HWY 158 W RODUCO NC 27968 USA "
    },
    {
      "Location ID": "27970",
      "Location Name": "ROPER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 W HIGHWAY 64 BYP ROPER NC 27970 USA "
    },
    {
      "Location ID": "27973",
      "Location Name": "SHAWBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 814 SHAWBORO RD SHAWBORO NC 27973 USA "
    },
    {
      "Location ID": "27976",
      "Location Name": "SOUTH MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 MAIN ST SOUTH MILLS NC 27976 USA "
    },
    {
      "Location ID": "27979",
      "Location Name": "SUNBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 990 US HIGHWAY 158 E SUNBURY NC 27979 USA "
    },
    {
      "Location ID": "27980",
      "Location Name": "TYNER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 630 CENTER HILL RD TYNER NC 27980 USA "
    },
    {
      "Location ID": "27981",
      "Location Name": "WANCHESE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3525 MILL LANDING RD WANCHESE NC 27981 USA "
    },
    {
      "Location ID": "27983",
      "Location Name": "WINDSOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 N KING ST WINDSOR NC 27983 USA "
    },
    {
      "Location ID": "27985",
      "Location Name": "WINFALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 317 MAIN ST WINFALL NC 27985 USA "
    },
    {
      "Location ID": "27986",
      "Location Name": "WINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 406 N MAIN ST WINTON NC 27986 USA "
    },
    {
      "Location ID": "279KH",
      "Location Name": "KITTY HAWK KITES INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 306 W LAKE DR UNIT O KILL DEVIL HILLS NC 27948 USA "
    },
    {
      "Location ID": "27GTE",
      "Location Name": "G T E",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3632 ROXBORO ROAD DURHAM NC 27704 USA "
    },
    {
      "Location ID": "27UPS",
      "Location Name": "UPS MAIL INNOVATIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 BUSINESS PARK DR BUTNER NC 27509 USA "
    },
    {
      "Location ID": "27Z",
      "Location Name": "GREENSBORO NDC, NC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3701 W WENDOVER AVE GREENSBORO NC 27495 USA "
    },
    {
      "Location ID": "280",
      "Location Name": "LOG CHARLOTTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2901 SCOTT FUTRELL DR CHARLOTTE NC 28228 USA "
    },
    {
      "Location ID": "28001",
      "Location Name": "ALBEMARLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 320 S 2ND ST ALBEMARLE NC 28001 USA "
    },
    {
      "Location ID": "28006",
      "Location Name": "ALEXIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4226 CHARLES RAPER JONAS HWY ALEXIS NC 28006 USA "
    },
    {
      "Location ID": "28007",
      "Location Name": "ANSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8795 HIGHWAY 52 N ANSONVILLE NC 28007 USA "
    },
    {
      "Location ID": "28009",
      "Location Name": "BADIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 BOYDEN ST BADIN NC 28009 USA "
    },
    {
      "Location ID": "28010",
      "Location Name": "BARIUM SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2625 SHELTON AVE BARIUM SPRINGS NC 28010 USA "
    },
    {
      "Location ID": "28012",
      "Location Name": "BELMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 208 GLENWAY ST BELMONT NC 28012 USA "
    },
    {
      "Location ID": "28016",
      "Location Name": "BESSEMER CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 124 E ALABAMA AVE BESSEMER CITY NC 28016 USA "
    },
    {
      "Location ID": "28017",
      "Location Name": "BOILING SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 N MAIN ST BOILING SPRINGS NC 28017 USA "
    },
    {
      "Location ID": "28018",
      "Location Name": "BOSTIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1521 BOSTIC SUNSHINE HWY BOSTIC NC 28018 USA "
    },
    {
      "Location ID": "28019",
      "Location Name": "CAROLEEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2201 HIGHWAY 221A CAROLEEN NC 28019 USA "
    },
    {
      "Location ID": "28020",
      "Location Name": "CASAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5801 CASAR RD CASAR NC 28020 USA "
    },
    {
      "Location ID": "28021",
      "Location Name": "CHERRYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 N MULBERRY ST CHERRYVILLE NC 28021 USA "
    },
    {
      "Location ID": "28023",
      "Location Name": "CHINA GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 S BOSTIAN ST CHINA GROVE NC 28023 USA "
    },
    {
      "Location ID": "28024",
      "Location Name": "CLIFFSIDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 244 N MAIN ST CLIFFSIDE NC 28024 USA "
    },
    {
      "Location ID": "28025",
      "Location Name": "CONCORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 66 MCCACHERN BLVD SE CONCORD NC 28025 USA "
    },
    {
      "Location ID": "28027",
      "Location Name": "CONCORD PKWY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 455 CONCORD PKWY N CONCORD NC 28027 USA "
    },
    {
      "Location ID": "28031",
      "Location Name": "CORNELIUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20311 CHARTWELL CENTER DR CORNELIUS NC 28031 USA "
    },
    {
      "Location ID": "28034",
      "Location Name": "DALLAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3151 DALLAS HGH SHLS HWY DALLAS NC 28034 USA "
    },
    {
      "Location ID": "28036",
      "Location Name": "DAVIDSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 99 JACKSON ST DAVIDSON NC 28036 USA "
    },
    {
      "Location ID": "28037",
      "Location Name": "DENVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3593 DENVER DR DENVER NC 28037 USA "
    },
    {
      "Location ID": "28038",
      "Location Name": "EARL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2719 BLACKSBURG RD EARL NC 28038 USA "
    },
    {
      "Location ID": "28039",
      "Location Name": "EAST SPENCER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 S LONG ST EAST SPENCER NC 28039 USA "
    },
    {
      "Location ID": "28040",
      "Location Name": "ELLENBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 131 DEPOT ST ELLENBORO NC 28040 USA "
    },
    {
      "Location ID": "28041",
      "Location Name": "FAITH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1027 GANTT ST FAITH NC 28041 USA "
    },
    {
      "Location ID": "28042",
      "Location Name": "FALLSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 771 E STAGECOACH TRL FALLSTON NC 28042 USA "
    },
    {
      "Location ID": "28043",
      "Location Name": "FOREST CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 133 S POWELL ST FOREST CITY NC 28043 USA "
    },
    {
      "Location ID": "28054",
      "Location Name": "NEW HOPE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1882 S NEW HOPE RD GASTONIA NC 28054 USA "
    },
    {
      "Location ID": "28071",
      "Location Name": "GOLD HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14125 HIGHWAY 52 GOLD HILL NC 28071 USA "
    },
    {
      "Location ID": "28072",
      "Location Name": "GRANITE QUARRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 222 E BANK ST GRANITE QUARRY NC 28072 USA "
    },
    {
      "Location ID": "28073",
      "Location Name": "GROVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 MULBERRY RD GROVER NC 28073 USA "
    },
    {
      "Location ID": "28074",
      "Location Name": "HARRIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 173 HOGAN RD HARRIS NC 28074 USA "
    },
    {
      "Location ID": "28075",
      "Location Name": "HARRISBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4258 NC HIGHWAY 49 S HARRISBURG NC 28075 USA "
    },
    {
      "Location ID": "28076",
      "Location Name": "HENRIETTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2946 HARRIS HENRIETTA RD HENRIETTA NC 28076 USA "
    },
    {
      "Location ID": "28077",
      "Location Name": "HIGH SHOALS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 511 N LINCOLN ST HIGH SHOALS NC 28077 USA "
    },
    {
      "Location ID": "28078",
      "Location Name": "HUNTERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 GILEAD RD HUNTERSVILLE NC 28078 USA "
    },
    {
      "Location ID": "28079",
      "Location Name": "INDIAN TRAIL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 POSTAGE WAY INDIAN TRAIL NC 28079 USA "
    },
    {
      "Location ID": "28080",
      "Location Name": "IRON STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4832 E HIGHWAY 27 IRON STATION NC 28080 USA "
    },
    {
      "Location ID": "28086",
      "Location Name": "KINGS MOUNTAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 E GOLD ST KINGS MOUNTAIN NC 28086 USA "
    },
    {
      "Location ID": "28088",
      "Location Name": "LANDIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 230 E RYDER AVE LANDIS NC 28088 USA "
    },
    {
      "Location ID": "28089",
      "Location Name": "LATTIMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 306 E MAIN ST LATTIMORE NC 28089 USA "
    },
    {
      "Location ID": "28090",
      "Location Name": "LAWNDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 241 E MAIN ST LAWNDALE NC 28090 USA "
    },
    {
      "Location ID": "28091",
      "Location Name": "LILESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 E WALL ST LILESVILLE NC 28091 USA "
    },
    {
      "Location ID": "28092",
      "Location Name": "LINCOLNTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 326 E MAIN ST LINCOLNTON NC 28092 USA "
    },
    {
      "Location ID": "28097",
      "Location Name": "LOCUST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 MAIN ST W LOCUST NC 28097 USA "
    },
    {
      "Location ID": "28098",
      "Location Name": "LOWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 E 1ST ST LOWELL NC 28098 USA "
    },
    {
      "Location ID": "280BD",
      "Location Name": "THE BIG DEALS OF FLORIDA LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 536 N GENERALS BLVD LINCOLNTON NC 28092 USA "
    },
    {
      "Location ID": "280CA",
      "Location Name": "CHARLOTTE CHRISTMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1900 CONTINENTAL BLVD CHARLOTTE NC 28273 USA "
    },
    {
      "Location ID": "280CL",
      "Location Name": "CLT5",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1745 DERITA RD CONCORD NC 28027 USA "
    },
    {
      "Location ID": "280CP",
      "Location Name": "CHILDREN'S PLACE - CONCORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8111 CONCORD MILLS BLVD STE 342 CONCORD NC 28027 USA "
    },
    {
      "Location ID": "280EB",
      "Location Name": "EBUILDERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4106 BURNWOOD TRL DENVER NC 28037 USA "
    },
    {
      "Location ID": "280ED",
      "Location Name": "EBUILDER DIRECT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4368 BURNWOOD TRL DENVER NC 28037 USA "
    },
    {
      "Location ID": "280JT",
      "Location Name": "JEWELTEX FABRICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 266 MEADOWBROOK DR FOREST CITY NC 28043 USA "
    },
    {
      "Location ID": "280PY",
      "Location Name": "PREMIER YARNS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5991 CALDWELL PARK DR HARRISBURG NC 28075 USA "
    },
    {
      "Location ID": "280RK",
      "Location Name": "RK T-SHIRTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18637 NORTHLINE DR CORNELIUS NC 28031 USA "
    },
    {
      "Location ID": "280RP",
      "Location Name": "LOG CHARLOTTE NC RPDC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 524 SCALYBARK RD GASTONIA NC 28054 USA "
    },
    {
      "Location ID": "280SF",
      "Location Name": "SUPPLEMENT FIRST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10620 BAILEY RD STE K CORNELIUS NC 28031 USA "
    },
    {
      "Location ID": "280SW",
      "Location Name": "SWEETWATER LOGISTICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3512 FAITH CHURCH RD INDIAN TRAIL NC 28079 USA "
    },
    {
      "Location ID": "280TH",
      "Location Name": "MATHESON - CLT THS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3628 YORKMONT RD, STE A CHARLOTTE NC 28208 USA "
    },
    {
      "Location ID": "281",
      "Location Name": "LOG MID CAROLINA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1820 W POINTE DR STE A CHARLOTTE NC 28214 USA "
    },
    {
      "Location ID": "28102",
      "Location Name": "MC FARLAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12005 US HIGHWAY 52 S MC FARLAN NC 28102 USA "
    },
    {
      "Location ID": "28105",
      "Location Name": "MATTHEWS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 E JOHN ST MATTHEWS NC 28105 USA "
    },
    {
      "Location ID": "28107",
      "Location Name": "MIDLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13379 HIGHWAY 601 MIDLAND NC 28107 USA "
    },
    {
      "Location ID": "28109",
      "Location Name": "MISENHEIMER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 48456 US HWY 52 N MISENHEIMER NC 28109 USA "
    },
    {
      "Location ID": "28110",
      "Location Name": "MONROE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 S CHARLOTTE AVE MONROE NC 28112 USA "
    },
    {
      "Location ID": "28114",
      "Location Name": "MOORESBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 W CHURCH ST MOORESBORO NC 28114 USA "
    },
    {
      "Location ID": "28115",
      "Location Name": "MOORESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 INSTITUTE ST MOORESVILLE NC 28115 USA "
    },
    {
      "Location ID": "28117",
      "Location Name": "NORTH LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 172 WILLIAMSON RD MOORESVILLE NC 28117 USA "
    },
    {
      "Location ID": "28119",
      "Location Name": "MORVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 E MAIN ST MORVEN NC 28119 USA "
    },
    {
      "Location ID": "28120",
      "Location Name": "MOUNT HOLLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 806 W CENTRAL AVE MOUNT HOLLY NC 28120 USA "
    },
    {
      "Location ID": "28124",
      "Location Name": "MOUNT PLEASANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1498 S MAIN ST MOUNT PLEASANT NC 28124 USA "
    },
    {
      "Location ID": "28125",
      "Location Name": "MOUNT ULLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 775 GRAMPIAN RD MOUNT ULLA NC 28125 USA "
    },
    {
      "Location ID": "28126",
      "Location Name": "NEWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8105 OLD CONCORD RD NEWELL NC 28126 USA "
    },
    {
      "Location ID": "28127",
      "Location Name": "NEW LONDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 212 S MAIN ST NEW LONDON NC 28127 USA "
    },
    {
      "Location ID": "28128",
      "Location Name": "NORWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 243 S MAIN ST NORWOOD NC 28128 USA "
    },
    {
      "Location ID": "28129",
      "Location Name": "OAKBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 302 N MAIN ST OAKBORO NC 28129 USA "
    },
    {
      "Location ID": "28130",
      "Location Name": "PAW CREEK           NC",
      "Time Zone Offset (Hours)": 0,
      "Address": " N SIDE MT HOLLY RD PAW CREEK NC 28130 USA "
    },
    {
      "Location ID": "28133",
      "Location Name": "PEACHLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 W PASSAIC ST PEACHLAND NC 28133 USA "
    },
    {
      "Location ID": "28134",
      "Location Name": "PINEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 COLLEGE ST PINEVILLE NC 28134 USA "
    },
    {
      "Location ID": "28135",
      "Location Name": "POLKTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 45 W POLK ST POLKTON NC 28135 USA "
    },
    {
      "Location ID": "28136",
      "Location Name": "POLKVILLE           NC",
      "Time Zone Offset (Hours)": 0,
      "Address": " STATE HWY 226 POLKVILLE NC 28136 USA "
    },
    {
      "Location ID": "28137",
      "Location Name": "RICHFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 212 NC HWY 49 N RICHFIELD NC 28137 USA "
    },
    {
      "Location ID": "28138",
      "Location Name": "ROCKWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 BROAD ST ROCKWELL NC 28138 USA "
    },
    {
      "Location ID": "28139",
      "Location Name": "RUTHERFORDTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 E COURT ST RUTHERFORDTON NC 28139 USA "
    },
    {
      "Location ID": "28144",
      "Location Name": "SALISBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 605 E INNES ST SALISBURY NC 28144 USA "
    },
    {
      "Location ID": "28150",
      "Location Name": "SHELBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 405 S DEKALB ST SHELBY NC 28150 USA "
    },
    {
      "Location ID": "28159",
      "Location Name": "SPENCER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 4TH ST SPENCER NC 28159 USA "
    },
    {
      "Location ID": "28160",
      "Location Name": "SPINDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 124 TANNER ST SPINDALE NC 28160 USA "
    },
    {
      "Location ID": "28163",
      "Location Name": "STANFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 213 W STANLY ST STANFIELD NC 28163 USA "
    },
    {
      "Location ID": "28164",
      "Location Name": "STANLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 OLD MOUNT HOLLY RD STANLEY NC 28164 USA "
    },
    {
      "Location ID": "28166",
      "Location Name": "TROUTMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 189 WAGNER ST TROUTMAN NC 28166 USA "
    },
    {
      "Location ID": "28167",
      "Location Name": "UNION MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3733 US 221 HWY N UNION MILLS NC 28167 USA "
    },
    {
      "Location ID": "28168",
      "Location Name": "VALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6070 REEPSVILLE RD VALE NC 28168 USA "
    },
    {
      "Location ID": "28169",
      "Location Name": "WACO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 N MAIN ST WACO NC 28169 USA "
    },
    {
      "Location ID": "28170",
      "Location Name": "WADESBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 E MARTIN ST WADESBORO NC 28170 USA "
    },
    {
      "Location ID": "28173",
      "Location Name": "WAXHAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 WAXHAW PKWY WAXHAW NC 28173 USA "
    },
    {
      "Location ID": "28174",
      "Location Name": "WINGATE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 212 N MAIN ST WINGATE NC 28174 USA "
    },
    {
      "Location ID": "281AN",
      "Location Name": "MONROE ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1639 DICKERSON BLVD MONROE NC 28110 USA "
    },
    {
      "Location ID": "281BP",
      "Location Name": "BELK'S - PINEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11009 CAROLINA PLACE PKWY PINEVILLE NC 28134 USA "
    },
    {
      "Location ID": "281FZ",
      "Location Name": "LOG MID CAROLINA NC PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1900 CONTINENTAL BLVD STE I CHARLOTTE NC 28273 USA "
    },
    {
      "Location ID": "281IS",
      "Location Name": "INTERSTATE SUPPLIES AND SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 511 UNION WEST BLVD STE A STALLINGS NC 28104 USA "
    },
    {
      "Location ID": "281KW",
      "Location Name": "KOHL'S - WAXHAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 601 SHERMAN PL WAXHAW NC 28173 USA "
    },
    {
      "Location ID": "281MT",
      "Location Name": "MTJ MOTORSPORTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 156 CAYUGA DR MOORESVILLE NC 28117 USA "
    },
    {
      "Location ID": "281WM",
      "Location Name": "WALMART - TROUTMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 386 MURDOCK RD TROUTMAN NC 28166 USA "
    },
    {
      "Location ID": "28205",
      "Location Name": "INDEPENDENCE ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3717 EASTWAY DR CHARLOTTE NC 28205 USA "
    },
    {
      "Location ID": "28228",
      "Location Name": "CHARLOTTE P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2901 SCOTT FUTRELL DR CHARLOTTE NC 28228 USA "
    },
    {
      "Location ID": "282AA",
      "Location Name": "AUTO PLUS AUTO PARTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 700 W 28TH ST CHARLOTTE NC 28206 USA "
    },
    {
      "Location ID": "282AM",
      "Location Name": "AMAZON CLT9",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3620 REEVES RIDGE DR CHARLOTTE NC 28214 USA "
    },
    {
      "Location ID": "282BC",
      "Location Name": "BELK'S - CHARLOTTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4400 SHARON RD STE 505 CHARLOTTE NC 28211 USA "
    },
    {
      "Location ID": "282FU",
      "Location Name": "WACHOVIA/WELLS FARGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12301 VANCE DAVIS DR CHARLOTTE NC 28269 USA "
    },
    {
      "Location ID": "282FX",
      "Location Name": "282FX FEDEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8910 PIONEER AVE CHARLOTTE NC 28273 USA "
    },
    {
      "Location ID": "282JS",
      "Location Name": "JETSTREAM GROUND SERVICES, INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4402 YORKMONT RD CHARLOTTE NC 28208 USA "
    },
    {
      "Location ID": "282PB",
      "Location Name": "PITNEY BOWES - CHARLOTTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9015 AIR PARK WEST DR STE A CHARLOTTE NC 28214 USA "
    },
    {
      "Location ID": "282RG",
      "Location Name": "REGULAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6945 NORTH PARK BLVD STE L CHARLOTTE NC 28216 USA "
    },
    {
      "Location ID": "282UP",
      "Location Name": "UNITED PARCEL SERVICE (CSI)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4404 YORKMONT RD CHARLOTTE NC 28208 USA "
    },
    {
      "Location ID": "283",
      "Location Name": "FAYETTEVILLE P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 GREEN ST STE 200 FAYETTEVILLE NC 28301 USA "
    },
    {
      "Location ID": "28301",
      "Location Name": "TOKAY ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2812 RAMSEY ST FAYETTEVILLE NC 28301-3110 USA "
    },
    {
      "Location ID": "28303",
      "Location Name": "EUTAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 816 ELM ST FAYETTEVILLE NC 28303-0060 USA "
    },
    {
      "Location ID": "28304",
      "Location Name": "LAFAYETTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 907 BRIGHTON RD FAYETTEVILLE NC 28314-2511 USA "
    },
    {
      "Location ID": "28305",
      "Location Name": "HAYMOUNT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 OAKRIDGE AVE FAYETTEVILLE NC 28305-4835 USA "
    },
    {
      "Location ID": "28306",
      "Location Name": "LAKEDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2503 SOUTHERN AVE FAYETTEVILLE NC 28306-2948 USA "
    },
    {
      "Location ID": "28307",
      "Location Name": "FORT BRAGG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1605 REILLY RD FORT BRAGG NC 28307 USA "
    },
    {
      "Location ID": "28309",
      "Location Name": "HIGHLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2800 RAEFORD RD STE 2 FAYETTEVILLE NC 28303-9716 USA "
    },
    {
      "Location ID": "28312",
      "Location Name": "MJ SOFFEE WEBSTORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1030 FORT WORTH FAYETTEVILLE NC 28312-5217 USA "
    },
    {
      "Location ID": "28314",
      "Location Name": "CLIFFDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6380 CLIFFDALE RD FAYETTEVILLE NC 28314-2967 USA "
    },
    {
      "Location ID": "28315",
      "Location Name": "ABERDEEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 S POPLAR ST ABERDEEN NC 28315-2763 USA "
    },
    {
      "Location ID": "28318",
      "Location Name": "AUTRYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 W WILLIAMS ST AUTRYVILLE NC 28318-8980 USA "
    },
    {
      "Location ID": "28320",
      "Location Name": "BLADENBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 223 MARTIN LUTHER KING JR DR BLADENBORO NC 28320-8682 USA "
    },
    {
      "Location ID": "28323",
      "Location Name": "BUNNLEVEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6904 US HIGHWAY 401 S BUNNLEVEL NC 28323-8470 USA "
    },
    {
      "Location ID": "28325",
      "Location Name": "CALYPSO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 W TRADE ST CALYPSO NC 28325-8900 USA "
    },
    {
      "Location ID": "28326",
      "Location Name": "CAMERON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 450 CARTHAGE ST CAMERON NC 28326-8904 USA "
    },
    {
      "Location ID": "28327",
      "Location Name": "CARTHAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 S RAY ST CARTHAGE NC 28327-8794 USA "
    },
    {
      "Location ID": "28328",
      "Location Name": "CLINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1011 SUNSET AVE CLINTON NC 28328-3817 USA "
    },
    {
      "Location ID": "28330",
      "Location Name": "CORDOVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 232 LEDBETTER ST CORDOVA NC 28330-8900 USA "
    },
    {
      "Location ID": "28331",
      "Location Name": "CUMBERLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2304 WINGATE RD CUMBERLAND NC 28331-8916 USA "
    },
    {
      "Location ID": "28332",
      "Location Name": "DUBLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 86 3RD ST DUBLIN NC 28332-8903 USA "
    },
    {
      "Location ID": "28333",
      "Location Name": "DUDLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4219 US 117 ALT DUDLEY NC 28333-7243 USA "
    },
    {
      "Location ID": "28334",
      "Location Name": "DUNN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1115 S CLINTON AVE DUNN NC 28334-6314 USA "
    },
    {
      "Location ID": "28337",
      "Location Name": "ELIZABETHTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 S POPLAR ST ELIZABETHTOWN NC 28337-9313 USA "
    },
    {
      "Location ID": "28338",
      "Location Name": "ELLERBE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 268 2ND ST ELLERBE NC 28338-9000 USA "
    },
    {
      "Location ID": "28339",
      "Location Name": "ERWIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 904 S 13TH ST ERWIN NC 28339-8700 USA "
    },
    {
      "Location ID": "28340",
      "Location Name": "FAIRMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 N WALNUT ST FAIRMONT NC 28340-1952 USA "
    },
    {
      "Location ID": "28341",
      "Location Name": "FAISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 E MAIN ST FAISON NC 28341-8900 USA "
    },
    {
      "Location ID": "28342",
      "Location Name": "FALCON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7175 WEST ST FALCON NC 28342-8903 USA "
    },
    {
      "Location ID": "28343",
      "Location Name": "GIBSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4580 MAIN ST GIBSON NC 28343-8853 USA "
    },
    {
      "Location ID": "28344",
      "Location Name": "GODWIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8817 MAIN ST GODWIN NC 28344-9095 USA "
    },
    {
      "Location ID": "28345",
      "Location Name": "HAMLET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 W MAIN ST HAMLET NC 28345-3215 USA "
    },
    {
      "Location ID": "28347",
      "Location Name": "HOFFMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2717 N US 1 HWY MARSTON NC 28363-8619 USA "
    },
    {
      "Location ID": "28348",
      "Location Name": "HOPE MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3215 N MAIN ST HOPE MILLS NC 28348-1830 USA "
    },
    {
      "Location ID": "28349",
      "Location Name": "KENANSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 N MAIN ST KENANSVILLE NC 28349-8966 USA "
    },
    {
      "Location ID": "28350",
      "Location Name": "LAKEVIEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 278 CAMP EASTER RD LAKEVIEW NC 28350-8900 USA "
    },
    {
      "Location ID": "28351",
      "Location Name": "LAUREL HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9140 MORGAN ST LAUREL HILL NC 28351-9304 USA "
    },
    {
      "Location ID": "28352",
      "Location Name": "LAURINBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 S GILL ST LAURINBURG NC 28352-3613 USA "
    },
    {
      "Location ID": "28355",
      "Location Name": "LEMON SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7246 LEMON SPRINGS RD LEMON SPRINGS NC 28355-8902 USA "
    },
    {
      "Location ID": "28356",
      "Location Name": "LINDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4764 MAIN ST LINDEN NC 28356-9701 USA "
    },
    {
      "Location ID": "28357",
      "Location Name": "LUMBER BRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 S FAYETTEVILLE ST LUMBER BRIDGE NC 28357-8778 USA "
    },
    {
      "Location ID": "28358",
      "Location Name": "LUMBERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 E 7TH ST LUMBERTON NC 28358-4837 USA "
    },
    {
      "Location ID": "28362",
      "Location Name": "MARIETTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2733 MARIETTA RD MARIETTA NC 28362-8901 USA "
    },
    {
      "Location ID": "28363",
      "Location Name": "MARSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2717 N US 1 HWY MARSTON NC 28363 USA "
    },
    {
      "Location ID": "28364",
      "Location Name": "MAXTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 MCCASKILL AVE MAXTON NC 28364-1827 USA "
    },
    {
      "Location ID": "28365",
      "Location Name": "MOUNT OLIVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 611 N BREAZEALE AVE MOUNT OLIVE NC 28365-1203 USA "
    },
    {
      "Location ID": "28366",
      "Location Name": "NEWTON GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 208 FAYETTEVILLE ST NEWTON GROVE NC 28366-8992 USA "
    },
    {
      "Location ID": "28367",
      "Location Name": "NORMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3664 NC HIGHWAY 220 N NORMAN NC 28367-8900 USA "
    },
    {
      "Location ID": "28368",
      "Location Name": "OLIVIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 480 SEAWELL ROSSER RD OLIVIA NC 28368-8900 USA "
    },
    {
      "Location ID": "28369",
      "Location Name": "ORRUM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1545 S CREEK RD ORRUM NC 28369-9275 USA "
    },
    {
      "Location ID": "28371",
      "Location Name": "PARKTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 S FAYETTEVILLE ST PARKTON NC 28371-9006 USA "
    },
    {
      "Location ID": "28372",
      "Location Name": "PEMBROKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 404 E 3RD ST PEMBROKE NC 28372-8888 USA "
    },
    {
      "Location ID": "28373",
      "Location Name": "PINEBLUFF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 N WALNUT ST PINEBLUFF NC 28373-8901 USA "
    },
    {
      "Location ID": "28374",
      "Location Name": "PINEHURST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 BLAKE BLVD PINEHURST NC 28374-8449 USA "
    },
    {
      "Location ID": "28375",
      "Location Name": "PROCTORVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 W MAIN ST PROCTORVILLE NC 28375-8901 USA "
    },
    {
      "Location ID": "28376",
      "Location Name": "RAEFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 122 W ELWOOD AVE STE 102 RAEFORD NC 28376-2800 USA "
    },
    {
      "Location ID": "28377",
      "Location Name": "RED SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 230 S MAIN ST RED SPRINGS NC 28377-1622 USA "
    },
    {
      "Location ID": "28378",
      "Location Name": "REX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1045 REX RD REX NC 28378-8901 USA "
    },
    {
      "Location ID": "28379",
      "Location Name": "ROCKINGHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 W WASHINGTON ST ROCKINGHAM NC 28379-3583 USA "
    },
    {
      "Location ID": "28382",
      "Location Name": "ROSEBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 W PLEASANT ST ROSEBORO NC 28382-8910 USA "
    },
    {
      "Location ID": "28383",
      "Location Name": "ROWLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 N BOND ST ROWLAND NC 28383-9770 USA "
    },
    {
      "Location ID": "28384",
      "Location Name": "SAINT PAULS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 320 W BROAD ST SAINT PAULS NC 28384-1536 USA "
    },
    {
      "Location ID": "28385",
      "Location Name": "SALEMBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 CLINTON ST SALEMBURG NC 28385 USA "
    },
    {
      "Location ID": "28386",
      "Location Name": "SHANNON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14654 NC HIGHWAY 71 N SHANNON NC 28386-8905 USA "
    },
    {
      "Location ID": "28387",
      "Location Name": "SOUTHERN PINES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 190 SW BROAD ST SOUTHERN PINES NC 28387-5452 USA "
    },
    {
      "Location ID": "28390",
      "Location Name": "SPRING LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 225 N MAIN ST SPRING LAKE NC 28390-3847 USA "
    },
    {
      "Location ID": "28391",
      "Location Name": "STEDMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7519 CLINTON RD STEDMAN NC 28391-9065 USA "
    },
    {
      "Location ID": "28392",
      "Location Name": "TAR HEEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14548 NC HIGHWAY 87 W TAR HEEL NC 28392-8900 USA "
    },
    {
      "Location ID": "28393",
      "Location Name": "TURKEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 54 UNION RD TURKEY NC 28393-8467 USA "
    },
    {
      "Location ID": "28394",
      "Location Name": "VASS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 SEABOARD ST VASS NC 28394-8919 USA "
    },
    {
      "Location ID": "28395",
      "Location Name": "WADE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6927 WADE STEDMAN RD WADE NC 28395-9019 USA "
    },
    {
      "Location ID": "28396",
      "Location Name": "WAGRAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24100 N MAIN ST WAGRAM NC 28396-8939 USA "
    },
    {
      "Location ID": "28398",
      "Location Name": "WARSAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 W HILL ST WARSAW NC 28398-1816 USA "
    },
    {
      "Location ID": "28399",
      "Location Name": "WHITE OAK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10384 NC HIGHWAY 53 W WHITE OAK NC 28399-9710 USA "
    },
    {
      "Location ID": "283AC",
      "Location Name": "ANCIENT COSMETICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5129 RAEFORD RD FAYETTEVILLE NC 28304 USA "
    },
    {
      "Location ID": "283AN",
      "Location Name": "DOWNING RD ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2507 DOWNING RD FAYETTEVILLE NC 28312 USA "
    },
    {
      "Location ID": "283BF",
      "Location Name": "BELK - CROSS CREEK MALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 CROSS CREEK MALL FAYETTEVILLE NC 28303 USA "
    },
    {
      "Location ID": "283BS",
      "Location Name": "BELK - SOUTHERN PINES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10205 US 15 501 HWY UNIT 1 SOUTHERN PINES NC 28387 USA "
    },
    {
      "Location ID": "283BW",
      "Location Name": "NC BASKET WORKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 MAIN ST VASS NC 28394 USA "
    },
    {
      "Location ID": "283CX",
      "Location Name": "Fayetteville Christmas Annex",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3036A GILLESPE STREET FAYETTEVILLE NC 28306-7103 USA "
    },
    {
      "Location ID": "283DW",
      "Location Name": "DISCOUNT WHOLESALE INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1519 CARTHAGE RD STE 4A LUMBERTON NC 28358 USA "
    },
    {
      "Location ID": "283FZ",
      "Location Name": "LOG FAYETTEVILLE NC PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5300 CORPORATION DR HOPE MILLS NC 28348 USA "
    },
    {
      "Location ID": "283IJ",
      "Location Name": "INKJET2U",
      "Time Zone Offset (Hours)": 0,
      "Address": " 410 E RUSSELL ST FAYETTEVILLE NC 28301-5773 USA "
    },
    {
      "Location ID": "283MC",
      "Location Name": "VAMC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7300 S RAEFFORD RD FAYETTEVILLE NC 28376 USA "
    },
    {
      "Location ID": "283PS",
      "Location Name": "PROCTOR SILEX PLANT (28351)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 261 YADKIN RD SOUTHERN PINES NC 28387-3415 USA "
    },
    {
      "Location ID": "283QP",
      "Location Name": "QWIK PACK & SHIP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7711 S RAEFORD RD STE 102 FAYETTEVILLE NC 28304-5983 USA "
    },
    {
      "Location ID": "283QT",
      "Location Name": "QUANTICO TACTICAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9750 ABERDEEN RD ABERDEEN NC 28315-7742 USA "
    },
    {
      "Location ID": "283RM",
      "Location Name": "ROOMS TO GO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 901 ROOMS TO GO WAY DUNN NC 28334-7209 USA "
    },
    {
      "Location ID": "283RV",
      "Location Name": "RV PARTS STORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15860 SCOTLAND HIGH RD LAURINURG NC 28352 USA "
    },
    {
      "Location ID": "283SA",
      "Location Name": "SPOUT SPRINGS CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 196 H M CAGLE DR CAMERON NC 28326-7655 USA "
    },
    {
      "Location ID": "283SE",
      "Location Name": "SOUTHERN ELEGANCE CANDLE COMPANY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2550 ABERDEEN RD RAEFORD NC 28479 USA "
    },
    {
      "Location ID": "283SO",
      "Location Name": "MJ SOFFE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 SOFFE DR FAYETTEVILLE NC 28312-5262 USA "
    },
    {
      "Location ID": "283SP",
      "Location Name": "SPIRITUS SYSTEMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 BUD PL ABERDEEN NC 28315-8617 USA "
    },
    {
      "Location ID": "283SS",
      "Location Name": "CPU SPEEDIPRINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 164 WESTWOOD SHOPPING CENTER FAYETTEVILLE NC 28314 USA "
    },
    {
      "Location ID": "283VA",
      "Location Name": "VA HOSPITAL (N.O.)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2300 RAMSEY ST FAYETTEVILLE NC 28301-3856 USA "
    },
    {
      "Location ID": "284",
      "Location Name": "WILMINGTON P&DF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5675 CAROLINA BEACH DR. WILMINGTON NC 28402 USA "
    },
    {
      "Location ID": "28403",
      "Location Name": "AZALEA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3916 OLEANDER DR WILMINGTON NC 28403 USA "
    },
    {
      "Location ID": "28405",
      "Location Name": "DOGWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 314 LENNON DR WILMINGTON NC 28405-2383 USA "
    },
    {
      "Location ID": "28407",
      "Location Name": "UNC WILMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 601 COLLEGE RD WILMINGTON NC 28407-9998 USA "
    },
    {
      "Location ID": "28411",
      "Location Name": "MAGNOLIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8207 MARKET ST STE P WILMINGTON NC 28411-9192 USA "
    },
    {
      "Location ID": "28412",
      "Location Name": "MYRTLE GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5675 CAROLINA BEACH RD WILMINGTON NC 28412 USA "
    },
    {
      "Location ID": "28420",
      "Location Name": "ASH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3882 WHITEVILLE RD NW ASH NC 28420-3800 USA "
    },
    {
      "Location ID": "28421",
      "Location Name": "ATKINSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 W CHURCH ST ATKINSON NC 28421-9230 USA "
    },
    {
      "Location ID": "28422",
      "Location Name": "BOLIVIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3890 BUSINESS 17 E BOLIVIA NC 28422-8665 USA "
    },
    {
      "Location ID": "28423",
      "Location Name": "BOLTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15315 SAM POTTS HWY BOLTON NC 28423-8407 USA "
    },
    {
      "Location ID": "28424",
      "Location Name": "BRUNSWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1424 VINSON BLVD BRUNSWICK NC 28424-8900 USA "
    },
    {
      "Location ID": "28425",
      "Location Name": "BURGAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 E SATCHWELL ST BURGAW NC 28425-5064 USA "
    },
    {
      "Location ID": "28428",
      "Location Name": "CAROLINA BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1323 BRIDGE BARRIER RD CAROLINA BEACH NC 28428-3931 USA "
    },
    {
      "Location ID": "28429",
      "Location Name": "CASTLE HAYNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4024 HOLLY SHELTER RD CASTLE HAYNE NC 28429-5536 USA "
    },
    {
      "Location ID": "28430",
      "Location Name": "CERRO GORDO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 76 PINE ST CERRO GORDO NC 28430-9268 USA "
    },
    {
      "Location ID": "28431",
      "Location Name": "CHADBOURN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 601 N BROWN ST CHADBOURN NC 28431-1305 USA "
    },
    {
      "Location ID": "28432",
      "Location Name": "CLARENDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6337 TEN MILE RD CLARENDON NC 28432-6000 USA "
    },
    {
      "Location ID": "28433",
      "Location Name": "CLARKTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31 E GREEN ST CLARKTON NC 28433-2300 USA "
    },
    {
      "Location ID": "28434",
      "Location Name": "COUNCIL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11740 NC HIGHWAY 211 E COUNCIL NC 28434-9162 USA "
    },
    {
      "Location ID": "28435",
      "Location Name": "CURRIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28480 NC HIGHWAY 210 CURRIE NC 28435-9364 USA "
    },
    {
      "Location ID": "28436",
      "Location Name": "DELCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 DELCO PROSPER RD DELCO NC 28436-9804 USA "
    },
    {
      "Location ID": "28438",
      "Location Name": "EVERGREEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7553 OLD HIGHWAY 74 EVERGREEN NC 28438-9635 USA "
    },
    {
      "Location ID": "28439",
      "Location Name": "FAIR BLUFF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1115 MAIN ST FAIR BLUFF NC 28439-7000 USA "
    },
    {
      "Location ID": "28441",
      "Location Name": "GARLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 S BLADEN AVE GARLAND NC 28441-9716 USA "
    },
    {
      "Location ID": "28442",
      "Location Name": "HALLSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4161 SAM POTTS HWY HALLSBORO NC 28442-8912 USA "
    },
    {
      "Location ID": "28443",
      "Location Name": "HAMPSTEAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 DAN OWEN DR HAMPSTEAD NC 28443-2174 USA "
    },
    {
      "Location ID": "28444",
      "Location Name": "HARRELLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 218 TOMAHAWK HWY HARRELLS NC 28444-6000 USA "
    },
    {
      "Location ID": "28445",
      "Location Name": "HOLLY RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 213 HOLLY ST HOLLY RIDGE NC 28445-7879 USA "
    },
    {
      "Location ID": "28447",
      "Location Name": "IVANHOE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4109 IVANHOE RD IVANHOE NC 28447-9147 USA "
    },
    {
      "Location ID": "28448",
      "Location Name": "KELLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18529 NC HIGHWAY 53 E KELLY NC 28448-8407 USA "
    },
    {
      "Location ID": "28449",
      "Location Name": "KURE BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 237 FORT FISHER BLVD N KURE BEACH NC 28449-4876 USA "
    },
    {
      "Location ID": "28450",
      "Location Name": "LAKE WACCAMAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 CHAUNCEY TOWN RD LAKE WACCAMAW NC 28450-2001 USA "
    },
    {
      "Location ID": "28451",
      "Location Name": "LELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1123 VILLAGE RD NE LELAND NC 28451-8479 USA "
    },
    {
      "Location ID": "28452",
      "Location Name": "LONGWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2165 LONGWOOD RD NW LONGWOOD NC 28452-9763 USA "
    },
    {
      "Location ID": "28453",
      "Location Name": "MAGNOLIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 E MAIN ST MAGNOLIA NC 38453-5000 USA "
    },
    {
      "Location ID": "28454",
      "Location Name": "MAPLE HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 545 MAPLE HILL SCHOOL RD MAPLE HILL NC 28454-7617 USA "
    },
    {
      "Location ID": "28455",
      "Location Name": "NAKINA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7731 SEVEN CREEKS HWY NAKINA NC 28455-8908 USA "
    },
    {
      "Location ID": "28456",
      "Location Name": "RIEGELWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 208 RIEGELWOOD SHOPPING CTR RIEGELWOOD NC 28456-8689 USA "
    },
    {
      "Location ID": "28457",
      "Location Name": "ROCKY POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7974 US HIGHWAY 117 S ROCKY POINT NC 28457-8410 USA "
    },
    {
      "Location ID": "28458",
      "Location Name": "ROSE HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 128 E CHURCH ST ROSE HILL NC 28458-7412 USA "
    },
    {
      "Location ID": "28460",
      "Location Name": "SNEADS FERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2001 NC HIGHWAY 172 SNEADS FERRY NC 28460-6624 USA "
    },
    {
      "Location ID": "28461",
      "Location Name": "SOUTHPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 E NASH ST SOUTHPORT NC 28461-3987 USA "
    },
    {
      "Location ID": "28462",
      "Location Name": "SUPPLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 BLANTON RD SE SUPPLY NC 28462-3590 USA "
    },
    {
      "Location ID": "28463",
      "Location Name": "TABOR CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 E 5TH ST TABOR CITY NC 28463-2102 USA "
    },
    {
      "Location ID": "28464",
      "Location Name": "TEACHEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 N WEST AVE TEACHEY NC 28464-9311 USA "
    },
    {
      "Location ID": "28465",
      "Location Name": "OAK ISLAND CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4580 LONG BEACH RD SE SOUTHPORT NC 28461-8796 USA "
    },
    {
      "Location ID": "28466",
      "Location Name": "WALLACE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 E SOUTHERLAND ST WALLACE NC 28466-2329 USA "
    },
    {
      "Location ID": "28469",
      "Location Name": "SOUTH BRUNSWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1587 SEASIDE RD SW OCEAN ISLE BEACH NC 28469-1029 USA "
    },
    {
      "Location ID": "28470",
      "Location Name": "SHALLOTTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 SMITH AVE SHALLOTTE NC 28470-4732 USA "
    },
    {
      "Location ID": "28472",
      "Location Name": "WHITEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 S LEE ST WHITEVILLE NC 28472 USA "
    },
    {
      "Location ID": "28478",
      "Location Name": "WILLARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 WILLARD RAILROAD ST WILLARD NC 28478-7842 USA "
    },
    {
      "Location ID": "28479",
      "Location Name": "WINNABOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6351 OCEAN HWY E WINNABOW NC 28479-5770 USA "
    },
    {
      "Location ID": "28480",
      "Location Name": "WRIGHTSVILLE BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 CAUSEWAY DR WRIGHTSVILLE BEACH NC 28480-1748 USA "
    },
    {
      "Location ID": "284AA",
      "Location Name": "WILMINGTON MAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 152 N FRONT ST STE 232 WILMINGTON NC 28402-9998 USA "
    },
    {
      "Location ID": "284AH",
      "Location Name": "ABSORB HEALTH LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 COUNTRYSIDE ST SW SUPPLY NC 28462 USA "
    },
    {
      "Location ID": "284AL",
      "Location Name": "ALIEN OUTFITTERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2339 MERCANTILE DR NE LELAND NC 28451 USA "
    },
    {
      "Location ID": "284AN",
      "Location Name": "SOUTHPORT ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5260 DOSHER CUTOFF SE SOUTHPORT NC 28461 USA "
    },
    {
      "Location ID": "284BA",
      "Location Name": "BAME ACE HARDWARE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117B VILLAGE RD NE LELAND NC 28451-7413 USA "
    },
    {
      "Location ID": "284BB",
      "Location Name": "BRUNSWICK BEACON NEWS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 208 SMITH AVE SHALLOTTE NC 28470-8470 USA "
    },
    {
      "Location ID": "284CA",
      "Location Name": "CALABASH ACE HARDWARE CPU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9030 BEACH DR CALABASH NC 28467-2965 USA "
    },
    {
      "Location ID": "284CG",
      "Location Name": "G-CODE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 272 W STAG PARK SERVICE RD BURGAW NC 28425 USA "
    },
    {
      "Location ID": "284ES",
      "Location Name": "EASTERN SKATEBOARD SUPPLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6612 AMSTERDAM WAY WILMINGTON NC 28405-3762 USA "
    },
    {
      "Location ID": "284GC",
      "Location Name": "GREEN COMPASS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 MILLS ST CHADBOURN NC 28431 USA "
    },
    {
      "Location ID": "284LO",
      "Location Name": "LONG BEACH CONTRACT STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5703 E. OAK ISLAND DRIVE OAK ISLAND NC 28465-9999 USA "
    },
    {
      "Location ID": "284MO",
      "Location Name": "MOJOTONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 137 WORTH BEVERAGE DR BURGAW NC 28425-8702 USA "
    },
    {
      "Location ID": "284MP",
      "Location Name": "MARPAC LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2015 CAPITAL DR WILMINGTON NC 28405 USA "
    },
    {
      "Location ID": "284OE",
      "Location Name": "OUTDOOR EQUIPPED",
      "Time Zone Offset (Hours)": 0,
      "Address": " 307A RALEIGH ST UNIT 1 WILMINGTON NC 28412-6306 USA "
    },
    {
      "Location ID": "284QI",
      "Location Name": "QUEENSBORO INDUSTRIES, INC.",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1400 MARSTELLAR ST WILMINGTON NC 28401 USA "
    },
    {
      "Location ID": "284SA",
      "Location Name": "SANSHA USA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2080 MERCHANTILE WAY LELAND NC 28451-4053 USA "
    },
    {
      "Location ID": "284SO",
      "Location Name": "SOURCEONE INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2025 MERCANTILE DR LELAND NC 28451-4054 USA "
    },
    {
      "Location ID": "284ST",
      "Location Name": "UNC WILMINGTON STUDENT CTR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4855 PRICE DR WILMINGTON NC 28403 USA "
    },
    {
      "Location ID": "284TC",
      "Location Name": "TOWN CREEK FULFILLMENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6361 OCEAN HWY E WINNABOW NC 28479 USA "
    },
    {
      "Location ID": "284TF",
      "Location Name": "TRI-TECH FORENSIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3811 INTERNATIONAL BLVD NE STE 100 LELAND NC 28451 USA "
    },
    {
      "Location ID": "284TW",
      "Location Name": "THIS WEAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2024 CAPITAL DR WILMINGTON NC 28405-6464 USA "
    },
    {
      "Location ID": "284WW",
      "Location Name": "WRIGGLESWORTH ACE HARDWARE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3001 N KERR AVE WILMINGTON NC 28405 USA "
    },
    {
      "Location ID": "284XI",
      "Location Name": "XI ONLINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2957 N KERR AVE WILMINGTON NC 28405 USA "
    },
    {
      "Location ID": "28501",
      "Location Name": "KINSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 208 E CASWELL ST KINSTON NC 28501 USA "
    },
    {
      "Location ID": "28508",
      "Location Name": "ALBERTSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3714 N NC 903 HWY ALBERTSON NC 28508-9534 USA "
    },
    {
      "Location ID": "28509",
      "Location Name": "ALLIANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13144 NC 55 HWY ALLIANCE NC 28509-5000 USA "
    },
    {
      "Location ID": "28510",
      "Location Name": "ARAPAHOE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7706 KERSHAW RD ARAPAHOE NC 28510-9709 USA "
    },
    {
      "Location ID": "28511",
      "Location Name": "ATLANTIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 755 SEASHORE DR ATLANTIC NC 28511-9796 USA "
    },
    {
      "Location ID": "28512",
      "Location Name": "ATLANTIC BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1516 W FORT MACON RD ATLANTIC BEACH NC 28512-5328 USA "
    },
    {
      "Location ID": "28513",
      "Location Name": "AYDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 452 2ND ST AYDEN NC 28513-7179 USA "
    },
    {
      "Location ID": "28515",
      "Location Name": "BAYBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 MAIN ST BAYBORO NC 28515-9685 USA "
    },
    {
      "Location ID": "28516",
      "Location Name": "BEAUFORT EAST STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1903 LIVE OAK ST BEAUFORT NC 28516-9998 USA "
    },
    {
      "Location ID": "28518",
      "Location Name": "BEULAVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 807 E MAIN ST BEULAVILLE NC 28518-8715 USA "
    },
    {
      "Location ID": "28519",
      "Location Name": "BRIDGETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 601 N D ST BRIDGETON NC 28519-5000 USA "
    },
    {
      "Location ID": "28521",
      "Location Name": "CHINQUAPIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2758 S NC 41 50 HWY CHINQUAPIN NC 28521-8502 USA "
    },
    {
      "Location ID": "28522",
      "Location Name": "COMFORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4262 HIGHWAY 41 W COMFORT NC 28522-5001 USA "
    },
    {
      "Location ID": "28523",
      "Location Name": "COVE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 E SUNSET BLVD COVE CITY NC 28523-9603 USA "
    },
    {
      "Location ID": "28524",
      "Location Name": "DAVIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 272 HORSESHOE RD DAVIS NC 28524-5000 USA "
    },
    {
      "Location ID": "28525",
      "Location Name": "DEEP RUN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3466 OLD HWY 11 DEEP RUN NC 28525-9304 USA "
    },
    {
      "Location ID": "28526",
      "Location Name": "DOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 W KORNEGAY ST DOVER NC 28526-9427 USA "
    },
    {
      "Location ID": "28527",
      "Location Name": "ERNUL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 HILLS NECK RD ERNUL NC 28527-9720 USA "
    },
    {
      "Location ID": "28528",
      "Location Name": "GLOUCESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 310 PIGOTT RD GLOUCESTER NC 28528-9344 USA "
    },
    {
      "Location ID": "28529",
      "Location Name": "GRANTSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10644 NC 55 HWY E GRANTSBORO NC 28529-5000 USA "
    },
    {
      "Location ID": "28530",
      "Location Name": "GRIFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 466 QUEEN ST GRIFTON NC 28530-9731 USA "
    },
    {
      "Location ID": "28531",
      "Location Name": "HARKERS ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 823 ISLAND RD HARKERS ISLAND NC 28531-9525 USA "
    },
    {
      "Location ID": "28532",
      "Location Name": "HAVELOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 133 US HIGHWAY 70 W HAVELOCK NC 28532-9431 USA "
    },
    {
      "Location ID": "28538",
      "Location Name": "HOOKERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 THIRD ST HOOKERTON NC 28538-9650 USA "
    },
    {
      "Location ID": "28539",
      "Location Name": "HUBERT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 382 HUBERT BLVD HUBERT NC 28539-4266 USA "
    },
    {
      "Location ID": "28540",
      "Location Name": "JACKSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 719 NEW BRIDGE ST JACKSONVILLE NC 28540 USA "
    },
    {
      "Location ID": "28542",
      "Location Name": "CAMP LEJEUNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1770 MCHUGH BLVD CAMP LEJEUNE NC 28547-5000 USA "
    },
    {
      "Location ID": "28543",
      "Location Name": "TARAWA TERRACE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2463 IWO JIMA BLVD TARAWA TERRACE NC 28543-9998 USA "
    },
    {
      "Location ID": "28551",
      "Location Name": "LA GRANGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 S CASWELL ST LA GRANGE NC 28551-1709 USA "
    },
    {
      "Location ID": "28553",
      "Location Name": "MARSHALLBERG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1160 MARSHALLBERG RD MARSHALLBERG NC 28553-9723 USA "
    },
    {
      "Location ID": "28554",
      "Location Name": "MAURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3413 HIGHWAY 903 N MAURY NC 28554-9800 USA "
    },
    {
      "Location ID": "28555",
      "Location Name": "MAYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1004 MAIN ST MAYSVILLE NC 28555-5000 USA "
    },
    {
      "Location ID": "28556",
      "Location Name": "MERRITT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19686 NC HIGHWAY 55 MERRITT NC 28556-5000 USA "
    },
    {
      "Location ID": "28557",
      "Location Name": "MOREHEAD CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3500 BRIDGES ST MOREHEAD CITY NC 28557-3095 USA "
    },
    {
      "Location ID": "28560",
      "Location Name": "DOWNTOWN NEW BERN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 233 MIDDLE ST STE 101 NEW BERN NC 28560 USA "
    },
    {
      "Location ID": "28562",
      "Location Name": "NEW BERN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1851 S GLENBURNIE RD NEW BERN NC 28562 USA "
    },
    {
      "Location ID": "28570",
      "Location Name": "NEWPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 337 HOWARD BLVD NEWPORT NC 28570-7929 USA "
    },
    {
      "Location ID": "28571",
      "Location Name": "ORIENTAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 809 BROAD ST ORIENTAL NC 28571-9781 USA "
    },
    {
      "Location ID": "28572",
      "Location Name": "PINK HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5951 HWY 11 S PINK HILL NC 28572-9554 USA "
    },
    {
      "Location ID": "28573",
      "Location Name": "POLLOCKSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 MAIN ST POLLOCKSVILLE NC 28573-5000 USA "
    },
    {
      "Location ID": "28574",
      "Location Name": "RICHLANDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8201 RICHLANDS HWY RICHLANDS NC 28574-7403 USA "
    },
    {
      "Location ID": "28577",
      "Location Name": "SEALEVEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 751 US HIGHWAY 70 SEALEVEL SEALEVEL NC 28577-9609 USA "
    },
    {
      "Location ID": "28578",
      "Location Name": "SEVEN SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 MAIN ST SEVEN SPRINGS NC 28578-9998 USA "
    },
    {
      "Location ID": "28579",
      "Location Name": "SMYRNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 467 US HIGHWAY 70 SMYRNA SMYRNA NC 28579-9502 USA "
    },
    {
      "Location ID": "28580",
      "Location Name": "SNOW HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 911 SE SECOND ST SNOW HILL NC 28580-1634 USA "
    },
    {
      "Location ID": "28582",
      "Location Name": "STELLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 WETHERINGTON LANDING RD STELLA NC 28582-5000 USA "
    },
    {
      "Location ID": "28583",
      "Location Name": "STONEWALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16375 NC 55 HWY STONEWALL NC 28583-5000 USA "
    },
    {
      "Location ID": "28584",
      "Location Name": "SWANSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 664 W CORBETT AVE SWANSBORO NC 28584-8572 USA "
    },
    {
      "Location ID": "28585",
      "Location Name": "TRENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 S MARKET ST TRENTON NC 28585-5000 USA "
    },
    {
      "Location ID": "28586",
      "Location Name": "VANCEBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8134 MAIN ST VANCEBORO NC 28586-9154 USA "
    },
    {
      "Location ID": "28587",
      "Location Name": "VANDEMERE           NC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1370 PENNSYLVANIA AVE VANDEMERE NC 28587 USA "
    },
    {
      "Location ID": "28590",
      "Location Name": "WINTERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 467 MAIN ST WINTERVILLE NC 28590-9748 USA "
    },
    {
      "Location ID": "28594",
      "Location Name": "EMERALD ISLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 142 EASTVIEW DR EMERALD ISLE NC 28594-1911 USA "
    },
    {
      "Location ID": "285AA",
      "Location Name": "CHERRY POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 153 E ST HAVELOCK NC 28532-5000 USA "
    },
    {
      "Location ID": "285BH",
      "Location Name": "BSH HOME APPLIANCES CORPORATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 EXECUTIVE PARKWAY NEW BERN NC 28562 USA "
    },
    {
      "Location ID": "285BM",
      "Location Name": "BRYNN MARR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 279 HUFF DR JACKSONVILLE NC 28546-7494 USA "
    },
    {
      "Location ID": "285GC",
      "Location Name": "G-CODE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1171 HALLTOWN RD JACKSONVILLE NC 28546-8701 USA "
    },
    {
      "Location ID": "285HS",
      "Location Name": "HIGH SPEED GEAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 87 OLD HAMMOCKS RD SWANSBORO NC 28584-8661 USA "
    },
    {
      "Location ID": "28601",
      "Location Name": "LENOIR RHYNE COLLEGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 625 7TH AVE NE HICKORY NC 28601-3986 USA "
    },
    {
      "Location ID": "28602",
      "Location Name": "HICKORY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 231 GOVERNMENT AVE SW STE 99 HICKORY NC 28602 USA "
    },
    {
      "Location ID": "28603",
      "Location Name": "BETHLEHEM STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 75 RINK DAM HICKORY NC 28601 USA "
    },
    {
      "Location ID": "28604",
      "Location Name": "BANNER ELK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 155 CENTRAL WAY SW BANNER ELK NC 28604 USA "
    },
    {
      "Location ID": "28605",
      "Location Name": "BLOWING ROCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 977 MAIN ST BLOWING ROCK NC 28605 USA "
    },
    {
      "Location ID": "28606",
      "Location Name": "BOOMER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2716 BOOMER RD BOOMER NC 28606 USA "
    },
    {
      "Location ID": "28607",
      "Location Name": "BOONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1544 BLOWING ROCK RD BOONE NC 28607 USA "
    },
    {
      "Location ID": "28609",
      "Location Name": "CATAWBA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 E CENTRAL AVE CATAWBA NC 28609 USA "
    },
    {
      "Location ID": "28610",
      "Location Name": "CLAREMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3327 E MAIN ST CLAREMONT NC 28610 USA "
    },
    {
      "Location ID": "28611",
      "Location Name": "COLLETTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4323 COLLETTSVILLE RD COLLETTSVILLE NC 28611 USA "
    },
    {
      "Location ID": "28612",
      "Location Name": "CONNELLY SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1015 US HIGHWAY 70 CONNELLY SPRINGS NC 28612 USA "
    },
    {
      "Location ID": "28613",
      "Location Name": "CONOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 1ST ST E CONOVER NC 28613 USA "
    },
    {
      "Location ID": "28615",
      "Location Name": "CRESTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 636 THREE TOP RD CRESTON NC 28615 USA "
    },
    {
      "Location ID": "28616",
      "Location Name": "CROSSNORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 FOUNTAIN CIR CROSSNORE NC 28616 USA "
    },
    {
      "Location ID": "28617",
      "Location Name": "CRUMPLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9247 OLD HIGHWAY 16 CRUMPLER NC 28617 USA "
    },
    {
      "Location ID": "28618",
      "Location Name": "DEEP GAP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7378 OLD 421 S DEEP GAP NC 28618 USA "
    },
    {
      "Location ID": "28619",
      "Location Name": "DREXEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 CHURCH ST DREXEL NC 28619 USA "
    },
    {
      "Location ID": "28621",
      "Location Name": "ELKIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 213 W MAIN ST ELKIN NC 28621 USA "
    },
    {
      "Location ID": "28622",
      "Location Name": "ELK PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 153 MAIN ST ELK PARK NC 28622 USA "
    },
    {
      "Location ID": "28623",
      "Location Name": "ENNICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2529 BARRETT RD ENNICE NC 28623 USA "
    },
    {
      "Location ID": "28624",
      "Location Name": "FERGUSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12650 W NC HIGHWAY 268 FERGUSON NC 28624 USA "
    },
    {
      "Location ID": "28626",
      "Location Name": "FLEETWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 910 RAILROAD GRADE RD FLEETWOOD NC 28626 USA "
    },
    {
      "Location ID": "28627",
      "Location Name": "GLADE VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 GLADE VALLEY CHURCH RD GLADE VALLEY NC 28627 USA "
    },
    {
      "Location ID": "28628",
      "Location Name": "GLEN ALPINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 CATAWBA ST GLEN ALPINE NC 28628 USA "
    },
    {
      "Location ID": "28629",
      "Location Name": "GLENDALE SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7471 NC HIGHWAY 16 GLENDALE SPRINGS NC 28629 USA "
    },
    {
      "Location ID": "28630",
      "Location Name": "GRANITE FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 LAKESIDE AVE GRANITE FALLS NC 28630 USA "
    },
    {
      "Location ID": "28631",
      "Location Name": "GRASSY CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15500 OLD NC HIGHWAY 16 GRASSY CREEK NC 28631 USA "
    },
    {
      "Location ID": "28634",
      "Location Name": "HARMONY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3348 HARMONY HWY HARMONY NC 28634 USA "
    },
    {
      "Location ID": "28635",
      "Location Name": "HAYS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5465 MOUNTAIN VIEW RD HAYS NC 28635 USA "
    },
    {
      "Location ID": "28636",
      "Location Name": "HIDDENITE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5181 NC HIGHWAY 90 E HIDDENITE NC 28636 USA "
    },
    {
      "Location ID": "28637",
      "Location Name": "HILDEBRAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 511 US HIGHWAY 70 W STE D HILDEBRAN NC 28637 USA "
    },
    {
      "Location ID": "28638",
      "Location Name": "HUDSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 FAIRWAY AVE HUDSON NC 28638 USA "
    },
    {
      "Location ID": "28640",
      "Location Name": "JEFFERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 410 E MAIN ST JEFFERSON NC 28640 USA "
    },
    {
      "Location ID": "28641",
      "Location Name": "JONAS RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9042 NC HWY 181 JONAS RIDGE NC 28641 USA "
    },
    {
      "Location ID": "28642",
      "Location Name": "JONESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 127 N BRIDGE ST JONESVILLE NC 28642 USA "
    },
    {
      "Location ID": "28643",
      "Location Name": "LANSING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9294 NC HIGHWAY 194 N LANSING NC 28643 USA "
    },
    {
      "Location ID": "28644",
      "Location Name": "LAUREL SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12533 NC HIGHWAY 18 S LAUREL SPRINGS NC 28644 USA "
    },
    {
      "Location ID": "28645",
      "Location Name": "LENOIR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 RIDGE ST NW LENOIR NC 28645 USA "
    },
    {
      "Location ID": "28646",
      "Location Name": "LINVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4235 MITCHELL AVE LINVILLE NC 28646 USA "
    },
    {
      "Location ID": "28647",
      "Location Name": "LINVILLE FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8890 NC HIGHWAY 183 LINVILLE FALLS NC 28647 USA "
    },
    {
      "Location ID": "28649",
      "Location Name": "MC GRADY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11532 SPARTA RD MC GRADY NC 28649 USA "
    },
    {
      "Location ID": "28650",
      "Location Name": "MAIDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 512 ISLAND FORD RD MAIDEN NC 28650 USA "
    },
    {
      "Location ID": "28651",
      "Location Name": "MILLERS CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 148 POST OFFICE DR MILLERS CREEK NC 28651 USA "
    },
    {
      "Location ID": "28652",
      "Location Name": "MINNEAPOLIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2751 US HIGHWAY 19 E MINNEAPOLIS NC 28652 USA "
    },
    {
      "Location ID": "28653",
      "Location Name": "MONTEZUMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 380 OLD NC HIGHWAY 181 MONTEZUMA NC 28653 USA "
    },
    {
      "Location ID": "28654",
      "Location Name": "MORAVIAN FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1933 MORAVIAN FALLS RD MORAVIAN FALLS NC 28654 USA "
    },
    {
      "Location ID": "28655",
      "Location Name": "MORGANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 320 SANFORD DR MORGANTON NC 28655 USA "
    },
    {
      "Location ID": "28657",
      "Location Name": "NEWLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 124 ELK ST NEWLAND NC 28657 USA "
    },
    {
      "Location ID": "28658",
      "Location Name": "NEWTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 218 S MAIN AVE NEWTON NC 28658 USA "
    },
    {
      "Location ID": "28659",
      "Location Name": "NORTH WILKESBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 8TH ST NORTH WILKESBORO NC 28659 USA "
    },
    {
      "Location ID": "28660",
      "Location Name": "OLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 583 OLIN LOOP OLIN NC 28660 USA "
    },
    {
      "Location ID": "28661",
      "Location Name": "PATTERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2008 ROBY MARTIN RD PATTERSON NC 28661 USA "
    },
    {
      "Location ID": "28662",
      "Location Name": "PINEOLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2884 LINVILLE FALLS HWY PINEOLA NC 28662 USA "
    },
    {
      "Location ID": "28663",
      "Location Name": "PINEY CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4216 PINEY POST OFFICE RD PINEY CREEK NC 28663 USA "
    },
    {
      "Location ID": "28664",
      "Location Name": "PLUMTREE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4420 US HIGHWAY 19E S PLUMTREE NC 28664 USA "
    },
    {
      "Location ID": "28665",
      "Location Name": "PURLEAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 226 PURLEAR RD PURLEAR NC 28665 USA "
    },
    {
      "Location ID": "28666",
      "Location Name": "ICARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3462 MILLER BRIDGE RD ICARD NC 28666 USA "
    },
    {
      "Location ID": "28667",
      "Location Name": "RHODHISS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 CHURCH ST RHODHISS NC 28667 USA "
    },
    {
      "Location ID": "28668",
      "Location Name": "ROARING GAP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11370 US HIGHWAY 21 S ROARING GAP NC 28668 USA "
    },
    {
      "Location ID": "28669",
      "Location Name": "ROARING RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7953 ELKIN HIGHWAY 268 ROARING RIVER NC 28669 USA "
    },
    {
      "Location ID": "28670",
      "Location Name": "RONDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 383 3RD ST RONDA NC 28670 USA "
    },
    {
      "Location ID": "28671",
      "Location Name": "RUTHERFORD COLLEGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 721C MALCOLM BLVD RUTHERFORD COLLEGE NC 28671 USA "
    },
    {
      "Location ID": "28673",
      "Location Name": "SHERRILLS FORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8220 SHERRILLS FORD RD SHERRILLS FORD NC 28673 USA "
    },
    {
      "Location ID": "28675",
      "Location Name": "SPARTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 79 S GRAYSON ST SPARTA NC 28675 USA "
    },
    {
      "Location ID": "28676",
      "Location Name": "STATE ROAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 950 KLONDIKE RD STATE ROAD NC 28676 USA "
    },
    {
      "Location ID": "28677",
      "Location Name": "STATESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 S OAKLAND AVE STATESVILLE NC 28677 USA "
    },
    {
      "Location ID": "28678",
      "Location Name": "STONY POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8814 NC HWY 90 E STONY POINT NC 28678 USA "
    },
    {
      "Location ID": "28679",
      "Location Name": "SUGAR GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1205 US HIGHWAY 321 N SUGAR GROVE NC 28679 USA "
    },
    {
      "Location ID": "28681",
      "Location Name": "TAYLORSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 1ST AVE SW TAYLORSVILLE NC 28681 USA "
    },
    {
      "Location ID": "28682",
      "Location Name": "TERRELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7985 NC HIGHWAY 150 E TERRELL NC 28682 USA "
    },
    {
      "Location ID": "28683",
      "Location Name": "THURMOND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2301 THURMOND RD THURMOND NC 28683 USA "
    },
    {
      "Location ID": "28684",
      "Location Name": "TODD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4021 TODD RAILROAD GRADE RD TODD NC 28684 USA "
    },
    {
      "Location ID": "28685",
      "Location Name": "TRAPHILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9550 TRAPHILL RD TRAPHILL NC 28685 USA "
    },
    {
      "Location ID": "28688",
      "Location Name": "TURNERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2223 TURNERSBURG HWY TURNERSBURG NC 28688 USA "
    },
    {
      "Location ID": "28689",
      "Location Name": "UNION GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1501 UNION GROVE RD UNION GROVE NC 28689 USA "
    },
    {
      "Location ID": "28690",
      "Location Name": "VALDESE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 RODORET ST S VALDESE NC 28690 USA "
    },
    {
      "Location ID": "28691",
      "Location Name": "VALLE CRUCS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3565 NC HIGHWAY 194 S BANNER ELK NC 28604 USA "
    },
    {
      "Location ID": "28692",
      "Location Name": "VILAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6141 US HIGHWAY 421 N VILAS NC 28692 USA "
    },
    {
      "Location ID": "28693",
      "Location Name": "WARRENSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 122 NW SCHOOL RD WARRENSVILLE NC 28693 USA "
    },
    {
      "Location ID": "28694",
      "Location Name": "WEST JEFFERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 419 E 2ND ST WEST JEFFERSON NC 28694 USA "
    },
    {
      "Location ID": "28697",
      "Location Name": "WILKESBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 207 W MAIN ST RM 102 WILKESBORO NC 28697 USA "
    },
    {
      "Location ID": "28698",
      "Location Name": "ZIONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12416 US HIGHWAY 421 N ZIONVILLE NC 28698 USA "
    },
    {
      "Location ID": "28699",
      "Location Name": "SCOTTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4700 TAYLORSVILLE RD SCOTTS NC 28699 USA "
    },
    {
      "Location ID": "286BS",
      "Location Name": "BSN MEDICAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1011 KEISLER ROAD SE CONOVER NC 28613 USA "
    },
    {
      "Location ID": "286CD",
      "Location Name": "CABINET DOOR WORLD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 909 10ST NE HICKORY NC 28601 USA "
    },
    {
      "Location ID": "286CJ",
      "Location Name": "CHEAP JOE'S",
      "Time Zone Offset (Hours)": 0,
      "Address": " 374 INDUSTRIAL PARK DR BOONE NC 28607 USA "
    },
    {
      "Location ID": "286LC",
      "Location Name": "LENOIR RHYNE COLLEGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 625 7TH AVE NE HICKORY NC 28601 USA "
    },
    {
      "Location ID": "286SR",
      "Location Name": "CPU SPRINGS ROAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1269 16TH ST NE STE E HICKORY NC 28601 USA "
    },
    {
      "Location ID": "286SW",
      "Location Name": "STEVE WHITE MOTORS NC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3470 HWY 70 SE NEWTON NC 28658 USA "
    },
    {
      "Location ID": "287",
      "Location Name": "ASHEVILLE HUB",
      "Time Zone Offset (Hours)": 0,
      "Address": " 591 BREVARD RD ASHEVILLE NC 28810 USA "
    },
    {
      "Location ID": "28701",
      "Location Name": "ALEXANDER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2350 OLD MARSHALL HWY ALEXANDER NC 28701 USA "
    },
    {
      "Location ID": "28702",
      "Location Name": "ALMOND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1940 NC HIGHWAY 28 N ALMOND NC 28702 USA "
    },
    {
      "Location ID": "28704",
      "Location Name": "ARDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2385 HENDERSONVILLE RD ARDEN NC 28704 USA "
    },
    {
      "Location ID": "28705",
      "Location Name": "BAKERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 HEMLOCK DR BAKERSVILLE NC 28705 USA "
    },
    {
      "Location ID": "28707",
      "Location Name": "BALSAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 CABIN FLATS RD BALSAM NC 28707 USA "
    },
    {
      "Location ID": "28709",
      "Location Name": "BARNARDSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1664 BARNARDSVILLE HWY BARNARDSVILLE NC 28709 USA "
    },
    {
      "Location ID": "28710",
      "Location Name": "BAT CAVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 89 LAKE LURE HWY BAT CAVE NC 28710 USA "
    },
    {
      "Location ID": "28711",
      "Location Name": "BLACK MOUNTAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 RICHARDSON BLVD BLACK MOUNTAIN NC 28711 USA "
    },
    {
      "Location ID": "28712",
      "Location Name": "BREVARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 159 W MAIN ST BREVARD NC 28712 USA "
    },
    {
      "Location ID": "28713",
      "Location Name": "BRYSON CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 SLOPE ST BRYSON CITY NC 28713 USA "
    },
    {
      "Location ID": "28714",
      "Location Name": "BURNSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 670 W MAIN ST BURNSVILLE NC 28714 USA "
    },
    {
      "Location ID": "28715",
      "Location Name": "CANDLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 99 RUTHERFORD RD CANDLER NC 28715 USA "
    },
    {
      "Location ID": "28716",
      "Location Name": "CANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 183 PARK ST CANTON NC 28716 USA "
    },
    {
      "Location ID": "28717",
      "Location Name": "CASHIERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 230 FRANK ALLEN RD CASHIERS NC 28717 USA "
    },
    {
      "Location ID": "28718",
      "Location Name": "CEDAR MOUNTAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9515 GREENVILLE HWY CEDAR MOUNTAIN NC 28718 USA "
    },
    {
      "Location ID": "28719",
      "Location Name": "CHEROKEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 671 TSALI BLVD CHEROKEE NC 28719 USA "
    },
    {
      "Location ID": "28720",
      "Location Name": "CHIMNEY ROCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 366 MAIN ST CHIMNEY ROCK NC 28720 USA "
    },
    {
      "Location ID": "28721",
      "Location Name": "CLYDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8639 CAROLINA BLVD CLYDE NC 28721 USA "
    },
    {
      "Location ID": "28722",
      "Location Name": "COLUMBUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 126 WARD ST COLUMBUS NC 28722 USA "
    },
    {
      "Location ID": "28723",
      "Location Name": "CULLOWHEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4710 LITTLE SAVANNAH RD CULLOWHEE NC 28723 USA "
    },
    {
      "Location ID": "28724",
      "Location Name": "DANA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 RIDGE RD DANA NC 28724 USA "
    },
    {
      "Location ID": "28725",
      "Location Name": "DILLSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 385 HAYWOOD RD DILLSBORO NC 28725 USA "
    },
    {
      "Location ID": "28726",
      "Location Name": "EAST FLAT ROCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 W BLUE RIDGE RD EAST FLAT ROCK NC 28726 USA "
    },
    {
      "Location ID": "28727",
      "Location Name": "EDNEYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3979 CHIMNEY ROCK RD EDNEYVILLE NC 28727 USA "
    },
    {
      "Location ID": "28728",
      "Location Name": "ENKA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1572 SAND HILL RD ENKA NC 28728 USA "
    },
    {
      "Location ID": "28729",
      "Location Name": "ETOWAH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 ETOWAH CENTER DR ETOWAH NC 28729 USA "
    },
    {
      "Location ID": "28730",
      "Location Name": "FAIRVIEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1352 CHARLOTTE HWY FAIRVIEW NC 28730 USA "
    },
    {
      "Location ID": "28731",
      "Location Name": "FLAT ROCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2687 GREENVILLE HWY FLAT ROCK NC 28731 USA "
    },
    {
      "Location ID": "28732",
      "Location Name": "FLETCHER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3616 HENDERSONVILLE RD FLETCHER NC 28732 USA "
    },
    {
      "Location ID": "28733",
      "Location Name": "FONTANA DAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 FONTANA RD FONTANA DAM NC 28733 USA "
    },
    {
      "Location ID": "28734",
      "Location Name": "FRANKLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 DEPOT ST FRANKLIN NC 28734 USA "
    },
    {
      "Location ID": "28735",
      "Location Name": "GERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5000 GERTON HWY GERTON NC 28735 USA "
    },
    {
      "Location ID": "28736",
      "Location Name": "GLENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4907 N HIGHWAY 107 GLENVILLE NC 28736 USA "
    },
    {
      "Location ID": "28738",
      "Location Name": "HAZELWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 369 HAZELWOOD AVE WAYNESVILLE NC 28786 USA "
    },
    {
      "Location ID": "28740",
      "Location Name": "GREEN MOUNTAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 291 TOE RIVER RD GREEN MOUNTAIN NC 28740 USA "
    },
    {
      "Location ID": "28741",
      "Location Name": "HIGHLANDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 43 DILLARD RD HIGHLANDS NC 28741 USA "
    },
    {
      "Location ID": "28742",
      "Location Name": "HORSE SHOE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3740 BREVARD RD HORSE SHOE NC 28742 USA "
    },
    {
      "Location ID": "28743",
      "Location Name": "HOT SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 BRIDGE ST HOT SPRINGS NC 28743 USA "
    },
    {
      "Location ID": "28745",
      "Location Name": "LAKE JUNALUSKA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 OLD CLYDE RD LAKE JUNALUSKA NC 28745 USA "
    },
    {
      "Location ID": "28746",
      "Location Name": "LAKE LURE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2432 MEMORIAL HWY LAKE LURE NC 28746 USA "
    },
    {
      "Location ID": "28747",
      "Location Name": "LAKE TOXAWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1320 BLUE RIDGE RD LAKE TOXAWAY NC 28747 USA "
    },
    {
      "Location ID": "28748",
      "Location Name": "LEICESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2520 NEW LEICESTER HWY LEICESTER NC 28748 USA "
    },
    {
      "Location ID": "28749",
      "Location Name": "LITTLE SWITZERLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9455 HIGHWAY 226A LITTLE SWITZERLAND NC 28749 USA "
    },
    {
      "Location ID": "2874W",
      "Location Name": "Barnes 4WD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 MCKINNEY RD ETOWAH NC 28729-9772 USA "
    },
    {
      "Location ID": "28750",
      "Location Name": "LYNN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2633 LYNN RD LYNN NC 28750 USA "
    },
    {
      "Location ID": "28751",
      "Location Name": "MAGGIE VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4280 SOCO RD MAGGIE VALLEY NC 28751 USA "
    },
    {
      "Location ID": "28752",
      "Location Name": "MARION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 S MAIN ST MARION NC 28752 USA "
    },
    {
      "Location ID": "28753",
      "Location Name": "MARSHALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 S MAIN ST MARSHALL NC 28753 USA "
    },
    {
      "Location ID": "28754",
      "Location Name": "MARS HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 65 S MAIN ST MARS HILL NC 28754 USA "
    },
    {
      "Location ID": "28755",
      "Location Name": "MICAVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 551 MICAVILLE LOOP MICAVILLE NC 28755 USA "
    },
    {
      "Location ID": "28756",
      "Location Name": "MILL SPRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 SCHOOL RD MILL SPRING NC 28756 USA "
    },
    {
      "Location ID": "28757",
      "Location Name": "MONTREAT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 ASSEMBLY DR MONTREAT NC 28757 USA "
    },
    {
      "Location ID": "28758",
      "Location Name": "MOUNTAIN HOME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 524 COURTLAND BLVD MOUNTAIN HOME NC 28758 USA "
    },
    {
      "Location ID": "28760",
      "Location Name": "NAPLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 S NAPLES RD NAPLES NC 28760 USA "
    },
    {
      "Location ID": "28761",
      "Location Name": "NEBO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 229 NEBO SCHOOL RD NEBO NC 28761 USA "
    },
    {
      "Location ID": "28762",
      "Location Name": "OLD FORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 180 E MAIN ST OLD FORT NC 28762 USA "
    },
    {
      "Location ID": "28763",
      "Location Name": "OTTO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8491 GEORGIA RD OTTO NC 28763 USA "
    },
    {
      "Location ID": "28765",
      "Location Name": "PENLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1162 PENLAND RD PENLAND NC 28765 USA "
    },
    {
      "Location ID": "28766",
      "Location Name": "PENROSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5858 OLD HENDERSONVILLE HWY PENROSE NC 28766 USA "
    },
    {
      "Location ID": "28768",
      "Location Name": "PISGAH FOREST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 OLD HENDERSONVILLE HWY PISGAH FOREST NC 28768 USA "
    },
    {
      "Location ID": "28770",
      "Location Name": "RIDGECREST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 TUCKER RD RIDGECREST NC 28770 USA "
    },
    {
      "Location ID": "28771",
      "Location Name": "ROBBINSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 74 SWEETWATER RD ROBBINSVILLE NC 28771 USA "
    },
    {
      "Location ID": "28772",
      "Location Name": "ROSMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 272 MAIN ST ROSMAN NC 28772 USA "
    },
    {
      "Location ID": "28773",
      "Location Name": "SALUDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 E MAIN ST SALUDA NC 28773 USA "
    },
    {
      "Location ID": "28774",
      "Location Name": "SAPPHIRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17740 ROSMAN HWY SAPPHIRE NC 28774 USA "
    },
    {
      "Location ID": "28775",
      "Location Name": "SCALY MOUNTAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7312 DILLARD RD SCALY MOUNTAIN NC 28775 USA "
    },
    {
      "Location ID": "28776",
      "Location Name": "SKYLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1950 HENDERSONVILLE RD SKYLAND NC 28776 USA "
    },
    {
      "Location ID": "28777",
      "Location Name": "SPRUCE PINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 899 OAK AVE SPRUCE PINE NC 28777 USA "
    },
    {
      "Location ID": "28778",
      "Location Name": "SWANNANOA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2311 US 70 HWY SWANNANOA NC 28778 USA "
    },
    {
      "Location ID": "28779",
      "Location Name": "SYLVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 271 JACKSON PLZ SYLVA NC 28779 USA "
    },
    {
      "Location ID": "28781",
      "Location Name": "TOPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23979 US HIGHWAY 19 TOPTON NC 28781 USA "
    },
    {
      "Location ID": "28782",
      "Location Name": "TRYON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 95 PACOLET ST TRYON NC 28782 USA "
    },
    {
      "Location ID": "28783",
      "Location Name": "TUCKASEGEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 CANADA RD TUCKASEGEE NC 28783 USA "
    },
    {
      "Location ID": "28786",
      "Location Name": "WAYNESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 523 S HAYWOOD ST WAYNESVILLE NC 28786 USA "
    },
    {
      "Location ID": "28787",
      "Location Name": "WEAVERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 270 N MAIN ST WEAVERVILLE NC 28787 USA "
    },
    {
      "Location ID": "28788",
      "Location Name": "WEBSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1345 WEBSTER RD WEBSTER NC 28788 USA "
    },
    {
      "Location ID": "28789",
      "Location Name": "WHITTIER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22 MAIN ST WHITTIER NC 28789 USA "
    },
    {
      "Location ID": "28790",
      "Location Name": "ZIRCONIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5576 GREENVILLE HWY ZIRCONIA NC 28790 USA "
    },
    {
      "Location ID": "28792",
      "Location Name": "HENDERSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 427 5TH AVE W HENDERSONVILLE NC 28739 USA "
    },
    {
      "Location ID": "28793",
      "Location Name": "HENDERSONVILLE MAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1800 FOUR SEASONS BLVD STE 11 HENDERSONVILLE NC 28792 USA "
    },
    {
      "Location ID": "287AB",
      "Location Name": "HENDERSONVILLE ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90 LAKEWOOD RD HENDERSONVILLE NC 28792 USA "
    },
    {
      "Location ID": "287AC",
      "Location Name": "AIR CHECK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1936 BUTLER BRIDGE RD MILLS RIVER NC 28759 USA "
    },
    {
      "Location ID": "287AF",
      "Location Name": "AEROFLOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 65 BEALE RD ARDEN NC 28704 USA "
    },
    {
      "Location ID": "287BA",
      "Location Name": "Barnes 4WD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 MCKINNEY RD ETOWAH NC 28729-9772 USA "
    },
    {
      "Location ID": "287BR",
      "Location Name": "BLUE RIDGE BARGAINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 51 JACKSON LOOP RD FLAT ROCK NC 28731 USA "
    },
    {
      "Location ID": "287BS",
      "Location Name": "BODY AND SOUL LABS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 COMMERCE STREET OLD FORT NC 28762 USA "
    },
    {
      "Location ID": "287BW",
      "Location Name": "Bonworth Factory Outlet",
      "Time Zone Offset (Hours)": 0,
      "Address": " 707 BROOKLYN AVE HENDERSONVILLE NC 28792 USA "
    },
    {
      "Location ID": "287CB",
      "Location Name": "CYCLE BITZ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 370 BUTLER BRIDGE RD FLETCHER NC 28732 USA "
    },
    {
      "Location ID": "287CC",
      "Location Name": "CAROLINA CUSTOM FOAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 EASTSIDE DRIVE BLACK MOUNTAIN NC 28711 USA "
    },
    {
      "Location ID": "287CF",
      "Location Name": "COZY FLEECE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4600 HENDERSONVILLE RD FLETCHER NC 28732-8254 USA "
    },
    {
      "Location ID": "287DR",
      "Location Name": "DYNAMITE ROASTING COMPANY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2744 US HWY 70 SWANNANOA NC 28778 USA "
    },
    {
      "Location ID": "287EB",
      "Location Name": "EDEN BROTHERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2099 BREVARD RD ARDEN NC 28704 USA "
    },
    {
      "Location ID": "287EF",
      "Location Name": "EAST FORK POTTERY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 144 CARIBOU RD ASHEVILLE NC 28803 USA "
    },
    {
      "Location ID": "287FF",
      "Location Name": "FEEL FREE KAYAK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 BUCKEYE COVE RD SWANNANOA NC 28778 USA "
    },
    {
      "Location ID": "287FS",
      "Location Name": "FEELING SMITTEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 49 GRACE WAY FLETCHER NC 28732-8365 USA "
    },
    {
      "Location ID": "287GN",
      "Location Name": "GOLDEN NEEDLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 RUTHLEGE CIR FLETCHER NC 28732 USA "
    },
    {
      "Location ID": "287IW",
      "Location Name": "INKWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 38 ROSSCRAGGON RD STE O ASHEVILLE NC 28803-1165 USA "
    },
    {
      "Location ID": "287KB",
      "Location Name": "KITSBOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 59 COMMERCE ST OLD FORT NC 28762 USA "
    },
    {
      "Location ID": "287KK",
      "Location Name": "KOLORFUL KITCHENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 96D FLETCHER COMMERCE DR FLETCHER NC 28732 USA "
    },
    {
      "Location ID": "287KR",
      "Location Name": "KOHL'S - ROCK HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1522 MEETING BLVD ROCK HILL SC 29730 USA "
    },
    {
      "Location ID": "287ME",
      "Location Name": "MOUNTAIN ELECTRONICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 539 CLEAR CREEK RD BURNSVILLE NC 28714 USA "
    },
    {
      "Location ID": "287MH",
      "Location Name": "MISSION HEALTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 RIDGEFIELD CT ASHEVILLE NC 28806 USA "
    },
    {
      "Location ID": "287ML",
      "Location Name": "M&M LABS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 135 ADA MOORE ST COLUMBUS NC 28722 USA "
    },
    {
      "Location ID": "287MM",
      "Location Name": "MOOD & MIND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 56 MCGUIRE CIRCLE PISGAH FOREST NC 28768 USA "
    },
    {
      "Location ID": "287MR",
      "Location Name": "MR HONDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 774 HENDERSONVILLE RD ASHEVILLE NC 28803 USA "
    },
    {
      "Location ID": "287MT",
      "Location Name": "MICROTECH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 321 FANNING FIELD RD MILLS RIVER NC 28759 USA "
    },
    {
      "Location ID": "287MW",
      "Location Name": "MANUAL WOODWORKERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3737 HOWARD GAP RD HENDERSONVILLE NC 28792 USA "
    },
    {
      "Location ID": "287OX",
      "Location Name": "OCEAN X",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1845 BREVARD RD ARDEN NC 28704-9145 USA "
    },
    {
      "Location ID": "287PH",
      "Location Name": "PHARMA SUPPLY COMPANY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 145 CANE CREEK INDUSTRIAL PARK SUITE 550 FLETCHER NC 28732 USA "
    },
    {
      "Location ID": "287PR",
      "Location Name": "PRINTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 224 OLD AIRPORT RD FLETCHER NC 28732 USA "
    },
    {
      "Location ID": "287PU",
      "Location Name": "PARTS UNLIMITED",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 COMMERICAL BLVD FLAT ROCK NC 28731 USA "
    },
    {
      "Location ID": "287TA",
      "Location Name": "TSA SAFETY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 CONRAD INDUSTRIAL DR WEAVERVILLE NC 28787 USA "
    },
    {
      "Location ID": "287TS",
      "Location Name": "TANGENT SCALE MODELS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 NEW LEICESTER HWY ASHEVILLE NC 28806 USA "
    },
    {
      "Location ID": "287TW",
      "Location Name": "TAMED WILD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 417 N PINE STREET HENDERSONVILLE NC 28792 USA "
    },
    {
      "Location ID": "287UG",
      "Location Name": "Uplifting Gifts",
      "Time Zone Offset (Hours)": 0,
      "Address": " 128 BINGHAM RD ASHEVILLE NC 28806 USA "
    },
    {
      "Location ID": "287VB",
      "Location Name": "VBS LOGISTICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 218 OLD AIRPORT RD FLETCHER NC 28732 USA "
    },
    {
      "Location ID": "28801",
      "Location Name": "WEST ASHEVILLE-01",
      "Time Zone Offset (Hours)": 0,
      "Address": " 591 BREVARD RD ASHEVILLE NC 28810 USA "
    },
    {
      "Location ID": "28802",
      "Location Name": "ASHEVILLE DOWNTOWN STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 COXE AVE ASHEVILLE NC 28802 USA "
    },
    {
      "Location ID": "28803",
      "Location Name": "LONDON RD ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37 LONDON RD ASHEVILLE NC 28803 USA "
    },
    {
      "Location ID": "28804",
      "Location Name": "GRACE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 725 MERRIMON AVE ASHEVILLE NC 28804 USA "
    },
    {
      "Location ID": "28805",
      "Location Name": "OTEEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1141 TUNNEL RD STE C ASHEVILLE NC 28805 USA "
    },
    {
      "Location ID": "28806",
      "Location Name": "WEST ASHEVILLE-06",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1302 PATTON AVENUE ASHEVILLE NC 28806 USA "
    },
    {
      "Location ID": "28813",
      "Location Name": "BILTMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 780 HENDERSONVILLE RD STE 7 ASHEVILLE NC 28803 USA "
    },
    {
      "Location ID": "28816",
      "Location Name": "WEST ASHEVILLE FINANCE UNIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1302 PATTON AVE ASHEVILLE NC 28816 USA "
    },
    {
      "Location ID": "288AD",
      "Location Name": "AFG DISTRIBUTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 128 BINGHAM RD ASHEVILLE NC 28806 USA "
    },
    {
      "Location ID": "288AS",
      "Location Name": "APPALACHIAN STANDARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 PINEY PARK RD ASHEVILLE NC 28806 USA "
    },
    {
      "Location ID": "288BC",
      "Location Name": "BILTMORE COMPANY WAREHOUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2000 RIVERSIDE DR ASHEVILLE NC 28804 USA "
    },
    {
      "Location ID": "288CC",
      "Location Name": "CENTER FOR CHILDRENLSHOEBOXTASK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2004 RIVERSIDE DR UNIT S ASHEVILLE NC 28804 USA "
    },
    {
      "Location ID": "288DG",
      "Location Name": "DANIELS GRAPHICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 131 SWEETEN CREEK ASHEVILLE NC 28803 USA "
    },
    {
      "Location ID": "288EB",
      "Location Name": "EDEN BROTHERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 OLD BREVARD RD ASHEVILLE NC 28806 USA "
    },
    {
      "Location ID": "288HG",
      "Location Name": "HIS GLASSWORKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2000 RIVERSIDE DR STE 19 ASHEVILLE NC 28804 USA "
    },
    {
      "Location ID": "288ME",
      "Location Name": "3 MINUTE EGG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 179 MERRIMON AVE ASHEVILLE NC 28801 USA "
    },
    {
      "Location ID": "288NT",
      "Location Name": "NODDING TURTLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 208 ELK PARK DRIVE ASHEVILLE NC 28804 USA "
    },
    {
      "Location ID": "288OP",
      "Location Name": "OOWEE PRODUCTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2004 RIVERSIDE DR UNIT F ASHEVILLE NC 28804 USA "
    },
    {
      "Location ID": "288VA",
      "Location Name": "VETERANS ADMIN HOSPITAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1100 TUNNEL RD ASHEVILLE NC 28805 USA "
    },
    {
      "Location ID": "28901",
      "Location Name": "ANDREWS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 CHESTNUT ST ANDREWS NC 28901 USA "
    },
    {
      "Location ID": "28902",
      "Location Name": "BRASSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 98 SETTAWIG RD BRASSTOWN NC 28902 USA "
    },
    {
      "Location ID": "28903",
      "Location Name": "CULBERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2400 NC HWY 60 CULBERSON NC 28903 USA "
    },
    {
      "Location ID": "28904",
      "Location Name": "HAYESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 136 SANDERSON ST HAYESVILLE NC 28904 USA "
    },
    {
      "Location ID": "28905",
      "Location Name": "MARBLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 189 MARBLE RD MARBLE NC 28905 USA "
    },
    {
      "Location ID": "28906",
      "Location Name": "MURPHY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 388 VALLEY RIVER AVE MURPHY NC 28906 USA "
    },
    {
      "Location ID": "28909",
      "Location Name": "WARNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4731 OLD HWY 64 W WARNE NC 28909 USA "
    },
    {
      "Location ID": "289MM",
      "Location Name": "MURPHY MED CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4130 US 64 E MURPHY NC 28906 USA "
    },
    {
      "Location ID": "28PSI",
      "Location Name": "PSI GROUP INC GMF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4300 NORTHLAKE COURT-SUITE K CHARLOTTE NC 28216 USA "
    },
    {
      "Location ID": "290",
      "Location Name": "LOG COLUMBIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2001 DIXIANA RD COLUMBIA SC 29292 USA "
    },
    {
      "Location ID": "29001",
      "Location Name": "ALCOLU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1842 MAIN ST ALCOLU SC 29001 USA "
    },
    {
      "Location ID": "29002",
      "Location Name": "BALLENTINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1720 DUTCH FORK RD STE A BALLENTINE SC 29002 USA "
    },
    {
      "Location ID": "29003",
      "Location Name": "BAMBERG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11955 HERITAGE HWY BAMBERG SC 29003 USA "
    },
    {
      "Location ID": "29009",
      "Location Name": "BETHUNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 503 KING ST E BETHUNE SC 29009 USA "
    },
    {
      "Location ID": "29010",
      "Location Name": "BISHOPVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 451 N MAIN ST BISHOPVILLE SC 29010 USA "
    },
    {
      "Location ID": "29014",
      "Location Name": "BLACKSTOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1083 DURHAM RD BLACKSTOCK SC 29014 USA "
    },
    {
      "Location ID": "29015",
      "Location Name": "BLAIR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 96 GRANITE RD BLAIR SC 29015 USA "
    },
    {
      "Location ID": "29016",
      "Location Name": "BLYTHEWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 MCNULTY ST BLYTHEWOOD SC 29016 USA "
    },
    {
      "Location ID": "29018",
      "Location Name": "BOWMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6902 CHARLESTON HWY BOWMAN SC 29018 USA "
    },
    {
      "Location ID": "29020",
      "Location Name": "CAMDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 542 E DEKALB ST CAMDEN SC 29020 USA "
    },
    {
      "Location ID": "29030",
      "Location Name": "CAMERON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 304 BOYCE LAWTON DR CAMERON SC 29030 USA "
    },
    {
      "Location ID": "29031",
      "Location Name": "CARLISLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4512 KING KENNEDY ST CARLISLE SC 29031 USA "
    },
    {
      "Location ID": "29032",
      "Location Name": "CASSATT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2589 HIGHWAY 1 N CASSATT SC 29032 USA "
    },
    {
      "Location ID": "29036",
      "Location Name": "CHAPIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1249 CHAPIN RD CHAPIN SC 29036 USA "
    },
    {
      "Location ID": "29037",
      "Location Name": "CHAPPELLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 390 SC HIGHWAY 39 CHAPPELLS SC 29037 USA "
    },
    {
      "Location ID": "29038",
      "Location Name": "COPE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 204 COTTON AVE COPE SC 29038 USA "
    },
    {
      "Location ID": "29039",
      "Location Name": "CORDOVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2046 LEGRAND SMOAK ST CORDOVA SC 29039 USA "
    },
    {
      "Location ID": "29040",
      "Location Name": "DALZELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4235 CAMDEN HWY DALZELL SC 29040 USA "
    },
    {
      "Location ID": "29041",
      "Location Name": "DAVIS STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5232 MOSES DINGLE RD DAVIS STATION SC 29041 USA "
    },
    {
      "Location ID": "29042",
      "Location Name": "DENMARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5022 CAROLINA HWY DENMARK SC 29042 USA "
    },
    {
      "Location ID": "29044",
      "Location Name": "EASTOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 MAIN ST EASTOVER SC 29044 USA "
    },
    {
      "Location ID": "29045",
      "Location Name": "ELGIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1100 CHURCH ST ELGIN SC 29045 USA "
    },
    {
      "Location ID": "29046",
      "Location Name": "ELLIOTT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1783 ELLIOTT HWY ELLIOTT SC 29046 USA "
    },
    {
      "Location ID": "29047",
      "Location Name": "ELLOREE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6417 OLD NUMBER SIX HWY ELLOREE SC 29047 USA "
    },
    {
      "Location ID": "29048",
      "Location Name": "EUTAWVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 313 PORCHER AVE EUTAWVILLE SC 29048 USA "
    },
    {
      "Location ID": "29051",
      "Location Name": "GABLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12306 HWY 301 GABLE SC 29051 USA "
    },
    {
      "Location ID": "29052",
      "Location Name": "GADSDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7731 BLUFF RD GADSDEN SC 29052 USA "
    },
    {
      "Location ID": "29053",
      "Location Name": "GASTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 S MAIN ST GASTON SC 29053 USA "
    },
    {
      "Location ID": "29054",
      "Location Name": "GILBERT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 BROAD ST GILBERT SC 29054 USA "
    },
    {
      "Location ID": "29055",
      "Location Name": "GREAT FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 649 DEARBORN ST GREAT FALLS SC 29055 USA "
    },
    {
      "Location ID": "29056",
      "Location Name": "GREELEYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 C E MURRAY BLVD GREELEYVILLE SC 29056 USA "
    },
    {
      "Location ID": "29058",
      "Location Name": "HEATH SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 N MAIN ST HEATH SPRINGS SC 29058 USA "
    },
    {
      "Location ID": "29059",
      "Location Name": "HOLLY HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 525 GARDNER BLVD HOLLY HILL SC 29059 USA "
    },
    {
      "Location ID": "29061",
      "Location Name": "HOPKINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6200 LOWER RICHLAND BLVD HOPKINS SC 29061 USA "
    },
    {
      "Location ID": "29062",
      "Location Name": "HORATIO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3240 HORATIO HAGOOD RD HORATIO SC 29062 USA "
    },
    {
      "Location ID": "29063",
      "Location Name": "IRMO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7821 SAINT ANDREWS RD IRMO SC 29063 USA "
    },
    {
      "Location ID": "29065",
      "Location Name": "JENKINSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11298 STATE HIGHWAY 215 S JENKINSVILLE SC 29065 USA "
    },
    {
      "Location ID": "29067",
      "Location Name": "KERSHAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 521 N MATSON ST KERSHAW SC 29067 USA "
    },
    {
      "Location ID": "29069",
      "Location Name": "LAMAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 MAIN ST LAMAR SC 29069 USA "
    },
    {
      "Location ID": "29070",
      "Location Name": "BATESBURG LEESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 E CHURCH ST LEESVILLE SC 29070 USA "
    },
    {
      "Location ID": "29072",
      "Location Name": "DOWNTOWN LEXINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 710 W MAIN LEXINGTON SC 29071 USA "
    },
    {
      "Location ID": "29073",
      "Location Name": "LEXINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1830 S LAKE DR LEXINGTON SC 29073 USA "
    },
    {
      "Location ID": "29074",
      "Location Name": "LIBERTY HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4049 JOHN G RICHARDS RD LIBERTY HILL SC 29074 USA "
    },
    {
      "Location ID": "29075",
      "Location Name": "LITTLE MOUNTAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5953 WHEELAND RD LITTLE MOUNTAIN SC 29075 USA "
    },
    {
      "Location ID": "29078",
      "Location Name": "LUGOFF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 PLAZA DR LUGOFF SC 29078 USA "
    },
    {
      "Location ID": "29079",
      "Location Name": "LYDIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5179 INDIAN BRANCH RD LYDIA SC 29079 USA "
    },
    {
      "Location ID": "29080",
      "Location Name": "LYNCHBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 85 MAGNOLIA ST LYNCHBURG SC 29080 USA "
    },
    {
      "Location ID": "29081",
      "Location Name": "EHRHARDT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13443 BROXTON BRIDGE RD EHRHARDT SC 29081 USA "
    },
    {
      "Location ID": "29082",
      "Location Name": "LODGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21080 BELLS HWY LODGE SC 29082 USA "
    },
    {
      "Location ID": "290AA",
      "Location Name": "KING HAIGLER STATION, SC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2929 LIBERTY HILL RD CAMDEN SC 29020 USA "
    },
    {
      "Location ID": "290AX",
      "Location Name": "COLUMBIA CHRISTMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 610 KELSEY CT STE 500 WEST COLUMBIA SC 29172 USA "
    },
    {
      "Location ID": "290GS",
      "Location Name": "G&S INVESTMENTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 285 FRENCH COLLINS RD CONWAY SC 29526 USA "
    },
    {
      "Location ID": "29101",
      "Location Name": "MC BEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 56 W PINE AVE MC BEE SC 29101 USA "
    },
    {
      "Location ID": "29102",
      "Location Name": "MANNING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 W KEITT ST MANNING SC 29102 USA "
    },
    {
      "Location ID": "29104",
      "Location Name": "MAYESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4650 FLORENCE HWY MAYESVILLE SC 29104 USA "
    },
    {
      "Location ID": "29105",
      "Location Name": "MONETTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 230 MAIN ST MONETTA SC 29105 USA "
    },
    {
      "Location ID": "29107",
      "Location Name": "NEESES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 323 RICE ST NEESES SC 29107 USA "
    },
    {
      "Location ID": "29108",
      "Location Name": "NEWBERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1321 COLLEGE ST NEWBERRY SC 29108 USA "
    },
    {
      "Location ID": "29111",
      "Location Name": "NEW ZION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6702 SALEM RD NEW ZION SC 29111 USA "
    },
    {
      "Location ID": "29112",
      "Location Name": "NORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7903 SALLEY RD NORTH SC 29112 USA "
    },
    {
      "Location ID": "29113",
      "Location Name": "NORWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8414 SAVANNAH HWY NORWAY SC 29113 USA "
    },
    {
      "Location ID": "29114",
      "Location Name": "OLANTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 219 N JONES RD OLANTA SC 29114 USA "
    },
    {
      "Location ID": "29115",
      "Location Name": "ORANGEBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1550 MIDDLETON ST ORANGEBURG SC 29115 USA "
    },
    {
      "Location ID": "29122",
      "Location Name": "PEAK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 RIVER ST PEAK SC 29122 USA "
    },
    {
      "Location ID": "29123",
      "Location Name": "PELION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 739 PINE ST PELION SC 29123 USA "
    },
    {
      "Location ID": "29125",
      "Location Name": "PINEWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 E CLARK ST PINEWOOD SC 29125 USA "
    },
    {
      "Location ID": "29126",
      "Location Name": "POMARIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5375 US HIGHWAY 176 POMARIA SC 29126 USA "
    },
    {
      "Location ID": "29127",
      "Location Name": "PROSPERITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 155 N MAIN ST PROSPERITY SC 29127 USA "
    },
    {
      "Location ID": "29128",
      "Location Name": "REMBERT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8335 CAMDEN HWY REMBERT SC 29128 USA "
    },
    {
      "Location ID": "29129",
      "Location Name": "RIDGE SPRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 610 E MAIN ST RIDGE SPRING SC 29129 USA "
    },
    {
      "Location ID": "29130",
      "Location Name": "RIDGEWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 290 S PALMER ST RIDGEWAY SC 29130 USA "
    },
    {
      "Location ID": "29132",
      "Location Name": "RION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 243 RION RD RION SC 29132 USA "
    },
    {
      "Location ID": "29133",
      "Location Name": "ROWESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 219 ROWES PUMP DR ROWESVILLE SC 29133 USA "
    },
    {
      "Location ID": "29135",
      "Location Name": "SAINT MATTHEWS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 616 F R HUFF DR SAINT MATTHEWS SC 29135 USA "
    },
    {
      "Location ID": "29137",
      "Location Name": "SALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 RAILROAD AVE N SALLEY SC 29137 USA "
    },
    {
      "Location ID": "29138",
      "Location Name": "SALUDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 424 TRAVIS AVE SALUDA SC 29138 USA "
    },
    {
      "Location ID": "29142",
      "Location Name": "SANTEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 689 BASS DR SANTEE SC 29142 USA "
    },
    {
      "Location ID": "29143",
      "Location Name": "SARDINIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " US POSTAL SERVICE SARDINIA SC 29143 USA "
    },
    {
      "Location ID": "29145",
      "Location Name": "SILVERSTREET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1195 MAIN ST SILVERSTREET SC 29145 USA "
    },
    {
      "Location ID": "29146",
      "Location Name": "SPRINGFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7220 FESTIVAL TRAIL RD SPRINGFIELD SC 29146 USA "
    },
    {
      "Location ID": "29147",
      "Location Name": "STATE PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 HINTON ST STATE PARK SC 29147 USA "
    },
    {
      "Location ID": "29148",
      "Location Name": "SUMMERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 LARRY KING HWY SUMMERTON SC 29148 USA "
    },
    {
      "Location ID": "29150",
      "Location Name": "SUMTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 311 N MAIN ST SUMTER SC 29150 USA "
    },
    {
      "Location ID": "29152",
      "Location Name": "SHAW AFB",
      "Time Zone Offset (Hours)": 0,
      "Address": " 510 NELSON AVE SHAW AFB SC 29152 USA "
    },
    {
      "Location ID": "29160",
      "Location Name": "SWANSEA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 295 S CHURCH ST SWANSEA SC 29160 USA "
    },
    {
      "Location ID": "29161",
      "Location Name": "TIMMONSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 203 E MAIN ST TIMMONSVILLE SC 29161 USA "
    },
    {
      "Location ID": "29162",
      "Location Name": "TURBEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1290 CLARENCE COKER HWY TURBEVILLE SC 29162 USA "
    },
    {
      "Location ID": "29163",
      "Location Name": "VANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 450 CAMDEN RD VANCE SC 29163 USA "
    },
    {
      "Location ID": "29164",
      "Location Name": "WAGENER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 171 PARK ST NE WAGENER SC 29164 USA "
    },
    {
      "Location ID": "29166",
      "Location Name": "WARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 E FRONT ST WARD SC 29166 USA "
    },
    {
      "Location ID": "29168",
      "Location Name": "WEDGEFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2385 HIGHWAY 261 S WEDGEFIELD SC 29168 USA "
    },
    {
      "Location ID": "29175",
      "Location Name": "WESTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3141 KERSHAW HWY WESTVILLE SC 29175 USA "
    },
    {
      "Location ID": "29177",
      "Location Name": "WHITE ROCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1947 DUTCH FORK RD WHITE ROCK SC 29177 USA "
    },
    {
      "Location ID": "29178",
      "Location Name": "WHITMIRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 MAIN ST WHITMIRE SC 29178 USA "
    },
    {
      "Location ID": "29180",
      "Location Name": "WINNSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 144 N CONGRESS ST WINNSBORO SC 29180 USA "
    },
    {
      "Location ID": "291BB",
      "Location Name": "BROME BIRD CARE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 SOUTHERN PATIO PKWY ROWESVILLE SC 29133 USA "
    },
    {
      "Location ID": "29301",
      "Location Name": "WESTSIDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 520 W BLACKSTOCK RD SPARTANBURG SC 29301 USA "
    },
    {
      "Location ID": "29306",
      "Location Name": "SPARTANBURG MPO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 S CHURCH ST SPARTANBURG SC 29306 USA "
    },
    {
      "Location ID": "29316",
      "Location Name": "BOILING SPRINGS STA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1934 BOILING SPRINGS RD SPARTANBURG SC 29316 USA "
    },
    {
      "Location ID": "29320",
      "Location Name": "ARCADIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1893 HAYNE ST ARCADIA SC 29320 USA "
    },
    {
      "Location ID": "29321",
      "Location Name": "BUFFALO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 857 MAIN ST BUFFALO SC 29321 USA "
    },
    {
      "Location ID": "29322",
      "Location Name": "CAMPOBELLO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 S MAIN ST CAMPOBELLO SC 29322 USA "
    },
    {
      "Location ID": "29323",
      "Location Name": "CHESNEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 E CHESTER ST CHESNEE SC 29323 USA "
    },
    {
      "Location ID": "29324",
      "Location Name": "CLIFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1017 COWPENS CLIFTON RD CLIFTON SC 29324 USA "
    },
    {
      "Location ID": "29325",
      "Location Name": "CLINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 ELIZABETH ST CLINTON SC 29325 USA "
    },
    {
      "Location ID": "29329",
      "Location Name": "CONVERSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 E MAIN ST CONVERSE SC 29329 USA "
    },
    {
      "Location ID": "29330",
      "Location Name": "COWPENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 W CHURCH ST COWPENS SC 29330 USA "
    },
    {
      "Location ID": "29331",
      "Location Name": "CROSS ANCHOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 635 UNION HWY CROSS ANCHOR SC 29331 USA "
    },
    {
      "Location ID": "29332",
      "Location Name": "CROSS HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 968 N MAIN ST CROSS HILL SC 29332 USA "
    },
    {
      "Location ID": "29333",
      "Location Name": "DRAYTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1955 DRAYTON RD DRAYTON SC 29333 USA "
    },
    {
      "Location ID": "29334",
      "Location Name": "DUNCAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 131 W MAIN ST DUNCAN SC 29334 USA "
    },
    {
      "Location ID": "29335",
      "Location Name": "ENOREE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1369 PARKER RD ENOREE SC 29335 USA "
    },
    {
      "Location ID": "29336",
      "Location Name": "FAIRFOREST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2435 FAIRFOREST CLEVEDALE RD FAIRFOREST SC 29336 USA "
    },
    {
      "Location ID": "29338",
      "Location Name": "FINGERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10275 FRANKLIN ST FINGERVILLE SC 29338 USA "
    },
    {
      "Location ID": "29341",
      "Location Name": "GAFFNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1208 W FLOYD BAKER BLVD GAFFNEY SC 29341 USA "
    },
    {
      "Location ID": "29346",
      "Location Name": "GLENDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 350 BROADWAY ST GLENDALE SC 29346 USA "
    },
    {
      "Location ID": "29348",
      "Location Name": "GRAMLING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14921 ASHEVILLE HWY GRAMLING SC 29348 USA "
    },
    {
      "Location ID": "29349",
      "Location Name": "INMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 BLACKSTOCK RD INMAN SC 29349 USA "
    },
    {
      "Location ID": "29353",
      "Location Name": "JONESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2912 FURMAN L FENDLEY HWY JONESVILLE SC 29353 USA "
    },
    {
      "Location ID": "29356",
      "Location Name": "LANDRUM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 E RUTHERFORD ST LANDRUM SC 29356 USA "
    },
    {
      "Location ID": "29360",
      "Location Name": "LAURENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 302 N HARPER ST LAURENS SC 29360 USA "
    },
    {
      "Location ID": "29364",
      "Location Name": "LOCKHART",
      "Time Zone Offset (Hours)": 0,
      "Address": " 404 S 2ND ST LOCKHART SC 29364 USA "
    },
    {
      "Location ID": "29365",
      "Location Name": "LYMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12490 GREENVILLE HWY LYMAN SC 29365 USA "
    },
    {
      "Location ID": "29368",
      "Location Name": "MAYO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4496 CHESNEE HWY MAYO SC 29368 USA "
    },
    {
      "Location ID": "29369",
      "Location Name": "MOORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1129 CENTER POINT DR MOORE SC 29369 USA "
    },
    {
      "Location ID": "29370",
      "Location Name": "MOUNTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1466 MOUNTVILLE RD MOUNTVILLE SC 29370 USA "
    },
    {
      "Location ID": "29372",
      "Location Name": "PACOLET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 W MAIN ST PACOLET SC 29372 USA "
    },
    {
      "Location ID": "29373",
      "Location Name": "PACOLET MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 MONTGOMERY AVE PACOLET MILLS SC 29373 USA "
    },
    {
      "Location ID": "29374",
      "Location Name": "PAULINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2990 HIGHWAY 56 PAULINE SC 29374 USA "
    },
    {
      "Location ID": "29375",
      "Location Name": "REIDVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7460 REIDVILLE RD REIDVILLE SC 29375 USA "
    },
    {
      "Location ID": "29376",
      "Location Name": "ROEBUCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4301 S CHURCH STREET EXT ROEBUCK SC 29376 USA "
    },
    {
      "Location ID": "29377",
      "Location Name": "STARTEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 N MAIN ST STARTEX SC 29377 USA "
    },
    {
      "Location ID": "29378",
      "Location Name": "UNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 331 SIBLEY ST UNA SC 29378 USA "
    },
    {
      "Location ID": "29379",
      "Location Name": "UNION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 804 INDUSTRIAL PARK RD UNION SC 29379 USA "
    },
    {
      "Location ID": "29384",
      "Location Name": "WATERLOO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11219 HIGHWAY 221 S WATERLOO SC 29384 USA "
    },
    {
      "Location ID": "29385",
      "Location Name": "WELLFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1003 MAIN ST WELLFORD SC 29385 USA "
    },
    {
      "Location ID": "29386",
      "Location Name": "WHITE STONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3430 SOUTHPORT RD WHITE STONE SC 29386 USA "
    },
    {
      "Location ID": "29388",
      "Location Name": "WOODRUFF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 955 N MAIN ST WOODRUFF SC 29388 USA "
    },
    {
      "Location ID": "293AS",
      "Location Name": "ADIDAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 495 CEDAR CREST RD SPARTANBURG SC 29301 USA "
    },
    {
      "Location ID": "293AZ",
      "Location Name": "AMAZON GSP1",
      "Time Zone Offset (Hours)": 0,
      "Address": " 402 JOHN DODD RD SPARTANBURG SC 29303 USA "
    },
    {
      "Location ID": "293BF",
      "Location Name": "BELKS FULLFILLMENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3805 FURMAN L FENDLEY HWY JONESVILLE SC 29353 USA "
    },
    {
      "Location ID": "293NE",
      "Location Name": "NEWARK ELECTRONICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 217 WILCOX AVE GAFFNEY SC 29641 USA "
    },
    {
      "Location ID": "293PS",
      "Location Name": "PITNEY BOWES PRESORT SVCS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 240 COMMERCE CT DUNCAN SC 29334 USA "
    },
    {
      "Location ID": "293RA",
      "Location Name": "ROBERT ALLEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 PEACHVIEW RD GAFFNEY SC 29341 USA "
    },
    {
      "Location ID": "293RD",
      "Location Name": "RR DONNELLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 JONES RD SPARTANBURG SC 29307 USA "
    },
    {
      "Location ID": "293TC",
      "Location Name": "TCP GLOBAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 191 RIDGEVIEW CIR DUNCAN SC 29334 USA "
    },
    {
      "Location ID": "293UH",
      "Location Name": "UNITED HEALTH GROUP PRINT SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 170 PARKWAY W STE 180 DUNCAN SC 29334 USA "
    },
    {
      "Location ID": "293WM",
      "Location Name": "WALMARTDOTCOM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1050 VERN CORA RD LAURENS SC 29360 USA "
    },
    {
      "Location ID": "294",
      "Location Name": "LOG CHARLESTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7075 CROSS COUNTY RD NORTH CHARLESTON SC 29418 USA "
    },
    {
      "Location ID": "29426",
      "Location Name": "ADAMS RUN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5190 HIGHWAY 174 ADAMS RUN SC 29426 USA "
    },
    {
      "Location ID": "29429",
      "Location Name": "AWENDAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6201 N HIGHWAY 17 AWENDAW SC 29429 USA "
    },
    {
      "Location ID": "29431",
      "Location Name": "BONNEAU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 CEDAR ST BONNEAU SC 29431 USA "
    },
    {
      "Location ID": "29432",
      "Location Name": "BRANCHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 EDWARD ST BRANCHVILLE SC 29432 USA "
    },
    {
      "Location ID": "29434",
      "Location Name": "CORDESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2732 HIGHWAY 402 CORDESVILLE SC 29434 USA "
    },
    {
      "Location ID": "29435",
      "Location Name": "COTTAGEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10969 COTTAGEVILLE HWY COTTAGEVILLE SC 29435 USA "
    },
    {
      "Location ID": "29436",
      "Location Name": "CROSS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1836 RANGER DR CROSS SC 29436 USA "
    },
    {
      "Location ID": "29437",
      "Location Name": "DORCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2650 HIGHWAY 78 DORCHESTER SC 29437 USA "
    },
    {
      "Location ID": "29438",
      "Location Name": "EDISTO ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 539 HIGHWAY 174 EDISTO ISLAND SC 29438 USA "
    },
    {
      "Location ID": "29439",
      "Location Name": "FOLLY BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 E INDIAN AVE FOLLY BEACH SC 29439 USA "
    },
    {
      "Location ID": "29440",
      "Location Name": "GEORGETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1101 CHARLOTTE ST GEORGETOWN SC 29440 USA "
    },
    {
      "Location ID": "29445",
      "Location Name": "GOOSE CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 307 N GOOSE CREEK BLVD GOOSE CREEK SC 29445 USA "
    },
    {
      "Location ID": "29446",
      "Location Name": "GREEN POND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12225 GREEN POND HWY GREEN POND SC 29446 USA "
    },
    {
      "Location ID": "29448",
      "Location Name": "HARLEYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 E MAIN ST HARLEYVILLE SC 29448 USA "
    },
    {
      "Location ID": "29449",
      "Location Name": "HOLLYWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6345 HIGHWAY 162 HOLLYWOOD SC 29449 USA "
    },
    {
      "Location ID": "29450",
      "Location Name": "HUGER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3282 HIGHWAY 41 HUGER SC 29450 USA "
    },
    {
      "Location ID": "29451",
      "Location Name": "ISLE OF PALMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 PALM BLVD ISLE OF PALMS SC 29451 USA "
    },
    {
      "Location ID": "29452",
      "Location Name": "JACKSONBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16534 ACE BASIN PKWY JACKSONBORO SC 29452 USA "
    },
    {
      "Location ID": "29455",
      "Location Name": "JOHNS ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2860 MAYBANK HWY JOHNS ISLAND SC 29455 USA "
    },
    {
      "Location ID": "29456",
      "Location Name": "LADSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3213 LADSON RD LADSON SC 29456 USA "
    },
    {
      "Location ID": "29458",
      "Location Name": "MC CLELLANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 959 S PINCKNEY ST MC CLELLANVILLE SC 29458 USA "
    },
    {
      "Location ID": "29461",
      "Location Name": "MONCKS CORNER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 N HIGHWAY 52 MONCKS CORNER SC 29461 USA "
    },
    {
      "Location ID": "29464",
      "Location Name": "MOUNT PLEASANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1050 JOHNNIE DODDS BLVD MOUNT PLEASANT SC 29464 USA "
    },
    {
      "Location ID": "29468",
      "Location Name": "PINEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2080 HIGHWAY 45 PINEVILLE SC 29468 USA "
    },
    {
      "Location ID": "29469",
      "Location Name": "PINOPOLIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1940 PINOPOLIS RD PINOPOLIS SC 29469 USA "
    },
    {
      "Location ID": "29470",
      "Location Name": "RAVENEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6239 SAVANNAH HWY RAVENEL SC 29470 USA "
    },
    {
      "Location ID": "29471",
      "Location Name": "REEVESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 RIGBY ST REEVESVILLE SC 29471 USA "
    },
    {
      "Location ID": "29472",
      "Location Name": "RIDGEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 185 CHURCH ST RIDGEVILLE SC 29472 USA "
    },
    {
      "Location ID": "29474",
      "Location Name": "ROUND O",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11110 ROUND O RD ROUND O SC 29474 USA "
    },
    {
      "Location ID": "29475",
      "Location Name": "RUFFIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21972 LOWCOUNTRY HWY RUFFIN SC 29475 USA "
    },
    {
      "Location ID": "29476",
      "Location Name": "RUSSELLVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1737 HIGHWAY 35 RUSSELLVILLE SC 29476 USA "
    },
    {
      "Location ID": "29477",
      "Location Name": "SAINT GEORGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5988 W JIM BILTON BLVD SAINT GEORGE SC 29477 USA "
    },
    {
      "Location ID": "29479",
      "Location Name": "SAINT STEPHEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3981 BYRNES DR SAINT STEPHEN SC 29479 USA "
    },
    {
      "Location ID": "29481",
      "Location Name": "SMOAKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27639 LOWCOUNTRY HWY SMOAKS SC 29481 USA "
    },
    {
      "Location ID": "29482",
      "Location Name": "SULLIVANS ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2061 MIDDLE ST SULLIVANS ISLAND SC 29482 USA "
    },
    {
      "Location ID": "29483",
      "Location Name": "SUMMERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 N GUM ST SUMMERVILLE SC 29483 USA "
    },
    {
      "Location ID": "29485",
      "Location Name": "OAKBROOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10070 DORCHESTER RD SUMMERVILLE SC 29485 USA "
    },
    {
      "Location ID": "29487",
      "Location Name": "WADMALAW ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1917 LIBERIA RD WADMALAW ISLAND SC 29487 USA "
    },
    {
      "Location ID": "29488",
      "Location Name": "WALTERBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 333 E WASHINGTON ST WALTERBORO SC 29488 USA "
    },
    {
      "Location ID": "29493",
      "Location Name": "WILLIAMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1718 GEORGE WARREN DR WILLIAMS SC 29493 USA "
    },
    {
      "Location ID": "294AX",
      "Location Name": "CHARLESTON PEAK ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5056 INTERNATIONAL BLVD N CHARLESTON SC 29418 USA "
    },
    {
      "Location ID": "295",
      "Location Name": "FLORENCE P&DF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1901 W EVANS ST FLORENCE SC 29501 USA "
    },
    {
      "Location ID": "29504",
      "Location Name": "SOUTH PARK STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 510 2ND LOOP RD FLORENCE SC 29505 USA "
    },
    {
      "Location ID": "29505",
      "Location Name": "SOUTH FLORENCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 450 2ND LOOP RD FLORENCE SC 29505 USA "
    },
    {
      "Location ID": "29506",
      "Location Name": "QUINBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 625 E ASHBY RD FLORENCE SC 29506 USA "
    },
    {
      "Location ID": "29510",
      "Location Name": "ANDREWS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 N MORGAN AVE ANDREWS SC 29510-9998 USA "
    },
    {
      "Location ID": "29511",
      "Location Name": "AYNOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2941 HWY 501 EAST AYNOR SC 29511-9998 USA "
    },
    {
      "Location ID": "29512",
      "Location Name": "BENNETTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 208 W MAIN ST BENNETTSVILLE SC 29512-9998 USA "
    },
    {
      "Location ID": "29516",
      "Location Name": "BLENHEIM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 W HIGH ST BLENHEIM SC 29516-9998 USA "
    },
    {
      "Location ID": "29518",
      "Location Name": "CADES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3116 WILLIAMSBURG COUNTY HWY CADES SC 29518-3499 USA "
    },
    {
      "Location ID": "29519",
      "Location Name": "CENTENARY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4811 S HIGHWAY 41A CENTENARY SC 29519-5000 USA "
    },
    {
      "Location ID": "29520",
      "Location Name": "CHERAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 240 MARKET ST CHERAW SC 29520-9997 USA "
    },
    {
      "Location ID": "29525",
      "Location Name": "CLIO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 RED BLUFF ST CLIO SC 29525-9998 USA "
    },
    {
      "Location ID": "29526",
      "Location Name": "CONWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2570 MAIN ST CONWAY SC 29526 USA "
    },
    {
      "Location ID": "29530",
      "Location Name": "COWARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3701 US 52 HWY COWARD SC 29530-9998 USA "
    },
    {
      "Location ID": "29532",
      "Location Name": "DARLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 E SMITH AVE DARLINGTON SC 29532 USA "
    },
    {
      "Location ID": "29536",
      "Location Name": "DILLON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 W MAIN ST DILLON SC 29536-9998 USA "
    },
    {
      "Location ID": "29541",
      "Location Name": "EFFINGHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5913 S IRBY ST EFFINGHAM SC 29541-9998 USA "
    },
    {
      "Location ID": "29543",
      "Location Name": "FORK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4114 HWY 57 S FORK SC 29543-9998 USA "
    },
    {
      "Location ID": "29545",
      "Location Name": "GREEN SEA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3720 MITCHELL C RD GREEN SEA SC 29545-9998 USA "
    },
    {
      "Location ID": "29546",
      "Location Name": "GRESHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7027 HWY 908 GRESHAM SC 29546-9998 USA "
    },
    {
      "Location ID": "29547",
      "Location Name": "HAMER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20809 HWY 301 N HAMER SC 29546-9998 USA "
    },
    {
      "Location ID": "29550",
      "Location Name": "HARTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 S 4TH ST HARTSVILLE SC 29550-9998 USA "
    },
    {
      "Location ID": "29554",
      "Location Name": "HEMINGWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 E BROAD ST HEMINGWAY SC 29554-9998 USA "
    },
    {
      "Location ID": "29555",
      "Location Name": "JOHNSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 417 N GEORGETOWN HWY JOHNSONVILLE SC 29555-9998 USA "
    },
    {
      "Location ID": "29556",
      "Location Name": "KINGSTREE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 129 W MILL ST KINGSTREE SC 29556-9998 USA "
    },
    {
      "Location ID": "29560",
      "Location Name": "LAKE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 226 W MAIN ST LAKE CITY SC 29560-9998 USA "
    },
    {
      "Location ID": "29563",
      "Location Name": "LAKE VIEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 211 RICHARD TEMPLE BLVD LAKE VIEW SC 29563-9998 USA "
    },
    {
      "Location ID": "29564",
      "Location Name": "LANE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 341 S LANE RD LANE SC 29564-9998 USA "
    },
    {
      "Location ID": "29565",
      "Location Name": "LATTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 W MAIN ST LATTA SC 29565-9998 USA "
    },
    {
      "Location ID": "29566",
      "Location Name": "LITTLE RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4299 HORSESHOE RD N LITTLE RIVER SC 29566 USA "
    },
    {
      "Location ID": "29567",
      "Location Name": "LITTLE ROCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1403 HARLLEES BRIDGE RD LITTLE ROCK SC 29567-9998 USA "
    },
    {
      "Location ID": "29568",
      "Location Name": "LONGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 HIGHWAY 9 E LONGS SC 29568 USA "
    },
    {
      "Location ID": "29569",
      "Location Name": "LORIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3085 BROAD ST LORIS SC 29569 USA "
    },
    {
      "Location ID": "29570",
      "Location Name": "MCCOLL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 N MAIN ST MCCOLL SC 29570-9998 USA "
    },
    {
      "Location ID": "29571",
      "Location Name": "MARION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 S MAIN ST MARION SC 29571-9998 USA "
    },
    {
      "Location ID": "29572",
      "Location Name": "LIVE OAK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 820 67TH AVE N MYRTLE BEACH SC 29572 USA "
    },
    {
      "Location ID": "29574",
      "Location Name": "MULLINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 320 N MAIN ST MULLINS SC 29574-9998 USA "
    },
    {
      "Location ID": "29575",
      "Location Name": "SURFSIDE BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 420 HIGHWAY 17 N SURFSIDE BEACH SC 29575 USA "
    },
    {
      "Location ID": "29576",
      "Location Name": "MURRELLS INLET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 654 BELLAMY AVE MURRELLS INLET SC 29576 USA "
    },
    {
      "Location ID": "29577",
      "Location Name": "MYRTLE BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 505 N KINGS HWY MYRTLE BEACH SC 29577 USA "
    },
    {
      "Location ID": "29580",
      "Location Name": "NESMITH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 MINGO CHANDLER RD NESMITH SC 29580-9998 USA "
    },
    {
      "Location ID": "29581",
      "Location Name": "NICHOLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 S NICHOLS ST NICHOLS SC 29581-9998 USA "
    },
    {
      "Location ID": "29582",
      "Location Name": "NORTH MYRTLE BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 621 6TH AVE S NORTH MYRTLE BEACH SC 29582 USA "
    },
    {
      "Location ID": "29583",
      "Location Name": "PAMPLICO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 132 S TRADE ST PAMPLICO SC 29583-9998 USA "
    },
    {
      "Location ID": "29584",
      "Location Name": "PATRICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 S MAIN ST PATRICK SC 29584-9998 USA "
    },
    {
      "Location ID": "29585",
      "Location Name": "PAWLEYS ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10993 OCEAN HWY PAWLEYS ISLAND SC 29585 USA "
    },
    {
      "Location ID": "29588",
      "Location Name": "SOCASTEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7140 SC-707 MYRTLE BEACH SC 29588 USA "
    },
    {
      "Location ID": "29589",
      "Location Name": "RAINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2677 TROY ATKINSON RD RAINS SC 29589-9998 USA "
    },
    {
      "Location ID": "29590",
      "Location Name": "SALTERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 DEPOT ST SALTERS SC 29590-9998 USA "
    },
    {
      "Location ID": "29591",
      "Location Name": "SCRANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1810 US 52 HWY SCRANTON SC 29591-9998 USA "
    },
    {
      "Location ID": "29592",
      "Location Name": "SELLERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2806 HWY 301 SELLERS SC 29592-9998 USA "
    },
    {
      "Location ID": "29593",
      "Location Name": "SOCIETY HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 269 S MAIN ST SOCIETY HILL SC 29593-9998 USA "
    },
    {
      "Location ID": "29594",
      "Location Name": "TATUM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 N STANTON ST TATUM SC 29594-9800 USA "
    },
    {
      "Location ID": "29596",
      "Location Name": "WALLACE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4130 POST OFFICE DR WALLACE SC 29596-9998 USA "
    },
    {
      "Location ID": "29598",
      "Location Name": "COASTAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4009 HIGHWAY 17 S NORTH MYRTLE BEACH SC 29582 USA "
    },
    {
      "Location ID": "295AH",
      "Location Name": "ABBY'S HARBOR DODGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4849 HIGHWAY 501 MYRTLE BEACH SC 29579 USA "
    },
    {
      "Location ID": "295CA",
      "Location Name": "CHERAW CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 CALVARY RD CHERAW SC 29520-9998 USA "
    },
    {
      "Location ID": "295CF",
      "Location Name": "CAROLINA FOREST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4025 POSTAL WAY MYRTLE BEACH SC 29579 USA "
    },
    {
      "Location ID": "295FS",
      "Location Name": "FEDERAL STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 W EVANS ST FLORENCE SC 29501 USA "
    },
    {
      "Location ID": "295HR",
      "Location Name": "HIPPIE RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2879 WACCAMAW COVE  LONGS SC 29568 USA "
    },
    {
      "Location ID": "295LA",
      "Location Name": "LAUDISI DISTRIBUTION GROUP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 550 HWY 9 E LONGS SC 29568-8788 USA "
    },
    {
      "Location ID": "295LD",
      "Location Name": "L D BAGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3425 HIGHWAY 17 BUSINESS MURRELLS INLT SC 29576 USA "
    },
    {
      "Location ID": "295LP",
      "Location Name": "LAWNMOWER PARTS WORLD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5693 WESTMORELAND RD CONWAY SC 29526 USA "
    },
    {
      "Location ID": "295RR",
      "Location Name": "WILD WEST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3683 RALPH ELLIS BLVD LORIS SC 29569 USA "
    },
    {
      "Location ID": "295SW",
      "Location Name": "SHED WINDOWS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9326 HIGHWAY 90 LONGS SC 29568-6222 USA "
    },
    {
      "Location ID": "295TW",
      "Location Name": "TUPPERWARE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 248 TUPPERWARE RD HEMINGWAY SC 29554-5594 USA "
    },
    {
      "Location ID": "295WB",
      "Location Name": "WHOLESALE BOUTIQUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10607 HIGHWAY 707 UNIT 140 MYRTLE BEACH SC 29588 USA "
    },
    {
      "Location ID": "295WI",
      "Location Name": "WILD WEST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 CAROLINA RD CONWAY SC 29526 USA "
    },
    {
      "Location ID": "295WW",
      "Location Name": "WILD WEST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 802 HIGHWAY 17 S SURFSIDE BCH SC 29575 USA "
    },
    {
      "Location ID": "296",
      "Location Name": "GREENVILLE P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 204 FAIRFOREST WAY GREENVILLE SC 29607 USA "
    },
    {
      "Location ID": "29601",
      "Location Name": "GREENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 304 CALVIN ST GREENVILLE SC 29601 USA "
    },
    {
      "Location ID": "29602",
      "Location Name": "GREENVILLE MPO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 W WASHINGTON ST GREENVILLE SC 29602 USA "
    },
    {
      "Location ID": "29604",
      "Location Name": "STATION A",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 CONESTEE AVE GREENVILLE SC 29604 USA "
    },
    {
      "Location ID": "29606",
      "Location Name": "PLEASANTBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1521 LAURENS RD GREENVILLE SC 29606 USA "
    },
    {
      "Location ID": "29607",
      "Location Name": "GREENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 W WASHINGTON ST GREENVILLE SC 29602 USA "
    },
    {
      "Location ID": "29608",
      "Location Name": "PARK PLACE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2431 N PLEASANTBURG DR GREENVILLE SC 29608 USA "
    },
    {
      "Location ID": "29610",
      "Location Name": "BEREA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7500 WHITE HORSE RD GREENVILLE SC 29610 USA "
    },
    {
      "Location ID": "29613",
      "Location Name": "FURMAN UNIVERSITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3300 POINSETT HWY GREENVILLE SC 29613 USA "
    },
    {
      "Location ID": "29614",
      "Location Name": "BOB JONES UNIVERSITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1700 WADE HAMPTON RD GREENVILLE SC 29614 USA "
    },
    {
      "Location ID": "29615",
      "Location Name": "KEITH D OGLESBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 E ORCHARD PARK DR GREENVILLE SC 29616 USA "
    },
    {
      "Location ID": "29620",
      "Location Name": "ABBEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1503 N MAIN ST ABBEVILLE SC 29620 USA "
    },
    {
      "Location ID": "29621",
      "Location Name": "ANDERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1900B N MAIN ST ANDERSON SC 29621 USA "
    },
    {
      "Location ID": "29627",
      "Location Name": "BELTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 BREAZEALE ST BELTON SC 29627 USA "
    },
    {
      "Location ID": "29628",
      "Location Name": "CALHOUN FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 E SAVANNAH ST CALHOUN FALLS SC 29628 USA "
    },
    {
      "Location ID": "29630",
      "Location Name": "CENTRAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1205 W MAIN ST CENTRAL SC 29630 USA "
    },
    {
      "Location ID": "29631",
      "Location Name": "CLEMSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 519 COLLEGE AVE CLEMSON SC 29631 USA "
    },
    {
      "Location ID": "29635",
      "Location Name": "CLEVELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4031 GEER HWY CLEVELAND SC 29635 USA "
    },
    {
      "Location ID": "29636",
      "Location Name": "CONESTEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 MAIN ST CONESTEE SC 29636 USA "
    },
    {
      "Location ID": "29638",
      "Location Name": "DONALDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 W MAIN ST DONALDS SC 29638 USA "
    },
    {
      "Location ID": "29639",
      "Location Name": "DUE WEST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 WASHINGTON ST DUE WEST SC 29639 USA "
    },
    {
      "Location ID": "29640",
      "Location Name": "EASLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 810 S PENDLETON ST EASLEY SC 29640 USA "
    },
    {
      "Location ID": "29643",
      "Location Name": "FAIR PLAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 805 E FAIRPLAY BLVD FAIR PLAY SC 29643 USA "
    },
    {
      "Location ID": "29644",
      "Location Name": "FOUNTAIN INN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 290 MCCARTER RD FOUNTAIN INN SC 29644 USA "
    },
    {
      "Location ID": "29645",
      "Location Name": "GRAY COURT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 411 W MAIN ST GRAY COURT SC 29645 USA "
    },
    {
      "Location ID": "29647",
      "Location Name": "PARK SEED CO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 PARKTON AVE GREENWOOD SC 29647 USA "
    },
    {
      "Location ID": "29649",
      "Location Name": "GREENWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 240 N CREEK BLVD GREENWOOD SC 29646 USA "
    },
    {
      "Location ID": "29650",
      "Location Name": "GREER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 504 PENNSYLVANIA AVE GREER SC 29650 USA "
    },
    {
      "Location ID": "29653",
      "Location Name": "HODGES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 ROBERTSON ST HODGES SC 29653 USA "
    },
    {
      "Location ID": "29654",
      "Location Name": "HONEA PATH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 312 N SHIRLEY AVE HONEA PATH SC 29654 USA "
    },
    {
      "Location ID": "29655",
      "Location Name": "IVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 E GREEN ST IVA SC 29655 USA "
    },
    {
      "Location ID": "29656",
      "Location Name": "LA FRANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 MAIN ST LA FRANCE SC 29656 USA "
    },
    {
      "Location ID": "29657",
      "Location Name": "LIBERTY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 N COMMERCE ST LIBERTY SC 29657 USA "
    },
    {
      "Location ID": "29658",
      "Location Name": "LONG CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13851 LONG CREEK HWY LONG CREEK SC 29658 USA "
    },
    {
      "Location ID": "29659",
      "Location Name": "LOWNDESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 272 MAIN ST LOWNDESVILLE SC 29659 USA "
    },
    {
      "Location ID": "29661",
      "Location Name": "MARIETTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3047 GEER HWY MARIETTA SC 29661 USA "
    },
    {
      "Location ID": "29662",
      "Location Name": "MAULDIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 498 N MAIN ST MAULDIN SC 29662 USA "
    },
    {
      "Location ID": "29664",
      "Location Name": "MOUNTAIN REST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9601 HIGHLANDS HWY MOUNTAIN REST SC 29664 USA "
    },
    {
      "Location ID": "29665",
      "Location Name": "NEWRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 701A BROADWAY ST NEWRY SC 29665 USA "
    },
    {
      "Location ID": "29666",
      "Location Name": "NINETY SIX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 N CAMBRIDGE ST NINETY SIX SC 29666 USA "
    },
    {
      "Location ID": "29667",
      "Location Name": "NORRIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 S NORRIS DR NORRIS SC 29667 USA "
    },
    {
      "Location ID": "29669",
      "Location Name": "PELZER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 185 LEBBY ST PELZER SC 29669 USA "
    },
    {
      "Location ID": "29670",
      "Location Name": "PENDLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 891 S MECHANIC ST PENDLETON SC 29670 USA "
    },
    {
      "Location ID": "29671",
      "Location Name": "PICKENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 JOHNSON ST PICKENS SC 29671 USA "
    },
    {
      "Location ID": "29673",
      "Location Name": "PIEDMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 912 ANDERSON ST PIEDMONT SC 29673 USA "
    },
    {
      "Location ID": "29675",
      "Location Name": "RICHLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 304 RICHLAND RD RICHLAND SC 29675 USA "
    },
    {
      "Location ID": "29676",
      "Location Name": "SALEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 467 E MAIN ST SALEM SC 29676 USA "
    },
    {
      "Location ID": "29677",
      "Location Name": "SANDY SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5410 HIGHWAY 76 SANDY SPRINGS SC 29677 USA "
    },
    {
      "Location ID": "29678",
      "Location Name": "SENECA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 508 E MAIN ST SENECA SC 29678 USA "
    },
    {
      "Location ID": "29681",
      "Location Name": "SIMPSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 634 NE MAIN ST SIMPSONVILLE SC 29681 USA "
    },
    {
      "Location ID": "29682",
      "Location Name": "SIX MILE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 515 N MAIN ST SIX MILE SC 29682 USA "
    },
    {
      "Location ID": "29683",
      "Location Name": "SLATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 MAIN ST SLATER SC 29683 USA "
    },
    {
      "Location ID": "29684",
      "Location Name": "STARR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7842 HIGHWAY 81 S STARR SC 29684 USA "
    },
    {
      "Location ID": "29685",
      "Location Name": "SUNSET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7149 HIGHWAY 11 SUNSET SC 29685 USA "
    },
    {
      "Location ID": "29686",
      "Location Name": "TAMASSEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2655B CAIN DR TAMASSEE SC 29686-9998 USA "
    },
    {
      "Location ID": "29687",
      "Location Name": "TAYLORS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4359 WADE HAMPTON BLVD TAYLORS SC 29687 USA "
    },
    {
      "Location ID": "29688",
      "Location Name": "TIGERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7864 N TIGERVILLE RD TIGERVILLE SC 29688 USA "
    },
    {
      "Location ID": "29689",
      "Location Name": "TOWNVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8923 HIGHWAY 24 TOWNVILLE SC 29689 USA "
    },
    {
      "Location ID": "29690",
      "Location Name": "TRAVELERS REST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 S MAIN ST TRAVELERS REST SC 29690 USA "
    },
    {
      "Location ID": "29691",
      "Location Name": "WALHALLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 208 W NORTH BROAD ST WALHALLA SC 29691 USA "
    },
    {
      "Location ID": "29692",
      "Location Name": "WARE SHOALS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 MCLANE AVE WARE SHOALS SC 29692 USA "
    },
    {
      "Location ID": "29693",
      "Location Name": "WESTMINSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 800 E MAIN ST WESTMINSTER SC 29693 USA "
    },
    {
      "Location ID": "29696",
      "Location Name": "WEST UNION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 190 S HIGHWAY 11 WEST UNION SC 29696 USA "
    },
    {
      "Location ID": "29697",
      "Location Name": "WILLIAMSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 MILL ST WILLIAMSTON SC 29697 USA "
    },
    {
      "Location ID": "296AA",
      "Location Name": "FAIRVIEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 677 FAIRVIEW RD SIMPSONVILLE SC 29680 USA "
    },
    {
      "Location ID": "296AB",
      "Location Name": "MAGNOLIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 MAGNOLIA AVE GREENWOOD SC 29646 USA "
    },
    {
      "Location ID": "296BJ",
      "Location Name": "BOB JONES PUBLICATIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 134 WHITE OAK DR GREENVILLE SC 29607 USA "
    },
    {
      "Location ID": "296BO",
      "Location Name": "BREAKOUT BRAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 565 WOODDRUFF RD GREENVILLE SC 29607 USA "
    },
    {
      "Location ID": "296CL",
      "Location Name": "COMPX LOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 OLD MILL ROAD GREENVILLE SC 29607 USA "
    },
    {
      "Location ID": "296DO",
      "Location Name": "DIRECT OUTBOUND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 700 RIDGE RD GREENVILLE SC 29607 USA "
    },
    {
      "Location ID": "296FF",
      "Location Name": "FUJU FILM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 923 HYW 246 S GREENWOOD SC 29649-7908 USA "
    },
    {
      "Location ID": "296FW",
      "Location Name": "FRESH WATER SYSTEMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2299 RIDGE ROAD GREENVILLE SC 29607 USA "
    },
    {
      "Location ID": "296FX",
      "Location Name": "GSP FEDEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3080 GATEWAY DR GREER SC 29651 USA "
    },
    {
      "Location ID": "296GN",
      "Location Name": "GNC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4941 LIBERTY HWY ANDERSON SC 29621 USA "
    },
    {
      "Location ID": "296GW",
      "Location Name": "GOODWILL INDUSTRIES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 HAYWOOD RD GREENVILLE SC 29607 USA "
    },
    {
      "Location ID": "296MA",
      "Location Name": "MEDALS OF AMERICA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 SOUTHCHASE BLVD FOUNTAIN INN SC 29644 USA "
    },
    {
      "Location ID": "296ML",
      "Location Name": "MARLEY LILLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 W PHILLIPS RD GREER SC 29650 USA "
    },
    {
      "Location ID": "296MP",
      "Location Name": "MARTIN PRINTING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1743 POWDERSVILLE RD EASLEY SC 29640 USA "
    },
    {
      "Location ID": "296ND",
      "Location Name": "NEXT DAY MRO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3195 J VERNE SMITH PARKWAY GREER SC 29650 USA "
    },
    {
      "Location ID": "296PA",
      "Location Name": "LOG GREENVILLE SC MPA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3309 LAURENS RD GREENVILLE SC 29607 USA "
    },
    {
      "Location ID": "296RW",
      "Location Name": "REDEMPTION WORLD OUTREACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 74 BYRDLAND DR GREENVILLE SC 29607 USA "
    },
    {
      "Location ID": "296SD",
      "Location Name": "SUPER DUPER PUBLICATIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5201 PELHAM RD GREENVILLE SC 29615 USA "
    },
    {
      "Location ID": "296SE",
      "Location Name": "SC ELITE TECHNOLOGY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1200 WOODRUFF RD STE H25 GREENVILLE SC 29607 USA "
    },
    {
      "Location ID": "296TB",
      "Location Name": "TEXTBOOK BROKERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 489 S PLEASANTBURG DR GREENVILLE SC 29607 USA "
    },
    {
      "Location ID": "29702",
      "Location Name": "BLACKSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 W PINE ST BLACKSBURG SC 29702 USA "
    },
    {
      "Location ID": "29703",
      "Location Name": "BOWLING GREEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1771 N HIGHWAY 321 BOWLING GREEN SC 29703 USA "
    },
    {
      "Location ID": "29704",
      "Location Name": "CATAWBA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4795 CURETON FERRY RD CATAWBA SC 29704 USA "
    },
    {
      "Location ID": "29706",
      "Location Name": "CHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 131 SALUDA ST CHESTER SC 29706 USA "
    },
    {
      "Location ID": "29709",
      "Location Name": "CHESTERFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 E MAIN ST CHESTERFIELD SC 29709 USA "
    },
    {
      "Location ID": "29710",
      "Location Name": "CLOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 913 BETHEL ST CLOVER SC 29710 USA "
    },
    {
      "Location ID": "29712",
      "Location Name": "EDGEMOOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4469 EDGELAND RD EDGEMOOR SC 29712 USA "
    },
    {
      "Location ID": "29714",
      "Location Name": "FORT LAWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5915 LANCASTER HWY FORT LAWN SC 29714 USA "
    },
    {
      "Location ID": "29715",
      "Location Name": "FORT MILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 TOM HALL ST FORT MILL SC 29715 USA "
    },
    {
      "Location ID": "29718",
      "Location Name": "JEFFERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 S MAIN ST JEFFERSON SC 29718 USA "
    },
    {
      "Location ID": "29720",
      "Location Name": "LANCASTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 501 N MAIN ST LANCASTER SC 29720 USA "
    },
    {
      "Location ID": "29726",
      "Location Name": "MC CONNELLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4182 CHESTER HWY MC CONNELLS SC 29726 USA "
    },
    {
      "Location ID": "29727",
      "Location Name": "MOUNT CROGHAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 CAMDEN RD MOUNT CROGHAN SC 29727 USA "
    },
    {
      "Location ID": "29728",
      "Location Name": "PAGELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 207 N PEARL ST PAGELAND SC 29728 USA "
    },
    {
      "Location ID": "29729",
      "Location Name": "RICHBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 BROAD ST RICHBURG SC 29729 USA "
    },
    {
      "Location ID": "29730",
      "Location Name": "ROCK HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 WILSON ST S ROCK HILL SC 29730 USA "
    },
    {
      "Location ID": "29741",
      "Location Name": "RUBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 367 MARKET ST RUBY SC 29741 USA "
    },
    {
      "Location ID": "29744",
      "Location Name": "VAN WYCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5222 OLD HICKORY RD VAN WYCK SC 29744 USA "
    },
    {
      "Location ID": "29745",
      "Location Name": "YORK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 909 LIBERTY ST E YORK SC 29745 USA "
    },
    {
      "Location ID": "297AB",
      "Location Name": "EBENEZER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2191 EBENEZER RD ROCK HILL SC 29732 USA "
    },
    {
      "Location ID": "297DD",
      "Location Name": "DISTRIBUTION DIRECT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 LINKWOOD RD ROCK HILL SC 29730 USA "
    },
    {
      "Location ID": "297MM",
      "Location Name": "MASTER MEDIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1075 MAXWELL MILL RD FORT MILL SC 29708 USA "
    },
    {
      "Location ID": "297PC",
      "Location Name": "PCI GROUP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11632 HARRISBURG RD FORT MILL SC 29707 USA "
    },
    {
      "Location ID": "297SF",
      "Location Name": "STATE FARM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1550 PORTER RD ROCK HILL SC 29730 USA "
    },
    {
      "Location ID": "298",
      "Location Name": "AUGUSTA GA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 525 8TH STREET AUGUSTA GA 30901 USA "
    },
    {
      "Location ID": "29801",
      "Location Name": "AIKEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 307 LAURENS ST NW AIKEN SC 29801 USA "
    },
    {
      "Location ID": "29803",
      "Location Name": "SUMMERALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 758 SILVER BLUFF RD AIKEN SC 29803 USA "
    },
    {
      "Location ID": "29809",
      "Location Name": "NEW ELLENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 518 MAIN ST N NEW ELLENTON SC 29809 USA "
    },
    {
      "Location ID": "29810",
      "Location Name": "ALLENDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 315 AUGUSTA HWY ALLENDALE SC 29810 USA "
    },
    {
      "Location ID": "29812",
      "Location Name": "BARNWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 221 MAIN ST BARNWELL SC 29812 USA "
    },
    {
      "Location ID": "29813",
      "Location Name": "HILDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 75 RAILROAD AVE BARNWELL SC 29812 USA "
    },
    {
      "Location ID": "29816",
      "Location Name": "BATH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3880 AUGUSTA RD BATH SC 29816 USA "
    },
    {
      "Location ID": "29817",
      "Location Name": "BLACKVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 476 MAIN ST BLACKVILLE SC 29817 USA "
    },
    {
      "Location ID": "29821",
      "Location Name": "CLARKS HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13423 SC HIGHWAY 28 CLARKS HILL SC 29821 USA "
    },
    {
      "Location ID": "29822",
      "Location Name": "CLEARWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4425 JEFFERSON DAVIS HWY CLEARWATER SC 29822 USA "
    },
    {
      "Location ID": "29824",
      "Location Name": "EDGEFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 325 BACON ST EDGEFIELD SC 29824 USA "
    },
    {
      "Location ID": "29826",
      "Location Name": "ELKO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10049 US HIGHWAY 78 ELKO SC 29826 USA "
    },
    {
      "Location ID": "29827",
      "Location Name": "FAIRFAX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 190 PICKENS AVE N FAIRFAX SC 29827 USA "
    },
    {
      "Location ID": "29828",
      "Location Name": "GLOVERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2298 AUGUSTA RD GLOVERVILLE SC 29828 USA "
    },
    {
      "Location ID": "29829",
      "Location Name": "GRANITEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 CANAL ST STE 4 GRANITEVILLE SC 29829 USA "
    },
    {
      "Location ID": "29831",
      "Location Name": "JACKSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 MAIN ST JACKSON SC 29831 USA "
    },
    {
      "Location ID": "29832",
      "Location Name": "JOHNSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 519 CALHOUN ST JOHNSTON SC 29832 USA "
    },
    {
      "Location ID": "29834",
      "Location Name": "LANGLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2748 AUGUSTA RD LANGLEY SC 29834 USA "
    },
    {
      "Location ID": "29835",
      "Location Name": "MC CORMICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 N PINE ST MC CORMICK SC 29835 USA "
    },
    {
      "Location ID": "29836",
      "Location Name": "MARTIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6545 AUGUSTA HWY MARTIN SC 29836 USA "
    },
    {
      "Location ID": "29838",
      "Location Name": "MODOC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11489 SC HIGHWAY 28 MODOC SC 29838 USA "
    },
    {
      "Location ID": "29839",
      "Location Name": "MONTMORENCI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3053 CHARLESTON HWY MONTMORENCI SC 29839 USA "
    },
    {
      "Location ID": "29841",
      "Location Name": "NORTH AUGUSTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1450 GEORGIA AVE NORTH AUGUSTA SC 29841 USA "
    },
    {
      "Location ID": "29842",
      "Location Name": "BEECH ISLAND VPO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 257 BEECH ISLAND AVE BEECH ISLAND SC 29842 USA "
    },
    {
      "Location ID": "29843",
      "Location Name": "OLAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15223 CAROLINA HWY OLAR SC 29843 USA "
    },
    {
      "Location ID": "29844",
      "Location Name": "PARKSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 RAILROAD AVE PARKSVILLE SC 29844 USA "
    },
    {
      "Location ID": "29845",
      "Location Name": "PLUM BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 DEPOT ST N PLUM BRANCH SC 29845 USA "
    },
    {
      "Location ID": "29846",
      "Location Name": "SYCAMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 345 MAIN ST W SYCAMORE SC 29846 USA "
    },
    {
      "Location ID": "29847",
      "Location Name": "TRENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 WATSON ST TRENTON SC 29847 USA "
    },
    {
      "Location ID": "29849",
      "Location Name": "ULMER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 219 BURTONS FERRY HWY ULMER SC 29849 USA "
    },
    {
      "Location ID": "29850",
      "Location Name": "VAUCLUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 SENN ST VAUCLUSE SC 29850 USA "
    },
    {
      "Location ID": "29851",
      "Location Name": "WARRENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1341 AUGUSTA RD WARRENVILLE SC 29851 USA "
    },
    {
      "Location ID": "29853",
      "Location Name": "WILLISTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12926 MAIN ST WILLISTON SC 29853 USA "
    },
    {
      "Location ID": "29856",
      "Location Name": "WINDSOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 559 MIDDLETON DR N WINDSOR SC 29856 USA "
    },
    {
      "Location ID": "29902",
      "Location Name": "BEAUFORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 501 CHARLES ST BEAUFORT SC 29902 USA "
    },
    {
      "Location ID": "29904",
      "Location Name": "MARINE CORPS AIR STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1283 GEIGER BLVD BEAUFORT SC 29904 USA "
    },
    {
      "Location ID": "29905",
      "Location Name": "PARRIS ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 292 BOULEVARD DE FRANCE PARRIS ISLAND SC 29905 USA "
    },
    {
      "Location ID": "29906",
      "Location Name": "BURTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 ROBERT SMALLS PKWY STE C BEAUFORT SC 29906 USA "
    },
    {
      "Location ID": "29909",
      "Location Name": "BLUFFTON CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 208 RED OAKS WAY OKATIE SC 29909 USA "
    },
    {
      "Location ID": "29910",
      "Location Name": "BLUFFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 THURMOND WAY BLUFFTON SC 29910 USA "
    },
    {
      "Location ID": "29911",
      "Location Name": "BRUNSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 816 N RAILROAD AVE BRUNSON SC 29911 USA "
    },
    {
      "Location ID": "29916",
      "Location Name": "EARLY BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 FICKEN RD EARLY BRANCH SC 29916 USA "
    },
    {
      "Location ID": "29918",
      "Location Name": "ESTILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 328 E RAILROAD AVE N ESTILL SC 29918 USA "
    },
    {
      "Location ID": "29920",
      "Location Name": "SAINT HELENA ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 774 SEA ISLAND PKWY SAINT HELENA ISLAND SC 29920 USA "
    },
    {
      "Location ID": "29921",
      "Location Name": "FURMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 81 JEFFERSON ST FURMAN SC 29921 USA "
    },
    {
      "Location ID": "29922",
      "Location Name": "GARNETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 AUGUSTA STAGECOACH RD GARNETT SC 29922 USA "
    },
    {
      "Location ID": "29923",
      "Location Name": "GIFFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5944 COLUMBIA HWY GIFFORD SC 29923 USA "
    },
    {
      "Location ID": "29924",
      "Location Name": "HAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 604 1ST ST W HAMPTON SC 29924 USA "
    },
    {
      "Location ID": "29926",
      "Location Name": "FAIRFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 213 WILLIAM HILTON PKWY HILTON HEAD ISLAND SC 29926 USA "
    },
    {
      "Location ID": "29927",
      "Location Name": "HARDEEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23848 WHYTE HARDEE BLVD HARDEEVILLE SC 29927 USA "
    },
    {
      "Location ID": "29928",
      "Location Name": "HILTON HEAD ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 BOW CIR HILTON HEAD ISLAND SC 29928 USA "
    },
    {
      "Location ID": "29929",
      "Location Name": "ISLANDTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14759 SNIDERS HWY ISLANDTON SC 29929 USA "
    },
    {
      "Location ID": "29931",
      "Location Name": "LOBECO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1892 TRASK PKWY LOBECO SC 29931 USA "
    },
    {
      "Location ID": "29932",
      "Location Name": "LURAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2902 COLUMBIA HWY N LURAY SC 29932 USA "
    },
    {
      "Location ID": "29933",
      "Location Name": "MILEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21396 OLD SALKEHATCHIE HWY MILEY SC 29933 USA "
    },
    {
      "Location ID": "29934",
      "Location Name": "PINELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3672 CYPRESS BRANCH RD PINELAND SC 29934 USA "
    },
    {
      "Location ID": "29935",
      "Location Name": "PORT ROYAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1625 PARIS AVE PORT ROYAL SC 29935 USA "
    },
    {
      "Location ID": "29936",
      "Location Name": "RIDGELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7554 W MAIN ST RIDGELAND SC 29936 USA "
    },
    {
      "Location ID": "29940",
      "Location Name": "SEABROOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 219 SEABROOK RD SEABROOK SC 29940 USA "
    },
    {
      "Location ID": "29941",
      "Location Name": "SHELDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 PAIGE POINT BLF SHELDON SC 29941 USA "
    },
    {
      "Location ID": "29943",
      "Location Name": "TILLMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 COTTON HILL RD TILLMAN SC 29943 USA "
    },
    {
      "Location ID": "29944",
      "Location Name": "VARNVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 81 MAIN ST VARNVILLE SC 29944 USA "
    },
    {
      "Location ID": "29945",
      "Location Name": "YEMASSEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 SALKEHATCHIE RD YEMASSEE SC 29945 USA "
    },
    {
      "Location ID": "300",
      "Location Name": "LOG NORTH METRO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1605 BOGGS RD DULUTH GA 30096 USA "
    },
    {
      "Location ID": "30005",
      "Location Name": "WEBB BRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4575 WEBB BRIDGE RD ALPHARETTA GA 30005 USA "
    },
    {
      "Location ID": "30009",
      "Location Name": "ALPHARETTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2400 OLD MILTON PKWY ALPHARETTA GA 30009 USA "
    },
    {
      "Location ID": "30011",
      "Location Name": "AUBURN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 COLLEGE ST AUBURN GA 30011 USA "
    },
    {
      "Location ID": "30012",
      "Location Name": "OLDE TOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1007 GREEN ST SE CONYERS GA 30012 USA "
    },
    {
      "Location ID": "30013",
      "Location Name": "CONYERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1705 HIGHWAY 138 SE CONYERS GA 30013 USA "
    },
    {
      "Location ID": "30014",
      "Location Name": "COVINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2131 STALLINGS ST NW COVINGTON GA 30014 USA "
    },
    {
      "Location ID": "30017",
      "Location Name": "GRAYSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 516 SOSEBEE FARM RD GRAYSON GA 30017 USA "
    },
    {
      "Location ID": "30018",
      "Location Name": "JERSEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 133 MAIN ST JERSEY GA 30018 USA "
    },
    {
      "Location ID": "30019",
      "Location Name": "DACULA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 470 DACULA RD DACULA GA 30019 USA "
    },
    {
      "Location ID": "30024",
      "Location Name": "SUWANEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 990 PEACHTREE INDUSTRIAL BLVD SUWANEE GA 30024-9998 USA "
    },
    {
      "Location ID": "30025",
      "Location Name": "SOCIAL CIRCLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 W HIGHTOWER TRL SOCIAL CIRCLE GA 30025 USA "
    },
    {
      "Location ID": "30039",
      "Location Name": "CENTERVILLE BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3375 CENTERVILLE HWY SNELLVILLE GA 30039 USA "
    },
    {
      "Location ID": "30040",
      "Location Name": "CUMMING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 525 TRIBBLE GAP RD CUMMING GA 30040 USA "
    },
    {
      "Location ID": "30043",
      "Location Name": "LAWRENCEVILLE NORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1557 BUFORD DR LAWRENCEVILLE GA 30043 USA "
    },
    {
      "Location ID": "30044",
      "Location Name": "LAWRENCEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 PATTERSON RD LAWRENCEVILLE GA 30044 USA "
    },
    {
      "Location ID": "30046",
      "Location Name": "CROGAN STREET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 E CROGAN ST LAWRENCEVILLE GA 30046 USA "
    },
    {
      "Location ID": "30047",
      "Location Name": "LILBURN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4370 LAWRENCEVILLE HWY NW LILBURN GA 30047 USA "
    },
    {
      "Location ID": "30052",
      "Location Name": "LOGANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4160 LOGAN DRIVE LOGANVILLE GA 30052 USA "
    },
    {
      "Location ID": "30054",
      "Location Name": "OXFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 907 EMORY ST OXFORD GA 30054 USA "
    },
    {
      "Location ID": "30058",
      "Location Name": "LITHONIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3035 STONE MOUNTAIN ST LITHONIA GA 30058 USA "
    },
    {
      "Location ID": "30070",
      "Location Name": "PORTERDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2600 MAIN ST PORTERDALE GA 30070 USA "
    },
    {
      "Location ID": "30074",
      "Location Name": "REDAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1544 WELLBORN RD STE 4 LITHONIA GA 30058 USA "
    },
    {
      "Location ID": "30075",
      "Location Name": "CROSSTOWN CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 225 E CROSSVILLE RD ROSWELL GA 30075 USA "
    },
    {
      "Location ID": "30076",
      "Location Name": "ROSWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8920 EVES RD ROSWELL GA 30076 USA "
    },
    {
      "Location ID": "30077",
      "Location Name": "ROSWELL POSTAL 77",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10719 ALPHARETTA HWY ROSWELL GA 30077 USA "
    },
    {
      "Location ID": "30078",
      "Location Name": "SNELLVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2250 OAK RD SNELLVILLE GA 30078-9998 USA "
    },
    {
      "Location ID": "30094",
      "Location Name": "CONYERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1007 GREEN STREET CONYERS GA 30094 USA "
    },
    {
      "Location ID": "30095",
      "Location Name": "GWINNETT MALL CORNERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3628 SATELLITE BLVD DULUTH GA 30096-9997 USA "
    },
    {
      "Location ID": "30096",
      "Location Name": "DULUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3470 MCCLURE BRIDGE RD DULUTH GA 30096-9998 USA "
    },
    {
      "Location ID": "300AA",
      "Location Name": "ALPHARETTA CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1295 BLUEGRASS LAKES PKWY STE A ALPHARETTA GA 30004 USA "
    },
    {
      "Location ID": "300AD",
      "Location Name": "LAWRENCEVILLE CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 748 WINER INDUSTRIAL WAY LAWRENCEVILLE GA 30046 USA "
    },
    {
      "Location ID": "300AE",
      "Location Name": "COVINGTON CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10721 COVINGTON BY PASS RD COVINGTON GA 30014 USA "
    },
    {
      "Location ID": "300AN",
      "Location Name": "AFFILIATE NUTRA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4357 PARK DR STE G NORCROSS GA 30093 USA "
    },
    {
      "Location ID": "300AT",
      "Location Name": "ATLANTA NETWORK TECHNOLOGY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 601 CHEROKEE PKWY ACWORTH GA 30102 USA "
    },
    {
      "Location ID": "300BS",
      "Location Name": "AT&T",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6175 SHILOH RD ALPHARETTA GA 30005 USA "
    },
    {
      "Location ID": "300CM",
      "Location Name": "COOL MINI OR NOT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1290 OLD ALPHARETTA RD ALPHARETTA GA 30005 USA "
    },
    {
      "Location ID": "300GL",
      "Location Name": "DHL SMARTMAIL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1500 SOUTHPOINT DR STE 200 FOREST PARK GA 30297 USA "
    },
    {
      "Location ID": "300IB",
      "Location Name": "I-BLASON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1880 MCFARLAND PKWY STE 180 ALPHARETTA GA 30005 USA "
    },
    {
      "Location ID": "300JD",
      "Location Name": "JDY SPUPLY CHAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 975 OLD NORCROSS RD LAWRENCEVILLE GA 30046 USA "
    },
    {
      "Location ID": "300JO",
      "Location Name": "JONDO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 322 INDUSTRIAL PARK DR LAWRENCEVILLE GA 30046 USA "
    },
    {
      "Location ID": "300LA",
      "Location Name": "LANIER CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2530 IVY ST E CUMMING GA 30041 USA "
    },
    {
      "Location ID": "300PB",
      "Location Name": "PROFESSIONAL BOOK DISTRIBUTORS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1650 BLUEGRASS LAKES PKWY ALPHARETTA GA 30004-7714 USA "
    },
    {
      "Location ID": "300PE",
      "Location Name": "PEACH SKINS SHEETS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1225 OLD ALPHARETTA RD ALPHARETTA GA 30005 USA "
    },
    {
      "Location ID": "300PS",
      "Location Name": "ROSWELL POSTAL STORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10719 ALPHARETTA HWY ROSWELL GA 30076-2432 USA "
    },
    {
      "Location ID": "300SC",
      "Location Name": "HEALTHPORT TECHNOLOGIES LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 BLUEGRASS VALLEY PKWY ALPHARETTA GA 30005 USA "
    },
    {
      "Location ID": "300SF",
      "Location Name": "STATE FARM INSURANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3000 MARCONI DRIVE ALPHARETTA GA 30005 USA "
    },
    {
      "Location ID": "300TW",
      "Location Name": "TEXTBOOK WAREHOUSE/ALPHA BOOK WORKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 936 CURIE DR ALPHARETTA GA 30005 USA "
    },
    {
      "Location ID": "300WI",
      "Location Name": "WINTERGREEN CORP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 CURIE DR ALPHARETTA GA 30005 USA "
    },
    {
      "Location ID": "30101",
      "Location Name": "ACWORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4915 N MAIN ST ACWORTH GA 30101 USA "
    },
    {
      "Location ID": "30103",
      "Location Name": "ADAIRSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 FINLEY ST ADAIRSVILLE GA 30103 USA "
    },
    {
      "Location ID": "30104",
      "Location Name": "ARAGON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2646 ROME HWY ARAGON GA 30104 USA "
    },
    {
      "Location ID": "30105",
      "Location Name": "ARMUCHEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5345 MARTHA BERRY HWY NE ARMUCHEE GA 30105 USA "
    },
    {
      "Location ID": "30106",
      "Location Name": "(30106) AUSTELL CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5055 AUSTELL RD STE C AUSTELL GA 30106 USA "
    },
    {
      "Location ID": "30107",
      "Location Name": "BALL GROUND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 180 VALLEY ST BALL GROUND GA 30107 USA "
    },
    {
      "Location ID": "30108",
      "Location Name": "BOWDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 W COLLEGE ST BOWDON GA 30108 USA "
    },
    {
      "Location ID": "30109",
      "Location Name": "BOWDON JUNCTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4363 HIGHWAY 27 N BOWDON JUNCTION GA 30109 USA "
    },
    {
      "Location ID": "30110",
      "Location Name": "BREMEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 TALLAPOOSA ST W BREMEN GA 30110 USA "
    },
    {
      "Location ID": "30111",
      "Location Name": "CLARKDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5071 AUSTELL POWDER SPRINGS RD CLARKDALE GA 30111 USA "
    },
    {
      "Location ID": "30113",
      "Location Name": "BUCHANAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 368 CARROLLTON ST BUCHANAN GA 30113 USA "
    },
    {
      "Location ID": "30114",
      "Location Name": "CANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2400 RIVERSTONE BLVD CANTON GA 30114-9998 USA "
    },
    {
      "Location ID": "30117",
      "Location Name": "CARROLLTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 THOMAS NEWELL WAY CARROLLTON GA 30117 USA "
    },
    {
      "Location ID": "30120",
      "Location Name": "CARTERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 LIBERTY DR CARTERSVILLE GA 30121 USA "
    },
    {
      "Location ID": "30122",
      "Location Name": "LITHIA SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2727 SKYVIEW DR LITHIA SPRINGS GA 30122 USA "
    },
    {
      "Location ID": "30123",
      "Location Name": "CASSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 66 FIRE TOWER RD NW CASSVILLE GA 30123 USA "
    },
    {
      "Location ID": "30124",
      "Location Name": "CAVE SPRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 MILL ST SW CAVE SPRING GA 30124 USA "
    },
    {
      "Location ID": "30125",
      "Location Name": "CEDARTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 145 WEST AVE CEDARTOWN GA 30125 USA "
    },
    {
      "Location ID": "30126",
      "Location Name": "MABLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5284 FLOYD RD SW MABLETON GA 30126 USA "
    },
    {
      "Location ID": "30127",
      "Location Name": "POWDER SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4644 POWDER SPRINGS DALLAS RD POWDER SPRINGS GA 30127 USA "
    },
    {
      "Location ID": "30129",
      "Location Name": "COOSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4000 ALABAMA HWY NW COOSA GA 30129 USA "
    },
    {
      "Location ID": "30132",
      "Location Name": "DALLAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 280 MERCHANTS DR DALLAS GA 30132 USA "
    },
    {
      "Location ID": "30134",
      "Location Name": "CAMPBELLTON STREET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8486 CAMPBELLTON ST DOUGLASVILLE GA 30134 USA "
    },
    {
      "Location ID": "30135",
      "Location Name": "DOUGLASVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6000 STEWART PKWY DOUGLASVILLE GA 30135 USA "
    },
    {
      "Location ID": "30137",
      "Location Name": "EMERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 86 GASTON WESTBROOK AVE EMERSON GA 30137 USA "
    },
    {
      "Location ID": "30138",
      "Location Name": "ESOM HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 HARDIN RD ESOM HILL GA 30138 USA "
    },
    {
      "Location ID": "30139",
      "Location Name": "FAIRMOUNT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2295 HIGHWAY 411 SE FAIRMOUNT GA 30139 USA "
    },
    {
      "Location ID": "30140",
      "Location Name": "FELTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7369 HIGHWAY 27 FELTON GA 30140 USA "
    },
    {
      "Location ID": "30141",
      "Location Name": "HIRAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 225 MAIN ST HIRAM GA 30141 USA "
    },
    {
      "Location ID": "30142",
      "Location Name": "HOLLY SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2210 HOLLY SPRINGS PKWY HOLLY SPRINGS GA 30142 USA "
    },
    {
      "Location ID": "30143",
      "Location Name": "JASPER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 EAGLES LNDG JASPER GA 30143-9998 USA "
    },
    {
      "Location ID": "30144",
      "Location Name": "KENNESAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2001 DUNCAN DR NW KENNESAW GA 30144 USA "
    },
    {
      "Location ID": "30145",
      "Location Name": "KINGSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 W MAIN ST KINGSTON GA 30145 USA "
    },
    {
      "Location ID": "30146",
      "Location Name": "LEBANON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5101 OLD HIGHWAY 5 LEBANON GA 30146 USA "
    },
    {
      "Location ID": "30147",
      "Location Name": "LINDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 502 PARK AVE SE LINDALE GA 30147 USA "
    },
    {
      "Location ID": "30148",
      "Location Name": "MARBLE HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12813 HIGHWAY 53 MARBLE HILL GA 30148 USA "
    },
    {
      "Location ID": "30149",
      "Location Name": "CPU MOUNT BERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2277 MARTHA BERRY HWY NW MOUNT BERRY GA 30149 USA "
    },
    {
      "Location ID": "30150",
      "Location Name": "MOUNT ZION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 MARTIN ST MOUNT ZION GA 30150 USA "
    },
    {
      "Location ID": "30151",
      "Location Name": "NELSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 199 DOGWOOD PASS NELSON GA 30151 USA "
    },
    {
      "Location ID": "30153",
      "Location Name": "ROCKMART",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 E ELM ST ROCKMART GA 30153 USA "
    },
    {
      "Location ID": "30161",
      "Location Name": "ROME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1420 MARTHA BERRY BLVD NE ROME GA 30165 USA "
    },
    {
      "Location ID": "30168",
      "Location Name": "AUSTELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2847 VETERANS MEMORIAL HWY SW AUSTELL GA 30168 USA "
    },
    {
      "Location ID": "30170",
      "Location Name": "ROOPVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 270 S HIGHWAY 27 ROOPVILLE GA 30170 USA "
    },
    {
      "Location ID": "30171",
      "Location Name": "RYDAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 POST OFFICE CIR NE RYDAL GA 30171 USA "
    },
    {
      "Location ID": "30172",
      "Location Name": "SHANNON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 361 BURLINGTON DR SHANNON GA 30172 USA "
    },
    {
      "Location ID": "30173",
      "Location Name": "SILVER CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4565 ROCKMART RD SE SILVER CREEK GA 30173 USA "
    },
    {
      "Location ID": "30175",
      "Location Name": "TALKING ROCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 ANTIOCH CHURCH RD TALKING ROCK GA 30175 USA "
    },
    {
      "Location ID": "30176",
      "Location Name": "TALLAPOOSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1919 U S HIGHWAY 78 TALLAPOOSA GA 30176 USA "
    },
    {
      "Location ID": "30177",
      "Location Name": "TATE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4817 HIGHWAY 53 E TATE GA 30177 USA "
    },
    {
      "Location ID": "30178",
      "Location Name": "TAYLORSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 61 MADDEN ST TAYLORSVILLE GA 30178 USA "
    },
    {
      "Location ID": "30179",
      "Location Name": "TEMPLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 68 JAMES ST TEMPLE GA 30179 USA "
    },
    {
      "Location ID": "30180",
      "Location Name": "VILLA RICA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 43 COMMUNITY SQUARE BLVD VILLA RICA GA 30180 USA "
    },
    {
      "Location ID": "30182",
      "Location Name": "WACO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 86 ATLANTIC AVE WACO GA 30182 USA "
    },
    {
      "Location ID": "30183",
      "Location Name": "WALESKA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7265 REINHARDT COLLEGE PKWY WALESKA GA 30183 USA "
    },
    {
      "Location ID": "30184",
      "Location Name": "WHITE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3172 HIGHWAY 411 NE WHITE GA 30184 USA "
    },
    {
      "Location ID": "30185",
      "Location Name": "WHITESBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 710 MAIN ST WHITESBURG GA 30185 USA "
    },
    {
      "Location ID": "30187",
      "Location Name": "WINSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2929 POST RD WINSTON GA 30187 USA "
    },
    {
      "Location ID": "30188",
      "Location Name": "WOODSTOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 225 PARKWAY 575 WOODSTOCK GA 30188-9998 USA "
    },
    {
      "Location ID": "301AC",
      "Location Name": "VILLA RICA CAX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 R F D WAY VILLA RICA GA 30180 USA "
    },
    {
      "Location ID": "301AM",
      "Location Name": "AMAZON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2201 THORTON RD LITHIA SPRING GA 30122-3895 USA "
    },
    {
      "Location ID": "301BF",
      "Location Name": "BROOKSTONE FINANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1727 MARS HILL ROAD ACWORTH GA 30101-8090 USA "
    },
    {
      "Location ID": "301CA",
      "Location Name": "ACWORTH CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4200 MCEVER INDUSTRIAL DR NW ACWORTH GA 30101 USA "
    },
    {
      "Location ID": "301DP",
      "Location Name": "DOODAD PRINTING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7990 SECOND FLAGS DR D AUSTELL GA 30168 USA "
    },
    {
      "Location ID": "301KA",
      "Location Name": "KENNESAW CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6950 COBB INTERNATIONAL BLVD NW KENNESAW GA 30152 USA "
    },
    {
      "Location ID": "301KB",
      "Location Name": "BARRETT PARKWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 840 ERNEST W BARRETT PKWY NW STE 572 KENNESAW GA 30144 USA "
    },
    {
      "Location ID": "301OG",
      "Location Name": "OAK GROVE BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1329 ALABAMA RD ACWORTH GA 30101-2318 USA "
    },
    {
      "Location ID": "301OS",
      "Location Name": "ONE STOP MAILING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7801 THIRD FLAG PKWY AUSTELL GA 30168 USA "
    },
    {
      "Location ID": "301QC",
      "Location Name": "QUILL CORPORATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7701 STAPLES DR LITHIA SPGS GA 30122 USA "
    },
    {
      "Location ID": "301SF",
      "Location Name": "STITCH FIX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 801 FACTORY SHOALS RD LITHIA SPRINGS GA 30122-3616 USA "
    },
    {
      "Location ID": "301TH",
      "Location Name": "ATLANTA THS SURFACE HUB",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 PENNEY RD SUITE 1200 FOREST PARK GA 30297 USA "
    },
    {
      "Location ID": "301TL",
      "Location Name": "TOWNE LAKE STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3101 PARKBROOKE CIR WOODSTOCK GA 30189-9998 USA "
    },
    {
      "Location ID": "301UA",
      "Location Name": "UNIFORM ADVANTAGE DISTRIBUTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2100 THORNTON RD STE 400 LITHIA SPRINGS GA 30122-3811 USA "
    },
    {
      "Location ID": "30204",
      "Location Name": "BARNESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 138 FORSYTH ST BARNESVILLE GA 30204 USA "
    },
    {
      "Location ID": "30205",
      "Location Name": "BROOKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 W MCINTOSH RD BROOKS GA 30205 USA "
    },
    {
      "Location ID": "30206",
      "Location Name": "CONCORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 MAIN ST CONCORD GA 30206 USA "
    },
    {
      "Location ID": "30212",
      "Location Name": "EXPERIMENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1382 EXPERIMENT ST EXPERIMENT GA 30212 USA "
    },
    {
      "Location ID": "30216",
      "Location Name": "FLOVILLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 340 HEARD ST FLOVILLA GA 30216 USA "
    },
    {
      "Location ID": "30218",
      "Location Name": "GAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18685 HIGHWAY 85 GAY GA 30218 USA "
    },
    {
      "Location ID": "30222",
      "Location Name": "GREENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 405 LAGRANGE ST GREENVILLE GA 30222 USA "
    },
    {
      "Location ID": "30223",
      "Location Name": "GRIFFIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 N 8TH ST GRIFFIN GA 30223 USA "
    },
    {
      "Location ID": "30228",
      "Location Name": "HAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 998 HIGHWAY 19 41 HAMPTON GA 30228 USA "
    },
    {
      "Location ID": "30229",
      "Location Name": "HARALSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 214 HIGHWAY 85 HARALSON GA 30229 USA "
    },
    {
      "Location ID": "30233",
      "Location Name": "JACKSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 461 E 2ND ST JACKSON GA 30233 USA "
    },
    {
      "Location ID": "30234",
      "Location Name": "JENKINSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2109 HIGHWAY 42 N JENKINSBURG GA 30234 USA "
    },
    {
      "Location ID": "30236",
      "Location Name": "JONESBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 255 N MAIN ST JONESBORO GA 30236 USA "
    },
    {
      "Location ID": "30248",
      "Location Name": "LOCUST GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4050 HIGHWAY 42 LOCUST GROVE GA 30248 USA "
    },
    {
      "Location ID": "30250",
      "Location Name": "LOVEJOY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2261 TALMADGE RD LOVEJOY GA 30250 USA "
    },
    {
      "Location ID": "30251",
      "Location Name": "LUTHERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 N MAIN ST LUTHERSVILLE GA 30251 USA "
    },
    {
      "Location ID": "30256",
      "Location Name": "MEANSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12570 GA HWY 109 MEANSVILLE GA 30256 USA "
    },
    {
      "Location ID": "30257",
      "Location Name": "MILNER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 GREENWOOD ST MILNER GA 30257 USA "
    },
    {
      "Location ID": "30258",
      "Location Name": "MOLENA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2696 MAIN ST MOLENA GA 30258 USA "
    },
    {
      "Location ID": "30259",
      "Location Name": "MORELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 BALL ST MORELAND GA 30259 USA "
    },
    {
      "Location ID": "30266",
      "Location Name": "ORCHARD HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2201 S MCDONOUGH RD ORCHARD HILL GA 30266 USA "
    },
    {
      "Location ID": "30269",
      "Location Name": "PEACHTREE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 151 HIGHWAY 74 S PEACHTREE CITY GA 30269 USA "
    },
    {
      "Location ID": "30273",
      "Location Name": "REX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6450 EVANS DR REX GA 30273 USA "
    },
    {
      "Location ID": "30276",
      "Location Name": "SENOIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 68 BROAD ST SENOIA GA 30276 USA "
    },
    {
      "Location ID": "30277",
      "Location Name": "SHARPSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6545 HIGHWAY 54 SHARPSBURG GA 30277 USA "
    },
    {
      "Location ID": "30284",
      "Location Name": "SUNNY SIDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3980 NORTH EXPY SUNNY SIDE GA 30284 USA "
    },
    {
      "Location ID": "30285",
      "Location Name": "THE ROCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4674 W MAIN ST THE ROCK GA 30285 USA "
    },
    {
      "Location ID": "30286",
      "Location Name": "THOMASTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 E THOMPSON ST THOMASTON GA 30286 USA "
    },
    {
      "Location ID": "30288",
      "Location Name": "CONLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1333 CEDAR GROVE RD CONLEY GA 30288 USA "
    },
    {
      "Location ID": "30289",
      "Location Name": "TURIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 S HUNTER ST TURIN GA 30289 USA "
    },
    {
      "Location ID": "30290",
      "Location Name": "TYRONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 COMMERCE DR TYRONE GA 30290 USA "
    },
    {
      "Location ID": "30292",
      "Location Name": "WILLIAMSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 421 HIGHWAY 362 W WILLIAMSON GA 30292 USA "
    },
    {
      "Location ID": "30293",
      "Location Name": "WOODBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 JONES MILL RD WOODBURY GA 30293 USA "
    },
    {
      "Location ID": "30294",
      "Location Name": "ELLENWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5465 HIGHWAY 42 ELLENWOOD GA 30294 USA "
    },
    {
      "Location ID": "30295",
      "Location Name": "ZEBULON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 370 GRIFFIN ST ZEBULON GA 30295 USA "
    },
    {
      "Location ID": "302FA",
      "Location Name": "FAYETTEVILLE GA ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 HUIET RD FAYETTEVILLE GA 30214 USA "
    },
    {
      "Location ID": "302RP",
      "Location Name": "LOG ATLANTA GA RPDC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 PALMETTO LOGISTICS PKWY PALMETTO GA 30213 USA "
    },
    {
      "Location ID": "303",
      "Location Name": "ATLANTA P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3900 CROWN RD SW ATLANTA GA 30304 USA "
    },
    {
      "Location ID": "30301",
      "Location Name": "PHOENIX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41 MARIETTA ST NW LBBY ATLANTA GA 30303 USA "
    },
    {
      "Location ID": "30302",
      "Location Name": "CENTRAL CITY FINANCE STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 183 FORSYTH ST SW ATLANTA GA 30303 USA "
    },
    {
      "Location ID": "30318",
      "Location Name": "MARTECH ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 967 BRADY AVE NW ATLANTA GA 30318 USA "
    },
    {
      "Location ID": "30336",
      "Location Name": "INDUSTRIAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5686 FULTON INDUSTRIAL BLVD SW ATLANTA GA 30336 USA "
    },
    {
      "Location ID": "30349",
      "Location Name": "OLD NATIONAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2385 GODBY RD ATLANTA GA 30349 USA "
    },
    {
      "Location ID": "303AD",
      "Location Name": "ADVO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7924 TROON CIRCLE SW AUSTELL GA 30168 USA "
    },
    {
      "Location ID": "303AN",
      "Location Name": "ATLANTA NEWORK TECHNOLOGY ONLINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 710 MORGAN FALLS RD ATLANTA GA 30350 USA "
    },
    {
      "Location ID": "303AX",
      "Location Name": "LOG CROWN ROAD ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4575 LAKE MIRROR PL FOREST PARK GA 30297 USA "
    },
    {
      "Location ID": "303AZ",
      "Location Name": "ATL6",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4200 N COMMERCE DR ATLANTA GA 30344 USA "
    },
    {
      "Location ID": "303CH",
      "Location Name": "CHICO'S",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1020 BARROW INDUSTRIAL PKWY ATLANTA GA 30680 USA "
    },
    {
      "Location ID": "303CX",
      "Location Name": "ATLANTA CMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1625 OAKLEY INDUSTRIAL BLVD FAIRBURN GA 30213 USA "
    },
    {
      "Location ID": "303DL",
      "Location Name": "DELTA AIRLINES CARGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1600 M H JACKSON SERVICE RD ATLANTA GA 30354 USA "
    },
    {
      "Location ID": "303DS",
      "Location Name": "DEPENDABLE MAIL SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 850 AQUILA WAY STE 120 AUSTELL GA 30331 USA "
    },
    {
      "Location ID": "303DT",
      "Location Name": "DATAMATX INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3146 NORTHEAST EXPY NE ATLANTA GA 30341 USA "
    },
    {
      "Location ID": "303FZ",
      "Location Name": "LOG ATLANTA GA PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 THE BLUFFS AUSTELL GA 30168 USA "
    },
    {
      "Location ID": "303MR",
      "Location Name": "ATLANTA MAIL RECOVERY CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 VILLANOVA DR SW ATLANTA GA 30336 USA "
    },
    {
      "Location ID": "303PS",
      "Location Name": "PITNEY BOWES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5355 BUFFINGTON RD STE A ATLANTA GA 30349 USA "
    },
    {
      "Location ID": "303SP",
      "Location Name": "FEDEX SMARTPOST HUB",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4644 SOUTHPARK BLVD ELLENWOOD GA 30294 USA "
    },
    {
      "Location ID": "303UM",
      "Location Name": "UPS MAIL INNOVATIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3781 SOUTHSIDE IND. CT SE ATLANTA GA 30354 USA "
    },
    {
      "Location ID": "304",
      "Location Name": "SWAINSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 211 W MORING ST SWAINSBORO GA 30401 USA "
    },
    {
      "Location ID": "30401",
      "Location Name": "SWAINSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 211 W MORING ST SWAINSBORO GA 30401 USA "
    },
    {
      "Location ID": "30410",
      "Location Name": "AILEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 129 RAILROAD ST AILEY GA 30410 USA "
    },
    {
      "Location ID": "30411",
      "Location Name": "ALAMO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 W RAILROAD AVE ALAMO GA 30411 USA "
    },
    {
      "Location ID": "30412",
      "Location Name": "ALSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5133 GA HIGHWAY 135 ALSTON GA 30412 USA "
    },
    {
      "Location ID": "30413",
      "Location Name": "BARTOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1031 N RAILROAD AVE BARTOW GA 30413 USA "
    },
    {
      "Location ID": "30415",
      "Location Name": "BROOKLET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 129 E LEE ST BROOKLET GA 30415 USA "
    },
    {
      "Location ID": "30417",
      "Location Name": "CLAXTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 COURTHOUSE ST CLAXTON GA 30417 USA "
    },
    {
      "Location ID": "30420",
      "Location Name": "COBBTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6888 MAIN ST COBBTOWN GA 30420 USA "
    },
    {
      "Location ID": "30421",
      "Location Name": "COLLINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 225 S MAIN ST COLLINS GA 30421 USA "
    },
    {
      "Location ID": "30423",
      "Location Name": "DAISY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 306 RAILROAD ST DAISY GA 30423 USA "
    },
    {
      "Location ID": "30424",
      "Location Name": "DOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6516 STATESBORO HWY DOVER GA 30424 USA "
    },
    {
      "Location ID": "30425",
      "Location Name": "GARFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 213 MONROE ST GARFIELD GA 30425 USA "
    },
    {
      "Location ID": "30426",
      "Location Name": "GIRARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10280 GA HIGHWAY 23 S GIRARD GA 30426 USA "
    },
    {
      "Location ID": "30427",
      "Location Name": "GLENNVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 S VETERANS BLVD GLENNVILLE GA 30427 USA "
    },
    {
      "Location ID": "30428",
      "Location Name": "GLENWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 N THIRD ST GLENWOOD GA 30428 USA "
    },
    {
      "Location ID": "30429",
      "Location Name": "HAGAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5272 NW US HIGHWAY 280 HAGAN GA 30429 USA "
    },
    {
      "Location ID": "30434",
      "Location Name": "LOUISVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 131 W BROAD ST LOUISVILLE GA 30434 USA "
    },
    {
      "Location ID": "30436",
      "Location Name": "LYONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 131 N STATE ST LYONS GA 30436 USA "
    },
    {
      "Location ID": "30439",
      "Location Name": "METTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 S ROUNTREE ST METTER GA 30439 USA "
    },
    {
      "Location ID": "30441",
      "Location Name": "MIDVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 238 N RAILROAD ST MIDVILLE GA 30441 USA "
    },
    {
      "Location ID": "30442",
      "Location Name": "MILLEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 608 E WINTHROPE AVE MILLEN GA 30442 USA "
    },
    {
      "Location ID": "30445",
      "Location Name": "MOUNT VERNON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 W CHURCH ST MOUNT VERNON GA 30445 USA "
    },
    {
      "Location ID": "30446",
      "Location Name": "NEWINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 OLIVER HWY NEWINGTON GA 30446 USA "
    },
    {
      "Location ID": "30448",
      "Location Name": "NUNEZ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8451 GA HIGHWAY 297 NUNEZ GA 30448 USA "
    },
    {
      "Location ID": "30449",
      "Location Name": "OLIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6185 EFFINGHAM HWY OLIVER GA 30449 USA "
    },
    {
      "Location ID": "30450",
      "Location Name": "PORTAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27310 US HIGHWAY 80 W PORTAL GA 30450 USA "
    },
    {
      "Location ID": "30451",
      "Location Name": "PULASKI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 E RAILROAD ST PULASKI GA 30451 USA "
    },
    {
      "Location ID": "30452",
      "Location Name": "REGISTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2981 GA HIGHWAY 46 REGISTER GA 30452 USA "
    },
    {
      "Location ID": "30453",
      "Location Name": "REIDSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 JAMES ST REIDSVILLE GA 30453 USA "
    },
    {
      "Location ID": "30454",
      "Location Name": "ROCKLEDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2155 ROCKLEDGE RD ROCKLEDGE GA 30454 USA "
    },
    {
      "Location ID": "30455",
      "Location Name": "ROCKY FORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 151 MAIN ST ROCKY FORD GA 30455 USA "
    },
    {
      "Location ID": "30456",
      "Location Name": "SARDIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 932 PERRY AVE SARDIS GA 30456 USA "
    },
    {
      "Location ID": "30457",
      "Location Name": "SOPERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5059 S THIRD ST SOPERTON GA 30457 USA "
    },
    {
      "Location ID": "30458",
      "Location Name": "STATESBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 S COLLEGE ST STATESBORO GA 30458 USA "
    },
    {
      "Location ID": "30464",
      "Location Name": "STILLMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 N 2ND ST STILLMORE GA 30464 USA "
    },
    {
      "Location ID": "30467",
      "Location Name": "SYLVANIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 S MAIN ST SYLVANIA GA 30467 USA "
    },
    {
      "Location ID": "30470",
      "Location Name": "TARRYTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 431 MAIN ST TARRYTOWN GA 30470 USA "
    },
    {
      "Location ID": "30471",
      "Location Name": "TWIN CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 227 OLD SWAINSBORO RD TWIN CITY GA 30471 USA "
    },
    {
      "Location ID": "30473",
      "Location Name": "UVALDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 N RAILROAD ST UVALDA GA 30473 USA "
    },
    {
      "Location ID": "30474",
      "Location Name": "VIDALIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 310 CHURCH ST VIDALIA GA 30474 USA "
    },
    {
      "Location ID": "30477",
      "Location Name": "WADLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 RAILROAD AVE WADLEY GA 30477 USA "
    },
    {
      "Location ID": "305",
      "Location Name": "GAINESVILLE GA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 364 GREEN ST NE GAINESVILLE GA 30501 USA "
    },
    {
      "Location ID": "30501",
      "Location Name": "GAINESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 364 GREEN ST NE GAINESVILLE GA 30501 USA "
    },
    {
      "Location ID": "30512",
      "Location Name": "BLAIRSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 345 YOUNG HARRIS ST BLAIRSVILLE GA 30512 USA "
    },
    {
      "Location ID": "30513",
      "Location Name": "BLUE RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 273 ORVIN LANCE DR BLUE RIDGE GA 30513 USA "
    },
    {
      "Location ID": "30518",
      "Location Name": "BUFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2030 BUFORD HWY BUFORD GA 30518 USA "
    },
    {
      "Location ID": "30519",
      "Location Name": "BUFORD CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2766 HAMILTON MILL RD BUFORD GA 30519 USA "
    },
    {
      "Location ID": "30523",
      "Location Name": "CLARKESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 WASHINGTON ST CLARKESVILLE GA 30523 USA "
    },
    {
      "Location ID": "30527",
      "Location Name": "CLERMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 430 MAIN ST CLERMONT GA 30527 USA "
    },
    {
      "Location ID": "30528",
      "Location Name": "CLEVELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 HELEN HWY CLEVELAND GA 30528 USA "
    },
    {
      "Location ID": "30533",
      "Location Name": "DAHLONEGA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 72 ALICIA LN DAHLONEGA GA 30533 USA "
    },
    {
      "Location ID": "30534",
      "Location Name": "DAWSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 452 HIGHWAY 53 E DAWSONVILLE GA 30534 USA "
    },
    {
      "Location ID": "30539",
      "Location Name": "EAST ELLIJAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 230 OLD ORCHARD SQ EAST ELLIJAY GA 30539-8173 USA "
    },
    {
      "Location ID": "30540",
      "Location Name": "ELLIJAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 801 INDUSTRIAL BLVD ELLIJAY GA 30540 USA "
    },
    {
      "Location ID": "30542",
      "Location Name": "FLOWERY BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4915 ATLANTA HWY FLOWERY BRANCH GA 30542 USA "
    },
    {
      "Location ID": "30552",
      "Location Name": "LAKEMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 LAKEMONT INDUSTRIAL DR LAKEMONT GA 30552 USA "
    },
    {
      "Location ID": "305AX",
      "Location Name": "GAINESVILLE CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2530 PENTEE DR GAINESVILLE GA 30507 USA "
    },
    {
      "Location ID": "305BD",
      "Location Name": "BED, BATH & BEYOND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 860 JOHN B BROOKS RD PENDERGRASS GA 30567-4612 USA "
    },
    {
      "Location ID": "305BH",
      "Location Name": "BRASELTON HOSCHTON CAX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 544 JESSE CRONIC RD BRASELTON GA 30517 USA "
    },
    {
      "Location ID": "305BK",
      "Location Name": "BLACK HAWK SAFETY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 675 PROGRESS CENTER AVE STE H LAWRENCEVILLE GA 30043 USA "
    },
    {
      "Location ID": "305DS",
      "Location Name": "DRESS UP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 961 CHESTNUT ST SE GAINESVILLE GA 30501-6902 USA "
    },
    {
      "Location ID": "305FT",
      "Location Name": "FASHION TEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5152 BELLE WOOD CT STE A BUFORD GA 30518-5881 USA "
    },
    {
      "Location ID": "305MO",
      "Location Name": "MERCIER ORCHARDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8660 BLUE RIDGE DR BLUE RIDGE GA 30513-4117 USA "
    },
    {
      "Location ID": "305OS",
      "Location Name": "ONE STOP FAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4979 BRISTOL INDUSTRIAL BLVD STE 200 BUFORD GA 30518-1772 USA "
    },
    {
      "Location ID": "305PW",
      "Location Name": "PET WISH PRO (BUFORD)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5152 BELLWOOD CT STE E BUFORD GA 30518-5881 USA "
    },
    {
      "Location ID": "305SS",
      "Location Name": "SENSORY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4992 BRISTOL INDUSTRIAL BLVD BUFORD GA 30518-1772 USA "
    },
    {
      "Location ID": "306",
      "Location Name": "ATHENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 575 OLYMPIC DR ATHENS GA 30601 USA "
    },
    {
      "Location ID": "30601",
      "Location Name": "ATHENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 575 OLYMPIC DR ATHENS GA 30601 USA "
    },
    {
      "Location ID": "30606",
      "Location Name": "PROMENADE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3654 ATLANTA HWY STE M ATHENS GA 30606 USA "
    },
    {
      "Location ID": "30656",
      "Location Name": "MONROE CAX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 SARATOGA DR MONROE GA 30656 USA "
    },
    {
      "Location ID": "306BB",
      "Location Name": "BURTON AND BURTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 325 CLEVELAND RD BOGART GA 30622 USA "
    },
    {
      "Location ID": "306CH",
      "Location Name": "CHICOS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1275 BARROW INDUSTRIAL PKWY WINDER GA 30680 USA "
    },
    {
      "Location ID": "306CR",
      "Location Name": "OCONEE OFF ROAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3975 ATLANTA HWY ATHENS GA 30606 USA "
    },
    {
      "Location ID": "306GA",
      "Location Name": "GREENSBORO CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1041 WESTEND DR STE 110 GREENSBORO GA 30642 USA "
    },
    {
      "Location ID": "306ML",
      "Location Name": "MERCHANDISE LIQUIDATORS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 328 COMMERCE BLVD ATHENS GA 30606 USA "
    },
    {
      "Location ID": "306RD",
      "Location Name": "RED DRESS BOUTIQUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 240 COLLINS INDUSTRIAL BLVD ATHENS GA 30601 USA "
    },
    {
      "Location ID": "306TA",
      "Location Name": "TOPS AUTO PARTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 143 BEN BURTON CIR BOGART GA 30622 USA "
    },
    {
      "Location ID": "306UP",
      "Location Name": "UBER PRINTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 240 COLLINS INDUSTRIAL BLVD ATHENS GA 30601 USA "
    },
    {
      "Location ID": "30701",
      "Location Name": "CALHOUN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 COURT ST CALHOUN GA 30701 USA "
    },
    {
      "Location ID": "30705",
      "Location Name": "CHATSWORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 W FORT ST CHATSWORTH GA 30705 USA "
    },
    {
      "Location ID": "30707",
      "Location Name": "CHICKAMAUGA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 207 W 10TH ST CHICKAMAUGA GA 30707 USA "
    },
    {
      "Location ID": "30708",
      "Location Name": "CISCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12539 HIGHWAY 411 N CISCO GA 30708 USA "
    },
    {
      "Location ID": "30710",
      "Location Name": "COHUTTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 325 WOLFE ST COHUTTA GA 30710 USA "
    },
    {
      "Location ID": "30711",
      "Location Name": "CRANDALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6410 HIGHWAY 411 N CRANDALL GA 30711 USA "
    },
    {
      "Location ID": "30720",
      "Location Name": "DALTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 S THORNTON AVE DALTON GA 30720 USA "
    },
    {
      "Location ID": "30724",
      "Location Name": "ETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 219 CCC CAMP RD ETON GA 30724 USA "
    },
    {
      "Location ID": "30726",
      "Location Name": "GRAYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 VAUGHN ST GRAYSVILLE GA 30726 USA "
    },
    {
      "Location ID": "30728",
      "Location Name": "LA FAYETTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 FOSTER BLVD LA FAYETTE GA 30728 USA "
    },
    {
      "Location ID": "30730",
      "Location Name": "LYERLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 ALPINE ST LYERLY GA 30730 USA "
    },
    {
      "Location ID": "30731",
      "Location Name": "MENLO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2951 HIGHWAY 337 MENLO GA 30731 USA "
    },
    {
      "Location ID": "30732",
      "Location Name": "OAKMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 225 OAKMAN RD OAKMAN GA 30732 USA "
    },
    {
      "Location ID": "30733",
      "Location Name": "PLAINVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 133 YORK DR SW PLAINVILLE GA 30733 USA "
    },
    {
      "Location ID": "30734",
      "Location Name": "RANGER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 US HIGHWAY 411 SE RANGER GA 30734 USA "
    },
    {
      "Location ID": "30735",
      "Location Name": "RESACA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3073 BATTLEFIELD PKWY NE RESACA GA 30735 USA "
    },
    {
      "Location ID": "30736",
      "Location Name": "RINGGOLD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 862 LAFAYETTE ST RINGGOLD GA 30736 USA "
    },
    {
      "Location ID": "30738",
      "Location Name": "RISING FAWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 MASON RD RISING FAWN GA 30738 USA "
    },
    {
      "Location ID": "30739",
      "Location Name": "ROCK SPRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 ROCK SPRING DR ROCK SPRING GA 30739 USA "
    },
    {
      "Location ID": "30740",
      "Location Name": "ROCKY FACE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2609 CHATTANOOGA RD ROCKY FACE GA 30740 USA "
    },
    {
      "Location ID": "30746",
      "Location Name": "SUGAR VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 155 MAIN ST NW SUGAR VALLEY GA 30746 USA "
    },
    {
      "Location ID": "30747",
      "Location Name": "SUMMERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9922 COMMERCE ST SUMMERVILLE GA 30747 USA "
    },
    {
      "Location ID": "30751",
      "Location Name": "TENNGA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 57 TENNGA GREGORY RD TENNGA GA 30751 USA "
    },
    {
      "Location ID": "30752",
      "Location Name": "TRENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12441 N MAIN ST TRENTON GA 30752 USA "
    },
    {
      "Location ID": "30753",
      "Location Name": "TRION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14366 HIGHWAY 27 TRION GA 30753 USA "
    },
    {
      "Location ID": "30755",
      "Location Name": "TUNNEL HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 S VARNELL RD TUNNEL HILL GA 30755 USA "
    },
    {
      "Location ID": "30756",
      "Location Name": "VARNELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 E VARNELL MAIN ST VARNELL GA 30756 USA "
    },
    {
      "Location ID": "30757",
      "Location Name": "WILDWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20605 HIGHWAY 11 WILDWOOD GA 30757 USA "
    },
    {
      "Location ID": "307PT",
      "Location Name": "PALLET TECH SOLUTIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 365 PATTERSON AVE FORT OGLETHORPE GA 30742-3142 USA "
    },
    {
      "Location ID": "308",
      "Location Name": "LOG AUGUSTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 525 8TH ST AUGUSTA GA 30901 USA "
    },
    {
      "Location ID": "30803",
      "Location Name": "AVERA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9448 BROAD ST AVERA GA 30803 USA "
    },
    {
      "Location ID": "30805",
      "Location Name": "BLYTHE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 141 GIN HOUSE ST BLYTHE GA 30805 USA "
    },
    {
      "Location ID": "30809",
      "Location Name": "EVANS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 607 RONALD REAGAN DR EVANS GA 30809 USA "
    },
    {
      "Location ID": "30810",
      "Location Name": "GIBSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 W MAIN ST GIBSON GA 30810 USA "
    },
    {
      "Location ID": "30812",
      "Location Name": "GRACEWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 MYRTLE BLVD GRACEWOOD GA 30812 USA "
    },
    {
      "Location ID": "30813",
      "Location Name": "GROVETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 JOHN DEERE PKWY GROVETOWN GA 30813 USA "
    },
    {
      "Location ID": "30814",
      "Location Name": "HARLEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 335 W MILLEDGEVILLE RD HARLEM GA 30814 USA "
    },
    {
      "Location ID": "30815",
      "Location Name": "HEPHZIBAH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4902 WINDSOR SPRING RD HEPHZIBAH GA 30815 USA "
    },
    {
      "Location ID": "30816",
      "Location Name": "KEYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 OLD WAYNESBORO RD KEYSVILLE GA 30816 USA "
    },
    {
      "Location ID": "30820",
      "Location Name": "MITCHELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6488 GA HIGHWAY 102 W MITCHELL GA 30820 USA "
    },
    {
      "Location ID": "30822",
      "Location Name": "PERKINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1664 PERKINS GREEN FORK RD PERKINS GA 30822 USA "
    },
    {
      "Location ID": "30823",
      "Location Name": "STAPLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 135 E MAIN ST STAPLETON GA 30823 USA "
    },
    {
      "Location ID": "30824",
      "Location Name": "THOMSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 223 JACKSON ST THOMSON GA 30824 USA "
    },
    {
      "Location ID": "30830",
      "Location Name": "WAYNESBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 240 E 6TH ST WAYNESBORO GA 30830 USA "
    },
    {
      "Location ID": "30833",
      "Location Name": "WRENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 ESTELLE ST WRENS GA 30833 USA "
    },
    {
      "Location ID": "308AZ",
      "Location Name": "AMAZON  AGS5 - APPLING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 785 INNOVATION PKWY APPLING GA 30802 USA "
    },
    {
      "Location ID": "30906",
      "Location Name": "PEACH ORCHARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3108 PEACH ORCHARD RD STE 8 AUGUSTA GA 30906 USA "
    },
    {
      "Location ID": "30907",
      "Location Name": "MARTINEZ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 COMMERCIAL BLVD AUGUSTA GA 30907 USA "
    },
    {
      "Location ID": "30909",
      "Location Name": "FOREST HILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3301 WRIGHTSBORO RD AUGUSTA GA 30909 USA "
    },
    {
      "Location ID": "30917",
      "Location Name": "AUGUSTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " US POSTAL SERVICE 125 COMMERICAL BLVD AUGUSTA GA 30917 USA "
    },
    {
      "Location ID": "30H",
      "Location Name": "ATLANTA STC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1970 MAPLE AVE ATLANTA GA 30336 USA "
    },
    {
      "Location ID": "30QWM",
      "Location Name": "WORLD MARKETING DORAVILLE QW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3101 MCCALL DR ATLANTA GA 30340 USA "
    },
    {
      "Location ID": "30Z",
      "Location Name": "ATLANTA NDC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1800 JAMES JACKSON PKWY NW ATLANTA GA 30369 USA "
    },
    {
      "Location ID": "310",
      "Location Name": "LOG MACON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 451 COLLEGE ST MACON GA 31213 USA "
    },
    {
      "Location ID": "31001",
      "Location Name": "ABBEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 MAIN ST W ABBEVILLE GA 31001 USA "
    },
    {
      "Location ID": "31002",
      "Location Name": "ADRIAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 W MAIN ST ADRIAN GA 31002 USA "
    },
    {
      "Location ID": "31003",
      "Location Name": "ALLENTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 61 1ST ST ALLENTOWN GA 31003 USA "
    },
    {
      "Location ID": "31004",
      "Location Name": "BOLINGBROKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8043 RIVOLI RD BOLINGBROKE GA 31004 USA "
    },
    {
      "Location ID": "31005",
      "Location Name": "BONAIRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 604 GA HIGHWAY 247 S BONAIRE GA 31005 USA "
    },
    {
      "Location ID": "31006",
      "Location Name": "BUTLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 E MAIN ST BUTLER GA 31006 USA "
    },
    {
      "Location ID": "31007",
      "Location Name": "BYROMVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 452 MAIN ST BYROMVILLE GA 31007 USA "
    },
    {
      "Location ID": "31008",
      "Location Name": "BYRON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 GA HIGHWAY 49 S BYRON GA 31008 USA "
    },
    {
      "Location ID": "31009",
      "Location Name": "CADWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1010 COLEMAN ST CADWELL GA 31009 USA "
    },
    {
      "Location ID": "31011",
      "Location Name": "CHAUNCEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 47 DURHAM ST CHAUNCEY GA 31011 USA "
    },
    {
      "Location ID": "31012",
      "Location Name": "CHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 MAIN ST W CHESTER GA 31012 USA "
    },
    {
      "Location ID": "31013",
      "Location Name": "CLINCHFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2620 S US HIGHWAY 341 CLINCHFIELD GA 31013 USA "
    },
    {
      "Location ID": "31014",
      "Location Name": "COCHRAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 N 2ND ST COCHRAN GA 31014 USA "
    },
    {
      "Location ID": "31015",
      "Location Name": "CORDELE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 S 6TH ST CORDELE GA 31015 USA "
    },
    {
      "Location ID": "31016",
      "Location Name": "CULLODEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 KNOXVILLE ST CULLODEN GA 31016 USA "
    },
    {
      "Location ID": "31018",
      "Location Name": "DAVISBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 N MAIN ST DAVISBORO GA 31018 USA "
    },
    {
      "Location ID": "31019",
      "Location Name": "DEXTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 E MAIN ST DEXTER GA 31019 USA "
    },
    {
      "Location ID": "31020",
      "Location Name": "DRY BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5615 JEFFERSONVILLE RD DRY BRANCH GA 31020 USA "
    },
    {
      "Location ID": "31021",
      "Location Name": "DUBLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 514 BELLEVUE AVE DUBLIN GA 31021 USA "
    },
    {
      "Location ID": "31023",
      "Location Name": "EASTMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 2ND AVE EASTMAN GA 31023 USA "
    },
    {
      "Location ID": "31024",
      "Location Name": "EATONTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 410 E SUMTER ST EATONTON GA 31024 USA "
    },
    {
      "Location ID": "31025",
      "Location Name": "ELKO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2601 ELKO RD ELKO GA 31025 USA "
    },
    {
      "Location ID": "31028",
      "Location Name": "CENTERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 628 N HOUSTON LAKE BLVD CENTERVILLE GA 31028 USA "
    },
    {
      "Location ID": "31029",
      "Location Name": "FORSYTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 65 W JOHNSTON ST FORSYTH GA 31029 USA "
    },
    {
      "Location ID": "31030",
      "Location Name": "FORT VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 ANDERSON AVE FORT VALLEY GA 31030 USA "
    },
    {
      "Location ID": "31031",
      "Location Name": "GORDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 290 MILLEDGEVILLE HWY GORDON GA 31031 USA "
    },
    {
      "Location ID": "31032",
      "Location Name": "GRAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 235 W CLINTON ST GRAY GA 31032 USA "
    },
    {
      "Location ID": "31033",
      "Location Name": "HADDOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1355 GA HIGHWAY 22 E HADDOCK GA 31033 USA "
    },
    {
      "Location ID": "31034",
      "Location Name": "HARDWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1870 IRWINTON RD HARDWICK GA 31034 USA "
    },
    {
      "Location ID": "31035",
      "Location Name": "HARRISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 N RAILROAD AVE HARRISON GA 31035 USA "
    },
    {
      "Location ID": "31036",
      "Location Name": "HAWKINSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 148 COMMERCE ST HAWKINSVILLE GA 31036 USA "
    },
    {
      "Location ID": "31037",
      "Location Name": "HELENA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 S FORSYTH AVE MC RAE HELENA GA 31037 USA "
    },
    {
      "Location ID": "31038",
      "Location Name": "HILLSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1917 GA HIGHWAY 11 S HILLSBORO GA 31038 USA "
    },
    {
      "Location ID": "31039",
      "Location Name": "HOWARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 336 BROWN RD HOWARD GA 31039 USA "
    },
    {
      "Location ID": "31040",
      "Location Name": "DUBLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1826 INDUSTRIAL BLVD DUBLIN GA 31021 USA "
    },
    {
      "Location ID": "31041",
      "Location Name": "IDEAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 603 TOM WATSON AVE IDEAL GA 31041 USA "
    },
    {
      "Location ID": "31042",
      "Location Name": "IRWINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 ASBELL ST IRWINTON GA 31042 USA "
    },
    {
      "Location ID": "31044",
      "Location Name": "JEFFERSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13718 US HIGHWAY 80 JEFFERSONVILLE GA 31044 USA "
    },
    {
      "Location ID": "31045",
      "Location Name": "JEWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10862 MACON HWY JEWELL GA 31045 USA "
    },
    {
      "Location ID": "31046",
      "Location Name": "JULIETTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 356 BOWDOIN RD JULIETTE GA 31046 USA "
    },
    {
      "Location ID": "31047",
      "Location Name": "KATHLEEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1010 GA HIGHWAY 247 S KATHLEEN GA 31047 USA "
    },
    {
      "Location ID": "31049",
      "Location Name": "KITE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1123 RAILROAD ST KITE GA 31049 USA "
    },
    {
      "Location ID": "31051",
      "Location Name": "LILLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 122 WADLEY ST LILLY GA 31051 USA "
    },
    {
      "Location ID": "31052",
      "Location Name": "LIZELLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2899 WILLIAMSON DR LIZELLA GA 31052 USA "
    },
    {
      "Location ID": "31054",
      "Location Name": "MC INTYRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 RAILROAD ST MC INTYRE GA 31054 USA "
    },
    {
      "Location ID": "31055",
      "Location Name": "MC RAE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23 S SECOND AVE MC RAE HELENA GA 31055 USA "
    },
    {
      "Location ID": "31057",
      "Location Name": "MARSHALLVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 CAMELLIA BLVD MARSHALLVILLE GA 31057 USA "
    },
    {
      "Location ID": "31058",
      "Location Name": "MAUK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 GA HIGHWAY 127 W MAUK GA 31058 USA "
    },
    {
      "Location ID": "31060",
      "Location Name": "MILAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 322 LEE ST MILAN GA 31060 USA "
    },
    {
      "Location ID": "31061",
      "Location Name": "MILLEDGEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 E HANCOCK ST LBBY MILLEDGEVILLE GA 31061 USA "
    },
    {
      "Location ID": "31063",
      "Location Name": "MONTEZUMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 CABOT ST MONTEZUMA GA 31063 USA "
    },
    {
      "Location ID": "31064",
      "Location Name": "MONTICELLO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 145 E WASHINGTON ST MONTICELLO GA 31064 USA "
    },
    {
      "Location ID": "31065",
      "Location Name": "MONTROSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4334 US HIGHWAY 80 W MONTROSE GA 31065 USA "
    },
    {
      "Location ID": "31066",
      "Location Name": "MUSELLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 POST OFFICE ST MUSELLA GA 31066 USA "
    },
    {
      "Location ID": "31067",
      "Location Name": "OCONEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 FAIRBANKS ST OCONEE GA 31067 USA "
    },
    {
      "Location ID": "31068",
      "Location Name": "OGLETHORPE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 N SUMTER ST OGLETHORPE GA 31068 USA "
    },
    {
      "Location ID": "31069",
      "Location Name": "PERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1400 MACON RD PERRY GA 31069 USA "
    },
    {
      "Location ID": "31070",
      "Location Name": "PINEHURST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 551 CYPRESS AVE PINEHURST GA 31070 USA "
    },
    {
      "Location ID": "31071",
      "Location Name": "PINEVIEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 135 COMMERCE ST E PINEVIEW GA 31071 USA "
    },
    {
      "Location ID": "31072",
      "Location Name": "PITTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 8TH ST S PITTS GA 31072 USA "
    },
    {
      "Location ID": "31075",
      "Location Name": "RENTZ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2120 W RAILROAD ST RENTZ GA 31075 USA "
    },
    {
      "Location ID": "31076",
      "Location Name": "REYNOLDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 N CRAWFORD ST REYNOLDS GA 31076 USA "
    },
    {
      "Location ID": "31077",
      "Location Name": "RHINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 1ST ST RHINE GA 31077 USA "
    },
    {
      "Location ID": "31078",
      "Location Name": "ROBERTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 160 N DUGGER AVE ROBERTA GA 31078 USA "
    },
    {
      "Location ID": "31079",
      "Location Name": "ROCHELLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 577 2ND AVE ROCHELLE GA 31079 USA "
    },
    {
      "Location ID": "31081",
      "Location Name": "RUPERT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 208 J RAN COOPER RD RUPERT GA 31081 USA "
    },
    {
      "Location ID": "31082",
      "Location Name": "SANDERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 W CHURCH ST SANDERSVILLE GA 31082 USA "
    },
    {
      "Location ID": "31083",
      "Location Name": "SCOTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 974 MAIN ST SCOTLAND GA 31083 USA "
    },
    {
      "Location ID": "31085",
      "Location Name": "SHADY DALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22591 N MAIN ST SHADY DALE GA 31085 USA "
    },
    {
      "Location ID": "31086",
      "Location Name": "SMARR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2783 US HIGHWAY 41 S SMARR GA 31086 USA "
    },
    {
      "Location ID": "31087",
      "Location Name": "SPARTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12877 BROAD ST STE 102 SPARTA GA 31087 USA "
    },
    {
      "Location ID": "31088",
      "Location Name": "WARNER ROBINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 904 RUSSELL PKWY WARNER ROBINS GA 31088 USA "
    },
    {
      "Location ID": "31089",
      "Location Name": "TENNILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 131 SMITH ST TENNILLE GA 31089 USA "
    },
    {
      "Location ID": "31090",
      "Location Name": "TOOMSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 153 MAIN ST TOOMSBORO GA 31090 USA "
    },
    {
      "Location ID": "31091",
      "Location Name": "UNADILLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1885 PINE ST UNADILLA GA 31091 USA "
    },
    {
      "Location ID": "31092",
      "Location Name": "VIENNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 W COTTON ST VIENNA GA 31092 USA "
    },
    {
      "Location ID": "31094",
      "Location Name": "WARTHEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7753 GA HIGHWAY 15 N WARTHEN GA 31094 USA "
    },
    {
      "Location ID": "31096",
      "Location Name": "WRIGHTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8648 S MARCUS ST WRIGHTSVILLE GA 31096 USA "
    },
    {
      "Location ID": "31097",
      "Location Name": "YATESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 EMPIRE ST YATESVILLE GA 31097 USA "
    },
    {
      "Location ID": "310AA",
      "Location Name": "MILLEDGEVILLE CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 170 GARRETT WAY NW MILLEDGEVILLE GA 31061 USA "
    },
    {
      "Location ID": "310AN",
      "Location Name": "LOG SOUTH MACON ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1740 ROCKY CREEK RD REAR MACON GA 31206 USA "
    },
    {
      "Location ID": "310AS",
      "Location Name": "ACADEMY SPORTS WAREHOUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 ACADEMY DR JEFFERSONVLLE GA 31044 USA "
    },
    {
      "Location ID": "310AX",
      "Location Name": "LOG HOLT AVE GA ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 595 HOLT AVE MACON GA 31204 USA "
    },
    {
      "Location ID": "310BB",
      "Location Name": "BEST BUY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 BEST BUY DR DUBLIN GA 31021 USA "
    },
    {
      "Location ID": "310CA",
      "Location Name": "CORDELE CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 302 N 6TH ST CORDELE GA 31015 USA "
    },
    {
      "Location ID": "310HB",
      "Location Name": "HABAND COMPANY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 148 INDUSTRIAL BLVD EATONTON GA 31024 USA "
    },
    {
      "Location ID": "313",
      "Location Name": "SAVANNAH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 BOURNE BLVD SAVANNAH GA 31408 USA "
    },
    {
      "Location ID": "31301",
      "Location Name": "ALLENHURST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4282 W OGLETHORPE HWY ALLENHURST GA 31301 USA "
    },
    {
      "Location ID": "31302",
      "Location Name": "BLOOMINGDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 407 E US HIGHWAY 80 BLOOMINGDALE GA 31302 USA "
    },
    {
      "Location ID": "31304",
      "Location Name": "CRESCENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4236 GA HIGHWAY 99 CRESCENT GA 31304 USA "
    },
    {
      "Location ID": "31305",
      "Location Name": "DARIEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 501 NORTH WAY DARIEN GA 31305 USA "
    },
    {
      "Location ID": "31307",
      "Location Name": "EDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1228 US HIGHWAY 80 EDEN GA 31307 USA "
    },
    {
      "Location ID": "31312",
      "Location Name": "GUYTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 711 W CENTRAL BLVD GUYTON GA 31312 USA "
    },
    {
      "Location ID": "31313",
      "Location Name": "HINESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 744 W OGLETHORPE HWY HINESVILLE GA 31313 USA "
    },
    {
      "Location ID": "31314",
      "Location Name": "FORT STEWART",
      "Time Zone Offset (Hours)": 0,
      "Address": " 843 HASE RD FORT STEWART GA 31314 USA "
    },
    {
      "Location ID": "31316",
      "Location Name": "LUDOWICI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 E ACADEMY ST LUDOWICI GA 31316 USA "
    },
    {
      "Location ID": "31318",
      "Location Name": "MELDRIM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 318 5TH ST MELDRIM GA 31318 USA "
    },
    {
      "Location ID": "31319",
      "Location Name": "MERIDIAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10034 GA HIGHWAY 99 MERIDIAN GA 31319 USA "
    },
    {
      "Location ID": "31320",
      "Location Name": "MIDWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 BUTLER ST MIDWAY GA 31320 USA "
    },
    {
      "Location ID": "31321",
      "Location Name": "PEMBROKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 298 LEDFORD ST PEMBROKE GA 31321 USA "
    },
    {
      "Location ID": "31322",
      "Location Name": "POOLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1030 US HIGHWAY 80 W POOLER GA 31322 USA "
    },
    {
      "Location ID": "31323",
      "Location Name": "RICEBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4705 S COASTAL HWY RICEBORO GA 31323 USA "
    },
    {
      "Location ID": "31324",
      "Location Name": "RICHMOND HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9664 FORD AVE RICHMOND HILL GA 31324 USA "
    },
    {
      "Location ID": "31326",
      "Location Name": "RINCON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 503 N RIDGE ST RINCON GA 31326 USA "
    },
    {
      "Location ID": "31327",
      "Location Name": "SAPELO ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 LONG TABBY LN SAPELO ISLAND GA 31327 USA "
    },
    {
      "Location ID": "31328",
      "Location Name": "TYBEE ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 S CAMPBELL AVE TYBEE ISLAND GA 31328 USA "
    },
    {
      "Location ID": "31329",
      "Location Name": "SPRINGFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 708 S LAUREL ST SPRINGFIELD GA 31329 USA "
    },
    {
      "Location ID": "31331",
      "Location Name": "TOWNSEND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1219 GA HIGHWAY 57 TOWNSEND GA 31331 USA "
    },
    {
      "Location ID": "31333",
      "Location Name": "WALTHOURVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 52 TALMADGE RD WALTHOURVILLE GA 31333 USA "
    },
    {
      "Location ID": "31401",
      "Location Name": "SAVANNAH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 N FAHM ST RM 1A SAVANNAH GA 31401 USA "
    },
    {
      "Location ID": "314SU",
      "Location Name": "SAVANNAH STATE (SSU), GA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3219 COLLEGE ST SAVANNAH GA 31404 USA "
    },
    {
      "Location ID": "31501",
      "Location Name": "WAYCROSS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 601 TEBEAU ST FL 1 WAYCROSS GA 31501 USA "
    },
    {
      "Location ID": "31510",
      "Location Name": "ALMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 523 W 12TH ST ALMA GA 31510 USA "
    },
    {
      "Location ID": "31512",
      "Location Name": "AMBROSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 PINE ST AMBROSE GA 31512 USA "
    },
    {
      "Location ID": "31513",
      "Location Name": "BAXLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 N OAK ST BAXLEY GA 31513 USA "
    },
    {
      "Location ID": "31516",
      "Location Name": "BLACKSHEAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 327 MAIN ST BLACKSHEAR GA 31516 USA "
    },
    {
      "Location ID": "31518",
      "Location Name": "BRISTOL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1983 GA HIGHWAY 121 BRISTOL GA 31518 USA "
    },
    {
      "Location ID": "31519",
      "Location Name": "BROXTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 OCMULGEE ST E BROXTON GA 31519 USA "
    },
    {
      "Location ID": "31520",
      "Location Name": "BRUNSWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 805 GLOUCESTER ST BRUNSWICK GA 31520 USA "
    },
    {
      "Location ID": "31522",
      "Location Name": "SAINT SIMONS ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 260 EDWARDS PLZ SAINT SIMONS ISLAND GA 31522 USA "
    },
    {
      "Location ID": "31527",
      "Location Name": "JEKYLL ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 PIER RD UNIT B JEKYLL ISLAND GA 31527 USA "
    },
    {
      "Location ID": "31532",
      "Location Name": "DENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 E GEORGIA AVE DENTON GA 31532 USA "
    },
    {
      "Location ID": "31533",
      "Location Name": "DOUGLAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 MADISON AVE S DOUGLAS GA 31533 USA "
    },
    {
      "Location ID": "31537",
      "Location Name": "FOLKSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3845 MAIN ST FOLKSTON GA 31537 USA "
    },
    {
      "Location ID": "31539",
      "Location Name": "HAZLEHURST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 S CROMARTIE ST HAZLEHURST GA 31539 USA "
    },
    {
      "Location ID": "31542",
      "Location Name": "HOBOKEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4850 MAIN ST E HOBOKEN GA 31542 USA "
    },
    {
      "Location ID": "31543",
      "Location Name": "HORTENSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4779 HIGHWAY 32 W HORTENSE GA 31543 USA "
    },
    {
      "Location ID": "31545",
      "Location Name": "JESUP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 405 E WALNUT ST JESUP GA 31546 USA "
    },
    {
      "Location ID": "31547",
      "Location Name": "CPU KINGS BAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1360 USS SIMON BOLIVAR RD KINGS BAY GA 31547 USA "
    },
    {
      "Location ID": "31548",
      "Location Name": "KINGSLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 310 S GROVE BLVD KINGSLAND GA 31548 USA "
    },
    {
      "Location ID": "31549",
      "Location Name": "LUMBER CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 68 N CHURCH ST LUMBER CITY GA 31549 USA "
    },
    {
      "Location ID": "31551",
      "Location Name": "MERSHON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2547 GA HIGHWAY 32 MERSHON GA 31551 USA "
    },
    {
      "Location ID": "31553",
      "Location Name": "NAHUNTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10119 MAIN ST N NAHUNTA GA 31553 USA "
    },
    {
      "Location ID": "31554",
      "Location Name": "NICHOLLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 N LIBERTY ST NICHOLLS GA 31554 USA "
    },
    {
      "Location ID": "31555",
      "Location Name": "ODUM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 219 S CHURCH ST ODUM GA 31555 USA "
    },
    {
      "Location ID": "31556",
      "Location Name": "OFFERMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7203 US HIGHWAY 84 OFFERMAN GA 31556 USA "
    },
    {
      "Location ID": "31557",
      "Location Name": "PATTERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6469 US HIGHWAY 84 PATTERSON GA 31557 USA "
    },
    {
      "Location ID": "31558",
      "Location Name": "SAINT MARYS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 724 CHARLIE SMITH SR HWY SAINT MARYS GA 31558 USA "
    },
    {
      "Location ID": "31560",
      "Location Name": "SCREVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 W JL TYRE ST SCREVEN GA 31560 USA "
    },
    {
      "Location ID": "31561",
      "Location Name": "SEA ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 351 SEA ISLAND RD STE 104 SAINT SIMONS ISLAND GA 31522 USA "
    },
    {
      "Location ID": "31562",
      "Location Name": "SAINT GEORGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12995 FLORIDA AVE STE A SAINT GEORGE GA 31562 USA "
    },
    {
      "Location ID": "31563",
      "Location Name": "SURRENCY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 GA HIGHWAY 121 SE SURRENCY GA 31563 USA "
    },
    {
      "Location ID": "31564",
      "Location Name": "WARESBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4308 ALBANY AVE WARESBORO GA 31564 USA "
    },
    {
      "Location ID": "31565",
      "Location Name": "WAVERLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24311 US HIGHWAY 17 WAVERLY GA 31565 USA "
    },
    {
      "Location ID": "31566",
      "Location Name": "WAYNESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25380 HIGHWAY 82 WAYNESVILLE GA 31566 USA "
    },
    {
      "Location ID": "31567",
      "Location Name": "WEST GREEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9978 US HIGHWAY 221 N WEST GREEN GA 31567 USA "
    },
    {
      "Location ID": "31568",
      "Location Name": "WHITE OAK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 MCKINNON RD N WHITE OAK GA 31568 USA "
    },
    {
      "Location ID": "31569",
      "Location Name": "WOODBINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 BEDELL AVE WOODBINE GA 31569 USA "
    },
    {
      "Location ID": "315AX",
      "Location Name": "BLACKSHEAR CARRIER ANX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3297 US HIGHWAY 84 BLACKSHEAR GA 31516 USA "
    },
    {
      "Location ID": "315HO",
      "Location Name": "HOPES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 SPAULDING CT BRUNSWICK GA 31525 USA "
    },
    {
      "Location ID": "315MH",
      "Location Name": "MIRACLE HAIR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 OWENS LN BRUNSWICK GA 31525 USA "
    },
    {
      "Location ID": "31602",
      "Location Name": "NORTHSIDE VALDOSTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3698 INNER PERIMETER RD VALDOSTA GA 31602 USA "
    },
    {
      "Location ID": "31625",
      "Location Name": "BARNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13841 GA HIGHWAY 122 BARNEY GA 31625 USA "
    },
    {
      "Location ID": "31632",
      "Location Name": "HAHIRA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 112 N CHURCH ST HAHIRA GA 31632 USA "
    },
    {
      "Location ID": "31638",
      "Location Name": "MORVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 GORDON ST MORVEN GA 31638 USA "
    },
    {
      "Location ID": "317",
      "Location Name": "ALBANY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1501 S SLAPPEY BLVD ALBANY GA 31701 USA "
    },
    {
      "Location ID": "31709",
      "Location Name": "AMERICUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 128 E FORSYTH ST STE 100 AMERICUS GA 31709 USA "
    },
    {
      "Location ID": "31712",
      "Location Name": "ARABI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4185 BEDGOOD AVE ARABI GA 31712 USA "
    },
    {
      "Location ID": "31714",
      "Location Name": "ASHBURN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 218 E COLLEGE AVE ASHBURN GA 31714 USA "
    },
    {
      "Location ID": "31716",
      "Location Name": "BACONTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 140 E WALTON ST BACONTON GA 31716 USA "
    },
    {
      "Location ID": "31722",
      "Location Name": "BERLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 293 N LANGFORD ST BERLIN GA 31722 USA "
    },
    {
      "Location ID": "31730",
      "Location Name": "CAMILLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 E BROAD ST CAMILLA GA 31730 USA "
    },
    {
      "Location ID": "31735",
      "Location Name": "COBB",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 PRYOR COBB RD COBB GA 31735 USA "
    },
    {
      "Location ID": "31743",
      "Location Name": "DE SOTO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 360 BAGLEY ST DE SOTO GA 31743 USA "
    },
    {
      "Location ID": "31747",
      "Location Name": "ELLENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 158 BAKER ST ELLENTON GA 31747 USA "
    },
    {
      "Location ID": "31750",
      "Location Name": "FITZGERALD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 124 N MAIN ST FITZGERALD GA 31750 USA "
    },
    {
      "Location ID": "31756",
      "Location Name": "HARTSFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7226 GA HIGHWAY 37 W HARTSFIELD GA 31756 USA "
    },
    {
      "Location ID": "31763",
      "Location Name": "LEESBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 152 ROBERT B LEE DR LEESBURG GA 31763 USA "
    },
    {
      "Location ID": "31765",
      "Location Name": "MEIGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2010 E DEPOT ST MEIGS GA 31765 USA "
    },
    {
      "Location ID": "31768",
      "Location Name": "MOULTRIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 N MAIN ST MOULTRIE GA 31768 USA "
    },
    {
      "Location ID": "31769",
      "Location Name": "MYSTIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 224 JACKSON ST MYSTIC GA 31769 USA "
    },
    {
      "Location ID": "31772",
      "Location Name": "OAKFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 408 WHITEHALL ST OAKFIELD GA 31772 USA "
    },
    {
      "Location ID": "31773",
      "Location Name": "OCHLOCKNEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2065 SPENCE ST OCHLOCKNEE GA 31773 USA "
    },
    {
      "Location ID": "31774",
      "Location Name": "OCILLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 S IRWIN AVE OCILLA GA 31774 USA "
    },
    {
      "Location ID": "31779",
      "Location Name": "PELHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 160 MATHEWSON AVE SW PELHAM GA 31779 USA "
    },
    {
      "Location ID": "31780",
      "Location Name": "PLAINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 MAIN ST PLAINS GA 31780 USA "
    },
    {
      "Location ID": "31781",
      "Location Name": "POULAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 BROAD ST SE POULAN GA 31781 USA "
    },
    {
      "Location ID": "31782",
      "Location Name": "PUTNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4314 RADIUM SPRINGS RD PUTNEY GA 31782 USA "
    },
    {
      "Location ID": "31783",
      "Location Name": "REBECCA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41 N RAILROAD ST REBECCA GA 31783 USA "
    },
    {
      "Location ID": "31784",
      "Location Name": "SALE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 166 S BARNES ST SALE CITY GA 31784 USA "
    },
    {
      "Location ID": "31787",
      "Location Name": "SMITHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 LE CONTE ST SMITHVILLE GA 31787 USA "
    },
    {
      "Location ID": "31789",
      "Location Name": "SUMNER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 501 MAIN ST SUMNER GA 31789 USA "
    },
    {
      "Location ID": "31790",
      "Location Name": "SYCAMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 W JEWETT ST SYCAMORE GA 31790 USA "
    },
    {
      "Location ID": "31791",
      "Location Name": "SYLVESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 404 HARDY ST SYLVESTER GA 31791 USA "
    },
    {
      "Location ID": "31792",
      "Location Name": "THOMASVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 404 N BROAD ST STE 101 THOMASVILLE GA 31792 USA "
    },
    {
      "Location ID": "31794",
      "Location Name": "TIFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 JOHN HOWARD WAY TIFTON GA 31794 USA "
    },
    {
      "Location ID": "31795",
      "Location Name": "TY TY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 N PICKETT ST TY TY GA 31795 USA "
    },
    {
      "Location ID": "31796",
      "Location Name": "WARWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 DOGWOOD ST SW WARWICK GA 31796 USA "
    },
    {
      "Location ID": "317BK",
      "Location Name": "BELKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15023 US 19 S THOMASVILLE GA 31792 USA "
    },
    {
      "Location ID": "317DD",
      "Location Name": "DDP MONOGRAMS & GIFTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1660 COLONIAL DR THOMASVILLE GA 31757 USA "
    },
    {
      "Location ID": "317FL",
      "Location Name": "TALL TIMBERS VLG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 LIBERTY ST THOMASVILLE GA 31757 USA "
    },
    {
      "Location ID": "317ON",
      "Location Name": "OUTDOOR NETWORK LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1113 SEMINOLE LN ALBANY GA 31707 USA "
    },
    {
      "Location ID": "318",
      "Location Name": "COLUMBUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3916 MILGEN RD COLUMBUS GA 31907 USA "
    },
    {
      "Location ID": "31801",
      "Location Name": "BOX SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6150 COLUMBUS HWY BOX SPRINGS GA 31801 USA "
    },
    {
      "Location ID": "31803",
      "Location Name": "BUENA VISTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 E 3RD AVE BUENA VISTA GA 31803 USA "
    },
    {
      "Location ID": "31804",
      "Location Name": "CATAULA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 PRESTON RD CATAULA GA 31804 USA "
    },
    {
      "Location ID": "31805",
      "Location Name": "CUSSETA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 173 WELLS ST CUSSETA GA 31805 USA "
    },
    {
      "Location ID": "31806",
      "Location Name": "ELLAVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 38 S BROAD ST ELLAVILLE GA 31806 USA "
    },
    {
      "Location ID": "31807",
      "Location Name": "ELLERSLIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3750 GA HIGHWAY 85 ELLERSLIE GA 31807 USA "
    },
    {
      "Location ID": "31808",
      "Location Name": "FORTSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2511 DOUBLE CHURCHES RD FORTSON GA 31808 USA "
    },
    {
      "Location ID": "31810",
      "Location Name": "GENEVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 446 COLUMBUS HWY GENEVA GA 31810 USA "
    },
    {
      "Location ID": "31811",
      "Location Name": "HAMILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9078 GA HIGHWAY 116 W HAMILTON GA 31811 USA "
    },
    {
      "Location ID": "31812",
      "Location Name": "JUNCTION CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 65 BROAD ST JUNCTION CITY GA 31812 USA "
    },
    {
      "Location ID": "31815",
      "Location Name": "LUMPKIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1688 BROAD ST LUMPKIN GA 31815 USA "
    },
    {
      "Location ID": "31816",
      "Location Name": "MANCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 415 W MAIN ST MANCHESTER GA 31816 USA "
    },
    {
      "Location ID": "31820",
      "Location Name": "MIDLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9232 COUNTY LINE RD MIDLAND GA 31820 USA "
    },
    {
      "Location ID": "31822",
      "Location Name": "PINE MOUNTAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 158 N MCDOUGALD AVE PINE MOUNTAIN GA 31822 USA "
    },
    {
      "Location ID": "31823",
      "Location Name": "PINE MOUNTAIN VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13642 GA HIGHWAY 116 PINE MOUNTAIN VALLEY GA 31823 USA "
    },
    {
      "Location ID": "31824",
      "Location Name": "PRESTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 190 MONTGOMERY ST PRESTON GA 31824 USA "
    },
    {
      "Location ID": "31825",
      "Location Name": "RICHLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 46 DEPOT ST RICHLAND GA 31825 USA "
    },
    {
      "Location ID": "31826",
      "Location Name": "SHILOH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21901 GA HIGHWAY 116 SHILOH GA 31826 USA "
    },
    {
      "Location ID": "31827",
      "Location Name": "TALBOTTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 S JEFFERSON AVE TALBOTTON GA 31827 USA "
    },
    {
      "Location ID": "31830",
      "Location Name": "WARM SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3795 WHITE HOUSE PKWY WARM SPRINGS GA 31830 USA "
    },
    {
      "Location ID": "31831",
      "Location Name": "WAVERLY HALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 99 RIDGEWAY RD WAVERLY HALL GA 31831 USA "
    },
    {
      "Location ID": "31832",
      "Location Name": "WESTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3286 WESTON BLVD WESTON GA 31832 USA "
    },
    {
      "Location ID": "31833",
      "Location Name": "WEST POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 729 4TH AVE FL 1 WEST POINT GA 31833 USA "
    },
    {
      "Location ID": "31836",
      "Location Name": "WOODLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 E MAIN ST WOODLAND GA 31836 USA "
    },
    {
      "Location ID": "31903",
      "Location Name": "BAKER VILLAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1435 BENNING DR COLUMBUS GA 31903 USA "
    },
    {
      "Location ID": "31905",
      "Location Name": "FORT MOORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 VIBBERT AVE FORT MOORE GA 31905 USA "
    },
    {
      "Location ID": "31908",
      "Location Name": "COLUMBUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3916 MILGEN RD COLUMBUS GA 31907 USA "
    },
    {
      "Location ID": "319TS",
      "Location Name": "TOTAL SYSTEMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7101 STONE MILL DR COLUMBUS GA 31909-4912 USA "
    },
    {
      "Location ID": "31MTE",
      "Location Name": "ATLANTA MTESC NEW BREED",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5400 FULTON INDUSTRIAL BLVD SW ATLANTA GA 30336 USA "
    },
    {
      "Location ID": "320",
      "Location Name": "LOG JACKSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1100 KINGS RD JACKSONVILLE FL 32203 USA "
    },
    {
      "Location ID": "32006",
      "Location Name": "FLEMING ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1880 EASTWEST PKWY FLEMING ISLAND FL 32003 USA "
    },
    {
      "Location ID": "32007",
      "Location Name": "BOSTWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1132 N HIGHWAY 17 BOSTWICK FL 32007 USA "
    },
    {
      "Location ID": "32008",
      "Location Name": "BRANFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 SUWANNEE AVE NW BRANFORD FL 32008 USA "
    },
    {
      "Location ID": "32009",
      "Location Name": "BRYCEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7875 US HIGHWAY 301 BRYCEVILLE FL 32009 USA "
    },
    {
      "Location ID": "32011",
      "Location Name": "CALLAHAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 450054 STATE ROAD 200 CALLAHAN FL 32011 USA "
    },
    {
      "Location ID": "32033",
      "Location Name": "ELKTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5785 COUNTY ROAD 305 ELKTON FL 32033 USA "
    },
    {
      "Location ID": "32034",
      "Location Name": "FERNANDINA BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1997 SADLER RD FERNANDINA BEACH FL 32034 USA "
    },
    {
      "Location ID": "32035",
      "Location Name": "DOWNTOWN FERNANDINA BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 CENTRE ST FERNANDINA BEACH FL 32034 USA "
    },
    {
      "Location ID": "32038",
      "Location Name": "FORT WHITE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 318 SW HOLSTEIN AVE FORT WHITE FL 32038 USA "
    },
    {
      "Location ID": "32040",
      "Location Name": "GLEN SAINT MARY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7345 W MOUNT VERNON ST GLEN SAINT MARY FL 32040 USA "
    },
    {
      "Location ID": "32042",
      "Location Name": "GRAHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9847 SW 104TH AVE GRAHAM FL 32042 USA "
    },
    {
      "Location ID": "32043",
      "Location Name": "GREEN COVE SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 PALMER ST GREEN COVE SPRINGS FL 32043 USA "
    },
    {
      "Location ID": "32044",
      "Location Name": "HAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5627 NAVARRE AVE HAMPTON FL 32044 USA "
    },
    {
      "Location ID": "32046",
      "Location Name": "HILLIARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 551835 US HIGHWAY 1 HILLIARD FL 32046 USA "
    },
    {
      "Location ID": "32052",
      "Location Name": "JASPER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 MARTIN LUTHER KING DR SE JASPER FL 32052 USA "
    },
    {
      "Location ID": "32053",
      "Location Name": "JENNINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1269 PLUM ST JENNINGS FL 32053 USA "
    },
    {
      "Location ID": "32054",
      "Location Name": "LAKE BUTLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 NW 1ST ST LAKE BUTLER FL 32054 USA "
    },
    {
      "Location ID": "32055",
      "Location Name": "LAKE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 NW MAIN BLVD LAKE CITY FL 32055 USA "
    },
    {
      "Location ID": "32058",
      "Location Name": "LAWTEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22706 US HIGHWAY 301 N LAWTEY FL 32058 USA "
    },
    {
      "Location ID": "32059",
      "Location Name": "LEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7760 E US HIGHWAY 90 LEE FL 32059 USA "
    },
    {
      "Location ID": "32062",
      "Location Name": "MC ALPIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17054 US HIGHWAY 129 MC ALPIN FL 32062 USA "
    },
    {
      "Location ID": "32063",
      "Location Name": "MACCLENNY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 W LOWDER ST MACCLENNY FL 32063 USA "
    },
    {
      "Location ID": "32064",
      "Location Name": "LIVE OAK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1415 OHIO AVE N LIVE OAK FL 32064 USA "
    },
    {
      "Location ID": "32066",
      "Location Name": "MAYO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 157 E MAIN ST MAYO FL 32066 USA "
    },
    {
      "Location ID": "32068",
      "Location Name": "MIDDLEBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2073 PALMETTO ST MIDDLEBURG FL 32068 USA "
    },
    {
      "Location ID": "32071",
      "Location Name": "O BRIEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22654 US HIGHWAY 129 O BRIEN FL 32071 USA "
    },
    {
      "Location ID": "32079",
      "Location Name": "PENNEY FARMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4380 LEWIS AVE PENNEY FARMS FL 32079 USA "
    },
    {
      "Location ID": "32082",
      "Location Name": "PONTE VEDRA BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 CORRIDOR RD PONTE VEDRA BEACH FL 32082 USA "
    },
    {
      "Location ID": "32083",
      "Location Name": "RAIFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13132 NE STATE ROAD 121 RAIFORD FL 32083 USA "
    },
    {
      "Location ID": "32084",
      "Location Name": "SAINT AUGUSTINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 99 KING ST SAINT AUGUSTINE FL 32084 USA "
    },
    {
      "Location ID": "32087",
      "Location Name": "SANDERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14470 US HIGHWAY 90 SANDERSON FL 32087 USA "
    },
    {
      "Location ID": "32091",
      "Location Name": "STARKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 122 N WALNUT ST STARKE FL 32091 USA "
    },
    {
      "Location ID": "32092",
      "Location Name": "WORLD GOLF VILLAGE ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 455 W TOWN PL SAINT AUGUSTINE FL 32092 USA "
    },
    {
      "Location ID": "32094",
      "Location Name": "WELLBORN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12096 COUNTY ROAD 137 WELLBORN FL 32094 USA "
    },
    {
      "Location ID": "32096",
      "Location Name": "WHITE SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16555 SPRING ST WHITE SPRINGS FL 32096 USA "
    },
    {
      "Location ID": "32097",
      "Location Name": "YULEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 463063 STATE ROAD 200 YULEE FL 32097 USA "
    },
    {
      "Location ID": "320AX",
      "Location Name": "ALVEREZ WAREHOUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13910 ALVEREZ DR JACKSONVILLE FL 32218 USA "
    },
    {
      "Location ID": "320CA",
      "Location Name": "MIDTOWN ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1740 DOBBS RD SAINT AUGUSTINE FL 32084 USA "
    },
    {
      "Location ID": "320DL",
      "Location Name": "DIY LETTERING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 184 SW RING CT LAKE CITY FL 32025 USA "
    },
    {
      "Location ID": "320FP",
      "Location Name": "FAST PACK, FL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 226 SE LEE SCHOOL AVE LEE FL 32059 USA "
    },
    {
      "Location ID": "320FZ",
      "Location Name": "LOG JACKSONVILLE FL PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10089 N MAIN ST JACKSONVILLE FL 32218 USA "
    },
    {
      "Location ID": "320PM",
      "Location Name": "JACKSONVILLE FL L&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13340 INTERNATIONAL PARKWAY JACKSONVILLE FL 32218 USA "
    },
    {
      "Location ID": "320SA",
      "Location Name": "SAINT AUGUSTINE ANNEX NEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1740 DOBBS RD SAINT AUGUSTINE FL 32084 USA "
    },
    {
      "Location ID": "320VG",
      "Location Name": "VENDITIO GROUP LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4030 DEERPARK BLVD # 200 ELKTON FL 32033 USA "
    },
    {
      "Location ID": "321",
      "Location Name": "DAYTONA BEACH FL P&DF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 BILL FRANCE BLVD DAYTONA BEACH FL 32114-9998 USA "
    },
    {
      "Location ID": "32102",
      "Location Name": "ASTOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24433 STATE ROAD 40 ASTOR FL 32102 USA "
    },
    {
      "Location ID": "32105",
      "Location Name": "BARBERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1680 RAILROAD AVE BARBERVILLE FL 32105 USA "
    },
    {
      "Location ID": "32110",
      "Location Name": "BUNNELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 704 E MOODY BLVD BUNNELL FL 32110 USA "
    },
    {
      "Location ID": "32111",
      "Location Name": "CANDLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10801 SE 110TH STREET RD CANDLER FL 32111 USA "
    },
    {
      "Location ID": "32112",
      "Location Name": "CRESCENT CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 403 N SUMMIT ST CRESCENT CITY FL 32112 USA "
    },
    {
      "Location ID": "32113",
      "Location Name": "CITRA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2250 E HIGHWAY 318 CITRA FL 32113 USA "
    },
    {
      "Location ID": "32114",
      "Location Name": "DAYTONA BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 BILL FRANCE BLVD DAYTONA BEACH FL 32114 USA "
    },
    {
      "Location ID": "32115",
      "Location Name": "DOWNTOWN DAYTONA BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 N BEACH ST DAYTONA BEACH FL 32114 USA "
    },
    {
      "Location ID": "32116",
      "Location Name": "DAYTONA BEACH SHORES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2136S ATLANTIC AVE DAYTONA BEACH S FL 32116 USA "
    },
    {
      "Location ID": "32117",
      "Location Name": "HOLLY HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 231 7TH ST DAYTONA BEACH FL 32117 USA "
    },
    {
      "Location ID": "32118",
      "Location Name": "PENINSULA STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 N ATLANTIC AVE DAYTONA BEACH FL 32118 USA "
    },
    {
      "Location ID": "32119",
      "Location Name": "SOUTH DAYTONA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2400 S RIDGEWOOD AVE SUITE 28 DAYTONA BEACH FL 32121 USA "
    },
    {
      "Location ID": "32127",
      "Location Name": "PORT ORANGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 725 DUNLAWTON AVE PORT ORANGE FL 32127 USA "
    },
    {
      "Location ID": "32130",
      "Location Name": "DE LEON SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 155 PONCE DELEON BLVD DE LEON SPRINGS FL 32130 USA "
    },
    {
      "Location ID": "32131",
      "Location Name": "EAST PALATKA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 134 W MCCORMICK RD EAST PALATKA FL 32131 USA "
    },
    {
      "Location ID": "32132",
      "Location Name": "EDGEWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 S RIDGEWOOD AVE EDGEWATER FL 32132 USA "
    },
    {
      "Location ID": "32133",
      "Location Name": "EASTLAKE WEIR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13803 S HIGHWAY 25 EASTLAKE WEIR FL 32133 USA "
    },
    {
      "Location ID": "32134",
      "Location Name": "FORT MC COY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14945 NE HIGHWAY 315 FORT MC COY FL 32134 USA "
    },
    {
      "Location ID": "32136",
      "Location Name": "FLAGLER BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 S DAYTONA AVE FLAGLER BEACH FL 32136 USA "
    },
    {
      "Location ID": "32137",
      "Location Name": "PALM COAST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 PINE CONE DR PALM COAST FL 32137 USA "
    },
    {
      "Location ID": "32139",
      "Location Name": "GEORGETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1441 COUNTY ROAD 309 GEORGETOWN FL 32139 USA "
    },
    {
      "Location ID": "32142",
      "Location Name": "PALM COAST DATA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 COMMERCE BLVD PALM COAST FL 32164 USA "
    },
    {
      "Location ID": "32145",
      "Location Name": "HASTINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 E COCHRAN AVE HASTINGS FL 32145 USA "
    },
    {
      "Location ID": "32147",
      "Location Name": "HOLLISTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 650 HIGHWAY 20 HOLLISTER FL 32147 USA "
    },
    {
      "Location ID": "32148",
      "Location Name": "INTERLACHEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 MATHE AVE INTERLACHEN FL 32148 USA "
    },
    {
      "Location ID": "32159",
      "Location Name": "LADY LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 850 TEAGUE TRL LADY LAKE FL 32159 USA "
    },
    {
      "Location ID": "32168",
      "Location Name": "NEW SMYRNA BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 MISSION DR NEW SMYRNA BEACH FL 32168 USA "
    },
    {
      "Location ID": "32174",
      "Location Name": "ORMOND BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 260 WILLIAMSON BLVD ORMOND BEACH FL 32174 USA "
    },
    {
      "Location ID": "32176",
      "Location Name": "ORMOND BEACH BEACHSIDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 E GRANADA BLVD ORMOND BEACH FL 32176 USA "
    },
    {
      "Location ID": "32177",
      "Location Name": "PALATKA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1105 SAINT JOHNS AVE PALATKA FL 32177 USA "
    },
    {
      "Location ID": "32179",
      "Location Name": "OCKLAWAHA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13470 E HIGHWAY 25 OCKLAWAHA FL 32179 USA "
    },
    {
      "Location ID": "32180",
      "Location Name": "PIERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 N VOLUSIA AVE PIERSON FL 32180 USA "
    },
    {
      "Location ID": "32181",
      "Location Name": "POMONA PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1778 S HIGHWAY 17 POMONA PARK FL 32181 USA "
    },
    {
      "Location ID": "32182",
      "Location Name": "ORANGE SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24282 US HIGHWAY 315 ORANGE SPRINGS FL 32182 USA "
    },
    {
      "Location ID": "32187",
      "Location Name": "SAN MATEO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 534 S US HIGHWAY 17 SAN MATEO FL 32187 USA "
    },
    {
      "Location ID": "32189",
      "Location Name": "SATSUMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 COUNTY ROAD 309 SATSUMA FL 32189 USA "
    },
    {
      "Location ID": "32190",
      "Location Name": "SEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 COUNTY ROAD 305 SEVILLE FL 32190 USA "
    },
    {
      "Location ID": "32192",
      "Location Name": "SPARR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12855 NE JACKSONVILLE RD SPARR FL 32192 USA "
    },
    {
      "Location ID": "32193",
      "Location Name": "WELAKA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 664 3RD AVE WELAKA FL 32193 USA "
    },
    {
      "Location ID": "32195",
      "Location Name": "WEIRSDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13595 SE HIGHWAY 42 WEIRSDALE FL 32195 USA "
    },
    {
      "Location ID": "321AB",
      "Location Name": "ALLANDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 E COMMONWEALTH BLVD ALLANDALE FL 32123 USA "
    },
    {
      "Location ID": "321AD",
      "Location Name": "LAKE COMO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 148 EUCLID AVE POMONA PARK FL 32181 USA "
    },
    {
      "Location ID": "321BB",
      "Location Name": "BRAILLE BUREAU (DAYTONA BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 421 PLATT ST DAYTONA BEACH FL 32114 USA "
    },
    {
      "Location ID": "321CD",
      "Location Name": "COSTA DEL MAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2361 MASON AVE STE 100 DAYTONA BEACH FL 32117 USA "
    },
    {
      "Location ID": "321SS",
      "Location Name": "SALT SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14100 N HIGHWAY 19 STE G SALT SPRINGS FL 32134 USA "
    },
    {
      "Location ID": "32212",
      "Location Name": "JACKSONVILLE NAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 569 CHILDS ST JACKSONVILLE FL 32212 USA "
    },
    {
      "Location ID": "32228",
      "Location Name": "MAYPORT NAVAL STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 460 MASSEY AVE JACKSONVILLE FL 32228 USA "
    },
    {
      "Location ID": "32229",
      "Location Name": "JACKSONVILLE FL ATO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14350 PECAN PARK RD JACKSONVILLE FL 32218 USA "
    },
    {
      "Location ID": "32233",
      "Location Name": "ATLANTIC BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1001 MAYPORT RD ATLANTIC BEACH FL 32233 USA "
    },
    {
      "Location ID": "322AN",
      "Location Name": "JACKSONVILLE BEACH ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1324 BEACH BLVD JACKSONVILLE BEACH FL 32250 USA "
    },
    {
      "Location ID": "322AO",
      "Location Name": "AMAZON SC JAX9 - JACKSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13450 WATERWORKS ST JACKSONVILLE FL 32221-8118 USA "
    },
    {
      "Location ID": "322AX",
      "Location Name": "AMAZON JAX5",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4948 BULLS BAY HWY JACKSONVILLE FL 32219 USA "
    },
    {
      "Location ID": "322CX",
      "Location Name": "JACKSONVILLE CMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8558 WESTSIDE INDUSTRIAL DR JACKSONVILLE FL 32219 USA "
    },
    {
      "Location ID": "322FZ",
      "Location Name": "LOG JACKSONVILLE FL PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10089 N MAIN ST JACKSONVILLE FL 32218 USA "
    },
    {
      "Location ID": "323",
      "Location Name": "TALLAHASSEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2800 S ADAMS ST TALLAHASSEE FL 32301 USA "
    },
    {
      "Location ID": "32301",
      "Location Name": "TALLAHASSEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2800 S ADAMS ST TALLAHASSEE FL 32301 USA "
    },
    {
      "Location ID": "32302",
      "Location Name": "PARK AVENUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 221 W PARK AVE TALLAHASSEE FL 32301 USA "
    },
    {
      "Location ID": "32304",
      "Location Name": "WESTSIDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2020 W PENSACOLA ST STE 100 TALLAHASSEE FL 32304 USA "
    },
    {
      "Location ID": "32308",
      "Location Name": "CENTERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2355 CENTERVILLE RD TALLAHASSEE FL 32308 USA "
    },
    {
      "Location ID": "32315",
      "Location Name": "LEON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1845 N M L KING JR BLVD TALLAHASSEE FL 32303 USA "
    },
    {
      "Location ID": "32320",
      "Location Name": "APALACHICOLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 AVENUE D STE 101 APALACHICOLA FL 32320 USA "
    },
    {
      "Location ID": "32321",
      "Location Name": "BRISTOL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12587 NW VIRGINIA G WEAVER ST BRISTOL FL 32321 USA "
    },
    {
      "Location ID": "32322",
      "Location Name": "CARRABELLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 93 TALLAHASSEE ST CARRABELLE FL 32322 USA "
    },
    {
      "Location ID": "32323",
      "Location Name": "LANARK VILLAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 156 HEFFERNAN DR CARRABELLE FL 32322 USA "
    },
    {
      "Location ID": "32324",
      "Location Name": "CHATTAHOOCHEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 W WASHINGTON ST CHATTAHOOCHEE FL 32324 USA "
    },
    {
      "Location ID": "32327",
      "Location Name": "CRAWFORDVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 606 WAKULLA ARRAN RD CRAWFORDVILLE FL 32327 USA "
    },
    {
      "Location ID": "32328",
      "Location Name": "EASTPOINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 353 US HIGHWAY 98 EASTPOINT FL 32328 USA "
    },
    {
      "Location ID": "32330",
      "Location Name": "GREENSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 191 GREEN AVE GREENSBORO FL 32330 USA "
    },
    {
      "Location ID": "32331",
      "Location Name": "GREENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13626 W US 90 GREENVILLE FL 32331 USA "
    },
    {
      "Location ID": "32332",
      "Location Name": "GRETNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14678 MAIN ST GRETNA FL 32332 USA "
    },
    {
      "Location ID": "32333",
      "Location Name": "HAVANA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9080 HAVANA HWY HAVANA FL 32333 USA "
    },
    {
      "Location ID": "32334",
      "Location Name": "HOSFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16827 NE STATE ROAD 65 HOSFORD FL 32334 USA "
    },
    {
      "Location ID": "32336",
      "Location Name": "LAMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6244 E CAPPS HWY LAMONT FL 32336 USA "
    },
    {
      "Location ID": "32337",
      "Location Name": "LLOYD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8781 OLD LLOYD RD LLOYD FL 32337 USA "
    },
    {
      "Location ID": "32340",
      "Location Name": "MADISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 197 SW PINCKNEY ST MADISON FL 32340 USA "
    },
    {
      "Location ID": "32343",
      "Location Name": "MIDWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31000 BLUE STAR HWY MIDWAY FL 32343 USA "
    },
    {
      "Location ID": "32344",
      "Location Name": "MONTICELLO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 275 N JEFFERSON ST MONTICELLO FL 32344 USA "
    },
    {
      "Location ID": "32346",
      "Location Name": "PANACEA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1444 COASTAL HWY PANACEA FL 32346 USA "
    },
    {
      "Location ID": "32347",
      "Location Name": "PERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1600 S JEFFERSON ST PERRY FL 32348 USA "
    },
    {
      "Location ID": "32350",
      "Location Name": "PINETTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 NE POPLAR AVE PINETTA FL 32350 USA "
    },
    {
      "Location ID": "32351",
      "Location Name": "QUINCY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 772 S PAT THOMAS PKWY QUINCY FL 32351 USA "
    },
    {
      "Location ID": "32355",
      "Location Name": "SAINT MARKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 779 PORT LEON DR SAINT MARKS FL 32355 USA "
    },
    {
      "Location ID": "32356",
      "Location Name": "SALEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16580 S US 19 SALEM FL 32356 USA "
    },
    {
      "Location ID": "32358",
      "Location Name": "SOPCHOPPY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2284 SOPCHOPPY HWY SOPCHOPPY FL 32358 USA "
    },
    {
      "Location ID": "32359",
      "Location Name": "STEINHATCHEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 15TH ST SE STEINHATCHEE FL 32359 USA "
    },
    {
      "Location ID": "32360",
      "Location Name": "TELOGIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27134 NE COUNTY ROAD 67 TELOGIA FL 32360 USA "
    },
    {
      "Location ID": "32361",
      "Location Name": "WACISSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 38 TRAM RD WACISSA FL 32361 USA "
    },
    {
      "Location ID": "32362",
      "Location Name": "WOODVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8219 WOODVILLE HWY WOODVILLE FL 32362 USA "
    },
    {
      "Location ID": "323AA",
      "Location Name": "LAKE JACKSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3607 N MONROE ST TALLAHASSEE FL 32303 USA "
    },
    {
      "Location ID": "323CG",
      "Location Name": "CORPORATE GRAPHIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 240 SW COMMERCE DR MADISON FL 32340 USA "
    },
    {
      "Location ID": "323CS",
      "Location Name": "CSG SYSTEMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 509 COMMERCE BLVD CRAWFORDVILLE FL 32327 USA "
    },
    {
      "Location ID": "323DR",
      "Location Name": "TALLAHASSEE DEPT OF REVENUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5067 TENNESSEE CAPITAL BLVD TALLAHASSEE FL 32303 USA "
    },
    {
      "Location ID": "323FS",
      "Location Name": "FLORIDA STATE UNIVERSITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 W COLLEGE AVE TALLAHASSEE FL 32306 USA "
    },
    {
      "Location ID": "323GE",
      "Location Name": "GOODWILL ECOMMERCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1668 NORMAN PARK DR STE B TALLAHASSEE FL 32304 USA "
    },
    {
      "Location ID": "324",
      "Location Name": "PANAMA CITY P&DF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1336 SHERMAN AVE PANAMA CITY FL 32401 USA "
    },
    {
      "Location ID": "32402",
      "Location Name": "PANAMA CITY DOWNTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 421 JENKS AVE PANAMA CITY FL 32402 USA "
    },
    {
      "Location ID": "32403",
      "Location Name": "TYNDALL AFB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 590 SUWANNEE RD STE 105 PANAMA CITY FL 32403 USA "
    },
    {
      "Location ID": "32405",
      "Location Name": "NORTH SIDE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1315 W 17TH ST PANAMA CITY FL 32405 USA "
    },
    {
      "Location ID": "32407",
      "Location Name": "PANAMA CITY BEACH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 420 CHURCHWELL DR PANAMA CITY BEACH FL 32407 USA "
    },
    {
      "Location ID": "32409",
      "Location Name": "SOUTHPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7526 HIGHWAY 77 PANAMA CITY FL 32409 USA "
    },
    {
      "Location ID": "32410",
      "Location Name": "MEXICO BEACH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 625C 15TH ST MEXICO BEACH FL 32456 USA "
    },
    {
      "Location ID": "32413",
      "Location Name": "ROSEMARY BEACH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 28 MAIN ST PANAMA CITY BEA FL 32413 USA "
    },
    {
      "Location ID": "32420",
      "Location Name": "ALFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1772 GEORGIA ST ALFORD FL 32420 USA "
    },
    {
      "Location ID": "32421",
      "Location Name": "ALTHA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 25934 N MAIN ST ALTHA FL 32421 USA "
    },
    {
      "Location ID": "32422",
      "Location Name": "ARGYLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4757 U S HIGHWAY 90 E ARGYLE FL 32422 USA "
    },
    {
      "Location ID": "32423",
      "Location Name": "BASCOM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5011 BASSWOOD RD BASCOM FL 32423 USA "
    },
    {
      "Location ID": "32424",
      "Location Name": "BLOUNTSTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 17621 MAIN ST N BLOUNTSTOWN FL 32424 USA "
    },
    {
      "Location ID": "32425",
      "Location Name": "BONIFAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 325 W HIGHWAY 90 BONIFAY FL 32425 USA "
    },
    {
      "Location ID": "32426",
      "Location Name": "CAMPBELLTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5265 HIGHWAY 231 CAMPBELLTON FL 32426 USA "
    },
    {
      "Location ID": "32428",
      "Location Name": "CHIPLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1276 CHURCH AVE CHIPLEY FL 32428 USA "
    },
    {
      "Location ID": "32430",
      "Location Name": "CLARKSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11351 STATE ROAD 20 W CLARKSVILLE FL 32430 USA "
    },
    {
      "Location ID": "32431",
      "Location Name": "COTTONDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3155 MAIN ST COTTONDALE FL 32431 USA "
    },
    {
      "Location ID": "32432",
      "Location Name": "CYPRESS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2045 MAIN ST CYPRESS FL 32432 USA "
    },
    {
      "Location ID": "32433",
      "Location Name": "DEFUNIAK SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 S 18TH ST DEFUNIAK SPRINGS FL 32435 USA "
    },
    {
      "Location ID": "32437",
      "Location Name": "EBRO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5416 OBIES ST EBRO FL 32437 USA "
    },
    {
      "Location ID": "32438",
      "Location Name": "FOUNTAIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12606 SILVER LAKE RD FOUNTAIN FL 32438 USA "
    },
    {
      "Location ID": "32439",
      "Location Name": "FREEPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 77 BRECKYN LOOP FREEPORT FL 32439 USA "
    },
    {
      "Location ID": "32440",
      "Location Name": "GRACEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5360 CLIFF ST GRACEVILLE FL 32440 USA "
    },
    {
      "Location ID": "32442",
      "Location Name": "GRAND RIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2064 PORTER AVE GRAND RIDGE FL 32442 USA "
    },
    {
      "Location ID": "32443",
      "Location Name": "GREENWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4233 BRYAN ST GREENWOOD FL 32443 USA "
    },
    {
      "Location ID": "32444",
      "Location Name": "LYNN HAVEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2319 S HIGHWAY 77 LYNN HAVEN FL 32444 USA "
    },
    {
      "Location ID": "32445",
      "Location Name": "MALONE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5179 7TH AVE MALONE FL 32445 USA "
    },
    {
      "Location ID": "32446",
      "Location Name": "MARIANNA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4396 LAFAYETTE ST MARIANNA FL 32446 USA "
    },
    {
      "Location ID": "32455",
      "Location Name": "PONCE DE LEON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2834 N HIGHWAY 81 PONCE DE LEON FL 32455 USA "
    },
    {
      "Location ID": "32456",
      "Location Name": "PORT SAINT JOE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 502 GARRISON AVE PORT SAINT JOE FL 32456 USA "
    },
    {
      "Location ID": "32459",
      "Location Name": "SANTA ROSA BEACH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3906 US HIGHWAY 98 W SANTA ROSA BEACH FL 32459-9998 USA "
    },
    {
      "Location ID": "32460",
      "Location Name": "SNEADS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2042 GREEN AVE SNEADS FL 32460 USA "
    },
    {
      "Location ID": "32461",
      "Location Name": "SUNNYSIDE",
      "Time Zone Offset (Hours)": -1,
      "Address": " P O BOX 208 ROSEMARY BEACH FL 32461 USA "
    },
    {
      "Location ID": "32462",
      "Location Name": "VERNON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3039 MAIN ST VERNON FL 32462 USA "
    },
    {
      "Location ID": "32463",
      "Location Name": "WAUSAU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3556 WASHINGTON ST WAUSAU FL 32463 USA "
    },
    {
      "Location ID": "32464",
      "Location Name": "WESTVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2527 N PINE ST WESTVILLE FL 32464 USA "
    },
    {
      "Location ID": "32465",
      "Location Name": "WEWAHITCHKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 432 HIGHWAY 22 WEWAHITCHKA FL 32465 USA "
    },
    {
      "Location ID": "32466",
      "Location Name": "YOUNGSTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11805 HIGHWAY 231 YOUNGSTOWN FL 32466 USA "
    },
    {
      "Location ID": "324AA",
      "Location Name": "SANTA ROSA CARRIER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3906 US HIGHWAY 98 W STE 2 SANTA ROSA BEACH FL 32459 USA "
    },
    {
      "Location ID": "324GP",
      "Location Name": "GRILL PARTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24 COMMERCIAL PKWY SANTA RSA BCH FL 32459 USA "
    },
    {
      "Location ID": "324HT",
      "Location Name": "HIGH TIDE HEALTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 605 N COUNTY HIGHWAY 393 STE A2 SANTA ROSA BEAC FL 32459 USA "
    },
    {
      "Location ID": "324PM",
      "Location Name": "PANHANDLE MERCANTILE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 605 N COUNTY HIGHWAY 393 STE C4 SANTA ROSA BEAC FL 32459 USA "
    },
    {
      "Location ID": "324TY",
      "Location Name": "TYNDALL AFB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 590 SUWANNEE RD STE 105 PANAMA CITY FL 32403 USA "
    },
    {
      "Location ID": "324UP",
      "Location Name": "UPS - WATERCOLOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 174 WATERCOLOR WAY STE 103 SANTA ROSA BEAC FL 32459 USA "
    },
    {
      "Location ID": "324WS",
      "Location Name": "WATER SOUND CPU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6652 E COUNTY HWY 30A STE150 PANAMA CITY FL 32413 USA "
    },
    {
      "Location ID": "325",
      "Location Name": "PENSACOLA P&DC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1400 W JORDAN ST PENSACOLA FL 32501 USA "
    },
    {
      "Location ID": "32502",
      "Location Name": "DOWNTOWN PENSACOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 PALAFOX PL PENSACOLA FL 32502 USA "
    },
    {
      "Location ID": "32503",
      "Location Name": "EAST HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3001 N DAVIS HWY PENSACOLA FL 32503 USA "
    },
    {
      "Location ID": "32504",
      "Location Name": "NOBLES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7150 TIPPIN AVE PENSACOLA FL 32504 USA "
    },
    {
      "Location ID": "32506",
      "Location Name": "MYRTLE GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5200 LILLIAN HWY PENSACOLA FL 32506 USA "
    },
    {
      "Location ID": "32507",
      "Location Name": "WARRINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 901 S OLD CORRY FIELD RD PENSACOLA FL 32507 USA "
    },
    {
      "Location ID": "32508",
      "Location Name": "NAVAL AIR STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 WEST AVE STE B PENSACOLA FL 32508 USA "
    },
    {
      "Location ID": "32509",
      "Location Name": "SAUFLEY FIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6000 SAUFLEY RD BLDG 808 PENSACOLA FL 32509 USA "
    },
    {
      "Location ID": "32511",
      "Location Name": "NAVAL TECHNICAL TRAINING CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " CORRY STATION PENSACOLA FL 32511 USA "
    },
    {
      "Location ID": "32514",
      "Location Name": "FERRY PASS CPU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8084 N DAVIS HWY STE D1 PENSACOLA FL 32514 USA "
    },
    {
      "Location ID": "32526",
      "Location Name": "BELLVIEW CPU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2400 W MICHIGAN AVE PENSACOLA FL 32526 USA "
    },
    {
      "Location ID": "32530",
      "Location Name": "BAGDAD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4629 FORSYTH ST BAGDAD FL 32530 USA "
    },
    {
      "Location ID": "32531",
      "Location Name": "BAKER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5690 HIGHWAY 4 BAKER FL 32531 USA "
    },
    {
      "Location ID": "32533",
      "Location Name": "CANTONMENT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1430 S HIGHWAY 29 CANTONMENT FL 32533 USA "
    },
    {
      "Location ID": "32535",
      "Location Name": "CENTURY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8450 N CENTURY BLVD CENTURY FL 32535 USA "
    },
    {
      "Location ID": "32536",
      "Location Name": "CRESTVIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1325 COMMERCE DR CRESTVIEW FL 32536 USA "
    },
    {
      "Location ID": "32538",
      "Location Name": "PAXTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21892 US HIGHWAY 331 N PAXTON FL 32538 USA "
    },
    {
      "Location ID": "32539",
      "Location Name": "CRESTVIEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1325 COMMERCE DR CRESTVIEW FL 32539-9998 USA "
    },
    {
      "Location ID": "32541",
      "Location Name": "DESTIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 175 MAIN ST DESTIN FL 32541 USA "
    },
    {
      "Location ID": "32542",
      "Location Name": "EGLIN AFB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 W VAN MATRE AVE EGLIN AFB FL 32542 USA "
    },
    {
      "Location ID": "32544",
      "Location Name": "HURLBURT FIELD CPU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 437 TULLY ST HURLBURT FIELD FL 32544 USA "
    },
    {
      "Location ID": "32548",
      "Location Name": "FORT WALTON BEACH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 21 WALTER MARTIN RD NE FORT WALTON BEACH FL 32548 USA "
    },
    {
      "Location ID": "32550",
      "Location Name": "MIRAMAR BEACH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 322 MIRAMAR BEACH DR MIRAMAR BEACH FL 32550 USA "
    },
    {
      "Location ID": "32560",
      "Location Name": "GONZALEZ",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1450 OLD CHEMSTRAND RD GONZALEZ FL 32560 USA "
    },
    {
      "Location ID": "32561",
      "Location Name": "GULF BREEZE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 NORTHCLIFFE DR GULF BREEZE FL 32561 USA "
    },
    {
      "Location ID": "32564",
      "Location Name": "HOLT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 563 HIGHWAY 90 W HOLT FL 32564 USA "
    },
    {
      "Location ID": "32565",
      "Location Name": "JAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3719 HIGHWAY 4 JAY FL 32565 USA "
    },
    {
      "Location ID": "32566",
      "Location Name": "NAVARRE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1910 NAVARRE SCHOOL RD NAVARRE FL 32566 USA "
    },
    {
      "Location ID": "32567",
      "Location Name": "LAUREL HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3957 2ND AVE LAUREL HILL FL 32567 USA "
    },
    {
      "Location ID": "32568",
      "Location Name": "MC DAVID",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7 MAIN ST MC DAVID FL 32568 USA "
    },
    {
      "Location ID": "32569",
      "Location Name": "MARY ESTHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 W HOLLYWOOD BLVD MARY ESTHER FL 32569 USA "
    },
    {
      "Location ID": "32570",
      "Location Name": "MILTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5111 DOGWOOD DR MILTON FL 32570 USA "
    },
    {
      "Location ID": "32571",
      "Location Name": "PACE CARRIER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4584 CHUMUCKLA HWY MILTON FL 32571 USA "
    },
    {
      "Location ID": "32577",
      "Location Name": "MOLINO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 550 MOLINO RD MOLINO FL 32577 USA "
    },
    {
      "Location ID": "32578",
      "Location Name": "NICEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 90 PALM BLVD N NICEVILLE FL 32578 USA "
    },
    {
      "Location ID": "32579",
      "Location Name": "SHALIMAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1000 N EGLIN PKWY SHALIMAR FL 32579 USA "
    },
    {
      "Location ID": "32580",
      "Location Name": "VALPARAISO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 EDGE AVE VALPARAISO FL 32580 USA "
    },
    {
      "Location ID": "325AD",
      "Location Name": "ABEKA DISTRIBUTION CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5414 RAWSON LN PENSACOLA FL 32503 USA "
    },
    {
      "Location ID": "325AS",
      "Location Name": "NEXCOM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 291 CUNNINGHAM ST PENSACOLA FL 32507 USA "
    },
    {
      "Location ID": "325AX",
      "Location Name": "PENSACOLA ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 257 AMBER ST PENSACOLA FL 32503 USA "
    },
    {
      "Location ID": "325PS",
      "Location Name": "PCC PRINT SHOP INC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 SAINT JOHN ST PENSACOLA FL 32503 USA "
    },
    {
      "Location ID": "325SC",
      "Location Name": "SNACK CRATE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 E WRIGHT ST PENSACOLA FL 32501 USA "
    },
    {
      "Location ID": "325WF",
      "Location Name": "UNIV OF WEST FLORIDA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11000 UNIVERSITY PARKWAY PENSACOLA FL 32514 USA "
    },
    {
      "Location ID": "326",
      "Location Name": "LOG GAINESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4600 SW 34TH ST GAINESVILLE FL 32608 USA "
    },
    {
      "Location ID": "32601",
      "Location Name": "MAIN STREET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1401 N MAIN ST GAINESVILLE FL 32601 USA "
    },
    {
      "Location ID": "32611",
      "Location Name": "UNIVERSITY OF FLORIDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " RADIO ROAD GAINESVILLE FL 32611 USA "
    },
    {
      "Location ID": "32615",
      "Location Name": "ALACHUA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15200 NW 142ND TER ALACHUA FL 32615 USA "
    },
    {
      "Location ID": "32618",
      "Location Name": "ARCHER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13553 SW STATE ROAD 45 ARCHER FL 32618 USA "
    },
    {
      "Location ID": "32621",
      "Location Name": "BRONSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 747 E HATHAWAY AVE BRONSON FL 32621 USA "
    },
    {
      "Location ID": "32625",
      "Location Name": "CEDAR KEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 518 2ND ST CEDAR KEY FL 32625 USA "
    },
    {
      "Location ID": "32626",
      "Location Name": "CHIEFLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 222 W PARK AVE CHIEFLAND FL 32626 USA "
    },
    {
      "Location ID": "32639",
      "Location Name": "GULF HAMMOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5350 SE HIGHWAY 19 GULF HAMMOCK FL 32639 USA "
    },
    {
      "Location ID": "32643",
      "Location Name": "HIGH SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23687 NW 188TH AVE HIGH SPRINGS FL 32643 USA "
    },
    {
      "Location ID": "32668",
      "Location Name": "MORRISTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3350 SE HIGHWAY 41 MORRISTON FL 32668 USA "
    },
    {
      "Location ID": "32669",
      "Location Name": "NEWBERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 252 N MAIN ST NEWBERRY FL 32669 USA "
    },
    {
      "Location ID": "32683",
      "Location Name": "OTTER CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 SE US HIGHWAY 19 OTTER CREEK FL 32683 USA "
    },
    {
      "Location ID": "32696",
      "Location Name": "WILLISTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 NW 1ST AVE WILLISTON FL 32696 USA "
    },
    {
      "Location ID": "326HC",
      "Location Name": "HALO CIGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5909 NW 18TH DR GAINESVILLE FL 32653 USA "
    },
    {
      "Location ID": "326PS",
      "Location Name": "NORTHWEST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4111 NW 16TH BLVD GAINESVILLE FL 32605 USA "
    },
    {
      "Location ID": "327",
      "Location Name": "MID-FLORIDA P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 800 RINEHART RD MID FLORIDA FL 32799 USA "
    },
    {
      "Location ID": "32702",
      "Location Name": "ALTOONA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 42038 STATE ROAD 19 ALTOONA FL 32702 USA "
    },
    {
      "Location ID": "32706",
      "Location Name": "CASSADAGA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1087 STEVENS ST CASSADAGA FL 32706 USA "
    },
    {
      "Location ID": "32707",
      "Location Name": "CASSELBERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 LIVE OAKS BLVD CASSELBERRY FL 32707 USA "
    },
    {
      "Location ID": "32708",
      "Location Name": "WINTER SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1065 E STATE ROAD 434 WINTER SPRINGS FL 32708 USA "
    },
    {
      "Location ID": "32709",
      "Location Name": "CHRISTMAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23580 E COLONIAL DR CHRISTMAS FL 32709 USA "
    },
    {
      "Location ID": "32710",
      "Location Name": "CLARCONA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7228 CLARCONA OCOEE RD CLARCONA FL 32710 USA "
    },
    {
      "Location ID": "32712",
      "Location Name": "APOPKA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 581 N PARK AVE APOPKA FL 32712 USA "
    },
    {
      "Location ID": "32714",
      "Location Name": "ALTAMONTE SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 321 MONTGOMERY RD ALTAMONTE SPRINGS FL 32714 USA "
    },
    {
      "Location ID": "32715",
      "Location Name": "PRAIRIE LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1140 E ALTAMONTE DR STE 1016 ALTAMONTE SPRINGS FL 32701 USA "
    },
    {
      "Location ID": "32720",
      "Location Name": "DELAND CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 240 W NEW YORK AVE DELAND FL 32720 USA "
    },
    {
      "Location ID": "32724",
      "Location Name": "DELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 336 E NEW YORK AVE DELAND FL 32724 USA "
    },
    {
      "Location ID": "32725",
      "Location Name": "DELTONA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 944 DELTONA BLVD DELTONA FL 32725 USA "
    },
    {
      "Location ID": "32726",
      "Location Name": "EUSTIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 E CLIFFORD AVE EUSTIS FL 32726 USA "
    },
    {
      "Location ID": "32730",
      "Location Name": "FERN PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 FERNWOOD BLVD FERN PARK FL 32730 USA "
    },
    {
      "Location ID": "32732",
      "Location Name": "GENEVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 199 PINE ST GENEVA FL 32732 USA "
    },
    {
      "Location ID": "32733",
      "Location Name": "GOLDENROD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7501 CITRUS AVE GOLDENROD FL 32733 USA "
    },
    {
      "Location ID": "32735",
      "Location Name": "GRAND ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36525 S FISH CAMP RD GRAND ISLAND FL 32735 USA "
    },
    {
      "Location ID": "32738",
      "Location Name": "DELTONA PINES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2048 HOWLAND BLVD DELTONA FL 32738 USA "
    },
    {
      "Location ID": "32744",
      "Location Name": "LAKE HELEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 E MICHIGAN AVE LAKE HELEN FL 32744 USA "
    },
    {
      "Location ID": "32746",
      "Location Name": "LAKE MARY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3415 W LAKE MARY BLVD LAKE MARY FL 32746 USA "
    },
    {
      "Location ID": "32747",
      "Location Name": "LAKE MONROE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 755 MONROE RD LAKE MONROE FL 32747 USA "
    },
    {
      "Location ID": "32750",
      "Location Name": "LONGWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 501 FLORIDA CENTRAL PKWY LONGWOOD FL 32750 USA "
    },
    {
      "Location ID": "32751",
      "Location Name": "MAITLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 151 N MAITLAND AVE MAITLAND FL 32751 USA "
    },
    {
      "Location ID": "32754",
      "Location Name": "MIMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3405 KELLY RD MIMS FL 32754 USA "
    },
    {
      "Location ID": "32757",
      "Location Name": "MOUNT DORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2600 WEST OLD HIGHWAY 441 MOUNT DORA FL 32757 USA "
    },
    {
      "Location ID": "32759",
      "Location Name": "OAK HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 129 E HALIFAX AVE OAK HILL FL 32759 USA "
    },
    {
      "Location ID": "32763",
      "Location Name": "ORANGE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 260 N INDUSTRIAL DR ORANGE CITY FL 32763 USA "
    },
    {
      "Location ID": "32764",
      "Location Name": "OSTEEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 DICKSON AVE OSTEEN FL 32764 USA "
    },
    {
      "Location ID": "32765",
      "Location Name": "OVIEDO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 567 E FRANKLIN ST OVIEDO FL 32765 USA "
    },
    {
      "Location ID": "32767",
      "Location Name": "PAISLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24952 COUNTY ROAD 42 PAISLEY FL 32767 USA "
    },
    {
      "Location ID": "32768",
      "Location Name": "PLYMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 900 PLYMOUTH SORRENTO RD PLYMOUTH FL 32768 USA "
    },
    {
      "Location ID": "32771",
      "Location Name": "SANFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 E 1ST ST SANFORD FL 32771 USA "
    },
    {
      "Location ID": "32773",
      "Location Name": "SANFORD ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1475 E AIRPORT BLVD SANFORD FL 32773 USA "
    },
    {
      "Location ID": "32775",
      "Location Name": "SCOTTSMOOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3750 MAGOON AVE SCOTTSMOOR FL 32775 USA "
    },
    {
      "Location ID": "32776",
      "Location Name": "SORRENTO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24106 STATE ROAD 46 SORRENTO FL 32776 USA "
    },
    {
      "Location ID": "32777",
      "Location Name": "TANGERINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5424 LAKE ST TANGERINE FL 32777 USA "
    },
    {
      "Location ID": "32778",
      "Location Name": "TAVARES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 399 E BURLEIGH BLVD TAVARES FL 32778 USA "
    },
    {
      "Location ID": "32779",
      "Location Name": "WEKIVA SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 920 WEKIVA SPRINGS RD LONGWOOD FL 32779 USA "
    },
    {
      "Location ID": "32780",
      "Location Name": "TITUSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2503 S WASHINGTON AVE TITUSVILLE FL 32780 USA "
    },
    {
      "Location ID": "32784",
      "Location Name": "UMATILLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 315 N CENTRAL AVE UMATILLA FL 32784 USA "
    },
    {
      "Location ID": "32789",
      "Location Name": "WINTER PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 N NEW YORK AVE WINTER PARK FL 32789 USA "
    },
    {
      "Location ID": "32792",
      "Location Name": "ALOMA CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4214 METRIC DR WINTER PARK FL 32792 USA "
    },
    {
      "Location ID": "32793",
      "Location Name": "ALOMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 221 DRIGGS DR WINTER PARK FL 32792 USA "
    },
    {
      "Location ID": "32798",
      "Location Name": "ZELLWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3125 N WASHINGTON ST ZELLWOOD FL 32798 USA "
    },
    {
      "Location ID": "327CA",
      "Location Name": "MID-FLORIDA CHRISTMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 800 RINEHART RD LAKE MARY FL 32746 USA "
    },
    {
      "Location ID": "327DT",
      "Location Name": "DREAMWAY TRADING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 240 OLD SANDORD OVIEDO RD WINTER SPRINGS FL 32708 USA "
    },
    {
      "Location ID": "327EC",
      "Location Name": "ECKLERS AUTOMOTIVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7980 GRISSOM PKWY TITUSVILLE FL 32780 USA "
    },
    {
      "Location ID": "327EN",
      "Location Name": "ENTERPRISE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 710 JACOB BROCK AVE ENTERPRISE FL 32725 USA "
    },
    {
      "Location ID": "327OL",
      "Location Name": "ONLINE LABELS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2021 E LAKE MARY BLVD SANFORD FL 32773-7140 USA "
    },
    {
      "Location ID": "327OP",
      "Location Name": "OPTUM RX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5700 DOT COM CT OVIEDO FL 32765 USA "
    },
    {
      "Location ID": "327PO",
      "Location Name": "OVIEDO STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 83 GENEVA DR OVIEDO FL 32765 USA "
    },
    {
      "Location ID": "327PS",
      "Location Name": "DEBARY POSTAL STORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 133 S HIGHWAY 17-92 DE BARY FL 32713 USA "
    },
    {
      "Location ID": "327SB",
      "Location Name": "SMART BREAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 297 POWER CT SANFORD FL 32771-9403 USA "
    },
    {
      "Location ID": "327TP",
      "Location Name": "URBAN CARRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 445 SPECIALTY POINT SANFORD FL 32771-6622 USA "
    },
    {
      "Location ID": "327WL",
      "Location Name": "WIZARD LABS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 927 FERN ST STE 1000 ALTAMONTE SPRINGS FL 32701 USA "
    },
    {
      "Location ID": "328",
      "Location Name": "ORLANDO P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10401 POST OFFICE BLVD W FL 1 ORLANDO FL 32862 USA "
    },
    {
      "Location ID": "328FZ",
      "Location Name": "LOG SEMINOLE FL PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10425 S ORANGE AVE ORLANDO FL 32824 USA "
    },
    {
      "Location ID": "328GM",
      "Location Name": "DHL GLOBAL MAIL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7415 EMERALD DUNES DR STE 600 ORLANDO FL 32822 USA "
    },
    {
      "Location ID": "328PM",
      "Location Name": "SEMINOLE P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2036 E LANDSTREET RD ORLANDO FL 32824 USA "
    },
    {
      "Location ID": "328SE",
      "Location Name": "SAVAGE ENTERPRISES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1038 ARLINGTON ST ORLANDO FL 32805 USA "
    },
    {
      "Location ID": "328SP",
      "Location Name": "FEDEX SMART POST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 501 GILLS DR ORLANDO FL 32824 USA "
    },
    {
      "Location ID": "328TH",
      "Location Name": "QUANTEM AVIATION SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9597 BENFORD RD ORLANDO FL 32827 USA "
    },
    {
      "Location ID": "328UP",
      "Location Name": "UPS MAIL INNOVATIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7500 SOUTHLAND BLVD ORLANDO FL 32809 USA "
    },
    {
      "Location ID": "328WM",
      "Location Name": "WALMART #1220",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2500 S KIRKMAN RD ORLANDO FL 32811 USA "
    },
    {
      "Location ID": "329",
      "Location Name": "MELBOURNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 640 E NEW HAVEN AVE MELBOURNE FL 32901 USA "
    },
    {
      "Location ID": "32903",
      "Location Name": "INDIALANTIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 N PALM AVE INDIALANTIC FL 32903 USA "
    },
    {
      "Location ID": "32904",
      "Location Name": "WEST MELBOURNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2305 MINTON RD MELBOURNE FL 32904 USA "
    },
    {
      "Location ID": "32905",
      "Location Name": "PALM BAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4660 LIPSCOMB ST NE PALM BAY FL 32905 USA "
    },
    {
      "Location ID": "32907",
      "Location Name": "PALM BAY WEST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 950 MALABAR RD SW PALM BAY FL 32907 USA "
    },
    {
      "Location ID": "32910",
      "Location Name": "INTERCHANGE SQUARE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1155 MALABAR RD NE STE 18 PALM BAY FL 32907 USA "
    },
    {
      "Location ID": "32920",
      "Location Name": "CAPE CANAVERAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8700 ASTRONAUT BLVD CAPE CANAVERAL FL 32920 USA "
    },
    {
      "Location ID": "32925",
      "Location Name": "PATRICK AFB",
      "Time Zone Offset (Hours)": 0,
      "Address": " 424 FALCON AVE PATRICK AFB FL 32925 USA "
    },
    {
      "Location ID": "32926",
      "Location Name": "COCOA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2000 STATE ROAD 524 COCOA FL 32926 USA "
    },
    {
      "Location ID": "32932",
      "Location Name": "COCOA BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 N BREVARD AVE COCOA BEACH FL 32931 USA "
    },
    {
      "Location ID": "32937",
      "Location Name": "SATELLITE BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1071 S PATRICK DR STE 101 SATELLITE BEACH FL 32937 USA "
    },
    {
      "Location ID": "32940",
      "Location Name": "SUNTREE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6105 N WICKHAM RD MELBOURNE FL 32940 USA "
    },
    {
      "Location ID": "32948",
      "Location Name": "FELLSMERE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 N OAK ST FELLSMERE FL 32948 USA "
    },
    {
      "Location ID": "32949",
      "Location Name": "GRANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5560 OLD DIXIE HWY GRANT FL 32949 USA "
    },
    {
      "Location ID": "32950",
      "Location Name": "MALABAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1720 MALABAR RD MALABAR FL 32950 USA "
    },
    {
      "Location ID": "32951",
      "Location Name": "MELBOURNE BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 504 OCEAN AVE MELBOURNE BEACH FL 32951 USA "
    },
    {
      "Location ID": "32953",
      "Location Name": "MERRITT ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 333 CROCKETT BLVD MERRITT ISLAND FL 32953 USA "
    },
    {
      "Location ID": "32955",
      "Location Name": "ROCKLEDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3000 MURRELL RD ROCKLEDGE FL 32955 USA "
    },
    {
      "Location ID": "32957",
      "Location Name": "ROSELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13305 ROSELAND RD ROSELAND FL 32957 USA "
    },
    {
      "Location ID": "32958",
      "Location Name": "SEBASTIAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1290 MAIN ST SEBASTIAN FL 32958 USA "
    },
    {
      "Location ID": "32959",
      "Location Name": "SHARPES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4425 N COCOA BLVD SHARPES FL 32959 USA "
    },
    {
      "Location ID": "32963",
      "Location Name": "VERO BEACH FINANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3320 CARDINAL DR VERO BEACH FL 32963 USA "
    },
    {
      "Location ID": "32966",
      "Location Name": "CITRUS RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8500 20TH ST VERO BEACH FL 32966 USA "
    },
    {
      "Location ID": "32967",
      "Location Name": "VERO BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1551 US HIGHWAY 1 VERO BEACH FL 32960 USA "
    },
    {
      "Location ID": "32970",
      "Location Name": "WABASSO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8114 US HIGHWAY 1 WABASSO FL 32970 USA "
    },
    {
      "Location ID": "329AS",
      "Location Name": "AMERICAN SIGN LETTERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10045 102ND TER SEBASTIAN FL 32958 USA "
    },
    {
      "Location ID": "329AX",
      "Location Name": "APOLLO ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 680 N APOLLO BLVD FRNT MELBOURNE FL 32935 USA "
    },
    {
      "Location ID": "329VB",
      "Location Name": "VERO BEACH DOWNTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2050 13TH AVE OFC VERO BEACH FL 32960 USA "
    },
    {
      "Location ID": "32H",
      "Location Name": "CENTRAL FLORIDA STC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11010 FLORIDA CROWN DR ORLANDO FL 32824 USA "
    },
    {
      "Location ID": "32MTE",
      "Location Name": "JACKSONVILLE MTESC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8500 WESTSIDE IND DR UNIT 1 JACKSONVILLE FL 32219 USA "
    },
    {
      "Location ID": "32Z",
      "Location Name": "LOG JACKSONVILLE NDC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7415 COMMONWEALTH AVE JACKSONVILLE FL 32099 USA "
    },
    {
      "Location ID": "330",
      "Location Name": "SOUTH FLORIDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16000 PINES BLVD PEMBROKE PINES FL 33027 USA "
    },
    {
      "Location ID": "33001",
      "Location Name": "LONG KEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 68340 OVERSEAS HWY LONG KEY FL 33001 USA "
    },
    {
      "Location ID": "33014",
      "Location Name": "PALMETTO LAKES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5375 NW 159TH ST MIAMI LAKES FL 33014 USA "
    },
    {
      "Location ID": "33015",
      "Location Name": "MIAMI GARDENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6193 NW 183RD ST HIALEAH FL 33015 USA "
    },
    {
      "Location ID": "33036",
      "Location Name": "ISLAMORADA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 82801 OVERSEAS HWY ISLAMORADA FL 33036 USA "
    },
    {
      "Location ID": "33037",
      "Location Name": "KEY LARGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100100 OVERSEAS HWY KEY LARGO FL 33037 USA "
    },
    {
      "Location ID": "33040",
      "Location Name": "KEY WEST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 WHITEHEAD ST KEY WEST FL 33040 USA "
    },
    {
      "Location ID": "33042",
      "Location Name": "SUMMERLAND KEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24700 OVERSEAS HWY SUMMERLAND KEY FL 33042 USA "
    },
    {
      "Location ID": "33043",
      "Location Name": "BIG PINE KEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29959 OVERSEAS HWY BIG PINE KEY FL 33043 USA "
    },
    {
      "Location ID": "33045",
      "Location Name": "FLAGLER KEY WEST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2764 N ROOSEVELT BLVD KEY WEST FL 33040 USA "
    },
    {
      "Location ID": "33050",
      "Location Name": "MARATHON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5171 OVERSEAS HWY MARATHON FL 33050 USA "
    },
    {
      "Location ID": "33051",
      "Location Name": "KEY COLONY BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 W OCEAN DR KEY COLONY BEACH FL 33051 USA "
    },
    {
      "Location ID": "33052",
      "Location Name": "MARATHON SHORES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11400 OVERSEAS HWY STE 120 MARATHON FL 33050 USA "
    },
    {
      "Location ID": "33054",
      "Location Name": "OPA LOCKA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 550 FISHERMAN ST OPA LOCKA FL 33054 USA "
    },
    {
      "Location ID": "33056",
      "Location Name": "CAROL CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18690 NW 37TH AVE MIAMI GARDENS FL 33056 USA "
    },
    {
      "Location ID": "33070",
      "Location Name": "TAVERNIER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 91220 OVERSEAS HWY TAVERNIER FL 33070 USA "
    },
    {
      "Location ID": "330CX",
      "Location Name": "SOUTH FLORIDA PEAK CROSSDOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2500 COMMERCE CENTER WAY UNIT 800 PEMBROKE PARK FL 33023 USA "
    },
    {
      "Location ID": "330HC",
      "Location Name": "HIALEAH ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7000 W 19TH CT HIALEAH FL 33014 USA "
    },
    {
      "Location ID": "330JI",
      "Location Name": "JODEE INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3100 N 29TH AVE HOLLYWOOD FL 33020 USA "
    },
    {
      "Location ID": "330KL",
      "Location Name": "KIRA LABS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2950 N ANDREWS AVENUE EXT POMPANO BEACH FL 33064 USA "
    },
    {
      "Location ID": "330PM",
      "Location Name": "LOG ROYAL PALM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5500 NW 142ND ST OPA LOCKA FL 33054 USA "
    },
    {
      "Location ID": "330PT",
      "Location Name": "PET MEDS EXPRESS INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 420 S CONGRESS AVE # 100 DELRAY BEACH FL 33445 USA "
    },
    {
      "Location ID": "331",
      "Location Name": "LOG MIAMI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2200 NW 72ND AVE STE 200 MIAMI FL 33152 USA "
    },
    {
      "Location ID": "33101",
      "Location Name": "FLAGLER MIAMI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 NW 2ND AVE MIAMI FL 33101 USA "
    },
    {
      "Location ID": "33116",
      "Location Name": "SNAPPER CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11000 SW 104TH ST MIAMI FL 33116 USA "
    },
    {
      "Location ID": "33125",
      "Location Name": "JOSE MARTI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 425 NW 27TH AVE MIAMI FL 33125 USA "
    },
    {
      "Location ID": "33138",
      "Location Name": "LITTLE RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 140 NE 84TH ST MIAMI FL 33138 USA "
    },
    {
      "Location ID": "33139",
      "Location Name": "MIAMI BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1300 WASHINGTON AVE MIAMI BEACH FL 33119 USA "
    },
    {
      "Location ID": "33141",
      "Location Name": "NORMANDY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 525 71ST ST MIAMI BEACH FL 33141 USA "
    },
    {
      "Location ID": "33142",
      "Location Name": "ALLAPATTAH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1799 NW 28TH ST MIAMI FL 33142 USA "
    },
    {
      "Location ID": "33146",
      "Location Name": "HIBISCUS CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3500 S DIXIE HWY MIAMI FL 33146 USA "
    },
    {
      "Location ID": "33155",
      "Location Name": "LUDLAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6900 BIRD RD MIAMI FL 33155 USA "
    },
    {
      "Location ID": "33160",
      "Location Name": "NORTH MIAMI BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16400 W DIXIE HWY NORTH MIAMI BEACH FL 33160 USA "
    },
    {
      "Location ID": "33165",
      "Location Name": "OLYMPIA HEIGHTS STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3801 SW 117TH AVE OLYMPIA HEIGHTS FL 33165 USA "
    },
    {
      "Location ID": "33167",
      "Location Name": "GRATIGNY BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 995 NW 119 STREET MIAMI FL 33167 USA "
    },
    {
      "Location ID": "33169",
      "Location Name": "NORLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18640 NW 2ND AVE MIAMI FL 33169 USA "
    },
    {
      "Location ID": "33173",
      "Location Name": "SUNSET STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7501 SW 117TH AVE MIAMI FL 33173 USA "
    },
    {
      "Location ID": "33180",
      "Location Name": "COUNTY LINE ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 SE 1ST AVE HALLANDALE BEACH FL 33009 USA "
    },
    {
      "Location ID": "33197",
      "Location Name": "QUAIL HEIGHTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10360 SW 186TH ST MIAMI FL 33197 USA "
    },
    {
      "Location ID": "331AI",
      "Location Name": "ALLIANCE GROUND INTERNATIONAL LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1651 NW 68TH AVE # 706S MIAMI FL 33126 USA "
    },
    {
      "Location ID": "331AZ",
      "Location Name": "MIA5",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1900 NW 132ND PL MIAMI FL 33182 USA "
    },
    {
      "Location ID": "331CA",
      "Location Name": "CARGO SOLUTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8412 NW 70TH ST MIAMI FL 33166 USA "
    },
    {
      "Location ID": "331FZ",
      "Location Name": "LOG ROYAL PALM FL PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21201 NW 43RD AVE MIAMI GARDENS FL 33014 USA "
    },
    {
      "Location ID": "331IS",
      "Location Name": "SIA IMPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5457 NW 72ND AVE MEDLEY FL 33166 USA "
    },
    {
      "Location ID": "331MA",
      "Location Name": "MODA EXPRESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19501 NE 10TH AVE STE G MIAMI FL 33179 USA "
    },
    {
      "Location ID": "331PM",
      "Location Name": "MIAMI FL L&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1904 NW 97TH AVENUE DORAL FL 33172 USA "
    },
    {
      "Location ID": "331SW",
      "Location Name": "SWISSPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1851 NW 68TH AVE BLDG 706 MIAMI FL 33126 USA "
    },
    {
      "Location ID": "331TH",
      "Location Name": "THERAFIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1578 NW 165TH ST MIAMI GARDENS FL 33169 USA "
    },
    {
      "Location ID": "331UP",
      "Location Name": "UPS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3401 NW 67TH AVE BAY 16 MIAMI FL 33122 USA "
    },
    {
      "Location ID": "331WW",
      "Location Name": "WORLDWIDE FLIGHT SERVICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2461 NW 66TH AVE STE 100 MIAMI FL 33122 USA "
    },
    {
      "Location ID": "333",
      "Location Name": "FORT LAUDERDALE (FL) P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1900 W OAKLAND PARK BLVD STE 1 FORT LAUDERDALE FL 33310 USA "
    },
    {
      "Location ID": "333AC",
      "Location Name": "FT LAUDERDALE AIR CARGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3519 SW 2ND AVE FREIGHT HOUSE FT LAUDERDALE FL 33315 USA "
    },
    {
      "Location ID": "333AE",
      "Location Name": "AMERICAN EXPRESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2965 WESTON RD STE 100 WESTON FL 33331 USA "
    },
    {
      "Location ID": "333AN",
      "Location Name": "ANIMATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10424 W STATE ROAD 84 STE 4271 DAVIE FL 33324 USA "
    },
    {
      "Location ID": "333AO",
      "Location Name": "AURAFIN ORO AMERICA (RICHLINE GROUP)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6701 NOB HILL RD TAMARAC FL 33321 USA "
    },
    {
      "Location ID": "333CA",
      "Location Name": "SOUTH FL CMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1900 W OAKLAND PARK BLVD STE 1 FORT LAUDERDALE FL 33310 USA "
    },
    {
      "Location ID": "333FL",
      "Location Name": "FTL MO CARRIER ANX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1900 W OAKLAND PARK BLVD STE 105 OAKLAND PARK FL 33310 USA "
    },
    {
      "Location ID": "333IB",
      "Location Name": "IBB",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14051 NW 14TH ST SUNRISE FL 33323 USA "
    },
    {
      "Location ID": "333PC",
      "Location Name": "POSTAL CENTER INTERNATIONAL PCI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2965 W CORPORATE LAKES BLVD WESTON FL 33331 USA "
    },
    {
      "Location ID": "334",
      "Location Name": "LOG WEST PALM BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3200 SUMMIT BLVD WEST PALM BEACH FL 33416 USA "
    },
    {
      "Location ID": "33401",
      "Location Name": "CITY PLACE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1040 OKEECHOBEE RD WEST PALM BEACH FL 33401 USA "
    },
    {
      "Location ID": "33404",
      "Location Name": "RIVIERA BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1905 W BLUE HERON BLVD RIVIERA BEACH FL 33404 USA "
    },
    {
      "Location ID": "33410",
      "Location Name": "PALM BEACH GARDENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3330 FAIRCHILD GARDENS AVE PALM BEACH GARDENS FL 33410 USA "
    },
    {
      "Location ID": "33411",
      "Location Name": "PALMS WEST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10299 SOUTHERN BLVD ROYAL PALM BEACH FL 33411 USA "
    },
    {
      "Location ID": "33415",
      "Location Name": "PALMS CENTRAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 818 S MILITARY TRL WEST PALM BEACH FL 33415 USA "
    },
    {
      "Location ID": "33416",
      "Location Name": "WEST PALM BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3200 SUMMIT BLVD WEST PALM BEACH FL 33416 USA "
    },
    {
      "Location ID": "33417",
      "Location Name": "HAVERHILL BR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3900 N HAVERHILL RD WEST PALM BEACH FL 33417 USA "
    },
    {
      "Location ID": "33428",
      "Location Name": "WEST BOCA CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21850 JUDGE WINIKOFF RD BOCA RATON FL 33428 USA "
    },
    {
      "Location ID": "33430",
      "Location Name": "BELLE GLADE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1340 NW AVENUE L BELLE GLADE FL 33430 USA "
    },
    {
      "Location ID": "33431",
      "Location Name": "BOCA RATON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2800 N MILITARY TRL BOCA RATON FL 33431 USA "
    },
    {
      "Location ID": "33432",
      "Location Name": "DOWNTOWN BOCA RATON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 170 NE 2ND ST BOCA RATON FL 33432 USA "
    },
    {
      "Location ID": "33433",
      "Location Name": "BOCA RIO BR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8185 VIA ANCHO RD BOCA RATON FL 33433 USA "
    },
    {
      "Location ID": "33435",
      "Location Name": "DOWNTOWN BOYNTON BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 217 N SEACREST BLVD BOYNTON BEACH FL 33435 USA "
    },
    {
      "Location ID": "33436",
      "Location Name": "BOYNTON BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1530 W BOYNTON BEACH BLVD BOYNTON BEACH FL 33436 USA "
    },
    {
      "Location ID": "33438",
      "Location Name": "CANAL POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12570 US HIGHWAY 441 CANAL POINT FL 33438 USA "
    },
    {
      "Location ID": "33440",
      "Location Name": "CLEWISTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 S BERNER RD CLEWISTON FL 33440 USA "
    },
    {
      "Location ID": "33441",
      "Location Name": "DEERFIELD BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 212 E HILLSBORO BLVD DEERFIELD BEACH FL 33441 USA "
    },
    {
      "Location ID": "33442",
      "Location Name": "DEERFIELD BEACH ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 155 GOOLSBY BLVD DEERFIELD BEACH FL 33442 USA "
    },
    {
      "Location ID": "33445",
      "Location Name": "DELRAY BEACH CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 855 NW 17TH AVE STE C DELRAY BEACH FL 33445 USA "
    },
    {
      "Location ID": "33446",
      "Location Name": "WEST DELRAY BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10290 ATLANTIC AVE DELRAY BEACH FL 33446 USA "
    },
    {
      "Location ID": "33455",
      "Location Name": "HOBE SOUND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9995 SE FEDERAL HWY HOBE SOUND FL 33455 USA "
    },
    {
      "Location ID": "33458",
      "Location Name": "JUPITER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1095 MILITARY TRL JUPITER FL 33458 USA "
    },
    {
      "Location ID": "33459",
      "Location Name": "LAKE HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 W CORKSCREW BLVD LAKE HARBOR FL 33459 USA "
    },
    {
      "Location ID": "33460",
      "Location Name": "LUCERNE AVENUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 720 LUCERNE AVE LAKE WORTH BEACH FL 33460 USA "
    },
    {
      "Location ID": "33461",
      "Location Name": "LAKE WORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4151 LAKE WORTH RD LAKE WORTH FL 33461 USA "
    },
    {
      "Location ID": "33462",
      "Location Name": "LANTANA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 W OCEAN AVE LANTANA FL 33462 USA "
    },
    {
      "Location ID": "33469",
      "Location Name": "TEQUESTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 OLD DIXIE HWY JUPITER FL 33469 USA "
    },
    {
      "Location ID": "33470",
      "Location Name": "LOXAHATCHEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14611 SOUTHERN BLVD LOXAHATCHEE FL 33470 USA "
    },
    {
      "Location ID": "33471",
      "Location Name": "MOORE HAVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 10TH ST SW MOORE HAVEN FL 33471 USA "
    },
    {
      "Location ID": "33476",
      "Location Name": "PAHOKEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 331 BACOM POINT RD PAHOKEE FL 33476 USA "
    },
    {
      "Location ID": "33480",
      "Location Name": "PALM BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 340 ROYAL POINCIANA WAY STE 328 PALM BEACH FL 33480 USA "
    },
    {
      "Location ID": "33486",
      "Location Name": "PALMETTO PARK ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1275 W PALMETTO PARK RD BOCA RATON FL 33486 USA "
    },
    {
      "Location ID": "33493",
      "Location Name": "SOUTH BAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 190 US HIGHWAY 27 S SOUTH BAY FL 33493 USA "
    },
    {
      "Location ID": "334AU",
      "Location Name": "AUDIO AMERICA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15132 PARK OF COMMERCE #100 JUPITER FL 33478 USA "
    },
    {
      "Location ID": "334CA",
      "Location Name": "PALM BCH CARRIER ANX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1040 OKEECHOBEE RD STE 100 WEST PALM BEACH FL 33401 USA "
    },
    {
      "Location ID": "334CF",
      "Location Name": "CUSHMAN FRUIT CO INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3325 FOREST HILL BLVD. WEST PALM BEACH FL 33406 USA "
    },
    {
      "Location ID": "334DE",
      "Location Name": "DERMAGIST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1562 PARK LN S STE 700 JUPITER FL 33458 USA "
    },
    {
      "Location ID": "334ET",
      "Location Name": "EXTREME TACTICAL DYNAMICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1410 PARK LN S STE 4 JUPITER FL 33458 USA "
    },
    {
      "Location ID": "334JD",
      "Location Name": "JOEY D GOLF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2885 JUPITER PARK DR STE 300 JUPITER FL 33458 USA "
    },
    {
      "Location ID": "334LA",
      "Location Name": "LIFE'S ABUNDANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 CAPITAL ST JUPITER FL 33458 USA "
    },
    {
      "Location ID": "334NU",
      "Location Name": "NUTRAMEDIX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2885 JUPITER PARK DR STE 1600 JUPITER FL 33458 USA "
    },
    {
      "Location ID": "334PO",
      "Location Name": "WEST BOCA POSTAL STORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11419 W PALMETTO PARK RD STE A BOCA RATON FL 33428 USA "
    },
    {
      "Location ID": "334PS",
      "Location Name": "PALMETTO PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1279 W PALMETTO PARK RD BOCA RATON FL 33486 USA "
    },
    {
      "Location ID": "334SA",
      "Location Name": "WORTH AVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 S COUNTY RD PALM BEACH FL 33480 USA "
    },
    {
      "Location ID": "334WC",
      "Location Name": "WELLINGTON CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3161 FAIRLANE FARMS RD STE 1 WELLINGTON FL 33414 USA "
    },
    {
      "Location ID": "334WG",
      "Location Name": "WILEN PRESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3333 SW 15TH ST DEERFIELD BCH FL 33442 USA "
    },
    {
      "Location ID": "335",
      "Location Name": "TAMPA PDC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3501 BESSIE COLEMAN BLVD FL 2 TAMPA FL 33630 USA "
    },
    {
      "Location ID": "33503",
      "Location Name": "BALM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15036 BALM RD BALM FL 33503 USA "
    },
    {
      "Location ID": "33508",
      "Location Name": "BLOOMINGDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 867 W BLOOMINGDALE AVE BRANDON FL 33511 USA "
    },
    {
      "Location ID": "33511",
      "Location Name": "BRANDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1315 OAKFIELD DR BRANDON FL 33511 USA "
    },
    {
      "Location ID": "33513",
      "Location Name": "BUSHNELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 800 N MAIN ST BUSHNELL FL 33513 USA "
    },
    {
      "Location ID": "33514",
      "Location Name": "CENTER HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 89 E KINGS HWY CENTER HILL FL 33514 USA "
    },
    {
      "Location ID": "33521",
      "Location Name": "COLEMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1109 FLORIDA AVE COLEMAN FL 33521 USA "
    },
    {
      "Location ID": "33524",
      "Location Name": "CRYSTAL SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1425 PAUL S BUCHMAN HWY CRYSTAL SPRINGS FL 33524 USA "
    },
    {
      "Location ID": "33525",
      "Location Name": "DADE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37926 CHURCH AVE DADE CITY FL 33525 USA "
    },
    {
      "Location ID": "33527",
      "Location Name": "DOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14170 DR MARTIN LUTHER KING JR BLVD DOVER FL 33527 USA "
    },
    {
      "Location ID": "33530",
      "Location Name": "DURANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7202 TURKEY CREEK RD DURANT FL 33530 USA "
    },
    {
      "Location ID": "33534",
      "Location Name": "GIBSONTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11126 S US HIGHWAY 41 GIBSONTON FL 33534 USA "
    },
    {
      "Location ID": "33537",
      "Location Name": "LACOOCHEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 38915 STATE ROAD 575 LACOOCHEE FL 33537 USA "
    },
    {
      "Location ID": "33538",
      "Location Name": "LAKE PANASOFFKEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1397 CR 437 LAKE PANASOFFKEE FL 33538 USA "
    },
    {
      "Location ID": "33540",
      "Location Name": "ZEPHYRHILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 38032 POSTAL DR ZEPHYRHILLS FL 33542 USA "
    },
    {
      "Location ID": "33547",
      "Location Name": "LITHIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7825 LITHIA PINECREST RD LITHIA FL 33547 USA "
    },
    {
      "Location ID": "33548",
      "Location Name": "LUTZ(M.O. CARRIERS/BOXES)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18489 US HWY 41 N LUTZ FL 33548 USA "
    },
    {
      "Location ID": "33549",
      "Location Name": "LUTZ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18489 N US HIGHWAY 41 LUTZ FL 33549 USA "
    },
    {
      "Location ID": "33550",
      "Location Name": "MANGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11507 DR MARTIN LUTHER KING JR BLVD MANGO FL 33550 USA "
    },
    {
      "Location ID": "33556",
      "Location Name": "ODESSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13266 BYRD DR STE 100 ODESSA FL 33556 USA "
    },
    {
      "Location ID": "33564",
      "Location Name": "PLANT CITY BOXES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 W REYNOLDS ST PLANT CITY FL 33566 USA "
    },
    {
      "Location ID": "33566",
      "Location Name": "PLANT CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2501 WALDEN WOODS DR PLANT CITY FL 33566 USA "
    },
    {
      "Location ID": "33568",
      "Location Name": "RIVERVIEW(M.O. CARRIERS/BOXES)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10810 BOYETTE RD RIVERVIEW FL 33568 USA "
    },
    {
      "Location ID": "33569",
      "Location Name": "RIVERVIEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10810 BOYETTE RD RIVERVIEW FL 33569 USA "
    },
    {
      "Location ID": "33570",
      "Location Name": "RUSKIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 455 N US HIGHWAY 41 RUSKIN FL 33570 USA "
    },
    {
      "Location ID": "33572",
      "Location Name": "RUSKIN CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6859 N US HIGHWAY 41 APOLLO BEACH FL 33572 USA "
    },
    {
      "Location ID": "33573",
      "Location Name": "SUN CITY CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1645 SUN CITY CENTER PLZ SUN CITY CENTER FL 33573 USA "
    },
    {
      "Location ID": "33574",
      "Location Name": "SAINT LEO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33601 STATE ROAD 52 SAINT LEO FL 33574 USA "
    },
    {
      "Location ID": "33576",
      "Location Name": "SAN ANTONIO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12231 MAIN ST SAN ANTONIO FL 33576 USA "
    },
    {
      "Location ID": "33584",
      "Location Name": "SEFFNER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1431 S KINGSWAY RD SEFFNER FL 33584 USA "
    },
    {
      "Location ID": "33585",
      "Location Name": "SUMTERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 445 S US 301 SUMTERVILLE FL 33585 USA "
    },
    {
      "Location ID": "33586",
      "Location Name": "SUN CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4449 OLD HIGHWAY 41 S SUN CITY FL 33586 USA "
    },
    {
      "Location ID": "33587",
      "Location Name": "SYDNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1620 SYDNEY DOVER RD SYDNEY FL 33587 USA "
    },
    {
      "Location ID": "33592",
      "Location Name": "THONOTOSASSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10806 MAIN ST THONOTOSASSA FL 33592 USA "
    },
    {
      "Location ID": "33593",
      "Location Name": "TRILBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37451 TRILBY RD TRILBY FL 33593 USA "
    },
    {
      "Location ID": "33594",
      "Location Name": "VALRICO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2406 E STATE ROAD 60 VALRICO FL 33594 USA "
    },
    {
      "Location ID": "33597",
      "Location Name": "WEBSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 SE 1ST ST WEBSTER FL 33597 USA "
    },
    {
      "Location ID": "33598",
      "Location Name": "WIMAUMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5608 STATE ROAD 674 WIMAUMA FL 33598 USA "
    },
    {
      "Location ID": "335AB",
      "Location Name": "CPU APOLLO BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 219 FLAMINGO DR APOLLO BEACH FL 33572 USA "
    },
    {
      "Location ID": "335BH",
      "Location Name": "BERGHOFF INTERNATIONAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11063 STATE ROAD 54 ODESSA FL 33556 USA "
    },
    {
      "Location ID": "335CS",
      "Location Name": "COMPLETE SKIN CARE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9514 SWIFT CREEK CIR DOVER FL 33527 USA "
    },
    {
      "Location ID": "335DC",
      "Location Name": "YBOR CITY FL PDC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1801 GRANT ST TAMPA FL 33605 USA "
    },
    {
      "Location ID": "335SC",
      "Location Name": "SUNCOAST CYCLE 2",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11052 CHALLENGER AVE ODESSA FL 33556 USA "
    },
    {
      "Location ID": "335SP",
      "Location Name": "SPEEDY PRO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 725 LITHIA PINECREST RD BRANDON FL 33511 USA "
    },
    {
      "Location ID": "335SW",
      "Location Name": "SEED WORLD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6881 LUTZ LAKE FERN RD ODESSA FL 33556 USA "
    },
    {
      "Location ID": "335WC",
      "Location Name": "CPU WESLEY CHAPEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5804 BOYETTE RD WESLEY CHAPEL FL 33545 USA "
    },
    {
      "Location ID": "33602",
      "Location Name": "COMMERCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 N ASHLEY DR TAMPA FL 33602 USA "
    },
    {
      "Location ID": "33604",
      "Location Name": "SULPHUR SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6706 N NEBRASKA AVE TAMPA FL 33604 USA "
    },
    {
      "Location ID": "33605",
      "Location Name": "YBOR CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2000 E 12TH AVE TAMPA FL 33605 USA "
    },
    {
      "Location ID": "33607",
      "Location Name": "WEST TAMPA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1802 N HOWARD AVE TAMPA FL 33607 USA "
    },
    {
      "Location ID": "33610",
      "Location Name": "PRODUCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2810 E HILLSBOROUGH AVE TAMPA FL 33610 USA "
    },
    {
      "Location ID": "33611",
      "Location Name": "INTERBAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4520 W OAKELLAR AVE TAMPA FL 33611 USA "
    },
    {
      "Location ID": "33612",
      "Location Name": "FOREST HILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11800 N FLORIDA AVE TAMPA FL 33612 USA "
    },
    {
      "Location ID": "33614",
      "Location Name": "HILLDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3201 W HILLSBOROUGH AVE TAMPA FL 33614 USA "
    },
    {
      "Location ID": "33615",
      "Location Name": "TOWN N COUNTRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7521 PAULA DR TAMPA FL 33615 USA "
    },
    {
      "Location ID": "33617",
      "Location Name": "TEMPLE TERRACE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8761 N 56TH ST TEMPLE TERRACE FL 33617 USA "
    },
    {
      "Location ID": "33618",
      "Location Name": "CARROLLWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12651 N DALE MABRY HWY TAMPA FL 33618 USA "
    },
    {
      "Location ID": "33619",
      "Location Name": "PALM RIVER CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9860 CURRIE DAVIS DR TAMPA FL 33619 USA "
    },
    {
      "Location ID": "33624",
      "Location Name": "NORTHDALE CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5330 EHRLICH RD STE 122 TAMPA FL 33624 USA "
    },
    {
      "Location ID": "33629",
      "Location Name": "PENINSULA TAMPA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1002B S CHURCH AVE TAMPA FL 33629 USA "
    },
    {
      "Location ID": "33647",
      "Location Name": "NEW TAMPA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16350 BRUCE B DOWNS BLVD TAMPA FL 33647 USA "
    },
    {
      "Location ID": "33694",
      "Location Name": "EHRLICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14910 N DALE MABRY HWY TAMPA FL 33618 USA "
    },
    {
      "Location ID": "336FF",
      "Location Name": "FUNNELS2FULFILLMENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 608 S 22ND ST TAMPA FL 33605 USA "
    },
    {
      "Location ID": "336FK",
      "Location Name": "FALKENBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10121 E ADAMO DR TAMPA FL 33619 USA "
    },
    {
      "Location ID": "336FZ",
      "Location Name": "LOG YBOR CITY FL PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4720 OAK FAIR BLVD TAMPA FL 33610 USA "
    },
    {
      "Location ID": "336SC",
      "Location Name": "SOUTHWEST  AIRLINE CARGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4662 AIR CARGO RD STE 1800 TAMPA FL 33614 USA "
    },
    {
      "Location ID": "336SE",
      "Location Name": "AMK SQUARED ENTERPRISES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12826 COMMODITY PL TAMPA FL 33626 USA "
    },
    {
      "Location ID": "336TH",
      "Location Name": "TAMPA THS INTEGRATED AIR SVC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7004 BENJAMIN RD STE 100E TAMPA FL 33634 USA "
    },
    {
      "Location ID": "336VD",
      "Location Name": "VITAMIN DISCOUNT CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5519 SOUTHERN COMFORT BLVD TAMPA FL 33634 USA "
    },
    {
      "Location ID": "337",
      "Location Name": "SAINT PETERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3135 1ST AVE N SAINT PETERSBUR FL 33730 USA "
    },
    {
      "Location ID": "33701",
      "Location Name": "OPEN AIR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 76 4TH ST N SAINT PETERSBURG FL 33701 USA "
    },
    {
      "Location ID": "33702",
      "Location Name": "NORTHSIDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1201 GANDY BLVD N SAINT PETERSBURG FL 33702 USA "
    },
    {
      "Location ID": "33703",
      "Location Name": "GATEWAY MALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 701 77TH AVE N SAINT PETERSBURG FL 33702 USA "
    },
    {
      "Location ID": "33704",
      "Location Name": "EUCLID",
      "Time Zone Offset (Hours)": 0,
      "Address": " 901 34TH AVE N SAINT PETERSBURG FL 33704 USA "
    },
    {
      "Location ID": "33705",
      "Location Name": "MIDTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1750 16TH ST S SAINT PETERSBURG FL 33705 USA "
    },
    {
      "Location ID": "33706",
      "Location Name": "SAINT PETE BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 COREY AVE ST PETE BEACH FL 33706 USA "
    },
    {
      "Location ID": "33707",
      "Location Name": "GULFWINDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4222 22ND AVE S SAINT PETERSBURG FL 33711 USA "
    },
    {
      "Location ID": "33708",
      "Location Name": "MADEIRA BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15019 MADEIRA WAY MADEIRA BEACH FL 33708 USA "
    },
    {
      "Location ID": "33710",
      "Location Name": "CROSSROADS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1275 66TH ST N SAINT PETERSBURG FL 33710 USA "
    },
    {
      "Location ID": "33733",
      "Location Name": "SAINT PETERSBURG MAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3135 1ST AVE N SAINT PETERSBURG FL 33730 USA "
    },
    {
      "Location ID": "33744",
      "Location Name": "BAY PINES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10000 BAY PINES BLVD BLDG 20 BAY PINES FL 33744 USA "
    },
    {
      "Location ID": "33762",
      "Location Name": "HIGH POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4600 140TH AVE N STE 100 CLEARWATER FL 33762 USA "
    },
    {
      "Location ID": "33765",
      "Location Name": "CLEARWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 S BELCHER RD CLEARWATER FL 33765 USA "
    },
    {
      "Location ID": "33770",
      "Location Name": "LARGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 8TH AVE SW LARGO FL 33770 USA "
    },
    {
      "Location ID": "33772",
      "Location Name": "SEMINOLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9355 113TH ST SEMINOLE FL 33772 USA "
    },
    {
      "Location ID": "33781",
      "Location Name": "PINELLAS PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5260 78TH AVE N PINELLAS PARK FL 33781 USA "
    },
    {
      "Location ID": "33785",
      "Location Name": "INDIAN ROCKS BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 204 4TH AVE INDIAN ROCKS BEACH FL 33785 USA "
    },
    {
      "Location ID": "337BA",
      "Location Name": "BAYCARE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8452 118TH AVE LARGO FL 33773 USA "
    },
    {
      "Location ID": "337CA",
      "Location Name": "TAMPA XMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4720 OAK FAIR BLVD TAMPA FL 33610 USA "
    },
    {
      "Location ID": "337CE",
      "Location Name": "CONCEPT ELECTRONICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22069 US HIGHWAY 19 N CLEARWATER FL 33765 USA "
    },
    {
      "Location ID": "337CS",
      "Location Name": "CLEVELAND STREET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 650 CLEVELAND ST CLEARWATER FL 33755 USA "
    },
    {
      "Location ID": "337EC",
      "Location Name": "EYE CANDY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1684 N BELCHER RD CLEARWATER FL 33765 USA "
    },
    {
      "Location ID": "337HA",
      "Location Name": "HASHTAG DIRECT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2700 23RD ST N SAINT PETERSBURG FL 33713 USA "
    },
    {
      "Location ID": "337JG",
      "Location Name": "JAGGED PEAK - GANDY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2007 GANDY BLVD N SAINT PETERSBURG FL 33702 USA "
    },
    {
      "Location ID": "337MF",
      "Location Name": "MUSCLE FACTOR X",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2852 20TH AVE N ST PETERSBURG FL 33713-4238 USA "
    },
    {
      "Location ID": "337PS",
      "Location Name": "COUNTRYSIDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25941 US HIGHWAY 19 N CLEARWATER FL 33763 USA "
    },
    {
      "Location ID": "337QA",
      "Location Name": "QAS-QUA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4890 N HOOVER BLVD TAMPA FL 33634 USA "
    },
    {
      "Location ID": "337SB",
      "Location Name": "SUNSET POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1851 N HERCULES AVE CLEARWATER FL 33765 USA "
    },
    {
      "Location ID": "337VP",
      "Location Name": "VAL-PAK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 VALPAK AVENUE NORTH ST PETERSBURG FL 33716-4102 USA "
    },
    {
      "Location ID": "338",
      "Location Name": "LAKELAND MAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2800 LAKELAND HILLS BLVD LAKELAND FL 33805 USA "
    },
    {
      "Location ID": "33803",
      "Location Name": "DIXIELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1057 S FLORIDA AVE LAKELAND FL 33803 USA "
    },
    {
      "Location ID": "33805",
      "Location Name": "LAKELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2800 LAKELAND HILLS BLVD LAKELAND FL 33805-9998 USA "
    },
    {
      "Location ID": "33813",
      "Location Name": "SOUTHSIDE POSTAL STORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6000 S FLORIDA AVE LAKELAND FL 33813 USA "
    },
    {
      "Location ID": "33815",
      "Location Name": "DOWNTOWN LAKELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 N MISSOURI AVE LAKELAND FL 33815 USA "
    },
    {
      "Location ID": "33820",
      "Location Name": "ALTURAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2760 3RD ST ALTURAS FL 33830 USA "
    },
    {
      "Location ID": "33823",
      "Location Name": "AUBURNDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 E LAKE AVE AUBURNDALE FL 33823 USA "
    },
    {
      "Location ID": "33825",
      "Location Name": "AVON PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 S VERONA AVE AVON PARK FL 33825 USA "
    },
    {
      "Location ID": "33827",
      "Location Name": "BABSON PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1200 N SCENIC HWY BABSON PARK FL 33827 USA "
    },
    {
      "Location ID": "33830",
      "Location Name": "BARTOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 S CENTRAL AVE BARTOW FL 33830 USA "
    },
    {
      "Location ID": "33834",
      "Location Name": "BOWLING GREEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4702 CENTRAL AVE BOWLING GREEN FL 33834 USA "
    },
    {
      "Location ID": "33835",
      "Location Name": "BRADLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6947 OLD  STATE ROAD 37 BRADLEY FL 33835 USA "
    },
    {
      "Location ID": "33837",
      "Location Name": "DAVENPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 SOUTH BLVD E DAVENPORT FL 33837 USA "
    },
    {
      "Location ID": "33838",
      "Location Name": "DUNDEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 ATLANTIC AVE DUNDEE FL 33838 USA "
    },
    {
      "Location ID": "33839",
      "Location Name": "EAGLE LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 665 N US HIGHWAY 17 EAGLE LAKE FL 33839 USA "
    },
    {
      "Location ID": "33840",
      "Location Name": "EATON PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2615 ELLIS AVE EATON PARK FL 33840 USA "
    },
    {
      "Location ID": "33841",
      "Location Name": "FORT MEADE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 N SEMINOLE AVE FORT MEADE FL 33841 USA "
    },
    {
      "Location ID": "33843",
      "Location Name": "FROSTPROOF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 W 1ST ST FROSTPROOF FL 33843 USA "
    },
    {
      "Location ID": "33844",
      "Location Name": "HAINES CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1051 STATE ROAD 544 E HAINES CITY FL 33844 USA "
    },
    {
      "Location ID": "33846",
      "Location Name": "HIGHLAND CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4114 CLUBHOUSE RD HIGHLAND CITY FL 33846 USA "
    },
    {
      "Location ID": "33847",
      "Location Name": "HOMELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 339 4TH ST HOMELAND FL 33847 USA "
    },
    {
      "Location ID": "33848",
      "Location Name": "INTERCESSION CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1538 TALLAHASSEE BLVD INTERCESSION CITY FL 33848 USA "
    },
    {
      "Location ID": "33849",
      "Location Name": "KATHLEEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6136 KATHLEEN RD KATHLEEN FL 33849 USA "
    },
    {
      "Location ID": "33850",
      "Location Name": "LAKE ALFRED",
      "Time Zone Offset (Hours)": 0,
      "Address": " 195 W HAINES BLVD LAKE ALFRED FL 33850 USA "
    },
    {
      "Location ID": "33851",
      "Location Name": "LAKE HAMILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 W MAIN ST LAKE HAMILTON FL 33851 USA "
    },
    {
      "Location ID": "33852",
      "Location Name": "LAKE PLACID",
      "Time Zone Offset (Hours)": 0,
      "Address": " 901 HILLCREST AVE LAKE PLACID FL 33852 USA "
    },
    {
      "Location ID": "33853",
      "Location Name": "LAKE WALES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 W PARK AVE LAKE WALES FL 33853 USA "
    },
    {
      "Location ID": "33854",
      "Location Name": "LAKESHORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 CLUB CIR LAKESHORE FL 33854 USA "
    },
    {
      "Location ID": "33855",
      "Location Name": "INDIAN LAKE ESTATES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1720 PARK AVE INDIAN LAKE ESTATES FL 33855 USA "
    },
    {
      "Location ID": "33856",
      "Location Name": "NALCREST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10275 LEISURE LN W NALCREST FL 33856 USA "
    },
    {
      "Location ID": "33857",
      "Location Name": "LORIDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1665 US HIGHWAY 98 LORIDA FL 33857 USA "
    },
    {
      "Location ID": "33858",
      "Location Name": "LOUGHMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 511 RONALD REAGAN PKWY LOUGHMAN FL 33858 USA "
    },
    {
      "Location ID": "33860",
      "Location Name": "MULBERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2000 MULFORD RD MULBERRY FL 33860 USA "
    },
    {
      "Location ID": "33863",
      "Location Name": "NICHOLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4805 NICHOLS RD NICHOLS FL 33863 USA "
    },
    {
      "Location ID": "33865",
      "Location Name": "ONA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5155 STATE ROAD 64 W ONA FL 33865 USA "
    },
    {
      "Location ID": "33868",
      "Location Name": "POLK CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 112 CARTER BLVD POLK CITY FL 33868 USA "
    },
    {
      "Location ID": "33870",
      "Location Name": "SEBRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 518 N RIDGEWOOD DR SEBRING FL 33870 USA "
    },
    {
      "Location ID": "33871",
      "Location Name": "LAKE JACKSON BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 829 MALL RING RD SEBRING FL 33870 USA "
    },
    {
      "Location ID": "33873",
      "Location Name": "WAUCHULA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 E MAIN ST WAUCHULA FL 33873 USA "
    },
    {
      "Location ID": "33877",
      "Location Name": "WAVERLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7301 STATE RD 540 WAVERLY FL 33877 USA "
    },
    {
      "Location ID": "33880",
      "Location Name": "WINTER HAVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 W CENTRAL AVE WINTER HAVEN FL 33880 USA "
    },
    {
      "Location ID": "33881",
      "Location Name": "FLORENCE VILLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1401 1ST ST N WINTER HAVEN FL 33881 USA "
    },
    {
      "Location ID": "33890",
      "Location Name": "ZOLFO SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3141 US HIGHWAY 17 S ZOLFO SPRINGS FL 33890 USA "
    },
    {
      "Location ID": "338AA",
      "Location Name": "1ST STREET CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 140 N 1ST ST LAKE WALES FL 33853 USA "
    },
    {
      "Location ID": "338AR",
      "Location Name": "BY ALEXA RAE BOUTIQUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3150 COUNTY LINE RD SUITE 3 LAKELAND FL 38811 USA "
    },
    {
      "Location ID": "338AZ",
      "Location Name": "AMAZON MCO9 SORT CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2841 ACCESS RD NW DAVENPORT FL 33897 USA "
    },
    {
      "Location ID": "338EO",
      "Location Name": "ESSENTIAL OILS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4605 OAK CIR SEBRING FL 33870 USA "
    },
    {
      "Location ID": "338EP",
      "Location Name": "EAGLE PHARMACY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 330 EAGLES LANDING DR LAKELAND FL 33810 USA "
    },
    {
      "Location ID": "338LC",
      "Location Name": "LAKELAND CHRYSLER DODGE JEEP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2875 MALL HILL DR LAKELAND FL 33810-6736 USA "
    },
    {
      "Location ID": "338LI",
      "Location Name": "LIVE INFINITELY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3850 ANCHUA DR LAKELAND FL 33811 USA "
    },
    {
      "Location ID": "338LT",
      "Location Name": "LAKELAND TOYOTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2925 MALL HILL DR LAKELAND FL 33810-6716 USA "
    },
    {
      "Location ID": "338MC",
      "Location Name": "MCO5",
      "Time Zone Offset (Hours)": 0,
      "Address": " 305 DEEN STILL RD DAVENPORT FL 33897 USA "
    },
    {
      "Location ID": "338PM",
      "Location Name": "PILOT MALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4040 LAIRD BLVD LAKELAND FL 33811 USA "
    },
    {
      "Location ID": "338RX",
      "Location Name": "WELLDYNERX INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 EAGLES LANDING DR LAKELAND FL 33810 USA "
    },
    {
      "Location ID": "338SR",
      "Location Name": "SAVE RACK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 307 W MAIN ST STE 2 LAKELAND FL 33815 USA "
    },
    {
      "Location ID": "338WA",
      "Location Name": "WALMART 1",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4900 N RIDGE TRL DAVENPORT FL 33897 USA "
    },
    {
      "Location ID": "338WM",
      "Location Name": "WALMART 2",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5100 N RIDGE TRL DAVENPORT FL 33897 USA "
    },
    {
      "Location ID": "338WT",
      "Location Name": "WALMART SORT CENTER MCO9",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6800 STATE ROAD 33 N LAKELAND FL 33805-8002 USA "
    },
    {
      "Location ID": "339",
      "Location Name": "FORT MYERS P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14080 JETPORT LOOP RM B-111 FORT MYERS FL 33913 USA "
    },
    {
      "Location ID": "33901",
      "Location Name": "DOWNTOWN FORT MYERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1350 MONROE ST FORT MYERS FL 33901 USA "
    },
    {
      "Location ID": "33903",
      "Location Name": "NORTH FORT MYERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1882 N TAMIAMI TRL NORTH FORT MYERS FL 33903 USA "
    },
    {
      "Location ID": "33904",
      "Location Name": "CAPE CORAL SOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4722 SE 17TH AVE CAPE CORAL FL 33904 USA "
    },
    {
      "Location ID": "33905",
      "Location Name": "TICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4585 PALM BEACH BLVD FORT MYERS FL 33905 USA "
    },
    {
      "Location ID": "33907",
      "Location Name": "PAGE FIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2655 N AIRPORT RD FORT MYERS FL 33907 USA "
    },
    {
      "Location ID": "33909",
      "Location Name": "MATLACHA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4547 PINE ISLAND RD NW UNIT A MATLACHA FL 33993 USA "
    },
    {
      "Location ID": "33912",
      "Location Name": "SIX MILE CYPRESS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14651 BEN C PRATT/6 MILE CYPRESS PKWY FORT MYERS FL 33912 USA "
    },
    {
      "Location ID": "33920",
      "Location Name": "ALVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17301 BROADWAY ST ALVA FL 33920 USA "
    },
    {
      "Location ID": "33921",
      "Location Name": "BOCA GRANDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 434 4TH ST BOCA GRANDE FL 33921 USA "
    },
    {
      "Location ID": "33922",
      "Location Name": "BOKEELIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15980 STRINGFELLOW RD BOKEELIA FL 33922 USA "
    },
    {
      "Location ID": "33924",
      "Location Name": "CAPTIVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14812 CAPTIVA DR SW CAPTIVA FL 33924 USA "
    },
    {
      "Location ID": "33928",
      "Location Name": "ESTERO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20791 THREE OAKS PKWY ESTERO FL 33928 USA "
    },
    {
      "Location ID": "33930",
      "Location Name": "FELDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 223 FL- 29 FELDA FL 33930 USA "
    },
    {
      "Location ID": "33931",
      "Location Name": "FORT MYERS BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 CAROLINA AVE FORT MYERS BEACH FL 33931 USA "
    },
    {
      "Location ID": "33935",
      "Location Name": "LABELLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 W WASHINGTON AVE LABELLE FL 33935 USA "
    },
    {
      "Location ID": "33936",
      "Location Name": "LEHIGH ACRES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1201 BUSINESS WAY LEHIGH ACRES FL 33936 USA "
    },
    {
      "Location ID": "33944",
      "Location Name": "PALMDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1134 BROADWAY ST NW PALMDALE FL 33944 USA "
    },
    {
      "Location ID": "33945",
      "Location Name": "PINELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7450 PINELAND RD PINELAND FL 33945 USA "
    },
    {
      "Location ID": "33946",
      "Location Name": "PLACIDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8601 PLACIDA RD PLACIDA FL 33946 USA "
    },
    {
      "Location ID": "33948",
      "Location Name": "MURDOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19190 COCHRAN BLVD FRNT PORT CHARLOTTE FL 33948 USA "
    },
    {
      "Location ID": "33950",
      "Location Name": "PUNTA GORDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 E MARION AVE PUNTA GORDA FL 33950 USA "
    },
    {
      "Location ID": "33952",
      "Location Name": "PORT CHARLOTTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3740 TAMIAMI TRL PORT CHARLOTTE FL 33952 USA "
    },
    {
      "Location ID": "33954",
      "Location Name": "PORT CHARLOTTE ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18100 PAULSON DR PORT CHARLOTTE FL 33954 USA "
    },
    {
      "Location ID": "33956",
      "Location Name": "SAINT JAMES CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4480 STRINGFELLOW RD SAINT JAMES CITY FL 33956 USA "
    },
    {
      "Location ID": "33957",
      "Location Name": "SANIBEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 650 TARPON BAY RD SANIBEL FL 33957 USA "
    },
    {
      "Location ID": "33960",
      "Location Name": "VENUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 820 US HIGHWAY 27 N VENUS FL 33960 USA "
    },
    {
      "Location ID": "33965",
      "Location Name": "FLORIDA GULF COAST UNIVERSITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10501 FGCU BLVD S FORT MYERS FL 33965 USA "
    },
    {
      "Location ID": "33990",
      "Location Name": "CAPE CORAL CENTRAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1030 SE 9TH AVE CAPE CORAL FL 33990 USA "
    },
    {
      "Location ID": "33991",
      "Location Name": "CAPE CORAL NORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1441 SW 4TH ST CAPE CORAL FL 33991 USA "
    },
    {
      "Location ID": "339AM",
      "Location Name": "AMAZON WAREHOUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10030 BAVARIA RD FORT MYERS FL 33913 USA "
    },
    {
      "Location ID": "339AR",
      "Location Name": "ALICO ROAD CPU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7600 ALICO RD FORT MYERS FL 33912 USA "
    },
    {
      "Location ID": "339AT",
      "Location Name": "ATRIUM-CPU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8695 COLLEGE PKWY STE 1030 FORT MYERS FL 33919 USA "
    },
    {
      "Location ID": "339AZ",
      "Location Name": "AMAZON RSW5 - FORT MYERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8250 LOGISTICS DR FORT MYERS FL 33912-6026 USA "
    },
    {
      "Location ID": "339BC",
      "Location Name": "BONES COFFEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 75 MID CAPE TERRACE CAPE CORAL FL 33991 USA "
    },
    {
      "Location ID": "339CC",
      "Location Name": "CYPRESS/CLEVELAND CPU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13300 CLEVELAND AVE FORT MYERS FL 33907 USA "
    },
    {
      "Location ID": "339CE",
      "Location Name": "CLEAN EEZ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16150 OLD US 41 UNIT 2 FORT MYERS FL 33912 USA "
    },
    {
      "Location ID": "339DD",
      "Location Name": "DOG DAYS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4715 CORONADO PKWY STE E CAPE CORAL FL 33904-9074 USA "
    },
    {
      "Location ID": "339DT",
      "Location Name": "DIAMOND TOURS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13100 WESTLINKS TERRACE STE 1 FORT MYERS FL 33913 USA "
    },
    {
      "Location ID": "339EA",
      "Location Name": "ESTERO CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8111 BROADWAY AVE E ESTERO FL 33928-9998 USA "
    },
    {
      "Location ID": "339GB",
      "Location Name": "GABY'S BAGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17121 CAM CT STE 5 FORT MYERS FL 33967 USA "
    },
    {
      "Location ID": "339GW",
      "Location Name": "GOODWILL  INDUSTRIES OF SWFL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5100 TICE ST FORT MYERS FL 33905 USA "
    },
    {
      "Location ID": "339HM",
      "Location Name": "HARVARD MARINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 847 SE 9TH TERRACE CAPE CORAL FL 33990 USA "
    },
    {
      "Location ID": "339HP",
      "Location Name": "HEALTH PARK CPU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9981 S HEALTHPARK DR FORT MYERS FL 33908 USA "
    },
    {
      "Location ID": "339JE",
      "Location Name": "SANIBEL-JERRY'S CONTRACT STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1700 PERIWINKLE WAY SANIBEL FL 33957 USA "
    },
    {
      "Location ID": "339JP",
      "Location Name": "JEANIUS PUBLISHING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5483 LEE ST STE 9 LEHIGH ACRES FL 33871 USA "
    },
    {
      "Location ID": "339KH",
      "Location Name": "KAY'S HALLMARK CPU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15600 SAN CARLOS BLVD FORT MYERS FL 33908 USA "
    },
    {
      "Location ID": "339LB",
      "Location Name": "LAZY H BAIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 635 A RD LABELLE FL 33935 USA "
    },
    {
      "Location ID": "339LM",
      "Location Name": "LEE MEMORIAL HOSPITAL CPU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2776 CLEVELAND AVE FORT MYERS FL 33901 USA "
    },
    {
      "Location ID": "339PD",
      "Location Name": "PULSADERM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12801 COMMONWEALTH DR STE 4 FORT MYERS FL 33913 USA "
    },
    {
      "Location ID": "339RO",
      "Location Name": "REFINERY NUMBER ONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 840 SW 4TH ST CAPE CORAL FL 33991 USA "
    },
    {
      "Location ID": "339SA",
      "Location Name": "SANIBEL-CHAMBER OF COMMERCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 888 SEXTANT DR SANIBEL FL 33957 USA "
    },
    {
      "Location ID": "339SB",
      "Location Name": "SANTA BARBARA CPU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 HANCOK BRIDGE PKWY CAPE CORAL FL 33991 USA "
    },
    {
      "Location ID": "339SC",
      "Location Name": "SAN CARLOS CPU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18911 S TAMIAMI TRAIL FORT MYERS FL 33908 USA "
    },
    {
      "Location ID": "339SF",
      "Location Name": "SHELL FACTORY CPU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2787 N TAMIAMI TRAIL FORT MYERS FL 33903 USA "
    },
    {
      "Location ID": "339SH",
      "Location Name": "SUN HARVEST CPU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14810 METRO PKWY FORT MYERS FL 33912 USA "
    },
    {
      "Location ID": "339SS",
      "Location Name": "SEE THE SUN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5780 YOUNGQUIST RD STE 4 FORT MYERS FL 33912 USA "
    },
    {
      "Location ID": "339ST",
      "Location Name": "SOUL TERRA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1261 LAMAR RD NORTH FORT MYERS FL 33903 USA "
    },
    {
      "Location ID": "339US",
      "Location Name": "USA INDUSTRIES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17050 ALICO COMMERCE CT STE 1 FORT MYERS FL 33967 USA "
    },
    {
      "Location ID": "33ISC",
      "Location Name": "LOG MIAMI ISC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11698 NW 25TH ST MIAMI FL 33112 USA "
    },
    {
      "Location ID": "33TCA",
      "Location Name": "TAMPA CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4009 W SPRUCE ST TAMPA FL 33607 USA "
    },
    {
      "Location ID": "34102",
      "Location Name": "NAPLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1200 GOODLETTE RD N NAPLES FL 34102 USA "
    },
    {
      "Location ID": "34104",
      "Location Name": "EAST NAPLES CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3573 PROGRESS AVE NAPLES FL 34104 USA "
    },
    {
      "Location ID": "34108",
      "Location Name": "COCO RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1130 CREEKSIDE PKWY NAPLES FL 34108 USA "
    },
    {
      "Location ID": "34116",
      "Location Name": "GOLDEN GATE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11665 COLLIER BLVD NAPLES FL 34116 USA "
    },
    {
      "Location ID": "34117",
      "Location Name": "GOLDEN GATE CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4080 15TH AVE SW NAPLES FL 34116 USA "
    },
    {
      "Location ID": "34135",
      "Location Name": "BONITA SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26150 OLD 41 RD BONITA SPRINGS FL 34135 USA "
    },
    {
      "Location ID": "34138",
      "Location Name": "CHOKOLOSKEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 195 SMALLWOOD DR CHOKOLOSKEE FL 34138 USA "
    },
    {
      "Location ID": "34139",
      "Location Name": "EVERGLADES CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 601 COLLIER AVE EVERGLADES CITY FL 34139 USA "
    },
    {
      "Location ID": "34140",
      "Location Name": "GOODLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 427 PAPAYA ST GOODLAND FL 34140 USA "
    },
    {
      "Location ID": "34141",
      "Location Name": "OCHOPEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 38000 TAMIAMI TRL E OCHOPEE FL 34141 USA "
    },
    {
      "Location ID": "34142",
      "Location Name": "IMMOKALEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 810 N 15TH ST IMMOKALEE FL 34142 USA "
    },
    {
      "Location ID": "34145",
      "Location Name": "MARCO ISLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 E ELKCAM CIR MARCO ISLAND FL 34145 USA "
    },
    {
      "Location ID": "34199",
      "Location Name": "TOWN MARKET CPU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7770 PRESERVE LN NAPLES FL 34119 USA "
    },
    {
      "Location ID": "341BJ",
      "Location Name": "BELLA JADE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3845 BECK BLVD NAPLES FL 34114 USA "
    },
    {
      "Location ID": "341BR",
      "Location Name": "BONITA BEACH ROAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9071 BONITA BEACH RD SE BONITA SPRINGS FL 34135 USA "
    },
    {
      "Location ID": "341CW",
      "Location Name": "COLDEST WATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 769 21ST ST SW NAPLES FL 34117 USA "
    },
    {
      "Location ID": "341DC",
      "Location Name": "DURA COAT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4691 7TH AVE NW NAPLES FL 34119 USA "
    },
    {
      "Location ID": "341DT",
      "Location Name": "DOWNTOWN NAPLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 860 6TH AVE S NAPLES FL 34102 USA "
    },
    {
      "Location ID": "341EA",
      "Location Name": "EVERGLADES AMMUNITION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3564 PLOVER AVE NAPLES FL 34117 USA "
    },
    {
      "Location ID": "341GC",
      "Location Name": "GREETING CARD OUTLET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4863 GOLDEN GATE PKWY NAPLES FL 34116 USA "
    },
    {
      "Location ID": "341NA",
      "Location Name": "FASHION2LOVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2560 8TH AVE SE NAPLES FL 34117 USA "
    },
    {
      "Location ID": "341NO",
      "Location Name": "NORDSTROM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5489 TAMIAMI TRL N NAPLES FL 34108 USA "
    },
    {
      "Location ID": "341NP",
      "Location Name": "NEAPOLITAN CPU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 838 NEAPOLITAN WAY NAPLES FL 34103 USA "
    },
    {
      "Location ID": "341PC",
      "Location Name": "NORTH NAPLES CPU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13500 TAMAIMI TRAIL N NAPLES FL 34110 USA "
    },
    {
      "Location ID": "341PP",
      "Location Name": "PICKLE PRO LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3527 PLOVER AVE #2 NAPLES FL 34117 USA "
    },
    {
      "Location ID": "342",
      "Location Name": "MANASOTA P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 850 TALLEVAST RD SARASOTA FL 34260 USA "
    },
    {
      "Location ID": "34203",
      "Location Name": "BRADEN RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4112 53RD AVE E BRADENTON FL 34203 USA "
    },
    {
      "Location ID": "34205",
      "Location Name": "BRADENTON MAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 824 MANATEE AVE W BRADENTON FL 34205 USA "
    },
    {
      "Location ID": "34206",
      "Location Name": "BRADENTON, FL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 824 MANATEE AVE WEST BRADENTON FL 34206 USA "
    },
    {
      "Location ID": "34207",
      "Location Name": "FIFTY SEVENTH AVENUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2005 57TH AVE W BRADENTON FL 34207 USA "
    },
    {
      "Location ID": "34209",
      "Location Name": "PALMA SOLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 75TH ST W BRADENTON FL 34209 USA "
    },
    {
      "Location ID": "34211",
      "Location Name": "LAKEWOOD RANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5910 POST BLVD BRADENTON FL 34211 USA "
    },
    {
      "Location ID": "34215",
      "Location Name": "CORTEZ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12112 44TH AVE W CORTEZ FL 34215 USA "
    },
    {
      "Location ID": "34216",
      "Location Name": "ANNA MARIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101B S BAY BLVD ANNA MARIA FL 34216 USA "
    },
    {
      "Location ID": "34217",
      "Location Name": "BRADENTON BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 BRIDGE ST BRADENTON BEACH FL 34217 USA "
    },
    {
      "Location ID": "34218",
      "Location Name": "HOLMES BEACH CONT BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5354 GULF DR HOLMES BEACH FL 34217 USA "
    },
    {
      "Location ID": "34219",
      "Location Name": "PARRISH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12144 US HIGHWAY 301 N PARRISH FL 34219 USA "
    },
    {
      "Location ID": "34221",
      "Location Name": "PALMETTO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 520 7TH ST W PALMETTO FL 34221 USA "
    },
    {
      "Location ID": "34222",
      "Location Name": "ELLENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2605 72ND AVE E ELLENTON FL 34222 USA "
    },
    {
      "Location ID": "34223",
      "Location Name": "ENGLEWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 950 S RIVER RD ENGLEWOOD FL 34223 USA "
    },
    {
      "Location ID": "34228",
      "Location Name": "LONGBOAT KEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 560 BAY ISLES RD LONGBOAT KEY FL 34228 USA "
    },
    {
      "Location ID": "34229",
      "Location Name": "OSPREY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 333 S TAMIAMI TRL OSPREY FL 34229 USA "
    },
    {
      "Location ID": "34230",
      "Location Name": "SARASOTA MAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1661 RINGLING BLVD SARASOTA FL 34230 USA "
    },
    {
      "Location ID": "34231",
      "Location Name": "GULF GATE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2875 ASHTON RD SARASOTA FL 34231 USA "
    },
    {
      "Location ID": "34232",
      "Location Name": "GLENGARRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 935 N BENEVA RD STE 801 SARASOTA FL 34232 USA "
    },
    {
      "Location ID": "34238",
      "Location Name": "SARASOTA CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6552 PALMER PARK CIR SARASOTA FL 34238 USA "
    },
    {
      "Location ID": "34239",
      "Location Name": "SOUTHGATE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2055 SIESTA DR SARASOTA FL 34239 USA "
    },
    {
      "Location ID": "34243",
      "Location Name": "ONECO ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1619 E 53RD AVE SARASOTA FL 34243 USA "
    },
    {
      "Location ID": "34250",
      "Location Name": "TERRA CEIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1891 CENTER RD TERRA CEIA FL 34250 USA "
    },
    {
      "Location ID": "34251",
      "Location Name": "MYAKKA CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10490 CARLTON AVE MYAKKA CITY FL 34251 USA "
    },
    {
      "Location ID": "34264",
      "Location Name": "ONECO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2333 53RD AVE E ONECO FL 34264 USA "
    },
    {
      "Location ID": "34266",
      "Location Name": "ARCADIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 N POLK AVE ARCADIA FL 34266 USA "
    },
    {
      "Location ID": "34267",
      "Location Name": "FORT OGDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9693 SW HIGHWAY 17 FORT OGDEN FL 34267 USA "
    },
    {
      "Location ID": "34268",
      "Location Name": "NOCATEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2509 SW COUNTY ROAD 760 NOCATEE FL 34268 USA "
    },
    {
      "Location ID": "34270",
      "Location Name": "TALLEVAST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2205 TALLEVAST RD TALLEVAST FL 34270 USA "
    },
    {
      "Location ID": "34272",
      "Location Name": "LAUREL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 302 LAUREL RD E LAUREL FL 34272 USA "
    },
    {
      "Location ID": "34275",
      "Location Name": "NOKOMIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 303 LAUREL RD W NOKOMIS FL 34275 USA "
    },
    {
      "Location ID": "34284",
      "Location Name": "VENICE CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 314 SEABOARD AVE VENICE FL 34284 USA "
    },
    {
      "Location ID": "34285",
      "Location Name": "VENICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 350 W VENICE AVE VENICE FL 34285 USA "
    },
    {
      "Location ID": "34286",
      "Location Name": "NORTH PORT CITY CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4975 CITY HALL BLVD NORTH PORT FL 34286 USA "
    },
    {
      "Location ID": "34287",
      "Location Name": "NORTH PORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13611 TAMIAMI TRL NORTH PORT FL 34287 USA "
    },
    {
      "Location ID": "34293",
      "Location Name": "VENICE CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 314 SEABOARD AVE VENICE FL 34285 USA "
    },
    {
      "Location ID": "342AA",
      "Location Name": "BRADENTON CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 815 4TH AVE W BRADENTON FL 34205 USA "
    },
    {
      "Location ID": "342BU",
      "Location Name": "ACCESS USA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4285 EXPRESS LN SARASOTA FL 34238 USA "
    },
    {
      "Location ID": "342GF",
      "Location Name": "CLEETUS MCFARLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20240 STATE ROAD 64 BRADENTON FL 34212 USA "
    },
    {
      "Location ID": "342JS",
      "Location Name": "JOHNSON SMITH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4514 19TH COURT EAST BRADENTON FL 34203 USA "
    },
    {
      "Location ID": "342MY",
      "Location Name": "ACCESS USA/ MY USA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4285 EXPRESS LANE SARASOTA FL 34238 USA "
    },
    {
      "Location ID": "342RC",
      "Location Name": "RHINE CORPORATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1661 W UNIVERSITY PKWY STE A SARASOTA FL 34243 USA "
    },
    {
      "Location ID": "342SS",
      "Location Name": "STEEL SUPPLEMENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7455 16TH E UNIT 111 SARASOTA FL 34243 USA "
    },
    {
      "Location ID": "342TT",
      "Location Name": "TERVIS TUMBLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 TRIPLE DIAMOND VENICE FL 34275 USA "
    },
    {
      "Location ID": "342WM",
      "Location Name": "WALMART",
      "Time Zone Offset (Hours)": 0,
      "Address": " 163 S, TAMIAMI TRIAL OSPREY FL 34229 USA "
    },
    {
      "Location ID": "342YM",
      "Location Name": "YARD MASTERY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 LAKEWOOD RANCH BLVD STE 110 BRADENTON FL 34211 USA "
    },
    {
      "Location ID": "34420",
      "Location Name": "BELLEVIEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4800 SE 110TH ST BELLEVIEW FL 34420 USA "
    },
    {
      "Location ID": "34429",
      "Location Name": "CRYSTAL RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 NE 4TH AVE CRYSTAL RIVER FL 34429 USA "
    },
    {
      "Location ID": "34432",
      "Location Name": "DUNNELLON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11432 N WILLIAMS ST DUNNELLON FL 34432 USA "
    },
    {
      "Location ID": "34436",
      "Location Name": "FLORAL CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7667 S FLORIDA AVE FLORAL CITY FL 34436 USA "
    },
    {
      "Location ID": "34442",
      "Location Name": "HERNANDO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3795 E PARSONS POINT RD HERNANDO FL 34442 USA "
    },
    {
      "Location ID": "34445",
      "Location Name": "HOLDER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6906 N FLORIDA AVE HOLDER FL 34445 USA "
    },
    {
      "Location ID": "34446",
      "Location Name": "HOMOSASSA SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4610 S SUNCOAST BLVD HOMOSASSA FL 34446 USA "
    },
    {
      "Location ID": "34449",
      "Location Name": "INGLIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 70 N INGLIS AVE INGLIS FL 34449 USA "
    },
    {
      "Location ID": "34450",
      "Location Name": "INVERNESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1400 HIGHWAY 41 N INVERNESS FL 34450 USA "
    },
    {
      "Location ID": "34461",
      "Location Name": "LECANTO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 320 S LECANTO HWY LECANTO FL 34461 USA "
    },
    {
      "Location ID": "34465",
      "Location Name": "BEVERLY HILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3971 N LECANTO HWY BEVERLY HILLS FL 34465 USA "
    },
    {
      "Location ID": "34472",
      "Location Name": "MARICAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6530 SE MARICAMP RD OCALA FL 34472 USA "
    },
    {
      "Location ID": "34474",
      "Location Name": "PADDOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4545 SW 60TH AVE OCALA FL 34474 USA "
    },
    {
      "Location ID": "34477",
      "Location Name": "PADDOCK BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4545 SW 60 AVE OCALA FL 34477 USA "
    },
    {
      "Location ID": "34478",
      "Location Name": "OCALA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 SW 1ST AVE OCALA FL 34478 USA "
    },
    {
      "Location ID": "34484",
      "Location Name": "OXFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3974 COUNTY ROAD 201 OXFORD FL 34484 USA "
    },
    {
      "Location ID": "34487",
      "Location Name": "HOMOSASSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10780 W YULEE DR HOMOSASSA FL 34487 USA "
    },
    {
      "Location ID": "34488",
      "Location Name": "SILVER SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3050 NE 55TH AVE SILVER SPRINGS FL 34488 USA "
    },
    {
      "Location ID": "34491",
      "Location Name": "SUMMERFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14560 S US HIGHWAY 301 SUMMERFIELD FL 34491 USA "
    },
    {
      "Location ID": "34498",
      "Location Name": "YANKEETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 56TH ST YANKEETOWN FL 34498 USA "
    },
    {
      "Location ID": "344AZ",
      "Location Name": "AMAZON OCALA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3400 NW 35TH STREET RD OCALA FL 34475 USA "
    },
    {
      "Location ID": "344BC",
      "Location Name": "BEST CONNECTIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16430 S HIGHWAY 475 SUMMERFIELD FL 34491 USA "
    },
    {
      "Location ID": "344CS",
      "Location Name": "CPU CARDSMART",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8449 SW HIGHWAY 200 STE 135 OCALA FL 34481 USA "
    },
    {
      "Location ID": "34601",
      "Location Name": "BROOKSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19101 CORTEZ BLVD BROOKSVILLE FL 34601 USA "
    },
    {
      "Location ID": "34603",
      "Location Name": "BROOKSVILLE (MAIN OFFICE)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19101 CORTEZ BLVD BROOKSVILLE FL 34601 USA "
    },
    {
      "Location ID": "34606",
      "Location Name": "SPRING HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8501 PHILATELIC DR SPRING HILL FL 34606 USA "
    },
    {
      "Location ID": "34609",
      "Location Name": "AVIATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16150 AVIATION LOOP DR BROOKSVILLE FL 34604 USA "
    },
    {
      "Location ID": "34636",
      "Location Name": "ISTACHATTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28199 MAGNON DR ISTACHATTA FL 34636 USA "
    },
    {
      "Location ID": "34639",
      "Location Name": "LAND O LAKES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5230 LAND O LAKES BLVD LAND O LAKES FL 34639 USA "
    },
    {
      "Location ID": "34653",
      "Location Name": "NEW PORT RICHEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6550 MAIN ST NEW PORT RICHEY FL 34653 USA "
    },
    {
      "Location ID": "34654",
      "Location Name": "TRINITY ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4022 LITTLE ROAD NEW PORT RICHEY FL 34654 USA "
    },
    {
      "Location ID": "34655",
      "Location Name": "TRINITY CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4022 LITTLE RD NEW PORT RICHEY FL 34655 USA "
    },
    {
      "Location ID": "34660",
      "Location Name": "OZONA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 310 ORANGE ST OZONA FL 34660 USA "
    },
    {
      "Location ID": "34661",
      "Location Name": "NOBLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29095 FORBES ST NOBLETON FL 34661 USA "
    },
    {
      "Location ID": "34667",
      "Location Name": "HUDSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13610 BIG BEND DR HUDSON FL 34667 USA "
    },
    {
      "Location ID": "34668",
      "Location Name": "PORT RICHEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8101 WASHINGTON ST PORT RICHEY FL 34668 USA "
    },
    {
      "Location ID": "34677",
      "Location Name": "OLDSMAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3905 TAMPA RD OLDSMAR FL 34677 USA "
    },
    {
      "Location ID": "34679",
      "Location Name": "ARIPEKA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18925 ARIPEKA RD ARIPEKA FL 34679 USA "
    },
    {
      "Location ID": "34680",
      "Location Name": "ELFERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4122 MADISON ST ELFERS FL 34680 USA "
    },
    {
      "Location ID": "34681",
      "Location Name": "CRYSTAL BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 420 CRYSTAL BEACH AVE CRYSTAL BEACH FL 34681 USA "
    },
    {
      "Location ID": "34683",
      "Location Name": "PALM HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 495 ALT 19 PALM HARBOR FL 34683 USA "
    },
    {
      "Location ID": "34689",
      "Location Name": "TARPON SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 850 E LIME ST TARPON SPRINGS FL 34689 USA "
    },
    {
      "Location ID": "34690",
      "Location Name": "HOLIDAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4737 MILE STRETCH DR HOLIDAY FL 34690 USA "
    },
    {
      "Location ID": "34695",
      "Location Name": "SAFETY HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1703 N MCMULLEN BOOTH RD SAFETY HARBOR FL 34695 USA "
    },
    {
      "Location ID": "34698",
      "Location Name": "DUNEDIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1350 COUNTY ROAD 1 DUNEDIN FL 34698 USA "
    },
    {
      "Location ID": "346BD",
      "Location Name": "DOWNTOWN BROOKSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 207 E FORT DADE AVE BROOKSVILLE FL 34601 USA "
    },
    {
      "Location ID": "346EC",
      "Location Name": "EMBASSY CROSSINGS BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9602 US HWY 19 PORT RICHEY FL 34668 USA "
    },
    {
      "Location ID": "346LG",
      "Location Name": "LEMONGRASS PRODUCTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 720 ANCLOTE RD TARPON SPGS FL 34689 USA "
    },
    {
      "Location ID": "346PB",
      "Location Name": "PLAZA BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7341 SPRING HILL DR SPRING HILL FL 34606 USA "
    },
    {
      "Location ID": "346SD",
      "Location Name": "SHOP THE DOCKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 140 OSCAR HILL RD TARPON SPGS FL 34689 USA "
    },
    {
      "Location ID": "346TA",
      "Location Name": "LAND O LAKES CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5875 EHREN CUTOFF LAND O LAKES FL 34639 USA "
    },
    {
      "Location ID": "346TE",
      "Location Name": "TEW INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13464 CHAMBORD ST BROOKSVILLE FL 34613 USA "
    },
    {
      "Location ID": "34705",
      "Location Name": "ASTATULA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25711 MONROE ST ASTATULA FL 34705 USA "
    },
    {
      "Location ID": "34711",
      "Location Name": "CLERMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 877 W MINNEOLA AVE CLERMONT FL 34711 USA "
    },
    {
      "Location ID": "34713",
      "Location Name": "SOUTH CLERMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1100 US HIGHWAY 27 STE F CLERMONT FL 34714 USA "
    },
    {
      "Location ID": "34731",
      "Location Name": "FRUITLAND PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 W MILLER ST FRUITLAND PARK FL 34731 USA "
    },
    {
      "Location ID": "34734",
      "Location Name": "GOTHA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9907 8TH ST GOTHA FL 34734 USA "
    },
    {
      "Location ID": "34736",
      "Location Name": "GROVELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 304 W BROAD ST GROVELAND FL 34736 USA "
    },
    {
      "Location ID": "34737",
      "Location Name": "HOWEY IN THE HILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 S FLORIDA AVE HOWEY IN THE HILLS FL 34737 USA "
    },
    {
      "Location ID": "34744",
      "Location Name": "KISSIMMEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2600 MICHIGAN AVE KISSIMMEE FL 34744 USA "
    },
    {
      "Location ID": "34746",
      "Location Name": "KISSIMMEE CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3050 POLYNESIAN ISLE BLVD KISSIMMEE FL 34746 USA "
    },
    {
      "Location ID": "34748",
      "Location Name": "LEESBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1201 S 14TH ST LEESBURG FL 34748 USA "
    },
    {
      "Location ID": "34753",
      "Location Name": "MASCOTTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 411 MIDWAY AVE MASCOTTE FL 34753 USA "
    },
    {
      "Location ID": "34755",
      "Location Name": "MINNEOLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 131 W WASHINGTON ST MINNEOLA FL 34755 USA "
    },
    {
      "Location ID": "34756",
      "Location Name": "MONTVERDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17101 PORTER AVE MONTVERDE FL 34756 USA "
    },
    {
      "Location ID": "34760",
      "Location Name": "OAKLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 N TUBB ST OAKLAND FL 34760 USA "
    },
    {
      "Location ID": "34761",
      "Location Name": "OCOEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 449 W SILVER STAR RD OCOEE FL 34761 USA "
    },
    {
      "Location ID": "34762",
      "Location Name": "OKAHUMPKA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3441 COUNTY ROAD 470 OKAHUMPKA FL 34762 USA "
    },
    {
      "Location ID": "34769",
      "Location Name": "SAINT CLOUD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4701 OLD CANOE CREEK RD SAINT CLOUD FL 34769 USA "
    },
    {
      "Location ID": "34777",
      "Location Name": "WINTER GARDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15155 W COLONIAL DR WINTER GARDEN FL 34787 USA "
    },
    {
      "Location ID": "34785",
      "Location Name": "WILDWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 316 SHOPPING CENTER DR WILDWOOD FL 34785 USA "
    },
    {
      "Location ID": "34786",
      "Location Name": "WINDERMERE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9300 CONROY WINDERMERE RD WINDERMERE FL 34786 USA "
    },
    {
      "Location ID": "34787",
      "Location Name": "DOWNTOWN WINTER GARDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 207 W PLANT ST WINTER GARDEN FL 34787 USA "
    },
    {
      "Location ID": "34788",
      "Location Name": "HAINES CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 32327 COUNTY ROAD 473 LEESBURG FL 34788 USA "
    },
    {
      "Location ID": "34797",
      "Location Name": "YALAHA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8712 COUNTY ROAD 48 YALAHA FL 34797 USA "
    },
    {
      "Location ID": "347AX",
      "Location Name": "OCOEE CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 425 OCOEE APOPKA RD OCOEE FL 34761 USA "
    },
    {
      "Location ID": "347CA",
      "Location Name": "CLERMONT CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 CITRUS TOWER BLVD CLERMONT FL 34711 USA "
    },
    {
      "Location ID": "347PO",
      "Location Name": "POINCIANA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4774 SAN REMO RD KISSIMMEE FL 34758 USA "
    },
    {
      "Location ID": "34952",
      "Location Name": "PORT SAINT LUCIE MIDPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2255 SE VETERANS MEMORIAL PKWY PORT SAINT LUCIE FL 34952 USA "
    },
    {
      "Location ID": "34956",
      "Location Name": "INDIANTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15300 E THELMA WATERS AVE INDIANTOWN FL 34956 USA "
    },
    {
      "Location ID": "34957",
      "Location Name": "JENSEN BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2301 NE SAVANNAH RD JENSEN BEACH FL 34957 USA "
    },
    {
      "Location ID": "34972",
      "Location Name": "OKEECHOBEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 STATE ROAD 70 E OKEECHOBEE FL 34972 USA "
    },
    {
      "Location ID": "34981",
      "Location Name": "FORT PIERCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5000 W MIDWAY RD FORT PIERCE FL 34981 USA "
    },
    {
      "Location ID": "34986",
      "Location Name": "PORT SAINT LUCIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 290 NW PEACOCK BLVD PORT SAINT LUCIE FL 34986 USA "
    },
    {
      "Location ID": "34990",
      "Location Name": "PALM CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1257 SW MARTIN HWY PALM CITY FL 34990 USA "
    },
    {
      "Location ID": "34994",
      "Location Name": "STUART",
      "Time Zone Offset (Hours)": 0,
      "Address": " 801 SE JOHNSON AVE STUART FL 34994 USA "
    },
    {
      "Location ID": "34997",
      "Location Name": "STUART ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4411 SE FEDERAL HWY STUART FL 34997 USA "
    },
    {
      "Location ID": "349PC",
      "Location Name": "PALM CITY ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3505 SW CORPORATE PKWY PALM CITY FL 34990 USA "
    },
    {
      "Location ID": "350",
      "Location Name": "LOG BIRMINGHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 351 24TH ST N RM 210 BIRMINGHAM AL 35203 USA "
    },
    {
      "Location ID": "35004",
      "Location Name": "MOODY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2455 MOODY PKWY MOODY AL 35004 USA "
    },
    {
      "Location ID": "35005",
      "Location Name": "ADAMSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3609 GRAY AVE ADAMSVILLE AL 35005 USA "
    },
    {
      "Location ID": "35006",
      "Location Name": "ADGER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7313 JOHNS RD ADGER AL 35006 USA "
    },
    {
      "Location ID": "35007",
      "Location Name": "ALABASTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 50 KENT STONE WAY ALABASTER AL 35007 USA "
    },
    {
      "Location ID": "35010",
      "Location Name": "ALEXANDER CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 233 LEE ST ALEXANDER CITY AL 35010 USA "
    },
    {
      "Location ID": "35015",
      "Location Name": "ALTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5548 JOHNSON ST ALTON AL 35015 USA "
    },
    {
      "Location ID": "35016",
      "Location Name": "ARAB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 819 N MAIN ST ARAB AL 35016 USA "
    },
    {
      "Location ID": "35019",
      "Location Name": "BAILEYTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12125 AL HIGHWAY 69 N BAILEYTON AL 35019 USA "
    },
    {
      "Location ID": "35020",
      "Location Name": "BESSEMER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1112 4TH AVE N BESSEMER AL 35020 USA "
    },
    {
      "Location ID": "35023",
      "Location Name": "HUEYTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 615 BROOKLANE DR HUEYTOWN AL 35023 USA "
    },
    {
      "Location ID": "35032",
      "Location Name": "BON AIR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 FRONT ST BON AIR AL 35032 USA "
    },
    {
      "Location ID": "35033",
      "Location Name": "BREMEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2313 COUNTY ROAD 54 BREMEN AL 35033 USA "
    },
    {
      "Location ID": "35034",
      "Location Name": "BRENT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2150 MAIN ST BRENT AL 35034 USA "
    },
    {
      "Location ID": "35035",
      "Location Name": "BRIERFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 18323 HIGHWAY 139 BRIERFIELD AL 35035 USA "
    },
    {
      "Location ID": "35040",
      "Location Name": "CALERA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8088 HIGHWAY 31 CALERA AL 35040 USA "
    },
    {
      "Location ID": "35042",
      "Location Name": "CENTREVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 155 BIRMINGHAM RD STE 100 CENTREVILLE AL 35042 USA "
    },
    {
      "Location ID": "35043",
      "Location Name": "CHELSEA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16738 HIGHWAY 280 CHELSEA AL 35043 USA "
    },
    {
      "Location ID": "35044",
      "Location Name": "CHILDERSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 9TH AVE SW CHILDERSBURG AL 35044 USA "
    },
    {
      "Location ID": "35045",
      "Location Name": "CLANTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 608 6TH ST S CLANTON AL 35045 USA "
    },
    {
      "Location ID": "35051",
      "Location Name": "COLUMBIANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 W COLLEGE ST COLUMBIANA AL 35051 USA "
    },
    {
      "Location ID": "35053",
      "Location Name": "CRANE HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15051 COUNTY ROAD 222 CRANE HILL AL 35053 USA "
    },
    {
      "Location ID": "35055",
      "Location Name": "CULLMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 MAIN AVE SW CULLMAN AL 35055 USA "
    },
    {
      "Location ID": "35060",
      "Location Name": "DOCENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 CIRCLE DR DOCENA AL 35060 USA "
    },
    {
      "Location ID": "35062",
      "Location Name": "DORA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 975 SHARON BLVD DORA AL 35062 USA "
    },
    {
      "Location ID": "35063",
      "Location Name": "EMPIRE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5323 EMPIRE RD EMPIRE AL 35063 USA "
    },
    {
      "Location ID": "35068",
      "Location Name": "FULTONDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1515 STOUTS RD FULTONDALE AL 35068 USA "
    },
    {
      "Location ID": "35070",
      "Location Name": "GARDEN CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 311 1ST AVE S GARDEN CITY AL 35070 USA "
    },
    {
      "Location ID": "35071",
      "Location Name": "GARDENDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1753 DECATUR HWY GARDENDALE AL 35071 USA "
    },
    {
      "Location ID": "35072",
      "Location Name": "GOODWATER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 64 COOSA COUNTY ROAD 66 GOODWATER AL 35072 USA "
    },
    {
      "Location ID": "35073",
      "Location Name": "GRAYSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 2ND AVE SW GRAYSVILLE AL 35073 USA "
    },
    {
      "Location ID": "35074",
      "Location Name": "GREEN POND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20607 EASTERN VALLEY RD GREEN POND AL 35074 USA "
    },
    {
      "Location ID": "35077",
      "Location Name": "HANCEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 630 MAIN ST NE HANCEVILLE AL 35077 USA "
    },
    {
      "Location ID": "35078",
      "Location Name": "HARPERSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 41 WHEAT ST HARPERSVILLE AL 35078 USA "
    },
    {
      "Location ID": "35079",
      "Location Name": "HAYDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4425 STATE HIGHWAY 160 HAYDEN AL 35079 USA "
    },
    {
      "Location ID": "35080",
      "Location Name": "HELENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 800 HIGHWAY 52 HELENA AL 35080 USA "
    },
    {
      "Location ID": "35083",
      "Location Name": "HOLLY POND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 77 BROOKLYN RD HOLLY POND AL 35083 USA "
    },
    {
      "Location ID": "35085",
      "Location Name": "JEMISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24760 US HIGHWAY 31 JEMISON AL 35085 USA "
    },
    {
      "Location ID": "35087",
      "Location Name": "JOPPA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15960 AL HIGHWAY 69 N JOPPA AL 35087 USA "
    },
    {
      "Location ID": "35089",
      "Location Name": "KELLYTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 338 FIREHOUSE RD KELLYTON AL 35089 USA "
    },
    {
      "Location ID": "35091",
      "Location Name": "KIMBERLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9261 STOUTS RD KIMBERLY AL 35091 USA "
    },
    {
      "Location ID": "35098",
      "Location Name": "LOGAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4475 COUNTY ROAD 831 LOGAN AL 35098 USA "
    },
    {
      "Location ID": "350AC",
      "Location Name": "AMERICAN CLASSICS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 160 COUNTY ROAD 565 HANCEVILLE AL 35077 USA "
    },
    {
      "Location ID": "350AX",
      "Location Name": "LOG BIRMINGHAM ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4500 1ST AVE S BIRMINGHAM AL 35222 USA "
    },
    {
      "Location ID": "350CA",
      "Location Name": "BIRMINGHAM CHRISTMAS ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3501 1ST AVE N BIRMINGHAM AL 35222 USA "
    },
    {
      "Location ID": "350FZ",
      "Location Name": "LOG BIRMINGHAM AL PSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3501 1ST AVE N BIRMINGHAM AL 35222 USA "
    },
    {
      "Location ID": "350HS",
      "Location Name": "HIBBETT SPORTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 CORPORATE WOODS LN ALABASTER AL 35007 USA "
    },
    {
      "Location ID": "35111",
      "Location Name": "MC CALLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6301 OLD TUSCALOOSA HWY MC CALLA AL 35111 USA "
    },
    {
      "Location ID": "35112",
      "Location Name": "MARGARET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 25 POST OFFICE DR MARGARET AL 35112 USA "
    },
    {
      "Location ID": "35114",
      "Location Name": "MAYLENE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10011 HIGHWAY 17 ALABASTER AL 35114 USA "
    },
    {
      "Location ID": "35115",
      "Location Name": "MONTEVALLO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 720 VINE ST MONTEVALLO AL 35115 USA "
    },
    {
      "Location ID": "35116",
      "Location Name": "MORRIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8315 OLD HIGHWAY 31 MORRIS AL 35116 USA "
    },
    {
      "Location ID": "35118",
      "Location Name": "MULGA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 1ST AVE MULGA AL 35118 USA "
    },
    {
      "Location ID": "35119",
      "Location Name": "NEW CASTLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4768 MULBERRY DR NEW CASTLE AL 35119 USA "
    },
    {
      "Location ID": "35120",
      "Location Name": "ODENVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 17 BURGESS DR ODENVILLE AL 35120 USA "
    },
    {
      "Location ID": "35124",
      "Location Name": "PELHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2960 PELHAM PKWY PELHAM AL 35124 USA "
    },
    {
      "Location ID": "35130",
      "Location Name": "QUINTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7515 QUINTON RD QUINTON AL 35130 USA "
    },
    {
      "Location ID": "35131",
      "Location Name": "RAGLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 81 KATHRYN ST RAGLAND AL 35131 USA "
    },
    {
      "Location ID": "35136",
      "Location Name": "ROCKFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 329 JACKSON ST ROCKFORD AL 35136 USA "
    },
    {
      "Location ID": "35137",
      "Location Name": "SAGINAW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 858 HIGHWAY 31 SAGINAW AL 35137 USA "
    },
    {
      "Location ID": "35142",
      "Location Name": "SHANNON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2101 SHANNON OXMOOR RD SHANNON AL 35142 USA "
    },
    {
      "Location ID": "35143",
      "Location Name": "SHELBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3788 HIGHWAY 47 SHELBY AL 35143 USA "
    },
    {
      "Location ID": "35146",
      "Location Name": "SPRINGVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5926 US HIGHWAY 11 SPRINGVILLE AL 35146 USA "
    },
    {
      "Location ID": "35147",
      "Location Name": "STERRETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 49930 HIGHWAY 25 STERRETT AL 35147 USA "
    },
    {
      "Location ID": "35148",
      "Location Name": "SUMITON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1675 MAIN ST SUMITON AL 35148 USA "
    },
    {
      "Location ID": "35150",
      "Location Name": "SYLACAUGA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 S BROADWAY AVE SYLACAUGA AL 35150 USA "
    },
    {
      "Location ID": "35171",
      "Location Name": "THORSBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20650 US HIGHWAY 31 THORSBY AL 35171 USA "
    },
    {
      "Location ID": "35173",
      "Location Name": "TRUSSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 810 WATTERSON CURV TRUSSVILLE AL 35173 USA "
    },
    {
      "Location ID": "35175",
      "Location Name": "UNION GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3935 UNION GROVE RD UNION GROVE AL 35175 USA "
    },
    {
      "Location ID": "35176",
      "Location Name": "VANDIVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 52782 HIGHWAY 25 VANDIVER AL 35176 USA "
    },
    {
      "Location ID": "35178",
      "Location Name": "VINCENT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 42840 HIGHWAY 25 VINCENT AL 35178 USA "
    },
    {
      "Location ID": "35179",
      "Location Name": "VINEMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 18181 US HIGHWAY 31 VINEMONT AL 35179 USA "
    },
    {
      "Location ID": "35180",
      "Location Name": "WARRIOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 420 HIGHWAY 31 S WARRIOR AL 35180 USA "
    },
    {
      "Location ID": "35181",
      "Location Name": "WATSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4016 POWDER MILL RD WATSON AL 35181 USA "
    },
    {
      "Location ID": "35182",
      "Location Name": "WATTSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 50 OAKWOOD LN WATTSVILLE AL 35182 USA "
    },
    {
      "Location ID": "35183",
      "Location Name": "WEOGUFKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20312 COOSA COUNTY ROAD 29 WEOGUFKA AL 35183 USA "
    },
    {
      "Location ID": "35184",
      "Location Name": "WEST BLOCTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 FLORIDA ST WEST BLOCTON AL 35184 USA "
    },
    {
      "Location ID": "35185",
      "Location Name": "WESTOVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9779 HIGHWAY 55 WESTOVER AL 35185 USA "
    },
    {
      "Location ID": "35186",
      "Location Name": "WILSONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 31111 HIGHWAY 25 WILSONVILLE AL 35186 USA "
    },
    {
      "Location ID": "35187",
      "Location Name": "WILTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2324 HIGHWAY 25 WILTON AL 35187 USA "
    },
    {
      "Location ID": "35188",
      "Location Name": "WOODSTOCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 28770 HIGHWAY 5 WOODSTOCK AL 35188 USA "
    },
    {
      "Location ID": "351SS",
      "Location Name": "SYLVAN SPRINGS CPU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5607 BIRMINGPORT RD SYLVAN SPRINGS AL 35118 USA "
    },
    {
      "Location ID": "35209",
      "Location Name": "GREEN SPRINGS ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 285 SNOW DR BIRMINGHAM AL 35209 USA "
    },
    {
      "Location ID": "35222",
      "Location Name": "DOWNTOWN ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 612 37TH ST N BIRMINGHAM AL 35222 USA "
    },
    {
      "Location ID": "352ML",
      "Location Name": "MAIL SOUTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 222 ROEBUCK INDUSTRIAL PKWY BIRMINGHAM AL 35235 USA "
    },
    {
      "Location ID": "352NC",
      "Location Name": "NATIONAL COMPUTER PRINT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 920 18TH ST N BIRMINGHAM AL 35203 USA "
    },
    {
      "Location ID": "352SM",
      "Location Name": "SUNGUARD MAILING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 AUTOMATION WAY BIRMINGHAM AL 35210 USA "
    },
    {
      "Location ID": "354",
      "Location Name": "SKYLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3201 SKYLAND BLVD E TUSCALOOSA AL 35405 USA "
    },
    {
      "Location ID": "35401",
      "Location Name": "TUSCALOOSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1313 22ND AVE TUSCALOOSA AL 35401 USA "
    },
    {
      "Location ID": "35441",
      "Location Name": "AKRON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 197 1ST AVE S AKRON AL 35441 USA "
    },
    {
      "Location ID": "35442",
      "Location Name": "ALICEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 3RD AVE NE ALICEVILLE AL 35442 USA "
    },
    {
      "Location ID": "35443",
      "Location Name": "BOLIGEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 157 COUNTY ROAD 81 BOLIGEE AL 35443 USA "
    },
    {
      "Location ID": "35446",
      "Location Name": "BUHL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16512 HIGHWAY 140 BUHL AL 35446 USA "
    },
    {
      "Location ID": "35447",
      "Location Name": "CARROLLTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 56 COURT SQ CARROLLTON AL 35447 USA "
    },
    {
      "Location ID": "35452",
      "Location Name": "COKER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14585 ROMULUS RD COKER AL 35452 USA "
    },
    {
      "Location ID": "35453",
      "Location Name": "COTTONDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3101 BUTTERMILK RD COTTONDALE AL 35453 USA "
    },
    {
      "Location ID": "35456",
      "Location Name": "DUNCANVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12108 HIGHWAY 82 E DUNCANVILLE AL 35456 USA "
    },
    {
      "Location ID": "35458",
      "Location Name": "ELROD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12630 ELROD RD ELROD AL 35458 USA "
    },
    {
      "Location ID": "35460",
      "Location Name": "EPES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10230 CEDAR HILL DR EPES AL 35460 USA "
    },
    {
      "Location ID": "35461",
      "Location Name": "ETHELSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 491 MAIN ST ETHELSVILLE AL 35461 USA "
    },
    {
      "Location ID": "35462",
      "Location Name": "EUTAW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 227 PRAIRIE AVE EUTAW AL 35462 USA "
    },
    {
      "Location ID": "35463",
      "Location Name": "FOSTERS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13467 HIGHWAY 11 S FOSTERS AL 35463 USA "
    },
    {
      "Location ID": "35466",
      "Location Name": "GORDO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 842 INDUSTRIAL PARK BLVD GORDO AL 35466 USA "
    },
    {
      "Location ID": "35470",
      "Location Name": "LIVINGSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 321 WASHINGTON ST LIVINGSTON AL 35470 USA "
    },
    {
      "Location ID": "35474",
      "Location Name": "MOUNDVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 259 2ND AVE MOUNDVILLE AL 35474 USA "
    },
    {
      "Location ID": "35476",
      "Location Name": "NORTHPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4200 MCFARLAND BLVD NORTHPORT AL 35476 USA "
    },
    {
      "Location ID": "35478",
      "Location Name": "PETERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8910 OLD BIRMINGHAM HWY PETERSON AL 35478 USA "
    },
    {
      "Location ID": "35481",
      "Location Name": "REFORM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 406 1ST AVE E REFORM AL 35481 USA "
    },
    {
      "Location ID": "355",
      "Location Name": "JASPER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2101 3RD AVE S JASPER AL 35501 USA "
    },
    {
      "Location ID": "35544",
      "Location Name": "BEAVERTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 32457 COUNTY ROAD 49 BEAVERTON AL 35544 USA "
    },
    {
      "Location ID": "35548",
      "Location Name": "BRILLIANT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1260 MAIN ST BRILLIANT AL 35548 USA "
    },
    {
      "Location ID": "35549",
      "Location Name": "CARBON HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 170 3RD AVE NW CARBON HILL AL 35549 USA "
    },
    {
      "Location ID": "35552",
      "Location Name": "DETROIT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 65910 HIGHWAY 17 DETROIT AL 35552 USA "
    },
    {
      "Location ID": "35554",
      "Location Name": "ELDRIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 37855 HIGHWAY 78 ELDRIDGE AL 35554 USA "
    },
    {
      "Location ID": "35559",
      "Location Name": "GLEN ALLEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 152 HIGHWAY 129 S GLEN ALLEN AL 35559 USA "
    },
    {
      "Location ID": "35563",
      "Location Name": "GUIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7479 US HIGHWAY 43 GUIN AL 35563 USA "
    },
    {
      "Location ID": "35565",
      "Location Name": "HALEYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1212 21ST ST HALEYVILLE AL 35565 USA "
    },
    {
      "Location ID": "35570",
      "Location Name": "HAMILTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 315 BEXAR AVE E HAMILTON AL 35570 USA "
    },
    {
      "Location ID": "35573",
      "Location Name": "KANSAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 497 OLD HIGHWAY 78 KANSAS AL 35573 USA "
    },
    {
      "Location ID": "35578",
      "Location Name": "NAUVOO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 481 FOURTH ST NAUVOO AL 35578 USA "
    },
    {
      "Location ID": "35586",
      "Location Name": "SULLIGENT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 55144 HIGHWAY 17 SULLIGENT AL 35586 USA "
    },
    {
      "Location ID": "35587",
      "Location Name": "TOWNLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 82 MAIN ST TOWNLEY AL 35587 USA "
    },
    {
      "Location ID": "35592",
      "Location Name": "VERNON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 44924 HIGHWAY 17 VERNON AL 35592 USA "
    },
    {
      "Location ID": "35594",
      "Location Name": "WINFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 161 BROOK ST WINFIELD AL 35594 USA "
    },
    {
      "Location ID": "356",
      "Location Name": "DECATUR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 WELL ST NE DECATUR AL 35601 USA "
    },
    {
      "Location ID": "35610",
      "Location Name": "ANDERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7312 HIGHWAY 207 ANDERSON AL 35610 USA "
    },
    {
      "Location ID": "35611",
      "Location Name": "ATHENS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1110 W MARKET ST ATHENS AL 35611 USA "
    },
    {
      "Location ID": "35616",
      "Location Name": "CHEROKEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 MAIN ST CHEROKEE AL 35616 USA "
    },
    {
      "Location ID": "35618",
      "Location Name": "COURTLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 361 ALABAMA ST COURTLAND AL 35618 USA "
    },
    {
      "Location ID": "35620",
      "Location Name": "ELKMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 25540 LEVIE DAVIS DR ELKMONT AL 35620 USA "
    },
    {
      "Location ID": "35621",
      "Location Name": "EVA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4456 HIGHWAY 55 E EVA AL 35621 USA "
    },
    {
      "Location ID": "35630",
      "Location Name": "FLORENCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 N SEMINARY ST FLORENCE AL 35630 USA "
    },
    {
      "Location ID": "35643",
      "Location Name": "HILLSBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 564 FRONT ST HILLSBORO AL 35643 USA "
    },
    {
      "Location ID": "35645",
      "Location Name": "KILLEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1650 HIGHWAY 72 KILLEN AL 35645 USA "
    },
    {
      "Location ID": "35646",
      "Location Name": "LEIGHTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8945 MAIN ST LEIGHTON AL 35646 USA "
    },
    {
      "Location ID": "35648",
      "Location Name": "LEXINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 STEVENS AVE LEXINGTON AL 35648 USA "
    },
    {
      "Location ID": "35652",
      "Location Name": "ROGERSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15801 HIGHWAY 72 ROGERSVILLE AL 35652 USA "
    },
    {
      "Location ID": "35660",
      "Location Name": "SHEFFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 N COLUMBIA AVE SHEFFIELD AL 35660 USA "
    },
    {
      "Location ID": "35661",
      "Location Name": "MUSCLE SHOALS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2350 AVALON AVE MUSCLE SHOALS AL 35661 USA "
    },
    {
      "Location ID": "35670",
      "Location Name": "SOMERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3696 HIGHWAY 67 S SOMERVILLE AL 35670 USA "
    },
    {
      "Location ID": "35671",
      "Location Name": "TANNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20236 HUNTSVILLE BROWNSFERRY RD TANNER AL 35671 USA "
    },
    {
      "Location ID": "35672",
      "Location Name": "TOWN CREEK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2746 AL HIGHWAY 20 TOWN CREEK AL 35672 USA "
    },
    {
      "Location ID": "35673",
      "Location Name": "TRINITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1290 OLD HIGHWAY 24 TRINITY AL 35673 USA "
    },
    {
      "Location ID": "35674",
      "Location Name": "TUSCUMBIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 599 HIGHWAY 72 W TUSCUMBIA AL 35674 USA "
    },
    {
      "Location ID": "356MS",
      "Location Name": "MY SOUTHERN CHARM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3795 STATE HIGHWAY 13 HALEYVILLE AL 35565 USA "
    },
    {
      "Location ID": "357",
      "Location Name": "LOG HUNTSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3408 WALL TRIANA HWY HUNTSVILLE AL 35813 USA "
    },
    {
      "Location ID": "35740",
      "Location Name": "BRIDGEPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 50450 AL HIGHWAY 277 BRIDGEPORT AL 35740 USA "
    },
    {
      "Location ID": "35742",
      "Location Name": "CAPSHAW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1108 OLD RAILROAD BED RD CAPSHAW AL 35742 USA "
    },
    {
      "Location ID": "35744",
      "Location Name": "DUTTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4496 AL HIGHWAY 71 DUTTON AL 35744 USA "
    },
    {
      "Location ID": "35747",
      "Location Name": "GRANT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5319 MAIN ST GRANT AL 35747 USA "
    },
    {
      "Location ID": "35749",
      "Location Name": "HARVEST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9157 WALL TRIANA HWY HARVEST AL 35749 USA "
    },
    {
      "Location ID": "35750",
      "Location Name": "HAZEL GREEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14297 HIGHWAY 231 431 N HAZEL GREEN AL 35750 USA "
    },
    {
      "Location ID": "35752",
      "Location Name": "HOLLYWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 29206 US HIGHWAY 72 HOLLYWOOD AL 35752 USA "
    },
    {
      "Location ID": "35754",
      "Location Name": "LACEYS SPRING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1795 HIGHWAY 231 LACEYS SPRING AL 35754 USA "
    },
    {
      "Location ID": "35755",
      "Location Name": "LANGSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8989 COUNTY ROAD 67 LANGSTON AL 35755 USA "
    },
    {
      "Location ID": "35759",
      "Location Name": "MERIDIANVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11639 HIGHWAY 231 431 N MERIDIANVILLE AL 35759 USA "
    },
    {
      "Location ID": "35760",
      "Location Name": "NEW HOPE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5464 MAIN DR NEW HOPE AL 35760 USA "
    },
    {
      "Location ID": "35761",
      "Location Name": "NEW MARKET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5516 WINCHESTER RD NEW MARKET AL 35761 USA "
    },
    {
      "Location ID": "35762",
      "Location Name": "NORMAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 DRAKE DR NORMAL AL 35762 USA "
    },
    {
      "Location ID": "35763",
      "Location Name": "OWENS CROSS ROADS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8396 HIGHWAY 431 S OWENS CROSS ROADS AL 35763 USA "
    },
    {
      "Location ID": "35765",
      "Location Name": "PISGAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2324 COUNTY ROAD 58 PISGAH AL 35765 USA "
    },
    {
      "Location ID": "35768",
      "Location Name": "SCOTTSBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S MARKET ST SCOTTSBORO AL 35768 USA "
    },
    {
      "Location ID": "35771",
      "Location Name": "SECTION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 52 DUTTON RD SECTION AL 35771 USA "
    },
    {
      "Location ID": "35772",
      "Location Name": "STEVENSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E 2ND ST STEVENSON AL 35772 USA "
    },
    {
      "Location ID": "35773",
      "Location Name": "TONEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 136 TONEY RD TONEY AL 35773 USA "
    },
    {
      "Location ID": "35775",
      "Location Name": "VALHERMOSO SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7071 HIGHWAY 36 E VALHERMOSO SPRINGS AL 35775 USA "
    },
    {
      "Location ID": "357MB",
      "Location Name": "MILITARY'S BEST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 26509 JOHN T REID PKWY SCOTTSBORO AL 35768 USA "
    },
    {
      "Location ID": "357MR",
      "Location Name": "MAPLES RUGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1005 HEROES DR SCOTTSBORO AL 35768 USA "
    },
    {
      "Location ID": "35976",
      "Location Name": "GUNTERSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1021 BLOUNT AVE GUNTERSVILLE AL 35976 USA "
    },
    {
      "Location ID": "360",
      "Location Name": "LOG MONTGOMERY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6701 WINTON BLOUNT BLVD MONTGOMERY AL 36119 USA "
    },
    {
      "Location ID": "36010",
      "Location Name": "BRUNDIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 129 N MAIN ST BRUNDIDGE AL 36010 USA "
    },
    {
      "Location ID": "36030",
      "Location Name": "FOREST HOME",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7844 FOREST HOME RD FOREST HOME AL 36030 USA "
    },
    {
      "Location ID": "36034",
      "Location Name": "GLENWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 26 N KING ST GLENWOOD AL 36034 USA "
    },
    {
      "Location ID": "36037",
      "Location Name": "GREENVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E COMMERCE ST GREENVILLE AL 36037 USA "
    },
    {
      "Location ID": "36049",
      "Location Name": "LUVERNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 29 E 3RD ST LUVERNE AL 36049 USA "
    },
    {
      "Location ID": "36065",
      "Location Name": "PINE LEVEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 685 PINE LEVEL LOOP PINE LEVEL AL 36065 USA "
    },
    {
      "Location ID": "36071",
      "Location Name": "RUTLEDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1606 LEE ST RUTLEDGE AL 36071 USA "
    },
    {
      "Location ID": "36081",
      "Location Name": "TROY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1300 S BRUNDIDGE ST TROY AL 36081 USA "
    },
    {
      "Location ID": "360AX",
      "Location Name": "MONTGOMERY ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5445 ATLANTA HIGHWAY MONTGOMERY AL 36109 USA "
    },
    {
      "Location ID": "362",
      "Location Name": "ANNISTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1101 QUINTARD AVE ANNISTON AL 36201 USA "
    },
    {
      "Location ID": "36203",
      "Location Name": "OXFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 90 PLAZA LN OXFORD AL 36203 USA "
    },
    {
      "Location ID": "36204",
      "Location Name": "BLUE MOUNTAIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3202 MCCLELLAN BLVD ANNISTON AL 36204 USA "
    },
    {
      "Location ID": "36205",
      "Location Name": "FORT MC CLELLAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 267 TOWN CENTER DR ANNISTON AL 36205 USA "
    },
    {
      "Location ID": "36250",
      "Location Name": "ALEXANDRIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6582 US HIGHWAY 431 ALEXANDRIA AL 36250 USA "
    },
    {
      "Location ID": "36251",
      "Location Name": "ASHLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 83280 HIGHWAY 9 ASHLAND AL 36251 USA "
    },
    {
      "Location ID": "36253",
      "Location Name": "BYNUM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2045 BYNUM BLVD BYNUM AL 36253 USA "
    },
    {
      "Location ID": "36254",
      "Location Name": "CHOCCOLOCCO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 28 MILLIGAN ST CHOCCOLOCCO AL 36254 USA "
    },
    {
      "Location ID": "36255",
      "Location Name": "CRAGFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11304 CRAGFORD RD CRAGFORD AL 36255 USA "
    },
    {
      "Location ID": "36256",
      "Location Name": "DAVISTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 22960 HIGHWAY 22 E DAVISTON AL 36256 USA "
    },
    {
      "Location ID": "36257",
      "Location Name": "DE ARMANVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 661 DE ARMANVILLE RD DE ARMANVILLE AL 36257 USA "
    },
    {
      "Location ID": "36258",
      "Location Name": "DELTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 99145 HIGHWAY 9 DELTA AL 36258 USA "
    },
    {
      "Location ID": "36260",
      "Location Name": "EASTABOGA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10329 US HIGHWAY 78 W EASTABOGA AL 36260 USA "
    },
    {
      "Location ID": "36261",
      "Location Name": "EDWARDSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4024 TALLAPOOSA ST EDWARDSVILLE AL 36261 USA "
    },
    {
      "Location ID": "36262",
      "Location Name": "FRUITHURST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16205 HIGHWAY 78 FRUITHURST AL 36262 USA "
    },
    {
      "Location ID": "36263",
      "Location Name": "GRAHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 32877 HIGHWAY 48 GRAHAM AL 36263 USA "
    },
    {
      "Location ID": "36264",
      "Location Name": "HEFLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1519 ALMON ST HEFLIN AL 36264 USA "
    },
    {
      "Location ID": "36265",
      "Location Name": "JACKSONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 421 PELHAM RD N JACKSONVILLE AL 36265 USA "
    },
    {
      "Location ID": "36266",
      "Location Name": "LINEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 60035 HIGHWAY 49 LINEVILLE AL 36266 USA "
    },
    {
      "Location ID": "36268",
      "Location Name": "MUNFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 33 CEDARS RD MUNFORD AL 36268 USA "
    },
    {
      "Location ID": "36269",
      "Location Name": "MUSCADINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 37 COUNTY ROAD 267 MUSCADINE AL 36269 USA "
    },
    {
      "Location ID": "36271",
      "Location Name": "OHATCHEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7847 AL HIGHWAY 77 OHATCHEE AL 36271 USA "
    },
    {
      "Location ID": "36272",
      "Location Name": "PIEDMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 E LADIGA ST PIEDMONT AL 36272 USA "
    },
    {
      "Location ID": "36273",
      "Location Name": "RANBURNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 21371 MAIN ST RANBURNE AL 36273 USA "
    },
    {
      "Location ID": "36274",
      "Location Name": "ROANOKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3078 HIGHWAY 431 ROANOKE AL 36274 USA "
    },
    {
      "Location ID": "36275",
      "Location Name": "SPRING GARDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1325 COUNTY ROAD 29 SPRING GARDEN AL 36275 USA "
    },
    {
      "Location ID": "36276",
      "Location Name": "WADLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 MAIN ST WADLEY AL 36276 USA "
    },
    {
      "Location ID": "36277",
      "Location Name": "WEAVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 ALEXANDRIA RD WEAVER AL 36277 USA "
    },
    {
      "Location ID": "36278",
      "Location Name": "WEDOWEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 17697 HIGHWAY 431 WEDOWEE AL 36278 USA "
    },
    {
      "Location ID": "36279",
      "Location Name": "WELLINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 50 WELLINGTON DR WELLINGTON AL 36279 USA "
    },
    {
      "Location ID": "36280",
      "Location Name": "WOODLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 148 WOODLAND DR WOODLAND AL 36280 USA "
    },
    {
      "Location ID": "36302",
      "Location Name": "DOTHAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 379 N OATES ST DOTHAN AL 36302 USA "
    },
    {
      "Location ID": "36303",
      "Location Name": "NORTHSIDE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3741 ROSS CLARK CIR DOTHAN AL 36303 USA "
    },
    {
      "Location ID": "36310",
      "Location Name": "ABBEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 E WASHINGTON ST ABBEVILLE AL 36310 USA "
    },
    {
      "Location ID": "36311",
      "Location Name": "ARITON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 52 PEA RIVER RD ARITON AL 36311 USA "
    },
    {
      "Location ID": "36312",
      "Location Name": "ASHFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 603 N BROADWAY ST ASHFORD AL 36312 USA "
    },
    {
      "Location ID": "36313",
      "Location Name": "BELLWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4940 COUNTY ROAD 45 BELLWOOD AL 36313 USA "
    },
    {
      "Location ID": "36314",
      "Location Name": "BLACK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 128 N MAIN ST BLACK AL 36314 USA "
    },
    {
      "Location ID": "36316",
      "Location Name": "CHANCELLOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10701 N STATE HIGHWAY 27 CHANCELLOR AL 36316 USA "
    },
    {
      "Location ID": "36318",
      "Location Name": "COFFEE SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 147 N MAIN ST COFFEE SPRINGS AL 36318 USA "
    },
    {
      "Location ID": "36319",
      "Location Name": "COLUMBIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 N MAIN ST COLUMBIA AL 36319 USA "
    },
    {
      "Location ID": "36320",
      "Location Name": "COTTONWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 53 JOE COOK ST COTTONWOOD AL 36320 USA "
    },
    {
      "Location ID": "36321",
      "Location Name": "COWARTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2935 JORDON AVE COWARTS AL 36321 USA "
    },
    {
      "Location ID": "36322",
      "Location Name": "DALEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 VIRGINIA AVE DALEVILLE AL 36322 USA "
    },
    {
      "Location ID": "36323",
      "Location Name": "ELBA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 469 CLAXTON AVE N ELBA AL 36323 USA "
    },
    {
      "Location ID": "36330",
      "Location Name": "ENTERPRISE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 616 GLOVER AVE ENTERPRISE AL 36330 USA "
    },
    {
      "Location ID": "36340",
      "Location Name": "GENEVA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 800 E CHURCH AVE GENEVA AL 36340 USA "
    },
    {
      "Location ID": "36343",
      "Location Name": "GORDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1950 N COUNTY ROAD 95 GORDON AL 36343 USA "
    },
    {
      "Location ID": "36344",
      "Location Name": "HARTFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W MAIN ST HARTFORD AL 36344 USA "
    },
    {
      "Location ID": "36345",
      "Location Name": "HEADLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 711 MITCHELL ST HEADLAND AL 36345 USA "
    },
    {
      "Location ID": "36346",
      "Location Name": "JACK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 29950 HIGHWAY 87 JACK AL 36346 USA "
    },
    {
      "Location ID": "36350",
      "Location Name": "MIDLAND CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 167 KELLY AVE MIDLAND CITY AL 36350 USA "
    },
    {
      "Location ID": "36351",
      "Location Name": "NEW BROCKTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 311 S JOHN ST NEW BROCKTON AL 36351 USA "
    },
    {
      "Location ID": "36352",
      "Location Name": "NEWTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 715 S COLLEGE ST NEWTON AL 36352 USA "
    },
    {
      "Location ID": "36353",
      "Location Name": "NEWVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 182 N BROAD ST NEWVILLE AL 36353 USA "
    },
    {
      "Location ID": "36360",
      "Location Name": "OZARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 537 ANDREWS AVE OZARK AL 36360 USA "
    },
    {
      "Location ID": "36362",
      "Location Name": "FORT RUCKER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9000 RUF AVE FORT RUCKER AL 36362 USA "
    },
    {
      "Location ID": "36370",
      "Location Name": "PANSEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14021 E US 84 PANSEY AL 36370 USA "
    },
    {
      "Location ID": "36371",
      "Location Name": "PINCKARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1313 HIGHWAY 134 PINCKARD AL 36371 USA "
    },
    {
      "Location ID": "36373",
      "Location Name": "SHORTERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 315 COUNTY ROAD 65 SHORTERVILLE AL 36373 USA "
    },
    {
      "Location ID": "36374",
      "Location Name": "SKIPPERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5093 HIGHWAY 105 SKIPPERVILLE AL 36374 USA "
    },
    {
      "Location ID": "36375",
      "Location Name": "SLOCOMB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 957 W LAWRENCE HARRIS HWY SLOCOMB AL 36375 USA "
    },
    {
      "Location ID": "36376",
      "Location Name": "WEBB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 175 KIRKLAND ST WEBB AL 36376 USA "
    },
    {
      "Location ID": "363GR",
      "Location Name": "247 GRILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1910 HARTFORD HWY STE 2 DOTHAN AL 36301 USA "
    },
    {
      "Location ID": "363RG",
      "Location Name": "RGS ONLINE AUTO FILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7367 FORTNER ST DOTHAN AL 36305 USA "
    },
    {
      "Location ID": "36420",
      "Location Name": "ANDALUSIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 520 E THREE NOTCH ST ANDALUSIA AL 36420 USA "
    },
    {
      "Location ID": "36425",
      "Location Name": "BEATRICE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 291 MOORE ST BEATRICE AL 36425 USA "
    },
    {
      "Location ID": "36435",
      "Location Name": "COY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5092 J M BROOKS RD COY AL 36435 USA "
    },
    {
      "Location ID": "36439",
      "Location Name": "EXCEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10 MAIN ST EXCEL AL 36439 USA "
    },
    {
      "Location ID": "36445",
      "Location Name": "FRISCO CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 27 CHURCH ST FRISCO CITY AL 36445 USA "
    },
    {
      "Location ID": "36456",
      "Location Name": "MC KENZIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 83 N GARLAND RD MC KENZIE AL 36456 USA "
    },
    {
      "Location ID": "36460",
      "Location Name": "MONROEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16 N ALABAMA AVE MONROEVILLE AL 36460 USA "
    },
    {
      "Location ID": "36471",
      "Location Name": "PETERMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 885 OLD PETERMAN HWY PETERMAN AL 36471 USA "
    },
    {
      "Location ID": "36474",
      "Location Name": "RED LEVEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 28906 SMILEY ST RED LEVEL AL 36474 USA "
    },
    {
      "Location ID": "36476",
      "Location Name": "RIVER FALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24805 COVINGTON ST RIVER FALLS AL 36476 USA "
    },
    {
      "Location ID": "36480",
      "Location Name": "URIAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 77 CEMETERY ST URIAH AL 36480 USA "
    },
    {
      "Location ID": "36481",
      "Location Name": "VREDENBURGH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 34 CHURCH ST VREDENBURGH AL 36481 USA "
    },
    {
      "Location ID": "364PS",
      "Location Name": "PAC-N-SHIP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 406 RIVER FALLS ST ANDALUSIA AL 36420 USA "
    },
    {
      "Location ID": "365",
      "Location Name": "LOG MOBILE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4538 SHIPYARD RD MOBILE AL 36675 USA "
    },
    {
      "Location ID": "36502",
      "Location Name": "ATMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 N MAIN ST ATMORE AL 36502 USA "
    },
    {
      "Location ID": "36505",
      "Location Name": "AXIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11436 OLD HIGHWAY 43 AXIS AL 36505 USA "
    },
    {
      "Location ID": "36507",
      "Location Name": "BAY MINETTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 601 MCMEANS AVE BAY MINETTE AL 36507 USA "
    },
    {
      "Location ID": "36509",
      "Location Name": "BAYOU LA BATRE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8761 W RABBY ST BAYOU LA BATRE AL 36509 USA "
    },
    {
      "Location ID": "36511",
      "Location Name": "BON SECOUR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6419 BON SECOUR HWY BON SECOUR AL 36511 USA "
    },
    {
      "Location ID": "36512",
      "Location Name": "BUCKS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15260 HIGHWAY 43 BUCKS AL 36512 USA "
    },
    {
      "Location ID": "36513",
      "Location Name": "CALVERT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 170 HIGHWAY 43 N CALVERT AL 36513 USA "
    },
    {
      "Location ID": "36518",
      "Location Name": "CHATOM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13486 SAINT STEPHENS AVE CHATOM AL 36518 USA "
    },
    {
      "Location ID": "36521",
      "Location Name": "CHUNCHULA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11105 ROBERTS RD CHUNCHULA AL 36521 USA "
    },
    {
      "Location ID": "36522",
      "Location Name": "CITRONELLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8165 FORREST ST CITRONELLE AL 36522 USA "
    },
    {
      "Location ID": "36523",
      "Location Name": "CODEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7970 HIGHWAY 188 CODEN AL 36523 USA "
    },
    {
      "Location ID": "36524",
      "Location Name": "COFFEEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 57 RIVER ST E COFFEEVILLE AL 36524 USA "
    },
    {
      "Location ID": "36525",
      "Location Name": "CREOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9617 OLD HIGHWAY 43 CREOLA AL 36525 USA "
    },
    {
      "Location ID": "36526",
      "Location Name": "DAPHNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 808 DAPHNE AVE DAPHNE AL 36526 USA "
    },
    {
      "Location ID": "36527",
      "Location Name": "SPANISH FORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6450 US HIGHWAY 90 STE A DAPHNE AL 36527 USA "
    },
    {
      "Location ID": "36528",
      "Location Name": "DAUPHIN ISLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 423 LA VENTE ST DAUPHIN ISLAND AL 36528 USA "
    },
    {
      "Location ID": "36529",
      "Location Name": "DEER PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 342 DEER PARK BURBANK RD DEER PARK AL 36529 USA "
    },
    {
      "Location ID": "36530",
      "Location Name": "ELBERTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24750 STATE ST ELBERTA AL 36530 USA "
    },
    {
      "Location ID": "36532",
      "Location Name": "FAIRHOPE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 509 FAIRHOPE AVE FAIRHOPE AL 36532 USA "
    },
    {
      "Location ID": "36535",
      "Location Name": "FOLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 E LAUREL AVE FOLEY AL 36535 USA "
    },
    {
      "Location ID": "36539",
      "Location Name": "FRUITDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 48 GREEN ST FRUITDALE AL 36539 USA "
    },
    {
      "Location ID": "36541",
      "Location Name": "GRAND BAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12580 US HIGHWAY 90 GRAND BAY AL 36541 USA "
    },
    {
      "Location ID": "36542",
      "Location Name": "GULF SHORES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2149 W 1ST ST GULF SHORES AL 36542 USA "
    },
    {
      "Location ID": "36544",
      "Location Name": "IRVINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9251 IRVINGTON BLB HWY IRVINGTON AL 36544 USA "
    },
    {
      "Location ID": "36545",
      "Location Name": "JACKSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1829 COLLEGE AVE JACKSON AL 36545 USA "
    },
    {
      "Location ID": "36548",
      "Location Name": "LEROY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 19 WASHINGTON ST LEROY AL 36548 USA "
    },
    {
      "Location ID": "36549",
      "Location Name": "LILLIAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 33985 US HIGHWAY 98 LILLIAN AL 36549 USA "
    },
    {
      "Location ID": "36551",
      "Location Name": "LOXLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1059 S HOLLEY ST LOXLEY AL 36551 USA "
    },
    {
      "Location ID": "36553",
      "Location Name": "MC INTOSH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7595 HIGHWAY 43 MC INTOSH AL 36553 USA "
    },
    {
      "Location ID": "36555",
      "Location Name": "MAGNOLIA SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14840 US HIGHWAY 98 MAGNOLIA SPRINGS AL 36555 USA "
    },
    {
      "Location ID": "36556",
      "Location Name": "MALCOLM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 BATES LAKE RD MALCOLM AL 36556 USA "
    },
    {
      "Location ID": "36559",
      "Location Name": "MONTROSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7325 SIBLEY ST MONTROSE AL 36559 USA "
    },
    {
      "Location ID": "36560",
      "Location Name": "MOUNT VERNON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 19175 OLD HIGHWAY 43 MOUNT VERNON AL 36560 USA "
    },
    {
      "Location ID": "36561",
      "Location Name": "ORANGE BEACH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 25778 JOHN M SNOOK DR ORANGE BEACH AL 36561 USA "
    },
    {
      "Location ID": "36562",
      "Location Name": "PERDIDO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 23336 COUNTY ROAD 47 PERDIDO AL 36562 USA "
    },
    {
      "Location ID": "36564",
      "Location Name": "POINT CLEAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6581 COUNTY ROAD 32 POINT CLEAR AL 36564 USA "
    },
    {
      "Location ID": "36567",
      "Location Name": "ROBERTSDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 18145 US HIGHWAY 90 ROBERTSDALE AL 36567 USA "
    },
    {
      "Location ID": "36568",
      "Location Name": "SAINT ELMO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8900 HIGHWAY 90 W SAINT ELMO AL 36568 USA "
    },
    {
      "Location ID": "36571",
      "Location Name": "SARALAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 NORTON AVE SARALAND AL 36571 USA "
    },
    {
      "Location ID": "36572",
      "Location Name": "SATSUMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 50 ORANGE AVE W SATSUMA AL 36572 USA "
    },
    {
      "Location ID": "36575",
      "Location Name": "SEMMES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9051 MOFFETT RD SEMMES AL 36575 USA "
    },
    {
      "Location ID": "36576",
      "Location Name": "SILVERHILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 21731 BROAD ST SILVERHILL AL 36576 USA "
    },
    {
      "Location ID": "36578",
      "Location Name": "STAPLETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 35387 STATE HIGHWAY 59 STAPLETON AL 36578 USA "
    },
    {
      "Location ID": "36580",
      "Location Name": "SUMMERDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 COUNTY ROAD 32 E SUMMERDALE AL 36580 USA "
    },
    {
      "Location ID": "36582",
      "Location Name": "THEODORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5631 HIGHWAY 90 THEODORE AL 36582 USA "
    },
    {
      "Location ID": "36585",
      "Location Name": "WAGARVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20191 HIGHWAY 43 WAGARVILLE AL 36585 USA "
    },
    {
      "Location ID": "36587",
      "Location Name": "WILMER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13255 MOFFETT RD WILMER AL 36587 USA "
    },
    {
      "Location ID": "365AL",
      "Location Name": "ALTO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2 ALTO WAY ATMORE AL 36502 USA "
    },
    {
      "Location ID": "365AM",
      "Location Name": "AMAZON MOB5",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6735 TRIPPEL RD THEODORE AL 36582-6502 USA "
    },
    {
      "Location ID": "365AN",
      "Location Name": "LOG MOBILE ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 251 N ROYAL ST MOBILE AL 36602 USA "
    },
    {
      "Location ID": "365AX",
      "Location Name": "MOBILE CHRISTMAS ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 245 JACINTOPORT BLVD SARALAND AL 36571 USA "
    },
    {
      "Location ID": "365BB",
      "Location Name": "B & B PECAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16151 GREENO RD FAIRHOPE AL 36532 USA "
    },
    {
      "Location ID": "365FN",
      "Location Name": "FILTER'S NOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9800 I 65 SERVICE RD S CREOLA AL 36525 USA "
    },
    {
      "Location ID": "365HC",
      "Location Name": "HEALTH CARE DIAGNOSTICS, AL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 29922 COUNTY ROAD 49 LOXLEY AL 36551 USA "
    },
    {
      "Location ID": "365ME",
      "Location Name": "MEDISTAT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 E AZALEA AVE FOLEY AL 36535 USA "
    },
    {
      "Location ID": "365SC",
      "Location Name": "COLUMBIA SOUTHERN UNIVERSITY, AL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 21982 UNIVERSITY LN ORANGE BEACH AL 36561 USA "
    },
    {
      "Location ID": "36605",
      "Location Name": "BAYSIDE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2000 ROSEDALE RD MOBILE AL 36605 USA "
    },
    {
      "Location ID": "366AX",
      "Location Name": "MOBILE DOWNTOWN ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 250 ST JOSEPH ST MOBILE AL 36601 USA "
    },
    {
      "Location ID": "366FX",
      "Location Name": "INTEGRATED - MOB THS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2117 MICHIGAN AVE MOBILE AL 36615 USA "
    },
    {
      "Location ID": "367",
      "Location Name": "SELMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1301 ALABAMA AVE SELMA AL 36703 USA "
    },
    {
      "Location ID": "36801",
      "Location Name": "OPELIKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 S 7TH ST OPELIKA AL 36801 USA "
    },
    {
      "Location ID": "36830",
      "Location Name": "AUBURN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 OPELIKA RD AUBURN AL 36830 USA "
    },
    {
      "Location ID": "36854",
      "Location Name": "VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 FOB JAMES DR VALLEY AL 36854 USA "
    },
    {
      "Location ID": "36858",
      "Location Name": "HATCHECHUBBEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 601 UCHEE RD HATCHECHUBBEE AL 36858 USA "
    },
    {
      "Location ID": "36860",
      "Location Name": "HURTSBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 311 MAIN ST HURTSBORO AL 36860 USA "
    },
    {
      "Location ID": "36863",
      "Location Name": "LANETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 1ST ST LANETT AL 36863 USA "
    },
    {
      "Location ID": "36867",
      "Location Name": "PHENIX CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1310 9TH AVE PHENIX CITY AL 36867 USA "
    },
    {
      "Location ID": "36875",
      "Location Name": "SEALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 21 CUTCHIN DR SEALE AL 36875 USA "
    },
    {
      "Location ID": "36877",
      "Location Name": "SMITHS STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2720 PANTHER PKWY SMITHS STATION AL 36877 USA "
    },
    {
      "Location ID": "370",
      "Location Name": "LOG NASHVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 525 ROYAL PKWY NASHVILLE TN 37229 USA "
    },
    {
      "Location ID": "37023",
      "Location Name": "BIG ROCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 515 BIG ROCK RD BIG ROCK TN 37023 USA "
    },
    {
      "Location ID": "37027",
      "Location Name": "BRENTWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8011 BROOKS CHAPEL RD BRENTWOOD TN 37027 USA "
    },
    {
      "Location ID": "37028",
      "Location Name": "BUMPUS MILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3035 HIGHWAY 120 BUMPUS MILLS TN 37028 USA "
    },
    {
      "Location ID": "37031",
      "Location Name": "CASTALIAN SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3070 HARTSVILLE PIKE CASTALIAN SPRINGS TN 37031 USA "
    },
    {
      "Location ID": "37040",
      "Location Name": "CLARKSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2031 WILMA RUDOLPH BLVD CLARKSVILLE TN 37040 USA "
    },
    {
      "Location ID": "37047",
      "Location Name": "CORNERSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 N MAIN ST CORNERSVILLE TN 37047 USA "
    },
    {
      "Location ID": "37050",
      "Location Name": "CUMBERLAND CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 BASS AVE CUMBERLAND CITY TN 37050 USA "
    },
    {
      "Location ID": "37052",
      "Location Name": "CUNNINGHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 160 HIGHWAY 13 CUNNINGHAM TN 37052 USA "
    },
    {
      "Location ID": "37055",
      "Location Name": "DICKSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 SKYLINE CIR DICKSON TN 37055 USA "
    },
    {
      "Location ID": "37058",
      "Location Name": "DOVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 326 SPRING ST DOVER TN 37058 USA "
    },
    {
      "Location ID": "37061",
      "Location Name": "ERIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 30 ARLINGTON ST ERIN TN 37061 USA "
    },
    {
      "Location ID": "37064",
      "Location Name": "FRANKLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 810 OAK MEADOW DR FRANKLIN TN 37064 USA "
    },
    {
      "Location ID": "37066",
      "Location Name": "GALLATIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 380 MAPLE ST GALLATIN TN 37066 USA "
    },
    {
      "Location ID": "37074",
      "Location Name": "HARTSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 W MAIN ST HARTSVILLE TN 37074 USA "
    },
    {
      "Location ID": "37078",
      "Location Name": "HURRICANE MILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1855 HURRICANE MILLS RD HURRICANE MILLS TN 37078 USA "
    },
    {
      "Location ID": "37079",
      "Location Name": "INDIAN MOUND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1207 HIGHWAY 46 INDIAN MOUND TN 37079 USA "
    },
    {
      "Location ID": "37083",
      "Location Name": "LAFAYETTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 506 ELLINGTON DR LAFAYETTE TN 37083 USA "
    },
    {
      "Location ID": "37086",
      "Location Name": "LA VERGNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5309 MURFREESBORO RD LA VERGNE TN 37086 USA "
    },
    {
      "Location ID": "37087",
      "Location Name": "LEBANON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 226 E GAY ST LEBANON TN 37087 USA "
    },
    {
      "Location ID": "37091",
      "Location Name": "LEWISBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 557 E COMMERCE ST LEWISBURG TN 37091 USA "
    },
    {
      "Location ID": "37097",
      "Location Name": "LOBELVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 22 S MAIN ST LOBELVILLE TN 37097 USA "
    },
    {
      "Location ID": "370AN",
      "Location Name": "AMAZON BNA9",
      "Time Zone Offset (Hours)": -1,
      "Address": " 271 MASON RD LA VERGNE TN 37086-3212 USA "
    },
    {
      "Location ID": "370CA",
      "Location Name": "BRENTWOOD CARRIER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1734 GEN GEORGE PATTON DR BRENTWOOD TN 37027 USA "
    },
    {
      "Location ID": "370CG",
      "Location Name": "COOPER GENERAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5501 CENTRE POINTE DR LA VERGNE TN 37086 USA "
    },
    {
      "Location ID": "370FZ",
      "Location Name": "LOG NASHVILLE TN PSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 191 CHARTER PL LA VERGNE TN 37086 USA "
    },
    {
      "Location ID": "370GM",
      "Location Name": "GENCO (LA VERGNE)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1223 HEIL QUAKER BLVD LA VERGNE TN 37086 USA "
    },
    {
      "Location ID": "370HM",
      "Location Name": "HYLA MOBILE (LA VERGNE TN)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 601 MASON RD STE 180 LA VERGNE TN 37086 USA "
    },
    {
      "Location ID": "370HT",
      "Location Name": "SHOCK HOUND/HOT TOPIC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6001  RELIANCE DR LA VERGNE TN 37086 USA "
    },
    {
      "Location ID": "370IB",
      "Location Name": "INGRAM BOOK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 INGRAM BLVD LA VERGNE TN 37086 USA "
    },
    {
      "Location ID": "370KA",
      "Location Name": "KEYSTONE AUTOMOTIVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 MASON RD LA VERGNE TN 37086 USA "
    },
    {
      "Location ID": "370LC",
      "Location Name": "LIFEWAY CHRISTIAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 535 MADDOX SIMPSON PKWY LEBANON TN 37090 USA "
    },
    {
      "Location ID": "370LX",
      "Location Name": "SOUTHSIDE ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 SOUTHSIDE PARK DR LEBANON TN 37090 USA "
    },
    {
      "Location ID": "370MI",
      "Location Name": "UPS MAIL INNOVATIONS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1375 HEIL QUAKER BLVD LA VERGNE TN 37086 USA "
    },
    {
      "Location ID": "370PB",
      "Location Name": "PITNEY BOWES (TN)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 371 MASON RD LA VERGNE TN 37086 USA "
    },
    {
      "Location ID": "370QS",
      "Location Name": "QUANTA SERVICE NASHVILLE LLC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1621 HEIL QUAKER BLVD LA VERGNE TN 37086 USA "
    },
    {
      "Location ID": "370SA",
      "Location Name": "SAKS (LA VERGNE)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 WALDENBOOKS DR LA VERGNE TN 37086 USA "
    },
    {
      "Location ID": "370UP",
      "Location Name": "UPS SUPPLY CHAIN SOLUTIONS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 917 AIRPARK CENTER DR NASHVILLE TN 37217 USA "
    },
    {
      "Location ID": "37122",
      "Location Name": "MOUNT JULIET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2491 N MOUNT JULIET RD MOUNT JULIET TN 37122 USA "
    },
    {
      "Location ID": "37129",
      "Location Name": "MURFREESBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2255 MEMORIAL BLVD MURFREESBORO TN 37129 USA "
    },
    {
      "Location ID": "37130",
      "Location Name": "CHURCH STREET STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 825 S CHURCH ST MURFREESBORO TN 37130 USA "
    },
    {
      "Location ID": "37150",
      "Location Name": "RED BOILING SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 134 MARKET ST RED BOILING SPRINGS TN 37150 USA "
    },
    {
      "Location ID": "37167",
      "Location Name": "SMYRNA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 250 MAYFIELD DR SMYRNA TN 37167 USA "
    },
    {
      "Location ID": "37174",
      "Location Name": "SPRING HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 223 TOWN CENTER PKWY SPRING HILL TN 37174 USA "
    },
    {
      "Location ID": "37175",
      "Location Name": "STEWART",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6100 HIGHWAY 147 STEWART TN 37175 USA "
    },
    {
      "Location ID": "37178",
      "Location Name": "TENNESSEE RIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 940 HIGHWAY 49 TENNESSEE RIDGE TN 37178 USA "
    },
    {
      "Location ID": "37179",
      "Location Name": "THOMPSONS STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1507 THOMPSON STATION RD W THOMPSONS STATION TN 37179 USA "
    },
    {
      "Location ID": "37191",
      "Location Name": "WOODLAWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2412 WOODLAWN RD WOODLAWN TN 37191 USA "
    },
    {
      "Location ID": "371AS",
      "Location Name": "ASURION (SMYRNA)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1850 MIDWAY LN SMYRNA TN 37167 USA "
    },
    {
      "Location ID": "371BR",
      "Location Name": "BLACK RIFLE COFFEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 549A ALDI BLVD MOUNT JULIET TN 37122 USA "
    },
    {
      "Location ID": "371OP",
      "Location Name": "OPTORO (MOUNT JULIET)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8051 EASTGATE BLVD MOUNT JULIET TN 37122 USA "
    },
    {
      "Location ID": "372",
      "Location Name": "TN DISTRICT & HR OFFICES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 811 ROYAL PARKWAY NASHVILLE TN 37229 USA "
    },
    {
      "Location ID": "372AM",
      "Location Name": "AMAZON BNA5",
      "Time Zone Offset (Hours)": -1,
      "Address": " 50 AIRWAYS BLVD NASHVILLE TN 37217-2516 USA "
    },
    {
      "Location ID": "372CX",
      "Location Name": "NASHVILLE TN CHRISTMAS ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 731 MASSMAN DR NASHVILLE TN 37210 USA "
    },
    {
      "Location ID": "372EG",
      "Location Name": "ESSEX GROUP TECH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 455 INDUSTRIAL BLVD C LA VERGNE TN 37086 USA "
    },
    {
      "Location ID": "372MA",
      "Location Name": "LOG MUSIC CITY ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 570 KNIGHT VALLEY DR NASHVILLE TN 37227 USA "
    },
    {
      "Location ID": "373",
      "Location Name": "LOG CHATTANOOGA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6050 SHALLOWFORD RD CHATTANOOGA TN 37421 USA "
    },
    {
      "Location ID": "37301",
      "Location Name": "ALTAMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 70 FITCHTOWN RD ALTAMONT TN 37301 USA "
    },
    {
      "Location ID": "37302",
      "Location Name": "APISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4498 BILL JONES RD APISON TN 37302 USA "
    },
    {
      "Location ID": "37303",
      "Location Name": "ATHENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 S WHITE ST ATHENS TN 37303 USA "
    },
    {
      "Location ID": "37305",
      "Location Name": "BEERSHEBA SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 19472 STATE ROUTE 56 BEERSHEBA SPRINGS TN 37305 USA "
    },
    {
      "Location ID": "37306",
      "Location Name": "BELVIDERE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 704 BELVIDERE RD BELVIDERE TN 37306 USA "
    },
    {
      "Location ID": "37307",
      "Location Name": "BENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 188 MAIN ST BENTON TN 37307 USA "
    },
    {
      "Location ID": "37308",
      "Location Name": "BIRCHWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5505 HIGHWAY 60 BIRCHWOOD TN 37308 USA "
    },
    {
      "Location ID": "37309",
      "Location Name": "CALHOUN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 748 HIGHWAY 163 CALHOUN TN 37309 USA "
    },
    {
      "Location ID": "37310",
      "Location Name": "CHARLESTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8916 HIWASSEE ST NE CHARLESTON TN 37310 USA "
    },
    {
      "Location ID": "37311",
      "Location Name": "CLEVELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1981 KEITH ST NW CLEVELAND TN 37311 USA "
    },
    {
      "Location ID": "37313",
      "Location Name": "COALMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 655 HEIDENBURG ST COALMONT TN 37313 USA "
    },
    {
      "Location ID": "37315",
      "Location Name": "COLLEGEDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5006 UNIVERSITY DR W COLLEGEDALE TN 37315 USA "
    },
    {
      "Location ID": "37317",
      "Location Name": "COPPERHILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 145 OCOEE ST COPPERHILL TN 37317 USA "
    },
    {
      "Location ID": "37318",
      "Location Name": "COWAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 CUMBERLAND ST E COWAN TN 37318 USA "
    },
    {
      "Location ID": "37321",
      "Location Name": "DAYTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 426 1ST AVE DAYTON TN 37321 USA "
    },
    {
      "Location ID": "37322",
      "Location Name": "DECATUR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 RIVER RD DECATUR TN 37322 USA "
    },
    {
      "Location ID": "37324",
      "Location Name": "DECHERD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 E MAIN ST DECHERD TN 37324 USA "
    },
    {
      "Location ID": "37325",
      "Location Name": "DELANO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9673 HIGHWAY 411 DELANO TN 37325 USA "
    },
    {
      "Location ID": "37326",
      "Location Name": "DUCKTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1656 N HIGHWAY 68 DUCKTOWN TN 37326 USA "
    },
    {
      "Location ID": "37327",
      "Location Name": "DUNLAP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15198 RANKIN AVE DUNLAP TN 37327 USA "
    },
    {
      "Location ID": "37328",
      "Location Name": "ELORA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 68 OLD HUNTLAND RD ELORA TN 37328 USA "
    },
    {
      "Location ID": "37329",
      "Location Name": "ENGLEWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 17 S NIOTA RD ENGLEWOOD TN 37329 USA "
    },
    {
      "Location ID": "37330",
      "Location Name": "ESTILL SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 316 S MAIN ST ESTILL SPRINGS TN 37330 USA "
    },
    {
      "Location ID": "37331",
      "Location Name": "ETOWAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 517 TENNESSEE AVE ETOWAH TN 37331 USA "
    },
    {
      "Location ID": "37332",
      "Location Name": "EVENSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13602 RHEA COUNTY HWY EVENSVILLE TN 37332 USA "
    },
    {
      "Location ID": "37334",
      "Location Name": "FAYETTEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 226 COLLEGE ST E FAYETTEVILLE TN 37334 USA "
    },
    {
      "Location ID": "37335",
      "Location Name": "FLINTVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 241 FLINTVILLE RD FLINTVILLE TN 37335 USA "
    },
    {
      "Location ID": "37336",
      "Location Name": "GEORGETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8405 HIGHWAY 60 GEORGETOWN TN 37336 USA "
    },
    {
      "Location ID": "37338",
      "Location Name": "GRAYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4397 DAYTON AVE GRAYSVILLE TN 37338 USA "
    },
    {
      "Location ID": "37340",
      "Location Name": "GUILD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11360 HIGHWAY 41 GUILD TN 37340 USA "
    },
    {
      "Location ID": "37341",
      "Location Name": "HARRISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6301 HIGHWAY 58 HARRISON TN 37341 USA "
    },
    {
      "Location ID": "37342",
      "Location Name": "HILLSBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7746 HILLSBORO HWY HILLSBORO TN 37342 USA "
    },
    {
      "Location ID": "37345",
      "Location Name": "HUNTLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 ALABAMA ST HUNTLAND TN 37345 USA "
    },
    {
      "Location ID": "37347",
      "Location Name": "JASPER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3739 MAIN ST JASPER TN 37347 USA "
    },
    {
      "Location ID": "37348",
      "Location Name": "KELSO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 36 TEAL HOLLOW RD KELSO TN 37348 USA "
    },
    {
      "Location ID": "37352",
      "Location Name": "LYNCHBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13 HILES ST LYNCHBURG TN 37352 USA "
    },
    {
      "Location ID": "37353",
      "Location Name": "MC DONALD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4980 S LEE HWY MC DONALD TN 37353 USA "
    },
    {
      "Location ID": "37354",
      "Location Name": "MADISONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 161 WARREN ST MADISONVILLE TN 37354 USA "
    },
    {
      "Location ID": "37355",
      "Location Name": "MANCHESTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1601 HILLSBORO BLVD MANCHESTER TN 37355 USA "
    },
    {
      "Location ID": "37356",
      "Location Name": "MONTEAGLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 320 MOFFAT ST MONTEAGLE TN 37356 USA "
    },
    {
      "Location ID": "37357",
      "Location Name": "MORRISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 140 E MAPLE ST MORRISON TN 37357 USA "
    },
    {
      "Location ID": "37359",
      "Location Name": "MULBERRY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 27 OLD LYNCHBURG HWY NE MULBERRY TN 37359 USA "
    },
    {
      "Location ID": "37363",
      "Location Name": "OOLTEWAH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9301 OCOEE ST OOLTEWAH TN 37363 USA "
    },
    {
      "Location ID": "37366",
      "Location Name": "PELHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1619 US 41 PELHAM TN 37366 USA "
    },
    {
      "Location ID": "37367",
      "Location Name": "PIKEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3372 MAIN ST PIKEVILLE TN 37367 USA "
    },
    {
      "Location ID": "37370",
      "Location Name": "RICEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3804 HIGHWAY 11 S RICEVILLE TN 37370 USA "
    },
    {
      "Location ID": "37373",
      "Location Name": "SALE CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15117 DAYTON PIKE SALE CREEK TN 37373 USA "
    },
    {
      "Location ID": "37374",
      "Location Name": "SEQUATCHIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3670 VALLEY VIEW HWY SEQUATCHIE TN 37374 USA "
    },
    {
      "Location ID": "37375",
      "Location Name": "SEWANEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 94 UNIVERSITY AVE SEWANEE TN 37375 USA "
    },
    {
      "Location ID": "37377",
      "Location Name": "SIGNAL MOUNTAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1155 RIDGEWAY AVE SIGNAL MOUNTAIN TN 37377 USA "
    },
    {
      "Location ID": "37378",
      "Location Name": "SMARTT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5174 MANCHESTER HWY SMARTT TN 37378 USA "
    },
    {
      "Location ID": "37379",
      "Location Name": "SODDY DAISY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10575 DAYTON PIKE SODDY DAISY TN 37379 USA "
    },
    {
      "Location ID": "37380",
      "Location Name": "SOUTH PITTSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 ELM AVE SOUTH PITTSBURG TN 37380 USA "
    },
    {
      "Location ID": "37381",
      "Location Name": "SPRING CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 265 FRONT ST SPRING CITY TN 37381 USA "
    },
    {
      "Location ID": "37382",
      "Location Name": "SUMMITVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7881 MCMINNVILLE HWY SUMMITVILLE TN 37382 USA "
    },
    {
      "Location ID": "37385",
      "Location Name": "TELLICO PLAINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 171 BANK ST TELLICO PLAINS TN 37385 USA "
    },
    {
      "Location ID": "37387",
      "Location Name": "TRACY CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 245 NATHURST ST TRACY CITY TN 37387 USA "
    },
    {
      "Location ID": "37388",
      "Location Name": "TULLAHOMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 S JACKSON ST TULLAHOMA TN 37388 USA "
    },
    {
      "Location ID": "37394",
      "Location Name": "VIOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15 LYNN ST VIOLA TN 37394 USA "
    },
    {
      "Location ID": "37396",
      "Location Name": "WHITESIDE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3725 HIGHWAY 134 WHITESIDE TN 37396 USA "
    },
    {
      "Location ID": "37397",
      "Location Name": "WHITWELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13190 HIGHWAY 28 WHITWELL TN 37397 USA "
    },
    {
      "Location ID": "37398",
      "Location Name": "WINCHESTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 S JEFFERSON ST RM 100 WINCHESTER TN 37398 USA "
    },
    {
      "Location ID": "373FC",
      "Location Name": "FROST CUTLERY (OOLTEWAH TN)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6861 MOUNTAIN VIEW RD OOLTEWAH TN 37363 USA "
    },
    {
      "Location ID": "373MF",
      "Location Name": "MCKEE FOODS CORPORATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10106 PARK LN OOLTEWAH TN 37363 USA "
    },
    {
      "Location ID": "373PR",
      "Location Name": "PILGRAMS BEST",
      "Time Zone Offset (Hours)": -1,
      "Address": " HC 77 BOX A38 BEERSHEBA SPRINGS TN 37305 USA "
    },
    {
      "Location ID": "37402",
      "Location Name": "DOWNTOWN CHATTANOOGA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 910 GEORGIA AVE CHATTANOOGA TN 37402 USA "
    },
    {
      "Location ID": "37421",
      "Location Name": "CHICKAMAUGA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6050 SHALLOWFORD RD CHATTANOOGA TN 37421 USA "
    },
    {
      "Location ID": "376",
      "Location Name": "JOHNSON CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 530 E MAIN ST JOHNSON CITY TN 37601 USA "
    },
    {
      "Location ID": "37604",
      "Location Name": "CARROLL REECE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1100 N STATE OF FRANKLIN RD JOHNSON CITY TN 37604 USA "
    },
    {
      "Location ID": "37615",
      "Location Name": "GRAY STATION, TN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 OLD GRAY STATION RD JOHNSON CITY TN 37615 USA "
    },
    {
      "Location ID": "37616",
      "Location Name": "AFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 432 AFTON RD AFTON TN 37616 USA "
    },
    {
      "Location ID": "37617",
      "Location Name": "BLOUNTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 169 FRANKLIN DR BLOUNTVILLE TN 37617 USA "
    },
    {
      "Location ID": "37618",
      "Location Name": "BLUFF CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4326 BLUFF CITY HWY BLUFF CITY TN 37618 USA "
    },
    {
      "Location ID": "37620",
      "Location Name": "VOLUNTEER STATION, TN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1430 VOLUNTEER PKWY STE 32 BRISTOL TN 37620 USA "
    },
    {
      "Location ID": "37640",
      "Location Name": "BUTLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 211 PIERCETOWN RD BUTLER TN 37640 USA "
    },
    {
      "Location ID": "37641",
      "Location Name": "CHUCKEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8140 E ANDREW JOHNSON HWY CHUCKEY TN 37641 USA "
    },
    {
      "Location ID": "37642",
      "Location Name": "CHURCH HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 131 E MAIN BLVD CHURCH HILL TN 37642 USA "
    },
    {
      "Location ID": "37643",
      "Location Name": "ELIZABETHTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 901 W ELK AVE ELIZABETHTON TN 37643 USA "
    },
    {
      "Location ID": "37645",
      "Location Name": "MOUNT CARMEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 MAIN ST E MOUNT CARMEL TN 37645 USA "
    },
    {
      "Location ID": "37650",
      "Location Name": "ERWIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 N MAIN AVE ERWIN TN 37650 USA "
    },
    {
      "Location ID": "37656",
      "Location Name": "FALL BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 134 RURITAN RD FALL BRANCH TN 37656 USA "
    },
    {
      "Location ID": "37657",
      "Location Name": "FLAG POND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1305 RICE CREEK RD FLAG POND TN 37657 USA "
    },
    {
      "Location ID": "37658",
      "Location Name": "HAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 433 HIGHWAY 321 HAMPTON TN 37658 USA "
    },
    {
      "Location ID": "37659",
      "Location Name": "JONESBOROUGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 BOONE ST JONESBOROUGH TN 37659 USA "
    },
    {
      "Location ID": "37660",
      "Location Name": "DOWNTOWN KINGSPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 320 W CENTER ST KINGSPORT TN 37660 USA "
    },
    {
      "Location ID": "37663",
      "Location Name": "COLONIAL HEIGHTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4313 FORT HENRY DR KINGSPORT TN 37663 USA "
    },
    {
      "Location ID": "37664",
      "Location Name": "KINGSPORT MAIN, TN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1001 N EASTMAN RD KINGSPORT TN 37664 USA "
    },
    {
      "Location ID": "37681",
      "Location Name": "LIMESTONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 359 OPIE ARNOLD RD LIMESTONE TN 37681 USA "
    },
    {
      "Location ID": "37682",
      "Location Name": "MILLIGAN COLLEGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 NETH DR MILLIGAN COLLEGE TN 37682 USA "
    },
    {
      "Location ID": "37683",
      "Location Name": "MOUNTAIN CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 216 COLLEGE ST MOUNTAIN CITY TN 37683 USA "
    },
    {
      "Location ID": "37684",
      "Location Name": "MOUNTAIN HOME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 53 MEMORIAL DR MOUNTAIN HOME TN 37684 USA "
    },
    {
      "Location ID": "37686",
      "Location Name": "PINEY FLATS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 257 JONESBORO RD PINEY FLATS TN 37686 USA "
    },
    {
      "Location ID": "37687",
      "Location Name": "ROAN MOUNTAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8060 HIGHWAY 19 E ROAN MOUNTAIN TN 37687 USA "
    },
    {
      "Location ID": "37688",
      "Location Name": "SHADY VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 136 HIGHWAY 133 SHADY VALLEY TN 37688 USA "
    },
    {
      "Location ID": "37690",
      "Location Name": "TELFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1888 OLD STATE ROUTE 34 TELFORD TN 37690 USA "
    },
    {
      "Location ID": "37691",
      "Location Name": "TRADE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 164 HIGHWAY 67 N TRADE TN 37691 USA "
    },
    {
      "Location ID": "37692",
      "Location Name": "UNICOI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4010 UNICOI DR UNICOI TN 37692 USA "
    },
    {
      "Location ID": "37694",
      "Location Name": "WATAUGA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 N 2ND ST WATAUGA TN 37694 USA "
    },
    {
      "Location ID": "376EC",
      "Location Name": "EASTMAN CHEMICAL, TN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 S WILCOX DR KINGSPORT TN 37660 USA "
    },
    {
      "Location ID": "376EL",
      "Location Name": "E&L ENTERPRICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 127 HIGHWAY 75, SUITE 2 BLOUNTVILLE TN 37617 USA "
    },
    {
      "Location ID": "376ET",
      "Location Name": "CPU EAST TENN STATE UNIV",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 JL SEEHORN JR RD JOHNSON CITY TN 37614 USA "
    },
    {
      "Location ID": "376FI",
      "Location Name": "FIDELITON, TN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 FIDELITONE WAY ELIZABETHTON TN 37643 USA "
    },
    {
      "Location ID": "376GL",
      "Location Name": "GOODERS LAB, TN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1348 MILLIGAN HWY JOHNSON CITY TN 37601 USA "
    },
    {
      "Location ID": "376HB",
      "Location Name": "HOLSTON BOOKS, TN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 494 E MAIN ST KINGSPORT TN 37660 USA "
    },
    {
      "Location ID": "376HS",
      "Location Name": "HSN FULFILMENT OF TN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 857 MOUNTAIN VIEW DR PINEY FLATS TN 37686 USA "
    },
    {
      "Location ID": "376JQ",
      "Location Name": "VA JAMES H QUILLEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 69 DOGWOOD AVE MOUNTAIN HOME TN 37684 USA "
    },
    {
      "Location ID": "376LG",
      "Location Name": "LUGNUT 4X4",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2212 BUFFALO RD STE 260 JOHNSON CITY TN 37604 USA "
    },
    {
      "Location ID": "376NP",
      "Location Name": "NEW BEGINNINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 686 INDUSTRIAL PARK RD PINEY FLATS TN 37686 USA "
    },
    {
      "Location ID": "377",
      "Location Name": "KNOXVILLE P&DC, TN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1237 E WEISGARBER RD KNOXVILLE TN 37950 USA "
    },
    {
      "Location ID": "37701",
      "Location Name": "ALCOA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 S HALL RD ALCOA TN 37701 USA "
    },
    {
      "Location ID": "37710",
      "Location Name": "BRICEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1401 BRICEVILLE HWY BRICEVILLE TN 37710 USA "
    },
    {
      "Location ID": "37713",
      "Location Name": "BYBEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1580 HIGHWAY 160 BYBEE TN 37713 USA "
    },
    {
      "Location ID": "37714",
      "Location Name": "CARYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 HILL LN CARYVILLE TN 37714 USA "
    },
    {
      "Location ID": "37715",
      "Location Name": "CLAIRFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5671 HIGHWAY 90 CLAIRFIELD TN 37715 USA "
    },
    {
      "Location ID": "37730",
      "Location Name": "EAGAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4183 HIGHWAY 90 EAGAN TN 37730 USA "
    },
    {
      "Location ID": "37732",
      "Location Name": "ELGIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 179 RUGBY HWY ELGIN TN 37732 USA "
    },
    {
      "Location ID": "37733",
      "Location Name": "RUGBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5569 RUGBY HWY RUGBY TN 37733 USA "
    },
    {
      "Location ID": "37738",
      "Location Name": "GATLINBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1216 E PARKWAY GATLINBURG TN 37738 USA "
    },
    {
      "Location ID": "37742",
      "Location Name": "GREENBACK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6726 MORGANTON RD GREENBACK TN 37742 USA "
    },
    {
      "Location ID": "37743",
      "Location Name": "GREENEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 W SUMMER ST GREENEVILLE TN 37743 USA "
    },
    {
      "Location ID": "37762",
      "Location Name": "JELLICO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 368 N MAIN ST JELLICO TN 37762 USA "
    },
    {
      "Location ID": "37763",
      "Location Name": "KINGSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 424 N KENTUCKY ST KINGSTON TN 37763 USA "
    },
    {
      "Location ID": "37769",
      "Location Name": "ROCKY TOP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 2ND ST ROCKY TOP TN 37769 USA "
    },
    {
      "Location ID": "37770",
      "Location Name": "LANCING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1206 NASHVILLE HWY LANCING TN 37770 USA "
    },
    {
      "Location ID": "37771",
      "Location Name": "LENOIR CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 217 E BROADWAY ST LENOIR CITY TN 37771 USA "
    },
    {
      "Location ID": "37777",
      "Location Name": "LOUISVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2948 TOPSIDE RD LOUISVILLE TN 37777 USA "
    },
    {
      "Location ID": "37801",
      "Location Name": "MARYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 226 KELLER LN MARYVILLE TN 37801 USA "
    },
    {
      "Location ID": "37813",
      "Location Name": "MORRISTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 803 S CUMBERLAND ST MORRISTOWN TN 37813 USA "
    },
    {
      "Location ID": "37821",
      "Location Name": "NEWPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 265 COSBY HWY NEWPORT TN 37821 USA "
    },
    {
      "Location ID": "37843",
      "Location Name": "PARROTTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2053 OLD PARROTTSVILLE HWY PARROTTSVILLE TN 37843 USA "
    },
    {
      "Location ID": "37852",
      "Location Name": "ROBBINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5900 SCOTT HWY ROBBINS TN 37852 USA "
    },
    {
      "Location ID": "37862",
      "Location Name": "SEVIERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 711 DOLLY PARTON PKWY SEVIERVILLE TN 37862 USA "
    },
    {
      "Location ID": "37863",
      "Location Name": "PIGEON FORGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3235 RENA ST PIGEON FORGE TN 37863 USA "
    },
    {
      "Location ID": "37865",
      "Location Name": "SEYMOUR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10615 CHAPMAN HWY SEYMOUR TN 37865 USA "
    },
    {
      "Location ID": "37871",
      "Location Name": "STRAWBERRY PLAINS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2704 W HIGHWAY 11E STRAWBERRY PLAINS TN 37871 USA "
    },
    {
      "Location ID": "37872",
      "Location Name": "SUNBRIGHT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8129 MORGAN CO HWY SUNBRIGHT TN 37872 USA "
    },
    {
      "Location ID": "37881",
      "Location Name": "THORN HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 OLD MOUNTAIN RD THORN HILL TN 37881 USA "
    },
    {
      "Location ID": "37921",
      "Location Name": "NORTHWEST ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4334 MIDDLEBROOK PIKE KNOXVILLE TN 37921 USA "
    },
    {
      "Location ID": "379AX",
      "Location Name": "KNOXVILLE PEAK ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1903 BIG SPRINGS RD MARYVILLE TN 37801 USA "
    },
    {
      "Location ID": "379CG",
      "Location Name": "COLLECTIBLES OF THE GAME ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4312 MIDDLEBROOK PIKE KNOXVILLE TN 37921 USA "
    },
    {
      "Location ID": "379TA",
      "Location Name": "CONCORD CHRISTMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 PERIMETER PARK RD KNOXVILLE TN 37922 USA "
    },
    {
      "Location ID": "37H",
      "Location Name": "SOUTHEAST AREA (TN) STC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 640 NORTHPARK DR CLINTON TN 37716 USA "
    },
    {
      "Location ID": "380",
      "Location Name": "LOG MEMPHIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 555 S B B KING BLVD LBBY MEMPHIS TN 38101 USA "
    },
    {
      "Location ID": "38002",
      "Location Name": "ARLINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5885 AIRLINE RD ARLINGTON TN 38002 USA "
    },
    {
      "Location ID": "38004",
      "Location Name": "ATOKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 186 ATOKA MUNFORD RD ATOKA TN 38004 USA "
    },
    {
      "Location ID": "38007",
      "Location Name": "BOGOTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9866 HIGHWAY 78 BOGOTA TN 38007 USA "
    },
    {
      "Location ID": "38008",
      "Location Name": "BOLIVAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 E MARKET ST BOLIVAR TN 38008 USA "
    },
    {
      "Location ID": "38011",
      "Location Name": "BRIGHTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1600 OLD HIGHWAY 51 S BRIGHTON TN 38011 USA "
    },
    {
      "Location ID": "38012",
      "Location Name": "BROWNSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 ANDERSON AVE BROWNSVILLE TN 38012 USA "
    },
    {
      "Location ID": "38015",
      "Location Name": "BURLISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6379 HIGHWAY 59 W BURLISON TN 38015 USA "
    },
    {
      "Location ID": "38017",
      "Location Name": "COLLIERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 131 S CENTER ST COLLIERVILLE TN 38017 USA "
    },
    {
      "Location ID": "38019",
      "Location Name": "COVINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 S MAIN ST COVINGTON TN 38019 USA "
    },
    {
      "Location ID": "38021",
      "Location Name": "CROCKETT MILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 28 JACKSON STORE RD CROCKETT MILLS TN 38021 USA "
    },
    {
      "Location ID": "38023",
      "Location Name": "DRUMMONDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5701 RICHARDSON LANDING RD DRUMMONDS TN 38023 USA "
    },
    {
      "Location ID": "38024",
      "Location Name": "DYERSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 309 N CHURCH AVE DYERSBURG TN 38024 USA "
    },
    {
      "Location ID": "38028",
      "Location Name": "EADS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2824 JEFFERSON EADS TN 38028 USA "
    },
    {
      "Location ID": "38034",
      "Location Name": "FRIENDSHIP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 143 POPLAR ST FRIENDSHIP TN 38034 USA "
    },
    {
      "Location ID": "38037",
      "Location Name": "GATES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 76 HENNING ST GATES TN 38037 USA "
    },
    {
      "Location ID": "38039",
      "Location Name": "GRAND JUNCTION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 152 CHARLESTON ROW E GRAND JUNCTION TN 38039 USA "
    },
    {
      "Location ID": "38040",
      "Location Name": "HALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 320 SHARP ST HALLS TN 38040 USA "
    },
    {
      "Location ID": "38041",
      "Location Name": "HENNING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 415 N MAIN ST HENNING TN 38041 USA "
    },
    {
      "Location ID": "38042",
      "Location Name": "HICKORY VALLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7065 HIGHWAY 18 S HICKORY VALLEY TN 38042 USA "
    },
    {
      "Location ID": "38044",
      "Location Name": "HORNSBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 475 MAIN ST HORNSBY TN 38044 USA "
    },
    {
      "Location ID": "38045",
      "Location Name": "LACONIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2430 LACONIA RD LACONIA TN 38045 USA "
    },
    {
      "Location ID": "38046",
      "Location Name": "LA GRANGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 30 MAIN ST LA GRANGE TN 38046 USA "
    },
    {
      "Location ID": "38049",
      "Location Name": "MASON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 361 HIGHWAY 70 W MASON TN 38049 USA "
    },
    {
      "Location ID": "38050",
      "Location Name": "MAURY CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 N BROADWAY ST MAURY CITY TN 38050 USA "
    },
    {
      "Location ID": "38052",
      "Location Name": "MIDDLETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 296 S MAIN ST MIDDLETON TN 38052 USA "
    },
    {
      "Location ID": "38057",
      "Location Name": "MOSCOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 90 4TH AVE MOSCOW TN 38057 USA "
    },
    {
      "Location ID": "38058",
      "Location Name": "MUNFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 25 BASS ST MUNFORD TN 38058 USA "
    },
    {
      "Location ID": "38060",
      "Location Name": "OAKLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14695 HIGHWAY 194 OAKLAND TN 38060 USA "
    },
    {
      "Location ID": "38061",
      "Location Name": "POCAHONTAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 22350 HIGHWAY 57 W POCAHONTAS TN 38061 USA "
    },
    {
      "Location ID": "38063",
      "Location Name": "RIPLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 E JACKSON AVE RIPLEY TN 38063 USA "
    },
    {
      "Location ID": "38066",
      "Location Name": "ROSSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 MAIN ST ROSSVILLE TN 38066 USA "
    },
    {
      "Location ID": "38067",
      "Location Name": "SAULSBURY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5911 HIGHWAY 57 SAULSBURY TN 38067 USA "
    },
    {
      "Location ID": "38068",
      "Location Name": "SOMERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 W NORTH ST SOMERVILLE TN 38068 USA "
    },
    {
      "Location ID": "38070",
      "Location Name": "TIGRETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 179 STOKES RD TIGRETT TN 38070 USA "
    },
    {
      "Location ID": "38071",
      "Location Name": "TIPTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2520 TIPTON RD TIPTON TN 38071 USA "
    },
    {
      "Location ID": "38075",
      "Location Name": "WHITEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 W MAIN ST WHITEVILLE TN 38075 USA "
    },
    {
      "Location ID": "38077",
      "Location Name": "WYNNBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5 1ST ST WYNNBURG TN 38077 USA "
    },
    {
      "Location ID": "38079",
      "Location Name": "TIPTONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 404 CHURCH ST TIPTONVILLE TN 38079 USA "
    },
    {
      "Location ID": "38080",
      "Location Name": "RIDGELY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 LAKE ST RIDGELY TN 38080 USA "
    },
    {
      "Location ID": "380FZ",
      "Location Name": "LOG MEMPHIS TN PSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3456 MEYERS RD MEMPHIS TN 38108 USA "
    },
    {
      "Location ID": "380PX",
      "Location Name": "LOG JET COVE ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3300 JET CV STE 10 MEMPHIS TN 38118 USA "
    },
    {
      "Location ID": "38118",
      "Location Name": "MEMPHIS AIRPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4233 LOUIS CARRUTHERS DR MEMPHIS TN 38130 USA "
    },
    {
      "Location ID": "38130",
      "Location Name": "MEMPHIS AMC BOX SECTION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4233 LOUIS CARRUTHERS DR MEMPHIS TN 38130 USA "
    },
    {
      "Location ID": "381AZ",
      "Location Name": "AMAZON MEM5 (MEMPHIS TN)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5155 CITATION DR MEMPHIS TN 38118-7830 USA "
    },
    {
      "Location ID": "381LA",
      "Location Name": "NIKE - SD1",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5155 LAMAR AVE MEMPHIS TN 38118 USA "
    },
    {
      "Location ID": "381MP",
      "Location Name": "MEMPHIS MPA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4550 SWINNEA RD MEMPHIS TN 38118 USA "
    },
    {
      "Location ID": "381NI",
      "Location Name": "NIKE INC - NALC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3699 SWOOSH DR MEMPHIS TN 38128 USA "
    },
    {
      "Location ID": "382",
      "Location Name": "MC KENZIE TN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15075 HIGHLAND DR MC KENZIE TN 38201 USA "
    },
    {
      "Location ID": "38220",
      "Location Name": "ATWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1570 E MAIN ST ATWOOD TN 38220 USA "
    },
    {
      "Location ID": "38221",
      "Location Name": "BIG SANDY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 45 DEPOT ST BIG SANDY TN 38221 USA "
    },
    {
      "Location ID": "38222",
      "Location Name": "BUCHANAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15 CLAYTONTOWN RD BUCHANAN TN 38222 USA "
    },
    {
      "Location ID": "38223",
      "Location Name": "COMO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10506 HIGHWAY 54 COMO TN 38223 USA "
    },
    {
      "Location ID": "38224",
      "Location Name": "COTTAGE GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7100 MAIN ST COTTAGE GROVE TN 38224 USA "
    },
    {
      "Location ID": "38226",
      "Location Name": "DUKEDOM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3640 STATE LINE RD DUKEDOM TN 38226 USA "
    },
    {
      "Location ID": "38231",
      "Location Name": "HENRY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 191 ATLANTIC AVE HENRY TN 38231 USA "
    },
    {
      "Location ID": "38232",
      "Location Name": "HORNBEAK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 S MAIN ST HORNBEAK TN 38232 USA "
    },
    {
      "Location ID": "38235",
      "Location Name": "MC LEMORESVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5605 MAIN ST MC LEMORESVILLE TN 38235 USA "
    },
    {
      "Location ID": "38236",
      "Location Name": "MANSFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2154 MANSFIELD RD MANSFIELD TN 38236 USA "
    },
    {
      "Location ID": "38237",
      "Location Name": "MARTIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 UNIVERSITY ST MARTIN TN 38237 USA "
    },
    {
      "Location ID": "38241",
      "Location Name": "PALMERSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6180 HIGHWAY 89 PALMERSVILLE TN 38241 USA "
    },
    {
      "Location ID": "38251",
      "Location Name": "PURYEAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 W FRONT ST PURYEAR TN 38251 USA "
    },
    {
      "Location ID": "38253",
      "Location Name": "RIVES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 498 S FRONT ST RIVES TN 38253 USA "
    },
    {
      "Location ID": "38254",
      "Location Name": "SAMBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5466 W HIGHWAY 22 S SAMBURG TN 38254 USA "
    },
    {
      "Location ID": "38256",
      "Location Name": "SPRINGVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7935 HIGHWAY 69 S SPRINGVILLE TN 38256 USA "
    },
    {
      "Location ID": "38258",
      "Location Name": "TREZEVANT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5580 BROAD ST N TREZEVANT TN 38258 USA "
    },
    {
      "Location ID": "38260",
      "Location Name": "TROY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 W HARPER ST TROY TN 38260 USA "
    },
    {
      "Location ID": "38261",
      "Location Name": "UNION CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 W WASHINGTON AVE UNION CITY TN 38261 USA "
    },
    {
      "Location ID": "38271",
      "Location Name": "WOODLAND MILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 179 N THOMPSON ST WOODLAND MILLS TN 38271 USA "
    },
    {
      "Location ID": "383",
      "Location Name": "JACKSON TN CS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 DR MARTIN LUTHER KING JR D JACKSON TN 38301 USA "
    },
    {
      "Location ID": "38305",
      "Location Name": "NORTH JACKSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 161 W UNIVERSITY PKWY JACKSON TN 38305 USA "
    },
    {
      "Location ID": "38339",
      "Location Name": "GUYS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1845 GUYS CHEWALLA RD GUYS TN 38339 USA "
    },
    {
      "Location ID": "38358",
      "Location Name": "MILAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1170 S MAIN ST MILAN TN 38358 USA "
    },
    {
      "Location ID": "38367",
      "Location Name": "RAMER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4283 HIGHWAY 57 W RAMER TN 38367 USA "
    },
    {
      "Location ID": "38375",
      "Location Name": "SELMER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 HOUSTON AVE SELMER TN 38375 USA "
    },
    {
      "Location ID": "384",
      "Location Name": "COLUMBIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 417 W 7TH ST COLUMBIA TN 38401 USA "
    },
    {
      "Location ID": "38449",
      "Location Name": "ARDMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 26050 MAIN ST ARDMORE TN 38449 USA "
    },
    {
      "Location ID": "38464",
      "Location Name": "LAWRENCEBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 218 N MILITARY AVE LAWRENCEBURG TN 38464 USA "
    },
    {
      "Location ID": "38478",
      "Location Name": "PULASKI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 W COLLEGE ST PULASKI TN 38478 USA "
    },
    {
      "Location ID": "38485",
      "Location Name": "WAYNESBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 422 S MAIN ST WAYNESBORO TN 38485 USA "
    },
    {
      "Location ID": "385",
      "Location Name": "COOKEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 S WILLOW AVE COOKEVILLE TN 38501 USA "
    },
    {
      "Location ID": "38555",
      "Location Name": "CROSSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 240 OLD JAMESTOWN HWY CROSSVILLE TN 38555 USA "
    },
    {
      "Location ID": "385AS",
      "Location Name": "ACADEMY SPORTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4500 ACADEMY RD COOKEVILLE TN 38506 USA "
    },
    {
      "Location ID": "38601",
      "Location Name": "ABBEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8 E LONG ST ABBEVILLE MS 38601 USA "
    },
    {
      "Location ID": "38603",
      "Location Name": "ASHLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16010 BOUNDARY DR ASHLAND MS 38603 USA "
    },
    {
      "Location ID": "38606",
      "Location Name": "BATESVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 375 LAKEWOOD DR BATESVILLE MS 38606 USA "
    },
    {
      "Location ID": "38610",
      "Location Name": "BLUE MOUNTAIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 N GUYTON BLVD BLUE MOUNTAIN MS 38610 USA "
    },
    {
      "Location ID": "38611",
      "Location Name": "BYHALIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8140 HIGHWAY 178 BYHALIA MS 38611 USA "
    },
    {
      "Location ID": "38614",
      "Location Name": "CLARKSDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 236 SHARKEY AVE RM 100 CLARKSDALE MS 38614 USA "
    },
    {
      "Location ID": "38617",
      "Location Name": "COAHOMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 W WALNUT ST COAHOMA MS 38617 USA "
    },
    {
      "Location ID": "38618",
      "Location Name": "COLDWATER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 686 2ND ST COLDWATER MS 38618 USA "
    },
    {
      "Location ID": "38619",
      "Location Name": "COMO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 N MAIN ST COMO MS 38619 USA "
    },
    {
      "Location ID": "38620",
      "Location Name": "COURTLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 252 OLD HIGHWAY 51 COURTLAND MS 38620 USA "
    },
    {
      "Location ID": "38621",
      "Location Name": "CRENSHAW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 609 BROAD ST CRENSHAW MS 38621 USA "
    },
    {
      "Location ID": "38622",
      "Location Name": "CROWDER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 131A 3RD ST CROWDER MS 38622 USA "
    },
    {
      "Location ID": "38623",
      "Location Name": "DARLING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 580 DARLING RD DARLING MS 38623 USA "
    },
    {
      "Location ID": "38626",
      "Location Name": "DUNDEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1028 RYALS RD DUNDEE MS 38626 USA "
    },
    {
      "Location ID": "38628",
      "Location Name": "FALCON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 NEVILS RD FALCON MS 38628 USA "
    },
    {
      "Location ID": "38629",
      "Location Name": "FALKNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20610 HIGHWAY 15 FALKNER MS 38629 USA "
    },
    {
      "Location ID": "38631",
      "Location Name": "FRIARS POINT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 774 2ND ST FRIARS POINT MS 38631 USA "
    },
    {
      "Location ID": "38632",
      "Location Name": "HERNANDO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12 W COMMERCE ST HERNANDO MS 38632 USA "
    },
    {
      "Location ID": "38633",
      "Location Name": "HICKORY FLAT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 535 HIGHWAY 5 HICKORY FLAT MS 38633 USA "
    },
    {
      "Location ID": "38634",
      "Location Name": "IDA B WELLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 365 MARTIN LUTHER KING JR DR HOLLY SPRINGS MS 38635 USA "
    },
    {
      "Location ID": "38635",
      "Location Name": "HOLLY SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 N MEMPHIS ST HOLLY SPRINGS MS 38635 USA "
    },
    {
      "Location ID": "38637",
      "Location Name": "HORN LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3724 GOODMAN RD W HORN LAKE MS 38637 USA "
    },
    {
      "Location ID": "38639",
      "Location Name": "JONESTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 195 LONGINO ST JONESTOWN MS 38639 USA "
    },
    {
      "Location ID": "38641",
      "Location Name": "LAKE CORMORANT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12744 STAR LANDING RD W LAKE CORMORANT MS 38641 USA "
    },
    {
      "Location ID": "38642",
      "Location Name": "LAMAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10464 LAMAR RD LAMAR MS 38642 USA "
    },
    {
      "Location ID": "38643",
      "Location Name": "LAMBERT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 700 COVINGTON AVE LAMBERT MS 38643 USA "
    },
    {
      "Location ID": "38644",
      "Location Name": "LULA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 SECOND ST LULA MS 38644 USA "
    },
    {
      "Location ID": "38645",
      "Location Name": "LYON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 PARK ST LYON MS 38645 USA "
    },
    {
      "Location ID": "38646",
      "Location Name": "MARKS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 423 POPLAR ST MARKS MS 38646 USA "
    },
    {
      "Location ID": "38647",
      "Location Name": "MICHIGAN CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 73 MAIN ST MICHIGAN CITY MS 38647 USA "
    },
    {
      "Location ID": "38650",
      "Location Name": "MYRTLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1183 SPRINGDALE AVE MYRTLE MS 38650 USA "
    },
    {
      "Location ID": "38651",
      "Location Name": "NESBIT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 600 OLD HIGHWAY 51 N NESBIT MS 38651 USA "
    },
    {
      "Location ID": "38652",
      "Location Name": "NEW ALBANY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 801 E BANKHEAD ST NEW ALBANY MS 38652 USA "
    },
    {
      "Location ID": "38655",
      "Location Name": "OXFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 MCELROY DR OXFORD MS 38655 USA "
    },
    {
      "Location ID": "38658",
      "Location Name": "POPE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 774 MAIN ST POPE MS 38658 USA "
    },
    {
      "Location ID": "38659",
      "Location Name": "POTTS CAMP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24 S CENTER ST POTTS CAMP MS 38659 USA "
    },
    {
      "Location ID": "38661",
      "Location Name": "RED BANKS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 406 YARBROUGH LOOP RED BANKS MS 38661 USA "
    },
    {
      "Location ID": "38663",
      "Location Name": "RIPLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 919 CITY AVE S RIPLEY MS 38663 USA "
    },
    {
      "Location ID": "38664",
      "Location Name": "ROBINSONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11457 OLD HIGHWAY 61 N ROBINSONVILLE MS 38664 USA "
    },
    {
      "Location ID": "38665",
      "Location Name": "SARAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1353 SARAH RD SARAH MS 38665 USA "
    },
    {
      "Location ID": "38666",
      "Location Name": "SARDIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 S MAIN ST SARDIS MS 38666 USA "
    },
    {
      "Location ID": "38668",
      "Location Name": "SENATOBIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 W MAIN ST SENATOBIA MS 38668 USA "
    },
    {
      "Location ID": "38669",
      "Location Name": "SHERARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 50 HIGHWAY 322 SHERROD RD CLARKSDALE MS 38669 USA "
    },
    {
      "Location ID": "38670",
      "Location Name": "SLEDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 WILLIAMS AVE SLEDGE MS 38670 USA "
    },
    {
      "Location ID": "38671",
      "Location Name": "SOUTHAVEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7550 AIRWAYS BLVD SOUTHAVEN MS 38671 USA "
    },
    {
      "Location ID": "38674",
      "Location Name": "TIPLERSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24440 HIGHWAY 15 TIPLERSVILLE MS 38674 USA "
    },
    {
      "Location ID": "38676",
      "Location Name": "TUNICA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 912 MAGNOLIA ST TUNICA MS 38676 USA "
    },
    {
      "Location ID": "38679",
      "Location Name": "VICTORIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5764 HIGHWAY 178 W VICTORIA MS 38679 USA "
    },
    {
      "Location ID": "38680",
      "Location Name": "WALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6380 HIGHWAY 161 WALLS MS 38680 USA "
    },
    {
      "Location ID": "38683",
      "Location Name": "WALNUT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 50 HIGHWAY 354 WALNUT MS 38683 USA "
    },
    {
      "Location ID": "38685",
      "Location Name": "WATERFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9 BETHLEHEM RD WATERFORD MS 38685 USA "
    },
    {
      "Location ID": "386AD",
      "Location Name": "ADAPT - BYHALIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 165 WINGO RD BYHALIA MS 38611 USA "
    },
    {
      "Location ID": "386AZ",
      "Location Name": "AMAZON.COM INC-MEM3 - HORN LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1615 COMMERCE PKWY N HORN LAKE MS 38637-2313 USA "
    },
    {
      "Location ID": "386CG",
      "Location Name": "CIRCLE G TRACTOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 842 HIGHWAY 72 LAMAR MS 38642 USA "
    },
    {
      "Location ID": "386RR",
      "Location Name": "LSC COMMUNICATIONS - SENATOBIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 MATTHEWS DR SENATOBIA MS 38668 USA "
    },
    {
      "Location ID": "386SP",
      "Location Name": "FEDEX SMART POST - SOUTHAVEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4325 EXECUTIVE DR STE 200 SOUTHAVEN MS 38672 USA "
    },
    {
      "Location ID": "387",
      "Location Name": "GREENVILLE MS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 MAIN ST STE 154 GREENVILLE MS 38701 USA "
    },
    {
      "Location ID": "38703",
      "Location Name": "CROSSROADS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1910 HIGHWAY 82 E GREENVILLE MS 38703 USA "
    },
    {
      "Location ID": "38720",
      "Location Name": "ALLIGATOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5 MAIN ST ALLIGATOR MS 38720 USA "
    },
    {
      "Location ID": "38721",
      "Location Name": "ANGUILLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 HOLLAND ST ANGUILLA MS 38721 USA "
    },
    {
      "Location ID": "38722",
      "Location Name": "ARCOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101B TOWER LN ARCOLA MS 38722 USA "
    },
    {
      "Location ID": "38725",
      "Location Name": "BENOIT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 PRESTON ST BENOIT MS 38725 USA "
    },
    {
      "Location ID": "38726",
      "Location Name": "BEULAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 N CLARK ST BEULAH MS 38726 USA "
    },
    {
      "Location ID": "38730",
      "Location Name": "BOYLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 T M JONES HWY BOYLE MS 38730 USA "
    },
    {
      "Location ID": "38732",
      "Location Name": "CLEVELAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 S CHRISMAN AVE CLEVELAND MS 38732 USA "
    },
    {
      "Location ID": "38740",
      "Location Name": "DUNCAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 E MAIN ST DUNCAN MS 38740 USA "
    },
    {
      "Location ID": "38746",
      "Location Name": "GUNNISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 514 RAILROAD AVE GUNNISON MS 38746 USA "
    },
    {
      "Location ID": "38748",
      "Location Name": "HOLLANDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 EAST AVE S HOLLANDALE MS 38748 USA "
    },
    {
      "Location ID": "38751",
      "Location Name": "INDIANOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 W PERCY ST INDIANOLA MS 38751 USA "
    },
    {
      "Location ID": "38753",
      "Location Name": "INVERNESS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 THIRD ST INVERNESS MS 38753 USA "
    },
    {
      "Location ID": "38754",
      "Location Name": "ISOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 JULIA ST ISOLA MS 38754 USA "
    },
    {
      "Location ID": "38756",
      "Location Name": "LELAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 N BROAD ST LELAND MS 38756 USA "
    },
    {
      "Location ID": "38759",
      "Location Name": "MERIGOLD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 E GOFF ST MERIGOLD MS 38759 USA "
    },
    {
      "Location ID": "38760",
      "Location Name": "METCALFE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 METCALFE RD METCALFE MS 38760 USA "
    },
    {
      "Location ID": "38761",
      "Location Name": "MOORHEAD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 E DELTA AVE MOORHEAD MS 38761 USA "
    },
    {
      "Location ID": "38762",
      "Location Name": "MOUND BAYOU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 S EDWARDS AVE MOUND BAYOU MS 38762 USA "
    },
    {
      "Location ID": "38764",
      "Location Name": "PACE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 329 JENNY WASHINGTON RD PACE MS 38764 USA "
    },
    {
      "Location ID": "38765",
      "Location Name": "PANTHER BURN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 83 PANTHER BURN RD PANTHER BURN MS 38765 USA "
    },
    {
      "Location ID": "38767",
      "Location Name": "RENA LARA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3291 HIGHWAY 1 RENA LARA MS 38767 USA "
    },
    {
      "Location ID": "38769",
      "Location Name": "ROSEDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 700 FRONT ST ROSEDALE MS 38769 USA "
    },
    {
      "Location ID": "38772",
      "Location Name": "SCOTT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 COTTON ROW SCOTT MS 38772 USA "
    },
    {
      "Location ID": "38773",
      "Location Name": "SHAW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 FAISON ST SHAW MS 38773 USA "
    },
    {
      "Location ID": "38774",
      "Location Name": "SHELBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 304 SECOND AVE SHELBY MS 38774 USA "
    },
    {
      "Location ID": "38776",
      "Location Name": "STONEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4845 OLD LELAND RD STONEVILLE MS 38776 USA "
    },
    {
      "Location ID": "38780",
      "Location Name": "WAYSIDE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3501 HIGHWAY 1 S WAYSIDE MS 38780 USA "
    },
    {
      "Location ID": "38781",
      "Location Name": "WINSTONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 E WINSTON ST WINSTONVILLE MS 38781 USA "
    },
    {
      "Location ID": "388",
      "Location Name": "TUPELO MS CS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 362 S THOMAS ST TUPELO MS 38801 USA "
    },
    {
      "Location ID": "38820",
      "Location Name": "ALGOMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5 MAIN AVE ALGOMA MS 38820 USA "
    },
    {
      "Location ID": "38826",
      "Location Name": "BELDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3871 MCCULLOUGH BLVD BELDEN MS 38826 USA "
    },
    {
      "Location ID": "38827",
      "Location Name": "BELMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 363 2ND ST BELMONT MS 38827 USA "
    },
    {
      "Location ID": "38828",
      "Location Name": "BLUE SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1146 HIGHWAY 9 S BLUE SPRINGS MS 38828 USA "
    },
    {
      "Location ID": "38829",
      "Location Name": "BOONEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 515 N 2ND ST BOONEVILLE MS 38829 USA "
    },
    {
      "Location ID": "38833",
      "Location Name": "BURNSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 318 HIGHWAY 72 BURNSVILLE MS 38833 USA "
    },
    {
      "Location ID": "38834",
      "Location Name": "CORINTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 N MADISON ST CORINTH MS 38834 USA "
    },
    {
      "Location ID": "38838",
      "Location Name": "DENNIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 744 HIGHWAY 4 DENNIS MS 38838 USA "
    },
    {
      "Location ID": "38841",
      "Location Name": "ECRU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 239 MAIN ST ECRU MS 38841 USA "
    },
    {
      "Location ID": "38846",
      "Location Name": "GLEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2430 HIGHWAY 72 E GLEN MS 38846 USA "
    },
    {
      "Location ID": "38847",
      "Location Name": "GOLDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 195 FRONT ST GOLDEN MS 38847 USA "
    },
    {
      "Location ID": "38850",
      "Location Name": "HOULKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 WALKER ST HOULKA MS 38850 USA "
    },
    {
      "Location ID": "38852",
      "Location Name": "IUKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 801 W EASTPORT ST IUKA MS 38852 USA "
    },
    {
      "Location ID": "38863",
      "Location Name": "PONTOTOC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 HIGHWAY 15 N PONTOTOC MS 38863 USA "
    },
    {
      "Location ID": "38864",
      "Location Name": "RANDOLPH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 213 RANDOLPH LOOP RANDOLPH MS 38864 USA "
    },
    {
      "Location ID": "38865",
      "Location Name": "RIENZI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 73 S FRONT ST RIENZI MS 38865 USA "
    },
    {
      "Location ID": "38869",
      "Location Name": "SHERMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 65 E MAIN ST SHERMAN MS 38869 USA "
    },
    {
      "Location ID": "38873",
      "Location Name": "TISHOMINGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9 NATCHEZ ST TISHOMINGO MS 38873 USA "
    },
    {
      "Location ID": "389",
      "Location Name": "GRENADA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2500 GATEWAY ST GRENADA MS 38901 USA "
    },
    {
      "Location ID": "38941",
      "Location Name": "ITTA BENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 DEWEY ST ITTA BENA MS 38941 USA "
    },
    {
      "Location ID": "38946",
      "Location Name": "MORGAN CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 DELTA ST MORGAN CITY MS 38946 USA "
    },
    {
      "Location ID": "38950",
      "Location Name": "PHILIPP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1277 MS HIGHWAY 8 PHILIPP MS 38950 USA "
    },
    {
      "Location ID": "38959",
      "Location Name": "SWIFTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100466 HIGHWAY 7 SWIFTOWN MS 38959 USA "
    },
    {
      "Location ID": "38962",
      "Location Name": "TIPPO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2309 TIPPO MACEL RD TIPPO MS 38962 USA "
    },
    {
      "Location ID": "38H",
      "Location Name": "MEMPHIS STC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1660 STATELINE RD E STE 1 SOUTHAVEN MS 38671 USA "
    },
    {
      "Location ID": "38Z",
      "Location Name": "LOG MEMPHIS NDC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1921 ELVIS PRESLEY BLVD MEMPHIS TN 38136 USA "
    },
    {
      "Location ID": "38ZAX",
      "Location Name": "JET COVE PEAK ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4300 NEW GETWELL RD MEMPHIS TN 38118 USA "
    },
    {
      "Location ID": "390",
      "Location Name": "LOG JACKSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 E SOUTH ST JACKSON MS 39201 USA "
    },
    {
      "Location ID": "39044",
      "Location Name": "BRAXTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 W MAIN ST BRAXTON MS 39044 USA "
    },
    {
      "Location ID": "39046",
      "Location Name": "CANTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 S UNION ST CANTON MS 39046 USA "
    },
    {
      "Location ID": "39059",
      "Location Name": "CRYSTAL SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 E MARION AVE CRYSTAL SPRINGS MS 39059 USA "
    },
    {
      "Location ID": "39067",
      "Location Name": "ETHEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2112 MAIN ST ETHEL MS 39067 USA "
    },
    {
      "Location ID": "39074",
      "Location Name": "FOREST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 313 E SECOND ST FOREST MS 39074 USA "
    },
    {
      "Location ID": "39077",
      "Location Name": "GALLMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20140 S HIGHWAY 51 GALLMAN MS 39077 USA "
    },
    {
      "Location ID": "39080",
      "Location Name": "HARPERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9190 OLD HILLSBORO RD HARPERVILLE MS 39080 USA "
    },
    {
      "Location ID": "39083",
      "Location Name": "HAZLEHURST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 CALDWELL DR HAZLEHURST MS 39083 USA "
    },
    {
      "Location ID": "39087",
      "Location Name": "HILLSBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 61 HILLSBORO LUDLOW RD HILLSBORO MS 39087 USA "
    },
    {
      "Location ID": "39090",
      "Location Name": "KOSCIUSKO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 65 VETERANS MEMORIAL DR KOSCIUSKO MS 39090 USA "
    },
    {
      "Location ID": "39092",
      "Location Name": "LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7621 STEVE LEE DR LAKE MS 39092 USA "
    },
    {
      "Location ID": "39108",
      "Location Name": "MC COOL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3258 HIGHWAY 407 MC COOL MS 39108 USA "
    },
    {
      "Location ID": "39109",
      "Location Name": "MADDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12424 HIGHWAY 488 MADDEN MS 39109 USA "
    },
    {
      "Location ID": "39110",
      "Location Name": "MADISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 990 HIGHWAY 51 MADISON MS 39110 USA "
    },
    {
      "Location ID": "39111",
      "Location Name": "MAGEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 701 3RD ST SW MAGEE MS 39111 USA "
    },
    {
      "Location ID": "39114",
      "Location Name": "MENDENHALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1658 SIMPSON HIGHWAY 149 MENDENHALL MS 39114 USA "
    },
    {
      "Location ID": "39116",
      "Location Name": "MIZE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 N OAK ST MIZE MS 39116 USA "
    },
    {
      "Location ID": "39117",
      "Location Name": "MORTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 128 HIGHWAY 481 S MORTON MS 39117 USA "
    },
    {
      "Location ID": "39119",
      "Location Name": "MOUNT OLIVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 408 S MAIN ST MOUNT OLIVE MS 39119 USA "
    },
    {
      "Location ID": "39148",
      "Location Name": "PINEY WOODS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11 WARD LN PINEY WOODS MS 39148 USA "
    },
    {
      "Location ID": "39151",
      "Location Name": "PUCKETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1086 HIGHWAY 13 N PUCKETT MS 39151 USA "
    },
    {
      "Location ID": "39152",
      "Location Name": "PULASKI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7052 HIGHWAY 481 S PULASKI MS 39152 USA "
    },
    {
      "Location ID": "39153",
      "Location Name": "RALEIGH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 MAIN ST RALEIGH MS 39153 USA "
    },
    {
      "Location ID": "39157",
      "Location Name": "RIDGELAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 611 S PEAR ORCHARD RD RIDGELAND MS 39157 USA "
    },
    {
      "Location ID": "39167",
      "Location Name": "STAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 339 MANGUM DR STAR MS 39167 USA "
    },
    {
      "Location ID": "39168",
      "Location Name": "TAYLORSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 750 HIGHWAY 28 E TAYLORSVILLE MS 39168 USA "
    },
    {
      "Location ID": "39189",
      "Location Name": "WALNUT GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 143 MAIN ST WALNUT GROVE MS 39189 USA "
    },
    {
      "Location ID": "39191",
      "Location Name": "WESSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2072 HIGHWAY 51 WESSON MS 39191 USA "
    },
    {
      "Location ID": "39194",
      "Location Name": "YAZOO CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 341 N MAIN ST YAZOO CITY MS 39194 USA "
    },
    {
      "Location ID": "39201",
      "Location Name": "JACKSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 E SOUTH ST JACKSON MS 39201 USA "
    },
    {
      "Location ID": "393",
      "Location Name": "MERIDIAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2100 9TH ST MERIDIAN MS 39301 USA "
    },
    {
      "Location ID": "39305",
      "Location Name": "NORTH MERIDIAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5008 29TH AVE MERIDIAN MS 39305 USA "
    },
    {
      "Location ID": "39307",
      "Location Name": "WEST MERIDIAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 708 45TH AVE MERIDIAN MS 39307 USA "
    },
    {
      "Location ID": "39320",
      "Location Name": "BAILEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9032 HIGHWAY 493 BAILEY MS 39320 USA "
    },
    {
      "Location ID": "39322",
      "Location Name": "BUCKATUNNA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2360 BUCKATUNNA CHICORA CLARA RD BUCKATUNNA MS 39322 USA "
    },
    {
      "Location ID": "39324",
      "Location Name": "CLARA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 21 CLARA SCHOOL RD CLARA MS 39324 USA "
    },
    {
      "Location ID": "39325",
      "Location Name": "COLLINSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9061 COLLINSVILLE RD COLLINSVILLE MS 39325 USA "
    },
    {
      "Location ID": "39326",
      "Location Name": "DALEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11039 HIGHWAY 39 N DALEVILLE MS 39326 USA "
    },
    {
      "Location ID": "39328",
      "Location Name": "DE KALB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 147 VETERAN ST DE KALB MS 39328 USA "
    },
    {
      "Location ID": "39337",
      "Location Name": "LITTLE ROCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 45 LITTLE ROCK COUNTY LINE RD LITTLE ROCK MS 39337 USA "
    },
    {
      "Location ID": "39354",
      "Location Name": "PRESTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10816 HIGHWAY 397 PRESTON MS 39354 USA "
    },
    {
      "Location ID": "39355",
      "Location Name": "QUITMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 S ARCHUSA AVE QUITMAN MS 39355 USA "
    },
    {
      "Location ID": "39359",
      "Location Name": "SEBASTOPOL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16811 HIGHWAY 21 N SEBASTOPOL MS 39359 USA "
    },
    {
      "Location ID": "39360",
      "Location Name": "SHUBUTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 160 S HIGH ST SHUBUTA MS 39360 USA "
    },
    {
      "Location ID": "39362",
      "Location Name": "STATE LINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 240 MAIN ST STATE LINE MS 39362 USA "
    },
    {
      "Location ID": "39365",
      "Location Name": "UNION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 BANK ST UNION MS 39365 USA "
    },
    {
      "Location ID": "39367",
      "Location Name": "WAYNESBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 704 AZALEA DR WAYNESBORO MS 39367 USA "
    },
    {
      "Location ID": "393BF",
      "Location Name": "BETHEL FRAME SHOP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 225 HIGHWAY 492 SEBASTOPOL MS 39359 USA "
    },
    {
      "Location ID": "394",
      "Location Name": "HATTIESBURG MS CS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 S 40TH AVE HATTIESBURG MS 39402 USA "
    },
    {
      "Location ID": "39429",
      "Location Name": "COLUMBIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 815 MAIN ST COLUMBIA MS 39429 USA "
    },
    {
      "Location ID": "39452",
      "Location Name": "LUCEDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 COX ST LUCEDALE MS 39452 USA "
    },
    {
      "Location ID": "39464",
      "Location Name": "OVETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3227 OVETT MOSELLE RD OVETT MS 39464 USA "
    },
    {
      "Location ID": "39465",
      "Location Name": "PETAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 MORRIS ST PETAL MS 39465 USA "
    },
    {
      "Location ID": "39476",
      "Location Name": "RICHTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 FRONT ST RICHTON MS 39476 USA "
    },
    {
      "Location ID": "39478",
      "Location Name": "SANDY HOOK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 480 JOHN FORD HOME RD SANDY HOOK MS 39478 USA "
    },
    {
      "Location ID": "39482",
      "Location Name": "SUMRALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4862 HIGHWAY 589 SUMRALL MS 39482 USA "
    },
    {
      "Location ID": "39483",
      "Location Name": "FOXWORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 72 HIGHWAY 587 FOXWORTH MS 39483 USA "
    },
    {
      "Location ID": "394OG",
      "Location Name": "OAK GROVE CPU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5009 OLD HIGHWAY 11 HATTIESBURG MS 39402 USA "
    },
    {
      "Location ID": "395",
      "Location Name": "LOG GULFPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10285 CORPORATE DR GULFPORT MS 39503 USA "
    },
    {
      "Location ID": "396",
      "Location Name": "MCCOMB MS CS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 530 DELAWARE AVE MCCOMB MS 39648 USA "
    },
    {
      "Location ID": "39601",
      "Location Name": "BROOKHAVEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W CHEROKEE ST BROOKHAVEN MS 39601 USA "
    },
    {
      "Location ID": "39629",
      "Location Name": "BOGUE CHITTO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 409 MONTICELLO ST BOGUE CHITTO MS 39629 USA "
    },
    {
      "Location ID": "39631",
      "Location Name": "CENTREVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 310 E MAIN ST CENTREVILLE MS 39631 USA "
    },
    {
      "Location ID": "39632",
      "Location Name": "CHATAWA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1104 SAINT MARY DR CHATAWA MS 39632 USA "
    },
    {
      "Location ID": "39635",
      "Location Name": "FERNWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1007 CHERRY DR FERNWOOD MS 39635 USA "
    },
    {
      "Location ID": "39638",
      "Location Name": "GLOSTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 E MAIN ST GLOSTER MS 39638 USA "
    },
    {
      "Location ID": "39641",
      "Location Name": "JAYESS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 624 HIGHWAY 44 JAYESS MS 39641 USA "
    },
    {
      "Location ID": "39643",
      "Location Name": "KOKOMO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 364 OLD HIGHWAY 24 W KOKOMO MS 39643 USA "
    },
    {
      "Location ID": "39645",
      "Location Name": "LIBERTY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1010 E MAIN ST LIBERTY MS 39645 USA "
    },
    {
      "Location ID": "39648",
      "Location Name": "MCCOMB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 530 DELAWARE AVE MCCOMB MS 39648 USA "
    },
    {
      "Location ID": "39652",
      "Location Name": "MAGNOLIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 MAGNOLIA ST MAGNOLIA MS 39652 USA "
    },
    {
      "Location ID": "39654",
      "Location Name": "MONTICELLO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1141 W BROAD ST MONTICELLO MS 39654 USA "
    },
    {
      "Location ID": "39656",
      "Location Name": "OAK VALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 362 THIRD AVE OAK VALE MS 39656 USA "
    },
    {
      "Location ID": "39657",
      "Location Name": "OSYKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 W LIBERTY ST OSYKA MS 39657 USA "
    },
    {
      "Location ID": "39662",
      "Location Name": "RUTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3663 HIGHWAY 583 SE RUTH MS 39662 USA "
    },
    {
      "Location ID": "39663",
      "Location Name": "SILVER CREEK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 56 MAIN AVE SILVER CREEK MS 39663 USA "
    },
    {
      "Location ID": "39664",
      "Location Name": "SMITHDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6005 MS HIGHWAY 570 SMITHDALE MS 39664 USA "
    },
    {
      "Location ID": "39665",
      "Location Name": "SONTAG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 903 SONTAG NOLA RD SONTAG MS 39665 USA "
    },
    {
      "Location ID": "39666",
      "Location Name": "SUMMIT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 W ROBB ST SUMMIT MS 39666 USA "
    },
    {
      "Location ID": "39667",
      "Location Name": "TYLERTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 622 BEULAH AVE TYLERTOWN MS 39667 USA "
    },
    {
      "Location ID": "39669",
      "Location Name": "WOODVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 381 MAIN ST WOODVILLE MS 39669 USA "
    },
    {
      "Location ID": "396WS",
      "Location Name": "BROOKHAVEN (WEST STATION)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 407 BROOKHAVEN STREET BROOKHAVEN MS 39601 USA "
    },
    {
      "Location ID": "397",
      "Location Name": "COLUMBUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3202 BLUECUTT RD COLUMBUS MS 39705 USA "
    },
    {
      "Location ID": "39701",
      "Location Name": "DOWNTOWN COLUMBUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 524 MAIN ST COLUMBUS MS 39701 USA "
    },
    {
      "Location ID": "39705",
      "Location Name": "COLUMBUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3202 BLUECUTT RD COLUMBUS MS 39705 USA "
    },
    {
      "Location ID": "39730",
      "Location Name": "ABERDEEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 W COMMERCE ST ABERDEEN MS 39730 USA "
    },
    {
      "Location ID": "39740",
      "Location Name": "CALEDONIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 650 MAIN ST CALEDONIA MS 39740 USA "
    },
    {
      "Location ID": "39741",
      "Location Name": "CEDARBLUFF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15363 HIGHWAY 50 W CEDARBLUFF MS 39741 USA "
    },
    {
      "Location ID": "39746",
      "Location Name": "HAMILTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 40462 HIGHWAY 45 S HAMILTON MS 39746 USA "
    },
    {
      "Location ID": "39750",
      "Location Name": "MABEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 157 WATER ST MABEN MS 39750 USA "
    },
    {
      "Location ID": "39751",
      "Location Name": "MANTEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16 FIRST ST MANTEE MS 39751 USA "
    },
    {
      "Location ID": "39755",
      "Location Name": "PHEBA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N BEASLEY RD PHEBA MS 39755 USA "
    },
    {
      "Location ID": "39756",
      "Location Name": "PRAIRIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10177 HIGHWAY 382 PRAIRIE MS 39756 USA "
    },
    {
      "Location ID": "39759",
      "Location Name": "STARKVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 822 TAYLOR ST STARKVILLE MS 39759 USA "
    },
    {
      "Location ID": "39766",
      "Location Name": "STEENS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 346 STEENS VERNON RD STEENS MS 39766 USA "
    },
    {
      "Location ID": "39773",
      "Location Name": "WEST POINT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 376 COMMERCE ST WEST POINT MS 39773 USA "
    },
    {
      "Location ID": "39776",
      "Location Name": "WOODLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 MARKET ST WOODLAND MS 39776 USA "
    },
    {
      "Location ID": "397BM",
      "Location Name": "B AND M POLES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1224 N ESHMAN AVE WEST POINT MS 39773 USA "
    },
    {
      "Location ID": "397MO",
      "Location Name": "WEST POINT PO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 26427 E MAIN ST WEST POINT MS 39773 USA "
    },
    {
      "Location ID": "39813",
      "Location Name": "ARLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 438 PIONEER RD NW ARLINGTON GA 39813 USA "
    },
    {
      "Location ID": "39823",
      "Location Name": "BLAKELY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2243 S MAIN ST BLAKELY GA 39823 USA "
    },
    {
      "Location ID": "39824",
      "Location Name": "BLUFFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 PINE ST N BLUFFTON GA 39824 USA "
    },
    {
      "Location ID": "39826",
      "Location Name": "BRONWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 122 GEISE ST S BRONWOOD GA 39826 USA "
    },
    {
      "Location ID": "39828",
      "Location Name": "CAIRO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 1ST ST SW CAIRO GA 39828 USA "
    },
    {
      "Location ID": "39832",
      "Location Name": "CEDAR SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 SHEFFIELD ST CEDAR SPRINGS GA 39832 USA "
    },
    {
      "Location ID": "39834",
      "Location Name": "CLIMAX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 W BROAD ST CLIMAX GA 39834 USA "
    },
    {
      "Location ID": "39836",
      "Location Name": "COLEMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2623 BAY ST COLEMAN GA 39836 USA "
    },
    {
      "Location ID": "39841",
      "Location Name": "DAMASCUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1290 SAMMONS ST DAMASCUS GA 39841 USA "
    },
    {
      "Location ID": "39842",
      "Location Name": "DAWSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 124 STONEWALL ST SE DAWSON GA 39842 USA "
    },
    {
      "Location ID": "39845",
      "Location Name": "DONALSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 W 2ND ST DONALSONVILLE GA 39845 USA "
    },
    {
      "Location ID": "39846",
      "Location Name": "EDISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19372 HARTFORD ST EDISON GA 39846 USA "
    },
    {
      "Location ID": "39851",
      "Location Name": "FORT GAINES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 203 HANCOCK ST S FORT GAINES GA 39851 USA "
    },
    {
      "Location ID": "39861",
      "Location Name": "JAKIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1145 N PEARL ST JAKIN GA 39861 USA "
    },
    {
      "Location ID": "39862",
      "Location Name": "LEARY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 59 COLLEY ST LEARY GA 39862 USA "
    },
    {
      "Location ID": "39866",
      "Location Name": "MORGAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11017 DICKEY ST MORGAN GA 39866 USA "
    },
    {
      "Location ID": "39877",
      "Location Name": "PARROTT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 OLD DAWSON RD PARROTT GA 39877 USA "
    },
    {
      "Location ID": "39897",
      "Location Name": "WHIGHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 W BROAD AVE WHIGHAM GA 39897 USA "
    },
    {
      "Location ID": "399",
      "Location Name": "INTERNAL REVENUE SERVICE CENTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4800 BUFORD HWY ATLANTA GA 30329 USA "
    },
    {
      "Location ID": "3BMEU",
      "Location Name": "BMEUA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5433 W. SLIGH AVE TAMPA FL 33634 USA "
    },
    {
      "Location ID": "400",
      "Location Name": "LOG LOUISVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1420 GARDINER LN LOUISVILLE KY 40231 USA "
    },
    {
      "Location ID": "40065",
      "Location Name": "SHELBYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 4TH ST SHELBYVILLE KY 40065 USA "
    },
    {
      "Location ID": "40076",
      "Location Name": "WADDY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3100 WADDY RD WADDY KY 40076 USA "
    },
    {
      "Location ID": "400CA",
      "Location Name": "LOUISVILLE KY CMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1391 DIXIE HWY LOUISVILLE KY 40210 USA "
    },
    {
      "Location ID": "400SR",
      "Location Name": "SHADY RAYS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 KINGBROOK PKWY STE 1 SIMPSONVILLE KY 40067 USA "
    },
    {
      "Location ID": "402AD",
      "Location Name": "ANNSHIRE ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4500 ANNSHIRE AVE LOUISVILLE KY 40213 USA "
    },
    {
      "Location ID": "402CA",
      "Location Name": "LOUISVILLE KY CHRISTMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 251 HILTON DR JEFFERSONVILLE IN 47130 USA "
    },
    {
      "Location ID": "402UP",
      "Location Name": "UPS PEAK SUPPLY CHAIN SOLUTIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4603 ALLMOND AVE STE 2 LOUISVILLE KY 40209 USA "
    },
    {
      "Location ID": "403",
      "Location Name": "LOG LEXINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1088 NANDINO BLVD LEXINGTON KY 40511 USA "
    },
    {
      "Location ID": "40310",
      "Location Name": "BURGIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 217 MAIN ST BURGIN KY 40310 USA "
    },
    {
      "Location ID": "40311",
      "Location Name": "CARLISLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 231 W MAIN ST CARLISLE KY 40311 USA "
    },
    {
      "Location ID": "40312",
      "Location Name": "CLAY CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 70 RIVER ST CLAY CITY KY 40312 USA "
    },
    {
      "Location ID": "40313",
      "Location Name": "CLEARFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 519 KY HIGHWAY 519 CLEARFIELD KY 40313 USA "
    },
    {
      "Location ID": "40319",
      "Location Name": "FARMERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7355 US HIGHWAY 60 W FARMERS KY 40319 USA "
    },
    {
      "Location ID": "40324",
      "Location Name": "GEORGETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 N BRADFORD LN GEORGETOWN KY 40324 USA "
    },
    {
      "Location ID": "40330",
      "Location Name": "HARRODSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 N MAIN ST HARRODSBURG KY 40330 USA "
    },
    {
      "Location ID": "40336",
      "Location Name": "IRVINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 230 MAIN ST IRVINE KY 40336 USA "
    },
    {
      "Location ID": "40339",
      "Location Name": "KEENE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 KEENE SOUTH ELKHORN RD KEENE KY 40339 USA "
    },
    {
      "Location ID": "40342",
      "Location Name": "LAWRENCEBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1269 FOX CREEK RD LAWRENCEBURG KY 40342 USA "
    },
    {
      "Location ID": "40348",
      "Location Name": "MILLERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 425 MAIN ST MILLERSBURG KY 40348 USA "
    },
    {
      "Location ID": "40351",
      "Location Name": "MOREHEAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 700 W 1ST ST MOREHEAD KY 40351 USA "
    },
    {
      "Location ID": "40353",
      "Location Name": "MOUNT STERLING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 W LOCUST ST MOUNT STERLING KY 40353 USA "
    },
    {
      "Location ID": "40356",
      "Location Name": "NICHOLASVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 410 S MAIN ST NICHOLASVILLE KY 40356 USA "
    },
    {
      "Location ID": "40358",
      "Location Name": "OLYMPIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5724 E HIGHWAY 36 OLYMPIA KY 40358 USA "
    },
    {
      "Location ID": "40359",
      "Location Name": "OWENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1065 HIGHWAY 127 N OWENTON KY 40359 USA "
    },
    {
      "Location ID": "40360",
      "Location Name": "OWINGSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 95 E MAIN ST OWINGSVILLE KY 40360 USA "
    },
    {
      "Location ID": "40361",
      "Location Name": "PARIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 W 8TH ST STE 105 PARIS KY 40361 USA "
    },
    {
      "Location ID": "40370",
      "Location Name": "SADIEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 131 MAIN ST SADIEVILLE KY 40370 USA "
    },
    {
      "Location ID": "40371",
      "Location Name": "SALT LICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 91 MAIN ST SALT LICK KY 40371 USA "
    },
    {
      "Location ID": "40372",
      "Location Name": "SALVISA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 355 MAIN ST SALVISA KY 40372 USA "
    },
    {
      "Location ID": "40380",
      "Location Name": "STANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 165 S SIPPLE ST STANTON KY 40380 USA "
    },
    {
      "Location ID": "40383",
      "Location Name": "VERSAILLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 149 FIELDVIEW DR VERSAILLES KY 40383 USA "
    },
    {
      "Location ID": "40385",
      "Location Name": "WACO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4045 NEW IRVINE RD WACO KY 40385 USA "
    },
    {
      "Location ID": "40390",
      "Location Name": "WILMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 E MAIN ST WILMORE KY 40390 USA "
    },
    {
      "Location ID": "40391",
      "Location Name": "WINCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 N MAIN ST WINCHESTER KY 40391 USA "
    },
    {
      "Location ID": "403TD",
      "Location Name": "THOROUGHBRED DIESEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4843 ROCKWELL RD WINCHESTER KY 40391 USA "
    },
    {
      "Location ID": "403WH",
      "Location Name": "WILLOW HILL SUPPLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4707 ROCKWELL RD WINCHESTER KY 40391 USA "
    },
    {
      "Location ID": "40403",
      "Location Name": "BEREA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 222 GLADES RD BEREA KY 40403 USA "
    },
    {
      "Location ID": "40422",
      "Location Name": "DANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 E MAIN ST DANVILLE KY 40422 USA "
    },
    {
      "Location ID": "40475",
      "Location Name": "RICHMOND CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 E WATER ST RICHMOND KY 40475 USA "
    },
    {
      "Location ID": "40484",
      "Location Name": "STANFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 CANE RUN RD STANFORD KY 40484 USA "
    },
    {
      "Location ID": "404PS",
      "Location Name": "RICHMOND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 140 N MADISON AVE RICHMOND KY 40475 USA "
    },
    {
      "Location ID": "404RR",
      "Location Name": "LSC COMM DANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3201 LEBANON RD DANVILLE KY 40422 USA "
    },
    {
      "Location ID": "40601",
      "Location Name": "FRANKFORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1210 WILKINSON BLVD FRANKFORT KY 40601 USA "
    },
    {
      "Location ID": "406DI",
      "Location Name": "DIECAST DIRECT INCORPORATED",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3005 OLD LAWRENCEBURG RD FRANKFORT KY 40601 USA "
    },
    {
      "Location ID": "407",
      "Location Name": "LONDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1760 HIGHWAY 192 W LONDON KY 40741-9998 USA "
    },
    {
      "Location ID": "40807",
      "Location Name": "BENHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 229 MAIN ST STE 1 BENHAM KY 40807 USA "
    },
    {
      "Location ID": "40823",
      "Location Name": "CUMBERLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1312 E MAIN ST CUMBERLAND KY 40823 USA "
    },
    {
      "Location ID": "40828",
      "Location Name": "EVARTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 138 MAIN ST EVARTS KY 40828 USA "
    },
    {
      "Location ID": "40831",
      "Location Name": "HARLAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 N 1ST ST HARLAN KY 40831 USA "
    },
    {
      "Location ID": "40847",
      "Location Name": "KENVIR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 139 POST OFFICE DR KENVIR KY 40847 USA "
    },
    {
      "Location ID": "40855",
      "Location Name": "LYNCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 E MAIN ST LYNCH KY 40855 USA "
    },
    {
      "Location ID": "40962",
      "Location Name": "MANCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 309 CLAY COUNTY SHOPPING CTR MANCHESTER KY 40962 USA "
    },
    {
      "Location ID": "40965",
      "Location Name": "MIDDLESBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 N 20TH ST MIDDLESBORO KY 40965 USA "
    },
    {
      "Location ID": "40972",
      "Location Name": "ONEIDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14963 N HIGHWAY 66 ONEIDA KY 40972 USA "
    },
    {
      "Location ID": "40977",
      "Location Name": "PINEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 S WALNUT ST PINEVILLE KY 40977 USA "
    },
    {
      "Location ID": "41001",
      "Location Name": "ALEXANDRIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6933 ALEXANDRIA PIKE ALEXANDRIA KY 41001 USA "
    },
    {
      "Location ID": "41005",
      "Location Name": "BURLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2600 BURLINGTON PIKE BURLINGTON KY 41005 USA "
    },
    {
      "Location ID": "41006",
      "Location Name": "BUTLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 FRONT ST BUTLER KY 41006 USA "
    },
    {
      "Location ID": "41008",
      "Location Name": "CARROLLTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 520 HIGHLAND AVE CARROLLTON KY 41008 USA "
    },
    {
      "Location ID": "41011",
      "Location Name": "COVINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 700 SCOTT ST COVINGTON KY 41011 USA "
    },
    {
      "Location ID": "41017",
      "Location Name": "DIXIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 BUTTERMILK PIKE LAKESIDE PARK KY 41017 USA "
    },
    {
      "Location ID": "41018",
      "Location Name": "ERLANGER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3135 DIXIE HWY ERLANGER KY 41018 USA "
    },
    {
      "Location ID": "41030",
      "Location Name": "CRITTENDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 S MAIN ST CRITTENDEN KY 41030 USA "
    },
    {
      "Location ID": "41031",
      "Location Name": "CYNTHIANA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 LADISH RD CYNTHIANA KY 41031 USA "
    },
    {
      "Location ID": "41035",
      "Location Name": "DRY RIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 S MAIN ST DRY RIDGE KY 41035 USA "
    },
    {
      "Location ID": "41040",
      "Location Name": "FALMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 W SHELBY ST FALMOUTH KY 41040 USA "
    },
    {
      "Location ID": "41041",
      "Location Name": "FLEMINGSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 131 E WATER ST FLEMINGSBURG KY 41041 USA "
    },
    {
      "Location ID": "41042",
      "Location Name": "FLORENCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7101 TURFWAY RD FLORENCE KY 41042 USA "
    },
    {
      "Location ID": "41046",
      "Location Name": "GLENCOE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 N MAIN ST GLENCOE KY 41046 USA "
    },
    {
      "Location ID": "41049",
      "Location Name": "HILLSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 SOUDER DR HILLSBORO KY 41049 USA "
    },
    {
      "Location ID": "41051",
      "Location Name": "INDEPENDENCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5106 MADISON PIKE INDEPENDENCE KY 41051 USA "
    },
    {
      "Location ID": "41056",
      "Location Name": "MAYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 131 E 3RD ST MAYSVILLE KY 41056 USA "
    },
    {
      "Location ID": "41071",
      "Location Name": "NEWPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 420 COLUMBIA ST NEWPORT KY 41071 USA "
    },
    {
      "Location ID": "41083",
      "Location Name": "SANDERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14150 KY HIGHWAY 36 E SANDERS KY 41083 USA "
    },
    {
      "Location ID": "41086",
      "Location Name": "SPARTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 KY HIGHWAY 467 W SPARTA KY 41086 USA "
    },
    {
      "Location ID": "41091",
      "Location Name": "UNION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1664 FROGTOWN RD UNION KY 41091 USA "
    },
    {
      "Location ID": "41093",
      "Location Name": "WALLINGFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7408 WALLINGFORD RD WALLINGFORD KY 41093 USA "
    },
    {
      "Location ID": "41094",
      "Location Name": "WALTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 52 S MAIN ST WALTON KY 41094 USA "
    },
    {
      "Location ID": "41095",
      "Location Name": "WARSAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 E MAIN ST WARSAW KY 41095 USA "
    },
    {
      "Location ID": "41097",
      "Location Name": "WILLIAMSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 N MAIN ST WILLIAMSTOWN KY 41097 USA "
    },
    {
      "Location ID": "41098",
      "Location Name": "WORTHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 481 US HIGHWAY 467 WORTHVILLE KY 41098 USA "
    },
    {
      "Location ID": "410AC",
      "Location Name": "CONDUENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1670 DOLWICK DR STE 11 ERLANGER KY 41018 USA "
    },
    {
      "Location ID": "410AN",
      "Location Name": "AMAZON CVG9",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2305 LITTON LN HEBRON KY 41048 USA "
    },
    {
      "Location ID": "410DH",
      "Location Name": "DHL GLOBAL MAIL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2325 GLOBAL WAY HEBRON KY 41048 USA "
    },
    {
      "Location ID": "41101",
      "Location Name": "ASHLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1140 CARTER AVE ASHLAND KY 41101 USA "
    },
    {
      "Location ID": "41121",
      "Location Name": "ARGILLITE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5470 STATE ROUTE 1 ARGILLITE KY 41121 USA "
    },
    {
      "Location ID": "41129",
      "Location Name": "CATLETTSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3514 COURT ST CATLETTSBURG KY 41129 USA "
    },
    {
      "Location ID": "41139",
      "Location Name": "FLATWOODS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2125 ARGILLITE RD FLATWOODS KY 41139 USA "
    },
    {
      "Location ID": "41141",
      "Location Name": "GARRISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9102 E KY 8 GARRISON KY 41141 USA "
    },
    {
      "Location ID": "41142",
      "Location Name": "GRAHN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 61 POST OFFICE AVE GRAHN KY 41142 USA "
    },
    {
      "Location ID": "41143",
      "Location Name": "GRAYSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 390 S CAROL MALONE BLVD GRAYSON KY 41143 USA "
    },
    {
      "Location ID": "41144",
      "Location Name": "GREENUP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1312 ASHLAND RD GREENUP KY 41144 USA "
    },
    {
      "Location ID": "41146",
      "Location Name": "HITCHINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3625 S STATE HIGHWAY 1 HITCHINS KY 41146 USA "
    },
    {
      "Location ID": "41164",
      "Location Name": "OLIVE HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 W TOM T HALL BLVD OLIVE HILL KY 41164 USA "
    },
    {
      "Location ID": "41166",
      "Location Name": "QUINCY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11977 E KY 8 QUINCY KY 41166 USA "
    },
    {
      "Location ID": "41168",
      "Location Name": "RUSH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15100 STATE ROUTE 854 RUSH KY 41168 USA "
    },
    {
      "Location ID": "41169",
      "Location Name": "RUSSELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 517 BOYD ST RUSSELL KY 41169 USA "
    },
    {
      "Location ID": "41171",
      "Location Name": "SANDY HOOK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 S KY 7 SANDY HOOK KY 41171-9997 USA "
    },
    {
      "Location ID": "41173",
      "Location Name": "SOLDIER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8337 STATE HIGHWAY 174 SOLDIER KY 41173 USA "
    },
    {
      "Location ID": "41174",
      "Location Name": "SOUTH PORTSMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2246 STATE ROUTE 8 SOUTH PORTSMOUTH KY 41174 USA "
    },
    {
      "Location ID": "41175",
      "Location Name": "SOUTH SHORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 NARCO DR SOUTH SHORE KY 41175 USA "
    },
    {
      "Location ID": "41179",
      "Location Name": "VANCEBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 THIRD ST VANCEBURG KY 41179 USA "
    },
    {
      "Location ID": "41181",
      "Location Name": "WILLARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8679 S STATE HIGHWAY 1 WILLARD KY 41181 USA "
    },
    {
      "Location ID": "41183",
      "Location Name": "WORTHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 605 FERRY AVE WORTHINGTON KY 41183 USA "
    },
    {
      "Location ID": "41189",
      "Location Name": "TOLLESBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3900 W KY 10 TOLLESBORO KY 41189 USA "
    },
    {
      "Location ID": "411RC",
      "Location Name": "RACER'S CPU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14525 STATE ROUTE 180 ASHLAND KY 41102 USA "
    },
    {
      "Location ID": "411WU",
      "Location Name": "WURTLAND CONTRACT STA (41144)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 WURTLAND AVE GREENUP KY 41144-9996 USA "
    },
    {
      "Location ID": "41224",
      "Location Name": "INEZ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 PARK PL INEZ KY 41224 USA "
    },
    {
      "Location ID": "41230",
      "Location Name": "LOUISA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 E MADISON ST LOUISA KY 41230 USA "
    },
    {
      "Location ID": "41232",
      "Location Name": "LOWMANSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 48 MAY LN LOWMANSVILLE KY 41232 USA "
    },
    {
      "Location ID": "41240",
      "Location Name": "PAINTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 FEDERAL DR PAINTSVILLE KY 41240 USA "
    },
    {
      "Location ID": "41274",
      "Location Name": "WITTENSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3027 N US HIGHWAY 23 WITTENSVILLE KY 41274 USA "
    },
    {
      "Location ID": "413",
      "Location Name": "CAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 175 MOUNTAIN PARKWAY SPUR RD CAMPTON KY 41301 USA "
    },
    {
      "Location ID": "41339",
      "Location Name": "JACKSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 790 HIGHWAY 15 N JACKSON KY 41339 USA "
    },
    {
      "Location ID": "41385",
      "Location Name": "VANCLEVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6857 HIGHWAY 15 N VANCLEVE KY 41385 USA "
    },
    {
      "Location ID": "41501",
      "Location Name": "PIKEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 281 THOMPSON RD PIKEVILLE KY 41501 USA "
    },
    {
      "Location ID": "41512",
      "Location Name": "ASHCAMP BOX ONLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6804 ELKHORN CREEK ASHCAMP KY 41512 USA "
    },
    {
      "Location ID": "41513",
      "Location Name": "BELCHER CARRIERS ONLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14857 REGINA BELCHER HWY BELCHER KY 41513 USA "
    },
    {
      "Location ID": "41514",
      "Location Name": "BELFRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25320 US HIGHWAY 119 N STE 100 BELFRY KY 41514 USA "
    },
    {
      "Location ID": "41517",
      "Location Name": "BURDINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11872 HIGHWAY 805 BURDINE KY 41517 USA "
    },
    {
      "Location ID": "41520",
      "Location Name": "DORTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 87 DORTON JENKINS HWY DORTON KY 41520 USA "
    },
    {
      "Location ID": "41522",
      "Location Name": "ELKHORN CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 145 W RUSSELL ST ELKHORN CITY KY 41522 USA "
    },
    {
      "Location ID": "41526",
      "Location Name": "FORDS BRANCH BOX SECTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2730 KEWANEE RD FORDS BRANCH KY 41526 USA "
    },
    {
      "Location ID": "41531",
      "Location Name": "HARDY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2240 STATE HIGHWAY 319 HARDY KY 41531 USA "
    },
    {
      "Location ID": "41534",
      "Location Name": "HELLIER BOX ONLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 126 ALLEGHENY RD HELLIER KY 41534 USA "
    },
    {
      "Location ID": "41537",
      "Location Name": "JENKINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9382 HIGHWAY 805 STE A JENKINS KY 41537 USA "
    },
    {
      "Location ID": "41538",
      "Location Name": "JONANCY BOX SECTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3440 KY HIGHWAY 610 W JONANCY KY 41538 USA "
    },
    {
      "Location ID": "41539",
      "Location Name": "KIMPER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9414 STATE HIGHWAY 194 E KIMPER KY 41539 USA "
    },
    {
      "Location ID": "41544",
      "Location Name": "MC CARR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6884 STATE HIGHWAY 319 MC CARR KY 41544 USA "
    },
    {
      "Location ID": "41553",
      "Location Name": "PHELPS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37719 STATE HIGHWAY 194 E PHELPS KY 41553 USA "
    },
    {
      "Location ID": "41559",
      "Location Name": "REGINA BOX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10051 REGINA BELCHER HWY REGINA KY 41559 USA "
    },
    {
      "Location ID": "41560",
      "Location Name": "ROBINSON CREEK BOX SECTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6500 ROBINSON CREEK RD ROBINSON CREEK KY 41560 USA "
    },
    {
      "Location ID": "41561",
      "Location Name": "ROCKHOUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1231 ROCKHOUSE ST ROBINSON CREEK KY 41560 USA "
    },
    {
      "Location ID": "41562",
      "Location Name": "SHELBIANA BOX SECTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 147 LONGVIEW DR SHELBIANA KY 41562 USA "
    },
    {
      "Location ID": "41572",
      "Location Name": "VIRGIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1070 KY HIGHWAY 610 W VIRGIE KY 41572 USA "
    },
    {
      "Location ID": "41601",
      "Location Name": "ALLEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 137 COURT ST ALLEN KY 41601 USA "
    },
    {
      "Location ID": "41603",
      "Location Name": "BANNER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 54 HALL ADDITION BANNER KY 41603 USA "
    },
    {
      "Location ID": "41605",
      "Location Name": "BETSY LAYNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11105 US HIGHWAY 23 S STE 101 BETSY LAYNE KY 41605 USA "
    },
    {
      "Location ID": "41606",
      "Location Name": "BEVINSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20311 KY ROUTE 122 BEVINSVILLE KY 41606 USA "
    },
    {
      "Location ID": "41635",
      "Location Name": "HAROLD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 LAYNESVILLE RD HAROLD KY 41635 USA "
    },
    {
      "Location ID": "41636",
      "Location Name": "HI HAT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17460 KY ROUTE 122 HI HAT KY 41636 USA "
    },
    {
      "Location ID": "41642",
      "Location Name": "IVEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6367 US HIGHWAY 23 S STE 101 IVEL KY 41642 USA "
    },
    {
      "Location ID": "41647",
      "Location Name": "MC DOWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9571 KY ROUTE 122 MC DOWELL KY 41647 USA "
    },
    {
      "Location ID": "41649",
      "Location Name": "MARTIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11561 MAIN ST MARTIN KY 41649 USA "
    },
    {
      "Location ID": "41650",
      "Location Name": "MELVIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22944 KY ROUTE 122 MELVIN KY 41650 USA "
    },
    {
      "Location ID": "41653",
      "Location Name": "PRESTONSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 FRANKLIN COR PRESTONSBURG KY 41653 USA "
    },
    {
      "Location ID": "41655",
      "Location Name": "PRINTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2928 KY ROUTE 122 STE 2 PRINTER KY 41655 USA "
    },
    {
      "Location ID": "41659",
      "Location Name": "STANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 185 E MAIN ST STANVILLE KY 41659 USA "
    },
    {
      "Location ID": "41669",
      "Location Name": "WHEELWRIGHT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 SHOP FORK ST WHEELWRIGHT KY 41669 USA "
    },
    {
      "Location ID": "417",
      "Location Name": "HAZARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 BLACK GOLD BLVD HAZARD KY 41701 USA "
    },
    {
      "Location ID": "41749",
      "Location Name": "HYDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 140 HIGHWAY 80 HYDEN KY 41749 USA "
    },
    {
      "Location ID": "41751",
      "Location Name": "JEFF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 136 KY HIGHWAY 7 STE 101 JEFF KY 41751 USA "
    },
    {
      "Location ID": "41824",
      "Location Name": "ISOM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9305 HIGHWAY 15 ISOM KY 41824 USA "
    },
    {
      "Location ID": "41837",
      "Location Name": "MAYKING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3397 HIGHWAY 119 N MAYKING KY 41837 USA "
    },
    {
      "Location ID": "41858",
      "Location Name": "WHITESBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 71 HIGHWAY 119 S WHITESBURG KY 41858 USA "
    },
    {
      "Location ID": "420",
      "Location Name": "PADUCAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 S 4TH ST PADUCAH KY 42003 USA "
    },
    {
      "Location ID": "42002",
      "Location Name": "AVONDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2927 BROADWAY ST PADUCAH KY 42001 USA "
    },
    {
      "Location ID": "42021",
      "Location Name": "ARLINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 178 WALNUT ST ARLINGTON KY 42021 USA "
    },
    {
      "Location ID": "42023",
      "Location Name": "BARDWELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 ELM ST BARDWELL KY 42023 USA "
    },
    {
      "Location ID": "42024",
      "Location Name": "BARLOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 S 4TH ST BARLOW KY 42024 USA "
    },
    {
      "Location ID": "42025",
      "Location Name": "BENTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 219 W 5TH ST BENTON KY 42025 USA "
    },
    {
      "Location ID": "42027",
      "Location Name": "BOAZ",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1970 MCCRACKEN COUNTY RD BOAZ KY 42027 USA "
    },
    {
      "Location ID": "42029",
      "Location Name": "CALVERT CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 134 E 5TH AVE CALVERT CITY KY 42029 USA "
    },
    {
      "Location ID": "42031",
      "Location Name": "CLINTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 304 S WASHINGTON ST CLINTON KY 42031 USA "
    },
    {
      "Location ID": "42038",
      "Location Name": "EDDYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 144 COMMERCE ST EDDYVILLE KY 42038 USA "
    },
    {
      "Location ID": "42039",
      "Location Name": "FANCY FARM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 23 STATE ROUTE 339 S FANCY FARM KY 42039 USA "
    },
    {
      "Location ID": "42041",
      "Location Name": "FULTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 416 WALNUT ST FULTON KY 42041 USA "
    },
    {
      "Location ID": "42044",
      "Location Name": "GILBERTSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 ADMINISTRATION AVE GILBERTSVILLE KY 42044 USA "
    },
    {
      "Location ID": "42048",
      "Location Name": "HARDIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 85 COMMERCE ST HARDIN KY 42048 USA "
    },
    {
      "Location ID": "42049",
      "Location Name": "HAZEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 304 MAIN ST HAZEL KY 42049 USA "
    },
    {
      "Location ID": "42050",
      "Location Name": "HICKMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 JACKSON ST HICKMAN KY 42050 USA "
    },
    {
      "Location ID": "42051",
      "Location Name": "HICKORY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1976 STATE ROUTE 1241 HICKORY KY 42051 USA "
    },
    {
      "Location ID": "42053",
      "Location Name": "KEVIL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 253 WALLACE AVE E KEVIL KY 42053 USA "
    },
    {
      "Location ID": "42056",
      "Location Name": "LA CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 348 BROADWAY LA CENTER KY 42056 USA "
    },
    {
      "Location ID": "42060",
      "Location Name": "LOVELACEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 142 N BROADWAY ST LOVELACEVILLE KY 42060 USA "
    },
    {
      "Location ID": "42064",
      "Location Name": "MARION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 128 E CARLISLE ST MARION KY 42064 USA "
    },
    {
      "Location ID": "42066",
      "Location Name": "MAYFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1102 PARIS RD MAYFIELD KY 42066 USA "
    },
    {
      "Location ID": "42071",
      "Location Name": "MURRAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1105 CHESTNUT ST MURRAY KY 42071 USA "
    },
    {
      "Location ID": "42078",
      "Location Name": "SALEM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 155 E MAIN ST SALEM KY 42078 USA "
    },
    {
      "Location ID": "42081",
      "Location Name": "SMITHLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 E ADAIR ST SMITHLAND KY 42081 USA "
    },
    {
      "Location ID": "42082",
      "Location Name": "SYMSONIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12022 STATE ROUTE 131 SYMSONIA KY 42082 USA "
    },
    {
      "Location ID": "42086",
      "Location Name": "WEST PADUCAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7475 US HIGHWAY 60 W WEST PADUCAH KY 42086 USA "
    },
    {
      "Location ID": "42087",
      "Location Name": "WICKLIFFE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 330 COURT ST WICKLIFFE KY 42087 USA "
    },
    {
      "Location ID": "42088",
      "Location Name": "WINGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 93 LEBANON ST WINGO KY 42088 USA "
    },
    {
      "Location ID": "420AA",
      "Location Name": "SOUTHSIDE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3160 IRVIN COBB DR PADUCAH KY 42003 USA "
    },
    {
      "Location ID": "420BN",
      "Location Name": "RE-FAB BOUTIQUE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1104 PADUCAH RD  MAYFIELD KY 42066 USA "
    },
    {
      "Location ID": "420CA",
      "Location Name": "PADUCAH CARRIER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3550 PARK PLAZA RD PADUCAH KY 42001 USA "
    },
    {
      "Location ID": "420CO",
      "Location Name": "COLLECTORS BOOKS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5801 KENTUCKY DAM RD PADUCAH KY 42003 USA "
    },
    {
      "Location ID": "420EC",
      "Location Name": "ELDER CYCLE LLC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2838 STATE ROUTE 121 N MAYFIELD KY 42066 USA "
    },
    {
      "Location ID": "420KE",
      "Location Name": "K-9 ELITE (BOAZ KY)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7660 OLD US HIGHWAY 45 BOAZ KY 42027 USA "
    },
    {
      "Location ID": "420PH",
      "Location Name": "PHARMACY INC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 82 SPRUCE ST STE 100 MURRAY KY 42071 USA "
    },
    {
      "Location ID": "421",
      "Location Name": "BOWLING GREEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5300 SCOTTSVILLE RD BOWLING GREEN KY 42104 USA "
    },
    {
      "Location ID": "42101",
      "Location Name": "BOWLING GREEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5300 SCOTTSVILLE RD BOWLING GREEN KY 42104 USA "
    },
    {
      "Location ID": "42120",
      "Location Name": "ADOLPHUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6422 NEW GALLATIN RD ADOLPHUS KY 42120 USA "
    },
    {
      "Location ID": "42122",
      "Location Name": "ALVATON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10363 ALVATON RD ALVATON KY 42122 USA "
    },
    {
      "Location ID": "42133",
      "Location Name": "FOUNTAIN RUN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 MAIN ST FOUNTAIN RUN KY 42133 USA "
    },
    {
      "Location ID": "42164",
      "Location Name": "SCOTTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 405 E MAIN ST SCOTTSVILLE KY 42164 USA "
    },
    {
      "Location ID": "421BK",
      "Location Name": "BOOKS FYI (CADIZ)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2271 DELMONT CHURCH RD CADIZ KY 42211 USA "
    },
    {
      "Location ID": "421RR",
      "Location Name": "LSC COMM GLASGOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 DONNELLEY DR GLASGOW KY 42141 USA "
    },
    {
      "Location ID": "42206",
      "Location Name": "AUBURN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 309 W MAIN ST AUBURN KY 42206 USA "
    },
    {
      "Location ID": "42210",
      "Location Name": "BROWNSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 535 S MAIN ST BROWNSVILLE KY 42210 USA "
    },
    {
      "Location ID": "42211",
      "Location Name": "CADIZ",
      "Time Zone Offset (Hours)": -1,
      "Address": " 72 LAKOTA DR CADIZ KY 42211 USA "
    },
    {
      "Location ID": "42217",
      "Location Name": "CROFTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 S MADISONVILLE ST CROFTON KY 42217 USA "
    },
    {
      "Location ID": "42220",
      "Location Name": "ELKTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 W MAIN ST ELKTON KY 42220 USA "
    },
    {
      "Location ID": "42234",
      "Location Name": "GUTHRIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 W PARK ST GUTHRIE KY 42234 USA "
    },
    {
      "Location ID": "42240",
      "Location Name": "HOPKINSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 S MAIN ST HOPKINSVILLE KY 42240 USA "
    },
    {
      "Location ID": "42256",
      "Location Name": "LEWISBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 182 S 3RD ST LEWISBURG KY 42256 USA "
    },
    {
      "Location ID": "42262",
      "Location Name": "OAK GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15124 FORT CAMPBELL BLVD OAK GROVE KY 42262 USA "
    },
    {
      "Location ID": "42266",
      "Location Name": "PEMBROKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 S MAIN ST PEMBROKE KY 42266 USA "
    },
    {
      "Location ID": "42274",
      "Location Name": "ROCKFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 135 RICHPOND ROCKFIELD RD ROCKFIELD KY 42274 USA "
    },
    {
      "Location ID": "42276",
      "Location Name": "RUSSELLVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 W 4TH ST RUSSELLVILLE KY 42276 USA "
    },
    {
      "Location ID": "42286",
      "Location Name": "TRENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 N MAIN ST TRENTON KY 42286 USA "
    },
    {
      "Location ID": "422GP",
      "Location Name": "USDA FOREST SERVICE (CADIZ)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 VAN MORGAN DR CADIZ KY 42211 USA "
    },
    {
      "Location ID": "423",
      "Location Name": "OWENSBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 54 BON HARBOR HLS OWENSBORO KY 42301 USA "
    },
    {
      "Location ID": "42320",
      "Location Name": "BEAVER DAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 435 S MAIN ST BEAVER DAM KY 42320 USA "
    },
    {
      "Location ID": "42323",
      "Location Name": "BEECHMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3736 MERLE TRAVIS HWY BEECHMONT KY 42323 USA "
    },
    {
      "Location ID": "42328",
      "Location Name": "CENTERTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 805 MAIN ST CENTERTOWN KY 42328 USA "
    },
    {
      "Location ID": "42330",
      "Location Name": "CENTRAL CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W BROAD ST CENTRAL CITY KY 42330 USA "
    },
    {
      "Location ID": "42332",
      "Location Name": "CLEATON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 36 MAIN ST CLEATON KY 42332 USA "
    },
    {
      "Location ID": "42337",
      "Location Name": "DRAKESBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 W MOSE RAGER BLVD DRAKESBORO KY 42337 USA "
    },
    {
      "Location ID": "42339",
      "Location Name": "DUNMOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11230 US HIGHWAY 431 S DUNMOR KY 42339 USA "
    },
    {
      "Location ID": "42343",
      "Location Name": "FORDSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 25 SMITH ST FORDSVILLE KY 42343 USA "
    },
    {
      "Location ID": "42344",
      "Location Name": "GRAHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4909 STATE ROUTE 175 S GRAHAM KY 42344 USA "
    },
    {
      "Location ID": "42345",
      "Location Name": "GREENVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 COURT ST GREENVILLE KY 42345 USA "
    },
    {
      "Location ID": "42347",
      "Location Name": "HARTFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1101 S MAIN ST HARTFORD KY 42347 USA "
    },
    {
      "Location ID": "42348",
      "Location Name": "HAWESVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 MAIN CROSS ST HAWESVILLE KY 42348 USA "
    },
    {
      "Location ID": "42350",
      "Location Name": "ISLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 S 1ST ST ISLAND KY 42350 USA "
    },
    {
      "Location ID": "42351",
      "Location Name": "LEWISPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 550 4TH ST LEWISPORT KY 42351 USA "
    },
    {
      "Location ID": "42352",
      "Location Name": "LIVERMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 HILL ST LIVERMORE KY 42352 USA "
    },
    {
      "Location ID": "42354",
      "Location Name": "MC HENRY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3156 US HIGHWAY 62 W MC HENRY KY 42354 USA "
    },
    {
      "Location ID": "42355",
      "Location Name": "MACEO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10124 STATE ROUTE 405 MACEO KY 42355 USA "
    },
    {
      "Location ID": "42366",
      "Location Name": "PHILPOT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6028 STATE ROUTE 54 PHILPOT KY 42366 USA "
    },
    {
      "Location ID": "42369",
      "Location Name": "ROCKPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12 MAIN ST ROCKPORT KY 42369 USA "
    },
    {
      "Location ID": "42374",
      "Location Name": "SOUTH CARROLLTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10719 US HIGHWAY 431 N SOUTH CARROLLTON KY 42374 USA "
    },
    {
      "Location ID": "42376",
      "Location Name": "UTICA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10734 US HIGHWAY 431 UTICA KY 42376 USA "
    },
    {
      "Location ID": "42378",
      "Location Name": "WHITESVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10575 MAIN CROSS ST WHITESVILLE KY 42378 USA "
    },
    {
      "Location ID": "42402",
      "Location Name": "BASKETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1015 2ND AVE BASKETT KY 42402 USA "
    },
    {
      "Location ID": "42404",
      "Location Name": "CLAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 39 E ELM ST CLAY KY 42404 USA "
    },
    {
      "Location ID": "42406",
      "Location Name": "CORYDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 MAIN ST CORYDON KY 42406 USA "
    },
    {
      "Location ID": "42408",
      "Location Name": "DAWSON SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 W RAMSEY ST DAWSON SPRINGS KY 42408 USA "
    },
    {
      "Location ID": "42409",
      "Location Name": "DIXON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 73 US HIGHWAY 41A S DIXON KY 42409 USA "
    },
    {
      "Location ID": "42410",
      "Location Name": "EARLINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 E MAIN ST EARLINGTON KY 42410 USA "
    },
    {
      "Location ID": "42411",
      "Location Name": "FREDONIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 513 CASSIDY AVE FREDONIA KY 42411 USA "
    },
    {
      "Location ID": "42413",
      "Location Name": "HANSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 50 SUNSET RD HANSON KY 42413 USA "
    },
    {
      "Location ID": "42420",
      "Location Name": "HENDERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 1ST ST HENDERSON KY 42420 USA "
    },
    {
      "Location ID": "42431",
      "Location Name": "MADISONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 W CENTER ST MADISONVILLE KY 42431 USA "
    },
    {
      "Location ID": "42436",
      "Location Name": "MANITOU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5270 NEBO RD MANITOU KY 42436 USA "
    },
    {
      "Location ID": "42437",
      "Location Name": "MORGANFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 231 W MAIN ST MORGANFIELD KY 42437 USA "
    },
    {
      "Location ID": "42440",
      "Location Name": "MORTONS GAP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 140 CROSS ST MORTONS GAP KY 42440 USA "
    },
    {
      "Location ID": "42442",
      "Location Name": "NORTONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 39 E WALNUT ST NORTONVILLE KY 42442 USA "
    },
    {
      "Location ID": "42444",
      "Location Name": "POOLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9599 US HIGHWAY 41A N POOLE KY 42444 USA "
    },
    {
      "Location ID": "42445",
      "Location Name": "PRINCETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W WASHINGTON ST PRINCETON KY 42445 USA "
    },
    {
      "Location ID": "42450",
      "Location Name": "PROVIDENCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 E MAIN ST PROVIDENCE KY 42450 USA "
    },
    {
      "Location ID": "42451",
      "Location Name": "REED",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16110 US HIGHWAY 60 E REED KY 42451 USA "
    },
    {
      "Location ID": "42452",
      "Location Name": "ROBARDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1204 E RAILROAD ST ROBARDS KY 42452 USA "
    },
    {
      "Location ID": "42453",
      "Location Name": "SAINT CHARLES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6205 NORTONVILLE RD SAINT CHARLES KY 42453 USA "
    },
    {
      "Location ID": "42455",
      "Location Name": "SEBREE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 32 WATKINS SEBREE RD SEBREE KY 42455 USA "
    },
    {
      "Location ID": "42456",
      "Location Name": "SLAUGHTERS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15452 STATE ROUTE 120 E SLAUGHTERS KY 42456 USA "
    },
    {
      "Location ID": "42458",
      "Location Name": "SPOTTSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1003 RIVER RD SPOTTSVILLE KY 42458 USA "
    },
    {
      "Location ID": "42459",
      "Location Name": "STURGIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 409 N MAIN ST STURGIS KY 42459 USA "
    },
    {
      "Location ID": "42461",
      "Location Name": "UNIONTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 223 MAIN ST UNIONTOWN KY 42461 USA "
    },
    {
      "Location ID": "42462",
      "Location Name": "WAVERLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 124 N MAPLE ST WAVERLY KY 42462 USA "
    },
    {
      "Location ID": "42464",
      "Location Name": "WHITE PLAINS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 S BOB BRUCE DR WHITE PLAINS KY 42464 USA "
    },
    {
      "Location ID": "424CI",
      "Location Name": "CARHARTT INC (HANSON)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 380 ESTILL BAKER RD HANSON KY 42413 USA "
    },
    {
      "Location ID": "424CS",
      "Location Name": "COLUMBIA SPORTWEARS (ROBARDS)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2001 NORTHERN STAR WAY ROBARDS KY 42452 USA "
    },
    {
      "Location ID": "425",
      "Location Name": "SOMERSET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 N MAPLE ST SOMERSET KY 42501 USA "
    },
    {
      "Location ID": "42518",
      "Location Name": "BRONSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1801 HIGHWAY 90 BRONSTON KY 42518 USA "
    },
    {
      "Location ID": "42544",
      "Location Name": "NANCY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9150 W HIGHWAY 80 NANCY KY 42544 USA "
    },
    {
      "Location ID": "42553",
      "Location Name": "SCIENCE HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 735 CENTRAL AVE SCIENCE HILL KY 42553 USA "
    },
    {
      "Location ID": "42564",
      "Location Name": "WEST SOMERSET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 299 WASHINGTON DR SOMERSET KY 42501 USA "
    },
    {
      "Location ID": "42565",
      "Location Name": "WINDSOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3593 KY 80 WINDSOR KY 42565 USA "
    },
    {
      "Location ID": "42567",
      "Location Name": "EUBANK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15058 N HIGHWAY 1247 EUBANK KY 42567 USA "
    },
    {
      "Location ID": "425NL",
      "Location Name": "NEW LIFE INDUSTRIES (SOMERSET)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 140 CHAPPELLS DAIRY RD SOMERSET KY 42503 USA "
    },
    {
      "Location ID": "42602",
      "Location Name": "ALBANY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 N CROSS ST ALBANY KY 42602 USA "
    },
    {
      "Location ID": "42629",
      "Location Name": "JAMESTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 66 FAIR OAKS DR JAMESTOWN KY 42629 USA "
    },
    {
      "Location ID": "42633",
      "Location Name": "MONTICELLO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 65 BARNES DR MONTICELLO KY 42633 USA "
    },
    {
      "Location ID": "42642",
      "Location Name": "RUSSELL SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 414 MAIN ST RUSSELL SPRINGS KY 42642 USA "
    },
    {
      "Location ID": "427",
      "Location Name": "ELIZABETHTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2934 DOLPHIN DR ELIZABETHTOWN KY 42701 USA "
    },
    {
      "Location ID": "42713",
      "Location Name": "BONNIEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7692 N DIXIE HWY BONNIEVILLE KY 42713 USA "
    },
    {
      "Location ID": "42740",
      "Location Name": "GLENDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 438 E MAIN ST GLENDALE KY 42740 USA "
    },
    {
      "Location ID": "42746",
      "Location Name": "HARDYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 62 N JACKSON HWY HARDYVILLE KY 42746 USA "
    },
    {
      "Location ID": "42749",
      "Location Name": "HORSE CAVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 WATER ST HORSE CAVE KY 42749 USA "
    },
    {
      "Location ID": "42765",
      "Location Name": "MUNFORDVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 S WASHINGTON ST MUNFORDVILLE KY 42765 USA "
    },
    {
      "Location ID": "42776",
      "Location Name": "SONORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 204 E WESTERN AVE SONORA KY 42776 USA "
    },
    {
      "Location ID": "42784",
      "Location Name": "UPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 N WALNUT ST UPTON KY 42784 USA "
    },
    {
      "Location ID": "430",
      "Location Name": "COLUMBUS P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2323 CITYGATE DR COLUMBUS OH 43218 USA "
    },
    {
      "Location ID": "43001",
      "Location Name": "ALEXANDRIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 W MAIN ST ALEXANDRIA OH 43001 USA "
    },
    {
      "Location ID": "43002",
      "Location Name": "AMLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6931 RINGS RD AMLIN OH 43002 USA "
    },
    {
      "Location ID": "43003",
      "Location Name": "ASHLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 E HIGH ST ASHLEY OH 43003 USA "
    },
    {
      "Location ID": "43004",
      "Location Name": "BLACKLICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 450 N BRICE RD BLACKLICK OH 43004 USA "
    },
    {
      "Location ID": "43006",
      "Location Name": "BRINKHAVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 204 HIGH ST BRINKHAVEN OH 43006 USA "
    },
    {
      "Location ID": "43007",
      "Location Name": "BROADWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18992 STATE ROUTE 347 BROADWAY OH 43007 USA "
    },
    {
      "Location ID": "43008",
      "Location Name": "BUCKEYE LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4717 WALNUT RD BUCKEYE LAKE OH 43008 USA "
    },
    {
      "Location ID": "43009",
      "Location Name": "CABLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3582 MAIN ST CABLE OH 43009 USA "
    },
    {
      "Location ID": "43010",
      "Location Name": "CATAWBA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 W PLEASANT ST CATAWBA OH 43010 USA "
    },
    {
      "Location ID": "43011",
      "Location Name": "CENTERBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 N CLAYTON ST CENTERBURG OH 43011 USA "
    },
    {
      "Location ID": "43013",
      "Location Name": "CROTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 S HIGH ST CROTON OH 43013 USA "
    },
    {
      "Location ID": "43014",
      "Location Name": "DANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 414 S MARKET ST DANVILLE OH 43014 USA "
    },
    {
      "Location ID": "43015",
      "Location Name": "DELAWARE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 S LIBERTY ST DELAWARE OH 43015 USA "
    },
    {
      "Location ID": "43016",
      "Location Name": "DUBLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6400 EMERALD PKWY DUBLIN OH 43016 USA "
    },
    {
      "Location ID": "43017",
      "Location Name": "SHAMROCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 715 SHAWAN FALLS DR DUBLIN OH 43017 USA "
    },
    {
      "Location ID": "43018",
      "Location Name": "ETNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1045 PIKE ST SW ETNA OH 43018 USA "
    },
    {
      "Location ID": "43019",
      "Location Name": "FREDERICKTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 67 N PLEASANT ST FREDERICKTOWN OH 43019 USA "
    },
    {
      "Location ID": "43021",
      "Location Name": "GALENA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 W PARK ST GALENA OH 43021 USA "
    },
    {
      "Location ID": "43022",
      "Location Name": "GAMBIER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 CHASE AVE GAMBIER OH 43022 USA "
    },
    {
      "Location ID": "43023",
      "Location Name": "GRANVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 203 BROADWAY E GRANVILLE OH 43023 USA "
    },
    {
      "Location ID": "43025",
      "Location Name": "HEBRON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 146 N HIGH ST HEBRON OH 43025 USA "
    },
    {
      "Location ID": "43026",
      "Location Name": "HILLIARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4000 LEAP RD HILLIARD OH 43026 USA "
    },
    {
      "Location ID": "43027",
      "Location Name": "HOMER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1080 HOMER RD NW HOMER OH 43027 USA "
    },
    {
      "Location ID": "43028",
      "Location Name": "HOWARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23359 COSHOCTON RD HOWARD OH 43028 USA "
    },
    {
      "Location ID": "43029",
      "Location Name": "IRWIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24176 STATE ROUTE 161 IRWIN OH 43029 USA "
    },
    {
      "Location ID": "43030",
      "Location Name": "JACKSONTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6686 NATIONAL RD SE JACKSONTOWN OH 43030 USA "
    },
    {
      "Location ID": "43031",
      "Location Name": "JOHNSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 211 W COSHOCTON ST JOHNSTOWN OH 43031 USA "
    },
    {
      "Location ID": "43033",
      "Location Name": "KIRKERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 145 W MAIN ST KIRKERSVILLE OH 43033 USA "
    },
    {
      "Location ID": "43035",
      "Location Name": "LEWIS CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 393 E ORANGE RD LEWIS CENTER OH 43035 USA "
    },
    {
      "Location ID": "43036",
      "Location Name": "MAGNETIC SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 57 S MAIN ST MAGNETIC SPRINGS OH 43036 USA "
    },
    {
      "Location ID": "43040",
      "Location Name": "MARYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 N MAIN ST MARYSVILLE OH 43040 USA "
    },
    {
      "Location ID": "43044",
      "Location Name": "MECHANICSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 S MAIN ST MECHANICSBURG OH 43044 USA "
    },
    {
      "Location ID": "43045",
      "Location Name": "MILFORD CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 E STATE ST MILFORD CENTER OH 43045 USA "
    },
    {
      "Location ID": "43046",
      "Location Name": "MILLERSPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12050 LANCASTER ST MILLERSPORT OH 43046 USA "
    },
    {
      "Location ID": "43048",
      "Location Name": "MOUNT LIBERTY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7088 COLUMBUS RD MOUNT LIBERTY OH 43048 USA "
    },
    {
      "Location ID": "43050",
      "Location Name": "MOUNT VERNON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 W HIGH ST MOUNT VERNON OH 43050 USA "
    },
    {
      "Location ID": "43054",
      "Location Name": "NEW ALBANY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 W MAIN ST NEW ALBANY OH 43054 USA "
    },
    {
      "Location ID": "43055",
      "Location Name": "NEWARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 70 E CHURCH ST NEWARK OH 43055 USA "
    },
    {
      "Location ID": "43060",
      "Location Name": "NORTH LEWISBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 E MAPLE ST NORTH LEWISBURG OH 43060 USA "
    },
    {
      "Location ID": "43061",
      "Location Name": "OSTRANDER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 420 W NORTH ST OSTRANDER OH 43061 USA "
    },
    {
      "Location ID": "43062",
      "Location Name": "PATASKALA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 175 E BROAD ST PATASKALA OH 43062 USA "
    },
    {
      "Location ID": "43064",
      "Location Name": "PLAIN CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 219 MAPLE ST PLAIN CITY OH 43064 USA "
    },
    {
      "Location ID": "43065",
      "Location Name": "POWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 GRACE DR POWELL OH 43065 USA "
    },
    {
      "Location ID": "43066",
      "Location Name": "RADNOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4355 STATE ROUTE 203 RADNOR OH 43066 USA "
    },
    {
      "Location ID": "43067",
      "Location Name": "RAYMOND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21655 MAIN ST RAYMOND OH 43067 USA "
    },
    {
      "Location ID": "43068",
      "Location Name": "REYNOLDSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7185 E MAIN ST REYNOLDSBURG OH 43068 USA "
    },
    {
      "Location ID": "43070",
      "Location Name": "ROSEWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6492 KISER LAKE RD ROSEWOOD OH 43070 USA "
    },
    {
      "Location ID": "43071",
      "Location Name": "SAINT LOUISVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 N SUGAR ST SAINT LOUISVILLE OH 43071 USA "
    },
    {
      "Location ID": "43072",
      "Location Name": "SAINT PARIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 131 E MAIN ST SAINT PARIS OH 43072 USA "
    },
    {
      "Location ID": "43073",
      "Location Name": "SUMMIT STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6396 SUMMIT RD SW SUMMIT STATION OH 43073 USA "
    },
    {
      "Location ID": "43074",
      "Location Name": "SUNBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 267 W GRANVILLE ST SUNBURY OH 43074 USA "
    },
    {
      "Location ID": "43076",
      "Location Name": "THORNVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 N MAIN ST THORNVILLE OH 43076 USA "
    },
    {
      "Location ID": "43077",
      "Location Name": "UNIONVILLE CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 W MAIN ST UNIONVILLE CENTER OH 43077 USA "
    },
    {
      "Location ID": "43078",
      "Location Name": "URBANA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 S MAIN ST URBANA OH 43078 USA "
    },
    {
      "Location ID": "43080",
      "Location Name": "UTICA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 N MAIN ST UTICA OH 43080 USA "
    },
    {
      "Location ID": "43081",
      "Location Name": "STATE STREET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 260 S STATE ST WESTERVILLE OH 43081 US USA OH 43081 USA "
    },
    {
      "Location ID": "43082",
      "Location Name": "WESTERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 617 MCCORKLE BLVD WESTERVILLE OH 43082 USA "
    },
    {
      "Location ID": "43083",
      "Location Name": "WESTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3987 W US HIGHWAY 36 WESTVILLE OH 43083 USA "
    },
    {
      "Location ID": "43084",
      "Location Name": "WOODSTOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 BURNWELL ST WOODSTOCK OH 43084 USA "
    },
    {
      "Location ID": "43085",
      "Location Name": "WORTHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 597 HIGH ST WORTHINGTON OH 43085 USA "
    },
    {
      "Location ID": "430AB",
      "Location Name": "STATE STREET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 260 S STATE ST WESTERVILLE OH 43081 USA "
    },
    {
      "Location ID": "430AD",
      "Location Name": "ALLIANCE DATA SYSTEMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6939 AMERICANA PKWY REYNOLDSBURG OH 43068 USA "
    },
    {
      "Location ID": "430AF",
      "Location Name": "ABERCROMBIE & FITCH (COLUMBUS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9100 SMITH MILL RD NEW ALBANY OH 43035 USA "
    },
    {
      "Location ID": "430AM",
      "Location Name": "AMAZON CMH1",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11903 NATIONAL RD SW ETNA OH 43018 US USA OH 43018 USA "
    },
    {
      "Location ID": "430AR",
      "Location Name": "ATWOOD ROPE (MILLERSPORT)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2185 REFUGEE ST MILLERSPORT OH 43046-9748 USA "
    },
    {
      "Location ID": "430AS",
      "Location Name": "ASCENA (ETNA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 HERITAGE DR ETNA OH 43062 USA "
    },
    {
      "Location ID": "430AT",
      "Location Name": "ATRIUM (JOHNSTOWN)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 188 COMMERCE BLVD JOHNSTOWN OH 43031-9011 USA "
    },
    {
      "Location ID": "430AW",
      "Location Name": "AIR WAVES INC (LEWIS CENTER)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7750 GREEN MEADOWS DR STE A LEWIS CENTER OH 43035 USA "
    },
    {
      "Location ID": "430BM",
      "Location Name": "BUCKEYE MILITARY MOMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 137 E DUBLIN GRANVILLE RD WORTHINGTON OH 43085 USA "
    },
    {
      "Location ID": "430BO",
      "Location Name": "JP MORGAN CHASE (380)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 380 S CLEVELAND AVE WESTERVILLE OH 43081 USA "
    },
    {
      "Location ID": "430BP",
      "Location Name": "BAESMAN PRINTING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4477 REYNOLDS DR HILLIARD OH 43026 USA "
    },
    {
      "Location ID": "430CA",
      "Location Name": "COLUMBUS OH CMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4850 POTH RD WHITEHALL OH 43213 USA "
    },
    {
      "Location ID": "430CK",
      "Location Name": "C KRUEGERS FINEST BAKED GOODS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6845 COMMERCE COURT DR BLACKLICK OH 43004 USA "
    },
    {
      "Location ID": "430CR",
      "Location Name": "CRAZY RICHARDS PEANUT BUTTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7920 CORPORATE BLVD SUITE B PLAIN CITY OH 43064 USA "
    },
    {
      "Location ID": "430CW",
      "Location Name": "CRAFT WHOLESALERS INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4600 S HAMILTON RD GROVEPORT OH 43125-9636 USA "
    },
    {
      "Location ID": "430CZ",
      "Location Name": "CALZURO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8055 CORPORATE BLVD UNIT B PLAIN CITY OH 43064 USA "
    },
    {
      "Location ID": "430DD",
      "Location Name": "RR DONNELLEY (DDM DIRECT)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 472 O NEILL DR HEBRON OH 43025 USA "
    },
    {
      "Location ID": "430DH",
      "Location Name": "DASCO (WESTERVILLE OH)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 375 N WEST ST WESTERVILLE OH 43082 USA "
    },
    {
      "Location ID": "430EL",
      "Location Name": "EXCEL LOGISTICS (WORTHINGTON)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4800 INDUSTRIAL PKWY PLAIN CITY OH 43064 USA "
    },
    {
      "Location ID": "430FT",
      "Location Name": "FINTIE (DUBLIN)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6365 OLD AVERY RD STE 6 DUBLIN OH 43016 USA "
    },
    {
      "Location ID": "430GF",
      "Location Name": "31 GIFTS (JOHNSTOWN)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 231 COMMERCE BLVD JOHNSTOWN OH 43031-9645 US USA OH 43219 USA "
    },
    {
      "Location ID": "430GW",
      "Location Name": "GOLF WORKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4820 JACKSONTOWN RD NEWARK OH 43056 USA "
    },
    {
      "Location ID": "430HD",
      "Location Name": "HARRY & DAVID (HEBRON)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 RELIANCE DR HEBRON OH 43025 USA "
    },
    {
      "Location ID": "430HG",
      "Location Name": "HAMMOCK GEAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6913 AMERICANA PKWY REYNOLDSBURG OH 43068 USA "
    },
    {
      "Location ID": "430HM",
      "Location Name": "HIMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9750 INNOVATION CAMPUS WAY NEW ALBANY OH 43054 USA "
    },
    {
      "Location ID": "430IP",
      "Location Name": "IRON PONY MOTORSPORTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5436 WESTERVILLE RD WESTERVILLE OH 43081 USA "
    },
    {
      "Location ID": "430JG",
      "Location Name": "JEGS (DELAWARE)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 JEGS PL DELAWARE OH 43015 USA "
    },
    {
      "Location ID": "430JL",
      "Location Name": "JUNIOR LIBRARY GUILD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7858 INDUSTRIAL PKWY PLAIN CITY OH 43064 USA "
    },
    {
      "Location ID": "430JP",
      "Location Name": "JP MORGAN CHASE (370)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 370 S CLEVELAND AVE WESTERVILLE OH 43081 USA "
    },
    {
      "Location ID": "430KO",
      "Location Name": "KOHLS - ETNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10201 SCHUSTER WAY ETNA OH 43062 USA "
    },
    {
      "Location ID": "430ME",
      "Location Name": "MAX EFFORT MUSCLE (NEWARK OH)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 ANTHONY DR NEWARK OH 43055 USA "
    },
    {
      "Location ID": "430MN",
      "Location Name": "MIXT NUTRITION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8435 RAUSCH DR PLAIN CITY OH 43064 USA "
    },
    {
      "Location ID": "430MO",
      "Location Name": "COLUMBUS (MAIN OFFICE DOOR 55)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 850 TWIN RIVERS DR COLUMBUS OH 43216 USA "
    },
    {
      "Location ID": "430MP",
      "Location Name": "MAILPRO1",
      "Time Zone Offset (Hours)": 0,
      "Address": " 439 DUNLAP ST STE B DELAWARE OH 43015 USA "
    },
    {
      "Location ID": "430NG",
      "Location Name": "NOXGEAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 966 PROPRIETORS RD WORTHINGTON OH 43085 USA "
    },
    {
      "Location ID": "430PP",
      "Location Name": "PROPEL PHARMACY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4150 TULLER RD STE 230 DUBLIN OH 43017 USA "
    },
    {
      "Location ID": "430PR",
      "Location Name": "PRESSWORKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 351 W BIGELOW PKWY PLAIN CITY OH 43064 USA "
    },
    {
      "Location ID": "430RO",
      "Location Name": "ROCKS BOX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2626 PORT RD COLUMBUS OH 43217 USA "
    },
    {
      "Location ID": "430RR",
      "Location Name": "HEBRON (RR DONNELLY)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 190 MILLIKEN DR HEBRON OH 43025 USA "
    },
    {
      "Location ID": "430SC",
      "Location Name": "SADDLE CREEK LOGISTICS (ETNA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 175 HERITAGE DR ETNA OH 43062-9805 USA "
    },
    {
      "Location ID": "430SI",
      "Location Name": "SIMON SAYS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44 LAZELLE RD COLUMBUS OH 43235 US USA OH 43235 USA "
    },
    {
      "Location ID": "430SM",
      "Location Name": "SMARTBILL (HEBRON)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1050 0 NEILL DR HEBRON OH 43025 USA "
    },
    {
      "Location ID": "430SO",
      "Location Name": "SPEED FC (ETNA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 HERITAGE DR ETNA OH 43062 USA "
    },
    {
      "Location ID": "430SP",
      "Location Name": "SPEED FC (ETNA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 HERITAGE DR ETNA OH 43062-9805 USA "
    },
    {
      "Location ID": "430TO",
      "Location Name": "TORRID",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 ENTERPRISE PKWY W JEFFERSON OH 43162 USA "
    },
    {
      "Location ID": "430WH",
      "Location Name": "WHIPLASH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1225 SOUTHGATE PKWY ETNA OH 43062-6508 USA "
    },
    {
      "Location ID": "430XP",
      "Location Name": "XPO LOGISTICS (ETNA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 HERITAGE DR ETNA OH 43062-9805 USA "
    },
    {
      "Location ID": "43102",
      "Location Name": "AMANDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 144 E MAIN ST AMANDA OH 43102 USA "
    },
    {
      "Location ID": "43103",
      "Location Name": "ASHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 MAIN ST E ASHVILLE OH 43103 USA "
    },
    {
      "Location ID": "43105",
      "Location Name": "BALTIMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 432 W MARKET ST BALTIMORE OH 43105 USA "
    },
    {
      "Location ID": "43106",
      "Location Name": "BLOOMINGBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50 MAIN ST BLOOMINGBURG OH 43106 USA "
    },
    {
      "Location ID": "43107",
      "Location Name": "BREMEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 W MAIN ST BREMEN OH 43107 USA "
    },
    {
      "Location ID": "43109",
      "Location Name": "BRICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3045 BRICE RD BRICE OH 43109 USA "
    },
    {
      "Location ID": "43110",
      "Location Name": "CANAL WINCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 N HIGH ST CANAL WINCHESTER OH 43110 USA "
    },
    {
      "Location ID": "43111",
      "Location Name": "CARBON HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41769 HAWK ST CARBON HILL OH 43111 USA "
    },
    {
      "Location ID": "43112",
      "Location Name": "CARROLL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 53 LOCK ST CARROLL OH 43112 USA "
    },
    {
      "Location ID": "43113",
      "Location Name": "CIRCLEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 224 S COURT ST CIRCLEVILLE OH 43113 USA "
    },
    {
      "Location ID": "43115",
      "Location Name": "CLARKSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17343 HIGH ST CLARKSBURG OH 43115 USA "
    },
    {
      "Location ID": "43116",
      "Location Name": "COMMERCIAL POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 BELSHAZZAR ST COMMERCIAL POINT OH 43116 USA "
    },
    {
      "Location ID": "43119",
      "Location Name": "GALLOWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5820 SULLIVANT AVE GALLOWAY OH 43119 USA "
    },
    {
      "Location ID": "43123",
      "Location Name": "GROVE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2539 DARTMOOR RD GROVE CITY OH 43123 USA "
    },
    {
      "Location ID": "43125",
      "Location Name": "GROVEPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 189 MAIN ST GROVEPORT OH 43125 USA "
    },
    {
      "Location ID": "43126",
      "Location Name": "HARRISBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1019 COLUMBUS ST HARRISBURG OH 43126 USA "
    },
    {
      "Location ID": "43127",
      "Location Name": "HAYDENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16966 HAYDENVILLE RD HAYDENVILLE OH 43127 USA "
    },
    {
      "Location ID": "43128",
      "Location Name": "JEFFERSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 S MAIN ST JEFFERSONVILLE OH 43128 USA "
    },
    {
      "Location ID": "43130",
      "Location Name": "LANCASTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 204 S BROAD ST LANCASTER OH 43130 USA "
    },
    {
      "Location ID": "43135",
      "Location Name": "LAURELVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16088 PIKE ST LAURELVILLE OH 43135 USA "
    },
    {
      "Location ID": "43136",
      "Location Name": "LITHOPOLIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 260 W COLUMBUS ST LITHOPOLIS OH 43136 USA "
    },
    {
      "Location ID": "43137",
      "Location Name": "LOCKBOURNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 MECHANICS ST LOCKBOURNE OH 43137 USA "
    },
    {
      "Location ID": "43138",
      "Location Name": "LOGAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 N MARKET ST LOGAN OH 43138 USA "
    },
    {
      "Location ID": "43140",
      "Location Name": "LONDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 S OAK ST LONDON OH 43140 USA "
    },
    {
      "Location ID": "43142",
      "Location Name": "MILLEDGEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8118 CHURCH ST MILLEDGEVILLE OH 43142 USA "
    },
    {
      "Location ID": "43143",
      "Location Name": "MOUNT STERLING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 W COLUMBUS ST MOUNT STERLING OH 43143 USA "
    },
    {
      "Location ID": "43144",
      "Location Name": "MURRAY CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13990 LOCUST ST MURRAY CITY OH 43144 USA "
    },
    {
      "Location ID": "43145",
      "Location Name": "NEW HOLLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 N MAIN ST NEW HOLLAND OH 43145 USA "
    },
    {
      "Location ID": "43146",
      "Location Name": "ORIENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7820 STAHL RD ORIENT OH 43146 USA "
    },
    {
      "Location ID": "43147",
      "Location Name": "PICKERINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 520 HILL RD N PICKERINGTON OH 43147 USA "
    },
    {
      "Location ID": "43148",
      "Location Name": "PLEASANTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 S MAIN ST PLEASANTVILLE OH 43148 USA "
    },
    {
      "Location ID": "43149",
      "Location Name": "ROCKBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26707 MAIN ST ROCKBRIDGE OH 43149 USA "
    },
    {
      "Location ID": "43150",
      "Location Name": "RUSHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8525 MAIN ST RUSHVILLE OH 43150 USA "
    },
    {
      "Location ID": "43151",
      "Location Name": "SEDALIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 61 FEDERAL ST SW SEDALIA OH 43151 USA "
    },
    {
      "Location ID": "43153",
      "Location Name": "SOUTH SOLON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7230 E MAIN ST SOUTH SOLON OH 43153 USA "
    },
    {
      "Location ID": "43154",
      "Location Name": "STOUTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11438 MAIN ST STOUTSVILLE OH 43154 USA "
    },
    {
      "Location ID": "43155",
      "Location Name": "SUGAR GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 S ELM ST SUGAR GROVE OH 43155 USA "
    },
    {
      "Location ID": "43156",
      "Location Name": "TARLTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 HARRISON ST TARLTON OH 43156 USA "
    },
    {
      "Location ID": "43157",
      "Location Name": "THURSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8053 HIGH ST THURSTON OH 43157 USA "
    },
    {
      "Location ID": "43158",
      "Location Name": "UNION FURNACE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18006 MAIN ST UNION FURNACE OH 43158 USA "
    },
    {
      "Location ID": "43160",
      "Location Name": "WASHINGTON COURT HOUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 129 W MARKET ST WASHINGTON COURT HOUSE OH 43160 USA "
    },
    {
      "Location ID": "43162",
      "Location Name": "WEST JEFFERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 249 W MAIN ST WEST JEFFERSON OH 43162 USA "
    },
    {
      "Location ID": "43164",
      "Location Name": "WILLIAMSPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 S WATER ST WILLIAMSPORT OH 43164 USA "
    },
    {
      "Location ID": "431AL",
      "Location Name": "AMAZON FLEX (LOCKBOURNE)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9570 LOGISTICS CT COLUMBUS OH 43137 USA "
    },
    {
      "Location ID": "431AM",
      "Location Name": "AMAZON CMH2",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6050 GATEWAY CT GROVEPORT OH 43125 USA "
    },
    {
      "Location ID": "431AN",
      "Location Name": "AMAZON CMH5 - WEST JEFFERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6400 STATE ROUTE 29 WEST JEFFERSON OH 43162 USA "
    },
    {
      "Location ID": "431AR",
      "Location Name": "ART.COM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4000 CREEKSIDE PKWY LOCKBOURNE OH 43117 USA "
    },
    {
      "Location ID": "431AZ",
      "Location Name": "AMAZON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3538 TRADEPORT COURT LOCKBOURNE OH 43137 USA "
    },
    {
      "Location ID": "431BA",
      "Location Name": "BEACHBODY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6360 PORT RD GROVEPORT OH 43125 USA "
    },
    {
      "Location ID": "431BB",
      "Location Name": "BUILD A BEAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5925 GREEN POINTE DR S GROVEPORT OH 43125 USA "
    },
    {
      "Location ID": "431BC",
      "Location Name": "BEAUTY COUNTER/DHL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2450 CREEKSIDE PARKWAY LOCKBOURNE OH 43137 USA "
    },
    {
      "Location ID": "431BE",
      "Location Name": "BARE ESCENTUALS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5271 CENTERPOINT PKWY GROVEPORT OH 43125 USA "
    },
    {
      "Location ID": "431CC",
      "Location Name": "CHERYL'S COOKIES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4465 INDUSTRIAL CENTER DR OBETZ OH 43207 USA "
    },
    {
      "Location ID": "431CE",
      "Location Name": "CONCORD EXPRESS (GROVEPORT)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5710 GREEN POINTE DR N STE D GROVEPORT OH 43125 USA "
    },
    {
      "Location ID": "431CL",
      "Location Name": "RADIAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6360  PORT RD GROVEPORT OH 43125 USA "
    },
    {
      "Location ID": "431CR",
      "Location Name": "CARHARTT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6200 WINCHESTER BLVD CANAL WINCHESTER OH 43110 USA "
    },
    {
      "Location ID": "431CS",
      "Location Name": "CYRIL SCOTT CO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3950 LANCASTER NEW LEXINGTON RD SE LANCASTER OH 43130 USA "
    },
    {
      "Location ID": "431CV",
      "Location Name": "CIRCLEVILLE CARRIER UNIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1191 KINGSTON PIKE CIRCLEVILLE OH 43113 USA "
    },
    {
      "Location ID": "431DC",
      "Location Name": "DHL Supply Chain",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2849 ROHR ROAD GROVEPORT OH 43125-9301 USA "
    },
    {
      "Location ID": "431DE",
      "Location Name": "DHL EXPRESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2315 CREEKSIDE PKWY STE 400 LOCKBOURNE OH 43137 USA "
    },
    {
      "Location ID": "431DF",
      "Location Name": "DISTRIBUTION FULFILLMENT SERV",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6600 ALUM CREEK GROVEPORT OH 43125 USA "
    },
    {
      "Location ID": "431DH",
      "Location Name": "DHL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4900 CREEKSIDE PARKWAY LOCKBOURNE OH 43137 USA "
    },
    {
      "Location ID": "431DL",
      "Location Name": "DELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4555 CREEKSIDE DR LOCKBOURNE OH 43117-9287 USA "
    },
    {
      "Location ID": "431DS",
      "Location Name": "DREW SHOE (LANCASTER OH)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 252 QUARRY RD SE LANCASTER OH 43130 USA "
    },
    {
      "Location ID": "431DT",
      "Location Name": "DISCOUNT TIRE DIRECT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2315 CREEKSIDE PARKWAY LOCKBOURNE OH 43117 USA "
    },
    {
      "Location ID": "431DW",
      "Location Name": "DREW SHOE WAREHOUSE (COMMERCE)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2053 COMMERCE ST LANCASTER OH 43130 USA "
    },
    {
      "Location ID": "431EG",
      "Location Name": "EXCEL GROVE CITY - DHL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3650 BROOKHAM DR GROVE CITY OH 43123 USA "
    },
    {
      "Location ID": "431EL",
      "Location Name": "ESSILOR (2400)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2400 SPIEGEL DR STE A GROVEPORT OH 43125 USA "
    },
    {
      "Location ID": "431EP",
      "Location Name": "EXPRESS POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5701 N MEADOWS DR GROVE CITY OH 43123 USA "
    },
    {
      "Location ID": "431ES",
      "Location Name": "ESSILOR (GROVEPORT)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2435 SPIEGEL DR GROVEPORT OH 43125 USA "
    },
    {
      "Location ID": "431EX",
      "Location Name": "EXPEDITORS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6054 SHOOK RD STE 100 LOCKBOURNE OH 43137 USA "
    },
    {
      "Location ID": "431FS",
      "Location Name": "FUNAI SERVICE CORPORATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2425 SPIEGEL DR GROVEPORT OH 43125 USA "
    },
    {
      "Location ID": "431GB",
      "Location Name": "CaaSTLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5650 GREEN POINTE DR N STE C GROVEPORT OH 43125 USA "
    },
    {
      "Location ID": "431GD",
      "Location Name": "GO DIRECT SOLUTIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5830 SALTZGABER RD GROVEPORT OH 43125 USA "
    },
    {
      "Location ID": "431GI",
      "Location Name": "GAP INC DIRECT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6001 GREEN POINTE DR S GROVEPORT OH 43125 USA "
    },
    {
      "Location ID": "431GL",
      "Location Name": "GLASFLOSS (LANCASTER OH)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2168 COMMERCE ST LANCASTER OH 43130 USA "
    },
    {
      "Location ID": "431GP",
      "Location Name": "GROUPON (GROVEPORT)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6400 PORT RD GROVEPORT OH 43125 USA "
    },
    {
      "Location ID": "431GR",
      "Location Name": "GROVE CITY RETAIL STORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2082 STRINGTOWN RD GROVE CITY OH 43123 USA "
    },
    {
      "Location ID": "431GU",
      "Location Name": "GUTHY RENKER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6390 COMMERCE CT GROVEPORT OH 43125 USA "
    },
    {
      "Location ID": "431GY",
      "Location Name": "GYMBOREE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2829 ROHR RD GROVEPORT OH 43125 USA "
    },
    {
      "Location ID": "431IB",
      "Location Name": "INTEGRA BEAUTY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2850 ROHR RD GROVEPORT OH 43125 USA "
    },
    {
      "Location ID": "431JF",
      "Location Name": "JOANN FABRICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1020 ENTERPRISE PKWY WEST JEFFERSON OH 43162 USA "
    },
    {
      "Location ID": "431LA",
      "Location Name": "LULULEMON/ATHLETICA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2200 SPIEGEL DRIVE GROVEPORT OH 43125 USA "
    },
    {
      "Location ID": "431LR",
      "Location Name": "LULULEMON (RETURNS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2450 CREEKSIDE PARKWAY LOCKBOURNE OH 43137 USA "
    },
    {
      "Location ID": "431MC",
      "Location Name": "MERRILL CORP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3400 SOUTHPARK PL STE H GROVE CITY OH 43123 USA "
    },
    {
      "Location ID": "431MD",
      "Location Name": "MEDLINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1040 INDUSTRIAL PKWY WEST JEFFERSON OH 43162 USA "
    },
    {
      "Location ID": "431MG",
      "Location Name": "MAURICES - GEODIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4337 AIR BASE RD ASHVILLE OH 43103 USA "
    },
    {
      "Location ID": "431MO",
      "Location Name": "MID-OHIO FOODBANK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3960 BROOKHAM DRIVE GROVE CITY OH 43123 USA "
    },
    {
      "Location ID": "431NG",
      "Location Name": "NETWORK GLOBAL LOGISTICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1500 COMMODITY DR LOCKBOURNE OH 43137 USA "
    },
    {
      "Location ID": "431NI",
      "Location Name": "NATIONWIDE INSURANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3400 SOUTH PARK GROVE CITY OH 43123 USA "
    },
    {
      "Location ID": "431OW",
      "Location Name": "ONE WRITE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3750 LANCASTER NEW LEXINGTON RD SE LANCASTER OH 43130 USA "
    },
    {
      "Location ID": "431PB",
      "Location Name": "PITNEY BOWES - CANAL WINCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6111 BIXBY RD CANAL WINCHESTER OH 43110 USA "
    },
    {
      "Location ID": "431PD",
      "Location Name": "FEDEX SMARTPOST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2969 LEWIS CENTER WAY GROVE CITY OH 43123 USA "
    },
    {
      "Location ID": "431PL",
      "Location Name": "PORT LOGISTICS GROUP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1675 BEGGROW ST LOCKBOURNE OH 43137 USA "
    },
    {
      "Location ID": "431RB",
      "Location Name": "ROCKY BOOTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37601 ROCKY BOOTS WAY LOGAN OH 43138 USA "
    },
    {
      "Location ID": "431RF",
      "Location Name": "RODAN & FIELDS (LOCKBURNE)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2450 CREEKSIDE PKWY STE 100 LOCKBOURNE OH 43137 USA "
    },
    {
      "Location ID": "431RH",
      "Location Name": "RESTORATION HARDWARE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 ENTERPRISE PKWY WEST JEFFERSON OH 43162 USA "
    },
    {
      "Location ID": "431SB",
      "Location Name": "SPECIALIZED BICYCLE - VSCM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3330 ALUM CREEK DR COLUMBUS OH 43207 USA "
    },
    {
      "Location ID": "431SD",
      "Location Name": "STELLA & DOT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5820 OPUS DR GROVEPORT OH 43125 USA "
    },
    {
      "Location ID": "431SL",
      "Location Name": "SHAKLEE (GROVEPORT OH)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5650 GREEN POINTE DR N STE A GROVEPORT OH 43125 USA "
    },
    {
      "Location ID": "431ST",
      "Location Name": "STAPLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 E HIGH ST LONDON OH 43140 USA "
    },
    {
      "Location ID": "431TC",
      "Location Name": "VENTURE SOLUTIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3125 LEWIS CENTRE WAY GROVE CITY OH 43123 USA "
    },
    {
      "Location ID": "431TR",
      "Location Name": "GROVEPORT (TOYS R US)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2829 ROHR RD GROVEPORT OH 43125 US USA OH 43125 USA "
    },
    {
      "Location ID": "431UP",
      "Location Name": "UPS MAIL INNOVATIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3500 CENTERPOINT DR STE B GROVE CITY OH 43123 USA "
    },
    {
      "Location ID": "431US",
      "Location Name": "USA 111",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5885 GREEN POINTE DR S STE B GROVEPORT OH 43125 US USA OH 43125 USA "
    },
    {
      "Location ID": "431WA",
      "Location Name": "RR DONNELLY (GROVE CITY)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3801 GANTZ RD GROVE CITY OH 43123 USA "
    },
    {
      "Location ID": "431WC",
      "Location Name": "WC NATIONAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4241 WILLIAMS RD GROVEPORT OH 43125-9029 USA "
    },
    {
      "Location ID": "431ZU",
      "Location Name": "ZULILY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3051 CREEKSIDE PARKWAY LOCKBOURNE OH 43137 USA "
    },
    {
      "Location ID": "43201",
      "Location Name": "SHORT NORTH RETAIL UNIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 E 4TH AVE COLUMBUS OH 43201 USA "
    },
    {
      "Location ID": "43202",
      "Location Name": "CLINTONVILLE FINANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2804 N HIGH ST COLUMBUS OH 43202 USA "
    },
    {
      "Location ID": "43203",
      "Location Name": "MOUNT VERNON FINANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 327 N 20TH ST COLUMBUS OH 43203 USA "
    },
    {
      "Location ID": "43204",
      "Location Name": "HILLTOP RETAIL STORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2873 W BROAD ST COLUMBUS OH 43204 USA "
    },
    {
      "Location ID": "43206",
      "Location Name": "GERMAN VILLAGE FINANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 E WHITTIER ST COLUMBUS OH 43206 USA "
    },
    {
      "Location ID": "43207",
      "Location Name": "SOUTH COLUMBUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 445 E INNIS AVE COLUMBUS OH 43207 USA "
    },
    {
      "Location ID": "43209",
      "Location Name": "BEXLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2935 E MAIN ST COLUMBUS OH 43209 USA "
    },
    {
      "Location ID": "43210",
      "Location Name": "UNIVERSITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 234 W 18TH AVE COLUMBUS OH 43210 USA "
    },
    {
      "Location ID": "43211",
      "Location Name": "LINDEN FINANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1981 CLEVELAND AVE COLUMBUS OH 43211 USA "
    },
    {
      "Location ID": "43212",
      "Location Name": "TRI VILLAGE FINANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1391 GRANDVIEW AVE COLUMBUS OH 43212 USA "
    },
    {
      "Location ID": "43213",
      "Location Name": "WHITEHALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3750 E BROAD ST COLUMBUS OH 43213 USA "
    },
    {
      "Location ID": "43214",
      "Location Name": "BEECHWOLD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4364 N HIGH ST COLUMBUS OH 43214 USA "
    },
    {
      "Location ID": "43215",
      "Location Name": "CHRISTOPHER COLUMBUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 N HIGH ST LBBY COLUMBUS OH 43215 USA "
    },
    {
      "Location ID": "43220",
      "Location Name": "NORTHWEST FINANCE UNIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1822 HENDERSON RD COLUMBUS OH 43220 USA "
    },
    {
      "Location ID": "43221",
      "Location Name": "UPPER ARLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 850 TWIN RIVERS DR COLUMBUS OH 43216 USA "
    },
    {
      "Location ID": "43222",
      "Location Name": "CENTRAL POINT FINANCE UNIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1499 W BROAD ST COLUMBUS OH 43222 USA "
    },
    {
      "Location ID": "43224",
      "Location Name": "OAKLAND PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2200 INNIS RD COLUMBUS OH 43224 USA "
    },
    {
      "Location ID": "43227",
      "Location Name": "LIVINGSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3850 E LIVINGSTON AVE COLUMBUS OH 43227 USA "
    },
    {
      "Location ID": "43228",
      "Location Name": "WESTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 770 GEORGESVILLE RD COLUMBUS OH 43228 USA "
    },
    {
      "Location ID": "43229",
      "Location Name": "NORTHLAND RETAIL STORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2469 E DUBLIN GRANVILLE RD COLUMBUS OH 43229 USA "
    },
    {
      "Location ID": "43230",
      "Location Name": "GAHANNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 246 LINCOLN CIR STE H COLUMBUS OH 43230 USA "
    },
    {
      "Location ID": "43232",
      "Location Name": "EASTLAND FINANCE UNIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4255 MACSWAY AVE COLUMBUS OH 43232 USA "
    },
    {
      "Location ID": "43235",
      "Location Name": "WEST WORTHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6316 NICHOLAS DR COLUMBUS OH 43235 USA "
    },
    {
      "Location ID": "432AA",
      "Location Name": "AAA (COLUMBUS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 480 SCHROCK RD STE A COLUMBUS OH 43229-1092 USA "
    },
    {
      "Location ID": "432AB",
      "Location Name": "BEECHWOLD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4364 N HIGH ST COLUMBUS OH 43214-9998 USA "
    },
    {
      "Location ID": "432AC",
      "Location Name": "AC OPTICAL LAB",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4747 ROBERTS RD COLUMBUS OH 43228 USA "
    },
    {
      "Location ID": "432BE",
      "Location Name": "FRANKLIN CO BOARD OF ELECTIONS (COLUMBUS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1700 MORSE RD COLUMBUS OH 43229 USA "
    },
    {
      "Location ID": "432BG",
      "Location Name": "BAESMAN GROUP (COLUMBUS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3820 ZANE TRACE DR COLUMBUS OH 43228 USA "
    },
    {
      "Location ID": "432BI",
      "Location Name": "BOISE CASCADE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2279 W BROOK DR COLUMBUS OH 43228-9643 USA "
    },
    {
      "Location ID": "432BN",
      "Location Name": "BROWNIE POINTS (COLUMBUS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5712 WESTBOURNE AVE COLUMBUS OH 43213-1400 USA "
    },
    {
      "Location ID": "432BR",
      "Location Name": "BASEBALL RAMPAGE (COLUMBUS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3104 SCIOTO DARBY EXECUTIVE CT HILLIARD OH 43026 USA "
    },
    {
      "Location ID": "432BS",
      "Location Name": "COLUMBUS (BRON-SHOE)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1313 ALUM CREEK DR COLUMBUS OH 43209 US USA OH 43209 USA "
    },
    {
      "Location ID": "432BU",
      "Location Name": "BUDDY RC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2041 BUILDERS PL COLUMBUS OH 43204 USA "
    },
    {
      "Location ID": "432CA",
      "Location Name": "EASTLAND CARRIER UNIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2601 COURTRIGHT RD COLUMBUS OH 43232 USA "
    },
    {
      "Location ID": "432CB",
      "Location Name": "CBB SOURS (COLUMBUS OH)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4780 ARLINGTON CENTRE BLVD UNIT 29A COLUMBUS OH 43220-2907 USA "
    },
    {
      "Location ID": "432CC",
      "Location Name": "CRYSTAL CLASSICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6185 HUNTLEY RD COLUMBUS OH 43229 USA "
    },
    {
      "Location ID": "432CD",
      "Location Name": "CASEY'S DISTRIBUTING (COLUMBUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2735 WESTBELT DR COLUMBUS OH 43228-3896 USA "
    },
    {
      "Location ID": "432CF",
      "Location Name": "CRAFTS AMERICANA GROUP (COLUM)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1250 BOLTONFIELD ST COLUMBUS OH 43228 USA "
    },
    {
      "Location ID": "432CG",
      "Location Name": "(COLUMBIA GAS)/NISOURCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4580 BRIDGEWAY AVE, STE A COLUMBUS OH 43219 USA "
    },
    {
      "Location ID": "432CH",
      "Location Name": "COLUMBUS (CHEMICAL ABSTRACT)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4540 OLENTANGY RIVER RD COLUMBUS OH 43210 USA "
    },
    {
      "Location ID": "432CL",
      "Location Name": "CALTRIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1705 ATLAS ST COLUMBUS OH 43228 USA "
    },
    {
      "Location ID": "432CM",
      "Location Name": "CAMPUS MAIL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2500 KENNY RD COLUMBUS OH 43210 USA "
    },
    {
      "Location ID": "432CT",
      "Location Name": "COVETRUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3820 TWIN CREEKS DR COLUMBUS OH 43204 USA "
    },
    {
      "Location ID": "432CU",
      "Location Name": "CITYGATE CALLER UNIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2323 CITYGATE DR COLUMBUS OH 43218 USA "
    },
    {
      "Location ID": "432DS",
      "Location Name": "DSW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4314 E 5TH AVE COLUMBUS OH 43219 USA "
    },
    {
      "Location ID": "432EC",
      "Location Name": "EASTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2601 COURTRIGHT RD COLUMBUS OH 43232-9998 USA "
    },
    {
      "Location ID": "432EK",
      "Location Name": "ELK PROMOTIONS (COLUMBUS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7020 HUNTLEY RD STE B COLUMBUS OH 43229-1050 USA "
    },
    {
      "Location ID": "432ER",
      "Location Name": "EARTHLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 320 OUTERBELT ST COLUMBUS OH 43213 USA "
    },
    {
      "Location ID": "432FA",
      "Location Name": "FORWARD AIR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2865 GEORGE PAIGE JR RD COLUMBUS OH 43217 USA "
    },
    {
      "Location ID": "432FC",
      "Location Name": "FRANKLIN COUNTY COURTHOUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 375 S HIGH ST COLUMBUS OH 43215 USA "
    },
    {
      "Location ID": "432FL",
      "Location Name": "FARO Logistics",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7070 PONTIUS ROAD GROVEPORT OH 43215 USA "
    },
    {
      "Location ID": "432FR",
      "Location Name": "ROUNDHILL FURNITURE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3640 ZANE TRACE DR COLUMBUS OH 43228 USA "
    },
    {
      "Location ID": "432FS",
      "Location Name": "COLUMBUS FSS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 850 TWIN RIVERS DR COLUMBUS OH 43216 USA "
    },
    {
      "Location ID": "432FU",
      "Location Name": "FULFILLMENT CENTER (COLUMBUS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1825 E 25TH AVE COLUMBUS OH 43219 USA "
    },
    {
      "Location ID": "432GA",
      "Location Name": "GAHANNA ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2323 CITYGATE DR OFC 2 COLUMBUS OH 43218 USA "
    },
    {
      "Location ID": "432GC",
      "Location Name": "GERMAN VILLAGE ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1612 LOCKBOURNE RD COLUMBUS OH 43207 USA "
    },
    {
      "Location ID": "432GE",
      "Location Name": "GEODIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4314 E 5TH AVE COLUMBUS OH 43219 USA "
    },
    {
      "Location ID": "432GF",
      "Location Name": "31 GIFTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3425 MORSE XING COLUMBUS OH 43219-6014 USA "
    },
    {
      "Location ID": "432GM",
      "Location Name": "GRANGE MUTUAL INSURANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 650 S FRONT ST COLUMBUS OH 43206 USA "
    },
    {
      "Location ID": "432GO",
      "Location Name": "GO ACTIVE SOLUTIONS (OBETZ OH)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2195 BROEHM RD OBETZ OH 43207 USA "
    },
    {
      "Location ID": "432GV",
      "Location Name": "GERMAN VILLAGE ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1612 LOCKBOURNE RD COLUMBUS OH 43207-9997 USA "
    },
    {
      "Location ID": "432HD",
      "Location Name": "HAYWARD DISTRIBUTING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4061 PERIMETER DR COLUMBUS OH 43228 USA "
    },
    {
      "Location ID": "432HL",
      "Location Name": "James & James",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2235 SOUTHWEST BLVD STE A GROVE CITY OH 43123 USA "
    },
    {
      "Location ID": "432HO",
      "Location Name": "HOMAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2282 WESTBROOKE DR STE K COLUMBUS OH 43228 USA "
    },
    {
      "Location ID": "432HS",
      "Location Name": "HUMAN SERVICES (ODJFS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2088 INTEGERTY DRIVE N COLUMBUS OH 43209 USA "
    },
    {
      "Location ID": "432HT",
      "Location Name": "HUNTINGTON NATIONAL BANK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 EASTON OVAL COLUMBUS OH 43219-6010 USA "
    },
    {
      "Location ID": "432HU",
      "Location Name": "HUCKBERRY (COLUMBUS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4000 CREEKSIDE PKWY STE 100 LOCKBOURNE OH 43137 USA "
    },
    {
      "Location ID": "432JC",
      "Location Name": "JC PENNEY (COLUMBUS CENTER)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5555 SCARBOUGH BLVD COLUMBUS OH 43232 USA "
    },
    {
      "Location ID": "432L0",
      "Location Name": "FST LOGISTICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1727 GEORGESVILLE RD COLUMBUS OH 43228 USA "
    },
    {
      "Location ID": "432LA",
      "Location Name": "MOBY DICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4712 POTH RD COLUMBUS OH 43213 USA "
    },
    {
      "Location ID": "432LB",
      "Location Name": "L BRANDS DC2",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 LIMITED PKWY COLUMBUS OH 43230 USA "
    },
    {
      "Location ID": "432LD",
      "Location Name": "LEADERPROMOS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 915 TAYLOR RD STE 5 COLUMBUS OH 43230 USA "
    },
    {
      "Location ID": "432LE",
      "Location Name": "AC LENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2250 INTERNATIONAL ST COLUMBUS OH 43228 USA "
    },
    {
      "Location ID": "432LO",
      "Location Name": "FST LOGISTICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1727 GEORGESVILLE RD COLUMBUS OH 43228-3619 USA "
    },
    {
      "Location ID": "432MA",
      "Location Name": "MARSALA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 799 N HAGUE AVE COLUMBUS OH 43204 USA "
    },
    {
      "Location ID": "432ME",
      "Location Name": "MERRILL CORP (COLUMBUS OH)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3201 ALBERTA ST COLUMBUS OH 43204-2029 USA "
    },
    {
      "Location ID": "432MI",
      "Location Name": "THE MOTORISTS INSURANCE GROUP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 471 E BROAD ST BSMT COLUMBUS OH 43215 USA "
    },
    {
      "Location ID": "432MM",
      "Location Name": "MERCK MEDCO RX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 255 PHILLIPI RD COLUMBUS OH 43216 US USA OH 43216 USA "
    },
    {
      "Location ID": "432MS",
      "Location Name": "PSI GROUP INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3360 URBANCREST INDUSTRIAL DR GROVE CITY OH 43123 USA "
    },
    {
      "Location ID": "432MV",
      "Location Name": "BUREAU OF MOTOR VEHICLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1970 W BROAD ST COLUMBUS OH 43223 USA "
    },
    {
      "Location ID": "432NC",
      "Location Name": "NORTHLAND CARRIER UNIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5700 MAPLE CANYON AVE COLUMBUS OH 43229 USA "
    },
    {
      "Location ID": "432NK",
      "Location Name": "NAYKED APPAREL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2800 CORPORATE EXCHANGE DR STE 75 COLUMBUS OH 43231 USA "
    },
    {
      "Location ID": "432NO",
      "Location Name": "NORDSTROM 234",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4000 WORTH AVE COLUMBUS OH 43219 USA "
    },
    {
      "Location ID": "432NW",
      "Location Name": "NORTHWEST CARRIER UNIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4905 GETTYSBURG RD COLUMBUS OH 43220 USA "
    },
    {
      "Location ID": "432OD",
      "Location Name": "OHIO DISTRIBUTION WAREHOUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1580 WILLIAMS RD COLUMBUS OH 43207 USA "
    },
    {
      "Location ID": "432ON",
      "Location Name": "ONE WORLD DIRECT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 315 PHILLIPI RD COLUMBUS OH 43228 US USA OH 43228 USA "
    },
    {
      "Location ID": "432OP",
      "Location Name": "OPERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 277 E TOWN ST COLUMBUS OH 43215 USA "
    },
    {
      "Location ID": "432OW",
      "Location Name": "ONE WORLD DIRECT (COLUMBUS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 315 PHILLIPI RD COLUMBUS OH 43228-1309 USA "
    },
    {
      "Location ID": "432PD",
      "Location Name": "POWER DISTRIBUTORS (COLUMBUS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3700 PARAGON DR COLUMBUS OH 43228 USA "
    },
    {
      "Location ID": "432PS",
      "Location Name": "PRINT SYNDICATE (COLUMBUS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1275 KINNEAR RD STE 130 COLUMBUS OH 43212 USA "
    },
    {
      "Location ID": "432PV",
      "Location Name": "PALEO VALLEY - COLUMBUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1621 GEORGESVILLE RD COLUMBUS OH 43228 USA "
    },
    {
      "Location ID": "432PW",
      "Location Name": "PARTS WAREHOUSE OHIO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1751 DIVIDEND DR COLUMBUS OH 43228 USA "
    },
    {
      "Location ID": "432RF",
      "Location Name": "ROGUE FITNESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 511 E 5TH AVE COLUMBUS OH 43201 USA "
    },
    {
      "Location ID": "432RH",
      "Location Name": "OPPOR FOR OHIOANS W DISABIL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 E CAMPUS VIEW BLVD COLUMBUS OH 43235 USA "
    },
    {
      "Location ID": "432RP",
      "Location Name": "ROCK FLOWER PAPER COLUMBUS OH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6575 HUNTLEY RD STE I COLUMBUS OH 43229 USA "
    },
    {
      "Location ID": "432RS",
      "Location Name": "ROADRUNNER SPORTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5327 FISHER RD COLUMBUS OH 43228 USA "
    },
    {
      "Location ID": "432SA",
      "Location Name": "STATE AUTO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 518 E BROAD ST COLUMBUS OH 43215-3901 USA "
    },
    {
      "Location ID": "432SB",
      "Location Name": "SALLY BEAUTY SUPPLY LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 43228 JANITROL RD COLUMBUS OH 43228-1301 USA "
    },
    {
      "Location ID": "432SE",
      "Location Name": "SERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 E BROAD ST STE 100 COLUMBUS OH 43215 USA "
    },
    {
      "Location ID": "432SG",
      "Location Name": "STEPHEN GOULD (GHANNA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 861 TAYLOR RD STE 1 GAHANNA OH 43230 USA "
    },
    {
      "Location ID": "432SH",
      "Location Name": "SHIPPING TREE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4720 POTH RD COLUMBUS OH 43213 USA "
    },
    {
      "Location ID": "432SI",
      "Location Name": "SPORTS IMAGE (COLUMBUS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4777 ROBERTS RD COLUMBUS OH 43228 USA "
    },
    {
      "Location ID": "432SL",
      "Location Name": "STATE LIBRARY OF OHIO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 274 E 1ST AVE STE 100 COLUMBUS OH 43201 USA "
    },
    {
      "Location ID": "432SS",
      "Location Name": "SIMON SAYS STAMP (COLUMBUS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44 LAZELLE RD E COLUMBUS OH 43235 USA "
    },
    {
      "Location ID": "432ST",
      "Location Name": "STATE OFFICE TOWER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 E BROAD ST COLUMBUS OH 43215 USA "
    },
    {
      "Location ID": "432TF",
      "Location Name": "TROY FILTERS (COLUMBUS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1680 WEST BELT DR COLUMBUS OH 43228 USA "
    },
    {
      "Location ID": "432TM",
      "Location Name": "TECH-MART (COLUMBUS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2761 WESTBELT DR COLUMBUS OH 43228 USA "
    },
    {
      "Location ID": "432TP",
      "Location Name": "TACPACK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 527 SCHROCK RD COLUMBUS OH 43229-1081 USA "
    },
    {
      "Location ID": "432UL",
      "Location Name": "COLUMBUS (UNIVERSITY LIBRARY)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1858 NEIL AVE COLUMBUS OH 43210 USA "
    },
    {
      "Location ID": "432UM",
      "Location Name": "COLUMBUS (UNIV MED CENTER)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 450 W 1OTH AVE COLUMBUS OH 43210 USA "
    },
    {
      "Location ID": "432UP",
      "Location Name": "UPS - TRABUE (COLUMBUS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5101 TRABUE RD COLUMBUS OH 43228 USA "
    },
    {
      "Location ID": "432UT",
      "Location Name": "ULTRA PRO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4100 LOCKBOURNE RD COLUMBUS OH 43207 USA "
    },
    {
      "Location ID": "432VA",
      "Location Name": "COLUMBUS (VETERAN'S AFFAIRS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 420 NORTH JAMES RD COLUMBUS OH 43219 USA "
    },
    {
      "Location ID": "432VS",
      "Location Name": "VICTORIA'S SECRET (COLUMBUS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8655 E BROAD ST REYNOLDSBURG OH 43068 USA "
    },
    {
      "Location ID": "432VX",
      "Location Name": "VALEX (COLUMBUS OH)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4331 EQUITY DR COLUMBUS OH 43228-3842 USA "
    },
    {
      "Location ID": "432WC",
      "Location Name": "WHITEHALL CARRIER  ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 83 ROBINWOOD AVE COLUMBUS OH 43213 USA "
    },
    {
      "Location ID": "432WP",
      "Location Name": "WATKINS PRINTING CO (COLUMBUS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1401 E 17TH AVE COLUMBUS OH 43211 USA "
    },
    {
      "Location ID": "432ZO",
      "Location Name": "ZENNI OPTICAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4531 INDUSTRIAL CENTER DR OBETZ OH 43207 USA "
    },
    {
      "Location ID": "43302",
      "Location Name": "MARION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 230 BARKS RD W MARION OH 43302 USA "
    },
    {
      "Location ID": "43310",
      "Location Name": "BELLE CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 W MAIN ST BELLE CENTER OH 43310 USA "
    },
    {
      "Location ID": "43311",
      "Location Name": "BELLEFONTAINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 132 S DETROIT ST BELLEFONTAINE OH 43311 USA "
    },
    {
      "Location ID": "43314",
      "Location Name": "CALEDONIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 128 S MAIN ST CALEDONIA OH 43314 USA "
    },
    {
      "Location ID": "43315",
      "Location Name": "CARDINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 S MARION ST CARDINGTON OH 43315 USA "
    },
    {
      "Location ID": "43316",
      "Location Name": "CAREY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 128 N VANCE ST CAREY OH 43316 USA "
    },
    {
      "Location ID": "43317",
      "Location Name": "CHESTERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44 E SANDUSKY ST CHESTERVILLE OH 43317 USA "
    },
    {
      "Location ID": "43318",
      "Location Name": "DE GRAFF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 S MAIN ST DE GRAFF OH 43318 USA "
    },
    {
      "Location ID": "43319",
      "Location Name": "EAST LIBERTY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3236 S MAIN ST EAST LIBERTY OH 43319 USA "
    },
    {
      "Location ID": "43320",
      "Location Name": "EDISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 N BOUNDARY ST EDISON OH 43320 USA "
    },
    {
      "Location ID": "43321",
      "Location Name": "FULTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 E MAIN ST FULTON OH 43321 USA "
    },
    {
      "Location ID": "43322",
      "Location Name": "GREEN CAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 216 MARION ST GREEN CAMP OH 43322 USA "
    },
    {
      "Location ID": "43323",
      "Location Name": "HARPSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7272 WYANDOT ST HARPSTER OH 43323 USA "
    },
    {
      "Location ID": "43324",
      "Location Name": "HUNTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6493 LIMA ST HUNTSVILLE OH 43324 USA "
    },
    {
      "Location ID": "43325",
      "Location Name": "IBERIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3570 STATE ROUTE 309 IBERIA OH 43325 USA "
    },
    {
      "Location ID": "43326",
      "Location Name": "KENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 305 W FRANKLIN ST KENTON OH 43326 USA "
    },
    {
      "Location ID": "43330",
      "Location Name": "KIRBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 138 N MAIN ST KIRBY OH 43330 USA "
    },
    {
      "Location ID": "43331",
      "Location Name": "LAKEVIEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 160 S MAIN ST LAKEVIEW OH 43331 USA "
    },
    {
      "Location ID": "43332",
      "Location Name": "LA RUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 65 S HIGH ST LA RUE OH 43332 USA "
    },
    {
      "Location ID": "43333",
      "Location Name": "LEWISTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7670 COUNTY ROAD 91 LEWISTOWN OH 43333 USA "
    },
    {
      "Location ID": "43334",
      "Location Name": "MARENGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 97 S MAIN ST MARENGO OH 43334 USA "
    },
    {
      "Location ID": "43336",
      "Location Name": "MIDDLEBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12020 GREEN ST MIDDLEBURG OH 43336 USA "
    },
    {
      "Location ID": "43337",
      "Location Name": "MORRAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 W NEFF ST MORRAL OH 43337 USA "
    },
    {
      "Location ID": "43338",
      "Location Name": "MOUNT GILEAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37 W HIGH ST MOUNT GILEAD OH 43338 USA "
    },
    {
      "Location ID": "43340",
      "Location Name": "MOUNT VICTORY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 S MAIN ST MOUNT VICTORY OH 43340 USA "
    },
    {
      "Location ID": "43341",
      "Location Name": "NEW BLOOMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 160 S MAIN ST NEW BLOOMINGTON OH 43341 USA "
    },
    {
      "Location ID": "43342",
      "Location Name": "PROSPECT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 N MAIN ST PROSPECT OH 43342 USA "
    },
    {
      "Location ID": "43343",
      "Location Name": "QUINCY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 S MIAMI ST QUINCY OH 43343 USA "
    },
    {
      "Location ID": "43344",
      "Location Name": "RICHWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 E OTTAWA ST RICHWOOD OH 43344 USA "
    },
    {
      "Location ID": "43345",
      "Location Name": "RIDGEWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 E HALE ST RIDGEWAY OH 43345 USA "
    },
    {
      "Location ID": "43346",
      "Location Name": "ROUNDHEAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17770 N MAIN ST ROUNDHEAD OH 43346 USA "
    },
    {
      "Location ID": "43347",
      "Location Name": "RUSHSYLVANIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 N SANDUSKY ST RUSHSYLVANIA OH 43347 USA "
    },
    {
      "Location ID": "43348",
      "Location Name": "RUSSELLS POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 901 STATE ROUTE 708 RUSSELLS POINT OH 43348 USA "
    },
    {
      "Location ID": "43350",
      "Location Name": "SPARTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 W MAIN ST SPARTA OH 43350 USA "
    },
    {
      "Location ID": "43351",
      "Location Name": "UPPER SANDUSKY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 122 W WYANDOT AVE UPPER SANDUSKY OH 43351 USA "
    },
    {
      "Location ID": "43356",
      "Location Name": "WALDO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 S MARION ST WALDO OH 43356 USA "
    },
    {
      "Location ID": "43357",
      "Location Name": "WEST LIBERTY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 E BAIRD ST WEST LIBERTY OH 43357 USA "
    },
    {
      "Location ID": "43358",
      "Location Name": "WEST MANSFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 N MAIN ST WEST MANSFIELD OH 43358 USA "
    },
    {
      "Location ID": "43359",
      "Location Name": "WHARTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 124 W SANDUSKY ST WHARTON OH 43359 USA "
    },
    {
      "Location ID": "43360",
      "Location Name": "ZANESFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2856 SANDUSKY ST ZANESFIELD OH 43360 USA "
    },
    {
      "Location ID": "433CO",
      "Location Name": "5 CORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1221 W SANDUSKY AVE BELLEFONTAINE OH 43311 USA "
    },
    {
      "Location ID": "433RU",
      "Location Name": "RUDIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 W OTTAWA ST RICHWOOD OH 43344 USA "
    },
    {
      "Location ID": "433TL",
      "Location Name": "MR. TAILIGHT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 211 RAILROAD ST EDISON OH 43320 USA "
    },
    {
      "Location ID": "434",
      "Location Name": "LOG TOLEDO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 435 S SAINT CLAIR ST TOLEDO OH 43601 USA "
    },
    {
      "Location ID": "43402",
      "Location Name": "BOWLING GREEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 W WASHINGTON ST BOWLING GREEN OH 43402 USA "
    },
    {
      "Location ID": "43420",
      "Location Name": "FREMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2120 ENTERPRISE ST FREMONT OH 43420 USA "
    },
    {
      "Location ID": "43430",
      "Location Name": "GENOA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 606 WASHINGTON ST GENOA OH 43430 USA "
    },
    {
      "Location ID": "43440",
      "Location Name": "LAKESIDE MARBLEHEAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1306 W MAIN ST LAKESIDE MARBLEHEAD OH 43440 USA "
    },
    {
      "Location ID": "43449",
      "Location Name": "OAK HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 126 N CHURCH ST OAK HARBOR OH 43449 USA "
    },
    {
      "Location ID": "43452",
      "Location Name": "PORT CLINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 W 2ND ST PORT CLINTON OH 43452 USA "
    },
    {
      "Location ID": "43469",
      "Location Name": "WOODVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 W MAIN ST WOODVILLE OH 43469 USA "
    },
    {
      "Location ID": "43506",
      "Location Name": "BRYAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 142 N MAIN ST BRYAN OH 43506 USA "
    },
    {
      "Location ID": "43510",
      "Location Name": "COLTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 637 HENRY ST COLTON OH 43510 USA "
    },
    {
      "Location ID": "43512",
      "Location Name": "DEFIANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 420 W 2ND ST DEFIANCE OH 43512 USA "
    },
    {
      "Location ID": "43515",
      "Location Name": "DELTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6660 US HIGHWAY 20A DELTA OH 43515 USA "
    },
    {
      "Location ID": "43519",
      "Location Name": "EVANSPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18883 MAIN ST EVANSPORT OH 43519 USA "
    },
    {
      "Location ID": "43520",
      "Location Name": "FARMER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3948 STATE ROUTE 2 FARMER OH 43520 USA "
    },
    {
      "Location ID": "43522",
      "Location Name": "GRAND RAPIDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17565 WAPAKONETA RD GRAND RAPIDS OH 43522 USA "
    },
    {
      "Location ID": "43523",
      "Location Name": "GRELTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6944 COUNTY ROAD M GRELTON OH 43523 USA "
    },
    {
      "Location ID": "43524",
      "Location Name": "HAMLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 335 RANDOLPH ST HAMLER OH 43524 USA "
    },
    {
      "Location ID": "43526",
      "Location Name": "HICKSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 E EDGERTON ST HICKSVILLE OH 43526 USA "
    },
    {
      "Location ID": "43527",
      "Location Name": "HOLGATE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 165 N WILHELM ST HOLGATE OH 43527 USA "
    },
    {
      "Location ID": "43532",
      "Location Name": "LIBERTY CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 EAST ST LIBERTY CENTER OH 43532 USA "
    },
    {
      "Location ID": "43534",
      "Location Name": "MC CLURE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 232 S EAST ST MC CLURE OH 43534 USA "
    },
    {
      "Location ID": "43535",
      "Location Name": "MALINTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 N TURKEYFOOT AVE MALINTA OH 43535 USA "
    },
    {
      "Location ID": "43536",
      "Location Name": "MARK CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10577 FARMER MARK RD MARK CENTER OH 43536 USA "
    },
    {
      "Location ID": "43537",
      "Location Name": "MAUMEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1375 FORD ST MAUMEE OH 43537 USA "
    },
    {
      "Location ID": "43542",
      "Location Name": "MONCLOVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7959 MONCLOVA RD MONCLOVA OH 43542 USA "
    },
    {
      "Location ID": "43543",
      "Location Name": "MONTPELIER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 EMPIRE ST MONTPELIER OH 43543 USA "
    },
    {
      "Location ID": "43545",
      "Location Name": "NAPOLEON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 W WASHINGTON ST NAPOLEON OH 43545 USA "
    },
    {
      "Location ID": "43547",
      "Location Name": "NEAPOLIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8250 MAIN ST NEAPOLIS OH 43547 USA "
    },
    {
      "Location ID": "43548",
      "Location Name": "NEW BAVARIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 867 WALNUT ST NEW BAVARIA OH 43548 USA "
    },
    {
      "Location ID": "43549",
      "Location Name": "NEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 170 E MAIN ST NEY OH 43549 USA "
    },
    {
      "Location ID": "43551",
      "Location Name": "PERRYSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1205 LOUISIANA AVE PERRYSBURG OH 43551 USA "
    },
    {
      "Location ID": "43554",
      "Location Name": "PIONEER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 W 1ST ST PIONEER OH 43554 USA "
    },
    {
      "Location ID": "43555",
      "Location Name": "RIDGEVILLE CORNERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20348 COUNTY ROAD X RIDGEVILLE CORNERS OH 43555 USA "
    },
    {
      "Location ID": "43556",
      "Location Name": "SHERWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 N HARRISON ST SHERWOOD OH 43556 USA "
    },
    {
      "Location ID": "43558",
      "Location Name": "SWANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 S HALLETT AVE SWANTON OH 43558 USA "
    },
    {
      "Location ID": "43566",
      "Location Name": "WATERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 S 3RD ST WATERVILLE OH 43566 USA "
    },
    {
      "Location ID": "43567",
      "Location Name": "WAUSEON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 124 E ELM ST WAUSEON OH 43567 USA "
    },
    {
      "Location ID": "43570",
      "Location Name": "WEST UNITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 E JACKSON ST WEST UNITY OH 43570 USA "
    },
    {
      "Location ID": "43571",
      "Location Name": "WHITEHOUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6822 PROVIDENCE ST WHITEHOUSE OH 43571 USA "
    },
    {
      "Location ID": "435SC",
      "Location Name": "SPANGLER CANDY (BRYAN OH)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 N PORTLAND ST BRYAN OH 43506 USA "
    },
    {
      "Location ID": "437",
      "Location Name": "ZANESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 900 MCINTIRE AVE ZANESVILLE OH 43701 USA "
    },
    {
      "Location ID": "43701",
      "Location Name": "ZANESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 900 MCINTIRE AVE ZANESVILLE OH 43701-9998 USA "
    },
    {
      "Location ID": "43711",
      "Location Name": "AVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 50614 STATE ROUTE 821 AVA OH 43711 USA "
    },
    {
      "Location ID": "43713",
      "Location Name": "BARNESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 E CHURCH ST BARNESVILLE OH 43713 USA "
    },
    {
      "Location ID": "43716",
      "Location Name": "BEALLSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 52731 OHIO AVE BEALLSVILLE OH 43716 USA "
    },
    {
      "Location ID": "43717",
      "Location Name": "BELLE VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 LIBERTY ST BELLE VALLEY OH 43717 USA "
    },
    {
      "Location ID": "43718",
      "Location Name": "BELMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 128 W MAIN ST BELMONT OH 43718 USA "
    },
    {
      "Location ID": "43719",
      "Location Name": "BETHESDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 S MAIN ST BETHESDA OH 43719 USA "
    },
    {
      "Location ID": "43720",
      "Location Name": "BLUE ROCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8780 S RIVER RD BLUE ROCK OH 43720 USA "
    },
    {
      "Location ID": "43722",
      "Location Name": "BUFFALO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11985 CLAY PIKE RD BUFFALO OH 43722 USA "
    },
    {
      "Location ID": "43723",
      "Location Name": "BYESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 WATSON AVE BYESVILLE OH 43723 USA "
    },
    {
      "Location ID": "43724",
      "Location Name": "CALDWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 601 WEST ST CALDWELL OH 43724 USA "
    },
    {
      "Location ID": "43725",
      "Location Name": "CAMBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 954 WHEELING AVE CAMBRIDGE OH 43725 USA "
    },
    {
      "Location ID": "43727",
      "Location Name": "CHANDLERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8875 CHANDLERSVILLE RD CHANDLERSVILLE OH 43727 USA "
    },
    {
      "Location ID": "43728",
      "Location Name": "CHESTERHILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7402 MARION ST CHESTERHILL OH 43728 USA "
    },
    {
      "Location ID": "43730",
      "Location Name": "CORNING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 WASHINGTON ST CORNING OH 43730 USA "
    },
    {
      "Location ID": "43731",
      "Location Name": "CROOKSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 E MAIN ST CROOKSVILLE OH 43731 USA "
    },
    {
      "Location ID": "43732",
      "Location Name": "CUMBERLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 MILL ST CUMBERLAND OH 43732 USA "
    },
    {
      "Location ID": "43733",
      "Location Name": "DERWENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10936 CLAY PIKE RD DERWENT OH 43733 USA "
    },
    {
      "Location ID": "43734",
      "Location Name": "DUNCAN FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 315 MAIN ST DUNCAN FALLS OH 43734 USA "
    },
    {
      "Location ID": "43735",
      "Location Name": "EAST FULTONHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6970 AXLINE AVE EAST FULTONHAM OH 43735 USA "
    },
    {
      "Location ID": "43739",
      "Location Name": "GLENFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4627 STATE ROUTE 204 GLENFORD OH 43739 USA "
    },
    {
      "Location ID": "43740",
      "Location Name": "GRATIOT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 349 MAIN ST GRATIOT OH 43740 USA "
    },
    {
      "Location ID": "43747",
      "Location Name": "JERUSALEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 51666 MAIN ST JERUSALEM OH 43747 USA "
    },
    {
      "Location ID": "43748",
      "Location Name": "JUNCTION CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 W MAIN ST JUNCTION CITY OH 43748 USA "
    },
    {
      "Location ID": "43749",
      "Location Name": "KIMBOLTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 S MAIN ST KIMBOLTON OH 43749 USA "
    },
    {
      "Location ID": "43750",
      "Location Name": "KIPLING",
      "Time Zone Offset (Hours)": 0,
      "Address": " MCKINLEY ST USA OH 43750 USA "
    },
    {
      "Location ID": "43754",
      "Location Name": "LEWISVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33212 RAILROAD ST LEWISVILLE OH 43754 USA "
    },
    {
      "Location ID": "43755",
      "Location Name": "LORE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 MAIN ST NW LORE CITY OH 43755 USA "
    },
    {
      "Location ID": "43756",
      "Location Name": "MCCONNELSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 83 S KENNEBEC AVE MCCONNELSVILLE OH 43756 USA "
    },
    {
      "Location ID": "43758",
      "Location Name": "MALTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 534 N MAIN ST MALTA OH 43758 USA "
    },
    {
      "Location ID": "43759",
      "Location Name": "MORRISTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 126 E MAIN ST MORRISTOWN OH 43759 USA "
    },
    {
      "Location ID": "43760",
      "Location Name": "MOUNT PERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5142 STATE ROUTE 204 USA OH 43760 USA "
    },
    {
      "Location ID": "43761",
      "Location Name": "MOXAHALA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3310 STATE ROUTE 13 SE MOXAHALA OH 43761 USA "
    },
    {
      "Location ID": "43762",
      "Location Name": "NEW CONCORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 61 W MAIN ST NEW CONCORD OH 43762 USA "
    },
    {
      "Location ID": "43764",
      "Location Name": "NEW LEXINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 W BROWN ST NEW LEXINGTON OH 43764 USA "
    },
    {
      "Location ID": "43766",
      "Location Name": "NEW STRAITSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 214 W MAIN ST NEW STRAITSVILLE OH 43766 USA "
    },
    {
      "Location ID": "43767",
      "Location Name": "NORWICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10445 MAIN ST NORWICH OH 43767 USA "
    },
    {
      "Location ID": "43768",
      "Location Name": "OLD WASHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 204 OLD NATIONAL RD OLD WASHINGTON OH 43768 USA "
    },
    {
      "Location ID": "43771",
      "Location Name": "PHILO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 265 MAIN ST PHILO OH 43771 USA "
    },
    {
      "Location ID": "43772",
      "Location Name": "PLEASANT CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 MAIN ST PLEASANT CITY OH 43772 USA "
    },
    {
      "Location ID": "43773",
      "Location Name": "QUAKER CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 151 SOUTH ST QUAKER CITY OH 43773 USA "
    },
    {
      "Location ID": "43777",
      "Location Name": "ROSEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 226 N MAIN ST ROSEVILLE OH 43777 USA "
    },
    {
      "Location ID": "43780",
      "Location Name": "SENECAVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 HIGH ST SENECAVILLE OH 43780 USA "
    },
    {
      "Location ID": "43783",
      "Location Name": "SOMERSET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 320 S COLUMBUS ST SOMERSET OH 43783 USA "
    },
    {
      "Location ID": "43787",
      "Location Name": "STOCKPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1975 MAIN ST STOCKPORT OH 43787 USA "
    },
    {
      "Location ID": "43788",
      "Location Name": "SUMMERFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 W CROSS ST SUMMERFIELD OH 43788 USA "
    },
    {
      "Location ID": "43791",
      "Location Name": "WHITE COTTAGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3880 ROBERTS ADDITION WHITE COTTAGE OH 43791 USA "
    },
    {
      "Location ID": "43793",
      "Location Name": "WOODSFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 N MAIN ST WOODSFIELD OH 43793 USA "
    },
    {
      "Location ID": "437AB",
      "Location Name": "ZANE STREET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1035 ZANE STREET ZANESVILLE OH 43701 USA "
    },
    {
      "Location ID": "437AV",
      "Location Name": "AVON (ZANESVILLE)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3603 EASTPOINTE DR ZANESVILLE OH 43701 USA "
    },
    {
      "Location ID": "437BW",
      "Location Name": "B WEAR SPORTWEAR (ZANESVILLE)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 5 BS DR ZANESVILLE OH 43701 USA "
    },
    {
      "Location ID": "437ZA",
      "Location Name": "ZANESVILLE (RETAIL UNIT)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1035 ZANE ST ZANESVILLE OH 43701 USA "
    },
    {
      "Location ID": "43802",
      "Location Name": "ADAMSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8125 EAST ST ADAMSVILLE OH 43802 USA "
    },
    {
      "Location ID": "43804",
      "Location Name": "BALTIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 N RAY ST BALTIC OH 43804 USA "
    },
    {
      "Location ID": "43811",
      "Location Name": "CONESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 504 MARQUAND AVE CONESVILLE OH 43811 USA "
    },
    {
      "Location ID": "43812",
      "Location Name": "COSHOCTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 516 CHESTNUT ST FRNT COSHOCTON OH 43812 USA "
    },
    {
      "Location ID": "43821",
      "Location Name": "DRESDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 W 4TH ST DRESDEN OH 43821 USA "
    },
    {
      "Location ID": "43822",
      "Location Name": "FRAZEYSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 N STATE ST FRAZEYSBURG OH 43822 USA "
    },
    {
      "Location ID": "43824",
      "Location Name": "FRESNO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 54259 TOWNSHIP ROAD 172 FRESNO OH 43824 USA "
    },
    {
      "Location ID": "43830",
      "Location Name": "NASHPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7764 OBANNON RD NASHPORT OH 43830 USA "
    },
    {
      "Location ID": "43832",
      "Location Name": "NEWCOMERSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 133 W CANAL ST NEWCOMERSTOWN OH 43832 USA "
    },
    {
      "Location ID": "43836",
      "Location Name": "PLAINFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 E MAIN ST PLAINFIELD OH 43836 USA "
    },
    {
      "Location ID": "43837",
      "Location Name": "PORT WASHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 SAINT CLAIRSVILLE RD SW PORT WASHINGTON OH 43837 USA "
    },
    {
      "Location ID": "43840",
      "Location Name": "STONE CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 W JEFFERSON ST STONE CREEK OH 43840 USA "
    },
    {
      "Location ID": "43842",
      "Location Name": "TRINWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12925 MAIN ST TRINWAY OH 43842 USA "
    },
    {
      "Location ID": "43843",
      "Location Name": "WALHONDING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33118 STATE ROUTE 715 WALHONDING OH 43843 USA "
    },
    {
      "Location ID": "43844",
      "Location Name": "WARSAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 803 E MAIN ST WARSAW OH 43844 USA "
    },
    {
      "Location ID": "43845",
      "Location Name": "WEST LAFAYETTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 501 W MAIN ST WEST LAFAYETTE OH 43845 USA "
    },
    {
      "Location ID": "438FA",
      "Location Name": "FANATICS (FRAZEYSBURG)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5563 RAIDERS RD FRAZEYSBURG OH 43822 USA "
    },
    {
      "Location ID": "438LB",
      "Location Name": "LONGABERGER (ZANESVILLE)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4005 ALL AMERICAN WAY ZANESVILLE OH 43701 US USA OH 43701 USA "
    },
    {
      "Location ID": "438QS",
      "Location Name": "QUALITY SCHOOL TEXT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 53671 TOWNSHIP ROAD 508 FRESNO OH 43824-9736 USA "
    },
    {
      "Location ID": "439",
      "Location Name": "STEUBENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 N 3RD ST STEUBENVILLE OH 43952 USA "
    },
    {
      "Location ID": "43901",
      "Location Name": "ADENA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 W MAIN ST ADENA OH 43901 USA "
    },
    {
      "Location ID": "43903",
      "Location Name": "AMSTERDAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 S MAIN ST AMSTERDAM OH 43903 USA "
    },
    {
      "Location ID": "43907",
      "Location Name": "CADIZ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 82422 CADIZ JEWETT RD CADIZ OH 43907 USA "
    },
    {
      "Location ID": "43908",
      "Location Name": "BERGHOLZ",
      "Time Zone Offset (Hours)": 0,
      "Address": " 254 2ND ST BERGHOLZ OH 43908 USA "
    },
    {
      "Location ID": "43910",
      "Location Name": "BLOOMINGDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 HIGH ST BLOOMINGDALE OH 43910 USA "
    },
    {
      "Location ID": "43913",
      "Location Name": "BRILLIANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 801 3RD ST BRILLIANT OH 43913 USA "
    },
    {
      "Location ID": "43917",
      "Location Name": "DILLONVALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 132 SMITHFIELD ST DILLONVALE OH 43917 USA "
    },
    {
      "Location ID": "43925",
      "Location Name": "EAST SPRINGFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9889 COUNTY ROAD 39 EAST SPRINGFIELD OH 43925 USA "
    },
    {
      "Location ID": "43938",
      "Location Name": "MINGO JUNCTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 679 COMMERCIAL ST MINGO JUNCTION OH 43938 USA "
    },
    {
      "Location ID": "43944",
      "Location Name": "RICHMOND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 E MAIN ST RICHMOND OH 43944 USA "
    },
    {
      "Location ID": "43948",
      "Location Name": "SMITHFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1303 MAIN ST SMITHFIELD OH 43948 USA "
    },
    {
      "Location ID": "43953",
      "Location Name": "WINTERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 LURAY DR STEUBENVILLE OH 43953 USA "
    },
    {
      "Location ID": "43974",
      "Location Name": "HARRISVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 49364 MAIN ST HARRISVILLE OH 43974 USA "
    },
    {
      "Location ID": "43976",
      "Location Name": "HOPEDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 165 MILLER STATION RD HOPEDALE OH 43976 USA "
    },
    {
      "Location ID": "43981",
      "Location Name": "NEW ATHENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 128 S MAIN ST NEW ATHENS OH 43981 USA "
    },
    {
      "Location ID": "43986",
      "Location Name": "JEWETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 W MAIN ST JEWETT OH 43986 USA "
    },
    {
      "Location ID": "43988",
      "Location Name": "SCIO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 E MAIN ST SCIO OH 43988 USA "
    },
    {
      "Location ID": "440",
      "Location Name": "LOG CLEVELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2400 ORANGE AVE RM 30 CLEVELAND OH 44101 USA "
    },
    {
      "Location ID": "44003",
      "Location Name": "ANDOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 275 E MAIN ST ANDOVER OH 44003 USA "
    },
    {
      "Location ID": "44004",
      "Location Name": "ASHTABULA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4307 STATION AVE ASHTABULA OH 44004 USA "
    },
    {
      "Location ID": "44010",
      "Location Name": "AUSTINBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2773 STATE ROUTE 307 AUSTINBURG OH 44010 USA "
    },
    {
      "Location ID": "44021",
      "Location Name": "BURTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14519 N CHESHIRE ST BURTON OH 44021 USA "
    },
    {
      "Location ID": "44022",
      "Location Name": "CHAGRIN FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 160 E WASHINGTON ST CHAGRIN FALLS OH 44022 USA "
    },
    {
      "Location ID": "44024",
      "Location Name": "CHARDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 CENTER ST CHARDON OH 44024 USA "
    },
    {
      "Location ID": "44026",
      "Location Name": "CHESTERLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12804 CHILLICOTHE RD CHESTERLAND OH 44026 USA "
    },
    {
      "Location ID": "44030",
      "Location Name": "CONNEAUT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 268 STATE ST CONNEAUT OH 44030 USA "
    },
    {
      "Location ID": "44032",
      "Location Name": "DORSET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2477 STATE ROUTE 193 DORSET OH 44032 USA "
    },
    {
      "Location ID": "44040",
      "Location Name": "GATES MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1500 CHAGRIN RIVER RD GATES MILLS OH 44040 USA "
    },
    {
      "Location ID": "44041",
      "Location Name": "GENEVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1041 S BROADWAY GENEVA OH 44041 USA "
    },
    {
      "Location ID": "44047",
      "Location Name": "JEFFERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37 W JEFFERSON ST JEFFERSON OH 44047 USA "
    },
    {
      "Location ID": "44048",
      "Location Name": "KINGSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3110 W MAIN ST KINGSVILLE OH 44048 USA "
    },
    {
      "Location ID": "44057",
      "Location Name": "MADISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 375 N LAKE ST MADISON OH 44057 USA "
    },
    {
      "Location ID": "44060",
      "Location Name": "MENTOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8600 TYLER BLVD MENTOR OH 44060 USA "
    },
    {
      "Location ID": "44062",
      "Location Name": "MIDDLEFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15993 E HIGH ST MIDDLEFIELD OH 44062 USA "
    },
    {
      "Location ID": "44065",
      "Location Name": "NEWBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10756 KINSMAN RD NEWBURY OH 44065 USA "
    },
    {
      "Location ID": "44067",
      "Location Name": "NORTHFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 E AURORA RD SAGAMORE HILLS OH 44067 USA "
    },
    {
      "Location ID": "44068",
      "Location Name": "NORTH KINGSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2716 E CENTER NORTH KINGSVILLE OH 44068 USA "
    },
    {
      "Location ID": "44072",
      "Location Name": "NOVELTY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14837 CHILLICOTHE RD NOVELTY OH 44072 USA "
    },
    {
      "Location ID": "44076",
      "Location Name": "ORWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 47 S MAPLE ST ORWELL OH 44076 USA "
    },
    {
      "Location ID": "44077",
      "Location Name": "PAINESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 E JACKSON ST PAINESVILLE OH 44077 USA "
    },
    {
      "Location ID": "44081",
      "Location Name": "PERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3754 CENTER RD PERRY OH 44081 USA "
    },
    {
      "Location ID": "44082",
      "Location Name": "PIERPONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 62 STATE ROUTE 7 S PIERPONT OH 44082 USA "
    },
    {
      "Location ID": "44084",
      "Location Name": "ROCK CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3046 E WATER ST ROCK CREEK OH 44084 USA "
    },
    {
      "Location ID": "44085",
      "Location Name": "ROME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3232 US HIGHWAY 6 ROME OH 44085 USA "
    },
    {
      "Location ID": "44087",
      "Location Name": "TWINSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8935 DARROW RD TWINSBURG OH 44087 USA "
    },
    {
      "Location ID": "44088",
      "Location Name": "UNIONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6887 S RIDGE RD UNIONVILLE OH 44088 USA "
    },
    {
      "Location ID": "44092",
      "Location Name": "WICKLIFFE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1400 LLOYD RD WICKLIFFE OH 44092 USA "
    },
    {
      "Location ID": "44093",
      "Location Name": "WILLIAMSFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5911 US ROUTE 322 WILLIAMSFIELD OH 44093 USA "
    },
    {
      "Location ID": "44094",
      "Location Name": "WILLOUGHBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4040 ERIE ST WILLOUGHBY OH 44094 USA "
    },
    {
      "Location ID": "44095",
      "Location Name": "WILLOWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 260 E 312TH ST WILLOWICK OH 44095 USA "
    },
    {
      "Location ID": "44099",
      "Location Name": "WINDSOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5103 STATE ROUTE 322 WINDSOR OH 44099 USA "
    },
    {
      "Location ID": "440AZ",
      "Location Name": "AMAZON CLE5 SORT CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8685 INDEPENDENCE PKWY TWINSBURG OH 44087 USA "
    },
    {
      "Location ID": "440CA",
      "Location Name": "CLEVELAND CMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16065 IMPERIAL PKWY STRONGSVILLE OH 44149 USA "
    },
    {
      "Location ID": "440FZ",
      "Location Name": "LOG CLEVELAND OH PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5520 CHEVOLET BLVD PARMA OH 44130 USA "
    },
    {
      "Location ID": "440MP",
      "Location Name": "MONOPILE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34440 VINE ST WILLOWICK OH 44095 USA "
    },
    {
      "Location ID": "440SS",
      "Location Name": "SCHNEIDERS SADDLERY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8255 E WASHINGTON ST CHAGRIN FALLS OH 44023 USA "
    },
    {
      "Location ID": "440WM",
      "Location Name": "WEEKLEYS MAILING SERVICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1420 W BAGLEY RD BEREA OH 44017 USA "
    },
    {
      "Location ID": "44101",
      "Location Name": "CLEVELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2400 ORANGE AVE CLEVELAND OH 44101 USA "
    },
    {
      "Location ID": "44124",
      "Location Name": "LYNDHURST MAYFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5815 LANDERBROOK DR CLEVELAND OH 44124 USA "
    },
    {
      "Location ID": "441FS",
      "Location Name": "LOG CLEVELAND FSS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5801 POSTAL RD CLEVELAND OH 44181 USA "
    },
    {
      "Location ID": "441MW",
      "Location Name": "MIDWEST PRESORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2222 W 110TH ST CLEVELAND OH 44102 USA "
    },
    {
      "Location ID": "441PD",
      "Location Name": "ANGSTROM GRAPHICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4437 E 49TH ST CLEVELAND OH 44125 USA "
    },
    {
      "Location ID": "441PI",
      "Location Name": "PROGRESSIVE INSURANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 747 ALPHA DR CLEVELAND OH 44143 USA "
    },
    {
      "Location ID": "441UP",
      "Location Name": "UPS MIDDLEBURG OH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17940 ENGLEWOOD DR CLEVELAND OH 44130 USA "
    },
    {
      "Location ID": "442",
      "Location Name": "LOG AKRON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 675 WOLF LEDGES PKWY RM 114 AKRON OH 44309 USA "
    },
    {
      "Location ID": "44201",
      "Location Name": "ATWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6713 WATERLOO RD ATWATER OH 44201 USA "
    },
    {
      "Location ID": "44256",
      "Location Name": "MEDINA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 303 N COURT ST MEDINA OH 44256 USA "
    },
    {
      "Location ID": "44278",
      "Location Name": "TALLMADGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 162 NORTHEAST AVE TALLMADGE OH 44278 USA "
    },
    {
      "Location ID": "442DH",
      "Location Name": "DHLEC - DIESTRIBUTION CTR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 542 SEASONS RD STE 1 STOW OH 44224 USA "
    },
    {
      "Location ID": "442MA",
      "Location Name": "MEDINA CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1195 INDUSTRIAL PKWY MEDINA OH 44256 USA "
    },
    {
      "Location ID": "442NC",
      "Location Name": "NEW CHANNEL DIRECT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1173 INDUSTRIAL PKWY N BRUNSWICK OH 44212 USA "
    },
    {
      "Location ID": "442PO",
      "Location Name": "HESS PRINT SOLUTIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3765 SUNNYBROOK RD KENT OH 44240 USA "
    },
    {
      "Location ID": "444",
      "Location Name": "LOG YOUNGSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 99 S WALNUT ST RM 289 YOUNGSTOWN OH 44501 USA "
    },
    {
      "Location ID": "44401",
      "Location Name": "BERLIN CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15401 W AKRON CANFIELD RD BERLIN CENTER OH 44401 USA "
    },
    {
      "Location ID": "44402",
      "Location Name": "BRISTOLVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1850 STATE ROUTE 88 BRISTOLVILLE OH 44402 USA "
    },
    {
      "Location ID": "44403",
      "Location Name": "BROOKFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6875 WARREN SHARON RD BROOKFIELD OH 44403 USA "
    },
    {
      "Location ID": "44406",
      "Location Name": "CANFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44 N BROAD ST CANFIELD OH 44406 USA "
    },
    {
      "Location ID": "44408",
      "Location Name": "COLUMBIANA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 149 S MAIN ST STE 2 COLUMBIANA OH 44408 USA "
    },
    {
      "Location ID": "44411",
      "Location Name": "DEERFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1365 STATE ROUTE 14 DEERFIELD OH 44411 USA "
    },
    {
      "Location ID": "44412",
      "Location Name": "DIAMOND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10096 TALLMADGE RD DIAMOND OH 44412 USA "
    },
    {
      "Location ID": "44413",
      "Location Name": "EAST PALESTINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 269 N MARKET ST EAST PALESTINE OH 44413 USA "
    },
    {
      "Location ID": "44415",
      "Location Name": "ELKTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 42188 STATE ROUTE 154 ELKTON OH 44415 USA "
    },
    {
      "Location ID": "44416",
      "Location Name": "ELLSWORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6036 S SALEM WARREN RD ELLSWORTH OH 44416 USA "
    },
    {
      "Location ID": "44420",
      "Location Name": "GIRARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 N MARKET ST GIRARD OH 44420 USA "
    },
    {
      "Location ID": "44422",
      "Location Name": "GREENFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12184 LISBON RD GREENFORD OH 44422 USA "
    },
    {
      "Location ID": "44424",
      "Location Name": "HARTFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6893 STATE ROUTE 305 HARTFORD OH 44424 USA "
    },
    {
      "Location ID": "44425",
      "Location Name": "HUBBARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44 E LIBERTY ST HUBBARD OH 44425 USA "
    },
    {
      "Location ID": "44428",
      "Location Name": "KINSMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8289 STATE ST KINSMAN OH 44428 USA "
    },
    {
      "Location ID": "44429",
      "Location Name": "LAKE MILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17839 MAHONING AVE LAKE MILTON OH 44429 USA "
    },
    {
      "Location ID": "44430",
      "Location Name": "LEAVITTSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3825 W MARKET ST LEAVITTSBURG OH 44430 USA "
    },
    {
      "Location ID": "44432",
      "Location Name": "LISBON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7983 DICKEY DR LISBON OH 44432 USA "
    },
    {
      "Location ID": "44437",
      "Location Name": "MC DONALD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 OHIO AVE MC DONALD OH 44437 USA "
    },
    {
      "Location ID": "44438",
      "Location Name": "MASURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7835 ELM ST MASURY OH 44438 USA "
    },
    {
      "Location ID": "44439",
      "Location Name": "MESOPOTAMIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4370 KINSMAN RD MESOPOTAMIA OH 44439 USA "
    },
    {
      "Location ID": "44441",
      "Location Name": "NEGLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7560 COMMERCE ST NEGLEY OH 44441 USA "
    },
    {
      "Location ID": "44442",
      "Location Name": "NEW MIDDLETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10399 MAIN ST NEW MIDDLETOWN OH 44442 USA "
    },
    {
      "Location ID": "44443",
      "Location Name": "NEW SPRINGFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13813 GRANT ST NEW SPRINGFIELD OH 44443 USA "
    },
    {
      "Location ID": "44444",
      "Location Name": "NEWTON FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 112 RIDGE RD NEWTON FALLS OH 44444 USA "
    },
    {
      "Location ID": "44445",
      "Location Name": "NEW WATERFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3818 W MAIN ST NEW WATERFORD OH 44445 USA "
    },
    {
      "Location ID": "44446",
      "Location Name": "NILES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 43 W PARK AVE NILES OH 44446 USA "
    },
    {
      "Location ID": "44449",
      "Location Name": "NORTH BENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9131 12TH ST NORTH BENTON OH 44449 USA "
    },
    {
      "Location ID": "44450",
      "Location Name": "NORTH BLOOMFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8829 STATE ROUTE 45 NORTH BLOOMFIELD OH 44450 USA "
    },
    {
      "Location ID": "44451",
      "Location Name": "NORTH JACKSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 263 S SALEM WARREN RD NORTH JACKSON OH 44451 USA "
    },
    {
      "Location ID": "44452",
      "Location Name": "NORTH LIMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11675 SOUTH AVE NORTH LIMA OH 44452 USA "
    },
    {
      "Location ID": "44453",
      "Location Name": "ORANGEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7401 STATELINE RD ORANGEVILLE OH 44453 USA "
    },
    {
      "Location ID": "44454",
      "Location Name": "PETERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6363 E GARFIELD RD PETERSBURG OH 44454 USA "
    },
    {
      "Location ID": "44455",
      "Location Name": "ROGERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7529 DEPOT ST ROGERS OH 44455 USA "
    },
    {
      "Location ID": "44460",
      "Location Name": "SALEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 275 PENN AVE SALEM OH 44460 USA "
    },
    {
      "Location ID": "44470",
      "Location Name": "SOUTHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3307 STATE ROUTE 534 SOUTHINGTON OH 44470 USA "
    },
    {
      "Location ID": "44481",
      "Location Name": "WARREN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 HIGH ST NE WARREN OH 44481 USA "
    },
    {
      "Location ID": "44484",
      "Location Name": "WARREN ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2807 NILES RD SE WARREN OH 44484 USA "
    },
    {
      "Location ID": "44491",
      "Location Name": "WEST FARMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 226 E MAIN ST WEST FARMINGTON OH 44491 USA "
    },
    {
      "Location ID": "444MC",
      "Location Name": "MACY'S - NORTH JACKSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 S BAILEY RD NORTH JACKSON OH 44451 USA "
    },
    {
      "Location ID": "444TP",
      "Location Name": "TP TOOLS & EQUIPMENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7075 STATE ROUTE 446 CANFIELD OH 44406 USA "
    },
    {
      "Location ID": "446",
      "Location Name": "CANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2650 CLEVELAND AVE NW CANTON OH 44711 USA "
    },
    {
      "Location ID": "44601",
      "Location Name": "ALLIANCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 S ARCH AVE ALLIANCE OH 44601 USA "
    },
    {
      "Location ID": "44609",
      "Location Name": "BELOIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13666 S MAIN ST BELOIT OH 44609 USA "
    },
    {
      "Location ID": "44615",
      "Location Name": "CARROLLTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 246 S LISBON ST CARROLLTON OH 44615 USA "
    },
    {
      "Location ID": "44619",
      "Location Name": "DAMASCUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16098 W SALEM ALLIANCE RD DAMASCUS OH 44619 USA "
    },
    {
      "Location ID": "44620",
      "Location Name": "DELLROY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 E MAIN ST DELLROY OH 44620 USA "
    },
    {
      "Location ID": "44634",
      "Location Name": "HOMEWORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4434 MIDDLE ST HOMEWORTH OH 44634 USA "
    },
    {
      "Location ID": "44639",
      "Location Name": "LEESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 95 S WATER ST LEESVILLE OH 44639 USA "
    },
    {
      "Location ID": "44640",
      "Location Name": "LIMAVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 E MAIN ST LIMAVILLE OH 44640 USA "
    },
    {
      "Location ID": "44650",
      "Location Name": "MAXIMO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6995 OAKHILL AVE NE MAXIMO OH 44650 USA "
    },
    {
      "Location ID": "44665",
      "Location Name": "NORTH GEORGETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27416 MAIN ST NORTH GEORGETOWN OH 44665 USA "
    },
    {
      "Location ID": "44672",
      "Location Name": "SEBRING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 196 W OHIO AVE SEBRING OH 44672 USA "
    },
    {
      "Location ID": "44675",
      "Location Name": "SHERRODSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 S SHERROD AVE SHERRODSVILLE OH 44675 USA "
    },
    {
      "Location ID": "44695",
      "Location Name": "BOWERSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 212 MAIN ST BOWERSTON OH 44695 USA "
    },
    {
      "Location ID": "448",
      "Location Name": "MANSFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 N DIAMOND ST MANSFIELD OH 44901 USA "
    },
    {
      "Location ID": "44804",
      "Location Name": "ARCADIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 N MAIN ST ARCADIA OH 44804 USA "
    },
    {
      "Location ID": "44805",
      "Location Name": "ASHLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 530 CLAREMONT AVE ASHLAND OH 44805 USA "
    },
    {
      "Location ID": "44807",
      "Location Name": "ATTICA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 S MAIN ST ATTICA OH 44807 USA "
    },
    {
      "Location ID": "44809",
      "Location Name": "BASCOM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5755 W TIFFIN ST BASCOM OH 44809 USA "
    },
    {
      "Location ID": "44811",
      "Location Name": "BELLEVUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 140 N SANDUSKY ST BELLEVUE OH 44811 USA "
    },
    {
      "Location ID": "44813",
      "Location Name": "BELLVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 BELL ST BELLVILLE OH 44813 USA "
    },
    {
      "Location ID": "44815",
      "Location Name": "BETTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 203 STATE ST BETTSVILLE OH 44815 USA "
    },
    {
      "Location ID": "44818",
      "Location Name": "BLOOMVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 S MARION ST BLOOMVILLE OH 44818 USA "
    },
    {
      "Location ID": "44820",
      "Location Name": "BUCYRUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 S POPLAR ST BUCYRUS OH 44820 USA "
    },
    {
      "Location ID": "44822",
      "Location Name": "BUTLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 W ELM ST BUTLER OH 44822 USA "
    },
    {
      "Location ID": "44824",
      "Location Name": "CASTALIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 N WATER ST CASTALIA OH 44824 USA "
    },
    {
      "Location ID": "44827",
      "Location Name": "CRESTLINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 244 N SELTZER ST CRESTLINE OH 44827 USA "
    },
    {
      "Location ID": "44828",
      "Location Name": "FLAT ROCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15909 JEFFERSON ST FLAT ROCK OH 44828 USA "
    },
    {
      "Location ID": "44830",
      "Location Name": "FOSTORIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 W CENTER ST FOSTORIA OH 44830 USA "
    },
    {
      "Location ID": "44833",
      "Location Name": "GALION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 133 N COLUMBUS ST GALION OH 44833 USA "
    },
    {
      "Location ID": "44836",
      "Location Name": "GREEN SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 S BROADWAY ST GREEN SPRINGS OH 44836 USA "
    },
    {
      "Location ID": "44837",
      "Location Name": "GREENWICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 MAIN ST GREENWICH OH 44837 USA "
    },
    {
      "Location ID": "44838",
      "Location Name": "HAYESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 E MAIN ST HAYESVILLE OH 44838 USA "
    },
    {
      "Location ID": "44839",
      "Location Name": "HURON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 378 MAIN ST HURON OH 44839 USA "
    },
    {
      "Location ID": "44840",
      "Location Name": "JEROMESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 S HIGH ST JEROMESVILLE OH 44840 USA "
    },
    {
      "Location ID": "44841",
      "Location Name": "KANSAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6047 MAIN ST KANSAS OH 44841 USA "
    },
    {
      "Location ID": "44842",
      "Location Name": "LOUDONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 124 S WATER ST LOUDONVILLE OH 44842 USA "
    },
    {
      "Location ID": "44843",
      "Location Name": "LUCAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 W MAIN ST LUCAS OH 44843 USA "
    },
    {
      "Location ID": "44846",
      "Location Name": "MILAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 E CHURCH ST MILAN OH 44846 USA "
    },
    {
      "Location ID": "44847",
      "Location Name": "MONROEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 N MAIN ST MONROEVILLE OH 44847 USA "
    },
    {
      "Location ID": "44848",
      "Location Name": "NANKIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 953 STATE ROUTE 58 NANKIN OH 44848 USA "
    },
    {
      "Location ID": "44850",
      "Location Name": "NEW HAVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3991 CENTER ST NEW HAVEN OH 44850 USA "
    },
    {
      "Location ID": "44851",
      "Location Name": "NEW LONDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 86 E MAIN ST NEW LONDON OH 44851 USA "
    },
    {
      "Location ID": "44855",
      "Location Name": "NORTH FAIRFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 N MAIN ST NORTH FAIRFIELD OH 44855 USA "
    },
    {
      "Location ID": "44857",
      "Location Name": "NORWALK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16 WHITTLESEY AVE NORWALK OH 44857 USA "
    },
    {
      "Location ID": "44859",
      "Location Name": "NOVA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 246 STATE ROUTE 511 NOVA OH 44859 USA "
    },
    {
      "Location ID": "44861",
      "Location Name": "OLD FORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8064 MAIN ST OLD FORT OH 44861 USA "
    },
    {
      "Location ID": "44864",
      "Location Name": "PERRYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 112 W 1ST ST PERRYSVILLE OH 44864 USA "
    },
    {
      "Location ID": "44865",
      "Location Name": "PLYMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 PLYMOUTH ST PLYMOUTH OH 44865 USA "
    },
    {
      "Location ID": "44866",
      "Location Name": "POLK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 W CONGRESS ST POLK OH 44866 USA "
    },
    {
      "Location ID": "44867",
      "Location Name": "REPUBLIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 MADISON ST REPUBLIC OH 44867 USA "
    },
    {
      "Location ID": "44870",
      "Location Name": "SANDUSKY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2220 CALDWELL ST SANDUSKY OH 44870 USA "
    },
    {
      "Location ID": "44874",
      "Location Name": "SAVANNAH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 E MAIN ST SAVANNAH OH 44874 USA "
    },
    {
      "Location ID": "44875",
      "Location Name": "SHELBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26 N GAMBLE ST SHELBY OH 44875 USA "
    },
    {
      "Location ID": "44878",
      "Location Name": "SHILOH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 W MAIN ST SHILOH OH 44878 USA "
    },
    {
      "Location ID": "44880",
      "Location Name": "SULLIVAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 245 TOWNSHIP ROAD 481 SULLIVAN OH 44880 USA "
    },
    {
      "Location ID": "44883",
      "Location Name": "TIFFIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 175 S MONROE ST TIFFIN OH 44883 USA "
    },
    {
      "Location ID": "44890",
      "Location Name": "WILLARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 S MYRTLE AVE WILLARD OH 44890 USA "
    },
    {
      "Location ID": "448BM",
      "Location Name": "BOOKMASTERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 AMBERWOOD PKWY ASHLAND OH 44805 USA "
    },
    {
      "Location ID": "448MH",
      "Location Name": "MEYER HATCHERY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 626 STATE ROUTE 89 POLK OH 44866 USA "
    },
    {
      "Location ID": "450",
      "Location Name": "LOG CINCINNATI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1591 DALTON AVE CINCINNATI OH 45234 USA "
    },
    {
      "Location ID": "45005",
      "Location Name": "FRANKLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 712 E 2ND ST FRANKLIN OH 45005 USA "
    },
    {
      "Location ID": "45011",
      "Location Name": "HAMILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 COURT ST HAMILTON OH 45011 USA "
    },
    {
      "Location ID": "45013",
      "Location Name": "ROSSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 865 FRANKLIN ST HAMILTON OH 45013 USA "
    },
    {
      "Location ID": "45014",
      "Location Name": "FAIRFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 700 WESSEL DR FAIRFIELD OH 45014 USA "
    },
    {
      "Location ID": "45030",
      "Location Name": "HARRISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 420 S STATE ST HARRISON OH 45030 USA "
    },
    {
      "Location ID": "45034",
      "Location Name": "KINGS MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1985 KING AVE KINGS MILLS OH 45034 USA "
    },
    {
      "Location ID": "45036",
      "Location Name": "LEBANON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 230 NEW ST LEBANON OH 45036 USA "
    },
    {
      "Location ID": "45039",
      "Location Name": "MAINEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7878 S STATE ROUTE 48 MAINEVILLE OH 45039 USA "
    },
    {
      "Location ID": "45040",
      "Location Name": "MASON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6338 SNIDER RD MASON OH 45040 USA "
    },
    {
      "Location ID": "45041",
      "Location Name": "MIAMITOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6739 HAMILTON CLEVES RD MIAMITOWN OH 45041 USA "
    },
    {
      "Location ID": "45042",
      "Location Name": "MIDDLETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 320 N VERITY PKWY MIDDLETOWN OH 45042 USA "
    },
    {
      "Location ID": "45050",
      "Location Name": "MONROE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 S MAIN ST MONROE OH 45050 USA "
    },
    {
      "Location ID": "45055",
      "Location Name": "OVERPECK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4212 TRENTON RD OVERPECK OH 45055 USA "
    },
    {
      "Location ID": "45056",
      "Location Name": "OXFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5145 BROWN RD OXFORD OH 45056 USA "
    },
    {
      "Location ID": "45061",
      "Location Name": "ROSS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4275 HAMILTON CLEVES HWY ROSS OH 45061 USA "
    },
    {
      "Location ID": "45062",
      "Location Name": "SEVEN MILE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 N MAIN ST SEVEN MILE OH 45062 USA "
    },
    {
      "Location ID": "45064",
      "Location Name": "SOMERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 156 S MAIN ST SOMERVILLE OH 45064 USA "
    },
    {
      "Location ID": "45065",
      "Location Name": "SOUTH LEBANON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 219 E FOREST AVE SOUTH LEBANON OH 45065 USA "
    },
    {
      "Location ID": "45066",
      "Location Name": "SPRINGBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 S PIONEER BLVD SPRINGBORO OH 45066 USA "
    },
    {
      "Location ID": "45067",
      "Location Name": "TRENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 290 EDGEWOOD DR TRENTON OH 45067 USA "
    },
    {
      "Location ID": "45068",
      "Location Name": "WAYNESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 235 MIAMI ST WAYNESVILLE OH 45068 USA "
    },
    {
      "Location ID": "45069",
      "Location Name": "WEST CHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8730 CINCINNATI DAYTON RD WEST CHESTER OH 45069 USA "
    },
    {
      "Location ID": "45070",
      "Location Name": "WEST ELKTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 N MAIN ST WEST ELKTON OH 45070 USA "
    },
    {
      "Location ID": "450FZ",
      "Location Name": "LOG CINCINNATI OH PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8615 JACQUEMIN DR WESTCHESTER OH 45069 USA "
    },
    {
      "Location ID": "45102",
      "Location Name": "AMELIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 S KLINE AVE AMELIA OH 45102 USA "
    },
    {
      "Location ID": "45103",
      "Location Name": "BATAVIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 575 W MAIN ST BATAVIA OH 45103 USA "
    },
    {
      "Location ID": "45106",
      "Location Name": "BETHEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 213 N EAST ST BETHEL OH 45106 USA "
    },
    {
      "Location ID": "45107",
      "Location Name": "BLANCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 S WRIGHT ST BLANCHESTER OH 45107 USA "
    },
    {
      "Location ID": "45121",
      "Location Name": "GEORGETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 E GRANT AVE GEORGETOWN OH 45121 USA "
    },
    {
      "Location ID": "45122",
      "Location Name": "GOSHEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1978 MAIN ST GOSHEN OH 45122 USA "
    },
    {
      "Location ID": "45123",
      "Location Name": "GREENFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 233 JEFFERSON ST GREENFIELD OH 45123 USA "
    },
    {
      "Location ID": "45133",
      "Location Name": "HILLSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 N HIGH ST SIDE HILLSBORO OH 45133 USA "
    },
    {
      "Location ID": "45135",
      "Location Name": "LEESBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 44 E MAIN ST LEESBURG OH 45135 USA "
    },
    {
      "Location ID": "45140",
      "Location Name": "LOVELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 LOVELAND MADEIRA RD LOVELAND OH 45140 USA "
    },
    {
      "Location ID": "45150",
      "Location Name": "MILFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 CASTLEBERRY CT MILFORD OH 45150 USA "
    },
    {
      "Location ID": "45152",
      "Location Name": "MORROW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 531 W PIKE ST MORROW OH 45152 USA "
    },
    {
      "Location ID": "45153",
      "Location Name": "MOSCOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 WELLS ST MOSCOW OH 45153 USA "
    },
    {
      "Location ID": "45154",
      "Location Name": "MOUNT ORAB",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 E MAIN ST MOUNT ORAB OH 45154 USA "
    },
    {
      "Location ID": "45157",
      "Location Name": "NEW RICHMOND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 501 MARKET ST NEW RICHMOND OH 45157 USA "
    },
    {
      "Location ID": "45159",
      "Location Name": "NEW VIENNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 W MAIN ST NEW VIENNA OH 45159 USA "
    },
    {
      "Location ID": "45167",
      "Location Name": "RIPLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 S 2ND ST RIPLEY OH 45167 USA "
    },
    {
      "Location ID": "45171",
      "Location Name": "SARDINIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 BROAD ST SARDINIA OH 45171 USA "
    },
    {
      "Location ID": "45176",
      "Location Name": "WILLIAMSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 332 W MAIN ST WILLIAMSBURG OH 45176 USA "
    },
    {
      "Location ID": "45177",
      "Location Name": "WILMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1515 ALEX DR WILMINGTON OH 45177 USA "
    },
    {
      "Location ID": "452",
      "Location Name": "CINCINNATI P&DC BLDG A",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1623 DALTON AVE CINCINNATI OH 45234 USA "
    },
    {
      "Location ID": "452AX",
      "Location Name": "LOG SPRINGDALE ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12000 NORTHWEST BLVD CINCINNATI OH 45246 USA "
    },
    {
      "Location ID": "452UM",
      "Location Name": "UNITED MAIL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1221 HARRISON AVE CINCINNATI OH 45214-1719 USA "
    },
    {
      "Location ID": "453",
      "Location Name": "DAYTON P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1111 E 5TH ST DAYTON OH 45401 USA "
    },
    {
      "Location ID": "45302",
      "Location Name": "ANNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 N 2ND ST ANNA OH 45302 USA "
    },
    {
      "Location ID": "45303",
      "Location Name": "ANSONIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 112 N MAIN ST ANSONIA OH 45303 USA "
    },
    {
      "Location ID": "45306",
      "Location Name": "BOTKINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 S MILL ST BOTKINS OH 45306 USA "
    },
    {
      "Location ID": "45307",
      "Location Name": "BOWERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3180 MAYSVILLE ST BOWERSVILLE OH 45307 USA "
    },
    {
      "Location ID": "45308",
      "Location Name": "BRADFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 N MIAMI AVE BRADFORD OH 45308 USA "
    },
    {
      "Location ID": "45309",
      "Location Name": "BROOKVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22 MULBERRY ST BROOKVILLE OH 45309 USA "
    },
    {
      "Location ID": "45310",
      "Location Name": "BURKETTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 W MAIN ST BURKETTSVILLE OH 45310 USA "
    },
    {
      "Location ID": "45311",
      "Location Name": "CAMDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 32 W CENTRAL AVE CAMDEN OH 45311 USA "
    },
    {
      "Location ID": "45312",
      "Location Name": "CASSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 N MAIN ST CASSTOWN OH 45312 USA "
    },
    {
      "Location ID": "45314",
      "Location Name": "CEDARVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 N MAIN ST CEDARVILLE OH 45314 USA "
    },
    {
      "Location ID": "45315",
      "Location Name": "CLAYTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 196 TALMADGE RD CLAYTON OH 45315 USA "
    },
    {
      "Location ID": "45317",
      "Location Name": "CONOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7711 N ALCONY CONOVER RD CONOVER OH 45317 USA "
    },
    {
      "Location ID": "45318",
      "Location Name": "COVINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 E SPRING ST COVINGTON OH 45318 USA "
    },
    {
      "Location ID": "45319",
      "Location Name": "DONNELSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 S HAMPTON RD DONNELSVILLE OH 45319 USA "
    },
    {
      "Location ID": "45320",
      "Location Name": "EATON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 W MAIN ST EATON OH 45320 USA "
    },
    {
      "Location ID": "45321",
      "Location Name": "ELDORADO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 N MAPLE ST ELDORADO OH 45321 USA "
    },
    {
      "Location ID": "45323",
      "Location Name": "ENON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27 N XENIA DR ENON OH 45323 USA "
    },
    {
      "Location ID": "45328",
      "Location Name": "GETTYSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105A CLAY ST GETTYSBURG OH 45328 USA "
    },
    {
      "Location ID": "45330",
      "Location Name": "GRATIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 E FRANKLIN ST GRATIS OH 45330 USA "
    },
    {
      "Location ID": "45332",
      "Location Name": "HOLLANSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 124 N MAIN ST HOLLANSBURG OH 45332 USA "
    },
    {
      "Location ID": "45333",
      "Location Name": "HOUSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3733 STATE ROUTE 66 HOUSTON OH 45333 USA "
    },
    {
      "Location ID": "45334",
      "Location Name": "JACKSON CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 E PIKE ST JACKSON CENTER OH 45334 USA "
    },
    {
      "Location ID": "45335",
      "Location Name": "JAMESTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37 N LIMESTONE ST JAMESTOWN OH 45335 USA "
    },
    {
      "Location ID": "45336",
      "Location Name": "KETTLERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16620 KETTLERSVILLE RD KETTLERSVILLE OH 45336 USA "
    },
    {
      "Location ID": "45337",
      "Location Name": "LAURA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 203 S MAIN ST LAURA OH 45337 USA "
    },
    {
      "Location ID": "45338",
      "Location Name": "LEWISBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 723 N COMMERCE ST LEWISBURG OH 45338 USA "
    },
    {
      "Location ID": "45339",
      "Location Name": "LUDLOW FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 COVINGTON AVE LUDLOW FALLS OH 45339 USA "
    },
    {
      "Location ID": "45340",
      "Location Name": "MAPLEWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21611 MAPLEWOOD RD MAPLEWOOD OH 45340 USA "
    },
    {
      "Location ID": "45341",
      "Location Name": "MEDWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 W MAIN ST MEDWAY OH 45341 USA "
    },
    {
      "Location ID": "45344",
      "Location Name": "NEW CARLISLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 W LAKE AVE NEW CARLISLE OH 45344 USA "
    },
    {
      "Location ID": "45345",
      "Location Name": "NEW LEBANON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 77 W MAIN ST NEW LEBANON OH 45345 USA "
    },
    {
      "Location ID": "45346",
      "Location Name": "NEW MADISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 133 N HARRISON ST NEW MADISON OH 45346 USA "
    },
    {
      "Location ID": "45347",
      "Location Name": "NEW PARIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 306 S WASHINGTON ST NEW PARIS OH 45347 USA "
    },
    {
      "Location ID": "45349",
      "Location Name": "NORTH HAMPTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23 N MAIN ST NORTH HAMPTON OH 45349 USA "
    },
    {
      "Location ID": "45350",
      "Location Name": "NORTH STAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 S MAIN ST NORTH STAR OH 45350 USA "
    },
    {
      "Location ID": "45351",
      "Location Name": "OSGOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24 W MAIN ST OSGOOD OH 45351 USA "
    },
    {
      "Location ID": "45354",
      "Location Name": "PHILLIPSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 E MAIN ST PHILLIPSBURG OH 45354 USA "
    },
    {
      "Location ID": "45356",
      "Location Name": "PIQUA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 N WAYNE ST PIQUA OH 45356 USA "
    },
    {
      "Location ID": "45358",
      "Location Name": "PITSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 JEFFERSON ST PITSBURG OH 45358 USA "
    },
    {
      "Location ID": "45359",
      "Location Name": "PLEASANT HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 N MAIN ST PLEASANT HILL OH 45359 USA "
    },
    {
      "Location ID": "45360",
      "Location Name": "PORT JEFFERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 E MAIN ST PORT JEFFERSON OH 45360 USA "
    },
    {
      "Location ID": "45361",
      "Location Name": "POTSDAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 W CROSS ST POTSDAM OH 45361 USA "
    },
    {
      "Location ID": "45362",
      "Location Name": "ROSSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 N BROAD ST ROSSBURG OH 45362 USA "
    },
    {
      "Location ID": "45363",
      "Location Name": "RUSSIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 234 W MAIN ST RUSSIA OH 45363 USA "
    },
    {
      "Location ID": "45365",
      "Location Name": "SIDNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 135 N OHIO AVE SIDNEY OH 45365 USA "
    },
    {
      "Location ID": "45368",
      "Location Name": "SOUTH CHARLESTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 S CHILLICOTHE ST SOUTH CHARLESTON OH 45368 USA "
    },
    {
      "Location ID": "45369",
      "Location Name": "SOUTH VIENNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 1/2 W MAIN ST SOUTH VIENNA OH 45369 USA "
    },
    {
      "Location ID": "45370",
      "Location Name": "SPRING VALLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 W MAIN ST SPRING VALLEY OH 45370 USA "
    },
    {
      "Location ID": "45371",
      "Location Name": "TIPP CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 520 N HYATT ST TIPP CITY OH 45371 USA "
    },
    {
      "Location ID": "45372",
      "Location Name": "TREMONT CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 57 E MAIN TREMONT CITY OH 45372 USA "
    },
    {
      "Location ID": "45373",
      "Location Name": "TROY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 305 S MARKET ST OFC TROY OH 45373 USA "
    },
    {
      "Location ID": "45377",
      "Location Name": "VANDALIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 323 JAMES BOHANAN DR VANDALIA OH 45377 USA "
    },
    {
      "Location ID": "45378",
      "Location Name": "VERONA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 224 E MAIN ST VERONA OH 45378 USA "
    },
    {
      "Location ID": "45380",
      "Location Name": "VERSAILLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 E WARD ST VERSAILLES OH 45380 USA "
    },
    {
      "Location ID": "45381",
      "Location Name": "WEST ALEXANDRIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 W DAYTON ST WEST ALEXANDRIA OH 45381 USA "
    },
    {
      "Location ID": "45382",
      "Location Name": "WEST MANCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 E SCOTT ST WEST MANCHESTER OH 45382 USA "
    },
    {
      "Location ID": "45383",
      "Location Name": "WEST MILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 N MAIN ST WEST MILTON OH 45383 USA "
    },
    {
      "Location ID": "45387",
      "Location Name": "YELLOW SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 CORRY ST YELLOW SPRINGS OH 45387 USA "
    },
    {
      "Location ID": "45388",
      "Location Name": "YORKSHIRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 W MAIN ST YORKSHIRE OH 45388 USA "
    },
    {
      "Location ID": "45389",
      "Location Name": "CHRISTIANSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6 E 1ST ST CHRISTIANSBURG OH 45389 USA "
    },
    {
      "Location ID": "453SL",
      "Location Name": "AMISIVE - MIAMISBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3303 W TECH BLVD MIAMISBURG OH 45342 USA "
    },
    {
      "Location ID": "455",
      "Location Name": "SPRINGFIELD PO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 N LIMESTONE ST SPRINGFIELD OH 45501 USA "
    },
    {
      "Location ID": "456",
      "Location Name": "CHILLICOTHE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 S WALNUT ST CHILLICOTHE OH 45601 USA "
    },
    {
      "Location ID": "45601",
      "Location Name": "CHILLICOTHE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 S WALNUT ST CHILLICOTHE OH 45601 USA "
    },
    {
      "Location ID": "45612",
      "Location Name": "BAINBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 E MAIN ST BAINBRIDGE OH 45612 USA "
    },
    {
      "Location ID": "45613",
      "Location Name": "BEAVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5610 BEAVER PIKE BEAVER OH 45613 USA "
    },
    {
      "Location ID": "45614",
      "Location Name": "BIDWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15070 STATE ROUTE 554 BIDWELL OH 45614 USA "
    },
    {
      "Location ID": "45617",
      "Location Name": "BOURNEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11684 US HIGHWAY 50 W BOURNEVILLE OH 45617 USA "
    },
    {
      "Location ID": "45618",
      "Location Name": "CHERRY FORK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14536 STATE ROUTE 136 CHERRY FORK OH 45618 USA "
    },
    {
      "Location ID": "45619",
      "Location Name": "CHESAPEAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 607 3RD AVE CHESAPEAKE OH 45619 USA "
    },
    {
      "Location ID": "45620",
      "Location Name": "CHESHIRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7976 STATE ROUTE 7 N CHESHIRE OH 45620 USA "
    },
    {
      "Location ID": "45621",
      "Location Name": "COALTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 S 2ND ST COALTON OH 45621 USA "
    },
    {
      "Location ID": "45623",
      "Location Name": "CROWN CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 54 CHARLES ST CROWN CITY OH 45623 USA "
    },
    {
      "Location ID": "45628",
      "Location Name": "FRANKFORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 32 N MAIN ST FRANKFORT OH 45628 USA "
    },
    {
      "Location ID": "45629",
      "Location Name": "FRANKLIN FURNACE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4202 GALLIA PIKE FRANKLIN FURNACE OH 45629 USA "
    },
    {
      "Location ID": "45631",
      "Location Name": "GALLIPOLIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 440 2ND AVE GALLIPOLIS OH 45631 USA "
    },
    {
      "Location ID": "45634",
      "Location Name": "HAMDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 N MAIN ST HAMDEN OH 45634 USA "
    },
    {
      "Location ID": "45636",
      "Location Name": "HAVERHILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1540 GALLIA PIKE HAVERHILL OH 45636 USA "
    },
    {
      "Location ID": "45638",
      "Location Name": "IRONTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 214 N 4TH ST IRONTON OH 45638 USA "
    },
    {
      "Location ID": "45640",
      "Location Name": "JACKSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 360 MAIN ST JACKSON OH 45640 USA "
    },
    {
      "Location ID": "45643",
      "Location Name": "KERR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 178 AMBLESIDE DR KERR OH 45643 USA "
    },
    {
      "Location ID": "45644",
      "Location Name": "KINGSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4 W PICKAWAY ST KINGSTON OH 45644 USA "
    },
    {
      "Location ID": "45647",
      "Location Name": "LONDONDERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5055 VIGO RD LONDONDERRY OH 45647 USA "
    },
    {
      "Location ID": "45648",
      "Location Name": "LUCASVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 38 ROBERT LUCAS RD LUCASVILLE OH 45648 USA "
    },
    {
      "Location ID": "45651",
      "Location Name": "MC ARTHUR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 S MARKET ST MC ARTHUR OH 45651 USA "
    },
    {
      "Location ID": "45652",
      "Location Name": "MC DERMOTT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 BARKER ST MC DERMOTT OH 45652 USA "
    },
    {
      "Location ID": "45653",
      "Location Name": "MINFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10623 STATE ROUTE 139 MINFORD OH 45653 USA "
    },
    {
      "Location ID": "45654",
      "Location Name": "NEW PLYMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 68197 STATE ROUTE 56 NEW PLYMOUTH OH 45654 USA "
    },
    {
      "Location ID": "45656",
      "Location Name": "OAK HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 RAILROAD ST OAK HILL OH 45656 USA "
    },
    {
      "Location ID": "45657",
      "Location Name": "OTWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6969 STATE ROUTE 73 OTWAY OH 45657 USA "
    },
    {
      "Location ID": "45658",
      "Location Name": "PATRIOT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1584 PATRIOT RD PATRIOT OH 45658 USA "
    },
    {
      "Location ID": "45659",
      "Location Name": "PEDRO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8284 STATE ROUTE 93 PEDRO OH 45659 USA "
    },
    {
      "Location ID": "45660",
      "Location Name": "PEEBLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 184 N MAIN ST PEEBLES OH 45660 USA "
    },
    {
      "Location ID": "45661",
      "Location Name": "PIKETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 S FORSYTHE ST PIKETON OH 45661 USA "
    },
    {
      "Location ID": "45662",
      "Location Name": "PORTSMOUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 610 GAY ST PORTSMOUTH OH 45662 USA "
    },
    {
      "Location ID": "45669",
      "Location Name": "PROCTORVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6200 COUNTY ROAD 107 PROCTORVILLE OH 45669 USA "
    },
    {
      "Location ID": "45671",
      "Location Name": "RARDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1586 MAIN ST RARDEN OH 45671 USA "
    },
    {
      "Location ID": "45672",
      "Location Name": "RAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36977 STATE ROUTE 327 RAY OH 45672 USA "
    },
    {
      "Location ID": "45673",
      "Location Name": "RICHMOND DALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1024 MAIN ST RICHMOND DALE OH 45673 USA "
    },
    {
      "Location ID": "45674",
      "Location Name": "RIO GRANDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 408 W COLLEGE ST RIO GRANDE OH 45674 USA "
    },
    {
      "Location ID": "45677",
      "Location Name": "SCIOTO FURNACE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8276 STATE ROUTE 140 SCIOTO FURNACE OH 45677 USA "
    },
    {
      "Location ID": "45678",
      "Location Name": "SCOTTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11168 STATE ROUTE 217 SCOTTOWN OH 45678 USA "
    },
    {
      "Location ID": "45679",
      "Location Name": "SEAMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6886 TRI COUNTY RD SEAMAN OH 45679 USA "
    },
    {
      "Location ID": "45680",
      "Location Name": "SOUTH POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 COLLINS AVE SOUTH POINT OH 45680 USA "
    },
    {
      "Location ID": "45681",
      "Location Name": "SOUTH SALEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3067 STEWART ST SOUTH SALEM OH 45681 USA "
    },
    {
      "Location ID": "45682",
      "Location Name": "SOUTH WEBSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 141 JACKSON ST SOUTH WEBSTER OH 45682 USA "
    },
    {
      "Location ID": "45683",
      "Location Name": "STOCKDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 56 STATE ROUTE 335 BEAVER OH 45613-8800 USA "
    },
    {
      "Location ID": "45684",
      "Location Name": "STOUT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 2ND ST STOUT OH 45684 USA "
    },
    {
      "Location ID": "45685",
      "Location Name": "THURMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 315 STATE ROUTE 279 THURMAN OH 45685 USA "
    },
    {
      "Location ID": "45686",
      "Location Name": "VINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 137 MAIN ST VINTON OH 45686 USA "
    },
    {
      "Location ID": "45690",
      "Location Name": "WAVERLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 E 2ND ST WAVERLY OH 45690 USA "
    },
    {
      "Location ID": "45692",
      "Location Name": "WELLSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 E BROADWAY ST WELLSTON OH 45692 USA "
    },
    {
      "Location ID": "45693",
      "Location Name": "WEST UNION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 E NORTH ST WEST UNION OH 45693 USA "
    },
    {
      "Location ID": "45694",
      "Location Name": "WHEELERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11687 GALLIA PIKE RD WHEELERSBURG OH 45694 USA "
    },
    {
      "Location ID": "45695",
      "Location Name": "WILKESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 154 MAIN ST WILKESVILLE OH 45695 USA "
    },
    {
      "Location ID": "45696",
      "Location Name": "WILLOW WOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5491 STATE ROUTE 217 USA OH 45696 USA "
    },
    {
      "Location ID": "45697",
      "Location Name": "WINCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19138 STATE ROUTE 136 WINCHESTER OH 45697 USA "
    },
    {
      "Location ID": "45698",
      "Location Name": "ZALESKI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 S 1ST ST ZALESKI OH 45698 USA "
    },
    {
      "Location ID": "456AA",
      "Location Name": "SCIOTOVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 423 HARDING AVE PORTSMOUTH OH 45662 USA "
    },
    {
      "Location ID": "456SD",
      "Location Name": "SPRING DIAGNOSTICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16064 BEAVER PIKE JACKSON OH 45640 USA "
    },
    {
      "Location ID": "457",
      "Location Name": "ATHENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 W STIMSON AVE ATHENS OH 45701 USA "
    },
    {
      "Location ID": "45701",
      "Location Name": "ATHENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5 W STIMSON AVE ATHENS OH 45701 USA "
    },
    {
      "Location ID": "45710",
      "Location Name": "ALBANY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5303 WASHINGTON RD ALBANY OH 45710 USA "
    },
    {
      "Location ID": "45711",
      "Location Name": "AMESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 STATE ST AMESVILLE OH 45711 USA "
    },
    {
      "Location ID": "45713",
      "Location Name": "BARTLETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 MAIN ST BARTLETT OH 45713 USA "
    },
    {
      "Location ID": "45714",
      "Location Name": "BELPRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1105 WASHINGTON BLVD BELPRE OH 45714 USA "
    },
    {
      "Location ID": "45715",
      "Location Name": "BEVERLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 409 1/2 5TH ST BEVERLY OH 45715 USA "
    },
    {
      "Location ID": "45716",
      "Location Name": "BUCHTEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17505 2ND ST BUCHTEL OH 45716 USA "
    },
    {
      "Location ID": "45719",
      "Location Name": "CHAUNCEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23 SYCAMORE ST CHAUNCEY OH 45719 USA "
    },
    {
      "Location ID": "45720",
      "Location Name": "CHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 46416 STATE ROUTE 248 UNIT B CHESTER OH 45720 USA "
    },
    {
      "Location ID": "45723",
      "Location Name": "COOLVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26141 MAIN ST COOLVILLE OH 45723 USA "
    },
    {
      "Location ID": "45724",
      "Location Name": "CUTLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9722 STATE ROUTE 555 CUTLER OH 45724 USA "
    },
    {
      "Location ID": "45727",
      "Location Name": "DEXTER CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 MAIN ST DEXTER CITY OH 45727 USA "
    },
    {
      "Location ID": "45732",
      "Location Name": "GLOUSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 76 HIGH ST GLOUSTER OH 45732 USA "
    },
    {
      "Location ID": "45735",
      "Location Name": "GUYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6930 SAVANNAH ST GUYSVILLE OH 45735 USA "
    },
    {
      "Location ID": "45739",
      "Location Name": "HOCKINGPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 699 STATE ROUTE 124 HOCKINGPORT OH 45739 USA "
    },
    {
      "Location ID": "45740",
      "Location Name": "JACKSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 S 6TH ST JACKSONVILLE OH 45740 USA "
    },
    {
      "Location ID": "45741",
      "Location Name": "LANGSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " STATE ROUTE 124 USA OH 45741 USA "
    },
    {
      "Location ID": "45742",
      "Location Name": "LITTLE HOCKING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3985 NEWBURY RD LITTLE HOCKING OH 45742 USA "
    },
    {
      "Location ID": "45743",
      "Location Name": "LONG BOTTOM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 61462 STATE ROUTE 124 LONG BOTTOM OH 45743 USA "
    },
    {
      "Location ID": "45744",
      "Location Name": "LOWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 203 3RD ST LOWELL OH 45744 USA "
    },
    {
      "Location ID": "45745",
      "Location Name": "LOWER SALEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 MAIN ST LOWER SALEM OH 45745 USA "
    },
    {
      "Location ID": "45750",
      "Location Name": "MARIETTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 275 FRONT ST MARIETTA OH 45750 USA "
    },
    {
      "Location ID": "45760",
      "Location Name": "MIDDLEPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 320 MILL ST MIDDLEPORT OH 45760 USA "
    },
    {
      "Location ID": "45761",
      "Location Name": "MILLFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16099 MAIN ST MILLFIELD OH 45761 USA "
    },
    {
      "Location ID": "45764",
      "Location Name": "NELSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 W WASHINGTON ST NELSONVILLE OH 45764 USA "
    },
    {
      "Location ID": "45766",
      "Location Name": "NEW MARSHFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3996 OLD STATE ROUTE 56 NEW MARSHFIELD OH 45766 USA "
    },
    {
      "Location ID": "45767",
      "Location Name": "NEW MATAMORAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 515 2ND ST NEW MATAMORAS OH 45767 USA "
    },
    {
      "Location ID": "45768",
      "Location Name": "NEWPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 GREENE ST NEWPORT OH 45768 USA "
    },
    {
      "Location ID": "45769",
      "Location Name": "POMEROY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 212 E 2ND ST POMEROY OH 45769 USA "
    },
    {
      "Location ID": "45770",
      "Location Name": "PORTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30935 HIGH ST PORTLAND OH 45770 USA "
    },
    {
      "Location ID": "45771",
      "Location Name": "RACINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 208 S 3RD ST RACINE OH 45771 USA "
    },
    {
      "Location ID": "45772",
      "Location Name": "REEDSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55420 4TH AVE ST REEDSVILLE OH 45772 USA "
    },
    {
      "Location ID": "45773",
      "Location Name": "RENO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27630 STATE ROUTE 7 RENO OH 45773 USA "
    },
    {
      "Location ID": "45775",
      "Location Name": "RUTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 SALEM ST RUTLAND OH 45775 USA "
    },
    {
      "Location ID": "45776",
      "Location Name": "SHADE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2280 OLD RTE 33 SHADE OH 45776 USA "
    },
    {
      "Location ID": "45778",
      "Location Name": "STEWART",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8125 STATE ROUTE 329 STEWART OH 45778 USA "
    },
    {
      "Location ID": "45779",
      "Location Name": "SYRACUSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2391 3RD ST SYRACUSE OH 45779 USA "
    },
    {
      "Location ID": "45780",
      "Location Name": "THE PLAINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 40 N PLAINS RD THE PLAINS OH 45780 USA "
    },
    {
      "Location ID": "45782",
      "Location Name": "TRIMBLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 CONGRESS ST USA OH 45782 USA "
    },
    {
      "Location ID": "45783",
      "Location Name": "TUPPERS PLAINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 42160 STATE ROUTE 7 TUPPERS PLAINS OH 45783 USA "
    },
    {
      "Location ID": "45784",
      "Location Name": "VINCENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 45 WARRIOR DR VINCENT OH 45784 USA "
    },
    {
      "Location ID": "45786",
      "Location Name": "WATERFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 635 MAIN ST WATERFORD OH 45786 USA "
    },
    {
      "Location ID": "45787",
      "Location Name": "WATERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 TOWNSHIP ROAD 109A WATERTOWN OH 45787 USA "
    },
    {
      "Location ID": "457EM",
      "Location Name": "ED MAP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 296 HARPER ST STE 1 NELSONVILLE OH 45764 USA "
    },
    {
      "Location ID": "457RA",
      "Location Name": "ROCK AUTO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 TENNIS CENTER DR MARIETTA OH 45739 USA "
    },
    {
      "Location ID": "457SM",
      "Location Name": "STEWART MACDONALD'S",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 N SHAFER ST STE B ATHENS OH 45701 USA "
    },
    {
      "Location ID": "45806",
      "Location Name": "CRIDERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 E MAIN ST CRIDERSVILLE OH 45806 USA "
    },
    {
      "Location ID": "45810",
      "Location Name": "ADA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 131 S MAIN ST ADA OH 45810 USA "
    },
    {
      "Location ID": "45812",
      "Location Name": "ALGER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 S MAIN ST ALGER OH 45812 USA "
    },
    {
      "Location ID": "45813",
      "Location Name": "ANTWERP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 303 S MAIN ST ANTWERP OH 45813 USA "
    },
    {
      "Location ID": "45814",
      "Location Name": "ARLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 W LIBERTY ST ARLINGTON OH 45814 USA "
    },
    {
      "Location ID": "45817",
      "Location Name": "BLUFFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 132 S MAIN ST BLUFFTON OH 45817 USA "
    },
    {
      "Location ID": "45820",
      "Location Name": "CAIRO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 W MAIN ST CAIRO OH 45820 USA "
    },
    {
      "Location ID": "45821",
      "Location Name": "CECIL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 N MAIN ST CECIL OH 45821 USA "
    },
    {
      "Location ID": "45822",
      "Location Name": "CELINA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 N MAIN ST CELINA OH 45822 USA "
    },
    {
      "Location ID": "45826",
      "Location Name": "CHICKASAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 49 W FRANKLIN ST CHICKASAW OH 45826 USA "
    },
    {
      "Location ID": "45827",
      "Location Name": "CLOVERDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 MAHONING ST CLOVERDALE OH 45827 USA "
    },
    {
      "Location ID": "45828",
      "Location Name": "COLDWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 E MAIN ST COLDWATER OH 45828 USA "
    },
    {
      "Location ID": "45830",
      "Location Name": "COLUMBUS GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 122 N HIGH ST COLUMBUS GROVE OH 45830 USA "
    },
    {
      "Location ID": "45831",
      "Location Name": "CONTINENTAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 S MAIN ST CONTINENTAL OH 45831 USA "
    },
    {
      "Location ID": "45832",
      "Location Name": "CONVOY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 W TULLY ST CONVOY OH 45832 USA "
    },
    {
      "Location ID": "45833",
      "Location Name": "DELPHOS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 127 N MAIN ST DELPHOS OH 45833 USA "
    },
    {
      "Location ID": "45835",
      "Location Name": "DOLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9924 FRANKLIN ST DOLA OH 45835 USA "
    },
    {
      "Location ID": "45836",
      "Location Name": "DUNKIRK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 N MAIN ST DUNKIRK OH 45836 USA "
    },
    {
      "Location ID": "45838",
      "Location Name": "ELGIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18100 SANDS RD ELGIN OH 45838-9998 USA "
    },
    {
      "Location ID": "45840",
      "Location Name": "FINDLAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 229 W MAIN CROSS ST FINDLAY OH 45840 USA "
    },
    {
      "Location ID": "45841",
      "Location Name": "JENERA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 N MAIN ST JENERA OH 45841-9998 USA "
    },
    {
      "Location ID": "45843",
      "Location Name": "FOREST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 E LIMA ST FOREST OH 45843 USA "
    },
    {
      "Location ID": "45844",
      "Location Name": "FORT JENNINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 S WATER ST FORT JENNINGS OH 45844 USA "
    },
    {
      "Location ID": "45845",
      "Location Name": "FORT LORAMIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 E PARK ST FORT LORAMIE OH 45845 USA "
    },
    {
      "Location ID": "45846",
      "Location Name": "FORT RECOVERY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 SAINT CLAIR ST FORT RECOVERY OH 45846 USA "
    },
    {
      "Location ID": "45848",
      "Location Name": "GLANDORF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 S MAIN ST GLANDORF OH 45848 USA "
    },
    {
      "Location ID": "45849",
      "Location Name": "GROVER HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 S MAIN ST GROVER HILL OH 45849 USA "
    },
    {
      "Location ID": "45850",
      "Location Name": "HARROD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 129 W 3RD ST HARROD OH 45850 USA "
    },
    {
      "Location ID": "45851",
      "Location Name": "HAVILAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 208 MAIN ST HAVILAND OH 45851 USA "
    },
    {
      "Location ID": "45853",
      "Location Name": "KALIDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 MAIN ST KALIDA OH 45853 USA "
    },
    {
      "Location ID": "45855",
      "Location Name": "LATTY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 380 2ND ST LATTY OH 45855 USA "
    },
    {
      "Location ID": "45856",
      "Location Name": "LEIPSIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 321 S BELMORE ST LEIPSIC OH 45856 USA "
    },
    {
      "Location ID": "45858",
      "Location Name": "MC COMB",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 W SOUTH ST MC COMB OH 45858 USA "
    },
    {
      "Location ID": "45859",
      "Location Name": "MC GUFFEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 402 COURTRIGHT ST MC GUFFEY OH 45859 USA "
    },
    {
      "Location ID": "45860",
      "Location Name": "MARIA STEIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8629 STATE ROUTE 119 MARIA STEIN OH 45860 USA "
    },
    {
      "Location ID": "45861",
      "Location Name": "MELROSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 519 STATE ST MELROSE OH 45861 USA "
    },
    {
      "Location ID": "45862",
      "Location Name": "MENDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 N MAIN ST MENDON OH 45862 USA "
    },
    {
      "Location ID": "45863",
      "Location Name": "MIDDLE POINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 E JACKSON ST MIDDLE POINT OH 45863 USA "
    },
    {
      "Location ID": "45864",
      "Location Name": "MILLER CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 S MAIN ST MILLER CITY OH 45864 USA "
    },
    {
      "Location ID": "45865",
      "Location Name": "MINSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 62 E 4TH ST MINSTER OH 45865 USA "
    },
    {
      "Location ID": "45866",
      "Location Name": "MONTEZUMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 99 W MAIN ST MONTEZUMA OH 45866 USA "
    },
    {
      "Location ID": "45867",
      "Location Name": "MOUNT BLANCHARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 N MAIN ST MOUNT BLANCHARD OH 45867 USA "
    },
    {
      "Location ID": "45868",
      "Location Name": "MOUNT CORY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 LINCOLN ST MOUNT CORY OH 45868 USA "
    },
    {
      "Location ID": "45869",
      "Location Name": "NEW BREMEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 N MAIN ST NEW BREMEN OH 45869 USA "
    },
    {
      "Location ID": "45870",
      "Location Name": "NEW HAMPSHIRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 46 E MARKET ST NEW HAMPSHIRE OH 45870 USA "
    },
    {
      "Location ID": "45871",
      "Location Name": "NEW KNOXVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 S MAIN ST NEW KNOXVILLE OH 45871 USA "
    },
    {
      "Location ID": "45872",
      "Location Name": "NORTH BALTIMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 E BROADWAY ST NORTH BALTIMORE OH 45872 USA "
    },
    {
      "Location ID": "45873",
      "Location Name": "OAKWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 223 N 1ST ST OAKWOOD OH 45873 USA "
    },
    {
      "Location ID": "45874",
      "Location Name": "OHIO CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 S MAIN ST OHIO CITY OH 45874 USA "
    },
    {
      "Location ID": "45875",
      "Location Name": "OTTAWA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 404 E MAIN ST OTTAWA OH 45875 USA "
    },
    {
      "Location ID": "45876",
      "Location Name": "OTTOVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 E 3RD ST OTTOVILLE OH 45876 USA "
    },
    {
      "Location ID": "45877",
      "Location Name": "PANDORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 E MAIN ST PANDORA OH 45877 USA "
    },
    {
      "Location ID": "45879",
      "Location Name": "PAULDING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 W JACKSON ST PAULDING OH 45879 USA "
    },
    {
      "Location ID": "45880",
      "Location Name": "PAYNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 S LAURA ST PAYNE OH 45880 USA "
    },
    {
      "Location ID": "45881",
      "Location Name": "RAWSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 S MAIN ST RAWSON OH 45881 USA "
    },
    {
      "Location ID": "45882",
      "Location Name": "ROCKFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 S MAIN ST ROCKFORD OH 45882 USA "
    },
    {
      "Location ID": "45883",
      "Location Name": "SAINT HENRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 E MAIN ST SAINT HENRY OH 45883 USA "
    },
    {
      "Location ID": "45884",
      "Location Name": "SAINT JOHNS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19210 CENTER ST SAINT JOHNS OH 45884 USA "
    },
    {
      "Location ID": "45885",
      "Location Name": "SAINT MARYS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 N CHESTNUT ST SAINT MARYS OH 45885 USA "
    },
    {
      "Location ID": "45886",
      "Location Name": "SCOTT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12436 BLAINE ST SCOTT OH 45886 USA "
    },
    {
      "Location ID": "45887",
      "Location Name": "SPENCERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 S BROADWAY ST SPENCERVILLE OH 45887 USA "
    },
    {
      "Location ID": "45888",
      "Location Name": "UNIOPOLIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 N MAIN ST UNIOPOLIS OH 45888 USA "
    },
    {
      "Location ID": "45889",
      "Location Name": "VAN BUREN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 S MAIN ST VAN BUREN OH 45889 USA "
    },
    {
      "Location ID": "45890",
      "Location Name": "VANLUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 S BUFFALO ST VANLUE OH 45890 USA "
    },
    {
      "Location ID": "45891",
      "Location Name": "VAN WERT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 314 E MAIN ST VAN WERT OH 45891 USA "
    },
    {
      "Location ID": "45893",
      "Location Name": "VAUGHNSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 342 W FINDLAY ST VAUGHNSVILLE OH 45893 USA "
    },
    {
      "Location ID": "45894",
      "Location Name": "VENEDOCIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15155 MAIN ST VENEDOCIA OH 45894 USA "
    },
    {
      "Location ID": "45895",
      "Location Name": "WAPAKONETA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 WILLIPIE ST WAPAKONETA OH 45895 USA "
    },
    {
      "Location ID": "45896",
      "Location Name": "WAYNESFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 N WESTMINSTER ST WAYNESFIELD OH 45896 USA "
    },
    {
      "Location ID": "45898",
      "Location Name": "WILLSHIRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 WALCOTT ST WILLSHIRE OH 45898 USA "
    },
    {
      "Location ID": "45899",
      "Location Name": "WREN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 STATE ROUTE 49 WREN OH 45899 USA "
    },
    {
      "Location ID": "458BB",
      "Location Name": "BEST BUY (FINDLAY)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14401 COUNTY ROAD 212 FINDLAY OH 45840 USA "
    },
    {
      "Location ID": "458DM",
      "Location Name": "DURALINE MEDICAL PRODUCTS INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 324 WERNER ST LEIPSIC OH 45856 USA "
    },
    {
      "Location ID": "458DT",
      "Location Name": "LIMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 350 W HIGH ST LIMA OH 45801 USA "
    },
    {
      "Location ID": "458MA",
      "Location Name": "MARATHON OIL (FINDLAY)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 539 S MAIN ST FINDLAY OH 45840-3229 USA "
    },
    {
      "Location ID": "458RR",
      "Location Name": "REYNOLDS & REYNOLDS (CELINA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 824 MURLIN AVE CELINA OH 45822 USA "
    },
    {
      "Location ID": "458UL",
      "Location Name": "UNIVERSAL LETTERING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1197 GRILL RD, B VAN WERT OH 45891 USA "
    },
    {
      "Location ID": "458UP",
      "Location Name": "U.S. PLASTICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1390 NEUBRECHT RD LIMA OH 45801-3196 USA "
    },
    {
      "Location ID": "45Z",
      "Location Name": "LOG CINCINNATI NDC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3055 CRESCENTVILLE RD CINCINNATI OH 45235 USA "
    },
    {
      "Location ID": "45ZCA",
      "Location Name": "CINCINNATI NDC PEAK ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3571 CONCORDE DR VANDALIA OH 45377 USA "
    },
    {
      "Location ID": "460",
      "Location Name": "INDIANAPOLIS P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 W SOUTH ST INDIANAPOLIS IN 46206 USA "
    },
    {
      "Location ID": "46011",
      "Location Name": "ANDERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1505 RAIBLE AVE ANDERSON IN 46011 USA "
    },
    {
      "Location ID": "46051",
      "Location Name": "LAPEL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 803 N MAIN ST LAPEL IN 46051 USA "
    },
    {
      "Location ID": "46056",
      "Location Name": "MARKLEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 E MAIN ST MARKLEVILLE IN 46056 USA "
    },
    {
      "Location ID": "46064",
      "Location Name": "PENDLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 137 W STATE ST PENDLETON IN 46064 USA "
    },
    {
      "Location ID": "460BR",
      "Location Name": "BROOKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4124 ALLPOINTS DR WHITESTOWN IN 46075 USA "
    },
    {
      "Location ID": "460FZ",
      "Location Name": "LOG INDIANAPOLIS IN PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 800 COMMERCE PARKWAY WEST DR STE A GREENWOOD IN 46143 USA "
    },
    {
      "Location ID": "460IT",
      "Location Name": "ITS LOGISTICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4683 ALBERT S WHITE DR WHITESTOWN IN 46075 USA "
    },
    {
      "Location ID": "460MW",
      "Location Name": "MEDCO WHITESTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4750 E 450 S WHITESTOWN IN 46075 USA "
    },
    {
      "Location ID": "460NO",
      "Location Name": "NORWEX USA INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4683 ALBERT S WHITE DR STE 100 WHITESTOWN IN 46075 USA "
    },
    {
      "Location ID": "460NR",
      "Location Name": "NIKE REBOUND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 135 S MOUNT ZION RD LEBANON IN 46052-8186 USA "
    },
    {
      "Location ID": "460SL",
      "Location Name": "STYLE LINK LOGISTICS LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5400 E 500 S WHITESTOWN IN 46075 USA "
    },
    {
      "Location ID": "460TF",
      "Location Name": "TF FULFILLMENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4055 S 500 E BLDG 7A WHITESTOWN IN 46075 USA "
    },
    {
      "Location ID": "46104",
      "Location Name": "ARLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2150 N 700 W ARLINGTON IN 46104 USA "
    },
    {
      "Location ID": "46111",
      "Location Name": "BROOKLYN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8 N MAIN ST BROOKLYN IN 46111 USA "
    },
    {
      "Location ID": "46125",
      "Location Name": "EMINENCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6543 STATE ROAD 42 N EMINENCE IN 46125 USA "
    },
    {
      "Location ID": "46130",
      "Location Name": "FOUNTAINTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11675 N DIVISION RD FOUNTAINTOWN IN 46130 USA "
    },
    {
      "Location ID": "46144",
      "Location Name": "GWYNNEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7137 E US HIGHWAY 52 GWYNNEVILLE IN 46144 USA "
    },
    {
      "Location ID": "46150",
      "Location Name": "MANILLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2971 S CROSS ST MANILLA IN 46150 USA "
    },
    {
      "Location ID": "46151",
      "Location Name": "MARTINSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 S MAIN ST MARTINSVILLE IN 46151 USA "
    },
    {
      "Location ID": "46156",
      "Location Name": "MILROY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 W MAIN ST MILROY IN 46156 USA "
    },
    {
      "Location ID": "46157",
      "Location Name": "MONROVIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 W WASHINGTON ST MONROVIA IN 46157 USA "
    },
    {
      "Location ID": "46161",
      "Location Name": "MORRISTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 E MAIN ST MORRISTOWN IN 46161 USA "
    },
    {
      "Location ID": "46163",
      "Location Name": "NEW PALESTINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17 S WESTSIDE DR NEW PALESTINE IN 46163 USA "
    },
    {
      "Location ID": "46166",
      "Location Name": "PARAGON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 151 W UNION ST PARAGON IN 46166 USA "
    },
    {
      "Location ID": "46173",
      "Location Name": "RUSHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 230 W 3RD ST RUSHVILLE IN 46173 USA "
    },
    {
      "Location ID": "46176",
      "Location Name": "SHELBYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 E WASHINGTON ST SHELBYVILLE IN 46176 USA "
    },
    {
      "Location ID": "461AN",
      "Location Name": "AMERICAN MAYHEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4202 N EMS BLVD STE 100 GREENFIELD IN 46140 USA "
    },
    {
      "Location ID": "461FX",
      "Location Name": "461FX FEDEX SUPPLY CHAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 700 COMMERCE PARKWAY WEST DR D GREENWOOD IN 46143 USA "
    },
    {
      "Location ID": "461KO",
      "Location Name": "KOHLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9998 ALLPOINTS PKWY PLAINFIELD IN 46168 USA "
    },
    {
      "Location ID": "46201",
      "Location Name": "LINWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4401 E 10TH ST STE 18 INDIANAPOLIS IN 46201 USA "
    },
    {
      "Location ID": "462FE",
      "Location Name": "462FE FEDEX SUPPLY CHAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5425 EXPLORATION DR STE 200 INDIANAPOLIS IN 46241 USA "
    },
    {
      "Location ID": "462KA",
      "Location Name": "LOG INDIANAPOLIS MP ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7451 TEMPELHOF DR STE A INDIANAPOLIS IN 46241 USA "
    },
    {
      "Location ID": "462PS",
      "Location Name": "PSI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5071 W 74TH ST INDIANAPOLIS IN 46268 USA "
    },
    {
      "Location ID": "462QA",
      "Location Name": "QUANTEM AVIATION SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7899 S SERVICE RD STE G INDIANAPOLIS IN 46241 USA "
    },
    {
      "Location ID": "462SM",
      "Location Name": "462SM FEDEX SMART POST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5454 DECATUR BLVD STE 241 INDIANAPOLIS IN 46241 USA "
    },
    {
      "Location ID": "463",
      "Location Name": "LOG GARY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1499 MARTIN LUTHER KING DR GARY IN 46401 USA "
    },
    {
      "Location ID": "46301",
      "Location Name": "BEVERLY SHORES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 165 E US HWY 12 BEVERLY SHORES IN 46301 USA "
    },
    {
      "Location ID": "46303",
      "Location Name": "CEDAR LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9715 W 133RD AVE CEDAR LAKE IN 46303 USA "
    },
    {
      "Location ID": "46304",
      "Location Name": "CHESTERTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1070 S CALUMET RD CHESTERTON IN 46304 USA "
    },
    {
      "Location ID": "46311",
      "Location Name": "DYER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2225 GREAT LAKES DR DYER IN 46311 USA "
    },
    {
      "Location ID": "46319",
      "Location Name": "GRIFFITH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 N BROAD ST GRIFFITH IN 46319 USA "
    },
    {
      "Location ID": "46342",
      "Location Name": "HOBART",
      "Time Zone Offset (Hours)": -1,
      "Address": " 221 MAIN ST HOBART IN 46342 USA "
    },
    {
      "Location ID": "46345",
      "Location Name": "KINGSBURY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5164 S US HIGHWAY 35 KINGSBURY IN 46345 USA "
    },
    {
      "Location ID": "46346",
      "Location Name": "KINGSFORD HEIGHTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 423 PARK RD KINGSFORD HEIGHTS IN 46346 USA "
    },
    {
      "Location ID": "46350",
      "Location Name": "LA PORTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1201 LINCOLNWAY LA PORTE IN 46350 USA "
    },
    {
      "Location ID": "46360",
      "Location Name": "MICHIGAN CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 WASHINGTON ST MICHIGAN CITY IN 46360 USA "
    },
    {
      "Location ID": "46365",
      "Location Name": "MILL CREEK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1618 S 875 E MILL CREEK IN 46365 USA "
    },
    {
      "Location ID": "46371",
      "Location Name": "ROLLING PRAIRIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3 S DEPOT ST ROLLING PRAIRIE IN 46371 USA "
    },
    {
      "Location ID": "46373",
      "Location Name": "SAINT JOHN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9200 THIEL ST SAINT JOHN IN 46373 USA "
    },
    {
      "Location ID": "46375",
      "Location Name": "SCHERERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1200 EAGLE RIDGE DR SCHERERVILLE IN 46375 USA "
    },
    {
      "Location ID": "46382",
      "Location Name": "UNION MILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 609 WATER ST UNION MILLS IN 46382 USA "
    },
    {
      "Location ID": "46383",
      "Location Name": "VALPARAISO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2700 VALPARAISO ST VALPARAISO IN 46383 USA "
    },
    {
      "Location ID": "46391",
      "Location Name": "WESTVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 PLAIN ST WESTVILLE IN 46391 USA "
    },
    {
      "Location ID": "46393",
      "Location Name": "WHEELER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 RAILROAD AVE WHEELER IN 46393 USA "
    },
    {
      "Location ID": "465",
      "Location Name": "LOG SOUTH BEND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 424 S MICHIGAN ST SOUTH BEND IN 46601 USA "
    },
    {
      "Location ID": "46507",
      "Location Name": "BRISTOL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 206 W VISTULA ST BRISTOL IN 46507 USA "
    },
    {
      "Location ID": "46515",
      "Location Name": "ELKHART            IN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 601 S MAIN ST ELKHART IN 46515 USA "
    },
    {
      "Location ID": "46526",
      "Location Name": "GOSHEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 415 W PIKE ST GOSHEN IN 46526 USA "
    },
    {
      "Location ID": "46540",
      "Location Name": "MIDDLEBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 S MAIN ST MIDDLEBURY IN 46540 USA "
    },
    {
      "Location ID": "46544",
      "Location Name": "MISHAWAKA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 E 3RD ST MISHAWAKA IN 46544 USA "
    },
    {
      "Location ID": "46550",
      "Location Name": "NAPPANEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 E MARKET ST NAPPANEE IN 46550 USA "
    },
    {
      "Location ID": "46565",
      "Location Name": "SHIPSHEWANA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 355 MAIN ST SHIPSHEWANA IN 46565 USA "
    },
    {
      "Location ID": "46571",
      "Location Name": "TOPEKA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 129 E LAKE ST TOPEKA IN 46571 USA "
    },
    {
      "Location ID": "46580",
      "Location Name": "WARSAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 365 W MARKET ST WARSAW IN 46580 USA "
    },
    {
      "Location ID": "46590",
      "Location Name": "WINONA LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 710 PARK AVE WINONA LAKE IN 46590 USA "
    },
    {
      "Location ID": "465RR",
      "Location Name": "LSC COMMUNICATIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2801 W OLD ROAD 30 WARSAW IN 46580 USA "
    },
    {
      "Location ID": "467",
      "Location Name": "LOG FORT WAYNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1501 S CLINTON ST FORT WAYNE IN 46802 USA "
    },
    {
      "Location ID": "46706",
      "Location Name": "AUBURN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 S JACKSON ST AUBURN IN 46706 USA "
    },
    {
      "Location ID": "46711",
      "Location Name": "BERNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 W MAIN ST BERNE IN 46711 USA "
    },
    {
      "Location ID": "46714",
      "Location Name": "BLUFFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 S MAIN ST BLUFFTON IN 46714 USA "
    },
    {
      "Location ID": "46733",
      "Location Name": "DECATUR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 213 COURT ST DECATUR IN 46733 USA "
    },
    {
      "Location ID": "46738",
      "Location Name": "GARRETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 W KEYSER ST GARRETT IN 46738 USA "
    },
    {
      "Location ID": "46745",
      "Location Name": "HOAGLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10811 HOAGLAND RD HOAGLAND IN 46745 USA "
    },
    {
      "Location ID": "46748",
      "Location Name": "HUNTERTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15741 LIMA RD HUNTERTOWN IN 46748 USA "
    },
    {
      "Location ID": "46765",
      "Location Name": "LEO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14015 AMSTUTZ RD LEO IN 46765 USA "
    },
    {
      "Location ID": "46772",
      "Location Name": "MONROE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 E WASHINGTON ST MONROE IN 46772 USA "
    },
    {
      "Location ID": "46774",
      "Location Name": "NEW HAVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 520 BROADWAY ST NEW HAVEN IN 46774 USA "
    },
    {
      "Location ID": "46777",
      "Location Name": "OSSIAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 S OGDEN ST OSSIAN IN 46777 USA "
    },
    {
      "Location ID": "46785",
      "Location Name": "SAINT JOE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 THIRD ST SAINT JOE IN 46785 USA "
    },
    {
      "Location ID": "46788",
      "Location Name": "SPENCERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6909 STATE ROAD 1 SPENCERVILLE IN 46788 USA "
    },
    {
      "Location ID": "468FX",
      "Location Name": "468FX FEDEX PLANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3620 INDEPENDENCE DR FORT WAYNE IN 46808 USA "
    },
    {
      "Location ID": "469",
      "Location Name": "KOKOMO P&DF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2719 S WEBSTER ST KOKOMO IN 46902 USA "
    },
    {
      "Location ID": "46910",
      "Location Name": "AKRON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 W ROCHESTER ST AKRON IN 46910-9998 USA "
    },
    {
      "Location ID": "46911",
      "Location Name": "AMBOY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 S MAIN ST AMBOY IN 46911-9998 USA "
    },
    {
      "Location ID": "46914",
      "Location Name": "BUNKER HILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 E BROADWAY BUNKER HILL IN 46914-9998 USA "
    },
    {
      "Location ID": "46915",
      "Location Name": "BURLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 809 MICHIGAN RD BURLINGTON IN 46915-9998 USA "
    },
    {
      "Location ID": "46916",
      "Location Name": "BURROWS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1010 BURROWS RD BURROWS IN 46916 USA "
    },
    {
      "Location ID": "46917",
      "Location Name": "CAMDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 W MAIN ST CAMDEN IN 46917-9998 USA "
    },
    {
      "Location ID": "46919",
      "Location Name": "CONVERSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 S JEFFERSON ST CONVERSE IN 46919-9998 USA "
    },
    {
      "Location ID": "46920",
      "Location Name": "CUTLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 96 E 485 S CUTLER IN 46920-9998 USA "
    },
    {
      "Location ID": "46921",
      "Location Name": "DEEDSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1211 W 1000 N DEEDSVILLE IN 46921 USA "
    },
    {
      "Location ID": "46922",
      "Location Name": "DELONG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9067 OLSON RD DELONG IN 46922 USA "
    },
    {
      "Location ID": "46923",
      "Location Name": "DELPHI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 W FRANKLIN ST DELPHI IN 46923 USA "
    },
    {
      "Location ID": "46926",
      "Location Name": "DENVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 W HARRISON ST DENVER IN 46926-9998 USA "
    },
    {
      "Location ID": "46928",
      "Location Name": "FAIRMOUNT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 204 S MAIN ST FAIRMOUNT IN 46928 USA "
    },
    {
      "Location ID": "46929",
      "Location Name": "FLORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 21 E MAIN ST FLORA IN 46929 USA "
    },
    {
      "Location ID": "46931",
      "Location Name": "FULTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7382 LIBERTY AVE FULTON IN 46931 USA "
    },
    {
      "Location ID": "46932",
      "Location Name": "GALVESTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 W WASHINGTON ST GALVESTON IN 46932 USA "
    },
    {
      "Location ID": "46933",
      "Location Name": "GAS CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 N 2ND ST GAS CITY IN 46933 USA "
    },
    {
      "Location ID": "46936",
      "Location Name": "GREENTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 204 S MERIDIAN ST GREENTOWN IN 46936 USA "
    },
    {
      "Location ID": "46937",
      "Location Name": "HEMLOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4577 E COUNTY RD 400 S KOKOMO IN 46937 USA "
    },
    {
      "Location ID": "46939",
      "Location Name": "KEWANNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 203 E MAIN ST KEWANNA IN 46939 USA "
    },
    {
      "Location ID": "46940",
      "Location Name": "LA FONTAINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12 W BRANSON ST LA FONTAINE IN 46940 USA "
    },
    {
      "Location ID": "46941",
      "Location Name": "LAGRO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 830 MAIN ST LAGRO IN 46941 USA "
    },
    {
      "Location ID": "46945",
      "Location Name": "LEITERS FORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4510 SCHOOL ST LEITERS FORD IN 46945 USA "
    },
    {
      "Location ID": "46946",
      "Location Name": "LIBERTY MILLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 E MAIN ST LIBERTY MILLS IN 46946 USA "
    },
    {
      "Location ID": "46947",
      "Location Name": "LOGANSPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 406 9TH ST LOGANSPORT IN 46947 USA "
    },
    {
      "Location ID": "46950",
      "Location Name": "LUCERNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1567 W FRUSHOUR ST LUCERNE IN 46950-9998 USA "
    },
    {
      "Location ID": "46951",
      "Location Name": "MACY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 180 E COMMERCE ST MACY IN 46951-9998 USA "
    },
    {
      "Location ID": "46952",
      "Location Name": "MARION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 W 3RD ST MARION IN 46952 USA "
    },
    {
      "Location ID": "46957",
      "Location Name": "MATTHEWS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 702 MASSACHUSETTS AVE MATTHEWS IN 46957 USA "
    },
    {
      "Location ID": "46958",
      "Location Name": "MEXICO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3969 N MEXICO RD MEXICO IN 46958 USA "
    },
    {
      "Location ID": "46960",
      "Location Name": "MONTEREY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6194 E MAIN ST STE B MONTEREY IN 46960 USA "
    },
    {
      "Location ID": "46962",
      "Location Name": "NORTH MANCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 E 2ND ST NORTH MANCHESTER IN 46962 USA "
    },
    {
      "Location ID": "46965",
      "Location Name": "OAKFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1184 E 400 S OAKFORD IN 46965 USA "
    },
    {
      "Location ID": "46970",
      "Location Name": "PERU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 S BROADWAY PERU IN 46970 USA "
    },
    {
      "Location ID": "46974",
      "Location Name": "ROANN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 160 N CHIPPEWA ST ROANN IN 46974-9998 USA "
    },
    {
      "Location ID": "46975",
      "Location Name": "ROCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 601 E 9TH ST ROCHESTER IN 46975 USA "
    },
    {
      "Location ID": "46978",
      "Location Name": "ROYAL CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 S CHICAGO ST ROYAL CENTER IN 46978-9998 USA "
    },
    {
      "Location ID": "46979",
      "Location Name": "RUSSIAVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 225 N UNION ST RUSSIAVILLE IN 46979 USA "
    },
    {
      "Location ID": "46980",
      "Location Name": "SERVIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 190 S MAIN ST SERVIA IN 46980 USA "
    },
    {
      "Location ID": "46982",
      "Location Name": "SILVER LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 E MAIN ST SILVER LAKE IN 46982-9998 USA "
    },
    {
      "Location ID": "46984",
      "Location Name": "SOMERSET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 SHOPPING CENTER RD SOMERSET IN 46984 USA "
    },
    {
      "Location ID": "46985",
      "Location Name": "STAR CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5800 S JUDSON ST STAR CITY IN 46985 USA "
    },
    {
      "Location ID": "46986",
      "Location Name": "SWAYZEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 N WASHINGTON ST SWAYZEE IN 46986-9998 USA "
    },
    {
      "Location ID": "46987",
      "Location Name": "SWEETSER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 W GREENSBURG ST SWEETSER IN 46987 USA "
    },
    {
      "Location ID": "46988",
      "Location Name": "TWELVE MILE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8051 E STATE ROAD 16 TWELVE MILE IN 46988-9998 USA "
    },
    {
      "Location ID": "46989",
      "Location Name": "UPLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 222 N MAIN ST UPLAND IN 46989 USA "
    },
    {
      "Location ID": "46990",
      "Location Name": "URBANA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 E MILL ST URBANA IN 46990-9998 USA "
    },
    {
      "Location ID": "46991",
      "Location Name": "VAN BUREN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 E MAIN ST VAN BUREN IN 46991-9998 USA "
    },
    {
      "Location ID": "46992",
      "Location Name": "WABASH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 W MAIN ST WABASH IN 46992 USA "
    },
    {
      "Location ID": "46994",
      "Location Name": "WALTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 N DEPOT ST WALTON IN 46994-9998 USA "
    },
    {
      "Location ID": "46996",
      "Location Name": "WINAMAC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 E PEARL ST WINAMAC IN 46996 USA "
    },
    {
      "Location ID": "46C",
      "Location Name": "MPA CHRISTMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3910 WALDEMERE AVE INDIANAPOLIS IN 46241 USA "
    },
    {
      "Location ID": "46H",
      "Location Name": "INDIANAPOLIS STC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 701 COLUMBIA RD STE 151 PLAINFIELD IN 46168 USA "
    },
    {
      "Location ID": "47006",
      "Location Name": "BATESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3 W GEORGE ST BATESVILLE IN 47006 USA "
    },
    {
      "Location ID": "47012",
      "Location Name": "BROOKVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1019 MAIN ST BROOKVILLE IN 47012 USA "
    },
    {
      "Location ID": "47016",
      "Location Name": "CEDAR GROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 204 CENTRAL AVE CEDAR GROVE IN 47016 USA "
    },
    {
      "Location ID": "47024",
      "Location Name": "LAUREL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 W PEARL ST LAUREL IN 47024 USA "
    },
    {
      "Location ID": "47030",
      "Location Name": "METAMORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19048 S MAIN ST METAMORA IN 47030 USA "
    },
    {
      "Location ID": "47041",
      "Location Name": "SUNMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 219 N MERIDIAN ST SUNMAN IN 47041 USA "
    },
    {
      "Location ID": "47043",
      "Location Name": "VEVAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 W PIKE ST VEVAY IN 47043 USA "
    },
    {
      "Location ID": "47111",
      "Location Name": "CHARLESTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 361 MAIN CROSS ST CHARLESTOWN IN 47111 USA "
    },
    {
      "Location ID": "47118",
      "Location Name": "ENGLISH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 S INDIANA AVE ENGLISH IN 47118 USA "
    },
    {
      "Location ID": "47122",
      "Location Name": "GEORGETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1095 MARCI LN GEORGETOWN IN 47122 USA "
    },
    {
      "Location ID": "47129",
      "Location Name": "CLARKSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 757 E LEWIS AND CLARK PKWY STE 814 CLARKSVILLE IN 47129 USA "
    },
    {
      "Location ID": "47130",
      "Location Name": "JEFFERSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 303 E COURT AVE JEFFERSONVILLE IN 47130 USA "
    },
    {
      "Location ID": "47140",
      "Location Name": "MARENGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 168 E PLEASANT AVE MARENGO IN 47140 USA "
    },
    {
      "Location ID": "47145",
      "Location Name": "MILLTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 W MAIN ST MILLTOWN IN 47145 USA "
    },
    {
      "Location ID": "47161",
      "Location Name": "NEW SALISBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7735 HIGHWAY 135 NE NEW SALISBURY IN 47161 USA "
    },
    {
      "Location ID": "47166",
      "Location Name": "RAMSEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1310 HIGHWAY 64 NW RAMSEY IN 47166 USA "
    },
    {
      "Location ID": "47167",
      "Location Name": "SALEM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 E WALNUT ST SALEM IN 47167 USA "
    },
    {
      "Location ID": "47170",
      "Location Name": "SCOTTSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 W MCCLAIN AVE SCOTTSBURG IN 47170 USA "
    },
    {
      "Location ID": "47172",
      "Location Name": "SELLERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 315 E UTICA ST SELLERSBURG IN 47172 USA "
    },
    {
      "Location ID": "471BP",
      "Location Name": "BAR PRODUCTS.COM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1010 KELLER DR NE NEW SALISBURY IN 47161 USA "
    },
    {
      "Location ID": "471SH",
      "Location Name": "SELLERSBURG HEATING & AIR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8703 COMMERCE PARK DR SELLERSBURG IN 47172 USA "
    },
    {
      "Location ID": "471SS",
      "Location Name": "SHOE SENSATION INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 800 PATROL RD JEFFERSONVLLE IN 47130 USA "
    },
    {
      "Location ID": "472",
      "Location Name": "COLUMBUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 450 JACKSON ST COLUMBUS IN 47201 USA "
    },
    {
      "Location ID": "47224",
      "Location Name": "CANAAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8988 N MAIN ST CANAAN IN 47224 USA "
    },
    {
      "Location ID": "47250",
      "Location Name": "MADISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 207 JEFFERSON ST MADISON IN 47250 USA "
    },
    {
      "Location ID": "472RR",
      "Location Name": "R R DONNELLEY  (IDS14)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 709 A AVENUE SEYMOUR IN 47274 USA "
    },
    {
      "Location ID": "473",
      "Location Name": "MUNCIE P&DF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4300 S COWAN RD MUNCIE IN 47302 USA "
    },
    {
      "Location ID": "47302",
      "Location Name": "MUNCIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 501 W MEMORIAL DR MUNCIE IN 47302 USA "
    },
    {
      "Location ID": "47305",
      "Location Name": "DOWNTOWN MUNCIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 S FRANKLIN ST MUNCIE IN 47305 USA "
    },
    {
      "Location ID": "47320",
      "Location Name": "ALBANY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 230 E STATE ST ALBANY IN 47320 USA "
    },
    {
      "Location ID": "47322",
      "Location Name": "BENTONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7029 N COUNTY ROAD 550 W BENTONVILLE IN 47322 USA "
    },
    {
      "Location ID": "47324",
      "Location Name": "BOSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 S SALEM ST BOSTON IN 47324 USA "
    },
    {
      "Location ID": "47326",
      "Location Name": "BRYANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 E MAIN ST BRYANT IN 47326 USA "
    },
    {
      "Location ID": "47327",
      "Location Name": "CAMBRIDGE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 227 W MAIN ST CAMBRIDGE CITY IN 47327 USA "
    },
    {
      "Location ID": "47330",
      "Location Name": "CENTERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 E MAIN ST CENTERVILLE IN 47330 USA "
    },
    {
      "Location ID": "47331",
      "Location Name": "CONNERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 801 N CENTRAL AVE CONNERSVILLE IN 47331 USA "
    },
    {
      "Location ID": "47334",
      "Location Name": "DALEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7924 S WALNUT ST DALEVILLE IN 47334 USA "
    },
    {
      "Location ID": "47335",
      "Location Name": "DUBLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2183 E CUMBERLAND ST DUBLIN IN 47335 USA "
    },
    {
      "Location ID": "47336",
      "Location Name": "DUNKIRK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 W COMMERCE ST DUNKIRK IN 47336 USA "
    },
    {
      "Location ID": "47337",
      "Location Name": "DUNREITH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 WASHINGTON ST DUNREITH IN 47337 USA "
    },
    {
      "Location ID": "47338",
      "Location Name": "EATON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 E HARRIS ST EATON IN 47338 USA "
    },
    {
      "Location ID": "47339",
      "Location Name": "ECONOMY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 218 MAIN ST ECONOMY IN 47339 USA "
    },
    {
      "Location ID": "47340",
      "Location Name": "FARMLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 S MAIN ST FARMLAND IN 47340 USA "
    },
    {
      "Location ID": "47341",
      "Location Name": "FOUNTAIN CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 S WALNUT ST FOUNTAIN CITY IN 47341 USA "
    },
    {
      "Location ID": "47342",
      "Location Name": "GASTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 W ELM ST GASTON IN 47342 USA "
    },
    {
      "Location ID": "47344",
      "Location Name": "GREENSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 260 N HIGH ST GREENSBORO IN 47344 USA "
    },
    {
      "Location ID": "47345",
      "Location Name": "GREENS FORK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15 S MAIN ST GREENS FORK IN 47345 USA "
    },
    {
      "Location ID": "47346",
      "Location Name": "HAGERSTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 W MAIN ST HAGERSTOWN IN 47346 USA "
    },
    {
      "Location ID": "47348",
      "Location Name": "HARTFORD CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 S HIGH ST HARTFORD CITY IN 47348 USA "
    },
    {
      "Location ID": "47351",
      "Location Name": "KENNARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 N MAIN ST KENNARD IN 47351 USA "
    },
    {
      "Location ID": "47352",
      "Location Name": "LEWISVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 W MAIN ST LEWISVILLE IN 47352 USA "
    },
    {
      "Location ID": "47353",
      "Location Name": "LIBERTY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 E UNION ST LIBERTY IN 47353 USA "
    },
    {
      "Location ID": "47354",
      "Location Name": "LOSANTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 N MAIN ST LOSANTVILLE IN 47354 USA "
    },
    {
      "Location ID": "47355",
      "Location Name": "LYNN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 S MAIN ST LYNN IN 47355 USA "
    },
    {
      "Location ID": "47356",
      "Location Name": "MIDDLETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 816 MILL ST MIDDLETOWN IN 47356 USA "
    },
    {
      "Location ID": "47357",
      "Location Name": "MILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 307 S CENTRAL AVE MILTON IN 47357 USA "
    },
    {
      "Location ID": "47358",
      "Location Name": "MODOC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 E CONLEY ST MODOC IN 47358 USA "
    },
    {
      "Location ID": "47359",
      "Location Name": "MONTPELIER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 S JEFFERSON ST MONTPELIER IN 47359 USA "
    },
    {
      "Location ID": "47360",
      "Location Name": "MOORELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 N BROAD ST MOORELAND IN 47360 USA "
    },
    {
      "Location ID": "47361",
      "Location Name": "MOUNT SUMMIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 W MAIN ST MOUNT SUMMIT IN 47361 USA "
    },
    {
      "Location ID": "47362",
      "Location Name": "NEW CASTLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 305 S 14TH ST NEW CASTLE IN 47362 USA "
    },
    {
      "Location ID": "47366",
      "Location Name": "NEW LISBON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6625 E COUNTY ROAD 500 S NEW LISBON IN 47366 USA "
    },
    {
      "Location ID": "47367",
      "Location Name": "OAKVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 551 W OAKVILLE RD OAKVILLE IN 47367 USA "
    },
    {
      "Location ID": "47368",
      "Location Name": "PARKER CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 S MAIN ST PARKER CITY IN 47368 USA "
    },
    {
      "Location ID": "47369",
      "Location Name": "PENNVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 S UNION ST PENNVILLE IN 47369 USA "
    },
    {
      "Location ID": "47370",
      "Location Name": "PERSHING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 MAIN ST PERSHING IN 47370 USA "
    },
    {
      "Location ID": "47371",
      "Location Name": "PORTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 S MERIDIAN ST PORTLAND IN 47371 USA "
    },
    {
      "Location ID": "47373",
      "Location Name": "REDKEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 133 W HIGH ST REDKEY IN 47373 USA "
    },
    {
      "Location ID": "47374",
      "Location Name": "RICHMOND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 400 N A ST OFC RICHMOND IN 47374 USA "
    },
    {
      "Location ID": "47380",
      "Location Name": "RIDGEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 S WALNUT ST RIDGEVILLE IN 47380 USA "
    },
    {
      "Location ID": "47382",
      "Location Name": "SARATOGA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 E MAIN ST SARATOGA IN 47382 USA "
    },
    {
      "Location ID": "47383",
      "Location Name": "SELMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 211 S ALBANY ST SELMA IN 47383 USA "
    },
    {
      "Location ID": "47384",
      "Location Name": "SHIRLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 330 MAIN ST SHIRLEY IN 47384 USA "
    },
    {
      "Location ID": "47385",
      "Location Name": "SPICELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 E MAIN ST SPICELAND IN 47385 USA "
    },
    {
      "Location ID": "47386",
      "Location Name": "SPRINGPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 312 W MAIN ST SPRINGPORT IN 47386 USA "
    },
    {
      "Location ID": "47387",
      "Location Name": "STRAUGHN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5004 E US HIGHWAY 40 STRAUGHN IN 47387 USA "
    },
    {
      "Location ID": "47388",
      "Location Name": "SULPHUR SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 N MERIDIAN ST SULPHUR SPRINGS IN 47388 USA "
    },
    {
      "Location ID": "47390",
      "Location Name": "UNION CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 W PEARL ST UNION CITY IN 47390 USA "
    },
    {
      "Location ID": "47392",
      "Location Name": "WEBSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3231 WEBSTER RD WEBSTER IN 47392 USA "
    },
    {
      "Location ID": "47393",
      "Location Name": "WILLIAMSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8293 MAIN ST WILLIAMSBURG IN 47393 USA "
    },
    {
      "Location ID": "47394",
      "Location Name": "WINCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 N MERIDIAN ST WINCHESTER IN 47394 USA "
    },
    {
      "Location ID": "47396",
      "Location Name": "YORKTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9301 W SMITH ST YORKTOWN IN 47396 USA "
    },
    {
      "Location ID": "474",
      "Location Name": "BLOOMINGTON ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4738 W VERNAL PIKE BLOOMINGTON IN 47404 USA "
    },
    {
      "Location ID": "47401",
      "Location Name": "BLOOMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 520 S WALNUT ST BLOOMINGTON IN 47401 USA "
    },
    {
      "Location ID": "47408",
      "Location Name": "WOODBRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3210 E 10TH ST BLOOMINGTON IN 47408 USA "
    },
    {
      "Location ID": "47420",
      "Location Name": "AVOCA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8464 STATE ROAD 54 W AVOCA IN 47420 USA "
    },
    {
      "Location ID": "47421",
      "Location Name": "BEDFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1720 H ST BEDFORD IN 47421 USA "
    },
    {
      "Location ID": "47424",
      "Location Name": "BLOOMFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 N WASHINGTON ST BLOOMFIELD IN 47424 USA "
    },
    {
      "Location ID": "47426",
      "Location Name": "CLEAR CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4888 S ROGERS ST CLEAR CREEK IN 47426 USA "
    },
    {
      "Location ID": "47427",
      "Location Name": "COAL CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4225 MAIN ST COAL CITY IN 47427 USA "
    },
    {
      "Location ID": "47429",
      "Location Name": "ELLETTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 N SALE ST ELLETTSVILLE IN 47429 USA "
    },
    {
      "Location ID": "47431",
      "Location Name": "FREEDOM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5634 MAIN ST FREEDOM IN 47431 USA "
    },
    {
      "Location ID": "47432",
      "Location Name": "FRENCH LICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8617 W COLLEGE ST FRENCH LICK IN 47432 USA "
    },
    {
      "Location ID": "47433",
      "Location Name": "GOSPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 N 4TH ST GOSPORT IN 47433 USA "
    },
    {
      "Location ID": "47434",
      "Location Name": "HARRODSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9262 S HARRODSBURG RD HARRODSBURG IN 47434 USA "
    },
    {
      "Location ID": "47435",
      "Location Name": "HELMSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2401 NORTH ST HELMSBURG IN 47435 USA "
    },
    {
      "Location ID": "47436",
      "Location Name": "HELTONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6525 STATE ROAD 58 E HELTONVILLE IN 47436 USA "
    },
    {
      "Location ID": "47437",
      "Location Name": "HURON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 513 HURON PIKE HURON IN 47437 USA "
    },
    {
      "Location ID": "47438",
      "Location Name": "JASONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 325 W MAIN ST JASONVILLE IN 47438 USA "
    },
    {
      "Location ID": "47441",
      "Location Name": "LINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 S MAIN ST LINTON IN 47441 USA "
    },
    {
      "Location ID": "47443",
      "Location Name": "LYONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 167 W BROAD ST LYONS IN 47443 USA "
    },
    {
      "Location ID": "47445",
      "Location Name": "MIDLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13340 W FIRST ST MIDLAND IN 47445 USA "
    },
    {
      "Location ID": "47446",
      "Location Name": "MITCHELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 N 7TH ST MITCHELL IN 47446 USA "
    },
    {
      "Location ID": "47448",
      "Location Name": "NASHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 E GOULD ST NASHVILLE IN 47448 USA "
    },
    {
      "Location ID": "47449",
      "Location Name": "NEWBERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 414 BROAD ST NEWBERRY IN 47449 USA "
    },
    {
      "Location ID": "47451",
      "Location Name": "OOLITIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 MAIN ST OOLITIC IN 47451 USA "
    },
    {
      "Location ID": "47452",
      "Location Name": "ORLEANS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 W WASHINGTON ST ORLEANS IN 47452 USA "
    },
    {
      "Location ID": "47454",
      "Location Name": "PAOLI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 N GOSPEL ST PAOLI IN 47454 USA "
    },
    {
      "Location ID": "47455",
      "Location Name": "PATRICKSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10097 STATE HIGHWAY 246 PATRICKSBURG IN 47455 USA "
    },
    {
      "Location ID": "47456",
      "Location Name": "QUINCY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3218 SOUTH ST QUINCY IN 47456 USA "
    },
    {
      "Location ID": "47458",
      "Location Name": "SMITHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7390 S STRAIN RIDGE RD SMITHVILLE IN 47458 USA "
    },
    {
      "Location ID": "47459",
      "Location Name": "SOLSBERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10023 E TULIP RD SOLSBERRY IN 47459 USA "
    },
    {
      "Location ID": "47460",
      "Location Name": "SPENCER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30 S WASHINGTON ST SPENCER IN 47460 USA "
    },
    {
      "Location ID": "47462",
      "Location Name": "SPRINGVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 193 BRICK ST SPRINGVILLE IN 47462 USA "
    },
    {
      "Location ID": "47463",
      "Location Name": "STANFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9048 W STATE ROAD 45 STANFORD IN 47463 USA "
    },
    {
      "Location ID": "47464",
      "Location Name": "STINESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8201 W MAIN ST STINESVILLE IN 47464 USA "
    },
    {
      "Location ID": "47465",
      "Location Name": "SWITZ CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 230 CHARLOTTE AVE SWITZ CITY IN 47465 USA "
    },
    {
      "Location ID": "47468",
      "Location Name": "UNIONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8031 E STATE ROAD 45 UNIONVILLE IN 47468 USA "
    },
    {
      "Location ID": "47469",
      "Location Name": "WEST BADEN SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8182 W STATE ROAD 56 WEST BADEN SPRINGS IN 47469 USA "
    },
    {
      "Location ID": "47470",
      "Location Name": "WILLIAMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 61 KERN ST WILLIAMS IN 47470 USA "
    },
    {
      "Location ID": "47471",
      "Location Name": "WORTHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 S COMMERCIAL ST WORTHINGTON IN 47471 USA "
    },
    {
      "Location ID": "475",
      "Location Name": "WASHINGTON IN CSO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 405 CENTER ST WASHINGTON IN 47501 USA "
    },
    {
      "Location ID": "47501",
      "Location Name": "WASHINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 405 CENTER ST WASHINGTON IN 47501 USA "
    },
    {
      "Location ID": "47586",
      "Location Name": "TELL CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 444 MAIN ST TELL CITY IN 47586 USA "
    },
    {
      "Location ID": "476",
      "Location Name": "EVANSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7100 PETERSBURG RD EVANSVILLE IN 47711 USA "
    },
    {
      "Location ID": "47601",
      "Location Name": "BOONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 W LOCUST ST BOONVILLE IN 47601 USA "
    },
    {
      "Location ID": "47610",
      "Location Name": "CHANDLER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 W LINCOLN AVE CHANDLER IN 47610 USA "
    },
    {
      "Location ID": "47611",
      "Location Name": "CHRISNEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 23 W MARKET ST CHRISNEY IN 47611 USA "
    },
    {
      "Location ID": "47612",
      "Location Name": "CYNTHIANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10864 MAIN ST CYNTHIANA IN 47612 USA "
    },
    {
      "Location ID": "47613",
      "Location Name": "ELBERFELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 155 W MAIN ST ELBERFELD IN 47613 USA "
    },
    {
      "Location ID": "47615",
      "Location Name": "GRANDVIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 MAIN ST GRANDVIEW IN 47615 USA "
    },
    {
      "Location ID": "47616",
      "Location Name": "GRIFFIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 224 S MAIN ST GRIFFIN IN 47616 USA "
    },
    {
      "Location ID": "47617",
      "Location Name": "HATFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9129 W OLD STATE ROAD 66 HATFIELD IN 47617 USA "
    },
    {
      "Location ID": "47618",
      "Location Name": "INGLEFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13200 DARMSTADT RD INGLEFIELD IN 47618 USA "
    },
    {
      "Location ID": "47619",
      "Location Name": "LYNNVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 216 N MAIN ST LYNNVILLE IN 47619 USA "
    },
    {
      "Location ID": "47620",
      "Location Name": "MOUNT VERNON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 308 WALNUT ST MOUNT VERNON IN 47620 USA "
    },
    {
      "Location ID": "47630",
      "Location Name": "NEWBURGH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5411 STATE ROUTE 261 NEWBURGH IN 47630 USA "
    },
    {
      "Location ID": "47631",
      "Location Name": "NEW HARMONY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 534 CHURCH ST NEW HARMONY IN 47631 USA "
    },
    {
      "Location ID": "47633",
      "Location Name": "POSEYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 41 S LOCUST ST POSEYVILLE IN 47633 USA "
    },
    {
      "Location ID": "47634",
      "Location Name": "RICHLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4143 N STATE ROAD 161 RICHLAND IN 47634 USA "
    },
    {
      "Location ID": "47635",
      "Location Name": "ROCKPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 N 5TH ST ROCKPORT IN 47635 USA "
    },
    {
      "Location ID": "47637",
      "Location Name": "TENNYSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 124 S MAIN ST TENNYSON IN 47637 USA "
    },
    {
      "Location ID": "47638",
      "Location Name": "WADESVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7255 MAIN ST WADESVILLE IN 47638 USA "
    },
    {
      "Location ID": "47639",
      "Location Name": "HAUBSTADT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 W HAUB ST HAUBSTADT IN 47639 USA "
    },
    {
      "Location ID": "47648",
      "Location Name": "FORT BRANCH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 E VINE ST FORT BRANCH IN 47648 USA "
    },
    {
      "Location ID": "47649",
      "Location Name": "FRANCISCO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 308 W MAIN ST FRANCISCO IN 47649 USA "
    },
    {
      "Location ID": "47654",
      "Location Name": "MACKEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9500 E MAIN ST MACKEY IN 47654 USA "
    },
    {
      "Location ID": "47660",
      "Location Name": "OAKLAND CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 W HARRISON ST OAKLAND CITY IN 47660 USA "
    },
    {
      "Location ID": "47665",
      "Location Name": "OWENSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 S MILL ST OWENSVILLE IN 47665 USA "
    },
    {
      "Location ID": "47666",
      "Location Name": "PATOKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 S MAIN ST PATOKA IN 47666 USA "
    },
    {
      "Location ID": "47670",
      "Location Name": "PRINCETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 129 E BROADWAY ST PRINCETON IN 47670 USA "
    },
    {
      "Location ID": "476BS",
      "Location Name": "BAND SHOPPE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8900 HIGHWAY 65 CYNTHIANA IN 47612 USA "
    },
    {
      "Location ID": "476TB",
      "Location Name": "THOR BROS (FRANCISCO)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2582 S 650 E FRANCISCO IN 47649 USA "
    },
    {
      "Location ID": "47714",
      "Location Name": "RIVER CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1915 WASHINGTON AVE EVANSVILLE IN 47714 USA "
    },
    {
      "Location ID": "477AN",
      "Location Name": "EVANSVILLE ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 800 SYCAMORE ST EVANSVILLE IN 47708 USA "
    },
    {
      "Location ID": "477EW",
      "Location Name": "CPU EL WALTERS A/C",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12420 N GREEN RIVER RD EVANSVILLE IN 47725 USA "
    },
    {
      "Location ID": "477PA",
      "Location Name": "EVANSVILLE PEAK ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2000 LYNCH RD EVANSVILLE IN 47711 USA "
    },
    {
      "Location ID": "478",
      "Location Name": "TERRE HAUTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 W MARGARET DR TERRE HAUTE IN 47802 USA "
    },
    {
      "Location ID": "47803",
      "Location Name": "ROSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 70 ROSE AVE TERRE HAUTE IN 47803 USA "
    },
    {
      "Location ID": "47805",
      "Location Name": "NORTH TERRE HAUTE BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3048 LAFAYETTE AVE NORTH TERRE HAU IN 47805 USA "
    },
    {
      "Location ID": "47831",
      "Location Name": "BLANFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15008 S MAIN ST BLANFORD IN 47831 USA "
    },
    {
      "Location ID": "47832",
      "Location Name": "BLOOMINGDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90 S MAIN ST BLOOMINGDALE IN 47832 USA "
    },
    {
      "Location ID": "47833",
      "Location Name": "BOWLING GREEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5181 E STATE ROAD 46 BOWLING GREEN IN 47833 USA "
    },
    {
      "Location ID": "47834",
      "Location Name": "BRAZIL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 227 W NATIONAL AVE BRAZIL IN 47834 USA "
    },
    {
      "Location ID": "47836",
      "Location Name": "BRIDGETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8128 S BRIDGETON RD BRIDGETON IN 47836 USA "
    },
    {
      "Location ID": "47837",
      "Location Name": "CARBON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14641 N POPLAR ST CARBON IN 47837 USA "
    },
    {
      "Location ID": "47838",
      "Location Name": "CARLISLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 N LEDGERWOOD ST CARLISLE IN 47838 USA "
    },
    {
      "Location ID": "47840",
      "Location Name": "CENTERPOINT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 504 E MAIN ST CENTERPOINT IN 47840 USA "
    },
    {
      "Location ID": "47841",
      "Location Name": "CLAY CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 804 MAIN ST CLAY CITY IN 47841 USA "
    },
    {
      "Location ID": "47842",
      "Location Name": "CLINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 405 VINE ST CLINTON IN 47842 USA "
    },
    {
      "Location ID": "47845",
      "Location Name": "COALMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6457 W JASON ST COALMONT IN 47845 USA "
    },
    {
      "Location ID": "47846",
      "Location Name": "CORY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5160 W DEPOT ST CORY IN 47846 USA "
    },
    {
      "Location ID": "47847",
      "Location Name": "DANA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 171 S MAPLE ST DANA IN 47847 USA "
    },
    {
      "Location ID": "47848",
      "Location Name": "DUGGER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8182 E MAIN ST DUGGER IN 47848 USA "
    },
    {
      "Location ID": "47849",
      "Location Name": "FAIRBANKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9236 N MAIN ST FAIRBANKS IN 47849 USA "
    },
    {
      "Location ID": "47850",
      "Location Name": "FARMERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 W MAIN ST FARMERSBURG IN 47850 USA "
    },
    {
      "Location ID": "47851",
      "Location Name": "FONTANET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8878 N MAIN ST FONTANET IN 47851 USA "
    },
    {
      "Location ID": "47852",
      "Location Name": "GRAYSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2269 N STATE ROAD 63 GRAYSVILLE IN 47852 USA "
    },
    {
      "Location ID": "47853",
      "Location Name": "HARMONY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1875 E US HIGHWAY 40 HARMONY IN 47853 USA "
    },
    {
      "Location ID": "47854",
      "Location Name": "HILLSDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7267 S 1ST ST HILLSDALE IN 47854 USA "
    },
    {
      "Location ID": "47855",
      "Location Name": "HYMERA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 S MAIN ST HYMERA IN 47855 USA "
    },
    {
      "Location ID": "47857",
      "Location Name": "KNIGHTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9737 N CRAWFORD ST KNIGHTSVILLE IN 47857 USA "
    },
    {
      "Location ID": "47858",
      "Location Name": "LEWIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10392 E STATE ROAD 246 LEWIS IN 47858 USA "
    },
    {
      "Location ID": "47859",
      "Location Name": "MARSHALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 61 E GUION ST MARSHALL IN 47859 USA "
    },
    {
      "Location ID": "47861",
      "Location Name": "MEROM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2066 S 3RD ST MEROM IN 47861 USA "
    },
    {
      "Location ID": "47862",
      "Location Name": "MONTEZUMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 982 N WASHINGTON ST MONTEZUMA IN 47862 USA "
    },
    {
      "Location ID": "47863",
      "Location Name": "NEW GOSHEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2785 DURKEES FERRY RD NEW GOSHEN IN 47863 USA "
    },
    {
      "Location ID": "47866",
      "Location Name": "PIMENTO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2378 E COTTOM DR PIMENTO IN 47866 USA "
    },
    {
      "Location ID": "47868",
      "Location Name": "POLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8460 E STATE ROAD 42 POLAND IN 47868 USA "
    },
    {
      "Location ID": "47869",
      "Location Name": "PRAIRIE CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15907 STATE ROAD 63 PRAIRIE CREEK IN 47869 USA "
    },
    {
      "Location ID": "47870",
      "Location Name": "PRAIRIETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3645 MURPHY RD PRAIRIETON IN 47870 USA "
    },
    {
      "Location ID": "47871",
      "Location Name": "RILEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7800 STATE ROAD 46 E RILEY IN 47871 USA "
    },
    {
      "Location ID": "47872",
      "Location Name": "ROCKVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 N MARKET ST ROCKVILLE IN 47872 USA "
    },
    {
      "Location ID": "47874",
      "Location Name": "ROSEDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 N MAIN ST ROSEDALE IN 47874 USA "
    },
    {
      "Location ID": "47875",
      "Location Name": "SAINT BERNICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3039 W BROADWAY ST SAINT BERNICE IN 47875 USA "
    },
    {
      "Location ID": "47876",
      "Location Name": "SAINT MARY OF THE WOODS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3678 N ARMS PL SAINT MARY OF THE WOODS IN 47876 USA "
    },
    {
      "Location ID": "47878",
      "Location Name": "SEELYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9538 E US HIGHWAY 40 SEELYVILLE IN 47878 USA "
    },
    {
      "Location ID": "47879",
      "Location Name": "SHELBURN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13 N RAILROAD ST SHELBURN IN 47879 USA "
    },
    {
      "Location ID": "47880",
      "Location Name": "SHEPARDSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90 E TRINITY AVE SHEPARDSVILLE IN 47880 USA "
    },
    {
      "Location ID": "47881",
      "Location Name": "STAUNTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 E COLUMBUS ST STAUNTON IN 47881 USA "
    },
    {
      "Location ID": "47882",
      "Location Name": "SULLIVAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 E WASHINGTON ST SULLIVAN IN 47882 USA "
    },
    {
      "Location ID": "47884",
      "Location Name": "UNIVERSAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 552 E WOOD AVE UNIVERSAL IN 47884 USA "
    },
    {
      "Location ID": "47885",
      "Location Name": "WEST TERRE HAUTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 S 3RD ST WEST TERRE HAUTE IN 47885 USA "
    },
    {
      "Location ID": "478SF",
      "Location Name": "SPEAKER FULFILLMENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2001 N HUNT ST TERRE HAUTE IN 47805 USA "
    },
    {
      "Location ID": "479",
      "Location Name": "LAFAYETTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3450 SOUTH ST LAFAYETTE IN 47905 USA "
    },
    {
      "Location ID": "47906",
      "Location Name": "WEST LAFAYETTE CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1221 CUMBERLAND AVE WEST LAFAYETTE IN 47906 USA "
    },
    {
      "Location ID": "47923",
      "Location Name": "BROOKSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 S SOUTH ST BROOKSTON IN 47923 USA "
    },
    {
      "Location ID": "47925",
      "Location Name": "BUFFALO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 S MIDDLE ST BUFFALO IN 47925 USA "
    },
    {
      "Location ID": "47926",
      "Location Name": "BURNETTSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 310 LOGAN ST BURNETTSVILLE IN 47926 USA "
    },
    {
      "Location ID": "47929",
      "Location Name": "CHALMERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 E MAIN ST CHALMERS IN 47929 USA "
    },
    {
      "Location ID": "47932",
      "Location Name": "COVINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 418 LIBERTY ST COVINGTON IN 47932 USA "
    },
    {
      "Location ID": "47942",
      "Location Name": "EARL PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 W 5TH ST EARL PARK IN 47942 USA "
    },
    {
      "Location ID": "47944",
      "Location Name": "FOWLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 S MADISON AVE FOWLER IN 47944 USA "
    },
    {
      "Location ID": "47946",
      "Location Name": "FRANCESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 122 S BILL ST FRANCESVILLE IN 47946 USA "
    },
    {
      "Location ID": "47948",
      "Location Name": "GOODLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 122 W UNION ST GOODLAND IN 47948 USA "
    },
    {
      "Location ID": "47949",
      "Location Name": "HILLSBORO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 E MAIN ST HILLSBORO IN 47949 USA "
    },
    {
      "Location ID": "47950",
      "Location Name": "IDAVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 W US HIGHWAY 24 IDAVILLE IN 47950 USA "
    },
    {
      "Location ID": "47951",
      "Location Name": "KENTLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 309 E GRAHAM ST KENTLAND IN 47951 USA "
    },
    {
      "Location ID": "47957",
      "Location Name": "MEDARYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 501 E MAIN ST MEDARYVILLE IN 47957 USA "
    },
    {
      "Location ID": "47958",
      "Location Name": "MELLOTT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 S MAIN ST MELLOTT IN 47958 USA "
    },
    {
      "Location ID": "47959",
      "Location Name": "MONON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 415 N MARKET ST MONON IN 47959 USA "
    },
    {
      "Location ID": "47960",
      "Location Name": "MONTICELLO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 W BROADWAY ST MONTICELLO IN 47960 USA "
    },
    {
      "Location ID": "47969",
      "Location Name": "NEWTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 W WASHINGTON ST NEWTOWN IN 47969 USA "
    },
    {
      "Location ID": "47977",
      "Location Name": "REMINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 31 S OHIO ST REMINGTON IN 47977 USA "
    },
    {
      "Location ID": "47980",
      "Location Name": "REYNOLDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 801 E 2ND ST REYNOLDS IN 47980 USA "
    },
    {
      "Location ID": "47987",
      "Location Name": "VEEDERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 N MAIN ST VEEDERSBURG IN 47987 USA "
    },
    {
      "Location ID": "479MI",
      "Location Name": "MAIL INC - DMU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2730 N 9TH ST LAFAYETTE IN 47904 USA "
    },
    {
      "Location ID": "47C",
      "Location Name": "MPA CHRISTMAS ANNEX P4",
      "Time Zone Offset (Hours)": 0,
      "Address": " 909 WHITAKER RD PLAINFIELD IN 46168 USA "
    },
    {
      "Location ID": "480",
      "Location Name": "MICHIGAN METROPLEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 711 N GLENWOOD AVE PONTIAC MI 48340 USA "
    },
    {
      "Location ID": "48005",
      "Location Name": "ARMADA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 74260 BURK ST ARMADA MI 48005 USA "
    },
    {
      "Location ID": "48006",
      "Location Name": "AVOCA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8810 AVOCA RD AVOCA MI 48006 USA "
    },
    {
      "Location ID": "48022",
      "Location Name": "EMMETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3120 MAIN ST EMMETT MI 48022 USA "
    },
    {
      "Location ID": "48032",
      "Location Name": "JEDDO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8511 WILDCAT RD JEDDO MI 48032 USA "
    },
    {
      "Location ID": "48038",
      "Location Name": "CLINTON MACOMB ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15285 SAMOHIN DR MACOMB MI 48044 USA "
    },
    {
      "Location ID": "48041",
      "Location Name": "MEMPHIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34805 POTTER ST MEMPHIS MI 48041 USA "
    },
    {
      "Location ID": "48046",
      "Location Name": "MOUNT CLEMENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 155 S MAIN ST MOUNT CLEMENS MI 48046 USA "
    },
    {
      "Location ID": "48048",
      "Location Name": "NEW HAVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 58480 MAIN ST NEW HAVEN MI 48048 USA "
    },
    {
      "Location ID": "48049",
      "Location Name": "NORTH STREET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5201 NORTH RD NORTH STREET MI 48049 USA "
    },
    {
      "Location ID": "48061",
      "Location Name": "PORT HURON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 852 32ND ST PORT HURON MI 48061 USA "
    },
    {
      "Location ID": "48062",
      "Location Name": "RICHMOND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36511 DIVISION RD RICHMOND MI 48062 USA "
    },
    {
      "Location ID": "48090",
      "Location Name": "WARREN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28401 MOUND RD WARREN MI 48090 USA "
    },
    {
      "Location ID": "48097",
      "Location Name": "YALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 211 JONES ST YALE MI 48097 USA "
    },
    {
      "Location ID": "481",
      "Location Name": "LOG DETROIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1401 W FORT ST RM 1022 DETROIT MI 48233 USA "
    },
    {
      "Location ID": "48101",
      "Location Name": "ALLEN PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6800 ROOSEVELT AVE ALLEN PARK MI 48101 USA "
    },
    {
      "Location ID": "48103",
      "Location Name": "ANN ARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2075 W STADIUM BLVD ANN ARBOR MI 48103 USA "
    },
    {
      "Location ID": "48104",
      "Location Name": "LIBERTY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 E LIBERTY ST LBBY ANN ARBOR MI 48104 USA "
    },
    {
      "Location ID": "48106",
      "Location Name": "SOUTH UNIVERSITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1214 S UNIVERSITY AVE STE S104 ANN ARBOR MI 48104 USA "
    },
    {
      "Location ID": "48107",
      "Location Name": "LIBERTY STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 E LIBERTY ANN ARBOR MI 48107 USA "
    },
    {
      "Location ID": "48111",
      "Location Name": "BELLEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 45601 S INTERSTATE 94 SERVICE DR BELLEVILLE MI 48111 USA "
    },
    {
      "Location ID": "48115",
      "Location Name": "BRIDGEWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8994 AUSTIN RD SALINE MI 48115 USA "
    },
    {
      "Location ID": "48116",
      "Location Name": "BRIGHTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 ORNDORF DR BRIGHTON MI 48116 USA "
    },
    {
      "Location ID": "48117",
      "Location Name": "CARLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12840 GRAFTON RD CARLETON MI 48117 USA "
    },
    {
      "Location ID": "48118",
      "Location Name": "CHELSEA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1101 S MAIN ST STE 100 CHELSEA MI 48118 USA "
    },
    {
      "Location ID": "48125",
      "Location Name": "FT DEARBORN ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22599 VAN BORN RD DEARBORN MI 48124 USA "
    },
    {
      "Location ID": "48130",
      "Location Name": "DEXTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3140 BAKER RD DEXTER MI 48130 USA "
    },
    {
      "Location ID": "48133",
      "Location Name": "ERIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9780 S DIXIE HWY ERIE MI 48133 USA "
    },
    {
      "Location ID": "48134",
      "Location Name": "FLAT ROCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 26216 GIBRALTAR RD FLAT ROCK MI 48134 USA "
    },
    {
      "Location ID": "48135",
      "Location Name": "GARDEN CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 140 MERRIMAN RD GARDEN CITY MI 48135 USA "
    },
    {
      "Location ID": "48137",
      "Location Name": "GREGORY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 E M 36 GREGORY MI 48137 USA "
    },
    {
      "Location ID": "48138",
      "Location Name": "GROSSE ILE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8841 MACOMB ST GROSSE ILE MI 48138 USA "
    },
    {
      "Location ID": "48139",
      "Location Name": "HAMBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7420 E MI STATE ROAD 36 HAMBURG MI 48139 USA "
    },
    {
      "Location ID": "48141",
      "Location Name": "INKSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27631 MICHIGAN AVE INKSTER MI 48141 USA "
    },
    {
      "Location ID": "48143",
      "Location Name": "LAKELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9680 KRESS RD LAKELAND MI 48143 USA "
    },
    {
      "Location ID": "48144",
      "Location Name": "LAMBERTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8213 SECOR RD LAMBERTVILLE MI 48144 USA "
    },
    {
      "Location ID": "48145",
      "Location Name": "LA SALLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3985 S OTTER CREEK RD LA SALLE MI 48145 USA "
    },
    {
      "Location ID": "48146",
      "Location Name": "LINCOLN PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1515 FORT ST LINCOLN PARK MI 48146 USA "
    },
    {
      "Location ID": "48150",
      "Location Name": "LIVONIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11800 MERRIMAN RD LIVONIA MI 48150 USA "
    },
    {
      "Location ID": "48152",
      "Location Name": "GREENMEAD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 37500 PEMBROKE RD LIVONIA MI 48152 USA "
    },
    {
      "Location ID": "48157",
      "Location Name": "LUNA PIER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4352 LUNA PIER RD LUNA PIER MI 48157 USA "
    },
    {
      "Location ID": "48158",
      "Location Name": "MANCHESTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 E MAIN ST MANCHESTER MI 48158 USA "
    },
    {
      "Location ID": "48160",
      "Location Name": "MILAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 E MAIN ST MILAN MI 48160 USA "
    },
    {
      "Location ID": "48161",
      "Location Name": "MONROE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 W FRONT ST MONROE MI 48161 USA "
    },
    {
      "Location ID": "48165",
      "Location Name": "NEW HUDSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 56655 GRAND RIVER AVE NEW HUDSON MI 48165 USA "
    },
    {
      "Location ID": "48166",
      "Location Name": "NEWPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8463 SWAN CREEK RD NEWPORT MI 48166 USA "
    },
    {
      "Location ID": "48169",
      "Location Name": "PINCKNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1325 E M 36 PINCKNEY MI 48169 USA "
    },
    {
      "Location ID": "48173",
      "Location Name": "ROCKWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22181 HURON RIVER DR ROCKWOOD MI 48173 USA "
    },
    {
      "Location ID": "48176",
      "Location Name": "SALINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 N MAPLE RD SALINE MI 48176 USA "
    },
    {
      "Location ID": "48177",
      "Location Name": "SAMARIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1344 SAMARIA RD SAMARIA MI 48177 USA "
    },
    {
      "Location ID": "48178",
      "Location Name": "SOUTH LYON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 S LAFAYETTE ST SOUTH LYON MI 48178 USA "
    },
    {
      "Location ID": "48179",
      "Location Name": "SOUTH ROCKWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12730 DIXIE HWY SOUTH ROCKWOOD MI 48179 USA "
    },
    {
      "Location ID": "48180",
      "Location Name": "TAYLOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24837 GODDARD RD TAYLOR MI 48180 USA "
    },
    {
      "Location ID": "48182",
      "Location Name": "TEMPERANCE MI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8149 LEWIS AVE TEMPERANCE MI 48182-9998 USA "
    },
    {
      "Location ID": "48183",
      "Location Name": "TRENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2740 3RD ST TRENTON MI 48183 USA "
    },
    {
      "Location ID": "48185",
      "Location Name": "WESTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6300 N WAYNE RD WESTLAND MI 48185 USA "
    },
    {
      "Location ID": "48187",
      "Location Name": "CANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 480 N CANTON CENTER RD CANTON MI 48187 USA "
    },
    {
      "Location ID": "48189",
      "Location Name": "WHITMORE LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9725 MAIN ST WHITMORE LAKE MI 48189 USA "
    },
    {
      "Location ID": "48190",
      "Location Name": "WHITTAKER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11014 WHITTAKER RD WHITTAKER MI 48190 USA "
    },
    {
      "Location ID": "48191",
      "Location Name": "WILLIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8790 BUNTON RD WILLIS MI 48191 USA "
    },
    {
      "Location ID": "48192",
      "Location Name": "WYANDOTTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 231 EUREKA RD WYANDOTTE MI 48192 USA "
    },
    {
      "Location ID": "48195",
      "Location Name": "SOUTHGATE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14000 DIX TOLEDO RD SOUTHGATE MI 48195 USA "
    },
    {
      "Location ID": "48198",
      "Location Name": "YPSILANTI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1606 S HURON ST YPSILANTI MI 48197 USA "
    },
    {
      "Location ID": "481AF",
      "Location Name": "AFFORDABLE TOOLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6385 S DIXIE HWY ERIE MI 48133 USA "
    },
    {
      "Location ID": "481FZ",
      "Location Name": "LOG DETROIT MI PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18765 SEAWAY DR MELVINDALE MI 48122 USA "
    },
    {
      "Location ID": "481KA",
      "Location Name": "KEYSTONE AUTOMOTIVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17950 DIX TOLEDO HWY BROWNSTOWN MI 48193 USA "
    },
    {
      "Location ID": "481SH",
      "Location Name": "SHAR PRODUCTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2465 S INDUSTRIAL HWY ANN ARBOR MI 48104 USA "
    },
    {
      "Location ID": "48212",
      "Location Name": "HAMTRAMCK ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14600 DEQUINDRE ST DETROIT MI 48212 USA "
    },
    {
      "Location ID": "482HA",
      "Location Name": "HAMTRAMCK ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14600 DEQUINDRE ST DETROIT MI 48212 USA "
    },
    {
      "Location ID": "483",
      "Location Name": "MICHIGAN METROPLEX P&D",
      "Time Zone Offset (Hours)": 0,
      "Address": " 711 N GLENWOOD PONTIAC MI 48340 USA "
    },
    {
      "Location ID": "48311",
      "Location Name": "STERLING HEIGHTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7007 METROPOLITAN PKWY STERLING HEIGHTS MI 48311 USA "
    },
    {
      "Location ID": "48320",
      "Location Name": "KEEGO HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2851 ORCHARD LAKE RD KEEGO HARBOR MI 48320 USA "
    },
    {
      "Location ID": "483CA",
      "Location Name": "NORTHVILLE PEAK ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23231 INDUSTRIAL PARK DR FARMINGTON HILLS MI 48335 USA "
    },
    {
      "Location ID": "484",
      "Location Name": "FLINT P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 E BOULEVARD DR FLINT MI 48502 USA "
    },
    {
      "Location ID": "48401",
      "Location Name": "APPLEGATE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2469 S SHERMAN RD APPLEGATE MI 48401 USA "
    },
    {
      "Location ID": "48411",
      "Location Name": "ATLAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8519 PERRY RD ATLAS MI 48411 USA "
    },
    {
      "Location ID": "48412",
      "Location Name": "ATTICA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 485 N LAKE PLEASANT RD ATTICA MI 48412 USA "
    },
    {
      "Location ID": "48413",
      "Location Name": "BAD AXE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 133 N HANSELMAN ST BAD AXE MI 48413 USA "
    },
    {
      "Location ID": "48414",
      "Location Name": "BANCROFT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 N MAIN ST BANCROFT MI 48414 USA "
    },
    {
      "Location ID": "48415",
      "Location Name": "BIRCH RUN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8196 MAIN ST BIRCH RUN MI 48415 USA "
    },
    {
      "Location ID": "48416",
      "Location Name": "BROWN CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4160 MAIN ST BROWN CITY MI 48416 USA "
    },
    {
      "Location ID": "48417",
      "Location Name": "BURT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12925 NICHOLS RD BURT MI 48417 USA "
    },
    {
      "Location ID": "48418",
      "Location Name": "BYRON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 203 S SAGINAW ST BYRON MI 48418 USA "
    },
    {
      "Location ID": "48419",
      "Location Name": "CARSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 77 S MAIN ST CARSONVILLE MI 48419 USA "
    },
    {
      "Location ID": "48420",
      "Location Name": "CLIO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 800 S MILL ST CLIO MI 48420 USA "
    },
    {
      "Location ID": "48421",
      "Location Name": "COLUMBIAVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4643 PINE ST COLUMBIAVILLE MI 48421 USA "
    },
    {
      "Location ID": "48422",
      "Location Name": "CROSWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 N HOWARD AVE CROSWELL MI 48422 USA "
    },
    {
      "Location ID": "48423",
      "Location Name": "DAVISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10290 DAVISON RD DAVISON MI 48423 USA "
    },
    {
      "Location ID": "48426",
      "Location Name": "DECKER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1624 VAN DYKE RD DECKER MI 48426 USA "
    },
    {
      "Location ID": "48427",
      "Location Name": "DECKERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2517 MAPLE ST DECKERVILLE MI 48427 USA "
    },
    {
      "Location ID": "48428",
      "Location Name": "DRYDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5580 MAIN ST DRYDEN MI 48428 USA "
    },
    {
      "Location ID": "48429",
      "Location Name": "DURAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 211 N OAK ST DURAND MI 48429 USA "
    },
    {
      "Location ID": "48430",
      "Location Name": "FENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 S LEROY ST FENTON MI 48430 USA "
    },
    {
      "Location ID": "48433",
      "Location Name": "FLUSHING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 127 S CHERRY ST FLUSHING MI 48433 USA "
    },
    {
      "Location ID": "48434",
      "Location Name": "FORESTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8007 3RD ST FORESTVILLE MI 48434 USA "
    },
    {
      "Location ID": "48435",
      "Location Name": "FOSTORIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9301 FOSTER ST FOSTORIA MI 48435 USA "
    },
    {
      "Location ID": "48436",
      "Location Name": "GAINES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 GENESEE ST GAINES MI 48436 USA "
    },
    {
      "Location ID": "48437",
      "Location Name": "GENESEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7198 N GENESEE RD GENESEE MI 48437 USA "
    },
    {
      "Location ID": "48438",
      "Location Name": "GOODRICH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8283 S STATE RD GOODRICH MI 48438 USA "
    },
    {
      "Location ID": "48439",
      "Location Name": "GRAND BLANC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11426 DAVIS ST GRAND BLANC MI 48480 USA "
    },
    {
      "Location ID": "48440",
      "Location Name": "HADLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3481 HADLEY RD HADLEY MI 48440 USA "
    },
    {
      "Location ID": "48441",
      "Location Name": "HARBOR BEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 SCHOOL ST HARBOR BEACH MI 48441 USA "
    },
    {
      "Location ID": "48442",
      "Location Name": "HOLLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 W MAPLE ST HOLLY MI 48442 USA "
    },
    {
      "Location ID": "48444",
      "Location Name": "IMLAY CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 310 E 3RD ST IMLAY CITY MI 48444 USA "
    },
    {
      "Location ID": "48445",
      "Location Name": "KINDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 MAIN ST KINDE MI 48445 USA "
    },
    {
      "Location ID": "48446",
      "Location Name": "LAPEER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 567 W NEPESSING ST LAPEER MI 48446 USA "
    },
    {
      "Location ID": "48449",
      "Location Name": "LENNON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3195 S SHERIDAN RD LENNON MI 48449 USA "
    },
    {
      "Location ID": "48450",
      "Location Name": "LEXINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5204 MAIN ST LEXINGTON MI 48450 USA "
    },
    {
      "Location ID": "48451",
      "Location Name": "LINDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 S MAIN ST LINDEN MI 48451 USA "
    },
    {
      "Location ID": "48453",
      "Location Name": "MARLETTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2984 FENNER ST MARLETTE MI 48453 USA "
    },
    {
      "Location ID": "48454",
      "Location Name": "MELVIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1296 MAIN ST MELVIN MI 48454 USA "
    },
    {
      "Location ID": "48455",
      "Location Name": "METAMORA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3795 N OAK ST METAMORA MI 48455 USA "
    },
    {
      "Location ID": "48456",
      "Location Name": "MINDEN CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1608 MAIN ST MINDEN CITY MI 48456 USA "
    },
    {
      "Location ID": "48457",
      "Location Name": "MONTROSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 W MAPLE ST MONTROSE MI 48457 USA "
    },
    {
      "Location ID": "48458",
      "Location Name": "MOUNT MORRIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 760 ALBERT ST MOUNT MORRIS MI 48458 USA "
    },
    {
      "Location ID": "48460",
      "Location Name": "NEW LOTHROP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7505 S SAGINAW ST NEW LOTHROP MI 48460 USA "
    },
    {
      "Location ID": "48461",
      "Location Name": "NORTH BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6720 LINCOLN ST NORTH BRANCH MI 48461 USA "
    },
    {
      "Location ID": "48462",
      "Location Name": "ORTONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 POND ST ORTONVILLE MI 48462 USA "
    },
    {
      "Location ID": "48463",
      "Location Name": "OTISVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 199 N STATE ST OTISVILLE MI 48463 USA "
    },
    {
      "Location ID": "48464",
      "Location Name": "OTTER LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6384 DETROIT ST OTTER LAKE MI 48464 USA "
    },
    {
      "Location ID": "48466",
      "Location Name": "PECK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 46 E LAPEER ST PECK MI 48466 USA "
    },
    {
      "Location ID": "48467",
      "Location Name": "PORT AUSTIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8710 NORTH ST PORT AUSTIN MI 48467 USA "
    },
    {
      "Location ID": "48468",
      "Location Name": "PORT HOPE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4456 MAIN ST PORT HOPE MI 48468 USA "
    },
    {
      "Location ID": "48469",
      "Location Name": "PORT SANILAC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 N RIDGE ST PORT SANILAC MI 48469 USA "
    },
    {
      "Location ID": "48470",
      "Location Name": "RUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7031 E ATWATER RD RUTH MI 48470 USA "
    },
    {
      "Location ID": "48471",
      "Location Name": "SANDUSKY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55 S MORSE ST SANDUSKY MI 48471 USA "
    },
    {
      "Location ID": "48472",
      "Location Name": "SNOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1493 MAIN ST SNOVER MI 48472 USA "
    },
    {
      "Location ID": "48473",
      "Location Name": "SWARTZ CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8055 PAUL FORTINO DR SWARTZ CREEK MI 48473 USA "
    },
    {
      "Location ID": "48475",
      "Location Name": "UBLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4482 GARFIELD ST UBLY MI 48475 USA "
    },
    {
      "Location ID": "48476",
      "Location Name": "VERNON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 137 E MAIN ST VERNON MI 48476 USA "
    },
    {
      "Location ID": "484AE",
      "Location Name": "AUTO EXPRESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 231 E NEPESSING STREET LAPEER MI 48446 USA "
    },
    {
      "Location ID": "484AU",
      "Location Name": "STEINER TRACTOR PARTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1660 S M 13 LENNON MI 48449 USA "
    },
    {
      "Location ID": "484DH",
      "Location Name": "DHL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8204 EMBURY ROAD GRAND BLANC MI 48439 USA "
    },
    {
      "Location ID": "484GP",
      "Location Name": "GROWTH & OPPORTUNITY GOLF BALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 525 S COURT ST LAPEER MI 48446-2552 USA "
    },
    {
      "Location ID": "484JF",
      "Location Name": "JOSH'S FROGS LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8751 MONROE RD DURAND MI 48429 USA "
    },
    {
      "Location ID": "484NW",
      "Location Name": "NORTHWEST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3274 W PASADENA AVE FLINT MI 48504 USA "
    },
    {
      "Location ID": "484SD",
      "Location Name": "SHANGO DISTRIBUTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 MOUNTAIN DRIVE IMLAY CITY MI 48444 USA "
    },
    {
      "Location ID": "484VO",
      "Location Name": "VERNON OUTDOOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7320 E M71 VERNON MI 48476 USA "
    },
    {
      "Location ID": "484ZZ",
      "Location Name": "ZOOM ZOOM PARTS CO.",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1828 NORTH SAGINAW STREET LAPEER MI 48446 USA "
    },
    {
      "Location ID": "48504",
      "Location Name": "MOTT PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2712 FLUSHING RD FLINT MI 48504 USA "
    },
    {
      "Location ID": "48505",
      "Location Name": "NORTHSIDE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3311 N SAGINAW ST FLINT MI 48505 USA "
    },
    {
      "Location ID": "48507",
      "Location Name": "CODY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1601 W ATHERTON RD FLINT MI 48507 USA "
    },
    {
      "Location ID": "48509",
      "Location Name": "NORTHEAST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4465 DAVISON RD BURTON MI 48509 USA "
    },
    {
      "Location ID": "48519",
      "Location Name": "SOUTHEAST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4065 MANOR DR BURTON MI 48519 USA "
    },
    {
      "Location ID": "48532",
      "Location Name": "SOUTHWEST",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2500 S LINDEN RD FLINT MI 48532 USA "
    },
    {
      "Location ID": "486",
      "Location Name": "SAGINAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1233 S WASHINGTON AVE SAGINAW MI 48601 USA "
    },
    {
      "Location ID": "48601",
      "Location Name": "CUMBERLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1300 CUMBERLAND ST SAGINAW MI 48601 USA "
    },
    {
      "Location ID": "48603",
      "Location Name": "BOARDWALK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3193 BOARDWALK DR SAGINAW MI 48603 USA "
    },
    {
      "Location ID": "48610",
      "Location Name": "Alger",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1454 JOY ST ALGER MI 48610 USA "
    },
    {
      "Location ID": "48611",
      "Location Name": "AUBURN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 951 W MIDLAND RD AUBURN MI 48611 USA "
    },
    {
      "Location ID": "48612",
      "Location Name": "BEAVERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 362 N ROSS ST BEAVERTON MI 48612 USA "
    },
    {
      "Location ID": "48615",
      "Location Name": "BRECKENRIDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 307 E SAGINAW ST BRECKENRIDGE MI 48615 USA "
    },
    {
      "Location ID": "48616",
      "Location Name": "CHESANING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 230 E BROAD ST CHESANING MI 48616 USA "
    },
    {
      "Location ID": "48617",
      "Location Name": "CLARE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 W 5TH ST CLARE MI 48617 USA "
    },
    {
      "Location ID": "48618",
      "Location Name": "COLEMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 N 3RD ST COLEMAN MI 48618 USA "
    },
    {
      "Location ID": "48619",
      "Location Name": "COMINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4240 N ABBE RD COMINS MI 48619 USA "
    },
    {
      "Location ID": "48620",
      "Location Name": "EDENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 461 CEDAR DR EDENVILLE MI 48620 USA "
    },
    {
      "Location ID": "48621",
      "Location Name": "FAIRVIEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1531 N ABBE RD FAIRVIEW MI 48621 USA "
    },
    {
      "Location ID": "48622",
      "Location Name": "FARWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 254 W MAIN ST FARWELL MI 48622 USA "
    },
    {
      "Location ID": "48623",
      "Location Name": "FREELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7736 MIDLAD RD STE 1 FREELAND MI 48623 USA "
    },
    {
      "Location ID": "48624",
      "Location Name": "GLADWIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 140 N BOWERY AVE GLADWIN MI 48624 USA "
    },
    {
      "Location ID": "48625",
      "Location Name": "HARRISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 191 S 1ST ST HARRISON MI 48625 USA "
    },
    {
      "Location ID": "48626",
      "Location Name": "HEMLOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 S HEMLOCK RD HEMLOCK MI 48626 USA "
    },
    {
      "Location ID": "48627",
      "Location Name": "HIGGINS LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6311 W HIGGINS LAKE DR HIGGINS LAKE MI 48627 USA "
    },
    {
      "Location ID": "48628",
      "Location Name": "HOPE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5230 N HOPE RD HOPE MI 48628 USA "
    },
    {
      "Location ID": "48629",
      "Location Name": "HOUGHTON LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2255 TOWER HILL RD HOUGHTON LAKE MI 48629 USA "
    },
    {
      "Location ID": "48630",
      "Location Name": "HOUGHTON LAKE HEIGHTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1641 HEIGHTSVIEW DR HOUGHTON LAKE HEIGHTS MI 48630 USA "
    },
    {
      "Location ID": "48631",
      "Location Name": "KAWKAWLIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 W GROVE ST KAWKAWLIN MI 48631 USA "
    },
    {
      "Location ID": "48632",
      "Location Name": "LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8959 LAKE STATION AVE LAKE MI 48632 USA "
    },
    {
      "Location ID": "48633",
      "Location Name": "LAKE GEORGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 190 W PARK ST LAKE GEORGE MI 48633 USA "
    },
    {
      "Location ID": "48634",
      "Location Name": "LINWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 W CENTER ST LINWOOD MI 48634 USA "
    },
    {
      "Location ID": "48636",
      "Location Name": "LUZERNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2289 ROYCE AVE LUZERNE MI 48636 USA "
    },
    {
      "Location ID": "48637",
      "Location Name": "MERRILL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 242 S MIDLAND ST MERRILL MI 48637 USA "
    },
    {
      "Location ID": "48640",
      "Location Name": "MIDLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2900 RODD ST MIDLAND MI 48640 USA "
    },
    {
      "Location ID": "48647",
      "Location Name": "MIO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 213 S MORENCI AVE MIO MI 48647 USA "
    },
    {
      "Location ID": "48649",
      "Location Name": "OAKLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 S MAIN ST OAKLEY MI 48649 USA "
    },
    {
      "Location ID": "48650",
      "Location Name": "PINCONNING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 207 S KAISER ST PINCONNING MI 48650 USA "
    },
    {
      "Location ID": "48651",
      "Location Name": "PRUDENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 899 W HOUGHTON LAKE DR PRUDENVILLE MI 48651 USA "
    },
    {
      "Location ID": "48652",
      "Location Name": "RHODES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2832 RHODES RD RHODES MI 48652 USA "
    },
    {
      "Location ID": "48653",
      "Location Name": "ROSCOMMON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 281 LAKE ST ROSCOMMON MI 48653 USA "
    },
    {
      "Location ID": "48654",
      "Location Name": "ROSE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 130 CLYDE ST ROSE CITY MI 48654 USA "
    },
    {
      "Location ID": "48655",
      "Location Name": "SAINT CHARLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 S SAGINAW ST SAINT CHARLES MI 48655 USA "
    },
    {
      "Location ID": "48656",
      "Location Name": "SAINT HELEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2332 N SAINT HELEN RD SAINT HELEN MI 48656 USA "
    },
    {
      "Location ID": "48657",
      "Location Name": "SANFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 239 W SAGINAW RD SANFORD MI 48657 USA "
    },
    {
      "Location ID": "48658",
      "Location Name": "STANDISH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 S GROVE ST STANDISH MI 48658 USA "
    },
    {
      "Location ID": "48659",
      "Location Name": "STERLING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 420 W STATE ST STERLING MI 48659 USA "
    },
    {
      "Location ID": "48661",
      "Location Name": "WEST BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 122 S 3RD ST WEST BRANCH MI 48661 USA "
    },
    {
      "Location ID": "486FC",
      "Location Name": "FORWARD CORPORATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 219 N FRONT ST STANDISH MI 48658 USA "
    },
    {
      "Location ID": "486GR",
      "Location Name": "FRANKS GREAT OUTDOORS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1212 N HURON LINWOOD MI 48634 USA "
    },
    {
      "Location ID": "486MC",
      "Location Name": "MCKAY PRESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7600 W WACKERLY ST MIDLAND MI 48642 USA "
    },
    {
      "Location ID": "486MG",
      "Location Name": "MAGLINER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1205 W CEDAR ST STANDISH MI 48658 USA "
    },
    {
      "Location ID": "486QP",
      "Location Name": "QUAD GRAPHICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1700 JAMES SAVAGE RD MIDLAND MI 48642 USA "
    },
    {
      "Location ID": "486TL",
      "Location Name": "20 LAKES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4950 W M-61 GLADWIN MI 48624-9492 USA "
    },
    {
      "Location ID": "486WC",
      "Location Name": "WILLIAMS CHEESE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 998 N HURON LINWOOD MI 48634 USA "
    },
    {
      "Location ID": "486WE",
      "Location Name": "TOWNSHIP BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2130 WIENEKE RD SAGINAW MI 48603 USA "
    },
    {
      "Location ID": "48701",
      "Location Name": "AKRON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4282 BEACH ST AKRON MI 48701 USA "
    },
    {
      "Location ID": "48703",
      "Location Name": "AU GRES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 W HURON RD AU GRES MI 48703 USA "
    },
    {
      "Location ID": "48708",
      "Location Name": "BAY CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 WASHINGTON AVE BAY CITY MI 48708 USA "
    },
    {
      "Location ID": "48710",
      "Location Name": "UNIVERSITY CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7400 BAY RD SAGINAW MI 48604 USA "
    },
    {
      "Location ID": "48720",
      "Location Name": "BAY PORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 805 PROMENADE ST BAY PORT MI 48720 USA "
    },
    {
      "Location ID": "48722",
      "Location Name": "BRIDGEPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3760 STATE ST BRIDGEPORT MI 48722 USA "
    },
    {
      "Location ID": "48723",
      "Location Name": "CARO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 W LINCOLN ST CARO MI 48723 USA "
    },
    {
      "Location ID": "48725",
      "Location Name": "CASEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7060 MAIN ST CASEVILLE MI 48725 USA "
    },
    {
      "Location ID": "48726",
      "Location Name": "CASS CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4544 SHERMAN ST CASS CITY MI 48726 USA "
    },
    {
      "Location ID": "48727",
      "Location Name": "CLIFFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9581 MAIN ST CLIFFORD MI 48727 USA "
    },
    {
      "Location ID": "48729",
      "Location Name": "DEFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1522 RAILROAD ST DEFORD MI 48729 USA "
    },
    {
      "Location ID": "48730",
      "Location Name": "EAST TAWAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 326 NEWMAN ST EAST TAWAS MI 48730 USA "
    },
    {
      "Location ID": "48731",
      "Location Name": "ELKTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4958 MILL ST ELKTON MI 48731 USA "
    },
    {
      "Location ID": "48733",
      "Location Name": "FAIRGROVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2045 MAIN ST FAIRGROVE MI 48733 USA "
    },
    {
      "Location ID": "48734",
      "Location Name": "FRANKENMUTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 N MAIN ST FRANKENMUTH MI 48734 USA "
    },
    {
      "Location ID": "48735",
      "Location Name": "GAGETOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6483 LINCOLN ST GAGETOWN MI 48735 USA "
    },
    {
      "Location ID": "48737",
      "Location Name": "GLENNIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3160 STATE RD GLENNIE MI 48737 USA "
    },
    {
      "Location ID": "48738",
      "Location Name": "GREENBUSH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2398 S US HIGHWAY 23 GREENBUSH MI 48738 USA "
    },
    {
      "Location ID": "48739",
      "Location Name": "HALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 121 E MAIN ST HALE MI 48739 USA "
    },
    {
      "Location ID": "48740",
      "Location Name": "HARRISVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 109 1ST ST HARRISVILLE MI 48740 USA "
    },
    {
      "Location ID": "48741",
      "Location Name": "KINGSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3484 RIVER ST KINGSTON MI 48741 USA "
    },
    {
      "Location ID": "48742",
      "Location Name": "LINCOLN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 S 2ND ST LINCOLN MI 48742 USA "
    },
    {
      "Location ID": "48744",
      "Location Name": "MAYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6120 FULTON ST MAYVILLE MI 48744 USA "
    },
    {
      "Location ID": "48745",
      "Location Name": "MIKADO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2256 S F 41 MIKADO MI 48745 USA "
    },
    {
      "Location ID": "48746",
      "Location Name": "MILLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4717 MAIN ST MILLINGTON MI 48746 USA "
    },
    {
      "Location ID": "48747",
      "Location Name": "MUNGER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11 E MUNGER RD MUNGER MI 48747 USA "
    },
    {
      "Location ID": "48748",
      "Location Name": "NATIONAL CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4770 WHITTEMORE RD NATIONAL CITY MI 48748 USA "
    },
    {
      "Location ID": "48749",
      "Location Name": "OMER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 BRIDGE ST OMER MI 48749 USA "
    },
    {
      "Location ID": "48750",
      "Location Name": "OSCODA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 221 N STATE ST OSCODA MI 48750 USA "
    },
    {
      "Location ID": "48754",
      "Location Name": "OWENDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7314 MAIN ST OWENDALE MI 48754 USA "
    },
    {
      "Location ID": "48755",
      "Location Name": "PIGEON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7181 E MICHIGAN AVE PIGEON MI 48755 USA "
    },
    {
      "Location ID": "48756",
      "Location Name": "PRESCOTT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 105 N WASHINGTON ST PRESCOTT MI 48756 USA "
    },
    {
      "Location ID": "48757",
      "Location Name": "REESE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2077 GATES ST REESE MI 48757 USA "
    },
    {
      "Location ID": "48758",
      "Location Name": "RICHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9400 W SAGINAW RD RICHVILLE MI 48758 USA "
    },
    {
      "Location ID": "48759",
      "Location Name": "SEBEWAING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 49 N CENTER ST SEBEWAING MI 48759 USA "
    },
    {
      "Location ID": "48760",
      "Location Name": "SILVERWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2760 CLIFFORD RD SILVERWOOD MI 48760 USA "
    },
    {
      "Location ID": "48761",
      "Location Name": "SOUTH BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6495 HEATH RD SOUTH BRANCH MI 48761 USA "
    },
    {
      "Location ID": "48762",
      "Location Name": "SPRUCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1495 E SPRUCE RD SPRUCE MI 48762 USA "
    },
    {
      "Location ID": "48763",
      "Location Name": "TAWAS CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 616 W LAKE ST TAWAS CITY MI 48763 USA "
    },
    {
      "Location ID": "48767",
      "Location Name": "UNIONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6599 CENTER ST UNIONVILLE MI 48767 USA "
    },
    {
      "Location ID": "48768",
      "Location Name": "VASSAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 203 N MAIN ST VASSAR MI 48768 USA "
    },
    {
      "Location ID": "48770",
      "Location Name": "WHITTEMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 S BULLOCK ST WHITTEMORE MI 48770 USA "
    },
    {
      "Location ID": "487AT",
      "Location Name": "AIRCRAFT TOOLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 AUSABLE RD OSCODA MI 48750 USA "
    },
    {
      "Location ID": "487BR",
      "Location Name": "BRONNER'S CHRISTMAS WONDERLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 CHRISTMAS LN FRANKENMUTH MI 48734 USA "
    },
    {
      "Location ID": "487EN",
      "Location Name": "ENGINEERED INSANITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4162 DOERR RD CASS CITY MI 48726 USA "
    },
    {
      "Location ID": "487PC",
      "Location Name": "PCS OUTDOORS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5911 MISSION ST OSCODA MI 48750 USA "
    },
    {
      "Location ID": "488",
      "Location Name": "LANSING P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4800 COLLINS RD LANSING MI 48924 USA "
    },
    {
      "Location ID": "48801",
      "Location Name": "ALMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 233 N STATE ST ALMA MI 48801 USA "
    },
    {
      "Location ID": "48806",
      "Location Name": "ASHLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 S STERLING ST ASHLEY MI 48806 USA "
    },
    {
      "Location ID": "48807",
      "Location Name": "BANNISTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 395 E MAIN ST BANNISTER MI 48807 USA "
    },
    {
      "Location ID": "48808",
      "Location Name": "BATH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14137 WEBSTER RD BATH MI 48808 USA "
    },
    {
      "Location ID": "48809",
      "Location Name": "BELDING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 E MAIN ST BELDING MI 48809 USA "
    },
    {
      "Location ID": "48811",
      "Location Name": "CARSON CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 215 W MAIN ST CARSON CITY MI 48811 USA "
    },
    {
      "Location ID": "48812",
      "Location Name": "CEDAR LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7772 ACADEMY RD CEDAR LAKE MI 48812 USA "
    },
    {
      "Location ID": "48813",
      "Location Name": "CHARLOTTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 W LOVETT ST CHARLOTTE MI 48813 USA "
    },
    {
      "Location ID": "48815",
      "Location Name": "CLARKSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 N MAIN ST CLARKSVILLE MI 48815 USA "
    },
    {
      "Location ID": "48818",
      "Location Name": "CRYSTAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 204 S MAIN ST CRYSTAL MI 48818 USA "
    },
    {
      "Location ID": "48819",
      "Location Name": "DANSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1036 S JACKSON ST DANSVILLE MI 48819 USA "
    },
    {
      "Location ID": "48820",
      "Location Name": "DEWITT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 901 S BRIDGE ST DEWITT MI 48820 USA "
    },
    {
      "Location ID": "48821",
      "Location Name": "DIMONDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 126 W JEFFERSON ST DIMONDALE MI 48821 USA "
    },
    {
      "Location ID": "48822",
      "Location Name": "EAGLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13479 W GRAND RIVER HWY EAGLE MI 48822 USA "
    },
    {
      "Location ID": "48823",
      "Location Name": "EAST LANSING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1140 ABBOT RD EAST LANSING MI 48823 USA "
    },
    {
      "Location ID": "48824",
      "Location Name": "CPU MSU STUDENT UNION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 49 ABBOT RD EAST LANSING MI 48824 USA "
    },
    {
      "Location ID": "48827",
      "Location Name": "EATON RAPIDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 245 S MAIN ST EATON RAPIDS MI 48827 USA "
    },
    {
      "Location ID": "48829",
      "Location Name": "EDMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 317 E MAIN ST EDMORE MI 48829 USA "
    },
    {
      "Location ID": "48831",
      "Location Name": "ELSIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 153 E MAIN ST ELSIE MI 48831 USA "
    },
    {
      "Location ID": "48833",
      "Location Name": "EUREKA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7907 N WELLING RD EUREKA MI 48833 USA "
    },
    {
      "Location ID": "48834",
      "Location Name": "FENWICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 382 W FENWICK RD FENWICK MI 48834 USA "
    },
    {
      "Location ID": "48835",
      "Location Name": "FOWLER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 135 N MAIN ST FOWLER MI 48835 USA "
    },
    {
      "Location ID": "48836",
      "Location Name": "FOWLERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7030 W GRAND RIVER RD FOWLERVILLE MI 48836 USA "
    },
    {
      "Location ID": "48837",
      "Location Name": "GRAND LEDGE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 E JEFFERSON ST GRAND LEDGE MI 48837 USA "
    },
    {
      "Location ID": "48838",
      "Location Name": "GREENVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 W CASS ST GREENVILLE MI 48838 USA "
    },
    {
      "Location ID": "48840",
      "Location Name": "HASLETT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1518 HASLETT RD HASLETT MI 48840 USA "
    },
    {
      "Location ID": "48842",
      "Location Name": "HOLT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2190 AURELIUS RD HOLT MI 48842 USA "
    },
    {
      "Location ID": "48843",
      "Location Name": "HOWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 325 S MICHIGAN AVE HOWELL MI 48843 USA "
    },
    {
      "Location ID": "48845",
      "Location Name": "HUBBARDSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 MAIN ST E HUBBARDSTON MI 48845 USA "
    },
    {
      "Location ID": "48846",
      "Location Name": "IONIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 117 N KIDD ST IONIA MI 48846 USA "
    },
    {
      "Location ID": "48847",
      "Location Name": "ITHACA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 N PINE RIVER ST ITHACA MI 48847 USA "
    },
    {
      "Location ID": "48848",
      "Location Name": "LAINGSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 221 E GRAND RIVER RD LAINGSBURG MI 48848 USA "
    },
    {
      "Location ID": "48849",
      "Location Name": "LAKE ODESSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 907 4TH AVE LAKE ODESSA MI 48849 USA "
    },
    {
      "Location ID": "48850",
      "Location Name": "LAKEVIEW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 319 WASHINGTON ST LAKEVIEW MI 48850 USA "
    },
    {
      "Location ID": "48851",
      "Location Name": "LYONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 308 E BRIDGE ST LYONS MI 48851 USA "
    },
    {
      "Location ID": "48852",
      "Location Name": "MCBRIDES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4321 DIVISION ST MCBRIDES MI 48852 USA "
    },
    {
      "Location ID": "48853",
      "Location Name": "MAPLE RAPIDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 W MAIN ST MAPLE RAPIDS MI 48853 USA "
    },
    {
      "Location ID": "48854",
      "Location Name": "MASON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 144 W ASH ST MASON MI 48854 USA "
    },
    {
      "Location ID": "48856",
      "Location Name": "MIDDLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 S NEWTON ST MIDDLETON MI 48856 USA "
    },
    {
      "Location ID": "48857",
      "Location Name": "MORRICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 316 MAIN ST MORRICE MI 48857 USA "
    },
    {
      "Location ID": "48858",
      "Location Name": "MOUNT PLEASANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 813 N MAIN ST MOUNT PLEASANT MI 48858 USA "
    },
    {
      "Location ID": "48860",
      "Location Name": "MUIR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 230 W SUPERIOR ST MUIR MI 48860 USA "
    },
    {
      "Location ID": "48861",
      "Location Name": "MULLIKEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 129 MAIN ST MULLIKEN MI 48861 USA "
    },
    {
      "Location ID": "48862",
      "Location Name": "NORTH STAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 W GRATIOT ST NORTH STAR MI 48862 USA "
    },
    {
      "Location ID": "48864",
      "Location Name": "OKEMOS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2025 CENTRAL PARK DR OKEMOS MI 48864 USA "
    },
    {
      "Location ID": "48865",
      "Location Name": "ORLEANS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3018 W PALMER RD ORLEANS MI 48865 USA "
    },
    {
      "Location ID": "48866",
      "Location Name": "OVID",
      "Time Zone Offset (Hours)": 0,
      "Address": " 203 N MAIN ST OVID MI 48866 USA "
    },
    {
      "Location ID": "48867",
      "Location Name": "OWOSSO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 319 N WASHINGTON ST OWOSSO MI 48867 USA "
    },
    {
      "Location ID": "48870",
      "Location Name": "PALO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8406 MAIN PALO MI 48870 USA "
    },
    {
      "Location ID": "48871",
      "Location Name": "PERRINTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 128 S ROBINSON ST PERRINTON MI 48871 USA "
    },
    {
      "Location ID": "48872",
      "Location Name": "PERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 N MAIN PERRY MI 48872 USA "
    },
    {
      "Location ID": "48873",
      "Location Name": "PEWAMO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 154 E MAIN ST PEWAMO MI 48873 USA "
    },
    {
      "Location ID": "48874",
      "Location Name": "POMPEII",
      "Time Zone Offset (Hours)": 0,
      "Address": " 103 E FULTON ST POMPEII MI 48874 USA "
    },
    {
      "Location ID": "48875",
      "Location Name": "PORTLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 235 N LINCOLN ST PORTLAND MI 48875 USA "
    },
    {
      "Location ID": "48876",
      "Location Name": "POTTERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 319 N DUNBAR ST POTTERVILLE MI 48876 USA "
    },
    {
      "Location ID": "48877",
      "Location Name": "RIVERDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6728 N LUMBERJACK RD RIVERDALE MI 48877 USA "
    },
    {
      "Location ID": "48878",
      "Location Name": "ROSEBUSH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3905 E ROSEBUSH RD ROSEBUSH MI 48878 USA "
    },
    {
      "Location ID": "48879",
      "Location Name": "SAINT JOHNS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 W STATE ST SAINT JOHNS MI 48879 USA "
    },
    {
      "Location ID": "48880",
      "Location Name": "SAINT LOUIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 217 W CENTER ST SAINT LOUIS MI 48880 USA "
    },
    {
      "Location ID": "48881",
      "Location Name": "SARANAC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 29 N BRIDGE ST SARANAC MI 48881 USA "
    },
    {
      "Location ID": "48882",
      "Location Name": "SHAFTSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7090 W BEARD RD SHAFTSBURG MI 48882 USA "
    },
    {
      "Location ID": "48883",
      "Location Name": "SHEPHERD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 101 N 2ND ST SHEPHERD MI 48883 USA "
    },
    {
      "Location ID": "48884",
      "Location Name": "SHERIDAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 207 S MAIN ST SHERIDAN MI 48884 USA "
    },
    {
      "Location ID": "48885",
      "Location Name": "SIDNEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2960 W SIDNEY RD SIDNEY MI 48885 USA "
    },
    {
      "Location ID": "48886",
      "Location Name": "SIX LAKES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 126 N CLARK ST SIX LAKES MI 48886 USA "
    },
    {
      "Location ID": "48888",
      "Location Name": "STANTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 363 N STATE ST STANTON MI 48888 USA "
    },
    {
      "Location ID": "48889",
      "Location Name": "SUMNER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10674 W SAINT CHARLES RD SUMNER MI 48889 USA "
    },
    {
      "Location ID": "48890",
      "Location Name": "SUNFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 178 MAIN ST SUNFIELD MI 48890 USA "
    },
    {
      "Location ID": "48891",
      "Location Name": "VESTABURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8845 3RD ST VESTABURG MI 48891 USA "
    },
    {
      "Location ID": "48892",
      "Location Name": "WEBBERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 216 W GRAND RIVER RD WEBBERVILLE MI 48892 USA "
    },
    {
      "Location ID": "48893",
      "Location Name": "WEIDMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3366 N WOODRUFF RD WEIDMAN MI 48893 USA "
    },
    {
      "Location ID": "48894",
      "Location Name": "WESTPHALIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 W MAIN ST WESTPHALIA MI 48894 USA "
    },
    {
      "Location ID": "48895",
      "Location Name": "WILLIAMSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1295 E GRAND RIVER RD WILLIAMSTON MI 48895 USA "
    },
    {
      "Location ID": "48896",
      "Location Name": "WINN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2855 W BLANCHARD RD WINN MI 48896 USA "
    },
    {
      "Location ID": "48897",
      "Location Name": "WOODLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 134 N MAIN ST WOODLAND MI 48897 USA "
    },
    {
      "Location ID": "488CA",
      "Location Name": "CATI ARMOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 435 PACKARD HWY STE C CHARLOTTE MI 48813 USA "
    },
    {
      "Location ID": "488CR",
      "Location Name": "CARTRIDGES R US",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 RAYCRAFT DR ITHACA MI 48847 USA "
    },
    {
      "Location ID": "488CS",
      "Location Name": "CSH, Inc",
      "Time Zone Offset (Hours)": 0,
      "Address": " 503 S SHIAWASSEE ST CORUNNA MI 48817 USA "
    },
    {
      "Location ID": "488DG",
      "Location Name": "D&G EQUIPMENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 710 NORTH CEDAR ST, STE B MASON MI 48854 USA "
    },
    {
      "Location ID": "488DP",
      "Location Name": "DATA PAK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 AUSTIN CT HOWELL MI 48843 USA "
    },
    {
      "Location ID": "488FL",
      "Location Name": "FLAT RIVER GROUP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 306 REED ST BELDING MI 48809 USA "
    },
    {
      "Location ID": "488JF",
      "Location Name": "JOSH'S FROGS LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 222 S ELM ST OWOSSO MI 48867 USA "
    },
    {
      "Location ID": "488KE",
      "Location Name": "KEYSTONE AUTO PARTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5835 VINING RD GREENVILLE MI 48838 USA "
    },
    {
      "Location ID": "488PA",
      "Location Name": "PALO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8406 JUDEVINE RD FENWICK MI 48834 USA "
    },
    {
      "Location ID": "488PS",
      "Location Name": "LANSING POSTAL STORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5208 WEST SAGINAW HIGHWAY LANSING MI 48917 USA "
    },
    {
      "Location ID": "488QG",
      "Location Name": "QUAD GRAPHICS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1321 E VAN DEINSE ST GREENVILLE MI 48838 USA "
    },
    {
      "Location ID": "488RA",
      "Location Name": "RELIABLE AFTERMARKET PARTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3615 E GRAND RIVER AVE WILLIAMSTON MI 48895 USA "
    },
    {
      "Location ID": "488TD",
      "Location Name": "TOP DUCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 203 E TOLLES DR SAINT JOHNS MI 48879 USA "
    },
    {
      "Location ID": "488TH",
      "Location Name": "FULL THROTTLE MOTORSPORTS INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9555 WOODLANE DR DIMONDALE MI 48821 USA "
    },
    {
      "Location ID": "48910",
      "Location Name": "SOUTHWEST CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3285 EXPRESS CT LANSING MI 48910 USA "
    },
    {
      "Location ID": "48915",
      "Location Name": "DELTA BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5208 W SAGINAW HWY LANSING MI 48917 USA "
    },
    {
      "Location ID": "48917",
      "Location Name": "DELTA CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6101 W MICHIGAN AVE LANSING MI 48917 USA "
    },
    {
      "Location ID": "48924",
      "Location Name": "LANSING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4800 COLLINS RD LANSING MI 48924 USA "
    },
    {
      "Location ID": "48933",
      "Location Name": "DOWNTOWN LANSING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 315 W ALLEGAN ST STE A LANSING MI 48933 USA "
    },
    {
      "Location ID": "48C",
      "Location Name": "DNDC PEAK ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19000 RIALTO ST MELVINDALE MI 48122 USA "
    },
    {
      "Location ID": "48Z",
      "Location Name": "DETROIT NDC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17500 OAKWOOD BLVD ALLEN PARK MI 48101 USA "
    },
    {
      "Location ID": "490",
      "Location Name": "KALAMAZOO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3885 S 9TH ST KALAMAZOO MI 49009 USA "
    },
    {
      "Location ID": "49009",
      "Location Name": "WESTWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 167 N DRAKE RD KALAMAZOO MI 49009 USA "
    },
    {
      "Location ID": "49010",
      "Location Name": "ALLEGAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 TROWBRIDGE ST ALLEGAN MI 49010 USA "
    },
    {
      "Location ID": "49011",
      "Location Name": "ATHENS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 115 N CAPITAL AVE ATHENS MI 49011 USA "
    },
    {
      "Location ID": "49012",
      "Location Name": "AUGUSTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 112 S WEBSTER ST AUGUSTA MI 49012 USA "
    },
    {
      "Location ID": "49013",
      "Location Name": "BANGOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 538 RAILROAD ST BANGOR MI 49013 USA "
    },
    {
      "Location ID": "49016",
      "Location Name": "BATTLE CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90 MCCAMLY ST S BATTLE CREEK MI 49016 USA "
    },
    {
      "Location ID": "49020",
      "Location Name": "BEDFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22115 BEDFORD RD BEDFORD MI 49020 USA "
    },
    {
      "Location ID": "49021",
      "Location Name": "BELLEVUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 N MAIN ST BELLEVUE MI 49021 USA "
    },
    {
      "Location ID": "49022",
      "Location Name": "BENTON HARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 525 RIVERVIEW DR BENTON HARBOR MI 49022 USA "
    },
    {
      "Location ID": "49026",
      "Location Name": "BLOOMINGDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 CHESTNUT ST BLOOMINGDALE MI 49026 USA "
    },
    {
      "Location ID": "49027",
      "Location Name": "BREEDSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18 E MAIN ST BREEDSVILLE MI 49027 USA "
    },
    {
      "Location ID": "49028",
      "Location Name": "BRONSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 E CHICAGO ST BRONSON MI 49028 USA "
    },
    {
      "Location ID": "49029",
      "Location Name": "BURLINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 N ELM ST BURLINGTON MI 49029 USA "
    },
    {
      "Location ID": "49033",
      "Location Name": "CERESCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 MANSIE ST CERESCO MI 49033 USA "
    },
    {
      "Location ID": "49034",
      "Location Name": "CLIMAX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 116 N MAIN ST CLIMAX MI 49034 USA "
    },
    {
      "Location ID": "49036",
      "Location Name": "COLDWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 W PEARL ST COLDWATER MI 49036 USA "
    },
    {
      "Location ID": "49041",
      "Location Name": "COMSTOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6095 KING HWY COMSTOCK MI 49041 USA "
    },
    {
      "Location ID": "49042",
      "Location Name": "CONSTANTINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 340 S WASHINGTON ST CONSTANTINE MI 49042 USA "
    },
    {
      "Location ID": "49045",
      "Location Name": "DECATUR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 127 S PHELPS ST DECATUR MI 49045 USA "
    },
    {
      "Location ID": "49046",
      "Location Name": "DELTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10155 S M 43 HWY DELTON MI 49046 USA "
    },
    {
      "Location ID": "49047",
      "Location Name": "DOWAGIAC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 COMMERCIAL ST DOWAGIAC MI 49047 USA "
    },
    {
      "Location ID": "49050",
      "Location Name": "DOWLING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8599 S M 37 HWY DOWLING MI 49050 USA "
    },
    {
      "Location ID": "49051",
      "Location Name": "EAST LEROY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4005 K DR S EAST LEROY MI 49051 USA "
    },
    {
      "Location ID": "49052",
      "Location Name": "FULTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13957 E W AVE FULTON MI 49052 USA "
    },
    {
      "Location ID": "49053",
      "Location Name": "GALESBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 124 E MICHIGAN AVE GALESBURG MI 49053 USA "
    },
    {
      "Location ID": "49055",
      "Location Name": "GOBLES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 E EXCHANGE ST GOBLES MI 49055 USA "
    },
    {
      "Location ID": "49056",
      "Location Name": "GRAND JUNCTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 54014 COUNTY ROAD 388 GRAND JUNCTION MI 49056 USA "
    },
    {
      "Location ID": "49058",
      "Location Name": "HASTINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 W MILL ST HASTINGS MI 49058 USA "
    },
    {
      "Location ID": "49060",
      "Location Name": "HICKORY CORNERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14737 KELLOGG SCHOOL RD HICKORY CORNERS MI 49060 USA "
    },
    {
      "Location ID": "49062",
      "Location Name": "KENDALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 15925 MAIN ST KENDALL MI 49062 USA "
    },
    {
      "Location ID": "49063",
      "Location Name": "LACOTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60062 CHURCH ST LACOTA MI 49063 USA "
    },
    {
      "Location ID": "49065",
      "Location Name": "LAWTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 S MAIN ST LAWTON MI 49065 USA "
    },
    {
      "Location ID": "49066",
      "Location Name": "LEONIDAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 30980 KING RD LEONIDAS MI 49066 USA "
    },
    {
      "Location ID": "49067",
      "Location Name": "MARCELLUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 203 S CENTRE ST MARCELLUS MI 49067 USA "
    },
    {
      "Location ID": "49068",
      "Location Name": "MARSHALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 E MICHIGAN AVE MARSHALL MI 49068 USA "
    },
    {
      "Location ID": "49071",
      "Location Name": "MATTAWAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 55680 N MAIN ST MATTAWAN MI 49071 USA "
    },
    {
      "Location ID": "49072",
      "Location Name": "MENDON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 134 W MAIN ST MENDON MI 49072 USA "
    },
    {
      "Location ID": "49073",
      "Location Name": "NASHVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 231 N MAIN ST NASHVILLE MI 49073 USA "
    },
    {
      "Location ID": "49076",
      "Location Name": "OLIVET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 132 WASHINGTON ST OLIVET MI 49076 USA "
    },
    {
      "Location ID": "49078",
      "Location Name": "OTSEGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 N FAIR ST OTSEGO MI 49078 USA "
    },
    {
      "Location ID": "49079",
      "Location Name": "PAW PAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 125 N KALAMAZOO ST PAW PAW MI 49079 USA "
    },
    {
      "Location ID": "49080",
      "Location Name": "PLAINWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 457 E BRIDGE ST PLAINWELL MI 49080 USA "
    },
    {
      "Location ID": "49082",
      "Location Name": "QUINCY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 N MAIN ST QUINCY MI 49082 USA "
    },
    {
      "Location ID": "49083",
      "Location Name": "RICHLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8480 N 32ND ST RICHLAND MI 49083 USA "
    },
    {
      "Location ID": "49087",
      "Location Name": "SCHOOLCRAFT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 217 HAYWARD ST SCHOOLCRAFT MI 49087 USA "
    },
    {
      "Location ID": "49088",
      "Location Name": "SCOTTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11030 NORSCOTT ST SCOTTS MI 49088 USA "
    },
    {
      "Location ID": "49089",
      "Location Name": "SHERWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 133 FULTON ST SHERWOOD MI 49089 USA "
    },
    {
      "Location ID": "49090",
      "Location Name": "SOUTH HAVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 336 BROADWAY ST SOUTH HAVEN MI 49090 USA "
    },
    {
      "Location ID": "49091",
      "Location Name": "STURGIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 124 E CHICAGO RD STURGIS MI 49091 USA "
    },
    {
      "Location ID": "49092",
      "Location Name": "TEKONSHA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 119 N WALNUT ST TEKONSHA MI 49092 USA "
    },
    {
      "Location ID": "49093",
      "Location Name": "THREE RIVERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 N MAIN ST THREE RIVERS MI 49093 USA "
    },
    {
      "Location ID": "49094",
      "Location Name": "UNION CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 ELLEN ST UNION CITY MI 49094 USA "
    },
    {
      "Location ID": "49097",
      "Location Name": "VICKSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 N KALAMAZOO ST VICKSBURG MI 49097 USA "
    },
    {
      "Location ID": "49099",
      "Location Name": "WHITE PIGEON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 N KALAMAZOO ST WHITE PIGEON MI 49099 USA "
    },
    {
      "Location ID": "490IP",
      "Location Name": "WALSWORTH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2180 MAIDEN LN SAINT JOSEPH MI 49085 USA "
    },
    {
      "Location ID": "490PP",
      "Location Name": "PLANT POWERSPORTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 647 E CHICAGO RD COLDWATER MI 49036 USA "
    },
    {
      "Location ID": "490RP",
      "Location Name": "REMNANT PUBLICATIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 649 E CHICAGO RD COLDWATER MI 49036 USA "
    },
    {
      "Location ID": "490US",
      "Location Name": "US SALON SUPPLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 708 S KALAMAZOO ST PAW PAW MI 49079 USA "
    },
    {
      "Location ID": "491",
      "Location Name": "KALAMAZOO           MI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3885 S 9TH STREET KALAMAZOO MI 49009 USA "
    },
    {
      "Location ID": "49120",
      "Location Name": "NILES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 741 BROADWAY ST NILES MI 49120 USA "
    },
    {
      "Location ID": "492",
      "Location Name": "JACKSON MI P.O.",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1500 N ELM AVENUE JACKSON MI 49202 USA "
    },
    {
      "Location ID": "49221",
      "Location Name": "ADRIAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 413 E MAUMEE ST ADRIAN MI 49221 USA "
    },
    {
      "Location ID": "49224",
      "Location Name": "ALBION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 307 N SUPERIOR ST ALBION MI 49224 USA "
    },
    {
      "Location ID": "49241",
      "Location Name": "HANOVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 175 W MAIN ST HANOVER MI 49241 USA "
    },
    {
      "Location ID": "49242",
      "Location Name": "HILLSDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 99 N BROAD ST HILLSDALE MI 49242 USA "
    },
    {
      "Location ID": "49245",
      "Location Name": "HOMER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 W MAIN ST HOMER MI 49245 USA "
    },
    {
      "Location ID": "49246",
      "Location Name": "HORTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 MAIN ST HORTON MI 49246 USA "
    },
    {
      "Location ID": "49247",
      "Location Name": "HUDSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 401 W MAIN ST HUDSON MI 49247 USA "
    },
    {
      "Location ID": "49249",
      "Location Name": "JEROME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8650 N JEROME RD JEROME MI 49249 USA "
    },
    {
      "Location ID": "49250",
      "Location Name": "JONESVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 MAUMEE ST JONESVILLE MI 49250 USA "
    },
    {
      "Location ID": "49251",
      "Location Name": "LESLIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 170 S MAIN ST LESLIE MI 49251 USA "
    },
    {
      "Location ID": "49252",
      "Location Name": "LITCHFIELD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 S CHICAGO ST LITCHFIELD MI 49252 USA "
    },
    {
      "Location ID": "49257",
      "Location Name": "MOSCOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1761 E CHICAGO RD MOSCOW MI 49257 USA "
    },
    {
      "Location ID": "49262",
      "Location Name": "NORTH ADAMS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 114 W MAIN ST NORTH ADAMS MI 49262 USA "
    },
    {
      "Location ID": "49264",
      "Location Name": "ONONDAGA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5555 OAK ST ONONDAGA MI 49264 USA "
    },
    {
      "Location ID": "49269",
      "Location Name": "PARMA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 E MAIN ST PARMA MI 49269 USA "
    },
    {
      "Location ID": "49277",
      "Location Name": "RIVES JUNCTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3955 RIVES EATON RD RIVES JUNCTION MI 49277 USA "
    },
    {
      "Location ID": "49281",
      "Location Name": "SOMERSET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14268 E CHICAGO RD SOMERSET MI 49281 USA "
    },
    {
      "Location ID": "49282",
      "Location Name": "SOMERSET CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12631 E CHICAGO RD SOMERSET CENTER MI 49282 USA "
    },
    {
      "Location ID": "49284",
      "Location Name": "SPRINGPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 MECHANIC ST SPRINGPORT MI 49284 USA "
    },
    {
      "Location ID": "49288",
      "Location Name": "WALDRON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 S MAIN ST WALDRON MI 49288 USA "
    },
    {
      "Location ID": "492PM",
      "Location Name": "PRECISION METAL ART",
      "Time Zone Offset (Hours)": 0,
      "Address": " 245 W MECHANIC ST HILLSDALE MI 49242 USA "
    },
    {
      "Location ID": "493",
      "Location Name": "GRAND RAPIDS P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 225 MICHIGAN ST NW GRAND RAPIDS MI 49501 USA "
    },
    {
      "Location ID": "49301",
      "Location Name": "ADA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7125 HEADLEY ST SE ADA MI 49301 USA "
    },
    {
      "Location ID": "49302",
      "Location Name": "ALTO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11525 KIRBY ST SE ALTO MI 49302 USA "
    },
    {
      "Location ID": "49303",
      "Location Name": "BAILEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 17586 BAILEY RD BAILEY MI 49303 USA "
    },
    {
      "Location ID": "49304",
      "Location Name": "BALDWIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 513 MICHIGAN AVE BALDWIN MI 49304 USA "
    },
    {
      "Location ID": "49305",
      "Location Name": "BARRYTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 151 E NORTHERN AVE BARRYTON MI 49305 USA "
    },
    {
      "Location ID": "49306",
      "Location Name": "BELMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6220 BELMONT AVE NE BELMONT MI 49306 USA "
    },
    {
      "Location ID": "49307",
      "Location Name": "BIG RAPIDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 MAPLE ST BIG RAPIDS MI 49307 USA "
    },
    {
      "Location ID": "49308",
      "Location Name": "DIVISION STREET STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 826 DIVISION ST BIG RAPIDS MI 49307 USA "
    },
    {
      "Location ID": "49309",
      "Location Name": "BITELY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10647 N BINGHAM AVE BITELY MI 49309 USA "
    },
    {
      "Location ID": "49310",
      "Location Name": "BLANCHARD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 420 MAIN ST BLANCHARD MI 49310 USA "
    },
    {
      "Location ID": "49311",
      "Location Name": "BRADLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2910 10TH ST BRADLEY MI 49311 USA "
    },
    {
      "Location ID": "49312",
      "Location Name": "BROHMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 577 W LINCOLN ST BROHMAN MI 49312 USA "
    },
    {
      "Location ID": "49314",
      "Location Name": "BURNIPS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4273 30TH ST BURNIPS MI 49314 USA "
    },
    {
      "Location ID": "49315",
      "Location Name": "BYRON CENTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8350 BYRON CENTER AVE SW BYRON CENTER MI 49315 USA "
    },
    {
      "Location ID": "49316",
      "Location Name": "CALEDONIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9339 CHERRY VALLEY AVE SE CALEDONIA MI 49316 USA "
    },
    {
      "Location ID": "49319",
      "Location Name": "CEDAR SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 36 E CHERRY ST CEDAR SPRINGS MI 49319 USA "
    },
    {
      "Location ID": "49320",
      "Location Name": "CHIPPEWA LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10510 DWIGHT ST CHIPPEWA LAKE MI 49320 USA "
    },
    {
      "Location ID": "49321",
      "Location Name": "COMSTOCK PARK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3913 LELAND AVE NW COMSTOCK PARK MI 49321 USA "
    },
    {
      "Location ID": "49323",
      "Location Name": "DORR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1841 142ND AVE DORR MI 49323 USA "
    },
    {
      "Location ID": "49325",
      "Location Name": "FREEPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 162 DIVISION ST FREEPORT MI 49325 USA "
    },
    {
      "Location ID": "49326",
      "Location Name": "GOWEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2922 OAK ST GOWEN MI 49326 USA "
    },
    {
      "Location ID": "49327",
      "Location Name": "GRANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 79 W PINE ST GRANT MI 49327 USA "
    },
    {
      "Location ID": "49329",
      "Location Name": "HOWARD CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 E EDGERTON ST HOWARD CITY MI 49329 USA "
    },
    {
      "Location ID": "49330",
      "Location Name": "KENT CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 60 S MAIN ST KENT CITY MI 49330 USA "
    },
    {
      "Location ID": "49331",
      "Location Name": "LOWELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 N BROADWAY ST LOWELL MI 49331 USA "
    },
    {
      "Location ID": "49332",
      "Location Name": "MECOSTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 W MAIN ST MECOSTA MI 49332 USA "
    },
    {
      "Location ID": "49333",
      "Location Name": "MIDDLEVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 S BROADWAY ST MIDDLEVILLE MI 49333 USA "
    },
    {
      "Location ID": "49335",
      "Location Name": "MOLINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1229 144TH AVE MOLINE MI 49335 USA "
    },
    {
      "Location ID": "49336",
      "Location Name": "MORLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 123 S CASS ST MORLEY MI 49336 USA "
    },
    {
      "Location ID": "49337",
      "Location Name": "NEWAYGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 136 W WOOD ST NEWAYGO MI 49337 USA "
    },
    {
      "Location ID": "49339",
      "Location Name": "PIERSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7 S 4TH ST PIERSON MI 49339 USA "
    },
    {
      "Location ID": "49340",
      "Location Name": "REMUS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 302 W WHEATLAND AVE REMUS MI 49340 USA "
    },
    {
      "Location ID": "49341",
      "Location Name": "ROCKFORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 57 COURTLAND ST ROCKFORD MI 49341 USA "
    },
    {
      "Location ID": "49343",
      "Location Name": "SAND LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 E MAPLE ST SAND LAKE MI 49343 USA "
    },
    {
      "Location ID": "49344",
      "Location Name": "SHELBYVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 90 124TH AVE SHELBYVILLE MI 49344 USA "
    },
    {
      "Location ID": "49345",
      "Location Name": "SPARTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 34 E DIVISION ST SPARTA MI 49345 USA "
    },
    {
      "Location ID": "49346",
      "Location Name": "STANWOOD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7551 NORTHLAND DR STANWOOD MI 49346 USA "
    },
    {
      "Location ID": "49347",
      "Location Name": "TRUFANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 W 2ND ST TRUFANT MI 49347 USA "
    },
    {
      "Location ID": "49348",
      "Location Name": "WAYLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 325 RENO DR WAYLAND MI 49348 USA "
    },
    {
      "Location ID": "49349",
      "Location Name": "WHITE CLOUD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1097 E WILCOX AVE WHITE CLOUD MI 49349 USA "
    },
    {
      "Location ID": "493AD",
      "Location Name": "ALTICOR MAIL CENTER  MI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7575 E. FULTON ADA MI 49355 USA "
    },
    {
      "Location ID": "493AU",
      "Location Name": "ROCKFORD CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 241 ROCKFORD PARK DR NE ROCKFORD MI 49341 USA "
    },
    {
      "Location ID": "493AW",
      "Location Name": "AMWAY CORP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7575 FULTON ST E ADA MI 49355 USA "
    },
    {
      "Location ID": "493BP",
      "Location Name": "BAKER PUBLISHING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6030 FULTON ST E ADA MI 49301 USA "
    },
    {
      "Location ID": "493BT",
      "Location Name": "BEANILLA TRADING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 550 WOLVERINE ST STOP 1 ROCKFORD MI 49341 USA "
    },
    {
      "Location ID": "493FZ",
      "Location Name": "LOG GRAND RAPIDS MI PSA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4300 36TH ST KENTWOOD MI 49512 USA "
    },
    {
      "Location ID": "493GR",
      "Location Name": "GAME ROOM GUYS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4741 WEST RIVER DR NE COMSTOCK PARK MI 49321 USA "
    },
    {
      "Location ID": "493PA",
      "Location Name": "PREMIER AUTO AND TRUCK PARTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 17 MILE RD NE CEDAR SPRINGS MI 49319 USA "
    },
    {
      "Location ID": "493PL",
      "Location Name": "WOLVERINE WORLD WIDE (HOWARD)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 214 WASHBURN ST HOWARD CITY MI 49329 USA "
    },
    {
      "Location ID": "493SD",
      "Location Name": "STYLE DOTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 555 NORTHLAND DR NE C ROCKFORD MI 49341 USA "
    },
    {
      "Location ID": "493TS",
      "Location Name": "THE THERAPY SHOPPEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13707 WEST ST CEDAR SPRINGS MI 49319 USA "
    },
    {
      "Location ID": "493VW",
      "Location Name": "VINAL WORLD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 450 S STATE ST SPARTA MI 49345 USA "
    },
    {
      "Location ID": "49401",
      "Location Name": "ALLENDALE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6370 LAKE MICHIGAN DR STE 800 ALLENDALE MI 49401 USA "
    },
    {
      "Location ID": "49402",
      "Location Name": "BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11952 W US HIGHWAY 10 BRANCH MI 49402 USA "
    },
    {
      "Location ID": "49404",
      "Location Name": "COOPERSVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 314 CENTER ST COOPERSVILLE MI 49404 USA "
    },
    {
      "Location ID": "49405",
      "Location Name": "CUSTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9 S MADISON AVE CUSTER MI 49405 USA "
    },
    {
      "Location ID": "49406",
      "Location Name": "DOUGLAS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 70 CENTER ST DOUGLAS MI 49406 USA "
    },
    {
      "Location ID": "49408",
      "Location Name": "FENNVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 W MAIN ST FENNVILLE MI 49408 USA "
    },
    {
      "Location ID": "49409",
      "Location Name": "FERRYSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 110 4TH ST FERRYSBURG MI 49409 USA "
    },
    {
      "Location ID": "49410",
      "Location Name": "FOUNTAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5016 N CLEVELAND ST FOUNTAIN MI 49410 USA "
    },
    {
      "Location ID": "49411",
      "Location Name": "FREE SOIL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2650 E MICHIGAN ST FREE SOIL MI 49411 USA "
    },
    {
      "Location ID": "49412",
      "Location Name": "FREMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19 S DIVISION AVE FREMONT MI 49412 USA "
    },
    {
      "Location ID": "49413",
      "Location Name": "GERBER PRODUCTS INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 445 STATE ST FREMONT MI 49413 USA "
    },
    {
      "Location ID": "49415",
      "Location Name": "FRUITPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 53 N 3RD AVE FRUITPORT MI 49415 USA "
    },
    {
      "Location ID": "49417",
      "Location Name": "GRAND HAVEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 324 WASHINGTON AVE STE 1 GRAND HAVEN MI 49417 USA "
    },
    {
      "Location ID": "49418",
      "Location Name": "GRANDVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2939 WILSON AVE SW GRANDVILLE MI 49418 USA "
    },
    {
      "Location ID": "49419",
      "Location Name": "HAMILTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4668 PINE ST HAMILTON MI 49419 USA "
    },
    {
      "Location ID": "49420",
      "Location Name": "HART",
      "Time Zone Offset (Hours)": 0,
      "Address": " 135 S STATE ST HART MI 49420 USA "
    },
    {
      "Location ID": "49421",
      "Location Name": "HESPERIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 W MICHIGAN AVE HESPERIA MI 49421 USA "
    },
    {
      "Location ID": "49423",
      "Location Name": "HOLLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 190 E 8TH ST HOLLAND MI 49423 USA "
    },
    {
      "Location ID": "49425",
      "Location Name": "HOLTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8779 HOLTON DUCK LAKE RD HOLTON MI 49425 USA "
    },
    {
      "Location ID": "49426",
      "Location Name": "HUDSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3333 CENTRAL BLVD HUDSONVILLE MI 49426 USA "
    },
    {
      "Location ID": "49427",
      "Location Name": "JAMESTOWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3209 24TH AVE JAMESTOWN MI 49427 USA "
    },
    {
      "Location ID": "49428",
      "Location Name": "JENISON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 408 CHICAGO DR JENISON MI 49428 USA "
    },
    {
      "Location ID": "49430",
      "Location Name": "LAMONT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4521 LEONARD RD LAMONT MI 49430 USA "
    },
    {
      "Location ID": "49431",
      "Location Name": "LUDINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 E LUDINGTON AVE LUDINGTON MI 49431 USA "
    },
    {
      "Location ID": "49434",
      "Location Name": "MACATAWA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2150 S SHORE DR MACATAWA MI 49434 USA "
    },
    {
      "Location ID": "49435",
      "Location Name": "MARNE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14614 STATE ST MARNE MI 49435 USA "
    },
    {
      "Location ID": "49436",
      "Location Name": "MEARS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5666 W FOX RD MEARS MI 49436 USA "
    },
    {
      "Location ID": "49437",
      "Location Name": "MONTAGUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8761 FERRY ST MONTAGUE MI 49437 USA "
    },
    {
      "Location ID": "49440",
      "Location Name": "MUSKEGON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 800 1ST ST MUSKEGON MI 49440 USA "
    },
    {
      "Location ID": "49444",
      "Location Name": "MUSKEGON HEIGHTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2517 PECK ST MUSKEGON MI 49444 USA "
    },
    {
      "Location ID": "49445",
      "Location Name": "NORTH MUSKEGON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 404 CENTER ST MUSKEGON MI 49445 USA "
    },
    {
      "Location ID": "49446",
      "Location Name": "NEW ERA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4695 1ST ST NEW ERA MI 49446 USA "
    },
    {
      "Location ID": "49448",
      "Location Name": "NUNICA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 16980 MAIN ST NUNICA MI 49448 USA "
    },
    {
      "Location ID": "49449",
      "Location Name": "PENTWATER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 91 S HANCOCK ST PENTWATER MI 49449 USA "
    },
    {
      "Location ID": "49451",
      "Location Name": "RAVENNA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12521 CROCKERY CREEK DR RAVENNA MI 49451 USA "
    },
    {
      "Location ID": "49452",
      "Location Name": "ROTHBURY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2854 W WINSTON RD ROTHBURY MI 49452 USA "
    },
    {
      "Location ID": "49453",
      "Location Name": "SAUGATUCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 434 BUTLER ST SAUGATUCK MI 49453 USA "
    },
    {
      "Location ID": "49454",
      "Location Name": "SCOTTVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 S MAIN ST SCOTTVILLE MI 49454 USA "
    },
    {
      "Location ID": "49455",
      "Location Name": "SHELBY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 210 N MICHIGAN AVE SHELBY MI 49455 USA "
    },
    {
      "Location ID": "49456",
      "Location Name": "SPRING LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 211 W EXCHANGE ST SPRING LAKE MI 49456 USA "
    },
    {
      "Location ID": "49458",
      "Location Name": "WALHALLA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6635 E US HIGHWAY 10 WALHALLA MI 49458 USA "
    },
    {
      "Location ID": "49459",
      "Location Name": "WALKERVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 132 E LAUREL ST WALKERVILLE MI 49459 USA "
    },
    {
      "Location ID": "49460",
      "Location Name": "WEST OLIVE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8600 146TH AVE WEST OLIVE MI 49460 USA "
    },
    {
      "Location ID": "49461",
      "Location Name": "WHITEHALL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 203 S MEARS AVE WHITEHALL MI 49461 USA "
    },
    {
      "Location ID": "49464",
      "Location Name": "ZEELAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 155 E MAIN AVE ZEELAND MI 49464 USA "
    },
    {
      "Location ID": "494AX",
      "Location Name": "LOG GRAND RAPIDS P3",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3550 PATTERSON AVE SE GRAND RAPIDS MI 49512 USA "
    },
    {
      "Location ID": "494AZ",
      "Location Name": "AMAZON - VUAD - HOLLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 350 E 64TH ST HOLLAND MI 49423 USA "
    },
    {
      "Location ID": "494BA",
      "Location Name": "BABBITTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3712 AIRLINE RD NORTON SHORES MI 49444 USA "
    },
    {
      "Location ID": "494GT",
      "Location Name": "GEAR TREE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1961 PINE RIDGE DR SW STE C JENISON MI 49428 USA "
    },
    {
      "Location ID": "494HL",
      "Location Name": "HOT LOGIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1705 EATON DR GRAND HAVEN MI 49417 USA "
    },
    {
      "Location ID": "494JS",
      "Location Name": "JS PRODUCT DEVELOPMENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3306 WINDQUEST DR HOLLAND MI 49424 USA "
    },
    {
      "Location ID": "494MC",
      "Location Name": "CPU MEIJER #232",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5300 HARVEY ST MUSKEGON MI 49444 USA "
    },
    {
      "Location ID": "494MG",
      "Location Name": "MAPLE HILL GOLF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5555 IVANREST AVE SW WYOMING MI 49418 USA "
    },
    {
      "Location ID": "494PM",
      "Location Name": "PERFORMANCE MOTORSPORTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11099 WATERTOWER CT HOLLAND MI 49424 USA "
    },
    {
      "Location ID": "494RP",
      "Location Name": "ROGERS PRINTING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3350 MAIN ST RAVENNA MI 49451 USA "
    },
    {
      "Location ID": "494SF",
      "Location Name": "SHEFIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3423 QUINCY ST HUDSONVILLE MI 49426 USA "
    },
    {
      "Location ID": "494ST",
      "Location Name": "SHOOTING TARGETS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2514 PORT SHELDON ST JENISON MI 49428 USA "
    },
    {
      "Location ID": "494TF",
      "Location Name": "THOROUGHBRED FULFILLMENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8191 LOGISTICS DR STE A ZEELAND MI 49464 USA "
    },
    {
      "Location ID": "494TL",
      "Location Name": "TOWN LINE HATCHERY & POULTRY FARM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4198 96TH AVE ZEELAND MI 49464 USA "
    },
    {
      "Location ID": "494TW",
      "Location Name": "TITAN WHEEL ACCESSORIES LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11140 WATERTOWER CT HOLLAND MI 49424 USA "
    },
    {
      "Location ID": "494WP",
      "Location Name": "WHITEHALL PRODUCTS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1625 WARNER ST WHITEHALL MI 49461-1827 USA "
    },
    {
      "Location ID": "494ZE",
      "Location Name": "ZEELAND CARRIER ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20 N CHURCH ST ZEELAND MI 49464 USA "
    },
    {
      "Location ID": "495",
      "Location Name": "GRAND RAPIDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 225 MICHIGAN ST NW GRAND RAPIDS MI 49501 USA "
    },
    {
      "Location ID": "49512",
      "Location Name": "EAST PARIS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3970 S GREENBROOKE DR SE GRAND RAPIDS MI 49512 USA "
    },
    {
      "Location ID": "49599",
      "Location Name": "RIVERVIEW DISTRICT OFFICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 678 FRONT STREET  NW GRAND RAPIDS MI 49504 USA "
    },
    {
      "Location ID": "495AX",
      "Location Name": "GRAND RAPIDS PROCESSING ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3500 PATTERSON AVE SE STE A GRAND RAPIDS MI 49512 USA "
    },
    {
      "Location ID": "495CN",
      "Location Name": "COLOR INC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3280 CHICAGO DR SW GRANDVILLE MI 49418 USA "
    },
    {
      "Location ID": "495OD",
      "Location Name": "OPEN DOOR ENTERPRISES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11346 1ST AVE NW GRAND RAPIDS MI 49534 USA "
    },
    {
      "Location ID": "495PG",
      "Location Name": "PARTS GIANT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4039 40TH ST SE GRAND RAPIDS MI 49512 USA "
    },
    {
      "Location ID": "496",
      "Location Name": "LOG TRAVERSE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1801 GARFIELD RD N TRAVERSE CITY MI 49696 USA "
    },
    {
      "Location ID": "49601",
      "Location Name": "CADILLAC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 118 E HARRIS ST CADILLAC MI 49601 USA "
    },
    {
      "Location ID": "49610",
      "Location Name": "ACME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3585 BUNKER HILL RD ACME MI 49610 USA "
    },
    {
      "Location ID": "49616",
      "Location Name": "BENZONIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1035 MICHIGAN AVE BENZONIA MI 49616 USA "
    },
    {
      "Location ID": "49617",
      "Location Name": "BEULAH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 246 S BENZIE BLVD BEULAH MI 49617 USA "
    },
    {
      "Location ID": "49618",
      "Location Name": "BOON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 209 S HASKINS RD BOON MI 49618 USA "
    },
    {
      "Location ID": "49620",
      "Location Name": "BUCKLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 E WEXFORD AVE BUCKLEY MI 49620 USA "
    },
    {
      "Location ID": "49621",
      "Location Name": "CEDAR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2989 E BELLINGER RD CEDAR MI 49621 USA "
    },
    {
      "Location ID": "49623",
      "Location Name": "CHASE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6958 S DEPOT ST CHASE MI 49623 USA "
    },
    {
      "Location ID": "49625",
      "Location Name": "COPEMISH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 18772 MAPLE ST COPEMISH MI 49625 USA "
    },
    {
      "Location ID": "49628",
      "Location Name": "ELBERTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 655 FRANKFORT AVE ELBERTA MI 49628 USA "
    },
    {
      "Location ID": "49630",
      "Location Name": "EMPIRE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10234 W FRONT ST EMPIRE MI 49630 USA "
    },
    {
      "Location ID": "49631",
      "Location Name": "EVART",
      "Time Zone Offset (Hours)": 0,
      "Address": " 240 N MAIN ST EVART MI 49631 USA "
    },
    {
      "Location ID": "49635",
      "Location Name": "FRANKFORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 615 MAIN ST FRANKFORT MI 49635 USA "
    },
    {
      "Location ID": "49636",
      "Location Name": "GLEN ARBOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6456 WESTERN AVE GLEN ARBOR MI 49636 USA "
    },
    {
      "Location ID": "49637",
      "Location Name": "GRAWN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1568 STATE ST GRAWN MI 49637 USA "
    },
    {
      "Location ID": "49638",
      "Location Name": "HARRIETTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 108 S DAVIS ST HARRIETTA MI 49638 USA "
    },
    {
      "Location ID": "49639",
      "Location Name": "HERSEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 102 S MAIN ST HERSEY MI 49639 USA "
    },
    {
      "Location ID": "49640",
      "Location Name": "HONOR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10982 MAIN ST HONOR MI 49640 USA "
    },
    {
      "Location ID": "49642",
      "Location Name": "IDLEWILD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 812 E ESSEX IDLEWILD MI 49642 USA "
    },
    {
      "Location ID": "49643",
      "Location Name": "INTERLOCHEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9508 9TH ST INTERLOCHEN MI 49643 USA "
    },
    {
      "Location ID": "49650",
      "Location Name": "LAKE ANN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 19614 MAPLE ST LAKE ANN MI 49650 USA "
    },
    {
      "Location ID": "49655",
      "Location Name": "LEROY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 W GILBERT ST LEROY MI 49655 USA "
    },
    {
      "Location ID": "49656",
      "Location Name": "LUTHER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 207 STATE ST LUTHER MI 49656 USA "
    },
    {
      "Location ID": "49657",
      "Location Name": "MC BAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 N ROLAND ST MC BAIN MI 49657 USA "
    },
    {
      "Location ID": "49660",
      "Location Name": "MANISTEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 35 FILER ST MANISTEE MI 49660 USA "
    },
    {
      "Location ID": "49664",
      "Location Name": "MAPLE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8621 S MAPLE CITY RD MAPLE CITY MI 49664 USA "
    },
    {
      "Location ID": "49665",
      "Location Name": "MARION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 216 E MAIN ST MARION MI 49665 USA "
    },
    {
      "Location ID": "49668",
      "Location Name": "MESICK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 340 W MESICK AVE MESICK MI 49668 USA "
    },
    {
      "Location ID": "49677",
      "Location Name": "REED CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 232 W UPTON AVE REED CITY MI 49677 USA "
    },
    {
      "Location ID": "49683",
      "Location Name": "THOMPSONVILLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14799 THOMPSON AVE THOMPSONVILLE MI 49683 USA "
    },
    {
      "Location ID": "49684",
      "Location Name": "TRAVERSE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 S UNION ST TRAVERSE CITY MI 49684 USA "
    },
    {
      "Location ID": "49686",
      "Location Name": "BARLOW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1756 BARLOW ST TRAVERSE CITY MI 49686 USA "
    },
    {
      "Location ID": "49688",
      "Location Name": "TUSTIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 E CHURCH ST TUSTIN MI 49688 USA "
    },
    {
      "Location ID": "496EF",
      "Location Name": "E FULFILLMENT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 807 AIRPORT ACCESS RD STE D TRAVERSE CITY MI 49686 USA "
    },
    {
      "Location ID": "496FX",
      "Location Name": "XPERT FULFILLMENT, MI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8160 CADILLAC HIGHWAY BENZONIA MI 49616 USA "
    },
    {
      "Location ID": "496GW",
      "Location Name": "GOODWILL INDUSTRIES OF NORTHERN MI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1561 LAITNER DR TRAVERSE CITY MI 49696 USA "
    },
    {
      "Location ID": "496SI",
      "Location Name": "TATTLER REUSABLE CANNING LIDS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9125 E US HIGHWAY 10 CHASE MI 49623 USA "
    },
    {
      "Location ID": "496TB",
      "Location Name": "TRAVERSE BAY MANUFACTURING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8980 CAIRN HWY ELK RAPIDS MI 49629 USA "
    },
    {
      "Location ID": "496TM",
      "Location Name": "TRAINING MASK LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1140 PLETT RD CADILLAC MI 49601 USA "
    },
    {
      "Location ID": "497",
      "Location Name": "GAYLORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 289 W COMMERCE BLVD GAYLORD MI 49735 USA "
    },
    {
      "Location ID": "49701",
      "Location Name": "MACKINAW CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 306 E CENTRAL AVE MACKINAW CITY MI 49701 USA "
    },
    {
      "Location ID": "49705",
      "Location Name": "AFTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 498 M 68 HWY AFTON MI 49705 USA "
    },
    {
      "Location ID": "49706",
      "Location Name": "ALANSON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6263 RIVER ST ALANSON MI 49706 USA "
    },
    {
      "Location ID": "49707",
      "Location Name": "ALPENA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 350 N 2ND AVE ALPENA MI 49707 USA "
    },
    {
      "Location ID": "49709",
      "Location Name": "ATLANTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12606 STATE ST ATLANTA MI 49709 USA "
    },
    {
      "Location ID": "49712",
      "Location Name": "BOYNE CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 WATER ST BOYNE CITY MI 49712 USA "
    },
    {
      "Location ID": "49713",
      "Location Name": "BOYNE FALLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2543 CENTER ST BOYNE FALLS MI 49713 USA "
    },
    {
      "Location ID": "49715",
      "Location Name": "BRIMLEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6981 S M 221 BRIMLEY MI 49715 USA "
    },
    {
      "Location ID": "49717",
      "Location Name": "BURT LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10501 E WEST RESORT RD BURT LAKE MI 49717 USA "
    },
    {
      "Location ID": "49718",
      "Location Name": "CARP LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6496 PARADISE TRL CARP LAKE MI 49718 USA "
    },
    {
      "Location ID": "49720",
      "Location Name": "CHARLEVOIX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6700 M 66 N CHARLEVOIX MI 49720 USA "
    },
    {
      "Location ID": "49721",
      "Location Name": "CHEBOYGAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 200 N MAIN ST CHEBOYGAN MI 49721 USA "
    },
    {
      "Location ID": "49722",
      "Location Name": "CONWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3459 N US 31 CONWAY MI 49722 USA "
    },
    {
      "Location ID": "49724",
      "Location Name": "DAFTER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3082 W 10 MILE RD DAFTER MI 49724 USA "
    },
    {
      "Location ID": "49728",
      "Location Name": "ECKERMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9925 S SHELDON RD ECKERMAN MI 49728 USA "
    },
    {
      "Location ID": "49730",
      "Location Name": "ELMIRA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8846 UNDERWOOD AVE ELMIRA MI 49730 USA "
    },
    {
      "Location ID": "49733",
      "Location Name": "FREDERIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6450 OLD 27 N FREDERIC MI 49733 USA "
    },
    {
      "Location ID": "49735",
      "Location Name": "GAYLORD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 289 W COMMERCE BLVD GAYLORD MI 49735 USA "
    },
    {
      "Location ID": "49738",
      "Location Name": "GRAYLING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 E MICHIGAN AVE GRAYLING MI 49738 USA "
    },
    {
      "Location ID": "49740",
      "Location Name": "HARBOR SPRINGS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 STATE ST HARBOR SPRINGS MI 49740 USA "
    },
    {
      "Location ID": "49743",
      "Location Name": "HAWKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8129 F-21 HWY HAWKS MI 49743 USA "
    },
    {
      "Location ID": "49744",
      "Location Name": "HERRON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10084 M 32 W HERRON MI 49744 USA "
    },
    {
      "Location ID": "49746",
      "Location Name": "HILLMAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 111 N STATE ST HILLMAN MI 49746 USA "
    },
    {
      "Location ID": "49747",
      "Location Name": "HUBBARD LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13818 HUBBARD LAKE RD HUBBARD LAKE MI 49747 USA "
    },
    {
      "Location ID": "49748",
      "Location Name": "HULBERT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10395 S MAPLE ST HULBERT MI 49748 USA "
    },
    {
      "Location ID": "49749",
      "Location Name": "INDIAN RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6095 LAKE ST INDIAN RIVER MI 49749 USA "
    },
    {
      "Location ID": "49751",
      "Location Name": "JOHANNESBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10701 M 32 E JOHANNESBURG MI 49751 USA "
    },
    {
      "Location ID": "49753",
      "Location Name": "LACHINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2292 M 65 N LACHINE MI 49753 USA "
    },
    {
      "Location ID": "49755",
      "Location Name": "LEVERING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5912 ROBINSON ST LEVERING MI 49755 USA "
    },
    {
      "Location ID": "49756",
      "Location Name": "LEWISTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4165 SALLING AVE LEWISTON MI 49756 USA "
    },
    {
      "Location ID": "49759",
      "Location Name": "MILLERSBURG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5316 MAIN ST MILLERSBURG MI 49759 USA "
    },
    {
      "Location ID": "49760",
      "Location Name": "MORAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " N4050 STATE ROUTE 123 MORAN MI 49760 USA "
    },
    {
      "Location ID": "49761",
      "Location Name": "MULLETT LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7221 MULLETT LAKE DR MULLETT LAKE MI 49761 USA "
    },
    {
      "Location ID": "49764",
      "Location Name": "ODEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8841 MAIN ST ODEN MI 49764 USA "
    },
    {
      "Location ID": "49765",
      "Location Name": "ONAWAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 20675 STATE ST ONAWAY MI 49765 USA "
    },
    {
      "Location ID": "49766",
      "Location Name": "OSSINEKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11926 US HIGHWAY 23 S OSSINEKE MI 49766 USA "
    },
    {
      "Location ID": "49768",
      "Location Name": "PARADISE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8196 N M 123 PARADISE MI 49768 USA "
    },
    {
      "Location ID": "49769",
      "Location Name": "PELLSTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 146 STIMPSON DR PELLSTON MI 49769 USA "
    },
    {
      "Location ID": "49770",
      "Location Name": "PETOSKEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 318 STATE ST PETOSKEY MI 49770 USA "
    },
    {
      "Location ID": "49777",
      "Location Name": "PRESQUE ISLE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8133 E GRAND LAKE RD PRESQUE ISLE MI 49777 USA "
    },
    {
      "Location ID": "49781",
      "Location Name": "SAINT IGNACE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 310 S STATE ST SAINT IGNACE MI 49781 USA "
    },
    {
      "Location ID": "49783",
      "Location Name": "SAULT SAINTE MARIE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 161 RIDGE ST SAULT SAINTE MARIE MI 49783 USA "
    },
    {
      "Location ID": "49788",
      "Location Name": "KINCHELOE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4800 W M 80 KINCHELOE MI 49788 USA "
    },
    {
      "Location ID": "49791",
      "Location Name": "TOPINABEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1593 N STRAITS HWY TOPINABEE MI 49791 USA "
    },
    {
      "Location ID": "49792",
      "Location Name": "TOWER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9722 M 68 HWY TOWER MI 49792 USA "
    },
    {
      "Location ID": "49793",
      "Location Name": "TROUT LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 MAPLE ST TROUT LAKE MI 49793 USA "
    },
    {
      "Location ID": "49795",
      "Location Name": "VANDERBILT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 534 E MAIN ST VANDERBILT MI 49795 USA "
    },
    {
      "Location ID": "49796",
      "Location Name": "WALLOON LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4149 STATE ST WALLOON LAKE MI 49796 USA "
    },
    {
      "Location ID": "49797",
      "Location Name": "WATERS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10568 OTSEGOLAND RD WATERS MI 49797 USA "
    },
    {
      "Location ID": "49799",
      "Location Name": "WOLVERINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 12966 S STRAITS HWY WOLVERINE MI 49799 USA "
    },
    {
      "Location ID": "497FT",
      "Location Name": "F&T FUR HARVESTERS TRADING POS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10681 BUSHEY RD ALPENA MI 49707 USA "
    },
    {
      "Location ID": "497GF",
      "Location Name": "MRS. GLEE'S GLUTEN FREE FOODS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 24291 VETERANS MEMORIAL HWY HILLMAN MI 49746 USA "
    },
    {
      "Location ID": "497SF",
      "Location Name": "SUNFROG LLC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1782 OROURKE BLVD GAYLORD MI 49735 USA "
    },
    {
      "Location ID": "497TS",
      "Location Name": "TORSCH SERVICE AND SUPPLY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2452 KING SETTLEMENT RD ALPENA MI 49707 USA "
    },
    {
      "Location ID": "498",
      "Location Name": "LOG IRON MOUNTAIN, MI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 700 W BREITUNG AVE KINGSFORD MI 49802 USA "
    },
    {
      "Location ID": "49801",
      "Location Name": "IRON MOUNTAIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 W LUDINGTON ST IRON MOUNTAIN MI 49801 USA "
    },
    {
      "Location ID": "49806",
      "Location Name": "AU TRAIN",
      "Time Zone Offset (Hours)": 0,
      "Address": " N7589 ARBUTUS ST AU TRAIN MI 49806 USA "
    },
    {
      "Location ID": "49807",
      "Location Name": "BARK RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1316 OLD HIGHWAY 2/41 BARK RIVER MI 49807 USA "
    },
    {
      "Location ID": "49812",
      "Location Name": "CARNEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 N US HIGHWAY 41 CARNEY MI 49812 USA "
    },
    {
      "Location ID": "49814",
      "Location Name": "CHAMPION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3953 US HIGHWAY 41 W CHAMPION MI 49814 USA "
    },
    {
      "Location ID": "49815",
      "Location Name": "CHANNING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 601 RAILROAD AVE CHANNING MI 49815 USA "
    },
    {
      "Location ID": "49816",
      "Location Name": "CHATHAM",
      "Time Zone Offset (Hours)": 0,
      "Address": " E3735 MUNISING ST CHATHAM MI 49816 USA "
    },
    {
      "Location ID": "49817",
      "Location Name": "COOKS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1131S COUNTY ROAD 442 COOKS MI 49817 USA "
    },
    {
      "Location ID": "49819",
      "Location Name": "ARNOLD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 42904 COUNTY ROAD 426 ARNOLD MI 49819 USA "
    },
    {
      "Location ID": "49821",
      "Location Name": "DAGGETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 W SCHOOL RD DAGGETT MI 49821 USA "
    },
    {
      "Location ID": "49822",
      "Location Name": "DEERTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " E1014 STATE HIGHWAY M28 DEERTON MI 49822 USA "
    },
    {
      "Location ID": "49825",
      "Location Name": "EBEN JUNCTION",
      "Time Zone Offset (Hours)": 0,
      "Address": " E2895 STATE HIGHWAY M94 EBEN JUNCTION MI 49825 USA "
    },
    {
      "Location ID": "49827",
      "Location Name": "ENGADINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 104 MELVILLE ST ENGADINE MI 49827 USA "
    },
    {
      "Location ID": "49829",
      "Location Name": "ESCANABA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2525 1ST AVE N ESCANABA MI 49829 USA "
    },
    {
      "Location ID": "49831",
      "Location Name": "FELCH",
      "Time Zone Offset (Hours)": -1,
      "Address": " W4243 STATE HIGHWAY M69 FELCH MI 49831 USA "
    },
    {
      "Location ID": "49833",
      "Location Name": "LITTLE LAKE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1673 STATE HIGHWAY M35 E LITTLE LAKE MI 49833 USA "
    },
    {
      "Location ID": "49837",
      "Location Name": "GLADSTONE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1003 DELTA AVE GLADSTONE MI 49837 USA "
    },
    {
      "Location ID": "49838",
      "Location Name": "GOULD CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " N6116 S GOULD CITY RD GOULD CITY MI 49838 USA "
    },
    {
      "Location ID": "49840",
      "Location Name": "GULLIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2926W US HIGHWAY 2 GULLIVER MI 49840 USA "
    },
    {
      "Location ID": "49841",
      "Location Name": "GWINN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 95 N PINE ST GWINN MI 49841 USA "
    },
    {
      "Location ID": "49845",
      "Location Name": "HARRIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " W555 US HIGHWAY 2 41 HARRIS MI 49845 USA "
    },
    {
      "Location ID": "49847",
      "Location Name": "HERMANSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " W5444 2ND ST HERMANSVILLE MI 49847 USA "
    },
    {
      "Location ID": "49848",
      "Location Name": "INGALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " N7303 US HIGHWAY 41 INGALLS MI 49848 USA "
    },
    {
      "Location ID": "49849",
      "Location Name": "ISHPEMING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 211 N 2ND ST ISHPEMING MI 49849 USA "
    },
    {
      "Location ID": "49853",
      "Location Name": "MC MILLAN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 22579 STATE HIGHWAY M28 MC MILLAN MI 49853 USA "
    },
    {
      "Location ID": "49854",
      "Location Name": "MANISTIQUE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 301 S CEDAR ST MANISTIQUE MI 49854 USA "
    },
    {
      "Location ID": "49855",
      "Location Name": "MARQUETTE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 202 W WASHINGTON ST STE 1 MARQUETTE MI 49855 USA "
    },
    {
      "Location ID": "49858",
      "Location Name": "MENOMINEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 801 11TH ST MENOMINEE MI 49858 USA "
    },
    {
      "Location ID": "49861",
      "Location Name": "MICHIGAMME",
      "Time Zone Offset (Hours)": 0,
      "Address": " 113 W MAIN ST MICHIGAMME MI 49861 USA "
    },
    {
      "Location ID": "49862",
      "Location Name": "MUNISING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 220 ELM AVE MUNISING MI 49862 USA "
    },
    {
      "Location ID": "49863",
      "Location Name": "NADEAU",
      "Time Zone Offset (Hours)": -1,
      "Address": " N13755 1ST ST NADEAU MI 49863 USA "
    },
    {
      "Location ID": "49866",
      "Location Name": "NEGAUNEE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 304 W CASE ST NEGAUNEE MI 49866 USA "
    },
    {
      "Location ID": "49868",
      "Location Name": "NEWBERRY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 216 E JOHN ST NEWBERRY MI 49868 USA "
    },
    {
      "Location ID": "49870",
      "Location Name": "NORWAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 520 MAIN ST NORWAY MI 49870 USA "
    },
    {
      "Location ID": "49871",
      "Location Name": "PALMER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 NICHOLAS AVE PALMER MI 49871 USA "
    },
    {
      "Location ID": "49872",
      "Location Name": "PERKINS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11892 STATE HIGHWAY M35 PERKINS MI 49872 USA "
    },
    {
      "Location ID": "49874",
      "Location Name": "POWERS",
      "Time Zone Offset (Hours)": -1,
      "Address": " W3804 US HIGHWAY 2/41 POWERS MI 49874 USA "
    },
    {
      "Location ID": "49876",
      "Location Name": "QUINNESEC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1214 QUINNESEC ST QUINNESEC MI 49876 USA "
    },
    {
      "Location ID": "49878",
      "Location Name": "RAPID RIVER",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10467 S MAIN ST RAPID RIVER MI 49878 USA "
    },
    {
      "Location ID": "49879",
      "Location Name": "REPUBLIC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 415 2ND ST REPUBLIC MI 49879 USA "
    },
    {
      "Location ID": "49880",
      "Location Name": "ROCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3980 W MAPLE RIDGE 37TH RD ROCK MI 49880 USA "
    },
    {
      "Location ID": "49881",
      "Location Name": "SAGOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 1ST ST SAGOLA MI 49881 USA "
    },
    {
      "Location ID": "49883",
      "Location Name": "SENEY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10790N GRAND ST SENEY MI 49883 USA "
    },
    {
      "Location ID": "49884",
      "Location Name": "SHINGLETON",
      "Time Zone Offset (Hours)": 0,
      "Address": " E12503 STATE HIGHWAY M28 SHINGLETON MI 49884 USA "
    },
    {
      "Location ID": "49885",
      "Location Name": "SKANDIA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 224 KREIGER DR SKANDIA MI 49885 USA "
    },
    {
      "Location ID": "49886",
      "Location Name": "SPALDING",
      "Time Zone Offset (Hours)": -1,
      "Address": " N16118 CEDAR ST SPALDING MI 49886 USA "
    },
    {
      "Location ID": "49887",
      "Location Name": "STEPHENSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " W520 SAMUEL ST STEPHENSON MI 49887 USA "
    },
    {
      "Location ID": "49891",
      "Location Name": "TRENARY",
      "Time Zone Offset (Hours)": 0,
      "Address": " E2993 STATE HIGHWAY M67 TRENARY MI 49891 USA "
    },
    {
      "Location ID": "49892",
      "Location Name": "VULCAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " W4994 CENTRAL AVE VULCAN MI 49892 USA "
    },
    {
      "Location ID": "49893",
      "Location Name": "WALLACE",
      "Time Zone Offset (Hours)": -1,
      "Address": " N5965 US HIGHWAY 41 WALLACE MI 49893 USA "
    },
    {
      "Location ID": "49894",
      "Location Name": "WELLS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5659 MAIN ST WELLS MI 49894 USA "
    },
    {
      "Location ID": "49895",
      "Location Name": "WETMORE",
      "Time Zone Offset (Hours)": 0,
      "Address": " E9714 STATE HIGHWAY M28 WETMORE MI 49895 USA "
    },
    {
      "Location ID": "49896",
      "Location Name": "WILSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " W2261 US HIGHWAY 2 41 WILSON MI 49896 USA "
    },
    {
      "Location ID": "498JG",
      "Location Name": "JEN'S GEMS BY PEACH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2303 9TH AVE N ESCANABA MI 49829 USA "
    },
    {
      "Location ID": "498KC",
      "Location Name": "KABASIC CREATIONS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1628 LUDINGTON ST ESCANABA MI 49829 USA "
    },
    {
      "Location ID": "498KN",
      "Location Name": "KINGSLEY NORTH INC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 910 BROWN ST NORWAY MI 49870 USA "
    },
    {
      "Location ID": "498RD",
      "Location Name": "Race Driven/ATV Cycle",
      "Time Zone Offset (Hours)": 0,
      "Address": " 501 STEPHENSON AVE ESCANABA MI 49829 USA "
    },
    {
      "Location ID": "49902",
      "Location Name": "ALPHA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 405 MAIN ST ALPHA MI 49902 USA "
    },
    {
      "Location ID": "49903",
      "Location Name": "AMASA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 MAPLE AVE AMASA MI 49903 USA "
    },
    {
      "Location ID": "49908",
      "Location Name": "BARAGA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 404 US HIGHWAY 41 N BARAGA MI 49908 USA "
    },
    {
      "Location ID": "49910",
      "Location Name": "BERGLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 107 PINE ST BERGLAND MI 49910 USA "
    },
    {
      "Location ID": "49911",
      "Location Name": "BESSEMER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 S SOPHIE ST BESSEMER MI 49911 USA "
    },
    {
      "Location ID": "49912",
      "Location Name": "BRUCE CROSSING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13944 US HIGHWAY 45 BRUCE CROSSING MI 49912 USA "
    },
    {
      "Location ID": "49913",
      "Location Name": "CALUMET",
      "Time Zone Offset (Hours)": 0,
      "Address": " 201 6TH ST CALUMET MI 49913 USA "
    },
    {
      "Location ID": "49915",
      "Location Name": "CASPIAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 E CASPIAN AVE CASPIAN MI 49915 USA "
    },
    {
      "Location ID": "49916",
      "Location Name": "CHASSELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 41951 WILSON MEMORIAL DR CHASSELL MI 49916 USA "
    },
    {
      "Location ID": "49919",
      "Location Name": "COVINGTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25387 US HIGHWAY 141 COVINGTON MI 49919 USA "
    },
    {
      "Location ID": "49920",
      "Location Name": "CRYSTAL FALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 SUPERIOR AVE CRYSTAL FALLS MI 49920 USA "
    },
    {
      "Location ID": "49922",
      "Location Name": "DOLLAR BAY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 48694 MAIN ST DOLLAR BAY MI 49922 USA "
    },
    {
      "Location ID": "49925",
      "Location Name": "EWEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 106 S CEDAR ST EWEN MI 49925 USA "
    },
    {
      "Location ID": "49927",
      "Location Name": "GAASTRA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12 2ND STREET GAASTRA MI 49927 USA "
    },
    {
      "Location ID": "49929",
      "Location Name": "GREENLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 311 PLANK RD GREENLAND MI 49929 USA "
    },
    {
      "Location ID": "49930",
      "Location Name": "HANCOCK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 221 QUINCY ST HANCOCK MI 49930 USA "
    },
    {
      "Location ID": "49931",
      "Location Name": "HOUGHTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 701 SHELDEN AVE HOUGHTON MI 49931 USA "
    },
    {
      "Location ID": "49934",
      "Location Name": "HUBBELL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 52210 DUNCAN AVE HUBBELL MI 49934 USA "
    },
    {
      "Location ID": "49935",
      "Location Name": "IRON RIVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 425 W GENESEE ST IRON RIVER MI 49935 USA "
    },
    {
      "Location ID": "49938",
      "Location Name": "IRONWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 E AYER ST IRONWOOD MI 49938 USA "
    },
    {
      "Location ID": "49943",
      "Location Name": "KENTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " HWY M28 KENTON MI 49439 USA "
    },
    {
      "Location ID": "49945",
      "Location Name": "LAKE LINDEN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 CALUMET ST LAKE LINDEN MI 49945 USA "
    },
    {
      "Location ID": "49946",
      "Location Name": "LANSE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 N MAIN ST LANSE MI 49946 USA "
    },
    {
      "Location ID": "49947",
      "Location Name": "MARENISCO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 HALL ST MARENISCO MI 49947 USA "
    },
    {
      "Location ID": "49948",
      "Location Name": "MASS CITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1315 ADVENTURE AVE MASS CITY MI 49948 USA "
    },
    {
      "Location ID": "49953",
      "Location Name": "ONTONAGON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 207 COPPER ST ONTONAGON MI 49953 USA "
    },
    {
      "Location ID": "49959",
      "Location Name": "RAMSAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 17 MILL ST RAMSAY MI 49959 USA "
    },
    {
      "Location ID": "49960",
      "Location Name": "ROCKLAND",
      "Time Zone Offset (Hours)": 0,
      "Address": " 86 NATIONAL AVE ROCKLAND MI 49960 USA "
    },
    {
      "Location ID": "49961",
      "Location Name": "SIDNAW",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13640 E STATE HIGHWAY M28 SIDNAW MI 49961 USA "
    },
    {
      "Location ID": "49964",
      "Location Name": "STAMBAUGH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 WASHINGTON AVE STAMBAUGH MI 49964 USA "
    },
    {
      "Location ID": "49967",
      "Location Name": "TROUT CREEK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 153 DIVISION ST TROUT CREEK MI 49967 USA "
    },
    {
      "Location ID": "49968",
      "Location Name": "WAKEFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 409 HANCOCK ST WAKEFIELD MI 49968 USA "
    },
    {
      "Location ID": "49969",
      "Location Name": "WATERSMEET",
      "Time Zone Offset (Hours)": -1,
      "Address": " N4708 MAIN ST WATERSMEET MI 49969 USA "
    },
    {
      "Location ID": "49970",
      "Location Name": "WATTON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 11729 STATE HIGHWAY M28 WATTON MI 49970 USA "
    },
    {
      "Location ID": "49971",
      "Location Name": "WHITE PINE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 303 MINERAL RIVER PLZ WHITE PINE MI 49971 USA "
    },
    {
      "Location ID": "499LS",
      "Location Name": "LAURIUM",
      "Time Zone Offset (Hours)": 0,
      "Address": " 75 3RD ST LAURIUM MI 49913 USA "
    },
    {
      "Location ID": "49C",
      "Location Name": "GRAND RAPIDS CMAS ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3070 SHAFFER AVE SE KENTWOOD MI 49512 USA "
    },
    {
      "Location ID": "49MTE",
      "Location Name": "MICHIGAN MTESC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 32500 VAN BORN RD STE 700 WAYNE MI 48184 USA "
    },
    {
      "Location ID": "500",
      "Location Name": "LOG DES MOINES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1165 2ND AVE DES MOINES IA 50318 USA "
    },
    {
      "Location ID": "50002",
      "Location Name": "ADAIR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 317 AUDUBON ST ADAIR IA 50002 USA "
    },
    {
      "Location ID": "50006",
      "Location Name": "ALDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1223 WATER ST ALDEN IA 50006 USA "
    },
    {
      "Location ID": "50007",
      "Location Name": "ALLEMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14172 NE 6TH ST ALLEMAN IA 50007 USA "
    },
    {
      "Location ID": "50008",
      "Location Name": "ALLERTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 N CENTRAL AVE ALLERTON IA 50008 USA "
    },
    {
      "Location ID": "50009",
      "Location Name": "ALTOONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 8TH ST SE ALTOONA IA 50009 USA "
    },
    {
      "Location ID": "50020",
      "Location Name": "ANITA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 906 MAIN ST ANITA IA 50020 USA "
    },
    {
      "Location ID": "50022",
      "Location Name": "ATLANTIC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20 E 5TH ST ATLANTIC IA 50022 USA "
    },
    {
      "Location ID": "50025",
      "Location Name": "AUDUBON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 428 TRACY ST AUDUBON IA 50025 USA "
    },
    {
      "Location ID": "50026",
      "Location Name": "BAGLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 213 MAIN ST BAGLEY IA 50026 USA "
    },
    {
      "Location ID": "50027",
      "Location Name": "BARNES CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 610 SPRUCE ST BARNES CITY IA 50027 USA "
    },
    {
      "Location ID": "50028",
      "Location Name": "BAXTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 E STATE ST BAXTER IA 50028 USA "
    },
    {
      "Location ID": "50029",
      "Location Name": "BAYARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 MAIN ST BAYARD IA 50029 USA "
    },
    {
      "Location ID": "50032",
      "Location Name": "BERWICK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5760 NE BERWICK DR BERWICK IA 50032 USA "
    },
    {
      "Location ID": "50035",
      "Location Name": "BONDURANT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 89 PAINE ST SE BONDURANT IA 50035 USA "
    },
    {
      "Location ID": "50038",
      "Location Name": "BOONEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 31080 MAIN ST BOONEVILLE IA 50038 USA "
    },
    {
      "Location ID": "50041",
      "Location Name": "BRADFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 MAIN ST BRADFORD IA 50041 USA "
    },
    {
      "Location ID": "50042",
      "Location Name": "BRAYTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 COUNTY TRUNK RD BRAYTON IA 50042 USA "
    },
    {
      "Location ID": "50046",
      "Location Name": "CAMBRIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 304 WATER ST CAMBRIDGE IA 50046 USA "
    },
    {
      "Location ID": "50048",
      "Location Name": "CASEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E GRANT ST CASEY IA 50048 USA "
    },
    {
      "Location ID": "50049",
      "Location Name": "CHARITON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 126 S GRAND ST CHARITON IA 50049 USA "
    },
    {
      "Location ID": "50054",
      "Location Name": "COLFAX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 E HOWARD ST COLFAX IA 50054 USA "
    },
    {
      "Location ID": "50055",
      "Location Name": "COLLINS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 1ST ST COLLINS IA 50055 USA "
    },
    {
      "Location ID": "50056",
      "Location Name": "COLO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 MAIN ST COLO IA 50056 USA "
    },
    {
      "Location ID": "50058",
      "Location Name": "COON RAPIDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 507 MAIN ST COON RAPIDS IA 50058 USA "
    },
    {
      "Location ID": "50060",
      "Location Name": "CORYDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 W STATE ST CORYDON IA 50060 USA "
    },
    {
      "Location ID": "50061",
      "Location Name": "CUMMING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 44TH ST CUMMING IA 50061 USA "
    },
    {
      "Location ID": "50063",
      "Location Name": "DALLAS CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 506 14TH ST DALLAS CENTER IA 50063 USA "
    },
    {
      "Location ID": "50065",
      "Location Name": "DAVIS CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 613 S MUIR ST DAVIS CITY IA 50065 USA "
    },
    {
      "Location ID": "50066",
      "Location Name": "DAWSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 E WALNUT AVE DAWSON IA 50066 USA "
    },
    {
      "Location ID": "50067",
      "Location Name": "DECATUR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 408 W NATIONAL ST DECATUR IA 50067 USA "
    },
    {
      "Location ID": "50068",
      "Location Name": "DERBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 481 FRONT ST DERBY IA 50068 USA "
    },
    {
      "Location ID": "50069",
      "Location Name": "DE SOTO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 859 GUTHRIE ST DE SOTO IA 50069 USA "
    },
    {
      "Location ID": "50070",
      "Location Name": "DEXTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 702 MARSHALL ST DEXTER IA 50070 USA "
    },
    {
      "Location ID": "50072",
      "Location Name": "EARLHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 E 1ST ST EARLHAM IA 50072 USA "
    },
    {
      "Location ID": "50073",
      "Location Name": "ELKHART",
      "Time Zone Offset (Hours)": -1,
      "Address": " 263 NW MAIN ST ELKHART IA 50073 USA "
    },
    {
      "Location ID": "50074",
      "Location Name": "ELLSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 E MAIN ST ELLSTON IA 50074 USA "
    },
    {
      "Location ID": "50076",
      "Location Name": "EXIRA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N CARTHAGE ST EXIRA IA 50076 USA "
    },
    {
      "Location ID": "500AN",
      "Location Name": "DES MOINES PEAK SEASON ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4200 NW URBANDALE DR 1 URBANDALE IA 50322 USA "
    },
    {
      "Location ID": "500ET",
      "Location Name": "ET VIDEO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 616 VELVET AVE COON RAPIDS IA 50058 USA "
    },
    {
      "Location ID": "500FZ",
      "Location Name": "LOG DES MOINES IA PSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4301 121ST ST URBANDALE IA 50323 USA "
    },
    {
      "Location ID": "50103",
      "Location Name": "GARDEN GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 E MAIN ST GARDEN GROVE IA 50103 USA "
    },
    {
      "Location ID": "50104",
      "Location Name": "GIBSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 S MARSHALL ST GIBSON IA 50104 USA "
    },
    {
      "Location ID": "50107",
      "Location Name": "GRAND JUNCTION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 403 MAIN ST E GRAND JUNCTION IA 50107 USA "
    },
    {
      "Location ID": "50108",
      "Location Name": "GRAND RIVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 246 BROADWAY ST GRAND RIVER IA 50108 USA "
    },
    {
      "Location ID": "50112",
      "Location Name": "GRINNELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 932 BROAD ST GRINNELL IA 50112 USA "
    },
    {
      "Location ID": "50115",
      "Location Name": "GUTHRIE CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 MAIN ST GUTHRIE CENTER IA 50115 USA "
    },
    {
      "Location ID": "50123",
      "Location Name": "HUMESTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 BROAD ST HUMESTON IA 50123 USA "
    },
    {
      "Location ID": "50124",
      "Location Name": "HUXLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 624 N US HIGHWAY 69 HUXLEY IA 50124 USA "
    },
    {
      "Location ID": "50126",
      "Location Name": "IOWA FALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 MAIN ST IOWA FALLS IA 50126 USA "
    },
    {
      "Location ID": "50128",
      "Location Name": "JAMAICA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 405 MAIN ST JAMAICA IA 50128 USA "
    },
    {
      "Location ID": "50129",
      "Location Name": "JEFFERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 W HARRISON ST JEFFERSON IA 50129 USA "
    },
    {
      "Location ID": "50133",
      "Location Name": "KELLERTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S DECATUR ST KELLERTON IA 50133 USA "
    },
    {
      "Location ID": "50135",
      "Location Name": "KELLOGG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 411 MAIN ST KELLOGG IA 50135 USA "
    },
    {
      "Location ID": "50136",
      "Location Name": "KESWICK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 S IRONS ST KESWICK IA 50136 USA "
    },
    {
      "Location ID": "50137",
      "Location Name": "KILLDUFF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7229 MAIN ST KILLDUFF IA 50137 USA "
    },
    {
      "Location ID": "50138",
      "Location Name": "KNOXVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 E MARION ST KNOXVILLE IA 50138 USA "
    },
    {
      "Location ID": "50139",
      "Location Name": "LACONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 S WASHINGTON AVE LACONA IA 50139 USA "
    },
    {
      "Location ID": "50140",
      "Location Name": "LAMONI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 N MAPLE ST LAMONI IA 50140 USA "
    },
    {
      "Location ID": "50144",
      "Location Name": "LEON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 NE 2ND ST LEON IA 50144 USA "
    },
    {
      "Location ID": "50145",
      "Location Name": "LIBERTY CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 21979 160TH AVE LIBERTY CENTER IA 50145 USA "
    },
    {
      "Location ID": "50146",
      "Location Name": "LINDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 133 S MAIN ST LINDEN IA 50146 USA "
    },
    {
      "Location ID": "50147",
      "Location Name": "LINEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 BROWN ST LINEVILLE IA 50147 USA "
    },
    {
      "Location ID": "50149",
      "Location Name": "LORIMOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 512 MAIN ST LORIMOR IA 50149 USA "
    },
    {
      "Location ID": "50151",
      "Location Name": "LUCAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 E FRONT ST LUCAS IA 50151 USA "
    },
    {
      "Location ID": "50153",
      "Location Name": "LYNNVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 409 EAST ST LYNNVILLE IA 50153 USA "
    },
    {
      "Location ID": "50155",
      "Location Name": "MACKSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 E NORTH ST MACKSBURG IA 50155 USA "
    },
    {
      "Location ID": "50156",
      "Location Name": "MADRID",
      "Time Zone Offset (Hours)": -1,
      "Address": " 232 S MAIN ST MADRID IA 50156 USA "
    },
    {
      "Location ID": "50157",
      "Location Name": "MALCOM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 MAIN ST MALCOM IA 50157 USA "
    },
    {
      "Location ID": "50158",
      "Location Name": "MARSHALLTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 309 E LINN ST MARSHALLTOWN IA 50158 USA "
    },
    {
      "Location ID": "50160",
      "Location Name": "MARTENSDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 IOWA AVE MARTENSDALE IA 50160 USA "
    },
    {
      "Location ID": "50161",
      "Location Name": "MAXWELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 85 MAIN ST MAXWELL IA 50161 USA "
    },
    {
      "Location ID": "50163",
      "Location Name": "MELCHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 S MAIN ST MELCHER DALLAS IA 50163 USA "
    },
    {
      "Location ID": "50164",
      "Location Name": "MENLO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 503 SHERMAN ST MENLO IA 50164 USA "
    },
    {
      "Location ID": "50166",
      "Location Name": "MILO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 MAIN ST MILO IA 50166 USA "
    },
    {
      "Location ID": "50167",
      "Location Name": "MINBURN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 514 CHESTNUT ST MINBURN IA 50167 USA "
    },
    {
      "Location ID": "50168",
      "Location Name": "MINGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 N STATION ST MINGO IA 50168 USA "
    },
    {
      "Location ID": "50169",
      "Location Name": "MITCHELLVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 CENTER AVE S MITCHELLVILLE IA 50169 USA "
    },
    {
      "Location ID": "50170",
      "Location Name": "MONROE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 W MARION ST MONROE IA 50170 USA "
    },
    {
      "Location ID": "50171",
      "Location Name": "MONTEZUMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 E LIBERTY ST MONTEZUMA IA 50171 USA "
    },
    {
      "Location ID": "50174",
      "Location Name": "MURRAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 430 MAPLE ST MURRAY IA 50174 USA "
    },
    {
      "Location ID": "501BG",
      "Location Name": "DMU BROWNELLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3006 BROWNELLS PKWY GRINNELL IA 50112 USA "
    },
    {
      "Location ID": "501VP",
      "Location Name": "MINBURN VILLAGE POST OFFICE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 416 CHESTNUT ST MINBURN IA 50167 USA "
    },
    {
      "Location ID": "50207",
      "Location Name": "NEW SHARON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 S PEARL ST NEW SHARON IA 50207 USA "
    },
    {
      "Location ID": "50208",
      "Location Name": "NEWTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 S 2ND AVE E NEWTON IA 50208 USA "
    },
    {
      "Location ID": "50210",
      "Location Name": "NEW VIRGINIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 407 WEST ST NEW VIRGINIA IA 50210 USA "
    },
    {
      "Location ID": "50211",
      "Location Name": "NORWALK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1105 HIGH RD NORWALK IA 50211 USA "
    },
    {
      "Location ID": "50213",
      "Location Name": "OSCEOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 S FILLMORE ST OSCEOLA IA 50213 USA "
    },
    {
      "Location ID": "50216",
      "Location Name": "PANORA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 E CHURCH ST PANORA IA 50216 USA "
    },
    {
      "Location ID": "50219",
      "Location Name": "PELLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 801 FRANKLIN ST PELLA IA 50219 USA "
    },
    {
      "Location ID": "50220",
      "Location Name": "PERRY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1420 2ND ST PERRY IA 50220 USA "
    },
    {
      "Location ID": "50225",
      "Location Name": "PLEASANTVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 E MONROE ST PLEASANTVILLE IA 50225 USA "
    },
    {
      "Location ID": "50226",
      "Location Name": "POLK CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 N 3RD ST POLK CITY IA 50226 USA "
    },
    {
      "Location ID": "50229",
      "Location Name": "PROLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8056 HIGHWAY 28 STE 2 PROLE IA 50229 USA "
    },
    {
      "Location ID": "50232",
      "Location Name": "REASNOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 322 NEWTON ST REASNOR IA 50232 USA "
    },
    {
      "Location ID": "50233",
      "Location Name": "REDFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1106 THOMAS ST REDFIELD IA 50233 USA "
    },
    {
      "Location ID": "50237",
      "Location Name": "RUNNELLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 BROWN ST RUNNELLS IA 50237 USA "
    },
    {
      "Location ID": "50238",
      "Location Name": "RUSSELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 S MAPLE ST RUSSELL IA 50238 USA "
    },
    {
      "Location ID": "50240",
      "Location Name": "SAINT CHARLES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 129 W MAIN ST SAINT CHARLES IA 50240 USA "
    },
    {
      "Location ID": "50241",
      "Location Name": "SAINT MARYS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 IOWA ST SAINT MARYS IA 50241 USA "
    },
    {
      "Location ID": "50243",
      "Location Name": "SHELDAHL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 COUNTY AVE SHELDAHL IA 50243 USA "
    },
    {
      "Location ID": "50244",
      "Location Name": "SLATER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 422 MAIN ST SLATER IA 50244 USA "
    },
    {
      "Location ID": "50248",
      "Location Name": "STORY CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 613 BROAD ST STORY CITY IA 50248 USA "
    },
    {
      "Location ID": "50250",
      "Location Name": "STUART",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 NE 2ND ST STUART IA 50250 USA "
    },
    {
      "Location ID": "50251",
      "Location Name": "SULLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 624 4TH ST SULLY IA 50251 USA "
    },
    {
      "Location ID": "50255",
      "Location Name": "THORNBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1009 BROADWAY ST THORNBURG IA 50255 USA "
    },
    {
      "Location ID": "50257",
      "Location Name": "TRURO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 E NORTH ST TRURO IA 50257 USA "
    },
    {
      "Location ID": "50261",
      "Location Name": "VAN METER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 409 GRANT ST VAN METER IA 50261 USA "
    },
    {
      "Location ID": "50262",
      "Location Name": "VAN WERT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S CHAMPA ST VAN WERT IA 50262 USA "
    },
    {
      "Location ID": "50264",
      "Location Name": "WELDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 HIGHWAY J12 WELDON IA 50264 USA "
    },
    {
      "Location ID": "50268",
      "Location Name": "WHAT CHEER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 S BARNES ST WHAT CHEER IA 50268 USA "
    },
    {
      "Location ID": "50272",
      "Location Name": "WILLIAMSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 W SOUTH AVE WILLIAMSON IA 50272 USA "
    },
    {
      "Location ID": "50273",
      "Location Name": "WINTERSET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 N 2ND AVE WINTERSET IA 50273 USA "
    },
    {
      "Location ID": "50274",
      "Location Name": "WIOTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 405 PIONEER AVE WIOTA IA 50274 USA "
    },
    {
      "Location ID": "50275",
      "Location Name": "WOODBURN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 610 SIGLER ST WOODBURN IA 50275 USA "
    },
    {
      "Location ID": "50277",
      "Location Name": "YALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 MAIN ST YALE IA 50277 USA "
    },
    {
      "Location ID": "504",
      "Location Name": "MASON CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 N DELAWARE AVE STE 100 MASON CITY IA 50401 USA "
    },
    {
      "Location ID": "50420",
      "Location Name": "ALEXANDER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 407 HARRIMAN ST ALEXANDER IA 50420-8066 USA "
    },
    {
      "Location ID": "50421",
      "Location Name": "BELMOND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 216 1ST ST NE BELMOND IA 50421-1004 USA "
    },
    {
      "Location ID": "50423",
      "Location Name": "BRITT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 52 1ST ST SW BRITT IA 50423-1512 USA "
    },
    {
      "Location ID": "50424",
      "Location Name": "BUFFALO CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15 2ND AVE NE BUFFALO CENTER IA 50424-1003 USA "
    },
    {
      "Location ID": "50428",
      "Location Name": "CLEAR LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 1ST AVE N CLEAR LAKE IA 50428-1830 USA "
    },
    {
      "Location ID": "50430",
      "Location Name": "CORWITH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 NW ELM ST CORWITH IA 50430-5007 USA "
    },
    {
      "Location ID": "50431",
      "Location Name": "COULTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 MAIN ST COULTER IA 50431-5000 USA "
    },
    {
      "Location ID": "50432",
      "Location Name": "CRYSTAL LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 STATE AVE S CRYSTAL LAKE IA 50432-7734 USA "
    },
    {
      "Location ID": "50434",
      "Location Name": "FERTILE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 W MAIN ST FERTILE IA 50434-1018 USA "
    },
    {
      "Location ID": "50435",
      "Location Name": "FLOYD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 MONROE ST FLOYD IA 50435-5007 USA "
    },
    {
      "Location ID": "50436",
      "Location Name": "FOREST CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 312 N CLARK ST FOREST CITY IA 50436-1442 USA "
    },
    {
      "Location ID": "50438",
      "Location Name": "GARNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 190 E 3RD ST GARNER IA 50438-1244 USA "
    },
    {
      "Location ID": "50440",
      "Location Name": "GRAFTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 311 3RD ST GRAFTON IA 50440-7559 USA "
    },
    {
      "Location ID": "50441",
      "Location Name": "HAMPTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 22 S FEDERAL ST HAMPTON IA 50441-2162 USA "
    },
    {
      "Location ID": "50444",
      "Location Name": "HANLONTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 MAIN ST HANLONTOWN IA 50444-1016 USA "
    },
    {
      "Location ID": "50446",
      "Location Name": "JOICE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 MAIN ST JOICE IA 50446-7713 USA "
    },
    {
      "Location ID": "50447",
      "Location Name": "KANAWHA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 221 N MAIN ST KANAWHA IA 50447-7707 USA "
    },
    {
      "Location ID": "50448",
      "Location Name": "KENSETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 511 ELM ST KENSETT IA 50448-7625 USA "
    },
    {
      "Location ID": "50449",
      "Location Name": "KLEMME",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 E MAIN ST KLEMME IA 50449-9048 USA "
    },
    {
      "Location ID": "50450",
      "Location Name": "LAKE MILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 N MILL ST LAKE MILLS IA 50450-1350 USA "
    },
    {
      "Location ID": "50451",
      "Location Name": "LAKOTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 223 3RD ST LAKOTA IA 50451-7084 USA "
    },
    {
      "Location ID": "50452",
      "Location Name": "LATIMER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 N AKIR ST LATIMER IA 50452-7591 USA "
    },
    {
      "Location ID": "50453",
      "Location Name": "LELAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 W BROADWAY LELAND IA 50453-7470 USA "
    },
    {
      "Location ID": "50456",
      "Location Name": "MANLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 E MAIN ST MANLY IA 50456-7717 USA "
    },
    {
      "Location ID": "50457",
      "Location Name": "MESERVEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 421 1ST ST MESERVEY IA 50457-1004 USA "
    },
    {
      "Location ID": "50458",
      "Location Name": "NORA SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 19 S HAWKEYE AVE NORA SPRINGS IA 50458-7739 USA "
    },
    {
      "Location ID": "50459",
      "Location Name": "NORTHWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 806 1ST AVE S NORTHWOOD IA 50459-9998 USA "
    },
    {
      "Location ID": "50460",
      "Location Name": "ORCHARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 315 MAIN ST ORCHARD IA 50460-7526 USA "
    },
    {
      "Location ID": "50461",
      "Location Name": "OSAGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 N 8TH ST OSAGE IA 50461-9998 USA "
    },
    {
      "Location ID": "50464",
      "Location Name": "PLYMOUTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 502 MAIN ST PLYMOUTH IA 50464-7712 USA "
    },
    {
      "Location ID": "50465",
      "Location Name": "RAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 N MAIN ST RAKE IA 50465-5000 USA "
    },
    {
      "Location ID": "50466",
      "Location Name": "RICEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 W MAIN ST RICEVILLE IA 50466-7706 USA "
    },
    {
      "Location ID": "50468",
      "Location Name": "ROCKFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 W MAIN AVE ROCKFORD IA 50468-7718 USA "
    },
    {
      "Location ID": "50469",
      "Location Name": "ROCKWELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 313 MAIN ST E ROCKWELL IA 50469-1073 USA "
    },
    {
      "Location ID": "50470",
      "Location Name": "ROWAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 MAIN ST ROWAN IA 50470-5005 USA "
    },
    {
      "Location ID": "50471",
      "Location Name": "RUDD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 514 CHICKASAW ST RUDD IA 50471-5029 USA "
    },
    {
      "Location ID": "50472",
      "Location Name": "SAINT ANSGAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 704 E 4TH ST SAINT ANSGAR IA 50472-9571 USA "
    },
    {
      "Location ID": "50473",
      "Location Name": "SCARVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 403 MAIN ST SCARVILLE IA 50473-7555 USA "
    },
    {
      "Location ID": "50475",
      "Location Name": "SHEFFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 N 2ND ST SHEFFIELD IA 50475-7703 USA "
    },
    {
      "Location ID": "50476",
      "Location Name": "STACYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 N BROAD ST STACYVILLE IA 50476-5054 USA "
    },
    {
      "Location ID": "50477",
      "Location Name": "SWALEDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 5TH ST S SWALEDALE IA 50477-8825 USA "
    },
    {
      "Location ID": "50478",
      "Location Name": "THOMPSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 132 N JACKSON ST THOMPSON IA 50478-5035 USA "
    },
    {
      "Location ID": "50479",
      "Location Name": "THORNTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 313 MAIN ST THORNTON IA 50479-7720 USA "
    },
    {
      "Location ID": "50480",
      "Location Name": "TITONKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 259 MAIN ST N TITONKA IA 50480-7725 USA "
    },
    {
      "Location ID": "50482",
      "Location Name": "VENTURA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N WEIMER ST VENTURA IA 50482-5035 USA "
    },
    {
      "Location ID": "50483",
      "Location Name": "WESLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 MAIN ST W WESLEY IA 50483-7761 USA "
    },
    {
      "Location ID": "50484",
      "Location Name": "WODEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 222 MAIN WODEN IA 50484-7719 USA "
    },
    {
      "Location ID": "504AS",
      "Location Name": "ALL STATES AG PARTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 311 N PARK ST LAKE MILLS IA 50450-9998 USA "
    },
    {
      "Location ID": "504RR",
      "Location Name": "RR DONNELLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 HERITAGE DR OSAGE IA 50461-9998 USA "
    },
    {
      "Location ID": "505",
      "Location Name": "FORT DODGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3440 MAPLE DR FORT DODGE IA 50501 USA "
    },
    {
      "Location ID": "50510",
      "Location Name": "ALBERT CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 MAIN ST ALBERT CITY IA 50510 USA "
    },
    {
      "Location ID": "50511",
      "Location Name": "ALGONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 N MINNESOTA ST ALGONA IA 50511 USA "
    },
    {
      "Location ID": "50514",
      "Location Name": "ARMSTRONG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 608 1ST AVE ARMSTRONG IA 50514 USA "
    },
    {
      "Location ID": "50516",
      "Location Name": "BADGER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 1ST AVE SE BADGER IA 50516 USA "
    },
    {
      "Location ID": "50517",
      "Location Name": "BANCROFT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 W RAMSEY ST BANCROFT IA 50517 USA "
    },
    {
      "Location ID": "50522",
      "Location Name": "BURT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 WALNUT ST BURT IA 50522 USA "
    },
    {
      "Location ID": "50525",
      "Location Name": "CLARION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 1ST ST NE STE 1 CLARION IA 50525 USA "
    },
    {
      "Location ID": "50528",
      "Location Name": "CYLINDER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 229 MAIN ST CYLINDER IA 50528 USA "
    },
    {
      "Location ID": "50532",
      "Location Name": "DUNCOMBE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 414 MAIN ST DUNCOMBE IA 50532 USA "
    },
    {
      "Location ID": "50533",
      "Location Name": "EAGLE GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 S IOWA AVE EAGLE GROVE IA 50533 USA "
    },
    {
      "Location ID": "50536",
      "Location Name": "EMMETSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2210 10TH ST EMMETSBURG IA 50536 USA "
    },
    {
      "Location ID": "50539",
      "Location Name": "FENTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 609 MAPLE ST FENTON IA 50539 USA "
    },
    {
      "Location ID": "50542",
      "Location Name": "GOLDFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 539 N MAIN ST GOLDFIELD IA 50542 USA "
    },
    {
      "Location ID": "50546",
      "Location Name": "HAVELOCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 850 WOOD ST HAVELOCK IA 50546 USA "
    },
    {
      "Location ID": "50554",
      "Location Name": "LAURENS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 316 W MAIN ST LAURENS IA 50554 USA "
    },
    {
      "Location ID": "50556",
      "Location Name": "LEDYARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 217 EDMUNDS ST LEDYARD IA 50556 USA "
    },
    {
      "Location ID": "50559",
      "Location Name": "LONE ROCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 MAINE ST LONE ROCK IA 50559 USA "
    },
    {
      "Location ID": "50565",
      "Location Name": "MARATHON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 AGORA ST MARATHON IA 50565 USA "
    },
    {
      "Location ID": "50574",
      "Location Name": "POCAHONTAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 88 COURT SQ POCAHONTAS IA 50574 USA "
    },
    {
      "Location ID": "50578",
      "Location Name": "RINGSTED",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 W MAPLE ST RINGSTED IA 50578 USA "
    },
    {
      "Location ID": "50590",
      "Location Name": "SWEA CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 3RD AVE W SWEA CITY IA 50590 USA "
    },
    {
      "Location ID": "50591",
      "Location Name": "THOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 N ANN ST THOR IA 50591 USA "
    },
    {
      "Location ID": "50594",
      "Location Name": "VINCENT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 N 1ST ST VINCENT IA 50594 USA "
    },
    {
      "Location ID": "50595",
      "Location Name": "WEBSTER CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 805 SENECA ST WEBSTER CITY IA 50595 USA "
    },
    {
      "Location ID": "50598",
      "Location Name": "WHITTEMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 421 BROAD ST WHITTEMORE IA 50598 USA "
    },
    {
      "Location ID": "506",
      "Location Name": "WATERLOO P&DF, IA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 SYCAMORE ST WATERLOO IA 50701 USA "
    },
    {
      "Location ID": "50601",
      "Location Name": "ACKLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 706 1ST AVE ACKLEY IA 50601-1555 USA "
    },
    {
      "Location ID": "50602",
      "Location Name": "ALLISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 N MAIN ST ALLISON IA 50602-7708 USA "
    },
    {
      "Location ID": "50603",
      "Location Name": "ALTA VISTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 N WHITE AVE ALTA VISTA IA 50603-7708 USA "
    },
    {
      "Location ID": "50604",
      "Location Name": "APLINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 925 PARRIOTT ST APLINGTON IA 50604-7705 USA "
    },
    {
      "Location ID": "50605",
      "Location Name": "AREDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 E MAIN ST AREDALE IA 50605-8402 USA "
    },
    {
      "Location ID": "50607",
      "Location Name": "AURORA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 405 MAIN ST AURORA IA 50607-9756 USA "
    },
    {
      "Location ID": "50609",
      "Location Name": "BEAMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 CENTER ST BEAMAN IA 50609-8562 USA "
    },
    {
      "Location ID": "50613",
      "Location Name": "CEDAR FALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 221 W 6TH ST CEDAR FALLS IA 50613-9998 USA "
    },
    {
      "Location ID": "50616",
      "Location Name": "CHARLES CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 N MAIN ST CHARLES CITY IA 50616-2005 USA "
    },
    {
      "Location ID": "50619",
      "Location Name": "CLARKSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 S MAIN ST CLARKSVILLE IA 50619-7748 USA "
    },
    {
      "Location ID": "50621",
      "Location Name": "CONRAD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 N MAIN ST CONRAD IA 50621-7748 USA "
    },
    {
      "Location ID": "50622",
      "Location Name": "DENVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 340 S STATE ST DENVER IA 50622-7706 USA "
    },
    {
      "Location ID": "50623",
      "Location Name": "DEWAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5916 1ST ST DEWAR IA 50623-5000 USA "
    },
    {
      "Location ID": "50624",
      "Location Name": "DIKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 508 MAIN ST DIKE IA 50624-7724 USA "
    },
    {
      "Location ID": "50625",
      "Location Name": "DUMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 603 MAIN ST DUMONT IA 50625-1035 USA "
    },
    {
      "Location ID": "50626",
      "Location Name": "DUNKERTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 713 S CANFIELD ST DUNKERTON IA 50626-9514 USA "
    },
    {
      "Location ID": "50627",
      "Location Name": "ELDORA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1334 EDGINGTON AVE ELDORA IA 50627-9998 USA "
    },
    {
      "Location ID": "50628",
      "Location Name": "ELMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 412 MAIN ST ELMA IA 50628-8372 USA "
    },
    {
      "Location ID": "50629",
      "Location Name": "FAIRBANK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 E MAIN ST FAIRBANK IA 50629-8623 USA "
    },
    {
      "Location ID": "50630",
      "Location Name": "FREDERICKSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 E MAIN ST FREDERICKSBURG IA 50630-2014 USA "
    },
    {
      "Location ID": "50631",
      "Location Name": "FREDERIKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 3RD ST FREDERIKA IA 50631-5000 USA "
    },
    {
      "Location ID": "50634",
      "Location Name": "GILBERTVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1320 5TH ST GILBERTVILLE IA 50634-7726 USA "
    },
    {
      "Location ID": "50635",
      "Location Name": "GLADBROOK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 GOULD ST GLADBROOK IA 50635-9322 USA "
    },
    {
      "Location ID": "50636",
      "Location Name": "GREENE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 S 1ST ST GREENE IA 50636-7758 USA "
    },
    {
      "Location ID": "50638",
      "Location Name": "GRUNDY CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1700 G AVE GRUNDY CENTER IA 50638-9998 USA "
    },
    {
      "Location ID": "50641",
      "Location Name": "HAZLETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 MAIN ST S HAZLETON IA 50641-9504 USA "
    },
    {
      "Location ID": "50642",
      "Location Name": "HOLLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 MAIN ST HOLLAND IA 50642-7713 USA "
    },
    {
      "Location ID": "50643",
      "Location Name": "HUDSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 JEFFERSON ST HUDSON IA 50643-9719 USA "
    },
    {
      "Location ID": "50644",
      "Location Name": "INDEPENDENCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 2ND AVE NE INDEPENDENCE IA 50644-1857 USA "
    },
    {
      "Location ID": "50645",
      "Location Name": "IONIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 W MAIN ST IONIA IA 50645-9622 USA "
    },
    {
      "Location ID": "50647",
      "Location Name": "JANESVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 MAIN ST JANESVILLE IA 50647-7738 USA "
    },
    {
      "Location ID": "50648",
      "Location Name": "JESUP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 811 MAIN ST JESUP IA 50648-7703 USA "
    },
    {
      "Location ID": "50650",
      "Location Name": "LAMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 620 BUSH ST LAMONT IA 50650-9041 USA "
    },
    {
      "Location ID": "50651",
      "Location Name": "LA PORTE CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 408 CHESTNUT ST LA PORTE CITY IA 50651-1357 USA "
    },
    {
      "Location ID": "50652",
      "Location Name": "LINCOLN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 MAIN ST LINCOLN IA 50652-5000 USA "
    },
    {
      "Location ID": "50653",
      "Location Name": "MARBLE ROCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 S MAIN ST MARBLE ROCK IA 50653-9998 USA "
    },
    {
      "Location ID": "50654",
      "Location Name": "MASONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 504 BARRETT ST MASONVILLE IA 50654-8533 USA "
    },
    {
      "Location ID": "50655",
      "Location Name": "MAYNARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 255 MAIN ST W MAYNARD IA 50655-7655 USA "
    },
    {
      "Location ID": "50658",
      "Location Name": "NASHUA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 MAIN ST NASHUA IA 50658-7746 USA "
    },
    {
      "Location ID": "50659",
      "Location Name": "NEW HAMPTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 N CHESTNUT AVE NEW HAMPTON IA 50659-9998 USA "
    },
    {
      "Location ID": "50660",
      "Location Name": "NEW HARTFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 403 PACKWAUKEE ST NEW HARTFORD IA 50660-9706 USA "
    },
    {
      "Location ID": "50662",
      "Location Name": "OELWEIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 30 1ST AVE NE OELWEIN IA 50662-1747 USA "
    },
    {
      "Location ID": "50665",
      "Location Name": "PARKERSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 602 COATES ST PARKERSBURG IA 50665-7732 USA "
    },
    {
      "Location ID": "50666",
      "Location Name": "PLAINFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 720 MAIN ST PLAINFIELD IA 50666-7730 USA "
    },
    {
      "Location ID": "50667",
      "Location Name": "RAYMOND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 COMMERCIAL ST RAYMOND IA 50667-7727 USA "
    },
    {
      "Location ID": "50668",
      "Location Name": "READLYN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 218 MAIN ST READLYN IA 50668-7733 USA "
    },
    {
      "Location ID": "50669",
      "Location Name": "REINBECK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 509 CLARK ST REINBECK IA 50669-1185 USA "
    },
    {
      "Location ID": "50670",
      "Location Name": "SHELL ROCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 S WALNUT ST SHELL ROCK IA 50670-7780 USA "
    },
    {
      "Location ID": "50672",
      "Location Name": "STEAMBOAT ROCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 4TH ST STEAMBOAT ROCK IA 50672-1013 USA "
    },
    {
      "Location ID": "50673",
      "Location Name": "STOUT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 MAIN ST STOUT IA 50673-5000 USA "
    },
    {
      "Location ID": "50674",
      "Location Name": "SUMNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 S CARPENTER ST SUMNER IA 50674-1443 USA "
    },
    {
      "Location ID": "50675",
      "Location Name": "TRAER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N MAIN ST TRAER IA 50675-1027 USA "
    },
    {
      "Location ID": "50676",
      "Location Name": "TRIPOLI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 S MAIN ST TRIPOLI IA 50676-7713 USA "
    },
    {
      "Location ID": "50677",
      "Location Name": "WAVERLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 124 2ND ST SE WAVERLY IA 50677-9998 USA "
    },
    {
      "Location ID": "50680",
      "Location Name": "WELLSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 N ADAMS ST WELLSBURG IA 50680-7811 USA "
    },
    {
      "Location ID": "50682",
      "Location Name": "WINTHROP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 253 W MADISON ST WINTHROP IA 50682-7705 USA "
    },
    {
      "Location ID": "506AC",
      "Location Name": "AMERICAN COLOR IMAGING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 715 E 18TH ST CEDAR FALLS IA 50613-4320 USA "
    },
    {
      "Location ID": "506AS",
      "Location Name": "AMERICAN SOY ORGANICS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 375 INDUSTRIAL AVE NEW HAMPTON IA 50659 USA "
    },
    {
      "Location ID": "506BA",
      "Location Name": "BRACE ABILITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 E 2ND ST CEDAR FALLS IA 50613-3357 USA "
    },
    {
      "Location ID": "506BI",
      "Location Name": "BOSSEN IMPLEMENT INC.",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 WASHBURN AVE LAMONT IA 50650-9535 USA "
    },
    {
      "Location ID": "506CS",
      "Location Name": "COLLISION SERVICES INC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1050 DALE DR HUDSON IA 50643-7710 USA "
    },
    {
      "Location ID": "506GH",
      "Location Name": "GEARHEAD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 650 5TH ST HUDSON IA 50643-2501 USA "
    },
    {
      "Location ID": "506RC",
      "Location Name": "RADA CUTLERY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 905 INDUSTRIAL RD WAVERLY IA 50677-1851 USA "
    },
    {
      "Location ID": "506SB",
      "Location Name": "DO IT SOFT BAITS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 N STATE ST DENVER IA 50622 USA "
    },
    {
      "Location ID": "506SC",
      "Location Name": "SCHEELS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 402 VIKING PLAZA DRIVE CEDAR FALLS IA 50613 USA "
    },
    {
      "Location ID": "506SG",
      "Location Name": "STANDARD GOLF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6620 NORDIC DR CEDAR FALLS IA 50360-6139 USA "
    },
    {
      "Location ID": "50707",
      "Location Name": "EVANSDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3568 LAFAYETTE RD EVANSDALE IA 50707-9715 USA "
    },
    {
      "Location ID": "507AX",
      "Location Name": "WATERLOO CARRIER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3314 MARNIE AVE WATERLOO IA 50701-4303 USA "
    },
    {
      "Location ID": "507PS",
      "Location Name": "PROFESSIONAL OFFICE SERVICES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2757 BURTON AVE WATERLOO IA 50729-7039 USA "
    },
    {
      "Location ID": "507VM",
      "Location Name": "VGM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4026 ALEXANDRA DR WATERLOO IA 50702-6118 USA "
    },
    {
      "Location ID": "508",
      "Location Name": "CRESTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 S ELM ST CRESTON IA 50801 USA "
    },
    {
      "Location ID": "50830",
      "Location Name": "AFTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 W KANSAS ST AFTON IA 50830 USA "
    },
    {
      "Location ID": "50831",
      "Location Name": "ARISPE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 FORBES ST ARISPE IA 50831 USA "
    },
    {
      "Location ID": "50833",
      "Location Name": "BEDFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 609 CENTRAL AVE BEDFORD IA 50833 USA "
    },
    {
      "Location ID": "50835",
      "Location Name": "BENTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 E MARKET ST BENTON IA 50835 USA "
    },
    {
      "Location ID": "50836",
      "Location Name": "BLOCKTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 609 KING ST BLOCKTON IA 50836 USA "
    },
    {
      "Location ID": "50840",
      "Location Name": "CLEARFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 BROADWAY ST CLEARFIELD IA 50840 USA "
    },
    {
      "Location ID": "50841",
      "Location Name": "CORNING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 608 7TH ST CORNING IA 50841 USA "
    },
    {
      "Location ID": "50845",
      "Location Name": "DIAGONAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 221 BROADWAY ST DIAGONAL IA 50845 USA "
    },
    {
      "Location ID": "50848",
      "Location Name": "GRAVITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 MAIN ST GRAVITY IA 50848 USA "
    },
    {
      "Location ID": "50849",
      "Location Name": "GREENFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 SE JACKSON ST GREENFIELD IA 50849 USA "
    },
    {
      "Location ID": "50851",
      "Location Name": "LENOX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 W TEMPLE ST LENOX IA 50851 USA "
    },
    {
      "Location ID": "50854",
      "Location Name": "MOUNT AYR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 W MADISON ST MOUNT AYR IA 50854 USA "
    },
    {
      "Location ID": "50859",
      "Location Name": "PRESCOTT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 6TH AVE PRESCOTT IA 50859 USA "
    },
    {
      "Location ID": "50860",
      "Location Name": "REDDING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 3RD ST REDDING IA 50860 USA "
    },
    {
      "Location ID": "50861",
      "Location Name": "SHANNON CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 MAIN ST SHANNON CITY IA 50861 USA "
    },
    {
      "Location ID": "50862",
      "Location Name": "SHARPSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 WABASH ST SHARPSBURG IA 50862 USA "
    },
    {
      "Location ID": "50863",
      "Location Name": "TINGLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 W MAIN ST TINGLEY IA 50863 USA "
    },
    {
      "Location ID": "50864",
      "Location Name": "VILLISCA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 S 3RD AVE VILLISCA IA 50864 USA "
    },
    {
      "Location ID": "50ABX",
      "Location Name": "DES MOINES ABX CDL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1650 E WASHINGTON AVE DES MOINES IA 50313 USA "
    },
    {
      "Location ID": "50PSI",
      "Location Name": "DMU - PSI DES MOINES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4350 121ST ST URBANDALE IA 50323 USA "
    },
    {
      "Location ID": "50Z",
      "Location Name": "DES MOINES NDC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4000 109TH ST URBANDALE IA 50395 USA "
    },
    {
      "Location ID": "51001",
      "Location Name": "AKRON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 351 REED ST AKRON IA 51001 USA "
    },
    {
      "Location ID": "51002",
      "Location Name": "ALTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 S CHEROKEE ST ALTA IA 51002 USA "
    },
    {
      "Location ID": "51003",
      "Location Name": "ALTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1022 3RD AVE ALTON IA 51003 USA "
    },
    {
      "Location ID": "51004",
      "Location Name": "ANTHON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 N 2ND AVE ANTHON IA 51004 USA "
    },
    {
      "Location ID": "51005",
      "Location Name": "AURELIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 MYRTLE ST AURELIA IA 51005 USA "
    },
    {
      "Location ID": "51006",
      "Location Name": "BATTLE CREEK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 MAIN ST BATTLE CREEK IA 51006 USA "
    },
    {
      "Location ID": "51007",
      "Location Name": "BRONSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 E 1ST ST BRONSON IA 51007 USA "
    },
    {
      "Location ID": "51008",
      "Location Name": "BRUNSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 230 OAK ST BRUNSVILLE IA 51008 USA "
    },
    {
      "Location ID": "51009",
      "Location Name": "CALUMET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 2ND ST PAULLINA IA 51009 USA "
    },
    {
      "Location ID": "51010",
      "Location Name": "CASTANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 N 3RD ST CASTANA IA 51010 USA "
    },
    {
      "Location ID": "51012",
      "Location Name": "CHEROKEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 W WILLOW ST CHEROKEE IA 51012 USA "
    },
    {
      "Location ID": "51014",
      "Location Name": "CLEGHORN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 N LEWIS AVE CLEGHORN IA 51014 USA "
    },
    {
      "Location ID": "51016",
      "Location Name": "CORRECTIONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 5TH ST CORRECTIONVILLE IA 51016 USA "
    },
    {
      "Location ID": "51018",
      "Location Name": "CUSHING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 406 3RD ST CUSHING IA 51018 USA "
    },
    {
      "Location ID": "51019",
      "Location Name": "DANBURY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 MAIN ST DANBURY IA 51019 USA "
    },
    {
      "Location ID": "51020",
      "Location Name": "GALVA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 S MAIN ST GALVA IA 51020 USA "
    },
    {
      "Location ID": "51022",
      "Location Name": "GRANVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 839 BROAD ST GRANVILLE IA 51022 USA "
    },
    {
      "Location ID": "51023",
      "Location Name": "HAWARDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 CENTRAL AVE HAWARDEN IA 51023 USA "
    },
    {
      "Location ID": "51024",
      "Location Name": "HINTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 W MAIN ST HINTON IA 51024 USA "
    },
    {
      "Location ID": "51025",
      "Location Name": "HOLSTEIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 E 2ND ST HOLSTEIN IA 51025 USA "
    },
    {
      "Location ID": "51026",
      "Location Name": "HORNICK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 315 MAIN ST HORNICK IA 51026 USA "
    },
    {
      "Location ID": "51027",
      "Location Name": "IRETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 320 MAIN ST IRETON IA 51027 USA "
    },
    {
      "Location ID": "51028",
      "Location Name": "KINGSLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 MAIN ST KINGSLEY IA 51028 USA "
    },
    {
      "Location ID": "51029",
      "Location Name": "LARRABEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 N MAIN ST LARRABEE IA 51029 USA "
    },
    {
      "Location ID": "51030",
      "Location Name": "LAWTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 314 CEDAR ST LAWTON IA 51030 USA "
    },
    {
      "Location ID": "51031",
      "Location Name": "LE MARS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 230 1ST ST NW LE MARS IA 51031 USA "
    },
    {
      "Location ID": "51033",
      "Location Name": "LINN GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 WEAVER ST ALTA IA 51033 USA "
    },
    {
      "Location ID": "51034",
      "Location Name": "MAPLETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 S 5TH ST MAPLETON IA 51034 USA "
    },
    {
      "Location ID": "51035",
      "Location Name": "MARCUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 E PINE ST MARCUS IA 51035 USA "
    },
    {
      "Location ID": "51036",
      "Location Name": "MAURICE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 2ND ST MAURICE IA 51036 USA "
    },
    {
      "Location ID": "51037",
      "Location Name": "MERIDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 EAGLE ST MERIDEN IA 51037 USA "
    },
    {
      "Location ID": "51038",
      "Location Name": "MERRILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 134 MAIN ST MERRILL IA 51038 USA "
    },
    {
      "Location ID": "51039",
      "Location Name": "MOVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 319 MAIN ST MOVILLE IA 51039 USA "
    },
    {
      "Location ID": "51040",
      "Location Name": "ONAWA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1017 IOWA AVE ONAWA IA 51040 USA "
    },
    {
      "Location ID": "51041",
      "Location Name": "ORANGE CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 ALBANY AVE SE ORANGE CITY IA 51041 USA "
    },
    {
      "Location ID": "51044",
      "Location Name": "OTO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 32 WASHINGTON ST OTO IA 51044 USA "
    },
    {
      "Location ID": "51045",
      "Location Name": "OYENS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 MAIN ST OYENS IA 51045 USA "
    },
    {
      "Location ID": "51046",
      "Location Name": "PAULLINA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 S MAIN ST PAULLINA IA 51046 USA "
    },
    {
      "Location ID": "51047",
      "Location Name": "PETERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 222 MAIN ST PETERSON IA 51047 USA "
    },
    {
      "Location ID": "51048",
      "Location Name": "PIERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 324 FRONT ST PIERSON IA 51048 USA "
    },
    {
      "Location ID": "51049",
      "Location Name": "QUIMBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 W 2ND AVE QUIMBY IA 51049 USA "
    },
    {
      "Location ID": "51050",
      "Location Name": "REMSEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 23 E 2ND ST REMSEN IA 51050 USA "
    },
    {
      "Location ID": "51051",
      "Location Name": "RODNEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 MAIN ST RODNEY IA 51051 USA "
    },
    {
      "Location ID": "51052",
      "Location Name": "SALIX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 310 TIPTON ST SALIX IA 51052 USA "
    },
    {
      "Location ID": "51053",
      "Location Name": "SCHALLER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 S MAIN ST SCHALLER IA 51053 USA "
    },
    {
      "Location ID": "51054",
      "Location Name": "SERGEANT BLUFF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 4TH ST SERGEANT BLUFF IA 51054 USA "
    },
    {
      "Location ID": "51055",
      "Location Name": "SLOAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 309 4TH ST SLOAN IA 51055 USA "
    },
    {
      "Location ID": "51056",
      "Location Name": "SMITHLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 S HICKORY ST SMITHLAND IA 51056 USA "
    },
    {
      "Location ID": "51058",
      "Location Name": "SUTHERLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 W 1ST ST SUTHERLAND IA 51058 USA "
    },
    {
      "Location ID": "51060",
      "Location Name": "UTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 E MAIN ST UTE IA 51060 USA "
    },
    {
      "Location ID": "51061",
      "Location Name": "WASHTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 MAIN ST WASHTA IA 51061 USA "
    },
    {
      "Location ID": "51062",
      "Location Name": "WESTFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 UNION ST WESTFIELD IA 51062 USA "
    },
    {
      "Location ID": "51063",
      "Location Name": "WHITING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 609 WHITTIER ST WHITING IA 51063 USA "
    },
    {
      "Location ID": "510BH",
      "Location Name": "BAD HABIT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 23406 OLD HIGHWAY 75 MERRILL IA 51038 USA "
    },
    {
      "Location ID": "510RA",
      "Location Name": "REVIVAL ANIMAL HEALTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1700 ALBANY PL ORANGE CITY IA 51041 USA "
    },
    {
      "Location ID": "510ST",
      "Location Name": "STAPLES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1520 ALBANY PL ORANGE CITY IA 51041 USA "
    },
    {
      "Location ID": "51101",
      "Location Name": "SIOUX CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 JACKSON ST SIOUX CITY IA 51101 USA "
    },
    {
      "Location ID": "512",
      "Location Name": "SHELDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 312 11TH ST SHELDON IA 51201 USA "
    },
    {
      "Location ID": "51230",
      "Location Name": "ALVORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 217 N MAIN ST ALVORD IA 51230 USA "
    },
    {
      "Location ID": "51231",
      "Location Name": "ARCHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 MAIN ST ARCHER IA 51231 USA "
    },
    {
      "Location ID": "51232",
      "Location Name": "ASHTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 152 1ST AVE ASHTON IA 51232 USA "
    },
    {
      "Location ID": "51234",
      "Location Name": "BOYDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 911 MAIN ST SHELDON IA 51234 USA "
    },
    {
      "Location ID": "51235",
      "Location Name": "DOON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 223 MAIN ST DOON IA 51235 USA "
    },
    {
      "Location ID": "51237",
      "Location Name": "GEORGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 E MICHIGAN AVE GEORGE IA 51237 USA "
    },
    {
      "Location ID": "51238",
      "Location Name": "HOSPERS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 MAIN ST HOSPERS IA 51238 USA "
    },
    {
      "Location ID": "51239",
      "Location Name": "HULL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1204 MAIN ST HULL IA 51239 USA "
    },
    {
      "Location ID": "51240",
      "Location Name": "INWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 S MAIN ST INWOOD IA 51240 USA "
    },
    {
      "Location ID": "51241",
      "Location Name": "LARCHWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 935 BROADWAY ST LARCHWOOD IA 51241 USA "
    },
    {
      "Location ID": "51242",
      "Location Name": "LESTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 311 MAIN ST LESTER IA 51242 USA "
    },
    {
      "Location ID": "51243",
      "Location Name": "LITTLE ROCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 319 MAIN ST LITTLE ROCK IA 51243 USA "
    },
    {
      "Location ID": "51245",
      "Location Name": "PRIMGHAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 280 S GREEN AVE PRIMGHAR IA 51245 USA "
    },
    {
      "Location ID": "51246",
      "Location Name": "ROCK RAPIDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 217 N 2ND AVE ROCK RAPIDS IA 51246 USA "
    },
    {
      "Location ID": "51247",
      "Location Name": "ROCK VALLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1501 MAIN ST ROCK VALLEY IA 51247 USA "
    },
    {
      "Location ID": "51248",
      "Location Name": "SANBORN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 312 MAIN ST SANBORN IA 51248 USA "
    },
    {
      "Location ID": "51249",
      "Location Name": "SIBLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 412 9TH ST SIBLEY IA 51249 USA "
    },
    {
      "Location ID": "51250",
      "Location Name": "SIOUX CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 N MAIN AVE SIOUX CENTER IA 51250 USA "
    },
    {
      "Location ID": "512AF",
      "Location Name": "AMERICAN FARM COMPANY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4026 LOG AVE HOSPERS IA 51238 USA "
    },
    {
      "Location ID": "512AP",
      "Location Name": "GLYNLYON INC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 504 N 2ND AVE ROCK RAPIDS IA 51246 USA "
    },
    {
      "Location ID": "512DD",
      "Location Name": "D&D GLOBAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1333 VALLEY DRIVE ROCK VALLEY IA 51247 USA "
    },
    {
      "Location ID": "512MM",
      "Location Name": "MCCARTY MOTORS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 509 1ST AVE ROCK RAPIDS IA 51246 USA "
    },
    {
      "Location ID": "513",
      "Location Name": "SPENCER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11 E 18TH ST SPENCER IA 51301 USA "
    },
    {
      "Location ID": "51331",
      "Location Name": "ARNOLDS PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 50 N HIGHWAY 71 ARNOLDS PARK IA 51331 USA "
    },
    {
      "Location ID": "51333",
      "Location Name": "DICKENS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 2ND ST DICKENS IA 51333 USA "
    },
    {
      "Location ID": "51334",
      "Location Name": "ESTHERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 605 2ND AVE N ESTHERVILLE IA 51334 USA "
    },
    {
      "Location ID": "51338",
      "Location Name": "EVERLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 N MAIN ST EVERLY IA 51338 USA "
    },
    {
      "Location ID": "51340",
      "Location Name": "FOSTORIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 MAIN ST FOSTORIA IA 51340 USA "
    },
    {
      "Location ID": "51341",
      "Location Name": "GILLETTE GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 RAILWAY ST GILLETT GROVE IA 51341 USA "
    },
    {
      "Location ID": "51342",
      "Location Name": "GRAETTINGER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 W ROBINS ST GRAETTINGER IA 51342 USA "
    },
    {
      "Location ID": "51345",
      "Location Name": "HARRIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 312 MAIN ST N HARRIS IA 51345 USA "
    },
    {
      "Location ID": "51346",
      "Location Name": "HARTLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 41 S CENTRAL AVE HARTLEY IA 51346 USA "
    },
    {
      "Location ID": "51347",
      "Location Name": "LAKE PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 N MARKET ST LAKE PARK IA 51347 USA "
    },
    {
      "Location ID": "51350",
      "Location Name": "MELVIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 246 MAIN ST MELVIN IA 51350 USA "
    },
    {
      "Location ID": "51351",
      "Location Name": "MILFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 N AVE MILFORD IA 51351 USA "
    },
    {
      "Location ID": "51354",
      "Location Name": "OCHEYEDAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 851 MAIN ST OCHEYEDAN IA 51354 USA "
    },
    {
      "Location ID": "51355",
      "Location Name": "OKOBOJI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1009 HIGHWAY 71 S OKOBOJI IA 51355 USA "
    },
    {
      "Location ID": "51357",
      "Location Name": "ROYAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 308 MAIN ST ROYAL IA 51357 USA "
    },
    {
      "Location ID": "51358",
      "Location Name": "RUTHVEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1202 GOWRIE ST RUTHVEN IA 51358 USA "
    },
    {
      "Location ID": "51360",
      "Location Name": "SPIRIT LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1513 HILL AVE SPIRIT LAKE IA 51360 USA "
    },
    {
      "Location ID": "51363",
      "Location Name": "SUPERIOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 7TH ST SUPERIOR IA 51363 USA "
    },
    {
      "Location ID": "51364",
      "Location Name": "TERRIL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 S STATE ST TERRIL IA 51364 USA "
    },
    {
      "Location ID": "51365",
      "Location Name": "WALLINGFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 18 SAINT JAMES AVE WALLINGFORD IA 51365 USA "
    },
    {
      "Location ID": "51366",
      "Location Name": "WEBB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 2ND AVE WEBB IA 51366 USA "
    },
    {
      "Location ID": "514",
      "Location Name": "CARROLL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 614 N CLARK ST CARROLL IA 51401 USA "
    },
    {
      "Location ID": "52001",
      "Location Name": "DUBUQUE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 350 W 6TH ST STE 124 DUBUQUE IA 52001 USA "
    },
    {
      "Location ID": "52030",
      "Location Name": "ANDREW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13 W BENTON ST ANDREW IA 52030 USA "
    },
    {
      "Location ID": "52031",
      "Location Name": "BELLEVUE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 N 2ND ST BELLEVUE IA 52031 USA "
    },
    {
      "Location ID": "52032",
      "Location Name": "BELLEVUE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 157 JESS ST BERNARD IA 52032 USA "
    },
    {
      "Location ID": "52033",
      "Location Name": "CASCADE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 2ND AVE NW CASCADE IA 52033 USA "
    },
    {
      "Location ID": "52035",
      "Location Name": "COLESBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 304 MAIN ST COLESBURG IA 52035 USA "
    },
    {
      "Location ID": "52037",
      "Location Name": "DELMAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 604 VANE ST DELMAR IA 52037 USA "
    },
    {
      "Location ID": "52038",
      "Location Name": "DUNDEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 N CENTER ST DUNDEE IA 52038 USA "
    },
    {
      "Location ID": "52040",
      "Location Name": "DYERSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 315 3RD AVE SE DYERSVILLE IA 52040 USA "
    },
    {
      "Location ID": "52042",
      "Location Name": "EDGEWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 E UNION ST EDGEWOOD IA 52042 USA "
    },
    {
      "Location ID": "52043",
      "Location Name": "ELKADER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 MULBERRY ST ELKADER IA 52043 USA "
    },
    {
      "Location ID": "52045",
      "Location Name": "EPWORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 N CENTER AVE EPWORTH IA 52045 USA "
    },
    {
      "Location ID": "52046",
      "Location Name": "FARLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 1ST ST N FARLEY IA 52046 USA "
    },
    {
      "Location ID": "52049",
      "Location Name": "GARNAVILLO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 S MAIN ST GARNAVILLO IA 52049 USA "
    },
    {
      "Location ID": "52050",
      "Location Name": "GREELEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 E 3RD ST GREELEY IA 52050 USA "
    },
    {
      "Location ID": "52052",
      "Location Name": "GUTTENBERG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 519 1ST ST GUTTENBERG IA 52052 USA "
    },
    {
      "Location ID": "52053",
      "Location Name": "HOLY CROSS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5952 MAPLE RD HOLY CROSS IA 52053 USA "
    },
    {
      "Location ID": "52054",
      "Location Name": "LAMOTTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 800 PLEASANT ST LAMOTTE IA 52054 USA "
    },
    {
      "Location ID": "52056",
      "Location Name": "LUXEMBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 E MAIN ST LUXEMBURG IA 52056 USA "
    },
    {
      "Location ID": "52057",
      "Location Name": "MANCHESTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 E DELAWARE ST MANCHESTER IA 52057 USA "
    },
    {
      "Location ID": "52060",
      "Location Name": "MAQUOKETA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 N MAIN ST MAQUOKETA IA 52060 USA "
    },
    {
      "Location ID": "52064",
      "Location Name": "MILES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 323 FERRY RD MILES IA 52064 USA "
    },
    {
      "Location ID": "52065",
      "Location Name": "NEW VIENNA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2177 MAIN ST NEW VIENNA IA 52065 USA "
    },
    {
      "Location ID": "52068",
      "Location Name": "PEOSTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8554 KAPP DR PEOSTA IA 52068 USA "
    },
    {
      "Location ID": "52069",
      "Location Name": "PRESTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 172 W GILLET ST PRESTON IA 52069 USA "
    },
    {
      "Location ID": "52070",
      "Location Name": "SABULA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 411 BROAD ST SABULA IA 52070 USA "
    },
    {
      "Location ID": "52076",
      "Location Name": "STRAWBERRY POINT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 132 W MISSION ST STRAWBERRY POINT IA 52076 USA "
    },
    {
      "Location ID": "52077",
      "Location Name": "VOLGA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 WASHINGTON ST VOLGA IA 52077 USA "
    },
    {
      "Location ID": "52078",
      "Location Name": "WORTHINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 1ST ST S WORTHINGTON IA 52078 USA "
    },
    {
      "Location ID": "52079",
      "Location Name": "ZWINGLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 168 N MAIN ST ZWINGLE IA 52079 USA "
    },
    {
      "Location ID": "520AN",
      "Location Name": "WEST SIDE ANNEX, IA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4555 FUTURO CT DUBUQUE IA 52002 USA "
    },
    {
      "Location ID": "520BF",
      "Location Name": "BLAIN'S FARM & FLEET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2675 NW ARTERIAL DUBUQUE IA 52002-0502 USA "
    },
    {
      "Location ID": "520DD",
      "Location Name": "DDI INC.",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7425 CHAVENELLE RD DUBUQUE IA 52002-9568 USA "
    },
    {
      "Location ID": "520FG",
      "Location Name": "FIRST GEAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8668 KAPP DR PEOSTA IA 52068-9568 USA "
    },
    {
      "Location ID": "520LC",
      "Location Name": "LEARNING CURVE BRANDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2021 9TH ST SE DYERSVILLE IA 52040 USA "
    },
    {
      "Location ID": "520LT",
      "Location Name": "LA MOTTE TELEPHONE CO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 PINE ST LA MOTTE IA 52054 USA "
    },
    {
      "Location ID": "520ND",
      "Location Name": "NORDSTROM DISTRIBUTION CTR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5050 CHAVENELLE RD DUBUQUE IA 52002 USA "
    },
    {
      "Location ID": "520PW",
      "Location Name": "PEOSTA WAREHOUSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9802 KAPP CT PEOSTA IA 52068 USA "
    },
    {
      "Location ID": "520SC",
      "Location Name": "SWISS COLONY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8970 ENTERPRISE DR PEOSTA IA 52068 USA "
    },
    {
      "Location ID": "520TM",
      "Location Name": "THE MARKET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 N LOCUST ST EDGEWOOD IA 52042 USA "
    },
    {
      "Location ID": "520TY",
      "Location Name": "TYME",
      "Time Zone Offset (Hours)": -1,
      "Address": " 29540 HWY 52, STE B BELLEVUE IA 52031 USA "
    },
    {
      "Location ID": "521",
      "Location Name": "DECORAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 HEIVLY ST DECORAH IA 52101-9998 USA "
    },
    {
      "Location ID": "52101",
      "Location Name": "DECORAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 HEIVLY ST DECORAH IA 52101-9998 USA "
    },
    {
      "Location ID": "52132",
      "Location Name": "CALMAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 S MARYVILLE ST CALMAR IA 52132-8527 USA "
    },
    {
      "Location ID": "52133",
      "Location Name": "CASTALIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 E GREENE ST CASTALIA IA 52133-8549 USA "
    },
    {
      "Location ID": "52134",
      "Location Name": "CHESTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 MAIN ST CHESTER IA 52134-7705 USA "
    },
    {
      "Location ID": "52135",
      "Location Name": "CLERMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 319 MILL ST CLERMONT IA 52135-4400 USA "
    },
    {
      "Location ID": "52136",
      "Location Name": "CRESCO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 128 2ND AVE W CRESCO IA 52136-1509 USA "
    },
    {
      "Location ID": "52141",
      "Location Name": "ELGIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 238 CENTER ST ELGIN IA 52141-9334 USA "
    },
    {
      "Location ID": "52142",
      "Location Name": "FAYETTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 E WATER ST FAYETTE IA 52142-4400 USA "
    },
    {
      "Location ID": "52144",
      "Location Name": "FORT ATKINSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 MAIN ST FORT ATKINSON IA 52144-7506 USA "
    },
    {
      "Location ID": "52146",
      "Location Name": "HARPERS FERRY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 131 N GREELEY AVE HARPERS FERRY IA 52146-4400 USA "
    },
    {
      "Location ID": "52151",
      "Location Name": "LANSING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 383 MAIN ST LANSING IA 52151-4400 USA "
    },
    {
      "Location ID": "52155",
      "Location Name": "LIME SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 W MAIN ST LIME SPRINGS IA 52155-4400 USA "
    },
    {
      "Location ID": "52156",
      "Location Name": "LUANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 BURGES ST LUANA IA 52156-4400 USA "
    },
    {
      "Location ID": "52157",
      "Location Name": "MC GREGOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 2ND ST MCGREGOR IA 52157-4400 USA "
    },
    {
      "Location ID": "52158",
      "Location Name": "MARQUETTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 NORTH ST MARQUETTE IA 52158-4400 USA "
    },
    {
      "Location ID": "52159",
      "Location Name": "MONONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 S MAIN ST MONONA IA 52159-4400 USA "
    },
    {
      "Location ID": "52160",
      "Location Name": "NEW ALBIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 190 MAIN ST NE NEW ALBIN IA 52160-4400 USA "
    },
    {
      "Location ID": "52161",
      "Location Name": "OSSIAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 E MAIN ST OSSIAN IA 52161-8132 USA "
    },
    {
      "Location ID": "52162",
      "Location Name": "POSTVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 146 W TILDEN ST POSTVILLE IA 52162-7793 USA "
    },
    {
      "Location ID": "52165",
      "Location Name": "RIDGEWAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 635 RAILROAD ST RIDGEWAY IA 52165-7715 USA "
    },
    {
      "Location ID": "52169",
      "Location Name": "WADENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 124 E 2ND AVE WADENA IA 52169-4400 USA "
    },
    {
      "Location ID": "52172",
      "Location Name": "WAUKON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 W MAIN ST WAUKON IA 52172-1663 USA "
    },
    {
      "Location ID": "52175",
      "Location Name": "WEST UNION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 N VINE ST WEST UNION IA 52175-1269 USA "
    },
    {
      "Location ID": "521AF",
      "Location Name": "AG FARM TOYS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 811 GUNDER ESTATES BLVD CLERMONT IA 52135-8562 USA "
    },
    {
      "Location ID": "521SS",
      "Location Name": "SEED SAVERS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3094 N WINN RD DECORAH IA 52101-9998 USA "
    },
    {
      "Location ID": "521VP",
      "Location Name": "BARRYS MINI MART",
      "Time Zone Offset (Hours)": -1,
      "Address": " 403 W CHESTNUT ST HARPERS FERRY IA 52146-9998 USA "
    },
    {
      "Location ID": "522",
      "Location Name": "CEDAR RAPIDS P&DC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 615 6TH AVE SE CEDAR RAPIDS IA 52401 USA "
    },
    {
      "Location ID": "52201",
      "Location Name": "AINSWORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 N RAILROAD ST AINSWORTH IA 52201 USA "
    },
    {
      "Location ID": "52202",
      "Location Name": "ALBURNETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N MAIN AVE ALBURNETT IA 52202 USA "
    },
    {
      "Location ID": "52203",
      "Location Name": "AMANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4015 G ST AMANA IA 52203 USA "
    },
    {
      "Location ID": "52205",
      "Location Name": "ANAMOSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 S FORD ST ANAMOSA IA 52205 USA "
    },
    {
      "Location ID": "52206",
      "Location Name": "ATKINS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 87 MAIN AVE ATKINS IA 52206 USA "
    },
    {
      "Location ID": "52207",
      "Location Name": "BALDWIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4932 HIGHWAY 64 BALDWIN IA 52207 USA "
    },
    {
      "Location ID": "52208",
      "Location Name": "BELLE PLAINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1008 13TH ST BELLE PLAINE IA 52208 USA "
    },
    {
      "Location ID": "52209",
      "Location Name": "BLAIRSTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 311 LOCUST ST NW BLAIRSTOWN IA 52209 USA "
    },
    {
      "Location ID": "52210",
      "Location Name": "BRANDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 704 MAIN ST BRANDON IA 52210 USA "
    },
    {
      "Location ID": "52211",
      "Location Name": "BROOKLYN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 127 W FRONT ST BROOKLYN IA 52211 USA "
    },
    {
      "Location ID": "52212",
      "Location Name": "CENTER JUNCTION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10612 MAIN ST CENTER JUNCTION IA 52212 USA "
    },
    {
      "Location ID": "52213",
      "Location Name": "CENTER POINT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 708 MAIN ST CENTER POINT IA 52213 USA "
    },
    {
      "Location ID": "52214",
      "Location Name": "CENTRAL CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 55 4TH ST S CENTRAL CITY IA 52214 USA "
    },
    {
      "Location ID": "52215",
      "Location Name": "CHELSEA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2899 HIGHWAY E66 CHELSEA IA 52215 USA "
    },
    {
      "Location ID": "52216",
      "Location Name": "CLARENCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 310 6TH AVE CLARENCE IA 52216 USA "
    },
    {
      "Location ID": "52218",
      "Location Name": "COGGON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 E MAIN ST COGGON IA 52218 USA "
    },
    {
      "Location ID": "52220",
      "Location Name": "CONROY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 572 7TH AVE CONROY IA 52220 USA "
    },
    {
      "Location ID": "52222",
      "Location Name": "DEEP RIVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 MAIN ST DEEP RIVER IA 52222 USA "
    },
    {
      "Location ID": "52223",
      "Location Name": "DELHI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 FRANKLIN ST DELHI IA 52223 USA "
    },
    {
      "Location ID": "52224",
      "Location Name": "DYSART",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 JEFFERSON ST DYSART IA 52224 USA "
    },
    {
      "Location ID": "52225",
      "Location Name": "ELBERON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 MAIN ST ELBERON IA 52225 USA "
    },
    {
      "Location ID": "52227",
      "Location Name": "ELY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1690 DOWS ST ELY IA 52227 USA "
    },
    {
      "Location ID": "52228",
      "Location Name": "FAIRFAX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 418 VANDERBILT ST FAIRFAX IA 52228 USA "
    },
    {
      "Location ID": "52229",
      "Location Name": "GARRISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 PINE ST GARRISON IA 52229 USA "
    },
    {
      "Location ID": "52231",
      "Location Name": "HARPER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 MAIN ST HARPER IA 52231 USA "
    },
    {
      "Location ID": "52232",
      "Location Name": "Hartwick",
      "Time Zone Offset (Hours)": -1,
      "Address": " MAIN ST HARTWICK IA 52232 USA "
    },
    {
      "Location ID": "52233",
      "Location Name": "HIAWATHA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 99 E WILLMAN ST HIAWATHA IA 52223 USA "
    },
    {
      "Location ID": "52235",
      "Location Name": "HILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14 EAST AVE HILLS IA 52235 USA "
    },
    {
      "Location ID": "52237",
      "Location Name": "HOPKINTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 1ST ST SW HOPKINTON IA 52237 USA "
    },
    {
      "Location ID": "52240",
      "Location Name": "IOWA CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 925 HIGHWAY 6 E IOWA CITY IA 52240 USA "
    },
    {
      "Location ID": "52247",
      "Location Name": "KALONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 5TH ST KALONA IA 52247 USA "
    },
    {
      "Location ID": "52248",
      "Location Name": "KEOTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 E BROADWAY AVE KEOTA IA 52248 USA "
    },
    {
      "Location ID": "52249",
      "Location Name": "KEYSTONE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 87 MAIN ST KEYSTONE IA 52249 USA "
    },
    {
      "Location ID": "52251",
      "Location Name": "LADORA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 605 PACIFIC ST LADORA IA 52251 USA "
    },
    {
      "Location ID": "52253",
      "Location Name": "LISBON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 129 W MAIN ST LISBON IA 52253 USA "
    },
    {
      "Location ID": "52254",
      "Location Name": "LOST NATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 415 MAIN ST LOST NATION IA 52254 USA "
    },
    {
      "Location ID": "52255",
      "Location Name": "LOWDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 308 WASHINGTON AVE LOWDEN IA 52255 USA "
    },
    {
      "Location ID": "522AN",
      "Location Name": "CEDAR RAPIDS PARCEL ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4515 20TH AVE SW STE F CEDAR RAPIDS IA 52404 USA "
    },
    {
      "Location ID": "522VD",
      "Location Name": "VETRXDIRECT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1900 JAMES ST STE 11 CORALVILLE IA 52241-1895 USA "
    },
    {
      "Location ID": "522WA",
      "Location Name": "CORALVILLE NEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2150 JAMES ST CORALVILLE IA 52241 USA "
    },
    {
      "Location ID": "522WM",
      "Location Name": "WEST MUSIC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 WESTCOR DR CORALVILLE IA 52241 USA "
    },
    {
      "Location ID": "52301",
      "Location Name": "MARENGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 198 W WASHINGTON ST MARENGO IA 52301 USA "
    },
    {
      "Location ID": "52302",
      "Location Name": "MARION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3800 HIGHWAY 151 MARION IA 52302 USA "
    },
    {
      "Location ID": "52305",
      "Location Name": "MARTELLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 S MILITARY ST MARTELLE IA 52305 USA "
    },
    {
      "Location ID": "52306",
      "Location Name": "MECHANICSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 E 1ST ST MECHANICSVILLE IA 52306 USA "
    },
    {
      "Location ID": "52307",
      "Location Name": "MIDDLE AMANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2516 220TH TRL MIDDLE AMANA IA 52307 USA "
    },
    {
      "Location ID": "52308",
      "Location Name": "MILLERSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 692 WASHINGTON ST MILLERSBURG IA 52308 USA "
    },
    {
      "Location ID": "52309",
      "Location Name": "MONMOUTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 N DIVISION ST MONMOUTH IA 52309 USA "
    },
    {
      "Location ID": "52310",
      "Location Name": "MONTICELLO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 N CEDAR ST MONTICELLO IA 52310 USA "
    },
    {
      "Location ID": "52313",
      "Location Name": "MOUNT AUBURN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 2ND ST MOUNT AUBURN IA 52313 USA "
    },
    {
      "Location ID": "52314",
      "Location Name": "MOUNT VERNON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 124 2ND ST SW MOUNT VERNON IA 52314 USA "
    },
    {
      "Location ID": "52315",
      "Location Name": "NEWHALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11 MAIN ST NEWHALL IA 52315 USA "
    },
    {
      "Location ID": "52316",
      "Location Name": "NORTH ENGLISH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 W OAK ST NORTH ENGLISH IA 52316 USA "
    },
    {
      "Location ID": "52317",
      "Location Name": "NORTH LIBERTY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 75 COMMERCIAL DR NORTH LIBERTY IA 52317 USA "
    },
    {
      "Location ID": "52318",
      "Location Name": "NORWAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 W RAILROAD ST NORWAY IA 52318 USA "
    },
    {
      "Location ID": "52320",
      "Location Name": "OLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 W LOCUST ST OLIN IA 52320 USA "
    },
    {
      "Location ID": "52321",
      "Location Name": "ONSLOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N MAIN ST ONSLOW IA 52321 USA "
    },
    {
      "Location ID": "52322",
      "Location Name": "OXFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E MAIN ST OXFORD IA 52322 USA "
    },
    {
      "Location ID": "52323",
      "Location Name": "OXFORD JUNCTION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 BROADWAY ST OXFORD JUNCTION IA 52323 USA "
    },
    {
      "Location ID": "52324",
      "Location Name": "PALO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 700 1ST ST PALO IA 52324 USA "
    },
    {
      "Location ID": "52325",
      "Location Name": "PARNELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 691 CLEVELAND ST PARNELL IA 52325 USA "
    },
    {
      "Location ID": "52326",
      "Location Name": "QUASQUETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 E DUBUQUE ST QUASQUETON IA 52326 USA "
    },
    {
      "Location ID": "52327",
      "Location Name": "RIVERSIDE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 81 GREENE ST RIVERSIDE IA 52327 USA "
    },
    {
      "Location ID": "52329",
      "Location Name": "ROWLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 ELY ST ROWLEY IA 52329 USA "
    },
    {
      "Location ID": "52330",
      "Location Name": "RYAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 MAIN ST RYAN IA 52330 USA "
    },
    {
      "Location ID": "52332",
      "Location Name": "SHELLSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 PEARL ST SE SHELLSBURG IA 52332 USA "
    },
    {
      "Location ID": "52333",
      "Location Name": "SOLON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1100 S MARKET ST SOLON IA 52333 USA "
    },
    {
      "Location ID": "52334",
      "Location Name": "SOUTH AMANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 515 P ST SOUTH AMANA IA 52334 USA "
    },
    {
      "Location ID": "52335",
      "Location Name": "SOUTH ENGLISH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 IVES ST SOUTH ENGLISH IA 52335 USA "
    },
    {
      "Location ID": "52336",
      "Location Name": "SPRINGVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 262 BROADWAY ST SPRINGVILLE IA 52336 USA "
    },
    {
      "Location ID": "52337",
      "Location Name": "STANWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 E BROADWAY ST STANWOOD IA 52337 USA "
    },
    {
      "Location ID": "52338",
      "Location Name": "SWISHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 DIVISION ST SW SWISHER IA 52338 USA "
    },
    {
      "Location ID": "52339",
      "Location Name": "TAMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 412 STATE ST TAMA IA 52339 USA "
    },
    {
      "Location ID": "52340",
      "Location Name": "TIFFIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 484 STATE ST TIFFIN IA 52340 USA "
    },
    {
      "Location ID": "52342",
      "Location Name": "TOLEDO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 W HIGH ST TOLEDO IA 52342 USA "
    },
    {
      "Location ID": "52345",
      "Location Name": "URBANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 304 W WOOD ST URBANA IA 52345 USA "
    },
    {
      "Location ID": "52346",
      "Location Name": "VAN HORNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 MAIN ST VAN HORNE IA 52346 USA "
    },
    {
      "Location ID": "52347",
      "Location Name": "VICTOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 WASHINGTON ST VICTOR IA 52347 USA "
    },
    {
      "Location ID": "52348",
      "Location Name": "VINING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 1ST ST VINING IA 52348 USA "
    },
    {
      "Location ID": "52349",
      "Location Name": "VINTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 516 1ST AVE VINTON IA 52349 USA "
    },
    {
      "Location ID": "52351",
      "Location Name": "WALFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 HIGHWAY 151 W WALFORD IA 52351 USA "
    },
    {
      "Location ID": "52352",
      "Location Name": "WALKER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 GREENE ST WALKER IA 52352 USA "
    },
    {
      "Location ID": "52353",
      "Location Name": "WASHINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 N MARION AVE WASHINGTON IA 52353 USA "
    },
    {
      "Location ID": "52354",
      "Location Name": "WATKINS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 1ST ST WATKINS IA 52354 USA "
    },
    {
      "Location ID": "52356",
      "Location Name": "WELLMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 310 9TH AVE WELLMAN IA 52356 USA "
    },
    {
      "Location ID": "52358",
      "Location Name": "WEST BRANCH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 PARKSIDE DR WEST BRANCH IA 52358 USA "
    },
    {
      "Location ID": "52361",
      "Location Name": "WILLIAMSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 601 COURT ST WILLIAMSBURG IA 52361 USA "
    },
    {
      "Location ID": "52362",
      "Location Name": "WYOMING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E MAIN ST WYOMING IA 52362 USA "
    },
    {
      "Location ID": "523BB",
      "Location Name": "TANGER MALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 TANGER DR WILLIAMSBURG IA 52361 USA "
    },
    {
      "Location ID": "523CS",
      "Location Name": "CLICKSTOP INC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 BLUE CREEK DR URBANA IA 52345 USA "
    },
    {
      "Location ID": "523FN",
      "Location Name": "FRONTIER NATURAL PRODUCTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3021 78TH ST NORWAY IA 52318 USA "
    },
    {
      "Location ID": "523PJ",
      "Location Name": "PEPPER JOE'S",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 BLUECREEK DR URBANA IA 52345 USA "
    },
    {
      "Location ID": "523PS",
      "Location Name": "PREMIER 1 SHEEP SUPPLIES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2031 300TH ST WASHINGTON IA 52353 USA "
    },
    {
      "Location ID": "523ST",
      "Location Name": "SPOTIX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2810 STONER CT STE 1 NORTH LIBERTY IA 52317 USA "
    },
    {
      "Location ID": "52402",
      "Location Name": "CEDAR RAPIDS NE STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1380 60TH ST NE CEDAR RAPIDS IA 52402 USA "
    },
    {
      "Location ID": "52404",
      "Location Name": "CEDAR RAPIDS WESTSIDE STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1930 WILEY BLVD CEDAR RAPIDS IA 52404 USA "
    },
    {
      "Location ID": "524BI",
      "Location Name": "B H FACTORY OUTLET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4520 20TH AVE SW CEDAR RAPIDS IA 52404 USA "
    },
    {
      "Location ID": "524MS",
      "Location Name": "FISHER GROUP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1250 N CENTER POINT RD HIAWATHA IA 52233 USA "
    },
    {
      "Location ID": "524ND",
      "Location Name": "NORDSTROM DIRECT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7700 18TH ST SW CEDAR RAPIDS IA 52404 USA "
    },
    {
      "Location ID": "525",
      "Location Name": "OTTUMWA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 616 W 2ND ST OTTUMWA IA 52501 USA "
    },
    {
      "Location ID": "52531",
      "Location Name": "ALBIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 BENTON AVE W ALBIA IA 52531 USA "
    },
    {
      "Location ID": "52534",
      "Location Name": "BEACON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 320 KILBOURN ST BEACON IA 52534 USA "
    },
    {
      "Location ID": "52536",
      "Location Name": "BLAKESBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 W STATE ST BLAKESBURG IA 52536 USA "
    },
    {
      "Location ID": "52543",
      "Location Name": "CEDAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2804 HIGHWAY 23 CEDAR IA 52543 USA "
    },
    {
      "Location ID": "52548",
      "Location Name": "CHILLICOTHE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 318 MARKET ST CHILLICOTHE IA 52548 USA "
    },
    {
      "Location ID": "52553",
      "Location Name": "EDDYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 WALNUT ST EDDYVILLE IA 52553 USA "
    },
    {
      "Location ID": "52556",
      "Location Name": "FAIRFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 W BROADWAY AVE FAIRFIELD IA 52556 USA "
    },
    {
      "Location ID": "52561",
      "Location Name": "FREMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 N WALNUT ST FREMONT IA 52561 USA "
    },
    {
      "Location ID": "52563",
      "Location Name": "HEDRICK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 S MAIN ST HEDRICK IA 52563 USA "
    },
    {
      "Location ID": "52566",
      "Location Name": "KIRKVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 S CEDAR ST KIRKVILLE IA 52566 USA "
    },
    {
      "Location ID": "52569",
      "Location Name": "MELROSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 ERIN AVE MELROSE IA 52569 USA "
    },
    {
      "Location ID": "52577",
      "Location Name": "OSKALOOSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 6TH AVE E OSKALOOSA IA 52577 USA "
    },
    {
      "Location ID": "52586",
      "Location Name": "ROSE HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 339 MAIN ST ROSE HILL IA 52586 USA "
    },
    {
      "Location ID": "52591",
      "Location Name": "SIGOURNEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 W WASHINGTON ST SIGOURNEY IA 52591 USA "
    },
    {
      "Location ID": "52595",
      "Location Name": "UNIVERSITY PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2204 9TH AVE E UNIVERSITY PARK IA 52595 USA "
    },
    {
      "Location ID": "526",
      "Location Name": "BURLINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 N MAIN ST STE 100 BURLINGTON IA 52601 USA "
    },
    {
      "Location ID": "52601",
      "Location Name": "BURLINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 N MAIN ST STE 100 BURLINGTON IA 52601 USA "
    },
    {
      "Location ID": "52620",
      "Location Name": "BONAPARTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 626 1ST ST BONAPARTE IA 52620 USA "
    },
    {
      "Location ID": "52621",
      "Location Name": "CRAWFORDSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 E VINE ST CRAWFORDSVILLE IA 52621 USA "
    },
    {
      "Location ID": "52623",
      "Location Name": "DANVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 N ELM ST DANVILLE IA 52623 USA "
    },
    {
      "Location ID": "52624",
      "Location Name": "DENMARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 ACADEMY AVE DENMARK IA 52624 USA "
    },
    {
      "Location ID": "52625",
      "Location Name": "DONNELLSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 516 CHESTNUT ST DONNELLSON IA 52625 USA "
    },
    {
      "Location ID": "52626",
      "Location Name": "FARMINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S FRONT ST FARMINGTON IA 52626 USA "
    },
    {
      "Location ID": "52627",
      "Location Name": "FORT MADISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1019 AVENUE H FORT MADISON IA 52627 USA "
    },
    {
      "Location ID": "52630",
      "Location Name": "HILLSBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 W COMMERCIAL ST HILLSBORO IA 52630 USA "
    },
    {
      "Location ID": "52631",
      "Location Name": "HOUGHTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 525 MAIN ST HOUGHTON IA 52631 USA "
    },
    {
      "Location ID": "52632",
      "Location Name": "KEOKUK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 S 2ND ST KEOKUK IA 52632 USA "
    },
    {
      "Location ID": "52635",
      "Location Name": "LOCKRIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 S CENTER ST LOCKRIDGE IA 52635 USA "
    },
    {
      "Location ID": "52637",
      "Location Name": "MEDIAPOLIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 706 MAIN ST MEDIAPOLIS IA 52637 USA "
    },
    {
      "Location ID": "52638",
      "Location Name": "MIDDLETOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 250 MAIN ST MIDDLETOWN IA 52638 USA "
    },
    {
      "Location ID": "52639",
      "Location Name": "MONTROSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 MAIN ST MONTROSE IA 52639 USA "
    },
    {
      "Location ID": "52640",
      "Location Name": "MORNING SUN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 19 N MAIN ST MORNING SUN IA 52640 USA "
    },
    {
      "Location ID": "52641",
      "Location Name": "MOUNT PLEASANT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 N JEFFERSON ST MOUNT PLEASANT IA 52641 USA "
    },
    {
      "Location ID": "52644",
      "Location Name": "MOUNT UNION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 W RAILROAD ST MOUNT UNION IA 52644 USA "
    },
    {
      "Location ID": "52645",
      "Location Name": "NEW LONDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 S WALNUT ST NEW LONDON IA 52645 USA "
    },
    {
      "Location ID": "52646",
      "Location Name": "OAKVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 505 RUSSELL ST OAKVILLE IA 52646 USA "
    },
    {
      "Location ID": "52647",
      "Location Name": "OLDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 S MAIN ST OLDS IA 52647 USA "
    },
    {
      "Location ID": "52648",
      "Location Name": "PILOT GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 41 PILOT GROVE RD PILOT GROVE IA 52648 USA "
    },
    {
      "Location ID": "52649",
      "Location Name": "SALEM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103A S MAIN ST SALEM IA 52649 USA "
    },
    {
      "Location ID": "52650",
      "Location Name": "SPERRY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2600 SPERRY ROAD SPERRY IA 52650 USA "
    },
    {
      "Location ID": "52651",
      "Location Name": "STOCKPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 S MAIN ST STOCKPORT IA 52651 USA "
    },
    {
      "Location ID": "52652",
      "Location Name": "SWEDESBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 JAMES AVE SWEDESBURG IA 52652 USA "
    },
    {
      "Location ID": "52653",
      "Location Name": "WAPELLO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 N MAIN ST WAPELLO IA 52653 USA "
    },
    {
      "Location ID": "52654",
      "Location Name": "WAYLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 W MAIN ST WAYLAND IA 52654 USA "
    },
    {
      "Location ID": "52655",
      "Location Name": "WEST BURLINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 BROADWAY ST WEST BURLINGTON IA 52655 USA "
    },
    {
      "Location ID": "52656",
      "Location Name": "WEST POINT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 326 4TH ST WEST POINT IA 52656 USA "
    },
    {
      "Location ID": "52658",
      "Location Name": "WEVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1694 354TH AVE WEVER IA 52658 USA "
    },
    {
      "Location ID": "52659",
      "Location Name": "WINFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 W PINE ST WINFIELD IA 52659 USA "
    },
    {
      "Location ID": "527",
      "Location Name": "ROCK ISLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2633 11TH STREET ROCK ISLAND IL 61201 USA "
    },
    {
      "Location ID": "52701",
      "Location Name": "ANDOVER (IA)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4326 MAIN ST ANDOVER IA 52701 USA "
    },
    {
      "Location ID": "52720",
      "Location Name": "ATALISSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 CHERRY ST ATALISSA IA 52720 USA "
    },
    {
      "Location ID": "52721",
      "Location Name": "BENNETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 341 MAIN ST BENNETT IA 52721 USA "
    },
    {
      "Location ID": "52722",
      "Location Name": "BETTENDORF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4439 DEVILS GLEN RD BETTENDORF IA 52722 USA "
    },
    {
      "Location ID": "52726",
      "Location Name": "BLUE GRASS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 TERRACE DR BLUE GRASS IA 52726 USA "
    },
    {
      "Location ID": "52728",
      "Location Name": "BUFFALO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 326 FRONT ST BUFFALO IA 52728 USA "
    },
    {
      "Location ID": "52729",
      "Location Name": "CALAMUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 2ND ST CALAMUS IA 52729 USA "
    },
    {
      "Location ID": "52730",
      "Location Name": "CAMANCHE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 820 S WASHINGTON BLVD CAMANCHE IA 52730 USA "
    },
    {
      "Location ID": "52731",
      "Location Name": "CHARLOTTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 BROADWAY ST CHARLOTTE IA 52731 USA "
    },
    {
      "Location ID": "52732",
      "Location Name": "CLINTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 S 1ST ST CLINTON IA 52732 USA "
    },
    {
      "Location ID": "52738",
      "Location Name": "COLUMBUS JUNCTION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 2ND ST COLUMBUS JUNCTION IA 52738 USA "
    },
    {
      "Location ID": "52739",
      "Location Name": "CONESVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 N TODDS FERRY RD CONESVILLE IA 52739 USA "
    },
    {
      "Location ID": "52742",
      "Location Name": "DE WITT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 618 9TH ST DE WITT IA 52742 USA "
    },
    {
      "Location ID": "52745",
      "Location Name": "DIXON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 502 RAILROAD ST DIXON IA 52745 USA "
    },
    {
      "Location ID": "52746",
      "Location Name": "DONAHUE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 N MAIN ST DONAHUE IA 52746 USA "
    },
    {
      "Location ID": "52747",
      "Location Name": "DURANT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 822 5TH ST DURANT IA 52747 USA "
    },
    {
      "Location ID": "52748",
      "Location Name": "ELDRIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 W LE CLAIRE RD ELDRIDGE IA 52748 USA "
    },
    {
      "Location ID": "52749",
      "Location Name": "FRUITLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 136A NORTH ST FRUITLAND IA 52749 USA "
    },
    {
      "Location ID": "52750",
      "Location Name": "GOOSE LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 228 MAIN ST GOOSE LAKE IA 52750 USA "
    },
    {
      "Location ID": "52751",
      "Location Name": "GRAND MOUND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 EAST ST GRAND MOUND IA 52751 USA "
    },
    {
      "Location ID": "52752",
      "Location Name": "GRANDVIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 S MAIN ST GRANDVIEW IA 52752 USA "
    },
    {
      "Location ID": "52753",
      "Location Name": "LE CLAIRE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 310 JONES ST LE CLAIRE IA 52753 USA "
    },
    {
      "Location ID": "52754",
      "Location Name": "LETTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 S CHERRY ST LETTS IA 52754 USA "
    },
    {
      "Location ID": "52755",
      "Location Name": "LONE TREE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 N DEVOE ST LONE TREE IA 52755 USA "
    },
    {
      "Location ID": "52756",
      "Location Name": "LONG GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 S 1ST ST LONG GROVE IA 52756 USA "
    },
    {
      "Location ID": "52757",
      "Location Name": "LOW MOOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 3RD ST LOW MOOR IA 52757 USA "
    },
    {
      "Location ID": "52758",
      "Location Name": "MC CAUSLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 304 N SALINA ST MC CAUSLAND IA 52758 USA "
    },
    {
      "Location ID": "52760",
      "Location Name": "MOSCOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2476 112TH ST MOSCOW IA 52760 USA "
    },
    {
      "Location ID": "52761",
      "Location Name": "MUSCATINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3100 CEDAR ST MUSCATINE IA 52761 USA "
    },
    {
      "Location ID": "52765",
      "Location Name": "NEW LIBERTY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 345 HWY 227 NEW LIBERTY IA 52765 USA "
    },
    {
      "Location ID": "52766",
      "Location Name": "NICHOLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 IJEM AVE NICHOLS IA 52766 USA "
    },
    {
      "Location ID": "52767",
      "Location Name": "PLEASANT VALLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24641 VALLEY DR PLEASANT VALLEY IA 52767 USA "
    },
    {
      "Location ID": "52768",
      "Location Name": "PRINCETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 634 HIGHWAY 67 PRINCETON IA 52768 USA "
    },
    {
      "Location ID": "52769",
      "Location Name": "STOCKTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 318 COMMERCE ST STOCKTON IA 52769 USA "
    },
    {
      "Location ID": "52772",
      "Location Name": "TIPTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 512 LYNN ST TIPTON IA 52772 USA "
    },
    {
      "Location ID": "52773",
      "Location Name": "WALCOTT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 E BRYANT ST WALCOTT IA 52773 USA "
    },
    {
      "Location ID": "52774",
      "Location Name": "WELTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 502 MAIN ST WELTON IA 52774 USA "
    },
    {
      "Location ID": "52776",
      "Location Name": "WEST LIBERTY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 N COLUMBUS ST WEST LIBERTY IA 52776 USA "
    },
    {
      "Location ID": "52777",
      "Location Name": "WHEATLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 S MAIN ST WHEATLAND IA 52777 USA "
    },
    {
      "Location ID": "52778",
      "Location Name": "WILTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 E 4TH ST WILTON IA 52778 USA "
    },
    {
      "Location ID": "527CB",
      "Location Name": "COLONY BRANDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2000 HARRISON DR STE 100 CLINTON IA 52732 USA "
    },
    {
      "Location ID": "527CD",
      "Location Name": "CDS (WILTON)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1419 W 5TH ST WILTON IA 52778 USA "
    },
    {
      "Location ID": "527DS",
      "Location Name": "CDS (TIPTON)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 411 E SOUTH ST TIPTON IA 52772 USA "
    },
    {
      "Location ID": "527NE",
      "Location Name": "BRAND NEW ENGINES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 601 W SOUTH ST TIPTON IA 52772 USA "
    },
    {
      "Location ID": "527PF",
      "Location Name": "PFC: PROMOTION FULFILLMENT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 311 21ST ST CAMANCHE IA 52730 USA "
    },
    {
      "Location ID": "527VP",
      "Location Name": "WELTON VILLAGE POST OFFICE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 333 WELTON AVE WELTON IA 52774 USA "
    },
    {
      "Location ID": "528",
      "Location Name": "DAVENPORT MAIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 933 W 2ND ST DAVENPORT IA 52802 USA "
    },
    {
      "Location ID": "52806",
      "Location Name": "DAVENPORT NW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4018 MARQUETTE ST DAVENPORT IA 52806 USA "
    },
    {
      "Location ID": "528MA",
      "Location Name": "MID AMERICAN ENERGY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 E 2ND ST DAVENPORT IA 52801 USA "
    },
    {
      "Location ID": "530",
      "Location Name": "LOG MILWAUKEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 345 W SAINT PAUL AVE MILWAUKEE WI 53203 USA "
    },
    {
      "Location ID": "53002",
      "Location Name": "ALLENTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 330 1ST ST ALLENTON WI 53002 USA "
    },
    {
      "Location ID": "53007",
      "Location Name": "BUTLER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12420 W HAMPTON AVE BUTLER WI 53007 USA "
    },
    {
      "Location ID": "53010",
      "Location Name": "CAMPBELLSPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 324 N HELENA ST CAMPBELLSPORT WI 53010 USA "
    },
    {
      "Location ID": "53012",
      "Location Name": "CEDARBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " W63N605 HANOVER AVE CEDARBURG WI 53012 USA "
    },
    {
      "Location ID": "53014",
      "Location Name": "CHILTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 57 E MAIN ST CHILTON WI 53014 USA "
    },
    {
      "Location ID": "53017",
      "Location Name": "COLGATE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3392 COUNTY LINE RD COLGATE WI 53017 USA "
    },
    {
      "Location ID": "53018",
      "Location Name": "DELAFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 925 GENESEE ST DELAFIELD WI 53018 USA "
    },
    {
      "Location ID": "53019",
      "Location Name": "EDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 SHERIDAN ST EDEN WI 53019 USA "
    },
    {
      "Location ID": "53020",
      "Location Name": "ELKHART LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 146 E RHINE ST ELKHART LAKE WI 53020 USA "
    },
    {
      "Location ID": "53022",
      "Location Name": "GERMANTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " W156N11301 PILGRIM RD GERMANTOWN WI 53022 USA "
    },
    {
      "Location ID": "53024",
      "Location Name": "GRAFTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1817 HIGHLAND DR GRAFTON WI 53024 USA "
    },
    {
      "Location ID": "53027",
      "Location Name": "HARTFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 45 E WISCONSIN ST HARTFORD WI 53027 USA "
    },
    {
      "Location ID": "53029",
      "Location Name": "HARTLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 E INDUSTRIAL DR HARTLAND WI 53029 USA "
    },
    {
      "Location ID": "53032",
      "Location Name": "HORICON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 420 E LAKE ST HORICON WI 53032 USA "
    },
    {
      "Location ID": "53033",
      "Location Name": "HUBERTUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3695 HUBERTUS RD HUBERTUS WI 53033 USA "
    },
    {
      "Location ID": "53036",
      "Location Name": "IXONIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " W1235 MARIETTA AVE IXONIA WI 53036 USA "
    },
    {
      "Location ID": "53037",
      "Location Name": "JACKSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " W225N16702 CEDAR PARK CT JACKSON WI 53037 USA "
    },
    {
      "Location ID": "53040",
      "Location Name": "KEWASKUM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 347 MAIN ST KEWASKUM WI 53040 USA "
    },
    {
      "Location ID": "53042",
      "Location Name": "KIEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 FREMONT ST KIEL WI 53042 USA "
    },
    {
      "Location ID": "53048",
      "Location Name": "LOMIRA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 827 RAILROAD AVE LOMIRA WI 53048 USA "
    },
    {
      "Location ID": "53051",
      "Location Name": "MENOMONEE FALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " N85W16110 APPLETON AVE MENOMONEE FALLS WI 53051 USA "
    },
    {
      "Location ID": "53058",
      "Location Name": "NASHOTAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " W330N4361 LAKELAND DR NASHOTAH WI 53058 USA "
    },
    {
      "Location ID": "53061",
      "Location Name": "NEW HOLSTEIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1816 WISCONSIN AVE NEW HOLSTEIN WI 53061 USA "
    },
    {
      "Location ID": "53065",
      "Location Name": "OAKFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 BOOTH ST OAKFIELD WI 53065 USA "
    },
    {
      "Location ID": "53066",
      "Location Name": "OCONOMOWOC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 38 S MAIN ST OCONOMOWOC WI 53066 USA "
    },
    {
      "Location ID": "53069",
      "Location Name": "OKAUCHEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " N50W35013 WISCONSIN AVE OKAUCHEE WI 53069 USA "
    },
    {
      "Location ID": "53070",
      "Location Name": "OOSTBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1508 CENTER AVE OOSTBURG WI 53070 USA "
    },
    {
      "Location ID": "53072",
      "Location Name": "PEWAUKEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 140 SIMMONS AVE PEWAUKEE WI 53072 USA "
    },
    {
      "Location ID": "53073",
      "Location Name": "PLYMOUTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 E MAIN ST PLYMOUTH WI 53073 USA "
    },
    {
      "Location ID": "53074",
      "Location Name": "PORT WASHINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 E MAIN ST PORT WASHINGTON WI 53074 USA "
    },
    {
      "Location ID": "53076",
      "Location Name": "RICHFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1925 STATE ROAD 175 RICHFIELD WI 53076 USA "
    },
    {
      "Location ID": "53080",
      "Location Name": "SAUKVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 600 W DEKORA ST SAUKVILLE WI 53080 USA "
    },
    {
      "Location ID": "53081",
      "Location Name": "SHEBOYGAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 522 N 9TH ST SHEBOYGAN WI 53081 USA "
    },
    {
      "Location ID": "53086",
      "Location Name": "SLINGER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 350 E WASHINGTON ST SLINGER WI 53086 USA "
    },
    {
      "Location ID": "53088",
      "Location Name": "STOCKBRIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 S MILITARY RD STOCKBRIDGE WI 53088 USA "
    },
    {
      "Location ID": "53089",
      "Location Name": "SUSSEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " N63W23565 SILVER SPRING DR SUSSEX WI 53089 USA "
    },
    {
      "Location ID": "53092",
      "Location Name": "THIENSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 124 W FREISTADT RD THIENSVILLE WI 53092 USA "
    },
    {
      "Location ID": "53094",
      "Location Name": "WATERTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 411 DODGE ST WATERTOWN WI 53094 USA "
    },
    {
      "Location ID": "53095",
      "Location Name": "WEST BEND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 607 ELM ST WEST BEND WI 53095 USA "
    },
    {
      "Location ID": "530PC",
      "Location Name": "LOG MILWAUKEE PRIORITY ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7620 S 10TH ST OAK CREEK WI 53154 USA "
    },
    {
      "Location ID": "530QG",
      "Location Name": "QUAD GRAPHICS - DIRECT MARKETING",
      "Time Zone Offset (Hours)": -1,
      "Address": " W224N3322 DUPLAINVILLE RD PEWAUKEE WI 53072 USA "
    },
    {
      "Location ID": "530QH",
      "Location Name": "QUAD GRAPHICS INC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1900 W SUMNER ST HARTFORD WI 53027 USA "
    },
    {
      "Location ID": "530QL",
      "Location Name": "QUAD GRAPHICS",
      "Time Zone Offset (Hours)": -1,
      "Address": " N11896 HIGHWAY 175 LOMIRA WI 53048 USA "
    },
    {
      "Location ID": "530QS",
      "Location Name": "QUAD GRAPHICS SUSSEX WI",
      "Time Zone Offset (Hours)": -1,
      "Address": " N63W23075 STATE HIGHWAY 74 SUSSEX WI 53089 USA "
    },
    {
      "Location ID": "530RS",
      "Location Name": "ARANDELL-SCHMIDT",
      "Time Zone Offset (Hours)": -1,
      "Address": " N82W13118 LEON RD MENOMONEE FALLS WI 53051 USA "
    },
    {
      "Location ID": "530TP",
      "Location Name": "TIMES PRINTING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 INDUSTRIAL DR RANDOM LAKE WI 53075 USA "
    },
    {
      "Location ID": "530UM",
      "Location Name": "UNITED MAILING SERVICES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3625 N 126TH ST STE A BROOKFIELD WI 53005 USA "
    },
    {
      "Location ID": "530WE",
      "Location Name": "WESTERN PUBLISHING  WI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1220 MOUND AVE RACINE WI 53404 USA "
    },
    {
      "Location ID": "53101",
      "Location Name": "BASSETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 34241 BASSETT RD BASSETT WI 53101 USA "
    },
    {
      "Location ID": "53103",
      "Location Name": "BIG BEND",
      "Time Zone Offset (Hours)": -1,
      "Address": " S87W22530 EDGEWOOD AVE BIG BEND WI 53103 USA "
    },
    {
      "Location ID": "53104",
      "Location Name": "BRISTOL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8223 199TH AVE BRISTOL WI 53104 USA "
    },
    {
      "Location ID": "53105",
      "Location Name": "BURLINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 S PINE ST BURLINGTON WI 53105 USA "
    },
    {
      "Location ID": "53108",
      "Location Name": "CALEDONIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11510 COUNTY ROAD G CALEDONIA WI 53108 USA "
    },
    {
      "Location ID": "53109",
      "Location Name": "CAMP LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9540 CAMP LAKE RD CAMP LAKE WI 53109 USA "
    },
    {
      "Location ID": "53114",
      "Location Name": "DARIEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 E BELOIT ST DARIEN WI 53114 USA "
    },
    {
      "Location ID": "53115",
      "Location Name": "DELAVAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 335 E WALWORTH AVE DELAVAN WI 53115 USA "
    },
    {
      "Location ID": "53120",
      "Location Name": "EAST TROY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2099 MILL ST EAST TROY WI 53120 USA "
    },
    {
      "Location ID": "53121",
      "Location Name": "ELKHORN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 E WALWORTH ST ELKHORN WI 53121 USA "
    },
    {
      "Location ID": "53125",
      "Location Name": "FONTANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 185 THIRD AVE FONTANA WI 53125 USA "
    },
    {
      "Location ID": "53126",
      "Location Name": "FRANKSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3319 ROBERTS ST FRANKSVILLE WI 53126 USA "
    },
    {
      "Location ID": "53127",
      "Location Name": "GENESEE DEPOT",
      "Time Zone Offset (Hours)": -1,
      "Address": " S42W31230 STATE ROAD 83 GENESEE DEPOT WI 53127 USA "
    },
    {
      "Location ID": "53128",
      "Location Name": "GENOA CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 742 WALWORTH ST GENOA CITY WI 53128 USA "
    },
    {
      "Location ID": "53129",
      "Location Name": "GREENDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5741 BROAD ST GREENDALE WI 53129 USA "
    },
    {
      "Location ID": "53132",
      "Location Name": "FRANKLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9575 BRENWOOD PARK DR FRANKLIN WI 53132 USA "
    },
    {
      "Location ID": "53139",
      "Location Name": "KANSASVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3825 S BEAUMONT AVE KANSASVILLE WI 53139 USA "
    },
    {
      "Location ID": "53147",
      "Location Name": "LAKE GENEVA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 672 W MAIN ST LAKE GENEVA WI 53147 USA "
    },
    {
      "Location ID": "53149",
      "Location Name": "MUKWONAGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 911 GREENRIDGE CTR MUKWONAGO WI 53149 USA "
    },
    {
      "Location ID": "53150",
      "Location Name": "MUSKEGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " S74W16860 JANESVILLE RD MUSKEGO WI 53150 USA "
    },
    {
      "Location ID": "53152",
      "Location Name": "NEW MUNSTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 34315 GENEVA RD NEW MUNSTER WI 53152 USA "
    },
    {
      "Location ID": "53153",
      "Location Name": "NORTH PRAIRIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 N HARRISON ST NORTH PRAIRIE WI 53153 USA "
    },
    {
      "Location ID": "53157",
      "Location Name": "PELL LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " N1265 PARK RD PELL LAKE WI 53157 USA "
    },
    {
      "Location ID": "53159",
      "Location Name": "POWERS LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 39922 BLOOMFIELD RD POWERS LAKE WI 53159 USA "
    },
    {
      "Location ID": "53168",
      "Location Name": "SALEM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24913 83RD PL SALEM WI 53168 USA "
    },
    {
      "Location ID": "53170",
      "Location Name": "SILVER LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 739 S COGSWELL DR SILVER LAKE WI 53170 USA "
    },
    {
      "Location ID": "53177",
      "Location Name": "STURTEVANT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2849 WISCONSIN ST STURTEVANT WI 53177 USA "
    },
    {
      "Location ID": "53181",
      "Location Name": "TWIN LAKES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 170 LANCE DR TWIN LAKES WI 53181 USA "
    },
    {
      "Location ID": "53182",
      "Location Name": "UNION GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 830 MAIN ST UNION GROVE WI 53182 USA "
    },
    {
      "Location ID": "53183",
      "Location Name": "WALES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 JAMES ST WALES WI 53183 USA "
    },
    {
      "Location ID": "53184",
      "Location Name": "WALWORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 N MAIN ST WALWORTH WI 53184 USA "
    },
    {
      "Location ID": "53185",
      "Location Name": "WATERFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 218 N MILWAUKEE ST WATERFORD WI 53185 USA "
    },
    {
      "Location ID": "53190",
      "Location Name": "WHITEWATER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 213 W CENTER ST WHITEWATER WI 53190 USA "
    },
    {
      "Location ID": "53191",
      "Location Name": "WILLIAMS BAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 129 N WALWORTH AVE WILLIAMS BAY WI 53191 USA "
    },
    {
      "Location ID": "53194",
      "Location Name": "WOODWORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8105 160TH AVE WOODWORTH WI 53194 USA "
    },
    {
      "Location ID": "531AB",
      "Location Name": "AMERICAN BUSINESS TECHNOLOGIES",
      "Time Zone Offset (Hours)": -1,
      "Address": " N1977 SCHAITEL RD LAKE GENEVA WI 53147 USA "
    },
    {
      "Location ID": "531HG",
      "Location Name": "HIGHLIGHTER GRAPHICS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 700 BLACKHAWK DR BURLINGTON WI 53105 USA "
    },
    {
      "Location ID": "531MK",
      "Location Name": "MKE5",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11211 BURLINGTON RD KENOSHA WI 53144 USA "
    },
    {
      "Location ID": "531PA",
      "Location Name": "MILWAUKEE PEAK SEASON ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2200 E COLLEGE AVE CUDAHY WI 53110 USA "
    },
    {
      "Location ID": "531PC",
      "Location Name": "MILWAUKEE WI ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2201 E COLLEGE AVE OAK CREEK WI 53154 USA "
    },
    {
      "Location ID": "531PS",
      "Location Name": "MILWAUKEE PEAK SEASON ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2100 E COLLEGE AVE CUDAHY WI 53110 USA "
    },
    {
      "Location ID": "531QD",
      "Location Name": "QUAD DIRECT MARKETING COMMINGLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " W228N2801 DUPLAINVILLE RD WAUKESHA WI 53186 USA "
    },
    {
      "Location ID": "531QN",
      "Location Name": "531QN FEDEX SMART POST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16555 W ROGERS DR NEW BERLIN WI 53151 USA "
    },
    {
      "Location ID": "531WP",
      "Location Name": "WIPERS 1-2-3",
      "Time Zone Offset (Hours)": -1,
      "Address": " 813 FOX LN WATERFORD WI 53185 USA "
    },
    {
      "Location ID": "53203",
      "Location Name": "MILWAUKEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 345 W SAINT PAUL AVE MILWAUKEE WI 53203 USA "
    },
    {
      "Location ID": "532AD",
      "Location Name": "ADV0",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5172 S 13TH ST MILWAUKEE WI 53221 USA "
    },
    {
      "Location ID": "532CF",
      "Location Name": "CARGO FORCE - UPS - MKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 430 W GRANGE AVE STE 100 MILWAUKEE WI 53207 USA "
    },
    {
      "Location ID": "532II",
      "Location Name": "INTEGRATED MAIL IND(MILWAUKEE), WI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3450 W HOPKINS ST MILWAUKEE WI 53216 USA "
    },
    {
      "Location ID": "532MS",
      "Location Name": "MPS SERVICES",
      "Time Zone Offset (Hours)": -1,
      "Address": " N56 W13500 SILVER SPRING RD MENOMENEE FALLS WI 53201 USA "
    },
    {
      "Location ID": "532MT",
      "Location Name": "MAJESTIC TERMINAL SERVICES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 311 AIR CARGO WAY MILWAUKEE WI 53207 USA "
    },
    {
      "Location ID": "532QW",
      "Location Name": "QUAD WEST ALLIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 555 S 108TH ST MILWAUKEE WI 53214 USA "
    },
    {
      "Location ID": "532US",
      "Location Name": "US BANCORP - STATEMENT SERVICES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 777 E WISCONSIN AVE BSMT BSMT MILWAUKEE WI 53202 USA "
    },
    {
      "Location ID": "535",
      "Location Name": "LOG MADISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3902 MILWAUKEE ST MADISON WI 53714 USA "
    },
    {
      "Location ID": "53523",
      "Location Name": "CAMBRIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 PARK ST CAMBRIDGE WI 53523 USA "
    },
    {
      "Location ID": "53526",
      "Location Name": "COBB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 DIVISION ST COBB WI 53526 USA "
    },
    {
      "Location ID": "53527",
      "Location Name": "COTTAGE GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 451 W COTTAGE GROVE RD COTTAGE GROVE WI 53527 USA "
    },
    {
      "Location ID": "53531",
      "Location Name": "DEERFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16 W NELSON ST DEERFIELD WI 53531 USA "
    },
    {
      "Location ID": "53533",
      "Location Name": "DODGEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1121 N JOHNS ST DODGEVILLE WI 53533 USA "
    },
    {
      "Location ID": "53534",
      "Location Name": "EDGERTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 SWIFT ST EDGERTON WI 53534 USA "
    },
    {
      "Location ID": "53535",
      "Location Name": "EDMUND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3156 N MAIN ST EDMUND WI 53535 USA "
    },
    {
      "Location ID": "53538",
      "Location Name": "FORT ATKINSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24 MILWAUKEE AVE E FORT ATKINSON WI 53538 USA "
    },
    {
      "Location ID": "53543",
      "Location Name": "HIGHLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 803 DODGEVILLE ST HIGHLAND WI 53543 USA "
    },
    {
      "Location ID": "53549",
      "Location Name": "JEFFERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 122 S CENTER AVE JEFFERSON WI 53549 USA "
    },
    {
      "Location ID": "53551",
      "Location Name": "LAKE MILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 155 E OAK ST LAKE MILLS WI 53551 USA "
    },
    {
      "Location ID": "53553",
      "Location Name": "LINDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 509 MAIN ST LINDEN WI 53553 USA "
    },
    {
      "Location ID": "53554",
      "Location Name": "LIVINGSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 295 N CENTER ST LIVINGSTON WI 53554 USA "
    },
    {
      "Location ID": "53558",
      "Location Name": "MCFARLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5315 LONG ST MCFARLAND WI 53558 USA "
    },
    {
      "Location ID": "53563",
      "Location Name": "MILTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 HILLTOP DR MILTON WI 53563 USA "
    },
    {
      "Location ID": "53569",
      "Location Name": "MONTFORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 S FOUNTAIN ST MONTFORT WI 53569 USA "
    },
    {
      "Location ID": "53580",
      "Location Name": "REWEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 511 MAIN ST REWEY WI 53580 USA "
    },
    {
      "Location ID": "53589",
      "Location Name": "STOUGHTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 246 E MAIN ST STOUGHTON WI 53589 USA "
    },
    {
      "Location ID": "53590",
      "Location Name": "SUN PRAIRIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1715 LINNERUD DR SUN PRAIRIE WI 53590 USA "
    },
    {
      "Location ID": "535AA",
      "Location Name": "STOUGHTON CARRIER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 541 BUSINESS PARK CIR STOUGHTON WI 53589 USA "
    },
    {
      "Location ID": "535EA",
      "Location Name": "VORTEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 VORTEX DR BARNEVELD WI 53507 USA "
    },
    {
      "Location ID": "535FG",
      "Location Name": "FREEDOM GRAPHIC SYSTEMS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1475 E HIGH ST MILTON WI 53563 USA "
    },
    {
      "Location ID": "535LA",
      "Location Name": "LAND'S END",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 LANDS END LN DODGEVILLE WI 53595 USA "
    },
    {
      "Location ID": "535OP",
      "Location Name": "OPPORTUNITIES INC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 E BLACKHAWK DR FORT ATKINSON WI 53538 USA "
    },
    {
      "Location ID": "535SS",
      "Location Name": "SUTTLE STRAUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1000 UNIEK DR WAUNAKEE WI 53597 USA "
    },
    {
      "Location ID": "53703",
      "Location Name": "CAPITOL ANNEX DELIVERY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 702 E WASHINGTON AVE LOWR MADISON WI 53703 USA "
    },
    {
      "Location ID": "53801",
      "Location Name": "BAGLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 140 S BAGLEY AVE BAGLEY WI 53801 USA "
    },
    {
      "Location ID": "53802",
      "Location Name": "BEETOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9502 MUSCALLOUNGE RD BEETOWN WI 53802 USA "
    },
    {
      "Location ID": "53806",
      "Location Name": "CASSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 E AMELIA ST CASSVILLE WI 53806 USA "
    },
    {
      "Location ID": "53809",
      "Location Name": "FENNIMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1090 12TH ST FENNIMORE WI 53809 USA "
    },
    {
      "Location ID": "53810",
      "Location Name": "GLEN HAVEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13211 MAIN ST GLEN HAVEN WI 53810 USA "
    },
    {
      "Location ID": "53825",
      "Location Name": "STITZER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4011 COMMERCIAL ST STE 1 STITZER WI 53825 USA "
    },
    {
      "Location ID": "539",
      "Location Name": "PORTAGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 W CONANT ST PORTAGE WI 53901 USA "
    },
    {
      "Location ID": "53901",
      "Location Name": "PORTAGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 W CONANT ST PORTAGE WI 53901 USA "
    },
    {
      "Location ID": "53910",
      "Location Name": "ADAMS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 151 S GRANT ST ADAMS WI 53910 USA "
    },
    {
      "Location ID": "53911",
      "Location Name": "ARLINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 MAIN ST ARLINGTON WI 53911 USA "
    },
    {
      "Location ID": "53913",
      "Location Name": "BARABOO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 BROADWAY ST BARABOO WI 53913 USA "
    },
    {
      "Location ID": "53919",
      "Location Name": "BRANDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 N COMMERCIAL ST BRANDON WI 53919 USA "
    },
    {
      "Location ID": "53920",
      "Location Name": "BRIGGSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " N433 COUNTY ROAD A BRIGGSVILLE WI 53920 USA "
    },
    {
      "Location ID": "53924",
      "Location Name": "CAZENOVIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 E MAIN ST CAZENOVIA WI 53924 USA "
    },
    {
      "Location ID": "53925",
      "Location Name": "COLUMBUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 S DICKASON BLVD COLUMBUS WI 53925 USA "
    },
    {
      "Location ID": "53926",
      "Location Name": "DALTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " N755 COUNTY ROAD FF DALTON WI 53926 USA "
    },
    {
      "Location ID": "53928",
      "Location Name": "DOYLESTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " W2979 RAILROAD ST DOYLESTOWN WI 53928 USA "
    },
    {
      "Location ID": "53929",
      "Location Name": "ELROY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 266 MAIN ST ELROY WI 53929 USA "
    },
    {
      "Location ID": "53930",
      "Location Name": "ENDEAVOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 PARK ST ENDEAVOR WI 53930 USA "
    },
    {
      "Location ID": "53931",
      "Location Name": "FAIRWATER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 409 MAIN ST FAIRWATER WI 53931 USA "
    },
    {
      "Location ID": "53932",
      "Location Name": "FALL RIVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 STEEL RD FALL RIVER WI 53932 USA "
    },
    {
      "Location ID": "53934",
      "Location Name": "FRIENDSHIP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 315 MAIN ST FRIENDSHIP WI 53934 USA "
    },
    {
      "Location ID": "53936",
      "Location Name": "GRAND MARSH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 644 COUNTY RD E GRAND MARSH WI 53936 USA "
    },
    {
      "Location ID": "53937",
      "Location Name": "HILLPOINT",
      "Time Zone Offset (Hours)": -1,
      "Address": " S6071 STATE ROAD 154 HILLPOINT WI 53937 USA "
    },
    {
      "Location ID": "53939",
      "Location Name": "KINGSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 N SOUTH ST KINGSTON WI 53939 USA "
    },
    {
      "Location ID": "53940",
      "Location Name": "LAKE DELTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 49 E ADAMS ST LAKE DELTON WI 53940 USA "
    },
    {
      "Location ID": "53941",
      "Location Name": "LA VALLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 E MAIN ST LA VALLE WI 53941 USA "
    },
    {
      "Location ID": "53942",
      "Location Name": "LIME RIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 N MAIN ST LIME RIDGE WI 53942 USA "
    },
    {
      "Location ID": "53943",
      "Location Name": "LOGANVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 245 WALNUT ST LOGANVILLE WI 53943 USA "
    },
    {
      "Location ID": "53944",
      "Location Name": "LYNDON STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 E FLINT ST LYNDON STATION WI 53944 USA "
    },
    {
      "Location ID": "53946",
      "Location Name": "MARKESAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 735 N MARGARET ST MARKESAN WI 53946 USA "
    },
    {
      "Location ID": "53947",
      "Location Name": "MARQUETTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 126 S LYONS ST MARQUETTE WI 53947 USA "
    },
    {
      "Location ID": "53948",
      "Location Name": "MAUSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 536 LA CROSSE ST MAUSTON WI 53948 USA "
    },
    {
      "Location ID": "53949",
      "Location Name": "MONTELLO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 E MONTELLO ST MONTELLO WI 53949 USA "
    },
    {
      "Location ID": "53950",
      "Location Name": "NEW LISBON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 N ADAMS ST NEW LISBON WI 53950 USA "
    },
    {
      "Location ID": "53951",
      "Location Name": "NORTH FREEDOM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 E WALNUT ST NORTH FREEDOM WI 53951 USA "
    },
    {
      "Location ID": "53952",
      "Location Name": "OXFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 E ORMSBY ST OXFORD WI 53952 USA "
    },
    {
      "Location ID": "53953",
      "Location Name": "PACKWAUKEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " W5981 MAIN ST PACKWAUKEE WI 53953 USA "
    },
    {
      "Location ID": "53954",
      "Location Name": "PARDEEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 INDUSTRIAL DR PARDEEVILLE WI 53954 USA "
    },
    {
      "Location ID": "53955",
      "Location Name": "POYNETTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 128 E SEWARD ST POYNETTE WI 53955 USA "
    },
    {
      "Location ID": "53959",
      "Location Name": "REEDSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 N WALNUT ST REEDSBURG WI 53959 USA "
    },
    {
      "Location ID": "53960",
      "Location Name": "RIO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 LINCOLN AVE RIO WI 53960 USA "
    },
    {
      "Location ID": "53961",
      "Location Name": "ROCK SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 W BROADWAY ROCK SPRINGS WI 53961 USA "
    },
    {
      "Location ID": "53962",
      "Location Name": "UNION CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 335 HIGH ST UNION CENTER WI 53962 USA "
    },
    {
      "Location ID": "53964",
      "Location Name": "WESTFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 E 2ND ST WESTFIELD WI 53964 USA "
    },
    {
      "Location ID": "53965",
      "Location Name": "WISCONSIN DELLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 310 MINNESOTA AVE WISCONSIN DELLS WI 53965 USA "
    },
    {
      "Location ID": "53968",
      "Location Name": "WONEWOC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 227 CENTER ST WONEWOC WI 53968 USA "
    },
    {
      "Location ID": "53969",
      "Location Name": "WYOCENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 230 S WASHINGTON ST WYOCENA WI 53969 USA "
    },
    {
      "Location ID": "539CP",
      "Location Name": "JB KENEHAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 555 BEICHL AVE BEAVER DAM WI 53916 USA "
    },
    {
      "Location ID": "539LE",
      "Location Name": "LAND'S END",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1701 LANDS END DR REEDSBURG WI 53959 USA "
    },
    {
      "Location ID": "539SP",
      "Location Name": "SCHUMANN PRINTERS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 701 S MAIN ST FALL RIVER WI 53932 USA "
    },
    {
      "Location ID": "539WD",
      "Location Name": "WISCONSIN DELLS CON BUREAUU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 WISCONSIN AVE WISCONSIN DELLS WI 53965 USA "
    },
    {
      "Location ID": "53ANX",
      "Location Name": "(HAPA) HOWELL AVE POSTAL ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5500 S HOWELL AVE MILWAUKEE WI 53207 USA "
    },
    {
      "Location ID": "54016",
      "Location Name": "HUDSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 808 HEGGEN ST HUDSON WI 54016 USA "
    },
    {
      "Location ID": "541",
      "Location Name": "LOG GREEN BAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 PACKERLAND DR GREEN BAY WI 54303 USA "
    },
    {
      "Location ID": "54107",
      "Location Name": "BONDUEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 128 N CHURCH ST BONDUEL WI 54107 USA "
    },
    {
      "Location ID": "54111",
      "Location Name": "CECIL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 E FREEBORN ST CECIL WI 54111 USA "
    },
    {
      "Location ID": "54121",
      "Location Name": "FLORENCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 540 CENTRAL AVE FLORENCE WI 54121 USA "
    },
    {
      "Location ID": "54128",
      "Location Name": "GRESHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1201 MAIN ST GRESHAM WI 54128 USA "
    },
    {
      "Location ID": "54130",
      "Location Name": "KAUKAUNA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 DOTY ST KAUKAUNA WI 54130 USA "
    },
    {
      "Location ID": "54135",
      "Location Name": "KESHENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " W3210 WOLF RIVER RD KESHENA WI 54135 USA "
    },
    {
      "Location ID": "54139",
      "Location Name": "LENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 133 E RAILROAD ST LENA WI 54139 USA "
    },
    {
      "Location ID": "54150",
      "Location Name": "NEOPIT",
      "Time Zone Offset (Hours)": -1,
      "Address": " W6786 CHURCH ST NEOPIT WI 54150 USA "
    },
    {
      "Location ID": "54151",
      "Location Name": "NIAGARA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 617 WASHINGTON AVE NIAGARA WI 54151 USA "
    },
    {
      "Location ID": "541IW",
      "Location Name": "IWC WAREHOUSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 821 PARKVIEW RD GREEN BAY WI 54304 USA "
    },
    {
      "Location ID": "541RG",
      "Location Name": "RR DONNELLEY RESPONSE MRKTG DEPERE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1333 SCHEURING RD DE PERE WI 54115 USA "
    },
    {
      "Location ID": "543MR",
      "Location Name": "RR DONNELLEY - GREENVILLE WI",
      "Time Zone Offset (Hours)": -1,
      "Address": " W6545 QUALITY DR GREENVILLE WI 54942 USA "
    },
    {
      "Location ID": "543RG",
      "Location Name": "MOORE RESPONSE GRAPHICS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2660 S BROADWAY GREEN BAY WI 54304 USA "
    },
    {
      "Location ID": "544",
      "Location Name": "LOG WAUSAU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 CRESKE AVE ROTHSCHILD WI 54474 USA "
    },
    {
      "Location ID": "54401",
      "Location Name": "WAUSAU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 235 FOREST ST WAUSAU WI 54403 USA "
    },
    {
      "Location ID": "54405",
      "Location Name": "ABBOTSFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 N 2ND ST ABBOTSFORD WI 54405 USA "
    },
    {
      "Location ID": "54408",
      "Location Name": "ANIWA",
      "Time Zone Offset (Hours)": -1,
      "Address": " W19057 1ST ST ANIWA WI 54408 USA "
    },
    {
      "Location ID": "54409",
      "Location Name": "ANTIGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 CLERMONT ST ANTIGO WI 54409 USA "
    },
    {
      "Location ID": "54412",
      "Location Name": "AUBURNDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10548 GEORGE AVE AUBURNDALE WI 54412 USA "
    },
    {
      "Location ID": "54413",
      "Location Name": "BABCOCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1608 STATE HIGHWAY 80 BABCOCK WI 54413 USA "
    },
    {
      "Location ID": "54415",
      "Location Name": "BLENKER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3888 COUNTY ROAD P BLENKER WI 54415 USA "
    },
    {
      "Location ID": "54420",
      "Location Name": "CHILI",
      "Time Zone Offset (Hours)": -1,
      "Address": " W931 CHILI RD CHILI WI 54420 USA "
    },
    {
      "Location ID": "54422",
      "Location Name": "CURTISS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 915 MERIDIAN ST CURTISS WI 54422 USA "
    },
    {
      "Location ID": "54424",
      "Location Name": "DEERBROOK",
      "Time Zone Offset (Hours)": -1,
      "Address": " N5290 COUNTY ROAD A DEERBROOK WI 54424 USA "
    },
    {
      "Location ID": "54426",
      "Location Name": "EDGAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 W BEECH ST EDGAR WI 54426 USA "
    },
    {
      "Location ID": "54428",
      "Location Name": "ELCHO",
      "Time Zone Offset (Hours)": -1,
      "Address": " N11262 DORR ST ELCHO WI 54428 USA "
    },
    {
      "Location ID": "54429",
      "Location Name": "ELDERON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 467 HIGHLAND AVE ELDERON WI 54429 USA "
    },
    {
      "Location ID": "54433",
      "Location Name": "GILMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 W MAIN ST GILMAN WI 54433 USA "
    },
    {
      "Location ID": "54436",
      "Location Name": "GRANTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 MAPLE ST GRANTON WI 54436 USA "
    },
    {
      "Location ID": "54437",
      "Location Name": "GREENWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 E DIVISION ST GREENWOOD WI 54437 USA "
    },
    {
      "Location ID": "54440",
      "Location Name": "HATLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 CLARK ST HATLEY WI 54440 USA "
    },
    {
      "Location ID": "54443",
      "Location Name": "JUNCTION CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 408 MAIN ST JUNCTION CITY WI 54443 USA "
    },
    {
      "Location ID": "54446",
      "Location Name": "LOYAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 327 N MAIN ST LOYAL WI 54446 USA "
    },
    {
      "Location ID": "54447",
      "Location Name": "LUBLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " W13321 SOUTH ST LUBLIN WI 54447 USA "
    },
    {
      "Location ID": "54449",
      "Location Name": "MARSHFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 S CHESTNUT AVE MARSHFIELD WI 54449 USA "
    },
    {
      "Location ID": "54454",
      "Location Name": "MILLADORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 144 MAIN ST MILLADORE WI 54454 USA "
    },
    {
      "Location ID": "54455",
      "Location Name": "MOSINEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 408 2ND ST MOSINEE WI 54455 USA "
    },
    {
      "Location ID": "54456",
      "Location Name": "NEILLSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 619 HEWETT ST NEILLSVILLE WI 54456 USA "
    },
    {
      "Location ID": "54457",
      "Location Name": "NEKOOSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1001 MARKET ST NEKOOSA WI 54457 USA "
    },
    {
      "Location ID": "54460",
      "Location Name": "OWEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 320 N CENTRAL AVE OWEN WI 54460 USA "
    },
    {
      "Location ID": "54463",
      "Location Name": "PELICAN LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 737 USH 45 PELICAN LAKE WI 54463 USA "
    },
    {
      "Location ID": "54465",
      "Location Name": "PICKEREL",
      "Time Zone Offset (Hours)": -1,
      "Address": " N9238 STATE HIGHWAY 55 PICKEREL WI 54465 USA "
    },
    {
      "Location ID": "54469",
      "Location Name": "PORT EDWARDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 160 MARKET AVE PORT EDWARDS WI 54469 USA "
    },
    {
      "Location ID": "54471",
      "Location Name": "RINGLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 169915 BRICKYARD DR RINGLE WI 54471 USA "
    },
    {
      "Location ID": "54473",
      "Location Name": "ROSHOLT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 N MAIN ST ROSHOLT WI 54473 USA "
    },
    {
      "Location ID": "54474",
      "Location Name": "ROTHSCHILD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 29 BROWN BLVD ROTHSCHILD WI 54474 USA "
    },
    {
      "Location ID": "54475",
      "Location Name": "RUDOLPH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1570 MAIN ST RUDOLPH WI 54475 USA "
    },
    {
      "Location ID": "54476",
      "Location Name": "SCHOFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5404 NORMANDY ST SCHOFIELD WI 54476 USA "
    },
    {
      "Location ID": "54479",
      "Location Name": "SPENCER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 W DEARBORN ST SPENCER WI 54479 USA "
    },
    {
      "Location ID": "54484",
      "Location Name": "STRATFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 213114 LEGION ST STRATFORD WI 54484 USA "
    },
    {
      "Location ID": "54485",
      "Location Name": "SUMMIT LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " N9575 COUNTY ROAD B SUMMIT LAKE WI 54485 USA "
    },
    {
      "Location ID": "54491",
      "Location Name": "WHITE LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 621 SCHOOL ST WHITE LAKE WI 54491 USA "
    },
    {
      "Location ID": "54493",
      "Location Name": "WILLARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " W8120 MAIN ST WILLARD WI 54493 USA "
    },
    {
      "Location ID": "54494",
      "Location Name": "WISCONSIN RAPIDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 320 E GRAND AVE WISCONSIN RAPIDS WI 54494 USA "
    },
    {
      "Location ID": "54498",
      "Location Name": "WITHEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 505 DIVISION ST WITHEE WI 54498 USA "
    },
    {
      "Location ID": "544ME",
      "Location Name": "MENARDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2001 JEROME ST ANTIGO WI 54409 USA "
    },
    {
      "Location ID": "544WD",
      "Location Name": "WOODCHUCK'S WOODS",
      "Time Zone Offset (Hours)": -1,
      "Address": " W3024 STARR RD GRANTON WI 54436 USA "
    },
    {
      "Location ID": "545",
      "Location Name": "RHINELANDER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 156 S COURTNEY ST RHINELANDER WI 54501 USA "
    },
    {
      "Location ID": "54511",
      "Location Name": "ARGONNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7894 STATE HIGHWAY 32 55 ARGONNE WI 54511 USA "
    },
    {
      "Location ID": "54512",
      "Location Name": "BOULDER JUNCTION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10328 MAIN ST BOULDER JUNCTION WI 54512 USA "
    },
    {
      "Location ID": "54519",
      "Location Name": "CONOVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4665 COUNTY ROAD K E CONOVER WI 54519 USA "
    },
    {
      "Location ID": "54520",
      "Location Name": "CRANDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 W WASHINGTON ST CRANDON WI 54520 USA "
    },
    {
      "Location ID": "54521",
      "Location Name": "EAGLE RIVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 E WALL ST EAGLE RIVER WI 54521 USA "
    },
    {
      "Location ID": "54529",
      "Location Name": "HARSHAW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5289 LAKEWOOD RD HARSHAW WI 54529 USA "
    },
    {
      "Location ID": "54531",
      "Location Name": "HAZELHURST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7057 US HIGHWAY 51 HAZELHURST WI 54531 USA "
    },
    {
      "Location ID": "54539",
      "Location Name": "LAKE TOMAHAWK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7229 BRADLEY ST LAKE TOMAHAWK WI 54539 USA "
    },
    {
      "Location ID": "54540",
      "Location Name": "LAND O LAKES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4272 COUNTY ROAD B LAND O LAKES WI 54540 USA "
    },
    {
      "Location ID": "54541",
      "Location Name": "LAONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5324 LINDEN ST LAONA WI 54541 USA "
    },
    {
      "Location ID": "54542",
      "Location Name": "LONG LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3357 STATE HIGHWAY 139 LONG LAKE WI 54542 USA "
    },
    {
      "Location ID": "54545",
      "Location Name": "MANITOWISH WATERS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5971 COUNTY RD W MANITOWISH WATERS WI 54545 USA "
    },
    {
      "Location ID": "54548",
      "Location Name": "MINOCQUA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 126 W FRONT ST MINOCQUA WI 54548 USA "
    },
    {
      "Location ID": "54554",
      "Location Name": "PHELPS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4530 COUNTY RD E PHELPS WI 54554 USA "
    },
    {
      "Location ID": "54558",
      "Location Name": "SAINT GERMAIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 415 SUNRISE LN SAINT GERMAIN WI 54558 USA "
    },
    {
      "Location ID": "54560",
      "Location Name": "SAYNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8726 SAYNER ST SAYNER WI 54560 USA "
    },
    {
      "Location ID": "54561",
      "Location Name": "STAR LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7697 COUNTY ROAD K STAR LAKE WI 54561 USA "
    },
    {
      "Location ID": "54562",
      "Location Name": "THREE LAKES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6970 W SCHOOL ST THREE LAKES WI 54562 USA "
    },
    {
      "Location ID": "54566",
      "Location Name": "WABENO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4553 N BRANCH ST WABENO WI 54566 USA "
    },
    {
      "Location ID": "54568",
      "Location Name": "WOODRUFF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 610 VETERANS PKWY WOODRUFF WI 54568 USA "
    },
    {
      "Location ID": "546",
      "Location Name": "LA CROSSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 425 STATE ST LA CROSSE WI 54601 USA "
    },
    {
      "Location ID": "54613",
      "Location Name": "ARKDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1568 STATE ROAD 21 ARKDALE WI 54613 USA "
    },
    {
      "Location ID": "54618",
      "Location Name": "CAMP DOUGLAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 MAIN ST CAMP DOUGLAS WI 54618 USA "
    },
    {
      "Location ID": "54621",
      "Location Name": "CHASEBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 DEPOT ST CHASEBURG WI 54621 USA "
    },
    {
      "Location ID": "54624",
      "Location Name": "DE SOTO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 HOUGHTON ST DE SOTO WI 54624 USA "
    },
    {
      "Location ID": "54626",
      "Location Name": "EASTMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 N MAIN ST STE C EASTMAN WI 54626 USA "
    },
    {
      "Location ID": "54628",
      "Location Name": "FERRYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 158 MAIN ST FERRYVILLE WI 54628 USA "
    },
    {
      "Location ID": "54632",
      "Location Name": "GENOA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 MAIN ST GENOA WI 54632 USA "
    },
    {
      "Location ID": "54636",
      "Location Name": "HOLMEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1111 LINDEN DR HOLMEN WI 54636 USA "
    },
    {
      "Location ID": "54639",
      "Location Name": "LA FARGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 W MAIN ST LA FARGE WI 54639 USA "
    },
    {
      "Location ID": "54646",
      "Location Name": "NECEDAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 603 W 3RD ST NECEDAH WI 54646 USA "
    },
    {
      "Location ID": "54649",
      "Location Name": "OAKDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 126 E BADGER DR OAKDALE WI 54649 USA "
    },
    {
      "Location ID": "54650",
      "Location Name": "ONALASKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 304 11TH AVE N ONALASKA WI 54650 USA "
    },
    {
      "Location ID": "54651",
      "Location Name": "ONTARIO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 321 MAIN ST ONTARIO WI 54651 USA "
    },
    {
      "Location ID": "54658",
      "Location Name": "STODDARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 N MAIN ST STODDARD WI 54658 USA "
    },
    {
      "Location ID": "54660",
      "Location Name": "TOMAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1000 N SUPERIOR AVE TOMAH WI 54660 USA "
    },
    {
      "Location ID": "54664",
      "Location Name": "VIOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 W COMMERCIAL ST VIOLA WI 54664 USA "
    },
    {
      "Location ID": "547",
      "Location Name": "LOG EAU CLAIRE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3510 HOGARTH ST EAU CLAIRE WI 54703 USA "
    },
    {
      "Location ID": "54729",
      "Location Name": "CHIPPEWA FALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 315 N BRIDGE ST CHIPPEWA FALLS WI 54729 USA "
    },
    {
      "Location ID": "547MN",
      "Location Name": "MASON SHOE CO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1313 1ST AVE CHIPPEWA FLS WI 54729 USA "
    },
    {
      "Location ID": "54801",
      "Location Name": "SPOONER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 704 N FRONT ST SPOONER WI 54801 USA "
    },
    {
      "Location ID": "54805",
      "Location Name": "ALMENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 127 SOO AVE E ALMENA WI 54805 USA "
    },
    {
      "Location ID": "54810",
      "Location Name": "BALSAM LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 308 PEARL ST BALSAM LAKE WI 54810 USA "
    },
    {
      "Location ID": "54812",
      "Location Name": "BARRON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 437 E LA SALLE AVE BARRON WI 54812 USA "
    },
    {
      "Location ID": "54813",
      "Location Name": "BARRONETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2964 US HIGHWAY 63 BARRONETT WI 54813 USA "
    },
    {
      "Location ID": "54814",
      "Location Name": "BAYFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 22 S BROAD ST BAYFIELD WI 54814 USA "
    },
    {
      "Location ID": "54816",
      "Location Name": "BENOIT",
      "Time Zone Offset (Hours)": -1,
      "Address": " HWY F BENOIT WI 54816 USA "
    },
    {
      "Location ID": "54821",
      "Location Name": "CABLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 43435 KAVANAUGH RD CABLE WI 54821 USA "
    },
    {
      "Location ID": "54824",
      "Location Name": "CENTURIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 316 4TH ST CENTURIA WI 54824 USA "
    },
    {
      "Location ID": "54826",
      "Location Name": "COMSTOCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1866 US HIGHWAY 63 COMSTOCK WI 54826 USA "
    },
    {
      "Location ID": "54829",
      "Location Name": "CUMBERLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1400 3RD AVE CUMBERLAND WI 54829 USA "
    },
    {
      "Location ID": "54832",
      "Location Name": "DRUMMOND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 52445 WISCONSIN AVE DRUMMOND WI 54832 USA "
    },
    {
      "Location ID": "54837",
      "Location Name": "FREDERIC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 311 OAK ST W FREDERIC WI 54837 USA "
    },
    {
      "Location ID": "54838",
      "Location Name": "GORDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9725 E COUNTY ROAD Y GORDON WI 54838 USA "
    },
    {
      "Location ID": "54839",
      "Location Name": "GRAND VIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 54595 CUDWORTH AVE GRAND VIEW WI 54839 USA "
    },
    {
      "Location ID": "54843",
      "Location Name": "HAYWARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10597 MAIN ST HAYWARD WI 54843 USA "
    },
    {
      "Location ID": "54850",
      "Location Name": "LA POINTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 COLONEL WOODS AVE LA POINTE WI 54850 USA "
    },
    {
      "Location ID": "54853",
      "Location Name": "LUCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 128 S MAIN ST LUCK WI 54853 USA "
    },
    {
      "Location ID": "54856",
      "Location Name": "MASON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24675 COUNTY HWY E MASON WI 54856 USA "
    },
    {
      "Location ID": "54858",
      "Location Name": "MILLTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 71 MAIN ST E MILLTOWN WI 54858 USA "
    },
    {
      "Location ID": "54859",
      "Location Name": "MINONG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 321 5TH AVE MINONG WI 54859 USA "
    },
    {
      "Location ID": "54870",
      "Location Name": "SARONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " W5340 RIPLEY RD SARONA WI 54870 USA "
    },
    {
      "Location ID": "54871",
      "Location Name": "SHELL LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 5TH AVE SHELL LAKE WI 54871 USA "
    },
    {
      "Location ID": "54873",
      "Location Name": "SOLON SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9226 E MAIN ST SOLON SPRINGS WI 54873 USA "
    },
    {
      "Location ID": "54875",
      "Location Name": "SPRINGBROOK",
      "Time Zone Offset (Hours)": -1,
      "Address": " W3060 HIGHWAY 63 SPRINGBROOK WI 54875 USA "
    },
    {
      "Location ID": "54888",
      "Location Name": "TREGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " N7288 SERVICE RD TREGO WI 54888 USA "
    },
    {
      "Location ID": "54889",
      "Location Name": "TURTLE LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 MAPLE ST S TURTLE LAKE WI 54889 USA "
    },
    {
      "Location ID": "549",
      "Location Name": "OSHKOSH P&DF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1025 W 20TH AVE OSHKOSH WI 54903 USA "
    },
    {
      "Location ID": "54901",
      "Location Name": "OSHKOSH ANNEX, WI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1900 VENTURE DR OSHKOSH WI 54902 USA "
    },
    {
      "Location ID": "54909",
      "Location Name": "ALMOND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 127 MAIN ST ALMOND WI 54909 USA "
    },
    {
      "Location ID": "54911",
      "Location Name": "APPLETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 W FRANKLIN ST APPLETON WI 54911 USA "
    },
    {
      "Location ID": "54913",
      "Location Name": "GRAND CHUTE STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 311 N CASALOMA DR APPLETON WI 54913 USA "
    },
    {
      "Location ID": "54921",
      "Location Name": "BANCROFT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5506 COUNTY RD W BANCROFT WI 54921 USA "
    },
    {
      "Location ID": "54922",
      "Location Name": "BEAR CREEK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 ROLLO ST BEAR CREEK WI 54922 USA "
    },
    {
      "Location ID": "54923",
      "Location Name": "BERLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 122 S PEARL ST BERLIN WI 54923 USA "
    },
    {
      "Location ID": "54926",
      "Location Name": "BIG FALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 216 S MAIN ST BIG FALLS WI 54926 USA "
    },
    {
      "Location ID": "54927",
      "Location Name": "BUTTE DES MORTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5757 MAIN ST BUTTE DES MORTS WI 54927 USA "
    },
    {
      "Location ID": "54928",
      "Location Name": "CAROLINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " W12742 COUNTY ROAD M CAROLINE WI 54928 USA "
    },
    {
      "Location ID": "54929",
      "Location Name": "CLINTONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2 N MAIN ST CLINTONVILLE WI 54929 USA "
    },
    {
      "Location ID": "54930",
      "Location Name": "COLOMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 145 N FRONT ST COLOMA WI 54930 USA "
    },
    {
      "Location ID": "54931",
      "Location Name": "DALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " W9728 STATE ROAD 96 DALE WI 54931 USA "
    },
    {
      "Location ID": "54932",
      "Location Name": "ELDORADO",
      "Time Zone Offset (Hours)": -1,
      "Address": " N7666 COUNTY ROAD C ELDORADO WI 54932 USA "
    },
    {
      "Location ID": "54933",
      "Location Name": "EMBARRASS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 E MAIN ST EMBARRASS WI 54933 USA "
    },
    {
      "Location ID": "54935",
      "Location Name": "FOND DU LAC, WI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 99 W 2ND ST FOND DU LAC WI 54935 USA "
    },
    {
      "Location ID": "54940",
      "Location Name": "FREMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 627 WOLF RIVER DR FREMONT WI 54940 USA "
    },
    {
      "Location ID": "54941",
      "Location Name": "GREEN LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 487 HILL ST GREEN LAKE WI 54941 USA "
    },
    {
      "Location ID": "54942",
      "Location Name": "GREENVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " N1886 MUNICIPAL DR GREENVILLE WI 54942 USA "
    },
    {
      "Location ID": "54943",
      "Location Name": "HANCOCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 127 W NORTH LAKE ST HANCOCK WI 54943 USA "
    },
    {
      "Location ID": "54944",
      "Location Name": "HORTONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 N PINE ST HORTONVILLE WI 54944 USA "
    },
    {
      "Location ID": "54945",
      "Location Name": "IOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 190 N MAIN ST IOLA WI 54945 USA "
    },
    {
      "Location ID": "54946",
      "Location Name": "KING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 CUMBERLIDGE AVE KING WI 54946 USA "
    },
    {
      "Location ID": "54947",
      "Location Name": "LARSEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8284 COUNTY ROAD T LARSEN WI 54947 USA "
    },
    {
      "Location ID": "54948",
      "Location Name": "LEOPOLIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " N4952 4TH ST LEOPOLIS WI 54948 USA "
    },
    {
      "Location ID": "54949",
      "Location Name": "MANAWA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 129 UNION ST MANAWA WI 54949 USA "
    },
    {
      "Location ID": "54950",
      "Location Name": "MARION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 238 N MAIN ST MARION WI 54950 USA "
    },
    {
      "Location ID": "54952",
      "Location Name": "MENASHA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 84 RACINE ST MENASHA WI 54952 USA "
    },
    {
      "Location ID": "54956",
      "Location Name": "NEENAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 W FRANKLIN AVE NEENAH WI 54956 USA "
    },
    {
      "Location ID": "54960",
      "Location Name": "NESHKORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 316 S MAIN ST NESHKORO WI 54960 USA "
    },
    {
      "Location ID": "54961",
      "Location Name": "NEW LONDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 S PEARL ST NEW LONDON WI 54961 USA "
    },
    {
      "Location ID": "54962",
      "Location Name": "OGDENSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 WASHINGTON ST OGDENSBURG WI 54962 USA "
    },
    {
      "Location ID": "54963",
      "Location Name": "OMRO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 S WEBSTER AVE OMRO WI 54963 USA "
    },
    {
      "Location ID": "54964",
      "Location Name": "PICKETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6642 STATE ROAD 44 PICKETT WI 54964 USA "
    },
    {
      "Location ID": "54965",
      "Location Name": "PINE RIVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " N4712 COUNTY RD E PINE RIVER WI 54965 USA "
    },
    {
      "Location ID": "54966",
      "Location Name": "PLAINFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 W NORTH ST PLAINFIELD WI 54966 USA "
    },
    {
      "Location ID": "54967",
      "Location Name": "POY SIPPI",
      "Time Zone Offset (Hours)": -1,
      "Address": " N4396 STATE ROAD 49 POY SIPPI WI 54967 USA "
    },
    {
      "Location ID": "54968",
      "Location Name": "PRINCETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 S PEARL ST PRINCETON WI 54968 USA "
    },
    {
      "Location ID": "54969",
      "Location Name": "READFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " E9090 STATE ROAD 96 READFIELD WI 54969 USA "
    },
    {
      "Location ID": "54970",
      "Location Name": "REDGRANITE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 E BANNERMAN AVE REDGRANITE WI 54970 USA "
    },
    {
      "Location ID": "54971",
      "Location Name": "RIPON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 BLACKBURN ST RIPON WI 54971 USA "
    },
    {
      "Location ID": "54974",
      "Location Name": "ROSENDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 181 N GRANT ST ROSENDALE WI 54974 USA "
    },
    {
      "Location ID": "54976",
      "Location Name": "SAXEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " W4655 PORTAGE ST SAXEVILLE WI 54976 USA "
    },
    {
      "Location ID": "54977",
      "Location Name": "SCANDINAVIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 345 N MAIN ST SCANDINAVIA WI 54977 USA "
    },
    {
      "Location ID": "54978",
      "Location Name": "TILLEDA",
      "Time Zone Offset (Hours)": -1,
      "Address": " N6192 COUNTY ROAD D TILLEDA WI 54978 USA "
    },
    {
      "Location ID": "54979",
      "Location Name": "VAN DYNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " N9519 VAN DYNE RD VAN DYNE WI 54979 USA "
    },
    {
      "Location ID": "54980",
      "Location Name": "WAUKAU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2670 STATE ROAD 116 WAUKAU WI 54980 USA "
    },
    {
      "Location ID": "54981",
      "Location Name": "WAUPACA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 S MAIN ST WAUPACA WI 54981 USA "
    },
    {
      "Location ID": "54982",
      "Location Name": "WAUTOMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 W MAIN ST WAUTOMA WI 54982 USA "
    },
    {
      "Location ID": "54983",
      "Location Name": "WEYAUWEGA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 N PINE ST WEYAUWEGA WI 54983 USA "
    },
    {
      "Location ID": "54984",
      "Location Name": "WILD ROSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 801 MAIN ST WILD ROSE WI 54984 USA "
    },
    {
      "Location ID": "54985",
      "Location Name": "WINNEBAGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4060 COUNTY ROAD A WINNEBAGO WI 54985 USA "
    },
    {
      "Location ID": "54986",
      "Location Name": "WINNECONNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 34 S 2ND ST WINNECONNE WI 54986 USA "
    },
    {
      "Location ID": "549AM",
      "Location Name": "AMAZON VENDOR FLEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1919 W COLLEGE AVE APPLETON WI 54914 USA "
    },
    {
      "Location ID": "549AR",
      "Location Name": "ALTA RESOURCES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2435 SCHULTZ RD NEENAH WI 54956 USA "
    },
    {
      "Location ID": "549ES",
      "Location Name": "EARTHSENSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " W9715 STATE ROAD 96 FREMONT WI 54940 USA "
    },
    {
      "Location ID": "549FM",
      "Location Name": "4-IMPRINT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2875 ATLAS AVE OSHKOSH WI 54901 USA "
    },
    {
      "Location ID": "549NH",
      "Location Name": "NATURAL HEALTHY CONCEPTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 310 N WESTHILL BLVD APPLETON WI 54914 USA "
    },
    {
      "Location ID": "549OG",
      "Location Name": "OUTLOOK GRAPHICS (NEENAH)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8520 MARTIN DR. NEENAH WI 54956 USA "
    },
    {
      "Location ID": "549RC",
      "Location Name": "WALSWORTH PRINTING SERVICES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 656 S DOUGLAS ST RIPON WI 54971 USA "
    },
    {
      "Location ID": "549SS",
      "Location Name": "SCHOOL SPECIALTY",
      "Time Zone Offset (Hours)": -1,
      "Address": " W6316 DESIGN DR GREENVILLE WI 54942 USA "
    },
    {
      "Location ID": "549TT",
      "Location Name": "TOYS 4 TRUCKS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3005 N MARSHALL RD APPLETON WI 54911 USA "
    },
    {
      "Location ID": "550",
      "Location Name": "LOG SAINT PAUL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3232 DENMARK AVE EAGAN MN 55121 USA "
    },
    {
      "Location ID": "55016",
      "Location Name": "COTTAGE GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7130 E POINT DOUGLAS COTTAGE GROVE MN 55016 USA "
    },
    {
      "Location ID": "55018",
      "Location Name": "DENNISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 37598 GOODHUE AVE DENNISON MN 55018 USA "
    },
    {
      "Location ID": "55019",
      "Location Name": "DUNDAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 COUNTY ROAD 1 E DUNDAS MN 55019 USA "
    },
    {
      "Location ID": "55021",
      "Location Name": "FARIBAULT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 28 3RD ST NE FARIBAULT MN 55021 USA "
    },
    {
      "Location ID": "55024",
      "Location Name": "FARMINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 ELM ST FARMINGTON MN 55024 USA "
    },
    {
      "Location ID": "55025",
      "Location Name": "FOREST LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 78 6TH AVE SW FOREST LAKE MN 55025 USA "
    },
    {
      "Location ID": "55027",
      "Location Name": "GOODHUE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 N BROADWAY GOODHUE MN 55027 USA "
    },
    {
      "Location ID": "55033",
      "Location Name": "HASTINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 2ND ST E HASTINGS MN 55033 USA "
    },
    {
      "Location ID": "55044",
      "Location Name": "LAKEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20711 HOLT AVE LAKEVILLE MN 55044 USA "
    },
    {
      "Location ID": "55052",
      "Location Name": "MORRISTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 DIVISION ST S MORRISTOWN MN 55052 USA "
    },
    {
      "Location ID": "55053",
      "Location Name": "NERSTRAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 309 MAIN ST NERSTRAND MN 55053 USA "
    },
    {
      "Location ID": "55055",
      "Location Name": "NEWPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 21ST ST STE 141 NEWPORT MN 55055 USA "
    },
    {
      "Location ID": "55057",
      "Location Name": "NORTHFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14 BRIDGE SQ NORTHFIELD MN 55057 USA "
    },
    {
      "Location ID": "55066",
      "Location Name": "RED WING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 222 WEST AVE RED WING MN 55066 USA "
    },
    {
      "Location ID": "55068",
      "Location Name": "ROSEMOUNT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3050 145TH ST W ROSEMOUNT MN 55068 USA "
    },
    {
      "Location ID": "55071",
      "Location Name": "SAINT PAUL PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 619 3RD ST SAINT PAUL PARK MN 55071 USA "
    },
    {
      "Location ID": "55073",
      "Location Name": "SCANDIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 21190 OZARK AVE N SCANDIA MN 55073 USA "
    },
    {
      "Location ID": "55075",
      "Location Name": "SOUTH SAINT PAUL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 236 CONCORD EXCHANGE N SOUTH SAINT PAUL MN 55075 USA "
    },
    {
      "Location ID": "55087",
      "Location Name": "WARSAW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6493 234TH ST W WARSAW MN 55087 USA "
    },
    {
      "Location ID": "550AX",
      "Location Name": "ST. PAUL CHRISTMAS ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5105 VALLEY INDUSTRIAL BLVD S SHAKOPEE MN 55379 USA "
    },
    {
      "Location ID": "550CA",
      "Location Name": "COTTAGE GROVE ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7523 95TH ST S COTTAGE GROVE MN 55016 USA "
    },
    {
      "Location ID": "550FZ",
      "Location Name": "LOG TWIN CITIES MN PSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 345 PLATO BLVD SAINT PAUL MN 55107 USA "
    },
    {
      "Location ID": "550NA",
      "Location Name": "NORTHFIELD CARRIER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2101 CANNON RD NORTHFIELD MN 55057 USA "
    },
    {
      "Location ID": "550OH",
      "Location Name": "OAK PARK HEIGHTS ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5520 MEMORIAL DR N STILLWATER MN 55082 USA "
    },
    {
      "Location ID": "551",
      "Location Name": "ST PAUL P&DC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 EAST KELLOGG SAINT PAUL MN 55101 USA "
    },
    {
      "Location ID": "55101",
      "Location Name": "SAINT PAUL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 5TH ST E STE 150 SAINT PAUL MN 55101 USA "
    },
    {
      "Location ID": "551LO",
      "Location Name": "LONE OAK MAILING TECH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2020 SILVER BELL RD EAGAN MN 55122 USA "
    },
    {
      "Location ID": "553",
      "Location Name": "LOG MINNEAPOLIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 S 1ST ST MINNEAPOLIS MN 55401 USA "
    },
    {
      "Location ID": "55303",
      "Location Name": "ANOKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2168 7TH AVE ANOKA MN 55303 USA "
    },
    {
      "Location ID": "55304",
      "Location Name": "ANDOVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15219 BLUEBIRD ST NW ANOKA MN 55304 USA "
    },
    {
      "Location ID": "55311",
      "Location Name": "MAPLE GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13500 GROVE DR MAPLE GROVE MN 55311 USA "
    },
    {
      "Location ID": "55316",
      "Location Name": "CHAMPLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12299 CHAMPLIN DR CHAMPLIN MN 55316 USA "
    },
    {
      "Location ID": "55336",
      "Location Name": "GLENCOE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 905 11TH ST E GLENCOE MN 55336 USA "
    },
    {
      "Location ID": "55337",
      "Location Name": "BURNSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13800 NICOLLET BLVD BURNSVILLE MN 55337 USA "
    },
    {
      "Location ID": "55364",
      "Location Name": "MOUND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5510 SHORELINE DR MOUND MN 55364 USA "
    },
    {
      "Location ID": "55368",
      "Location Name": "NORWOOD YOUNG AMERICA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 MERGER ST NORWOOD YOUNG AMERICA MN 55368 USA "
    },
    {
      "Location ID": "55372",
      "Location Name": "PRIOR LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4455 VILLAGE LAKE DR SE PRIOR LAKE MN 55372 USA "
    },
    {
      "Location ID": "55379",
      "Location Name": "SHAKOPEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 135 SOMMERVILLE ST S SHAKOPEE MN 55379 USA "
    },
    {
      "Location ID": "553AG",
      "Location Name": "ELK RIVER CARRIER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 560 3RD ST NW ELK RIVER MN 55330 USA "
    },
    {
      "Location ID": "553CA",
      "Location Name": "SHAKOPEE CARRIER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 240 SHUMWAY ST S STE 200 SHAKOPEE MN 55379 USA "
    },
    {
      "Location ID": "553IN",
      "Location Name": "INFINITY 1 MERCHANDISING INC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1674 LAKE DR W CHANHASSEN MN 55317 USA "
    },
    {
      "Location ID": "553L2",
      "Location Name": "CHANHASSEN CARRIER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1775 LAKE DR W CHANHASSEN MN 55317 USA "
    },
    {
      "Location ID": "554",
      "Location Name": "MINNEAPOLIS             MN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 S 1ST ST MINNEAPOLIS MN 55401 USA "
    },
    {
      "Location ID": "55422",
      "Location Name": "ROBBINSDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4048 LAKELAND AVE N MINNEAPOLIS MN 55422 USA "
    },
    {
      "Location ID": "554MA",
      "Location Name": "TWIN CITIES METRO HUB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3501 BROADWAY ST NE FRNT BLAINE MN 55449 USA "
    },
    {
      "Location ID": "554ZS",
      "Location Name": "PSI GROUP INC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5100 MAIN ST NE STE 500 FRIDLEY MN 55421 USA "
    },
    {
      "Location ID": "556",
      "Location Name": "LOG DULUTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2800 W MICHIGAN ST DULUTH MN 55806 USA "
    },
    {
      "Location ID": "55701",
      "Location Name": "ADOLPH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3710 MIDWAY RD DULUTH MN 55701 USA "
    },
    {
      "Location ID": "55706",
      "Location Name": "BABBITT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 33 CENTRAL BLVD STE 100 BABBITT MN 55706 USA "
    },
    {
      "Location ID": "55709",
      "Location Name": "BOVEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 2ND ST BOVEY MN 55709 USA "
    },
    {
      "Location ID": "55711",
      "Location Name": "BROOKSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4869 HIGHWAY 31 BROOKSTON MN 55711 USA "
    },
    {
      "Location ID": "55716",
      "Location Name": "CALUMET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 930 GARY ST CALUMET MN 55716 USA "
    },
    {
      "Location ID": "55721",
      "Location Name": "COHASSET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10 W HIGHWAY 2 COHASSET MN 55721 USA "
    },
    {
      "Location ID": "55722",
      "Location Name": "COLERAINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 POSTAL ST COLERAINE MN 55722 USA "
    },
    {
      "Location ID": "55724",
      "Location Name": "COTTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9087 HIGHWAY 53 COTTON MN 55724 USA "
    },
    {
      "Location ID": "55731",
      "Location Name": "ELY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 34 S 2ND AVE E ELY MN 55731 USA "
    },
    {
      "Location ID": "55732",
      "Location Name": "EMBARRASS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4878 HIGHWAY 21 EMBARRASS MN 55732 USA "
    },
    {
      "Location ID": "55736",
      "Location Name": "FLOODWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 7TH AVE W FLOODWOOD MN 55736 USA "
    },
    {
      "Location ID": "55744",
      "Location Name": "GRAND RAPIDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 505 NW 1ST AVE GRAND RAPIDS MN 55744 USA "
    },
    {
      "Location ID": "55746",
      "Location Name": "HIBBING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1902 3RD AVE E HIBBING MN 55746 USA "
    },
    {
      "Location ID": "55753",
      "Location Name": "KEEWATIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 3RD AVE KEEWATIN MN 55753 USA "
    },
    {
      "Location ID": "55764",
      "Location Name": "MARBLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 ALICE AVE MARBLE MN 55764 USA "
    },
    {
      "Location ID": "55765",
      "Location Name": "MEADOWLANDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7729 CENTRAL AVE MEADOWLANDS MN 55765 USA "
    },
    {
      "Location ID": "55769",
      "Location Name": "NASHWAUK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 132 2ND ST NASHWAUK MN 55769 USA "
    },
    {
      "Location ID": "55775",
      "Location Name": "PENGILLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 31143 STATE HIGHWAY 65 PENGILLY MN 55775 USA "
    },
    {
      "Location ID": "55779",
      "Location Name": "SAGINAW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7027 SAGINAW RD SAGINAW MN 55779 USA "
    },
    {
      "Location ID": "55782",
      "Location Name": "SOUDAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 31 1ST AVE SOUDAN MN 55782 USA "
    },
    {
      "Location ID": "55786",
      "Location Name": "TACONITE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 28 HAYNES ST S TACONITE MN 55786 USA "
    },
    {
      "Location ID": "55790",
      "Location Name": "TOWER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 508 MAIN ST TOWER MN 55790 USA "
    },
    {
      "Location ID": "55792",
      "Location Name": "VIRGINIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 231 1ST ST S VIRGINIA MN 55792 USA "
    },
    {
      "Location ID": "55793",
      "Location Name": "WARBA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 BRIDGE ST WARBA MN 55793 USA "
    },
    {
      "Location ID": "557RC",
      "Location Name": "RIVER COUNTRY LLC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3992 RIVER RD IRON MN 55751 USA "
    },
    {
      "Location ID": "557RM",
      "Location Name": "RON'S CORNER MARKET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7896 US HIGHWAY 169 BOVEY MN 55709 USA "
    },
    {
      "Location ID": "55803",
      "Location Name": "MOUNT ROYAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 MOUNT ROYAL SHOPPING CIR DULUTH MN 55803 USA "
    },
    {
      "Location ID": "55810",
      "Location Name": "PROCTOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 217 2ND ST DULUTH MN 55810 USA "
    },
    {
      "Location ID": "55901",
      "Location Name": "ROCHESTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1445 VALLEYHIGH DR NW ROCHESTER MN 55901 USA "
    },
    {
      "Location ID": "55904",
      "Location Name": "BEAR CREEK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1224 EASTGATE DR SE ROCHESTER MN 55904 USA "
    },
    {
      "Location ID": "55910",
      "Location Name": "ALTURA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 17 S MAIN ST ALTURA MN 55910 USA "
    },
    {
      "Location ID": "55918",
      "Location Name": "BROWNSDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 MILL ST S BROWNSDALE MN 55918 USA "
    },
    {
      "Location ID": "55920",
      "Location Name": "BYRON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 218 BYRON AVE N BYRON MN 55920 USA "
    },
    {
      "Location ID": "55929",
      "Location Name": "DOVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 MAIN ST N DOVER MN 55929 USA "
    },
    {
      "Location ID": "55934",
      "Location Name": "EYOTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 126 S FRONT ST SW EYOTA MN 55934 USA "
    },
    {
      "Location ID": "55940",
      "Location Name": "HAYFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11 W MAIN ST HAYFIELD MN 55940 USA "
    },
    {
      "Location ID": "55944",
      "Location Name": "KASSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14 E VETERANS MEMORIAL HWY KASSON MN 55944 USA "
    },
    {
      "Location ID": "55947",
      "Location Name": "LA CRESCENT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 230 MAIN ST LA CRESCENT MN 55947 USA "
    },
    {
      "Location ID": "55950",
      "Location Name": "LANSING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 54016 270TH ST LANSING MN 55950 USA "
    },
    {
      "Location ID": "55955",
      "Location Name": "MANTORVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 21 5TH ST W MANTORVILLE MN 55955 USA "
    },
    {
      "Location ID": "55959",
      "Location Name": "MINNESOTA CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 MILL ST MINNESOTA CITY MN 55959 USA "
    },
    {
      "Location ID": "55969",
      "Location Name": "ROLLINGSTONE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 MAIN ST ROLLINGSTONE MN 55969 USA "
    },
    {
      "Location ID": "55972",
      "Location Name": "SAINT CHARLES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1201 WHITEWATER AVE SAINT CHARLES MN 55972 USA "
    },
    {
      "Location ID": "55979",
      "Location Name": "UTICA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 W MAIN ST N UTICA MN 55979 USA "
    },
    {
      "Location ID": "55982",
      "Location Name": "WALTHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 223 MAIN ST S WALTHAM MN 55982 USA "
    },
    {
      "Location ID": "55987",
      "Location Name": "WINONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 67 W 5TH ST WINONA MN 55987 USA "
    },
    {
      "Location ID": "559FX",
      "Location Name": "559FX FEDEX RAMP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7800 AIR COMMERCE DR SW ROCHESTER MN 55902 USA "
    },
    {
      "Location ID": "559IB",
      "Location Name": "IB DATA CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 27199 STATE HWY 56 BROWNSDALE MN 55918 USA "
    },
    {
      "Location ID": "559MW",
      "Location Name": "MISSISSIPPI WELDING SUPPLY INC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5150 W 6TH ST WINONA MN 55987 USA "
    },
    {
      "Location ID": "55Z",
      "Location Name": "LOG MINNEAPOLIS ST PAUL NDC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3165 LEXINGTON AVE S EAGAN MN 55121 USA "
    },
    {
      "Location ID": "560",
      "Location Name": "LOG MANKATO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 851 SUMMIT AVE MANKATO MN 56001 USA "
    },
    {
      "Location ID": "56011",
      "Location Name": "BELLE PLAINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 233 N MERIDIAN ST BELLE PLAINE MN 56011 USA "
    },
    {
      "Location ID": "56058",
      "Location Name": "LE SUEUR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 N MAIN ST LE SUEUR MN 56058 USA "
    },
    {
      "Location ID": "560HP",
      "Location Name": "HOUSE OF PRINT MADELIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 322 BENZEL AVE SW MADELIA MN 56062 USA "
    },
    {
      "Location ID": "561",
      "Location Name": "WINDOM MN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 445 9TH ST WINDOM MN 56101 USA "
    },
    {
      "Location ID": "56110",
      "Location Name": "ADRIAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 MAINE AVE STE 104 ADRIAN MN 56110 USA "
    },
    {
      "Location ID": "56111",
      "Location Name": "ALPHA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 MAIN ST N ALPHA MN 56111 USA "
    },
    {
      "Location ID": "56114",
      "Location Name": "AVOCA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 140 SW 2ND ST AVOCA MN 56114 USA "
    },
    {
      "Location ID": "56115",
      "Location Name": "BALATON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 3RD ST BALATON MN 56115 USA "
    },
    {
      "Location ID": "56116",
      "Location Name": "BEAVER CREEK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 313 E 1ST AVE BEAVER CREEK MN 56116 USA "
    },
    {
      "Location ID": "56117",
      "Location Name": "BIGELOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1416 BROADWAY ST BIGELOW MN 56117 USA "
    },
    {
      "Location ID": "56119",
      "Location Name": "BREWSTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1005 2ND AVE BREWSTER MN 56119 USA "
    },
    {
      "Location ID": "56120",
      "Location Name": "BUTTERFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 2ND ST N BUTTERFIELD MN 56120 USA "
    },
    {
      "Location ID": "56121",
      "Location Name": "CEYLON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 W MAIN ST CEYLON MN 56121 USA "
    },
    {
      "Location ID": "56122",
      "Location Name": "CHANDLER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 4TH ST CHANDLER MN 56122 USA "
    },
    {
      "Location ID": "56123",
      "Location Name": "CURRIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 MILL ST N CURRIE MN 56123 USA "
    },
    {
      "Location ID": "56125",
      "Location Name": "DOVRAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 250 WOODMAN AVE DOVRAY MN 56125 USA "
    },
    {
      "Location ID": "56126",
      "Location Name": "DUNDEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " MAIN STREET DUNDEE MN 56126 USA "
    },
    {
      "Location ID": "56127",
      "Location Name": "DUNNELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 W WENBERG ST DUNNELL MN 56127 USA "
    },
    {
      "Location ID": "56128",
      "Location Name": "EDGERTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 741 MAIN ST EDGERTON MN 56128 USA "
    },
    {
      "Location ID": "56129",
      "Location Name": "ELLSWORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 E 3RD AVE ELLSWORTH MN 56129 USA "
    },
    {
      "Location ID": "56131",
      "Location Name": "FULDA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 N BALTIMORE AVE FULDA MN 56131 USA "
    },
    {
      "Location ID": "56134",
      "Location Name": "HARDWICK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 E MAIN ST HARDWICK MN 56134 USA "
    },
    {
      "Location ID": "56136",
      "Location Name": "HENDRICKS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 S MAIN ST HENDRICKS MN 56136 USA "
    },
    {
      "Location ID": "56137",
      "Location Name": "HERON LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1006 3RD AVE HERON LAKE MN 56137 USA "
    },
    {
      "Location ID": "56138",
      "Location Name": "HILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 S MAIN AVE HILLS MN 56138 USA "
    },
    {
      "Location ID": "56139",
      "Location Name": "HOLLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 230 MAIN ST HOLLAND MN 56139 USA "
    },
    {
      "Location ID": "56140",
      "Location Name": "IHLEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 BERTHA AVE IHLEN MN 56140 USA "
    },
    {
      "Location ID": "56141",
      "Location Name": "IONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 330 GRACE AVE IONA MN 56141 USA "
    },
    {
      "Location ID": "56142",
      "Location Name": "IVANHOE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 321 N SHERWOOD ST IVANHOE MN 56142 USA "
    },
    {
      "Location ID": "56143",
      "Location Name": "JACKSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 2ND ST JACKSON MN 56143 USA "
    },
    {
      "Location ID": "56144",
      "Location Name": "JASPER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 216 WALL ST W JASPER MN 56144 USA "
    },
    {
      "Location ID": "56145",
      "Location Name": "JEFFERS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 E WHITED ST JEFFERS MN 56145 USA "
    },
    {
      "Location ID": "56147",
      "Location Name": "KENNETH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 351 MAIN ST KENNETH MN 56147 USA "
    },
    {
      "Location ID": "56149",
      "Location Name": "LAKE BENTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 126 E BENTON ST LAKE BENTON MN 56149 USA "
    },
    {
      "Location ID": "56150",
      "Location Name": "LAKEFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 319 MAIN ST LAKEFIELD MN 56150 USA "
    },
    {
      "Location ID": "56151",
      "Location Name": "LAKE WILSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 137 BROADWAY AVE LAKE WILSON MN 56151 USA "
    },
    {
      "Location ID": "56152",
      "Location Name": "LAMBERTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 S MAIN ST LAMBERTON MN 56152 USA "
    },
    {
      "Location ID": "56153",
      "Location Name": "LEOTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11815 110TH ST LEOTA MN 56153 USA "
    },
    {
      "Location ID": "56155",
      "Location Name": "LISMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 173 S 3RD AVE LISMORE MN 56155 USA "
    },
    {
      "Location ID": "56156",
      "Location Name": "LUVERNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 407 E LINCOLN ST LUVERNE MN 56156 USA "
    },
    {
      "Location ID": "56157",
      "Location Name": "LYND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 W RAILROAD ST LYND MN 56157 USA "
    },
    {
      "Location ID": "56158",
      "Location Name": "MAGNOLIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 W LUVERNE ST MAGNOLIA MN 56158 USA "
    },
    {
      "Location ID": "56159",
      "Location Name": "MOUNTAIN LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 10TH ST N MOUNTAIN LAKE MN 56159 USA "
    },
    {
      "Location ID": "56160",
      "Location Name": "ODIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 N 1ST ST ODIN MN 56160 USA "
    },
    {
      "Location ID": "56161",
      "Location Name": "OKABENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 S MINNESOTA AVE OKABENA MN 56161 USA "
    },
    {
      "Location ID": "56162",
      "Location Name": "ORMSBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 506 MAIN ST ORMSBY MN 56162 USA "
    },
    {
      "Location ID": "56164",
      "Location Name": "PIPESTONE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 S HIAWATHA AVE PIPESTONE MN 56164 USA "
    },
    {
      "Location ID": "56166",
      "Location Name": "REVERE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 MAIN ST REVERE MN 56166 USA "
    },
    {
      "Location ID": "56167",
      "Location Name": "ROUND LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 137 MAIN ST ROUND LAKE MN 56167 USA "
    },
    {
      "Location ID": "56168",
      "Location Name": "RUSHMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 N THOMPSON AVE RUSHMORE MN 56168 USA "
    },
    {
      "Location ID": "56169",
      "Location Name": "RUSSELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 310 FRONT ST RUSSELL MN 56169 USA "
    },
    {
      "Location ID": "56170",
      "Location Name": "RUTHTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 420 AETNA ST RUTHTON MN 56170 USA "
    },
    {
      "Location ID": "56171",
      "Location Name": "SHERBURN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 127 N MAIN ST SHERBURN MN 56171 USA "
    },
    {
      "Location ID": "56172",
      "Location Name": "SLAYTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2541 BROADWAY AVE SLAYTON MN 56172 USA "
    },
    {
      "Location ID": "56174",
      "Location Name": "STORDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 320 AMERICA ST STORDEN MN 56174 USA "
    },
    {
      "Location ID": "56175",
      "Location Name": "TRACY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 3RD ST TRACY MN 56175 USA "
    },
    {
      "Location ID": "56176",
      "Location Name": "TRIMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11 MAIN ST E TRIMONT MN 56176 USA "
    },
    {
      "Location ID": "56178",
      "Location Name": "TYLER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 263 N TYLER ST TYLER MN 56178 USA "
    },
    {
      "Location ID": "56180",
      "Location Name": "WALNUT GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 610 MAIN ST WALNUT GROVE MN 56180 USA "
    },
    {
      "Location ID": "56181",
      "Location Name": "WELCOME",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 DUGAN ST N WELCOME MN 56181 USA "
    },
    {
      "Location ID": "56183",
      "Location Name": "WESTBROOK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 734 6TH ST WESTBROOK MN 56183 USA "
    },
    {
      "Location ID": "56185",
      "Location Name": "WILMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 315 4TH AVE WILMONT MN 56185 USA "
    },
    {
      "Location ID": "56186",
      "Location Name": "WOODSTOCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 MAIN ST N WOODSTOCK MN 56186 USA "
    },
    {
      "Location ID": "56187",
      "Location Name": "WORTHINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 850 10TH ST WORTHINGTON MN 56187 USA "
    },
    {
      "Location ID": "562",
      "Location Name": "WILLMAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 TROTT AVE SW WILLMAR MN 56201 USA "
    },
    {
      "Location ID": "56201",
      "Location Name": "WILLMAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 TROTT AVE SW WILLMAR MN 56201 USA "
    },
    {
      "Location ID": "56207",
      "Location Name": "ALBERTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 MAIN ST ALBERTA MN 56207 USA "
    },
    {
      "Location ID": "56208",
      "Location Name": "APPLETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 42 W SORENSON AVE APPLETON MN 56208 USA "
    },
    {
      "Location ID": "56210",
      "Location Name": "BARRY",
      "Time Zone Offset (Hours)": -1,
      "Address": " US POSTAL SERVICE BARRY MN 56210 USA "
    },
    {
      "Location ID": "56211",
      "Location Name": "BEARDSLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 MAIN ST N BEARDSLEY MN 56211 USA "
    },
    {
      "Location ID": "56212",
      "Location Name": "BELLINGHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 2ND ST S BELLINGHAM MN 56212 USA "
    },
    {
      "Location ID": "56215",
      "Location Name": "BENSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 14TH ST N BENSON MN 56215 USA "
    },
    {
      "Location ID": "56219",
      "Location Name": "BROWNS VALLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 314 W BROADWAY BROWNS VALLEY MN 56219 USA "
    },
    {
      "Location ID": "56221",
      "Location Name": "CHOKIO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 122 MAIN ST CHOKIO MN 56221 USA "
    },
    {
      "Location ID": "56225",
      "Location Name": "CLINTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 MAIN ST CLINTON MN 56225 USA "
    },
    {
      "Location ID": "56226",
      "Location Name": "CLONTARF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 GRACE AVE CLONTARF MN 56226 USA "
    },
    {
      "Location ID": "56227",
      "Location Name": "CORRELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 COUNTY ROAD 25 N CORRELL MN 56227 USA "
    },
    {
      "Location ID": "56231",
      "Location Name": "DANVERS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 408 MINNESOTA AVE DANVERS MN 56231 USA "
    },
    {
      "Location ID": "56235",
      "Location Name": "DONNELLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 4TH ST DONNELLY MN 56235 USA "
    },
    {
      "Location ID": "56236",
      "Location Name": "DUMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 MAIN ST E DUMONT MN 56236 USA "
    },
    {
      "Location ID": "56240",
      "Location Name": "GRACEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 327 STUDDART AVE GRACEVILLE MN 56240 USA "
    },
    {
      "Location ID": "56241",
      "Location Name": "GRANITE FALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 7TH AVE GRANITE FALLS MN 56241 USA "
    },
    {
      "Location ID": "56244",
      "Location Name": "HANCOCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 660 6TH ST HANCOCK MN 56244 USA "
    },
    {
      "Location ID": "56248",
      "Location Name": "HERMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 ATLANTIC AVE S HERMAN MN 56248 USA "
    },
    {
      "Location ID": "56249",
      "Location Name": "HOLLOWAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 RAND ST HOLLOWAY MN 56249 USA "
    },
    {
      "Location ID": "56250",
      "Location Name": "JOHNSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 3RD AVE JOHNSON MN 56250 USA "
    },
    {
      "Location ID": "56252",
      "Location Name": "KERKHOVEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 903 ATLANTIC AVE KERKHOVEN MN 56252 USA "
    },
    {
      "Location ID": "56256",
      "Location Name": "MADISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 711 1ST ST W MADISON MN 56256 USA "
    },
    {
      "Location ID": "56257",
      "Location Name": "MARIETTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 3RD AVE N MARIETTA MN 56257 USA "
    },
    {
      "Location ID": "56258",
      "Location Name": "MARSHALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 W LYON ST MARSHALL MN 56258 USA "
    },
    {
      "Location ID": "56262",
      "Location Name": "MILAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 222 N 2ND ST MILAN MN 56262 USA "
    },
    {
      "Location ID": "56265",
      "Location Name": "MONTEVIDEO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 316 S 1ST ST MONTEVIDEO MN 56265 USA "
    },
    {
      "Location ID": "56267",
      "Location Name": "MORRIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 E 6TH ST MORRIS MN 56267 USA "
    },
    {
      "Location ID": "56271",
      "Location Name": "MURDOCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 412 MAIN AVE MURDOCK MN 56271 USA "
    },
    {
      "Location ID": "56272",
      "Location Name": "NASSAU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 4TH STREET NASSAU MN 56257 USA "
    },
    {
      "Location ID": "56273",
      "Location Name": "NEW LONDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 31 CENTRAL AVE E NEW LONDON MN 56273 USA "
    },
    {
      "Location ID": "56274",
      "Location Name": "NORCROSS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 17557 GRISWOLD AVE NORCROSS MN 56274 USA "
    },
    {
      "Location ID": "56276",
      "Location Name": "ODESSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 COUNTY ROAD 28 E ODESSA MN 56276 USA "
    },
    {
      "Location ID": "56277",
      "Location Name": "OLIVIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 806 E DEPUE AVE OLIVIA MN 56277 USA "
    },
    {
      "Location ID": "56278",
      "Location Name": "ORTONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 JACKSON AVE ORTONVILLE MN 56278 USA "
    },
    {
      "Location ID": "56279",
      "Location Name": "PENNOCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 ATLANTIC AVE NW PENNOCK MN 56279 USA "
    },
    {
      "Location ID": "56283",
      "Location Name": "REDWOOD FALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 E 4TH ST REDWOOD FALLS MN 56283 USA "
    },
    {
      "Location ID": "56288",
      "Location Name": "SPICER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 186 PROGRESS WAY SPICER MN 56288 USA "
    },
    {
      "Location ID": "56289",
      "Location Name": "SUNBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 316 CENTRAL AVE SUNBURG MN 56289 USA "
    },
    {
      "Location ID": "56295",
      "Location Name": "WATSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 COUNTY ROAD 9 WATSON MN 56295 USA "
    },
    {
      "Location ID": "56296",
      "Location Name": "WHEATON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1107 BROADWAY WHEATON MN 56296 USA "
    },
    {
      "Location ID": "563",
      "Location Name": "LOG WAITE PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 517 10TH AVE S WAITE PARK MN 56387 USA "
    },
    {
      "Location ID": "56301",
      "Location Name": "SAINT CLOUD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 915 2ND ST N SAINT CLOUD MN 56301 USA "
    },
    {
      "Location ID": "56308",
      "Location Name": "ALEXANDRIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 519 ELM ST ALEXANDRIA MN 56308 USA "
    },
    {
      "Location ID": "56311",
      "Location Name": "BARRETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 HAWKINS AVE BARRETT MN 56311 USA "
    },
    {
      "Location ID": "56378",
      "Location Name": "SAUK CENTRE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 MAIN ST S SAUK CENTRE MN 56378 USA "
    },
    {
      "Location ID": "563DC",
      "Location Name": "FDCI INC.",
      "Time Zone Offset (Hours)": -1,
      "Address": " 730 ANDERSON AVE SAINT CLOUD MN 56303 USA "
    },
    {
      "Location ID": "564",
      "Location Name": "BRAINERD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 422 LAUREL ST BRAINERD MN 56401 USA "
    },
    {
      "Location ID": "564CA",
      "Location Name": "BAXTER CARRIER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7757 COLLEGE RD BAXTER MN 56425 USA "
    },
    {
      "Location ID": "565",
      "Location Name": "DETROIT LAKES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 250 STATE ST W DETROIT LAKES MN 56501 USA "
    },
    {
      "Location ID": "56501",
      "Location Name": "DETROIT LAKES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 250 STATE ST W DETROIT LAKES MN 56501 USA "
    },
    {
      "Location ID": "56510",
      "Location Name": "ADA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 4TH AVE W ADA MN 56510 USA "
    },
    {
      "Location ID": "56511",
      "Location Name": "AUDUBON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 4TH ST AUDUBON MN 56511 USA "
    },
    {
      "Location ID": "56515",
      "Location Name": "BATTLE LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 N LAKE AVE BATTLE LAKE MN 56515 USA "
    },
    {
      "Location ID": "56516",
      "Location Name": "BEJOU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 525 MAIN ST BEJOU MN 56516 USA "
    },
    {
      "Location ID": "56518",
      "Location Name": "BLUFFTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 2ND ST BLUFFTON MN 56518 USA "
    },
    {
      "Location ID": "56519",
      "Location Name": "BORUP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 MAIN AVE BORUP MN 56519 USA "
    },
    {
      "Location ID": "56521",
      "Location Name": "CALLAWAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 312 MAIN AVE CALLAWAY MN 56521 USA "
    },
    {
      "Location ID": "56522",
      "Location Name": "CAMPBELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 510 5TH ST CAMPBELL MN 56522 USA "
    },
    {
      "Location ID": "56524",
      "Location Name": "CLITHERALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 S OAK ST CLITHERALL MN 56524 USA "
    },
    {
      "Location ID": "56527",
      "Location Name": "DEER CREEK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 MAIN AVE E DEER CREEK MN 56527 USA "
    },
    {
      "Location ID": "56528",
      "Location Name": "DENT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 MAIN ST DENT MN 56528 USA "
    },
    {
      "Location ID": "56529",
      "Location Name": "DILWORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 1ST AVE SE DILWORTH MN 56529 USA "
    },
    {
      "Location ID": "56531",
      "Location Name": "ELBOW LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16 1ST AVE SE ELBOW LAKE MN 56531 USA "
    },
    {
      "Location ID": "56535",
      "Location Name": "ERSKINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 VANCE AVE S ERSKINE MN 56535 USA "
    },
    {
      "Location ID": "56536",
      "Location Name": "FELTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 340 5TH ST FELTON MN 56536 USA "
    },
    {
      "Location ID": "56537",
      "Location Name": "FERGUS FALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1011 WESTERN AVE FERGUS FALLS MN 56537 USA "
    },
    {
      "Location ID": "56540",
      "Location Name": "FERTILE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 WASHINGTON AVE NE FERTILE MN 56540 USA "
    },
    {
      "Location ID": "56542",
      "Location Name": "FOSSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 2ND ST NW FOSSTON MN 56542 USA "
    },
    {
      "Location ID": "56544",
      "Location Name": "FRAZEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 E MAIN AVE FRAZEE MN 56544 USA "
    },
    {
      "Location ID": "56545",
      "Location Name": "GARY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 1ST AVE E GARY MN 56545 USA "
    },
    {
      "Location ID": "56547",
      "Location Name": "GLYNDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 27 STATE ST SE GLYNDON MN 56547 USA "
    },
    {
      "Location ID": "56549",
      "Location Name": "HAWLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 503 FRONT ST HAWLEY MN 56549 USA "
    },
    {
      "Location ID": "56551",
      "Location Name": "HENNING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 507 DOUGLAS AVE HENNING MN 56551 USA "
    },
    {
      "Location ID": "56552",
      "Location Name": "HITTERDAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 52 FRONT ST E HITTERDAL MN 56552 USA "
    },
    {
      "Location ID": "56554",
      "Location Name": "LAKE PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2035 2ND ST LAKE PARK MN 56554 USA "
    },
    {
      "Location ID": "56556",
      "Location Name": "MCINTOSH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 285 CLEVELAND AVE SW MCINTOSH MN 56556 USA "
    },
    {
      "Location ID": "56557",
      "Location Name": "MAHNOMEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 W ADAMS AVE MAHNOMEN MN 56557 USA "
    },
    {
      "Location ID": "56560",
      "Location Name": "MOORHEAD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 5TH ST S MOORHEAD MN 56560 USA "
    },
    {
      "Location ID": "56567",
      "Location Name": "NEW YORK MILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16 WALKER AVE N NEW YORK MILLS MN 56567 USA "
    },
    {
      "Location ID": "56569",
      "Location Name": "OGEMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 412 MAIN ST E OGEMA MN 56569 USA "
    },
    {
      "Location ID": "56570",
      "Location Name": "OSAGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 54586 STATE HIGHWAY 34 OSAGE MN 56570 USA "
    },
    {
      "Location ID": "56571",
      "Location Name": "OTTERTAIL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 W MAIN ST OTTERTAIL MN 56571 USA "
    },
    {
      "Location ID": "56573",
      "Location Name": "PERHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 3RD AVE NW PERHAM MN 56573 USA "
    },
    {
      "Location ID": "56575",
      "Location Name": "PONSFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 48510 COUNTY HIGHWAY 26 PONSFORD MN 56575 USA "
    },
    {
      "Location ID": "56576",
      "Location Name": "RICHVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 MAIN ST S RICHVILLE MN 56576 USA "
    },
    {
      "Location ID": "56583",
      "Location Name": "TINTAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " MAIN STREET TENNEY MN 56583 USA "
    },
    {
      "Location ID": "56584",
      "Location Name": "TWIN VALLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 MAIN AVE W TWIN VALLEY MN 56584 USA "
    },
    {
      "Location ID": "56585",
      "Location Name": "ULEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15 1ST ST NW ULEN MN 56585 USA "
    },
    {
      "Location ID": "56586",
      "Location Name": "UNDERWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 MAIN ST S UNDERWOOD MN 56586 USA "
    },
    {
      "Location ID": "56587",
      "Location Name": "VERGAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 E MAIN ST VERGAS MN 56587 USA "
    },
    {
      "Location ID": "56588",
      "Location Name": "VINING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3005 FRONT ST VINING MN 56588 USA "
    },
    {
      "Location ID": "56589",
      "Location Name": "WAUBUN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1015 1ST ST WAUBUN MN 56589 USA "
    },
    {
      "Location ID": "56590",
      "Location Name": "WENDELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 542 STATE HIGHWAY 55 WENDELL MN 56590 USA "
    },
    {
      "Location ID": "56592",
      "Location Name": "WINGER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14 S MAIN ST WINGER MN 56592 USA "
    },
    {
      "Location ID": "56593",
      "Location Name": "WOLF LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " COUNTY ROAD 38 WOLF LAKE MN 56593 USA "
    },
    {
      "Location ID": "565LS",
      "Location Name": "LAKESHIRTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 750 RANDOLPH RD DETROIT LAKES MN 56501 USA "
    },
    {
      "Location ID": "565VP",
      "Location Name": "WINGER VPO - DEPOT FOODS & DEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 18 N BORUD ST WINGER MN 56592 USA "
    },
    {
      "Location ID": "566",
      "Location Name": "BEMIDJI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 IRVINE AVE NW BEMIDJI MN 56601 USA "
    },
    {
      "Location ID": "56621",
      "Location Name": "BAGLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 26 GETCHELL AVE NE BAGLEY MN 56621 USA "
    },
    {
      "Location ID": "56623",
      "Location Name": "BAUDETTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 1ST AVE NW BAUDETTE MN 56623 USA "
    },
    {
      "Location ID": "56626",
      "Location Name": "BENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 38 COUNTY ROAD 8 BENA MN 56626 USA "
    },
    {
      "Location ID": "56627",
      "Location Name": "BIG FALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 2ND ST NW BIG FALLS MN 56627 USA "
    },
    {
      "Location ID": "56628",
      "Location Name": "BIGFORK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 MAIN ST BIGFORK MN 56628 USA "
    },
    {
      "Location ID": "56630",
      "Location Name": "BLACKDUCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 57 1ST ST SE BLACKDUCK MN 56630 USA "
    },
    {
      "Location ID": "56631",
      "Location Name": "BOWSTRING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 46023 MN-6 BOWSTRING MN 56631 USA "
    },
    {
      "Location ID": "56633",
      "Location Name": "CASS LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 322 2ND ST NW CASS LAKE MN 56633 USA "
    },
    {
      "Location ID": "56634",
      "Location Name": "CLEARBROOK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 MAIN ST S CLEARBROOK MN 56634 USA "
    },
    {
      "Location ID": "56636",
      "Location Name": "DEER RIVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12 1ST AVE NE DEER RIVER MN 56636 USA "
    },
    {
      "Location ID": "56637",
      "Location Name": "TALMOON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 43867 STATE HWY 286 TALMOON MN 56637 USA "
    },
    {
      "Location ID": "56639",
      "Location Name": "EFFIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12 N HWY 5 EFFIE MN 56639 USA "
    },
    {
      "Location ID": "56641",
      "Location Name": "FEDERAL DAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " US POSTAL SERVICE 1000 CNTY RD 8 FEDERAL DAM MN 56641 USA "
    },
    {
      "Location ID": "56644",
      "Location Name": "GONVICK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 233 2ND AVE GONVICK MN 56644 USA "
    },
    {
      "Location ID": "56646",
      "Location Name": "GULLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 S MAIN ST GULLY MN 56646 USA "
    },
    {
      "Location ID": "56647",
      "Location Name": "HINES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 22061 HINES RD NE HINES MN 56647 USA "
    },
    {
      "Location ID": "56649",
      "Location Name": "INTERNATIONAL FALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 4TH ST INTERNATIONAL FALLS MN 56649 USA "
    },
    {
      "Location ID": "56650",
      "Location Name": "KELLIHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 162 E MAIN ST KELLIHER MN 56650 USA "
    },
    {
      "Location ID": "56652",
      "Location Name": "LEONARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24624 LEONARD RD LEONARD MN 56652 USA "
    },
    {
      "Location ID": "56653",
      "Location Name": "LITTLEFORK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 716 3RD AVE LITTLEFORK MN 56653 USA "
    },
    {
      "Location ID": "56655",
      "Location Name": "LONGVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1462 COUNTY ROAD 5 LONGVILLE MN 56655 USA "
    },
    {
      "Location ID": "56657",
      "Location Name": "MARCELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 39370 STATE HIGHWAY 286 MARCELL MN 56657 USA "
    },
    {
      "Location ID": "56658",
      "Location Name": "MARGIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " US POSTAL SERVICE MARGIE MN 56658 USA "
    },
    {
      "Location ID": "56660",
      "Location Name": "MIZPAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 WOODLAND AVE MIZPAH MN 56660 USA "
    },
    {
      "Location ID": "56661",
      "Location Name": "NORTHOME",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12055 MAIN ST NORTHOME MN 56661 USA "
    },
    {
      "Location ID": "56667",
      "Location Name": "PUPOSKY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1139 DURAND DR NW PUPOSKY MN 56667 USA "
    },
    {
      "Location ID": "56668",
      "Location Name": "RANIER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3479 DULUTH ST RANIER MN 56668 USA "
    },
    {
      "Location ID": "56670",
      "Location Name": "REDBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 3RD ST REDBY MN 56670 USA "
    },
    {
      "Location ID": "56671",
      "Location Name": "REDLAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 STATE HIGHWAY 1 REDLAKE MN 56671 USA "
    },
    {
      "Location ID": "56672",
      "Location Name": "REMER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 MAIN ST E REMER MN 56672 USA "
    },
    {
      "Location ID": "56673",
      "Location Name": "ROOSEVELT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10 N MAIN ST ROOSEVELT MN 56673 USA "
    },
    {
      "Location ID": "56676",
      "Location Name": "SHEVLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 148 BROADWAY AVE S SHEVLIN MN 56676 USA "
    },
    {
      "Location ID": "56678",
      "Location Name": "SOLWAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 218 CENTERLINE RD N SOLWAY MN 56678 USA "
    },
    {
      "Location ID": "56679",
      "Location Name": "SOUTH INTERNATIONAL FALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15 18TH ST E SOUTH INTERNATIONAL FALLS MN 56679 USA "
    },
    {
      "Location ID": "56683",
      "Location Name": "TENSTRIKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 171 3RD AVE N TENSTRIKE MN 56683 USA "
    },
    {
      "Location ID": "56685",
      "Location Name": "WASKISH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 54997 HIGHWAY 72 NE WASKISH MN 56685 USA "
    },
    {
      "Location ID": "56686",
      "Location Name": "WILLIAMS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24 MAIN ST WILLIAMS MN 56686 USA "
    },
    {
      "Location ID": "56687",
      "Location Name": "WILTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 CNTY RD 14 WILTON MN 56687 USA "
    },
    {
      "Location ID": "56701",
      "Location Name": "THIEF RIVER FALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 LABREE AVE N THIEF RIVER FALLS MN 56701 USA "
    },
    {
      "Location ID": "56710",
      "Location Name": "ALVARADO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 MARSHALL ST ALVARADO MN 56710 USA "
    },
    {
      "Location ID": "56713",
      "Location Name": "ARGYLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 W 3RD ST ARGYLE MN 56713 USA "
    },
    {
      "Location ID": "56714",
      "Location Name": "BADGER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 N MAIN ST BADGER MN 56714 USA "
    },
    {
      "Location ID": "56715",
      "Location Name": "BROOKS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 MAIN ST BROOKS MN 56715 USA "
    },
    {
      "Location ID": "56716",
      "Location Name": "CROOKSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 S BROADWAY CROOKSTON MN 56716 USA "
    },
    {
      "Location ID": "56720",
      "Location Name": "DONALDSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 COMMERCIAL ST DONALDSON MN 56720 USA "
    },
    {
      "Location ID": "56721",
      "Location Name": "EAST GRAND FORKS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 3RD ST NW EAST GRAND FORKS MN 56721 USA "
    },
    {
      "Location ID": "56723",
      "Location Name": "FISHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 319 THOMPSON AVE FISHER MN 56723 USA "
    },
    {
      "Location ID": "56725",
      "Location Name": "GOODRIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 VAUGHN MAIN ST E GOODRIDGE MN 56725 USA "
    },
    {
      "Location ID": "56726",
      "Location Name": "GREENBUSH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 136 4TH ST N GREENBUSH MN 56726 USA "
    },
    {
      "Location ID": "56728",
      "Location Name": "HALLOCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 S BIRCH AVE HALLOCK MN 56728 USA "
    },
    {
      "Location ID": "56731",
      "Location Name": "HUMBOLDT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 MAIN ST HUMBOLDT MN 56731 USA "
    },
    {
      "Location ID": "56732",
      "Location Name": "KARLSTAD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 MAIN ST S KARLSTAD MN 56732 USA "
    },
    {
      "Location ID": "56733",
      "Location Name": "KENNEDY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 510 ATLANTIC AVE KENNEDY MN 56733 USA "
    },
    {
      "Location ID": "56734",
      "Location Name": "LAKE BRONSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N 1ST AVE LAKE BRONSON MN 56734 USA "
    },
    {
      "Location ID": "56735",
      "Location Name": "LANCASTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 CENTRAL AVE S LANCASTER MN 56735 USA "
    },
    {
      "Location ID": "56736",
      "Location Name": "MENTOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 GARFIELD AVE N MENTOR MN 56736 USA "
    },
    {
      "Location ID": "56737",
      "Location Name": "MIDDLE RIVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 HILL AVE S MIDDLE RIVER MN 56737 USA "
    },
    {
      "Location ID": "56738",
      "Location Name": "NEWFOLDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 S MAIN ST NEWFOLDEN MN 56738 USA "
    },
    {
      "Location ID": "56742",
      "Location Name": "OKLEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 2ND AVE OKLEE MN 56742 USA "
    },
    {
      "Location ID": "56744",
      "Location Name": "OSLO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 312 MAIN ST OSLO MN 56744 USA "
    },
    {
      "Location ID": "56748",
      "Location Name": "PLUMMER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 CENTRAL AVE E PLUMMER MN 56748 USA "
    },
    {
      "Location ID": "56750",
      "Location Name": "RED LAKE FALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 CHAMPAGNE AVE SW RED LAKE FALLS MN 56750 USA "
    },
    {
      "Location ID": "56751",
      "Location Name": "ROSEAU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 CENTER ST E ROSEAU MN 56751 USA "
    },
    {
      "Location ID": "56754",
      "Location Name": "SAINT HILAIRE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 N BROADWAY SAINT HILAIRE MN 56754 USA "
    },
    {
      "Location ID": "56756",
      "Location Name": "SALOL",
      "Time Zone Offset (Hours)": -1,
      "Address": " PO BOX 9998 SALOL MN 56756 USA "
    },
    {
      "Location ID": "56757",
      "Location Name": "STEPHEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 422 5TH ST STEPHEN MN 56757 USA "
    },
    {
      "Location ID": "56758",
      "Location Name": "STRANDQUIST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 MAIN ST STRANDQUIST MN 56758 USA "
    },
    {
      "Location ID": "56759",
      "Location Name": "STRATHCONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 S 1ST ST STRATHCONA MN 56759 USA "
    },
    {
      "Location ID": "56760",
      "Location Name": "VIKING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N MAIN ST VIKING MN 56760 USA "
    },
    {
      "Location ID": "56762",
      "Location Name": "WARREN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 520 N MINNESOTA ST WARREN MN 56762 USA "
    },
    {
      "Location ID": "56763",
      "Location Name": "WARROAD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 MAIN AVE NE WARROAD MN 56763 USA "
    },
    {
      "Location ID": "567BV",
      "Location Name": "BADGER VILLAGE POST OFFICE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 N MAIN ST BADGER MN 56714 USA "
    },
    {
      "Location ID": "567CC",
      "Location Name": "COLLECTION BOX VPO CENEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 US HIGHWAY 59 S BROOKS MN 56715 USA "
    },
    {
      "Location ID": "567DK",
      "Location Name": "DIGI-KEY CORPORATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 701 BROOKS AVE S THIEF RIVER FALLS MN 56701 USA "
    },
    {
      "Location ID": "567ES",
      "Location Name": "EAGLE SQUARE VPO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 405 BRIDGE ST NE RED LAKE FALLS MN 56750 USA "
    },
    {
      "Location ID": "567MW",
      "Location Name": "MARVIN WINDOW & FACTORY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 802 STATE AVE WARROAD MN 56763 USA "
    },
    {
      "Location ID": "570",
      "Location Name": "SIOUX FALLS P&DC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4801 N 4TH AVE SIOUX FALLS SD 57104 USA "
    },
    {
      "Location ID": "57001",
      "Location Name": "ALCESTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 DAKOTA ST ALCESTER SD 57001 USA "
    },
    {
      "Location ID": "57002",
      "Location Name": "AURORA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 219 N BROADWAY ST AURORA SD 57002 USA "
    },
    {
      "Location ID": "57003",
      "Location Name": "BALTIC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 132 ST OLAF AVE BALTIC SD 57003 USA "
    },
    {
      "Location ID": "57004",
      "Location Name": "BERESFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 S 3RD ST BERESFORD SD 57004 USA "
    },
    {
      "Location ID": "57005",
      "Location Name": "BRANDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1120 E HOLLY BLVD BRANDON SD 57005 USA "
    },
    {
      "Location ID": "57006",
      "Location Name": "BROOKINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 MAIN AVE BROOKINGS SD 57006 USA "
    },
    {
      "Location ID": "57007",
      "Location Name": "UNIVERSITY STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1451 STADIUM RD BROOKINGS SD 57007 USA "
    },
    {
      "Location ID": "57012",
      "Location Name": "CANISTOTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 222 W MAIN ST CANISTOTA SD 57012 USA "
    },
    {
      "Location ID": "57013",
      "Location Name": "CANTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 315 E 5TH ST CANTON SD 57013 USA "
    },
    {
      "Location ID": "57014",
      "Location Name": "CENTERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 533 BROADWAY ST CENTERVILLE SD 57014 USA "
    },
    {
      "Location ID": "57016",
      "Location Name": "CHESTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 4TH ST CHESTER SD 57016 USA "
    },
    {
      "Location ID": "57017",
      "Location Name": "COLMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 129 N MAIN ST COLMAN SD 57017 USA "
    },
    {
      "Location ID": "57018",
      "Location Name": "COLTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 213 E 4TH ST COLTON SD 57018 USA "
    },
    {
      "Location ID": "57020",
      "Location Name": "CROOKS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 MAIN ST CROOKS SD 57020 USA "
    },
    {
      "Location ID": "57021",
      "Location Name": "DAVIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 E US HIGHWAY 18 DAVIS SD 57021 USA "
    },
    {
      "Location ID": "57022",
      "Location Name": "DELL RAPIDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 E 4TH ST DELL RAPIDS SD 57022 USA "
    },
    {
      "Location ID": "57025",
      "Location Name": "ELK POINT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 W MAIN ST ELK POINT SD 57025 USA "
    },
    {
      "Location ID": "57026",
      "Location Name": "ELKTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 ELK ST ELKTON SD 57026 USA "
    },
    {
      "Location ID": "57028",
      "Location Name": "FLANDREAU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 W 2ND AVE FLANDREAU SD 57028 USA "
    },
    {
      "Location ID": "57029",
      "Location Name": "FREEMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 421 S POPLAR ST FREEMAN SD 57029 USA "
    },
    {
      "Location ID": "57030",
      "Location Name": "GARRETSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 641 N MAIN AVE GARRETSON SD 57030 USA "
    },
    {
      "Location ID": "57031",
      "Location Name": "GAYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 508 WASHINGTON ST GAYVILLE SD 57031 USA "
    },
    {
      "Location ID": "57032",
      "Location Name": "HARRISBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 E WILLOW ST HARRISBURG SD 57032 USA "
    },
    {
      "Location ID": "57033",
      "Location Name": "HARTFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 901 N OAKS AVE HARTFORD SD 57033 USA "
    },
    {
      "Location ID": "57035",
      "Location Name": "HUMBOLDT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1005 W 2ND AVE HUMBOLDT SD 57035 USA "
    },
    {
      "Location ID": "57036",
      "Location Name": "HURLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 608 CENTER AVE HURLEY SD 57036 USA "
    },
    {
      "Location ID": "57037",
      "Location Name": "IRENE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 W MAIN ST IRENE SD 57037 USA "
    },
    {
      "Location ID": "57038",
      "Location Name": "JEFFERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 MAIN ST JEFFERSON SD 57038 USA "
    },
    {
      "Location ID": "57039",
      "Location Name": "LENNOX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 S MAIN ST LENNOX SD 57039 USA "
    },
    {
      "Location ID": "57040",
      "Location Name": "LESTERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 223 MAIN AVE LESTERVILLE SD 57040 USA "
    },
    {
      "Location ID": "57041",
      "Location Name": "LYONS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 MAIN ST CROOKS SD 57041 USA "
    },
    {
      "Location ID": "57042",
      "Location Name": "MADISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 E CENTER ST MADISON SD 57042 USA "
    },
    {
      "Location ID": "57043",
      "Location Name": "MARION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 N BROADWAY AVE MARION SD 57043 USA "
    },
    {
      "Location ID": "57045",
      "Location Name": "MENNO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 227 S 5TH ST MENNO SD 57045 USA "
    },
    {
      "Location ID": "57046",
      "Location Name": "MISSION HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 WASHINGTON AVE S MISSION HILL SD 57046 USA "
    },
    {
      "Location ID": "57048",
      "Location Name": "MONTROSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 W MAIN ST MONTROSE SD 57048 USA "
    },
    {
      "Location ID": "57049",
      "Location Name": "NORTH SIOUX CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 310 N DERBY LN NORTH SIOUX CITY SD 57049 USA "
    },
    {
      "Location ID": "57050",
      "Location Name": "NUNDA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 504 MAIN ST NUNDA SD 57050 USA "
    },
    {
      "Location ID": "57051",
      "Location Name": "OLDHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 N LILLIE AVE OLDHAM SD 57051 USA "
    },
    {
      "Location ID": "57052",
      "Location Name": "OLIVET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 145 EUCLID ST OLIVET SD 57052 USA "
    },
    {
      "Location ID": "57053",
      "Location Name": "PARKER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 S MAIN AVE PARKER SD 57053 USA "
    },
    {
      "Location ID": "57054",
      "Location Name": "RAMONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 217 E MAIN ST RAMONA SD 57054 USA "
    },
    {
      "Location ID": "57055",
      "Location Name": "RENNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 25797 475TH AVE RENNER SD 57055 USA "
    },
    {
      "Location ID": "57058",
      "Location Name": "SALEM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 311 N MAIN ST SALEM SD 57058 USA "
    },
    {
      "Location ID": "57059",
      "Location Name": "SCOTLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 640 1ST ST SCOTLAND SD 57059 USA "
    },
    {
      "Location ID": "57061",
      "Location Name": "SINAI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 MAIN AVE SINAI SD 57061 USA "
    },
    {
      "Location ID": "57062",
      "Location Name": "SPRINGFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 811 8TH ST SPRINGFIELD SD 57062 USA "
    },
    {
      "Location ID": "57063",
      "Location Name": "TABOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 S LIDICE AVE TABOR SD 57063 USA "
    },
    {
      "Location ID": "57064",
      "Location Name": "TEA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 502 E FIGZEL CT STE A TEA SD 57064 USA "
    },
    {
      "Location ID": "57065",
      "Location Name": "TRENT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 3RD ST TRENT SD 57065 USA "
    },
    {
      "Location ID": "57066",
      "Location Name": "TYNDALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1716 MAIN ST TYNDALL SD 57066 USA "
    },
    {
      "Location ID": "57067",
      "Location Name": "UTICA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 2ND ST UTICA SD 57067 USA "
    },
    {
      "Location ID": "57068",
      "Location Name": "VALLEY SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 409 BROADWAY AVE VALLEY SPRINGS SD 57068 USA "
    },
    {
      "Location ID": "57069",
      "Location Name": "VERMILLION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16 COURT ST VERMILLION SD 57069 USA "
    },
    {
      "Location ID": "57070",
      "Location Name": "VIBORG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 S MAIN ST VIBORG SD 57070 USA "
    },
    {
      "Location ID": "57071",
      "Location Name": "VOLGA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 W 3RD ST VOLGA SD 57071 USA "
    },
    {
      "Location ID": "57072",
      "Location Name": "VOLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 MAIN ST VOLIN SD 57072 USA "
    },
    {
      "Location ID": "57073",
      "Location Name": "WAKONDA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 OHIO ST WAKONDA SD 57073 USA "
    },
    {
      "Location ID": "57075",
      "Location Name": "WENTWORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 S MAIN AVE WENTWORTH SD 57075 USA "
    },
    {
      "Location ID": "57077",
      "Location Name": "WORTHING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 S MAIN ST WORTHING SD 57077 USA "
    },
    {
      "Location ID": "57078",
      "Location Name": "YANKTON ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 506 W 25TH ST YANKTON SD 57078 USA "
    },
    {
      "Location ID": "57080",
      "Location Name": "MADISON-LEWIS CPO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 741 S WASHINGTON MADISON SD 57042 USA "
    },
    {
      "Location ID": "570AP",
      "Location Name": "ALPINE RAMP SIOUX FALLS APT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3501 N AVIATION AVE SIOUX FALLS SD 57104 USA "
    },
    {
      "Location ID": "570CB",
      "Location Name": "YANKTON COLLECTION BOXES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 335 WALNUT ST YANKTON SD 57078 USA "
    },
    {
      "Location ID": "570CM",
      "Location Name": "CROOKS (METRO COLLECTION BOX)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 25797 475TH  AVE RENNER SD 57055 USA "
    },
    {
      "Location ID": "570FF",
      "Location Name": "FILLY FLAIR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 325 E 4TH ST COLTON SD 57018 USA "
    },
    {
      "Location ID": "570FX",
      "Location Name": "SIOUX FALLS FEDEX RAMP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 47 N JOHN ORR DRIVE SIOUX FALLS SD 57104 USA "
    },
    {
      "Location ID": "570LB",
      "Location Name": "LAURIEBELLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 27079 KATIE RD TEA SD 57064 USA "
    },
    {
      "Location ID": "570LM",
      "Location Name": "LARSON MANUFACTURING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2333 EASTBROOK DRIVE BROOKINGS SD 57006 USA "
    },
    {
      "Location ID": "570MJ",
      "Location Name": "MASON JAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24599 472ND AVE DELL RAPIDS SD 57022 USA "
    },
    {
      "Location ID": "570TD",
      "Location Name": "TEL DRUG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4901 N 4TH AVE SIOUX FALLS SD 57104 USA "
    },
    {
      "Location ID": "570WD",
      "Location Name": "WEISSER DISTRIBUTING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 921 E AMIDON ST SIOUX FALLS SD 57104 USA "
    },
    {
      "Location ID": "570YA",
      "Location Name": "YANKTON ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 506 W 25TH ST YANKTON SD 57078 USA "
    },
    {
      "Location ID": "57101",
      "Location Name": "SIOUX FALLS DOWNTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 320 S 2ND AVE SIOUX FALLS SD 57104 USA "
    },
    {
      "Location ID": "57103",
      "Location Name": "(57103) CLIFF CARRIER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 630 S CLIFF AVE SIOUX FALLS SD 57104 USA "
    },
    {
      "Location ID": "57106",
      "Location Name": "SOUTHWEST CARRIER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4709 S TECHNOPOLIS DR SIOUX FALLS SD 57106 USA "
    },
    {
      "Location ID": "57109",
      "Location Name": "MEADOWS POSTAL STORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2501 S LOUISE AVE SIOUX FALLS SD 57106 USA "
    },
    {
      "Location ID": "571AX",
      "Location Name": "SIOUX FALLS PEAK ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 221 NORTH CHAPEL HILL SIOUX FALLS SD 57103 USA "
    },
    {
      "Location ID": "571MV",
      "Location Name": "MEDVANTX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2503 E 54TH ST N SIOUX FALLS SD 57104 USA "
    },
    {
      "Location ID": "571SC",
      "Location Name": "SCHEELS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2101 W 41ST ST STE 25A SIOUX FALLS SD 57105 USA "
    },
    {
      "Location ID": "571WB",
      "Location Name": "WILD OAK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3956 W TICKMAN ST SIOUX FALLS SD 57107 USA "
    },
    {
      "Location ID": "572",
      "Location Name": "DAKOTA CENTRAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 355 15TH ST NW HURON SD 57399 USA "
    },
    {
      "Location ID": "57201",
      "Location Name": "WATERTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 405 E KEMP AVE WATERTOWN SD 57201 USA "
    },
    {
      "Location ID": "57212",
      "Location Name": "ARLINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 S MAIN ST ARLINGTON SD 57212 USA "
    },
    {
      "Location ID": "57213",
      "Location Name": "ASTORIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 331 MAIN ST ASTORIA SD 57213 USA "
    },
    {
      "Location ID": "57214",
      "Location Name": "BADGER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 319 E MAIN ST BADGER SD 57214 USA "
    },
    {
      "Location ID": "57216",
      "Location Name": "BIG STONE CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 553 MAIN ST BIG STONE CITY SD 57216 USA "
    },
    {
      "Location ID": "57217",
      "Location Name": "BRADLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 MAIN ST BRADLEY SD 57217 USA "
    },
    {
      "Location ID": "57218",
      "Location Name": "BRANDT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 MAIN ST BRANDT SD 57218 USA "
    },
    {
      "Location ID": "57219",
      "Location Name": "BRISTOL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 MAIN ST S BRISTOL SD 57219 USA "
    },
    {
      "Location ID": "57220",
      "Location Name": "BRUCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 508 JEFFERSON ST BRUCE SD 57220 USA "
    },
    {
      "Location ID": "57221",
      "Location Name": "BRYANT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 W MAIN ST BRYANT SD 57221 USA "
    },
    {
      "Location ID": "57223",
      "Location Name": "CASTLEWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 E MAIN ST CASTLEWOOD SD 57223 USA "
    },
    {
      "Location ID": "57224",
      "Location Name": "CLAIRE CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 270 MAIN ST CLAIRE CITY SD 57224 USA "
    },
    {
      "Location ID": "57225",
      "Location Name": "CLARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 N SMITH ST CLARK SD 57225 USA "
    },
    {
      "Location ID": "57226",
      "Location Name": "CLEAR LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 405 3RD ST W CLEAR LAKE SD 57226 USA "
    },
    {
      "Location ID": "57227",
      "Location Name": "CORONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 MAIN ST CORONA SD 57227 USA "
    },
    {
      "Location ID": "57231",
      "Location Name": "DE SMET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 CALUMET AVE SW DE SMET SD 57231 USA "
    },
    {
      "Location ID": "57232",
      "Location Name": "EDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 S 3RD ST EDEN SD 57232 USA "
    },
    {
      "Location ID": "57234",
      "Location Name": "ESTELLINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 MAIN ST N ESTELLINE SD 57234 USA "
    },
    {
      "Location ID": "57235",
      "Location Name": "FLORENCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 235 MAIN AVE FLORENCE SD 57235 USA "
    },
    {
      "Location ID": "57237",
      "Location Name": "GARY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1109 COTEAU ST GARY SD 57237 USA "
    },
    {
      "Location ID": "57238",
      "Location Name": "GOODWIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 W CHICAGO AVE GOODWIN SD 57238 USA "
    },
    {
      "Location ID": "57239",
      "Location Name": "GRENVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 915 KOSCIUSKO AVE GRENVILLE SD 57239 USA "
    },
    {
      "Location ID": "57241",
      "Location Name": "HAYTI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 MAIN AVE HAYTI SD 57241 USA "
    },
    {
      "Location ID": "57242",
      "Location Name": "HAZEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 17 W WILLIAMS ST HAZEL SD 57242 USA "
    },
    {
      "Location ID": "57243",
      "Location Name": "HENRY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 MAIN ST HENRY SD 57243 USA "
    },
    {
      "Location ID": "57245",
      "Location Name": "KRANZBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 DAKOTA AVE N KRANZBURG SD 57245 USA "
    },
    {
      "Location ID": "57247",
      "Location Name": "LAKE CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 S BROADWAY ST LAKE CITY SD 57247 USA "
    },
    {
      "Location ID": "57248",
      "Location Name": "LAKE NORDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 MAIN AVE LAKE NORDEN SD 57248 USA "
    },
    {
      "Location ID": "57249",
      "Location Name": "LAKE PRESTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 MAIN AVE N LAKE PRESTON SD 57249 USA "
    },
    {
      "Location ID": "57251",
      "Location Name": "MARVIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E DIVISION ST MARVIN SD 57251 USA "
    },
    {
      "Location ID": "57252",
      "Location Name": "MILBANK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 222 E 3RD AVE MILBANK SD 57252 USA "
    },
    {
      "Location ID": "57255",
      "Location Name": "NEW EFFINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 MAIN ST NEW EFFINGTON SD 57255 USA "
    },
    {
      "Location ID": "57257",
      "Location Name": "PEEVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 310 MAIN ST PEEVER SD 57257 USA "
    },
    {
      "Location ID": "57258",
      "Location Name": "RAYMOND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 FLOWER ST RAYMOND SD 57258 USA "
    },
    {
      "Location ID": "57259",
      "Location Name": "REVILLO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 317 N 2ND AVE REVILLO SD 57259 USA "
    },
    {
      "Location ID": "57260",
      "Location Name": "ROSHOLT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12 W MAIN ST ROSHOLT SD 57260 USA "
    },
    {
      "Location ID": "57261",
      "Location Name": "ROSLYN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 509 N MAIN ST ROSLYN SD 57261 USA "
    },
    {
      "Location ID": "57262",
      "Location Name": "SISSETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 E OAK ST STE 103 SISSETON SD 57262 USA "
    },
    {
      "Location ID": "57263",
      "Location Name": "SOUTH SHORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 N MAIN ST SOUTH SHORE SD 57263 USA "
    },
    {
      "Location ID": "57264",
      "Location Name": "STOCKHOLM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 N MAIN ST STOCKHOLM SD 57264 USA "
    },
    {
      "Location ID": "57266",
      "Location Name": "SUMMIT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 MAPLE ST SUMMIT SD 57266 USA "
    },
    {
      "Location ID": "57268",
      "Location Name": "TORONTO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 540 MAIN AVE TORONTO SD 57268 USA "
    },
    {
      "Location ID": "57270",
      "Location Name": "VEBLEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 S MAIN ST VEBLEN SD 57270 USA "
    },
    {
      "Location ID": "57272",
      "Location Name": "WALLACE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 602 MAIN ST WALLACE SD 57272 USA "
    },
    {
      "Location ID": "57273",
      "Location Name": "WAUBAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 N MAIN ST WAUBAY SD 57273 USA "
    },
    {
      "Location ID": "57274",
      "Location Name": "WEBSTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 715 MAIN ST WEBSTER SD 57274 USA "
    },
    {
      "Location ID": "57276",
      "Location Name": "WHITE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 311 W MAIN ST WHITE SD 57276 USA "
    },
    {
      "Location ID": "57278",
      "Location Name": "WILLOW LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 GARFIELD AVE WILLOW LAKE SD 57278 USA "
    },
    {
      "Location ID": "57279",
      "Location Name": "WILMOT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 713 MAIN ST WILMOT SD 57279 USA "
    },
    {
      "Location ID": "57301",
      "Location Name": "MITCHELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 W 5TH AVE MITCHELL SD 57301 USA "
    },
    {
      "Location ID": "57311",
      "Location Name": "ALEXANDRIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 MAIN ST ALEXANDRIA SD 57311 USA "
    },
    {
      "Location ID": "57312",
      "Location Name": "ALPENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 818 MAIN ST ALPENA SD 57312 USA "
    },
    {
      "Location ID": "57313",
      "Location Name": "ARMOUR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 814 MAIN AVE ARMOUR SD 57313 USA "
    },
    {
      "Location ID": "57314",
      "Location Name": "ARTESIAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 225 N MAIN ST ARTESIAN SD 57314 USA "
    },
    {
      "Location ID": "57315",
      "Location Name": "AVON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 129 MAIN ST N AVON SD 57315 USA "
    },
    {
      "Location ID": "57317",
      "Location Name": "BONESTEEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 405 MELLETTE ST BONESTEEL SD 57317 USA "
    },
    {
      "Location ID": "57319",
      "Location Name": "BRIDGEWATER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 235 N MAIN AVE BRIDGEWATER SD 57319 USA "
    },
    {
      "Location ID": "57321",
      "Location Name": "CANOVA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 MAIN ST W CANOVA SD 57321 USA "
    },
    {
      "Location ID": "57322",
      "Location Name": "CARPENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 531 415TH AVE CARPENTER SD 57322 USA "
    },
    {
      "Location ID": "57323",
      "Location Name": "CARTHAGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 MAIN ST E CARTHAGE SD 57323 USA "
    },
    {
      "Location ID": "57324",
      "Location Name": "CAVOUR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 391 SOLFERINO ST CAVOUR SD 57324 USA "
    },
    {
      "Location ID": "57325",
      "Location Name": "CHAMBERLAIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 N SANBORN ST CHAMBERLAIN SD 57325 USA "
    },
    {
      "Location ID": "57328",
      "Location Name": "CORSICA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 225 E MAIN ST CORSICA SD 57328 USA "
    },
    {
      "Location ID": "57330",
      "Location Name": "DELMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 W MAIN ST DELMONT SD 57330 USA "
    },
    {
      "Location ID": "57331",
      "Location Name": "DIMOCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 S MAIN ST DIMOCK SD 57331 USA "
    },
    {
      "Location ID": "57332",
      "Location Name": "EMERY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 124 N 3RD ST EMERY SD 57332 USA "
    },
    {
      "Location ID": "57334",
      "Location Name": "ETHAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 W MAIN ST ETHAN SD 57334 USA "
    },
    {
      "Location ID": "57335",
      "Location Name": "FAIRFAX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 412 MAIN ST FAIRFAX SD 57335 USA "
    },
    {
      "Location ID": "57337",
      "Location Name": "FEDORA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 23304 421ST AVE FEDORA SD 57337 USA "
    },
    {
      "Location ID": "57339",
      "Location Name": "FORT THOMPSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 SD HIGHWAY 249 FORT THOMPSON SD 57339 USA "
    },
    {
      "Location ID": "57340",
      "Location Name": "FULTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 217 N MAIN AVE FULTON SD 57340 USA "
    },
    {
      "Location ID": "57341",
      "Location Name": "GANN VALLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 229 MAIN ST GANN VALLEY SD 57341 USA "
    },
    {
      "Location ID": "57342",
      "Location Name": "GEDDES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 312 MAIN AVE GEDDES SD 57342 USA "
    },
    {
      "Location ID": "57345",
      "Location Name": "HIGHMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 COMMERCIAL AVE SE HIGHMORE SD 57345 USA "
    },
    {
      "Location ID": "57348",
      "Location Name": "HITCHCOCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 386 CLARKE ST HITCHCOCK SD 57348 USA "
    },
    {
      "Location ID": "57349",
      "Location Name": "HOWARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 N MAIN ST HOWARD SD 57349 USA "
    },
    {
      "Location ID": "57350",
      "Location Name": "HURON MAIN POST OFFICE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 DAKOTA AVE S HURON SD 57350 USA "
    },
    {
      "Location ID": "57353",
      "Location Name": "IROQUOIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 199 S OTTOWA ST IROQUOIS SD 57353 USA "
    },
    {
      "Location ID": "57354",
      "Location Name": "KAYLOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 MAIN ST KAYLOR SD 57354 USA "
    },
    {
      "Location ID": "57355",
      "Location Name": "KIMBALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 MAIN ST KIMBALL SD 57355 USA "
    },
    {
      "Location ID": "57356",
      "Location Name": "LAKE ANDES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 499 MAIN ST LAKE ANDES SD 57356 USA "
    },
    {
      "Location ID": "57359",
      "Location Name": "LETCHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 E MAIN ST LETCHER SD 57359 USA "
    },
    {
      "Location ID": "57362",
      "Location Name": "MILLER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 320 N BROADWAY AVE MILLER SD 57362 USA "
    },
    {
      "Location ID": "57363",
      "Location Name": "MOUNT VERNON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 S MAIN ST MOUNT VERNON SD 57363 USA "
    },
    {
      "Location ID": "57365",
      "Location Name": "OACOMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 W 2ND ST OACOMA SD 57365 USA "
    },
    {
      "Location ID": "57366",
      "Location Name": "PARKSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 N 1ST ST PARKSTON SD 57366 USA "
    },
    {
      "Location ID": "57367",
      "Location Name": "PICKSTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 LEWIS AVE PICKSTOWN SD 57367 USA "
    },
    {
      "Location ID": "57368",
      "Location Name": "PLANKINTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 N MAIN ST PLANKINTON SD 57368 USA "
    },
    {
      "Location ID": "57369",
      "Location Name": "PLATTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20 E 5TH ST PLATTE SD 57369 USA "
    },
    {
      "Location ID": "57370",
      "Location Name": "PUKWANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 MAIN ST PUKWANA SD 57370 USA "
    },
    {
      "Location ID": "57371",
      "Location Name": "REE HEIGHTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 DAKOTA AVE S REE HEIGHTS SD 57371 USA "
    },
    {
      "Location ID": "57373",
      "Location Name": "SAINT LAWRENCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 N COMMERCIAL AVE SAINT LAWRENCE SD 57373 USA "
    },
    {
      "Location ID": "57374",
      "Location Name": "SPENCER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 MAIN ST SPENCER SD 57374 USA "
    },
    {
      "Location ID": "57375",
      "Location Name": "STICKNEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 309 MAIN ST STICKNEY SD 57375 USA "
    },
    {
      "Location ID": "57376",
      "Location Name": "TRIPP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 S MAIN ST TRIPP SD 57376 USA "
    },
    {
      "Location ID": "57380",
      "Location Name": "WAGNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 2ND ST SE WAGNER SD 57380 USA "
    },
    {
      "Location ID": "57381",
      "Location Name": "WESSINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 170 WESSINGTON ST S WESSINGTON SD 57381 USA "
    },
    {
      "Location ID": "57382",
      "Location Name": "WESSINGTON SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 124 WALLACE AVE S WESSINGTON SPRINGS SD 57382 USA "
    },
    {
      "Location ID": "57383",
      "Location Name": "WHITE LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 S MAIN ST WHITE LAKE SD 57383 USA "
    },
    {
      "Location ID": "57384",
      "Location Name": "WOLSEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 COMMERCIAL AVE SW WOLSEY SD 57384 USA "
    },
    {
      "Location ID": "57385",
      "Location Name": "WOONSOCKET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 S DUMONT AVE WOONSOCKET SD 57385 USA "
    },
    {
      "Location ID": "573PR",
      "Location Name": "PRECISION RELOADING LLC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1700 W CEDAR AVE STE B MITCHELL SD 57301 USA "
    },
    {
      "Location ID": "573RP",
      "Location Name": "ARMOUR ROCKET PUB CO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 818 MAIN AVE ARMOUR SD 57313 USA "
    },
    {
      "Location ID": "573SJ",
      "Location Name": "ST JOSEPHS INDIAN SCHOOL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1301 N MAIN ST CHAMBERLAIN SD 57325 USA "
    },
    {
      "Location ID": "574",
      "Location Name": "ABERDEEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1202 S 5TH ST ABERDEEN SD 57401 USA "
    },
    {
      "Location ID": "57421",
      "Location Name": "AMHERST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 519 FRONT ST AMHERST SD 57421 USA "
    },
    {
      "Location ID": "57422",
      "Location Name": "ANDOVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 S MAIN ST ANDOVER SD 57422 USA "
    },
    {
      "Location ID": "57424",
      "Location Name": "ASHTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E MAIN ST ASHTON SD 57424 USA "
    },
    {
      "Location ID": "57426",
      "Location Name": "BARNARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 MAIN ST BARNARD SD 57426 USA "
    },
    {
      "Location ID": "57427",
      "Location Name": "BATH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 S MAIN ST BATH SD 57427 USA "
    },
    {
      "Location ID": "57428",
      "Location Name": "BOWDLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2086 MAIN ST BOWDLE SD 57428 USA "
    },
    {
      "Location ID": "57429",
      "Location Name": "BRENTFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 W 4TH ST BRENTFORD SD 57429 USA "
    },
    {
      "Location ID": "57430",
      "Location Name": "BRITTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 605 MAIN AVE BRITTON SD 57430 USA "
    },
    {
      "Location ID": "57433",
      "Location Name": "COLUMBIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5 N BROADWAY ST COLUMBIA SD 57433 USA "
    },
    {
      "Location ID": "57434",
      "Location Name": "CONDE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 180 BROADWAY ST NE CONDE SD 57434 USA "
    },
    {
      "Location ID": "57435",
      "Location Name": "CRESBARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 MAIN ST CRESBARD SD 57435 USA "
    },
    {
      "Location ID": "57436",
      "Location Name": "DOLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 226 HUMPHREY DR N DOLAND SD 57436 USA "
    },
    {
      "Location ID": "57437",
      "Location Name": "EUREKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 709 G AVE EUREKA SD 57437 USA "
    },
    {
      "Location ID": "57438",
      "Location Name": "FAULKTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 8TH AVE S FAULKTON SD 57438 USA "
    },
    {
      "Location ID": "57439",
      "Location Name": "FERNEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 CENTER ST FERNEY SD 57439 USA "
    },
    {
      "Location ID": "57440",
      "Location Name": "FRANKFORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 611 JEFFERSON AVE FRANKFORT SD 57440 USA "
    },
    {
      "Location ID": "57441",
      "Location Name": "FREDERICK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 413 MAIN ST FREDERICK SD 57441 USA "
    },
    {
      "Location ID": "57442",
      "Location Name": "GETTYSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 N EXENE ST GETTYSBURG SD 57442 USA "
    },
    {
      "Location ID": "57445",
      "Location Name": "GROTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 N MAIN ST GROTON SD 57445 USA "
    },
    {
      "Location ID": "57446",
      "Location Name": "HECLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 226 MAIN ST HECLA SD 57446 USA "
    },
    {
      "Location ID": "57448",
      "Location Name": "HOSMER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 E MAIN HOSMER SD 57448 USA "
    },
    {
      "Location ID": "57450",
      "Location Name": "HOVEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 262 MAIN ST HOVEN SD 57450 USA "
    },
    {
      "Location ID": "57451",
      "Location Name": "IPSWICH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 336 1ST AVE IPSWICH SD 57451 USA "
    },
    {
      "Location ID": "57452",
      "Location Name": "JAVA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4125 N MAIN ST JAVA SD 57452 USA "
    },
    {
      "Location ID": "57454",
      "Location Name": "LANGFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 309 MAIN ST LANGFORD SD 57454 USA "
    },
    {
      "Location ID": "57456",
      "Location Name": "LEOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5 S SHERMAN ST LEOLA SD 57456 USA "
    },
    {
      "Location ID": "57460",
      "Location Name": "MANSFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 E MAIN ST MANSFIELD SD 57460 USA "
    },
    {
      "Location ID": "57461",
      "Location Name": "MELLETTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 W MAIN ST MELLETTE SD 57461 USA "
    },
    {
      "Location ID": "57465",
      "Location Name": "NORTHVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 W ELM ST NORTHVILLE SD 57465 USA "
    },
    {
      "Location ID": "57466",
      "Location Name": "ONAKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 MAIN ST ONAKA SD 57466 USA "
    },
    {
      "Location ID": "57467",
      "Location Name": "ORIENT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 WINTER ST ORIENT SD 57467 USA "
    },
    {
      "Location ID": "57468",
      "Location Name": "PIERPONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 W MAIN ST PIERPONT SD 57468 USA "
    },
    {
      "Location ID": "57469",
      "Location Name": "REDFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24 E 6TH AVE REDFIELD SD 57469 USA "
    },
    {
      "Location ID": "57470",
      "Location Name": "ROCKHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 S BROADWAY AVE ROCKHAM SD 57470 USA "
    },
    {
      "Location ID": "57471",
      "Location Name": "ROSCOE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 N MAIN ST ROSCOE SD 57471 USA "
    },
    {
      "Location ID": "57472",
      "Location Name": "SELBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3408 MAIN ST SELBY SD 57472 USA "
    },
    {
      "Location ID": "57473",
      "Location Name": "SENECA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 PINE ST SENECA SD 57473 USA "
    },
    {
      "Location ID": "57475",
      "Location Name": "TOLSTOY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 5TH AVE NW RM 1 TOLSTOY SD 57475 USA "
    },
    {
      "Location ID": "57476",
      "Location Name": "TULARE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 MAIN ST TULARE SD 57476 USA "
    },
    {
      "Location ID": "57477",
      "Location Name": "TURTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 E CENTER ST TURTON SD 57477 USA "
    },
    {
      "Location ID": "57479",
      "Location Name": "WARNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 22 W MAIN ST WARNER SD 57479 USA "
    },
    {
      "Location ID": "57481",
      "Location Name": "WESTPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14 MAIN ST W WESTPORT SD 57481 USA "
    },
    {
      "Location ID": "574MP",
      "Location Name": "MIDSTATES PRINTING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4820 CAPITAL AVE NE ABERDEEN SD 57401 USA "
    },
    {
      "Location ID": "575",
      "Location Name": "PIERRE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 225 S PIERRE ST STE 101 PIERRE SD 57501 USA "
    },
    {
      "Location ID": "57520",
      "Location Name": "AGAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 ASH ST AGAR SD 57520 USA "
    },
    {
      "Location ID": "57521",
      "Location Name": "BELVIDERE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 406 B ST BELVIDERE SD 57521 USA "
    },
    {
      "Location ID": "57522",
      "Location Name": "BLUNT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 N MAIN ST BLUNT SD 57522 USA "
    },
    {
      "Location ID": "57523",
      "Location Name": "BURKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 833 MAIN ST BURKE SD 57523 USA "
    },
    {
      "Location ID": "57528",
      "Location Name": "COLOME",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 MAIN ST COLOME SD 57528 USA "
    },
    {
      "Location ID": "57531",
      "Location Name": "DRAPER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 147 S MAIN ST DRAPER SD 57531 USA "
    },
    {
      "Location ID": "57532",
      "Location Name": "FORT PIERRE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 412 N DEADWOOD ST FORT PIERRE SD 57532 USA "
    },
    {
      "Location ID": "57533",
      "Location Name": "GREGORY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 517 MAIN ST GREGORY SD 57533 USA "
    },
    {
      "Location ID": "57538",
      "Location Name": "HERRICK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 727 HERRICK AVE HERRICK SD 57538 USA "
    },
    {
      "Location ID": "57543",
      "Location Name": "KADOKA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1001 MAIN ST KADOKA SD 57543 USA "
    },
    {
      "Location ID": "57544",
      "Location Name": "KENNEBEC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 S MAIN ST KENNEBEC SD 57544 USA "
    },
    {
      "Location ID": "57547",
      "Location Name": "LONG VALLEY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 27199 SD HIGHWAY 73 LONG VALLEY SD 57547 USA "
    },
    {
      "Location ID": "57548",
      "Location Name": "LOWER BRULE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 312 CRAZY HORSE AVE LOWER BRULE SD 57548 USA "
    },
    {
      "Location ID": "57551",
      "Location Name": "MARTIN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 506 3RD AVE MARTIN SD 57551 USA "
    },
    {
      "Location ID": "57555",
      "Location Name": "MISSION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 E 3RD ST MISSION SD 57555 USA "
    },
    {
      "Location ID": "57559",
      "Location Name": "MURDO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 216 MAIN ST MURDO SD 57559 USA "
    },
    {
      "Location ID": "57564",
      "Location Name": "ONIDA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 703 BIRCH AVE ONIDA SD 57564 USA "
    },
    {
      "Location ID": "57567",
      "Location Name": "PHILIP",
      "Time Zone Offset (Hours)": -2,
      "Address": " 214 E PINE ST PHILIP SD 57567 USA "
    },
    {
      "Location ID": "57568",
      "Location Name": "PRESHO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 N MAIN AVE PRESHO SD 57568 USA "
    },
    {
      "Location ID": "57569",
      "Location Name": "RELIANCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 W HIGHWAY 47 RELIANCE SD 57569 USA "
    },
    {
      "Location ID": "57570",
      "Location Name": "ROSEBUD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 E SHARPFISH ST ROSEBUD SD 57570 USA "
    },
    {
      "Location ID": "57572",
      "Location Name": "SAINT FRANCIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 MAIN ST SAINT FRANCIS SD 57572 USA "
    },
    {
      "Location ID": "57576",
      "Location Name": "VIVIAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 S MAIN AVE VIVIAN SD 57576 USA "
    },
    {
      "Location ID": "57579",
      "Location Name": "WHITE RIVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 415 N MAIN ST WHITE RIVER SD 57579 USA "
    },
    {
      "Location ID": "57580",
      "Location Name": "WINNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 402 S MONROE ST WINNER SD 57580 USA "
    },
    {
      "Location ID": "57601",
      "Location Name": "MOBRIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 BURLINGTON ST SE STE 1 MOBRIDGE SD 57601 USA "
    },
    {
      "Location ID": "57623",
      "Location Name": "DUPREE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 323 S MAIN ST DUPREE SD 57623 USA "
    },
    {
      "Location ID": "57626",
      "Location Name": "FAITH",
      "Time Zone Offset (Hours)": -2,
      "Address": " 114 MAIN ST FAITH SD 57626 USA "
    },
    {
      "Location ID": "57632",
      "Location Name": "HERREID",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 MAIN ST N HERREID SD 57632 USA "
    },
    {
      "Location ID": "57633",
      "Location Name": "ISABEL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 200 MAIN ST ISABEL SD 57633 USA "
    },
    {
      "Location ID": "57638",
      "Location Name": "LEMMON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 310 1ST AVE W LEMMON SD 57638 USA "
    },
    {
      "Location ID": "57639",
      "Location Name": "LITTLE EAGLE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1 RUNNING ANTELOPE DR LITTLE EAGLE SD 57639 USA "
    },
    {
      "Location ID": "57641",
      "Location Name": "MC INTOSH",
      "Time Zone Offset (Hours)": -2,
      "Address": " 188 MAIN ST MC INTOSH SD 57641 USA "
    },
    {
      "Location ID": "57642",
      "Location Name": "MC LAUGHLIN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 221 MAIN ST MC LAUGHLIN SD 57642 USA "
    },
    {
      "Location ID": "57645",
      "Location Name": "MORRISTOWN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 223 MAIN ST MORRISTOWN SD 57645 USA "
    },
    {
      "Location ID": "57648",
      "Location Name": "POLLOCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 MAIN ST POLLOCK SD 57648 USA "
    },
    {
      "Location ID": "57656",
      "Location Name": "TIMBER LAKE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 801 MAIN ST TIMBER LAKE SD 57656 USA "
    },
    {
      "Location ID": "576MW",
      "Location Name": "ONE WORLD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1915 10TH AVE W MOBRIDGE SD 57601 USA "
    },
    {
      "Location ID": "577",
      "Location Name": "LOG RAPID CITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 500 EAST BLVD RAPID CITY SD 57701 USA "
    },
    {
      "Location ID": "57717",
      "Location Name": "BELLE FOURCHE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 804 STATE ST BELLE FOURCHE SD 57717 USA "
    },
    {
      "Location ID": "57718",
      "Location Name": "BLACK HAWK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 7501 CAPTAIN SOELZER ST BLACK HAWK SD 57718 USA "
    },
    {
      "Location ID": "57783",
      "Location Name": "SPEARFISH",
      "Time Zone Offset (Hours)": -2,
      "Address": " 120 YANKEE ST SPEARFISH SD 57783 USA "
    },
    {
      "Location ID": "57785",
      "Location Name": "STURGIS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 820 KINSHIP RD STURGIS SD 57785 USA "
    },
    {
      "Location ID": "577FM",
      "Location Name": "FENSKE MEDIA CORPORATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3635 HOMESTEAD ST RAPID CITY SD 57703 USA "
    },
    {
      "Location ID": "577HD",
      "Location Name": "BLACK HILLS HARLEY DAVIDSON, SD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2820 HARLEY DR RAPID CITY SD 57702 USA "
    },
    {
      "Location ID": "580",
      "Location Name": "FARGO ND P&DC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 657 2ND AVE N RM 104 FARGO ND 58102 USA "
    },
    {
      "Location ID": "58012",
      "Location Name": "CASSELTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 840 FRONT ST CASSELTON ND 58012 USA "
    },
    {
      "Location ID": "58021",
      "Location Name": "DAVENPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 50 1ST AVE DAVENPORT ND 58021 USA "
    },
    {
      "Location ID": "58027",
      "Location Name": "ENDERLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 309 RAILWAY ST ENDERLIN ND 58027 USA "
    },
    {
      "Location ID": "58047",
      "Location Name": "HORACE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 91 MAIN ST N HORACE ND 58047 USA "
    },
    {
      "Location ID": "58051",
      "Location Name": "KINDRED",
      "Time Zone Offset (Hours)": -1,
      "Address": " 472 ELM ST KINDRED ND 58051 USA "
    },
    {
      "Location ID": "58052",
      "Location Name": "LEONARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 406 RAILROAD AVE S LEONARD ND 58052 USA "
    },
    {
      "Location ID": "58054",
      "Location Name": "LISBON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 17 4TH AVE W LISBON ND 58054 USA "
    },
    {
      "Location ID": "58068",
      "Location Name": "SHELDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 216 FRONT ST SHELDON ND 58068 USA "
    },
    {
      "Location ID": "58072",
      "Location Name": "VALLEY CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 149 3RD ST NE VALLEY CITY ND 58072 USA "
    },
    {
      "Location ID": "58078",
      "Location Name": "WEST FARGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 415 MAIN AVE E WEST FARGO ND 58078 USA "
    },
    {
      "Location ID": "580AX",
      "Location Name": "FARGO PEAK SEASON ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2521 3RD AVE NW WEST FARGO ND 58078 USA "
    },
    {
      "Location ID": "581",
      "Location Name": "FARGO LOG DIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4007 33RD ST N STE A FARGO ND 58102 USA "
    },
    {
      "Location ID": "581AF",
      "Location Name": "FARGO ND ASF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1907 4TH AVE NW WEST FARGO ND 58078 USA "
    },
    {
      "Location ID": "581FX",
      "Location Name": "581FX FARGO FEDEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3847 31ST ST N FARGO ND 58102 USA "
    },
    {
      "Location ID": "582",
      "Location Name": "GRAND FORKS P&DC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2501 28TH AVE S GRAND FORKS ND 58201 USA "
    },
    {
      "Location ID": "58201",
      "Location Name": "GRAND FORKS ND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2501 28TH AVE S GRAND FORKS ND 58201 USA "
    },
    {
      "Location ID": "58202",
      "Location Name": "CPU UNIVERSITY OF ND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3701 CAMPUS RD STOP 7053 GRAND FORKS ND 58202 USA "
    },
    {
      "Location ID": "58204",
      "Location Name": "GRAND FORKS AFB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 590 HOLZAPPLE ST GRAND FORKS AFB ND 58204 USA "
    },
    {
      "Location ID": "58210",
      "Location Name": "ADAMS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 MAIN ST ADAMS ND 58210 USA "
    },
    {
      "Location ID": "58212",
      "Location Name": "ANETA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 MAIN AVE ANETA ND 58212 USA "
    },
    {
      "Location ID": "58214",
      "Location Name": "ARVILLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 HUGHES AVE ARVILLA ND 58214 USA "
    },
    {
      "Location ID": "58218",
      "Location Name": "BUXTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 309 BROADWAY BUXTON ND 58218 USA "
    },
    {
      "Location ID": "58220",
      "Location Name": "CAVALIER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 MAIN ST W CAVALIER ND 58220 USA "
    },
    {
      "Location ID": "58222",
      "Location Name": "CRYSTAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 332 3RD ST CRYSTAL ND 58222 USA "
    },
    {
      "Location ID": "58225",
      "Location Name": "DRAYTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 S MAIN ST DRAYTON ND 58225 USA "
    },
    {
      "Location ID": "58227",
      "Location Name": "EDINBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 250 MAIN AVE EDINBURG ND 58227 USA "
    },
    {
      "Location ID": "58228",
      "Location Name": "EMERADO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 MAIN ST EMERADO ND 58228 USA "
    },
    {
      "Location ID": "58229",
      "Location Name": "FAIRDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 MAIN ST FAIRDALE ND 58229 USA "
    },
    {
      "Location ID": "58230",
      "Location Name": "FINLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 4TH ST W FINLEY ND 58230 USA "
    },
    {
      "Location ID": "58231",
      "Location Name": "FORDVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 MAIN ST N FORDVILLE ND 58231 USA "
    },
    {
      "Location ID": "58233",
      "Location Name": "FOREST RIVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 FRONT ST FOREST RIVER ND 58233 USA "
    },
    {
      "Location ID": "58235",
      "Location Name": "GILBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 MAIN AVE GILBY ND 58235 USA "
    },
    {
      "Location ID": "58237",
      "Location Name": "GRAFTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 506 GRIGGS AVE GRAFTON ND 58237 USA "
    },
    {
      "Location ID": "58238",
      "Location Name": "HAMILTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 402 PAGE AVE STE A HAMILTON ND 58238 USA "
    },
    {
      "Location ID": "58240",
      "Location Name": "HATTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 611 RAILROAD AVE E HATTON ND 58240 USA "
    },
    {
      "Location ID": "58241",
      "Location Name": "HENSEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 E MAIN ST HENSEL ND 58241 USA "
    },
    {
      "Location ID": "58243",
      "Location Name": "HOOPLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 236 2ND ST HOOPLE ND 58243 USA "
    },
    {
      "Location ID": "58245",
      "Location Name": "JOHNSTOWN CPO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3252 JOHNSTOWN ST GILBY ND 58235 USA "
    },
    {
      "Location ID": "58249",
      "Location Name": "LANGDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 323 8TH AVE LANGDON ND 58249 USA "
    },
    {
      "Location ID": "58250",
      "Location Name": "LANKIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 MAIN ST LANKIN ND 58250 USA "
    },
    {
      "Location ID": "58251",
      "Location Name": "LARIMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 E FRONT ST LARIMORE ND 58251 USA "
    },
    {
      "Location ID": "58254",
      "Location Name": "MCVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 N MAIN ST MCVILLE ND 58254 USA "
    },
    {
      "Location ID": "58256",
      "Location Name": "MANVEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 502 OLDHAM AVE MANVEL ND 58256 USA "
    },
    {
      "Location ID": "58257",
      "Location Name": "MAYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 37 1ST AVE NE MAYVILLE ND 58257 USA "
    },
    {
      "Location ID": "58258",
      "Location Name": "MEKINOCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 MAIN ST MEKINOCK ND 58258 USA "
    },
    {
      "Location ID": "58259",
      "Location Name": "MICHIGAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 1ST ST E STE 4 MICHIGAN ND 58259 USA "
    },
    {
      "Location ID": "58261",
      "Location Name": "MINTO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 221 HARVEY AVE MINTO ND 58261 USA "
    },
    {
      "Location ID": "58265",
      "Location Name": "NECHE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 434 MAIN AVE NECHE ND 58265 USA "
    },
    {
      "Location ID": "58266",
      "Location Name": "NIAGARA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 N 2ND ST NIAGARA ND 58266 USA "
    },
    {
      "Location ID": "58267",
      "Location Name": "NORTHWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4 N RAYMOND ST NORTHWOOD ND 58267 USA "
    },
    {
      "Location ID": "58269",
      "Location Name": "OSNABROCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 326 BROADWAY OSNABROCK ND 58269 USA "
    },
    {
      "Location ID": "58270",
      "Location Name": "PARK RIVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 309 BRIGGS AVE S PARK RIVER ND 58270 USA "
    },
    {
      "Location ID": "58271",
      "Location Name": "PEMBINA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 554 W ROLETTE ST PEMBINA ND 58271 USA "
    },
    {
      "Location ID": "58272",
      "Location Name": "PETERSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 5TH ST PETERSBURG ND 58272 USA "
    },
    {
      "Location ID": "58273",
      "Location Name": "PISEK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 68 LOVICK AVE PISEK ND 58273 USA "
    },
    {
      "Location ID": "58274",
      "Location Name": "PORTLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 629 PARKE AVE PORTLAND ND 58274 USA "
    },
    {
      "Location ID": "58275",
      "Location Name": "REYNOLDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 321 4TH AVE REYNOLDS ND 58275 USA "
    },
    {
      "Location ID": "58276",
      "Location Name": "SAINT THOMAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 340 MAIN ST SAINT THOMAS ND 58276 USA "
    },
    {
      "Location ID": "58277",
      "Location Name": "SHARON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 MAIN ST SHARON ND 58277 USA "
    },
    {
      "Location ID": "58278",
      "Location Name": "THOMPSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 3RD ST THOMPSON ND 58278 USA "
    },
    {
      "Location ID": "58282",
      "Location Name": "WALHALLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 408 10TH ST WALHALLA ND 58282 USA "
    },
    {
      "Location ID": "58301",
      "Location Name": "DEVILS LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 502 3RD ST NE DEVILS LAKE ND 58301 USA "
    },
    {
      "Location ID": "58316",
      "Location Name": "BELCOURT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 EXPRESS LN BELCOURT ND 58316 USA "
    },
    {
      "Location ID": "58317",
      "Location Name": "BISBEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 MAIN ST BISBEE ND 58317 USA "
    },
    {
      "Location ID": "58318",
      "Location Name": "BOTTINEAU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 522 THOMPSON ST BOTTINEAU ND 58318 USA "
    },
    {
      "Location ID": "58321",
      "Location Name": "BROCKET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 MAIN ST E BROCKET ND 58321 USA "
    },
    {
      "Location ID": "58324",
      "Location Name": "CANDO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 403 5TH AVE CANDO ND 58324 USA "
    },
    {
      "Location ID": "58329",
      "Location Name": "DUNSEITH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 PEACE GARDEN AVE E DUNSEITH ND 58329 USA "
    },
    {
      "Location ID": "58330",
      "Location Name": "EDMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 315 S MAIN ST EDMORE ND 58330 USA "
    },
    {
      "Location ID": "58331",
      "Location Name": "EGELAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 MAIN ST EGELAND ND 58331 USA "
    },
    {
      "Location ID": "58332",
      "Location Name": "ESMOND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 1ST AVE N ESMOND ND 58332 USA "
    },
    {
      "Location ID": "58335",
      "Location Name": "FORT TOTTEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 816 3RD AVE N FORT TOTTEN ND 58335 USA "
    },
    {
      "Location ID": "58338",
      "Location Name": "HAMPDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 213 MAIN ST W HAMPDEN ND 58338 USA "
    },
    {
      "Location ID": "58341",
      "Location Name": "HARVEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 708 ADAMS AVE HARVEY ND 58341 USA "
    },
    {
      "Location ID": "58344",
      "Location Name": "LAKOTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 MAIN ST N LAKOTA ND 58344 USA "
    },
    {
      "Location ID": "58345",
      "Location Name": "LAWTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 MAIN ST LAWTON ND 58345 USA "
    },
    {
      "Location ID": "58346",
      "Location Name": "LEEDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 MAIN ST W LEEDS ND 58346 USA "
    },
    {
      "Location ID": "58348",
      "Location Name": "MADDOCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 CENTRAL AVE MADDOCK ND 58348 USA "
    },
    {
      "Location ID": "58351",
      "Location Name": "MINNEWAUKAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 151 MAIN ST E MINNEWAUKAN ND 58351 USA "
    },
    {
      "Location ID": "58352",
      "Location Name": "MUNICH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 MAIN ST MUNICH ND 58352 USA "
    },
    {
      "Location ID": "58356",
      "Location Name": "NEW ROCKFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 827 1ST AVE N NEW ROCKFORD ND 58356 USA "
    },
    {
      "Location ID": "58357",
      "Location Name": "OBERON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 MAIN AVE E OBERON ND 58357 USA "
    },
    {
      "Location ID": "58365",
      "Location Name": "ROCKLAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 44 MAIN ST ROCKLAKE ND 58365 USA "
    },
    {
      "Location ID": "58366",
      "Location Name": "ROLETTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 MAIN ST ROLETTE ND 58366 USA "
    },
    {
      "Location ID": "58367",
      "Location Name": "ROLLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 1ST AVE SE ROLLA ND 58367 USA "
    },
    {
      "Location ID": "58368",
      "Location Name": "RUGBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 2ND ST SE RUGBY ND 58368 USA "
    },
    {
      "Location ID": "58372",
      "Location Name": "SARLES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 MAIN ST SARLES ND 58372 USA "
    },
    {
      "Location ID": "58374",
      "Location Name": "SHEYENNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 RICHTER AVE SHEYENNE ND 58374 USA "
    },
    {
      "Location ID": "58377",
      "Location Name": "STARKWEATHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 MAIN ST STARKWEATHER ND 58377 USA "
    },
    {
      "Location ID": "58379",
      "Location Name": "TOKIO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 413 3RD AVE TOKIO ND 58379 USA "
    },
    {
      "Location ID": "58380",
      "Location Name": "TOLNA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 MAIN ST N TOLNA ND 58380 USA "
    },
    {
      "Location ID": "58384",
      "Location Name": "WILLOW CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 259 MAIN ST WILLOW CITY ND 58384 USA "
    },
    {
      "Location ID": "583HA",
      "Location Name": "HAMAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 1ST ST HAMAR ND 58380 USA "
    },
    {
      "Location ID": "583NC",
      "Location Name": "NORTH CENTRAL PRINTING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 VIKING DR RUGBY ND 58368 USA "
    },
    {
      "Location ID": "584",
      "Location Name": "JAMESTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 2ND ST SW JAMESTOWN ND 58401 USA "
    },
    {
      "Location ID": "58401",
      "Location Name": "JAMESTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 2ND ST SW JAMESTOWN ND 58401 USA "
    },
    {
      "Location ID": "58420",
      "Location Name": "BUCHANAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 MAIN ST BUCHANAN ND 58420 USA "
    },
    {
      "Location ID": "58426",
      "Location Name": "COURTENAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 98 1ST AVE N COURTENAY ND 58426 USA "
    },
    {
      "Location ID": "58433",
      "Location Name": "EDGELEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 511 MAIN ST EDGELEY ND 58433 USA "
    },
    {
      "Location ID": "58438",
      "Location Name": "FESSENDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 82 MAIN AVE S FESSENDEN ND 58438 USA "
    },
    {
      "Location ID": "58443",
      "Location Name": "GLENFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 161 GLENFIELD ST GLENFIELD ND 58443 USA "
    },
    {
      "Location ID": "58445",
      "Location Name": "GRACE CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 128 CITY ST GRACE CITY ND 58445 USA "
    },
    {
      "Location ID": "58455",
      "Location Name": "KENSAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 402 MAIN KENSAL ND 58455 USA "
    },
    {
      "Location ID": "58458",
      "Location Name": "LAMOURE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 23 CENTER AVE E LAMOURE ND 58458 USA "
    },
    {
      "Location ID": "58461",
      "Location Name": "LITCHVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 508 3RD AVE LITCHVILLE ND 58461 USA "
    },
    {
      "Location ID": "58463",
      "Location Name": "MCCLUSKY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 MAIN ST S MCCLUSKY ND 58463 USA "
    },
    {
      "Location ID": "58464",
      "Location Name": "MCHENRY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 322 JOHNSTON ST MCHENRY ND 58464 USA "
    },
    {
      "Location ID": "58466",
      "Location Name": "MARION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 308 MAIN AVE MARION ND 58466 USA "
    },
    {
      "Location ID": "58475",
      "Location Name": "PETTIBONE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 N MAIN ST PETTIBONE ND 58475 USA "
    },
    {
      "Location ID": "58478",
      "Location Name": "ROBINSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 1ST AVE E ROBINSON ND 58478 USA "
    },
    {
      "Location ID": "58481",
      "Location Name": "SPIRITWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 313 MAIN ST SPIRITWOOD ND 58481 USA "
    },
    {
      "Location ID": "58486",
      "Location Name": "SYKESTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 34 MAIN AVE N SYKESTON ND 58486 USA "
    },
    {
      "Location ID": "58488",
      "Location Name": "TUTTLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 N MAIN ST TUTTLE ND 58488 USA "
    },
    {
      "Location ID": "58492",
      "Location Name": "WIMBLEDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 3RD AVE WIMBLEDON ND 58492 USA "
    },
    {
      "Location ID": "58494",
      "Location Name": "WING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 MAIN ST WING ND 58494 USA "
    },
    {
      "Location ID": "58496",
      "Location Name": "WOODWORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 MAIN ST N WOODWORTH ND 58496 USA "
    },
    {
      "Location ID": "584LF",
      "Location Name": "LAMOURE FOODS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10220 HIGHWAY 13 LAMOURE ND 58458 USA "
    },
    {
      "Location ID": "584TF",
      "Location Name": "TOY FARMER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7496 106TH AVE SE EDGELEY ND 58458 USA "
    },
    {
      "Location ID": "585",
      "Location Name": "BISMARCK P&DF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2220 E BISMARCK EXPY BISMARCK ND 58504 USA "
    },
    {
      "Location ID": "58501",
      "Location Name": "BISMARCK DOWNTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 E ROSSER AVE RM 112 BISMARCK ND 58501 USA "
    },
    {
      "Location ID": "58504",
      "Location Name": "APO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 403 AIRPORT ROAD BISMARCK ND 58504 USA "
    },
    {
      "Location ID": "58521",
      "Location Name": "BALDWIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20101 26TH ST NE BALDWIN ND 58521 USA "
    },
    {
      "Location ID": "58523",
      "Location Name": "BEULAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 E MAIN ST BEULAH ND 58523 USA "
    },
    {
      "Location ID": "58528",
      "Location Name": "CANNON BALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 440 HORSESHOE RD CANNON BALL ND 58528 USA "
    },
    {
      "Location ID": "58529",
      "Location Name": "CARSON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 217 N MAIN ST CARSON ND 58529 USA "
    },
    {
      "Location ID": "58530",
      "Location Name": "CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 S CENTER AVE CENTER ND 58530 USA "
    },
    {
      "Location ID": "58531",
      "Location Name": "COLEHARBOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 MAIN ST COLEHARBOR ND 58531 USA "
    },
    {
      "Location ID": "58533",
      "Location Name": "ELGIN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 213 N MAIN ST ELGIN ND 58533 USA "
    },
    {
      "Location ID": "58535",
      "Location Name": "FLASHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 N MAIN ST FLASHER ND 58535 USA "
    },
    {
      "Location ID": "58538",
      "Location Name": "FORT YATES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 S RIVER RD FORT YATES ND 58538 USA "
    },
    {
      "Location ID": "58540",
      "Location Name": "GARRISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 33 1ST AVE NW GARRISON ND 58540 USA "
    },
    {
      "Location ID": "58541",
      "Location Name": "GOLDEN VALLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13 MAIN ST W GOLDEN VALLEY ND 58541 USA "
    },
    {
      "Location ID": "58542",
      "Location Name": "HAGUE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 MAIN AVE W HAGUE ND 58542 USA "
    },
    {
      "Location ID": "58544",
      "Location Name": "HAZELTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 345 MAIN ST HAZELTON ND 58544 USA "
    },
    {
      "Location ID": "58545",
      "Location Name": "HAZEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13 1ST AVE SW HAZEN ND 58545 USA "
    },
    {
      "Location ID": "58552",
      "Location Name": "LINTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 NW 1ST ST LINTON ND 58552 USA "
    },
    {
      "Location ID": "58553",
      "Location Name": "MCKENZIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 E MAIN AVE MCKENZIE ND 58553 USA "
    },
    {
      "Location ID": "58554",
      "Location Name": "MANDAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 COLLINS AVE MANDAN ND 58554 USA "
    },
    {
      "Location ID": "58558",
      "Location Name": "MENOKEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12 FRONT AVE E MENOKEN ND 58558 USA "
    },
    {
      "Location ID": "58559",
      "Location Name": "MERCER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 MAIN ST MERCER ND 58559 USA "
    },
    {
      "Location ID": "58560",
      "Location Name": "MOFFIT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 MAIN ST N MOFFIT ND 58560 USA "
    },
    {
      "Location ID": "58562",
      "Location Name": "NEW LEIPZIG",
      "Time Zone Offset (Hours)": -2,
      "Address": " 115 MAIN AVE NEW LEIPZIG ND 58562 USA "
    },
    {
      "Location ID": "58563",
      "Location Name": "NEW SALEM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 N 4TH ST NEW SALEM ND 58563 USA "
    },
    {
      "Location ID": "58565",
      "Location Name": "RIVERDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 2ND ST RIVERDALE ND 58565 USA "
    },
    {
      "Location ID": "58570",
      "Location Name": "SOLEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 N MAIN ST SOLEN ND 58570 USA "
    },
    {
      "Location ID": "58571",
      "Location Name": "STANTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 HARMON AVE STANTON ND 58571 USA "
    },
    {
      "Location ID": "58572",
      "Location Name": "STERLING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 MAIN AVE STERLING ND 58572 USA "
    },
    {
      "Location ID": "58573",
      "Location Name": "STRASBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 604 MAIN ST STRASBURG ND 58573 USA "
    },
    {
      "Location ID": "58575",
      "Location Name": "TURTLE LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 MAIN ST TURTLE LAKE ND 58575 USA "
    },
    {
      "Location ID": "58576",
      "Location Name": "UNDERWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 1ST ST UNDERWOOD ND 58576 USA "
    },
    {
      "Location ID": "58577",
      "Location Name": "WASHBURN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 721 MAIN AVE WASHBURN ND 58577 USA "
    },
    {
      "Location ID": "58579",
      "Location Name": "WILTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 25 DAKOTA AVE WILTON ND 58579 USA "
    },
    {
      "Location ID": "58580",
      "Location Name": "ZAP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 MAIN ST ZAP ND 58580 USA "
    },
    {
      "Location ID": "58581",
      "Location Name": "ZEELAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 S MAIN ZEELAND ND 58581 USA "
    },
    {
      "Location ID": "585AX",
      "Location Name": "Bismarck Christmas Annex",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1200 INDUSTRIAL DR BISMARCK ND 58501-3317 USA "
    },
    {
      "Location ID": "585DA",
      "Location Name": "DAN'S CPU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 BURLINGTON ST SE STE 1 MANDAN ND 58554 USA "
    },
    {
      "Location ID": "585DN",
      "Location Name": "CPU DANS SUPER 3125",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3101 N 11TH ST STE 1 BISMARCK ND 58503 USA "
    },
    {
      "Location ID": "585DS",
      "Location Name": "CPU DANS SUPER 3126",
      "Time Zone Offset (Hours)": -1,
      "Address": " 835 S WASHINGTON ST STE 1 BISMARCK ND 58504 USA "
    },
    {
      "Location ID": "586",
      "Location Name": "DICKINSON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 15 1ST ST E DICKINSON ND 58601 USA "
    },
    {
      "Location ID": "58601",
      "Location Name": "DICKINSON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 15 1ST ST E DICKINSON ND 58601 USA "
    },
    {
      "Location ID": "58621",
      "Location Name": "BEACH",
      "Time Zone Offset (Hours)": -2,
      "Address": " 177 S CENTRAL AVE BEACH ND 58621 USA "
    },
    {
      "Location ID": "58622",
      "Location Name": "BELFIELD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 105 1ST AVE NW BELFIELD ND 58622 USA "
    },
    {
      "Location ID": "58623",
      "Location Name": "BOWMAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 17 1ST AVE SE BOWMAN ND 58623 USA "
    },
    {
      "Location ID": "58625",
      "Location Name": "DODGE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 105 CENTRAL AVE DODGE ND 58625 USA "
    },
    {
      "Location ID": "58630",
      "Location Name": "GLADSTONE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 114 5TH AVE GLADSTONE ND 58630 USA "
    },
    {
      "Location ID": "58631",
      "Location Name": "GLEN ULLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 S MAIN ST GLEN ULLIN ND 58631 USA "
    },
    {
      "Location ID": "58634",
      "Location Name": "GRASSY BUTTE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 409 MAIN ST GRASSY BUTTE ND 58634 USA "
    },
    {
      "Location ID": "58636",
      "Location Name": "HALLIDAY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 113 E MAIN ST HALLIDAY ND 58636 USA "
    },
    {
      "Location ID": "58638",
      "Location Name": "HEBRON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 S PARK ST HEBRON ND 58638 USA "
    },
    {
      "Location ID": "58639",
      "Location Name": "HETTINGER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 502 ADAMS AVE HETTINGER ND 58639 USA "
    },
    {
      "Location ID": "58640",
      "Location Name": "KILLDEER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 48 CENTRAL AVE S KILLDEER ND 58640 USA "
    },
    {
      "Location ID": "58645",
      "Location Name": "MEDORA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 355 3RD AVE MEDORA ND 58645 USA "
    },
    {
      "Location ID": "58646",
      "Location Name": "MOTT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 123 BROWN AVE MOTT ND 58646 USA "
    },
    {
      "Location ID": "58647",
      "Location Name": "NEW ENGLAND",
      "Time Zone Offset (Hours)": -2,
      "Address": " 826 MCKENZIE ST NEW ENGLAND ND 58647 USA "
    },
    {
      "Location ID": "58649",
      "Location Name": "REEDER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 204 MAIN ST REEDER ND 58649 USA "
    },
    {
      "Location ID": "58652",
      "Location Name": "RICHARDTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 111 B ST N RICHARDTON ND 58652 USA "
    },
    {
      "Location ID": "58653",
      "Location Name": "SCRANTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 205 2ND AVE N SCRANTON ND 58653 USA "
    },
    {
      "Location ID": "58655",
      "Location Name": "SOUTH HEART",
      "Time Zone Offset (Hours)": -2,
      "Address": " 401 RUDOLPH AVE NW SOUTH HEART ND 58655 USA "
    },
    {
      "Location ID": "58656",
      "Location Name": "TAYLOR",
      "Time Zone Offset (Hours)": -2,
      "Address": " 111 W MAIN ST TAYLOR ND 58656 USA "
    },
    {
      "Location ID": "586DA",
      "Location Name": "DANS CPU DICKINSON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 302 E. 1ST ST. DICKINSON ND 58601 USA "
    },
    {
      "Location ID": "587",
      "Location Name": "MINOT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 5TH AVE SW MINOT ND 58701 USA "
    },
    {
      "Location ID": "58703",
      "Location Name": "FEDEX TERMINAL (MINOT), ND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 515 AIRPORT RD MINOT ND 58703 USA "
    },
    {
      "Location ID": "58704",
      "Location Name": "MINOT AFB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 PEACEKEEPER PL MINOT AFB ND 58704 USA "
    },
    {
      "Location ID": "58705",
      "Location Name": "MINOT AFB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 PEACEKEEPER PL MINOT ND 58705 USA "
    },
    {
      "Location ID": "58710",
      "Location Name": "ANAMOOSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 602 MAIN ST ANAMOOSE ND 58710 USA "
    },
    {
      "Location ID": "58711",
      "Location Name": "ANTLER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 MAIN ST ANTLER ND 58711 USA "
    },
    {
      "Location ID": "58718",
      "Location Name": "BERTHOLD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 MAIN ST N BERTHOLD ND 58718 USA "
    },
    {
      "Location ID": "58721",
      "Location Name": "BOWBELLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 MAIN ST NE BOWBELLS ND 58721 USA "
    },
    {
      "Location ID": "58722",
      "Location Name": "BURLINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 129 COLTON AVE BURLINGTON ND 58722 USA "
    },
    {
      "Location ID": "58725",
      "Location Name": "CARPIO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 520 MAIN ST CARPIO ND 58725 USA "
    },
    {
      "Location ID": "58727",
      "Location Name": "COLUMBUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 MAIN ST COLUMBUS ND 58727 USA "
    },
    {
      "Location ID": "58730",
      "Location Name": "CROSBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 MAIN ST NW CROSBY ND 58730 USA "
    },
    {
      "Location ID": "58733",
      "Location Name": "DES LACS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 MAIN ST S DES LACS ND 58733 USA "
    },
    {
      "Location ID": "58735",
      "Location Name": "DOUGLAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 MAIN ST S. DOUGLAS ND 58735 USA "
    },
    {
      "Location ID": "58736",
      "Location Name": "DRAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 MAIN ST DRAKE ND 58736 USA "
    },
    {
      "Location ID": "58740",
      "Location Name": "GLENBURN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 MAIN ST GLENBURN ND 58740 USA "
    },
    {
      "Location ID": "58741",
      "Location Name": "GRANVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 1ST ST SW GRANVILLE ND 58741 USA "
    },
    {
      "Location ID": "58744",
      "Location Name": "KARLSRUHE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 MAIN ST N KARLSRUHE ND 58744 USA "
    },
    {
      "Location ID": "58746",
      "Location Name": "KENMARE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 22 2ND ST NW KENMARE ND 58746 USA "
    },
    {
      "Location ID": "58750",
      "Location Name": "LANSFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 706 MAIN ST LANSFORD ND 58750 USA "
    },
    {
      "Location ID": "58752",
      "Location Name": "LIGNITE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 MAIN ST LIGNITE ND 58752 USA "
    },
    {
      "Location ID": "58756",
      "Location Name": "MAKOTI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 MAIN ST N MAKOTI ND 58756 USA "
    },
    {
      "Location ID": "58758",
      "Location Name": "MARTIN ND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 BALLMAN AVE MARTIN ND 58758 USA "
    },
    {
      "Location ID": "58759",
      "Location Name": "MAX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 MAIN ST MAX ND 58759 USA "
    },
    {
      "Location ID": "58760",
      "Location Name": "MAXBASS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 MAIN ST MAXBASS ND 58760 USA "
    },
    {
      "Location ID": "58761",
      "Location Name": "MOHALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 MAIN ST E MOHALL ND 58761 USA "
    },
    {
      "Location ID": "58762",
      "Location Name": "NEWBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 380 MAIN ST NEWBURG ND 58762 USA "
    },
    {
      "Location ID": "58763",
      "Location Name": "NEW TOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 361 MAIN ST NEW TOWN ND 58763 USA "
    },
    {
      "Location ID": "58765",
      "Location Name": "NOONAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 S MAIN ST NOONAN ND 58765 USA "
    },
    {
      "Location ID": "58770",
      "Location Name": "PARSHALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 MAIN ST S PARSHALL ND 58770 USA "
    },
    {
      "Location ID": "58771",
      "Location Name": "PLAZA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10 E PLAZA ST PLAZA ND 58771 USA "
    },
    {
      "Location ID": "58773",
      "Location Name": "POWERS LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 MAIN ST POWERS LAKE ND 58773 USA "
    },
    {
      "Location ID": "58776",
      "Location Name": "ROSS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 MAIN ST ROSS ND 58776 USA "
    },
    {
      "Location ID": "58779",
      "Location Name": "RYDER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 280 N MAIN ST RYDER ND 58779 USA "
    },
    {
      "Location ID": "58781",
      "Location Name": "SAWYER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20 MAIN ST S SAWYER ND 58781 USA "
    },
    {
      "Location ID": "58782",
      "Location Name": "SHERWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 144 MAIN ST SHERWOOD ND 58782 USA "
    },
    {
      "Location ID": "58783",
      "Location Name": "SOURIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 MAIN ST SOURIS ND 58783 USA "
    },
    {
      "Location ID": "58784",
      "Location Name": "STANLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 902 JARARD ST SE STANLEY ND 58784 USA "
    },
    {
      "Location ID": "58785",
      "Location Name": "SURREY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 PLEASANT AVE N SURREY ND 58785 USA "
    },
    {
      "Location ID": "58788",
      "Location Name": "TOWNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 MAIN ST S TOWNER ND 58788 USA "
    },
    {
      "Location ID": "58790",
      "Location Name": "VELVA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 MAIN ST N VELVA ND 58790 USA "
    },
    {
      "Location ID": "58792",
      "Location Name": "VOLTAIRE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9 MAIN ST VOLTAIRE ND 58792 USA "
    },
    {
      "Location ID": "58793",
      "Location Name": "WESTHOPE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 180 MAIN ST WESTHOPE ND 58793 USA "
    },
    {
      "Location ID": "587NT",
      "Location Name": "NEW TOWN VPO (CENEX STATION)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 712 1ST.ST N. NEW TOWN ND 58763 USA "
    },
    {
      "Location ID": "587TC",
      "Location Name": "VAN HOOK TP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8258 39TH STREET NW NEW TOWN ND 58763 USA "
    },
    {
      "Location ID": "58801",
      "Location Name": "WILLISTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 E. BROADWAY WILLISTON ND 58801 USA "
    },
    {
      "Location ID": "58803",
      "Location Name": "BADLANDS STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4315 9TH AVENUE WEST WILLISTON ND 58801 USA "
    },
    {
      "Location ID": "58830",
      "Location Name": "ALAMO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24 MAIN ST ALAMO ND 58830 USA "
    },
    {
      "Location ID": "58831",
      "Location Name": "ALEXANDER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 602 ELK ST ALEXANDER ND 58831 USA "
    },
    {
      "Location ID": "58835",
      "Location Name": "ARNEGARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 MAIN ST ARNEGARD ND 58835 USA "
    },
    {
      "Location ID": "58843",
      "Location Name": "EPPING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 MAIN AVE EPPING ND 58843 USA "
    },
    {
      "Location ID": "58845",
      "Location Name": "GRENORA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 MAIN ST GRENORA ND 58845 USA "
    },
    {
      "Location ID": "58849",
      "Location Name": "RAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6 MAIN ST RAY ND 58849 USA "
    },
    {
      "Location ID": "58852",
      "Location Name": "TIOGA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24 N MAIN ST TIOGA ND 58852 USA "
    },
    {
      "Location ID": "58854",
      "Location Name": "WATFORD CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1204 4TH AVE NE WATFORD CITY ND 58854 USA "
    },
    {
      "Location ID": "58856",
      "Location Name": "ZAHL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 MAIN ST ZAHL ND 58856 USA "
    },
    {
      "Location ID": "588BA",
      "Location Name": "BADLANDS PO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4315 9TH AVE W UNIT 411 WILLISTON ND 58801 USA "
    },
    {
      "Location ID": "588CW",
      "Location Name": "CASHWISE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 311TH ST WEST WILLISTON ND 58801 USA "
    },
    {
      "Location ID": "588VP",
      "Location Name": "VPO COLLECTION BOX TIOGA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6714 HWY 40 TIOGA ND 58852 USA "
    },
    {
      "Location ID": "590",
      "Location Name": "LOG BILLINGS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 841 S 26TH ST BILLINGS MT 59101 USA "
    },
    {
      "Location ID": "59006",
      "Location Name": "BALLANTINE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1605 ASH ST BALLANTINE MT 59006 USA "
    },
    {
      "Location ID": "59008",
      "Location Name": "BELFRY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 115 VAILL AVE BELFRY MT 59008 USA "
    },
    {
      "Location ID": "59010",
      "Location Name": "BIGHORN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 138 BIGHORN RD BIGHORN MT 59010 USA "
    },
    {
      "Location ID": "59013",
      "Location Name": "BOYD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 9076 HWY 212 BOYD MT 59013 USA "
    },
    {
      "Location ID": "59014",
      "Location Name": "BRIDGER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 113 N MAIN ST BRIDGER MT 59014 USA "
    },
    {
      "Location ID": "59018",
      "Location Name": "CLYDE PARK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 103 1ST AVE N CLYDE PARK MT 59018 USA "
    },
    {
      "Location ID": "59024",
      "Location Name": "CUSTER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 413 2ND AVE CUSTER MT 59024 USA "
    },
    {
      "Location ID": "59026",
      "Location Name": "EDGAR",
      "Time Zone Offset (Hours)": -2,
      "Address": " 201 ELWELL ST EDGAR MT 59026 USA "
    },
    {
      "Location ID": "59027",
      "Location Name": "EMIGRANT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 305 STORY RD EMIGRANT MT 59027 USA "
    },
    {
      "Location ID": "59029",
      "Location Name": "FROMBERG",
      "Time Zone Offset (Hours)": -2,
      "Address": " 123 W RIVER ST FROMBERG MT 59029 USA "
    },
    {
      "Location ID": "59030",
      "Location Name": "GARDINER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 707 SCOTT ST W GARDINER MT 59030 USA "
    },
    {
      "Location ID": "59031",
      "Location Name": "GARRYOWEN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4185 GARRYOWEN RD GARRYOWEN MT 59031 USA "
    },
    {
      "Location ID": "59037",
      "Location Name": "HUNTLEY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 155 NORTHERN AVE HUNTLEY MT 59037 USA "
    },
    {
      "Location ID": "59038",
      "Location Name": "HYSHAM",
      "Time Zone Offset (Hours)": -2,
      "Address": " 118 SPURLING ST HYSHAM MT 59038 USA "
    },
    {
      "Location ID": "59041",
      "Location Name": "JOLIET",
      "Time Zone Offset (Hours)": -2,
      "Address": " 120 E FRONT AVE JOLIET MT 59041 USA "
    },
    {
      "Location ID": "59044",
      "Location Name": "LAUREL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 614 1ST AVE LAUREL MT 59044 USA "
    },
    {
      "Location ID": "59047",
      "Location Name": "LIVINGSTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 105 N 2ND ST FL 1 LIVINGSTON MT 59047 USA "
    },
    {
      "Location ID": "5904A",
      "Location Name": "LIVINGSTON ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 230 JEFFERSON ST LIVINGSTON MT 59047 USA "
    },
    {
      "Location ID": "59050",
      "Location Name": "LODGE GRASS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 14 S MAIN ST LODGE GRASS MT 59050 USA "
    },
    {
      "Location ID": "59064",
      "Location Name": "POMPEYS PILLAR",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3519 2ND ST S POMPEYS PILLAR MT 59064 USA "
    },
    {
      "Location ID": "59065",
      "Location Name": "PRAY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 8 PRAY RD PRAY MT 59065 USA "
    },
    {
      "Location ID": "59066",
      "Location Name": "PRYOR",
      "Time Zone Offset (Hours)": -2,
      "Address": " 130 MAKPUA AVE PRYOR MT 59066 USA "
    },
    {
      "Location ID": "59068",
      "Location Name": "RED LODGE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 119 S HAUSER AVE RED LODGE MT 59068 USA "
    },
    {
      "Location ID": "59070",
      "Location Name": "ROBERTS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1 N 1ST ST ROBERTS MT 59070 USA "
    },
    {
      "Location ID": "59079",
      "Location Name": "SHEPHERD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5450 CAREY AVE SHEPHERD MT 59079 USA "
    },
    {
      "Location ID": "59086",
      "Location Name": "WILSALL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 310 ELLIOT ST N WILSALL MT 59086 USA "
    },
    {
      "Location ID": "59088",
      "Location Name": "WORDEN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2427 MAIN ST WORDEN MT 59088 USA "
    },
    {
      "Location ID": "59089",
      "Location Name": "WYOLA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 15 S MONDELL AVE WYOLA MT 59089 USA "
    },
    {
      "Location ID": "590AX",
      "Location Name": "BILLINGS PEAK ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 332 S PLAINVIEW ST BILLINGS MT 59101 USA "
    },
    {
      "Location ID": "590PL",
      "Location Name": "PRINTING FOR LESS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 100 PFL WAY LIVINGSTON MT 59047 USA "
    },
    {
      "Location ID": "591",
      "Location Name": "BILLINGS ASF",
      "Time Zone Offset (Hours)": -2,
      "Address": " 841 S 26TH ST BILLINGS MT 59101 USA "
    },
    {
      "Location ID": "59101",
      "Location Name": "BILLINGS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 841 S 26TH ST BILLINGS MT 59101 USA "
    },
    {
      "Location ID": "59103",
      "Location Name": "DOWNTOWN BILLINGS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2602 1ST AVE N FL 1 BILLINGS MT 59101 USA "
    },
    {
      "Location ID": "59105",
      "Location Name": "RONALD REAGAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 710 WICKS LN BILLINGS MT 59105 USA "
    },
    {
      "Location ID": "591DS",
      "Location Name": "DOLLAR STORE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1327 MAIN ST BILLINGS MT 59105 USA "
    },
    {
      "Location ID": "591OD",
      "Location Name": "OFFICE DEPOT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 323 LAKE ELMO DR BILLINGS MT 59105 USA "
    },
    {
      "Location ID": "591SC",
      "Location Name": "SCHEELS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1121 SHILOH CROSSING BLVD BILLINGS MT 59102 USA "
    },
    {
      "Location ID": "592",
      "Location Name": "WOLF POINT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 131 FRONT ST WOLF POINT MT 59201 USA "
    },
    {
      "Location ID": "59212",
      "Location Name": "BAINVILLE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 306 CLINTON AVE BAINVILLE MT 59212 USA "
    },
    {
      "Location ID": "59213",
      "Location Name": "BROCKTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 701 B ST BROCKTON MT 59213 USA "
    },
    {
      "Location ID": "59218",
      "Location Name": "CULBERTSON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 21 3RD ST E CULBERTSON MT 59218 USA "
    },
    {
      "Location ID": "59255",
      "Location Name": "POPLAR",
      "Time Zone Offset (Hours)": -2,
      "Address": " 105 E ST W POPLAR MT 59255 USA "
    },
    {
      "Location ID": "593",
      "Location Name": "MILES CITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 106 N 7TH ST MILES CITY MT 59301 USA "
    },
    {
      "Location ID": "59330",
      "Location Name": "GLENDIVE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 221 N KENDRICK AVE GLENDIVE MT 59330 USA "
    },
    {
      "Location ID": "594",
      "Location Name": "LOG GREAT FALLS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1409 14TH ST SW GREAT FALLS MT 59404 USA "
    },
    {
      "Location ID": "59401",
      "Location Name": "GREAT FALLS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 215 1ST AVE N GREAT FALLS MT 59401 USA "
    },
    {
      "Location ID": "59402",
      "Location Name": "MALSTROM AFB",
      "Time Zone Offset (Hours)": -2,
      "Address": " MALSTROM AIR FORCE BASE MALMSTROM A F B MT 59402 USA "
    },
    {
      "Location ID": "59405",
      "Location Name": "CHARLES M RUSSELL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3400 9TH AVE S GREAT FALLS MT 59405 USA "
    },
    {
      "Location ID": "59410",
      "Location Name": "AUGUSTA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 102 WALRATH ST AUGUSTA MT 59410 USA "
    },
    {
      "Location ID": "59412",
      "Location Name": "BELT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 314 BRIDGE ST BELT MT 59412 USA "
    },
    {
      "Location ID": "59416",
      "Location Name": "BRADY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 107 CENTRAL AVE E LOWR BRADY MT 59416 USA "
    },
    {
      "Location ID": "59417",
      "Location Name": "BROWNING",
      "Time Zone Offset (Hours)": -2,
      "Address": " 316 N PIEGAN ST BROWNING MT 59417 USA "
    },
    {
      "Location ID": "59419",
      "Location Name": "BYNUM",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5520 HIGHWAY 89 N BYNUM MT 59419 USA "
    },
    {
      "Location ID": "59421",
      "Location Name": "CASCADE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 105 3RD AVE S CASCADE MT 59421 USA "
    },
    {
      "Location ID": "59422",
      "Location Name": "CHOTEAU",
      "Time Zone Offset (Hours)": -2,
      "Address": " 103 1ST ST NW CHOTEAU MT 59422 USA "
    },
    {
      "Location ID": "59425",
      "Location Name": "CONRAD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 14 5TH AVE SE CONRAD MT 59425 USA "
    },
    {
      "Location ID": "59427",
      "Location Name": "CUT BANK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 37 S CENTRAL AVE CUT BANK MT 59427 USA "
    },
    {
      "Location ID": "59432",
      "Location Name": "DUPUYER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 110 MONTANA ST DUPUYER MT 59432 USA "
    },
    {
      "Location ID": "59433",
      "Location Name": "DUTTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 77 SW 1ST ST DUTTON MT 59433 USA "
    },
    {
      "Location ID": "59434",
      "Location Name": "EAST GLACIER PARK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 15 BLACKFOOT AVE EAST GLACIER PARK MT 59434 USA "
    },
    {
      "Location ID": "59435",
      "Location Name": "ETHRIDGE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 26667 US HIGHWAY 2 ETHRIDGE MT 59435 USA "
    },
    {
      "Location ID": "59436",
      "Location Name": "FAIRFIELD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 409 1ST AVE S FAIRFIELD MT 59436 USA "
    },
    {
      "Location ID": "59443",
      "Location Name": "FORT SHAW",
      "Time Zone Offset (Hours)": -2,
      "Address": " 13356 MT HIGHWAY 200 FORT SHAW MT 59443 USA "
    },
    {
      "Location ID": "59447",
      "Location Name": "GEYSER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 110 E HILL AVE GEYSER MT 59447 USA "
    },
    {
      "Location ID": "59448",
      "Location Name": "HEART BUTTE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 22 WHITETAIL CREEK RD HEART BUTTE MT 59448 USA "
    },
    {
      "Location ID": "59452",
      "Location Name": "HOBSON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 109 CENTRAL AVE HOBSON MT 59452 USA "
    },
    {
      "Location ID": "59454",
      "Location Name": "KEVIN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 11 FRONT ST KEVIN MT 59454 USA "
    },
    {
      "Location ID": "59457",
      "Location Name": "LEWISTOWN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 204 3RD AVE N LEWISTOWN MT 59457 USA "
    },
    {
      "Location ID": "59462",
      "Location Name": "MOCCASIN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 202 1ST AVE W MOCCASIN MT 59462 USA "
    },
    {
      "Location ID": "59467",
      "Location Name": "PENDROY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 121 MAIN ST PENDROY MT 59467 USA "
    },
    {
      "Location ID": "59468",
      "Location Name": "POWER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 406 CENTRAL AVE POWER MT 59468 USA "
    },
    {
      "Location ID": "59469",
      "Location Name": "RAYNESFORD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 101 W MAIN ST RAYNESFORD MT 59469 USA "
    },
    {
      "Location ID": "59474",
      "Location Name": "SHELBY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 145 1ST AVE SE SHELBY MT 59474 USA "
    },
    {
      "Location ID": "59477",
      "Location Name": "SIMMS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 310 N MAIN ST SIMMS MT 59477 USA "
    },
    {
      "Location ID": "59479",
      "Location Name": "STANFORD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 80 CENTRAL AVE STANFORD MT 59479 USA "
    },
    {
      "Location ID": "59482",
      "Location Name": "SUNBURST",
      "Time Zone Offset (Hours)": -2,
      "Address": " 28 1ST ST N SUNBURST MT 59482 USA "
    },
    {
      "Location ID": "59483",
      "Location Name": "SUN RIVER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 220 W CARROLL ST SUN RIVER MT 59483 USA "
    },
    {
      "Location ID": "59484",
      "Location Name": "SWEET GRASS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 334 CHURCH ST SWEET GRASS MT 59484 USA "
    },
    {
      "Location ID": "59485",
      "Location Name": "ULM",
      "Time Zone Offset (Hours)": -2,
      "Address": " 6 MILLEGAN RD ULM MT 59485 USA "
    },
    {
      "Location ID": "59486",
      "Location Name": "VALIER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 515 MONTANA VALIER MT 59486 USA "
    },
    {
      "Location ID": "59487",
      "Location Name": "VAUGHN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 380 POST AVE VAUGHN MT 59487 USA "
    },
    {
      "Location ID": "594MA",
      "Location Name": "MALSTROM AFB ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " MALSTROM AIR FORCE BASE GREAT FALLS MT 59402 USA "
    },
    {
      "Location ID": "595",
      "Location Name": "HAVRE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 115 3RD ST HAVRE MT 59501 USA "
    },
    {
      "Location ID": "59520",
      "Location Name": "BIG SANDY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 103 JOHANNES AVE BIG SANDY MT 59520 USA "
    },
    {
      "Location ID": "59521",
      "Location Name": "BOX ELDER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 40 1ST AVE SW BOX ELDER MT 59521 USA "
    },
    {
      "Location ID": "59522",
      "Location Name": "CHESTER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 25 MAIN ST CHESTER MT 59522 USA "
    },
    {
      "Location ID": "59523",
      "Location Name": "CHINOOK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 113 3RD ST W CHINOOK MT 59523 USA "
    },
    {
      "Location ID": "59524",
      "Location Name": "DODSON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 300 BARRETT AVE DODSON MT 59524 USA "
    },
    {
      "Location ID": "59525",
      "Location Name": "GILDFORD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 525 1ST AVE GILDFORD MT 59525 USA "
    },
    {
      "Location ID": "59526",
      "Location Name": "HARLEM",
      "Time Zone Offset (Hours)": -2,
      "Address": " 216 2ND ST SW HARLEM MT 59526 USA "
    },
    {
      "Location ID": "59528",
      "Location Name": "HINGHAM",
      "Time Zone Offset (Hours)": -2,
      "Address": " 508 2ND ST HINGHAM MT 59528 USA "
    },
    {
      "Location ID": "59530",
      "Location Name": "INVERNESS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 130 MAIN ST INVERNESS MT 59530 USA "
    },
    {
      "Location ID": "59531",
      "Location Name": "JOPLIN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 23 2ND ST JOPLIN MT 59531 USA "
    },
    {
      "Location ID": "59532",
      "Location Name": "KREMLIN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 210 CENTRAL AVE KREMLIN MT 59532 USA "
    },
    {
      "Location ID": "59538",
      "Location Name": "MALTA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 207 S 1ST AVE E MALTA MT 59538 USA "
    },
    {
      "Location ID": "59540",
      "Location Name": "RUDYARD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 15 MAIN ST N RUDYARD MT 59540 USA "
    },
    {
      "Location ID": "59547",
      "Location Name": "ZURICH",
      "Time Zone Offset (Hours)": -2,
      "Address": " 150 1ST AVE ZURICH MT 59547 USA "
    },
    {
      "Location ID": "596",
      "Location Name": "HELENA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2300 N HARRIS ST HELENA MT 59601 USA "
    },
    {
      "Location ID": "59631",
      "Location Name": "BASIN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 100 BASIN ST BASIN MT 59631 USA "
    },
    {
      "Location ID": "59632",
      "Location Name": "BOULDER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 126 S MAIN ST BOULDER MT 59632 USA "
    },
    {
      "Location ID": "59634",
      "Location Name": "CLANCY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2 N MAIN ST CLANCY MT 59634 USA "
    },
    {
      "Location ID": "59638",
      "Location Name": "JEFFERSON CITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1 N MAIN ST JEFFERSON CITY MT 59638 USA "
    },
    {
      "Location ID": "597",
      "Location Name": "BUTTE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 701 DEWEY BLVD BUTTE MT 59701 USA "
    },
    {
      "Location ID": "59701",
      "Location Name": "BUTTE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 701 DEWEY BLVD BUTTE MT 59701 USA "
    },
    {
      "Location ID": "59710",
      "Location Name": "ALDER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2325 MT HIGHWAY 287 ALDER MT 59710 USA "
    },
    {
      "Location ID": "59711",
      "Location Name": "ANACONDA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 218 MAIN ST ANACONDA MT 59711 USA "
    },
    {
      "Location ID": "59715",
      "Location Name": "BABCOCK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 32 E BABCOCK ST BOZEMAN MT 59715 USA "
    },
    {
      "Location ID": "59718",
      "Location Name": "BOZEMAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2201 BAXTER LN BOZEMAN MT 59718 USA "
    },
    {
      "Location ID": "59722",
      "Location Name": "DEER LODGE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 510 MAIN ST DEER LODGE MT 59722 USA "
    },
    {
      "Location ID": "59724",
      "Location Name": "DELL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 24 MAIN ST DELL MT 59724 USA "
    },
    {
      "Location ID": "59725",
      "Location Name": "DILLON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 117 S IDAHO ST DILLON MT 59725 USA "
    },
    {
      "Location ID": "59727",
      "Location Name": "DIVIDE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 43 N RIVER RD DIVIDE MT 59727 USA "
    },
    {
      "Location ID": "59729",
      "Location Name": "ENNIS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 81 MT HIGHWAY 287 ENNIS MT 59729 USA "
    },
    {
      "Location ID": "59735",
      "Location Name": "HARRISON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 7552 US HIGHWAY 287 N HARRISON MT 59735 USA "
    },
    {
      "Location ID": "59739",
      "Location Name": "LIMA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 700 PEAT ST LIMA MT 59739 USA "
    },
    {
      "Location ID": "59740",
      "Location Name": "MC ALLISTER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5557 US HIGHWAY 287 MC ALLISTER MT 59740 USA "
    },
    {
      "Location ID": "59743",
      "Location Name": "MELROSE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 228 MAIN ST MELROSE MT 59743 USA "
    },
    {
      "Location ID": "59745",
      "Location Name": "NORRIS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 6536 US HIGHWAY 287 NORRIS MT 59745 USA "
    },
    {
      "Location ID": "59747",
      "Location Name": "PONY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3 REEL ST PONY MT 59747 USA "
    },
    {
      "Location ID": "59748",
      "Location Name": "RAMSAY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1 BEULAH ST RAMSAY MT 59748 USA "
    },
    {
      "Location ID": "59749",
      "Location Name": "SHERIDAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 208 S MAIN ST SHERIDAN MT 59749 USA "
    },
    {
      "Location ID": "59751",
      "Location Name": "SILVER STAR",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5342 MT HIGHWAY 41 N SILVER STAR MT 59751 USA "
    },
    {
      "Location ID": "59752",
      "Location Name": "THREE FORKS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 9 E FRONT ST THREE FORKS MT 59752 USA "
    },
    {
      "Location ID": "59754",
      "Location Name": "TWIN BRIDGES",
      "Time Zone Offset (Hours)": -2,
      "Address": " 101 S MAIN ST TWIN BRIDGES MT 59754 USA "
    },
    {
      "Location ID": "59755",
      "Location Name": "VIRGINIA CITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 209 W WALLACE VIRGINIA CITY MT 59755 USA "
    },
    {
      "Location ID": "59756",
      "Location Name": "WARM SPRINGS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 22 GARNET WAY WARM SPRINGS MT 59756 USA "
    },
    {
      "Location ID": "59759",
      "Location Name": "WHITEHALL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 15 N MAIN ST WHITEHALL MT 59759 USA "
    },
    {
      "Location ID": "59760",
      "Location Name": "WILLOW CREEK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 14 MAIN ST WILLOW CREEK MT 59760 USA "
    },
    {
      "Location ID": "597FX",
      "Location Name": "597FX FEDEX RETAIL UNIT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 125 BASIN CREEK RD BUTTE MT 59701 USA "
    },
    {
      "Location ID": "598",
      "Location Name": "LOG MISSOULA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1100 W KENT AVE MISSOULA MT 59801 USA "
    },
    {
      "Location ID": "59801",
      "Location Name": "MISSOULA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1100 W KENT AVE MISSOULA MT 59801 USA "
    },
    {
      "Location ID": "59845",
      "Location Name": "HOT SPRINGS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 111 SPRING ST HOT SPRINGS MT 59845 USA "
    },
    {
      "Location ID": "59859",
      "Location Name": "PLAINS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 310 W MEANY ST PLAINS MT 59859 USA "
    },
    {
      "Location ID": "598FX",
      "Location Name": "598FX FEDEX MSO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5500 MOMONT RD MISSOULA MT 59808 USA "
    },
    {
      "Location ID": "599",
      "Location Name": "KALISPELL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 350 N MERIDIAN RD KALISPELL MT 59901 USA "
    },
    {
      "Location ID": "600",
      "Location Name": "LOG PALATINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1300 E NORTHWEST HWY PALATINE IL 60095 USA "
    },
    {
      "Location ID": "60002",
      "Location Name": "ANTIOCH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 420 ORCHARD ST ANTIOCH IL 60002 USA "
    },
    {
      "Location ID": "60004",
      "Location Name": "ARLINGTON HEIGHTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 909 W EUCLID AVE ARLINGTON HEIGHTS IL 60005 USA "
    },
    {
      "Location ID": "60007",
      "Location Name": "ELK GROVE VILLAGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 611 LANDMEIER RD ELK GROVE VILLAGE IL 60007 USA "
    },
    {
      "Location ID": "60008",
      "Location Name": "ROLLING MEADOWS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3266 KIRCHOFF RD ROLLING MEADOWS IL 60008 USA "
    },
    {
      "Location ID": "60015",
      "Location Name": "DEERFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 707 OSTERMAN AVE DEERFIELD IL 60015 USA "
    },
    {
      "Location ID": "60018",
      "Location Name": "DES PLAINES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1000 E OAKTON ST DES PLAINES IL 60018 USA "
    },
    {
      "Location ID": "60022",
      "Location Name": "GLENCOE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 336 HAZEL AVE GLENCOE IL 60022 USA "
    },
    {
      "Location ID": "60025",
      "Location Name": "GLENVIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1400 PATRIOT BLVD GLENVIEW IL 60026 USA "
    },
    {
      "Location ID": "60029",
      "Location Name": "GOLF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 BRIAR RD GOLF IL 60029 USA "
    },
    {
      "Location ID": "60030",
      "Location Name": "GRAYSLAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 75 COMMERCE DR GRAYSLAKE IL 60030 USA "
    },
    {
      "Location ID": "60031",
      "Location Name": "GURNEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 N OPLAINE RD GURNEE IL 60031 USA "
    },
    {
      "Location ID": "60035",
      "Location Name": "HIGHLAND PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 833 CENTRAL AVE HIGHLAND PARK IL 60035 USA "
    },
    {
      "Location ID": "60040",
      "Location Name": "HIGHWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 HIGHWOOD AVE HIGHWOOD IL 60040 USA "
    },
    {
      "Location ID": "60043",
      "Location Name": "KENILWORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 408 GREEN BAY RD KENILWORTH IL 60043 USA "
    },
    {
      "Location ID": "60044",
      "Location Name": "LAKE BLUFF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 26 E SCRANTON AVE LAKE BLUFF IL 60044 USA "
    },
    {
      "Location ID": "60045",
      "Location Name": "LAKE FOREST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 230 NORTHGATE ST LAKE FOREST IL 60045 USA "
    },
    {
      "Location ID": "60046",
      "Location Name": "LAKE VILLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 CEDAR AVE LAKE VILLA IL 60046 USA "
    },
    {
      "Location ID": "60048",
      "Location Name": "LIBERTYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1520 ARTAIUS PKWY LIBERTYVILLE IL 60048 USA "
    },
    {
      "Location ID": "60053",
      "Location Name": "MORTON GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9114 WAUKEGAN RD MORTON GROVE IL 60053 USA "
    },
    {
      "Location ID": "60056",
      "Location Name": "MOUNT PROSPECT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 W CENTRAL RD MOUNT PROSPECT IL 60056 USA "
    },
    {
      "Location ID": "60060",
      "Location Name": "MUNDELEIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 435 E HAWLEY ST MUNDELEIN IL 60060 USA "
    },
    {
      "Location ID": "60061",
      "Location Name": "VERNON HILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 675 LAKEVIEW PKWY VERNON HILLS IL 60061 USA "
    },
    {
      "Location ID": "60062",
      "Location Name": "NORTHBROOK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2460 DUNDEE RD NORTHBROOK IL 60062 USA "
    },
    {
      "Location ID": "60064",
      "Location Name": "NORTH CHICAGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1514 SHERIDAN RD NORTH CHICAGO IL 60064 USA "
    },
    {
      "Location ID": "60067",
      "Location Name": "PALATINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 440 W COLFAX ST PALATINE IL 60067 USA "
    },
    {
      "Location ID": "60068",
      "Location Name": "PARK RIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 333 BUSSE HWY PARK RIDGE IL 60068 USA "
    },
    {
      "Location ID": "60069",
      "Location Name": "LINCOLNSHIRE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 SCHELTER RD LINCOLNSHIRE IL 60069 USA "
    },
    {
      "Location ID": "60070",
      "Location Name": "PROSPECT HEIGHTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9 S ELMHURST RD PROSPECT HEIGHTS IL 60070 USA "
    },
    {
      "Location ID": "60073",
      "Location Name": "ROUND LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1940 N MUNICIPAL WAY ROUND LAKE IL 60073 USA "
    },
    {
      "Location ID": "60076",
      "Location Name": "SKOKIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4950 MADISON ST SKOKIE IL 60077 USA "
    },
    {
      "Location ID": "60083",
      "Location Name": "WADSWORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 39067 N CAROLINE AVE WADSWORTH IL 60083 USA "
    },
    {
      "Location ID": "60085",
      "Location Name": "WAUKEGAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 326 N GENESEE ST WAUKEGAN IL 60085 USA "
    },
    {
      "Location ID": "60087",
      "Location Name": "WAUKEGAN EDISON SQ STA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1519 N LEWIS AVE WAUKEGAN IL 60085 USA "
    },
    {
      "Location ID": "60088",
      "Location Name": "GREAT LAKES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 525 BRONSON AVE GREAT LAKES IL 60088 USA "
    },
    {
      "Location ID": "60089",
      "Location Name": "BUFFALO GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 255 N BUFFALO GROVE RD BUFFALO GROVE IL 60089 USA "
    },
    {
      "Location ID": "60090",
      "Location Name": "WHEELING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 250 W DUNDEE RD WHEELING IL 60090 USA "
    },
    {
      "Location ID": "60091",
      "Location Name": "WILMETTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1241 CENTRAL AVE WILMETTE IL 60091 USA "
    },
    {
      "Location ID": "60093",
      "Location Name": "WINNETKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 512 CHESTNUT ST WINNETKA IL 60093 USA "
    },
    {
      "Location ID": "60096",
      "Location Name": "WINTHROP HARBOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1225 SHERIDAN RD STE F WINTHROP HARBOR IL 60096 USA "
    },
    {
      "Location ID": "60099",
      "Location Name": "ZION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1225 SHILOH BLVD ZION IL 60099 USA "
    },
    {
      "Location ID": "600FZ",
      "Location Name": "LOG BUSSE IL PSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2600 LIVELY BLVD ELK GROVE IL 60007 USA "
    },
    {
      "Location ID": "600LF",
      "Location Name": "LAKE FOREST CARRIER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 27848 N BRADLEY RD LAKE FOREST IL 60045 USA "
    },
    {
      "Location ID": "600SG",
      "Location Name": "SEGERDAHL GRAPHICS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 770 S WOLF RD WHEELING IL 60090 USA "
    },
    {
      "Location ID": "600SR",
      "Location Name": "SEGRADAHL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 311 GILMAN AVE WHEELING IL 60090 USA "
    },
    {
      "Location ID": "601",
      "Location Name": "LOG CAROL STREAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 FULLERTON AVE CAROL STREAM IL 60199 USA "
    },
    {
      "Location ID": "60106",
      "Location Name": "BENSENVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 E GREEN ST BENSENVILLE IL 60106 USA "
    },
    {
      "Location ID": "60111",
      "Location Name": "CLARE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 26450 CLARE RD CLARE IL 60111 USA "
    },
    {
      "Location ID": "60113",
      "Location Name": "CRESTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 N MAIN ST CRESTON IL 60113 USA "
    },
    {
      "Location ID": "60115",
      "Location Name": "DEKALB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 600 E LINCOLN HWY DEKALB IL 60115 USA "
    },
    {
      "Location ID": "60119",
      "Location Name": "ELBURN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 815 N MAIN ST ELBURN IL 60119 USA "
    },
    {
      "Location ID": "60123",
      "Location Name": "ELGIN WEST SIDE ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 195 S RANDALL RD ELGIN IL 60123 USA "
    },
    {
      "Location ID": "60126",
      "Location Name": "ELMHURST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 154 W PARK AVE ELMHURST IL 60126 USA "
    },
    {
      "Location ID": "60129",
      "Location Name": "ESMOND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 650 EYCHANER RD ESMOND IL 60129 USA "
    },
    {
      "Location ID": "60134",
      "Location Name": "GENEVA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 26 S 3RD ST GENEVA IL 60134 USA "
    },
    {
      "Location ID": "60135",
      "Location Name": "GENOA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 705 PEARSON DR GENOA IL 60135 USA "
    },
    {
      "Location ID": "60137",
      "Location Name": "GLEN ELLYN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 757 DU PAGE BLVD GLEN ELLYN IL 60137 USA "
    },
    {
      "Location ID": "60142",
      "Location Name": "HUNTLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11013 N WOODSTOCK ST HUNTLEY IL 60142 USA "
    },
    {
      "Location ID": "60148",
      "Location Name": "LOMBARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 380 E SAINT CHARLES RD LOMBARD IL 60148 USA "
    },
    {
      "Location ID": "60150",
      "Location Name": "MALTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 403 E ADAMS ST MALTA IL 60150 USA "
    },
    {
      "Location ID": "60174",
      "Location Name": "ST CHARLES EAST SIDE STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 616 E MAIN ST SAINT CHARLES IL 60174 USA "
    },
    {
      "Location ID": "60178",
      "Location Name": "SYCAMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 E STATE ST SYCAMORE IL 60178 USA "
    },
    {
      "Location ID": "60181",
      "Location Name": "VILLA PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1415 S ARDMORE AVE VILLA PARK IL 60181 USA "
    },
    {
      "Location ID": "60185",
      "Location Name": "WEST CHICAGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1130 W WASHINGTON ST WEST CHICAGO IL 60185 USA "
    },
    {
      "Location ID": "60187",
      "Location Name": "WHEATON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 122 N WHEATON AVE WHEATON IL 60187 USA "
    },
    {
      "Location ID": "601AI",
      "Location Name": "EPSILON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1240 E NORTH AVE WEST CHICAGO IL 60185 USA "
    },
    {
      "Location ID": "601AN",
      "Location Name": "AMERICAN LITHO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 175 MERCEDES DR CAROL STREAM IL 60188 USA "
    },
    {
      "Location ID": "601AW",
      "Location Name": "UPS MAIL INNOVATIONS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 490 SUPREME DR BENSENVILLE IL 60106 USA "
    },
    {
      "Location ID": "601AX",
      "Location Name": "UNITED STATIONERS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 810 KIMBERLY DR CAROL STREAM IL 60188 USA "
    },
    {
      "Location ID": "601BI",
      "Location Name": "American Litho",
      "Time Zone Offset (Hours)": -1,
      "Address": " 175 MERCEDES DR CAROL STREAM IL 60188 USA "
    },
    {
      "Location ID": "601CI",
      "Location Name": "CENTRAL ILLINOIS PSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7506 ROOSEVELT RD FOREST PARK IL 60130 USA "
    },
    {
      "Location ID": "601CW",
      "Location Name": "CONTINENTAL WEB DMU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1430 INDUSTRIAL DR ITASCA IL 60143 USA "
    },
    {
      "Location ID": "601DD",
      "Location Name": "DEMAR DIRECT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1133 N RIDGE LOMBARD IL 60148 USA "
    },
    {
      "Location ID": "601DH",
      "Location Name": "DHL GLOBAL MAIL - MELROSE PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2407 W NORTH AVE MELROSE PARK IL 60160 USA "
    },
    {
      "Location ID": "601DM",
      "Location Name": "DIAMOND MARKETING SOLUTIONS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 KIMBERLY DR CAROL STREAM IL 60188 USA "
    },
    {
      "Location ID": "601DS",
      "Location Name": "DIGITAL IMAGE SOLUTIONS-ITASCA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1430 W THORNDALE AVE ITASCA IL 60143 USA "
    },
    {
      "Location ID": "601LD",
      "Location Name": "LEHIGH DIRECT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1900 S 25TH AVE BROADVIEW IL 60155 USA "
    },
    {
      "Location ID": "601MW",
      "Location Name": "RR DONNELLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1750 WALLACE AVE SAINT CHARLES IL 60174 USA "
    },
    {
      "Location ID": "601OS",
      "Location Name": "ONE STOP MAILING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 601 REGENCY DR GLENDALE HEIGHTS IL 60139 USA "
    },
    {
      "Location ID": "601PA",
      "Location Name": "CHICAGO SURFACE HUB PEAK ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 872 THOMAS DR BENSENVILLE IL 60106 USA "
    },
    {
      "Location ID": "601SC",
      "Location Name": "SEND-IT CORP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 901 W ALGONQUIN RD ALGONQUIN IL 60102 USA "
    },
    {
      "Location ID": "601UB",
      "Location Name": "UNITED BUSINESS MAIL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11697 W GRAND AVE NORTHLAKE IL 60164 USA "
    },
    {
      "Location ID": "602",
      "Location Name": "EVANSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1101 DAVIS ST EVANSTON IL 60201 USA "
    },
    {
      "Location ID": "604",
      "Location Name": "LOG SOUTH SUBURBAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6801 W 73RD ST BEDFORD PARK IL 60499 USA "
    },
    {
      "Location ID": "60409",
      "Location Name": "CALUMET CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1200 RING RD CALUMET CITY IL 60409 USA "
    },
    {
      "Location ID": "60411",
      "Location Name": "CHICAGO HEIGHTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1333 W END AVE CHICAGO HEIGHTS IL 60411 USA "
    },
    {
      "Location ID": "60419",
      "Location Name": "DOLTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14212 COTTAGE GROVE AVE DOLTON IL 60419 USA "
    },
    {
      "Location ID": "60423",
      "Location Name": "FRANKFORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 21201 S ELSNER RD FRANKFORT IL 60423 USA "
    },
    {
      "Location ID": "60425",
      "Location Name": "GLENWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 19 W MAIN ST GLENWOOD IL 60425 USA "
    },
    {
      "Location ID": "60426",
      "Location Name": "HARVEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15441 CENTER AVE HARVEY IL 60426 USA "
    },
    {
      "Location ID": "60430",
      "Location Name": "HOMEWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1921 RIDGE RD HOMEWOOD IL 60430 USA "
    },
    {
      "Location ID": "60438",
      "Location Name": "LANSING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 18125 ROY ST LANSING IL 60438 USA "
    },
    {
      "Location ID": "60443",
      "Location Name": "MATTESON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20650 S CICERO AVE MATTESON IL 60443 USA "
    },
    {
      "Location ID": "60448",
      "Location Name": "MOKENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 19934 WOLF RD MOKENA IL 60448 USA "
    },
    {
      "Location ID": "60455",
      "Location Name": "BRIDGEVIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7401 W 100TH PL BRIDGEVIEW IL 60455 USA "
    },
    {
      "Location ID": "60462",
      "Location Name": "ORLAND PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9500 W 144TH PL ORLAND PARK IL 60462 USA "
    },
    {
      "Location ID": "60466",
      "Location Name": "PARK FOREST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 INDIANWOOD BLVD PARK FOREST IL 60466 USA "
    },
    {
      "Location ID": "60473",
      "Location Name": "SOUTH HOLLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16260 LOUIS AVE SOUTH HOLLAND IL 60473 USA "
    },
    {
      "Location ID": "60476",
      "Location Name": "THORNTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 E ELEANOR ST THORNTON IL 60476 USA "
    },
    {
      "Location ID": "60477",
      "Location Name": "TINLEY PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7230 171ST ST TINLEY PARK IL 60477 USA "
    },
    {
      "Location ID": "60482",
      "Location Name": "WORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11114 S HARLEM AVE WORTH IL 60482 USA "
    },
    {
      "Location ID": "604AA",
      "Location Name": "LEMONT ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15871 W 127TH ST LEMONT IL 60439 USA "
    },
    {
      "Location ID": "604AL",
      "Location Name": "AMERICAN LOGISTICS GROUP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1053 N SCHMIDT RD ROMEOVILLE IL 60446 USA "
    },
    {
      "Location ID": "604AN",
      "Location Name": "TRI CITY CARRIER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 701 W HOLBROOK RD GLENWOOD IL 60425 USA "
    },
    {
      "Location ID": "604AO",
      "Location Name": "AMAZON/ORD9",
      "Time Zone Offset (Hours)": -1,
      "Address": " 23700 W BLUFF RD A CHANNAHON IL 60410 USA "
    },
    {
      "Location ID": "604EM",
      "Location Name": "EXECUTIVE MAILING SERVICE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7855 W 111TH ST STE 1 PALOS HILLS IL 60465 USA "
    },
    {
      "Location ID": "604JM",
      "Location Name": "JETSON MIDWEST MAIL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8711 S 77TH AVE BRIDGEVIEW IL 60455 USA "
    },
    {
      "Location ID": "604QW",
      "Location Name": "QUAD GRAPHICS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1290 REMINGTON BLVD BOLINGBROOK IL 60490 USA "
    },
    {
      "Location ID": "604SM",
      "Location Name": "THE CALMARK GROUP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6751 S SAYRE AVE CHICAGO IL 60638 USA "
    },
    {
      "Location ID": "604VP",
      "Location Name": "VICTORY PACKAGING - BOLINGBROOK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 365 CROSSING RD BOLINBROOK IL 60440 USA "
    },
    {
      "Location ID": "605",
      "Location Name": "LOG FOX VALLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3900 GABRIELLE LN FOX VALLEY IL 60599 USA "
    },
    {
      "Location ID": "60504",
      "Location Name": "AURORA POST OFFICE",
      "Time Zone Offset (Hours)": -1,
      "Address": " FOX VALLEY MALL AURORA IL 60507 USA "
    },
    {
      "Location ID": "60510",
      "Location Name": "BATAVIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 N RANDALL RD BATAVIA IL 60510 USA "
    },
    {
      "Location ID": "60511",
      "Location Name": "BIG ROCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 E 1ST ST BIG ROCK IL 60511 USA "
    },
    {
      "Location ID": "60512",
      "Location Name": "BRISTOL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11 CANNONBALL TRL BRISTOL IL 60512 USA "
    },
    {
      "Location ID": "60514",
      "Location Name": "CLARENDON HILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 312 PARK AVE CLARENDON HILLS IL 60514 USA "
    },
    {
      "Location ID": "60518",
      "Location Name": "EARLVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 W WINTHROP ST EARLVILLE IL 60518 USA "
    },
    {
      "Location ID": "60519",
      "Location Name": "EOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6S404 OLD EOLA RD EOLA IL 60519 USA "
    },
    {
      "Location ID": "60520",
      "Location Name": "HINCKLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 557 E LINCOLN AVE HINCKLEY IL 60520 USA "
    },
    {
      "Location ID": "60521",
      "Location Name": "HINSDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 SYMONDS DR HINSDALE IL 60521 USA "
    },
    {
      "Location ID": "60525",
      "Location Name": "LA GRANGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 W HILLGROVE AVE LA GRANGE IL 60525 USA "
    },
    {
      "Location ID": "60527",
      "Location Name": "WBD CARRIER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7140 MONROE ST WILLOWBROOK IL 60527 USA "
    },
    {
      "Location ID": "60530",
      "Location Name": "LEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 E LEE RD LEE IL 60530 USA "
    },
    {
      "Location ID": "60531",
      "Location Name": "LELAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 N MAIN ST LELAND IL 60531 USA "
    },
    {
      "Location ID": "60532",
      "Location Name": "LISLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 817 OGDEN AVE LISLE IL 60532 USA "
    },
    {
      "Location ID": "60536",
      "Location Name": "MILLBROOK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 27 HARRIS AVE MILLBROOK IL 60536 USA "
    },
    {
      "Location ID": "60537",
      "Location Name": "MILLINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 N GRANT ST MILLINGTON IL 60537 USA "
    },
    {
      "Location ID": "60538",
      "Location Name": "MONTGOMERY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1899 VILLAGE CENTER PKWY MONTGOMERY IL 60538 USA "
    },
    {
      "Location ID": "60540",
      "Location Name": "NAPERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1750 W OGDEN AVE NAPERVILLE IL 60540 USA "
    },
    {
      "Location ID": "60541",
      "Location Name": "NEWARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11 E MAIN ST NEWARK IL 60541 USA "
    },
    {
      "Location ID": "60542",
      "Location Name": "NORTH AURORA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 65 S RANDALL RD NORTH AURORA IL 60542 USA "
    },
    {
      "Location ID": "60543",
      "Location Name": "OSWEGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 26 S MADISON ST OSWEGO IL 60543 USA "
    },
    {
      "Location ID": "60544",
      "Location Name": "PLAINFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14855 S VAN DYKE RD PLAINFIELD IL 60544 USA "
    },
    {
      "Location ID": "60545",
      "Location Name": "PLANO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 N CENTER ST PLANO IL 60545 USA "
    },
    {
      "Location ID": "60548",
      "Location Name": "SANDWICH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 22 N EDDY ST SANDWICH IL 60548 USA "
    },
    {
      "Location ID": "60549",
      "Location Name": "SERENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3809 E 2276TH RD SERENA IL 60549 USA "
    },
    {
      "Location ID": "60550",
      "Location Name": "SHABBONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206C W COMANCHE AVE SHABBONA IL 60550 USA "
    },
    {
      "Location ID": "60551",
      "Location Name": "SHERIDAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 180 W SI JOHNSON AVE SHERIDAN IL 60551 USA "
    },
    {
      "Location ID": "60552",
      "Location Name": "SOMONAUK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 N GAGE ST SOMONAUK IL 60552 USA "
    },
    {
      "Location ID": "60553",
      "Location Name": "STEWARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 403 MAIN ST STE A STEWARD IL 60553 USA "
    },
    {
      "Location ID": "60554",
      "Location Name": "SUGAR GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 45 E CROSS ST SUGAR GROVE IL 60554 USA "
    },
    {
      "Location ID": "60555",
      "Location Name": "WARRENVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3S101 ROCKWELL ST WARRENVILLE IL 60555 USA "
    },
    {
      "Location ID": "60556",
      "Location Name": "WATERMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 N ELM ST WATERMAN IL 60556 USA "
    },
    {
      "Location ID": "605FI",
      "Location Name": "FREEDOM GRAPHICS SYSTEMS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 780 MCCLURE RD AURORA IL 60502 USA "
    },
    {
      "Location ID": "605MC",
      "Location Name": "MARKETING CARD TECHNOLOGY LLC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8245 LEMONT RD DARIEN IL 60561 USA "
    },
    {
      "Location ID": "605PS",
      "Location Name": "LSC COMMUNICATIONS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2707 N EOLA RD STE C AURORA IL 60502 USA "
    },
    {
      "Location ID": "605SF",
      "Location Name": "FGS-WI, LLC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1301 FRONTENAC RD NAPERVILLE IL 60563 USA "
    },
    {
      "Location ID": "605TB",
      "Location Name": "VISION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 605 TERRITORIAL DR STE C BOLINGBROOK IL 60440 USA "
    },
    {
      "Location ID": "606",
      "Location Name": "LOG CHICAGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 433 W HARRISON ST STE 3012A CHICAGO IL 60699 USA "
    },
    {
      "Location ID": "606AA",
      "Location Name": "WEST GLEN STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 715 W GLEN AVE PEORIA IL 60601 USA "
    },
    {
      "Location ID": "606DP",
      "Location Name": "Rapid Displays",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4300 W 47TH ST CHICAGO IL 60632 USA "
    },
    {
      "Location ID": "607",
      "Location Name": "CHICAGO ISC NORTH P&DC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11560 W IRVING PARK RD CHICAGO IL 60131 USA "
    },
    {
      "Location ID": "60712",
      "Location Name": "EDGEBK/LINCOLNWOOD ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6500 N LINCOLN AVE STE 1 LINCOLNWOOD IL 60712 USA "
    },
    {
      "Location ID": "607SP",
      "Location Name": "SPECIALTY PRINT COMMUNICATIONS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7420 N LEHIGH AVE NILES IL 60714 USA "
    },
    {
      "Location ID": "609",
      "Location Name": "KANKAKEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 475 E COURT ST KANKAKEE IL 60901 USA "
    },
    {
      "Location ID": "60902",
      "Location Name": "KANKAKEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 475 E COURT ST KANKAKEE IL 60901 USA "
    },
    {
      "Location ID": "60910",
      "Location Name": "AROMA PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 S BRIDGE ST AROMA PARK IL 60910 USA "
    },
    {
      "Location ID": "60911",
      "Location Name": "ASHKUM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 E MAIN ST ASHKUM IL 60911 USA "
    },
    {
      "Location ID": "60912",
      "Location Name": "BEAVERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 702 SAINT CHARLES ST BEAVERVILLE IL 60912 USA "
    },
    {
      "Location ID": "60913",
      "Location Name": "BONFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 157 W SMITH ST BONFIELD IL 60913 USA "
    },
    {
      "Location ID": "60914",
      "Location Name": "BOURBONNAIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 850 MAIN ST NW BOURBONNAIS IL 60914 USA "
    },
    {
      "Location ID": "60915",
      "Location Name": "BRADLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 258 W BROADWAY ST BRADLEY IL 60915 USA "
    },
    {
      "Location ID": "60917",
      "Location Name": "BUCKINGHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 RAILROAD ST BUCKINGHAM IL 60917 USA "
    },
    {
      "Location ID": "60918",
      "Location Name": "BUCKLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 N RAILROAD AVE BUCKLEY IL 60918 USA "
    },
    {
      "Location ID": "60919",
      "Location Name": "CABERY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 MAIN ST CABERY IL 60919 USA "
    },
    {
      "Location ID": "60920",
      "Location Name": "CAMPUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 CENTER ST CAMPUS IL 60920 USA "
    },
    {
      "Location ID": "60921",
      "Location Name": "CHATSWORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15 N 4TH ST CHATSWORTH IL 60921 USA "
    },
    {
      "Location ID": "60922",
      "Location Name": "CHEBANSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 S CHESTNUT ST CHEBANSE IL 60922 USA "
    },
    {
      "Location ID": "60924",
      "Location Name": "CISSNA PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 129 W GARFIELD AVE CISSNA PARK IL 60924 USA "
    },
    {
      "Location ID": "60926",
      "Location Name": "CLAYTONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 MANNING ST CLAYTONVILLE IL 60926 USA "
    },
    {
      "Location ID": "60927",
      "Location Name": "CLIFTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 175 E 6TH AVE CLIFTON IL 60927 USA "
    },
    {
      "Location ID": "60928",
      "Location Name": "CRESCENT CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 405 MAIN ST CRESCENT CITY IL 60928 USA "
    },
    {
      "Location ID": "60929",
      "Location Name": "CULLOM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 W HACK ST CULLOM IL 60929 USA "
    },
    {
      "Location ID": "60930",
      "Location Name": "DANFORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 N FRONT ST DANFORTH IL 60930 USA "
    },
    {
      "Location ID": "60931",
      "Location Name": "DONOVAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 E 2ND ST DONOVAN IL 60931 USA "
    },
    {
      "Location ID": "60932",
      "Location Name": "EAST LYNN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 41495 MAIN ST EAST LYNN IL 60932 USA "
    },
    {
      "Location ID": "60933",
      "Location Name": "ELLIOTT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 S MAIN ST ELLIOTT IL 60933 USA "
    },
    {
      "Location ID": "60934",
      "Location Name": "EMINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 1/2 WABASH ST EMINGTON IL 60934 USA "
    },
    {
      "Location ID": "60935",
      "Location Name": "ESSEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 W MAIN ST ESSEX IL 60935 USA "
    },
    {
      "Location ID": "60936",
      "Location Name": "GIBSON CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 127 E 9TH ST GIBSON CITY IL 60936 USA "
    },
    {
      "Location ID": "60938",
      "Location Name": "GILMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 N CENTRAL ST GILMAN IL 60938 USA "
    },
    {
      "Location ID": "60939",
      "Location Name": "GOODWINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 553 N 1800 EAST RD GOODWINE IL 60939 USA "
    },
    {
      "Location ID": "60940",
      "Location Name": "GRANT PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 N MAIN ST GRANT PARK IL 60940 USA "
    },
    {
      "Location ID": "60941",
      "Location Name": "HERSCHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 S MAIN ST HERSCHER IL 60941 USA "
    },
    {
      "Location ID": "60942",
      "Location Name": "HOOPESTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 314 S MARKET ST HOOPESTON IL 60942 USA "
    },
    {
      "Location ID": "60944",
      "Location Name": "HOPKINS PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3952 S KING DR HOPKINS PARK IL 60944 USA "
    },
    {
      "Location ID": "60945",
      "Location Name": "IROQUOIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 S MAIN ST IROQUOIS IL 60945 USA "
    },
    {
      "Location ID": "60946",
      "Location Name": "KEMPTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 213 E MAIN ST KEMPTON IL 60946 USA "
    },
    {
      "Location ID": "60948",
      "Location Name": "LODA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 N OAK ST LODA IL 60948 USA "
    },
    {
      "Location ID": "60949",
      "Location Name": "LUDLOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 E THOMAS ST LUDLOW IL 60949 USA "
    },
    {
      "Location ID": "60950",
      "Location Name": "MANTENO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 157 N MAIN ST MANTENO IL 60950 USA "
    },
    {
      "Location ID": "60951",
      "Location Name": "MARTINTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 W MAIN ST MARTINTON IL 60951 USA "
    },
    {
      "Location ID": "60952",
      "Location Name": "MELVIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 E MAIN ST MELVIN IL 60952 USA "
    },
    {
      "Location ID": "60953",
      "Location Name": "MILFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 N WEST AVE MILFORD IL 60953 USA "
    },
    {
      "Location ID": "60954",
      "Location Name": "MOMENCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 E WASHINGTON ST MOMENCE IL 60954 USA "
    },
    {
      "Location ID": "60955",
      "Location Name": "ONARGA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 W SEMINARY AVE ONARGA IL 60955 USA "
    },
    {
      "Location ID": "60956",
      "Location Name": "PAPINEAU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 E PAPINEAU ST PAPINEAU IL 60956 USA "
    },
    {
      "Location ID": "60957",
      "Location Name": "PAXTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 S MARKET ST PAXTON IL 60957 USA "
    },
    {
      "Location ID": "60959",
      "Location Name": "PIPER CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2 N PINE ST PIPER CITY IL 60959 USA "
    },
    {
      "Location ID": "60960",
      "Location Name": "RANKIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 S MAIN ST RANKIN IL 60960 USA "
    },
    {
      "Location ID": "60961",
      "Location Name": "REDDICK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 W LINCOLN ST REDDICK IL 60961 USA "
    },
    {
      "Location ID": "60962",
      "Location Name": "ROBERTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 E GREEN ST ROBERTS IL 60962 USA "
    },
    {
      "Location ID": "60963",
      "Location Name": "ROSSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 MAPLE ST ROSSVILLE IL 60963 USA "
    },
    {
      "Location ID": "60964",
      "Location Name": "SAINT ANNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 S CHICAGO AVE SAINT ANNE IL 60964 USA "
    },
    {
      "Location ID": "60966",
      "Location Name": "SHELDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 W DEPOT ST SHELDON IL 60966 USA "
    },
    {
      "Location ID": "60968",
      "Location Name": "THAWVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 W MAIN ST THAWVILLE IL 60968 USA "
    },
    {
      "Location ID": "60969",
      "Location Name": "UNION HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 CENTER ST UNION HILL IL 60969 USA "
    },
    {
      "Location ID": "60970",
      "Location Name": "WATSEKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 W WALNUT ST WATSEKA IL 60970 USA "
    },
    {
      "Location ID": "60973",
      "Location Name": "WELLINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 311 E MAIN ST WELLINGTON IL 60973 USA "
    },
    {
      "Location ID": "60974",
      "Location Name": "WOODLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 WEST AVE WOODLAND IL 60974 USA "
    },
    {
      "Location ID": "609BT",
      "Location Name": "BAKER AND TAYLOR INC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 S GLADIOLUS MOMENCE IL 60954 USA "
    },
    {
      "Location ID": "60A",
      "Location Name": "CHICAGO PEAK CROSSDOCK ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7506 ROOSEVELT RD BLDG 10 FOREST PARK IL 60130 USA "
    },
    {
      "Location ID": "60H",
      "Location Name": "LOG CHICAGO METRO PRIRTY ANX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2591 BUSSE RD ELK GROVE VILLAGE IL 60007 USA "
    },
    {
      "Location ID": "60ISC",
      "Location Name": "LOG JT WEEKER ISC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11600 W IRVING PARK RD CHICAGO IL 60688 USA "
    },
    {
      "Location ID": "60MTE",
      "Location Name": "CHICAGO MTESC, IL",
      "Time Zone Offset (Hours)": -1,
      "Address": " US POSTAL SERVICE 850 VETERANS PKWY BOLINGBROOK IL 60440 USA "
    },
    {
      "Location ID": "60S",
      "Location Name": "CHICAGO STC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2350 FRIEDER LN AURORA IL 60502 USA "
    },
    {
      "Location ID": "60Z",
      "Location Name": "LOG CHICAGO NDC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7500 ROOSEVELT RD FOREST PARK IL 60130 USA "
    },
    {
      "Location ID": "610",
      "Location Name": "ROCKFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5225 HARRISON AVE ROCKFORD IL 61125 USA "
    },
    {
      "Location ID": "61001",
      "Location Name": "APPLE RIVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 W RAILROAD ST APPLE RIVER IL 61001 USA "
    },
    {
      "Location ID": "61006",
      "Location Name": "ASHTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 605 BROWN AVE ASHTON IL 61006 USA "
    },
    {
      "Location ID": "61007",
      "Location Name": "BAILEYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 S COMMERCIAL ST BAILEYVILLE IL 61007 USA "
    },
    {
      "Location ID": "61008",
      "Location Name": "BELVIDERE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1800 DOC WOLF DR BELVIDERE IL 61008 USA "
    },
    {
      "Location ID": "61010",
      "Location Name": "BYRON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 232 N UNION ST BYRON IL 61010 USA "
    },
    {
      "Location ID": "61011",
      "Location Name": "CALEDONIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2201 IL ROUTE 173 CALEDONIA IL 61011 USA "
    },
    {
      "Location ID": "61012",
      "Location Name": "CAPRON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 W MAIN ST CAPRON IL 61012 USA "
    },
    {
      "Location ID": "61013",
      "Location Name": "CEDARVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 W WASHINGTON ST CEDARVILLE IL 61013 USA "
    },
    {
      "Location ID": "61014",
      "Location Name": "CHADWICK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 218 N MAIN ST CHADWICK IL 61014 USA "
    },
    {
      "Location ID": "61015",
      "Location Name": "CHANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 MAIN ST UNIT A CHANA IL 61015 USA "
    },
    {
      "Location ID": "61016",
      "Location Name": "CHERRY VALLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 E STATE ST CHERRY VALLEY IL 61016 USA "
    },
    {
      "Location ID": "61018",
      "Location Name": "DAKOTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 139 W MAIN ST DAKOTA IL 61018 USA "
    },
    {
      "Location ID": "61019",
      "Location Name": "DAVIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 420 N STANTON ST DAVIS IL 61019 USA "
    },
    {
      "Location ID": "61020",
      "Location Name": "DAVIS JUNCTION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 402 W CHICAGO AVE DAVIS JUNCTION IL 61020 USA "
    },
    {
      "Location ID": "61021",
      "Location Name": "DIXON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 405 W 2ND ST DIXON IL 61021 USA "
    },
    {
      "Location ID": "61024",
      "Location Name": "DURAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 N CENTER ST DURAND IL 61024 USA "
    },
    {
      "Location ID": "61025",
      "Location Name": "EAST DUBUQUE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 WALL ST EAST DUBUQUE IL 61025 USA "
    },
    {
      "Location ID": "61027",
      "Location Name": "ELEROY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 316 N BRIDGE ST ELEROY IL 61027 USA "
    },
    {
      "Location ID": "61028",
      "Location Name": "ELIZABETH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 E MYRTLE ST ELIZABETH IL 61028 USA "
    },
    {
      "Location ID": "61030",
      "Location Name": "FORRESTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 E MAIN ST FORRESTON IL 61030 USA "
    },
    {
      "Location ID": "61031",
      "Location Name": "FRANKLIN GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 S ELM ST FRANKLIN GROVE IL 61031 USA "
    },
    {
      "Location ID": "61032",
      "Location Name": "FREEPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 N CHICAGO AVE FL 1 FREEPORT IL 61032 USA "
    },
    {
      "Location ID": "61037",
      "Location Name": "GALT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 411 GALT RD GALT IL 61037 USA "
    },
    {
      "Location ID": "61038",
      "Location Name": "GARDEN PRAIRIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6820 PLEASANT ST GARDEN PRAIRIE IL 61038 USA "
    },
    {
      "Location ID": "61039",
      "Location Name": "GERMAN VALLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 35 CHURCH ST GERMAN VALLEY IL 61039 USA "
    },
    {
      "Location ID": "61041",
      "Location Name": "HANOVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 JEFFERSON ST HANOVER IL 61041 USA "
    },
    {
      "Location ID": "61042",
      "Location Name": "HARMON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 N 1ST ST HARMON IL 61042 USA "
    },
    {
      "Location ID": "61043",
      "Location Name": "HOLCOMB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 W MAIN ST HOLCOMB IL 61043 USA "
    },
    {
      "Location ID": "61044",
      "Location Name": "KENT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 767 N KENT RD KENT IL 61044 USA "
    },
    {
      "Location ID": "61046",
      "Location Name": "LANARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 600 W OLYMPIC DR LANARK IL 61046 USA "
    },
    {
      "Location ID": "61047",
      "Location Name": "LEAF RIVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 MAIN ST LEAF RIVER IL 61047 USA "
    },
    {
      "Location ID": "61048",
      "Location Name": "LENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 913 S LOGAN ST LENA IL 61048 USA "
    },
    {
      "Location ID": "61049",
      "Location Name": "LINDENWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 S WALNUT ST LINDENWOOD IL 61049 USA "
    },
    {
      "Location ID": "61050",
      "Location Name": "MC CONNELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 218 E MAIN ST MC CONNELL IL 61050 USA "
    },
    {
      "Location ID": "61051",
      "Location Name": "MILLEDGEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 315 N MEYERS AVE MILLEDGEVILLE IL 61051 USA "
    },
    {
      "Location ID": "61052",
      "Location Name": "MONROE CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 MAIN ST MONROE CENTER IL 61052 USA "
    },
    {
      "Location ID": "61053",
      "Location Name": "MOUNT CARROLL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 N CLAY ST MOUNT CARROLL IL 61053 USA "
    },
    {
      "Location ID": "61054",
      "Location Name": "MOUNT MORRIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 21 W MAIN ST MOUNT MORRIS IL 61054 USA "
    },
    {
      "Location ID": "61057",
      "Location Name": "NACHUSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1712 WARREN ST NACHUSA IL 61057 USA "
    },
    {
      "Location ID": "61059",
      "Location Name": "NORA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 W CHESTNUT ST NORA IL 61059 USA "
    },
    {
      "Location ID": "61060",
      "Location Name": "ORANGEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 W HIGH ST ORANGEVILLE IL 61060 USA "
    },
    {
      "Location ID": "61061",
      "Location Name": "OREGON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 W WASHINGTON ST OREGON IL 61061 USA "
    },
    {
      "Location ID": "61062",
      "Location Name": "PEARL CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 S MAIN ST PEARL CITY IL 61062 USA "
    },
    {
      "Location ID": "61063",
      "Location Name": "PECATONICA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 E 3RD ST PECATONICA IL 61063 USA "
    },
    {
      "Location ID": "61064",
      "Location Name": "POLO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 N FRANKLIN AVE POLO IL 61064 USA "
    },
    {
      "Location ID": "61065",
      "Location Name": "POPLAR GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 E BRITNIE DR POPLAR GROVE IL 61065 USA "
    },
    {
      "Location ID": "61067",
      "Location Name": "RIDOTT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 ADAMS ST RIDOTT IL 61067 USA "
    },
    {
      "Location ID": "61068",
      "Location Name": "ROCHELLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 LINCOLN HWY ROCHELLE IL 61068 USA "
    },
    {
      "Location ID": "61070",
      "Location Name": "ROCK CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 N MAIN ST ROCK CITY IL 61070 USA "
    },
    {
      "Location ID": "61071",
      "Location Name": "ROCK FALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 2ND AVE ROCK FALLS IL 61071 USA "
    },
    {
      "Location ID": "61072",
      "Location Name": "ROCKTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 W MAIN ST ROCKTON IL 61072 USA "
    },
    {
      "Location ID": "61073",
      "Location Name": "ROSCOE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11320 MAIN ST ROSCOE IL 61073 USA "
    },
    {
      "Location ID": "61074",
      "Location Name": "SAVANNA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 321 3RD ST SAVANNA IL 61074 USA "
    },
    {
      "Location ID": "61075",
      "Location Name": "SCALES MOUND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 310 SOUTH AVE SCALES MOUND IL 61075 USA "
    },
    {
      "Location ID": "61077",
      "Location Name": "SEWARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2970 TRACY ST SEWARD IL 61077 USA "
    },
    {
      "Location ID": "61078",
      "Location Name": "SHANNON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2 N LOCUST ST SHANNON IL 61078 USA "
    },
    {
      "Location ID": "61079",
      "Location Name": "SHIRLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13047 PUTNAM ST SHIRLAND IL 61079 USA "
    },
    {
      "Location ID": "61080",
      "Location Name": "SOUTH BELOIT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 311 OAK GROVE AVE SOUTH BELOIT IL 61080 USA "
    },
    {
      "Location ID": "61081",
      "Location Name": "STERLING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 408 3RD AVE STERLING IL 61081 USA "
    },
    {
      "Location ID": "61084",
      "Location Name": "STILLMAN VALLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 N WALNUT ST STILLMAN VALLEY IL 61084 USA "
    },
    {
      "Location ID": "61085",
      "Location Name": "STOCKTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W RAILROAD AVE STOCKTON IL 61085 USA "
    },
    {
      "Location ID": "61087",
      "Location Name": "WARREN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E MAIN ST WARREN IL 61087 USA "
    },
    {
      "Location ID": "61088",
      "Location Name": "WINNEBAGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 LANDMARK DR WINNEBAGO IL 61088 USA "
    },
    {
      "Location ID": "61089",
      "Location Name": "WINSLOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 CARVER ST WINSLOW IL 61089 USA "
    },
    {
      "Location ID": "610AN",
      "Location Name": "GALENA SQUARE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 901 GALENA SQUARE DR GALENA IL 61036 USA "
    },
    {
      "Location ID": "61101",
      "Location Name": "KILBURN ROCKFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2440 KILBURN AVE ROCKFORD IL 61101 USA "
    },
    {
      "Location ID": "612",
      "Location Name": "QUAD CITIES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7700 68TH ST MILAN IL 61264 USA "
    },
    {
      "Location ID": "61201",
      "Location Name": "ROCK ISLAND DOWNTOWN STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1956 2ND AVE ROCK ISLAND IL 61201 USA "
    },
    {
      "Location ID": "61230",
      "Location Name": "ALBANY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 3RD AVE S ALBANY IL 61230 USA "
    },
    {
      "Location ID": "61231",
      "Location Name": "ALEDO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 N COLLEGE AVE ALEDO IL 61231 USA "
    },
    {
      "Location ID": "61232",
      "Location Name": "ANDALUSIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 124 2ND AVE E ANDALUSIA IL 61232 USA "
    },
    {
      "Location ID": "61233",
      "Location Name": "ANDOVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 513 5TH ST ANDOVER IL 61233 USA "
    },
    {
      "Location ID": "61234",
      "Location Name": "ANNAWAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 S MAIN ST ANNAWAN IL 61234 USA "
    },
    {
      "Location ID": "61235",
      "Location Name": "ATKINSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 E MAIN ST ATKINSON IL 61235 USA "
    },
    {
      "Location ID": "61236",
      "Location Name": "BARSTOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 18617 1ST AVE BARSTOW IL 61236 USA "
    },
    {
      "Location ID": "61237",
      "Location Name": "BUFFALO PRAIRIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 18401 206TH ST W BUFFALO PRAIRIE IL 61237 USA "
    },
    {
      "Location ID": "61238",
      "Location Name": "CAMBRIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 N PROSPECT ST CAMBRIDGE IL 61238 USA "
    },
    {
      "Location ID": "61239",
      "Location Name": "CARBON CLIFF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 3RD ST CARBON CLIFF IL 61239 USA "
    },
    {
      "Location ID": "61240",
      "Location Name": "COAL VALLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 E 22ND AVE COAL VALLEY IL 61240 USA "
    },
    {
      "Location ID": "61241",
      "Location Name": "COLONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 1ST ST COLONA IL 61241 USA "
    },
    {
      "Location ID": "61242",
      "Location Name": "CORDOVA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1001 MAIN AVE CORDOVA IL 61242 USA "
    },
    {
      "Location ID": "61243",
      "Location Name": "DEER GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 28983 HUB ST DEER GROVE IL 61243 USA "
    },
    {
      "Location ID": "61244",
      "Location Name": "EAST MOLINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 805 16TH AVE EAST MOLINE IL 61244 USA "
    },
    {
      "Location ID": "61250",
      "Location Name": "ERIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 802 MAIN ST ERIE IL 61250 USA "
    },
    {
      "Location ID": "61251",
      "Location Name": "FENTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9979 FENTON RD FENTON IL 61251 USA "
    },
    {
      "Location ID": "61252",
      "Location Name": "FULTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 915 4TH ST FULTON IL 61252 USA "
    },
    {
      "Location ID": "61254",
      "Location Name": "GENESEO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 W 1ST ST GENESEO IL 61254 USA "
    },
    {
      "Location ID": "61256",
      "Location Name": "HAMPTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 625 8TH ST HAMPTON IL 61256 USA "
    },
    {
      "Location ID": "61257",
      "Location Name": "HILLSDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 BUTZER ST HILLSDALE IL 61257 USA "
    },
    {
      "Location ID": "61259",
      "Location Name": "ILLINOIS CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 23828 124TH AVE W ILLINOIS CITY IL 61259 USA "
    },
    {
      "Location ID": "61260",
      "Location Name": "JOY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 W MAIN ST JOY IL 61260 USA "
    },
    {
      "Location ID": "61261",
      "Location Name": "LYNDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 409 2ND AVE E LYNDON IL 61261 USA "
    },
    {
      "Location ID": "61262",
      "Location Name": "LYNN CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 404 SOUTH ST LYNN CENTER IL 61262 USA "
    },
    {
      "Location ID": "61263",
      "Location Name": "MATHERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 509 2ND ST MATHERVILLE IL 61263 USA "
    },
    {
      "Location ID": "61264",
      "Location Name": "MILAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 3RD ST W MILAN IL 61264 USA "
    },
    {
      "Location ID": "61265",
      "Location Name": "MOLINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 514 17TH ST MOLINE IL 61265 USA "
    },
    {
      "Location ID": "61270",
      "Location Name": "MORRISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 226 W MAIN ST MORRISON IL 61270 USA "
    },
    {
      "Location ID": "61273",
      "Location Name": "ORION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1012 4TH ST ORION IL 61273 USA "
    },
    {
      "Location ID": "61274",
      "Location Name": "OSCO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 N RAILROAD ST OSCO IL 61274 USA "
    },
    {
      "Location ID": "61275",
      "Location Name": "PORT BYRON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 HICKORY ST PORT BYRON IL 61275 USA "
    },
    {
      "Location ID": "61276",
      "Location Name": "PREEMPTION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1699 US HIGHWAY 67 PREEMPTION IL 61276 USA "
    },
    {
      "Location ID": "61277",
      "Location Name": "PROPHETSTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 313 WASHINGTON ST PROPHETSTOWN IL 61277 USA "
    },
    {
      "Location ID": "61278",
      "Location Name": "RAPIDS CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 135 13TH ST RAPIDS CITY IL 61278 USA "
    },
    {
      "Location ID": "61279",
      "Location Name": "REYNOLDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 217 N MAIN ST REYNOLDS IL 61279 USA "
    },
    {
      "Location ID": "61281",
      "Location Name": "SHERRARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 402 3RD ST SHERRARD IL 61281 USA "
    },
    {
      "Location ID": "61282",
      "Location Name": "SILVIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1001 1ST AVE SILVIS IL 61282 USA "
    },
    {
      "Location ID": "61283",
      "Location Name": "TAMPICO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 N MAIN ST TAMPICO IL 61283 USA "
    },
    {
      "Location ID": "61284",
      "Location Name": "TAYLOR RIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6928 134TH AVE W TAYLOR RIDGE IL 61284 USA "
    },
    {
      "Location ID": "61285",
      "Location Name": "THOMSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 705 ARGO FAY RTE THOMSON IL 61285 USA "
    },
    {
      "Location ID": "612DD",
      "Location Name": "DIVVYDOSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4300 44TH AVE MOLINE IL 61265 USA "
    },
    {
      "Location ID": "613",
      "Location Name": "LA SALLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2950 CHARTRES ST LA SALLE IL 61301 USA "
    },
    {
      "Location ID": "61301",
      "Location Name": "LA SALLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2950 CHARTRES ST LASALLE IL 61301 USA "
    },
    {
      "Location ID": "61310",
      "Location Name": "AMBOY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 E DIVISION ST AMBOY IL 61310 USA "
    },
    {
      "Location ID": "61312",
      "Location Name": "ARLINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 E RAILROAD AVE ARLINGTON IL 61312 USA "
    },
    {
      "Location ID": "61313",
      "Location Name": "BLACKSTONE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12385 E 3153 NORTH RD BLACKSTONE IL 61313 USA "
    },
    {
      "Location ID": "61314",
      "Location Name": "BUDA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 W MAIN ST BUDA IL 61314 USA "
    },
    {
      "Location ID": "61315",
      "Location Name": "BUREAU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 E NEBRASKA ST BUREAU IL 61315 USA "
    },
    {
      "Location ID": "61316",
      "Location Name": "CEDAR POINT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 CEDAR ST CEDAR POINT IL 61316 USA "
    },
    {
      "Location ID": "61317",
      "Location Name": "CHERRY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 N MAIN ST CHERRY IL 61317 USA "
    },
    {
      "Location ID": "61318",
      "Location Name": "COMPTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 240 W MAIN ST COMPTON IL 61318 USA "
    },
    {
      "Location ID": "61319",
      "Location Name": "CORNELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 N 6TH ST CORNELL IL 61319 USA "
    },
    {
      "Location ID": "61320",
      "Location Name": "DALZELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 139 SAUNDERS ST DALZELL IL 61320 USA "
    },
    {
      "Location ID": "61321",
      "Location Name": "DANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S MAIN ST DANA IL 61321 USA "
    },
    {
      "Location ID": "61322",
      "Location Name": "DEPUE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 W 4TH ST DEPUE IL 61322 USA "
    },
    {
      "Location ID": "61323",
      "Location Name": "DOVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 E WASHINGTON ST DOVER IL 61323 USA "
    },
    {
      "Location ID": "61325",
      "Location Name": "GRAND RIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 W MAIN ST GRAND RIDGE IL 61325 USA "
    },
    {
      "Location ID": "61326",
      "Location Name": "GRANVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 S MCCOY ST GRANVILLE IL 61326 USA "
    },
    {
      "Location ID": "61327",
      "Location Name": "HENNEPIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 316 E HIGH ST HENNEPIN IL 61327 USA "
    },
    {
      "Location ID": "61328",
      "Location Name": "KASBEER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20367 2470 NORTH AVE KASBEER IL 61328 USA "
    },
    {
      "Location ID": "61329",
      "Location Name": "LADD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 233 N MAIN AVE LADD IL 61329 USA "
    },
    {
      "Location ID": "61330",
      "Location Name": "LA MOILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 98 FRANKLIN ST LA MOILLE IL 61330 USA "
    },
    {
      "Location ID": "61331",
      "Location Name": "LEE CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1132 INLET RD LEE CENTER IL 61331 USA "
    },
    {
      "Location ID": "61332",
      "Location Name": "LEONORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 402 GARY ST LEONORE IL 61332 USA "
    },
    {
      "Location ID": "61333",
      "Location Name": "LONG POINT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 414 MAIN ST LONG POINT IL 61333 USA "
    },
    {
      "Location ID": "61334",
      "Location Name": "LOSTANT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 W 2ND ST LOSTANT IL 61334 USA "
    },
    {
      "Location ID": "61335",
      "Location Name": "MC NABB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 318 W MAIN ST MC NABB IL 61335 USA "
    },
    {
      "Location ID": "61336",
      "Location Name": "MAGNOLIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 N CHICAGO ST MAGNOLIA IL 61336 USA "
    },
    {
      "Location ID": "61337",
      "Location Name": "MALDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 221 W MAIN ST MALDEN IL 61337 USA "
    },
    {
      "Location ID": "61338",
      "Location Name": "MANLIUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 E MAPLE AVE MANLIUS IL 61338 USA "
    },
    {
      "Location ID": "61340",
      "Location Name": "MARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 W HENNEPIN ST MARK IL 61340 USA "
    },
    {
      "Location ID": "61341",
      "Location Name": "MARSEILLES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 WASHINGTON ST MARSEILLES IL 61341 USA "
    },
    {
      "Location ID": "61342",
      "Location Name": "MENDOTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 818 WASHINGTON ST MENDOTA IL 61342 USA "
    },
    {
      "Location ID": "61344",
      "Location Name": "MINERAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 E MAIN ST MINERAL IL 61344 USA "
    },
    {
      "Location ID": "61345",
      "Location Name": "NEPONSET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 W COMMERCIAL ST NEPONSET IL 61345 USA "
    },
    {
      "Location ID": "61346",
      "Location Name": "NEW BEDFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 N MAIN ST NEW BEDFORD IL 61346 USA "
    },
    {
      "Location ID": "61348",
      "Location Name": "OGLESBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 W WALNUT ST OGLESBY IL 61348 USA "
    },
    {
      "Location ID": "61349",
      "Location Name": "OHIO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 N MAIN ST OHIO IL 61349 USA "
    },
    {
      "Location ID": "61350",
      "Location Name": "OTTAWA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 750 E ETNA RD OTTAWA IL 61350 USA "
    },
    {
      "Location ID": "61353",
      "Location Name": "PAW PAW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 226 CHICAGO RD PAW PAW IL 61353 USA "
    },
    {
      "Location ID": "61354",
      "Location Name": "PERU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1500 4TH ST PERU IL 61354 USA "
    },
    {
      "Location ID": "61356",
      "Location Name": "PRINCETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 326 S MAIN ST PRINCETON IL 61356 USA "
    },
    {
      "Location ID": "61358",
      "Location Name": "RUTLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 SW FRONT ST RUTLAND IL 61358 USA "
    },
    {
      "Location ID": "61359",
      "Location Name": "SEATONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 ROUTE 6 SEATONVILLE IL 61359 USA "
    },
    {
      "Location ID": "61360",
      "Location Name": "SENECA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 E ARMOUR ST SENECA IL 61360 USA "
    },
    {
      "Location ID": "61361",
      "Location Name": "SHEFFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 S MAIN ST SHEFFIELD IL 61361 USA "
    },
    {
      "Location ID": "61362",
      "Location Name": "SPRING VALLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 E ERIE ST SPRING VALLEY IL 61362 USA "
    },
    {
      "Location ID": "61363",
      "Location Name": "STANDARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 S 2ND ST STANDARD IL 61363 USA "
    },
    {
      "Location ID": "61364",
      "Location Name": "STREATOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 221 E HICKORY ST STREATOR IL 61364 USA "
    },
    {
      "Location ID": "61367",
      "Location Name": "SUBLETTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 W MAIN ST SUBLETTE IL 61367 USA "
    },
    {
      "Location ID": "61368",
      "Location Name": "TISKILWA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 E MAIN ST TISKILWA IL 61368 USA "
    },
    {
      "Location ID": "61369",
      "Location Name": "TOLUCA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 W SANTA FE AVE TOLUCA IL 61369 USA "
    },
    {
      "Location ID": "61370",
      "Location Name": "TONICA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 S ONECA ST TONICA IL 61370 USA "
    },
    {
      "Location ID": "61371",
      "Location Name": "TRIUMPH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3906 E 752ND RD TRIUMPH IL 61371 USA "
    },
    {
      "Location ID": "61372",
      "Location Name": "TROY GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 E OTTAWA ST TROY GROVE IL 61372 USA "
    },
    {
      "Location ID": "61373",
      "Location Name": "UTICA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 310 N MILL ST UTICA IL 61373 USA "
    },
    {
      "Location ID": "61374",
      "Location Name": "VAN ORIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 27861 2600 EAST ST VAN ORIN IL 61374 USA "
    },
    {
      "Location ID": "61375",
      "Location Name": "VARNA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 304 WALNUT ST VARNA IL 61375 USA "
    },
    {
      "Location ID": "61376",
      "Location Name": "WALNUT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 N MAIN ST WALNUT IL 61376 USA "
    },
    {
      "Location ID": "61377",
      "Location Name": "WENONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 N CHESTNUT ST WENONA IL 61377 USA "
    },
    {
      "Location ID": "61378",
      "Location Name": "WEST BROOKLYN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2521 JOHNSON ST WEST BROOKLYN IL 61378 USA "
    },
    {
      "Location ID": "61379",
      "Location Name": "WYANET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 W MAIN ST WYANET IL 61379 USA "
    },
    {
      "Location ID": "613AC",
      "Location Name": "ACE HARDWARE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2123 N EUCLID AVE PRINCETON IL 61356 USA "
    },
    {
      "Location ID": "613AL",
      "Location Name": "ALLSKIDSTEERS INC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 602 850N AVE NEPONSET IL 61345 USA "
    },
    {
      "Location ID": "613CP",
      "Location Name": "CAR PARTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4800 INDUSTRIAL DR UNIT 43 PERU IL 61354 USA "
    },
    {
      "Location ID": "613JC",
      "Location Name": "JC WHITNEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 JC WHITNEY WAY LASALLE IL 61301 USA "
    },
    {
      "Location ID": "613JW",
      "Location Name": "JC WHITNEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4800 INDUSTRIAL DRIVE PERU IL 61354 USA "
    },
    {
      "Location ID": "613OF",
      "Location Name": "OPTIMUM FULFILLMENT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4800 INDUSTRIAL DR UNIT 33 PERU IL 61354 USA "
    },
    {
      "Location ID": "613RP",
      "Location Name": "R & P CARRIAGES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 270 E. JACKSON STREET SENECA IL 61360 USA "
    },
    {
      "Location ID": "614",
      "Location Name": "GALESBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 476 E MAIN ST STE A GALESBURG IL 61401-9998 USA "
    },
    {
      "Location ID": "61401",
      "Location Name": "GALESBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 476 E MAIN ST GALESBURG IL 61401 USA "
    },
    {
      "Location ID": "61410",
      "Location Name": "ABINGDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 W MEEK ST ABINGDON IL 61410 USA "
    },
    {
      "Location ID": "61411",
      "Location Name": "ADAIR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W MAIN ST ADAIR IL 61411 USA "
    },
    {
      "Location ID": "61412",
      "Location Name": "ALEXIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S MAIN ST ALEXIS IL 61412 USA "
    },
    {
      "Location ID": "61413",
      "Location Name": "ALPHA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 W D ST ALPHA IL 61413 USA "
    },
    {
      "Location ID": "61414",
      "Location Name": "ALTONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 S DEPOT ST ALTONA IL 61414 USA "
    },
    {
      "Location ID": "61415",
      "Location Name": "AVON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E WASHINGTON ST AVON IL 61415 USA "
    },
    {
      "Location ID": "61416",
      "Location Name": "BARDOLPH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 S CHESTNUT ST BARDOLPH IL 61416 USA "
    },
    {
      "Location ID": "61418",
      "Location Name": "BIGGSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 E MAIN ST BIGGSVILLE IL 61418 USA "
    },
    {
      "Location ID": "61419",
      "Location Name": "BISHOP HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 N BISHOP HILL ST BISHOP HILL IL 61419 USA "
    },
    {
      "Location ID": "61420",
      "Location Name": "BLANDINSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 E JEFFERSON ST BLANDINSVILLE IL 61420 USA "
    },
    {
      "Location ID": "61421",
      "Location Name": "BRADFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 129 W MAIN ST BRADFORD IL 61421 USA "
    },
    {
      "Location ID": "61422",
      "Location Name": "BUSHNELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 223 E HAIL ST BUSHNELL IL 61422 USA "
    },
    {
      "Location ID": "61423",
      "Location Name": "CAMERON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 559 CARE ST CAMERON IL 61423 USA "
    },
    {
      "Location ID": "61424",
      "Location Name": "CAMP GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1060 2ND ST CAMP GROVE IL 61424 USA "
    },
    {
      "Location ID": "61425",
      "Location Name": "CARMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 MAIN ST CARMAN IL 61425 USA "
    },
    {
      "Location ID": "61427",
      "Location Name": "CUBA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 E JEFFERSON ST CUBA IL 61427 USA "
    },
    {
      "Location ID": "61428",
      "Location Name": "DAHINDA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1453 KNOX HIGHWAY 15 DAHINDA IL 61428 USA "
    },
    {
      "Location ID": "61430",
      "Location Name": "EAST GALESBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 407 1/2 S STATE ST EAST GALESBURG IL 61430 USA "
    },
    {
      "Location ID": "61431",
      "Location Name": "ELLISVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 E MAIN ST ELLISVILLE IL 61431 USA "
    },
    {
      "Location ID": "61432",
      "Location Name": "FAIRVIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 520 CARTER ST FAIRVIEW IL 61432 USA "
    },
    {
      "Location ID": "61433",
      "Location Name": "FIATT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14279 E STATE ROUTE 9 FIATT IL 61433 USA "
    },
    {
      "Location ID": "61434",
      "Location Name": "GALVA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 EXCHANGE ST GALVA IL 61434 USA "
    },
    {
      "Location ID": "61436",
      "Location Name": "GILSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1016 MOSS ST GILSON IL 61436 USA "
    },
    {
      "Location ID": "61437",
      "Location Name": "GLADSTONE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 S MAIN ST GLADSTONE IL 61437 USA "
    },
    {
      "Location ID": "61438",
      "Location Name": "GOOD HOPE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 W MAIN ST GOOD HOPE IL 61438 USA "
    },
    {
      "Location ID": "61439",
      "Location Name": "HENDERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 N MAIN ST HENDERSON IL 61439 USA "
    },
    {
      "Location ID": "61440",
      "Location Name": "INDUSTRY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E MAIN ST INDUSTRY IL 61440 USA "
    },
    {
      "Location ID": "61441",
      "Location Name": "IPAVA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 40 E MAIN ST IPAVA IL 61441 USA "
    },
    {
      "Location ID": "61442",
      "Location Name": "KEITHSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 S 4TH ST KEITHSBURG IL 61442 USA "
    },
    {
      "Location ID": "61443",
      "Location Name": "KEWANEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 S TREMONT ST KEWANEE IL 61443 USA "
    },
    {
      "Location ID": "61447",
      "Location Name": "KIRKWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 131 S KIRK ST KIRKWOOD IL 61447 USA "
    },
    {
      "Location ID": "61448",
      "Location Name": "KNOXVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 N PUBLIC SQ KNOXVILLE IL 61448 USA "
    },
    {
      "Location ID": "61449",
      "Location Name": "LA FAYETTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 COMMERCIAL ST LA FAYETTE IL 61449 USA "
    },
    {
      "Location ID": "61450",
      "Location Name": "LA HARPE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 E MAIN ST LA HARPE IL 61450 USA "
    },
    {
      "Location ID": "61451",
      "Location Name": "LAURA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 21014 MAPLE ST LAURA IL 61451 USA "
    },
    {
      "Location ID": "61452",
      "Location Name": "LITTLETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 S MAIN ST LITTLETON IL 61452 USA "
    },
    {
      "Location ID": "61453",
      "Location Name": "LITTLE YORK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 N WALNUT LITTLE YORK IL 61453 USA "
    },
    {
      "Location ID": "61454",
      "Location Name": "LOMAX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 MAIN ST LOMAX IL 61454 USA "
    },
    {
      "Location ID": "61455",
      "Location Name": "MACOMB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 S RANDOLPH ST MACOMB IL 61455 USA "
    },
    {
      "Location ID": "61458",
      "Location Name": "MAQUON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 MAIN ST MAQUON IL 61458 USA "
    },
    {
      "Location ID": "61459",
      "Location Name": "MARIETTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 W COAL ST MARIETTA IL 61459 USA "
    },
    {
      "Location ID": "61460",
      "Location Name": "MEDIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 S MAIN ST MEDIA IL 61460 USA "
    },
    {
      "Location ID": "61462",
      "Location Name": "MONMOUTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 235 S 1ST ST MONMOUTH IL 61462 USA "
    },
    {
      "Location ID": "61465",
      "Location Name": "NEW WINDSOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 315 MAIN ST NEW WINDSOR IL 61465 USA "
    },
    {
      "Location ID": "61466",
      "Location Name": "NORTH HENDERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 MAIN ST NORTH HENDERSON IL 61466 USA "
    },
    {
      "Location ID": "61467",
      "Location Name": "ONEIDA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 126 E HOLMES ST ONEIDA IL 61467 USA "
    },
    {
      "Location ID": "61469",
      "Location Name": "OQUAWKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 403 SCHUYLER ST OQUAWKA IL 61469 USA "
    },
    {
      "Location ID": "61470",
      "Location Name": "PRAIRIE CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 325 E MAIN ST PRAIRIE CITY IL 61470 USA "
    },
    {
      "Location ID": "61471",
      "Location Name": "RARITAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E MAIN ST RARITAN IL 61471 USA "
    },
    {
      "Location ID": "61472",
      "Location Name": "RIO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 E CENTER AVE RIO IL 61472 USA "
    },
    {
      "Location ID": "61473",
      "Location Name": "ROSEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 140 N ARBITARY ST ROSEVILLE IL 61473 USA "
    },
    {
      "Location ID": "61474",
      "Location Name": "SAINT AUGUSTINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 34 N MAIN ST SAINT AUGUSTINE IL 61474 USA "
    },
    {
      "Location ID": "61476",
      "Location Name": "SEATON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 317 MAPLE ST SEATON IL 61476 USA "
    },
    {
      "Location ID": "61477",
      "Location Name": "SMITHFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 148 S MAIN ST SMITHFIELD IL 61477 USA "
    },
    {
      "Location ID": "61478",
      "Location Name": "SMITHSHIRE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 124 E STATE ST SMITHSHIRE IL 61478 USA "
    },
    {
      "Location ID": "61479",
      "Location Name": "SPEER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 MAIN ST SPEER IL 61479 USA "
    },
    {
      "Location ID": "61480",
      "Location Name": "STRONGHURST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 S BROADWAY ST STRONGHURST IL 61480 USA "
    },
    {
      "Location ID": "61482",
      "Location Name": "TABLE GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 E SIDE SQ TABLE GROVE IL 61482 USA "
    },
    {
      "Location ID": "61483",
      "Location Name": "TOULON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 W VINE ST TOULON IL 61483 USA "
    },
    {
      "Location ID": "61484",
      "Location Name": "VERMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 W 2ND ST VERMONT IL 61484 USA "
    },
    {
      "Location ID": "61485",
      "Location Name": "VICTORIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 229 E MAIN ST VICTORIA IL 61485 USA "
    },
    {
      "Location ID": "61486",
      "Location Name": "VIOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1508 13TH ST VIOLA IL 61486 USA "
    },
    {
      "Location ID": "61488",
      "Location Name": "WATAGA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 140 S MAIN ST WATAGA IL 61488 USA "
    },
    {
      "Location ID": "61489",
      "Location Name": "WILLIAMSFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 E GALE ST WILLIAMSFIELD IL 61489 USA "
    },
    {
      "Location ID": "61490",
      "Location Name": "WOODHULL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 N DIVISION ST WOODHULL IL 61490 USA "
    },
    {
      "Location ID": "61491",
      "Location Name": "WYOMING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E WILLIAMS ST WYOMING IL 61491 USA "
    },
    {
      "Location ID": "614BM",
      "Location Name": "BOSS MANUFACTURING CO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1501 BURLINGTON AVE KEWANEE IL 61443 USA "
    },
    {
      "Location ID": "614DB",
      "Location Name": "DICK BLICK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 695 US HIGHWAY 150 EAST GALESBURG IL 61401-8310 USA "
    },
    {
      "Location ID": "614GB",
      "Location Name": "GERMAN BLISS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 624 WEST SPRING STREET PEORIA IL 61559 USA "
    },
    {
      "Location ID": "614RR",
      "Location Name": "RAINBOW RESOURCES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 655 TOWNSHIP RD 500E TOULON IL 61483 USA "
    },
    {
      "Location ID": "615",
      "Location Name": "PEORIA P&DF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 95 STATE ST PEORIA IL 61601 USA "
    },
    {
      "Location ID": "61501",
      "Location Name": "ASTORIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 W BROADWAY ASTORIA IL 61501 USA "
    },
    {
      "Location ID": "61516",
      "Location Name": "BENSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 320 FRONT ST BENSON IL 61516 USA "
    },
    {
      "Location ID": "61517",
      "Location Name": "BRIMFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 N GALENA AVE BRIMFIELD IL 61517 USA "
    },
    {
      "Location ID": "61519",
      "Location Name": "BRYANT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 E MAIN ST BRYANT IL 61519 USA "
    },
    {
      "Location ID": "61520",
      "Location Name": "CANTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 60 W ELM ST CANTON IL 61520 USA "
    },
    {
      "Location ID": "61523",
      "Location Name": "CHILLICOTHE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1114 N 2ND ST CHILLICOTHE IL 61523 USA "
    },
    {
      "Location ID": "61524",
      "Location Name": "DUNFERMLINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 316 CENTER ST DUNFERMLINE IL 61524 USA "
    },
    {
      "Location ID": "61525",
      "Location Name": "DUNLAP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 N 2ND ST DUNLAP IL 61525 USA "
    },
    {
      "Location ID": "61526",
      "Location Name": "EDELSTEIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2809 W MAIN ST EDELSTEIN IL 61526 USA "
    },
    {
      "Location ID": "61528",
      "Location Name": "EDWARDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10112 W DUBOIS RD EDWARDS IL 61528 USA "
    },
    {
      "Location ID": "61529",
      "Location Name": "ELMWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 N MAGNOLIA ST ELMWOOD IL 61529 USA "
    },
    {
      "Location ID": "61530",
      "Location Name": "EUREKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 W EUREKA AVE EUREKA IL 61530 USA "
    },
    {
      "Location ID": "61531",
      "Location Name": "FARMINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 67 W FORT ST FARMINGTON IL 61531 USA "
    },
    {
      "Location ID": "61532",
      "Location Name": "FOREST CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N MAIN ST FOREST CITY IL 61532 USA "
    },
    {
      "Location ID": "61533",
      "Location Name": "GLASFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 E MAIN ST GLASFORD IL 61533 USA "
    },
    {
      "Location ID": "61534",
      "Location Name": "GREEN VALLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 W MAIN ST GREEN VALLEY IL 61534 USA "
    },
    {
      "Location ID": "61535",
      "Location Name": "GROVELAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 18769 SPRINGFIELD RD GROVELAND IL 61535 USA "
    },
    {
      "Location ID": "61536",
      "Location Name": "HANNA CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 213 N MAIN ST HANNA CITY IL 61536 USA "
    },
    {
      "Location ID": "61537",
      "Location Name": "HENRY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 505 MAIN ST HENRY IL 61537 USA "
    },
    {
      "Location ID": "61539",
      "Location Name": "KINGSTON MINES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 WASHINGTON ST KINGSTON MINES IL 61539 USA "
    },
    {
      "Location ID": "61540",
      "Location Name": "LACON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 N WASHINGTON ST LACON IL 61540 USA "
    },
    {
      "Location ID": "61541",
      "Location Name": "LA ROSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 S MAIN ST LA ROSE IL 61541 USA "
    },
    {
      "Location ID": "61542",
      "Location Name": "LEWISTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 N MAIN ST LEWISTOWN IL 61542 USA "
    },
    {
      "Location ID": "61544",
      "Location Name": "LONDON MILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 S 2ND ST LONDON MILLS IL 61544 USA "
    },
    {
      "Location ID": "61545",
      "Location Name": "LOWPOINT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1954 N GRANT ST LOWPOINT IL 61545 USA "
    },
    {
      "Location ID": "61546",
      "Location Name": "MANITO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 E MARKET ST MANITO IL 61546 USA "
    },
    {
      "Location ID": "61547",
      "Location Name": "MAPLETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8607 W MAIN ST MAPLETON IL 61547 USA "
    },
    {
      "Location ID": "61548",
      "Location Name": "METAMORA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 E PARTRIDGE ST METAMORA IL 61548 USA "
    },
    {
      "Location ID": "61550",
      "Location Name": "MORTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 600 W JEFFERSON ST MORTON IL 61550 USA "
    },
    {
      "Location ID": "61552",
      "Location Name": "MOSSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10420 STATE ST MOSSVILLE IL 61552 USA "
    },
    {
      "Location ID": "61553",
      "Location Name": "NORRIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 W DIVISION ST NORRIS IL 61553 USA "
    },
    {
      "Location ID": "61554",
      "Location Name": "PEKIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 514 BROADWAY ST PEKIN IL 61554 USA "
    },
    {
      "Location ID": "61559",
      "Location Name": "PRINCEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 133 E EVANS ST PRINCEVILLE IL 61559 USA "
    },
    {
      "Location ID": "61560",
      "Location Name": "PUTNAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 CENTERS ST PUTNAM IL 61560 USA "
    },
    {
      "Location ID": "61561",
      "Location Name": "ROANOKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 413 N MAIN ST ROANOKE IL 61561 USA "
    },
    {
      "Location ID": "61562",
      "Location Name": "ROME",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15715 RIVER BEACH RD ROME IL 61562 USA "
    },
    {
      "Location ID": "61563",
      "Location Name": "SAINT DAVID",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 MAIN ST SAINT DAVID IL 61563 USA "
    },
    {
      "Location ID": "61564",
      "Location Name": "SOUTH PEKIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 MAIN ST SOUTH PEKIN IL 61564 USA "
    },
    {
      "Location ID": "61565",
      "Location Name": "SPARLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 CENTER ST SPARLAND IL 61565 USA "
    },
    {
      "Location ID": "61567",
      "Location Name": "TOPEKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 W 4TH ST TOPEKA IL 61567 USA "
    },
    {
      "Location ID": "61568",
      "Location Name": "TREMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 W WALNUT ST TREMONT IL 61568 USA "
    },
    {
      "Location ID": "61569",
      "Location Name": "TRIVOLI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 18610 W MAIN ST TRIVOLI IL 61569 USA "
    },
    {
      "Location ID": "61570",
      "Location Name": "WASHBURN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 139 S JEFFERSON ST WASHBURN IL 61570 USA "
    },
    {
      "Location ID": "61571",
      "Location Name": "WASHINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 S HIGH ST WASHINGTON IL 61571 USA "
    },
    {
      "Location ID": "61572",
      "Location Name": "YATES CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 W MAIN ST YATES CITY IL 61572 USA "
    },
    {
      "Location ID": "615AN",
      "Location Name": "METAMORA CARRIER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 219 E MOUNT VERNON METAMORA IL 61548 USA "
    },
    {
      "Location ID": "615HR",
      "Location Name": "HOERR RACING PRODUCTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9804 W. PRIMROSE EDWARDS IL 61528 USA "
    },
    {
      "Location ID": "615MD",
      "Location Name": "M & D PRINTING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 515 UNIVERSITY AVE HENRY IL 61537-1059 USA "
    },
    {
      "Location ID": "615SP",
      "Location Name": "STEVENS PUBLISHING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 W. PEARL ASTORIA IL 61501 USA "
    },
    {
      "Location ID": "61602",
      "Location Name": "PERSIMMON ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 90 PERSIMMON ST PEORIA IL 61602 USA "
    },
    {
      "Location ID": "61604",
      "Location Name": "WEST GLEN STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 715 W GLEN AVE PEORIA IL 61614-4875 USA "
    },
    {
      "Location ID": "61607",
      "Location Name": "BARTONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5814 S ADAMS ST BARTONVILLE IL 61607 USA "
    },
    {
      "Location ID": "61611",
      "Location Name": "EAST PEORIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 415 E WASHINGTON ST EAST PEORIA IL 61611 USA "
    },
    {
      "Location ID": "61614",
      "Location Name": "NORTH UNIVERSITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6310 N UNIVERSITY ST PEORIA IL 61614 USA "
    },
    {
      "Location ID": "616AA",
      "Location Name": "WEST GLEN STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 715 W GLEN AVE PEORIA IL 61614 USA "
    },
    {
      "Location ID": "61701",
      "Location Name": "BLOOMINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1511 E EMPIRE ST BLOOMINGTON IL 61701 USA "
    },
    {
      "Location ID": "61720",
      "Location Name": "ANCHOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 239 2ND ST ANCHOR IL 61720 USA "
    },
    {
      "Location ID": "61721",
      "Location Name": "ARMINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 N GRIFFIN ST ARMINGTON IL 61721 USA "
    },
    {
      "Location ID": "61722",
      "Location Name": "ARROWSMITH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 W CROSSON ST ARROWSMITH IL 61722 USA "
    },
    {
      "Location ID": "61723",
      "Location Name": "ATLANTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 SW CHURCH ST ATLANTA IL 61723 USA "
    },
    {
      "Location ID": "61724",
      "Location Name": "BELLFLOWER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 N LATCHA ST BELLFLOWER IL 61724 USA "
    },
    {
      "Location ID": "61725",
      "Location Name": "CARLOCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 E WASHINGTON ST CARLOCK IL 61725 USA "
    },
    {
      "Location ID": "61726",
      "Location Name": "CHENOA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 213 E OWSLEY ST CHENOA IL 61726 USA "
    },
    {
      "Location ID": "61727",
      "Location Name": "CLINTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 N QUINCY ST CLINTON IL 61727 USA "
    },
    {
      "Location ID": "61728",
      "Location Name": "COLFAX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 W MAIN ST COLFAX IL 61728 USA "
    },
    {
      "Location ID": "61729",
      "Location Name": "CONGERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 MAIN ST CONGERVILLE IL 61729 USA "
    },
    {
      "Location ID": "61730",
      "Location Name": "COOKSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 N KOCH ST COOKSVILLE IL 61730 USA "
    },
    {
      "Location ID": "61731",
      "Location Name": "CROPSEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 146 MAIN ST CROPSEY IL 61731 USA "
    },
    {
      "Location ID": "61732",
      "Location Name": "DANVERS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 E EXCHANGE ST DANVERS IL 61732 USA "
    },
    {
      "Location ID": "61733",
      "Location Name": "DEER CREEK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 E 1ST AVE DEER CREEK IL 61733 USA "
    },
    {
      "Location ID": "61734",
      "Location Name": "DELAVAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 E 5TH ST DELAVAN IL 61734 USA "
    },
    {
      "Location ID": "61735",
      "Location Name": "DEWITT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 CHICAGO ST DEWITT IL 61735 USA "
    },
    {
      "Location ID": "61736",
      "Location Name": "DOWNS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 403 S SEMINARY ST DOWNS IL 61736 USA "
    },
    {
      "Location ID": "61737",
      "Location Name": "ELLSWORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 N MAIN ST ELLSWORTH IL 61737 USA "
    },
    {
      "Location ID": "61738",
      "Location Name": "EL PASO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 W FRONT ST EL PASO IL 61738 USA "
    },
    {
      "Location ID": "61739",
      "Location Name": "FAIRBURY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 127 E LOCUST ST FAIRBURY IL 61739 USA "
    },
    {
      "Location ID": "61740",
      "Location Name": "FLANAGAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 S MAIN ST FLANAGAN IL 61740 USA "
    },
    {
      "Location ID": "61741",
      "Location Name": "FORREST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 E KRACK ST FORREST IL 61741 USA "
    },
    {
      "Location ID": "61742",
      "Location Name": "GOODFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 E PEORIA ST GOODFIELD IL 61742 USA "
    },
    {
      "Location ID": "61743",
      "Location Name": "GRAYMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 N MAIN ST GRAYMONT IL 61743 USA "
    },
    {
      "Location ID": "61744",
      "Location Name": "GRIDLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 324 N CENTER ST GRIDLEY IL 61744 USA "
    },
    {
      "Location ID": "61745",
      "Location Name": "HEYWORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 S WILLIS ST HEYWORTH IL 61745 USA "
    },
    {
      "Location ID": "61747",
      "Location Name": "HOPEDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 SE MAIN ST HOPEDALE IL 61747 USA "
    },
    {
      "Location ID": "61748",
      "Location Name": "HUDSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 E WALNUT ST HUDSON IL 61748 USA "
    },
    {
      "Location ID": "61749",
      "Location Name": "KENNEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 S JOHNSTON ST KENNEY IL 61749 USA "
    },
    {
      "Location ID": "61751",
      "Location Name": "LAWNDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10 S LINCOLN ST LAWNDALE IL 61751 USA "
    },
    {
      "Location ID": "61752",
      "Location Name": "LE ROY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 E CHERRY ST LE ROY IL 61752 USA "
    },
    {
      "Location ID": "61753",
      "Location Name": "LEXINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 W MAIN ST LEXINGTON IL 61753 USA "
    },
    {
      "Location ID": "61754",
      "Location Name": "MC LEAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 S HAMILTON ST MC LEAN IL 61754 USA "
    },
    {
      "Location ID": "61755",
      "Location Name": "MACKINAW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 S MAIN ST MACKINAW IL 61755 USA "
    },
    {
      "Location ID": "61756",
      "Location Name": "MAROA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 E MAIN ST MAROA IL 61756 USA "
    },
    {
      "Location ID": "61759",
      "Location Name": "MINIER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 219 W CHICAGO ST MINIER IL 61759 USA "
    },
    {
      "Location ID": "61760",
      "Location Name": "MINONK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 160 W 5TH ST MINONK IL 61760 USA "
    },
    {
      "Location ID": "61761",
      "Location Name": "NORMAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 W NORTH ST NORMAL IL 61761-9900 USA "
    },
    {
      "Location ID": "61764",
      "Location Name": "PONTIAC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 W MADISON ST PONTIAC IL 61764 USA "
    },
    {
      "Location ID": "61769",
      "Location Name": "SAUNEMIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 76 NORTH ST SAUNEMIN IL 61769 USA "
    },
    {
      "Location ID": "61770",
      "Location Name": "SAYBROOK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 W LINCOLN ST SAYBROOK IL 61770 USA "
    },
    {
      "Location ID": "61771",
      "Location Name": "SECOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 W GILMAN ST SECOR IL 61771 USA "
    },
    {
      "Location ID": "61772",
      "Location Name": "SHIRLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 FOSTER ST SHIRLEY IL 61772 USA "
    },
    {
      "Location ID": "61773",
      "Location Name": "SIBLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 S SCIOTA ST SIBLEY IL 61773 USA "
    },
    {
      "Location ID": "61774",
      "Location Name": "STANFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 W MAIN ST STANFORD IL 61774 USA "
    },
    {
      "Location ID": "61775",
      "Location Name": "STRAWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 S 1ST ST STRAWN IL 61775 USA "
    },
    {
      "Location ID": "61776",
      "Location Name": "TOWANDA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 S MADISON ST TOWANDA IL 61776 USA "
    },
    {
      "Location ID": "61777",
      "Location Name": "WAPELLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 MAIN ST WAPELLA IL 61777 USA "
    },
    {
      "Location ID": "61778",
      "Location Name": "WAYNESVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 S ISHAM ST WAYNESVILLE IL 61778 USA "
    },
    {
      "Location ID": "617AA",
      "Location Name": "BLOOMINGTON EMPIRE ST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1511 E EMPIRE ST BLOOMINGTON IL 61701 USA "
    },
    {
      "Location ID": "617CF",
      "Location Name": "MOORE WALLACE RMS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 S CAIN ST CLINTON IL 61727 USA "
    },
    {
      "Location ID": "617LI",
      "Location Name": "THE LIFE GUARD STORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2012 W COLLEGE AVE NORMAL IL 61761 USA "
    },
    {
      "Location ID": "617MW",
      "Location Name": "MOORE WALLACE RMS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 S CAIN ST CLINTON IL 61727 USA "
    },
    {
      "Location ID": "617SS",
      "Location Name": "S & S CASTING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5468 E 2000 N ROAD FLANAGAN IL 61740 USA "
    },
    {
      "Location ID": "617ST",
      "Location Name": "STATE FARM MRSF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2202 GRAF DRIVE BLOOMINGTON IL 61704 USA "
    },
    {
      "Location ID": "617VI",
      "Location Name": "VIVO TECHNOLOGY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 195 E MARTIN DRIVE GOODFIELD IL 61742 USA "
    },
    {
      "Location ID": "617WA",
      "Location Name": "LSC COMMUNICATIONS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1600 N MAIN ST PONTIAC IL 61764 USA "
    },
    {
      "Location ID": "618",
      "Location Name": "CHAMPAIGN P&DC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2001 N MATTIS AVE CHAMPAIGN IL 61821 USA "
    },
    {
      "Location ID": "61801",
      "Location Name": "DOWNTOWN URBANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 S BROADWAY AVE STE 1 URBANA IL 61801 USA "
    },
    {
      "Location ID": "61802",
      "Location Name": "URBANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3100 E TATMAN CT URBANA IL 61802 USA "
    },
    {
      "Location ID": "61810",
      "Location Name": "ALLERTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 VERMILION AVE ALLERTON IL 61810 USA "
    },
    {
      "Location ID": "61811",
      "Location Name": "ALVIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 CHICAGO ST ALVIN IL 61811 USA "
    },
    {
      "Location ID": "61812",
      "Location Name": "ARMSTRONG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 30515 ARMSTRONG RD ARMSTRONG IL 61812 USA "
    },
    {
      "Location ID": "61813",
      "Location Name": "BEMENT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 E BODMAN ST BEMENT IL 61813 USA "
    },
    {
      "Location ID": "61814",
      "Location Name": "BISMARCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 E HOLLOWAY ST BISMARCK IL 61814 USA "
    },
    {
      "Location ID": "61815",
      "Location Name": "BONDVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 S MARKET ST BONDVILLE IL 61815 USA "
    },
    {
      "Location ID": "61816",
      "Location Name": "BROADLANDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 E DILLER ST BROADLANDS IL 61816 USA "
    },
    {
      "Location ID": "61817",
      "Location Name": "CATLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 S SANDUSKY ST CATLIN IL 61817 USA "
    },
    {
      "Location ID": "61818",
      "Location Name": "CERRO GORDO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 313 E SOUTH ST CERRO GORDO IL 61818 USA "
    },
    {
      "Location ID": "61820",
      "Location Name": "NEIL STREET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 600 N NEIL ST CHAMPAIGN IL 61820 USA "
    },
    {
      "Location ID": "61821",
      "Location Name": "CHAMPAIGN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2001 N MATTIS AVE CHAMPAIGN IL 61821 USA "
    },
    {
      "Location ID": "61830",
      "Location Name": "CISCO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 S MAIN ST CISCO IL 61830 USA "
    },
    {
      "Location ID": "61831",
      "Location Name": "COLLISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7205 WALNUT ST COLLISON IL 61831 USA "
    },
    {
      "Location ID": "61832",
      "Location Name": "DANVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 N HAZEL ST DANVILLE IL 61832 USA "
    },
    {
      "Location ID": "61839",
      "Location Name": "DE LAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 NE 3RD ST DE LAND IL 61839 USA "
    },
    {
      "Location ID": "61840",
      "Location Name": "DEWEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 2ND ST DEWEY IL 61840 USA "
    },
    {
      "Location ID": "61841",
      "Location Name": "FAIRMOUNT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 S MAIN ST FAIRMOUNT IL 61841 USA "
    },
    {
      "Location ID": "61842",
      "Location Name": "FARMER CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 E GREEN ST FARMER CITY IL 61842 USA "
    },
    {
      "Location ID": "61843",
      "Location Name": "FISHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 N 3RD ST FISHER IL 61843 USA "
    },
    {
      "Location ID": "61844",
      "Location Name": "FITHIAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 N MAIN ST FITHIAN IL 61844 USA "
    },
    {
      "Location ID": "61845",
      "Location Name": "FOOSLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 2ND ST FOOSLAND IL 61845 USA "
    },
    {
      "Location ID": "61846",
      "Location Name": "GEORGETOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 E WEST ST GEORGETOWN IL 61846 USA "
    },
    {
      "Location ID": "61847",
      "Location Name": "GIFFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 213 S MAIN ST GIFFORD IL 61847 USA "
    },
    {
      "Location ID": "61848",
      "Location Name": "HENNING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 122 E LANE ST HENNING IL 61848 USA "
    },
    {
      "Location ID": "61849",
      "Location Name": "HOMER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 135 S MAIN ST HOMER IL 61849 USA "
    },
    {
      "Location ID": "61850",
      "Location Name": "INDIANOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2 N VERMILION ST INDIANOLA IL 61850 USA "
    },
    {
      "Location ID": "61851",
      "Location Name": "IVESDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 CHAPIN ST IVESDALE IL 61851 USA "
    },
    {
      "Location ID": "61852",
      "Location Name": "LONGVIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 E LOGAN ST LONGVIEW IL 61852 USA "
    },
    {
      "Location ID": "61853",
      "Location Name": "MAHOMET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 304 E MAIN ST MAHOMET IL 61853 USA "
    },
    {
      "Location ID": "61854",
      "Location Name": "MANSFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 W OLIVER ST MANSFIELD IL 61854 USA "
    },
    {
      "Location ID": "61856",
      "Location Name": "MONTICELLO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 W LIVINGSTON ST MONTICELLO IL 61856 USA "
    },
    {
      "Location ID": "61857",
      "Location Name": "MUNCIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N MAIN ST MUNCIE IL 61857 USA "
    },
    {
      "Location ID": "61858",
      "Location Name": "OAKWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 S SCOTT ST OAKWOOD IL 61858 USA "
    },
    {
      "Location ID": "61859",
      "Location Name": "OGDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 S EAST AVE OGDEN IL 61859 USA "
    },
    {
      "Location ID": "61862",
      "Location Name": "PENFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 128 MAIN ST PENFIELD IL 61862 USA "
    },
    {
      "Location ID": "61863",
      "Location Name": "PESOTUM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 1/2 E LINCOLN ST PESOTUM IL 61863 USA "
    },
    {
      "Location ID": "61864",
      "Location Name": "PHILO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 126 W WASHINGTON ST PHILO IL 61864 USA "
    },
    {
      "Location ID": "61865",
      "Location Name": "POTOMAC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 W STATE ST POTOMAC IL 61865 USA "
    },
    {
      "Location ID": "61866",
      "Location Name": "RANTOUL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 N MAPLEWOOD DR RANTOUL IL 61866 USA "
    },
    {
      "Location ID": "61870",
      "Location Name": "RIDGE FARM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10 W WOODYARD AVE RIDGE FARM IL 61870 USA "
    },
    {
      "Location ID": "61871",
      "Location Name": "ROYAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 CHESTER ST ROYAL IL 61871 USA "
    },
    {
      "Location ID": "61872",
      "Location Name": "SADORUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 E MARKET ST SADORUS IL 61872 USA "
    },
    {
      "Location ID": "61873",
      "Location Name": "SAINT JOSEPH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 N MAIN ST SAINT JOSEPH IL 61873 USA "
    },
    {
      "Location ID": "61874",
      "Location Name": "SAVOY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 415 N DUNLAP AVE SAVOY IL 61874 USA "
    },
    {
      "Location ID": "61875",
      "Location Name": "SEYMOUR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 N MAIN ST SEYMOUR IL 61875 USA "
    },
    {
      "Location ID": "61876",
      "Location Name": "SIDELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 E MARKET ST SIDELL IL 61876 USA "
    },
    {
      "Location ID": "61877",
      "Location Name": "SIDNEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 222 S DAVID ST SIDNEY IL 61877 USA "
    },
    {
      "Location ID": "61878",
      "Location Name": "THOMASBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 610 N COMMERCIAL ST THOMASBORO IL 61878 USA "
    },
    {
      "Location ID": "61880",
      "Location Name": "TOLONO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 N VORCEY ST TOLONO IL 61880 USA "
    },
    {
      "Location ID": "61882",
      "Location Name": "WELDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 450 MAPLE ST WELDON IL 61882 USA "
    },
    {
      "Location ID": "61883",
      "Location Name": "WESTVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 S STATE ST WESTVILLE IL 61883 USA "
    },
    {
      "Location ID": "61884",
      "Location Name": "WHITE HEATH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 E HIGH ST WHITE HEATH IL 61884 USA "
    },
    {
      "Location ID": "618AN",
      "Location Name": "CHAMPAIGN CHRISTMAS ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2309 W BLOOMINGTON RD STE I CHAMPAIGN IL 61822 USA "
    },
    {
      "Location ID": "618HH",
      "Location Name": "HORIZON HOBBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1608 INTERSTATE DRIVE CHAMPAIGN IL 61821 USA "
    },
    {
      "Location ID": "61910",
      "Location Name": "ARCOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 S OAK ST ARCOLA IL 61910 USA "
    },
    {
      "Location ID": "61911",
      "Location Name": "ARTHUR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 315 S VINE ST STE 2 ARTHUR IL 61911 USA "
    },
    {
      "Location ID": "61912",
      "Location Name": "ASHMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 W ASHMORE ST ASHMORE IL 61912 USA "
    },
    {
      "Location ID": "61913",
      "Location Name": "ATWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 W CENTRAL ST ATWOOD IL 61913 USA "
    },
    {
      "Location ID": "61914",
      "Location Name": "BETHANY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 S WASHINGTON ST BETHANY IL 61914 USA "
    },
    {
      "Location ID": "61917",
      "Location Name": "BROCTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 E 3RD ST BROCTON IL 61917 USA "
    },
    {
      "Location ID": "61919",
      "Location Name": "CAMARGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 W MAIN ST CAMARGO IL 61919 USA "
    },
    {
      "Location ID": "61920",
      "Location Name": "CHARLESTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 667 WINDSOR RD CHARLESTON IL 61920 USA "
    },
    {
      "Location ID": "61924",
      "Location Name": "CHRISMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 W MADISON AVE CHRISMAN IL 61924 USA "
    },
    {
      "Location ID": "61925",
      "Location Name": "DALTON CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 135 E MAIN ST DALTON CITY IL 61925 USA "
    },
    {
      "Location ID": "61929",
      "Location Name": "HAMMOND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 E 1ST ST HAMMOND IL 61929 USA "
    },
    {
      "Location ID": "61930",
      "Location Name": "HINDSBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 407 MISSOURI ST HINDSBORO IL 61930 USA "
    },
    {
      "Location ID": "61931",
      "Location Name": "HUMBOLDT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 407 ELM ST HUMBOLDT IL 61931 USA "
    },
    {
      "Location ID": "61932",
      "Location Name": "HUME",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 FRONT ST HUME IL 61932 USA "
    },
    {
      "Location ID": "61933",
      "Location Name": "KANSAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 126 E BUENA VISTA ST KANSAS IL 61933 USA "
    },
    {
      "Location ID": "61936",
      "Location Name": "LA PLACE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 E FRONT ST LA PLACE IL 61936 USA "
    },
    {
      "Location ID": "61937",
      "Location Name": "LOVINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E STATE ST LOVINGTON IL 61937 USA "
    },
    {
      "Location ID": "61938",
      "Location Name": "MATTOON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 N 15TH ST MATTOON IL 61938 USA "
    },
    {
      "Location ID": "61940",
      "Location Name": "METCALF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 N CENTRAL ST METCALF IL 61940 USA "
    },
    {
      "Location ID": "61941",
      "Location Name": "MURDOCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 N CENTER ST MURDOCK IL 61941 USA "
    },
    {
      "Location ID": "61942",
      "Location Name": "NEWMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S HOWARD ST NEWMAN IL 61942 USA "
    },
    {
      "Location ID": "61943",
      "Location Name": "OAKLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4 PIKE ST OAKLAND IL 61943 USA "
    },
    {
      "Location ID": "61944",
      "Location Name": "PARIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 139 E WOOD ST PARIS IL 61944 USA "
    },
    {
      "Location ID": "61949",
      "Location Name": "REDMON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 402 OAK ST REDMON IL 61949 USA "
    },
    {
      "Location ID": "61951",
      "Location Name": "SULLIVAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16 S MADISON ST SULLIVAN IL 61951 USA "
    },
    {
      "Location ID": "61953",
      "Location Name": "TUSCOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 E SALE ST TUSCOLA IL 61953 USA "
    },
    {
      "Location ID": "61955",
      "Location Name": "VERMILION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 EDGAR ST VERMILION IL 61955 USA "
    },
    {
      "Location ID": "61956",
      "Location Name": "VILLA GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 N MAIN ST VILLA GROVE IL 61956 USA "
    },
    {
      "Location ID": "61957",
      "Location Name": "WINDSOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1105 MAINE ST WINDSOR IL 61957 USA "
    },
    {
      "Location ID": "619RR",
      "Location Name": "LSC COMMUNICATIONS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6821 E COUNTRY ROAD 1100N MATTOON IL 61938 USA "
    },
    {
      "Location ID": "61MTE",
      "Location Name": "PAE - CHICAGO MTESC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1070 WINDHAM PKWY ROMEOVILLE IL 60446 USA "
    },
    {
      "Location ID": "62002",
      "Location Name": "ALTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2350 HOMER M ADAMS PKWY ALTON IL 62002 USA "
    },
    {
      "Location ID": "62017",
      "Location Name": "COFFEEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 W MAIN ST COFFEEN IL 62017 USA "
    },
    {
      "Location ID": "62022",
      "Location Name": "DOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24443 PINE ST DOW IL 62022 USA "
    },
    {
      "Location ID": "62028",
      "Location Name": "ELSAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 48 MILL ST ELSAH IL 62028 USA "
    },
    {
      "Location ID": "62032",
      "Location Name": "FILLMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S MAIN ST FILLMORE IL 62032 USA "
    },
    {
      "Location ID": "62035",
      "Location Name": "GODFREY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 TOLLE LN GODFREY IL 62035 USA "
    },
    {
      "Location ID": "62037",
      "Location Name": "GRAFTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 419 E MAIN ST GRAFTON IL 62037 USA "
    },
    {
      "Location ID": "62049",
      "Location Name": "HILLSBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 W WOOD ST HILLSBORO IL 62049 USA "
    },
    {
      "Location ID": "62080",
      "Location Name": "RAMSEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 320 S SUPERIOR ST RAMSEY IL 62080 USA "
    },
    {
      "Location ID": "62089",
      "Location Name": "TAYLOR SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 618 E MAIN ST TAYLOR SPRINGS IL 62089 USA "
    },
    {
      "Location ID": "62239",
      "Location Name": "DUPO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 EMMA AVE DUPO IL 62239 USA "
    },
    {
      "Location ID": "62240",
      "Location Name": "EAST CARONDELET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 824 STATE ST EAST CARONDELET IL 62240 USA "
    },
    {
      "Location ID": "62301",
      "Location Name": "QUINCY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3535 LOCUST ST QUINCY IL 62301 USA "
    },
    {
      "Location ID": "62311",
      "Location Name": "AUGUSTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 503 MAIN ST AUGUSTA IL 62311 USA "
    },
    {
      "Location ID": "62316",
      "Location Name": "BOWEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 E 5TH ST BOWEN IL 62316 USA "
    },
    {
      "Location ID": "62319",
      "Location Name": "CAMDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12270 BROADWAY ST CAMDEN IL 62319 USA "
    },
    {
      "Location ID": "62320",
      "Location Name": "CAMP POINT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 N ILLINOIS ST CAMP POINT IL 62320 USA "
    },
    {
      "Location ID": "62326",
      "Location Name": "COLCHESTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 W MARKET ST COLCHESTER IL 62326 USA "
    },
    {
      "Location ID": "62339",
      "Location Name": "GOLDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 SMITH ST GOLDEN IL 62339 USA "
    },
    {
      "Location ID": "62343",
      "Location Name": "HULL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 E HULL AVE HULL IL 62343 USA "
    },
    {
      "Location ID": "62345",
      "Location Name": "KINDERHOOK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 185 S HARPER ST KINDERHOOK IL 62345 USA "
    },
    {
      "Location ID": "62346",
      "Location Name": "LA PRAIRIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E MAIN ST LA PRAIRIE IL 62346 USA "
    },
    {
      "Location ID": "62349",
      "Location Name": "LORAINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 N BROAD ST LORAINE IL 62349 USA "
    },
    {
      "Location ID": "62351",
      "Location Name": "MENDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 W COLLINS ST MENDON IL 62351 USA "
    },
    {
      "Location ID": "62352",
      "Location Name": "MILTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 435 PITTSFIELD ST MILTON IL 62352 USA "
    },
    {
      "Location ID": "62355",
      "Location Name": "NEBO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 133 S UNION ST NEBO IL 62355 USA "
    },
    {
      "Location ID": "62356",
      "Location Name": "NEW CANTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 140 N MAIN ST NEW CANTON IL 62356 USA "
    },
    {
      "Location ID": "62360",
      "Location Name": "PAYSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 N MAIN ST PAYSON IL 62360 USA "
    },
    {
      "Location ID": "62361",
      "Location Name": "PEARL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 N FRONT ST PEARL IL 62361 USA "
    },
    {
      "Location ID": "62365",
      "Location Name": "PLAINVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 230 E MAIN ST PLAINVILLE IL 62365 USA "
    },
    {
      "Location ID": "62366",
      "Location Name": "PLEASANT HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 E QUINCY ST PLEASANT HILL IL 62366 USA "
    },
    {
      "Location ID": "62367",
      "Location Name": "PLYMOUTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 EASTSIDE SQ PLYMOUTH IL 62367 USA "
    },
    {
      "Location ID": "62370",
      "Location Name": "ROCKPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 MERCHANT ST ROCKPORT IL 62370 USA "
    },
    {
      "Location ID": "62374",
      "Location Name": "TENNESSEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 W BUSHNELL ST TENNESSEE IL 62374 USA "
    },
    {
      "Location ID": "623KO",
      "Location Name": "KOHL'S - QUINCY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6100 BROADWAY ST QUINCY IL 62305 USA "
    },
    {
      "Location ID": "62401",
      "Location Name": "EFFINGHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 N 3RD ST EFFINGHAM IL 62401 USA "
    },
    {
      "Location ID": "62410",
      "Location Name": "ALLENDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 304 E MAIN ST ALLENDALE IL 62410 USA "
    },
    {
      "Location ID": "62411",
      "Location Name": "ALTAMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 122 W WASHINGTON AVE ALTAMONT IL 62411 USA "
    },
    {
      "Location ID": "62414",
      "Location Name": "BEECHER CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S SWEAZY ST BEECHER CITY IL 62414 USA "
    },
    {
      "Location ID": "62417",
      "Location Name": "BRIDGEPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 250 E OLIVE ST BRIDGEPORT IL 62417 USA "
    },
    {
      "Location ID": "62418",
      "Location Name": "BROWNSTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 E MAIN ST BROWNSTOWN IL 62418 USA "
    },
    {
      "Location ID": "62419",
      "Location Name": "CALHOUN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E MAIN ST CALHOUN IL 62419 USA "
    },
    {
      "Location ID": "62420",
      "Location Name": "CASEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 W ALABAMA AVE CASEY IL 62420 USA "
    },
    {
      "Location ID": "62421",
      "Location Name": "CLAREMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 N WASHINGTON BLVD CLAREMONT IL 62421 USA "
    },
    {
      "Location ID": "62422",
      "Location Name": "COWDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 E ELM ST COWDEN IL 62422 USA "
    },
    {
      "Location ID": "62424",
      "Location Name": "DIETERICH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 S MAIN ST DIETERICH IL 62424 USA "
    },
    {
      "Location ID": "62426",
      "Location Name": "EDGEWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 807 BROAD ST EDGEWOOD IL 62426 USA "
    },
    {
      "Location ID": "62428",
      "Location Name": "GREENUP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 E CUMBERLAND ST GREENUP IL 62428 USA "
    },
    {
      "Location ID": "62431",
      "Location Name": "HERRICK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9 S BROADWAY ST HERRICK IL 62431 USA "
    },
    {
      "Location ID": "62432",
      "Location Name": "HIDALGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 E HARRISON ST HIDALGO IL 62432 USA "
    },
    {
      "Location ID": "62436",
      "Location Name": "JEWETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1100 CUMBERLAND RD JEWETT IL 62436 USA "
    },
    {
      "Location ID": "62439",
      "Location Name": "LAWRENCEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1014 JEFFERSON ST LAWRENCEVILLE IL 62439 USA "
    },
    {
      "Location ID": "62440",
      "Location Name": "LERNA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 513 MAIN ST LERNA IL 62440 USA "
    },
    {
      "Location ID": "62441",
      "Location Name": "MARSHALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 N 7TH ST MARSHALL IL 62441 USA "
    },
    {
      "Location ID": "62442",
      "Location Name": "MARTINSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2 N YORK ST MARTINSVILLE IL 62442 USA "
    },
    {
      "Location ID": "62443",
      "Location Name": "MASON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 755 ADAMS ST MASON IL 62443 USA "
    },
    {
      "Location ID": "62445",
      "Location Name": "MONTROSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 W NATIONAL RD MONTROSE IL 62445 USA "
    },
    {
      "Location ID": "62446",
      "Location Name": "MOUNT ERIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 W MAIN ST MOUNT ERIE IL 62446 USA "
    },
    {
      "Location ID": "62447",
      "Location Name": "NEOGA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 256 E 6TH ST NEOGA IL 62447 USA "
    },
    {
      "Location ID": "62448",
      "Location Name": "NEWTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W JOURDAN ST NEWTON IL 62448 USA "
    },
    {
      "Location ID": "62449",
      "Location Name": "OBLONG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 N RANGE ST OBLONG IL 62449 USA "
    },
    {
      "Location ID": "62450",
      "Location Name": "OLNEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 330 S WHITTLE AVE OLNEY IL 62450 USA "
    },
    {
      "Location ID": "62451",
      "Location Name": "PALESTINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 W MARKET ST PALESTINE IL 62451 USA "
    },
    {
      "Location ID": "62452",
      "Location Name": "PARKERSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N MAIN ST PARKERSBURG IL 62452 USA "
    },
    {
      "Location ID": "62454",
      "Location Name": "ROBINSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E WALNUT ST ROBINSON IL 62454 USA "
    },
    {
      "Location ID": "62458",
      "Location Name": "SAINT ELMO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 E 5TH ST SAINT ELMO IL 62458 USA "
    },
    {
      "Location ID": "62460",
      "Location Name": "SAINT FRANCISVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 803 MAIN ST SAINT FRANCISVILLE IL 62460 USA "
    },
    {
      "Location ID": "62461",
      "Location Name": "SHUMWAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E MAIN ST SHUMWAY IL 62461 USA "
    },
    {
      "Location ID": "62462",
      "Location Name": "SIGEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 E LOUIS ST SIGEL IL 62462 USA "
    },
    {
      "Location ID": "62463",
      "Location Name": "STEWARDSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 W MAIN ST STEWARDSON IL 62463 USA "
    },
    {
      "Location ID": "62465",
      "Location Name": "STRASBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 E COMMERCIAL ST STRASBURG IL 62465 USA "
    },
    {
      "Location ID": "62466",
      "Location Name": "SUMNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 W SOUTH AVE SUMNER IL 62466 USA "
    },
    {
      "Location ID": "62467",
      "Location Name": "TEUTOPOLIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 W MAIN ST TEUTOPOLIS IL 62467 USA "
    },
    {
      "Location ID": "62468",
      "Location Name": "TOLEDO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 COURT HOUSE SQ TOLEDO IL 62468 USA "
    },
    {
      "Location ID": "62469",
      "Location Name": "TRILLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 W TRILLA RD TRILLA IL 62469 USA "
    },
    {
      "Location ID": "62471",
      "Location Name": "VANDALIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 304 S 4TH ST VANDALIA IL 62471 USA "
    },
    {
      "Location ID": "62473",
      "Location Name": "WATSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S MONROE ST WATSON IL 62473 USA "
    },
    {
      "Location ID": "62474",
      "Location Name": "WESTFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 W STATE ST WESTFIELD IL 62474 USA "
    },
    {
      "Location ID": "62476",
      "Location Name": "WEST SALEM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 E NORTH ST WEST SALEM IL 62476 USA "
    },
    {
      "Location ID": "62479",
      "Location Name": "WHEELER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13244 N MAIN ST WHEELER IL 62479 USA "
    },
    {
      "Location ID": "62481",
      "Location Name": "YALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 S RANGE ST YALE IL 62481 USA "
    },
    {
      "Location ID": "624AA",
      "Location Name": "KINGERY PRINTING CONNEXIONS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3012 S BANKER ST EFFINGHAM IL 62401 USA "
    },
    {
      "Location ID": "624AB",
      "Location Name": "QUAD GRAPHICS EFFNGHM INDUST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 420 INDUSTRIAL AVE EFFINGHAM IL 62401 USA "
    },
    {
      "Location ID": "624PP",
      "Location Name": "POLY PAK & SHIP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2021 ILLINI AVE VANDALIA IL 62471 USA "
    },
    {
      "Location ID": "624QP",
      "Location Name": "QUEBECOR PRINTING CO. (IDS59",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1200 W NICCUM AVE EFFINGHAM IL 62401 USA "
    },
    {
      "Location ID": "625",
      "Location Name": "SPRINGFIELD IL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2105 E COOK ST SPRINGFIELD IL 62703 USA "
    },
    {
      "Location ID": "62510",
      "Location Name": "ASSUMPTION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 219 N CHESTNUT ST ASSUMPTION IL 62510 USA "
    },
    {
      "Location ID": "62513",
      "Location Name": "BLUE MOUND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 230 W NILES ST BLUE MOUND IL 62513 USA "
    },
    {
      "Location ID": "62526",
      "Location Name": "MEMORIAL STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1601 E MOUND RD DECATUR IL 62526 USA "
    },
    {
      "Location ID": "62530",
      "Location Name": "DIVERNON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 157 W DODDS ST DIVERNON IL 62530 USA "
    },
    {
      "Location ID": "62534",
      "Location Name": "FINDLAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 E SOUTH 2ND ST FINDLAY IL 62534 USA "
    },
    {
      "Location ID": "62536",
      "Location Name": "GLENARM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 53 JUDD RD GLENARM IL 62536 USA "
    },
    {
      "Location ID": "62537",
      "Location Name": "HARRISTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1355 N MERIDIAN RD HARRISTOWN IL 62537 USA "
    },
    {
      "Location ID": "62539",
      "Location Name": "ILLIOPOLIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 463 LOUISA ST ILLIOPOLIS IL 62539 USA "
    },
    {
      "Location ID": "62540",
      "Location Name": "KINCAID",
      "Time Zone Offset (Hours)": -1,
      "Address": " 607 DIAL ST KINCAID IL 62540 USA "
    },
    {
      "Location ID": "62543",
      "Location Name": "LATHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 221 N MACON ST LATHAM IL 62543 USA "
    },
    {
      "Location ID": "62544",
      "Location Name": "MACON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 W DUNN ST MACON IL 62544 USA "
    },
    {
      "Location ID": "62550",
      "Location Name": "MOWEAQUA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 W MAIN ST MOWEAQUA IL 62550 USA "
    },
    {
      "Location ID": "62551",
      "Location Name": "NIANTIC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 155 E LEWIS ST NIANTIC IL 62551 USA "
    },
    {
      "Location ID": "62553",
      "Location Name": "OCONEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10 OAK ST OCONEE IL 62553 USA "
    },
    {
      "Location ID": "62557",
      "Location Name": "PANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 S LOCUST ST PANA IL 62557 USA "
    },
    {
      "Location ID": "62558",
      "Location Name": "PAWNEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 620 5TH ST PAWNEE IL 62558 USA "
    },
    {
      "Location ID": "62565",
      "Location Name": "SHELBYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 S MORGAN ST SHELBYVILLE IL 62565 USA "
    },
    {
      "Location ID": "62567",
      "Location Name": "STONINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 E 4TH ST STONINGTON IL 62567 USA "
    },
    {
      "Location ID": "62568",
      "Location Name": "TAYLORVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 S MAIN ST TAYLORVILLE IL 62568 USA "
    },
    {
      "Location ID": "62570",
      "Location Name": "TOVEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 411 MIDLAND ST TOVEY IL 62570 USA "
    },
    {
      "Location ID": "62571",
      "Location Name": "TOWER HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 S MICHIGAN ST TOWER HILL IL 62571 USA "
    },
    {
      "Location ID": "62573",
      "Location Name": "WARRENSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 164 N MAIN ST WARRENSBURG IL 62573 USA "
    },
    {
      "Location ID": "62617",
      "Location Name": "BATH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 E 1ST ST BATH IL 62617 USA "
    },
    {
      "Location ID": "62630",
      "Location Name": "CHESTERFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20 S MAIN ST CHESTERFIELD IL 62630 USA "
    },
    {
      "Location ID": "62633",
      "Location Name": "EASTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 S 2ND ST EASTON IL 62633 USA "
    },
    {
      "Location ID": "62635",
      "Location Name": "EMDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 304 LINCOLN ST EMDEN IL 62635 USA "
    },
    {
      "Location ID": "62638",
      "Location Name": "FRANKLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 312 WYATT AVE FRANKLIN IL 62638 USA "
    },
    {
      "Location ID": "62643",
      "Location Name": "HARTSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 N 2ND ST HARTSBURG IL 62643 USA "
    },
    {
      "Location ID": "62644",
      "Location Name": "HAVANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 128 S ORANGE ST HAVANA IL 62644 USA "
    },
    {
      "Location ID": "62649",
      "Location Name": "HETTICK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 E POST ST HETTICK IL 62649 USA "
    },
    {
      "Location ID": "62656",
      "Location Name": "LINCOLN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 S MCLEAN ST LINCOLN IL 62656 USA "
    },
    {
      "Location ID": "62666",
      "Location Name": "MIDDLETOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 407 5TH ST MIDDLETOWN IL 62666 USA "
    },
    {
      "Location ID": "62667",
      "Location Name": "MODESTO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 S MAIN ST MODESTO IL 62667 USA "
    },
    {
      "Location ID": "62671",
      "Location Name": "NEW HOLLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 W LINCOLN ST NEW HOLLAND IL 62671 USA "
    },
    {
      "Location ID": "62674",
      "Location Name": "PALMYRA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 S MAIN ST PALMYRA IL 62674 USA "
    },
    {
      "Location ID": "62675",
      "Location Name": "PETERSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 S 7TH ST PETERSBURG IL 62675 USA "
    },
    {
      "Location ID": "62682",
      "Location Name": "SAN JOSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 W VINE ST SAN JOSE IL 62682 USA "
    },
    {
      "Location ID": "62692",
      "Location Name": "WAVERLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 N PEARL ST WAVERLY IL 62692 USA "
    },
    {
      "Location ID": "62702",
      "Location Name": "NORTHEAST ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1927 E SANGAMON AVE SPRINGFIELD IL 62702 USA "
    },
    {
      "Location ID": "628",
      "Location Name": "CENTRALIA IL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 314 S POPLAR ST CENTRALIA IL 62801 USA "
    },
    {
      "Location ID": "62806",
      "Location Name": "ALBION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 W MAIN ST ALBION IL 62806 USA "
    },
    {
      "Location ID": "62812",
      "Location Name": "BENTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 N DU QUOIN ST BENTON IL 62812 USA "
    },
    {
      "Location ID": "62815",
      "Location Name": "BONE GAP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 N STATE ST BONE GAP IL 62815 USA "
    },
    {
      "Location ID": "62819",
      "Location Name": "BUCKNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 S MARKET ST BUCKNER IL 62819 USA "
    },
    {
      "Location ID": "62822",
      "Location Name": "CHRISTOPHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 N EMMA ST CHRISTOPHER IL 62822 USA "
    },
    {
      "Location ID": "62825",
      "Location Name": "COELLO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9091 MAIN ST COELLO IL 62825 USA "
    },
    {
      "Location ID": "62837",
      "Location Name": "FAIRFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 E DELAWARE ST FAIRFIELD IL 62837 USA "
    },
    {
      "Location ID": "62841",
      "Location Name": "FREEMAN SPUR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 19072 FREEMAN SPUR RD FREEMAN SPUR IL 62841 USA "
    },
    {
      "Location ID": "62862",
      "Location Name": "MILL SHOALS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 483 LOCUST ST MILL SHOALS IL 62862 USA "
    },
    {
      "Location ID": "62865",
      "Location Name": "MULKEYTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7803 MAIN ST MULKEYTOWN IL 62865 USA "
    },
    {
      "Location ID": "62887",
      "Location Name": "SPRINGERTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 S MAIN ST SPRINGERTON IL 62887 USA "
    },
    {
      "Location ID": "62891",
      "Location Name": "VALIER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E MAIN ST VALIER IL 62891 USA "
    },
    {
      "Location ID": "62896",
      "Location Name": "WEST FRANKFORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 219 E MAIN ST WEST FRANKFORT IL 62896 USA "
    },
    {
      "Location ID": "629",
      "Location Name": "CARBONDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1301 E MAIN ST CARBONDALE IL 62901 USA "
    },
    {
      "Location ID": "62907",
      "Location Name": "AVA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 222 W MAIN ST AVA IL 62907 USA "
    },
    {
      "Location ID": "62914",
      "Location Name": "CAIRO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1500 WASHINGTON AVE CAIRO IL 62914 USA "
    },
    {
      "Location ID": "62915",
      "Location Name": "CAMBRIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 N MAPLE ST CAMBRIA IL 62915 USA "
    },
    {
      "Location ID": "62916",
      "Location Name": "CAMPBELL HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 W FRONT ST CAMPBELL HILL IL 62916 USA "
    },
    {
      "Location ID": "62918",
      "Location Name": "CARTERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E GRAND AVE CARTERVILLE IL 62918 USA "
    },
    {
      "Location ID": "62919",
      "Location Name": "CAVE IN ROCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 153 E MAIN ST CAVE IN ROCK IL 62919 USA "
    },
    {
      "Location ID": "62921",
      "Location Name": "COLP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 N MAYOR CALIPER DR COLP IL 62921 USA "
    },
    {
      "Location ID": "62924",
      "Location Name": "DE SOTO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 N CHESTNUT ST DE SOTO IL 62924 USA "
    },
    {
      "Location ID": "62927",
      "Location Name": "DOWELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 UNION AVE DOWELL IL 62927 USA "
    },
    {
      "Location ID": "62930",
      "Location Name": "ELDORADO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 4TH ST ELDORADO IL 62930 USA "
    },
    {
      "Location ID": "62931",
      "Location Name": "ELIZABETHTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 151 N MAIN ST ELIZABETHTOWN IL 62931 USA "
    },
    {
      "Location ID": "62932",
      "Location Name": "ELKVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 S 4TH ST ELKVILLE IL 62932 USA "
    },
    {
      "Location ID": "62933",
      "Location Name": "ENERGY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 N PERSHING ST ENERGY IL 62933 USA "
    },
    {
      "Location ID": "62934",
      "Location Name": "EQUALITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 W LANE ST EQUALITY IL 62934 USA "
    },
    {
      "Location ID": "62935",
      "Location Name": "GALATIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 W MAIN ST GALATIA IL 62935 USA "
    },
    {
      "Location ID": "62946",
      "Location Name": "HARRISBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 W CHURCH ST HARRISBURG IL 62946 USA "
    },
    {
      "Location ID": "62947",
      "Location Name": "HEROD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 HIGHWAY 34 S HEROD IL 62947 USA "
    },
    {
      "Location ID": "62948",
      "Location Name": "HERRIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 S PARK AVE HERRIN IL 62948 USA "
    },
    {
      "Location ID": "62949",
      "Location Name": "HURST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 N BUSH AVE HURST IL 62949 USA "
    },
    {
      "Location ID": "62951",
      "Location Name": "JOHNSTON CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 W BROADWAY BLVD JOHNSTON CITY IL 62951 USA "
    },
    {
      "Location ID": "62954",
      "Location Name": "JUNCTION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 348 FRONT ST JUNCTION IL 62954 USA "
    },
    {
      "Location ID": "62959",
      "Location Name": "MARION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 E MAIN ST MARION IL 62959 USA "
    },
    {
      "Location ID": "62965",
      "Location Name": "MUDDY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 PUBLIC RD MUDDY IL 62965 USA "
    },
    {
      "Location ID": "62966",
      "Location Name": "MURPHYSBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 N 11TH ST MURPHYSBORO IL 62966 USA "
    },
    {
      "Location ID": "62977",
      "Location Name": "RALEIGH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16 E CHURCH ST RALEIGH IL 62977 USA "
    },
    {
      "Location ID": "62979",
      "Location Name": "RIDGWAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 S MURPHY ST RIDGWAY IL 62979 USA "
    },
    {
      "Location ID": "62982",
      "Location Name": "ROSICLARE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 POSTAL WAY ROSICLARE IL 62982 USA "
    },
    {
      "Location ID": "62983",
      "Location Name": "ROYALTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 309 S MAIN ST ROYALTON IL 62983 USA "
    },
    {
      "Location ID": "62984",
      "Location Name": "SHAWNEETOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 N LINCOLN BLVD E SHAWNEETOWN IL 62984 USA "
    },
    {
      "Location ID": "62994",
      "Location Name": "VERGENNES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 725 HACK AVE VERGENNES IL 62994 USA "
    },
    {
      "Location ID": "62997",
      "Location Name": "WILLISVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 808 BROADWAY ST WILLISVILLE IL 62997 USA "
    },
    {
      "Location ID": "62999",
      "Location Name": "ZEIGLER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2 CIRCLE ST ZEIGLER IL 62999 USA "
    },
    {
      "Location ID": "630",
      "Location Name": "LOG SAINT LOUIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1720 MARKET ST RM 3025 SAINT LOUIS MO 63155 USA "
    },
    {
      "Location ID": "63013",
      "Location Name": "BEAUFORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6611 HIGHWAY 185 BEAUFORT MO 63013 USA "
    },
    {
      "Location ID": "63025",
      "Location Name": "EUREKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 THRESHER DR EUREKA MO 63025 USA "
    },
    {
      "Location ID": "63026",
      "Location Name": "FENTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10 FENTON PLZ FENTON MO 63026 USA "
    },
    {
      "Location ID": "63028",
      "Location Name": "FESTUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 WALNUT ST FESTUS MO 63028 USA "
    },
    {
      "Location ID": "63037",
      "Location Name": "GERALD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20 W 4TH ST GERALD MO 63037 USA "
    },
    {
      "Location ID": "63039",
      "Location Name": "GRAY SUMMIT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2666 HIGHWAY 100 GRAY SUMMIT MO 63039 USA "
    },
    {
      "Location ID": "63047",
      "Location Name": "HEMATITE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3677 STATE ROAD P HEMATITE MO 63047 USA "
    },
    {
      "Location ID": "63056",
      "Location Name": "LESLIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 321 MAIN ST LESLIE MO 63056 USA "
    },
    {
      "Location ID": "63065",
      "Location Name": "MAPAVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3823 STATE ROAD Z MAPAVILLE MO 63065 USA "
    },
    {
      "Location ID": "63069",
      "Location Name": "PACIFIC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 324 W SAINT LOUIS ST PACIFIC MO 63069 USA "
    },
    {
      "Location ID": "63077",
      "Location Name": "SAINT CLAIR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1020 PLAZA CT S STE A SAINT CLAIR MO 63077 USA "
    },
    {
      "Location ID": "63079",
      "Location Name": "STANTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 51 HIGHWAY W STANTON MO 63079 USA "
    },
    {
      "Location ID": "63080",
      "Location Name": "SULLIVAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 33 W VINE ST SULLIVAN MO 63080 USA "
    },
    {
      "Location ID": "63084",
      "Location Name": "UNION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1276 N HIGHWAY 47 UNION MO 63084 USA "
    },
    {
      "Location ID": "63088",
      "Location Name": "VALLEY PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 SAINT LOUIS AVE VALLEY PARK MO 63088 USA "
    },
    {
      "Location ID": "63090",
      "Location Name": "WASHINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1777 WASHINGTON CROSSING WASHINGTON MO 63090 USA "
    },
    {
      "Location ID": "63091",
      "Location Name": "ROSEBUD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 257 HIGHWAY 50 ROSEBUD MO 63091 USA "
    },
    {
      "Location ID": "630AN",
      "Location Name": "LOG SAINT LOUIS METRO ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5463 PHANTOM DR HAZELWOOD MO 63042 USA "
    },
    {
      "Location ID": "630FZ",
      "Location Name": "LOG SAINT LOUIS MO PSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4702 PARK 370 BLVD HAZELWOOD MO 63042 USA "
    },
    {
      "Location ID": "630TH",
      "Location Name": "ST LOUIS THS SURFACE HUB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6951 N HANLEY RD HAZELWOOD MO 63042 USA "
    },
    {
      "Location ID": "63301",
      "Location Name": "SAINT CHARLES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1890 1ST CAPITOL DR SAINT CHARLES MO 63301 USA "
    },
    {
      "Location ID": "63373",
      "Location Name": "PORTAGE DES SIOUX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1375 CLARK ST PORTAGE DES SIOUX MO 63373 USA "
    },
    {
      "Location ID": "63386",
      "Location Name": "WEST ALTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14000R US HIGHWAY 67 WEST ALTON MO 63386 USA "
    },
    {
      "Location ID": "63430",
      "Location Name": "ALEXANDRIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 MARKET ST ALEXANDRIA MO 63430 USA "
    },
    {
      "Location ID": "63445",
      "Location Name": "KAHOKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 W COMMERCIAL ST KAHOKA MO 63445 USA "
    },
    {
      "Location ID": "63465",
      "Location Name": "REVERE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 MAIN ST REVERE MO 63465 USA "
    },
    {
      "Location ID": "63472",
      "Location Name": "WAYLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 E DES MOINES ST WAYLAND MO 63472 USA "
    },
    {
      "Location ID": "635",
      "Location Name": "KIRKSVILLE MO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 E. JEFFERSON KIRKSVILLE MO 63501 USA "
    },
    {
      "Location ID": "63549",
      "Location Name": "LA PLATA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 E SANDERS ST LA PLATA MO 63549 USA "
    },
    {
      "Location ID": "63552",
      "Location Name": "MACON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 DUFF ST MACON MO 63552 USA "
    },
    {
      "Location ID": "636",
      "Location Name": "PARK HILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 T J STEWART DR PARK HILLS MO 63601 USA "
    },
    {
      "Location ID": "63625",
      "Location Name": "BLACK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24634 HIGHWAY 49 BLACK MO 63625 USA "
    },
    {
      "Location ID": "63629",
      "Location Name": "BUNKER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 OAK ST BUNKER MO 63629 USA "
    },
    {
      "Location ID": "63633",
      "Location Name": "CENTERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2312 GREEN ST CENTERVILLE MO 63633 USA "
    },
    {
      "Location ID": "63638",
      "Location Name": "ELLINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 2ND ST ELLINGTON MO 63638 USA "
    },
    {
      "Location ID": "63640",
      "Location Name": "FARMINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 E COLUMBIA ST FARMINGTON MO 63640 USA "
    },
    {
      "Location ID": "63645",
      "Location Name": "FREDERICKTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 155 S MAIN ST FREDERICKTOWN MO 63645 USA "
    },
    {
      "Location ID": "63650",
      "Location Name": "IRONTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 W RUSSELL ST IRONTON MO 63650 USA "
    },
    {
      "Location ID": "63654",
      "Location Name": "LESTERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 33010 HIGHWAY 21 LESTERVILLE MO 63654 USA "
    },
    {
      "Location ID": "63663",
      "Location Name": "PILOT KNOB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W MAPLE ST PILOT KNOB MO 63663 USA "
    },
    {
      "Location ID": "63664",
      "Location Name": "POTOSI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 LAUREL ST POTOSI MO 63664 USA "
    },
    {
      "Location ID": "63665",
      "Location Name": "REDFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 REDFORD LOOP REDFORD MO 63665 USA "
    },
    {
      "Location ID": "63670",
      "Location Name": "SAINTE GENEVIEVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 135 MERCHANT ST SAINTE GENEVIEVE MO 63670 USA "
    },
    {
      "Location ID": "637",
      "Location Name": "LOG CAPE GIRARDEAU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 475 KELL FARM DR CAPE GIRARDEAU MO 63701 USA "
    },
    {
      "Location ID": "63732",
      "Location Name": "ALTENBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 51 POPLAR ST ALTENBURG MO 63732 USA "
    },
    {
      "Location ID": "63736",
      "Location Name": "BENTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 S WINCHESTER BENTON MO 63736 USA "
    },
    {
      "Location ID": "63740",
      "Location Name": "CHAFFEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 217 S MAIN ST CHAFFEE MO 63740 USA "
    },
    {
      "Location ID": "63742",
      "Location Name": "COMMERCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 308 CAPE GIRARDEAU ST COMMERCE MO 63742 USA "
    },
    {
      "Location ID": "63748",
      "Location Name": "FROHNA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7 BALL PARK DR FROHNA MO 63748 USA "
    },
    {
      "Location ID": "63751",
      "Location Name": "GLENALLEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 19158 SHORT ST GLENALLEN MO 63751 USA "
    },
    {
      "Location ID": "63752",
      "Location Name": "GORDONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5850 STATE HIGHWAY 25 GORDONVILLE MO 63752 USA "
    },
    {
      "Location ID": "63755",
      "Location Name": "JACKSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 E MAIN ST JACKSON MO 63755 USA "
    },
    {
      "Location ID": "63758",
      "Location Name": "KELSO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 140 S MESSMER ST KELSO MO 63758 USA "
    },
    {
      "Location ID": "63760",
      "Location Name": "LEOPOLD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15243 BCR 402 LEOPOLD MO 63760 USA "
    },
    {
      "Location ID": "63764",
      "Location Name": "MARBLE HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 STATE HIGHWAY 34 W MARBLE HILL MO 63764 USA "
    },
    {
      "Location ID": "63767",
      "Location Name": "MORLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 N HARDING ST MORLEY MO 63767 USA "
    },
    {
      "Location ID": "63770",
      "Location Name": "OLD APPLETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 343 APPLETON MAIN ST OLD APPLETON MO 63770 USA "
    },
    {
      "Location ID": "63775",
      "Location Name": "PERRYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20 E SAINTE MARIE ST PERRYVILLE MO 63775 USA "
    },
    {
      "Location ID": "63780",
      "Location Name": "SCOTT CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 605 2ND ST E SCOTT CITY MO 63780 USA "
    },
    {
      "Location ID": "63784",
      "Location Name": "VANDUSER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 902 VANDUSER ST VANDUSER MO 63784 USA "
    },
    {
      "Location ID": "63785",
      "Location Name": "WHITEWATER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8742 STATE HIGHWAY A WHITEWATER MO 63785 USA "
    },
    {
      "Location ID": "638",
      "Location Name": "SIKESTON MO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 E. NORTH ST. SIKESTON MO 63801 USA "
    },
    {
      "Location ID": "63821",
      "Location Name": "ARBYRD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 NE FRISCO ST ARBYRD MO 63821 USA "
    },
    {
      "Location ID": "63824",
      "Location Name": "BLODGETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 406 STATE HIGHWAY H BLODGETT MO 63824 USA "
    },
    {
      "Location ID": "63829",
      "Location Name": "CARDWELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 S MAIN ST CARDWELL MO 63829 USA "
    },
    {
      "Location ID": "63841",
      "Location Name": "DEXTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 E STODDARD ST DEXTER MO 63841 USA "
    },
    {
      "Location ID": "63855",
      "Location Name": "HORNERSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 405 PECAN ST HORNERSVILLE MO 63855 USA "
    },
    {
      "Location ID": "63857",
      "Location Name": "KENNETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 N MAIN ST KENNETT MO 63857 USA "
    },
    {
      "Location ID": "63876",
      "Location Name": "SENATH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 W COMMERCIAL ST SENATH MO 63876 USA "
    },
    {
      "Location ID": "639",
      "Location Name": "POPLAR BLUFF MO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 430 POPLAR ST. POPLAR BLUFF MO 63901 USA "
    },
    {
      "Location ID": "63935",
      "Location Name": "DONIPHAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 N GRAND AVE DONIPHAN MO 63935 USA "
    },
    {
      "Location ID": "63937",
      "Location Name": "ELLSINORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 NORTH ST ELLSINORE MO 63937 USA "
    },
    {
      "Location ID": "63939",
      "Location Name": "FAIRDEALING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N COUNTY ROAD 160E26 FAIRDEALING MO 63939 USA "
    },
    {
      "Location ID": "63941",
      "Location Name": "FREMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 3RD ST FREMONT MO 63941 USA "
    },
    {
      "Location ID": "63943",
      "Location Name": "GRANDIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 406 5TH ST GRANDIN MO 63943 USA "
    },
    {
      "Location ID": "63945",
      "Location Name": "HARVIELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 54 E MAIN ST HARVIELL MO 63945 USA "
    },
    {
      "Location ID": "63953",
      "Location Name": "NAYLOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 BROAD ST NAYLOR MO 63953 USA "
    },
    {
      "Location ID": "63954",
      "Location Name": "NEELYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 407 HART ST NEELYVILLE MO 63954 USA "
    },
    {
      "Location ID": "63955",
      "Location Name": "OXLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 STATE HIGHWAY 142 E OXLY MO 63955 USA "
    },
    {
      "Location ID": "63965",
      "Location Name": "VAN BUREN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 W OLIVER ST VAN BUREN MO 63965 USA "
    },
    {
      "Location ID": "63C",
      "Location Name": "PEAK HUB - ST LOUIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6857 HAZELWOOD AVE BERKELEY MO 63134 USA "
    },
    {
      "Location ID": "63Z",
      "Location Name": "LOG SAINT LOUIS NDC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5800 PHANTOM DR HAZELWOOD MO 63042 USA "
    },
    {
      "Location ID": "640",
      "Location Name": "LOG KANSAS CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1700 CLEVELAND AVE KANSAS CITY MO 64121 USA "
    },
    {
      "Location ID": "64011",
      "Location Name": "BATES CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 404 E HIGHWAY Z BATES CITY MO 64011 USA "
    },
    {
      "Location ID": "64012",
      "Location Name": "BELTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 BRADFORD LN BELTON MO 64012 USA "
    },
    {
      "Location ID": "64016",
      "Location Name": "BUCKNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16 WASHINGTON ST BUCKNER MO 64016 USA "
    },
    {
      "Location ID": "64020",
      "Location Name": "CONCORDIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13 SE 7TH ST CONCORDIA MO 64020 USA "
    },
    {
      "Location ID": "64022",
      "Location Name": "DOVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 270 W WALNUT ST DOVER MO 64022 USA "
    },
    {
      "Location ID": "64024",
      "Location Name": "EXCELSIOR SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 515 SAINT LOUIS AVE EXCELSIOR SPRINGS MO 64024 USA "
    },
    {
      "Location ID": "64029",
      "Location Name": "GRAIN VALLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 W WALNUT ST GRAIN VALLEY MO 64029 USA "
    },
    {
      "Location ID": "64035",
      "Location Name": "HARDIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 NE 2ND ST HARDIN MO 64035 USA "
    },
    {
      "Location ID": "64036",
      "Location Name": "HENRIETTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 W 3RD ST HENRIETTA MO 64036 USA "
    },
    {
      "Location ID": "64037",
      "Location Name": "HIGGINSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2001 MAIN ST HIGGINSVILLE MO 64037 USA "
    },
    {
      "Location ID": "64048",
      "Location Name": "HOLT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 MAIN ST HOLT MO 64048 USA "
    },
    {
      "Location ID": "64060",
      "Location Name": "KEARNEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 S PLATTE CLAY WAY KEARNEY MO 64060 USA "
    },
    {
      "Location ID": "64062",
      "Location Name": "LAWSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 420 N PENNSYLVANIA AVE LAWSON MO 64062 USA "
    },
    {
      "Location ID": "64066",
      "Location Name": "LEVASY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 MAIN ST LEVASY MO 64066 USA "
    },
    {
      "Location ID": "64067",
      "Location Name": "LEXINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 S BUSINESS HIGHWAY 13 LEXINGTON MO 64067 USA "
    },
    {
      "Location ID": "64068",
      "Location Name": "LIBERTY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1000 PROGRESS DR LIBERTY MO 64068 USA "
    },
    {
      "Location ID": "64070",
      "Location Name": "LONE JACK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 309 N BYNUM RD LONE JACK MO 64070 USA "
    },
    {
      "Location ID": "64072",
      "Location Name": "MISSOURI CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 426 MAIN ST MISSOURI CITY MO 64072 USA "
    },
    {
      "Location ID": "64074",
      "Location Name": "NAPOLEON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 265 W 2ND ST NAPOLEON MO 64074 USA "
    },
    {
      "Location ID": "64075",
      "Location Name": "OAK GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1700 S BROADWAY OAK GROVE MO 64075 USA "
    },
    {
      "Location ID": "64076",
      "Location Name": "ODESSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E DRYDEN ST ODESSA MO 64076 USA "
    },
    {
      "Location ID": "64077",
      "Location Name": "ORRICK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 W SOUTH FRONT ST ORRICK MO 64077 USA "
    },
    {
      "Location ID": "64079",
      "Location Name": "PLATTE CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1101 HAMPTON LN PLATTE CITY MO 64079 USA "
    },
    {
      "Location ID": "64085",
      "Location Name": "RICHMOND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 S COLLEGE ST RICHMOND MO 64085 USA "
    },
    {
      "Location ID": "64088",
      "Location Name": "SIBLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 CHICAGO AVE SIBLEY MO 64088 USA "
    },
    {
      "Location ID": "64089",
      "Location Name": "SMITHVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 RICHARDSON ST SMITHVILLE MO 64089 USA "
    },
    {
      "Location ID": "64096",
      "Location Name": "WAVERLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 E KELLING AVE WAVERLY MO 64096 USA "
    },
    {
      "Location ID": "64097",
      "Location Name": "WELLINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 E HIGHWAY 224 WELLINGTON MO 64097 USA "
    },
    {
      "Location ID": "64098",
      "Location Name": "WESTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 18205 LIBRARY DR WESTON MO 64098 USA "
    },
    {
      "Location ID": "640AX",
      "Location Name": "KANSAS CITY PEAK ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 31450 W 196TH ST STE A EDGERTON KS 66021 USA "
    },
    {
      "Location ID": "640FZ",
      "Location Name": "LOG KANSAS CITY MO PSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4151 NW 41ST ST RIVERSIDE MO 64150 USA "
    },
    {
      "Location ID": "64151",
      "Location Name": "BARRY WOODS ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8320 N PLATTE PURCHASE DR KANSAS CITY MO 64118 USA "
    },
    {
      "Location ID": "641OT",
      "Location Name": "BROADRIDGE MAILING SERV KC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2600 SOUTHWEST BLVD KANSAS CITY MO 64108 USA "
    },
    {
      "Location ID": "641UP",
      "Location Name": "UPS MAIL INNOVATIONS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1010 CENTURY DR KANSAS CITY MO 64120 USA "
    },
    {
      "Location ID": "644",
      "Location Name": "SAINT JOSEPH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 S 8TH ST SAINT JOSEPH MO 64501 USA "
    },
    {
      "Location ID": "64402",
      "Location Name": "ALBANY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E CLAY ST ALBANY MO 64402 USA "
    },
    {
      "Location ID": "64421",
      "Location Name": "AMAZONIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 142 STATE ROUTE K AMAZONIA MO 64421 USA "
    },
    {
      "Location ID": "64424",
      "Location Name": "BETHANY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1407 CENTRAL ST BETHANY MO 64424 USA "
    },
    {
      "Location ID": "64426",
      "Location Name": "BLYTHEDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 629 STATE HWY N BLYTHEDALE MO 64426 USA "
    },
    {
      "Location ID": "64427",
      "Location Name": "BOLCKOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 RICHARDSON ST BOLCKOW MO 64427 USA "
    },
    {
      "Location ID": "64429",
      "Location Name": "CAMERON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 E GRAND AVE CAMERON MO 64429 USA "
    },
    {
      "Location ID": "64431",
      "Location Name": "CLEARMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 2ND ST CLEARMONT MO 64431 USA "
    },
    {
      "Location ID": "64437",
      "Location Name": "CRAIG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 S MAIN ST CRAIG MO 64437 USA "
    },
    {
      "Location ID": "64442",
      "Location Name": "EAGLEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14042 14TH ST EAGLEVILLE MO 64442 USA "
    },
    {
      "Location ID": "64443",
      "Location Name": "EASTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 N BUCKLIN DR EASTON MO 64443 USA "
    },
    {
      "Location ID": "64445",
      "Location Name": "ELMO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 W MAIN ST ELMO MO 64445 USA "
    },
    {
      "Location ID": "64446",
      "Location Name": "FAIRFAX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 N DENNY ST FAIRFAX MO 64446 USA "
    },
    {
      "Location ID": "64449",
      "Location Name": "FILLMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 126 E MAIN ST FILLMORE MO 64449 USA "
    },
    {
      "Location ID": "64451",
      "Location Name": "FOREST CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 GRAND AVE FOREST CITY MO 64451 USA "
    },
    {
      "Location ID": "64454",
      "Location Name": "GOWER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 99 N 3RD ST GOWER MO 64454 USA "
    },
    {
      "Location ID": "64461",
      "Location Name": "HOPKINS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 E BARNARD ST HOPKINS MO 64461 USA "
    },
    {
      "Location ID": "64463",
      "Location Name": "KING CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 E VERMONT ST KING CITY MO 64463 USA "
    },
    {
      "Location ID": "64468",
      "Location Name": "MARYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 507 N FILLMORE ST MARYVILLE MO 64468 USA "
    },
    {
      "Location ID": "64470",
      "Location Name": "MOUND CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 W 6TH ST MOUND CITY MO 64470 USA "
    },
    {
      "Location ID": "64473",
      "Location Name": "OREGON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 S JEFFERSON ST OREGON MO 64473 USA "
    },
    {
      "Location ID": "64474",
      "Location Name": "OSBORN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 600 CLINTON AVE OSBORN MO 64474 USA "
    },
    {
      "Location ID": "64476",
      "Location Name": "PICKERING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 411 E 5TH ST PICKERING MO 64476 USA "
    },
    {
      "Location ID": "64477",
      "Location Name": "PLATTSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 S MAIN ST PLATTSBURG MO 64477 USA "
    },
    {
      "Location ID": "64480",
      "Location Name": "REA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 218 BYERS ST REA MO 64480 USA "
    },
    {
      "Location ID": "64481",
      "Location Name": "RIDGEWAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 402 PINE ST RIDGEWAY MO 64481 USA "
    },
    {
      "Location ID": "64482",
      "Location Name": "ROCK PORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 W OPP ST ROCK PORT MO 64482 USA "
    },
    {
      "Location ID": "64483",
      "Location Name": "ROSENDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11501 HIGHWAY 48 ROSENDALE MO 64483 USA "
    },
    {
      "Location ID": "64485",
      "Location Name": "SAVANNAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 507 W MAIN ST SAVANNAH MO 64485 USA "
    },
    {
      "Location ID": "64489",
      "Location Name": "STANBERRY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 N PARK ST STANBERRY MO 64489 USA "
    },
    {
      "Location ID": "64490",
      "Location Name": "STEWARTSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 406 MAIN ST STEWARTSVILLE MO 64490 USA "
    },
    {
      "Location ID": "64491",
      "Location Name": "TARKIO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 505 MAIN ST TARKIO MO 64491 USA "
    },
    {
      "Location ID": "64498",
      "Location Name": "WESTBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 E MAIN ST WESTBORO MO 64498 USA "
    },
    {
      "Location ID": "64501",
      "Location Name": "SAINT JOSEPH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 S 8TH ST SAINT JOSEPH MO 64501 USA "
    },
    {
      "Location ID": "646",
      "Location Name": "CHILLICOTHE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 S WASHINGTON ST CHILLICOTHE MO 64601 USA "
    },
    {
      "Location ID": "64620",
      "Location Name": "ALTAMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 MAIN ST ALTAMONT MO 64620 USA "
    },
    {
      "Location ID": "64622",
      "Location Name": "BOGARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 W 3RD ST BOGARD MO 64622 USA "
    },
    {
      "Location ID": "64623",
      "Location Name": "BOSWORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 N KANSAS AVE BOSWORTH MO 64623 USA "
    },
    {
      "Location ID": "64624",
      "Location Name": "BRAYMER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 E 2ND ST BRAYMER MO 64624 USA "
    },
    {
      "Location ID": "64625",
      "Location Name": "BRECKENRIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 165 S 7TH ST BRECKENRIDGE MO 64625 USA "
    },
    {
      "Location ID": "64628",
      "Location Name": "BROOKFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W BOSTON ST BROOKFIELD MO 64628 USA "
    },
    {
      "Location ID": "64631",
      "Location Name": "BUCKLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 31 N LIVINGSTON ST BUCKLIN MO 64631 USA "
    },
    {
      "Location ID": "64633",
      "Location Name": "CARROLLTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N FOLGER ST CARROLLTON MO 64633 USA "
    },
    {
      "Location ID": "64635",
      "Location Name": "CHULA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 640 MANSUR ST CHULA MO 64635 USA "
    },
    {
      "Location ID": "64636",
      "Location Name": "COFFEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 E STATE HIGHWAY B COFFEY MO 64636 USA "
    },
    {
      "Location ID": "64637",
      "Location Name": "COWGILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 460 MAIN ST COWGILL MO 64637 USA "
    },
    {
      "Location ID": "64638",
      "Location Name": "DAWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 MAIN ST DAWN MO 64638 USA "
    },
    {
      "Location ID": "64640",
      "Location Name": "GALLATIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 S MAIN ST GALLATIN MO 64640 USA "
    },
    {
      "Location ID": "64641",
      "Location Name": "GALT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 CENTER ST GALT MO 64641 USA "
    },
    {
      "Location ID": "64643",
      "Location Name": "HALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 MAIN ST HALE MO 64643 USA "
    },
    {
      "Location ID": "64644",
      "Location Name": "HAMILTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 W BIRD ST HAMILTON MO 64644 USA "
    },
    {
      "Location ID": "64645",
      "Location Name": "HARRIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 315 MAIN ST HARRIS MO 64645 USA "
    },
    {
      "Location ID": "64647",
      "Location Name": "JAMESON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 N MAIN ST JAMESON MO 64647 USA "
    },
    {
      "Location ID": "64648",
      "Location Name": "JAMESPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 S BROADWAY ST JAMESPORT MO 64648 USA "
    },
    {
      "Location ID": "64649",
      "Location Name": "KIDDER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 315 E 3RD ST KIDDER MO 64649 USA "
    },
    {
      "Location ID": "64650",
      "Location Name": "KINGSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 36 N FRANKLIN ST KINGSTON MO 64650 USA "
    },
    {
      "Location ID": "64652",
      "Location Name": "LAREDO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 216 E MAIN ST LAREDO MO 64652 USA "
    },
    {
      "Location ID": "64655",
      "Location Name": "LUCERNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 STATE HIGHWAY A E LUCERNE MO 64655 USA "
    },
    {
      "Location ID": "64656",
      "Location Name": "LUDLOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 MAIN ST LUDLOW MO 64656 USA "
    },
    {
      "Location ID": "64657",
      "Location Name": "MC FALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 508 MAIN ST MC FALL MO 64657 USA "
    },
    {
      "Location ID": "64658",
      "Location Name": "MARCELINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 E RITCHIE AVE MARCELINE MO 64658 USA "
    },
    {
      "Location ID": "64659",
      "Location Name": "MEADVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 S MACON ST MEADVILLE MO 64659 USA "
    },
    {
      "Location ID": "64660",
      "Location Name": "MENDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 MAIN ST MENDON MO 64660 USA "
    },
    {
      "Location ID": "64661",
      "Location Name": "MERCER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N STATE ST MERCER MO 64661 USA "
    },
    {
      "Location ID": "64667",
      "Location Name": "NEWTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 S MAIN ST NEWTOWN MO 64667 USA "
    },
    {
      "Location ID": "64668",
      "Location Name": "NORBORNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 E 2ND ST NORBORNE MO 64668 USA "
    },
    {
      "Location ID": "64670",
      "Location Name": "PATTONSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 2ND AVE PATTONSBURG MO 64670 USA "
    },
    {
      "Location ID": "64671",
      "Location Name": "POLO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 W ELM ST POLO MO 64671 USA "
    },
    {
      "Location ID": "64672",
      "Location Name": "POWERSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 MAIN ST POWERSVILLE MO 64672 USA "
    },
    {
      "Location ID": "64673",
      "Location Name": "PRINCETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 S BROADWAY ST PRINCETON MO 64673 USA "
    },
    {
      "Location ID": "64676",
      "Location Name": "ROTHVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 MAIN ST ROTHVILLE MO 64676 USA "
    },
    {
      "Location ID": "64679",
      "Location Name": "SPICKARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 N 2ND ST SPICKARD MO 64679 USA "
    },
    {
      "Location ID": "64681",
      "Location Name": "SUMNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 W CENTRE ST SUMNER MO 64681 USA "
    },
    {
      "Location ID": "64682",
      "Location Name": "TINA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 N MAIN ST TINA MO 64682 USA "
    },
    {
      "Location ID": "64683",
      "Location Name": "TRENTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W 11TH ST TRENTON MO 64683 USA "
    },
    {
      "Location ID": "64686",
      "Location Name": "UTICA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 509 W THROOP ST UTICA MO 64686 USA "
    },
    {
      "Location ID": "64688",
      "Location Name": "WHEELING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 N GRANT ST WHEELING MO 64688 USA "
    },
    {
      "Location ID": "64689",
      "Location Name": "WINSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 W 2ND ST WINSTON MO 64689 USA "
    },
    {
      "Location ID": "646WP",
      "Location Name": "WALSWORTH PUBLISHING COMPANY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 803 S MISSOURI AVE MARCELINE MO 64658 USA "
    },
    {
      "Location ID": "64722",
      "Location Name": "AMORET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 STATE ROUTE 52 AMORET MO 64722 USA "
    },
    {
      "Location ID": "64723",
      "Location Name": "AMSTERDAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13151 NW MAIN ST AMSTERDAM MO 64723 USA "
    },
    {
      "Location ID": "64726",
      "Location Name": "BLAIRSTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 N MAIN ST BLAIRSTOWN MO 64726 USA "
    },
    {
      "Location ID": "64733",
      "Location Name": "CHILHOWEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 E WALNUT ST CHILHOWEE MO 64733 USA "
    },
    {
      "Location ID": "64734",
      "Location Name": "CLEVELAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 MAIN ST CLEVELAND MO 64734 USA "
    },
    {
      "Location ID": "64742",
      "Location Name": "DREXEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 S 2ND ST DREXEL MO 64742 USA "
    },
    {
      "Location ID": "64743",
      "Location Name": "EAST LYNNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 415 MAIN ST EAST LYNNE MO 64743 USA "
    },
    {
      "Location ID": "64745",
      "Location Name": "FOSTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 MAIN ST FOSTER MO 64745 USA "
    },
    {
      "Location ID": "64746",
      "Location Name": "FREEMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 E MAIN ST FREEMAN MO 64746-9998 USA "
    },
    {
      "Location ID": "64748",
      "Location Name": "GOLDEN CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 519 MAIN ST GOLDEN CITY MO 64748 USA "
    },
    {
      "Location ID": "64752",
      "Location Name": "HUME",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 MAIN ST HUME MO 64752 USA "
    },
    {
      "Location ID": "64755",
      "Location Name": "JASPER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 122 W GRAND ST JASPER MO 64755 USA "
    },
    {
      "Location ID": "64759",
      "Location Name": "LAMAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 131 W 11TH ST LAMAR MO 64759 USA "
    },
    {
      "Location ID": "64761",
      "Location Name": "LEETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 N MAIN ST LEETON MO 64761 USA "
    },
    {
      "Location ID": "64765",
      "Location Name": "METZ",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 S MAIN ST METZ MO 64765 USA "
    },
    {
      "Location ID": "64778",
      "Location Name": "RICHARDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12309 S MAIN ST RICHARDS MO 64778 USA "
    },
    {
      "Location ID": "64779",
      "Location Name": "RICH HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 N 6TH ST RICH HILL MO 64779 USA "
    },
    {
      "Location ID": "648",
      "Location Name": "JOPLIN MO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N. MAIN STREET JOPLIN MO 64801 USA "
    },
    {
      "Location ID": "64801",
      "Location Name": "JOPLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N MAIN ST JOPLIN MO 64801 USA "
    },
    {
      "Location ID": "64804",
      "Location Name": "JOPLIN STA A",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3115 S MAIN ST JOPLIN MO 64804 USA "
    },
    {
      "Location ID": "64830",
      "Location Name": "ALBA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 N MAIN ST ALBA MO 64830 USA "
    },
    {
      "Location ID": "64831",
      "Location Name": "ANDERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 E MAIN ST ANDERSON MO 64831 USA "
    },
    {
      "Location ID": "64834",
      "Location Name": "CARL JUNCTION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 S MAIN ST CARL JUNCTION MO 64834 USA "
    },
    {
      "Location ID": "64835",
      "Location Name": "CARTERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 E MAIN ST CARTERVILLE MO 64835 USA "
    },
    {
      "Location ID": "64836",
      "Location Name": "CARTHAGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 226 W 3RD ST CARTHAGE MO 64836 USA "
    },
    {
      "Location ID": "64840",
      "Location Name": "DIAMOND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 E MARKET ST DIAMOND MO 64840 USA "
    },
    {
      "Location ID": "64843",
      "Location Name": "GOODMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 W SPLITLOG ST GOODMAN MO 64843 USA "
    },
    {
      "Location ID": "64844",
      "Location Name": "GRANBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 126 S MAIN ST GRANBY MO 64844 USA "
    },
    {
      "Location ID": "64849",
      "Location Name": "NECK CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 GRAND AVE NECK CITY MO 64849 USA "
    },
    {
      "Location ID": "64850",
      "Location Name": "NEOSHO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E HICKORY ST NEOSHO MO 64850 USA "
    },
    {
      "Location ID": "64854",
      "Location Name": "NOEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 318 MAIN ST NOEL MO 64854 USA "
    },
    {
      "Location ID": "64855",
      "Location Name": "ORONOGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S 2ND ST ORONOGO MO 64855 USA "
    },
    {
      "Location ID": "64856",
      "Location Name": "PINEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 304 HARMON ST PINEVILLE MO 64856 USA "
    },
    {
      "Location ID": "64857",
      "Location Name": "PURCELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 W 4TH ST PURCELL MO 64857 USA "
    },
    {
      "Location ID": "64858",
      "Location Name": "RACINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11945 W HIGHWAY 86 RACINE MO 64858 USA "
    },
    {
      "Location ID": "64863",
      "Location Name": "SOUTH WEST CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 S MAIN ST SOUTH WEST CITY MO 64863 USA "
    },
    {
      "Location ID": "64865",
      "Location Name": "SENECA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1205 CHEROKEE AVE SENECA MO 64865 USA "
    },
    {
      "Location ID": "64868",
      "Location Name": "TIFF CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10703 W STATE HIGHWAY 76 TIFF CITY MO 64868 USA "
    },
    {
      "Location ID": "64870",
      "Location Name": "WEBB CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 W DAUGHERTY ST WEBB CITY MO 64870 USA "
    },
    {
      "Location ID": "648MD",
      "Location Name": "MASS DEPOT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4500 DONIPHAN DR NEOSHO MO 64850 USA "
    },
    {
      "Location ID": "648SB",
      "Location Name": "SUNBEAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 NELSON AVE NEOSHO MO 64850 USA "
    },
    {
      "Location ID": "648ST",
      "Location Name": "4 STATES TRUCKS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4579 HIGHWAY 43 JOPLIN MO 64804 USA "
    },
    {
      "Location ID": "650",
      "Location Name": "LOG MID MISSOURI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11450 S AIRPORT DR COLUMBIA MO 65299 USA "
    },
    {
      "Location ID": "65001",
      "Location Name": "ARGYLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3 2ND ST ARGYLE MO 65001 USA "
    },
    {
      "Location ID": "65010",
      "Location Name": "ASHLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 131 E BROADWAY ASHLAND MO 65010 USA "
    },
    {
      "Location ID": "65011",
      "Location Name": "BARNETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 409 2ND ST BARNETT MO 65011 USA "
    },
    {
      "Location ID": "65017",
      "Location Name": "BRUMLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 859 HIGHWAY C BRUMLEY MO 65017 USA "
    },
    {
      "Location ID": "65018",
      "Location Name": "CALIFORNIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 N HIGH ST CALIFORNIA MO 65018 USA "
    },
    {
      "Location ID": "65020",
      "Location Name": "CAMDENTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 625 W US HIGHWAY 54 CAMDENTON MO 65020 USA "
    },
    {
      "Location ID": "65023",
      "Location Name": "CENTERTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11917 MAIN ST CENTERTOWN MO 65023 USA "
    },
    {
      "Location ID": "65025",
      "Location Name": "CLARKSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 W STATE ST CLARKSBURG MO 65025 USA "
    },
    {
      "Location ID": "65026",
      "Location Name": "ELDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 E 1ST ST ELDON MO 65026 USA "
    },
    {
      "Location ID": "65032",
      "Location Name": "EUGENE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16106 BERRY ST EUGENE MO 65032 USA "
    },
    {
      "Location ID": "65034",
      "Location Name": "FORTUNA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 72456 WEBER ST FORTUNA MO 65034 USA "
    },
    {
      "Location ID": "65035",
      "Location Name": "FREEBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 HARRISON ST FREEBURG MO 65035 USA "
    },
    {
      "Location ID": "65037",
      "Location Name": "GRAVOIS MILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 25707 HIGHWAY 5 GRAVOIS MILLS MO 65037 USA "
    },
    {
      "Location ID": "65040",
      "Location Name": "HENLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16503 PIT RD HENLEY MO 65040 USA "
    },
    {
      "Location ID": "65041",
      "Location Name": "HERMANN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 412 W 16TH ST HERMANN MO 65041 USA "
    },
    {
      "Location ID": "65043",
      "Location Name": "HOLTS SUMMIT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 357 S SUMMIT DR HOLTS SUMMIT MO 65043 USA "
    },
    {
      "Location ID": "65047",
      "Location Name": "KAISER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 405 HIGHWAY 42 KAISER MO 65047 USA "
    },
    {
      "Location ID": "65049",
      "Location Name": "LAKE OZARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2111 BAGNELL DAM BLVD LAKE OZARK MO 65049 USA "
    },
    {
      "Location ID": "65052",
      "Location Name": "LINN CREEK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 199 BUSINESS PARK RD LINN CREEK MO 65052 USA "
    },
    {
      "Location ID": "65053",
      "Location Name": "LOHMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2922 MAIN ST LOHMAN MO 65053 USA "
    },
    {
      "Location ID": "65055",
      "Location Name": "MC GIRK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 31461 EAST ST MC GIRK MO 65055 USA "
    },
    {
      "Location ID": "65058",
      "Location Name": "META",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 3RD ST META MO 65058 USA "
    },
    {
      "Location ID": "65059",
      "Location Name": "MOKANE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 FULTON AVE MOKANE MO 65059 USA "
    },
    {
      "Location ID": "65063",
      "Location Name": "NEW BLOOMFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 CHESTNUT ST NEW BLOOMFIELD MO 65063 USA "
    },
    {
      "Location ID": "65064",
      "Location Name": "OLEAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 252 MAIN ST OLEAN MO 65064 USA "
    },
    {
      "Location ID": "65065",
      "Location Name": "OSAGE BEACH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5545 OSAGE BEACH PKWY OSAGE BEACH MO 65065 USA "
    },
    {
      "Location ID": "65067",
      "Location Name": "PORTLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10452 STATE ROUTE 94 PORTLAND MO 65067 USA "
    },
    {
      "Location ID": "65069",
      "Location Name": "RHINELAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 BLUFF ST RHINELAND MO 65069 USA "
    },
    {
      "Location ID": "65074",
      "Location Name": "RUSSELLVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5103 SIMPSON ST RUSSELLVILLE MO 65074 USA "
    },
    {
      "Location ID": "65075",
      "Location Name": "SAINT ELIZABETH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 140 S WALNUT ST SAINT ELIZABETH MO 65075 USA "
    },
    {
      "Location ID": "65076",
      "Location Name": "SAINT THOMAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14807 ROUTE B SAINT THOMAS MO 65076 USA "
    },
    {
      "Location ID": "65077",
      "Location Name": "STEEDMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9635 STATE ROAD CC STEEDMAN MO 65077 USA "
    },
    {
      "Location ID": "65078",
      "Location Name": "STOVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 W 2ND ST STOVER MO 65078 USA "
    },
    {
      "Location ID": "65079",
      "Location Name": "SUNRISE BEACH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14190 N STATE HIGHWAY 5 SUNRISE BEACH MO 65079 USA "
    },
    {
      "Location ID": "65080",
      "Location Name": "TEBBETTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5599 COUNTY ROAD 4011 TEBBETTS MO 65080 USA "
    },
    {
      "Location ID": "65081",
      "Location Name": "TIPTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 235 S OSAGE AVE TIPTON MO 65081 USA "
    },
    {
      "Location ID": "65082",
      "Location Name": "TUSCUMBIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1972 HIGHWAY 52 TUSCUMBIA MO 65082 USA "
    },
    {
      "Location ID": "65083",
      "Location Name": "ULMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 357 HIGHWAY C ULMAN MO 65083 USA "
    },
    {
      "Location ID": "65084",
      "Location Name": "VERSAILLES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 S MONROE ST VERSAILLES MO 65084 USA "
    },
    {
      "Location ID": "65085",
      "Location Name": "WESTPHALIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 126 E MAIN ST WESTPHALIA MO 65085 USA "
    },
    {
      "Location ID": "650DP",
      "Location Name": "DIESEL POWER PLUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 31716 OLD EIGHTY SEVEN STE A CALIFORNIA MO 65018 USA "
    },
    {
      "Location ID": "650GV",
      "Location Name": "GVS - GOODS VARIETY STORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13835 HIGHWAY 52 VERSAILLES MO 65084 USA "
    },
    {
      "Location ID": "65101",
      "Location Name": "JEFFERSON CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 131 W HIGH ST RM 104 JEFFERSON CITY MO 65101 USA "
    },
    {
      "Location ID": "651ML",
      "Location Name": "MODERN LITHO-PRINT INC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6009 STERTZER RD JEFFERSON CTY MO 65101 USA "
    },
    {
      "Location ID": "65201",
      "Location Name": "COLUMBIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 511 E WALNUT ST COLUMBIA MO 65201 USA "
    },
    {
      "Location ID": "65203",
      "Location Name": "COLUMBIA MALL STA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2300 BERNADETTE DR STE 200 COLUMBIA MO 65203 USA "
    },
    {
      "Location ID": "65230",
      "Location Name": "ARMSTRONG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 S CENTRAL ST ARMSTRONG MO 65230 USA "
    },
    {
      "Location ID": "65231",
      "Location Name": "AUXVASSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 S MAIN ST AUXVASSE MO 65231 USA "
    },
    {
      "Location ID": "65232",
      "Location Name": "BENTON CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 FRONT ST BENTON CITY MO 65232 USA "
    },
    {
      "Location ID": "65233",
      "Location Name": "BOONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 526 E SPRING ST BOONVILLE MO 65233 USA "
    },
    {
      "Location ID": "65236",
      "Location Name": "BRUNSWICK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 W BROADWAY ST BRUNSWICK MO 65236 USA "
    },
    {
      "Location ID": "65240",
      "Location Name": "CENTRALIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 W SNEED ST CENTRALIA MO 65240 USA "
    },
    {
      "Location ID": "65243",
      "Location Name": "CLARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 HUBBARD ST CLARK MO 65243 USA "
    },
    {
      "Location ID": "65244",
      "Location Name": "CLIFTON HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 639 HARLAN ST CLIFTON HILL MO 65244 USA "
    },
    {
      "Location ID": "65248",
      "Location Name": "FAYETTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 W MORRISON ST FAYETTE MO 65248 USA "
    },
    {
      "Location ID": "65250",
      "Location Name": "FRANKLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 2ND ST FRANKLIN MO 65250 USA "
    },
    {
      "Location ID": "65251",
      "Location Name": "FULTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 COURT ST FULTON MO 65251 USA "
    },
    {
      "Location ID": "65254",
      "Location Name": "GLASGOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 404 1ST ST GLASGOW MO 65254 USA "
    },
    {
      "Location ID": "65255",
      "Location Name": "HALLSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 319 HWY 124 E HALLSVILLE MO 65255 USA "
    },
    {
      "Location ID": "65259",
      "Location Name": "HUNTSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 S MAIN ST HUNTSVILLE MO 65259 USA "
    },
    {
      "Location ID": "65261",
      "Location Name": "KEYTESVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 418 W BRIDGE ST KEYTESVILLE MO 65261 USA "
    },
    {
      "Location ID": "65262",
      "Location Name": "KINGDOM CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5500 OLD US HIGHWAY 40 KINGDOM CITY MO 65262 USA "
    },
    {
      "Location ID": "65264",
      "Location Name": "MARTINSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 E JEFFERSON ST MARTINSBURG MO 65264 USA "
    },
    {
      "Location ID": "65265",
      "Location Name": "MEXICO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 420 W JACKSON ST MEXICO MO 65265 USA "
    },
    {
      "Location ID": "65270",
      "Location Name": "MOBERLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 JOHNSON ST MOBERLY MO 65270 USA "
    },
    {
      "Location ID": "65274",
      "Location Name": "NEW FRANKLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 128 E BROADWAY NEW FRANKLIN MO 65274 USA "
    },
    {
      "Location ID": "65278",
      "Location Name": "RENICK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 ROBINSON ST RENICK MO 65278 USA "
    },
    {
      "Location ID": "65279",
      "Location Name": "ROCHEPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 CENTRAL ST ROCHEPORT MO 65279 USA "
    },
    {
      "Location ID": "65280",
      "Location Name": "RUSH HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 N CENTER ST RUSH HILL MO 65280 USA "
    },
    {
      "Location ID": "65281",
      "Location Name": "SALISBURY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 E 4TH ST SALISBURY MO 65281 USA "
    },
    {
      "Location ID": "65284",
      "Location Name": "STURGEON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 N WENTZ ST STURGEON MO 65284 USA "
    },
    {
      "Location ID": "65285",
      "Location Name": "THOMPSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10339 AUDRAIN ROAD 945 THOMPSON MO 65285 USA "
    },
    {
      "Location ID": "652AA",
      "Location Name": "TIGER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3212B LEMONE INDUSTRIAL BLVD COLUMBIA MO 65201 USA "
    },
    {
      "Location ID": "652AG",
      "Location Name": "AEROGROW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2201 LAKEVIEW RD MEXICO MO 65265 USA "
    },
    {
      "Location ID": "652CS",
      "Location Name": "COMMERCE STA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3709 SANDMAN LN COLUMBIA MO 65201 USA "
    },
    {
      "Location ID": "652GO",
      "Location Name": "GOLD CREST DISTRIBUTING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1600 BASSFORD DR MEXICO MO 65265 USA "
    },
    {
      "Location ID": "652GS",
      "Location Name": "GRAF AND SONS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4050 S CLARK ST MEXICO MO 65265 USA "
    },
    {
      "Location ID": "652HD",
      "Location Name": "HOME DECORATIONS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4675 E LIBERTY MEXICO MO 65265 USA "
    },
    {
      "Location ID": "652OB",
      "Location Name": "OVID BELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1201 BLUFF ST FULTON MO 65251 USA "
    },
    {
      "Location ID": "652SS",
      "Location Name": "SOFT SURROUNDINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4655 E LIBERTY ST MEXICO MO 65265 USA "
    },
    {
      "Location ID": "653",
      "Location Name": "GUANICA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 39 CALLE 13 DE MARZO STE 101 GUANICA PR 65399 USA "
    },
    {
      "Location ID": "65301",
      "Location Name": "SEDALIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 405 E 5TH ST SEDALIA MO 65301 USA "
    },
    {
      "Location ID": "65305",
      "Location Name": "WHITEMAN AIR FORCE BASE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 740 ARNOLD AVE STE 1A WHITEMAN AIR FORCE BASE MO 65305 USA "
    },
    {
      "Location ID": "65320",
      "Location Name": "ARROW ROCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 304 MAIN ST ARROW ROCK MO 65320 USA "
    },
    {
      "Location ID": "65322",
      "Location Name": "BLACKWATER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 MAIN ST BLACKWATER MO 65322 USA "
    },
    {
      "Location ID": "65323",
      "Location Name": "CALHOUN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 E 5TH ST CALHOUN MO 65323 USA "
    },
    {
      "Location ID": "65332",
      "Location Name": "GREEN RIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 N MAIN ST GREEN RIDGE MO 65332 USA "
    },
    {
      "Location ID": "65336",
      "Location Name": "KNOB NOSTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 505 W MCPHERSON ST KNOB NOSTER MO 65336 USA "
    },
    {
      "Location ID": "65337",
      "Location Name": "LA MONTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 S MAIN ST LA MONTE MO 65337 USA "
    },
    {
      "Location ID": "65340",
      "Location Name": "MARSHALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 N LAFAYETTE AVE MARSHALL MO 65340 USA "
    },
    {
      "Location ID": "65348",
      "Location Name": "OTTERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 E GROVER ST OTTERVILLE MO 65348 USA "
    },
    {
      "Location ID": "65349",
      "Location Name": "SLATER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 159 W MAPLE ST SLATER MO 65349 USA "
    },
    {
      "Location ID": "65350",
      "Location Name": "SMITHTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 S WALNUT AVE SMITHTON MO 65350 USA "
    },
    {
      "Location ID": "65354",
      "Location Name": "SYRACUSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 E FRONT ST SYRACUSE MO 65354 USA "
    },
    {
      "Location ID": "65360",
      "Location Name": "WINDSOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 N MAIN ST WINDSOR MO 65360 USA "
    },
    {
      "Location ID": "654",
      "Location Name": "ROLLA MO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 W. 8TH ST ROLLA MO 65401 USA "
    },
    {
      "Location ID": "65401",
      "Location Name": "ROLLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 W 8TH ST ROLLA MO 65401 USA "
    },
    {
      "Location ID": "65438",
      "Location Name": "BIRCH TREE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 1ST ST BIRCH TREE MO 65438 USA "
    },
    {
      "Location ID": "65439",
      "Location Name": "BIXBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2573 STATE ROUTE 32 BIXBY MO 65439 USA "
    },
    {
      "Location ID": "65440",
      "Location Name": "BOSS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 22333 E HIGHWAY 32 BOSS MO 65440 USA "
    },
    {
      "Location ID": "65446",
      "Location Name": "CHERRYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20376 HIGHWAY 19 CHERRYVILLE MO 65446 USA "
    },
    {
      "Location ID": "65452",
      "Location Name": "CROCKER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 10TH ST CROCKER MO 65452 USA "
    },
    {
      "Location ID": "65453",
      "Location Name": "CUBA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 703 W WASHINGTON ST CUBA MO 65453 USA "
    },
    {
      "Location ID": "65459",
      "Location Name": "DIXON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 W 2ND ST DIXON MO 65459 USA "
    },
    {
      "Location ID": "65466",
      "Location Name": "EMINENCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 18253 MAIN ST EMINENCE MO 65466 USA "
    },
    {
      "Location ID": "65473",
      "Location Name": "FORT LEONARD WOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 233 ILLINOIS AVE STE 1 FORT LEONARD WOOD MO 65473 USA "
    },
    {
      "Location ID": "65483",
      "Location Name": "HOUSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 N 1ST ST HOUSTON MO 65483 USA "
    },
    {
      "Location ID": "65536",
      "Location Name": "LEBANON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 E COMMERCIAL ST LEBANON MO 65536 USA "
    },
    {
      "Location ID": "65542",
      "Location Name": "LICKING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 133 S MAIN ST LICKING MO 65542 USA "
    },
    {
      "Location ID": "65548",
      "Location Name": "MOUNTAIN VIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 W 2ND ST MOUNTAIN VIEW MO 65548 USA "
    },
    {
      "Location ID": "65556",
      "Location Name": "RICHLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 W MCCLURG AVE RICHLAND MO 65556 USA "
    },
    {
      "Location ID": "65559",
      "Location Name": "SAINT JAMES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 N BOURBEUSE ST SAINT JAMES MO 65559 USA "
    },
    {
      "Location ID": "65560",
      "Location Name": "SALEM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 N JACKSON ST SALEM MO 65560 USA "
    },
    {
      "Location ID": "65565",
      "Location Name": "STEELVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 S 1ST ST STEELVILLE MO 65565 USA "
    },
    {
      "Location ID": "65566",
      "Location Name": "VIBURNUM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10 VIBURNUM CENTER RD VIBURNUM MO 65566 USA "
    },
    {
      "Location ID": "65567",
      "Location Name": "STOUTLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 40 E STATE ROAD H STOUTLAND MO 65567 USA "
    },
    {
      "Location ID": "65583",
      "Location Name": "WAYNESVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 NORTH ST WAYNESVILLE MO 65583 USA "
    },
    {
      "Location ID": "65584",
      "Location Name": "SAINT ROBERT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 194 EASTLAWN AVE STE B SAINT ROBERT MO 65584 USA "
    },
    {
      "Location ID": "65588",
      "Location Name": "WINONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 E 1ST ST WINONA MO 65588 USA "
    },
    {
      "Location ID": "65590",
      "Location Name": "LONG LANE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1668 STATE HIGHWAY 32 LONG LANE MO 65590 USA "
    },
    {
      "Location ID": "655AL",
      "Location Name": "ART LIGHTING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10 INDUSTRIAL RD RICHLAND MO 65556 USA "
    },
    {
      "Location ID": "655AX",
      "Location Name": "LEBANON CARRIER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 701 E COPELAND ST LEBANON MO 65536 USA "
    },
    {
      "Location ID": "656",
      "Location Name": "SPRINGFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 W CHESTNUT EXPY SPRINGFIELD MO 65801 USA "
    },
    {
      "Location ID": "65604",
      "Location Name": "ASH GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 N TOWER ST ASH GROVE MO 65604 USA "
    },
    {
      "Location ID": "65605",
      "Location Name": "AURORA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 E OLIVE ST AURORA MO 65605 USA "
    },
    {
      "Location ID": "65606",
      "Location Name": "ALTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 198 MAIN ST ALTON MO 65606 USA "
    },
    {
      "Location ID": "65608",
      "Location Name": "AVA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 499 W WASHINGTON AVE AVA MO 65608 USA "
    },
    {
      "Location ID": "65610",
      "Location Name": "BILLINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 NE US HIGHWAY 60 BILLINGS MO 65610 USA "
    },
    {
      "Location ID": "65611",
      "Location Name": "BLUE EYE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 STATE HIGHWAY 13 BLUE EYE MO 65611 USA "
    },
    {
      "Location ID": "65612",
      "Location Name": "BOIS D ARC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10507 W STATE HIGHWAY T BOIS D ARC MO 65612 USA "
    },
    {
      "Location ID": "65613",
      "Location Name": "BOLIVAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 328 S MAIN AVE BOLIVAR MO 65613 USA "
    },
    {
      "Location ID": "65614",
      "Location Name": "BRADLEYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 25856 STATE HIGHWAY 76 BRADLEYVILLE MO 65614 USA "
    },
    {
      "Location ID": "65615",
      "Location Name": "BRANSON MO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 320 SOUTH COMMERCIAL ST BRANSON MO 65615 USA "
    },
    {
      "Location ID": "65616",
      "Location Name": "BRANSON ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 141 INDUSTRIAL PARK DR STE E HOLLISTER MO 65672 USA "
    },
    {
      "Location ID": "65619",
      "Location Name": "BROOKLINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2874 N BROOKLINE AVE BROOKLINE MO 65619 USA "
    },
    {
      "Location ID": "65622",
      "Location Name": "BUFFALO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 S PINE ST BUFFALO MO 65622 USA "
    },
    {
      "Location ID": "65624",
      "Location Name": "CAPE FAIR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8089 W STATE HIGHWAY 76 UNIT D CAPE FAIR MO 65624 USA "
    },
    {
      "Location ID": "65625",
      "Location Name": "CASSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 800 WEST ST CASSVILLE MO 65625 USA "
    },
    {
      "Location ID": "65631",
      "Location Name": "CLEVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 CLARKE AVE CLEVER MO 65631 USA "
    },
    {
      "Location ID": "65632",
      "Location Name": "CONWAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 601 W JEFFERSON AVE STE D CONWAY MO 65632 USA "
    },
    {
      "Location ID": "65633",
      "Location Name": "CRANE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 MAIN ST CRANE MO 65633 USA "
    },
    {
      "Location ID": "65634",
      "Location Name": "CROSS TIMBERS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12808 PARK ST CROSS TIMBERS MO 65634 USA "
    },
    {
      "Location ID": "65635",
      "Location Name": "DADEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 E MAIN ST STE 103 DADEVILLE MO 65635 USA "
    },
    {
      "Location ID": "65636",
      "Location Name": "DIGGINS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 505 PLAZA DR DIGGINS MO 65636 USA "
    },
    {
      "Location ID": "65640",
      "Location Name": "DUNNEGAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3777 S 28TH RD DUNNEGAN MO 65640 USA "
    },
    {
      "Location ID": "65641",
      "Location Name": "EAGLE ROCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 28815 STATE HIGHWAY 86 EAGLE ROCK MO 65641 USA "
    },
    {
      "Location ID": "65646",
      "Location Name": "EVERTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 W COMMERCIAL ST EVERTON MO 65646 USA "
    },
    {
      "Location ID": "65647",
      "Location Name": "EXETER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 S FRONT ST EXETER MO 65647 USA "
    },
    {
      "Location ID": "65648",
      "Location Name": "FAIR GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 E OLD MILL RD FAIR GROVE MO 65648 USA "
    },
    {
      "Location ID": "65649",
      "Location Name": "FAIR PLAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 S TROOST AVE FAIR PLAY MO 65649 USA "
    },
    {
      "Location ID": "65650",
      "Location Name": "FLEMINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 691 MAIN ST FLEMINGTON MO 65650 USA "
    },
    {
      "Location ID": "65652",
      "Location Name": "FORDLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 132 MAIN ST FORDLAND MO 65652 USA "
    },
    {
      "Location ID": "65653",
      "Location Name": "FORSYTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 SHADOWROCK DR FORSYTH MO 65653 USA "
    },
    {
      "Location ID": "65655",
      "Location Name": "GAINESVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 ELM ST GAINESVILLE MO 65655 USA "
    },
    {
      "Location ID": "65656",
      "Location Name": "GALENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 30691 STATE HIGHWAY 413 GALENA MO 65656 USA "
    },
    {
      "Location ID": "65658",
      "Location Name": "GOLDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 28667 STATE HIGHWAY J GOLDEN MO 65658 USA "
    },
    {
      "Location ID": "65661",
      "Location Name": "GREENFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 S MAIN ST GREENFIELD MO 65661 USA "
    },
    {
      "Location ID": "65663",
      "Location Name": "HALF WAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2132 HIGHWAY 32 HALF WAY MO 65663 USA "
    },
    {
      "Location ID": "65664",
      "Location Name": "HALLTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 224 MAIN ST HALLTOWN MO 65664 USA "
    },
    {
      "Location ID": "65666",
      "Location Name": "HARDENVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 25736 US HIGHWAY 160 HARDENVILLE MO 65666 USA "
    },
    {
      "Location ID": "65667",
      "Location Name": "HARTVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 COURT SQ HARTVILLE MO 65667 USA "
    },
    {
      "Location ID": "65668",
      "Location Name": "HERMITAGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 18704 SPRING ST HERMITAGE MO 65668 USA "
    },
    {
      "Location ID": "65669",
      "Location Name": "HIGHLANDVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 299 KENTLING AVE HIGHLANDVILLE MO 65669 USA "
    },
    {
      "Location ID": "65673",
      "Location Name": "HOLLISTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1980 S BUSINESS 65 HOLLISTER MO 65673 USA "
    },
    {
      "Location ID": "65674",
      "Location Name": "HUMANSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 W TILDEN ST HUMANSVILLE MO 65674 USA "
    },
    {
      "Location ID": "65675",
      "Location Name": "HURLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 S RAIL ST HURLEY MO 65675 USA "
    },
    {
      "Location ID": "65676",
      "Location Name": "ISABELLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9614 US HIGHWAY 160 ISABELLA MO 65676 USA "
    },
    {
      "Location ID": "65680",
      "Location Name": "KISSEE MILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13044 E STATE HIGHWAY 76 KISSEE MILLS MO 65680 USA "
    },
    {
      "Location ID": "65681",
      "Location Name": "LAMPE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 99 STATE ROUTE H LAMPE MO 65681 USA "
    },
    {
      "Location ID": "65682",
      "Location Name": "LOCKWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 800 MAIN ST LOCKWOOD MO 65682 USA "
    },
    {
      "Location ID": "65685",
      "Location Name": "LOUISBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 274 STATE HIGHWAY 64 LOUISBURG MO 65685 USA "
    },
    {
      "Location ID": "65686",
      "Location Name": "KIMBERLING CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 38A KIMBERLING CTR LN KIMBERLING CITY MO 65686 USA "
    },
    {
      "Location ID": "65688",
      "Location Name": "BRANDSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 E MAIN ST BRANDSVILLE MO 65688 USA "
    },
    {
      "Location ID": "65689",
      "Location Name": "CABOOL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 519 CEDAR AVE CABOOL MO 65689 USA "
    },
    {
      "Location ID": "65692",
      "Location Name": "KOSHKONONG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 DIGGINS ST KOSHKONONG MO 65692 USA "
    },
    {
      "Location ID": "656DC",
      "Location Name": "DREAMTIME CREATIONS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 21089 LAWRENCE 1215 AURORA MO 65605 USA "
    },
    {
      "Location ID": "656MC",
      "Location Name": "MORNINGSIDE CHURCH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 GRACE CHAPEL RD BLUE EYE MO 65611 USA "
    },
    {
      "Location ID": "656OH",
      "Location Name": "OLYVIA'S HEARTLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 135 SOUTHTOWN BLVD HOLLISTER MO 65672 USA "
    },
    {
      "Location ID": "65702",
      "Location Name": "MACOMB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4880 HIGHWAY A MACOMB MO 65702 USA "
    },
    {
      "Location ID": "65704",
      "Location Name": "MANSFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 310 E COMMERCIAL ST MANSFIELD MO 65704 USA "
    },
    {
      "Location ID": "65705",
      "Location Name": "MARIONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W WASHINGTON MARIONVILLE MO 65705 USA "
    },
    {
      "Location ID": "65706",
      "Location Name": "MARSHFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 N CLAY ST MARSHFIELD MO 65706 USA "
    },
    {
      "Location ID": "65707",
      "Location Name": "MILLER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 E MAIN ST MILLER MO 65707 USA "
    },
    {
      "Location ID": "65708",
      "Location Name": "MONETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 4TH ST MONETT MO 65708 USA "
    },
    {
      "Location ID": "65711",
      "Location Name": "MOUNTAIN GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 S UNION ST MOUNTAIN GROVE MO 65711 USA "
    },
    {
      "Location ID": "65712",
      "Location Name": "MOUNT VERNON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 E SOUTH ST MOUNT VERNON MO 65712 USA "
    },
    {
      "Location ID": "65713",
      "Location Name": "NIANGUA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 S MAIN ST NIANGUA MO 65713 USA "
    },
    {
      "Location ID": "65714",
      "Location Name": "NIXA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 404 W SOUTH ST NIXA MO 65714 USA "
    },
    {
      "Location ID": "65717",
      "Location Name": "NORWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 E MAIN ST NORWOOD MO 65717 USA "
    },
    {
      "Location ID": "65721",
      "Location Name": "OZARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1450 W JACKSON ST OZARK MO 65721 USA "
    },
    {
      "Location ID": "65722",
      "Location Name": "PHILLIPSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 S FRONT ST PHILLIPSBURG MO 65722 USA "
    },
    {
      "Location ID": "65723",
      "Location Name": "PIERCE CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 E COMMERCIAL ST PIERCE CITY MO 65723 USA "
    },
    {
      "Location ID": "65724",
      "Location Name": "PITTSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 25397 STATE HIGHWAY 64 PITTSBURG MO 65724 USA "
    },
    {
      "Location ID": "65725",
      "Location Name": "PLEASANT HOPE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 MAIN ST PLEASANT HOPE MO 65725 USA "
    },
    {
      "Location ID": "65726",
      "Location Name": "POINT LOOKOUT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9998 ACADEMIC AVE POINT LOOKOUT MO 65726 USA "
    },
    {
      "Location ID": "65732",
      "Location Name": "PRESTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 29024 US HIGHWAY 54 PRESTON MO 65732 USA "
    },
    {
      "Location ID": "65734",
      "Location Name": "PURDY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 WASHINGTON AVE PURDY MO 65734 USA "
    },
    {
      "Location ID": "65737",
      "Location Name": "REEDS SPRING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 22153 MAIN ST REEDS SPRING MO 65737 USA "
    },
    {
      "Location ID": "65738",
      "Location Name": "REPUBLIC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 660 E HARRISON ST REPUBLIC MO 65738 USA "
    },
    {
      "Location ID": "65739",
      "Location Name": "RIDGEDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3234 RIDGEDALE RD RIDGEDALE MO 65739 USA "
    },
    {
      "Location ID": "65740",
      "Location Name": "ROCKAWAY BEACH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2578 STATE HIGHWAY 176 ROCKAWAY BEACH MO 65740 USA "
    },
    {
      "Location ID": "65742",
      "Location Name": "ROGERSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 226 W CENTER ST ROGERSVILLE MO 65742 USA "
    },
    {
      "Location ID": "65745",
      "Location Name": "SELIGMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 28048 N HICKORY ST SELIGMAN MO 65745 USA "
    },
    {
      "Location ID": "65746",
      "Location Name": "SEYMOUR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 218 W WASHINGTON ST SEYMOUR MO 65746 USA "
    },
    {
      "Location ID": "65747",
      "Location Name": "SHELL KNOB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24847 STATE HIGHWAY 39 SHELL KNOB MO 65747 USA "
    },
    {
      "Location ID": "65752",
      "Location Name": "SOUTH GREENFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 PARK ST SOUTH GREENFIELD MO 65752 USA "
    },
    {
      "Location ID": "65754",
      "Location Name": "SPOKANE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 745 SPOKANE RD SPOKANE MO 65754 USA "
    },
    {
      "Location ID": "65755",
      "Location Name": "SQUIRES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 MAIN ST SQUIRES MO 65755 USA "
    },
    {
      "Location ID": "65756",
      "Location Name": "STOTTS CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 E MOUNT VERNON ST STOTTS CITY MO 65756 USA "
    },
    {
      "Location ID": "65757",
      "Location Name": "STRAFFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 S WASHINGTON ST STRAFFORD MO 65757 USA "
    },
    {
      "Location ID": "65759",
      "Location Name": "TANEYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 310 GRAND AVE TANEYVILLE MO 65759 USA "
    },
    {
      "Location ID": "65760",
      "Location Name": "TECUMSEH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 33256 US HIGHWAY 160 TECUMSEH MO 65760 USA "
    },
    {
      "Location ID": "65761",
      "Location Name": "THEODOSIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5625 US HIGHWAY 160 THEODOSIA MO 65761 USA "
    },
    {
      "Location ID": "65762",
      "Location Name": "THORNFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13 COUNTY ROAD 916 THORNFIELD MO 65762 USA "
    },
    {
      "Location ID": "65764",
      "Location Name": "TUNAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4 STATE RD E TUNAS MO 65764 USA "
    },
    {
      "Location ID": "65765",
      "Location Name": "TURNERS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6480 E FARM ROAD 148 TURNERS MO 65765 USA "
    },
    {
      "Location ID": "65767",
      "Location Name": "URBANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 N MAIN ST URBANA MO 65767 USA "
    },
    {
      "Location ID": "65769",
      "Location Name": "VERONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 170 S 3RD ST VERONA MO 65769 USA "
    },
    {
      "Location ID": "65770",
      "Location Name": "WALNUT GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 412 S WASHINGTON AVE WALNUT GROVE MO 65770 USA "
    },
    {
      "Location ID": "65772",
      "Location Name": "WASHBURN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 MAIN ST WASHBURN MO 65772 USA "
    },
    {
      "Location ID": "65773",
      "Location Name": "WASOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1652 COUNTY ROAD 129 WASOLA MO 65773 USA "
    },
    {
      "Location ID": "65774",
      "Location Name": "WEAUBLEAU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 144 E US HIGHWAY 54 WEAUBLEAU MO 65774 USA "
    },
    {
      "Location ID": "65775",
      "Location Name": "WEST PLAINS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 WASHINGTON AVE WEST PLAINS MO 65775 USA "
    },
    {
      "Location ID": "65778",
      "Location Name": "MYRTLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 MAIN ST MYRTLE MO 65778 USA "
    },
    {
      "Location ID": "65779",
      "Location Name": "WHEATLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 18545 N MAIN ST WHEATLAND MO 65779 USA "
    },
    {
      "Location ID": "65781",
      "Location Name": "WILLARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 WILLEY ST WILLARD MO 65781 USA "
    },
    {
      "Location ID": "65785",
      "Location Name": "STOCKTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 406 EAST ST STOCKTON MO 65785 USA "
    },
    {
      "Location ID": "65786",
      "Location Name": "MACKS CREEK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13073 W US HIGHWAY 54 MACKS CREEK MO 65786 USA "
    },
    {
      "Location ID": "65787",
      "Location Name": "ROACH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 353 STATE ROAD AA ROACH MO 65787 USA "
    },
    {
      "Location ID": "65789",
      "Location Name": "POMONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4279 COUNTY ROAD 4860 POMONA MO 65789 USA "
    },
    {
      "Location ID": "65791",
      "Location Name": "THAYER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 N 2ND ST THAYER MO 65791 USA "
    },
    {
      "Location ID": "65793",
      "Location Name": "WILLOW SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 E 3RD ST WILLOW SPRINGS MO 65793 USA "
    },
    {
      "Location ID": "657AS",
      "Location Name": "AMERICAN SOUND CONNECTION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1738 N GREGORY DR NIXA MO 65714 USA "
    },
    {
      "Location ID": "657BW",
      "Location Name": "BRANSON WEST CPU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 18128 STATE HWY 13 REEDS SPRING MO 65737 USA "
    },
    {
      "Location ID": "657JE",
      "Location Name": "JORDAN ESSENTIALS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1106 EAGLECREST ST NIXA MO 65714 USA "
    },
    {
      "Location ID": "657LA",
      "Location Name": "LAMONSTER GARAGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1271 N ROBIN ST NIXA MO 65714 USA "
    },
    {
      "Location ID": "65802",
      "Location Name": "GRIESEMER STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1919 W. BENNETT ST SPRINGFIELD MO 65807 USA "
    },
    {
      "Location ID": "65803",
      "Location Name": "SPRINGFIELD AIRPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5000 W KEARNEY ST SPRINGFIEKLD AIRPORT MO 65803 USA "
    },
    {
      "Location ID": "65804",
      "Location Name": "GLENSTONE STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1442 S GLENSTONE AVE GLENSTONE STATION MO 65804 USA "
    },
    {
      "Location ID": "65807",
      "Location Name": "SOUTHWEST ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3951 S CAMPBELL SPRINGFIELD MO 65807 USA "
    },
    {
      "Location ID": "658BP",
      "Location Name": "BASS PRO SHOPS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2500 E KEARNEY ST SPRINGFIELD MO 65803 USA "
    },
    {
      "Location ID": "658BR",
      "Location Name": "BLACK RIVER IMAGING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2525 N PARTNERSHIP BLVD SPRINGFIELD MO 65803 USA "
    },
    {
      "Location ID": "658NA",
      "Location Name": "NATIONAL AUDIO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 309 E WATER ST SPRINGFIELD MO 65806 USA "
    },
    {
      "Location ID": "65JTC",
      "Location Name": "JAMES RIVER TOWNE CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1893 E INDEPENDENCE ST SPRINGFIELD MO 65804 USA "
    },
    {
      "Location ID": "66002",
      "Location Name": "ATCHISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 621 KANSAS AVE ATCHISON KS 66002 USA "
    },
    {
      "Location ID": "66008",
      "Location Name": "BENDENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 COMMERCIAL ST BENDENA KS 66008 USA "
    },
    {
      "Location ID": "66012",
      "Location Name": "BONNER SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 135 CEDAR AVE BONNER SPRINGS KS 66012 USA "
    },
    {
      "Location ID": "66017",
      "Location Name": "DENTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 506 MAIN ST DENTON KS 66017 USA "
    },
    {
      "Location ID": "66024",
      "Location Name": "ELWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 607 VERMONT ST ELWOOD KS 66024 USA "
    },
    {
      "Location ID": "66035",
      "Location Name": "HIGHLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 W MAIN ST HIGHLAND KS 66035 USA "
    },
    {
      "Location ID": "66041",
      "Location Name": "LANCASTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N BROADWAY ST LANCASTER KS 66041 USA "
    },
    {
      "Location ID": "66042",
      "Location Name": "LANE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 416 KANSAS AVE LANE KS 66042 USA "
    },
    {
      "Location ID": "66044",
      "Location Name": "LAWRENCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 645 VERMONT ST LAWRENCE KS 66044 USA "
    },
    {
      "Location ID": "66046",
      "Location Name": "JAYHAWK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1901 W 31ST ST LAWRENCE KS 66046 USA "
    },
    {
      "Location ID": "66048",
      "Location Name": "LEAVENWORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 330 SHAWNEE ST LEAVENWORTH KS 66048 USA "
    },
    {
      "Location ID": "66061",
      "Location Name": "OLATHE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 N CHESTNUT ST OLATHE KS 66061 USA "
    },
    {
      "Location ID": "66062",
      "Location Name": "OLATHE EAST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15050 W 138TH ST OLATHE KS 66062 USA "
    },
    {
      "Location ID": "66067",
      "Location Name": "OTTAWA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 S HICKORY ST OTTAWA KS 66067 USA "
    },
    {
      "Location ID": "66071",
      "Location Name": "PAOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 19 E WEA ST PAOLA KS 66071 USA "
    },
    {
      "Location ID": "66076",
      "Location Name": "POMONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 230 MADISON ST POMONA KS 66076 USA "
    },
    {
      "Location ID": "66078",
      "Location Name": "PRINCETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 314 GALVESTON ST PRINCETON KS 66078 USA "
    },
    {
      "Location ID": "66079",
      "Location Name": "RANTOUL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 E MAIN ST RANTOUL KS 66079 USA "
    },
    {
      "Location ID": "66080",
      "Location Name": "RICHMOND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 E CENTRAL ST RICHMOND KS 66080 USA "
    },
    {
      "Location ID": "66085",
      "Location Name": "STILWELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 19800 METCALF AVE STILWELL KS 66085 USA "
    },
    {
      "Location ID": "66087",
      "Location Name": "TROY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 S MAIN ST TROY KS 66087 USA "
    },
    {
      "Location ID": "66090",
      "Location Name": "WATHENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 304 SAINT JOSEPH ST WATHENA KS 66090 USA "
    },
    {
      "Location ID": "66094",
      "Location Name": "WHITE CLOUD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 MAIN ST WHITE CLOUD KS 66094 USA "
    },
    {
      "Location ID": "66095",
      "Location Name": "WILLIAMSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 W WILLIAM ST WILLIAMSBURG KS 66095 USA "
    },
    {
      "Location ID": "660AE",
      "Location Name": "AMERICAN EAGLE OUTFITTERS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1301 N DAVIS AVE OTTAWA KS 66067 USA "
    },
    {
      "Location ID": "660AP",
      "Location Name": "ALLEN PRESS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 810 E 10TH ST LAWRENCE KS 66044 USA "
    },
    {
      "Location ID": "660GE",
      "Location Name": "GENCO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3450 S 4TH ST LEAVENWORTH KS 66048 USA "
    },
    {
      "Location ID": "661",
      "Location Name": "KANSAS CITY         KS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5215 RICHLAND AVE KANSAS CITY KS 66106 USA "
    },
    {
      "Location ID": "662MC",
      "Location Name": "MCI5",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16851 W 113TH ST LENEXA KS 66219 USA "
    },
    {
      "Location ID": "664",
      "Location Name": "TOPEKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1410 NW GAGE BLVD TOPEKA KS 66618 USA "
    },
    {
      "Location ID": "66403",
      "Location Name": "AXTELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 5TH ST AXTELL KS 66403 USA "
    },
    {
      "Location ID": "66404",
      "Location Name": "BAILEYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 MAIN ST BAILEYVILLE KS 66404 USA "
    },
    {
      "Location ID": "66406",
      "Location Name": "BEATTIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 CENTER ST BEATTIE KS 66406 USA "
    },
    {
      "Location ID": "66411",
      "Location Name": "BLUE RAPIDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 22 PUBLIC SQ BLUE RAPIDS KS 66411 USA "
    },
    {
      "Location ID": "66412",
      "Location Name": "BREMEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 BRENNEKE BREMEN KS 66412 USA "
    },
    {
      "Location ID": "66415",
      "Location Name": "CENTRALIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 505 4TH ST CENTRALIA KS 66415 USA "
    },
    {
      "Location ID": "66416",
      "Location Name": "CIRCLEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 LINCOLN ST CIRCLEVILLE KS 66416 USA "
    },
    {
      "Location ID": "66417",
      "Location Name": "CORNING",
      "Time Zone Offset (Hours)": -1,
      "Address": " CORNER 5TH & MAIN CORNING KS 66417 USA "
    },
    {
      "Location ID": "66419",
      "Location Name": "DENISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 CENTRAL AVE DENISON KS 66419 USA "
    },
    {
      "Location ID": "66422",
      "Location Name": "EMMETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 MAIN ST EMMETT KS 66422 USA "
    },
    {
      "Location ID": "66427",
      "Location Name": "FRANKFORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 137 W 2ND ST FRANKFORT KS 66427 USA "
    },
    {
      "Location ID": "66428",
      "Location Name": "GOFF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1737 STATE HIGHWAY 9 GOFF KS 66428 USA "
    },
    {
      "Location ID": "66429",
      "Location Name": "GRANTVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2123 WASHINGTON ST GRANTVILLE KS 66429 USA "
    },
    {
      "Location ID": "66432",
      "Location Name": "HAVENSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " MAIN ST HAVENSVILLE KS 66432 USA "
    },
    {
      "Location ID": "66434",
      "Location Name": "HIAWATHA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 613 UTAH ST HIAWATHA KS 66434 USA "
    },
    {
      "Location ID": "66440",
      "Location Name": "HOYT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 E 4TH ST HOYT KS 66440 USA "
    },
    {
      "Location ID": "664LB",
      "Location Name": "TOPEKA/SHAWNEE LIBRARY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1515 SW 10 AVE TOPEKA KS 66604 USA "
    },
    {
      "Location ID": "664WU",
      "Location Name": "WASHBURN UNIVERSITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1700 SW COLLEGE AVE TOPEKA KS 66621 USA "
    },
    {
      "Location ID": "66508",
      "Location Name": "MARYSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 S 9TH ST MARYSVILLE KS 66508 USA "
    },
    {
      "Location ID": "66509",
      "Location Name": "MAYETTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 E MAIN ST MAYETTA KS 66509 USA "
    },
    {
      "Location ID": "66512",
      "Location Name": "MERIDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N PALMBERG ST MERIDEN KS 66512 USA "
    },
    {
      "Location ID": "66518",
      "Location Name": "OKETO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 E CENTER ST OKETO KS 66518 USA "
    },
    {
      "Location ID": "66521",
      "Location Name": "ONAGA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 LUCIEN ST ONAGA KS 66521 USA "
    },
    {
      "Location ID": "66522",
      "Location Name": "ONEIDA, KS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 5TH ST ONEIDA KS 66522 USA "
    },
    {
      "Location ID": "66534",
      "Location Name": "SABETHA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 122 S 9TH ST SABETHA KS 66534 USA "
    },
    {
      "Location ID": "66536",
      "Location Name": "SAINT MARYS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 S 6TH ST SAINT MARYS KS 66536 USA "
    },
    {
      "Location ID": "66538",
      "Location Name": "SENECA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 607 MAIN ST SENECA KS 66538 USA "
    },
    {
      "Location ID": "66540",
      "Location Name": "SOLDIER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 1ST ST SOLDIER KS 66540 USA "
    },
    {
      "Location ID": "66541",
      "Location Name": "SUMMERFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 320 MAIN ST SUMMERFIELD KS 66541 USA "
    },
    {
      "Location ID": "66548",
      "Location Name": "WATERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 S NEBRASKA ST WATERVILLE KS 66548 USA "
    },
    {
      "Location ID": "66550",
      "Location Name": "WETMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 321 2ND ST WETMORE KS 66550 USA "
    },
    {
      "Location ID": "66603",
      "Location Name": "TOPEKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 424 S KANSAS AVE STE 1 TOPEKA KS 66603 USA "
    },
    {
      "Location ID": "66604",
      "Location Name": "GAGE CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1430 SW WOODHULL ST TOPEKA KS 66604 USA "
    },
    {
      "Location ID": "66605",
      "Location Name": "HICREST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2921 SE ADAMS ST TOPEKA KS 66605 USA "
    },
    {
      "Location ID": "66608",
      "Location Name": "NORTH TOPEKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 NW LYMAN RD TOPEKA KS 66608 USA "
    },
    {
      "Location ID": "66614",
      "Location Name": "SHERWOOD CARRIER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6120 SW 29TH ST TOPEKA KS 66614 USA "
    },
    {
      "Location ID": "66619",
      "Location Name": "PAULINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5826 SW TOPEKA BLVD TOPEKA KS 66619 USA "
    },
    {
      "Location ID": "66624",
      "Location Name": "MATERIAL DISTRIBUTION CTR #8",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 SW GARY ORMSBY DR TOPEKA KS 66624 USA "
    },
    {
      "Location ID": "666BC",
      "Location Name": "BLUE CROSS/BLUE SHIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1133 SW TOPEKA BLVD TOPEKA KS 66629 USA "
    },
    {
      "Location ID": "666CM",
      "Location Name": "STATE OF KANSAS CENTRAL MAIL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 915 SW HARRISON ST STE B44 TOPEKA KS 66612 USA "
    },
    {
      "Location ID": "666DD",
      "Location Name": "DISABILITY DETERMINATION SVCS.",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3640 SW TOPEKA BLVD STE 100 TOPEKA KS 66611 USA "
    },
    {
      "Location ID": "666FB",
      "Location Name": "FEDERAL BUILDING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 444 SE QUINCY ST TOPEKA KS 66683 USA "
    },
    {
      "Location ID": "666HE",
      "Location Name": "DEPT. OF HEALTH & ENVIRONMENT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 740 FORBES FIELD TOPEKA KS 66620 USA "
    },
    {
      "Location ID": "666JB",
      "Location Name": "KANSAS JUDICIAL BRANCH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 SW 10TH AVE TOPEKA KS 66612 USA "
    },
    {
      "Location ID": "666MD",
      "Location Name": "MATERIAL DISTRIBUTION CTR #7",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 SW GARY ORMSBY DR TOPEKA KS 66624 USA "
    },
    {
      "Location ID": "666OP",
      "Location Name": "OGDEN PUBLICATIONS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1503 SW 42ND ST TOPEKA KS 66609 USA "
    },
    {
      "Location ID": "666PC",
      "Location Name": "USPS NPC BLDG 303",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 SW GARY ORMSBY DR TOPEKA KS 66624 USA "
    },
    {
      "Location ID": "666PT",
      "Location Name": "PT'S COFFEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 929 SW UNIVERSITY BLVD STE D2 TOPEKA KS 66619 USA "
    },
    {
      "Location ID": "666SW",
      "Location Name": "SOUTHWEST PUBLISHING & MAILING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4000 SE ADAMS ST TOPEKA KS 66609 USA "
    },
    {
      "Location ID": "666TC",
      "Location Name": "TOPEKA CRF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7215 SW TOPEKA BLVD BLDG 8 TOPEKA KS 66624 USA "
    },
    {
      "Location ID": "666TM",
      "Location Name": "TOPEKA MDC #5",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7215 SW TOPEKA BLVD STE 5B TOPEKA KS 66624-8701 USA "
    },
    {
      "Location ID": "666VA",
      "Location Name": "V A MEDICAL CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2200 SW GAGE BLVD TOPEKA KS 66622 USA "
    },
    {
      "Location ID": "666WE",
      "Location Name": "WESTAR ENERGY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 818 S KANSAS AVE TOPEKA KS 66612 USA "
    },
    {
      "Location ID": "667",
      "Location Name": "FORT SCOTT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 S NATIONAL AVE FORT SCOTT KS 66701 USA "
    },
    {
      "Location ID": "66701",
      "Location Name": "FORT SCOTT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 S NATIONAL AVE FORT SCOTT KS 66701 USA "
    },
    {
      "Location ID": "66710",
      "Location Name": "ALTOONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 732 MAIN ST ALTOONA KS 66710 USA "
    },
    {
      "Location ID": "66713",
      "Location Name": "BAXTER SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1115 PARK AVE BAXTER SPRINGS KS 66713 USA "
    },
    {
      "Location ID": "66717",
      "Location Name": "BUFFALO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 308 N MAIN ST BUFFALO KS 66717 USA "
    },
    {
      "Location ID": "66720",
      "Location Name": "CHANUTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 N LINCOLN AVE CHANUTE KS 66720 USA "
    },
    {
      "Location ID": "66724",
      "Location Name": "CHEROKEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 S VINE ST CHEROKEE KS 66724 USA "
    },
    {
      "Location ID": "66725",
      "Location Name": "COLUMBUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 223 S PENNSYLVANIA AVE COLUMBUS KS 66725 USA "
    },
    {
      "Location ID": "66728",
      "Location Name": "CRESTLINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 653 SE HIGHWAY ALTERNATE 69 CRESTLINE KS 66728 USA "
    },
    {
      "Location ID": "66736",
      "Location Name": "FREDONIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 428 MADISON ST FREDONIA KS 66736 USA "
    },
    {
      "Location ID": "66739",
      "Location Name": "GALENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 E 5TH ST GALENA KS 66739 USA "
    },
    {
      "Location ID": "66742",
      "Location Name": "GAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 W 1ST ST GAS KS 66742 USA "
    },
    {
      "Location ID": "66743",
      "Location Name": "GIRARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 N SUMMIT ST GIRARD KS 66743 USA "
    },
    {
      "Location ID": "66748",
      "Location Name": "HUMBOLDT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 811 NEW YORK ST HUMBOLDT KS 66748 USA "
    },
    {
      "Location ID": "66749",
      "Location Name": "IOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 WEST ST IOLA KS 66749 USA "
    },
    {
      "Location ID": "66751",
      "Location Name": "LA HARPE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 802 S MAIN ST LA HARPE KS 66751 USA "
    },
    {
      "Location ID": "66753",
      "Location Name": "MC CUNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 601 MAIN ST MC CUNE KS 66753 USA "
    },
    {
      "Location ID": "66755",
      "Location Name": "MORAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 226 N CEDAR ST MORAN KS 66755 USA "
    },
    {
      "Location ID": "66757",
      "Location Name": "NEODESHA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 N 5TH ST NEODESHA KS 66757 USA "
    },
    {
      "Location ID": "66762",
      "Location Name": "PITTSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 702 N LOCUST ST PITTSBURG KS 66762 USA "
    },
    {
      "Location ID": "66770",
      "Location Name": "RIVERTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7018 SE NIGHTHAWK LN RIVERTON KS 66770 USA "
    },
    {
      "Location ID": "66773",
      "Location Name": "SCAMMON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 E 3RD ST SCAMMON KS 66773 USA "
    },
    {
      "Location ID": "66781",
      "Location Name": "WEIR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 E MAIN ST WEIR KS 66781 USA "
    },
    {
      "Location ID": "66782",
      "Location Name": "WEST MINERAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 JOPLIN ST WEST MINERAL KS 66782 USA "
    },
    {
      "Location ID": "667CF",
      "Location Name": "CLEAVER FARM AND HOME",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 W 21ST ST CHANUTE KS 66720 USA "
    },
    {
      "Location ID": "66801",
      "Location Name": "EMPORIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 625 MERCHANT ST EMPORIA KS 66801 USA "
    },
    {
      "Location ID": "669",
      "Location Name": "CONCORDIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 622 WASHINGTON ST CONCORDIA KS 66901 USA "
    },
    {
      "Location ID": "66901",
      "Location Name": "CONCORDIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 622 WASHINGTON ST CONCORDIA KS 66901 USA "
    },
    {
      "Location ID": "66933",
      "Location Name": "BARNES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20 N CENTER ST BARNES KS 66933 USA "
    },
    {
      "Location ID": "66935",
      "Location Name": "BELLEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1119 18TH ST BELLEVILLE KS 66935 USA "
    },
    {
      "Location ID": "66936",
      "Location Name": "BURR OAK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 S MAIN ST BURR OAK KS 66936 USA "
    },
    {
      "Location ID": "66937",
      "Location Name": "CLIFTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E PARALLEL ST CLIFTON KS 66937 USA "
    },
    {
      "Location ID": "66938",
      "Location Name": "CLYDE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 308 WASHINGTON ST CLYDE KS 66938 USA "
    },
    {
      "Location ID": "66939",
      "Location Name": "COURTLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 416 MAIN ST COURTLAND KS 66939 USA "
    },
    {
      "Location ID": "66940",
      "Location Name": "CUBA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 309 BAIRD ST CUBA KS 66940 USA "
    },
    {
      "Location ID": "66941",
      "Location Name": "ESBON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 315 GRAND AVE ESBON KS 66941 USA "
    },
    {
      "Location ID": "66942",
      "Location Name": "FORMOSO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 MAIN ST FORMOSO KS 66942 USA "
    },
    {
      "Location ID": "66943",
      "Location Name": "GREENLEAF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 418 COMMERCIAL ST GREENLEAF KS 66943 USA "
    },
    {
      "Location ID": "66944",
      "Location Name": "HADDAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 503 MAIN ST HADDAM KS 66944 USA "
    },
    {
      "Location ID": "66945",
      "Location Name": "HANOVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E NORTH ST HANOVER KS 66945 USA "
    },
    {
      "Location ID": "66948",
      "Location Name": "JAMESTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 304 WALNUT ST JAMESTOWN KS 66948 USA "
    },
    {
      "Location ID": "66949",
      "Location Name": "JEWELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 315 MAIN ST JEWELL KS 66949 USA "
    },
    {
      "Location ID": "66951",
      "Location Name": "KENSINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 S MAIN ST KENSINGTON KS 66951 USA "
    },
    {
      "Location ID": "66952",
      "Location Name": "LEBANON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 416 MAIN ST LEBANON KS 66952 USA "
    },
    {
      "Location ID": "66953",
      "Location Name": "LINN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 S OAK ST LINN KS 66953 USA "
    },
    {
      "Location ID": "66955",
      "Location Name": "MAHASKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 N MAIN ST MAHASKA KS 66955 USA "
    },
    {
      "Location ID": "66956",
      "Location Name": "MANKATO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 S COMMERCIAL ST MANKATO KS 66956 USA "
    },
    {
      "Location ID": "66958",
      "Location Name": "MORROWVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 S MAIN ST MORROWVILLE KS 66958 USA "
    },
    {
      "Location ID": "66959",
      "Location Name": "MUNDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 S MAIN ST MUNDEN KS 66959 USA "
    },
    {
      "Location ID": "66960",
      "Location Name": "NARKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 MAIN ST NARKA KS 66960 USA "
    },
    {
      "Location ID": "66962",
      "Location Name": "PALMER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E 2ND ST PALMER KS 66962 USA "
    },
    {
      "Location ID": "66963",
      "Location Name": "RANDALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 MAIN ST RANDALL KS 66963 USA "
    },
    {
      "Location ID": "66964",
      "Location Name": "REPUBLIC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 413 MAIN ST REPUBLIC KS 66964 USA "
    },
    {
      "Location ID": "66966",
      "Location Name": "SCANDIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 4TH ST SCANDIA KS 66966 USA "
    },
    {
      "Location ID": "66967",
      "Location Name": "SMITH CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 E COURT ST SMITH CENTER KS 66967 USA "
    },
    {
      "Location ID": "66968",
      "Location Name": "WASHINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 C ST WASHINGTON KS 66968 USA "
    },
    {
      "Location ID": "66970",
      "Location Name": "WEBBER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 LAMM ST WEBBER KS 66970 USA "
    },
    {
      "Location ID": "669LV",
      "Location Name": "LAMBERT VET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 814 K STREET BELLEVILLE KS 66935 USA "
    },
    {
      "Location ID": "66H",
      "Location Name": "KANSAS CITY STC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 31450 W 196TH ST EDGERTON KS 66021 USA "
    },
    {
      "Location ID": "66MD1",
      "Location Name": "MATERIAL DISTRIBUTION CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 SW GARY ORMSBY DR BLDG 301 TOPEKA KS 66624 USA "
    },
    {
      "Location ID": "66MDC",
      "Location Name": "MATERIAL DISTRIBUTION CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 SW GARY ORMSBY DR BLDG 302 TOPEKA KS 66624 USA "
    },
    {
      "Location ID": "66VMF",
      "Location Name": "TOPEKA VMF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 408 W 1ST AVE TOPEKA KS 66603 USA "
    },
    {
      "Location ID": "66Z",
      "Location Name": "LOG KANSAS CITY NDC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4900 SPEAKER RD KANSAS CITY KS 66106 USA "
    },
    {
      "Location ID": "670",
      "Location Name": "WICHITA P&DC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7117 W HARRY ST WICHITA KS 67276 USA "
    },
    {
      "Location ID": "670_53FT",
      "Location Name": "WICHITA P&DC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7117 W HARRY ST WICHITA KS 67276 USA "
    },
    {
      "Location ID": "67001",
      "Location Name": "ANDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 318 N MAIN ST ANDALE KS 67001 USA "
    },
    {
      "Location ID": "67002",
      "Location Name": "ANDOVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 N ANDOVER RD ANDOVER KS 67002 USA "
    },
    {
      "Location ID": "67003",
      "Location Name": "ANTHONY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 W STEADMAN ST ANTHONY KS 67003 USA "
    },
    {
      "Location ID": "67004",
      "Location Name": "ARGONIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 S MAIN ST ARGONIA KS 67004 USA "
    },
    {
      "Location ID": "67005",
      "Location Name": "ARKANSAS CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 E WASHINGTON AVE ARKANSAS CITY KS 67005 USA "
    },
    {
      "Location ID": "67008",
      "Location Name": "ATLANTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 S MAIN ST ATLANTA KS 67008 USA "
    },
    {
      "Location ID": "67009",
      "Location Name": "ATTICA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 136 N MAIN ST ATTICA KS 67009 USA "
    },
    {
      "Location ID": "67010",
      "Location Name": "AUGUSTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 E 5TH AVE AUGUSTA KS 67010 USA "
    },
    {
      "Location ID": "67012",
      "Location Name": "BEAUMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11406 SW WALE ST EUREKA KS 67045 USA "
    },
    {
      "Location ID": "67013",
      "Location Name": "BELLE PLAINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 434 N MERCHANT ST BELLE PLAINE KS 67013 USA "
    },
    {
      "Location ID": "67016",
      "Location Name": "BENTLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 140 S WICHITA ST BENTLEY KS 67016 USA "
    },
    {
      "Location ID": "67017",
      "Location Name": "BENTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 135 S MAIN ST BENTON KS 67017 USA "
    },
    {
      "Location ID": "67018",
      "Location Name": "BLUFF CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 621 CENTRAL AVE BLUFF CITY KS 67018 USA "
    },
    {
      "Location ID": "67019",
      "Location Name": "BURDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 417 N MAIN ST BURDEN KS 67019 USA "
    },
    {
      "Location ID": "67020",
      "Location Name": "BURRTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 E ADAMS ST BURRTON KS 67020 USA "
    },
    {
      "Location ID": "67022",
      "Location Name": "CALDWELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14 N MAIN ST CALDWELL KS 67022 USA "
    },
    {
      "Location ID": "67023",
      "Location Name": "CAMBRIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 W MAIN ST CAMBRIDGE KS 67023 USA "
    },
    {
      "Location ID": "67024",
      "Location Name": "CEDAR VALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 706 CEDAR ST CEDAR VALE KS 67024 USA "
    },
    {
      "Location ID": "67025",
      "Location Name": "CHENEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 N MAIN ST CHENEY KS 67025 USA "
    },
    {
      "Location ID": "67026",
      "Location Name": "CLEARWATER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 640 N 4TH ST CLEARWATER KS 67026 USA "
    },
    {
      "Location ID": "67029",
      "Location Name": "COLDWATER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 E MAIN ST COLDWATER KS 67029 USA "
    },
    {
      "Location ID": "67030",
      "Location Name": "COLWICH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 417 W WICHITA AVE COLWICH KS 67030 USA "
    },
    {
      "Location ID": "67031",
      "Location Name": "CONWAY SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 W SPRING AVE CONWAY SPRINGS KS 67031 USA "
    },
    {
      "Location ID": "67035",
      "Location Name": "CUNNINGHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 N MAIN ST CUNNINGHAM KS 67035 USA "
    },
    {
      "Location ID": "67036",
      "Location Name": "DANVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 RYAN AVE DANVILLE KS 67036 USA "
    },
    {
      "Location ID": "67037",
      "Location Name": "DERBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W GREENWAY ST DERBY KS 67037 USA "
    },
    {
      "Location ID": "67038",
      "Location Name": "DEXTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 N MAIN ST DEXTER KS 67038 USA "
    },
    {
      "Location ID": "67039",
      "Location Name": "DOUGLASS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 315 S FORREST ST DOUGLASS KS 67039 USA "
    },
    {
      "Location ID": "67041",
      "Location Name": "ELBING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 225 N MAIN ST ELBING KS 67041 USA "
    },
    {
      "Location ID": "67042",
      "Location Name": "EL DORADO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 131 E CENTRAL AVE EL DORADO KS 67042 USA "
    },
    {
      "Location ID": "67045",
      "Location Name": "EUREKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 N OAK ST EUREKA KS 67045 USA "
    },
    {
      "Location ID": "67047",
      "Location Name": "FALL RIVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 412 MERCHANTS AVE FALL RIVER KS 67047 USA "
    },
    {
      "Location ID": "67050",
      "Location Name": "GARDEN PLAIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 412 N MAIN ST GARDEN PLAIN KS 67050 USA "
    },
    {
      "Location ID": "67051",
      "Location Name": "GEUDA SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 S 1ST ST GEUDA SPRINGS KS 67051 USA "
    },
    {
      "Location ID": "67052",
      "Location Name": "GODDARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 328 N MAIN ST GODDARD KS 67052 USA "
    },
    {
      "Location ID": "67053",
      "Location Name": "GOESSEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 E MAIN ST GOESSEL KS 67053 USA "
    },
    {
      "Location ID": "67054",
      "Location Name": "GREENSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 S MAIN ST GREENSBURG KS 67054 USA "
    },
    {
      "Location ID": "67056",
      "Location Name": "HALSTEAD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 319 MAIN ST HALSTEAD KS 67056 USA "
    },
    {
      "Location ID": "67057",
      "Location Name": "HARDTNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 S MAIN ST HARDTNER KS 67057 USA "
    },
    {
      "Location ID": "67058",
      "Location Name": "HARPER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 901 CENTRAL ST HARPER KS 67058 USA "
    },
    {
      "Location ID": "67059",
      "Location Name": "HAVILAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 N MAIN ST HAVILAND KS 67059 USA "
    },
    {
      "Location ID": "67060",
      "Location Name": "HAYSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 224 W GRAND AVE HAYSVILLE KS 67060 USA "
    },
    {
      "Location ID": "67061",
      "Location Name": "HAZELTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 405 E MAIN ST HAZELTON KS 67061 USA "
    },
    {
      "Location ID": "67062",
      "Location Name": "HESSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 W KNOTT ST HESSTON KS 67062 USA "
    },
    {
      "Location ID": "67063",
      "Location Name": "HILLSBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 N MAIN ST HILLSBORO KS 67063 USA "
    },
    {
      "Location ID": "67065",
      "Location Name": "ISABEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 122 N MAIN ST ISABEL KS 67065 USA "
    },
    {
      "Location ID": "67066",
      "Location Name": "IUKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 N MAIN ST IUKA KS 67066 USA "
    },
    {
      "Location ID": "67067",
      "Location Name": "KECHI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 E KECHI RD KECHI KS 67067 USA "
    },
    {
      "Location ID": "67068",
      "Location Name": "KINGMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 425 N MAIN ST KINGMAN KS 67068 USA "
    },
    {
      "Location ID": "67070",
      "Location Name": "KIOWA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 602 MAIN ST KIOWA KS 67070 USA "
    },
    {
      "Location ID": "67073",
      "Location Name": "LEHIGH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 W MAIN ST LEHIGH KS 67073 USA "
    },
    {
      "Location ID": "67074",
      "Location Name": "LEON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 S MAIN ST LEON KS 67074 USA "
    },
    {
      "Location ID": "670CA",
      "Location Name": "Wichita Christmas Annex",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7601 UNIVERSITY WICHITA KS 67209 USA "
    },
    {
      "Location ID": "670FZ",
      "Location Name": "LOG WICHITA KS PSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7601 W UNIVERSITY ST WICHITA KS 67209 USA "
    },
    {
      "Location ID": "670KO",
      "Location Name": "KOHL'S - DERBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2800 N COMMERCE ST DERBY KS 67037 USA "
    },
    {
      "Location ID": "67101",
      "Location Name": "MAIZE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 E ALBERT ST MAIZE KS 67101 USA "
    },
    {
      "Location ID": "67103",
      "Location Name": "MAYFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 N OSBORN ST MAYFIELD KS 67103 USA "
    },
    {
      "Location ID": "67104",
      "Location Name": "MEDICINE LODGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 W KANSAS AVE MEDICINE LODGE KS 67104 USA "
    },
    {
      "Location ID": "67106",
      "Location Name": "MILTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1211 N WALNUT ST MILTON KS 67106 USA "
    },
    {
      "Location ID": "67107",
      "Location Name": "MOUNDRIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 S CHRISTIAN AVE MOUNDRIDGE KS 67107 USA "
    },
    {
      "Location ID": "67108",
      "Location Name": "MOUNT HOPE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 W MAIN ST MOUNT HOPE KS 67108 USA "
    },
    {
      "Location ID": "67109",
      "Location Name": "MULLINVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 S MAIN ST MULLINVILLE KS 67109 USA "
    },
    {
      "Location ID": "67110",
      "Location Name": "MULVANE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 W MAIN ST MULVANE KS 67110 USA "
    },
    {
      "Location ID": "67111",
      "Location Name": "MURDOCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2624 SE MURDOCK AVE MURDOCK KS 67111 USA "
    },
    {
      "Location ID": "67112",
      "Location Name": "NASHVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 S MAIN ST NASHVILLE KS 67112 USA "
    },
    {
      "Location ID": "67114",
      "Location Name": "NEWTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 N POPLAR ST NEWTON KS 67114 USA "
    },
    {
      "Location ID": "67117",
      "Location Name": "NORTH NEWTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2509 N MAIN ST NORTH NEWTON KS 67117 USA "
    },
    {
      "Location ID": "67118",
      "Location Name": "CPU NORWICH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 S MAIN ST NORWICH KS 67118 USA "
    },
    {
      "Location ID": "67119",
      "Location Name": "OXFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 N SUMNER ST OXFORD KS 67119 USA "
    },
    {
      "Location ID": "67120",
      "Location Name": "PECK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 328 W 7TH ST PECK KS 67120 USA "
    },
    {
      "Location ID": "67122",
      "Location Name": "PIEDMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 J80 RD PIEDMONT KS 67122 USA "
    },
    {
      "Location ID": "67123",
      "Location Name": "POTWIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 E MARSHALL AVE POTWIN KS 67123 USA "
    },
    {
      "Location ID": "67124",
      "Location Name": "PRATT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 E 3RD ST PRATT KS 67124 USA "
    },
    {
      "Location ID": "67127",
      "Location Name": "PROTECTION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 W WALNUT ST PROTECTION KS 67127 USA "
    },
    {
      "Location ID": "67131",
      "Location Name": "ROCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 333 E WILLIAMS ST ROCK KS 67131 USA "
    },
    {
      "Location ID": "67132",
      "Location Name": "ROSALIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 616 SE ROSALIA RD ROSALIA KS 67132 USA "
    },
    {
      "Location ID": "67133",
      "Location Name": "ROSE HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 N ROSE HILL RD ROSE HILL KS 67133 USA "
    },
    {
      "Location ID": "67134",
      "Location Name": "SAWYER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 S MAIN ST SAWYER KS 67134 USA "
    },
    {
      "Location ID": "67135",
      "Location Name": "SEDGWICK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 415 N COMMERCIAL AVE SEDGWICK KS 67135 USA "
    },
    {
      "Location ID": "67137",
      "Location Name": "SEVERY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 W MAIN ST SEVERY KS 67137 USA "
    },
    {
      "Location ID": "67138",
      "Location Name": "SHARON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 S BROADWAY ST SHARON KS 67138 USA "
    },
    {
      "Location ID": "67140",
      "Location Name": "SOUTH HAVEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 N MAIN ST SOUTH HAVEN KS 67140 USA "
    },
    {
      "Location ID": "67142",
      "Location Name": "SPIVEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 W STANLEY ST SPIVEY KS 67142 USA "
    },
    {
      "Location ID": "67144",
      "Location Name": "TOWANDA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 550 MAIN ST TOWANDA KS 67144 USA "
    },
    {
      "Location ID": "67146",
      "Location Name": "UDALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 507 S CLARK ST UDALL KS 67146 USA "
    },
    {
      "Location ID": "67147",
      "Location Name": "VALLEY CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 E MAIN ST VALLEY CENTER KS 67147 USA "
    },
    {
      "Location ID": "67149",
      "Location Name": "VIOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 S MAIN ST VIOLA KS 67149 USA "
    },
    {
      "Location ID": "67151",
      "Location Name": "WALTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 S 2ND AVE WALTON KS 67151 USA "
    },
    {
      "Location ID": "67152",
      "Location Name": "WELLINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 221 W HARVEY AVE OFC WELLINGTON KS 67152 USA "
    },
    {
      "Location ID": "67154",
      "Location Name": "WHITEWATER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 S MAIN ST WHITEWATER KS 67154 USA "
    },
    {
      "Location ID": "67155",
      "Location Name": "WILMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 S MAIN ST WILMORE KS 67155 USA "
    },
    {
      "Location ID": "67156",
      "Location Name": "WINFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 218 E 10TH AVE WINFIELD KS 67156 USA "
    },
    {
      "Location ID": "67159",
      "Location Name": "ZENDA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 N MAIN ST ZENDA KS 67159 USA "
    },
    {
      "Location ID": "671SF",
      "Location Name": "STROTHER FIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 22214 D ST WINFIELD KS 67156 USA "
    },
    {
      "Location ID": "67202",
      "Location Name": "DOWNTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 330 W 2ND ST N WICHITA KS 67202 USA "
    },
    {
      "Location ID": "67204",
      "Location Name": "NORTH WICHITA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2325 N ARKANSAS AVE WICHITA KS 67204 USA "
    },
    {
      "Location ID": "67207",
      "Location Name": "CORPORATE HILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9350 E CORPORATE HILLS DR WICHITA KS 67207 USA "
    },
    {
      "Location ID": "67208",
      "Location Name": "MUNGER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1314 N OLIVER AVE WICHITA KS 67208 USA "
    },
    {
      "Location ID": "67209",
      "Location Name": "DELANO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 626 S TYLER RD WICHITA KS 67209 USA "
    },
    {
      "Location ID": "67216",
      "Location Name": "RIVER CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3241 S HYDRAULIC ST WICHITA KS 67216 USA "
    },
    {
      "Location ID": "67217",
      "Location Name": "CHISHOLM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2510 S ELIZABETH AVE WICHITA KS 67217 USA "
    },
    {
      "Location ID": "672DP",
      "Location Name": "Docuplex",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1501 E DOUGLASS WICHITA KS 67211 USA "
    },
    {
      "Location ID": "672DR",
      "Location Name": "DUBIA ROACHES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 801 E LINCOLN ST WICHITA KS 67211 USA "
    },
    {
      "Location ID": "672ES",
      "Location Name": "EPIC SPORTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9750 E 53RD ST N BEL AIRE KS 67226 USA "
    },
    {
      "Location ID": "672MC",
      "Location Name": "McCormick Armstrong",
      "Time Zone Offset (Hours)": -1,
      "Address": " 635 N HYDRAULIC ST WICHITA KS 67214 USA "
    },
    {
      "Location ID": "672SP",
      "Location Name": "SHEPLERS (WICHITA)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4950 LAURA ST WICHITA KS 67216 USA "
    },
    {
      "Location ID": "672TH",
      "Location Name": "WICHITA THS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2225 S AIR CARGO RD WICHITA KS 67209 USA "
    },
    {
      "Location ID": "673",
      "Location Name": "INDEPENDENCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 E LAUREL ST INDEPENDENCE KS 67301 USA "
    },
    {
      "Location ID": "67330",
      "Location Name": "ALTAMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 S HUSTON ST ALTAMONT KS 67330 USA "
    },
    {
      "Location ID": "67333",
      "Location Name": "CANEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W 5TH AVE CANEY KS 67333 USA "
    },
    {
      "Location ID": "67335",
      "Location Name": "CHERRYVALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 N NEOSHO ST CHERRYVALE KS 67335 USA "
    },
    {
      "Location ID": "67336",
      "Location Name": "CHETOPA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 519 MAPLE ST CHETOPA KS 67336 USA "
    },
    {
      "Location ID": "67337",
      "Location Name": "COFFEYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 232 W 8TH ST COFFEYVILLE KS 67337 USA "
    },
    {
      "Location ID": "67340",
      "Location Name": "DEARING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 312 S INDEPENDENCE ST DEARING KS 67340 USA "
    },
    {
      "Location ID": "67341",
      "Location Name": "DENNIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 S MAIN ST DENNIS KS 67341 USA "
    },
    {
      "Location ID": "67342",
      "Location Name": "EDNA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 N DELAWARE ST EDNA KS 67342 USA "
    },
    {
      "Location ID": "67344",
      "Location Name": "ELK CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 E HICKORY ST ELK CITY KS 67344 USA "
    },
    {
      "Location ID": "67345",
      "Location Name": "ELK FALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 712 7TH ST ELK FALLS KS 67345 USA "
    },
    {
      "Location ID": "67346",
      "Location Name": "GRENOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 S MAIN ST GRENOLA KS 67346 USA "
    },
    {
      "Location ID": "67347",
      "Location Name": "HAVANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 505 E MARY ST HAVANA KS 67347 USA "
    },
    {
      "Location ID": "67349",
      "Location Name": "HOWARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 135 E RANDOLPH ST HOWARD KS 67349 USA "
    },
    {
      "Location ID": "67351",
      "Location Name": "LIBERTY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 MAIN ST LIBERTY KS 67351 USA "
    },
    {
      "Location ID": "67352",
      "Location Name": "LONGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 416 KANSAS LONGTON KS 67352 USA "
    },
    {
      "Location ID": "67353",
      "Location Name": "MOLINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 N MAIN ST MOLINE KS 67353 USA "
    },
    {
      "Location ID": "67354",
      "Location Name": "MOUND VALLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 413 HICKORY ST MOUND VALLEY KS 67354 USA "
    },
    {
      "Location ID": "67355",
      "Location Name": "NIOTAZE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 E 2ND ST NIOTAZE KS 67355 USA "
    },
    {
      "Location ID": "67356",
      "Location Name": "OSWEGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 819 4TH ST OSWEGO KS 67356 USA "
    },
    {
      "Location ID": "67357",
      "Location Name": "PARSONS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1700 MAIN ST PARSONS KS 67357 USA "
    },
    {
      "Location ID": "67360",
      "Location Name": "PERU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E 6TH ST PERU KS 67360 USA "
    },
    {
      "Location ID": "67361",
      "Location Name": "SEDAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 W MAIN ST SEDAN KS 67361 USA "
    },
    {
      "Location ID": "67363",
      "Location Name": "SYCAMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 E MAIN SYCAMORE KS 67363 USA "
    },
    {
      "Location ID": "67364",
      "Location Name": "TYRO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 W MAIN ST TYRO KS 67364 USA "
    },
    {
      "Location ID": "673HG",
      "Location Name": "HUGOS ONLINE INDUSTRIAL SUPPLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2700 W MAIN ST INDEPENDENCE KS 67301 USA "
    },
    {
      "Location ID": "673KO",
      "Location Name": "KOPCO INC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 A STREET CANEY KS 67333 USA "
    },
    {
      "Location ID": "674",
      "Location Name": "SALINA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 E ASH ST SALINA KS 67401 USA "
    },
    {
      "Location ID": "67410",
      "Location Name": "ABILENE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 217 N BUCKEYE AVE ABILENE KS 67410 USA "
    },
    {
      "Location ID": "67416",
      "Location Name": "ASSARIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 S CENTER ST ASSARIA KS 67416 USA "
    },
    {
      "Location ID": "67417",
      "Location Name": "AURORA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 MAIN ST AURORA KS 67417 USA "
    },
    {
      "Location ID": "67418",
      "Location Name": "BARNARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 316 MAIN ST BARNARD KS 67418 USA "
    },
    {
      "Location ID": "67420",
      "Location Name": "BELOIT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 E MAIN ST BELOIT KS 67420 USA "
    },
    {
      "Location ID": "67422",
      "Location Name": "BENNINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 N NELSON ST BENNINGTON KS 67422 USA "
    },
    {
      "Location ID": "67423",
      "Location Name": "BEVERLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 N MAIN ST BEVERLY KS 67423 USA "
    },
    {
      "Location ID": "67425",
      "Location Name": "BROOKVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 E ANDERSON ST BROOKVILLE KS 67425 USA "
    },
    {
      "Location ID": "67427",
      "Location Name": "BUSHTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 S MAIN ST BUSHTON KS 67427 USA "
    },
    {
      "Location ID": "67428",
      "Location Name": "CANTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 S MAIN ST CANTON KS 67428 USA "
    },
    {
      "Location ID": "67430",
      "Location Name": "CAWKER CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 702 WISCONSIN ST CAWKER CITY KS 67430 USA "
    },
    {
      "Location ID": "67431",
      "Location Name": "CHAPMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 E 5TH ST CHAPMAN KS 67431 USA "
    },
    {
      "Location ID": "67432",
      "Location Name": "CLAY CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 806 6TH ST CLAY CENTER KS 67432 USA "
    },
    {
      "Location ID": "67436",
      "Location Name": "DELPHOS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 N MAIN ST DELPHOS KS 67436 USA "
    },
    {
      "Location ID": "67437",
      "Location Name": "DOWNS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 808 MORGAN AVE DOWNS KS 67437 USA "
    },
    {
      "Location ID": "67438",
      "Location Name": "DURHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 508 DOUGLAS DURHAM KS 67438 USA "
    },
    {
      "Location ID": "67439",
      "Location Name": "ELLSWORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 W 2ND ST ELLSWORTH KS 67439 USA "
    },
    {
      "Location ID": "67441",
      "Location Name": "ENTERPRISE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 S FACTORY ENTERPRISE KS 67441 USA "
    },
    {
      "Location ID": "67442",
      "Location Name": "FALUN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 S MAIN ST FALUN KS 67442 USA "
    },
    {
      "Location ID": "67443",
      "Location Name": "GALVA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 216 S MAIN ST GALVA KS 67443 USA "
    },
    {
      "Location ID": "67444",
      "Location Name": "GENESEO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 715 MAIN ST GENESEO KS 67444 USA "
    },
    {
      "Location ID": "67445",
      "Location Name": "GLASCO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E MAIN ST GLASCO KS 67445 USA "
    },
    {
      "Location ID": "67446",
      "Location Name": "GLEN ELDER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 228 W MAIN ST GLEN ELDER KS 67446 USA "
    },
    {
      "Location ID": "67447",
      "Location Name": "GREEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 DIXON AVE GREEN KS 67447 USA "
    },
    {
      "Location ID": "67448",
      "Location Name": "GYPSUM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 520 MAPLE ST GYPSUM KS 67448 USA "
    },
    {
      "Location ID": "67449",
      "Location Name": "HERINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 17 E MAIN ST HERINGTON KS 67449 USA "
    },
    {
      "Location ID": "67450",
      "Location Name": "HOLYROOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 W SANTA FE AVE HOLYROOD KS 67450 USA "
    },
    {
      "Location ID": "67451",
      "Location Name": "HOPE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 N MAIN ST HOPE KS 67451 USA "
    },
    {
      "Location ID": "67454",
      "Location Name": "KANOPOLIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N KANSAS ST KANOPOLIS KS 67454 USA "
    },
    {
      "Location ID": "67455",
      "Location Name": "LINCOLN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 S 3RD ST LINCOLN KS 67455 USA "
    },
    {
      "Location ID": "67456",
      "Location Name": "LINDSBORG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 E LINCOLN ST LINDSBORG KS 67456 USA "
    },
    {
      "Location ID": "67457",
      "Location Name": "LITTLE RIVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 280 MAIN ST LITTLE RIVER KS 67457 USA "
    },
    {
      "Location ID": "67458",
      "Location Name": "LONGFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 WEDA ST LONGFORD KS 67458 USA "
    },
    {
      "Location ID": "67460",
      "Location Name": "MCPHERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 E KANSAS AVE MCPHERSON KS 67460 USA "
    },
    {
      "Location ID": "67464",
      "Location Name": "MARQUETTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 N WASHINGTON MARQUETTE KS 67464 USA "
    },
    {
      "Location ID": "67466",
      "Location Name": "MILTONVALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 STARR MILTONVALE KS 67466 USA "
    },
    {
      "Location ID": "67467",
      "Location Name": "MINNEAPOLIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 W 2ND ST MINNEAPOLIS KS 67467 USA "
    },
    {
      "Location ID": "67468",
      "Location Name": "MORGANVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 MAIN ST MORGANVILLE KS 67468 USA "
    },
    {
      "Location ID": "67470",
      "Location Name": "NEW CAMBRIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S CENTER ST NEW CAMBRIA KS 67470 USA "
    },
    {
      "Location ID": "67473",
      "Location Name": "OSBORNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 233 W MAIN ST OSBORNE KS 67473 USA "
    },
    {
      "Location ID": "67474",
      "Location Name": "PORTIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 411 MARKET ST PORTIS KS 67474 USA "
    },
    {
      "Location ID": "67475",
      "Location Name": "RAMONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 N D ST RAMONA KS 67475 USA "
    },
    {
      "Location ID": "67476",
      "Location Name": "ROXBURY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 S MAIN ST ROXBURY KS 67476 USA "
    },
    {
      "Location ID": "67478",
      "Location Name": "SIMPSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 E FRONT ST SIMPSON KS 67478 USA "
    },
    {
      "Location ID": "67480",
      "Location Name": "SOLOMON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 122 W MAIN ST SOLOMON KS 67480 USA "
    },
    {
      "Location ID": "67481",
      "Location Name": "SYLVAN GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 S MAIN ST SYLVAN GROVE KS 67481 USA "
    },
    {
      "Location ID": "67482",
      "Location Name": "TALMAGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2970 MAIN ST TALMAGE KS 67482 USA "
    },
    {
      "Location ID": "67483",
      "Location Name": "TAMPA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 313 N MAIN STREET TAMPA KS 67483 USA "
    },
    {
      "Location ID": "67484",
      "Location Name": "TESCOTT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S MAIN ST TESCOTT KS 67484 USA "
    },
    {
      "Location ID": "67487",
      "Location Name": "WAKEFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 713 ELM ST WAKEFIELD KS 67487 USA "
    },
    {
      "Location ID": "67490",
      "Location Name": "WILSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2404 AVENUE E WILSON KS 67490 USA "
    },
    {
      "Location ID": "67491",
      "Location Name": "WINDOM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 701 MAIN ST WINDOM KS 67491 USA "
    },
    {
      "Location ID": "67492",
      "Location Name": "WOODBINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3 N BROADWAY WOODBINE KS 67492 USA "
    },
    {
      "Location ID": "675",
      "Location Name": "HUTCHINSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 128 E 1ST AVE HUTCHINSON KS 67501 USA "
    },
    {
      "Location ID": "67510",
      "Location Name": "ABBYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 N MAIN ST ABBYVILLE KS 67510 USA "
    },
    {
      "Location ID": "67511",
      "Location Name": "ALBERT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2002 MAIN ST ALBERT KS 67511 USA "
    },
    {
      "Location ID": "67512",
      "Location Name": "ALDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 PIONEER ST ALDEN KS 67512 USA "
    },
    {
      "Location ID": "67513",
      "Location Name": "ALEXANDER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 MAIN ST ALEXANDER KS 67513 USA "
    },
    {
      "Location ID": "67514",
      "Location Name": "ARLINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 W MAIN ST ARLINGTON KS 67514 USA "
    },
    {
      "Location ID": "67516",
      "Location Name": "BAZINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 S MAIN ST BAZINE KS 67516 USA "
    },
    {
      "Location ID": "67519",
      "Location Name": "BELPRE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 506 DUDLEY ST BELPRE KS 67519 USA "
    },
    {
      "Location ID": "67520",
      "Location Name": "BISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 219 MAIN ST BISON KS 67520 USA "
    },
    {
      "Location ID": "67521",
      "Location Name": "BROWNELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 W STATE ROAD 4 BROWNELL KS 67521 USA "
    },
    {
      "Location ID": "67522",
      "Location Name": "BUHLER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 N MAIN ST BUHLER KS 67522 USA "
    },
    {
      "Location ID": "67523",
      "Location Name": "BURDETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 ELM ST BURDETT KS 67523 USA "
    },
    {
      "Location ID": "67524",
      "Location Name": "CHASE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 513 MAIN ST CHASE KS 67524 USA "
    },
    {
      "Location ID": "67526",
      "Location Name": "ELLINWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 N MAIN ST ELLINWOOD KS 67526 USA "
    },
    {
      "Location ID": "67529",
      "Location Name": "GARFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 213 3RD ST GARFIELD KS 67529 USA "
    },
    {
      "Location ID": "67530",
      "Location Name": "GREAT BEND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2100 16TH ST GREAT BEND KS 67530 USA "
    },
    {
      "Location ID": "67543",
      "Location Name": "HAVEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 N KANSAS ST HAVEN KS 67543 USA "
    },
    {
      "Location ID": "67544",
      "Location Name": "HOISINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 E 2ND ST HOISINGTON KS 67544 USA "
    },
    {
      "Location ID": "67546",
      "Location Name": "INMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 S MAIN ST INMAN KS 67546 USA "
    },
    {
      "Location ID": "67547",
      "Location Name": "KINSLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 717 MARSH AVE KINSLEY KS 67547 USA "
    },
    {
      "Location ID": "67548",
      "Location Name": "LA CROSSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 122 E 8TH ST LA CROSSE KS 67548 USA "
    },
    {
      "Location ID": "67550",
      "Location Name": "LARNED",
      "Time Zone Offset (Hours)": -1,
      "Address": " 803 BROADWAY ST LARNED KS 67550 USA "
    },
    {
      "Location ID": "67552",
      "Location Name": "LEWIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 MAIN ST LEWIS KS 67552 USA "
    },
    {
      "Location ID": "67554",
      "Location Name": "LYONS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 EAST AVE N LYONS KS 67554 USA "
    },
    {
      "Location ID": "67556",
      "Location Name": "MC CRACKEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 MAIN ST MC CRACKEN KS 67556 USA "
    },
    {
      "Location ID": "67557",
      "Location Name": "MACKSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 N MAIN ST MACKSVILLE KS 67557 USA "
    },
    {
      "Location ID": "67560",
      "Location Name": "NESS CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 S KANSAS AVE NESS CITY KS 67560 USA "
    },
    {
      "Location ID": "67561",
      "Location Name": "NICKERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6 N NICKERSON ST NICKERSON KS 67561 USA "
    },
    {
      "Location ID": "67564",
      "Location Name": "OLMITZ",
      "Time Zone Offset (Hours)": -1,
      "Address": " 223 MAIN ST OLMITZ KS 67564 USA "
    },
    {
      "Location ID": "67565",
      "Location Name": "OTIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 N MAIN ST OTIS KS 67565 USA "
    },
    {
      "Location ID": "67566",
      "Location Name": "PARTRIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24 S MAIN ST PARTRIDGE KS 67566 USA "
    },
    {
      "Location ID": "67567",
      "Location Name": "PAWNEE ROCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 CENTRE ST PAWNEE ROCK KS 67567 USA "
    },
    {
      "Location ID": "67570",
      "Location Name": "PRETTY PRAIRIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 W MAIN ST PRETTY PRAIRIE KS 67570 USA "
    },
    {
      "Location ID": "67572",
      "Location Name": "RANSOM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 417 S VERMONT AVE RANSOM KS 67572 USA "
    },
    {
      "Location ID": "67573",
      "Location Name": "RAYMOND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 MAIN ST RAYMOND KS 67573 USA "
    },
    {
      "Location ID": "67574",
      "Location Name": "ROZEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 N MAIN ST ROZEL KS 67574 USA "
    },
    {
      "Location ID": "67575",
      "Location Name": "RUSH CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 221 N MAIN ST RUSH CENTER KS 67575 USA "
    },
    {
      "Location ID": "67576",
      "Location Name": "ST JOHN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 320 N MAIN ST ST JOHN KS 67576 USA "
    },
    {
      "Location ID": "67578",
      "Location Name": "STAFFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 N MAIN ST STAFFORD KS 67578 USA "
    },
    {
      "Location ID": "67579",
      "Location Name": "STERLING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 S BROADWAY AVE STERLING KS 67579 USA "
    },
    {
      "Location ID": "67581",
      "Location Name": "SYLVIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 S MAIN ST SYLVIA KS 67581 USA "
    },
    {
      "Location ID": "67583",
      "Location Name": "TURON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 N BURNS ST TURON KS 67583 USA "
    },
    {
      "Location ID": "67584",
      "Location Name": "UTICA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 142 S OHIO ST UTICA KS 67584 USA "
    },
    {
      "Location ID": "67585",
      "Location Name": "YODER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9803 S MAIN ST YODER KS 67585 USA "
    },
    {
      "Location ID": "676",
      "Location Name": "HAYS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 706 FORT ST HAYS KS 67601 USA "
    },
    {
      "Location ID": "67621",
      "Location Name": "AGRA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 317 MAIN ST AGRA KS 67621 USA "
    },
    {
      "Location ID": "67622",
      "Location Name": "ALMENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 503 MAIN ST ALMENA KS 67622 USA "
    },
    {
      "Location ID": "67623",
      "Location Name": "ALTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 703 MILL ST STE B ALTON KS 67623 USA "
    },
    {
      "Location ID": "67625",
      "Location Name": "BOGUE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 MAIN ST BOGUE KS 67625 USA "
    },
    {
      "Location ID": "67626",
      "Location Name": "BUNKER HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 563 ELM ST BUNKER HILL KS 67626 USA "
    },
    {
      "Location ID": "67628",
      "Location Name": "CEDAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 MAIN ST CEDAR KS 67628 USA "
    },
    {
      "Location ID": "67631",
      "Location Name": "COLLYER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 313 AINSLIE AVE COLLYER KS 67631 USA "
    },
    {
      "Location ID": "67632",
      "Location Name": "DAMAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 MAIN ST DAMAR KS 67632 USA "
    },
    {
      "Location ID": "67634",
      "Location Name": "DORRANCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 521 MAIN ST DORRANCE KS 67634 USA "
    },
    {
      "Location ID": "67637",
      "Location Name": "ELLIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 807 JEFFERSON ST ELLIS KS 67637 USA "
    },
    {
      "Location ID": "67638",
      "Location Name": "GAYLORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 600 5TH ST GAYLORD KS 67638 USA "
    },
    {
      "Location ID": "67640",
      "Location Name": "GORHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 E US HIGHWAY 40 GORHAM KS 67640 USA "
    },
    {
      "Location ID": "67642",
      "Location Name": "HILL CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 E MAIN ST HILL CITY KS 67642 USA "
    },
    {
      "Location ID": "67643",
      "Location Name": "JENNINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 S KANSAS AVE JENNINGS KS 67643 USA "
    },
    {
      "Location ID": "67644",
      "Location Name": "KIRWIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9 SQUARE ST KIRWIN KS 67644 USA "
    },
    {
      "Location ID": "67645",
      "Location Name": "LENORA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 S MAIN ST LENORA KS 67645 USA "
    },
    {
      "Location ID": "67646",
      "Location Name": "LOGAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 W MAIN ST LOGAN KS 67646 USA "
    },
    {
      "Location ID": "67647",
      "Location Name": "LONG ISLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 335 WASHINGTON AVE LONG ISLAND KS 67647 USA "
    },
    {
      "Location ID": "67648",
      "Location Name": "LUCAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 218 S MAIN ST LUCAS KS 67648 USA "
    },
    {
      "Location ID": "67649",
      "Location Name": "LURAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S MAIN ST LURAY KS 67649 USA "
    },
    {
      "Location ID": "67650",
      "Location Name": "MORLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 409 MAIN ST MORLAND KS 67650 USA "
    },
    {
      "Location ID": "67651",
      "Location Name": "NATOMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 402 ELM ST NATOMA KS 67651 USA "
    },
    {
      "Location ID": "67653",
      "Location Name": "NORCATUR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 S DECATUR AVE NORCATUR KS 67653 USA "
    },
    {
      "Location ID": "67654",
      "Location Name": "NORTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 E WASHINGTON ST NORTON KS 67654 USA "
    },
    {
      "Location ID": "67657",
      "Location Name": "PALCO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 411 MAIN ST PALCO KS 67657 USA "
    },
    {
      "Location ID": "67658",
      "Location Name": "PARADISE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 MAIN ST PARADISE KS 67658 USA "
    },
    {
      "Location ID": "67661",
      "Location Name": "PHILLIPSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 888 2ND ST PHILLIPSBURG KS 67661 USA "
    },
    {
      "Location ID": "67663",
      "Location Name": "PLAINVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 S MAIN ST PLAINVILLE KS 67663 USA "
    },
    {
      "Location ID": "67664",
      "Location Name": "PRAIRIE VIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 MAIN ST PRAIRIE VIEW KS 67664 USA "
    },
    {
      "Location ID": "67665",
      "Location Name": "RUSSELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 135 W WISCONSIN ST RUSSELL KS 67665 USA "
    },
    {
      "Location ID": "67669",
      "Location Name": "STOCKTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 124 S WALNUT ST STOCKTON KS 67669 USA "
    },
    {
      "Location ID": "67671",
      "Location Name": "VICTORIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1003 4TH ST VICTORIA KS 67671 USA "
    },
    {
      "Location ID": "67672",
      "Location Name": "WAKEENEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 407 RUSSELL AVE WAKEENEY KS 67672 USA "
    },
    {
      "Location ID": "67673",
      "Location Name": "WALDO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 422 MAIN ST WALDO KS 67673 USA "
    },
    {
      "Location ID": "67675",
      "Location Name": "WOODSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 508 MAIN ST WOODSTON KS 67675 USA "
    },
    {
      "Location ID": "677",
      "Location Name": "COLBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 535 N COURT AVE COLBY KS 67701 USA "
    },
    {
      "Location ID": "67730",
      "Location Name": "ATWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 MAIN ST ATWOOD KS 67730 USA "
    },
    {
      "Location ID": "67731",
      "Location Name": "BIRD CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 W BRESSLER BIRD CITY KS 67731 USA "
    },
    {
      "Location ID": "67737",
      "Location Name": "GRAINFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 MAIN ST GRAINFIELD KS 67737 USA "
    },
    {
      "Location ID": "67738",
      "Location Name": "GRINNELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 93 S ADAMS ST GRINNELL KS 67738 USA "
    },
    {
      "Location ID": "67739",
      "Location Name": "HERNDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 S QUINCY AVE HERNDON KS 67739 USA "
    },
    {
      "Location ID": "67740",
      "Location Name": "HOXIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 733 MAIN ST HOXIE KS 67740 USA "
    },
    {
      "Location ID": "67745",
      "Location Name": "MC DONALD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 218 RAWLINS AVE MC DONALD KS 67745 USA "
    },
    {
      "Location ID": "67749",
      "Location Name": "OBERLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 135 E HALL ST OBERLIN KS 67749 USA "
    },
    {
      "Location ID": "67752",
      "Location Name": "QUINTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 315 MAIN ST QUINTER KS 67752 USA "
    },
    {
      "Location ID": "67753",
      "Location Name": "REXFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 245 MAIN ST REXFORD KS 67753 USA "
    },
    {
      "Location ID": "67756",
      "Location Name": "SAINT FRANCIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 222 W WASHINGTON ST SAINT FRANCIS KS 67756 USA "
    },
    {
      "Location ID": "67757",
      "Location Name": "SELDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 S KANSAS AVE SELDEN KS 67757 USA "
    },
    {
      "Location ID": "678",
      "Location Name": "DODGE CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 700 CENTRAL AVE DODGE CITY KS 67801 USA "
    },
    {
      "Location ID": "67831",
      "Location Name": "ASHLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 710 MAIN ST ASHLAND KS 67831 USA "
    },
    {
      "Location ID": "67834",
      "Location Name": "BUCKLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 S MAIN ST BUCKLIN KS 67834 USA "
    },
    {
      "Location ID": "67835",
      "Location Name": "CIMARRON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 N MAIN ST CIMARRON KS 67835 USA "
    },
    {
      "Location ID": "67837",
      "Location Name": "COPELAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 SANTA FE ST COPELAND KS 67837 USA "
    },
    {
      "Location ID": "67838",
      "Location Name": "DEERFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 514 MAIN ST DEERFIELD KS 67838 USA "
    },
    {
      "Location ID": "67839",
      "Location Name": "DIGHTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 S LANE ST DIGHTON KS 67839 USA "
    },
    {
      "Location ID": "67840",
      "Location Name": "ENGLEWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 N STATE ROAD 283 ENGLEWOOD KS 67840 USA "
    },
    {
      "Location ID": "67841",
      "Location Name": "ENSIGN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 AUBREY ST ENSIGN KS 67841 USA "
    },
    {
      "Location ID": "67842",
      "Location Name": "FORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 601 N MAIN ST FORD KS 67842 USA "
    },
    {
      "Location ID": "67844",
      "Location Name": "FOWLER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 N MAIN ST FOWLER KS 67844 USA "
    },
    {
      "Location ID": "67846",
      "Location Name": "GARDEN CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 N 6TH ST GARDEN CITY KS 67846 USA "
    },
    {
      "Location ID": "67849",
      "Location Name": "HANSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 N LOGAN ST HANSTON KS 67849 USA "
    },
    {
      "Location ID": "67850",
      "Location Name": "HEALY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1001 S ELM ST HEALY KS 67850 USA "
    },
    {
      "Location ID": "67851",
      "Location Name": "HOLCOMB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 304 N JONES AVE HOLCOMB KS 67851 USA "
    },
    {
      "Location ID": "67853",
      "Location Name": "INGALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 S MAIN ST INGALLS KS 67853 USA "
    },
    {
      "Location ID": "67854",
      "Location Name": "JETMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 320 BOWLUS ST JETMORE KS 67854 USA "
    },
    {
      "Location ID": "67855",
      "Location Name": "JOHNSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 S MAIN ST JOHNSON KS 67855 USA "
    },
    {
      "Location ID": "67857",
      "Location Name": "KENDALL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 102 W B ST KENDALL KS 67857 USA "
    },
    {
      "Location ID": "67859",
      "Location Name": "KISMET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 506 MAIN ST KISMET KS 67859 USA "
    },
    {
      "Location ID": "67860",
      "Location Name": "LAKIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 E LINCOLN AVE LAKIN KS 67860 USA "
    },
    {
      "Location ID": "67861",
      "Location Name": "LEOTI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 N 4TH ST LEOTI KS 67861 USA "
    },
    {
      "Location ID": "67862",
      "Location Name": "MANTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 216 N MAIN ST MANTER KS 67862 USA "
    },
    {
      "Location ID": "67864",
      "Location Name": "MEADE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E WEST PLAINS ST MEADE KS 67864 USA "
    },
    {
      "Location ID": "67865",
      "Location Name": "MINNEOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 S MAIN ST MINNEOLA KS 67865 USA "
    },
    {
      "Location ID": "67867",
      "Location Name": "MONTEZUMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 N AZTEC ST MONTEZUMA KS 67867 USA "
    },
    {
      "Location ID": "67869",
      "Location Name": "PLAINS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 416 GRAND AVE PLAINS KS 67869 USA "
    },
    {
      "Location ID": "67870",
      "Location Name": "SATANTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 SEQUOYAH ST SATANTA KS 67870 USA "
    },
    {
      "Location ID": "67871",
      "Location Name": "SCOTT CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 S MAIN ST SCOTT CITY KS 67871 USA "
    },
    {
      "Location ID": "67876",
      "Location Name": "SPEARVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 312 N MAIN ST SPEARVILLE KS 67876 USA "
    },
    {
      "Location ID": "67877",
      "Location Name": "SUBLETTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 S INMAN ST SUBLETTE KS 67877 USA "
    },
    {
      "Location ID": "67878",
      "Location Name": "SYRACUSE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 200 N GATES ST SYRACUSE KS 67878 USA "
    },
    {
      "Location ID": "67879",
      "Location Name": "TRIBUNE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 123 W GREELEY AVE TRIBUNE KS 67879 USA "
    },
    {
      "Location ID": "67880",
      "Location Name": "ULYSSES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 S BAUGHMAN ST ULYSSES KS 67880 USA "
    },
    {
      "Location ID": "67882",
      "Location Name": "WRIGHT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10894 MAIN ST WRIGHT KS 67882 USA "
    },
    {
      "Location ID": "678HP",
      "Location Name": "HIGH PLAINS PUBLISHING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1500 E WYATT EARP BLVD DODGE CITY KS 67801 USA "
    },
    {
      "Location ID": "680",
      "Location Name": "LOG OMAHA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1124 PACIFIC ST RM 212 OMAHA NE 68108 USA "
    },
    {
      "Location ID": "68025",
      "Location Name": "FREMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 348 E 6TH ST FREMONT NE 68025 USA "
    },
    {
      "Location ID": "68031",
      "Location Name": "HOOPER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 N MAIN ST HOOPER NE 68031 USA "
    },
    {
      "Location ID": "68044",
      "Location Name": "NICKERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 E CEDAR ST NICKERSON NE 68044 USA "
    },
    {
      "Location ID": "68057",
      "Location Name": "SCRIBNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 3RD ST SCRIBNER NE 68057 USA "
    },
    {
      "Location ID": "68063",
      "Location Name": "UEHLING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 406 MAIN ST UEHLING NE 68063 USA "
    },
    {
      "Location ID": "68072",
      "Location Name": "WINSLOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 ELKHORN ST WINSLOW NE 68072 USA "
    },
    {
      "Location ID": "680AN",
      "Location Name": "OMAHA PEAK ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 770 N 114TH ST OMAHA NE 68154 USA "
    },
    {
      "Location ID": "680FZ",
      "Location Name": "LOG OMAHA NE PSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14620 GOLD COAST RD STE 400 OMAHA NE 68138 USA "
    },
    {
      "Location ID": "680MC",
      "Location Name": "OMAHA (NE) MCC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13593 GILES RD OMAHA NE 68138 USA "
    },
    {
      "Location ID": "680NX",
      "Location Name": "LOG OMAHA NE MCA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11850 PORTAL RD LA VISTA NE 68128 USA "
    },
    {
      "Location ID": "681BA",
      "Location Name": "BOYSTOWN PEAK ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8922 J ST OMAHA NE 68127 USA "
    },
    {
      "Location ID": "681CA",
      "Location Name": "OMAHA CHRISTMAS ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 155 IDA ST OMAHA NE 68110 USA "
    },
    {
      "Location ID": "681CP",
      "Location Name": "FDR CROWNPOINT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 805 CROWN POINT AVE OMAHA NE 68110 USA "
    },
    {
      "Location ID": "681FD",
      "Location Name": "FIRST DATA RESOURCES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7302 PACIFIC ST OMAHA NE 68114 USA "
    },
    {
      "Location ID": "681PP",
      "Location Name": "PAPILLION PEAK ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5750 S 77TH ST OMAHA NE 68127 USA "
    },
    {
      "Location ID": "681PS",
      "Location Name": "PITNEY BOWES PRESORT SVCS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10110 I ST OMAHA NE 68127 USA "
    },
    {
      "Location ID": "683",
      "Location Name": "LOG LINCOLN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 700 R ST LINCOLN NE 68501 USA "
    },
    {
      "Location ID": "68305",
      "Location Name": "AUBURN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1320 COURTHOUSE AVE AUBURN NE 68305 USA "
    },
    {
      "Location ID": "68309",
      "Location Name": "BARNESTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 GRAND AVE BARNESTON NE 68309 USA "
    },
    {
      "Location ID": "68310",
      "Location Name": "BEATRICE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 425 N 6TH ST BEATRICE NE 68310 USA "
    },
    {
      "Location ID": "68318",
      "Location Name": "BLUE SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 E BROAD ST BLUE SPRINGS NE 68318 USA "
    },
    {
      "Location ID": "68323",
      "Location Name": "BURCHARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 2ND ST BURCHARD NE 68323 USA "
    },
    {
      "Location ID": "68326",
      "Location Name": "CARLETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 320 D ST CARLETON NE 68326 USA "
    },
    {
      "Location ID": "68331",
      "Location Name": "CORTLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 335 W 4TH ST CORTLAND NE 68331 USA "
    },
    {
      "Location ID": "68335",
      "Location Name": "DAVENPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S MAPLE AVE DAVENPORT NE 68335 USA "
    },
    {
      "Location ID": "68337",
      "Location Name": "DAWSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 516 RIDGE ST DAWSON NE 68337 USA "
    },
    {
      "Location ID": "68340",
      "Location Name": "DESHLER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 513 4TH ST DESHLER NE 68340 USA "
    },
    {
      "Location ID": "68342",
      "Location Name": "DILLER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 408 COMMERCIAL ST DILLER NE 68342 USA "
    },
    {
      "Location ID": "68350",
      "Location Name": "ENDICOTT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 N SCRIBNER ST ENDICOTT NE 68350 USA "
    },
    {
      "Location ID": "68352",
      "Location Name": "FAIRBURY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 D ST FAIRBURY NE 68352 USA "
    },
    {
      "Location ID": "68355",
      "Location Name": "FALLS CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1520 HARLAN ST FALLS CITY NE 68355 USA "
    },
    {
      "Location ID": "68361",
      "Location Name": "GENEVA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 N 9TH ST GENEVA NE 68361 USA "
    },
    {
      "Location ID": "68362",
      "Location Name": "GILEAD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 225 S MAIN ST GILEAD NE 68362 USA "
    },
    {
      "Location ID": "68370",
      "Location Name": "HEBRON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 145 N 5TH ST HEBRON NE 68370 USA "
    },
    {
      "Location ID": "68376",
      "Location Name": "HUMBOLDT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 335 W SQUARE ST HUMBOLDT NE 68376 USA "
    },
    {
      "Location ID": "68377",
      "Location Name": "JANSEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 BROAD ST JANSEN NE 68377 USA "
    },
    {
      "Location ID": "68379",
      "Location Name": "JULIAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 W ST JULIAN NE 68379 USA "
    },
    {
      "Location ID": "68381",
      "Location Name": "LIBERTY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 601 WEBSTER ST LIBERTY NE 68381 USA "
    },
    {
      "Location ID": "683LV",
      "Location Name": "LAMBERT VET SUPPLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 714 5TH ST FAIRBURY NE 68352 USA "
    },
    {
      "Location ID": "68410",
      "Location Name": "NEBRASKA CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 508 CENTRAL AVE NEBRASKA CITY NE 68410 USA "
    },
    {
      "Location ID": "68415",
      "Location Name": "ODELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 MAIN ST ODELL NE 68415 USA "
    },
    {
      "Location ID": "68422",
      "Location Name": "PICKRELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 222 AUSTIN ST PICKRELL NE 68422 USA "
    },
    {
      "Location ID": "68431",
      "Location Name": "RULO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 S 1ST ST RULO NE 68431 USA "
    },
    {
      "Location ID": "68436",
      "Location Name": "SHICKLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 N MARKET ST SHICKLEY NE 68436 USA "
    },
    {
      "Location ID": "68440",
      "Location Name": "STEELE CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W MAIN ST STEELE CITY NE 68440 USA "
    },
    {
      "Location ID": "68457",
      "Location Name": "VERDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 402 MAIN ST VERDON NE 68457 USA "
    },
    {
      "Location ID": "68466",
      "Location Name": "WYMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 W F ST WYMORE NE 68466 USA "
    },
    {
      "Location ID": "68507",
      "Location Name": "NORTHVIEW ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3120 BAIR AVE LINCOLN NE 68504 USA "
    },
    {
      "Location ID": "68516",
      "Location Name": "CHENEY RIDGE ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5301 RED ROCK LN LINCOLN NE 68516 USA "
    },
    {
      "Location ID": "686",
      "Location Name": "COLUMBUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1555 24TH AVENUE COLUMBUS NE 68601 USA "
    },
    {
      "Location ID": "68628",
      "Location Name": "CLARKS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 N GREEN ST CLARKS NE 68628 USA "
    },
    {
      "Location ID": "68634",
      "Location Name": "DUNCAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 902 8TH ST DUNCAN NE 68634 USA "
    },
    {
      "Location ID": "68651",
      "Location Name": "OSCEOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 241 N MAIN ST OSCEOLA NE 68651 USA "
    },
    {
      "Location ID": "68654",
      "Location Name": "POLK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 N MAIN ST POLK NE 68654 USA "
    },
    {
      "Location ID": "68658",
      "Location Name": "RISING CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 750 MAIN ST RISING CITY NE 68658 USA "
    },
    {
      "Location ID": "68662",
      "Location Name": "SHELBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 N WALNUT ST SHELBY NE 68662 USA "
    },
    {
      "Location ID": "68663",
      "Location Name": "SILVER CREEK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 VINE ST SILVER CREEK NE 68663 USA "
    },
    {
      "Location ID": "68666",
      "Location Name": "STROMSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 E 4TH ST STROMSBURG NE 68666 USA "
    },
    {
      "Location ID": "687",
      "Location Name": "LOG NORFOLK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1100 S PINE INDUSTRIAL RD NORFOLK NE 68701 USA "
    },
    {
      "Location ID": "68710",
      "Location Name": "ALLEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 E 2ND ST ALLEN NE 68710 USA "
    },
    {
      "Location ID": "68723",
      "Location Name": "CARROLL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 520 MAIN ST CARROLL NE 68723 USA "
    },
    {
      "Location ID": "68728",
      "Location Name": "CONCORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 LINCOLN ST CONCORD NE 68728 USA "
    },
    {
      "Location ID": "68732",
      "Location Name": "DIXON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 2ND ST DIXON NE 68732 USA "
    },
    {
      "Location ID": "68740",
      "Location Name": "HOSKINS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 S MAIN ST HOSKINS NE 68740 USA "
    },
    {
      "Location ID": "68745",
      "Location Name": "LAUREL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 E 2ND ST LAUREL NE 68745 USA "
    },
    {
      "Location ID": "68787",
      "Location Name": "WAYNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 N PEARL ST WAYNE NE 68787 USA "
    },
    {
      "Location ID": "68790",
      "Location Name": "WINSIDE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 MAIN ST WINSIDE NE 68790 USA "
    },
    {
      "Location ID": "687MP",
      "Location Name": "MARATHON PRESS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1500 SQUARE TURN BLVD NORFOLK NE 68701 USA "
    },
    {
      "Location ID": "688",
      "Location Name": "LOG GRAND ISLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3835 W OLD POTASH HWY GRAND ISLAND NE 68803 USA "
    },
    {
      "Location ID": "68813",
      "Location Name": "ANSELMO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 E SMITH AVE ANSELMO NE 68813 USA "
    },
    {
      "Location ID": "68814",
      "Location Name": "ANSLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 806 MAIN ST ANSLEY NE 68814 USA "
    },
    {
      "Location ID": "68816",
      "Location Name": "ARCHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2121 ARCHER RD STOP 2 ARCHER NE 68816 USA "
    },
    {
      "Location ID": "68817",
      "Location Name": "ASHTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 CENTER AVE ASHTON NE 68817 USA "
    },
    {
      "Location ID": "68818",
      "Location Name": "AURORA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1105 L ST AURORA NE 68818 USA "
    },
    {
      "Location ID": "68820",
      "Location Name": "BOELUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 520 DELAWARE ST BOELUS NE 68820 USA "
    },
    {
      "Location ID": "68822",
      "Location Name": "BROKEN BOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 741 S D ST BROKEN BOW NE 68822 USA "
    },
    {
      "Location ID": "68823",
      "Location Name": "BURWELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 188 S 8TH AVE BURWELL NE 68823 USA "
    },
    {
      "Location ID": "68824",
      "Location Name": "CAIRO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 E MEDINA ST CAIRO NE 68824 USA "
    },
    {
      "Location ID": "68826",
      "Location Name": "CENTRAL CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1512 16TH ST CENTRAL CITY NE 68826 USA "
    },
    {
      "Location ID": "68827",
      "Location Name": "CHAPMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 9TH ST CHAPMAN NE 68827 USA "
    },
    {
      "Location ID": "68831",
      "Location Name": "DANNEBROG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 MILL ST S DANNEBROG NE 68831 USA "
    },
    {
      "Location ID": "68833",
      "Location Name": "DUNNING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 403 JEWETT AVE DUNNING NE 68833 USA "
    },
    {
      "Location ID": "68835",
      "Location Name": "ELBA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 608 PEARL ST ELBA NE 68835 USA "
    },
    {
      "Location ID": "68838",
      "Location Name": "FARWELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 LANDER AVE FARWELL NE 68838 USA "
    },
    {
      "Location ID": "68841",
      "Location Name": "GILTNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3013 N COMMERCIAL AVE GILTNER NE 68841 USA "
    },
    {
      "Location ID": "68842",
      "Location Name": "GREELEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 W ONEILL AVE GREELEY NE 68842 USA "
    },
    {
      "Location ID": "68843",
      "Location Name": "HAMPTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 N 3RD ST HAMPTON NE 68843 USA "
    },
    {
      "Location ID": "68846",
      "Location Name": "HORDVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 1/2 MAIN ST HORDVILLE NE 68846 USA "
    },
    {
      "Location ID": "68847",
      "Location Name": "KEARNEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2401 E AVE KEARNEY NE 68847 USA "
    },
    {
      "Location ID": "68852",
      "Location Name": "LITCHFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 N MAIN ST LITCHFIELD NE 68852 USA "
    },
    {
      "Location ID": "68853",
      "Location Name": "LOUP CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 137 S 7TH ST LOUP CITY NE 68853 USA "
    },
    {
      "Location ID": "68854",
      "Location Name": "MARQUETTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 404 RAILROAD ST MARQUETTE NE 68854 USA "
    },
    {
      "Location ID": "68855",
      "Location Name": "MASON CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 MAIN ST MASON CITY NE 68855 USA "
    },
    {
      "Location ID": "68856",
      "Location Name": "MERNA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 CENTER AVE MERNA NE 68856 USA "
    },
    {
      "Location ID": "68859",
      "Location Name": "NORTH LOUP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 W 1ST ST NORTH LOUP NE 68859 USA "
    },
    {
      "Location ID": "68862",
      "Location Name": "ORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1630 L ST ORD NE 68862 USA "
    },
    {
      "Location ID": "68864",
      "Location Name": "PALMER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 907 COMMERCIAL ST PALMER NE 68864 USA "
    },
    {
      "Location ID": "68865",
      "Location Name": "PHILLIPS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 515 WEST ST PHILLIPS NE 68865 USA "
    },
    {
      "Location ID": "68866",
      "Location Name": "PLEASANTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 W ELM ST PLEASANTON NE 68866 USA "
    },
    {
      "Location ID": "68869",
      "Location Name": "RAVENNA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 MILAN AVE RAVENNA NE 68869 USA "
    },
    {
      "Location ID": "68871",
      "Location Name": "ROCKVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 229 N LEY ST ROCKVILLE NE 68871 USA "
    },
    {
      "Location ID": "68872",
      "Location Name": "SAINT LIBORY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 SPRUCE ST SAINT LIBORY NE 68872 USA "
    },
    {
      "Location ID": "68873",
      "Location Name": "SAINT PAUL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 901 7TH ST SAINT PAUL NE 68873 USA "
    },
    {
      "Location ID": "68875",
      "Location Name": "SCOTIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 S MAIN ST SCOTIA NE 68875 USA "
    },
    {
      "Location ID": "68882",
      "Location Name": "WOLBACH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 CENTER AVE WOLBACH NE 68882 USA "
    },
    {
      "Location ID": "689",
      "Location Name": "HASTINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 N KANSAS AVE HASTINGS NE 68901 USA "
    },
    {
      "Location ID": "68934",
      "Location Name": "DEWEESE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 S DEWEESE AVE DEWEESE NE 68934 USA "
    },
    {
      "Location ID": "68935",
      "Location Name": "EDGAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 312 N C ST EDGAR NE 68935 USA "
    },
    {
      "Location ID": "68938",
      "Location Name": "FAIRFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 N D ST FAIRFIELD NE 68938 USA "
    },
    {
      "Location ID": "68941",
      "Location Name": "GLENVIL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 WINTERS AVE GLENVIL NE 68941 USA "
    },
    {
      "Location ID": "68943",
      "Location Name": "HARDY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 MAIN ST HARDY NE 68943 USA "
    },
    {
      "Location ID": "68961",
      "Location Name": "NELSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 363 S MAIN ST NELSON NE 68961 USA "
    },
    {
      "Location ID": "68974",
      "Location Name": "RUSKIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 940 MAIN ST RUSKIN NE 68974 USA "
    },
    {
      "Location ID": "68978",
      "Location Name": "SUPERIOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 E 4TH ST SUPERIOR NE 68978 USA "
    },
    {
      "Location ID": "689PA",
      "Location Name": "PRO AG PLUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 324 1/2 N C ST EDGAR NE 68935 USA "
    },
    {
      "Location ID": "690",
      "Location Name": "MC COOK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 411 W 3RD ST MC COOK NE 69001 USA "
    },
    {
      "Location ID": "69020",
      "Location Name": "BARTLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 COMMERCIAL ST BARTLEY NE 69020 USA "
    },
    {
      "Location ID": "69021",
      "Location Name": "BENKELMAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 505 CHIEF ST BENKELMAN NE 69021 USA "
    },
    {
      "Location ID": "69022",
      "Location Name": "CAMBRIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 710 PATTERSON ST CAMBRIDGE NE 69022 USA "
    },
    {
      "Location ID": "69023",
      "Location Name": "CHAMPION",
      "Time Zone Offset (Hours)": -2,
      "Address": " 380 MAIN ST CHAMPION NE 69023 USA "
    },
    {
      "Location ID": "69024",
      "Location Name": "CULBERTSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 407 WYOMING ST CULBERTSON NE 69024 USA "
    },
    {
      "Location ID": "69025",
      "Location Name": "CURTIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 CENTER AVE CURTIS NE 69025 USA "
    },
    {
      "Location ID": "69026",
      "Location Name": "DANBURY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 GRANDVILLE AVE DANBURY NE 69026 USA "
    },
    {
      "Location ID": "69027",
      "Location Name": "ENDERS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 580 PIONEER ST ENDERS NE 69027 USA "
    },
    {
      "Location ID": "69028",
      "Location Name": "EUSTIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 N MAIN ST EUSTIS NE 69028 USA "
    },
    {
      "Location ID": "69029",
      "Location Name": "FARNAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 221 MAIN ST FARNAM NE 69029 USA "
    },
    {
      "Location ID": "69030",
      "Location Name": "HAIGLER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 149 N PORTER AVE HAIGLER NE 69030 USA "
    },
    {
      "Location ID": "69033",
      "Location Name": "IMPERIAL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 523 WELLINGTON ST IMPERIAL NE 69033 USA "
    },
    {
      "Location ID": "69034",
      "Location Name": "INDIANOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 N 4TH ST INDIANOLA NE 69034 USA "
    },
    {
      "Location ID": "69037",
      "Location Name": "MAX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 240 MAIN ST MAX NE 69037 USA "
    },
    {
      "Location ID": "69038",
      "Location Name": "MAYWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 S COMMERCIAL ST MAYWOOD NE 69038 USA "
    },
    {
      "Location ID": "69039",
      "Location Name": "MOOREFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 N ASH ST MOOREFIELD NE 69039 USA "
    },
    {
      "Location ID": "69040",
      "Location Name": "PALISADE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 N MAIN ST PALISADE NE 69040 USA "
    },
    {
      "Location ID": "69043",
      "Location Name": "STRATTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 310 BAILEY ST STRATTON NE 69043 USA "
    },
    {
      "Location ID": "69044",
      "Location Name": "TRENTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 402 MAIN ST TRENTON NE 69044 USA "
    },
    {
      "Location ID": "69045",
      "Location Name": "WAUNETA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 215 N TECUMSEH WAUNETA NE 69045 USA "
    },
    {
      "Location ID": "69046",
      "Location Name": "WILSONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 706 MAIN ST WILSONVILLE NE 69046 USA "
    },
    {
      "Location ID": "691",
      "Location Name": "LOG NORTH PLATTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1302 INDUSTRIAL AVE NORTH PLATTE NE 69101 USA "
    },
    {
      "Location ID": "69162",
      "Location Name": "SIDNEY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 844 ILLINOIS ST SIDNEY NE 69162 USA "
    },
    {
      "Location ID": "693",
      "Location Name": "ALLIANCE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 401 BOX BUTTE AVE ALLIANCE NE 69301 USA "
    },
    {
      "Location ID": "69331",
      "Location Name": "ANGORA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 100 ROAD 118 ANGORA NE 69331 USA "
    },
    {
      "Location ID": "69333",
      "Location Name": "ASHBY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 106 MAIN ST ASHBY NE 69333 USA "
    },
    {
      "Location ID": "69334",
      "Location Name": "BAYARD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 517 MAIN ST BAYARD NE 69334 USA "
    },
    {
      "Location ID": "69336",
      "Location Name": "BRIDGEPORT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 114 W 10TH ST BRIDGEPORT NE 69336 USA "
    },
    {
      "Location ID": "69337",
      "Location Name": "CHADRON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 278 MAIN ST CHADRON NE 69337 USA "
    },
    {
      "Location ID": "69340",
      "Location Name": "ELLSWORTH",
      "Time Zone Offset (Hours)": -2,
      "Address": " 100 MAIN ST ELLSWORTH NE 69340 USA "
    },
    {
      "Location ID": "69350",
      "Location Name": "HYANNIS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 116 S MAIN AVE HYANNIS NE 69350 USA "
    },
    {
      "Location ID": "69351",
      "Location Name": "LAKESIDE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1331 STATE HIGHWAY 250 LAKESIDE NE 69351 USA "
    },
    {
      "Location ID": "69353",
      "Location Name": "MCGREW",
      "Time Zone Offset (Hours)": -2,
      "Address": " 100 MAIN ST MCGREW NE 69353 USA "
    },
    {
      "Location ID": "69355",
      "Location Name": "MELBETA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 101 2ND ST MELBETA NE 69355 USA "
    },
    {
      "Location ID": "69356",
      "Location Name": "MINATARE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 320 MAIN ST MINATARE NE 69356 USA "
    },
    {
      "Location ID": "69361",
      "Location Name": "SCOTTSBLUFF",
      "Time Zone Offset (Hours)": -2,
      "Address": " 112 W 20TH ST SCOTTSBLUFF NE 69361 USA "
    },
    {
      "Location ID": "69366",
      "Location Name": "WHITMAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 100 CORROTHER ST WHITMAN NE 69366 USA "
    },
    {
      "Location ID": "700",
      "Location Name": "NEW ORLEANS P&DC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 701 LOYOLA AVE NEW ORLEANS LA 70113 USA "
    },
    {
      "Location ID": "70036",
      "Location Name": "BARATARIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4164 PRIVATEER BLVD BARATARIA LA 70036 USA "
    },
    {
      "Location ID": "70053",
      "Location Name": "GRETNA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 406 GRETNA BLVD GRETNA LA 70053 USA "
    },
    {
      "Location ID": "70058",
      "Location Name": "HARVEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2801 MANHATTAN BLVD HARVEY LA 70058 USA "
    },
    {
      "Location ID": "70067",
      "Location Name": "LAFITTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2630 JEAN LAFITTE BLVD LAFITTE LA 70067 USA "
    },
    {
      "Location ID": "70072",
      "Location Name": "MARRERO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5351 LAPALCO BLVD MARRERO LA 70072 USA "
    },
    {
      "Location ID": "70094",
      "Location Name": "WESTWEGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1335 WESTBANK EXPY WESTWEGO LA 70094 USA "
    },
    {
      "Location ID": "700MN",
      "Location Name": "MR NOLA'S GLITTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1700 PROMENADE BLVD STE 1101 MARRERO LA 70072 USA "
    },
    {
      "Location ID": "701AX",
      "Location Name": "LOG SAINT ROSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 RIVERBEND DR SAINT ROSE LA 70087 USA "
    },
    {
      "Location ID": "70301",
      "Location Name": "THIBODAUX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 910 CANAL BLVD THIBODAUX LA 70301 USA "
    },
    {
      "Location ID": "70339",
      "Location Name": "PIERRE PART",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3511 HIGHWAY 70 S PIERRE PART LA 70339 USA "
    },
    {
      "Location ID": "70341",
      "Location Name": "BELLE ROSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7101 HIGHWAY 1 BELLE ROSE LA 70341 USA "
    },
    {
      "Location ID": "70346",
      "Location Name": "DONALDSONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 IBERVILLE ST DONALDSONVILLE LA 70346 USA "
    },
    {
      "Location ID": "70360",
      "Location Name": "HOUMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 425 LAFAYETTE ST OFC HOUMA LA 70360 USA "
    },
    {
      "Location ID": "70391",
      "Location Name": "PAINCOURTVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6075 HIGHWAY 1 PAINCOURTVILLE LA 70391 USA "
    },
    {
      "Location ID": "70393",
      "Location Name": "PLATTENVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 HIGHWAY 70 SPUR PLATTENVILLE LA 70393 USA "
    },
    {
      "Location ID": "70449",
      "Location Name": "MAUREPAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 23839 HIGHWAY 22 MAUREPAS LA 70449 USA "
    },
    {
      "Location ID": "70462",
      "Location Name": "SPRINGFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 32170 2ND ST SPRINGFIELD LA 70462 USA "
    },
    {
      "Location ID": "705",
      "Location Name": "LAFAYETTE P&DF, LA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1105 MOSS ST LAFAYETTE LA 70501 USA "
    },
    {
      "Location ID": "70513",
      "Location Name": "AVERY ISLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 MAIN RD AVERY ISLAND LA 70513 USA "
    },
    {
      "Location ID": "70514",
      "Location Name": "BALDWIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 604 MAIN ST BALDWIN LA 70514 USA "
    },
    {
      "Location ID": "70518",
      "Location Name": "BROUSSARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4200 HIGHWAY 90 E BROUSSARD LA 70518 USA "
    },
    {
      "Location ID": "70520",
      "Location Name": "CARENCRO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 809 VETERANS DR CARENCRO LA 70520 USA "
    },
    {
      "Location ID": "70535",
      "Location Name": "EUNICE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 250 W LAUREL AVE EUNICE LA 70535 USA "
    },
    {
      "Location ID": "70538",
      "Location Name": "FRANKLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 420 WILLOW ST FRANKLIN LA 70538 USA "
    },
    {
      "Location ID": "70544",
      "Location Name": "JEANERETTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1614 MAIN ST JEANERETTE LA 70544 USA "
    },
    {
      "Location ID": "70554",
      "Location Name": "MAMOU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 MAIN ST MAMOU LA 70554 USA "
    },
    {
      "Location ID": "70560",
      "Location Name": "NEW IBERIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 817 E DALE ST NEW IBERIA LA 70560 USA "
    },
    {
      "Location ID": "70569",
      "Location Name": "LYDIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4013 DARNELL RD LYDIA LA 70569 USA "
    },
    {
      "Location ID": "70570",
      "Location Name": "OPELOUSAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 152 WALLIOR ST OPELOUSAS LA 70570 USA "
    },
    {
      "Location ID": "70586",
      "Location Name": "VILLE PLATTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 W MAGNOLIA ST VILLE PLATTE LA 70586 USA "
    },
    {
      "Location ID": "707",
      "Location Name": "BATON ROUGE P&DC, LA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8101 BLUEBONNET BLVD BATON ROUGE LA 70810 USA "
    },
    {
      "Location ID": "70710",
      "Location Name": "ADDIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4260 FORET ST ADDIS LA 70710 USA "
    },
    {
      "Location ID": "70711",
      "Location Name": "ALBANY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 29683 S. MONTPELIER RD ALBANY LA 70711 USA "
    },
    {
      "Location ID": "70712",
      "Location Name": "ANGOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11200 MAIN ST ANGOLA LA 70712 USA "
    },
    {
      "Location ID": "70714",
      "Location Name": "BAKER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3009 RAY WEILAND DR BAKER LA 70714 USA "
    },
    {
      "Location ID": "70715",
      "Location Name": "BATCHELOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4992 LA HIGHWAY 1 BATCHELOR LA 70715 USA "
    },
    {
      "Location ID": "70718",
      "Location Name": "BRITTANY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1830 EAST HIGHWAY 30 BRITTANY LA 70718 USA "
    },
    {
      "Location ID": "70719",
      "Location Name": "BRUSLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 315 E MAIN ST BRUSLY LA 70719 USA "
    },
    {
      "Location ID": "70721",
      "Location Name": "CARVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5715 HIGHWAY 75 CARVILLE LA 70721 USA "
    },
    {
      "Location ID": "70722",
      "Location Name": "CLINTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1049 PLANK ROAD CLINTON LA 70722 USA "
    },
    {
      "Location ID": "70723",
      "Location Name": "CONVENT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6574 LA HWY 44 CONVENT LA 70723 USA "
    },
    {
      "Location ID": "70725",
      "Location Name": "DARROW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 37142 HIGHWAY 22 DARROW LA 70725 USA "
    },
    {
      "Location ID": "70726",
      "Location Name": "DENHAM SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 140 DEL ORLEANS DENHAM  SPRINGS LA 70726 USA "
    },
    {
      "Location ID": "70728",
      "Location Name": "DUPLESSIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 39265 HIGHWAY 621 DUPLESSIS LA 70728 USA "
    },
    {
      "Location ID": "70729",
      "Location Name": "ERWINVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4710 POYDRAS BAYOU RD ERWINVILLE LA 70729 USA "
    },
    {
      "Location ID": "70730",
      "Location Name": "ETHEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3457 HIGHWAY 19 ETHEL LA 70730 USA "
    },
    {
      "Location ID": "70732",
      "Location Name": "FORDOCHE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5246 FORDOCHE FORDOCHE LA 70732 USA "
    },
    {
      "Location ID": "70733",
      "Location Name": "FRENCH SETTLEMENT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16169 LA HIGHWAY 16 FRENCH SETTLEMENT LA 70733 USA "
    },
    {
      "Location ID": "70734",
      "Location Name": "GEISMAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6223 HIGHWAY 73 GEISMAR LA 70734 USA "
    },
    {
      "Location ID": "70736",
      "Location Name": "GLYNN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14146 HWY 416 GLYNN LA 70736 USA "
    },
    {
      "Location ID": "70737",
      "Location Name": "GONZALES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1706 S. BURNSIDE AVE GONZALES LA 70737 USA "
    },
    {
      "Location ID": "70739",
      "Location Name": "GREENWELL SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 22801 GREENWELL SPRINGS RD GREENWELL SPRINGS LA 70739 USA "
    },
    {
      "Location ID": "70740",
      "Location Name": "GROSSE TETE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108015 WILLOW ST GROSSE TETE LA 70740 USA "
    },
    {
      "Location ID": "70743",
      "Location Name": "HESTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4163 KAHN ST HESTER LA 70743 USA "
    },
    {
      "Location ID": "70744",
      "Location Name": "HOLDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 30165 LA 441 HWY HOLDEN LA 70744 USA "
    },
    {
      "Location ID": "70747",
      "Location Name": "INNIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6541 LA HIGHWAY 1 INNIS LA 70747 USA "
    },
    {
      "Location ID": "70748",
      "Location Name": "JACKSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3016 BANK ST JACKSON LA 70748 USA "
    },
    {
      "Location ID": "70749",
      "Location Name": "JARREAU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5341 BLVD D ISLE JARREAU LA 70749 USA "
    },
    {
      "Location ID": "70750",
      "Location Name": "KROTZ SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 427 FLORIDA ST KROTZ SPRINGS LA 70750 USA "
    },
    {
      "Location ID": "70752",
      "Location Name": "LAKELAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12158 LA HIGHWAY 416 LAKELAND LA 70752 USA "
    },
    {
      "Location ID": "70753",
      "Location Name": "LETTSWORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9863 LA HIGHWAY 1 LETTSWORTH LA 70753 USA "
    },
    {
      "Location ID": "70754",
      "Location Name": "LIVINGSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 29490 S FROST LIVINGSTON LA 70754 USA "
    },
    {
      "Location ID": "70755",
      "Location Name": "LIVONIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3050 LA HIGHWAY 78 LIVONIA LA 70755 USA "
    },
    {
      "Location ID": "70756",
      "Location Name": "LOTTIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5422 LA HWY 81 E LOTTIE LA 70756 USA "
    },
    {
      "Location ID": "70757",
      "Location Name": "MARINGOUIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 77330 ST LANDRY MARINGOUIN LA 70757 USA "
    },
    {
      "Location ID": "70759",
      "Location Name": "MORGANZA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1081 S LA HWY 1 MORGANZA LA 70759 USA "
    },
    {
      "Location ID": "70760",
      "Location Name": "NEW ROADS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 148 NEW ROADS ST NEW ROADS LA 70760 USA "
    },
    {
      "Location ID": "70761",
      "Location Name": "NORWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3822 CAMELLIA ST NORWOOD LA 70761 USA "
    },
    {
      "Location ID": "70762",
      "Location Name": "OSCAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8105 FALSE RIVER RD OSCAR LA 70762 USA "
    },
    {
      "Location ID": "70763",
      "Location Name": "PAULINA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2388 HWY 44 PAULINA LA 70763 USA "
    },
    {
      "Location ID": "70764",
      "Location Name": "PLAQUEMINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 58015 CANAL ST PLAQUEMINE LA 70764 USA "
    },
    {
      "Location ID": "70767",
      "Location Name": "PORT ALLEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 600 COURT ST PORT ALLEN LA 70767 USA "
    },
    {
      "Location ID": "70769",
      "Location Name": "PRAIRIEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15655 AIRLINE HWY PRAIRIEVILLE LA 70769 USA "
    },
    {
      "Location ID": "70770",
      "Location Name": "PRIDE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16524 PRIDE PORT HUDSON RD PRIDE LA 70770 USA "
    },
    {
      "Location ID": "70772",
      "Location Name": "ROSEDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15710 HIGHWAY 77 ROSEDALE LA 70772 USA "
    },
    {
      "Location ID": "70773",
      "Location Name": "ROUGON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13236 LA HWY 416 ROUGON LA 70773 USA "
    },
    {
      "Location ID": "70774",
      "Location Name": "SAINT AMANT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 44473 HIGHWAY 429 SAINT AMANT LA 70774 USA "
    },
    {
      "Location ID": "70775",
      "Location Name": "SAINT FRANCISVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5613 COMMERCE ST SAINT FRANCISVILLE LA 70775 USA "
    },
    {
      "Location ID": "70776",
      "Location Name": "SAINT GABRIEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6080 HIGHWAY 74 SAINT GABRIEL LA 70776 USA "
    },
    {
      "Location ID": "70777",
      "Location Name": "SLAUGHTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 804 W RAIROAD SLAUGHTER LA 70777 USA "
    },
    {
      "Location ID": "70778",
      "Location Name": "SORRENTO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8213 JOHN LEBLANC BLVD SORRENTO LA 70778 USA "
    },
    {
      "Location ID": "70780",
      "Location Name": "SUNSHINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2155 BESSON LN SUNSHINE LA 70780 USA "
    },
    {
      "Location ID": "70782",
      "Location Name": "TUNICA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16630 TUNICA TRCE TUNICA LA 70782 USA "
    },
    {
      "Location ID": "70783",
      "Location Name": "VENTRESS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7741 LEGION RD VENTRESS LA 70783 USA "
    },
    {
      "Location ID": "70784",
      "Location Name": "WAKEFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11206 U.S HIGHWAY 61 WAKEFIELD LA 70784 USA "
    },
    {
      "Location ID": "70785",
      "Location Name": "WALKER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10053 FLORIDA BLVD WALKER LA 70785 USA "
    },
    {
      "Location ID": "70786",
      "Location Name": "WATSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8040 WATSON  CIRCLE WATSON LA 70786 USA "
    },
    {
      "Location ID": "70787",
      "Location Name": "WEYANOKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6220 OVIDA IRONDALE RD WEYANOKE LA 70787 USA "
    },
    {
      "Location ID": "70788",
      "Location Name": "WHITE CASTLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 32495 LEONA AVE WHITE CASTLE LA 70788 USA "
    },
    {
      "Location ID": "70789",
      "Location Name": "WILSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10029 HIGHWAY 19 WILSON LA 70789 USA "
    },
    {
      "Location ID": "70791",
      "Location Name": "ZACHARY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6001 MAIN ST ZACHARY LA 70791 USA "
    },
    {
      "Location ID": "70801",
      "Location Name": "MAIN OFFICE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 750 FLORIDA ST BATON ROUGE LA 70801 USA "
    },
    {
      "Location ID": "70804",
      "Location Name": "CAPITOL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 N 3RD ST BATON ROUGE LA 70802 USA "
    },
    {
      "Location ID": "70805",
      "Location Name": "ISTROUMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5200 LONGFELLOW DR BATON ROUGE LA 70805 USA "
    },
    {
      "Location ID": "70806",
      "Location Name": "AUDUBON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4500 GOVERNMENT ST BATON ROUGE LA 70806 USA "
    },
    {
      "Location ID": "70807",
      "Location Name": "SCOTLANDVILLE STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7980 PLANK RD BATON ROUGE LA 70811 USA "
    },
    {
      "Location ID": "70808",
      "Location Name": "SOUTHEAST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4535 BENNINGTON AVE BATON ROUGE LA 70808 USA "
    },
    {
      "Location ID": "70809",
      "Location Name": "COMMERCE PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10380 PERKINS RD BATON ROUGE LA 70810 USA "
    },
    {
      "Location ID": "70814",
      "Location Name": "BROADVIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 235 MARILYN DR BATON ROUGE LA 70815 USA "
    },
    {
      "Location ID": "70815",
      "Location Name": "OLD HAMMOND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10936 OLD HAMMOND HWY BATON ROUGE LA 70816 USA "
    },
    {
      "Location ID": "70816",
      "Location Name": "WOODLAWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4700 MILWOOD DR BATON ROUGE LA 70817 USA "
    },
    {
      "Location ID": "70818",
      "Location Name": "CENTRAL FINANCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13515 HOOPER RD BATON ROUGE LA 70818 USA "
    },
    {
      "Location ID": "70837",
      "Location Name": "CENTRAL FINANCE UNIT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13513 PLANK RD BATON ROUGE LA 70837 USA "
    },
    {
      "Location ID": "708JS",
      "Location Name": "JIMMY SWAGGART MINISTRIES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8919 WORLD MINISTRY AVE BATON ROUGE LA 70810 USA "
    },
    {
      "Location ID": "70H",
      "Location Name": "BATON ROUGE HASP SW AREA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6735 EXCHEQUER DRIVE BATON ROUGE LA 70809 USA "
    },
    {
      "Location ID": "710",
      "Location Name": "SHREVEPORT P&DC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2400 TEXAS AVE SHREVEPORT LA 71102 USA "
    },
    {
      "Location ID": "71001",
      "Location Name": "ARCADIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1979 N RAILROAD AVE ARCADIA LA 71001 USA "
    },
    {
      "Location ID": "71002",
      "Location Name": "ASHLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 521 HIGHWAY 153 ASHLAND LA 71002 USA "
    },
    {
      "Location ID": "71003",
      "Location Name": "ATHENS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 258 HIGHWAY 805 ATHENS LA 71003 USA "
    },
    {
      "Location ID": "71004",
      "Location Name": "BELCHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 714 RAILROAD ST BELCHER LA 71004 USA "
    },
    {
      "Location ID": "71006",
      "Location Name": "BENTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 180 BURT BLVD BENTON LA 71006 USA "
    },
    {
      "Location ID": "71007",
      "Location Name": "BETHANY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10085 HIGHWAY 79 BETHANY LA 71007 USA "
    },
    {
      "Location ID": "71008",
      "Location Name": "BIENVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 25549 HIGHWAY 9 BIENVILLE LA 71008 USA "
    },
    {
      "Location ID": "71009",
      "Location Name": "BLANCHARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 MAIN ST BLANCHARD LA 71009 USA "
    },
    {
      "Location ID": "71016",
      "Location Name": "CASTOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8992 HIGHWAY 4 CASTOR LA 71016 USA "
    },
    {
      "Location ID": "71018",
      "Location Name": "COTTON VALLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 439 RESIDENT ST COTTON VALLEY LA 71018 USA "
    },
    {
      "Location ID": "71019",
      "Location Name": "COUSHATTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5036 CUT OFF RD COUSHATTA LA 71019 USA "
    },
    {
      "Location ID": "71021",
      "Location Name": "CULLEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 511 COYLE AVE CULLEN LA 71021 USA "
    },
    {
      "Location ID": "71023",
      "Location Name": "DOYLINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 PILGRIM REST RD DOYLINE LA 71023 USA "
    },
    {
      "Location ID": "71024",
      "Location Name": "DUBBERLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3193 HIGHWAY 531 DUBBERLY LA 71024 USA "
    },
    {
      "Location ID": "71027",
      "Location Name": "FRIERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5400 HIGHWAY 175 FRIERSON LA 71027 USA "
    },
    {
      "Location ID": "71028",
      "Location Name": "GIBSLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1259 S 3RD ST GIBSLAND LA 71028 USA "
    },
    {
      "Location ID": "71029",
      "Location Name": "GILLIAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5517 1ST ST GILLIAM LA 71029 USA "
    },
    {
      "Location ID": "71030",
      "Location Name": "GLOSTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 MAIN ST GLOSTER LA 71030 USA "
    },
    {
      "Location ID": "71031",
      "Location Name": "GOLDONNA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3153 MAIN ST GOLDONNA LA 71031 USA "
    },
    {
      "Location ID": "71032",
      "Location Name": "GRAND CANE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 719 MAIN ST GRAND CANE LA 71032 USA "
    },
    {
      "Location ID": "71033",
      "Location Name": "GREENWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7070 ADAMS LN GREENWOOD LA 71033 USA "
    },
    {
      "Location ID": "71034",
      "Location Name": "HALL SUMMIT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7101 CARTER AVE HALL SUMMIT LA 71034 USA "
    },
    {
      "Location ID": "71037",
      "Location Name": "HAUGHTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 399 W MCKINLEY AVE HAUGHTON LA 71037 USA "
    },
    {
      "Location ID": "71038",
      "Location Name": "HAYNESVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10007 HIGHWAY 79 HAYNESVILLE LA 71038 USA "
    },
    {
      "Location ID": "71039",
      "Location Name": "HEFLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 N MAIN ST HEFLIN LA 71039 USA "
    },
    {
      "Location ID": "71040",
      "Location Name": "HOMER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 612 N MAIN ST HOMER LA 71040 USA "
    },
    {
      "Location ID": "71043",
      "Location Name": "HOSSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14836 PARKWAY DR HOSSTON LA 71043 USA "
    },
    {
      "Location ID": "71044",
      "Location Name": "IDA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6994 E MAGNOLIA LN IDA LA 71044 USA "
    },
    {
      "Location ID": "71045",
      "Location Name": "JAMESTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4143 E FRONT ST JAMESTOWN LA 71045 USA "
    },
    {
      "Location ID": "71046",
      "Location Name": "KEATCHIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9892 HIGHWAY 5 KEATCHIE LA 71046 USA "
    },
    {
      "Location ID": "71047",
      "Location Name": "KEITHVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12395 MANSFIELD RD KEITHVILLE LA 71047 USA "
    },
    {
      "Location ID": "71049",
      "Location Name": "LOGANSPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 N 4TH ST LOGANSPORT LA 71049 USA "
    },
    {
      "Location ID": "71051",
      "Location Name": "ELM GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10718 HIGHWAY 71 ELM GROVE LA 71051 USA "
    },
    {
      "Location ID": "71052",
      "Location Name": "MANSFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 CROSBY ST MANSFIELD LA 71052 USA "
    },
    {
      "Location ID": "71055",
      "Location Name": "MINDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 S MONROE ST MINDEN LA 71055 USA "
    },
    {
      "Location ID": "71060",
      "Location Name": "MOORINGSPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 CROOM ST MOORINGSPORT LA 71060 USA "
    },
    {
      "Location ID": "71061",
      "Location Name": "OIL CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 FURMAN ST OIL CITY LA 71061 USA "
    },
    {
      "Location ID": "71063",
      "Location Name": "PELICAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 151 JACKSON ST PELICAN LA 71063 USA "
    },
    {
      "Location ID": "71064",
      "Location Name": "PLAIN DEALING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 W MARY LEE ST PLAIN DEALING LA 71064 USA "
    },
    {
      "Location ID": "71065",
      "Location Name": "PLEASANT HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2212 W MAIN ST PLEASANT HILL LA 71065 USA "
    },
    {
      "Location ID": "71066",
      "Location Name": "POWHATAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1812 HIGHWAY 1 N POWHATAN LA 71066 USA "
    },
    {
      "Location ID": "71067",
      "Location Name": "PRINCETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5491 HIGHWAY 80 PRINCETON LA 71067 USA "
    },
    {
      "Location ID": "71068",
      "Location Name": "RINGGOLD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2325 MILL ST RINGGOLD LA 71068 USA "
    },
    {
      "Location ID": "71069",
      "Location Name": "RODESSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20065 W FRONT ST RODESSA LA 71069 USA "
    },
    {
      "Location ID": "71070",
      "Location Name": "SALINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1032 COOPER ST SALINE LA 71070 USA "
    },
    {
      "Location ID": "71071",
      "Location Name": "SAREPTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24468 HIGHWAY 371 SAREPTA LA 71071 USA "
    },
    {
      "Location ID": "71073",
      "Location Name": "SIBLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 219 N MAIN ST SIBLEY LA 71073 USA "
    },
    {
      "Location ID": "71075",
      "Location Name": "SPRINGHILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 N GILES ST SPRINGHILL LA 71075 USA "
    },
    {
      "Location ID": "71078",
      "Location Name": "STONEWALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 986 HIGHWAY 171 STONEWALL LA 71078 USA "
    },
    {
      "Location ID": "71080",
      "Location Name": "TAYLOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 SHORT ST TAYLOR LA 71080 USA "
    },
    {
      "Location ID": "71082",
      "Location Name": "VIVIAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 W LOUISIANA AVE VIVIAN LA 71082 USA "
    },
    {
      "Location ID": "710FZ",
      "Location Name": "LOG SHREVEPORT LA PSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7600 ANTOINE BLVD SHREVEPORT LA 71129 USA "
    },
    {
      "Location ID": "71101",
      "Location Name": "DOWNTOWN SHREVEPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 333 MILAM ST SHREVEPORT LA 71101 USA "
    },
    {
      "Location ID": "71104",
      "Location Name": "CENTENARY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 326 KINGS HWY SHREVEPORT LA 71104 USA "
    },
    {
      "Location ID": "71105",
      "Location Name": "SOUTHFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1925 E 70TH ST SHREVEPORT LA 71105 USA "
    },
    {
      "Location ID": "71106",
      "Location Name": "LYNBROOK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 241 LYNBROOK BLVD SHREVEPORT LA 71106 USA "
    },
    {
      "Location ID": "71107",
      "Location Name": "INDUSTRIAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1446 HAWN AVE SHREVEPORT LA 71107 USA "
    },
    {
      "Location ID": "71110",
      "Location Name": "BARKSDALE AIR FORCE BASE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 50 VANDENBERG AVE STE 135 BARNARDSVILLE LA 71110 USA "
    },
    {
      "Location ID": "71111",
      "Location Name": "BOSSIER CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 BENTON RD BOSSIER CITY LA 71111 USA "
    },
    {
      "Location ID": "71112",
      "Location Name": "PLANTATION STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4310 BARKSDALE BLVD BOSSIER CITY LA 71112 USA "
    },
    {
      "Location ID": "71118",
      "Location Name": "MERIWETHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8340 MANSFIELD RD SHREVEPORT LA 71108 USA "
    },
    {
      "Location ID": "71129",
      "Location Name": "HUNTINGTON STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6719 PINES RD SHREVEPORT LA 71129 USA "
    },
    {
      "Location ID": "711AN",
      "Location Name": "SHREVEPORT ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7600 GENERAL MOTORS BLVD SHREVEPORT LA 71129 USA "
    },
    {
      "Location ID": "712",
      "Location Name": "MONROE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 STERLINGTON RD MONROE LA 71203 USA "
    },
    {
      "Location ID": "71201",
      "Location Name": "DOWNTOWN MONROE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 NORTH 3RD ST MONROE LA 71201 USA "
    },
    {
      "Location ID": "71203",
      "Location Name": "MONROE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 STERLINGTON RD MONROE LA 71203 USA "
    },
    {
      "Location ID": "71207",
      "Location Name": "NORTHSIDE STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1701 SHANNON ST MONROE LA 71201 USA "
    },
    {
      "Location ID": "71210",
      "Location Name": "DOWNTOWN MONROE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 NORTH 3RD ST MONROE LA 71201 USA "
    },
    {
      "Location ID": "71218",
      "Location Name": "ARCHIBALD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3744 HIGHWAY 15 ARCHIBALD LA 71218 USA "
    },
    {
      "Location ID": "71219",
      "Location Name": "BASKIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1179 HIGHWAY 15 BASKIN LA 71219 USA "
    },
    {
      "Location ID": "71220",
      "Location Name": "BASTROP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 N WASHINGTON ST BASTROP LA 71220 USA "
    },
    {
      "Location ID": "71222",
      "Location Name": "BERNICE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 74 6TH ST BERNICE LA 71222 USA "
    },
    {
      "Location ID": "71223",
      "Location Name": "BONITA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15392 BONITA AVE BONITA LA 71223 USA "
    },
    {
      "Location ID": "71225",
      "Location Name": "CALHOUN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3136 HIGHWAY 80 W CALHOUN LA 71225 USA "
    },
    {
      "Location ID": "71226",
      "Location Name": "CHATHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6561 HIGHWAY 34 CHATHAM LA 71226 USA "
    },
    {
      "Location ID": "71227",
      "Location Name": "CHOUDRANT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2586 HIGHWAY 80 CHOUDRANT LA 71227 USA "
    },
    {
      "Location ID": "71229",
      "Location Name": "COLLINSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4603 MAIN ST COLLINSTON LA 71229 USA "
    },
    {
      "Location ID": "71230",
      "Location Name": "CROWVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5342 HIGHWAY 17 CROWVILLE LA 71230 USA "
    },
    {
      "Location ID": "71232",
      "Location Name": "DELHI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 711 BROADWAY ST DELHI LA 71232 USA "
    },
    {
      "Location ID": "71233",
      "Location Name": "DELTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 OUACHITA AVE DELTA LA 71233 USA "
    },
    {
      "Location ID": "71234",
      "Location Name": "DOWNSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9507 HIGHWAY 145 DOWNSVILLE LA 71234 USA "
    },
    {
      "Location ID": "71235",
      "Location Name": "DUBACH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1743 MCMULLEN ST DUBACH LA 71235 USA "
    },
    {
      "Location ID": "71237",
      "Location Name": "EPPS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 HIGHWAY 17 EPPS LA 71237 USA "
    },
    {
      "Location ID": "71238",
      "Location Name": "EROS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9972 HIGHWAY 34 EROS LA 71238 USA "
    },
    {
      "Location ID": "71240",
      "Location Name": "FAIRBANKS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1003 HIGHWAY 134 FAIRBANKS LA 71240 USA "
    },
    {
      "Location ID": "71241",
      "Location Name": "FARMERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 E GREEN ST FARMERVILLE LA 71241 USA "
    },
    {
      "Location ID": "71242",
      "Location Name": "FOREST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 250 CHERRY ST FOREST LA 71242 USA "
    },
    {
      "Location ID": "71243",
      "Location Name": "FORT NECESSITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10072 HIGHWAY 562 FORT NECESSITY LA 71243 USA "
    },
    {
      "Location ID": "71245",
      "Location Name": "GRAMBLING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 324 MAIN ST GRAMBLING LA 71245 USA "
    },
    {
      "Location ID": "71247",
      "Location Name": "HODGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 392 BANK ST HODGE LA 71247 USA "
    },
    {
      "Location ID": "71249",
      "Location Name": "JIGGER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7135 HIGHWAY 128 JIGGER LA 71249 USA "
    },
    {
      "Location ID": "71250",
      "Location Name": "JONES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 17366 WILMOT HWY JONES LA 71250 USA "
    },
    {
      "Location ID": "71251",
      "Location Name": "JONESBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 610 E MAIN ST JONESBORO LA 71251 USA "
    },
    {
      "Location ID": "71253",
      "Location Name": "KILBOURNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 E MAIN ST KILBOURNE LA 71253 USA "
    },
    {
      "Location ID": "71254",
      "Location Name": "LAKE PROVIDENCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 SPARROW ST LAKE PROVIDENCE LA 71254 USA "
    },
    {
      "Location ID": "71256",
      "Location Name": "LILLIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 HIGHWAY 15 LILLIE LA 71256 USA "
    },
    {
      "Location ID": "71259",
      "Location Name": "MANGHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 LOUISIANA ST MANGHAM LA 71259 USA "
    },
    {
      "Location ID": "71260",
      "Location Name": "MARION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 MAIN ST MARION LA 71260 USA "
    },
    {
      "Location ID": "71261",
      "Location Name": "MER ROUGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 405 DAVENPORT AVE MER ROUGE LA 71261 USA "
    },
    {
      "Location ID": "71263",
      "Location Name": "OAK GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 S BRIGGS ST OAK GROVE LA 71263 USA "
    },
    {
      "Location ID": "71264",
      "Location Name": "OAK RIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 S OAK ST OAK RIDGE LA 71264 USA "
    },
    {
      "Location ID": "71266",
      "Location Name": "PIONEER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 CHERRY ST PIONEER LA 71266 USA "
    },
    {
      "Location ID": "71268",
      "Location Name": "QUITMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8223 QUITMAN HWY QUITMAN LA 71268 USA "
    },
    {
      "Location ID": "71269",
      "Location Name": "RAYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 BENEDETTE ST RAYVILLE LA 71269 USA "
    },
    {
      "Location ID": "71270",
      "Location Name": "RUSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 700 E GEORGIA AVE RUSTON LA 71270 USA "
    },
    {
      "Location ID": "71275",
      "Location Name": "SIMSBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2903 MARTHA ST SIMSBORO LA 71275 USA "
    },
    {
      "Location ID": "71276",
      "Location Name": "SONDHEIMER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 17826 HIGHWAY 65 S SONDHEIMER LA 71276 USA "
    },
    {
      "Location ID": "71277",
      "Location Name": "SPEARSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1360 HIGHWAY 15 SPEARSVILLE LA 71277 USA "
    },
    {
      "Location ID": "71279",
      "Location Name": "START",
      "Time Zone Offset (Hours)": -1,
      "Address": " 34 OWL LOOP RD START LA 71279 USA "
    },
    {
      "Location ID": "71280",
      "Location Name": "STERLINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 HIGH AVE STERLINGTON LA 71280 USA "
    },
    {
      "Location ID": "71281",
      "Location Name": "SWARTZ",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1892 HIGHWAY 139 SWARTZ LA 71281 USA "
    },
    {
      "Location ID": "71282",
      "Location Name": "TALLULAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 N CEDAR ST TALLULAH LA 71282 USA "
    },
    {
      "Location ID": "71286",
      "Location Name": "TRANSYLVANIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13119 HIGHWAY 65 S TRANSYLVANIA LA 71286 USA "
    },
    {
      "Location ID": "71291",
      "Location Name": "WEST MONROE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 601 WOOD ST WEST MONROE LA 71291 USA "
    },
    {
      "Location ID": "71295",
      "Location Name": "WINNSBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3900 FRONT ST WINNSBORO LA 71295 USA "
    },
    {
      "Location ID": "712BA",
      "Location Name": "BAMKO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 LAMONT ST OAK GROVE LA 71263 USA "
    },
    {
      "Location ID": "712TC",
      "Location Name": "TWIN CITY AUTOMOTIVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 N 8TH ST WEST MONROE LA 71291 USA "
    },
    {
      "Location ID": "713",
      "Location Name": "ALEXANDRIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1715 ODOM ST ALEXANDRIA LA 71301 USA "
    },
    {
      "Location ID": "71307",
      "Location Name": "SOUTH PARK STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3401 GOVERNMENT ST ALEXANDRIA LA 71302 USA "
    },
    {
      "Location ID": "71315",
      "Location Name": "WESTSIDE STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1411 METRO DR ALEXANDRIA LA 71301 USA "
    },
    {
      "Location ID": "71322",
      "Location Name": "BUNKIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 W MAGNOLIA ST BUNKIE LA 71322 USA "
    },
    {
      "Location ID": "71323",
      "Location Name": "CENTER POINT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1006 HIGHWAY 107 CENTER POINT LA 71323 USA "
    },
    {
      "Location ID": "71324",
      "Location Name": "CHASE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 FEED MILL RD CHASE LA 71324 USA "
    },
    {
      "Location ID": "71325",
      "Location Name": "CHENEYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 KLOCK ST CHENEYVILLE LA 71325 USA "
    },
    {
      "Location ID": "71326",
      "Location Name": "CLAYTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 31449 HIGHWAY 15 CLAYTON LA 71326 USA "
    },
    {
      "Location ID": "71327",
      "Location Name": "COTTONPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 955 SYCAMORE ST COTTONPORT LA 71327 USA "
    },
    {
      "Location ID": "71328",
      "Location Name": "DEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1264 HIGHWAY 115 DEVILLE LA 71328 USA "
    },
    {
      "Location ID": "71329",
      "Location Name": "DUPONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5735 HIGHWAY 107 S DUPONT LA 71329 USA "
    },
    {
      "Location ID": "71330",
      "Location Name": "ECHO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10491 HIGHWAY 1 ECHO LA 71330 USA "
    },
    {
      "Location ID": "71331",
      "Location Name": "EFFIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3277 HIGHWAY 107 EFFIE LA 71331 USA "
    },
    {
      "Location ID": "71333",
      "Location Name": "EVERGREEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 HILL ST EVERGREEN LA 71333 USA "
    },
    {
      "Location ID": "71334",
      "Location Name": "FERRIDAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 999 1ST ST FERRIDAY LA 71334 USA "
    },
    {
      "Location ID": "71336",
      "Location Name": "GILBERT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7589 GILBERT ST GILBERT LA 71336 USA "
    },
    {
      "Location ID": "71339",
      "Location Name": "HAMBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 287 OLD HIGHWAY 1 HAMBURG LA 71339 USA "
    },
    {
      "Location ID": "71340",
      "Location Name": "HARRISONBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 PINE ST HARRISONBURG LA 71340 USA "
    },
    {
      "Location ID": "71341",
      "Location Name": "HESSMER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2999 MAIN ST HESSMER LA 71341 USA "
    },
    {
      "Location ID": "71342",
      "Location Name": "JENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3811 W OAK ST JENA LA 71342 USA "
    },
    {
      "Location ID": "71343",
      "Location Name": "JONESVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 902 FIRST ST JONESVILLE LA 71343 USA "
    },
    {
      "Location ID": "71345",
      "Location Name": "LEBEAU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8478 HIGHWAY 71 LEBEAU LA 71345 USA "
    },
    {
      "Location ID": "71346",
      "Location Name": "LECOMPTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1920 HIGHWAY 71 S LECOMPTE LA 71346 USA "
    },
    {
      "Location ID": "71348",
      "Location Name": "LIBUSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6400 HIGHWAY 28 E LIBUSE LA 71348 USA "
    },
    {
      "Location ID": "71350",
      "Location Name": "MANSURA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2109 LEGLISE ST MANSURA LA 71350 USA "
    },
    {
      "Location ID": "71351",
      "Location Name": "MARKSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 226 W CAPPEL ST MARKSVILLE LA 71351 USA "
    },
    {
      "Location ID": "71353",
      "Location Name": "MELVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 522 CHURCH ST MELVILLE LA 71353 USA "
    },
    {
      "Location ID": "71355",
      "Location Name": "MOREAUVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 MAIN ST MOREAUVILLE LA 71355 USA "
    },
    {
      "Location ID": "71356",
      "Location Name": "MORROW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 173 HIGHWAY 107 MORROW LA 71356 USA "
    },
    {
      "Location ID": "71357",
      "Location Name": "NEWELLTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 930 VERONA ST NEWELLTON LA 71357 USA "
    },
    {
      "Location ID": "71358",
      "Location Name": "PALMETTO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 E RAILROAD AVE STE B PALMETTO LA 71358 USA "
    },
    {
      "Location ID": "71360",
      "Location Name": "PINEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 PINEGROVE DR PINEVILLE LA 71360 USA "
    },
    {
      "Location ID": "71362",
      "Location Name": "PLAUCHEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 GIN ST PLAUCHEVILLE LA 71362 USA "
    },
    {
      "Location ID": "71363",
      "Location Name": "RHINEHART",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8769 HIGHWAY 84 E RHINEHART LA 71363 USA "
    },
    {
      "Location ID": "71366",
      "Location Name": "SAINT JOSEPH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 144 PLANK RD SAINT JOSEPH LA 71366 USA "
    },
    {
      "Location ID": "71367",
      "Location Name": "SAINT LANDRY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1888 SAINT LANDRY HWY SAINT LANDRY LA 71367 USA "
    },
    {
      "Location ID": "71368",
      "Location Name": "SICILY ISLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 PENISTON ST SICILY ISLAND LA 71368 USA "
    },
    {
      "Location ID": "71369",
      "Location Name": "SIMMESPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 360 MISSION DR SIMMESPORT LA 71369 USA "
    },
    {
      "Location ID": "71371",
      "Location Name": "TROUT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12999 HIGHWAY 84 W TROUT LA 71371 USA "
    },
    {
      "Location ID": "71373",
      "Location Name": "VIDALIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1000 CARTER ST VIDALIA LA 71373 USA "
    },
    {
      "Location ID": "71375",
      "Location Name": "WATERPROOF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 338 MAIN ST WATERPROOF LA 71375 USA "
    },
    {
      "Location ID": "71378",
      "Location Name": "WISNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 FORT SCOTT ST WISNER LA 71378 USA "
    },
    {
      "Location ID": "713LB",
      "Location Name": "LSUA BOOKSTORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8100 HIGHWAY 71 S ALEXANDRIA LA 71302 USA "
    },
    {
      "Location ID": "713SD",
      "Location Name": "SOUTHERN DESIGNS & GIFTS LLC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4894 HIGHWAY 84 W VIDALIA LA 71373 USA "
    },
    {
      "Location ID": "713SS",
      "Location Name": "SOUTHERN DESIGN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4894 HWY 84 VIDALIA LA 71373 USA "
    },
    {
      "Location ID": "71403",
      "Location Name": "ANACOCO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4658 MAIN ST ANACOCO LA 71403 USA "
    },
    {
      "Location ID": "71405",
      "Location Name": "BALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5912 MONROE HWY BALL LA 71405 USA "
    },
    {
      "Location ID": "71406",
      "Location Name": "BELMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9160 HIGHWAY 175 BELMONT LA 71406 USA "
    },
    {
      "Location ID": "71407",
      "Location Name": "BENTLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 19497 HIGHWAY 167 BENTLEY LA 71407 USA "
    },
    {
      "Location ID": "71409",
      "Location Name": "BOYCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 95 PACIFIC AVE BOYCE LA 71409 USA "
    },
    {
      "Location ID": "71410",
      "Location Name": "CALVIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 279 3RD ST CALVIN LA 71410 USA "
    },
    {
      "Location ID": "71411",
      "Location Name": "CAMPTI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3070 HIGHWAY 71 CAMPTI LA 71411 USA "
    },
    {
      "Location ID": "71414",
      "Location Name": "CLARENCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5978 HIGHWAY 71 CLARENCE LA 71414 USA "
    },
    {
      "Location ID": "71415",
      "Location Name": "CLARKS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1710 HIGHWAY 845 CLARKS LA 71415 USA "
    },
    {
      "Location ID": "71416",
      "Location Name": "CLOUTIERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14904 HIGHWAY 1 CLOUTIERVILLE LA 71416 USA "
    },
    {
      "Location ID": "71417",
      "Location Name": "COLFAX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 707 8TH ST COLFAX LA 71417 USA "
    },
    {
      "Location ID": "71418",
      "Location Name": "COLUMBIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 WALL ST COLUMBIA LA 71418 USA "
    },
    {
      "Location ID": "71419",
      "Location Name": "CONVERSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 308 W PORT ARTHUR AVE CONVERSE LA 71419 USA "
    },
    {
      "Location ID": "71422",
      "Location Name": "DODSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 GANSVILLE RD DODSON LA 71422 USA "
    },
    {
      "Location ID": "71423",
      "Location Name": "DRY PRONG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 605 FIRST ST DRY PRONG LA 71423 USA "
    },
    {
      "Location ID": "71426",
      "Location Name": "FISHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 737 FOUR L DR FISHER LA 71426 USA "
    },
    {
      "Location ID": "71428",
      "Location Name": "FLORA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1689 HIGHWAY 120 FLORA LA 71428 USA "
    },
    {
      "Location ID": "71429",
      "Location Name": "FLORIEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1284 N NOLAN TRCE FLORIEN LA 71429 USA "
    },
    {
      "Location ID": "71430",
      "Location Name": "FOREST HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11038 HIGHWAY 165 S FOREST HILL LA 71430 USA "
    },
    {
      "Location ID": "71432",
      "Location Name": "GEORGETOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4474 HIGHWAY 500 GEORGETOWN LA 71432 USA "
    },
    {
      "Location ID": "71433",
      "Location Name": "GLENMORA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1201 7TH AVE GLENMORA LA 71433 USA "
    },
    {
      "Location ID": "71435",
      "Location Name": "GRAYSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 954 HIGHWAY 850 GRAYSON LA 71435 USA "
    },
    {
      "Location ID": "71439",
      "Location Name": "HORNBECK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1127 HAMMOND ST HORNBECK LA 71439 USA "
    },
    {
      "Location ID": "71440",
      "Location Name": "JOYCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7018 HIGHWAY 84 E JOYCE LA 71440 USA "
    },
    {
      "Location ID": "71441",
      "Location Name": "KELLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3401 HIGHWAY 506 W KELLY LA 71441 USA "
    },
    {
      "Location ID": "71446",
      "Location Name": "LEESVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 S 3RD ST LEESVILLE LA 71446 USA "
    },
    {
      "Location ID": "71447",
      "Location Name": "LENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 57 LENA FLATWOODS RD LENA LA 71447 USA "
    },
    {
      "Location ID": "71448",
      "Location Name": "LONGLEAF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 76 LONG LEAF RD LONGLEAF LA 71448 USA "
    },
    {
      "Location ID": "71449",
      "Location Name": "MANY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 490 CHURCH ST MANY LA 71449 USA "
    },
    {
      "Location ID": "71450",
      "Location Name": "MARTHAVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10644 HIGHWAY 120 MARTHAVILLE LA 71450 USA "
    },
    {
      "Location ID": "71454",
      "Location Name": "MONTGOMERY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 509 WOODLAND ST MONTGOMERY LA 71454 USA "
    },
    {
      "Location ID": "71456",
      "Location Name": "NATCHEZ",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9465 HIGHWAY 1 NATCHEZ LA 71456 USA "
    },
    {
      "Location ID": "71457",
      "Location Name": "NATCHITOCHES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 240 SAINT DENIS ST NATCHITOCHES LA 71457 USA "
    },
    {
      "Location ID": "71459",
      "Location Name": "FORT POLK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6180 UNIVERSITY PKWY FORT POLK LA 71459 USA "
    },
    {
      "Location ID": "71461",
      "Location Name": "NEW LLANO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 330 VERNON ST NEW LLANO LA 71461 USA "
    },
    {
      "Location ID": "71462",
      "Location Name": "NOBLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 820 HIGHWAY 1218 NOBLE LA 71462 USA "
    },
    {
      "Location ID": "71463",
      "Location Name": "OAKDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 240 E 6TH AVE OAKDALE LA 71463 USA "
    },
    {
      "Location ID": "71465",
      "Location Name": "OLLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3470 MAIN STREET 165 OLLA LA 71465 USA "
    },
    {
      "Location ID": "71467",
      "Location Name": "POLLOCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7968 RIDGE ST POLLOCK LA 71467 USA "
    },
    {
      "Location ID": "71468",
      "Location Name": "PROVENCAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 ROBELINE PROVENCAL RD PROVENCAL LA 71468 USA "
    },
    {
      "Location ID": "71469",
      "Location Name": "ROBELINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 175 CHAPLIN LOOP ROBELINE LA 71469 USA "
    },
    {
      "Location ID": "71473",
      "Location Name": "SIKES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4697 HIGHWAY 126 SIKES LA 71473 USA "
    },
    {
      "Location ID": "71474",
      "Location Name": "SIMPSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4259 HIGHWAY 8 SIMPSON LA 71474 USA "
    },
    {
      "Location ID": "71475",
      "Location Name": "SLAGLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8787 HIGHWAY 8 SLAGLE LA 71475 USA "
    },
    {
      "Location ID": "71477",
      "Location Name": "TIOGA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5019 SHREVEPORT HWY TIOGA LA 71477 USA "
    },
    {
      "Location ID": "71479",
      "Location Name": "TULLOS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10175 S MAIN ST TULLOS LA 71479 USA "
    },
    {
      "Location ID": "71480",
      "Location Name": "URANIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2150 E HARDTNER DR URANIA LA 71480 USA "
    },
    {
      "Location ID": "71483",
      "Location Name": "WINNFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 S BEVILL ST WINNFIELD LA 71483 USA "
    },
    {
      "Location ID": "71485",
      "Location Name": "WOODWORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20 CASTOR PLUNGE RD WOODWORTH LA 71485 USA "
    },
    {
      "Location ID": "71486",
      "Location Name": "ZWOLLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1237 N MAIN ST ZWOLLE LA 71486 USA "
    },
    {
      "Location ID": "714AA",
      "Location Name": "EAST NATCHITOCHES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 727 KEYSER AVE NATCHITOCHES LA 71457 USA "
    },
    {
      "Location ID": "714KW",
      "Location Name": "KNIFEWORKS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7900 HIGHWAY 165 COLUMBIA LA 71418 USA "
    },
    {
      "Location ID": "714NW",
      "Location Name": "NSU POST OFFICE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 350 SAM SIBLEY DR NATCHITOCHES LA 71497 USA "
    },
    {
      "Location ID": "716",
      "Location Name": "PINE BLUFF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 E 8TH AVE STE 105 PINE BLUFF AR 71601 USA "
    },
    {
      "Location ID": "71601",
      "Location Name": "PINE BLUFF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 E 8TH AVE STE 105 PINE BLUFF AR 71601-9998 USA "
    },
    {
      "Location ID": "71602",
      "Location Name": "WHITE HALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7402 DOLLARWAY RD WHITE HALL AR 71602 USA "
    },
    {
      "Location ID": "71603",
      "Location Name": "OAK PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2901 S FIR ST PINE BLUFF AR 71603 USA "
    },
    {
      "Location ID": "71630",
      "Location Name": "ARKANSAS CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 SPRAGUE ST ARKANSAS CITY AR 71630 USA "
    },
    {
      "Location ID": "71635",
      "Location Name": "CROSSETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 312 MAIN ST CROSSETT AR 71635 USA "
    },
    {
      "Location ID": "71638",
      "Location Name": "DERMOTT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 S FREEMAN ST DERMOTT AR 71638 USA "
    },
    {
      "Location ID": "71639",
      "Location Name": "DUMAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 320 N MAIN ST DUMAS AR 71639 USA "
    },
    {
      "Location ID": "71640",
      "Location Name": "EUDORA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 N ARCHER ST EUDORA AR 71640 USA "
    },
    {
      "Location ID": "71642",
      "Location Name": "FOUNTAIN HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 PINE ST FOUNTAIN HILL AR 71642 USA "
    },
    {
      "Location ID": "71643",
      "Location Name": "GOULD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 W JACKSON BLVD GOULD AR 71643 USA "
    },
    {
      "Location ID": "71644",
      "Location Name": "GRADY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 BUSINESS 65 GRADY AR 71644 USA "
    },
    {
      "Location ID": "71646",
      "Location Name": "HAMBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 E ADAMS ST HAMBURG AR 71646 USA "
    },
    {
      "Location ID": "71652",
      "Location Name": "KINGSLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 80 FIRST ST KINGSLAND AR 71652 USA "
    },
    {
      "Location ID": "71653",
      "Location Name": "LAKE VILLAGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 S COKLEY ST LAKE VILLAGE AR 71653 USA "
    },
    {
      "Location ID": "71654",
      "Location Name": "MC GEHEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 S 1ST ST MC GEHEE AR 71654 USA "
    },
    {
      "Location ID": "71655",
      "Location Name": "MONTICELLO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1492 N HYATT ST MONTICELLO AR 71655 USA "
    },
    {
      "Location ID": "71656",
      "Location Name": "UNIV OF ARK AT MONTICELLO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 580 UNIVERSITY DR MONTICELLO AR 71655 USA "
    },
    {
      "Location ID": "71659",
      "Location Name": "MOSCOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9201 HIGHWAY 65 S MOSCOW AR 71659 USA "
    },
    {
      "Location ID": "71660",
      "Location Name": "NEW EDINBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6530 HIGHWAY 8 NEW EDINBURG AR 71660 USA "
    },
    {
      "Location ID": "71662",
      "Location Name": "PICKENS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 126 RAILROAD ST PICKENS AR 71662 USA "
    },
    {
      "Location ID": "71665",
      "Location Name": "RISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 506 S MAIN ST RISON AR 71665 USA "
    },
    {
      "Location ID": "71666",
      "Location Name": "ROHWER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 136 COOKS RAMP RD TILLAR AR 71670 USA "
    },
    {
      "Location ID": "71667",
      "Location Name": "STAR CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 S JEFFERSON ST STAR CITY AR 71667 USA "
    },
    {
      "Location ID": "71670",
      "Location Name": "TILLAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 166 B ST TILLAR AR 71670 USA "
    },
    {
      "Location ID": "71671",
      "Location Name": "WARREN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 408 N MAIN ST WARREN AR 71671 USA "
    },
    {
      "Location ID": "71674",
      "Location Name": "WATSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4121 HIGHWAY 1 N WATSON AR 71674 USA "
    },
    {
      "Location ID": "71675",
      "Location Name": "WILMAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2617 HIGHWAY 278 W WILMAR AR 71675 USA "
    },
    {
      "Location ID": "71677",
      "Location Name": "WINCHESTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5260 HIGHWAY 138 WINCHESTER AR 71677 USA "
    },
    {
      "Location ID": "71678",
      "Location Name": "YORKTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 69 ANDERSON TRL YORKTOWN AR 71678 USA "
    },
    {
      "Location ID": "716BM",
      "Location Name": "BAMKO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 144 INDUSTRIAL DR  MONTICELLO AR 71655 USA "
    },
    {
      "Location ID": "717",
      "Location Name": "CAMDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 351 W WASHINGTON ST STE 100 CAMDEN AR 71701 USA "
    },
    {
      "Location ID": "71720",
      "Location Name": "BEARDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 160 N PLUM ST BEARDEN AR 71720 USA "
    },
    {
      "Location ID": "71721",
      "Location Name": "BEIRNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16462 HIGHWAY 51 S BEIRNE AR 71721 USA "
    },
    {
      "Location ID": "71722",
      "Location Name": "BLUFF CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4979 HIGHWAY 24 W BLUFF CITY AR 71722 USA "
    },
    {
      "Location ID": "71724",
      "Location Name": "CALION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 324 MILL ST CALION AR 71724 USA "
    },
    {
      "Location ID": "71726",
      "Location Name": "CHIDESTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 HIGHWAY 57 CHIDESTER AR 71726 USA "
    },
    {
      "Location ID": "71731",
      "Location Name": "EL DORADO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 550 S TIMBERLANE DR EL DORADO AR 71730 USA "
    },
    {
      "Location ID": "71740",
      "Location Name": "EMERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 N ELM ST EMERSON AR 71740 USA "
    },
    {
      "Location ID": "71742",
      "Location Name": "FORDYCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 801 W 4TH ST FORDYCE AR 71742 USA "
    },
    {
      "Location ID": "71743",
      "Location Name": "GURDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 E JOSLYN ST GURDON AR 71743 USA "
    },
    {
      "Location ID": "71744",
      "Location Name": "HAMPTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 213 W DUNN AVE HAMPTON AR 71744 USA "
    },
    {
      "Location ID": "71745",
      "Location Name": "HARRELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 E CENTRAL ST HARRELL AR 71745 USA "
    },
    {
      "Location ID": "71747",
      "Location Name": "HUTTIG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 601 OLIN AVE HUTTIG AR 71747 USA "
    },
    {
      "Location ID": "71749",
      "Location Name": "JUNCTION CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 E CENTER ST JUNCTION CITY AR 71749 USA "
    },
    {
      "Location ID": "71750",
      "Location Name": "LAWSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 MOUNT ZION RD LAWSON AR 71750 USA "
    },
    {
      "Location ID": "71751",
      "Location Name": "LOUANN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 N MAIN ST LOUANN AR 71751 USA "
    },
    {
      "Location ID": "71752",
      "Location Name": "MC NEIL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 OLIVE ST MC NEIL AR 71752 USA "
    },
    {
      "Location ID": "71753",
      "Location Name": "MAGNOLIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 N PINE MAGNOLIA AR 71753 USA "
    },
    {
      "Location ID": "71759",
      "Location Name": "NORPHLET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S VINEYARD ST NORPHLET AR 71759 USA "
    },
    {
      "Location ID": "71762",
      "Location Name": "SMACKOVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 W 8TH ST SMACKOVER AR 71762 USA "
    },
    {
      "Location ID": "71763",
      "Location Name": "SPARKMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 N COMMERCE AVE SPARKMAN AR 71763 USA "
    },
    {
      "Location ID": "71764",
      "Location Name": "STEPHENS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 W RUBY ST STEPHENS AR 71764 USA "
    },
    {
      "Location ID": "71765",
      "Location Name": "STRONG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9559 STRONG HWY STRONG AR 71765 USA "
    },
    {
      "Location ID": "71766",
      "Location Name": "THORNTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 917 S 1ST ST THORNTON AR 71766 USA "
    },
    {
      "Location ID": "71770",
      "Location Name": "WALDO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 E MCKISSACK ST WALDO AR 71770 USA "
    },
    {
      "Location ID": "71772",
      "Location Name": "WHELEN SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 E MAIN ST WHELEN SPRINGS AR 71772 USA "
    },
    {
      "Location ID": "71801",
      "Location Name": "HOPE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 S LAUREL ST HOPE AR 71801 USA "
    },
    {
      "Location ID": "71822",
      "Location Name": "ASHDOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 451 E LOCKE ST ASHDOWN AR 71822 USA "
    },
    {
      "Location ID": "71823",
      "Location Name": "BEN LOMOND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 S MAIN ST BEN LOMOND AR 71823 USA "
    },
    {
      "Location ID": "71825",
      "Location Name": "BLEVINS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5911 HIGHWAY 29 N BLEVINS AR 71825 USA "
    },
    {
      "Location ID": "71826",
      "Location Name": "BRADLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 PULLMAN ST BRADLEY AR 71826 USA "
    },
    {
      "Location ID": "71827",
      "Location Name": "BUCKNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 W MYRTLE ST BUCKNER AR 71827 USA "
    },
    {
      "Location ID": "71828",
      "Location Name": "CALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 794 WESTMORELAND DR CALE AR 71828 USA "
    },
    {
      "Location ID": "71832",
      "Location Name": "DE QUEEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 N 4TH ST DE QUEEN AR 71832 USA "
    },
    {
      "Location ID": "71833",
      "Location Name": "DIERKS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 MAIN ST DIERKS AR 71833 USA "
    },
    {
      "Location ID": "71834",
      "Location Name": "DODDRIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 28338 HIGHWAY 71 DODDRIDGE AR 71834 USA "
    },
    {
      "Location ID": "71835",
      "Location Name": "EMMET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 W 1ST ST EMMET AR 71835 USA "
    },
    {
      "Location ID": "71836",
      "Location Name": "FOREMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 510 TRACY LAWRENCE AVE FOREMAN AR 71836 USA "
    },
    {
      "Location ID": "71837",
      "Location Name": "FOUKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 N MONSTER EXPY FOUKE AR 71837 USA "
    },
    {
      "Location ID": "71838",
      "Location Name": "FULTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 LITTLE RIVER ST FULTON AR 71838 USA "
    },
    {
      "Location ID": "71839",
      "Location Name": "GARLAND CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 6TH ST GARLAND CITY AR 71839 USA "
    },
    {
      "Location ID": "71840",
      "Location Name": "GENOA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8185 STATE HIGHWAY 196 GENOA AR 71840 USA "
    },
    {
      "Location ID": "71841",
      "Location Name": "GILLHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 N 2ND ST GILLHAM AR 71841 USA "
    },
    {
      "Location ID": "71842",
      "Location Name": "HORATIO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 W MAIN ST HORATIO AR 71842 USA "
    },
    {
      "Location ID": "71845",
      "Location Name": "LEWISVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 310 E FIRST ST LEWISVILLE AR 71845 USA "
    },
    {
      "Location ID": "71846",
      "Location Name": "LOCKESBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 E WALNUT ST LOCKESBURG AR 71846 USA "
    },
    {
      "Location ID": "71847",
      "Location Name": "MC CASKILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 HEMPSTEAD 337 MC CASKILL AR 71847 USA "
    },
    {
      "Location ID": "71851",
      "Location Name": "MINERAL SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 E RUNNELS ST MINERAL SPRINGS AR 71851 USA "
    },
    {
      "Location ID": "71852",
      "Location Name": "NASHVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 N MAIN ST NASHVILLE AR 71852 USA "
    },
    {
      "Location ID": "71853",
      "Location Name": "OGDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 131 PINE ST OGDEN AR 71853 USA "
    },
    {
      "Location ID": "71855",
      "Location Name": "OZAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6181 HIGHWAY 278 W OZAN AR 71855 USA "
    },
    {
      "Location ID": "71857",
      "Location Name": "PRESCOTT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 E ELM ST PRESCOTT AR 71857 USA "
    },
    {
      "Location ID": "71858",
      "Location Name": "ROSSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4463 US HIGHWAY 371 ROSSTON AR 71858 USA "
    },
    {
      "Location ID": "71859",
      "Location Name": "SARATOGA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1948 MAIN ST SARATOGA AR 71859 USA "
    },
    {
      "Location ID": "71860",
      "Location Name": "STAMPS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 THOMAS ST STAMPS AR 71860 USA "
    },
    {
      "Location ID": "71861",
      "Location Name": "TAYLOR AR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 LONG ST TAYLOR AR 71861 USA "
    },
    {
      "Location ID": "71862",
      "Location Name": "WASHINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 FRANKLIN ST WASHINGTON AR 71862 USA "
    },
    {
      "Location ID": "71865",
      "Location Name": "WILTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 322 E DOUGLAS AVE WILTON AR 71865 USA "
    },
    {
      "Location ID": "71866",
      "Location Name": "WINTHROP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 612 HIGH ST WINTHROP AR 71866 USA "
    },
    {
      "Location ID": "718AA",
      "Location Name": "DOWNTOWN TEXARKANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 N STATE LINE AVE STE 101 TEXARKANA AR 71854 USA "
    },
    {
      "Location ID": "718YP",
      "Location Name": "YORK PECAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2919 HWY 32 FOREMAN AR 71836 USA "
    },
    {
      "Location ID": "719",
      "Location Name": "HOT SPRINGS NATIONAL PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 335 SECTION LINE RD HOT SPRINGS NATIONAL PARK AR 71913 USA "
    },
    {
      "Location ID": "71901",
      "Location Name": "HOT SPRINGS PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 620 CENTRAL AVE STE 1A HOT SPRINGS NATIONAL PARK AR 71901 USA "
    },
    {
      "Location ID": "71909",
      "Location Name": "HOT SPRINGS VILLAGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4656 N HIGHWAY 7 STE P HOT SPRINGS VILLAGE AR 71909 USA "
    },
    {
      "Location ID": "71913",
      "Location Name": "HOT SPRINGS ALBERT PIKE STA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 HAMMOND DR HOT SPRINGS NATIONAL PARK AR 71913 USA "
    },
    {
      "Location ID": "71914",
      "Location Name": "HOT SPRINGS NATIONAL PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 335 SECTION LINE RD HOT SPRINGS NATIONAL PARK AR 71913-9998 USA "
    },
    {
      "Location ID": "71921",
      "Location Name": "AMITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 136 W THOMPSON ST AMITY AR 71921 USA "
    },
    {
      "Location ID": "71922",
      "Location Name": "ANTOINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 40 E MAIN ST ANTOINE AR 71922 USA "
    },
    {
      "Location ID": "71923",
      "Location Name": "ARKADELPHIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 615 CRITTENDEN ST ARKADELPHIA AR 71923 USA "
    },
    {
      "Location ID": "71929",
      "Location Name": "BISMARCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 MILLENNIUM PARK DR BISMARCK AR 71929 USA "
    },
    {
      "Location ID": "71933",
      "Location Name": "BONNERDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1980 HIGHWAY 70 BONNERDALE AR 71933 USA "
    },
    {
      "Location ID": "71935",
      "Location Name": "CADDO GAP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 57 6TH ST CADDO GAP AR 71935 USA "
    },
    {
      "Location ID": "71937",
      "Location Name": "COVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 E BARTON AVE COVE AR 71937 USA "
    },
    {
      "Location ID": "71940",
      "Location Name": "DELIGHT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 126 E ANTIOCH ST DELIGHT AR 71940 USA "
    },
    {
      "Location ID": "71941",
      "Location Name": "DONALDSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 BRYANT DR DONALDSON AR 71941 USA "
    },
    {
      "Location ID": "71942",
      "Location Name": "FRIENDSHIP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2595 MALVERN RD FRIENDSHIP AR 71942 USA "
    },
    {
      "Location ID": "71943",
      "Location Name": "GLENWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 916 E BROADWAY GLENWOOD AR 71943 USA "
    },
    {
      "Location ID": "71944",
      "Location Name": "GRANNIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8580 HIGHWAY 71 S GRANNIS AR 71944 USA "
    },
    {
      "Location ID": "71945",
      "Location Name": "HATFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 E HORNBECK RD HATFIELD AR 71945 USA "
    },
    {
      "Location ID": "71949",
      "Location Name": "JESSIEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7919 N HIGHWAY 7 JESSIEVILLE AR 71949 USA "
    },
    {
      "Location ID": "71950",
      "Location Name": "KIRBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2633 HIGHWAY 27 N KIRBY AR 71950 USA "
    },
    {
      "Location ID": "71952",
      "Location Name": "LANGLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2579 HIGHWAY 84 W LANGLEY AR 71952 USA "
    },
    {
      "Location ID": "71953",
      "Location Name": "MENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 MORROW ST S MENA AR 71953 USA "
    },
    {
      "Location ID": "71956",
      "Location Name": "MOUNTAIN PINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 240 MAIN AVE MOUNTAIN PINE AR 71956 USA "
    },
    {
      "Location ID": "71957",
      "Location Name": "MOUNT IDA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 140 US-270 MOUNT IDA AR 71957 USA "
    },
    {
      "Location ID": "71958",
      "Location Name": "MURFREESBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 E MAIN MURFREESBORO AR 71958 USA "
    },
    {
      "Location ID": "71959",
      "Location Name": "NEWHOPE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5638 HIGHWAY 70 W NEWHOPE AR 71959 USA "
    },
    {
      "Location ID": "71960",
      "Location Name": "NORMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 340 E MAIN ST NORMAN AR 71960 USA "
    },
    {
      "Location ID": "71961",
      "Location Name": "ODEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1093 W HIGHWAY 88 ODEN AR 71961 USA "
    },
    {
      "Location ID": "71962",
      "Location Name": "OKOLONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 316 S MAIN ST OKOLONA AR 71962 USA "
    },
    {
      "Location ID": "71964",
      "Location Name": "PEARCY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5106 AIRPORT RD PEARCY AR 71964 USA "
    },
    {
      "Location ID": "71965",
      "Location Name": "PENCIL BLUFF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3672 W HIGHWAY 270 PENCIL BLUFF AR 71965 USA "
    },
    {
      "Location ID": "71968",
      "Location Name": "ROYAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6951 ALBERT PIKE RD ROYAL AR 71968 USA "
    },
    {
      "Location ID": "71969",
      "Location Name": "SIMS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1465 HIGHWAY 298 W SIMS AR 71969-9998 USA "
    },
    {
      "Location ID": "71970",
      "Location Name": "STORY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5311 HIGHWAY 27 N STORY AR 71970 USA "
    },
    {
      "Location ID": "71971",
      "Location Name": "UMPIRE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 142 HIGHWAY 84 E UMPIRE AR 71971 USA "
    },
    {
      "Location ID": "71972",
      "Location Name": "VANDERVOORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 N HORNBECK AVE VANDERVOORT AR 71972-9998 USA "
    },
    {
      "Location ID": "71973",
      "Location Name": "WICKES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7631 HIGHWAY 71 S WICKES AR 71973 USA "
    },
    {
      "Location ID": "719BS",
      "Location Name": "BUREAU OF CONSULAR AFFAIRS (PASSPORT BOOK PR CTR)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 191 OFFICE PARK DR HOT SPRINGS AR 71913 USA "
    },
    {
      "Location ID": "719IS",
      "Location Name": "ISOKENETICS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2720 MOUNTAIN PINE RD HOT SPRINGS AR 71913-8129 USA "
    },
    {
      "Location ID": "719SA",
      "Location Name": "PASSPORT BOOK PRINT CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 191 OFFICE PARK DR HOT SPRINGS AR 71913-5496 USA "
    },
    {
      "Location ID": "720",
      "Location Name": "LITTLE ROCK P&DC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4700 E MCCAIN BLVD LITTLE ROCK AR 72231 USA "
    },
    {
      "Location ID": "72001",
      "Location Name": "ADONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1892 HIGHWAY 10 W ADONA AR 72001 USA "
    },
    {
      "Location ID": "72002",
      "Location Name": "ALEXANDER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15604 ALEXANDER RD ALEXANDER AR 72002 USA "
    },
    {
      "Location ID": "72003",
      "Location Name": "ALMYRA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E MAIN ST ALMYRA AR 72003 USA "
    },
    {
      "Location ID": "72004",
      "Location Name": "ALTHEIMER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 W FRONT ST ALTHEIMER AR 72004 USA "
    },
    {
      "Location ID": "72005",
      "Location Name": "AMAGON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 AMAGON AVE AMAGON AR 72005 USA "
    },
    {
      "Location ID": "72006",
      "Location Name": "AUGUSTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 MAIN ST AUGUSTA AR 72006 USA "
    },
    {
      "Location ID": "72007",
      "Location Name": "AUSTIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3385 HIGHWAY 367 N AUSTIN AR 72007 USA "
    },
    {
      "Location ID": "72010",
      "Location Name": "BALD KNOB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 N MAIN ST BALD KNOB AR 72010 USA "
    },
    {
      "Location ID": "72011",
      "Location Name": "BAUXITE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 BAUXITE CUTOFF RD BAUXITE AR 72011 USA "
    },
    {
      "Location ID": "72012",
      "Location Name": "BEEBE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 E CENTER ST BEEBE AR 72012 USA "
    },
    {
      "Location ID": "72013",
      "Location Name": "BEE BRANCH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 148 HIGHWAY 92 E BEE BRANCH AR 72013 USA "
    },
    {
      "Location ID": "72014",
      "Location Name": "BEEDEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9907 HIGHWAY 37 S BEEDEVILLE AR 72014 USA "
    },
    {
      "Location ID": "72015",
      "Location Name": "BENTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1425 MILITARY RD BENTON AR 72015 USA "
    },
    {
      "Location ID": "72016",
      "Location Name": "VPO BIGELOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 360 HIGHWAY 113 S BIGELOW AR 72016-5026 USA "
    },
    {
      "Location ID": "72017",
      "Location Name": "BISCOE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 506 W SPORTSMAN DR BISCOE AR 72017 USA "
    },
    {
      "Location ID": "72020",
      "Location Name": "BRADFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 SW 3RD ST BRADFORD AR 72020 USA "
    },
    {
      "Location ID": "72021",
      "Location Name": "BRINKLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 N MAIN ST BRINKLEY AR 72021 USA "
    },
    {
      "Location ID": "72023",
      "Location Name": "CABOT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 E MAIN ST CABOT AR 72023 USA "
    },
    {
      "Location ID": "72024",
      "Location Name": "CARLISLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 N COURT ST CARLISLE AR 72024 USA "
    },
    {
      "Location ID": "72025",
      "Location Name": "CASA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3684 HIGHWAY 10 3RD ST CASA AR 72025 USA "
    },
    {
      "Location ID": "72027",
      "Location Name": "CENTER RIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5744 HIGHWAY 9 CENTER RIDGE AR 72027 USA "
    },
    {
      "Location ID": "72028",
      "Location Name": "CHOCTAW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 173 HIGHWAY 330 E CHOCTAW AR 72028 USA "
    },
    {
      "Location ID": "72029",
      "Location Name": "CLARENDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 MADISON ST CLARENDON AR 72029 USA "
    },
    {
      "Location ID": "72030",
      "Location Name": "CLEVELAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3954 HIGHWAY 95 CLEVELAND AR 72030 USA "
    },
    {
      "Location ID": "72031",
      "Location Name": "CLINTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 145 VOLUNTEER PKWY CLINTON AR 72031 USA "
    },
    {
      "Location ID": "72032",
      "Location Name": "CONWAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1060 HOGAN LN CONWAY AR 72034 USA "
    },
    {
      "Location ID": "72034",
      "Location Name": "DOWNTOWN CONWAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1111 MAIN ST STE 107 CONWAY AR 72032-5410 USA "
    },
    {
      "Location ID": "72036",
      "Location Name": "COTTON PLANT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 279 W MAIN ST COTTON PLANT AR 72036 USA "
    },
    {
      "Location ID": "72037",
      "Location Name": "COY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 561 MAIN ST COY AR 72037 USA "
    },
    {
      "Location ID": "72039",
      "Location Name": "DAMASCUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 17465 HIGHWAY 65 S DAMASCUS AR 72039 USA "
    },
    {
      "Location ID": "72040",
      "Location Name": "DES ARC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 S 4TH ST DES ARC AR 72040 USA "
    },
    {
      "Location ID": "72041",
      "Location Name": "DE VALLS BLUFF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 NEWT PARK DR DE VALLS BLUFF AR 72041 USA "
    },
    {
      "Location ID": "72042",
      "Location Name": "DE WITT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 221 W CROSS ST DE WITT AR 72042 USA "
    },
    {
      "Location ID": "72043",
      "Location Name": "DIAZ",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3400 S MAIN ST DIAZ AR 72043 USA "
    },
    {
      "Location ID": "72044",
      "Location Name": "EDGEMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10239 EDGEMONT RD EDGEMONT AR 72044 USA "
    },
    {
      "Location ID": "72045",
      "Location Name": "EL PASO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3092 HIGHWAY 5 EL PASO AR 72045 USA "
    },
    {
      "Location ID": "72046",
      "Location Name": "ENGLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 N MAIN ST ENGLAND AR 72046 USA "
    },
    {
      "Location ID": "72047",
      "Location Name": "ENOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 232C HIGHWAY 107 N ENOLA AR 72047 USA "
    },
    {
      "Location ID": "72052",
      "Location Name": "GARNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4392 HIGHWAY 367 S GARNER AR 72052 USA "
    },
    {
      "Location ID": "72053",
      "Location Name": "COLLEGE STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4000 GRAY ST STE A COLLEGE STATION AR 72053 USA "
    },
    {
      "Location ID": "72055",
      "Location Name": "GILLETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14 W MAIN ST GILLETT AR 72055 USA "
    },
    {
      "Location ID": "72057",
      "Location Name": "GRAPEVINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 151 GRANT COUNTY 18 GRAPEVINE AR 72057 USA "
    },
    {
      "Location ID": "72058",
      "Location Name": "GREENBRIER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 88 S BROADVIEW ST GREENBRIER AR 72058 USA "
    },
    {
      "Location ID": "72061",
      "Location Name": "GUY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4 SUNSET LN GUY AR 72061 USA "
    },
    {
      "Location ID": "72064",
      "Location Name": "HAZEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 N CHESTER ST HAZEN AR 72064 USA "
    },
    {
      "Location ID": "72065",
      "Location Name": "HENSLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 27001 HIGHWAY 365 HENSLEY AR 72065 USA "
    },
    {
      "Location ID": "72067",
      "Location Name": "HIGDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7545 EDGEMONT RD HIGDEN AR 72067 USA "
    },
    {
      "Location ID": "72069",
      "Location Name": "HOLLY GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 169 E RALPH ABRAMSON ST HOLLY GROVE AR 72069 USA "
    },
    {
      "Location ID": "72070",
      "Location Name": "HOUSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2087 HIGHWAY 60 E STE A HOUSTON AR 72070 USA "
    },
    {
      "Location ID": "72072",
      "Location Name": "HUMNOKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12221 US HIGHWAY 165 E HUMNOKE AR 72072 USA "
    },
    {
      "Location ID": "72073",
      "Location Name": "HUMPHREY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 N MULBERRY ST HUMPHREY AR 72073 USA "
    },
    {
      "Location ID": "72074",
      "Location Name": "HUNTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 245 SW MAIN ST HUNTER AR 72074 USA "
    },
    {
      "Location ID": "72075",
      "Location Name": "JACKSONPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 REDMAN ST JACKSONPORT AR 72075 USA "
    },
    {
      "Location ID": "72076",
      "Location Name": "JACKSONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1205 W MAIN ST JACKSONVILLE AR 72076 USA "
    },
    {
      "Location ID": "72079",
      "Location Name": "JEFFERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4112 S HIGHWAY 365 JEFFERSON AR 72079 USA "
    },
    {
      "Location ID": "72081",
      "Location Name": "JUDSONIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 W WASHINGTON ST JUDSONIA AR 72081 USA "
    },
    {
      "Location ID": "72083",
      "Location Name": "KEO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 MAIN ST KEO AR 72083 USA "
    },
    {
      "Location ID": "72084",
      "Location Name": "LEOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 609 MAIN ST LEOLA AR 72084 USA "
    },
    {
      "Location ID": "72085",
      "Location Name": "LETONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 E MAIN ST LETONA AR 72085 USA "
    },
    {
      "Location ID": "72086",
      "Location Name": "LONOKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 705 E FRONT ST LONOKE AR 72086 USA "
    },
    {
      "Location ID": "72087",
      "Location Name": "LONSDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 PLEASANT RUN RD LONSDALE AR 72087 USA "
    },
    {
      "Location ID": "72088",
      "Location Name": "CPU FAIRFIELD BAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 548 DAVE CREEK PKWY FAIRFIELD BAY AR 72088-9998 USA "
    },
    {
      "Location ID": "72089",
      "Location Name": "BRYANT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2103 N REYNOLDS RD BRYANT AR 72089 USA "
    },
    {
      "Location ID": "72099",
      "Location Name": "LITTLE ROCK AIR FORCE BASE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 988 CANNON DR JACKSONVILLE AR 72076 USA "
    },
    {
      "Location ID": "720AH",
      "Location Name": "CPU ARKANSAS HEALTH CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6701 HIGHWAY 67 BENTON AR 72015 USA "
    },
    {
      "Location ID": "720AJ",
      "Location Name": "UALR MAILROOM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2801 S UNIVERSITY AVE LITTLE ROCK AR 72204-1000 USA "
    },
    {
      "Location ID": "720AP",
      "Location Name": "ADVANCE PRODUCTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 496 HIGHWAY 64 EAST CONWAY AR 72032 USA "
    },
    {
      "Location ID": "720AX",
      "Location Name": "LINDSEY RD ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6910 LINDSEY RD LITTLE ROCK AR 72206 USA "
    },
    {
      "Location ID": "720CP",
      "Location Name": "GRAVEL RIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7907 JACKSONVILLE CATO RD JACKSONVILLE AR 72076 USA "
    },
    {
      "Location ID": "720DC",
      "Location Name": "DOWNTOWN CONWAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1111 MAIN ST STE 107 CONWAY AR 72032 USA "
    },
    {
      "Location ID": "720DT",
      "Location Name": "CPU DOGWOOD TREE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 25255 HWY 5, SUITE H LONSDALE AR 72087 USA "
    },
    {
      "Location ID": "720FB",
      "Location Name": "CPU FAIRFIELD BAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 548 DAVE CREEK PKWY FAIRFIELD BAY AR 72088 USA "
    },
    {
      "Location ID": "720FS",
      "Location Name": "FIVE STAR AUTO PARTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2201 N REDMOND RD JACKSONVILLE AR 72076-2307 USA "
    },
    {
      "Location ID": "720FZ",
      "Location Name": "LOG LITTLE ROCK AR PSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 700 BURKHALTER DRIVE MAUMELLE AR 72113 USA "
    },
    {
      "Location ID": "720MC",
      "Location Name": "MY CRAFT SOURCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1423 MILITARY RD  BENTON AR 72015 USA "
    },
    {
      "Location ID": "720MT",
      "Location Name": "MAGNUM TARGETS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5021 HUGHES RD BAUXITE AR 72011-9568 USA "
    },
    {
      "Location ID": "720SW",
      "Location Name": "SMART WAREHOUSING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1031 N REDMOND RD JACKSONVILLE AR 72076-3657 USA "
    },
    {
      "Location ID": "72101",
      "Location Name": "MC CRORY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 N EDMONDS AVE MC CRORY AR 72101 USA "
    },
    {
      "Location ID": "72102",
      "Location Name": "MC RAE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 1ST ST MC RAE AR 72102 USA "
    },
    {
      "Location ID": "72103",
      "Location Name": "MABELVALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10715 MABELVALE WEST RD MABELVALE AR 72103 USA "
    },
    {
      "Location ID": "72104",
      "Location Name": "MALVERN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 715 MARTIN LUTHER KING BLVD OFC MALVERN AR 72104 USA "
    },
    {
      "Location ID": "72106",
      "Location Name": "MAYFLOWER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8 ASHMORE DR MAYFLOWER AR 72106 USA "
    },
    {
      "Location ID": "72107",
      "Location Name": "MENIFEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3919 HIGHWAY 64 MENIFEE AR 72107 USA "
    },
    {
      "Location ID": "72110",
      "Location Name": "MORRILTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 N MOOSE ST MORRILTON AR 72110 USA "
    },
    {
      "Location ID": "72111",
      "Location Name": "MOUNT VERNON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1375 HIGHWAY 36 MOUNT VERNON AR 72111 USA "
    },
    {
      "Location ID": "72112",
      "Location Name": "NEWPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 LAUREL ST NEWPORT AR 72112 USA "
    },
    {
      "Location ID": "72113",
      "Location Name": "MAUMELLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 COMMERCE DR MAUMELLE AR 72113 USA "
    },
    {
      "Location ID": "72114",
      "Location Name": "MAIN OFFICE N LITTLE ROCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 W PERSHING BLVD NORTH LITTLE ROCK AR 72114 USA "
    },
    {
      "Location ID": "72116",
      "Location Name": "PARKHILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4601 JOHN F KENNEDY BLVD NORTH LITTLE ROCK AR 72116 USA "
    },
    {
      "Location ID": "72117",
      "Location Name": "ROSE CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4903 E BROADWAY ST NORTH LITTLE ROCK AR 72117 USA "
    },
    {
      "Location ID": "72120",
      "Location Name": "SHERWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2300 WILDWOOD AVE SHERWOOD AR 72120 USA "
    },
    {
      "Location ID": "72121",
      "Location Name": "PANGBURN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 608 MAIN ST PANGBURN AR 72121 USA "
    },
    {
      "Location ID": "72122",
      "Location Name": "PARON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 22560 HIGHWAY 9 PARON AR 72122 USA "
    },
    {
      "Location ID": "72123",
      "Location Name": "PATTERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 S MAIN ST PATTERSON AR 72123 USA "
    },
    {
      "Location ID": "72125",
      "Location Name": "PERRY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3376 MAIN ST PERRY AR 72125 USA "
    },
    {
      "Location ID": "72126",
      "Location Name": "PERRYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 B ST PERRYVILLE AR 72126 USA "
    },
    {
      "Location ID": "72127",
      "Location Name": "PLUMERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 N SPRINGFIELD ST PLUMERVILLE AR 72127 USA "
    },
    {
      "Location ID": "72128",
      "Location Name": "POYEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 E FOURTH ST POYEN AR 72128 USA "
    },
    {
      "Location ID": "72129",
      "Location Name": "PRATTSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9113 HIGHWAY 270 W PRATTSVILLE AR 72129 USA "
    },
    {
      "Location ID": "72131",
      "Location Name": "QUITMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5770 HEBER SPRINGS RD W QUITMAN AR 72131 USA "
    },
    {
      "Location ID": "72132",
      "Location Name": "REDFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 SE RAILROAD ST REDFIELD AR 72132 USA "
    },
    {
      "Location ID": "72134",
      "Location Name": "ROE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 180 MAIN ST ROE AR 72134 USA "
    },
    {
      "Location ID": "72135",
      "Location Name": "ROLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 21111 CRIBBS ST ROLAND AR 72135 USA "
    },
    {
      "Location ID": "72136",
      "Location Name": "ROMANCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 292 HIGHWAY 31 ROMANCE AR 72136 USA "
    },
    {
      "Location ID": "72137",
      "Location Name": "ROSE BUD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6083 HIGHWAY 36 W ROSE BUD AR 72137 USA "
    },
    {
      "Location ID": "72139",
      "Location Name": "RUSSELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4409 HIGHWAY AVE RUSSELL AR 72139 USA "
    },
    {
      "Location ID": "72142",
      "Location Name": "SCOTT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14918 ALEXANDER RD SCOTT AR 72142 USA "
    },
    {
      "Location ID": "72143",
      "Location Name": "SEARCY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 600 W ACADEMY AVE SEARCY AR 72143 USA "
    },
    {
      "Location ID": "72150",
      "Location Name": "SHERIDAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 313 S OAK ST SHERIDAN AR 72150 USA "
    },
    {
      "Location ID": "72152",
      "Location Name": "SHERRILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 E MAIN ST SHERRILL AR 72152 USA "
    },
    {
      "Location ID": "72153",
      "Location Name": "SHIRLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 239 SAM ST SHIRLEY AR 72153 USA "
    },
    {
      "Location ID": "72156",
      "Location Name": "SOLGOHACHIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3142 HIGHWAY 9 SOLGOHACHIA AR 72156 USA "
    },
    {
      "Location ID": "72157",
      "Location Name": "SPRINGFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 765 STAGECOACH RD SPRINGFIELD AR 72157 USA "
    },
    {
      "Location ID": "72158",
      "Location Name": "STATE SERVICES (CPO)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6701 HIGHWAY 67 BENTON AR 72015-8909 USA "
    },
    {
      "Location ID": "72160",
      "Location Name": "STUTTGART",
      "Time Zone Offset (Hours)": -1,
      "Address": " 304 W MICHIGAN ST STUTTGART AR 72160 USA "
    },
    {
      "Location ID": "72164",
      "Location Name": "SWEET HOME",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7010 HIGHWAY 365 S SWEET HOME AR 72164 USA "
    },
    {
      "Location ID": "72167",
      "Location Name": "TRASKWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8108 HIGHWAY 229 TRASKWOOD AR 72167 USA "
    },
    {
      "Location ID": "72168",
      "Location Name": "TUCKER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 JOE T HENSLEE RD TUCKER AR 72168 USA "
    },
    {
      "Location ID": "72169",
      "Location Name": "TUPELO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11220 HIGHWAY 17 TUPELO AR 72169 USA "
    },
    {
      "Location ID": "72170",
      "Location Name": "ULM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 127 W 2ND ST ULM AR 72170 USA "
    },
    {
      "Location ID": "72173",
      "Location Name": "VILONIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8 S CHURCH ST VILONIA AR 72173 USA "
    },
    {
      "Location ID": "72175",
      "Location Name": "WABBASEKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 S 1ST ST WABBASEKA AR 72175 USA "
    },
    {
      "Location ID": "72176",
      "Location Name": "WARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 BALDING RD WARD AR 72176 USA "
    },
    {
      "Location ID": "72179",
      "Location Name": "WILBURN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4605 WILBURN RD WILBURN AR 72179 USA "
    },
    {
      "Location ID": "72180",
      "Location Name": "WOODSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24622 HIGHWAY 365 WOODSON AR 72180 USA "
    },
    {
      "Location ID": "72181",
      "Location Name": "WOOSTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 19 PATTON RD WOOSTER AR 72181 USA "
    },
    {
      "Location ID": "72183",
      "Location Name": "WRIGHTSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16220 HIGHWAY 365 WRIGHTSVILLE AR 72183 USA "
    },
    {
      "Location ID": "721AB",
      "Location Name": "VETERANS ADMINISTRATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2200 FORT ROOTS DR NORTH LITTLE ROCK AR 72114-1709 USA "
    },
    {
      "Location ID": "721AE",
      "Location Name": "HERITAGE PUBLISHING COMPANY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2402 WILDWOOD AVE SHERWOOD AR 72120-5084 USA "
    },
    {
      "Location ID": "721DI",
      "Location Name": "DILLARD'S FULFILLMENT CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 600 CARNAHAN DR MAUMELLE AR 72113 USA "
    },
    {
      "Location ID": "721IS",
      "Location Name": "ISLANDS IN THE SUN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 CHAMPS BLVD DOCK 37 MAUMELLE AR 72113 USA "
    },
    {
      "Location ID": "721SA",
      "Location Name": "SEARCY CARRIER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2701 E RACE AVE STE 1 SEARCY AR 72143 USA "
    },
    {
      "Location ID": "721TS",
      "Location Name": "TRI-STATE ENTERPRISES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2003 W 38TH ST NORTH LITTLE ROCK AR 72118 USA "
    },
    {
      "Location ID": "721VA",
      "Location Name": "VETERANS ADMINISTRATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2200 FORT ROOTS DR NORTH LITTLE ROCK AR 72114 USA "
    },
    {
      "Location ID": "72202",
      "Location Name": "MAIN OFFICE LITTLE ROCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 600 E CAPITOL AVE LITTLE ROCK AR 72202 USA "
    },
    {
      "Location ID": "72204",
      "Location Name": "ASHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7401 COLONEL GLENN RD LITTLE ROCK AR 72204 USA "
    },
    {
      "Location ID": "72205",
      "Location Name": "BRADY ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8313 W MARKHAM ST LITTLE ROCK AR 72205 USA "
    },
    {
      "Location ID": "72206",
      "Location Name": "SOUTHSIDE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1700 MAIN ST LITTLE ROCK AR 72206 USA "
    },
    {
      "Location ID": "72207",
      "Location Name": "FOREST PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5420 KAVANAUGH BLVD LITTLE ROCK AR 72207 USA "
    },
    {
      "Location ID": "72209",
      "Location Name": "INDUSTRIAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6909 BASELINE RD LITTLE ROCK AR 72209 USA "
    },
    {
      "Location ID": "72210",
      "Location Name": "OTTER CREEK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13501 OTTER CREEK PKWY LITTLE ROCK AR 72210 USA "
    },
    {
      "Location ID": "72211",
      "Location Name": "WESTSIDE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11415 HURON LN LITTLE ROCK AR 72211 USA "
    },
    {
      "Location ID": "72215",
      "Location Name": "BRADY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8509 W MARKHAM ST LITTLE ROCK AR 72205 USA "
    },
    {
      "Location ID": "72223",
      "Location Name": "CHENAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 815 TECHNOLOGY DR LITTLE ROCK AR 72223 USA "
    },
    {
      "Location ID": "72225",
      "Location Name": "HILLCREST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2803 KAVANAUGH BLVD LITTLE ROCK AR 72205 USA "
    },
    {
      "Location ID": "722AD",
      "Location Name": "ARKANSAS DISTRICT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 420 NATURAL RESOURCES DR LITTLE ROCK AR 72205 USA "
    },
    {
      "Location ID": "722AF",
      "Location Name": "BLUE CROSS BLUE SHIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2201 BROOKWOOD DR LITTLE ROCK AR 72202-1774 USA "
    },
    {
      "Location ID": "722AG",
      "Location Name": "BIG MAC BLDG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 W. CAPITAL LITTLE ROCK AR 72201-8000 USA "
    },
    {
      "Location ID": "722AK",
      "Location Name": "COMMERCIAL MAIL SVCS INC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 NORTH CROSS LITTLE ROCK AR 72201 USA "
    },
    {
      "Location ID": "722AR",
      "Location Name": "DISTRICT OFFICE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 420 NATURAL RESOURCES DR LITTLE ROCK AR 72205-1501 USA "
    },
    {
      "Location ID": "722AT",
      "Location Name": "DP & L",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6401 LINDSEY RD LITTLE ROCK AR 72206 USA "
    },
    {
      "Location ID": "722BC",
      "Location Name": "BLUE CROSS BLUE SHIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2201 BROOKWOOD DR LITTLE ROCK AR 72202 USA "
    },
    {
      "Location ID": "722BM",
      "Location Name": "BIG MAC BUILDING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 W. CAPITAL LITTLE ROCK AR 72201 USA "
    },
    {
      "Location ID": "722BO",
      "Location Name": "BOSPHOROUS ONLINE LLC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2400 CANTRELL RD, SUITE 105 LITTLE ROCK AR 72202 USA "
    },
    {
      "Location ID": "722CA",
      "Location Name": "LITTLE ROCK XMAS ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6001 LINDSEY RD LITTLE ROCK AR 72206 USA "
    },
    {
      "Location ID": "722CS",
      "Location Name": "CPU CHENAL 10 STORAGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24300 CHENAL PKWY LITTLE ROCK AR 72223 USA "
    },
    {
      "Location ID": "722FC",
      "Location Name": "FIRST CLASS BOOKS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6700 SLOANE DR LITTLE ROCK AR 72206-3873 USA "
    },
    {
      "Location ID": "722FX",
      "Location Name": "FEDEX HUB WESTPARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1500 WESTPARK DR LITTLE ROCK AR 72204 USA "
    },
    {
      "Location ID": "722SA",
      "Location Name": "CPU SANDERS ACE HARDWARE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20191 ARCH STREET PIKE LITTLE ROCK AR 72206 USA "
    },
    {
      "Location ID": "722UA",
      "Location Name": "UALR MAILROOM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2801 S UNIVERSITY AVE LITTLE ROCK AR 72204 USA "
    },
    {
      "Location ID": "722UP",
      "Location Name": "UPS - INTEGRATED",
      "Time Zone Offset (Hours)": -1,
      "Address": " 800 TEMPLE ST LITTLE ROCK AR 72202 USA "
    },
    {
      "Location ID": "723",
      "Location Name": "WEST MEMPHIS AR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 615 E COOPER WEST MEMPHIS AR 72301 USA "
    },
    {
      "Location ID": "72310",
      "Location Name": "ARMOREL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4606 N STATE HIGHWAY 137 ARMOREL AR 72310 USA "
    },
    {
      "Location ID": "72313",
      "Location Name": "BASSETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 414 HIGHWAY 61 BASSETT AR 72313 USA "
    },
    {
      "Location ID": "72315",
      "Location Name": "BLYTHEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 600 N BROADWAY ST BLYTHEVILLE AR 72315 USA "
    },
    {
      "Location ID": "72320",
      "Location Name": "BRICKEYS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12092 HIGHWAY 79 N BRICKEYS AR 72320 USA "
    },
    {
      "Location ID": "72321",
      "Location Name": "BURDETTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 157 PLANTATION DR BURDETTE AR 72321 USA "
    },
    {
      "Location ID": "72324",
      "Location Name": "CHERRY VALLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3239 HIGHWAY 42 CHERRY VALLEY AR 72324 USA "
    },
    {
      "Location ID": "72325",
      "Location Name": "CLARKEDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10555 HIGHWAY 77 CLARKEDALE AR 72325 USA "
    },
    {
      "Location ID": "72326",
      "Location Name": "COLT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8587 HIGHWAY 1 N COLT AR 72326 USA "
    },
    {
      "Location ID": "72327",
      "Location Name": "CRAWFORDSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5482 MAIN ST CRAWFORDSVILLE AR 72327 USA "
    },
    {
      "Location ID": "72330",
      "Location Name": "DYESS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 HIGHWAY 297 N DYESS AR 72330 USA "
    },
    {
      "Location ID": "72331",
      "Location Name": "EARLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 700 EXCHANGE ST EARLE AR 72331 USA "
    },
    {
      "Location ID": "72332",
      "Location Name": "EDMONDSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 94 WARD ST EDMONDSON AR 72332 USA "
    },
    {
      "Location ID": "72335",
      "Location Name": "FORREST CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 621 E BROADWAY ST FORREST CITY AR 72335 USA "
    },
    {
      "Location ID": "72338",
      "Location Name": "FRENCHMANS BAYOU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8071 S US HIGHWAY 61 FRENCHMANS BAYOU AR 72338 USA "
    },
    {
      "Location ID": "72339",
      "Location Name": "GILMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 S FRONT ST GILMORE AR 72339 USA "
    },
    {
      "Location ID": "72340",
      "Location Name": "GOODWIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 75 SFC 907 GOODWIN AR 72340 USA "
    },
    {
      "Location ID": "72342",
      "Location Name": "HELENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 617 WALNUT STE 103 HELENA AR 72342 USA "
    },
    {
      "Location ID": "72346",
      "Location Name": "HETH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 19763 HIGHWAY 70 E HETH AR 72346 USA "
    },
    {
      "Location ID": "72347",
      "Location Name": "HICKORY RIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 132 S FLORA ST HICKORY RIDGE AR 72347 USA "
    },
    {
      "Location ID": "72348",
      "Location Name": "HUGHES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 N STONEWALL ST HUGHES AR 72348 USA "
    },
    {
      "Location ID": "72350",
      "Location Name": "JOINER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 HIGHWAY 118 W JOINER AR 72350 USA "
    },
    {
      "Location ID": "72351",
      "Location Name": "KEISER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 MAIN ST KEISER AR 72351 USA "
    },
    {
      "Location ID": "72354",
      "Location Name": "LEPANTO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 HOLMES ST LEPANTO AR 72354 USA "
    },
    {
      "Location ID": "72358",
      "Location Name": "LUXORA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 N MAIN ST LUXORA AR 72358 USA "
    },
    {
      "Location ID": "72359",
      "Location Name": "MADISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S FIRST ST MADISON AR 72359 USA "
    },
    {
      "Location ID": "72360",
      "Location Name": "MARIANNA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 W MISSISSIPPI ST MARIANNA AR 72360 USA "
    },
    {
      "Location ID": "72364",
      "Location Name": "MARION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 140 BLOCK ST MARION AR 72364 USA "
    },
    {
      "Location ID": "72365",
      "Location Name": "MARKED TREE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 402 FRISCO ST MARKED TREE AR 72365 USA "
    },
    {
      "Location ID": "72370",
      "Location Name": "OSCEOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E JOHNSON AVE OSCEOLA AR 72370 USA "
    },
    {
      "Location ID": "72372",
      "Location Name": "PALESTINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 310 W PARKER ST PALESTINE AR 72372 USA "
    },
    {
      "Location ID": "72373",
      "Location Name": "PARKIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 S FRONT ST PARKIN AR 72373 USA "
    },
    {
      "Location ID": "72376",
      "Location Name": "PROCTOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5650 HIGHWAY 147 S PROCTOR AR 72376 USA "
    },
    {
      "Location ID": "72384",
      "Location Name": "TURRELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 237 BARTON ST TURRELL AR 72384 USA "
    },
    {
      "Location ID": "72386",
      "Location Name": "TYRONZA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 329 S MAIN ST TYRONZA AR 72386 USA "
    },
    {
      "Location ID": "72387",
      "Location Name": "VANNDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2905 HIGHWAY 1B VANNDALE AR 72387 USA "
    },
    {
      "Location ID": "72390",
      "Location Name": "WEST HELENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 221 N SEBASTIAN WEST HELENA AR 72390 USA "
    },
    {
      "Location ID": "72391",
      "Location Name": "WEST RIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5204 W STATE HIGHWAY 140 WEST RIDGE AR 72391 USA "
    },
    {
      "Location ID": "72392",
      "Location Name": "WHEATLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 LITTLE ROCK ST WHEATLEY AR 72392 USA "
    },
    {
      "Location ID": "72395",
      "Location Name": "WILSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3 PARK ST WILSON AR 72395 USA "
    },
    {
      "Location ID": "72396",
      "Location Name": "WYNNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 402 MERRIMAN AVE E WYNNE AR 72396 USA "
    },
    {
      "Location ID": "724",
      "Location Name": "NORTHEAST ARKANSAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3405 MOORE RD JONESBORO AR 72401 USA "
    },
    {
      "Location ID": "72425",
      "Location Name": "DELAPLAINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3523 HIGHWAY 90 DELAPLAINE AR 72425 USA "
    },
    {
      "Location ID": "72435",
      "Location Name": "KNOBEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 260 MAPLE ST KNOBEL AR 72435 USA "
    },
    {
      "Location ID": "72449",
      "Location Name": "O KEAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2233 N HIGHWAY 34 O KEAN AR 72449 USA "
    },
    {
      "Location ID": "72450",
      "Location Name": "PARAGOULD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W COURT ST PARAGOULD AR 72450 USA "
    },
    {
      "Location ID": "72453",
      "Location Name": "PEACH ORCHARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 SW ELM ST PEACH ORCHARD AR 72453 USA "
    },
    {
      "Location ID": "72472",
      "Location Name": "TRUMANN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 HIGHWAY 463 S TRUMANN AR 72472 USA "
    },
    {
      "Location ID": "72476",
      "Location Name": "WALNUT RIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 SW 3RD ST WALNUT RIDGE AR 72476 USA "
    },
    {
      "Location ID": "725",
      "Location Name": "BATESVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 490 E COLLEGE ST STE 139 BATESVILLE AR 72501 USA "
    },
    {
      "Location ID": "72501",
      "Location Name": "BATESVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 490 E COLLEGE ST STE 139 BATESVILLE AR 72501-9998 USA "
    },
    {
      "Location ID": "72513",
      "Location Name": "ASH FLAT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 34 W MAIN ST ASH FLAT AR 72513 USA "
    },
    {
      "Location ID": "72517",
      "Location Name": "BROCKWELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4047 N AR 9 HWY BROCKWELL AR 72517 USA "
    },
    {
      "Location ID": "72521",
      "Location Name": "CAVE CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 S MAIN ST CAVE CITY AR 72521 USA "
    },
    {
      "Location ID": "72523",
      "Location Name": "CONCORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10845 HEBER SPRINGS RD N CONCORD AR 72523 USA "
    },
    {
      "Location ID": "72524",
      "Location Name": "CORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8060 CORD RD CORD AR 72524 USA "
    },
    {
      "Location ID": "72525",
      "Location Name": "CHEROKEE VILLAGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 SANTEE DRIVE CHEROKEE VILLAG AR 72529 USA "
    },
    {
      "Location ID": "72526",
      "Location Name": "CUSHMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 COLLIETOWN RD CUSHMAN AR 72526 USA "
    },
    {
      "Location ID": "72527",
      "Location Name": "DESHA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20 GAINER FERRY RD DESHA AR 72527 USA "
    },
    {
      "Location ID": "72530",
      "Location Name": "DRASCO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6679 HEBER SPRINGS RD N DRASCO AR 72530 USA "
    },
    {
      "Location ID": "72532",
      "Location Name": "EVENING SHADE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2095 EVENING SHADE DR EVENING SHADE AR 72532 USA "
    },
    {
      "Location ID": "72536",
      "Location Name": "FRANKLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 780 HIGHWAY 56 E FRANKLIN AR 72536 USA "
    },
    {
      "Location ID": "72539",
      "Location Name": "GLENCOE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 32 HIGHWAY 289 S GLENCOE AR 72539 USA "
    },
    {
      "Location ID": "72540",
      "Location Name": "GUION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 MAIN ST GUION AR 72540 USA "
    },
    {
      "Location ID": "72542",
      "Location Name": "HARDY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 HIGHWAY 63 N HARDY AR 72542 USA "
    },
    {
      "Location ID": "72543",
      "Location Name": "HEBER SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1215 HIGHWAY 25B N HEBER SPRINGS AR 72543 USA "
    },
    {
      "Location ID": "72546",
      "Location Name": "IDA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4420 HEBER SPRINGS RD N IDA AR 72546 USA "
    },
    {
      "Location ID": "72550",
      "Location Name": "LOCUST GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4955 HEBER SPRINGS RD LOCUST GROVE AR 72550 USA "
    },
    {
      "Location ID": "72553",
      "Location Name": "MAGNESS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 95 BRITTNAY ST MAGNESS AR 72553 USA "
    },
    {
      "Location ID": "72554",
      "Location Name": "MAMMOTH SPRING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1075 MAIN ST MAMMOTH SPRING AR 72554 USA "
    },
    {
      "Location ID": "72555",
      "Location Name": "MARCELLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 HIDDEN RD MARCELLA AR 72555 USA "
    },
    {
      "Location ID": "72556",
      "Location Name": "MELBOURNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 LUNEN ST MELBOURNE AR 72556 USA "
    },
    {
      "Location ID": "72560",
      "Location Name": "MOUNTAIN VIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 802 SYLAMORE AVE MOUNTAIN VIEW AR 72560 USA "
    },
    {
      "Location ID": "72561",
      "Location Name": "MOUNT PLEASANT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 PEARL ST MOUNT PLEASANT AR 72561 USA "
    },
    {
      "Location ID": "72562",
      "Location Name": "NEWARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 N OAK ST NEWARK AR 72562 USA "
    },
    {
      "Location ID": "72564",
      "Location Name": "OIL TROUGH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 MAIN ST OIL TROUGH AR 72564 USA "
    },
    {
      "Location ID": "72565",
      "Location Name": "OXFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 270 N 1ST ST OXFORD AR 72565 USA "
    },
    {
      "Location ID": "72567",
      "Location Name": "PLEASANT GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 28433 HIGHWAY 14 PLEASANT GROVE AR 72567 USA "
    },
    {
      "Location ID": "72568",
      "Location Name": "PLEASANT PLAINS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 360 MAIN ST PLEASANT PLAINS AR 72568 USA "
    },
    {
      "Location ID": "72569",
      "Location Name": "POUGHKEEPSIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4066 ARKANSAS HIGHWAY 58 POUGHKEEPSIE AR 72569 USA "
    },
    {
      "Location ID": "72571",
      "Location Name": "ROSIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 39 CAPSHAW RD ROSIE AR 72571 USA "
    },
    {
      "Location ID": "72572",
      "Location Name": "SAFFELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 363 HIGHWAY 25 SAFFELL AR 72572 USA "
    },
    {
      "Location ID": "72573",
      "Location Name": "SAGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1545 AR 69B HWY SAGE AR 72573 USA "
    },
    {
      "Location ID": "72575",
      "Location Name": "SALADO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 959 NEWPORT RD SALADO AR 72575 USA "
    },
    {
      "Location ID": "72576",
      "Location Name": "SALEM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 510 HIGHWAY 62 W SALEM AR 72576 USA "
    },
    {
      "Location ID": "72577",
      "Location Name": "SIDNEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 308 ARKANSAS HIGHWAY 58 SIDNEY AR 72577 USA "
    },
    {
      "Location ID": "72578",
      "Location Name": "STURKIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5312 STURKIE RD STURKIE AR 72578-9998 USA "
    },
    {
      "Location ID": "72579",
      "Location Name": "SULPHUR ROCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 S MAIN ST SULPHUR ROCK AR 72579 USA "
    },
    {
      "Location ID": "72581",
      "Location Name": "TUMBLING SHOALS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2395 HEBER SPRINGS RD N TUMBLING SHOALS AR 72581 USA "
    },
    {
      "Location ID": "72583",
      "Location Name": "VIOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9971 HIGHWAY 62 W VIOLA AR 72583 USA "
    },
    {
      "Location ID": "725CV",
      "Location Name": "CPU CHEROKEE VILLAGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13 E CHEROKEE VILLAGE MALL CHEROKEE VILLAGE AR 72529 USA "
    },
    {
      "Location ID": "725HH",
      "Location Name": "HERBAL HEALER ACADEMY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 127 MCCAIN DRIVE MOUNTAIN VIEW AR 72560 USA "
    },
    {
      "Location ID": "725LP",
      "Location Name": "LIFE PLUS INTERNATIONAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 50 INDUSTRIAL DR BATESVILLE AR 72501 USA "
    },
    {
      "Location ID": "726",
      "Location Name": "HARRISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 W INDUSTRIAL PARK RD HARRISON AR 72601 USA "
    },
    {
      "Location ID": "727",
      "Location Name": "LOG NORTHWEST ARKANSAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2300 S CITY LAKE RD FAYETTEVILLE AR 72701 USA "
    },
    {
      "Location ID": "72730",
      "Location Name": "FARMINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 190 W CIMARRON PL FARMINGTON AR 72730 USA "
    },
    {
      "Location ID": "72735",
      "Location Name": "GOSHEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 188 W BOWEN BLVD GOSHEN AR 72735 USA "
    },
    {
      "Location ID": "72737",
      "Location Name": "GREENLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4500 S SCHOOL AVE STE P GREENLAND AR 72737 USA "
    },
    {
      "Location ID": "72738",
      "Location Name": "HINDSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 143 E VALLEY RD HINDSVILLE AR 72738 USA "
    },
    {
      "Location ID": "72740",
      "Location Name": "HUNTSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 CHURCH AVE HUNTSVILLE AR 72740 USA "
    },
    {
      "Location ID": "72742",
      "Location Name": "KINGSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5103 HIGHWAY 21 KINGSTON AR 72742 USA "
    },
    {
      "Location ID": "72744",
      "Location Name": "LINCOLN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 304 W PRIDEMORE DR LINCOLN AR 72744 USA "
    },
    {
      "Location ID": "72753",
      "Location Name": "PRAIRIE GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 221 W BUCHANAN ST PRAIRIE GROVE AR 72753 USA "
    },
    {
      "Location ID": "72769",
      "Location Name": "SUMMERS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10591 S HIGHWAY 59 SUMMERS AR 72769 USA "
    },
    {
      "Location ID": "72774",
      "Location Name": "WEST FORK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 WEBBER ST WEST FORK AR 72774 USA "
    },
    {
      "Location ID": "72776",
      "Location Name": "WITTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16734 HIGHWAY 23 WITTER AR 72776 USA "
    },
    {
      "Location ID": "728",
      "Location Name": "RUSSELLVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 S BOSTON AVE RUSSELLVILLE AR 72801 USA "
    },
    {
      "Location ID": "72801",
      "Location Name": "RUSSELLVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 S BOSTON AVE RUSSELLVILLE AR 72801 USA "
    },
    {
      "Location ID": "729",
      "Location Name": "FORT SMITH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3318 S 74TH ST FORT SMITH OK 72903 USA "
    },
    {
      "Location ID": "72901",
      "Location Name": "DOWNTOWN FORT SMITH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1020 GARRISON AVE FORT SMITH AR 72901 USA "
    },
    {
      "Location ID": "72904",
      "Location Name": "MIDLAND FORT SMITH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3100 WIRSING AVE FORT SMITH AR 72904 USA "
    },
    {
      "Location ID": "72908",
      "Location Name": "FIANNA HILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2712 MARKET TRCE FORT SMITH AR 72908 USA "
    },
    {
      "Location ID": "72921",
      "Location Name": "ALMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 524 HIGHWAY 64 E ALMA AR 72921 USA "
    },
    {
      "Location ID": "72923",
      "Location Name": "BARLING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 609 FORT ST BARLING AR 72923 USA "
    },
    {
      "Location ID": "72926",
      "Location Name": "BOLES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11992 HIGHWAY 71 S BOLES AR 72926 USA "
    },
    {
      "Location ID": "72927",
      "Location Name": "BOONEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 S BROADWAY AVE BOONEVILLE AR 72927 USA "
    },
    {
      "Location ID": "72928",
      "Location Name": "BRANCH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 510 HIGHWAY 22 W BRANCH AR 72928 USA "
    },
    {
      "Location ID": "72933",
      "Location Name": "CHARLESTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 E MAIN ST CHARLESTON AR 72933 USA "
    },
    {
      "Location ID": "72934",
      "Location Name": "CHESTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 405 E FRONT AVE CHESTER AR 72934 USA "
    },
    {
      "Location ID": "72935",
      "Location Name": "DYER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 N MAIN ST DYER AR 72935 USA "
    },
    {
      "Location ID": "72936",
      "Location Name": "GREENWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 450 E ATLANTA ST GREENWOOD AR 72936 USA "
    },
    {
      "Location ID": "72937",
      "Location Name": "HACKETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 402 N MAIN ST HACKETT AR 72937 USA "
    },
    {
      "Location ID": "72938",
      "Location Name": "HARTFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 N BROADWAY ST HARTFORD AR 72938 USA "
    },
    {
      "Location ID": "72940",
      "Location Name": "HUNTINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 HIGHWAY 71 SW HUNTINGTON AR 72940 USA "
    },
    {
      "Location ID": "72941",
      "Location Name": "LAVACA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 504 W MAIN ST LAVACA AR 72941 USA "
    },
    {
      "Location ID": "72943",
      "Location Name": "MAGAZINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 60 N STATE HIGHWAY 109 MAGAZINE AR 72943 USA "
    },
    {
      "Location ID": "72944",
      "Location Name": "MANSFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 310 E HOWARD ST MANSFIELD AR 72944 USA "
    },
    {
      "Location ID": "72945",
      "Location Name": "MIDLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 403 N 3RD ST MIDLAND AR 72945 USA "
    },
    {
      "Location ID": "72946",
      "Location Name": "MOUNTAINBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6 HICKORY ST MOUNTAINBURG AR 72946 USA "
    },
    {
      "Location ID": "72947",
      "Location Name": "MULBERRY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 221 N MAIN ST MULBERRY AR 72947 USA "
    },
    {
      "Location ID": "72949",
      "Location Name": "OZARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 E RIVER ST OZARK AR 72949 USA "
    },
    {
      "Location ID": "72950",
      "Location Name": "PARKS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10009 E HIGHWAY 28 PARKS AR 72950 USA "
    },
    {
      "Location ID": "72951",
      "Location Name": "RATCLIFF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9901 W STATE HIGHWAY 22 RATCLIFF AR 72951 USA "
    },
    {
      "Location ID": "72952",
      "Location Name": "RUDY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 224 N RAILROAD AVE RUDY AR 72952 USA "
    },
    {
      "Location ID": "72956",
      "Location Name": "VAN BUREN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2741 FAYETTEVILLE RD VAN BUREN AR 72956 USA "
    },
    {
      "Location ID": "72958",
      "Location Name": "WALDRON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 368 WASHINGTON ST WALDRON AR 72958 USA "
    },
    {
      "Location ID": "72959",
      "Location Name": "WINSLOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 383 N WINSLOW BLVD WINSLOW AR 72959 USA "
    },
    {
      "Location ID": "729AA",
      "Location Name": "GMF FORT SMITH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3318 S 74TH ST FORT SMITH AR 72903 USA "
    },
    {
      "Location ID": "729MO",
      "Location Name": "VAN BUREN MPO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2741 FAYETTEVILLE RD VAN BUREN AR 72956 USA "
    },
    {
      "Location ID": "729TS",
      "Location Name": "TRISTATE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5412 S 24TH ST FORT SMITH AR 72901 USA "
    },
    {
      "Location ID": "730",
      "Location Name": "OKLAHOMA CITY P&DC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4025 W RENO AVE OKLAHOMA CITY OK 73125 USA "
    },
    {
      "Location ID": "73002",
      "Location Name": "ALEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 S MAIN ST ALEX OK 73002 USA "
    },
    {
      "Location ID": "73003",
      "Location Name": "COFFEE CREEK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 407 W COVELL RD EDMOND OK 73003 USA "
    },
    {
      "Location ID": "73004",
      "Location Name": "AMBER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 402 E MAIN ST AMBER OK 73004 USA "
    },
    {
      "Location ID": "73005",
      "Location Name": "ANADARKO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 S 1ST ST ANADARKO OK 73005 USA "
    },
    {
      "Location ID": "73006",
      "Location Name": "APACHE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 E EVANS AVE APACHE OK 73006 USA "
    },
    {
      "Location ID": "73007",
      "Location Name": "ARCADIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 N ODOR ST ARCADIA OK 73007 USA "
    },
    {
      "Location ID": "73008",
      "Location Name": "BETHANY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6612 NW 38TH ST BETHANY OK 73008 USA "
    },
    {
      "Location ID": "73009",
      "Location Name": "BINGER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 224 W MAIN ST BINGER OK 73009 USA "
    },
    {
      "Location ID": "73010",
      "Location Name": "BLANCHARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1200 N COUNCIL AVE BLANCHARD OK 73010 USA "
    },
    {
      "Location ID": "73011",
      "Location Name": "BRADLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 S MCKEE ST BRADLEY OK 73011 USA "
    },
    {
      "Location ID": "73013",
      "Location Name": "CENTENNIAL STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 801 W 33RD ST EDMOND OK 73013 USA "
    },
    {
      "Location ID": "73014",
      "Location Name": "CALUMET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 E MAIN ST CALUMET OK 73014 USA "
    },
    {
      "Location ID": "73015",
      "Location Name": "CARNEGIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14 N BROADWAY ST CARNEGIE OK 73015 USA "
    },
    {
      "Location ID": "73016",
      "Location Name": "CASHION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 S MAIN ST CASHION OK 73016 USA "
    },
    {
      "Location ID": "73017",
      "Location Name": "CEMENT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 406 N MAIN ST CEMENT OK 73017 USA "
    },
    {
      "Location ID": "73018",
      "Location Name": "CHICKASHA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 702 W KANSAS AVE CHICKASHA OK 73018 USA "
    },
    {
      "Location ID": "73020",
      "Location Name": "CHOCTAW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14500 E GRAND ST CHOCTAW OK 73020 USA "
    },
    {
      "Location ID": "73022",
      "Location Name": "CONCHO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 S BLACK KETTLE BLVD CONCHO OK 73022 USA "
    },
    {
      "Location ID": "73027",
      "Location Name": "COYLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 E MAIN ST COYLE OK 73027 USA "
    },
    {
      "Location ID": "73028",
      "Location Name": "CRESCENT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 N GRAND ST CRESCENT OK 73028 USA "
    },
    {
      "Location ID": "73029",
      "Location Name": "CYRIL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 W MAIN ST CYRIL OK 73029 USA "
    },
    {
      "Location ID": "73030",
      "Location Name": "DAVIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 E MAIN ST DAVIS OK 73030 USA "
    },
    {
      "Location ID": "73031",
      "Location Name": "DIBBLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12252 STATE HIGHWAY 39 DIBBLE OK 73031 USA "
    },
    {
      "Location ID": "73032",
      "Location Name": "DOUGHERTY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 E MAIN ST DOUGHERTY OK 73032 USA "
    },
    {
      "Location ID": "73033",
      "Location Name": "EAKLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 404 W MAIN ST EAKLY OK 73033 USA "
    },
    {
      "Location ID": "73034",
      "Location Name": "EDMOND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 N BROADWAY EDMOND OK 73034 USA "
    },
    {
      "Location ID": "73036",
      "Location Name": "EL RENO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 N EVANS AVE EL RENO OK 73036 USA "
    },
    {
      "Location ID": "73038",
      "Location Name": "FORT COBB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 402 HAZLETT FORT COBB OK 73038 USA "
    },
    {
      "Location ID": "73040",
      "Location Name": "GEARY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 NW 1ST ST GEARY OK 73040 USA "
    },
    {
      "Location ID": "73041",
      "Location Name": "GOTEBO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11 W COMMERCIAL AVE GOTEBO OK 73041 USA "
    },
    {
      "Location ID": "73042",
      "Location Name": "GRACEMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W MAIN ST GRACEMONT OK 73042 USA "
    },
    {
      "Location ID": "73043",
      "Location Name": "GREENFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 MADISON ST GREENFIELD OK 73043 USA "
    },
    {
      "Location ID": "73044",
      "Location Name": "GUTHRIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W OKLAHOMA AVE GUTHRIE OK 73044 USA "
    },
    {
      "Location ID": "73045",
      "Location Name": "HARRAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 19900 NE 23RD ST HARRAH OK 73045 USA "
    },
    {
      "Location ID": "73047",
      "Location Name": "HINTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 S NOBLE ST HINTON OK 73047 USA "
    },
    {
      "Location ID": "73048",
      "Location Name": "HYDRO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 227 W MAIN ST HYDRO OK 73048 USA "
    },
    {
      "Location ID": "73049",
      "Location Name": "JONES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 E MAIN ST JONES OK 73049 USA "
    },
    {
      "Location ID": "73050",
      "Location Name": "LANGSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 WILEY JONES ST LANGSTON OK 73050 USA "
    },
    {
      "Location ID": "73051",
      "Location Name": "LEXINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 NE 2ND ST LEXINGTON OK 73051 USA "
    },
    {
      "Location ID": "73052",
      "Location Name": "LINDSAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 426 S MAIN ST LINDSAY OK 73052 USA "
    },
    {
      "Location ID": "73053",
      "Location Name": "LOOKEBA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W WICHITA AVE LOOKEBA OK 73053 USA "
    },
    {
      "Location ID": "73054",
      "Location Name": "LUTHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 506 S ASH ST LUTHER OK 73054 USA "
    },
    {
      "Location ID": "73055",
      "Location Name": "MARLOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 324 W MAIN ST MARLOW OK 73055 USA "
    },
    {
      "Location ID": "73056",
      "Location Name": "MARSHALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 221 E MAIN ST MARSHALL OK 73056 USA "
    },
    {
      "Location ID": "73057",
      "Location Name": "MAYSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 622 MAIN ST MAYSVILLE OK 73057 USA "
    },
    {
      "Location ID": "73058",
      "Location Name": "MERIDIAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E 5TH ST MERIDIAN OK 73058 USA "
    },
    {
      "Location ID": "73059",
      "Location Name": "MINCO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 MAIN ST MINCO OK 73059 USA "
    },
    {
      "Location ID": "73061",
      "Location Name": "MORRISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 WOOLSEY AVE MORRISON OK 73061 USA "
    },
    {
      "Location ID": "73062",
      "Location Name": "MOUNTAIN VIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 MAIN ST MOUNTAIN VIEW OK 73062 USA "
    },
    {
      "Location ID": "73063",
      "Location Name": "MULHALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 S LEWIS MULHALL OK 73063 USA "
    },
    {
      "Location ID": "73064",
      "Location Name": "MUSTANG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 600 N MUSTANG RD MUSTANG OK 73064 USA "
    },
    {
      "Location ID": "73065",
      "Location Name": "NEWCASTLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 N WRIGHT ST NEWCASTLE OK 73065 USA "
    },
    {
      "Location ID": "73066",
      "Location Name": "NICOMA PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2301 OVERHOLSER NICOMA PARK OK 73066 USA "
    },
    {
      "Location ID": "73067",
      "Location Name": "NINNEKAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 905 S WALNUT ST NINNEKAH OK 73067 USA "
    },
    {
      "Location ID": "73068",
      "Location Name": "NOBLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 MAPLE ST NOBLE OK 73068 USA "
    },
    {
      "Location ID": "73069",
      "Location Name": "NORMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 129 W GRAY ST NORMAN OK 73069 USA "
    },
    {
      "Location ID": "73072",
      "Location Name": "SOONER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 36TH AVE NORMAN OK 73072 USA "
    },
    {
      "Location ID": "73073",
      "Location Name": "ORLANDO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 404 5TH ST ORLANDO OK 73073 USA "
    },
    {
      "Location ID": "73074",
      "Location Name": "PAOLI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 OKLAHOMA ST PAOLI OK 73074 USA "
    },
    {
      "Location ID": "73075",
      "Location Name": "PAULS VALLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 W PAUL AVE PAULS VALLEY OK 73075 USA "
    },
    {
      "Location ID": "73077",
      "Location Name": "PERRY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 326 N 7TH ST PERRY OK 73077 USA "
    },
    {
      "Location ID": "73078",
      "Location Name": "PIEDMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 JACKSON AVE NW PIEDMONT OK 73078 USA "
    },
    {
      "Location ID": "73079",
      "Location Name": "POCASSET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 MAIN ST POCASSET OK 73079 USA "
    },
    {
      "Location ID": "73080",
      "Location Name": "PURCELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 228 W MAIN ST PURCELL OK 73080 USA "
    },
    {
      "Location ID": "73082",
      "Location Name": "RUSH SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 317 W BLAKELY ST RUSH SPRINGS OK 73082 USA "
    },
    {
      "Location ID": "73084",
      "Location Name": "SPENCER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3601 N SPENCER RD SPENCER OK 73084 USA "
    },
    {
      "Location ID": "73086",
      "Location Name": "SULPHUR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1100 W 2ND ST SULPHUR OK 73086 USA "
    },
    {
      "Location ID": "73089",
      "Location Name": "TUTTLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 407 E MAIN ST TUTTLE OK 73089 USA "
    },
    {
      "Location ID": "73090",
      "Location Name": "UNION CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 N MAIN UNION CITY OK 73090 USA "
    },
    {
      "Location ID": "73092",
      "Location Name": "VERDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 MORRIS AVE VERDEN OK 73092 USA "
    },
    {
      "Location ID": "73093",
      "Location Name": "WASHINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 N MAIN ST WASHINGTON OK 73093 USA "
    },
    {
      "Location ID": "73095",
      "Location Name": "WAYNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 S SHANNON ST WAYNE OK 73095 USA "
    },
    {
      "Location ID": "73096",
      "Location Name": "WEATHERFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 E FRANKLIN AVE WEATHERFORD OK 73096 USA "
    },
    {
      "Location ID": "73097",
      "Location Name": "WHEATLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6920 S MAIN ST WHEATLAND OK 73097 USA "
    },
    {
      "Location ID": "73098",
      "Location Name": "WYNNEWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 E ROBERT S KERR WYNNEWOOD OK 73098 USA "
    },
    {
      "Location ID": "73099",
      "Location Name": "YUKON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 GARTH S BROOKS AVE YUKON OK 73099 USA "
    },
    {
      "Location ID": "730FO",
      "Location Name": "FREEDOM OAK BARRELS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14310 N LINCOLN BLVD, STE 310 EDMOND OK 73013 USA "
    },
    {
      "Location ID": "730GL",
      "Location Name": "GLOBE LIFE & ACCIDENT INS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 133 NW 122ND ST OKLAHOMA CITY OK 73114 USA "
    },
    {
      "Location ID": "730OF",
      "Location Name": "ONE FAITH BOUTIQUE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 425 W MAIN ST YUKON OK 73099 USA "
    },
    {
      "Location ID": "730OO",
      "Location Name": "OLIVER AND OLIVIA APPAREL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3825 NW 166TH ST APT C3 EDMOND OK 73012 USA "
    },
    {
      "Location ID": "730PM",
      "Location Name": "PETRA INDUSTRIES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2101 S KELLY AVE OKLAHOMA CITY OK 73013 USA "
    },
    {
      "Location ID": "730PP",
      "Location Name": "PRINT THE PARTY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2217 S FRETZ AVE STE 110 EDMOND OK 73013-3799 USA "
    },
    {
      "Location ID": "730PT",
      "Location Name": "PACIFIC TRADING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1200 FRETZ AVE EDMOND OK 73003 USA "
    },
    {
      "Location ID": "730TF",
      "Location Name": "TEE FOR THE SOUL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1381 FRETZ DR EDMOND OK 73003 USA "
    },
    {
      "Location ID": "730TP",
      "Location Name": "TATE PUBLISHING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1093 S CORNWELL DR YUKON OK 73099-4554 USA "
    },
    {
      "Location ID": "730WI",
      "Location Name": "WIRELESS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 424 NE 144TH PL EDMOND OK 73013 USA "
    },
    {
      "Location ID": "73102",
      "Location Name": "CENTER CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 NW 5TH ST OKLAHOMA CITY OK 73102 USA "
    },
    {
      "Location ID": "73105",
      "Location Name": "Unknown",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1930 NE 24TH OKLAHOMA CITY OK 73105 USA "
    },
    {
      "Location ID": "73107",
      "Location Name": "FARLEY STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3621 NW 16TH ST OKLAHOMA CITY OK 73107 USA "
    },
    {
      "Location ID": "73108",
      "Location Name": "STOCKYARDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1101 S AGNEW AVE OKLAHOMA CITY OK 73108-9998 USA "
    },
    {
      "Location ID": "73110",
      "Location Name": "MIDWEST CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8275 E RENO AVE OKLAHOMA CITY OK 73110 USA "
    },
    {
      "Location ID": "73112",
      "Location Name": "THIRTY NINTH STREET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3022 W I 44 SERVICE RD OKLAHOMA CITY OK 73112 USA "
    },
    {
      "Location ID": "73114",
      "Location Name": "BRITTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 W BRITTON RD OKLAHOMA CITY OK 73114 USA "
    },
    {
      "Location ID": "73115",
      "Location Name": "DEL CITY FINANCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4500 SE 28TH ST DEL CITY OK 73115 USA "
    },
    {
      "Location ID": "73117",
      "Location Name": "MARTIN LUTHER KING JR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 701 N KELLEY AVE OKLAHOMA CITY OK 73117 USA "
    },
    {
      "Location ID": "73118",
      "Location Name": "SHARTEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5300 N SHARTEL AVE OKLAHOMA CITY OK 73118 USA "
    },
    {
      "Location ID": "73119",
      "Location Name": "SOUTHWEST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5701 S AGNEW AVE OKLAHOMA CITY OK 73119-9998 USA "
    },
    {
      "Location ID": "73120",
      "Location Name": "VILLAGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2304 W HEFNER RD OKLAHOMA CITY OK 73120 USA "
    },
    {
      "Location ID": "73129",
      "Location Name": "SOUTHEAST OKLAHOMA CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4801 S I 35 SERVICE RD OKLAHOMA CITY OK 73129 USA "
    },
    {
      "Location ID": "73132",
      "Location Name": "WARR ACRES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6001 NW 63RD ST OKLAHOMA CITY OK 73132 USA "
    },
    {
      "Location ID": "73136",
      "Location Name": "MARTIN LUTHER KING JR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 701 N KELLEY AVE OKLAHOMA CITY OK 73117-9998 USA "
    },
    {
      "Location ID": "73137",
      "Location Name": "RENO MERIDIAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4901 W RENO AVE STE 400 OKLAHOMA CITY OK 73127 USA "
    },
    {
      "Location ID": "73145",
      "Location Name": "TINKER AFB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3681 H AVE BLDG 478 TINKER AFB OK 73145 USA "
    },
    {
      "Location ID": "73148",
      "Location Name": "STOCKYARDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1101 S AGNEW AVE OKLAHOMA CITY OK 73108 USA "
    },
    {
      "Location ID": "73159",
      "Location Name": "PENN 89",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9201 S PENNSYLVANIA AVE OKLAHOMA CITY OK 73159 USA "
    },
    {
      "Location ID": "73160",
      "Location Name": "MOORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 601 S SERVICE RD MOORE OK 73160 USA "
    },
    {
      "Location ID": "73162",
      "Location Name": "HEFNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7800 W HEFNER RD OKLAHOMA CITY OK 73162 USA "
    },
    {
      "Location ID": "73170",
      "Location Name": "SANTA FE CARRIER UNIT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 SW 134TH ST OKLAHOMA CITY OK 73170 USA "
    },
    {
      "Location ID": "73179",
      "Location Name": "OKC AMF RETAIL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6500 AIR CARGO RD STE B OKLAHOMA CITY OK 73195 USA "
    },
    {
      "Location ID": "731AA",
      "Location Name": "AAFES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3681 H AVE BLDG 478 TINKER AFB OK 73145 USA "
    },
    {
      "Location ID": "731AB",
      "Location Name": "ABCD DISTRIBUTING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2710 N MOORE AVE MOORE OK 73160 USA "
    },
    {
      "Location ID": "731AM",
      "Location Name": "AMS HEALTH SCIENCES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 711 NE 39TH ST OKLAHOMA CITY OK 73105 USA "
    },
    {
      "Location ID": "731AR",
      "Location Name": "AMAZON RETURN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9201 S PORTLAND AVE OKLAHOMA CITY OK 73159 USA "
    },
    {
      "Location ID": "731AZ",
      "Location Name": "AMAZON OKC5",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1414 S COUNCIL RD OKLAHOMA CITY OK 73128 USA "
    },
    {
      "Location ID": "731BW",
      "Location Name": "BARNWOOD USA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6700 NEWCASTLE RD OKLAHOMA CITY OK 73179 USA "
    },
    {
      "Location ID": "731CB",
      "Location Name": "CBD PLUS USA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2121 S PORTLAND AVE OKLAHOMA CITY OK 73108 USA "
    },
    {
      "Location ID": "731CE",
      "Location Name": "CRAFTS ETC.",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7717 SW 44TH ST OKLAHOMA CITY OK 73179 USA "
    },
    {
      "Location ID": "731EF",
      "Location Name": "ENCORE FULFILLMENT #2",
      "Time Zone Offset (Hours)": -1,
      "Address": " 605 N TULSA AVE, BLDG 2 OKLAHOMA CITY OK 73107 USA "
    },
    {
      "Location ID": "731EN",
      "Location Name": "ENCORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3815 N SANTA FE AVE C OKLAHOMA CITY OK 73118 USA "
    },
    {
      "Location ID": "731ES",
      "Location Name": "ESELLSIMPLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 727 OVERHEAD DR OKLAHOMA CITY OK 73128 USA "
    },
    {
      "Location ID": "731HW",
      "Location Name": "HOME WETBAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 425 E HILL ST OKLAHOMA CITY OK 73105 USA "
    },
    {
      "Location ID": "731KQ",
      "Location Name": "KOSMOS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2800 S ANN ARBOR AVE OKLAHOMA CITY OK 73128 USA "
    },
    {
      "Location ID": "731MB",
      "Location Name": "MADE BY INFLUENCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3100 SOUTH MERIDIAN AVE OKLAHOMA CITY OK 73119 USA "
    },
    {
      "Location ID": "731MD",
      "Location Name": "MARDEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7737 SW 44TH ST OKLAHOMA CITY OK 73179 USA "
    },
    {
      "Location ID": "731MS",
      "Location Name": "MYRIAD SYSTEM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7627 E I-44 SERVICE RD OKLAHOMA CITY OK 73111 USA "
    },
    {
      "Location ID": "731MW",
      "Location Name": "METRO SHOE WAREHOUSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 560 E MEMORIAL RD OKLAHOMA CITY OK 73114 USA "
    },
    {
      "Location ID": "731PL",
      "Location Name": "PRIVATE LABEL SUPPLEMENTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1230 NW 5TH ST OKLAHOMA CITY OK 73106 USA "
    },
    {
      "Location ID": "731PV",
      "Location Name": "PERFECT VAPE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2305 S AGNEW AVE OKLAHOMA CITY OK 73180 USA "
    },
    {
      "Location ID": "731QG",
      "Location Name": "QUAD GRAPHICS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6801 S SUNNYLANE RD DEL CITY OK 73135 USA "
    },
    {
      "Location ID": "731SM",
      "Location Name": "METRO SHOE #2",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2210 W MEMORIAL RD, STE A OKLAHOMA CITY OK 73134 USA "
    },
    {
      "Location ID": "73301",
      "Location Name": "INTERNAL REVENUE SERVICE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2021 WOODWARD ST AUSTIN TX 78710 USA "
    },
    {
      "Location ID": "734",
      "Location Name": "ARDMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 1ST AVE SW ARDMORE OK 73401 USA "
    },
    {
      "Location ID": "73401",
      "Location Name": "ARDMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 1ST AVE SW ARDMORE OK 73401-9998 USA "
    },
    {
      "Location ID": "73425",
      "Location Name": "COUNTYLINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 W MAIN ST COUNTYLINE OK 73425 USA "
    },
    {
      "Location ID": "73432",
      "Location Name": "COLEMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12900 OK HIGHWAY 48A E COLEMAN OK 73432 USA "
    },
    {
      "Location ID": "73433",
      "Location Name": "ELMORE CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 S MAIN ST ELMORE CITY OK 73433 USA "
    },
    {
      "Location ID": "73434",
      "Location Name": "FOSTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 HIGHWAY 29A FOSTER OK 73434 USA "
    },
    {
      "Location ID": "73438",
      "Location Name": "HEALDTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 186 FRANKLIN ST HEALDTON OK 73438 USA "
    },
    {
      "Location ID": "73439",
      "Location Name": "KINGSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N MAIN ST KINGSTON OK 73439 USA "
    },
    {
      "Location ID": "73443",
      "Location Name": "LONE GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 25 HERITAGE ST LONE GROVE OK 73443 USA "
    },
    {
      "Location ID": "73446",
      "Location Name": "MADILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 223 W LILLIE BLVD MADILL OK 73446 USA "
    },
    {
      "Location ID": "73447",
      "Location Name": "MANNSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N 20TH AVE MANNSVILLE OK 73447 USA "
    },
    {
      "Location ID": "73448",
      "Location Name": "MARIETTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 W MAIN ST MARIETTA OK 73448 USA "
    },
    {
      "Location ID": "73449",
      "Location Name": "MEAD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 310 N MAIN ST MEAD OK 73449 USA "
    },
    {
      "Location ID": "73450",
      "Location Name": "MILBURN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 E I ST MILBURN OK 73450 USA "
    },
    {
      "Location ID": "73453",
      "Location Name": "OVERBROOK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 232 US HIGHWAY 77 OVERBROOK OK 73453 USA "
    },
    {
      "Location ID": "73455",
      "Location Name": "RAVIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 312 E GRAND ST RAVIA OK 73455 USA "
    },
    {
      "Location ID": "73456",
      "Location Name": "RINGLING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 N 4TH ST RINGLING OK 73456 USA "
    },
    {
      "Location ID": "73459",
      "Location Name": "THACKERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 281 S US HIGHWAY 77 THACKERVILLE OK 73459 USA "
    },
    {
      "Location ID": "73460",
      "Location Name": "TISHOMINGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 N NESHOBA ST TISHOMINGO OK 73460 USA "
    },
    {
      "Location ID": "73461",
      "Location Name": "WAPANUCKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 E MAIN ST WAPANUCKA OK 73461 USA "
    },
    {
      "Location ID": "73463",
      "Location Name": "WILSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1224 US HIGHWAY 70A WILSON OK 73463 USA "
    },
    {
      "Location ID": "73481",
      "Location Name": "RATLIFF CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 57 CHURCH ST RATLIFF CITY OK 73481 USA "
    },
    {
      "Location ID": "73491",
      "Location Name": "VELMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 503 MAIN ST VELMA OK 73491 USA "
    },
    {
      "Location ID": "734BB",
      "Location Name": "BEST BUY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3211 PRAIRIE VALLEY RD ARDMORE OK 73401 USA "
    },
    {
      "Location ID": "735",
      "Location Name": "LAWTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 S 5TH ST LAWTON OK 73501 USA "
    },
    {
      "Location ID": "73501",
      "Location Name": "LAWTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 S 5TH ST LAWTON OK 73501-9998 USA "
    },
    {
      "Location ID": "73501",
      "Location Name": "LAWTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 SW 5TH ST LAWTON OK 73501 USA "
    },
    {
      "Location ID": "73502",
      "Location Name": "SHERIDAN STA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6601 W GORE BLVD LAWTON OK 73505 USA "
    },
    {
      "Location ID": "73503",
      "Location Name": "FORT SILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 930 FORT SILL BLVD STE 100 FORT SILL OK 73503 USA "
    },
    {
      "Location ID": "73505",
      "Location Name": "CAMERON STA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6601 W GORE BLVD LAWTON OK 73505 USA "
    },
    {
      "Location ID": "73520",
      "Location Name": "ADDINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 PARKER ST ADDINGTON OK 73520 USA "
    },
    {
      "Location ID": "73521",
      "Location Name": "ALTUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 217 W CYPRESS ST ALTUS OK 73521 USA "
    },
    {
      "Location ID": "73526",
      "Location Name": "BLAIR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 E MAIN ST BLAIR OK 73526 USA "
    },
    {
      "Location ID": "73527",
      "Location Name": "CACHE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 601 S 8TH ST CACHE OK 73527 USA "
    },
    {
      "Location ID": "73528",
      "Location Name": "CHATTANOOGA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 MADISON AVE CHATTANOOGA OK 73528 USA "
    },
    {
      "Location ID": "73529",
      "Location Name": "COMANCHE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 S 2ND ST COMANCHE OK 73529 USA "
    },
    {
      "Location ID": "73530",
      "Location Name": "DAVIDSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 W GRAND ST DAVIDSON OK 73530 USA "
    },
    {
      "Location ID": "73531",
      "Location Name": "DEVOL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 W MULBERRY ST DEVOL OK 73531 USA "
    },
    {
      "Location ID": "73532",
      "Location Name": "DUKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 E 2ND ST DUKE OK 73532 USA "
    },
    {
      "Location ID": "73533",
      "Location Name": "DUNCAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 802 W WILLOW AVE DUNCAN OK 73533 USA "
    },
    {
      "Location ID": "73537",
      "Location Name": "ELDORADO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 N 4TH ST ELDORADO OK 73537 USA "
    },
    {
      "Location ID": "73538",
      "Location Name": "ELGIN",
      "Time Zone Offset (Hours)": -1,
      "Address": "8292 8292 STATE HIGHWAY 17 ELGIN OK 73538 USA "
    },
    {
      "Location ID": "73539",
      "Location Name": "ELMER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 MAIN ST ELMER OK 73539 USA "
    },
    {
      "Location ID": "73541",
      "Location Name": "FLETCHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 N SELBY FLETCHER OK 73541 USA "
    },
    {
      "Location ID": "73542",
      "Location Name": "FREDERICK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 E GRAND AVE FREDERICK OK 73542 USA "
    },
    {
      "Location ID": "73543",
      "Location Name": "GERONIMO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 E MAIN ST GERONIMO OK 73543 USA "
    },
    {
      "Location ID": "73544",
      "Location Name": "GOULD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 S KENNEDY AVE GOULD OK 73544 USA "
    },
    {
      "Location ID": "73546",
      "Location Name": "GRANDFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 E 2ND ST GRANDFIELD OK 73546 USA "
    },
    {
      "Location ID": "73547",
      "Location Name": "GRANITE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 402 N GRANITE ST GRANITE OK 73547 USA "
    },
    {
      "Location ID": "73548",
      "Location Name": "HASTINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 W MAIN ST HASTINGS OK 73548 USA "
    },
    {
      "Location ID": "73549",
      "Location Name": "HEADRICK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 310 N BROADWAY ST HEADRICK OK 73549 USA "
    },
    {
      "Location ID": "73550",
      "Location Name": "HOLLIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 N 2ND ST HOLLIS OK 73550 USA "
    },
    {
      "Location ID": "73552",
      "Location Name": "INDIAHOMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 308 MAIN ST INDIAHOMA OK 73552 USA "
    },
    {
      "Location ID": "73554",
      "Location Name": "MANGUM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S PENNSYLVANIA AVE MANGUM OK 73554 USA "
    },
    {
      "Location ID": "73555",
      "Location Name": "MANITOU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 HIGHWAY 183 MANITOU OK 73555 USA "
    },
    {
      "Location ID": "73556",
      "Location Name": "MARTHA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 E CHURCH ST MARTHA OK 73556 USA "
    },
    {
      "Location ID": "73557",
      "Location Name": "MEDICINE PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 191 E LAKE DR MEDICINE PARK OK 73557 USA "
    },
    {
      "Location ID": "73559",
      "Location Name": "MOUNTAIN PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 SPRUCE ST MOUNTAIN PARK OK 73559 USA "
    },
    {
      "Location ID": "73560",
      "Location Name": "OLUSTEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 E 4TH ST OLUSTEE OK 73560 USA "
    },
    {
      "Location ID": "73562",
      "Location Name": "RANDLETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S 6TH ST RANDLETT OK 73562 USA "
    },
    {
      "Location ID": "73564",
      "Location Name": "ROOSEVELT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 526 S MAIN ST ROOSEVELT OK 73564 USA "
    },
    {
      "Location ID": "73565",
      "Location Name": "RYAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1114 5TH ST RYAN OK 73565 USA "
    },
    {
      "Location ID": "73566",
      "Location Name": "SNYDER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 7TH ST SNYDER OK 73566 USA "
    },
    {
      "Location ID": "73567",
      "Location Name": "STERLING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 W MAIN ST STERLING OK 73567 USA "
    },
    {
      "Location ID": "73568",
      "Location Name": "TEMPLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 S COMMERCIAL ST TEMPLE OK 73568 USA "
    },
    {
      "Location ID": "73569",
      "Location Name": "TERRAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 309 E APACHE ST TERRAL OK 73569 USA "
    },
    {
      "Location ID": "73570",
      "Location Name": "TIPTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 E MAIN ST TIPTON OK 73570 USA "
    },
    {
      "Location ID": "73572",
      "Location Name": "WALTERS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 W COLORADO ST WALTERS OK 73572 USA "
    },
    {
      "Location ID": "73573",
      "Location Name": "WAURIKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 W BROADWAY AVE WAURIKA OK 73573 USA "
    },
    {
      "Location ID": "735BA",
      "Location Name": "BARRACK 6TH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1607 RANDOLPH RD FORT SILL OK 73503 USA "
    },
    {
      "Location ID": "735BB",
      "Location Name": "BARRACK 2-6",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1614 RANDOLPH RD FORT SILL OK 73503 USA "
    },
    {
      "Location ID": "735BC",
      "Location Name": "BARRACK 3-6",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2740 BRAGG RD FORT SILL OK 73503 USA "
    },
    {
      "Location ID": "735BD",
      "Location Name": "BARRACK 2-4",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3418 BABCOCK RD FORT SILL OK 73503 USA "
    },
    {
      "Location ID": "735BE",
      "Location Name": "BARRACK 3-13",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3420 BABCOCK RD FORT SILL OK 73503 USA "
    },
    {
      "Location ID": "735BF",
      "Location Name": "BARRACK 1-78",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4401 BLAIR ST FORT SILL OK 73503 USA "
    },
    {
      "Location ID": "735BG",
      "Location Name": "BARRACK 1-31",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5955 ROTHWELL ST FORT SILL OK 73503 USA "
    },
    {
      "Location ID": "735BJ",
      "Location Name": "BARRACK 1-19",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5960 ROTHWELL ST FORT SILL OK 73503 USA "
    },
    {
      "Location ID": "735BK",
      "Location Name": "95TH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6002 EDWARDS ST FORT SILL OK 73503 USA "
    },
    {
      "Location ID": "735BL",
      "Location Name": "BARRACK 1-79",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6007 ROTHWELL FORT SILL OK 73503 USA "
    },
    {
      "Location ID": "735BM",
      "Location Name": "434 BLDG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6017 BESSINGER ST FORT SILL OK 73503 USA "
    },
    {
      "Location ID": "735BN",
      "Location Name": "BARRACK 1-40",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6050 ROTHWELL ST FORT SILL OK 73503 USA "
    },
    {
      "Location ID": "735BS",
      "Location Name": "FORT SILL BARRACK STOP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1653 RANDOLPH RD FORT SILL OK 73503 USA "
    },
    {
      "Location ID": "735FS",
      "Location Name": "75TH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3440 KOEHLER LOOP FORT SILL OK 73503 USA "
    },
    {
      "Location ID": "735GC",
      "Location Name": "CPU R&S DRUG STORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3117 N HWY 81 DUNCAN OK 73533 USA "
    },
    {
      "Location ID": "735GO",
      "Location Name": "GOODNERS CONT STA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1949 S HWY 81 DUNCAN OK 73533-3083 USA "
    },
    {
      "Location ID": "735HF",
      "Location Name": "LAWTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 SW 5TH ST LAWTON OK 73501 USA "
    },
    {
      "Location ID": "735LO",
      "Location Name": "LOCATOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4700 MOW WAY RD FORT SILL OK 73503 USA "
    },
    {
      "Location ID": "735MP",
      "Location Name": "MP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2025 ADAMS RD FORT SILL OK 73503 USA "
    },
    {
      "Location ID": "735OS",
      "Location Name": "OSB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 745 GERONIMO RD FORT SILL OK 73503 USA "
    },
    {
      "Location ID": "736",
      "Location Name": "CLINTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 S 11TH ST CLINTON OK 73601 USA "
    },
    {
      "Location ID": "73601",
      "Location Name": "CLINTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 S 11TH ST CLINTON OK 73601-9998 USA "
    },
    {
      "Location ID": "73620",
      "Location Name": "ARAPAHO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 668 MAIN ST ARAPAHO OK 73620 USA "
    },
    {
      "Location ID": "73622",
      "Location Name": "BESSIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 709 MAIN ST BESSIE OK 73622 USA "
    },
    {
      "Location ID": "73624",
      "Location Name": "BURNS FLAT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 SOONER DR BURNS FLAT OK 73624 USA "
    },
    {
      "Location ID": "73625",
      "Location Name": "BUTLER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 N OLMSTEAD AVE BUTLER OK 73625 USA "
    },
    {
      "Location ID": "73626",
      "Location Name": "CANUTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 919 RTE 66 CANUTE OK 73626 USA "
    },
    {
      "Location ID": "73627",
      "Location Name": "CARTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 E MAIN ST CARTER OK 73627 USA "
    },
    {
      "Location ID": "73628",
      "Location Name": "CHEYENNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 S LL MALES CHEYENNE OK 73628 USA "
    },
    {
      "Location ID": "73632",
      "Location Name": "CORDELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 E 1ST ST CORDELL OK 73632 USA "
    },
    {
      "Location ID": "73639",
      "Location Name": "CUSTER CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 630 MAIN ST CUSTER CITY OK 73639 USA "
    },
    {
      "Location ID": "73641",
      "Location Name": "DILL CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 606 S MCDONALD ST DILL CITY OK 73641 USA "
    },
    {
      "Location ID": "73644",
      "Location Name": "ELK CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S ADAMS ST ELK CITY OK 73644 USA "
    },
    {
      "Location ID": "73645",
      "Location Name": "ERICK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 W 3RD ST ERICK OK 73645 USA "
    },
    {
      "Location ID": "73646",
      "Location Name": "FAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 82880 CUSTER FAY OK 73646 USA "
    },
    {
      "Location ID": "73647",
      "Location Name": "FOSS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 E BROADWAY ST FOSS OK 73647 USA "
    },
    {
      "Location ID": "73650",
      "Location Name": "HAMMON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 S MAIN ST HAMMON OK 73650 USA "
    },
    {
      "Location ID": "73651",
      "Location Name": "HOBART",
      "Time Zone Offset (Hours)": -1,
      "Address": " 229 S MAIN ST HOBART OK 73651 USA "
    },
    {
      "Location ID": "73654",
      "Location Name": "LEEDEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 515 S MAIN ST LEEDEY OK 73654 USA "
    },
    {
      "Location ID": "73655",
      "Location Name": "LONE WOLF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1024 MAIN ST LONE WOLF OK 73655 USA "
    },
    {
      "Location ID": "73658",
      "Location Name": "OAKWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 S MISSOURI ST OAKWOOD OK 73658 USA "
    },
    {
      "Location ID": "73660",
      "Location Name": "REYDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 2ND AVE REYDON OK 73660 USA "
    },
    {
      "Location ID": "73661",
      "Location Name": "ROCKY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 313 MAIN ST ROCKY OK 73661 USA "
    },
    {
      "Location ID": "73662",
      "Location Name": "SAYRE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 N 4TH ST SAYRE OK 73662 USA "
    },
    {
      "Location ID": "73663",
      "Location Name": "SEILING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 209 N WALNUT ST SEILING OK 73663 USA "
    },
    {
      "Location ID": "73664",
      "Location Name": "SENTINEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 312 E MAIN ST SENTINEL OK 73664 USA "
    },
    {
      "Location ID": "73667",
      "Location Name": "TALOGA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 S BROADWAY ST TALOGA OK 73667 USA "
    },
    {
      "Location ID": "73669",
      "Location Name": "THOMAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 N MAIN ST THOMAS OK 73669 USA "
    },
    {
      "Location ID": "73673",
      "Location Name": "WILLOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 W MAIN ST WILLOW OK 73673 USA "
    },
    {
      "Location ID": "736WM",
      "Location Name": "WESTERN MEDICAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 804 HWY 270, RR 1 BOX 84 SEILING OK 73663 USA "
    },
    {
      "Location ID": "737",
      "Location Name": "ENID",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 W BROADWAY AVE ENID OK 73701 USA "
    },
    {
      "Location ID": "73701",
      "Location Name": "ENID",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 W BROADWAY AVE ENID OK 73701-9998 USA "
    },
    {
      "Location ID": "73716",
      "Location Name": "ALINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 N MAIN ST ALINE OK 73716 USA "
    },
    {
      "Location ID": "73717",
      "Location Name": "ALVA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 408 GOVERNMENT ST ALVA OK 73717 USA "
    },
    {
      "Location ID": "73718",
      "Location Name": "AMES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 E MARIA GAUTIER BLVD AMES OK 73718 USA "
    },
    {
      "Location ID": "73719",
      "Location Name": "AMORITA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 E CHESTNUT AMORITA OK 73719 USA "
    },
    {
      "Location ID": "73720",
      "Location Name": "BISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 W MAIN ST BISON OK 73720 USA "
    },
    {
      "Location ID": "73722",
      "Location Name": "BURLINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 513 MAIN ST BURLINGTON OK 73722 USA "
    },
    {
      "Location ID": "73724",
      "Location Name": "CANTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 W MAIN ST CANTON OK 73724 USA "
    },
    {
      "Location ID": "73727",
      "Location Name": "CARRIER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E MAIN ST CARRIER OK 73727 USA "
    },
    {
      "Location ID": "73728",
      "Location Name": "CHEROKEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1780 S GRAND AVE CHEROKEE OK 73728 USA "
    },
    {
      "Location ID": "73729",
      "Location Name": "CLEO SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 N SQUARE ST CLEO SPRINGS OK 73729 USA "
    },
    {
      "Location ID": "73730",
      "Location Name": "COVINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 316 W MAIN ST COVINGTON OK 73730 USA "
    },
    {
      "Location ID": "73731",
      "Location Name": "DACOMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1000 MAIN ST DACOMA OK 73731 USA "
    },
    {
      "Location ID": "73734",
      "Location Name": "DOVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 N CHISHOLM TRL DOVER OK 73734 USA "
    },
    {
      "Location ID": "73735",
      "Location Name": "DRUMMOND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 319 MAIN ST DRUMMOND OK 73735 USA "
    },
    {
      "Location ID": "73736",
      "Location Name": "FAIRMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 620 MAIN ST FAIRMONT OK 73736 USA "
    },
    {
      "Location ID": "73737",
      "Location Name": "FAIRVIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 W BROADWAY FAIRVIEW OK 73737 USA "
    },
    {
      "Location ID": "73738",
      "Location Name": "GARBER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 MAIN ST GARBER OK 73738 USA "
    },
    {
      "Location ID": "73739",
      "Location Name": "GOLTRY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W MAIN GOLTRY OK 73739 USA "
    },
    {
      "Location ID": "73741",
      "Location Name": "HELENA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 N MAIN ST HELENA OK 73741 USA "
    },
    {
      "Location ID": "73742",
      "Location Name": "HENNESSEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 221 S MAIN ST HENNESSEY OK 73742 USA "
    },
    {
      "Location ID": "73743",
      "Location Name": "HILLSDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 S MAIN ST HILLSDALE OK 73743 USA "
    },
    {
      "Location ID": "73744",
      "Location Name": "HITCHCOCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 W MAIN ST HITCHCOCK OK 73744 USA "
    },
    {
      "Location ID": "73746",
      "Location Name": "HOPETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 19405 COUNTY ROAD 440 HOPETON OK 73746 USA "
    },
    {
      "Location ID": "73747",
      "Location Name": "ISABELLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 130 W MAIN ST ISABELLA OK 73747 USA "
    },
    {
      "Location ID": "73749",
      "Location Name": "JET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 418 MAIN ST JET OK 73749 USA "
    },
    {
      "Location ID": "73750",
      "Location Name": "KINGFISHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 S MAIN ST KINGFISHER OK 73750 USA "
    },
    {
      "Location ID": "73753",
      "Location Name": "KREMLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3415 E KEOWEE RD KREMLIN OK 73753 USA "
    },
    {
      "Location ID": "73754",
      "Location Name": "LAHOMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 535 MAIN ST LAHOMA OK 73754 USA "
    },
    {
      "Location ID": "73755",
      "Location Name": "LONGDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 KEN MILLER BLVD LONGDALE OK 73755 USA "
    },
    {
      "Location ID": "73758",
      "Location Name": "MANCHESTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 MAIN ST MANCHESTER OK 73758 USA "
    },
    {
      "Location ID": "73759",
      "Location Name": "MEDFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 W CHEROKEE ST MEDFORD OK 73759 USA "
    },
    {
      "Location ID": "73760",
      "Location Name": "MENO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 HIGHWAY 60 MENO OK 73760 USA "
    },
    {
      "Location ID": "73761",
      "Location Name": "NASH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 S MAIN ST NASH OK 73761 USA "
    },
    {
      "Location ID": "73762",
      "Location Name": "OKARCHE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 W OKLAHOMA ST OKARCHE OK 73762 USA "
    },
    {
      "Location ID": "73763",
      "Location Name": "OKEENE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 N 5TH ST OKEENE OK 73763 USA "
    },
    {
      "Location ID": "73766",
      "Location Name": "POND CREEK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 402 E GRAND AVE POND CREEK OK 73766 USA "
    },
    {
      "Location ID": "73768",
      "Location Name": "RINGWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 124 S MAIN ST RINGWOOD OK 73768 USA "
    },
    {
      "Location ID": "73771",
      "Location Name": "WAKITA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 E MAIN ST WAKITA OK 73771 USA "
    },
    {
      "Location ID": "73772",
      "Location Name": "WATONGA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 N NOBLE AVE WATONGA OK 73772 USA "
    },
    {
      "Location ID": "73773",
      "Location Name": "WAUKOMIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 504 E EUCLID AVE WAUKOMIS OK 73773 USA "
    },
    {
      "Location ID": "737FS",
      "Location Name": "FIRST SATURDAY LIME",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 W COLORADO RD OKARCHE OK 73762 USA "
    },
    {
      "Location ID": "738",
      "Location Name": "WOODWARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2020 WILLIAMS AVE WOODWARD OK 73801 USA "
    },
    {
      "Location ID": "73801",
      "Location Name": "WOODWARD, OK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2020 WILLIAMS AVE WOODWARD OK 73801 USA "
    },
    {
      "Location ID": "73834",
      "Location Name": "BUFFALO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 E ELM ST BUFFALO OK 73834 USA "
    },
    {
      "Location ID": "73835",
      "Location Name": "CAMARGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 S BROADWAY ST CAMARGO OK 73835 USA "
    },
    {
      "Location ID": "73840",
      "Location Name": "FARGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 216 S MAIN ST FARGO OK 73840 USA "
    },
    {
      "Location ID": "73841",
      "Location Name": "FORT SUPPLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 229 BROADWAY ST FORT SUPPLY OK 73841 USA "
    },
    {
      "Location ID": "73842",
      "Location Name": "FREEDOM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1055 MAIN ST FREEDOM OK 73842 USA "
    },
    {
      "Location ID": "73843",
      "Location Name": "GAGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 414 N MAIN ST GAGE OK 73843 USA "
    },
    {
      "Location ID": "73844",
      "Location Name": "GATE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 E MAIN ST GATE OK 73844 USA "
    },
    {
      "Location ID": "73848",
      "Location Name": "LAVERNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 W JANE JAYROE BLVD LAVERNE OK 73848 USA "
    },
    {
      "Location ID": "73851",
      "Location Name": "MAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 HIGHWAY 270 MAY OK 73851 USA "
    },
    {
      "Location ID": "73852",
      "Location Name": "MOORELAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 S MAIN ST MOORELAND OK 73852 USA "
    },
    {
      "Location ID": "73853",
      "Location Name": "MUTUAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 157 N BROADWAY AVE MUTUAL OK 73853 USA "
    },
    {
      "Location ID": "73857",
      "Location Name": "SHARON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 N MAIN ST SHARON OK 73857 USA "
    },
    {
      "Location ID": "73858",
      "Location Name": "SHATTUCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 507 S MAIN ST SHATTUCK OK 73858 USA "
    },
    {
      "Location ID": "73859",
      "Location Name": "VICI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 706 N MAIN ST VICI OK 73859 USA "
    },
    {
      "Location ID": "73860",
      "Location Name": "WAYNOKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1620 SANTA FE ST WAYNOKA OK 73860 USA "
    },
    {
      "Location ID": "740",
      "Location Name": "LOG TULSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2132 S 91ST EAST AVE TULSA OK 74141 USA "
    },
    {
      "Location ID": "74074",
      "Location Name": "STILLWATER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 809 S LEWIS ST STILLWATER OK 74076 USA "
    },
    {
      "Location ID": "741AB",
      "Location Name": "Resource One",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2900 E APACHE ST TULSA OK 74110 USA "
    },
    {
      "Location ID": "741PA",
      "Location Name": "TULSA PEAK ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13914 E ADMIRAL PL TULSA OK 74116 USA "
    },
    {
      "Location ID": "744",
      "Location Name": "MUSKOGEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 505 N MAIN ST MUSKOGEE OK 74401 USA "
    },
    {
      "Location ID": "74421",
      "Location Name": "BEGGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 N CHOCTAW ST BEGGS OK 74421 USA "
    },
    {
      "Location ID": "74445",
      "Location Name": "MORRIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 321 S 2ND ST MORRIS OK 74445 USA "
    },
    {
      "Location ID": "74447",
      "Location Name": "OKMULGEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 W 4TH ST OKMULGEE OK 74447 USA "
    },
    {
      "Location ID": "74456",
      "Location Name": "PRESTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10040 POLK AVE PRESTON OK 74456 USA "
    },
    {
      "Location ID": "745",
      "Location Name": "MCALESTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 E WASHINGTON AVE MC ALESTER OK 74501 USA "
    },
    {
      "Location ID": "74501",
      "Location Name": "MCALESTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 E WASHINGTON AVE MCALESTER OK 74501 USA "
    },
    {
      "Location ID": "74521",
      "Location Name": "ALBION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 S KENTUCKY ST ALBION OK 74521 USA "
    },
    {
      "Location ID": "74522",
      "Location Name": "ALDERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 49 W HIGHWAY 270 ALDERSON OK 74522 USA "
    },
    {
      "Location ID": "74523",
      "Location Name": "ANTLERS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 219 N HIGH ST ANTLERS OK 74523 USA "
    },
    {
      "Location ID": "74525",
      "Location Name": "ATOKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 409 E COURT ST ATOKA OK 74525 USA "
    },
    {
      "Location ID": "74530",
      "Location Name": "BROMIDE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 S MAIN ST BROMIDE OK 74530 USA "
    },
    {
      "Location ID": "74531",
      "Location Name": "CALVIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 CANADIAN ST CALVIN OK 74531 USA "
    },
    {
      "Location ID": "74533",
      "Location Name": "CANEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 W MAIN ST CANEY OK 74533 USA "
    },
    {
      "Location ID": "74535",
      "Location Name": "CLARITA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 MAIN ST CLARITA OK 74535 USA "
    },
    {
      "Location ID": "74536",
      "Location Name": "CLAYTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 N LAWSON BLVD CLAYTON OK 74536 USA "
    },
    {
      "Location ID": "74538",
      "Location Name": "COALGATE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 38 N MAIN ST COALGATE OK 74538 USA "
    },
    {
      "Location ID": "74546",
      "Location Name": "HAILEYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 509 PARKWAY AVE HAILEYVILLE OK 74546 USA "
    },
    {
      "Location ID": "74547",
      "Location Name": "HARTSHORNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1021 PENNSYLVANIA AVE HARTSHORNE OK 74547 USA "
    },
    {
      "Location ID": "74552",
      "Location Name": "KINTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 W BROADWAY ST KINTA OK 74552 USA "
    },
    {
      "Location ID": "74553",
      "Location Name": "KIOWA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 824 S HARRISON ST KIOWA OK 74553 USA "
    },
    {
      "Location ID": "74554",
      "Location Name": "KREBS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 40 S MAIN ST KREBS OK 74554 USA "
    },
    {
      "Location ID": "74555",
      "Location Name": "LANE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11820 E HIGHWAY 3 LANE OK 74555 USA "
    },
    {
      "Location ID": "74556",
      "Location Name": "LEHIGH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 N HIGHWAY 75 LEHIGH OK 74556 USA "
    },
    {
      "Location ID": "74559",
      "Location Name": "PANOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5755 HIGHWAY 270 E PANOLA OK 74559 USA "
    },
    {
      "Location ID": "74561",
      "Location Name": "QUINTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 911 E MAIN ST QUINTON OK 74561 USA "
    },
    {
      "Location ID": "74562",
      "Location Name": "RATTAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 W MAIN ST RATTAN OK 74562 USA "
    },
    {
      "Location ID": "74563",
      "Location Name": "RED OAK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 NE DIVISION ST RED OAK OK 74563 USA "
    },
    {
      "Location ID": "74565",
      "Location Name": "SAVANNA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15 S MAIN ST SAVANNA OK 74565 USA "
    },
    {
      "Location ID": "74569",
      "Location Name": "STRINGTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 164 E REBA MCENTIRE AVE STRINGTOWN OK 74569 USA "
    },
    {
      "Location ID": "74570",
      "Location Name": "STUART",
      "Time Zone Offset (Hours)": -1,
      "Address": " 619 ROOSEVELT AVE STUART OK 74570 USA "
    },
    {
      "Location ID": "74571",
      "Location Name": "TALIHINA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 403 DALLAS ST TALIHINA OK 74571 USA "
    },
    {
      "Location ID": "74572",
      "Location Name": "TUPELO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 403 MAIN ST TUPELO OK 74572 USA "
    },
    {
      "Location ID": "74574",
      "Location Name": "TUSKAHOMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 435689 E 1657 RD TUSKAHOMA OK 74574 USA "
    },
    {
      "Location ID": "74578",
      "Location Name": "WILBURTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 E MAIN ST WILBURTON OK 74578 USA "
    },
    {
      "Location ID": "745AN",
      "Location Name": "MCALESTER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 EXPRESS LN MCALESTER OK 74501 USA "
    },
    {
      "Location ID": "747",
      "Location Name": "DURANT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1223 W MAIN ST DURANT OK 74701 USA "
    },
    {
      "Location ID": "74728",
      "Location Name": "BROKEN BOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 405 MAIN ST BROKEN BOW OK 74728 USA "
    },
    {
      "Location ID": "74735",
      "Location Name": "FORT TOWSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 N MAIN ST FORT TOWSON OK 74735 USA "
    },
    {
      "Location ID": "74737",
      "Location Name": "GOLDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 LINE ST GOLDEN OK 74737 USA "
    },
    {
      "Location ID": "74740",
      "Location Name": "HAWORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 N ELM ST HAWORTH OK 74740 USA "
    },
    {
      "Location ID": "74743",
      "Location Name": "HUGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 207 N 4TH ST HUGO OK 74743 USA "
    },
    {
      "Location ID": "74745",
      "Location Name": "IDABEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 N CENTRAL AVE LBBY IDABEL OK 74745 USA "
    },
    {
      "Location ID": "74756",
      "Location Name": "SAWYER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 320 SAWYER DAM RD SAWYER OK 74756 USA "
    },
    {
      "Location ID": "74761",
      "Location Name": "SWINK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 FRONT ST SWINK OK 74761 USA "
    },
    {
      "Location ID": "74764",
      "Location Name": "VALLIANT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 N DALTON ST VALLIANT OK 74764 USA "
    },
    {
      "Location ID": "74766",
      "Location Name": "WRIGHT CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 W 10TH ST WRIGHT CITY OK 74766 USA "
    },
    {
      "Location ID": "748",
      "Location Name": "SHAWNEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 E 9TH ST SHAWNEE OK 74801 USA "
    },
    {
      "Location ID": "74820",
      "Location Name": "ADA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 131 E 12TH ST ADA OK 74820 USA "
    },
    {
      "Location ID": "74824",
      "Location Name": "AGRA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13 S MAIN ST AGRA OK 74824 USA "
    },
    {
      "Location ID": "74826",
      "Location Name": "ASHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 W MAIN ST ASHER OK 74826 USA "
    },
    {
      "Location ID": "74829",
      "Location Name": "BOLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 122 S PECAN ST BOLEY OK 74829 USA "
    },
    {
      "Location ID": "74830",
      "Location Name": "BOWLEGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 126 S MAIN BOWLEGS OK 74830 USA "
    },
    {
      "Location ID": "74831",
      "Location Name": "BYARS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 252 E RIPLEY ST BYARS OK 74831 USA "
    },
    {
      "Location ID": "74832",
      "Location Name": "CARNEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 406 SANTA FE ST CARNEY OK 74832 USA "
    },
    {
      "Location ID": "74833",
      "Location Name": "CASTLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 MAIN ST CASTLE OK 74833 USA "
    },
    {
      "Location ID": "74834",
      "Location Name": "CHANDLER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 W 9TH ST CHANDLER OK 74834 USA "
    },
    {
      "Location ID": "74837",
      "Location Name": "CROMWELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 N SHAWNEE AVE CROMWELL OK 74837 USA "
    },
    {
      "Location ID": "74839",
      "Location Name": "DUSTIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 127 N BROADWAY ST DUSTIN OK 74839 USA "
    },
    {
      "Location ID": "74840",
      "Location Name": "EARLSBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 W MAIN ST EARLSBORO OK 74840 USA "
    },
    {
      "Location ID": "74845",
      "Location Name": "HANNA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 S MAIN ST HANNA OK 74845 USA "
    },
    {
      "Location ID": "74848",
      "Location Name": "HOLDENVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 W 7TH ST HOLDENVILLE OK 74848 USA "
    },
    {
      "Location ID": "74849",
      "Location Name": "KONAWA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 S BROADWAY ST KONAWA OK 74849 USA "
    },
    {
      "Location ID": "74851",
      "Location Name": "MCLOUD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 700 S 8TH ST MCLOUD OK 74851 USA "
    },
    {
      "Location ID": "74852",
      "Location Name": "MACOMB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 36654 SECOND ST MACOMB OK 74852 USA "
    },
    {
      "Location ID": "74854",
      "Location Name": "MAUD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W MAIN ST MAUD OK 74854 USA "
    },
    {
      "Location ID": "74855",
      "Location Name": "MEEKER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 220 W CARL HUBBELL BLVD MEEKER OK 74855 USA "
    },
    {
      "Location ID": "74856",
      "Location Name": "MILL CREEK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 W MAIN ST MILL CREEK OK 74856 USA "
    },
    {
      "Location ID": "74857",
      "Location Name": "NEWALLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5900 S HARRAH RD NEWALLA OK 74857 USA "
    },
    {
      "Location ID": "74859",
      "Location Name": "OKEMAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 418 W BROADWAY ST OKEMAH OK 74859 USA "
    },
    {
      "Location ID": "74860",
      "Location Name": "PADEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 813 S MAIN ST PADEN OK 74860 USA "
    },
    {
      "Location ID": "74864",
      "Location Name": "PRAGUE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 804 W 9TH ST PRAGUE OK 74864 USA "
    },
    {
      "Location ID": "74865",
      "Location Name": "ROFF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 W MAIN ST ROFF OK 74865 USA "
    },
    {
      "Location ID": "74866",
      "Location Name": "SAINT LOUIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 34000 SAINT LOUIS RD SAINT LOUIS OK 74866 USA "
    },
    {
      "Location ID": "74867",
      "Location Name": "SASAKWA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 OLIVE ST SASAKWA OK 74867 USA "
    },
    {
      "Location ID": "74868",
      "Location Name": "SEMINOLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 E OAK AVE SEMINOLE OK 74868 USA "
    },
    {
      "Location ID": "74872",
      "Location Name": "STRATFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 E MAIN ST STRATFORD OK 74872 USA "
    },
    {
      "Location ID": "74873",
      "Location Name": "TECUMSEH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 W PARK ST TECUMSEH OK 74873 USA "
    },
    {
      "Location ID": "74875",
      "Location Name": "TRYON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10 S OKLAHOMA ST TRYON OK 74875 USA "
    },
    {
      "Location ID": "74878",
      "Location Name": "WANETTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 E MAIN ST WANETTE OK 74878 USA "
    },
    {
      "Location ID": "74880",
      "Location Name": "WELEETKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 W 9TH ST WELEETKA OK 74880 USA "
    },
    {
      "Location ID": "74881",
      "Location Name": "WELLSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 2ND ST WELLSTON OK 74881 USA "
    },
    {
      "Location ID": "74883",
      "Location Name": "WETUMKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 119 N MAIN ST WETUMKA OK 74883 USA "
    },
    {
      "Location ID": "74884",
      "Location Name": "WEWOKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 W 2ND ST WEWOKA OK 74884 USA "
    },
    {
      "Location ID": "750",
      "Location Name": "LOG NORTH TEXAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 951 W BETHEL RD COPPELL TX 75099 USA "
    },
    {
      "Location ID": "75001",
      "Location Name": "ADDISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4900 AIRPORT PKWY ADDISON TX 75001 USA "
    },
    {
      "Location ID": "75061",
      "Location Name": "IRVING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2701 W IRVING BLVD IRVING TX 75061 USA "
    },
    {
      "Location ID": "750AN",
      "Location Name": "DALLAS CHRISTMAS ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2040 MCKENZIE DR STE 125 CARROLLTON TX 75006 USA "
    },
    {
      "Location ID": "750AS",
      "Location Name": "ALLSTATE DMU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8901 ESTERS BLVD IRVING TX 76039 USA "
    },
    {
      "Location ID": "750BA",
      "Location Name": "BANK OF AMERICA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16001 DALLAS PKWY ADDISON TX 75001 USA "
    },
    {
      "Location ID": "750CP",
      "Location Name": "BROADRIDGE FIN SOLUTIONS DMU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 404 S ROYAL LN COPPELL TX 75019 USA "
    },
    {
      "Location ID": "750FZ",
      "Location Name": "LOG DALLAS TX PSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 602 FOUNTAIN PKWY GRAND PRARIE TX 75050 USA "
    },
    {
      "Location ID": "750GC",
      "Location Name": "G&C DIRECT MAIL MARKETING INC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1275 PROFIT DR DALLAS TX 75247 USA "
    },
    {
      "Location ID": "750KU",
      "Location Name": "KUBRA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 955 FREEPORT PKWY STE 200 COPPELL TX 75019 USA "
    },
    {
      "Location ID": "750PS",
      "Location Name": "PITNEY BOWES PRESORT SVCS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1101 POST N PADDOCK ST GRAND PRAIRIE TX 75050 USA "
    },
    {
      "Location ID": "750TM",
      "Location Name": "COPPELL MAIL BAG - 569",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 DIVIDEND DR COPPELL TX 75019 USA "
    },
    {
      "Location ID": "75102",
      "Location Name": "BARRY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 HOUSTON ST BARRY TX 75102 USA "
    },
    {
      "Location ID": "75110",
      "Location Name": "CORSICANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 S MAIN ST CORSICANA TX 75110 USA "
    },
    {
      "Location ID": "75119",
      "Location Name": "ENNIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 N MCKINNEY ST ENNIS TX 75119 USA "
    },
    {
      "Location ID": "75124",
      "Location Name": "EUSTACE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 US HIGHWAY 175 W EUSTACE TX 75124 USA "
    },
    {
      "Location ID": "75141",
      "Location Name": "HUTCHINS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 720 LANCASTER HUTCHINS RD HUTCHINS TX 75141 USA "
    },
    {
      "Location ID": "75146",
      "Location Name": "LANCASTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 N STATE ST LANCASTER TX 75146 USA "
    },
    {
      "Location ID": "75154",
      "Location Name": "RED OAK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 E OVILLA RD RED OAK TX 75154 USA "
    },
    {
      "Location ID": "75165",
      "Location Name": "WAXAHACHIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 316 N COLLEGE ST WAXAHACHIE TX 75165 USA "
    },
    {
      "Location ID": "752",
      "Location Name": "LOG DALLAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 TOM LANDRY HWY DALLAS TX 75260 USA "
    },
    {
      "Location ID": "75201",
      "Location Name": "DOWNTOWN DALLAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 N ERVAY ST DALLAS TX 75201 USA "
    },
    {
      "Location ID": "75208",
      "Location Name": "STATION A DALLAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 515 CENTRE ST DALLAS TX 75208 USA "
    },
    {
      "Location ID": "75211",
      "Location Name": "BEVERLY HILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2202 S COCKRELL HILL RD DALLAS TX 75211 USA "
    },
    {
      "Location ID": "752AD",
      "Location Name": "ADVO SYSTEMS INC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 600 N COCKRELLHILL RD DALLAS TX 75211 USA "
    },
    {
      "Location ID": "752DL",
      "Location Name": "DELTA MAIL TERMINAL (DFW)",
      "Time Zone Offset (Hours)": -1,
      "Address": " DFW AIRPORT DALLAS TX 75261 USA "
    },
    {
      "Location ID": "752SI",
      "Location Name": "STATE FARM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8225 BENT BRANCH DR IRVING TX 75063 USA "
    },
    {
      "Location ID": "753",
      "Location Name": "SCF 753",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 DFW TPKE DALLAS TX 75208 USA "
    },
    {
      "Location ID": "754",
      "Location Name": "GREENVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3701 STANFORD ST GREENVILLE TX 75401 USA "
    },
    {
      "Location ID": "75428",
      "Location Name": "COMMERCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1300 PARK ST COMMERCE TX 75428 USA "
    },
    {
      "Location ID": "75432",
      "Location Name": "COOPER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 150 E DALLAS AVE COOPER TX 75432 USA "
    },
    {
      "Location ID": "75460",
      "Location Name": "PARIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 CLARKSVILLE ST PARIS TX 75460 USA "
    },
    {
      "Location ID": "755",
      "Location Name": "TEXARKANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2211N ROBISON RD TEXARKANA TX 75501 USA "
    },
    {
      "Location ID": "75550",
      "Location Name": "ANNONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 E MAIN ST ANNONA TX 75550 USA "
    },
    {
      "Location ID": "75551",
      "Location Name": "ATLANTA TX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E MILLER ST ATLANTA TX 75551 USA "
    },
    {
      "Location ID": "75554",
      "Location Name": "AVERY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 340 FM 911 S AVERY TX 75554 USA "
    },
    {
      "Location ID": "75555",
      "Location Name": "BIVINS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 MORSE LN BIVINS TX 75555 USA "
    },
    {
      "Location ID": "75556",
      "Location Name": "BLOOMBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 411 S ARKANSAS AVE BLOOMSBURG TX 75556 USA "
    },
    {
      "Location ID": "75558",
      "Location Name": "COOKVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6648 US HIGHWAY 67 E COOKVILLE TX 75558 USA "
    },
    {
      "Location ID": "75559",
      "Location Name": "DE KALB",
      "Time Zone Offset (Hours)": -1,
      "Address": " 312 W FRONT ST DE KALB TX 75559 USA "
    },
    {
      "Location ID": "75560",
      "Location Name": "DOUGLASSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 W HIGHWAY 77 DOUGLASSVILLE TX 75560 USA "
    },
    {
      "Location ID": "75561",
      "Location Name": "HOOKS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15 E AVENUE A HOOKS TX 75561 USA "
    },
    {
      "Location ID": "75562",
      "Location Name": "KILDARE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1315 CO RD 1894 KILDARE TX 75562 USA "
    },
    {
      "Location ID": "75563",
      "Location Name": "LINDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 E RUSH ST LINDEN TX 75563 USA "
    },
    {
      "Location ID": "75564",
      "Location Name": "LODI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6819 FM 248 S LODI TX 75564 USA "
    },
    {
      "Location ID": "75565",
      "Location Name": "MC LEOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 19641 FM 125 S MCLEOD TX 75565 USA "
    },
    {
      "Location ID": "75566",
      "Location Name": "MARIETTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 S CENTRAL AVE MARIETTA TX 75566 USA "
    },
    {
      "Location ID": "75567",
      "Location Name": "MAUD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 366 HOUSTON ST MAUD TX 75567 USA "
    },
    {
      "Location ID": "75568",
      "Location Name": "NAPLES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 MAIN ST NAPLES TX 75568 USA "
    },
    {
      "Location ID": "75569",
      "Location Name": "NASH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1910 N KINGS HWY NASH TX 75569 USA "
    },
    {
      "Location ID": "75570",
      "Location Name": "NEW BOSTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 N WEST ST NEW BOSTON TX 75570 USA "
    },
    {
      "Location ID": "75571",
      "Location Name": "OMAHA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 E MAIN ST OMAHA TX 75571 USA "
    },
    {
      "Location ID": "75572",
      "Location Name": "QUEEN CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1014 HOUSTON ST QUEEN CITY TX 75572 USA "
    },
    {
      "Location ID": "75573",
      "Location Name": "REDWATER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 REDWATER BLVD W REDWATER TX 75573 USA "
    },
    {
      "Location ID": "75574",
      "Location Name": "SIMMS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7539 US HIGHWAY 67 W SIMMS TX 75574 USA "
    },
    {
      "Location ID": "755RC",
      "Location Name": "ROWE CASA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 175 ARKANSAS STREET NEW BOSTON TX 75570 USA "
    },
    {
      "Location ID": "75601",
      "Location Name": "DOWNTOWN LONGVIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 E METHVIN ST LONGVIEW TX 75601 USA "
    },
    {
      "Location ID": "75602",
      "Location Name": "LONGVIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2336 S MOBBERLY AVE LONGVIEW TX 75602 USA "
    },
    {
      "Location ID": "75605",
      "Location Name": "NORTHWEST LONGVIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4501 MCCANN RD LONGVIEW TX 75605 USA "
    },
    {
      "Location ID": "75630",
      "Location Name": "AVINGER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 TEXAS HIGHWAY 155 AVINGER TX 75630 USA "
    },
    {
      "Location ID": "75631",
      "Location Name": "BECKVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 125 S WASHINGTON ST BECKVILLE TX 75631 USA "
    },
    {
      "Location ID": "75633",
      "Location Name": "CARTHAGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 W WELLINGTON ST CARTHAGE TX 75633 USA "
    },
    {
      "Location ID": "75636",
      "Location Name": "CASON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4334 TEXAS STATE HIGHWAY 11 W CASON TX 75636 USA "
    },
    {
      "Location ID": "75637",
      "Location Name": "CLAYTON TX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4535 W HIGHWAY 315 CLAYTON TX 75637 USA "
    },
    {
      "Location ID": "75638",
      "Location Name": "DAINGERFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 W SCURRY ST DAINGERFIELD TX 75638 USA "
    },
    {
      "Location ID": "75639",
      "Location Name": "DE BERRY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7132 US HIGHWAY 79 N DE BERRY TX 75639 USA "
    },
    {
      "Location ID": "75640",
      "Location Name": "DIANA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2218 US HIGHWAY 259 S DIANA TX 75640 USA "
    },
    {
      "Location ID": "75642",
      "Location Name": "ELYSIAN FIELDS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 18154 FM 31 ELYSIAN FIELDS TX 75642 USA "
    },
    {
      "Location ID": "75643",
      "Location Name": "GARY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2601 FM 999 GARY TX 75643 USA "
    },
    {
      "Location ID": "75644",
      "Location Name": "GILMER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 405 N CYPRESS ST GILMER TX 75644 USA "
    },
    {
      "Location ID": "75647",
      "Location Name": "GLADEWATER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N DEAN ST GLADEWATER TX 75647 USA "
    },
    {
      "Location ID": "75650",
      "Location Name": "HALLSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 E MAIN ST HALLSVILLE TX 75650 USA "
    },
    {
      "Location ID": "75651",
      "Location Name": "HARLETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 17017 STATE HIGHWAY 154 HARLETON TX 75651 USA "
    },
    {
      "Location ID": "75652",
      "Location Name": "HENDERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 505 S MAIN ST HENDERSON TX 75654 USA "
    },
    {
      "Location ID": "75656",
      "Location Name": "HUGHES SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 E 2ND HUGHES SPRINGS TX 75656 USA "
    },
    {
      "Location ID": "75657",
      "Location Name": "JEFFERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 W LAFAYETTE ST JEFFERSON TX 75657 USA "
    },
    {
      "Location ID": "75658",
      "Location Name": "JOINERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7110 HIGHWAY 64 W JOINERVILLE TX 75658 USA "
    },
    {
      "Location ID": "75659",
      "Location Name": "JONESVILLE TX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2335 FM 134 JONESVILLE TX 75659 USA "
    },
    {
      "Location ID": "75660",
      "Location Name": "JUDSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6460 TRYON RD JUDSON TX 75660 USA "
    },
    {
      "Location ID": "75661",
      "Location Name": "KARNACK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15592 FM 134 KARNACK TX 75661 USA "
    },
    {
      "Location ID": "75662",
      "Location Name": "KILGORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 N KILGORE ST KILGORE TX 75662 USA "
    },
    {
      "Location ID": "75666",
      "Location Name": "LAIRD HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10992 STATE HIGHWAY 42 N LAIRD HILL TX 75666 USA "
    },
    {
      "Location ID": "75667",
      "Location Name": "LANEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13836 FM 225 S LANEVILLE TX 75667 USA "
    },
    {
      "Location ID": "75668",
      "Location Name": "LONE STAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 308 N MAIN ST LONE STAR TX 75668 USA "
    },
    {
      "Location ID": "75669",
      "Location Name": "LONG BRANCH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 671 FM 348 LONG BRANCH TX 75669 USA "
    },
    {
      "Location ID": "75670",
      "Location Name": "MARSHALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 E TRAVIS ST MARSHALL TX 75670 USA "
    },
    {
      "Location ID": "75680",
      "Location Name": "MINDEN, TX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11371 COUNTY RD. 317 S. HENDERSON TX 75680 USA "
    },
    {
      "Location ID": "75681",
      "Location Name": "MOUNT ENTERPRISE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 E RUSK ST MOUNT ENTERPRISE TX 75681 USA "
    },
    {
      "Location ID": "75682",
      "Location Name": "NEW LONDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10750 S MAIN ST NEW LONDON TX 75682 USA "
    },
    {
      "Location ID": "75683",
      "Location Name": "ORE CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 US HIGHWAY 259 S ORE CITY TX 75683 USA "
    },
    {
      "Location ID": "75684",
      "Location Name": "OVERTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 S RUSK ST OVERTON TX 75684 USA "
    },
    {
      "Location ID": "75685",
      "Location Name": "PANOLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 175 S FM 9 PANOLA TX 75685 USA "
    },
    {
      "Location ID": "75686",
      "Location Name": "PITTSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 151 E MARSHALL ST PITTSBURG TX 75686 USA "
    },
    {
      "Location ID": "75687",
      "Location Name": "PRICE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9031 FM 13 PRICE TX 75687 USA "
    },
    {
      "Location ID": "75688",
      "Location Name": "SCOTTSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8657 US HIGHWAY 80 E SCOTTSVILLE TX 75688 USA "
    },
    {
      "Location ID": "75689",
      "Location Name": "SELMAN CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10310 HIGHWAY 64 W SELMAN CITY TX 75689 USA "
    },
    {
      "Location ID": "75691",
      "Location Name": "TATUM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 165 N HOOD ST TATUM TX 75691 USA "
    },
    {
      "Location ID": "75692",
      "Location Name": "WASKOM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 265 SPUR 156 WASKOM TX 75692 USA "
    },
    {
      "Location ID": "75693",
      "Location Name": "WHITE OAK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1200 S WHITE OAK RD WHITE OAK TX 75693 USA "
    },
    {
      "Location ID": "75694",
      "Location Name": "WOODLAWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9620 US HIGHWAY 59 N WOODLAWN TX 75694 USA "
    },
    {
      "Location ID": "756SC",
      "Location Name": "SHARPS COMPLIANCE, INC.",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1544 NE LOOP CARTHAGE TX 75633 USA "
    },
    {
      "Location ID": "756SH",
      "Location Name": "SMALLWOOD HOMES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1604 E WHALEY ST LONGVIEW TX 75602 USA "
    },
    {
      "Location ID": "756SM",
      "Location Name": "SMALLWOOD HOMES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 605 E US HWY 80 WHITE OAK TX 75693 USA "
    },
    {
      "Location ID": "757",
      "Location Name": "EAST TEXAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12621 FM 3311 TYLER TX 75708 USA "
    },
    {
      "Location ID": "75703",
      "Location Name": "SOUTH TYLER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6722 S BROADWAY AVE STE 100 TYLER TX 75703 USA "
    },
    {
      "Location ID": "75712",
      "Location Name": "TYLER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2100 W MARTIN LUTHER KING JR BLVD TYLER TX 75702 USA "
    },
    {
      "Location ID": "75751",
      "Location Name": "ATHENS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 409 E TYLER ST ATHENS TX 75751 USA "
    },
    {
      "Location ID": "75756",
      "Location Name": "BROWNSBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14201 STATE HIGHWAY 31 E BROWNSBORO TX 75756 USA "
    },
    {
      "Location ID": "75758",
      "Location Name": "CHANDLER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 326 STATE HIGHWAY 31 E CHANDLER TX 75758 USA "
    },
    {
      "Location ID": "75760",
      "Location Name": "CUSHING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 602 7TH ST CUSHING TX 75760 USA "
    },
    {
      "Location ID": "75764",
      "Location Name": "GALLATIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 139 E FIRST AVE GALLATIN TX 75764 USA "
    },
    {
      "Location ID": "75766",
      "Location Name": "JACKSONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 W RUSK ST JACKSONVILLE TX 75766 USA "
    },
    {
      "Location ID": "75772",
      "Location Name": "MAYDELLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 156 COUNTY ROAD 1801 MAYDELLE TX 75772 USA "
    },
    {
      "Location ID": "75778",
      "Location Name": "MURCHISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6684 STATE HIGHWAY 31 MURCHISON TX 75778 USA "
    },
    {
      "Location ID": "75780",
      "Location Name": "NEW SUMMERFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13460 STATE HIGHWAY 110 N NEW SUMMERFIELD TX 75780 USA "
    },
    {
      "Location ID": "75784",
      "Location Name": "REKLAW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 320 N MAIN ST REKLAW TX 75784 USA "
    },
    {
      "Location ID": "75785",
      "Location Name": "RUSK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 W 5TH ST RUSK TX 75785 USA "
    },
    {
      "Location ID": "75788",
      "Location Name": "SACUL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 US HIGHWAY 204 SACUL TX 75788 USA "
    },
    {
      "Location ID": "757SF",
      "Location Name": "STRATEGIC FULFILLMENT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 N PEARL ST BIG SANDY TX 75755 USA "
    },
    {
      "Location ID": "758",
      "Location Name": "PALESTINE POST OFFICE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1213 N LINK ST PALESTINE TX 75803 USA "
    },
    {
      "Location ID": "75930",
      "Location Name": "BRONSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10850 US HIGHWAY 96 N BRONSON TX 75930 USA "
    },
    {
      "Location ID": "75931",
      "Location Name": "BROOKELAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15674 US HIGHWAY 96 N BROOKELAND TX 75931 USA "
    },
    {
      "Location ID": "75951",
      "Location Name": "JASPER TX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 N BOWIE ST JASPER TX 75951-9998 USA "
    },
    {
      "Location ID": "75957",
      "Location Name": "SAM RAYBURN TX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3881 SAM RAYBURN PKWY BROOKELAND TX 75931 USA "
    },
    {
      "Location ID": "75961",
      "Location Name": "NACOGDOCHES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3007 N UNIVERSITY DR NACOGDOCHES TX 75965 USA "
    },
    {
      "Location ID": "75962",
      "Location Name": "S.F.AUSTIN UNIV.",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1936 NORTH STREET NACOGDOCHES TX 75962 USA "
    },
    {
      "Location ID": "75968",
      "Location Name": "PINELAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 TIMBERLAND HWY PINELAND TX 75968 USA "
    },
    {
      "Location ID": "75972",
      "Location Name": "SAN AUGUSTINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 W MAIN ST SAN AUGUSTINE TX 75972 USA "
    },
    {
      "Location ID": "75H",
      "Location Name": "SOUTHERN AREA STC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4931 CONFLANS RD IRVING TX 75059 USA "
    },
    {
      "Location ID": "75UPS",
      "Location Name": "UPS MAIL INNOVATIONS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 WRANGLER DR STE 100 COPPELL TX 76019 USA "
    },
    {
      "Location ID": "75YRD",
      "Location Name": "DALLAS NDC YARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2400 DFW TPKE DALLAS TX 75211 USA "
    },
    {
      "Location ID": "75Z",
      "Location Name": "LOG DALLAS NDC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2400 DALLAS FT WORTH TPKE DALLAS TX 75398 USA "
    },
    {
      "Location ID": "760",
      "Location Name": "LOG FORT WORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4600 MARK IV PKWY FORT WORTH TX 76161 USA "
    },
    {
      "Location ID": "76008",
      "Location Name": "ALEDO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 412 OLD ANNETTA RD ALEDO TX 76008 USA "
    },
    {
      "Location ID": "76009",
      "Location Name": "ALVARADO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 E HIGHWAY 67 ALVARADO TX 76009 USA "
    },
    {
      "Location ID": "76020",
      "Location Name": "AZLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 409 COMMERCE ST AZLE TX 76020 USA "
    },
    {
      "Location ID": "76028",
      "Location Name": "BURLESON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 232 SW JOHNSON AVE BURLESON TX 76028 USA "
    },
    {
      "Location ID": "76031",
      "Location Name": "CLEBURNE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 FAIRCREST DR CLEBURNE TX 76033 USA "
    },
    {
      "Location ID": "76035",
      "Location Name": "CRESSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9300 PITTSBURG ST CRESSON TX 76035 USA "
    },
    {
      "Location ID": "76036",
      "Location Name": "CROWLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 S CROWLEY RD CROWLEY TX 76036 USA "
    },
    {
      "Location ID": "76041",
      "Location Name": "FORRESTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 406 CHERRY ST FORRESTON TX 76041 USA "
    },
    {
      "Location ID": "76043",
      "Location Name": "GLEN ROSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 NE BIG BEND TRL GLEN ROSE TX 76043 USA "
    },
    {
      "Location ID": "76044",
      "Location Name": "GODLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 S MAIN ST GODLEY TX 76044 USA "
    },
    {
      "Location ID": "76048",
      "Location Name": "GRANBURY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 530 N CROCKETT ST GRANBURY TX 76048 USA "
    },
    {
      "Location ID": "76049",
      "Location Name": "ACTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5561 N GATE RD GRANBURY TX 76049 USA "
    },
    {
      "Location ID": "76050",
      "Location Name": "GRANDVIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 E CRINER ST GRANDVIEW TX 76050 USA "
    },
    {
      "Location ID": "76052",
      "Location Name": "HASLET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1097 SCHOOLHOUSE RD HASLET TX 76052 USA "
    },
    {
      "Location ID": "76055",
      "Location Name": "ITASCA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 E ADAMS ST ITASCA TX 76055 USA "
    },
    {
      "Location ID": "76058",
      "Location Name": "JOSHUA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 N BROADWAY ST JOSHUA TX 76058 USA "
    },
    {
      "Location ID": "76059",
      "Location Name": "KEENE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 S OLD BETSY RD KEENE TX 76059 USA "
    },
    {
      "Location ID": "76060",
      "Location Name": "KENNEDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 317 E KENNEDALE PKWY KENNEDALE TX 76060 USA "
    },
    {
      "Location ID": "76061",
      "Location Name": "LILLIAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10061 E FM 917 LILLIAN TX 76061 USA "
    },
    {
      "Location ID": "76063",
      "Location Name": "MANSFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 752 N MAIN ST MANSFIELD TX 76063 USA "
    },
    {
      "Location ID": "76064",
      "Location Name": "MAYPEARL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 80 MAIN ST MAYPEARL TX 76064 USA "
    },
    {
      "Location ID": "76065",
      "Location Name": "MIDLOTHIAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 E AVENUE G MIDLOTHIAN TX 76065 USA "
    },
    {
      "Location ID": "76066",
      "Location Name": "MILLSAP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 FAIRVIEW RD MILLSAP TX 76066 USA "
    },
    {
      "Location ID": "76067",
      "Location Name": "MINERAL WELLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 SW 1ST AVE MINERAL WELLS TX 76067 USA "
    },
    {
      "Location ID": "76077",
      "Location Name": "RAINBOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1683 N FM 200 RAINBOW TX 76077 USA "
    },
    {
      "Location ID": "76082",
      "Location Name": "SPRINGTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 225 BONNIE BELL LN SPRINGTOWN TX 76082 USA "
    },
    {
      "Location ID": "76084",
      "Location Name": "VENUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 W 8TH ST VENUS TX 76084 USA "
    },
    {
      "Location ID": "76086",
      "Location Name": "WEATHERFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1145 SANTA FE DR WEATHERFORD TX 76086 USA "
    },
    {
      "Location ID": "76093",
      "Location Name": "RIO VISTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 HIGHWAY 174 S RIO VISTA TX 76093 USA "
    },
    {
      "Location ID": "760AN",
      "Location Name": "DALLAS PEAK ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1000 NOLEN DR STE 100 GRAPEVINE TX 76051 USA "
    },
    {
      "Location ID": "760FZ",
      "Location Name": "LOG FORT WORTH TX PSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 INTERMODAL PKWY STE 100 FORT WORTH TX 76177 USA "
    },
    {
      "Location ID": "76155",
      "Location Name": "AMON CARTER STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4400 AMON CARTER BLVD STE 103 FORT WORTH TX 76155 USA "
    },
    {
      "Location ID": "761DS",
      "Location Name": "DSG ARMS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9001 SOUTH FWY FORT WORTH TX 76140 USA "
    },
    {
      "Location ID": "761FC",
      "Location Name": "FORT WORTH CHRISTMAS ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 TERMINAL RD STE 3 FT WORTH TX 76106 USA "
    },
    {
      "Location ID": "761FX",
      "Location Name": "761FX FEDEX GROUND - ALLIANCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2701 TEXAS LONGHORN WAY FORT WORTH TX 76177 USA "
    },
    {
      "Location ID": "762",
      "Location Name": "DENTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E MCKINNEY ST DENTON TX 76201 USA "
    },
    {
      "Location ID": "76201",
      "Location Name": "DENTON, TX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E MCKINNEY ST DENTON TX 76201 USA "
    },
    {
      "Location ID": "76204",
      "Location Name": "TEXAS WOMANS UNIVERSITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 TWU STUDENT BUILDING DENTON TX 76204 USA "
    },
    {
      "Location ID": "76206",
      "Location Name": "GTFU DENTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1113 DALLAS DRIVE DENTON TX 76206 USA "
    },
    {
      "Location ID": "76227",
      "Location Name": "AUBREY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 STANLEY DR AUBREY TX 76227 USA "
    },
    {
      "Location ID": "76233",
      "Location Name": "COLLINSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 N MAIN ST COLLINSVILLE TX 76233 USA "
    },
    {
      "Location ID": "76234",
      "Location Name": "DECATUR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 E WALNUT ST DECATUR TX 76234 USA "
    },
    {
      "Location ID": "76239",
      "Location Name": "FORESTBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 16928 FM 455 FORESTBURG TX 76239 USA "
    },
    {
      "Location ID": "76240",
      "Location Name": "GAINESVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 321 E CALIFORNIA ST GAINESVILLE TX 76240 USA "
    },
    {
      "Location ID": "76245",
      "Location Name": "GORDONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 17371 FM 901 GORDONVILLE TX 76245 USA "
    },
    {
      "Location ID": "76247",
      "Location Name": "JUSTIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 W 5TH ST JUSTIN TX 76247 USA "
    },
    {
      "Location ID": "76249",
      "Location Name": "KRUM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 N 2ND ST KRUM TX 76249 USA "
    },
    {
      "Location ID": "76250",
      "Location Name": "LINDSAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 625 N ASH ST LINDSAY TX 76250 USA "
    },
    {
      "Location ID": "76252",
      "Location Name": "MUENSTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 235 W 2ND ST MUENSTER TX 76252 USA "
    },
    {
      "Location ID": "76255",
      "Location Name": "NOCONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 CLAY ST NOCONA TX 76255 USA "
    },
    {
      "Location ID": "76258",
      "Location Name": "PILOT POINT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 N WASHINGTON ST PILOT POINT TX 76258 USA "
    },
    {
      "Location ID": "76259",
      "Location Name": "PONDER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W BAILEY ST PONDER TX 76259 USA "
    },
    {
      "Location ID": "76264",
      "Location Name": "SADLER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 S MAIN ST SADLER TX 76264 USA "
    },
    {
      "Location ID": "76265",
      "Location Name": "SAINT JO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 S BROAD ST SAINT JO TX 76265 USA "
    },
    {
      "Location ID": "76266",
      "Location Name": "SANGER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1008 N 5TH ST SANGER TX 76266 USA "
    },
    {
      "Location ID": "76267",
      "Location Name": "SLIDELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 COUNTY ROAD 2820 SLIDELL TX 76267 USA "
    },
    {
      "Location ID": "76268",
      "Location Name": "SOUTHMAYD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 CHRISTI LN SOUTHMAYD TX 76268 USA "
    },
    {
      "Location ID": "76271",
      "Location Name": "TIOGA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 N RAY ROBERTS PKWY TIOGA TX 76271 USA "
    },
    {
      "Location ID": "76272",
      "Location Name": "VALLEY VIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 409 N FRONTAGE RD VALLEY VIEW TX 76272 USA "
    },
    {
      "Location ID": "76273",
      "Location Name": "WHITESBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 W MAIN ST WHITESBORO TX 76273 USA "
    },
    {
      "Location ID": "762SB",
      "Location Name": "SMITH BROTHERS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2954 GANZAR RD WEST DENTON TX 76207 USA "
    },
    {
      "Location ID": "76367",
      "Location Name": "IOWA PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 E CASH ST IOWA PARK TX 76367 USA "
    },
    {
      "Location ID": "76372",
      "Location Name": "NEWCASTLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 800 BROADWAY NEWCASTLE TX 76372 USA "
    },
    {
      "Location ID": "764",
      "Location Name": "STEPHENVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 431 N GRAHAM ST STEPHENVILLE TX 76401 USA "
    },
    {
      "Location ID": "76401",
      "Location Name": "STEPHENVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 431 N GRAHAM ST STEPHENVILLE TX 76401 USA "
    },
    {
      "Location ID": "76424",
      "Location Name": "BRECKENRIDGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 W WILLIAMS ST BRECKENRIDGE TX 76424 USA "
    },
    {
      "Location ID": "76426",
      "Location Name": "BRIDGEPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1305 HALSELL ST BRIDGEPORT TX 76426 USA "
    },
    {
      "Location ID": "76427",
      "Location Name": "BRYSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 N DEPOT ST BRYSON TX 76427 USA "
    },
    {
      "Location ID": "76429",
      "Location Name": "CADDO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14075 LOOP 252 CADDO TX 76429 USA "
    },
    {
      "Location ID": "76430",
      "Location Name": "ALBANY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 24 N JACOBS ST ALBANY TX 76430 USA "
    },
    {
      "Location ID": "76432",
      "Location Name": "BLANKET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 801 MAIN ST BLANKET TX 76432 USA "
    },
    {
      "Location ID": "76435",
      "Location Name": "CARBON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 MAIN ST CARBON TX 76435 USA "
    },
    {
      "Location ID": "76436",
      "Location Name": "CARLTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 139 S FM 1744 CARLTON TX 76436 USA "
    },
    {
      "Location ID": "76437",
      "Location Name": "CISCO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 W 6TH ST CISCO TX 76437 USA "
    },
    {
      "Location ID": "76439",
      "Location Name": "DENNIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6940 FM 1189 DENNIS TX 76439 USA "
    },
    {
      "Location ID": "76442",
      "Location Name": "COMANCHE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 W OAK AVE COMANCHE TX 76442 USA "
    },
    {
      "Location ID": "76444",
      "Location Name": "DE LEON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 540 W NAVARRO AVE DE LEON TX 76444 USA "
    },
    {
      "Location ID": "76445",
      "Location Name": "DESDEMONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6016 HIGHWAY 8 DESDEMONA TX 76445 USA "
    },
    {
      "Location ID": "76446",
      "Location Name": "DUBLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 222 N PATRICK ST DUBLIN TX 76446 USA "
    },
    {
      "Location ID": "76448",
      "Location Name": "EASTLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 411 W MAIN ST EASTLAND TX 76448 USA "
    },
    {
      "Location ID": "76449",
      "Location Name": "GRAFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 N MAIN ST GRAFORD TX 76449 USA "
    },
    {
      "Location ID": "76450",
      "Location Name": "GRAHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 TENNESSEE ST GRAHAM TX 76450 USA "
    },
    {
      "Location ID": "76453",
      "Location Name": "GORDON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 S MAIN ST GORDON TX 76453 USA "
    },
    {
      "Location ID": "76454",
      "Location Name": "GORMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 N KENT ST GORMAN TX 76454 USA "
    },
    {
      "Location ID": "76457",
      "Location Name": "HICO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 112 E 1ST ST HICO TX 76457 USA "
    },
    {
      "Location ID": "76458",
      "Location Name": "JACKSBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 E SOUTH ST JACKSBORO TX 76458 USA "
    },
    {
      "Location ID": "76459",
      "Location Name": "JERMYN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7664 STATE HIGHWAY 114 JERMYN TX 76459 USA "
    },
    {
      "Location ID": "76460",
      "Location Name": "LOVING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 HAWKINS ST LOVING TX 76460 USA "
    },
    {
      "Location ID": "76461",
      "Location Name": "LINGLEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 18999 W FM 8 LINGLEVILLE TX 76461 USA "
    },
    {
      "Location ID": "76463",
      "Location Name": "MINGUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 GORDON RD MINGUS TX 76463 USA "
    },
    {
      "Location ID": "76464",
      "Location Name": "MORAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 340 WATERS ST MORAN TX 76464 USA "
    },
    {
      "Location ID": "76466",
      "Location Name": "OLDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 526 FM 3363 OLDEN TX 76466 USA "
    },
    {
      "Location ID": "76468",
      "Location Name": "PROCTOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1270 HIGHWAY 1476 PROCTOR TX 76468 USA "
    },
    {
      "Location ID": "76470",
      "Location Name": "RANGER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 N AUSTIN ST RANGER TX 76470 USA "
    },
    {
      "Location ID": "76472",
      "Location Name": "SANTO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 58 BELL ST SANTO TX 76472 USA "
    },
    {
      "Location ID": "76475",
      "Location Name": "STRAWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 E HOUSLEY ST STRAWN TX 76475 USA "
    },
    {
      "Location ID": "76476",
      "Location Name": "TOLAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9101 W US HIGHWAY 377 TOLAR TX 76476 USA "
    },
    {
      "Location ID": "76483",
      "Location Name": "THROCKMORTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 233 N MINTER AVE THROCKMORTON TX 76483 USA "
    },
    {
      "Location ID": "76484",
      "Location Name": "PALO PINTO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 N 5TH AVE PALO PINTO TX 76484 USA "
    },
    {
      "Location ID": "76486",
      "Location Name": "PERRIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 319 W HACKLEY ST PERRIN TX 76486 USA "
    },
    {
      "Location ID": "76491",
      "Location Name": "WOODSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 HIGHWAY 183 N WOODSON TX 76491 USA "
    },
    {
      "Location ID": "765",
      "Location Name": "TEMPLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 N MAIN ST TEMPLE TX 76501 USA "
    },
    {
      "Location ID": "76511",
      "Location Name": "BARTLETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 131 N EVIE ST BARTLETT TX 76511 USA "
    },
    {
      "Location ID": "76513",
      "Location Name": "BELTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 N WALL ST BELTON TX 76513 USA "
    },
    {
      "Location ID": "76518",
      "Location Name": "BUCKHOLTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 E HIGHWAY 190 BUCKHOLTS TX 76518 USA "
    },
    {
      "Location ID": "76520",
      "Location Name": "CAMERON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 W 2ND ST CAMERON TX 76520 USA "
    },
    {
      "Location ID": "76522",
      "Location Name": "COPPERAS COVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 802 E AVENUE D COPPERAS COVE TX 76522 USA "
    },
    {
      "Location ID": "76524",
      "Location Name": "EDDY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 FRANKLIN RD EDDY TX 76524 USA "
    },
    {
      "Location ID": "76525",
      "Location Name": "EVANT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 153 N US HIGHWAY 281 EVANT TX 76525 USA "
    },
    {
      "Location ID": "76526",
      "Location Name": "FLAT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10065 S STATE HIGHWAY 36 FLAT TX 76526 USA "
    },
    {
      "Location ID": "76527",
      "Location Name": "FLORENCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 308 N PATTERSON AVE FLORENCE TX 76527 USA "
    },
    {
      "Location ID": "76528",
      "Location Name": "GATESVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 802 E LEON ST GATESVILLE TX 76528 USA "
    },
    {
      "Location ID": "76530",
      "Location Name": "GRANGER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 117 W DAVILLA ST GRANGER TX 76530 USA "
    },
    {
      "Location ID": "76531",
      "Location Name": "HAMILTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 S RICE ST HAMILTON TX 76531 USA "
    },
    {
      "Location ID": "76533",
      "Location Name": "HEIDENHEIMER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5720 E BUSINESS 190 HEIDENHEIMER TX 76533 USA "
    },
    {
      "Location ID": "76534",
      "Location Name": "HOLLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 205 W TRAVIS ST HOLLAND TX 76534 USA "
    },
    {
      "Location ID": "76537",
      "Location Name": "JARRELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12355 N INTERSTATE 35 JARRELL TX 76537 USA "
    },
    {
      "Location ID": "76538",
      "Location Name": "JONESBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11345 N STATE HIGHWAY 36 JONESBORO TX 76538 USA "
    },
    {
      "Location ID": "76539",
      "Location Name": "KEMPNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 379 PECAN ST KEMPNER TX 76539 USA "
    },
    {
      "Location ID": "76541",
      "Location Name": "KILLEEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 N 10TH ST KILLEEN TX 76541 USA "
    },
    {
      "Location ID": "76542",
      "Location Name": "COPPER MOUNTAIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3100 S W S YOUNG DR KILLEEN TX 76542 USA "
    },
    {
      "Location ID": "76544",
      "Location Name": "FORT HOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 332 761ST TANK BATTALION AVE FORT HOOD TX 76544 USA "
    },
    {
      "Location ID": "76548",
      "Location Name": "HARKER HEIGHTS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 415 E FM 2410 RD HARKER HEIGHTS TX 76548 USA "
    },
    {
      "Location ID": "76549",
      "Location Name": "WILLOW SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2403 W STAN SCHLUETER LOOP KILLEEN TX 76549 USA "
    },
    {
      "Location ID": "76550",
      "Location Name": "LAMPASAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1700 E 4TH ST LAMPASAS TX 76550 USA "
    },
    {
      "Location ID": "76554",
      "Location Name": "LITTLE RIVER ACADEMY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 N EVANS ST LITTLE RIVER ACADEMY TX 76554 USA "
    },
    {
      "Location ID": "76556",
      "Location Name": "MILANO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 320 W AVENUE E MILANO TX 76556 USA "
    },
    {
      "Location ID": "76557",
      "Location Name": "MOODY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 AVENUE E MOODY TX 76557 USA "
    },
    {
      "Location ID": "76558",
      "Location Name": "MOUND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5116 FM 1829 MOUND TX 76558 USA "
    },
    {
      "Location ID": "76559",
      "Location Name": "NOLANVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 N MAIN ST NOLANVILLE TX 76559 USA "
    },
    {
      "Location ID": "76561",
      "Location Name": "OGLESBY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 MAIN ST OGLESBY TX 76561 USA "
    },
    {
      "Location ID": "76564",
      "Location Name": "PENDLETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9765 PENDLETON TROY RD PENDLETON TX 76564 USA "
    },
    {
      "Location ID": "76566",
      "Location Name": "PURMELA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12250 W US HIGHWAY 84 PURMELA TX 76566 USA "
    },
    {
      "Location ID": "76567",
      "Location Name": "ROCKDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 234 ACKERMAN ST ROCKDALE TX 76567 USA "
    },
    {
      "Location ID": "76569",
      "Location Name": "ROGERS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 W MESQUITE AVE ROGERS TX 76569 USA "
    },
    {
      "Location ID": "76570",
      "Location Name": "ROSEBUD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 206 N 5TH ST ROSEBUD TX 76570 USA "
    },
    {
      "Location ID": "76571",
      "Location Name": "SALADO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 820 N MAIN ST SALADO TX 76571 USA "
    },
    {
      "Location ID": "76573",
      "Location Name": "SCHWERTNER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14775 FM 1105 SCHWERTNER TX 76573 USA "
    },
    {
      "Location ID": "76574",
      "Location Name": "TAYLOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 W 4TH ST TAYLOR TX 76574 USA "
    },
    {
      "Location ID": "76577",
      "Location Name": "THORNDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 N 1ST ST THORNDALE TX 76577 USA "
    },
    {
      "Location ID": "76578",
      "Location Name": "THRALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 E SHELDON AVE THRALL TX 76578 USA "
    },
    {
      "Location ID": "76579",
      "Location Name": "TROY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10 FRONT ST TROY TX 76579 USA "
    },
    {
      "Location ID": "765DG",
      "Location Name": "DASH OF GLITTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 654 PERRY LN THORNDALE TX 76577 USA "
    },
    {
      "Location ID": "766",
      "Location Name": "WACO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 430 W STATE HIGHWAY 6 WACO TX 76702 USA "
    },
    {
      "Location ID": "76622",
      "Location Name": "AQUILLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 E TREADWELL AQUILLA TX 76622 USA "
    },
    {
      "Location ID": "76627",
      "Location Name": "BLUM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 W 4TH ST BLUM TX 76627 USA "
    },
    {
      "Location ID": "76636",
      "Location Name": "COVINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 GATHINGS AVE COVINGTON TX 76636 USA "
    },
    {
      "Location ID": "76640",
      "Location Name": "ELM MOTT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 252 W ELM MOTT DR ELM MOTT TX 76640 USA "
    },
    {
      "Location ID": "76652",
      "Location Name": "KOPPERL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 FM 1859 KOPPERL TX 76652 USA "
    },
    {
      "Location ID": "76654",
      "Location Name": "LEROY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 115 W COMMERCE LEROY TX 76654 USA "
    },
    {
      "Location ID": "76684",
      "Location Name": "ROSS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1441 ROSS RD ROSS TX 76684 USA "
    },
    {
      "Location ID": "76692",
      "Location Name": "WHITNEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 516 E JEFFERSON AVE WHITNEY TX 76692 USA "
    },
    {
      "Location ID": "76701",
      "Location Name": "DOWNTOWN WACO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 424 CLAY AVE WACO TX 76706 USA "
    },
    {
      "Location ID": "76705",
      "Location Name": "BELLMEAD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 901 BANK DR WACO TX 76705 USA "
    },
    {
      "Location ID": "76708",
      "Location Name": "HIGHLANDER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4428 N 19TH ST WACO TX 76708 USA "
    },
    {
      "Location ID": "76710",
      "Location Name": "WESTVIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 800 WOODED ACRES DR WACO TX 76710 USA "
    },
    {
      "Location ID": "768",
      "Location Name": "BROWNWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 600 CENTER AVE BROWNWOOD TX 76801 USA "
    },
    {
      "Location ID": "76821",
      "Location Name": "BALLINGER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 S 8TH ST BALLINGER TX 76821 USA "
    },
    {
      "Location ID": "76823",
      "Location Name": "BANGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 E SANTA FE ST BANGS TX 76823-9998 USA "
    },
    {
      "Location ID": "76825",
      "Location Name": "BRADY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 229 S BLACKBURN ST BRADY TX 76825 USA "
    },
    {
      "Location ID": "76834",
      "Location Name": "COLEMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W PECAN ST COLEMAN TX 76834 USA "
    },
    {
      "Location ID": "76837",
      "Location Name": "EDEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 W BROADWAY ST EDEN TX 76837 USA "
    },
    {
      "Location ID": "76844",
      "Location Name": "GOLDTHWAITE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1401 W FRONT ST GOLDTHWAITE TX 76844 USA "
    },
    {
      "Location ID": "76MTE",
      "Location Name": "DALLAS MTESC (NEW BREED)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1717 W AIRFIELD DR DALLAS TX 75261 USA "
    },
    {
      "Location ID": "76PSA",
      "Location Name": "FORT WORTH TX PSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 INTERMODAL PKWY STE 100 FORT WORTH TX 76177 USA "
    },
    {
      "Location ID": "770",
      "Location Name": "HOUSTON P & DC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 FRANKLIN ST HOUSTON TX 77201 USA "
    },
    {
      "Location ID": "77095",
      "Location Name": "COPPERFIELD ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8825 HIGHWAY 6 N HOUSTON TX 77095 USA "
    },
    {
      "Location ID": "770PS",
      "Location Name": "PSI GROUP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6920 BRASADA DR STE 300 HOUSTON TX 77085 USA "
    },
    {
      "Location ID": "773",
      "Location Name": "NORTH HOUSTON P&DC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4600 ALDINE BENDER RD NORTH HOUSTON TX 77315 USA "
    },
    {
      "Location ID": "77326",
      "Location Name": "ACE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2038 HIGHWAY 2610 ACE TX 77326 USA "
    },
    {
      "Location ID": "773AX",
      "Location Name": "HOUSTON N ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4665 KENDRICK PLAZA DR HOUSTON TX 77032 USA "
    },
    {
      "Location ID": "773CA",
      "Location Name": "MONTGOMERY ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 21359 EVA ST MONTGOMERY TX 77356 USA "
    },
    {
      "Location ID": "773FZ",
      "Location Name": "LOG HOUSTON TX PSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15600 MORALES RD HOUSTON TX 77032 USA "
    },
    {
      "Location ID": "773PX",
      "Location Name": "HOUSTON CHRISTMAS ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 ESPLANADE BLVD STE 100 HOUSTON TX 77060 USA "
    },
    {
      "Location ID": "77414",
      "Location Name": "BAY CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1309 BAYWOOD DR BAY CITY TX 77414 USA "
    },
    {
      "Location ID": "77426",
      "Location Name": "CHAPPELL HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5110 MAIN ST CHAPPELL HILL TX 77426 USA "
    },
    {
      "Location ID": "77428",
      "Location Name": "COLLEGEPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15235 FM 1095 COLLEGEPORT TX 77428 USA "
    },
    {
      "Location ID": "77436",
      "Location Name": "EGYPT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10718 FM 1161 W EGYPT TX 77436 USA "
    },
    {
      "Location ID": "77440",
      "Location Name": "ELMATON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10430 HIGHWAY 35 ELMATON TX 77440 USA "
    },
    {
      "Location ID": "77443",
      "Location Name": "GLEN FLORA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 126 BRIDGE ST GLEN FLORA TX 77443 USA "
    },
    {
      "Location ID": "77445",
      "Location Name": "HEMPSTEAD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 901 12TH ST HEMPSTEAD TX 77445 USA "
    },
    {
      "Location ID": "77446",
      "Location Name": "PRAIRIE VIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 21212 FM 1098 LOOP PRAIRIE VIEW TX 77446 USA "
    },
    {
      "Location ID": "77447",
      "Location Name": "HOCKLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 17210 WARREN RANCH RD HOCKLEY TX 77447 USA "
    },
    {
      "Location ID": "77448",
      "Location Name": "HUNGERFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 E LIVE OAK ST HUNGERFORD TX 77448 USA "
    },
    {
      "Location ID": "77451",
      "Location Name": "KENDLETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13635 WILLIE MELTON BLVD KENDLETON TX 77451 USA "
    },
    {
      "Location ID": "77456",
      "Location Name": "MARKHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 610 BROADWAY ST MARKHAM TX 77456 USA "
    },
    {
      "Location ID": "77459",
      "Location Name": "MISSOURI CITY ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3701 GLENN LAKES LN MISSOURI CITY TX 77459 USA "
    },
    {
      "Location ID": "77482",
      "Location Name": "VAN VLECK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8901 STATE HIGHWAY 35 N VAN VLECK TX 77482 USA "
    },
    {
      "Location ID": "77484",
      "Location Name": "WALLER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 40090 HIGHWAY 290 BUSINESS WALLER TX 77484 USA "
    },
    {
      "Location ID": "77488",
      "Location Name": "WHARTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 141 E MILAM ST WHARTON TX 77488 USA "
    },
    {
      "Location ID": "77494",
      "Location Name": "KATY ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1331 PIN OAK RD KATY TX 77494 USA "
    },
    {
      "Location ID": "77515",
      "Location Name": "ANGLETON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 CANNAN DR ANGLETON TX 77515 USA "
    },
    {
      "Location ID": "77519",
      "Location Name": "BATSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9117 MAIN ST BATSON TX 77519 USA "
    },
    {
      "Location ID": "77533",
      "Location Name": "DAISETTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 MAIN ST DAISETTA TX 77533 USA "
    },
    {
      "Location ID": "77534",
      "Location Name": "DANBURY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6003 5TH ST DANBURY TX 77534 USA "
    },
    {
      "Location ID": "77535",
      "Location Name": "DAYTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 S MAIN ST DAYTON TX 77535 USA "
    },
    {
      "Location ID": "77545",
      "Location Name": "FRESNO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4725 TEAL BEND BLVD FRESNO TX 77545 USA "
    },
    {
      "Location ID": "77561",
      "Location Name": "HARDIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11158 HIGHWAY 146 N HARDIN TX 77561 USA "
    },
    {
      "Location ID": "77564",
      "Location Name": "HULL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7404 FM 834 E HULL TX 77564 USA "
    },
    {
      "Location ID": "77566",
      "Location Name": "LAKE JACKSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 OAK DR S LAKE JACKSON TX 77566 USA "
    },
    {
      "Location ID": "77573",
      "Location Name": "LEAGUE CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 240 W GALVESTON ST LEAGUE CITY TX 77573 USA "
    },
    {
      "Location ID": "77575",
      "Location Name": "LIBERTY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1515 SAM HOUSTON ST LIBERTY TX 77575 USA "
    },
    {
      "Location ID": "77577",
      "Location Name": "LIVERPOOL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2320 MAIN ST LIVERPOOL TX 77577 USA "
    },
    {
      "Location ID": "77578",
      "Location Name": "MANVEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6925 MASTERS RD MANVEL TX 77578 USA "
    },
    {
      "Location ID": "77583",
      "Location Name": "ROSHARON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1810 E FM 1462 RD ROSHARON TX 77583 USA "
    },
    {
      "Location ID": "77585",
      "Location Name": "SARATOGA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 17019 FM 770 SARATOGA TX 77585 USA "
    },
    {
      "Location ID": "776",
      "Location Name": "BEAUMONT  P&DF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5815 WALDEN RD BEAUMONT TX 77707 USA "
    },
    {
      "Location ID": "77611",
      "Location Name": "BRIDGE CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 TEXAS AVE BRIDGE CITY TX 77611 USA "
    },
    {
      "Location ID": "77612",
      "Location Name": "BUNA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 332 TX STATE HIGHWAY 62 BUNA TX 77612 USA "
    },
    {
      "Location ID": "77613",
      "Location Name": "CHINA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 N BROADWAY ST CHINA TX 77613 USA "
    },
    {
      "Location ID": "77614",
      "Location Name": "DEWEYVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 680 TX STATE HIGHWAY 272 DEWEYVILLE TX 77614 USA "
    },
    {
      "Location ID": "77615",
      "Location Name": "EVADALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 225 FM 105 EVADALE TX 77615 USA "
    },
    {
      "Location ID": "77616",
      "Location Name": "FRED",
      "Time Zone Offset (Hours)": -1,
      "Address": " 20239 FM 92 S FRED TX 77616 USA "
    },
    {
      "Location ID": "77619",
      "Location Name": "GROVES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6230 39TH ST GROVES TX 77619 USA "
    },
    {
      "Location ID": "77622",
      "Location Name": "HAMSHIRE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 25142 HIGHWAY 124 HAMSHIRE TX 77622 USA "
    },
    {
      "Location ID": "77623",
      "Location Name": "HIGH ISLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2008 S 5TH ST HIGH ISLAND TX 77623 USA "
    },
    {
      "Location ID": "77624",
      "Location Name": "HILLISTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 283 FM 1013 RD HILLISTER TX 77624 USA "
    },
    {
      "Location ID": "77625",
      "Location Name": "KOUNTZE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1245 S PINE ST KOUNTZE TX 77625 USA "
    },
    {
      "Location ID": "77626",
      "Location Name": "MAURICEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11424 HIGHWAY 12 MAURICEVILLE TX 77626 USA "
    },
    {
      "Location ID": "77627",
      "Location Name": "NEDERLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 223 N 14TH ST NEDERLAND TX 77627 USA "
    },
    {
      "Location ID": "77629",
      "Location Name": "NOME",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2285 HIGHWAY 90 NOME TX 77629 USA "
    },
    {
      "Location ID": "77630",
      "Location Name": "ORANGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 4TH ST ORANGE TX 77630 USA "
    },
    {
      "Location ID": "77639",
      "Location Name": "ORANGEFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5481 FM 408 ORANGEFIELD TX 77639 USA "
    },
    {
      "Location ID": "77640",
      "Location Name": "PORT ARTHUR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 345 LAKESHORE DR PORT ARTHUR TX 77640 USA "
    },
    {
      "Location ID": "77650",
      "Location Name": "PORT BOLIVAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2500 BROADWAY AVE PORT BOLIVAR TX 77650 USA "
    },
    {
      "Location ID": "77651",
      "Location Name": "PORT NECHES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1324 PORT NECHES AVE PORT NECHES TX 77651 USA "
    },
    {
      "Location ID": "77656",
      "Location Name": "SILSBEE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 W AVENUE P SILSBEE TX 77656 USA "
    },
    {
      "Location ID": "77657",
      "Location Name": "LUMBERTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 650 S MAIN ST LUMBERTON TX 77657 USA "
    },
    {
      "Location ID": "77659",
      "Location Name": "SOUR LAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 665 6TH ST SOUR LAKE TX 77659 USA "
    },
    {
      "Location ID": "77660",
      "Location Name": "SPURGER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12385 FM 92 RD S SPURGER TX 77660 USA "
    },
    {
      "Location ID": "77661",
      "Location Name": "STOWELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3608 STATE HIGHWAY 124 STOWELL TX 77661 USA "
    },
    {
      "Location ID": "77662",
      "Location Name": "VIDOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 285 S ARCHIE ST VIDOR TX 77662 USA "
    },
    {
      "Location ID": "77663",
      "Location Name": "VILLAGE MILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4956 FM 3063 VILLAGE MILLS TX 77663 USA "
    },
    {
      "Location ID": "77664",
      "Location Name": "WARREN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 COUNTY ROAD 1520 WARREN TX 77664 USA "
    },
    {
      "Location ID": "77665",
      "Location Name": "WINNIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 324 HIGHWAY 124 WINNIE TX 77665 USA "
    },
    {
      "Location ID": "776CB",
      "Location Name": "CRYSTAL BEACH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2200 HIGHWAY 87 PORT BOLIVAR TX 77650 USA "
    },
    {
      "Location ID": "77701",
      "Location Name": "DOWNTOWN BEAUMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 WILLOW ST STE 1 BEAUMONT TX 77701 USA "
    },
    {
      "Location ID": "77705",
      "Location Name": "SOUTH END",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3501 AVENUE A BEAUMONT TX 77705 USA "
    },
    {
      "Location ID": "77706",
      "Location Name": "TOBE HAHN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3910 N DOWLEN RD BEAUMONT TX 77706 USA "
    },
    {
      "Location ID": "777LA",
      "Location Name": "CPU LAMAR UNIVERSITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 REDBIRD LN BEAUMONT TX 77705 USA "
    },
    {
      "Location ID": "778",
      "Location Name": "BRYAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2121 E WM J BRYAN PKWY BRYAN TX 77801 USA "
    },
    {
      "Location ID": "77801",
      "Location Name": "BRYAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2121 E WM J BRYAN PKWY BRYAN TX 77801 USA "
    },
    {
      "Location ID": "77806",
      "Location Name": "DT STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 W. WILLIAMS JOEL BRYAN PKY BRYAN TX 77806 USA "
    },
    {
      "Location ID": "77833",
      "Location Name": "BRENHAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 309 N MARKET ST BRENHAM TX 77833 USA "
    },
    {
      "Location ID": "77835",
      "Location Name": "BURTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 N RAILROAD ST BURTON TX 77835 USA "
    },
    {
      "Location ID": "77840",
      "Location Name": "COLLEGE STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2130 HARVEY MITCHELL PKWY S COLLEGE STATION TX 77840 USA "
    },
    {
      "Location ID": "77841",
      "Location Name": "NORTHGATE COLLEGE STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 N HOUSTON ST COLLEGE STATION TX 77841 USA "
    },
    {
      "Location ID": "77843",
      "Location Name": "TEXAS A&M UNIVERSITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1585 MAIL STOP COLLEGE STA TX 77843 USA "
    },
    {
      "Location ID": "780",
      "Location Name": "LOG SAN ANTONIO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10410 PERRIN BEITEL RD SAN ANTONIO TX 78284 USA "
    },
    {
      "Location ID": "78028",
      "Location Name": "KERRVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 244 CULLY DR KERRVILLE TX 78028 USA "
    },
    {
      "Location ID": "78041",
      "Location Name": "LAREDO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2700 E SAUNDERS ST LAREDO TX 78041 USA "
    },
    {
      "Location ID": "78045",
      "Location Name": "DEL MAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2395 E DEL MAR BLVD LAREDO TX 78045 USA "
    },
    {
      "Location ID": "780AM",
      "Location Name": "AMAZON SORT CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1410 S CALLAGHAN RD SAN ANTONIO TX 78227 USA "
    },
    {
      "Location ID": "780AN",
      "Location Name": "SAN ANTONIO CMAS ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1228 CORNERWAY BLVD SAN ANTONIO TX 78219 USA "
    },
    {
      "Location ID": "780FZ",
      "Location Name": "LOG SAN ANTONIO TX PSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6719 IH-10 E SAN ANTONIO TX 78244 USA "
    },
    {
      "Location ID": "780MP",
      "Location Name": "LOG SAN ANTONIO ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10410 PERRIN BEITEL RD BLDG 2 SAN ANTONIO TX 78284 USA "
    },
    {
      "Location ID": "78102",
      "Location Name": "BEEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 N SAINT MARYS ST BEEVILLE TX 78102 USA "
    },
    {
      "Location ID": "78108",
      "Location Name": "CIBOLO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 725 FM 1103 CIBOLO TX 78108 USA "
    },
    {
      "Location ID": "78109",
      "Location Name": "CONVERSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9155 SCHAEFER RD CONVERSE TX 78109 USA "
    },
    {
      "Location ID": "78119",
      "Location Name": "KENEDY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 223 S 2ND ST KENEDY TX 78119 USA "
    },
    {
      "Location ID": "78121",
      "Location Name": "LA VERNIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2560 FM 775 LA VERNIA TX 78121 USA "
    },
    {
      "Location ID": "78130",
      "Location Name": "NEW BRAUNFELS ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 686 S SEGUIN AVE NEW BRAUNFELS TX 78130 USA "
    },
    {
      "Location ID": "78154",
      "Location Name": "SCHERTZ",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1081 ELBEL RD SCHERTZ TX 78154 USA "
    },
    {
      "Location ID": "78155",
      "Location Name": "SEGUIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 531 W COURT ST SEGUIN TX 78155 USA "
    },
    {
      "Location ID": "782AC",
      "Location Name": "AIR CARGO TERMINAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10000 JOHN SAUNDERS RD STE 10 SAN ANTONIO TX 78216 USA "
    },
    {
      "Location ID": "782VP",
      "Location Name": "VICTORY PACKAGING - SAN ANTONIO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1310 CORNERWAY BLVD SAN ANTONIO TX 78219 USA "
    },
    {
      "Location ID": "783",
      "Location Name": "LOG CORPUS CHRISTI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 809 NUECES BAY BLVD CORPUS CHRISTI TX 78469 USA "
    },
    {
      "Location ID": "78330",
      "Location Name": "AGUA DULCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1518 2ND ST AGUA DULCE TX 78330 USA "
    },
    {
      "Location ID": "78332",
      "Location Name": "ALICE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 401 E 2ND ST ALICE TX 78332 USA "
    },
    {
      "Location ID": "78336",
      "Location Name": "ARANSAS PASS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 634 S COMMERCIAL ST ARANSAS PASS TX 78336 USA "
    },
    {
      "Location ID": "78339",
      "Location Name": "BANQUETE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4376 ELIA ST BANQUETE TX 78339 USA "
    },
    {
      "Location ID": "78341",
      "Location Name": "BENAVIDES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 404 E MAIN BENAVIDES TX 78341 USA "
    },
    {
      "Location ID": "78343",
      "Location Name": "BISHOP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 S ASH AVE BISHOP TX 78343 USA "
    },
    {
      "Location ID": "78344",
      "Location Name": "BRUNI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 204 E 3RD ST BRUNI TX 78344 USA "
    },
    {
      "Location ID": "78351",
      "Location Name": "DRISCOLL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W MAIN DRISCOLL TX 78351 USA "
    },
    {
      "Location ID": "78352",
      "Location Name": "EDROY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 17916 HIGHWAY 234 EDROY TX 78352 USA "
    },
    {
      "Location ID": "78353",
      "Location Name": "ENCINO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 291 S BUSINESS HIGHWAY 281 ENCINO TX 78353 USA "
    },
    {
      "Location ID": "78355",
      "Location Name": "FALFURRIAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 218 E CHAPOTE ST FALFURRIAS TX 78355 USA "
    },
    {
      "Location ID": "78358",
      "Location Name": "FULTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 CACTUS ST FULTON TX 78358 USA "
    },
    {
      "Location ID": "78359",
      "Location Name": "GREGORY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 W 4TH ST GREGORY TX 78359 USA "
    },
    {
      "Location ID": "78361",
      "Location Name": "HEBBRONVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 502 N SMITH AVE HEBBRONVILLE TX 78361 USA "
    },
    {
      "Location ID": "78362",
      "Location Name": "INGLESIDE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2230 STATE HIGHWAY 361 INGLESIDE TX 78362 USA "
    },
    {
      "Location ID": "78363",
      "Location Name": "KINGSVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 E KLEBERG AVE KINGSVILLE TX 78363 USA "
    },
    {
      "Location ID": "78368",
      "Location Name": "MATHIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 S BEE ST MATHIS TX 78368 USA "
    },
    {
      "Location ID": "78370",
      "Location Name": "ODEM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 223 MAIN ST ODEM TX 78370 USA "
    },
    {
      "Location ID": "78371",
      "Location Name": "OILTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 W LAREDO AVE OILTON TX 78371 USA "
    },
    {
      "Location ID": "78372",
      "Location Name": "ORANGE GROVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 S EUGENIA ST ORANGE GROVE TX 78372 USA "
    },
    {
      "Location ID": "78373",
      "Location Name": "PORT ARANSAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1211 STATE HIGHWAY 361 PORT ARANSAS TX 78373 USA "
    },
    {
      "Location ID": "78374",
      "Location Name": "PORTLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 LANG RD PORTLAND TX 78374 USA "
    },
    {
      "Location ID": "78375",
      "Location Name": "PREMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 S AGNES PREMONT TX 78375 USA "
    },
    {
      "Location ID": "78376",
      "Location Name": "REALITOS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6417 HIGHWAY 359 REALITOS TX 78376 USA "
    },
    {
      "Location ID": "78377",
      "Location Name": "REFUGIO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 507 OSAGE ST REFUGIO TX 78377 USA "
    },
    {
      "Location ID": "78380",
      "Location Name": "ROBSTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 313 E MAIN AVE ROBSTOWN TX 78380 USA "
    },
    {
      "Location ID": "78382",
      "Location Name": "ROCKPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1550 FM 2165 ROCKPORT TX 78382 USA "
    },
    {
      "Location ID": "78383",
      "Location Name": "SANDIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 12140 HIGHWAY 359 SANDIA TX 78383 USA "
    },
    {
      "Location ID": "78384",
      "Location Name": "SAN DIEGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 N VICTORIA ST SAN DIEGO TX 78384 USA "
    },
    {
      "Location ID": "78387",
      "Location Name": "SINTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 S SAN PATRICIO ST SINTON TX 78387 USA "
    },
    {
      "Location ID": "78389",
      "Location Name": "SKIDMORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 122 E PATRICIO ST SKIDMORE TX 78389 USA "
    },
    {
      "Location ID": "78390",
      "Location Name": "TAFT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 VICTORIA AVE TAFT TX 78390 USA "
    },
    {
      "Location ID": "78391",
      "Location Name": "TYNAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 508 HIGHWAY 359 TYNAN TX 78391 USA "
    },
    {
      "Location ID": "78393",
      "Location Name": "WOODSBORO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 FIRST ST WOODSBORO TX 78393 USA "
    },
    {
      "Location ID": "785",
      "Location Name": "LOG MCALLEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 620 E PECAN BLVD MCALLEN TX 78501 USA "
    },
    {
      "Location ID": "78501",
      "Location Name": "MCALLEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 620 E PECAN BLVD MCALLEN TX 78501 USA "
    },
    {
      "Location ID": "78516",
      "Location Name": "ALAMO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 423 LOS ALAMOS DR ALAMO TX 78516 USA "
    },
    {
      "Location ID": "78547",
      "Location Name": "GARCIASVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6648 FM 1430 GARCIASVILLE TX 78547 USA "
    },
    {
      "Location ID": "78548",
      "Location Name": "GRULLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 247 FM 2360 GRULLA TX 78548 USA "
    },
    {
      "Location ID": "78550",
      "Location Name": "HARLINGEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1502 NEW COMBES HWY HARLINGEN TX 78550 USA "
    },
    {
      "Location ID": "78560",
      "Location Name": "LA JOYA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 N LEO AVE LA JOYA TX 78560 USA "
    },
    {
      "Location ID": "78565",
      "Location Name": "LOS EBANOS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 185 ROSENDO BENAVIDEZ ST LOS EBANOS TX 78565 USA "
    },
    {
      "Location ID": "78566",
      "Location Name": "LOS FRESNOS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 W OCEAN BLVD LOS FRESNOS TX 78566 USA "
    },
    {
      "Location ID": "78567",
      "Location Name": "LOS INDIOS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 810 E US HIGHWAY 281 LOS INDIOS TX 78567 USA "
    },
    {
      "Location ID": "78568",
      "Location Name": "LOZANO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 25698 E BROWN TRACT RD LOZANO TX 78568 USA "
    },
    {
      "Location ID": "78570",
      "Location Name": "MERCEDES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 STARR ST MERCEDES TX 78570 USA "
    },
    {
      "Location ID": "78572",
      "Location Name": "MISSION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 901 N FRANCISCO AVE MISSION TX 78572 USA "
    },
    {
      "Location ID": "78575",
      "Location Name": "OLMITO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7955 N EXPRESSWAY 77 OLMITO TX 78575 USA "
    },
    {
      "Location ID": "78576",
      "Location Name": "PENITAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 TOM GILL RD PENITAS TX 78576 USA "
    },
    {
      "Location ID": "78577",
      "Location Name": "PHARR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 W PARK AVE PHARR TX 78577 USA "
    },
    {
      "Location ID": "78578",
      "Location Name": "PORT ISABEL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 N MANAUTOU ST PORT ISABEL TX 78578 USA "
    },
    {
      "Location ID": "78579",
      "Location Name": "PROGRESO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 215 E US HIGHWAY 281 PROGRESO TX 78579 USA "
    },
    {
      "Location ID": "78582",
      "Location Name": "RIO GRANDE CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 W EISENHOWER ST RIO GRANDE CITY TX 78582 USA "
    },
    {
      "Location ID": "78583",
      "Location Name": "RIO HONDO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 E COLORADO ST RIO HONDO TX 78583 USA "
    },
    {
      "Location ID": "78584",
      "Location Name": "ROMA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1724 E GRANT ST ROMA TX 78584 USA "
    },
    {
      "Location ID": "78586",
      "Location Name": "SAN BENITO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 W HICKS ST SAN BENITO TX 78586 USA "
    },
    {
      "Location ID": "78589",
      "Location Name": "SAN JUAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 W AMY DR SAN JUAN TX 78589 USA "
    },
    {
      "Location ID": "78592",
      "Location Name": "SANTA MARIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11175 MILITARY RD SANTA MARIA TX 78592 USA "
    },
    {
      "Location ID": "78595",
      "Location Name": "SULLIVAN CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 CENIZOS DR SULLIVAN CITY TX 78595 USA "
    },
    {
      "Location ID": "78597",
      "Location Name": "SOUTH PADRE ISLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4701 PADRE BLVD SOUTH PADRE ISLAND TX 78597 USA "
    },
    {
      "Location ID": "785AP",
      "Location Name": "ADAPT A PLATOON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 118 W COLORADO ST RIO HONDO TX 78583 USA "
    },
    {
      "Location ID": "785FX",
      "Location Name": "FEDEX MCALLEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 902 N JACKSON PHARR TX 78577 USA "
    },
    {
      "Location ID": "786",
      "Location Name": "AUSTIN P&DC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8225 CROSS PARK DR AUSTIN TX 78710 USA "
    },
    {
      "Location ID": "78602",
      "Location Name": "BASTROP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1106 MAIN ST BASTROP TX 78602 USA "
    },
    {
      "Location ID": "78605",
      "Location Name": "BERTRAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 135 W FM 243 BERTRAM TX 78605 USA "
    },
    {
      "Location ID": "78606",
      "Location Name": "BLANCO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 402 4TH ST BLANCO TX 78606 USA "
    },
    {
      "Location ID": "78609",
      "Location Name": "BUCHANAN DAM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 17727 E STATE HIGHWAY 29 BUCHANAN DAM TX 78609 USA "
    },
    {
      "Location ID": "78610",
      "Location Name": "BUDA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1320 CABELAS DR BUDA TX 78610 USA "
    },
    {
      "Location ID": "78611",
      "Location Name": "BURNET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 508 E JACKSON ST BURNET TX 78611 USA "
    },
    {
      "Location ID": "78612",
      "Location Name": "CEDAR CREEK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1167 STATE HIGHWAY 21 W CEDAR CREEK TX 78612 USA "
    },
    {
      "Location ID": "78613",
      "Location Name": "CEDAR PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 E WHITESTONE BLVD CEDAR PARK TX 78613 USA "
    },
    {
      "Location ID": "78614",
      "Location Name": "COST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 136 S FM 466 COST TX 78614 USA "
    },
    {
      "Location ID": "78615",
      "Location Name": "COUPLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 13701 S STATE HIGHWAY 95 COUPLAND TX 78615 USA "
    },
    {
      "Location ID": "78616",
      "Location Name": "DALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1383 FM 1854 DALE TX 78616 USA "
    },
    {
      "Location ID": "78617",
      "Location Name": "DEL VALLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2883 HIGHWAY 71 E DEL VALLE TX 78617 USA "
    },
    {
      "Location ID": "78619",
      "Location Name": "DRIFTWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 ELDER HILL RD DRIFTWOOD TX 78619 USA "
    },
    {
      "Location ID": "78620",
      "Location Name": "DRIPPING SPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1450 W HIGHWAY 290 DRIPPING SPRINGS TX 78620 USA "
    },
    {
      "Location ID": "78621",
      "Location Name": "ELGIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 21 N AVENUE C ELGIN TX 78621 USA "
    },
    {
      "Location ID": "78622",
      "Location Name": "FENTRESS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 109 GONZALES AVE FENTRESS TX 78622 USA "
    },
    {
      "Location ID": "78623",
      "Location Name": "FISCHER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4050 FM 484 FISCHER TX 78623 USA "
    },
    {
      "Location ID": "78624",
      "Location Name": "FREDERICKSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1150 N US HIGHWAY 87 FREDERICKSBURG TX 78624 USA "
    },
    {
      "Location ID": "78626",
      "Location Name": "GEORGETOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2300 SCENIC DR GEORGETOWN TX 78626 USA "
    },
    {
      "Location ID": "78629",
      "Location Name": "GONZALES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 920 N SAINT JOSEPH ST STE 105 GONZALES TX 78629 USA "
    },
    {
      "Location ID": "78634",
      "Location Name": "HUTTO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 388 EXCHANGE BLVD HUTTO TX 78634 USA "
    },
    {
      "Location ID": "78635",
      "Location Name": "HYE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10261 W US HIGHWAY 290 HYE TX 78635 USA "
    },
    {
      "Location ID": "78636",
      "Location Name": "JOHNSON CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 S AVENUE G JOHNSON CITY TX 78636 USA "
    },
    {
      "Location ID": "78638",
      "Location Name": "KINGSBURY, TX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10055 E HIGHWAY 90 KINSBURY TX 78638 USA "
    },
    {
      "Location ID": "78639",
      "Location Name": "KINGSLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1901 W RANCH ROAD 1431 KINGSLAND TX 78639 USA "
    },
    {
      "Location ID": "78640",
      "Location Name": "KYLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 555 REBEL RD KYLE TX 78640 USA "
    },
    {
      "Location ID": "78641",
      "Location Name": "LEANDER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 801 S HIGHWAY 183 LEANDER TX 78641 USA "
    },
    {
      "Location ID": "78642",
      "Location Name": "LIBERTY HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2655 RANCH ROAD 1869 LIBERTY HILL TX 78642 USA "
    },
    {
      "Location ID": "78643",
      "Location Name": "LLANO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 819 BERRY ST LLANO TX 78643 USA "
    },
    {
      "Location ID": "78644",
      "Location Name": "LOCKHART",
      "Time Zone Offset (Hours)": -1,
      "Address": " 217 W MARKET ST LOCKHART TX 78644 USA "
    },
    {
      "Location ID": "78645",
      "Location Name": "NORTH LAKE TRAVIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8027 BRONCO LN LAGO VISTA TX 78645 USA "
    },
    {
      "Location ID": "78648",
      "Location Name": "LULING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 517 E CROCKETT ST LULING TX 78648 USA "
    },
    {
      "Location ID": "78650",
      "Location Name": "MC DADE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 161 WACO ST MC DADE TX 78650 USA "
    },
    {
      "Location ID": "78651",
      "Location Name": "MCNEIL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14005 MC NEIL RD MCNEIL TX 78651 USA "
    },
    {
      "Location ID": "78652",
      "Location Name": "MANCHACA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 780 W FM 1626 MANCHACA TX 78652 USA "
    },
    {
      "Location ID": "78653",
      "Location Name": "MANOR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11401 US HIGHWAY 290 E MANOR TX 78653 USA "
    },
    {
      "Location ID": "78654",
      "Location Name": "MARBLE FALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1212 N US HIGHWAY 281 MARBLE FALLS TX 78654 USA "
    },
    {
      "Location ID": "78655",
      "Location Name": "MARTINDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 LOCKHART ST MARTINDALE TX 78655 USA "
    },
    {
      "Location ID": "78656",
      "Location Name": "MAXWELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 32 FM 1966 MAXWELL TX 78656 USA "
    },
    {
      "Location ID": "78657",
      "Location Name": "HORSESHOE BAY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9740 W FM 2147 HORSESHOE BAY TX 78657 USA "
    },
    {
      "Location ID": "78659",
      "Location Name": "PAIGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 GONZALES ST PAIGE TX 78659 USA "
    },
    {
      "Location ID": "78660",
      "Location Name": "PFLUGERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 S HEATHERWILDE BLVD PFLUGERVILLE TX 78660 USA "
    },
    {
      "Location ID": "78661",
      "Location Name": "PRAIRIE LEA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6855 SAN MARCOS HWY PRAIRIE LEA TX 78661 USA "
    },
    {
      "Location ID": "78662",
      "Location Name": "RED ROCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 LENTZ MAIN ST RED ROCK TX 78662 USA "
    },
    {
      "Location ID": "78663",
      "Location Name": "ROUND MOUNTAIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10603 US HIGHWAY 281 N ROUND MOUNTAIN TX 78663 USA "
    },
    {
      "Location ID": "78664",
      "Location Name": "ROUND ROCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2250 DOUBLE CREEK DR ROUND ROCK TX 78664 USA "
    },
    {
      "Location ID": "78666",
      "Location Name": "SAN MARCOS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 S STAGECOACH TRL SAN MARCOS TX 78666 USA "
    },
    {
      "Location ID": "78667",
      "Location Name": "SAN MARCOS APO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 BUGG LN STE 110A SAN MARCOS TX 78666 USA "
    },
    {
      "Location ID": "78669",
      "Location Name": "SPICEWOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 121 SPUR 191 SPICEWOOD TX 78669 USA "
    },
    {
      "Location ID": "78670",
      "Location Name": "STAPLES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2585 ALLISON RD STAPLES TX 78670 USA "
    },
    {
      "Location ID": "78671",
      "Location Name": "STONEWALL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14490 E US HIGHWAY 290 STONEWALL TX 78671 USA "
    },
    {
      "Location ID": "78676",
      "Location Name": "WIMBERLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 JOE WIMBERLEY BLVD WIMBERLEY TX 78676 USA "
    },
    {
      "Location ID": "78680",
      "Location Name": "WEST STATION BOX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 797 SAM BASS RD ROUND ROCK TX 78680 USA "
    },
    {
      "Location ID": "78681",
      "Location Name": "ROUND ROCK WEST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 797 SAM BASS RD ROUND ROCK TX 78681 USA "
    },
    {
      "Location ID": "786AX",
      "Location Name": "BASTROP CARRIER ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1114 MAIN ST BASTROP TX 78602 USA "
    },
    {
      "Location ID": "786BN",
      "Location Name": "BPN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2590 OAKMONT DRIVE ROUND ROCK TX 78665 USA "
    },
    {
      "Location ID": "786BP",
      "Location Name": "BERDOLL PECAN FARM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2626 STATE HWY 71W CEDAR CREEK TX 78612 USA "
    },
    {
      "Location ID": "786CH",
      "Location Name": "DRIPPING SPRINGS-CHEAPEES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 SHELTON RANCH RD DRIPPING SPRINGS TX 78620 USA "
    },
    {
      "Location ID": "786FQ",
      "Location Name": "FAT QUARTERS SHOP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1722 TRADEMARK ST. BUDA TX 78610 USA "
    },
    {
      "Location ID": "786FZ",
      "Location Name": "LOG AUSTIN TX PSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9101 WALL ST AUSTIN TX 78754 USA "
    },
    {
      "Location ID": "786PF",
      "Location Name": "PALEFACE FEED STORE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 21120 STATE HIGHWAY 71 W UNIT A SPICEWOOD TX 78669 USA "
    },
    {
      "Location ID": "786PM",
      "Location Name": "AUSTIN ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9001 TUSCANY WAY AUSTIN TX 78710 USA "
    },
    {
      "Location ID": "786SR",
      "Location Name": "STREET RAYS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 252 INVESTMENT LOOP HUTTO TX 78634 USA "
    },
    {
      "Location ID": "78701",
      "Location Name": "DOWNTOWN AUSTIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 823 CONGRESS AVE STE 150 AUSTIN TX 78701 USA "
    },
    {
      "Location ID": "78702",
      "Location Name": "EAST AUSTIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1914 E 6TH ST AUSTIN TX 78702 USA "
    },
    {
      "Location ID": "78703",
      "Location Name": "WEST AUSTIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2418 SPRING LN AUSTIN TX 78703 USA "
    },
    {
      "Location ID": "78704",
      "Location Name": "SOUTH CONGRESS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3903 S CONGRESS AVE AUSTIN TX 78704 USA "
    },
    {
      "Location ID": "78705",
      "Location Name": "NORTH AUSTIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4300 SPEEDWAY AUSTIN TX 78705 USA "
    },
    {
      "Location ID": "78711",
      "Location Name": "CAPITOL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 E 17TH ST AUSTIN TX 78701 USA "
    },
    {
      "Location ID": "78712",
      "Location Name": "UNIVERSITY AUSTIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 INNER CAMPUS DR AUSTIN TX 78712 USA "
    },
    {
      "Location ID": "78729",
      "Location Name": "MCNEIL STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10109 LAKE CREEK PKWY AUSTIN TX 78729 USA "
    },
    {
      "Location ID": "78730",
      "Location Name": "CHIMNEY CORNERS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3575 FAR WEST BLVD AUSTIN TX 78730 USA "
    },
    {
      "Location ID": "78734",
      "Location Name": "LAKE TRAVIS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2110 RANCH ROAD 620 S LAKEWAY TX 78734 USA "
    },
    {
      "Location ID": "78744",
      "Location Name": "SOUTHEAST AUSTIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4516 BURLESON RD AUSTIN TX 78744 USA "
    },
    {
      "Location ID": "78745",
      "Location Name": "MOCKINGBIRD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7310 MANCHACA RD AUSTIN TX 78745 USA "
    },
    {
      "Location ID": "78746",
      "Location Name": "WESTLAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3201 BEE CAVES RD STE 120 AUSTIN TX 78746 USA "
    },
    {
      "Location ID": "78749",
      "Location Name": "OAK HILL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6104 OLD FREDERICKSBURG RD AUSTIN TX 78749 USA "
    },
    {
      "Location ID": "78751",
      "Location Name": "CENTRAL PARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3507 N LAMAR BLVD AUSTIN TX 78705 USA "
    },
    {
      "Location ID": "78752",
      "Location Name": "NORTHEAST AUSTIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 BLACKSON AVE AUSTIN TX 78752 USA "
    },
    {
      "Location ID": "78757",
      "Location Name": "NORTHCROSS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7700 NORTHCROSS DR AUSTIN TX 78757 USA "
    },
    {
      "Location ID": "78758",
      "Location Name": "BLUEBONNET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1822 W BRAKER LN AUSTIN TX 78758 USA "
    },
    {
      "Location ID": "78759",
      "Location Name": "BALCONES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11900 JOLLYVILLE RD AUSTIN TX 78759 USA "
    },
    {
      "Location ID": "78760",
      "Location Name": "SOUTHEAST STATION (BOX)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4516 BURLESON ROAD AUSTIN TX 78760-9998 USA "
    },
    {
      "Location ID": "787AC",
      "Location Name": "ASCENSION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7701 METROPOLIS DR STE 200 AUSTIN TX 78744 USA "
    },
    {
      "Location ID": "787BA",
      "Location Name": "BRYAN ANTHONY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11212 METRIC BLVD STE 100 AUSTIN TX 78758 USA "
    },
    {
      "Location ID": "787CA",
      "Location Name": "AUSTIN CHRISTMAS ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9101 WALL ST BLDG 13 AUSTIN TX 78754 USA "
    },
    {
      "Location ID": "787FS",
      "Location Name": "FREE SPEECH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3019 ALVIN DEVANE BLVD AUSTIN TX 78741 USA "
    },
    {
      "Location ID": "787JB",
      "Location Name": "JASON BROWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6406 BURLESON RD STE 130 AUSTIN TX 78744 USA "
    },
    {
      "Location ID": "787OL",
      "Location Name": "ONNIT LABS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4401 FRIEDRICH LANE BLDG 3 STE 302 AUSTIN TX 78744 USA "
    },
    {
      "Location ID": "787ON",
      "Location Name": "ONE LIVE MEDIA (BURLESON)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7000 BURLESON RD AUSTIN TX 78744 USA "
    },
    {
      "Location ID": "787PE",
      "Location Name": "PEACHYBBIES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15112 N INTERSTATE 35 AUSTIN TX 78728 USA "
    },
    {
      "Location ID": "787UT",
      "Location Name": "UT MAIL ROOM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2207 COMAL ST AUSTIN TX 78722 USA "
    },
    {
      "Location ID": "787WC",
      "Location Name": "WEST CAMPUS BOOKS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3500 COMSOUTH DR AUSTIN TX 78744 USA "
    },
    {
      "Location ID": "788",
      "Location Name": "UVALDE TX.",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 S GETTY UVALDE TX 78801 USA "
    },
    {
      "Location ID": "78801",
      "Location Name": "UVALDE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 S GETTY ST UVALDE TX 78801 USA "
    },
    {
      "Location ID": "78828",
      "Location Name": "BARKSDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 25594 S HIGHWAY 55 BARKSDALE TX 78828 USA "
    },
    {
      "Location ID": "78829",
      "Location Name": "BATESVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1 NOPAL ALY BATESVILLE TX 78829 USA "
    },
    {
      "Location ID": "78832",
      "Location Name": "BRACKETTVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 308 E SPRING ST BRACKETTVILLE TX 78832 USA "
    },
    {
      "Location ID": "78833",
      "Location Name": "CAMP WOOD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 309 N NUECES CAMP WOOD TX 78833 USA "
    },
    {
      "Location ID": "78840",
      "Location Name": "DEL RIO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2001 N BEDELL AVE DEL RIO TX 78840 USA "
    },
    {
      "Location ID": "78841",
      "Location Name": "PECAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 114 E BROADWAY ST DEL RIO TX 78840 USA "
    },
    {
      "Location ID": "78852",
      "Location Name": "EAGLE PASS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 410 S BIBB AVE EAGLE PASS TX 78852 USA "
    },
    {
      "Location ID": "78880",
      "Location Name": "ROCKSPRINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 102 W BANDERA ST ROCKSPRINGS TX 78880 USA "
    },
    {
      "Location ID": "78931",
      "Location Name": "BLEIBLERVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3945 FM 2502 BLEIBLERVILLE TX 78931 USA "
    },
    {
      "Location ID": "78932",
      "Location Name": "CARMINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 W SYLVAN ST CARMINE TX 78932 USA "
    },
    {
      "Location ID": "78933",
      "Location Name": "CAT SPRING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 182 N FRONT ST CAT SPRING TX 78933 USA "
    },
    {
      "Location ID": "78934",
      "Location Name": "COLUMBUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1221 WALNUT ST COLUMBUS TX 78934 USA "
    },
    {
      "Location ID": "78938",
      "Location Name": "ELLINGER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 706 FM 2503 ELLINGER TX 78938 USA "
    },
    {
      "Location ID": "78940",
      "Location Name": "FAYETTEVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 212 E MAIN ST FAYETTEVILLE TX 78940 USA "
    },
    {
      "Location ID": "78941",
      "Location Name": "FLATONIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 E SOUTH MAIN ST FLATONIA TX 78941 USA "
    },
    {
      "Location ID": "78942",
      "Location Name": "GIDDINGS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 279 E AUSTIN ST GIDDINGS TX 78942 USA "
    },
    {
      "Location ID": "78944",
      "Location Name": "INDUSTRY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7944 ERNST PKWY INDUSTRY TX 78944 USA "
    },
    {
      "Location ID": "78945",
      "Location Name": "LA GRANGE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 113 E COLORADO ST LA GRANGE TX 78945 USA "
    },
    {
      "Location ID": "78946",
      "Location Name": "LEDBETTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 CHAPPEL ST LEDBETTER TX 78946 USA "
    },
    {
      "Location ID": "78947",
      "Location Name": "LEXINGTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 624 N ROCKDALE ST LEXINGTON TX 78947 USA "
    },
    {
      "Location ID": "78948",
      "Location Name": "LINCOLN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1243 MAIN AVE LINCOLN TX 78948 USA "
    },
    {
      "Location ID": "78950",
      "Location Name": "NEW ULM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 824 PECAN ST NEW ULM TX 78950 USA "
    },
    {
      "Location ID": "78952",
      "Location Name": "PLUM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 240 PLUM CHURCH RD PLUM TX 78952 USA "
    },
    {
      "Location ID": "78953",
      "Location Name": "ROSANKY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2024 FM 535 ROSANKY TX 78953 USA "
    },
    {
      "Location ID": "78954",
      "Location Name": "ROUND TOP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 395 E MILL ST ROUND TOP TX 78954 USA "
    },
    {
      "Location ID": "78956",
      "Location Name": "SCHULENBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 622 ANDERSON ST SCHULENBURG TX 78956 USA "
    },
    {
      "Location ID": "78957",
      "Location Name": "SMITHVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 MAIN ST SMITHVILLE TX 78957 USA "
    },
    {
      "Location ID": "78959",
      "Location Name": "WAELDER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 423 W US HIGHWAY 90 WAELDER TX 78959 USA "
    },
    {
      "Location ID": "78960",
      "Location Name": "WARDA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5621 S US HIGHWAY 77 WARDA TX 78960 USA "
    },
    {
      "Location ID": "78962",
      "Location Name": "WEIMAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 E MAIN ST WEIMAR TX 78962 USA "
    },
    {
      "Location ID": "790",
      "Location Name": "AMARILLO P&DF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2301 ROSS ST AMARILLO TX 79120 USA "
    },
    {
      "Location ID": "79003",
      "Location Name": "ALLISON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 WHEELER AVE ALLISON TX 79003 USA "
    },
    {
      "Location ID": "79005",
      "Location Name": "BOOKER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 122 S AUSTIN ST BOOKER TX 79005 USA "
    },
    {
      "Location ID": "79008",
      "Location Name": "BORGER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 500 S MCGEE ST BORGER TX 79007 USA "
    },
    {
      "Location ID": "79009",
      "Location Name": "BOVINA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 2ND ST BOVINA TX 79009 USA "
    },
    {
      "Location ID": "79010",
      "Location Name": "BOYS RANCH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8 JULIAN BIVINS BLVD BOYS RANCH TX 79010 USA "
    },
    {
      "Location ID": "79011",
      "Location Name": "BRISCOE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 15715 FM 1046 BRISCOE TX 79011 USA "
    },
    {
      "Location ID": "79013",
      "Location Name": "CACTUS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 NORTH DR CACTUS TX 79013 USA "
    },
    {
      "Location ID": "79014",
      "Location Name": "CANADIAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 311 PURCELL AVE CANADIAN TX 79014 USA "
    },
    {
      "Location ID": "79015",
      "Location Name": "CANYON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1304 4TH AVE CANYON TX 79015 USA "
    },
    {
      "Location ID": "79018",
      "Location Name": "CHANNING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 705 ROCK ISLAND ST CHANNING TX 79018 USA "
    },
    {
      "Location ID": "79019",
      "Location Name": "CLAUDE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 TRICE ST CLAUDE TX 79019 USA "
    },
    {
      "Location ID": "79022",
      "Location Name": "DALHART",
      "Time Zone Offset (Hours)": -1,
      "Address": " 702 W 7TH ST DALHART TX 79022 USA "
    },
    {
      "Location ID": "79024",
      "Location Name": "DARROUZETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 MAIN ST DARROUZETT TX 79024 USA "
    },
    {
      "Location ID": "79025",
      "Location Name": "DAWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 FRYE AVE DAWN TX 79025 USA "
    },
    {
      "Location ID": "79027",
      "Location Name": "DIMMITT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 217 SE 2ND ST DIMMITT TX 79027 USA "
    },
    {
      "Location ID": "79029",
      "Location Name": "DUMAS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 315 S BLISS AVE DUMAS TX 79029 USA "
    },
    {
      "Location ID": "79031",
      "Location Name": "EARTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 N AMHERST RD EARTH TX 79031 USA "
    },
    {
      "Location ID": "79033",
      "Location Name": "FARNSWORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 FARNSWORTH ST FARNSWORTH TX 79033 USA "
    },
    {
      "Location ID": "79034",
      "Location Name": "FOLLETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S MAIN ST FOLLETT TX 79034 USA "
    },
    {
      "Location ID": "79035",
      "Location Name": "FRIONA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1010 MAIN ST FRIONA TX 79035 USA "
    },
    {
      "Location ID": "79036",
      "Location Name": "FRITCH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 N HOYNE AVE FRITCH TX 79036 USA "
    },
    {
      "Location ID": "79039",
      "Location Name": "GROOM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 104 W 1ST ST GROOM TX 79039 USA "
    },
    {
      "Location ID": "79040",
      "Location Name": "GRUVER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 MAIN ST GRUVER TX 79040 USA "
    },
    {
      "Location ID": "79041",
      "Location Name": "HALE CENTER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 203 W STEVENSON AVE HALE CENTER TX 79041 USA "
    },
    {
      "Location ID": "79042",
      "Location Name": "HAPPY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 MAIN ST HAPPY TX 79042 USA "
    },
    {
      "Location ID": "79044",
      "Location Name": "HARTLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 WHITE ST HARTLEY TX 79044 USA "
    },
    {
      "Location ID": "79045",
      "Location Name": "HEREFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 408 MAIN ST HEREFORD TX 79045 USA "
    },
    {
      "Location ID": "79046",
      "Location Name": "HIGGINS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 N MAIN ST HIGGINS TX 79046 USA "
    },
    {
      "Location ID": "79052",
      "Location Name": "KRESS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 400 SKIPWORTH ST KRESS TX 79052 USA "
    },
    {
      "Location ID": "79057",
      "Location Name": "MCLEAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 S MAIN ST MCLEAN TX 79057 USA "
    },
    {
      "Location ID": "79059",
      "Location Name": "MIAMI",
      "Time Zone Offset (Hours)": -1,
      "Address": " 116 S MAIN ST MIAMI TX 79059 USA "
    },
    {
      "Location ID": "79061",
      "Location Name": "MOBEETIE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 W 1ST ST MOBEETIE TX 79061 USA "
    },
    {
      "Location ID": "79062",
      "Location Name": "MORSE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 MAIN ST MORSE TX 79062 USA "
    },
    {
      "Location ID": "79065",
      "Location Name": "PAMPA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 E FOSTER AVE PAMPA TX 79065 USA "
    },
    {
      "Location ID": "79068",
      "Location Name": "PANHANDLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 402 MAIN ST PANHANDLE TX 79068 USA "
    },
    {
      "Location ID": "79070",
      "Location Name": "PERRYTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2722 S MAIN ST PERRYTON TX 79070 USA "
    },
    {
      "Location ID": "79072",
      "Location Name": "PLAINVIEW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 725 ASH ST PLAINVIEW TX 79072 USA "
    },
    {
      "Location ID": "79078",
      "Location Name": "SANFORD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 305 E MAIN ST SANFORD TX 79078 USA "
    },
    {
      "Location ID": "79079",
      "Location Name": "SHAMROCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 N MADDEN ST SHAMROCK TX 79079 USA "
    },
    {
      "Location ID": "79081",
      "Location Name": "SPEARMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N BERNICE ST SPEARMAN TX 79081 USA "
    },
    {
      "Location ID": "79082",
      "Location Name": "SPRINGLAKE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 108 W MAIN ST SPRINGLAKE TX 79082 USA "
    },
    {
      "Location ID": "79083",
      "Location Name": "STINNETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 431 S MAIN ST STINNETT TX 79083 USA "
    },
    {
      "Location ID": "79087",
      "Location Name": "TEXLINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 S 2ND ST TEXLINE TX 79087 USA "
    },
    {
      "Location ID": "79088",
      "Location Name": "TULIA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 129 N ARMSTRONG AVE TULIA TX 79088 USA "
    },
    {
      "Location ID": "79091",
      "Location Name": "UMBARGER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 25380 STOREY ST UMBARGER TX 79091 USA "
    },
    {
      "Location ID": "79096",
      "Location Name": "WHEELER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 507 S CANADIAN ST WHEELER TX 79096 USA "
    },
    {
      "Location ID": "79097",
      "Location Name": "WHITE DEER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 E 3RD ST WHITE DEER TX 79097 USA "
    },
    {
      "Location ID": "79105",
      "Location Name": "DOWNTOWN AMARILLO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 505 E 9TH AVE AMARILLO TX 79105 USA "
    },
    {
      "Location ID": "79109",
      "Location Name": "LONE STAR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5000 S WESTERN ST AMARILLO TX 79109 USA "
    },
    {
      "Location ID": "79116",
      "Location Name": "SAN JACINTO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 307 S WESTERN ST AMARILLO TX 79116 USA "
    },
    {
      "Location ID": "79117",
      "Location Name": "NORTH AMARILLO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 841 MARTIN RD AMARILLO TX 79117 USA "
    },
    {
      "Location ID": "79124",
      "Location Name": "JORDAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8301 W AMARILLO BLVD AMARILLO TX 79124 USA "
    },
    {
      "Location ID": "791AZ",
      "Location Name": "AMAZON.COM INC - AMA1 - AMARILLO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8590 NE 24TH AVE AMARILLO TX 79108 USA "
    },
    {
      "Location ID": "791FX",
      "Location Name": "791FX AMARILLO FEDEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5129 CANYON DR AMARILLO TX 79110 USA "
    },
    {
      "Location ID": "792",
      "Location Name": "CHILDRESS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 507 AVENUE F NW CHILDRESS TX 79201 USA "
    },
    {
      "Location ID": "79221",
      "Location Name": "AIKEN",
      "Time Zone Offset (Hours)": -1,
      "Address": " HIGHWAY 70 & FM 2301 AIKEN TX 79221 USA "
    },
    {
      "Location ID": "79229",
      "Location Name": "DICKENS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 921 5TH ST DICKENS TX 79229 USA "
    },
    {
      "Location ID": "79235",
      "Location Name": "FLOYDADA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 226 W MISSOURI ST FLOYDADA TX 79235 USA "
    },
    {
      "Location ID": "79241",
      "Location Name": "LOCKNEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 N MAIN ST LOCKNEY TX 79241 USA "
    },
    {
      "Location ID": "79243",
      "Location Name": "MCADOO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 S MAIN MCADOO TX 79243 USA "
    },
    {
      "Location ID": "79250",
      "Location Name": "PETERSBURG",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1501 E 1ST ST PETERSBURG TX 79250 USA "
    },
    {
      "Location ID": "793",
      "Location Name": "LUBBOCK P&DF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1515 CRICKETS AVE LUBBOCK TX 79402 USA "
    },
    {
      "Location ID": "79311",
      "Location Name": "ABERNATHY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 801 AVENUE D ABERNATHY TX 79311 USA "
    },
    {
      "Location ID": "79312",
      "Location Name": "AMHERST",
      "Time Zone Offset (Hours)": -1,
      "Address": " 815 MAIN ST AMHERST TX 79312 USA "
    },
    {
      "Location ID": "79313",
      "Location Name": "ANTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 306 LAWRENCE ST ANTON TX 79313 USA "
    },
    {
      "Location ID": "79316",
      "Location Name": "BROWNFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 301 W HILL ST BROWNFIELD TX 79316 USA "
    },
    {
      "Location ID": "79322",
      "Location Name": "CROSBYTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 N BERKSHIRE ST CROSBYTON TX 79322 USA "
    },
    {
      "Location ID": "79323",
      "Location Name": "DENVER CITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 E 4TH ST DENVER CITY TX 79323 USA "
    },
    {
      "Location ID": "79325",
      "Location Name": "FARWELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 120 4TH ST FARWELL TX 79325 USA "
    },
    {
      "Location ID": "79329",
      "Location Name": "IDALOU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 311 MAIN ST IDALOU TX 79329 USA "
    },
    {
      "Location ID": "79331",
      "Location Name": "LAMESA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 202 N AVENUE G LAMESA TX 79331 USA "
    },
    {
      "Location ID": "79336",
      "Location Name": "LEVELLAND",
      "Time Zone Offset (Hours)": -1,
      "Address": " 712 AVENUE G LEVELLAND TX 79336 USA "
    },
    {
      "Location ID": "79339",
      "Location Name": "LITTLEFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 801 PHELPS AVE LITTLEFIELD TX 79339 USA "
    },
    {
      "Location ID": "79343",
      "Location Name": "LORENZO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 225 US HIGHWAY 82 LORENZO TX 79343 USA "
    },
    {
      "Location ID": "79345",
      "Location Name": "MEADOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 602 N RENFRO ST MEADOW TX 79345 USA "
    },
    {
      "Location ID": "79346",
      "Location Name": "MORTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 W TAYLOR AVE MORTON TX 79346 USA "
    },
    {
      "Location ID": "79347",
      "Location Name": "MULESHOE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 221 E 3RD ST MULESHOE TX 79347 USA "
    },
    {
      "Location ID": "79350",
      "Location Name": "NEW DEAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 309 N MONROE ST NEW DEAL TX 79350 USA "
    },
    {
      "Location ID": "79351",
      "Location Name": "ODONNELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 819 DOAK ST ODONNELL TX 79351 USA "
    },
    {
      "Location ID": "79355",
      "Location Name": "PLAINS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 504 11TH ST PLAINS TX 79355 USA "
    },
    {
      "Location ID": "79357",
      "Location Name": "RALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 620 TILFORD AVE RALLS TX 79357 USA "
    },
    {
      "Location ID": "79358",
      "Location Name": "ROPESVILLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 403 TIMMONS AVE ROPESVILLE TX 79358 USA "
    },
    {
      "Location ID": "79359",
      "Location Name": "SEAGRAVES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 MAIN ST SEAGRAVES TX 79359 USA "
    },
    {
      "Location ID": "79360",
      "Location Name": "SEMINOLE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 SE 3RD ST SEMINOLE TX 79360 USA "
    },
    {
      "Location ID": "79363",
      "Location Name": "SHALLOWATER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 CLOVIS RD SHALLOWATER TX 79363 USA "
    },
    {
      "Location ID": "79367",
      "Location Name": "SMYER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1002 1ST ST SMYER TX 79367 USA "
    },
    {
      "Location ID": "79370",
      "Location Name": "SPUR",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 W HARRIS ST SPUR TX 79370 USA "
    },
    {
      "Location ID": "79371",
      "Location Name": "SUDAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 105 HORNET DR SUDAN TX 79371 USA "
    },
    {
      "Location ID": "79372",
      "Location Name": "SUNDOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 807 S SLAUGHTER AVE SUNDOWN TX 79372 USA "
    },
    {
      "Location ID": "79373",
      "Location Name": "TAHOKA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1525 CONWAY ST TAHOKA TX 79373 USA "
    },
    {
      "Location ID": "79378",
      "Location Name": "WELLMAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 503 YOAKUM WELLMAN TX 79378 USA "
    },
    {
      "Location ID": "79379",
      "Location Name": "WHITEFACE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 W 2ND ST WHITEFACE TX 79379 USA "
    },
    {
      "Location ID": "79380",
      "Location Name": "WHITHARRAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 BADGER WHITHARRAL TX 79380 USA "
    },
    {
      "Location ID": "79382",
      "Location Name": "WOLFFORTH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 E HIGHWAY 62 WOLFFORTH TX 79382 USA "
    },
    {
      "Location ID": "793AN",
      "Location Name": "LUBBOCK ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1601 CRICKETS AVE LUBBOCK TX 79402 USA "
    },
    {
      "Location ID": "793OO",
      "Location Name": "OIL OFFICE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 PLAINS HWY DENVER CITY TX 79323 USA "
    },
    {
      "Location ID": "79408",
      "Location Name": "DOWNTOWN LUBBOCK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 411 AVENUE L LUBBOCK TX 79408 USA "
    },
    {
      "Location ID": "79413",
      "Location Name": "MONTEREY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5014 GARY AVE LUBBOCK TX 79413 USA "
    },
    {
      "Location ID": "79416",
      "Location Name": "CPU WHISPERWOOD MAIL SERVICE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5404 4TH ST UNIT F LUBBOCK TX 79416 USA "
    },
    {
      "Location ID": "79423",
      "Location Name": "CPU MEGARON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8207 ITHACA AVE STE D LUBBOCK TX 79423 USA "
    },
    {
      "Location ID": "79430",
      "Location Name": "TEXAS TECH MED SCHOOL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2500 BROADWAY LUBBOCK TX 79430 USA "
    },
    {
      "Location ID": "79452",
      "Location Name": "FREEDOM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5001 AVENUE L LUBBOCK TX 79452 USA "
    },
    {
      "Location ID": "79464",
      "Location Name": "SINGER",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4901 S LOOP 289 LUBBOCK TX 79464 USA "
    },
    {
      "Location ID": "79490",
      "Location Name": "SUNSET",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2121 FRANKFORD AVE LUBBOCK TX 79490 USA "
    },
    {
      "Location ID": "794FX",
      "Location Name": "Lubbock FEDEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 7802 N CEDAR AVE LUBBOCK TX 79403 USA "
    },
    {
      "Location ID": "794JN",
      "Location Name": "JACAVI BEAUTY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1104 LUBBOCK BUSINESS PARK BLVD LUBBOCK TX 79403 USA "
    },
    {
      "Location ID": "794LA",
      "Location Name": "LOUISVILLE CARRIER ANX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4811 LOUISVILLE AVE LUBBOCK TX 79413 USA "
    },
    {
      "Location ID": "794PT",
      "Location Name": "POST TECH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1500 BOSTON LUBBOCK TX 79430 USA "
    },
    {
      "Location ID": "794TM",
      "Location Name": "TECH MAIL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2903 4TH STREET LUBBOCK TX 79430 USA "
    },
    {
      "Location ID": "795",
      "Location Name": "LOG ABILENE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1020 E OVERLAND TRL ABILENE TX 79601 USA "
    },
    {
      "Location ID": "79501",
      "Location Name": "ANSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1002 11TH ST ANSON TX 79501 USA "
    },
    {
      "Location ID": "79502",
      "Location Name": "ASPERMONT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 704 S WASHINGTON AVE ASPERMONT TX 79502 USA "
    },
    {
      "Location ID": "79504",
      "Location Name": "BAIRD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 W 3RD ST BAIRD TX 79504 USA "
    },
    {
      "Location ID": "79510",
      "Location Name": "CLYDE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 615 ELM ST CLYDE TX 79510 USA "
    },
    {
      "Location ID": "79518",
      "Location Name": "GIRARD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1485 STATE HIGHWAY 70 GIRARD TX 79518 USA "
    },
    {
      "Location ID": "79520",
      "Location Name": "HAMLIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 225 SE AVENUE A HAMLIN TX 79520 USA "
    },
    {
      "Location ID": "79525",
      "Location Name": "HAWLEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 525 E ACCESS RD HAWLEY TX 79525 USA "
    },
    {
      "Location ID": "79528",
      "Location Name": "JAYTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 840 S MAIN ST JAYTON TX 79528 USA "
    },
    {
      "Location ID": "79540",
      "Location Name": "OLD GLORY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9080 FM 1835 OLD GLORY TX 79540 USA "
    },
    {
      "Location ID": "79567",
      "Location Name": "WINTERS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 221 S MAIN ST WINTERS TX 79567 USA "
    },
    {
      "Location ID": "79601",
      "Location Name": "MAIN OFFICE ABILENE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 341 PINE ST ABILENE TX 79601 USA "
    },
    {
      "Location ID": "79605",
      "Location Name": "SOUTHERN HILLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2501 BUFFALO GAP RD ABILENE TX 79605 USA "
    },
    {
      "Location ID": "797",
      "Location Name": "MIDLAND P&DF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10000 LOOP 40 MIDLAND TX 79711 USA "
    },
    {
      "Location ID": "79701",
      "Location Name": "MIDLAND DOWNTOWN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 E WALL ST MIDLAND TX 79701 USA "
    },
    {
      "Location ID": "79705",
      "Location Name": "CLAYDESTA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 612 VETERANS AIRPARK LN MIDLAND TX 79705 USA "
    },
    {
      "Location ID": "79707",
      "Location Name": "GRAVES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3304 W WADLEY AVE MIDLAND TX 79707 USA "
    },
    {
      "Location ID": "79713",
      "Location Name": "ACKERLY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 303 W 5TH ST ACKERLY TX 79713 USA "
    },
    {
      "Location ID": "79714",
      "Location Name": "ANDREWS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 110 NW AVENUE H ANDREWS TX 79714 USA "
    },
    {
      "Location ID": "79719",
      "Location Name": "BARSTOW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 MACKEY AVE BARSTOW TX 79719 USA "
    },
    {
      "Location ID": "79720",
      "Location Name": "BIG SPRING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 501 S MAIN ST BIG SPRING TX 79720 USA "
    },
    {
      "Location ID": "79730",
      "Location Name": "COYANOSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2550 N HIGHWAY 1776 COYANOSA TX 79730-9998 USA "
    },
    {
      "Location ID": "79731",
      "Location Name": "CRANE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 509 S ALFORD ST CRANE TX 79731 USA "
    },
    {
      "Location ID": "79735",
      "Location Name": "FORT STOCKTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 E 4TH ST FORT STOCKTON TX 79735 USA "
    },
    {
      "Location ID": "79741",
      "Location Name": "GOLDSMITH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 310 E GULF GOLDSMITH TX 79741 USA "
    },
    {
      "Location ID": "79742",
      "Location Name": "GRANDFALLS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 214 N AVENUE D GRANDFALLS TX 79742 USA "
    },
    {
      "Location ID": "79743",
      "Location Name": "IMPERIAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 304 E STATE HIGHWAY 11 IMPERIAL TX 79743 USA "
    },
    {
      "Location ID": "79744",
      "Location Name": "IRAAN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 302 S DRAKE IRAAN TX 79744 USA "
    },
    {
      "Location ID": "79745",
      "Location Name": "KERMIT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 309 N OAK ST KERMIT TX 79745 USA "
    },
    {
      "Location ID": "79752",
      "Location Name": "MCCAMEY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 103 W 3RD ST MC CAMEY TX 79752 USA "
    },
    {
      "Location ID": "79754",
      "Location Name": "MENTONE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 111 BELL ST MENTONE TX 79754 USA "
    },
    {
      "Location ID": "79756",
      "Location Name": "MONAHANS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 S BETTY AVE MONAHANS TX 79756 USA "
    },
    {
      "Location ID": "79758",
      "Location Name": "GARDENDALE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4600 E US HIGHWAY 158 GARDENDALE TX 79758 USA "
    },
    {
      "Location ID": "79759",
      "Location Name": "NOTREES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 37 W HIGHWAY 302 NOTREES TX 79759 USA "
    },
    {
      "Location ID": "79760",
      "Location Name": "ODESSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 N TEXAS AVE ODESSA TX 79761 USA "
    },
    {
      "Location ID": "79762",
      "Location Name": "NORTHEAST ODESSA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4551 E 52ND ST ODESSA TX 79762 USA "
    },
    {
      "Location ID": "79770",
      "Location Name": "ORLA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3737 N HIGHWAY 285 ORLA TX 79770 USA "
    },
    {
      "Location ID": "79772",
      "Location Name": "PECOS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 106 W 4TH ST PECOS TX 79772 USA "
    },
    {
      "Location ID": "79776",
      "Location Name": "PENWELL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 157 S FM ROAD 1601 PENWELL TX 79776 USA "
    },
    {
      "Location ID": "79777",
      "Location Name": "PYOTE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 211 N ROGERS PYOTE TX 79777 USA "
    },
    {
      "Location ID": "79778",
      "Location Name": "RANKIN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 819 S GRAND ST RANKIN TX 79778 USA "
    },
    {
      "Location ID": "79781",
      "Location Name": "SHEFFIELD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9584 HIGHWAY 290 SHEFFIELD TX 79781 USA "
    },
    {
      "Location ID": "79782",
      "Location Name": "STANTON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 101 N SAINT MARY STANTON TX 79782 USA "
    },
    {
      "Location ID": "79785",
      "Location Name": "TOYAH",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1984 W FRONTAGE I 20 TOYAH TX 79785 USA "
    },
    {
      "Location ID": "79788",
      "Location Name": "WICKETT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 300 OBRIEN ST WICKETT TX 79788 USA "
    },
    {
      "Location ID": "79789",
      "Location Name": "WINK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 210 E HENDRICKS BLVD WINK TX 79789 USA "
    },
    {
      "Location ID": "798",
      "Location Name": "EL PASO P&DC",
      "Time Zone Offset (Hours)": -2,
      "Address": " 8401 BOEING DR EL PASO TX 79910 USA "
    },
    {
      "Location ID": "79830",
      "Location Name": "ALPINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 901 W HOLLAND AVE ALPINE TX 79830-9998 USA "
    },
    {
      "Location ID": "79835",
      "Location Name": "CANUTILLO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 7050 DONIPHAN DR CANUTILLO TX 79835-5407 USA "
    },
    {
      "Location ID": "79836",
      "Location Name": "CLINT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 13001 ALAMEDA AVE CLINT TX 79836-9998 USA "
    },
    {
      "Location ID": "79837",
      "Location Name": "DELL CITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 108 N MAIN ST DELL CITY TX 79837-9998 USA "
    },
    {
      "Location ID": "79838",
      "Location Name": "FABENS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 605 W MAIN ST FABENS TX 79838-9998 USA "
    },
    {
      "Location ID": "79839",
      "Location Name": "FORT HANCOCK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 107 W TEXAS HIGHWAY 20 FORT HANCOCK TX 79839-9998 USA "
    },
    {
      "Location ID": "79842",
      "Location Name": "MARATHON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 107 E US HIGHWAY 90 MARATHON TX 79842-9998 USA "
    },
    {
      "Location ID": "79843",
      "Location Name": "MARFA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 100 N HIGHLAND AVE MARFA TX 79843-9998 USA "
    },
    {
      "Location ID": "79848",
      "Location Name": "SANDERSON",
      "Time Zone Offset (Hours)": -1,
      "Address": " 208 W DOWNIE ST SANDERSON TX 79848-9800 USA "
    },
    {
      "Location ID": "79849",
      "Location Name": "SAN ELIZARIO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1416 SAN ANTONIO RD SAN ELIZARIO TX 79849-9998 USA "
    },
    {
      "Location ID": "79851",
      "Location Name": "SIERRA BLANCA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1195 EL PASO ST SIERRA BLANCA TX 79851-9800 USA "
    },
    {
      "Location ID": "79853",
      "Location Name": "TORNILLO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 19280 COBB ST TORNILLO TX 79853-9998 USA "
    },
    {
      "Location ID": "79854",
      "Location Name": "VALENTINE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 311 W CALIFORNIA AVE VALENTINE TX 79854-9998 USA "
    },
    {
      "Location ID": "79855",
      "Location Name": "VAN HORN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 603 W BROADWAY ST VAN HORN TX 79855-9800 USA "
    },
    {
      "Location ID": "79901",
      "Location Name": "DOWNTOWN EL PASO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 219 E MILLS AVE EL PASO TX 79901-9998 USA "
    },
    {
      "Location ID": "79902",
      "Location Name": "MESA HILLS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5981 N MESA ST EL PASO TX 79902-9998 USA "
    },
    {
      "Location ID": "79903",
      "Location Name": "FIVE POINTS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3011 E YANDELL DR EL PASO TX 79903-4133 USA "
    },
    {
      "Location ID": "79904",
      "Location Name": "SUNRISE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4100 HERCULES AVE EL PASO TX 79904-9998 USA "
    },
    {
      "Location ID": "79905",
      "Location Name": "WASHINGTON PARK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4400 E PAISANO DR EL PASO TX 79905-9998 USA "
    },
    {
      "Location ID": "79906",
      "Location Name": "FORT BLISS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1733 PLEASONTON RD EL PASO TX 79906-9998 USA "
    },
    {
      "Location ID": "79907",
      "Location Name": "YSLETA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 880 N ZARAGOZA RD EL PASO TX 79907-9998 USA "
    },
    {
      "Location ID": "79912",
      "Location Name": "CORONADO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 7383 REMCON CIR EL PASO TX 79912-9998 USA "
    },
    {
      "Location ID": "79915",
      "Location Name": "RANCHLAND",
      "Time Zone Offset (Hours)": -2,
      "Address": " 7314 GATEWAY BLVD E EL PASO TX 79915-9998 USA "
    },
    {
      "Location ID": "79924",
      "Location Name": "NORTHGATE EL PASO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5249 SANDERS AVE EL PASO TX 79924-9998 USA "
    },
    {
      "Location ID": "79925",
      "Location Name": "PEBBLE HILLS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3100 N YARBROUGH DR EL PASO TX 79925-9998 USA "
    },
    {
      "Location ID": "79927",
      "Location Name": "SOCORRO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 10550 N LOOP DR SOCORRO TX 79927-9998 USA "
    },
    {
      "Location ID": "79930",
      "Location Name": "SUMMIT HEIGHTS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4116 DYER ST EL PASO TX 79930-9998 USA "
    },
    {
      "Location ID": "79936",
      "Location Name": "SANDY CREEK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2100 GEORGE DIETER DR EL PASO TX 79936-9998 USA "
    },
    {
      "Location ID": "79940",
      "Location Name": "DOWNTOWN EL PASO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 219 E MILLS AVE EL PASO TX 79901-9998 USA "
    },
    {
      "Location ID": "79968",
      "Location Name": "UTEP",
      "Time Zone Offset (Hours)": -2,
      "Address": " 501 W UNIVERSITY DR EL PASO TX 79902-5809 USA "
    },
    {
      "Location ID": "799DA",
      "Location Name": "DETROIT AXLE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4600 RIPLEY DR EL PASO TX 79922 USA "
    },
    {
      "Location ID": "799FB",
      "Location Name": "EAST FORT BLISS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 20727 CONSTITUTION AVE FORT BLISS TX 79918-9996 USA "
    },
    {
      "Location ID": "799LM",
      "Location Name": "LEXMARK KUEHNE & NAGEL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1325 PENDALE RD EL PASO TX 79936-6912 USA "
    },
    {
      "Location ID": "799NA",
      "Location Name": "NAC LOGISTICS LLC",
      "Time Zone Offset (Hours)": -2,
      "Address": " 9660 JOE RODRIGUEZ DR STE 1-2 EL PASO TX 79927-2124 USA "
    },
    {
      "Location ID": "799NC",
      "Location Name": "NCH MARKETING SERVICES",
      "Time Zone Offset (Hours)": -2,
      "Address": " 26 WALTER JONES BLVD STE A EL PASO TX 79906-5319 USA "
    },
    {
      "Location ID": "800",
      "Location Name": "DENVER P&DC, CO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 7540 E 53RD PL DENVER CO 80266 USA "
    },
    {
      "Location ID": "80020",
      "Location Name": "BROOMFIELD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 280 E 1ST AVE BROOMFIELD CO 80020 USA "
    },
    {
      "Location ID": "80021",
      "Location Name": "BROOMFIELD EAGLE VIEW",
      "Time Zone Offset (Hours)": -2,
      "Address": " 8800 W 116TH CIR BROOMFIELD CO 80021 USA "
    },
    {
      "Location ID": "80026",
      "Location Name": "LAFAYETTE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 603 S PUBLIC RD LAFAYETTE CO 80026 USA "
    },
    {
      "Location ID": "80027",
      "Location Name": "LOUISVILLE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 566 S MCCASLIN BLVD SUPERIOR CO 80027 USA "
    },
    {
      "Location ID": "80030",
      "Location Name": "HARRIS PARK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 7262 MEADE ST WESTMINSTER CO 80030 USA "
    },
    {
      "Location ID": "80031",
      "Location Name": "WESTMINSTER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3051 W 105TH AVE WESTMINSTER CO 80031 USA "
    },
    {
      "Location ID": "80033",
      "Location Name": "WHEAT RIDGE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4210 WADSWORTH BLVD WHEAT RIDGE CO 80033 USA "
    },
    {
      "Location ID": "800AX",
      "Location Name": "DENVER PEAK ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 14401 E 33RD PL STE F AURORA CO 80011 USA "
    },
    {
      "Location ID": "800FZ",
      "Location Name": "LOG DENVER CO PSA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4690 FLORENCE ST DENVER CO 80238 USA "
    },
    {
      "Location ID": "800TH",
      "Location Name": "DENVER TRUCK HUB, CO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 20500 E COLFAX AVE AURORA CO 80018 USA "
    },
    {
      "Location ID": "80105",
      "Location Name": "DEER TRAIL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 195 DATE ST DEER TRAIL CO 80105 USA "
    },
    {
      "Location ID": "80110",
      "Location Name": "ENGLEWOOD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 915 W LEHIGH AVE ENGLEWOOD CO 80110 USA "
    },
    {
      "Location ID": "80112",
      "Location Name": "GREENWOOD VILLAGE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 6855 S DAYTON ST GREENWOOD VILLAGE CO 80112 USA "
    },
    {
      "Location ID": "80120",
      "Location Name": "LITTLETON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5753 S PRINCE ST LITTLETON CO 80120 USA "
    },
    {
      "Location ID": "80122",
      "Location Name": "CENTENNIAL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2221 E ARAPAHOE RD CENTENNIAL CO 80122 USA "
    },
    {
      "Location ID": "80123",
      "Location Name": "COLUMBINE HILLS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 6698 S IRIS ST LITTLETON CO 80123 USA "
    },
    {
      "Location ID": "80126",
      "Location Name": "HIGHLANDS RANCH",
      "Time Zone Offset (Hours)": -2,
      "Address": " 9609 S UNIVERSITY BLVD HIGHLANDS RANCH CO 80130 USA "
    },
    {
      "Location ID": "80127",
      "Location Name": "KEN CARYL RANCH",
      "Time Zone Offset (Hours)": -2,
      "Address": " 8200 SHAFFER PKWY LITTLETON CO 80127 USA "
    },
    {
      "Location ID": "80214",
      "Location Name": "EDGEWATER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1990 DEPEW ST LAKEWOOD CO 80214 USA "
    },
    {
      "Location ID": "80216",
      "Location Name": "STOCKYARDS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4910 BRIGHTON BLVD DENVER CO 80216 USA "
    },
    {
      "Location ID": "80217",
      "Location Name": "GMF FINANCE STATION",
      "Time Zone Offset (Hours)": -2,
      "Address": " 7550 E. 53RD PLACE DENVER CO 80217 USA "
    },
    {
      "Location ID": "802AA",
      "Location Name": "CAPITOL HILL ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1551 N DOWNING ST DENVER CO 80218 USA "
    },
    {
      "Location ID": "80440",
      "Location Name": "FAIRPLAY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 517 HATHAWAY ST FAIRPLAY CO 80440 USA "
    },
    {
      "Location ID": "80501",
      "Location Name": "LONGMONT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 201 COFFMAN ST LONGMONT CO 80501 USA "
    },
    {
      "Location ID": "80513",
      "Location Name": "BERTHOUD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 425 3RD ST BERTHOUD CO 80513 USA "
    },
    {
      "Location ID": "80514",
      "Location Name": "DACONO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 650 GLEN CREIGHTON DR DACONO CO 80514 USA "
    },
    {
      "Location ID": "80520",
      "Location Name": "FIRESTONE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 478 1ST ST FIRESTONE CO 80520 USA "
    },
    {
      "Location ID": "80521",
      "Location Name": "FORT COLLINS OLD TOWN STA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 301 S HOWES ST FL 1 FORT COLLINS CO 80521 USA "
    },
    {
      "Location ID": "80530",
      "Location Name": "FREDERICK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 411 OAK ST FREDERICK CO 80530 USA "
    },
    {
      "Location ID": "80533",
      "Location Name": "HYGIENE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 11830 N 75TH ST HYGIENE CO 80533 USA "
    },
    {
      "Location ID": "80534",
      "Location Name": "JOHNSTOWN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 121 CASTLE PINES AVE JOHNSTOWN CO 80534 USA "
    },
    {
      "Location ID": "80540",
      "Location Name": "LYONS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 305 RAILROAD AVE LYONS CO 80540 USA "
    },
    {
      "Location ID": "80542",
      "Location Name": "MEAD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 350 WELKER AVE MEAD CO 80542 USA "
    },
    {
      "Location ID": "80543",
      "Location Name": "MILLIKEN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1009 BROAD ST MILLIKEN CO 80543 USA "
    },
    {
      "Location ID": "80544",
      "Location Name": "NIWOT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 150 MURRAY ST NIWOT CO 80544 USA "
    },
    {
      "Location ID": "80546",
      "Location Name": "SEVERANCE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 320 1ST ST SEVERANCE CO 80546 USA "
    },
    {
      "Location ID": "80547",
      "Location Name": "TIMNATH",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4128 MAIN ST TIMNATH CO 80547 USA "
    },
    {
      "Location ID": "80550",
      "Location Name": "WINDSOR",
      "Time Zone Offset (Hours)": -2,
      "Address": " 215 6TH ST WINDSOR CO 80550 USA "
    },
    {
      "Location ID": "805MS",
      "Location Name": "CPU MAIN STREET MARKETPLACE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1727 MAIN ST LONGMONT CO 80501 USA "
    },
    {
      "Location ID": "805WC",
      "Location Name": "WIN CARRIER ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1070 DIAMOND VALLEY DR STE 500 WINDSOR CO 80550 USA "
    },
    {
      "Location ID": "806",
      "Location Name": "INVALID / CLOSED FACILITY USPS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 90 N 4TH AVE BRIGHTON CO 80601 USA "
    },
    {
      "Location ID": "80611",
      "Location Name": "BRIGGSDALE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 302 MAIN ST BRIGGSDALE CO 80611 USA "
    },
    {
      "Location ID": "80622",
      "Location Name": "GALETON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 24830 1ST ST GALETON CO 80622 USA "
    },
    {
      "Location ID": "80624",
      "Location Name": "GILL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 31302 3RD ST GILL CO 80624 USA "
    },
    {
      "Location ID": "80631",
      "Location Name": "DOWNTOWN GREELEY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 925 11TH AVE GREELEY CO 80631 USA "
    },
    {
      "Location ID": "80644",
      "Location Name": "KERSEY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 331 1ST ST KERSEY CO 80644 USA "
    },
    {
      "Location ID": "807",
      "Location Name": "FORT MORGAN         CO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 300 STATE ST FORT MORGAN CO 80701 USA "
    },
    {
      "Location ID": "80701",
      "Location Name": "FORT MORGAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 300 STATE ST FORT MORGAN CO 80701 USA "
    },
    {
      "Location ID": "80729",
      "Location Name": "GROVER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 303 CHATOGA ST GROVER CO 80729 USA "
    },
    {
      "Location ID": "80732",
      "Location Name": "HEREFORD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 39590 HEREFORD ST HEREFORD CO 80732 USA "
    },
    {
      "Location ID": "80742",
      "Location Name": "NEW RAYMER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 236 CENTRE AVE NEW RAYMER CO 80742 USA "
    },
    {
      "Location ID": "80751",
      "Location Name": "STERLING",
      "Time Zone Offset (Hours)": -2,
      "Address": " 306 POPLAR ST STERLING CO 80751 USA "
    },
    {
      "Location ID": "80754",
      "Location Name": "STONEHAM",
      "Time Zone Offset (Hours)": -2,
      "Address": " 41621 MARBLE AVE STONEHAM CO 80754 USA "
    },
    {
      "Location ID": "808",
      "Location Name": "COLORADO SPRINGS P&DC",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3655 E FOUNTAIN BLVD COLORADO SPRINGS CO 80910 USA "
    },
    {
      "Location ID": "80802",
      "Location Name": "ARAPAHOE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 16665 MICHIGAN AVE ARAPAHOE CO 80802 USA "
    },
    {
      "Location ID": "80804",
      "Location Name": "ARRIBA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 520 COLORADO AVE ARRIBA CO 80804 USA "
    },
    {
      "Location ID": "80805",
      "Location Name": "BETHUNE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 135 E 1ST AVE BETHUNE CO 80805 USA "
    },
    {
      "Location ID": "80807",
      "Location Name": "BURLINGTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 259 14TH ST BURLINGTON CO 80807 USA "
    },
    {
      "Location ID": "80808",
      "Location Name": "CALHAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 655 CASCADE ST CALHAN CO 80808 USA "
    },
    {
      "Location ID": "80809",
      "Location Name": "CASCADE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 8006 UTE PASS AVE CASCADE CO 80809 USA "
    },
    {
      "Location ID": "80810",
      "Location Name": "CHEYENNE WELLS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 90 E 1ST ST CHEYENNE WELLS CO 80810 USA "
    },
    {
      "Location ID": "80812",
      "Location Name": "COPE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 45537 MAIN ST COPE CO 80812 USA "
    },
    {
      "Location ID": "80813",
      "Location Name": "CRIPPLE CREEK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 319 W CARR ST CRIPPLE CREEK CO 80813 USA "
    },
    {
      "Location ID": "80814",
      "Location Name": "DIVIDE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 66 HYBROOK ROAD SOUTH DIVIDE CO 80814 USA "
    },
    {
      "Location ID": "80815",
      "Location Name": "FLAGLER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 315 MAIN AVE FLAGLER CO 80815 USA "
    },
    {
      "Location ID": "80816",
      "Location Name": "FLORISSANT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 18101 COUNTY ROAD 1 FLORISSANT CO 80816 USA "
    },
    {
      "Location ID": "80817",
      "Location Name": "FOUNTAIN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 101 S SANTA FE AVE FOUNTAIN CO 80817 USA "
    },
    {
      "Location ID": "80818",
      "Location Name": "GENOA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 217 MAIN ST GENOA CO 80818 USA "
    },
    {
      "Location ID": "80819",
      "Location Name": "GREEN MOUNTAIN FALLS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 10560 UTE PASS AVE GREEN MOUNTAIN FALLS CO 80819 USA "
    },
    {
      "Location ID": "80820",
      "Location Name": "GUFFEY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1517 CANYON ST GUFFEY CO 80820 USA "
    },
    {
      "Location ID": "80821",
      "Location Name": "HUGO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 422 4TH AVE HUGO CO 80821 USA "
    },
    {
      "Location ID": "80822",
      "Location Name": "JOES",
      "Time Zone Offset (Hours)": -2,
      "Address": " 6461 US HIGHWAY 36 JOES CO 80822 USA "
    },
    {
      "Location ID": "80824",
      "Location Name": "KIRK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 11028 COUNTY ROAD 3 KIRK CO 80824 USA "
    },
    {
      "Location ID": "80825",
      "Location Name": "KIT CARSON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 104 MAIN ST KIT CARSON CO 80825 USA "
    },
    {
      "Location ID": "80827",
      "Location Name": "LAKE GEORGE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 37370 US HIGHWAY 24 LAKE GEORGE CO 80827 USA "
    },
    {
      "Location ID": "80828",
      "Location Name": "LIMON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 250 F AVE LIMON CO 80828 USA "
    },
    {
      "Location ID": "80829",
      "Location Name": "MANITOU SPRINGS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 307 CANON AVE MANITOU SPRINGS CO 80829 USA "
    },
    {
      "Location ID": "80830",
      "Location Name": "MATHESON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 37021 BROADWAY AVE MATHESON CO 80830 USA "
    },
    {
      "Location ID": "80831",
      "Location Name": "PEYTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 13055 BRADSHAW RD PEYTON CO 80831 USA "
    },
    {
      "Location ID": "80832",
      "Location Name": "RAMAH",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2 S COMMERCIAL ST RAMAH CO 80832 USA "
    },
    {
      "Location ID": "80833",
      "Location Name": "RUSH",
      "Time Zone Offset (Hours)": -2,
      "Address": " 455 N RUSH RD RUSH CO 80833 USA "
    },
    {
      "Location ID": "80834",
      "Location Name": "SEIBERT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 113 COLORADO AVE SEIBERT CO 80834 USA "
    },
    {
      "Location ID": "80835",
      "Location Name": "SIMLA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 611 CARIBOU ST SIMLA CO 80835 USA "
    },
    {
      "Location ID": "80836",
      "Location Name": "STRATTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 313 COLORADO AVE STRATTON CO 80836 USA "
    },
    {
      "Location ID": "80840",
      "Location Name": "USAF ACADEMY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5136 COMMUNITY CENTER DR USAF ACADEMY CO 80840 USA "
    },
    {
      "Location ID": "80860",
      "Location Name": "VICTOR",
      "Time Zone Offset (Hours)": -2,
      "Address": " 106 N 3RD ST VICTOR CO 80860 USA "
    },
    {
      "Location ID": "80861",
      "Location Name": "VONA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1263 1ST AVE VONA CO 80861 USA "
    },
    {
      "Location ID": "80862",
      "Location Name": "WILD HORSE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 8511 US HIGHWAY 40-287 WILD HORSE CO 80862 USA "
    },
    {
      "Location ID": "80863",
      "Location Name": "TAMARAC",
      "Time Zone Offset (Hours)": -2,
      "Address": " 900 TAMARAC PKWY WOODLAND PARK CO 80863 USA "
    },
    {
      "Location ID": "80864",
      "Location Name": "YODER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 475 N YODER RD YODER CO 80864 USA "
    },
    {
      "Location ID": "80866",
      "Location Name": "WOODLAND PARK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 300 W SOUTH AVE WOODLAND PARK CO 80863 USA "
    },
    {
      "Location ID": "808AA",
      "Location Name": "CADET",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2360 VANDENBERG DR STE 3C24 USAF ACADEMY CO 80840 USA "
    },
    {
      "Location ID": "808PA",
      "Location Name": "POSTAL ANNEX(FALCON CPO)",
      "Time Zone Offset (Hours)": -2,
      "Address": " 7661 MCLAUGHLIN RD FALCON CO 80831 USA "
    },
    {
      "Location ID": "80903",
      "Location Name": "COLORADO SPRINGS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 201 E PIKES PEAK AVE COLORADO SPRINGS CO 80903 USA "
    },
    {
      "Location ID": "80904",
      "Location Name": "WEST END",
      "Time Zone Offset (Hours)": -2,
      "Address": " 204 S 25TH ST COLORADO SPRINGS CO 80904 USA "
    },
    {
      "Location ID": "80906",
      "Location Name": "CHEYENNE MOUNTAIN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1540 S 8TH ST COLORADO SPRINGS CO 80905 USA "
    },
    {
      "Location ID": "80907",
      "Location Name": "NORTH END CO SPGS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2940 N PROSPECT ST COLORADO SPRINGS CO 80907 USA "
    },
    {
      "Location ID": "80909",
      "Location Name": "ANTARES",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2641 E UINTAH ST COLORADO SPRINGS CO 80909 USA "
    },
    {
      "Location ID": "80911",
      "Location Name": "SECURITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5755 KITTERY DR COLORADO SPRINGS CO 80911 USA "
    },
    {
      "Location ID": "80913",
      "Location Name": "FORT CARSON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1611 MCDONALD ST COLORADO SPRINGS CO 80913 USA "
    },
    {
      "Location ID": "80914",
      "Location Name": "CPU PETERSON AFB",
      "Time Zone Offset (Hours)": -2,
      "Address": " 360 W OTIS ST COLORADO SPRINGS CO 80914 USA "
    },
    {
      "Location ID": "80915",
      "Location Name": "CIMARRON HILLS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5925 GALLEY RD COLORADO SPRINGS CO 80915 USA "
    },
    {
      "Location ID": "80917",
      "Location Name": "TEMPLETON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4356 MONTEBELLO DR COLORADO SPRINGS CO 80918 USA "
    },
    {
      "Location ID": "80919",
      "Location Name": "ROCKRIMMON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5001 CENTENNIAL BLVD COLORADO SPRINGS CO 80919 USA "
    },
    {
      "Location ID": "80920",
      "Location Name": "BRIARGATE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 8585 CRITERION DR COLORADO SPRINGS CO 80920 USA "
    },
    {
      "Location ID": "809AA",
      "Location Name": "WEST END CARRIER ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2410 ROBINSON ST COLORADO SPRINGS CO 80904 USA "
    },
    {
      "Location ID": "809MS",
      "Location Name": "MONSTER SCOOTER, INC",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5045 GALLEY RD COLORADO SPRINGS CO 80915 USA "
    },
    {
      "Location ID": "809TM",
      "Location Name": "TMR DIRECT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2110 BUSCH AVE COLORADO SPGS CO 80904 USA "
    },
    {
      "Location ID": "80H",
      "Location Name": "DENVER STC",
      "Time Zone Offset (Hours)": -2,
      "Address": " 7500 EAST 53RD PLACE DENVER CO 80266 USA "
    },
    {
      "Location ID": "80Z",
      "Location Name": "DENVER NDC",
      "Time Zone Offset (Hours)": -2,
      "Address": " 7755 E 56TH AVE DENVER CO 80266 USA "
    },
    {
      "Location ID": "810",
      "Location Name": "PUEBLO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1022 FORTINO BLVD PUEBLO CO 81008 USA "
    },
    {
      "Location ID": "81001",
      "Location Name": "BELMONT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1025 E US HIGHWAY 50 BYP PUEBLO CO 81001 USA "
    },
    {
      "Location ID": "81003",
      "Location Name": "MIDTOWN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1000 W 6TH ST STE U PUEBLO CO 81003 USA "
    },
    {
      "Location ID": "81005",
      "Location Name": "SUNSET",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3000 WEDGEWOOD DR PUEBLO CO 81005 USA "
    },
    {
      "Location ID": "81007",
      "Location Name": "CPU PUEBLO WEST",
      "Time Zone Offset (Hours)": -2,
      "Address": " 434 S MCCULLOCH BLVD STE 2 PUEBLO CO 81007 USA "
    },
    {
      "Location ID": "81009",
      "Location Name": "PUEBLO GOVT PRINTING OFFICE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 31451 UNITED AVE PUEBLO CO 81001 USA "
    },
    {
      "Location ID": "81019",
      "Location Name": "CPU COLORADO CITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4490 BENT BROTHERS BLVD COLORADO CITY CO 81019 USA "
    },
    {
      "Location ID": "81022",
      "Location Name": "AVONDALE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 230 AVONDALE BLVD AVONDALE CO 81022 USA "
    },
    {
      "Location ID": "81025",
      "Location Name": "BOONE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 524 MAIN ST BOONE CO 81025 USA "
    },
    {
      "Location ID": "81030",
      "Location Name": "CHERAW",
      "Time Zone Offset (Hours)": -2,
      "Address": " 207 W GRAND AVE CHERAW CO 81030 USA "
    },
    {
      "Location ID": "81033",
      "Location Name": "CROWLEY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 208 BROADWAY ST CROWLEY CO 81033 USA "
    },
    {
      "Location ID": "81036",
      "Location Name": "EADS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1200 MAINE ST EADS CO 81036 USA "
    },
    {
      "Location ID": "81039",
      "Location Name": "FOWLER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 34191 COUNTY ROAD 3.5 FOWLER CO 81039 USA "
    },
    {
      "Location ID": "81041",
      "Location Name": "GRANADA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 111 S MAIN ST GRANADA CO 81041 USA "
    },
    {
      "Location ID": "81043",
      "Location Name": "HARTMAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 108 S MAIN ST HARTMAN CO 81043 USA "
    },
    {
      "Location ID": "81045",
      "Location Name": "HASWELL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 301 MAIN ST HASWELL CO 81045 USA "
    },
    {
      "Location ID": "81047",
      "Location Name": "HOLLY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 101 E SANTA FE ST HOLLY CO 81047 USA "
    },
    {
      "Location ID": "81050",
      "Location Name": "LA JUNTA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 324 COLORADO AVE LA JUNTA CO 81050 USA "
    },
    {
      "Location ID": "81052",
      "Location Name": "LAMAR",
      "Time Zone Offset (Hours)": -2,
      "Address": " 300 S 5TH ST LAMAR CO 81052 USA "
    },
    {
      "Location ID": "81054",
      "Location Name": "LAS ANIMAS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 513 6TH ST LAS ANIMAS CO 81054 USA "
    },
    {
      "Location ID": "81055",
      "Location Name": "LA VETA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 117 E RYUS ST LA VETA CO 81055 USA "
    },
    {
      "Location ID": "81058",
      "Location Name": "MANZANOLA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 103 S PARK ST MANZANOLA CO 81058 USA "
    },
    {
      "Location ID": "81062",
      "Location Name": "OLNEY SPRINGS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 601 WARNER ST OLNEY SPRINGS CO 81062 USA "
    },
    {
      "Location ID": "81063",
      "Location Name": "ORDWAY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 115 SHERMAN AVE ORDWAY CO 81063 USA "
    },
    {
      "Location ID": "81067",
      "Location Name": "ROCKY FORD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 401 N 9TH ST ROCKY FORD CO 81067 USA "
    },
    {
      "Location ID": "81069",
      "Location Name": "RYE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 8084 STATE HIGHWAY 165 RYE CO 81069 USA "
    },
    {
      "Location ID": "81071",
      "Location Name": "SHERIDAN LAKE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 619 BURNETTE ST SHERIDAN LAKE CO 81071 USA "
    },
    {
      "Location ID": "81073",
      "Location Name": "SPRINGFIELD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 188 E 9TH AVE SPRINGFIELD CO 81073 USA "
    },
    {
      "Location ID": "81076",
      "Location Name": "SUGAR CITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 208 COLORADO AVE SUGAR CITY CO 81076 USA "
    },
    {
      "Location ID": "81077",
      "Location Name": "SWINK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 302 COLUMBIA AVE SWINK CO 81077 USA "
    },
    {
      "Location ID": "81082",
      "Location Name": "TRINIDAD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 301 E MAIN ST TRINIDAD CO 81082 USA "
    },
    {
      "Location ID": "81087",
      "Location Name": "VILAS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 202 MAIN ST VILAS CO 81087 USA "
    },
    {
      "Location ID": "81089",
      "Location Name": "WALSENBURG",
      "Time Zone Offset (Hours)": -2,
      "Address": " 204 E 6TH ST WALSENBURG CO 81089 USA "
    },
    {
      "Location ID": "81090",
      "Location Name": "WALSH",
      "Time Zone Offset (Hours)": -2,
      "Address": " 529 COLORADO AVE WALSH CO 81090 USA "
    },
    {
      "Location ID": "810CO",
      "Location Name": "CHAE ORGANICS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 23300 US HIGHWAY 160 WALSENBURG CO 81089 USA "
    },
    {
      "Location ID": "810JB",
      "Location Name": "JODY'S BEAUTY SHOP VPO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 206 BROADWAY CROWLEY CO 80908 USA "
    },
    {
      "Location ID": "810SC",
      "Location Name": "UNIV S COLORADO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2200 BONFORTE BLVD PUEBLO CO 81001-4901 USA "
    },
    {
      "Location ID": "811",
      "Location Name": "ALAMOSA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 505 3RD ST ALAMOSA CO 81101 USA "
    },
    {
      "Location ID": "81120",
      "Location Name": "ANTONITO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 428 MAIN ST ANTONITO CO 81120 USA "
    },
    {
      "Location ID": "81129",
      "Location Name": "CONEJOS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 6731 COUNTY ROAD 13 CONEJOS CO 81129 USA "
    },
    {
      "Location ID": "81132",
      "Location Name": "DEL NORTE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 590 COLUMBIA AVE DEL NORTE CO 81132 USA "
    },
    {
      "Location ID": "81140",
      "Location Name": "LA JARA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 525 MAIN ST LA JARA CO 81140 USA "
    },
    {
      "Location ID": "81141",
      "Location Name": "MANASSA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 413 MAIN ST MANASSA CO 81141 USA "
    },
    {
      "Location ID": "81144",
      "Location Name": "MONTE VISTA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 150 WASHINGTON ST MONTE VISTA CO 81144 USA "
    },
    {
      "Location ID": "81147",
      "Location Name": "PAGOSA SPRINGS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 250 HOT SPRINGS BLVD PAGOSA SPRINGS CO 81147 USA "
    },
    {
      "Location ID": "81148",
      "Location Name": "ROMEO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 615 2ND AVE ROMEO CO 81148 USA "
    },
    {
      "Location ID": "81151",
      "Location Name": "SANFORD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 810 MAIN ST SANFORD CO 81151 USA "
    },
    {
      "Location ID": "81154",
      "Location Name": "SOUTH FORK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 130 W FRONTAGE RD SOUTH FORK CO 81154 USA "
    },
    {
      "Location ID": "812",
      "Location Name": "SALIDA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 310 D ST SALIDA CO 81201 USA "
    },
    {
      "Location ID": "81211",
      "Location Name": "BUENA VISTA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 110 BROOKDALE AVE BUENA VISTA CO 81211 USA "
    },
    {
      "Location ID": "81212",
      "Location Name": "CANON CITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1501 MAIN ST CANON CITY CO 81212 USA "
    },
    {
      "Location ID": "81236",
      "Location Name": "NATHROP",
      "Time Zone Offset (Hours)": -2,
      "Address": " 22617 US HIGHWAY 285 NATHROP CO 81236 USA "
    },
    {
      "Location ID": "813",
      "Location Name": "DURANGO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 222 W 8TH ST DURANGO CO 81301 USA "
    },
    {
      "Location ID": "81301",
      "Location Name": "DURANGO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 222 W 8TH ST DURANGO CO 81301 USA "
    },
    {
      "Location ID": "81320",
      "Location Name": "CAHONE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 51796 HIGHWAY 491 CAHONE CO 81320-8500 USA "
    },
    {
      "Location ID": "81321",
      "Location Name": "CORTEZ",
      "Time Zone Offset (Hours)": -2,
      "Address": " 35 S BEECH ST CORTEZ CO 81321-9998 USA "
    },
    {
      "Location ID": "81323",
      "Location Name": "DOLORES",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1000 RAILROAD AVE N DOLORES CO 81323-9733 USA "
    },
    {
      "Location ID": "81324",
      "Location Name": "DOVE CREEK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 80 HIGHWAY 491 DOVE CREEK CO 81324-9997 USA "
    },
    {
      "Location ID": "81326",
      "Location Name": "HESPERUS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 10864 HIGHWAY 160 HESPERUS CO 81326-7808 USA "
    },
    {
      "Location ID": "81327",
      "Location Name": "LEWIS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 18794 HIGHWAY 491 LEWIS CO 81327-9997 USA "
    },
    {
      "Location ID": "81328",
      "Location Name": "MANCOS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 291 N WALNUT ST MANCOS CO 81328-9194 USA "
    },
    {
      "Location ID": "81329",
      "Location Name": "MARVEL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 10383 COUNTY ROAD 100 MARVEL CO 81329-9997 USA "
    },
    {
      "Location ID": "81331",
      "Location Name": "PLEASANT VIEW",
      "Time Zone Offset (Hours)": -2,
      "Address": " 26070 HIGHWAY 491 PLEASANT VIEW CO 81331-9997 USA "
    },
    {
      "Location ID": "81335",
      "Location Name": "YELLOW JACKET",
      "Time Zone Offset (Hours)": -2,
      "Address": " 22009 COUNTY ROAD 18 YELLOW JACKET CO 81335-9997 USA "
    },
    {
      "Location ID": "813PV",
      "Location Name": "PLEASANT VIEW MERCANTILE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 26070 HIGHWAY 491 PLEASANT VIEW CO 81331 USA "
    },
    {
      "Location ID": "813SB",
      "Location Name": "SHOP BLOC",
      "Time Zone Offset (Hours)": -2,
      "Address": " 41190 ROAD G MANCOS CO 81328-8929 USA "
    },
    {
      "Location ID": "81401",
      "Location Name": "MONTROSE ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3025 WOODGATE RD MONTROSE CO 81401 USA "
    },
    {
      "Location ID": "815",
      "Location Name": "LOG GRAND JUNCTION",
      "Time Zone Offset (Hours)": -2,
      "Address": " 602 BURKEY ST GRAND JUNCTION CO 81505 USA "
    },
    {
      "Location ID": "81501",
      "Location Name": "GRAND JUNCTION",
      "Time Zone Offset (Hours)": -2,
      "Address": " 241 N 4TH ST GRAND JUNCTION CO 81501 USA "
    },
    {
      "Location ID": "81504",
      "Location Name": "FRUITVALE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2923 NORTH AVE STE 4 GRAND JUNCTION CO 81504 USA "
    },
    {
      "Location ID": "81520",
      "Location Name": "CLIFTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3252 F RD CLIFTON CO 81520 USA "
    },
    {
      "Location ID": "81521",
      "Location Name": "FRUITA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 113 N PARK SQ FRUITA CO 81521 USA "
    },
    {
      "Location ID": "81524",
      "Location Name": "LOMA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1345 13 RD LOMA CO 81524 USA "
    },
    {
      "Location ID": "81525",
      "Location Name": "MACK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1021 HOTEL CIR MACK CO 81525 USA "
    },
    {
      "Location ID": "81526",
      "Location Name": "PALISADE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 201 W THIRD ST PALISADE CO 81526 USA "
    },
    {
      "Location ID": "815GJ",
      "Location Name": "GRAND JUNCTION CARRIER ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 734 SCARLET ST GRAND JUNCTION CO 81505 USA "
    },
    {
      "Location ID": "815RS",
      "Location Name": "CPU GOOD 2 GO STORES #707",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2146 BROADWAY GRAND JUNCTION CO 81507 USA "
    },
    {
      "Location ID": "816",
      "Location Name": "GLENWOOD SPRINGS MHU",
      "Time Zone Offset (Hours)": -2,
      "Address": " 195 CENTER DR GLENWOOD SPRING CO 81601 USA "
    },
    {
      "Location ID": "81621",
      "Location Name": "BASALT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 12 MIDLAND AVE BASALT CO 81621 USA "
    },
    {
      "Location ID": "81623",
      "Location Name": "CARBONDALE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 655 MAIN ST CARBONDALE CO 81623 USA "
    },
    {
      "Location ID": "81624",
      "Location Name": "COLLBRAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 110 MAIN ST COLLBRAN CO 81624 USA "
    },
    {
      "Location ID": "81625",
      "Location Name": "CRAIG",
      "Time Zone Offset (Hours)": -2,
      "Address": " 556 PERSHING ST CRAIG CO 81625 USA "
    },
    {
      "Location ID": "81630",
      "Location Name": "DE BEQUE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 347 MINTER AVE DE BEQUE CO 81630 USA "
    },
    {
      "Location ID": "81635",
      "Location Name": "PARACHUTE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 120 N RAILROAD AVE PARACHUTE CO 81635 USA "
    },
    {
      "Location ID": "81638",
      "Location Name": "HAMILTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 17436 S HIGHWAY 13 HAMILTON CO 81638 USA "
    },
    {
      "Location ID": "81639",
      "Location Name": "HAYDEN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 850 W JEFFERSON AVE HAYDEN CO 81639 USA "
    },
    {
      "Location ID": "81641",
      "Location Name": "MEEKER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 365 6TH ST MEEKER CO 81641 USA "
    },
    {
      "Location ID": "81643",
      "Location Name": "MESA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 49012 KE RD MESA CO 81643 USA "
    },
    {
      "Location ID": "81646",
      "Location Name": "MOLINA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 53169 KE RD MOLINA CO 81646 USA "
    },
    {
      "Location ID": "81647",
      "Location Name": "NEW CASTLE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 112 W MAIN ST NEW CASTLE CO 81647 USA "
    },
    {
      "Location ID": "81650",
      "Location Name": "RIFLE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 330 RAILROAD AVE RIFLE CO 81650 USA "
    },
    {
      "Location ID": "81652",
      "Location Name": "SILT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 116 LYON BLVD SILT CO 81652 USA "
    },
    {
      "Location ID": "81654",
      "Location Name": "SNOWMASS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 26900 HIGHWAY 82 SNOWMASS CO 81654 USA "
    },
    {
      "Location ID": "81657",
      "Location Name": "VAIL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1300 N FRONTAGE RD W VAIL CO 81657 USA "
    },
    {
      "Location ID": "816AA",
      "Location Name": "RIFLE ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 818 TAUGHENBAUGH BLVD STE 106 RIFLE CO 81650 USA "
    },
    {
      "Location ID": "820",
      "Location Name": "LOG CHEYENNE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4800 CONVERSE AVE CHEYENNE WY 82009 USA "
    },
    {
      "Location ID": "821",
      "Location Name": "MAMMOTH HOT SPRINGS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1000 MAMMOTH DR MAMMOTH HOT SPG WY 82190 USA "
    },
    {
      "Location ID": "822",
      "Location Name": "WHEATLAND",
      "Time Zone Offset (Hours)": -2,
      "Address": " 852 WALNUT ST WHEATLAND WY 82201 USA "
    },
    {
      "Location ID": "82210",
      "Location Name": "CHUGWATER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 237 2ND ST CHUGWATER WY 82210 USA "
    },
    {
      "Location ID": "823",
      "Location Name": "RAWLINS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 106 5TH ST RAWLINS WY 82301 USA "
    },
    {
      "Location ID": "824",
      "Location Name": "WORLAND",
      "Time Zone Offset (Hours)": -2,
      "Address": " 119 N 7TH ST WORLAND WY 82401 USA "
    },
    {
      "Location ID": "82412",
      "Location Name": "BYRON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 120 E MAIN ST BYRON WY 82412 USA "
    },
    {
      "Location ID": "82420",
      "Location Name": "COWLEY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 71 E MAIN ST COWLEY WY 82420 USA "
    },
    {
      "Location ID": "82421",
      "Location Name": "DEAVER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 18 CENTRAL AVE DEAVER WY 82421 USA "
    },
    {
      "Location ID": "82423",
      "Location Name": "FRANNIE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 392 ASH ST FRANNIE WY 82423 USA "
    },
    {
      "Location ID": "82431",
      "Location Name": "LOVELL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 167 E 3RD ST LOVELL WY 82431 USA "
    },
    {
      "Location ID": "825",
      "Location Name": "RIVERTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 501 E MAIN ST RIVERTON WY 82501 USA "
    },
    {
      "Location ID": "82501",
      "Location Name": "RIVERTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 501 E MAIN ST RIVERTON WY 82501 USA "
    },
    {
      "Location ID": "82520",
      "Location Name": "LANDER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 230 GRANDVIEW DR LANDER WY 82520 USA "
    },
    {
      "Location ID": "826",
      "Location Name": "LOG CASPER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 411 N FOREST DR CASPER WY 82609 USA "
    },
    {
      "Location ID": "82601",
      "Location Name": "DOWNTOWN CASPER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 150 E B ST LBBY CASPER WY 82601 USA "
    },
    {
      "Location ID": "82633",
      "Location Name": "DOUGLAS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 129 N 3RD ST DOUGLAS WY 82633 USA "
    },
    {
      "Location ID": "82637",
      "Location Name": "GLENROCK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 703 W BIRCH ST GLENROCK WY 82637 USA "
    },
    {
      "Location ID": "827",
      "Location Name": "GILLETTE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 311 S KENDRICK AVE GILLETTE WY 82716 USA "
    },
    {
      "Location ID": "828",
      "Location Name": "SHERIDAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 101 E LOUCKS ST SHERIDAN WY 82801 USA "
    },
    {
      "Location ID": "82801",
      "Location Name": "SHERIDAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 101 E LOUCKS ST SHERIDAN WY 82801 USA "
    },
    {
      "Location ID": "82831",
      "Location Name": "ARVADA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 25 ATOKA ST ARVADA WY 82831 USA "
    },
    {
      "Location ID": "82833",
      "Location Name": "BIG HORN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 226 JOHNSON ST BIG HORN WY 82833 USA "
    },
    {
      "Location ID": "82834",
      "Location Name": "BUFFALO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 193 S MAIN ST BUFFALO WY 82834 USA "
    },
    {
      "Location ID": "82835",
      "Location Name": "CLEARMONT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1210 FRONT ST CLEARMONT WY 82835 USA "
    },
    {
      "Location ID": "82837",
      "Location Name": "LEITER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4679 US HIGHWAY 14-16 E LEITER WY 82837 USA "
    },
    {
      "Location ID": "82842",
      "Location Name": "STORY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 23 N PINEY RD STORY WY 82842 USA "
    },
    {
      "Location ID": "832",
      "Location Name": "LOG POCATELLO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1750 FLANDRO DR POCATELLO ID 83202 USA "
    },
    {
      "Location ID": "83201",
      "Location Name": "POCATELLO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 730 E CLARK ST POCATELLO ID 83201 USA "
    },
    {
      "Location ID": "83221",
      "Location Name": "BLACKFOOT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 165 W PACIFIC ST BLACKFOOT ID 83221 USA "
    },
    {
      "Location ID": "833",
      "Location Name": "PIONEER STATION",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1376 FILLMORE ST TWIN FALLS ID 83301 USA "
    },
    {
      "Location ID": "83301",
      "Location Name": "TWIN FALLS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 253 2ND AVE W TWIN FALLS ID 83301 USA "
    },
    {
      "Location ID": "83314",
      "Location Name": "BLISS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 100 E HIGHWAY 30 BLISS ID 83314 USA "
    },
    {
      "Location ID": "83316",
      "Location Name": "BUHL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 830 MAIN ST BUHL ID 83316 USA "
    },
    {
      "Location ID": "83318",
      "Location Name": "BURLEY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1353 OVERLAND AVE BURLEY ID 83318 USA "
    },
    {
      "Location ID": "83328",
      "Location Name": "FILER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 413 MAIN ST FILER ID 83328 USA "
    },
    {
      "Location ID": "83330",
      "Location Name": "GOODING",
      "Time Zone Offset (Hours)": -2,
      "Address": " 201 3RD AVE E GOODING ID 83330 USA "
    },
    {
      "Location ID": "83332",
      "Location Name": "HAGERMAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 444 N STATE ST HAGERMAN ID 83332 USA "
    },
    {
      "Location ID": "83346",
      "Location Name": "OAKLEY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 130 W MAIN ST OAKLEY ID 83346 USA "
    },
    {
      "Location ID": "83355",
      "Location Name": "WENDELL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 32 N SHOSHONE ST WENDELL ID 83355 USA "
    },
    {
      "Location ID": "834",
      "Location Name": "IDAHO FALLS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 605 4TH ST IDAHO FALLS ID 83401 USA "
    },
    {
      "Location ID": "83401",
      "Location Name": "IDAHO FALLS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 605 4TH ST IDAHO FALLS ID 83401 USA "
    },
    {
      "Location ID": "83402",
      "Location Name": "EAGLE ROCK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 875 N CAPITAL AVE IDAHO FALLS ID 83402 USA "
    },
    {
      "Location ID": "835",
      "Location Name": "LEWISTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1613 IDAHO ST LEWISTON ID 83501 USA "
    },
    {
      "Location ID": "83522",
      "Location Name": "COTTONWOOD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 301 BROADWAY COTTONWOOD ID 83522 USA "
    },
    {
      "Location ID": "83523",
      "Location Name": "CRAIGMONT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 118 W MAIN ST CRAIGMONT ID 83523 USA "
    },
    {
      "Location ID": "83524",
      "Location Name": "CULDESAC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 902 PHINNEY ST CULDESAC ID 83524 USA "
    },
    {
      "Location ID": "83526",
      "Location Name": "FERDINAND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 341 MAIN ST FERDINAND ID 83526 USA "
    },
    {
      "Location ID": "83530",
      "Location Name": "GRANGEVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 300 W MAIN ST STE 100 GRANGEVILLE ID 83530 USA "
    },
    {
      "Location ID": "83535",
      "Location Name": "JULIAETTA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 195 MAIN ST JULIAETTA ID 83535 USA "
    },
    {
      "Location ID": "83536",
      "Location Name": "KAMIAH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 412 MAIN ST KAMIAH ID 83536 USA "
    },
    {
      "Location ID": "83537",
      "Location Name": "KENDRICK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 703 E MAIN ST KENDRICK ID 83537 USA "
    },
    {
      "Location ID": "83539",
      "Location Name": "KOOSKIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 104 S MAIN ST KOOSKIA ID 83539 USA "
    },
    {
      "Location ID": "83540",
      "Location Name": "LAPWAI",
      "Time Zone Offset (Hours)": -3,
      "Address": " 102 S MAIN ST LAPWAI ID 83540 USA "
    },
    {
      "Location ID": "83541",
      "Location Name": "LENORE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19770 LENORE GRADE LENORE ID 83541 USA "
    },
    {
      "Location ID": "83543",
      "Location Name": "NEZPERCE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 310 OAK ST NEZPERCE ID 83543 USA "
    },
    {
      "Location ID": "83544",
      "Location Name": "OROFINO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 320 MICHIGAN AVE OROFINO ID 83544 USA "
    },
    {
      "Location ID": "83545",
      "Location Name": "PECK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 102 N MAIN ST PECK ID 83545 USA "
    },
    {
      "Location ID": "83552",
      "Location Name": "STITES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 212 MAIN ST STITES ID 83552 USA "
    },
    {
      "Location ID": "83555",
      "Location Name": "WINCHESTER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 318 NEZPERCE AVE WINCHESTER ID 83555 USA "
    },
    {
      "Location ID": "835HF",
      "Location Name": "LEWISTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1613 IDAHO ST LEWISTON ID 83501 USA "
    },
    {
      "Location ID": "836",
      "Location Name": "LOG BOISE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2201 S COLE RD BOISE ID 83708 USA "
    },
    {
      "Location ID": "83605",
      "Location Name": "CALDWELL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 823 ARTHUR ST CALDWELL ID 83605 USA "
    },
    {
      "Location ID": "83611",
      "Location Name": "CASCADE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 202 S FRONT ST CASCADE ID 83611 USA "
    },
    {
      "Location ID": "83615",
      "Location Name": "DONNELLY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 186 N MAIN ST DONNELLY ID 83615 USA "
    },
    {
      "Location ID": "83619",
      "Location Name": "FRUITLAND",
      "Time Zone Offset (Hours)": -2,
      "Address": " 501 SW 3RD ST FRUITLAND ID 83619 USA "
    },
    {
      "Location ID": "83622",
      "Location Name": "GARDEN VALLEY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 946 BANKS LOWMAN RD GARDEN VALLEY ID 83622 USA "
    },
    {
      "Location ID": "83624",
      "Location Name": "GRAND VIEW",
      "Time Zone Offset (Hours)": -2,
      "Address": " 265 STATE ST GRAND VIEW ID 83624 USA "
    },
    {
      "Location ID": "83626",
      "Location Name": "GREENLEAF",
      "Time Zone Offset (Hours)": -2,
      "Address": " 21344 MAIN ST GREENLEAF ID 83626 USA "
    },
    {
      "Location ID": "83628",
      "Location Name": "HOMEDALE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 7 N MAIN ST HOMEDALE ID 83628 USA "
    },
    {
      "Location ID": "83629",
      "Location Name": "HORSESHOE BEND",
      "Time Zone Offset (Hours)": -2,
      "Address": " 449 HIGHWAY 55 HORSESHOE BEND ID 83629 USA "
    },
    {
      "Location ID": "83630",
      "Location Name": "HUSTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 16021 PRIDE LN HUSTON ID 83630 USA "
    },
    {
      "Location ID": "83634",
      "Location Name": "KUNA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 693 E WYTHE CREEK CT KUNA ID 83634 USA "
    },
    {
      "Location ID": "83638",
      "Location Name": "MCCALL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 495 DEINHARD LN MCCALL ID 83638 USA "
    },
    {
      "Location ID": "83639",
      "Location Name": "MARSING",
      "Time Zone Offset (Hours)": -2,
      "Address": " 210 W MAIN ST MARSING ID 83639 USA "
    },
    {
      "Location ID": "83641",
      "Location Name": "MELBA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 314 CARRIE REX AVE MELBA ID 83641 USA "
    },
    {
      "Location ID": "83642",
      "Location Name": "MERIDIAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1200 N MAIN ST MERIDIAN ID 83642 USA "
    },
    {
      "Location ID": "83647",
      "Location Name": "MOUNTAIN HOME",
      "Time Zone Offset (Hours)": -2,
      "Address": " 350 N 3RD E MOUNTAIN HOME ID 83647 USA "
    },
    {
      "Location ID": "83651",
      "Location Name": "NAMPA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 123 11TH AVE S NAMPA ID 83651 USA "
    },
    {
      "Location ID": "83654",
      "Location Name": "NEW MEADOWS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 221 VIRGINIA ST NEW MEADOWS ID 83654 USA "
    },
    {
      "Location ID": "83655",
      "Location Name": "NEW PLYMOUTH",
      "Time Zone Offset (Hours)": -2,
      "Address": " 325 N PLYMOUTH AVE NEW PLYMOUTH ID 83655 USA "
    },
    {
      "Location ID": "83656",
      "Location Name": "NOTUS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 385 FARGO AVE NOTUS ID 83656 USA "
    },
    {
      "Location ID": "83660",
      "Location Name": "PARMA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 207 N 3RD ST PARMA ID 83660 USA "
    },
    {
      "Location ID": "83661",
      "Location Name": "PAYETTE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 915 CENTER AVE PAYETTE ID 83661 USA "
    },
    {
      "Location ID": "83672",
      "Location Name": "WEISER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 106 W MAIN ST WEISER ID 83672 USA "
    },
    {
      "Location ID": "83676",
      "Location Name": "WILDER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 109 5TH ST STE B WILDER ID 83676 USA "
    },
    {
      "Location ID": "836CA",
      "Location Name": "NAMPA CARRIER ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 820 PARKCENTRE WAY NAMPA ID 83651 USA "
    },
    {
      "Location ID": "836PP",
      "Location Name": "PACIFIC PRESS PUBLISHING ASSOC",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1350 N KINGS RD NAMPA ID 83687 USA "
    },
    {
      "Location ID": "836SF",
      "Location Name": "SPECIALTY FULFILLMENT CENTER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3 17TH AVE S NAMPA ID 83651 USA "
    },
    {
      "Location ID": "837AZ",
      "Location Name": "AMAZON - BOI",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2201 E FREIGHT ST BOISE ID 83716 USA "
    },
    {
      "Location ID": "83801",
      "Location Name": "ATHOL",
      "Time Zone Offset (Hours)": -3,
      "Address": "29777 29777 N OLD HWY 95 ATHOL ID 83801 USA "
    },
    {
      "Location ID": "83804",
      "Location Name": "BLANCHARD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 425 RAILROAD AVE BLANCHARD ID 83804 USA "
    },
    {
      "Location ID": "83805",
      "Location Name": "BONNERS FERRY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7167 1ST ST BONNERS FERRY ID 83805 USA "
    },
    {
      "Location ID": "83806",
      "Location Name": "BOVILL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 202 MAIN ST BOVILL ID 83806 USA "
    },
    {
      "Location ID": "83810",
      "Location Name": "CATALDO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 36180 E CANYON RD CATALDO ID 83810 USA "
    },
    {
      "Location ID": "83811",
      "Location Name": "CLARK FORK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 230 MAIN ST CLARK FORK ID 83811 USA "
    },
    {
      "Location ID": "83813",
      "Location Name": "COCOLALLA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 31 COCOLALLA LOOP RD COCOLALLA ID 83813 USA "
    },
    {
      "Location ID": "83823",
      "Location Name": "DEARY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 407 LINE ST DEARY ID 83823 USA "
    },
    {
      "Location ID": "83826",
      "Location Name": "EASTPORT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 100 E FERRY AVE EASTPORT ID 83826 USA "
    },
    {
      "Location ID": "83827",
      "Location Name": "ELK RIVER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 202 MAIN ST ELK RIVER ID 83827 USA "
    },
    {
      "Location ID": "83832",
      "Location Name": "GENESEE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 110 W WALNUT ST GENESEE ID 83832 USA "
    },
    {
      "Location ID": "83835",
      "Location Name": "HAYDEN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 109 W HONEYSUCKLE AVE HAYDEN ID 83835 USA "
    },
    {
      "Location ID": "83836",
      "Location Name": "HOPE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 626 WELLINGTON PL HOPE ID 83836 USA "
    },
    {
      "Location ID": "83837",
      "Location Name": "KELLOGG",
      "Time Zone Offset (Hours)": -3,
      "Address": " 302 S DIVISION ST KELLOGG ID 83837 USA "
    },
    {
      "Location ID": "83839",
      "Location Name": "KINGSTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 43051 SILVER VALLEY RD KINGSTON ID 83839 USA "
    },
    {
      "Location ID": "83840",
      "Location Name": "KOOTENAI",
      "Time Zone Offset (Hours)": -3,
      "Address": " 109 1ST ST KOOTENAI ID 83840 USA "
    },
    {
      "Location ID": "83843",
      "Location Name": "MOSCOW",
      "Time Zone Offset (Hours)": -3,
      "Address": " 220 E 5TH ST LBBY MOSCOW ID 83843 USA "
    },
    {
      "Location ID": "83847",
      "Location Name": "NAPLES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 100 HIGHWAY 95 NAPLES ID 83847 USA "
    },
    {
      "Location ID": "83849",
      "Location Name": "OSBURN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 107 N 4TH ST OSBURN ID 83849 USA "
    },
    {
      "Location ID": "83850",
      "Location Name": "PINEHURST",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9 MAIN ST PINEHURST ID 83850 USA "
    },
    {
      "Location ID": "83851",
      "Location Name": "PLUMMER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 235 10TH ST PLUMMER ID 83851 USA "
    },
    {
      "Location ID": "83852",
      "Location Name": "PONDERAY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 296 4TH ST PONDERAY ID 83852 USA "
    },
    {
      "Location ID": "83853",
      "Location Name": "PORTHILL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3329 PORTHILL LOOP RD PORTHILL ID 83853 USA "
    },
    {
      "Location ID": "83854",
      "Location Name": "POST FALLS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 405 N GREENSFERRY RD POST FALLS ID 83854 USA "
    },
    {
      "Location ID": "83856",
      "Location Name": "PRIEST RIVER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 43 TREAT ST PRIEST RIVER ID 83856 USA "
    },
    {
      "Location ID": "83858",
      "Location Name": "RATHDRUM",
      "Time Zone Offset (Hours)": -3,
      "Address": " 13867 W HIGHWAY 53 RATHDRUM ID 83858 USA "
    },
    {
      "Location ID": "83860",
      "Location Name": "SAGLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 468665 HIGHWAY 95 SAGLE ID 83860 USA "
    },
    {
      "Location ID": "83861",
      "Location Name": "SAINT MARIES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 222 S 7TH ST STE 2 SAINT MARIES ID 83861 USA "
    },
    {
      "Location ID": "83864",
      "Location Name": "SANDPOINT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 204 N 4TH AVE SANDPOINT ID 83864 USA "
    },
    {
      "Location ID": "83865",
      "Location Name": "COLBURN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 490870 HIGHWAY 95 COLBURN ID 83865 USA "
    },
    {
      "Location ID": "83867",
      "Location Name": "SILVERTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 102 W YELLOWSTONE AVE SILVERTON ID 83867 USA "
    },
    {
      "Location ID": "83868",
      "Location Name": "SMELTERVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 223 MAIN ST SMELTERVILLE ID 83868 USA "
    },
    {
      "Location ID": "83869",
      "Location Name": "SPIRIT LAKE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6087 W MAINE ST SPIRIT LAKE ID 83869 USA "
    },
    {
      "Location ID": "83871",
      "Location Name": "TROY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 605 S MAIN ST TROY ID 83871 USA "
    },
    {
      "Location ID": "83873",
      "Location Name": "WALLACE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 403 CEDAR ST WALLACE ID 83873 USA "
    },
    {
      "Location ID": "83876",
      "Location Name": "WORLEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 29801 S 3RD ST WORLEY ID 83876 USA "
    },
    {
      "Location ID": "838AM",
      "Location Name": "ALTERNATIVE MODLING CONCEPTS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5575 W RIVERBEND AVE POST FALLS ID 83854 USA "
    },
    {
      "Location ID": "838BK",
      "Location Name": "BUCK KNIVES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 660 S LOCHSA ST POST FALLS ID 83854 USA "
    },
    {
      "Location ID": "838FF",
      "Location Name": "FOXY FIX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4214 W RIVERBEND AVE POST FALLS ID 83854 USA "
    },
    {
      "Location ID": "838LG",
      "Location Name": "LACIE GIRL CLOTHING",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4470 W SELTICE WAY POST FALLS ID 83854 USA "
    },
    {
      "Location ID": "838MA",
      "Location Name": "MACEK CONSULTING",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4391 W HARGRAVE AVE POST FALLS ID 83854 USA "
    },
    {
      "Location ID": "838NA",
      "Location Name": "COEUR D ALENE CARRIER ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3845 N SCHREIBER WAY COEUR D ALENE ID 83815 USA "
    },
    {
      "Location ID": "838NM",
      "Location Name": "NANAMACS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4415 W HARGRAVE AVE POST FALLS ID 83854 USA "
    },
    {
      "Location ID": "838PO",
      "Location Name": "ELK RIVER LODGE GENERAL STORE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 201 MAIN ST ELK RIVER ID 83827 USA "
    },
    {
      "Location ID": "838SF",
      "Location Name": "SHABBY FABRICS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1052 S CLEARWATER LOOP POST FALLS ID 83854 USA "
    },
    {
      "Location ID": "838TI",
      "Location Name": "TEDDER INDUSTRIES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4411 W RIVERBEND AVE POST FALLS ID 83854 USA "
    },
    {
      "Location ID": "838WM",
      "Location Name": "WALMART",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6405 W POINTE PKWY POST FALLS ID 83854-6948 USA "
    },
    {
      "Location ID": "83AIM",
      "Location Name": "AMERICAN IMAGE MARKETING",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3923 E FLAMING AVE NAMPA ID 83687 USA "
    },
    {
      "Location ID": "840",
      "Location Name": "LOG SALT LAKE CITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1760 W 2100 S SALT LAKE CITY UT 84199 USA "
    },
    {
      "Location ID": "84003",
      "Location Name": "AMERICAN FORK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 55 N MERCHANT ST AMERICAN FORK UT 84003 USA "
    },
    {
      "Location ID": "84010",
      "Location Name": "BOUNTIFUL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 20 S MAIN ST BOUNTIFUL UT 84010 USA "
    },
    {
      "Location ID": "84014",
      "Location Name": "CENTERVILLE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 275 N MAIN ST CENTERVILLE UT 84014 USA "
    },
    {
      "Location ID": "84015",
      "Location Name": "CLEARFIELD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 98 N MAIN ST CLEARFIELD UT 84015 USA "
    },
    {
      "Location ID": "84016",
      "Location Name": "FREEPORT CENTER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 391 E 1700 S CLEARFIELD UT 84015 USA "
    },
    {
      "Location ID": "84017",
      "Location Name": "COALVILLE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 33 E CENTER ST COALVILLE UT 84017 USA "
    },
    {
      "Location ID": "84020",
      "Location Name": "DRAPER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 12180 S 300 E DRAPER UT 84020 USA "
    },
    {
      "Location ID": "84021",
      "Location Name": "DUCHESNE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 243 E MAIN ST DUCHESNE UT 84021 USA "
    },
    {
      "Location ID": "84024",
      "Location Name": "ECHO",
      "Time Zone Offset (Hours)": -2,
      "Address": " OLD HIGHWAY 30 ECHO UT 84024 USA "
    },
    {
      "Location ID": "84025",
      "Location Name": "FARMINGTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 145 E STATE ST FARMINGTON UT 84025 USA "
    },
    {
      "Location ID": "84026",
      "Location Name": "FORT DUCHESNE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 7299 E HIGHWAY 40 FORT DUCHESNE UT 84026 USA "
    },
    {
      "Location ID": "84032",
      "Location Name": "HEBER CITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 125 E 100 N HEBER CITY UT 84032 USA "
    },
    {
      "Location ID": "84033",
      "Location Name": "HENEFER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 95 S MAIN ST HENEFER UT 84033 USA "
    },
    {
      "Location ID": "84036",
      "Location Name": "KAMAS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 55 W 100 N KAMAS UT 84036 USA "
    },
    {
      "Location ID": "84037",
      "Location Name": "KAYSVILLE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 285 N MAIN ST KAYSVILLE UT 84037 USA "
    },
    {
      "Location ID": "84041",
      "Location Name": "LAYTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1123 N FAIRFIELD RD LAYTON UT 84041 USA "
    },
    {
      "Location ID": "84043",
      "Location Name": "LEHI",
      "Time Zone Offset (Hours)": -2,
      "Address": " 333 E MAIN ST LEHI UT 84043 USA "
    },
    {
      "Location ID": "84047",
      "Location Name": "MIDVALE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 35 W CENTER ST MIDVALE UT 84047 USA "
    },
    {
      "Location ID": "84050",
      "Location Name": "MORGAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 188 N STATE ST MORGAN UT 84050 USA "
    },
    {
      "Location ID": "84052",
      "Location Name": "MYTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 80 E MAIN ST MYTON UT 84052 USA "
    },
    {
      "Location ID": "84054",
      "Location Name": "NORTH SALT LAKE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 345 N HIGHWAY 89 NORTH SALT LAKE UT 84054 USA "
    },
    {
      "Location ID": "84055",
      "Location Name": "OAKLEY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4820 N STATE ROAD 32 OAKLEY UT 84055 USA "
    },
    {
      "Location ID": "84057",
      "Location Name": "OREM",
      "Time Zone Offset (Hours)": -2,
      "Address": " 222 W CENTER ST OREM UT 84057 USA "
    },
    {
      "Location ID": "84060",
      "Location Name": "OLD TOWN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 450 MAIN ST PARK CITY UT 84060 USA "
    },
    {
      "Location ID": "84062",
      "Location Name": "PLEASANT GROVE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 180 S 100 W PLEASANT GROVE UT 84062 USA "
    },
    {
      "Location ID": "84065",
      "Location Name": "RIVERTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 12760 S PARK AVE RIVERTON UT 84065 USA "
    },
    {
      "Location ID": "84066",
      "Location Name": "ROOSEVELT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 81 S 300 E ROOSEVELT UT 84066 USA "
    },
    {
      "Location ID": "84067",
      "Location Name": "ROY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4879 S 1900 W ROY UT 84067 USA "
    },
    {
      "Location ID": "84068",
      "Location Name": "PARK CITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2100 PARK AVE PARK CITY UT 84060 USA "
    },
    {
      "Location ID": "84070",
      "Location Name": "SANDY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 8850 S 700 E SANDY UT 84070 USA "
    },
    {
      "Location ID": "84078",
      "Location Name": "VERNAL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 67 N 800 W VERNAL UT 84078 USA "
    },
    {
      "Location ID": "84083",
      "Location Name": "WENDOVER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 550 E WENDOVER BLVD WENDOVER UT 84083 USA "
    },
    {
      "Location ID": "84084",
      "Location Name": "WEST JORDAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 7901 S 3200 W WEST JORDAN UT 84088 USA "
    },
    {
      "Location ID": "84095",
      "Location Name": "SOUTH JORDAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 10102 S REDWOOD RD SOUTH JORDAN UT 84095 USA "
    },
    {
      "Location ID": "84097",
      "Location Name": "MOUNTAIN SHADOWS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 877 E 1200 S OREM UT 84097 USA "
    },
    {
      "Location ID": "84098",
      "Location Name": "SNYDERVILLE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1764 UINTA WAY STE H1 PARK CITY UT 84098 USA "
    },
    {
      "Location ID": "840AN",
      "Location Name": "LOG SALT LAKE CITY ASF",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1960 S INDUSTRIAL RD SALT LAKE CITY UT 84199 USA "
    },
    {
      "Location ID": "840AP",
      "Location Name": "ALEXANDERS PRINT ADVANTAGE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 245 S 1060 W LINDON UT 84042 USA "
    },
    {
      "Location ID": "840AX",
      "Location Name": "SALT LAKE PEAK ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1195 N 6550 W STE 400 SALT LAKE CITY UT 84116 USA "
    },
    {
      "Location ID": "840BN",
      "Location Name": "BALTIC BORN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 142 N 1800 W STE 300 LINDON UT 84042 USA "
    },
    {
      "Location ID": "840BW",
      "Location Name": "BIKE WAGON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 680 W 200 N NORTH SALT LAKE UT 84054 USA "
    },
    {
      "Location ID": "840DC",
      "Location Name": "DRAGON STEEL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1057 W 700 S STE 200 PLEASANT GROVE UT 84062 USA "
    },
    {
      "Location ID": "840DM",
      "Location Name": "DAVE'S DISCOUNT MOTORS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 228 W 12300 S. STE 106 DRAPER UT 84020 USA "
    },
    {
      "Location ID": "840HJ",
      "Location Name": "HYDRO JUG",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1017 W 600 N UNIT 1 OGDEN UT 84404 USA "
    },
    {
      "Location ID": "840HS",
      "Location Name": "HARMONS STATION PARK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 200 N STATION PKWY FARMINGTON UT 84025 USA "
    },
    {
      "Location ID": "840IC",
      "Location Name": "INTEGRACORE - VISIBLE SCM",
      "Time Zone Offset (Hours)": -2,
      "Address": " 6077 W WELLS PARK RD WEST JORDAN UT 84081 USA "
    },
    {
      "Location ID": "840ID",
      "Location Name": "IDRIVE FULLFILLMENT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 271 W 12800 S STE 207 DRAPER UT 84020 USA "
    },
    {
      "Location ID": "840KN",
      "Location Name": "KNS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 12577 S 265 W DRAPER UT 84020 USA "
    },
    {
      "Location ID": "840MM",
      "Location Name": "MINDY MAE'S MARKETING",
      "Time Zone Offset (Hours)": -2,
      "Address": " 705 N 1000 W #9 CENTERVILLE UT 84014 USA "
    },
    {
      "Location ID": "840MS",
      "Location Name": "MOMENTUM SHIPPING",
      "Time Zone Offset (Hours)": -2,
      "Address": " 553 N KAYS DR KAYSVILLE UT 84037 USA "
    },
    {
      "Location ID": "840OE",
      "Location Name": "OUTDOOR EQUIPPED",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1660 W GORDON AVE LAYTON UT 84041 USA "
    },
    {
      "Location ID": "840PB",
      "Location Name": "PITNEY BOWES / NEWGISTICS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2449 S 6755 W STE E W VALLEY CITY UT 84128 USA "
    },
    {
      "Location ID": "840SS",
      "Location Name": "SEVEN TIMES SIX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 582 S 1100 W W BOUNTIFUL UT 84087 USA "
    },
    {
      "Location ID": "840UP",
      "Location Name": "UPGRAB",
      "Time Zone Offset (Hours)": -2,
      "Address": " 625 N 1250 W  #2 CENTERVILLE UT 84014 USA "
    },
    {
      "Location ID": "840VM",
      "Location Name": "VITALITY MEDICAL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 11585 S STATE ST STE 106 DRAPER UT 84020 USA "
    },
    {
      "Location ID": "840VT",
      "Location Name": "VIVINT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 500 S 500 W BLDG 2 LINDON UT 84042 USA "
    },
    {
      "Location ID": "840YP",
      "Location Name": "YOUNIQUE PRODUCTS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4425 N THANKSGIVING WAY LEHI UT 84043 USA "
    },
    {
      "Location ID": "841",
      "Location Name": "SALT LAKE CITY DISTRICT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1760 W 2100 S SALT LAKE CITY UT 84199 USA "
    },
    {
      "Location ID": "84101",
      "Location Name": "SLC DOWTOWN STA RETAIL EAQ",
      "Time Zone Offset (Hours)": -2,
      "Address": " 30 W 900 S SALT LAKE CITY UT 84101 USA "
    },
    {
      "Location ID": "84104",
      "Location Name": "CUSTER ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2595 CUSTER RD STE 76 SALT LAKE CITY UT 84104 USA "
    },
    {
      "Location ID": "841DL",
      "Location Name": "SLC DELTA CARGO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3624 W 510 N SALT LAKE CITY UT 84116 USA "
    },
    {
      "Location ID": "841EL",
      "Location Name": "ENLINX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2620 S DECKER LAKE BLVD #500 WEST VALLEY CIT UT 84119 USA "
    },
    {
      "Location ID": "841PS",
      "Location Name": "PROSTAR",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1545 S 4800 W SALT LAKE CITY UT 84104 USA "
    },
    {
      "Location ID": "841VP",
      "Location Name": "VICTORY PACKAGING - SALT LAKE CITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 455 S 6400 W STE 400 SALT LAKE CITY UT 84104 USA "
    },
    {
      "Location ID": "842",
      "Location Name": "IRS OGDEN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1160 W 12TH ST OGDEN UT 84201-0000 USA "
    },
    {
      "Location ID": "84201",
      "Location Name": "IRS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1160 W 12TH ST OGDEN UT 84201 USA "
    },
    {
      "Location ID": "843",
      "Location Name": "OGDEN               UT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3680 PACIFIC AVE OGDEN UT 84401 USA "
    },
    {
      "Location ID": "84301",
      "Location Name": "BEAR RIVER CITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4670 W 5900 N BEAR RIVER CITY UT 84301 USA "
    },
    {
      "Location ID": "84302",
      "Location Name": "BRIGHAM CITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 16 S 100 W BRIGHAM CITY UT 84302 USA "
    },
    {
      "Location ID": "84307",
      "Location Name": "CORINNE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2425 N 4000 W CORINNE UT 84307 USA "
    },
    {
      "Location ID": "84310",
      "Location Name": "EDEN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2577 N HIGHWAY 162 EDEN UT 84310 USA "
    },
    {
      "Location ID": "84311",
      "Location Name": "FIELDING",
      "Time Zone Offset (Hours)": -2,
      "Address": " 25 W MAIN ST FIELDING UT 84311 USA "
    },
    {
      "Location ID": "84312",
      "Location Name": "GARLAND",
      "Time Zone Offset (Hours)": -2,
      "Address": " 11 N MAIN ST GARLAND UT 84312 USA "
    },
    {
      "Location ID": "84316",
      "Location Name": "HOWELL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 15520 EAST CANAL ROAD HOWELL UT 84316 USA "
    },
    {
      "Location ID": "84317",
      "Location Name": "HUNTSVILLE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 7785 E 500 S HUNTSVILLE UT 84317 USA "
    },
    {
      "Location ID": "84321",
      "Location Name": "LOGAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 75 W 200 N LOGAN UT 84321 USA "
    },
    {
      "Location ID": "84330",
      "Location Name": "PLYMOUTH",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5046 W 20300 N PLYMOUTH UT 84330 USA "
    },
    {
      "Location ID": "84334",
      "Location Name": "RIVERSIDE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 15445 N 5400 W RIVERSIDE UT 84334 USA "
    },
    {
      "Location ID": "84336",
      "Location Name": "SNOWVILLE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 115 W MAIN ST SNOWVILLE UT 84336 USA "
    },
    {
      "Location ID": "84337",
      "Location Name": "TREMONTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 111 S TREMONT ST TREMONTON UT 84337 USA "
    },
    {
      "Location ID": "84340",
      "Location Name": "WILLARD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 196 S MAIN ST WILLARD UT 84340 USA "
    },
    {
      "Location ID": "84401",
      "Location Name": "OGDEN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3680 PACIFIC AVE OGDEN UT 84401 USA "
    },
    {
      "Location ID": "84403",
      "Location Name": "MOUNT OGDEN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1145 E 4600 S OGDEN UT 84403 USA "
    },
    {
      "Location ID": "844RS",
      "Location Name": "IRS AUTOLIV",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1973 RULON WHITE BLVD OGDEN UT 84201 USA "
    },
    {
      "Location ID": "84501",
      "Location Name": "PRICE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 95 S CARBON AVE PRICE UT 84501 USA "
    },
    {
      "Location ID": "84513",
      "Location Name": "CASTLE DALE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 55 S CENTER ST CASTLE DALE UT 84513 USA "
    },
    {
      "Location ID": "84516",
      "Location Name": "CLAWSON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 10 S MAIN ST CLAWSON UT 84516 USA "
    },
    {
      "Location ID": "84518",
      "Location Name": "CLEVELAND",
      "Time Zone Offset (Hours)": -2,
      "Address": " 290 W MAIN ST CLEVELAND UT 84518 USA "
    },
    {
      "Location ID": "84521",
      "Location Name": "ELMO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 100 E MAIN ST ELMO UT 84521 USA "
    },
    {
      "Location ID": "84522",
      "Location Name": "EMERY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 31 E MAIN ST EMERY UT 84522 USA "
    },
    {
      "Location ID": "84523",
      "Location Name": "FERRON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 60 N 100 W FERRON UT 84523 USA "
    },
    {
      "Location ID": "84525",
      "Location Name": "GREEN RIVER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 80 E MAIN ST GREEN RIVER UT 84525 USA "
    },
    {
      "Location ID": "84528",
      "Location Name": "HUNTINGTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 45 E 100 N HUNTINGTON UT 84528 USA "
    },
    {
      "Location ID": "84530",
      "Location Name": "LA SAL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2 MAIN ST LA SAL UT 84530 USA "
    },
    {
      "Location ID": "84532",
      "Location Name": "MOAB",
      "Time Zone Offset (Hours)": -2,
      "Address": " 50 E 100 N MOAB UT 84532 USA "
    },
    {
      "Location ID": "84535",
      "Location Name": "MONTICELLO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 197 S MAIN ST MONTICELLO UT 84535 USA "
    },
    {
      "Location ID": "84537",
      "Location Name": "ORANGEVILLE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 350 S MAIN ST ORANGEVILLE UT 84537 USA "
    },
    {
      "Location ID": "845WS",
      "Location Name": "RAY POGUE",
      "Time Zone Offset (Hours)": -2,
      "Address": " HC 11 BOX 100 GREEN RIVER UT 84525 USA "
    },
    {
      "Location ID": "846",
      "Location Name": "LOG PROVO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 936 S 250 E PROVO UT 84606 USA "
    },
    {
      "Location ID": "84601",
      "Location Name": "PROVO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 95 W 100 S PROVO UT 84601 USA "
    },
    {
      "Location ID": "84620",
      "Location Name": "AURORA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 178 W CENTER ST AURORA UT 84620 USA "
    },
    {
      "Location ID": "84622",
      "Location Name": "CENTERFIELD",
      "Time Zone Offset (Hours)": -2,
      "Address": " P O BOX 229998 CENTERFIELD UT 84622 USA "
    },
    {
      "Location ID": "84624",
      "Location Name": "DELTA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 86 S 300 E DELTA UT 84624 USA "
    },
    {
      "Location ID": "84626",
      "Location Name": "ELBERTA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 15156 S 12800 W ELBERTA UT 84626 USA "
    },
    {
      "Location ID": "84627",
      "Location Name": "EPHRAIM",
      "Time Zone Offset (Hours)": -2,
      "Address": " 55 E 100 N EPHRAIM UT 84627 USA "
    },
    {
      "Location ID": "84628",
      "Location Name": "EUREKA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 482 E MAIN ST EUREKA UT 84628 USA "
    },
    {
      "Location ID": "84629",
      "Location Name": "FAIRVIEW",
      "Time Zone Offset (Hours)": -2,
      "Address": " 62 W CENTER ST FAIRVIEW UT 84629 USA "
    },
    {
      "Location ID": "84631",
      "Location Name": "FILLMORE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 60 E CENTER ST FILLMORE UT 84631 USA "
    },
    {
      "Location ID": "84633",
      "Location Name": "GOSHEN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 80 S CENTER ST GOSHEN UT 84633 USA "
    },
    {
      "Location ID": "84634",
      "Location Name": "GUNNISON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 95 W 100 S GUNNISON UT 84634 USA "
    },
    {
      "Location ID": "84635",
      "Location Name": "HINCKLEY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5 N MAIN ST HINCKLEY UT 84635 USA "
    },
    {
      "Location ID": "84636",
      "Location Name": "HOLDEN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 35 SOUTH MAIN HOLDEN UT 84636 USA "
    },
    {
      "Location ID": "84637",
      "Location Name": "KANOSH",
      "Time Zone Offset (Hours)": -2,
      "Address": " 50 S MAIN ST KANOSH UT 84637 USA "
    },
    {
      "Location ID": "84638",
      "Location Name": "LEAMINGTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " P O BOX 389998 LEAMINGTON UT 84638 USA "
    },
    {
      "Location ID": "84639",
      "Location Name": "LEVAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 75 N MAIN ST LEVAN UT 84639 USA "
    },
    {
      "Location ID": "84640",
      "Location Name": "LYNNDYL",
      "Time Zone Offset (Hours)": -2,
      "Address": " US HIGHWAY 6 LYNNDYL UT 84640 USA "
    },
    {
      "Location ID": "84642",
      "Location Name": "MANTI",
      "Time Zone Offset (Hours)": -2,
      "Address": " 140 N MAIN ST MANTI UT 84642 USA "
    },
    {
      "Location ID": "84643",
      "Location Name": "MAYFIELD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 48 N MAIN ST MAYFIELD UT 84643 USA "
    },
    {
      "Location ID": "84644",
      "Location Name": "MEADOW",
      "Time Zone Offset (Hours)": -2,
      "Address": " 120 N MAIN ST MEADOW UT 84644 USA "
    },
    {
      "Location ID": "84645",
      "Location Name": "MONA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 44 W 100 N MONA UT 84645 USA "
    },
    {
      "Location ID": "84647",
      "Location Name": "MOUNT PLEASANT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 55 N 100 W MOUNT PLEASANT UT 84647 USA "
    },
    {
      "Location ID": "84648",
      "Location Name": "NEPHI",
      "Time Zone Offset (Hours)": -2,
      "Address": " 10 N MAIN ST NEPHI UT 84648 USA "
    },
    {
      "Location ID": "84649",
      "Location Name": "OAK CITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 70 N MAIN ST OAK CITY UT 84649 USA "
    },
    {
      "Location ID": "84651",
      "Location Name": "PAYSON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 955 E 100 N PAYSON UT 84651 USA "
    },
    {
      "Location ID": "84652",
      "Location Name": "REDMOND",
      "Time Zone Offset (Hours)": -2,
      "Address": " 19 S 100 W REDMOND UT 84652 USA "
    },
    {
      "Location ID": "84653",
      "Location Name": "SALEM",
      "Time Zone Offset (Hours)": -2,
      "Address": " 134 E 200 N SALEM UT 84653 USA "
    },
    {
      "Location ID": "84654",
      "Location Name": "SALINA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 35 N 100 E SALINA UT 84654 USA "
    },
    {
      "Location ID": "84655",
      "Location Name": "SANTAQUIN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 20 W MAIN ST SANTAQUIN UT 84655 USA "
    },
    {
      "Location ID": "84656",
      "Location Name": "SCIPIO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 65 W 200 S SCIPIO UT 84656 USA "
    },
    {
      "Location ID": "84657",
      "Location Name": "SIGURD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 20 S MAIN ST SIGURD UT 84657 USA "
    },
    {
      "Location ID": "84660",
      "Location Name": "SPANISH FORK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 165 E 900 N SPANISH FORK UT 84660 USA "
    },
    {
      "Location ID": "84663",
      "Location Name": "SPRINGVILLE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 75 E CENTER ST SPRINGVILLE UT 84663 USA "
    },
    {
      "Location ID": "84665",
      "Location Name": "STERLING",
      "Time Zone Offset (Hours)": -2,
      "Address": " 111 N MAIN STREET STERLING UT 84665 USA "
    },
    {
      "Location ID": "846AH",
      "Location Name": "ABUNDANT HEALTH",
      "Time Zone Offset (Hours)": -2,
      "Address": " 222 W 3560 N SPANISH FORK UT 84660 USA "
    },
    {
      "Location ID": "846LF",
      "Location Name": "LAUNCH FULFILLMENT - SPRINGVILLE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1710 W 1000 N STE 101 SPRINGVILLE UT 84663 USA "
    },
    {
      "Location ID": "846NC",
      "Location Name": "NUTRA CONNECTION",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3549 N MAIN ST SPANISH FORK UT 84660 USA "
    },
    {
      "Location ID": "846NE",
      "Location Name": "NEXPEDITE #2",
      "Time Zone Offset (Hours)": -2,
      "Address": " 66 W 400 S PROVO UT 84601 USA "
    },
    {
      "Location ID": "846NS",
      "Location Name": "NU SKIN WAREHOUSE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 275 E 1325 S PROVO UT 84606 USA "
    },
    {
      "Location ID": "846PD",
      "Location Name": "PLUNDER DESIGN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 150 E 400 N SALEM UT 84653 USA "
    },
    {
      "Location ID": "846RM",
      "Location Name": "ROCKY MOUNTAIN ATV",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1551 AMERICAN WAY PAYSON UT 84651 USA "
    },
    {
      "Location ID": "846SP",
      "Location Name": "SHERWOOD PET HEALTH",
      "Time Zone Offset (Hours)": -2,
      "Address": " 505 W 100 N EPHRAIM UT 84627 USA "
    },
    {
      "Location ID": "846YL",
      "Location Name": "YOUNG LIVING ESSENTIAL OILS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 142 E 3450 N SPANISH FORK UT 84660 USA "
    },
    {
      "Location ID": "846ZX",
      "Location Name": "ZUMMIX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3596 S MOUNTAIN VISTA PKWY PROVO UT 84606 USA "
    },
    {
      "Location ID": "84701",
      "Location Name": "RICHFIELD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 93 N MAIN ST RICHFIELD UT 84701 USA "
    },
    {
      "Location ID": "84710",
      "Location Name": "ALTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 11 N MAIN ST ALTON UT 84710 USA "
    },
    {
      "Location ID": "84713",
      "Location Name": "BEAVER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 20 S MAIN ST BEAVER UT 84713 USA "
    },
    {
      "Location ID": "84720",
      "Location Name": "CEDAR CITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 333 N MAIN ST CEDAR CITY UT 84720 USA "
    },
    {
      "Location ID": "84723",
      "Location Name": "CIRCLEVILLE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 143 W MAIN ST CIRCLEVILLE UT 84723 USA "
    },
    {
      "Location ID": "84729",
      "Location Name": "GLENDALE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 35 S MAIN ST GLENDALE UT 84729 USA "
    },
    {
      "Location ID": "84735",
      "Location Name": "HATCH",
      "Time Zone Offset (Hours)": -2,
      "Address": " 190 N MAIN ST HATCH UT 84735 USA "
    },
    {
      "Location ID": "84737",
      "Location Name": "HURRICANE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1075 W 100 N HURRICANE UT 84737 USA "
    },
    {
      "Location ID": "84740",
      "Location Name": "JUNCTION",
      "Time Zone Offset (Hours)": -2,
      "Address": " 500 N MAIN ST JUNCTION UT 84740 USA "
    },
    {
      "Location ID": "84741",
      "Location Name": "KANAB",
      "Time Zone Offset (Hours)": -2,
      "Address": " 39 S MAIN ST KANAB UT 84741 USA "
    },
    {
      "Location ID": "84746",
      "Location Name": "LEEDS",
      "Time Zone Offset (Hours)": -2,
      "Address": " P O BOX 469998 LEEDS UT 84746 USA "
    },
    {
      "Location ID": "84750",
      "Location Name": "MARYSVALE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 100 W 200 N MARYSVALE UT 84750 USA "
    },
    {
      "Location ID": "84754",
      "Location Name": "MONROE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 485 N MAIN ST MONROE UT 84754 USA "
    },
    {
      "Location ID": "84758",
      "Location Name": "ORDERVILLE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 14 W STATE ST ORDERVILLE UT 84758 USA "
    },
    {
      "Location ID": "84759",
      "Location Name": "PANGUITCH",
      "Time Zone Offset (Hours)": -2,
      "Address": " 65 N 100 W PANGUITCH UT 84759 USA "
    },
    {
      "Location ID": "84760",
      "Location Name": "PAROWAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 500 N 100 W ST PARAGONAH UT 84760 USA "
    },
    {
      "Location ID": "84761",
      "Location Name": "PAROWAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 58 S 100 W PAROWAN UT 84761 USA "
    },
    {
      "Location ID": "84765",
      "Location Name": "SANTA CLARA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1400 CHAPEL ST SANTA CLARA UT 84765 USA "
    },
    {
      "Location ID": "84770",
      "Location Name": "DOWNTOWN SAINT GEORGE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 180 N MAIN ST SAINT GEORGE UT 84770 USA "
    },
    {
      "Location ID": "84772",
      "Location Name": "SUMMIT",
      "Time Zone Offset (Hours)": -2,
      "Address": " US POSTAL SERVICE SUMMIT UT 84772 USA "
    },
    {
      "Location ID": "84780",
      "Location Name": "WASHINGTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 25 W TELEGRAPH ST WASHINGTON UT 84780 USA "
    },
    {
      "Location ID": "84790",
      "Location Name": "SAINT GEORGE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1150 E RIVERSIDE DR SAINT GEORGE UT 84790 USA "
    },
    {
      "Location ID": "847BL",
      "Location Name": "BALANCE OF NATURE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 880 N PINION ST HILDALE UT 84784 USA "
    },
    {
      "Location ID": "847PZ",
      "Location Name": "PAPARAZZI ACCESSORIES",
      "Time Zone Offset (Hours)": -2,
      "Address": " 36 N 1000 W HURRICANE UT 84737 USA "
    },
    {
      "Location ID": "84ANX",
      "Location Name": "SALT LAKE CITY ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1795 W 2100 S SALT LAKE CITY UT 84199 USA "
    },
    {
      "Location ID": "84H",
      "Location Name": "SALT LAKE CITY STC",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2440 S 3600 W STE C SALT LAKE CITY UT 84119 USA "
    },
    {
      "Location ID": "84IRS",
      "Location Name": "IRS CPS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1160 W 1200 S OGDEN UT 84201 USA "
    },
    {
      "Location ID": "850",
      "Location Name": "PHOENIX ASF",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4949 E VAN BUREN ST RM 49 PHOENIX AZ 85026 USA "
    },
    {
      "Location ID": "85087",
      "Location Name": "DAISY MOUNTAIN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 44047 N 43RD AVE PHOENIX AZ 85087 USA "
    },
    {
      "Location ID": "850AA",
      "Location Name": "NORTHWEST ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2727 W CAMELBACK RD PHOENIX AZ 85017 USA "
    },
    {
      "Location ID": "850AX",
      "Location Name": "PHOENIX PEAK ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 901 S 86TH AVE STE 101 TOLLESON AZ 85353 USA "
    },
    {
      "Location ID": "850FZ",
      "Location Name": "LOG WEST VALLEY AZ PSA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 500 S 104TH AVE TOLLESON AZ 85353 USA "
    },
    {
      "Location ID": "850NV",
      "Location Name": "NORTH VALLEY FACILITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2502 W GRADNVIEW RD PHOENIX AZ 85023 USA "
    },
    {
      "Location ID": "850PS",
      "Location Name": "PITNEY BOWES PRESORT SERVICES",
      "Time Zone Offset (Hours)": -2,
      "Address": " 835 E UNIVERSITY DR # 6 PHOENIX AZ 85034 USA "
    },
    {
      "Location ID": "85120",
      "Location Name": "APACHE JUNCTION",
      "Time Zone Offset (Hours)": -2,
      "Address": " 151 W SUPERSTITION BLVD APACHE JUNCTION AZ 85120 USA "
    },
    {
      "Location ID": "85142",
      "Location Name": "QUEEN CREEK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 22424 S ELLSWORTH LOOP RD QUEEN CREEK AZ 85142 USA "
    },
    {
      "Location ID": "852",
      "Location Name": "LOG PHOENIX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4949 E VAN BUREN ST PHOENIX AZ 85026 USA "
    },
    {
      "Location ID": "85233",
      "Location Name": "FIESTA ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2191 W OBISPO AVE GILBERT AZ 85233 USA "
    },
    {
      "Location ID": "85326",
      "Location Name": "BUCKEYE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 51 E MONROE AVE BUCKEYE AZ 85326 USA "
    },
    {
      "Location ID": "85327",
      "Location Name": "CAVE CREEK BOXES",
      "Time Zone Offset (Hours)": -2,
      "Address": " 38400 N SCHOOLHOUSE ROAD CAVE CREEK AZ 85327 USA "
    },
    {
      "Location ID": "85331",
      "Location Name": "CAVE CREEK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 38400 N SCHOOL HOUSE RD CAVE CREEK AZ 85331 USA "
    },
    {
      "Location ID": "85332",
      "Location Name": "CONGRESS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 22850 W HIGHWAY 71 CONGRESS AZ 85332 USA "
    },
    {
      "Location ID": "85335",
      "Location Name": "EL MIRAGE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 11925 W THUNDERBIRD RD EL MIRAGE AZ 85335 USA "
    },
    {
      "Location ID": "85338",
      "Location Name": "AVONDALE GOODYEAR",
      "Time Zone Offset (Hours)": -2,
      "Address": " 875 S ESTRELLA PKWY GOODYEAR AZ 85338 USA "
    },
    {
      "Location ID": "85340",
      "Location Name": "LITCHFIELD PARK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 591 E PLAZA CIR LITCHFIELD PARK AZ 85340 USA "
    },
    {
      "Location ID": "85345",
      "Location Name": "PEORIA DOWNTOWN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 10700 N 85TH AVE PEORIA AZ 85345 USA "
    },
    {
      "Location ID": "85351",
      "Location Name": "SUN CITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 9802 W BELL RD SUN CITY AZ 85351 USA "
    },
    {
      "Location ID": "85355",
      "Location Name": "WADDELL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 7000 N COTTON LN WADDELL AZ 85355 USA "
    },
    {
      "Location ID": "85358",
      "Location Name": "WICKENBURG",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2029 W WICKENBURG WAY WICKENBURG AZ 85390 USA "
    },
    {
      "Location ID": "85362",
      "Location Name": "YARNELL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 22610 HIGHWAY 89 YARNELL AZ 85362 USA "
    },
    {
      "Location ID": "85363",
      "Location Name": "YOUNGTOWN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 11129 W ARIZONA AVE YOUNGTOWN AZ 85363 USA "
    },
    {
      "Location ID": "85374",
      "Location Name": "SURPRISE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 14955 W BELL RD SURPRISE AZ 85374 USA "
    },
    {
      "Location ID": "85375",
      "Location Name": "SUN CITY WEST",
      "Time Zone Offset (Hours)": -2,
      "Address": " 19437 N NEW TRADITION RD SUN CITY WEST AZ 85375 USA "
    },
    {
      "Location ID": "85377",
      "Location Name": "CAREFREE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 100 EASY ST CAREFREE AZ 85377 USA "
    },
    {
      "Location ID": "85381",
      "Location Name": "PEORIA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 8380 W EMILE ZOLA AVE PEORIA AZ 85381 USA "
    },
    {
      "Location ID": "853AC",
      "Location Name": "LAVEEN ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3721 W DOBBINS RD LAVEEN AZ 85339 USA "
    },
    {
      "Location ID": "853AX",
      "Location Name": "EL MIRAGE ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 12375 W ALICE STE 1 EL MIRAGE AZ 85335 USA "
    },
    {
      "Location ID": "853JL",
      "Location Name": "JBUG LOGISTICS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 12900 N DYSART RD SURPRISE AZ 85379 USA "
    },
    {
      "Location ID": "853RE",
      "Location Name": "REI - GOODYEAR DC3",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4877 N COTTON LN GOODYEAR AZ 85395 USA "
    },
    {
      "Location ID": "855",
      "Location Name": "GLOBE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1770 HIGHWAY 60-77 GLOBE AZ 85501 USA "
    },
    {
      "Location ID": "85530",
      "Location Name": "BYLAS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 297 E US HIGHWAY 70 BYLAS AZ 85530 USA "
    },
    {
      "Location ID": "85531",
      "Location Name": "CENTRAL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5442 W COOMBS ST CENTRAL AZ 85531 USA "
    },
    {
      "Location ID": "85533",
      "Location Name": "CLIFTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 494 N CORONADO BLVD CLIFTON AZ 85533 USA "
    },
    {
      "Location ID": "85534",
      "Location Name": "DUNCAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 209 MAIN ST DUNCAN AZ 85534 USA "
    },
    {
      "Location ID": "85536",
      "Location Name": "FORT THOMAS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 15550 W US HIGHWAY 70 FORT THOMAS AZ 85536 USA "
    },
    {
      "Location ID": "85540",
      "Location Name": "MORENCI",
      "Time Zone Offset (Hours)": -2,
      "Address": " 9 MORENCI MALL MORENCI AZ 85540 USA "
    },
    {
      "Location ID": "85541",
      "Location Name": "PAYSON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 100 W FRONTIER ST PAYSON AZ 85541 USA "
    },
    {
      "Location ID": "85542",
      "Location Name": "PERIDOT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 261 STATE HIGHWAY 170 PERIDOT AZ 85542 USA "
    },
    {
      "Location ID": "85543",
      "Location Name": "PIMA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 41 E CENTER ST PIMA AZ 85543 USA "
    },
    {
      "Location ID": "85546",
      "Location Name": "SAFFORD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 504 S 5TH AVE SAFFORD AZ 85546 USA "
    },
    {
      "Location ID": "85550",
      "Location Name": "SAN CARLOS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 9998 SAN CARLOS AVE SAN CARLOS AZ 85550 USA "
    },
    {
      "Location ID": "85551",
      "Location Name": "SOLOMON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2106 S BOWIE AVE SOLOMON AZ 85551 USA "
    },
    {
      "Location ID": "85552",
      "Location Name": "THATCHER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1122 N STADIUM AVE THATCHER AZ 85552 USA "
    },
    {
      "Location ID": "855AA",
      "Location Name": "GLOBE ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1770 HIGHWAY 60-77 GLOBE AZ 85501 USA "
    },
    {
      "Location ID": "855SC",
      "Location Name": "GLOBE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 101 S HILL ST GLOBE AZ 85501 USA "
    },
    {
      "Location ID": "856",
      "Location Name": "LOG TUCSON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1501 S CHERRYBELL STRA TUCSON AZ 85726 USA "
    },
    {
      "Location ID": "85603",
      "Location Name": "BISBEE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1248 W HIGHWAY 92 BISBEE AZ 85603 USA "
    },
    {
      "Location ID": "85607",
      "Location Name": "DOUGLAS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 601 E 10TH ST DOUGLAS AZ 85607 USA "
    },
    {
      "Location ID": "85610",
      "Location Name": "ELFRIDA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 10341 N HIGHWAY 191 ELFRIDA AZ 85610 USA "
    },
    {
      "Location ID": "85613",
      "Location Name": "FORT HUACHUCA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 47 ARIZONA ST FORT HUACHUCA AZ 85613 USA "
    },
    {
      "Location ID": "85614",
      "Location Name": "GREEN VALLEY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 50 E CONTINENTAL RD GREEN VALLEY AZ 85614 USA "
    },
    {
      "Location ID": "85615",
      "Location Name": "HEREFORD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 7268 S HIGHWAY 92 HEREFORD AZ 85615 USA "
    },
    {
      "Location ID": "85616",
      "Location Name": "HUACHUCA CITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 690 N GONZALES BLVD HUACHUCA CITY AZ 85616 USA "
    },
    {
      "Location ID": "85624",
      "Location Name": "PATAGONIA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 100 N TAYLOR LN PATAGONIA AZ 85624 USA "
    },
    {
      "Location ID": "85626",
      "Location Name": "PIRTLEVILLE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 430 W GRACE AVE PIRTLEVILLE AZ 85626 USA "
    },
    {
      "Location ID": "85629",
      "Location Name": "SAHUARITA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 815 W VIA RANCHO SAHUARITA SAHUARITA AZ 85629 USA "
    },
    {
      "Location ID": "85630",
      "Location Name": "SAINT DAVID",
      "Time Zone Offset (Hours)": -2,
      "Address": " 21 S MILLER LN SAINT DAVID AZ 85630 USA "
    },
    {
      "Location ID": "85635",
      "Location Name": "SIERRA VISTA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2300 E FRY BLVD SIERRA VISTA AZ 85635 USA "
    },
    {
      "Location ID": "85637",
      "Location Name": "SONOITA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3166 HIGHWAY 83 STE 1 SONOITA AZ 85637 USA "
    },
    {
      "Location ID": "85638",
      "Location Name": "TOMBSTONE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 100 HASKELL ST TOMBSTONE AZ 85638 USA "
    },
    {
      "Location ID": "85642",
      "Location Name": "VAIL ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 13333 E COLOSSAL CAVE RD VAIL AZ 85641 USA "
    },
    {
      "Location ID": "856CM",
      "Location Name": "CENTRAL MAIL ROOM - FORT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 448 CHRISTY AVE FORT HUACHUCA AZ 85613 USA "
    },
    {
      "Location ID": "856TH",
      "Location Name": "TUCSON THS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2800 E AIRPORT DR UNIT 6 TUCSON AZ 85756 USA "
    },
    {
      "Location ID": "85704",
      "Location Name": "CASAS ADOBES",
      "Time Zone Offset (Hours)": -2,
      "Address": " 6281 N ORACLE RD TUCSON AZ 85704 USA "
    },
    {
      "Location ID": "85711",
      "Location Name": "CORONADO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 255 N ROSEMONT BLVD TUCSON AZ 85711 USA "
    },
    {
      "Location ID": "85726",
      "Location Name": "TUCSON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1501 S CHERRYBELL STRA RM 205 TUCSON AZ 85726 USA "
    },
    {
      "Location ID": "857GC",
      "Location Name": "GENCO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 6350 E LITTLETOWN RD TUCSON AZ 85756 USA "
    },
    {
      "Location ID": "857TA",
      "Location Name": "TARGET.COM",
      "Time Zone Offset (Hours)": -2,
      "Address": " 8940 E RITA PARK DR TUCSON AZ 85747 USA "
    },
    {
      "Location ID": "859",
      "Location Name": "SHOW LOW",
      "Time Zone Offset (Hours)": -2,
      "Address": " 191 W DEUCE OF CLUBS SHOW LOW AZ 85901 USA "
    },
    {
      "Location ID": "85912",
      "Location Name": "WHITE MOUNTAIN LAKE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1785 EDMONDS CIR SHOW LOW AZ 85901 USA "
    },
    {
      "Location ID": "85920",
      "Location Name": "ALPINE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 42631 HWY 191 ALPINE AZ 85920 USA "
    },
    {
      "Location ID": "85925",
      "Location Name": "EAGAR",
      "Time Zone Offset (Hours)": -2,
      "Address": " 113 W CENTRAL AVE EAGAR AZ 85925 USA "
    },
    {
      "Location ID": "85926",
      "Location Name": "FORT APACHE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 105 OFFICERS ROW FORT APACHE AZ 85926 USA "
    },
    {
      "Location ID": "85929",
      "Location Name": "LAKESIDE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1815 W JACKSON LN LAKESIDE AZ 85929 USA "
    },
    {
      "Location ID": "85930",
      "Location Name": "MCNARY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 14 CADY ST MCNARY AZ 85930 USA "
    },
    {
      "Location ID": "85932",
      "Location Name": "NUTRIOSO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1 COUNTY RD 2013 NUTRIOSO AZ 85932 USA "
    },
    {
      "Location ID": "85935",
      "Location Name": "PINETOP",
      "Time Zone Offset (Hours)": -2,
      "Address": " 712 E WHITE MOUNTAIN BLVD PINETOP AZ 85935 USA "
    },
    {
      "Location ID": "85937",
      "Location Name": "SNOWFLAKE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 761 S 1ST ST W SNOWFLAKE AZ 85937 USA "
    },
    {
      "Location ID": "85938",
      "Location Name": "SPRINGERVILLE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5 E MAIN ST SPRINGERVILLE AZ 85938 USA "
    },
    {
      "Location ID": "85939",
      "Location Name": "TAYLOR",
      "Time Zone Offset (Hours)": -2,
      "Address": " 25 S MAIN ST TAYLOR AZ 85939 USA "
    },
    {
      "Location ID": "85940",
      "Location Name": "VERNON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1688 APACHE COUNTY ROAD 3140 VERNON AZ 85940 USA "
    },
    {
      "Location ID": "85941",
      "Location Name": "WHITERIVER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1025 S CHIEF AVE WHITERIVER AZ 85941 USA "
    },
    {
      "Location ID": "85H",
      "Location Name": "WEST VALLEY AZ P&DC",
      "Time Zone Offset (Hours)": -2,
      "Address": " 620 N 47TH AVE PHOENIX AZ 85043 USA "
    },
    {
      "Location ID": "860",
      "Location Name": "FLAGSTAFF",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2400 N POSTAL BLVD FLAGSTAFF AZ 86004 USA "
    },
    {
      "Location ID": "86025",
      "Location Name": "HOLBROOK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 100 W ERIE ST HOLBROOK AZ 86025 USA "
    },
    {
      "Location ID": "86032",
      "Location Name": "JOSEPH CITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4592 W MAIN ST JOSEPH CITY AZ 86032 USA "
    },
    {
      "Location ID": "86047",
      "Location Name": "WINSLOW",
      "Time Zone Offset (Hours)": -2,
      "Address": " 223 N WILLIAMSON AVE WINSLOW AZ 86047 USA "
    },
    {
      "Location ID": "863",
      "Location Name": "PRESCOTT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 442 MILLER VALLEY RD PRESCOTT AZ 86301 USA "
    },
    {
      "Location ID": "86314",
      "Location Name": "PRESCOTT VALLEY ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 6545 E 2ND ST PRESCOTT VALLEY AZ 86314 USA "
    },
    {
      "Location ID": "86320",
      "Location Name": "ASH FORK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 435 PARK AVE ASH FORK AZ 86320 USA "
    },
    {
      "Location ID": "86322",
      "Location Name": "CAMP VERDE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 403 W FINNIE FLAT RD CAMP VERDE AZ 86322 USA "
    },
    {
      "Location ID": "86323",
      "Location Name": "CHINO VALLEY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 725 E ROAD 2 N CHINO VALLEY AZ 86323 USA "
    },
    {
      "Location ID": "86326",
      "Location Name": "COTTONWOOD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 700 E MINGUS AVE COTTONWOOD AZ 86326 USA "
    },
    {
      "Location ID": "86334",
      "Location Name": "PAULDEN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 23375 N STATE ROUTE 89 PAULDEN AZ 86334 USA "
    },
    {
      "Location ID": "86336",
      "Location Name": "SEDONA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 190 W STATE ROUTE 89A SEDONA AZ 86336 USA "
    },
    {
      "Location ID": "86337",
      "Location Name": "SELIGMAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 22801 W OLD HIGHWAY 66 SELIGMAN AZ 86337 USA "
    },
    {
      "Location ID": "86351",
      "Location Name": "SEDONA VILLAGE OF OAK CREEK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 45 W CORTEZ DR SEDONA AZ 86351 USA "
    },
    {
      "Location ID": "864",
      "Location Name": "KINGMAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1901 JOHNSON AVE KINGMAN AZ 86401 USA "
    },
    {
      "Location ID": "86436",
      "Location Name": "TOPOCK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 12936 S OATMAN HWY TOPOCK AZ 86436 USA "
    },
    {
      "Location ID": "86442",
      "Location Name": "BULLHEAD CITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1882 LAKESIDE DR BULLHEAD CITY AZ 86442 USA "
    },
    {
      "Location ID": "86503",
      "Location Name": "CHINLE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 500 HIGHWAY 191 CHINLE AZ 86503-1799 USA "
    },
    {
      "Location ID": "86505",
      "Location Name": "GANADO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 191 STATE HIGHWAY 264 GANADO AZ 86505-9998 USA "
    },
    {
      "Location ID": "86510",
      "Location Name": "PINON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 500 STATE ROAD 4 PINON AZ 86510-1700 USA "
    },
    {
      "Location ID": "86511",
      "Location Name": "SAINT MICHAELS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 343 HIGHWAY 264 SAINT MICHAELS AZ 86511-9998 USA "
    },
    {
      "Location ID": "86515",
      "Location Name": "WINDOW ROCK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 264 WINDOW ROCK LOOP RD WINDOW ROCK AZ 86515-9998 USA "
    },
    {
      "Location ID": "86520",
      "Location Name": "CPU BLUE GAP",
      "Time Zone Offset (Hours)": -2,
      "Address": " 350 STATE ROAD 4 BLUE GAP AZ 86520-1700 USA "
    },
    {
      "Location ID": "86545",
      "Location Name": "CPU ROCK POINT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 400 HIGHWAY 191 ROCK POINT AZ 86545-1700 USA "
    },
    {
      "Location ID": "870",
      "Location Name": "ALBUQUERQUE P&DC",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1135 BROADWAY BLVD NE ALBUQUERQUE NM 87101 USA "
    },
    {
      "Location ID": "87002",
      "Location Name": "BELEN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 400 N MAIN ST BELEN NM 87002 USA "
    },
    {
      "Location ID": "87004",
      "Location Name": "BERNALILLO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 145 CALLE DEL PRESIDENTE BERNALILLO NM 87004 USA "
    },
    {
      "Location ID": "87006",
      "Location Name": "BOSQUE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1002 S HIGHWAY 116 BOSQUE NM 87006 USA "
    },
    {
      "Location ID": "87007",
      "Location Name": "CASA BLANCA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 182 CASA BLANCA RD CASA BLANCA NM 87007 USA "
    },
    {
      "Location ID": "87008",
      "Location Name": "CEDAR CREST",
      "Time Zone Offset (Hours)": -2,
      "Address": " 12218 STATE HIGHWAY 14 N CEDAR CREST NM 87008 USA "
    },
    {
      "Location ID": "87010",
      "Location Name": "CERRILLOS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1 MAIN ST CERRILLOS NM 87010 USA "
    },
    {
      "Location ID": "87013",
      "Location Name": "CUBA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 6358 MAIN ST CUBA NM 87013 USA "
    },
    {
      "Location ID": "87014",
      "Location Name": "CUBERO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 6 CAMINO REAL RD CUBERO NM 87014 USA "
    },
    {
      "Location ID": "87015",
      "Location Name": "EDGEWOOD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 13 PLAZA LOOP EDGEWOOD NM 87015 USA "
    },
    {
      "Location ID": "87016",
      "Location Name": "ESTANCIA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 413 E HIGHLAND AVE ESTANCIA NM 87016 USA "
    },
    {
      "Location ID": "87020",
      "Location Name": "GRANTS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 816 W SANTA FE AVE GRANTS NM 87020 USA "
    },
    {
      "Location ID": "87021",
      "Location Name": "MILAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 105 AIRPORT RD MILAN NM 87021 USA "
    },
    {
      "Location ID": "87022",
      "Location Name": "ISLETA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 119 TRIBAL ROAD 40 ISLETA NM 87022 USA "
    },
    {
      "Location ID": "87023",
      "Location Name": "JARALES",
      "Time Zone Offset (Hours)": -2,
      "Address": " 483 HIGHWAY 109 JARALES NM 87023 USA "
    },
    {
      "Location ID": "87026",
      "Location Name": "LAGUNA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 6 RIO SAN JOSE RD LAGUNA NM 87026 USA "
    },
    {
      "Location ID": "87031",
      "Location Name": "LOS LUNAS MPO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 100 CARSON DR SE LOS LUNAS NM 87031 USA "
    },
    {
      "Location ID": "87032",
      "Location Name": "MCINTOSH",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1820 HIGHWAY 41 MCINTOSH NM 87032 USA "
    },
    {
      "Location ID": "87035",
      "Location Name": "MORIARTY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 514 ROUTE 66 MORIARTY NM 87035 USA "
    },
    {
      "Location ID": "87036",
      "Location Name": "MOUNTAINAIR",
      "Time Zone Offset (Hours)": -2,
      "Address": " 221 W BROADWAY MOUNTAINAIR NM 87036 USA "
    },
    {
      "Location ID": "87038",
      "Location Name": "NEW LAGUNA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 22 STATE ROAD 124 NEW LAGUNA NM 87038 USA "
    },
    {
      "Location ID": "87042",
      "Location Name": "PERALTA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2470 BOSQUE FARMS BLVD PERALTA NM 87042 USA "
    },
    {
      "Location ID": "87043",
      "Location Name": "PLACITAS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 652 STATE HIGHWAY 165 PLACITAS NM 87043 USA "
    },
    {
      "Location ID": "87047",
      "Location Name": "SANDIA PARK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 16 FROST RD SANDIA PARK NM 87047 USA "
    },
    {
      "Location ID": "87048",
      "Location Name": "CORRALES",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4101 CORRALES RD CORRALES NM 87048 USA "
    },
    {
      "Location ID": "87049",
      "Location Name": "SAN FIDEL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1094 STATE HIGHWAY 124 SAN FIDEL NM 87049 USA "
    },
    {
      "Location ID": "87053",
      "Location Name": "SAN YSIDRO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 90 BOBBY GARCIA LN SAN YSIDRO NM 87053 USA "
    },
    {
      "Location ID": "87059",
      "Location Name": "TIJERAS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 499 NEW MEXICO 333 TIJERAS NM 87059 USA "
    },
    {
      "Location ID": "87060",
      "Location Name": "TOME",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2971 STATE HIGHWAY 47 TOME NM 87060 USA "
    },
    {
      "Location ID": "87063",
      "Location Name": "WILLARD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 712 N DUNLAVY AVE WILLARD NM 87063 USA "
    },
    {
      "Location ID": "87070",
      "Location Name": "CLINES CORNERS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1 YACHT CLUB DR CLINES CORNERS NM 87070-9998 USA "
    },
    {
      "Location ID": "870LL",
      "Location Name": "LOS LUNAS ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 351 CANAL BLVD SW LOS LUNAS NM 87031 USA "
    },
    {
      "Location ID": "870RG",
      "Location Name": "RIO GRANDE MFG",
      "Time Zone Offset (Hours)": -2,
      "Address": " 7500 BLUEWATER  RD NW ALBUQUERQUE NM 87121 USA "
    },
    {
      "Location ID": "870WM",
      "Location Name": "WALMART",
      "Time Zone Offset (Hours)": -2,
      "Address": " 460 HIGHWAY 528 BERNALILLO NM 87004 USA "
    },
    {
      "Location ID": "871",
      "Location Name": "ALBUQUERQUE NM ASF",
      "Time Zone Offset (Hours)": -2,
      "Address": " 7601 BLUEWATER RD NW STE A ALBUQUERQUE NM 87121 USA "
    },
    {
      "Location ID": "87102",
      "Location Name": "MAIN OFFICE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1135 BROADWAY BLVD NE BLDG C ALBUQUERQUE NM 87101 USA "
    },
    {
      "Location ID": "87103",
      "Location Name": "DOWNTOWN ALBUQUERQUE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 201 5TH ST SW ALBUQUERQUE NM 87102 USA "
    },
    {
      "Location ID": "87104",
      "Location Name": "OLD ALBUQUERQUE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2016 CENTRAL AVE SW ALBUQUERQUE NM 87104-9998 USA "
    },
    {
      "Location ID": "87105",
      "Location Name": "FIVE POINTS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1050 SUNSET RD SW ALBUQUERQUE NM 87105 USA "
    },
    {
      "Location ID": "87107",
      "Location Name": "NORTH VALLEY CARRIER ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 110 MONTANO RD NE ALBUQUERQUE NM 87107 USA "
    },
    {
      "Location ID": "87108",
      "Location Name": "HIGHLAND",
      "Time Zone Offset (Hours)": -2,
      "Address": " 111 ALVARADO DR SE ALBUQUERQUE NM 87108 USA "
    },
    {
      "Location ID": "87109",
      "Location Name": "ACADEMY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 6255 SAN ANTONIO DR NE ALBUQUERQUE NM 87109 USA "
    },
    {
      "Location ID": "87110",
      "Location Name": "UPTOWN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2505 GRACELAND DR NE ALBUQUERQUE NM 87110-9998 USA "
    },
    {
      "Location ID": "87111",
      "Location Name": "STEVE SCHIFF",
      "Time Zone Offset (Hours)": -2,
      "Address": " 9719 CANDELARIA RD NE ALBUQUERQUE NM 87111 USA "
    },
    {
      "Location ID": "87112",
      "Location Name": "MANZANO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 11600 HAINES AVE NE ALBUQUERQUE NM 87112 USA "
    },
    {
      "Location ID": "87114",
      "Location Name": "RICHARD J PINO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4600 PARADISE BLVD NW ALBUQUERQUE NM 87114 USA "
    },
    {
      "Location ID": "87116",
      "Location Name": "KIRTLAND AFB",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1400 1ST ST SE ALBUQUERQUE NM 87116 USA "
    },
    {
      "Location ID": "87119",
      "Location Name": "AIRPORT MAIL FACILITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2100 GEORGE RD SE ALBUQUERQUE NM 87106 USA "
    },
    {
      "Location ID": "87120",
      "Location Name": "NORTH VALLEY FINANCE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 424 VERANDA RD NW ALBUQUERQUE NM 87107 USA "
    },
    {
      "Location ID": "87123",
      "Location Name": "FOOTHILLS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 13101 LOMAS BLVD NE ALBUQUERQUE NM 87123 USA "
    },
    {
      "Location ID": "87124",
      "Location Name": "RIO RANCHO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 900 PINETREE RD SE RIO RANCHO NM 87124 USA "
    },
    {
      "Location ID": "87184",
      "Location Name": "ALAMEDA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 9132 4TH ST NW ALBUQUERQUE NM 87114 USA "
    },
    {
      "Location ID": "87190",
      "Location Name": "UPTOWN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2505 GRACELAND DR NE ALBUQUERQUE NM 87110 USA "
    },
    {
      "Location ID": "87194",
      "Location Name": "OLD ALBUQUERQUE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2016 CENTRAL AVE SW ALBUQUERQUE NM 87104 USA "
    },
    {
      "Location ID": "87196",
      "Location Name": "UNIVERSITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 115 CORNELL DR SE ALBUQUERQUE NM 87106 USA "
    },
    {
      "Location ID": "871CW",
      "Location Name": "COTTONWOOD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 10701 CORRALES RD NW STE 19A ALBUQUERQUE NM 87114-9996 USA "
    },
    {
      "Location ID": "873",
      "Location Name": "GALLUP",
      "Time Zone Offset (Hours)": -2,
      "Address": " 500 S SECOND ST GALLUP NM 87301 USA "
    },
    {
      "Location ID": "87301",
      "Location Name": "GALLUP",
      "Time Zone Offset (Hours)": -2,
      "Address": " 500 S SECOND ST GALLUP NM 87301 USA "
    },
    {
      "Location ID": "873AA",
      "Location Name": "GALLUP",
      "Time Zone Offset (Hours)": -2,
      "Address": " 950 W AZTEC AVE GALLUP NM 87301-9703 USA "
    },
    {
      "Location ID": "874",
      "Location Name": "FARMINGTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2301 E 20TH ST FARMINGTON NM 87401 USA "
    },
    {
      "Location ID": "87401",
      "Location Name": "FARMINGTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2301 E 20TH ST FARMINGTON NM 87401-9998 USA "
    },
    {
      "Location ID": "87410",
      "Location Name": "AZTEC",
      "Time Zone Offset (Hours)": -2,
      "Address": " 601 S RIO GRANDE AVE AZTEC NM 87410 USA "
    },
    {
      "Location ID": "87413",
      "Location Name": "BLOOMFIELD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1108 W BROADWAY AVE BLOOMFIELD NM 87413 USA "
    },
    {
      "Location ID": "87415",
      "Location Name": "FLORA VISTA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 810 NM 516 FLORA VISTA NM 87415 USA "
    },
    {
      "Location ID": "87416",
      "Location Name": "FRUITLAND",
      "Time Zone Offset (Hours)": -2,
      "Address": " 97 ROAD 6675 FRUITLAND NM 87416 USA "
    },
    {
      "Location ID": "87417",
      "Location Name": "KIRTLAND",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4211 US 64 KIRTLAND NM 87417 USA "
    },
    {
      "Location ID": "87420",
      "Location Name": "SHIPROCK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 91 HIGHWAY 491 S SHIPROCK NM 87420 USA "
    },
    {
      "Location ID": "87421",
      "Location Name": "WATERFLOW",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3428 HIGHWAY 64 WATERFLOW NM 87421 USA "
    },
    {
      "Location ID": "875",
      "Location Name": "SANTA FE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 120 S FEDERAL PL STE 101 SANTA FE NM 87501 USA "
    },
    {
      "Location ID": "87501",
      "Location Name": "SANTA FE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 120 S FEDERAL PL STE 101 SANTA FE NM 87501 USA "
    },
    {
      "Location ID": "87502",
      "Location Name": "VILLA LINDA STATION",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4520 CERRILLOS RD STE 1250 SANTA FE NM 87502-9998 USA "
    },
    {
      "Location ID": "87505",
      "Location Name": "CORONADO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2071 S PACHECO ST SANTA FE NM 87505 USA "
    },
    {
      "Location ID": "87510",
      "Location Name": "ABIQUIU",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4 COUNTY ROAD 187 ABIQUIU NM 87510-9998 USA "
    },
    {
      "Location ID": "87515",
      "Location Name": "CANJILON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 385 STATE ROAD 115 CANJILON NM 87515-9998 USA "
    },
    {
      "Location ID": "87517",
      "Location Name": "CARSON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 508 STATE HIGHWAY 567 CARSON NM 87517-9998 USA "
    },
    {
      "Location ID": "87518",
      "Location Name": "CEBOLLA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5A COUNTY ROAD 310 CEBOLLA NM 87518-9998 USA "
    },
    {
      "Location ID": "87520",
      "Location Name": "CHAMA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 199 5TH ST W CHAMA NM 87520-9998 USA "
    },
    {
      "Location ID": "87521",
      "Location Name": "CHAMISAL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2756 STATE ROAD 76 CHAMISAL NM 87521-9998 USA "
    },
    {
      "Location ID": "87522",
      "Location Name": "CHIMAYO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 724 STATE HIGHWAY 76 CHIMAYO NM 87522-9998 USA "
    },
    {
      "Location ID": "87523",
      "Location Name": "CORDOVA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 74 COUNTY ROAD 80 CORDOVA NM 87523-9998 USA "
    },
    {
      "Location ID": "87528",
      "Location Name": "DULCE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 10 FOOTHILLS ST DULCE NM 87528-9998 USA "
    },
    {
      "Location ID": "87529",
      "Location Name": "EL PRADO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1518 PASEO DEL PUEBLO NORTE EL PRADO NM 87529 USA "
    },
    {
      "Location ID": "87532",
      "Location Name": "ESPANOLA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1010 INDUSTRIAL PARK RD ESPANOLA NM 87532 USA "
    },
    {
      "Location ID": "87535",
      "Location Name": "GLORIETA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 16 CALLE LOMITA GLORIETA NM 87535-9998 USA "
    },
    {
      "Location ID": "87538",
      "Location Name": "ILFELD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 400 COUNTY ROAD B43B ILFELD NM 87538-9998 USA "
    },
    {
      "Location ID": "87544",
      "Location Name": "LOS ALAMOS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 199 CENTRAL PARK SQ LOS ALAMOS NM 87544 USA "
    },
    {
      "Location ID": "87548",
      "Location Name": "MEDANALES",
      "Time Zone Offset (Hours)": -2,
      "Address": " 180 COUNTY ROAD 142 MEDANALES NM 87548-9998 USA "
    },
    {
      "Location ID": "87549",
      "Location Name": "OJO CALIENTE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 35286 US HIGHWAY 285 OJO CALIENTE NM 87549-9998 USA "
    },
    {
      "Location ID": "87551",
      "Location Name": "LOS OJOS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 95 STATE ROAD 514 LOS OJOS NM 87551-9997 USA "
    },
    {
      "Location ID": "87552",
      "Location Name": "PECOS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 63 S MAIN ST PECOS NM 87552-9998 USA "
    },
    {
      "Location ID": "87553",
      "Location Name": "PENASCO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 14196 STATE ROAD 75 PENASCO NM 87553-9998 USA "
    },
    {
      "Location ID": "87556",
      "Location Name": "QUESTA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 43 HIGHWAY 38 QUESTA NM 87556-9998 USA "
    },
    {
      "Location ID": "87558",
      "Location Name": "RED RIVER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 120 W MAIN ST RED RIVER NM 87558-9998 USA "
    },
    {
      "Location ID": "87560",
      "Location Name": "RIBERA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 169 STATE HIGHWAY 3 RIBERA NM 87560-9998 USA "
    },
    {
      "Location ID": "87562",
      "Location Name": "ROWE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3198 FRONTAGE RD ROWE NM 87562-9998 USA "
    },
    {
      "Location ID": "87565",
      "Location Name": "SAN JOSE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 16 CAMINO DE LOS PEIRDAS SAN JOSE NM 87565-9998 USA "
    },
    {
      "Location ID": "87567",
      "Location Name": "SANTA CRUZ",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1701 STATE ROAD 76 SANTA CRUZ NM 87567-9998 USA "
    },
    {
      "Location ID": "87569",
      "Location Name": "SERAFINA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 294 COUNTY ROAD B26A SERAFINA NM 87569-9998 USA "
    },
    {
      "Location ID": "87575",
      "Location Name": "TIERRA AMARILLA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 17306 STATE HIGHWAY 84/64 TIERRA AMARILLA NM 87575-9998 USA "
    },
    {
      "Location ID": "87578",
      "Location Name": "TRUCHAS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 10 COUNTY ROAD 75 TRUCHAS NM 87578-9998 USA "
    },
    {
      "Location ID": "87579",
      "Location Name": "VADITO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1709 STATE ROAD 75 VADITO NM 87579-9998 USA "
    },
    {
      "Location ID": "87594",
      "Location Name": "DE VARGAS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 534 N GUADALUPE ST SANTA FE NM 87501-9211 USA "
    },
    {
      "Location ID": "875CA",
      "Location Name": "TAOS ENCHANTMENT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 110 BERTHA RD TAOS NM 87571 USA "
    },
    {
      "Location ID": "875WR",
      "Location Name": "WHITE ROCK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 101 LONGVIEW DR WHITE ROCK NM 87547 USA "
    },
    {
      "Location ID": "877",
      "Location Name": "LAS VEGAS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1001 DOUGLAS AVE LAS VEGAS NM 87701 USA "
    },
    {
      "Location ID": "87701",
      "Location Name": "LAS VEGAS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1001 DOUGLAS AVE LAS VEGAS NM 87701 USA "
    },
    {
      "Location ID": "87728",
      "Location Name": "MAXWELL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 300 MAXWELL AVE MAXWELL NM 87728 USA "
    },
    {
      "Location ID": "87730",
      "Location Name": "MILLS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 100 WILSON AVE MILLS NM 87730-9998 USA "
    },
    {
      "Location ID": "87733",
      "Location Name": "MOSQUERO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 34 MAIN ST MOSQUERO NM 87733-1000 USA "
    },
    {
      "Location ID": "87740",
      "Location Name": "RATON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 245 PARK AVE RATON NM 87740 USA "
    },
    {
      "Location ID": "87743",
      "Location Name": "ROY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 350 RICHELIEU ST ROY NM 87743-9997 USA "
    },
    {
      "Location ID": "87746",
      "Location Name": "SOLANO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2979 HIGHWAY 39 SOLANO NM 87746-9998 USA "
    },
    {
      "Location ID": "87747",
      "Location Name": "SPRINGER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 610 5TH ST SPRINGER NM 87747 USA "
    },
    {
      "Location ID": "87752",
      "Location Name": "WAGON MOUND",
      "Time Zone Offset (Hours)": -2,
      "Address": " 504 RAILROAD AVE WAGON MOUND NM 87752-9998 USA "
    },
    {
      "Location ID": "87753",
      "Location Name": "WATROUS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 233 BRIDGE ST WATROUS NM 87753-1001 USA "
    },
    {
      "Location ID": "878",
      "Location Name": "SOCORRO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 124 PLAZA ST SOCORRO NM 87801 USA "
    },
    {
      "Location ID": "87801",
      "Location Name": "SOCORRO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 124 PLAZA ST SOCORRO NM 87801 USA "
    },
    {
      "Location ID": "87821",
      "Location Name": "DATIL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 101 W HIGHWAY 60 DATIL NM 87821-9998 USA "
    },
    {
      "Location ID": "87825",
      "Location Name": "MAGDALENA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 100 E 1ST ST MAGDALENA NM 87825-9998 USA "
    },
    {
      "Location ID": "87827",
      "Location Name": "PIE TOWN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 17 FRONTAGE RD PIE TOWN NM 87827-9998 USA "
    },
    {
      "Location ID": "87829",
      "Location Name": "QUEMADO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3476 W HIGHWAY 60 QUEMADO NM 87829-9998 USA "
    },
    {
      "Location ID": "879",
      "Location Name": "TRUTH OR CONSEQUENCES",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1507 N DATE ST TRUTH OR CONSEQUENCES NM 87901 USA "
    },
    {
      "Location ID": "87901",
      "Location Name": "TRUTH OR CONSEQUENCES",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1507 N DATE ST TRUTH OR CONSEQUENCES NM 87901 USA "
    },
    {
      "Location ID": "87930",
      "Location Name": "ARREY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 13717 HIGHWAY 187 ARREY NM 87930 USA "
    },
    {
      "Location ID": "87931",
      "Location Name": "CABALLO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 14730 HIGHWAY 187 CABALLO NM 87931 USA "
    },
    {
      "Location ID": "87933",
      "Location Name": "DERRY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 13125 HIGHWAY 187 DERRY NM 87933 USA "
    },
    {
      "Location ID": "87936",
      "Location Name": "GARFIELD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 8960 HIGHWAY 187 GARFIELD NM 87936 USA "
    },
    {
      "Location ID": "87937",
      "Location Name": "HATCH",
      "Time Zone Offset (Hours)": -2,
      "Address": " 107 DAVIS ST HATCH NM 87937 USA "
    },
    {
      "Location ID": "87940",
      "Location Name": "RINCON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2203 RINCON RD RINCON NM 87940 USA "
    },
    {
      "Location ID": "87941",
      "Location Name": "SALEM",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4550 HIGHWAY 187 N SALEM NM 87941 USA "
    },
    {
      "Location ID": "87942",
      "Location Name": "WILLIAMSBURG",
      "Time Zone Offset (Hours)": -2,
      "Address": " 105 CENTRAL ST WILLIAMSBURG NM 87942 USA "
    },
    {
      "Location ID": "880",
      "Location Name": "LAS CRUCES",
      "Time Zone Offset (Hours)": -2,
      "Address": " 201 E LAS CRUCES AVE LAS CRUCES NM 88001-9998 USA "
    },
    {
      "Location ID": "88001",
      "Location Name": "LAS CRUCES",
      "Time Zone Offset (Hours)": -2,
      "Address": " 201 E LAS CRUCES AVE LAS CRUCES NM 88001-9998 USA "
    },
    {
      "Location ID": "88002",
      "Location Name": "WHITE SANDS MISSILE RANGE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 153 JOLLET WHITE SANDS MISSILE RANGE NM 88002 USA "
    },
    {
      "Location ID": "88003",
      "Location Name": "UNIVERSITY PARK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1600 INTERNATIONAL MALL LAS CRUCES NM 88003-1294 USA "
    },
    {
      "Location ID": "88006",
      "Location Name": "NPU",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1206 E IDAHO AVE LAS CRUCES NM 88001-3754 USA "
    },
    {
      "Location ID": "88008",
      "Location Name": "SANTA TERESA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5290 MCNUTT RD SUITE 201 SANTA TERESA NM 88008-9694 USA "
    },
    {
      "Location ID": "88011",
      "Location Name": "COMMERCE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1180 COMMERCE DR LAS CRUCES NM 88011 USA "
    },
    {
      "Location ID": "88013",
      "Location Name": "COMMERCE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1180 COMMERCE DR LAS CRUCES NM 88011-9998 USA "
    },
    {
      "Location ID": "88020",
      "Location Name": "ANIMAS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2415 STATE HIGHWAY 338 ANIMAS NM 88020 USA "
    },
    {
      "Location ID": "88021",
      "Location Name": "ANTHONY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1190 ANTHONY DR ANTHONY NM 88021-9800 USA "
    },
    {
      "Location ID": "88027",
      "Location Name": "CHAMBERINO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 9620 STATE HIGHWAY 28 S CHAMBERINO NM 88027-9800 USA "
    },
    {
      "Location ID": "88030",
      "Location Name": "DEMING",
      "Time Zone Offset (Hours)": -2,
      "Address": " 201 W SPRUCE ST DEMING NM 88030 USA "
    },
    {
      "Location ID": "88032",
      "Location Name": "DONA ANA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5480 LEDESMA DONA ANA NM 88032 USA "
    },
    {
      "Location ID": "88033",
      "Location Name": "FAIRACRES",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3800 W PICACHO AVE FAIRACRES NM 88033-9800 USA "
    },
    {
      "Location ID": "88044",
      "Location Name": "LA MESA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 16140 S HIGHWAY 28 LA MESA NM 88044-9802 USA "
    },
    {
      "Location ID": "88045",
      "Location Name": "LORDSBURG",
      "Time Zone Offset (Hours)": -2,
      "Address": " 401 SHAKESPEARE ST LORDSBURG NM 88045 USA "
    },
    {
      "Location ID": "88046",
      "Location Name": "MESILLA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2253 CALLE DE PARIAN MESILLA NM 88046-9800 USA "
    },
    {
      "Location ID": "88047",
      "Location Name": "MESILLA PARK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 310 E UNION AVE MESILLA PARK NM 88047-9800 USA "
    },
    {
      "Location ID": "88048",
      "Location Name": "MESQUITE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 395 HIGHWAY 228 MESQUITE NM 88048-9800 USA "
    },
    {
      "Location ID": "88052",
      "Location Name": "ORGAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 15770 PADRE LA RUE ST ORGAN NM 88052 USA "
    },
    {
      "Location ID": "88056",
      "Location Name": "RODEO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 16 CUSTIE AVE RODEO NM 88056 USA "
    },
    {
      "Location ID": "88058",
      "Location Name": "SAN MIGUEL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 19003 STATE HIGHWAY 28 SAN MIGUEL NM 88058-9800 USA "
    },
    {
      "Location ID": "88061",
      "Location Name": "SILVER CITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 500 N HUDSON ST SILVER CITY NM 88061-9998 USA "
    },
    {
      "Location ID": "88063",
      "Location Name": "SUNLAND PARK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3500 MCNUTT RD SUNLAND PARK NM 88063-9800 USA "
    },
    {
      "Location ID": "881",
      "Location Name": "CLOVIS NORTHWEST STATION",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1224 W 21ST ST COLVIS NM 88101 USA "
    },
    {
      "Location ID": "88101",
      "Location Name": "CLOVIS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 417 GIDDING ST STE 100 CLOVIS NM 88101 USA "
    },
    {
      "Location ID": "88103",
      "Location Name": "CANNON A F B",
      "Time Zone Offset (Hours)": -2,
      "Address": " 205 AIR COMMANDO WAY CLOVIS NM 88103 USA "
    },
    {
      "Location ID": "88112",
      "Location Name": "BROADVIEW",
      "Time Zone Offset (Hours)": -2,
      "Address": " US POSTAL SERVICE RR 1 BOX 53A BELLVIEW NM 88112 USA "
    },
    {
      "Location ID": "88120",
      "Location Name": "GRADY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 406 W MAIN ST GRADY NM 88120 USA "
    },
    {
      "Location ID": "88130",
      "Location Name": "PORTALES",
      "Time Zone Offset (Hours)": -2,
      "Address": " 116 W 1ST ST PORTALES NM 88130 USA "
    },
    {
      "Location ID": "881PH",
      "Location Name": "PRIVETT HATCHERY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2301 W 18TH ST PORTALES NM 88130 USA "
    },
    {
      "Location ID": "882",
      "Location Name": "ROSWELL ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 497 W BRASHER RD ROSWELL NM 88203 USA "
    },
    {
      "Location ID": "88201",
      "Location Name": "ROSWELL Main",
      "Time Zone Offset (Hours)": -2,
      "Address": " 415 N PENNSYLVANIA AVE ROSWELL NM 88201 USA "
    },
    {
      "Location ID": "88210",
      "Location Name": "ARTESIA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1401 W CENTRE AVE ARTESIA NM 88210 USA "
    },
    {
      "Location ID": "88220",
      "Location Name": "CARLSBAD Main",
      "Time Zone Offset (Hours)": -2,
      "Address": " 301 N CANYON ST CARLSBAD NM 88220 USA "
    },
    {
      "Location ID": "8822A",
      "Location Name": "CARLSBAD ANNEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3200 SAN JOSE BLVD CARLSBAD NM 88220 USA "
    },
    {
      "Location ID": "88230",
      "Location Name": "DEXTER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 302 S LINCOLN AVE DEXTER NM 88230 USA "
    },
    {
      "Location ID": "88231",
      "Location Name": "EUNICE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1201 AVENUE K EUNICE NM 88231 USA "
    },
    {
      "Location ID": "88232",
      "Location Name": "HAGERMAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 108 E ARGYLE ST HAGERMAN NM 88232 USA "
    },
    {
      "Location ID": "88240",
      "Location Name": "HOBBS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2200 N ALTO DR HOBBS NM 88240 USA "
    },
    {
      "Location ID": "88252",
      "Location Name": "JAL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 111 S 4TH ST JAL NM 88252 USA "
    },
    {
      "Location ID": "88253",
      "Location Name": "LAKE ARTHUR",
      "Time Zone Offset (Hours)": -2,
      "Address": " 506 N NEVADA RD LAKE ARTHUR NM 88253 USA "
    },
    {
      "Location ID": "88254",
      "Location Name": "LAKEWOOD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 194 LAKEWOOD RD LAKEWOOD NM 88254 USA "
    },
    {
      "Location ID": "88260",
      "Location Name": "LOVINGTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 203 E AVENUE D LOVINGTON NM 88260 USA "
    },
    {
      "Location ID": "88267",
      "Location Name": "TATUM",
      "Time Zone Offset (Hours)": -2,
      "Address": " 114 S AVENUE A TATUM NM 88267 USA "
    },
    {
      "Location ID": "883",
      "Location Name": "ALAMOGORDO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 930 12TH ST ALAMOGORDO NM 88310-9998 USA "
    },
    {
      "Location ID": "88310",
      "Location Name": "ALAMOGORDO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 930 12TH ST ALAMOGORDO NM 88310 USA "
    },
    {
      "Location ID": "88317",
      "Location Name": "CLOUDCROFT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 20 CURLEW PL CLOUDCROFT NM 88317 USA "
    },
    {
      "Location ID": "88325",
      "Location Name": "HIGH ROLLS MOUNTAIN PARK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 4 CHERRY BLOSSOM HIGH ROLLS MOUNTAIN PARK NM 88325 USA "
    },
    {
      "Location ID": "88337",
      "Location Name": "LA LUZ",
      "Time Zone Offset (Hours)": -2,
      "Address": " 12 MAIN ST LA LUZ NM 88337 USA "
    },
    {
      "Location ID": "88339",
      "Location Name": "MAYHILL",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3495 US HIGHWAY 82 MAYHILL NM 88339 USA "
    },
    {
      "Location ID": "88342",
      "Location Name": "OROGRANDE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1271 MAIN ST OROGRANDE NM 88342-9800 USA "
    },
    {
      "Location ID": "884",
      "Location Name": "TUCUMCARI",
      "Time Zone Offset (Hours)": -2,
      "Address": " 220 S 1ST ST TUCUMCARI NM 88401 USA "
    },
    {
      "Location ID": "88401",
      "Location Name": "TUCUMCARI",
      "Time Zone Offset (Hours)": -2,
      "Address": " 220 S 1ST ST TUCUMCARI NM 88401 USA "
    },
    {
      "Location ID": "88410",
      "Location Name": "AMISTAD",
      "Time Zone Offset (Hours)": -2,
      "Address": " 21 AMISTAD RD AMISTAD NM 88410 USA "
    },
    {
      "Location ID": "88415",
      "Location Name": "CLAYTON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1 WALNUT ST CLAYTON NM 88415 USA "
    },
    {
      "Location ID": "88416",
      "Location Name": "CONCHAS DAM",
      "Time Zone Offset (Hours)": -2,
      "Address": " 101 NICKLE LN CONCHAS DAM NM 88416-9998 USA "
    },
    {
      "Location ID": "88418",
      "Location Name": "DES MOINES",
      "Time Zone Offset (Hours)": -2,
      "Address": " 720 BROADWAY AVE DES MOINES NM 88418-9998 USA "
    },
    {
      "Location ID": "88419",
      "Location Name": "FOLSOM",
      "Time Zone Offset (Hours)": -2,
      "Address": " 53 GRAND AVE FOLSOM NM 88419-9998 USA "
    },
    {
      "Location ID": "88421",
      "Location Name": "GARITA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5120 NM HWY 104 GARITA NM 88421-9998 USA "
    },
    {
      "Location ID": "88424",
      "Location Name": "GRENVILLE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 16 5TH AVE GRENVILLE NM 88424-9998 USA "
    },
    {
      "Location ID": "88426",
      "Location Name": "LOGAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1003 MARTINEZ ST LOGAN NM 88426 USA "
    },
    {
      "Location ID": "88430",
      "Location Name": "NARA VISA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 460 BELL ST NARA VISA NM 88430 USA "
    },
    {
      "Location ID": "88431",
      "Location Name": "NEWKIRK",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1006 EMERALD RD NEWKIRK NM 88431-9998 USA "
    },
    {
      "Location ID": "88434",
      "Location Name": "SAN JON",
      "Time Zone Offset (Hours)": -2,
      "Address": " 402 ROUTE 66 SAN JON NM 88434-9998 USA "
    },
    {
      "Location ID": "88435",
      "Location Name": "SANTA ROSA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 120 S 5TH ST SANTA ROSA NM 88435 USA "
    },
    {
      "Location ID": "88436",
      "Location Name": "SEDAN",
      "Time Zone Offset (Hours)": -2,
      "Address": " 508 CALLIS RD SEDAN NM 88436 USA "
    },
    {
      "Location ID": "88439",
      "Location Name": "TREMENTINA",
      "Time Zone Offset (Hours)": -2,
      "Address": " 26 POST OFFICE LN TREMENTINA NM 88439-9998 USA "
    },
    {
      "Location ID": "890",
      "Location Name": "LAS VEGAS P&DC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1001 E SUNSET RD RM 1054 LAS VEGAS NV 89199 USA "
    },
    {
      "Location ID": "89041",
      "Location Name": "PAHRUMP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2300 POSTAL DR PAHRUMP NV 89048 USA "
    },
    {
      "Location ID": "890AX",
      "Location Name": "LAS VEGAS PEAK ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6650 SPENCER ST STE 100 LAS VEGAS NV 89119 USA "
    },
    {
      "Location ID": "890FZ",
      "Location Name": "LOG LAS VEGAS NV PSA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1720 CHAPARRAL RD HENDERSON NV 89044 USA "
    },
    {
      "Location ID": "890PB",
      "Location Name": "PITNEY BOWES - NORTH LAS VEGAS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4240 N 5TH ST NORTH LAS VEGAS NV 89032 USA "
    },
    {
      "Location ID": "891",
      "Location Name": "LAS VEGAS P&DC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1001 E SUNSET ROAD LAS VEGAS NV 89199 USA "
    },
    {
      "Location ID": "891AN",
      "Location Name": "AMAZON VGT5 - LAS VEGAS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5549 N HOLLYWOOD BLVD LAS VEGAS NV 89115 USA "
    },
    {
      "Location ID": "891AZ",
      "Location Name": "AMAZON LAS5 SORT CENTER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4410 NEXUS WAY LAS VEGAS NV 89115 USA "
    },
    {
      "Location ID": "893LC",
      "Location Name": "LOWDEN COLLECTION BOX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12020 W HIGHWAY 12 TOUCHET WA 99360-9712 USA "
    },
    {
      "Location ID": "894",
      "Location Name": "LOG RENO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2000 VASSAR ST RENO NV 89510 USA "
    },
    {
      "Location ID": "894AX",
      "Location Name": "RENO PEAK ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 385 CABELA DR UNIT 100 RENO NV 89523 USA "
    },
    {
      "Location ID": "894CA",
      "Location Name": "RENO XMAS ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 980 UNITED CIR SPARKS NV 89431 USA "
    },
    {
      "Location ID": "895",
      "Location Name": "RENO P&DC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2000 VASSAR STREET RENO NV 89510 USA "
    },
    {
      "Location ID": "89501",
      "Location Name": "DOWNTOWN RENO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 135 N SIERRA ST STE A1 RENO NV 89501 USA "
    },
    {
      "Location ID": "89503",
      "Location Name": "WASHINGTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1490 STARDUST ST RENO NV 89503 USA "
    },
    {
      "Location ID": "89506",
      "Location Name": "SIERRA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1050 N HILLS BLVD RENO NV 89506 USA "
    },
    {
      "Location ID": "89507",
      "Location Name": "UNIVERSITY STATION",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1674 N. VIRGINIA STREET RENO NV 89507 USA "
    },
    {
      "Location ID": "89511",
      "Location Name": "STEAMBOAT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 75 MCCABE DR RENO NV 89511 USA "
    },
    {
      "Location ID": "89520",
      "Location Name": "GALENA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 299 E PLUMB LN STE 110 RENO NV 89502 USA "
    },
    {
      "Location ID": "89523",
      "Location Name": "PEAVINE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1580 GRAND POINT WAY RENO NV 89523 USA "
    },
    {
      "Location ID": "895AR",
      "Location Name": "ARAMARK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1195 TRADEMARK DR STE 103 RENO NV 89521 USA "
    },
    {
      "Location ID": "895BL",
      "Location Name": "BLOCH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1170 TRADEMARK DR STE 112 RENO NV 89521 USA "
    },
    {
      "Location ID": "895BW",
      "Location Name": "BENDER WAREHOUSE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 205 E PARR BLVD RENO NV 89512 USA "
    },
    {
      "Location ID": "895BX",
      "Location Name": "BENDER WAREHOUSE #2",
      "Time Zone Offset (Hours)": -3,
      "Address": " 345 PARR CIR RENO NV 89512 USA "
    },
    {
      "Location ID": "895MN",
      "Location Name": "MOLTEN USA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1170 TRADEMARK DR STE 109 RENO NV 89521 USA "
    },
    {
      "Location ID": "895PR",
      "Location Name": "PROTHERA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9736 S VIRGINIA ST STE G RENO NV 89511 USA "
    },
    {
      "Location ID": "895UR",
      "Location Name": "UNIVERSITY RENO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1674 N VIRGINIA ST RENO NV 89503 USA "
    },
    {
      "Location ID": "895VA",
      "Location Name": "VA HOSPITAL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 975 KIRMAN AVE RENO NV 89502 USA "
    },
    {
      "Location ID": "898",
      "Location Name": "ELKO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 500 ASPEN WAY ELKO NV 89801 USA "
    },
    {
      "Location ID": "89802",
      "Location Name": "ELKO",
      "Time Zone Offset (Hours)": -3,
      "Address": " US POSTAL SERVICE 2082 IDAHO ST ELKO NV 89802 USA "
    },
    {
      "Location ID": "89803",
      "Location Name": "ELKO MAIN OFFICE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 275 THIRD STREET ELKO NV 89803 USA "
    },
    {
      "Location ID": "89815",
      "Location Name": "SPRING CREEK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 576 SPRING VALLEY CT SPRING CREEK NV 89815 USA "
    },
    {
      "Location ID": "89820",
      "Location Name": "BATTLE MOUNTAIN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 810 SUNSET DR BATTLE MOUNTAIN NV 89820 USA "
    },
    {
      "Location ID": "89822",
      "Location Name": "CARLIN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1106 CHESTNUT ST CARLIN NV 89822 USA "
    },
    {
      "Location ID": "89835",
      "Location Name": "WELLS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 201 CASTLE ST WELLS NV 89835 USA "
    },
    {
      "Location ID": "89883",
      "Location Name": "WEST WENDOVER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 810 ALPINE ST WEST WENDOVER NV 89883 USA "
    },
    {
      "Location ID": "898EA",
      "Location Name": "ASPEN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 500 ASPEN WAY ELKO NV 89801 USA "
    },
    {
      "Location ID": "900",
      "Location Name": "LOS ANGELES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7001 S CENTRAL AVE LOS ANGELES CA 90052 USA "
    },
    {
      "Location ID": "90008",
      "Location Name": "CRENSHAW",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3894 CRENSHAW BLVD LOS ANGELES CA 90008 USA "
    },
    {
      "Location ID": "90012",
      "Location Name": "ALAMEDA CARRIER ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1055 N VIGNES ST LOS ANGELES CA 90012 USA "
    },
    {
      "Location ID": "90016",
      "Location Name": "WEST ADAMS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4960 W WASHINGTON BLVD LOS ANGELES CA 90016 USA "
    },
    {
      "Location ID": "90022",
      "Location Name": "EAST LOS ANGELES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 975 S ATLANTIC BLVD LOS ANGELES CA 90022 USA "
    },
    {
      "Location ID": "90026",
      "Location Name": "EDENDALE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1525 N ALVARADO ST LOS ANGELES CA 90026 USA "
    },
    {
      "Location ID": "90028",
      "Location Name": "HOLLYWOOD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1615 WILCOX AVE LOS ANGELES CA 90028 USA "
    },
    {
      "Location ID": "90039",
      "Location Name": "GRIFFITH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3370 GLENDALE BLVD LOS ANGELES CA 90039 USA "
    },
    {
      "Location ID": "90040",
      "Location Name": "COMMERCE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5670 E WASHINGTON BLVD COMMERCE CA 90040 USA "
    },
    {
      "Location ID": "90041",
      "Location Name": "EAGLE ROCK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7435 N FIGUEROA ST LOS ANGELES CA 90041 USA "
    },
    {
      "Location ID": "90052",
      "Location Name": "LOS ANGELES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7001 S CENTRAL AVE RM 322 LOS ANGELES CA 90052 USA "
    },
    {
      "Location ID": "90058",
      "Location Name": "VERNON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5121 HAMPTON ST VERNON CA 90058 USA "
    },
    {
      "Location ID": "90065",
      "Location Name": "GLASSELL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3950 EAGLE ROCK BLVD LOS ANGELES CA 90065 USA "
    },
    {
      "Location ID": "900AA",
      "Location Name": "AMERICAN AIRLINES BLDG",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5908 AVION DR LOS ANGELES CA 90045 USA "
    },
    {
      "Location ID": "900AG",
      "Location Name": "SUNSET",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1425 N CHEROKEE AVE LOS ANGELES CA 90093 USA "
    },
    {
      "Location ID": "900AN",
      "Location Name": "ALL NIPPON AIRWAYS CARGO FAC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6040 AVION DR LOS ANGELES CA 90045 USA "
    },
    {
      "Location ID": "900CM",
      "Location Name": "SOPRANO WEAR",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2025 LONG BEACH AVE VERNON CA 90058 USA "
    },
    {
      "Location ID": "900DL",
      "Location Name": "DELTA AIR TERMINAL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 11101 AVIATION BLVD LOS ANGELES CA 90045 USA "
    },
    {
      "Location ID": "900DT",
      "Location Name": "TOKI DOKI",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5534 W WASHINGTON BLVD LOS ANGELES CA 90016 USA "
    },
    {
      "Location ID": "900FZ",
      "Location Name": "LOG LOS ANGELES CA PSA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 15927 DISTRIBUTION WAY CERRITOS CA 90703 USA "
    },
    {
      "Location ID": "900GF",
      "Location Name": "LOS ANGELES CA FSS ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 902 E GAGE AVE LOS ANGELES CA 90001 USA "
    },
    {
      "Location ID": "900GL",
      "Location Name": "GLOBAL LOGISTICS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12821 S SPRING STREET GARDENA CA 90061 USA "
    },
    {
      "Location ID": "900LC",
      "Location Name": "LINCOLN HGTS CARRIER ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2425 ALHAMBRA AVE LOS ANGELES CA 90031 USA "
    },
    {
      "Location ID": "900LM",
      "Location Name": "LA MART",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1458 S SAN PEDRO ST UNIT L5902 LOS ANGELES CA 90015 USA "
    },
    {
      "Location ID": "900QU",
      "Location Name": "QUADRIGA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2930 VAIL AVE COMMERCE CA 90040 USA "
    },
    {
      "Location ID": "900SU",
      "Location Name": "SUN COUNTRY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6851 W IMPERIAL HWY LOS ANGELES CA 90045 USA "
    },
    {
      "Location ID": "900SY",
      "Location Name": "APS CARGO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6851 W IMPERIAL HWY LOS ANGELES CA 90045 USA "
    },
    {
      "Location ID": "900UA",
      "Location Name": "UNITED AIRLINES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5932 W CENTURY BLVD LOS ANGELES CA 90045 USA "
    },
    {
      "Location ID": "90241",
      "Location Name": "DOWNEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8111 FIRESTONE BLVD DOWNEY CA 90241 USA "
    },
    {
      "Location ID": "90242",
      "Location Name": "SOUTH DOWNEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7911 IMPERIAL HWY DOWNEY CA 90242 USA "
    },
    {
      "Location ID": "90247",
      "Location Name": "GARDENA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1455 W REDONDO BEACH BLVD GARDENA CA 90247 USA "
    },
    {
      "Location ID": "90250",
      "Location Name": "HAWTHORNE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12700 INGLEWOOD AVE HAWTHORNE CA 90250 USA "
    },
    {
      "Location ID": "90255",
      "Location Name": "HUNTINGTON PARK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6606 SEVILLE AVE HUNTINGTON PARK CA 90255 USA "
    },
    {
      "Location ID": "90260",
      "Location Name": "LAWNDALE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4320 MARINE AVE LAWNDALE CA 90260 USA "
    },
    {
      "Location ID": "90262",
      "Location Name": "LYNWOOD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 11200 LONG BEACH BLVD LYNWOOD CA 90262 USA "
    },
    {
      "Location ID": "90265",
      "Location Name": "COLONY ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 23648 PACIFIC COAST HWY MALIBU CA 90265 USA "
    },
    {
      "Location ID": "90272",
      "Location Name": "PACIFIC PALISADES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 15209 W SUNSET BLVD PACIFIC PALISADES CA 90272 USA "
    },
    {
      "Location ID": "90280",
      "Location Name": "SOUTH GATE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10120 WRIGHT RD SOUTH GATE CA 90280 USA "
    },
    {
      "Location ID": "90290",
      "Location Name": "TOPANGA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 101 S TOPANGA CANYON BLVD TOPANGA CA 90290 USA "
    },
    {
      "Location ID": "902AV",
      "Location Name": "ADVO SYSTEMS INC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1001 W WALNUT ST COMPTON CA 90220 USA "
    },
    {
      "Location ID": "902EB",
      "Location Name": "EAST BIZ",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3501 JACK NORTHROP AVE HAWTHORNE CA 90250 USA "
    },
    {
      "Location ID": "902FS",
      "Location Name": "FIRESTONE STATION",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3270 FIRESTONE BLVD SOUTH GATE CA 90280 USA "
    },
    {
      "Location ID": "902GL",
      "Location Name": "GLOBAL SYNERGY LOGISTICS",
      "Time Zone Offset (Hours)": -3,
      "Address": " GLOBAL SYNERGY LOGISTICS GARDENA CA 90248 USA "
    },
    {
      "Location ID": "902HH",
      "Location Name": "HONEYBEE HEALTH INC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3515 HELMS AVE CULVER CITY CA 90232 USA "
    },
    {
      "Location ID": "902IB",
      "Location Name": "IBC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12441 EUCALYPTUS AVE HAWTHORNE CA 90250 USA "
    },
    {
      "Location ID": "902PD",
      "Location Name": "POINT DUME",
      "Time Zone Offset (Hours)": -3,
      "Address": " 29160 HEATHERCLIFF RD FL 1 MALIBU CA 90265 USA "
    },
    {
      "Location ID": "902PS",
      "Location Name": "PSI GROUP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 18550 S BROADWICK ST COMPTON CA 90220 USA "
    },
    {
      "Location ID": "90301",
      "Location Name": "HILLCREST INGLEWOOD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 300 E HILLCREST BLVD INGLEWOOD CA 90301 USA "
    },
    {
      "Location ID": "903IA",
      "Location Name": "INGLEWOOD-CARRIER ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4201 W IMPERIAL HWY INGELWOOD CA 90304 USA "
    },
    {
      "Location ID": "90402",
      "Location Name": "WILL ROGERS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1217 WILSHIRE BLVD SANTA MONICA CA 90403 USA "
    },
    {
      "Location ID": "90403",
      "Location Name": "SANTA MONICA ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1653 7TH ST SANTA MONICA CA 90401 USA "
    },
    {
      "Location ID": "90406",
      "Location Name": "SANTA MONICA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1248 5TH STREET SANTA MONICA CA 90406 USA "
    },
    {
      "Location ID": "90501",
      "Location Name": "MARCELINA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1433 MARCELINA AVE TORRANCE CA 90501 USA "
    },
    {
      "Location ID": "90503",
      "Location Name": "TORRANCE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2510 MONTEREY ST TORRANCE CA 90503 USA "
    },
    {
      "Location ID": "90504",
      "Location Name": "NORTH TORRANCE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 18080 CRENSHAW BLVD TORRANCE CA 90504 USA "
    },
    {
      "Location ID": "90505",
      "Location Name": "WALTERIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4216 PACIFIC COAST HWY TORRANCE CA 90505 USA "
    },
    {
      "Location ID": "90601",
      "Location Name": "BAILEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6709 WASHINGTON AVE WHITTIER CA 90601 USA "
    },
    {
      "Location ID": "90605",
      "Location Name": "WHITTIER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8520 MICHIGAN AVE WHITTIER CA 90605 USA "
    },
    {
      "Location ID": "90620",
      "Location Name": "BUENA PARK AREA 1",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7377 LA PLAMA AVE BUEANA PARK CA 90622 USA "
    },
    {
      "Location ID": "90622",
      "Location Name": "BUENA PARK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7377 LA PALMA AVE BUENA PARK CA 90622 USA "
    },
    {
      "Location ID": "90630",
      "Location Name": "CYPRESS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5762 LINCOLN AVE CYPRESS CA 90630 USA "
    },
    {
      "Location ID": "90631",
      "Location Name": "LA HABRA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1001 W IMPERIAL HWY LA HABRA CA 90631 USA "
    },
    {
      "Location ID": "90638",
      "Location Name": "LA MIRADA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 14901 ADELFA DR LA MIRADA CA 90638 USA "
    },
    {
      "Location ID": "90680",
      "Location Name": "STANTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10440 BEACH BLVD STANTON CA 90680 USA "
    },
    {
      "Location ID": "906AM",
      "Location Name": "AMAZON LAX5",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6400 VALLEY VIEW ST BUENA PARK CA 90620 USA "
    },
    {
      "Location ID": "906DH",
      "Location Name": "DHL - ECOMMERCE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3963 WORKMAN MILL RD STE A WHITTIER CA 90601 USA "
    },
    {
      "Location ID": "906LM",
      "Location Name": "LA MIRADA PEAK ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 14041 ROSECRANS AVE LA MIRADA CA 90638 USA "
    },
    {
      "Location ID": "906PR",
      "Location Name": "PROSOUND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10743 WALKER ST CYPRESS CA 90630 USA "
    },
    {
      "Location ID": "907",
      "Location Name": "LONG BEACH P&DC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2300 REDONDO AVE LONG BEACH CA 90809 USA "
    },
    {
      "Location ID": "90701",
      "Location Name": "ARTESIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 11721 183RD ST ARTESIA CA 90701 USA "
    },
    {
      "Location ID": "90704",
      "Location Name": "AVALON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 118 METROPOLE ST AVALON CA 90704 USA "
    },
    {
      "Location ID": "90710",
      "Location Name": "HARBOR CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 25690 FRAMPTON AVE HARBOR CITY CA 90710 USA "
    },
    {
      "Location ID": "90714",
      "Location Name": "LAKEWOOD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5200 CLARK AVE LAKEWOOD CA 90714 USA "
    },
    {
      "Location ID": "90717",
      "Location Name": "LOMITA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 25131 NARBONNE AVE LOMITA CA 90717 USA "
    },
    {
      "Location ID": "90720",
      "Location Name": "LOS ALAMITOS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10650 REAGAN ST LOS ALAMITOS CA 90720 USA "
    },
    {
      "Location ID": "90745",
      "Location Name": "CARSON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 21350 AVALON BLVD CARSON CA 90745 USA "
    },
    {
      "Location ID": "907AN",
      "Location Name": "AFFLICTION LLC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1799 APOLLO CT SEAL BEACH CA 90740 USA "
    },
    {
      "Location ID": "907AX",
      "Location Name": "SO CA PEAK ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 24760 MAIN ST CARSON CA 90745 USA "
    },
    {
      "Location ID": "907DI",
      "Location Name": "DECOTEX INC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 508 E E ST C WILMINGTON CA 90744 USA "
    },
    {
      "Location ID": "907MA",
      "Location Name": "MONEY MAILER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10631 HUMBOLT ST LOS ALAMITOS CA 90720 USA "
    },
    {
      "Location ID": "907PA",
      "Location Name": "SOUTHERN CA PEAK ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 24760 MAIN ST CARSON CA 90745 USA "
    },
    {
      "Location ID": "907TO",
      "Location Name": "TREND OFFSET",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3791 CATALINA ST LOS ALAMITOS CA 90720 USA "
    },
    {
      "Location ID": "90809",
      "Location Name": "GMF LONG BEACH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2300 REDONDO AVE SIGNAL HILL CA 90755 USA "
    },
    {
      "Location ID": "908IC",
      "Location Name": "CUSTOMS BORDER PROTECTION",
      "Time Zone Offset (Hours)": -3,
      "Address": " 20700 DENKER AVE SUITE A TORRANCE CA 90501 USA "
    },
    {
      "Location ID": "90IRF",
      "Location Name": "LOG LA CA ISC IRF MPA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10080 INTERNATIONAL RD LOS ANGELES CA 90045 USA "
    },
    {
      "Location ID": "90ISC",
      "Location Name": "LOS ANGELES INT'L SERVICE CENT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 24760 MAIN ST CARSON CA 90745 USA "
    },
    {
      "Location ID": "90Z",
      "Location Name": "LOS ANGELES NDC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5555 BANDINI BLVD BELL GARDENS CA 90201 USA "
    },
    {
      "Location ID": "91001",
      "Location Name": "ALTADENA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2271 LAKE AVE ALTADENA CA 91001 USA "
    },
    {
      "Location ID": "91006",
      "Location Name": "ARCADIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 41 WHEELER AVE ARCADIA CA 91006 USA "
    },
    {
      "Location ID": "91010",
      "Location Name": "DUARTE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1220 HIGHLAND AVE DUARTE CA 91010 USA "
    },
    {
      "Location ID": "91011",
      "Location Name": "LA CANADA FLINTRIDGE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 607 FOOTHILL BLVD LA CANADA FLINTRIDGE CA 91011 USA "
    },
    {
      "Location ID": "91016",
      "Location Name": "MONROVIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 225 S IVY AVE MONROVIA CA 91016 USA "
    },
    {
      "Location ID": "91020",
      "Location Name": "MONTROSE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2100 MONTROSE AVE MONTROSE CA 91020 USA "
    },
    {
      "Location ID": "91024",
      "Location Name": "SIERRA MADRE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 61 S BALDWIN AVE SIERRA MADRE CA 91024 USA "
    },
    {
      "Location ID": "91030",
      "Location Name": "SOUTH PASADENA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1001 FREMONT AVE SOUTH PASADENA CA 91030 USA "
    },
    {
      "Location ID": "91040",
      "Location Name": "SUNLAND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8587 FENWICK ST SUNLAND CA 91040 USA "
    },
    {
      "Location ID": "91042",
      "Location Name": "TUJUNGA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10209 TUJUNGA CANYON BLVD TUJUNGA CA 91042 USA "
    },
    {
      "Location ID": "91046",
      "Location Name": "VERDUGO CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2816 HONOLULU AVE VERDUGO CITY CA 91046 USA "
    },
    {
      "Location ID": "910AP",
      "Location Name": "ARROYO PARKWAY UNLOCAL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 155 E GLENARM ST PASADENA CA 91105 USA "
    },
    {
      "Location ID": "910CL",
      "Location Name": "CASE LOGISTICS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1812 EVERGREEN ST DUARTE CA 91010 USA "
    },
    {
      "Location ID": "910CT",
      "Location Name": "CHANTILLY TREASURES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 718 S PRIMROSE AVE MONROVIA CA 91016 USA "
    },
    {
      "Location ID": "910EF",
      "Location Name": "EFORCITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12336 LOWER AZUSA RD ARCADIA CA 91006 USA "
    },
    {
      "Location ID": "910NA",
      "Location Name": "NORDSTROMS ARCADIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 400 S BALDWIN AVE STE 200 ARCADIA CA 91007 USA "
    },
    {
      "Location ID": "910NR",
      "Location Name": "NORDSTROM RACK PASADENA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3363 FOOTHILL BLVD PASADENA CA 91107 USA "
    },
    {
      "Location ID": "910SS",
      "Location Name": "STEAL STREET",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1075 HAMILTON RD DUARTE CA 91010 USA "
    },
    {
      "Location ID": "910TM",
      "Location Name": "TAI MOUNTAIN CORP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1780 EVERGREEN ST DUARTE CA 91010 USA "
    },
    {
      "Location ID": "911",
      "Location Name": "PASADENA P&DC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 600 LINCOLN AVE PASADENA CA 91109 USA "
    },
    {
      "Location ID": "91101",
      "Location Name": "PLAZA STATION",
      "Time Zone Offset (Hours)": -3,
      "Address": " 281 E COLORADO BLVD PASADENA CA 91101 USA "
    },
    {
      "Location ID": "91103",
      "Location Name": "JACKIE ROBINSON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1355 N MENTOR AVE PASADENA CA 91104 USA "
    },
    {
      "Location ID": "91106",
      "Location Name": "CATALINA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 967 E COLORADO BLVD PASADENA CA 91106 USA "
    },
    {
      "Location ID": "91107",
      "Location Name": "EAST PASADENA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3016 E COLORADO BLVD PASADENA CA 91107 USA "
    },
    {
      "Location ID": "91108",
      "Location Name": "SAN MARINO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2960 HUNTINGTON DRIVE SAN MARINO CA 91108 USA "
    },
    {
      "Location ID": "91109",
      "Location Name": "PASADENA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 600 LINCOLN AVE PASADENA CA 91109 USA "
    },
    {
      "Location ID": "91126",
      "Location Name": "CAL TECH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1200 E CALIFORNIA BLVD PASADENA CA 91125 USA "
    },
    {
      "Location ID": "911AN",
      "Location Name": "PASADENA PROCESSING ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 600 LINCOLN AVE PASADENA CA 91109 USA "
    },
    {
      "Location ID": "911DD",
      "Location Name": "BIO TAB",
      "Time Zone Offset (Hours)": -3,
      "Address": " 401 E HUNTINGTON MONROVIA CA 91016 USA "
    },
    {
      "Location ID": "911HI",
      "Location Name": "HAMILTON INDUSTRIES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1075 HAMILTON RD DUARTE CA 91010 USA "
    },
    {
      "Location ID": "911OS",
      "Location Name": "ORANGEWOOD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 99 W CALIFORNIA BLVD PASADENA CA 91105 USA "
    },
    {
      "Location ID": "91201",
      "Location Name": "GRAND CENTRAL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6444 SAN FERNANDO RD GLENDALE CA 91201 USA "
    },
    {
      "Location ID": "91202",
      "Location Name": "NORTH GLENDALE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1009 N PACIFIC AVE GLENDALE CA 91202 USA "
    },
    {
      "Location ID": "91203",
      "Location Name": "GLENDALE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 313 E BROADWAY GLENDALE CA 91205 USA "
    },
    {
      "Location ID": "91205",
      "Location Name": "TROPICO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 120 E CHEVY CHASE DR GLENDALE CA 91205 USA "
    },
    {
      "Location ID": "91206",
      "Location Name": "VERDUGO VIEJO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 101 N VERDUGO RD GLENDALE CA 91206 USA "
    },
    {
      "Location ID": "91214",
      "Location Name": "LA CRESCENTA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3300 FOOTHILL BLVD LA CRESCENTA CA 91214 USA "
    },
    {
      "Location ID": "912BF",
      "Location Name": "BL FULLFILLMENT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 631 ROBERTA AVE GLENDALE CA 91201 USA "
    },
    {
      "Location ID": "912CN",
      "Location Name": "COAST NATIONAL SALES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6737 SAN FERNANDO RD GLENDALE CA 91201 USA "
    },
    {
      "Location ID": "912EA",
      "Location Name": "EUROPEAN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 815 WESTERN AVE SUITE 2 GLENDALE CA 91201 USA "
    },
    {
      "Location ID": "912GF",
      "Location Name": "GARLIC FARM",
      "Time Zone Offset (Hours)": -3,
      "Address": " 624 RUBERTA AVE GLENDALE CA 91201 USA "
    },
    {
      "Location ID": "912KR",
      "Location Name": "KRISAR",
      "Time Zone Offset (Hours)": -3,
      "Address": " 445 W GARFIELD AVE GLENDALE CA 91204 USA "
    },
    {
      "Location ID": "912ND",
      "Location Name": "NATIONAL DISTRIBUTER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 825 WESTERN AVE STE 12 GLENDALE CA 91201-2376 USA "
    },
    {
      "Location ID": "912NR",
      "Location Name": "NORDSTROM RACK GLENDALE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 227 N GLENDALE AVE GLENDALE CA 91206 USA "
    },
    {
      "Location ID": "912NS",
      "Location Name": "NORDSTROM THE AMERICA AT BRAND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 102 CARUSO AVE GLENDALE CA 91210 USA "
    },
    {
      "Location ID": "912NW",
      "Location Name": "NATIONWIDE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 815 WESTERN #3 GLENDALE CA 91201 USA "
    },
    {
      "Location ID": "913",
      "Location Name": "SANTA CLARITA P&DC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 28201 FRANKLIN PARKWAY SANTA CLARITA CA 91383 USA "
    },
    {
      "Location ID": "91301",
      "Location Name": "AGOURA HILLS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5158 CLARETON DR AGOURA HILLS CA 91301 USA "
    },
    {
      "Location ID": "91303",
      "Location Name": "CHALLENGER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 21801 SHERMAN WAY CANOGA PARK CA 91303 USA "
    },
    {
      "Location ID": "91304",
      "Location Name": "CANOGA PARK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8201 CANOGA AVE CANOGA PARK CA 91304 USA "
    },
    {
      "Location ID": "91306",
      "Location Name": "WINNETKA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7655 WINNETKA AVE WINNETKA CA 91306 USA "
    },
    {
      "Location ID": "91307",
      "Location Name": "WEST HILLS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 23055 SHERMAN WAY WEST HILLS CA 91307 USA "
    },
    {
      "Location ID": "91311",
      "Location Name": "CHATSWORTH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 21606 DEVONSHIRE ST CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "91316",
      "Location Name": "ENCINO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5805 WHITE OAK AVE ENCINO CA 91316 USA "
    },
    {
      "Location ID": "91320",
      "Location Name": "NEWBURY PARK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3401 GRANDE VISTA DR NEWBURY PARK CA 91320 USA "
    },
    {
      "Location ID": "91321",
      "Location Name": "NEWHALL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 24201 THE OLD RD NEWHALL CA 91321 USA "
    },
    {
      "Location ID": "91324",
      "Location Name": "NORTHRIDGE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9534 RESEDA BLVD NORTHRIDGE CA 91324 USA "
    },
    {
      "Location ID": "91326",
      "Location Name": "RANCH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19300 RINALDI ST PORTER RANCH CA 91326 USA "
    },
    {
      "Location ID": "91331",
      "Location Name": "PACOIMA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 13507 VAN NUYS BLVD PACOIMA CA 91331 USA "
    },
    {
      "Location ID": "91335",
      "Location Name": "RESEDA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7320 RESEDA BLVD RESEDA CA 91335 USA "
    },
    {
      "Location ID": "91342",
      "Location Name": "SYLMAR",
      "Time Zone Offset (Hours)": -3,
      "Address": " 13700 FOOTHILL BLVD SYLMAR CA 91342 USA "
    },
    {
      "Location ID": "91343",
      "Location Name": "NORTH HILLS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9134 SEPULVEDA BLVD NORTH HILLS CA 91343 USA "
    },
    {
      "Location ID": "91345",
      "Location Name": "MISSION HILLS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10919 SEPULVEDA BLVD SAN FERNANDO CA 91345 USA "
    },
    {
      "Location ID": "91351",
      "Location Name": "CANYON COUNTRY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 18336 SOLEDAD CANYON RD CANYON COUNTRY CA 91387 USA "
    },
    {
      "Location ID": "91352",
      "Location Name": "SUN VALLEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10946 RATNER ST SUN VALLEY CA 91352 USA "
    },
    {
      "Location ID": "91355",
      "Location Name": "VALENCIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 25842 1/2 TOURNAMENT RD VALENCIA CA 91355 USA "
    },
    {
      "Location ID": "91356",
      "Location Name": "TARZANA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5609 YOLANDA AVE TARZANA CA 91356 USA "
    },
    {
      "Location ID": "91360",
      "Location Name": "CONEJO VALLEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 235 N MOORPARK RD STE A THOUSAND OAKS CA 91360 USA "
    },
    {
      "Location ID": "91362",
      "Location Name": "THOUSAND OAKS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3435 E THOUSAND OAKS BLVD THOUSAND OAKS CA 91362 USA "
    },
    {
      "Location ID": "91364",
      "Location Name": "WOODLAND HILLS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 21200 OXNARD STREET WOODLAND HIILLS CA 91367 USA "
    },
    {
      "Location ID": "91380",
      "Location Name": "SANTA CLARITA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 24355 CREEKSIDE RD SANTA CLARITA CA 91355 USA "
    },
    {
      "Location ID": "913AA",
      "Location Name": "ATI",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8955 FULLBRIGHT CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913AB",
      "Location Name": "ATYPICAL BRANDS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19867 PRAIRIE ST SUITE 300 CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913AF",
      "Location Name": "ALTERN FULFILLMENT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19801 NORDHOFF PL CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913AG",
      "Location Name": "ABV GROUP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19736 DEARBORN ST CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913AI",
      "Location Name": "APPLIANCE PARTS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5916 KESTER AVE VAN NUYS CA 91411 USA "
    },
    {
      "Location ID": "913AM",
      "Location Name": "AMS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 29010 COMMERCE CENTER DR VALENCIA CA 91355 USA "
    },
    {
      "Location ID": "913AP",
      "Location Name": "ALL PRIORITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9901 SAN FERNANDO RD #17 PACOIMA CA 91335 USA "
    },
    {
      "Location ID": "913AZ",
      "Location Name": "AMAZING LOGISTICS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 28290 AVENUE PENN UNIT 102 VALENCIA CA 91355 USA "
    },
    {
      "Location ID": "913BC",
      "Location Name": "BESAME",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10641 GLENOAKS BLVD PACOIMA CA 91331 USA "
    },
    {
      "Location ID": "913BD",
      "Location Name": "BCD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19801 NORDHOFF ST SUITE 105 CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913BF",
      "Location Name": "BLUEMARK FULLFILLMENT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 27909 HANCOCK PARKWAY VALENCIA CA 91355 USA "
    },
    {
      "Location ID": "913BL",
      "Location Name": "BLINKY LIGHTS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10810 CANTARA ST SUN VALLEY CA 91352 USA "
    },
    {
      "Location ID": "913BP",
      "Location Name": "BICYCLE PLACE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3481 OLD CONEJO RD NEWBURY PARK CA 91320 USA "
    },
    {
      "Location ID": "913BR",
      "Location Name": "BRS DIRECT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7301 TOPANGA BLVD SUITE 203 CANOGA PARK CA 91303 USA "
    },
    {
      "Location ID": "913BU",
      "Location Name": "BURT'S",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2333 BORCHARD RD NEWBURY PARK CA 91320 USA "
    },
    {
      "Location ID": "913BW",
      "Location Name": "BLACK & WHITE EAGLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 21350 LASSEN ST CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913CC",
      "Location Name": "CASTAIC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 28201 FRANKLIN PARKWAY SANTA CLARITA CA 91383-9998 USA "
    },
    {
      "Location ID": "913CG",
      "Location Name": "COTA GLOBAL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8944 FULLBRIGHT AVE STE B CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913CI",
      "Location Name": "CANBY INDUSTRIES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6924 CANBY AVENUE RESEDA CA 91335 USA "
    },
    {
      "Location ID": "913CL",
      "Location Name": "CLEAN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 28013 WOODSTOCK CASTAIC CA 91384 USA "
    },
    {
      "Location ID": "913CS",
      "Location Name": "CLASSIC SLEEP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 30851 AGOURA RD STE 202 AGOURA CA 91301 USA "
    },
    {
      "Location ID": "913CT",
      "Location Name": "CALTRIC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12720 WENTWORTH ST ARLETA CA 91331 USA "
    },
    {
      "Location ID": "913CW",
      "Location Name": "CC WELLNESS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 28918 HANCOCK PKWY VALENCIA CA 91355 USA "
    },
    {
      "Location ID": "913CX",
      "Location Name": "CPI LUXURY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10220 NORRIS AVE PACOIMA CA 91331 USA "
    },
    {
      "Location ID": "913DM",
      "Location Name": "DISCOUNT POOL MART",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10363 BALBOA BLVD GRANADA HILLS CA 91344 USA "
    },
    {
      "Location ID": "913DN",
      "Location Name": "DNJ ENGINES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8960 LURLINE AVE CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913DP",
      "Location Name": "DREAM PRODUCTS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 810 LAWRENCE DR STE 128 NEWBURY PARK CA 91320 USA "
    },
    {
      "Location ID": "913DR",
      "Location Name": "DERMATHOLOGIC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8130 REMMET AVE CANOGA PARK CA 91304 USA "
    },
    {
      "Location ID": "913DS",
      "Location Name": "DA SALLAS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10310 NORRIS AVE PACOIMA CA 91331 USA "
    },
    {
      "Location ID": "913DY",
      "Location Name": "DROP SHIP YOUR",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9842 GLENOAKS BLVD SUN VALLEY CA 91352 USA "
    },
    {
      "Location ID": "913EX",
      "Location Name": "EXHART",
      "Time Zone Offset (Hours)": -3,
      "Address": " 20364 PLUMMER ST CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913FA",
      "Location Name": "FANCY SPRINKLERS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12701 VAN NUYS BLVD SUITE L PACOIMA CA 91331 USA "
    },
    {
      "Location ID": "913FB",
      "Location Name": "FAT BEATS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8920 ETON AVE CANOGA PARK CA 91304 USA "
    },
    {
      "Location ID": "913FE",
      "Location Name": "FACTORY EFFEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 24849 ANZA DR VALENCIA CA 91355 USA "
    },
    {
      "Location ID": "913FS",
      "Location Name": "MCRB",
      "Time Zone Offset (Hours)": -3,
      "Address": " 20660 NORDHOFF ST CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913GC",
      "Location Name": "GRACE COMMUNITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12848 MUSCATINE ST PACOIMA CA 91331 USA "
    },
    {
      "Location ID": "913GF",
      "Location Name": "GIRLFRIENDS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 27963 FRANKLIN PKWY VALENCIA CA 91355 USA "
    },
    {
      "Location ID": "913GH",
      "Location Name": "GALPHIN HONDA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 11151 LAUREL CANYON BLVD SAN FERNANDO CA 91340 USA "
    },
    {
      "Location ID": "913GP",
      "Location Name": "GEM PRODUCTS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9020 ETON AVE CANOGA PARK CA 91304 USA "
    },
    {
      "Location ID": "913HH",
      "Location Name": "HANK AND HARRY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 11663 TUXFORD ST SUN VALLEY CA 91352 USA "
    },
    {
      "Location ID": "913HO",
      "Location Name": "HOT STUFF",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12701 VAN NUYS BLVD SUITE D PACOIMA CA 91331 USA "
    },
    {
      "Location ID": "913HS",
      "Location Name": "HOLLYWOOD STORAGE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3425 OLD CONEJO RD NEWBURY PARK CA 91320 USA "
    },
    {
      "Location ID": "913HU",
      "Location Name": "HUMBLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9400 LURLINE AVE UNIT B CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913IA",
      "Location Name": "ITAP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 22048 SHERMAN WAY #210 CANOGA PARK CA 91303 USA "
    },
    {
      "Location ID": "913IJ",
      "Location Name": "INKJET",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7920 DEERING AVE CANOGA PARK CA 91304 USA "
    },
    {
      "Location ID": "913IK",
      "Location Name": "INK 2000",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19875 NORDHOFF ST NORTHRIDGE CA 91324 USA "
    },
    {
      "Location ID": "913IM",
      "Location Name": "IMC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8901 CANOGA AVE CANOGA PARK CA 91303 USA "
    },
    {
      "Location ID": "913IN",
      "Location Name": "INTEGRA BEAUTY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 20362 PLUMMER ST CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913IR",
      "Location Name": "INK ROCK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5320 DERRY SUITE E AGOURA CA 91301 USA "
    },
    {
      "Location ID": "913IS",
      "Location Name": "I SELL BEAUTY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8385 CANOGA AVE CANOGA PARK CA 91304 USA "
    },
    {
      "Location ID": "913JS",
      "Location Name": "JEFREE STAR",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9440 LURLINE AVE CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913KC",
      "Location Name": "KITCHEN CONCEPTS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8940 QUARTZ AVE NORTHRIDGE CA 91324 USA "
    },
    {
      "Location ID": "913KM",
      "Location Name": "KILLER MERCHANDISE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9400 LURLINE AVE F CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913KS",
      "Location Name": "KEN SPORT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 20500 PLUMMER ST CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913LT",
      "Location Name": "LA TOOLS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12252 DERWENT AVE PORTER RANCH CA 91326 USA "
    },
    {
      "Location ID": "913LY",
      "Location Name": "LYONS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 23742 LYONS AVE NEWHALL CA 91321 USA "
    },
    {
      "Location ID": "913MA",
      "Location Name": "MOCA AUTO PARTS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10010 REMMET AVE CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913MD",
      "Location Name": "MEDALLION",
      "Time Zone Offset (Hours)": -3,
      "Address": " 20675 NORDHOFF ST CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913ME",
      "Location Name": "MELT COSMETICS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9347 ETON AVE CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913MI",
      "Location Name": "MINIMUS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2610 CONEJO SPECTRUM ST NEWBURY PARK CA 91320 USA "
    },
    {
      "Location ID": "913ML",
      "Location Name": "MOULTON LOGISTICS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12236 SHERMAN WAY NORTH HOLLYWOOD CA 91605 USA "
    },
    {
      "Location ID": "913MM",
      "Location Name": "M MAN 3000",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7851 ALABAMA AVENUE CANOGA PARK CA 91304 USA "
    },
    {
      "Location ID": "913MN",
      "Location Name": "MIRACLE NOODLES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12353 FOOTHILL BLVD SYLMAR CA 91342 USA "
    },
    {
      "Location ID": "913MO",
      "Location Name": "MOOSE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9763 TOPANGA CANYON PL CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913MT",
      "Location Name": "MR TORTILLA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1112 ARROYO ST STE 1 SAN FERNANDO CA 91340 USA "
    },
    {
      "Location ID": "913NC",
      "Location Name": "NORDSTROMS CANOGA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 21725 VICTORY BLVD CANOGA PARK CA 91303 USA "
    },
    {
      "Location ID": "913ND",
      "Location Name": "NORDSTROM",
      "Time Zone Offset (Hours)": -3,
      "Address": " 283 HODENCAMP RD THOUSAND OAKS CA 91360 USA "
    },
    {
      "Location ID": "913NS",
      "Location Name": "NATURAL SUCCULENTS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19201 PARTHENIA ST NORTHRIDGE CA 91324 USA "
    },
    {
      "Location ID": "913NT",
      "Location Name": "NUTRITION",
      "Time Zone Offset (Hours)": -3,
      "Address": " 29017 AVENUE PENN VALENCIA CA 91355 USA "
    },
    {
      "Location ID": "913NU",
      "Location Name": "NUME",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19736 DEARBORN ST CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913NW",
      "Location Name": "NAKED WARDROBE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19855 NORDHOFF ST NORTHRIDGE CA 91324 USA "
    },
    {
      "Location ID": "913OS",
      "Location Name": "ORGANIC SOLUTION",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19801 NORDHOFF PLACE STE 107 CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913PL",
      "Location Name": "PHOTO LAB",
      "Time Zone Offset (Hours)": -3,
      "Address": " 28022 INDUSTRY #F VALENCIA CA 91355 USA "
    },
    {
      "Location ID": "913PO",
      "Location Name": "PORTABLE POWER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 628 CELIS ST SAN FERNANDO CA 91340 USA "
    },
    {
      "Location ID": "913PP",
      "Location Name": "MAXWELL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 21604 MARILLA ST CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913PT",
      "Location Name": "PRINTFUL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19749 DEARBORN ST CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913RD",
      "Location Name": "RONCO DRUGS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 18607 VENTURA BLVD TARZANA CA 91356 USA "
    },
    {
      "Location ID": "913RF",
      "Location Name": "RAPID FULLFILLMENT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12924 PIERCE ST PACOIMA CA 91331 USA "
    },
    {
      "Location ID": "913RI",
      "Location Name": "RED LIGHT DISTRICTS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 20339 NORDHOFF ST CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913RO",
      "Location Name": "ROCKY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 21540 PRAIRIE UNIT A CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913RP",
      "Location Name": "RIVERPLATE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8200 REMMET AVE CANOGA PARK CA 91304 USA "
    },
    {
      "Location ID": "913SA",
      "Location Name": "SPEED ADDICTS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 15230 SAN FERNANDO MISSION BLVD MISSION HILLS CA 91345 USA "
    },
    {
      "Location ID": "913SB",
      "Location Name": "SLIME BOX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19849 NORDHOFF ST NORTHRIDGE CA 91324 USA "
    },
    {
      "Location ID": "913SG",
      "Location Name": "SAUGUS DRUGS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 27641 BOUQUET CANYON ROAD SANTA CLARITA CA 91350 USA "
    },
    {
      "Location ID": "913SL",
      "Location Name": "SUNCO LIGHTING",
      "Time Zone Offset (Hours)": -3,
      "Address": " 21101 OSBORNE ST CANOGA PARK CA 91304 USA "
    },
    {
      "Location ID": "913SP",
      "Location Name": "SPONGELLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9673 TOPANGA CANYON PL CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913SR",
      "Location Name": "SNACKS R US",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12701 VAN NUYS BLVD PACOIMA CA 91331 USA "
    },
    {
      "Location ID": "913SS",
      "Location Name": "LA POLICE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 28022 INDUSTRY DR VALENCIA CA 91355 USA "
    },
    {
      "Location ID": "913TD",
      "Location Name": "THE DETOX MARKET",
      "Time Zone Offset (Hours)": -3,
      "Address": " 18121 NAPA ST NORTHRIDGE CA 91325 USA "
    },
    {
      "Location ID": "913TE",
      "Location Name": "TOUCH OF EXCELLENCE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 27621 SKYLARK LN SANTA CLARITA CA 91350 USA "
    },
    {
      "Location ID": "913TN",
      "Location Name": "TNT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6025 YOLANDA AVE TARZANA CA 91356 USA "
    },
    {
      "Location ID": "913UA",
      "Location Name": "UNIFORMS 4 ALL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 20701 NORDHOFF ST CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913VI",
      "Location Name": "VISION IMAGING",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9540 COZYCROFT AVE CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913VM",
      "Location Name": "VISION MEDIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 29125 AVENUE PAINE VALENCIA CA 91355 USA "
    },
    {
      "Location ID": "913WB",
      "Location Name": "WAKE BALLAST",
      "Time Zone Offset (Hours)": -3,
      "Address": " 27351 TRIUMPH AVENUE CANYON COUNTRY CA 91387 USA "
    },
    {
      "Location ID": "913WC",
      "Location Name": "WILDFLOWER CASES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 812 MITCHELL ROAD NEWBURY PARK CA 91320 USA "
    },
    {
      "Location ID": "913WF",
      "Location Name": "WESTLAKE FULLFILLMENT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5310 DERRY AVE # 1 AGOURA HILLS CA 91301 USA "
    },
    {
      "Location ID": "913WO",
      "Location Name": "WORK SOURCE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9207 ETON AVE CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913WP",
      "Location Name": "2 WHEEL PROS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 21033 DEVONSHIRE ST CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913WS",
      "Location Name": "WALKING SHOES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9028 BALBOA BLVD NORTHRIDGE CA 91325 USA "
    },
    {
      "Location ID": "913YC",
      "Location Name": "YOUR CHAIR COVER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9830 GLENOAKS BLVD SUN VALLEY CA 91352 USA "
    },
    {
      "Location ID": "913YL",
      "Location Name": "YOUNG LA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9420 LURELINE UNIT B CHATSWORTH CA 91311 USA "
    },
    {
      "Location ID": "913YY",
      "Location Name": "YOUNG YOU ORGANIC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 20236 RUNNYMEADE ST CANOGA PARK CA 91303 USA "
    },
    {
      "Location ID": "913ZP",
      "Location Name": "ZIP FULLFILLMENT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2535 CONEJO SPECTRUM STREET NEWBURY PARK CA 91320 USA "
    },
    {
      "Location ID": "914",
      "Location Name": "VAN NUYS            CA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 15701 SHERMAN WAY VAN NUYS CA 91400-9998 USA "
    },
    {
      "Location ID": "91401",
      "Location Name": "CIVIC CENTER VAN NUYS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6531 VAN NUYS BLVD VAN NUYS CA 91401 USA "
    },
    {
      "Location ID": "91402",
      "Location Name": "PANORAMA CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 14416 CHASE ST PANORAMA CITY CA 91402 USA "
    },
    {
      "Location ID": "91403",
      "Location Name": "SHERMAN OAKS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 14900 MAGNOLIA BLVD SHERMAN OAKS CA 91403 USA "
    },
    {
      "Location ID": "91406",
      "Location Name": "VAN NUYS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 15701 SHERMAN WAY VAN NUYS CA 91406 USA "
    },
    {
      "Location ID": "91409",
      "Location Name": "VAN NUYS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 15701 SHERMAN WAY VAN NUYS CA 91409 USA "
    },
    {
      "Location ID": "914AD",
      "Location Name": "AMERIDEPOT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7625 HAYVENHURST AVE VAN NUYS CA 91406 USA "
    },
    {
      "Location ID": "914CM",
      "Location Name": "CHEF MERITO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7915 SEPULVEDA VAN NUYS CA 91405 USA "
    },
    {
      "Location ID": "914DS",
      "Location Name": "DB SALES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 14713 CALIFA ST VAN NUYS CA 91411 USA "
    },
    {
      "Location ID": "914EC",
      "Location Name": "ECN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7731 HAYVENHURST AVE VAN NUYS CA 91411 USA "
    },
    {
      "Location ID": "914FS",
      "Location Name": "VAN NUYS FSS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 15701 SHERMAN WAY VAN NUYS CA 91406 USA "
    },
    {
      "Location ID": "914IV",
      "Location Name": "INTERNATIONAL VIDO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7731 HAYVENHURST AVE VAN NUYS CA 91406 USA "
    },
    {
      "Location ID": "914SM",
      "Location Name": "SUPREME MED",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7044 SOPHIA AVE VAN NUYS CA 91406 USA "
    },
    {
      "Location ID": "914SS",
      "Location Name": "STRONG SUPPLEMENT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 15164 STAGG ST VAN NUYS CA 91405 USA "
    },
    {
      "Location ID": "914UI",
      "Location Name": "UNDERSPORT INC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6856 VALJEAN AVE VAN NUYS CA 91406 USA "
    },
    {
      "Location ID": "914UT",
      "Location Name": "UNIQUE TOOLS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7750 DENSMORE AVE VAN NUYS CA 91406 USA "
    },
    {
      "Location ID": "914VB",
      "Location Name": "VG RUSH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8803 AMIGO AVE NORTHRIDGE CA 91325 USA "
    },
    {
      "Location ID": "914WW",
      "Location Name": "WORLDWIDE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7636 BURNET AVE VAN NUYS CA 91411 USA "
    },
    {
      "Location ID": "91505",
      "Location Name": "BURBANK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2140 N HOLLYWOOD WAY BURBANK CA 91505 USA "
    },
    {
      "Location ID": "915BH",
      "Location Name": "BH COSMETICS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8137 LANKERSHIM BLVD NORTH HOLLYWOOD CA 91605 USA "
    },
    {
      "Location ID": "915BL",
      "Location Name": "BLISSY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1835 N KEYSTONE ST BURBANK CA 91504 USA "
    },
    {
      "Location ID": "915DC",
      "Location Name": "DOSE OF COLORS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7545 N SAN FERNANDO RD BURBANK CA 91505 USA "
    },
    {
      "Location ID": "915LL",
      "Location Name": "LAURA LEE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 527 N VICTORY BLVD BURBANK CA 91502 USA "
    },
    {
      "Location ID": "915UN",
      "Location Name": "UNITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7555 SAN FERNANDO RD BURBANK CA 91505 USA "
    },
    {
      "Location ID": "91601",
      "Location Name": "CHANDLER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 11304 CHANDLER BLVD NORTH HOLLYWOOD CA 91601 USA "
    },
    {
      "Location ID": "91602",
      "Location Name": "TOLUCA LAKE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10063 RIVERSIDE DR TOLUCA LAKE CA 91602 USA "
    },
    {
      "Location ID": "91604",
      "Location Name": "STUDIO CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3950 LAUREL CANYON BLVD STUDIO CITY CA 91604 USA "
    },
    {
      "Location ID": "91607",
      "Location Name": "VALLEY VILLAGE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12450 MAGNOLIA BLVD VALLEY VILLAGE CA 91607 USA "
    },
    {
      "Location ID": "91615",
      "Location Name": "NORTH HOLLYWOOD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7035 LAUREL CANYON BLVD NORTH HOLLYWOOD CA 91605 USA "
    },
    {
      "Location ID": "916EC",
      "Location Name": "EXOTIC CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6933 FARMDALE AVE NORTH HOLLYWOOD CA 91605 USA "
    },
    {
      "Location ID": "916LA",
      "Location Name": "LASHIFY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 11437 CHANDLER BLVD NORTH HOLLYWOOD CA 91601 USA "
    },
    {
      "Location ID": "916ME",
      "Location Name": "MAVE ENTERPRISES DBA IT'S DELISH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 11555 CANTARA ST STE B NORTH HOLLYWOOD CA 91605 USA "
    },
    {
      "Location ID": "917",
      "Location Name": "INDUSTRY P&DC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 15421 E GALE AVE CITY OF INDUSTRY CA 91715 USA "
    },
    {
      "Location ID": "91701",
      "Location Name": "ALTA LOMA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6649 AMETHYST AVE RANCHO CUCAMONGA CA 91701 USA "
    },
    {
      "Location ID": "91708",
      "Location Name": "CHINO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5375 WALNUT AVE CHINO CA 91708 USA "
    },
    {
      "Location ID": "91709",
      "Location Name": "CHINO HILLS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 14071 PEYTON DR CHINO HILLS CA 91709 USA "
    },
    {
      "Location ID": "91729",
      "Location Name": "RANCHO CUCAMONGA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10950 ARROW RTE RANCHO CUCAMONGA CA 91729 USA "
    },
    {
      "Location ID": "91730",
      "Location Name": "RANCHO CUCAMONGA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10950 ARROW ROUTE RANCHO CUCAMONG CA 91730 USA "
    },
    {
      "Location ID": "91734",
      "Location Name": "EL MONTE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 11151 VALLEY BLVD EL MONTE CA 91734 USA "
    },
    {
      "Location ID": "91739",
      "Location Name": "ETIWANDA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7615 ETIWANDA AVE RANCHO CUCAMONG CA 91739 USA "
    },
    {
      "Location ID": "91761",
      "Location Name": "ONTARIO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1555 E HOLT BLVD ONTARIO CA 91761 USA "
    },
    {
      "Location ID": "91768",
      "Location Name": "POMONA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 580 W MONTEREY AVE POMONA CA 91768 USA "
    },
    {
      "Location ID": "91769",
      "Location Name": "POMONA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 580 W MONTEREY AVE POMONA CA 91769 USA "
    },
    {
      "Location ID": "917AX",
      "Location Name": "LOG CITY OF INDSUTRY MPA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 321 VINELAND AVENUE BASSETT CA 91746 USA "
    },
    {
      "Location ID": "917AZ",
      "Location Name": "LOG CITY OF INDSUTRY MPA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 321 VINELAND AVENUE BASSETT CA 91746 USA "
    },
    {
      "Location ID": "917CS",
      "Location Name": "NANCY'S HALLMARK CONTRACT STA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9789 BASELINE ROAD RANCHO CUCAMONG CA 91730 USA "
    },
    {
      "Location ID": "917PK",
      "Location Name": "SAN BERNARDINO PEAK ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12087 LANDON DR EASTVALE CA 91752 USA "
    },
    {
      "Location ID": "917UP",
      "Location Name": "ONTARIO THS UPS OFFSITE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1460 S ARCHIBALD AVE ONTARIO CA 91761 USA "
    },
    {
      "Location ID": "918",
      "Location Name": "INDUSTRY P&DC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 15421 GALE AVE INDUSTRY CA 91715 USA "
    },
    {
      "Location ID": "91902",
      "Location Name": "BONITA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5073 CENTRAL AVE BONITA CA 91902 USA "
    },
    {
      "Location ID": "91910",
      "Location Name": "CHULA VISTA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 750 3RD AVE CHULA VISTA CA 91910 USA "
    },
    {
      "Location ID": "91914",
      "Location Name": "EASTLAKE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 830 KUHN DR CHULA VISTA CA 91914 USA "
    },
    {
      "Location ID": "91932",
      "Location Name": "IMPERIAL BEACH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 946 DONAX AVE IMPERIAL BEACH CA 91932 USA "
    },
    {
      "Location ID": "91942",
      "Location Name": "LA MESA ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6055 LAKE MURRAY BLVD LA MESA CA 91942 USA "
    },
    {
      "Location ID": "91950",
      "Location Name": "NATIONAL CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 710 E 16TH ST NATIONAL CITY CA 91950 USA "
    },
    {
      "Location ID": "91977",
      "Location Name": "LA MESA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10174 AUSTIN DR SPRING VALLEY CA 91977 USA "
    },
    {
      "Location ID": "91H",
      "Location Name": "SOUTHERN CA STC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2400 E ARTESIA BLVD LONG BEACH CA 90805 USA "
    },
    {
      "Location ID": "91MTE",
      "Location Name": "CSC - USPS MTESC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 13479 VALLEY BLVD FONTANA CA 92335 USA "
    },
    {
      "Location ID": "920",
      "Location Name": "MARGARET SELLERS P&DC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 11251 RANCHO CARMEL DR SAN DIEGO CA 92199 USA "
    },
    {
      "Location ID": "92003",
      "Location Name": "BONSALL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5521 MISSION RD STE D BONSALL CA 92003 USA "
    },
    {
      "Location ID": "92007",
      "Location Name": "CARDIFF BY THE SEA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 135 LIVERPOOL DR, SUITE A CARDIFF BY THE SEA CA 92007 USA "
    },
    {
      "Location ID": "92008",
      "Location Name": "CARLSBAD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2772 ROOSEVELT ST CARLSBAD CA 92008 USA "
    },
    {
      "Location ID": "92009",
      "Location Name": "LA COSTA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1700 AVIARA PKWY CARLSBAD CA 92011 USA "
    },
    {
      "Location ID": "92014",
      "Location Name": "DEL MAR",
      "Time Zone Offset (Hours)": -3,
      "Address": " 122 15TH ST DEL MAR CA 92014 USA "
    },
    {
      "Location ID": "92020",
      "Location Name": "EL CAJON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 401 W LEXINGTON AVE EL CAJON CA 92020 USA "
    },
    {
      "Location ID": "92021",
      "Location Name": "BOSTONIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 867 N 2ND ST EL CAJON CA 92021 USA "
    },
    {
      "Location ID": "92025",
      "Location Name": "ESCONDIDO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1157 W MISSION AVE ESCONDIDO CA 92025 USA "
    },
    {
      "Location ID": "92027",
      "Location Name": "ORANGE GLEN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1770 E VALLEY PKWY ESCONDIDO CA 92027 USA "
    },
    {
      "Location ID": "92028",
      "Location Name": "FALLBROOK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 747 S MISSION RD FALLBROOK CA 92028 USA "
    },
    {
      "Location ID": "92040",
      "Location Name": "LAKESIDE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12515 WOODSIDE AVE LAKESIDE CA 92040 USA "
    },
    {
      "Location ID": "92054",
      "Location Name": "BROOKS STREET",
      "Time Zone Offset (Hours)": -3,
      "Address": " 211 BROOKS ST OCEANSIDE CA 92054 USA "
    },
    {
      "Location ID": "92055",
      "Location Name": "CAMP PENDLETON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 16840 USPS BLDG 16840 CAMP PENDLETON CA 92055 USA "
    },
    {
      "Location ID": "92056",
      "Location Name": "OCEANSIDE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1895 AVENIDA DEL ORO OCEANSIDE CA 92056 USA "
    },
    {
      "Location ID": "92061",
      "Location Name": "PAUMA VALLEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 16160 HIGHWAY 76 PAUMA VALLEY CA 92061 USA "
    },
    {
      "Location ID": "92064",
      "Location Name": "POWAY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 13308 MIDLAND RD POWAY CA 92064 USA "
    },
    {
      "Location ID": "92065",
      "Location Name": "RAMONA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1444 MAIN ST RAMONA CA 92065 USA "
    },
    {
      "Location ID": "92067",
      "Location Name": "RANCHO SANTA FE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 16956 VIA DE SANTA FE RANCHO SANTA FE CA 92091 USA "
    },
    {
      "Location ID": "92068",
      "Location Name": "SAN LUIS REY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3774 MISSION AVE OCEANSIDE CA 92058 USA "
    },
    {
      "Location ID": "92069",
      "Location Name": "SAN MARCOS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 420 N TWIN OAKS VALLEY RD SAN MARCOS CA 92069 USA "
    },
    {
      "Location ID": "92071",
      "Location Name": "SANTEE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9518 MISSION GORGE RD SANTEE CA 92071 USA "
    },
    {
      "Location ID": "92075",
      "Location Name": "SOLANA BEACH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 153 S SIERRA AVE SOLANA BEACH CA 92075 USA "
    },
    {
      "Location ID": "92082",
      "Location Name": "VALLEY CENTER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 28588 COLE GRADE RD VALLEY CENTER CA 92082 USA "
    },
    {
      "Location ID": "92083",
      "Location Name": "VISTA MAIN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 960 POSTAL WAY VISTA CA 92083 USA "
    },
    {
      "Location ID": "92084",
      "Location Name": "PALA VISTA ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1525 E VISTA WAY VISTA CA 92084 USA "
    },
    {
      "Location ID": "920CA",
      "Location Name": "CARLSBAD TEMPORARY ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 760 SHADOWRIDGE DR VISTA CA 92083-7986 USA "
    },
    {
      "Location ID": "920DX",
      "Location Name": "DEL RAYO PLAZA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 16091 SAN DIEGUITO RD RANCHO SANTA FE CA 92091 USA "
    },
    {
      "Location ID": "920EM",
      "Location Name": "ENCINITAS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1150 GARDEN VIEW RD ENCINITAS CA 92024 USA "
    },
    {
      "Location ID": "920PA",
      "Location Name": "SAN Peak Annex",
      "Time Zone Offset (Hours)": -3,
      "Address": " 760 SHADOWRIDGE DRIVE VISTA CA 92083 USA "
    },
    {
      "Location ID": "920PK",
      "Location Name": "SAN DIEGO PEAK ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2611 BUSINESS PARK DR VISTA CA 92081 USA "
    },
    {
      "Location ID": "92128",
      "Location Name": "RANCHO BERNARDO ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 16960 BERNARDO CENTER DR SAN DIEGO CA 92128 USA "
    },
    {
      "Location ID": "92131",
      "Location Name": "SCRIPPS RANCH ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10060 WILLOW CREEK RD SAN DIEGO CA 92131 USA "
    },
    {
      "Location ID": "92153",
      "Location Name": "OTAY MESA POSTAL STORE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 650 DENNERY RD STE 102 SAN DIEGO CA 92154 USA "
    },
    {
      "Location ID": "92154",
      "Location Name": "OTAY MESA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2960 FERN AVE SAN DIEGO CA 92154 USA "
    },
    {
      "Location ID": "92173",
      "Location Name": "SAN YSIDRO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 440 W SAN YSIDRO BLVD SAN YSIDRO CA 92173 USA "
    },
    {
      "Location ID": "92199",
      "Location Name": "SAN DIEGO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 11251 RANCHO CARMEL DR SAN DIEGO CA 92199 USA "
    },
    {
      "Location ID": "921CF",
      "Location Name": "MIDWAY ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2701 MIDWAY DR SAN DIEGO CA 92110 USA "
    },
    {
      "Location ID": "921UC",
      "Location Name": "LA JOLLA ANNEX UC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5047 SHOREHAM PL SAN DIEGO CA 92122 USA "
    },
    {
      "Location ID": "922",
      "Location Name": "PALM SPRINGS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 333 E AMADO RD PALM SPRINGS CA 92262 USA "
    },
    {
      "Location ID": "92201",
      "Location Name": "INDIO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 45805 FARGO ST INDIO CA 92201 USA "
    },
    {
      "Location ID": "92220",
      "Location Name": "BANNING",
      "Time Zone Offset (Hours)": -3,
      "Address": " 176 N ALESSANDRO ST BANNING CA 92220 USA "
    },
    {
      "Location ID": "92222",
      "Location Name": "BARD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1280 BARD RD BARD CA 92222 USA "
    },
    {
      "Location ID": "92223",
      "Location Name": "BEAUMONT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 950 BEAUMONT AVE BEAUMONT CA 92223 USA "
    },
    {
      "Location ID": "92230",
      "Location Name": "CABAZON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 50360 RAMONA RD CABAZON CA 92230 USA "
    },
    {
      "Location ID": "92231",
      "Location Name": "CALEXICO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1101 OLLIE AVE CALEXICO CA 92231 USA "
    },
    {
      "Location ID": "92234",
      "Location Name": "CATHEDRAL CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 33490 DATE PALM DR CATHEDRAL CITY CA 92234 USA "
    },
    {
      "Location ID": "92236",
      "Location Name": "COACHELLA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1412 6TH ST COACHELLA CA 92236 USA "
    },
    {
      "Location ID": "92240",
      "Location Name": "DESERT HOT SPRINGS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 66311 TWO BUNCH PALMS TRL DESERT HOT SPRINGS CA 92240 USA "
    },
    {
      "Location ID": "92243",
      "Location Name": "EL CENTRO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1598 W MAIN ST EL CENTRO CA 92243 USA "
    },
    {
      "Location ID": "92249",
      "Location Name": "HEBER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 71 E MAIN ST HEBER CA 92249 USA "
    },
    {
      "Location ID": "92250",
      "Location Name": "HOLTVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 520 FERN AVE HOLTVILLE CA 92250 USA "
    },
    {
      "Location ID": "92251",
      "Location Name": "IMPERIAL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 820 N IMPERIAL AVE IMPERIAL CA 92251 USA "
    },
    {
      "Location ID": "92253",
      "Location Name": "COVE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 51321 AVENIDA BERMUDAS LA QUINTA CA 92253 USA "
    },
    {
      "Location ID": "92254",
      "Location Name": "MECCA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 91307 2ND ST MECCA CA 92254 USA "
    },
    {
      "Location ID": "92258",
      "Location Name": "NORTH PALM SPRINGS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 64117 DILLION RD NORTH PALM SPRINGS CA 92258 USA "
    },
    {
      "Location ID": "92259",
      "Location Name": "OCOTILLO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1153 N IMPERIAL HWY OCOTILLO CA 92259 USA "
    },
    {
      "Location ID": "92260",
      "Location Name": "PALM DESERT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 74801 HOVLEY LN E PALM DESERT CA 92260 USA "
    },
    {
      "Location ID": "92262",
      "Location Name": "PALM SPRINGS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 333 N SUNRISE WAY PALM SPRINGS CA 92262 USA "
    },
    {
      "Location ID": "92264",
      "Location Name": "SMOKE TREE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1775 E PALM CANYON DR STE 605 PALM SPRINGS CA 92264 USA "
    },
    {
      "Location ID": "92273",
      "Location Name": "SEELEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1801 W PARK ST SEELEY CA 92273 USA "
    },
    {
      "Location ID": "92274",
      "Location Name": "THERMAL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 87200 AIRPORT BLVD THERMAL CA 92274 USA "
    },
    {
      "Location ID": "92276",
      "Location Name": "THOUSAND PALMS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 72630 RAMON RD THOUSAND PALMS CA 92276 USA "
    },
    {
      "Location ID": "92283",
      "Location Name": "WINTERHAVEN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2127 WINTERHAVEN DR WINTERHAVEN CA 92283 USA "
    },
    {
      "Location ID": "923",
      "Location Name": "SAN BERNARDINO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1900 W REDLANDS BLVD SAN BERNARDINO CA 92403 USA "
    },
    {
      "Location ID": "92301",
      "Location Name": "ADELANTO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 11530 RANCHO RD ADELANTO CA 92301 USA "
    },
    {
      "Location ID": "92307",
      "Location Name": "APPLE VALLEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 22099 US HIGHWAY 18 APPLE VALLEY CA 92307 USA "
    },
    {
      "Location ID": "92311",
      "Location Name": "BARSTOW",
      "Time Zone Offset (Hours)": -3,
      "Address": " 425 S 2ND AVE BARSTOW CA 92311 USA "
    },
    {
      "Location ID": "92312",
      "Location Name": "BARSTOW BOXES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 306 LANKFORD LAKE RD BLDG 306 FORT IRWIN CA 92310 USA "
    },
    {
      "Location ID": "92324",
      "Location Name": "COLTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 265 N 7TH ST COLTON CA 92324 USA "
    },
    {
      "Location ID": "92345",
      "Location Name": "HESPERIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 17240 OLIVE ST HESPERIA CA 92345 USA "
    },
    {
      "Location ID": "92363",
      "Location Name": "NEEDLES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 628 3RD ST NEEDLES CA 92363 USA "
    },
    {
      "Location ID": "92371",
      "Location Name": "PHELAN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9782 CLOVIS RD PHELAN CA 92371 USA "
    },
    {
      "Location ID": "92372",
      "Location Name": "PINON HILLS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10353 OASIS RD PINON HILLS CA 92372 USA "
    },
    {
      "Location ID": "92392",
      "Location Name": "SPRING VALLEY LAKE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 13295 SPRING VALLEY PKWY VICTORVILLE CA 92395 USA "
    },
    {
      "Location ID": "92397",
      "Location Name": "WRIGHTWOOD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1440 STATE HIGHWAY 2 WRIGHTWOOD CA 92397 USA "
    },
    {
      "Location ID": "92399",
      "Location Name": "YUCAIPA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12460 CALIFORNIA ST YUCAIPA CA 92399 USA "
    },
    {
      "Location ID": "923AX",
      "Location Name": "LOG SAN BERNARDINO CA MPA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 27400 5TH ST HIGHLAND CA 92346 USA "
    },
    {
      "Location ID": "923MO",
      "Location Name": "RIALTO ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1630 N RIVERSIDE AVE RIALTO CA 92376 USA "
    },
    {
      "Location ID": "923VP",
      "Location Name": "VICTORY PACKAGING - FONTANA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 13100 LOOP RD FONTANA CA 92337-6905 USA "
    },
    {
      "Location ID": "924AC",
      "Location Name": "AMAZON.COM ONT 5",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2020 E CENTRAL AVE SN BERNRDNO CA 92408 USA "
    },
    {
      "Location ID": "924AZ",
      "Location Name": "AMAZON SNA-7",
      "Time Zone Offset (Hours)": -3,
      "Address": " 555 E ORANGE SHOW RD SN BERNRDNO CA 92408 USA "
    },
    {
      "Location ID": "924JP",
      "Location Name": "JENCO PRODUCTIONS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 401 S J ST SAN BERNARDINO CA 92410 USA "
    },
    {
      "Location ID": "925",
      "Location Name": "RIVERSIDE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3890 ORANGE STREET RIVERSIDE CA 92501 USA "
    },
    {
      "Location ID": "92530",
      "Location Name": "LAKE ELSINORE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 500 W GRAHAM AVE LAKE ELSINORE CA 92530 USA "
    },
    {
      "Location ID": "92531",
      "Location Name": "LAKE ELSINORE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 500 W GRAHAM LAKE ELSINORE CA 92531 USA "
    },
    {
      "Location ID": "92543",
      "Location Name": "HEMET",
      "Time Zone Offset (Hours)": -3,
      "Address": " 324 S STATE ST HEMET CA 92543 USA "
    },
    {
      "Location ID": "92545",
      "Location Name": "MAIN OFFICE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3120 W FLORIDA AVE HEMET CA 92545 USA "
    },
    {
      "Location ID": "92553",
      "Location Name": "MORENO VALLEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 23580 ALESSANDRO BLVD MORENO VALLEY CA 92553 USA "
    },
    {
      "Location ID": "92562",
      "Location Name": "MURRIETA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 24930 WASHINGTON AVE MURRIETA CA 92562 USA "
    },
    {
      "Location ID": "92563",
      "Location Name": "MURRIETA CARRIER ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 39571 LOS ALAMOS RD MURRIETA CA 92563 USA "
    },
    {
      "Location ID": "92564",
      "Location Name": "MURRIETA CARRIER ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 24930 WASHINGTON MURRIETA CA 92564 USA "
    },
    {
      "Location ID": "92570",
      "Location Name": "PERRIS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 778 S REDLANDS AVE PERRIS CA 92570 USA "
    },
    {
      "Location ID": "92583",
      "Location Name": "SAN JACINTO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 315 E ESPLANADE AVE SAN JACINTO CA 92583 USA "
    },
    {
      "Location ID": "92584",
      "Location Name": "SUN CITY CARRIER ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 29700 HAUN RD SUN CITY CA 92586 USA "
    },
    {
      "Location ID": "92585",
      "Location Name": "ROMOLAND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 28480 US HIGHWAY 74 STE C MENIFEE CA 92585 USA "
    },
    {
      "Location ID": "92586",
      "Location Name": "SUN CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 29700 HAUN RD MENIFEE CA 92586 USA "
    },
    {
      "Location ID": "92591",
      "Location Name": "TEMECULA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 30777 RANCHO CALIFORNIA RD TEMECULA CA 92591 USA "
    },
    {
      "Location ID": "92593",
      "Location Name": "TEMECULA FINANCE STATION",
      "Time Zone Offset (Hours)": -3,
      "Address": " 28360 FRONT STREET TEMECULA CA 92523 USA "
    },
    {
      "Location ID": "92595",
      "Location Name": "WILDOMAR",
      "Time Zone Offset (Hours)": -3,
      "Address": " 21392 PALOMAR ST WILDOMAR CA 92595 USA "
    },
    {
      "Location ID": "925IF",
      "Location Name": "IDEAL FULFILLMENT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 28381 VINCENT MORAGA DR TEMECULA CA 92590 USA "
    },
    {
      "Location ID": "925MV",
      "Location Name": "MORENO VALLEY DDC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 23800 CACTUS AVE MORENO VALLEY CA 92553 USA "
    },
    {
      "Location ID": "925PA",
      "Location Name": "SAN BERNARDINO PEAK ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 27400 5TH ST HIGHLAND CA 92346 USA "
    },
    {
      "Location ID": "925ST",
      "Location Name": "SHIPPING TREE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 42301 ZEVO DR STE A TEMECULA CA 92590 USA "
    },
    {
      "Location ID": "926",
      "Location Name": "LOG SANTA ANA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3101 W SUNFLOWER AVE SANTA ANA CA 92799 USA "
    },
    {
      "Location ID": "92602",
      "Location Name": "NORTHWOOD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1 LEAGUE IRVINE CA 92602 USA "
    },
    {
      "Location ID": "92620",
      "Location Name": "NORTHWOOD BOXES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1 LEAGUE IRVINE CA 92620 USA "
    },
    {
      "Location ID": "92629",
      "Location Name": "DANA POINT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 24551 DEL PRADO DANA POINT CA 92629 USA "
    },
    {
      "Location ID": "92674",
      "Location Name": "SAN CLEMENTE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 520 E AVENIDA PICO SAN CLEMENTE CA 92674 USA "
    },
    {
      "Location ID": "92683",
      "Location Name": "WESTMINSTER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 15030 GOLDENWEST CIR WESTMINSTER CA 92685 USA "
    },
    {
      "Location ID": "926FX",
      "Location Name": "926FX FEDEX RAMP - JWA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3000 AIRWAY AVE STE 100 COSTA MESA CA 92626 USA "
    },
    {
      "Location ID": "926RS",
      "Location Name": "RANCHO SAN CLEMENTE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 905 CALLE NEGOCIO SAN CLEMENTE CA 92673 USA "
    },
    {
      "Location ID": "92799",
      "Location Name": "SANTA ANA P&DC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3101 WEST SUNFLOWER SANTA ANA CA 92799 USA "
    },
    {
      "Location ID": "927DD",
      "Location Name": "LOG NORTH GRAND DDC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2201 N GRAND AVE SANTA ANA CA 92711 USA "
    },
    {
      "Location ID": "928",
      "Location Name": "ANAHEIM P&DF",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5335 E LA PALMA AVE ANAHEIM CA 92899 USA "
    },
    {
      "Location ID": "92811",
      "Location Name": "ATWOOD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1679 E ORANGETHORPE AVE ATWOOD CA 92811 USA "
    },
    {
      "Location ID": "92871",
      "Location Name": "PLACENTIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1400 N KRAEMER BLVD PLACENTIA CA 92871 USA "
    },
    {
      "Location ID": "928AA",
      "Location Name": "ADVANCED IMAGE DIRECT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1415 S ACACIA AVE FULLERTON CA 92831 USA "
    },
    {
      "Location ID": "928IN",
      "Location Name": "INFOSEND INC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4240 E LA PALMA AVE ANAHEIM CA 92807 USA "
    },
    {
      "Location ID": "928MV",
      "Location Name": "MERICAL VITA PAK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 231/233 E. BRISTOL LANE ORANGE CA 92865 USA "
    },
    {
      "Location ID": "930",
      "Location Name": "OXNARD P&DF",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2901 CAMINO DEL SOL OXNARD CA 93030 USA "
    },
    {
      "Location ID": "93001",
      "Location Name": "VENTURA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 675 E SANTA CLARA ST VENTURA CA 93001 USA "
    },
    {
      "Location ID": "93003",
      "Location Name": "EAST VENTURA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 41 S WAKE FOREST AVE VENTURA CA 93003 USA "
    },
    {
      "Location ID": "93010",
      "Location Name": "CAMARILLO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2150 PICKWICK DR CAMARILLO CA 93010 USA "
    },
    {
      "Location ID": "93013",
      "Location Name": "CARPINTERIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5425 CARPINTERIA AVE CARPINTERIA CA 93013 USA "
    },
    {
      "Location ID": "93015",
      "Location Name": "FILLMORE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 333 CENTRAL AVE FILLMORE CA 93015 USA "
    },
    {
      "Location ID": "93021",
      "Location Name": "MOORPARK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 100 W HIGH ST MOORPARK CA 93021 USA "
    },
    {
      "Location ID": "93022",
      "Location Name": "OAK VIEW",
      "Time Zone Offset (Hours)": -3,
      "Address": " 360 VENTURA AVE OAK VIEW CA 93022 USA "
    },
    {
      "Location ID": "93023",
      "Location Name": "OJAI",
      "Time Zone Offset (Hours)": -3,
      "Address": " 201 E OJAI AVE OJAI CA 93023 USA "
    },
    {
      "Location ID": "93030",
      "Location Name": "OXNARD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1961 N C ST OXNARD CA 93036 USA "
    },
    {
      "Location ID": "93032",
      "Location Name": "FEDERAL BUILDING OXNARD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 350 S A ST OXNARD CA 93030-5899 USA "
    },
    {
      "Location ID": "93033",
      "Location Name": "SAVIERS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2532 SAVIERS RD OXNARD CA 93000-9990 USA "
    },
    {
      "Location ID": "93040",
      "Location Name": "PIRU",
      "Time Zone Offset (Hours)": -3,
      "Address": " 652 N MAIN ST PIRU CA 93040 USA "
    },
    {
      "Location ID": "93041",
      "Location Name": "PORT HUENEME",
      "Time Zone Offset (Hours)": -3,
      "Address": " 560 E PLEASANT VALLEY PORT HUENEME CA 93041-9990 USA "
    },
    {
      "Location ID": "93060",
      "Location Name": "SANTA PAULA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 111 S MILL ST SANTA PAULA CA 93060 USA "
    },
    {
      "Location ID": "93065",
      "Location Name": "SIMI VALLEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2551 GALENA AVE SIMI VALLEY CA 93065 USA "
    },
    {
      "Location ID": "93067",
      "Location Name": "SUMMERLAND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2245 LILLIE AVE SUMMERLAND CA 93067 USA "
    },
    {
      "Location ID": "930AG",
      "Location Name": "ANS GEAR, CA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 41 MORELAND RD SIMI VALLEY CA 93065 USA "
    },
    {
      "Location ID": "930BH",
      "Location Name": "BELLAMI HAIR, CA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3850 ROYAL AVE STE A SIMI VALLEY CA 93063 USA "
    },
    {
      "Location ID": "930BO",
      "Location Name": "BOKU FOODS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 987 W.OJAI AVE OJAI CA 93023 USA "
    },
    {
      "Location ID": "930CP",
      "Location Name": "COLOUR POP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1450 STELLAR DR OXNARD CA 93033 USA "
    },
    {
      "Location ID": "930CV",
      "Location Name": "COASTAL VINEYARDS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5241 N COMMERCE AVE STE A MOORPARK CA 93021 USA "
    },
    {
      "Location ID": "930DC",
      "Location Name": "DC SOLUTIONS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 310 E EASY STREET SIMI VALLEY CA 93065 USA "
    },
    {
      "Location ID": "930GB",
      "Location Name": "GOODY BOX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2205 1ST ST UNIT 109 SIMI VALLEY CA 93065-1944 USA "
    },
    {
      "Location ID": "930GG",
      "Location Name": "GENERATION GENIUS INC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 520 E EASY ST SIMI VALLEY CA 93065 USA "
    },
    {
      "Location ID": "930MA",
      "Location Name": "MARCHEY INDUSTRY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 754 CALLE PLANO CAMARILLO CA 93012 USA "
    },
    {
      "Location ID": "930MC",
      "Location Name": "MOUNT MCCOY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 225 SIMI VILLAGE DR SIMI VALLEY CA 93065 USA "
    },
    {
      "Location ID": "930RW",
      "Location Name": "RAW WORLD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 460 BRYANT CIRCLE OJAI CA 93023-4220 USA "
    },
    {
      "Location ID": "930SP",
      "Location Name": "SANTA PAULA ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 950 W TELEGRAPH RD SANTA PAULA CA 93060 USA "
    },
    {
      "Location ID": "930TT",
      "Location Name": "TIP TOP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 92 PLEASANT VALLEY RD CAMARILLO CA 93010 USA "
    },
    {
      "Location ID": "930WF",
      "Location Name": "JILLAMY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3200 GOLF COURSE DR STE B VENTURA CA 93003 USA "
    },
    {
      "Location ID": "930YC",
      "Location Name": "YOUNGBLOOD COSMESTICS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4583 ISH DRIVE SIMI VALLEY CA 93063 USA "
    },
    {
      "Location ID": "931",
      "Location Name": "SANTA BARBARA P&DC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 400 STORKE RD GOLETA CA 93199 USA "
    },
    {
      "Location ID": "93101",
      "Location Name": "SANTA BARBARA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 836 ANACAPA ST SANTA BARBARA CA 93102-9998 USA "
    },
    {
      "Location ID": "93102",
      "Location Name": "SANTA BARBARA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 836 ANACAPA ST SANTA BARBARA CA 93102 USA "
    },
    {
      "Location ID": "93105",
      "Location Name": "SAN ROQUE STATION",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3345 STATE ST SANTA BARBARA CA 93105 USA "
    },
    {
      "Location ID": "93108",
      "Location Name": "MONTECITO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1470 E VALLEY RD STE H SANTA BARBARA CA 93108 USA "
    },
    {
      "Location ID": "93111",
      "Location Name": "GOLETA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 130 S PATTERSON AVE SANTA BARBARA CA 93111 USA "
    },
    {
      "Location ID": "931AB1",
      "Location Name": "SANTA BARBARA P&DC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 400 STORKE RD GOLETA CA 93199 USA "
    },
    {
      "Location ID": "931AO",
      "Location Name": "A-OK POWER MOTERS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5777 HOLLISTER AVE GOLETA CA 93117 USA "
    },
    {
      "Location ID": "931B1",
      "Location Name": "SANTA BARBARA P&DC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 400 STORKE RD GOLETA CA 93199 USA "
    },
    {
      "Location ID": "931EB",
      "Location Name": "EAST BEACH CARRIER ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 107 NOPALITOS WAY SANTA BARBARA CA 93103 USA "
    },
    {
      "Location ID": "931LP",
      "Location Name": "LAS POSITAS ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3345 STATE ST SANTA BARBARA CA 93105 USA "
    },
    {
      "Location ID": "932",
      "Location Name": "BAKERSFIELD P&DC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3400 PEGASUS DR BAKERSFIELD CA 93380 USA "
    },
    {
      "Location ID": "93201",
      "Location Name": "ALPAUGH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3896 CENTER AVE ALPAUGH CA 93201 USA "
    },
    {
      "Location ID": "93202",
      "Location Name": "ARMONA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 13992 ADA ST STE 205 ARMONA CA 93202 USA "
    },
    {
      "Location ID": "93203",
      "Location Name": "ARVIN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 140 N HILL ST ARVIN CA 93203 USA "
    },
    {
      "Location ID": "93204",
      "Location Name": "AVENAL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 913 E SAN JOAQUIN ST AVENAL CA 93204 USA "
    },
    {
      "Location ID": "93205",
      "Location Name": "BODFISH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1 MILLER ST BODFISH CA 93205 USA "
    },
    {
      "Location ID": "93206",
      "Location Name": "BUTTONWILLOW",
      "Time Zone Offset (Hours)": -3,
      "Address": " 121 N MAIN ST BUTTONWILLOW CA 93206 USA "
    },
    {
      "Location ID": "93210",
      "Location Name": "COALINGA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 218 W DURIAN AVE COALINGA CA 93210 USA "
    },
    {
      "Location ID": "93212",
      "Location Name": "CORCORAN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 917 CHASE AVE CORCORAN CA 93212 USA "
    },
    {
      "Location ID": "93215",
      "Location Name": "DELANO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1800 GARCES HWY DELANO CA 93215 USA "
    },
    {
      "Location ID": "93218",
      "Location Name": "DUCOR",
      "Time Zone Offset (Hours)": -3,
      "Address": " 23318 AVENUE 56 DUCOR CA 93218 USA "
    },
    {
      "Location ID": "93219",
      "Location Name": "EARLIMART",
      "Time Zone Offset (Hours)": -3,
      "Address": " 300 N CHURCH RD EARLIMART CA 93219 USA "
    },
    {
      "Location ID": "93220",
      "Location Name": "EDISON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12299 EDISON HWY EDISON CA 93220 USA "
    },
    {
      "Location ID": "93221",
      "Location Name": "EXETER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 135 S D ST EXETER CA 93221 USA "
    },
    {
      "Location ID": "93222",
      "Location Name": "PINE MOUNTAIN CLUB",
      "Time Zone Offset (Hours)": -3,
      "Address": " 16321 ASKIN DR PINE MOUNTAIN CLUB CA 93222 USA "
    },
    {
      "Location ID": "93223",
      "Location Name": "FARMERSVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 382 N FARMERSVILLE BLVD FARMERSVILLE CA 93223 USA "
    },
    {
      "Location ID": "93224",
      "Location Name": "FELLOWS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 36 MIDWAY RD FELLOWS CA 93224 USA "
    },
    {
      "Location ID": "93225",
      "Location Name": "FRAZIER PARK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 512 MONTEREY TRL FRAZIER PARK CA 93225 USA "
    },
    {
      "Location ID": "93227",
      "Location Name": "GOSHEN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6901 AVENUE 305 GOSHEN CA 93227 USA "
    },
    {
      "Location ID": "93230",
      "Location Name": "HANFORD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 517 N IRWIN ST HANFORD CA 93230 USA "
    },
    {
      "Location ID": "93234",
      "Location Name": "HURON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 36100 S LASSEN AVE HURON CA 93234 USA "
    },
    {
      "Location ID": "93235",
      "Location Name": "IVANHOE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 32971 ROAD 159 IVANHOE CA 93235 USA "
    },
    {
      "Location ID": "93238",
      "Location Name": "KERNVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 45 BIG BLUE RD KERNVILLE CA 93238 USA "
    },
    {
      "Location ID": "93239",
      "Location Name": "KETTLEMAN CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1000 MILHAM ST KETTLEMAN CITY CA 93239 USA "
    },
    {
      "Location ID": "93240",
      "Location Name": "LAKE ISABELLA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6441 LAKE ISABELLA BLVD LAKE ISABELLA CA 93240 USA "
    },
    {
      "Location ID": "93241",
      "Location Name": "LAMONT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 11940 MAIN ST LAMONT CA 93241 USA "
    },
    {
      "Location ID": "93242",
      "Location Name": "LATON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6245 DE WOODY AVE LATON CA 93242 USA "
    },
    {
      "Location ID": "93243",
      "Location Name": "LEBEC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2132 LEBEC RD LEBEC CA 93243 USA "
    },
    {
      "Location ID": "93245",
      "Location Name": "LEMOORE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 206 C ST LEMOORE CA 93245 USA "
    },
    {
      "Location ID": "93246",
      "Location Name": "LEMOORE NAVAL AIR STATION",
      "Time Zone Offset (Hours)": -3,
      "Address": " 826 HANCOCK CIR LEMOORE CA 93246 USA "
    },
    {
      "Location ID": "93247",
      "Location Name": "LINDSAY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 262 E HONOLULU ST LINDSAY CA 93247 USA "
    },
    {
      "Location ID": "93250",
      "Location Name": "MC FARLAND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 633 2ND ST MC FARLAND CA 93250 USA "
    },
    {
      "Location ID": "93251",
      "Location Name": "MCKITTRICK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 23263 2ND ST MC KITTRICK CA 93251 USA "
    },
    {
      "Location ID": "93255",
      "Location Name": "ONYX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8275 EASY ST ONYX CA 93255 USA "
    },
    {
      "Location ID": "93256",
      "Location Name": "PIXLEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 570 S MAIN ST PIXLEY CA 93256 USA "
    },
    {
      "Location ID": "93257",
      "Location Name": "PORTERVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 65 W MILL AVE PORTERVILLE CA 93257 USA "
    },
    {
      "Location ID": "93261",
      "Location Name": "RICHGROVE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 20920 GROVE DR RICHGROVE CA 93261 USA "
    },
    {
      "Location ID": "93263",
      "Location Name": "SHAFTER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 322 STATE AVE SHAFTER CA 93263 USA "
    },
    {
      "Location ID": "93265",
      "Location Name": "SPRINGVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 35701 HIGHWAY 190 SPRINGVILLE CA 93265 USA "
    },
    {
      "Location ID": "93266",
      "Location Name": "STRATFORD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 20340 MAIN ST STRATFORD CA 93266 USA "
    },
    {
      "Location ID": "93267",
      "Location Name": "STRATHMORE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19708 ORANGE BELT DR STRATHMORE CA 93267 USA "
    },
    {
      "Location ID": "93268",
      "Location Name": "TAFT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 427 NORTH ST TAFT CA 93268 USA "
    },
    {
      "Location ID": "93270",
      "Location Name": "TERRA BELLA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9622 ROAD 236 TERRA BELLA CA 93270 USA "
    },
    {
      "Location ID": "93272",
      "Location Name": "TIPTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 221 N GRAHAM RD TIPTON CA 93272 USA "
    },
    {
      "Location ID": "93274",
      "Location Name": "TULARE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 340 E TULARE AVE TULARE CA 93274 USA "
    },
    {
      "Location ID": "93276",
      "Location Name": "TUPMAN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 337 EMMONS BLVD TUPMAN CA 93276 USA "
    },
    {
      "Location ID": "93277",
      "Location Name": "VISALIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2345 W BEECH AVE VISALIA CA 93277 USA "
    },
    {
      "Location ID": "93280",
      "Location Name": "WASCO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 800 E ST WASCO CA 93280 USA "
    },
    {
      "Location ID": "93283",
      "Location Name": "WELDON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5765 VISTA GRANDE DR WELDON CA 93283 USA "
    },
    {
      "Location ID": "93285",
      "Location Name": "WOFFORD HEIGHTS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6336 WOFFORD BLVD WOFFORD HEIGHTS CA 93285 USA "
    },
    {
      "Location ID": "93292",
      "Location Name": "LOVERS LANE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1230 S LOVERS LN VISALIA CA 93292 USA "
    },
    {
      "Location ID": "932AA",
      "Location Name": "TULARE CARRIER ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 834 INDUSTRIAL AVE TULARE CA 93274 USA "
    },
    {
      "Location ID": "932CO",
      "Location Name": "CALIFORNIA ORANGES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 33100 HWY 190 SPRINGVILLE CA 93265 USA "
    },
    {
      "Location ID": "932DT",
      "Location Name": "DISTINCTIVE TILE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 955 COMMERCE WAY LEMOORE CA 93245 USA "
    },
    {
      "Location ID": "932IS",
      "Location Name": "INSECT LORE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 132 S. BEECH AVENUE SHAFTER CA 93263 USA "
    },
    {
      "Location ID": "932LO",
      "Location Name": "LOVERS LANE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1230  S LOVERS LN VISALIA CA 93292 USA "
    },
    {
      "Location ID": "932OS",
      "Location Name": "OUTDOOR SUPPLY INC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5212 LAKE ISABELLA BLVD LAKE ISABELLA CA 93240 USA "
    },
    {
      "Location ID": "932PD",
      "Location Name": "PRO DOCUMENTS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 90 W. POPLAR AVENUE PORTERVILLE CA 93257 USA "
    },
    {
      "Location ID": "932PR",
      "Location Name": "PORTERVILLE RECORDER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 115 E. OAK STREET PORTERVILLE CA 93257 USA "
    },
    {
      "Location ID": "932SD",
      "Location Name": "STOCKDALE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5501 STOCKDALE HWY BAKERSFIELD CA 93309 USA "
    },
    {
      "Location ID": "932SS",
      "Location Name": "STRATUS STONE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 709 C KRUGER AVENUE HANFORD CA 93230 USA "
    },
    {
      "Location ID": "932VF",
      "Location Name": "VF OUTDOOR",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2525 N PLAZA DR VISALIA CA 93291 USA "
    },
    {
      "Location ID": "932WP",
      "Location Name": "WONDERFUL PISTACHIOS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 13646 HWY 33 LOST HILLS CA 93249 USA "
    },
    {
      "Location ID": "933",
      "Location Name": "BAKERSFIELD P&DC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3400 PEGASUS DR BAKERSFIELD CA 93380 USA "
    },
    {
      "Location ID": "93301",
      "Location Name": "DOWNTOWN BAKERSFIELD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1730 18TH ST BAKERSFIELD CA 93301 USA "
    },
    {
      "Location ID": "93304",
      "Location Name": "SOUTH BAKERSFIELD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3200 LARSON LN BAKERSFIELD CA 93304 USA "
    },
    {
      "Location ID": "93305",
      "Location Name": "EAST BAKERSFIELD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 727 KENTUCKY ST BAKERSFIELD CA 93305 USA "
    },
    {
      "Location ID": "93306",
      "Location Name": "HILLCREST FINANCE UNIT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1436 CRESTMONT DR BAKERSFIELD CA 93306 USA "
    },
    {
      "Location ID": "93307",
      "Location Name": "BRUNDAGE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2525 E BRUNDAGE LN BAKERSFIELD CA 93307 USA "
    },
    {
      "Location ID": "93308",
      "Location Name": "OILDALE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7229 DOLE CT BAKERSFIELD CA 93308 USA "
    },
    {
      "Location ID": "93309",
      "Location Name": "STOCKDALE ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5601 STOCKDALE HWY BAKERSFIELD CA 93309 USA "
    },
    {
      "Location ID": "93311",
      "Location Name": "CAMINO MEDIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10001 CAMINO MEDIA BAKERSFIELD CA 93311 USA "
    },
    {
      "Location ID": "93383",
      "Location Name": "PUMPKIN CENTER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3107 TAFT HWY BAKERSFIELD CA 93313 USA "
    },
    {
      "Location ID": "933CL",
      "Location Name": "KERN COUNTY LIBRARY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1315 TRUXTUN AVENUE BAKERSFIELD CA 93301 USA "
    },
    {
      "Location ID": "933CM",
      "Location Name": "KERN COUNTY MAILROOM",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1501 L STREET BAKERSFIELD CA 93301 USA "
    },
    {
      "Location ID": "933CW",
      "Location Name": "KERN COUNTY WELFARE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 100 E. CALIFORNIA AVENUE BAKERSFIELD CA 93307 USA "
    },
    {
      "Location ID": "933HA",
      "Location Name": "HILLCREST",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5710 AUBURN ST BAKERSFIELD CA 93306 USA "
    },
    {
      "Location ID": "933MS",
      "Location Name": "MINNER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 118 MINNER AVE BAKERSFIELD CA 93308 USA "
    },
    {
      "Location ID": "93401",
      "Location Name": "SAN LUIS OBISPO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1655 DALIDIO DR SAN LUIS OBISPO CA 93401 USA "
    },
    {
      "Location ID": "93402",
      "Location Name": "LOS OSOS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1189 LOS OSOS VALLEY RD LOS OSOS CA 93402 USA "
    },
    {
      "Location ID": "93406",
      "Location Name": "MISSION SAN LUIS OBISPO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 893 MARSH ST SAN LUIS OBISPO CA 93401 USA "
    },
    {
      "Location ID": "93420",
      "Location Name": "ARROYO GRANDE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 160 STATION WAY ARROYO GRANDE CA 93420 USA "
    },
    {
      "Location ID": "93421",
      "Location Name": "CPU HALCYON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 936 S HALCYON RD ARROYO GRANDE CA 93420 USA "
    },
    {
      "Location ID": "93422",
      "Location Name": "ATASCADERO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9800 EL CAMINO REAL ATASCADERO CA 93422 USA "
    },
    {
      "Location ID": "93424",
      "Location Name": "AVILA BEACH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 191 SAN MIGUEL AVILA BEACH CA 93424 USA "
    },
    {
      "Location ID": "93427",
      "Location Name": "BUELLTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 140 W HIGHWAY 246 BUELLTON CA 93427 USA "
    },
    {
      "Location ID": "93428",
      "Location Name": "CAMBRIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4100 BRIDGE ST CAMBRIA CA 93428 USA "
    },
    {
      "Location ID": "93429",
      "Location Name": "CASMALIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3401 POINT SAL RD CASMALIA CA 93429 USA "
    },
    {
      "Location ID": "93430",
      "Location Name": "CAYUCOS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 97 ASH AVE CAYUCOS CA 93430 USA "
    },
    {
      "Location ID": "93433",
      "Location Name": "GROVER BEACH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 917 W GRAND AVE GROVER BEACH CA 93433 USA "
    },
    {
      "Location ID": "93434",
      "Location Name": "GUADALUPE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1030 GUADALUPE ST GUADALUPE CA 93434 USA "
    },
    {
      "Location ID": "93436",
      "Location Name": "LOMPOC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 801 W OCEAN AVE LOMPOC CA 93436 USA "
    },
    {
      "Location ID": "93437",
      "Location Name": "CPU VANDENBERG AFB",
      "Time Zone Offset (Hours)": -3,
      "Address": " 100 COMMUNITY LOOP BLDG 10373 LOMPOC CA 93437 USA "
    },
    {
      "Location ID": "93440",
      "Location Name": "LOS ALAMOS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 497 BELL ST LOS ALAMOS CA 93440 USA "
    },
    {
      "Location ID": "93441",
      "Location Name": "LOS OLIVOS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2880 GRAND AVE STE B LOS OLIVOS CA 93441 USA "
    },
    {
      "Location ID": "93442",
      "Location Name": "MORRO BAY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 898 NAPA AVE MORRO BAY CA 93442 USA "
    },
    {
      "Location ID": "93444",
      "Location Name": "NIPOMO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 630 W TEFFT ST NIPOMO CA 93444 USA "
    },
    {
      "Location ID": "93445",
      "Location Name": "OCEANO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1800 BEACH ST OCEANO CA 93445 USA "
    },
    {
      "Location ID": "93446",
      "Location Name": "PASO ROBLES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 800 6TH ST PASO ROBLES CA 93446 USA "
    },
    {
      "Location ID": "93449",
      "Location Name": "PISMO BEACH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 100 CREST DR PISMO BEACH CA 93449 USA "
    },
    {
      "Location ID": "93452",
      "Location Name": "SAN SIMEON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 444 SLO SAN SIMEON RD SAN SIMEON CA 93452 USA "
    },
    {
      "Location ID": "93453",
      "Location Name": "SANTA MARGARITA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 22360 EL CAMINO REAL SANTA MARGARITA CA 93453 USA "
    },
    {
      "Location ID": "93454",
      "Location Name": "SANTA MARIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 201 E BATTLES RD SANTA MARIA CA 93454 USA "
    },
    {
      "Location ID": "93460",
      "Location Name": "SANTA YNEZ",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3564 SAGUNTO ST SANTA YNEZ CA 93460 USA "
    },
    {
      "Location ID": "93463",
      "Location Name": "SOLVANG",
      "Time Zone Offset (Hours)": -3,
      "Address": " 430 ALISAL RD SOLVANG CA 93463 USA "
    },
    {
      "Location ID": "93465",
      "Location Name": "TEMPLETON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 101 N MAIN ST TEMPLETON CA 93465 USA "
    },
    {
      "Location ID": "934AU",
      "Location Name": "AUSPIENT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1367 FAIRWAY DR SANTA MARIA CA 93455 USA "
    },
    {
      "Location ID": "934HC",
      "Location Name": "HEARST CASTLE (N.O.)",
      "Time Zone Offset (Hours)": -3,
      "Address": " 750 HEARST CASTLE RD SAN SIMEON CA 93452-9741 USA "
    },
    {
      "Location ID": "934SB",
      "Location Name": "SHELL BEACH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1301 SHELL BEACH RD PISMO BEACH CA 93449 USA "
    },
    {
      "Location ID": "934TW",
      "Location Name": "TENNIS WAREHOUSE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 181 SURBURAN RD SAN LUIS OBISPO CA 93401 USA "
    },
    {
      "Location ID": "93501",
      "Location Name": "MOJAVE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2053 BELSHAW ST MOJAVE CA 93501 USA "
    },
    {
      "Location ID": "93505",
      "Location Name": "CALIFORNIA CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 21100 HACIENDA BLVD CALIFORNIA CITY CA 93505 USA "
    },
    {
      "Location ID": "93510",
      "Location Name": "ACTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3632 SMITH AVE ACTON CA 93510 USA "
    },
    {
      "Location ID": "93513",
      "Location Name": "BIG PINE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 140 N MAIN ST BIG PINE CA 93513 USA "
    },
    {
      "Location ID": "93514",
      "Location Name": "BISHOP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 585 W LINE ST BISHOP CA 93514 USA "
    },
    {
      "Location ID": "93516",
      "Location Name": "BORON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 26990 ANDERSON ST BORON CA 93516 USA "
    },
    {
      "Location ID": "93517",
      "Location Name": "BRIDGEPORT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 29 KINGSLEY BRIDGEPORT CA 93517 USA "
    },
    {
      "Location ID": "93518",
      "Location Name": "CALIENTE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 27750 CALIENTE BODFISH RD CALIENTE CA 93518 USA "
    },
    {
      "Location ID": "93519",
      "Location Name": "CANTIL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 28111 JAWBONE CANYON RD MOJAVE CA 93501 USA "
    },
    {
      "Location ID": "93523",
      "Location Name": "EDWARDS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1000 KINCHELOE AVE EDWARDS CA 93523 USA "
    },
    {
      "Location ID": "93526",
      "Location Name": "INDEPENDENCE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 101 S EDWARDS ST INDEPENDENCE CA 93526 USA "
    },
    {
      "Location ID": "93527",
      "Location Name": "INYOKERN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6504 LOCUST AVE INYOKERN CA 93527 USA "
    },
    {
      "Location ID": "93528",
      "Location Name": "JOHANNESBURG",
      "Time Zone Offset (Hours)": -3,
      "Address": " 515 BROADWAY AVE JOHANNESBURG CA 93528 USA "
    },
    {
      "Location ID": "93529",
      "Location Name": "JUNE LAKE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2747 BOULDER DR JUNE LAKE CA 93529 USA "
    },
    {
      "Location ID": "93531",
      "Location Name": "KEENE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 30235 WOODFORD TEHACHAPI RD KEENE CA 93531 USA "
    },
    {
      "Location ID": "93534",
      "Location Name": "LANCASTER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 43824 20TH ST W LANCASTER CA 93534 USA "
    },
    {
      "Location ID": "93541",
      "Location Name": "LEE VINING",
      "Time Zone Offset (Hours)": -3,
      "Address": " 121 LEE VINING AVE LEE VINING CA 93541 USA "
    },
    {
      "Location ID": "93543",
      "Location Name": "LITTLEROCK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7765 PEARLBLOSSOM HWY LITTLEROCK CA 93543 USA "
    },
    {
      "Location ID": "93545",
      "Location Name": "LONE PINE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 121 E BUSH ST LONE PINE CA 93545 USA "
    },
    {
      "Location ID": "93546",
      "Location Name": "MAMMOTH LAKES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3330 MAIN ST MAMMOTH LAKES CA 93546 USA "
    },
    {
      "Location ID": "93549",
      "Location Name": "OLANCHA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 100 S HIGHWAY 395 OLANCHA CA 93549 USA "
    },
    {
      "Location ID": "93550",
      "Location Name": "PALMDALE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 38917 20TH STREET EAST PALMDALE CA 93550 USA "
    },
    {
      "Location ID": "93554",
      "Location Name": "RANDSBURG",
      "Time Zone Offset (Hours)": -3,
      "Address": " 26741 BUTTE AVE RANDSBURG CA 93554 USA "
    },
    {
      "Location ID": "93555",
      "Location Name": "RIDGECREST",
      "Time Zone Offset (Hours)": -3,
      "Address": " 101 E COSO AVE RIDGECREST CA 93555 USA "
    },
    {
      "Location ID": "93560",
      "Location Name": "ROSAMOND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1950 W ROSAMOND BLVD ROSAMOND CA 93560 USA "
    },
    {
      "Location ID": "93561",
      "Location Name": "TEHACHAPI",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1085 VOYAGER DR TEHACHAPI CA 93561 USA "
    },
    {
      "Location ID": "935AA",
      "Location Name": "LANCASTER CARRIER ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1008 W AVENUE J2 LANCASTER CA 93534 USA "
    },
    {
      "Location ID": "935AB",
      "Location Name": "PALMDALE CARRIER ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 38917 20TH STREET EAST STE 701G PALMDALE CA 93550 USA "
    },
    {
      "Location ID": "935AS",
      "Location Name": "ANTELOPE STATION",
      "Time Zone Offset (Hours)": -3,
      "Address": " 43824 20TH STREET LANCASTER CA 93534 USA "
    },
    {
      "Location ID": "935CA",
      "Location Name": "CANTIL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 28111 JAWBONE CANYON RD MOJAVE CA 93501 USA "
    },
    {
      "Location ID": "935CS",
      "Location Name": "CEDAR",
      "Time Zone Offset (Hours)": -3,
      "Address": " 567 W LANCASTER BLVD LANCASTER CA 93534 USA "
    },
    {
      "Location ID": "935FP",
      "Location Name": "FLEX N PINK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 821 TUCKER RD SUITE E TEHACHAPI CA 93561 USA "
    },
    {
      "Location ID": "935RA",
      "Location Name": "RANDSBURG",
      "Time Zone Offset (Hours)": -3,
      "Address": " 26741 BUTTE AVE RANDSBURG CA 93554 USA "
    },
    {
      "Location ID": "935SD",
      "Location Name": "SHIP DEPOT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 114 GRAND CYPRESS AVE PALMDALE CA 93551 USA "
    },
    {
      "Location ID": "935TS",
      "Location Name": "TOWN SQUARE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2220 E PALMDALE BLVD PALMDALE CA 93550 USA "
    },
    {
      "Location ID": "936",
      "Location Name": "FRESNO P&DC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1900 E ST FRESNO CA 93706 USA "
    },
    {
      "Location ID": "93601",
      "Location Name": "AHWAHNEE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 44878 NOTRE DAME LN AHWAHNEE CA 93601 USA "
    },
    {
      "Location ID": "93602",
      "Location Name": "AUBERRY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 33012 AUBERRY RD AUBERRY CA 93602 USA "
    },
    {
      "Location ID": "93604",
      "Location Name": "BASS LAKE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 54449 ROAD 432 BASS LAKE CA 93604 USA "
    },
    {
      "Location ID": "93605",
      "Location Name": "BIG CREEK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 55083 POINT RD BIG CREEK CA 93605 USA "
    },
    {
      "Location ID": "93606",
      "Location Name": "BIOLA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4672 N BIOLA AVE BIOLA CA 93606 USA "
    },
    {
      "Location ID": "93608",
      "Location Name": "CANTUA CREEK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 28900 W CLARKSON AVE CANTUA CREEK CA 93608 USA "
    },
    {
      "Location ID": "93609",
      "Location Name": "CARUTHERS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2413 W TAHOE AVE CARUTHERS CA 93609 USA "
    },
    {
      "Location ID": "93610",
      "Location Name": "CHOWCHILLA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 135 S 1ST ST CHOWCHILLA CA 93610 USA "
    },
    {
      "Location ID": "93612",
      "Location Name": "CLOVIS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 655 MINNEWAWA AVE CLOVIS CA 93612 USA "
    },
    {
      "Location ID": "93614",
      "Location Name": "COARSEGOLD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 46944 PONY EXPRESS LN COARSEGOLD CA 93614 USA "
    },
    {
      "Location ID": "93615",
      "Location Name": "CUTLER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12749 1ST DR CUTLER CA 93615 USA "
    },
    {
      "Location ID": "93616",
      "Location Name": "DEL REY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10772 MORRO AVE DEL REY CA 93616 USA "
    },
    {
      "Location ID": "93618",
      "Location Name": "DINUBA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 222 S K ST DINUBA CA 93618 USA "
    },
    {
      "Location ID": "93620",
      "Location Name": "DOS PALOS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1555 GOLDEN GATE AVE DOS PALOS CA 93620 USA "
    },
    {
      "Location ID": "93622",
      "Location Name": "FIREBAUGH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1580 11TH ST FIREBAUGH CA 93622 USA "
    },
    {
      "Location ID": "93624",
      "Location Name": "FIVE POINTS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 21074 S LASSEN AVE FIVE POINTS CA 93624 USA "
    },
    {
      "Location ID": "93625",
      "Location Name": "FOWLER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19 E MERCED ST FOWLER CA 93625 USA "
    },
    {
      "Location ID": "93626",
      "Location Name": "FRIANT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 17275 N FRIANT RD FRIANT CA 93626 USA "
    },
    {
      "Location ID": "93627",
      "Location Name": "HELM",
      "Time Zone Offset (Hours)": -3,
      "Address": " 13883 S LASSEN AVE HELM CA 93627 USA "
    },
    {
      "Location ID": "93630",
      "Location Name": "KERMAN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 14920 W D ST KERMAN CA 93630 USA "
    },
    {
      "Location ID": "93631",
      "Location Name": "KINGSBURG",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1401 MARION ST KINGSBURG CA 93631 USA "
    },
    {
      "Location ID": "93634",
      "Location Name": "LAKESHORE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 62804 HUNTINGTON LAKE RD LAKESHORE CA 93634 USA "
    },
    {
      "Location ID": "93635",
      "Location Name": "LOS BANOS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1135 6TH ST LOS BANOS CA 93635 USA "
    },
    {
      "Location ID": "93638",
      "Location Name": "MADERA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 201 S MADERA CA 93638 USA "
    },
    {
      "Location ID": "93640",
      "Location Name": "MENDOTA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 655 OLLER ST MENDOTA CA 93640 USA "
    },
    {
      "Location ID": "93643",
      "Location Name": "NORTH FORK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 33173 ROAD 222 NORTH FORK CA 93643 USA "
    },
    {
      "Location ID": "93644",
      "Location Name": "OAKHURST",
      "Time Zone Offset (Hours)": -3,
      "Address": " 40064 HIGHWAY 49 OAKHURST CA 93644 USA "
    },
    {
      "Location ID": "93645",
      "Location Name": "O NEALS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 47065 ROAD 201 O NEALS CA 93645 USA "
    },
    {
      "Location ID": "93646",
      "Location Name": "ORANGE COVE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 550 PARK BLVD ORANGE COVE CA 93646 USA "
    },
    {
      "Location ID": "93647",
      "Location Name": "OROSI",
      "Time Zone Offset (Hours)": -3,
      "Address": " 41323 ROAD 128 OROSI CA 93647 USA "
    },
    {
      "Location ID": "93648",
      "Location Name": "PARLIER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 850 E PARLIER AVE PARLIER CA 93648 USA "
    },
    {
      "Location ID": "93650",
      "Location Name": "PINEDALE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 34 W MINARETS AVE FRESNO CA 93650 USA "
    },
    {
      "Location ID": "93651",
      "Location Name": "PRATHER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 29460 AUBERRY RD PRATHER CA 93651 USA "
    },
    {
      "Location ID": "93652",
      "Location Name": "RAISIN CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9221 S ORMUS AVE RAISIN CITY CA 93652 USA "
    },
    {
      "Location ID": "93654",
      "Location Name": "REEDLEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1509 11TH ST REEDLEY CA 93654 USA "
    },
    {
      "Location ID": "93656",
      "Location Name": "RIVERDALE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3401 HENSON ST RIVERDALE CA 93656 USA "
    },
    {
      "Location ID": "93657",
      "Location Name": "SANGER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 724 O ST SANGER CA 93657 USA "
    },
    {
      "Location ID": "93660",
      "Location Name": "SAN JOAQUIN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8667 9TH ST SAN JOAQUIN CA 93660 USA "
    },
    {
      "Location ID": "93661",
      "Location Name": "SANTA RITA PARK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8100 W AZUSA RD SANTA RITA PARK CA 93661 USA "
    },
    {
      "Location ID": "93662",
      "Location Name": "SELMA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2058 HIGH ST SELMA CA 93662 USA "
    },
    {
      "Location ID": "93664",
      "Location Name": "SHAVER LAKE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 40677 SHAVER FOREST RD SHAVER LAKE CA 93664 USA "
    },
    {
      "Location ID": "93665",
      "Location Name": "SOUTH DOS PALOS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8858 W M ST SOUTH DOS PALOS CA 93665 USA "
    },
    {
      "Location ID": "93666",
      "Location Name": "SULTANA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10457 AVENUE 416 SULTANA CA 93666 USA "
    },
    {
      "Location ID": "93667",
      "Location Name": "TOLLHOUSE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 28425 GIBBS RANCH RD TOLLHOUSE CA 93667 USA "
    },
    {
      "Location ID": "93668",
      "Location Name": "TRANQUILLITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 25660 SCAGGS AVE TRANQUILLITY CA 93668 USA "
    },
    {
      "Location ID": "93670",
      "Location Name": "YETTEM",
      "Time Zone Offset (Hours)": -3,
      "Address": " 14026 AVENUE 384 YETTEM CA 93670 USA "
    },
    {
      "Location ID": "93673",
      "Location Name": "TRAVER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 36501 BURKE DR TRAVER CA 93673 USA "
    },
    {
      "Location ID": "936AA",
      "Location Name": "MADERA CARRIER ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 220 S MADERA CA 93638 USA "
    },
    {
      "Location ID": "936BB",
      "Location Name": "BEST BUY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 777 MONTE VISTA DR DINUBA CA 93618 USA "
    },
    {
      "Location ID": "936BU",
      "Location Name": "BURREL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10655 WEST ELK HORN BURREL CA 93607 USA "
    },
    {
      "Location ID": "936PA",
      "Location Name": "FRESNO PEAK ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1775 PARK ST STE 79B SELMA CA 93662 USA "
    },
    {
      "Location ID": "936WM",
      "Location Name": "WALMART",
      "Time Zone Offset (Hours)": -3,
      "Address": " 14061 W WHITESBRIDGE AVE KERMAN CA 93630 USA "
    },
    {
      "Location ID": "93704",
      "Location Name": "CARDWELL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1606 E GRIFFITH WAY FRESNO CA 93704 USA "
    },
    {
      "Location ID": "93705",
      "Location Name": "HUGHES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3727 N HUGHES AVE FRESNO CA 93705 USA "
    },
    {
      "Location ID": "93720",
      "Location Name": "WOODWARD PARK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 755 E NEES AVE FRESNO CA 93720 USA "
    },
    {
      "Location ID": "93721",
      "Location Name": "CIVIC CENTER FRESNO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 855 M STREET FRESNO CA 93721 USA "
    },
    {
      "Location ID": "93725",
      "Location Name": "CALWA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4026 E JENSEN AVE FRESNO CA 93725 USA "
    },
    {
      "Location ID": "93727",
      "Location Name": "EAST FRESNO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5555 E TULARE AVE FRESNO CA 93727 USA "
    },
    {
      "Location ID": "93728",
      "Location Name": "TOWER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 824 E FERN AVE FRESNO CA 93728 USA "
    },
    {
      "Location ID": "937AB",
      "Location Name": "ALPHA BRODER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4247 S MINNEWAWA AVE FRESNO CA 93725 USA "
    },
    {
      "Location ID": "937AM",
      "Location Name": "AMAZON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3575 S ORANGE AVE FRESNO CA 93725 USA "
    },
    {
      "Location ID": "937BE",
      "Location Name": "BELLA NATURAL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1155 E NORTH AVE STE 101 FRESNO CA 93725 USA "
    },
    {
      "Location ID": "937CE",
      "Location Name": "CEDAR ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2382 S CEDAR AVE FRESNO CA 93725 USA "
    },
    {
      "Location ID": "937CL",
      "Location Name": "CLINTER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2601 EAST OLIVE ST FRESNO CA 93701 USA "
    },
    {
      "Location ID": "937CS",
      "Location Name": "CARDWELL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1606 E GRIFFITH WAY FRESNO CA 93704 USA "
    },
    {
      "Location ID": "937EI",
      "Location Name": "EXCELSIOR INTEGRATED",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2851 S EAST AVENUE FRESNO CA 93725 USA "
    },
    {
      "Location ID": "937GA",
      "Location Name": "GAP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3400 GAP DR FRESNO CA 93727 USA "
    },
    {
      "Location ID": "937LR",
      "Location Name": "LITTLE RAYMONDS PRINT SHOP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2904 S ANGUS AVE STE 103 FRESNO CA 93725 USA "
    },
    {
      "Location ID": "937MA",
      "Location Name": "METRO AUTO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3119 S WILLOW AVE FRESNO CA 93725 USA "
    },
    {
      "Location ID": "937MM",
      "Location Name": "MERCURY MARINE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3421 E NORTH AVE FRESNO CA 93725 USA "
    },
    {
      "Location ID": "937MP",
      "Location Name": "MOBILE PAGE CONTRACT STATION",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4460 W SHAW AVE FRESNO CA 93722 USA "
    },
    {
      "Location ID": "937SC",
      "Location Name": "STATE COMPENSATION INSURANCE FUND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10 RIVER PARK PL E FL 4 FRESNO CA 93720 USA "
    },
    {
      "Location ID": "937VA",
      "Location Name": "VA MEDICAL CENTER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2615 E CLINTON AVE FRESNO CA 93703 USA "
    },
    {
      "Location ID": "937VI",
      "Location Name": "VERIZON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3443 E CENTRAL AVENUE FRESNO CA 93725 USA "
    },
    {
      "Location ID": "93888",
      "Location Name": "INTERNAL REVENUE SERVICE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3211 S NORTHPOINTE DR FRESNO CA 93725 USA "
    },
    {
      "Location ID": "939",
      "Location Name": "SALINAS P&DF",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1011 POST DR SALINAS CA 93907 USA "
    },
    {
      "Location ID": "93928",
      "Location Name": "JOLON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 79 INFANTRY RD JOLON CA 93928 USA "
    },
    {
      "Location ID": "93930",
      "Location Name": "KING CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 123 S 3RD ST KING CITY CA 93930 USA "
    },
    {
      "Location ID": "93932",
      "Location Name": "LOCKWOOD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 68025 JOLON RD LOCKWOOD CA 93932 USA "
    },
    {
      "Location ID": "93954",
      "Location Name": "SAN LUCAS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 53575 MAIN ST SAN LUCAS CA 93954 USA "
    },
    {
      "Location ID": "940",
      "Location Name": "SAN FRANCISCO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1300 EVANS AVE STE 300P SAN FRANCISCO CA 94188 USA "
    },
    {
      "Location ID": "94015",
      "Location Name": "WESTLAKE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 199 SOUTHGATE AVE DALY CITY CA 94015 USA "
    },
    {
      "Location ID": "940FZ",
      "Location Name": "LOG SAN FRANCISCO CA PSA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2200 COURAGE DRIVE FAIRFIELD CA 94533 USA "
    },
    {
      "Location ID": "940NP",
      "Location Name": "LOG NORTH PENINSULA DDC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1625 ROLLINS RD BURLINGAME CA 94010 USA "
    },
    {
      "Location ID": "941DA",
      "Location Name": "DELTA CARGO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 612 WEST FIELD RD SAN FRANCISCO CA 94128 USA "
    },
    {
      "Location ID": "941HB",
      "Location Name": "PEAK SEASON HUB - SF",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1210 17TH ST SAN FRANCISCO CA 94107 USA "
    },
    {
      "Location ID": "941KA",
      "Location Name": "KALITTA PEAK CHARTER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 606 MCDONNELL RD SAN FRANCISCO CA 94128 USA "
    },
    {
      "Location ID": "941NC",
      "Location Name": "NORTH BEACH ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2200 POWELL ST SAN FRANCISCO CA 94133 USA "
    },
    {
      "Location ID": "941UA",
      "Location Name": "UNITED CARGO SFO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 585 MCDONNELL ROAD SFO SAN FRANCISCO CA 94128 USA "
    },
    {
      "Location ID": "945",
      "Location Name": "LOG OAKLAND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1675 7TH ST OAKLAND CA 94615 USA "
    },
    {
      "Location ID": "94508",
      "Location Name": "ANGWIN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5 ANGWIN PLZ ANGWIN CA 94508 USA "
    },
    {
      "Location ID": "94511",
      "Location Name": "BETHEL ISLAND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6444 BETHEL ISLAND RD BETHEL ISLAND CA 94511 USA "
    },
    {
      "Location ID": "94513",
      "Location Name": "BRENTWOOD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 18 OAK ST BRENTWOOD CA 94513 USA "
    },
    {
      "Location ID": "94516",
      "Location Name": "CANYON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 99 PINEHURST RD CANYON CA 94516 USA "
    },
    {
      "Location ID": "94517",
      "Location Name": "CLAYTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6150 CENTER ST CLAYTON CA 94517 USA "
    },
    {
      "Location ID": "94548",
      "Location Name": "KNIGHTSEN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3019 KNIGHTSEN AVE KNIGHTSEN CA 94548 USA "
    },
    {
      "Location ID": "94556",
      "Location Name": "MORAGA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 460 CENTER ST MORAGA CA 94556 USA "
    },
    {
      "Location ID": "94561",
      "Location Name": "OAKLEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 400 DEL ANTICO AVE OAKLEY CA 94561 USA "
    },
    {
      "Location ID": "94567",
      "Location Name": "POPE VALLEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5850 POPE VALLEY RD POPE VALLEY CA 94567 USA "
    },
    {
      "Location ID": "94574",
      "Location Name": "SAINT HELENA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1461 MAIN ST SAINT HELENA CA 94574 USA "
    },
    {
      "Location ID": "94598",
      "Location Name": "YGNACIO VALLEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2244 OAK GROVE RD WALNUT CREEK CA 94598 USA "
    },
    {
      "Location ID": "945AD",
      "Location Name": "VALASSIS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6955 MOWRY AVE NEWARK CA 94560 USA "
    },
    {
      "Location ID": "945DB",
      "Location Name": "BYRON CA DISCOVERY BAY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3345 BIXLER RD DISCOVERY BAY CA 94505 USA "
    },
    {
      "Location ID": "945LC",
      "Location Name": "LIVERMORE ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2090 LAS POSITAS CT LIVERMORE CA 94550 USA "
    },
    {
      "Location ID": "945OP",
      "Location Name": "OAKLAND PEAK ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2998 ALVARADO ST H SAN LEANDRO CA 94577 USA "
    },
    {
      "Location ID": "946DG",
      "Location Name": "DELTA GLOBAL SERVICES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1 AIRPORT DR OAKLAND CA 94621 USA "
    },
    {
      "Location ID": "948",
      "Location Name": "MC VITTIE ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2100 CHANSLOR AVE RICHMOND CA 94804 USA "
    },
    {
      "Location ID": "94850",
      "Location Name": "S F BMC U S P S",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2501 RYDIN ROAD RICHMOND CA 94850 USA "
    },
    {
      "Location ID": "948DK",
      "Location Name": "DOLL'S KILL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1414 HARBOUR WAY S STE 1701 RICHMOND CA 94804 USA "
    },
    {
      "Location ID": "949",
      "Location Name": "NORTH BAY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1150 N MCDOWELL BLVD PETALUMA CA 94999 USA "
    },
    {
      "Location ID": "94901",
      "Location Name": "SAN RAFAEL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 40 BELLAM BLVD SAN RAFAEL CA 94901 USA "
    },
    {
      "Location ID": "94920",
      "Location Name": "BELVEDERE TIBURON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6 BEACH RD BELVEDERE TIBURON CA 94920 USA "
    },
    {
      "Location ID": "94924",
      "Location Name": "BOLINAS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 20 BRIGHTON AVE BOLINAS CA 94924 USA "
    },
    {
      "Location ID": "94925",
      "Location Name": "CORTE MADERA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7 PIXLEY AVE CORTE MADERA CA 94925 USA "
    },
    {
      "Location ID": "94928",
      "Location Name": "ROHNERT PARK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 150 RALEYS TOWNE CTR ROHNERT PARK CA 94928 USA "
    },
    {
      "Location ID": "94930",
      "Location Name": "FAIRFAX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 773 CENTER BLVD FAIRFAX CA 94930 USA "
    },
    {
      "Location ID": "94931",
      "Location Name": "COTATI",
      "Time Zone Offset (Hours)": -3,
      "Address": " 502 E COTATI AVE COTATI CA 94931 USA "
    },
    {
      "Location ID": "94933",
      "Location Name": "FOREST KNOLLS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6 CASTRO ST FOREST KNOLLS CA 94933 USA "
    },
    {
      "Location ID": "94937",
      "Location Name": "INVERNESS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12781 SIR FRANCIS DRAKE BLVD INVERNESS CA 94937 USA "
    },
    {
      "Location ID": "94938",
      "Location Name": "LAGUNITAS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7120 SIR FRANCIS DRAKE BLVD LAGUNITAS CA 94938 USA "
    },
    {
      "Location ID": "94939",
      "Location Name": "LARKSPUR",
      "Time Zone Offset (Hours)": -3,
      "Address": " 120 WARD ST LARKSPUR CA 94939 USA "
    },
    {
      "Location ID": "94941",
      "Location Name": "MILL VALLEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 751 E BLITHEDALE AVE MILL VALLEY CA 94941 USA "
    },
    {
      "Location ID": "94946",
      "Location Name": "NICASIO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1 OLD RANCHERIA RD NICASIO CA 94946 USA "
    },
    {
      "Location ID": "94947",
      "Location Name": "NOVATO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1537 S NOVATO BLVD NOVATO CA 94947 USA "
    },
    {
      "Location ID": "94950",
      "Location Name": "OLEMA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10155 HIGHWAY 1 OLEMA CA 94950 USA "
    },
    {
      "Location ID": "94951",
      "Location Name": "PENNGROVE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 11041 MAIN ST PENNGROVE CA 94951 USA "
    },
    {
      "Location ID": "94955",
      "Location Name": "CASA GRANDE ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1601 CORPORATE CIR PETALUMA CA 94954 USA "
    },
    {
      "Location ID": "94956",
      "Location Name": "POINT REYES STATION",
      "Time Zone Offset (Hours)": -3,
      "Address": " 11260 HIGHWAY 1 POINT REYES STATION CA 94956 USA "
    },
    {
      "Location ID": "94957",
      "Location Name": "ROSS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1 ROSS CMN ROSS CA 94957 USA "
    },
    {
      "Location ID": "94960",
      "Location Name": "SAN ANSELMO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 121 SAN ANSELMO AVE SAN ANSELMO CA 94960 USA "
    },
    {
      "Location ID": "94963",
      "Location Name": "SAN GERONIMO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 630 SAN GERONIMO VALLEY DR SAN GERONIMO CA 94963 USA "
    },
    {
      "Location ID": "94964",
      "Location Name": "SAN QUENTIN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1 MAIN ST SAN QUENTIN CA 94964 USA "
    },
    {
      "Location ID": "94965",
      "Location Name": "SAUSALITO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 150 HARBOR DR SAUSALITO CA 94965 USA "
    },
    {
      "Location ID": "94970",
      "Location Name": "STINSON BEACH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 15 CALLE DEL MAR STINSON BEACH CA 94970 USA "
    },
    {
      "Location ID": "94973",
      "Location Name": "WOODACRE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 183 SAN GERONIMO VALLEY DR WOODACRE CA 94973 USA "
    },
    {
      "Location ID": "949CC",
      "Location Name": "CIVIC CENTER SAN RAFAEL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2 CIVIC CENTER DR SAN RAFAEL CA 94903 USA "
    },
    {
      "Location ID": "949MR",
      "Location Name": "MISSION RAFAEL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 910 D ST SAN RAFAEL CA 94901 USA "
    },
    {
      "Location ID": "949NE",
      "Location Name": "NEILMED",
      "Time Zone Offset (Hours)": -3,
      "Address": " 601 AVIATION BLVD SANTA ROSA CA 95403 USA "
    },
    {
      "Location ID": "949NO",
      "Location Name": "NORDSTROM 423",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1870 REDWOOD HWY CORTE MADERA CA 94925 USA "
    },
    {
      "Location ID": "949ZO",
      "Location Name": "ZENNI OPTICAL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 150 ALAMEDA DEL PRADO NOVATO CA 94949 USA "
    },
    {
      "Location ID": "94ISC",
      "Location Name": "SAN FRANCISCO ISC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 660 W FIELD RD SAN FRANCISCO CA 94128 USA "
    },
    {
      "Location ID": "94Z",
      "Location Name": "SAN FRANCISCO NDC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2501 RYDIN RD RICHMOND CA 94850 USA "
    },
    {
      "Location ID": "950",
      "Location Name": "LOG SAN JOSE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1750 LUNDY AVE SAN JOSE CA 95101 USA "
    },
    {
      "Location ID": "95003",
      "Location Name": "APTOS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 500 CATHEDRAL DR APTOS CA 95003 USA "
    },
    {
      "Location ID": "95008",
      "Location Name": "CAMPBELL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 500 W HAMILTON AVE CAMPBELL CA 95008 USA "
    },
    {
      "Location ID": "95010",
      "Location Name": "CAPITOLA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 826 BAY AVE CAPITOLA CA 95010 USA "
    },
    {
      "Location ID": "95014",
      "Location Name": "CUPERTINO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 21701 STEVENS CREEK BLVD CUPERTINO CA 95014 USA "
    },
    {
      "Location ID": "95030",
      "Location Name": "LOS GATOS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 101 S SANTA CRUZ AVE LOS GATOS CA 95030 USA "
    },
    {
      "Location ID": "95060",
      "Location Name": "SANTA CRUZ",
      "Time Zone Offset (Hours)": -3,
      "Address": " 850 FRONT ST SANTA CRUZ CA 95060 USA "
    },
    {
      "Location ID": "95062",
      "Location Name": "EAST SANTA CRUZ",
      "Time Zone Offset (Hours)": -3,
      "Address": " 120 MORRISSEY BLVD SANTA CRUZ CA 95062 USA "
    },
    {
      "Location ID": "95066",
      "Location Name": "SCOTTS VALLEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 241 KINGS VILLAGE RD SCOTTS VALLEY CA 95066 USA "
    },
    {
      "Location ID": "95070",
      "Location Name": "SARATOGA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19630 ALLENDALE AVE SARATOGA CA 95070 USA "
    },
    {
      "Location ID": "95073",
      "Location Name": "SOQUEL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4768 SOQUEL DR SOQUEL CA 95073 USA "
    },
    {
      "Location ID": "95076",
      "Location Name": "WATSONVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 225 MAIN ST WATSONVILLE CA 95076 USA "
    },
    {
      "Location ID": "950BP",
      "Location Name": "BAY PHOTO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 920 DISC DR SCOTTS VALLEY CA 95066 USA "
    },
    {
      "Location ID": "950DC",
      "Location Name": "DELL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1587 DELL AVE CAMPBELL CA 95008 USA "
    },
    {
      "Location ID": "950PA",
      "Location Name": "PERFUMER'S APPRENTICE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 170 TECHNOLOGY CIR SCOTTS VALLEY CA 95066 USA "
    },
    {
      "Location ID": "950SA",
      "Location Name": "JOHN SANCHEZ ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1451 WALSH AVE SANTA CLARA CA 95050 USA "
    },
    {
      "Location ID": "95111",
      "Location Name": "SEVEN TREES ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 80 LEWIS RD SAN JOSE CA 95111 USA "
    },
    {
      "Location ID": "95125",
      "Location Name": "WILLOW GLEN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1750 MERIDIAN AVE SAN JOSE CA 95125 USA "
    },
    {
      "Location ID": "951PA",
      "Location Name": "LOG SAN JOSE CA MPA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1750 MERIDIAN AVE SAN JOSE CA 95125 USA "
    },
    {
      "Location ID": "952",
      "Location Name": "LOG STOCKTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3131 ARCH AIRPORT RD STOCKTON CA 95213 USA "
    },
    {
      "Location ID": "95202",
      "Location Name": "DELTA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 240 E CHANNEL ST STOCKTON CA 95202 USA "
    },
    {
      "Location ID": "95204",
      "Location Name": "TUXEDO PARK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2616 PACIFIC AVE STOCKTON CA 95204 USA "
    },
    {
      "Location ID": "95205",
      "Location Name": "EAST STOCKTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3333 E MAIN ST STOCKTON CA 95205 USA "
    },
    {
      "Location ID": "95206",
      "Location Name": "HOMESTEAD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1610 S EL DORADO ST STOCKTON CA 95206 USA "
    },
    {
      "Location ID": "95207",
      "Location Name": "CALAVERAS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1048 W ROBINHOOD DR STOCKTON CA 95207 USA "
    },
    {
      "Location ID": "95208",
      "Location Name": "STOCKTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4245 WEST LN STOCKTON CA 95208 USA "
    },
    {
      "Location ID": "95210",
      "Location Name": "HAMMER RANCH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7554 PACIFIC AVE STOCKTON CA 95207 USA "
    },
    {
      "Location ID": "95213",
      "Location Name": "ARCH AIRPORT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3131 ARCH AIRPORT RD STOCKTON CA 95213 USA "
    },
    {
      "Location ID": "95231",
      "Location Name": "FRENCH CAMP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 315 E FRENCH CAMP RD FRENCH CAMP CA 95231 USA "
    },
    {
      "Location ID": "95234",
      "Location Name": "HOLT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10340 S WHISKEY SLOUGH RD HOLT CA 95234 USA "
    },
    {
      "Location ID": "952AM",
      "Location Name": "AMAZON STOCKTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1909 ZEPHYR ST STOCKTON CA 95206 USA "
    },
    {
      "Location ID": "952AZ",
      "Location Name": "AMAZON SCK9",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4601 NEWCASTLE RD STOCKTON CA 95215 USA "
    },
    {
      "Location ID": "952DF",
      "Location Name": "DORFMAN PACIFIC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2615 BOEING WAY STOCKTON CA 95206 USA "
    },
    {
      "Location ID": "952LT",
      "Location Name": "LE TOTE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3021 BOEING WAY STOCKTON CA 95206 USA "
    },
    {
      "Location ID": "952PA",
      "Location Name": "NORTHERN CA PEAK ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3131 ARCH AIRPORT RD STOCKTON CA 95213 USA "
    },
    {
      "Location ID": "952PC",
      "Location Name": "PRESORT CENTER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3806 CORONADO STOCKTON CA 95204 USA "
    },
    {
      "Location ID": "953",
      "Location Name": "TOA ALTA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 70 CALLE LUIS MUNOZ RIVERA TOA ALTA PR 95399 USA "
    },
    {
      "Location ID": "95301",
      "Location Name": "ATWATER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1599 BROADWAY AVE ATWATER CA 95301 USA "
    },
    {
      "Location ID": "95304",
      "Location Name": "TRACY CARRIER ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4025 COMMERCIAL DR STE B TRACY CA 95304 USA "
    },
    {
      "Location ID": "95307",
      "Location Name": "CERES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2801 MITCHELL RD CERES CA 95307 USA "
    },
    {
      "Location ID": "95315",
      "Location Name": "DELHI",
      "Time Zone Offset (Hours)": -3,
      "Address": " 16096 LOCUST ST DELHI CA 95315 USA "
    },
    {
      "Location ID": "95328",
      "Location Name": "KEYES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5655 7TH ST KEYES CA 95328 USA "
    },
    {
      "Location ID": "95330",
      "Location Name": "LATHROP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 15529 7TH ST LATHROP CA 95330 USA "
    },
    {
      "Location ID": "95334",
      "Location Name": "LIVINGSTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1444 B ST LIVINGSTON CA 95334 USA "
    },
    {
      "Location ID": "95340",
      "Location Name": "MERCED",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2334 M ST MERCED CA 95340 USA "
    },
    {
      "Location ID": "95350",
      "Location Name": "MODESTO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 715 KEARNEY AVE MODESTO CA 95350 USA "
    },
    {
      "Location ID": "95376",
      "Location Name": "TRACY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 125 W 9TH ST TRACY CA 95376 USA "
    },
    {
      "Location ID": "95380",
      "Location Name": "TURLOCK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 555 E MAIN ST TURLOCK CA 95380 USA "
    },
    {
      "Location ID": "953AO",
      "Location Name": "AMAZON TCY5",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2250 PROMONTORY PKWY TRACY CA 95377 USA "
    },
    {
      "Location ID": "953MI",
      "Location Name": "MEDLINE INDUSTRIES INC.",
      "Time Zone Offset (Hours)": -3,
      "Address": " 400 DARCY PARKWAY LATHROP CA 95330 USA "
    },
    {
      "Location ID": "953MT",
      "Location Name": "CPU TUOLUMNE MEADOWS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 14000 HIGHWAY 120 E YOSEMITE NATIONAL PARK CA 95389 USA "
    },
    {
      "Location ID": "953TA",
      "Location Name": "TRACY CARRIER ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4025 COMMERCIAL STE B TRACY CA 95376 USA "
    },
    {
      "Location ID": "953WC",
      "Location Name": "QUAD GRAPHICS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2201 COOPER AVE MERCED CA 95348 USA "
    },
    {
      "Location ID": "95402",
      "Location Name": "SANTA ROSA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 730 2ND ST SANTA ROSA CA 95404 USA "
    },
    {
      "Location ID": "95403",
      "Location Name": "SANTA ROSA CARRIER ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2850 MCBRIDE LN SANTA ROSA CA 95403 USA "
    },
    {
      "Location ID": "95410",
      "Location Name": "ALBION",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3350 ALBION RIDGE RD ALBION CA 95410 USA "
    },
    {
      "Location ID": "95416",
      "Location Name": "BOYES HOT SPRINGS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 18092 SONOMA HWY BOYES HOT SPRINGS CA 95416 USA "
    },
    {
      "Location ID": "95419",
      "Location Name": "CAMP MEEKER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5240 BOHEMIAN HWY CAMP MEEKER CA 95419 USA "
    },
    {
      "Location ID": "95421",
      "Location Name": "CAZADERO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6145 CAZADERO HWY CAZADERO CA 95421 USA "
    },
    {
      "Location ID": "95425",
      "Location Name": "CLOVERDALE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 115 BROAD ST CLOVERDALE CA 95425 USA "
    },
    {
      "Location ID": "95430",
      "Location Name": "DUNCANS MILLS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 25375 STEELHEAD BLVD DUNCANS MILLS CA 95430 USA "
    },
    {
      "Location ID": "95431",
      "Location Name": "ELDRIDGE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 15000 ARNOLD DR ELDRIDGE CA 95431 USA "
    },
    {
      "Location ID": "95433",
      "Location Name": "EL VERANO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19050 BAY ST EL VERANO CA 95433 USA "
    },
    {
      "Location ID": "95436",
      "Location Name": "FORESTVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6484 MIRABEL RD FORESTVILLE CA 95436 USA "
    },
    {
      "Location ID": "95437",
      "Location Name": "FORT BRAGG",
      "Time Zone Offset (Hours)": -3,
      "Address": " 203 N FRANKLIN ST FORT BRAGG CA 95437 USA "
    },
    {
      "Location ID": "95439",
      "Location Name": "FULTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3340 FULTON RD FULTON CA 95439 USA "
    },
    {
      "Location ID": "95441",
      "Location Name": "GEYSERVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 116 SCHOOL HOUSE LN GEYSERVILLE CA 95441 USA "
    },
    {
      "Location ID": "95442",
      "Location Name": "GLEN ELLEN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 13720 ARNOLD DR GLEN ELLEN CA 95442 USA "
    },
    {
      "Location ID": "95444",
      "Location Name": "GRATON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3171 ROSS RD GRATON CA 95444 USA "
    },
    {
      "Location ID": "95445",
      "Location Name": "GUALALA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 39361 S HIGHWAY 1 GUALALA CA 95445 USA "
    },
    {
      "Location ID": "95446",
      "Location Name": "GUERNEVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 14060 MILL ST GUERNEVILLE CA 95446 USA "
    },
    {
      "Location ID": "95448",
      "Location Name": "HEALDSBURG",
      "Time Zone Offset (Hours)": -3,
      "Address": " 160 FOSS CREEK CIR HEALDSBURG CA 95448 USA "
    },
    {
      "Location ID": "95449",
      "Location Name": "HOPLAND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 13400 S HIGHWAY 101 HOPLAND CA 95449 USA "
    },
    {
      "Location ID": "95450",
      "Location Name": "JENNER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10439 HIGHWAY 1 JENNER CA 95450 USA "
    },
    {
      "Location ID": "95452",
      "Location Name": "KENWOOD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8930 SONOMA HWY KENWOOD CA 95452 USA "
    },
    {
      "Location ID": "95454",
      "Location Name": "LAYTONVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 50 RAMSEY RD LAYTONVILLE CA 95454 USA "
    },
    {
      "Location ID": "95459",
      "Location Name": "MANCHESTER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19391 US HIGHWAY 1 MANCHESTER CA 95459 USA "
    },
    {
      "Location ID": "95460",
      "Location Name": "MENDOCINO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10500 FORD ST MENDOCINO CA 95460 USA "
    },
    {
      "Location ID": "95462",
      "Location Name": "MONTE RIO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19420 HIGHWAY 116 MONTE RIO CA 95462 USA "
    },
    {
      "Location ID": "95465",
      "Location Name": "OCCIDENTAL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3805 BOHEMIAN HWY OCCIDENTAL CA 95465 USA "
    },
    {
      "Location ID": "95468",
      "Location Name": "POINT ARENA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 264 MAIN ST POINT ARENA CA 95468 USA "
    },
    {
      "Location ID": "95470",
      "Location Name": "REDWOOD VALLEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 993 E SCHOOL WAY REDWOOD VALLEY CA 95470 USA "
    },
    {
      "Location ID": "95471",
      "Location Name": "RIO NIDO METRO BOX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 14770 RIO NIDO RD GUERNEVILLE CA 95446 USA "
    },
    {
      "Location ID": "95472",
      "Location Name": "SEBASTOPOL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 290 S MAIN ST SEBASTOPOL CA 95472 USA "
    },
    {
      "Location ID": "95476",
      "Location Name": "SONOMA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 617 BROADWAY SONOMA CA 95476 USA "
    },
    {
      "Location ID": "95480",
      "Location Name": "STEWARTS POINT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 32049 HIGHWAY 1 STEWARTS POINT CA 95480 USA "
    },
    {
      "Location ID": "95482",
      "Location Name": "UKIAH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 671 S ORCHARD AVE UKIAH CA 95482 USA "
    },
    {
      "Location ID": "95486",
      "Location Name": "VILLA GRANDE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 21893 WEST ST VILLA GRANDE CA 95486 USA "
    },
    {
      "Location ID": "95487",
      "Location Name": "VINEBURG",
      "Time Zone Offset (Hours)": -3,
      "Address": " 20579 8TH ST E VINEBURG CA 95487 USA "
    },
    {
      "Location ID": "95490",
      "Location Name": "WILLITS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 315 S MAIN ST WILLITS CA 95490 USA "
    },
    {
      "Location ID": "95492",
      "Location Name": "WINDSOR",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8800 LAKEWOOD DR WINDSOR CA 95492 USA "
    },
    {
      "Location ID": "954BC",
      "Location Name": "BACHAN'S",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9119 GRATON RD GRATON CA 95444 USA "
    },
    {
      "Location ID": "954FN",
      "Location Name": "FORCES OF NATURE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 21787 8TH ST E STE 3A SONOMA CA 95476 USA "
    },
    {
      "Location ID": "954NE",
      "Location Name": "NEILMED",
      "Time Zone Offset (Hours)": -3,
      "Address": " 601 AVIATION BLVD SANTA ROSA CA 95403 USA "
    },
    {
      "Location ID": "954SA",
      "Location Name": "SEBASTOPOL CARRIER ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 275 PETALUMA AVE SEBASTOPOL CA 95472 USA "
    },
    {
      "Location ID": "954SR",
      "Location Name": "THE SEA RANCH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 60 SEAWALK DR THE SEA RANCH CA 95497 USA "
    },
    {
      "Location ID": "954UX",
      "Location Name": "UKIAH ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 251 W PERKINS STREET UKIAH CA 95482 USA "
    },
    {
      "Location ID": "955",
      "Location Name": "EUREKA P&DC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 337 W CLARK ST EUREKA CA 95501 USA "
    },
    {
      "Location ID": "95521",
      "Location Name": "ARCATA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 799 H ST ARCATA CA 95521 USA "
    },
    {
      "Location ID": "95524",
      "Location Name": "BAYSIDE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1836 OLD ARCATA RD BAYSIDE CA 95524 USA "
    },
    {
      "Location ID": "95531",
      "Location Name": "CRESCENT CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 751 2ND ST CRESCENT CITY CA 95531 USA "
    },
    {
      "Location ID": "95537",
      "Location Name": "FIELDS LANDING",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6710 FIELDS LANDING DR FIELDS LANDING CA 95537 USA "
    },
    {
      "Location ID": "95540",
      "Location Name": "FORTUNA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 699 13TH ST FORTUNA CA 95540 USA "
    },
    {
      "Location ID": "95542",
      "Location Name": "GARBERVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 368 SPROWL CREEK RD GARBERVILLE CA 95542 USA "
    },
    {
      "Location ID": "95543",
      "Location Name": "GASQUET",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10360 US HIGHWAY 199 GASQUET CA 95543 USA "
    },
    {
      "Location ID": "95548",
      "Location Name": "KLAMATH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 141 KLAMATH BLVD KLAMATH CA 95548 USA "
    },
    {
      "Location ID": "95551",
      "Location Name": "LOLETA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 338 MAIN ST LOLETA CA 95551 USA "
    },
    {
      "Location ID": "95553",
      "Location Name": "MIRANDA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6723 STATE HIGHWAY 254 MIRANDA CA 95553 USA "
    },
    {
      "Location ID": "95554",
      "Location Name": "MYERS FLAT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12848 STATE HIGHWAY 254 MYERS FLAT CA 95554 USA "
    },
    {
      "Location ID": "95555",
      "Location Name": "ORICK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 121147 HIGHWAY 101 ORICK CA 95555 USA "
    },
    {
      "Location ID": "95559",
      "Location Name": "PHILLIPSVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2788 HIGHWAY 254 PHILLIPSVILLE CA 95559 USA "
    },
    {
      "Location ID": "95560",
      "Location Name": "REDWAY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3400 REDWOOD DR REDWAY CA 95560 USA "
    },
    {
      "Location ID": "95562",
      "Location Name": "RIO DELL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 315 WILDWOOD AVE RIO DELL CA 95562 USA "
    },
    {
      "Location ID": "95564",
      "Location Name": "SAMOA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 244 N BAYVIEW AVE SAMOA CA 95564 USA "
    },
    {
      "Location ID": "95565",
      "Location Name": "SCOTIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 116 MAIN ST SCOTIA CA 95565 USA "
    },
    {
      "Location ID": "95567",
      "Location Name": "SMITH RIVER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 291 N FRED D HAIGHT DR SMITH RIVER CA 95567 USA "
    },
    {
      "Location ID": "95569",
      "Location Name": "REDCREST",
      "Time Zone Offset (Hours)": -3,
      "Address": " 26406 STATE HIGHWAY 254 REDCREST CA 95569 USA "
    },
    {
      "Location ID": "95571",
      "Location Name": "WEOTT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 11 LUM ST WEOTT CA 95571 USA "
    },
    {
      "Location ID": "95585",
      "Location Name": "LEGGETT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 67674 DRIVE THRU TREE RD LEGGETT CA 95585 USA "
    },
    {
      "Location ID": "955CR",
      "Location Name": "COLLEGE OF THE REDWOODS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7351 TOMPKINS HILL RD EUREKA CA 95501 USA "
    },
    {
      "Location ID": "955FD",
      "Location Name": "FORT DICK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6666 LAKE EARL DR FORT DICK CA 95538 USA "
    },
    {
      "Location ID": "955MK",
      "Location Name": "MC KINLEYVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1561 CITY CENTER RD MCKINLEYVILLE CA 95519 USA "
    },
    {
      "Location ID": "956",
      "Location Name": "SACRAMENTO P&DC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3775 INDUSTRIAL BLVD WEST SACRAMENTO CA 95799 USA "
    },
    {
      "Location ID": "95603",
      "Location Name": "AUBURN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 371 NEVADA ST AUBURN CA 95603 USA "
    },
    {
      "Location ID": "95629",
      "Location Name": "FIDDLETOWN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 14283 JIBBOOM ST FIDDLETOWN CA 95629 USA "
    },
    {
      "Location ID": "95631",
      "Location Name": "FORESTHILL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 24035 RACETRACK ST FORESTHILL CA 95631 USA "
    },
    {
      "Location ID": "95636",
      "Location Name": "GRIZZLY FLATS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4991 SCIARONI RD GRIZZLY FLATS CA 95636 USA "
    },
    {
      "Location ID": "95650",
      "Location Name": "LOOMIS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3669 TAYLOR RD LOOMIS CA 95650 USA "
    },
    {
      "Location ID": "95653",
      "Location Name": "MADISON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 29053 MAIN ST MADISON CA 95653 USA "
    },
    {
      "Location ID": "95656",
      "Location Name": "MOUNT AUKUM",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8171 MOUNT AUKUM RD STE 101 MOUNT AUKUM CA 95656 USA "
    },
    {
      "Location ID": "95658",
      "Location Name": "NEWCASTLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9241 OLD STATE HWY NEWCASTLE CA 95658 USA "
    },
    {
      "Location ID": "95663",
      "Location Name": "PENRYN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2205 RIPPEY RD PENRYN CA 95663 USA "
    },
    {
      "Location ID": "95669",
      "Location Name": "PLYMOUTH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9477 MAIN ST PLYMOUTH CA 95669 USA "
    },
    {
      "Location ID": "95683",
      "Location Name": "SLOUGHHOUSE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7268 MURIETA DR SLOUGHHOUSE CA 95683 USA "
    },
    {
      "Location ID": "95684",
      "Location Name": "SOMERSET",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6040 GRIZZLY FLAT RD SOMERSET CA 95684 USA "
    },
    {
      "Location ID": "95687",
      "Location Name": "VACAVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 200 PRAIRIE CT VACAVILLE CA 95687 USA "
    },
    {
      "Location ID": "95688",
      "Location Name": "CERNON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 98 CERNON ST VACAVILLE CA 95688 USA "
    },
    {
      "Location ID": "956AM",
      "Location Name": "AMAZON C/S SMF5",
      "Time Zone Offset (Hours)": -3,
      "Address": " 300 CROCKER DR VACAVILLE CA 95688 USA "
    },
    {
      "Location ID": "956AX",
      "Location Name": "LOG SACRAMENTO CA MPA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2000 ROYAL OAKS DR SACRAMENTO CA 95813 USA "
    },
    {
      "Location ID": "956AZ",
      "Location Name": "AMAZON SMF7 - VACAVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4800 MIDWAY RD VACAVILLE CA 95688 USA "
    },
    {
      "Location ID": "956NB",
      "Location Name": "NORTH BAY DIST INC (CESSNA)",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2050 CESSNA DR VACAVILLE CA 95688 USA "
    },
    {
      "Location ID": "956ND",
      "Location Name": "NORTH BAY DIST INC (MONTE VIS)",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2029 E MONTE VISTA AVE VACAVILLE CA 95688 USA "
    },
    {
      "Location ID": "956NI",
      "Location Name": "NORTH BAY DISTRIBUTION INC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2031 CESSNA DR VACAVILLE CA 95688 USA "
    },
    {
      "Location ID": "956PA",
      "Location Name": "ROSEVILLE PEAK ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 199 N SUNRISE AVE ROSEVILLE CA 95661 USA "
    },
    {
      "Location ID": "956ZD",
      "Location Name": "PITNEY BOWES PSI GROUP, INC.",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3700 SEAPORT BLVD 20 W SACRAMENTO CA 95691 USA "
    },
    {
      "Location ID": "95701",
      "Location Name": "ALTA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 33735 NARY RED RD ALTA CA 95701 USA "
    },
    {
      "Location ID": "95703",
      "Location Name": "APPLEGATE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 17880 APPLEGATE RD APPLEGATE CA 95703 USA "
    },
    {
      "Location ID": "95709",
      "Location Name": "CAMINO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4120 CARSON RD CAMINO CA 95709 USA "
    },
    {
      "Location ID": "95712",
      "Location Name": "CHICAGO PARK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 17748 LOWER COLFAX RD CHICAGO PARK CA 95712 USA "
    },
    {
      "Location ID": "95713",
      "Location Name": "COLFAX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 40 W CHURCH ST COLFAX CA 95713 USA "
    },
    {
      "Location ID": "95714",
      "Location Name": "DUTCH FLAT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 32805 MAIN ST DUTCH FLAT CA 95714 USA "
    },
    {
      "Location ID": "95717",
      "Location Name": "GOLD RUN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 31194 GOLD RUN RD GOLD RUN CA 95717 USA "
    },
    {
      "Location ID": "95722",
      "Location Name": "MEADOW VISTA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 16930 PLACER HILLS RD MEADOW VISTA CA 95722 USA "
    },
    {
      "Location ID": "95726",
      "Location Name": "POLLOCK PINES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2669 SANDERS DR POLLOCK PINES CA 95726 USA "
    },
    {
      "Location ID": "95728",
      "Location Name": "SODA SPRINGS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 21719 DONNER PASS RD SODA SPRINGS CA 95728 USA "
    },
    {
      "Location ID": "95736",
      "Location Name": "WEIMAR",
      "Time Zone Offset (Hours)": -3,
      "Address": " 21050 CANYON WAY WEIMAR CA 95736 USA "
    },
    {
      "Location ID": "957BC",
      "Location Name": "BROADRIDGE CUSTOMER COMMUNICATIONS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5220 ROBERT J MATHEWS PKWY EL DORADO HLS CA 95762 USA "
    },
    {
      "Location ID": "957DF",
      "Location Name": "VPO DUTCH FLAT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 32795 MAIN ST DUTCH FLAT CA 95714 USA "
    },
    {
      "Location ID": "957EG",
      "Location Name": "VPO EMIGRANT GAP 2",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1 NYACK RD EMIGRANT GAP CA 95715 USA "
    },
    {
      "Location ID": "957EM",
      "Location Name": "VPO EMIGRANAT GAP 1, CA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 43440 LAING RD EMIGRANT GAP CA 95715 USA "
    },
    {
      "Location ID": "958FE",
      "Location Name": "958FE FEDEX GROUND SERVICES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8371 ROVANA CIR STE 100 SACRAMENTO CA 95828 USA "
    },
    {
      "Location ID": "958PB",
      "Location Name": "AT&T",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3900 CHANNEL DR WEST SACRAMENTO CA 95691 USA "
    },
    {
      "Location ID": "95912",
      "Location Name": "ARBUCKLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 415 5TH ST ARBUCKLE CA 95912 USA "
    },
    {
      "Location ID": "95913",
      "Location Name": "ARTOIS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 357 FRONT ST ARTOIS CA 95913 USA "
    },
    {
      "Location ID": "95917",
      "Location Name": "BIGGS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2994 6TH ST BIGGS CA 95917 USA "
    },
    {
      "Location ID": "95920",
      "Location Name": "BUTTE CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 31 KILARNEY ST BUTTE CITY CA 95920 USA "
    },
    {
      "Location ID": "95926",
      "Location Name": "CHICO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 550 VALLOMBROSA AVE CHICO CA 95926 USA "
    },
    {
      "Location ID": "95932",
      "Location Name": "COLUSA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 357 MARKET ST COLUSA CA 95932 USA "
    },
    {
      "Location ID": "95937",
      "Location Name": "DUNNIGAN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3650 COUNTY ROAD 99W DUNNIGAN CA 95937 USA "
    },
    {
      "Location ID": "95938",
      "Location Name": "DURHAM",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2396 DURHAM DAYTON HWY DURHAM CA 95938 USA "
    },
    {
      "Location ID": "95943",
      "Location Name": "GLENN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1836 STATE HIGHWAY 45 GLENN CA 95943 USA "
    },
    {
      "Location ID": "95948",
      "Location Name": "GRIDLEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 805 SYCAMORE ST GRIDLEY CA 95948 USA "
    },
    {
      "Location ID": "95950",
      "Location Name": "GRIMES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 170 MAIN ST GRIMES CA 95950 USA "
    },
    {
      "Location ID": "95951",
      "Location Name": "HAMILTON CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 201 MAIN ST HAMILTON CITY CA 95951 USA "
    },
    {
      "Location ID": "95953",
      "Location Name": "LIVE OAK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2622 GUM ST LIVE OAK CA 95953 USA "
    },
    {
      "Location ID": "95955",
      "Location Name": "MAXWELL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 260 OAK ST MAXWELL CA 95955 USA "
    },
    {
      "Location ID": "95957",
      "Location Name": "MERIDIAN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1041 3RD ST MERIDIAN CA 95957 USA "
    },
    {
      "Location ID": "95958",
      "Location Name": "NELSON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6805 MIDWAY RD NELSON CA 95958 USA "
    },
    {
      "Location ID": "95963",
      "Location Name": "ORLAND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 831 4TH ST ORLAND CA 95963 USA "
    },
    {
      "Location ID": "95965",
      "Location Name": "OROVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1735 ROBINSON ST OROVILLE CA 95965 USA "
    },
    {
      "Location ID": "95968",
      "Location Name": "PALERMO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7390 LINCOLN BLVD PALERMO CA 95968 USA "
    },
    {
      "Location ID": "95970",
      "Location Name": "PRINCETON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 149 COMMERCIAL ST PRINCETON CA 95970 USA "
    },
    {
      "Location ID": "95974",
      "Location Name": "RICHVALE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1259 BROADWAY RICHVALE CA 95974 USA "
    },
    {
      "Location ID": "95982",
      "Location Name": "SUTTER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2209 CALIFORNIA ST SUTTER CA 95982 USA "
    },
    {
      "Location ID": "95987",
      "Location Name": "WILLIAMS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 801 E ST WILLIAMS CA 95987 USA "
    },
    {
      "Location ID": "95988",
      "Location Name": "WILLOWS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 315 W SYCAMORE ST WILLOWS CA 95988 USA "
    },
    {
      "Location ID": "959AM",
      "Location Name": "A MAIN HOBBIES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 424 OTTERSON DR STE 100 CHICO CA 95928 USA "
    },
    {
      "Location ID": "959LU",
      "Location Name": "LULUS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2336 PARK AVE CHICO CA 95928 USA "
    },
    {
      "Location ID": "959SU",
      "Location Name": "5TH SUN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 487 RYAN AVE CHICO CA 95973 USA "
    },
    {
      "Location ID": "95ACE",
      "Location Name": "ACE HARDWARE CPU",
      "Time Zone Offset (Hours)": -5,
      "Address": " 240 MULDOON RD ANCHORAGE AK 99504 USA "
    },
    {
      "Location ID": "95H",
      "Location Name": "NORTHERN CA STC",
      "Time Zone Offset (Hours)": -3,
      "Address": "STE1  7531 METRO AIR PKWY STE 1 SACRAMENTO CA 95837 USA "
    },
    {
      "Location ID": "95MTE",
      "Location Name": "SAN FRANCISCO MTESC - NEW",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2795 PARADISE RD STE 100 TRACY CA 95304 USA "
    },
    {
      "Location ID": "960",
      "Location Name": "LOG REDDING",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2323 CHURN CREEK RD REDDING CA 96002 USA "
    },
    {
      "Location ID": "96001",
      "Location Name": "DOWNTOWN REDDING",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1647 YUBA ST REDDING CA 96001 USA "
    },
    {
      "Location ID": "960BF",
      "Location Name": "BENEFICIAL INSECTORY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9664 TANQUERAY CT REDDING CA 96003 USA "
    },
    {
      "Location ID": "960RF",
      "Location Name": "REDDING FASTERNERS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3721 ELECTRO WAY REDDING CA 96002 USA "
    },
    {
      "Location ID": "96114",
      "Location Name": "JANESVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 463-495 MAIN ST JANESVILLE CA 96114 USA "
    },
    {
      "Location ID": "96117",
      "Location Name": "LITCHFIELD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 721-255 CENTER RD LITCHFIELD CA 96117 USA "
    },
    {
      "Location ID": "96128",
      "Location Name": "STANDISH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 718-650 US HIGHWAY 395 E STANDISH CA 96128 USA "
    },
    {
      "Location ID": "96134",
      "Location Name": "TULELAKE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 541 MODOC AVE TULELAKE CA 96134 USA "
    },
    {
      "Location ID": "967",
      "Location Name": "LOG HONOLULU",
      "Time Zone Offset (Hours)": -5,
      "Address": " 3600 AOLELE ST RM 208E HONOLULU HI 96820 USA "
    },
    {
      "Location ID": "96719",
      "Location Name": "HAWI",
      "Time Zone Offset (Hours)": -5,
      "Address": " 55-515 HAWI RD HAWI HI 96719 USA "
    },
    {
      "Location ID": "96727",
      "Location Name": "HONOKAA",
      "Time Zone Offset (Hours)": -5,
      "Address": " 45-490 LEHUA ST HONOKAA HI 96727 USA "
    },
    {
      "Location ID": "96732",
      "Location Name": "KAHULUI",
      "Time Zone Offset (Hours)": -5,
      "Address": " 138 S PUUNENE AVE KAHULUI HI 96732 USA "
    },
    {
      "Location ID": "96743",
      "Location Name": "KAMUELA",
      "Time Zone Offset (Hours)": -5,
      "Address": " 67-1197 MAMALAHOA HWY KAMUELA HI 96743 USA "
    },
    {
      "Location ID": "96753",
      "Location Name": "KIHEI",
      "Time Zone Offset (Hours)": -5,
      "Address": " 1254 S KIHEI RD KIHEI HI 96753 USA "
    },
    {
      "Location ID": "96755",
      "Location Name": "KAPAAU",
      "Time Zone Offset (Hours)": -5,
      "Address": " 54-396 UNION MILL RD KAPAAU HI 96755 USA "
    },
    {
      "Location ID": "96761",
      "Location Name": "LAHAINA",
      "Time Zone Offset (Hours)": -5,
      "Address": " 1760 HONOAPIILANI HWY LAHAINA HI 96761 USA "
    },
    {
      "Location ID": "96767",
      "Location Name": "DOWNTOWN LAHAINA",
      "Time Zone Offset (Hours)": -5,
      "Address": " 132 PAPALAUA ST LAHAINA HI 96761 USA "
    },
    {
      "Location ID": "96768",
      "Location Name": "MAKAWAO",
      "Time Zone Offset (Hours)": -5,
      "Address": " 1075 MAKAWAO AVE MAKAWAO HI 96768 USA "
    },
    {
      "Location ID": "96784",
      "Location Name": "PUUNENE",
      "Time Zone Offset (Hours)": -5,
      "Address": " 10 HANSEN RD PUUNENE HI 96784 USA "
    },
    {
      "Location ID": "96788",
      "Location Name": "PUKALANI",
      "Time Zone Offset (Hours)": -5,
      "Address": " 55 PUKALANI ST STE 12 MAKAWAO HI 96768 USA "
    },
    {
      "Location ID": "96793",
      "Location Name": "WAILUKU",
      "Time Zone Offset (Hours)": -5,
      "Address": " 250 IMI KALA ST WAILUKU HI 96793 USA "
    },
    {
      "Location ID": "967HM",
      "Location Name": "HAMAKUA MACADAMIA NUT COMPANY",
      "Time Zone Offset (Hours)": -5,
      "Address": " 61-3251 MALUOKALANI ST KAMUELA HI 96743 USA "
    },
    {
      "Location ID": "970",
      "Location Name": "PORTLAND P&DC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 715 NW HOYT ST PORTLAND OR 97208 USA "
    },
    {
      "Location ID": "97001",
      "Location Name": "ANTELOPE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 45404 MAIN ST ANTELOPE OR 97001 USA "
    },
    {
      "Location ID": "97002",
      "Location Name": "AURORA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 14682 OTTAWAY RD NE AURORA OR 97002 USA "
    },
    {
      "Location ID": "97009",
      "Location Name": "BORING",
      "Time Zone Offset (Hours)": -3,
      "Address": " 28515 SE HIGHWAY 212 BORING OR 97009 USA "
    },
    {
      "Location ID": "97011",
      "Location Name": "BRIGHTWOOD OR",
      "Time Zone Offset (Hours)": -3,
      "Address": " 20275 E AMBROSE ST BRIGHTWOOD OR 97011 USA "
    },
    {
      "Location ID": "97013",
      "Location Name": "CANBY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 615 NW 2ND AVE CANBY OR 97013 USA "
    },
    {
      "Location ID": "97015",
      "Location Name": "CLACKAMAS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9009 SE ADAMS ST CLACKAMAS OR 97015 USA "
    },
    {
      "Location ID": "97017",
      "Location Name": "COLTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 20980 S HIGHWAY 211 COLTON OR 97017 USA "
    },
    {
      "Location ID": "97029",
      "Location Name": "GRASS VALLEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 114 N MILL ST GRASS VALLEY OR 97029 USA "
    },
    {
      "Location ID": "97030",
      "Location Name": "GRESHAM",
      "Time Zone Offset (Hours)": -3,
      "Address": " 103 W POWELL BLVD GRESHAM OR 97030 USA "
    },
    {
      "Location ID": "97032",
      "Location Name": "HUBBARD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3016 G ST HUBBARD OR 97032 USA "
    },
    {
      "Location ID": "97033",
      "Location Name": "KENT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 95097 DOBIE POINT LN KENT OR 97033 USA "
    },
    {
      "Location ID": "97039",
      "Location Name": "MORO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 109 1ST ST MORO OR 97039 USA "
    },
    {
      "Location ID": "97045",
      "Location Name": "OREGON CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19300 MOLALLA AVE OREGON CITY OR 97045 USA "
    },
    {
      "Location ID": "97050",
      "Location Name": "RUFUS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 308 FOWLER ST RUFUS OR 97050 USA "
    },
    {
      "Location ID": "97055",
      "Location Name": "SANDY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 17570 WOLF DR SANDY OR 97055 USA "
    },
    {
      "Location ID": "97057",
      "Location Name": "SHANIKO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 93482 4TH ST SHANIKO OR 97057 USA "
    },
    {
      "Location ID": "97058",
      "Location Name": "THE DALLES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 101 W 2ND ST THE DALLES OR 97058 USA "
    },
    {
      "Location ID": "97060",
      "Location Name": "TROUTDALE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 647 SW CHERRY PARK RD TROUTDALE OR 97060 USA "
    },
    {
      "Location ID": "97065",
      "Location Name": "WASCO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1024 CLARK ST WASCO OR 97065 USA "
    },
    {
      "Location ID": "97070",
      "Location Name": "WILSONVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 29333 SW TOWN CENTER LOOP E WILSONVILLE OR 97070 USA "
    },
    {
      "Location ID": "97071",
      "Location Name": "WOODBURN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 525 N 1ST ST WOODBURN OR 97071 USA "
    },
    {
      "Location ID": "970AC",
      "Location Name": "AMERICAN AIRLINES AIR FREIGHT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7760 NE AIRPORT WAY PORTLAND OR 97218 USA "
    },
    {
      "Location ID": "970AN",
      "Location Name": "PORTLAND PEAK ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6855 NE 82ND AVE PORTLAND OR 97220 USA "
    },
    {
      "Location ID": "970AX",
      "Location Name": "PORTLAND PEAK ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5230 N BASIN AVE PORTLAND OR 97217 USA "
    },
    {
      "Location ID": "970AZ",
      "Location Name": "AZURE STANDARD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 500 AZURE LN MORO OR 97039 USA "
    },
    {
      "Location ID": "970DC",
      "Location Name": "HOOD RIVER ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3020 LOWER MILL DR HOOD RIVER OR 97031 USA "
    },
    {
      "Location ID": "970TD",
      "Location Name": "TUALATIN DCU",
      "Time Zone Offset (Hours)": -3,
      "Address": " 18850 SW TETON AVE TUALATIN OR 97062 USA "
    },
    {
      "Location ID": "97107",
      "Location Name": "BAY CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9220 5TH ST BAY CITY OR 97107 USA "
    },
    {
      "Location ID": "97110",
      "Location Name": "CANNON BEACH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 163 N HEMLOCK CANNON BEACH OR 97110 USA "
    },
    {
      "Location ID": "97112",
      "Location Name": "CLOVERDALE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 34480 HIGHWAY 101 S CLOVERDALE OR 97112 USA "
    },
    {
      "Location ID": "97118",
      "Location Name": "GARIBALDI",
      "Time Zone Offset (Hours)": -3,
      "Address": " 511 ACACIA ST GARIBALDI OR 97118 USA "
    },
    {
      "Location ID": "97122",
      "Location Name": "HEBO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 31155 HIGHWAY 101 S HEBO OR 97122 USA "
    },
    {
      "Location ID": "97124",
      "Location Name": "EVERGREEN DCU BVRTN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3685 NE ALOCLEK DR PORTLAND OR 97291 USA "
    },
    {
      "Location ID": "97130",
      "Location Name": "MANZANITA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 370 LANEDA AVE MANZANITA OR 97130 USA "
    },
    {
      "Location ID": "97131",
      "Location Name": "NEHALEM",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12810 H ST NEHALEM OR 97131 USA "
    },
    {
      "Location ID": "97135",
      "Location Name": "PACIFIC CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 35230 BROOTEN RD PACIFIC CITY OR 97135 USA "
    },
    {
      "Location ID": "97136",
      "Location Name": "ROCKAWAY BEACH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 105 N 3RD AVE ROCKAWAY BEACH OR 97136 USA "
    },
    {
      "Location ID": "97138",
      "Location Name": "SEASIDE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 300 AVENUE A SEASIDE OR 97138 USA "
    },
    {
      "Location ID": "97141",
      "Location Name": "TILLAMOOK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2200 1ST ST TILLAMOOK OR 97141 USA "
    },
    {
      "Location ID": "97147",
      "Location Name": "WHEELER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 500 NEHALEM BLVD WHEELER OR 97147 USA "
    },
    {
      "Location ID": "97149",
      "Location Name": "NESKOWIN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 48990 HIGHWAY 101 S NESKOWIN OR 97149 USA "
    },
    {
      "Location ID": "971AM",
      "Location Name": "AMAZON PDX5",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5647 NW HUFFMAN ST HILLSBORO OR 97124 USA "
    },
    {
      "Location ID": "971KA",
      "Location Name": "KAP CC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 36084 7TH ST NEHALEM OR 97131 USA "
    },
    {
      "Location ID": "971TS",
      "Location Name": "TILLAMOOK COUNTY SMOKER",
      "Time Zone Offset (Hours)": -3,
      "Address": " PO BOX 3120 BAY CITY OR 97107 USA "
    },
    {
      "Location ID": "972",
      "Location Name": "LOG PORTLAND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7007 NE CORNFOOT RD PORTLAND OR 97218 USA "
    },
    {
      "Location ID": "97218",
      "Location Name": "NORTHWEST CARGO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8116 NE AIR CARGO RD PORTLAND OR 97218 USA "
    },
    {
      "Location ID": "9722D",
      "Location Name": "MOUNT HOOD DDC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8564 NE ALDERWOOD RD PORTLAND OR 97220 USA "
    },
    {
      "Location ID": "972AE",
      "Location Name": "LENTS DCU",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8100 SE CRYSTAL SPRINGS BLVD PORTLAND OR 97206 USA "
    },
    {
      "Location ID": "972AM",
      "Location Name": "AMAZON PDX6",
      "Time Zone Offset (Hours)": -3,
      "Address": " 15000 N LOMBARD ST PORTLAND OR 97203 USA "
    },
    {
      "Location ID": "972DM",
      "Location Name": "DIRECT MARKETING SOLUTION",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8534 NE ALDERWOOD RD PORTLAND OR 97220 USA "
    },
    {
      "Location ID": "972FX",
      "Location Name": "972FX FEDEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5159 NE CORNFOOT RD PORTLAND OR 97218 USA "
    },
    {
      "Location ID": "973",
      "Location Name": "SALEM",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1050 25TH ST SE SALEM OR 97301 USA "
    },
    {
      "Location ID": "97321",
      "Location Name": "ALBANY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 525 2ND AVE SW ALBANY OR 97321 USA "
    },
    {
      "Location ID": "97325",
      "Location Name": "AUMSVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 320 MAIN ST AUMSVILLE OR 97325 USA "
    },
    {
      "Location ID": "97330",
      "Location Name": "CORVALLIS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 311 SW 2ND ST CORVALLIS OR 97333 USA "
    },
    {
      "Location ID": "97345",
      "Location Name": "FOSTER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5300 POPLAR ST FOSTER OR 97345 USA "
    },
    {
      "Location ID": "97355",
      "Location Name": "LEBANON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 55 WALKER RD LEBANON OR 97355 USA "
    },
    {
      "Location ID": "97358",
      "Location Name": "LYONS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 402 IRONWOOD ST LYONS OR 97358 USA "
    },
    {
      "Location ID": "97360",
      "Location Name": "MILL CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 101 SE KINGWOOD AVE MILL CITY OR 97360 USA "
    },
    {
      "Location ID": "97362",
      "Location Name": "MOUNT ANGEL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 90 N GARFIELD ST MOUNT ANGEL OR 97362 USA "
    },
    {
      "Location ID": "97365",
      "Location Name": "NEWPORT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 310 SW 2ND ST NEWPORT OR 97365 USA "
    },
    {
      "Location ID": "97367",
      "Location Name": "LINCOLN CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1501 SE EAST DEVILS LAKE RD LINCOLN CITY OR 97367 USA "
    },
    {
      "Location ID": "97373",
      "Location Name": "SAINT BENEDICT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1 ABBEY DR SAINT BENEDICT OR 97373 USA "
    },
    {
      "Location ID": "97375",
      "Location Name": "SCOTTS MILLS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 251 3RD ST SCOTTS MILLS OR 97375 USA "
    },
    {
      "Location ID": "97380",
      "Location Name": "SILETZ",
      "Time Zone Offset (Hours)": -3,
      "Address": " 295 SE GAITHER ST SILETZ OR 97380 USA "
    },
    {
      "Location ID": "97381",
      "Location Name": "SILVERTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 551 N WATER ST SILVERTON OR 97381 USA "
    },
    {
      "Location ID": "97385",
      "Location Name": "SUBLIMITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 211 NW STARR ST SUBLIMITY OR 97385 USA "
    },
    {
      "Location ID": "97386",
      "Location Name": "SWEET HOME",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1303 LONG ST SWEET HOME OR 97386 USA "
    },
    {
      "Location ID": "97391",
      "Location Name": "TOLEDO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 147 NW 1ST ST TOLEDO OR 97391 USA "
    },
    {
      "Location ID": "97394",
      "Location Name": "WALDPORT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 165 NW JOHN ST WALDPORT OR 97394 USA "
    },
    {
      "Location ID": "973DC",
      "Location Name": "SALEM HOLLYWOOD DCU",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2222 CLAXTER RD NE SALEM OR 97301 USA "
    },
    {
      "Location ID": "974",
      "Location Name": "LOG EUGENE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3148 GATEWAY ST SPRINGFIELD OR 97475 USA "
    },
    {
      "Location ID": "97409",
      "Location Name": "ALVADORE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 90828 RAILROAD ST ALVADORE OR 97409 USA "
    },
    {
      "Location ID": "97412",
      "Location Name": "BLACHLY             OR",
      "Time Zone Offset (Hours)": -3,
      "Address": " 20448 HIGHWAY 36 BLACHLY OR 97412 USA "
    },
    {
      "Location ID": "97415",
      "Location Name": "BROOKINGS, OR",
      "Time Zone Offset (Hours)": -3,
      "Address": " 711 SPRUCE ST BROOKINGS OR 97415 USA "
    },
    {
      "Location ID": "97419",
      "Location Name": "CHESHIRE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 27071 HIGHWAY 36 CHESHIRE OR 97419 USA "
    },
    {
      "Location ID": "97420",
      "Location Name": "COOS BAY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 470 GOLDEN AVE COOS BAY OR 97420 USA "
    },
    {
      "Location ID": "97423",
      "Location Name": "COQUILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 75 N BIRCH ST COQUILLE OR 97423 USA "
    },
    {
      "Location ID": "97430",
      "Location Name": "DEADWOOD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 14701 HIGHWAY 36 DEADWOOD OR 97430 USA "
    },
    {
      "Location ID": "97435",
      "Location Name": "DRAIN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 228 W C AVE DRAIN OR 97435 USA "
    },
    {
      "Location ID": "97436",
      "Location Name": "ELKTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 275 B ST ELKTON OR 97436 USA "
    },
    {
      "Location ID": "97437",
      "Location Name": "ELMIRA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 88769 TERRITORIAL RD ELMIRA OR 97437 USA "
    },
    {
      "Location ID": "97446",
      "Location Name": "HARRISBURG",
      "Time Zone Offset (Hours)": -3,
      "Address": " 204 SMITH ST HARRISBURG OR 97446 USA "
    },
    {
      "Location ID": "97448",
      "Location Name": "JUNCTION CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 669 GREENWOOD ST JUNCTION CITY OR 97448 USA "
    },
    {
      "Location ID": "97453",
      "Location Name": "MAPLETON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10656 HIGHWAY 126 MAPLETON OR 97453 USA "
    },
    {
      "Location ID": "97456",
      "Location Name": "MONROE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 590 MAIN ST MONROE OR 97456 USA "
    },
    {
      "Location ID": "97458",
      "Location Name": "MYRTLE POINT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 514 ASH ST MYRTLE POINT OR 97458 USA "
    },
    {
      "Location ID": "97459",
      "Location Name": "NORTH BEND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1835 MCPHERSON ST NORTH BEND OR 97459 USA "
    },
    {
      "Location ID": "97461",
      "Location Name": "NOTI",
      "Time Zone Offset (Hours)": -3,
      "Address": " 22528 HIGHWAY 126 NOTI OR 97461 USA "
    },
    {
      "Location ID": "97462",
      "Location Name": "OAKLAND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 223 NE 2ND ST OAKLAND OR 97462 USA "
    },
    {
      "Location ID": "97467",
      "Location Name": "REEDSPORT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 301 FIR AVE REEDSPORT OR 97467 USA "
    },
    {
      "Location ID": "97470",
      "Location Name": "ROSEBURG",
      "Time Zone Offset (Hours)": -3,
      "Address": " 519 SE KANE ST ROSEBURG OR 97470 USA "
    },
    {
      "Location ID": "97473",
      "Location Name": "SCOTTSBURG",
      "Time Zone Offset (Hours)": -3,
      "Address": " 35346 STATE HIGHWAY 38 SCOTTSBURG OR 97473 USA "
    },
    {
      "Location ID": "97479",
      "Location Name": "SUTHERLIN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 591 E CENTRAL AVE SUTHERLIN OR 97479 USA "
    },
    {
      "Location ID": "97480",
      "Location Name": "SWISSHOME",
      "Time Zone Offset (Hours)": -3,
      "Address": " 13297 HIGHWAY 36 SWISSHOME OR 97480 USA "
    },
    {
      "Location ID": "97487",
      "Location Name": "VENETA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 25042 DUNHAM AVE VENETA OR 97487 USA "
    },
    {
      "Location ID": "97490",
      "Location Name": "WALTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 18945 HIGHWAY 126 WALTON OR 97490 USA "
    },
    {
      "Location ID": "97494",
      "Location Name": "WILBUR",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8837 OLD HWY 99 N WILBUR OR 97494 USA "
    },
    {
      "Location ID": "97495",
      "Location Name": "WINCHESTER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5571 NE STEPHENS ST WINCHESTER OR 97495 USA "
    },
    {
      "Location ID": "97499",
      "Location Name": "YONCALLA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 173 ALDER ST YONCALLA OR 97499 USA "
    },
    {
      "Location ID": "974EG",
      "Location Name": "EUGENE PEAK ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3500 CHAD DR EUGENE OR 97408 USA "
    },
    {
      "Location ID": "974WB",
      "Location Name": "WINCHESTER BAY",
      "Time Zone Offset (Hours)": -3,
      "Address": " PO BOX 1039 WINCHESTER BAY OR 97467 USA "
    },
    {
      "Location ID": "975",
      "Location Name": "LOG MEDFORD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2195 SAGE RD MEDFORD OR 97501 USA "
    },
    {
      "Location ID": "97501",
      "Location Name": "MEDFORD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 325 S RIVERSIDE AVE MEDFORD OR 97501 USA "
    },
    {
      "Location ID": "9752D",
      "Location Name": "GRANTS PASS DCU",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1636 NW WASHINGTON BLVD GRANTS PASS OR 97526 USA "
    },
    {
      "Location ID": "976",
      "Location Name": "KLAMATH FALLS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 317 S 7TH ST KLAMATH FALLS OR 97601 USA "
    },
    {
      "Location ID": "97630",
      "Location Name": "LAKEVIEW",
      "Time Zone Offset (Hours)": -3,
      "Address": " 18 S G ST LAKEVIEW OR 97630 USA "
    },
    {
      "Location ID": "97632",
      "Location Name": "MALIN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2109 BROADWAY ST MALIN OR 97632 USA "
    },
    {
      "Location ID": "97633",
      "Location Name": "MERRILL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 138 E FRONT ST MERRILL OR 97633 USA "
    },
    {
      "Location ID": "97634",
      "Location Name": "MIDLAND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10505 HWY 97 S MIDLAND OR 97634 USA "
    },
    {
      "Location ID": "977",
      "Location Name": "BEND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2300 NE 4TH ST BEND OR 97701 USA "
    },
    {
      "Location ID": "97739",
      "Location Name": "LA PINE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 51649 HUNTINGTON RD LA PINE OR 97739 USA "
    },
    {
      "Location ID": "978",
      "Location Name": "PENDLETON           OR",
      "Time Zone Offset (Hours)": -3,
      "Address": " 104 SW DORION AVE PENDLETON OR 97801 USA "
    },
    {
      "Location ID": "97814",
      "Location Name": "BAKER CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1550 DEWEY AVE BAKER CITY OR 97814 USA "
    },
    {
      "Location ID": "97850",
      "Location Name": "LA GRANDE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1202 WASHINGTON AVE LA GRANDE OR 97850 USA "
    },
    {
      "Location ID": "97901",
      "Location Name": "ADRIAN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 105 OREGON ST ADRIAN OR 97901 USA "
    },
    {
      "Location ID": "97905",
      "Location Name": "DURKEE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 28677 OLD HWY 30 DURKEE OR 97905 USA "
    },
    {
      "Location ID": "97907",
      "Location Name": "HUNTINGTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10 E WASHINGTON ST HUNTINGTON OR 97907 USA "
    },
    {
      "Location ID": "97913",
      "Location Name": "NYSSA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 16 N 4TH ST NYSSA OR 97913 USA "
    },
    {
      "Location ID": "97914",
      "Location Name": "ONTARIO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 88 SW 2ND AVE ONTARIO OR 97914 USA "
    },
    {
      "Location ID": "980",
      "Location Name": "LOG SEATTLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10700 27TH AVE S SEATTLE WA 98168 USA "
    },
    {
      "Location ID": "98002",
      "Location Name": "AUBURN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 11 3RD ST NW AUBURN WA 98002 USA "
    },
    {
      "Location ID": "98011",
      "Location Name": "BOTHELL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10500 BEARDSLEE BLVD BOTHELL WA 98011 USA "
    },
    {
      "Location ID": "98012",
      "Location Name": "MILL CREEK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 15833 MILL CREEK BLVD MILL CREEK WA 98012 USA "
    },
    {
      "Location ID": "98020",
      "Location Name": "EDMONDS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 130 2ND AVE N EDMONDS WA 98020 USA "
    },
    {
      "Location ID": "98023",
      "Location Name": "TWIN LAKES AUBURN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1815 SW CAMPUS DR FEDERAL WAY WA 98023 USA "
    },
    {
      "Location ID": "98026",
      "Location Name": "PERRINVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7601 OLYMPIC VIEW DR EDMONDS WA 98026 USA "
    },
    {
      "Location ID": "98031",
      "Location Name": "KENT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10612 SE 240TH ST KENT WA 98031 USA "
    },
    {
      "Location ID": "98036",
      "Location Name": "LYNNWOOD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6817 208TH ST SW LYNNWOOD WA 98036 USA "
    },
    {
      "Location ID": "98038",
      "Location Name": "MAPLE VALLEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 22023 SE WAX RD MAPLE VALLEY WA 98038 USA "
    },
    {
      "Location ID": "9803D",
      "Location Name": "LOG SOUTH DDC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 24800 64TH AVE S KENT WA 98032 USA "
    },
    {
      "Location ID": "98055",
      "Location Name": "DOWNTOWN RENTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 314 WILLIAMS AVE S RENTON WA 98057 USA "
    },
    {
      "Location ID": "98058",
      "Location Name": "RENTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 17200 116TH AVE SE RENTON WA 98058 USA "
    },
    {
      "Location ID": "98059",
      "Location Name": "HIGHLANDS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4301 NE 4TH ST RENTON WA 98059 USA "
    },
    {
      "Location ID": "9805D",
      "Location Name": "LOG EAST DDC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7241 185TH AVE NE REDMOND WA 98052 USA "
    },
    {
      "Location ID": "98072",
      "Location Name": "WOODINVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 17610 WOODINVILLE SNOHOMISH RD NE WOODINVILLE WA 98072 USA "
    },
    {
      "Location ID": "980AX",
      "Location Name": "SEATTLE PEAK ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 18516 80TH AVE S KENT WA 98032 USA "
    },
    {
      "Location ID": "980FZ",
      "Location Name": "LOG SEATTLE WA PSA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 18340 SEGALE PARK DRIVE B TUKWILA WA 98188 USA "
    },
    {
      "Location ID": "980KS",
      "Location Name": "KAYE SMITH BUSINESS GRAPHICS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4101 OAKESDALE AVE SW RENTON WA 98057 USA "
    },
    {
      "Location ID": "980LD",
      "Location Name": "KAYE SMITH BUSINESS GRAPHICS, WA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4101 OAKESDALE AVE SW RENTON WA 98057 USA "
    },
    {
      "Location ID": "980MA",
      "Location Name": "MAL ADVERTISING BUREAU",
      "Time Zone Offset (Hours)": -3,
      "Address": " 21319 68TH AVE S KENT WA 98032 USA "
    },
    {
      "Location ID": "980OP",
      "Location Name": "OPTUM RX - ARLINGTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6015 180TH ST NE STE 109 ARLINGTON WA 98223 USA "
    },
    {
      "Location ID": "980PT",
      "Location Name": "POWERTECH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 17669 128TH PL NE BLDG C WOODINVILLE WA 98072 USA "
    },
    {
      "Location ID": "980PX",
      "Location Name": "SEATTLE PEAK LOT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10230 E MARGINAL WAY S TUKWILA WA 98168 USA "
    },
    {
      "Location ID": "980RO",
      "Location Name": "ROTARY OFFSET PRESS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8435 S 208TH ST KENT WA 98031 USA "
    },
    {
      "Location ID": "980VL",
      "Location Name": "VITAMIN LIFE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 15940 REDMOND WAY REDMOND WA 98052 USA "
    },
    {
      "Location ID": "981AN",
      "Location Name": "LOG SEATTLE PRIORITY ML ANX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 22430 RUSSELL RD KENT WA 98032 USA "
    },
    {
      "Location ID": "981MM",
      "Location Name": "DCS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 700 SW 34TH ST RENTON WA 98057 USA "
    },
    {
      "Location ID": "981PS",
      "Location Name": "PITNEY BOWES PRESORT SERVICES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19005 64TH AVE S STE 175 KENT WA 98032 USA "
    },
    {
      "Location ID": "982",
      "Location Name": "EVERETT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8120 HARDESON RD EVERETT WA 98203 USA "
    },
    {
      "Location ID": "98226",
      "Location Name": "MOUNT BAKER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3150 ORLEANS ST BELLINGHAM WA 98226 USA "
    },
    {
      "Location ID": "98230",
      "Location Name": "BLAINE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 701 HARRISON AVE BLAINE WA 98230 USA "
    },
    {
      "Location ID": "982AS",
      "Location Name": "AUTOMATED MAILING SVCS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 880 GRANT AVE BLAINE WA 98230 USA "
    },
    {
      "Location ID": "982TT",
      "Location Name": "TRIPLE T TRADING/NORTHSIDE, INC.",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4052 152ND ST NE MARYSVILLE WA 98271 USA "
    },
    {
      "Location ID": "983",
      "Location Name": "LOG TACOMA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4001 S PINE ST TACOMA WA 98413 USA "
    },
    {
      "Location ID": "98324",
      "Location Name": "CARLSBORG",
      "Time Zone Offset (Hours)": -3,
      "Address": " 20 BUSINESS PARK LOOP CARLSBORG WA 98324 USA "
    },
    {
      "Location ID": "98342",
      "Location Name": "INDIANOLA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9200 NE SHORE DR INDIANOLA WA 98342 USA "
    },
    {
      "Location ID": "98364",
      "Location Name": "PORT GAMBLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4839 NE VIEW DR STE A PORT GAMBLE WA 98364 USA "
    },
    {
      "Location ID": "98382",
      "Location Name": "SEQUIM",
      "Time Zone Offset (Hours)": -3,
      "Address": " 240 S SUNNYSIDE AVE SEQUIM WA 98382 USA "
    },
    {
      "Location ID": "98383",
      "Location Name": "SILVERDALE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10855 SILVERDALE WAY NW SILVERDALE WA 98383 USA "
    },
    {
      "Location ID": "98392",
      "Location Name": "SUQUAMISH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 18485 AUGUSTA AVE NE SUQUAMISH WA 98392 USA "
    },
    {
      "Location ID": "984",
      "Location Name": "TACOMA WA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4001 SOUTH PINE ST TACOMA WA 98413 USA "
    },
    {
      "Location ID": "985",
      "Location Name": "OLYMPIA P&DF",
      "Time Zone Offset (Hours)": -3,
      "Address": " 717 76TH AVE SW TUMWATER WA 98501 USA "
    },
    {
      "Location ID": "98501",
      "Location Name": "OLYMPIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 900 JEFFERSON ST SE OLYMPIA WA 98501 USA "
    },
    {
      "Location ID": "98502",
      "Location Name": "WESTSIDE STATION",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1200 COOPER POINT RD SW STE 40 OLYMPIA WA 98502 USA "
    },
    {
      "Location ID": "98520",
      "Location Name": "ABERDEEN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 115 N K ST ABERDEEN WA 98520 USA "
    },
    {
      "Location ID": "98522",
      "Location Name": "ADNA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 109 BUNKER CREEK HWY ADNA WA 98522 USA "
    },
    {
      "Location ID": "98530",
      "Location Name": "BUCODA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 108 N MAIN ST BUCODA WA 98530 USA "
    },
    {
      "Location ID": "98531",
      "Location Name": "CENTRALIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 214 CENTRALIA COLLEGE BLVD CENTRALIA WA 98531 USA "
    },
    {
      "Location ID": "98532",
      "Location Name": "CHEHALIS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 225 NW CASCADE AVE CHEHALIS WA 98532 USA "
    },
    {
      "Location ID": "98535",
      "Location Name": "COPALIS BEACH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 731 COPALIS BEACH RD COPALIS BEACH WA 98535 USA "
    },
    {
      "Location ID": "98536",
      "Location Name": "COPALIS CROSSING",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1663 OCEAN BEACH RD COPALIS CROSSING WA 98536 USA "
    },
    {
      "Location ID": "98537",
      "Location Name": "COSMOPOLIS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 114 F ST COSMOPOLIS WA 98537 USA "
    },
    {
      "Location ID": "98538",
      "Location Name": "CURTIS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 388 BOISTFORT RD CURTIS WA 98538 USA "
    },
    {
      "Location ID": "98539",
      "Location Name": "DOTY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 212 STEVENS RD DOTY WA 98539 USA "
    },
    {
      "Location ID": "9853C",
      "Location Name": "CENTRALIA CARRIER ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3615 NORTHPARK DR CENTRALIA WA 98531 USA "
    },
    {
      "Location ID": "98541",
      "Location Name": "ELMA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 204 W YOUNG ST ELMA WA 98541 USA "
    },
    {
      "Location ID": "98544",
      "Location Name": "GALVIN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4810 GALVIN RD GALVIN WA 98544 USA "
    },
    {
      "Location ID": "98547",
      "Location Name": "GRAYLAND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 883 CRANBERRY RD GRAYLAND WA 98547 USA "
    },
    {
      "Location ID": "98550",
      "Location Name": "HOQUIAM",
      "Time Zone Offset (Hours)": -3,
      "Address": " 620 8TH ST HOQUIAM WA 98550 USA "
    },
    {
      "Location ID": "98554",
      "Location Name": "LEBAM",
      "Time Zone Offset (Hours)": -3,
      "Address": " 142 LEBAM RD LEBAM WA 98554 USA "
    },
    {
      "Location ID": "98557",
      "Location Name": "MCCLEARY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 117 S 4TH ST MCCLEARY WA 98557 USA "
    },
    {
      "Location ID": "98561",
      "Location Name": "MENLO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1000 HIGHWAY 6 MENLO WA 98561 USA "
    },
    {
      "Location ID": "98562",
      "Location Name": "MOCLIPS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4758 STATE ROUTE 109 MOCLIPS WA 98562 USA "
    },
    {
      "Location ID": "98563",
      "Location Name": "MONTESANO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 211 W PIONEER AVE MONTESANO WA 98563 USA "
    },
    {
      "Location ID": "98569",
      "Location Name": "OCEAN SHORES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 689 DOLPHIN AVE NE OCEAN SHORES WA 98569 USA "
    },
    {
      "Location ID": "98571",
      "Location Name": "PACIFIC BEACH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12 MAIN ST PACIFIC BEACH WA 98571 USA "
    },
    {
      "Location ID": "98572",
      "Location Name": "PE ELL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 203 N MAIN ST PE ELL WA 98572 USA "
    },
    {
      "Location ID": "98583",
      "Location Name": "SATSOP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 102 S 5TH ST SATSOP WA 98583 USA "
    },
    {
      "Location ID": "98587",
      "Location Name": "TAHOLAH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 142 W QUINAULT ST TAHOLAH WA 98587 USA "
    },
    {
      "Location ID": "98589",
      "Location Name": "TENINO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 124 SUSSEX AVE W TENINO WA 98589 USA "
    },
    {
      "Location ID": "98595",
      "Location Name": "WESTPORT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 400 W OCEAN AVE WESTPORT WA 98595 USA "
    },
    {
      "Location ID": "985SN",
      "Location Name": "STIHL NORTHWEST",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3122 KUPER RD CENTRALIA WA 98531 USA "
    },
    {
      "Location ID": "985SU",
      "Location Name": "SUPLAY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 45 MARION RD ELMA WA 98541 USA "
    },
    {
      "Location ID": "985TS",
      "Location Name": "TUMWATER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 200 ISRAEL RD SE TUMWATER WA 98501 USA "
    },
    {
      "Location ID": "98601",
      "Location Name": "AMBOY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 21501 NE 399TH ST AMBOY WA 98601 USA "
    },
    {
      "Location ID": "98604",
      "Location Name": "BATTLE GROUND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 418 W MAIN ST BATTLE GROUND WA 98604 USA "
    },
    {
      "Location ID": "98606",
      "Location Name": "BRUSH PRAIRIE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12012 NE 150TH CIR BRUSH PRAIRIE WA 98606 USA "
    },
    {
      "Location ID": "98607",
      "Location Name": "CAMAS DCU",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2455 SE 8TH AVE CAMAS WA 98607 USA "
    },
    {
      "Location ID": "98609",
      "Location Name": "CARROLLS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3803 OLD PACIFIC HWY S CARROLLS WA 98609 USA "
    },
    {
      "Location ID": "98611",
      "Location Name": "CASTLE ROCK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 126 COWLITZ ST W CASTLE ROCK WA 98611 USA "
    },
    {
      "Location ID": "98612",
      "Location Name": "CATHLAMET",
      "Time Zone Offset (Hours)": -3,
      "Address": " 30 RIVER ST CATHLAMET WA 98612 USA "
    },
    {
      "Location ID": "98620",
      "Location Name": "GOLDENDALE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1020 S COLUMBUS AVE GOLDENDALE WA 98620 USA "
    },
    {
      "Location ID": "98625",
      "Location Name": "KALAMA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 454 N 1ST ST KALAMA WA 98625 USA "
    },
    {
      "Location ID": "98626",
      "Location Name": "KELSO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 304 ACADEMY ST KELSO WA 98626 USA "
    },
    {
      "Location ID": "98629",
      "Location Name": "LA CENTER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 421 NE JOHN STORM AVE LA CENTER WA 98629 USA "
    },
    {
      "Location ID": "98632",
      "Location Name": "LONGVIEW",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1603 LARCH ST LONGVIEW WA 98632 USA "
    },
    {
      "Location ID": "98638",
      "Location Name": "NASELLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 294 KNAPPTON RD NASELLE WA 98638 USA "
    },
    {
      "Location ID": "98642",
      "Location Name": "RIDGEFIELD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 205 N MAIN AVE RIDGEFIELD WA 98642 USA "
    },
    {
      "Location ID": "98643",
      "Location Name": "ROSBURG",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4182 STATE ROUTE 4 W ROSBURG WA 98643 USA "
    },
    {
      "Location ID": "98647",
      "Location Name": "SKAMOKAWA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1391 W STATE ROUTE 4 SKAMOKAWA WA 98647 USA "
    },
    {
      "Location ID": "98661",
      "Location Name": "VANCOUVER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2700 CAPLES AVE VANCOUVER WA 98661 USA "
    },
    {
      "Location ID": "98671",
      "Location Name": "WASHOUGAL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 129 PENDLETON WAY WASHOUGAL WA 98671 USA "
    },
    {
      "Location ID": "98673",
      "Location Name": "WISHRAM",
      "Time Zone Offset (Hours)": -3,
      "Address": " 521 BRIDGEWAY RD WISHRAM WA 98673 USA "
    },
    {
      "Location ID": "98674",
      "Location Name": "WOODLAND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 190 BOZARTH AVE WOODLAND WA 98674 USA "
    },
    {
      "Location ID": "98675",
      "Location Name": "YACOLT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 555 N AMBOY AVE YACOLT WA 98675 USA "
    },
    {
      "Location ID": "98684",
      "Location Name": "CASCADE PARK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 304 SE HEARTHWOOD BLVD VANCOUVER WA 98684 USA "
    },
    {
      "Location ID": "986CR",
      "Location Name": "CAMAS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 440 NE 5TH AVE CAMAS WA 98607 USA "
    },
    {
      "Location ID": "986EV",
      "Location Name": "EAST VANCOUVER DCU",
      "Time Zone Offset (Hours)": -3,
      "Address": " 401 NE 136TH AVE VANCOUVER WA 98684 USA "
    },
    {
      "Location ID": "986JR",
      "Location Name": "J & R TOOLS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1358 DOWN RIVER DR WOODLAND WA 98674 USA "
    },
    {
      "Location ID": "988",
      "Location Name": "WENATCHEE SCF",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3075 OHME RD WENATCHEE WA 98801 USA "
    },
    {
      "Location ID": "98801",
      "Location Name": "WENATCHEE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1050 MAPLE ST WENATCHEE WA 98801 USA "
    },
    {
      "Location ID": "98812",
      "Location Name": "BREWSTER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 109 N 7TH ST BREWSTER WA 98812 USA "
    },
    {
      "Location ID": "98814",
      "Location Name": "CARLTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2274 US HIGHWAY 153 CARLTON WA 98814 USA "
    },
    {
      "Location ID": "98815",
      "Location Name": "CASHMERE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 121 ELBERTA AVE CASHMERE WA 98815 USA "
    },
    {
      "Location ID": "98816",
      "Location Name": "CHELAN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 144 E JOHNSON AVE CHELAN WA 98816 USA "
    },
    {
      "Location ID": "98821",
      "Location Name": "DRYDEN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6828 DRYDEN AVE DRYDEN WA 98821 USA "
    },
    {
      "Location ID": "98822",
      "Location Name": "ENTIAT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 14108 KINZEL ST ENTIAT WA 98822 USA "
    },
    {
      "Location ID": "98823",
      "Location Name": "EPHRATA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 119 1ST AVE NW EPHRATA WA 98823 USA "
    },
    {
      "Location ID": "98826",
      "Location Name": "LEAVENWORTH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 960 US HIGHWAY 2 LEAVENWORTH WA 98826 USA "
    },
    {
      "Location ID": "98829",
      "Location Name": "MALOTT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 32 B AND O RD MALOTT WA 98829 USA "
    },
    {
      "Location ID": "98834",
      "Location Name": "METHOW",
      "Time Zone Offset (Hours)": -3,
      "Address": " 34 MAIN ST METHOW WA 98834 USA "
    },
    {
      "Location ID": "98836",
      "Location Name": "MONITOR",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3160 MAIN ST MONITOR WA 98836 USA "
    },
    {
      "Location ID": "98837",
      "Location Name": "MOSES LAKE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 223 W 3RD AVE MOSES LAKE WA 98837 USA "
    },
    {
      "Location ID": "98840",
      "Location Name": "OKANOGAN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 212 2ND AVE N OKANOGAN WA 98840 USA "
    },
    {
      "Location ID": "98841",
      "Location Name": "OMAK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 104 S MAIN ST OMAK WA 98841 USA "
    },
    {
      "Location ID": "98844",
      "Location Name": "OROVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1234 IRONWOOD ST OROVILLE WA 98844 USA "
    },
    {
      "Location ID": "98846",
      "Location Name": "PATEROS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 170 PATEROS MALL PATEROS WA 98846 USA "
    },
    {
      "Location ID": "98847",
      "Location Name": "PESHASTIN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10168 MAIN ST PESHASTIN WA 98847 USA "
    },
    {
      "Location ID": "98848",
      "Location Name": "QUINCY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 20 C ST SW QUINCY WA 98848 USA "
    },
    {
      "Location ID": "98849",
      "Location Name": "RIVERSIDE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 407 KENDALL ST RIVERSIDE WA 98849 USA "
    },
    {
      "Location ID": "98855",
      "Location Name": "TONASKET",
      "Time Zone Offset (Hours)": -3,
      "Address": " 15 W 5TH ST TONASKET WA 98855 USA "
    },
    {
      "Location ID": "98856",
      "Location Name": "TWISP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 205 GLOVER ST TWISP WA 98856 USA "
    },
    {
      "Location ID": "98857",
      "Location Name": "WARDEN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 201 E 1ST ST WARDEN WA 98857 USA "
    },
    {
      "Location ID": "98862",
      "Location Name": "WINTHROP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1110 STATE ROUTE 20 WINTHROP WA 98862 USA "
    },
    {
      "Location ID": "989",
      "Location Name": "YAKIMA WA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 205 W WASHINGTON AVE YAKIMA WA 98903 USA "
    },
    {
      "Location ID": "98922",
      "Location Name": "CLE ELUM",
      "Time Zone Offset (Hours)": -3,
      "Address": " 305 E 1ST ST CLE ELUM WA 98922 USA "
    },
    {
      "Location ID": "98925",
      "Location Name": "EASTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2151 RAILROAD ST EASTON WA 98925 USA "
    },
    {
      "Location ID": "98926",
      "Location Name": "ELLENSBURG",
      "Time Zone Offset (Hours)": -3,
      "Address": " 100 E 3RD AVE ELLENSBURG WA 98926 USA "
    },
    {
      "Location ID": "98930",
      "Location Name": "GRANDVIEW",
      "Time Zone Offset (Hours)": -3,
      "Address": " 116 GRANDRIDGE RD GRANDVIEW WA 98930 USA "
    },
    {
      "Location ID": "98934",
      "Location Name": "KITTITAS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 105 S MAIN ST KITTITAS WA 98934 USA "
    },
    {
      "Location ID": "98940",
      "Location Name": "RONALD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8291 STATE ROUTE 903 RONALD WA 98940 USA "
    },
    {
      "Location ID": "98941",
      "Location Name": "ROSLYN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 122 W PENNSYLVANIA AVE ROSLYN WA 98941 USA "
    },
    {
      "Location ID": "98943",
      "Location Name": "SOUTH CLE ELUM",
      "Time Zone Offset (Hours)": -3,
      "Address": " 604 CLEVELAND AVE SOUTH CLE ELUM WA 98943 USA "
    },
    {
      "Location ID": "98944",
      "Location Name": "SUNNYSIDE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 713 E EDISON AVE SUNNYSIDE WA 98944 USA "
    },
    {
      "Location ID": "98946",
      "Location Name": "THORP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 50 1ST ST THORP WA 98946 USA "
    },
    {
      "Location ID": "98948",
      "Location Name": "TOPPENISH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 14 JEFFERSON AVE TOPPENISH WA 98948 USA "
    },
    {
      "Location ID": "98950",
      "Location Name": "VANTAGE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 551 MAIN ST VANTAGE WA 98950 USA "
    },
    {
      "Location ID": "98951",
      "Location Name": "WAPATO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 307 S SATUS AVE WAPATO WA 98951 USA "
    },
    {
      "Location ID": "989AA",
      "Location Name": "SUNNYSIDE CARRIER ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 770 CUSTER AVE SUNNYSIDE WA 98944 USA "
    },
    {
      "Location ID": "98ATO",
      "Location Name": "THS (NORTH BUILDING)",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2330 S 156TH ST STE A SEATAC WA 98158 USA "
    },
    {
      "Location ID": "98Z",
      "Location Name": "LOG SEATTLE NDC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 34301 9TH AVE S FEDERAL WAY WA 98003 USA "
    },
    {
      "Location ID": "990",
      "Location Name": "SPOKANE P&DC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2928 S SPOTTED RD SPOKANE WA 99224 USA "
    },
    {
      "Location ID": "99001",
      "Location Name": "AIRWAY HEIGHTS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1501 S MULLAN RD AIRWAY HEIGHTS WA 99001 USA "
    },
    {
      "Location ID": "99003",
      "Location Name": "CHATTAROY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3616 E CHATTAROY RD CHATTAROY WA 99003 USA "
    },
    {
      "Location ID": "99004",
      "Location Name": "CHENEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 224 2ND ST CHENEY WA 99004 USA "
    },
    {
      "Location ID": "99005",
      "Location Name": "COLBERT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19823 N YALE RD COLBERT WA 99005 USA "
    },
    {
      "Location ID": "99006",
      "Location Name": "DEER PARK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 19 S VERNON AVE DEER PARK WA 99006 USA "
    },
    {
      "Location ID": "99009",
      "Location Name": "ELK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9409 E BRIDGES RD ELK WA 99009 USA "
    },
    {
      "Location ID": "99011",
      "Location Name": "FAIRCHILD AIR FORCE BASE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 100 N CHENNAULT AVE STE 100 FAIRCHILD AIR FORCE BASE WA 99011 USA "
    },
    {
      "Location ID": "99014",
      "Location Name": "FOUR LAKES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10117 S ELECTRIC AVE FOUR LAKES WA 99014 USA "
    },
    {
      "Location ID": "99016",
      "Location Name": "GREENACRES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 306 N BARKER RD SPOKANE VALLEY WA 99016 USA "
    },
    {
      "Location ID": "99019",
      "Location Name": "LIBERTY LAKE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1423 N MOLTER RD LIBERTY LAKE WA 99019 USA "
    },
    {
      "Location ID": "99020",
      "Location Name": "MARSHALL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9823 S MARSHALL AVE MARSHALL WA 99020 USA "
    },
    {
      "Location ID": "99021",
      "Location Name": "MEAD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 11616 N MARKET ST MEAD WA 99021 USA "
    },
    {
      "Location ID": "99022",
      "Location Name": "MEDICAL LAKE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 322 E GRACE ST MEDICAL LAKE WA 99022 USA "
    },
    {
      "Location ID": "99025",
      "Location Name": "NEWMAN LAKE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 25125 E TRENT AVE NEWMAN LAKE WA 99025 USA "
    },
    {
      "Location ID": "99026",
      "Location Name": "NINE MILE FALLS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12522 N NINE MILE RD NINE MILE FALLS WA 99026 USA "
    },
    {
      "Location ID": "99027",
      "Location Name": "OTIS ORCHARDS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 21616 E WELLESLEY AVE OTIS ORCHARDS WA 99027 USA "
    },
    {
      "Location ID": "99029",
      "Location Name": "REARDAN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 155 S LAKE ST REARDAN WA 99029 USA "
    },
    {
      "Location ID": "99030",
      "Location Name": "ROCKFORD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 36 W EMMA ST ROCKFORD WA 99030 USA "
    },
    {
      "Location ID": "99031",
      "Location Name": "SPANGLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 230 N MAIN AVE SPANGLE WA 99031 USA "
    },
    {
      "Location ID": "99036",
      "Location Name": "VALLEYFORD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12809 S MADISON RD VALLEYFORD WA 99036 USA "
    },
    {
      "Location ID": "99037",
      "Location Name": "VERADALE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 15202 E SPRAGUE AVE SPOKANE VALLEY WA 99037 USA "
    },
    {
      "Location ID": "99101",
      "Location Name": "ADDY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1386 MAIN ST ADDY WA 99101 USA "
    },
    {
      "Location ID": "99103",
      "Location Name": "ALMIRA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 102 N 3RD ST ALMIRA WA 99103 USA "
    },
    {
      "Location ID": "99109",
      "Location Name": "CHEWELAH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 202 E MAIN AVE CHEWELAH WA 99109 USA "
    },
    {
      "Location ID": "99110",
      "Location Name": "CLAYTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4486 RAILROAD AVE CLAYTON WA 99110 USA "
    },
    {
      "Location ID": "99111",
      "Location Name": "COLFAX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 211 S MAIN ST COLFAX WA 99111 USA "
    },
    {
      "Location ID": "99113",
      "Location Name": "COLTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 509 STEPTOE ST COLTON WA 99113 USA "
    },
    {
      "Location ID": "99114",
      "Location Name": "COLVILLE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 204 S OAK ST COLVILLE WA 99114 USA "
    },
    {
      "Location ID": "99115",
      "Location Name": "COULEE CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 101 N 4TH ST COULEE CITY WA 99115 USA "
    },
    {
      "Location ID": "99116",
      "Location Name": "COULEE DAM",
      "Time Zone Offset (Hours)": -3,
      "Address": " 402 MEAD WAY COULEE DAM WA 99116 USA "
    },
    {
      "Location ID": "99117",
      "Location Name": "CRESTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 115 SW CRESTON AVE CRESTON WA 99117 USA "
    },
    {
      "Location ID": "99119",
      "Location Name": "CUSICK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 205 S 1ST ST CUSICK WA 99119 USA "
    },
    {
      "Location ID": "99122",
      "Location Name": "DAVENPORT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 402 7TH ST DAVENPORT WA 99122 USA "
    },
    {
      "Location ID": "99123",
      "Location Name": "ELECTRIC CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5 W COULEE BLVD ELECTRIC CITY WA 99123 USA "
    },
    {
      "Location ID": "99124",
      "Location Name": "ELMER CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 16 S FRONT ST ELMER CITY WA 99124 USA "
    },
    {
      "Location ID": "99133",
      "Location Name": "GRAND COULEE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 407 MIDWAY AVE GRAND COULEE WA 99133 USA "
    },
    {
      "Location ID": "99135",
      "Location Name": "HARTLINE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 867 WILLARD ST HARTLINE WA 99135 USA "
    },
    {
      "Location ID": "99139",
      "Location Name": "IONE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 419 MAIN ST IONE WA 99139 USA "
    },
    {
      "Location ID": "99141",
      "Location Name": "KETTLE FALLS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 510 MEYERS ST KETTLE FALLS WA 99141 USA "
    },
    {
      "Location ID": "99146",
      "Location Name": "LAURIER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 27007 N HIGHWAY 395 STE 1 LAURIER WA 99146 USA "
    },
    {
      "Location ID": "99148",
      "Location Name": "LOON LAKE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3992 COLVILLE RD LOON LAKE WA 99148 USA "
    },
    {
      "Location ID": "99149",
      "Location Name": "MALDEN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1 S MAIN ST MALDEN WA 99149 USA "
    },
    {
      "Location ID": "99152",
      "Location Name": "METALINE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 101 N MAIN AVE METALINE WA 99152 USA "
    },
    {
      "Location ID": "99153",
      "Location Name": "METALINE FALLS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 224 E 5TH AVE METALINE FALLS WA 99153 USA "
    },
    {
      "Location ID": "99155",
      "Location Name": "NESPELEM",
      "Time Zone Offset (Hours)": -3,
      "Address": " 100 9TH ST NESPELEM WA 99155 USA "
    },
    {
      "Location ID": "99156",
      "Location Name": "NEWPORT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 100 N WASHINGTON AVE NEWPORT WA 99156 USA "
    },
    {
      "Location ID": "99163",
      "Location Name": "PULLMAN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1135 S GRAND AVE PULLMAN WA 99163 USA "
    },
    {
      "Location ID": "99166",
      "Location Name": "REPUBLIC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 128 N CLARK ST REPUBLIC WA 99166 USA "
    },
    {
      "Location ID": "99170",
      "Location Name": "ROSALIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 511 S WHITMAN AVE ROSALIA WA 99170 USA "
    },
    {
      "Location ID": "99171",
      "Location Name": "SAINT JOHN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5 N PARK ST SAINT JOHN WA 99171 USA "
    },
    {
      "Location ID": "99173",
      "Location Name": "SPRINGDALE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 113 S MAIN ST SPRINGDALE WA 99173 USA "
    },
    {
      "Location ID": "99179",
      "Location Name": "UNIONTOWN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 117 MONTGOMERY ST UNIONTOWN WA 99179 USA "
    },
    {
      "Location ID": "99180",
      "Location Name": "USK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 102 W 5TH ST USK WA 99180 USA "
    },
    {
      "Location ID": "99181",
      "Location Name": "VALLEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3073 3RD AVE VALLEY WA 99181 USA "
    },
    {
      "Location ID": "99185",
      "Location Name": "WILBUR",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5 SW BROADWAY ST WILBUR WA 99185 USA "
    },
    {
      "Location ID": "991PB",
      "Location Name": "PALOUSE BRAND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1120 SE LATAH ST PULLMAN WA 99163 USA "
    },
    {
      "Location ID": "991WS",
      "Location Name": "WSU CREAMERY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 240 DAIRY RD PULLMAN WA 99164 USA "
    },
    {
      "Location ID": "99202",
      "Location Name": "LIBERTY PARK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1602 E SPRAGUE AVE SPOKANE WA 99202 USA "
    },
    {
      "Location ID": "99203",
      "Location Name": "MANITO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3120 S GRAND BLVD SPOKANE WA 99203 USA "
    },
    {
      "Location ID": "99205",
      "Location Name": "SHADLE GARLAND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1903 W GARLAND AVE SPOKANE WA 99205 USA "
    },
    {
      "Location ID": "99207",
      "Location Name": "HAYS PARK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3910 N CRESTLINE ST SPOKANE WA 99207 USA "
    },
    {
      "Location ID": "99208",
      "Location Name": "ROSEWOOD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6325 N MONROE ST SPOKANE WA 99208 USA "
    },
    {
      "Location ID": "99210",
      "Location Name": "RIVERSIDE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 904 W RIVERSIDE AVE STE 109 SPOKANE WA 99201 USA "
    },
    {
      "Location ID": "99211",
      "Location Name": "PARKWATER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2020 N DICKEY RD SPOKANE VALLEY WA 99212 USA "
    },
    {
      "Location ID": "99212",
      "Location Name": "METRO CARRIER ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5511 E ALKI AVE SPOKANE VALLEY WA 99212 USA "
    },
    {
      "Location ID": "99213",
      "Location Name": "DISHMAN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 111 N VISTA RD STE A SPOKANE VALLEY WA 99212 USA "
    },
    {
      "Location ID": "99214",
      "Location Name": "SPOKANE VALLEY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 11712 E SPRAGUE AVE SPOKANE VALLEY WA 99206 USA "
    },
    {
      "Location ID": "99215",
      "Location Name": "TRENTWOOD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 14409 E TRENT AVE SPOKANE VALLEY WA 99216 USA "
    },
    {
      "Location ID": "99216",
      "Location Name": "TRENTWOOD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 14409 E TRENT AVE SPOKANE VALLEY WA 99216 USA "
    },
    {
      "Location ID": "99217",
      "Location Name": "HILLYARD",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4401 N FREYA ST SPOKANE WA 99217 USA "
    },
    {
      "Location ID": "99218",
      "Location Name": "NORTHPOINTE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9857 N NEVADA ST SPOKANE WA 99218 USA "
    },
    {
      "Location ID": "99223",
      "Location Name": "REGAL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5428 S REGAL ST SPOKANE WA 99223 USA "
    },
    {
      "Location ID": "99224",
      "Location Name": "SUNSET HILL",
      "Time Zone Offset (Hours)": -3,
      "Address": "6801 6801 W FLIGHTLINE BLVD SPOKANE WA 99224 USA "
    },
    {
      "Location ID": "992PA",
      "Location Name": "SPOKANE PEAK ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 9322 W HARLAN LN SPOKANE WA 99224 USA "
    },
    {
      "Location ID": "992ST",
      "Location Name": "STOPBOX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 932 N LAKE RD SPOKANE VALLEY WA 99212 USA "
    },
    {
      "Location ID": "993",
      "Location Name": "PASCO ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3500 W COURT ST PASCO WA 99301 USA "
    },
    {
      "Location ID": "99301",
      "Location Name": "PASCO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3500 W COURT ST PASCO WA 99301 USA "
    },
    {
      "Location ID": "99320",
      "Location Name": "BENTON CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 909 DALE AVE BENTON CITY WA 99320 USA "
    },
    {
      "Location ID": "99323",
      "Location Name": "BURBANK",
      "Time Zone Offset (Hours)": -3,
      "Address": " 114 2ND AVE BURBANK WA 99323 USA "
    },
    {
      "Location ID": "99324",
      "Location Name": "COLLEGE PLACE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 500 S COLLEGE AVE COLLEGE PLACE WA 99324 USA "
    },
    {
      "Location ID": "99326",
      "Location Name": "CONNELL",
      "Time Zone Offset (Hours)": -3,
      "Address": " 115 E FRANKLIN ST CONNELL WA 99326 USA "
    },
    {
      "Location ID": "99328",
      "Location Name": "DAYTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 202 S 2ND ST DAYTON WA 99328 USA "
    },
    {
      "Location ID": "99329",
      "Location Name": "DIXIE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10202 E HIGHWAY 12 DIXIE WA 99329 USA "
    },
    {
      "Location ID": "99330",
      "Location Name": "ELTOPIA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 13101 GLADE NORTH RD ELTOPIA WA 99330 USA "
    },
    {
      "Location ID": "99333",
      "Location Name": "HOOPER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 51 HAXTON RD HOOPER WA 99333 USA "
    },
    {
      "Location ID": "99335",
      "Location Name": "KAHLOTUS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 125 E WESTON ST KAHLOTUS WA 99335 USA "
    },
    {
      "Location ID": "99341",
      "Location Name": "LIND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 119 W 2ND AVE LIND WA 99341 USA "
    },
    {
      "Location ID": "99343",
      "Location Name": "MESA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 111 FIRST AVE MESA WA 99343 USA "
    },
    {
      "Location ID": "99344",
      "Location Name": "OTHELLO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 602 E MAIN ST OTHELLO WA 99344 USA "
    },
    {
      "Location ID": "99345",
      "Location Name": "PATERSON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 48801 W PRIOR AVE PATERSON WA 99345 USA "
    },
    {
      "Location ID": "99346",
      "Location Name": "PLYMOUTH",
      "Time Zone Offset (Hours)": -3,
      "Address": " 72304 SE 3RD AVE PLYMOUTH WA 99346 USA "
    },
    {
      "Location ID": "99347",
      "Location Name": "POMEROY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1076 MAIN ST POMEROY WA 99347 USA "
    },
    {
      "Location ID": "99348",
      "Location Name": "PRESCOTT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 106 S D ST PRESCOTT WA 99348 USA "
    },
    {
      "Location ID": "99350",
      "Location Name": "PROSSER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1103 MEADE AVE PROSSER WA 99350 USA "
    },
    {
      "Location ID": "99356",
      "Location Name": "ROOSEVELT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 222 FRONTAGE RD ROOSEVELT WA 99356 USA "
    },
    {
      "Location ID": "99357",
      "Location Name": "ROYAL CITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 132 EVERGREEN AVE NW ROYAL CITY WA 99357 USA "
    },
    {
      "Location ID": "99360",
      "Location Name": "TOUCHET",
      "Time Zone Offset (Hours)": -3,
      "Address": " 69 TOUCHET NORTH RD TOUCHET WA 99360 USA "
    },
    {
      "Location ID": "99361",
      "Location Name": "WAITSBURG",
      "Time Zone Offset (Hours)": -3,
      "Address": " 224 MAIN ST WAITSBURG WA 99361 USA "
    },
    {
      "Location ID": "99362",
      "Location Name": "WALLA WALLA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 128 N 2ND AVE WALLA WALLA WA 99362 USA "
    },
    {
      "Location ID": "99363",
      "Location Name": "WALLULA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 150 N COLUMBIA WAY WALLULA WA 99363 USA "
    },
    {
      "Location ID": "99371",
      "Location Name": "WASHTUCNA COLLECTION BOX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 170 SW MAIN ST WASHTUCNA WA 99371 USA "
    },
    {
      "Location ID": "993LC",
      "Location Name": "LOWDEN COLLECTION BOX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 12020 W HIGHWAY 12 TOUCHET WA 99360 USA "
    },
    {
      "Location ID": "993WC",
      "Location Name": "WASHTUCNA COLLECTION BOX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 170 SW MAIN ST WASHTUCNA WA 99371 USA "
    },
    {
      "Location ID": "993WR",
      "Location Name": "WEST RICHLAND",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4801 W VAN GIESEN ST WEST RICHLAND WA 99353 USA "
    },
    {
      "Location ID": "99402",
      "Location Name": "ASOTIN",
      "Time Zone Offset (Hours)": -3,
      "Address": " 121 2ND ST ASOTIN WA 99402 USA "
    },
    {
      "Location ID": "99403",
      "Location Name": "CLARKSTON",
      "Time Zone Offset (Hours)": -3,
      "Address": " 949 6TH ST CLARKSTON WA 99403 USA "
    },
    {
      "Location ID": "995",
      "Location Name": "ANCHORAGE P&DC",
      "Time Zone Offset (Hours)": -5,
      "Address": " 4141 POSTMARK DR ANCHORAGE AK 99530 USA "
    },
    {
      "Location ID": "99501",
      "Location Name": "EASTCHESTER",
      "Time Zone Offset (Hours)": -5,
      "Address": " 800 INGRA ST ANCHORAGE AK 99501 USA "
    },
    {
      "Location ID": "99503",
      "Location Name": "MIDTOWN",
      "Time Zone Offset (Hours)": -5,
      "Address": " 3721 B ST ANCHORAGE AK 99503 USA "
    },
    {
      "Location ID": "99504",
      "Location Name": "MULDOON",
      "Time Zone Offset (Hours)": -5,
      "Address": " 2420 MULDOON RD ANCHORAGE AK 99504 USA "
    },
    {
      "Location ID": "99505",
      "Location Name": "FORT RICHARDSON",
      "Time Zone Offset (Hours)": -5,
      "Address": " 724 QUARTERMASTER RD JBER AK 99505 USA "
    },
    {
      "Location ID": "99506",
      "Location Name": "ELMENDORF AFB",
      "Time Zone Offset (Hours)": -5,
      "Address": " 10437 KUTER AVE JBER AK 99506 USA "
    },
    {
      "Location ID": "99507",
      "Location Name": "LAKE OTIS",
      "Time Zone Offset (Hours)": -5,
      "Address": " 5855 LAKE OTIS PKWY ANCHORAGE AK 99507 USA "
    },
    {
      "Location ID": "99508",
      "Location Name": "RUSSIAN JACK",
      "Time Zone Offset (Hours)": -5,
      "Address": " 801 NORTHWAY DR ANCHORAGE AK 99508 USA "
    },
    {
      "Location ID": "99511",
      "Location Name": "HUFFMAN STATION",
      "Time Zone Offset (Hours)": -5,
      "Address": " 1221 HUFFMAN PARK DRIVE ANCHORAGE AK 99511 USA "
    },
    {
      "Location ID": "99513",
      "Location Name": "FEDERAL BLDG",
      "Time Zone Offset (Hours)": -5,
      "Address": " 222 W 7TH AVE ANCHORAGE AK 99513 USA "
    },
    {
      "Location ID": "99515",
      "Location Name": "HUFFMAN",
      "Time Zone Offset (Hours)": -5,
      "Address": " 1221 HUFFMAN PARK DR ANCHORAGE AK 99515 USA "
    },
    {
      "Location ID": "99517",
      "Location Name": "SPENARD",
      "Time Zone Offset (Hours)": -5,
      "Address": " 1601 W NORTHERN LIGHTS BLVD ANCHORAGE AK 99517 USA "
    },
    {
      "Location ID": "99522",
      "Location Name": "SAND LAKE",
      "Time Zone Offset (Hours)": -5,
      "Address": " 2200 STRAWBERRY RD ANCHORAGE AK 99502 USA "
    },
    {
      "Location ID": "99525",
      "Location Name": "POSTAL STORE",
      "Time Zone Offset (Hours)": -5,
      "Address": " 320 W 5TH AVE STE 348 ANCHORAGE AK 99501 USA "
    },
    {
      "Location ID": "99556",
      "Location Name": "ANCHOR POINT",
      "Time Zone Offset (Hours)": -5,
      "Address": " 33790 STERLING HWY ANCHOR POINT AK 99556 USA "
    },
    {
      "Location ID": "99567",
      "Location Name": "CHUGIAK",
      "Time Zone Offset (Hours)": -5,
      "Address": " 22255 DOGSLED CT CHUGIAK AK 99567 USA "
    },
    {
      "Location ID": "99568",
      "Location Name": "CLAM GULCH",
      "Time Zone Offset (Hours)": -5,
      "Address": " 65790 STERLING HWY CLAM GULCH AK 99568 USA "
    },
    {
      "Location ID": "99572",
      "Location Name": "COOPER LANDING",
      "Time Zone Offset (Hours)": -5,
      "Address": " 38741 SNUG HARBOR RD COOPER LANDING AK 99572 USA "
    },
    {
      "Location ID": "99573",
      "Location Name": "COPPER CENTER",
      "Time Zone Offset (Hours)": -5,
      "Address": " 101.5 OLD RICHARDSON HWY COPPER CENTER AK 99573 USA "
    },
    {
      "Location ID": "99577",
      "Location Name": "EAGLE RIVER",
      "Time Zone Offset (Hours)": -5,
      "Address": " 11471 BUSINESS BLVD EAGLE RIVER AK 99577 USA "
    },
    {
      "Location ID": "99586",
      "Location Name": "GAKONA",
      "Time Zone Offset (Hours)": -5,
      "Address": " 2.5 TOK CUTOFF GAKONA AK 99586 USA "
    },
    {
      "Location ID": "99587",
      "Location Name": "GIRDWOOD",
      "Time Zone Offset (Hours)": -5,
      "Address": " 118 LINDBLAD AVE GIRDWOOD AK 99587 USA "
    },
    {
      "Location ID": "99588",
      "Location Name": "GLENNALLEN",
      "Time Zone Offset (Hours)": -5,
      "Address": " 9998 AURORA DR GLENNALLEN AK 99588 USA "
    },
    {
      "Location ID": "99603",
      "Location Name": "HOMER",
      "Time Zone Offset (Hours)": -5,
      "Address": " 3658 HEATH ST HOMER AK 99603 USA "
    },
    {
      "Location ID": "99610",
      "Location Name": "KASILOF",
      "Time Zone Offset (Hours)": -5,
      "Address": " 23758 KALIFORNSKY BEACH RD KASILOF AK 99610 USA "
    },
    {
      "Location ID": "99611",
      "Location Name": "KENAI",
      "Time Zone Offset (Hours)": -5,
      "Address": " 140 BIDARKA ST KENAI AK 99611 USA "
    },
    {
      "Location ID": "99623",
      "Location Name": "CPU MEADOW LAKES",
      "Time Zone Offset (Hours)": -5,
      "Address": " 745 S RAINBOW ST WASILLA AK 99623 USA "
    },
    {
      "Location ID": "99631",
      "Location Name": "MOOSE PASS",
      "Time Zone Offset (Hours)": -5,
      "Address": " 31785 POST OFFICE DR MOOSE PASS AK 99631 USA "
    },
    {
      "Location ID": "99635",
      "Location Name": "NIKISKI",
      "Time Zone Offset (Hours)": -5,
      "Address": " 51708 KENAI SPUR HWY STE B NIKISKI AK 99635 USA "
    },
    {
      "Location ID": "99639",
      "Location Name": "NINILCHIK",
      "Time Zone Offset (Hours)": -5,
      "Address": " 15700 KINGSLEY RD NINILCHIK AK 99639 USA "
    },
    {
      "Location ID": "99645",
      "Location Name": "PALMER",
      "Time Zone Offset (Hours)": -5,
      "Address": " 500 S COBB ST PALMER AK 99645 USA "
    },
    {
      "Location ID": "99652",
      "Location Name": "CPU BIG LAKE",
      "Time Zone Offset (Hours)": -5,
      "Address": " 2809 BIG LAKE RD BIG LAKE AK 99652 USA "
    },
    {
      "Location ID": "99654",
      "Location Name": "WASILLA ANNEX",
      "Time Zone Offset (Hours)": -5,
      "Address": " 626 N BOBBY DAY CIR WASILLA AK 99654 USA "
    },
    {
      "Location ID": "99664",
      "Location Name": "SEWARD",
      "Time Zone Offset (Hours)": -5,
      "Address": " 507 MADISON AVE SEWARD AK 99664 USA "
    },
    {
      "Location ID": "99669",
      "Location Name": "SOLDOTNA",
      "Time Zone Offset (Hours)": -5,
      "Address": " 175 N BINKLEY ST SOLDOTNA AK 99669 USA "
    },
    {
      "Location ID": "99672",
      "Location Name": "STERLING",
      "Time Zone Offset (Hours)": -5,
      "Address": " 38161 MIDWAY DR STERLING AK 99672 USA "
    },
    {
      "Location ID": "99674",
      "Location Name": "SUTTON",
      "Time Zone Offset (Hours)": -5,
      "Address": " 11266 N CHICKALOON WAY SUTTON AK 99674 USA "
    },
    {
      "Location ID": "99676",
      "Location Name": "TALKEETNA",
      "Time Zone Offset (Hours)": -5,
      "Address": " 13707 E 1ST ST TALKEETNA AK 99676 USA "
    },
    {
      "Location ID": "9967C",
      "Location Name": "CHICKALOON",
      "Time Zone Offset (Hours)": -5,
      "Address": " 33968 N GLENN HWY SUTTON AK 99674 USA "
    },
    {
      "Location ID": "99686",
      "Location Name": "VALDEZ",
      "Time Zone Offset (Hours)": -5,
      "Address": " 221 TATITLEK ST VALDEZ AK 99686 USA "
    },
    {
      "Location ID": "99687",
      "Location Name": "WASILLA",
      "Time Zone Offset (Hours)": -5,
      "Address": " 401 N MAIN ST WASILLA AK 99654 USA "
    },
    {
      "Location ID": "99688",
      "Location Name": "WILLOW",
      "Time Zone Offset (Hours)": -5,
      "Address": " 13232 N MCKINLEY WAY WILLOW AK 99688 USA "
    },
    {
      "Location ID": "99693",
      "Location Name": "CPU WHITTIER",
      "Time Zone Offset (Hours)": -5,
      "Address": " 9693 KENAI ST WHITTIER AK 99693 USA "
    },
    {
      "Location ID": "99694",
      "Location Name": "HOUSTON",
      "Time Zone Offset (Hours)": -5,
      "Address": " 17152 W PARKS HWY HOUSTON AK 99694 USA "
    },
    {
      "Location ID": "996BW",
      "Location Name": "CPU BRENTWOOD",
      "Time Zone Offset (Hours)": -5,
      "Address": " 5050 E DUNBAR DR STE G WASILLA AK 99654 USA "
    },
    {
      "Location ID": "996FC",
      "Location Name": "FRITZ CREEK",
      "Time Zone Offset (Hours)": -5,
      "Address": " 55770 E END RD HOMER AK 99603 USA "
    },
    {
      "Location ID": "996KN",
      "Location Name": "CPU KNIK",
      "Time Zone Offset (Hours)": -5,
      "Address": " 5715 S SETTLERS BAY DR WASILLA AK 99654 USA "
    },
    {
      "Location ID": "99702",
      "Location Name": "EIELSON AFB",
      "Time Zone Offset (Hours)": -5,
      "Address": " 365 KODIAK ST EIELSON AFB AK 99702 USA "
    },
    {
      "Location ID": "99703",
      "Location Name": "FORT WAINWRIGHT",
      "Time Zone Offset (Hours)": -5,
      "Address": " 3726 NEELY RD FORT WAINWRIGHT AK 99703 USA "
    },
    {
      "Location ID": "99705",
      "Location Name": "NORTH POLE",
      "Time Zone Offset (Hours)": -5,
      "Address": " 325 S SANTA CLAUS LN NORTH POLE AK 99705 USA "
    },
    {
      "Location ID": "99707",
      "Location Name": "FAIRBANKS DOWNTOWN STA",
      "Time Zone Offset (Hours)": -5,
      "Address": " 315 BARNETTE ST FAIRBANKS AK 99701 USA "
    },
    {
      "Location ID": "99708",
      "Location Name": "COLLEGE",
      "Time Zone Offset (Hours)": -5,
      "Address": " 755 FAIRBANKS ST FAIRBANKS AK 99709 USA "
    },
    {
      "Location ID": "99709",
      "Location Name": "FAIRBANKS MAIN OFFICE",
      "Time Zone Offset (Hours)": -5,
      "Address": " 5400 MAIL TRAIL RD FAIRBANKS AK 99709 USA "
    },
    {
      "Location ID": "997BA",
      "Location Name": "BADGER",
      "Time Zone Offset (Hours)": -5,
      "Address": " 771  BADGER RD NORTH POLE AK 99705 USA "
    },
    {
      "Location ID": "997CF",
      "Location Name": "CPU COLDFOOT",
      "Time Zone Offset (Hours)": -5,
      "Address": " 9000 DALTON HWY FAIRBANKS AK 99709 USA "
    },
    {
      "Location ID": "997ST",
      "Location Name": "CPU STEESE",
      "Time Zone Offset (Hours)": -5,
      "Address": " 646 ROHLOFF ST FAIRBANKS AK 99712 USA "
    },
    {
      "Location ID": "998",
      "Location Name": "JUNEAU",
      "Time Zone Offset (Hours)": -5,
      "Address": " 9491 VINTAGE BLVD JUNEAU AK 99801 USA "
    },
    {
      "Location ID": "99801",
      "Location Name": "FEDERAL STATION",
      "Time Zone Offset (Hours)": -5,
      "Address": " 709 W 9TH ST JUNEAU AK 99801 USA "
    },
    {
      "Location ID": "99821",
      "Location Name": "AUKE BAY",
      "Time Zone Offset (Hours)": -5,
      "Address": " 11899 GLACIER AUKE BAY AK 99821 USA "
    },
    {
      "Location ID": "99824",
      "Location Name": "DOUGLAS",
      "Time Zone Offset (Hours)": -5,
      "Address": " 804 3RD ST DOUGLAS AK 99824 USA "
    },
    {
      "Location ID": "99MTE",
      "Location Name": "HLG - SEATTLE MTESC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2402 R ST NW AUBURN WA 98001 USA "
    },
    {
      "Location ID": "ABEFX",
      "Location Name": "ABEFX FEDEX RAMP ABE AIRPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 951 POSTAL ROAD ALLENTOWN PA 18109 USA "
    },
    {
      "Location ID": "ABQ5X",
      "Location Name": "UPS - PEAK SUPPLY CHAIN SOLUTION",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3720 SPIRIT DR SE ALBUQUERQUE NM 87106 USA "
    },
    {
      "Location ID": "ABQFX",
      "Location Name": "FEDEX RAMP",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3720 SPIRIT DR SE ALBUQUERQUE NM 87106 USA "
    },
    {
      "Location ID": "ABQTH",
      "Location Name": "THS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3720 SPIRIT DR SE ALBUQUERQUE NM 87106 USA "
    },
    {
      "Location ID": "ABQUP",
      "Location Name": "ALBUQUERQUE GATEWAY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3720 SPIRIT DR SE ALBUQUERQUE NM 87106 USA "
    },
    {
      "Location ID": "ACK",
      "Location Name": "NANTUCKET AIRPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14 AIRPORT ROAD NANTUCKET MA 02554 USA "
    },
    {
      "Location ID": "AMA",
      "Location Name": "AMARILLO AIRPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10110 AIRPORT BLVD AMARILLO TX 79111 USA "
    },
    {
      "Location ID": "ANC",
      "Location Name": "LOG ANCHORAGE AMF",
      "Time Zone Offset (Hours)": -5,
      "Address": " 4101 POSTMARK DR ANCHORAGE AK 99529 USA "
    },
    {
      "Location ID": "ANCTH",
      "Location Name": "ANCHORAGE THS",
      "Time Zone Offset (Hours)": -5,
      "Address": " 3501 POSTMARK DR ANCHORAGE AK 99502 USA "
    },
    {
      "Location ID": "APL00",
      "Location Name": "MOUSER ELECTRONICS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1000 N MAIN ST MANSFIELD TX 76063 USA "
    },
    {
      "Location ID": "APL01",
      "Location Name": "EAST FORT WORTH SERVICE AREA, TX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 ANYWHERE FORT WORTH TX 76112 USA "
    },
    {
      "Location ID": "APL14",
      "Location Name": "MONTICELLO STATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5219 MONTICELLO STATION WILLIAMSBURG VA 23188 USA "
    },
    {
      "Location ID": "APL15",
      "Location Name": "LA TIENDA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3601 LA GRANGE PKWY TOANO VA 23168 USA "
    },
    {
      "Location ID": "APL16",
      "Location Name": "FORT LEE BRANCH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1400 MAHONE AVE FORT LEE VA 23801 USA "
    },
    {
      "Location ID": "APL17",
      "Location Name": "WALNUT HILL (PETERSBURG)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3131 S CRATER RD PETERSBURG VA 23805 USA "
    },
    {
      "Location ID": "APL22",
      "Location Name": "ACADEMY ST STA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 205 S ACADEMY ST CARY NC 27519 USA "
    },
    {
      "Location ID": "APL23",
      "Location Name": "EDWARDS BROS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 800 EDWARDS DR LILLINGTON NC 27546 USA "
    },
    {
      "Location ID": "APL25",
      "Location Name": "MASON SHOE 1ST AVE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1313 1ST AVE CHIPPEWA FALLS WI 54729 USA "
    },
    {
      "Location ID": "APL26",
      "Location Name": "DEADHEAD POINT, MN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 ANYWHERE MINNEAPOLIS MN 55403 USA "
    },
    {
      "Location ID": "APL30",
      "Location Name": "VARIOUS MAILERS - LINCOLN AREA, NE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 ANYWHERE LINCOLN NE 68508 USA "
    },
    {
      "Location ID": "APL31",
      "Location Name": "WEST FORT WORTH SERVICE AREA, TX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 123 ANYWHERE FORT WORTH TX 76114 USA "
    },
    {
      "Location ID": "ATL",
      "Location Name": "PEACHTREE P&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2310 MAYNARD H JACKSON JR BLVD ATLANTA GA 30320 USA "
    },
    {
      "Location ID": "ATLTH",
      "Location Name": "MATHESON - ATL THS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 795 ATLANTA SOUTH PKWY STE 100 COLLEGE PARK GA 30349 USA "
    },
    {
      "Location ID": "ATLUP",
      "Location Name": "UPS - ATL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 795 ATLANTA SOUTH PKWY STE 700 ATLANTA GA 30349 USA "
    },
    {
      "Location ID": "ATMTE",
      "Location Name": "PAE ATLANTA MTESC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4300 S FULTON PKWY ATLANTA GA 30349 USA "
    },
    {
      "Location ID": "ATWFX",
      "Location Name": "OUTAGAMIE AIRPORT (FEDEX)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6322 ATLANTIS DRIVE W APPLETON WI 54914 USA "
    },
    {
      "Location ID": "AUSAA",
      "Location Name": "AMERICAN CARGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3400 SPIRIT OF TEXAS DR STE 290 AUSTIN TX 78719 USA "
    },
    {
      "Location ID": "AUSAK",
      "Location Name": "ALASKA CARGO, TX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9400 FREIGHT LN STE C AUSTIN TX 78719 USA "
    },
    {
      "Location ID": "AUSCO",
      "Location Name": "CONTINENTAL CARGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3400 SPIRIT OF TEXAS DR STE 300 AUSTIN TX 78719 USA "
    },
    {
      "Location ID": "AUSDL",
      "Location Name": "DELTA CARGO, TX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3400 SPIRIT OF TEXAS DR STE 160 AUSTIN TX 78719 USA "
    },
    {
      "Location ID": "AUSFX",
      "Location Name": "FEDEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9301 CARGO AVE AUSTIN TX 78719 USA "
    },
    {
      "Location ID": "AUSTH",
      "Location Name": "INTEGRATED",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9400 FREIGHT LN STE D AUSTIN TX 78719 USA "
    },
    {
      "Location ID": "AUSUA",
      "Location Name": "UNITED AIRLINES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3400 SPIRIT OF TEXAS DR STE 100 AUSTIN TX 78719 USA "
    },
    {
      "Location ID": "AUSWN",
      "Location Name": "SOUTHWEST AIRLINES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3400 SPIRIT OF TEXAS DRIVE AUSTIN TX 78719 USA "
    },
    {
      "Location ID": "AVX",
      "Location Name": "AVALON AIRPORT",
      "Time Zone Offset (Hours)": -3,
      "Address": " SANTA CATALINA ISLAND AVALON CA 90704 USA "
    },
    {
      "Location ID": "BDL",
      "Location Name": "BRADLEY AMF CT 06199",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10 SCHOEPHOESTER RD WINDSOR LOCKS CT 06199 USA "
    },
    {
      "Location ID": "BDL5X",
      "Location Name": "QUANTUM AVIATION - BDL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 67 NICHOLSON RD EAST GRANBY CT 06026 USA "
    },
    {
      "Location ID": "BDLAA",
      "Location Name": "PROSEGUR SERVICE GROUP - AMERICAN AIRLINES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 33 NICHOLSON RD EAST GRANBY CT 06026 USA "
    },
    {
      "Location ID": "BDLTH",
      "Location Name": "MATHESON - BDL THS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 US AIRPORT WASY E. GANBY CT 06026 USA "
    },
    {
      "Location ID": "BDLTS",
      "Location Name": "TOTAL AIRPORT SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 US AIRPORTS WAY EAST GRANBY CT 06026 USA "
    },
    {
      "Location ID": "BDLUP",
      "Location Name": "BDL UPS FREIGHT BLDG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 INSURANCE LN(HANGER 85-179) WINDSOR LOCKS CT 06096 USA "
    },
    {
      "Location ID": "BDTHS",
      "Location Name": "BDL THS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 300 US AIRPORTS WAY BLDG 300 EAST GRANBY CT 06096 USA "
    },
    {
      "Location ID": "BGRFX",
      "Location Name": "FED EX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 124 BANAIR RD BANGOR ME 04401 USA "
    },
    {
      "Location ID": "BHMFX",
      "Location Name": "BHMFX FEDEX RAMP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1710-40TH ST NORTH BIRMINGHAM AL 35217 USA "
    },
    {
      "Location ID": "BIL",
      "Location Name": "BILLINGS AIR TRANSFER OFFICE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1761 AVIATION PL BILLINGS MT 59105 USA "
    },
    {
      "Location ID": "BILTH",
      "Location Name": "MATHESON - BIL THS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1761 AVIATON PLACE BILLINGS MT 59105 USA "
    },
    {
      "Location ID": "BIS",
      "Location Name": "BISMARCK AIRPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2301 UNIVERSITY DR BISMARCK ND 58504 USA "
    },
    {
      "Location ID": "BMIFX",
      "Location Name": "BMIFX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3027 CIRA DRIVE BLOOMINGTON IL 61704 USA "
    },
    {
      "Location ID": "BNA",
      "Location Name": "NASHVILLE PRIORITY ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 935 AIRPORT SERVICE RD NASHVILLE TN 37227 USA "
    },
    {
      "Location ID": "BOI",
      "Location Name": "AIRPORT MAIL FACILITY",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2625 APOLLO ST BOISE ID 83705 USA "
    },
    {
      "Location ID": "BOITH",
      "Location Name": "BOISE THS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2625 W APOLLO ST BOISE ID 83715 USA "
    },
    {
      "Location ID": "BOS",
      "Location Name": "BOSTON THS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 440 MCCLELLAN HIGHWAY BOSTON MA 02128 USA "
    },
    {
      "Location ID": "BOSDL",
      "Location Name": "DELTA CARGO LOGAN APT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 152 HARBORSIDE DR BLDG 56 BOSTON MA 02128 USA "
    },
    {
      "Location ID": "BOSFX",
      "Location Name": "BOSFX BOS FEDEX RAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 142 HARBORSIDE DR BOSTON MA 02128 USA "
    },
    {
      "Location ID": "BOSUA",
      "Location Name": "BOS UA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 112 HARBORSIDE DR BLDG 63 BOSTON MA 02128 USA "
    },
    {
      "Location ID": "BOSUS",
      "Location Name": "BOS US",
      "Time Zone Offset (Hours)": 0,
      "Address": " 132 HARBORSIDE DR BLDG 58 BOSTON MA 02128 USA "
    },
    {
      "Location ID": "BTRTH",
      "Location Name": "BATON ROUGE THS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9316 JACKIE COCHRAN DR BATON ROUGE LA 70807 USA "
    },
    {
      "Location ID": "BTVFX",
      "Location Name": "FED-EX (BTV)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 921 MARSHALL AVENUE WILLISTON VT 05495 USA "
    },
    {
      "Location ID": "BUF",
      "Location Name": "BUFFALO AMF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 285 CAYUGA RD BUFFALO NY 14241 USA "
    },
    {
      "Location ID": "BUFFX",
      "Location Name": "BUFFX BUF FEDEX RAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 299 CAYUGA RD BUFFALO NY 14225 USA "
    },
    {
      "Location ID": "BUR",
      "Location Name": "BURBANK AIRPORT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2627 N HOLLYWOOD WAY BURBANK CA 91505 USA "
    },
    {
      "Location ID": "BWIAA",
      "Location Name": "WORLDWIDE FLIGHT SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1005 AVIATION BLVD # 9 BALTIMORE MD 21240 USA "
    },
    {
      "Location ID": "BWIDC",
      "Location Name": "DELTA CARGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1005 AIR CARGO DR BLDG B27 BALTIMORE MD 21240 USA "
    },
    {
      "Location ID": "BWITH",
      "Location Name": "METRO AIR THS - BWI THS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 852 INTERNATIONAL DRIVE LINTHICUM HEIGHTS MD 21090 USA "
    },
    {
      "Location ID": "BWIUP",
      "Location Name": "BWI UPS FREIGHT BUILDING",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1003 AIR CARGO DR BLDG D 46 BALTIMORE MD 21240 USA "
    },
    {
      "Location ID": "CAEFX",
      "Location Name": "CAEFX FEDEX RAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3525 AIR COMMERCE DR WEST COLUMBIA SC 29170 USA "
    },
    {
      "Location ID": "CHA",
      "Location Name": "CHATTANOOGA APT TN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1001 AIRPORT ROAD CHATTANOOGA TN 37421 USA "
    },
    {
      "Location ID": "CID",
      "Location Name": "CEDAR RAPIDS AIR MAIL ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4515 20TH AVE SW CEDAR RAPIDS IA 52404 USA "
    },
    {
      "Location ID": "CIDFX",
      "Location Name": "CEDAR RAPIDS AMF (FDX)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3601 WRIGHT BROTHERS BLVD W CEDAR RAPIDS IA 52404 USA "
    },
    {
      "Location ID": "CLEFX",
      "Location Name": "CLEFX FEDEX RAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5701 POSTAL RD CLEVELAND OH 44135 USA "
    },
    {
      "Location ID": "CLETH",
      "Location Name": "CLEVELAND THS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5801 POSTAL RD STE 220 CLEVELAND OH 44135 USA "
    },
    {
      "Location ID": "CLTAG",
      "Location Name": "AIR GENERAL (US & AA GH)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4706 YORKMONT RD CHARLOTTE KY 28208 USA "
    },
    {
      "Location ID": "CLTCS",
      "Location Name": "CLT UPS CARTAGE SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4100 YORKMONT RD CHARLOTTE NC 28208 USA "
    },
    {
      "Location ID": "CLTTH",
      "Location Name": "CHARLOTTE THS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3628-A YORKMONT AVE CHARLOTTE NC 28219 USA "
    },
    {
      "Location ID": "CMHCS",
      "Location Name": "UPS CARGAGE SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7280 ALUM CREEK DR STE L COLUMBUS OH 43228 USA "
    },
    {
      "Location ID": "CMHFX",
      "Location Name": "FEDEX RAMP-LOCKBOURN",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7066 CARGO ROAD COLUMBUS OH 43217 USA "
    },
    {
      "Location ID": "CMHWF",
      "Location Name": "GAT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4760 EAST 5TH AVE STE A COLUMBUS OH 43219 USA "
    },
    {
      "Location ID": "CMHWN",
      "Location Name": "SOUTHWEST AIRLINES CARGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4750 EAST 5TH AVE COLUMBUS OH 43219 USA "
    },
    {
      "Location ID": "COSFX",
      "Location Name": "FEDEX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1245 AVIATION WAY STE A COLORADO SPRINGS CO 80916 USA "
    },
    {
      "Location ID": "CPX",
      "Location Name": "CULEBRA PR APT",
      "Time Zone Offset (Hours)": 0,
      "Address": " PO BOX 157-0 PUERTO REAL ISLA DE CULEBRA PR 00648 USA "
    },
    {
      "Location ID": "CRWTH",
      "Location Name": "THS BLDG YEAGER AIRPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1406 BIGLEY AVE CHARLESTON WV 25302 USA "
    },
    {
      "Location ID": "CVGFX",
      "Location Name": "CVGFX ERLANGER FEDEX RAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3403 LOGAN RD HEBRON KY 41048 USA "
    },
    {
      "Location ID": "CVGTH",
      "Location Name": "MATHESON FLIGHT EXTENDERS CVG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 76 CLAY DR HEBRON KY 41048 USA "
    },
    {
      "Location ID": "DEN",
      "Location Name": "DENVER AIRPORT MAIL CENTER",
      "Time Zone Offset (Hours)": -2,
      "Address": " 25630 E 75TH AVE DENVER CO 80249 USA "
    },
    {
      "Location ID": "DENFX",
      "Location Name": "DENFX FEDEX RAMP",
      "Time Zone Offset (Hours)": -2,
      "Address": " 26900 E 75TH AVE DENVER CO 80249 USA "
    },
    {
      "Location ID": "DENIA",
      "Location Name": "INTEGRATED AIRLINE SERVICE",
      "Time Zone Offset (Hours)": -2,
      "Address": " 26200 E 75TH AVE BLDG B DENVER CO 80249 USA "
    },
    {
      "Location ID": "DENTH",
      "Location Name": "MATHESON - DEN THS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 25630 E 75TH AVE DENVER CO 80249 USA "
    },
    {
      "Location ID": "DFWAA",
      "Location Name": "DFW AMERICAN MAIL SORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2400 E 16TH ST DFW AIRPORT TX 75261 USA "
    },
    {
      "Location ID": "DFWTH",
      "Location Name": "MATHESON FLIGHT EXTENDERS DFW",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3193 BASS PRO DR GRAPEVINE TX 76051 USA "
    },
    {
      "Location ID": "DFWUA",
      "Location Name": "UNITED AIRLINES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2417 N SUPPORT RD DALLAS TX 75261 USA "
    },
    {
      "Location ID": "DFWUP",
      "Location Name": "UPS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1840 W AIRFIELD DR DALLAS TX 75261 USA "
    },
    {
      "Location ID": "DFWWF",
      "Location Name": "WORLDWIDE FREIGHT SERVICES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1639 W 23RD ST STE 200 DALLAS TX 75261 USA "
    },
    {
      "Location ID": "DSM",
      "Location Name": "INVALID FACILITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1400 SE GATEWAY DR GRIMES IA 50111 USA "
    },
    {
      "Location ID": "DSMFX",
      "Location Name": "DSMFX DES MOINES FEDEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3023 ARMY POST RD DES MOINES IA 50321 USA "
    },
    {
      "Location ID": "DSMTH",
      "Location Name": "DES MOINES THS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3025 ARMY POST RD DES MOINES IA 50321 USA "
    },
    {
      "Location ID": "DTWAA",
      "Location Name": "AIR GENERAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 614 E SERVICE DR DETROIT MI 48242 USA "
    },
    {
      "Location ID": "DTWCS",
      "Location Name": "DTW UPS CSI",
      "Time Zone Offset (Hours)": 0,
      "Address": " 10725 HARRISON ROAD ROMULUS MI 48174 USA "
    },
    {
      "Location ID": "DTWDL",
      "Location Name": "DELTA CARGO, DTW FACILITY",
      "Time Zone Offset (Hours)": 0,
      "Address": " 536 E SERVICE DR # 536 DETROIT MI 48242 USA "
    },
    {
      "Location ID": "DTWFX",
      "Location Name": "DTWFX FEDEX RAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 723 W SERVICE DR DETROIT MI 48242 USA "
    },
    {
      "Location ID": "DTWTH",
      "Location Name": "METRO AIR - DTW THS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 27100 TROLLEY INDUSTRIAL DR TAYLOR MI 48180 USA "
    },
    {
      "Location ID": "EAT",
      "Location Name": "WENATCHEE",
      "Time Zone Offset (Hours)": -3,
      "Address": " ONE PANGBORN DRIVE WENATCHEE WA 98802 USA "
    },
    {
      "Location ID": "ELMFX",
      "Location Name": "FEDEX (ELMIRA AIRPORT)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 358 SING SING RD HORSEHEADS NY 14845 USA "
    },
    {
      "Location ID": "ELPFX",
      "Location Name": "FEDEX EL PASO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 501 GEORGE PERRY BLVD STE A EL PASO TX 79925-4432 USA "
    },
    {
      "Location ID": "ELPTH",
      "Location Name": "CONSOLIDATED AIRLINE SERVICES",
      "Time Zone Offset (Hours)": -2,
      "Address": " 301 GEORGE PERRY BLVD STE C EL PASO TX 79925-4432 USA "
    },
    {
      "Location ID": "ELPWN",
      "Location Name": "SOUTHWEST AIRLINES CARGO - EL PASO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 6435 CONVAIR RD EL PASO TX 79925 USA "
    },
    {
      "Location ID": "EWRAA",
      "Location Name": "AGI - AA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 340-1 AIRIS DR NEWARK NJ 07114 USA "
    },
    {
      "Location ID": "EWRAS",
      "Location Name": "ALASKA AIRLINES - EWR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 339-2 AIRIS DR NEWARK NJ 07114 USA "
    },
    {
      "Location ID": "EWRDL",
      "Location Name": "AGI - DL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 339-2 AIRIS DR NEWARK NJ 07114 USA "
    },
    {
      "Location ID": "EWREM",
      "Location Name": "FEDEX NIGHT-TURN",
      "Time Zone Offset (Hours)": 0,
      "Address": " BLDGS 155/156 EARHART DR NEWARK NJ 07114 USA "
    },
    {
      "Location ID": "EWRFX",
      "Location Name": "FEDEX RAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " EWR METROPLEX BLDG 347 NEWARK NJ 07114 USA "
    },
    {
      "Location ID": "EWRTH",
      "Location Name": "CARGO FORCE NEWARK THS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 120 CENTRAL AVE KEARNY NJ 07032 USA "
    },
    {
      "Location ID": "EWRUA",
      "Location Name": "TOTAL AIRPORT SVCS US",
      "Time Zone Offset (Hours)": 0,
      "Address": " 340-2 AIRIS DR  BAYS 15-31 NEWARK NJ 07114 USA "
    },
    {
      "Location ID": "EWRUS",
      "Location Name": "TOTAL AIRPORT SERVICES (US)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 339-3 AIRIS DR NEWARK NJ 07114 USA "
    },
    {
      "Location ID": "FARTH",
      "Location Name": "WEST FARGO THS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2521 3RD AVE NW WEST FARGO ND 58078 USA "
    },
    {
      "Location ID": "FATFX",
      "Location Name": "FEDEX RAMP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2444 N ASHLEY WAY FRESNO CA 93727 USA "
    },
    {
      "Location ID": "FNTFX",
      "Location Name": "FEDEX BLDG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3521 W BRISTOL RD FLINT MI 48507 USA "
    },
    {
      "Location ID": "FSD",
      "Location Name": "SIOUX FALLS REGIONAL AIRPORT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2801 JAYCEE LN SIOUX FALLS SD 57104 USA "
    },
    {
      "Location ID": "FSDTH",
      "Location Name": "SIOUX FALLS THS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2801 JAYCEE LN SIOUX FALLS SD 57104 USA "
    },
    {
      "Location ID": "FWAFX",
      "Location Name": "FWAFX FEDEX RAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4329 W FERGUSON RD YODER IN 46798 USA "
    },
    {
      "Location ID": "GEGTH",
      "Location Name": "MATHESON - GEG THS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2928 S SPOTTED RD SPOKANE WA 99224 USA "
    },
    {
      "Location ID": "GFKTH",
      "Location Name": "GRAND FORKS THS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2661 AIRPORT DR GRAND FORKS ND 58203 USA "
    },
    {
      "Location ID": "GJT",
      "Location Name": "WALKER FIELD AIRPORT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2828 WALKER FLD RD SUITE 211 GRAND JUNCTION CO 81506 USA "
    },
    {
      "Location ID": "GRRFX",
      "Location Name": "GRRFX FEDEX RAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6450 AIR CARGO DR SE GRAND RAPIDS MI 49512 USA "
    },
    {
      "Location ID": "GRRTH",
      "Location Name": "GRAND RAPIDS THS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3800 36TH ST SE GRAND RAPIDS MI 49512 USA "
    },
    {
      "Location ID": "GSODL",
      "Location Name": "DELTA CARGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4230 C CARGO ROAD GREENSBORO NC 27409 USA "
    },
    {
      "Location ID": "GSOFX",
      "Location Name": "FEDEX HUB",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6313A BRYAN BLVE GREENSBORO NC 27409 USA "
    },
    {
      "Location ID": "GSOTH",
      "Location Name": "GREENSBORO (WORLDWIDE FLT SVC)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4230 L CARGO RD GREENSBORO NC 27409 USA "
    },
    {
      "Location ID": "GSP",
      "Location Name": "GREENVILLE SC AIRPORT MAIL ANX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1 BROZZINI CT STE B GREENVILLE SC 29615 USA "
    },
    {
      "Location ID": "GTFTH",
      "Location Name": "MATHESON - GTF THS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1400 AIR CARGO RD GREAT FALLS MT 59404 USA "
    },
    {
      "Location ID": "HCR",
      "Location Name": "HOLY CROSS APT  AK",
      "Time Zone Offset (Hours)": -5,
      "Address": " 100 4TH ST HOLLY CROSS AK 99602 USA "
    },
    {
      "Location ID": "HNLTH",
      "Location Name": "ASIG - HNL",
      "Time Zone Offset (Hours)": -5,
      "Address": " 98 KAPALULU PL HONOLULU HI 96819 USA "
    },
    {
      "Location ID": "HTS",
      "Location Name": "HUNTINGTON AIRPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1449 AIRPORT RD HUNTINGTON WV 25704 USA "
    },
    {
      "Location ID": "HUB",
      "Location Name": "INDIANAPOLIS IMTC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2475 SOUTH HOFFMAN RD INDIANAPOLIS IN 46241 USA "
    },
    {
      "Location ID": "HUP",
      "Location Name": "FEDEX HUB TRUCK GATE",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2903 SPRANKLE AVE MEMPHIS TN 38118 USA "
    },
    {
      "Location ID": "HYA",
      "Location Name": "HYANNIS AIRPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 480 BARNSTABLE ROAD HYANNIS MA 02601 USA "
    },
    {
      "Location ID": "IAD",
      "Location Name": "LOG DULLES AIR TRANSFER OFC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23941 CARGO DR UNIT 22 STERLING VA 20166 USA "
    },
    {
      "Location ID": "IADTH",
      "Location Name": "METRO AIR SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 45055 UNDERWOOD LN STE 130 STERLING VA 20166 USA "
    },
    {
      "Location ID": "IADUP",
      "Location Name": "UNITED PARCEL SERVICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 23941 CARGO DR STE 650 STERLING VA 20166 USA "
    },
    {
      "Location ID": "IAHAA",
      "Location Name": "AMERICAN AIRLINES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 19175 LEE RD STE 100 HUMBLE TX 77338 USA "
    },
    {
      "Location ID": "IAHFX",
      "Location Name": "IAHFX FEDEX RAMP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 17855 JFK BLVD HOUSTON TX 77032 USA "
    },
    {
      "Location ID": "IAHTH",
      "Location Name": "WORLDWIDE - IAH THS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 18445 SECURITY RD HOUSTON TX 77032 USA "
    },
    {
      "Location ID": "IAHUA",
      "Location Name": "UNITED AIRLINES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4265 WRIGHT RD HOUSTON TX 77032 USA "
    },
    {
      "Location ID": "ICTFX",
      "Location Name": "WICHITA FED EX BUILDING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2073 S AIR CARGO RD WICHITA KS 67209 USA "
    },
    {
      "Location ID": "ICTTH",
      "Location Name": "WICHITA THS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1935 S AIR CARGO RD STE 800 WICHITA KS 67209 USA "
    },
    {
      "Location ID": "ICTUP",
      "Location Name": "WICHITA UPS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1935 S AIR CARGO RD WICHITA KS 67209 USA "
    },
    {
      "Location ID": "IND",
      "Location Name": "LOG INDIANAPOLIS HS RD ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2690 S HIGH SCHOOL RD INDIANAPOLIS IN 46241 USA "
    },
    {
      "Location ID": "INDCS",
      "Location Name": "IND UPS CARTAGE SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7125 W MORRIS ST INDIANAPOLIS IN 46225 USA "
    },
    {
      "Location ID": "INDTH",
      "Location Name": "METRO AIR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2730 CARGO RD INDIANAPOLIS IN 46241 USA "
    },
    {
      "Location ID": "INDUA",
      "Location Name": "AIR GENERAL, INC (UA)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7899 S SERVICE RD E INDIANAPOLIS IN 46241 USA "
    },
    {
      "Location ID": "JAXAA",
      "Location Name": "MAJESTIC TERMINAL SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13824 THOMAS IMESON AVE JACKSONVILLE FL 32218 USA "
    },
    {
      "Location ID": "JAXCF",
      "Location Name": "JACKSONVILLE CARGO FORCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1420 VANTAGE WAY STE 106 JACKSONVILLE FL 32218 USA "
    },
    {
      "Location ID": "JAXDL",
      "Location Name": "DELTA CARGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13906 THOMAS IMESON AVE JACKSONVILLE FL 32218 USA "
    },
    {
      "Location ID": "JAXFX",
      "Location Name": "JAXFX FEDEX FEDERAL EXPRESS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2260 COLE FLYER JACKSONVILLE FL 32218 USA "
    },
    {
      "Location ID": "JAXTH",
      "Location Name": "JACKSONVILLE THS, FL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 14200 PECAN PARK RD JACKSONVILLE FL 32218 USA "
    },
    {
      "Location ID": "JAXUP",
      "Location Name": "JAX UPS FREIGHT BLDG",
      "Time Zone Offset (Hours)": 0,
      "Address": " 13840 THOMAS IMESON AVE JACKSONVILLE FL 32218 USA "
    },
    {
      "Location ID": "JEC",
      "Location Name": "NEW JERSEY INT'L (JEC) & BMC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 80 COUNTY RD. JERSEY CITY NJ 07097 USA "
    },
    {
      "Location ID": "JFK",
      "Location Name": "LOG JFK ISC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 N BOUNDARY RD RM 2F1 JAMAICA NY 11430 USA "
    },
    {
      "Location ID": "JFK22",
      "Location Name": "JFK ANNEX",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 N BOUNDARY RD JAMAICA NY 11430 USA "
    },
    {
      "Location ID": "JFK71",
      "Location Name": "JFK UNITED AIR 71",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 N BOUNDARY RD 71 JAMAICA NY 11430 USA "
    },
    {
      "Location ID": "JFK86",
      "Location Name": "JFK BLDG 86 (UPS)/INT'L",
      "Time Zone Offset (Hours)": 0,
      "Address": " 86 CARGO PLZ STE 2 JAMAICA NY 11430 USA "
    },
    {
      "Location ID": "JFKAA",
      "Location Name": "AM AIRLINES - JFK BLDG 79",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 N BOUNDARY RD STE 1 JAMAICA NY 11430 USA "
    },
    {
      "Location ID": "JFKAK",
      "Location Name": "AIR ALASKA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 76 N BOUNDARY RD BLDG 76 CHINA JAMAICA NY 11430 USA "
    },
    {
      "Location ID": "JFKCS",
      "Location Name": "CARGO AIRPORT SERVICE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 261 N BOUNDARY RD STE 1 JAMAICA NY 11430 USA "
    },
    {
      "Location ID": "JFKDL",
      "Location Name": "DELTA AIRLINES - JFK",
      "Time Zone Offset (Hours)": 0,
      "Address": " 86 CARGO PLZ STE 4 JAMAICA NY 11430 USA "
    },
    {
      "Location ID": "JFKWF",
      "Location Name": "WORLDWID FLIGHT SERVICES #D",
      "Time Zone Offset (Hours)": 0,
      "Address": " 250 N BOUNDARY RD # 77 JAMAICA NY 11430 USA "
    },
    {
      "Location ID": "KSMTE",
      "Location Name": "GREATER KANSAS MTESC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 18451 MONTROSE ST EDGERTON KS 66030 USA "
    },
    {
      "Location ID": "KTH",
      "Location Name": "TIKCHIK APT         AK",
      "Time Zone Offset (Hours)": -5,
      "Address": " 4141 POSTMARK DR ANCHORAGE AK 99530 USA "
    },
    {
      "Location ID": "LAISC",
      "Location Name": "LA CA ISC IRF MPA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10080 INTERNATIONAL RD LOS ANGELES CA 90045 USA "
    },
    {
      "Location ID": "LAMTE",
      "Location Name": "LOS ANGELES MTESC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1603 S LA CADENA DR COLTON CA 92324 USA "
    },
    {
      "Location ID": "LAS",
      "Location Name": "LOG LAS VEGAS MPA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3755 E POST RD STE 110 LAS VEGAS NV 89120 USA "
    },
    {
      "Location ID": "LASCO",
      "Location Name": "LAS VEGAS CO/B6/UA/US CARGO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6075 SPENCER ST STE 192 LAS VEGAS NV 89119 USA "
    },
    {
      "Location ID": "LASFX",
      "Location Name": "LASFX FEDEX RAMP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6075 SPENCER ST LAS VEGAS NV 89119 USA "
    },
    {
      "Location ID": "LASTH",
      "Location Name": "LAS VEGAS THS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3650 E POST RD STE D LAS VEGAS NV 89120 USA "
    },
    {
      "Location ID": "LAX",
      "Location Name": "WORLDWAY AMC",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5800 W. CENTURY BLVD LOS ANGELES CA 90009 USA "
    },
    {
      "Location ID": "LAXAK",
      "Location Name": "ALASKA AIRLINES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5600 WEST CENTURY BLVD LOS ANGELES CA 90045 USA "
    },
    {
      "Location ID": "LAXCI",
      "Location Name": "CHINA AIRLINES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 11201 AVIATION BLVD LOS ANGELES CA 90045 USA "
    },
    {
      "Location ID": "LAXCS",
      "Location Name": "WORLDWIDE FLIGHT SERVICES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5621 W IMPERIAL HWY LOS ANGELES CA 90045 USA "
    },
    {
      "Location ID": "LAXFX",
      "Location Name": "LAXFX FEDEX RAMP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5927 W IMPERIAL HWY LOS ANGELES CA 90045 USA "
    },
    {
      "Location ID": "LAXJA",
      "Location Name": "JAPAN AIRLINES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6041 W IMPERIAL HWY LOS ANGELES CA 90045 USA "
    },
    {
      "Location ID": "LAXLU",
      "Location Name": "LUFTHANSA AIRLINES, CA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4721 W IMPERIAL HWY LOS ANGELES CA 90045 USA "
    },
    {
      "Location ID": "LAXMA",
      "Location Name": "MASAIR CARGO FACILITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6040 AVION DR LOS ANGELES CA 90045 USA "
    },
    {
      "Location ID": "LAXQF",
      "Location Name": "QANTAS AIRLINES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6555 W IMPERIAL HWY LOS ANGELES CA 90045 USA "
    },
    {
      "Location ID": "LAXSP",
      "Location Name": "SINGAPORE AILINES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6181 W IMPERIAL HWY LOS ANGELES CA 90045 USA "
    },
    {
      "Location ID": "LAXTH",
      "Location Name": "LAX THS ANNEX FACILITY",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1451 W KNOX ST TORRANCE CA 90501 USA "
    },
    {
      "Location ID": "LBBTH",
      "Location Name": "CARGO FORCE INC-THS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 8002 N BIRCH ST LUBBOCK TX 79403 USA "
    },
    {
      "Location ID": "LCKTH",
      "Location Name": "WORLDWIDE FLIGHT (COLUMUBS OH)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7280 ALUM CREEK DR COLUMBUS OH 43217 USA "
    },
    {
      "Location ID": "LGAAA",
      "Location Name": "LGA - AA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5B HANGER RUNWAY DRIVE LGA FLUSHING NY 11371 USA "
    },
    {
      "Location ID": "LGB",
      "Location Name": "LONG BEACH AIRPORT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 4100 E DONALD DOUGLAS DR LONG BEACH CA 90808 USA "
    },
    {
      "Location ID": "LITFX",
      "Location Name": "FEDEX HUB WESTPARK",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1500 WESTPARK DR LITTLE ROCK AR 72204-2417 USA "
    },
    {
      "Location ID": "LITUP",
      "Location Name": "UPS - INTEGRATED",
      "Time Zone Offset (Hours)": -1,
      "Address": " 800 TEMPLE ST LITTLE ROCK AR 72202-3367 USA "
    },
    {
      "Location ID": "MCIFX",
      "Location Name": "MCIFX FEDEX RAMP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1210 MEXICO CITY AVE KANSAS CITY MO 64153 USA "
    },
    {
      "Location ID": "MCITH",
      "Location Name": "MCI THS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 156 PARIS ST KANSAS CITY MO 64153 USA "
    },
    {
      "Location ID": "MCOAG",
      "Location Name": "AIR GENERAL - MCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 9463 BENFORD RD ORLANDO FL 32827 USA "
    },
    {
      "Location ID": "MCODL",
      "Location Name": "DELTA CARGO - MCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8965 TRADEPORT DR ORLANDO FL 32827 USA "
    },
    {
      "Location ID": "MCOTH",
      "Location Name": "ORLANDO THS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2437 E LANDSTREET RD ORLANDO FL 32824 USA "
    },
    {
      "Location ID": "MCOWN",
      "Location Name": "SOUTHWEST AIRLINES - MCO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8835 BEAR RD ORLANDO FL 32827 USA "
    },
    {
      "Location ID": "MEM",
      "Location Name": "MEMPHIS AIRPORT STATION",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4233 LOUIS CARRUTHERS DR MEMPHIS TN 38118 USA "
    },
    {
      "Location ID": "MHRCS",
      "Location Name": "MHR UPS CARTAGE SERVICES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 10260 TRUEMPER WAY MATHER CA 95655 USA "
    },
    {
      "Location ID": "MHTFX",
      "Location Name": "FED-EX (MHT)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 28 PERIMETER RD LONDONDERRY NH 03053 USA "
    },
    {
      "Location ID": "MIA",
      "Location Name": "MIAMI INTERNATIONAL AIRPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6150 NW 17TH ST MIAMI FL 33126 USA "
    },
    {
      "Location ID": "MIAFX",
      "Location Name": "MIAFX FEDEX RAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6100 NW 36TH ST VIRGINIA GARDENS FL 33166 USA "
    },
    {
      "Location ID": "MIATH",
      "Location Name": "CARGO FORCE - MIA THS",
      "Time Zone Offset (Hours)": 0,
      "Address": "STE101  2461 NW 67TH AVE STE 101 MIAMI FL 33122 USA "
    },
    {
      "Location ID": "MIAUP",
      "Location Name": "UPS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3401 NW 67TH AVE BAY 16 MIAMI FL 33122 USA "
    },
    {
      "Location ID": "MIMTE",
      "Location Name": "GREATER MI MTESC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 7128 INDUSTRIAL DR TEMPERANCE MI 48182 USA "
    },
    {
      "Location ID": "MKEFX",
      "Location Name": "MKEFX WI FEDEX CARGO FACILITY",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 AIR CARGO WAY STE 1 MILWAUKEE WI 53207 USA "
    },
    {
      "Location ID": "MKEQT",
      "Location Name": "QUANTEM",
      "Time Zone Offset (Hours)": -1,
      "Address": " 201 AIR CARGO WAY MILWAUKEE WI 53207 USA "
    },
    {
      "Location ID": "MKETH",
      "Location Name": "CARGO FORCE THS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 430 W GRANGE AVE MILWAUKEE WI 53207 USA "
    },
    {
      "Location ID": "MKMTE",
      "Location Name": "MILWAUKEE MTESC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4353 N RICHARDS ST MILWAUKEE WI 53212 USA "
    },
    {
      "Location ID": "MOBTH",
      "Location Name": "MOBILE THS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1882 5TH ST MOBILE AL 36615 USA "
    },
    {
      "Location ID": "MSNFX",
      "Location Name": "MSNFX FEDEX RAMP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 3420 MILLER ST MADISON WI 53704 USA "
    },
    {
      "Location ID": "MSO",
      "Location Name": "MISSOULA APT    MT",
      "Time Zone Offset (Hours)": -2,
      "Address": " 5225 HIGHWAY 10 WEST MISSOULA MT 59808 USA "
    },
    {
      "Location ID": "MSP",
      "Location Name": "LOG TWIN CITIES AMF",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5001 NORTHWEST DR SAINT PAUL MN 55111 USA "
    },
    {
      "Location ID": "MSPTH",
      "Location Name": "WORLDWIDE - MSP THS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2120 77TH STREET MINNEPOLIS MN 55450 USA "
    },
    {
      "Location ID": "MSY",
      "Location Name": "NEW ORLEANS AMC",
      "Time Zone Offset (Hours)": -1,
      "Address": " AIRPORT SERVICE RD B NEW ORLEANS LA 70141 USA "
    },
    {
      "Location ID": "MSYDL",
      "Location Name": "DELTA AIRLINES CARGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 AIRLINE DR BLDG 5 W ACCESS RD KENNER LA 70062 USA "
    },
    {
      "Location ID": "MSYFX",
      "Location Name": "INTEGRATED AIRLINE SERVICES (FEDEX)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 CROFTON RD STE 400 KENNER LA 70062 USA "
    },
    {
      "Location ID": "MSYTH",
      "Location Name": "INTEGRATED AIRLINE SERVICES LA",
      "Time Zone Offset (Hours)": -1,
      "Address": " 200 CROFTON RD STE 400 KENNER LA 70062 USA "
    },
    {
      "Location ID": "MSYUA",
      "Location Name": "UNITED AIRLINES CARGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 900 AIRLINE DR BLDG 5 W ACCESS RD KENNER LA 70062 USA "
    },
    {
      "Location ID": "OAKAX",
      "Location Name": "OAKLAND THS ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7210 EDGEWATER DR OAKLAND CA 94621 USA "
    },
    {
      "Location ID": "OAKFX",
      "Location Name": "OAKFX FEDEX RAMP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1 SALLY RIDE WAY OAKLAND CA 94621 USA "
    },
    {
      "Location ID": "OAKTH",
      "Location Name": "MATHERSON FLIGHT EXTENDER",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2062 W 140TH SAN LEANDRO CA 94577 USA "
    },
    {
      "Location ID": "OAKUP",
      "Location Name": "SANTA CLARA LOGISTICS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2960 ALVARADO ST SAN LEANDRO CA 94577 USA "
    },
    {
      "Location ID": "OGG",
      "Location Name": "KAHULUI AIRPORT",
      "Time Zone Offset (Hours)": -5,
      "Address": " 1 KAHULUI AIRPORT RD KAHULUI HI 96732 USA "
    },
    {
      "Location ID": "OKC",
      "Location Name": "OKC AMF RETAIL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6500 AIR CARGO RD STE B OKLAHOMA CITY OK 73195-9900 USA "
    },
    {
      "Location ID": "OKCFX",
      "Location Name": "FEDEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6301 AIR CARGO RD STE 1 OKLAHOMA CITY OK 73159 USA "
    },
    {
      "Location ID": "OKCTH",
      "Location Name": "INTEGRATED AIRLINE SERVICES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6300 AIR CARGO RD STE C OKLAHOMA CITY OK 73159 USA "
    },
    {
      "Location ID": "OMATH",
      "Location Name": "CARGO FORGE - OMA THS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1523 HARTMAN CT OMAHA NE 68110 USA "
    },
    {
      "Location ID": "ONT",
      "Location Name": "ONTARIO AIRPORT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2300 E AIRPORT DR ONTARIO CA 91761 USA "
    },
    {
      "Location ID": "ONTAX",
      "Location Name": "ONTARIO THS ANNEX",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2825 JURUPA ST ONTARIO CA 91761 USA "
    },
    {
      "Location ID": "ONTFX",
      "Location Name": "ONTFX FEDEX RAMP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1801 E AVION AVE ONTARIO CA 91761 USA "
    },
    {
      "Location ID": "ONTTH",
      "Location Name": "ONTARIO THS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2004 E JAY ST ONTARIO CA 91764 USA "
    },
    {
      "Location ID": "ONTUP",
      "Location Name": "ONT UPS FREIGHT BLDG",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3110 JURUPA ST ONTARIO CA 91761 USA "
    },
    {
      "Location ID": "ONTUS",
      "Location Name": "ONTARIO CARGO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2300 E AIRPORT DR ONTARIO CA 91761 USA "
    },
    {
      "Location ID": "ONTWN",
      "Location Name": "SOUTHWEST - ONT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 540 S HELLMAN PRIVADO ONTARIO CA 91761 USA "
    },
    {
      "Location ID": "ORDAA",
      "Location Name": "AMERICAN AIRLINES",
      "Time Zone Offset (Hours)": -1,
      "Address": " 517 EXPRESS CENTER DR STE 2 CHICAGO IL 60666 USA "
    },
    {
      "Location ID": "ORDAF",
      "Location Name": "AIR FRANCE CARGO (POB 66144)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 618 S CARGO ACCESS RD CHICAGO IL 60666 USA "
    },
    {
      "Location ID": "ORDAN",
      "Location Name": "CHICAGO METRO L&DC (BUSSE) ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2515 BUSSE RD ELK GROVE VLG IL 60007 USA "
    },
    {
      "Location ID": "ORDAS",
      "Location Name": "ASIANA AIRLINES (AGI BUILDING)",
      "Time Zone Offset (Hours)": -1,
      "Address": " 516 EXPRESS CENTER DR CHICAGO IL 60666 USA "
    },
    {
      "Location ID": "ORDAT",
      "Location Name": "UNITED FREIGHT BUILDING",
      "Time Zone Offset (Hours)": -1,
      "Address": " 630 S CARGO ACCESS RD CHICAGO IL 60666 USA "
    },
    {
      "Location ID": "ORDDL",
      "Location Name": "DELTA AIRLINES CARGO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 613 N ACCESS RD CHICAGO IL 60666 USA "
    },
    {
      "Location ID": "ORDIA",
      "Location Name": "CHICAGO INTL/MIL SERVICE CTR ANNEX",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10600 BELMONT AVE FRANKLIN PARK IL 60131 USA "
    },
    {
      "Location ID": "ORDTH",
      "Location Name": "METRO AIR SERVICE - ORD THS, IL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 11405 IRVING PARK RD FRANKLIN PARK IL 60131 USA "
    },
    {
      "Location ID": "ORFTH",
      "Location Name": "QUANTEM - ORF THS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 6000 ROBIN HOOD RD, BAY 12 NORFOLK VA 23518 USA "
    },
    {
      "Location ID": "PAE",
      "Location Name": "EASTSOUND  APT        AK",
      "Time Zone Offset (Hours)": -5,
      "Address": " 3609 TONGASS AVE KETCHIKAN AK 99901 USA "
    },
    {
      "Location ID": "PDX",
      "Location Name": "PORTLAND AIRPORT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7640 NE AIRPORT WAY PORTLAND OR 97238 USA "
    },
    {
      "Location ID": "PDXCS",
      "Location Name": "PDX UPS CARTAGE SERVICES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5337 NE COURIER CT PORTLAND OR 97218 USA "
    },
    {
      "Location ID": "PDXDC",
      "Location Name": "DELTA CARGO (DL)",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7912 NE AIR CARGO RD PORTLAND OR 97218 USA "
    },
    {
      "Location ID": "PDXTH",
      "Location Name": "MATHESON - PDX THS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8548 NE ALDERWOOD RD PORTLAND OR 97220 USA "
    },
    {
      "Location ID": "PDXTS",
      "Location Name": "AIRPORT TERMINAL SERVICES (ATS)",
      "Time Zone Offset (Hours)": -3,
      "Address": " 7780 NE AIR CARGO RD PORTLAND OR 97218 USA "
    },
    {
      "Location ID": "PDXUA",
      "Location Name": "UNITED CARGO (UA&CO)",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8101 NE AIRPORT WAY PORTLAND OR 97218 USA "
    },
    {
      "Location ID": "PDXUC",
      "Location Name": "UNITED CARGO (UA)",
      "Time Zone Offset (Hours)": -3,
      "Address": " 8101 NE AIRPORT WAY PORTLAND OR 97218 USA "
    },
    {
      "Location ID": "PHLAA",
      "Location Name": "AMERICAN AIRLINES-PHL",
      "Time Zone Offset (Hours)": 0,
      "Address": " CARGO CITY RD BLDG C-4 PHILADELPHIA PA 19153 USA "
    },
    {
      "Location ID": "PHLFX",
      "Location Name": "FEDEX RAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " CARGO CITY RD BLDG C-7 PHILADELPHIA PA 19153 USA "
    },
    {
      "Location ID": "PHLMT",
      "Location Name": "PHL CAIR",
      "Time Zone Offset (Hours)": 0,
      "Address": " 8800 ESSINGTON AVE BLDG 2 PHILADELPHIA PA 19153 USA "
    },
    {
      "Location ID": "PHLTH",
      "Location Name": "MATHESON PHL THS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 870 CALCON HOOK RD BLDG 3 SHARON HILL PA 19079 USA "
    },
    {
      "Location ID": "PHLUA",
      "Location Name": "UNITED AIRLINES-PHL",
      "Time Zone Offset (Hours)": 0,
      "Address": " CARGO CITY RD BLDG C-4 PHILADELPHIA PA 19153 USA "
    },
    {
      "Location ID": "PHLUP",
      "Location Name": "PHL UPS FREIGHT BLDG",
      "Time Zone Offset (Hours)": 0,
      "Address": " ONE HOG ISLAND ROAD PHILADELPHIA PA 19153 USA "
    },
    {
      "Location ID": "PHLUS",
      "Location Name": "US AIR-PHL",
      "Time Zone Offset (Hours)": 0,
      "Address": " CARGO CITY RD BLDG C-6 PHILADELPHIA PA 19153 USA "
    },
    {
      "Location ID": "PHLWN",
      "Location Name": "SOUTHWEST AIRLINES - PHL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2 CARGO CITY STE 18 PHILADELPHIA PA 19153 USA "
    },
    {
      "Location ID": "PHXAA",
      "Location Name": "AMERICAN AIRLINES CARGO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1321 S 27TH ST PHOENIX AZ 85034 USA "
    },
    {
      "Location ID": "PHXFX",
      "Location Name": "PHXFX FEDEX RAMP",
      "Time Zone Offset (Hours)": -2,
      "Address": " 3002 E OLD TOWER ROAD PHOENIX AZ 85034 USA "
    },
    {
      "Location ID": "PHXSP",
      "Location Name": "SWISSPORT CARGO",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1337 S 27TH ST BLDG B PHOENIX AZ 85034 USA "
    },
    {
      "Location ID": "PHXTH",
      "Location Name": "MATHESON POSTAL SERVICES",
      "Time Zone Offset (Hours)": -2,
      "Address": " 620 N 47TH AVE PHOENIX AZ 85043 USA "
    },
    {
      "Location ID": "PHXUP",
      "Location Name": "UPS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1247 S 27TH ST BLDG A PHOENIX AZ 85034 USA "
    },
    {
      "Location ID": "PHXWN",
      "Location Name": "SOUTHWEST AIRLINES - PHX",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1251 S 25TH PL STE 16 PHOENIX AZ 85034 USA "
    },
    {
      "Location ID": "PIT",
      "Location Name": "PITTSBURGH AMF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 AIRPORT BLVD SUITE 4000 PITTSBURGH PA 15231 USA "
    },
    {
      "Location ID": "PITAA",
      "Location Name": "AMERICAN AIRLINES - PIT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 TOWER RD PITTSBURGH PA 15231-0003 USA "
    },
    {
      "Location ID": "PITCD",
      "Location Name": "CARGO FORCE (CORAOPOLIS)",
      "Time Zone Offset (Hours)": 0,
      "Address": " 700 AIRSIDE DR MOON TWP PA 15108 USA "
    },
    {
      "Location ID": "PITTH",
      "Location Name": "CARGO FORCE",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3000 HALVERSON DR PITTSBURGH PA 15231 USA "
    },
    {
      "Location ID": "PITWW",
      "Location Name": "WORLDWIDE FLIGHT SERVICES",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 AIRPORT BLVD PITTSBURGH PA 15231-0000 USA "
    },
    {
      "Location ID": "PQI",
      "Location Name": "PRESQUE ISLE AIRPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1000 AIRPORT DRIVE PRESQUE ISLE ME 04769 USA "
    },
    {
      "Location ID": "PSC",
      "Location Name": "PASCO APT WA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 3601 N 20TH AVE PASCO WA 99301 USA "
    },
    {
      "Location ID": "PVDFX",
      "Location Name": "PVDFX PVD FEDEX RAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 540 AIRPORT RD WARWICK RI 02886 USA "
    },
    {
      "Location ID": "PWMFX",
      "Location Name": "PWMFX PWM FEDEX RAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 261 YELLOWBIRD RD PORTLAND ME 04102 USA "
    },
    {
      "Location ID": "RAP",
      "Location Name": "RAPID CITY APT  SD",
      "Time Zone Offset (Hours)": -1,
      "Address": " 4550 TERMINAL RD RAPID CITY SD 57703 USA "
    },
    {
      "Location ID": "RAPFX",
      "Location Name": "RAPFX FEDEX TERMINAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2350 E SAINT PATRICK ST RAPID CITY SD 57703 USA "
    },
    {
      "Location ID": "RD302",
      "Location Name": "LOG ATLANTA GA RPDC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 600 PALMETTO LOGISTICS PKWY PALMETTO GA 30268-9991 USA "
    },
    {
      "Location ID": "RDUFX",
      "Location Name": "Raleigh Fed-ex",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1320 INTERNATIONAL DRIVE RALEIGH NC 27623 USA "
    },
    {
      "Location ID": "RDUGM",
      "Location Name": "DHL GLOBAL MAIL - RALEIGH",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3401 GRESHAM LAKE RD RALEIGH NC 27615 USA "
    },
    {
      "Location ID": "RDUTH",
      "Location Name": "MATHESON",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1320 INTERNATIONAL DR MORRISVILLE NC 27560 USA "
    },
    {
      "Location ID": "RICTH",
      "Location Name": "RICHMOND THS VA",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5251 AIR EXPRESS RD RICHMOND VA 23250 USA "
    },
    {
      "Location ID": "RNOTH",
      "Location Name": "RENO THS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 1395 AIR CARGO WAY RENO NV 89502 USA "
    },
    {
      "Location ID": "ROA",
      "Location Name": "ROANOKE AIRPORT",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5202 AVIATION DR ROANOKE VA 24012 USA "
    },
    {
      "Location ID": "ROAFX",
      "Location Name": "FEDEX RAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5824 AIRPORT RD NW ROANOKE VA 24012 USA "
    },
    {
      "Location ID": "ROCFX",
      "Location Name": "ROCFX ROC FEDEX RAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1195 SCOTTSVILLE RD ROCHESTER NY 14624 USA "
    },
    {
      "Location ID": "ROCTH",
      "Location Name": "MATHESON FLIGHT EXTENDERS ROC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 500 AIRPORT WAY ROCHESTER NY 14624 USA "
    },
    {
      "Location ID": "SANCS",
      "Location Name": "SAN UPS CARTAGE SERVICES",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5255 LOVELOCK ST SAN DIEGO CA 92110 USA "
    },
    {
      "Location ID": "SANFX",
      "Location Name": "SANFX FEDEX RAMP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2221 W WASHINGTON ST SAN DIEGO CA 92110 USA "
    },
    {
      "Location ID": "SANTH",
      "Location Name": "SAN DIEGO THS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2701 MIDWAY DR SAN DIEGO CA 92110 USA "
    },
    {
      "Location ID": "SATTH",
      "Location Name": "INTEGRATED - SAT THS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 10315 WETMORE RD SAN ANTONIO TX 78216 USA "
    },
    {
      "Location ID": "SBNFX",
      "Location Name": "SBNFX FEDEX RAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 5301 LINCOLN WAY W SOUTH BEND IN 46628 USA "
    },
    {
      "Location ID": "SD135",
      "Location Name": "UTICA NY S&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 100 PITCHER ST UTICA NY 13504 USA "
    },
    {
      "Location ID": "SD306",
      "Location Name": "ATHENS GA S&DC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 575 OLYMPIC DR ATHENS GA 30601 USA "
    },
    {
      "Location ID": "SD666",
      "Location Name": "TOPEKA KS S&DC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1410 NW GAGE BLVD RM 111 TOPEKA KS 66618 USA "
    },
    {
      "Location ID": "SDF",
      "Location Name": "LOG LOUISVILLE AMF",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4440 CRITTENDEN DR LOUISVILLE KY 40221 USA "
    },
    {
      "Location ID": "SDFFX",
      "Location Name": "SDFFX FEDEX RAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4901 CRITTENDEN DR LOUISVILLE KY 40209-1705 USA "
    },
    {
      "Location ID": "SDFTH",
      "Location Name": "MATHESON - LOUISVILLE THS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4650 AIR FREIGHT DR, BAY 2 LOUISVILLE KY 40209 USA "
    },
    {
      "Location ID": "SEA",
      "Location Name": "SEATTLE AMC WA 98158-9741",
      "Time Zone Offset (Hours)": -3,
      "Address": " 16601 AIR CARGO ROAD SEATTLE WA 98158 USA "
    },
    {
      "Location ID": "SEAAA",
      "Location Name": "AMERICAN AIRLINES - SEA",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2427 S 161ST ST STE 205 SEATAC WA 98158 USA "
    },
    {
      "Location ID": "SEAAS",
      "Location Name": "ALASKA AIR CARGO",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2600 S 165TH ST SEATAC WA 98158 USA "
    },
    {
      "Location ID": "SEAFX",
      "Location Name": "SEAFX FEDEX RAMP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2450 S 161ST ST SEATAC WA 98158 USA "
    },
    {
      "Location ID": "SEATH",
      "Location Name": "SEATTLE THS - BUILDING G",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2580 S 156TH ST STE G201 SEATAC WA 98158 USA "
    },
    {
      "Location ID": "SFOTH",
      "Location Name": "METRO AIR",
      "Time Zone Offset (Hours)": -3,
      "Address": " 229 LITTLEFIELD AVE STE 4 SAN FRANCISCO CA 94080 USA "
    },
    {
      "Location ID": "SFOUS",
      "Location Name": "US AIRWAYS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 116 MCDONNELL RD SAN FRANCISCO CA 94128 USA "
    },
    {
      "Location ID": "SGF",
      "Location Name": "SPRINGFIELD REGIONAL APT MO",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5141 W CARGO WAY SPRINGFIELD MO 65803-9512 USA "
    },
    {
      "Location ID": "SHV",
      "Location Name": "SHREVEPORT APT",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5102 HOLLYWOOD AVE SHREVEPORT LA 71109 USA "
    },
    {
      "Location ID": "SHVTH",
      "Location Name": "INTEGRATED AIRLINE SVCS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 5685 WEST PERIMETER RD, BLDG B SHREVEPORT LA 71109 USA "
    },
    {
      "Location ID": "SJCFX",
      "Location Name": "SJCFX FEDEX RAMP",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2091 AIRPORT BLVD SAN JOSE CA 95110 USA "
    },
    {
      "Location ID": "SJTFX",
      "Location Name": "FEDEX CARGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 225 AVE JOSE A TONY SANTANA STE 400D CAROLINA PR 00979 USA "
    },
    {
      "Location ID": "SJUAA",
      "Location Name": "AA CARGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 375 AVE JOSE A TONY SANTANA STE 1 CAROLINA PR 00979 USA "
    },
    {
      "Location ID": "SJUDC",
      "Location Name": "DELTA AIR CARGO",
      "Time Zone Offset (Hours)": 0,
      "Address": " 25 AVE JOSE A TONY SANTANA STE 10 CAROLINA PR 00979 USA "
    },
    {
      "Location ID": "SJUGM",
      "Location Name": "GMD",
      "Time Zone Offset (Hours)": 0,
      "Address": " 225 AVE JOSE A TONY SANTANA STE 510E CAROLINA PR 00979 USA "
    },
    {
      "Location ID": "SJUMN",
      "Location Name": "MN AVIATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 460 AVE JOSE A TONY SANTANA CAROLINA PR 00979 USA "
    },
    {
      "Location ID": "SJUTH",
      "Location Name": "CARGO FORCE - SJU",
      "Time Zone Offset (Hours)": 0,
      "Address": " 150 AVE JOSE A TONY SANTANA STE 10 BLDG CAF CAROLINA PR 00979 USA "
    },
    {
      "Location ID": "SLC",
      "Location Name": "SALT LAKE CITY AMC",
      "Time Zone Offset (Hours)": -2,
      "Address": " 320 N 3700 W SALT LAKE CITY UT 84122 USA "
    },
    {
      "Location ID": "SLC5X",
      "Location Name": "PEAK SUPPLY CHAIN SOLUTIONS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 1950 N 2200 W STE 4 SALT LAKE CITY UT 84116 USA "
    },
    {
      "Location ID": "SLCTH",
      "Location Name": "MATHESON - SLC THS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 320 NORTH 3700 W SALT LAKE CITY UT 84122 USA "
    },
    {
      "Location ID": "SMF",
      "Location Name": "SACRAMENTO AIR TRANSFER OFFICE",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5853 CITATION WAY SACRAMENTO CA 95837 USA "
    },
    {
      "Location ID": "SMFFX",
      "Location Name": "SMFFX FEDEX RAMP SMF",
      "Time Zone Offset (Hours)": -3,
      "Address": " 6733 LINDBERGH DR STE 1 SACRAMENTO CA 95837 USA "
    },
    {
      "Location ID": "SMFTH",
      "Location Name": "SMF THS",
      "Time Zone Offset (Hours)": -3,
      "Address": " 5853A CITATION WAY SACRAMENTO CA 95837 USA "
    },
    {
      "Location ID": "STC",
      "Location Name": "#ST CLOUD APT       MN",
      "Time Zone Offset (Hours)": -1,
      "Address": " 1550 45TH AVE S.E. ST CLOUD MN 56304 USA "
    },
    {
      "Location ID": "STLAG",
      "Location Name": "AIR GENERAL",
      "Time Zone Offset (Hours)": -1,
      "Address": " 9833 AIR CARGO RD SAINT LOUIS MO 63134 USA "
    },
    {
      "Location ID": "STLFX",
      "Location Name": "STLFX FEDEX RAMP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 6143 JAMES S MCDONNELL BLVD SAINT LOUIS MO 63134 USA "
    },
    {
      "Location ID": "SWFTH",
      "Location Name": "ATLANTIC AVIATION",
      "Time Zone Offset (Hours)": 0,
      "Address": " 1032 1ST ST NEW WINDSOR NY 12553 USA "
    },
    {
      "Location ID": "TPA",
      "Location Name": "TAMPA AMC",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3501 BESSIE COLEMAN BLVD FL 1 TAMPA FL 33630 USA "
    },
    {
      "Location ID": "TPAAG",
      "Location Name": "AIR GENERAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4662 AIR CARGO RD STE 1600 TAMPA FL 33614 USA "
    },
    {
      "Location ID": "TPADL",
      "Location Name": "AGI - ALLIANCE GROUND INTERNATIONAL",
      "Time Zone Offset (Hours)": 0,
      "Address": " 4790 N HOOVER BLVD TAMPA FL 33634-5388 USA "
    },
    {
      "Location ID": "TPATH",
      "Location Name": "CARGO FORCE - TPA THS",
      "Time Zone Offset (Hours)": 0,
      "Address": "STEC & D  4700 N HOOVER BLVD TAMPA FL 33634 USA "
    },
    {
      "Location ID": "TULFX",
      "Location Name": "FEDEX RAMP",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2121 N 85TH E.AVE TULSA OK 74115 USA "
    },
    {
      "Location ID": "TULTH",
      "Location Name": "MATHESON - TUL THS",
      "Time Zone Offset (Hours)": -1,
      "Address": " 2161 N CARGO RD BLD 2, STE M TULSA OK 74115 USA "
    },
    {
      "Location ID": "TUSFX",
      "Location Name": "TUSFX FEDEX RAMP",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2760 E AIRPORT DR TUCSON AZ 85706 USA "
    },
    {
      "Location ID": "TUSTH",
      "Location Name": "MATHESON - TUS THS",
      "Time Zone Offset (Hours)": -2,
      "Address": " 2600 E LOS REALES TUCSON AZ 85706 USA "
    },
    {
      "Location ID": "TXMTE",
      "Location Name": "GREATER TEXAS MTESC",
      "Time Zone Offset (Hours)": -1,
      "Address": " 14900 FRYE RD FORT WORTH TX 76155 USA "
    },
    {
      "Location ID": "TYSFX",
      "Location Name": "TYSFX FEDEX RAMP",
      "Time Zone Offset (Hours)": 0,
      "Address": " 2037 CALLAHAN RD LOUISVILLE TN 37777 USA "
    },
    {
      "Location ID": "TYSTH",
      "Location Name": "GLOBAL LOGISTICS - TYS THS",
      "Time Zone Offset (Hours)": 0,
      "Address": " 3440 NORTHBEND CIR D ALCOA TN 37701 USA "
    },
    {
      "Location ID": "WSM",
      "Location Name": "WISEMAN",
      "Time Zone Offset (Hours)": -5,
      "Address": " (NOP) HC 65 BOX 9801 ANCHORAGE AK 99790 USA "
    },
    {
      "Location ID": "YKM",
      "Location Name": "YAKIMA APT",
      "Time Zone Offset (Hours)": -3,
      "Address": " 2400 WEST WASHINGTON AVE YAKIMA WA 98903 USA "
    }
]

function findUSPSNASSCode(code){
    const nasscode = USPS_NASS_CODES.find(nasscode => nasscode['Location ID'] === code);
    if (nasscode) {
        return nasscode['Location Name'];
    } else {
        return code;
    }
}

function findUSPSNASSCodeState(code){
  const nasscode = USPS_NASS_CODES.find(nasscode => nasscode['Location ID'] === code);
  if (nasscode) {
    const words = nasscode['Address'].trim().split(/\s+/); 
    if (words.length >= 3) {
      return words[words.length - 3];
    } else {
      return "Unknown";
    }
      
  } else {
      return code;
  }
}



function findUSPSTimeZoneOffsetHours(code){
    const nasscode = USPS_NASS_CODES.find(nasscode => nasscode['Location ID'] === code);
    if (nasscode) {
        return nasscode['Time Zone Offset (Hours)'];
    } else {
        return code;
    }
}

function findUSPSAddress(code){
    const nasscode = USPS_NASS_CODES.find(nasscode => nasscode['Location ID'] === code);
    if (nasscode) {
        return nasscode['Address'];
    } else {
        return code;
    }
}

function findUSPSOffsetTimezoneIANA(offset) {
  switch (offset) {
    case 0:
      return 'ET'; // Eastern Time (ET)
    case -1:
      return 'CT'; // Central Time (CT)
    case -2:
      return 'MT'; // Mountain Time (MT)
    case -3:
      return 'MST'; // Arizona Time (no DST)
    case -4:
      return 'PT'; // Pacific Time (PT)
    case -5:
      return 'AKT'; // Alaska Time (AKT)
    default:
      return '--'; // Handle other cases as needed
  }
}

function formatUSPSDateTime(code, uspstime){
  try{
    const offset = findUSPSTimeZoneOffsetHours(code);
    const locationTz = findUSPSOffsetTimezoneIANA(offset)
    const inputTime = new Date(uspstime);
    const month = String(inputTime.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(inputTime.getDate()).padStart(2, '0');
    const year = String(inputTime.getFullYear()).slice(-2); // Get the last 2 digits of the year
    const hours = String(inputTime.getHours()).padStart(2, '0');
    const minutes = String(inputTime.getMinutes()).padStart(2, '0');
    const formattedTime = `${month}/${day}/${year} ${hours}:${minutes} ${locationTz}`;
    return formattedTime
  } catch {
    return uspstime;
  }
}

function findUSPSNassCodeTimezoneIANA(code) {
  const nasscode = USPS_NASS_CODES.find(nasscode => nasscode['Location ID'] === code);
  if (nasscode) {
    const offset = nasscode['Time Zone Offset (Hours)'];
    switch (offset) {
      case 0:
        return 'America/New_York'; // Eastern Time (ET)
      case -1:
        return 'America/Chicago'; // Central Time (CT)
      case -2:
        return 'America/Denver'; // Mountain Time (MT)
      case -3:
        return 'America/Phoenix'; // Arizona Time (no DST)
      case -4:
        return 'America/Los_Angeles'; // Pacific Time (PT)
      case -5:
        return 'America/Anchorage'; // Alaska Time (AKT)
      default:
        return '--'; // Handle other cases as needed
    }
  } else {
    return code;
  }
}

export {
    USPS_NASS_CODES,
    findUSPSNASSCode,
    findUSPSTimeZoneOffsetHours,
    findUSPSAddress,
    formatUSPSDateTime,
    findUSPSNASSCodeState,
    findUSPSNassCodeTimezoneIANA
}