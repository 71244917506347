import React, { useEffect, useState } from "react";
import { Container, Card, Row, Col, Form, Table, Alert } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaCaretDown, FaCaretRight, } from "react-icons/fa";
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import { ROUTEPLAN_COVERAGE_DEFAULTS, ROUTEPLAN_WK1_KEYS, ROUTEPLAN_WK2_KEYS, TIMEZONE_OPTIONS } from "../../context/appdata";
import CalendarChart from "../../components/CalendarChart";
import { useParams  } from "react-router-dom"
import DataDisplay from "../../components/DataDisplay";
import { Week1Digest, DayOfWeekDigest, getDayOfWeek, formatDate } from "../../utils/DayOfWeekDigest";
import { formatNameBadge } from "../../components/formatNameBadge";
import AlertDisplay from "../../components/AlertDisplay";


const LOCAL_STORAGE_PREFIX = '@mctms-routeplan-version';

const RoutePlanDetail = ({ routeplan }) => {
    const { id } = useParams();
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const stops = routeplan.stops;
    const [alerts, setAlerts] = useState([])
    const alertState = { alerts, setAlerts }
    const [ops, setOps] = useState([]);
    const [runct, setRunCt] = useState();
    const date = new Date()
    const yyyy = date.getUTCFullYear();
    const today = formatDate(date);
    const tomorrow = new Date(date);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowplus14 = new Date(date);
    tomorrowplus14.setDate(tomorrowplus14.getDate() + 14);
    const todayplus30 = new Date(date);
    todayplus30.setDate(todayplus30.getDate() + 30);
    const todayplus30Formatted = formatDate(todayplus30);
    const tomorrowFormatted = formatDate(tomorrow);

    const [forecast, setForecast] = useState([]);

    const [showRoute, shouldShowRoute] = useState(false)
    const [showService, shouldShowService] = useState(false)
    const [showEquipment, shouldShowEquipment] = useState(false)
    const [showCoverage, shouldShowCoverage] = useState(false)
    const [showStandardOp, shouldShowStandardOp] = useState(false)
    const [showForecast, shouldShowForecast] = useState(false)

    const CoverageCards = (wkkey, h) => {
        return (
            <Col key={h} sm={12} md={6} lg={3}>
                <Card>
                    <Card.Header>
                        <span>
                            <b>{wkkey.toUpperCase()}</b>
                        </span>
                        <span style={{ float: 'right' }}>
                            <b>{routeplan.coverage[wkkey] ? (routeplan.coverage[wkkey]).length : 0} / {routeplan.crew === 'Team' ? 2 : 1}</b>
                        </span>
                    </Card.Header>
                    <Card.Body>
                        <Col>
                            {routeplan.coverage[wkkey] && routeplan.coverage[wkkey].map((operator, j) => (
                                <Container fluid style={{ margin: '0px', padding: '2px' }}>
                                    <Row>
                                        <Col xs={10}>
                                            {(ROUTEPLAN_COVERAGE_DEFAULTS.map(item => item.name)).includes(operator.name)
                                                ? <Form.Control key={h + j} disabled value={`${operator.name}`} />
                                                : <Form.Control key={h + j} disabled value={`${operator.lastname}, ${operator.firstname} `} />
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            ))}
                        </Col>
                    </Card.Body>
                    {(routeplan.coverage[wkkey] && (routeplan.coverage[wkkey]).length > (routeplan.crew === 'Team' ? 2 : 1))
                        &&
                        <Alert variant="warning">🚨 too many drivers</Alert>}
                </Card>
                <br />
            </Col>
        )
    }

    const moneyformatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });


    function getTimezoneCode(timezoneName) {
        const timezoneIndex = TIMEZONE_OPTIONS.findIndex(option => option.name === timezoneName);

        if (timezoneIndex !== -1) {
            return TIMEZONE_OPTIONS[timezoneIndex].code;
        }

        return null;
    }


    // Effect to load state from localStorage on initial render
    useEffect(() => {
        const loadStateFromLocalStorage = (key, setStateFunction) => {
            const storedValue = localStorage.getItem(key);
            if (storedValue !== null) {
                setStateFunction(JSON.parse(storedValue));
            }
        };

        loadStateFromLocalStorage(`${LOCAL_STORAGE_PREFIX}-showRoute`, shouldShowRoute);
        loadStateFromLocalStorage(`${LOCAL_STORAGE_PREFIX}-showService`, shouldShowService);
        loadStateFromLocalStorage(`${LOCAL_STORAGE_PREFIX}-showEquipment`, shouldShowEquipment);
        loadStateFromLocalStorage(`${LOCAL_STORAGE_PREFIX}-showCoverage`, shouldShowCoverage);
        loadStateFromLocalStorage(`${LOCAL_STORAGE_PREFIX}-showStandardOp`, shouldShowStandardOp);
    }, []);

    // Effect to save state into localStorage whenever any state changes
    useEffect(() => {
        const saveStateToLocalStorage = (key, stateValue) => {
            localStorage.setItem(key, JSON.stringify(stateValue));
        };

        saveStateToLocalStorage(`${LOCAL_STORAGE_PREFIX}-showRoute`, showRoute);
        saveStateToLocalStorage(`${LOCAL_STORAGE_PREFIX}-showService`, showService);
        saveStateToLocalStorage(`${LOCAL_STORAGE_PREFIX}-showEquipment`, showEquipment);
        saveStateToLocalStorage(`${LOCAL_STORAGE_PREFIX}-showCoverage`, showCoverage);
        saveStateToLocalStorage(`${LOCAL_STORAGE_PREFIX}-showStandardOp`, showStandardOp);
    }, [showRoute, showService, showEquipment, showCoverage, showStandardOp]);


    useEffect(() => {
        const loadStateFromLocalStorage = (key, setStateFunction) => {
            const storedValue = localStorage.getItem(key);
            if (storedValue !== null) {
                setStateFunction(JSON.parse(storedValue));
            }
        };

        const fetchOpChart = async () => {
            try {
                const operations = await Api.get(`/frequency/${routeplan.frequency._id}/digest/${yyyy}-01-01/${yyyy}-12-31`, idToken)
                const operationsClean = operations.filter(operation => {
                    const o = new Date(operation[0])
                    const s = new Date(routeplan.startop)
                    const e = new Date(routeplan.endop)
                    return o >= s && o <= e
                })

                const runs = operationsClean.reduce((acc, curr) => acc + curr[1], 0);
                setRunCt(runs)
                setOps(operationsClean)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Operations calendar request error' + JSON.stringify(error) }])
            }
            didSet(true)
        }

        const fetchVersionForecast = async () => {
            try {
                const data = await Api.get(`/forecast/routeplan/${id}/${tomorrowFormatted}/${todayplus30Formatted}`, idToken)
                const cleanForecast = data.map(route => {
                    let wkday;
                    if (route.routeplan.period === 'Weekly') {
                        wkday = Week1Digest(route.servicedate)
                    } else {
                        wkday = DayOfWeekDigest(route.servicedate)
                    }
                    return {
                        'Name': route.routeplan.name,
                        'Service Date': `${route.servicedate}`,
                        'Day': `${getDayOfWeek(route.servicedate)}`,
                        'Service Type': route.servicetype,
                        'Period': route.routeplan.period,
                        'Contract': route.routeplan.contract.name,
                        'Frequency ': `${route.routeplan.frequency.name} - ${route.routeplan.frequency.detail}`,
                        'Crew': route.routeplan.crew,
                        'Coverage': <>{route.routeplan.coverage[wkday].map(driver => formatNameBadge(driver))}</>
                    }
                })
                setForecast(cleanForecast);
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Forecasting error' }])
            }
        }
            
        if (!set) {
            fetchOpChart()
            fetchVersionForecast()
        }
    }, [id, tomorrowFormatted, todayplus30Formatted, routeplan, yyyy, set, idToken, routeplan.startop, routeplan.endop, alerts])

    return (
        <>
            <Card >
                <Card.Header>
                    <Row>
                        <Col>
                            <Card.Title>Route Plan Detail</Card.Title>
                            <Card.Subtitle></Card.Subtitle>
                        </Col>
                    </Row>
                </Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {(set && routeplan.name) &&
                    <>
                        <Card.Header>
                            <Card.Title onClick={() => shouldShowRoute(!showRoute)}> {showRoute ? <FaCaretDown /> : <FaCaretRight />} Route : {routeplan.name}</Card.Title>
                        </Card.Header>
                        {showRoute &&
                            <Card.Body>
                                <Row>
                                    <Col sm={12} md={6} lg={3}>
                                        Name
                                        <Form.Control
                                            value={routeplan.name}
                                            readOnly
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={12} md={6} lg={3}>
                                        Contract
                                        <Form.Control
                                            value={routeplan.contract.name}
                                            readOnly
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={12} md={6} lg={3}>
                                        Assignment
                                        <Form.Control
                                            value={routeplan.assignment.name}
                                            readOnly
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={12} md={6} lg={3}>
                                        Domicile
                                        <Form.Control
                                            value={routeplan.domicile.name}
                                            readOnly
                                            disabled
                                        />
                                    </Col>
                                </Row>
                                <br />
                                <Card.Header>
                                    <Card.Title> Stops </Card.Title>
                                </Card.Header>
                                <Table>
                                    <thead>
                                        <th># </th>
                                        <th>Trip</th>
                                        <th>Location</th>
                                        <th>Address</th>
                                        <th>Timezone</th>
                                        <th>Arrive</th>
                                        <th>Action</th>
                                        <th>Depart</th>
                                    </thead>
                                    <tbody>
                                        {stops?.length > 0 ? stops.map((stop, i) => (
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{stop.trip}</td>
                                                <td>{stop.location.name}</td>
                                                <td>{stop.location.address1} {stop.location.address2} {stop.location.city} {stop.location.state} {stop.location.zipcode}</td>
                                                <td>{getTimezoneCode(stop.timezone)}</td>
                                                <td>{stop.arrive}</td>
                                                <td>{stop.action}</td>
                                                <td>{stop.depart}</td>
                                            </tr>
                                        )) :
                                            <tr>
                                                <th colSpan={'100%'}>No stops.</th>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>

                            </Card.Body>
                        }
                                                <Card.Header>
                            <Row>
                                <Col>
                                    <Card.Title onClick={() => shouldShowEquipment(!showEquipment)}>
                                        {showEquipment ? <FaCaretDown /> : <FaCaretRight />} Equipment
                                    </Card.Title>
                                </Col>
                                <Col>
                                    <Form.Check
                                        label={'Trailer Required'}
                                        className="float-end"
                                        checked={routeplan.trailerrequired}
                                    />
                                </Col>
                            </Row>
                        </Card.Header>
                        {showEquipment &&
                            <Card.Body>
                                <Row>
                                    <Col sm={12} md={6} lg={4}>
                                        Vehicle
                                        <Form.Control value={routeplan.vehiclecat} readOnly disabled />

                                        <Form.Check
                                            label={'Schedule Vehicle'}
                                            checked={routeplan.schedulevehicle}
                                        />
                                        {routeplan.schedulevehicle === true &&
                                            <pre>
                                                {JSON.stringify(routeplan.scheduledvehicle, "", 2)}
                                            </pre>
                                        }
                                    </Col>
                                    <Col sm={12} md={6} lg={4}>
                                        {routeplan.trailerrequired &&
                                            <>
                                                Trailer
                                                <Form.Control value={routeplan.trailercat} readOnly disabled />
                                                <Form.Check
                                                    label={'Schedule Trailer'}
                                                    checked={routeplan.scheduletrailer}
                                                />
                                                {routeplan.scheduletrailer === true &&
                                                    <pre>
                                                        {JSON.stringify(routeplan.scheduledtrailer, "", 2)}
                                                    </pre>
                                                }
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </Card.Body>
                        }
                        <Card.Header>
                            <Row>
                                <Col>
                                    <Card.Title onClick={() => shouldShowStandardOp(!showStandardOp)}>
                                        {showStandardOp ? <FaCaretDown /> : <FaCaretRight />}  Standard Operation Procedure : {
                                            moneyformatter.format(routeplan.blockpay ?
                                                (routeplan.blockpayhours * routeplan.payrateperhour)
                                                :
                                                (routeplan.sophours * routeplan.payrateperhour)
                                            )}
                                    </Card.Title>
                                </Col>
                            </Row>
                        </Card.Header>
                        {showStandardOp &&
                            <Card.Body>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Check
                                            type="radio"
                                            label="Pay by Hour"
                                            name="option"
                                            value="hour"
                                            checked={routeplan.payby === 'hour'}
                                            readOnly
                                            disabled
                                        />
                                        {(routeplan.payby === 'hour') &&
                                            <Form.Check
                                                label={'Block Pay'}
                                                checked={routeplan.blockpay}
                                                readOnly
                                                disabled
                                            />
                                        }
                                        <Form.Check
                                            type="radio"
                                            label="Pay by Mile"
                                            name="option"
                                            value="mile"
                                            checked={routeplan.payby === 'mile'}
                                            readOnly
                                            disabled
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <b> {(routeplan.blockpay !== true && routeplan.payby === 'hour') && '*'} SOP Hours</b>
                                        <Form.Control
                                            type="number"
                                            value={routeplan.sophrs}
                                            readOnly
                                            disabled
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        {(routeplan.blockpay === true && routeplan.payby === 'hour') &&
                                            <>
                                                <b>{(routeplan.blockpay === true && routeplan.payby === 'hour') && '*'} Block Hours </b>
                                                <Form.Control
                                                    type="number"
                                                    value={routeplan.blockpayhours}
                                                    readOnly
                                                    disabled
                                                />
                                            </>
                                        }
                                    </Col>
                                    <Col xs={12}>
                                        {routeplan.payby === 'hour' &&
                                            <Row>
                                                <Col><b> {routeplan.payby === 'hour' && '*'} Rate Per Hour</b>
                                                    <Form.Control type="number"
                                                        step="0.00"
                                                        value={routeplan.payrateperhour}
                                                        readOnly
                                                        disabled
                                                    />
                                                </Col>
                                                <Col><b>Pay Est.</b>
                                                    <Form.Control
                                                        type="text"
                                                        value={routeplan.payest}
                                                        readOnly
                                                        disabled
                                                    />
                                                </Col>
                                            </Row>
                                        }
                                    </Col>
                                    {routeplan.payby === 'hour' &&
                                        <>
                                            <br />
                                            <Col xs={12}>
                                                <Table striped bordered hover responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>Type</th>
                                                            <th>Rule</th>
                                                            <th>Amount</th>
                                                            <th>Effective</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {routeplan.contract.payrates?.length > 0 && routeplan.contract.payrates.map((rate, i) => (
                                                            <tr key={i}>
                                                                <td>{rate.type}</td>
                                                                <td>{rate.rule}</td>
                                                                <td>{rate.amount}</td>
                                                                <td>{rate.effective}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </>
                                    }
                                </Row>

                                {routeplan.payby === 'mile' &&
                                    <Row>
                                        <Col xs={12}>

                                        </Col>
                                        <Col xs={6}>
                                            <b  > {(routeplan.payby === 'mile') && '*'} Miles</b>
                                            <Form.Control type="number"
                                                placeholder="Measured miles"
                                                value={routeplan.routemiles}
                                                readOnly
                                                disabled
                                            />
                                        </Col>
                                        <Col>
                                            {routeplan.payby === 'mile' &&
                                                <Row>
                                                    <Col><b> {(routeplan.payby === 'mile') && '*'}Rate Per Mile</b>
                                                        <Form.Control
                                                            value={routeplan.payratepermile}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col><b>Pay Est.</b>
                                                        <Form.Control
                                                            type="text"
                                                            value={routeplan.payratepermile * routeplan.routemiles}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Col>
                                                </Row>
                                            }
                                        </Col>
                                    </Row>
                                }
                            </Card.Body>
                        }
                        <Card.Header>
                            <Row>
                                <Col>
                                    <Card.Title onClick={() => shouldShowService(!showService)}>
                                        {showService ? <FaCaretDown /> : <FaCaretRight />}  Service : {routeplan.frequency.name} - {routeplan.frequency.detail}
                                    </Card.Title>
                                </Col>
                            </Row>
                        </Card.Header>
                        {showService &&
                            <Card.Body>
                                <Row>
                                    <Col sm={12} md={6} lg={3}>
                                        Type
                                        <Form.Control value={routeplan.servicetype} readOnly disabled />
                                    </Col>
                                    <Col sm={12} md={6} lg={3}>
                                        Frequency
                                        <Form.Control value={routeplan.frequency.name} readOnly disabled />
                                    </Col>
                                    <Col sm={12} md={6} lg={3}>
                                        Start Op
                                        <Form.Control type="date" value={routeplan.startop} readOnly disabled />
                                    </Col>
                                    <Col sm={12} md={6} lg={3}>
                                        End Op
                                        <Form.Control type="date" value={routeplan.endop} readOnly disabled />
                                    </Col>
                                </Row>
                                <br />
                                <Container fluid style={{ overflowX: 'scroll' }}>
                                    <CalendarChart title={`${routeplan.frequency.name}`} opdata={ops} adjust={Math.ceil(ops.length / 365)} />
                                    <Card.Subtitle>Operations : {runct} / {ops.length}</Card.Subtitle>
                                </Container>
                            </Card.Body>
                        }

                        <Card.Header>
                            <Row>
                                <Col>
                                    <Card.Title onClick={() => shouldShowCoverage(!showCoverage)}>
                                        {showCoverage ? <FaCaretDown /> : <FaCaretRight />} Scheduled Coverage
                                    </Card.Title>
                                </Col>
                            </Row>
                        </Card.Header>
                        {showCoverage &&
                            <Card.Body>
                                <Row>
                                    <Col sm={12} md={6} lg={4}>
                                        Crew
                                        <Form.Control value={routeplan.crew} readOnly disabled />
                                    </Col>
                                    <Col sm={12} md={6} lg={4}>
                                        Period
                                        <Form.Control value={routeplan.period} readOnly disabled />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <Container fluid>
                                            <Card.Title>
                                                Week 1
                                            </Card.Title>
                                            <Row>
                                                {ROUTEPLAN_WK1_KEYS.map((wk1key, h) => (
                                                    CoverageCards(wk1key, h)
                                                ))}
                                            </Row>
                                            <br />
                                            {routeplan.period === 'Biweekly' &&
                                                <>
                                                    <Card.Title>
                                                        Week 2
                                                    </Card.Title>
                                                    <Row>
                                                        {ROUTEPLAN_WK2_KEYS.map((wk2key, h) => (
                                                            CoverageCards(wk2key, h)
                                                        ))}
                                                    </Row>
                                                </>}
                                        </Container>
                                    </Col>
                                </Row>

                            </Card.Body>
                        }
                        <Card.Header>
                            <Row>
                                <Col>
                                    <Card.Title onClick={() => shouldShowForecast(!showForecast)}>{showForecast ? <FaCaretDown /> : <FaCaretRight />} Forecast </Card.Title>
                                </Col>
                            </Row>
                        </Card.Header>
                        {showForecast &&
                            <Card.Body>
                                {forecast.length > 0 ?
                                    <>
                                        <DataDisplay dataSource={forecast} lsKey={'@mctms-route-detail-forecast'} />
                                    </>
                                    :
                                    <>
                                        No data.
                                    </>
                                }

                            </Card.Body>
                        }
                    </>
                }
            </Card>
        </>
    );
}

export default RoutePlanDetail;