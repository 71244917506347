import { Container, Card, Row, Col, Form, Button, Alert } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import { TRAILER_CATEGORIES, TRAILER_DOOR_CATEGORIES } from "../../context/appdata";
import AlertDisplay from "../../components/AlertDisplay";

const initialFormState = {
    assignment: '',
    domicle: '',
}

function TrailerForm() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [formdata, setFormdata] = useState(initialFormState)
    const [assignments, setAssignments] = useState([]);
    const [domiciles, setDomiciles] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    async function handlePost() {
        try {
            await Api.post('/trailers', formdata, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success' }])
            setFormdata(initialFormState)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating' }])
        }

    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Api.get('/assignments/live', idToken)
                setAssignments(data)
                const domiciles = await Api.get('/locations/live/Domicile', idToken)
                setDomiciles(domiciles)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [set, idToken, alerts])


    return (
        <Container fluid>
            <Card>
                <Card.Header><b>Trailer Form</b></Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                <Card.Body>
                    <Row>
                        <Col sm={12} md={6} lg={3}>
                            Unit Number
                            <Form.Control type="text" value={formdata.unitnumber} onChange={e => setFormdata({ ...formdata, unitnumber: e.target.value })} />
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            VIN
                            <Form.Control type="text" value={formdata.vin} onChange={e => setFormdata({ ...formdata, vin: e.target.value })} />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col sm={12} md={6} lg={3}>
                            Assignment
                            <Form.Select value={JSON.stringify(formdata.assignment)} onChange={e => setFormdata({ ...formdata, assignment: JSON.parse(e.target.value) })}>
                                <option>Select</option>
                                {assignments && assignments.map(assignment => (
                                    <option key={assignment._id} value={JSON.stringify(assignment)}>{assignment.name}</option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            Domicile
                            <Form.Select value={JSON.stringify(formdata.domicile)} onChange={e => setFormdata({ ...formdata, domicile: JSON.parse(e.target.value) })}>
                                <option>Select</option>
                                {domiciles && domiciles.map(domicile => (
                                    <option key={domicile._id} value={JSON.stringify(domicile)}>{domicile.name}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col sm={12} md={6} lg={3}>
                            Year
                            <Form.Control type="number" value={formdata.year} onChange={e => setFormdata({ ...formdata, year: e.target.value })} />
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            Make
                            <Form.Control type="text" value={formdata.make} onChange={e => setFormdata({ ...formdata, make: e.target.value })} />
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            Model
                            <Form.Control type="text" value={formdata.model} onChange={e => setFormdata({ ...formdata, model: e.target.value })} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col sm={12} md={6} lg={3}>
                            Category
                            <Form.Select value={formdata.category} onChange={e => setFormdata({ ...formdata, category: e.target.value })}>
                                <option>Select</option>
                                {TRAILER_CATEGORIES.map((cat, i) => (
                                    <option key={i}>{cat.name}</option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            Door Type
                            <Form.Select value={formdata.doortype} onChange={e => setFormdata({ ...formdata, doortype: e.target.value })}>
                                <option>Select</option>
                                {TRAILER_DOOR_CATEGORIES.map((door, i) => (
                                    <option key={i}>{door.name}</option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            Length (ft)
                            <Form.Control type="text" value={formdata.unitlength} onChange={e => setFormdata({ ...formdata, unitlength: e.target.value })} />
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            Volume (cubic ft)
                            <Form.Control type="text" value={formdata.volume} onChange={e => setFormdata({ ...formdata, volume: e.target.value })} />
                        </Col>
                    </Row>
                    <br />
                    <Button variant="primary" onClick={() => handlePost()}>Create</Button>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default TrailerForm;