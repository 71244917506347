import { Container, Card, Row, Col, Spinner, Button, Modal, ModalBody, ModalHeader, ModalFooter, Accordion, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/auth';
import { useSettings } from '../../context/settings'
import DataDisplay from "../../components/DataDisplay";
import { FaArrowLeft, FaArrowRight, FaChartPie, FaEye, FaSync, FaTable } from "react-icons/fa";
import { MdCircle, MdHideSource } from "react-icons/md";
import { findUSPSNASSCode, findUSPSNASSCodeState, formatUSPSDateTime } from "../../context/uspsdata";
import { adjustStringLength } from "../../utils/String.helper";
import AlertDisplay from "../../components/AlertDisplay";
import { formatDateStrToMMDDYY, formatDateStrToMMDDYYHHMM } from "../../utils/DateTimeFormat.helper";
import {
    EDI_214_SHIPMENT_STATUS_CODES_MAIL_PICKUP_SPECIFIC,
    EDI_214_SHIPMENT_STATUS_CODES_MAIL_DELIVERY_SPECIFIC
} from "../../context/klienschmidtdata";
import LOAD_TENDER_REPORTS from './LoadTender.reports.json'
import { multidimensionalGroupBy } from "../../utils/Array.helper";
import PrintPDF from "../../components/PrintPDF";
import { Chart } from 'react-google-charts';
import LogisticsLoading from "../../components/LogisticsLoading";

const LOAD_TENDER_GROUPS = multidimensionalGroupBy(LOAD_TENDER_REPORTS, ['group']);

//todo dedeup
const today = new Date();  // today based on the clock
const tomorrow = new Date(today); // based on today value
const yesterday = new Date(today); // based on today value
const minusSeven = new Date(today); // minus 7 days
const minusFourteen = new Date(today); // minus 14 days
const minusThirty = new Date(today); // minus 30 days
const minusSixty = new Date(today); // minus 30 days
const minusNinty = new Date(today); // minus 30 days
const plusSeven = new Date(today); // based on today value, plus 7 days
const plusFourteen = new Date(today); // based on today value, plus 14 days
const plusThirty = new Date(today); // based on today value, plus 30 days
const plusSixty = new Date(today); // based on today value, plus 30 days
const plusNinty = new Date(today); // based on today value, plus 30 days
const lastSunday = new Date(today); // based on today value, the last week's Sunday
const lastSaturday = new Date(today);// based on today value, the last week's Saturday
const thisSunday = new Date(today); // based on today value, the current week's Sunday
const thisSaturday = new Date(today);// based on today value, the current week's Saturday
const nextSunday = new Date(today);// based on today value, the next week's Sunday
const nextSaturday = new Date(today);// based on today value, the next week's Saturday
const newyears = new Date(today); // current calendar's first day of the year
const newyearseve = new Date(today); // current calendar's last day of the year
const currentYear = today.getFullYear();
const currentMonth = today.getMonth();
const nextMonth = new Date(today);
nextMonth.setMonth(currentMonth + 1);
const firstdayofthemonth = new Date(currentYear, currentMonth, 1);
const lastdayofthemonth = new Date(currentYear, currentMonth + 1, 0);
const firstdayoflastmonth = new Date(currentYear, currentMonth - 1, 1);
const lastdayoflastmonth = new Date(currentYear, currentMonth, 0);
const firstdayofnextmonth = new Date(currentYear, currentMonth + 1, 1);
const lastDayOfNextMonth = new Date(nextMonth);
lastDayOfNextMonth.setMonth(nextMonth.getMonth() + 1, 0);

tomorrow.setDate(today.getDate() + 1);
yesterday.setDate(today.getDate() - 1);
minusSeven.setDate(today.getDate() - 7);
minusFourteen.setDate(today.getDate() - 14);
minusThirty.setDate(today.getDate() - 30);
minusSixty.setDate(today.getDate() - 60);
minusNinty.setDate(today.getDate() - 90);
plusSeven.setDate(today.getDate() + 7);
plusFourteen.setDate(today.getDate() + 14);
plusThirty.setDate(today.getDate() + 30);
plusSixty.setDate(today.getDate() + 60);
plusNinty.setDate(today.getDate() + 90);
const year = new Date().getFullYear(); // Current year
const fiscalQuarters = {
    q1: { start: new Date(year, 0, 1), end: new Date(year, 2, 31) },
    q2: { start: new Date(year, 3, 1), end: new Date(year, 5, 30) },
    q3: { start: new Date(year, 6, 1), end: new Date(year, 8, 30) },
    q4: { start: new Date(year, 9, 1), end: new Date(year, 11, 31) }
};


// Calculate the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
const currentDayOfWeek = today.getDay();

// Calculate the date for the last week's Sunday and Saturday
lastSunday.setDate(today.getDate() - (currentDayOfWeek + 7));
lastSaturday.setDate(today.getDate() - (currentDayOfWeek + 1));

// Calculate the date for the current week's Sunday and Saturday
thisSunday.setDate(today.getDate() - currentDayOfWeek);
thisSaturday.setDate(today.getDate() + (6 - currentDayOfWeek));

// Calculate the date for the next week's Sunday and Saturday
nextSunday.setDate(today.getDate() + (7 - currentDayOfWeek));
nextSaturday.setDate(today.getDate() + (13 - currentDayOfWeek));

// Calculate the first day and last day of the current year
newyears.setMonth(0); // January (0 index)
newyears.setDate(1);

newyearseve.setMonth(11); // December (0 index)
newyearseve.setDate(31);

function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    return `${year}-${month}-${day}`;
}

const initialQuery = {
    start: formatDate(today),
    end: formatDate(today),
    loadtenderLifeCycleStatus: 'All',
    contract: 'All',
    place: 'All',
    state: 'All',
    city: 'All',
    driver: 'All'
}

function LoadTenderDashboard() {
    const { settings } = useSettings();
    const { idToken } = useAuth();

    const [pause, isPaused] = useState(false);

    const [fetchDropdownDataOptions, didFetchDropdownDataOptions] = useState();
    const [fetched, setFetched] = useState(false);
    const [contracts, setContracts] = useState([]);
    const [places, setPlaces] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [edi204, setEDI204] = useState();
    const [edi204Clean, setEDI204Clean] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    const [modalActive, setScatterPlotModalActive] = useState();

    const [query, setQuery] = useState(() => {
        const savedQuery = localStorage.getItem('@mctms-loadtender-query');
        return savedQuery ? JSON.parse(savedQuery) : initialQuery;
    });
    const [period, setPeriod] = useState(() => {
        const savedPeriod = localStorage.getItem('@mctms-loadtender-period');
        return savedPeriod ? JSON.parse(savedPeriod) : 'today';
    });
    const [report, setReport] = useState(() => {
        const savedReport = localStorage.getItem('@mctms-loadtender-report');
        return savedReport ? JSON.parse(savedReport) : 'loadtender-all';
    });

    function objectToQueryString(obj) {
        const params = new URLSearchParams();
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                params.append(key, obj[key]);
            }
        }
        return params.toString();
    }

    const [displayToggle, setDisplayToggle] = useState();
    const [showCodes, setShowCodes] = useState();
    const [showHelp, setShowHelp] = useState();

    const labelStyle = {
        fontSize: '1.1em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        color: '#222',
        whiteSpace: 'nowrap',
        fontWeight: 'bold'
    }

    const labelOptionContainerStyle = {
        display: 'flex',
        padding: '0.25em',
    }

    const resetStyle = {
        fontSize: '.75em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        backgroundColor: '#fff5bd',
        border: '1px solid',
        borderColor: '#222',
        borderRadius: '0.25em',
        whiteSpace: 'nowrap',
    }

    const reloadStyle = {
        fontSize: '.75em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        backgroundColor: '#bcdbbc',
        border: '1px solid',
        borderColor: '#222',
        borderRadius: '0.25em',
        whiteSpace: 'nowrap',
    }

    const dataButtonStyle = {
        fontSize: '.75em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        backgroundColor: '#6eb6ff',
        border: '1px solid',
        borderColor: '#222',
        borderRadius: '0.25em',
        whiteSpace: 'nowrap',
    }

    const codesButtonStyle = {
        fontSize: '.75em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        backgroundColor: '#fbb70e',
        border: '1px solid',
        borderColor: '#222',
        borderRadius: '0.25em',
        whiteSpace: 'nowrap',
    }

    const helpButtonStyle = {
        fontSize: '.75em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        backgroundColor: '#f39c9c',
        border: '1px solid',
        borderColor: '#222',
        borderRadius: '0.25em',
        whiteSpace: 'nowrap',
    }

    const buttonStyle = {
        fontSize: '.8em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        backgroundColor: '#fff',
        border: '1px solid',
        borderColor: '#222',
        borderRadius: '0.25em',
        whiteSpace: 'nowrap'
    }

    const buttonSelectedStyle = {
        fontSize: '.8em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        backgroundColor: '#6eb6ff',
        border: '1px solid',
        borderColor: '#222',
        borderRadius: '0.25em',
        whiteSpace: 'nowrap'
    }

    // function objectToQueryString(obj) {
    //     const params = new URLSearchParams();
    //     for (const key in obj) {
    //         if (obj.hasOwnProperty(key)) {
    //             params.append(key, obj[key]);
    //         }
    //     }
    //     return params.toString();
    // }

    function setQueryStart(value) {
        if (value <= query.end) {
            setPeriod('custom')
            setQuery({ ...query, start: value })
            setFetched(false)
        } else {
            setAlerts([...alerts, { variant: 'warning', message: 'Start should come before end.' }]);
        }
    }
    function setQueryEnd(value) {
        if (value >= query.start) {
            setPeriod('custom')
            setQuery({ ...query, end: value })
            setFetched(false)
        } else {
            setAlerts([...alerts, { variant: 'warning', message: 'End should come after start.' }]);
        }
    }
    function setQueryLoadTenderStatus(value) {
        setQuery({ ...query, loadtenderLifeCycleStatus: value })
        setFetched(false)
    }
    function setQueryContract(value) {
        setQuery({ ...query, contract: value })
        setFetched(false)
    }
    function setQueryState(value) {
        setQuery({ ...query, state: value, place: 'All' })
        setFetched(false)
    }
    function setQueryPlace(value) {
        if (value === 'All') {
            setQuery({ ...query, place: 'All', state: 'All', city: 'All' })
        } else {
            setQuery({ ...query, place: value, state: value.state, city: value.city })
        }
        setFetched(false)
    }
    function setQueryDriver(value) {
        if (value === 'All') {
            setQuery({ ...query, driver: 'All' })
        } else {
            setQuery({ ...query, driver: value })
        }
        setFetched(false)
    }
    function setQueryToday() {
        setQuery({ ...query, start: formatDate(today), end: formatDate(today) })
        setPeriod('today')
        setFetched(false)
    }
    function setQueryTomorrow() {
        setQuery({ ...query, start: formatDate(tomorrow), end: formatDate(tomorrow) })
        setPeriod('tomorrow')
        setFetched(false)
    }
    function setQueryYesterday() {
        setQuery({ ...query, start: formatDate(yesterday), end: formatDate(yesterday) })
        setPeriod('yesterday')
        setFetched(false)
    }
    function setThisWeek() {
        setQuery({ ...query, start: formatDate(thisSunday), end: formatDate(thisSaturday) })
        setPeriod('thisweek')
        setFetched(false)
    }
    function setLastWeek() {
        setQuery({ ...query, start: formatDate(lastSunday), end: formatDate(lastSaturday) })
        setPeriod('lastweek')
        setFetched(false)
    }
    function setNextWeek() {
        setQuery({ ...query, start: formatDate(nextSunday), end: formatDate(nextSaturday) })
        setPeriod('nextweek')
        setFetched(false)
    }
    function setThisMonth() {
        setQuery({ ...query, start: formatDate(firstdayofthemonth), end: formatDate(lastdayofthemonth) })
        setPeriod('thismonth')
        setFetched(false)
    }
    function setLastMonth() {
        setQuery({ ...query, start: formatDate(firstdayoflastmonth), end: formatDate(lastdayoflastmonth) })
        setPeriod('lastmonth')
        setFetched(false)
    }
    function setNextMonth() {
        setQuery({ ...query, start: formatDate(firstdayofnextmonth), end: formatDate(lastDayOfNextMonth) })
        setPeriod('nextmonth')
        setFetched(false)
    }
    function setQueryMinus7() {
        setQuery({ ...query, start: formatDate(minusSeven), end: formatDate(today) })
        setPeriod('minus7')
        setFetched(false)
    }
    function setQueryMinus14() {
        setQuery({ ...query, start: formatDate(minusFourteen), end: formatDate(today) })
        setPeriod('minus14')
        setFetched(false)
    }
    function setQueryMinus30() {
        setQuery({ ...query, start: formatDate(minusThirty), end: formatDate(today) })
        setPeriod('minus30')
        setFetched(false)
    }
    function setQueryMinus60() {
        setQuery({ ...query, start: formatDate(minusSixty), end: formatDate(today) })
        setPeriod('minus60')
        setFetched(false)
    }
    function setQueryMinus90() {
        setQuery({ ...query, start: formatDate(minusNinty), end: formatDate(today) })
        setPeriod('minus90')
        setFetched(false)
    }
    function setQueryPlus7() {
        setQuery({ ...query, start: formatDate(today), end: formatDate(plusSeven) })
        setPeriod('plus7')
        setFetched(false)
    }
    function setQueryPlus14() {
        setQuery({ ...query, start: formatDate(today), end: formatDate(plusFourteen) })
        setPeriod('plus14')
        setFetched(false)
    }
    function setQueryPlus30() {
        setQuery({ ...query, start: formatDate(today), end: formatDate(plusThirty) })
        setPeriod('plus30')
        setFetched(false)
    }
    function setQueryPlus60() {
        setQuery({ ...query, start: formatDate(today), end: formatDate(plusSixty) })
        setPeriod('plus60')
        setFetched(false)
    }
    function setQueryPlus90() {
        setQuery({ ...query, start: formatDate(today), end: formatDate(plusNinty) })
        setPeriod('plus90')
        setFetched(false)
    }
    function setQueryYTD() {
        setQuery({ ...query, start: formatDate(newyears), end: formatDate(today) })
        setPeriod('ytd')
        setFetched(false)
    }
    function setQueryQ1() {
        setQuery({ ...query, start: formatDate(fiscalQuarters.q1.start), end: formatDate(fiscalQuarters.q1.end) })
        setPeriod('q1')
        setFetched(false)
    }
    function setQueryQ2() {
        setQuery({ ...query, start: formatDate(fiscalQuarters.q2.start), end: formatDate(fiscalQuarters.q2.end) })
        setPeriod('q2')
        setFetched(false)
    }
    function setQueryQ3() {
        setQuery({ ...query, start: formatDate(fiscalQuarters.q3.start), end: formatDate(fiscalQuarters.q3.end) })
        setPeriod('q3')
        setFetched(false)
    }
    function setQueryQ4() {
        setQuery({ ...query, start: formatDate(fiscalQuarters.q4.start), end: formatDate(fiscalQuarters.q4.end) })
        setPeriod('q4')
        setFetched(false)
    }
    function setQueryYear() {
        setQuery({ ...query, start: formatDate(newyears), end: formatDate(newyearseve) })
        setPeriod('year')
        setFetched(false)
    }

    function extractUniqueValues(arr) {
        const uniqueValues = new Set();

        arr.forEach(subArray => {
            subArray.forEach(value => {
                uniqueValues.add(value);
            });
        });

        return Array.from(uniqueValues);
    }

    function extractPrefixes(arr) {
        return arr.map(item => {
            const parts = item.split("_"); // Split the string into parts using the underscore character
            if (parts.length >= 2) {
                return parts.slice(0, 2).join("_"); // Combine the first two parts with an underscore
            } else {
                return item; // If there are fewer than 2 parts, return the original item
            }
        });
    }

    function updateReport(rpt) {
        setReport(rpt)
        setFetched(false)
    }

    useEffect(() => {
        localStorage.setItem('@mctms-loadtender-query', JSON.stringify(query));
    }, [query]);

    useEffect(() => {
        localStorage.setItem('@mctms-loadtender-period', JSON.stringify(period));
    }, [period]);

    useEffect(() => {
        localStorage.setItem('@mctms-loadtender-report', JSON.stringify(report));
    }, [report]);

    useEffect(() => {
        function cleanLoadTenders(edi204s) {
            if (Array(edi204s) && edi204s.length > 0) {
                const cleanTenders = edi204s.map(record => {
                    const extractedReferences = record?.Stops.map(via => via?.References.map(referance => referance?.Identifier));
                    const extractedValues = extractUniqueValues(extractedReferences)
                    const extractedData = extractPrefixes(extractedValues)
                    const uniqueData = [...new Set(extractedData)];
                    const schedule = adjustStringLength('S#', '2', '\u0020', false)
                        + ' | ' + adjustStringLength('LOCATION', '25', '\u0020', true)
                        + ' | ' + adjustStringLength('ARRIVE', '18', '\u0020', true)
                        + ' | ' + adjustStringLength('DEPART', '18', '\u0020', true)
                        + ' | ' + adjustStringLength('REASON', '18', '\u0020', true);

                    const scheduleAudit = adjustStringLength('S#', '2', '\u0020', false)
                        + ' | ' + adjustStringLength('LOCATION', '25', '\u0020', true)
                        + ' | ' + adjustStringLength('ARRIVE', '18', '\u0020', true)
                        + ' | ' + adjustStringLength('ARV', '4', '\u0020', true)
                        + ' | ' + adjustStringLength('DEPART', '18', '\u0020', true)
                        + ' | ' + adjustStringLength('DPT', '4', '\u0020', true)
                        + ' | ' + adjustStringLength('REASON', '18', '\u0020', true);

                    const edi214Updates = adjustStringLength('S#', '2', '\u0020', false)
                        + ' | ' + adjustStringLength('CODE', '4', '\u0020', true)
                        + ' | ' + adjustStringLength('DATETIME', '18', '\u0020', true)


                    const loadtenderSchedule = schedule + '\n' + record?.Stops.map(via => `${adjustStringLength(via?.StopNum, '2', '\u0020', false)
                        } | ${adjustStringLength((findUSPSNASSCode(via?.Name) + ', ' + findUSPSNASSCodeState(via?.Name)), '25', '\u0020', true)
                        } | ${adjustStringLength(formatUSPSDateTime(via?.Name, via?.RequestedDate, settings.timezone), '18', '\u0020', true)
                        } | ${adjustStringLength(formatUSPSDateTime(via?.Name, via?.LastRequestedDate, settings.timezone), '18', '\u0020', true)
                        } | ${adjustStringLength(via?.ReasonDesc, '18', ' ', true)
                        }`).join('\n');

                    const loadtenderAduit = record?.loadtenderTelematicsData.length > 0 ? `${record?.loadtenderTelematicsData.map(record =>
                        record.telematics.map(report => `${report?.operator?.name}\n${scheduleAudit}\n` +
                            report.linearizeLoadTenderAndLogs.map(via => `${adjustStringLength(via?.StopNum, '2', '\u0020', false)
                                } | ${adjustStringLength((findUSPSNASSCode(via?.Name) + ', ' + findUSPSNASSCodeState(via?.Name)), '25', '\u0020', true)
                                } | ${adjustStringLength(formatUSPSDateTime(via?.Name, via?.RequestedDate, settings.timezone), '18', '\u0020', true)
                                } | ${adjustStringLength(String(via?.eldOnPremiseArriveDiffMin), '4', '\u0020', true)
                                } | ${adjustStringLength(formatUSPSDateTime(via?.Name, via?.LastRequestedDate, settings.timezone), '18', '\u0020', true)
                                } | ${adjustStringLength(String(via?.eldOnPremiseDepartDiffMin), '4', '\u0020', true)
                                } | ${adjustStringLength(via?.ReasonDesc, '18', ' ', true)
                                }`).join('\n'))).join('\n')}` : 'No audit.'
                    return {
                        _id: record._id,
                        'Shipment Id': record?.ShipmentId,
                        'Status': record?.loadtenderLifeCycleStatus,
                        'TypeDesc': record?.TypeDesc,
                        'Contract': uniqueData.map(item => item.split('_')[0]).join(', \n'),
                        'Trip': uniqueData.map(item => item.split('_')[1]).join(', \n'),
                        'Contract Trip': uniqueData.join(', \n').replace('_', ' '),
                        'Coverage': record?.operators ? record?.operators.map(op => `${op.name}`).join('\n') : '',
                        'Service Date': formatUSPSDateTime(record?.Stops[0].Name, record?.Stops[0].RequestedDate, settings.timezone).split(' ')[0],
                        '1st Stop Arrive': formatUSPSDateTime(record?.Stops[0].Name, record?.Stops[0].RequestedDate, settings.timezone),
                        'Last Stop Depart': formatUSPSDateTime(record?.Stops[0].Name, record?.Stops[record?.Stops.length - 1].LastRequestedDate, settings.timezone),
                        'Equipment': record?.Equipment,
                        'Schedule': loadtenderSchedule,
                        'EDI Updates': edi214Updates + '\n' + record?.edi214Data?.map(msg => ` ${adjustStringLength(msg.ApptNumber, '2', '\u0020', true)} | ${adjustStringLength(msg.StatusCode, '4', '\u0020', true)} | ${adjustStringLength(formatDateStrToMMDDYYHHMM(msg.StatusDate) + ' ' + msg.TimeZone, '18', '\u0020', true)}`).join('\n'),
                        'Audit': loadtenderAduit,
                        'USPS Arrive': record?.Stops[0].RequestedDate,
                        'USPS Depart': record?.Stops[record?.Stops.length - 1].LastRequestedDate,
                    }
                })
                return cleanTenders;
            } else {
                return []
            }
        }

        function cleanLoadTenderAudits(edi204Audits) {
            if (Array(edi204Audits) && edi204Audits.length > 0) {
                const cleanTenders = edi204Audits.map(record => {
                    const extractedReferences = record?.newest?.Stops.map(via => via?.References.map(referance => referance?.Identifier));
                    const extractedValues = extractUniqueValues(extractedReferences)
                    const extractedData = extractPrefixes(extractedValues)
                    const uniqueData = [...new Set(extractedData)];
                    const schedule = adjustStringLength('S#', '2', '\u0020', false)
                        + ' | ' + adjustStringLength('LOCATION', '25', '\u0020', true)
                        + ' | ' + adjustStringLength('ARRIVE', '18', '\u0020', true)
                        + ' | ' + adjustStringLength('DEPART', '18', '\u0020', true)
                        + ' | ' + adjustStringLength('REASON', '18', '\u0020', true);

                    const scheduleAudit = adjustStringLength('S#', '2', '\u0020', false)
                        + ' | ' + adjustStringLength('LOCATION', '25', '\u0020', true)
                        + ' | ' + adjustStringLength('ARRIVE', '18', '\u0020', true)
                        + ' | ' + adjustStringLength('ARV', '4', '\u0020', true)
                        + ' | ' + adjustStringLength('DEPART', '18', '\u0020', true)
                        + ' | ' + adjustStringLength('DPT', '4', '\u0020', true)
                        + ' | ' + adjustStringLength('REASON', '18', '\u0020', true);

                    const edi214Updates = adjustStringLength('S#', '2', '\u0020', false)
                        + ' | ' + adjustStringLength('CODE', '4', '\u0020', true)
                        + ' | ' + adjustStringLength('DATETIME', '18', '\u0020', true)

                    return {
                        _id: record.original._id,
                        'Shipment Id': record?.newest?.ShipmentId,
                        'Versions': record?.versions.length,
                        'TypeDesc': record?.newest?.TypeDesc,
                        'Status': record?.newest?.loadtenderLifeCycleStatus,
                        'Contract': uniqueData.map(item => item.split('_')[0]).join(', \n'),
                        'Trip': uniqueData.map(item => item.split('_')[1]).join(', \n'),
                        'Contract Trip': uniqueData.join(', \n').replace('_', ' '),
                        'Coverage': record?.original?.operators ? record?.original?.operators.map(op => `${op.name}`).join('\n') : '',
                        'Service Date': formatUSPSDateTime(record?.newest?.Stops[0].Name, record?.newest?.Stops[0].RequestedDate, settings.timezone).split(' ')[0],
                        '1st Stop Arrive': formatUSPSDateTime(record?.newest?.Stops[0].Name, record?.newest?.Stops[0].RequestedDate, settings.timezone),
                        'Last Stop Depart': formatUSPSDateTime(record?.newest?.Stops[0].Name, record?.newest?.Stops[record?.newest?.Stops.length - 1].LastRequestedDate, settings.timezone),
                        'Equipment': record?.newest?.Equipment,
                        'Schedule': schedule + '\n' + record?.newest?.Stops.map(via => `${adjustStringLength(via?.StopNum, '2', '\u0020', false)
                            } | ${adjustStringLength((findUSPSNASSCode(via?.Name) + ', ' + findUSPSNASSCodeState(via?.Name)), '25', '\u0020', true)
                            } | ${adjustStringLength(formatUSPSDateTime(via?.Name, via?.RequestedDate, settings.timezone), '18', '\u0020', true)
                            } | ${adjustStringLength(formatUSPSDateTime(via?.Name, via?.LastRequestedDate, settings.timezone), '18', '\u0020', true)
                            } | ${adjustStringLength(via?.ReasonDesc, '18', ' ', true)
                            }`).join('\n'),
                        'EDI Updates': edi214Updates + '\n' + record?.newest?.edi214Data?.map(msg => ` ${adjustStringLength(msg.ApptNumber, '2', '\u0020', true)} | ${adjustStringLength(msg.StatusCode, '4', '\u0020', true)} | ${adjustStringLength(formatDateStrToMMDDYYHHMM(msg.StatusDate) + ' ' + msg.TimeZone, '18', '\u0020', true)}`).join('\n'),
                        'Audit': `${scheduleAudit}${record?.newest?.loadtenderTelematicsData.map(data =>
                            data.telematics.map(report => `\n${report?.operator?.name}\n` +
                                report.linearizeLoadTenderAndLogs.map(via => `${adjustStringLength(via?.StopNum, '2', '\u0020', false)
                                    } | ${adjustStringLength((findUSPSNASSCode(via?.Name) + ', ' + findUSPSNASSCodeState(via?.Name)), '25', '\u0020', true)
                                    } | ${adjustStringLength(formatUSPSDateTime(via?.Name, via?.RequestedDate, settings.timezone), '18', '\u0020', true)
                                    } | ${adjustStringLength(String(via?.eldOnPremiseArriveDiffMin), '4', '\u0020', true)
                                    } | ${adjustStringLength(formatUSPSDateTime(via?.Name, via?.LastRequestedDate, settings.timezone), '18', '\u0020', true)
                                    } | ${adjustStringLength(String(via?.eldOnPremiseDepartDiffMin), '4', '\u0020', true)
                                    } | ${adjustStringLength(via?.ReasonDesc, '18', ' ', true)
                                    }`).join('\n'))).join('\n')}`,
                        'USPS Arrive': record?.newest?.Stops[0].RequestedDate,
                        'USPS Depart': record?.newest?.Stops[record?.newest?.Stops.length - 1].LastRequestedDate,
                    }
                })
                return cleanTenders;
            } else {
                return []
            }
        }

        const fetchFilterData = async () => {
            try {
                const ediContracts = await Api.get(`/edi204/contracts`, idToken);
                setContracts(ediContracts)
            } catch (err) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured getting Contracts.' }])
            }
            try {
                const ediPlaces = await Api.get(`/edi204/places`, idToken);
                setPlaces(ediPlaces)
            } catch (err) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured getting Places.' }])
            }
            try {
                const ediDrivers = await Api.get(`/edi204/operators`, idToken);
                setDrivers(ediDrivers)
            } catch (err) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured getting Drivers.' }])
            }
            didFetchDropdownDataOptions(true)
        }

        const fetchReport = async () => {
            try {
                if (report) {
                    // console.log('Fetch Report ', report.name)
                    const filters = objectToQueryString(query);
                    const filtereddata = await Api.get(`/edi204/report/${report.name}?${filters}`, idToken)
                    let cleanData = [];
                    if (report.data === 'edi204') {
                        cleanData = cleanLoadTenders(filtereddata)
                    }
                    if (report.data === 'edi204-telematics') {
                        cleanData = cleanLoadTenderAudits(filtereddata)
                    }
                    setEDI204(filtereddata);
                    setEDI204Clean(cleanData)
                } else {
                    setEDI204([]);
                    setEDI204Clean([])
                    setAlerts([...alerts, { variant: 'warning', message: 'Select a report.' }]);
                }

            } catch {
                setEDI204([]);
                setEDI204Clean([])
                setAlerts([...alerts, { variant: 'warning', message: 'Error fetching report' }]);

            }
            setFetched(true);
            isPaused(false)
        }

        if (!fetchDropdownDataOptions) {
            fetchFilterData();
        }

        if (!fetched) {
            // console.log('Mount fetch')
            fetchReport()
            isPaused(true)
        }

    }, [fetchDropdownDataOptions, pause, fetched, alerts, idToken, query, report, settings])

    //  charts
    function loadtenderStatusChart(edi204s) {
        const newestEDI204s = edi204s.map(record => record.newest)
        const groupeddata = multidimensionalGroupBy(newestEDI204s, ['loadtenderLifeCycleStatus']);
        const graphdata = Object.keys(groupeddata).sort().map(key => ([`${key} (${groupeddata[key].length})`, groupeddata[key].length]));
        const graphheader = ['Life Cycle Status', 'Load Tender Ct.']
        const chartData = [graphheader, ...graphdata];
        let chartType;
        if (Object.keys(chartData).length >= 5) {
            chartType = 'BarChart'
        } else {
            chartType = 'PieChart'
        }
        return { type: chartType, data: chartData };
    }

    function loadtenderEquipmentChart(edi204s) {
        const newestEDI204s = edi204s.map(record => record.newest)
        const groupeddata = multidimensionalGroupBy(newestEDI204s, ['Equipment']);
        const graphdata = Object.keys(groupeddata).sort().map(key => ([`${key} (${groupeddata[key].length})`, groupeddata[key].length]));
        const graphheader = ['Equipment', 'Load Tender Ct.']
        const chartData = [graphheader, ...graphdata];
        let chartType;
        if (Object.keys(chartData).length >= 5) {
            chartType = 'BarChart'
        } else {
            chartType = 'PieChart'
        }
        return { type: chartType, data: chartData };
    }

    function loadtenderContractChart(edi204sClean) {
        const groupeddata = multidimensionalGroupBy(edi204sClean, ['Contract']); const graphdata = Object.keys(groupeddata).sort().map(key => ([`${key} (${groupeddata[key].length})`, groupeddata[key].length]));
        const graphheader = ['Contract', 'Load Tender Ct.']
        const chartData = [graphheader, ...graphdata];
        let chartType;
        if (Object.keys(chartData).length >= 5) {
            chartType = 'BarChart'
        } else {
            chartType = 'PieChart'
        }
        return { type: chartType, data: chartData };
    }

    function loadtenderCoverageChart(edi204sClean) {
        let unassigned = 0, covered = 0;
        edi204sClean.forEach(record => {
            if (record['Coverage'].trim('') !== '') {
                covered++;
            } else {
                unassigned++;
            }
        })

        const graphheader = ['Coverage', 'Load Tender Ct.']
        const chartData = [graphheader, [`Open (${unassigned})`, unassigned], [`Covered (${covered})`, covered]];
        return { type: 'PieChart', data: chartData };
    }

    function loadtenderGradedChart(edi204s) {
        let ungradeded = 0, graded = 0;
        edi204s.forEach(record => {
            if (record?.newest?.loadtenderTelematicsData.length > 0) {
                graded++;
            } else {
                ungradeded++;
            }
        })

        const graphheader = ['Coverage', 'Load Tender Ct.']
        const chartData = [graphheader, [`Ungraded (${ungradeded})`, ungradeded], [`Graded (${graded})`, graded]];
        return { type: 'PieChart', data: chartData };
    }

    function loadtenderCoverageBarChart(edi204sClean) {
        const groupeddata = multidimensionalGroupBy(edi204sClean, ['Coverage']);
        const graphdata = Object.keys(groupeddata).sort().map(key => ([`${key} (${groupeddata[key].length})`, groupeddata[key].length])).sort();
        const graphheader = ['Coverage', 'Load Tender Ct.']
        const chartData = [graphheader, ...graphdata];
        return chartData
    }

    function loadtenderContractTripBarChart(edi204sClean) {
        const groupeddata = multidimensionalGroupBy(edi204sClean, ['Contract Trip']);
        const graphdata = Object.keys(groupeddata).sort().map(key => ([`${key} (${groupeddata[key].length})`, groupeddata[key].length]));
        const graphheader = ['Contract Trip', 'Load Tender Ct.']
        const chartData = [graphheader, ...graphdata];
        return chartData
    }

    function loadtenderContractTripDateBarChart(edi204sClean) {
        const groupeddata = multidimensionalGroupBy(edi204sClean, ['Service Date']);
        const graphdata = Object.keys(groupeddata).sort().map(key => ([`${key} (${groupeddata[key].length})`, groupeddata[key].length]));
        const graphheader = ['Service Date', 'Load Tender Ct.']
        const pieChartData = [graphheader, ...graphdata];
        return pieChartData
    }

    function loadtenderContractTripTimeline(edi204sClean) {
        const data = [];
        const headers = [
            { type: "string", id: "Contract" },
            { type: "string", id: "Trip" },
            { type: "string", role: "tooltip", 'p': { 'html': true } },
            { type: "date", id: "Start" },
            { type: "date", id: "End" },
        ];
        data.push(headers);
        edi204sClean.forEach(record => {
            const tooltipContent = `
                <div>
                    <strong>Id:</strong> ${record['_id']}<br>
                    <strong>Driver(s): </strong> <br/>${(record['Coverage'].replace('\n', '<br/>'))}<br>
                    <strong>Contract:</strong> ${record['Contract']}<br>
                    <strong>Trip:</strong> ${record['Trip']}<br>
                    <strong>Start:</strong> ${record['1st Stop Arrive']}<br>
                    <strong>End:</strong> ${record['Last Stop Depart']}<br>
                </div>
            `;
            data.push([
                record['Contract'],
                record['Trip'],
                tooltipContent,
                new Date(record['USPS Arrive']),
                new Date(record['USPS Depart']),
            ]);
        })
        // console.log(data)
        return data;
    }

    function loadtenderSankeyCoverage(edi204Clean) {
        const data = [
            ["From", "To", "Count"],
        ];

        const coverage = multidimensionalGroupBy(edi204Clean, ['Coverage']);

        for (let i = 0; i < Object.keys(coverage).length; i++) {
            const driver = Object.keys(coverage)[i];
            const tenderCt = coverage[driver].length;
            const uniqueContractTrips = {}

            for (let t = 0; t < tenderCt; t++) {
                const contract_trip = coverage[driver][t]['Contract Trip'];
                if (Object.keys(uniqueContractTrips).includes(contract_trip)) {
                    uniqueContractTrips[contract_trip] = uniqueContractTrips[contract_trip] + 1
                } else {
                    uniqueContractTrips[contract_trip] = 1
                }
            }

            for (let j = 0; j < Object.keys(uniqueContractTrips).length; j++) {
                const trip = Object.keys(uniqueContractTrips)[j]
                const weight = uniqueContractTrips[trip]
                const tripNode = trip ? trip : 'Open';
                data.push([driver, tripNode, weight])
            }
        }

        // console.log(data)
        return data;
    }

    function loadtenderSankeyTripCoverage(edi204Clean) {
        const data = [
            ["From", "To", "Count"],
        ];

        const coverage = multidimensionalGroupBy(edi204Clean, ['Coverage']);

        for (let i = 0; i < Object.keys(coverage).length; i++) {
            const driver = Object.keys(coverage)[i];
            const tenderCt = coverage[driver].length;
            const uniqueContractTrips = {}

            for (let t = 0; t < tenderCt; t++) {
                const contract_trip = coverage[driver][t]['Contract Trip'];
                if (Object.keys(uniqueContractTrips).includes(contract_trip)) {
                    uniqueContractTrips[contract_trip] = uniqueContractTrips[contract_trip] + 1
                } else {
                    uniqueContractTrips[contract_trip] = 1
                }
            }

            for (let j = 0; j < Object.keys(uniqueContractTrips).length; j++) {
                const trip = Object.keys(uniqueContractTrips)[j]
                const weight = uniqueContractTrips[trip]
                const tripNode = trip ? trip : 'Open';
                data.push([tripNode, driver, weight])
            }
        }

        // console.log(data)
        return data;
    }

    // function loadtenderContractTripTimeline(edi204Clean) {
    //     const data = [];
    //     const headers = [
    //         { type: "string", id: "Contract" },
    //         { type: "string", id: "Trip" },
    //         { type: "string", role: "tooltip" },
    //         { type: "date", id: "Start" },
    //         { type: "date", id: "End" },
    //     ]
    //     data.push(headers);
    //     edi204Clean.forEach(record => {
    //         data.push([
    //             record['Contract'],
    //             record['Trip'],
    //             record['Contract Trip'],
    //             new Date(record['USPS Arrive']),
    //             new Date(record['USPS Depart']),
    //         ]);
    //     });

    //     return data;
    // }


    function loadtenderGradedCount(edi204s) {
        const newestEDI204s = edi204s.map(record => record.newest)
        let graded = 0, stopCt = 0;
        newestEDI204s.forEach(record => {
            if (record?.loadtenderTelematicsData && record?.loadtenderTelematicsData.length > 0) {
                graded++;
                stopCt += record.Stops.length;
            }
        })

        return { graded, stopCt };
    }

    function isNumber(value) {
        return typeof value === 'number' && !isNaN(value);
    }

    function loadtenderStopPunctualityPieChart(edi204) {
        const newestEDI204s = edi204.map(record => record.newest)
        const data = []
        const headers = ["Appoitments", "Ct"]
        data.push(headers)
        let missing = 0, early = 0, late = 0;
        newestEDI204s.forEach(reportItem => {
            reportItem?.loadtenderTelematicsData.forEach(report => {
                report?.telematics.forEach(record => {
                    record?.linearizeLoadTenderAndLogs.forEach(via => {
                        const arriveDiff = Number(via.eldOnPremiseArriveDiffMin);
                        const departDiff = Number(via.eldOnPremiseDepartDiffMin);
                        // console.log(isNumber(arriveDiff))
                        // a
                        if (arriveDiff >= 0) early++;
                        if (arriveDiff < 0) late++;
                        if (isNumber(arriveDiff) === false) missing++;

                        // d
                        if (departDiff >= 0) early++;
                        if (departDiff < 0) late++;
                        if (isNumber(departDiff) === false) missing++;
                    });
                });
            });
        });
        data.push(['Missing', missing])
        data.push(['Early', early])
        data.push(['Late', late])
        return data
    }

    function reset() {
        setQuery(initialQuery);
        setFetched(false)
    }

    function loadtenderPunctualityScatterChart(edi204) {
        const newestEDI204s = edi204.map(record => record.newest)
        const data = []
        const headers = ["Arrival", "Arrival, Departure"]
        data.push(headers)
        for (let i = 0; i < newestEDI204s.length; i++) {
            newestEDI204s[i]?.loadtenderTelematicsData.map(report =>
                report?.telematics.map(record => record?.linearizeLoadTenderAndLogs.forEach(via => {
                    const arrive = Number(via.eldOnPremiseArriveDiffMin);
                    const depart = Number(via.eldOnPremiseDepartDiffMin);
                    data.push([arrive, depart]);
                }
                )))
        }
        return data
    }

    function incrementDate(dateStr) {
        const date = new Date(dateStr);
        date.setDate(date.getDate() + 1); // Add one day
        const incrementedDate = date.toISOString().split('T')[0]; // Format back to YYYY-MM-DD
        return incrementedDate;
    }
    function decrementDate(dateStr) {
        const date = new Date(dateStr);
        date.setDate(date.getDate() - 1); // Add one day
        const incrementedDate = date.toISOString().split('T')[0]; // Format back to YYYY-MM-DD
        return incrementedDate;
    }

    function addDay() {
        const newQueryStart = incrementDate(query.start);
        const newQueryEnd = incrementDate(query.end);
        setQuery({ ...query, start: newQueryStart, end: newQueryEnd })
        setPeriod('custom')
        setFetched(false)
    }

    function subtractDay() {
        const newQueryStart = decrementDate(query.start);
        const newQueryEnd = decrementDate(query.end);
        setQuery({ ...query, start: newQueryStart, end: newQueryEnd })
        setPeriod('custom')
        setFetched(false)
    }

    function handleSelectPeriodChange(event) {
        const selectedValue = event.target.value;
        switch (selectedValue) {
            case 'minus90':
                setQueryMinus90();
                break;
            case 'minus60':
                setQueryMinus60();
                break;
            case 'minus30':
                setQueryMinus30();
                break;
            case 'minus14':
                setQueryMinus14();
                break;
            case 'minus7':
                setQueryMinus7();
                break;
            case 'lastmonth':
                setLastMonth();
                break;
            case 'lastweek':
                setLastWeek();
                break;
            case 'yesterday':
                setQueryYesterday();
                break;
            case 'today':
                setQueryToday();
                break;
            case 'tomorrow':
                setQueryTomorrow();
                break;
            case 'thisweek':
                setThisWeek();
                break;
            case 'nextweek':
                setNextWeek();
                break;
            case 'thismonth':
                setThisMonth();
                break;
            case 'nextmonth':
                setNextMonth();
                break;
            case 'plus7':
                setQueryPlus7();
                break;
            case 'plus14':
                setQueryPlus14();
                break;
            case 'plus30':
                setQueryPlus30();
                break;
            case 'plus60':
                setQueryPlus60();
                break;
            case 'plus90':
                setQueryPlus90();
                break;
            case 'q1':
                setQueryQ1();
                break;
            case 'q2':
                setQueryQ2();
                break;
            case 'q3':
                setQueryQ3();
                break;
            case 'q4':
                setQueryQ4();
                break;
            case 'ytd':
                setQueryYTD();
                break;
            case 'year':
                setQueryYear();
                break;
            default:
                setQueryToday();
                break;
        }
    }

    // const placesGroupBy = multidimensionalGroupBy(places, 'state')

    const [selectedScatterPlotData, setselectedScatterPlotData] = useState(null);

    const handleScatterPlotChartSelect = ({ chartWrapper }) => {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();

        if (selection.length > 0) {
            const selectedItem = selection[0];

            // Assuming that 'row' in the selection corresponds to the index in 'edi204' array
            if (selectedItem.row !== null) {
                const dataForRow = edi204[selectedItem.row];
                setselectedScatterPlotData(dataForRow);
                setScatterPlotModalActive(true);
            }
        }
    };

    return (
        <Container fluid style={{ margin: '0px' }}>
            <AlertDisplay alertState={alertState} />
            <Modal size='lg' show={showHelp}>
                <div style={{ border: '2px solid #f39c9c' }}>
                    <Modal.Header>
                        <h3>Help</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Buttons Defined</Accordion.Header>
                                <Accordion.Body>
                                    <i>(these buttons are disabled)</i>
                                    <ul>
                                        <li>
                                            <Button type="button" style={dataButtonStyle}>
                                                {displayToggle ? <FaTable /> : <FaChartPie />}{' '}
                                                {displayToggle ? 'Table' : 'Graphs'}
                                            </Button> = Displays data in tabular or graphic formates.
                                        </li>
                                        <li>
                                            <Button type="button" style={reloadStyle}><FaSync /> Fetch Loads</Button> = Check for new loads
                                        </li>
                                        <li>
                                            <Button type="button" style={codesButtonStyle}>
                                                {showCodes ? <MdHideSource /> : <MdCircle />}{' '}
                                                {showCodes ? 'Hide Codes' : 'Show Codes'}
                                            </Button>= shows frequently used USPS Status Codes
                                        </li>
                                        <li>
                                            <Button type="button" style={resetStyle}><FaSync /> Reset</Button> = Reset dropdowns to defaults.
                                        </li>
                                        <li>
                                            <Button type="button" style={helpButtonStyle}>
                                                {showHelp ? <MdHideSource /> : <MdCircle />}{' '}
                                                {showHelp ? 'Hide Help' : 'Show Help'}
                                            </Button> = Shows this menu
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Drop Downs Defined</Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>
                                            Report = this is a list of available reports that will automatically select groupings of data
                                        </li>
                                        <li>
                                            Period = this is a list of pre-defined periods that can be selected to auto-adjust date range.
                                        </li>
                                        <li>
                                            Status = this is the internal processing status of a load tender.
                                        </li>
                                        <li>
                                            Contract = this is the assigned USPS contract.
                                        </li>
                                        <li>
                                            City = this is a city within a load tender's stops.
                                        </li>
                                        <li>
                                            State = this is a state within a load tender's stops.
                                        </li>
                                        <li>
                                            Driver = this is an operator of a load tender.
                                        </li>
                                        <li>
                                            Start = this is the start date for the evaluation period.
                                        </li>
                                        <li>
                                            End = this is the end date for the evaluation period.
                                        </li>
                                        <li>
                                            Arrow Keys = allow for easy navigation of date range.
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Reports Defined</Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>All = this selects all load tenders.</li>
                                        <li>Open = this selects load tenders without coverage.</li>
                                        <li>Covered = this selects load tenders with coverage.</li>
                                        <li>Ungraded = this selects load tenders without an audit.</li>
                                        <li>Graded = this selects load tenders with audits complete.</li>
                                        <li>Pass = this selects load tenders with passing audits.</li>
                                        <li>Fail = this selects load tenders with failed audits.</li>
                                        <li>Missed Stops = this selects load tenders with missed stops.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Covering Open Load Tenders</Accordion.Header>
                                <Accordion.Body>
                                    Use these options to easily cover open load tenders.
                                    <ul>
                                        <li>
                                            Report = Open
                                        </li>
                                        <li>
                                            Period = Today (or date you need coverage)
                                        </li>
                                        <li>
                                            Status = All
                                        </li>
                                        <li>
                                            Contract = All (or your specific contract)
                                        </li>
                                        <li>
                                            City = All
                                        </li>
                                        <li>
                                            State = All
                                        </li>
                                        <li>
                                            Driver = All
                                        </li>
                                        <li>Start = will automatically adjust when you select a period. You may also provide a date.</li>
                                        <li>End = will automatically adjust when you select a period. You may also provide a date</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Searching For Load Tenders</Accordion.Header>
                                <Accordion.Body>
                                    Use these options as a starting point to find a load tender.
                                    <ul>
                                        <li>
                                            Report = All
                                        </li>
                                        <li>
                                            Period = {`<select a period to search>`}
                                        </li>
                                        <li>
                                            Status = All
                                        </li>
                                        <li>
                                            Contract = All (or your specific contract)
                                        </li>
                                        <li>
                                            City = All
                                        </li>
                                        <li>
                                            State = All
                                        </li>
                                        <li>
                                            Driver = All
                                        </li>
                                        <li>
                                            Start = will automatically adjust when you select a period. You may also provide a date.
                                        </li>
                                        <li>
                                            End = will automatically adjust when you select a period. You may also provide a date
                                        </li>
                                    </ul>
                                    <i>Remember to use the search data based on your provided text.</i>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowHelp(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
            <Modal size='lg' show={showCodes}>
                <div style={{ border: '2px solid #fbb70e' }}>
                    <Modal.Header>
                        <h3>Frequently Used Codes</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>PICK UP CODES</h5>
                        {EDI_214_SHIPMENT_STATUS_CODES_MAIL_PICKUP_SPECIFIC.map((code, i) => (
                            <div key={i}>
                                <b>{code.Code}</b> {code.Description}
                            </div>
                        ))}
                        <br />
                        <h5>DELIVERY CODES</h5>
                        {EDI_214_SHIPMENT_STATUS_CODES_MAIL_DELIVERY_SPECIFIC.map((code, i) => (
                            <div key={i}>
                                <b>{code.Code}</b> {code.Description}
                            </div>
                        ))}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowCodes(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
            <PrintPDF sourceName={`MCTMS-Load-Tender-Dashboard`}>
                <Card>
                    <Card.Header>
                        <Row style={{ alignItems: 'center' }}>
                            <Col style={{ alignItems: 'center' }}>
                                <h3>Load Tender Dashboard</h3>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Button type="button" style={dataButtonStyle} onClick={() => setDisplayToggle(!displayToggle)}>
                                    {displayToggle ? <FaTable /> : <FaChartPie />}{' '}
                                    {displayToggle ? 'Table' : 'Graphs'}
                                </Button>
                                <Button type="button" style={reloadStyle} onClick={() => setFetched(false)}><FaSync /> Fetch Loads</Button>
                                <Button type="button" style={resetStyle} onClick={() => reset()}><FaSync /> Reset</Button>
                                <Button type="button" style={codesButtonStyle} onClick={() => setShowCodes(!showCodes)}>
                                    {showCodes ? <MdHideSource /> : <MdCircle />}{' '}
                                    {showCodes ? 'Hide Codes' : 'Show Codes'}
                                </Button>
                                <Button type="button" style={helpButtonStyle} onClick={() => setShowHelp(!showHelp)}>
                                    {showHelp ? <MdHideSource /> : <MdCircle />}{' '}
                                    {showHelp ? 'Hide Help' : 'Show Help'}
                                </Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    {!pause ?
                        <>
                            <Card.Header>
                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                                    <div style={labelOptionContainerStyle}>
                                        <label style={labelStyle}>Report</label>
                                        <br />
                                        <select
                                            style={buttonStyle}
                                            onChange={(e) => updateReport(JSON.parse(e.target.value))}
                                            value={JSON.stringify(report)}
                                        >
                                            {Object.keys(LOAD_TENDER_GROUPS).map((group, i) => (
                                                LOAD_TENDER_GROUPS[group].map((rpt, j) => (
                                                    <option
                                                        key={i + j}
                                                        style={report?.name === rpt.name ? buttonSelectedStyle : buttonStyle}
                                                        value={JSON.stringify(rpt)}
                                                    >
                                                        {rpt.title}
                                                    </option>
                                                ))
                                            ))}
                                        </select>
                                    </div>
                                    <div style={labelOptionContainerStyle}>
                                        <label style={labelStyle}>Period</label>
                                        <br />
                                        <select style={buttonStyle} value={period} onChange={handleSelectPeriodChange}>
                                            <option value='custom' disabled>Custom</option>
                                            <option value='today'>Today</option>
                                            <option value='yesterday'>Yesterday</option>
                                            <option value='tomorrow'>Tomorrow</option>
                                            <option value='thisweek'>This Week</option>
                                            <option value='thismonth'>This Month</option>
                                            <option value='nextweek'>Next Week</option>
                                            <option value='nextmonth'>Next Month</option>
                                            <option value='lastweek'>Last Week</option>
                                            <option value='lastmonth'>Last Month</option>
                                            <option value='minus7'>-7</option>
                                            <option value='minus14'>-14</option>
                                            <option value='minus30'>-30</option>
                                            <option value='minus60'>-60</option>
                                            <option value='minus90'>-90</option>
                                            <option value='plus7'>+7</option>
                                            <option value='plus14'>+14</option>
                                            <option value='plus30'>+30</option>
                                            <option value='plus60'>+60</option>
                                            <option value='plus90'>+90</option>
                                            <option value='q1'>Q1</option>
                                            <option value='q2'>Q2</option>
                                            <option value='q3'>Q3</option>
                                            <option value='q4'>Q4</option>
                                            <option value='ytd'>YTD</option>
                                            <option value='year'>Year</option>
                                        </select>
                                    </div>
                                    <div style={labelOptionContainerStyle}>
                                        <label style={labelStyle}>Status</label>
                                        <br />
                                        <select
                                            // multiple
                                            style={buttonStyle}
                                            value={query.loadtenderLifeCycleStatus}
                                            onChange={e => setQueryLoadTenderStatus(e.target.value)}
                                        >
                                            <option value="All">All</option>
                                            <option value="pending">Pending</option>
                                            <option value="Accepted">Accepted</option>
                                            <option value="Completed">Completed</option>
                                            <option value="Rejected">Rejected</option>
                                        </select>
                                    </div>
                                    <div style={labelOptionContainerStyle}>
                                        <label style={labelStyle}>Contract</label>
                                        <br />
                                        <select
                                            // multiple
                                            style={buttonStyle}
                                            value={query.contract}
                                            onChange={e => setQueryContract(e.target.value)}
                                        >
                                            <option value="All">All</option>
                                            {contracts.sort().map((contract, i) => (
                                                <option key={i}>{contract}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div style={labelOptionContainerStyle}>
                                        <label style={labelStyle}>City</label>
                                        <br />
                                        <select
                                            // multiple
                                            style={buttonStyle}
                                            value={JSON.stringify(query.place)}
                                            onChange={e => e.target.value === 'All'
                                                ? setQueryPlace('All')
                                                : setQueryPlace(JSON.parse(e.target.value))
                                            }
                                        >
                                            <option value="All">All</option>
                                            {places.sort((a, b) => {
                                                if (a.state < b.state) return -1;
                                                if (a.state > b.state) return 1;
                                                if (a.city < b.city) return -1;
                                                if (a.city > b.city) return 1;
                                                return 0;
                                            }).map((place, i) => (
                                                query?.state === 'All'
                                                    ?
                                                    <option key={i} value={JSON.stringify(place)}>{place.city}, {place.state}</option>
                                                    :
                                                    query?.state === place.state &&
                                                    <option key={i} value={JSON.stringify(place)}>{place.city}, {place.state}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div style={labelOptionContainerStyle}>
                                        <label style={labelStyle}>State</label>
                                        <br />
                                        <select
                                            // multiple
                                            style={buttonStyle}
                                            value={query.state}
                                            onChange={e => setQueryState(e.target.value)}
                                        >
                                            <option value="All">All</option>
                                            {Array.from(new Set(places.map(place => place.state)))
                                                .sort()
                                                .map((state, i) => (
                                                    <option key={i} value={state}>{state}</option> // Step 4: Mapping to <option> elements
                                                ))}
                                        </select>
                                    </div>
                                    <div style={labelOptionContainerStyle}>
                                        <label style={labelStyle}>Driver</label>
                                        <br />
                                        <select
                                            // multiple
                                            style={buttonStyle}
                                            value={JSON.stringify(query.driver)}
                                            onChange={e => e.target.value === 'All'
                                                ? setQueryDriver('All')
                                                : setQueryDriver(JSON.parse(e.target.value))
                                            }
                                        >
                                            <option value="All">All</option>
                                            {drivers.sort((a, b) => {
                                                if (a < b) return -1;
                                                if (a > b) return 1;
                                                return 0;
                                            }).map((driver, i) => (
                                                <option key={i} value={JSON.stringify(driver)}>{driver}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div style={labelOptionContainerStyle}>
                                        <label style={labelStyle}>Start</label>
                                        <br />
                                        <input type="date" style={buttonStyle} value={query.start} onChange={e => setQueryStart(e.target.value)} />
                                    </div>
                                    <div style={labelOptionContainerStyle}>
                                        <label style={labelStyle}>End</label>
                                        <br />
                                        <input type="date" style={buttonStyle} value={query.end} onChange={e => {
                                            setQueryEnd(e.target.value)
                                        }} />
                                    </div>
                                    <div style={labelOptionContainerStyle}>
                                        <label style={labelStyle}>Adjust</label>
                                        <br />
                                        <button style={buttonStyle} onClick={() => subtractDay()}><FaArrowLeft /></button>
                                        <button style={buttonStyle} onClick={() => addDay()} ><FaArrowRight /></button>
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Header>
                                <b>
                                    {report && ` ${report.title} : ${report.detail} `}
                                    {period === 'minus90' && ' Period for the past 90 days, beginning from today .'}
                                    {period === 'minus60' && ' Period for the past 60 days, beginning from today .'}
                                    {period === 'minus30' && ' Period for the past 30 days, beginning from today .'}
                                    {period === 'minus14' && ' Period for the past 14 days, beginning from today.'}
                                    {period === 'minus7' && ' Period for the past 7 days, beginning from today.'}
                                    {period === 'yesterday' && ' Period yesterday\'s load tenders.'}
                                    {period === 'today' && ' Period today\'s load tenders.'}
                                    {period === 'tomorrow' && ' Period tomorrow\'s load tenders'}
                                    {period === 'thisweek' && ' Period for this week Sunday-Saturday.'}
                                    {period === 'nextweek' && ' Period for this week Sunday-Saturday.'}
                                    {period === 'lastweek' && ' Period from last week Sunday-Saturday.'}
                                    {period === 'thismonth' && ' Period for this month.'}
                                    {period === 'nextmonth' && ' Period for next month.'}
                                    {period === 'lastmonth' && ' Period for last month.'}
                                    {period === 'plus7' && ' Period for next 7 days, beginning from today.'}
                                    {period === 'plus14' && ' Period for next 14 days, beginning from today.'}
                                    {period === 'plus30' && ' Period for next 30 days, beginning from today.'}
                                    {period === 'plus60' && ' Period for next 60 days, beginning from today.'}
                                    {period === 'plus90' && ' Period for next 90 days, beginning from today.'}
                                    {period === 'ytd' && ' Period from first of the year through today.'}
                                    {period === 'q1' && ' Period the first quarter of the year.'}
                                    {period === 'q2' && ' Period the second quarter of the year.'}
                                    {period === 'q3' && ' Period the third quarter of the year.'}
                                    {period === 'q4' && ' Period the fourth quarter of the year.'}
                                    {period === 'year' && ' Period from first of the year to the last.'}
                                    {period === 'custom' && `Period custom.`}
                                    {` Date = ${formatDateStrToMMDDYY(query.start)} to ${formatDateStrToMMDDYY(query.end)}`}
                                    {` | `} Status = {query?.loadtenderLifeCycleStatus}{` `}
                                    {` | `} Contract = {query?.contract}{` `}
                                    {` | `} City = {query?.city}{` `}
                                    {` | `} State = {query?.state}{` `}
                                    {` | `} Driver = {query?.driver}{` `}
                                </b>
                            </Card.Header>
                            {fetched ?
                                <>
                                    <Modal size="lg" show={modalActive} onHide={() => setScatterPlotModalActive(false)}>
                                        <Modal.Header closeButton>
                                            Shipment ID : {selectedScatterPlotData?._id}
                                        </Modal.Header>
                                        <Modal.Body>
                                            <pre>
                                                {JSON.stringify((selectedScatterPlotData?.newest?.Stops), "", 2)}
                                            </pre>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={() => setScatterPlotModalActive(false)}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    <Card.Body>
                                        {displayToggle ? (
                                            (edi204?.length > 0 && edi204Clean?.length > 0) ?
                                                <>
                                                    <h3>Load Tender Reports</h3>
                                                    <h5>Total Load Tenders : {edi204 ? edi204?.length : 0}</h5>
                                                    <h5>Total Stops : {edi204.reduce((a, b) => a + b.newest.Stops.length, 0)}</h5>
                                                    <h5>Total Check Points : {2 * edi204.reduce((a, b) => a + b.newest.Stops.length, 0)}</h5>
                                                    <Row>
                                                        <Col sm={12} md={6} lg={4}>
                                                            <Chart
                                                                chartType={(loadtenderStatusChart(edi204)).type}
                                                                data={(loadtenderStatusChart(edi204).data)}
                                                                options={{ title: `Load Tenders` }}
                                                                width="100%"
                                                                height="600px"
                                                            />
                                                        </Col>
                                                        <Col sm={12} md={6} lg={4}>
                                                            <Chart
                                                                chartType={(loadtenderCoverageChart(edi204Clean)).type}
                                                                data={(loadtenderCoverageChart(edi204Clean).data)}
                                                                options={{ title: `Coverage` }}
                                                                width="100%"
                                                                height="600px"
                                                            />
                                                        </Col>
                                                        <Col sm={12} md={6} lg={4}>
                                                            <Chart
                                                                chartType={(loadtenderGradedChart(edi204)).type}
                                                                data={(loadtenderGradedChart(edi204).data)}
                                                                options={{ title: `Graded` }}
                                                                width="100%"
                                                                height="600px"
                                                            />
                                                        </Col>
                                                        <Col sm={12} md={6} lg={4}>
                                                            <Chart
                                                                chartType={(loadtenderEquipmentChart(edi204)).type}
                                                                data={(loadtenderEquipmentChart(edi204).data)}
                                                                options={{ title: `Equipment` }}
                                                                width="100%"
                                                                height="600px"
                                                            />
                                                        </Col>
                                                        <Col sm={12} md={6} lg={4}>
                                                            <Chart
                                                                chartType={(loadtenderContractChart(edi204Clean)).type}
                                                                data={(loadtenderContractChart(edi204Clean).data)}
                                                                options={{ title: `Contract` }}
                                                                width="100%"
                                                                height="600px"
                                                            />
                                                        </Col>
                                                        <Col sm={12} md={6} lg={4}>
                                                            <Chart
                                                                chartType={"BarChart"}
                                                                data={loadtenderContractTripDateBarChart(edi204Clean)}
                                                                options={{ title: `Load Tenders - Date` }}
                                                                width="100%"
                                                                height="600px"
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12} md={6}>
                                                            <Chart
                                                                chartType={"BarChart"}
                                                                data={loadtenderCoverageBarChart(edi204Clean)}
                                                                options={{ title: `Load Tenders - Coverage` }}
                                                                width="100%"
                                                                height="600px"
                                                            />
                                                        </Col>
                                                        <Col sm={12} md={6}>
                                                            <Chart
                                                                chartType={"BarChart"}
                                                                data={loadtenderContractTripBarChart(edi204Clean)}
                                                                options={{ title: `Load Tenders - Contract Trip` }}
                                                                width="100%"
                                                                height="600px"
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <h3>Load Tender Contract Trip Timeline</h3>
                                                            <Chart
                                                                width="100%"
                                                                height="800px"
                                                                chartType="Timeline"
                                                                data={loadtenderContractTripTimeline(edi204Clean)}
                                                                options={{
                                                                    avoidOverlappingGridLines: true,
                                                                    timeline: {
                                                                        showRowLabels: true,
                                                                        colorByRowLabel: true,
                                                                    },
                                                                    tooltip: { isHtml: true },
                                                                    allowHtml: true,
                                                                    hAxis: {
                                                                        format: 'MM/dd/yy HH:mm'
                                                                    },
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <h5>Driver to Contract Trip</h5>
                                                            <Chart
                                                                chartType="Sankey"
                                                                width="100%"
                                                                height="800px"
                                                                data={loadtenderSankeyCoverage(edi204Clean)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <h3>Telematics Reports</h3>
                                                    {((loadtenderGradedCount(edi204))?.graded > 0)
                                                        ?
                                                        <>
                                                            <h5>Graded Load Tenders : {(loadtenderGradedCount(edi204))?.graded}</h5>
                                                            <h5>Total Stops : {(loadtenderGradedCount(edi204))?.stopCt}</h5>
                                                            <h5>Total Check Points : {2 * (loadtenderGradedCount(edi204))?.stopCt}</h5>
                                                            <Row>
                                                                <Col sm={12} md={6} lg={4}>
                                                                    <Chart
                                                                        chartType={"PieChart"}
                                                                        data={loadtenderStopPunctualityPieChart(edi204)}
                                                                        options={{ title: `Load Tender Check Point Punctuality` }}
                                                                        width="100%"
                                                                        height="600px"
                                                                    />
                                                                </Col>
                                                                <Col sm={12} md={6} lg={8}>
                                                                    <Chart
                                                                        chartType="ScatterChart"
                                                                        width="100%"
                                                                        height="600px"
                                                                        data={loadtenderPunctualityScatterChart(edi204)}
                                                                        chartEvents={[
                                                                            {
                                                                                eventName: 'select',
                                                                                callback: handleScatterPlotChartSelect,
                                                                            },
                                                                        ]}
                                                                        options={{
                                                                            title: "Check Point Punctuality (+ Early | - Late)",
                                                                            hAxis: { title: "Arrival (minutes)" },
                                                                            vAxis: { title: "Departure (minutes)" },
                                                                            legend: "none",
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                        :
                                                        <>
                                                            <h5>No load tenders with telematics.</h5>
                                                        </>

                                                    }

                                                </>
                                                :
                                                <>
                                                    <h5>No records in query.</h5>
                                                </>
                                        )
                                            :
                                            <DataDisplay
                                                sourceName={'MCTMS-Active-Load-Tenders'}
                                                dataSource={edi204Clean}
                                                lsKey={'@mctms-loadtender-204-display-active'}
                                                urlPath={'/loadtender/update/'}
                                                urlKey={'_id'}
                                                popKeys={['_id','edi204','edi214','edi990','telematics','']}
                                            // addActions={[
                                            //     { urlPath: '/loadtender/view/', urlKey: '_id', icon: <FaEye />, }
                                            // ]}
                                            />
                                        }
                                    </Card.Body>
                                </>
                                :
                                <Card.Body>
                                    <Spinner />
                                </Card.Body>
                            }
                        </>
                        :
                        <Card.Body>
                            <LogisticsLoading />
                        </Card.Body>
                    }
                </Card>
            </PrintPDF>
        </Container>
    );
}

export default LoadTenderDashboard;