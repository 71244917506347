import React from 'react'


const Accident = () => {
    return (
        <>
            Accident Table
        </>
    )
}

export default Accident;