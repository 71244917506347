import React, { useState, useEffect } from "react";
import { Container, Card, Spinner, Table, Accordion, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api'
import AlertDisplay from '../../components/AlertDisplay';
import { useAuth } from '../../context/auth'
import DataDisplay from "../../components/DataDisplay";
import { getTimezoneCode } from "../../utils/Clock";

function RoutePlanIssues() {
    const { idToken } = useAuth();
    const [set, isSet] = useState();
    const [alerts, setAlerts] = useState([]);
    const [routeplansIssues, setRoutePlanIssues] = useState()
    const [locationsIssues, setLocationsIssues] = useState()
    const [frequenciesIssues, setFrequenciesIssues] = useState()
    const [contractIssues, setContractIssues] = useState()
    const [operatorsIssues, setOperatorsIssues] = useState()
    const [trailersIssues, setTrailersIssues] = useState()
    const [vehiclesIssues, setVehiclesIssues] = useState()

    const alertState = { alerts, setAlerts }

    function cleanContractData(data) {
        const cleanData = data.map(contract => {
            return {
                _id: contract?._id,
                'Contract': contract?.name,
                'Assignment Active' :  contract?.assignment?.isActive ? 'Yes ✅' : 'No 🚨',
            }
        })

        return cleanData;
    }

    function cleanFrequencyData(data) {
        const cleanData = data.map(frequency => {
            return {
                _id: frequency?._id,
                'Frequency': frequency?.name ,
            }
        })
        return cleanData;
    }

    function cleanLocationData(data) {
        const cleanData = data.map(location => {
            return {
                _id: location?._id,
                'Location': location?.name,

            }
        })
        return cleanData;
    }


    function cleanOperatorData(data) {
        const cleanData = data.map(operator => {
            return {
                _id: operator?._id,
                'Operator': operator?.name,
            }
        })
        return cleanData;
    }

    function cleanRoutePlanData(data) {
        // const cleanData = data.map(routeplan => {
        //     return {
        //         _id: routeplan?._id,
        //         'Route': routeplan?.name,
        //         'Assignment Issue': routeplan?.assignmentMatch ? routeplan?.assignmentMatch.isActive ? '✅' : '⚠️ Inactive' : '🚨 DNE',
        //         'Domicile Issue':routeplan?.domicileMatch ? routeplan?.domicileMatch?.isActive  ? '✅' : '⚠️ Inactive' : '🚨 DNE',
        //         'Contract Issue':routeplan?.contractMatch ? routeplan?.contractMatch?.isActive  ? '✅' : '⚠️ Inactive' : '🚨  DNE',
        //         'Frequency Issue':routeplan?.frequencyMatc ? routeplan?.frequencyMatch?.isActive  ? '✅' : '⚠️ Inactive' : '🚨  DNE',
        //         'Stops Issue': routeplan?.stops.map(stp => 
        //             `A: ${stp?.arrive} | D: ${stp?.depart} | ${getTimezoneCode(stp?.timezone)} | ${stp?.location?.name}`
        //         ).join('\n'),
        //         // 'Service Type': routeplan?.servicetype,
        //         // 'Assignment': routeplan?.assignment?.name,
        //         // 'Domicile': routeplan?.domicile?.name,
        //         // 'Crew': routeplan?.crew,
        //         // 'Coverage': routeplan?.coverage.map(driver => `${driver?.firstname} ${driver?.lastname}` ).join('\n')
        //     }
        // })
        return data;
    }

    function cleanTrailerData(data) {
        const cleanData = data.map(trailer => {
            return {
                _id: trailer?._id,
                'Trailer': trailer?.unit,

            }
        })
        return cleanData;
    }

    function cleanVehicleData(data) {
        const cleanData = data.map(vehicle => {
            return {
                _id: vehicle?._id,
                'Vehicle': vehicle?.unit,

            }
        })
        return cleanData;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const endpoints = [
                    '/contracts/issues',
                    '/frequencies/issues',
                    '/locations/issues',
                    '/operators/issues',
                    '/routeplans/issues',
                    '/trailers/issues',
                    '/vehicles/issues',
                ];

                const responses = await Promise.all(
                    endpoints.map(async (endpoint) => {
                        try {
                            return await Api.get(endpoint, idToken);
                        } catch (error) {
                            return null;
                        }
                    })
                );

                const [
                    contractIssue,
                    frequencyIssue,
                    locationIssue,
                    operatorIssue,
                    routeplansIssue,
                    trailerIssue,
                    vehicleIssue,
                ] = responses;

                const routeplanCleanIssueData = cleanRoutePlanData(routeplansIssue)
                const frequencyCleanIssueData = cleanFrequencyData(frequencyIssue)
                const locationCleanIssueData = cleanLocationData(locationIssue)
                const contractCleanIssueData = cleanContractData(contractIssue)
                const operatorCleanIssueData = cleanOperatorData(operatorIssue)
                const trailCleanIssueData = cleanTrailerData(trailerIssue)
                const vehicleCleanIssueData = cleanVehicleData(vehicleIssue)

                setRoutePlanIssues(routeplanCleanIssueData ?? []);
                setFrequenciesIssues(frequencyCleanIssueData ?? []);
                setLocationsIssues(locationCleanIssueData ?? []);
                setContractIssues(contractCleanIssueData ?? []);
                setOperatorsIssues(operatorCleanIssueData ?? []);
                setTrailersIssues(trailCleanIssueData ?? []);
                setVehiclesIssues(vehicleCleanIssueData ?? []);
            } catch (error) {
                setAlerts([
                    ...alerts,
                    { variant: 'warning', message: 'An error occurred while searching for issues.' },
                ]);
            } finally {
                isSet(true);
            }
        };

        if (!set) {
            fetchData()
        }
    }, [set, alerts, idToken, contractIssues])

    return (
        <Container fluid>
            <AlertDisplay alertState={alertState} />
            <Card>
                <Card.Header><b>Plan Issues Detected</b></Card.Header>
                {set ?
                    <>
                        <Accordion>
                        <Accordion.Item eventKey="3">
                                <Accordion.Header>Contract Issues - {contractIssues ? contractIssues.length : '-'}</Accordion.Header>
                                <Accordion.Body>
                                    <DataDisplay
                                        dataSource={contractIssues}
                                        lsKey={'@mctms-contracts-issues-display'}
                                        urlPath={'/plan/contract/'}
                                        urlKey={'_id'}
                                        popKeys={['_id']}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Frequency Issues - {frequenciesIssues ? frequenciesIssues.length : '-'}</Accordion.Header>
                                <Accordion.Body>
                                    <DataDisplay
                                        dataSource={frequenciesIssues}
                                        lsKey={'@mctms-frequency-issues-display'}
                                        urlPath={'/plan/frequency/'}
                                        urlKey={'_id'}
                                        popKeys={['_id']}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Location Issues - {locationsIssues ? locationsIssues.length : '-'}</Accordion.Header>
                                <Accordion.Body>
                                    <DataDisplay
                                        dataSource={locationsIssues}
                                        lsKey={'@mctms-locations-issues-display'}
                                        urlPath={'/plan/location/'}
                                        urlKey={'_id'}
                                        popKeys={['_id']}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Operator Issues - {operatorsIssues ? operatorsIssues.length : '-'}</Accordion.Header>
                                <Accordion.Body>
                                    <DataDisplay
                                        dataSource={operatorsIssues}
                                        lsKey={'@mctms-operators-issues-display'}
                                        urlPath={'plan/operator/'}
                                        urlKey={'_id'}
                                        popKeys={['_id']}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Route Plan Issues - {routeplansIssues ? routeplansIssues.length : '-'}</Accordion.Header>
                                <Accordion.Body>
                                    <DataDisplay
                                        dataSource={routeplansIssues}
                                        lsKey={'@mctms-routeplans-issues-display'}
                                        urlPath={'/plan/routeplan/'}
                                        urlKey={'_id'}
                                        popKeys={['_id']}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Trailer Issues - {trailersIssues ? trailersIssues.length : '-'}</Accordion.Header>
                                <Accordion.Body>
                                    <DataDisplay
                                        dataSource={trailersIssues}
                                        lsKey={'@mctms-trailers-issues-display'}
                                        urlPath={'/plan/trailer/'}
                                        urlKey={'_id'}
                                        popKeys={['_id']}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>Vehicle Issues - {vehiclesIssues ? vehiclesIssues.length : '-'}</Accordion.Header>
                                <Accordion.Body>
                                    <DataDisplay
                                        dataSource={vehiclesIssues}
                                        lsKey={'@mctms-vehicles-issues-display'}
                                        urlPath={'/plan/vehicle/'}
                                        urlKey={'_id'}
                                        popKeys={['_id']}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </>
                    :
                    <Spinner />
                }
            </Card>
            <br />
        </Container>
    );
}

export default RoutePlanIssues;