import React, { useState } from 'react'
import { Container, Card, Form, Button, Row, Col } from "react-bootstrap"
import { read, utils } from 'xlsx';


const ManifestPreview = ({data}) => {
    return (
            <Card>
                <Card.Header>
                    <Card.Title>
                        Manifest Form
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <pre>
                        {JSON.stringify(data)}
                    </pre>
                </Card.Body>
            </Card>
    )
}

export default ManifestPreview;