import { Container, Card, Row, Col, Form, Button, Spinner, Alert, Table, Tooltip, OverlayTrigger, Accordion } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import Api from "../../utils/Api";
import { useParams } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import appRoutes from "../../context/approutes";
import { FaInfoCircle } from 'react-icons/fa';
import { multidimensionalGroupBy } from '../../utils/Array.helper'
import AlertDisplay from "../../components/AlertDisplay";

function AccessStale() {
    const { idToken } = useAuth();
    const { id } = useParams();
    const [set, didSet] = useState();
    const [role, setRole] = useState({});
    const [permissions, setPermissions] = useState({});
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    const routeGroups = multidimensionalGroupBy(appRoutes, ['feature']);
    const routeGroupKeys = Object.keys(routeGroups);
    routeGroupKeys.sort((a, b) => {
        return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
    });

    async function handleUpdate() {
        try {
            const data = { ...role, permissions: permissions };
            await Api.patch(`/access/role/${id}`, data, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success', }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error updating', }])
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Api.get(`/access/stale`, idToken);
                setRole(data)

                if (data.permissions) {
                    setPermissions(data.permissions);
                }
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error', }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    })



    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header>
                    <b>Stale User Accounts</b>
                </Card.Header>
                {(set && role) ?
                    <>

                    </>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    );
}

export default AccessStale;