import React from "react";
import { Container, Card, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useRole } from '../../context/role';
import { FaCarCrash, FaPlusCircle, FaUmbrellaBeach } from "react-icons/fa";
import { GoReport } from "react-icons/go";
import { BsCashCoin } from "react-icons/bs";
import { TbPlusMinus } from "react-icons/tb";
import { Link } from "react-router-dom";


function Safety() {

    const { userRole, checkNavPermissions } = useRole();

    const contentOptions = [
        {
            href: "/safety/accidentmanagementsystem",
            header: '',
            elements:
                <>
                   <Link to="/safety/accidentmanagementsystem"><FaCarCrash /> Accident Management System</Link>
                </>
        },
    ];
    const allowedContent = checkNavPermissions(userRole, contentOptions)

    return (
        <Container fluid>
            <Card>
                <Card.Header><b>Safety</b></Card.Header>
                {allowedContent.map((content, i) => (
                    <Card.Body key={i}>
                        <Card.Title>{content.header}</Card.Title>
                        <Card.Text>
                            {content.elements}
                        </Card.Text>
                    </Card.Body>
                ))}
            </Card>
            <br />
        </Container>
    );
}

export default Safety;