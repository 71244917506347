import React, {useState, useEffect} from 'react'
import { Card, Modal } from 'react-bootstrap';

function USPSServicePointsDashboard() {

    const [servicePoint, setServicePoint] = useState();// a service point
    const [servicePoints, setServicePoints] = useState(); // all USPS service points

    async function fetchServicePoint(){

    }

    async function updateServicePoint(){

    }

    async function fetchServicePoints(){

    }

    async function uploadServicePoints(){
    
    }

    useEffect(()=>{

    },[])

    return (
        <React.Fragment>
            <Modal show={servicePoint && true} size="fullscreen">
                <Modal.Header>

                </Modal.Header>
                <Modal.Body>

                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
            <Card.Header>
                <Card.Subtitle>USPS SERVICE POINTS</Card.Subtitle>
            </Card.Header>
            <Card.Header>

            </Card.Header>
            <Card.Body>
                
            </Card.Body>
        </React.Fragment>
    )
}

export default USPSServicePointsDashboard;