import React, { useState, useEffect } from 'react'
import { Container, Card, Button, Row, Col, Spinner, Alert, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import { FaPlusCircle } from 'react-icons/fa'
import { useAuth } from '../../context/auth';
import approutes from '../../context/approutes'
import DataDisplay from '../../components/DataDisplay';
import { Link } from 'react-router-dom';


function AccessRole() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [roles, setRoles] = useState([]);
    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const r = await Api.get('/access/roles', idToken);

                //
                const cleanRoles = [];
                for (let i = 0; i < r.length; i++) {
                    const permissionCt = Object.values(r[i].permissions).filter(value => value === true).length
                    const cleaned = {
                        _id: r[i]._id,
                        'Name ': r[i].name,
                        '% Access': `${((Number(permissionCt)/Number(approutes.length)*100).toFixed(0))} %`,
                        'Allowed': permissionCt ,
                        'Permissions': approutes.length,
                        'Share Role': r[i].accessibility,
                        'Active ': r[i].isActive ? '✅' : '❌',
                    }
                    cleanRoles.push(cleaned)
                }
                cleanRoles.sort((a, b) => b['Allowed'] - a['Allowed']);
                setRoles(cleanRoles)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error', }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [idToken, set, alerts])

    return (
        <Container fluid>
            {alerts.length > 0 &&
                <>
                    {alerts.map((alrt, i) => (
                        <Row key={i}>
                            <Col>
                                <Alert variant={alrt.variant} dismissible onClick={() => setAlerts([])}>{alrt.message}</Alert>
                            </Col>
                        </Row>
                    ))}
                </>
            }
            {
                set ?
                    <>
                        <Card>
                            <Card.Header>
                                <Card.Title>Roles x {roles.length}
                                    <Link className="float-end" to="/access/role-form"><FaPlusCircle /> Role</Link>
                                </Card.Title>
                            </Card.Header>
                                <Card.Body>
                                    {set ? (
                                        <>
                                            {roles && roles.length > 0 ? (
                                                <DataDisplay
                                                    dataSource={roles}
                                                    urlPath={'/access/role/'}
                                                    urlKey={'_id'}
                                                    lsKey={'@mctms-access-roles-table'}
                                                    popKeys={['_id']}
                                                />
                                            ) : (
                                                <Row>
                                                    <Col>No roles.</Col>
                                                </Row>
                                            )}
                                        </>
                                    ) : (
                                        <Spinner />
                                    )}
                                </Card.Body>
                        </Card>
                    </> :
                    <Spinner />
            }

        </Container>
    )
}

export default AccessRole;