import { Container, Table } from "react-bootstrap"

const EDI210Component = ({ ...props }) => {
    const edi210s = props.edi210s;

    if (!edi210s || edi210s.length === 0) {
        return (
            <Container>
                <b>No EDI 210</b>
            </Container>
        )
    }

    return (
        <Table striped bordered hover responsive>
            <thead>
                <tr>
                    <th colSpan={6}>EDI210</th>
                </tr>
                <tr>
                    <th>InternalTrackingNum</th>
                    <th>ShipmentId</th>

                </tr>
            </thead>
            <tbody>
                {edi210s.map((edi210, i) => (
                    <tr key={i}>
                        <td>{edi210.InternalTrackingNum}</td>
                        <td>{edi210.ShipmentId}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default EDI210Component;