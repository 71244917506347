import React, { useEffect, useState } from 'react';
import { Form, Button, Card, Row, Col, Badge, Alert, Container } from 'react-bootstrap';
import logo from '../../../assets/logo.png'
import { useAuth } from '../../../context/auth';
import Api from '../../../utils/Api';
import {UNITED_STATES_CODES} from '../../../context/appdata'

const initialFormState = {
  email: "",
  firstname: "",
  lastname: "",
  purchasedate: "",
  location: "",
  opsmanager: "",
  merchant: "",
  reason: "",
  amount: "",
  city: "",
  state: "",
  zipcode: "",
  phone: "",
  acknowledge: "",
  checkfirstname: "",
  checklastname: "",
  address1: "",
  // address2: "", //omitted here because it is not required
  attachments: [],
};

function EmployeeReimbursementForm() {

  const states = UNITED_STATES_CODES.map(item => item.name).sort();

  const {user, idToken } = useAuth();
  const [alerts, setAlerts] = useState([]);
  const [load, didLoad] = useState();
  const [form, setForm] = useState(initialFormState);
  const [files, setFiles] = useState(null);
  const [profile, setProfile] = useState([]);

  const handleFileChange = (e) => {
    e.preventDefault();
    const selectedFiles = e.target.files;
    setFiles(selectedFiles);
  };

  const handleAmountBlur = () => {
    const { amount } = form;
    let formattedValue = amount;

    if (!amount.includes('.')) {
      // If there is no decimal point, add '.00' to the end
      formattedValue = amount === '' ? '0.00' : `${amount}.00`;
    }
    setForm({ ...form, amount: formattedValue });
  };

  const handleZipCodeBlur = () => {
    const zip = form.zipcode;
    if (zip.length === 5) {
      setForm({ ...form, zipcode: zip });
    } else {
      setForm({ ...form, zipcode: '' });
      setAlerts([
        ...alerts,
        {
          variant: "warning",
          message: `Enter a 5-digit zip-code`,
        },
      ]);
    }
  }

  const handlePhoneBlur = () => {
    const phoneNumber = form.phone.replace(/\D/g, '');
    let formattedPhoneNumber = phoneNumber;

    if (phoneNumber.length === 10) {
      formattedPhoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
      // console.log(formattedPhoneNumber);
      // console.log(form.phone);
    } else {
      setForm({ ...form, phone: '' });
      setAlerts([
        ...alerts,
        {
          variant: "warning",
          message: `Enter a 10 digit phone number`,
        },
      ]);

    }
    setForm({ ...form, phone: formattedPhoneNumber });
  };

  const handleFormSet = (key, val) => {
    const formData = {...form, [key]: val}
    const formJSON = JSON.stringify(formData);
    setForm(formData)
    localStorage.setItem('@mctms-empreimbursement-form-data', formJSON)
  }

  const handleSubmit = async () => {
    try {
      
      const missingFields = Object.keys(initialFormState).filter((field) => form[field] === null || form[field] === undefined || form[field] === '');

      if (missingFields.length > 0) {
        const missingFieldNames = missingFields.map((field) =>
          field.toUpperCase()
        );
        const missingFieldsMessage = missingFieldNames.join(", ");
        setAlerts([
          ...alerts,
          {
            variant: "warning",
            message: `Please fill in the required fields: ${missingFieldsMessage}`,
          },
        ]);
        return;
      }

      const formdata = new FormData();
      for (const file of files) {
        formdata.append('files', file);
      }

      const uploadResponse = await Api.uploadFiles('/storage/file', formdata, idToken);

      if (uploadResponse.ok) {
        const keys = uploadResponse.keys;
        const formResponse = await Api.post('/form/employeereimbursement', { ...form, attachments: keys, status: 'pending' }, idToken);
        if (formResponse.message) {
          setAlerts([
            ...alerts,
            {
              variant: "success",
              message: `Success`,
            },
          ]);
        } else {
          setAlerts([
            ...alerts,
            {
              variant: "warning",
              message: `Warning record did not save, please submit again.`,
            },
          ]);
        }
      } else {
        setAlerts([
          ...alerts,
          {
            variant: "warning",
            message: `A network error occured and the files were unable to upload. Please try again`,
          },
        ]);
      }

      // handleReset(); 
    } catch (error) {
      setAlerts([
        ...alerts,
        {
          variant: "warning",
          message: `Result: ${JSON.stringify(error)}`,
        },
      ]);
    }
  };

  const handleReset = () => {
    setForm(initialFormState);
    localStorage.removeItem('@mctms-empreimbursement-form-data', JSON.stringify(initialFormState))
    didLoad(false)
  };

  const handleLoad = async () => {
    const userprofile = await Api.get(`/access/profile/uid/${user.uid}`, idToken);
    setProfile(userprofile)
    
    const formjson = localStorage.getItem('@mctms-empreimbursement-form-data');
    const formobj = formjson ? JSON.parse(formjson) : initialFormState ;
    console.log(formobj)
    setForm({...formobj, firstname: profile.firstname, lastname: profile.lastname, email : user.email});
  }

  useEffect(()=>{
    if(!load){
      handleLoad()
    }
    return () => {
      didLoad(true)
    }
  })

  return (
    <Container fluid>
      <Card>
        <Card.Header>
          <div className="img-container d-flex justify-content-center align-items-center">
            <Card.Img
              src={logo}
              alt="logo"
              style={{ width: "150px", height: "auto", }}
            />
          </div>
          <Card.Title className="text-center">
            Employee Reimbursement
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={12} md={6} lg={4}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    value={form.email}
                    disabled
                    readOnly
                  />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstname"
                    placeholder="First Name"
                    value={form.firstname}
                    disabled
                    readOnly
                  />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastname"
                    placeholder="Last Name"
                    value={form.lastname}
                    disabled
                    readOnly
                  />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={4}>
              <Form.Label>Date of Purchase</Form.Label>
              <Form.Control
                type="date"
                value={form.purchasedate}
                onChange={(e) =>
                  handleFormSet('purchasedate', e.target.value) 
                }
                required
              />
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Form.Label>Location/Route</Form.Label>
              <Form.Control
                type="text"
                placeholder="where purchased or on what route"
                value={form.location}
                onChange={(e) =>
                  handleFormSet('location', e.target.value) 
                }
              />
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Form.Label>Merchant</Form.Label>
              <Form.Control
                type="text"
                placeholder="merchant/company name"
                value={form.merchant}
                onChange={(e) => handleFormSet('merchant', e.target.value )}
              />
            </Col>
          </Row>
          <Row>
            <Col  sm={12} md={6} lg={4}>
              <Form.Label>Ops. Manager</Form.Label>
              <Form.Control
                type="text"
                placeholder="full name"
                value={form.opsmanager}
                onChange={(e) =>
                  handleFormSet('opsmanager', e.target.value) 
                }
              />
            </Col>
            <Col  sm={12} md={6} lg={4}>
              <Form.Group>
                <Form.Label>Reason</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="reason for purchase"
                  value={form.reason}
                  onChange={(e) => handleFormSet('reason', e.target.value )}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Form.Group>
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="number"
                  step="0.01"
                  placeholder="0.00"
                  value={form.amount}
                  onChange={(e) => handleFormSet('amount', e.target.value )}
                  onBlur={handleAmountBlur}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Attach File</Form.Label>

                <Card.Text
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  marginTop: "10px",
                }}
              >
                Please attach the receipt. Reimbursements WILL NOT be issued
                without a receipt.
              </Card.Text>
                <Form.Control
                  type="file"
                  name="attachment"
                  label={files ? files.length > 1 ? `${files.length} files selected` : files[0].name : "Choose file"}
                  onChange={handleFileChange}
                  multiple
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <hr/>
          <Card.Text
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    marginTop: "10px",
                  }}
                >
                  * Reimbursements will be sent via mail as a live check. Check
                  will be made out exactly as the information you provide below.
          </Card.Text>
          <hr />
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Card.Text style={{ fontSize: "12px", fontStyle: "italic" }}>
              Name exactly as it should appear on the check.
            </Card.Text>
            <Row>
              <Col sm={12} md={6} lg={4}>
                <Form.Control
                  type="text"
                  name="checkfirstname"
                  placeholder="First Name"
                  value={form.checkfirstname}
                  onChange={(e) =>
                    handleFormSet('checkfirstname', e.target.value) 
                  }
                  required
                />
              </Col>
              <Col sm={12} md={6} lg={4}>
                <Form.Control
                  type="text"
                  name="checklastname"
                  placeholder="Last Name"
                  value={form.checklastname}
                  onChange={(e) =>
                    handleFormSet('checklastname', e.target.value) 
                  }
                  required
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Form.Label>Address</Form.Label>
            <Card.Text style={{ fontSize: "12px", fontStyle: "italic" }}>
              Address where check is to be mailed.
            </Card.Text>
            <Form.Control
              type="text"
              name="address1"
              placeholder="Address Line 1"
              value={form.address1}
              onChange={(e) => handleFormSet('address1', e.target.value )}
              required
            />

            <Form.Control
              type="text"
              name="address2"
              placeholder="Address Line 2"
              value={form.address2}
              onChange={(e) => handleFormSet('address2', e.target.value )}
            />

            <Row>
              <Col xs={12} md={6} lg={4}>
                <Form.Control
                  type="text"
                  name="city"
                  placeholder="City"
                  value={form.city}
                  onChange={(e) => handleFormSet('city', e.target.value) }
                />
              </Col>
              <Col xs={12} md={6} lg={4}>
                <Form.Select
                  placeholder="Zip Code"
                  value={form.state}
                  onChange={(e) => handleFormSet('state', e.target.value )}
                >
                  <option value=""><i>State</i></option>
                  {states.map((st,i) =>(
                    <option key={i} value={st}>{st}</option>
                  ))}
                </Form.Select>
   
              </Col>
              <Col xs={12} md={6} lg={4}>
                <Form.Control
                  type="number"
                  name="zipcode"
                  placeholder="Zip Code"
                  value={form.zipcode}
                  onChange={(e) => handleFormSet('zipcode', e.target.value )}
                  onBlur={handleZipCodeBlur}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
              <Col md={6} lg={4}>
                <Form.Group>
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    placeholder="(000) 000-0000"
                    value={form.phone}
                    onChange={(e) => handleFormSet('phone', e.target.value )}
                    onBlur={handlePhoneBlur}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col>
                <Form.Check
                  type="checkbox"
                  name="acknowledge"
                  label="I acknowledge that I have reviewed the information pertaining to my reimbursement request, and all is information is true & accurate."
                  onClick={()=>handleFormSet('acknowledge', !form.acknowledge)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={handleSubmit}
                  style={{ marginTop: "10px" }}
                >
                  Submit
                </Button>
                <Button
                  variant="danger"
                  type="reset"
                  onClick={handleReset}
                  style={{ marginTop: "10px", marginLeft: "10px" }}
                >
                  Reset Form
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Card.Body>
        {alerts &&
          <Card.Body>
            <Row>
              <Col>
                {alerts.map((alrt, i) => (
                  <Alert key={i} variant={alrt.variant} dismissible onClick={() =>
                    setAlerts(alerts.filter((_, index) => index !== i))
                  }>{JSON.stringify(alrt.message)}</Alert>
                ))}
              </Col>
            </Row>
          </Card.Body>
        }
      </Card>
    </Container>
  );
}

export default EmployeeReimbursementForm;