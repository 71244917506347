import React from "react";
import { FaCalendar, FaChartBar, FaChartPie, FaCubes, FaTruck } from 'react-icons/fa';
// import {BsSpeedometer} from 'react-icons/bs'
// import { FiSettings } from 'react-icons/fi';
import {GrDashboard} from 'react-icons/gr'
import {MdAssignmentInd} from 'react-icons/md'
import NavBarComponent from "../../components/NavBar.component";
import { useRole } from "../../context/role";
const DispatchNav = () => {
    const { userRole, checkNavPermissions } = useRole();
    const navOptions = [
        { href: "/dispatch/builder", icon: <FaCubes />, text: "Builder" },
        { href: "/dispatch", icon: <FaTruck />, text: "Dispatch" },
        { href: "/dispatch/dashboard", icon: <FaChartPie />, text: "Dashboard" },
        // { href: "/dispatch/calendar", icon: <FaCalendar />, text: "Calendar" },
        // { href: "/dispatch/open", icon: <MdAssignmentInd />, text: "Open" },
        // { href: "/dispatch/reports", icon: <FaChartBar />, text: "Reports" },
        // { href: "/dispatch/settings", icon: <FiSettings />, text: "Settings" },
      ];
      const allowed = checkNavPermissions(userRole, navOptions)
    return (
        <NavBarComponent name={'Dispatch Menu'} navOptions={allowed}/>
    );
};

export default DispatchNav