import React, { useState } from 'react'
import { Container, Card, Form, Button, Row, Col } from "react-bootstrap"
import { read, utils } from 'xlsx';
import ManifestPreview from './Manifest.preview';
import AlertDisplay from '../../components/AlertDisplay';
import { useAuth } from '../../context/auth';
import Api from '../../utils/Api';


const ManifestForm = () => {
    const { user, idToken } = useAuth();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts };

    const [data, setData] = useState();
    const [files, setFiles] = useState(null);

    const handleFileChange = (e) => {
        const selectedFiles = e.target.files;
        setFiles(selectedFiles);
    };

    // const handleSubmit = async () => {
    //     try {

    //       const missingFields = Object.keys(initialFormState).filter((field) => form[field] === null || form[field] === undefined || form[field] === '');

    //       if (missingFields.length > 0) {
    //         const missingFieldNames = missingFields.map((field) =>
    //           field.toUpperCase()
    //         );
    //         const missingFieldsMessage = missingFieldNames.join(", ");
    //         setAlerts([
    //           ...alerts,
    //           {
    //             variant: "warning",
    //             message: `Please fill in the required fields: ${missingFieldsMessage}`,
    //           },
    //         ]);
    //         return;
    //       }

    //       const formdata = new FormData();
    //       for (const file of files) {
    //         formdata.append('files', file);
    //       }

    //       const uploadResponse = await Api.uploadFiles('/storage/file', formdata, idToken);

    //       if (uploadResponse.ok) {
    //         const keys = uploadResponse.keys;
    //         const formResponse = await Api.post('/form/employeereimbursement', { ...form, attachments: keys, status: 'pending' }, idToken);
    //         if (formResponse.message) {
    //           setAlerts([
    //             ...alerts,
    //             {
    //               variant: "success",
    //               message: `Success`,
    //             },
    //           ]);
    //         } else {
    //           setAlerts([
    //             ...alerts,
    //             {
    //               variant: "warning",
    //               message: `Warning record did not save, please submit again.`,
    //             },
    //           ]);
    //         }
    //       } else {
    //         setAlerts([
    //           ...alerts,
    //           {
    //             variant: "warning",
    //             message: `A network error occured and the files were unable to upload. Please try again`,
    //           },
    //         ]);
    //       }

    //       // handleReset(); 
    //     } catch (error) {
    //       setAlerts([
    //         ...alerts,
    //         {
    //           variant: "warning",
    //           message: `Result: ${JSON.stringify(error)}`,
    //         },
    //       ]);
    //     }
    //   };

    const handleUpload = async (e) => {
        try {
            const formdata = new FormData();
            for (const file of files) {
                formdata.append('files', file);
            }

            console.log(formdata);

            const uploadResponse = await Api.uploadFiles('/storage/file', formdata, idToken);
            if (uploadResponse.ok) {
                const keys = uploadResponse.keys;
                const formResponse = await Api.post('/manifests', { attachments: keys }, idToken);
                if (formResponse.message) {
                    setAlerts([
                        ...alerts,
                        {
                            variant: "success",
                            message: `Success`,
                        },
                    ]);
                } else {
                    setAlerts([
                        ...alerts,
                        {
                            variant: "warning",
                            message: `Upload error.`,
                        },
                    ]);
                }
            } else {
                setAlerts([...alerts, { variant: 'warning', message: 'Error uploading', }])
            }
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error uploading', }])
        }
    }


    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <Card.Title>
                        Manifest Preview
                    </Card.Title>
                </Card.Header>
                <AlertDisplay alertState={alertState} />
                <Card.Body>
                    <Row>
                        <Col sm={10}>
                            <Form.Control
                                type="file"
                                accept=".xls,.xlsx,.ods,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                                onChange={handleFileChange}
                                name="attachment"
                                label={files ? files.length > 1 ? `${files.length} files selected` : files[0].name : "Choose file"}
                                multiple
                                required
                                />
                        </Col>
                        <Col sm={2}>
                            <Button onClick={handleUpload}>Upload</Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {data &&
                <>
                    <br />
                    <ManifestPreview data={data} />
                </>
            }
        </Container>
    )
}

export default ManifestForm;