import { Container, Card, Row, Col, Form, Button, Alert, Spinner } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import Api from "../../utils/Api";
import { useAuth } from '../../context/auth';
import { useParams } from 'react-router-dom';
import AlertDisplay from "../../components/AlertDisplay";

const initialFormState = {
    firstname: '',
    lastname: '',
    role: '',
}

function AccessNewUserForm() {
    const [set, didSet] = useState();
    const { idToken } = useAuth();
    const { uid } = useParams();
    const [roles, setRoles] = useState();
    const [user, setUser] = useState();
    const [formdata, setFormdata] = useState(initialFormState)
    const [alerts, setAlerts] = useState([]);
    const alertState = {alerts, setAlerts}
    const [assignments, setAssignments] = useState([]);
    const [domiciles, setDomiciles] = useState([]);

    async function handlePost() {
        try {
            const data = { ...formdata, 'uid': uid, email: user.email };
            await Api.post('/access/profiles', data, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success', }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error creating', }])
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const r = await Api.query('/access/roles', { accessibility: 'Allow' }, idToken);
                setRoles(r)
                const u = await Api.get(`/firebase/user/${uid}`, idToken);
                setUser(u)
                const a = await Api.get(`/assignments/live`, idToken);
                setAssignments(a)
                const d = await Api.get(`/locations/live/Domicile`, idToken);
                setDomiciles(d)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error', }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [idToken, set, uid, alerts])

    return (
        <Container fluid>
            <Card>
                <Card.Header><b>New User Profile Form</b></Card.Header>
                <AlertDisplay alertState={alertState} />
                {set ?
                    <Card.Body>
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                Email
                                <Form.Control
                                    type="text"
                                    value={user.email}
                                    readOnly={true}
                                    disabled
                                />
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                First Name
                                <Form.Control
                                    type="text"
                                    value={formdata.firstname}
                                    onChange={e => setFormdata({ ...formdata, firstname: e.target.value })}
                                />
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Last Name
                                <Form.Control
                                    type="text"
                                    value={formdata.lastname}
                                    onChange={e => setFormdata({ ...formdata, lastname: e.target.value })}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                Role
                                <Form.Select
                                    type="text"
                                    onChange={e => setFormdata({ ...formdata, role: JSON.parse(e.target.value) })}
                                >
                                    <option></option>
                                    {roles && roles.map((role, i) => (
                                        <option key={i} value={JSON.stringify(role)}>{role.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                    Assignment
                                    <Form.Select
                                        type="text" 
                                        value={JSON.stringify(formdata.assignment)}
                                        onChange={e => setFormdata({ ...formdata, assignment : JSON.parse(e.target.value)})} 
                                    >
                                        <option></option>
                                        {assignments && assignments.map((assignment,i)=>(
                                            <option key={i} value={JSON.stringify(assignment)}>{assignment.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col sm={12} md={6} lg={3}>
                                    Domicile
                                    <Form.Select
                                        type="text" 
                                        value={JSON.stringify(formdata.domicile)}
                                        onChange={e => setFormdata({ ...formdata, domicile : JSON.parse(e.target.value)})} 
                                    >
                                        <option></option>
                                        {domiciles && domiciles.map((domicile,i)=>(
                                            <option key={i} value={JSON.stringify(domicile)}>{domicile.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <Button variant="primary" onClick={() => handlePost()}>Create</Button>
                            </Col>

                        </Row>
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    );
}

export default AccessNewUserForm;