import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import { Container, Card, Row, Col, Spinner, Badge, } from "react-bootstrap";
import Api from '../../utils/Api';
import { FaPlusCircle } from 'react-icons/fa'
import { useAuth } from '../../context/auth';
import DataDisplay from '../../components/DataDisplay';
import { Link } from 'react-router-dom';
import AlertDisplay from '../../components/AlertDisplay'

function Assignment() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [assignments, setAssignments] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [query, setQuery] = useState({
        isActive: true
    });
    useEffect(() => {
        const fetchData = async () => {
            try {
                const queryParams = new URLSearchParams(query);
                const data = await Api.query('/assignments', queryParams, idToken);
                if (!data || !Array.isArray(data)) {
                    throw new Error("Received invalid data from server.");
                }
                const cleanAssignments = data.map(assignment => {
                    return {
                        _id: assignment._id,
                        'Name': assignment.name,
                        'Active': assignment.isActive ? '✅' : '❌',
                    }
                })
                setAssignments(cleanAssignments)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: JSON.stringify(error) }]);
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [idToken, set, alerts, query])

    return (
        <Container fluid>
            <Card>
                <Card.Header className="align-items-center">
                    <Card.Title>
                        <Row>
                            <Col>
                                Assignment
                            </Col>
                            <Col style={{textAlign:'center'}}>
                                <Badge
                                    bg={query.isActive ? 'success' : 'danger'}
                                    onClick={() => { setQuery({ ...query, isActive: !query.isActive }); didSet(false) }}
                                >
                                    {query.isActive ? 'Active' : 'Inactive'}
                                </Badge>
                            </Col>
                            <Col>
                                <Link style={{ float: 'right' }} to="/plan/assignment-form"><FaPlusCircle /> Form</Link>
                            </Col>
                        </Row>
                    </Card.Title>
                </Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {set ?
                    <>
                        <Card.Body>
                            {assignments.length > 0 ?
                                <DataDisplay
                                    dataSource={assignments}
                                    lsKey={'@mctms-assignments-display'}
                                    urlPath={'/plan/assignment/'}
                                    urlKey={'_id'}
                                    popKeys={['_id']}
                                />
                                :
                                <>
                                    No data.
                                </>
                            }
                        </Card.Body>
                    </>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    )
}

export default Assignment;