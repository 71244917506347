
class Api {
  static async query(endpoint, params, idToken) {
    let urlParams;
    if (params instanceof URLSearchParams) {
      urlParams = params;
    } else {
      urlParams = new URLSearchParams();
      Object.keys(params).forEach(key => urlParams.append(key, params[key]));
    }
    const queryString = urlParams.toString();
    const url = `${process.env.REACT_APP_API_BASE_URL}${endpoint}${queryString ? `?${queryString}` : ''}`;
    
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
    });
    if (!response.ok) {
      const errorData = await response.json();
      if (response.status === 401) {
        window.location.reload();
        throw new Error('API Error: Unauthorized');
      }
      throw new Error(`API Error: ${response.status} - ${errorData.message}`);
    }
    return await response.json();
  }

  static async get(endpoint, idToken) {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + endpoint;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          window.location.reload();
          throw new Error('API Error: Unauthorized');
        }
        const error = await response.json();
        throw error;
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async post(endpoint, body, idToken) {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + endpoint;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify(body),
      });
      if (!response.ok) {
        if (response.status === 401) {
          window.location.reload();
          throw new Error('API Error: Unauthorized');
        }
        const error = await response.json();
        throw error;
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async patch(endpoint, json, idToken) {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + endpoint;
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify(json),
      });
      if (!response.ok) {
        if (response.status === 401) {
          window.location.reload();
          throw new Error('API Error: Unauthorized');
        }
        const error = await response.json();
        throw error;
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async delete(endpoint, idToken) {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + endpoint;
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          window.location.reload();
          throw new Error('API Error: Unauthorized');
        }
        const error = await response.json();
        throw error;
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async uploadFiles(endpoint, formdata, idToken) {
    try {
      const response = await fetch(process.env.REACT_APP_API_BASE_URL + endpoint, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        body: formdata,
      });
  
      if (!response.ok) {
        throw new Error('Upload response was not OK');
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      // console.error('Error uploading files:', error);
      return error;
    }
  }
  
  static async consumeFileStream(endpoint, idToken) {
    try {
      const response = await fetch(process.env.REACT_APP_API_BASE_URL + endpoint, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      });


      if (!response.ok) {
        throw new Error('Network response was not OK');
      }
  
      const contentType = response.headers.get('content-type');
      const contentDisposition = response.headers.get('content-disposition');
  
      // Create a Blob from the response data
      const blob = await response.blob();
      return {blob, contentType, contentDisposition};
    } catch (error) {
      console.error('Error fetching and consuming file stream:', error);
      throw error;
    }
  }
}

export default Api;
