import { Container, Card, Row, Col, Form, Button, Spinner, Alert } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import { CONTACT_SERVICE_TYPES, ROUTEPLAN_COVERAGE_DEFAULTS } from "../../context/appdata";
import RoutePlanDetail from "../routeplan/RoutePlan.detail";
import AlertDisplay from "../../components/AlertDisplay";

function DispatchForm() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [activeRoutePlans, setActiveRoutePlans] = useState([]);
    const [dispatch, setDispatch] = useState({});
    const [operators, setOperators] = useState();
    const [alerts, setAlerts] = useState([])
    const alertState = { alerts, setAlerts }

    async function handlePost() {
        try { 
            const formdata = {
                name : dispatch.routeplan.name,
                routeplan: dispatch.routeplan,
                servicedate: dispatch.servicedate,
                servicetype: dispatch.servicetype,
                status: 'Created',
                createdUTC: '',
                updatedUTC: '',
                events: [],
                coverage: [{ name: 'OPEN', detail:'No one is assigned and this trip needs to be covered.'}],
                operations: dispatch.routeplan.stops,
            }
            await Api.post('/dispatch', formdata, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully created dispatch', }])
        } catch (error){
            setAlerts([...alerts, { variant: 'warning', message: 'Unable to create dispatch. '+error.message, }])
        }

    }

    useEffect(() => {

        const fetchData = async () => {
            try {
                const routes = await Api.get('/routeplans/live', idToken)
                setActiveRoutePlans(routes)

                const operatorData = await Api.get('/operators/live', idToken)
                setOperators(operatorData)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error', }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    })

    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header>Dispatch Form</Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {set ?
                    <Card.Body>
                        <Row>
                            <Col>
                                {activeRoutePlans ?
                                    <>
                                        Route Plan
                                        <Form.Select value={JSON.stringify(dispatch.routeplan)} onChange={e => setDispatch({ ...dispatch, routeplan: JSON.parse(e.target.value) })}>
                                            <option>Select...</option>
                                            {activeRoutePlans.map((plan) => (
                                                <option value={JSON.stringify(plan)}>
                                                    {plan.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </>
                                    :
                                    <Row>
                                        <Col>
                                            No active routes. Create a Route by navigating to Route.
                                        </Col>
                                    </Row>
                                }
                            </Col>
                            <Col>
                                Service Date
                                <Form.Control type="date" value={dispatch.servicedate} onChange={e => setDispatch({ ...dispatch, servicedate: e.target.value })} />
                            </Col>
                            <Col>
                                Service Type
                                <Form.Select value={dispatch.servicetype} onChange={e => setDispatch({ ...dispatch, servicetype: e.target.value })} >
                                    <option>Select...</option>
                                    {CONTACT_SERVICE_TYPES.map((type, i) => (
                                        <option key={i}>{type.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        {dispatch.routeplan &&
                            <RoutePlanDetail routeplan={dispatch.routeplan} />
                        }
                        <br />
                        <Row>
                            <Col>
                                <Button variant="primary" onClick={()=>handlePost()}>Create Dispatch</Button>
                            </Col>
                        </Row>
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }
            </Card>
        </Container>
    );
}

export default DispatchForm;