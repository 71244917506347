import React, { useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap"
import { FaInfoCircle } from "react-icons/fa";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import { findUSPSNASSCode } from "../../context/uspsdata";
import { convertCentToDollar } from "../../utils/Money.helper";

const requiredKeys = [
    "TransactionId",
    "InternalTrackingNum",
    "Customer",
    "CustomerISA",
    "SCAC",
    "ShipmentId",
];

const EDI204Component = ({ ...props }) => {

    const [show204, setShow204] = useState();
    const [show204detail, setShow204Detail] = useState();

    if (!props.edi204) {
        return (
            <Container>
                <b>No EDI 204</b>
            </Container>
        )
    }

    const edi204 = props.edi204;


    let allKeysPresent = true;

    for (let i = 0; i < requiredKeys.length; i++) {
        const key = requiredKeys[i];
        if (!edi204.hasOwnProperty(key)) {
            allKeysPresent = false;
            break;
        }
    }

    if (!allKeysPresent) {
        return (
            <Container>
                <b>Incorrectly formatted EDI204 - see Raw view.</b>
            </Container>
        )
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <Table striped responsive>
                        <thead>
                            <tr>
                                <th>
                                    EDI 204
                                    <span style={{float:'right'}} onClick={() => setShow204Detail(show204detail ? false : true)}><i>{show204detail ? 'Hide' : 'Show'}</i>{show204detail ? <AiFillCaretDown /> : <AiFillCaretRight />}</span>
                                </th>
                            </tr>
                        </thead>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={6}>
                    <b>ID : </b> {edi204._id}
                </Col>
                <Col sm={12} md={6} className="float-">
                    <span style={{ float: 'right' }}><b>Status :</b> {edi204.loadtenderLifeCycleStatus}</span>
                </Col>
            </Row>
            <br />
            <Row>
                <Col sm={12} md={6} lg={3}>
                    <b>ShipmentId :</b> {edi204.ShipmentId}
                </Col>
                <Col sm={12} md={6} lg={3}>
                    <b>InternalTrackingNum :</b> {edi204.InternalTrackingNum}
                </Col>
                <Col sm={12} md={6} lg={3}>
                    <b>SCAC :</b> {edi204.SCAC}
                </Col>
            </Row>
            <br />
            {show204detail &&
                <>
                    <Row>
                        <Col sm={12} md={6} lg={3}><b>Customer :</b> {edi204.Customer}</Col>
                        <Col sm={12} md={6} lg={3}><b>CustomerISA :</b> {edi204.CustomerISA}</Col>

                    </Row>
                    <Row>
                        <Col sm={12} md={6} lg={3}><b>Type :</b> {edi204.Type}</Col>
                        <Col sm={12} md={6} lg={3}><b>TypeDesc:</b> {edi204.TypeDesc}</Col>
                        <Col sm={12} md={6} lg={3}></Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6} lg={3}><b>RespondBy :</b> {edi204.RespondBy}</Col>
                        <Col sm={12} md={6} lg={3}><b>RespondByTimeZone :</b> {edi204.RespondByTimeZone}</Col>
                        <Col sm={12} md={6} lg={3}></Col>
                    </Row>
                    <br />
                    <Row>
                        <Col sm={12} md={6} lg={3}><b>Equipment :</b> {edi204.Equipment}</Col>
                        <Col sm={12} md={6} lg={3}><b>EquipmentNumber :</b> {edi204.EquipmentNumber}</Col>
                        <Col sm={12} md={6} lg={3}><b>TotalPiecesOfEquipment :</b> {edi204.TotalPiecesOfEquipment}</Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6} lg={3}><b>Pallets :</b> {edi204.Pallets}</Col>
                        <Col sm={12} md={6} lg={3}><b>Weight :</b> {edi204.Weight}</Col>
                        <Col sm={12} md={6} lg={3}><b>WeightUnit :</b> {edi204.WeightUnit}</Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6} lg={3}><b>TransportationMethod :</b> {edi204.TransportationMethod}</Col>
                        <Col sm={12} md={6} lg={3}><b>PaymentMethod :</b> {edi204.PaymentMethod}</Col>
                        <Col sm={12} md={6} lg={3}><b>Rate :</b>{` $ ${convertCentToDollar(edi204.Rate)}`}</Col>
                    </Row>
                    <Row>
                        <Col sm={12}><b>MiscReferenceNum :</b> {edi204.MiscReferenceNum}</Col>
                    </Row>
                    <br />
                    <Row>
                        <Col sm={12}>
                            <b><u>Bill To</u></b>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <b>Name :</b> {edi204.BillTo.Name}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <b>Address :</b> {edi204.BillTo.Address1} {edi204.BillTo.Address2} {edi204.BillTo.City} {edi204.BillTo.State} {edi204.BillTo.Zip} {edi204.BillTo.Country}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <b>Contact:</b> {JSON.stringify(edi204.BillTo.Contact, "", 2)}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <b>Type : </b>{edi204.BillTo.Type}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <b>Code : </b>{edi204.BillTo.Code}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col sm={12}>
                            <b>Note : </b>{edi204.Note}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col sm={12}>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            References
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {edi204.BillTo.References && edi204.BillTo.References.map((reference, i) => (
                                        <tr key={i}>
                                            <td>{JSON.stringify(reference, "", 2)}</td>
                                        </tr>
                                    ))}
                                    {edi204.References && edi204.References.map((reference, i) => (
                                        <tr key={i}>
                                            <td>{JSON.stringify(reference, "", 2)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </>
            }

            <hr/>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th colSpan={8}>Stops</th>
                    </tr>
                    <tr>
                        <th> <FaInfoCircle /> </th>
                        <th>StopNum </th>
                        <th>Name</th>
                        <th>Address</th>
                        <th>ReasonDesc</th>
                        <th>RequestedDate TZ</th>
                        <th>LastRequestedDate TZ</th>
                    </tr>
                </thead>
                <tbody>
                    {edi204.BillTo.Stops && edi204.BillTo.Stops.map((stop, i) => (
                        <React.Fragment key={`204-fragment-${i}`}>
                            <tr key={`204-row-${i}-a`}>
                                <td></td>
                                <td onClick={() => setShow204(show204 === i ? '' : i)}>{stop.StopNum}</td>
                                <td>{findUSPSNASSCode(stop.Name)}</td>
                                <td>{stop.Address1} {stop.Address2} {stop.City} {stop.State} {stop.Zip} {stop.Country}</td>
                                <td>{stop.ReasonDesc}</td>
                                <td>{stop.RequestedDate} {stop.Date1TimeZone}</td>
                                <td>{stop.LastRequestedDate} {stop.Date2TimeZone}</td>
                            </tr>
                            {show204 === i &&
                                <tr key={`204-row-${i}-b`}>
                                    <td colSpan={9}>
                                        <pre>
                                            {JSON.stringify(stop, "", 2)}
                                        </pre>
                                    </td>
                                </tr>
                            }
                        </React.Fragment>

                    ))}
                    {edi204.Stops && edi204.Stops.map((stop, i) => (
                        <React.Fragment key={`204-fragment-${i}`}>
                            <tr key={`204-row-${i}-a`}>
                                <td onClick={() => setShow204(show204 === i ? '' : i)}>{show204 === i ? <AiFillCaretDown /> : <AiFillCaretRight />}</td>
                                <td>{findUSPSNASSCode(stop.Name)}</td>
                                <td>{stop.Name}</td>
                                <td>{stop.Address1} {stop.Address2} {stop.City} {stop.State} {stop.Zip} {stop.Country}</td>
                                <td>{stop.ReasonDesc}</td>
                                <td>{stop.RequestedDate} {stop.Date1TimeZone}</td>
                                <td>{stop.LastRequestedDate} {stop.Date2TimeZone}</td>
                            </tr>
                            {show204 === i &&
                                <tr key={`204-row-${i}-b`}>
                                    <td colSpan={8}>
                                        <pre>
                                            {JSON.stringify(stop, "", 2)}
                                        </pre>
                                    </td>
                                </tr>
                            }
                        </React.Fragment>
                    ))}
                </tbody>
            </Table>
            
        </>
    )
}


export default EDI204Component;