import React, { useState, useEffect } from 'react';
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import HtmlEmailComponent from '../../components/HtmlEmail.component';
import HtmlEmail from '../../utils/HtmlEmail';
import AlertDisplay from '../../components/AlertDisplay';

const initialForm = {
    to: [],
    subject: '',
    html: '',
}

const NotifcationForm = () => {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [form, setForm] = useState(initialForm);
    const [groups, setGroups] = useState();
    const [emailHtml, setEmailHtml] = useState({});
    const [alerts, setAlerts] = useState([]);
    const alertState = {alerts, setAlerts}

    const handleCheck = (isChecked, grp) => {
        if (isChecked) {
            setForm((prevForm) => ({
                ...prevForm,
                to: [...new Set([...prevForm.to, ...grp.members])],
            }));
        } else {
            setForm((prevForm) => ({
                ...prevForm,
                to: prevForm.to.filter((email) => !grp.members.includes(email)),
            }));
        }
    };

    const handlePost = async () => {
        try {
            const html = new HtmlEmail(emailHtml)
            const htmlStr = html.getHtmlAsStr()
            await Api.post('/messenger', {...form, html:htmlStr}, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'Success' }])
            setEmailHtml({})
            setForm(initialForm)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Update error' }])
        }
    }

    useEffect(() => {

        const fetchData = async () => {
            try { 
                const data = await Api.query('/access/groups', {}, idToken)
                setGroups(data)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }

            didSet(true)
        }

        if (!set) {
            fetchData()
        }
    }, [set, idToken, alerts])
    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header>
                    Notifcation Form
                </Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                <Card.Body>
                    Groups:
                    {(groups && groups.length > 0) &&
                        groups.map((grp, i) => (
                            <Row key={i}>
                                <Col>
                                    <Form.Check
                                        label={grp.name}
                                        onChange={(e) => handleCheck(e.target.checked, grp)}
                                    />
                                </Col>
                                <Col>
                                    {grp.members.map((member, j) => (
                                        <Col key={j}>{member}</Col>
                                    ))}
                                </Col>
                            </Row>
                        ))
                    }
                </Card.Body>
                <Card.Body>
                    <h3>Email</h3>
                    <Row>
                        <Col>
                            To:
                            <Form.Control
                                type="text"
                                value={JSON.stringify(form.to)}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Email Subject:
                            <Form.Control
                                type="text"
                                value={form.subject}
                                onChange={e => setForm({ ...form, subject: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <hr />
                    <h3>Build Message</h3>
                    <Row>
                        <Col>
                            Header:
                            <Form.Control
                                type="text"
                                value={emailHtml.header}
                                onChange={e => setEmailHtml({ ...emailHtml, header: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Greeting:
                            <Form.Control
                                type="text"
                                value={emailHtml.greeting}
                                onChange={e => setEmailHtml({ ...emailHtml, greeting: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Title:
                            <Form.Control
                                type="text"
                                value={emailHtml.title}
                                onChange={e => setEmailHtml({ ...emailHtml, title: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Message:
                            <Form.Control
                                type="text"
                                value={emailHtml.message}
                                onChange={e => setEmailHtml({ ...emailHtml, message: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Follow Up:
                            <Form.Control
                                type="text"
                                value={emailHtml.followup}
                                onChange={e => setEmailHtml({ ...emailHtml, followup: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} lg={6}>
                            URL :
                            <Form.Control
                                type="text"
                                value={emailHtml.url}
                                onChange={e => setEmailHtml({ ...emailHtml, url: e.target.value })}
                            />
                        </Col>
                        <Col sm={12} lg={6}>
                            URL Text
                            <Form.Control
                                type="text"
                                value={emailHtml.urlText}
                                onChange={e => setEmailHtml({ ...emailHtml, urlText: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} lg={6}>
                            Complimentary
                            <Form.Control
                                type="text"
                                value={emailHtml.complimentary}
                                onChange={e => setEmailHtml({ ...emailHtml, complimentary: e.target.value })}
                            />
                        </Col>
                        <Col sm={12} lg={6}>
                            Signature
                            <Form.Control
                                type="text"
                                value={emailHtml.signature}
                                onChange={e => setEmailHtml({ ...emailHtml, signature: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Message Preview
                            <HtmlEmailComponent
                                header={emailHtml.header}
                                to={form.to}
                                greeting={emailHtml.greeting}
                                title={emailHtml.title}
                                message={emailHtml.message}
                                followup={emailHtml.followup}
                                url={emailHtml.url}
                                urlText={emailHtml.urlText}
                                complimentary={emailHtml.complimentary}
                                signature={emailHtml.signature}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Button onClick={() => handlePost()}>Send</Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default NotifcationForm;