import React from "react";
import { FaCarCrash, FaChartBar, FaCubes, FaTrafficLight } from 'react-icons/fa';
import { GiPoliceBadge } from "react-icons/gi";

import NavBarComponent from "../../components/NavBar.component";
import { useRole } from "../../context/role";
const SafetyNav = () => {
    const { userRole, checkNavPermissions } = useRole();
    const navOptions = [
        { href: "/safety", icon: <FaTrafficLight />, text: "Safety" },
        { href: "/safety/dashboard", icon: <FaCarCrash />, text: "Incident Management" },
        { href: "/safety/incidentmanagement", icon: <FaCarCrash />, text: "Incident Management" },
        // { href: "/dispatch/dashboard", icon: <GrDashboard />, text: "Dashboard" },
        // { href: "/dispatch/reports", icon: <FaChartBar />, text: "Reports" },
        // { href: "/dispatch/settings", icon: <FiSettings />, text: "Settings" },
      ];
      const allowed = checkNavPermissions(userRole, navOptions)
    return (
        <NavBarComponent name={'Safety Meny'} navOptions={allowed}/>
    );
};

export default SafetyNav