import React, { useState, useEffect } from "react";
import { Container, Card, Button, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from "../../../utils/Api";
import { useAuth } from "../../../context/auth";
import { FaPlusCircle } from "react-icons/fa";
import DataTable from '../../../components/DataTable'
import DataCard from '../../../components/DataCard'
import PTOCalendarComponent from "./PTOCalendar.component";
import DataDisplay from "../../../components/DataDisplay";

function PTORequest() {

  const { idToken } = useAuth();
  const [set, didSet] = useState();
  const [pto, setPTO] = useState([])
  // const [calendar, setCalendar] = useState([])
  // const [toggleView, setToggleView] = useState('calendar');

  // const getStatusVariant = (status) => {
  //   if (status === 'draft' ? true : false) {
  //     return 'secondary';
  //   } else if (status === 'pending') {
  //     return 'warning';
  //   } else if (status === 'approved') {
  //     return 'success';
  //   } else if (status === 'denied') {
  //     return 'danger';
  //   } else if (status === 'cancelled') {
  //     return 'dark';
  //   }
  //   return 'light';
  // };

  const fetchData = async () => {
    const ptoRecords = await Api.query('/form/pto', {}, idToken);
    // setCalendar(ptoRecords)
    const cleanPTO = ptoRecords.map(record => {
      return {
        _id: record._id,
        'Status': record.status,
        'Response' :record.response,
        'Request Date' :record.requestdate,
        'First Name': record.firstname,
        'Last Name': record.lastname,
        'Email': record.lastname,
        'First Day': record.firstdayoff,
        'Last Day': record.lastdayoff,
        'Return to Work': record.returndate,
        'Days Off': record.daysrequested,
        'Hours':record.hoursrequested,
        'Type': record.type,
        'Position': record.position,
        'Region': record.region,
        'Location': record.location,
      }
    })

    setPTO(cleanPTO)

  }

  useEffect(() => {
    if (!set) {
      fetchData()
    }
    return () => didSet(true)
  })


  return (
    <Container fluid>
      <Card>
        <Card.Header>
          <b>PTO Request</b>
          <Button
            variant="link"
            className="float-end"
            href="/form/pto-form"
          >
            <FaPlusCircle /> Form{" "}
          </Button>
          {/* <span className="float-end" style={{ margin: '0px 0.5em' }}>
            <Button onClick={() => setToggleView('table')}> Table</Button>
          </span>
          <span className="float-end" style={{ margin: '0px 0.5em' }}>
            <Button onClick={() => setToggleView('card')}> Card</Button>
          </span>
          <span className="float-end" style={{ margin: '0px 0.5em' }}>
            <Button onClick={() => setToggleView('calendar')}> Calendar</Button>
          </span> */}
        </Card.Header>
        <Card.Body>
          {pto.length > 0 ?
            <>
              <DataDisplay
                dataSource={pto}
                lsKey={'@mctms-pto-display'}
                urlPath={'/form/pto/'}
                urlKey={'_id'}
                popKeys={['_id']}
                sourceName={'PTO-Requests'}
              />
            </>
            :
            <>
              No data.
            </>
          }
        </Card.Body>
      </Card>
    </Container>
  );
}

export default PTORequest;