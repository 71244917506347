import { Container, Card, Button, Row, Col, Alert, Spinner } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/auth';
import DataDisplay from "../../components/DataDisplay";
import AlertDisplay from "../../components/AlertDisplay";


function Fuel() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [data, setData] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = {alerts, setAlerts}
    const [toggleView, setToggleView] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fuel = await Api.get(`/fuel`, idToken)
                setData(fuel.response.data)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }]);
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    })

    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header>
                    <b>Fuel</b>
                    <span className="float-end">
                        <Button onClick={() => setToggleView(!toggleView)}> {toggleView ? 'Table' : 'Card'}</Button>
                    </span>
                </Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {!set ?
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                    :
                    <Card.Body>
                        {data ?
                            <DataDisplay dataSource={data} lsKey={'@mctms-fuel-display'} />
                            :
                            <Spinner />
                        }
                    </Card.Body>
                }

            </Card>
        </Container>
    );
}

export default Fuel;