import React, { useState} from "react";
import { Navbar, Nav, } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Link } from "react-router-dom";

const NavBarComponent = ({ name, navOptions }) => {
  const [offcanvasVisible, setOffcanvasVisible] = useState(false);

  return (
    <Container fluid style={{ margin: '0', padding: '0' }} className="d-none d-lg-block"> 
      <Navbar expand="lg" style={{ margin: '0', padding: '0', }}>
        <Navbar.Toggle
          variant="link"
          style={{ margin: '0.25em', fontSize: '1.1em', flex: 1, backgroundColor: '#0d6efd', color: 'white', }}
          onClick={() => setOffcanvasVisible(!offcanvasVisible)}
        >
          {name}
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="d-flex justify-content-center w-100" defaultActiveKey={window.location.pathname}>
            {navOptions.map((navOp, i) => (
              <Nav.Item key={i} style={{ padding: '1em', fontSize:'1.1em'}}>
                <Link to={navOp.href}>
                  {navOp.icon} {navOp.text}
                </Link>
              </Nav.Item>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  )
}

export default NavBarComponent;