
import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col, Form, Spinner, } from "react-bootstrap";
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import { Week1Digest, DayOfWeekDigest } from "../../utils/DayOfWeekDigest";
import { formatNameBadge } from "../../components/formatNameBadge"
import DataDisplay from "../../components/DataDisplay";
import AlertDisplay from "../../components/AlertDisplay";
import CalendarChart from "../../components/CalendarChart"

function RoutePlanForecast() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [forecast, setForecast] = useState();
    const date = new Date();
    const defaultStart = date.toISOString().split('T')[0];
    const [start, setStart] = useState(defaultStart);
    date.setDate(date.getDate() + 6);
    const defaultEnd = date.toISOString().split('T')[0];
    const [end, setEnd] = useState(defaultEnd);
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    const [ops, setOps] = useState([]);


    function operationsChartData(start, end) {
        const ops = [];
        const currentDate = new Date(start);
        const endDate = new Date(end);
        while (currentDate <= endDate) {
            ops.push([new Date(currentDate), 1]);
            currentDate.setDate(currentDate.getDate() + 1);
        }
        console.log(ops);
        return ops;
    }

    function handleDateChange(key, value) {
        if (key === 'start') {
            if (value <= end) {
                setStart(value)
                didSet(false)
            } else {
                setAlerts([...alerts, { variant: 'warning', message: 'Start date must come before end date.' }])

            }
        }
        if (key === 'end') {
            if (value >= start) {
                setEnd(value)
                didSet(false)
            } else {
                setAlerts([...alerts, { variant: 'warning', message: 'End date must come after start date.' }])
            }
        }
    }

    useEffect(() => {



        const fetchData = async () => {
            try {
                const operations = operationsChartData(start,end)
                setOps(operations)
                const data = await Api.get(`/forecast/routeplans/${start}/${end}`, idToken)
                const cleanForecast = data.map((route) => {
                    const wkday = route.routeplan.period === 'Weekly' ? Week1Digest(route.servicedate) : DayOfWeekDigest(route.servicedate);
                    return {
                        'Name': route.routeplan.name,
                        'Service Date': route.servicedate,
                        'Service Type': route.servicetype,
                        'Period': route.routeplan.period,
                        'Contract': route.routeplan.contract.name,
                        'Frequency ': `${route.routeplan.frequency.name} - ${route.routeplan.frequency.detail}`,
                        'Crew': route.routeplan.crew,
                        'Coverage': <>{route.routeplan.coverage[wkday].map(driver => formatNameBadge(driver))}</>
                    }
                })
                setForecast(cleanForecast);
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error: ' + error.error }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData();
        }
    }, [set, idToken, start, end, alerts])

    return (
        <Container fluid>
            <AlertDisplay alertState={alertState} />
            <Card>
                <Card.Header>
                    <Card.Title>Route Plan Forecast</Card.Title>
                </Card.Header>
                <Card.Body>
                    <CalendarChart
                        title={`Forecast Period`}
                        opdata={ops}
                        adjust={Math.ceil(ops.length / 365)}
                    />
                </Card.Body>
                <Card.Body>
                    <Row>
                        <Col>
                            Start <Form.Control type="date" value={start} onChange={(e) => handleDateChange('start', e.target.value)} />
                        </Col>
                        <Col>
                            End <Form.Control type="date" value={end} onChange={(e) => handleDateChange('end', e.target.value)} />
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Body>
                    {!forecast
                        ?
                        <Spinner />
                        :
                        forecast.length > 0 ?
                            <DataDisplay dataSource={forecast} lsKey={'@mctms-forecast-data'} />
                            :
                            <>
                                No data.
                            </>
                    }

                </Card.Body>
            </Card>
        </Container>
    );
}

export default RoutePlanForecast;