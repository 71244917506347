import { useEffect, useState } from "react";
import AlertDisplay from "../../components/AlertDisplay";
import Api from "../../utils/Api";
import { useAuth } from "../../context/auth";
import { Card, Container, Spinner } from "react-bootstrap";




function LoadTenderLive() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [alerts, setAlerts] = useState();
    const alertState = { alerts, setAlerts }
    const [data, setData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const timestamp = '2023-12-04 10:00';
                const report = await Api.get(`/telematics/loadtender/audit-checkpoints/${timestamp}`, idToken);
                setData(report)
            } catch (error) {
                setAlerts({ variant: 'warning', message: 'Error fetching report.' })
            }
            didSet(true);
        }
        if (!set) {
            fetchData()
        }
    }, [idToken, set, alerts])

    return (
        <Container fluid>
            <AlertDisplay alertState={alertState} />

            {set ?
                <>
                    {data?.assignedShipmentsNotTracking.length > 0 &&
                        <div>
                            <b>Assigned Shipments without Tracking</b>
                            <div>

                                {
                                    data?.assignedShipmentsNotTracking.map(line => line).join('\n')
                                }
                            </div>
                        </div>
                    }

                    <pre style={{overflowY: 'auto', overflowX: 'auto' }}>
                        {
                            data?.textTableData.map(line => line).join('\n')
                        }
                    </pre>
                </>

                :
                <>
                    <Spinner /> This service takes some time to load.
                </>
            }

        </Container>
    )
}



export default LoadTenderLive;