import React, { useState, useEffect } from "react";
import { Container, Card, Form, Alert, Accordion, Row, Col, Button, Badge, Spinner } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useParams } from 'react-router-dom';
import { useAuth } from "../../../context/auth";
import Api from "../../../utils/Api";
import logo from '../../../assets/logo.png'
import AlertDisplay from "../../../components/AlertDisplay";


function EmployeeReimbursementUpdate() {

  const { id } = useParams();
  const { idToken } = useAuth();
  const [alerts, setAlerts] = useState([]);
  const alertState = {alerts, setAlerts}
  const [load, didLoad] = useState();
  const [employeereimbursement, setEmployeeReimbursement] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [files, setFiles] = useState(null);


  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles(selectedFiles);
  };

  const handleUpload = async () => {
    try {

      const formdata = new FormData();
      for (const file of files) {
        formdata.append('files', file);
      }

      const uploadResponse = await Api.uploadFiles('/storage/file', formdata, idToken);
      // add upload keys to employee reimbursement

      if (uploadResponse.ok) {
        const { keys } = uploadResponse;
        const updateData = { attachments: [...employeereimbursement.attachments, keys] };
        const formResponse = await Api.patch(`/form/employeereimbursement/${id}`, updateData, idToken);
        if (formResponse.message) {
          setAlerts([
            ...alerts,
            {
              variant: "success",
              message: `Success`,
            },
          ]);
        } else {
          setAlerts([
            ...alerts,
            {
              variant: "warning",
              message: `Warning record did not save, please submit again.`,
            },
          ]);
        }
      } else {
        setAlerts([
          ...alerts,
          {
            variant: "warning",
            message: `A network error occured and the files were unable to upload. Files should be image or pdf. Please try again.`,
          },
        ]);
      }
      didLoad()
    } catch (error) {
      setAlerts([
        ...alerts,
        {
          variant: "warning",
          message: `Result: ${JSON.stringify(error)}`,
        },
      ]);
    }
  };

  async function handleUpdate() {
    try {
      const result = await Api.patch(`/form/employeereimbursement/${id}`, employeereimbursement, idToken)
      setAlerts([
        ...alerts,
        {
          variant: "success",
          message: `Result: ${JSON.stringify(result)}`,
        },
      ]);
    } catch (error) {
      setAlerts([
        ...alerts,
        {
          variant: "warning",
          message: `Error: ${JSON.stringify(error)}`,
        },
      ]);
    }
    didLoad()
  }

  useEffect(() => {

    const fetchData = async () => {
      didLoad(true);
      try {
        const data = await Api.get(`/form/employeereimbursement/${id}`, idToken);
        setEmployeeReimbursement(data);
        const attach = [];

        for (const attachment of data.attachments) {
          if (typeof attachment === 'string') {
            const { blob, contentType } = await Api.consumeFileStream(`/storage/file/${attachment}`, idToken);
            const url = URL.createObjectURL(blob);
            attach.push({ url: url, type: contentType });
          } else if (Array.isArray(attachment)) {
            for (const singleAttachment of attachment) {
              const { blob, contentType } = await Api.consumeFileStream(`/storage/file/${singleAttachment}`, idToken);
              const url = URL.createObjectURL(blob);
              attach.push({ url: url, type: contentType });
            }
          }
        }

        setAttachments(attach);

      } catch (error) {
        setAlerts([
          ...alerts,
          {
            variant: "warning",
            message: `${error}`,
          },
        ]);
      }
    };

    if (!load) {
      fetchData()
    }
  });

  return (
    <Container fluid>
      <Card>
        <Card.Header>
          <div className="img-container d-flex justify-content-center align-items-center">
            <Card.Img
              src={logo}
              alt="logo"
              style={{ width: "150px", height: "auto", }}
            />
          </div>
          <Card.Title className="text-center">
            Employee Reimbursement
          </Card.Title>
        </Card.Header>
        <AlertDisplay alertState={alertState} />
        {!load ?
          <Card.Body>
            <Spinner />
          </Card.Body>
          :
          <>
            <Card.Body>
              <Row>
                <Col>
                  <Badge bg='secondary' className="float-end">{employeereimbursement.status}</Badge>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} lg={4} >
                  <Card.Title>Name, Address</Card.Title>
                  <Form.Control disabled value={`${employeereimbursement.firstname} ${employeereimbursement.lastname}`} />
                  <Form.Control disabled value={employeereimbursement.address1} />
                  <Form.Control disabled value={employeereimbursement.address2} />
                  <Form.Control disabled value={`${employeereimbursement.city} ${employeereimbursement.state} ${employeereimbursement.zipcode}`} />
                </Col>
                <Col sm={12} md={6} lg={4} >
                  <Card.Title>Email</Card.Title>
                  <Form.Control disabled value={employeereimbursement.email} />
                </Col>
                <Col sm={12} md={6} lg={4} >
                  <Card.Title>Phone</Card.Title>
                  <Form.Control disabled value={employeereimbursement.phone} />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} lg={4} >
                  <Card.Title>Date:</Card.Title>
                  <Form.Control disabled value={employeereimbursement.purchasedate} />
                </Col>
                <Col sm={12} md={6} lg={4} >
                  <Card.Title>Location/Route:</Card.Title>
                  <Form.Control disabled value={employeereimbursement.location} />
                </Col>
                <Col sm={12} md={6} lg={4} >
                  <Card.Title>Ops Manager:</Card.Title>
                  <Form.Control disabled value={employeereimbursement.opsmanager} />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} lg={4} >
                  <Card.Title>Merchant</Card.Title>
                  <Form.Control disabled value={employeereimbursement.merchant} />
                </Col>
                <Col sm={12} md={6} lg={4} >
                  <Card.Title>Reason</Card.Title>
                  <Form.Control disabled value={employeereimbursement.reason} />
                </Col>
                <Col sm={12} md={6} lg={4} >
                  <Col>
                    <Card.Title>Amount</Card.Title>
                    <Form.Control disabled value={employeereimbursement.amount} />
                  </Col>
                </Col>
              </Row>
            </Card.Body>
            <Card.Body>
              <Card.Subtitle>
                Attachments : {attachments.length}
              </Card.Subtitle>
              <Row>
                <Col>
                  <Form.Control
                    type="file"
                    name="attachment"
                    label={files ? files.length > 1 ? `${files.length} files selected` : files[0].name : "Choose file"}
                    onChange={handleFileChange}
                    multiple
                    required
                    accept=".jpg, .jpeg, .png, .gif, .bmp, .tiff, .pdf, application/pdf, image/*"
                  />
                </Col>
                <Col>
                  <Button onClick={handleUpload}>Upload</Button>
                </Col>
              </Row>
              <br />
              <Accordion>
                {attachments.length > 0 && attachments.map((attachment, i) => {
                  let content;
                  const isImage = ['image/png', 'image/jpg', 'image/jpeg'].includes(attachment.type);
                  const isPDF = attachment.type === 'application/pdf';

                  if (isImage) {
                    content = <img src={attachment.url} alt={`Attachment ${i}`} width="400px" />;
                  } else if (isPDF) {
                    content = <embed src={attachment.url} width="100%" height="600px" type="application/pdf" />;
                  } else {
                    content = <p>Unsupported file type.</p>;
                  }

                  return (
                    <Accordion.Item key={i} eventKey={i.toString()}>
                      <Accordion.Header>{employeereimbursement.attachments[i]}</Accordion.Header>
                      <Accordion.Body>
                        {content}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </Card.Body>
            <Card.Body>
              <Row>
                <Col>
                  Status:
                  <Form.Select value={employeereimbursement.status} onChange={(e) => setEmployeeReimbursement({ ...employeereimbursement, status: e.target.value })}>
                    <option></option>
                    <option>not approved</option>
                    <option>approved</option>
                  </Form.Select>
                </Col>
                <Col>
                  <br />
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleUpdate}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </>
        }
      </Card>
    </Container>
  );
}

export default EmployeeReimbursementUpdate;