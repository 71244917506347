
import React from "react"
import { Alert, Badge, Card, Col, Row } from "react-bootstrap"

const AlertDisplay = ({ alertState }) => {
    const { alerts, setAlerts } = alertState;
    const containerStyle = {
        backgroundColor: '#ccc',
        border: '1px solid #aaa',
        borderRadius: '0.1em',
        zIndex: 100000,
        position: 'fixed',  // Use 'fixed' to position relative to the viewport
        bottom: '0px',      // Push it to the bottom
        right: '0px',       // Push it to the right
        textAlign: 'right',
        margin: '1em',
        maxHeight: '500px',
        overflowY: 'auto',
    };
    return (
        (alerts && alerts.length > 0) ?
            <Card style={containerStyle}>
                <Card.Header style={{ margin: 0, padding: 0 }}>
                    <Row style={{ margin: 0, padding: 0 }}>
                        <Col style={{ margin: 0, padding: 0 }}>
                            <Card.Title style={{ margin: '0.5em', float: 'left' }}>Alert</Card.Title>
                        </Col>
                        <Col style={{ margin: 0, padding: 0 }}>
                            <Badge bg={'danger'} style={{ margin: '1em', cursor: 'pointer', float: 'right' }} onClick={() => setAlerts([])}>X</Badge>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body style={{ margin: 0, padding: 0 }}>
                    {alerts.map((alrt, i) => (
                        <Alert
                            style={{ margin: '1em' }}
                            key={i}
                            variant={alrt.variant}
                            dismissible
                            onClick={() => {
                                alerts.length > 1
                                    ? setAlerts(alerts.filter(index => index !== i))
                                    : setAlerts([])
                            }}
                        >{alrt.message}</Alert>
                    ))}
                </Card.Body>
            </Card>
            :
            ''
    )
}

export default AlertDisplay