const FORM_REPORTS = [
    { forwardurl: "/jobs/forms/debrief",  name: "Daily Debrief", detail:"Forms summary"},

    // build

    // { forwardurl: "/jobs/forms/pto/pending",  name: "PTO Pending", detail:"Pending PTO requests"},
    // { forwardurl: "/jobs/forms/pto/week",     name: "PTO Approved this Week", detail:"Approved PTO requests over the next 7 days."},
    // { forwardurl: "/jobs/forms/pto/month",    name: "PTO Approved this Month", detail:"Pending PTO requests over the next 30 days."},

    // { forwardurl: "/jobs/forms/empre/pending",  name: "Emp. Reimburstments Pending ", detail:"Get a list of pending employee reimbursement."},

    // { forwardurl: "/jobs/forms/tpv/current",  name: "Current 3rd Party Fleet", detail:"Get a current list of rental and lease unit assignments, "},
    
    // { forwardurl: "/jobs/forms/roadtests",  name: "Road Tests", detail:"Get list of road tests."},

    // 
    { forwardurl: "/jobs/mailcarrier/dispatch/snapshot",  name: "Dispatch Snapshot", detail:"Active Dispatches"},
    { forwardurl: "/jobs/mailcarrier/dispatch/doublebooked",  name: "Dispatch Drivers Double booked", detail:"Active Dispatches"},
    { forwardurl: "/jobs/mailcarrier/loadtender/snapshot",  name: "Load Tender Snapshot", detail:"Active Load Tenders"},
]

export {
    FORM_REPORTS,
}