import React from 'react';
import { Badge } from 'react-bootstrap';
import { DISPATCH_STATUSES } from '../context/appdata';

const StatusBadge = ({ status }) => {
  const getStatusVariantByName = (statusName) => {
    const statusObject = DISPATCH_STATUSES.find(status => status.name === statusName);
    return statusObject ? statusObject.variant : null;
  };

  const variant = getStatusVariantByName(status);

  return <Badge bg={variant}>{status}</Badge>;
};

export default StatusBadge;