import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import { Container, Card, Button, Spinner, } from "react-bootstrap";
import Api from '../../utils/Api';
import { FaPlay, FaPlusCircle } from 'react-icons/fa'
import { useAuth } from '../../context/auth';
import DataDisplay from '../../components/DataDisplay';
import AlertDisplay from '../../components/AlertDisplay';


function Report() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [reports, setReports] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = {alerts, setAlerts}

    useEffect(() => {
        const fetchAgendaJobs = async () => {
            try {
                const data = await Api.get('/jobs/agenda', idToken);
                console.log(data)
                setReports(data)
                didSet(true)
            } catch (error) {
                setAlerts([...alerts, {variant:'warning', message:'Error fetching agenda',}])
            }
        }
        // const fetchData = async () => {
        //     try {
        //         const queryParams = new URLSearchParams();
        //         const data = await Api.query('/reports', queryParams, idToken);
        //         const cleanReports = data.map(report => {
        //             return {
        //                 _id: report._id,
        //                 'Name' : report.name,
        //                 'Recipients' : report.groupMembers ? report.groupMembers.join(', ') : 0,
        //                 'Time' : report.time,
        //                 'Timezone' : report.timezone,
        //                 'Sun' : report.sunday ? "✅" : "❌",
        //                 'Mon' : report.monday ? "✅" : "❌",
        //                 'Tue' : report.tuesday ? "✅" : "❌",
        //                 'Wed' : report.wednesday ? "✅" : "❌",
        //                 'Thu' : report.thursday ? "✅" : "❌",
        //                 'Fri' : report.friday ? "✅" : "❌",
        //                 'Sat' : report.saturday ? "✅" : "❌",
        //             }
        //         })
        //         setReports(cleanReports)
        //     } catch (error) {
        //         setAlerts([...alerts, { variant: 'warning', message: JSON.stringify(error) }]);
        //     }
        //     didSet(true)
        // }
        if(!set){
            // fetchData()
            fetchAgendaJobs()
        }
    }, [idToken, set, alerts])

    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header>
                    <b>Report</b>
                    <Button variant="link" className="float-end" href="/report-form"><FaPlusCircle /> Form</Button>
                </Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {set ?
                    <>
                        <Card.Body>
                            {reports && reports.length > 0 ?
                                <>
                                    <DataDisplay
                                        dataSource={reports}
                                        lsKey={'@mctms-reports-data'}
                                        urlPath={'/report/'}
                                        urlKey={'_id'}
                                        popKeys={['_id']}
                                        // addActions={[
                                        //     { urlPath: '/report/run/', urlKey: '_id', icon: <FaPlay />, }
                                        // ]}
                                    />
                                </>
                                :
                                <>
                                    No data.
                                </>
                            }
                        </Card.Body>
                    </>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    )
}

export default Report;