import {
  Container,
  Card,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Spinner,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import Api from "../../utils/Api";
import {
  BiSolidArrowFromBottom,
  BiSolidArrowFromTop,
  BiSolidTrashAlt,
  BiSolidXCircle,
} from "react-icons/bi";
import { RiDeleteBinLine, RiWindow2Fill } from "react-icons/ri";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import logo from "../../../src/assets/logo.png";
import { useAuth } from "../../context/auth";
import { act } from "@testing-library/react";
import './Timecard.css';
import AlertDisplay from "../../components/AlertDisplay";
import { useParams } from "react-router-dom";

function TimecardForm() {
  const {uid, payperiodid} = useParams();

  const headers = [
    { title: "Date", type: "date" },
    // { title: "Day", type: "text" },
    { title: "Begin Time", type: "time" },
    { title: "Breaks", type: "time" },
    { title: "End Time", type: "time" },
    { title: "Total Time", type: "number" },
    { title: "Block", type: "number" },
    { title: "Other Time", type: "number" },
    { title: "", type: "number" },
    { title: "HCR", type: "text" },
    { title: "Trip", type: "text" },
    { title: "Start Origin", type: "text" },
    { title: "Ending Destination", type: "text" },
    { title: "Truck Number", type: "text" },
    // { title: "Gallons Fueled", type: "text" },
    // { title: "Total Amount Fueled", type: "text" },
  ];

  const headerStyles = {
    "Begin Time": "bg-green",
    "End Time": "bg-light-red",
    "Other Time": "bg-black",
    "": "bg-black",
    "Total Time": "bg-grey",
    'Block': "bg-orange",
  };

  const { user, idToken } = useAuth();

  const defaultFormData = {
    user: user,
    status: "draft",
    periodStart: "",
    periodEnd: "",
    driverName: "",
    location: "",
    truck: "",
    car: "",
    holiday: "",
    pto: "",
    rowData: [],
    isCertified: false,
  };

  const [form, setForm] = useState(defaultFormData);
  const [alerts, setAlerts] = useState([]);
  const alertState = { alerts, setAlerts }
  const [isPending, setIsPending] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isPostAttempt, setPostAttempt] = useState(false);
  const [profile, setProfile] = useState();
  const [set, didSet] = useState(false);
  const [errorCells, setErrorCells] = useState([]);
  const [formErrors, setFormErrors] = useState([]);

  const keys = headers.map((header) => header.title);

  const breakIndex = keys.indexOf("Breaks");

  const generateEmptyRow = () => {
    const newRowData = new Array(keys.length).fill("");
    newRowData[breakIndex] = [];
    return { data: newRowData };
  };

  const [rows, setRows] = useState([generateEmptyRow()]);

  const addRow = (position, index) => {
    const newRow = generateEmptyRow();
    let updatedRows = [...rows];
    if (position === "before") {
      updatedRows.splice(index, 0, newRow);
    } else if (position === "after") {
      updatedRows.splice(index + 1, 0, newRow);
    } else {
      updatedRows.push(newRow);
    }
    setRows(updatedRows);
  };

  const removeRow = (index) => {
    const updatedRows = rows.filter((_, idx) => idx !== index);
    setRows(updatedRows);
    localStorage.setItem("timecardRows", JSON.stringify(updatedRows));
  };

  const calculateSum = (columnTitle) => {
    return rows.reduce((acc, row) => {
      const columnIndex = headers.findIndex(
        (header) => header.title === columnTitle
      );
      const value = parseFloat(row.data[columnIndex]) || 0;
      return acc + value;
    }, 0);
  };

  const otherHoursSum = calculateSum("Other Time");
  const totalTimeSum = calculateSum("Total Time");
  const blockSum = calculateSum("Block");

  function computeTotalHours(rowIndex) {
    const updatedRows = [...rows];

    let totalBreakTime = 0;
    for (const breakObj of updatedRows[rowIndex].data[2]) {
      if (breakObj.startTime && breakObj.endTime) {
        totalBreakTime += convertTime(breakObj.startTime, breakObj.endTime).difference;
      }
    }

    const headerTitles = headers.map((h) => h.title);
    const beginTime = updatedRows[rowIndex].data[headerTitles.indexOf('Begin Time')];
    const endTime = updatedRows[rowIndex].data[headerTitles.indexOf('End Time')];

    let totalTime = 0;
    if (beginTime && endTime) {
      totalTime += convertTime(beginTime, endTime).difference;
    }

    const actualWorkHours = totalTime - totalBreakTime;

    const totalTimeIndex = headers.findIndex(header => header.title === "Total Time");
    updatedRows[rowIndex].data[totalTimeIndex] = actualWorkHours.toFixed(2);

    return updatedRows;
  }

  function handleBreakUpdate(
    breakIndex,
    rowIndex,
    cellIndex,
    breakKey,
    breakValue
  ) {
    const updatedRows = [...rows];
    updatedRows[rowIndex].data[cellIndex][breakIndex][breakKey] = breakValue;

    const rowsWithUpdatedTotal = computeTotalHours(rowIndex);

    const result = rowsWithUpdatedTotal.map((row) => {
      return keys.reduce((obj, key, index) => {
        obj[key] = row.data[index];
        return obj;
      }, {});
    });

    setRows(rowsWithUpdatedTotal);

    const updatedFormData = {
      ...form,
      rowData: result,
    };

    updateForm(updatedFormData);

    localStorage.setItem("timecardRows", JSON.stringify(rowsWithUpdatedTotal));
  }

  function handleBreakRemove(rowIndex, cellIndex, breakIndex) {
    // console.log(breakIndex);
    // console.log(typeof breakIndex);
    // const updatedRows = rows.map(c => {return c});
    // const cell = updatedRows[rowIndex].data[cellIndex].map(c => {return c});
    // const updatedCell = cell.filter(
    //   index => index !== breakIndex
    // );

    // console.log(updatedCell);
    // updatedRows[rowIndex].data[cellIndex] = updatedCell;

    // const result = updatedRows.map((row) => {
    //   return keys.reduce((obj, key, index) => {
    //     obj[key] = row.data[index];
    //     return obj;
    //   }, {});
    // });

    // setRows(updatedRows);

    // const updatedFormData = {
    //   ...form,
    //   rowData: result,
    // };

    // updateForm(updatedFormData);

    // localStorage.setItem("timecardRows", JSON.stringify(updatedRows));
    const updatedRows = [...rows];

    updatedRows[rowIndex].data[cellIndex].splice(breakIndex, 1);

    const rowsWithUpdatedTotal = computeTotalHours(rowIndex);

    const result = rowsWithUpdatedTotal.map((row) => {
      return keys.reduce((obj, key, index) => {
        obj[key] = row.data[index];
        return obj;
      }, {});
    });

    // Update state and local storage
    setRows(rowsWithUpdatedTotal);
    const updatedFormData = {
      ...form,
      rowData: result,
    };
    updateForm(updatedFormData);
    localStorage.setItem("timecardRows", JSON.stringify(rowsWithUpdatedTotal));
  }

  function handleBreakAddRow(rowIndex, cellIndex) {
    console.log("add break row");
    const updatedRows = [...rows];
    updatedRows[rowIndex].data[cellIndex].push({ startTime: "", endTime: "" });
    console.log(updatedRows);

    const result = updatedRows.map((row) => {
      return keys.reduce((obj, key, index) => {
        obj[key] = row.data[index];
        return obj;
      }, {});
    });

    setRows(updatedRows);

    const updatedFormData = {
      ...form,
      rowData: result,
    };

    updateForm(updatedFormData);

    localStorage.setItem("timecardRows", JSON.stringify(updatedRows));
  }

  const renderBreaksTable = (
    breakData,
    rowIndex,
    cellIndex,
    handleBreakUpdate
  ) => {
    return (
      <div>
        <table>
          {/* <thead>
            <tr>
              <th>Start</th>
              <th>End</th>
            </tr>
          </thead> */}
          <tbody>
            {breakData.length > 0 && breakData.map((breakObj, i) => (
              <tr key={i}>
                <td>
                  <input
                    type="time"
                    value={breakObj.startTime}
                    onChange={(e) =>
                      handleBreakUpdate(
                        i,
                        rowIndex,
                        cellIndex,
                        "startTime",
                        e.target.value
                      )
                    }
                    disabled={form.status === "pending"}
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={breakObj.endTime}
                    onChange={(e) =>
                      handleBreakUpdate(
                        i,
                        rowIndex,
                        cellIndex,
                        "endTime",
                        e.target.value
                      )
                    }
                    disabled={form.status === "pending"}
                  />
                </td>
                <td>
                  <button onClick={() => handleBreakRemove(rowIndex, cellIndex, i)}>
                    <RiDeleteBinLine />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="break-buttons">
          <button onClick={() => handleBreakAddRow(rowIndex, cellIndex)}>
            Add Break
          </button>
          {/* <button onClick={() => handleBreakClear(rowIndex, cellIndex)}>
            Clear Breaks
          </button> */}
        </div>
      </div>
    );
  };

  const updateForm = (updatedFormData) => {
    setForm(updatedFormData);
    localStorage.setItem("timecardForm", JSON.stringify(updatedFormData));
  };

  const handleFormChange = (name, value) => {
    const updatedFormData = { ...form, [name]: value };
    updateForm(updatedFormData);
  };

  const handleCellChange = (newValue, rowIndex, cellIndex) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex].data[cellIndex] = newValue;

    const headersTitles = headers.map((h) => h.title);

    if (cellIndex === 6 && newValue === 0) {
      let updatedRow = [...rows[rowIndex].data];
      updatedRow[7] = '';
    }

    if (
      ["Begin Time", "End Time"].includes(
        headersTitles[cellIndex]
      )
    ) {
      const beginTime = updatedRows[rowIndex].data[headersTitles.indexOf("Begin Time")];
      const endTime = updatedRows[rowIndex].data[headersTitles.indexOf("End Time")];

      let totalTime = 0;

      if (beginTime && endTime) {
        totalTime += convertTime(beginTime, endTime).difference;
      }

      const breakData = updatedRows[rowIndex].data[headersTitles.indexOf("Breaks")];

      let totalBreakTime = 0;

      if (breakData && Array.isArray(breakData)) {
        for (const breakObj of breakData) {
          if (breakObj.startTime && breakObj.endTime) {
            totalBreakTime += convertTime(breakObj.startTime, breakObj.endTime).difference;
          }
        }
      }

      totalTime -= totalBreakTime;

      updatedRows[rowIndex].data[headersTitles.indexOf("Total Time")] =
        totalTime.toFixed(2);
    }

    const result = updatedRows.map((row) => {
      return keys.reduce((obj, key, index) => {
        obj[key] = row.data[index];
        return obj;
      }, {});
    });

    setRows(updatedRows);

    const updatedFormData = {
      ...form,
      rowData: result,
      otherHoursSum: calculateSum("Other Time"),
      totalTimeSum: calculateSum("Total Time"),
      blockSum: calculateSum("Block"),
    };

    updateForm(updatedFormData);

    localStorage.setItem("timecardRows", JSON.stringify(updatedRows));
  };

  function handleCheckboxChange(e) {
    // setIsChecked(e.target.checked);
    setForm(prevForm => ({
      ...prevForm,
      isCertified: !prevForm.isCertified
    }));
  }

  async function handleErrorChecks() {
    setErrorCells([]);
    setFormErrors([]);
    const errors = [];
    let prevEndTime = null;
    let prevEndDate = null;

    for (const [rowIndex, row] of rows.entries()) {
      row.data[5] = 0; //setting Block time to 0 so handlePost passes the value check

      const breaksArray = row.data.find(cell => Array.isArray(cell));
      const beginTime = row.data[1];
      const endTime = row.data[3];
      const period = convertTime(beginTime, endTime);
      const rowDate = row.data[0];

      //Period Date Check
      if (form.periodStart > form.periodEnd) {
        setFormErrors(prevErrors => [...prevErrors, "periodStart"]);
        errors.push({
          variant: "warning",
          message: "Period Start cannot come after Period End",
        });
      }

      // Check that Row dates/times don't overlap
      if (prevEndTime) {
        const timeDifference = convertTime(prevEndTime, beginTime, true);
        if ((timeDifference.difference < 0 && prevEndDate === rowDate) || prevEndDate > rowDate) {
          setErrorCells(prevCells => [...prevCells, { rowIndex, cellIndex: 0 }]);
          setErrorCells(prevCells => [...prevCells, { rowIndex, cellIndex: 1 }]);
          errors.push({
            variant: "warning",
            message: "The start time/date of a row must not occur before the end time of the previous row.",
          });
        }
      }

      prevEndTime = endTime;
      const date = new Date(rowDate);

      if (period.overnight) {
        date.setDate(date.getDate() + 1);
        prevEndDate = date.toISOString().split('T')[0];
      } else {
        prevEndDate = rowDate
      }

      // 14 hour rule check
      if (period.difference > 14) {
        setErrorCells(prevCells => [...prevCells, { rowIndex, cellIndex: 1 }]);
        setErrorCells(prevCells => [...prevCells, { rowIndex, cellIndex: 3 }]);
        errors.push({
          variant: "warning",
          message: "Total hours (including breaks) cannot exceed 14 hours on a single day",
        });
      }

      //8 hour rule: Must have 30 min break with over 8 hours of drive time
      if (period.difference >= 8) {
        let has30minBreak = false;

        if (breaksArray && breaksArray.length > 0) {
          for (const breakItem of breaksArray) {
            const breakDuration = convertTime(breakItem.startTime, breakItem.endTime)

            if (breakDuration.difference >= 0.5) {
              has30minBreak = true;
              break;
            }
          }
        }

        if (!has30minBreak) {
          setErrorCells(prevCells => [...prevCells, { rowIndex: rowIndex, cellIndex: 2 }])
          errors.push({
            variant: "warning",
            message: "For work periods of 8 hours or more, a break of at least 30 minutes is required",
          });
        }
      }

      // 11 hour rule check
      if (row.data[4] > 11) {
        setErrorCells(prevCells => [...prevCells, { rowIndex, cellIndex: 4 }]);
        errors.push({
          variant: "warning",
          message:
            "Work hours cannot exceed 11 hours on a single day",
        });
      }


      // Check Breaks to fall between start and end times
      for (const breakItem of breaksArray) {
        const breakStartDiff = convertTime(beginTime, breakItem.startTime, true);
        const breakEndDiff = convertTime(breakItem.endTime, endTime, true);
        const breakDiff = convertTime(breakItem.startTime, breakItem.endTime, true);

        console.log(`Break Start Diff = ${breakStartDiff.difference}`);
        console.log(`Break End Diff = ${breakEndDiff.difference}`);
        console.log(`Break Diff = ${breakDiff.difference}`);
        console.log(`period overnight? ${period.overnight}`)

        if (!period.overnight) {
          console.log('period not overnight')
          if (breakStartDiff.difference < 0 || breakEndDiff.difference < 0) {
            setErrorCells(prevCells => [...prevCells, { rowIndex: rowIndex, cellIndex: 2 }]);
            errors.push({
              variant: "warning",
              message: "Break periods must start and end within the Begin Time and End Time.",
            });
          }

          // check if break start comes before break end
          if (breakDiff.difference < 0) {
            setErrorCells(prevCells => [...prevCells, { rowIndex, cellIndex: 2 }]);
            errors.push({
              variant: "warning",
              message: "Break Start Times must come before the Break End Times",
            });
          }

          //8 hour rule: If 8 hours pass before first break starts
          if (breakStartDiff.difference >= 8) {
            setErrorCells(prevCells => [...prevCells, { rowIndex, cellIndex: 2 }]);
            errors.push({
              variant: 'danger',
              message: 'A DOT-mandated, continuous 30-minute break must occur before 8 hours of drive time'
            });
          }

          // Break start or end falls outside of begin/end times
          if (breakItem.startTime > endTime || breakItem.endTime < beginTime) {
            setErrorCells(prevCells => [...prevCells, { rowIndex, cellIndex: 2 }]);
            errors.push({
              variant: "warning",
              message: "Break periods must start and end within the Begin Time and End Time.",
            });
          }

        } else {

          // this is for when the time period crosses midnight
          let adjustedEndTime = endTime;
          let adjustedBreakStart = breakItem.startTime;
          let adjustedBreakEnd = breakItem.endTime;

          if (breakItem.startTime < beginTime && breakItem.startTime > endTime) {
            setErrorCells(prevCells => [...prevCells, { rowIndex, cellIndex: 2 }]);
            errors.push({
              variant: "warning",
              message: "Break periods must start and end within the Begin Time and End Time.",
            });
          }

          // If the endTime is after midnight (like 03:00 in a 23:00 - 03:00 period)
          if (parseInt(endTime.split(":")[0]) < parseInt(beginTime.split(":")[0])) {
            adjustedEndTime = (parseInt(endTime.split(":")[0]) + 24).toString().padStart(2, '0') + ":" + endTime.split(":")[1];
          }

          // If break starts before the adjusted beginTime
          if (parseInt(breakItem.startTime.split(":")[0]) < parseInt(beginTime.split(":")[0])) {
            adjustedBreakStart = (parseInt(breakItem.startTime.split(":")[0]) + 24).toString().padStart(2, '0') + ":" + breakItem.startTime.split(":")[1];
          }

          // If break ends after the adjusted endTime
          if (parseInt(breakItem.endTime.split(":")[0]) < parseInt(adjustedBreakStart.split(":")[0])) {
            adjustedBreakEnd = (parseInt(breakItem.endTime.split(":")[0]) + 24).toString().padStart(2, '0') + ":" + breakItem.endTime.split(":")[1];
          }

          const adjustedBreakStartDiff = convertTime(beginTime, adjustedBreakStart, true);
          const adjustedBreakEndDiff = convertTime(adjustedBreakEnd, adjustedEndTime, true);

          if (adjustedBreakStartDiff.difference < 0 || adjustedBreakEndDiff.difference < 0) {
            setErrorCells(prevCells => [...prevCells, { rowIndex, cellIndex: 2 }]);
            errors.push({
              variant: "warning",
              message: "Break periods must start and end within the Begin Time and End Time.",
            });
          }

          const adjustedBreakDiff = convertTime(adjustedBreakStart, adjustedBreakEnd, true);

          if (adjustedBreakDiff.difference < 0) {
            setErrorCells(prevCells => [...prevCells, { rowIndex, cellIndex: 2 }]);
            errors.push({
              variant: "warning",
              message: "Break Start Times must come before the Break End Times",
            });
          }

          if (adjustedBreakStartDiff.difference >= 8) {
            setErrorCells(prevCells => [...prevCells, { rowIndex, cellIndex: 2 }]);
            errors.push({
              variant: 'warning',
              message: 'A DOT-mandated, continuous 30-minute break must occur before 8 hours of drive time'
            });
          }
        }
      }

      // Check breaks so they don't overlap
      for (let i = 1; i < breaksArray.length; i++) {
        const currentBreakStart = breaksArray[i].startTime;
        const prevBreakEnd = breaksArray[i - 1].endTime;

        if (convertTime(prevBreakEnd, currentBreakStart, true).difference < 0) {
          setErrorCells(prevCells => [...prevCells, { rowIndex, cellIndex: 2 }]);
          errors.push({
            variant: "warning",
            message: `Break starting at ${currentBreakStart} begins before the previous break ends.`,
          });
        }
      }

      // Check for values in each row cell
      for (let cellIndex = 0; cellIndex < row.data.length; cellIndex++) {
        const cellValue = row.data[cellIndex];

        // Date checks
        if (cellIndex === 0) {
          if (cellValue < form.periodStart || cellValue > form.periodEnd) {
            setErrorCells(prevCells => [...prevCells, { rowIndex, cellIndex }]);
            errors.push({
              variant: 'warning',
              message: "Table dates must fall on or between the Period Start and Period End dates.",
            });
          }

          if (cellValue === form.periodEnd && period.overnight) {
            setErrorCells(prevCells => [...prevCells, { rowIndex, cellIndex: 3 }]);
            errors.push({
              variant: 'warning',
              message: "Table end times must not go past the period end date.",
            });
          }
        }


        if (cellIndex === 7 && row.data[6] === '0') {
          continue;
        }

        if (Array.isArray(cellValue)) {
          if (cellValue.length > 0) {
            for (const breakItem of cellValue) {
              if (!breakItem.startTime || breakItem.startTime === "" || !breakItem.endTime || breakItem.endTime === "") {
                setErrorCells(prevCells => [...prevCells, { rowIndex, cellIndex: 2 }]);
                errors.push({
                  variant: "warning",
                  message: "All break fields must be filled out if breaks are added.",
                });
              }
            }
          }
        } else if ((cellValue !== 0 && (!cellValue || cellValue === "")) || (cellIndex === 5 && cellValue !== 0)) {
          const errorMessage = "All timecard fields must be filled out.";
          setErrorCells(prevCells => [...prevCells, { rowIndex, cellIndex }]);
          if (!errors.some(error => error.message === errorMessage)) {
            errors.push({
              variant: 'warning',
              message: errorMessage,
            });
          }
        }
      }
    }

    // Check that certify box is marked
    if (!isChecked) {
      errors.push({
        variant: "warning",
        message:
          "Please certify the timecard information by checking the checkbox.",
      });
    }
    return errors;
  }

  async function handleSave() {
    setPostAttempt(true);
    const errors = await handleErrorChecks();
    try {
      if (errors.length > 0) {
        throw new Error('Errors in form')
      }
      const res = await Api.post("/timecards", { ...form, status: "draft" }, idToken);
      setAlerts([...alerts, {
        variant: "success",
        message: "Timecard successfully saved!",
      }]);
    } catch (error) {
      setAlerts([...alerts, ...errors]);
    }
  }

  async function handleSubmit() {
    setPostAttempt(true);
    const errors = await handleErrorChecks();
    try {
      if (errors.length > 0) {
        throw new Error('Errors in form')
      }
      const res = await Api.post("/timecards", { ...form, status: "pending" }, idToken);
      setAlerts([...alerts, {
        variant: "success",
        message: "Timecard successfully submitted!",
      }]);
      handleReset();
    } catch (error) {
      setAlerts([...alerts, ...errors]);
    }
  }

  function handleReset() {
    localStorage.removeItem("timecardRows");
    setRows([generateEmptyRow()]);

    localStorage.removeItem("timecardForm");
    setForm(defaultFormData);

    setIsChecked(false);
    setPostAttempt(false);
    setErrorCells([]);
    setFormErrors([]);
  }

  function convertTime(startTime, endTime, checkBreakTime = false) {
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);

    let hourDifference = endHour - startHour;
    let minuteDifference = endMinute - startMinute;

    let overnight = false;

    if (checkBreakTime && (hourDifference < 0 || (hourDifference === 0 && minuteDifference < 0))) {
      return { difference: -1, overnight };
    }

    if (hourDifference < 0 || (hourDifference === 0 && minuteDifference < 0)) {
      hourDifference += 24;
      overnight = true;
    }

    if (minuteDifference < 0) {
      hourDifference -= 1;
      minuteDifference += 60;
    }

    const minuteAsFraction = minuteDifference / 60;

    // return hourDifference + minuteAsFraction;
    return { difference: hourDifference + minuteAsFraction, overnight };
  }

  const isErroneous = (rowIndex, cellIndex) => {
    return errorCells.some(cell => cell.rowIndex === rowIndex && cell.cellIndex === cellIndex);
  };

  const isFormErroneous = (fieldName) => {
    return formErrors.includes(fieldName);
  };

  useEffect(() => {
    const savedRows = localStorage.getItem("@mctms-timecard-rows");
    if (savedRows) {
      setRows(JSON.parse(savedRows));
    }

    const formData = localStorage.getItem("@mctms-timecard-form");
    if (formData) {
      setForm(JSON.parse(formData));
    }

    async function fetchData() {
      try {
        const p = await Api.get(`/access/profile/uid/${user.uid}`, idToken);
        const timecards = 
        setProfile(p);
      } catch (error) {
        setAlerts([
          ...alerts,
          {
            variant: "warning",
            message: "fetch error",
          },
        ]);
        return;
      }
      didSet(true);
    }

    if (!set) {
      fetchData();
    }

  }, [set, alerts, idToken, user]);

  return (
    <Container fluid>
      <br />
      {set ? <>
        <Card>
          <Card.Header>Timecard Form</Card.Header>
          <AlertDisplay alertState={alertState} />
        </Card>
        <div className="timecard">
          <div className="timecard-banner">
            <div className="banner-logo">
              <img src={logo} alt="logo"></img>
            </div>
            <div className="timecard-title">
              <h5>Mail Management Services Timesheet 2023</h5>
            </div>
            <div className="timecard-address">
              <p>Mail Management Services</p>
              <p>1919 S. 40th Street, Ste 222</p>
              <p>Lincoln, NE</p>
              <p>68506-5248</p>
              <p>402.890.2244</p>
            </div>
            <div className="pay-period-table">
              <table style={isFormErroneous("periodStart") ? { border: '2px solid red' } : {}}>
                <tr>
                  <th>Period Start</th>
                </tr>
                <tr>
                  <td>
                    <input
                      type="date"
                      name="periodStart"
                      value={form.periodStart}
                      onChange={(e) =>
                        handleFormChange("periodStart", e.target.value)
                      }
                      disabled={isPending}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <th>Period End</th>
                </tr>
                <tr>
                  <td>
                    <input
                      type="date"
                      name="periodEnd"
                      value={form.periodEnd}
                      onChange={(e) =>
                        handleFormChange("periodEnd", e.target.value)
                      }
                      disabled={isPending}
                      required
                    />
                  </td>
                </tr>
              </table>
            </div>
            <div className="banner-disclaimer">
              DRIVERS TIME RECORD for operation within a 150 air-mile radius of
              normal reporting location For all vehicles or combinations with a
              GVWR/GCWR over 10,000 lbs. or that requires placarding
            </div>
            <div className="driver-name-table">
              <table>
                <thead>
                  <tr>
                    <th>DRIVER NAME</th>
                    <td>
                      <input
                        type="text"
                        name="driverName"
                        value={`${profile.firstname} ${profile.lastname}`}
                        onChange={(e) =>
                          handleFormChange("driverName", e.target.value)
                        }
                        disabled={isPending}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>LOCATION</th>
                    <td>
                      <input
                        type="text"
                        name="location"
                        value={form.location}
                        onChange={(e) =>
                          handleFormChange("location", e.target.value)
                        }
                        disabled={isPending}
                        required
                      />
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div className="table-container">
            <table className="table-full-width">
              <thead>
                <tr>
                  <th colSpan="9">Time Capture</th>
                  <th colSpan="2">USPS Contract</th>
                  <th colSpan="2">Service Points</th>
                  <th colSpan="1">Vehicle</th>
                </tr>
                <tr>
                  <th>Actions</th>
                  {headers.map((header) => (
                    <th
                      key={header.title}
                      className={headerStyles[header.title] || ""}
                    >
                      {header.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map((row, idx) => (
                  <tr key={row.id}>
                    <div className="action-buttons">
                      <button
                        onClick={() => addRow("before", idx)}
                        disabled={isPending}
                      >
                        <AiOutlineArrowUp />
                        <span className="tooltip-text">Add row above</span>
                      </button>
                      <button
                        onClick={() => addRow("after", idx)}
                        disabled={isPending}
                      >
                        <AiOutlineArrowDown />
                        <span className="tooltip-text">Add row below</span>
                      </button>
                      <button
                        onClick={() => removeRow(idx)}
                        disabled={rows.length === 1 || isPending}
                      >
                        <RiDeleteBinLine />
                        <span className="tooltip-text">Delete row</span>
                      </button>
                    </div>
                    {row.data.map((cellData, cellIndex) => {
                      const headerTitle = headers[cellIndex].title;

                      if (headerTitle === "Breaks") {
                        return (
                          <td
                            key={cellIndex}
                            style={isErroneous(idx, cellIndex) ? { border: '3px solid red' } : {}}
                          >
                            {renderBreaksTable(
                              row.data[cellIndex],
                              idx,
                              cellIndex,
                              handleBreakUpdate
                            )}
                          </td>
                        );
                      }

                      if (headerTitle === "") {
                        const isOtherTimeZero = row.data[6] === '0';
                        return (
                          <td key={cellIndex} style={isErroneous(idx, cellIndex) ? { border: '3px solid red' } : {}}>
                            <div className="otherTimeOptions">
                              <label>
                                <input
                                  type="radio"
                                  name="otherTimeType"
                                  value='pto'
                                  checked={isOtherTimeZero ? false : rows[idx].data[cellIndex] === 'pto'}
                                  onChange={(e) => {
                                    handleCellChange(e.target.value, idx, cellIndex)
                                  }}
                                  disabled={isPending || !row.data[6] || isOtherTimeZero}
                                />
                                PTO
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  name="otherTimeType"
                                  value='holiday'
                                  checked={isOtherTimeZero ? false : rows[idx].data[cellIndex] === 'holiday'}
                                  onChange={(e) => {
                                    handleCellChange(e.target.value, idx, cellIndex)
                                  }}
                                  disabled={isPending || !row.data[6] || isOtherTimeZero}
                                />
                                Holiday
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  name="otherTimeType"
                                  value='other'
                                  checked={isOtherTimeZero ? false : rows[idx].data[cellIndex] === 'other'}
                                  onChange={(e) => {
                                    handleCellChange(e.target.value, idx, cellIndex)
                                  }}
                                  disabled={isPending || !row.data[6] || isOtherTimeZero}
                                />
                                Other
                              </label>
                            </div>
                          </td>
                        );
                      }

                      const isBlock = headerTitle === "Block";

                      return (
                        <td
                          key={cellIndex}
                          // style= {{border: '2px solid red'}}
                          style={isErroneous(idx, cellIndex) ? { border: '3px solid red' } : {}}
                        >
                          <input
                            type={headers[cellIndex].type}
                            value={cellData}
                            step={
                              headerTitle === "Other Time" ||
                                headerTitle === "Total Time" ||
                                headerTitle === "Block"
                                ? "0.01"
                                : undefined
                            }
                            readOnly={
                              headerTitle === "Total Time" ||
                              headerTitle === "Day"
                            }
                            onChange={(e) =>
                              handleCellChange(e.target.value, idx, cellIndex)
                            }
                            disabled={isPending || (isBlock && !isPending)}
                            required={headerTitle !== "Other Time" ? true : false}
                          />
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan="5" rowSpan="2">
                    *Pay periods start Saturday and end on Friday*
                  </th>
                  <td className="tfoot-padding">{totalTimeSum.toFixed(2)}</td>
                  <td className="tfoot-padding">{blockSum.toFixed(2)}</td>
                  <td className="tfoot-padding">{otherHoursSum.toFixed(2)}</td>
                  <td className="certify" colSpan="7" rowSpan="2">
                    <div className="flex-container" style={!form.isCertified && isPostAttempt ? { border: '2px solid red' } : {}}>
                      <div className="certify-box">
                        <input
                          type="checkbox"
                          disabled={isPending}
                          checked={form.isCertified}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="yellow-highlight">
                        <strong>
                          I certify that the above information has been completed
                          by me and is true and accurate
                        </strong>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Total Work Hours</th>
                  <th>Block Total</th>
                  <th>Other Hours Total</th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="form-buttons">
            <button onClick={handleReset} disabled={isPending}>
              RESET FORM
            </button>
            <button onClick={handleSave} disabled={isPending}>
              SAVE FORM
            </button>
            <button onClick={handleSubmit} disabled={isPending}>
              SUBMIT FORM
            </button>
          </div>
          <div className="bottom-section">
            <div className="bottom-text">
              <p>
                This document must be retained by the driver through the first
                week of the following month so that a previous seven day record of
                duty status can be presented to any enforcement officer upon
                request.
              </p>
              <p>
                A driver is exempt from maintaining the Drivers Daily Log
                Requirements of section 395.8 if ALL of the following are true
                395.1( e ) ( 1).{" "}
              </p>
              <p>
                The driver returns to their normal work reporting location and is
                released from work within 14 consecutive hours, A property
                carrying commercial motor vehicle driver must have at least 10
                consecutive hours off duty separating each 14 hours on duty{" "}
              </p>
              <strong>
                If a driver who uses the 150 air-mile radius exemption exceeds the
                150 air-mile radius, the 14 hour work day, or fails to take a
                minimum of 10 hours off duty between any work day period, the
                driver must comply with all regulations associated with the driver
                logbook requirements including daily hours of service worked.
              </strong>
            </div>
            {/* <div className="mgmt-table">
            <h6>FOR MANAGEMENT USE ONLY</h6>
            <table>
              <tr>
                <th>Truck:</th>
                <td>
                  <input 
                    type="text" 
                    name="truck" 
                    value={form.truck}
                    onChange={(e) => handleFormChange('truck', e.target.value)} 
                  />
                </td>
              </tr>
              <tr>
                <th>Car:</th>
                <td>
                  <input 
                    type="text"
                    name="car" 
                    value={form.car}
                    onChange={(e) => handleFormChange('car', e.target.value)}
                  />                  
                </td>
              </tr>
              <tr>
                <th>Holiday:</th>
                <td>
                  <input 
                    type="text"
                    name="holiday" 
                    value={form.holiday}
                    onChange={(e) => handleFormChange('holiday', e.target.value)}
                    />
                </td>
              </tr>
              <tr>
                <th>PTO:</th>
                <td>
                  <input 
                    type="text"
                    name="pto" 
                    value={form.pto}
                    onChange={(e) => handleFormChange('pto', e.target.value)}/>
                </td>
              </tr>
            </table>
          </div> */}
          </div>
        </div>
      </> : <Spinner />}
    </Container>
  );
}

export default TimecardForm;