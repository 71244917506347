import { Container, Card, Row, Col, Form, Button, Spinner, Alert} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import Api from "../../utils/Api";
import { useParams } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import AlertDisplay from "../../components/AlertDisplay";


function AccessProfileUpdate() {
    const { user, idToken } = useAuth();
    const { id } = useParams();
    const [set, didSet] = useState();
    const [roles, setRoles] = useState({});
    const [profile, setProfile] = useState({});
    const [alerts, setAlerts] = useState([]);
    const alertState = {alerts, setAlerts}
    const [assignments, setAssignments] = useState([]);
    const [domiciles, setDomiciles] = useState([]);


    async function handleUpdate() {
        try {
            await Api.patch(`/access/profile/${id}`, {...profile, email: user.email}, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success', }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error updating', }])
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                
                const r = await Api.get('/access/roles', idToken);
                setRoles(r)
                const p = await Api.get(`/access/profile/${id}`, idToken);
                setProfile(p)
                const a = await Api.get(`/assignments/live`, idToken);
                setAssignments(a)
                const d = await Api.get(`/locations/live/Domicile`, idToken);
                setDomiciles(d)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error', }])
            }
            didSet(true)
        }
        if(!set){
            fetchData()
        }
    })



    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <b>Profile Update</b>
                </Card.Header>
                <AlertDisplay alertState={alertState} />
                {set ?
                    <>
                        <Card.Body>
                            <Row>
                                <Col sm={12} md={6} lg={3}>
                                    Email <br/>
                                    <Form.Control 
                                        type="text" 
                                        value={profile.email}
                                        readOnly={true}
                                    />
                                </Col>
                                <Col sm={12} md={6} lg={3}>
                                    First Name
                                    <Form.Control 
                                        type="text" 
                                        value={profile.firstname}
                                        onChange={e => setProfile({ ...profile, firstname : e.target.value})} 
                                    />
                                </Col>
                                <Col sm={12} md={6} lg={3}>
                                    Last Name
                                    <Form.Control 
                                        type="text" 
                                        value={profile.lastname}
                                        onChange={e => setProfile({ ...profile, lastname : e.target.value})} 
                                    />
                                </Col>
                                <Col sm={12} md={6} lg={3}>
                                    Role
                                    <Form.Select
                                        type="text" 
                                        value={JSON.stringify(profile.role)}
                                        onChange={e => setProfile({ ...profile, role : JSON.parse(e.target.value)})} 
                                    >
                                        <option></option>
                                        {roles && roles.map((role,i)=>(
                                            <option key={i} value={JSON.stringify(role)}>{role.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                {/* <Col sm={12} md={6} lg={3}>
                                    Assignment
                                    <Form.Select
                                        type="text" 
                                        value={JSON.stringify(profile.assignment)}
                                        onChange={e => setProfile({ ...profile, assignment : JSON.parse(e.target.value)})} 
                                    >
                                        <option></option>
                                        {assignments && assignments.map((assignment,i)=>(
                                            <option key={i} value={JSON.stringify(assignment)}>{assignment.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col sm={12} md={6} lg={3}>
                                    Domicile
                                    <Form.Select
                                        type="text" 
                                        value={JSON.stringify(profile.domicile)}
                                        onChange={e => setProfile({ ...profile, domicile : JSON.parse(e.target.value)})} 
                                    >
                                        <option></option>
                                        {domiciles && domiciles.map((domicile,i)=>(
                                            <option key={i} value={JSON.stringify(domicile)}>{domicile.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col> */}
                            </Row>
                            <br/>
                            <Row>
                                <Col>
                                    <Button variant="primary" onClick={()=>handleUpdate()}>Update</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    );
}

export default AccessProfileUpdate;