import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Row, Col } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const DataTable = ({ dataSource, lsKey, urlPath, urlKey, popKeys = [] }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [columns, setColumns] = useState(() => {
    const savedColumns = localStorage.getItem(lsKey);
    if (savedColumns) {
      return JSON.parse(savedColumns);
    }
    return Object.keys(dataSource[0] || {});
  });
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState(dataSource);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [showFilters, setShowFilters] = useState(false);


  filteredData.sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
    if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });

  const resetLocalStorage = () => {
    localStorage.removeItem(lsKey);
    setColumns(Object.keys(dataSource[0] || {}));
  };

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = itemsPerPage === -1 ? filteredData : filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const currentPageRange = Math.min(currentPage + 2, totalPages);

  useEffect(() => {
    const keysMatchLocalStorage = () => {
      const savedColumns = localStorage.getItem(lsKey);
      if (!savedColumns) return false; // if there are no saved columns, they don't match
      const storedKeys = JSON.parse(savedColumns);
      const currentKeys = Object.keys(dataSource[0] || {});
      return JSON.stringify(storedKeys.sort()) === JSON.stringify(currentKeys.sort());
    };
  
    if (!keysMatchLocalStorage()) {
      resetLocalStorage();
    }
  
    localStorage.setItem(lsKey, JSON.stringify(columns));
  
    const applyFiltersAndSearch = () => {
      let filteredDataSource = dataSource;
  
      // Apply search text filter
      if (searchText.trim() !== '') {
        filteredDataSource = dataSource.filter((item) => {
          return columns.some((column) => {
            return item[column].toString().toLowerCase().includes(searchText.toLowerCase());
          });
        });
      }
  
      setFilteredData(filteredDataSource);
      setCurrentPage(1);
    };
  
    applyFiltersAndSearch();
  }, [dataSource, lsKey, columns, resetLocalStorage, searchText]);
  

  const handleColumnReorder = (dragIndex, dropIndex) => {
    const updatedColumns = [...columns];
    const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
    updatedColumns.splice(dropIndex, 0, draggedColumn);
    setColumns(updatedColumns);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (e) => {
    setCurrentPage(1);
    setItemsPerPage(parseInt(e.target.value));
  };

  const handleNavigationKeys = (e) => {
    if (e.key === 'ArrowLeft' && currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    } else if (e.key === 'ArrowRight' && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleColumnSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };


  const handleFilterChange = (column, value) => {
    const newFilters = { ...appliedFilters, [column]: value };
    setAppliedFilters(newFilters);

    let filtered = dataSource;
    Object.entries(newFilters).forEach(([filterColumn, filterValue]) => {
      if (filterValue !== '') {
        filtered = filtered.filter((item) => {
          const columnValue = item[filterColumn];

          if (typeof columnValue === 'string') {
            return columnValue.includes(filterValue);
          } else if (columnValue instanceof Date) {
            return columnValue.toISOString().includes(filterValue);
          } else if (typeof columnValue === 'number') {
            return columnValue.toString().includes(filterValue);
          }

          return false;
        });
      }
    });

    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const getUniqueValues = (data, column) => {
    const uniqueValues = new Set();
    data.forEach((item) => {
      const value = item[column];
      if (value) {
        uniqueValues.add(value);
      }
    });

    const sortedValues = Array.from(uniqueValues).sort((a, b) => {
      if (typeof a === 'string' && typeof b === 'string') {
        return a.localeCompare(b);
      } else if (typeof a === 'string') {
        return -1;
      } else if (typeof b === 'string') {
        return 1;
      } else {
        return a - b;
      }
    });

    return sortedValues;
  };

  const renderDraggableTableHeader = () => {
    return (
      <tr>
        {(urlPath && urlKey) &&
          <th></th>
        }
        {columns.map((column, index) => (
          !popKeys.includes(column) && 
          <th key={index}>
            <div
              className="d-flex align-items-center"
              draggable
              onDragStart={(e) => e.dataTransfer.setData('text/plain', index)}
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => {
                const dragIndex = parseInt(e.dataTransfer.getData('text/plain'));
                const dropIndex = index;
                handleColumnReorder(dragIndex, dropIndex);
              }}
            >
              <span onClick={() => handleColumnSort(column)}>{column}</span>
              {sortColumn === column && (
                <span className="sort-arrow">{sortOrder === 'asc' ? '↑' : '↓'}</span>
              )}
            </div>
            {showFilters && (
              <Form.Select
                style={{ minWidth: '200px' }}
                value={appliedFilters[column] || ''}
                onChange={(e) => handleFilterChange(column, e.target.value)}
                multiple
              >
                <option value="">All</option>
                {getUniqueValues(filteredData, column).map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </Form.Select>
            )}
          </th>
        ))}
      </tr>
    );
  };

  const renderCellValue = (value) => {
    if (typeof value === 'object') {
      if (React.isValidElement(value)) {
        return value;
      }
      return JSON.stringify(value);
    }
    return value;
  };
  
  const renderTableRows = () => {
    return currentItems.map((item, index) => (
      <tr key={index}>
        {(urlPath && urlKey) &&
          <td>
            <Link to={urlPath + item[urlKey]}>
              <FaEdit />
            </Link>
          </td>
        }
        {
          columns.map((column, idx) => (
            !popKeys.includes(column) && 
              <td key={idx}
              style={{
                maxWidth: '250px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }} title={renderCellValue(item[column])}>
                {renderCellValue(item[column])}
              </td>
          ))}
      </tr>
    ));
  };

  const renderPagination = () => {
    const pageNumbers = [];

    for (let number = Math.max(1, currentPage - 2); number <= currentPageRange; number++) {
      pageNumbers.push(
        <li
          key={number}
          className={`page-item ${currentPage === number ? 'active' : ''}`}
          onClick={() => handlePageChange(number)}
        >
          <span className="page-link">{number}</span>
        </li>
      );
    }


    return (
      <nav>
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <Button variant="link" onClick={() => handlePageChange(1)}>
              First
            </Button>
          </li>
          {currentPage > 1 && (
            <li className="page-item">
              <Button variant="link" onClick={() => handlePageChange(currentPage - 1)}>
                &laquo;
              </Button>
            </li>
          )}
          {pageNumbers}
          {currentPage < totalPages && (
            <li className="page-item">
              <Button variant="link" onClick={() => handlePageChange(currentPage + 1)}>
                &raquo;
              </Button>
            </li>
          )}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <Button variant="link" onClick={() => handlePageChange(totalPages)}>
              Last
            </Button>
          </li>
        </ul>
      </nav>
    );
  };

  return (
    <div className="table-container">
      <Row>
        <Col sm={12} md={6} lg={6}>
          <Form.Control
            type="text"
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Col>
        <Col sm={12} md={3} lg={3}>
          <Form.Select className="w-auto" value={itemsPerPage} onChange={handleItemsPerPageChange}>
            <option value={-1}>All</option>
            <option value={1}>1</option>
            <option value={5}>5</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Form.Select>
        </Col>
        <Col sm={12} md={3} lg={3}>
          {/* <Button variant="link" className="auto" onClick={() => { setShowFilters(!showFilters); resetLocalStorage(); }}>
            {showFilters ? 'Hide' : 'Show'} Filters
          </Button> */}
          <Button variant="link" className="auto" onClick={resetLocalStorage}>
            Reset Table
          </Button>
        </Col>
      </Row>
      <Table striped bordered hover responsive style={{ whiteSpace: 'nowrap' }}>
        <thead>{renderDraggableTableHeader()}</thead>
        <tbody>{renderTableRows()}</tbody>
      </Table>
      <div className="d-flex justify-content-between align-items-center p-2">
        <div>
          <span>
            Displaying {currentItems.length} out of {filteredData.length} records
          </span>
        </div>
        <div>{renderPagination()}</div>
      </div>
      <div tabIndex={0} onKeyDown={handleNavigationKeys} />
    </div>
  );
};

export default DataTable;
