
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaCalendar, FaDownload, FaHome, FaInfo, FaUser, } from 'react-icons/fa';
import { ImFilePdf } from 'react-icons/im';
import { BsCashStack } from 'react-icons/bs';
import { MdAttachMoney } from 'react-icons/md';
import NavBarComponent from '../../components/NavBar.component';


const TimecardNav = () => {

    const navOptions = [
        { href: "/timecard/info", icon: <FaInfo />, text: "Timecard Info" },
        { href: "/timecard/profile", icon: <MdAttachMoney />, text: "My Timecards" },
        { href: "/timecard", icon: <FaHome />, text: "Timecards" },
        // { href: "/timecard/dashboard", icon: <BsCashStack />, text: "Dashboard" },
        // { href: "/timecard/exports", icon: <FaDownload />, text: "Exports" },
        // { href: "/timecard/reports", icon: <ImFilePdf />, text: "Reports" },
        { href: "/timecard/payperiods", icon: <FaCalendar/>, text: "Pay Periods" },
      ];

    return (
        <NavBarComponent name={'Timecard Nav'} navOptions={navOptions}/>
    );
};

export default TimecardNav