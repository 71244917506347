import React from 'react'
import { Container, Card} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

function FiftyFiveHundredInfo() {

    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <Card.Title>Info * Prototype * </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Card.Title>
                        Instructions
                    </Card.Title>
                    <Card.Text>
                        <ol>
                            <li><Card.Link href="/ffh/upload">Upload</Card.Link> USPS 5500 files.</li>
                            <li>Use <Card.Link href="/ffh/table">Table</Card.Link> to filter and sort your data.</li>
                            <li>Use <Card.Link href="/ffh/reports">Reports</Card.Link> to see graphics and trends.</li>
                        </ol>
                    </Card.Text>
                    <Card.Title>
                        Limitations
                    </Card.Title>
                    <Card.Text>
                        <ul>
                            <li>Only USPS 5500 spreadsheets downloaded from https://logistics.usps.com/ Surface Transportation Automated Forms Portal can be uploaded.</li>
                            <li>There is a 5 MB limit for spreadsheeet uploads. (about 5,000 x USPS 5500s)</li>
                        </ul>
                    </Card.Text>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default FiftyFiveHundredInfo;