import React from 'react';
import { Button } from 'react-bootstrap';

const Tag = ({ name, fontColorHEX, backgroundColorHEX, handleFunction}) => {
  const tagStyle = {
    backgroundColor: backgroundColorHEX,
    color: fontColorHEX,
    border: `1px solid ${fontColorHEX}`,
    padding: '4px 8px',
    borderRadius: '4px',
    display: 'inline-block',
    marginRight: '8px',
    marginBottom: '8px',
  };

  return (
    <Button style={tagStyle} onClick={handleFunction}>
      {name}
    </Button>
  );
};

export default Tag;
