import { Container, Card, Row, Col, Button, Spinner, Alert, Form, Table, Badge,} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useCallback } from 'react'
import Api from "../../utils/Api";
import { useParams, useNavigate } from "react-router-dom"
import { useAuth } from '../../context/auth';
import { DISPATCH_STATUSES, ROUTEPLAN_COVERAGE_DEFAULTS, ROUTE_ACTION, TIMEZONE_OPTIONS } from "../../context/appdata";
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp, FaCaretDown, FaCaretRight, FaList, FaSignal, FaStickyNote, FaStopwatch, FaTrailer, FaTrash, FaTruck, FaUser } from "react-icons/fa";
import { useSettings } from "../../context/settings";
import { parseUtcToStr } from "../../utils/DateTimeFormat.helper";
import AlertDisplay from "../../components/AlertDisplay";
import DispatchRoutePlanDetail from "./Dispatch.routeplan.detail";
import DriverHOSLog from "../../components/DriverHOSLog";
import GoogleMapPlotRoute from "../../components/GoogleMapPlotRoute";
import ConfirmationModal from "../../components/ConfirmationModal";

function DispatchUpdate() {
    const navigate = useNavigate();
    const { id } = useParams();
    const { user, idToken } = useAuth();
    const { settings } = useSettings();
    const [load, didLoad] = useState();
    const [refetch, shouldRefetch] = useState();
    const [set, didSet] = useState();
    const [alerts, setAlerts] = useState([])
    const alertState = { alerts, setAlerts }
    const [dispatch, setDispatch] = useState();
    const [showDispatch, setShowDispatch] = useState(false);
    const [showTelematics, setShowTelematics] = useState(true);
    const [showDangerous, setShowDangerous] = useState(false);
    const [showRevive, setShowRevive] = useState(false);
    const [showReviveConfirmation, setShowReviveConfirmation] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [telematicsAudit, setTelematicsAudit] = useState();
    const [stops, setStops] = useState([]);
    const [coverage, setCoverage] = useState([]);
    const [events, setEvents] = useState([]);
    const [notes, setNotes] = useState([]);
    const [locations, setLocations] = useState([]);
    const [operators, setOperators] = useState([]);
    const [showStopContent, setShowStopContent] = useState([]);
    const [dispatchWaypoints, setDispatchWaypoints] = useState([]);
    const [routeplanWaypoints, setRoutePlanWaypoints] = useState([]);

    const [searchTolerance, setSearchTolerance] = useState(1);
    const [appointmentTolerance, setAppointmentTolerance] = useState(1);
    const [goodaudit, setGoodAudit] = useState('');
    const [pointoffailure, setPointOfFailure] = useState('');

    const [showGoogleMaps, setShowGoogleMaps] = useState();

    const initialNote = {
        userId: user.uid,
        userEmail: user.email,
        note: '',
    }

    const [note, setNote] = useState(initialNote)

    async function updateTelematicsReport() {
        try {
            const telematicsReportUpdate = {
                dispatchId: dispatch._id,
                searchTolerance: searchTolerance,
                appointmentTolerance: appointmentTolerance,
                goodaudit: goodaudit,
                pointoffailure: pointoffailure,
                dispatch: dispatch,
                telematics: telematicsAudit,
                userId: user.uid, email: user.email
            }
            await Api.patch(`/telematics/reports`, telematicsReportUpdate, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Telematics report upsert.' }])
            didLoad(false);
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: JSON.stringify(error.error) }])
        }
    }

    async function handleDispatchActivate() {
        try {
            const activityUpdate = { isActive: true, userId: user.uid, email: user.email }
            await Api.patch(`/dispatch/${id}/revive`, activityUpdate, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Dispatch activated', }])
            setShowReviveConfirmation(false)
            setShowRevive(false)
            didLoad(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured activating dispatch.', }])
        }
    }

    async function handleDispatchDectivate() {
        try {
            const activityUpdate = { isActive: false, userId: user.uid, email: user.email }
            await Api.patch(`/dispatch/${id}/kill`, activityUpdate, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Dispatch deactivated', }])
            setShowConfirmation(false)
            setShowDangerous(false)
            didLoad(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured deactivating dispatch.', }])
        }
    }

    async function updateStatus(status) {
        try {
            const statusUpdate = { status: status.name, userId: user.uid, email: user.email }
            await Api.patch(`/dispatch/${id}/status`, statusUpdate, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Status updated.' }])
            didLoad(false);
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: `The status was NOT updated.` }])
        }
    }

    async function updateCoverage() {
        try {
            const coverageUpdate = { coverage: dispatch.coverage, userId: user.uid, email: user.email }
            await Api.patch(`/dispatch/${id}/coverage`, coverageUpdate, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Coverage updated.' }])
            didLoad(false);
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Coverage was not updated.' }])
        }
    }

    async function updateStops() {
        try {
            const stopsUpdate = { stops: stops, userId: user.uid, email: user.email }
            await Api.patch(`/dispatch/${id}/stops`, stopsUpdate, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Operations updated.' }])
            didLoad(false);
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Operations were not updated.' }])
        }
    }

    async function createNote() {
        try {
            const notesUpdate = { note: note, userId: user.uid, email: user.email }
            await Api.patch(`/dispatch/${id}/notes`, notesUpdate, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Note created.' }])
            setNote(initialNote)
            didLoad(false);
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Note was not created.' }])
        }
    }

    function setStop(i, k, v) {
        stops[i][k] = v
        setDispatch({ ...dispatch, [stops]: [...stops] })
    }

    function moveStopUp(index) {
        if (index > 0) {
            const stop = stops[index];
            const newStops = [...stops];
            newStops.splice(index, 1);
            newStops.splice(index - 1, 0, stop);
            setStops([...newStops]);
            setDispatch({ ...dispatch, [stops]: [...newStops] })
        }
    }

    function moveStopDown(index) {
        if (index < stops.length - 1) {
            const stop = stops[index];
            const newStops = [...stops];
            newStops.splice(index, 1);
            newStops.splice(index + 1, 0, stop);
            setDispatch({ ...dispatch, [stops]: [...newStops] })
            setStops([...newStops]);
        }
    }

    function removeStop(index) {
        const filtered = stops.filter((_, i) => {
            return i !== index
        })
        setStops([...filtered])
        setDispatch({ ...dispatch, [stops]: [...filtered] })
    }

    function removeCoverage(j) {
        const coverageArr = coverage.filter((i, index) => index !== j) ?? [];
        const newCoverage = [...coverageArr]
        setCoverage(newCoverage)
        setDispatch({ ...dispatch, coverage: newCoverage })
    }

    function cover(val) {
        const coverageArr = coverage ?? []
        const newCoverage = [...coverageArr, val]
        setCoverage(newCoverage)
        setDispatch({ ...dispatch, coverage: newCoverage })
    }

    // dedup in route and dispatch
    function sumTimeDifferences(times) {
        const diffs = [];
        // let accum = 0
        for (let i = 1; i < times.length; i++) {
            const timeString1 = times[i - 1];
            const timeString2 = times[i];
            const [time1, tz1] = timeString1.split(' ');
            const [hour1, minute1] = time1.split(':');
            const [time2, tz2] = timeString2.split(' ');
            const [hour2, minute2] = time2.split(':');
            const date1 = new Date(0, 0, 0, hour1, minute1)
            const date2 = new Date(0, 0, 0, hour2, minute2)
            let ds1 = date1.toLocaleString('en-US', { timeZone: tz1 });
            let ds2 = date2.toLocaleString('en-US', { timeZone: tz2 });
            if (Date.parse(ds2) <= Date.parse(ds1)) {
                // add 24 hours to date2 if it is less than date1
                ds2 = new Date(Date.parse(ds2) + 24 * 60 * 60 * 1000);
            }
            const diff = Date.parse(ds2) - Date.parse(ds1);
            diffs.push(diff);
        }
        const totalDiff = diffs.reduce((acc, curr) => acc + curr, 0);
        const totalHours = totalDiff / 3600000;
        return totalHours;
    }

    function areTimesFormattedCorrectly(times) {
        const regex = /^\d{2}:\d{2} (CT|MT|PT|ET|America\/New_York|America\/Chicago|America\/Denver|America\/Los_Angeles)$/;
        return times.every(time => regex.test(time));
    }

    function calculateRouteHours(stops) {
        const times = [];
        for (let i = 0; i < stops.length; i++) {
            times.push(stops[i].arrive + " " + stops[i].timezone)
            times.push(stops[i].depart + " " + stops[i].timezone)
        }
        if (areTimesFormattedCorrectly(times)) {
            const timelapse = sumTimeDifferences(times)
            return timelapse
        } else {
            return 0;
        }
    }

    const updateShowStopContent = (index) => {
        const updatedShowStopContent = [...showStopContent];
        updatedShowStopContent[index] = !updatedShowStopContent[index];
        setShowStopContent(updatedShowStopContent);
    };

    function getTimezoneCode(timezoneName) {
        const timezoneIndex = TIMEZONE_OPTIONS.findIndex(option => option.name === timezoneName);

        if (timezoneIndex !== -1) {
            return TIMEZONE_OPTIONS[timezoneIndex].code;
        }

        return null;
    }

    function convertMillisecondsToHoursMinutes(durationInMilliseconds) {
        const hours = Math.floor(durationInMilliseconds / 3600000);
        const minutes = Math.floor((durationInMilliseconds % 3600000) / 60000);
        return `${hours} hr ${minutes} min`;
    }

    function formatTime(inputTime, timezone) {
        const dt = new Date(inputTime);
        const formattedTime = dt.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
            timeZone: timezone,
            hourCycle: 'h23'
        });

        return formattedTime;
    }

    async function nextDispatch() {
        try {
            const nextDispatch = await Api.get(`/dispatch/${id}/next`, idToken)
            navigate(`/dispatch/${nextDispatch._id}`);
            didLoad(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Unable to get dispatch' }])
        }

    }

    async function previousDispatch() {
        try {
            const previousDispatch = await Api.get(`/dispatch/${id}/previous`, idToken)
            navigate(`/dispatch/${previousDispatch._id}`);
            didLoad(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Unable to get dispatch' }])
        }
    }

    const extractWaypoints = useCallback(async (data) => {

        async function getLatLngFromAddress(address) {
            try {
                const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
                const response = await fetch(url);

                if (!response.ok) {
                    throw new Error(`Geocoding request failed with status ${response.status}`);
                }

                const data = await response.json();

                if (data.results && data.results.length > 0) {
                    const location = data.results[0].geometry.location;
                    return {
                        latitude: location.lat,
                        longitude: location.lng,
                    };
                } else {
                    console.error('Geocoding request did not return valid data.');
                    return null;
                }
            } catch (error) {
                console.error('Error while fetching geocoding data:', error);
                return null;
            }
        }

        const waypoints = [];

        for (const item of data) {
            try {
                const address = `${item.location.address1} ${item.location.address2} ${item.location.city} ${item.location.state} ${item.location.zip}`;
                const { latitude, longitude } = await getLatLngFromAddress(address);
                const waypoint = {
                    latitude: latitude,
                    longitude: longitude,
                    name: item.location.name,
                };
                waypoints.push(waypoint);
            } catch (error) {
                continue;
            }
        }
        return waypoints;
    }, []);

    async function extractSamsaraHOSWaypoints(data) {
        const waypoints = [];

        for (const item of data) {
            try {
                const waypoint = {
                    latitude: item.logRecordedLocation.latitude,
                    longitude: item.logRecordedLocation.longitude,
                    name: item.hosStatusType,
                };
                waypoints.push(waypoint);
            } catch (error) {
                continue;
            }
        }
        return waypoints;
    }

    const [hosWaypointsData, setHosWaypointsData] = useState([]);



    useEffect(() => {
        const fetchWaypoints = async () => {
            if (!telematicsAudit) return;
            const data = await Promise.all(
                telematicsAudit.map(report => extractSamsaraHOSWaypoints(report?.hoslogs))
            );
            setHosWaypointsData(data);
        };

        fetchWaypoints();
    }, [telematicsAudit]);

    useEffect(() => {
        const fetchDispatch = async () => {
            try {
                const dispatch = await Api.get(`/dispatch/${id}`, idToken);
                setDispatch(dispatch);
                setStops(dispatch.operations ?? []);
                setShowStopContent(Array(dispatch.operations ?? []).fill(false));
                setCoverage(dispatch.coverage ?? []);
                setEvents(dispatch.events ?? []);
                setNotes(dispatch.notes ?? []);
                setRoutePlanWaypoints(await extractWaypoints(dispatch.routeplan.stops));
                setDispatchWaypoints(await extractWaypoints(dispatch.operations));

            } catch (error) {
                setAlerts(prevAlerts => [...prevAlerts, { variant: 'warning', message: 'Unable to get dispatch' }]);
            }

            didLoad(true);
        };

        const fetchTelematics = async () => {
            try {
                console.log('Fetching Telematics: ' + id)
                const telematicsReport = await Api.get(`/telematics/report/${id}`, idToken);
                if (telematicsReport) {
                    setTelematicsAudit(telematicsReport.telematics);
                    setSearchTolerance(telematicsReport.searchTolerance)
                    setAppointmentTolerance(telematicsReport.appointmentTolerance)
                    setGoodAudit(telematicsReport.goodaudit)
                    setPointOfFailure(telematicsReport.pointoffailure)
                } else {
                    setTelematicsAudit();
                    setSearchTolerance(1)
                    setAppointmentTolerance(1)
                    setGoodAudit()
                    setPointOfFailure()
                    const telematics = await Api.get(`/telematics/audit-dispatch/${id}/${searchTolerance}/${appointmentTolerance}`, idToken);
                    setTelematicsAudit(telematics);
                }
            } catch (error) {
                setTelematicsAudit([]);
                setAlerts(prevAlerts => [...prevAlerts, { variant: 'warning', message: 'Unable to get Telematics' }]);
            }

            didLoad(true);
        };

        const refetchTelematics = async () => {
            try {
                const telematics = await Api.get(`/telematics/audit-dispatch/${id}/${searchTolerance}/${appointmentTolerance}`, idToken);
                setTelematicsAudit(telematics);
            } catch (error) {
                setTelematicsAudit([]);
                setAlerts(prevAlerts => [...prevAlerts, { variant: 'warning', message: 'Unable to refetch Telematics' }]);
            }

            shouldRefetch(false);
        };

        if (!load) {
            fetchDispatch();
            fetchTelematics();
        }

        if (refetch) {
            refetchTelematics()
            shouldRefetch(false)
        }
    }, [load, refetch, id, idToken, searchTolerance, appointmentTolerance, setAlerts, extractWaypoints,]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLocations(await Api.get(`/locations/live`, idToken));
                setOperators(await Api.get(`/operators/live`, idToken));
            } catch (error) {
                setAlerts(prevAlerts => [...prevAlerts, { variant: 'warning', message: error.message }]);
            }

            didSet(true);
        };

        if (!set) {
            fetchData();
        }
    }, [set, idToken, setAlerts]);




    const CoverageDropDown = () => {
        const encounteredDomiciles = new Set();
        return (
            <Form.Select
                onChange={e => e.target.value !== "" && cover(JSON.parse(e.target.value))}
            >
                <option value="">Select</option>
                {ROUTEPLAN_COVERAGE_DEFAULTS.map((option, i) => (
                    <option key={i} value={JSON.stringify(option)}>
                        {option.name}
                    </option>
                ))}
                {operators &&
                    operators.map((operator) => {
                        // Check if the domicile has already been encountered
                        const domicileName = operator.domicile.name;
                        if (!domicileName) return null; // Skip operators without a domicile

                        // Render the disabled option only for the first occurrence of the domicile
                        if (!encounteredDomiciles.has(domicileName)) {
                            encounteredDomiciles.add(domicileName);
                            return (
                                <>
                                    <option key={domicileName} disabled>
                                        {domicileName}
                                    </option>
                                    <option key={operator._id} value={JSON.stringify(operator)}>
                                        {operator.firstname} {operator.lastname}{" "}
                                        {operator.provider && `- ${operator.provider} 📶 `}
                                    </option>
                                </>

                            );
                        }

                        return (
                            <option key={operator._id} value={JSON.stringify(operator)}>
                                {operator.firstname} {operator.lastname}{" "}
                                {operator.provider && `- ${operator.provider} 📶 `}
                            </option>
                        );
                    })}
            </Form.Select>
        )
    }

    const badgeStyle = {
        cursor: 'pointer',

    }

    return (
        <>
            <Container fluid>
                <AlertDisplay alertState={alertState} />
                <Card>
                    <Card.Header>
                        <Row>
                            <Col><b>Dispatch Update</b></Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Badge style={{ ...badgeStyle, marginLeft: '1em' }} bg={showDispatch ? 'danger' : 'primary'} onClick={() => setShowDispatch(!showDispatch)}> {showDispatch ? 'Hide' : 'Show'} </Badge>
                                <Badge style={{ ...badgeStyle, marginLeft: '1em' }} onClick={() => previousDispatch()}>Prev <FaArrowLeft /></Badge>
                                <Badge style={{ ...badgeStyle, marginLeft: '1em' }} onClick={() => nextDispatch()}><FaArrowRight /> Next</Badge>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Header>
                        <Row>
                            <Col sm={12} lg={4} style={{ textAlign: 'center' }}>
                                <b>Route :</b> {' '}
                                {(set && dispatch) && dispatch.routeplan && dispatch?.routeplan?.name && (
                                    <span>{dispatch?.routeplan?.name}</span>
                                )}
                            </Col>
                            <Col sm={12} lg={4} style={{ textAlign: 'center' }}>
                                <b>Service Date :</b> {dispatch?.servicedate}
                            </Col>
                            <Col sm={12} lg={4} style={{ textAlign: 'center' }}>
                                <b>ID :</b> {dispatch?._id}
                            </Col>
                        </Row>
                    </Card.Header>
                    {(showDispatch) &&
                        <>
                            {(set && dispatch) ?
                                <>
                                    <Card.Body>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                                            <div style={{ margin: '0.25em' }}> <b>Status</b> </div>
                                            {DISPATCH_STATUSES.map((status, i) => (
                                                dispatch.status === status.name ?
                                                    <div style={{ margin: '0.25em' }}>
                                                        <Badge key={i} bg={status.variant} style={badgeStyle} onClick={() => { updateStatus(status) }}>{status.name}</Badge>
                                                    </div>
                                                    :
                                                    <div style={{ opacity: 0.25, margin: '0.25em' }}>
                                                        <Badge key={i} bg={status.variant} style={badgeStyle} onClick={() => updateStatus(status)}>{status.name}</Badge>
                                                    </div>
                                            ))}
                                        </div>

                                        <Row>
                                            <Col>
                                                <Table style={{ border: '1px solid #ccc', borderCollapse: 'collapse' }}>
                                                    <thead>
                                                        <tr>
                                                            <th colSpan="100%" style={{ backgroundColor: '#eee' }}>
                                                                <Row>
                                                                    <Col>
                                                                        <b><FaUser /> Coverage</b>
                                                                    </Col>
                                                                    <Col style={{ textAlign: 'center' }}>
                                                                        <b>{dispatch?.coverage ? (dispatch.coverage).length : 0} / {dispatch?.crew === 'Team' ? 2 : 1}</b>
                                                                    </Col>
                                                                    <Col style={{ textAlign: 'right' }}>
                                                                        <Badge style={badgeStyle} onClick={() => updateCoverage()}>Update Coverage</Badge>
                                                                    </Col>
                                                                </Row>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan="100%" style={{ backgroundColor: '#eee' }}>
                                                                <CoverageDropDown />
                                                                {dispatch.crew === 'Team' &&
                                                                    <Col xs={2}>
                                                                        <Badge style={badgeStyle} onClick={(coverage.length - 1 >= 0)
                                                                            ? () => cover(coverage[coverage.length - 1])
                                                                            : () => setAlerts([...alerts, { variant: 'warning', message: 'You must select a driver.' }])
                                                                        }>+</Badge>
                                                                    </Col>
                                                                }
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>Operator</th>
                                                            <th>ELD Provider</th>
                                                            <th>Phone</th>
                                                            <th style={{ textAlign: 'right' }}>Remove</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dispatch.coverage && dispatch.coverage.map((operator, j) => (
                                                            <tr>
                                                                <td>
                                                                    {(ROUTEPLAN_COVERAGE_DEFAULTS.map(item => item.name)).includes(operator.name)
                                                                        ? `${operator.name}`
                                                                        : `${operator.firstname} ${operator.lastname}`
                                                                    }
                                                                </td>
                                                                <td>{operator.provider && `${operator.provider} 📶 `}</td>
                                                                <td>
                                                                    <a href={`tel:+${operator.phone}`}>Call - {operator.phone}</a>
                                                                </td>
                                                                <td style={{ textAlign: 'right' }}>
                                                                    <Badge bg="warning" onClick={() => removeCoverage(j)}>X</Badge>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                    {(dispatch.coverage && (dispatch.coverage).length > (dispatch.crew === 'Team' ? 2 : 1))
                                                        &&
                                                        <tfoot>
                                                            <tr>
                                                                <th colSpan={'100%'}>
                                                                    <Alert variant="warning">🚨 too many drivers</Alert>
                                                                </th>
                                                            </tr>
                                                        </tfoot>
                                                    }
                                                </Table>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} lg={6}>
                                                <Table style={{ border: '1px solid #ccc', borderCollapse: 'collapse' }}>
                                                    <thead>
                                                        <tr><th colspan={'100%'} style={{ backgroundColor: '#eee' }}><FaTruck /> Vehicles</th></tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr><td colspan={'100%'}>Todo...</td></tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <Table style={{ border: '1px solid #ccc', borderCollapse: 'collapse' }}>
                                                    <thead>
                                                        <tr><th colspan={'100%'} style={{ backgroundColor: '#eee' }}><FaTrailer /> Trailers</th></tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr><td colspan={'100%'}>Todo...</td></tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <pre>
                                                    <Table style={{ border: '1px solid #ccc', borderCollapse: 'collapse', marginBottom: '0px' }}>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={'100%'} style={{ backgroundColor: '#eee' }}>
                                                                    <Row>
                                                                        <Col>
                                                                            <FaList /> Operations
                                                                        </Col>
                                                                        <Col style={{ textAlign: 'right' }}>
                                                                            <Badge
                                                                                bg="secondary"
                                                                                onClick={() => setStops([...stops, {
                                                                                    timezone: settings.timezone,
                                                                                    trip: '',
                                                                                    location: '',
                                                                                    arrive: '',
                                                                                    action: '',
                                                                                    depart: '',
                                                                                }])}
                                                                            >Add Stop</Badge>
                                                                            <Badge
                                                                                style={{ marginLeft: '0.5em' }}
                                                                                bg="primary"
                                                                                onClick={() => updateStops()}
                                                                            >Update Ops</Badge>
                                                                        </Col>
                                                                    </Row>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th>{'#'}</th>
                                                                <th>{'Trip'}</th>
                                                                <th>{'Location'}</th>
                                                                <th>{'Timezone'}</th>
                                                                <th>{'Arrive'}</th>
                                                                <th>{'Action '}</th>
                                                                <th>{'Depart'}</th>
                                                                <th>{'Up'}</th>
                                                                <th>{'Down'}</th>
                                                                <th style={{ textAlign: 'right' }}>{'Delete'}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {stops.length > 0 ? stops.map((stop, i) => (
                                                                <>
                                                                    <tr>
                                                                        <td onClick={() => updateShowStopContent(i)}><Badge>{i + 1}{showStopContent[i] ? <FaCaretDown /> : <FaCaretRight />}</Badge></td>
                                                                        <td onClick={() => updateShowStopContent(i)}>{stop.trip}</td>
                                                                        <td onClick={() => updateShowStopContent(i)}>
                                                                            {stop.location &&
                                                                                <>
                                                                                    {stop.location.name} <br />
                                                                                    {stop.location.address1} {stop.location.address2} <br />
                                                                                    {stop.location.city} {stop.location.state} {stop.location.zipcode}
                                                                                </>
                                                                            }
                                                                        </td>
                                                                        <td onClick={() => updateShowStopContent(i)}>{getTimezoneCode(stop.timezone)}</td>
                                                                        <td onClick={() => updateShowStopContent(i)}>{stop.arrive}</td>
                                                                        <td onClick={() => updateShowStopContent(i)}>{stop.action}</td>
                                                                        <td onClick={() => updateShowStopContent(i)}>{stop.depart}</td>
                                                                        <td>
                                                                            <Badge bg="secondary" onClick={() => moveStopUp(i)}>
                                                                                <FaArrowUp />
                                                                            </Badge>
                                                                        </td>
                                                                        <td>
                                                                            <Badge bg="secondary" onClick={() => moveStopDown(i)}>
                                                                                <FaArrowDown />
                                                                            </Badge>
                                                                        </td>
                                                                        <td>
                                                                            <Badge bg="warning" onClick={() => removeStop(i)} style={{ float: 'right' }}>
                                                                                <FaTrash />
                                                                            </Badge>
                                                                        </td>
                                                                    </tr>
                                                                    {showStopContent[i] &&
                                                                        <tr style={{ borderTop: '0px transparent', borderBottom: '2px solid #333' }}>
                                                                            <td colspan="100%" style={{ backgroundColor: 'rgba(13, 110, 253, 0.75)' }}>
                                                                                <Card>
                                                                                    <Card.Header>
                                                                                        Stop {i + 1} Update
                                                                                    </Card.Header>
                                                                                    <Card.Body>
                                                                                        <Row>
                                                                                            <Col xs={12} md={4}>
                                                                                                Trip/Note
                                                                                                <Form.Control type="text" value={stop.trip} onChange={e => setStop(i, 'trip', e.target.value)} />
                                                                                            </Col>
                                                                                            <Col xs={12} md={4}>
                                                                                                Location
                                                                                                <Form.Select value={JSON.stringify(stop.location)} onChange={e => e.target.value !== '' && setStop(i, 'location', JSON.parse(e.target.value))}>
                                                                                                    <option value="">Select</option>
                                                                                                    {locations && locations.map(location => (
                                                                                                        <option key={location._id} value={JSON.stringify(location)}>{location.name} - {location.provider}</option>
                                                                                                    ))}
                                                                                                </Form.Select>
                                                                                            </Col>
                                                                                            <Col xs={12} md={4}>
                                                                                                Timezone
                                                                                                <Form.Select value={stop.timezone} defaultValue={settings.timezone} onChange={e => e.target.value !== '' && setStop(i, 'timezone', e.target.value)} >
                                                                                                    <option value="">Select</option>
                                                                                                    {TIMEZONE_OPTIONS.map((tz, i) => (
                                                                                                        <option key={i} value={tz.name}>{tz.code}</option>
                                                                                                    ))}
                                                                                                </Form.Select>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <br />
                                                                                        <Row>
                                                                                            <Col xs={12} md={4}>
                                                                                                Manual Arrival
                                                                                                <Form.Control style={{ backgroundColor: '#fff5bd' }} value={stop.arrival} defaultValue={'--:--'} type="time" onChange={e => setStop(i, 'arrival', e.target.value)} />
                                                                                            </Col>
                                                                                            <Col xs={12} md={4}>
                                                                                                Scheduled Arrive
                                                                                                <Form.Control value={stop.arrive} type="time" onChange={e => setStop(i, 'arrive', e.target.value)} />
                                                                                            </Col>
                                                                                            <Col xs={12} md={4}>
                                                                                                GPS Arrive
                                                                                                <Form.Control value={stop.gpsarrive} defaultValue={'--:--'} type="time" readOnly disabled />
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <br />
                                                                                        <Row>
                                                                                            <Col xs={12} md={4}>
                                                                                                Action
                                                                                                <Form.Select
                                                                                                    style={{ backgroundColor: '#fff5bd' }}
                                                                                                    value={stop.action}
                                                                                                    onChange={e => e.target.value !== '' && setStop(i, 'action', e.target.value)}
                                                                                                >
                                                                                                    <option value="">Select</option>
                                                                                                    {ROUTE_ACTION.map((ra, i) => (
                                                                                                        <option value={ra.name}>{ra.name} - {ra.detail}</option>
                                                                                                    ))}
                                                                                                </Form.Select>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <br />
                                                                                        <Row>
                                                                                            <Col xs={12} md={4}>
                                                                                                Manual Departure
                                                                                                <Form.Control style={{ backgroundColor: '#fff5bd' }} value={stop.departure} defaultValue={'--:--'} type="time" onChange={e => setStop(i, 'departure', e.target.value)} />
                                                                                            </Col>
                                                                                            <Col xs={12} md={4}>
                                                                                                Scheduled Depart
                                                                                                <Form.Control value={stop.depart} type="time" onChange={e => setStop(i, 'depart', e.target.value)} />
                                                                                            </Col>
                                                                                            <Col xs={12} md={4}>
                                                                                                GPS Departure
                                                                                                <Form.Control value={stop.gpsdepart} defaultValue={'--:--'} type="time" readOnly disabled />
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Card.Body>

                                                                                </Card>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                </>
                                                            )) :
                                                                <tr>
                                                                    <td colspan="100%">
                                                                        Add stops.
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </tbody >
                                                        <tfoot>
                                                            <tr>
                                                                <th colSpan={2}></th>
                                                                <th colSpan={2}></th>
                                                                <th colSpan={2}>SOP : {calculateRouteHours(stops)}</th>
                                                                <th colSpan={2}>Rate: {dispatch.routeplan.payrateperhour}</th>
                                                                <th colSpan={2}>Pay Est: {dispatch.routeplan.payrateperhour * calculateRouteHours(stops)} </th>
                                                            </tr>
                                                        </tfoot>
                                                    </Table>
                                                </pre>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Table style={{ border: '1px solid #ccc', borderCollapse: 'collapse' }}>
                                                    <thead>
                                                        <tr>
                                                            <th colspan={'100%'} style={{ backgroundColor: '#eee' }}><FaStickyNote /> Notes
                                                                <Badge style={{ float: 'right' }} onClick={() => createNote()}>Create Note</Badge>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th colspan={'100%'} style={{ backgroundColor: '#eee' }}>
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Control
                                                                            value={note.note}
                                                                            as="textarea"
                                                                            placeholder={'Write a note...'}
                                                                            rows={1}
                                                                            onChange={(e) => setNote({ ...note, note: e.target.value })}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>Time</th>
                                                            <th>User</th>
                                                            <th>Note</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {notes.length > 0 ? notes.map((note, i) => (
                                                            <tr key={i}>
                                                                <td>{parseUtcToStr(note.createdUtcTimestamp, settings.timezone)}</td>
                                                                <td>{note.email}</td>
                                                                <td>{note.note}</td>
                                                            </tr>
                                                        )) :
                                                            <tr><td colspan={'100%'}>No notes</td></tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Table style={{ border: '1px solid #ccc', borderCollapse: 'collapse' }}>
                                                    <thead>
                                                        <tr><th colspan={'100%'} style={{ backgroundColor: '#eee' }}><FaStopwatch /> Events</th></tr>
                                                        <tr>
                                                            <th>Time</th>
                                                            <th>User</th>
                                                            <th>Type</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {events.length > 0 ? events.map((event, i) => (
                                                            <tr key={i}>
                                                                <td>{parseUtcToStr(event.createdUtcTimestamp, settings.timezone)}</td>
                                                                <td>{event.email}</td>
                                                                <td>{event.type}</td>
                                                            </tr>
                                                        )) :
                                                            <tr><td colspan={'100%'}>No events</td></tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </>
                                :
                                <Card.Body>
                                    <Spinner />
                                </Card.Body>
                            }
                        </>
                    }
                </Card>
                <br />
                <Card>
                    <Card.Header>
                        <Row>
                            <Col>
                                <b><FaSignal /> Telematics</b>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Badge style={{ ...badgeStyle, marginLeft: '1em' }} bg={showTelematics ? 'danger' : 'primary'} onClick={() => setShowTelematics(!showTelematics)}> {showTelematics ? 'Hide' : 'Show'} </Badge>
                            </Col>
                        </Row>
                    </Card.Header>
                    {showTelematics &&
                        <Card.Body>
                            {load && telematicsAudit ?
                                telematicsAudit.length > 0 ?
                                    <>
                                        {telematicsAudit.map((report, i) => {
                                            const hosWaypoints = hosWaypointsData[i];
                                            return (
                                                <Row key={i}>
                                                    <Col sm={12} >
                                                        <pre>
                                                            <Row>
                                                                <Col sm={2}  >
                                                                    Search Tolerance
                                                                    <Form.Control
                                                                        type="number"
                                                                        value={searchTolerance}
                                                                        onChange={e => { setSearchTolerance(e.target.value); shouldRefetch(true) }}
                                                                        step={1}
                                                                        min={1}
                                                                        max={8}
                                                                    />
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <div>
                                                                        Appointment Tolerance
                                                                        <Form.Control
                                                                            type="number"
                                                                            value={appointmentTolerance}
                                                                            onChange={e => { setAppointmentTolerance(e.target.value); shouldRefetch(true) }}
                                                                            step={0.25}
                                                                            min={0.25}
                                                                            max={5}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Table style={{ border: '1px solid #ccc' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ backgroundColor: '#eee' }}>Trip</th>
                                                                        <th style={{ backgroundColor: '#eee' }}>Location</th>
                                                                        <th style={{ backgroundColor: '#eee' }}>On Premise Pings</th>
                                                                        <th style={{ backgroundColor: '#eee' }}>Provider</th>
                                                                        <th style={{ backgroundColor: '#eee' }}>Sched. Arrive</th>
                                                                        <th style={{ backgroundColor: '#eee' }}>GPS Arrival</th>
                                                                        <th style={{ backgroundColor: '#eee' }}>+/-</th>
                                                                        <th style={{ backgroundColor: '#eee' }}>Sched. Depart</th>
                                                                        <th style={{ backgroundColor: '#eee' }}>GPS Departure</th>
                                                                        <th style={{ backgroundColor: '#eee' }}>+/-</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {report?.linearDispatch.map((via, j) => (
                                                                        <tr key={`${i}.${j}`}>
                                                                            <td>{via?.trip}</td>
                                                                            <td>{via?.location.name}</td>
                                                                            <td>{via?.eldOnPremiseCt}</td>
                                                                            <td>{via?.location.provider}</td>
                                                                            <td>{formatTime(via?.arriveTime, via?.timezone)}</td>
                                                                            <td style={{ backgroundColor: '#eee' }}>{via?.eldOnPremiseDataStart?.time && formatTime(via?.eldOnPremiseDataStart?.time, via?.timezone)}</td>
                                                                            <td style={
                                                                                via?.eldOnPremiseArriveDiffMin > 0
                                                                                    ? { backgroundColor: '#bceebc' }
                                                                                    : { backgroundColor: '#f39c9c' }
                                                                            }>{via?.eldOnPremiseArriveDiffMin}</td>
                                                                            <td>{formatTime(via?.departTime, via?.timezone)}</td>
                                                                            <td style={{ backgroundColor: '#efefef' }}>{via?.eldOnPremiseDataEnd?.time && formatTime(via?.eldOnPremiseDataEnd?.time, via?.timezone)}</td>
                                                                            <td style={
                                                                                via?.eldOnPremiseDepartDiffMin > 0
                                                                                    ? { backgroundColor: '#bceebc' }
                                                                                    : { backgroundColor: '#f39c9c' }
                                                                            }>{via?.eldOnPremiseDepartDiffMin}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        </pre>
                                                    </Col>
                                                    <Col lg={12} xl={6}>
                                                        <pre>
                                                            <Table style={{ border: '1px solid #ccc' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ backgroundColor: '#eee' }}>
                                                                            <Badge bg="secondary" style={badgeStyle} onClick={() => shouldRefetch(true)}>Fetch Fresh Telematics</Badge>
                                                                            <Badge bg="primary" style={badgeStyle} onClick={() => updateTelematicsReport()}>Update Telematics</Badge>
                                                                        </th>
                                                                        <th style={{ textAlign: 'center', backgroundColor: '#eee' }}>
                                                                            <Badge
                                                                                bg={(goodaudit === 'PASS' || goodaudit === null) ? 'success' : 'danger'}
                                                                                style={{ ...badgeStyle, float: 'right' }}
                                                                                onClick={() => setGoodAudit(goodaudit === 'PASS' ? 'FAIL' : 'PASS')}>
                                                                                {goodaudit === 'PASS' ? 'PASS' : 'FAIL'}
                                                                            </Badge>
                                                                        </th>
                                                                    </tr>
                                                                    {goodaudit === 'FAIL' &&
                                                                        <tr>
                                                                            <th style={{ backgroundColor: '#eee' }}>POINT OF FAILURE</th>
                                                                            <td style={{ backgroundColor: '#eee' }}>
                                                                                <Form.Control
                                                                                    as="textarea"
                                                                                    value={pointoffailure}
                                                                                    onChange={e => setPointOfFailure(e.target.value)}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                </thead>
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan={2} style={{ textAlign: 'center', backgroundColor: '#eee' }}>
                                                                            Audit : {` ${goodaudit ? goodaudit : 'UNGRADED'} `}
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Driver</th>
                                                                        <td>{report?.operator?.name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Route</th>
                                                                        <td>{report.routename}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Date</th>
                                                                        <td>{report.servicedate}</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th>Route Plan Stops</th>
                                                                        <td>{report.routeplanStopCt}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Route Timelapse</th>
                                                                        <td>{report.routeplanTimelapse.hours} hr {report.routeplanTimelapse.minutes} min</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Dispatch Stops</th>
                                                                        <td>{report.dispatchStopCt}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Dispatch Timelapse</th>
                                                                        <td>{report.dispatchTimelapse.hours} hr {report.dispatchTimelapse.minutes} min</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Route-Dispatch Percent Match</th>
                                                                        <td>{(report.routePercentMatch * 100).toFixed(1)} %</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th colSpan={2} style={{ textAlign: 'center', backgroundColor: '#eee' }}> Samsara Telematics</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Search Tolerance </th>
                                                                        <td>
                                                                            {searchTolerance}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Appointment Tolerance</th>
                                                                        <td>
                                                                            {appointmentTolerance}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Search Start</th>
                                                                        <td>{report.hoslogSearchStart}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Search End</th>
                                                                        <td>{report.hoslogSearchEnd}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Total Search Time</th>
                                                                        <td>{convertMillisecondsToHoursMinutes(report?.hoslogTotalTime)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Vehicles</th>
                                                                        <td>
                                                                            {report?.hoslogVehicleData?.data
                                                                                ? report?.hoslogVehicleData?.data.map(d => d.name).join(', ')
                                                                                : 'No vehicles'
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Work Start</th>
                                                                        <td>{report.hoslogWorkStart}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Work End</th>
                                                                        <td>{report.hoslogWorkEnd}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Duty Status</th>
                                                                        <td>
                                                                            {Object.keys(report.hoslogStatusTotals).map((key, i) => (
                                                                                <>
                                                                                    {key} : {convertMillisecondsToHoursMinutes(report.hoslogStatusTotals[key])}<br />
                                                                                </>
                                                                            ))}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Time Worked</th>
                                                                        <td>{convertMillisecondsToHoursMinutes(report?.hoslogTimeWorked)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Dispatch-Logs Percent Match</th>
                                                                        <td>{report.loggedScore}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>GRADE</th>
                                                                        <td>{` ${goodaudit ? goodaudit : 'UNGRADED'} `}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </pre>
                                                    </Col>
                                                    <Col lg={12} xl={6}>
                                                        <Card>
                                                            <Card.Header>
                                                                HOS Log
                                                            </Card.Header>
                                                            <DriverHOSLog hoslogs={report.hoslogs} />
                                                        </Card>
                                                        <br />
                                                        <Card>
                                                            <Card.Header>
                                                                <Badge style={badgeStyle} onClick={() => setShowGoogleMaps(!showGoogleMaps)}>
                                                                    {!showGoogleMaps ? 'Show' : 'Hide'} Maps
                                                                </Badge>
                                                            </Card.Header>
                                                            {showGoogleMaps &&
                                                                (set && dispatch && telematicsAudit) &&
                                                                <Card.Body>
                                                                    <pre>
                                                                        <GoogleMapPlotRoute
                                                                            mapCenterLatitude={routeplanWaypoints[0].lat}
                                                                            mapCenterLongitude={routeplanWaypoints[0].lng}
                                                                            waypoints1={routeplanWaypoints}
                                                                            waypoints2={dispatchWaypoints}
                                                                            waypoints3={hosWaypoints}
                                                                        />
                                                                    </pre>

                                                                </Card.Body>
                                                            }
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )

                                        }
                                        )}
                                    </>
                                    : 'No telematics data.'
                                :
                                <Row style={{ alignItems: 'center', }}>
                                    <Spinner /> Checking for telematic connections.
                                </Row>
                            }
                        </Card.Body>
                    }
                </Card>
                {(set && dispatch) &&
                    <>
                        <br />
                        <DispatchRoutePlanDetail routeplan={dispatch.routeplan} />
                    </>
                }
                <br />
                {(set && dispatch) &&
                    <Card>
                        <Card.Header style={dispatch.isActive ? { backgroundColor: '#f8665f' } : { backgroundColor: '#c5e0bb' }}>
                            <Row>
                                <Col>{dispatch.isActive ? 'Deactivate this dispatch' : 'Activate this dispatch'}</Col>
                                <Col style={{ textAlign: 'right' }}>
                                    {dispatch.isActive ?
                                        <Badge
                                            style={{ ...badgeStyle, marginLeft: '1em' }}
                                            bg={showDangerous ? 'primary' : 'danger'}
                                            onClick={() => setShowDangerous(!showDangerous)}>
                                            {showDangerous ? 'Hide' : 'Show'}
                                        </Badge>
                                        :
                                        <Badge
                                            style={{ ...badgeStyle, marginLeft: '1em' }}
                                            bg={showRevive ? 'primary' : 'success'}
                                            onClick={() => setShowRevive(!showRevive)}>
                                            {showRevive ? 'Hide' : 'Show'}
                                        </Badge>
                                    }
                                </Col>
                            </Row>
                        </Card.Header>
                        <ConfirmationModal
                            show={showReviveConfirmation}
                            onHide={() => setShowReviveConfirmation(false)}
                            onConfirm={() => handleDispatchActivate()}
                            message={`Are you sure you want to revive this dispatch?`}
                        />
                        {showRevive &&
                            <Card.Body>
                                <Row>
                                    <b>
                                        When you click "Activate this Dispatch" the dispatch will be include in active dispatch views and will be included in dispatch reports.
                                        Use this feature if you accidently removed a dispatch.
                                        <br />
                                        No longer providing service,
                                        generate dispatch by mistake, or other reason you need to remove the dispatch.
                                        <br />

                                    </b>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <Button variant="success" onClick={() => setShowReviveConfirmation(true)}>Activate this Dispatch</Button>
                                    </Col>
                                </Row>
                                <br />
                                This action is reversable.
                            </Card.Body>
                        }
                        <ConfirmationModal
                            show={showConfirmation}
                            onHide={() => setShowConfirmation(false)}
                            onConfirm={() => handleDispatchDectivate()}
                            message={`Are you sure you want to deactivate this dispatch?`}
                        />
                        {showDangerous &&
                            <Card.Body>
                                <Row>
                                    <p>
                                        When you click "Deactive this Dispatch" the dispatch will be excluded from active dispatch views and will be excluded from some reports.
                                        Use this feature if you need to remove a record from dispatch.
                                    </p>
                                    <p>
                                        Examples:
                                    </p>
                                    <p>
                                        No longer providing service, <br />
                                        generate dispatch by mistake, <br />
                                        change of plans, <br />
                                        or any other reason you need to remove a dispatch.
                                    </p>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <Button variant="danger" onClick={() => setShowConfirmation(true)}>Deactivate this Dispatch</Button>
                                    </Col>
                                </Row>
                                <br />
                                This action is reversable.
                            </Card.Body>
                        }
                    </Card>
                }
                <br />
            </Container>
        </>
    );
}

export default DispatchUpdate;