import { Container, Card, Row, Col, Form, Button, Table } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import { FaTrash } from "react-icons/fa";
import { LICENSE_TYPES, CONTACT_SERVICE_TYPES, CONTACT_PAYRATES_TYPES } from "../../context/appdata"; 
import AlertDisplay from "../../components/AlertDisplay";
const initialFormState = {
    name: '',
    assignment: '',
    domiciles: [],
    effective: '',
    lastday: '',
    payrate: [],
    contacts: [],
}

function ContractForm() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [assignments, setAssignments] = useState();
    const [domiciles, setDomiciles] = useState();
    const [formdata, setFormdata] = useState(initialFormState)
    const [alerts, setAlerts] = useState([]);
    const alertState = {alerts, setAlerts}
    const [payrates, setPayrates] = useState([]);
    
    async function handlePost() {
        try {
            await Api.post('/contracts', formdata, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success' }])
            setFormdata(initialFormState)
            setPayrates([])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Unable to create' }])
        }
    }

    function setPayRate(i, k, v) {
        payrates[i][k] = v
        setFormdata({ ...formdata, payrates: [...payrates] })
    }

    function removePayRate(index) {
        const filtered = payrates.filter((_, i) => {
            return i !== index
        })
        setPayrates(filtered)
        setFormdata({ ...formdata, payrates: filtered })
    }

    function setFormDomicile(domicile) {
        const formDomiciles = formdata.domiciles
        formDomiciles.push(domicile)
        setFormdata({ ...formdata, domiciles: formDomiciles })
    }

    function removeDomicile(index) {
        const filteredDomiciles = formdata.domiciles.filter((_, i) => {
            return i !== index
        })
        setFormdata({ ...formdata, domiciles: filteredDomiciles })
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Api.get('/assignments/live', idToken)
                setAssignments(data)
                const domiciles = await Api.get('/locations/live/Domicile', idToken)
                setDomiciles(domiciles)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    },[set,idToken,alerts,])

    return (
        <Container fluid>
            <Card>
                <Card.Header><b>Contract Form</b></Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                <Card.Body>
                    <Row>
                        <Col>
                            Name/Number
                            <Form.Control type="text" value={formdata.name} onChange={e => setFormdata({ ...formdata, name: e.target.value })} />
                        </Col>
                        <Col>
                            Assignment
                            <Form.Select
                                value={JSON.stringify(formdata.assignment)}
                                onChange={e => setFormdata({ ...formdata, assignment: JSON.parse(e.target.value) })}>
                                <option value="">Select</option>
                                {assignments && assignments.map(assignment => (
                                    <option key={assignment._id} value={JSON.stringify(assignment)}>{assignment.name}</option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col>
                            Effective Date
                            <Form.Control type="date" value={formdata.effective} onChange={e => setFormdata({ ...formdata, effective: e.target.value })} />
                        </Col>
                        <Col>
                            Last Day of Operation
                            <Form.Control type="date" value={formdata.lastday} onChange={e => setFormdata({ ...formdata, lastday: e.target.value })} />
                        </Col>
                    </Row>
                    <br />
                    <Card.Header>
                        <Row>
                            <Col>
                                <Card.Title>Domicile Locations</Card.Title>
                            </Col>
                            <Col>
                                <Form.Select value={''} aria-label="Default select"
                                    onChange={e => e.target.value !== "" && setFormDomicile(JSON.parse(e.target.value))}>
                                    <option value="">Select</option>
                                    {domiciles && domiciles.map(domicile => (
                                        <option key={domicile._id} value={JSON.stringify(domicile)}>{domicile.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        {(formdata.domiciles.length > 0) ? formdata.domiciles.map((domicile, i) => (
                            <Card key={i}>
                                <Card.Header>
                                    <Row>
                                        <Col>{domicile.name}</Col>
                                        <Col>
                                            <Button style={{ float: 'right' }} variant="warning" onClick={() => removeDomicile(i)}>
                                                <FaTrash />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    {domicile.address1} {domicile.address2} <br />
                                    {domicile.city} {domicile.state} {domicile.zipcode}
                                </Card.Body>
                            </Card>
                        )) :
                            <>
                                <>Add domiciles.</>
                            </>
                        }
                    </Card.Body>
                    <br />
                    <Card.Header>
                        <Card.Title>
                            Pay Rates
                        </Card.Title>
                    </Card.Header>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Class</th>
                                <th>Type</th>
                                <th>Rule</th>
                                <th>Amount</th>
                                <th>Effective Date</th>
                                <th>
                                    <Button variant="primary" className="float-end" onClick={() => setPayrates([...payrates, {}])}>Add Pay Rate</Button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(payrates && payrates.length > 0) ? payrates.map((payrate, i) => (
                                <tr>
                                    <td>
                                        <Form.Select value={payrate.class} onChange={(e) => setPayRate(i, 'class', e.target.value)}>
                                            <option value="">Select</option>
                                            <option>Class A</option>
                                            <option>Class B</option>
                                            <option>Class C</option>
                                        </Form.Select>
                                    </td>
                                    <td>
                                        <Form.Select value={payrate.type} onChange={(e) => setPayRate(i, 'type', e.target.value)}>
                                            <option value="">Select</option>
                                            <option>Wage Determinination</option>
                                            <option>Health and Welfare</option>
                                            <option>Bonus</option>
                                        </Form.Select>
                                    </td>
                                    <td>
                                        <Form.Select value={payrate.rule} onChange={(e) => setPayRate(i, 'rule', e.target.value)}>
                                            <option value="">Select</option>
                                            <option>All Hours</option>
                                            <option>First 40 Hours</option>
                                            <option>Over 40 Hours</option>
                                        </Form.Select>
                                    </td>
                                    <td><Form.Control type="number" value={payrate.amount} step=".01" onChange={(e) => setPayRate(i, 'amount', e.target.value)} /></td>
                                    <td><Form.Control type="date" value={payrate.effective} onChange={(e) => setPayRate(i, 'effective', e.target.value)} /></td>
                                    <td>
                                        <Button variant="warning" onClick={() => removePayRate(i)}>
                                            <FaTrash />
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                                <tr>
                                    <td colSpan={6}>Add a rate.</td>
                                </tr>
                            }
                        </tbody>
                    </Table>

                    <br />
                    <Button variant="primary" onClick={() => handlePost()}>Create</Button>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default ContractForm;