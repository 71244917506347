import React from "react";
import { Container, Card,} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

function TravelRequestForm() {

    return (
        <Container fluid>
            <Card>
                <Card.Header><b>Travel Request Form</b></Card.Header>
                <Card.Body>
   
                </Card.Body>
            </Card>
        </Container>
    );
}

export default TravelRequestForm;