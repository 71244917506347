import { Container, Card, Row, Col, Form, Button, Alert } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from "react";
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import { LOCATION_CATEGORIES } from "../../context/appdata";
import AlertDisplay from "../../components/AlertDisplay";
import GeofencePlotter from "../../components/GeofencePlotter";

const initialFormState = {
    type: '',
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
    latitude: '-',
    longitude: '-',
    proximity: '-'
}

function LocationForm() {
    const { idToken } = useAuth();

    const [geofences, setGeofences] = useState([]);
    const geofenceState = { geofences, setGeofences };

    const [circleGeofence, setCircleGeofence] = useState();
    const circleGeofenceState = { circleGeofence, setCircleGeofence };

    const [formdata, setFormdata] = useState(initialFormState)
    const [alerts, setAlerts] = useState([]);

    async function handlePost() {
        try {
            await Api.post('/locations', { ...formdata, geofences, circleGeofence }, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success' }]);
            setFormdata(initialFormState)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Create error' }]);
        }
    }

    return (
        <Container fluid>
            <Card>
                <Card.Header><Card.Title>Location Form</Card.Title></Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={{ alerts, setAlerts }} />
                }
                <Card.Body>
                    <Row>
                        <Col sm={6} md={4}>
                            Name
                            <Form.Control type="text"
                                value={formdata.name}
                                onChange={e => setFormdata({ ...formdata, name: e.target.value })}
                            />
                        </Col>
                        <Col sm={6} md={4}>
                            Type
                            <Form.Select value={formdata.type} onChange={e => setFormdata({ ...formdata, type: e.target.value })} >
                                <option>Select</option>
                                {LOCATION_CATEGORIES.map((category, i) => (
                                    <option key={i} value={category.name}>{category.name} - {category.detail}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            Address (Line 1)
                            <Form.Control type="text" value={formdata.address1} onChange={e => setFormdata({ ...formdata, address1: e.target.value })} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Address (Line 2)
                            <Form.Control type="text" value={formdata.address2} onChange={e => setFormdata({ ...formdata, address2: e.target.value })} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} lg={4}>
                            City
                            <Form.Control type="text" value={formdata.city} onChange={e => setFormdata({ ...formdata, city: e.target.value })} />
                        </Col>
                        <Col sm={12} lg={4}>
                            State
                            <Form.Control type="text" value={formdata.state} onChange={e => setFormdata({ ...formdata, state: e.target.value })} />
                        </Col>
                        <Col sm={12} lg={4}>
                            Zipcode
                            <Form.Control type="text" value={formdata.zipcode} onChange={e => setFormdata({ ...formdata, zipcode: e.target.value })} />
                        </Col>
                    </Row>
                    <br />
                    {/* <Row>
                        <Col sm={12} lg={4}>
                            Latitude
                            <Form.Control type="text" value={formdata.latitude} onChange={e => setFormdata({ ...formdata, latitude: e.target.value })}/>
                        </Col>
                        <Col sm={12} lg={4}>
                            Longitude
                            <Form.Control type="text" value={formdata.longitude} onChange={e => setFormdata({ ...formdata, longitude: e.target.value })} />
                        </Col>
                        <Col sm={12} lg={4}>
                            Proximity
                            <Form.Control type="number" step="1" value={formdata.proximity} onChange={e => setFormdata({ ...formdata, proximity: e.target.value })} />
                        </Col>
                    </Row> */}
                    <GeofencePlotter
                        geofenceState={geofenceState}
                        circleGeofenceState={circleGeofenceState}
                    />
                    <br />
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={() => handlePost()}>Create</Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default LocationForm;