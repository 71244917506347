import { Container, Card, Row, Col, Form, Button, Spinner, Alert, Tooltip, OverlayTrigger } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import Api from "../../utils/Api";
import { useParams } from 'react-router-dom';
import { FaInfoCircle } from "react-icons/fa";
import { useAuth } from '../../context/auth';
import { LOCATION_CATEGORIES } from "../../context/appdata";
import AlertDisplay from "../../components/AlertDisplay";
import GeofencePlotter from "../../components/GeofencePlotter";

function LocationUpdate() {

    const { id } = useParams();
    const { idToken } = useAuth();

    const [set, didSet] = useState();

    const [location, setLocation] = useState({});
    const [contracts, setContracts] = useState();

    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    const [geofences, setGeofences] = useState([]);
    const geofenceState = { geofences, setGeofences };

    const [circleGeofence, setCircleGeofence] = useState([]);
    const circleGeofenceState = { circleGeofence, setCircleGeofence };

    async function handleUpdate() {
        try {
            await Api.patch(`/location/${id}`, { ...location, geofences, circleGeofence }, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Updated' }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Update error' }])
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Api.get(`/location/${id}`, idToken)
                console.log(data)
                
                if (data?.geofences) {
                    setGeofences(data?.geofences)
                }
                if (data?.circleGeofence) {
                    setCircleGeofence(data?.circleGeofence)
                }

                setLocation(data)

                const contracts = await Api.get(`/contracts/live`, idToken)
                setContracts(contracts)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }


            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [set, idToken, alerts, id])

    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <b>Location Update</b>
                </Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {set ?
                    <Card.Body>
                        <Row>
                            <Col>
                                ID : {location._id}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm={6} md={4}>
                                Name
                                <Form.Control type="text" value={location.name} onChange={e => setLocation({ ...location, name: e.target.value })} disabled={location?.provider ? true : false} />
                            </Col>
                            <Col sm={6} md={4}>
                                Type
                                <Form.Select value={location.type} onChange={e => setLocation({ ...location, type: e.target.value })}>
                                    <option>Select</option>
                                    {LOCATION_CATEGORIES.map((cat, i) => (
                                        <option key={i} value={cat.name}>{cat.name} - {cat.detail}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            {location?.provider &&
                                <Col sm={6} md={4}>
                                    Provider
                                    <Form.Control type="text" value={location.provider} disabled={true} />
                                </Col>
                            }
                            {/* {location.type === 'Service Point' &&
                                <Col sm={6} md={4}>
                                    Contract Assignment
                                    <Form.Select value={location.assignedto} onChange={e => setLocation({ ...location, assignedto: e.target.value })} >
                                        <option>Select</option>
                                        {contracts.map(contract => (
                                            <option>{contract.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            } */}
                        </Row>
                        <Row lg={12}>
                            <Col>
                                Address (Line 1)
                                <Form.Control type="text" value={location.address1} onChange={e => setLocation({ ...location, address1: e.target.value })} disabled={location?.provider ? true : false} />
                            </Col>
                        </Row>
                        <Row lg={12}>
                            <Col>
                                Address (Line 2)
                                <Form.Control type="text" value={location.address2} onChange={e => setLocation({ ...location, address2: e.target.value })} disabled={location?.provider ? true : false} />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} md={4}>
                                City
                                <Form.Control type="text" value={location.city} onChange={e => setLocation({ ...location, city: e.target.value })} disabled={location?.provider ? true : false} />
                            </Col>
                            <Col sm={6} md={4}>
                                State
                                <Form.Control type="text" value={location.state} onChange={e => setLocation({ ...location, state: e.target.value })} disabled={location?.provider ? true : false} />
                            </Col>
                            <Col sm={6} md={4}>
                                Zip-code
                                <Form.Control type="text" value={location.zipcode} onChange={e => setLocation({ ...location, zipcode: e.target.value })} disabled={location?.provider ? true : false} />
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col sm={6} md={4} lg={4}>
                                Latitude
                                <Form.Control type="text" value={location.latitude} onChange={e => setLocation({ ...location, latitude: e.target.value })} disabled={location?.provider ? true : false} />
                            </Col>
                            <Col sm={6} md={4} lg={4}>
                                Longitude
                                <Form.Control type="text" value={location.longitude} onChange={e => setLocation({ ...location, longitude: e.target.value })} disabled={location?.provider ? true : false} />
                            </Col>
                            <Col sm={6} md={4} lg={4}>
                                Proximity
                                <Form.Control type="number" step="1" value={location.proximity} onChange={e => setLocation({ ...location, proximity: e.target.value })} disabled={location?.provider ? true : false} />
                            </Col>
                        </Row> */}
                        {location?.provider &&
                            <Row>
                                <Col>
                                    Geofence:
                                    <pre>
                                        {JSON.stringify(location.geofence, "", 2)}
                                    </pre>
                                </Col>
                            </Row>
                        }
                        <br />
                        <Row>
                            <Col sm={6}>
                                <Button variant="primary" onClick={() => handleUpdate()}>Update</Button>
                            </Col>
                            <Col className="d-flex justify-content-end align-items-center">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            Active - determines whether or not the record should be
                                            used by the software application.
                                        </Tooltip>
                                    }
                                >
                                    <div>
                                        Active <FaInfoCircle /> : <Button variant={location.isActive ? 'success' : 'danger'} onClick={() => { setLocation({ ...location, isActive: (location.isActive ? false : true) }) }}>{location.isActive ? 'True' : 'False'}</Button>
                                    </div>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                        <br />
                        <GeofencePlotter 
                            geofenceState={geofenceState} 
                            circleGeofenceState={circleGeofenceState} 
                        />
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    );
}

export default LocationUpdate;