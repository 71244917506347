import { Container, Card, Row, Col, Form, Button, Alert } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import  React, { useState } from "react";
import Api from "../../utils/Api";
import { useAuth } from '../../context/auth';
import AlertDisplay from '../../components/AlertDisplay'
const initialFormState = {
    name:'',
}
function AssignmentForm() {
    const { idToken } = useAuth();
    const [formdata, setFormdata] = useState({})
    const [alerts, setAlerts] = useState([]);
    const alertState = {alerts, setAlerts}

    async function handlePost() {
        try{
            await Api.post('/assignments', formdata, idToken)
            setAlerts([...alerts, {variant:'success', message: 'Success'}]);
            setFormdata(initialFormState)
        } catch(error){
            setAlerts([...alerts, {variant:'warning',message: 'Create error'}]);
        }
    }

    return (
        <Container fluid>
            <Card>
                <Card.Header><b>Assignment Form</b></Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                <Card.Body>
                    <Row>
                        <Col>
                            Name
                            <Form.Control type="text" value={formdata.name} onChange={e => setFormdata({ ...formdata, name : e.target.value })} />
                        </Col>
                    </Row>
                    <br />
                    <Button variant="primary" onClick={() => handlePost()}>Create</Button>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default AssignmentForm;