import React from "react";
import { Container, Card, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

function Plan() {
    return (
        <Container fluid>
            <Card>
                <Card.Header><b>Plan</b></Card.Header>
                <Card.Body>
                    <li><b>Assignment</b> - An assignment is used to assign responsibility.</li>
                    <li><b>Contract</b> - A contract is a service agreement for transportation services over a given period of time.</li>
                    <li><b>Forecast</b> - The forecast shows Route Plans against time. </li>
                    <li><b>Frequency</b> - A frequency is how often a Route should operate over the course of a given period.</li>
                    <li><b>Location</b> - A location is a physical address.</li>
                    <li><b>Operator</b> - An operator is a person whom has valid credentials to operate a vehicle.</li>
                    <li><b>Route</b> - A Route is the planned order of operations in service of a contract.</li>
                    <li><b>Special Operation</b> - A holiday or other unique date that may affect normal operations. </li>
                    <li><b>Trailer</b> - A trailer is an extension of a vehicle use for transportation of freight. </li>
                    <li><b>Vehicle</b> - A Vehicle is a power unit used to transport freight.</li>
                    <li><b>Tags</b> - A Tags is used to to identify, classify, or label. </li>
                </Card.Body>
            </Card>
            <br />
        </Container>
    );
}

export default Plan;