import React, { useEffect, useState } from "react";
import { Container, Card, Row, Col, Form, Button, Table, Spinner, Alert } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaArrowDown, FaArrowUp, FaTrash } from "react-icons/fa";
import Api from '../../utils/Api';
import { useSettings } from "../../context/settings";
import { useAuth } from '../../context/auth';
import { TIMEZONE_OPTIONS, ROUTE_ACTION, CONTACT_SERVICE_TYPES, VEHICLE_CATEGORIES, TRAILER_CATEGORIES, ROUTPLAN_PERIOD_TYPE, ROUTPLAN_CREW_TYPE, ROUTEPLAN_COVERAGE_DEFAULTS, ROUTEPLAN_WK1_KEYS, ROUTEPLAN_WK2_KEYS } from "../../context/appdata";
import AlertDisplay from "../../components/AlertDisplay";

const combinedKeys = [...ROUTEPLAN_WK1_KEYS, ...ROUTEPLAN_WK2_KEYS];

const coverageObj = combinedKeys.reduce((acc, key) => {
    acc[key] = [];
    return acc;
}, {});


const defaultRoutePlan = {
    name: "",
    startop: "",
    endop: "",
    frequency: {},
    contract: {},
    assignment: {},
    domicile: {},
    stops: [],
    payby: "",
    blockpay: "",
    blockpayhours: "",
    routemiles: "",
    coverage: coverageObj,
    crew: "Solo",
    servicetype: "",
    period: "",
    sophours: null,
    trailercat: null,
    trailerrequired: null,
    vehiclecat: null
}


function RoutePlanForm() {
    const { idToken } = useAuth();
    const { settings } = useSettings();
    const [set, didSet] = useState();
    const [formdata, setFormdata] = useState(defaultRoutePlan)
    const [coverage, setCoverage] = useState({});
    const [stops, setStops] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [assignments, setAssignments] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [frequencies, setFrequencies] = useState([]);
    const [locations, setLocations] = useState([]);
    const [domiciles, setDomiciles] = useState([]);
    const [operators, setOperators] = useState([]);
    const [vehciles, setVehicles] = useState([]);
    const [trailers, setTrailers] = useState([]);

    async function handlePost() {
        try {
            await Api.post('/routeplans', { ...formdata, stops: stops, coverage: coverage }, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success' }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Create error: '+error.error }])
        }
    }

    function removeCoverage(wkday, j) {
        const coverageArr = coverage[wkday].filter((i, index) => index !== j) ?? [];
        const newCoverage = { ...coverage, [wkday]: coverageArr }
        setCoverage({ ...newCoverage })
        setFormdata({ ...formdata, coverage: newCoverage })
    }

    function cover(wkday, val) {
        const coverageArr = coverage[wkday] ?? []
        const newCoverage = { ...coverage, [wkday]: [...coverageArr, val] }
        setCoverage({ ...newCoverage })
        setFormdata({ ...formdata, coverage: newCoverage })
    }

    function setStop(i, k, v) {
        stops[i][k] = v
        setFormdata({ ...formdata, [stops]: [...stops] })
    }

    function moveStopUp(index) {
        if (index > 0) {
            const stop = stops[index];
            const newStops = [...stops];
            newStops.splice(index, 1);
            newStops.splice(index - 1, 0, stop);
            setStops([...newStops]);
            setFormdata({ ...formdata, [stops]: [...newStops] })
        }
    }

    function moveStopDown(index) {
        if (index < stops.length - 1) {
            const stop = stops[index];
            const newStops = [...stops];
            newStops.splice(index, 1);
            newStops.splice(index + 1, 0, stop);
            setFormdata({ ...formdata, [stops]: [...newStops] })
            setStops([...newStops]);
        }
    }

    function removeStop(index) {
        const filtered = stops.filter((_, i) => {
            return i !== index
        })
        setStops([...filtered])
        setFormdata({ ...formdata, [stops]: [...filtered] })
    }

    function sumTimeDifferences(times) {
        const diffs = [];
        // let accum = 0
        for (let i = 1; i < times.length; i++) {
            const timeString1 = times[i - 1];
            const timeString2 = times[i];
            const [time1, tz1] = timeString1.split(' ');
            const [hour1, minute1] = time1.split(':');
            const [time2, tz2] = timeString2.split(' ');
            const [hour2, minute2] = time2.split(':');
            const date1 = new Date(0, 0, 0, hour1, minute1)
            const date2 = new Date(0, 0, 0, hour2, minute2)
            let ds1 = date1.toLocaleString('en-US', { timeZone: tz1 });
            let ds2 = date2.toLocaleString('en-US', { timeZone: tz2 });
            if (Date.parse(ds2) <= Date.parse(ds1)) {
                // add 24 hours to date2 if it is less than date1
                ds2 = new Date(Date.parse(ds2) + 24 * 60 * 60 * 1000);
            }
            const diff = Date.parse(ds2) - Date.parse(ds1);
            diffs.push(diff);
        }
        const totalDiff = diffs.reduce((acc, curr) => acc + curr, 0);
        const totalHours = totalDiff / 3600000;
        return totalHours;
    }

    function areTimesFormattedCorrectly(times) {
        const regex = /^\d{2}:\d{2} (CT|MT|PT|ET|America\/New_York|America\/Chicago|America\/Denver|America\/Los_Angeles)$/;
        return times.every(time => regex.test(time));
    }

    function calculateRouteHours(stops) {
        const times = [];
        for (let i = 0; i < stops.length; i++) {
            times.push(stops[i].arrive + " " + stops[i].timezone)
            times.push(stops[i].depart + " " + stops[i].timezone)
        }
        if (areTimesFormattedCorrectly(times)) {
            const timelapse = sumTimeDifferences(times)
            return timelapse
        } else {
            return 0;
        }

    }

    function getRatePerHour() {
        if (formdata.contract.payrates !== undefined) {
            let rates = [];
            for (let i = 0; i < formdata.contract.payrates.length; i++) {
                if (Date(formdata.contract.payrates[i].effective) <= Date(formdata.startop)) {
                    switch (formdata.contract.payrates[i].rule) {
                        case 'All Hours':
                            rates.push(formdata.contract.payrates[i]);
                            break;
                        case 'First 40 Hours':
                            rates.push(formdata.contract.payrates[i]);
                            break;
                        default:
                            break;
                    }
                }
            }
            const uniqueData = Object.values(rates.reduce((acc, curr) => {
                const key = `${curr.type}-${curr.rule}`;
                if (!(key in acc) || new Date(acc[key].effectiveDate) < new Date(curr.effectiveDate)) {
                    acc[key] = curr;
                }
                return acc;
            }, {}));
            //   console.log(uniqueData)
            return uniqueData.reduce((acc, curr) => {
                return acc += parseInt(curr.amount)
            }, 0)
        }
        return 0
    }

    const moneyformatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const CoverageCards = (wkkey, h) => {
        return (
            <Col key={h} sm={12} md={6} lg={3}>
                <Card>
                    <Card.Header>
                        <span>
                            <b>{wkkey.toUpperCase()}</b>
                        </span>
                        <span style={{ float: 'right' }}>
                            <b>{formdata.coverage[wkkey] ? (formdata.coverage[wkkey]).length : 0} / {formdata.crew === 'Team' ? 2 : 1}</b>
                        </span>
                    </Card.Header>
                    <Card.Body>
                        <Col>
                            <Container fluid style={{ margin: '0px', padding: '2px' }}>
                                <Row>
                                    <Col xs={10}>
                                        <Form.Select onChange={e => e.target.value !== "" && cover(wkkey, JSON.parse(e.target.value))}>
                                            <option value="">Select</option>
                                            {ROUTEPLAN_COVERAGE_DEFAULTS.map((option, i) => (
                                                <option key={i} value={JSON.stringify(option)} >{option.name}</option>
                                            ))}
                                            {operators && operators.map(operator => (
                                                <option key={operator._id} value={JSON.stringify(operator)}>{operator.firstname} {operator.lastname} {operator.provider && `- ${operator.provider} 📶 `}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                    {formdata.crew === 'Team' &&
                                        <Col xs={2}>
                                            <Button variant="primary" onClick={(coverage[wkkey].length - 1 >= 0)
                                                ? () => cover(wkkey, coverage[wkkey][coverage[wkkey].length - 1])
                                                : () => setAlerts([...alerts, { variant: 'warning', message: 'You must select a driver.' }])
                                            }>+</Button>
                                        </Col>
                                    }
                                </Row>
                            </Container>
                        </Col>
                        <hr />
                        <Col>
                            {formdata.coverage[wkkey] && formdata.coverage[wkkey].map((operator, j) => (
                                <Container fluid style={{ margin: '0px', padding: '2px' }}>
                                    <Row>
                                        <Col xs={10}>
                                            {(ROUTEPLAN_COVERAGE_DEFAULTS.map(item => item.name)).includes(operator.name)
                                                ? <Form.Control key={h + j} disabled value={`${operator.name}`} />
                                                : <Form.Control key={h + j} disabled value={`${operator.firstname} ${operator.lastname} ${operator.provider && `- ${operator.provider} 📶 `}`} />
                                            }
                                        </Col>
                                        <Col xs={2}>
                                            <Button variant="warning" onClick={() => removeCoverage(wkkey, j)}>X</Button>
                                        </Col>
                                    </Row>
                                </Container>
                            ))}
                        </Col>

                    </Card.Body>
                    {(formdata.coverage[wkkey] && (formdata.coverage[wkkey]).length > (formdata.crew === 'Team' ? 2 : 1))
                        &&
                        <Alert variant="warning">🚨 too many drivers</Alert>}
                </Card>
                <br />
            </Col>
        )
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const assigmentData = await Api.get('/assignments/live', idToken)
                const contractData = await Api.get('/contracts/live', idToken)
                const frequencyData = await Api.get('/frequencies/live', idToken)
                const locationData = await Api.get('/locations/live', idToken)
                const domicileData = await Api.get('/locations/live/Domicile', idToken)
                const operatorData = await Api.get('/operators/live', idToken)
                const trailersData = await Api.get('/trailers/live', idToken)
                const vehiclesData = await Api.get('/vehicles/live', idToken)
                setAssignments(assigmentData)
                setContracts(contractData)
                setFrequencies(frequencyData)
                setLocations(locationData)
                setDomiciles(domicileData)
                setOperators(operatorData)
                setTrailers(trailersData)
                setVehicles(vehiclesData)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    },[set,idToken,alerts,]);

    return (
        <Container fluid>
            <Card>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {set ?
                    <>
                        <Card.Header>
                            <Row>
                                <Col>
                                    <Card.Title> Route </Card.Title>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col sm={12} md={6} lg={3}>
                                    Name
                                    <Form.Control type="text" value={formdata.name} onChange={e => setFormdata({ ...formdata, name: e.target.value })} />
                                </Col>
                                <Col sm={12} md={6} lg={3}>
                                    Contract
                                    <Form.Select value={JSON.stringify(formdata.contract)} onChange={e => setFormdata({ ...formdata, contract: JSON.parse(e.target.value) })}>
                                        <option value="">Select</option>
                                        {contracts && contracts.map(contract => (
                                            <option key={contract._id} value={JSON.stringify(contract)}>{contract.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col sm={12} md={6} lg={3}>
                                    Assignment
                                    <Form.Select value={JSON.stringify(formdata.assignment)} onChange={e => setFormdata({ ...formdata, assignment: JSON.parse(e.target.value) })}>
                                        <option value="">Select</option>
                                        {assignments && assignments.map(assignment => (
                                            <option key={assignment._id} value={JSON.stringify(assignment)}>{assignment.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col sm={12} md={6} lg={3}>
                                    Domicile
                                    <Form.Select value={JSON.stringify(formdata.domicile)} onChange={e => setFormdata({ ...formdata, domicile: JSON.parse(e.target.value) })}>
                                        <option value="">Select</option>
                                        {domiciles && domiciles.map(domicile => (
                                            <option key={domicile._id} value={JSON.stringify(domicile)}>{domicile.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            </Row>
                            <br />
                            <div className="table-wrapper">
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Trip</th>
                                            <th>Location</th>
                                            <th>Timezone</th>
                                            <th>Arrive</th>
                                            <th>Action</th>
                                            <th>Depart</th>
                                            <th>
                                                <Button variant="primary" className="float-end" onClick={() => setStops([...stops, { timezone: settings.timezone }])}>Add Stop</Button>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {stops.length > 0 ? stops.map((stop, i) => (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td><Form.Control type="text" value={stop.trip} onChange={e => setStop(i, 'trip', e.target.value)} /></td>
                                                <td>
                                                    <Form.Select value={stop.location} onChange={e => setStop(i, 'location', e.target.value)}>
                                                        <option value="">Select</option>
                                                        {locations && locations.map(location => (
                                                            <option key={location._id}>{location.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </td>
                                                <td>
                                                    <Form.Select value={stop.timezone} defaultValue={settings.timezone} onChange={e => setStop(i, 'timezone', e.target.value)} >
                                                        <option value="">Select</option>
                                                        {TIMEZONE_OPTIONS.map((tz, i) => (
                                                            <option key={i} value={tz.name}>{tz.code}</option>
                                                        ))}

                                                    </Form.Select>
                                                </td>
                                                <td>
                                                    <Form.Control value={stop.arrive} defaultValue={'00:00'} type="time" onChange={e => setStop(i, 'arrive', e.target.value)} />
                                                </td>
                                                <td>
                                                    <Form.Select value={stop.action} onChange={e => setStop(i, 'action', e.target.value)} >
                                                        <option value="">Select</option>
                                                        {ROUTE_ACTION.map((ra, i) => (
                                                            <option value={ra.name}>{ra.name} - {ra.detail}</option>
                                                        ))}
                                                    </Form.Select>
                                                </td>
                                                <td>
                                                    <Form.Control value={stop.depart} defaultValue={'00:00'} type="time" onChange={e => setStop(i, 'depart', e.target.value)} />
                                                </td>
                                                <td>
                                                    <Button variant="secondary" onClick={() => moveStopUp(i)}>
                                                        <FaArrowUp />
                                                    </Button>
                                                    <Button variant="secondary" onClick={() => moveStopDown(i)}>
                                                        <FaArrowDown />
                                                    </Button>
                                                    <Button variant="warning" onClick={() => removeStop(i)}>
                                                        <FaTrash />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                            :
                                            <tr>
                                                <td colSpan={8}>
                                                    Add a stop.
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th colSpan="8">Standard Operation Procedure (SOP) <span className="float-end" > * Use to calculate Pay Est.</span></th>
                                        </tr>
                                        {(formdata.contract) &&
                                            <>
                                                <tr>
                                                    <th colSpan={2}>
                                                        <Form.Check
                                                            type="radio"
                                                            label="Pay by Hour"
                                                            name="option"
                                                            value="hour"
                                                            checked={formdata.payby === 'hour'}
                                                            onChange={e => setFormdata({ ...formdata, payby: e.target.value })}
                                                        />
                                                        {(formdata.payby === 'hour') &&
                                                            <Form.Check
                                                                label={'Block Pay'}
                                                                checked={formdata.blockpay}
                                                                onClick={() => {
                                                                    setFormdata({ ...formdata, blockpay: (formdata.blockpay ? false : true) })
                                                                }}
                                                            />
                                                        }
                                                    </th>
                                                    <td colSpan={formdata.blockpay === true ? 1 : 2}>
                                                        <b> {(formdata.blockpay !== true && formdata.payby === 'hour') && '*'} SOP Hours</b>
                                                        <Form.Control
                                                            type="number"
                                                            value={calculateRouteHours(stops)}
                                                            onChange={e => setFormdata({ ...formdata, sophours: e.target.value })}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </td>

                                                    {(formdata.blockpay === true && formdata.payby === 'hour') &&
                                                        <td>
                                                            <b>{(formdata.blockpay === true && formdata.payby === 'hour') && '*'} Block Hours </b>
                                                            <Form.Control
                                                                type="number"
                                                                step="0.00"
                                                                value={formdata.blockpayhours}
                                                                onChange={e => setFormdata({ ...formdata, blockpayhours: e.target.value })}
                                                            />

                                                        </td>
                                                    }
                                                    <td colSpan={5}>
                                                        {formdata.payby === 'hour' &&
                                                            <Row>
                                                                <Col><b> {formdata.payby === 'hour' && '*'} Rate Per Hour</b>
                                                                    <Form.Control type="number"
                                                                        step="0.00"
                                                                        value={getRatePerHour()}
                                                                        onChange={e => setFormdata({ ...formdata, payrateperhour: e.target.value })}
                                                                        readOnly
                                                                        disabled
                                                                    />
                                                                </Col>
                                                                <Col><b>Pay Est.</b>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={moneyformatter.format(formdata.blockpay ?
                                                                            (formdata.blockpayhours * getRatePerHour())
                                                                            :
                                                                            (calculateRouteHours(stops) * getRatePerHour())
                                                                        )}
                                                                        onChange={e => setFormdata({ ...formdata, payrph: e.target.value })}
                                                                        readOnly
                                                                        disabled
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th colSpan={2}>
                                                        <Form.Check
                                                            type="radio"
                                                            label="Pay by Mile"
                                                            name="option"
                                                            value="mile"
                                                            checked={formdata.payby === 'mile'}
                                                            onChange={e => setFormdata({ ...formdata, payby: e.target.value })}
                                                        />
                                                    </th>
                                                    <td colSpan={2}>
                                                        <b  > {(formdata.payby === 'mile') && '*'} Miles</b>
                                                        <Form.Control type="number"
                                                            step="0.00"
                                                            placeholder="Measured miles"
                                                            value={formdata.routemiles}
                                                            onChange={e => setFormdata({ ...formdata, routemiles: e.target.value })}
                                                        />
                                                    </td>
                                                    <td colSpan={5}>
                                                        {formdata.payby === 'mile' &&
                                                            <Row>
                                                                <Col><b> {(formdata.payby === 'mile') && '*'}Rate Per Mile</b>
                                                                    <Form.Control type="number"
                                                                        step="0.00"
                                                                        value={formdata.payratepermile}
                                                                        onChange={e => setFormdata({ ...formdata, payratepermile: e.target.value })}
                                                                    />
                                                                </Col>
                                                                <Col><b>Pay Est.</b>
                                                                    <Form.Control type="text"
                                                                        step="0.00"
                                                                        value={moneyformatter.format(formdata.payratepermile * formdata.routemiles)}
                                                                        onChange={e => setFormdata({ ...formdata, payrpm: e.target.value })}
                                                                        readOnly
                                                                        disabled
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </td>
                                                </tr>
                                            </>
                                        }
                                    </tfoot>
                                </Table>
                            </div>
                        </Card.Body>
                        <Card.Header>
                            <Row>
                                <Col>
                                    <Card.Title>Service </Card.Title>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>

                            <Row>
                                <Col sm={12} md={6} lg={3}>
                                    Type
                                    <Form.Select value={formdata.servicetype} onChange={e => setFormdata({ ...formdata, servicetype: e.target.value })}>
                                        <option value="">Select</option>
                                        {CONTACT_SERVICE_TYPES.map((servicetype, i) => (
                                            <option key={i} value={servicetype.name}>{servicetype.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col sm={12} md={6} lg={3}>
                                    Frequency
                                    <Form.Select value={JSON.stringify(formdata.frequency)} onChange={e => setFormdata({ ...formdata, frequency: JSON.parse(e.target.value) })}>
                                        <option value="">Select</option>
                                        {frequencies && frequencies.map(frequency => (
                                            <option key={frequency._id} value={JSON.stringify(frequency)}>{frequency.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col sm={12} md={6} lg={3}>
                                    Start Op
                                    <Form.Control type="date" onChange={e => setFormdata({ ...formdata, startop: e.target.value })} />
                                </Col>
                                <Col sm={12} md={6} lg={3}>
                                    End Op
                                    <Form.Control type="date" onChange={e => setFormdata({ ...formdata, endop: e.target.value })} />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Header>
                            <Row>
                                <Col>
                                    <Card.Title>Equipment </Card.Title>
                                </Col>
                                <Col>
                                    <Form.Check
                                        label={'Trailer Required'}
                                        className="float-end"
                                        checked={formdata.trailerrequired}
                                        onClick={() => { setFormdata({ ...formdata, trailerrequired: (formdata.trailerrequired ? false : true) }) }}
                                    />
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col sm={12} md={6} lg={4}>
                                    Vehicle
                                    <Form.Select value={formdata.vehiclecat} onChange={e => setFormdata({ ...formdata, vehiclecat: e.target.value })}>
                                        <option value="">Select</option>
                                        {VEHICLE_CATEGORIES.map((v, i) => (
                                            <option key={i} value={v.name}>{v.name}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Check
                                        label={'Schedule Vehicle'}
                                        checked={formdata.schedulevehicle}
                                        onClick={() => { setFormdata({ ...formdata, schedulevehicle: (formdata.schedulevehicle ? false : true) }) }}
                                    />
                                    {formdata.schedulevehicle === true &&
                                        <Form.Select value={JSON.stringify(formdata.scheduledvehicle)} onChange={e => setFormdata({ ...formdata, scheduledvehicle: JSON.parse(e.target.value) })}>
                                            <option value="">Select</option>
                                            {vehciles.map(vehicle => (
                                                <option value={JSON.stringify(vehicle)}>{vehicle.unitnumber}</option>
                                            ))}
                                        </Form.Select>
                                    }
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    {formdata.trailerrequired &&
                                        <>
                                            Trailer
                                            <Form.Select value={formdata.trailercat} onChange={e => setFormdata({ ...formdata, trailercat: e.target.value })}>
                                                <option value="">Select</option>
                                                {TRAILER_CATEGORIES.map((t, i) => (
                                                    <option key={i} value={t.name}>{t.name}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Check
                                                label={'Schedule Trailer'}
                                                checked={formdata.scheduletrailer}
                                                onClick={() => { setFormdata({ ...formdata, scheduletrailer: (formdata.scheduletrailer ? false : true) }) }}
                                            />
                                            {formdata.scheduletrailer === true &&
                                                <Form.Select value={JSON.stringify(formdata.scheduledtrailer)} onChange={e => setFormdata({ ...formdata, scheduledtrailer: JSON.parse(e.target.value) })}>
                                                    <option value="">Select</option>
                                                    {trailers.map(trailer => (
                                                        <option value={JSON.stringify(trailer)}>{trailer.unitnumber}</option>
                                                    ))}
                                                </Form.Select>
                                            }
                                        </>
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Header>
                            <Row>
                                <Col>
                                    <Card.Title>Coverage </Card.Title>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col sm={12} md={6} lg={4}>
                                    Crew
                                    <Form.Select value={formdata.crew} onChange={e => setFormdata({ ...formdata, crew: e.target.value })}>
                                        <option value="">Select</option>
                                        {ROUTPLAN_CREW_TYPE.map((name, i) => (
                                            <option key={i} value={name}>{name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    Period
                                    <Form.Select value={formdata.period} onChange={e => setFormdata({ ...formdata, period: e.target.value })}>
                                        <option value="">Select</option>
                                        {ROUTPLAN_PERIOD_TYPE.map((name, i) => (
                                            <option key={i} value={name}>{name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <Container fluid>
                                        <Card.Title>
                                            Week 1
                                        </Card.Title>
                                        <Row>
                                            {formdata.period !== '' && ROUTEPLAN_WK1_KEYS.map((wk1key, h) => (
                                                CoverageCards(wk1key, h)
                                            ))}
                                        </Row>
                                        <br />
                                        {formdata.period === 'Biweekly' &&
                                            <>
                                                <Card.Title>
                                                    Week 2
                                                </Card.Title>
                                                <Row>
                                                    {ROUTEPLAN_WK2_KEYS.map((wk2key, h) => (
                                                        CoverageCards(wk2key, h)
                                                    ))}
                                                </Row>
                                            </>}
                                    </Container>
                                </Col>
                            </Row>
                        </Card.Body>
                        <br />
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Button variant="primary" onClick={() => handlePost()}>Create</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }
            </Card>
        </Container>
    );
}

export default RoutePlanForm;