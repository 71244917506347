import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import { Container, Card, Row, Col, Spinner, Badge, } from "react-bootstrap";
import Api from '../../utils/Api';
import { FaPlusCircle } from 'react-icons/fa'
import { useAuth } from '../../context/auth';
import DataDisplay from '../../components/DataDisplay';
import { Link } from 'react-router-dom';
import AlertDisplay from '../../components/AlertDisplay';

function Contract() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [contracts, setContracts] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [query, setQuery] = useState({
        isActive: true
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const queryParams = new URLSearchParams(query);
                const data = await Api.query('/contracts', queryParams, idToken);
                if (!data || !Array.isArray(data)) {
                    throw new Error("Received invalid data from server.");
                }
                const cleanContracts = data.map(contract => {
                    return {
                        _id: contract._id,
                        'Name': contract.name,
                        'Assignment': contract.assignment.name,
                        'Domiciles': <>{contract?.domiciles ? contract.domiciles.map(domicile => <div>{domicile.name}</div>) : ''}</>,
                        'Effective': contract.effective,
                        'Last Day': contract.lastday,
                        'Active': contract.isActive ? '✅' : '❌',
                    }
                })
                setContracts(cleanContracts)

            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }]);
            }
            didSet(true)
        }
        fetchData()
    }, [idToken, set, alerts, query])

    return (
        <Container fluid>
            <Card>
                <Card.Header className="align-items-center">
                    <Card.Title>
                        <Row>
                            <Col>
                                Contract
                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                <Badge
                                    bg={query.isActive ? 'success' : 'danger'}
                                    onClick={() => { setQuery({ ...query, isActive: !query.isActive }); didSet(false) }}
                                >
                                    {query.isActive ? 'Active' : 'Inactive'}
                                </Badge>
                            </Col>
                            <Col>
                                <Link style={{ float: 'right' }} to="/plan/contract-form"><FaPlusCircle /> Form</Link>
                            </Col>
                        </Row>
                    </Card.Title>
                </Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {set ?
                    <>
                        <Card.Body>
                            {contracts.length > 0 ?
                                <>
                                    <DataDisplay
                                        dataSource={contracts}
                                        lsKey={'@mctms-contracts-display'}
                                        urlPath={'/plan/contract/'}
                                        urlKey={'_id'}
                                        popKeys={['_id']}
                                    />
                                </>
                                :
                                <>
                                    No data.
                                </>
                            }
                        </Card.Body>
                    </>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    )
}

export default Contract;