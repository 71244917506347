import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import { Container, Card, Button, Row, Col } from "react-bootstrap";
import Api from "../../../utils/Api";
import { useAuth } from "../../../context/auth";
import { FaPlusCircle } from "react-icons/fa";
import DataDisplay from '../../../components/DataDisplay';
// import DailyReportCalendar from './DailyReport.calendar';

function DailyReport() {
  const { idToken } = useAuth();
  const [set, didSet] = useState();
  const [dailyReports, setDailyReports] = useState([])
  const [alerts, setAlerts] = useState([]);

  const fetchData = async () => {
    try {
      const records = await Api.query('/form/dailyreport', {}, idToken);

      const cleanDailyReports = records.map(record => {
        return {
          _id: record._id,
          'Date': record.date,
          'Name': `${record.firstname} ${record.lastname}, ${record.email}`,
          'HCRs': `${record.hcrs} `,
          'Schedule': `${record.schedule}`,
          'Personnel': `${record.personnel} `,
          'Equipment': `${record.equipment} `,
          'Extra Service': `${record.extraservice} `,
          'USPS Communication': `${record.extraservice} `,
        }
      })

      setDailyReports(cleanDailyReports)
    } catch (error) {
      setAlerts([...alerts, { variant: 'warning', message: JSON.stringify(error) }]);
    }
  }

  useEffect(() => {
    if (!set) {
      fetchData()
    }
    return () => didSet(true)
  })

  return (
    <Container fluid>
      <Card>
        <Card.Header>
          <Row>
            <Col className="d-flex align-items-center"> <b>Daily Reports</b></Col>
            <Col>
              <Button
                variant="link"
                className="float-end"
                href="/form/dailyreport-form"
              >
                <FaPlusCircle /> Form{" "}
              </Button>
            </Col>
          </Row>
        </Card.Header>
        {/* <Card.Body>
          <DailyReportCalendar dailyreports={dailyReports} />
        </Card.Body> */}
        <Card.Body>
          {dailyReports && dailyReports.length > 0 ?
            <>
                <DataDisplay
                  dataSource={dailyReports}
                  lsKey={'@mctms-daily-reports-table'}
                  urlPath={'/form/dailyreport/'}
                  urlKey={'_id'}
                  popKeys={['_id']}
                  sourceName='DailyReport'
                />
            </>
            :
            <>
              No data.
            </>
          }
        </Card.Body>

      </Card>

    </Container>
  );
}

export default DailyReport;