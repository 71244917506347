
import React, { useState, useEffect, useCallback } from "react";
import { Container, Card, Form, Row, Col, Button, Spinner } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import AlertDisplay from "../../components/AlertDisplay";
import DataDisplay from "../../components/DataDisplay";
import Api from "../../utils/Api";
import { useAuth } from "../../context/auth";
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp } from "react-icons/fa";
// import { FaArrowDown, FaArrowUp, FaTrash, FaXingSquare } from "react-icons/fa";
// import Api from '../../utils/Api';
// import { useParams } from 'react-router-dom';

function TimecardPayPeriods() {
    const [set, didSet] = useState();
    const { idToken } = useAuth();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [payperiods, setPayPeriods] = useState();
    const [payperiodstartdate, setPayPeriodStartDate] = useState();
    const [toggle, setToggle] = useState();

    async function addNextPayPeriod(i) {
        try {
            await Api.post('/payperiod/next', { periodStart: payperiods[i]['Period Start'] }, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully added period ' + String(i) }]);
            didSet(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error adding next period ' + String(i) }]);
        }
    }

    async function addPerviousPayPeriod(i) {
        try {
            await Api.post('/payperiod/previous', { periodStart: payperiods[i]['Period Start'] }, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully added period ' + String(i) }]);
            didSet(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error adding previous period ' + String(i) }]);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Api.get('/payperiods/live', idToken);
                if (data) {
                    const cleanPayPeriods = data.map((payperiod, i) => {
                        return {
                            _id: payperiod._id,
                            'Period Start': payperiod.periodStart,
                            'Period End': payperiod.periodEnd,
                        }
                    })
                    setPayPeriods(cleanPayPeriods)
                }
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }]);
            }
            didSet(true)
        }

        if (!set) {
            fetchData();
        }

    }, [payperiodstartdate, set, alerts, idToken, payperiods,]);


    async function handleSetPayPeriod() {
        try {
            await Api.post('/payperiods', { periodStart: payperiodstartdate }, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'Pay Period Set' }]);
            didSet(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured setting pay period.' }]);
        }
    }

    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <Card.Title>
                        Pay Periods
                        <span style={{float:'right'}}>
                            <Button onClick={()=>setToggle(!toggle)}>{toggle ? 'Hide' : 'Show'} Pay Period Set</Button>
                        </span>
                    </Card.Title>
                </Card.Header>
                <AlertDisplay alertState={alertState} />
                {toggle &&
                    <Card.Body>
                        <Row>
                            <Col>
                                Start Date:
                                <Form.Control type="date" value={payperiodstartdate} onChange={e => setPayPeriodStartDate(e.target.value)} />
                            </Col>
                            <Col>
                                <br />
                                <Button onClick={() => handleSetPayPeriod()}>Set Biweekly Pay Period Day 1</Button>
                            </Col>
                        </Row>
                    </Card.Body>
                }
                <Card.Body>
                    {set ?
                        (payperiods) ?
                            <>
                                <Button variant="secondary" onClick={() => addPerviousPayPeriod(payperiods.length - 1)}><FaArrowLeft /> Add Previous Period</Button>
                                {' '}<Button variant="secondary" onClick={() => addNextPayPeriod(0)}>Add Next Period <FaArrowRight /></Button>
                                <DataDisplay
                                    dataSource={payperiods}
                                    lsKey={'@mctms-payperiods-display'}
                                    popKeys={['_id']}
                                />
                            </>
                            :
                            <Row>
                                <Col>
                                    No Pay Periods.
                                </Col>
                            </Row>

                        : <Spinner />}
                </Card.Body>
            </Card>
        </Container>
    );
}

export default TimecardPayPeriods;