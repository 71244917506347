import { Container, Card, Row, Col, Form, Button, Spinner, Alert, Tooltip, OverlayTrigger } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import Api from "../../utils/Api";
import { useParams } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import { useAuth } from '../../context/auth';
import AlertDisplay from '../../components/AlertDisplay'


function AssignmentUpdate() {
    const { idToken } = useAuth();
    const { id } = useParams();
    const [set, didSet] = useState();
    const [assignment, setAssignment] = useState({});
    const [alerts, setAlerts] = useState([]);
    const alertState = {alerts, setAlerts}

    async function handleUpdate() {
        try {
            await Api.patch(`/assignment/${id}`, assignment, idToken)
            setAlerts([...alerts, {variant:'success',message: 'Success'}]);
        } catch (error) {
            setAlerts([...alerts, {variant:'warning',message: 'Error updating'}]);
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Api.get(`/assignment/${id}`, idToken);
                setAssignment(data)
            } catch (error) {
                setAlerts([...alerts, {variant:'warning',message: 'Request error'}]);
            }
            didSet(true)
        }
        if(!set){
            fetchData()
        }
    },[set,idToken,alerts,id])

    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <b>Assignment Update</b>
                </Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {set ?
                    <>
                        <Card.Body>
                            <Row>
                                <Col>
                                    ID : {assignment._id}
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col md={6} lg={6}>
                                    <Form.Control type="text" value={assignment.name} onChange={e => setAssignment({ ...assignment, name: e.target.value })} />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col sm={6}>
                                    <Button variant="primary" onClick={() => handleUpdate()}>Update</Button>
                                </Col>
                                <Col className="d-flex justify-content-end align-items-center">
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip id="button-tooltip">
                                                Active - determines whether or not the record should be
                                                used by the software application.
                                            </Tooltip>
                                        }
                                    >
                                        <div>
                                            Active <FaInfoCircle /> : <Button variant={assignment.isActive ? 'success' : 'danger'} onClick={() => { setAssignment({ ...assignment, isActive: !assignment.isActive }) }}>{assignment.isActive ? 'True' : 'False'}</Button>
                                        </div>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </Card.Body>
                    </>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    );
}

export default AssignmentUpdate;