import React from "react"
import { Container } from "react-bootstrap"



const LoadTenderCalendar = () => { 
    return ( 
        <Container fluid>
            <li>Unassigned Load Tenders</li>
            <li>Load Tender Status</li>
        </Container>
    )
}

export default LoadTenderCalendar