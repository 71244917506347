
import React from 'react'

const AccidentForm = () => {
    return (
        <>
            Accident Form
        </>
    )
}

export default AccidentForm;