
import React from "react";
import { Container, Card, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
// import { FaArrowDown, FaArrowUp, FaTrash, FaXingSquare } from "react-icons/fa";
// import Api from '../../utils/Api';
// import { useParams } from 'react-router-dom';

function TimecardSettings() {

    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header>Timecard Settings</Card.Header>
                <Card.Body>
                    <Card.Text>
                        User generated settings 
                    </Card.Text>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default TimecardSettings;