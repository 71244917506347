import React, { useEffect, useState } from 'react';
import { Form, Button, Card, Row, Col, Badge, Alert, Image, Modal } from 'react-bootstrap';
import logo from '../../../assets/logo.png'
import truckfront from '../../../assets/truck-pic-front.png'
import truckright from '../../../assets/truck-pic-right.png'
import truckleft from '../../../assets/truck-pic-left.png'
import truckback from '../../../assets/truck-pic-back.png'
import truckinsideleft from '../../../assets/truck-pic-inside-left.png'
import truckinsideright from '../../../assets/truck-pic-inside-right.png'
import samplefront from '../../../assets/sample-front.jpeg'
import sampleleft from '../../../assets/sample-left.jpg'
import sampleright from '../../../assets/sample-right.jpg'
import sampleback from '../../../assets/sample-back.jpg'
import sampleinsideleft from '../../../assets/sample-inside-left.jpg'
import sampleinsideright from '../../../assets/sample-inside-right.jpg'
import {AiOutlineQuestionCircle, AiOutlineClose} from 'react-icons/ai';

import { useAuth } from '../../../context/auth';
import Api from '../../../utils/Api';
import { useDropzone } from 'react-dropzone';

const initialFormState = {
  contract: '',
  service: '',
  location: '',
  checkintype: '',
  vehicletype: '',
  termlength: '',
  company: '',
  unitnumber: '',
  odometerin: '',
  eld: '',
  ifta: '',
  lytx: '',
  address: '',
  year: '',
  make: '',
  model: '',
  vin: '',
  checkinnotes: '',
  checkinfirstname: '',
  checkinlastname: '',
  checkindate: '',
  odometerout: '',
  checkoutnotes: '',
  checkoutfirstname: '',
  checkoutlastname: '',
  checkoutdate: '',
};

const rowStyle = {
  marginTop: '12px',
};

const ThirdPartyVehicleForm = () => {
  const { user, idToken } = useAuth();
  const [alerts, setAlerts] = useState([]);
  const [message, setMessage] = useState();
  const [error, setError] = useState();
  const [load, didLoad] = useState();
  const [form, setForm] = useState(initialFormState);
  const [images, setImages] = useState([]);
  const [registrations, setRegistrations] = useState([]);
  const [inspections, setInspections] = useState([]);
  const [leases, setLeases] = useState([]);
  const [imagesOut, setImagesOut] = useState([]);
  const [popUpImage, setPopUpImage] = useState(false);
  const [showPopUpModal, setShowPopUpModal] = useState(false);

  const handleSubmit = async () => {
    try {
      const requiredFields = [
        "contract",
        "service",
        "location",
        "checkintype",
        "vehicletype",
        "termlength",
        "company",
        "unitnumber",
        "odometerin",
        "eld",
        "ifta",
        "lytx",
        "address",
        "year",
        "make",
        "model",
        "vin",
        "checkinnotes",
        "checkinfirstname",
        "checkinlastname",
        "checkindate",
        "odometerout",
        "checkoutnotes",
        "checkoutfirstname",
        "checkoutlastname",
        "checkoutdate",
      ];
      const missingFields = requiredFields.filter((field) => !form[field]);

      if (missingFields.length > 0) {
        const missingFieldNames = missingFields.map((field) =>
          field.toUpperCase()
        );
        const missingFieldsMessage = missingFieldNames.join(", ");
        setAlerts([
          ...alerts,
          {
            type: "warning",
            message: `Please fill in the required fields: ${missingFieldsMessage}`,
          },
        ]);
        return;
      }
      const res = await Api.post(
        "/form/thirdpartyvehicle",
        { ...form, email: user.email },
        idToken
      );
      setMessage(res);
      handleReset();
    } catch (error) {
      setError(error)
    }
  };

  const handleReset = () => {
    setForm(initialFormState);
    localStorage.setItem('vehicleInAndOutData', JSON.stringify(initialFormState));
    didLoad(false);
  };

  const handleInputChange = (key, value) => {
    const data = {...form, [key]: value}
    setForm(data);
    localStorage.setItem('vehicleInAndOutData', JSON.stringify(data));
    console.log(data);
  }

  const handleDropImages = (acceptedImages) => {
    setImages((prevImages) => [...prevImages, ...acceptedImages])
  };

  const handleRemoveImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };
  
  
  const handleDropRegistrations = (acceptedFiles) => {
    setRegistrations((prevRegistrations) => [...prevRegistrations, ...acceptedFiles]);
  };
  
  const handleRemoveRegistration = (index) => {
    setRegistrations((prevRegistrations) => prevRegistrations.filter((_, i) => i !== index));
  };
  
  const handleDropInspections = (acceptedFiles) => {
    setInspections((prevInspections) => [...prevInspections, ...acceptedFiles]);
  };
  
  const handleRemoveInspection = (index) => {
    setInspections((prevInspections) => prevInspections.filter((_, i) => i !== index));
  };
  
  const handleDropLeases = (acceptedFiles) => {
    setLeases((prevLeases) => [...prevLeases, ...acceptedFiles]);
  };
  
  const handleRemoveLease = (index) => {
    setLeases((prevLeases) => prevLeases.filter((_, i) => i !== index));
  };
  
  const handleDropImagesOut = (acceptedImagesOut) => {
    setImagesOut((prevImagesOut) => [...prevImagesOut, ...acceptedImagesOut])
  };

  const handleRemoveImageOut = (index) => {
    setImagesOut((prevImagesOut) => prevImagesOut.filter((_, i) => i !== index));
  };

  const { getRootProps: getRootPropsImages, getInputProps: getInputPropsImages, isDragActive: isDragActiveImages } =
  useDropzone({ onDrop: handleDropImages });
  
  const { getRootProps: getRootPropsRegistrations, getInputProps: getInputPropsRegistrations, isDragActive: isDragActiveRegistrations } =
  useDropzone({ onDrop: handleDropRegistrations });
  
  const { getRootProps: getRootPropsInspections, getInputProps: getInputPropsInspections, isDragActive: isDragActiveInspections } =
  useDropzone({ onDrop: handleDropInspections });
  
  const { getRootProps: getRootPropsLeases, getInputProps: getInputPropsLeases, isDragActive: isDragActiveLeases } =
  useDropzone({ onDrop: handleDropLeases });
  
  const { getRootProps: getRootPropsImagesOut, getInputProps: getInputPropsImagesOut, isDragActive: isDragActiveImagesOut } =
  useDropzone({ onDrop: handleDropImagesOut });

  const dropzoneStyle = {
    border: '2px dashed #ced4da',
    backgroundColor: 'white',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
  };

  const dropzoneActiveStyle = {
    backgroundColor: '#D1E3FF',
    border: '2px dashed #0056B3',
  };

  const handlePopUpImage = (image) => {
    setPopUpImage(image)
    setShowPopUpModal(true);
  };

  const yearOptions = Array.from({ length: 51}, (_, index) => new Date().getFullYear() - index)
  

  const handleLoad = () => {
    const formobj = JSON.parse(localStorage.getItem("vehicleInAndOutData"));
    if (formobj) {
      setForm(formobj);
    } else {
      setForm(initialFormState);
    }
    didLoad(true);
  };

  useEffect(() => {
    if (!load) {
      handleLoad();
    }
  });

  return (
    <Card>
      <Card.Body>
        <div className="img-container d-flex justify-content-center align-items-center">
          <Card.Img
            src={logo}
            alt="logo"
            style={{ width: "100px", height: "auto", marginBottom: "10px" }}
          />
        </div>
        <Card.Title className="text-center">
          MMS Vehicle Check In & Out
        </Card.Title>
        {
          <Row>
            <Col>
              {alerts.map((alrt, i) => (
                <Alert
                  key={i}
                  variant={alrt.type}
                  dismissible
                  onClick={() =>
                    setAlerts(alerts.filter((_, index) => index !== i))
                  }
                >
                  {alrt.message}
                </Alert>
              ))}
            </Col>
          </Row>
        }
        <Card>
          <Card.Body>
            <Form.Label className="fw-bold">VEHICLE CHECK-IN</Form.Label>
            <Form>
              <Row style={rowStyle}>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Form.Label>Contract Assignment</Form.Label>
                  <Form.Control
                    type="text"
                    name="contract"
                    value={form.contract}
                    onChange={(e) =>
                      handleInputChange("contract", e.target.value)
                    }
                    required
                  />
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Form.Label>Location Assignment</Form.Label>
                  <Form.Control
                    type="text"
                    name="location"
                    value={form.location}
                    onChange={(e) =>
                      handleInputChange("location", e.target.value)
                    }
                    required
                  />
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Form.Label>Length of Term</Form.Label>
                  <Form.Control
                    type="text"
                    name="termlength"
                    value={form.termlength}
                    onChange={(e) =>
                      handleInputChange("termlength", e.target.value)
                    }
                    required
                  />
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col xl={3} lg={12} md={12} sm={12} xs={12}>
                  <Form.Group>
                    <Form.Label>Service Type</Form.Label>
                    <Col>
                      <Form.Check
                        inline
                        label="normal"
                        type="radio"
                        id="option-normal"
                        name="service"
                        value="normal"
                        checked={form.service === "normal"}
                        onChange={(e) =>
                          handleInputChange("service", e.target.value)
                        }
                      />
                      <Form.Check
                        inline
                        label="peak"
                        type="radio"
                        id="option-peak"
                        name="service"
                        value="peak"
                        checked={form.service === "peak"}
                        onChange={(e) =>
                          handleInputChange("service", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={4} lg={12} md={12} sm={12} xs={12}>
                  <Form.Group>
                    <Form.Label>Check In Type</Form.Label>
                    <Col>
                      <Form.Check
                        inline
                        label="Lease"
                        type="radio"
                        id="option-lease"
                        name="checkintype"
                        value="lease"
                        checked={form.checkintype === "lease"}
                        onChange={(e) =>
                          handleInputChange("checkintype", e.target.value)
                        }
                      />
                      <Form.Check
                        inline
                        label="Rental"
                        type="radio"
                        id="option-rental"
                        name="checkintype"
                        value="rental"
                        checked={form.checkintype === "rental"}
                        onChange={(e) =>
                          handleInputChange("checkintype", e.target.value)
                        }
                      />
                      <Form.Check
                        inline
                        label="Substitute"
                        type="radio"
                        id="option-substitute"
                        name="checkintype"
                        value="substitute"
                        checked={form.checkintype === "substitute"}
                        onChange={(e) =>
                          handleInputChange("checkintype", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={5} lg={12} md={12} sm={12} xs={12}>
                  <Form.Group>
                    <Form.Label>Vehicle Type</Form.Label>
                    <Col>
                      <Form.Check
                        inline
                        label="Semi-Truck"
                        type="radio"
                        id="option-semitractor"
                        name="vehicletype"
                        value="semitractor"
                        checked={form.vehicletype === "semitractor"}
                        onChange={(e) =>
                          handleInputChange("vehicletype", e.target.value)
                        }
                      />
                      {/* </Col>
                      <Col xl={6} lg={6} md={6} sm={6} xs={6}> */}
                      <Form.Check
                        inline
                        label="Box Truck"
                        type="radio"
                        id="option-boxtruck"
                        name="vehicletype"
                        value="boxtruck"
                        checked={form.vehicletype === "boxtruck"}
                        onChange={(e) =>
                          handleInputChange("vehicletype", e.target.value)
                        }
                      />
                      {/* </Col>
                      <Col xl={6} lg={6} md={6} sm={6} xs={6}> */}
                      <Form.Check
                        inline
                        label="Van"
                        type="radio"
                        id="option-van"
                        name="vehicletype"
                        value="van"
                        checked={form.vehicletype === "van"}
                        onChange={(e) =>
                          handleInputChange("vehicletype", e.target.value)
                        }
                      />
                      {/* </Col>
                      <Col xl={6} lg={6} md={6} sm={6} xs={6}> */}
                      <Form.Check
                        inline
                        label="Car"
                        type="radio"
                        id="option-car"
                        name="vehicletype"
                        value="car"
                        checked={form.vehicletype === "car"}
                        onChange={(e) =>
                          handleInputChange("vehicletype", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Form.Label>Lease/Rental Company</Form.Label>
                  <Form.Control
                    type="text"
                    name="company"
                    value={form.company}
                    onChange={(e) =>
                      handleInputChange("company", e.target.value)
                    }
                    required
                  />
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Form.Label>Unit Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="unitnumber"
                    value={form.unitnumber}
                    onChange={(e) =>
                      handleInputChange("unitnumber", e.target.value)
                    }
                    required
                  />
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Form.Label>Odometer In</Form.Label>
                  <Form.Control
                    type="number"
                    name="odometerin"
                    value={form.odometerin}
                    onChange={(e) =>
                      handleInputChange("odometerin", e.target.value)
                    }
                    required
                  />
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Form.Group>
                    <Form.Label>Vehicle has ELD?</Form.Label>
                    <Col>
                      <Form.Check
                        inline
                        label="Yes"
                        type="radio"
                        id="option-Yes"
                        name="eld"
                        value="Yes"
                        checked={form.eld === "Yes"}
                        onChange={(e) =>
                          handleInputChange("eld", e.target.value)
                        }
                      />
                      <Form.Check
                        inline
                        label="No"
                        type="radio"
                        id="option-No"
                        name="eld"
                        value="No"
                        checked={form.eld === "No"}
                        onChange={(e) =>
                          handleInputChange("eld", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Form.Group>
                    <Form.Label>Vehicle has IFTA Stickers?</Form.Label>
                    <Col>
                      <Form.Check
                        inline
                        label="Yes"
                        type="radio"
                        id="option-Yes2"
                        name="ifta"
                        value="Yes"
                        checked={form.ifta === "Yes"}
                        onChange={(e) =>
                          handleInputChange("ifta", e.target.value)
                        }
                      />
                      <Form.Check
                        inline
                        label="No"
                        type="radio"
                        id="option-No2"
                        name="ifta"
                        value="No"
                        checked={form.ifta === "No"}
                        onChange={(e) =>
                          handleInputChange("ifta", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Form.Group>
                    <Form.Label>Vehicle has LYTX?</Form.Label>
                    <Col>
                      <Form.Check
                        inline
                        label="Yes"
                        type="radio"
                        id="option-Yes3"
                        name="lytx"
                        value="Yes"
                        checked={form.lytx === "Yes"}
                        onChange={(e) =>
                          handleInputChange("lytx", e.target.value)
                        }
                      />
                      <Form.Check
                        inline
                        label="No"
                        type="radio"
                        id="option-No3"
                        name="lytx"
                        value="No"
                        checked={form.lytx === "No"}
                        onChange={(e) =>
                          handleInputChange("lytx", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col md={6} sm={6}>
                  <Form.Label>Year</Form.Label>
                  <Form.Control
                    as='select'
                    name="year"
                    value={form.year}
                    onChange={(e) => handleInputChange("year", e.target.value)}
                    required
                  >
                    <option value=''>Select Year</option>
                    {yearOptions.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
                <Col md={6} sm={6}>
                  <Form.Label>Make</Form.Label>
                  <Form.Control
                    type="text"
                    name="make"
                    value={form.make}
                    onChange={(e) => handleInputChange("make", e.target.value)}
                    required
                  />
                </Col>
                <Col md={6} sm={6}>
                  <Form.Label>Model</Form.Label>
                  <Form.Control
                    type="text"
                    name="model"
                    value={form.model}
                    onChange={(e) => handleInputChange("model", e.target.value)}
                    required
                  />
                </Col>
                <Col md={6} sm={6}>
                  <Form.Label>VIN</Form.Label>
                  <Form.Control
                    type="text"
                    name="vin"
                    value={form.vin}
                    onChange={(e) => handleInputChange("vin", e.target.value)}
                    required
                  />
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col>
                  <Form.Label>Location's Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    value={form.address}
                    onChange={(e) =>
                      handleInputChange("address", e.target.value)
                    }
                    required
                  />
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col>
                  <Form.Label>Check-In Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    type="text"
                    name="checkinnotes"
                    value={form.checkinnotes}
                    onChange={(e) =>
                      handleInputChange("checkinnotes", e.target.value)
                    }
                    required
                  />
                </Col>
              </Row>

              <Row style={rowStyle}>
                <Form.Text>
                  Take pictures of the vehicle in positions 1, 2, 3, and 4 as
                  shown in the example. Images 5 and 6 should be taken inside
                  the cab of the vehicle. Then upload those pictures to Images
                </Form.Text>
              </Row>

              <Row>
                <Col xl={3} lg={3} md={3} sm={6}>
                  <div className='image-container'>
                  <Card.Img src={truckfront} alt="vehicle-picture-front" />
                    <Button variant='link' className='question-icon' onClick={() => handlePopUpImage(samplefront)}>
                      <AiOutlineQuestionCircle/>
                    </Button>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={3} sm={6}>
                  <div className='image-container'>
                  <Card.Img src={truckright} alt="vehicle-picture-right" />
                  <Button variant='link' className='question-icon' onClick={() => handlePopUpImage(sampleright)}>
                    <AiOutlineQuestionCircle/>
                  </Button>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={3} sm={6}>
                  <div className='image-container'>
                  <Card.Img src={truckback} alt="vehicle-picture-back" />
                  <Button variant='link' className='question-icon' onClick={() => handlePopUpImage(sampleback)}>
                    <AiOutlineQuestionCircle/>
                  </Button>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={3} sm={6}>
                  <div className='image-container'>
                  <Card.Img src={truckleft} alt="vehicle-picture-left" />
                  <Button variant='link' className='question-icon' onClick={() => handlePopUpImage(sampleleft)}>
                    <AiOutlineQuestionCircle/>
                  </Button>
                  </div>
                </Col>
              </Row>

              <Row className='justify-content-center'>
                <Col xl={3} lg={3} md={3} sm={6}>
                  <div className='image-container'>
                  <Card.Img src={truckinsideleft} alt="vehicle-picture-left" />
                  <Button variant='link' className='question-icon' onClick={() => handlePopUpImage(sampleinsideleft)}>
                    <AiOutlineQuestionCircle/>
                  </Button>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={3} sm={6}>
                  <div className='image-container'>
                  <Card.Img src={truckinsideright} alt="vehicle-picture-right" />
                  <Button variant='link' className='question-icon' onClick={() => handlePopUpImage(sampleinsideright)}>
                    <AiOutlineQuestionCircle/>
                  </Button>
                  </div>
                </Col>
                
              </Row>

              <Modal show={showPopUpModal} onHide={() => setShowPopUpModal(false)}  className="popup-modal">
                <Modal.Header closeButton>
                  {/* <Modal.Title>Front Image Example</Modal.Title> */}
                </Modal.Header>
                <Modal.Body className="popup-modal-body">
                  <Image src={popUpImage} alt="popup-image" className="popup-image"/>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowPopUpModal(false)}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>



              <Row style={rowStyle}>
                <Form.Group>
                  <Form.Label>Images</Form.Label>
                  <div
                    {...getRootPropsImages({
                      className: "dropzone",
                      style: isDragActiveImages
                        ? { ...dropzoneStyle, ...dropzoneActiveStyle }
                        : dropzoneStyle,
                    })}
                  >
                    <input {...getInputPropsImages()} />
                    {isDragActiveImages ? (
                      <p>Drop image files here</p>
                    ) : (
                      <p>
                        Drag and drop image files here, or click to select image
                        files
                      </p>
                    )}
                  </div>
                  {images.length > 0 && (
                    <ul>
                      {images.map((image, index) => (
                        <li key={index}>
                          {image.name}{" "}
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleRemoveImage(index)}
                          >
                            Remove
                          </Button>
                        </li>
                      ))}
                    </ul>
                  )}
                </Form.Group>
              </Row>
              <Row style={rowStyle}>
                <Form.Group>
                  <Form.Label>Registration</Form.Label>
                  <div
                    {...getRootPropsRegistrations({
                      className: "dropzone",
                      style: isDragActiveRegistrations
                        ? { ...dropzoneStyle, ...dropzoneActiveStyle }
                        : dropzoneStyle,
                    })}
                  >
                    <input {...getInputPropsRegistrations()} />
                    {isDragActiveRegistrations ? (
                      <p>Drop registration file(s) here</p>
                    ) : (
                      <p>
                        Drag and drop registration file(s) here, or click to
                        select file(s)
                      </p>
                    )}
                  </div>
                  {registrations.length > 0 && (
                    <ul>
                      {registrations.map((registration, index) => (
                        <li key={index}>
                          {registration.name}{" "}
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleRemoveRegistration(index)}
                          >
                            Remove
                          </Button>
                        </li>
                      ))}
                    </ul>
                  )}
                </Form.Group>
              </Row>
              <Row style={rowStyle}>
                <Form.Group>
                  <Form.Label>Annual Inspection</Form.Label>
                  <div
                    {...getRootPropsInspections({
                      className: "dropzone",
                      style: isDragActiveInspections
                        ? { ...dropzoneStyle, ...dropzoneActiveStyle }
                        : dropzoneStyle,
                    })}
                  >
                    <input {...getInputPropsInspections()} />
                    {isDragActiveInspections ? (
                      <p>Drop inspection file(s) here</p>
                    ) : (
                      <p>
                        Drag and drop inspection file(s) here, or click to
                        select file(s)
                      </p>
                    )}
                  </div>
                  {inspections.length > 0 && (
                    <ul>
                      {inspections.map((inspection, index) => (
                        <li key={index}>
                          {inspection.name}{" "}
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleRemoveInspection(index)}
                          >
                            Remove
                          </Button>
                        </li>
                      ))}
                    </ul>
                  )}
                </Form.Group>
              </Row>
              <Row style={rowStyle}>
                <Form.Group>
                  <Form.Label>Lease/Rental Agreement</Form.Label>
                  <div
                    {...getRootPropsLeases({
                      className: "dropzone",
                      style: isDragActiveLeases
                        ? { ...dropzoneStyle, ...dropzoneActiveStyle }
                        : dropzoneStyle,
                    })}
                  >
                    <input {...getInputPropsLeases()} />
                    {isDragActiveLeases ? (
                      <p>Drop lease/rental file(s) here</p>
                    ) : (
                      <p>
                        Drag and drop lease/rental file(s) here, or click to
                        select file(s)
                      </p>
                    )}
                  </div>
                  {leases.length > 0 && (
                    <ul>
                      {leases.map((lease, index) => (
                        <li key={index}>
                          {lease.name}{" "}
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleRemoveLease(index)}
                          >
                            Remove
                          </Button>
                        </li>
                      ))}
                    </ul>
                  )}
                </Form.Group>
              </Row>

              <Row style={rowStyle}>
                <Col lg={3} md={4} sm={4}>
                  <Form.Label>Check-In Date</Form.Label>
                  <Form.Control
                    type='date'
                    name='checkindate'
                    value={form.checkindate}
                    onChange={(e) =>
                      handleInputChange("checkindate", e.target.value)
                    }
                    required

                  />
                </Col>
              </Row>

              <Row style={rowStyle}> 
                <Col>
                    <Button 
                    variant='primary'
                    type='button'
                    onClick={handleSubmit}
                    >Check-In
                    </Button>
                    <Button 
                    variant='danger'
                    type='button'
                    onClick={handleReset}
                    className='mx-2'
                    >Reset Form
                    </Button>
                </Col>
              </Row>

            </Form>
          </Card.Body>
        </Card>

        <Card style={rowStyle}>
          <Card.Body>
            <Form.Label className="fw-bold">VEHICLE CHECK-OUT</Form.Label>
            <Form>
              <Row>
                <Col xl={6} lg={6} md={6} sm={6}> 
                    <Form.Label>Odometer Out</Form.Label>
                    <Form.Control 
                      type='number'
                      name='odometerout'
                      value={form.odometerout}
                      onChange={(e) => handleInputChange("odometerout", e.target.value)}
                      required
                    />
                </Col>
                <Col xl={6} lg={6} md={6} sm={6}> 
                    <Form.Label>Check-Out Date</Form.Label>
                    <Form.Control 
                      type='date'
                      name='checkoutdate'
                      value={form.checkoutdate}
                      onChange={(e) => handleInputChange("checkoutdate", e.target.value)}
                      required
                    />
                </Col>
              </Row>

              <Row style={rowStyle}>
                <Form.Group>
                  <Form.Label>Check-Out Images</Form.Label>
                  <div
                    {...getRootPropsImagesOut({
                      className: "dropzone",
                      style: isDragActiveImagesOut
                        ? { ...dropzoneStyle, ...dropzoneActiveStyle }
                        : dropzoneStyle,
                    })}
                  >
                    <input {...getInputPropsImagesOut()} />
                    {isDragActiveImagesOut ? (
                      <p>Drop image files here</p>
                    ) : (
                      <p>
                        Drag and drop image files here, or click to select image
                        files
                      </p>
                    )}
                  </div>
                  {imagesOut.length > 0 && (
                    <ul>
                      {imagesOut.map((image, index) => (
                        <li key={index}>
                          {image.name}{" "}
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleRemoveImageOut(index)}
                          >
                            Remove
                          </Button>
                        </li>
                      ))}
                    </ul>
                  )}
                </Form.Group>
              </Row>

              <Row style={rowStyle}> 
                <Col>
                    <Button 
                    variant='primary'
                    type='button'
                    onClick={handleSubmit}
                    >Check-Out
                    </Button>
                    <Button 
                    variant='danger'
                    type='button'
                    onClick={handleReset}
                    className='mx-2'
                    >Reset Form
                    </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Card.Body>
    </Card>
  );

};

export default ThirdPartyVehicleForm
;