import { Container, Card } from "react-bootstrap"
import { FaPlusCircle } from "react-icons/fa";
import { Link } from "react-router-dom";


const Manifest = () => {
    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <Card.Title>Manifest <Link className="float-end" to="/manifest-form"><FaPlusCircle /> Form</Link></Card.Title>
                </Card.Header>
                <Card.Body>
                  
                </Card.Body>
            </Card>
        </Container>
    )
}

export default Manifest;