import React, { useState, useEffect, useCallback } from 'react'
import AlertDisplay from '../../components/AlertDisplay'
import Api from '../../utils/Api'
import { useAuth } from '../../context/auth';
import { Badge, Button, Card, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import LogisticsLoading from '../../components/LogisticsLoading'
import DataDisplay from '../../components/DataDisplay';
import { useLocation, useNavigate } from 'react-router-dom';
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { FaDollarSign } from 'react-icons/fa';
import { MdAutorenew } from "react-icons/md";

function formatAsUSD(number) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    }).format(number);
}

function formatAsNanoUSD(number) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 9
    }).format(number);
}

function InvoiceDisplay({ invoice, setInvoice }) {
    const labelStyle = {
        display: 'block',
        fontWeight: 'bold',
        fontSize: '1.1em'
    }

    const colStyle = {
        margin: '0.5em 1em'
    }

    const tableHeaderStyle = {
        backgroundColor: '#f9f9f9',
        textAlign: 'left'
    }

    // return <pre>{JSON.stringify(invoice, "", 2)}</pre>
    return (
        <Container style={{ padding: '0', minWidth: '800px', backgroundColor: 'transparent' }}>
            <Card>
                <Card.Header style={{ alignItems: 'center', backgroundColor: '#f9f9f9', borderBottom: '2px solid #ccc' }}>
                    <Card.Title>
                        <h3>
                            <Row>
                                <Col>{invoice?.title}</Col>
                                <Col style={{ textAlign: 'right' }}>
                                    Status
                                    <Badge
                                        bg={
                                            invoice?.status === 'Draft' ? 'secondary' :
                                                invoice?.status === 'Sent' ? 'primary' :
                                                    invoice?.status === 'Remind' ? 'warning' :
                                                        invoice?.status === 'Cancelled' ? 'danger' :
                                                            invoice?.status === 'Paid' ? 'success' :
                                                                'dark'
                                        }
                                    >
                                        {invoice?.status}
                                    </Badge>
                                </Col>
                            </Row>
                        </h3>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Table responsive>
                        <tbody>
                            <tr>
                                <td>
                                    <u>Bill To: </u> <br />
                                    <strong>{invoice?.toFullName}</strong> <br />
                                    {invoice?.companyName} <br />
                                    {invoice?.fullAddress} <br />
                                    {invoice?.toEmail}
                                </td>
                                <td>
                                    <u>From: </u> <br />
                                    <strong>SYBR, LLC</strong> <br />
                                    1 Center Sq. Suite 203C Hanover PA 17331 USA <br />
                                    billing@sybr.org
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Row>
                        <Col style={colStyle}>
                            <label style={labelStyle}>Date </label>{invoice?.sentDate}
                        </Col>
                        <Col style={colStyle}>
                            <label style={labelStyle}>Terms</label>{invoice?.terms}
                        </Col>
                        <Col style={colStyle}>
                            <label style={labelStyle}>Due Date</label>{invoice?.dueDate}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={colStyle}>
                            <label style={labelStyle}>Period Start</label>
                            {invoice?.periodStart}
                        </Col>
                        <Col style={colStyle}>
                            <label style={labelStyle}>Period End</label>
                            {invoice?.periodEnd}
                        </Col>
                        <Col style={colStyle}>
                            <label style={labelStyle}>Unique Shipments</label>
                            {invoice?.uniqueShipmentCt.toLocaleString('en-US')}
                        </Col>
                    </Row>
                    <Table responsive>
                        <thead>
                            <tr style={{ textAlign: 'center' }}>
                                <th colSpan={3} style={tableHeaderStyle}>Mail Carrier TMS</th>
                            </tr>
                            <tr>
                                <th>Item</th>
                                <th></th>
                                <th style={{ textAlign: 'right' }}>Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Monthly Subscription</td>
                                <td></td>
                                <td style={{ textAlign: 'right' }}>{formatAsUSD(invoice?.tmsMonthlySubscriptionCost)}</td>
                            </tr>
                            <tr>
                                <td>{invoice?.uniqueShipmentCt.toLocaleString('en-US')} Shipments</td>
                                <td style={{ textAlign: 'center' }}>{formatAsUSD(invoice?.ratePerShipment)} Cost per Shipment</td>
                                <td style={{ textAlign: 'right' }}>{formatAsUSD(invoice?.mailcarriertmsCost)}</td>
                            </tr>
                            <tr>
                                <th>Mail Carrier TMS Subtotal</th>
                                <th></th>
                                <th style={{ textAlign: 'right' }}>{formatAsUSD(invoice?.mailcarriertmsTotal)}</th>
                            </tr>
                        </tbody>
                    </Table>
                    <Table style={{ textAlign: 'center' }}>
                        <thead>
                            <tr>
                                <th colSpan={6} style={tableHeaderStyle}>EDI Messaging</th>
                            </tr>
                            <tr>
                                <th style={{ textAlign: 'left' }}>Type</th>
                                <th>204</th>
                                <th>214</th>
                                <th>990</th>
                                <th>210</th>
                                <th style={{ textAlign: 'right' }}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: 'left' }}>Message Ct.</td>
                                <td>{invoice?.edi204ct.toLocaleString('en-US')}</td>
                                <td>{invoice?.edi214ct.toLocaleString('en-US')}</td>
                                <td>{invoice?.edi990ct.toLocaleString('en-US')}</td>
                                <td>{invoice?.edi210ct.toLocaleString('en-US')}</td>
                                <td style={{ textAlign: 'right' }}>{invoice?.totalMsgCt.toLocaleString('en-US')}</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'left' }}>Character Ct.</td>
                                <td>{invoice?.edi204CharCt.toLocaleString('en-US')}</td>
                                <td>{invoice?.edi214CharCt.toLocaleString('en-US')}</td>
                                <td>{invoice?.edi990CharCt.toLocaleString('en-US')}</td>
                                <td>{invoice?.edi210CharCt.toLocaleString('en-US')}</td>
                                <td style={{ textAlign: 'right' }}>{invoice?.totalCharCt.toLocaleString('en-US')}</td>
                            </tr>
                            <tr>
                                <th colSpan={3} style={{ textAlign: 'left' }}>Item</th>
                                <th colSpan={3} style={{ textAlign: 'right' }}>Cost</th>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{ textAlign: 'left' }}>Messaging</td>
                                <td style={{ textAlign: 'center' }}>{invoice?.totalCharCt.toLocaleString('en-US')} Char.</td>
                                <td style={{ textAlign: 'center' }}>~{formatAsNanoUSD(invoice?.ediCostPerCharacter)} Per Char.</td>
                                <td colSpan={2} style={{ textAlign: 'right' }}>{formatAsUSD(invoice?.ediTransmissionCost)}</td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{ textAlign: 'left' }}>Trading Partners</td>
                                <td style={{ textAlign: 'center' }}>{(invoice?.ediTradingPartners.toLocaleString('en-US'))} Partner(s)</td>
                                <td style={{ textAlign: 'center' }}>{formatAsUSD(invoice?.costPerTradingPartner)} Per Partner</td>
                                <td colSpan={2} style={{ textAlign: 'right' }}>{formatAsUSD(invoice?.ediTradingPartnersCost)}</td>
                            </tr>
                            <tr>
                                <th colSpan={3} style={{ textAlign: 'left' }}>EDI Messaging Subtotal</th>
                                <th colSpan={3} style={{ textAlign: 'right' }}>{formatAsUSD(invoice?.ediTotalCost)}</th>
                            </tr>
                        </tbody>
                    </Table>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th colSpan={2} style={{ backgroundColor: '#f9f9f9', textAlign: 'left' }}>
                                    Infrastructure
                                    {!invoice?.status &&
                                    <span style={{float:'right'}}>To Be Calculated</span>
                                    }
                                </th>
                            </tr>
                            <tr>
                                <th>Item</th>
                                <th style={{ textAlign: 'right' }}>Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Database Services</td>
                                <td style={{ textAlign: 'right' }}>
                                    {/* {formatAsUSD(invoice?.mongodbCost)} */}
                                    <Form.Control
                                        type="text"
                                        step="0.01"
                                        min="0"
                                        style={{ textAlign: 'right', paddingRight: '1em' }}
                                        value={invoice?.mongodbCost}
                                        onChange={e => setInvoice({ ...invoice, mongodbCost: e.target.value })}
                                        readOnly={['Draft'].includes(invoice.status) ? false : true}
                                        disabled={['Draft'].includes(invoice.status) ? false : true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Amazon Web Services</td>
                                <td style={{ textAlign: 'right' }}>
                                    {/* {formatAsUSD(invoice?.amazonWebServicesCost)} */}
                                    <Form.Control
                                        width={'100px'}
                                        type="text"
                                        step="0.01"
                                        min="0"
                                        style={{ textAlign: 'right', paddingRight: '1em' }}
                                        value={invoice?.amazonWebServicesCost}
                                        onChange={e => setInvoice({ ...invoice, amazonWebServicesCost: e.target.value })}
                                        readOnly={['Draft'].includes(invoice.status) ? false : true}
                                        disabled={['Draft'].includes(invoice.status) ? false : true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Google Cloud Platforms</td>
                                <td style={{ textAlign: 'right' }}>
                                    {/* {formatAsUSD(invoice?.googleCloudPlatformsCost)} */}
                                    <Form.Control
                                        type="text"
                                        step="0.01"
                                        min="0"
                                        style={{ textAlign: 'right' }}
                                        value={invoice?.googleCloudPlatformsCost}
                                        onChange={e => setInvoice({ ...invoice, googleCloudPlatformsCost: e.target.value })}
                                        readOnly={['Draft'].includes(invoice.status) ? false : true}
                                        disabled={['Draft'].includes(invoice.status) ? false : true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>Infrastructure Subtotal</th>
                                <th style={{ textAlign: 'right' }}>
                                    {formatAsUSD(invoice?.infrastructureCost)}
                                </th>
                            </tr>
                        </tbody>
                    </Table>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th colSpan={3} style={tableHeaderStyle}>
                                    Invoice Summary
                                </th>
                            </tr>
                            <tr>
                                <th>Item</th>
                                <th></th>
                                <th style={{ textAlign: 'right' }}>Cost</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontStyle: 'italic' }}>
                            <tr>
                                <td>Mail Carrier TMS Subtotal</td>
                                <td></td>

                                <td style={{ textAlign: 'right' }}>{formatAsUSD(invoice?.mailcarriertmsCost)}</td>
                            </tr>
                            <tr>
                                <td>EDI Messaging Subtotal</td>
                                <td></td>
                                <td style={{ textAlign: 'right' }}>{formatAsUSD(invoice?.ediTotalCost)}</td>
                            </tr>
                            <tr>
                                <td>Intrastructure Subtotal</td>
                                <td></td>
                                <td style={{ textAlign: 'right' }}>{formatAsUSD(invoice?.infrastructureCost)}</td>
                            </tr>
                            <tr>
                                <th>Subtotals' Total</th>
                                <th></th>
                                <th style={{ textAlign: 'right' }}>{formatAsUSD(invoice?.taxableAmount)}</th>
                            </tr>
                        </tbody>
                    </Table>
                    <Table responsive>
                        <tbody>
                            <tr>
                                <td><b>Subtotals' Total</b></td>
                                <td></td>
                                <td style={{ textAlign: 'right' }}><b>{formatAsUSD(invoice?.taxableAmount)}</b></td>
                            </tr>
                            <tr>
                                <td>PA State Tax</td>
                                <td>{formatAsUSD(invoice?.taxRate)} x {formatAsUSD(invoice?.taxableAmount)}</td>
                                <td style={{ textAlign: 'right' }}>{formatAsUSD(invoice?.totalTax)}</td>
                            </tr>
                            <tr>
                                <th style={{ color: '#263d7a', fontSize: '1.2em' }}>Amount Due</th>
                                <th></th>
                                <th style={{ color: '#263d7a', fontSize: '1.2em', textAlign: 'right' }}>{formatAsUSD(invoice?.bottomline)}</th>
                            </tr>
                        </tbody>
                    </Table>
                    <Table responsive>
                        <tbody>
                            <tr>
                                <td>
                                    Please make checks payable to SYBR, LLC and mail them to our corporate office at:
                                    <br />
                                    SYBR, LLC <br />
                                    1 Center Square, Suite 203C <br />
                                    Hanover, PA 17331
                                </td>
                                <th style={{ textAlign: 'right' }}>
                                    Thank you for your business.
                                </th>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Header>
                    <Card.Title>
                        Shipments: {invoice?.uniqueShipments.length}
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <pre>
                        <Row className='justify-content-center'>
                            {invoice?.uniqueShipments.map((ShipmentId, i) => (
                                <Col xs="auto" key={i} style={{ border: '1px solid #f9f9f9', margin: '0.1em' }}>
                                    {i + 1}|<a
                                        href={`/loadtender/dashboard/v2?search=${ShipmentId}`}
                                        target="_blank"
                                        rel="noreferrer">{ShipmentId}</a>
                                </Col>
                            ))}
                        </Row>
                    </pre>
                </Card.Body>
            </Card>
        </Container>
    )
}

const initialInvoice = {

}

const initialBillTo = {
    toFullName: '',
    toEmail: '',
    companyName: '',
    fullAddress: '',
}

const initialSubscription = {
    supportRate: '',
    ratePerShipment: '',
    contractTerm: '',
    contractEffective: '',
}

const modalStyle = {
    zIndex: 10001
}

function CreateNewInvoiceModal({
    createNewInvoiceModalState,
    handleSaveDraft,
    periodStartState,
    periodEndState,
    newInvoiceState,
    periodLoadedState
}) {
    const { createNewInvoiceModal, setCreateNewInvoiceModal } = createNewInvoiceModalState
    const { newInvoice, setNewInvoice } = newInvoiceState
    const { periodStart, setPeriodStart } = periodStartState;
    const { periodEnd, setPeriodEnd } = periodEndState;
    const { periodLoaded, setPeriodLoaded } = periodLoadedState
    return (
        <Modal show={createNewInvoiceModal} size='fullscreen' style={modalStyle}>
            <Modal.Header closeButton onClick={() => setCreateNewInvoiceModal(false)}>
                <h3>Draft Invoice</h3>
            </Modal.Header>
            <Modal.Body>
                <Container style={{ padding: "1em" }}>
                    <Row>
                        <Col xs="auto">
                            Period Start
                            <Form.Control type="datetime-local" value={periodStart} onChange={(e) => { setPeriodStart(e.target.value); }} />
                        </Col>
                        <Col xs="auto">
                            Period End
                            <Form.Control type="datetime-local" value={periodEnd} onChange={(e) => { setPeriodEnd(e.target.value); }} />
                        </Col>
                    </Row>
                </Container>
                {periodLoaded ?
                    newInvoice ?
                        <InvoiceDisplay invoice={newInvoice} setInvoice={setNewInvoice} />
                        : 'Nothing found'
                    : <LogisticsLoading message={'Getting usage for period.'} />
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => handleSaveDraft()}>
                    Save Draft
                </Button>
                <Button variant="primary" onClick={() => setPeriodLoaded(false)}>Reload</Button>
                <Button variant="secondary" onClick={() => setCreateNewInvoiceModal(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

function CurrentUsageModal({ currentInvoiceModalState, invoiceState, usageLoadedState }) {
    const { currentInvoiceModal, setCurrentInvoiceModal } = currentInvoiceModalState
    const { usageLoaded, setUsageLoaded } = usageLoadedState
    const { invoice, setInvoice } = invoiceState;

    return (
        <Modal show={currentInvoiceModal} size='fullscreen' style={modalStyle}>
            <Modal.Header closeButton onClick={() => setCurrentInvoiceModal(false)}>
                <h3>Current Period Usage</h3>
            </Modal.Header>
            <Modal.Body>
                {usageLoaded ?
                    invoice ?
                        <InvoiceDisplay invoice={invoice} setInvoice={setInvoice} />
                        : 'Nothing found.'
                    :
                    <LogisticsLoading message={'Calculating period usage...'} />
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={() => setUsageLoaded(false)}>
                    Reload
                </Button>
                <Button variant='secondary' onClick={() => setCurrentInvoiceModal(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

function InvoiceModal({
    invoiceModalState,
    invoiceState,
    handleInvoiceActivityToggle,
    handleCancelInvoice,
    handleSendInvoice,
    handleUpdateInvoice,
    handleRemindInvoice,
    invoiceLoadedState,
    handlePaidInvoice
}) {
    const navigate = useNavigate();
    const { invoiceModal, setInvoiceModal } = invoiceModalState;
    const { invoice, setInvoice } = invoiceState
    const { invoiceLoaded, setInvoiceLoaded } = invoiceLoadedState
    return (
        <Modal show={invoiceModal} size={'fullscreen'} style={modalStyle}>
            <Modal.Header closeButton onClick={() => {
                setInvoiceModal(false);
                setInvoice()
                navigate({ search: '' });
                setInvoiceLoaded(false);
            }}>
                <h3>Invoice : {invoice?._id}</h3>
            </Modal.Header>
            {invoiceLoaded
                ?
                <>
                    <Modal.Body>
                        <InvoiceDisplay invoice={invoice} setInvoice={setInvoice} />
                    </Modal.Body>
                    <Modal.Footer>
                        {invoice?.status}
                        {invoice.isActive &&
                            <>
                                {['Sent', 'Remind'].includes(invoice.status) &&
                                    <Button
                                        variant={'success'}
                                        onClick={() => handlePaidInvoice(invoice?._id)}
                                        disabled={['Sent', 'Remind'].includes(invoice.status) ? false : true}
                                    >
                                        Paid
                                    </Button>
                                }
                                {['Sent', 'Remind'].includes(invoice.status) &&
                                    <Button
                                        variant={'warning'}
                                        onClick={() => handleRemindInvoice(invoice)}
                                    >
                                        Remind
                                    </Button>
                                }
                                {['Sent', 'Remind'].includes(invoice.status) &&
                                    <Button
                                        variant={'danger'}
                                        onClick={() => handleCancelInvoice(invoice?._id)}
                                    >
                                        Cancel
                                    </Button>
                                }
                                {['Draft'].includes(invoice.status) &&
                                    <Button
                                        variant={'primary'}
                                        onClick={() => handleSendInvoice(invoice)}
                                        disabled={invoice.status === 'Draft' ? false : true}
                                    >
                                        Send Invoice
                                    </Button>
                                }
                                {['Draft'].includes(invoice.status) &&
                                    <Button
                                        variant={'primary'}
                                        onClick={() => handleUpdateInvoice(invoice._id)}
                                        disabled={invoice.status === 'Draft' ? false : true}
                                    >
                                        Update
                                    </Button>
                                }

                            </>
                        }
                        {invoice.status === 'Draft' &&
                            <>
                                <Button
                                    variant={invoice.isActive ? 'success' : 'warning'}
                                    onClick={() => handleInvoiceActivityToggle(invoice?._id)}
                                    disabled={invoice.status === 'Draft' ? false : true}
                                >
                                    {invoice.isActive ? 'Active' : 'Inactive'}
                                </Button>

                            </>
                        }
                        <Button variant="secondary" onClick={() => {
                            setInvoiceModal(false);
                            setInvoice()
                            navigate({ search: '' });
                            setInvoiceLoaded(false);
                        }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </>
                :
                <LogisticsLoading message={'Loading invoice...'} />
            }

        </Modal>
    )
}

function Invoices({
    invoiceModalState,
    currentInvoiceModalState,
    createNewInvoiceModalState,
    invoiceState,
    invoicesState,
    cleanInvoices,
    invoicesLoadedState,
    invoiceLoadedState,
    showBillToModalState,
}) {

    const { invoices } = invoicesState;
    const { invoicesLoaded } = invoicesLoadedState

    const { setInvoiceModal } = invoiceModalState
    const { setCurrentInvoiceModal } = currentInvoiceModalState
    const { setCreateNewInvoiceModal } = createNewInvoiceModalState
    const { setShowBillToModal } = showBillToModalState

    const badgeStyle = {
        margin: '0.1em',
        color: 'black',
        cursor: 'pointer',
        backgroundColor: '#ccc'
    }
    return (
        <>
            <Row style={{ backgroundColor: '#f9f9f9', borderBottom: '2px solid #ccc', padding: '0.25em' }}>
                <Col>BILLING DASHBOARD</Col>
                <Col style={{ textAlign: 'right' }}>
                    <Badge bg="info" style={badgeStyle} onClick={() => setShowBillToModal(true)}>< MdAutorenew /> Manage Subscription</Badge>
                    <Badge bg="warning" style={badgeStyle} onClick={() => setCurrentInvoiceModal(true)}><FaDollarSign /> Current Usage</Badge>
                    <Badge
                        bg="primary"
                        style={badgeStyle}
                        onClick={() => setCreateNewInvoiceModal(true)}>
                        <LiaFileInvoiceSolid /> New Invoice
                    </Badge>
                </Col>
            </Row>
            {invoicesLoaded ?
                <DataDisplay
                    dataSource={cleanInvoices}
                    lsKey={'@mctms-billing-invoices'}
                    sourceName={`MCTMS-Invoices`}
                    urlPath={`?InvoiceId=`}
                    urlKey={'_id'}
                    popKeys={['_id']}
                />
                :
                <LogisticsLoading message={'Loading invoices...'} />
            }
        </>
    )
}

function BillToModal({ showBillToModalState, handleSaveBillTo, billtoState, subscriptionState }) {
    const { showBillToModal, setShowBillToModal } = showBillToModalState;
    const { billto, setBillTo } = billtoState;
    const { subscription, setSubscription } = subscriptionState;

    return (
        <Modal show={showBillToModal} size="xl">
            <Modal.Header closeButton onClick={() => setShowBillToModal(false)}>
                <h3>Subscription</h3>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <h5>Bill To</h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Full Name
                        <Form.Control value={billto.toFullName} type="text" name="fullName" onChange={e => setBillTo({ ...billto, toFullName: e.target.value })} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Company Name
                        <Form.Control value={billto.companyName} type="text" name="companyName" onChange={e => setBillTo({ ...billto, companyName: e.target.value })} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Full Address
                        <Form.Control value={billto.fullAddress} type="text" name="fullAddress" onChange={e => setBillTo({ ...billto, fullAddress: e.target.value })} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Bill to Email
                        <Form.Control value={billto.toEmail} type="email" name="toEmail" onChange={e => setBillTo({ ...billto, toEmail: e.target.value })} />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <h5>Subscription</h5>
                    </Col>
                </Row>
                <Row>
                    <Col xs="auto">
                        Contract Effective
                        <Form.Control
                            value={subscription?.contractEffective}
                            type="date"
                            onChange={e => setSubscription({ ...subscription, contractEffective: e.target.value })}
                        />
                    </Col>
                    <Col xs="auto">
                        Contract Term
                        <Form.Select value={subscription?.contractTerm} onChange={e => setSubscription({ ...subscription, contractTerm: e.target.value })}>
                            <option></option>
                            <option>Monthly</option>
                            <option>Yearly</option>
                        </Form.Select>
                    </Col>
                    <Col xs="auto">
                        Payment Term
                        <Form.Select value={subscription?.paymentTerm} onChange={e => setSubscription({ ...subscription, paymentTerm: e.target.value })}>
                            <option></option>
                            <option value="Net 30">Net 30</option>
                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                    <Col xs="auto">
                        Monthly
                        <Form.Control
                            value={subscription?.monthlyCost}
                            min="100"
                            type="number" step="25"
                            onChange={e => setSubscription({ ...subscription, monthlyCost: e.target.value })} />
                    </Col>
                    <Col xs="auto">
                        Rate Per Shipment
                        <Form.Control
                            value={subscription?.ratePerShipment}
                            min={subscription?.contractTerm === 'Montly' ? '' : ''}
                            type="number" step=".01"
                            onChange={e => setSubscription({ ...subscription, ratePerShipment: e.target.value })}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs="auto">
                        EDI Trading Partners
                        <Form.Control
                            value={subscription?.ediTradingPartners}
                            min="1"
                            type="number" step="1"
                            onChange={e => setSubscription({ ...subscription, ediTradingPartners: e.target.value })}
                        />
                    </Col>
                    <Col xs="auto">
                        Cost Per Trading Partner
                        <Form.Control
                            value={subscription?.costPerTradingPartner}
                            min="1"
                            type="number" step="1"
                            onChange={e => setSubscription({ ...subscription, costPerTradingPartner: e.target.value })}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs="auto">
                        Tax Rate
                        <Form.Control
                            value={subscription?.taxRate}
                            min="0"
                            type="number" step=".01"
                            onChange={e => setSubscription({ ...subscription, taxRate: e.target.value })}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => handleSaveBillTo()}>Save</Button>
                <Button variant="secondary" onClick={() => setShowBillToModal(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

function Billing() {
    const { user, idToken } = useAuth();
    const location = useLocation();

    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    const [invoice, setInvoice] = useState();
    const invoiceState = { invoice, setInvoice }
    const [newInvoice, setNewInvoice] = useState();
    const newInvoiceState = { newInvoice, setNewInvoice }

    const [invoicesLoaded, setInvoicesLoaded] = useState();
    const invoicesLoadedState = { invoicesLoaded, setInvoicesLoaded }

    const [usageLoaded, setUsageLoaded] = useState();
    const usageLoadedState = { usageLoaded, setUsageLoaded }
    const [invoiceLoaded, setInvoiceLoaded] = useState();
    const invoiceLoadedState = { invoiceLoaded, setInvoiceLoaded }

    const [invoices, setInvoices] = useState();
    const invoicesState = { invoices, setInvoices }
    const [cleanInvoices, setCleanInvoices] = useState();

    const [invoiceModal, setInvoiceModal] = useState();
    const invoiceModalState = { invoiceModal, setInvoiceModal }

    const [currentInvoiceModal, setCurrentInvoiceModal] = useState();
    const currentInvoiceModalState = { currentInvoiceModal, setCurrentInvoiceModal }

    const [createNewInvoiceModal, setCreateNewInvoiceModal] = useState();
    const createNewInvoiceModalState = { createNewInvoiceModal, setCreateNewInvoiceModal }

    const [periodLoaded, setPeriodLoaded] = useState();
    const periodLoadedState = { periodLoaded, setPeriodLoaded }

    const [periodStart, setPeriodStart] = useState('2023-12-01T00:00');
    const periodStartState = { periodStart, setPeriodStart }

    const [periodEnd, setPeriodEnd] = useState('2023-12-31T23:59');
    const periodEndState = { periodEnd, setPeriodEnd }

    const [billto, setBillTo] = useState(initialBillTo);
    const billtoState = { billto, setBillTo };
    const [showBillToModal, setShowBillToModal] = useState();
    const showBillToModalState = { showBillToModal, setShowBillToModal }
    const [didFetchBillTo, setDidFetchBillTo] = useState();


    const [subscription, setSubscription] = useState(initialSubscription);
    const subscriptionState = { subscription, setSubscription };
    const [didFetchSubscription, setDidFetchSubscription] = useState();

    const fetchInvoicesData = useCallback(async () => {
        try {
            const invoicesData = await Api.get('/billing/invoices', idToken);
            setInvoices(invoicesData);
            const cleanInvoiceData = invoicesData.map((invoice => ({
                '_id': invoice._id,
                'Customer': invoice?.toFullName,
                'To Email': invoice?.toEmail,
                'Company': invoice?.companyName,
                'Status': invoice?.status,
                'Period Start': invoice.periodStart,
                'Period End': invoice.periodEnd,
                'Bottom Line': formatAsUSD(invoice?.bottomline),
                'Created': invoice?.createdUtcTimestamp,
                'Active': invoice?.isActive ? '✅ Active' : '❌ Inactive',
            })))
            setCleanInvoices(cleanInvoiceData)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occurred fetching invoices.' }]);
        } finally {
            setInvoicesLoaded(true);
        }
    }, [alerts, setAlerts, idToken]);

    async function handleInvoiceActivityToggle(invoiceId) {
        try {
            await Api.patch(`/billing/invoices/${invoiceId}/isActive`, { isActive: !invoice.isActive }, idToken);
            setInvoiceLoaded(false)
            setInvoicesLoaded(false)
            setAlerts([...alerts, { variant: 'success', message: 'Updated activity.', }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error updating activity.', }])
        }
    }

    async function handleRemindInvoice(invoice) {
        try {
            await Api.get(`/billing/invoices/${invoice._id}/remind`, idToken);
            setInvoiceLoaded(false)
            setInvoicesLoaded(false)
            setAlerts([...alerts, { variant: 'success', message: 'Sent invoice reminder.', }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error sending reminder.', }])
        }
    }

    async function handleSendInvoice(invoice) {
        try {
            await Api.get(`/billing/invoices/${invoice._id}/send`, idToken);
            setInvoiceLoaded(false)
            setInvoicesLoaded(false)
            setAlerts([...alerts, { variant: 'success', message: 'Sent invoice.', }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error sending invoice.', }])
        }
    }

    async function handleCancelInvoice(invoiceId) {
        try {
            await Api.get(`/billing/invoices/${invoiceId}/cancel`, idToken);
            setInvoiceLoaded(false)
            setInvoicesLoaded(false)
            setAlerts([...alerts, { variant: 'success', message: 'Invoice cancelation sent.', }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error sending cncalation notice.', }])
        }
    }

    async function handlePaidInvoice(invoiceId) {
        try {
            await Api.get(`/billing/invoices/${invoiceId}/paid`, idToken);
            setInvoiceLoaded(false)
            setInvoicesLoaded(false)
            setAlerts([...alerts, { variant: 'success', message: 'Invoice status updated to Paid.', }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error updating status.', }])
        }
    }

    async function handleSendDraft() {
        try {
            const query = {
                email: user.email,
                start: periodStart,
                end: periodEnd
            }
            const queryStr = new URLSearchParams(query)
            await Api.get(`/billing/send-draft?${queryStr}`, idToken);
            setAlerts([...alerts, { variant: 'success', message: `Draft sent to ${user.email}` }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occurred sending draft' }])
        }
    }

    async function handleUpdateInvoice(invoiceId) {
        try {
            await Api.post(`/billing/invoices/${invoiceId}/update`, invoice, idToken);
            setInvoiceLoaded(false)
            setInvoicesLoaded(false)
            setAlerts([...alerts, { variant: 'success', message: `Draft updated.` }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occurred updating draft' }])
        }
    }

    async function handleSaveDraft() {
        try {
            const query = {
                email: user.email,
                start: periodStart,
                end: periodEnd
            }
            const queryStr = new URLSearchParams(query)
            const draftInvoice = {
                ...newInvoice,
                status: 'Draft',
                createdBy: user.email,
            }
    
            await Api.post(`/billing/invoices?${queryStr}`, draftInvoice, idToken);
            setAlerts([...alerts, { variant: 'success', message: `Draft saved` }])
            setCreateNewInvoiceModal(false)
            setInvoicesLoaded(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured saving draft' }])
        }
    }

    async function handleSaveBillTo() {

        const messages = []
        try {
            await Api.post(`/billing/billto`, billto, idToken)
            messages.push({ variant: 'success', message: 'Updated Bill To' })
        } catch (error) {
            messages.push({ variant: 'warning', message: 'An error updating bill to' })
        }

        try {
            await Api.post(`/billing/subscription`, subscription, idToken)
            messages.push({ variant: 'success', message: 'Updated Subscription' })
        } catch (error) {
            messages.push({ variant: 'warning', message: 'An error occured updating subscription' })
        }

        setAlerts([...alerts, ...messages])

        setInvoiceLoaded(false)
        setInvoicesLoaded(false)
        setUsageLoaded(false)
        setPeriodLoaded(false)
        setDidFetchBillTo(false)
        setDidFetchSubscription(false)
    }

    // invoices 
    useEffect(() => {
        if (!invoicesLoaded) {
            fetchInvoicesData();
        }
    }, [invoicesLoaded, fetchInvoicesData]);

    // invoice
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const invoiceIdParam = queryParams.get('InvoiceId');
        const fetchInvoiceData = async () => {
            try {
                setInvoiceLoaded(false);
                const invoiceData = await Api.get(`/billing/invoices/${invoiceIdParam}`, idToken);
                if (invoiceData?.error) {
                    setInvoiceModal(true)
                } else {
                    setInvoice(invoiceData);
                    setInvoiceModal(true)
                }
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occurred fetching invoice.' }]);
            } finally {
                setInvoiceLoaded(true);
            }
        }

        // Check if InvoiceId exists or changes
        if (!invoiceLoaded && invoiceIdParam) {
            fetchInvoiceData();
        }

    }, [alerts, idToken, invoiceLoaded, location.search]);

    // current usage
    useEffect(() => {
        const fetchUsageData = async () => {
            try {
                const data = await Api.get('/billing/usage', idToken)
                setInvoice(data);
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured.' }])
            } finally {
                setUsageLoaded(true)
            }
        }
        if (!usageLoaded) {
            fetchUsageData();
        }
    }, [usageLoaded, alerts, idToken]);

    // new invoice
    useEffect(() => {
        const fetchNewInvoiceData = async () => {
            try {
                const query = {
                    start: periodStart,
                    end: periodEnd
                }
                const queryStr = new URLSearchParams(query)
                const data = await Api.get(`/billing/usage?${queryStr}`, idToken)
                setNewInvoice(data);
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured.' }])
            } finally {
                setPeriodLoaded(true)
            }
        }
        if (!periodLoaded) {
            fetchNewInvoiceData();
        }
    }, [periodLoaded, periodStart, periodEnd, alerts, idToken]);

    // bill to & sub
    useEffect(() => {
        const fetchBillTo = async () => {
            try {
                const billtoData = await Api.get(`/billing/billto`, idToken);
                setBillTo(billtoData);
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching Bill To' }])
            } finally {
                setDidFetchBillTo(true)
            }
        }

        const fetchSubscription = async () => {
            try {
                const subscriptionData = await Api.get(`/billing/subscription`, idToken);
                setSubscription(subscriptionData);
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching Subscription' }])
            } finally {
                setDidFetchSubscription(true)
            }
        }

        if (!didFetchBillTo) {
            fetchBillTo()
        }

        if (!didFetchSubscription) {
            fetchSubscription()
        }

    }, [alerts, idToken, didFetchBillTo, didFetchSubscription])


    return (
        <React.Fragment>
            <AlertDisplay alertState={alertState} />
            <Container fluid>
                <Invoices
                    invoiceModalState={invoiceModalState}
                    currentInvoiceModalState={currentInvoiceModalState}
                    createNewInvoiceModalState={createNewInvoiceModalState}
                    invoiceState={invoiceState}
                    invoicesState={invoicesState}
                    cleanInvoices={cleanInvoices}
                    invoicesLoadedState={invoicesLoadedState}
                    invoiceLoadedState={invoiceLoadedState}
                    showBillToModalState={showBillToModalState}
                />
                {showBillToModal &&
                    <BillToModal
                        handleSaveBillTo={handleSaveBillTo}
                        showBillToModalState={showBillToModalState}
                        billtoState={billtoState}
                        subscriptionState={subscriptionState}
                    />
                }
                {invoiceModal &&
                    <InvoiceModal
                        invoiceModalState={invoiceModalState}
                        invoiceState={invoiceState}
                        invoiceLoadedState={invoiceLoadedState}
                        handleInvoiceActivityToggle={handleInvoiceActivityToggle}
                        handleSendDraft={handleSendDraft}
                        handleUpdateInvoice={handleUpdateInvoice}
                        handleCancelInvoice={handleCancelInvoice}
                        handleSendInvoice={handleSendInvoice}
                        handlePaidInvoice={handlePaidInvoice}
                        handleRemindInvoice={handleRemindInvoice}
                    />
                }
                {currentInvoiceModal &&
                    <CurrentUsageModal
                        currentInvoiceModalState={currentInvoiceModalState}
                        invoiceState={invoiceState}
                        usageLoadedState={usageLoadedState}
                    />
                }
                {createNewInvoiceModalState &&
                    <CreateNewInvoiceModal
                        createNewInvoiceModalState={createNewInvoiceModalState}
                        handleSendDraft={handleSendDraft}
                        handleSaveDraft={handleSaveDraft}
                        periodStartState={periodStartState}
                        periodEndState={periodEndState}
                        newInvoiceState={newInvoiceState}
                        periodLoadedState={periodLoadedState}
                    />
                }
            </Container>
        </React.Fragment>
    )
}

export default Billing;