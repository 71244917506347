import React, { useState } from 'react';
import { Container,Table } from "react-bootstrap"
// import { Button, Container, Modal, ModalBody, Table } from "react-bootstrap"
// import { FaInfoCircle } from "react-icons/fa";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import { BsCardList} from "react-icons/bs";
// import { BsCardList, BsChatRightTextFill } from "react-icons/bs"; 
// import DriverHOSLog from '../../components/DriverHOSLog';
// import AlertDisplay from '../../components/AlertDisplay';


const LoadTenderOperator = ({ ...props }) => {
    const operators = props.operators
    const [showOperator, setShowOperator] = useState();
    // const [showMessageModal, setShowMessageModal] = useState();

    // const sendOperatorMessage = async () => {
    //     try {

    //     } catch(error) {

    //     }
    // }

    if (!operators || operators.length === 0) {
        return (
            <Container>
                <b>No Drivers Assigned</b>
            </Container>
        )
    }

    // if(showMessageModal){
    //     <Modal>
    //         <ModalBody>
    //             test
    //         </ModalBody>
    //     </Modal>
    // }

    return (
        <Table striped bordered hover responsive>
            <thead>
                <tr>
                    <th colSpan={7}>Drivers</th>
                </tr>
                <tr>
                    {/* <th><BsChatRightTextFill/></th> */}
                    <th> <BsCardList /> </th>
                    <th>Driver ID</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>LicenseNo</th>
                    <th>LicenseSt</th>
                </tr>
            </thead>
            <tbody>
                {operators && operators.map((operator, i) => (
                    <React.Fragment key={`operator-fragment-${i}`}>
                        <tr>
                            {/* <td>
                                <Button  onClick={() => setShowMessageModal(!showMessageModal)}><BsChatRightTextFill/></Button>
                            </td> */}
                            <td onClick={() => setShowOperator(showOperator === i ? '' : i)}>{showOperator === i ? <AiFillCaretDown /> : <AiFillCaretRight />}</td>
                            <td>{operator?.id}</td>
                            <td>{operator?.name}</td>
                            <td><a href={`tel:+1${operator?.phone}`}>{operator?.phone}</a></td>
                            <td>{operator?.licenseNumber}</td>
                            <td>{operator?.licenseState}</td>
                        </tr>
                        {showOperator === i &&
                            <tr key={`operator-row-${i}-b`}>
                                <td colSpan={7}>
                                    <pre>
                                        {JSON.stringify(operator, "", 2)}
                                    </pre>
                                </td>
                            </tr>
                        }
            
                    </React.Fragment>
                ))}
            </tbody>
        </Table>
    )
}

export default LoadTenderOperator;