
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { Card, Row, Col, Form, Button, NavLink} from "react-bootstrap";

// function MultidimensionalGroupBy(arr, groupby) {
//     const grouped = {};
//     arr.forEach(function (a) {
//         groupby.reduce(function (o, g, i) {                            
//             o[a[g]] = o[a[g]] || (i + 1 === groupby.length ? [] : {}); 
//             return o[a[g]];                                           
//         }, grouped).push(a);
//     });
//     return grouped;
// }


const DataAnalysisComponent = ({ data }) => {
  const [filteredData, setFilteredData] = useState(data);
  const [filters, setFilters] = useState({});
  const [selectedRecords, setSelectedRecords] = useState([]);

  useEffect(() => {
    setFilteredData(data); // Initial data
  }, [data]);

  const handleFilter = (key, value) => {
    const updatedFilters = { ...filters, [key]: value };
    setFilters(updatedFilters);

    let filtered = data;
    Object.entries(updatedFilters).forEach(([filterKey, filterValue]) => {
      if (filterValue !== '') {
        filtered = filtered.filter((item) => item[filterKey] === filterValue);
      }
    });

    setFilteredData(filtered);
  };

  const handleSort = (key) => {
    const sorted = [...filteredData].sort((a, b) =>
      a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0
    );
    setFilteredData(sorted);
  };

  const handleResetFilters = () => {
    setFilters({});
    setFilteredData(data);
  };

  const handleScatterPlotPointClick = (pointData) => {
    if (pointData.length > 0) {
      const [selectedPoint] = pointData;
      const selectedPointRecords = filteredData.filter((item) => {
        const departureLateness = parseFloat(item['Departure Lateness (min)']);
        const arrivalLateness = parseFloat(item['Arrival Lateness (min)']);
        return (
          !isNaN(departureLateness) &&
          !isNaN(arrivalLateness) &&
          departureLateness === selectedPoint.xValue &&
          arrivalLateness === selectedPoint.yValue
        );
      });
      setSelectedRecords(selectedPointRecords);
    } else {
      console.log('none')
      setSelectedRecords([]);
    }
  };

  const getUniqueValuesWithCount = (key) => {
    const uniqueValuesMap = filteredData.reduce((acc, item) => {
      const value = item[key];
      if (value && !acc[value]) {
        acc[value] = 1;
      } else if (value) {
        acc[value] += 1;
      }
      return acc;
    }, {});

    return Object.entries(uniqueValuesMap).map(([value, count]) => ({
      value,
      count,
    }));
  };

  // Parse numeric values for scatter plot data
  const scatterPlotData = [
    ['', 'Ar, Dp Lateness (min)'],
    ...filteredData
      .map((item) => [
        parseFloat(item['Departure Lateness (min)']),
        parseFloat(item['Arrival Lateness (min)']),
      ])
      .filter(([departureLateness, arrivalLateness]) => !isNaN(departureLateness) && !isNaN(arrivalLateness)),
  ];

  return (
    <Card>
      <Card.Header>
        <Button onClick={() => handleResetFilters()}>Reset Filters</Button>
      </Card.Header>
      <Card.Body>
        <Chart
          width={'100%'}
          height={'400px'}
          chartType="ScatterChart"
          loader={<div>Loading Chart...</div>}
          data={scatterPlotData}
          options={{
            title: 'Lateness Scatter Plot',
            hAxis: { title: 'Departure Lateness (min)' },
            vAxis: { title: 'Arrival Lateness (min)' },
            legend: 'none',
          }}
          // chartEvents={[
          //   {
          //     eventName: 'select',
          //     callback: handleScatterPlotPointClick,
          //   },
          // ]}
        />
      </Card.Body>
      <Card.Body>
        <Row>
          <Col>
            <h2>Records: {filteredData.length}</h2>
            {selectedRecords.map((record, index) => (
              <div key={index}>
                <pre>{JSON.stringify(record, null, 2)}</pre>
              </div>
            ))}
          </Col>
        </Row>
      </Card.Body>
      <Card.Body>
        <Row>
          {Object.keys(data[0]).map((key) => (
            <Col key={key} sm={12} md={4} lg={3}>
              <Form.Label>{key}:</Form.Label>
              <Form.Select
                value={filters[key] || ''}
                onChange={(e) => handleFilter(key, e.target.value)}
              >
                <option value="">All</option>
                {getUniqueValuesWithCount(key).map(({ value, count }) => (
                  <option key={value} value={value}>
                    {value} ({count})
                  </option>
                ))}
              </Form.Select>
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};


const ffhkey = 'fiftyfivehundred'

function FiftyFivehundredReports() {
    const [set, didSet] = useState();
    const [data, setData] = useState();

    useEffect(() => {
        if (!set) {
            const storedData = localStorage.getItem(ffhkey);
            const data = JSON.parse(storedData)
            setData(data)
        }
        return () => didSet(true);
    })

    return (
        <Card>
            {data &&
                <DataAnalysisComponent data={data} />
            }
        </Card>
    );
};

export default FiftyFivehundredReports;
