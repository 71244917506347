import React, { useEffect, useState } from 'react';
import { Form, Button, Card, Row, Col, Alert } from 'react-bootstrap';
import logo from '../../../assets/logo.png'
import { useAuth } from '../../../context/auth';
import Api from '../../../utils/Api';

const initialFormState = {
  firstname: '',
  lastname: '',
  date: '',
  hcrs: '',
  schedule: '',
  personnel: '',
  equipment: '',
  extraservice: '',
  uspscommunication: ''
};

const DailyReportForm = () => {
  const { user, idToken } = useAuth();
  const [alerts, setAlerts] = useState([]);
  const [load, didLoad] = useState();
  const [form, setForm] = useState(initialFormState);
  const [profile, setProfile] = useState([]);

  const handleFormSet = (key, val) => {
    const formData = {...form, [key]: val}
    const formJSON = JSON.stringify(formData);
    setForm(formData)
    localStorage.setItem('@mctms-dailyreport-form-data', formJSON)
  }



  const handlePost = async () => {
      try {
          await Api.post('/form/dailyreport', form, idToken)
          setAlerts([...alerts, { variant: 'success', message: 'Success' }])
          handleReset();
      } catch (error) {
          setAlerts([...alerts, { variant: 'warning', message: error }])
      }
  }

  const handleReset = async () => {
    setForm(initialFormState)
    localStorage.setItem('@mctms-dailyreport-form-data', JSON.stringify(initialFormState))
    didLoad(false);
  }

  useEffect(() => {
    const handleLoad = async () => {
      const userprofile = await Api.get(`/access/profile/uid/${user.uid}`, idToken);
      setProfile(userprofile)
  
      const formjson = localStorage.getItem('@mctms-dailyreport-form-data') ;
      const formobj = formjson ? JSON.parse(formjson) : initialFormState ;
      setForm({...formobj, firstname: profile.firstname, lastname: profile.lastname, uid: profile.uid, email : user.email});
      didLoad(true)
    }

    if (!load) {
      handleLoad();
    }
  });

  return (
    <Card>
      <Card.Header>
        <div className="img-container d-flex justify-content-center align-items-center">
          <Card.Img
            src={logo}
            alt="logo"
            style={{ width: "200px", height: "auto" }}
          />
        </div>
        <Card.Title className="text-center">Daily Report</Card.Title>
      </Card.Header>

      <Card.Body>
        <Form>
          <Row>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label className="form-label">First Name:</Form.Label>
                <Form.Control
                  type="text"
                  name="firstname"
                  value={profile.firstname}
                  disabled
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label className="form-label">Last Name:</Form.Label>
                <Form.Control
                  type="text"
                  name="lastname"
                  value={profile.lastname}
                  disabled
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label className="form-label">Email:</Form.Label>
                <Form.Control
                  type="text"
                  name="lastname"
                  value={user.email}
                  disabled
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label className="form-label">Date:</Form.Label>
                <Form.Control
                  type="date"
                  name="date"
                  value={form.date}
                  onChange={(e) => handleFormSet('date', e.target.value )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <Form.Label>HCRs:</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              name="hcrs"
              placeholder=""
              value={form.hcrs}
              onChange={(e) => handleFormSet('hcrs', e.target.value )}
            />
          </Form.Group>
          <Form.Group controlId="schedule">
            <Form.Label>Schedule:</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              name="schedule"
              placeholder="Did all trucks depart/arrive on time? Late Slips? Deviations?"
              value={form.schedule}
              onChange={(e) => handleFormSet('schedule', e.target.value )}
            />
          </Form.Group>
          <Form.Group controlId="personnel">
            <Form.Label>Personnel:</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              name="personnel"
              placeholder="Employees late? Sick? Poor performance? Accidents? Incidents? Coaching-Verbal? Violations?"
              value={form.personnel}
              onChange={(e) => handleFormSet('personnel', e.target.value )}
            />
          </Form.Group>
          <Form.Group controlId="equipment">
            <Form.Label>Equipment:</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              name="equipment"
              placeholder="Truck Issues/Breakdowns? Trucks serviced? Damages needing repair? Lytx cameras installed? Missing paperwork?"
              value={form.equipment}
              onChange={(e) => handleFormSet('equipment', e.target.value )}
            />
          </Form.Group>
          <Form.Group controlId="extraservice">
            <Form.Label>Extra Service:</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              name="extraservice"
              placeholder="Additional Trips?"
              value={form.extraservice}
              onChange={(e) =>
                handleFormSet('extraservice', e.target.value )
              }
            />
          </Form.Group>
          <Form.Group controlId="uspscommunication">
            <Form.Label>USPS Communication:</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              name="uspscommunication"
              placeholder="Any issues at Post Offices? Things to communicate? Changes?"
              value={form.uspscommunication}
              onChange={(e) =>
                handleFormSet('uspscommunication', e.target.value )
              }
            />
          </Form.Group>
          <Row>
            <Col>
              <Button
                variant="primary"
                type="button"
                onClick={handlePost}
                style={{ marginTop: "10px" }}
              >
                Submit
              </Button>
              <Button
                variant="danger"
                type="button"
                onClick={handleReset}
                style={{ marginTop: "10px", marginLeft: "10px" }}
              >
                Reset
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
      {alerts &&
        <Card.Body>
          <Row>
            <Col>
              {alerts.map((alrt, i) => (
                <Alert key={i} variant={alrt.variant} dismissible onClick={()=>setAlerts([])}>{JSON.stringify(alrt.message)}</Alert>
              ))}
            </Col>
          </Row>
        </Card.Body>
      }
    </Card>
  );

};

export default DailyReportForm;