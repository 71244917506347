import React, { useEffect, useState, useCallback } from 'react';
import { Container, Card, Spinner, Alert } from 'react-bootstrap';
import { RxCrossCircled, RxCheckCircled } from 'react-icons/rx';
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';

// Import images for services
import EIA_IMG from '../../assets/b2b/eia.png';
import KLS_IMG from '../../assets/b2b/kleinschmidt.jpg';
import SAM_IMG from '../../assets/b2b/samsara.png';
import FKS_IMG from '../../assets/b2b/fourkites.png';
import PCM_IMG from '../../assets/b2b/pcmiler.png';
import NWS_IMG from '../../assets/b2b/nationalweatherservice.png';
import TSL_IMG from '../../assets/b2b/tesla.jpg';
import GEO_IMG from '../../assets/b2b/geotab.jpg';
import LYT_IMG from '../../assets/b2b/lytx.png';
import FMCSA_IMG from '../../assets/b2b/fmcsa.png';
import AlertDisplay from '../../components/AlertDisplay';

function IOT() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const services = [
        {
            name: 'Samsara',
            state: useState(<Spinner />),
            colorState: useState('#fbb514'),
            messageState: useState('checking'),
            checkFunction: async () => Api.get('/samsara/healthcheck', idToken),
            image: SAM_IMG,
        },
        {
            name: 'Kleinschmidt',
            state: useState(<Spinner />),
            colorState: useState('#fbb514'),
            messageState: useState('checking'),
            checkFunction: async () => Api.get('/edi/healthcheck', idToken),
            image: KLS_IMG,
        },
        {
            name: 'EIA',
            state: useState(<Spinner />),
            colorState: useState('#fbb514'),
            messageState: useState('checking'),
            checkFunction: async () => Api.get('/fuel/healthcheck', idToken),
            image: EIA_IMG,
        },
        {
            name: 'FMCSA',
            state: useState(<Spinner />),
            colorState: useState('#fbb514'),
            messageState: useState('checking'),
            checkFunction: async () => Api.get('/fmcsa/healthcheck', idToken),
            image: FMCSA_IMG,
        },
        {
            name: 'PCMiler',
            state: useState(<Spinner />),
            colorState: useState('#fbb514'),
            messageState: useState('checking'),
            checkFunction: async () => Api.get('/pcmiler/healthcheck', idToken),
            image: PCM_IMG,
        },
        {
            name: 'FourKites',
            state: useState(<Spinner />),
            colorState: useState('#fbb514'),
            messageState: useState('checking'),
            checkFunction: async () => Api.get('/fourkites/healthcheck', idToken),
            image: FKS_IMG,
        },
        {
            name: 'National Weather Service',
            state: useState(<Spinner />),
            colorState: useState('#fbb514'),
            messageState: useState('checking'),
            checkFunction: async () => Api.get('/nws/healthcheck', idToken),
            image: NWS_IMG,
        },
        {
            name: 'Tesla',
            state: useState(<Spinner />),
            colorState: useState('#fbb514'),
            messageState: useState('checking'),
            checkFunction: async () => Api.get('/tesla/healthcheck', idToken),
            image: TSL_IMG,
        },
        {
            name: 'Lytx',
            state: useState(<Spinner />),
            colorState: useState('#fbb514'),
            messageState: useState('checking'),
            checkFunction: async () => Api.get('/lytx/healthcheck', idToken),
            image: LYT_IMG,
        },
        {
            name: 'Geotab',
            state: useState(<Spinner />),
            colorState: useState('#fbb514'),
            messageState: useState('checking'),
            checkFunction: async () => Api.get('/geotab/healthcheck', idToken),
            image: GEO_IMG,
        },

    ];


    useEffect(() => {
        const checkHealth = async () => {
            for (const service of services) {
                try{
                    const result = await service.checkFunction();
                    service.state[1](result ? <RxCheckCircled /> : <RxCrossCircled />);
                    service.colorState[1](result ? '#488e5c' : '#dc3912');
                    service.messageState[1](result ? 'Established' : 'Error');
                }catch(error){
                    setAlerts([...alerts, { variant: 'warning', message: `${service.name} error` }]);
                }

            }
            didSet(true);
        }

        if(!set){
            checkHealth();
        }
    },[set])

    // Function to generate service cards dynamically
    const generateServiceCards = () => {
        return services.map((service, index) => (
            <Card key={index} style={{ width: '18rem', margin: '1em' }}>
                <Card.Img src={service.image} variant="top" style={{ padding: '1em', height: '11rem', objectFit: 'contain' }} />
                <Card.Header>
                    <Card.Subtitle>
                        Connection: <span className='float-end' style={{ color: service.colorState[0] }}>{service.messageState[0]} {service.state[0]}</span>
                    </Card.Subtitle>
                </Card.Header>
            </Card>
        ));
    };

    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header>
                    <b>IoT - Internet of Things</b>
                </Card.Header>
                <AlertDisplay alertState={alertState} />
                <Card.Body className="d-flex justify-content-space-evenly align-items-center flex-wrap">
                    {generateServiceCards()}
                </Card.Body>
            </Card>
        </Container>
    );
}

export default IOT;
