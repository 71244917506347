

import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Container, Card, Row, Col, Spinner, Button, Modal, Badge, ModalBody, Form, Table, Accordion, AccordionHeader, AccordionItem, Alert, ListGroup, ListGroupItem, } from "react-bootstrap";
import { useAuth } from "../../context/auth";
import Api from "../../utils/Api";
import AlertDisplay from "../../components/AlertDisplay";
import LogisticsLoading from "../../components/LogisticsLoading";
import LOAD_TENDER_REPORTS from './LoadTender.reports.json'
import { useLocation, useNavigate } from 'react-router-dom';
import DataDisplayModal from "../../components/DataDisplayModal";
import { FaArrowLeft, FaArrowRight, FaCheck, FaCheckCircle, FaInfo, FaMinus, FaSave, FaStopwatch, FaTable, FaTags, FaWindowRestore } from "react-icons/fa";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { usePrint } from "../../context/printcontext";
import PrintPDF from "../../components/PrintPDF";
import { AiOutlineGlobal } from "react-icons/ai";
import { formatDateStrToMMDDYYHHMMSSTZ, formatDateStrToMMDDYYHHMMTZ } from "../../utils/DateTimeFormat.helper";
import DriverHOSLog from "../../components/DriverHOSLog";
import GeofenceDisplay from "../../components/GeofenceDisplay";
import { findUSPSAddress, findUSPSNASSCode, findUSPSNassCodeTimezoneIANA, formatUSPSDateTime } from "../../context/uspsdata";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { FaMagnifyingGlass, FaPlus } from "react-icons/fa6";
import { Chart } from 'react-google-charts';
import ConfirmationDangerModal from "../../components/ConfirmationDangerModal";
import { BiMailSend } from "react-icons/bi";
import { useSettings } from "../../context/settings";
import { DateTime } from 'luxon';
import { CompactPicker, TwitterPicker } from 'react-color';
import Tag from "../../components/TagButton";
// import D3PieChart from "../../components/D3PieChart";
import ShipmentGanttChart from "../../components/ShipmentGanttChart";
import {
    EDI_214_SHIPMENT_STATUS_CODES,
    EDI_214_SHIPMENT_STATUS_CODES_MAIL_SPECIFIC,
    EDI_214_SHIPMENT_STATUS_CODES_MAIL_PICKUP_SPECIFIC,
    EDI_214_SHIPMENT_STATUS_CODES_MAIL_DELIVERY_SPECIFIC,
    EDI_204_210_STOP_OFF_DETAIL_REASON_CODES,
    EDI_TRANSACTION_SET_PURPOSE_CODES,
    EDI_TIMEZONE_CODES,
    EDI_WEIGHT_CODES,
    EDI_EQUIPMENT_CODES,
    EDI_BUSINESS_INSTRUCTIONS_REFERENCE_NO_CODES,
    EDI_SHIPMENT_METHOD_OF_PAYMENT,
    EDI_STOP_REASON_CODE
} from '../../context/klienschmidtdata'
import DataDisplay from "../../components/DataDisplay";


const lsKey = '@mctms-loadtender-pipeline-display';

function objectToQueryString(obj) {
    const params = new URLSearchParams();
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (Array.isArray(value)) {
                value.forEach(element => {
                    params.append(key, element);
                });
            } else {
                params.append(key, value);
            }
        }
    }
    return params.toString();
}

function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    return `${year}-${month}-${day}`;
}

function formatZuluToTZ(utcTime, ianaTz) {

    const date = new Date(utcTime);
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
        timeZone: ianaTz,
        hourCycle: 'h23'
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);
    const formatted = formatter.format(date);
    return formatted;
}

async function extractSamsaraHOSWaypoints(data) {
    const waypoints = [];

    for (const item of data) {
        try {
            const waypoint = {
                latitude: item.logRecordedLocation.latitude,
                longitude: item.logRecordedLocation.longitude,
                name: item.hosStatusType,
            };
            waypoints.push(waypoint);
        } catch (error) {
            continue;
        }
    }
    return waypoints;
}

function convertMillisecondsToHoursMinutes(durationInMilliseconds) {
    const hours = Math.floor(durationInMilliseconds / 3600000);
    const minutes = Math.floor((durationInMilliseconds % 3600000) / 60000);
    return `${hours} hr ${minutes} min`;
}

function formatShipmentArriveDepartDateForInput(isoDate, timezone) {
    const luxonDate = DateTime.fromISO(isoDate);
    const formattedDate = luxonDate.setZone(timezone).toFormat('yyyy-MM-dd\'T\'HH:mm');
    return formattedDate;
}

function isNumber(value) {
    return typeof value === 'number' && !isNaN(value);
}

function getColorForPoint(arrive, depart) {
    if (arrive >= 0 && depart >= 0) {
        return 'green'; // early, early
    } else if (arrive < 0 && depart < 0) {
        return 'red'; // late, late
    } else if (arrive >= 0 && depart < 0) {
        return 'blue'; // in early, out late
    } else if (arrive < 0 && depart >= 0) {
        return 'orange'; // in late, out early
    }
}

const today = new Date();  // today based on the clock
const tomorrow = new Date(today); // based on today value
const yesterday = new Date(today); // based on today value
const minusSeven = new Date(today); // minus 7 days
const minusFourteen = new Date(today); // minus 14 days
const minusThirty = new Date(today); // minus 30 days
const minusSixty = new Date(today); // minus 30 days
const minusNinty = new Date(today); // minus 30 days
const plusSeven = new Date(today); // based on today value, plus 7 days
const plusFourteen = new Date(today); // based on today value, plus 14 days
const plusThirty = new Date(today); // based on today value, plus 30 days
const plusSixty = new Date(today); // based on today value, plus 30 days
const plusNinty = new Date(today); // based on today value, plus 30 days
const lastSunday = new Date(today); // based on today value, the last week's Sunday
const lastSaturday = new Date(today);// based on today value, the last week's Saturday
const thisSunday = new Date(today); // based on today value, the current week's Sunday
const thisSaturday = new Date(today);// based on today value, the current week's Saturday
const nextSunday = new Date(today);// based on today value, the next week's Sunday
const nextSaturday = new Date(today);// based on today value, the next week's Saturday
const newyears = new Date(today); // current calendar's first day of the year
const newyearseve = new Date(today); // current calendar's last day of the year
const currentYear = today.getFullYear();
const currentMonth = today.getMonth();
const nextMonth = new Date(today);
nextMonth.setMonth(currentMonth + 1);
const firstdayofthemonth = new Date(currentYear, currentMonth, 1);
const lastdayofthemonth = new Date(currentYear, currentMonth + 1, 0);
const firstdayoflastmonth = new Date(currentYear, currentMonth - 1, 1);
const lastdayoflastmonth = new Date(currentYear, currentMonth, 0);
const firstdayofnextmonth = new Date(currentYear, currentMonth + 1, 1);
const lastDayOfNextMonth = new Date(nextMonth);
lastDayOfNextMonth.setMonth(nextMonth.getMonth() + 1, 0);

tomorrow.setDate(today.getDate() + 1);
yesterday.setDate(today.getDate() - 1);
minusSeven.setDate(today.getDate() - 7);
minusFourteen.setDate(today.getDate() - 14);
minusThirty.setDate(today.getDate() - 30);
minusSixty.setDate(today.getDate() - 60);
minusNinty.setDate(today.getDate() - 90);
plusSeven.setDate(today.getDate() + 7);
plusFourteen.setDate(today.getDate() + 14);
plusThirty.setDate(today.getDate() + 30);
plusSixty.setDate(today.getDate() + 60);
plusNinty.setDate(today.getDate() + 90);
const year = new Date().getFullYear(); // Current year
const fiscalQuarters = {
    q1: { start: new Date(year, 0, 1), end: new Date(year, 2, 31) },
    q2: { start: new Date(year, 3, 1), end: new Date(year, 5, 30) },
    q3: { start: new Date(year, 6, 1), end: new Date(year, 8, 30) },
    q4: { start: new Date(year, 9, 1), end: new Date(year, 11, 31) }
};


// Calculate the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
const currentDayOfWeek = today.getDay();

// Calculate the date for the last week's Sunday and Saturday
lastSunday.setDate(today.getDate() - (currentDayOfWeek + 7));
lastSaturday.setDate(today.getDate() - (currentDayOfWeek + 1));

// Calculate the date for the current week's Sunday and Saturday
thisSunday.setDate(today.getDate() - currentDayOfWeek);
thisSaturday.setDate(today.getDate() + (6 - currentDayOfWeek));

// Calculate the date for the next week's Sunday and Saturday
nextSunday.setDate(today.getDate() + (7 - currentDayOfWeek));
nextSaturday.setDate(today.getDate() + (13 - currentDayOfWeek));

// Calculate the first day and last day of the current year
newyears.setMonth(0); // January (0 index)
newyears.setDate(1);

newyearseve.setMonth(11); // December (0 index)
newyearseve.setDate(31);


const initialQuery = {
    start: formatDate(today),
    end: formatDate(today),
    report: 'loadtender-all',
    loadtenderLifeCycleStatus: 'All',
    contract: 'All',
    place: 'All',
    state: 'All',
    city: 'All',
    driver: 'All',
    tags: [],
}

const initialTag = {
    name: 'Example',
    fontColorHEX: '#333',
    backgroundColorHEX: '#ccc'
}

const StopPunctualityLegend = () => {
    return (
        <Card >
            <Card.Header>
                Stop Punctuality Legend
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col xs="auto">
                        <div style={{ backgroundColor: 'gold', width: '1.2em', height: '1.2em', borderRadius: '50%' }}></div>
                    </Col>
                    <Col>Origin (0, 0)</Col>
                </Row>
                <Row>
                    <Col xs="auto">
                        <div style={{ backgroundColor: 'green', width: '1.2em', height: '1.2em', borderRadius: '50%' }}></div>
                    </Col>
                    <Col>Early Arrivals & Early Departures</Col>
                </Row>
                <Row>
                    <Col xs="auto">
                        <Col style={{ backgroundColor: 'blue', width: '1.2em', height: '1.2em', borderRadius: '50%' }}> </Col>
                    </Col>
                    <Col>Early Arrivals & Late Departures</Col>
                </Row>
                <Row>
                    <Col xs="auto">
                        <Col style={{ backgroundColor: 'red', width: '1.2em', height: '1.2em', borderRadius: '50%' }}> </Col>
                    </Col>
                    <Col >Late Arrivals & Late Departures</Col>
                </Row>
                <Row>
                    <Col xs="auto">
                        <Col style={{ backgroundColor: 'orange', width: '1.2em', height: '1.2em', borderRadius: '50%' }}> </Col>
                    </Col>
                    <Col>Late Arrivals & Early Departures</Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

const LoadTenderTagsModal = ({ tagsModalState, tagState, tagsState, handleTagCreate, didFetchTagsState }) => {
    const { showTagsModal, setShowTagsModal } = tagsModalState;
    const { tag, setTag } = tagState;
    const { tags } = tagsState;
    const { didFetchTags } = didFetchTagsState
    return (
        <Modal size={'xl'} show={showTagsModal} >
            <Modal.Header closeButton onClick={() => setShowTagsModal(false)}>
                <h3>Tags</h3>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs="auto">
                        <Form.Control
                            type="text"
                            placeholder='Name the tag'
                            value={tag.name}
                            onChange={e => setTag({ ...tag, name: e.target.value })}
                        />
                        <br />
                        <Button
                            style={{
                                color: tag.fontColorHEX,
                                backgroundColor: tag.backgroundColorHEX,
                                borderColor: tag.fontColorHEX,
                            }}>
                            {tag.name}
                        </Button>
                        <br />
                    </Col>
                    <Col xs="auto">
                        <CompactPicker // Use TwitterPicker component for color selection
                            color={tag.fontColorHEX}
                            onChangeComplete={(color) => setTag({ ...tag, fontColorHEX: color.hex })}
                        /><br />
                        Text Color
                    </Col>
                    <Col xs="auto">
                        <CompactPicker // Use TwitterPicker component for color selection
                            color={tag.backgroundColorHEX}
                            onChangeComplete={(color) => setTag({ ...tag, backgroundColorHEX: color.hex })}
                        />
                        <br />
                        Background Color
                    </Col>
                    <Col xs="auto">
                        <Button onClick={() => handleTagCreate()}>
                            Create
                        </Button>
                    </Col>
                </Row>
                <hr />
                {didFetchTags ?
                    <Row>
                        {tags.length > 0 && tags.map((t, i) => (
                            <Col xs="auto" key={i} style={{ padding: '0.25em' }}>
                                <Tag
                                    name={t.name}
                                    fontColorHEX={t.fontColorHEX}
                                    backgroundColorHEX={t.backgroundColorHEX}
                                />
                            </Col>
                        ))}
                    </Row>
                    :
                    <Spinner />
                }

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowTagsModal(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal >
    )
}

const LoadTenderCodesModal = ({ codesModalState }) => {
    const { showCodesModal, setShowCodesModal } = codesModalState;
    return (
        <Modal size={'xl'} show={showCodesModal}>
            <Modal.Header closeButton onClick={() => setShowCodesModal(false)}>
                <h3>EDI Codes</h3>
            </Modal.Header>
            <Modal.Body>
                <Accordion>
                    <Accordion.Item eventKey="13">
                        <Accordion.Header>Load Tender Navigation</Accordion.Header>
                        <Accordion.Body>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Tab</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Info</td>
                                        <td>Information about load tendering, terminology, and codes.</td>
                                    </tr>
                                    <tr>
                                        <td>IoT</td>
                                        <td>Internet of Things web connections.</td>
                                    </tr>
                                    <tr>
                                        <td>Active</td>
                                        <td>These tenders are active</td>
                                    </tr>

                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="16">
                        <Accordion.Header>EDI Status</Accordion.Header>
                        <Accordion.Body>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Pending</td>
                                        <td>These are load tenders which are pending a response.</td>
                                    </tr>
                                    <tr>
                                        <td>Rejected</td>
                                        <td>Load tenders in this status have been rejected and service has not been provided.</td>
                                    </tr>
                                    <tr>
                                        <td>Accepted</td>
                                        <td>Load tenders in this status have been agreed to, and service will be provided.</td>
                                    </tr>
                                    <tr>
                                        <td>Completed</td>
                                        <td>These tenders have been marked as completed.</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="14">
                        <Accordion.Header>EDI Message Types</Accordion.Header>
                        <Accordion.Body>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Message</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>204 : Motor Carrier Load Tender</td>
                                        <td>
                                            used by shippers or their agents to request a motor carrier to transport goods from one location to another.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>214 : Transportation Carrier Shipment Status Message</td>
                                        <td>used by carriers to provide shippers with information about the status of their shipments, including pickup and delivery times.</td>
                                    </tr>
                                    <tr>
                                        <td>990 : Response to a Load Tender</td>
                                        <td>used by motor carriers to acknowledge receipt of a load tender message from a shipper and to provide a response indicating whether they accept or reject the shipment request.</td>
                                    </tr>
                                    <tr>
                                        <td>210 : Motor Carrier Freight Invoice</td>
                                        <td>used by motor carriers to invoice shippers for the transportation of goods.</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="15">
                        <Accordion.Header>DOT Inspection Types</Accordion.Header>
                        <Accordion.Body>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Inspection Type</th>
                                        <th>Common Name</th>
                                        <th>Definition</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Level I Inspection</td>
                                        <td>North American Standard Inspection</td>
                                        <td>
                                            This is the most comprehensive and rigorous inspection. It includes a thorough examination of both the CMV and the driver. It covers items such as driver credentials, hours-of-service compliance, vehicle documentation, braking system, tires, lights, cargo securement, fuel system, exhaust system, and more.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Level II Inspection</td>
                                        <td>Walk-Around Driver/Vehicle Inspection</td>
                                        <td>
                                            This inspection focuses on the driver and the vehicle's components that can be checked without physically getting under the CMV. It includes items like driver's license, medical card, seat belt usage, alcohol and drug-related violations, and general vehicle condition.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Level III Inspection</td>
                                        <td>Driver-Only Inspection</td>
                                        <td>
                                            This inspection focuses solely on the driver's credentials and compliance with driver-related regulations. It includes items like driver's license, medical card, hours-of-service documentation, record of duty status, and other applicable driver-related requirements.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Level IV Inspection</td>
                                        <td>Special Inspection</td>
                                        <td>
                                            This inspection is typically conducted on specific items or components of the CMV that require specialized expertise. It can include inspections of items such as cargo tanks, hazardous materials (Hazmat) documentation, passenger vehicle requirements, or other specialized areas.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Level V Inspection</td>
                                        <td>Vehicle-Only Inspection</td>
                                        <td>
                                            This inspection focuses on the vehicle's components, equipment, and overall mechanical condition. It includes items such as brakes, tires, lights, fuel system, exhaust system, cargo securement, and other related vehicle components.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Level VI Inspection</td>
                                        <td>Enhanced NAS Inspection for Radioactive Shipments</td>
                                        <td>
                                            This inspection is specific to CMVs carrying radioactive materials. It includes additional checks related to the transportation of radioactive substances, including the vehicle's radiation shielding, warning devices, shipping papers, and other relevant requirements.
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>EDI 214 - SHIPMENT STATUS CODES : USPS MAIL SPECIFIC (x{EDI_214_SHIPMENT_STATUS_CODES_MAIL_SPECIFIC.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_214_SHIPMENT_STATUS_CODES_MAIL_SPECIFIC}
                                sourceName={'EDI-214-Shipment-Status-Codes-Mail'}
                                lsKey={'@mctms-edi-214-codes-mail'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>EDI 214 - SHIPMENT STATUS CODES : USPS MAIL - DELIVERY SPECIFIC (x{EDI_214_SHIPMENT_STATUS_CODES_MAIL_DELIVERY_SPECIFIC.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_214_SHIPMENT_STATUS_CODES_MAIL_DELIVERY_SPECIFIC}
                                sourceName={'EDI-214-Shipment-Status-Codes-Mail-Delivery'}
                                lsKey={'@mctms-edi-214-codes-mail-delivery'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>EDI 214 - SHIPMENT STATUS CODES : USPS MAIL APPOINTMENT SPECIFIC (x{EDI_214_SHIPMENT_STATUS_CODES_MAIL_PICKUP_SPECIFIC.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_214_SHIPMENT_STATUS_CODES_MAIL_PICKUP_SPECIFIC}
                                sourceName={'EDI-214-Shipment-Status-Codes-Mail-Pickup'}
                                lsKey={'@mctms-edi-214-codes'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>EDI 214 - SHIPMENT STATUS CODES (x{EDI_214_SHIPMENT_STATUS_CODES.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_214_SHIPMENT_STATUS_CODES}
                                sourceName={'EDI-214-Shipment-Status-Codes'}
                                lsKey={'@mctms-edi-214-codes'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>EDI 204 & 210 - STOP OFF DETAIL REASON CODES (x{EDI_204_210_STOP_OFF_DETAIL_REASON_CODES.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_204_210_STOP_OFF_DETAIL_REASON_CODES}
                                sourceName={'EDI-204-210-Stop-Off-Codes'}
                                lsKey={'@mctms-edi-204-210-Stop-Off-codes'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>EDI TRANSACTION SET PURPOSE CODES (x{EDI_TRANSACTION_SET_PURPOSE_CODES.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_TRANSACTION_SET_PURPOSE_CODES}
                                sourceName={'EDI-Transaction-Set-Purpose-Codes'}
                                lsKey={'@mctms-edi-transaction-set-purpose-codes'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>EDI TIMEZONE CODES (x{EDI_TIMEZONE_CODES.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_TIMEZONE_CODES}
                                sourceName={'EDI-Timezone-Codes'}
                                lsKey={'@mctms-edi-timezone-codes'}
                            />

                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header>EDI WEIGHT CODES (x{EDI_WEIGHT_CODES.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_WEIGHT_CODES}
                                sourceName={'EDI-Weight-Codes'}
                                lsKey={'@mctms-edi-weight-codes'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header>EDI EQUIPMENT CODES (x{EDI_EQUIPMENT_CODES.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_EQUIPMENT_CODES}
                                sourceName={'EDI-Equipment-Codes'}
                                lsKey={'@mctms-edi-equipment-codes'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                        <Accordion.Header>EDI BUSINESS INSTRUCTIONS REFERENCE NO CODES (x{EDI_BUSINESS_INSTRUCTIONS_REFERENCE_NO_CODES.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_BUSINESS_INSTRUCTIONS_REFERENCE_NO_CODES}
                                sourceName={'EDI-Business-Instruction-Codes'}
                                lsKey={'@mctms-edi-business-instruction-codes'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="10">
                        <Accordion.Header>EDI SHIPMENT METHOD OF PAYMENT (x{EDI_SHIPMENT_METHOD_OF_PAYMENT.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_SHIPMENT_METHOD_OF_PAYMENT}
                                sourceName={'EDI-Shipment-Method-Codes'}
                                lsKey={'@mctms-edi-shipment-method-codes'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="11">
                        <Accordion.Header>EDI STOP REASON CODE (x{EDI_STOP_REASON_CODE.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_STOP_REASON_CODE}
                                sourceName={'EDI-Stop-Codes'}
                                lsKey={'@mctms-edi-stop-codes'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowCodesModal(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const LoadTenderHelpModal = ({ helpModalState }) => {
    const { showHelpModal, setShowHelpModal } = helpModalState;
    return (
        <Modal size={'lg'} show={showHelpModal}>
            <Modal.Header closeButton onClick={() => setShowHelpModal(false)}>
                <h3>Help</h3>
            </Modal.Header>
            <Modal.Body>
                (Video library coming soon)
                <ListGroup>
                    <ListGroupItem>Using the Load Tender Dashboard</ListGroupItem>
                    <ListGroupItem>Assign Drivers</ListGroupItem>
                    <ListGroupItem>Remove Drivers</ListGroupItem>
                    <ListGroupItem>Auditing Load Tenders</ListGroupItem>
                    <ListGroupItem>Reject Load Tender</ListGroupItem>
                    <ListGroupItem>Sending EDI Messages</ListGroupItem>
                    <ListGroupItem>Sening FourKite Messages</ListGroupItem>
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowHelpModal(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const LoadTenderSearchModal = ({ shipmentFound, searchModalState, isSearchingState, searchShipmentIdState, handleSearchShipment }) => {
    const { isSearching } = isSearchingState
    const { showSearchModal, setShowSearchModal } = searchModalState;
    const { searchShipmentId, setSearchShipmentId } = searchShipmentIdState
    return (
        <Modal size={'sm'} show={showSearchModal}>
            <Modal.Header closeButton onClick={() => setShowSearchModal(false)}>
                <h3>Search</h3>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs="8">
                        <Form.Control
                            placeholder="Shipment ID"
                            type="text"
                            value={searchShipmentId}
                            onChange={(e) => setSearchShipmentId(e.target.value)}
                        />
                    </Col>
                    <Col xs="4">
                        {isSearching ?
                            <Spinner />
                            :
                            <Button onClick={() => handleSearchShipment()}>
                                Search
                            </Button>
                        }
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        {
                            !isSearching ?
                            (shipmentFound
                                ? 'Found'
                                : 'Not found'
                            ) :
                            'Searching'
                        }
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowSearchModal(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
const LoadTenderBanner = ({ helpModalState, codesModalState, tagsModalState, searchModalState }) => {
    const { setShowHelpModal } = helpModalState;
    const { setShowCodesModal } = codesModalState;
    const { setShowTagsModal } = tagsModalState;
    const { setShowSearchModal } = searchModalState

    const handlePrint = usePrint();
    return (
        <Card.Header>
            <Row className="align-items-center">
                <Col xs="auto">
                    <Card.Subtitle>LOAD TENDER DASHBOARD</Card.Subtitle>
                </Col>
                <Col>
                    <Badge bg={'danger'} style={{ float: 'right', marginRight: '1em' }} onClick={() => setShowHelpModal(true)}>
                        <IoMdHelpCircleOutline /> Help
                    </Badge>
                    <Badge bg={'warning'} style={{ float: 'right', marginRight: '1em' }} onClick={() => setShowCodesModal(true)}>
                        <FaInfo /> EDI
                    </Badge>
                    <Badge bg={'success'} style={{ float: 'right', marginRight: '1em' }} onClick={handlePrint}>
                        <FaSave /> Save
                    </Badge>
                    <Badge bg={'primary'} style={{ float: 'right', marginRight: '1em' }} onClick={() => setShowTagsModal(true)}>
                        <FaTags /> Tags
                    </Badge>
                    <Badge bg={'primary'} style={{ float: 'right', marginRight: '1em' }} onClick={() => setShowSearchModal(true)}>
                        <FaMagnifyingGlass /> Search All
                    </Badge>
                </Col>
            </Row>
        </Card.Header>
    )
}

const LoadTenderFilters = ({ queryState, contractsState, operatorsState, placesState, didFetchShipmentsState, dataGraphDisplayState, pageReset, tagsState, periodState }) => {
    const { dataDisplay, setDataDisplay } = dataGraphDisplayState;
    const { didFetchShipments, setDidFetchShipments } = didFetchShipmentsState
    const { query, setQuery } = queryState;
    const { contracts } = contractsState;
    const { operators } = operatorsState;
    const { places } = placesState;
    const { tags } = tagsState;
    const { period, setPeriod } = periodState;

    function incrementDate(dateStr) {
        const date = new Date(dateStr);
        date.setDate(date.getDate() + 1); // Add one day
        const incrementedDate = date.toISOString().split('T')[0]; // Format back to YYYY-MM-DD
        return incrementedDate;
    }
    function decrementDate(dateStr) {
        const date = new Date(dateStr);
        date.setDate(date.getDate() - 1); // Add one day
        const incrementedDate = date.toISOString().split('T')[0]; // Format back to YYYY-MM-DD
        return incrementedDate;
    }

    function addDay() {
        const newQueryStart = incrementDate(query.start);
        const newQueryEnd = incrementDate(query.end);
        setQuery({ ...query, start: newQueryStart, end: newQueryEnd })
        setPeriod('custom')
        setDidFetchShipments(false)
    }

    function subtractDay() {
        const newQueryStart = decrementDate(query.start);
        const newQueryEnd = decrementDate(query.end);
        setQuery({ ...query, start: newQueryStart, end: newQueryEnd })
        setPeriod('custom')
        setDidFetchShipments(false)
    }

    function updatePeriod(period) {
        setPeriod(period)
        switch (period) {
            case ('today'):
                setQuery({ ...query, start: formatDate(today), end: formatDate(today) })
                break;
            case ('tomorrow'):
                setQuery({ ...query, start: formatDate(tomorrow), end: formatDate(tomorrow) })
                break;
            case ('yesterday'):
                setQuery({ ...query, start: formatDate(yesterday), end: formatDate(yesterday) })
                break;
            case ('threeday'):
                setQuery({ ...query, start: formatDate(yesterday), end: formatDate(tomorrow) })
                break;
            case ('thisweek'):
                setQuery({ ...query, start: formatDate(thisSunday), end: formatDate(thisSaturday) })
                break;
            case ('lastweek'):
                setQuery({ ...query, start: formatDate(lastSunday), end: formatDate(lastSaturday) })
                break;
            case ('nextweek'):
                setQuery({ ...query, start: formatDate(nextSunday), end: formatDate(nextSaturday) })
                break;
            case ('thismonth'):
                setQuery({ ...query, start: formatDate(firstdayofthemonth), end: formatDate(lastdayofthemonth) })
                break;
            case ('lastmonth'):
                setQuery({ ...query, start: formatDate(firstdayoflastmonth), end: formatDate(lastdayoflastmonth) })
                break;
            case ('nextmonth'):
                setQuery({ ...query, start: formatDate(firstdayofnextmonth), end: formatDate(lastDayOfNextMonth) })
                break;
            case ('minus7'):
                setQuery({ ...query, start: formatDate(minusSeven), end: formatDate(today) })
                break;
            case ('minus14'):
                setQuery({ ...query, start: formatDate(minusFourteen), end: formatDate(today) })
                break;
            case ('minus30'):
                setQuery({ ...query, start: formatDate(minusThirty), end: formatDate(today) })
                break;
            case ('minus60'):
                setQuery({ ...query, start: formatDate(minusSixty), end: formatDate(today) })
                break;
            case ('minus90'):
                setQuery({ ...query, start: formatDate(minusNinty), end: formatDate(today) })
                break;
            case ('plus7'):
                setQuery({ ...query, start: formatDate(today), end: formatDate(plusSeven) })
                break;
            case ('plus14'):
                setQuery({ ...query, start: formatDate(today), end: formatDate(plusFourteen) })
                break;
            case ('plus30'):
                setQuery({ ...query, start: formatDate(today), end: formatDate(plusThirty) })
                break;
            case ('plus60'):
                setQuery({ ...query, start: formatDate(today), end: formatDate(plusSixty) })
                break;
            case ('plus90'):
                setQuery({ ...query, start: formatDate(today), end: formatDate(plusNinty) })
                break;
            case ('ytd'):
                setQuery({ ...query, start: formatDate(newyears), end: formatDate(today) })
                break;
            case ('q1'):
                setQuery({ ...query, start: formatDate(fiscalQuarters.q1.start), end: formatDate(fiscalQuarters.q1.end) })
                break;
            case ('q2'):
                setQuery({ ...query, start: formatDate(fiscalQuarters.q2.start), end: formatDate(fiscalQuarters.q2.end) })
                break;
            case ('q3'):
                setQuery({ ...query, start: formatDate(fiscalQuarters.q3.start), end: formatDate(fiscalQuarters.q3.end) })
                break;
            case ('q4'):
                setQuery({ ...query, start: formatDate(fiscalQuarters.q4.start), end: formatDate(fiscalQuarters.q4.end) })
                break;
            case ('year'):
                setQuery({ ...query, start: formatDate(newyears), end: formatDate(newyearseve) })
                break;
            default:
                setPeriod('custom')
        }
    }

    function updateQuery(q) {
        setQuery({ ...q })
        setDidFetchShipments(false)
    }

    const labelStyle = {
        display: 'block',
        fontSize: '0.9em',
        fontStyle: 'italic',
        textDecoration: 'underline'
    }

    const inputStyle = {
        fontSize: '0.9em',
        margin: '0em 0.1em',
    }

    const selectUpdateStyle = {
        fontSize: '1.1em',
        margin: '0em 0.1em',
        border: '2px solid #3366cc'
    }

    const selectStyle = {
        fontSize: '1.1em',
        margin: '0em 0.1em',
    }

    const dropdownAppliedStyle = {
        fontSize: '1.1em',
        margin: '0em 0.1em',
        backgroundColor: '#fff5bd',
        border: '2px solid #3366cc'
    }

    const buttonStyle = {
        color: '#fff',
        backgroundColor: '#0d6efd',
        border: '1px solid #0d6efd',
        margin: '0em 0.1em',
        fontSize: '0.9em'
    }

    const arrowButtonStyle = {
        color: '#fff',
        backgroundColor: '#0d6efd',
        border: '1px solid #0d6efd',
        margin: '0em 0.1em',
        fontSize: '0.9em'
    }

    const datagraphSelectedButtonStyle = {
        color: '#fff',
        backgroundColor: '#198754',
        border: '1px solid #198754',
        margin: '0em 0.1em',
        fontSize: '0.9em'
    }

    const datagraphButtonStyle = {
        color: '#fff',
        backgroundColor: 'rgba(25,135,84, 0.5)',
        border: '1px solid rgba(25,135,84, 0.5)',
        margin: '0em 0.1em',
        fontSize: '0.9em'
    }


    const pageResetButtonStyle = {
        color: '#fff',
        backgroundColor: '#ffc107',
        border: '1px solid #ffc107',
        margin: '0em 0.1em',
        fontSize: '0.9em'
    }

    return (
        <>
            <Row>
                <Col xs={'auto'}>
                    <label style={labelStyle}>Report</label>
                    <select
                        style={query.report === 'loadtender-all' ? selectUpdateStyle : dropdownAppliedStyle}
                        value={query.report}
                        onChange={(e) => updateQuery({ ...query, report: e.target.value })}>
                        {
                            LOAD_TENDER_REPORTS.map((rpt, i) => (
                                <option
                                    key={i}
                                    onChange={(e) => setQuery({ ...query, report: e.target.value })}
                                    value={rpt.name}
                                >
                                    {rpt.title}
                                </option>
                            ))
                        }
                    </select>
                </Col>
                <Col xs={'auto'}>
                    <label style={labelStyle}>Period</label>
                    <select style={selectStyle} value={period} onChange={(e) => updatePeriod(e.target.value)}>
                        <option value='custom' disabled>Custom</option>
                        <option value='today'>Today</option>
                        <option value='yesterday'>Yesterday</option>
                        <option value='tomorrow'>Tomorrow</option>
                        <option value='threeday'>3 Day</option>
                        <option value='thisweek'>This Week</option>
                        <option value='thismonth'>This Month</option>
                        <option value='nextweek'>Next Week</option>
                        <option value='nextmonth'>Next Month</option>
                        <option value='lastweek'>Last Week</option>
                        <option value='lastmonth'>Last Month</option>
                        <option value='minus7'>-7</option>
                        <option value='minus14'>-14</option>
                        <option value='minus30'>-30</option>
                        <option value='minus60'>-60</option>
                        <option value='minus90'>-90</option>
                        <option value='plus7'>+7</option>
                        <option value='plus14'>+14</option>
                        <option value='plus30'>+30</option>
                        <option value='plus60'>+60</option>
                        <option value='plus90'>+90</option>
                        <option value='q1'>Q1</option>
                        <option value='q2'>Q2</option>
                        <option value='q3'>Q3</option>
                        <option value='q4'>Q4</option>
                        <option value='ytd'>YTD</option>
                        <option value='year'>Year</option>
                    </select>
                </Col>
                <Col xs={'auto'}>
                    <label style={labelStyle}>Date Start</label>
                    <input style={inputStyle} type="date" value={query.start} onChange={e => {
                        setQuery({ ...query, start: e.target.value })
                        setPeriod('custom')
                    }} />
                </Col>
                <Col xs={'auto'}>
                    <label style={labelStyle}>Date End</label>
                    <input style={inputStyle} type="date" value={query.end} onChange={e => {
                        setQuery({ ...query, end: e.target.value })
                        setPeriod('custom')
                    }} />
                </Col>
                <Col xs={'auto'}>
                    <label style={labelStyle}>Status</label>
                    <select
                        style={query.loadtenderLifeCycleStatus === 'All' ? selectUpdateStyle : dropdownAppliedStyle}
                        value={query.loadtenderLifeCycleStatus}
                        onChange={e => updateQuery({ ...query, loadtenderLifeCycleStatus: e.target.value })}
                    >
                        <option value="All">All</option>
                        <option value="pending">Pending</option>
                        <option value="Accepted">Accepted</option>
                        <option value="Completed">Completed</option>
                        <option value="Rejected">Rejected</option>
                    </select>
                </Col>
                <Col xs={'auto'}>
                    <label style={labelStyle}>Contract</label>
                    <select
                        style={query.contract === 'All' ? selectUpdateStyle : dropdownAppliedStyle}
                        value={query.contract}
                        onChange={e => updateQuery({ ...query, 'contract': e.target.value })}
                    >
                        <option value="All">All</option>
                        {contracts.sort().map((contract, i) => (
                            <option key={i}>{contract}</option>
                        ))}
                    </select>
                </Col>
                <Col xs={'auto'}>
                    <label style={labelStyle}>State</label>
                    <select
                        style={query.state === 'All' ? selectUpdateStyle : dropdownAppliedStyle}
                        value={query.state}
                        onChange={e => updateQuery({ ...query, 'state': e.target.value })}
                    >
                        <option value="All">All</option>
                        {Array.from(new Set(places.map(place => place.state)))
                            .sort()
                            .map((state, i) => (
                                <option key={i} value={state}>{state}</option>
                            ))}
                    </select>
                </Col>
                <Col xs={'auto'}>
                    <label style={labelStyle}>City</label>
                    <select style={query.city === 'All' ? selectUpdateStyle : dropdownAppliedStyle}
                        value={query.city}
                        onChange={e => updateQuery({ ...query, 'city': e.target.value })}
                    >
                        <option value="All">All</option>
                        {places.sort((a, b) => {
                            if (a.state < b.state) return -1;
                            if (a.state > b.state) return 1;
                            if (a.city < b.city) return -1;
                            if (a.city > b.city) return 1;
                            return 0;
                        }).map((place, i) => (
                            query?.state === 'All'
                                ?
                                <option key={i} value={JSON.stringify(place)}>{place.city}, {place.state}</option>
                                :
                                query?.state === place.state &&
                                <option key={i} value={JSON.stringify(place)}>{place.city}, {place.state}</option>
                        ))}
                    </select>
                </Col>
                <Col xs={'auto'}>
                    <label style={labelStyle}>Driver</label>
                    <select
                        style={query.driver === 'All' ? selectUpdateStyle : dropdownAppliedStyle}
                        value={query.driver}
                        onChange={e => updateQuery({ ...query, 'driver': e.target.value })}
                    >
                        <option value="All">All</option>
                        {operators.sort((a, b) => {
                            if (a < b) return -1;
                            if (a > b) return 1;
                            return 0;
                        }).map((driver, i) => (
                            <option key={i} value={JSON.stringify(driver)}>{driver}</option>
                        ))}
                    </select>
                </Col>
                {/* <Col xs={'auto'}>
                    <label style={labelStyle}>Tags</label>
                    <select
                        style={query.tags.length === 0 ? selectStyle : dropdownAppliedStyle}
                        onChange={e => e.target.value === 'All' ? setQuery({ ...query, tags: [] }) : setQuery({ ...query, tags: [...query.tags, e.target.value] })}
                    >
                        <option value="All">All</option>
                        {tags.sort((a, b) => {
                            if (a < b) return -1;
                            if (a > b) return 1;
                            return 0;
                        }).map((tag, i) => (
                            <option key={i} value={tag.name}>{tag.name}</option>
                        ))}
                    </select>
                </Col> */}
                <Col xs={'auto'}>
                    <label style={labelStyle}>Click to Search</label>
                    {!didFetchShipments ?
                        <Spinner />
                        :
                        <button style={buttonStyle} onClick={() => setDidFetchShipments(false)}>
                            Search
                        </button>
                    }
                </Col>
                <Col xs={'auto'}>
                    <label style={labelStyle}>Change Dates</label>
                    {!didFetchShipments ?
                        <Spinner />
                        :
                        <>
                            <button style={arrowButtonStyle} onClick={() => subtractDay()}><FaArrowLeft /></button>
                            <button style={arrowButtonStyle} onClick={() => addDay()} ><FaArrowRight /></button>
                        </>
                    }
                </Col>
                <Col xs={'auto'}>
                    <label style={labelStyle}>Change Display</label>
                    <button style={dataDisplay === 'Graph' ? datagraphSelectedButtonStyle : datagraphButtonStyle} onClick={() => setDataDisplay('Graph')}>
                        Graph
                    </button>
                    <button style={dataDisplay === 'Gantt' ? datagraphSelectedButtonStyle : datagraphButtonStyle} onClick={() => setDataDisplay('Gantt')}>
                        Gantt
                    </button>
                    <button style={dataDisplay === 'Table' ? datagraphSelectedButtonStyle : datagraphButtonStyle} onClick={() => setDataDisplay('Table')}>
                        Table
                    </button>
                </Col>
                <Col xs={'auto'}>
                    <label style={labelStyle}>Reset</label>
                    <button style={pageResetButtonStyle} onClick={() => pageReset()}>Reset</button>
                </Col>
            </Row>
        </>

    )
}

const LoadTenderHistoryBar = ({ shipmentViewHistoryState, viewShipmentFromHistory }) => {

    const { shipmentViewHistory } = shipmentViewHistoryState

    if (shipmentViewHistory.length > 0) {
        return (
            <Row className="align-items-center">
                <Col xs="auto">
                    HISTORY
                </Col>
                {shipmentViewHistory.map((shipment, i) => (
                    <Col xs="auto">
                        <Button variant="link" key={i} onClick={() => viewShipmentFromHistory(shipment.ShipmentId)}>
                            {shipment['ContractTrip']}
                        </Button>
                    </Col>
                ))}
            </Row>
        )
    }

}

const LoadTenderChartsDisplay = ({ shipmentsState }) => {
    const { shipments } = shipmentsState;

    if (shipments.length === 0) {
        return (
            <Container fluid>
                <h3>
                    No data to display.
                </h3>
            </Container>
        )
    }

    function multidimensionalGroupBy(arr, groupby) {
        const grouped = {};
        arr.sort(function (a, b) {
            return a[groupby[0]] - b[groupby[0]];
        });
        arr.forEach(function (a) {
            groupby.reduce(function (o, g, i) {
                o[a[g]] = o[a[g]] || (i + 1 === groupby.length ? [] : {});
                return o[a[g]];
            }, grouped).push(a);
        });
        return grouped;
    }

    function googleChartDataKeyCount(shipments = [], key = [], headers = []) {
        const Groups = multidimensionalGroupBy(shipments, key);
        const GraphData = Object.keys(Groups).sort().map(key => ([`${key} (${Groups[key].length})`, Groups[key].length]));
        const ChartData = [headers, ...GraphData];
        return ChartData
    }

    function loadTenderCoverageChart(shipments) {
        let unassigned = 0, covered = 0;

        for (const record of shipments) {
            if (record['Coverage'].trim() !== '') {
                covered++;
            } else {
                unassigned++;
            }
        }

        const chartData = [['Coverage', 'Load Tender Ct.'], [`Open (${unassigned})`, unassigned], [`Covered (${covered})`, covered]];
        return chartData;
    }

    function loadTenderAuditChart(shipments) {
        let ungraded = 0, graded = 0;

        for (const record of shipments) {
            if (record.ediAudit && record.ediAudit.length > 0) {
                graded++;
            } else {
                ungraded++;
            }
        }

        const chartData = [['Audit', 'Load Tender Ct.'], [`Pending Audits (${ungraded})`, ungraded], [`Audit Complete (${graded})`, graded]];
        return chartData;
    }

    function loadTenderStopPunctualityPieChart(shipments) {
        const data = [['Appointments', 'Count']];
        const counts = { Missing: 0, Early: 0, Late: 0 };
        function processVia(via) {

        }

        for (const shipment of shipments) {
            for (const record of shipment?.ediAudit ?? []) {
                if (record?.linearizeLoadTenderAndLogs) {
                    record?.linearizeLoadTenderAndLogs.forEach(processVia);
                }
            }
        }

        for (const shipment of shipments) {
            const audits = shipment.ediAudit
            for (const audit of audits) {
                const telematics = audit.telematics;
                for (const report of telematics) {
                    const linearizeLoadTenderAndLogs = report?.linearizeLoadTenderAndLogs;
                    for (const via of linearizeLoadTenderAndLogs) {
                        const { eldOnPremiseArriveDiffMin, eldOnPremiseDepartDiffMin } = via;
                        const arriveDiff = Number(eldOnPremiseArriveDiffMin);
                        const departDiff = Number(eldOnPremiseDepartDiffMin);

                        if (isNaN(arriveDiff)) {
                            counts['Missing']++;
                        } else if (arriveDiff >= 0) {
                            counts['Early']++;
                        } else {
                            counts['Late']++;
                        }

                        if (isNaN(departDiff)) {
                            counts['Missing']++;
                        } else if (arriveDiff >= 0) {
                            counts['Early']++;
                        } else {
                            counts['Late']++;
                        }
                    }
                }
            }
        }

        for (const [category, count] of Object.entries(counts)) {
            if (count > 0) {
                data.push([`${category} (${count})`, count]);
            }
        }

        if (data.length === 1) {
            data.push(['No data', 1]);
        }

        return data;
    }


    function loadTenderPunctualityScatterChart(shipments) {
        const data = [['Arrival', 'Arrival, Departure', { role: 'style' }]];
        const goal = [0, 0, 'gold'];
        data.push(goal);
        for (const shipment of shipments) {
            const audits = shipment.ediAudit
            for (const audit of audits) {
                const telematics = audit.telematics;
                for (const report of telematics) {
                    const linearizeLoadTenderAndLogs = report?.linearizeLoadTenderAndLogs;
                    for (const via of linearizeLoadTenderAndLogs) {
                        const { eldOnPremiseArriveDiffMin, eldOnPremiseDepartDiffMin } = via;
                        const arrive = Number(eldOnPremiseArriveDiffMin);
                        const depart = Number(eldOnPremiseDepartDiffMin);

                        // Check if arrive and depart are valid numbers
                        if (!isNaN(arrive) && !isNaN(depart)) {
                            // Determine the color based on arrive and depart values
                            const color = getColorForPoint(arrive, depart);
                            data.push([arrive, depart, color]);
                        }
                    }
                }
            }
        }

        return data;
    }


    const statusChartData = googleChartDataKeyCount(shipments, ['Status'], ['Life Cycle Status', 'Load Tender Ct.'])
    const contractGroups = googleChartDataKeyCount(shipments, ['Contract'], ['Contract', 'Contract Ct.'])
    const equipmentGroups = googleChartDataKeyCount(shipments, ['Equipment'], ['Equipment', 'Equipment Ct.'])
    const dayOfWeekGroups = googleChartDataKeyCount(shipments, ['Day of Week'], ['Day of Week', 'Day of Week Ct.'])
    const serviceDateGroups = googleChartDataKeyCount(shipments, ['Service Date'], ['Service Date', 'Service Date Ct.'])
    const loadtenderCoverage = loadTenderCoverageChart(shipments);
    const loadtenderAudit = loadTenderAuditChart(shipments);
    const loadtenderPunctualityPieData = loadTenderStopPunctualityPieChart(shipments);
    const loadtenderPunctualityScatterData = loadTenderPunctualityScatterChart(shipments)

    return (
        <Container fluid>
            <Row>
                <Col sm={12} md={6} lg={3}>
                    <Chart
                        chartType={statusChartData.length > 7 ? 'BarChart' : 'PieChart'}
                        data={statusChartData}
                        options={{ title: `Load Tenders` }}
                        width="100%"
                        height="500px"
                    />
                </Col>
                <Col sm={12} md={6} lg={3}>
                    <Chart
                        chartType={'PieChart'}
                        data={loadtenderCoverage}
                        options={{ title: `Load Tender Coverage` }}
                        width="100%"
                        height="500px"
                    />
                </Col>
                <Col sm={12} md={6} lg={3}>
                    <Chart
                        chartType={dayOfWeekGroups.length > 7 ? 'BarChart' : 'PieChart'}
                        data={dayOfWeekGroups}
                        options={{ title: `Day of Week` }}
                        width="100%"
                        height="500px"
                    />
                </Col>
                <Col sm={12} md={6} lg={3}>
                    <Chart
                        chartType={serviceDateGroups.length > 7 ? 'BarChart' : 'PieChart'}
                        data={serviceDateGroups}
                        options={{ title: `Service Date` }}
                        width="100%"
                        height="500px"
                    />
                </Col>
                {contractGroups.length > 7 ?
                    <>
                        <Col sm={12}>
                            <Chart
                                chartType={'Bar'}
                                data={contractGroups}
                                options={{
                                    chart: {
                                        title: "Contract Trip Count",
                                        subtitle: "",
                                    },
                                    chartArea: { width: '50%' },
                                    hAxis: {
                                        title: "Count of Trips",
                                        minValue: 0,
                                    },
                                    vAxis: {
                                        title: "Contract",
                                    },
                                    legend: { position: "none" },
                                }}

                                width="100%"
                                height="500px"
                            />
                        </Col>
                        <hr />
                    </>
                    :
                    <Col sm={12} md={6} lg={4}>
                        <Chart
                            chartType={'PieChart'}
                            data={contractGroups}
                            options={{ title: `Contract Trip Count` }}
                            width="500px"
                            height="500px"
                        />
                    </Col>
                }

                {equipmentGroups.length > 7 ?
                    <>
                        <Col sm={12}>
                            <Chart
                                chartType={'Bar'}
                                data={equipmentGroups}
                                options={{
                                    chart: {
                                        title: "Equipment Used",
                                        subtitle: "",
                                    },
                                    chartArea: { width: '50%' },
                                    hAxis: {
                                        title: "Equipment Count",
                                        minValue: 0,
                                    },
                                    vAxis: {
                                        title: "Equipment",
                                        slantedText: true,
                                        slantedTextAngle: 45,
                                    },
                                    legend: { position: "none" },
                                }}
                                width="100%"
                                height="500px"
                            />
                        </Col>
                        <hr />
                    </>
                    :
                    <Col sm={12} md={6} lg={4}>
                        <Chart
                            chartType={'PieChart'}
                            data={equipmentGroups}
                            options={{ title: `Equipment Used` }}
                            width="500px"
                            height="500px"
                        />
                    </Col>
                }
            </Row>
            <hr />
            <Row>
                <Col style={{ textAlign: 'center' }}>
                    <i>Punctuality Charts Require Audits</i>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col sm={12} md={6}>
                    <Chart
                        chartType={'PieChart'}
                        data={loadtenderAudit}
                        options={{ title: `Load Tender Audits` }}
                        width="100%"
                        height="500px"
                    />
                </Col>
                <Col sm={12} md={6}>
                    <Chart
                        chartType={"PieChart"}
                        data={loadtenderPunctualityPieData}
                        options={{ title: `Load Tender Stops` }}
                        width="100%"
                        height="500px"
                    />
                </Col>
                <Col sm={12} md={6}>
                    <Chart
                        chartType="ScatterChart"
                        width="100%"
                        height="500px"
                        data={loadtenderPunctualityScatterData}
                        options={{
                            title: "Stop Punctuality (+ Early | - Late)",
                            hAxis: { title: "Arrival (minutes)" },
                            vAxis: { title: "Departure (minutes)" },
                            legend: "none",
                        }}
                    />
                </Col>
                <Col sm={12} md={6}>
                    <StopPunctualityLegend />
                </Col>
            </Row>
        </Container>
    )
}

const LoadTenderGanttDisplay = ({ shipmentsState }) => {
    const { shipments } = shipmentsState;

    if (shipments.length === 0) {
        return (
            <Container fluid>
                <h3>
                    No data to display.
                </h3>
            </Container>
        )
    }



    function loadtenderContractTripTimeline(shipments) {
        const data = [];
        for (let i = 0; i < shipments.length; i++) {
            const shipment = shipments[i];
            data.push({
                contract: shipment['Contract'],
                trip: shipment['Trip'],
                startdate: new Date(shipment.edi204[0]?.Stops[0]?.RequestedDate),
                enddate: new Date(shipment.edi204[0]?.Stops[shipment.edi204[0]?.Stops.length - 1]?.LastRequestedDate),
                ShipmentId: shipment.edi204[0]['ShipmentId'],
                Coverage: shipment.coverage.length > 0
            });
        }
        return data;
    }

    const loadtenderTimelineData = loadtenderContractTripTimeline(shipments);

    return (
        <>
            <Row>
                <Col xs="auto">
                    <i><FaInfo /> Gantt provides a graphical representation of a load tenders over time.</i>
                </Col>
                <Col xs="auto">
                    <b>Color Key</b>
                </Col>
                <Col xs="auto">
                    <div style={{ background: 'rgba(251,183,14,0.75)', padding: '0.1em', fontWeight: 'bold' }}>
                        Open Load
                    </div>
                </Col>
                <Col xs="auto">
                    <div style={{ background: 'rgba(110,182,255,0.75)', padding: '0.1em', fontWeight: 'bold' }}>
                        Covered Load
                    </div>
                </Col>
                {/* <Col xs="auto">
                    <div style={{ background: 'rgba(0,128,0,0.75)', padding: '0.1em', fontWeight:'bold' }}>
                        Early
                    </div>
                </Col>
                <Col xs="auto">
                    <div style={{ background: 'rgba(255,0,0,0.75)', padding: '0.1em', fontWeight:'bold' }}>
                        Late 
                    </div>
                </Col> 
                <Col xs="auto">
                    <div style={{ background: 'rgba(128,128,128,0.75)', padding: '0.1em', fontWeight:'bold' }}>
                        Complete 
                    </div>
                </Col>  */}
            </Row>

            <hr style={{ marginTop: '0.1em', marginBottom: '0.25em' }} />
            <Container fluid>

                <Row>
                    <Col>
                        <ShipmentGanttChart loadtenderTimelineData={loadtenderTimelineData} />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

const LoadTenderShipmentsDisplay = ({ queryState, shipmentsState }) => {
    const { query } = queryState;
    const { shipments } = shipmentsState;

    return (
        <Container fluid>
            {shipments ?
                <DataDisplayModal
                    sourceName={`MCTMS-Load-Tenders-${query?.start}-${query?.end}`}
                    dataSource={shipments}
                    lsKey={lsKey}
                    urlPath={`?ShipmentId=`}
                    urlKey={'_id'}
                    popKeys={['_id', 'coverage', 'edi204', 'edi214', 'edi990', 'edi210', 'telematics', 'fourkiteShipmentUpdates', 'ediAudit']}
                />
                :
                <h3>No data.</h3>
            }
        </Container>
    )
}

const LoadTenderShipmentModal = ({
    query,
    user,
    settings,
    shipmentState,
    shipmentModalState,
    ShipmentIdState,
    handleSearchReset,
    samsaraDriversState,
    samsaraDriversByLocationState,
    operatorOptionState,
    coverageOptionState,
    removeOptionState,
    handleCoverageAssign,
    handleCoverageRemove,
    handleShipmentReject,
    handleShipmentAccept,
    didFetchShipmentState,
    didFetchShipmentsState,
    geofenceState,
    circleGeofenceState,
    shipmentAudit,
    didFetchShipmentAuditState,
    appointmentToleranceState,
    searchToleranceState,
    handleManualEdiStatusUpdate,
    showManualEdiStatusUpdateState,
    ediMessageStopUpdateState,
    loadtenderWaypointsState,
    hosWaypointsState,
    allowTenderRejectState,
    handleCompletedLifeCycle,
    handleAcceptedLifeCycle,
    quietlyUpdateState,
    tagsState,
    handleAddShipmentTag,
    handleRemoveShipmentTag,
    handleSaveEdiAudit,
    activeTelematicsAuditState,
    goodAuditState,
    auditNoteState,
    handlePageToNextShipment,
    handlePageToPreviousShipment,
}) => {
    const { ShipmentId, setShipmentId } = ShipmentIdState
    const { shipment, setShipment } = shipmentState;
    const { showShipment, setShowShipment } = shipmentModalState;

    // const [activeShipment, setActiveShipment] = useState();
    const [driverassign, setDriverAssign] = useState(true);
    const [driverremove, setDriverRemove] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const { samsaraDrivers } = samsaraDriversState;
    const { samsaraDriversByLocation } = samsaraDriversByLocationState
    const [operatorlocation, setOperatorLocation] = useState('All');
    const { operatoroption, setOperatorOption } = operatorOptionState
    const { coverageoption, setCoverageOption } = coverageOptionState
    const { removeoption, setRemoveOption } = removeOptionState

    const { didFetchShipment } = didFetchShipmentState;
    const { didFetchShipments } = didFetchShipmentsState;
    const { didFetchShipmentAudit, setDidFetchShipmentAudit } = didFetchShipmentAuditState;

    const [active204, setActive204] = useState(0);
    const { searchTolerance, setSearchTolerance } = searchToleranceState;
    const { appointmentTolerance, setAppointmentTolerance } = appointmentToleranceState

    function updateSearchTolerance(value) {
        setSearchTolerance(value)
        setDidFetchShipmentAudit(false)
    }

    function updateAppointmentTolerance(value) {
        setAppointmentTolerance(value)
        setDidFetchShipmentAudit(false)
    }

    function handleShipmentModalClose() {
        setShipmentId('');
        setShowShipment(false);
        setShipment({});
        handleSearchReset()
    }

    const filteredSamsaraDrivers = useMemo(() => {
        if (samsaraDrivers && samsaraDrivers.length > 0) {
            return samsaraDrivers.filter(item =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        } else {
            return [];
        }
    }, [samsaraDrivers, searchTerm]);


    const { activeTelematicsAudit, setActiveTelematicsAudit } = activeTelematicsAuditState
    const { goodaudit, setGoodAudit } = goodAuditState;
    const { auditnote, setAuditNote } = auditNoteState;

    // useEffect(() => {
    //     const fetchWaypoints = async () => {
    //         if (!telematicsAudit) return;
    //         const data = await Promise.all(
    //             telematicsAudit.map(report => extractSamsaraHOSWaypoints(report?.hoslogs))
    //         );
    //         setHosWaypointsData(data);
    //     };
    //     fetchWaypoints();
    // }, [telematicsAudit]);

    const { quietlyUpdate, setQuietlyUpdate } = quietlyUpdateState

    const { loadtenderWaypoints, setLoadTenderWaypoints } = loadtenderWaypointsState;
    const { hosWaypoints, setHosWaypoints } = hosWaypointsState;


    const [showCoverage, setShowCoverage] = useState(true)
    const [showAudit, setShowAudit] = useState(true)
    const [showOperationsMap, setShowOperationsMap] = useState(false)
    const [showEdiMessages, setShowEdiMessages] = useState(false)
    const [showFourKiteMessages, setShowFourKiteMessages] = useState(false)

    const { allowTenderReject, setAllowTenderReject } = allowTenderRejectState;
    const [showEdiMessageModal, setShowEdiMessageModal] = useState(false);
    const [ediMessageModalVia, setEdiMessageModalVia] = useState({});
    function activateEdiMessageModal(via) {
        setEdiMessageModalVia(via)
        setShowEdiMessageModal(true)
    }
    const { ediMessageStopUpdate, setEdiMessageStopUpdate } = ediMessageStopUpdateState
    const { showManualEdiStatusUpdate, setShowManualEdiStatusUpdate } = showManualEdiStatusUpdateState
    const [showEdiTagModal, setShowEdiTagModal] = useState(false);


    const modalBodyStyle = {
        margin: '0.25em',
        padding: '0.25em'
    }



    function shipmentStopPunctualityScatterChart(linearizeLoadTenderAndLogs) {
        const goal = [0, 0, 'gold'];
        const chartData = [['Arrival', 'Arrival, Departure', { role: 'style' }], goal];

        for (const via of linearizeLoadTenderAndLogs) {
            const { eldOnPremiseArriveDiffMin, eldOnPremiseDepartDiffMin } = via;
            const arrive = Number(eldOnPremiseArriveDiffMin);
            const depart = Number(eldOnPremiseDepartDiffMin);

            // Check if arrive and depart are valid numbers
            if (!isNaN(arrive) && !isNaN(depart)) {
                // Determine the color based on arrive and depart values
                const color = getColorForPoint(arrive, depart);
                chartData.push([arrive, depart, color]);
            }
        }

        return chartData;
    }


    function shipmentStopPunctualityPieChart(linearizeLoadTenderAndLogs) {
        const headers = ["Appointments", "Ct", { role: "style" }];
        const data = [headers];
        let missing = 0, early = 0, late = 0;

        for (const via of linearizeLoadTenderAndLogs) {
            const arriveDiff = Number(via.eldOnPremiseArriveDiffMin);
            const departDiff = Number(via.eldOnPremiseDepartDiffMin);

            // Check if arriveDiff is a valid number
            if (!isNaN(arriveDiff)) {
                if (arriveDiff >= 0) early++;
                if (arriveDiff < 0) late++;
            } else {
                missing++;
            }

            // Check if departDiff is a valid number
            if (!isNaN(departDiff)) {
                if (departDiff >= 0) early++;
                if (departDiff < 0) late++;
            } else {
                missing++;
            }
        }

        data.push(['Missing', missing, 'grey']);
        data.push(['Early', early, 'green']);
        data.push(['Late', late, 'red']);

        return data;
    }


    const { tags } = tagsState;

    return (
        <Modal size={'fullscreen'} show={showShipment} onHide={() => handleShipmentModalClose()} style={{ zIndex: '10001' }}>
            <Modal.Header closeButton>
                <Modal.Title style={{ float: 'left' }}>
                    <span>
                        {!didFetchShipments ? <Spinner /> :
                            <>
                                Shipment ID : {shipment?._id} | Contract Trip : {shipment?.['Contract Trip']}
                            </>
                        }
                    </span>
                </Modal.Title>
            </Modal.Header>
            {(didFetchShipments && didFetchShipment && showShipment && shipment && shipment?._id) ?
                <Modal.Body style={modalBodyStyle}>
                    <>
                        <Modal
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={showEdiMessageModal}
                            style={{ backgroundColor: 'rgba(100, 100, 100, 0.75)', zIndex: '10002' }}
                        >
                            <Modal.Header closeButton onClick={() => setShowEdiMessageModal(false)}>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    EDI Manual Message
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <h4>Stop {ediMessageModalVia.StopNum} - {findUSPSNASSCode(ediMessageModalVia.Name)}</h4>
                                <br />
                                <Row>
                                    <Col style={{ textAlign: 'center' }}>
                                        <Button variant={ediMessageStopUpdate.action === 'eta' ? 'primary' : 'secondary'} onClick={() => setEdiMessageStopUpdate({ ...ediMessageStopUpdate, stopnum: ediMessageModalVia.StopNum, action: 'eta', })}>
                                            ETA
                                        </Button>
                                    </Col>
                                    <Col style={{ textAlign: 'center' }}>
                                        <Button variant={ediMessageStopUpdate.action === 'arrive' ? 'primary' : 'secondary'} onClick={() => {
                                            setEdiMessageStopUpdate({ ...ediMessageStopUpdate, stopnum: ediMessageModalVia.StopNum, action: 'arrive', });
                                        }}>
                                            Arrive
                                        </Button>
                                    </Col>
                                    <Col style={{ textAlign: 'center' }}>
                                        <Button variant={ediMessageStopUpdate.action === 'depart' ? 'primary' : 'secondary'} onClick={() => setEdiMessageStopUpdate({ ...ediMessageStopUpdate, stopnum: ediMessageModalVia.StopNum, action: 'depart', })}>
                                            Depart
                                        </Button>
                                    </Col>
                                </Row>
                                <br />
                                {ediMessageStopUpdate.action === 'arrive' && (shipmentAudit[0]?.linearizeLoadTenderAndLogs[ediMessageModalVia.StopNum - 1]?.eldOnPremiseDataStart?.time) &&
                                    <Row>
                                        <Col>
                                            <Badge
                                                style={{ fontSize: '1em' }}
                                                onClick={() => setEdiMessageStopUpdate({ ...ediMessageStopUpdate, timestamp: formatShipmentArriveDepartDateForInput(shipmentAudit[0].linearizeLoadTenderAndLogs[ediMessageModalVia.StopNum - 1].eldOnPremiseDataStart?.time, settings.timezone) })}>Use detected Arrival</Badge>
                                        </Col>
                                    </Row>
                                }
                                {ediMessageStopUpdate.action === 'depart' && (shipmentAudit[0]?.linearizeLoadTenderAndLogs[ediMessageModalVia.StopNum - 1]?.eldOnPremiseDataEnd?.time) &&
                                    <Row>
                                        <Col>

                                            <Badge
                                                style={{ fontSize: '1em' }}
                                                onClick={() => setEdiMessageStopUpdate({ ...ediMessageStopUpdate, timestamp: formatShipmentArriveDepartDateForInput(shipmentAudit[0]?.linearizeLoadTenderAndLogs[ediMessageModalVia.StopNum - 1].eldOnPremiseDataEnd?.time, settings.timezone) })}>Use detected departure</Badge>
                                        </Col>
                                    </Row>
                                }
                                <br />
                                <Row>
                                    <Col sm={10}>
                                        <Form.Control
                                            type="datetime-local"
                                            value={ediMessageStopUpdate?.timestamp}
                                            onChange={(e) => setEdiMessageStopUpdate({ ...ediMessageStopUpdate, timestamp: e.target.value })}
                                        />
                                    </Col>
                                    <Col sm={2}>
                                        <Button variant={'warning'} onClick={() => setEdiMessageStopUpdate({ ...ediMessageStopUpdate, timestamp: '' })}>
                                            X
                                        </Button>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                {
                                    showManualEdiStatusUpdate
                                        ? <Button variant="primary" onClick={() => handleManualEdiStatusUpdate()}>Send Update</Button>
                                        : <Spinner />
                                }
                                <Button variant="secondary" onClick={() => setShowEdiMessageModal(false)}>Close</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={showEdiTagModal}
                            style={{ backgroundColor: 'rgba(100, 100, 100, 0.75)', zIndex: '10002' }}
                        >
                            <Modal.Header closeButton onClick={() => setShowEdiTagModal(false)}>Tag Shipment</Modal.Header>
                            <Modal.Body>
                                Tags applied:
                                <Row>

                                    {shipment.edi204[0]?.ediTags && shipment.edi204[0].ediTags.map((t, i) => (
                                        <Col xs="auto" key={i} style={{ padding: '0.25em' }}>
                                            <Tag
                                                name={t.name}
                                                fontColorHEX={t.fontColorHEX}
                                                backgroundColorHEX={t.backgroundColorHEX}
                                                handleFunction={() => handleRemoveShipmentTag(t)}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                                <hr />
                                Available tags:
                                <Row>
                                    {tags.map((t, i) => (

                                        <Col xs="auto" key={i} style={{ padding: '0.25em' }}>
                                            <Tag
                                                name={t.name}
                                                fontColorHEX={t.fontColorHEX}
                                                backgroundColorHEX={t.backgroundColorHEX}
                                                handleFunction={() => handleAddShipmentTag(t)}
                                            />
                                        </Col>

                                    ))}
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowEdiTagModal(false)}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <div style={{ margin: '0.5em' }}>

                            <Row>
                                {shipment.edi204[0]?.ediTags && shipment.edi204[0]?.ediTags.map((t, i) => (
                                    <Col xs="auto" key={i} style={{ padding: '0.25em' }}>
                                        <Tag
                                            name={t.name}
                                            fontColorHEX={t.fontColorHEX}
                                            backgroundColorHEX={t.backgroundColorHEX}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </div>
                        {shipment.edi204[0].loadtenderLifeCycleStatus !== 'Rejected' &&
                            <>
                                <Card>
                                    <Card.Header>
                                        <Row>
                                            <Col>
                                                <Card.Title>Coverage : {shipment?.coverage.length}</Card.Title>
                                            </Col>
                                            <Col style={{ textAlign: 'right' }}>
                                                <Button
                                                    variant={showCoverage ? 'secondary' : 'primary'}
                                                    style={{ float: 'right' }}
                                                    onClick={() => setShowCoverage(!showCoverage)}
                                                >
                                                    {showCoverage ? 'Hide' : 'Show'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>
                                        {showCoverage &&
                                            <>
                                                <Form.Check checked={driverassign} label={'Assign Drivers'} onChange={() => setDriverAssign(!driverassign)} />
                                                <Form.Check checked={driverremove} label={'Remove Drivers'} onChange={() => setDriverRemove(!driverremove)} />
                                                {driverassign &&
                                                    <>
                                                        <Card.Header>
                                                            Assign Driver
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <Row>
                                                                <Col xs={12} md={6} lg={4}>
                                                                    <Row>
                                                                        <Col sm={12} lg={6}>
                                                                            Location
                                                                            <Form.Select onChange={(e) => setOperatorLocation(e.target.value)}>
                                                                                <option>All</option>
                                                                                {samsaraDriversByLocation && Object.keys(samsaraDriversByLocation).map((key, k) => (
                                                                                    <option key={k} value={key}>{key ? key : 'Undefined'}</option>
                                                                                ))}
                                                                            </Form.Select>
                                                                        </Col>
                                                                        <Col sm={12} lg={6}>
                                                                            Driver
                                                                            {operatorlocation === 'All'
                                                                                ?
                                                                                <Row>
                                                                                    <Col>
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            placeholder="Search..."
                                                                                            value={searchTerm}
                                                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                                                        />
                                                                                        {searchTerm.trim('') !== '' &&
                                                                                            <div style={{ maxHeight: '200px', overflowY: 'auto', margin: '0.5em', padding: '0.1em' }}>
                                                                                                {filteredSamsaraDrivers.map((item, i) => (
                                                                                                    <Row key={i} style={{ margin: '0.1em' }}>
                                                                                                        <Button variant="secondary" onClick={() => {
                                                                                                            setOperatorOption(JSON.stringify(item));
                                                                                                            setSearchTerm(item.name);
                                                                                                        }}
                                                                                                        >{item.name}</Button>
                                                                                                    </Row>
                                                                                                ))}
                                                                                            </div>
                                                                                        }
                                                                                    </Col>
                                                                                    {searchTerm.trim('') !== '' &&
                                                                                        <Col xs="auto">
                                                                                            <Button variant="warning" onClick={() => setSearchTerm('')}>
                                                                                                X
                                                                                            </Button>
                                                                                        </Col>

                                                                                    }

                                                                                </Row>
                                                                                :
                                                                                <Form.Select onChange={(e) => e.target.value !== '' && setOperatorOption(e.target.value)}>
                                                                                    <option value=''>Select...</option>
                                                                                    {samsaraDriversByLocation[operatorlocation] && samsaraDriversByLocation[operatorlocation].sort((a, b) => a.name.localeCompare(b.name)).map((driver, i) => (
                                                                                        <option key={i} value={JSON.stringify(driver)}>{driver.name}</option>
                                                                                    ))}
                                                                                </Form.Select>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col xs={12} md={6} lg={4}>
                                                                    Coverage Reason
                                                                    <Form.Select onChange={(e) => setCoverageOption(e.target.value)}>
                                                                        <option value=''>Select...</option>
                                                                        <option>Needs Coverage</option>
                                                                        <option>Recovery</option>
                                                                    </Form.Select>
                                                                </Col>
                                                                <Col xs={12} md={6} lg={4}>
                                                                    <br />
                                                                    <Button onClick={() => handleCoverageAssign()}>Assign</Button>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </>

                                                }
                                                {driverremove &&
                                                    <>
                                                        <Card.Header>
                                                            Remove Driver
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <Row>
                                                                <Col xs={12} md={6} lg={4}>
                                                                    Driver
                                                                    <Form.Select onChange={(e) => setOperatorOption(e.target.value)}>
                                                                        <option>Select...</option>
                                                                        {shipment?.coverage && shipment?.coverage.map((driver, i) => (
                                                                            <option key={i} value={JSON.stringify(driver)}>{driver.name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Col>
                                                                <Col xs={12} md={6} lg={4}>
                                                                    Remove Reason
                                                                    <Form.Select onChange={(e) => setRemoveOption(e.target.value)}>
                                                                        <option>Select...</option>
                                                                        <option>Call Off</option>
                                                                        <option>Double Booked</option>
                                                                        <option>Reassigned</option>
                                                                    </Form.Select>
                                                                </Col>
                                                                <Col xs={12} md={6} lg={4}>
                                                                    <br />
                                                                    <Button variant="warning" onClick={() => handleCoverageRemove()}>Remove</Button>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </>
                                                }
                                                <hr />
                                            </>
                                        }
                                        <div>
                                            {shipment?.coverage.length === 0 &&
                                                <Badge
                                                    style={{ fontSize: '1em' }}
                                                    bg={'warning'}>
                                                    Assign a driver to this load tender.
                                                </Badge>
                                            }
                                            {shipment?.coverage.map((message, i) => (
                                                <div style={{ fontSize: '1.2em', margin: '0.5em', fontWeight: 'bold' }} key={i}>
                                                    {message.name}
                                                </div>
                                            ))}
                                        </div>
                                    </Card.Body>
                                </Card>
                                <br />
                            </>
                        }
                        {['Accepted', 'Completed'].includes(shipment.edi204[0].loadtenderLifeCycleStatus) &&
                            <>
                                <Card>
                                    <Card.Header>
                                        <Row>
                                            <Col>
                                                <Card.Title>Telematics Audit</Card.Title>
                                            </Col>
                                            <Col style={{ textAlign: 'right' }}>
                                                <Button
                                                    variant={showAudit ? 'secondary' : 'primary'}
                                                    style={{ float: 'right' }}
                                                    onClick={() => setShowAudit(!showAudit)}
                                                >
                                                    {showAudit ? 'Hide' : 'Show'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    {showAudit &&
                                        <>
                                            <Card.Body>
                                                <Row>
                                                    <Col>
                                                        Live Audit <br />
                                                        <Button variant={activeTelematicsAudit === 'live' ? 'primary' : 'secondary'} onClick={() => setActiveTelematicsAudit('live')}>
                                                            Live
                                                        </Button>
                                                    </Col>
                                                    <Col style={{ textAlign: 'right' }}>
                                                        Saved Audits
                                                        <Row style={{ justifyContent: 'flex-end' }}>
                                                            {shipment?.ediAudit.length > 0 ?
                                                                shipment?.ediAudit.map((_, i) => (
                                                                    <Col xs="auto">
                                                                        <Button variant={activeTelematicsAudit === i ? 'primary' : 'secondary'} key={i} onClick={() => setActiveTelematicsAudit(i)}>
                                                                            {i + 1}
                                                                        </Button>
                                                                    </Col>
                                                                ))
                                                                :
                                                                'No audits have been saved.'
                                                            }
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                {activeTelematicsAudit === 'live' ?
                                                    <>
                                                        {shipment.coverage.length > 0 &&
                                                            <React.Fragment>
                                                                <Row className="align-items-center">
                                                                    <Col xs="auto">
                                                                        Search Tolerance
                                                                        <Row className="align-items-center">
                                                                            <Col xs="auto">
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    value={searchTolerance}
                                                                                    step={0.25}
                                                                                    min={1}
                                                                                    max={10}
                                                                                    style={{ width: '100px' }}
                                                                                    readOnly
                                                                                    disabled
                                                                                />
                                                                            </Col>
                                                                            <Col xs="auto">
                                                                                <Button variant={'primary'} onClick={() => updateSearchTolerance(searchTolerance + 0.25 < 10 ? searchTolerance + 0.25 : 10)}><FaPlus /></Button>
                                                                                <Button variant={'secondary'} onClick={() => updateSearchTolerance(searchTolerance - 0.25 > 0.25 ? searchTolerance - 0.25 : 0.25)}><FaMinus /></Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col xs="auto">
                                                                        Appointment Tolerance
                                                                        <Row className="align-items-center">
                                                                            <Col xs="auto">
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    value={appointmentTolerance}
                                                                                    step={0.25}
                                                                                    min={0.25}
                                                                                    max={10}
                                                                                    style={{ width: '100px' }}
                                                                                    readOnly
                                                                                    disabled
                                                                                />
                                                                            </Col>
                                                                            <Col xs="auto">
                                                                                <Button variant={'primary'} onClick={() => updateAppointmentTolerance(appointmentTolerance + 0.25 < 5 ? appointmentTolerance + 0.25 : 5)}><FaPlus /></Button>
                                                                                <Button variant={'secondary'} onClick={() => updateAppointmentTolerance(appointmentTolerance - 0.25 > 0.25 ? appointmentTolerance - 0.25 : 0.25)}><FaMinus /></Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col xs="auto">
                                                                        Grade
                                                                        <Form.Check checked={goodaudit === 'Pass'} label={'Pass'} onChange={() => setGoodAudit('Pass')} />
                                                                        <Form.Check checked={goodaudit === 'Fail'} label={'Fail'} onChange={() => setGoodAudit('Fail')} />
                                                                    </Col>
                                                                    <Col xs="auto">
                                                                        Audit Note
                                                                        <Form.Control
                                                                            style={{ minWidth: '200px' }}
                                                                            as="textarea"
                                                                            rows={2}
                                                                            placeholder="Enter text here"
                                                                            value={auditnote}
                                                                            onChange={e => setAuditNote(e.target.value)}
                                                                        />
                                                                    </Col>
                                                                    <Col xs="auto" style={{ textAlign: 'right' }}>
                                                                        Save Audit <br />
                                                                        <Button variant="primary" onClick={() => handleSaveEdiAudit()}>
                                                                            Save
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        }
                                                        <Row>
                                                            {!didFetchShipmentAudit
                                                                ?
                                                                <LogisticsLoading message={'Loading Telematics...'} />
                                                                :
                                                                <>
                                                                    {shipment.coverage.length === 0 &&
                                                                        <div>
                                                                            <Badge bg={'warning'} style={{ fontSize: '1.1em', margin: '0.1em' }}> Assign a driver to this load tender. </Badge>
                                                                        </div>
                                                                    }

                                                                    {shipmentAudit.length === 0 &&
                                                                        <React.Fragment>
                                                                            <Card.Body>
                                                                                <Col>
                                                                                    {shipmentAudit.some(report => report.operator.length > 0) &&
                                                                                        <div>
                                                                                            <Badge bg={'danger'} style={{ fontSize: '1.1em', margin: '0.1em' }}> Driver not tracking. </Badge>
                                                                                        </div>
                                                                                    }

                                                                                    {new Date(shipment.edi204[0].Stops[0].RequestedDate) >= new Date() &&
                                                                                        <div>
                                                                                            <Badge bg={'warning'} style={{ fontSize: '1.1em', margin: '0.1em' }}> Load Tender is in the future. </Badge>
                                                                                        </div>
                                                                                    }
                                                                                </Col>
                                                                            </Card.Body>
                                                                            <pre>
                                                                                <Table>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            {(shipment?.edi204[0]?.loadtenderLifeCycleStatus === 'Accepted' && shipment?.coverage.length > 0)
                                                                                                &&
                                                                                                <th style={{ backgroundColor: '#eee' }}>EDI</th>

                                                                                            }
                                                                                            <th style={{ backgroundColor: '#eee' }}>Stop</th>
                                                                                            <th style={{ backgroundColor: '#eee' }}>Location</th>
                                                                                            <th style={{ backgroundColor: '#eee' }}>Sched. Arrive</th>
                                                                                            <th style={{ backgroundColor: '#eee' }}>Sched. Depart</th>
                                                                                            <th style={{ backgroundColor: '#eee' }}>EDI Arrive</th>
                                                                                            <th style={{ backgroundColor: '#eee' }}>EDI Depart</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {shipment.edi204[0].Stops.map((via, i) => (
                                                                                            <tr key={`${i}`}>
                                                                                                {(shipment?.edi204[0]?.loadtenderLifeCycleStatus === 'Accepted' && shipment?.coverage.length > 0)
                                                                                                    &&
                                                                                                    <td>
                                                                                                        <Button bg={'primary'} onClick={() => activateEdiMessageModal(via)}><BiMailSend /></Button>
                                                                                                    </td>
                                                                                                }
                                                                                                <td>{via?.StopNum} </td>
                                                                                                <td>{findUSPSNASSCode(via?.Name)}</td>
                                                                                                <td>{formatUSPSDateTime(via?.Name, via?.RequestedDate)}</td>
                                                                                                <td>{formatUSPSDateTime(via?.Name, via?.LastRequestedDate)}</td>
                                                                                                <td>{via?.ediStopArrived ? 'True' : 'False'}</td>
                                                                                                <td>{via?.ediStopDelivered ? 'True' : 'False'}</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </Table>
                                                                            </pre>
                                                                        </React.Fragment>
                                                                    }

                                                                    {shipment.coverage.length === 0 &&
                                                                        <div>
                                                                            <Badge bg={'warning'} style={{ fontSize: '1.1em', margin: '0.1em' }}> Assign a driver to this load tender. </Badge>
                                                                        </div>
                                                                    }

                                                                    {shipmentAudit.length > 0 && shipmentAudit[0].linearizeLoadTenderAndLogs.length === 0 &&
                                                                        <React.Fragment>

                                                                            <Row>
                                                                                <Col xs="auto">
                                                                                    Driver's Log: <pre>
                                                                                        {Object.keys(shipmentAudit[0]?.hoslogStatusTotals).map((key, i) => (
                                                                                            <div key={i}>
                                                                                                {key} : {convertMillisecondsToHoursMinutes(shipmentAudit[0].hoslogStatusTotals[key])}<br />
                                                                                            </div>
                                                                                        ))}
                                                                                    </pre>
                                                                                </Col>
                                                                            </Row>
                                                                        </React.Fragment>
                                                                    }

                                                                    {shipmentAudit.length > 0 && shipmentAudit.map((report, i) => (
                                                                        report?.linearizeLoadTenderAndLogs.length > 0 &&
                                                                        <React.Fragment key={i}>
                                                                            {report?.operator &&
                                                                                <React.Fragment>
                                                                                    <React.Fragment>

                                                                                        <pre>
                                                                                            <Table>
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th style={{ backgroundColor: '#eee' }}>Stop</th>
                                                                                                        <th style={{ backgroundColor: '#eee' }}>Location</th>
                                                                                                        <th style={{ backgroundColor: '#eee' }}>EDI Arrive</th>
                                                                                                        <th style={{ backgroundColor: '#eee' }}>EDI Depart</th>
                                                                                                        <th style={{ backgroundColor: '#eee' }}>On Geofence Pings</th>
                                                                                                        <th style={{ backgroundColor: '#eee' }}>Sched. Arrive</th>
                                                                                                        <th style={{ backgroundColor: '#eee' }}>GPS Arrival</th>
                                                                                                        <th style={{ backgroundColor: '#eee' }}>+/-</th>
                                                                                                        <th style={{ backgroundColor: '#eee' }}>Sched. Depart</th>
                                                                                                        <th style={{ backgroundColor: '#eee' }}>GPS Departure</th>
                                                                                                        <th style={{ backgroundColor: '#eee' }}>+/-</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {shipmentAudit[0].linearizeLoadTenderAndLogs.map((via, j) => (
                                                                                                        <tr key={`${j}`}>
                                                                                                            <td>{via?.StopNum}</td>
                                                                                                            <td>{findUSPSNASSCode(via?.Name)}</td>
                                                                                                            <td>{via?.ediStopArrived ?
                                                                                                                <>
                                                                                                                    True ✅
                                                                                                                </> :
                                                                                                                <>
                                                                                                                    False ❌ <Button bg={'primary'} onClick={() => activateEdiMessageModal(via)}><BiMailSend /></Button>
                                                                                                                </>
                                                                                                            }</td>
                                                                                                            <td>{via?.ediStopDelivered ?
                                                                                                                <>
                                                                                                                    True ✅
                                                                                                                </> :
                                                                                                                <>
                                                                                                                    False ❌ <Button bg={'primary'} onClick={() => activateEdiMessageModal(via)}><BiMailSend /></Button>
                                                                                                                </>
                                                                                                            }</td>
                                                                                                            <td>{via?.eldOnPremiseCt}</td>
                                                                                                            <td>{formatUSPSDateTime(via?.Name, via?.RequestedDate)}</td>
                                                                                                            <td style={{ backgroundColor: '#eee' }}>
                                                                                                                {via?.eldOnPremiseDataStart?.time &&
                                                                                                                    `${formatZuluToTZ(via?.eldOnPremiseDataStart?.time, findUSPSNassCodeTimezoneIANA(via?.Name))}`
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td style={
                                                                                                                via?.eldOnPremiseArriveDiffMin > 0
                                                                                                                    ? { backgroundColor: '#bceebc' }
                                                                                                                    : { backgroundColor: '#f39c9c' }
                                                                                                            }>{via?.eldOnPremiseArriveDiffMin}</td>
                                                                                                            <td>{formatUSPSDateTime(via?.Name, via?.LastRequestedDate)}</td>
                                                                                                            <td style={{ backgroundColor: '#efefef' }}>
                                                                                                                {via?.eldOnPremiseDataEnd?.time &&
                                                                                                                    `${formatZuluToTZ(via?.eldOnPremiseDataEnd?.time, findUSPSNassCodeTimezoneIANA(via?.Name))}`
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td style={
                                                                                                                via?.eldOnPremiseDepartDiffMin > 0
                                                                                                                    ? { backgroundColor: '#bceebc' }
                                                                                                                    : { backgroundColor: '#f39c9c' }
                                                                                                            }>{via?.eldOnPremiseDepartDiffMin}</td>
                                                                                                        </tr>
                                                                                                    ))}
                                                                                                </tbody>
                                                                                            </Table>
                                                                                        </pre>
                                                                                        <Row>
                                                                                            <Col sm={12} lg={4}>
                                                                                                <Chart
                                                                                                    chartType={"PieChart"}
                                                                                                    data={shipmentStopPunctualityPieChart(shipmentAudit[0].linearizeLoadTenderAndLogs)}
                                                                                                    options={{
                                                                                                        title: `Load Tender Stop Punctuality`,
                                                                                                        colors: ['grey', 'green', 'red'],
                                                                                                    }}
                                                                                                    width="100%"
                                                                                                    height="500px"
                                                                                                />
                                                                                            </Col>
                                                                                            <Col sm={12} lg={4}>
                                                                                                <Chart
                                                                                                    chartType="ScatterChart"
                                                                                                    width="100%"
                                                                                                    height="500px"
                                                                                                    data={shipmentStopPunctualityScatterChart(shipmentAudit[0].linearizeLoadTenderAndLogs)}
                                                                                                    options={{
                                                                                                        title: "Stop Punctuality (+ Early | - Late)",
                                                                                                        hAxis: { title: "Arrival (minutes)" },
                                                                                                        vAxis: { title: "Departure (minutes)" },
                                                                                                        legend: "none",
                                                                                                    }}
                                                                                                />
                                                                                            </Col>
                                                                                            <Col sm={12} lg={4}>
                                                                                                <StopPunctualityLegend />
                                                                                            </Col>
                                                                                        </Row>

                                                                                    </React.Fragment>
                                                                                    <Row>
                                                                                        <Col sm={12} md={6} style={{ order: 2 }}>
                                                                                            <Table bordered responsive hover>
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th style={{ backgroundColor: '#f9f9f9' }}><Card.Title>Driver</Card.Title></th>
                                                                                                        <th style={{ backgroundColor: '#f9f9f9' }}><Card.Title>{report?.operator?.name}</Card.Title></th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <th>Contact Trip</th>
                                                                                                        <td>{shipment['Contract Trip']}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <th>ShipmentId</th>
                                                                                                        <td>{report?.shipmentId}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <th>Search Tolerance </th>
                                                                                                        <td>
                                                                                                            {searchTolerance}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <th>Appointment Tolerance</th>
                                                                                                        <td>
                                                                                                            {appointmentTolerance}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <th>Search Start</th>
                                                                                                        <td>{report?.hoslogSearchStart}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <th>Search End</th>
                                                                                                        <td>{report?.hoslogSearchEnd}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <th>Total Search Time</th>
                                                                                                        <td>{convertMillisecondsToHoursMinutes(report?.hoslogTotalTime)}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <th>Vehicles</th>
                                                                                                        <td>
                                                                                                            {report?.hoslogVehicleData?.data
                                                                                                                ? report?.hoslogVehicleData?.data.map(d => d.name).join(', ')
                                                                                                                : 'No vehicles'
                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <th>Work Start</th>
                                                                                                        <td>{report?.hoslogWorkStart}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <th>Work End</th>
                                                                                                        <td>{report?.hoslogWorkEnd}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <th>Duty Status</th>
                                                                                                        <td>
                                                                                                            {Object.keys(report?.hoslogStatusTotals).map((key, i) => (
                                                                                                                <>
                                                                                                                    {key} : {convertMillisecondsToHoursMinutes(report?.hoslogStatusTotals[key])}<br />
                                                                                                                </>
                                                                                                            ))}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <th>HOS Work Time</th>
                                                                                                        <td>{convertMillisecondsToHoursMinutes(report?.hoslogTimeWorked)}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <th>Load Tender Work Time</th>
                                                                                                        <td></td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <th>Load Tender SOP</th>
                                                                                                        <td></td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <th>SOP Over/Under</th>
                                                                                                        <td></td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </Table>
                                                                                        </Col>
                                                                                        <Col sm={12} md={6} style={{ order: 1 }}>
                                                                                            <Card style={{ borderRadius: 0, margin: 0, padding: 0 }} >
                                                                                                <Card.Header>{report?.operator?.name} Hours of Service Log</Card.Header>
                                                                                                <Card.Body>
                                                                                                    <DriverHOSLog hoslogs={report?.hoslogs} />
                                                                                                </Card.Body>
                                                                                            </Card>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </React.Fragment>
                                                                            }
                                                                        </React.Fragment>
                                                                    ))}
                                                                </>
                                                            }
                                                        </Row>
                                                    </>
                                                    :
                                                    <Row>
                                                        {shipment?.ediAudit && shipment?.ediAudit.length > 0 ?
                                                            shipment?.ediAudit[activeTelematicsAudit].telematics.map((report, i) =>
                                                                (report?.operator) &&
                                                                <React.Fragment key={i}>
                                                                    <React.Fragment>
                                                                        <React.Fragment>
                                                                            <Row className="align-items-center">
                                                                                <Col xs="auto">
                                                                                    Search Tolerance
                                                                                    <Row className="align-items-center">
                                                                                        <Col xs="auto">
                                                                                            <Form.Control
                                                                                                type="number"
                                                                                                value={report?.searchTolerance}
                                                                                                step={0.25}
                                                                                                min={1}
                                                                                                max={10}
                                                                                                style={{ width: '100px' }}
                                                                                                readOnly
                                                                                                disabled
                                                                                            />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                                <Col xs="auto">
                                                                                    Appointment Tolerance
                                                                                    <Row className="align-items-center">
                                                                                        <Col xs="auto">
                                                                                            <Form.Control
                                                                                                type="number"
                                                                                                value={report?.appointmentTolerance}
                                                                                                step={0.25}
                                                                                                min={0.25}
                                                                                                max={10}
                                                                                                style={{ width: '100px' }}
                                                                                                readOnly
                                                                                                disabled
                                                                                            />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                                <Col xs="auto">
                                                                                    Grade
                                                                                    <Form.Check
                                                                                        checked={shipment?.ediAudit[activeTelematicsAudit].goodaudit === 'Pass'}
                                                                                        label={'Pass'}
                                                                                        readOnly
                                                                                        disabled
                                                                                    />
                                                                                    <Form.Check
                                                                                        checked={shipment?.ediAudit[activeTelematicsAudit].goodaudit === 'Fail'}
                                                                                        label={'Fail'}
                                                                                        readOnly
                                                                                        disabled
                                                                                    />
                                                                                </Col>
                                                                                <Col xs="auto">
                                                                                    Audit Note
                                                                                    <Form.Control
                                                                                        style={{ minWidth: '200px' }}
                                                                                        as="textarea"
                                                                                        rows={2}
                                                                                        placeholder="Enter text here"
                                                                                        value={shipment?.ediAudit[activeTelematicsAudit].auditnote}
                                                                                        disabled
                                                                                        readOnly
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                            <pre>
                                                                                <Table>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th style={{ backgroundColor: '#eee' }}>Stop</th>
                                                                                            <th style={{ backgroundColor: '#eee' }}>Location</th>
                                                                                            <th style={{ backgroundColor: '#eee' }}>EDI Arrive</th>
                                                                                            <th style={{ backgroundColor: '#eee' }}>EDI Depart</th>
                                                                                            <th style={{ backgroundColor: '#eee' }}>On Geofence Pings</th>
                                                                                            <th style={{ backgroundColor: '#eee' }}>Sched. Arrive</th>
                                                                                            <th style={{ backgroundColor: '#eee' }}>GPS Arrival</th>
                                                                                            <th style={{ backgroundColor: '#eee' }}>+/-</th>
                                                                                            <th style={{ backgroundColor: '#eee' }}>Sched. Depart</th>
                                                                                            <th style={{ backgroundColor: '#eee' }}>GPS Departure</th>
                                                                                            <th style={{ backgroundColor: '#eee' }}>+/-</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {report?.linearizeLoadTenderAndLogs.map((via, j) => (
                                                                                            <tr key={`${j}`}>
                                                                                                <td>{via?.StopNum}</td>
                                                                                                <td>{findUSPSNASSCode(via?.Name)}</td>
                                                                                                <td>{via?.ediStopArrived ?
                                                                                                    <>
                                                                                                        True ✅
                                                                                                    </> :
                                                                                                    <>
                                                                                                        False ❌ <Button bg={'primary'} onClick={() => activateEdiMessageModal(via)}><BiMailSend /></Button>
                                                                                                    </>
                                                                                                }</td>
                                                                                                <td>{via?.ediStopDelivered ?
                                                                                                    <>
                                                                                                        True ✅
                                                                                                    </> :
                                                                                                    <>
                                                                                                        False ❌ <Button bg={'primary'} onClick={() => activateEdiMessageModal(via)}><BiMailSend /></Button>
                                                                                                    </>
                                                                                                }</td>
                                                                                                <td>{via?.eldOnPremiseCt}</td>
                                                                                                <td>{formatUSPSDateTime(via?.Name, via?.RequestedDate)}</td>
                                                                                                <td style={{ backgroundColor: '#eee' }}>
                                                                                                    {via?.eldOnPremiseDataStart?.time &&
                                                                                                        `${formatZuluToTZ(via?.eldOnPremiseDataStart?.time, findUSPSNassCodeTimezoneIANA(via?.Name))}`
                                                                                                    }
                                                                                                </td>
                                                                                                <td style={
                                                                                                    via?.eldOnPremiseArriveDiffMin > 0
                                                                                                        ? { backgroundColor: '#bceebc' }
                                                                                                        : { backgroundColor: '#f39c9c' }
                                                                                                }>{via?.eldOnPremiseArriveDiffMin}</td>
                                                                                                <td>{formatUSPSDateTime(via?.Name, via?.LastRequestedDate)}</td>
                                                                                                <td style={{ backgroundColor: '#efefef' }}>
                                                                                                    {via?.eldOnPremiseDataEnd?.time &&
                                                                                                        `${formatZuluToTZ(via?.eldOnPremiseDataEnd?.time, findUSPSNassCodeTimezoneIANA(via?.Name))}`
                                                                                                    }
                                                                                                </td>
                                                                                                <td style={
                                                                                                    via?.eldOnPremiseDepartDiffMin > 0
                                                                                                        ? { backgroundColor: '#bceebc' }
                                                                                                        : { backgroundColor: '#f39c9c' }
                                                                                                }>{via?.eldOnPremiseDepartDiffMin}</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </Table>
                                                                            </pre>
                                                                            <Row>
                                                                                <Col sm={12} lg={4}>
                                                                                    <Chart
                                                                                        chartType={"PieChart"}
                                                                                        data={shipmentStopPunctualityPieChart(shipmentAudit[0].linearizeLoadTenderAndLogs)}
                                                                                        options={{
                                                                                            title: `Load Tender Check Point Punctuality`,
                                                                                            colors: ['grey', 'green', 'red'],
                                                                                        }}
                                                                                        width="100%"
                                                                                        height="500px"
                                                                                    />
                                                                                </Col>
                                                                                <Col sm={12} lg={4}>
                                                                                    <Chart
                                                                                        chartType="ScatterChart"
                                                                                        width="100%"
                                                                                        height="500px" data={shipmentStopPunctualityScatterChart(shipmentAudit[0].linearizeLoadTenderAndLogs)}
                                                                                        options={{
                                                                                            title: "Check Point Punctuality (+ Early | - Late)",
                                                                                            hAxis: { title: "Arrival (minutes)" },
                                                                                            vAxis: { title: "Departure (minutes)" },
                                                                                        }}
                                                                                    />
                                                                                </Col>
                                                                                <Col sm={12} lg={4}>
                                                                                    <StopPunctualityLegend />
                                                                                </Col>
                                                                            </Row>

                                                                        </React.Fragment>
                                                                        <Row className="align-items-center">
                                                                            <Col sm={12} md={6}>
                                                                                <Table bordered responsive hover>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th style={{ backgroundColor: '#f9f9f9' }}><Card.Title>Driver</Card.Title></th>
                                                                                            <th style={{ backgroundColor: '#f9f9f9' }}><Card.Title>{report?.operator?.name}</Card.Title></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <th>Contact Trip</th>
                                                                                            <td>{shipment['Contract Trip']}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>ShipmentId</th>
                                                                                            <td>{report?.shipmentId}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Search Tolerance </th>
                                                                                            <td>
                                                                                                {searchTolerance}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Appointment Tolerance</th>
                                                                                            <td>
                                                                                                {appointmentTolerance}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Search Start</th>
                                                                                            <td>{report?.hoslogSearchStart}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Search End</th>
                                                                                            <td>{report?.hoslogSearchEnd}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Total Search Time</th>
                                                                                            <td>{convertMillisecondsToHoursMinutes(report?.hoslogTotalTime)}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Vehicles</th>
                                                                                            <td>
                                                                                                {report?.hoslogVehicleData?.data
                                                                                                    ? report?.hoslogVehicleData?.data.map(d => d.name).join(', ')
                                                                                                    : 'No vehicles'
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Work Start</th>
                                                                                            <td>{report?.hoslogWorkStart}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Work End</th>
                                                                                            <td>{report?.hoslogWorkEnd}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Duty Status</th>
                                                                                            <td>
                                                                                                {Object.keys(report?.hoslogStatusTotals).map((key, i) => (
                                                                                                    <>
                                                                                                        {key} : {convertMillisecondsToHoursMinutes(report?.hoslogStatusTotals[key])}<br />
                                                                                                    </>
                                                                                                ))}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>HOS Work Time</th>
                                                                                            <td>{convertMillisecondsToHoursMinutes(report?.hoslogTimeWorked)}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Load Tender Work Time</th>
                                                                                            <td></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Load Tender SOP</th>
                                                                                            <td></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>SOP Over/Under</th>
                                                                                            <td></td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </Table>
                                                                            </Col>
                                                                            <Col sm={12} md={6}>
                                                                                <Card style={{ borderRadius: 0, margin: 0, padding: 0 }} >
                                                                                    <Card.Header>{report?.operator?.name} Hours of Service Log</Card.Header>
                                                                                    <Card.Body>
                                                                                        <DriverHOSLog hoslogs={report?.hoslogs} />
                                                                                    </Card.Body>
                                                                                </Card>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                </React.Fragment>
                                                            )
                                                            :
                                                            'No audits have been saved.'
                                                        }
                                                    </Row>
                                                }

                                            </Card.Body>
                                        </>
                                    }
                                </Card>
                                <br />
                                <Card>
                                    <Card.Header>
                                        <Row>
                                            <Col>
                                                <Card.Title>Operations Map</Card.Title>
                                            </Col>
                                            <Col style={{ textAlign: 'right' }}>
                                                <Button
                                                    variant={showOperationsMap ? 'secondary' : 'primary'}
                                                    style={{ float: 'right' }}
                                                    onClick={() => setShowOperationsMap(!showOperationsMap)}
                                                >
                                                    {showOperationsMap ? 'Hide' : 'Show'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    {showOperationsMap &&
                                        <GeofenceDisplay
                                            geofenceState={geofenceState}
                                            circleGeofenceState={circleGeofenceState}
                                            loadtenderPlot={loadtenderWaypoints}
                                        // hosPlot={hosWaypoints[0].filter(waypoint => waypoint.latitude !== 0 && waypoint.longitude !== 0)}
                                        />
                                    }
                                </Card>
                                <br />
                            </>
                        }
                        <Card>
                            <Card.Header>
                                <Row>
                                    <Col>
                                        <Card.Title>Load Tender Messaging : {shipment?.edi204.length + shipment?.edi214.length + shipment?.edi990.length + shipment?.edi210.length}</Card.Title>
                                    </Col>
                                    <Col style={{ textAlign: 'right' }}>
                                        <Button
                                            variant={showEdiMessages ? 'secondary' : 'primary'}
                                            style={{ float: 'right' }}
                                            onClick={() => setShowEdiMessages(!showEdiMessages)}
                                        >
                                            {showEdiMessages ? 'Hide' : 'Show'}
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Header>
                            {showEdiMessages &&
                                <>
                                    <Card.Body>
                                        <>
                                            <Row>
                                                <Col style={{ textAlign: 'left' }}>
                                                    <label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>Received</label>
                                                    {formatDateStrToMMDDYYHHMMSSTZ(shipment?.edi204[active204].receivedUTC)}
                                                </Col>
                                                <Col style={{ textAlign: 'right' }}>
                                                    <label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>Newest to Old</label>
                                                    {shipment?.edi204.map((_, i) => (
                                                        <Button
                                                            style={{ fontSize: '0.9em', margin: '0.1em' }}
                                                            variant={active204 === i ? 'primary' : 'secondary'}
                                                            onClick={() => setActive204(i)}
                                                        >
                                                            {shipment?.edi204.length - i}
                                                        </Button>
                                                    ))}
                                                </Col>
                                            </Row>
                                            {shipment?.edi204.map((tender, i) => (
                                                active204 === i &&
                                                <div key={i}>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={'100%'}>
                                                                    <Row>
                                                                        <Col>
                                                                            <Card.Title>204 - Motor Carrier Load Tender : {shipment?.edi204.length}</Card.Title>
                                                                        </Col>
                                                                        {(shipment.loadtenderLifeCycleStatus === 'pending') &&
                                                                            <Col>
                                                                                <Button
                                                                                    style={{ float: 'right' }}
                                                                                    variant="success"
                                                                                    onClick={() => handleShipmentAccept()}
                                                                                >
                                                                                    Accept Load Tender
                                                                                </Button>
                                                                            </Col>
                                                                        }
                                                                    </Row>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                {(shipment.edi204[0].loadtenderLifeCycleStatus === 'Accepted' && shipment?.coverage.length > 0) &&
                                                                    <th></th>
                                                                }
                                                                <th>Stop Num</th>
                                                                <th>Name</th>
                                                                <th>Address</th>
                                                                <th>Sched. Arrive</th>
                                                                <th>Sched. Depart</th>
                                                                {/* <th>Weight</th>
                                                    <th>Num Items</th>
                                                    <th>Pallets</th>
                                                    <th>Type</th>
                                                    <th>Code</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {tender?.Stops.map((via, j) => (
                                                                <tr key={j}>
                                                                    {(shipment.edi204[0].loadtenderLifeCycleStatus === 'Accepted' && shipment?.coverage.length > 0) &&
                                                                        <td>
                                                                            <Button bg={'primary'} onClick={() => activateEdiMessageModal(via)}><BiMailSend /></Button>
                                                                        </td>
                                                                    }
                                                                    <td>{via?.StopNum}</td>
                                                                    <td> {findUSPSNASSCode(via?.Name)} </td>
                                                                    <td>{`${via?.Address1} ${via?.Address2} ${via?.City} ${via?.State} ${via?.Zip} ${via?.Country}`}</td>
                                                                    <td>{formatUSPSDateTime(via?.Name, via?.RequestedDate)}</td>
                                                                    <td>{formatUSPSDateTime(via?.Name, via?.LastRequestedDate)}</td>
                                                                    {/* <td>{via?.Weight}</td> */}
                                                                    {/* <td>{via?.NumItems}</td> */}
                                                                    {/* <td>{via?.Pallets}</td> */}
                                                                    {/* <td>{via?.Type}</td> */}
                                                                    {/* <td>{via?.Code}</td> */}
                                                                    {/* <td>{via?.Reason} </td>*/}
                                                                    {/* <td>{via?.ReasonDesc} </td>*/}
                                                                    {/* <td>{via?.Uom} </td>*/}
                                                                    {/* <td>{via?.Contact} </td>*/}
                                                                    {/* <td>{via?.References} </td>*/}
                                                                    {/* <td>{JSON.stringify(via?.ItemsOID)} </td> */}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    {/* <Row >
                                                    <Col xs="auto"><label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>ShipmentId</label>{tender?.ShipmentId}</Col>
                                                    <Col xs="auto"><label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>Status</label>{tender?.loadtenderLifeCycleStatus}</Col>
                                                    <Col xs="auto"><label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>Transaction Id</label>{tender?.TransactionId}</Col>
                                                    <Col xs="auto"><label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>Tracking Number</label>{tender?.InternalTrackingNum}</Col>
                                                    <Col xs="auto"><label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>Customer</label>{tender?.Customer}</Col>
                                                    <Col xs="auto"><label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>Customer ISA</label>{tender?.CustomerISA}</Col>
                                                    <Col xs="auto"><label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>SCAC</label>{tender?.SCAC}</Col>
                                                    <Col xs="auto"><label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>Type</label>{tender?.Type}</Col>
                                                    <Col xs="auto"><label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>TypeDesc</label>{tender?.TypeDesc}</Col>
                                                    <Col xs="auto"><label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>Equipment</label>{tender?.Equipment}</Col>
                                                    <Col xs="auto"><label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>EquipmentNumber</label>{tender?.EquipmentNumber}</Col>
                                                    <Col xs="auto"><label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>TotalPiecesOfEquipment</label>{tender?.TotalPiecesOfEquipment}</Col>
                                                    <Col xs="auto"><label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>EquipmentLength</label>{tender?.EquipLength}</Col>
                                                    <Col xs="auto"><label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>TransportationMethod</label>{tender?.TransportationMethod}</Col>
                                                    <Col xs="auto"><label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>Pallets</label>{tender?.Pallets}</Col>
                                                    <Col xs="auto"><label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>Rate</label>{tender?.Rate}</Col>
                                                    <Col xs="auto"><label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>Weight</label>{tender?.Weight}</Col>
                                                    <Col xs="auto"><label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>WeightUnit</label>{tender?.WeightUnit}</Col>
                                                    <Col xs="auto"><label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>PaymenthMethod</label>{tender?.PaymentMethod}</Col>
                                                    <Col xs="auto"><label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>Misc Reference Number</label>{tender?.MiscReferenceNum}</Col>
                                                    <Col xs="auto">
                                                        <label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>Bill To</label>
                                                        {tender?.BillTo.Name}
                                                        {tender?.BillTo.Address1}
                                                        {tender?.BillTo.City}
                                                        {tender?.BillTo.State}
                                                        {tender?.BillTo.Zip}
                                                        {tender?.BillTo.Country}
                                                        {tender?.BillTo.Code}
                                                        {tender?.BillTo.Type}
                                                    </Col>
                                                    <Col xs="auto">
                                                        <label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>Contact</label>
                                                        {JSON.stringify(tender?.Contact?.Name1)}<br />
                                                        {JSON.stringify(tender?.Contact?.Name2)}
                                                    </Col>
                                                    <Col xs="auto">
                                                        <label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>Note</label>
                                                        {tender?.Note}
                                                    </Col>
                                                    <label style={{ fontStyle: 'italic', textDecoration: 'underline', display: 'block' }}>References</label>
                                                    <Col xs="auto">
                                                        <Row>
                                                            {tender?.References.map((reference, j) => (
                                                                <Col key={j}>
                                                                    <div>{reference?.Identifier}</div>
                                                                    <div>{reference?.Qualifier}</div>
                                                                    <div>{reference?.ReasonDesc}</div>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </Col>
                                                </Row> */}
                                                </div>
                                            ))}
                                        </>
                                        <pre>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={'100%'}>
                                                            <Card.Title>214 - Load Tender Status Updates</Card.Title>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>ShipmentId</th>
                                                        <th>ApptNumber</th>
                                                        <th>StatusCode</th>
                                                        <th>StatusDate</th>
                                                        <th>Shipper</th>
                                                        <th>Consignee</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {shipment?.edi214.length > 0 ? shipment?.edi214.map((message, i) => (
                                                        <tr key={i}>
                                                            <td>{message.ShipmentId}</td>
                                                            <td>{message.ApptNumber}</td>
                                                            <td>{message.StatusCode}</td>
                                                            <td>{message.StatusDate} {message.TimeZone}</td>
                                                            <td>
                                                                <div>{message.Shipper.StopNum} - {message.Shipper.Name}</div>
                                                                <div>{`${message.Shipper.Address1} ${message.Shipper.Address2} ${message.Shipper.City} ${message.Shipper.State} ${message.Shipper.Zip}`}</div>
                                                                <div>{message.Shipper.PickUp}</div>
                                                                <div>{message.Shipper.DropOff}</div>
                                                                <div>{message.Shipper.Reason}</div>
                                                            </td>
                                                            <td>
                                                                <div>{message.Shipper.StopNum} - {message.Consignee.Name}</div>
                                                                <div>{`${message.Consignee.Address1} ${message.Consignee.Address2} ${message.Consignee.City} ${message.Consignee.State} ${message.Consignee.Zip}`}</div>
                                                                <div>{message.Consignee.PickUp}</div>
                                                                <div>{message.Consignee.DropOff}</div>
                                                                <div>{message.Consignee.Reason}</div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                        :
                                                        <tr>
                                                            <td colSpan={'100%'}>No data.</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                            <ConfirmationDangerModal
                                                show={allowTenderReject}
                                                onHide={() => setAllowTenderReject(false)}
                                                onConfirm={() => handleShipmentReject()}
                                                message={'Are you sure you want to reject this load tender? This cannot be undone.'}
                                            />
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={'100%'}>
                                                            <Row>
                                                                <Col>
                                                                    <Card.Title>990 - Response to a Load Tender</Card.Title>
                                                                </Col>

                                                                {(
                                                                    (
                                                                        ['pending', 'Accepted'].includes(shipment.edi204[0].loadtenderLifeCycleStatus)
                                                                        && (shipment.edi214.length === 0 && shipment.telematics.length === 0)
                                                                    )
                                                                    &&
                                                                    <Col style={{ textAlign: 'right' }}>
                                                                        {shipment?.coverage.length > 0 && 'Remove all coverage to enable reject. '}
                                                                        <Button
                                                                            variant="danger"
                                                                            onClick={() => setAllowTenderReject(true)}
                                                                            disabled={
                                                                                (shipment?.coverage.length > 0 || shipment?.edi214.length > 0)
                                                                                    ? true : false
                                                                            }
                                                                        >
                                                                            Reject Load Tender
                                                                        </Button>
                                                                    </Col>
                                                                )}
                                                                {
                                                                    shipment.edi204[0].loadtenderLifeCycleStatus === 'pending'
                                                                    &&
                                                                    <Col>
                                                                        <Button
                                                                            variant="success"
                                                                            onClick={() => handleShipmentAccept()}
                                                                            disabled={
                                                                                (shipment?.coverage.length > 0 || shipment?.edi214.length > 0)
                                                                                    ? true : false}
                                                                        >
                                                                            Accept Load Tender
                                                                        </Button>
                                                                    </Col>
                                                                }
                                                                {
                                                                    (
                                                                        shipment.edi204[0].loadtenderLifeCycleStatus === 'Accepted' &&
                                                                        shipment?.coverage.length > 0 &&
                                                                        shipment?.edi214.length > 0 &&
                                                                        shipment?.telematics.length > 0
                                                                    )
                                                                    &&
                                                                    <Col xs="auto">
                                                                        <Button variant="success" onClick={() => handleCompletedLifeCycle()}>
                                                                            Complete
                                                                        </Button>
                                                                    </Col>

                                                                }
                                                                {
                                                                    (shipment.edi204[0].loadtenderLifeCycleStatus === 'Completed')
                                                                    &&
                                                                    <Col xs="auto">
                                                                        <Button variant="secondary" onClick={() => handleAcceptedLifeCycle()}>
                                                                            Reactivate
                                                                        </Button>
                                                                    </Col>
                                                                }
                                                            </Row>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Message</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {shipment?.edi990.length > 0 ? shipment?.edi990.map((message, i) => (
                                                        <tr key={i}>
                                                            <td><pre>{JSON.stringify(message, "", 2)}</pre></td>
                                                        </tr>
                                                    )) :
                                                        <tr>
                                                            <td colSpan={'100%'}>No data.</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                            <Table striped bordered hover responsive>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={'100%'}>
                                                            <Card.Title>210 - Motor Carrier Freight Details and Invoice</Card.Title>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Messages</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {shipment?.edi210.length > 0 ? shipment?.edi210.map((message, i) => (
                                                        <tr key={i}>
                                                            <td><pre>{JSON.stringify(message, "", 2)}</pre></td>
                                                        </tr>
                                                    )) :
                                                        <tr>
                                                            <td colSpan={'100%'}>No data.</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                        </pre>
                                    </Card.Body>
                                </>
                            }
                        </Card>
                        <br />
                        <Card>
                            <Card.Header>
                                <Row>
                                    <Col>
                                        <Card.Title>FourKite Messaging : {shipment?.fourkiteShipmentUpdates.length}</Card.Title>
                                    </Col>
                                    <Col style={{ textAlign: 'right' }}>
                                        <Button
                                            variant={showFourKiteMessages ? 'secondary' : 'primary'}
                                            style={{ float: 'right' }}
                                            onClick={() => setShowFourKiteMessages(!showFourKiteMessages)}
                                        >
                                            {showFourKiteMessages ? 'Hide' : 'Show'}
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Header>
                            {showFourKiteMessages &&
                                <>
                                    <Card.Body>
                                        <pre>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Shipper</th>
                                                        <th>Shipment Id/BOL</th>
                                                        <th>SCAC</th>
                                                        <th>Vehicle Number</th>
                                                        <th>Create TimeStamp</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {shipment?.fourkiteShipmentUpdates && shipment?.fourkiteShipmentUpdates.length > 0 && shipment?.fourkiteShipmentUpdates.sort((a, b) => {
                                                        return a.createdUtcTimestamp < b.createdUtcTimestamp ? 1 : -1
                                                    }).map((message, i) => (
                                                        <tr key={i}>
                                                            <td>{message.shipper}</td>
                                                            <td>{message.billOfLading}</td>
                                                            <td>{message.operatingCarrierScac}</td>
                                                            <td>{message.truckNumber}</td>
                                                            <td>{message.createdUtcTimestamp}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </pre>
                                    </Card.Body>
                                </>
                            }
                        </Card>
                    </>
                </Modal.Body>
                :
                <Modal.Body>
                    <LogisticsLoading message={'Loading Shipment ...'} />
                </Modal.Body>
            }
            <Modal.Footer style={{ width: '100vw' }}>
                <Button vairant="primary" onClick={() => setShowEdiTagModal(true)}>Tag</Button>
                <Button variant="secondary" onClick={() => handlePageToPreviousShipment(ShipmentId)}><FaArrowLeft /> Previous</Button>
                <Button variant="secondary" onClick={() => handlePageToNextShipment(ShipmentId)}>Next <FaArrowRight /></Button>
                <Button variant="secondary" onClick={() => handleShipmentModalClose()}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal >
    )
}

function LoadTenderDashboardV2() {
    const { user, idToken } = useAuth();
    const { settings } = useSettings();
    const location = useLocation();
    const navigate = useNavigate();

    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts };



    // filters
    const [didFetchFilterData, setDidFetchFilterData] = useState(false);
    const [contracts, setContracts] = useState([]);
    const contractsState = { contracts, setContracts }
    const [operators, setOperators] = useState([]);
    const operatorsState = { operators, setOperators }
    const [places, setPlaces] = useState([]);
    const placesState = { places, setPlaces };

    const [dataDisplay, setDataDisplay] = useState(() => {
        const savedQuery = localStorage.getItem(`${lsKey}-data-display`);
        return savedQuery !== null ? savedQuery : 'Table';
    });

    const dataGraphDisplayState = { dataDisplay, setDataDisplay }
    useEffect(() => {
        localStorage.setItem(`${lsKey}-data-display`, dataDisplay);
    }, [dataDisplay]);

    //shipments
    const [query, setQuery] = useState(() => {
        const savedQuery = localStorage.getItem(`${lsKey}-data-query`);
        return savedQuery !== null ? JSON.parse(savedQuery) : initialQuery;
    });

    const queryState = { query, setQuery };

    useEffect(() => {
        localStorage.setItem(`${lsKey}-data-query`, JSON.stringify(query));
    }, [query]);

    const [period, setPeriod] = useState(query.period || 'today');
    const periodState = { period, setPeriod };


    function pageReset() {
        setQuery(initialQuery)
        localStorage.setItem(`${lsKey}-data-query`, JSON.stringify(initialQuery));
        setDidFetchShipments(false)
        setPeriod('today')
    }

    const [didFetchShipments, setDidFetchShipments] = useState(false)
    const didFetchShipmentsState = { didFetchShipments, setDidFetchShipments }

    const [shipments, setShipments] = useState([])
    const shipmentsState = { shipments, setShipments }

    //shipment
    const [didFetchShipment, setDidFetchShipment] = useState(false)
    const didFetchShipmentState = { didFetchShipment, setDidFetchShipment }

    const [ShipmentId, setShipmentId] = useState('');
    const ShipmentIdState = { ShipmentId, setShipmentId }

    const [shipment, setShipment] = useState({});
    const shipmentState = { shipment, setShipment }

    const [showShipment, setShowShipment] = useState(false);
    const shipmentModalState = { showShipment, setShowShipment }

    const [ediMessageStopUpdate, setEdiMessageStopUpdate] = useState({
        stopnum: '',
        action: '',
        timestamp: '',
    })
    const ediMessageStopUpdateState = { ediMessageStopUpdate, setEdiMessageStopUpdate }

    const [showManualEdiStatusUpdate, setShowManualEdiStatusUpdate] = useState(true);
    const showManualEdiStatusUpdateState = { showManualEdiStatusUpdate, setShowManualEdiStatusUpdate }

    function handleSearchReset() {
        navigate({ search: '' });
    };

    async function handleManualEdiStatusUpdate() {
        try {
            setShowManualEdiStatusUpdate(false)
            if (ediMessageStopUpdate?.action.trim() !== ''
                && ediMessageStopUpdate?.stopnum.trim() !== ''
                && ediMessageStopUpdate?.timestamp.trim() !== ''
            ) {
                const stopAction = ediMessageStopUpdate?.action;
                const stopTimestamp = ediMessageStopUpdate?.timestamp;
                const stopNum = Number(ediMessageStopUpdate?.stopnum);
                const edi204original = shipment.edi204[0]
                const edi204newest = shipment.edi204[shipment.edi204.length - 1]

                let new214;
                let shipperstop, consigneestop, shipperindex, consigneeindex;
                let statusCode;
                const timeZoneAbbreviations = {
                    "America/New_York": "ET",
                    "America/Chicago": "CT",
                    "America/Denver": "MT",
                    "America/Los_Angeles": "PT"
                };
                const tzcode = timeZoneAbbreviations[settings.timezone];


                switch (stopAction) {
                    case ('eta'):
                        statusCode = stopNum === 1 ? 'AA' : 'AG'
                        break;
                    case ('arrive'):
                        statusCode = stopNum === 1 ? 'X3' : 'X1'
                        break;
                    case ('depart'):
                        statusCode = stopNum === 1 ? 'AF' : 'CD'
                        break;
                    default:
                        statusCode = '';
                }


                if (stopNum === 1) {
                    shipperstop = stopNum
                    consigneestop = stopNum + 1
                    shipperindex = stopNum - 1
                    consigneeindex = stopNum
                    new214 = {
                        "TransactionId": 214,
                        "InternalTrackingNum": edi204original?.InternalTrackingNum,
                        "Customer": edi204original?.Customer,
                        "CustomerISA": edi204original?.CustomerISA,
                        "SCAC": edi204original?.SCAC,
                        "ShipmentId": edi204original?.ShipmentId,
                        "ApptNumber": shipperstop,
                        "StatusCode": statusCode,
                        "StatusDate": stopTimestamp,
                        "TimeZone": tzcode,
                        "City": edi204original?.BillTo.City,
                        "State": edi204original?.BillTo.State,
                        "Shipper": {
                            "StopNum": shipperstop,
                            "Name": edi204newest?.Stops[shipperindex].Name,
                            "Address1": edi204newest?.Stops[shipperindex].Address1,
                            "Address2": edi204newest?.Stops[shipperindex].Address2,
                            "City": edi204newest?.Stops[shipperindex].City,
                            "State": edi204newest?.Stops[shipperindex].State,
                            "Zip": edi204newest?.Stops[shipperindex].Zip,
                            "PickUp": true,
                            "DropOff": false,
                            "Reason": edi204newest?.Stops[shipperindex].Reason,
                        },
                        "Consignee": {
                            "StopNum": consigneestop,
                            "Name": edi204newest?.Stops[consigneeindex].Name,
                            "Address1": edi204newest?.Stops[consigneeindex].Address1,
                            "Address2": edi204newest?.Stops[consigneeindex].Address2,
                            "City": edi204newest?.Stops[consigneeindex].City,
                            "State": edi204newest?.Stops[consigneeindex].State,
                            "Zip": edi204newest?.Stops[consigneeindex].Zip,
                            "PickUp": false,
                            "DropOff": true,
                            "Reason": edi204newest?.Stops[consigneeindex].Reason,
                        }
                    }
                } else if (stopNum > 1) {
                    shipperstop = 1
                    consigneestop = stopNum
                    shipperindex = 0
                    consigneeindex = stopNum - 1
                    new214 = {
                        "TransactionId": 214,
                        "InternalTrackingNum": edi204original?.InternalTrackingNum,
                        "Customer": edi204original?.Customer,
                        "CustomerISA": edi204original?.CustomerISA,
                        "SCAC": edi204original?.SCAC,
                        "ShipmentId": edi204original?.ShipmentId,
                        "ApptNumber": consigneestop,
                        "StatusCode": statusCode,
                        "StatusDate": stopTimestamp,
                        "TimeZone": tzcode,
                        "City": edi204original?.BillTo.City,
                        "State": edi204original?.BillTo.State,
                        "Shipper": {
                            "StopNum": shipperstop,
                            "Name": edi204newest?.Stops[shipperindex].Name,
                            "Address1": edi204newest?.Stops[shipperindex].Address1,
                            "Address2": edi204newest?.Stops[shipperindex].Address2,
                            "City": edi204newest?.Stops[shipperindex].City,
                            "State": edi204newest?.Stops[shipperindex].State,
                            "Zip": edi204newest?.Stops[shipperindex].Zip,
                            "PickUp": true,
                            "DropOff": false,
                            "Reason": edi204newest?.Stops[shipperindex].Reason,
                        },
                        "Consignee": {
                            "StopNum": consigneestop,
                            "Name": edi204newest?.Stops[consigneeindex].Name,
                            "Address1": edi204newest?.Stops[consigneeindex].Address1,
                            "Address2": edi204newest?.Stops[consigneeindex].Address2,
                            "City": edi204newest?.Stops[consigneeindex].City,
                            "State": edi204newest?.Stops[consigneeindex].State,
                            "Zip": edi204newest?.Stops[consigneeindex].Zip,
                            "PickUp": false,
                            "DropOff": true,
                            "Reason": edi204newest?.Stops[consigneeindex].Reason,
                        }
                    }
                }

                // console.log(new214);
                await Api.post(`/edi/shipment/${ShipmentId}/214`, new214, idToken)
                setAlerts([...alerts, { variant: 'success', message: 'Status update sent successfully.', }])
            } else {
                setAlerts([...alerts, { variant: 'warning', message: 'Make sure you select ETA, Arrive, or Depart and select a date time.', }])
            }
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured sending manual status update.', }])
        } finally {
            setQuietlyUpdate(true)
            setShowManualEdiStatusUpdate(true)
            // setDidFetchShipment(false)
            // setDidFetchShipments(false)
        }

    }

    const [operatoroption, setOperatorOption] = useState();
    const operatorOptionState = { operatoroption, setOperatorOption }
    const [coverageoption, setCoverageOption] = useState();
    const coverageOptionState = { coverageoption, setCoverageOption }
    const [removeoption, setRemoveOption] = useState();
    const removeOptionState = { removeoption, setRemoveOption }

    async function handleCoverageAssign() {
        try {
            if (!operatoroption && !coverageoption) {
                throw new Error('To assign a driver, please select a Driver and Coverage Reason')
            }
            if (!operatoroption) {
                throw new Error('To assign a driver, please select a Driver')
            }
            if (!coverageoption) {
                throw new Error('To assign a driver, please select a Coverage Reason')
            }
            const edi204Update = {
                shipmentId: ShipmentId,
                operator: JSON.parse(operatoroption),
                operatortype: 'samsara',
                assignedby: user.email,
                assignedreason: coverageoption
            }
            await Api.patch(`/edi/shipment/${ShipmentId}/coverage-assign`, edi204Update, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully assigned operator to Load Tender' }])
            setDidFetchShipments(false);
            setDidFetchShipment(false);
            setOperatorOption()
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Operatored assignment error' }])
        }
    }

    async function handleCoverageRemove() {
        try {
            if (!operatoroption && !removeoption) {
                throw new Error('To assign a driver, please select a Driver and remove Reason')
            }
            if (!operatoroption) {
                throw new Error('To assign a driver, please select a Driver')
            }
            if (!removeoption) {
                throw new Error('To assign a driver, please select a remove Reason')
            }
            const edi204Update = {
                shipmentId: ShipmentId,
                operator: JSON.parse(operatoroption),
                operatortype: 'samsara',
                removededby: user.email,
                removededreason: removeoption
            }

            await Api.patch(`/edi/shipment/${ShipmentId}/coverage-remove`, edi204Update, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully removed operator from Load Tender' }])
            setDidFetchShipments(false);
            setDidFetchShipment(false);
            setOperatorOption()
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured removing operator from Load Tender.' }])
        }
    }

    const [allowTenderReject, setAllowTenderReject] = useState(false);
    const allowTenderRejectState = { allowTenderReject, setAllowTenderReject }

    async function handleShipmentReject() {
        try {
            const date = new Date();
            const utcTimestamp = date.toISOString();

            const edi204original = shipment.edi204[0]

            const edi204LifeCycleUpdate = {
                'loadtenderLifeCycleStatus': 'Rejected',
            }

            const edi990Update = {
                "TransactionId": 990,
                "InternalTrackingNum": edi204original?.InternalTrackingNum,
                "Customer": edi204original?.Customer,
                "CustomerISA": edi204original?.CustomerISA,
                "SCAC": edi204original?.SCAC,
                "Response": "Rejected",
                "ShipmentId": edi204original?.ShipmentId,
                "ProNumber": edi204original?.ProNumber,
                "TxnNumber": edi204original?.TxnNumber,
                "Timestamp": utcTimestamp,
                "TimeZone": "UT",
                "Note": "",
                "RejectionReason": "",
                "References": ""
            }


            await Api.post(`/edi/shipment/${ShipmentId}/990`, { ...edi990Update }, idToken)
            await Api.patch(`/edi/shipment/${ShipmentId}/lifecycle`, edi204LifeCycleUpdate, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully rejected load tender.' }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured while rejecting the load tender.' }])
        } finally {
            setAllowTenderReject(false)
            setDidFetchShipment(false)
            setDidFetchShipments(false)
        }
    }

    async function handleShipmentAccept() {
        try {
            const date = new Date();
            const utcTimestamp = date.toISOString();

            const edi204original = shipment.edi204[0]

            const edi204LifeCycleUpdate = {
                'loadtenderLifeCycleStatus': 'Accepted',
            }

            const edi990Update = {
                "TransactionId": 990,
                "InternalTrackingNum": edi204original?.InternalTrackingNum,
                "Customer": edi204original?.Customer,
                "CustomerISA": edi204original?.CustomerISA,
                "SCAC": edi204original?.SCAC,
                "Response": "Accepted",
                "ShipmentId": edi204original?.ShipmentId,
                "ProNumber": edi204original?.ProNumber,
                "TxnNumber": edi204original?.TxnNumber,
                "Timestamp": utcTimestamp,
                "TimeZone": "UT",
                "Note": "",
                "RejectionReason": "",
                "References": ""
            }

            await Api.post(`/edi/shipment/${ShipmentId}/990`, { ...edi990Update }, idToken)
            await Api.patch(`/edi/shipment/${ShipmentId}/lifecycle`, edi204LifeCycleUpdate, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully accepted load tender.' }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured while accepting the load tender.' }])
        } finally {
            setDidFetchShipment(false)
            setDidFetchShipments(false)
        }
    }

    async function handleCompletedLifeCycle() {
        try {
            const edi204LifeCycleUpdate = {
                'loadtenderLifeCycleStatus': 'Completed',
            }
            await Api.patch(`/edi/shipment/${ShipmentId}/lifecycle`, edi204LifeCycleUpdate, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully updated load tender.' }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured while completing life cycle.' }])
        } finally {
            setDidFetchShipment(false)
            setDidFetchShipments(false)
        }
    }

    async function handleAcceptedLifeCycle() {
        try {
            const edi204LifeCycleUpdate = {
                'loadtenderLifeCycleStatus': 'Accepted',
            }
            await Api.patch(`/edi/shipment/${ShipmentId}/lifecycle`, edi204LifeCycleUpdate, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully updated load tender.' }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured while accepting life cycle.' }])
        } finally {
            setDidFetchShipment(false)
            setDidFetchShipments(false)
        }
    }

    async function handleAddShipmentTag(tag) {
        try {
            await Api.patch(`/edi/shipment/${ShipmentId}/add-tag`, { ...tag }, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'Successfully tagged.' }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured tagging the load tender.' }])
        } finally {
            setDidFetchShipments(false)
            setDidFetchShipment(false)
        }
    }

    async function handleRemoveShipmentTag(tag) {
        try {
            await Api.patch(`/edi/shipment/${ShipmentId}/remove-tag`, { name: tag.name }, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully removed tag.' }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured while removing the tag.' }])
        } finally {
            setDidFetchShipments(false)
            setDidFetchShipment(false)
        }
    }

    async function handlePageToNextShipment(ShipmentId) {
        try {
            const maxIndex = shipments.length
            const index = shipments.findIndex(shipment => shipment._id === ShipmentId);
            const next = (index + 1)
            let nextShipment;
            if (next >= maxIndex) {
                nextShipment = shipments[0];
            } else {
                nextShipment = shipments[next];
            }
            setShipment(nextShipment)
            setShipmentId(nextShipment._id);
            const url = `/loadtender/dashboard/v2?ShipmentId=${nextShipment._id}`;
            navigate(url);
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occurred navigating to next.' }]);
        }
    }

    async function handlePageToPreviousShipment(ShipmentId) {
        try {
            const maxIndex = shipments.length
            const index = shipments.findIndex(shipment => shipment._id === ShipmentId);
            const pervious = (index - 1)
            let perviousShipment;
            if (pervious > 0) {
                perviousShipment = shipments[pervious];
            } else {
                perviousShipment = shipments[maxIndex - 1];
            }
            setShipment(perviousShipment)
            setShipmentId(perviousShipment._id);
            setDidFetchShipmentAudit(false);
            const url = `/loadtender/dashboard/v2?ShipmentId=${perviousShipment._id}`;
            navigate(url);
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occurred navigating to next.' }]);
        }
    }




    const [goodaudit, setGoodAudit] = useState();
    const goodAuditState = { goodaudit, setGoodAudit }

    const [auditnote, setAuditNote] = useState();
    const auditNoteState = { auditnote, setAuditNote };


    async function handleSaveEdiAudit() {
        try {
            const audit = {
                ShipmentId: shipment?.edi204[0]?.ShipmentId,
                goodaudit: goodaudit,
                auditnote: auditnote,
                telematics: shipmentAudit,
                email: user.email,
                userId: user.uid,
            }
            await Api.post(`/edi/shipment/${ShipmentId}/audit`, audit, idToken)
            setGoodAudit()
            setAuditNote('');
            setAlerts([...alerts, { variant: 'success', message: 'Audit saved.' }])
            setDidFetchShipments(false)
            setDidFetchShipment(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: `${error?.error} Include grade and note.` }])
        }
    }

    const [activeTelematicsAudit, setActiveTelematicsAudit] = useState('live');
    const activeTelematicsAuditState = { activeTelematicsAudit, setActiveTelematicsAudit }

    // shipment audit
    const [didFetchShipmentAudit, setDidFetchShipmentAudit] = useState(false);
    const didFetchShipmentAuditState = { didFetchShipmentAudit, setDidFetchShipmentAudit };
    const [shipmentAudit, setShipmentAudit] = useState();
    const [searchTolerance, setSearchTolerance] = useState(1);
    const searchToleranceState = { searchTolerance, setSearchTolerance }
    const [appointmentTolerance, setAppointmentTolerance] = useState(1);
    const appointmentToleranceState = { appointmentTolerance, setAppointmentTolerance }
    // shipment geofences
    const [geofences, setGeofences] = useState([]);
    const geofenceState = { geofences, setGeofences };
    const [circleGeofence, setCircleGeofence] = useState([]);
    const circleGeofenceState = { circleGeofence, setCircleGeofence };
    const [loadtenderWaypoints, setLoadTenderWaypoints] = useState([]);
    const loadtenderWaypointsState = { loadtenderWaypoints, setLoadTenderWaypoints }
    const [hosWaypoints, setHosWaypoints] = useState([]);
    const hosWaypointsState = { hosWaypoints, setHosWaypoints }
    // other modals
    const [showHelpModal, setShowHelpModal] = useState(false);
    const helpModalState = { showHelpModal, setShowHelpModal }

    const [showCodesModal, setShowCodesModal] = useState(false);
    const codesModalState = { showCodesModal, setShowCodesModal }

    const [showSearchModal, setShowSearchModal] = useState(false);
    const searchModalState = { showSearchModal, setShowSearchModal };

    const [searchShipmentId, setSearchShipmentId] = useState();
    const searchShipmentIdState = { searchShipmentId, setSearchShipmentId };
    const [searchShipmentResult, setSearchShipmentResult] = useState();
    const searchShipmentResultState = { searchShipmentResult, setSearchShipmentResult }
    const [searchForShipment, setDidSearchForShipment] = useState();
    const [shipmentFound, setShipmentFound] = useState();

    const [isSearching, setIsSearching] = useState(false);
    const isSearchingState = { isSearching, setIsSearching }

    const [showTagsModal, setShowTagsModal] = useState(false);
    const tagsModalState = { showTagsModal, setShowTagsModal }
    const [didFetchTags, setDidFetchTags] = useState(false);
    const didFetchTagsState = { didFetchTags, setDidFetchTags }
    const [tags, setTags] = useState([]);
    const tagsState = { tags, setTags }
    const [tag, setTag] = useState(initialTag);
    const tagState = { tag, setTag }

    async function handleTagCreate() {
        try {
            await Api.post('/edi/tags', tag, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'Tag created successfully.' }])
            setDidFetchTags(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured creating tag.' }])
        }
    }

    const [samsaraDrivers, setSamsaraDrivers] = useState();
    const samsaraDriversState = { samsaraDrivers, setSamsaraDrivers }
    const [samsaraDriversByLocation, setSamsaraDriversByLocation] = useState();
    const samsaraDriversByLocationState = { samsaraDriversByLocation, setSamsaraDriversByLocation }

    const [quietlyUpdate, setQuietlyUpdate] = useState();
    const quietlyUpdateState = { quietlyUpdate, setQuietlyUpdate }

    const [shipmentViewHistory, setShipmentViewHistory] = useState([]);
    const shipmentViewHistoryState = { shipmentViewHistory, setShipmentViewHistory }

    function viewShipmentFromHistory(ShipmentId) {
        const url = `/loadtender/dashboard/v2?ShipmentId=${ShipmentId}`;
        navigate(url);
    }

    const extractWaypoints = useCallback(async (data) => {
        async function getLatLngFromAddress(address) {
            try {
                const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
                const response = await fetch(url);

                if (!response.ok) {
                    throw new Error(`Geocoding request failed with status ${response.status}`);
                }

                const data = await response.json();

                if (data.results && data.results.length > 0) {
                    const location = data.results[0].geometry.location;
                    return {
                        latitude: location.lat,
                        longitude: location.lng,
                    };
                } else {
                    console.error('Geocoding request did not return valid data.');
                    return null;
                }
            } catch (error) {
                console.error('Error while fetching geocoding data:', error);
                return null;
            }
        }

        const waypoints = [];

        for (const item of data) {
            try {
                const address = findUSPSAddress(item.Name);
                const { latitude, longitude } = await getLatLngFromAddress(address);
                const waypoint = {
                    latitude: latitude,
                    longitude: longitude,
                    name: `${findUSPSNASSCode(item.Name)}`,
                };
                waypoints.push(waypoint);
            } catch (error) {
                continue;
            }
        }
        return waypoints;
    }, []);

    const handleSearchShipment = useCallback(async () => {
        try {
            setIsSearching(true);
            const data = await Api.get(`/edi/shipment/${searchShipmentId}`, idToken);
            if (data.length > 0) {
                // console.log(data)
                const newQueryStart = formatDate(new Date(data[0].edi204[0].Stops[0].RequestedDate));
                const newQueryEnd = formatDate(new Date(data[0].edi204[0].Stops[data[0].edi204[0].Stops.length-1].LastRequestedDate));
                console.log(newQueryStart)
                console.log(newQueryEnd)
                setShipmentFound(true)
                setQuery({...query, start: newQueryStart, end: newQueryEnd})
                navigate(`/loadtender/dashboard/v2?ShipmentId=${searchShipmentId}`)
                setDidFetchShipments(false)
                setDidFetchShipment(false)
            } else {
                setShipmentFound(false)
            }
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occurred searching for shipment.' }]);
        } finally {
            setIsSearching(false);
        }
    }, [alerts, setIsSearching, setAlerts, searchShipmentId, idToken, query, navigate]);

    useEffect(() => {
        // console.log('Mount Filter Data Fetch')
        const fetchFilterData = async () => {
            console.log('Fetch filter data')
            try {
                const data = await Api.get(`/edi204/contracts`, idToken);
                setContracts(data);
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Error fetching contracts' }])
            }
            try {
                const data = await Api.get(`/edi204/places`, idToken);
                setPlaces(data);
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Error fetching places' }])
            }
            try {
                const data = await Api.get(`/edi204/operators`, idToken);
                setOperators(data);
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Error fetching operators' }])
            }
            try {
                const driversdata = await Api.get(`/samsara/drivers-active`, idToken);
                const drivers = driversdata?.data;
                const groupedData = drivers.reduce((groups, record) => {
                    const homeTerminalName = record?.carrierSettings?.homeTerminalName;
                    if (!groups[homeTerminalName]) {
                        groups[homeTerminalName] = [];
                    }
                    groups[homeTerminalName].push(record);
                    return groups;
                }, {});

                const sortedKeys = Object.keys(groupedData).sort();

                const sortedGroupedData = {};
                for (const key of sortedKeys) {
                    sortedGroupedData[key] = groupedData[key];
                }

                setSamsaraDrivers(drivers)
                setSamsaraDriversByLocation(sortedGroupedData)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Error fetching active drivers' }])
            }
            setDidFetchFilterData(true);
        }
        if (!didFetchFilterData) {
            fetchFilterData();
        }
    }, [didFetchFilterData, alerts, idToken])

    useEffect(() => {
        // console.log('Mount Shipments Fetch')
        const fetchShipments = async () => {
            console.log('Fetch Shipments')
            try {
                const params = objectToQueryString(query);
                const data = await Api.get(`/edi/shipments?${params}`, idToken);
                setShipments(data);
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Error fetching shipments' }])
            }
            setDidFetchShipments(true);
        }

        if (!didFetchShipments) {
            fetchShipments()
        }

    }, [alerts, query, didFetchShipments, idToken])

    useEffect(() => {
        // console.log('Mount Shipment Fetch')
        const fetchShipment = async () => {
            console.log('Fetch Shipment')
            try {
                if (ShipmentId) {
                    const data = await Api.get(`/edi/shipment/${ShipmentId}`, idToken);
                    setShipment(data);
                }
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Error fetching shipment' }])
            }
            setDidFetchShipment(true);
        }

        if (!didFetchShipment) {
            fetchShipment()
        }

    }, [alerts, query, didFetchShipment, idToken, ShipmentId])

    useEffect(() => {
        // console.log('Mount Shipment Audit')
        const fetchShipmentAudit = async () => {
            console.log('Fetch shipment audit')
            try {
                const audit = await Api.get(`/edi/shipment/${ShipmentId}/audit/${searchTolerance}/${appointmentTolerance}`, idToken);
                setShipmentAudit(audit);
                const hosData = await Promise.all(
                    audit.map(report => extractSamsaraHOSWaypoints(report?.hoslogs))
                );
                setHosWaypoints(hosData);
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching shipment audit.' }])
            }
            setDidFetchShipmentAudit(true);
        }
        if ((!didFetchShipment || !didFetchShipmentAudit) && ShipmentId) {
            fetchShipmentAudit();
        }
    }, [didFetchShipment, didFetchShipmentAudit, ShipmentId, idToken, alerts, appointmentTolerance, searchTolerance])

    useEffect(() => {
        // console.log('Mount Shipment Search');
        const fetchShipmentData = async () => {
            console.log('Fetch Shipment Data')
            const searchParams = new URLSearchParams(location.search);
            const shipmentId = searchParams.get('ShipmentId');
            const exists = shipments.find(ship => ship._id === shipmentId);
            if (exists) {
                setShowShipment(true)
                setShipmentId(shipmentId)
                setShipment(exists)
                setDidFetchShipmentAudit(false)
                const waypoints = await extractWaypoints(exists.edi204[0].Stops)
                setLoadTenderWaypoints(waypoints)
            }
        }

        fetchShipmentData();

    }, [alerts, shipments, ShipmentId, location.search, extractWaypoints]);

    useEffect(() => {
        // console.log('Mount Shipment Search');
        const searchShipmentData = async () => {
            console.log('Search Shipment Data')
            const searchParams = new URLSearchParams(location.search);
            const searchShipment = searchParams.get('search');
            try {
                if (searchShipment) {
                    setSearchShipmentId(searchShipment)
                    const shipmentSeachResult = await Api.get(`/edi/shipment/${searchShipment}`, idToken)
                    if (shipmentSeachResult) {
                        setShowSearchModal(true)
                        handleSearchShipment();
                    }
                }
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: `Shipment ID ${searchShipment} not found.`, }])
            } finally {
                setDidSearchForShipment(true);
            }
        }

        if (!searchForShipment) {
            searchShipmentData();
        }

    }, [idToken, alerts, shipments, ShipmentId, location.search, extractWaypoints, handleSearchShipment, searchForShipment]);

    useEffect(() => {
        const updateLocationHistory = () => {
            const searchParams = new URLSearchParams(location.search);
            const shipmentId = searchParams.get('ShipmentId');
            const exists = shipments.find(ship => ship._id === shipmentId);

            if (exists) {
                const newHistoryItem = {
                    ShipmentId: exists._id,
                    ContractTrip: exists['Contract Trip']
                };

                setShipmentViewHistory(prevHistory => {
                    const existsInHistory = prevHistory.some(item => item.ShipmentId === exists._id);

                    if (!existsInHistory) {
                        const updatedHistory = [newHistoryItem, ...prevHistory];

                        if (updatedHistory.length > 10) {
                            updatedHistory.pop();
                        }

                        return updatedHistory;
                    } else {

                        const updatedHistory = [newHistoryItem, ...prevHistory.filter(item => item.ShipmentId !== exists._id)];

                        if (updatedHistory.length > 10) {
                            updatedHistory.pop();
                        }

                        return updatedHistory;
                    }
                });
            }
        };

        updateLocationHistory()

    }, [shipments, location.search]);

    useEffect(() => {
        // console.log('Mount Quietly Update')
        if (quietlyUpdate) {
            console.log('updating content quietly')
            const fetchShipmentAudit = async () => {
                try {
                    const audit = await Api.get(`/edi/shipment/${ShipmentId}/audit/${searchTolerance}/${appointmentTolerance}`, idToken);
                    setShipmentAudit(audit);
                    const hosData = await Promise.all(
                        audit.map(report => extractSamsaraHOSWaypoints(report?.hoslogs))
                    );
                    setHosWaypoints(hosData);
                } catch (error) {
                    setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching shipment audit.' }])
                } finally {
                    setDidFetchShipmentAudit(true);
                    setQuietlyUpdate(false)
                }
            }

            fetchShipmentAudit();
        }
    }, [quietlyUpdate, ShipmentId, searchTolerance, appointmentTolerance, idToken, alerts])

    useEffect(() => {
        // console.log('Mount Fetch Tags')
        const fetchTags = async () => {
            console.log('Fetch Tags')
            try {
                const tagData = await Api.get('/edi/tags', idToken);
                setTags(tagData)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching tags.' }])
            } finally {
                setDidFetchTags(true)
            }
        }
        if (!didFetchTags) {
            fetchTags();
        }
    }, [didFetchTags, alerts, idToken])

    useEffect(() => {
        // console.log('Mount Refetch');
        const interval = setInterval(() => {
            console.log('5 minute fetch')
            setDidFetchShipments(false);
        }, 300000); // 300000 milliseconds = 5 minutes
        // Clear the interval on component unmount
        return () => clearInterval(interval);
    }, []);

    return (
        <Card style={{ border: 'none', overflow: 'none' }}>
            <AlertDisplay alertState={alertState} />
            {
                <PrintPDF sourceName={'MCTMS-LoadTender-Dashboard-v2'}>
                    <LoadTenderBanner
                        helpModalState={helpModalState}
                        codesModalState={codesModalState}
                        tagsModalState={tagsModalState}
                        searchModalState={searchModalState}
                    />
                    <Card.Header>
                        {!didFetchFilterData ?
                            <Spinner />
                            :
                            <LoadTenderFilters
                                queryState={queryState}
                                contractsState={contractsState}
                                operatorsState={operatorsState}
                                placesState={placesState}
                                didFetchShipmentsState={didFetchShipmentsState}
                                dataGraphDisplayState={dataGraphDisplayState}
                                pageReset={pageReset}
                                tagsState={tagsState}
                                periodState={periodState}
                            />
                        }
                    </Card.Header>
                    <Card.Header>
                        <LoadTenderHistoryBar
                            shipmentViewHistoryState={shipmentViewHistoryState}
                            viewShipmentFromHistory={viewShipmentFromHistory}
                        />
                    </Card.Header>
                    <Card.Body style={{ margin: 0, padding: 0 }}>
                        {!didFetchShipments ?
                            <>
                                <br />
                                <LogisticsLoading message={'Loading...'} />
                            </>
                            :
                            <>
                                {dataDisplay === 'Graph' &&
                                    <LoadTenderChartsDisplay
                                        shipmentsState={shipmentsState}
                                    />
                                }
                                {dataDisplay === 'Gantt' &&
                                    <LoadTenderGanttDisplay
                                        shipmentsState={shipmentsState}
                                    />
                                }
                                {dataDisplay === 'Table' &&
                                    <LoadTenderShipmentsDisplay
                                        queryState={query}
                                        shipmentsState={shipmentsState}
                                    />
                                }
                            </>
                        }

                        <LoadTenderShipmentModal
                            query={query}
                            user={user}
                            settings={settings}
                            shipmentModalState={shipmentModalState}
                            ShipmentIdState={ShipmentIdState}
                            shipmentState={shipmentState}
                            handleSearchReset={handleSearchReset}
                            samsaraDriversState={samsaraDriversState}
                            samsaraDriversByLocationState={samsaraDriversByLocationState}
                            operatorOptionState={operatorOptionState}
                            coverageOptionState={coverageOptionState}
                            removeOptionState={removeOptionState}
                            handleCoverageAssign={handleCoverageAssign}
                            handleCoverageRemove={handleCoverageRemove}
                            handleShipmentReject={handleShipmentReject}
                            handleShipmentAccept={handleShipmentAccept}
                            didFetchShipmentState={didFetchShipmentState}
                            didFetchShipmentsState={didFetchShipmentsState}
                            geofenceState={geofenceState}
                            circleGeofenceState={circleGeofenceState}
                            shipmentAudit={shipmentAudit}
                            didFetchShipmentAuditState={didFetchShipmentAuditState}
                            appointmentToleranceState={appointmentToleranceState}
                            searchToleranceState={searchToleranceState}
                            handleManualEdiStatusUpdate={handleManualEdiStatusUpdate}
                            showManualEdiStatusUpdateState={showManualEdiStatusUpdateState}
                            ediMessageStopUpdateState={ediMessageStopUpdateState}
                            loadtenderWaypointsState={loadtenderWaypointsState}
                            hosWaypointsState={hosWaypointsState}
                            allowTenderRejectState={allowTenderRejectState}
                            handleCompletedLifeCycle={handleCompletedLifeCycle}
                            handleAcceptedLifeCycle={handleAcceptedLifeCycle}
                            quietlyUpdateState={quietlyUpdateState}
                            tagsState={tagsState}
                            handleAddShipmentTag={handleAddShipmentTag}
                            handleRemoveShipmentTag={handleRemoveShipmentTag}
                            activeTelematicsAuditState={activeTelematicsAuditState}
                            handleSaveEdiAudit={handleSaveEdiAudit}
                            goodAuditState={goodAuditState}
                            auditNoteState={auditNoteState}
                            handlePageToNextShipment={handlePageToNextShipment}
                            handlePageToPreviousShipment={handlePageToPreviousShipment}
                        />

                        <LoadTenderHelpModal
                            helpModalState={helpModalState}
                        />

                        <LoadTenderCodesModal
                            codesModalState={codesModalState}
                        />

                        <LoadTenderSearchModal
                            shipmentFound={shipmentFound}
                            shipmentState={shipmentState}
                            isSearchingState={isSearchingState}
                            searchModalState={searchModalState}
                            handleSearchShipment={handleSearchShipment}
                            searchShipmentIdState={searchShipmentIdState}
                        />

                        <LoadTenderTagsModal
                            tagsModalState={tagsModalState}
                            tagsState={tagsState}
                            tagState={tagState}
                            handleTagCreate={handleTagCreate}
                            didFetchTagsState={didFetchTagsState}
                        />
                    </Card.Body>
                </PrintPDF>
            }
        </Card>
    )

}


export default LoadTenderDashboardV2;