import React, { useState, useEffect } from 'react'
import { Container, Card, Row, Col, Form, Button, Spinner, Tooltip, OverlayTrigger, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from "../../utils/Api";
import { useParams } from 'react-router-dom';
import { FaInfoCircle } from "react-icons/fa";
import { useAuth } from '../../context/auth';
import { FUEL_TYPES, LICENSE_TYPES, VEHICLE_CATEGORIES } from "../../context/appdata";
import AlertDisplay from '../../components/AlertDisplay';

function VehicleUpdate() {
    const { idToken } = useAuth();
    const { id } = useParams();
    const [set, didSet] = useState();
    const [vehicle, setVehicle] = useState({});
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [assignments, setAssignments] = useState([]);
    const [domiciles, setDomiciles] = useState([]);

    async function handleUpdate() {
        try {
            await Api.patch(`/vehicle/${id}`, vehicle, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success' }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error updating: ' + error.error }])
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const vehicle = await Api.get(`/vehicle/${id}`, idToken);
                setVehicle(vehicle)

                const assignments = await Api.get('/assignments/live', idToken)
                setAssignments(assignments)

                const domiciles = await Api.get('/locations/live/Domicile', idToken)
                setDomiciles(domiciles)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [set, idToken, id, alerts])


    return (
        <Container fluid>
            <Card>
                <Card.Header>Vehicle Update</Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {set ?
                    <Card.Body>
                        <Row>
                            <Col>
                                ID : {vehicle._id}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                Unit Number
                                <Form.Control
                                    type="text"
                                    value={vehicle.unitnumber}
                                    onChange={e => setVehicle({ ...vehicle, unitnumber: e.target.value })}
                                    disabled={vehicle?.provider ? true : false}
                                />
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                VIN
                                <Form.Control
                                    type="text"
                                    value={vehicle.vin}
                                    onChange={e => setVehicle({ ...vehicle, vin: e.target.value })}
                                    disabled={vehicle?.provider ? true : false}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                Assignment
                                <Form.Select value={JSON.stringify(vehicle.assignment)} onChange={e => setVehicle({ ...vehicle, assignment: JSON.parse(e.target.value) })}>
                                    <option>Select</option>
                                    {assignments && assignments.map(assignment => (
                                        <option key={assignment._id} value={JSON.stringify(assignment)}>{assignment.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Domicile
                                <Form.Select value={JSON.stringify(vehicle.domicile)} onChange={e => setVehicle({ ...vehicle, domicile: JSON.parse(e.target.value) })}>
                                    <option>Select</option>
                                    {domiciles && domiciles.map(domicile => (
                                        <option key={domicile._id} value={JSON.stringify(domicile)}>{domicile.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                Vehicle Type
                                <Form.Select value={vehicle.category} onChange={e => setVehicle({ ...vehicle, category: e.target.value })}>
                                    <option>Select</option>
                                    {VEHICLE_CATEGORIES.map((category, i) => (
                                        <option key={i}>{category.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                License Required
                                <Form.Select value={vehicle.licensereq} onChange={e => setVehicle({ ...vehicle, licensereq: e.target.value })}>
                                    <option>Select</option>
                                    {LICENSE_TYPES.map((license, i) => (
                                        <option key={i}>{license.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Fuel Type
                                <Form.Select value={vehicle.fueltype} onChange={e => setVehicle({ ...vehicle, fueltype: e.target.value })}>
                                    <option>Select</option>
                                    {FUEL_TYPES.map((fuel, i) => (
                                        <option key={i}>{fuel.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                Year
                                <Form.Control type="number" value={vehicle.year} onChange={e => setVehicle({ ...vehicle, year: e.target.value })} />
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Make
                                <Form.Control type="text" value={vehicle.make} onChange={e => setVehicle({ ...vehicle, make: e.target.value })} />
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Model
                                <Form.Control type="text" value={vehicle.model} onChange={e => setVehicle({ ...vehicle, model: e.target.value })} />
                            </Col>

                        </Row>
                        {/* <br />
                        <Row>
                            <Col>
                                <Form.Check label={'Substitute required'} checked={vehicle.substitute} onChange={e => setVehicle({ ...vehicle, substitute : vehicle.substitute === true ? false : true })} />
                            </Col>
                        </Row> */}
                        {/* {vehicle.substitute &&
                        <>
                            <Row>
                                <Col>
                                    <Card.Title>
                                        Substitute Log
                                    </Card.Title>
                                    <Table striped bordered hover responsive>
                                        <thead>
                                            <th>#</th>
                                            <th>Unit Number</th>
                                            <th>Date In</th>
                                            <th>Date Out</th>
                                            <th>Reason</th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </>
                        } */}
                        <br />
                        <Row>
                            <Col sm={6}>
                                <Button variant="primary" onClick={() => handleUpdate()}>Update</Button>
                            </Col>
                            <Col className="d-flex justify-content-end align-items-center">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            Active - determines whether or not the record should be
                                            used by the software application.
                                        </Tooltip>
                                    }
                                >
                                    <div>
                                        Active <FaInfoCircle /> : <Button variant={vehicle.isActive ? 'success' : 'danger'} onClick={() => { setVehicle({ ...vehicle, isActive: (vehicle.isActive ? false : true) }) }}>{vehicle.isActive ? 'True' : 'False'}</Button>
                                    </div>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    );
}

export default VehicleUpdate;