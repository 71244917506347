// determine whether a date is week one or week two based on client settings
// returns wk1 or wk2 w/ dow eg. wk1mon or wk2sun

const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

const Week1Digest = (date) => {
    const targetDate = typeof date === "string" ? new Date(date + 'T00:00:00') : new Date(date);

    // Determine the day of the week based on the actual calendar
    const day = daysOfWeek[targetDate.getDay()];

    return `wk1${day}`;
}

const DayOfWeekDigest = (date) => {
    const targetDate = typeof date === "string" ? new Date(date + 'T00:00:00') : new Date(date);

    // Determine the ISO week date (year and week number)
    const isoYear = targetDate.getFullYear();
    const isoWeek = new Date(isoYear, targetDate.getMonth(), targetDate.getDate() - targetDate.getDay() + 4);
    const isoWeekNumber = Math.ceil((isoWeek - new Date(isoYear, 0, 1)) / 86400000 / 7);

    // Determine the day of the week based on the actual calendar
    const day = daysOfWeek[targetDate.getDay()];

    // Determine the week (1 or 2) based on ISO week number
    const week = (isoWeekNumber === 0 || isoWeekNumber % 2 === 0) ? 1 : 2;

    return `wk${week}${day}`;
}

function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}


function getDayOfWeek(dateString) {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const date = new Date(dateString);
    const dayOfWeekIndex = date.getUTCDay();
    return `${daysOfWeek[dayOfWeekIndex]}`;
}

module.exports = { DayOfWeekDigest, Week1Digest, getDayOfWeek, formatDate };

