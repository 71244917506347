import { Container, Card, Row, Col, Form, Button, Spinner, Alert, Tooltip, OverlayTrigger, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import Api from "../../utils/Api";
import { useParams } from 'react-router-dom';
import { FaInfoCircle } from "react-icons/fa";
import { useAuth } from '../../context/auth';
import AlertDisplay from "../../components/AlertDisplay";

function SpecialOpUpdate() {
    const { idToken } = useAuth();
    const { id } = useParams();
    const [set, didSet] = useState();
    const [specialop, setSpecialOp] = useState({});
    const [alerts, setAlerts] = useState([])
    const alertState = { alerts, setAlerts }

    async function handleUpdate() {
        try {
            await Api.patch(`/specialop/${id}`, specialop, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success' }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Update error' }])
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Api.get(`/specialop/${id}`, idToken);
                setSpecialOp(data)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }

            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [set, idToken, id, alerts])

    return (
        <Container fluid>
            <Card>
                <Card.Header><b>Special Op Update</b></Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {set ?
                    <Card.Body>
                        <Row>
                            <Col>
                                ID : {specialop._id}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm={6} md={4} lg={3}>
                                Name
                                <Form.Control value={specialop.name} onChange={(e) => setSpecialOp({ ...specialop, name: e.target.value })} />
                            </Col>
                            <Col sm={6} md={4} lg={3}>
                                Type
                                <Form.Select value={specialop.type} onChange={e => setSpecialOp({ ...specialop, type: e.target.value })}>
                                    <option>Select</option>
                                    <option value={"md"}>Month/Day</option>
                                    <option value={"mwl"}>Month/Week/Day of Week</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        {specialop.type === "md" &&
                            <Row>
                                <Col sm={6} md={4} lg={3}>
                                    Month
                                    <Form.Select value={specialop.month} onChange={e => setSpecialOp({ ...specialop, month: e.target.value })}>
                                        <option>Select</option>
                                        <option>January</option>
                                        <option>February</option>
                                        <option>February</option>
                                        <option>March</option>
                                        <option>April</option>
                                        <option>May</option>
                                        <option>June</option>
                                        <option>July</option>
                                        <option>August</option>
                                        <option>September</option>
                                        <option>October</option>
                                        <option>November</option>
                                        <option>December</option>
                                    </Form.Select>
                                </Col>
                                <Col sm={6} md={4} lg={3}>
                                    Day
                                    <Form.Control type="number" value={specialop.day} min={1} max={31} onChange={(e) => setSpecialOp({ ...specialop, day: e.target.value })} />
                                </Col>
                            </Row>
                        }
                        {specialop.type === 'mwl' &&
                            <Row>
                                <Col sm={6} md={4} lg={3}>
                                    Month
                                    <Form.Select value={specialop.month} onChange={e => setSpecialOp({ ...specialop, month: e.target.value })}>
                                        <option>Select</option>
                                        <option>January</option>
                                        <option>February</option>
                                        <option>February</option>
                                        <option>March</option>
                                        <option>April</option>
                                        <option>May</option>
                                        <option>June</option>
                                        <option>July</option>
                                        <option>August</option>
                                        <option>September</option>
                                        <option>October</option>
                                        <option>November</option>
                                        <option>December</option>
                                    </Form.Select>
                                </Col>
                                <Col sm={6} md={4} lg={3}>
                                    Week Number
                                    <Form.Select value={specialop.week} onChange={e => setSpecialOp({ ...specialop, week: e.target.value })}>
                                        <option>Select</option>
                                        <option>First</option>
                                        <option>Second</option>
                                        <option>Third</option>
                                        <option>Fourth</option>
                                        <option>Last</option>

                                    </Form.Select>
                                </Col>
                                <Col sm={6} md={4} lg={3}>
                                    Day of Week
                                    <Form.Select value={specialop.dow} onChange={e => setSpecialOp({ ...specialop, dow: e.target.value })}>
                                        <option>Select</option>
                                        <option>Monday</option>
                                        <option>Tuesday</option>
                                        <option>Wednesday</option>
                                        <option>Thursday</option>
                                        <option>Friday</option>
                                        <option>Saturday</option>
                                        <option>Sunday</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        }
                        <br />
                        <Row>
                            <Col sm={6}>
                                <Button variant="primary" onClick={() => handleUpdate()}>Update</Button>
                            </Col>
                            <Col className="d-flex justify-content-end align-items-center">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            Active - determines whether or not the record should be
                                            used by the software application.
                                        </Tooltip>
                                    }
                                >
                                    <div>
                                        Active <FaInfoCircle /> : <Button variant={specialop.isActive ? 'success' : 'danger'} onClick={() => { setSpecialOp({ ...specialop, isActive: (specialop.isActive ? false : true) }) }}>{specialop.isActive ? 'True' : 'False'}</Button>
                                    </div>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    );
}

export default SpecialOpUpdate;