import { Container, Card, Row, Col, Button, Spinner, Alert} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import Api from "../../utils/Api";
import { Link, useParams } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import EDI204Component from "./EDI204.component";
import EDI990Component from "./EDI990.component";
import EDI214Component from "./EDI214.component";
import EDI210Component from "./EDI210.component";
import LoadTenderOperator from "./LoadTenderOperator.component"; 
import AlertDisplay from "../../components/AlertDisplay";

function LoadTenderView() {
    const { id } = useParams();
    const { idToken } = useAuth();

    const [set, didSet] = useState();
  
    const [alerts, setAlerts] = useState([]);
    const alertState = {alerts, setAlerts}

    const [togglejson, setToggleJSON] = useState();

    const [edi204, setEDI204] = useState();
    const [edi990s, setEDI990s] = useState();
    const [edi214s, setEDI214s] = useState();
    const [edi210s, setEDI210s] = useState();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const e204data = await Api.get(`/edi204/${id}`, idToken);
                if(!e204data || e204data.length  === 0 ){
                    throw new Error('No EDI 204')
                }
                const e204 = e204data[0]
                setEDI204(e204)
                //
                const params = new URLSearchParams();
                params.append('ShipmentId', e204.original.ShipmentId);
                //
                const e990s = e204.newest.edi990Data;
                setEDI990s(e990s)
                //
                const e214s = e204.newest.edi214Data;
                setEDI214s(e214s)
                //
                const e210s = e204.newest.edi214Data;
                setEDI210s(e210s)

            } catch (err) {
                setAlerts([...alerts, {variant:'warning',message:'An error occured.'}])
            }
            didSet(true)
        }
        if(!set){
            fetchData()
        }
    })

    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <b>Load Tender View</b>
                    <Button variant="secondary" style={{ float: 'right' }} onClick={() => setToggleJSON(togglejson ? false : true)}>{togglejson ? "See UI" : "See Raw"}</Button>
                    <Link to={`/loadtender/update/${id}`} style={{ float: 'right', padding:'0.5em'}}> Update </Link>
                </Card.Header>
                <AlertDisplay alertState={alertState} />
                {set ?
                    <Card.Body>
                        <>
                            {togglejson ?
                                <Card.Body>
                                    <pre>
                                        EDI 204<br />
                                        {JSON.stringify(edi204, "", 2)}
                                        <hr />

                                        EDI 990<br />
                                        {JSON.stringify(edi990s, "", 2)}
                                        <hr />

                                        EDI 214<br />
                                        {JSON.stringify(edi214s, "", 2)}
                                        <hr />

                                        EDI 210 <br />
                                        {JSON.stringify(edi210s, "", 2)}
                                    </pre>
                                </Card.Body>
                                :
                                <>
                                    <EDI204Component edi204={edi204.newest} />
                                    <hr />
                                    <LoadTenderOperator operators={edi204?.original?.operators} />
                                    <hr/>
                                    <EDI990Component edi990s={edi990s} />
                                    <hr />
                                    <EDI214Component edi214s={edi214s} />
                                    <hr />
                                    <EDI210Component edi210s={edi210s} />
                                </>
                            }
                        </>
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
            <br />
        </Container>
    );
}

export default LoadTenderView;

