import { Container, Card, Row, Col, Spinner, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/auth';
import DataDisplay from "../../components/DataDisplay";
import { FaSync } from "react-icons/fa";
import { adjustStringLength} from "../../utils/String.helper";
import AlertDisplay from "../../components/AlertDisplay";

//todo dedeup
const today = new Date();  // today based on the clock
const tomorrow = new Date(today); // based on today value
const yesterday = new Date(today); // based on today value
const minusSeven = new Date(today); // minus 7 days
const minusFourteen = new Date(today); // minus 14 days
const minusThirty = new Date(today); // minus 30 days
const plusSeven = new Date(today); // based on today value, plus 7 days
const plusFourteen = new Date(today); // based on today value, plus 14 days
const plusThirty = new Date(today); // based on today value, plus 30 days
const lastSunday = new Date(today); // based on today value, the last week's Sunday
const lastSaturday = new Date(today);// based on today value, the last week's Saturday
const thisSunday = new Date(today); // based on today value, the current week's Sunday
const thisSaturday = new Date(today);// based on today value, the current week's Saturday
const nextSunday = new Date(today);// based on today value, the next week's Sunday
const nextSaturday = new Date(today);// based on today value, the next week's Saturday
const newyears = new Date(today); // current calendar's first day of the year
const newyearseve = new Date(today); // current calendar's last day of the year
const currentYear = today.getFullYear();
const currentMonth = today.getMonth();
const nextMonth = new Date(today);
nextMonth.setMonth(currentMonth + 1);
const firstdayofthemonth = new Date(currentYear, currentMonth, 1);
const lastdayofthemonth = new Date(currentYear, currentMonth + 1, 0);
const firstdayoflastmonth = new Date(currentYear, currentMonth - 1, 1);
const lastdayoflastmonth = new Date(currentYear, currentMonth, 0);
const firstdayofnextmonth = new Date(currentYear, currentMonth + 1, 1);
const lastDayOfNextMonth = new Date(nextMonth);
lastDayOfNextMonth.setMonth(nextMonth.getMonth() + 1, 0);

tomorrow.setDate(today.getDate() + 1);
yesterday.setDate(today.getDate() - 1);
minusSeven.setDate(today.getDate() - 7);
minusFourteen.setDate(today.getDate() - 14);
minusThirty.setDate(today.getDate() - 30);
plusSeven.setDate(today.getDate() + 7);
plusFourteen.setDate(today.getDate() + 14);
plusThirty.setDate(today.getDate() + 30);


// Calculate the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
const currentDayOfWeek = today.getDay();

// Calculate the date for the last week's Sunday and Saturday
lastSunday.setDate(today.getDate() - (currentDayOfWeek + 7));
lastSaturday.setDate(today.getDate() - (currentDayOfWeek + 1));

// Calculate the date for the current week's Sunday and Saturday
thisSunday.setDate(today.getDate() - currentDayOfWeek);
thisSaturday.setDate(today.getDate() + (6 - currentDayOfWeek));

// Calculate the date for the next week's Sunday and Saturday
nextSunday.setDate(today.getDate() + (7 - currentDayOfWeek));
nextSaturday.setDate(today.getDate() + (13 - currentDayOfWeek));

// Calculate the first day and last day of the current year
newyears.setMonth(0); // January (0 index)
newyears.setDate(1);

newyearseve.setMonth(11); // December (0 index)
newyearseve.setDate(31);

function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    return `${year}-${month}-${day}`;
}

const initialQuery = {
    start: formatDate(today),
    end: formatDate(today),
    // loadtenderLifeCycleStatus:'All',
    contract:'All',
}

function LoadTenderActive() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [load, didLoad] = useState();
    const [contracts, setContracts] = useState([]);
    const [edi204, setEDI204] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = {alerts, setAlerts}
    const [period, setPeriod] = useState('today');
    const [query, setQuery] = useState(initialQuery);

    const labelStyle = {
        fontSize: '.9em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        whiteSpace: 'nowrap',
    }

    const reloadStyle = {
        fontSize: '.75em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        backgroundColor: '#bcdbbc',
        border: '1px solid',
        borderColor: '#222',
        borderRadius: '0.25em',
        whiteSpace: 'nowrap',
        float: 'left'
    }


    const buttonStyle = {
        fontSize: '.8em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        backgroundColor: '#fff',
        border: '1px solid',
        borderColor: '#222',
        borderRadius: '0.25em',
        whiteSpace: 'nowrap'
    }

    const buttonSelectedStyle = {
        fontSize: '.8em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        backgroundColor: '#6eb6ff',
        border: '1px solid',
        borderColor: '#222',
        borderRadius: '0.25em',
        whiteSpace: 'nowrap'
    }



    function setQueryStart(value) {
        if (value <= query.end) {
            setPeriod('custom')
            setQuery({ ...query, start: value })
            didSet(false)
        } else {
            setAlerts([...alerts, { variant: 'warning', message: 'Start should come before end.' }]);
        }
    }
    function setQueryEnd(value) {
        if (value >= query.start) {
            setPeriod('custom')
            setQuery({ ...query, end: value })
            didSet(false)
        } else {
            setAlerts([...alerts, { variant: 'warning', message: 'End should come after start.' }]);
        }
    }

    function setQueryContract(value) {
        setQuery({ ...query, contract: value })
        didSet(false)
    }

    function setQueryToday() {
        setQuery({ ...query, start: formatDate(today), end: formatDate(today) })
        setPeriod('today')
        didSet(false)
    }
    function setQueryTomorrow() {
        setQuery({ ...query, start: formatDate(tomorrow), end: formatDate(tomorrow) })
        setPeriod('tomorrow')
        didSet(false)
    }
    function setQueryYeserday() {
        setQuery({ ...query, start: formatDate(yesterday), end: formatDate(yesterday) })
        setPeriod('yesterday')
        didSet(false)
    }
    function setThisWeek() {
        setQuery({ ...query, start: formatDate(thisSunday), end: formatDate(thisSaturday) })
        setPeriod('thisweek')
        didSet(false)
    }
    function setLastWeek() {
        setQuery({ ...query, start: formatDate(lastSunday), end: formatDate(lastSaturday) })
        setPeriod('lastweek')
        didSet(false)
    }
    function setNextWeek() {
        setQuery({ ...query, start: formatDate(nextSunday), end: formatDate(nextSaturday) })
        setPeriod('nextweek')
        didSet(false)
    }
    function setThisMonth() {
        setQuery({ ...query, start: formatDate(firstdayofthemonth), end: formatDate(lastdayofthemonth) })
        setPeriod('thismonth')
        didSet(false)
    }
    function setLastMonth() {
        setQuery({ ...query, start: formatDate(firstdayoflastmonth), end: formatDate(lastdayoflastmonth) })
        setPeriod('lastmonth')
        didSet(false)
    }
    function setNextMonth() {
        setQuery({ ...query, start: formatDate(firstdayofnextmonth), end: formatDate(lastDayOfNextMonth) })
        setPeriod('nextmonth')
        didSet(false)
    }
    function setQueryMinus7() {
        setQuery({ ...query, start: formatDate(minusSeven), end: formatDate(today) })
        setPeriod('minus7')
        didSet(false)
    }
    function setQueryMinus14() {
        setQuery({ ...query, start: formatDate(minusFourteen), end: formatDate(today) })
        setPeriod('minus14')
        didSet(false)
    }
    function setQueryMinus30() {
        setQuery({ ...query, start: formatDate(minusThirty), end: formatDate(today) })
        setPeriod('minus30')
        didSet(false)
    }
    function setQueryPlus7() {
        setQuery({ ...query, start: formatDate(today), end: formatDate(plusSeven) })
        setPeriod('plus7')
        didSet(false)
    }
    function setQueryPlus14() {
        setQuery({ ...query, start: formatDate(today), end: formatDate(plusFourteen) })
        setPeriod('plus14')
        didSet(false)
    }
    function setQueryPlus30() {
        setQuery({ ...query, start: formatDate(today), end: formatDate(plusThirty) })
        setPeriod('plus30')
        didSet(false)
    }
    function setQueryYTD() {
        setQuery({ ...query, start: formatDate(newyears), end: formatDate(today) })
        setPeriod('ytd')
        didSet(false)
    }

    useEffect(() => {
        function extractUniqueValues(arr) {
            const uniqueValues = new Set();
          
            arr.forEach(subArray => {
              subArray.forEach(value => {
                uniqueValues.add(value);
              });
            });
          
            return Array.from(uniqueValues);
          }

          function extractPrefixes(arr) {
                return  arr.map(item => {
                const parts = item.split("_"); // Split the string into parts using the underscore character
                if (parts.length >= 2) {
                  return parts.slice(0, 2).join("_"); // Combine the first two parts with an underscore
                } else {
                  return item; // If there are fewer than 2 parts, return the original item
                }
              });
          }
 

        const fetchData = async () => {
            try {
                const edi204s = await Api.query(`/edi204`, {'loadtenderLifeCycleStatus':'Accepted', ...query}, idToken);
                // setEDI204(edi204s)
                const clean204s = edi204s.map(record => {
                    const extractedReferences = record?.Stops.map(via => via?.References.map(referance => referance?.Identifier));
                    const extractedValues = extractUniqueValues(extractedReferences)
                    const extractedData = extractPrefixes(extractedValues)
                    const uniqueData = [...new Set(extractedData)];
                    return {
                        _id: record._id,
                        'Shipment Id': record?.ShipmentId,
                        'Coverage': record?.operators.map(op => `${op.name}`).join('\n'),
                        'Contract Trip': uniqueData.join(', \n').replace('_',' '),
                        // 'Requested Date': record?.Stops[0].RequestedDate,
                        // 'Last Requested Date': record?.Stops[record?.Stops.length-1].LastRequestedDate,
                        'Schedule': record?.Stops.map(via => `${
                            adjustStringLength(via?.StopNum,'2',' ',false)
                        } | A:${
                            adjustStringLength(via?.RequestedDate,'20',' ',false) 
                        } | D:${
                            adjustStringLength(via?.LastRequestedDate,'20',' ',false) 
                        } |  ${
                            adjustStringLength(via?.ReasonDesc,'20',' ',true)
                        }`).join('\n'),
                        'Equipment':record?.Equipment,
                    }
                })
                setEDI204(clean204s)
            } catch (err) {
                setAlerts([...alerts, {variant:'warning',message:'An error occured.'}])
            }
            didSet(true)
        }

        const loadData = async () => {
            try {
                const ediContracts = await Api.get(`/edi204/contracts`, idToken);
                setContracts(ediContracts)
            } catch (err) {
                setAlerts([...alerts, {variant:'warning',message:'An error occured.'}])
            }
            didLoad(true)
        }

        if(!set){
            fetchData()
        }

        if(!load){
            loadData();
        }
    })


    return (
        <Container fluid>
            <AlertDisplay alertState={alertState} />
            <Card>
                <Card.Header>
                    <Row>
                        <Col sm={12} md={6}><b>Load Tender Active</b></Col>
                    </Row>
                </Card.Header>
                <Card.Header>
                    <Row>
                        <Col>
                            <Button type="button" style={reloadStyle} onClick={() => didSet(false)}><FaSync /> Fetch</Button>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Header>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                        <div>
                            <label style={labelStyle}>Contract</label>
                            <select style={buttonStyle} value={query.location} onChange={e => setQueryContract(e.target.value)}>
                                <option value="All">All</option>
                                {contracts.map((contract,i) => (
                                    <option key={i}>{contract}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label style={labelStyle}>Start</label>
                            <input type="date" style={buttonStyle} value={query.start} onChange={e => setQueryStart(e.target.value)} />
                        </div>
                        <div>
                            <label style={labelStyle}>End</label>
                            <input type="date" style={buttonStyle} value={query.end} onChange={e => setQueryEnd(e.target.value)} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                        <button type="button" style={period === 'minus30' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryMinus30()}>-30</button>
                        <button type="button" style={period === 'minus14' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryMinus14()}>-14</button>
                        <button type="button" style={period === 'minus7' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryMinus7()}>-7</button>
                        <button type="button" style={period === 'lastmonth' ? buttonSelectedStyle : buttonStyle} onClick={() => setLastMonth()}>Last Month</button>
                        <button type="button" style={period === 'lastweek' ? buttonSelectedStyle : buttonStyle} onClick={() => setLastWeek()}>Last Week</button>
                        <button type="button" style={period === 'yesterday' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryYeserday()}>Yesterday</button>
                        <button type="button" style={period === 'today' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryToday()}>Today</button>
                        <button type="button" style={period === 'tomorrow' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryTomorrow()}>Tomorrow</button>
                        <button type="button" style={period === 'thisweek' ? buttonSelectedStyle : buttonStyle} onClick={() => setThisWeek()}>This Week</button>
                        <button type="button" style={period === 'nextweek' ? buttonSelectedStyle : buttonStyle} onClick={() => setNextWeek()}>Next Week</button>
                        <button type="button" style={period === 'thismonth' ? buttonSelectedStyle : buttonStyle} onClick={() => setThisMonth()}>This Month</button>
                        <button type="button" style={period === 'nextmonth' ? buttonSelectedStyle : buttonStyle} onClick={() => setNextMonth()}>Next Month</button>
                        <button type="button" style={period === 'plus7' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryPlus7()}>+7</button>
                        <button type="button" style={period === 'plus14' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryPlus14()}>+14</button>
                        <button type="button" style={period === 'plus30' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryPlus30()}>+30</button>
                        <button type="button" style={period === 'ytd' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryYTD()}>YTD</button>
                    </div>
                </Card.Header>
                <Card.Header id="mctms-printable">
                    {period === 'minus30' && ' Records from past 30 days.'}
                    {period === 'minus14' && ' Records from past 14 days.'}
                    {period === 'minus7' && ' Records from past 7 days.'}
                    {period === 'yesterday' && ' Records from yesterday.'}
                    {period === 'today' && ' Records from today.'}
                    {period === 'tomorrow' && ' Records for tomorrow.'}
                    {period === 'thisweek' && ' Records for this week Sunday-Saturday.'}
                    {period === 'nextweek' && ' Records for this week Sunday-Saturday.'}
                    {period === 'lastweek' && ' Records from last week Sunday-Saturday.'}
                    {period === 'thismonth' && ' Records for this month.'}
                    {period === 'nextmonth' && ' Records for next month.'}
                    {period === 'lastmonth' && ' Records for last month.'}
                    {period === 'plus7' && ' Records for next 7 days.'}
                    {period === 'plus14' && ' Records for next 14 days.'}
                    {period === 'plus30' && ' Records for next 30 days.'}
                    {period === 'ytd' && ' Records from first of the year through today.'}
                    {period === 'custom' && `Custom range: ${query.start}-${query.end}`}
                </Card.Header>
                {set ?
                    <Card.Body>
                        <DataDisplay
                            sourceName={'MCTMS-Active-Load-Tenders'}
                            dataSource={edi204}
                            lsKey={'@mctms-loadtender-204-display-active'}
                            urlPath={'/loadtender/update/'}
                            urlKey={'_id'}
                            popKeys={['_id']}
                            // addActions={[{urlPath: '/loadtender/view/', urlKey:'_id', icon:<FaEye/>}]}
                        />
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }
            </Card>
        </Container>
    );
}

export default LoadTenderActive;