
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { GoogleMap, DirectionsRenderer, Marker, useLoadScript } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '700px',
};

const GoogleMapPlotRoute = ({ centerMapLatitude, centerMapLongitude, waypoints1 = [], waypoints2 = [], waypoints3 = [], waypoints4 = [], gindex = 0 }) => {
  const validatedCenterMapLatitude = typeof centerMapLatitude === 'number' ? centerMapLatitude : 0;
  const validatedCenterMapLongitude = typeof centerMapLongitude === 'number' ? centerMapLongitude : 0;

  const mapRef = useRef(null);
  const [set, didSet] = useState();
  // const [apiLoaded, setApiLoaded] = useState(false);
  const [directions1, setDirections1] = useState(null);
  const [directions2, setDirections2] = useState(null);
  const [directions3, setDirections3] = useState(null);
  const [directions4, setDirections4] = useState(null);
  const [show1, setShow1] = useState(true);
  const [show2, setShow2] = useState(true);
  const [show3, setShow3] = useState(true);
  const [show4, setShow4] = useState(true);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });


  // const fitBounds = (map, directionsArray) => {
  //   if (apiLoaded) {
  //     const bounds = new window.google.maps.LatLngBounds();
  //     directionsArray.forEach(directions => {
  //       if (directions) {
  //         directions.routes[0].legs.forEach(leg => {
  //           leg.steps.forEach(step => {
  //             step.path.forEach(point => {
  //               bounds.extend(point);
  //             });
  //           });
  //         });
  //       }
  //     });
  //     map.fitBounds(bounds);
  //   }
  // };

  // const loadGoogleMapsApi = () => {
  //   if (!window.google) {
  //     const script = document.createElement("script");
  //     script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=initGoogleMapsApi`;
  //     script.async = true;
  //     script.defer = true;
  //     document.head.appendChild(script);
  //     window.initGoogleMapsApi = () => {
  //       setApiLoaded(true);
  //     };
  //   } else {
  //     setApiLoaded(true);
  //   }
  // };

  // useEffect(() => {
  //   loadGoogleMapsApi();
  // }, []);

  const CustomOverlay = ({ map, maps, position, text }) => {
    const container = useRef(null);

    useEffect(() => {
      if (map && maps) {
        const overlay = new maps.OverlayView();

        overlay.onAdd = () => {
          container.current = document.createElement('div');
          container.current.style.position = 'absolute';
          container.current.style.textAlign = 'center';
          container.current.style.whiteSpace = 'nowrap';
          container.current.style.fontSize = '12px';
          container.current.style.padding = '2px';
          container.current.innerHTML = text;

          const panes = overlay.getPanes();
          panes.overlayLayer.appendChild(container.current);
        };

        overlay.draw = () => {
          const point = overlay.getProjection().fromLatLngToDivPixel(position);
          container.current.style.left = point.x + 'px';
          container.current.style.top = (point.y - 30) + 'px'; // Adjust the Y position as needed
        };

        overlay.onRemove = () => {
          if (container.current) {
            container.current.parentNode.removeChild(container.current);
            container.current = null;
          }
        };

        overlay.setMap(map);

        return () => {
          overlay.setMap(null);
        };
      }
    }, [map, maps, position, text]);

    return null;
  };

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);


  useEffect(() => {
    if (!set && isLoaded) {
      const generateDirections = async (waypoints, setDirections) => {
        if (waypoints.length >= 2) {
          const waypointsLatLng = waypoints.map(waypoint => ({
            location: { lat: waypoint.latitude, lng: waypoint.longitude },
            stopover: true,
          }));
          const origin = waypointsLatLng[0].location;
          const destination = waypointsLatLng[waypointsLatLng.length - 1].location;
          const directionsService = new window.google.maps.DirectionsService();
          const request = {
            origin,
            destination,
            waypoints: waypointsLatLng.slice(1, -1),
            travelMode: window.google.maps.TravelMode.DRIVING,
            avoidTolls: false,
          };
          directionsService.route(request, (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              setDirections(result);
            } else {
              console.error('Failed to fetch directions:', status);
            }
          });
        }
      };

      waypoints1.length > 0 && generateDirections(waypoints1, setDirections1);
      waypoints2.length > 0 && generateDirections(waypoints2, setDirections2);
      waypoints3.length > 0 && generateDirections(waypoints3, setDirections3);
      waypoints4.length > 0 && generateDirections(waypoints4, setDirections4);
    }
    return () => didSet(true)
  }, [set, isLoaded, waypoints1, waypoints2, waypoints3, waypoints4]);

  if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
    return "Google Maps API key is not set in your environment variables.";
  }

  if (loadError) return "Error loading maps";

  if (!isLoaded) return "Loading Maps";

  return (
    <>
      {isLoaded && (
        <>
          <Row>
            {waypoints1.length > 0 &&
              <Col style={{ textAlign: 'center' }}>
                <Button style={{ backgroundColor: '#f39c9c', border: '1px solid #000', whiteSpace: 'nowrap', fontSize: '.9em', margin: '0.25em', padding: '0.25em', color: '#000' }} onClick={() => setShow1(!show1)}>
                  {show1 ? 'Hide Route Plan' : 'Show Route Plan'}
                </Button>
              </Col>
            }
            {waypoints2.length > 0 &&
              <Col style={{ textAlign: 'center' }}>
                <Button style={{ backgroundColor: '#bcdbbc', border: '1px solid #000', whiteSpace: 'nowrap', fontSize: '.9em', margin: '0.25em', padding: '0.25em', color: '#000' }} onClick={() => setShow2(!show2)}>
                  {show2 ? 'Hide Dispatch' : 'Show Dispatch'}
                </Button>
              </Col>
            }
            {waypoints3.length > 0 &&
              <Col style={{ textAlign: 'center' }}>
                <Button style={{ backgroundColor: '#95add5', border: '1px solid #000', whiteSpace: 'nowrap', fontSize: '.9em', margin: '0.25em', padding: '0.25em', color: '#000' }} onClick={() => setShow3(!show3)}>
                  {show3 ? 'Hide Tracking' : 'Show Tracking'}
                </Button>
              </Col>
            }
            {waypoints4.length > 0 &&
              <Col style={{ textAlign: 'center' }}>
                <Button style={{ backgroundColor: '#ff9900', border: '1px solid #000', whiteSpace: 'nowrap', fontSize: '.9em', margin: '0.25em', padding: '0.25em', color: '#000' }} onClick={() => setShow4(!show4)}>
                  {show4 ? 'Hide Load Tender' : 'Show Load Tender'}
                </Button>
              </Col>
            }
          </Row>
          <br />
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={{ lat: validatedCenterMapLatitude, lng: validatedCenterMapLongitude }}
            zoom={7}
            options={{ mapId: `MAP-${gindex}`, fullscreenControl: false }}
            onLoad={() => onMapLoad()}
          // apiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
          >
            {show1 &&
              directions1 && (
                <DirectionsRenderer
                  directions={directions1}
                  options={{ polylineOptions: { strokeColor: 'red' } }}
                />
              )}
            {show1 &&
              waypoints1.map((waypoint, index) => (
                <Marker
                  key={`red-${index}`}
                  position={{ lat: waypoint.latitude, lng: waypoint.longitude }}
                  label={`${index + 1}`}
                  options={{ icon: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png' }}
                />
              ))}
            {show2 &&
              directions2 && (
                <DirectionsRenderer
                  directions={directions2}
                  options={{ polylineOptions: { strokeColor: 'green' } }}
                />
              )}
            {show2 &&
              waypoints2.map((waypoint, index) => (
                <Marker
                  key={`green-${index}`}
                  position={{ lat: waypoint.latitude, lng: waypoint.longitude }}
                  label={`${index + 1}`}
                  options={{ icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png' }}
                />
              ))}
            {show3 &&
              directions3 && (
                <DirectionsRenderer
                  directions={directions3}
                  options={{ polylineOptions: { strokeColor: 'blue' } }}
                />
              )}
            {show3 &&
              waypoints3.map((waypoint, index) => (
                <Marker
                  key={`blue-${index}`}
                  position={{ lat: waypoint.latitude, lng: waypoint.longitude }}
                  label={`${index + 1}`}
                  options={{ icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png' }}
                />
              ))}
            {show4 &&
              directions4 && (
                <DirectionsRenderer
                  directions={directions4}
                  options={{ polylineOptions: { strokeColor: 'orange' } }}
                />
              )}
            {show4 &&
              waypoints4.map((waypoint, index) => (
                <Marker
                  key={`orange-${index}`}
                  position={{ lat: waypoint.latitude, lng: waypoint.longitude }}
                  label={`${index + 1}`}
                  options={{ icon: 'http://maps.google.com/mapfiles/ms/icons/orange-dot.png' }}
                />
              ))}
          </GoogleMap>
        </>
      )}
    </>
  );
};

export default GoogleMapPlotRoute;
