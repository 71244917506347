import React, { useState, useEffect, useRef } from "react";
import { Card, Button, Form, Row, Col, Alert } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
const XLSX = require("xlsx");

const ffhkey = 'fiftyfivehundred'

const maxSize = 1024 * 1024 * 5; // 5 MB (change as needed)

const USPS_5500_FILE_COLUMNS = [
  'Trip Date',
  'Arrival Lateness (min)',
  'Actual Departure (local)',
  'Excusable or Chargeable',
  'Driver\'s Number',
  'Departure Lateness (min)',
  'undefined',
  'Scheduled Arrival (local)',
  'Form Status',
  'Late Reason',
  'Driver\'s Name',
  'Short Description',
  'Number',
  'Sys ID',
  'Supplier Response Selected',
  'Actual Arrival (local)',
  'Point of Irregularity',
  'Route Number',
  'Trip Number',
  'Irregularities',
  'Irregularity Date',
  'Has Breached',
  'Date Sent to Supplier',
  'Due Date',
  'Supplier Response Received Date',
  'Scheduled Departure (local)',
  'Van # Destination',
  'Form Number'
]


function FiftyFivehundredUpload() {
  const fileInputRef = useRef(null);
  const [set, didSet] = useState();
  const [usedSpace, setUsedSpace] = useState(0);
  const [totalSpace, setTotalSpace] = useState(0);
  const [records, setRecords] = useState([])
  const [alert, setAlert] = useState();
  const [error, setError] = useState();

  function handleFileUpload(event) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const filedata = e.target.result;
        const workbook = XLSX.read(filedata, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const newData = XLSX.utils.sheet_to_json(worksheet,{ header: 0, defval: "" });
        // Calculate size of new data in bytes
        const newDataSize = new TextEncoder().encode(JSON.stringify(newData)).length;

        // Check for correct columns
        const missingKeys = [];
        const extraKeys = [];
        
        const hasCorrectKeys = newData.every((obj) => {
          const objKeys = Object.keys(obj);
          const missing = USPS_5500_FILE_COLUMNS.filter((key) => !objKeys.includes(key));
          missingKeys.push(...missing);
          const extra = objKeys.filter((key) => !USPS_5500_FILE_COLUMNS.includes(key));
          extraKeys.push(...extra);
          return missing.length === 0 && extra.length === 0;
        });

        if(!hasCorrectKeys){
          setError(`Uploaded file must match USPS 5500 file downloaded from https://logistics.usps.com/ \n Extra columns: ${extraKeys} \n Missing columns: ${missingKeys}`);
          didSet(false)
          return null;
        }

        // Retrieve existing data from local storage
        const storedData = localStorage.getItem(ffhkey);
        let existingDataSize = 0;
        if (storedData) {
          existingDataSize = new TextEncoder().encode(storedData).length;
        }

        // Check if the total data size exceeds the maximum storage size
        if (newDataSize + existingDataSize > maxSize) {
          setError("Local storage capacity exceeded");
          didSet(false)
          return null;
        }

        let updatedData = [];
        if (storedData) {
          const existingData = JSON.parse(storedData);
          // Update or add records from new data to existing data
          updatedData = existingData.map(existingItem => {
            const matchingNewItem = newData.find(newItem => newItem['Number'] === existingItem['Number']);
            if (matchingNewItem) {
              // Update existing record with new data
              return { ...existingItem, ...matchingNewItem };
            } else {
              // Keep existing record
              return existingItem;
            }
          });
          // Add new records from new data to existing data
          newData.forEach(newItem => {
            const matchingExistingItem = existingData.find(existingItem => existingItem['Number'] === newItem['Number']);
            if (!matchingExistingItem) {
              // Add new record
              updatedData.push(newItem);
            }
          });
        } else {
          // No existing data, use new data
          updatedData = newData;
        }

        // Store updated data in local storage
        localStorage.setItem(ffhkey, JSON.stringify(updatedData));
        setRecords(updatedData)
        setAlert('Upload successful')
        fileInputRef.current.value = "";
        didSet(false)
      };

      reader.readAsBinaryString(file);
    }


  };

  function handleClearLocalStorage() {
    localStorage.clear();
    setRecords([])
    didSet(false)
  };

  function calculateSpace() {
    const used = Object.keys(localStorage).reduce((accumulator, currentValue) => {
      return accumulator + localStorage.getItem(currentValue).length;
    }, 0);
    setUsedSpace(Math.round((used / 1024) * 1) / 1); // convert to kilobytes and round to 2 decimal places
    setTotalSpace(Math.round((maxSize / 1024) * 1) / 1);
  };

  useEffect(() => {
    if (!set) {
      calculateSpace();
      const storedData = localStorage.getItem(ffhkey);
      const data = JSON.parse(storedData)
      storedData && setRecords(data)
    }
    return () => didSet(true);
  }, [set]);

  return (
    <Card>
      <Card.Header>
        <span style={{ float: 'left' }}>
          Records: {records.length}
        </span>
        <span style={{ float: 'right' }}>
          Used space: {Math.round(usedSpace / totalSpace * 100 / 1)} %
        </span>
      </Card.Header>
      {error &&
        <Card.Body>
          <Alert variant="warning" dismissible onClick={() => setError()}>
            <pre>{error}</pre>
          </Alert>
        </Card.Body>
      }
      {alert &&
        <Card.Body>
          <Alert dismissible onClick={() => setAlert()}>
            {alert}
          </Alert>
        </Card.Body>
      }
      <Card.Body>
        <Row>
          <Col>
            <Form.Control type="file" accept=".csv, .xlsx" ref={fileInputRef} onChange={e => handleFileUpload(e)} />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Button onClick={() => handleClearLocalStorage()}>
              Clear Local Storage
            </Button>
          </Col>
        </Row>
      </Card.Body>

    </Card>
  );
}

export default FiftyFivehundredUpload;
