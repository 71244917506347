import React, { useState, useEffect } from "react";
import { Form, Button, Card, Row, Col, Badge, Alert, Spinner, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from "../../../utils/Api";
import { useAuth } from "../../../context/auth";
import { useParams } from 'react-router-dom';
import { FaCalendar, FaIdBadge, FaPaperPlane, FaPlusSquare, FaRoute, FaSignInAlt, FaSignature, FaStickyNote, FaTruck, FaUser, FaUsers, FaUsps } from "react-icons/fa";
import { utcTimestamp } from "../../../utils/DateTimeFormat.helper";
function DailyReportUpdate() {

  const initialFormState = {
    firstname: '',
    lastname: '',
    date: '',
    hcrs: '',
    schedule: '',
    personnel: '',
    equipment: '',
    extraservice: '',
    uspscommunication: ''
  };

  const { id } = useParams();
  const { user, idToken } = useAuth();
  const [alerts, setAlerts] = useState([]);

  const [load, didLoad] = useState();
  const [dailyReport, setDailyReport] = useState(initialFormState);
  const [ammendment, setAmmendment] = useState('');

  const handleUpdate = async () => {
    try {
      const utc = utcTimestamp()
      const ammendments = [{ utcTimestamp: utc, detail: ammendment }, ...dailyReport.ammendments || []]
      // console.log( {...dailyReport, ammendments: ammendments})
      await Api.patch(`/form/dailyreport/${dailyReport._id}`, { ...dailyReport, ammendments: ammendments }, idToken)
      setAlerts([...alerts, { variant: 'success', message: 'Success' }])
      setAmmendment()
      didLoad()
    } catch (error) {
      setAlerts([...alerts, { variant: 'warning', message: error }])
    }
  }

  const fetchData = async () => {
    try {
      const data = await Api.get(`/form/dailyreport/${id}`, idToken);
      setDailyReport(data);
    } catch (error) {
      setAlerts([...alerts, { variant: 'warning', message: error }])
    }
    didLoad(true)
  }

  useEffect(() => {
    if (!load) {
      fetchData()
    }
  });

  return (
    <Container fluid>
      <Card>
        <Card.Header>
          <b>Daily Report Detail</b>
        </Card.Header>

        <Card.Body>
          <Card.Title><FaPaperPlane /> Email:</Card.Title>
          <Card.Text style={{ marginLeft: '1em' }}>{dailyReport.email}</Card.Text>

          <Card.Title><FaIdBadge /> Name:</Card.Title>
          <Card.Text style={{ marginLeft: '1em' }}>{dailyReport.firstname} {dailyReport.lastname}</Card.Text>

          <Card.Title><FaCalendar /> Date:</Card.Title>
          <Card.Text style={{ marginLeft: '1em' }}>{dailyReport.date}</Card.Text>

          <Card.Title><FaSignature /> HCRs:</Card.Title>
          <Card.Text style={{ marginLeft: '1em' }}>{dailyReport.hcrs}</Card.Text>

          <Card.Title><FaRoute /> Schedule:</Card.Title>
          <Card.Text style={{ marginLeft: '1em' }}>{dailyReport.schedule}</Card.Text>

          <Card.Title><FaUsers /> Personnel:</Card.Title>
          <Card.Text style={{ marginLeft: '1em' }}>{dailyReport.personnel}</Card.Text>

          <Card.Title><FaTruck /> Equipment:</Card.Title>
          <Card.Text style={{ marginLeft: '1em' }}>{dailyReport.equipment}</Card.Text>

          <Card.Title><FaPlusSquare /> Extra Service:</Card.Title>
          <Card.Text style={{ marginLeft: '1em' }}>{dailyReport.extraservice}</Card.Text>

          <Card.Title><FaUsps /> USPS Comms:</Card.Title>
          <Card.Text style={{ marginLeft: '1em' }}>{dailyReport.uspscommunication}</Card.Text>

          <Card.Title><FaStickyNote /> Ammendments:</Card.Title>
          { ( dailyReport.ammendments && dailyReport.ammendments.length > 0)
              ? dailyReport.ammendments.map((note, i) => (<Card.Text style={{ marginLeft: '1em' }} key={i}><b>{note.utcTimestamp}</b> - {note.detail}</Card.Text>))
              : <Card.Text style={{ marginLeft: '1em' }}>None</Card.Text>
          }
        </Card.Body>
        {dailyReport.uid === user.uid &&
          <Card.Body>
            <Form.Group controlId="schedule">
              <Form.Label>Ammendment:</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Additional notes..."
                value={ammendment}
                onChange={(e) => setAmmendment(e.target.value)}
              />
            </Form.Group>
            <Button onClick={() => handleUpdate()}>Update</Button>
          </Card.Body>
        }

        <Card.Body>

        </Card.Body>
        {alerts &&
          <Card.Body>
            <Row>
              <Col>
                {alerts.map((alrt, i) => (
                  <Alert key={i} variant={alrt.variant} dismissible onClick={() => setAlerts([])}>{JSON.stringify(alrt.message)}</Alert>
                ))}
              </Col>
            </Row>
          </Card.Body>
        }
      </Card>
    </Container>
  );
}

export default DailyReportUpdate;