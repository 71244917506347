
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FaChartLine, FaClipboard,} from 'react-icons/fa';
import NavBarComponent from '../../components/NavBar.component';


const ReportNav = () => {

    const navOptions = [
        { href: "/report", icon: <FaClipboard />, text: "Reports" },
      ];

    return (
        <NavBarComponent name={'Report Nav'} navOptions={navOptions}/>
    );
};

export default ReportNav