import { Container, Card, Row, Col, Spinner, Alert } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/auth';
import DataDisplay from "../../components/DataDisplay";

function LoadTenderRequests() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [edi204, setEDI204] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const edi204s = await Api.query(`/edi204`, {'loadtenderLifeCycleStatus':'pending'}, idToken);
            setEDI204(edi204s)
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    },[set,idToken]);


    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <Row>
                        <Col sm={12} md={6}><b>Load Tender Requests</b></Col>
                    </Row>
                </Card.Header>
                {error &&
                    <Card.Body>
                        <Alert variant='warning' dismissible onClick={() => setError()}>{JSON.stringify(error)}</Alert>
                    </Card.Body>
                }
                {set ?
                    <Card.Body>
                        <DataDisplay
                            sourceName={'MCTMS-Load-Tenders-Pending'}
                            dataSource={edi204}
                            lsKey={'@mctms-loadtender-204-display-response'}
                            urlPath={'/loadtender/response/'}
                            urlKey={'_id'}
                            popKeys={['_id']}
                        />
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }
            </Card>
        </Container>
    );
}

export default LoadTenderRequests;