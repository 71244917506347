import { Container, Card, Button, Table, Row, Col, Nav, Spinner, Alert } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import React, { useState, useEffect } from 'react'
import { FaEdit, FaPlusCircle } from 'react-icons/fa'
import { useAuth } from "../../context/auth";
import DataDisplay from "../../components/DataDisplay";
import { Link } from "react-router-dom";
import AlertDisplay from "../../components/AlertDisplay";

function Timecard() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [load, didLoad] = useState();
    const [currentPeriod, setCurrentPeriod] = useState({});
    const [payperiods, setPayPeriods] = useState();
    // const [timecards, setTimecards] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const payperiods = await Api.get('/payperiods/live', idToken);
                if (!payperiods) {
                    throw new Error('No payperiods')
                }
                const cleanPayPeriods = payperiods.map(payperiod => {
                    return {
                        _id: payperiod._id,
                        'Start Date': payperiod?.periodStart,
                        'End Date': payperiod?.periodEnd,
                    }
                })
                setPayPeriods(cleanPayPeriods)
                const currentPeriod = payperiods[0];
                setCurrentPeriod(currentPeriod);
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Pay period request error' }]);
            }
            didSet(true)
        }

        // const fetchTimecards = async () => {
        //     try {
        //         const timecards = await Api.get(`/timecards/${currentPeriod.periodStart}/${currentPeriod.periodEnd}`, idToken);
        //         const cleanTimecards = timecards.map(timecard => {
        //             return {
        //                 _id: timecard._id,
        //                 'Status': timecard.status,
        //                 'Start Date': timecard?.periodStart,
        //                 'End Date': timecard?.periodEnd,
        //                 'Driver': timecard?.driverName,
        //             }
        //         })
        //         setTimecards(cleanTimecards)
        //     } catch (error) {
        //         setAlerts([...alerts, { variant: 'warning', message: 'Timecard request error' }]);
        //     }
        //     didLoad(true)
        // }

        if (!set) {
            fetchData()
        }
        // if (set && !load) {
        //     fetchTimecards();
        // }
    }, [idToken, set, load, alerts, currentPeriod,])

    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <Card.Title>
                        <Row>
                            <Col>Timecards</Col>
                            {/* <Col style={{textAlign:'right'}}>                        
                                <Card.Subtitle>{currentPeriod.periodStart}</Card.Subtitle>
                                <Card.Subtitle>{currentPeriod.periodEnd}</Card.Subtitle>
                            </Col> */}
                            {/* <Col> 
                                <Link className="float-end" to="/timecard-form"><FaPlusCircle /> Form</Link>
                            </Col> */}
                        </Row>
                    </Card.Title>
                </Card.Header>
                <AlertDisplay alertState={alertState} />
                {set ?
                    <Card.Body>
                        {payperiods ?
                            <DataDisplay
                                dataSource={payperiods}
                                lsKey={'@mctms-payperiod-display'}
                                urlPath={'/payperiod/'}
                                urlKey={'_id'}
                                popKeys={['_id']}
                            />
                            :
                            <Row>
                                <Col>
                                    No timecards.
                                </Col>
                            </Row>
                        }
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container >
    );
}

export default Timecard;