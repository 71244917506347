import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import {Container, Card, Spinner,} from "react-bootstrap";
import Api from '../../utils/Api';
import { FaPlusCircle} from 'react-icons/fa'
import { useAuth } from '../../context/auth';
import { useSettings } from '../../context/settings';
import { utcToTimezone } from '../../utils/DateTimeFormat.helper';
import DataDisplay from '../../components/DataDisplay';
import { Link } from 'react-router-dom';
import AlertDisplay from '../../components/AlertDisplay';

function Notification() {
    const { idToken } = useAuth();
    const { settings } = useSettings();
    const [set, didSet] = useState();
    const [notifications, setNotifications] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = {alerts, setAlerts}

    useEffect(() => {
        const fetchData = async () => {
            try {
                let data;
                const queryParams = new URLSearchParams();
                data = await Api.query('/notifications', queryParams, idToken);
                const cleanNotifications = data.map(notification => {
                    return {
                      _id: notification._id,
                      'Subject':notification.subject,
                      'Receipients':notification.to.length,
                      'Created': utcToTimezone(notification.createdUtcTimestamp, settings.timezone)
                    }
                  })
                setNotifications(cleanNotifications)
            } catch (error) {
                setAlerts([...alerts, {variant:'warning',message:JSON.stringify(error)}]);
            }
            didSet(true)
        }
        if(!set){
            fetchData()
        }
    }, [idToken, set, alerts, settings])

    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <Card.Title>Notification</Card.Title>
                    <Link className="float-end" href="/notification-form"><FaPlusCircle /> Form</Link>
                </Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {set ?
                    <>
                        <Card.Body>
                        {notifications.length > 0 ?
                            <>
                                <DataDisplay
                                    dataSource={notifications} 
                                    lsKey={'@mctms-notifications-display'} 
                                    urlPath={'/notification/'} 
                                    urlKey={'_id'} 
                                    popKeys={['_id']}
                                />
                            </>
                            :
                            <>
                            No data.
                            </>
                        }
                        </Card.Body>
                    </>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    )
}

export default Notification;