import { Container, Card } from "react-bootstrap"

const HCR = () => {
    return (
        <Container fluid>
            <br/>
            <Card>
                <Card.Header>
                    <Card.Title>
                        HCR - Highway Contract Routes
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    Coming soon...
                </Card.Body>
            </Card>
        </Container>
    )
}

export default HCR;