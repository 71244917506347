
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaUmbrellaBeach,FaPlaneDeparture, FaRoad, FaCarCrash } from 'react-icons/fa';
import { GoReport } from 'react-icons/go';
import {BsCashCoin} from 'react-icons/bs'
import {AiOutlineAlert} from 'react-icons/ai'
import { TbPlusMinus } from 'react-icons/tb';
import { HiOutlineTag } from 'react-icons/hi';
import NavBarComponent from '../../components/NavBar.component';
import { useRole } from '../../context/role';

const FormNav = () => {
    const { userRole, checkNavPermissions } = useRole();
    
    const navOptions = [
        { href: "/form/accident", icon: <FaCarCrash />, text: "Accident Report" },
        { href: "/form/dailyreport", icon: <GoReport />, text: "Daily Report" },
        { href: "/form/employeereimbursement", icon: <BsCashCoin />, text: "Emp. Reimbusement" },
        { href: "/form/incident", icon: <AiOutlineAlert />, text: "Incident" },
        { href: "/form/pto", icon: <FaUmbrellaBeach />, text: "PTO" },
        { href: "/form/purchaseorder", icon: <HiOutlineTag />, text: "Purchase Order" },
        { href: "/form/roadtest", icon: <FaRoad />, text: "Road Test" },
        { href: "/form/travelrequest", icon: <FaPlaneDeparture />, text: "Travel Request" },
        { href: "/form/thirdpartyvehicle", icon: <TbPlusMinus />, text: "3rd Party Vehicles" },
      ];
    const allowed = checkNavPermissions(userRole, navOptions)
    return (
        <NavBarComponent name={'Forms Menu'} navOptions={allowed}/>
    );
};


export default FormNav