import React, { useState, useEffect } from 'react'
import Api from "../../utils/Api";
import LogisticsLoading from "../../components/LogisticsLoading";
import { Button, Card, Container, Table } from 'react-bootstrap';
import AlertDisplay from '../../components/AlertDisplay';
import { FaEdit } from 'react-icons/fa';
import { useAuth } from '../../context/auth';

function LoadTenderWatch() {
    const { idToken } = useAuth()
    const [set, didSet] = useState();
    const [audit, setAudit] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    useEffect(() => {

        const fetchAudit = async () => {
            try {
                const now = new Date();
                const year = now.getFullYear();
                const month = String(now.getMonth() + 1).padStart(2, '0');
                const day = String(now.getDate()).padStart(2, '0');
                const hours = String(now.getHours()).padStart(2, '0');
                const minutes = String(now.getMinutes()).padStart(2, '0');
                const timestamp = `${year}-${month}-${day} ${hours}:${minutes}`;
                const data = await Api.get(`/edi/shipments/watch/${timestamp}`, idToken)
                setAudit(data)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured.', }])
            } finally {
                didSet(true)
            }
        }
        if (!set) {
            fetchAudit()
        }
    }, [set, alerts, idToken])

    function calculateMinuteSecond(milliseconds) {
        const minutes = Math.floor(milliseconds / 60000);
        const seconds = Math.floor((milliseconds % 60000) / 1000);
        const mm = String(minutes).padStart(2, '0');
        const ss = String(seconds).padStart(2, '0');
        return `${mm}:${ss}`;
    }

    function calculateMetersToMiles(meters) {
        const miles = meters * 0.000621371; // Convert meters to miles
        const formattedMiles = miles.toLocaleString('en-US', { maximumFractionDigits: 0 });
        return formattedMiles;
    }


    const thStyle = {
        border: ' 1px solid #ddd'
    }

    const tdStyle = {
        border: '1px solid #eee'
    }

    const tdNotTrackingStyle = {
        border: '1px solid red'
    }


    return (
        <Container fluid>
            <AlertDisplay alertState={alertState} />
            {
                set ?
                    audit ?
                        <>
                            <Button variant="primary" onClick={() => didSet(false)}>
                                Refresh
                            </Button>
                            <pre>
                                {audit.processTimeLapseMilli > 0 ? calculateMinuteSecond(audit.processTimeLapseMilli) : 0}<br />
                                {/* Stops {audit.ediStops} <br /> */}
                                Tracking {(((audit.allShipments.length - audit.assignedShipmentsNotTracking.length) / audit.allShipments.length) * 100).toFixed(1)} %  <br/>
                                Not Tracking {audit.assignedShipmentsNotTracking.length} <br />
                                Shipments {audit.allShipments.length} <br />
                                Assigned Shipments Not Tracking {JSON.stringify(audit.assignedShipmentsNotTracking,"",2)}
                            </pre>
                            <Card>
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={thStyle}>ShipmnetId</th>
                                            <th style={thStyle}>Contract</th>
                                            <th style={thStyle}>Trip</th>
                                            <th style={thStyle}>viaStopNum</th>
                                            <th style={thStyle}>viaCheckPoints</th>
                                            <th style={thStyle}>viaStopReason</th>
                                            <th style={thStyle}>viaDwell</th>
                                            <th style={thStyle}>viaArrival</th>
                                            <th style={thStyle}>viaDeparture</th>
                                            <th style={thStyle}>AA214</th>
                                            <th style={thStyle}>X3214</th>
                                            <th style={thStyle}>AF214</th>
                                            <th style={thStyle}>AG214</th>
                                            <th style={thStyle}>X1214</th>
                                            <th style={thStyle}>CD214</th>
                                            <th style={thStyle}>via214</th>
                                            <th style={thStyle}>unit</th>
                                            <th style={thStyle}>odometer</th>
                                            <th style={thStyle}>fuel</th>
                                            <th style={thStyle}>proximityGpsData</th>

                                            <th style={thStyle}>startFormattedString</th>
                                            <th style={thStyle}>startDifference</th>
                                            <th style={thStyle}>endFormattedString</th>
                                            <th style={thStyle}>endDifference</th>
                                            <th style={thStyle}>gpsTimelapseInMinute</th>
                                            <th style={thStyle}>viaProximityDwellDiff</th>

                                            <th style={thStyle}>inProximity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {audit.tableData.map((row, i) => (
                                            <tr key={i} >
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>
                                                    <FaEdit /> {row.ShipmnetId}
                                                </td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.Contract}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.Trip}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.viaStopNum}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.viaCheckPoints}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.viaStopReason}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.viaDwell}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.viaArrival}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.viaDeparture}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.AA214}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.X3214}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.AF214}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.AG214}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.X1214}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.CD214}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.via214}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.unit}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{calculateMetersToMiles(row.odometer)}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.fuel} %</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.proximityGpsData}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.startFormattedString}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.startDifference}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.endFormattedString}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.gpsTimelapseInMinute}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.proximityGpsData}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.viaProximityDwellDiff}</td>
                                                <td style={audit.assignedShipmentsNotTracking.includes(row.ShipmnetId) ? tdNotTrackingStyle : tdStyle}>{row.inProximity ? '📍' : ''}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Card>

                        </>

                        :
                        <>
                            <Button variant="primary" onClick={() => didSet(false)}>
                                Refresh
                            </Button>
                        </>
                    :
                    <LogisticsLoading message={'Loading Live Operations... (this can take some time)'} />
            }
        </Container>
    )
}


export default LoadTenderWatch;