import { Container, Card, Spinner, Row, Col, Badge, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import React, { useState, useEffect } from 'react'
import { FaPlusCircle, } from 'react-icons/fa'
import { useAuth } from '../../context/auth';
import DataDisplay from "../../components/DataDisplay";
import { Link } from "react-router-dom";
import AlertDisplay from "../../components/AlertDisplay";

function Location() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [locations, setLocations] = useState();
    const [alerts, setAlerts] = useState([]);
    const [query, setQuery] = useState({
        isActive: true
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const queryParams = new URLSearchParams(query);
                const data = await Api.query('/locations', queryParams, idToken);
                if (!data || !Array.isArray(data)) {
                    throw new Error("Received invalid data from server.");
                }
                const cleanLocations = data.map(location => {
                    return {
                        _id: location._id,
                        'Name': location.name,
                        'Type': location.type,
                        'Address': `${location.address1} ${location.address2} ${location.city} ${location.state} ${location.zipcode}`,
                        'Provider': location.provider,
                        'Active': location.isActive ? '✅' : '❌',
                    }
                })

                setLocations(cleanLocations)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }]);
            }
            didSet(true)
        }

        fetchData()

    }, [set, idToken, alerts, query])


    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <Card.Title>
                        <Row>
                            <Col>Location </Col>
                            <Col style={{ textAlign: 'center' }}>
                                <Badge
                                    bg={query.isActive ? 'success' : 'danger'}
                                    onClick={() => { setQuery({ ...query, isActive: !query.isActive }); didSet(false) }}
                                >
                                    {query.isActive ? 'Active' : 'Inactive'}
                                </Badge>
                            </Col>
                            <Col>
                                <Link className="float-end" to="/plan/location-form"><FaPlusCircle /> Form</Link>
                            </Col>
                        </Row>
                    </Card.Title>
                </Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={{ alerts, setAlerts }} />
                }
                {set ?
                    <Card.Body>
                        {locations.length > 0 ?
                            <>
                                <DataDisplay
                                    dataSource={locations}
                                    lsKey={'@mctms-locations-display'}
                                    urlPath={'/plan/location/'}
                                    urlKey={'_id'}
                                    popKeys={['_id']}
                                />
                            </>
                            :
                            <>
                                No data.
                            </>
                        }
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    );
}

export default Location;