import React from "react";
import { Container, Card, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useRole } from '../../context/role';
import { FaCarCrash, FaPlusCircle, FaUmbrellaBeach } from "react-icons/fa";
import { GoReport } from "react-icons/go";
import { BsCashCoin } from "react-icons/bs";
import { TbPlusMinus } from "react-icons/tb";
import { Link } from "react-router-dom";


function Forms() {

    const { userRole, checkNavPermissions } = useRole();

    const contentOptions = [
        {
            href: "/form/accident-form",
            header: '',
            elements:
                <>
                   <Link to="/form/accident-form"><FaCarCrash /> Accident Report</Link>
                </>
        },
        {
            href: "/form/dailyreport-form",
            header: '',
            elements:
                <>
                   <Link to="/form/dailyreport-form"><GoReport /> Daily Report</Link>
                </>
        },
        {
            href: "/form/employeereimbursement-form",
            header: '',
            elements:
                <>
                    <Link to="/form/employeereimbursement-form"><BsCashCoin /> Employee Reimbursement</Link>
                </>
        },
        {
            href: "/form/incident-form",
            header: '',
            elements:
                <>
                    <Link to="/form/incident-form"><FaPlusCircle /> Incident</Link>
                </>
        },
        {
            href: "/form/pto",
            header: '',
            elements:
                <>
                    <Link to="/form/pto-form"><FaUmbrellaBeach /> PTO </Link>
                </>
        },
        {
            href: "/form/purchaseorder-form",
            header: '',
            elements:
                <>
                    <Link to="/form/purchaseorder-form"><FaPlusCircle /> Purchase Order</Link>
                </>
        },
        {
            href: "/form/travelrequest-form",
            header: '',
            elements:
                <>
                    <Link to="/form/travelrequest-form"><FaPlusCircle /> Travel Request</Link>
                </>
        },
        {
            href: "/form/thirdpartyvehicle-form",
            header: '',
            elements:
                <>
                    <Link to="/form/thirdpartyvehicle-form"><TbPlusMinus /> Third Party Vehicle Checkout</Link>
                </>
        },
    ];
    const allowedContent = checkNavPermissions(userRole, contentOptions)

    return (
        <Container fluid>
            <Card>
                <Card.Header><b>Forms</b></Card.Header>
                {allowedContent.map((content, i) => (
                    <Card.Body key={i}>
                        <Card.Title>{content.header}</Card.Title>
                        <Card.Text>
                            {content.elements}
                        </Card.Text>
                    </Card.Body>
                ))}
            </Card>
            <br />
        </Container>
    );
}

export default Forms;