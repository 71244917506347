import React, { useState, useEffect } from 'react'
import { Container, Card, Button, Row, Col, Spinner, Alert, ListGroupItem, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import { FaPlusCircle } from 'react-icons/fa'
import { useAuth } from '../../context/auth';
import approutes from '../../context/approutes'
import DataDisplay from '../../components/DataDisplay';
import { Link } from 'react-router-dom';

function mapArraysByKey(array1, array2, key) {
    const result = [];

    array1.forEach((obj1) => {
        const matchedObj2 = array2.find((obj2) => obj2[key] === obj1[key]);

        if (matchedObj2) {
            result.push([obj1, matchedObj2]);
        } else {
            result.push([obj1, undefined]);
        }
    });

    array2.forEach((obj2) => {
        const matchedObj1 = array1.find((obj1) => obj1[key] === obj2[key]);

        if (!matchedObj1) {
            result.push([undefined, obj2]);
        }
    });

    return result;
}

function Access() {

    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <Card.Title>Access</Card.Title>
                </Card.Header>
                <Card.Body>
                    <ListGroupItem>
                        Roles - A credential that allows a user to carry out process within the application. 
                    </ListGroupItem>
                    <ListGroupItem>
                        Groups - A group of users for notifications.
                    </ListGroupItem>
                    <ListGroupItem>
                        Profiles - User that current have roles.
                    </ListGroupItem>
                    <ListGroupItem>
                        New Users - See List of New Users and assign them roles.
                    </ListGroupItem>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default Access;