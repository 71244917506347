import { Container, Card, Form, Row, Col, Button, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState,} from 'react';
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import AlertDisplay from '../../components/AlertDisplay'

const date = new Date();
const defaultStart = date.toISOString().split('T')[0];
date.setDate(date.getDate() + 1);
const tomorrowDate = date.toISOString().split('T')[0];
date.setDate(date.getDate() + 14);
const tomorrowPlus14 = date.toISOString().split('T')[0];

function DispatchBuilder() {
    const { idToken } = useAuth();
    const [buildstart, setBuildStart] = useState(tomorrowDate);
    const [buildend, setBuildEnd] = useState(tomorrowPlus14);
    const [alerts, setAlerts] = useState([]);
    const alertState = {alerts, setAlerts};

    const buildDispatch = async () => {
        if (buildstart <= buildend) {
            setAlerts([...alerts, { variant: 'success', message: "..." }])
            try{
                await Api.get(`/dispatch/build/${buildstart}/${buildend}`, idToken)
                setAlerts([...alerts, { variant: 'success', message: "Dispatch built!" }])
            } catch(error) {
                setAlerts([...alerts, { variant: 'warning', message: error.error }])
            }
        } else {
            setAlerts([...alerts, { variant: 'warning', message: "Build start date must be greater than today and build end must be greater than or equal to build start." }])
        }
    }

    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <Row className="justify-content-space-evenly align-items-center">
                        <Col> <b>Dispatch Builder</b> </Col>
                    </Row>
                </Card.Header>
                <AlertDisplay alertState={alertState}/>
                <>
                    <Card.Body>
                        <Card.Subtitle>
                            Build Dispatch
                        </Card.Subtitle>
                        <br />
                        <Row>
                            <Col sm={12} md={6} lg={4}>
                                Start Date : <Form.Control type="date" defaultValue={tomorrowDate} onChange={(e) => setBuildStart(e.target.value)} />
                            </Col>
                            <Col sm={12} md={6} lg={4}>
                                End Date : <Form.Control type="date" defaultValue={tomorrowPlus14} onChange={(e) => setBuildEnd(e.target.value)} />
                            </Col>
                            <Col sm={12} md={6} lg={4}>
                                <br />
                                <Button onClick={() => buildDispatch()}>Build</Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </>
            </Card>
        </Container>
    );
}

export default DispatchBuilder;
