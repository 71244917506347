
import React from "react";
import { Container, Card } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
// import Api from '../../utils/Api';
// import { useParams } from 'react-router-dom';

function TimecardInfo() {
    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header>Timecard Info</Card.Header>
                <Card.Body>
                    <Card.Text>
                        Timecard Information
                    </Card.Text>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default TimecardInfo;