import React, { useState } from "react";
import { Container, Table } from "react-bootstrap"
import { FaInfoCircle } from "react-icons/fa";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";

const EDI990Component = ({ ...props }) => {

    const edi990s = props.edi990s;

    const [show990, setShow990] = useState();

    if (!edi990s || edi990s.length === 0) {
        return (
            <Container>
                <b>No EDI 990</b>
            </Container>
        )
    }

    return (
        <Table striped bordered hover responsive>
            <thead>
                <tr>
                    <th colSpan={7}>EDI990</th>
                </tr>
                <tr>
                    <th> <FaInfoCircle /> </th>
                    <th>ShipmentId</th>
                    <th>InternalTrackingNum</th>
                    <th>Customer</th>
                    <th>CustomerISA</th>
                    <th>Response</th>
                    <th>Timestamp TZ</th>
                </tr>
            </thead>
            <tbody>
                {edi990s.map((edi990, i) => (
                    <React.Fragment key={`990-fragment-${i}`}>
                        <tr key={`990-row-${i}-a`}>
                            <td onClick={() => setShow990(show990 === i ? '' : i)}>{show990 === i ? <AiFillCaretDown/>: <AiFillCaretRight/> }</td>
                            <td>{edi990.ShipmentId}</td>
                            <td>{edi990.InternalTrackingNum}</td>
                            <td>{edi990.Customer}</td>
                            <td>{edi990.CustomerISA}</td>
                            <td>{edi990.Response}</td>
                            <td>{edi990.Timestamp} {edi990.TimeZone}</td>
                        </tr>
                        {show990 === i &&
                            <tr key={`990-row-${i}-b`}>
                                <td colSpan={7}>
                                    <pre>
                                        {JSON.stringify(edi990, "", 2)}
                                    </pre>
                                </td>
                            </tr>
                        }
                    </React.Fragment>
                ))}
            </tbody>
        </Table>
    )
}

export default EDI990Component;