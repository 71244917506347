import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DataDisplay from "../../components/DataDisplay";

const ffhkey = 'fiftyfivehundred'

const USPS_5500_FILE_COLUMNS = [
    'Trip Date',
    'Arrival Lateness (min)',
    'Actual Departure (local)',
    'Excusable or Chargeable',
    'Driver\'s Number',
    'Departure Lateness (min)',
    'undefined',
    'Scheduled Arrival (local)',
    'Form Status',
    'Late Reason',
    'Driver\'s Name',
    'Short Description',
    'Number',
    'Sys ID',
    'Supplier Response Selected',
    'Actual Arrival (local)',
    'Point of Irregularity',
    'Route Number',
    'Trip Number',
    'Irregularities',
    'Irregularity Date',
    'Has Breached',
    'Date Sent to Supplier',
    'Due Date',
    'Supplier Response Received Date',
    'Scheduled Departure (local)',
    'Van # Destination',
    'Form Number'
]


function FiftyFiveHundredTable() {
    const [set, didSet] = useState();
    const [records, setRecords] = useState()

    useEffect(() => {
        if (!set) {
            const storedData = localStorage.getItem(ffhkey);
            const data = JSON.parse(storedData)
            
            if (data) {
                setRecords(data);
            }
        }
        return () => didSet(true)
    }, [set, records, setRecords])

    return (
        <>
            <Card>
                <Card.Header>
                    5500 Table
                </Card.Header>
                {records ?
                    <DataDisplay 
                        dataSource={records}
                        lsKey={'@mctms-access-ffh-display'}
                        urlPath={'#'}
                        urlKey={''}
                        popKeys={[]}
                    />
                    :
                    <Card.Body>
                        No data. Go to <Card.Link href="/upload">Upload</Card.Link> and add USPS 5500s.
                    </Card.Body>
                }
            </Card>
        </>
    );
}

export default FiftyFiveHundredTable;
