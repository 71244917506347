import React, { useState, useEffect } from "react";
import { Form, Button, Card, Row, Col, Badge, Alert, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from "../../../utils/Api";
import { useAuth } from "../../../context/auth";
import { useParams } from 'react-router-dom';
import logo from '../../../assets/logo.png'

const initialFormState = {
    firstname: '',
    lastname: '',
    dateofrequest: '',
    email: '',
    type: '',
    position: '',
    region: '',
    location: '',
    firstdayoff: '',
    lastdayoff: '',
    returndate: '',
    daysrequested: 0,
    hoursrequested: '',
    notes: '',
    status: 'draft',
    managerfirstname: '',
    managerlastname: '',
    manageremail: '',
    responsedate: '',
    managernotes: '',
    response: '',
    canceledbyfirstname: '',
    canceledbylastname: '',
    canceledbyemail: '',
    cancelledDate: '',
    cancelnotes: ''
}

const PTORequestUpdate = () => {
    const { id } = useParams();
    const { user, idToken } = useAuth();
    const [alerts, setAlerts] = useState([]);
    const [load, didLoad] = useState();
    const [pto, setPTO] = useState(initialFormState);
    const [cancelrequest, shouldCancelRequest] = useState();

    const getStatusText = () => {
        return 'Status: ' + pto.status;
    };

    const getStatusVariant = () => {
        if (pto.status === 'draft' ? true : false) {
            return 'secondary';
        } else if (pto.status === 'pending') {
            return 'warning';
        } else if (pto.status === 'approved') {
            return 'success';
        } else if (pto.status === 'denied') {
            return 'danger';
        } else if (pto.status === 'cancelled') {
            return 'dark';
        }
        return 'light';
    };

    const handleReset = () => {
        didLoad(false)
    };

    const handleCancel = async () => {
        try {

            const cancelFields = ['canceledbyfirstname', 'canceledbylastname', 'cancelledDate', 'cancelnotes']
            const missingFields = cancelFields.filter((field) => !pto[field]);

            if (missingFields.length > 0) {
                const missingFieldNames = missingFields.map((field) => field.toUpperCase());
                const missingFieldsMessage = missingFieldNames.join(', ');
                setAlerts([...alerts, { variant: 'warning', message: `Please fill in the required fields: ${missingFieldsMessage}` }]);
                return
            }

            const cancelData = {
                canceledbyfirstname: pto.canceledbyfirstname,
                canceledbylastname: pto.canceledbylastname,
                cancelledDate: pto.cancelledDate,
                cancelnotes: pto.cancelnotes,
                status: 'cancelled',
                canceledbyemail: user.email
            }

            const result = await Api.patch(`/form/pto/${id}`, cancelData, idToken);
            setAlerts([...alerts, { variant: 'success', message: `${JSON.stringify(result)}` }]);
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: `${JSON.stringify(error)}` }]);
        }
        didLoad(false)
    };

    const handleUpdate = async () => {
        try {

            const result = await Api.patch(`/form/pto/${id}`, {...pto, status:pto.response}, idToken)

            setAlerts([
                ...alerts,
                {
                    variant: "success",
                    message: `Result: ${JSON.stringify(result)}`,
                },
            ]);
            didLoad()
        } catch (error) {
            setAlerts([
                ...alerts,
                {
                    variant: "warning",
                    message: `Error: ${JSON.stringify(error)}`,
                },
            ]);
        }

    }

    const fetchData = async () => {
        try {

            const data = await Api.get(`/form/pto/${id}`, idToken);
            setPTO(data)

            if (data.status === 'pending') {
                const profile = await Api.get(`/access/profile/uid/${user.uid}`, idToken);
                setPTO({ ...data, managerfirstname: profile.firstname, managerlastname: profile.lastname, manageremail: user.email });
            }

            if (['approved', 'denied'].includes(data.status)) {
                const profile = await Api.get(`/access/profile/uid/${user.uid}`, idToken);
                setPTO({ ...data, canceledbyfirstname: profile.firstname, canceledbylastname: profile.lastname, canceledbyemail: user.email });
            }

            data.status === 'cancelled' && shouldCancelRequest(true);

        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: JSON.stringify(error) }]);
        }
        didLoad(true)
    }

    useEffect(() => {
        if (!load) {
            fetchData()
        }
    });

    return (
        <Card>
            <Card.Header>
                <div className="img-container d-flex justify-content-center align-items-center">
                    <Card.Img
                        src={logo}
                        alt="logo"
                        style={{ width: "150px", height: "auto", }}
                    />
                </div>
                <Card.Title className="text-center">
                    PTO Update
                </Card.Title>
            </Card.Header>

            {!load ?
                <Card.Body>
                    <Spinner />
                </Card.Body>
                :
                <>
                    <Card.Body>
                        <Card.Text style={{ fontWeight: 'bold', fontSize: '12px' }}>
                            This form should not be used to request leave under the Family and Medical Leave Act (FMLA) or to request leave as an accommodation under the Americans with Disabilities Act (ADA). Employees should consult with HR to request leave under the FMLA or ADA.
                        </Card.Text>
                        <Card.Text style={{ fontStyle: 'italic', fontSize: '12px' }}>
                            Please submit this form for approval at least 4 weeks in advance of your preferred vaction dates. If you have questions in regards to your PTO please contact: Tonda @ 402-890-2244
                        </Card.Text>
                    </Card.Body>
                    <Card.Body>
                        <Card.Header>
                            <Card.Title>Request
                                <span style={{ float: 'right' }}><Badge pill bg={getStatusVariant()}>{getStatusText()}</Badge></span>
                            </Card.Title>
                        </Card.Header>
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                <Form.Group>
                                    <Form.Label className='form-label'>First Name</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={pto.firstname}
                                        disabled
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                <Form.Group>
                                    <Form.Label className='form-label'>Last Name</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={pto.lastname}
                                        disabled
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                <Form.Group>
                                    <Form.Label className='form-label'>Email:</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={pto.email}
                                        disabled
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                <Form.Group>
                                    <Form.Label className='form-label'>Request Date:</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={pto.dateofrequest}
                                        disabled
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row >
                            <Col>
                                Type
                                <Form.Control
                                    value={pto.type}
                                    disabled
                                    readOnly
                                />
                            </Col>
                            <Col>
                                Position
                                <Form.Control
                                    value={pto.position}
                                    disabled
                                    readOnly
                                />
                            </Col>
                            <Col>
                                {/* Regions */}
                                {pto.position === 'management' && (
                                    <Col>
                                        Region
                                        <Form.Control
                                            value={pto.region}
                                            disabled
                                            readOnly
                                        />
                                    </Col>
                                )}
                                {/* Locations */}
                                {pto.position === 'driver' && (
                                    <Col>
                                        Location
                                        <Form.Control
                                            value={pto.location}
                                            disabled
                                            readOnly
                                        />
                                    </Col>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                First Day Off
                                <Form.Control
                                    type='date'
                                    value={pto.firstdayoff}
                                    disabled
                                    readOnly
                                />
                            </Col>
                            <Col>
                                Last Day Off
                                <Form.Control
                                    type='date'
                                    value={pto.lastdayoff}
                                    disabled
                                    readOnly
                                />
                            </Col>
                            <Col>
                                Return to Work
                                <Form.Control
                                    type='date'
                                    value={pto.returndate}
                                    disabled
                                    readOnly
                                />
                            </Col>
                            <Col>
                                Days Requested:
                                <Form.Control
                                    type='number'
                                    value={pto.daysrequested}
                                    disabled
                                    readOnly
                                />
                            </Col>
                            <Col>
                                Hours Requested:
                                <Form.Control
                                    type='number'
                                    value={pto.hoursrequested}
                                    disabled
                                    readOnly
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Notes:
                                <Form.Control
                                    as="textarea"
                                    rows={2}
                                    style={{ resize: "vertical" }}
                                    value={pto.notes}
                                    disabled
                                    readOnly
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                    {/* Managers */}
                    <Card.Body>
                        <Card.Header>
                            <Card.Title>Response</Card.Title>
                        </Card.Header>
                        <Row>
                            <Col sm={12} md={6} lg={3} >
                                Manager Response
                                <Form.Check
                                    label='Approved'
                                    type='radio'
                                    name='response'
                                    value='approved'
                                    checked={pto.response === 'approved'}
                                    onChange={(e) => setPTO({ ...pto, response: e.target.value})}
                                    readOnly={['approved', 'denied', 'cancelled'].includes(pto.status)}
                                    disabled={['approved', 'denied', 'cancelled'].includes(pto.status)}
                                />
                                <Form.Check
                                    label='Denied'
                                    type='radio'
                                    name='response'
                                    value='denied'
                                    checked={pto.response === 'denied'}
                                    onChange={(e) => setPTO({ ...pto, response: e.target.value})}
                                    readOnly={['approved', 'denied', 'cancelled'].includes(pto.status)}
                                    disabled={['approved', 'denied', 'cancelled'].includes(pto.status)}
                                />
                            </Col>
                            <Col sm={12} md={6} lg={3} >
                                Manager First Name
                                <Form.Control
                                    type="text"
                                    value={pto.managerfirstname}
                                    readOnly={['approved', 'denied', 'cancelled'].includes(pto.status)}
                                    disabled={['approved', 'denied', 'cancelled'].includes(pto.status)}
                                />
                            </Col>
                            <Col sm={12} md={6} lg={3} >
                                Manager Last Name
                                <Form.Control
                                    type="text"
                                    value={pto.managerlastname}
                                    readOnly={['approved', 'denied', 'cancelled'].includes(pto.status)}
                                    disabled={['approved', 'denied', 'cancelled'].includes(pto.status)}
                                />
                            </Col>
                            <Col sm={12} md={6} lg={3} >
                                Response Date
                                <Form.Control
                                    type="date"
                                    value={pto.responsedate}
                                    onChange={(e) => setPTO({ ...pto, responsedate: e.target.value })}
                                    readOnly={['approved', 'denied', 'cancelled'].includes(pto.status)}
                                    disabled={['approved', 'denied', 'cancelled'].includes(pto.status)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Manager's Notes:</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={2}
                                        style={{ resize: "vertical" }}
                                        value={pto.managernotes} onChange={(e) => setPTO({ ...pto, managernotes: e.target.value })}
                                        readOnly={['approved', 'denied', 'cancelled'].includes(pto.status)}
                                        disabled={['approved', 'denied', 'cancelled'].includes(pto.status)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                    {/* Canceller Section */}
                    {(cancelrequest) && (
                        <Card.Body>
                            <Card.Header>
                                <Card.Title>Cancel</Card.Title>
                            </Card.Header>
                            <Row>
                                <Col sm={12} md={6} lg={3}>
                                    <Form.Group>
                                        <Form.Label>Cancelled By Name</Form.Label>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder='First Name'
                                                    value={pto.canceledbyfirstname}
                                                    onChange={(e) => setPTO({ ...pto, canceledbyfirstname: e.target.value })}
                                                    readOnly={['cancelled'].includes(pto.status)}
                                                    disabled={['cancelled'].includes(pto.status)}
                                                />
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder='Last Name'
                                                    value={pto.canceledbylastname}
                                                    onChange={(e) => setPTO({ ...pto, canceledbylastname: e.target.value })}
                                                    readOnly={['cancelled'].includes(pto.status)}
                                                    disabled={['cancelled'].includes(pto.status)}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={6} lg={3}>
                                    <Form.Group>
                                        <Form.Label>Cancelled Date</Form.Label>
                                        <Form.Control
                                            type='date'
                                            name='cancelledDate'
                                            value={pto.cancelledDate}
                                            onChange={(e) => setPTO({ ...pto, cancelledDate: e.target.value })}
                                            readOnly={['cancelled'].includes(pto.status)}
                                            disabled={['cancelled'].includes(pto.status)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Cancellation Notes:
                                    <Form.Control
                                        as="textarea"
                                        rows={2}
                                        style={{ resize: "vertical" }}
                                        value={pto.cancelnotes}
                                        onChange={(e) => setPTO({ ...pto, cancelnotes: e.target.value })}
                                        placeholder='must provide a note to confirm cancellation'
                                        readOnly={['cancelled'].includes(pto.status)}
                                        disabled={['cancelled'].includes(pto.status)}
                                    />
                                </Col>
                            </Row>
                            {pto.status !== 'cancelled' &&
                                <Row>
                                    <Col>
                                        <Button variant='primary' type='button' onClick={handleCancel} className="m-3">
                                            Confirm Cancellation
                                        </Button>
                                        <Button variant='secondary' type='button' onClick={() => shouldCancelRequest(cancelrequest ? false : true)} className="m-3">
                                            Go Back
                                        </Button>
                                    </Col>
                                </Row>
                            }
                        </Card.Body>
                    )}
                    {/* Actions */}
                    <Card.Body>
                        <Row>
                            <Col ms={12}>
                                {!['approved', 'denied', 'cancelled'].includes(pto.status) &&
                                    <Button variant='primary' type='button' onClick={handleUpdate} className="m-3">
                                        Respond to Request
                                    </Button>
                                }
                                {!['approved', 'denied', 'cancelled'].includes(pto.status) &&
                                    <Button variant='danger' type='button' onClick={handleReset} className="m-3">
                                        Reset
                                    </Button>
                                }
                                {!['cancelled'].includes(pto.status) &&
                                    <Button variant='warning' type='button' style={{ float: 'right' }} onClick={() => shouldCancelRequest(true)} className="m-3">
                                        Cancel Request
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </Card.Body>
                </>
            }
            {
                alerts.length > 0 &&
                <Card.Body>
                    <Row>
                        <Col>
                            {alerts.map((alrt, i) => (
                                <Alert key={i} variant={alrt.variant} dismissible onClick={() =>
                                    setAlerts(alerts.filter((_, index) => index !== i))
                                }>{JSON.stringify(alrt.message)}</Alert>
                            ))}
                        </Col>
                    </Row>
                </Card.Body>
            }
        </Card >
    );
};


export default PTORequestUpdate;