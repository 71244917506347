import { Badge } from "react-bootstrap";

const formatNameBadge = (driver) => {
    return driver.lastname && driver.firstname
        ? <Badge bg={'success'}>{driver.firstname} {driver.lastname}</Badge>
        : <Badge bg={driver.name.toLowerCase() === 'open' ? 'warning' : 'secondary'}>{driver.name}</Badge>;
};


export {
    formatNameBadge
};