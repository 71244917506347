
import { Container, Card, Row, Col, Form, Button, Spinner, Table, Tooltip, OverlayTrigger, Alert } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import Api from "../../utils/Api";
import { useParams } from 'react-router-dom';
import { FaTrash, FaInfoCircle } from "react-icons/fa";
import { useAuth } from '../../context/auth';
import AlertDisplay from "../../components/AlertDisplay";

function ContractUpdate() {
    const { idToken } = useAuth();

    const { id } = useParams();
    const [set, didSet] = useState();
    const [contract, setContract] = useState({});
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [assignments, setAssignments] = useState([]);
    const [payrates, setPayrates] = useState([]);
    const [domiciles, setDomiciles] = useState();

    async function handleUpdate() {
        try {
            await Api.patch(`/contract/${id}`, contract, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully updated contract.', }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Contract did NOT update.', }])
        }
    }

    function setPayRate(i, k, v) {
        payrates[i][k] = v
        setContract({ ...contract, payrates: [...payrates] })
    }

    function removePayRate(index) {
        const filtered = payrates.filter((_, i) => {
            return i !== index
        })
        setPayrates([...filtered])
        setContract({ ...contract, payrates: [...filtered] })
    }

    function setContractDomicile(domicile) {
        const formDomiciles = contract?.domiciles || []
        formDomiciles.push(domicile)
        setContract({ ...contract, domiciles: formDomiciles })
    }

    function removeDomicile(index) {
        const filteredDomiciles = contract.domiciles.filter((_, i) => {
            return i !== index
        })
        setContract({ ...contract, domiciles: filteredDomiciles })
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const contract = await Api.get(`/contract/${id}`, idToken)
                setContract(contract)
                contract.payrates && setPayrates(contract.payrates)
                const assignments = await Api.get('/assignments/live', idToken)
                setAssignments(assignments)
                const domiciles = await Api.get('/locations/live/Domicile', idToken)
                setDomiciles(domiciles)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error', }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [set, idToken, alerts, id])

    const RequiredAlert = ({message}) => {
        return (
            <Alert variant={"warning"} style={{fontSize:'.9em',padding:'0.1em'}}>
                <div>
                    {String(message)}
                </div>
            </Alert>
        )
    }

    return (
        <Container fluid>
            <Card>
                <Card.Header><b>Contract Update</b></Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {set ?
                    <Card.Body>
                        <Row>
                            <Col>
                                ID : {contract._id}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm={6} md={4} lg={3}>
                                Name/Number
                                <Form.Control type="text" value={contract.name} onChange={e => e.target.value.trim('') !== '' && setContract({ ...contract, name: e.target.value })} />
                                {contract.name.trim('') === '' &&
                                    <RequiredAlert message={'Required'}/>
                                }
                            </Col>
                            <Col sm={6} md={4} lg={3}>
                                Assignment
                                <Form.Select                                 
                                    value={JSON.stringify(contract.assignment)}
                                    onChange={e => e.target.value !== '' && setContract({ ...contract, assignment: JSON.parse(e.target.value) })}>
                                    <option value="">Select</option>
                                    {assignments && assignments.map(assignment => (
                                        <option key={assignment._id} value={JSON.stringify(assignment)}>{assignment.name}</option>
                                    ))}
                                </Form.Select>
                                {!JSON.stringify(assignments).includes(JSON.stringify(contract.assignment)) && 
                                    <>
                                        {
                                            contract.assignment ? <RequiredAlert message={'Using expired assignment : '+JSON.stringify(contract.assignment.name)+''}/>
                                            : <RequiredAlert message={'Required'}/>
                                        }
                                    </>
                                }
                            </Col>
                            <Col sm={6} md={4} lg={3}>
                                Effective Date
                                <Form.Control type="date" value={contract.effective} onChange={e => e.target.value !== '' && setContract({ ...contract, effective: e.target.value })} />
                                {contract.effective === '' && 
                                    <RequiredAlert message={'Required'}/>
                                }
                            </Col>
                            <Col sm={6} md={4} lg={3}>
                                Last Day of Operation
                                <Form.Control type="date" value={contract.lastday} onChange={e => setContract({ ...contract, lastday: e.target.value })} />
                                {contract.lastday === '' && 
                                    <RequiredAlert message={'Required'}/>
                                }
                            </Col>
                        </Row>
                        <br />
                        <Card.Header>
                            <Row>
                                <Col sm={12} md={6}>
                                    <Card.Title>Domicile Locations</Card.Title>
                                </Col>
                                <Col sm={12} md={6}>
                                    <Form.Select value={''} aria-label="Default select"
                                        onChange={e => e.target.value !== "" && setContractDomicile(JSON.parse(e.target.value))}>
                                        <option value="">Select</option>
                                        {domiciles && domiciles.map(domicile => (
                                            <option key={domicile._id} value={JSON.stringify(domicile)}>{domicile.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                {(contract.domiciles && contract.domiciles.length > 0) ? contract.domiciles.map((domicile, i) => (
                                    <Col sm={12} md={6} lg={3}>
                                        <Card key={i}>
                                            <Card.Header>
                                                <Row>
                                                    <Col>{domicile.name}</Col>
                                                    <Col>
                                                        <Button style={{ float: 'right' }} variant="warning" onClick={() => removeDomicile(i)}>
                                                            <FaTrash />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                {domicile.address1} {domicile.address2} <br />
                                                {domicile.city} {domicile.state} {domicile.zipcode}
                                            </Card.Body>
                                        </Card>
                                        <br/>
                                    </Col>
                                )) :
                                    <>
                                        <>Add domiciles.</>
                                    </>
                                }
                            </Row>
                        </Card.Body>
                        <br />
                        <Card.Header>Pay Rates</Card.Header>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Class</th>
                                    <th>Type</th>
                                    <th>Rule</th>
                                    <th>Amount</th>
                                    <th>Effective Date</th>
                                    <th style={{ textAlign: 'center' }}>
                                        <Button variant="primary" onClick={() => setPayrates([...payrates, {}])}>+</Button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {(payrates.length > 0) ? payrates.map((payrate, i) => (
                                    <tr>
                                        <td>
                                            <Form.Select value={payrate.class} onChange={(e) => setPayRate(i, 'class', e.target.value)}>
                                                <option value="">Select</option>
                                                <option>Class A</option>
                                                <option>Class B</option>
                                                <option>Class C</option>
                                            </Form.Select>
                                        </td>
                                        <td>
                                            <Form.Select value={payrate.type} onChange={(e) => setPayRate(i, 'type', e.target.value)}>
                                                <option value="">Select</option>
                                                <option>Wage Determinination</option>
                                                <option>Health and Welfare</option>
                                                <option>Incentive</option>
                                                <option>Bonus</option>
                                            </Form.Select>
                                        </td>
                                        <td>
                                            <Form.Select value={payrate.rule} onChange={(e) => setPayRate(i, 'rule', e.target.value)}>
                                                <option value="">Select</option>
                                                <option>All Hours</option>
                                                <option>First 40 Hours</option>
                                                <option>Over 40 Hours</option>
                                            </Form.Select>
                                        </td>
                                        <td><Form.Control type="number" value={payrate.amount} step=".01" onChange={(e) => setPayRate(i, 'amount', e.target.value)} /></td>
                                        <td><Form.Control type="date" value={payrate.effective} onChange={(e) => setPayRate(i, 'effective', e.target.value)} /></td>
                                        <td style={{ textAlign: 'center' }}>
                                            <Button variant="warning" onClick={() => removePayRate(i)}>
                                                <FaTrash />
                                            </Button>
                                        </td>
                                    </tr>
                                )) :
                                    <tr>
                                        <td colSpan={5}>Add a rate.</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>

                        <Row>
                            <Col sm={6}>
                                <Button variant="primary" onClick={() => handleUpdate()}>Update</Button>
                            </Col>
                            <Col className="d-flex justify-content-end align-items-center">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            Active - determines whether or not the record should be
                                            used by the software application.
                                        </Tooltip>
                                    }
                                >
                                    <div>
                                        Active <FaInfoCircle /> : <Button variant={contract.isActive ? 'success' : 'danger'} onClick={() => { setContract({ ...contract, isActive: (contract.isActive ? false : true) }) }}>{contract.isActive ? 'True' : 'False'}</Button>
                                    </div>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }
            </Card>
        </Container>
    );
}

export default ContractUpdate;