import { Container, Card, Row, Col, Spinner, Alert, Button, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/auth';
import { useSettings } from '../../context/settings'
import DataDisplay from "../../components/DataDisplay";
import { FaChartPie, FaPrint, FaSync, FaTable } from "react-icons/fa";
import { MdCircle, MdHideSource, MdSource } from "react-icons/md";
import { findUSPSNASSCode, findUSPSNASSCodeState, formatUSPSDateTime } from "../../context/uspsdata";
import { adjustStringLength } from "../../utils/String.helper";
import AlertDisplay from "../../components/AlertDisplay";
import { formatDateStrToMMDDYYHHMM } from "../../utils/DateTimeFormat.helper";
import { EDI_214_SHIPMENT_STATUS_CODES_MAIL_PICKUP_SPECIFIC, EDI_214_SHIPMENT_STATUS_CODES_MAIL_DELIVERY_SPECIFIC } from "../../context/klienschmidtdata";
//todo dedeup
const today = new Date();  // today based on the clock
const tomorrow = new Date(today); // based on today value
const yesterday = new Date(today); // based on today value
const minusSeven = new Date(today); // minus 7 days
const minusFourteen = new Date(today); // minus 14 days
const minusThirty = new Date(today); // minus 30 days
const plusSeven = new Date(today); // based on today value, plus 7 days
const plusFourteen = new Date(today); // based on today value, plus 14 days
const plusThirty = new Date(today); // based on today value, plus 30 days
const lastSunday = new Date(today); // based on today value, the last week's Sunday
const lastSaturday = new Date(today);// based on today value, the last week's Saturday
const thisSunday = new Date(today); // based on today value, the current week's Sunday
const thisSaturday = new Date(today);// based on today value, the current week's Saturday
const nextSunday = new Date(today);// based on today value, the next week's Sunday
const nextSaturday = new Date(today);// based on today value, the next week's Saturday
const newyears = new Date(today); // current calendar's first day of the year
const newyearseve = new Date(today); // current calendar's last day of the year
const currentYear = today.getFullYear();
const currentMonth = today.getMonth();
const nextMonth = new Date(today);
nextMonth.setMonth(currentMonth + 1);
const firstdayofthemonth = new Date(currentYear, currentMonth, 1);
const lastdayofthemonth = new Date(currentYear, currentMonth + 1, 0);
const firstdayoflastmonth = new Date(currentYear, currentMonth - 1, 1);
const lastdayoflastmonth = new Date(currentYear, currentMonth, 0);
const firstdayofnextmonth = new Date(currentYear, currentMonth + 1, 1);
const lastDayOfNextMonth = new Date(nextMonth);
lastDayOfNextMonth.setMonth(nextMonth.getMonth() + 1, 0);

tomorrow.setDate(today.getDate() + 1);
yesterday.setDate(today.getDate() - 1);
minusSeven.setDate(today.getDate() - 7);
minusFourteen.setDate(today.getDate() - 14);
minusThirty.setDate(today.getDate() - 30);
plusSeven.setDate(today.getDate() + 7);
plusFourteen.setDate(today.getDate() + 14);
plusThirty.setDate(today.getDate() + 30);


// Calculate the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
const currentDayOfWeek = today.getDay();

// Calculate the date for the last week's Sunday and Saturday
lastSunday.setDate(today.getDate() - (currentDayOfWeek + 7));
lastSaturday.setDate(today.getDate() - (currentDayOfWeek + 1));

// Calculate the date for the current week's Sunday and Saturday
thisSunday.setDate(today.getDate() - currentDayOfWeek);
thisSaturday.setDate(today.getDate() + (6 - currentDayOfWeek));

// Calculate the date for the next week's Sunday and Saturday
nextSunday.setDate(today.getDate() + (7 - currentDayOfWeek));
nextSaturday.setDate(today.getDate() + (13 - currentDayOfWeek));

// Calculate the first day and last day of the current year
newyears.setMonth(0); // January (0 index)
newyears.setDate(1);

newyearseve.setMonth(11); // December (0 index)
newyearseve.setDate(31);

function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    return `${year}-${month}-${day}`;
}

const initialQuery = {
    start: formatDate(today),
    end: formatDate(today),
    loadtenderLifeCycleStatus: 'All',
    contract: 'All',
}

function LoadTenderPay() {
    const { settings } = useSettings();
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [load, didLoad] = useState();
    const [contracts, setContracts] = useState([]);
    const [edi204, setEDI204] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [period, setPeriod] = useState('today');
    const [query, setQuery] = useState(initialQuery);
    const [displayToggle, setDisplayToggle] = useState();
    const [showCodes, setShowCodes] = useState();

    const labelStyle = {
        fontSize: '.9em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        whiteSpace: 'nowrap',
    }

    const resetStyle = {
        fontSize: '.75em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        backgroundColor: '#fff5bd',
        border: '1px solid',
        borderColor: '#222',
        borderRadius: '0.25em',
        whiteSpace: 'nowrap',
        float: 'right'
    }

    const reloadStyle = {
        fontSize: '.75em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        backgroundColor: '#bcdbbc',
        border: '1px solid',
        borderColor: '#222',
        borderRadius: '0.25em',
        whiteSpace: 'nowrap',
        float: 'left'
    }

    const displayButtonStyle = {
        fontSize: '.75em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        backgroundColor: '#fbb70e',
        border: '1px solid',
        borderColor: '#222',
        borderRadius: '0.25em',
        whiteSpace: 'nowrap',
        float: 'left'
    }

    const printStyle = {
        fontSize: '.75em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        backgroundColor: '#95add5',
        border: '1px solid',
        borderColor: '#222',
        borderRadius: '0.25em',
        whiteSpace: 'nowrap',
        float: 'left'
    }

    const buttonStyle = {
        fontSize: '.8em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        backgroundColor: '#fff',
        border: '1px solid',
        borderColor: '#222',
        borderRadius: '0.25em',
        whiteSpace: 'nowrap'
    }

    const buttonSelectedStyle = {
        fontSize: '.8em',
        margin: '0.25em',
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#222',
        backgroundColor: '#6eb6ff',
        border: '1px solid',
        borderColor: '#222',
        borderRadius: '0.25em',
        whiteSpace: 'nowrap'
    }


    function objectToQueryString(obj) {
        const params = new URLSearchParams();
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                params.append(key, obj[key]);
            }
        }
        return params.toString();
    }

    function setQueryStart(value) {
        if (value <= query.end) {
            setPeriod('custom')
            setQuery({ ...query, start: value })
            didSet(false)
        } else {
            setAlerts([...alerts, { variant: 'warning', message: 'Start should come before end.' }]);
        }
    }
    function setQueryEnd(value) {
        if (value >= query.start) {
            setPeriod('custom')
            setQuery({ ...query, end: value })
            didSet(false)
        } else {
            setAlerts([...alerts, { variant: 'warning', message: 'End should come after start.' }]);
        }
    }

    function setQueryLoadTenderStatus(value) {
        setQuery({ ...query, loadtenderLifeCycleStatus: value })
        didSet(false)
    }

    function setQueryContract(value) {
        setQuery({ ...query, contract: value })
        didSet(false)
    }

    function setQueryToday() {
        setQuery({ ...query, start: formatDate(today), end: formatDate(today) })
        setPeriod('today')
        didSet(false)
    }
    function setQueryTomorrow() {
        setQuery({ ...query, start: formatDate(tomorrow), end: formatDate(tomorrow) })
        setPeriod('tomorrow')
        didSet(false)
    }
    function setQueryYeserday() {
        setQuery({ ...query, start: formatDate(yesterday), end: formatDate(yesterday) })
        setPeriod('yesterday')
        didSet(false)
    }
    function setThisWeek() {
        setQuery({ ...query, start: formatDate(thisSunday), end: formatDate(thisSaturday) })
        setPeriod('thisweek')
        didSet(false)
    }
    function setLastWeek() {
        setQuery({ ...query, start: formatDate(lastSunday), end: formatDate(lastSaturday) })
        setPeriod('lastweek')
        didSet(false)
    }
    function setNextWeek() {
        setQuery({ ...query, start: formatDate(nextSunday), end: formatDate(nextSaturday) })
        setPeriod('nextweek')
        didSet(false)
    }
    function setThisMonth() {
        setQuery({ ...query, start: formatDate(firstdayofthemonth), end: formatDate(lastdayofthemonth) })
        setPeriod('thismonth')
        didSet(false)
    }
    function setLastMonth() {
        setQuery({ ...query, start: formatDate(firstdayoflastmonth), end: formatDate(lastdayoflastmonth) })
        setPeriod('lastmonth')
        didSet(false)
    }
    function setNextMonth() {
        setQuery({ ...query, start: formatDate(firstdayofnextmonth), end: formatDate(lastDayOfNextMonth) })
        setPeriod('nextmonth')
        didSet(false)
    }
    function setQueryMinus7() {
        setQuery({ ...query, start: formatDate(minusSeven), end: formatDate(today) })
        setPeriod('minus7')
        didSet(false)
    }
    function setQueryMinus14() {
        setQuery({ ...query, start: formatDate(minusFourteen), end: formatDate(today) })
        setPeriod('minus14')
        didSet(false)
    }
    function setQueryMinus30() {
        setQuery({ ...query, start: formatDate(minusThirty), end: formatDate(today) })
        setPeriod('minus30')
        didSet(false)
    }
    function setQueryPlus7() {
        setQuery({ ...query, start: formatDate(today), end: formatDate(plusSeven) })
        setPeriod('plus7')
        didSet(false)
    }
    function setQueryPlus14() {
        setQuery({ ...query, start: formatDate(today), end: formatDate(plusFourteen) })
        setPeriod('plus14')
        didSet(false)
    }
    function setQueryPlus30() {
        setQuery({ ...query, start: formatDate(today), end: formatDate(plusThirty) })
        setPeriod('plus30')
        didSet(false)
    }
    function setQueryYTD() {
        setQuery({ ...query, start: formatDate(newyears), end: formatDate(today) })
        setPeriod('ytd')
        didSet(false)
    }
    useEffect(() => {
        function extractUniqueValues(arr) {
            const uniqueValues = new Set();

            arr.forEach(subArray => {
                subArray.forEach(value => {
                    uniqueValues.add(value);
                });
            });

            return Array.from(uniqueValues);
        }

        function extractPrefixes(arr) {
            return arr.map(item => {
                const parts = item.split("_"); // Split the string into parts using the underscore character
                if (parts.length >= 2) {
                    return parts.slice(0, 2).join("_"); // Combine the first two parts with an underscore
                } else {
                    return item; // If there are fewer than 2 parts, return the original item
                }
            });
        }


        const fetchData = async () => {
            try {
                const edi204s = await Api.query(`/edi204`, query, idToken);
                // setEDI204(edi204s)
                const clean204s = edi204s.map(record => {
                    const extractedReferences = record?.Stops.map(via => via?.References.map(referance => referance?.Identifier));
                    const extractedValues = extractUniqueValues(extractedReferences)
                    const extractedData = extractPrefixes(extractedValues)
                    const uniqueData = [...new Set(extractedData)];
                    const schedule = adjustStringLength('S#', '2', '\u0020', false)
                        + ' | ' + adjustStringLength('LOCATION', '25', '\u0020', true)
                        + ' | ' + adjustStringLength('ARRIVE', '18', '\u0020', true)
                        + ' | ' + adjustStringLength('DEPART', '18', '\u0020', true)
                        + ' | ' + adjustStringLength('REASON', '18', '\u0020', true);

                    const edi214Updates = adjustStringLength('S#', '2', '\u0020', false)
                        + ' | ' + adjustStringLength('CODE', '4', '\u0020', true)
                        + ' | ' + adjustStringLength('DATETIME', '18', '\u0020', true)

                    return {
                        _id: record._id,
                        'Shipment Id': record?.ShipmentId,
                        'Status': record?.loadtenderLifeCycleStatus,
                        'Coverage': record?.operators ? record?.operators.map(op => `${op.name}`).join('\n') : '',
                        'Contract Trip': uniqueData.join(', \n').replace('_', ' '),
                        // '1st Stop Arrive': record?.Stops[0].RequestedDate,
                        'Tracking': '',
                        'Updates': edi214Updates + '\n' + record?.edi214Data?.map(msg => ` ${adjustStringLength(msg.ApptNumber, '2', '\u0020', true)} | ${adjustStringLength(msg.StatusCode, '4', '\u0020', true)} | ${adjustStringLength(formatDateStrToMMDDYYHHMM(msg.StatusDate) + ' ' + msg.TimeZone, '18', '\u0020', true)}`).join('\n'),
                        'Schedule': schedule + '\n' + record?.Stops.map(via => `${adjustStringLength(via?.StopNum, '2', '\u0020', false)
                            } | ${adjustStringLength((findUSPSNASSCode(via?.Name) + ', ' + findUSPSNASSCodeState(via?.Name)), '25', '\u0020', true)
                            } | ${adjustStringLength(formatUSPSDateTime(via?.Name, via?.RequestedDate, settings.timezone), '18', '\u0020', true)
                            } | ${adjustStringLength(formatUSPSDateTime(via?.Name, via?.LastRequestedDate, settings.timezone), '18', '\u0020', true)
                            } | ${adjustStringLength(via?.ReasonDesc, '18', ' ', true)
                            }`).join('\n'),
                        // 'Last Stop Depart': record?.Stops[record?.Stops.length-1].LastRequestedDate,
                        'Equipment': record?.Equipment,
                        'Rate': (record?.Rate / 100),
                    }
                })
                setEDI204(clean204s)
            } catch (err) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured getting Load Tenders.' }])
            }
            didSet(true)
        }

        const loadData = async () => {
            try {
                const ediContracts = await Api.get(`/edi204/contracts`, idToken);
                setContracts(ediContracts)
            } catch (err) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured getting Contracts.' }])
            }
            didLoad(true)
        }

        if (!set) {
            fetchData()
        }

        if (!load) {
            loadData();
        }
    })

    return (
        <Container fluid style={{ margin: '0px' }}>
            <AlertDisplay alertState={alertState} />
            <Card>
                <Card.Header>
                    <Card.Title>
                        Load Tender Pay 
                    </Card.Title>
                </Card.Header>
                <Card.Header>
                    <Row>
                        <Col>
                            <Button type="button" style={reloadStyle} onClick={() => didSet(false)}><FaSync /> Fetch</Button>
                            <Button type="button" style={printStyle}><FaPrint /> Print </Button>
                            <Button type="button" style={displayButtonStyle} onClick={() => setShowCodes(!showCodes)}>
                                {showCodes ? <MdHideSource/> : <MdCircle/>}{' '}
                                {showCodes ? 'Hide Codes' : 'Show Codes'}
                            </Button>
                            <Button type="button" style={displayButtonStyle} onClick={() => setDisplayToggle(!displayToggle)}>
                                {displayToggle ? <FaTable /> : <FaChartPie />}{' '}
                                {displayToggle ? 'Table' : 'Graph'}
                            </Button>
                        </Col>
                    </Row>
                </Card.Header>
                {showCodes &&
                    <Card.Body>
                        <pre>
                            <h5>PICK UP</h5>
                            {EDI_214_SHIPMENT_STATUS_CODES_MAIL_PICKUP_SPECIFIC.map((code, i) => (
                                <div key={i}>
                                    <b>{code.Code}</b> {code.Description}
                                </div>
                            ))}
                            <br/>
                            <h5>DELIVERY</h5>
                            {EDI_214_SHIPMENT_STATUS_CODES_MAIL_DELIVERY_SPECIFIC.map((code, i) => (
                                <div key={i}>
                                    <b>{code.Code}</b> {code.Description}
                                </div>
                            ))}
                        </pre>
                    </Card.Body>
                }
                <Card.Header>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                        <div>
                            <label style={labelStyle}>Status</label>
                            <select style={buttonStyle} value={query.loadtenderLifeCycleStatus} onChange={e => setQueryLoadTenderStatus(e.target.value)}>
                                <option value="All">All</option>
                                <option value="pending">Pending</option>
                                <option value="Accepted">Accepted</option>
                                <option value="Completed">Completed</option>
                                <option value="Rejected">Rejected</option>
                            </select>
                        </div>
                        <div>
                            <label style={labelStyle}>Contract</label>
                            <select style={buttonStyle} value={query.location} onChange={e => setQueryContract(e.target.value)}>
                                <option value="All">All</option>
                                {contracts.map((contract, i) => (
                                    <option key={i}>{contract}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label style={labelStyle}>Start</label>
                            <input type="date" style={buttonStyle} value={query.start} onChange={e => setQueryStart(e.target.value)} />
                        </div>
                        <div>
                            <label style={labelStyle}>End</label>
                            <input type="date" style={buttonStyle} value={query.end} onChange={e => setQueryEnd(e.target.value)} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                        <button type="button" style={period === 'minus30' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryMinus30()}>-30</button>
                        <button type="button" style={period === 'minus14' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryMinus14()}>-14</button>
                        <button type="button" style={period === 'minus7' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryMinus7()}>-7</button>
                        <button type="button" style={period === 'lastmonth' ? buttonSelectedStyle : buttonStyle} onClick={() => setLastMonth()}>Last Month</button>
                        <button type="button" style={period === 'lastweek' ? buttonSelectedStyle : buttonStyle} onClick={() => setLastWeek()}>Last Week</button>
                        <button type="button" style={period === 'yesterday' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryYeserday()}>Yesterday</button>
                        <button type="button" style={period === 'today' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryToday()}>Today</button>
                        <button type="button" style={period === 'tomorrow' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryTomorrow()}>Tomorrow</button>
                        <button type="button" style={period === 'thisweek' ? buttonSelectedStyle : buttonStyle} onClick={() => setThisWeek()}>This Week</button>
                        <button type="button" style={period === 'nextweek' ? buttonSelectedStyle : buttonStyle} onClick={() => setNextWeek()}>Next Week</button>
                        <button type="button" style={period === 'thismonth' ? buttonSelectedStyle : buttonStyle} onClick={() => setThisMonth()}>This Month</button>
                        <button type="button" style={period === 'nextmonth' ? buttonSelectedStyle : buttonStyle} onClick={() => setNextMonth()}>Next Month</button>
                        <button type="button" style={period === 'plus7' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryPlus7()}>+7</button>
                        <button type="button" style={period === 'plus14' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryPlus14()}>+14</button>
                        <button type="button" style={period === 'plus30' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryPlus30()}>+30</button>
                        <button type="button" style={period === 'ytd' ? buttonSelectedStyle : buttonStyle} onClick={() => setQueryYTD()}>YTD</button>
                    </div>
                </Card.Header>
                <Card.Header id="mctms-printable">
                    {period === 'minus30' && ' Load Tenders for the past 30 days, beginning from Today .'}
                    {period === 'minus14' && ' Load Tenders for the past 14 days, beginning from Today.'}
                    {period === 'minus7' && ' Load Tenders for the past 7 days, beginning from Today.'}
                    {period === 'yesterday' && ' Yesterday\'s Load Tenders.'}
                    {period === 'today' && ' Today\'s Load Tenders.'}
                    {period === 'tomorrow' && ' Tomorrow\'s Load Tenders'}
                    {period === 'thisweek' && ' Load Tenders for this week Sunday-Saturday.'}
                    {period === 'nextweek' && ' Load Tenders for this week Sunday-Saturday.'}
                    {period === 'lastweek' && ' Load Tenders from last week Sunday-Saturday.'}
                    {period === 'thismonth' && ' Load Tenders for this month.'}
                    {period === 'nextmonth' && ' Load Tenders for next month.'}
                    {period === 'lastmonth' && ' Load Tenders for last month.'}
                    {period === 'plus7' && ' Load Tenders for next 7 days, beginning from Today.'}
                    {period === 'plus14' && ' Load Tenders for next 14 days, beginning from Today.'}
                    {period === 'plus30' && ' Load Tenders for next 30 days, beginning from Today.'}
                    {period === 'ytd' && ' Load Tenders from first of the year through today.'}
                    {period === 'custom' && `Custom range: ${query.start}-${query.end}`}
                </Card.Header>
                {set ?
                    <Card.Body>
                        {displayToggle ?
                            <>
                                Charts coming soon.
                            </>
                            :
                            <DataDisplay
                                sourceName={'MCTMS-Active-Load-Tenders'}
                                dataSource={edi204}
                                lsKey={'@mctms-loadtender-204-display-active'}
                                urlPath={'/loadtender/update/'}
                                urlKey={'_id'}
                                popKeys={['_id']}
                            />
                        }
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }
            </Card>
        </Container>
    );
}

export default LoadTenderPay;