import { Container, Card, Button, Table, Row, Col, Nav, Spinner, Alert } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import React, { useState, useEffect } from 'react'
import { FaEdit, FaPlusCircle } from 'react-icons/fa'
import { useAuth } from "../../context/auth";
import DataDisplay from "../../components/DataDisplay";
import { Link } from "react-router-dom";
import AlertDisplay from "../../components/AlertDisplay";

function TimecardProfile() {
    const { user, idToken } = useAuth();
    const [set, didSet] = useState();
    const [payperiods, setPayPeriods] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = {alerts, setAlerts}
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Api.get('/payperiods/live', idToken);
                if (data) {
                    const cleanPayPeriods = data.map((payperiod, i) => {
                        return {
                            _id: payperiod._id,
                            'Period Start': payperiod.periodStart,
                            'Period End': payperiod.periodEnd,
                        }
                    })
                    setPayPeriods(cleanPayPeriods)
                }
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }]);
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [user, idToken, set, alerts, setAlerts])

    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <Card.Title>My Timecards <Link className="float-end" to="/timecard-form"><FaPlusCircle /> Form</Link></Card.Title>
                </Card.Header>
                <AlertDisplay alertState={alertState} />
                {set ?
                    <Card.Body>
                        {payperiods ?
                            <DataDisplay
                                dataSource={payperiods}
                                lsKey={'@mctms-profile-payperiods-display'}
                                urlPath={`/timecard/${user.uid}/`}
                                urlKey={'_id'}
                                popKeys={['_id']}
                            />
                            :
                            <Row>
                                <Col>
                                    No timecards. 
                                </Col>
                            </Row>
                        }
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    );
}

export default TimecardProfile;