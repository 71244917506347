import React from "react";
import { FaLockOpen, FaUserCog } from "react-icons/fa";
import NavBarComponent from "../../components/NavBar.component";
import { useRole } from "../../context/role";
import { HiUserAdd, HiUserGroup } from "react-icons/hi";
import { MdOutlineBadge } from "react-icons/md";
import { CgProfile } from "react-icons/cg";

const AccessNav = () => {
    const { userRole, checkNavPermissions } = useRole();
    
    const navOptions = [
        {href:"/access", icon: <FaLockOpen/>, text:"Access" },
        {href:"/access/roles", icon: <MdOutlineBadge/>, text:"Roles" },
        {href:"/access/groups", icon: <HiUserGroup/>, text:"Groups" },
        {href:"/access/profiles", icon: <CgProfile/>, text:"Users" },
        {href:"/access/new-users", icon: <HiUserAdd/>, text:"New Users" },
        {href:"/access/stale-users", icon: <FaUserCog/>, text:"Stale Users" },
    ]
    
    const allowed = checkNavPermissions(userRole, navOptions);

    return (
        <NavBarComponent name={'Access Menu'} navOptions={allowed} />
    );
};


export default AccessNav