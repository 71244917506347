import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Button, Form, Row, Col, ListGroup, ListGroupItem, Container, Dropdown, ModalHeader, ModalBody, ModalFooter, ModalTitle, Badge } from 'react-bootstrap';
import { FaArrowLeft, FaArrowRight, FaCircle, FaEdit, FaFileExport, FaFilter, FaList, FaSearch, FaSyncAlt, FaTable } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FiDelete } from 'react-icons/fi';
import { generateExcel, generateCSV, generatePDF } from '../utils/FileGenerator';
import { BsFiletypeXlsx, BsFiletypeCsv, BsFiletypePdf } from 'react-icons/bs';
import { Modal } from 'react-bootstrap';
import { GoNote } from "react-icons/go";
import { CiViewColumn } from "react-icons/ci";
import { RiDraggable } from "react-icons/ri";
import { MdCircle, MdHideSource } from 'react-icons/md';
// modalAction = {_id:'', icon:''}

const DataDisplayModal = ({ dataSource, lsKey, urlPath = '', urlKey = '', popKeys = [], sourceName, addActions = [] }) => {

    const [filteredData, setFilteredData] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState({});
    const [showFilters, setShowFilters] = useState(false);

    const [useCardStyle, setUseCardStyle] = useState(() => {
        const saved = localStorage.getItem(`${lsKey}-dataDisplay`);
        return saved ? saved : false;
    });

    const [columns, setColumns] = useState(() => {
        const saved = localStorage.getItem(`${lsKey}`);
        return saved ? JSON.parse(saved) : [];
    });

    const [searchText, setSearchText] = useState(() => {
        const saved = localStorage.getItem(`${lsKey}-searchText`);
        return saved !== null ? saved : '';
    });

    const [currentPage, setCurrentPage] = useState(() => {
        const saved = localStorage.getItem(`${lsKey}-currentPage`);
        return saved !== null ? Number(saved) : 1;
    });

    const [itemsPerPage, setItemsPerPage] = useState(() => {
        const saved = localStorage.getItem(`${lsKey}-itemsPerPage`);
        return saved !== null ? Number(saved) : 5;
    });

    const [includeColumnsInTable, setIncludeColumnsInTable] = useState(() => {
        const saved = localStorage.getItem(`${lsKey}-includeColumns`);
        return saved ? JSON.parse(saved) : [];
    });

    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');

    const [showCardColumnSort, setShowCardColumnSort] = useState(false)

    // Effect for initializing state from local storage
    useEffect(() => {
        const savedSortState = localStorage.getItem(`${lsKey}-columnSort`);
        if (savedSortState) {
            const { column, order } = JSON.parse(savedSortState);
            if (column) setSortColumn(column);
            if (order) setSortOrder(order);
        }
    }, [lsKey]);

    // Effect for updating local storage when sortColumn or sortOrder changes
    useEffect(() => {
        const sortState = { column: sortColumn, order: sortOrder };
        localStorage.setItem(`${lsKey}-columnSort`, JSON.stringify(sortState));
    }, [lsKey, sortColumn, sortOrder]);

    // Function to handle sorting (this is just a placeholder function)
    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortOrder('asc');
        }
    };

    // Calculate pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = itemsPerPage === -1 ? filteredData : filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const currentPageRange = Math.min(currentPage + 2, totalPages);

    const [showModal, setShowModal] = useState(false);

    // filteredData.sort((a, b) => {
    //     if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
    //     if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
    //     return 0;
    // });

    const resetLocalStorage = useCallback(() => {
        setAppliedFilters({})
        localStorage.removeItem(lsKey);
        localStorage.removeItem(`${lsKey}-searchText`);
        localStorage.removeItem(`${lsKey}-currentPage`);
        localStorage.removeItem(`${lsKey}-itemsPerPage`);
        localStorage.removeItem(`${lsKey}-includeColumns`)
        localStorage.removeItem(`${lsKey}-columnSort`)
        setSearchText('')
        setColumns(Object.keys(dataSource[0] || {}));
        setIncludeColumnsInTable(Object.keys(dataSource[0]))
    }, [dataSource, lsKey]);

    const extractTextFromComponent = useCallback((component) => {
        if (!component) {
            return '';
        }

        if (typeof component === 'string' || typeof component === 'number') {
            return String(component);
        }

        if (Array.isArray(component)) {
            return component.map(extractTextFromComponent).join(' ');
        }

        if (component.props && component.props.children) {
            return extractTextFromComponent(component.props.children);
        }

        return '';
    }, []);

    const resetTableToDefault = () => {
        setUseCardStyle(false)
        setSortColumn(null)
        setSortOrder('asc')
        setAppliedFilters({})
        setSearchText('')
        setFilteredData(dataSource);
        setCurrentPage(1);
    }

    const handleColumnReorder = (dragIndex, dropIndex) => {
        const updatedColumns = [...columns];
        const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
        updatedColumns.splice(dropIndex, 0, draggedColumn);
        setColumns(updatedColumns);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (e) => {
        setCurrentPage(1);
        setItemsPerPage(parseInt(e.target.value));
    };

    const handleNavigationKeys = (e) => {
        if (e.key === 'ArrowLeft' && currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        } else if (e.key === 'ArrowRight' && currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handleFilterChange = (column, value) => {
        const newFilters = { ...appliedFilters, [column]: value };
        setAppliedFilters(newFilters);
        let filtered = dataSource;
        Object.entries(newFilters).forEach(([filterColumn, filterValue]) => {
            if (filterValue !== '') {
                filtered = filtered.filter((item) => {
                    const columnValue = item[filterColumn];

                    if (typeof columnValue === 'string') {
                        return columnValue.includes(filterValue);
                    } else if (columnValue instanceof Date) {
                        return columnValue.toISOString().includes(filterValue);
                    } else if (typeof columnValue === 'number') {
                        return columnValue.toString().includes(filterValue);
                    }

                    return false;
                });
            }
        });

        setFilteredData(filtered);
        setCurrentPage(1);
    };

    const getUniqueValues = (data, column) => {
        const uniqueValues = new Set();
        data.forEach((item) => {
            const value = item[column];
            if (value) {
                uniqueValues.add(value);
            }
        });

        const sortedValues = Array.from(uniqueValues).sort((a, b) => {
            if (typeof a === 'string' && typeof b === 'string') {
                return a.localeCompare(b);
            } else if (typeof a === 'string') {
                return -1;
            } else if (typeof b === 'string') {
                return 1;
            } else {
                return a - b;
            }
        });

        return sortedValues;
    };

    const renderDraggableTableHeader = () => {
        return (
            <tr>
                {(urlPath && urlKey) &&
                    <th></th>
                }
                {columns.map((column, index) => (
                    (!popKeys.includes(column) && includeColumnsInTable.includes(column)) &&
                    <th key={index} style={{ whiteSpace: 'nowrap' }}>
                        <div
                            className="d-flex align-items-center"
                            draggable
                            onDragStart={(e) => e.dataTransfer.setData('text/plain', index)}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => {
                                const dragIndex = parseInt(e.dataTransfer.getData('text/plain'));
                                const dropIndex = index;
                                handleColumnReorder(dragIndex, dropIndex);
                            }}
                        >
                            <RiDraggable />
                            <span onClick={() => handleSort(column)}>
                                {(Object.keys(appliedFilters).includes(column) && appliedFilters[column] !== '') && <span style={{ color: 'red' }}>*</span>}
                                {column}
                            </span>
                            {sortColumn === column && (
                                <span className="sort-arrow">{sortOrder === 'asc' ? '↑' : '↓'}</span>
                            )}
                        </div>
                        {showFilters && (
                            <Form.Select
                                style={{ minWidth: '200px' }}
                                value={appliedFilters[column] || ''}
                                onChange={(e) => handleFilterChange(column, e.target.value)}
                                multiple
                            >
                                <option value="">All</option>
                                {getUniqueValues(filteredData, column).map((value, index) => (
                                    <option key={index} value={value}>
                                        {value}
                                    </option>
                                ))}
                            </Form.Select>
                        )}
                    </th>
                ))}
            </tr>
        );
    };

    const renderDraggableCardHeader = () => {
        return (
            showCardColumnSort &&
            <tr>
                <th style={{ border: 0 }}>
                    {columns.map((column, i) => (
                        (!popKeys.includes(column) && includeColumnsInTable.includes(column)) &&
                        <React.Fragment key={i} >
                            <Col style={{ border: '1px solid #eee', boxSizing: 'border-box', padding: '0px', margin: '0px' }}>
                                <div
                                    className="d-flex align-items-center"
                                    draggable
                                    onDragStart={(e) => e.dataTransfer.setData('text/plain', i)}
                                    onDragOver={(e) => e.preventDefault()}
                                    onDrop={(e) => {
                                        const dragIndex = parseInt(e.dataTransfer.getData('text/plain'));
                                        const dropIndex = i;
                                        handleColumnReorder(dragIndex, dropIndex);
                                    }}
                                    style={{ border: '1px solid #ccc', padding: '0.1em' }}
                                >
                                    <RiDraggable />
                                    <span onClick={() => handleSort(column)}>
                                        {(appliedFilters[column] !== undefined && appliedFilters[column] !== '') && <span style={{ color: 'red' }}>*</span>}
                                        {column}
                                    </span>
                                    {sortColumn === column && (
                                        <span className="sort-arrow">{sortOrder === 'asc' ? '↑' : '↓'}</span>
                                    )}
                                </div>
                                {showFilters && (
                                    <Form.Select
                                        style={{ borderRadius: 0 }}
                                        value={appliedFilters[column] || ''}
                                        onChange={(e) => handleFilterChange(column, e.target.value)}
                                        multiple
                                    >
                                        <option value="">All</option>
                                        {getUniqueValues(filteredData, column).map((value, i) => (
                                            <option key={i} value={value}>
                                                {value}
                                            </option>
                                        ))}
                                    </Form.Select>
                                )}
                            </Col>
                        </React.Fragment>
                    ))}
                </th>
            </tr>
        );
    };

    const renderDraggableCheckBoxes = () => {
        return (
            <Row>
                {(urlPath && urlKey) &&
                    <th></th>
                }
                {columns.map((column, index) => (
                    !popKeys.includes(column) &&
                    <th key={index} style={{ whiteSpace: 'nowrap' }}>
                        <div
                            className="d-flex align-items-center"
                            draggable
                            onDragStart={(e) => e.dataTransfer.setData('text/plain', index)}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => {
                                const dragIndex = parseInt(e.dataTransfer.getData('text/plain'));
                                const dropIndex = index;
                                handleColumnReorder(dragIndex, dropIndex);
                            }}
                        >
                            <RiDraggable />
                            <Form.Check
                                value={column}
                                // label={column}
                                checked={includeColumnsInTable.includes(column)}
                                onChange={() => handleCheckboxChange(column)}
                            />
                            <span onClick={() => handleSort(column)}>
                                {(Object.keys(appliedFilters).includes(column) && appliedFilters[column] !== '') && <span style={{ color: 'red', padding: '0.5em' }}>{` * `}</span>}
                                {' '}{column}
                            </span>
                            {sortColumn === column && (
                                <span className="sort-arrow">{sortOrder === 'asc' ? '↑' : '↓'}</span>
                            )}
                        </div>
                        {showFilters && (
                            <Form.Select
                                style={{ minWidth: '200px' }}
                                value={appliedFilters[column] || ''}
                                onChange={(e) => handleFilterChange(column, e.target.value)}
                                multiple

                            >
                                <option value="">All</option>
                                {getUniqueValues(filteredData, column).map((value, i) => (
                                    <option key={i} value={value}>
                                        {value}
                                    </option>
                                ))}
                            </Form.Select>
                        )}
                    </th>
                ))}
            </Row>
        );
    };

    const renderCellValue = (value) => {
        if (typeof value === 'object') {
            if (React.isValidElement(value)) {
                return value;
            }
            return JSON.stringify(value);
        }
        return value;
    };

    const renderTableRows = () => {
        return currentItems.map((item, index) => (
            <tr key={index}>
                {(urlPath && urlKey) &&
                    <td>
                        <span style={{ paddingLeft: '.25em', paddingRight: '.25em'}}>
                            <Link to={urlPath + item[urlKey]} style={{fontSize:'1.5em'}}>
                                <FaEdit />
                            </Link>
                        </span>
                        {(addActions && addActions.length > 0) &&
                            addActions.map((action, i) => (
                                <span key={i} style={{ paddingLeft: '.25em', paddingRight: '.25em', }}>
                                    <Button key={i} to={item[action.urlKey]}>
                                        {action.icon}
                                    </Button>
                                </span>
                            ))
                        }
                    </td>
                }
                {
                    columns.map((column, idx) => (
                        (!popKeys.includes(column) && includeColumnsInTable.includes(column)) &&
                        <td key={idx}>
                            {renderCellValue(item[column])}
                        </td>
                    ))}
            </tr>
        ));
    };

    const renderTableRowCards = () => {
        return currentItems.map((item, i) => (
            <tr key={i} style={{ border: 0 }}>
                <td style={{ border: 0, padding: 0, paddingBottom: '1em' }}>
                    <ListGroup>
                        {(urlPath && urlKey) &&
                            <ListGroupItem style={{ backgroundColor: '#f9f9f9' }}>
                                <Link to={urlPath + item[urlKey]} style={{fontSize:'1.5em'}}>
                                    <FaEdit />
                                </Link>
                            </ListGroupItem>
                        }
                        {columns.map((column, j) => (
                            (!popKeys.includes(column) && includeColumnsInTable.includes(column)) &&
                            <ListGroupItem key={j} style={{ padding: '0px', margin: '0px' }}>
                                <Row style={{ padding: '1px', margin: '0px' }}>
                                    <Col sm={12} md={2}>
                                        <b>{column}</b>
                                    </Col>
                                    <Col sm={12} md={10}>
                                        {renderCellValue(item[column])}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </td>
            </tr>
        ));
    };

    const renderPagination = () => {
        const pageNumbers = [];
        for (let number = Math.max(1, currentPage - 2); number <= currentPageRange; number++) {
            pageNumbers.push(
                <li
                    key={number}
                    className={`page-item ${currentPage === number ? 'active' : ''}`}
                    onClick={() => handlePageChange(number)}
                >
                    <span className="page-link">{number}</span>
                </li>
            );
        }

        return (
            <nav>
                <ul className="pagination" style={{ margin: 0 }}>
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <Button variant="link" onClick={() => handlePageChange(1)}>
                            First
                        </Button>
                    </li>
                    {currentPage > 1 && (
                        <li className="page-item">
                            <Button variant="link" onClick={() => handlePageChange(currentPage - 1)}>
                                <FaArrowLeft />
                            </Button>
                        </li>
                    )}
                    {pageNumbers}
                    {currentPage < totalPages && (
                        <li className="page-item">
                            <Button variant="link" onClick={() => handlePageChange(currentPage + 1)}>
                                <FaArrowRight />
                            </Button>
                        </li>
                    )}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <Button variant="link" onClick={() => handlePageChange(totalPages)}>
                            Last
                        </Button>
                    </li>
                </ul>
            </nav>
        );
    };

    const toggleDisplayStyle = () => {
        setUseCardStyle(!useCardStyle);
    };

    const handleCheckboxChange = (column) => {
        setIncludeColumnsInTable(prev => {
            if (prev.includes(column)) {
                // If the column is already in the array, remove it
                return prev.filter(item => item !== column);
            } else {
                // If the column is not in the array, add it
                return [...prev, column];
            }
        });
    };

    const includeAllColumns = () => {
        setIncludeColumnsInTable(Object.keys(dataSource[0]))
    }

    const excludeAllColumns = () => {
        setIncludeColumnsInTable(['_id'])
    }


    useEffect(() => {
        const handleScreenSizeChange = () => {
            const saved = localStorage.getItem(`${lsKey}-dataDisplay`);
            const shouldUseCardStyle = saved === 'true' ? true : false;
            setUseCardStyle(shouldUseCardStyle);
        };

        handleScreenSizeChange();

        window.addEventListener('resize', handleScreenSizeChange);

        return () => {
            window.removeEventListener('resize', handleScreenSizeChange);
        };

    }, [lsKey]);

    useEffect(() => {
        if (dataSource.length > 0) {
            const keysMatchLocalStorage = () => {
                const savedColumns = localStorage.getItem(`${lsKey}`);
                if (!savedColumns) return false; // if there are no saved columns, they don't match
                const storedKeys = JSON.parse(savedColumns);
                const currentKeys = Object.keys(dataSource[0] || {});
                return JSON.stringify(storedKeys.sort()) === JSON.stringify(currentKeys.sort());
            };
            const matchingKeys = keysMatchLocalStorage();
            
            if (!matchingKeys) {
                resetLocalStorage();
            }

            localStorage.setItem(`${lsKey}`, JSON.stringify(columns));

            const applyFiltersAndSearch = () => {
                let filteredDataSource = dataSource;

                if (searchText.trim() !== '') {
                    filteredDataSource = dataSource.filter((item) => {
                        return columns.some((column) => {
                            const value = item[column];
                            if (value === null || value === undefined) {
                                return false;
                            }

                            const valueString = extractTextFromComponent(value);

                            return valueString.toLowerCase().includes(searchText.toLowerCase());
                        });
                    });
                }

                setFilteredData(filteredDataSource);
                setCurrentPage(1);
            };


            applyFiltersAndSearch();
        }

        const saved = localStorage.getItem(`${lsKey}-columnSort`);
        const options = JSON.parse(saved)
        const column = options?.column ? options?.column : null;
        const order = options?.order ? options?.order : 'asc';
        setSortColumn(column)
        setSortOrder(order)

    }, [dataSource, lsKey, columns, resetLocalStorage, searchText, setCurrentPage, extractTextFromComponent]);

    useEffect(() => {
        localStorage.setItem(`${lsKey}-searchText`, searchText);
    }, [searchText, lsKey]);

    useEffect(() => {
        localStorage.setItem(`${lsKey}-currentPage`, currentPage.toString());
    }, [currentPage, lsKey]);

    useEffect(() => {
        localStorage.setItem(`${lsKey}-itemsPerPage`, itemsPerPage.toString());
    }, [itemsPerPage, lsKey]);

    useEffect(() => {
        localStorage.setItem(`${lsKey}-includeColumns`, JSON.stringify(includeColumnsInTable));
    }, [includeColumnsInTable, lsKey,]);

    useEffect(() => {
        localStorage.setItem(`${lsKey}-dataDisplay`, useCardStyle);
    }, [useCardStyle, lsKey,]);

    useEffect(() => {
        const sortState = {
            column: sortColumn,
            order: sortOrder
        };
        localStorage.setItem(`${lsKey}-columnSort`, JSON.stringify(sortState));
    }, [sortColumn, sortOrder, dataSource, lsKey]);

    // const sortedData = [...dataSource].sort((a, b) => {
    //     const aValue = extractTextFromComponent(a[sortColumn]);
    //     const bValue = extractTextFromComponent(b[sortColumn]);

    //     if (aValue < bValue) {
    //         return sortOrder === 'asc' ? -1 : 1;
    //     }
    //     if (aValue > bValue) {
    //         return sortOrder === 'asc' ? 1 : -1;
    //     }
    //     return 0;
    // });

    filteredData.sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
        if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
        return 0;
    });

    const selectColumnsModalRef = useRef(null);

    return (
        <Container fluid style={{ margin: '0px', padding: '0px', overflow: 'auto' }}>
            <Modal show={showModal} onHide={() => setShowModal(false)} ref={selectColumnsModalRef}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {renderDraggableCheckBoxes()}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="auto" onClick={() => { setShowFilters(!showFilters); }}>
                        <FaFilter /> {showFilters ? 'Hide' : 'Show'} Filters
                    </Button>
                    <Button onClick={() => includeAllColumns()}>
                        Include All
                    </Button>
                    <Button onClick={() => excludeAllColumns()}>
                        Exlcude All
                    </Button>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Row style={{ marginTop: '0.25em', marginBottom: '0.25em' }} className="justify-content-start align-items-center ">
                <Col xs='auto' >
                    Records: {currentItems.length} / {dataSource.length}
                </Col>
                <Col xs='auto' >
                    {!useCardStyle ?
                        <><FaTable /> Table</>
                        :
                        <>
                            <Badge bg={'secondary'} onClick={() => setShowCardColumnSort(!showCardColumnSort)}>
                                {showCardColumnSort ? <MdHideSource /> : <MdCircle />}
                            </Badge>{` `}
                            <GoNote />{' Card '}
                        </>
                    }
                </Col>
                <Col style={{ textAlign: 'center' }} xs='auto' >
                    <Button variant="link" className="auto" onClick={toggleDisplayStyle}>
                        {!useCardStyle ?
                            <><GoNote /> Card</>
                            :
                            <><FaTable /> Table</>
                        }
                    </Button>
                </Col>
                <Col xs='auto' >
                    <Button variant="link" className="auto" onClick={() => setShowModal(true)}>
                        <CiViewColumn /> Select Data
                    </Button>
                </Col>
                <Col xs='auto' >
                    <Button variant="link" className="auto" onClick={() => { setShowFilters(!showFilters); }}>
                        <FaFilter /> {showFilters ? 'Hide' : 'Show'} Filters
                    </Button>
                </Col>
                <Col xs='auto' >
                    <Button variant="link" className="auto" onClick={resetTableToDefault}>
                        <FaSyncAlt /> Reset
                    </Button>
                </Col>
                <Col xs='auto' >
                    <div className="align-items-center">
                        {renderPagination()}
                        <div tabIndex={0} onKeyDown={handleNavigationKeys} />
                    </div>
                </Col>
                <Col xs='auto' >
                    <Form.Select className="w-auto" style={{ margin: 'auto' }} value={itemsPerPage} onChange={handleItemsPerPageChange} >
                        <option value={-1}>All</option>
                        <option value={1}>1</option>
                        <option value={5}>5</option>
                        <option value={15}>15</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </Form.Select>
                </Col>
                <Col xs='auto' >
                    <Row>
                        <Col xs='auto' >
                            <Form.Control
                                type="text"
                                placeholder={"🔎 Search... "}
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                style={{ float: 'left', minWidth: '300px' }}
                            />
                        </Col>
                        <Col xs='auto' >
                            {searchText &&
                                <Button
                                    variant='secondary'
                                    onClick={() => setSearchText('')}
                                >
                                    <FiDelete />
                                </Button>
                            }
                        </Col>
                    </Row>
                </Col>
                <Col xs='auto' >
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <FaFileExport />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => generateExcel(dataSource, sourceName)}><BsFiletypeXlsx />Export to Excel</Dropdown.Item>
                            {/* <Dropdown.Item onClick={() => generateCSV(dataSource, sourceName)}><BsFiletypeCsv />Export to CSV</Dropdown.Item> */}
                            <Dropdown.Item onClick={() => generatePDF(dataSource, sourceName)}><BsFiletypePdf />Export to PDF</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
            <Row>
                <Col>
                    <pre>
                        <Table
                            striped={useCardStyle ? false : true}
                            bordered={useCardStyle ? false : true}
                            hover={useCardStyle ? false : true}
                            responsive={true}
                        >
                            <thead>{dataSource.length > 0 && (useCardStyle ? renderDraggableCardHeader() : renderDraggableTableHeader())}</thead>
                            <tbody>
                                {dataSource.length > 0
                                    ? (useCardStyle ? renderTableRowCards() : renderTableRows())
                                    :
                                    <tr>
                                        <td>
                                            No data.
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </pre>
                </Col>
            </Row>
        </Container >
    );
};

export default DataDisplayModal;
