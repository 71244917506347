import { Container, Card, Row, Col, Form, Spinner, Alert, Button, Table, Badge } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import Api from "../../utils/Api";
import { useAuth } from '../../context/auth';
import { TIMEZONE_OPTIONS } from "../../context/appdata";
import { FORM_REPORTS } from "../../context/appreports";
import { useParams } from 'react-router-dom';
import AlertDisplay from "../../components/AlertDisplay";
import { FaTrash } from "react-icons/fa";


function ReportUpdate() {
    const { id } = useParams();
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [emails, setEmails] = useState([]);
    const [report, setReport] = useState({});
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }


    const runJob = async () => {
        try {
            await Api.query(`/jobs/agenda/${id}/run`, {emails: [...emails]}, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Job ran successfully' }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: JSON.stringify(error) }])
        }
    }

    async function updateJob() {
        try {
            if(emails.length > 0 && emails.filter(i => i.trim() !== '')){
                const res = await Api.patch(`/jobs/agenda/${id}`, {emails:emails}, idToken)
                setAlerts([...alerts, { variant: 'success', message: JSON.stringify(res) }])
                didSet(false)
            } else {
                setAlerts([...alerts, { variant: 'warning', message: 'Emails cannot be empty' }])
            }
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: JSON.stringify(error) }])
        }
    }

    async function updateEmail(i, email) {
        emails[i] = email
        setEmails([...emails])
    }

    async function addEmail() {
        emails.push('')
        setEmails([...emails])
    }

    async function removeEmail(i) {
        const updatedEmails = emails.filter((_, index) => index !== i);
        setEmails(updatedEmails);
    }

    useEffect(() => {
        const handleFetch = async () => {
            try {
                const rpt = await Api.get(`/jobs/agenda/${id}`, idToken)
                const distro = rpt?.data?.emails ? [...rpt?.data?.emails] : [`${rpt?.data?.email}`];
                setEmails(distro)
                setReport(rpt)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured requesting data.' }])
            }
            didSet(true)
        }

        if (!set) {
            handleFetch();
        }
    }, [set, idToken, id, alerts, emails, report])

    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header>
                    <b>Report Form</b>
                </Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {set ?
                    < Card.Body >
                        <Row>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="type">Id:</label>
                                {report?._id}
                            </Col>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="type">Name:</label>
                                {report?.name}
                            </Col>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="type">Type:</label>
                                {report?.type}
                            </Col>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="report">Report:</label>
                                {report?.data?.email}
                            </Col>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="report">Report:</label>
                                {report?.data?.report}
                            </Col>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="priority">Priority:</label>
                                {report?.priority}
                            </Col>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="nextRunAt">Next Run At:</label>
                                {report?.nextRunAt}
                            </Col>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="repeatInterval">Repeat Interval (Cron format):</label>
                                {report?.repeatInterval}
                            </Col>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="shouldSaveResult">Should Save Result:</label>
                                {report?.shouldSaveResult}
                            </Col>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="repeatInterval">Skip Days: </label>
                                {report?.skipDays}
                            </Col>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="shouldSaveResult">Start Date: </label>
                                {report?.startDate}
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <Badge onClick={() => addEmail()}>+ Email</Badge>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            {emails.length > 0 && emails.map((email, i) => (
                                <Col sm={12} md={6} lg={4} xl={3} key={i}>
                                    <input type="email" style={{ minWidth: 300 }} value={email} onChange={(e) => updateEmail(i, e.target.value)} />
                                    <button onClick={() => removeEmail(i)}><FaTrash /></button>
                                </Col>
                            ))}
                        </Row>
                        <div style={{marginTop:'1em'}}>
                            <Button variant="primary" onClick={() => updateJob()} style={{marginRight:'0.5em'}}>Update</Button>
                            <Button variant="secondary" onClick={() => runJob()} style={{marginRigth:'0.5em'}}>Run</Button>
                        </div>

                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }
            </Card>
        </Container >
    );
}

export default ReportUpdate;