
import React from "react";
import { FaRoute, FaCalendarAlt, FaRegIdCard, FaTrailer, FaBookmark, FaTruck, FaSearchLocation, FaFileContract, FaLink, FaClipboard, FaExclamationTriangle, } from 'react-icons/fa';
import { FiRepeat, } from 'react-icons/fi';
import NavBarComponent from "../../components/NavBar.component";
import { useRole } from "../../context/role";
const PlanNav = () => {
    const { userRole, checkNavPermissions } = useRole();
    const navOptions = [
        {href:"/plan", icon:<FaClipboard /> , text:"Plan" },
        {href:"/plan/issues", icon: <FaExclamationTriangle />, text:"Issues" },
        {href:"/plan/assignment", icon:<FaLink /> , text:"Assignment" },
        {href:"/plan/contract", icon:<FaFileContract /> , text:"Contract" },
        {href:"/plan/forecast", icon:<FaCalendarAlt /> , text:"Forecast" },
        {href:"/plan/frequency", icon: <FiRepeat />, text:"Frequency" },
        {href:"/plan/location", icon: <FaSearchLocation />, text:"Location" },
        {href:"/plan/operator", icon: <FaRegIdCard />, text:"Operator" },
        {href:"/plan/routeplan", icon: <FaRoute />, text:"Route" },
        {href:"/plan/specialop", icon: <FaBookmark />, text:"Special Op" },
        {href:"/plan/trailer", icon: <FaTrailer />, text:"Trailer" },
        {href:"/plan/vehicle", icon: <FaTruck />, text:"Vehicle" },
    ]
    
    const allowed = checkNavPermissions(userRole, navOptions)
    return (
        <NavBarComponent name={'Plan Menu'} navOptions={allowed} />
    );
};


export default PlanNav