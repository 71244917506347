import { Container, Card, Form, Row, Col, Button, Spinner, Badge, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, } from 'react';
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp, FaCaretLeft, FaCaretRight, FaMinus, FaPlus, FaPlusCircle } from 'react-icons/fa'
import Api from '../../utils/Api';
// import VerticalGanttChart from "../../components/VerticalGanttChart";
import { useAuth } from '../../context/auth';
import { DISPATCH_STATUSES, CONTACT_SERVICE_TYPES } from "../../context/appdata";
import { formatNameBadge } from "../../components/formatNameBadge";
import StatusBadge from "../../components/StatusBadge";
import DataDisplay from "../../components/DataDisplay";
import { Link } from "react-router-dom";
import { TIMEZONE_OPTIONS } from "../../context/appdata";
import AlertDisplay from "../../components/AlertDisplay";
import DispatchNav from "./Dispatch.nav";
import { getTimezoneCode } from "../../utils/Clock";

const date = new Date();

const defaultStart = date.toISOString().split('T')[0];

const initialFilters = {
    servicedate: defaultStart,
    dateprojection: '0'
}

const localStorageKey = '@mctms-dispatch-filters'

function Dispatch() {
    const { user, idToken } = useAuth();
    const [profile, setProfile] = useState();
    const [set, didSet] = useState();
    const [load, didLoad] = useState();
    const [filtersFetched, didFiltersFetch] = useState();
    const [dispatches, setDispatches] = useState([]);
    const [filters, setFilters] = useState(initialFilters);
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [toggle, setToggle] = useState(true);
    const [accordian, setAccordian] = useState(true);
    const [dropdown, setDropDown] = useState(true);
    const [assignments, setAssignments] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [domiciles, setDomiciles] = useState([]);
    const [dateoption, setSelectedDateOption] = useState(initialFilters.servicedate);
    const [dateprojection, setDateProjection] = useState(initialFilters.dateprojection);

    const handleDateUp = () => {
        const currentDate = new Date(dateoption);
        currentDate.setDate(currentDate.getDate() + 1);

        const newDate = currentDate.toISOString().slice(0, 10);
        setSelectedDateOption(newDate);
        handleFilterSelect('servicedate', newDate);
    }

    const handleDateDown = () => {
        const currentDate = new Date(dateoption);
        currentDate.setDate(currentDate.getDate() - 1);

        const newDate = currentDate.toISOString().slice(0, 10);
        setSelectedDateOption(newDate);
        handleFilterSelect('servicedate', newDate);
    }

    const handleDayIncrease = () => {
        const val = Number(dateprojection) + 1
        if (val <= 365) {
            setDateProjection(val);
            handleFilterSelect('dateprojection', val);
        }
    }

    const handleDayDecrease = () => {
        const val = Number(dateprojection) - 1
        if (val > 0) {
            setDateProjection(val);
            handleFilterSelect('dateprojection', val);
        }
        if (val === 0) {
            setDateProjection('0');
            handleFilterSelect('dateprojection', '0');
        }
    }

    const handleDateOption = (e) => {
        setSelectedDateOption(e.target.value);
        handleFilterSelect('servicedate', e.target.value);
    };

    const handleDateProjection = (e) => {
        setDateProjection(e.target.value);
        handleFilterSelect('dateprojection', e.target.value,);
    };

    const handleServiceClick = (servicetype) => {
        handleFilterSelectArr('servicetype', servicetype.name);
    };

    const handleContractClick = (contract) => {
        handleFilterSelectArr('routeplan.contract.name', contract.name);
    };

    const handleStatusClick = (status) => {
        handleFilterSelectArr('status', status.name);
    };

    const handleAssignmentClick = (assignment) => {
        handleFilterSelectArr('routeplan.assignment.name', assignment.name);
    };

    const handleDomicileClick = (domicile) => {
        handleFilterSelectArr('routeplan.domicile.name', domicile.name);
    };

    const handleFilterSelect = (filterKey, filterVal) => {
        const data = { ...filters, [filterKey]: filterVal }
        setFilters(data)
        handleSaveDefaultFilters(data)
        didSet(false);
    };

    const handleFilterSelectArr = (filterKey, filterVal) => {
        let updatedFilterValue;
        if (filters[filterKey] && Array.isArray(filters[filterKey])) {
            if (filters[filterKey].includes(filterVal)) {
                updatedFilterValue = filters[filterKey].filter(val => val !== filterVal);
            } else {
                updatedFilterValue = [...filters[filterKey], filterVal];
            }
        } else {
            updatedFilterValue = [filterVal];
        }
        const data = { ...filters, [filterKey]: updatedFilterValue };
        setFilters(data);
        handleSaveDefaultFilters(data);
        didSet(false);
    };

    const handleSaveDefaultFilters = (filters) => {
        localStorage.setItem(localStorageKey, JSON.stringify(filters))
    }

    const handleResetFilters = () => {
        localStorage.removeItem(localStorageKey)
        setFilters(initialFilters);
        setSelectedDateOption(initialFilters.servicedate)
        setDateProjection(initialFilters.dateprojection)
        didSet(false)
    }

    const handleProfileFilters = () => {
        const data = { ...filters, 'routeplan.domicile.name': profile.domicile.name };
        setFilters(data);
        handleSaveDefaultFilters(data);
        didSet(false);
    }

    const handleLoadDefaultFilters = () => {
        const filterjson = localStorage.getItem(localStorageKey);
        const filterdata = JSON.parse(filterjson) || initialFilters;
        setFilters(filterdata);
        const selectedDateoption = filterdata.servicedate || defaultStart;
        const selectedDateprojection = filterdata.dateprojection || 0;
        setSelectedDateOption(selectedDateoption);
        setDateProjection(selectedDateprojection);
        didLoad(true)
    }

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const p = await Api.get(`/access/profile/uid/${user.uid}`, idToken);
                setProfile(p)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'User request error' }]);
            }
        }

        const fetchFilters = async () => {

            try {
                const [assignments, contracts, domiciles] = await Promise.all([
                    Api.get('/assignments/live', idToken),
                    Api.get('/contracts/live', idToken),
                    Api.get('/locations/live/Domicile', idToken),
                ]);
                setAssignments(assignments);
                setContracts(contracts);
                setDomiciles(domiciles);
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }]);
            }
            didFiltersFetch(true);
        };

        const fetchData = async () => {
            try {
                const data = await Api.query('/dispatch', filters, idToken);
                const cleanDispatch = data.map(dispatch => {
                    return {
                        _id: dispatch?._id,
                        'Status': <><StatusBadge status={dispatch.status} /></>,
                        'Service Date': dispatch?.servicedate,
                        'Route': <div style={{ whiteSpace: 'nowrap' }}>{dispatch?.routeplan?.name}</div>,
                        'Stops': <>{
                            dispatch?.operations.map(stp => (<div style={{ whiteSpace: 'nowrap', fontFamily: 'monospace' }}> A: {stp?.arrive} | D: {stp?.depart} | {getTimezoneCode(stp?.timezone)} | {stp?.location?.name} </div>))
                        }</>,
                        'Service Type': dispatch?.servicetype,
                        // 'Assignment': dispatch?.routeplan?.assignment?.name,
                        // 'Domicile': dispatch?.routeplan?.domicile?.name,
                        'Crew': dispatch?.routeplan?.crew,
                        'Coverage': <>{dispatch?.coverage.map((driver, i) => <span key={i}>{formatNameBadge(driver)}</span>)}</>
                    }
                })
                setDispatches(cleanDispatch)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Dispatch request error' + JSON.stringify(error) }]);
            }
            didSet(true)
        }

        if (!profile) {
            fetchUserProfile();
        }

        if (!filtersFetched) {
            console.log('fetch filters')
            fetchFilters();
        }

        if (!load) {
            console.log('load defaults')
            handleLoadDefaultFilters();
        }

        if (!set) {
            console.log('fetch data')
            fetchData()
        }



    }, [load, set, filtersFetched, idToken, filters, alerts, user, profile])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1000) {
                setDropDown(true)
            }
            if (window.innerWidth > 1000) {
                setDropDown(false)
            }
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [dropdown]);

    const DispatchTable = () => {
        return (
            <Container fluid>
                <Card>
                    <Card.Header>
                        <Row className="justify-content-space-evenly align-items-center">
                            <Card.Title>Dispatch <Link className="float-end" to="/dispatch-form"><FaPlusCircle /> Form </Link>
                            </Card.Title>
                        </Row>
                    </Card.Header>
                    {alerts.length > 0 &&
                        <AlertDisplay alertState={alertState} />
                    }
                    {set ?
                        <>
                            <Card.Body>
                                {dispatches && dispatches.length > 0 ?
                                    (
                                        <>
                                            <DataDisplay
                                                dataSource={dispatches}
                                                lsKey={'@mctms-dispatch-display'}
                                                urlPath={'/dispatch/'}
                                                urlKey={'_id'}
                                                popKeys={['_id']}
                                            />
                                        </>
                                    )
                                    :
                                    <Row>
                                        <Col>
                                            <Card.Text>
                                                No dispatch records.
                                            </Card.Text>
                                        </Col>
                                    </Row>
                                }
                            </Card.Body>
                        </>
                        :
                        <Card.Body>
                            <Spinner />
                        </Card.Body>
                    }
                </Card>
            </Container>
        )
    }

    const DispatchFilter = () => {
        return (
            <Container fluid>
                <>
                    <Row>
                        <Col>
                            <h4>Filters</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant="secondary" onClick={handleProfileFilters} style={{ fontSize: '0.75em' }}>My Domicile</Button>
                        </Col>
                        <Col style={{ textAlign: 'right' }}>
                            <Button variant="warning" onClick={handleResetFilters} style={{ fontSize: '0.75em' }}>Reset</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: 'center' }}> Date </Col>
                    </Row>
                    <Row>
                        <Col xxl={{ span: 3, order: 1 }} lg={{ span: 3, order: 2 }} md={{ span: 3, order: 1 }} xs={{ span: 3, order: 1 }} style={{ margin: '0px' }}>
                            <Button variant={'secondary'} onClick={handleDateDown} ><FaCaretLeft /></Button>
                        </Col>
                        <Col xxl={{ span: 6, order: 2 }} lg={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} xs={{ span: 6, order: 2 }} style={{ margin: '0px', padding: '0px' }}>
                            <Form.Control type="date" value={dateoption} onChange={handleDateOption} style={{ margin: '0px' }} />
                        </Col>
                        <Col xxl={{ span: 3, order: 3 }} lg={{ span: 3, order: 3 }} md={{ span: 3, order: 3 }} xs={{ span: 3, order: 3 }} style={{ margin: '0px', textAlign: 'right' }}>
                            <Button variant={'secondary'} onClick={handleDateUp} ><FaCaretRight /></Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: 'center' }}> Days Out </Col>
                    </Row>
                    <Row>
                        <Col xxl={{ span: 3, order: 1 }} lg={{ span: 3, order: 2 }} md={{ span: 3, order: 1 }} xs={{ span: 3, order: 1 }} style={{ margin: '0px' }}>
                            <Button variant={'secondary'} onClick={handleDayDecrease} ><FaMinus /></Button>
                        </Col>
                        <Col xxl={{ span: 6, order: 2 }} lg={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} xs={{ span: 6, order: 2 }} style={{ margin: '0px', padding: '0px' }}>
                            <Form.Control type="number" value={dateprojection} min="0" max="365" step="1" onChange={handleDateProjection} readOnly />
                        </Col>
                        <Col xxl={{ span: 3, order: 3 }} lg={{ span: 3, order: 3 }} md={{ span: 3, order: 3 }} xs={{ span: 3, order: 3 }} style={{ margin: '0px', textAlign: 'right' }}>
                            <Button variant={'secondary'} onClick={handleDayIncrease} ><FaPlus /></Button>
                        </Col>
                    </Row>
                    Service
                    <Row>
                        {(filters['servicetype'] !== undefined && filters['servicetype'].length > 0) ?
                            CONTACT_SERVICE_TYPES.map((service, i) => (
                                filters['servicetype'].includes(service.name) ?
                                    <Col key={i}>
                                        <Badge style={{ cursor: 'pointer' }} onClick={() => handleServiceClick(service)} >
                                            {service.name}
                                        </Badge>
                                    </Col>
                                    :
                                    <Col key={i} style={{ opacity: 0.25 }}>
                                        <Badge style={{ cursor: 'pointer' }} onClick={() => handleServiceClick(service)} >
                                            {service.name}
                                        </Badge>
                                    </Col>
                            ))
                            :
                            CONTACT_SERVICE_TYPES.map((service, i) => (
                                <Col key={i}>
                                    <Badge style={{ cursor: 'pointer' }} onClick={() => handleServiceClick(service)} >
                                        {service.name}
                                    </Badge>
                                </Col>
                            ))
                        }
                    </Row>
                    Status
                    <Row>
                        {filters.status !== undefined && filters.status.length > 0 ?
                            DISPATCH_STATUSES.map((status, i) => (
                                filters.status.includes(status.name) ?
                                    <Col key={i} >
                                        <Badge bg={status.variant} style={{ cursor: 'pointer' }} onClick={() => handleStatusClick(status)}>{status.name}</Badge>
                                    </Col>
                                    :
                                    <Col key={i} style={{ opacity: 0.25 }}>
                                        <Badge bg={status.variant} style={{ cursor: 'pointer' }} onClick={() => handleStatusClick(status)}>{status.name}</Badge>
                                    </Col>
                            ))
                            :
                            DISPATCH_STATUSES.map((status, i) => (
                                <Col key={i} style={{ cursor: 'pointer' }} >
                                    <Badge bg={status.variant} style={{ cursor: 'pointer' }} onClick={() => handleStatusClick(status)}>{status.name}</Badge>
                                </Col>
                            ))
                        }
                    </Row>
                    Contracts
                    <Row>
                        {(filters['routeplan.contract.name'] !== undefined && filters['routeplan.contract.name'].length > 0) ?
                            contracts.map((contract, i) => (
                                filters['routeplan.contract.name'].includes(contract.name) ?
                                    <Col key={i}>
                                        <Badge style={{ cursor: 'pointer' }} onClick={() => handleContractClick(contract)} >
                                            {contract.name}
                                        </Badge>
                                    </Col>
                                    :
                                    <Col key={i} style={{ opacity: 0.25 }}>
                                        <Badge style={{ cursor: 'pointer' }} onClick={() => handleContractClick(contract)} >
                                            {contract.name}
                                        </Badge>
                                    </Col>
                            ))
                            :
                            contracts.map((contract, i) => (
                                <Col key={i}>
                                    <Badge style={{ cursor: 'pointer' }} onClick={() => handleContractClick(contract)} >
                                        {contract.name}
                                    </Badge>
                                </Col>
                            ))
                        }
                    </Row>
                    Assignments
                    <Row>
                        {filters['routeplan.assignment.name'] !== undefined && filters['routeplan.assignment.name'].length > 0 ?
                            assignments.map((assignment, i) => (
                                filters['routeplan.assignment.name'].includes(assignment.name) ?
                                    <Col key={i}>
                                        <Badge style={{ cursor: 'pointer' }} onClick={() => handleAssignmentClick(assignment)} >
                                            {assignment.name}
                                        </Badge>
                                    </Col>
                                    :
                                    <Col key={i} style={{ opacity: 0.25 }}>
                                        <Badge style={{ cursor: 'pointer' }} onClick={() => handleAssignmentClick(assignment)} >
                                            {assignment.name}
                                        </Badge>
                                    </Col>
                            ))
                            :
                            assignments.map((assignment, i) => (
                                <Col key={i}>
                                    <Badge style={{ cursor: 'pointer' }} onClick={() => handleAssignmentClick(assignment)} >
                                        {assignment.name}
                                    </Badge>
                                </Col>
                            ))
                        }
                    </Row>
                    Sites | Domicile Locations
                    <Row>
                        {filters['routeplan.domicile.name'] !== undefined && filters['routeplan.domicile.name'].length > 0 ?
                            domiciles.map((domicile, i) => (
                                filters['routeplan.domicile.name'].includes(domicile.name) ?
                                    <Col key={i}>
                                        <Badge style={{ cursor: 'pointer' }} onClick={() => handleDomicileClick(domicile)} >
                                            {domicile.name}
                                        </Badge>
                                    </Col>
                                    :
                                    <Col key={i} style={{ opacity: 0.25 }}>
                                        <Badge style={{ cursor: 'pointer' }} onClick={() => handleDomicileClick(domicile)} >
                                            {domicile.name}
                                        </Badge>
                                    </Col>
                            ))
                            :
                            domiciles.map((domicile, i) => (
                                <Col key={i}>
                                    <Badge style={{ cursor: 'pointer' }} onClick={() => handleDomicileClick(domicile)} >
                                        {domicile.name}
                                    </Badge>
                                </Col>
                            ))
                        }
                    </Row>
                </>
            </Container>
        )
    }

    const DispatchAppliedFilters = () => {
        return (
            <Container fluid style={{ marginBottom: '1em' }}>
                <Card>
                    <Card.Header>
                        <Card.Title>Applied Filters</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            {Object.entries(filters).map(([key, value]) => {
                                const labelLookup = {
                                    'servicedate': 'Date',
                                    'dateprojection': 'Days Out',
                                    'servicetype': 'Service',
                                    'status': 'Status',
                                    'routeplan.contract.name': 'Contracts',
                                    'routeplan.assignment.name': 'Assignments',
                                    'routeplan.domicile.name': 'Domiciles'
                                };
                                const label = labelLookup[key];
                                return label ? (
                                    <Col key={key} xs={12} sm={6} md={4} lg={3} style={{ marginBottom: '0.5em' }}>
                                        <Card.Subtitle>{label}</Card.Subtitle>{Array.isArray(value) ? value.join(', ') : value}
                                    </Col>
                                ) : null;
                            })}
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        )
    }

    const ToggleButton = () => {
        return (
            <div>
                <button onClick={() => setToggle(!toggle)}
                    style={{
                        display: 'absolute',
                        fontSize: '12px',
                        marginLeft: '-10px',
                        marginBottom: '1em',
                        borderRadius: '50%',
                        borderWidth: '1px',
                        borderColor: '#333',
                        backgroundColor: '#fff'
                    }}
                >{toggle ? <FaArrowRight /> : <FaArrowLeft />}</button>
            </div>
        )
    }

    const ToggleButtonUpDown = () => {
        return (
            <>
                <button onClick={() => setAccordian(!accordian)}
                    style={{
                        display: 'absolute',
                        fontSize: '12px',
                        borderRadius: '50%',
                        borderWidth: '1px',
                        borderColor: '#333',
                        backgroundColor: '#fff'
                    }}
                >{accordian ? <FaArrowUp /> : <FaArrowDown />}</button> Filters
            </>
        )
    }

    return (
        <Container fluid style={{ margin: '0', padding: '0', }}>
            {dropdown ?
                <Col style={{ padding: '0', margin: '0', }}>
                    <DispatchNav />
                    <DispatchAppliedFilters />
                    {accordian &&
                        <Container fluid>
                            <Card>
                                <Card.Body>
                                    <DispatchFilter />
                                </Card.Body>
                            </Card>
                        </Container>
                    }
                    <Row style={{ margin: '1em' }}>
                        <Col>
                            <ToggleButtonUpDown />
                        </Col>
                    </Row>
                    <DispatchTable />
                </Col>
                :
                <Row style={{ margin: '0', padding: '0' }}>
                    {toggle ?
                        <>
                            <Col xs={{ span: 12, order: 2 }} md={{ span: 9, order: 1 }} lg={{ span: 10, order: 1 }} style={{ padding: "0px", margin: "0px" }}>
                                <DispatchNav />
                                <DispatchAppliedFilters />
                                <DispatchTable />
                            </Col>
                            <Col xs={{ span: 12, order: 1 }} md={{ span: 3, order: 2 }} lg={{ span: 2, order: 2 }}
                                style={{ padding: "0px", margin: "0px", backgroundColor: '#f8f9fa', minHeight: '93vh' }}>
                                <Row>
                                    <Col>
                                        <ToggleButton />
                                    </Col>
                                </Row>
                                {filtersFetched ?
                                    toggle && <DispatchFilter />
                                    :
                                    <Container>
                                        <Spinner />
                                    </Container>
                                }
                            </Col>
                        </>
                        :
                        <>
                            <Col xs={12} style={{ width: '98%', padding: "0px", margin: "0px", overflowY: 'auto' }}>
                                <DispatchNav />
                                <DispatchAppliedFilters />
                                <DispatchTable />
                            </Col>
                            <Col xs={0} style={{ width: '2%', padding: "0px", margin: "0px", backgroundColor: '#f8f9fa', borderLeft: '1px solid #ccc', minHeight: '93vh' }}>
                                <Row>
                                    <Col>
                                        <ToggleButton />
                                        <div style={{
                                            writingMode: 'vertical-lr',
                                            margin: 'auto'
                                        }}>
                                            <b>Filters</b>
                                        </div>
                                    </Col>
                                </Row>
                                {filtersFetched ?
                                    toggle && <DispatchFilter />
                                    :
                                    <Container>
                                        <Spinner />
                                    </Container>
                                }
                            </Col>
                        </>
                    }
                </Row>
            }
        </Container>
    );
}

export default Dispatch;
