import React from "react";
import { Container, Card,} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

function DispatchSettings() {
    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header>Dispatch Settings</Card.Header>
                <Card.Body>
                    <Card.Text>
                        Coming soon...
                    </Card.Text>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default DispatchSettings;