import { Container, Card, Row, Col, Form, Button, Spinner, Badge, Table } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import Api from "../../utils/Api";
import { useParams } from 'react-router-dom';
import { useAuth } from '../../context/auth';
// import EDI204Component from "./EDI204.component";
// import EDI990Component from "./EDI990.component";
// import EDI214Component from "./EDI214.component";
// import EDI210Component from "./EDI210.component";
// import {
//     EDI_214_SHIPMENT_STATUS_CODES_MAIL_PICKUP_SPECIFIC,
//     EDI_214_SHIPMENT_STATUS_CODES_MAIL_DELIVERY_SPECIFIC
// } from '../../context/klienschmidtdata';
import { useSettings } from "../../context/settings";
import LoadTenderOperator from "./LoadTenderOperator.component";
import { formatDateStrToMMDDYYHHMM, toRFC3339 } from "../../utils/DateTimeFormat.helper";
import AlertDisplay from "../../components/AlertDisplay";
import { findUSPSNASSCode, formatUSPSDateTime, findUSPSAddress, findUSPSNassCodeTimezoneIANA } from "../../context/uspsdata";
import { BsChatRightText } from "react-icons/bs";
import ConfirmationModal from '../../components/ConfirmationModal'
import { MdCircle, MdHideSource } from "react-icons/md";
import { FaCheckCircle, FaInfo, FaMinus, FaPlus, FaSignal, FaStopwatch, FaSync } from "react-icons/fa";
import DriverHOSLog from "../../components/DriverHOSLog";
import GoogleMapPlotRoute from "../../components/GoogleMapPlotRoute";
import PrintPDF from "../../components/PrintPDF";
import GeofenceDisplay from "../../components/GeofenceDisplay"
import { Chart } from 'react-google-charts';
import LogisticsLoading from "../../components/LogisticsLoading";

function LoadTenderUpdate() {
    const { id } = useParams();
    const { user, idToken } = useAuth();
    const { settings } = useSettings();
    const [set, didSet] = useState();
    const [alerts, setAlerts] = useState([])
    const alertState = { alerts, setAlerts }
    const [geofences, setGeofences] = useState([]);
    const geofenceState = { geofences, setGeofences };
    const [circleGeofence, setCircleGeofence] = useState([]);
    const circleGeofenceState = { circleGeofence, setCircleGeofence };
    // const [togglejson, setToggleJSON] = useState();
    // const [show, setShow] = useState(false);
    // const [showrow, setShowRow] = useState();

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    const [fetchTelematics, setFetchTelematics] = useState();
    const [edi204, setEDI204] = useState();
    // const [edi990s, setEDI990s] = useState();
    const [edi214s, setEDI214s] = useState();
    // const [edi210s, setEDI210s] = useState();
    const [searchTerm, setSearchTerm] = useState('');
    const [samsaradrivers, setSamsaraDrivers] = useState();
    const [samsaradriversbylocation, setSamsaraDriversByLocation] = useState({});
    const [operatorlocation, setOperatorLocation] = useState('All');
    const [operatoroption, setOperatorOption] = useState();
    const [coverageoption, setCoverageOption] = useState();
    const [removeoption, setRemoveOption] = useState();
    // const [eventoption, setEventOption] = useState();


    const [showModal, setShowModal] = useState();

    const [newEDI214, setNewEDI214] = useState({});
    const [newEDI214s, setNewEDI214s] = useState([])

    const [driverassign, setDriverAssign] = useState(true);
    const [driverremove, setDriverRemove] = useState(false);

    const [hoslogs, setHOSLogs] = useState();

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const [allowForcedStopUpdate, setAllowForcedStopUpdate] = useState()

    const [searchTolerance, setSearchTolerance] = useState(1);
    const [appointmentTolerance, setAppointmentTolerance] = useState(1);
    const [telematicsAudit, setTelematicsAudit] = useState([]);
    const [showTelematics, setShowTelematics] = useState(true);
    const [showEdiMessaging, setShowEdiMessaging] = useState(true);

    function setEdi214ShipperStatusUpdate(stop, key, value) {
        const timeZoneAbbreviations = {
            "America/New_York": "ET",
            "America/Chicago": "CT",
            "America/Denver": "MT",
            "America/Los_Angeles": "PT"
        };
        const tzcode = timeZoneAbbreviations[settings.timezone];

        if (!newEDI214[stop]) {
            newEDI214[stop] = {};
        }

        newEDI214[stop][key] = value;
        newEDI214[stop]['TimeZone'] = tzcode;

        // Update the state
        setNewEDI214(newEDI214);

        // Update the state array
        setNewEDI214s([...newEDI214s, newEDI214]);
    }

    const [showStopDetail, setShowStopDetail] = useState([]);

    const [showGoogleMaps, setShowGoogleMaps] = useState();

    // async function allowStopUpdate(stop) {
    //     const firstStopComplete = 'AF'
    //     const followingStopComplete = 'CD'
    //     const edimsgs = filterEDI214(stop)
    //     if (Number(stop) === Number("1")) {  // check first stop
    //         const hasMsg = edimsgs.some(msg => Number(msg.ApptNumber) === Number(stop) && msg.StatusCode === firstStopComplete);
    //         return hasMsg ? false : true;
    //     } else { // check all other stops
    //         // previous stop closed out 
    //         const prevStop = Number(stop) - 1
    //         const prevMsgs = filterEDI214(prevStop)
    //         const prevMsg = prevMsgs.some(msg => Number(msg.ApptNumber) === Number(prevStop) && (msg.StatusCode === firstStopComplete || msg.StatusCode === followingStopComplete));
    //         // this stop closed out
    //         const hasMsg = edimsgs.some(msg => Number(msg.ApptNumber) === Number(stop) && msg.StatusCode === followingStopComplete);
    //         if (!prevMsg) {
    //             return false
    //         } else {
    //             return hasMsg ? false : true;
    //         }
    //     }
    // }

    function stopETA(stop) {
        const pickupArrived = 'AA';
        const deliveryArrived = 'AG';
        const stopMsgs = filterEDI214(stop);
        const pickup = stopMsgs.some(msg => msg.StatusCode === pickupArrived);
        const delivery = stopMsgs.some(msg => msg.StatusCode === deliveryArrived);
        return pickup || delivery;
    }

    function stopArrived(stop) {
        const pickupArrived = 'X3';
        const deliveryArrived = 'X1';
        const stopMsgs = filterEDI214(stop);
        const pickup = stopMsgs.some(msg => msg.StatusCode === pickupArrived);
        const delivery = stopMsgs.some(msg => msg.StatusCode === deliveryArrived);
        return pickup || delivery;
    }

    function stopDeparted(stop) {
        const pickupDeparted = 'AF';
        const deliveryDeparted = 'CD';
        const stopMsgs = filterEDI214(stop);
        const pickup = stopMsgs.some(msg => msg.StatusCode === pickupDeparted);
        const delivery = stopMsgs.some(msg => msg.StatusCode === deliveryDeparted);
        return pickup || delivery;
    }

    function allowCompleteTender() {
        const pickupComplete = 'AF';
        const deliveriesComplete = 'CD';
        const passed = [];
        for (let i = 0; i < edi204?.newest?.Stops.length; i++) {
            const stopnum = edi204?.newest?.Stops[i]?.StopNum
            const e214s = filterEDI214(stopnum)
            if (stopnum === '1') {
                const stopPickupComplete = e214s.some(msg => Number(msg.ApptNumber) === Number(stopnum) && (msg.StatusCode === pickupComplete));
                passed.push(stopPickupComplete)
            } else {
                const stopDeliveryComplete = e214s.some(msg => Number(msg.ApptNumber) === Number(stopnum) && (msg.StatusCode === deliveriesComplete));
                passed.push(stopDeliveryComplete)
            }
        }
        const completed = !passed.some(val => val === false)
        return completed;
    }

    function filterEDI214(stopNum) {
        const data = edi214s.filter((msg) => Number(msg.ApptNumber) === Number(stopNum));
        return data;
    }

    // todo : move to backend, only post update object, stop, and 204._id
    async function handleStatusUpdate(stopNum) {
        stopNum = Number(stopNum)
        try {
            setAlerts([...alerts, { variant: 'success', message: `Updating Stop ${stopNum}...` }])
            // 204 stops start count at 1
            let new214;
            let shipperstop, consigneestop, shipperindex, consigneeindex;
            if (stopNum === 1) {
                shipperstop = stopNum
                consigneestop = stopNum + 1
                shipperindex = stopNum - 1
                consigneeindex = stopNum
                new214 = {
                    "TransactionId": 214,
                    "InternalTrackingNum": edi204?.original?.InternalTrackingNum,
                    "Customer": edi204?.original?.Customer,
                    "CustomerISA": edi204?.original?.CustomerISA,
                    "SCAC": edi204?.original?.SCAC,
                    "ShipmentId": edi204?.original?.ShipmentId,
                    "ApptNumber": shipperstop,
                    "StatusCode": newEDI214[stopNum].StatusCode,
                    "StatusDate": newEDI214[stopNum].StatusDate,
                    "TimeZone": newEDI214[stopNum].TimeZone,
                    "City": edi204?.original?.BillTo.City,
                    "State": edi204?.original?.BillTo.State,
                    "Shipper": {
                        "StopNum": shipperstop,
                        "Name": edi204.newest.Stops[shipperindex].Name,
                        "Address1": edi204.newest.Stops[shipperindex].Address1,
                        "Address2": edi204.newest.Stops[shipperindex].Address2,
                        "City": edi204.newest.Stops[shipperindex].City,
                        "State": edi204.newest.Stops[shipperindex].State,
                        "Zip": edi204.newest.Stops[shipperindex].Zip,
                        "PickUp": true,
                        "DropOff": false,
                        "Reason": edi204.newest.Stops[shipperindex].Reason,
                    },
                    "Consignee": {
                        "StopNum": consigneestop,
                        "Name": edi204.newest.Stops[consigneeindex].Name,
                        "Address1": edi204.newest.Stops[consigneeindex].Address1,
                        "Address2": edi204.newest.Stops[consigneeindex].Address2,
                        "City": edi204.newest.Stops[consigneeindex].City,
                        "State": edi204.newest.Stops[consigneeindex].State,
                        "Zip": edi204.newest.Stops[consigneeindex].Zip,
                        "PickUp": false,
                        "DropOff": true,
                        "Reason": edi204.newest.Stops[consigneeindex].Reason,
                    }
                }
            } else if (stopNum > 1) {
                shipperstop = 1
                consigneestop = stopNum
                shipperindex = 0
                consigneeindex = stopNum - 1
                new214 = {
                    "TransactionId": 214,
                    "InternalTrackingNum": edi204?.original?.InternalTrackingNum,
                    "Customer": edi204?.original?.Customer,
                    "CustomerISA": edi204?.original?.CustomerISA,
                    "SCAC": edi204?.original?.SCAC,
                    "ShipmentId": edi204?.original?.ShipmentId,
                    "ApptNumber": consigneestop,
                    "StatusCode": newEDI214[stopNum].StatusCode,
                    "StatusDate": newEDI214[stopNum].StatusDate,
                    "TimeZone": newEDI214[stopNum].TimeZone,
                    "City": edi204?.original?.BillTo.City,
                    "State": edi204?.original?.BillTo.State,
                    "Shipper": {
                        "StopNum": shipperstop,
                        "Name": edi204.newest.Stops[shipperindex].Name,
                        "Address1": edi204.newest.Stops[shipperindex].Address1,
                        "Address2": edi204.newest.Stops[shipperindex].Address2,
                        "City": edi204.newest.Stops[shipperindex].City,
                        "State": edi204.newest.Stops[shipperindex].State,
                        "Zip": edi204.newest.Stops[shipperindex].Zip,
                        "PickUp": true,
                        "DropOff": false,
                        "Reason": edi204.newest.Stops[shipperindex].Reason,
                    },
                    "Consignee": {
                        "StopNum": consigneestop,
                        "Name": edi204.newest.Stops[consigneeindex].Name,
                        "Address1": edi204.newest.Stops[consigneeindex].Address1,
                        "Address2": edi204.newest.Stops[consigneeindex].Address2,
                        "City": edi204.newest.Stops[consigneeindex].City,
                        "State": edi204.newest.Stops[consigneeindex].State,
                        "Zip": edi204.newest.Stops[consigneeindex].Zip,
                        "PickUp": false,
                        "DropOff": true,
                        "Reason": edi204.newest.Stops[consigneeindex].Reason,
                    }
                }
            }
            await Api.post('/edi214', new214, idToken)
            setAlerts([...alerts, { variant: 'success', message: `Successfully update Stop ${stopNum}` }])
            didSet(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: `Stop not updated. ${error.error}` }])
        }
    }

    // async function handleEvent() {
    // }

    // async function handleEventUpdate() {
    //     console.log(eventoption)
    // }

    async function handleLoadTenderCompleted() {
        try {
            const edi204Update = {
                _id: edi204?.original?._id,
                loadtenderLifeCycleStatus: 'Completed',
            }
            await Api.patch(`/edi204/${edi204?.original?._id}`, edi204Update, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully updated Load Tender' }])
            didSet(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Load Tender update error' }])
        }
        didSet(false)
    }

    async function handleLoadTenderReactivate() {
        try {
            const edi204Update = {
                _id: edi204?.original?._id,
                loadtenderLifeCycleStatus: 'Accepted',
            }
            await Api.patch(`/edi204/${edi204?.original?._id}`, edi204Update, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully updated Load Tender' }])
            didSet(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Load Tender update error' }])
        }
        didSet(false)
    }

    async function handleOperatorAssignment() {
        try {
            if (!operatoroption && !coverageoption) {
                throw new Error('To assign a driver, please select a Driver and Coverage Reason')
            }
            if (!operatoroption) {
                throw new Error('To assign a driver, please select a Driver')
            }
            if (!coverageoption) {
                throw new Error('To assign a driver, please select a Coverage Reason')
            }
            const edi204Update = {
                _id: edi204?.original?._id,
                operator: JSON.parse(operatoroption),
                operatortype: 'samsara',
                assignedby: user.email,
                assignedreason: coverageoption
            }
            await Api.patch(`/edi204/${edi204?.original?._id}/operator-assign`, edi204Update, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully assigned operator to Load Tender' }])
            setFetchTelematics(true)
            didSet(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Operatored assignment error' }])
        }
    }

    async function handleOperatorRemoval() {
        try {
            if (!operatoroption && !removeoption) {
                throw new Error('To remove a driver, please select a Driver and Remove Reason')
            }
            if (!operatoroption) {
                throw new Error('To remove a driver, please select a Driver')
            }
            if (!removeoption) {
                throw new Error('To remove a driver, please select a Remove Reason')
            }
            const edi204Update = {
                _id: edi204?.original?._id,
                operator: JSON.parse(operatoroption),
                operatortype: 'samsara',
                removedby: user.email,
                removedreason: removeoption
            }

            await Api.patch(`/edi204/${edi204?.original?._id}/operator-remove`, edi204Update, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully removed operator from Load Tender' }])
            setFetchTelematics(true)
            didSet(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Operatored remove error' }])
        }
    }

    const handleLoadTenderReject = async () => {
        try {
            const date = new Date();
            const utcTimestamp = date.toISOString();

            const edi204Update = {
                '_id': edi204?.original?._id,
                'loadtenderLifeCycleStatus': 'Rejected',
            }

            const edi990 = {
                "TransactionId": 990,
                "InternalTrackingNum": edi204?.original?.InternalTrackingNum,
                "Customer": edi204?.original?.Customer,
                "CustomerISA": edi204?.original?.CustomerISA,
                "SCAC": edi204?.original?.SCAC,
                "Response": "Rejected",
                "ShipmentId": edi204?.original?.ShipmentId,
                "ProNumber": edi204?.original?.ProNumber,
                "TxnNumber": edi204?.original?.TxnNumber,
                "Timestamp": utcTimestamp,
                "TimeZone": "UT",
                "Note": "",
                "RejectionReason": "",
                "References": ""
            }

            Promise.all([
                await Api.post('/edi990', edi990, idToken),
                await Api.patch(`/edi204/${edi204?.original?._id}`, edi204Update, idToken)
            ]).then(() => {
                setAlerts([...alerts, { variant: 'success', message: 'Load tender successfully rejected.', }])
            }).catch(err => {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured', }])
            })
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured', }])
        } finally {
            didSet(false)
        }
    }

    function convertMillisecondsToHoursMinutes(durationInMilliseconds) {
        const hours = Math.floor(durationInMilliseconds / 3600000);
        const minutes = Math.floor((durationInMilliseconds % 3600000) / 60000);
        return `${hours} hr ${minutes} min`;
    }

    const renderStopNumCodesMessages = (StopNum, Code, edi214s) => {
        const stopNumMsg = edi214s.filter(edi214 => Number(edi214.ApptNumber) === Number(StopNum) && edi214.StatusCode === Code)
        return (
            <div>
                {stopNumMsg.length > 0 ?
                    stopNumMsg.map(msg => (
                        <div>
                            {formatDateStrToMMDDYYHHMM(msg.StatusDate)} {msg.TimeZone} { }
                        </div>
                    ))
                    :
                    `No ${Code} update messages. Send an update.`
                }
            </div>
        )
    }

    function stopCompleted(stopnum) {
        if (stopnum === '1') {
            const exists = edi214s.some(msg => Number(msg.ApptNumber) === Number(stopnum) && msg.StatusCode === 'AF')
            return exists
        } else {
            const exists = edi214s.some(msg => Number(msg.ApptNumber) === Number(stopnum) && msg.StatusCode === 'CD')
            return exists
        }
    }

    // const toggleShowStopDetail = (stopNum) => {
    //     if (showStopDetail.includes(stopNum)) {
    //         setShowStopDetail(showStopDetail.filter(num => num !== stopNum));
    //     } else {
    //         setShowStopDetail([...showStopDetail, stopNum]);
    //     }
    // };

    async function extractSamsaraHOSWaypoints(data) {
        const waypoints = [];

        for (const item of data) {
            try {
                const waypoint = {
                    latitude: item.logRecordedLocation.latitude,
                    longitude: item.logRecordedLocation.longitude,
                    name: item.hosStatusType,
                };
                waypoints.push(waypoint);
            } catch (error) {
                continue;
            }
        }
        return waypoints;
    }

    const [hosWaypointsData, setHosWaypointsData] = useState([]);

    const [loadtenderWaypoints, setLoadTenderWaypoints] = useState([]);

    const [contractTrips, setContractTrips] = useState();
    const extractWaypoints = useCallback(async (data) => {
        async function getLatLngFromAddress(address) {
            try {
                const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
                const response = await fetch(url);

                if (!response.ok) {
                    throw new Error(`Geocoding request failed with status ${response.status}`);
                }

                const data = await response.json();

                if (data.results && data.results.length > 0) {
                    const location = data.results[0].geometry.location;
                    return {
                        latitude: location.lat,
                        longitude: location.lng,
                    };
                } else {
                    console.error('Geocoding request did not return valid data.');
                    return null;
                }
            } catch (error) {
                console.error('Error while fetching geocoding data:', error);
                return null;
            }
        }

        const waypoints = [];

        for (const item of data) {
            try {
                const address = findUSPSAddress(item.Name);
                const { latitude, longitude } = await getLatLngFromAddress(address);
                const waypoint = {
                    latitude: latitude,
                    longitude: longitude,
                    name: `${findUSPSNASSCode(item.Name)}`,
                };
                waypoints.push(waypoint);
            } catch (error) {
                continue;
            }
        }
        return waypoints;
    }, []);

    const [goodaudit, setGoodAudit] = useState('');
    const [pointoffailure, setPointOfFailure] = useState('');

    async function upsertTelematicsReport() {
        try {
            const telematicsReportUpdate = {
                loadtenderId: edi204?.original?._id,
                shipmentId: edi204?.original?.ShipmentId,
                searchTolerance: searchTolerance,
                appointmentTolerance: appointmentTolerance,
                goodaudit: goodaudit,
                pointoffailure: pointoffailure,
                edi204: edi204.newest,
                telematics: telematicsAudit,
                userId: user.uid,
                email: user.email
            }
            await Api.patch(`/telematics/loadtender/reports`, telematicsReportUpdate, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Telematics report upsert.' }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: JSON.stringify(error.error) }])
        }
    }

    const filteredSamsaraDrivers = useMemo(() => {
        if (samsaradrivers && samsaradrivers.length > 0) {
            return samsaradrivers.filter(item =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        } else {
            return [];
        }
    }, [samsaradrivers, searchTerm]);


    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowSize]);

    useEffect(() => {
        const fetchWaypoints = async () => {
            if (!telematicsAudit) return;
            const data = await Promise.all(
                telematicsAudit.map(report => extractSamsaraHOSWaypoints(report?.hoslogs))
            );
            setHosWaypointsData(data);
        };
        fetchWaypoints();
    }, [telematicsAudit]);



    useEffect(() => {
        const fetchBasedOnToleranceChange = async () => {
            try {
                const telematics = await Api.get(`/telematics/audit-loadtender/${id}/${searchTolerance}/${appointmentTolerance}`, idToken);
                setTelematicsAudit(telematics);
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured refetching telematics audit.' }])
            }
        }

        fetchBasedOnToleranceChange();

    }, [id, idToken, alerts, appointmentTolerance, searchTolerance])

    useEffect(() => {
        // console.log('MOUNT MAIN')
        const fetchData = async () => {
            // console.log('Fetch Data')
            try {
                const e204data = await Api.get(`/edi204/${id}`, idToken);
                if (!e204data || e204data.length === 0) {
                    throw new Error('No e204')
                }
                const e204 = e204data[0]
                setEDI204(e204)

                const waypoints = await extractWaypoints(e204?.newest?.Stops)
                setLoadTenderWaypoints(waypoints);
                const extractedReferences = e204?.newest?.Stops.map(via => via?.References.map(referance => referance?.Identifier));
                const extractedValues = extractUniqueValues(extractedReferences)
                const extractedData = extractPrefixes(extractedValues)
                const contractTrips = [...new Set(extractedData)];
                setContractTrips(contractTrips)
                const params = new URLSearchParams();
                params.append('ShipmentId', e204?.original?.ShipmentId);


                setEDI214s(e204?.newest?.edi214Data)

                const driversdata = await Api.get(`/samsara/drivers-active`, idToken);
                const drivers = driversdata.data;

                const groupedData = drivers.reduce((groups, record) => {
                    const homeTerminalName = record.carrierSettings.homeTerminalName;
                    if (!groups[homeTerminalName]) {
                        groups[homeTerminalName] = [];
                    }
                    groups[homeTerminalName].push(record);
                    return groups;
                }, {});

                const sortedKeys = Object.keys(groupedData).sort();

                const sortedGroupedData = {};
                for (const key of sortedKeys) {
                    sortedGroupedData[key] = groupedData[key];
                }

                setSamsaraDrivers(drivers)
                setSamsaraDriversByLocation(sortedGroupedData)
                if (e204?.original?.operators && e204?.original?.operators.length > 0) {
                    const ids = e204?.original?.operators.map(operator => operator.id).join(',');
                    const startdate = toRFC3339(e204?.newest?.Stops[0].RequestedDate);
                    const enddate = toRFC3339(e204?.newest?.Stops[e204?.newest?.Stops.length - 1].LastRequestedDate);
                    const driverhoslogs = await Api.get(`/samsara/drivers/${ids}/logs/${startdate}/${enddate}`, idToken);
                    const logdata = driverhoslogs.data;
                    setHOSLogs(logdata)
                }
            } catch (err) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured while fetching Load Tender.' + JSON.stringify(err) }])
            }
            didSet(true)
            setFetchTelematics(true)
        }

        if (!set) {
            fetchData();
        }

    }, [idToken, id, set, alerts, extractWaypoints]);

    useEffect(() => {
        const fetchLoadTenderTelematics = async () => {
            // console.log('Fetch Telematics')
            try {
                const telematicsReport = await Api.get(`/telematics/loadtender/report/${id}`, idToken);
                if (telematicsReport) {
                    setTelematicsAudit(telematicsReport.telematics);
                    setSearchTolerance(telematicsReport.searchTolerance)
                    setAppointmentTolerance(telematicsReport.appointmentTolerance)
                    setGoodAudit(telematicsReport.goodaudit)
                    setPointOfFailure(telematicsReport.pointoffailure)
                } else {
                    // const telematics = await Api.get(`/telematics/audit-loadtender/${id}/${searchTolerance}/${appointmentTolerance}`, idToken);
                    // console.log(telematics)
                }

            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured refetching telematics audit.' }])
            }
            setFetchTelematics(false)
        }

        if (fetchTelematics) {
            fetchLoadTenderTelematics()
        }

    }, [idToken, id, fetchTelematics, alerts, appointmentTolerance, searchTolerance])

    function isNumber(value) {
        return typeof value === 'number' && !isNaN(value);
    }

    function loadtenderStopPunctualityPieChart(edi204) {
        const newestEDI204 = edi204?.newest
        const data = []
        const headers = ["Appoitments", "Ct"]
        data.push(headers)
        let missing = 0, early = 0, late = 0;
        newestEDI204?.loadtenderTelematicsData.forEach(report => {
            report?.telematics.forEach(record => {
                record?.linearizeLoadTenderAndLogs.forEach(via => {
                    const arriveDiff = Number(via.eldOnPremiseArriveDiffMin);
                    const departDiff = Number(via.eldOnPremiseDepartDiffMin);
                    // console.log(isNumber(arriveDiff))
                    // a
                    if (arriveDiff >= 0) early++;
                    if (arriveDiff < 0) late++;
                    if (isNumber(arriveDiff) === false) missing++;

                    // d
                    if (departDiff >= 0) early++;
                    if (departDiff < 0) late++;
                    if (isNumber(departDiff) === false) missing++;
                });
            });
        });
        data.push(['Missing', missing])
        data.push(['Early', early])
        data.push(['Late', late])
        return data
    }

    function loadtenderPunctualityScatterChart(edi204) {
        const newestEDI204 = edi204?.newest
        const data = []
        const headers = ["Arrival", "Arrival, Departure"]
        const goal = [0, 0]
        data.push(headers, goal)
        // console.log(newestEDI204?.loadtenderTelematicsData)
        newestEDI204?.loadtenderTelematicsData.map(report =>
            report?.telematics.map(record => record?.linearizeLoadTenderAndLogs.forEach(via => {
                const arrive = Number(via.eldOnPremiseArriveDiffMin);
                const depart = Number(via.eldOnPremiseDepartDiffMin);
                data.push([arrive, depart]);
            })))
        // console.log(data)
        return data
    }

    function extractUniqueValues(arr) {
        const uniqueValues = new Set();

        arr.forEach(subArray => {
            subArray.forEach(value => {
                uniqueValues.add(value);
            });
        });

        return Array.from(uniqueValues);
    }

    function extractPrefixes(arr) {
        return arr.map(item => {
            const parts = item.split("_"); // Split the string into parts using the underscore character
            if (parts.length >= 2) {
                return parts.slice(0, 2).join("_"); // Combine the first two parts with an underscore
            } else {
                return item; // If there are fewer than 2 parts, return the original item
            }
        });
    }

    function formatZuluToTZ(utcTime, ianaTz) {

        const date = new Date(utcTime);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
            timeZone: ianaTz,
            hourCycle: 'h23'
        };

        const formatter = new Intl.DateTimeFormat('en-US', options);
        const formatted = formatter.format(date);
        return formatted;
    }

    const awaitingUpdates = () => {
        return (
            <b style={{ color: '#fbb70e' }}>
                <FaStopwatch />
            </b>
        )
    }

    const ediStopCompleted = () => {
        return (
            <b style={{ color: '#488e5c ' }}>
                <FaCheckCircle />
            </b>
        )
    }


    const renderStatusCodeSection = (stopNum, statusCode, edi214s) => {
        const isAccepted = edi204?.original?.loadtenderLifeCycleStatus === 'Accepted';
        const isAllowedToUpdate = allowForcedStopUpdate === stopNum;
        const currentStatus = newEDI214[stopNum]?.StatusCode;
        const statusDate = newEDI214[stopNum]?.StatusDate;

        return (
            <>
                {statusCode} : {(isAccepted && isAllowedToUpdate) ? (
                    <>
                        {renderStopNumCodesMessages(stopNum, statusCode, edi214s)}
                        <Form.Control
                            type="datetime-local"
                            value={currentStatus === statusCode && statusDate}
                            onChange={e => {
                                setEdi214ShipperStatusUpdate(stopNum, 'StatusCode', statusCode);
                                setEdi214ShipperStatusUpdate(stopNum, 'StatusDate', e.target.value);
                            }} /> {settings.timezone}
                    </>
                ) : (
                    <>
                        {renderStopNumCodesMessages(stopNum, statusCode, edi214s)}
                    </>
                )}

                {isAccepted && isAllowedToUpdate && currentStatus && currentStatus === statusCode && (
                    <Button variant="primary" onClick={() => handleStatusUpdate(stopNum)}>
                        Stop {stopNum} Update - {currentStatus}
                    </Button>
                )}
            </>
        );
    };


    return (
        <Container fluid>
            <AlertDisplay alertState={alertState} />
            <React.Fragment>
                {(set) ?
                    <>
                        {edi204?.original?.loadtenderLifeCycleStatus === 'pending' &&
                            <>
                                <h3><a href={`/loadtender/response/${id}`}>Respond to Load Tender Request</a></h3>
                            </>
                        }
                        {edi204?.original?.loadtenderLifeCycleStatus === 'Rejected' &&
                            <>
                                <h3><a href={`/loadtender/view/${id}`}>View Rejected Load Tender</a></h3>
                            </>
                        }
                        {(edi204?.original?.loadtenderLifeCycleStatus === 'Accepted' || edi204?.original?.loadtenderLifeCycleStatus === 'Completed') &&
                            <PrintPDF sourceName={`MCTMS-Load-Tender-${id}`}>
                                {edi204?.original?.loadtenderLifeCycleStatus === 'Accepted' &&
                                    <>
                                        <Card>
                                            <Card.Header>
                                                <Row>
                                                    <Col>
                                                        <Form.Check checked={driverassign} label={'Assign Drivers'} onChange={() => setDriverAssign(!driverassign)} />
                                                        <Form.Check checked={driverremove} label={'Remove Drivers'} onChange={() => setDriverRemove(!driverremove)} />
                                                    </Col>
                                                    <Col style={{ textAlign: 'center' }}>
                                                        <Card.Subtitle>Contact Trip</Card.Subtitle>
                                                        <Card.Title>{contractTrips && contractTrips.join(', ').replace('_', ' ')}</Card.Title>
                                                    </Col>
                                                    <Col style={{ textAlign: 'right' }}>
                                                        <Card.Subtitle>ShipmentId</Card.Subtitle>
                                                        <Card.Title>
                                                            {edi204?.original?.ShipmentId}
                                                        </Card.Title>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            {driverassign &&
                                                <Card.Body>
                                                    <Row>
                                                        <Col xs={12} md={6} lg={4}>
                                                            <Row>
                                                                <Col>
                                                                    Location
                                                                    <Form.Select onChange={(e) => setOperatorLocation(e.target.value)}>
                                                                        <option>All</option>
                                                                        {Object.keys(samsaradriversbylocation).map((key, k) => (
                                                                            <option key={k} value={key}>{key ? key : 'Undefined'}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Col>
                                                                <Col>
                                                                    Driver
                                                                    {operatorlocation === 'All'
                                                                        ?
                                                                        <>
                                                                            <Form.Control
                                                                                type="text"
                                                                                placeholder="Search..."
                                                                                value={searchTerm}
                                                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                                            />
                                                                            {/* <Form.Select
                                                                                value={operatoroption}
                                                                                onChange={(e) => setOperatorOption(e.target.value)}
                                                                            >
                                                                                <option value=''>Select...</option>
                                                                                {filteredSamsaraDrivers.map((item, i) => (
                                                                                    <option key={i} value={JSON.stringify(item)}>{item.name}</option>
                                                                                ))}
                                                                            </Form.Select> */}
                                                                            {searchTerm.trim('') !== '' &&
                                                                                <div style={{ maxHeight: '200px', overflowY: 'auto', margin: '0.5em', padding: '0.1em' }}>
                                                                                    {filteredSamsaraDrivers.map((item, i) => (
                                                                                        <Row key={i} style={{ margin: '0.1em' }}>
                                                                                            <Button variant="secondary" onClick={() => {
                                                                                                setOperatorOption(JSON.stringify(item));
                                                                                                setSearchTerm(item.name);
                                                                                            }}
                                                                                            >{item.name}</Button>
                                                                                        </Row>
                                                                                    ))}
                                                                                </div>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <Form.Select onChange={(e) => e.target.value !== '' && setOperatorOption(e.target.value)}>
                                                                            <option value=''>Select...</option>
                                                                            {samsaradriversbylocation[operatorlocation] && samsaradriversbylocation[operatorlocation].sort((a, b) => a.name.localeCompare(b.name)).map((driver, i) => (
                                                                                <option key={i} value={JSON.stringify(driver)}>{driver.name}</option>
                                                                            ))}
                                                                        </Form.Select>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={12} md={6} lg={4}>
                                                            Coverage Reason
                                                            <Form.Select onChange={(e) => setCoverageOption(e.target.value)}>
                                                                <option value=''>Select...</option>
                                                                <option>Needs Coverage</option>
                                                                <option>Recovery</option>
                                                            </Form.Select>
                                                        </Col>
                                                        <Col xs={12} md={6} lg={4}>
                                                            <br />
                                                            <Button onClick={() => handleOperatorAssignment()}>Assign</Button>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            }
                                            {driverremove &&
                                                <Card.Body>
                                                    <Row>
                                                        <Col xs={12} md={6} lg={4}>
                                                            Driver
                                                            <Form.Select onChange={(e) => setOperatorOption(e.target.value)}>
                                                                <option>Select...</option>
                                                                {edi204?.original?.operators && edi204?.original?.operators.map((driver, i) => (
                                                                    <option key={i} value={JSON.stringify(driver)}>{driver.name}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Col>
                                                        <Col xs={12} md={6} lg={4}>
                                                            Remove Reason
                                                            <Form.Select onChange={(e) => setRemoveOption(e.target.value)}>
                                                                <option>Select...</option>
                                                                <option>Call Off</option>
                                                                <option>Double Booked</option>
                                                                <option>Reassigned</option>
                                                            </Form.Select>
                                                        </Col>
                                                        <Col xs={12} md={6} lg={4}>
                                                            <br />
                                                            <Button variant="warning" onClick={() => handleOperatorRemoval()}>Remove</Button>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            }
                                            <Card.Body>
                                                <LoadTenderOperator
                                                    operators={edi204?.original?.operators}
                                                    hoslogs={hoslogs}
                                                />
                                            </Card.Body>
                                        </Card>
                                        <br />
                                        <Card>
                                            <Card.Header>
                                                <Row>
                                                    <Col>
                                                        <Card.Subtitle>Load Tender</Card.Subtitle>
                                                        <Card.Title>Summary</Card.Title>
                                                    </Col>
                                                    <Col style={{ textAlign: 'center' }}>
                                                        <Card.Subtitle>Contact Trip</Card.Subtitle>
                                                        <Card.Title>{contractTrips && contractTrips.join(', ').replace('_', ' ')}</Card.Title>
                                                    </Col>
                                                    <Col style={{ textAlign: 'right' }}>
                                                        <Card.Subtitle>ShipmentId</Card.Subtitle>
                                                        <Card.Title>
                                                            {edi204?.original?.ShipmentId}
                                                        </Card.Title>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Header>
                                                <Card.Subtitle>
                                                    <Row>
                                                        <Col>
                                                            <FaInfo /> Updates can occur manually and automatically.
                                                        </Col>
                                                        <Col>
                                                            Awaiting Updates = {awaitingUpdates()}
                                                        </Col>
                                                        <Col>
                                                            Completed = {ediStopCompleted()}
                                                        </Col>
                                                    </Row>
                                                </Card.Subtitle>
                                            </Card.Header>
                                            <Card.Body>
                                                <pre>
                                                    <Table striped bordered responsive hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Status</th>
                                                                <th>Stop</th>
                                                                <th>Name</th>
                                                                <th>Address</th>
                                                                <th>Action</th>
                                                                <th>Sched. Arrive</th>
                                                                <th>Sched. Depart</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {edi204.newest?.Stops && edi204.newest?.Stops.map((waypoint, i) => (
                                                                <tr>
                                                                    <td>
                                                                        {
                                                                            stopCompleted(waypoint.StopNum) ?
                                                                                ediStopCompleted()
                                                                                :
                                                                                awaitingUpdates()
                                                                        }
                                                                    </td>
                                                                    <td>{waypoint.StopNum}</td>
                                                                    <td>{findUSPSNASSCode(waypoint.Name)}</td>
                                                                    <td>
                                                                        <a target="_blank" rel='noreferrer' href={`https://maps.google.com/?q=${waypoint.Address1} ${waypoint.Address2} ${waypoint.City} ${waypoint.State} ${waypoint.Zip} ${waypoint.Country}`}>
                                                                            {waypoint.Address1} {waypoint.Address2} {waypoint.City} {waypoint.State} {waypoint.Zip} {waypoint.Country}
                                                                        </a>
                                                                    </td>
                                                                    {waypoint.StopNum === "1"
                                                                        ?
                                                                        <>
                                                                            <td>
                                                                                {waypoint.ReasonDesc}
                                                                            </td>
                                                                            <td>
                                                                                {formatUSPSDateTime(waypoint.Name, waypoint.RequestedDate)}  <br />

                                                                            </td>
                                                                            <td>
                                                                                {formatUSPSDateTime(waypoint.Name, waypoint.LastRequestedDate)}<br />
                                                                            </td>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <td>
                                                                                {waypoint.ReasonDesc}
                                                                            </td>
                                                                            <td>
                                                                                {formatUSPSDateTime(waypoint.Name, waypoint.RequestedDate)}  <br />
                                                                            </td>
                                                                            <td>
                                                                                {formatUSPSDateTime(waypoint.Name, waypoint.LastRequestedDate)}<br /> </td>
                                                                        </>
                                                                    }
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </pre>

                                                {edi204?.original?.loadtenderLifeCycleStatus === 'Completed' ?
                                                    <>
                                                        <Button bg="warning" onClick={() => handleLoadTenderReactivate()}>Reactivate Load Tender</Button>
                                                    </>
                                                    :
                                                    <>
                                                        {allowCompleteTender() &&
                                                            <Button bg="primary" onClick={() => handleLoadTenderCompleted()}>Mark Tender Completed</Button>
                                                        }
                                                    </>
                                                }
                                            </Card.Body>
                                        </Card>
                                        <br />

                                    </>
                                }
                                <>
                                    <Card>
                                        <Card.Header>
                                            <Card.Title>
                                                <b><FaSignal /> Telematics </b>
                                                <Badge bg={'secondary'} style={{ cursor: 'pointer', float: 'right' }} onClick={() => setShowTelematics(!showTelematics)}>
                                                    {showTelematics ? <><MdHideSource /> Hide</> : <><MdCircle /> Show</>}
                                                </Badge>
                                                <Badge bg={'secondary'} style={{ cursor: 'pointer', float: 'right', marginRight: '1em' }} onClick={() => {
                                                    setFetchTelematics(true)
                                                }
                                                }>
                                                    <FaSync /> Refetch
                                                </Badge>
                                            </Card.Title>
                                        </Card.Header>
                                        {showTelematics &&
                                            (telematicsAudit ?
                                                telematicsAudit.length > 0 ?
                                                    <Card.Body>
                                                        {telematicsAudit.map((report, i) => {
                                                            const hosWaypoints = hosWaypointsData[i];
                                                            const reportOps = report?.operator;
                                                            if (reportOps) {
                                                                return (
                                                                    <React.Fragment key={i}>
                                                                        <Card.Header>
                                                                            <Row>
                                                                                <Col xs={'auto'}>
                                                                                    <Card.Title>
                                                                                        <h3>{report?.operator?.name}</h3>
                                                                                    </Card.Title>
                                                                                </Col>
                                                                                <Col xs={'auto'} style={{ textAlign: 'center' }}>
                                                                                    <Card.Subtitle>Contact Trip</Card.Subtitle>
                                                                                    <Card.Title>{contractTrips && contractTrips.join(', ').replace('_', ' ')}</Card.Title>
                                                                                </Col>
                                                                                <Col xs={'auto'} style={{ textAlign: 'right' }}>
                                                                                    <Card.Subtitle>ShipmentId</Card.Subtitle>
                                                                                    <Card.Title>
                                                                                        {edi204?.original?.ShipmentId}
                                                                                    </Card.Title>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Header>
                                                                        <Row>
                                                                            <Col>
                                                                                <GeofenceDisplay
                                                                                    geofenceState={geofenceState}
                                                                                    circleGeofenceState={circleGeofenceState}
                                                                                    loadtenderPlot={loadtenderWaypoints}
                                                                                    hosPlot={hosWaypoints.filter(waypoint => waypoint.latitude !== 0 && waypoint.longitude !== 0)}
                                                                                // gpsPlot={
                                                                                //     report?.hoslogVehicleData?.data
                                                                                //         .flatMap(vehicle => (vehicle.gps))
                                                                                //         .map(data => ({ latitude: data.latitude, longitude: data.longitude }))
                                                                                //         .filter(waypoint => waypoint.latitude !== 0 && waypoint.longitude !== 0)
                                                                                // }
                                                                                />
                                                                                {/* {loadtenderWaypoints?.length}<br />
                                                                                {hosWaypoints?.length}<br />
                                                                                {
                                                                                    report?.hoslogVehicleData?.data
                                                                                        .flatMap(vehicle => (vehicle.gps))
                                                                                        .map(data => ({ latitude: data.latitude, longitude: data.longitude }))
                                                                                        .filter(waypoint => waypoint.latitude !== 0 && waypoint.longitude !== 0)
                                                                                        .length
                                                                                } */}
                                                                            </Col>
                                                                        </Row>
                                                                        <br />
                                                                        <Row>
                                                                            <Col>
                                                                                <Card style={{ borderRadius: '0px' }}>
                                                                                    {(edi204?.newest?.loadtenderTelematicsData && edi204?.newest?.loadtenderTelematicsData.length > 0) &&
                                                                                        <Row>
                                                                                            <Col sm={12} md={6} lg={4}>
                                                                                                <Chart
                                                                                                    chartType={"PieChart"}
                                                                                                    data={loadtenderStopPunctualityPieChart(edi204)}
                                                                                                    options={{ title: `Load Tender Check Point Punctuality` }}
                                                                                                    width="100%"
                                                                                                    height="600px"
                                                                                                />
                                                                                            </Col>
                                                                                            <Col sm={12} md={6} lg={8}>
                                                                                                <Chart
                                                                                                    chartType="ScatterChart"
                                                                                                    width="100%"
                                                                                                    height="500px" data={loadtenderPunctualityScatterChart(edi204)}
                                                                                                    // chartEvents={[
                                                                                                    //     {
                                                                                                    //         eventName: 'select',
                                                                                                    //         callback: ({ chartWrapper }) => {
                                                                                                    //             const chart = chartWrapper.getChart();
                                                                                                    //             const selection = chart.getSelection();
                                                                                                    //             if (selection.length === 0) return;
                                                                                                    //             const [selectedItem] = selection;
                                                                                                    //             const dataTable = chartWrapper.getDataTable();
                                                                                                    //             const { row } = selectedItem;
                                                                                                    //             const name = dataTable.getValue(row, 0);
                                                                                                    //             alert(`ID for ${name}`); // This could be a state update or another action
                                                                                                    //         },
                                                                                                    //     },
                                                                                                    // ]}
                                                                                                    options={{
                                                                                                        title: "Check Point Punctuality (+ Early | - Late)",
                                                                                                        hAxis: { title: "Arrival (minutes)" },
                                                                                                        vAxis: { title: "Departure (minutes)" },
                                                                                                        legend: "none",
                                                                                                        // trendlines: { 0: {} },
                                                                                                        series: {
                                                                                                            0: { pointShape: 'circle', pointSize: 10, color: 'green' }, // Example style for series 0
                                                                                                            1: { pointShape: 'triangle', pointSize: 12, color: 'blue' }, // Example style for series 1
                                                                                                            // Add more series styles as needed
                                                                                                        },
                                                                                                    }}
                                                                                                />
                                                                                            </Col>
                                                                                        </Row>
                                                                                    }
                                                                                </Card>
                                                                            </Col>

                                                                        </Row>
                                                                        <br />
                                                                        <Row>
                                                                            <Col xs={12}>
                                                                                <pre>
                                                                                    <Table style={{ border: '1px solid #ccc' }}>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th colSpan={2} style={{ backgroundColor: '#eee' }}>
                                                                                                    <div className="d-flex p-1 align-items-center">
                                                                                                        Contact Trip : {report?.uspsContractTrips.join(', ').replace('_', ' ')}
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th colSpan={2} style={{ backgroundColor: '#eee' }}>
                                                                                                    <div className="d-flex align-items-center">
                                                                                                        ShipmentId : {report?.edi204?.ShipmentId}
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th colSpan={7} style={{ backgroundColor: '#eee' }}>
                                                                                                    <div className="d-flex">
                                                                                                        <div className="d-flex p-1 align-items-center">
                                                                                                            Search Tolerance
                                                                                                            <Form.Control
                                                                                                                type="number"
                                                                                                                value={searchTolerance}
                                                                                                                step={0.25}
                                                                                                                min={1}
                                                                                                                max={10}
                                                                                                                style={{ width: '100px' }}
                                                                                                                readOnly
                                                                                                                disabled
                                                                                                            />
                                                                                                            <div>
                                                                                                                <Button variant={'primary'} onClick={() => setSearchTolerance(searchTolerance + 0.25 < 10 ? searchTolerance + 0.25 : 10)}><FaPlus /></Button>
                                                                                                                <Button variant={'secondary'} onClick={() => setSearchTolerance(searchTolerance - 0.25 > 0.25 ? searchTolerance - 0.25 : 0.25)}><FaMinus /></Button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="d-flex p-1 align-items-center">
                                                                                                            Appointment Tolerance
                                                                                                            <Form.Control
                                                                                                                type="number"
                                                                                                                value={appointmentTolerance}
                                                                                                                step={0.25}
                                                                                                                min={0.25}
                                                                                                                max={10}
                                                                                                                style={{ width: '100px' }}
                                                                                                                readOnly
                                                                                                                disabled
                                                                                                            />
                                                                                                            <div className="d-flex">
                                                                                                                <Button variant={'primary'} onClick={() => setAppointmentTolerance(appointmentTolerance + 0.25 < 5 ? appointmentTolerance + 0.25 : 5)}><FaPlus /></Button>
                                                                                                                <Button variant={'secondary'} onClick={() => setAppointmentTolerance(appointmentTolerance - 0.25 > 0.25 ? appointmentTolerance - 0.25 : 0.25)}><FaMinus /></Button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </th>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th style={{ backgroundColor: '#eee' }}>Stop</th>
                                                                                                <th style={{ backgroundColor: '#eee' }}>Location</th>
                                                                                                <th style={{ backgroundColor: '#eee' }}>On Geofence Pings</th>
                                                                                                <th style={{ backgroundColor: '#eee' }}>Sched. Arrive</th>
                                                                                                <th style={{ backgroundColor: '#eee' }}>GPS Arrival</th>
                                                                                                <th style={{ backgroundColor: '#eee' }}>+/-</th>
                                                                                                <th style={{ backgroundColor: '#eee' }}>Sched. Depart</th>
                                                                                                <th style={{ backgroundColor: '#eee' }}>GPS Departure</th>
                                                                                                <th style={{ backgroundColor: '#eee' }}>+/-</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {report?.linearizeLoadTenderAndLogs.map((via, j) => (
                                                                                                <tr key={`${i}.${j}`}>
                                                                                                    <td>{via?.StopNum}</td>
                                                                                                    <td>{findUSPSNASSCode(via?.Name)}</td>
                                                                                                    <td>{via?.eldOnPremiseCt}</td>
                                                                                                    <td>{formatUSPSDateTime(via?.Name, via?.RequestedDate)}</td>
                                                                                                    <td style={{ backgroundColor: '#eee' }}>
                                                                                                        {via?.eldOnPremiseDataStart?.time &&
                                                                                                            `${formatZuluToTZ(via?.eldOnPremiseDataStart?.time, findUSPSNassCodeTimezoneIANA(via?.Name))}`
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td style={
                                                                                                        via?.eldOnPremiseArriveDiffMin > 0
                                                                                                            ? { backgroundColor: '#bceebc' }
                                                                                                            : { backgroundColor: '#f39c9c' }
                                                                                                    }>{via?.eldOnPremiseArriveDiffMin}</td>
                                                                                                    <td>{formatUSPSDateTime(via?.Name, via?.LastRequestedDate)}</td>
                                                                                                    <td style={{ backgroundColor: '#efefef' }}>
                                                                                                        {via?.eldOnPremiseDataEnd?.time &&
                                                                                                            `${formatZuluToTZ(via?.eldOnPremiseDataEnd?.time, findUSPSNassCodeTimezoneIANA(via?.Name))}`
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td style={
                                                                                                        via?.eldOnPremiseDepartDiffMin > 0
                                                                                                            ? { backgroundColor: '#bceebc' }
                                                                                                            : { backgroundColor: '#f39c9c' }
                                                                                                    }>{via?.eldOnPremiseDepartDiffMin}</td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </pre>

                                                                            </Col>
                                                                            <Col xs={12} md={12} lg={6}>
                                                                                <Table striped bordered responsive hover>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th colSpan={2} style={{ textAlign: 'center', backgroundColor: '#eee' }}>
                                                                                                <Card.Title>
                                                                                                    Audit : {` ${goodaudit ? goodaudit : 'UNGRADED'} `}
                                                                                                </Card.Title>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <th>Search Tolerance </th>
                                                                                            <td>
                                                                                                {searchTolerance}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Appointment Tolerance</th>
                                                                                            <td>
                                                                                                {appointmentTolerance}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Search Start</th>
                                                                                            <td>{report?.hoslogSearchStart}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Search End</th>
                                                                                            <td>{report?.hoslogSearchEnd}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Total Search Time</th>
                                                                                            <td>{convertMillisecondsToHoursMinutes(report?.hoslogTotalTime)}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Driver</th>
                                                                                            <td>{report?.operator?.name}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Vehicles</th>
                                                                                            <td>
                                                                                                {report?.hoslogVehicleData?.data
                                                                                                    ? report?.hoslogVehicleData?.data.map(d => d.name).join(', ')
                                                                                                    : 'No vehicles'
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Work Start</th>
                                                                                            <td>{report?.hoslogWorkStart}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Work End</th>
                                                                                            <td>{report?.hoslogWorkEnd}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Duty Status</th>
                                                                                            <td>
                                                                                                {Object.keys(report?.hoslogStatusTotals).map((key, i) => (
                                                                                                    <>
                                                                                                        {key} : {convertMillisecondsToHoursMinutes(report?.hoslogStatusTotals[key])}<br />
                                                                                                    </>
                                                                                                ))}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Time Worked</th>
                                                                                            <td>{convertMillisecondsToHoursMinutes(report?.hoslogTimeWorked)}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Grade</th>
                                                                                            <td>
                                                                                                <Row>
                                                                                                    <Col>
                                                                                                        <Form.Check
                                                                                                            type="radio"
                                                                                                            label="PASS"
                                                                                                            name="goodaudit"
                                                                                                            id="goodaudit1"
                                                                                                            checked={goodaudit === 'PASS' ? true : false}
                                                                                                            onChange={() => setGoodAudit('PASS')}
                                                                                                        />
                                                                                                    </Col>
                                                                                                    <Col>
                                                                                                        <Form.Check
                                                                                                            type="radio"
                                                                                                            label="FAIL"
                                                                                                            name="goodaudit"
                                                                                                            id="goodaudit2"
                                                                                                            checked={goodaudit === 'FAIL' ? true : false}
                                                                                                            onChange={() => setGoodAudit('FAIL')}
                                                                                                        />
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </td>
                                                                                        </tr>
                                                                                        {goodaudit === 'FAIL' &&
                                                                                            <tr>
                                                                                                <th>Point of Failure</th>
                                                                                                <td>
                                                                                                    <Form.Control
                                                                                                        as={'textarea'}
                                                                                                        value={pointoffailure}
                                                                                                        onChange={(e) => setPointOfFailure(e.target.value)}
                                                                                                    />
                                                                                                </td>
                                                                                            </tr>
                                                                                        }
                                                                                    </tbody>
                                                                                    {edi204?.original?.loadtenderLifeCycleStatus === 'Accepted' &&
                                                                                        <tfoot>
                                                                                            <tr>
                                                                                                <td colSpan={2}>
                                                                                                    <span style={{ float: 'right' }}>
                                                                                                        <Badge bg={'primary'} style={{ cursor: 'pointer', fontSize: '1em' }} onClick={() => upsertTelematicsReport()}>
                                                                                                            Update
                                                                                                        </Badge>
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tfoot>
                                                                                    }
                                                                                </Table>
                                                                            </Col>
                                                                            <Col xs={12} md={12} lg={6}>
                                                                                <Card style={{ borderRadius: 0, }}>
                                                                                    <Card.Header style={{ backgroundColor: '#eee' }}>
                                                                                        <Card.Title>
                                                                                            HOS Log
                                                                                        </Card.Title>
                                                                                    </Card.Header>
                                                                                    <DriverHOSLog hoslogs={report.hoslogs} />
                                                                                </Card>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                )
                                                            } else {
                                                                return null;
                                                            }
                                                        })}
                                                    </Card.Body>
                                                    :
                                                    <Card.Body>
                                                        No telematics data.
                                                    </Card.Body>
                                                :
                                                <Card.Body>
                                                    <Spinner /> Checking for telematic connections.
                                                </Card.Body>
                                            )}
                                    </Card>
                                    <br />
                                    <Card>
                                        <Card.Header>
                                            <Card.Title>
                                                <Row>
                                                    <Col><b><BsChatRightText /> EDI Messaging</b></Col>
                                                    <Col>
                                                        <Badge bg={'secondary'} style={{ cursor: 'pointer', float: 'right' }} onClick={() => setShowEdiMessaging(!showEdiMessaging)}>
                                                            {showEdiMessaging ? <><MdHideSource /> Hide</> : <><MdCircle /> Show</>}
                                                        </Badge>
                                                    </Col>
                                                </Row>
                                            </Card.Title>
                                        </Card.Header>
                                        {showEdiMessaging &&
                                            <>
                                                {edi204?.original?.loadtenderLifeCycleStatus === 'Accepted' &&
                                                    <Card.Header>
                                                        <Card.Subtitle><FaInfo />
                                                            To manually send updates, click the inputs button with respect to the stop you want to update. Datetime inputs will show up in the for ETA, Arrived, and Departed columns when activated.
                                                            Select one of the datetime inputs and update the datetime. When the date time input changes, and update button will apear. The update button will have an associate code like: AA, X3, AF, AG, X1, or CD.
                                                            The code on the update button relates to the message that will be sent.
                                                        </Card.Subtitle>
                                                    </Card.Header>
                                                }
                                                <Card.Body>
                                                    <div>
                                                        <Table striped bordered responsive hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>Status</th>
                                                                    <th>Stop</th>
                                                                    <th>Name</th>
                                                                    <th>Address</th>
                                                                    <th>Reason</th>
                                                                    <th>Sched. Arrive</th>
                                                                    <th>Sched. Depart</th>
                                                                    <th>ETA</th>
                                                                    <th>Arrived</th>
                                                                    <th>Departed</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {edi204.newest?.Stops && edi204.newest?.Stops.map((waypoint, i) => (
                                                                    <tr key={i}>
                                                                        <td>
                                                                            {
                                                                                stopCompleted(waypoint.StopNum) ?
                                                                                    ediStopCompleted()
                                                                                    :
                                                                                    awaitingUpdates()
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {waypoint.StopNum}
                                                                        </td>
                                                                        <td>
                                                                            {findUSPSNASSCode(waypoint.Name)}
                                                                        </td>
                                                                        <td>
                                                                            <a target="_blank" rel='noreferrer' href={`https://maps.google.com/?q=${waypoint.Address1} ${waypoint.Address2} ${waypoint.City} ${waypoint.State} ${waypoint.Zip} ${waypoint.Country}`}>
                                                                                {waypoint.Address1} {waypoint.Address2} {waypoint.City} {waypoint.State} {waypoint.Zip} {waypoint.Country}
                                                                            </a>
                                                                        </td>
                                                                        {
                                                                            waypoint.StopNum === "1"
                                                                                ?
                                                                                // For StopNum "1"
                                                                                <>
                                                                                    <td>{waypoint.ReasonDesc}</td>
                                                                                    <td>{formatUSPSDateTime(waypoint.Name, waypoint.RequestedDate)}<br /></td>
                                                                                    <td>{formatUSPSDateTime(waypoint.Name, waypoint.LastRequestedDate)}<br /></td>
                                                                                    <td>{renderStatusCodeSection(waypoint.StopNum, 'AA', edi214s)}</td>
                                                                                    <td>{renderStatusCodeSection(waypoint.StopNum, 'X3', edi214s)}</td>
                                                                                    <td>{renderStatusCodeSection(waypoint.StopNum, 'AF', edi214s)}</td>
                                                                                </>
                                                                                :
                                                                                // For other StopNum values
                                                                                <>
                                                                                    <td>{waypoint.ReasonDesc}</td>
                                                                                    <td>{formatUSPSDateTime(waypoint.Name, waypoint.RequestedDate)}<br /></td>
                                                                                    <td>{formatUSPSDateTime(waypoint.Name, waypoint.LastRequestedDate)}<br /></td>
                                                                                    <td>{renderStatusCodeSection(waypoint.StopNum, 'AG', edi214s)}</td>
                                                                                    <td>{renderStatusCodeSection(waypoint.StopNum, 'X1', edi214s)}</td>
                                                                                    <td>{renderStatusCodeSection(waypoint.StopNum, 'CD', edi214s)}</td>
                                                                                </>
                                                                        }
                                                                        <td>
                                                                            {edi204?.original?.loadtenderLifeCycleStatus === 'Accepted' &&
                                                                                <Badge bg="secondary" onClick={() => setAllowForcedStopUpdate(allowForcedStopUpdate === waypoint.StopNum ? '' : waypoint.StopNum)}>
                                                                                    {allowForcedStopUpdate === waypoint.StopNum ? <MdCircle /> : <MdHideSource />} inputs
                                                                                </Badge>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Card.Body>
                                                <Card.Footer>
                                                    <Row>
                                                        <Col>
                                                            {edi204?.original?.loadtenderLifeCycleStatus === 'Completed' ?
                                                                <>
                                                                    <Button bg="warning" onClick={() => handleLoadTenderReactivate()}>Reactivate Load Tender</Button>
                                                                </>
                                                                :
                                                                <>
                                                                    {allowCompleteTender() &&
                                                                        <Button bg="primary" onClick={() => handleLoadTenderCompleted()}>Mark Tender Completed</Button>
                                                                    }
                                                                </>
                                                            }
                                                        </Col>
                                                        {edi204?.original?.loadtenderLifeCycleStatus === 'Accepted' &&
                                                            <Col>
                                                                <ConfirmationModal
                                                                    show={showModal}
                                                                    onHide={() => setShowModal(false)}
                                                                    onConfirm={() => handleLoadTenderReject()}
                                                                    message={'Are you sure you want to reject this Load Tender?'}
                                                                />
                                                                <Button
                                                                    type="button"
                                                                    variant="danger"
                                                                    onClick={() => setShowModal(true)}
                                                                    style={{ float: 'right' }}
                                                                >Reject Tender</Button>
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Card.Footer>
                                            </>
                                        }
                                    </Card>
                                </>
                                <br />
                            </PrintPDF>
                        }
                    </>
                    :
                    <>
                        <LogisticsLoading />
                    </>
                }
            </React.Fragment>
        </Container >
    );
}

export default LoadTenderUpdate;
