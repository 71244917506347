import { Container, Card, Row, Col, Form, Button, Spinner, Table, Tooltip, OverlayTrigger, Accordion } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import Api from "../../utils/Api";
import { useParams } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import appRoutes from "../../context/approutes";
import { FaInfoCircle } from 'react-icons/fa';
import { multidimensionalGroupBy } from '../../utils/Array.helper'
import AlertDisplay from "../../components/AlertDisplay";

function AccessRoleUpdate() {
    const { idToken } = useAuth();
    const { id } = useParams();
    const [set, didSet] = useState();
    const [role, setRole] = useState({});
    const [permissions, setPermissions] = useState({});
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    const routeGroups = multidimensionalGroupBy(appRoutes, ['feature']);
    const routeGroupKeys = Object.keys(routeGroups);
    routeGroupKeys.sort((a, b) => {
        return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
    });

    async function handleUpdate() {
        try {
            const data = { ...role, permissions: permissions };
            await Api.patch(`/access/role/${id}`, data, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success', }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error updating', }])
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Api.get(`/access/role/${id}`, idToken);
                setRole(data)

                if (data.permissions) {
                    setPermissions(data.permissions);
                }
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error', }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    })

    const UpdateAndAlertBar = () => {
        return (
            <>
                <Card.Body>
                    <Row>
                        <Col sm={6}>
                            <Button variant="primary" onClick={() => handleUpdate()}>Update</Button>
                        </Col>
                        <Col className="d-flex justify-content-end align-items-center">
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id="button-tooltip">
                                        Active - determines whether or not the record should be
                                        used by the software application.
                                    </Tooltip>
                                }
                            >
                                <div>
                                    Active <FaInfoCircle /> : <Button variant={role.isActive ? 'success' : 'danger'} onClick={() => { setRole({ ...role, isActive: !role.isActive }) }}>{role.isActive ? 'True' : 'False'}</Button>
                                </div>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                </Card.Body>
                <AlertDisplay alertState={alertState} />
            </>

        )
    }

    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header>
                    <b>Role Update</b>
                </Card.Header>
                {(set && role) ?
                    <>
                        <Card.Body>
                            <Row>
                                <Col sm={12} md={6} lg={3}>
                                    ID : {role._id}
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col sm={12} md={6} lg={3}>
                                    Name 
                                    <Form.Control type="text" value={role.name} onChange={e => setRole({ ...role, name: e.target.value })} />
                                </Col>
                                <Col sm={12} md={6} lg={3}>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip id="button-tooltip">
                                                Sharing the role will allow new users to be assigned this role.
                                                Only share roles with low levels of access.
                                            </Tooltip>
                                        }
                                    >
                                        <div>
                                            <FaInfoCircle /> Share Role
                                        </div>
                                    </OverlayTrigger>
                                    <Form.Select value={role.accessibility} onChange={e => setRole({ ...role, accessibility: e.target.value })}>
                                        <option></option>
                                        <option value={'Allow'}>Allow</option>
                                        <option value={'Block'}>Block</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Card.Body>
                        <UpdateAndAlertBar />
                        <Card.Body>
                            <Accordion>
                                {routeGroupKeys.map((feature, i) => (
                                    <Accordion.Item eventKey={i} key={i}>
                                        <Accordion.Header style={{ backgroundColor: '#efefef' }}>
                                            <div style={{ display: 'inline-block' }}>
                                                {feature} - {Object.keys(Object.entries(permissions)
                                                    .filter(([key, val]) =>
                                                        routeGroups[feature].some(approute => approute.path === key) && val === true
                                                    )
                                                    .reduce((acc, [key, val]) => {
                                                        acc[key] = val;
                                                        return acc;
                                                    }, {})).length} / {routeGroups[feature].length}
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Table striped bordered hover style={{ whiteSpace: 'nowrap' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Access</th>
                                                        <th>Name</th>
                                                        <th>Detail</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {routeGroups[feature].map((approute, j) => (
                                                        <tr key={j}>
                                                            <td style={{ width: '50px' }}>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={permissions[approute.path]}
                                                                    value={permissions[approute.path]}
                                                                    onChange={() => setPermissions({ ...permissions, [approute.path]: (permissions[approute.path] ? false : true) })}
                                                                />
                                                            </td>
                                                            <td style={{ width: '150px' }}>{approute.name}</td>
                                                            <td>{approute.detail}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>

                        </Card.Body>
                        <UpdateAndAlertBar />
                    </>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    );
}

export default AccessRoleUpdate;