import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import { Navbar, Nav, Offcanvas, Dropdown, Button, Image } from 'react-bootstrap';
import {
    FaRoute, FaCalendarAlt, FaRegIdCard, FaTrailer, FaBookmark, FaTruck, FaSearchLocation, FaFileContract, FaLink,
    FaGasPump, FaInfo, FaFileExcel, FaWpforms, FaMoneyCheck, FaUserLock, FaFilePdf, FaUserPlus, FaCloud, FaBell, FaWifi, FaBug, FaChartPie, FaArrowLeft, FaArrowRight, FaTrafficLight, FaCalendar, FaUser, FaChartBar, FaExclamationTriangle, FaUserCog, FaMicrochip, FaClipboard, FaHandshake, FaDollarSign, FaMapPin, FaFileInvoiceDollar, FaEye
} from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { AiFillHome, AiFillSchedule, AiOutlineSchedule, } from 'react-icons/ai';
import { TbReportAnalytics } from 'react-icons/tb';
import { BsBoxes, BsChatLeft } from 'react-icons/bs';
import { MdHelp, MdOutlineSensors } from 'react-icons/md';
import { TbNetwork } from 'react-icons/tb'
import { CgProfile } from "react-icons/cg";
import { useRole } from "../context/role";
import { FiRepeat, } from 'react-icons/fi';
import { FaCubes } from 'react-icons/fa';
import { FaUmbrellaBeach, FaPlaneDeparture, FaRoad, FaCarCrash } from 'react-icons/fa';
import { GoReport } from 'react-icons/go';
import { BsCashCoin } from 'react-icons/bs'
import { AiOutlineAlert } from 'react-icons/ai'
import { TbPlusMinus } from 'react-icons/tb';
import { HiOutlineTag } from 'react-icons/hi';
import { FaDownload, FaHome, } from 'react-icons/fa';
import { ImFilePdf } from 'react-icons/im';
import { BsCashStack } from 'react-icons/bs';
import { MdAttachMoney } from 'react-icons/md';
import { BsActivity, BsCheckLg, BsInbox, BsXLg } from "react-icons/bs";
import { FaTruckLoading } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { HiUserGroup } from "react-icons/hi";
import { MdOutlineBadge } from "react-icons/md";
import { useAuth } from '../context/auth';
import { AiOutlineLogout } from "react-icons/ai";
import ConfirmationModal from '../components/ConfirmationModal';
import MCTMS_LOGO from '../assets/mctms-logo.png'
import { FaMagnifyingGlass } from 'react-icons/fa6';

function NavOptions({ toggleState, offcanvasVisibleState }) {
    const { toggle, setToggle } = toggleState;
    const { offcanvasVisible, setOffcanvasVisible } = offcanvasVisibleState;
    const { userRole, checkNavPermissions } = useRole();
    const { logout } = useAuth();
    const [showModal, setShowModal] = useState();

    async function handleLogout() {
        await logout()
    }

    const navHeader0 = [
        { href: "/", icon: <AiFillHome />, text: "Home" },
    ]
    const navContent0 = [
        { href: "/", icon: <AiFillHome />, text: "Home" },
        { href: "/help", icon: <MdHelp />, text: "Help" },
        { href: "/profile", icon: <CgProfile />, text: "Profile" },
        { href: "/settings", icon: <FiSettings />, text: "Settings" },
    ]

    const navHeaderBilling = [
        { href: "/billing", icon: <FaFileInvoiceDollar />, text: "Billing" },
    ]

    const navContentBilling = [
        { href: "/billing", icon: <FaFileInvoiceDollar />, text: "Dashboard" },
    ]

    const navHeader1 = [
        { href: "/plan", icon: <AiOutlineSchedule />, text: "Plan" },
    ]
    const navContent1 = [
        { href: "/plan", icon: <AiOutlineSchedule />, text: "Route Planning" },
        { href: "/plan/issues", icon: <FaExclamationTriangle />, text: "Issues" },
        { href: "/plan/assignment", icon: <FaLink />, text: "Assignment" },
        { href: "/plan/contract", icon: <FaFileContract />, text: "Contract" },
        { href: "/plan/forecast", icon: <FaCalendarAlt />, text: "Forecast" },
        { href: "/plan/frequency", icon: <FiRepeat />, text: "Frequency" },
        { href: "/plan/location", icon: <FaSearchLocation />, text: "Location" },
        { href: "/plan/operator", icon: <FaRegIdCard />, text: "Operator" },
        { href: "/plan/routeplan", icon: <FaRoute />, text: "Route" },
        { href: "/plan/specialop", icon: <FaBookmark />, text: "Special Op" },
        { href: "/plan/trailer", icon: <FaTrailer />, text: "Trailer" },
        { href: "/plan/vehicle", icon: <FaTruck />, text: "Vehicle" }
    ]

    const navHeader2 = [
        { href: "/dispatch", icon: <FaTruck />, text: "Dispatch " },
    ]
    const navContent2 = [
        { href: "/dispatch", icon: <FaTruck />, text: "Dispatch " },
        { href: "/dispatch/dashboard", icon: <FaChartPie />, text: "Dashboard" },
        { href: "/dispatch/calendar", icon: <FaCalendar />, text: "Calendar" },
        { href: "/dispatch/open", icon: <FaUser />, text: "Open" },
        { href: "/dispatch/builder", icon: <FaCubes />, text: "Builder" },

    ]

    const navHeader12 = [
        { href: "/", icon: <FaFileExcel />, text: "USPS Documents" },
    ]

    const navContent12 = [
        { href: "/manifest", icon: <AiFillSchedule />, text: "Manifest" },
        { href: "/ffh", icon: <FaFileExcel />, text: "5500" },
        { href: "/hcr", icon: <FaFilePdf />, text: "HCR" },
    ]

    const navHeader9 = [
        { href: "/iot", icon: <MdOutlineSensors />, text: "Telematics " },
    ]
    const navContent9 = [
        { href: "/iot", icon: <MdOutlineSensors />, text: "IoT " },
        { href: "/fuel", icon: <FaGasPump />, text: "Fuel" },
        { href: "/weather", icon: <FaCloud />, text: "Weather" },
        { href: "/loadeye", icon: <FaTrailer />, text: "Load Eye" },
        { href: "/eld/samsara", icon: <FaMicrochip />, text: "Samsara" },
        { href: "/eld/geotab", icon: <FaMicrochip />, text: "Geotab" },
    ]

    const navHeader3 = [
        { href: "/forms", icon: <FaWpforms />, text: "Forms" },
    ]
    const navContent3 = [
        { href: "/forms", icon: <FaWpforms />, text: "Forms" },
        { href: "/form/accident", icon: <FaCarCrash />, text: "Accident Report" },
        { href: "/form/dailyreport", icon: <GoReport />, text: "Daily Report" },
        { href: "/form/employeereimbursement", icon: <BsCashCoin />, text: "Emp. Reimbusement" },
        { href: "/form/incident", icon: <AiOutlineAlert />, text: "Incident" },
        { href: "/form/pto", icon: <FaUmbrellaBeach />, text: "PTO" },
        { href: "/form/purchaseorder", icon: <HiOutlineTag />, text: "Purchase Order" },
        { href: "/form/roadtest", icon: <FaRoad />, text: "Road Test" },
        { href: "/form/travelrequest", icon: <FaPlaneDeparture />, text: "Travel Request" },
        { href: "/form/thirdpartyvehicle", icon: <TbPlusMinus />, text: "3rd Party Vehicles" },
    ]

    const navHeader4 = [
        { href: "/loadtender", icon: <TbNetwork />, text: "Load Tender" },
    ]

    const navContent4 = [
        { href: "/loadtender", icon: <FaInfo />, text: "Info" },
        // { href: "/loadtender/calendar", icon: <FaCalendar />, text: "Calendar" },
        { href: "/loadtender/iot", icon: <MdOutlineSensors />, text: "IoT" },
        { href: "/loadtender/pay", icon: <FaDollarSign />, text: "Pay" },
        { href: "/loadtender/watch", icon: <FaEye />, text: "Watch" },
        { href: "/loadtender/search", icon: <FaMagnifyingGlass />, text: "Search" },
        { href: "/loadtender/dashboard", icon: <FaChartPie />, text: "Dashboard" },
        { href: "/loadtender/dashboard/v2", icon: <FaChartPie />, text: "Dashboard v2" },
        { href: "/loadtender/dispatch", icon: <BsBoxes />, text: "Dispatch" },
        { href: "/loadtender/usps-service-points", icon: <FaMapPin />, text: "USPS Service Points" },
        // { href: "/loadtender/accepted", icon: <FaHandshake />, text: "Accepted" },
        // { href: "/loadtender/active", icon: <BsActivity />, text: "Active" },
        // { href: "/loadtender/completed", icon: <BsCheckLg />, text: "Completed" },
        // { href: "/loadtender/rejected", icon: <BsXLg />, text: "Rejected" },
        // { href: "/loadtender/requests", icon: <BsInbox />, text: "Requests" },
    ]

    const navHeader5 = [
        { href: "/timecard", icon: <FaMoneyCheck />, text: "Timecard" },
    ]
    const navContent5 = [
        { href: "/timecard/info", icon: <FaInfo />, text: "Info" },
        { href: "/timecard/profile", icon: <FaMoneyCheck />, text: "My Timecards" },
        { href: "/timecard", icon: <FaHome />, text: "Timecards" },
        { href: "/timecard/dashboard", icon: <BsCashStack />, text: "Dashboard" },
        { href: "/timecard/exports", icon: <FaDownload />, text: "Exports" },
        { href: "/timecard/reports", icon: <ImFilePdf />, text: "Reports" },
        { href: "/timecard/settings", icon: <MdAttachMoney />, text: "Settings" },
    ]

    const navHeader11 = [
        { href: "/safety", icon: <FaTrafficLight />, text: "Safety" },
    ]

    const navContent11 = [
        { href: "/safety", icon: <FaTrafficLight />, text: "Safety" },
        { href: "/safety/incidentmanagement", icon: <FaCarCrash />, text: "Incident Management" },
    ]

    const navHeader6 = [
        { href: "/access", icon: <FaUserLock />, text: "Access" },
    ]
    const navContent6 = [
        { href: "/access", icon: <FaUserLock />, text: "Access" },
        { href: "/access/groups", icon: <HiUserGroup />, text: "Groups" },
        { href: "/access/roles", icon: <MdOutlineBadge />, text: "Roles" },
        { href: "/access/profiles", icon: <CgProfile />, text: "Profiles" },
        { href: "/access/new-users", icon: <FaUserPlus />, text: "New Users" },
        { href: "/access/new-users", icon: <FaUserCog />, text: "Stale Users" },
    ]

    const navHeader7 = [
        { href: "/report", icon: <TbReportAnalytics />, text: "Reports" },
    ]

    const navContent7 = [
        { href: "/report", icon: <TbReportAnalytics />, text: "Reports" },
    ]

    const navHeader8 = [
        { href: "/notification", icon: <FaBell />, text: "Notifications" },
    ]

    const navContent8 = [
        { href: "/notification", icon: <FaBell />, text: "Notifications" },
    ]

    const homeheader = checkNavPermissions(userRole, navHeader0)
    const homecontent = checkNavPermissions(userRole, navContent0)

    const planheader = checkNavPermissions(userRole, navHeader1)
    const plancontent = checkNavPermissions(userRole, navContent1)

    const dispatchheader = checkNavPermissions(userRole, navHeader2)
    const dispatchcontent = checkNavPermissions(userRole, navContent2)

    const uspsdocsheader = checkNavPermissions(userRole, navHeader12)
    const uspsdocscontent = checkNavPermissions(userRole, navContent12)


    const eldeldheader = checkNavPermissions(userRole, navHeader9)
    const eldeldcontent = checkNavPermissions(userRole, navContent9)

    const formsheader = checkNavPermissions(userRole, navHeader3)
    const formscontent = checkNavPermissions(userRole, navContent3)

    const loadtenderheader = checkNavPermissions(userRole, navHeader4)
    const loadtendercontent = checkNavPermissions(userRole, navContent4)

    const safetyheader = checkNavPermissions(userRole, navHeader11)
    const safetycontent = checkNavPermissions(userRole, navContent11)

    const timecardheader = checkNavPermissions(userRole, navHeader5)
    const timecardcontent = checkNavPermissions(userRole, navContent5)

    const accessheader = checkNavPermissions(userRole, navHeader6)
    const accesscontent = checkNavPermissions(userRole, navContent6)

    const reportsheader = checkNavPermissions(userRole, navHeader7)
    const reportscontent = checkNavPermissions(userRole, navContent7)

    const notificationsheader = checkNavPermissions(userRole, navHeader8)
    const notificationscontent = checkNavPermissions(userRole, navContent8)


    const billingheader = checkNavPermissions(userRole, navHeaderBilling)
    const billingcontent = checkNavPermissions(userRole, navContentBilling)

    const menubar = [
        [homeheader, homecontent],
        [billingheader, billingcontent],
        [planheader, plancontent],
        [dispatchheader, dispatchcontent],
        [loadtenderheader, loadtendercontent],
        [eldeldheader, eldeldcontent],
        [uspsdocsheader, uspsdocscontent],
        [formsheader, formscontent],
        [safetyheader, safetycontent],
        [timecardheader, timecardcontent],
        [reportsheader, reportscontent],
        [notificationsheader, notificationscontent],
        [accessheader, accesscontent],

    ]

    const headerFontStyle = {
        fontSize: '1.15em'
    }

    const contentFontStyle = {
        fontSize: '1.05em'
    }

    return (
        <>
            <Nav variant="tabs"
                defaultActiveKey={window.location.pathname}
                className="flex-column flex-grow-1"
                style={{ borderBottom: '0px', borderRight: '1px solid #ccc'}}
            >
                {!offcanvasVisible &&
                    <Nav.Item 
                    style={{ textAlign: 'right', backgroundColor: '#f8f9fa', zIndex: 10 }}>
                        <button onClick={() => setToggle(!toggle)}
                            style={{
                                display: 'absolute',
                                fontSize: '12px',
                                marginRight: '-10px',
                                borderRadius: '50%',
                                borderWidth: '1px',
                                borderColor: '#333',
                                backgroundColor: '#fff',
                            }}
                        >{toggle ? <FaArrowRight /> : <FaArrowLeft />}</button>
                    </Nav.Item>
                }

                {!toggle && menubar.map(([headerItems, contentItems], i) => (
                    contentItems.length > 0 &&
                    <React.Fragment key={'ndd_' + i}>
                        <Dropdown as={Nav.Item} style={headerFontStyle}>
                            <Dropdown.Toggle as={Nav.Link} style={{ whiteSpace: 'pre-wrap' }}>
                                {headerItems.map((headerItem, j) => (
                                    <React.Fragment key={`${i}.${j}`} >
                                        {headerItem.icon} {headerItem.text}
                                    </React.Fragment>
                                ))}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={contentFontStyle}>
                                {contentItems.map((contentItem, k) => (
                                    <Dropdown.Item key={`${i}-${k}`} as={Link} to={contentItem.href} onClick={() => setOffcanvasVisible(false)} >
                                        <Link to={contentItem.href}>{contentItem.icon} {contentItem.text}</Link>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </React.Fragment>
                ))
                }
                {!toggle &&
                    <>
                        <hr />
                        <Nav.Item style={headerFontStyle}>
                            <Nav.Link href='https://forms.gle/Yram2oknfEahhuu56' target='_blank'> <BsChatLeft /> {'Feedback & Suggestions'} </Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={headerFontStyle}>
                            <Nav.Link href='https://forms.gle/WNSeN7UsAvnoRnXS7' target='_blank'> <FaBug /> {'Report Bug'} </Nav.Link>
                        </Nav.Item>
                        <hr />
                        <Button style={{ color: '#fbb70e', margin: '0px', padding: '0px' }} variant="link" onClick={() => setShowModal(true)}> <AiOutlineLogout /> Logout </Button>
                        {showModal &&
                            <ConfirmationModal show={() => setShowModal(true)} onHide={() => setShowModal(false)} onConfirm={() => handleLogout()} message={'Are you sure you want to leave?'} />
                        }
                    </>
                }
            </Nav >

        </>


    )
}

// todo : lifted state to REDUX 

const Navigation = ({ toggleState }) => {
    const [offcanvasVisible, setOffcanvasVisible] = useState(false);
    const offcanvasVisibleState = { offcanvasVisible, setOffcanvasVisible }

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowSize]);

    return (
        <Navbar bg="light" expand="lg" style={{ margin: '0', padding: '0' }}>
            <Navbar.Toggle
                style={{ margin: '0.25em', fontSize: '1.1em', flex: 1, backgroundColor:'#0d6efd' }}
                onClick={() => setOffcanvasVisible(!offcanvasVisible)}
            >
                Navigation
            </Navbar.Toggle>
            <Navbar.Collapse>
                {windowSize.width > 1000 &&
                    <NavOptions toggleState={toggleState} offcanvasVisibleState={offcanvasVisibleState} />
                }
            </Navbar.Collapse>
            <Offcanvas
                show={offcanvasVisible}
                onHide={() => setOffcanvasVisible(false)}
                className="border-0 w-100"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <Image src={MCTMS_LOGO} alt={'Mail Carrier TMS Logo'} style={{ maxHeight: '7vh' }} />
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <NavOptions toggleState={toggleState} offcanvasVisibleState={offcanvasVisibleState} />
                </Offcanvas.Body>
            </Offcanvas>
        </Navbar>
    );
};

export default Navigation;