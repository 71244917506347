import React, { useState } from "react";
import { Container, Table } from "react-bootstrap"
import { EDI_214_SHIPMENT_STATUS_CODES } from "../../context/klienschmidtdata";
import { FaInfoCircle } from "react-icons/fa";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";

const EDI214Component = ({ ...props }) => {
    const edi214s = props.edi214s;

    const [show214, setShow214] = useState();

    if (!edi214s || edi214s.length === 0) {
        return (
            <Container>
                <b>No EDI 214 : Status Updates</b>
            </Container>
        )
    }

    function findShipmentStatusCode(code) {
        return EDI_214_SHIPMENT_STATUS_CODES.find((status) => status.Code === code);
    }

    return (
        <Table striped bordered hover responsive>
            <thead>
                <tr>
                    <th colSpan={5}>EDI214</th>
                </tr>
                <tr>
                    <th> <FaInfoCircle /> </th>
                    <th>ShipmentId</th>
                    <th>Appt/Stop Number</th>
                    <th>Status</th>
                    <th>Status Date TZ</th>
                </tr>
            </thead>
            <tbody>
                {edi214s.map((edi214, i) => (
                    <React.Fragment key={i}>
                        <tr>
                            <td onClick={() => setShow214(show214 === i ? '' : i)}>{show214 === i ? <AiFillCaretDown/>: <AiFillCaretRight/> }</td>
                            <td>{edi214.ShipmentId}</td>
                            <td>{edi214.ApptNumber}</td>
                            <td>{edi214.StatusCode + "-" + findShipmentStatusCode(edi214.StatusCode).Description}</td>
                            <td>{edi214.StatusDate} {edi214.TimeZone}</td>
                        </tr>
                        {show214 === i &&
                            <tr key={`204-row-${i}-b`}>
                                <td colSpan={8}>
                                    <pre>
                                        {JSON.stringify(edi214, "", 2)}
                                    </pre>
                                </td>
                            </tr>
                        }
                    </React.Fragment>

                ))}
            </tbody>
        </Table>
    )
}

export default EDI214Component;