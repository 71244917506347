import React from 'react';

const HtmlEmailComponent = ({...props}) => {
    const {header, greeting, title, message, followup, url, urlText, complimentary, signature  } = props;
    return (
        <>
            <html>
                <head>
                    <meta charset="UTF-8" />
                    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <title>Mail Carrier TMS Message</title>
                </head>
                <body style={{fontFamily: 'Arial, sans-serif', lineHeight: '1.6', margin: 0, padding: '20px', backgroundColor: '#f1f1f1'}}>
                    <div style={{maxWidth: '600px', margin:'0 auto', backgroundColor: '#fff', padding: '20px', borderRadius: '5px',}}>
                        <h2 style={{color: '#333'}}>{header}</h2>
                        <p style={{color: '#333'}}>
                            {greeting} 
                            <br />
                            <br />
                            <strong>{title}</strong>
                            <br />
                            {message}
                            <br />
                            <br />
                            {followup}
                            {/* For more details, please visit our website: */}
                            <br />
                            <a href={url} target="_blank" rel="noreferrer" style={{color: '#007bff', textDecoration: 'none'}}>{urlText}</a>
                            <br />
                            <br />
                            <br />
                            {complimentary}
                            {/* Best regards, */}
                            <br />
                            {signature}
                            {/* Mail Carrier TMS Team */}
                        </p>
                    </div>
                </body>
            </html>

        </>
    )
}

export default HtmlEmailComponent