import { Container, Card, Spinner, Col, Badge, Row, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import React, { useState, useEffect } from 'react'
import { FaPlusCircle, } from 'react-icons/fa'
import { useAuth } from '../../context/auth';
import DataDisplay from "../../components/DataDisplay";
import { Link } from "react-router-dom";
import AlertDisplay from "../../components/AlertDisplay";

function Operator() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [operators, setOperators] = useState();
    const [alerts, setAlerts] = useState([])
    const alertState = {alerts, setAlerts}
    const [query, setQuery] = useState({
        isActive: true
    });
    useEffect(() => {
        const fetchData = async () => {
            try {
                const queryParams = new URLSearchParams(query);
                const data = await Api.query('/operators', queryParams, idToken);
                if (!data || !Array.isArray(data)) {
                    throw new Error("Received invalid data from server.");
                }
                const cleanOperators = data.map(operator => {
                    return {
                        _id: operator._id,
                        'First Name': operator.firstname,
                        'Last Name': operator.lastname,
                        'Assignment': operator.assignment.name,
                        'Domicile': operator.domicile.name,
                        'License Class': operator.licensetype,
                        'Provider':  operator.provider,
                        'Active': operator.isActive ? '✅' : '❌',
                    }
                })

                setOperators(cleanOperators)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }
            didSet(true)
        }
        if(!set){
            fetchData()
        }
    }, [set, idToken, alerts,query])

    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <Card.Title>
                        <Row>
                            <Col>Operator </Col>
                            <Col style={{ textAlign: 'center' }}>
                                <Badge
                                    bg={query.isActive ? 'success' : 'danger'}
                                    onClick={() => { setQuery({ ...query, isActive: !query.isActive }); didSet(false) }}
                                >
                                    {query.isActive ? 'Active' : 'Inactive'}
                                </Badge>
                            </Col>
                            <Col>
                            <Link className="float-end" to="/plan/operator-form"><FaPlusCircle /> Form</Link>
                            </Col>
                        </Row>
                   </Card.Title>
                </Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {set ?
                    <Card.Body>
                        {operators.length > 0 ?
                            <>
                                <DataDisplay
                                    dataSource={operators}
                                    lsKey={'@mctms-operators-display'}
                                    urlPath={'/plan/operator/'}
                                    urlKey={'_id'}
                                    popKeys={['_id']}
                                />
                            </>
                            :
                            <>
                                No data.
                            </>
                        }
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }
            </Card>
        </Container>
    );
}

export default Operator;